import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

import DownArrowIcon from "../../../Assets/Images/chevron-down.png";

import plassIcon from "../../../Assets/Images/plassIcon.png";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { TOAST_CONFIG } from "../../../lib/constants";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../lib/store/store";
import { createAdminUser, uploadFile } from "../../../Services/adminActions";
import { capitalizeEachWord } from "../../../lib/helpers";

export default function AddAdminPop({
  addAdminPop,
  setAddAdminPop,
  setReloadList,
  reloadList
}) {
  let authState = useAppSelector(state => state.auth);
  const [genderDrop, setGenderDrop] = useState(false);
  const [genderDropVal, setGenderDropVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const initAdmin = {
    name: "",
    email: "",
    phone: "",
    gender: "",
    address_line1: "",
    status: "active",
    password: ""
  }

  let [adminData, setAdminData] = useState({ ...initAdmin })

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setAddAdminPop(false);
    }
  };

  const validationObj = {
    hasErr: false,
    'name': {
      error: false,
      msg: ''
    },
    'email': {
      error: false,
      msg: ''
    },
    'password': {
      error: false,
      msg: ''
    },
    'phone': {
      error: false,
      msg: ''
    },
    'gender': {
      error: false,
      msg: ''
    },
    'address_line1': {
      error: false,
      msg: ''
    }
  }

  const [validationErrors, setValidationErrors] = useState({ ...validationObj });

  const handleValidation = (formInput) => {
    formInput = formInput || adminData;
    let validationerr = { ...validationObj };

    if (formInput.name == "") {
      validationerr.hasErr = true;
      validationerr.name = {
        error: true,
        msg: 'Name is required'
      }
    }

    if (formInput.email == "") {
      validationerr.hasErr = true;
      validationerr.email = {
        error: true,
        msg: 'Email is required'
      }
    }

    if (formInput.password == "") {
      validationerr.hasErr = true;
      validationerr.password = {
        error: true,
        msg: 'Password is required'
      }
    }
    
    if (formInput.password.length < 6) {
      validationerr.hasErr = true;
      validationerr.password = {
        error: true,
        msg: 'Password must have at least 6 characters.'
      }
    }
    if (!/[a-z]/.test(formInput.password) || !/[A-Z]/.test(formInput.password)) {
      validationerr.hasErr = true;
      validationerr.password = {
        error: true,
        msg: 'Password must contain both uppercase and lowercase letters.'
      }
    }

    if (!/[!@#?]/.test(formInput.password)) {
      validationerr.hasErr = true;
      validationerr.password = {
        error: true,
        msg: 'Password must include at least one special character (e.g., ! @ # ?).'
      }
    }

    if (formInput.phone == '') {
      validationerr.hasErr = true;
      validationerr.phone = {
        error: true,
        msg: 'Phone is required'
      }
    }

    if (formInput.gender == '') {
      validationerr.hasErr = true;
      validationerr.gender = {
        error: true,
        msg: 'Gender is required'
      }
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  }

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      setProfilePicture(file);
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      uploadImage(file)
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      uploadImage(file)
    }
  };

  const uploadImage = async (file) => {
    if (!file)
      return false;
    const formData = new FormData()
    formData.append('file', file)
    try {
      let imageUploadRes = await uploadFile(formData, authState.authToken);
      if (imageUploadRes.success) {
        setAdminData({ ...adminData, profile_pic: imageUploadRes?.data?.upload_id })
        // toast.success(imageUploadRes.message, TOAST_CONFIG);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    } finally {

    }
  }

  const setFormData = (e) => {
    let newState = {
      ...adminData,
      [e.target.name]: e.target.value,
    };

    setAdminData(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
  };

  useEffect(() => {
    if (addAdminPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [addAdminPop]);

  const createAdmin = async () => {
    if (!handleValidation()) {
      toast.error('Please correct form fields', TOAST_CONFIG);
      return false;
    }

    setLoading(true);
    try {
      const request = {
        ...adminData,
        status:"active",
        // password: adminData.phone
      }
      const response = await createAdminUser(request, authState.authToken);
      setLoading(false);
      if (response.success) {
        resetPopup();
        toast.success(response.message, TOAST_CONFIG);
        if (typeof (setReloadList) != 'undefined') {
          setReloadList(true);
        }
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }

  }

  const resetPopup = () => {
    setImage(null);
    setAdminData({...initAdmin});
    setAddAdminPop(false);
    setGenderDrop(false);
  }

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: addAdminPop ? "flex" : "none" }}
        className="addResearcherPop"
      >
        <Box
          sx={{ display: addAdminPop ? "flex" : "none" }}
          className="addResearcherContaint"
        >
          <Typography className="addResearcherHeader">
            Add Super Admin
          </Typography>
          <Typography className="addResearcherLabel">
            Super Admin Full Name
          </Typography>
          <input
            className="addResearcherInput addResearcherInputName"
            placeholder="Enter the full name of the Super Admin"
            onChange={setFormData}
            value={adminData.name}
            name="name"
          />
          <span className="validationerr">{validationErrors.name.msg}</span>
          <Box
            sx={{ display:"flex" }}
            className="settingBox"
          >
            <Typography className="workspaceLabel">
                Profile Picture
            </Typography>

            <Box
              className="fileInputBox upload-area"
              onClick={() => {
                document.getElementById("fileInput").click();
              }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {image ? (
                <img className="fileinputimg" src={image} alt="Upload" />
              ) : (
                <img src={plassIcon} alt="Upload" />
              )}
              <input
                id="fileInput"
                style={{ display: "none" }}
                type="file"
                onChange={handleFileInputChange}
              />
            </Box>
            <Typography className="fileInputLabel">
              Click or drag image to this area to upload
            </Typography>
          </Box>

          <Typography className="addResearcherLabel">Email Address</Typography>
          <input
            className="addResearcherInput addResearcherInputName"
            placeholder="Enter Super Admin valid Email Address"
            onChange={setFormData}
            value={adminData.email}
            name="email"
          />
          <span className="validationerr">{validationErrors.email.msg}</span>
          <Typography className="addResearcherLabel">Password</Typography>
          <input
            className="addResearcherInput addResearcherInputName"
            placeholder="Enter Super Admin Password"
            onChange={setFormData}
            value={adminData.password}
            name="password"
          />
          <span className="validationerr">{validationErrors.password.msg}</span>

          <Box className="basicBox lastBasicBox">
            <Box className="inputBox">
              <Typography className="addResearcherLabel">
                Phone Number
              </Typography>
              <input
                className="addResearcherInput"
                placeholder="Enter Super Admin valid Phone Number"
                onChange={setFormData}
                value={adminData.phone}
                name="phone"
              />
              <span className="validationerr">{validationErrors.phone.msg}</span>
            </Box>
            <Box className="inputBox">
              <Typography className="addResearcherLabel">Gender</Typography>
              <input
                onClick={() => setGenderDrop(!genderDrop)}
                value={capitalizeEachWord(adminData.gender)}
                className="addResearcherInput genderInput"
                placeholder="Select Super Admin Gender"
              />
              <img
                style={{ rotate: genderDrop ? "180deg" : "0deg" }}
                onClick={() => setGenderDrop(!genderDrop)}
                className="downArrow"
                src={DownArrowIcon}
              />

              <Box
                sx={{ height: genderDrop ? "auto" : "0px" }}
                className="genderDrop"
              >
                <Box
                  onClick={() => {
                    setGenderDropVal("Male");
                    setGenderDrop(false);
                    setAdminData({ ...adminData, gender: "male" })
                  }}
                  className="genderDropItem"
                >
                  <Typography>Male</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setGenderDropVal("Female");
                    setGenderDrop(false);
                    setAdminData({ ...adminData, gender: "female" })
                  }}
                  className="genderDropItem"
                >
                  <Typography>Female</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setGenderDropVal("Other");
                    setGenderDrop(false);
                    setAdminData({ ...adminData, gender: "other" })
                  }}
                  className="genderDropItem"
                >
                  <Typography>Other</Typography>
                </Box>
              </Box>
              <span className="validationerr">{validationErrors.phone.msg}</span>
            </Box>
          </Box>
          <Typography className="addResearcherLabel">Address</Typography>
          <input
            className="addResearcherInput addResearcherInputName"
            placeholder="Enter Super Admin valid Address"
            onChange={setFormData}
            value={adminData.address_line1}
            name="address_line1"
          />

          <Box className="addResearcherPupBtnBox">
            <AppBtnOutLine
              buttonText="Cancel"
              onClick={resetPopup}
            />

            <AppBtn buttonText="Add" onClick={createAdmin} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
