import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import { Box, Typography } from "@material-ui/core";

import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

//images
import backArrow from "../../../Assets/Images/noun-back.png";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import groupsIcon from "../../../Assets/Images/groupsIcon.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";
import xCircel from "../../../Assets/Images/x-circle.png";
import redTrash from "../../../Assets/Images/redTrash.png";
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png";
import Pagination from "../../../Components/AppTools/Pagination";
//DATA
import FormControl from "@mui/material/FormControl";
import { fieldItem } from "../../../Assets/Data";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
//components
import { SearchBar } from "../../AppTools/AppTool";
import { AppBtnOutLine, AppBtn } from "../../AppTools/AppButton";
import AddVideoProfileField from "../AddVideoProfileField";
import AddDateProfileField from "../AddDateProfileField";
import { nlNL } from "@mui/x-date-pickers";
import AddImageProfileField from "../AddImageProfileField";
import AddOnDotProfileField from "../AddOnDotProfileField";
import AddTimeProfileField from "../AddTimeProfileField";

import SideMenu from "../../WSAdministration/SideMenu";
import { sideHaumIcon, profileFieldType } from "../../../Assets/Data";
import axios from "../../../lib/axios";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { createNewCustomField } from "../../../Services/researcherActions";
import { useAppSelector } from "../../../lib/store/store";
import { uploadFile } from "../../../Services/adminActions";
import AdminLayout from "../../../Pages/Admin/partials/AdminLayout";
import ResearcherLayout from "../../../Pages/Researchers/partials/ResearcherLayout";
import { renderUserLayout } from "../../../lib/helpers";
export default function AddProfileField({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
  participantsSubMenu,
  setParticipantsSubMenu,
  configurationSubMenu,
  setConfigurationSubMenu,
}) {
  const [filterDrop, setFilterDrop] = useState(false);
  const [filterDropVal, setFilterDropVal] = useState(0);
  const [isShowUser, setIsShowUser] = useState(false);

  const email2 = "Provide Instructions here";
  const [emailval, setEmailval] = useState();
  const [emailval2, setEmailval2] = useState();
  const [emailval3, setEmailval3] = useState();
  const [emailval4, setEmailval4] = useState();
  const [signatureTextLaOut, setSignatureTextLaOut] = useState("start");
  const [participantDrop, setParticipantDrop] = useState(false);
  const [participantDropVal, setParticipantDropVal] = useState(false);
  const [searchParticipantDrop, setSearchParticipantDrop] = useState(false);
  const [searchParticipantDropVal, setSearchParticipantDropVal] =
    useState(false);

  const [signatureTextItalic, setSignatureTextItalic] = useState(false);
  const [signatureTextBold, setSignatureTextBold] = useState(false);
  const [signatureTextUnderLine, setSignatureTextUnderLine] = useState();

  const [signatureTextItalic2, setSignatureTextItalic2] = useState(false);
  const [signatureTextBold2, setSignatureTextBold2] = useState(false);
  const [signatureTextUnderLine2, setSignatureTextUnderLine2] = useState();

  const [signatureTextItalic3, setSignatureTextItalic3] = useState(false);
  const [signatureTextBold3, setSignatureTextBold3] = useState(false);
  const [signatureTextUnderLine3, setSignatureTextUnderLine3] = useState();

  const [signatureTextItalic4, setSignatureTextItalic4] = useState(false);
  const [signatureTextBold4, setSignatureTextBold4] = useState(false);
  const [signatureTextUnderLine4, setSignatureTextUnderLine4] = useState();

  const [profileType, setProfileType] = useState();
  const [projectTabe, setProjectTabe] = useState(0);

  const [groupDrop, setGroupDrop] = useState(false);
  const [redio, setRedio] = useState(0);
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState(null);

  const [chooseCount, setChooseCount] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  const Navigate = useNavigate();

  const handleMainOpenTabe = (Url) => {
    Navigate(Url);
  };

  useEffect(() => {
    let selTab = searchParams.get("ps");

    if (selTab != null) {
      setFilterDropVal(parseInt(selTab));
    } else {
      setFilterDropVal(0);
    }
    // console.log("Query String Value:", filterDropVal);
    getGroups();
    getParticipants();
  }, []);

  const handelTextlineChekc = (p, input) => {
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine === "underline") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine === "line-through") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("line-through");
        }
      }
    }
  };
  const handelTextlineChekc2 = (p, input) => {
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine2 === "underline") {
          setSignatureTextUnderLine2(null);
          return;
        } else {
          setSignatureTextUnderLine2("underline");
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine2 === "line-through") {
          setSignatureTextUnderLine2(null);
          return;
        } else {
          setSignatureTextUnderLine2("line-through");
        }
      }
    }
  };
  const handelTextlineChekc3 = (p, input) => {
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine3 === "underline") {
          setSignatureTextUnderLine3(null);
          return;
        } else {
          setSignatureTextUnderLine3("underline");
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine3 === "line-through") {
          setSignatureTextUnderLine3(null);
          return;
        } else {
          setSignatureTextUnderLine3("line-through");
        }
      }
    }
  };
  const handelTextlineChekc4 = (p, input) => {
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine4 === "underline") {
          setSignatureTextUnderLine4(null);
          return;
        } else {
          setSignatureTextUnderLine4("underline");
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine4 === "line-through") {
          setSignatureTextUnderLine4(null);
          return;
        } else {
          setSignatureTextUnderLine4("line-through");
        }
      }
    }
  };

  const handelChange = (e, i) => {
    const { name, value } = e.target;
    if (i === 1) {
      setEmailval((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else if (i === 2) {
      setEmailval2((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else if (i === 3) {
      setEmailval3((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else if (i === 4) {
      setEmailval4((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const chackBoxRoom = () => {
    return (
      <>
        {allGroups &&
          allGroups.map((group) => (
            <Box className="checkBoxItem">
              <Box>
                <input
                  className="custom-checkbox"
                  type="checkBox"
                  // checked={redio === 0 ? true : false}
                />
                <Typography>{group.name}</Typography>
              </Box>
            </Box>
          ))}
      </>
    );
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setFileName(file.name);
      };
      reader.readAsDataURL(file);
      uploadImage(file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setFileName(file.name);
      };
      reader.readAsDataURL(file);
      uploadImage(file);
    }
  };

  const chunkArray = (arr, size) => {
    return arr.reduce((acc, _, index) => {
      if (index % size === 0) {
        acc.push(arr.slice(index, index + size));
      }
      return acc;
    }, []);
  };

  const renderComponents = (value) => {
    return customField.choices.map((choice, index) => (
      <ChooseSection
        key={index}
        count={index}
        choice={choice}
        handleChoice={handleChoice}
        handleDeleteAttribute={handleDeleteAttribute}
      />
    ));
  };

  const handleDeleteAttribute = (index) => {
    const choices = customField.choices.filter((_, idx) => idx !== index);
    setCustomField({ ...customField, choices });
  };
  const handleAddAttribute = () => {
    const choices = customField.choices;
    choices.push({
      name: "",
      // value: [],
    });
    setCustomField({ ...customField, choices });
    console.log("====CustomField:", customField);
  };

  const initCustomField = {
    question: "",
    field_name: "",
    groups: [],
    lebel: "Continue",
    style: "Tertiary",
    lebel2: "Continue",
    style2: "Tertiary",
    destination: "",
    destination_url: "",
    prompt_status: "Not Started",
    skip: false,
    field_type: fieldItem[filterDropVal]?.qstring,
    instruction: "",
    limit_participant_check: false,
    content_pii: false,
    word_count: {
      enforce_count_check: false,
      min: "",
      max: "",
    },
    choices: [
      {
        name: "",
        groups: [],
      },
    ],
    customize_prompt_check: false,
    allow_multiple_choice_check: false,
    is_randomize_choice_order: false,
    prompt: "",
    response_required_check: false,
    enforce_decimal_limit: false,
    decimal_limit: 0,
    date_range: {
      enforce_range_check: false,
      start: "",
      end: "",
    },
    timezone: "",
    limit_qty: "",
    allow_vdo_desc_check: false,
    make_desc_check: false,
    limit_participant: [],
  };

  // My Code
  const [customField, setCustomField] = useState(initCustomField);
  const [allParticipantsList, setAllParticipantsList] = useState([]);
  const authState = useAppSelector((state) => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace);
  const setFormData = (e) => {
    let newState = {
      ...customField,
      [e.target.name]: e.target.value,
    };

    setCustomField(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
  };

  const validationObj = {
    hasErr: false,
    question: {
      error: false,
      msg: "",
    },
  };

  const handleParticipantsChange = async (e) => {
    const selectedValue = e.target.value;
    setCustomField((prevGroup) => ({
      ...prevGroup,
      [e.target.name]: selectedValue,
    }));
  };

  const getParticipants = async () => {
    return await axios
      .get(
        `/super-admin/admin/participants?workspace=${workspaceState?.selectedWorkspace?._id}`
      )
      .then((res) => {
        if (res.status) {
          setAllParticipantsList(res.data.data);
        }
      });
  };

  const handleChoice = (e, index, name, groups) => {
    const { choices } = customField;
    const updatedChoice = { ...choices[index], name, groups };
    const updatedChoices = choices.map((choice, idx) =>
      idx === index ? updatedChoice : choice
    );
    setCustomField({ ...customField, choices: updatedChoices });
  };

  const [validationErrors, setValidationErrors] = useState({
    ...validationObj,
  });
  const [allGroups, setAllGroups] = useState([]);

  const handleValidation = (formInput) => {
    formInput = formInput || customField;
    let validationerr = { ...validationObj };

    if (formInput.question == "") {
      validationerr.hasErr = true;
      validationerr.question = {
        error: true,
        msg: "Question is required",
      };
    }

    if (formInput.field_name == "") {
      validationerr.hasErr = true;
      validationerr.field_name = {
        error: true,
        msg: "Field name is required",
      };
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  };

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 1,
    totalPages: 0,
    totalItems: 0,
  });

  const onPageChange = (pageNum) => {
    getGroups(pageNum);
  };

  const getGroups = async (curPage = 1) => {
    const url = `/participant/groups?page=${curPage}&limit=9`;
    const res = await axios.get(url);
    if (res.status) {
      if (res.data.data.length > 0) {
        const groups = res.data.data;
        const setGroups = groups
          .filter((el) => el.is_enhanced == true)
          .map((group) => ({
            name: group.name,
            _id: group._id,
          }));

        setAllGroups(setGroups);
        setPaginationData({
          currentPage: res.data.pagination.currentPage,
          itemsPerPage: res.data.pagination.itemsPerPage,
          totalPages: res.data.pagination.totalPages,
          totalItems: res.data.pagination.totalItems,
        });
      }
    }
  };

  const resetObject = () => {
    setCustomField(initCustomField);
    setFileName(null);
    setImage(null);
  };

  const ChooseSection = ({
    count,
    choice = { name: "", groups: [] }, // Provide default values
    handleChoice,
    handleDeleteAttribute,
  }) => {
    const [openDropdown, setOpenDropdown] = useState(false);
    const [groups, setGroups] = useState(choice.groups || []);
    const [chName, setChName] = useState(choice.name);

    useEffect(() => {
      setChName(choice.name || "");
    }, [choice.name]);

    useEffect(() => {
      setGroups(choice.groups || []);
    }, [choice.groups]);

    const handleBlur = (e) => {
      handleChoice(e, count, chName, groups);
      if (e.target.value.length === 1) {
        e.target.blur();
      }
    };

    const handleGroupChange = (e) => {
      const selectedGroups = e.target.value;
      setGroups(selectedGroups);
      handleChoice(e, count, chName, selectedGroups);
    };

    const handleTextChange = (e) => {
      const newName = e.target.value;
      setChName(newName);
    };

    console.log("======chName====:", chName);

    return (
      <>
        <p style={{ marginTop: "20px", marginBottom: "10px" }}>
          Choice {count + 1}
        </p>
        <Box className="choiceInputBox">
          <input
            className="phoneInput"
            placeholder={`Enter choice ${count + 1}`}
            value={chName}
            onChange={handleTextChange}
            onBlur={handleBlur}
          />
          <img className="xCircel" src={xCircel} alt="Cancel" />
          <img
            className="redTrash"
            src={redTrash}
            onClick={() => handleDeleteAttribute(count)}
            alt="Delete"
          />
        </Box>

        <Box className="searchGroupSection">
          <FormControl sx={{ width: "100%" }} size="small">
            <Select
              labelId="demo-select-small-label11"
              id="demo-select-small11"
              value={groups}
              onChange={handleGroupChange}
              name="groups"
              multiple
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <p>Select Groups</p>;
                }
                return selected
                  .map((value) => {
                    const group = allGroups.find((grp) => grp._id === value);
                    return group ? group.name : value;
                  })
                  .join(", ");
              }}
            >
              {allGroups.map((grp, index) => (
                <MenuItem value={grp._id} key={index}>
                  {grp.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </>
    );
  };

  const uploadImage = async (file) => {
    if (!file) return false;
    const formData = new FormData();
    formData.append("file", file);
    try {
      let imageUploadRes = await uploadFile(formData, authState.authToken);
      if (imageUploadRes.success) {
        setCustomField({
          ...customField,
          file: imageUploadRes?.data?.upload_id,
        });
        // toast.success(imageUploadRes.message, TOAST_CONFIG);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    } finally {
    }
  };
  const createCustomFiled = async () => {
    if (!handleValidation()) {
      toast.error("Please correct form fields", TOAST_CONFIG);
      return false;
    }

    // setLoading(true);
    try {
      const allowTypes = ["registration", "my_account", "in_projct", "hidden"];
      const payload = {
        ...customField,
        type: allowTypes.includes(searchParams.get("type"))
          ? searchParams.get("type")
          : "registration",
        workspace_id: workspaceState?.selectedWorkspace?._id,
      };
      // return console.log("payload", payload);
      const companyRes = await createNewCustomField(
        payload,
        authState.authToken
      );
      // setLoading(false);
      if (companyRes.success) {
        toast.success(companyRes.message, TOAST_CONFIG);
        resetObject();
        handleMainOpenTabe("/workspace-administration/participant-profiling");
      } else {
        toast.error(companyRes.message, TOAST_CONFIG);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      // setLoading(false);
    }
  };

  const getChildren = () => {
    return (
      <Box className="researchersMainBox pageContainer">
        <Box className="addProfileFieldBox workspaceListBox">
          <Box
            mb={2}
            onClick={() =>
              handleMainOpenTabe(
                "/workspace-administration/participant-profiling"
              )
            }
            className="openBtn"
          >
            <img src={backArrow} />
            <Typography className="neonBackText">Back</Typography>
          </Box>
          <Typography className="pageHeaderText">Add Profile Field</Typography>

          <Box mt={5} className="csPBox">
            <Typography className="allPartRedioLabel">Question</Typography>
            <input
              style={{ marginTop: "16px" }}
              className="phoneInput"
              placeholder="Enter your question here"
              name="question"
              onChange={setFormData}
              value={customField.question}
            />
            <span className="validationerr">
              {validationErrors.question?.msg}
            </span>
          </Box>

          <Box mt={4} className="searchbox">
            <Box mr={2} width={"20%"}>
              <Box
                className="inputAppDrop"
                onClick={() => setFilterDrop(!filterDrop)}
              >
                <Box className="fieldValBox">
                  {fieldItem[filterDropVal]?.icon}
                  <Typography>
                    {fieldItem[filterDropVal]?.field || "Filter by Type"}
                  </Typography>
                </Box>
                <img
                  style={{
                    rotate: filterDrop ? "180deg" : "0deg",
                  }}
                  src={DownArrowIcon}
                />
                {/* pop */}
                <Box
                  sx={{ height: filterDrop ? "auto" : "0px" }}
                  className="statusPop"
                >
                  {fieldItem &&
                    fieldItem?.map((el, index) => (
                      <Box
                        key={index}
                        onClick={() => {
                          setSearchParams({
                            ps: index,
                            type: searchParams.get("type"),
                          });
                          setFilterDropVal(index);
                          resetObject();
                          setCustomField({
                            ...customField,
                            field_type: el.qstring.replaceAll("_", "-"),
                          });
                          console.log(fieldItem[filterDropVal]?.field);
                        }}
                        className="statusPopItem"
                      >
                        {el.icon}
                        <Typography>{el.field}</Typography>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
            <Box width={"80%"}>
              {/* <SearchBar placeholder="Search Fields" /> */}
              <input
                // style={{ marginTop: "16px" }}
                className="phoneInput"
                placeholder="Enter your field name here"
                name="field_name"
                onChange={setFormData}
                value={customField.field_name}
              />
              <span className="validationerr">
                {validationErrors.field_name?.msg}
              </span>
            </Box>
          </Box>

          {/* field Box */}
          {/* Poll section 1*/}
          <Box
            sx={{
              display:
                fieldItem[filterDropVal]?.field === "Poll" ? "flex" : "none",
            }}
            mt={5}
            className="fieldBox"
          >
            <Typography className="eTemPopLabel">Instructions</Typography>
            <Box className="emailInputBox">
              <Box className="emailInputBoxNav">
                <Box
                  onClick={() => setSignatureTextItalic(!signatureTextItalic)}
                  className="emailImgBox"
                >
                  <img src={IIcon} />
                </Box>
                <Box
                  onClick={() => setSignatureTextBold(!signatureTextBold)}
                  className="emailImgBox"
                >
                  <img src={BIcon} />
                </Box>
                <Box
                  onClick={() => handelTextlineChekc("underline", "signature")}
                  className="emailImgBox"
                >
                  <img src={UnderLineU} />
                </Box>
                <Box
                  onClick={() =>
                    handelTextlineChekc("line-through", "signature")
                  }
                  className="emailImgBox"
                >
                  <img src={MLineU} />
                </Box>
                <Box className="emailImgBox">
                  <img src={AtachMentIcon} />
                </Box>
                <Box className="emailImgBox">
                  <img src={Qbox} />
                </Box>
                <Box
                  onClick={() => setSignatureTextLaOut("start")}
                  className="emailImgBox"
                >
                  <img src={Line1} />
                </Box>
                <Box
                  onClick={() => setSignatureTextLaOut("center")}
                  className="emailImgBox"
                >
                  <img src={Line2} />
                </Box>
                <Box
                  onClick={() => setSignatureTextLaOut("end")}
                  className="emailImgBox"
                >
                  <img src={Line3} />
                </Box>
                <Box className="emailImgBox">
                  <img src={Line4} />
                </Box>
                <Box className="emailImgBox">
                  <img src={emoji} />
                </Box>
              </Box>
              <textarea
                style={{
                  textAlign: signatureTextLaOut,
                  fontStyle: signatureTextItalic ? "italic" : "normal",
                  fontWeight: signatureTextBold ? "bold" : "normal",
                  textDecoration: signatureTextUnderLine,
                }}
                className="emailInput"
                name="instruction"
                value={customField?.instruction}
                onChange={(e) => {
                  handelChange(e, 1);
                  setFormData(e);
                }}
              />
            </Box>
            <Typography className="allPartRedioLabel">Choices</Typography>
            {renderComponents(chooseCount)}

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                margin: "24px 0",
              }}
            >
              <Typography className="ppundText" onClick={handleAddAttribute}>
                Add Choice
              </Typography>
              {/* <Typography className="ppundText">Paste Bulk Choices</Typography> */}
            </Box>
            <p style={{ marginBottom: "24px" }} className="allPartRedioLabel">
              Poll Settings
            </p>
            <Box className="checkWlabel">
              <input
                className="custom-checkbox"
                type="checkBox"
                onClick={(el) =>
                  setCustomField({
                    ...customField,
                    allow_multiple_choice_check: el.target.checked,
                  })
                }
              />
              <Typography>Allow multiple selection of choices</Typography>
            </Box>
            <Box className="checkWlabel">
              <input
                className="custom-checkbox"
                type="checkBox"
                onClick={(el) =>
                  setCustomField({
                    ...customField,
                    is_randomize_choice_order: el.target.checked,
                  })
                }
              />
              <Typography>Randomize choice order</Typography>
            </Box>
            <p
              style={{ marginTop: "56px", marginBottom: "24px" }}
              className="allPartRedioLabel"
            >
              Advance Settings
            </p>
            <Box className="checkWlabel">
              <input
                className="custom-checkbox"
                type="checkBox"
                checked={customField?.limit_participant_check}
                onClick={(el) => {
                  setCustomField({
                    ...customField,
                    limit_participant_check: el.target.checked,
                  });
                  setIsShowUser(true);
                  if (el.target.checked === false) {
                    setCustomField((prevGroup) => ({
                      ...prevGroup,
                      limit_participant: [],
                    }));
                    setIsShowUser(false);
                  }
                }}
              />
              <Typography>Limit who can complete this field</Typography>
            </Box>

            <Box className="searchGroupSection">
              <Box className="internalInputBox">
                {isShowUser && (
                  <FormControl sx={{ width: "100%" }} size="small">
                    <Select
                      labelId="demo-select-small-label11"
                      id="demo-select-small11"
                      value={customField.limit_participant}
                      onChange={handleParticipantsChange}
                      name="limit_participant"
                      multiple
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <p>Select Participants</p>;
                        }
                        return selected
                          .map((value) => {
                            const user = allParticipantsList.find(
                              (user) => user._id === value
                            );
                            return user ? user.name : value;
                          })
                          .join(", ");
                      }}
                    >
                      {allParticipantsList.map((user, index) => (
                        <MenuItem value={user._id} key={index}>
                          {user.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {/* <Box
                className="inputAppDrop"
                onClick={() =>
                  setSearchParticipantDrop(!searchParticipantDrop)
                }
              >
                <Typography>
                  {searchParticipantDropVal || "Search Participant"}
                </Typography>
                <img
                  style={{
                    rotate: searchParticipantDrop ? "180deg" : "0deg",
                  }}
                  src={dropArrow}
                />
                <Box
                  sx={{ height: searchParticipantDrop ? "auto" : "0px" }}
                  className="statusPop"
                >
                  <Box
                    onClick={() =>
                      setSearchParticipantDropVal("Participant A")
                    }
                    className="statusPopItem"
                  >
                    <Typography>Participant 1</Typography>
                  </Box>
                  <Box
                    onClick={() =>
                      setSearchParticipantDropVal("Participant B")
                    }
                    className="statusPopItem"
                  >
                    <Typography>Participant 2</Typography>
                  </Box>
                </Box>
              </Box> */}
              </Box>
            </Box>

            <Box className="checkWlabel">
              <input
                className="custom-checkbox"
                type="checkBox"
                checked={customField?.content_pii}
                onClick={(el) =>
                  setCustomField({
                    ...customField,
                    content_pii: el.target.checked,
                  })
                }
              />
              <Typography>
                May contain personal identifiable information (PII)
              </Typography>
            </Box>
          </Box>

          {/* Plain Text section 2*/}
          <Box
            sx={{
              display:
                fieldItem[filterDropVal]?.field === "Plain Text"
                  ? "flex"
                  : "none",
            }}
            mt={5}
            className="fieldBox"
          >
            <Typography className="eTemPopLabel">Instructions</Typography>
            <Box className="emailInputBox">
              <Box className="emailInputBoxNav">
                <Box
                  onClick={() => setSignatureTextItalic2(!signatureTextItalic2)}
                  className="emailImgBox"
                >
                  <img src={IIcon} />
                </Box>
                <Box
                  onClick={() => setSignatureTextBold2(!signatureTextBold2)}
                  className="emailImgBox"
                >
                  <img src={BIcon} />
                </Box>
                <Box
                  onClick={() => handelTextlineChekc2("underline", "signature")}
                  className="emailImgBox"
                >
                  <img src={UnderLineU} />
                </Box>
                <Box
                  onClick={() =>
                    handelTextlineChekc2("line-through", "signature")
                  }
                  className="emailImgBox"
                >
                  <img src={MLineU} />
                </Box>
                <Box className="emailImgBox">
                  <img src={AtachMentIcon} />
                </Box>
                <Box className="emailImgBox">
                  <img src={Qbox} />
                </Box>
                <Box
                  onClick={() => setSignatureTextLaOut("start")}
                  className="emailImgBox"
                >
                  <img src={Line1} />
                </Box>
                <Box
                  onClick={() => setSignatureTextLaOut("center")}
                  className="emailImgBox"
                >
                  <img src={Line2} />
                </Box>
                <Box
                  onClick={() => setSignatureTextLaOut("end")}
                  className="emailImgBox"
                >
                  <img src={Line3} />
                </Box>
                <Box className="emailImgBox">
                  <img src={Line4} />
                </Box>
                <Box className="emailImgBox">
                  <img src={emoji} />
                </Box>
              </Box>
              <textarea
                style={{
                  textAlign: signatureTextLaOut,
                  fontStyle: signatureTextItalic2 ? "italic" : "normal",
                  fontWeight: signatureTextBold2 ? "bold" : "normal",
                  textDecoration: signatureTextUnderLine2,
                }}
                className="emailInput"
                name="instruction"
                value={customField?.instruction}
                onChange={(e) => {
                  handelChange(e, 2);
                  setFormData(e);
                }}
              />
            </Box>

            <Box
              className="mediaInputbox upload-area"
              onClick={() => {
                document.getElementById("fileInput").click();
              }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {image ? <img src={image} /> : <img src={uploadIcon} />}

              <Typography>
                {fileName
                  ? fileName
                  : "Click or drag media files to this area to upload"}
              </Typography>
              <input
                id="fileInput"
                style={{ display: "none" }}
                type="file"
                onChange={handleFileInputChange}
              />
            </Box>

            <p style={{ marginBottom: "24px" }} className="allPartRedioLabel">
              Basic Settings
            </p>
            <Box className="checkWlabel">
              <input
                className="custom-checkbox"
                type="checkBox"
                checked={customField?.word_count?.enforce_count_check}
                onClick={(el) =>
                  setCustomField({
                    ...customField,
                    word_count: {
                      ...customField.word_count,
                      enforce_count_check: el.target.checked,
                    },
                  })
                }
              />
              <Typography>Enforce word count</Typography>
            </Box>
            <Box ml={4} className="enforceInputBox">
              <input
                value={customField.word_count.min}
                className="phoneInput"
                onChange={(el) => {
                  setCustomField({
                    ...customField,
                    word_count: {
                      ...customField.word_count,
                      min: el.target.value,
                    },
                  });
                  console.log(el.target.value);
                }}
              />
              <p>to</p>
              <input
                value={customField.word_count.max}
                className="phoneInput"
                onChange={(el) =>
                  setCustomField({
                    ...customField,
                    word_count: {
                      ...customField.word_count,
                      max: el.target.value,
                    },
                  })
                }
              />
              <p>words</p>
            </Box>
            <Box className="checkWlabel">
              <input
                className="custom-checkbox"
                type="checkBox"
                checked={customField?.customize_prompt_check}
                onClick={(el) =>
                  setCustomField({
                    ...customField,
                    customize_prompt_check: el.target.checked,
                  })
                }
              />
              <Typography>Customize prompt</Typography>
            </Box>
            <Box className="wsCpInputBOx">
              <input
                type="text"
                className="phoneInput customizePromptInput"
                placeholder="This text will appear just above the response text box"
                value={customField.prompt}
                onChange={(el) => {
                  setCustomField({
                    ...customField,
                    prompt: el.target.value,
                  });
                }}
              />
            </Box>
            <Box className="checkWlabel">
              <input
                className="custom-checkbox"
                type="checkBox"
                checked={customField?.response_required_check}
                onClick={(el) =>
                  setCustomField({
                    ...customField,
                    response_required_check: el.target.checked,
                  })
                }
              />
              <Typography>Response Required</Typography>
            </Box>

            <p
              style={{ marginTop: "56px", marginBottom: "24px" }}
              className="allPartRedioLabel"
            >
              Advance Settings
            </p>
            <Box className="checkWlabel">
              <input
                className="custom-checkbox"
                type="checkBox"
                checked={customField?.limit_participant_check}
                onClick={(el) => {
                  setCustomField({
                    ...customField,
                    limit_participant_check: el.target.checked,
                  });
                  setIsShowUser(true);
                  if (el.target.checked === false) {
                    setCustomField((prevGroup) => ({
                      ...prevGroup,
                      limit_participant: [],
                    }));
                    setIsShowUser(false);
                  }
                }}
              />
              <Typography>Limit who can complete this field</Typography>
            </Box>

            <Box className="searchGroupSection">
              <Box className="internalInputBox">
                {isShowUser && (
                  <FormControl sx={{ width: "100%" }} size="small">
                    <Select
                      labelId="demo-select-small-label11"
                      id="demo-select-small11"
                      value={customField.limit_participant}
                      onChange={handleParticipantsChange}
                      name="limit_participant"
                      multiple
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <p>Select Participants</p>;
                        }
                        return selected
                          .map((value) => {
                            const user = allParticipantsList.find(
                              (user) => user._id === value
                            );
                            return user ? user.name : value;
                          })
                          .join(", ");
                      }}
                    >
                      {allParticipantsList.map((user, index) => (
                        <MenuItem value={user._id} key={index}>
                          {user.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {/* <Box
                className="inputAppDrop"
                onClick={() =>
                  setSearchParticipantDrop(!searchParticipantDrop)
                }
              >
                <Typography>
                  {searchParticipantDropVal || "Search Participant"}
                </Typography>
                <img
                  style={{
                    rotate: searchParticipantDrop ? "180deg" : "0deg",
                  }}
                  src={dropArrow}
                />
                <Box
                  sx={{ height: searchParticipantDrop ? "auto" : "0px" }}
                  className="statusPop"
                >
                  <Box
                    onClick={() =>
                      setSearchParticipantDropVal("Participant A")
                    }
                    className="statusPopItem"
                  >
                    <Typography>Participant 1</Typography>
                  </Box>
                  <Box
                    onClick={() =>
                      setSearchParticipantDropVal("Participant B")
                    }
                    className="statusPopItem"
                  >
                    <Typography>Participant 2</Typography>
                  </Box>
                </Box>
              </Box> */}
              </Box>
            </Box>
            <Box className="checkWlabel">
              <input
                className="custom-checkbox"
                type="checkBox"
                checked={customField?.content_pii}
                onClick={(el) =>
                  setCustomField({
                    ...customField,
                    content_pii: el.target.checked,
                  })
                }
              />
              <Typography>
                May contain personal identifiable information (PII)
              </Typography>
            </Box>
          </Box>

          {/* Formatted Text 3*/}
          <Box
            sx={{
              display:
                fieldItem[filterDropVal]?.field === "Formatted Text"
                  ? "flex"
                  : "none",
            }}
            mt={5}
            className="fieldBox"
          >
            <Typography className="eTemPopLabel">Instructions</Typography>
            <Box className="emailInputBox">
              <Box className="emailInputBoxNav">
                <Box
                  onClick={() => setSignatureTextItalic3(!signatureTextItalic3)}
                  className="emailImgBox"
                >
                  <img src={IIcon} />
                </Box>
                <Box
                  onClick={() => setSignatureTextBold3(!signatureTextBold3)}
                  className="emailImgBox"
                >
                  <img src={BIcon} />
                </Box>
                <Box
                  onClick={() => handelTextlineChekc3("underline", "signature")}
                  className="emailImgBox"
                >
                  <img src={UnderLineU} />
                </Box>
                <Box
                  onClick={() =>
                    handelTextlineChekc3("line-through", "signature")
                  }
                  className="emailImgBox"
                >
                  <img src={MLineU} />
                </Box>
                <Box className="emailImgBox">
                  <img src={AtachMentIcon} />
                </Box>
                <Box className="emailImgBox">
                  <img src={Qbox} />
                </Box>
                <Box
                  onClick={() => setSignatureTextLaOut("start")}
                  className="emailImgBox"
                >
                  <img src={Line1} />
                </Box>
                <Box
                  onClick={() => setSignatureTextLaOut("center")}
                  className="emailImgBox"
                >
                  <img src={Line2} />
                </Box>
                <Box
                  onClick={() => setSignatureTextLaOut("end")}
                  className="emailImgBox"
                >
                  <img src={Line3} />
                </Box>
                <Box className="emailImgBox">
                  <img src={Line4} />
                </Box>
                <Box className="emailImgBox">
                  <img src={emoji} />
                </Box>
              </Box>
              <textarea
                style={{
                  textAlign: signatureTextLaOut,
                  fontStyle: signatureTextItalic3 ? "italic" : "normal",
                  fontWeight: signatureTextBold3 ? "bold" : "normal",
                  textDecoration: signatureTextUnderLine3,
                }}
                className="emailInput"
                name="instruction"
                value={customField?.instruction}
                onChange={(e) => {
                  handelChange(e, 2);
                  setFormData(e);
                }}
              />
            </Box>

            <Box
              className="mediaInputbox upload-area"
              onClick={() => {
                document.getElementById("fileInput2").click();
              }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {image ? <img src={image} /> : <img src={uploadIcon} />}
              <Typography>
                {fileName
                  ? fileName
                  : "Click or drag media files to this area to upload"}
              </Typography>
              <input
                id="fileInput2"
                style={{ display: "none" }}
                type="file"
                onChange={handleFileInputChange}
              />
            </Box>

            <p style={{ marginBottom: "24px" }} className="allPartRedioLabel">
              Basic Settings
            </p>
            <Box className="checkWlabel">
              <input
                className="custom-checkbox"
                type="checkBox"
                checked={customField?.word_count?.enforce_count_check}
                onClick={(el) =>
                  setCustomField({
                    ...customField,
                    word_count: {
                      ...customField.word_count,
                      enforce_count_check: el.target.checked,
                    },
                  })
                }
              />
              <Typography>Enforce word count</Typography>
            </Box>
            <Box ml={4} className="enforceInputBox">
              <input
                value={customField.word_count.min}
                className="phoneInput"
                onChange={(el) =>
                  setCustomField({
                    ...customField,
                    word_count: {
                      ...customField.word_count,
                      min: el.target.value,
                    },
                  })
                }
              />
              <p>to</p>
              <input
                value={customField.word_count.max}
                className="phoneInput"
                onChange={(el) =>
                  setCustomField({
                    ...customField,
                    word_count: {
                      ...customField.word_count,
                      max: el.target.value,
                    },
                  })
                }
              />
              <p>words</p>
            </Box>
            <Box className="checkWlabel">
              <input
                className="custom-checkbox"
                type="checkBox"
                checked={customField?.customize_prompt_check}
                onClick={(el) =>
                  setCustomField({
                    ...customField,
                    customize_prompt_check: el.target.checked,
                  })
                }
              />
              <Typography>Customize prompt</Typography>
            </Box>
            <Box className="wsCpInputBOx">
              <input
                type="text"
                className="phoneInput customizePromptInput"
                placeholder="This text will appear just above the response text box"
                value={customField.prompt}
                onChange={(el) => {
                  setCustomField({
                    ...customField,
                    prompt: el.target.value,
                  });
                  console.log(el.target.value);
                }}
              />
            </Box>
            <Box className="checkWlabel">
              <input
                className="custom-checkbox"
                type="checkBox"
                checked={customField?.response_required_check}
                onClick={(el) =>
                  setCustomField({
                    ...customField,
                    response_required_check: el.target.checked,
                  })
                }
              />
              <Typography>Response Required</Typography>
            </Box>

            <p
              style={{ marginTop: "56px", marginBottom: "24px" }}
              className="allPartRedioLabel"
            >
              Advance Settings
            </p>
            <Box className="checkWlabel">
              <input
                className="custom-checkbox"
                type="checkBox"
                checked={customField?.limit_participant_check}
                onClick={(el) => {
                  setCustomField({
                    ...customField,
                    limit_participant_check: el.target.checked,
                  });
                  setIsShowUser(true);
                  if (el.target.checked === false) {
                    setCustomField((prevGroup) => ({
                      ...prevGroup,
                      limit_participant: [],
                    }));
                    setIsShowUser(false);
                  }
                }}
              />
              <Typography>Limit who can complete this field</Typography>
            </Box>

            <Box className="searchGroupSection">
              <Box className="internalInputBox">
                {isShowUser && (
                  <FormControl sx={{ width: "100%" }} size="small">
                    <Select
                      labelId="demo-select-small-label11"
                      id="demo-select-small11"
                      value={customField.limit_participant}
                      onChange={handleParticipantsChange}
                      name="limit_participant"
                      multiple
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <p>Select Participants</p>;
                        }
                        return selected
                          .map((value) => {
                            const user = allParticipantsList.find(
                              (user) => user._id === value
                            );
                            return user ? user.name : value;
                          })
                          .join(", ");
                      }}
                    >
                      {allParticipantsList.map((user, index) => (
                        <MenuItem value={user._id} key={index}>
                          {user.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {/* <Box
                className="inputAppDrop"
                onClick={() =>
                  setSearchParticipantDrop(!searchParticipantDrop)
                }
              >
                <Typography>
                  {searchParticipantDropVal || "Search Participant"}
                </Typography>
                <img
                  style={{
                    rotate: searchParticipantDrop ? "180deg" : "0deg",
                  }}
                  src={dropArrow}
                />
                <Box
                  sx={{ height: searchParticipantDrop ? "auto" : "0px" }}
                  className="statusPop"
                >
                  <Box
                    onClick={() =>
                      setSearchParticipantDropVal("Participant A")
                    }
                    className="statusPopItem"
                  >
                    <Typography>Participant 1</Typography>
                  </Box>
                  <Box
                    onClick={() =>
                      setSearchParticipantDropVal("Participant B")
                    }
                    className="statusPopItem"
                  >
                    <Typography>Participant 2</Typography>
                  </Box>
                </Box>
              </Box> */}
              </Box>
            </Box>
            <Box className="checkWlabel">
              <input
                className="custom-checkbox"
                type="checkBox"
                checked={customField?.content_pii}
                onClick={(el) =>
                  setCustomField({
                    ...customField,
                    content_pii: el.target.checked,
                  })
                }
              />
              <Typography>
                May contain personal identifiable information (PII)
              </Typography>
            </Box>
          </Box>

          {/* Number section 4*/}
          <Box
            sx={{
              display:
                fieldItem[filterDropVal]?.field === "Number" ? "flex" : "none",
            }}
            mt={5}
            className="fieldBox"
          >
            <Typography className="eTemPopLabel">Instructions</Typography>
            <Box className="emailInputBox">
              <Box className="emailInputBoxNav">
                <Box
                  onClick={() => setSignatureTextItalic4(!signatureTextItalic4)}
                  className="emailImgBox"
                >
                  <img src={IIcon} />
                </Box>
                <Box
                  onClick={() => setSignatureTextBold4(!signatureTextBold4)}
                  className="emailImgBox"
                >
                  <img src={BIcon} />
                </Box>
                <Box
                  onClick={() => handelTextlineChekc4("underline", "signature")}
                  className="emailImgBox"
                >
                  <img src={UnderLineU} />
                </Box>
                <Box
                  onClick={() =>
                    handelTextlineChekc4("line-through", "signature")
                  }
                  className="emailImgBox"
                >
                  <img src={MLineU} />
                </Box>
                <Box className="emailImgBox">
                  <img src={AtachMentIcon} />
                </Box>
                <Box className="emailImgBox">
                  <img src={Qbox} />
                </Box>
                <Box
                  onClick={() => setSignatureTextLaOut("start")}
                  className="emailImgBox"
                >
                  <img src={Line1} />
                </Box>
                <Box
                  onClick={() => setSignatureTextLaOut("center")}
                  className="emailImgBox"
                >
                  <img src={Line2} />
                </Box>
                <Box
                  onClick={() => setSignatureTextLaOut("end")}
                  className="emailImgBox"
                >
                  <img src={Line3} />
                </Box>
                <Box className="emailImgBox">
                  <img src={Line4} />
                </Box>
                <Box className="emailImgBox">
                  <img src={emoji} />
                </Box>
              </Box>
              <textarea
                style={{
                  textAlign: signatureTextLaOut,
                  fontStyle: signatureTextItalic4 ? "italic" : "normal",
                  fontWeight: signatureTextBold4 ? "bold" : "normal",
                  textDecoration: signatureTextUnderLine4,
                }}
                className="emailInput"
                name="instruction"
                value={customField?.instruction}
                onChange={(e) => {
                  handelChange(e, 2);
                  setFormData(e);
                }}
              />
            </Box>

            <Box
              className="mediaInputbox upload-area"
              onClick={() => {
                document.getElementById("fileInput2").click();
              }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {image ? <img src={image} /> : <img src={uploadIcon} />}
              <Typography>
                {fileName
                  ? fileName
                  : "Click or drag media files to this area to upload"}
              </Typography>
              <input
                id="fileInput2"
                style={{ display: "none" }}
                type="file"
                onChange={handleFileInputChange}
              />
            </Box>

            <p style={{ marginBottom: "24px" }} className="allPartRedioLabel">
              Basic Settings
            </p>
            <Box className="checkWlabel">
              <input
                className="custom-checkbox"
                type="checkBox"
                checked={customField?.word_count?.enforce_count_check}
                onClick={(el) =>
                  setCustomField({
                    ...customField,
                    word_count: {
                      ...customField.word_count,
                      enforce_count_check: el.target.checked,
                    },
                  })
                }
              />
              <Typography>Enforce Number Range</Typography>
            </Box>
            <Box ml={4} className="enforceInputBox">
              <input
                value={customField.word_count.min}
                className="phoneInput"
                onChange={(el) =>
                  setCustomField({
                    ...customField,
                    word_count: {
                      ...customField.word_count,
                      min: el.target.value,
                    },
                  })
                }
              />
              <p>to</p>
              <input
                value={customField.word_count.max}
                className="phoneInput"
                onChange={(el) =>
                  setCustomField({
                    ...customField,
                    word_count: {
                      ...customField.word_count,
                      max: el.target.value,
                    },
                  })
                }
              />
              <p>words</p>
            </Box>
            <Box className="checkWlabel">
              <input
                className="custom-checkbox"
                type="checkBox"
                checked={customField?.enforce_decimal_limit}
                onClick={(el) =>
                  setCustomField({
                    ...customField,
                    enforce_decimal_limit: el.target.checked,
                  })
                }
              />
              <Typography>Enforce Decimal Limit</Typography>
            </Box>
            <Box ml={4} className="enforceInputBox">
              <p>Limit Decimal to</p>
              <input
                value={customField.decimal_limit}
                className="phoneInput"
                onChange={(el) => {
                  setCustomField({
                    ...customField,
                    decimal_limit: el.target.value,
                  });
                }}
              />
              <p>Places</p>
            </Box>
            <Box className="checkWlabel">
              <input
                className="custom-checkbox"
                type="checkBox"
                checked={customField?.customize_prompt_check}
                onClick={(el) =>
                  setCustomField({
                    ...customField,
                    customize_prompt_check: el.target.checked,
                  })
                }
              />
              <Typography>Customize prompt</Typography>
            </Box>
            <Box className="wsCpInputBOx">
              <input
                type="text"
                className="phoneInput customizePromptInput"
                placeholder="This text will appear just above the response text box"
                value={customField.prompt}
                onChange={(el) => {
                  setCustomField({
                    ...customField,
                    prompt: el.target.value,
                  });
                }}
              />
            </Box>
            <Box className="checkWlabel">
              <input
                className="custom-checkbox"
                type="checkBox"
                checked={customField?.response_required_check}
                onClick={(el) =>
                  setCustomField({
                    ...customField,
                    response_required_check: el.target.checked,
                  })
                }
              />
              <Typography>Response Required</Typography>
            </Box>
            <p
              style={{ marginTop: "56px", marginBottom: "24px" }}
              className="allPartRedioLabel"
            >
              Advance Settings
            </p>
            <Box className="checkWlabel">
              <input
                className="custom-checkbox"
                type="checkBox"
                checked={customField?.limit_participant_check}
                onClick={(el) => {
                  setCustomField({
                    ...customField,
                    limit_participant_check: el.target.checked,
                  });
                  setIsShowUser(true);
                  if (el.target.checked === false) {
                    setCustomField((prevGroup) => ({
                      ...prevGroup,
                      limit_participant: [],
                    }));
                    setIsShowUser(false);
                  }
                }}
              />
              <Typography>Limit who can complete this field</Typography>
            </Box>

            <Box className="searchGroupSection">
              <Box className="internalInputBox">
                {isShowUser && (
                  <FormControl sx={{ width: "100%" }} size="small">
                    <Select
                      labelId="demo-select-small-label11"
                      id="demo-select-small11"
                      value={customField.limit_participant}
                      onChange={handleParticipantsChange}
                      name="limit_participant"
                      multiple
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <p>Select Participants</p>;
                        }
                        return selected
                          .map((value) => {
                            const user = allParticipantsList.find(
                              (user) => user._id === value
                            );
                            return user ? user.name : value;
                          })
                          .join(", ");
                      }}
                    >
                      {allParticipantsList.map((user, index) => (
                        <MenuItem value={user._id} key={index}>
                          {user.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {/* <Box
                className="inputAppDrop"
                onClick={() =>
                  setSearchParticipantDrop(!searchParticipantDrop)
                }
              >
                <Typography>
                  {searchParticipantDropVal || "Search Participant"}
                </Typography>
                <img
                  style={{
                    rotate: searchParticipantDrop ? "180deg" : "0deg",
                  }}
                  src={dropArrow}
                />
                <Box
                  sx={{ height: searchParticipantDrop ? "auto" : "0px" }}
                  className="statusPop"
                >
                  <Box
                    onClick={() =>
                      setSearchParticipantDropVal("Participant A")
                    }
                    className="statusPopItem"
                  >
                    <Typography>Participant 1</Typography>
                  </Box>
                  <Box
                    onClick={() =>
                      setSearchParticipantDropVal("Participant B")
                    }
                    className="statusPopItem"
                  >
                    <Typography>Participant 2</Typography>
                  </Box>
                </Box>
              </Box> */}
              </Box>
            </Box>
            <Box className="checkWlabel">
              <input
                className="custom-checkbox"
                type="checkBox"
                checked={customField?.content_pii}
                onClick={(el) =>
                  setCustomField({
                    ...customField,
                    content_pii: el.target.checked,
                  })
                }
              />
              <Typography>
                May contain personal identifiable information (PII)
              </Typography>
            </Box>
          </Box>

          {fieldItem[filterDropVal]?.field === "Video" ? (
            <AddVideoProfileField
              filterDropVal={filterDropVal}
              setFilterDropVal={setFilterDropVal}
              setCustomField={setCustomField}
              customField={customField}
              isShowUser={isShowUser}
              setIsShowUser={setIsShowUser}
              handleParticipantsChange={handleParticipantsChange}
              allParticipantsList={allParticipantsList}
            />
          ) : fieldItem[filterDropVal]?.field === "Date" ? (
            <AddDateProfileField
              filterDropVal={filterDropVal}
              setFilterDropVal={setFilterDropVal}
              setCustomField={setCustomField}
              customField={customField}
              isShowUser={isShowUser}
              setIsShowUser={setIsShowUser}
              handleParticipantsChange={handleParticipantsChange}
              allParticipantsList={allParticipantsList}
            />
          ) : fieldItem[filterDropVal]?.field === "Image" ? (
            <AddImageProfileField
              filterDropVal={filterDropVal}
              setFilterDropVal={setFilterDropVal}
              setCustomField={setCustomField}
              customField={customField}
              isShowUser={isShowUser}
              setIsShowUser={setIsShowUser}
              handleParticipantsChange={handleParticipantsChange}
              allParticipantsList={allParticipantsList}
            />
          ) : fieldItem[filterDropVal]?.field === "On-the-Dot" ? (
            <AddOnDotProfileField
              filterDropVal={filterDropVal}
              setFilterDropVal={setFilterDropVal}
              setCustomField={setCustomField}
              customField={customField}
              isShowUser={isShowUser}
              setIsShowUser={setIsShowUser}
              handleParticipantsChange={handleParticipantsChange}
              allParticipantsList={allParticipantsList}
              allGroups={allGroups}
            />
          ) : fieldItem[filterDropVal]?.field === "Timestamp" ? (
            <AddTimeProfileField
              filterDropVal={filterDropVal}
              setFilterDropVal={setFilterDropVal}
              setCustomField={setCustomField}
              customField={customField}
              isShowUser={isShowUser}
              setIsShowUser={setIsShowUser}
              handleParticipantsChange={handleParticipantsChange}
              allParticipantsList={allParticipantsList}
            />
          ) : null}

          <Box className="workPupBtnBox">
            <AppBtnOutLine
              buttonText="Cancel"
              onClick={() =>
                handleMainOpenTabe(
                  "/workspace-administration/participant-profiling"
                )
              }
            />
            <AppBtn
              buttonText="Add Profile Field"
              onClick={createCustomFiled}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return renderUserLayout(
    authState?.userType,
    null,
    getChildren(),
    "workadmin"
  );
}
