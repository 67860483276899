import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import "./style.css";
//images
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import lineOption from "../../../Assets/Images/Option2LIcon.png"


//data
import { timeZone, countryList } from "../../../Assets/Data";


//component
import { Box, Typography, FormLabel, } from '@mui/material';
import ReactFlagsSelect from "react-flags-select";
import { Status, AntSwitch } from '../../AppTools/AppTool';
import { AppBtn, AppBtnOutLine } from '../../AppTools/AppButton';
import BasicConLangPop from "../BasicConLangPop"
import DefaultLanguagePop from '../DefaultLanguagePop';


export default function ProjectBasicConfiguration() {
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedLanguage2, setSelectedLanguage2] = useState("");
  const [timeZoneDrop, setTimeZoneDrop] = useState(false)
  const [timeZoneVal, setTimeZoneVal] = useState("IST (Indian Standard Time): 5:01 PM");
  const [homeDrop, setHomeDrop] = useState(false)
  const [homeDropVal, setHomeDropVal] = useState("home")
  const homeItem = ["item1", "item2", "item3"]
  const [basicLangPop, setBasicLangPop] = useState(false)
  const [defaultLanguagePop, setDefaultLanguagePop] = useState(false);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  return (
    <>
      <Box className="BconfgPage">
        <BasicConLangPop basicLangPop={basicLangPop} setBasicLangPop={setBasicLangPop} />
        <DefaultLanguagePop defaultLanguagePop={defaultLanguagePop} setDefaultLanguagePop={setDefaultLanguagePop} />
        <Typography className='pageHeaderText'>{t("projectBasicConfiguration.basicConfigurationHead")}</Typography>
        <Typography className='pageSubHeader'>{t("projectBasicConfiguration.projectLanguageSubHead")}</Typography>

        <Box className="lang_Box">
          <Box className="FormFiled">
            <Box className="langLabel">
              <Typography>{t("projectBasicConfiguration.defaultLanguageLabel")}</Typography>
              <span onClick={() => setDefaultLanguagePop(true)}>{t("commonTxt.change")}</span>
            </Box>
            <ReactFlagsSelect
              className="SelectLanguage"
              selected={selectedLanguage}
              onSelect={(code) => setSelectedLanguage(code)}
              placeholder={t("projectBasicConfiguration.defaultLanguagePlaceholder")}
            />
          </Box>
          <Box className="FormFiled">
            <Box className="langLabel">
              <Typography>{t("projectBasicConfiguration.secondaryLanguageLabel")}</Typography>
              <span onClick={() => setBasicLangPop(true)}>{t("commonTxt.change")}</span>
            </Box>
            <input className='phoneInput' value="21 Selected" />
          </Box>
        </Box>

        <Typography className='pageSubHeader'>{t("projectBasicConfiguration.timeZoneHead")}</Typography>
        <Typography>{t("projectBasicConfiguration.timeZoneText")}</Typography>



        <Box className="lang_Box">
          <Box className="FormFiled">
            <Box className="langLabel">
              <Typography>{t("projectBasicConfiguration.selectCountryLabel")}</Typography>
              <span>{t("commonTxt.change")}</span>
            </Box>
            <ReactFlagsSelect
              className="SelectLanguage"
              selected={selectedLanguage2}
              onSelect={(code) => setSelectedLanguage2(code)}
              placeholder={t("projectBasicConfiguration.selectCountryPlaceholder")}
            />
          </Box>

          <Box className="companyInputBoxWlabel FormFiled">
            <Typography mb={1} className="inputLabel">{t("projectBasicConfiguration.selectTimeZoneLabel")}</Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setTimeZoneDrop(!timeZoneDrop)}
            >
              <Typography>
                {timeZoneVal || t("projectBasicConfiguration.selectTimeZonePlaceholder")}
              </Typography>
              <img
                style={{
                  rotate: timeZoneDrop ? "180deg" : "0deg",
                }}
                src={DownArrowIcon}
              />
              {/* pop */}
              <Box
                sx={{ height: timeZoneDrop ? "auto" : "0px" }}
                className="statusPop"
              >
                {timeZone &&
                  timeZone?.map((el, index) => (
                    <Box
                      key={index}
                      onClick={() => setTimeZoneVal(el)}
                      className="statusPopItem"
                    >
                      <Typography>{el}</Typography>
                    </Box>
                  ))
                }

              </Box>
            </Box>
          </Box>
        </Box>


        <Typography className='pageSubHeader'>{t("projectBasicConfiguration.primaryTabHead")}</Typography>
        <Box mb={5} sx={{ width: "36%" }}>
          <Status label=" " itemAry={homeItem} setStatusDrop={setHomeDrop} statusDrop={homeDrop} setStatusDropVal={setHomeDropVal} statusDropVal={homeDropVal} />
        </Box>

        <Typography className='pageSubHeader'>{t("projectBasicConfiguration.projectFeaturesHead")}</Typography>

        <Box className="featuresSwitchBox">
          <Box className="feSwitchItem">
            <Box>
              <AntSwitch />
              <Typography>{t("projectBasicConfiguration.homeLabel")}</Typography>
            </Box>
            <img src={lineOption} />
          </Box>

          <Box className="feSwitchItem">
            <Box>
              <AntSwitch />
              <Typography>{t("projectBasicConfiguration.activitiesLabel")}</Typography>
            </Box>
            <img src={lineOption} />
          </Box>

          <Box className="feSwitchItem">
            <Box>
              <AntSwitch />
              <Typography>{t("projectBasicConfiguration.conversationLabel")}</Typography>
            </Box>
            <img src={lineOption} />
          </Box>

          <Box className="feSwitchItem">
            <Box>
              <AntSwitch />
              <Typography>{t("projectBasicConfiguration.recapLabel")}</Typography>
            </Box>
            <img src={lineOption} />
          </Box>

        </Box>



        <Typography className='pageSubHeader'>{t("projectBasicConfiguration.activityGuidanceHead")}</Typography>
        <Box className="pbcRedioBox">

          <Box className="pbcRedioRow">
            <Box>
              <input
                className="emailSettingInput emailSettingRadio"
                type='radio'
                name='projectState'
                defaultChecked
              />
              <Typography>{t("projectBasicConfiguration.activityGuidanceRadio1")}</Typography>
            </Box>
            <Box>
              <input
                className="emailSettingInput emailSettingRadio"
                type='radio'
                name='projectState'
                defaultChecked
              />
              <Typography>{t("projectBasicConfiguration.activityGuidanceRadio2")}</Typography>
            </Box>
          </Box>


          <Box className="pbcRedioRow">
            <Box>
              <input
                className="emailSettingInput emailSettingRadio"
                type='radio'
                name='projectState'
                defaultChecked
              />
              <Typography>{t("projectBasicConfiguration.activityGuidanceRadio3")}</Typography>
            </Box>
          </Box>

        </Box>


        <Typography className='pageSubHeader'>{t("projectBasicConfiguration.ratingsHead")}</Typography>
        <Box className="pbcRedioBox">

          <Box className="pbcRedioRow">
            <Box>
              <input
                className="emailSettingInput emailSettingRadio"
                type='radio'
                name='projectState'
                defaultChecked
              />
              <Typography>{t("projectBasicConfiguration.ratingsRadio1")}</Typography>
            </Box>
            <Box>
              <input
                className="emailSettingInput emailSettingRadio"
                type='radio'
                name='projectState'
                defaultChecked
              />
              <Typography>{t("projectBasicConfiguration.ratingsRadio2")}</Typography>
            </Box>
          </Box>


          <Box className="pbcRedioRow">
            <Box>
              <input
                className="emailSettingInput emailSettingRadio"
                type='radio'
                name='projectState'
                defaultChecked
              />
              <Typography>{t("projectBasicConfiguration.ratingsRadio3")}</Typography>
            </Box>
          </Box>

        </Box>


        <Typography className='pageSubHeader'>{t("projectBasicConfiguration.conversationHead")}</Typography>

        <Box className="pbcRedioBox checkBoxINput">

          <Box className="pbcRedioRow">
            <Box>
              <input
                className="emailSettingInput emailSettingRadio"
                type='checkBox'
                name='projectState'
                defaultChecked
              />
              <Typography>{t("projectBasicConfiguration.conversationCheckbox1")}</Typography>
            </Box>
            <Box>
              <input
                className="emailSettingInput emailSettingRadio"
                type='checkBox'
                name='projectState'
                defaultChecked
              />
              <Typography>{t("projectBasicConfiguration.conversationCheckbox2")}</Typography>
            </Box>
          </Box>
          <Box className="pbcRedioRow">
            <Box>
              <input
                className="emailSettingInput emailSettingRadio"
                type='checkBox'
                name='projectState'
                defaultChecked
              />
              <Typography>{t("projectBasicConfiguration.conversationCheckbox3")}</Typography>
            </Box>
            <Box>
              <input
                className="emailSettingInput emailSettingRadio"
                type='checkBox'
                name='projectState'
                defaultChecked
              />
              <Typography>{t("projectBasicConfiguration.conversationCheckbox4")}</Typography>
            </Box>
          </Box>
        </Box>
        <Box className="workPupBtnBox">
          <AppBtnOutLine buttonText={t("commonTxt.resetBtn")} /> <AppBtn buttonText={t("commonTxt.saveBtn")} />
        </Box>
      </Box>
    </>
  )
}
