import React, { useEffect, useState } from "react";
import "./style.css";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import whitedropIcon from "../../../Assets/Images/Wchevron-down.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";
import exportIcon from "../../../Assets/Images/ExpoGroup.png";
import dropIcon from "../../../Assets/Images/chevron-downp.png";
import printIcon from "../../../Assets/Images/printIcon.png";
import arrow from "../../../Assets/Images/arrow.png";
import videoIcon from "../../../Assets/Images/videoBlackIcon.png";
import cottIcon from "../../../Assets/Images/cottBlackIcon.png";
import clanderIcon from "../../../Assets/Images/calendarBlackIcon.png";
import Avatar2 from "../../../Assets/Images/inb3.png";
import idelDot from "../../../Assets/Images/idelDot.png";
import video1 from "../../../Assets/Images/Videos/part1.mp4";

//component
import { AppBtn, AppBtnOutLine, PurplelBtn } from "../../AppTools/AppButton";
import { ApexChart } from "../../../Components/AppTools/PieChart";
import { Status } from "../../AppTools/AppTool";
import StackedBar from "../../../Components/AppTools/StackedBar";
import Slider from "@mui/material/Slider";
import StackedBar2 from "../../../Components/AppTools/StackedBar2";
import SideLineChart from "../../../Components/AppTools/SideLineChart";
import SizeAndChurn from "../PRSizeAndChurn";
import RecapSection from "../PRRecap";

//data
import ProjectParticipantReport from "../ProjectParticipantReport";
import ProjectConversationReport from "../ProjectConversationReport";
import ProjectPointsPieChartReport from "../ProjectPointsPieChartReport";
import ProjectTaskReport from "../ProjectTaskReport";
import ProjectActionLogReport from "../ProjectActionLogReport";
import {
  ParticipantData,
  participant5Data,
  activityCompData,
  summarizedData,
  repActivitiesData,
  mediaTypeData,
  SummarizedData,
  reportDropVal,
} from "../../../Assets/Data";

export default function ProjectSettingReports({
  dataReportTabe,
  setDataReportTabe,
}) {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const [projectSettingDrop, setProjectSettingDrop] = useState(false);
  const [projectSettingDropVal, setProjectSettingDropVal] =
    useState("Size & Churn");

  const [rangeDrop, setRangeDrop] = useState(false);
  const [rangeDropVal, setRangeDropVal] = useState("All Time");

  const [contributionDrop, setContributionDrop] = useState(false);
  const [contributionDropVal, setContributionDropVal] = useState("All Types");

  const [exportDrop2, setExportDrop2] = useState(false);
  const [exportDrop3, setExportDrop3] = useState(false);
  const [identitiesDrop, setIdentitiesDrop] = useState(false);
  const [identitiesDropVal, setIdentitiesDropVal] = useState();
  const [identitiesDrop2, setIdentitiesDrop2] = useState(false);
  const [identitiesDropVal2, setIdentitiesDropVal2] = useState();
  const [identitiesDrop3, setIdentitiesDrop3] = useState(false);
  const [identitiesDropVal3, setIdentitiesDropVal3] = useState();

  const [rangeDrop2, setRangeDrop2] = useState(false);
  const [rangeDropVal2, setRangeDropVal2] = useState("All Time");

  const [contributionDrop2, setContributionDrop2] = useState(false);
  const [contributionDropVal2, setContributionDropVal2] = useState("All Types");

  const [rangeDrop3, setRangeDrop3] = useState(false);
  const [rangeDropVal3, setRangeDropVal3] = useState("All Time");

  const [contributionDrop3, setContributionDrop3] = useState(false);
  const [contributionDropVal3, setContributionDropVal3] = useState("All Types");

  const [contributionDrop4, setContributionDrop4] = useState(false);
  const [contributionDropVal4, setContributionDropVal4] = useState("All Types");

  const [activityDrop, setActivityDrop] = useState(false);
  const [activityDropVal, setActivityDropVal] = useState("All Activities");
  const [currentPage, setCurrentPage] = useState(0);

  const [excerptsFdrop1, setExcerptsFdrop1] = useState(false);
  const [excerptsFdropVal1, setExcerptsFdropVal1] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const excerptsFdrop1Data = [
    "Activities",
    "Tasks",
    "Conversations",
    "Inactive Community Members",
  ];
  const mediaType = ["Video", "Audio", "Image"];
  const contributionType = ["Activities", "Tasks", "Conversations"];
  const dateRange = ["All Time"];

  const [excerptsFdrop2, setExcerptsFdrop2] = useState(false);
  const [excerptsFdropVal2, setExcerptsFdropVal2] = useState();

  const [excerptsFdrop3, setExcerptsFdrop3] = useState(false);
  const [excerptsFdropVal3, setExcerptsFdropVal3] = useState();
  const excerptsFdrop3Data = [
    "All Types",
    "Only Activities",
    "Only Conversations",
    "Only Messages",
  ];

  const [excerptsFdrop4, setExcerptsFdrop4] = useState(false);
  const [excerptsFdropVal4, setExcerptsFdropVal4] = useState();

  const [excerptsFdrop5, setExcerptsFdrop5] = useState(false);
  const [excerptsFdropVal5, setExcerptsFdropVal5] = useState();

  const [excerptsFdrop6, setExcerptsFdrop6] = useState(false);
  const [excerptsFdropVal6, setExcerptsFdropVal6] = useState();

  const [excerptsFdrop7, setExcerptsFdrop7] = useState(false);
  const [excerptsFdropVal7, setExcerptsFdropVal7] = useState();

  const [excerptsFdrop8, setExcerptsFdrop8] = useState(false);
  const [excerptsFdropVal8, setExcerptsFdropVal8] = useState();

  const dropVal = [
    "Size & Churn",
    "Composition",
    "Invitation Rates",
    "Participation",
    "Excerpts",
    "Activities",
    "Tasks",
    "Conversation",
    "Media",
    "Points",
    "Recap",
    "Action Log",
  ];

  const exportItem = ["PNG", "JPEG", "PDF", "SVG"];

  const indentitiesData = ["Full Name", "Username", "Unique ID"];

  const genderData = [
    {
      gender: "Male",
      Number: "122",
      Percentage: "40%",
    },
    {
      gender: "Female",
      Number: "326",
      Percentage: "60%",
    },
  ];

  const ageData = [
    {
      AgeGroup: "10-20",
      Number: "620",
      Percentage: "40%",
    },
    {
      AgeGroup: "21-30",
      Number: "120",
      Percentage: "20%",
    },
    {
      AgeGroup: "21-30",
      Number: "120",
      Percentage: "20%",
    },
  ];

  const ContinentData = [
    {
      Continent: "Asia",
      Number: "620",
      Percentage: "40%",
    },
    {
      Continent: "European",
      Number: "120",
      Percentage: "20%",
    },
    {
      Continent: "North America",
      Number: "120",
      Percentage: "20%",
    },
  ];

  useEffect(() => {
    let selTab = searchParams.get("t");
    let selTab2 = searchParams.get("dr");
    if (selTab && selTab2 != null) {
      var query_string = selTab;
      query_string = query_string.replaceAll("_", " ");
      query_string = query_string.replace(/(^\w|\s\w)/g, (firstCharOfWord) =>
        firstCharOfWord.toUpperCase()
      );
      console.log(query_string);
      if (selTab === "size_churn") {
        var query_string = "Size & Churn";
        setProjectSettingDropVal(query_string);
        setDataReportTabe(parseInt(selTab2));
      } else {
        setProjectSettingDropVal(query_string);
        setDataReportTabe(parseInt(selTab2));
      }
    } else if (selTab == null) {
      setDataReportTabe(parseInt(selTab2));
    }
  }, []);

  return (
    <>
      <Box className="pageContainer reportsPage">
        {/*----------------------------------- Size & Churn ------------------------------ */}
        <SizeAndChurn
          dataReportTabe={dataReportTabe}
          setDataReportTabe={setDataReportTabe}
          dropVal={dropVal}
          setProjectSettingDropVal={setProjectSettingDropVal}
          projectSettingDropVal={projectSettingDropVal}
          setProjectSettingDrop={setProjectSettingDrop}
          projectSettingDrop={projectSettingDrop}
          setRangeDrop={setRangeDrop}
          rangeDrop={rangeDrop}
          setRangeDropVal={setRangeDropVal}
          rangeDropVal={rangeDropVal}
          setContributionDrop={setContributionDrop}
          contributionDrop={contributionDrop}
          setContributionDropVal={setContributionDropVal}
          contributionDropVal={contributionDropVal}
          setExportDrop2={setExportDrop2}
          exportDrop2={exportDrop2}
          exportItem={exportItem}
        />

        {/* ---------------------------------------Recap section ---------------------------------- */}
        <RecapSection
          dataReportTabe={dataReportTabe}
          setDataReportTabe={setDataReportTabe}
          dropVal={dropVal}
          setProjectSettingDropVal={setProjectSettingDropVal}
          projectSettingDropVal={projectSettingDropVal}
          setProjectSettingDrop={setProjectSettingDrop}
          projectSettingDrop={projectSettingDrop}
          setRangeDrop={setRangeDrop}
          rangeDrop={rangeDrop}
          setRangeDropVal={setRangeDropVal}
          rangeDropVal={rangeDropVal}
          setContributionDrop={setContributionDrop}
          contributionDrop={contributionDrop}
          setContributionDropVal={setContributionDropVal}
          contributionDropVal={contributionDropVal}
          setExportDrop2={setExportDrop2}
          exportDrop2={exportDrop2}
          exportItem={exportItem}
        />

        {/* -------------------------------------Composition start----------------------------- */}
        <Box
          sx={{
            display: projectSettingDropVal === "Composition" ? "block" : "none",
          }}
          className="reportContainer integrationsContainer compositionSection"
        >
          <Box className="projectBtnBox">
            <Box className="inteBtndropBox">
              <Box
                className="projectManagementNavBtn"
                onClick={() => setProjectSettingDrop(!projectSettingDrop)}
              >
                <Typography className="DropBtnText">
                  {projectSettingDropVal || "Project State"}
                </Typography>
                <img
                  className="dropIcon"
                  src={whitedropIcon}
                  style={{
                    rotate: projectSettingDrop ? "180deg" : "0deg",
                    transition: "all 0.5s",
                    marginLeft: "10px",
                  }}
                />
                <Box
                  className="workspaceItemNavPop projectItemNavPop"
                  sx={{ display: projectSettingDrop ? "flex" : "none" }}
                >
                  {reportDropVal?.map((el, i) => (
                    <Box
                      key={i}
                      onClick={() => {
                        setSearchParams({ dr: dataReportTabe, t: el.qstring });
                        setProjectSettingDropVal(el.title);
                      }}
                    >
                      <Typography>{el.title}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Typography className="pageHeaderText">
            {t("projectParticipantReport.headerTxt")}
          </Typography>
          <Box className="participationReportDropSection">
            <Box className="inputBoxWlabel">
              <Typography>
                {t("projectParticipantReport.dateRangeDrop")}
              </Typography>
              <Box
                className="inputAppDrop"
                onClick={() => setRangeDrop2(!rangeDrop2)}
              >
                <Typography>
                  {rangeDropVal2 ||
                    t("projectParticipantReport.dateRangeDropPlaceholder")}
                </Typography>
                <img
                  style={{ rotate: rangeDrop2 ? "180deg" : "0deg" }}
                  src={dropArrow}
                />
                {/* pop */}
                <Box
                  sx={{ height: rangeDrop2 ? "auto" : "0px" }}
                  className="statusPop"
                >
                  <Box
                    onClick={() => setRangeDropVal2("All Time")}
                    className="statusPopItem"
                  >
                    <Typography>
                      {t("projectParticipantReport.dateRangeDropVal1")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className="inputBoxWlabel">
              <Typography>
                {t("projectParticipantReport.contributionTypeDrop")}
              </Typography>
              <Box
                className="inputAppDrop"
                onClick={() => setContributionDrop2(!contributionDrop2)}
              >
                <Typography>
                  {contributionDropVal2 ||
                    t(
                      "projectParticipantReport.contributionTypeDropPlaceholder"
                    )}
                </Typography>
                <img
                  style={{ rotate: contributionDrop2 ? "180deg" : "0deg" }}
                  src={dropArrow}
                />
                {/* pop */}
                <Box
                  sx={{ height: contributionDrop2 ? "auto" : "0px" }}
                  className="statusPop"
                >
                  <Box
                    onClick={() => setContributionDropVal2("All Types")}
                    className="statusPopItem"
                  >
                    <Typography>
                      {t("projectParticipantReport.contributionTypeDropVal1")}
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => setContributionDropVal2("Subscribed")}
                    className="statusPopItem"
                  >
                    <Typography>
                      {t("projectParticipantReport.contributionTypeDropVal2")}
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => setContributionDropVal2("Unsubscribed")}
                    className="statusPopItem"
                  >
                    <Typography>
                      {t("projectParticipantReport.contributionTypeDropVal3")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box mt={5} className="redioGroupSection">
            <Typography className="psSubLabel">
              {t("projectParticipantReport.subHeaderTxt2")}
            </Typography>

            <Box className="projectRadioBoxSection">
              <Box className="ProjectRadioInput">
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                />
                <Typography className="emailSettingLabel">
                  {t("projectParticipantReport.participantAllParticipant")}
                </Typography>
              </Box>
              <Box className="ProjectRadioInput">
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                />
                <Typography className="emailSettingLabel">
                  {t("projectParticipantReport.participantSelectGroup")}
                </Typography>
              </Box>
            </Box>

            <Box className="projectRadioBoxSection">
              <Box className="ProjectRadioInput">
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                />
                <Typography className="emailSettingLabel">
                  {t("projectParticipantReport.participantSelectParticipant")}
                </Typography>
              </Box>
            </Box>

            <Box className="participantIdentities">
              <Typography>
                {t("projectParticipantReport.participantIdentity")}
              </Typography>
              <Status
                itemAry={indentitiesData}
                setStatusDrop={setIdentitiesDrop}
                statusDrop={identitiesDrop}
                setStatusDropVal={setIdentitiesDropVal}
                statusDropVal={identitiesDropVal}
                label={t(
                  "projectParticipantReport.participantIdentityPlaceholder"
                )}
              />
            </Box>
          </Box>
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.resetBtn")} />
            <AppBtn buttonText={t("commonTxt.viewReportBtn")} />
          </Box>
        </Box>

        <Box
          sx={{
            display: projectSettingDropVal === "Composition" ? "block" : "none",
          }}
          mt={5}
          className="integrationsContainer"
        >
          <Box className="tabelNav">
            <Box className="dropWheader">
              <Typography className="pageHeaderText">
                {t("projectParticipantReport.headerTxt2")}
              </Typography>
            </Box>
            <Box className="repPrintBtnBox">
              <Box className="exporOpenBtn">
                <img src={printIcon} />
                <Typography className="verifyLink">
                  {t("commonTxt.printLink")}
                </Typography>
              </Box>
              <Box className="exporOpenBtn">
                <img src={exportIcon} />
                <Typography className="verifyLink">
                  {t("commonTxt.exportLink")}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box mt={6} className="tabelNav">
            <Box className="dropWheader">
              <Typography className="pageHeaderText">
                {t("CompositionReport.demograpgyGenderHead")}
              </Typography>
            </Box>
            <Box className="repPrintBtnBox">
              <Box className="exporOpenBtn">
                <img src={printIcon} />
                <Typography className="verifyLink">
                  {t("commonTxt.printLink")}
                </Typography>
              </Box>
              <Box className="exporOpenBtn">
                <img src={exportIcon} />
                <Typography className="verifyLink">
                  {t("commonTxt.exportLink")}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box className="pieBox">
            <ApexChart />
          </Box>

          <Box mb={6} className="workListBox">
            <Box className="haderRow" minWidth={"955px"}>
              <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                <Typography>{t("CompositionReport.tblGender")}</Typography>
              </Box>
              <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                <Typography>{t("CompositionReport.tblNumber")}</Typography>
              </Box>
              <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                <Typography>{t("CompositionReport.tblPercentage")}</Typography>
              </Box>
            </Box>

            {genderData?.map((el, i) => (
              <Box className="TabelRow" minWidth={"955px"}>
                <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.gender}</Typography>
                </Box>
                <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Number}</Typography>
                </Box>
                <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Percentage}</Typography>
                </Box>
              </Box>
            ))}
          </Box>

          <Box mb={3} className="tabelNav">
            <Box className="dropWheader">
              <Typography className="pageHeaderText">
                {t("CompositionReport.demograpgyAgeHead")}
              </Typography>
            </Box>
            <Box className="repPrintBtnBox">
              <Box className="exporOpenBtn">
                <img src={printIcon} />
                <Typography className="verifyLink">
                  {t("commonTxt.printLink")}
                </Typography>
              </Box>
              <Box className="exporOpenBtn">
                <img src={exportIcon} />
                <Typography className="verifyLink">
                  {t("commonTxt.exportLink")}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box className="pieBox">
            <ApexChart />
          </Box>

          <Box mb={6} className="workListBox">
            <Box className="haderRow" minWidth={"955px"}>
              <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                <Typography>{t("CompositionReport.tblAgeGroup")}</Typography>
              </Box>
              <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                <Typography>{t("CompositionReport.tblNumber")}</Typography>
              </Box>
              <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                <Typography>{t("CompositionReport.tblPercentage")}</Typography>
              </Box>
            </Box>

            {ageData?.map((el, i) => (
              <Box className="TabelRow" minWidth={"955px"}>
                <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.AgeGroup}</Typography>
                </Box>
                <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Number}</Typography>
                </Box>
                <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Percentage}</Typography>
                </Box>
              </Box>
            ))}
          </Box>

          <Box mb={3} className="tabelNav">
            <Box className="dropWheader">
              <Typography className="pageHeaderText">
                {t("CompositionReport.demograpgyLocationHead")}
              </Typography>
              <Box className="ndrop continentDrop">
                <Typography>{t("CompositionReport.continentDrop")}</Typography>
                <img src={dropIcon} />
              </Box>
            </Box>
            <Box className="repPrintBtnBox">
              <Box className="exporOpenBtn">
                <img src={printIcon} />
                <Typography className="verifyLink">
                  {t("commonTxt.printLink")}
                </Typography>
              </Box>
              <Box className="exporOpenBtn">
                <img src={exportIcon} />
                <Typography className="verifyLink">
                  {t("commonTxt.exportLink")}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box className="pieBox">
            <ApexChart />
          </Box>

          <Box mb={6} className="workListBox">
            <Box className="haderRow" minWidth={"955px"}>
              <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                <Typography>{t("CompositionReport.tblContinent")}</Typography>
              </Box>
              <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                <Typography>{t("CompositionReport.tblNumber")}</Typography>
              </Box>
              <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                <Typography>{t("CompositionReport.tblPercentage")}</Typography>
              </Box>
            </Box>

            {ContinentData?.map((el, i) => (
              <Box className="TabelRow" minWidth={"955px"}>
                <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Continent}</Typography>
                </Box>
                <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Number}</Typography>
                </Box>
                <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Percentage}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        {/* -------------------------------------Composition end----------------------------- */}

        {/* -----------------------------------------------Invitation Rates start-------------------------------------- */}
        <Box
          sx={{
            display:
              projectSettingDropVal === "Invitation Rates" ? "block" : "none",
          }}
          className="reportContainer integrationsContainer compositionSection"
        >
          <Box className="projectBtnBox">
            <Box className="inteBtndropBox">
              <Box
                className="projectManagementNavBtn"
                onClick={() => setProjectSettingDrop(!projectSettingDrop)}
              >
                <Typography className="DropBtnText">
                  {projectSettingDropVal || "Project State"}
                </Typography>
                <img
                  className="dropIcon"
                  src={whitedropIcon}
                  style={{
                    rotate: projectSettingDrop ? "180deg" : "0deg",
                    transition: "all 0.5s",
                    marginLeft: "10px",
                  }}
                />
                <Box
                  className="workspaceItemNavPop projectItemNavPop"
                  sx={{ display: projectSettingDrop ? "flex" : "none" }}
                >
                  {reportDropVal?.map((el, i) => (
                    <Box
                      key={i}
                      onClick={() => {
                        setSearchParams({ dr: dataReportTabe, t: el.qstring });
                        setProjectSettingDropVal(el.title);
                      }}
                    >
                      <Typography>{el.title}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Typography className="pageHeaderText">
            {t("projectParticipantReport.headerTxt")}
          </Typography>
          <Box className="participationReportDropSection">
            <Box className="inputBoxWlabel">
              <Typography>
                {t("projectParticipantReport.dateRangeDrop")}
              </Typography>
              <Box
                className="inputAppDrop"
                onClick={() => setRangeDrop3(!rangeDrop3)}
              >
                <Typography>
                  {rangeDropVal3 ||
                    t("projectParticipantReport.dateRangeDropPlaceholder")}
                </Typography>
                <img
                  style={{ rotate: rangeDrop3 ? "180deg" : "0deg" }}
                  src={dropArrow}
                />
                {/* pop */}
                <Box
                  sx={{ height: rangeDrop3 ? "auto" : "0px" }}
                  className="statusPop"
                >
                  <Box
                    onClick={() => setRangeDropVal3("All Time")}
                    className="statusPopItem"
                  >
                    <Typography>
                      {t("projectParticipantReport.dateRangeDropVal1")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className="inputBoxWlabel">
              <Typography>
                {t("projectParticipantReport.contributionTypeDrop")}
              </Typography>
              <Box
                className="inputAppDrop"
                onClick={() => setContributionDrop3(!contributionDrop3)}
              >
                <Typography>
                  {contributionDropVal3 ||
                    t(
                      "projectParticipantReport.contributionTypeDropPlaceholder"
                    )}
                </Typography>
                <img
                  style={{ rotate: contributionDrop3 ? "180deg" : "0deg" }}
                  src={dropArrow}
                />
                {/* pop */}
                <Box
                  sx={{ height: contributionDrop3 ? "auto" : "0px" }}
                  className="statusPop"
                >
                  <Box
                    onClick={() => setContributionDropVal3("All Types")}
                    className="statusPopItem"
                  >
                    <Typography>
                      {t("projectParticipantReport.contributionTypeDropVal1")}
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => setContributionDropVal3("Subscribed")}
                    className="statusPopItem"
                  >
                    <Typography>
                      {t("projectParticipantReport.contributionTypeDropVal2")}
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => setContributionDropVal3("Unsubscribed")}
                    className="statusPopItem"
                  >
                    <Typography>
                      {t("projectParticipantReport.contributionTypeDropVal3")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box mt={5} className="redioGroupSection">
            <Typography className="psSubLabel">
              {t("invitationRateReport.participantInvitationRateHead")}
            </Typography>

            <Box my={2} className="projectRadioBoxSection">
              <Box className="ProjectRadioInput">
                <input
                  className="custom-checkbox"
                  type="checkBox"
                  name="projectState"
                />
                <Typography className="emailSettingLabel">
                  {t("invitationRateReport.invitedCheckbox")}
                </Typography>
              </Box>
              <Box className="ProjectRadioInput">
                <input
                  className="custom-checkbox"
                  type="checkBox"
                  name="projectState"
                />
                <Typography className="emailSettingLabel">
                  {t("invitationRateReport.joinedCheckbox")}
                </Typography>
              </Box>
            </Box>

            <Box mt={4} className="projectRadioBoxSection">
              <Box className="ProjectRadioInput">
                <input
                  className="custom-checkbox"
                  type="checkBox"
                  name="projectState"
                />
                <Typography className="emailSettingLabel">
                  {t("invitationRateReport.reInvitedCheckbox")}
                </Typography>
              </Box>
              <Box className="ProjectRadioInput">
                <input
                  className="custom-checkbox"
                  type="checkBox"
                  name="projectState"
                />
                <Typography className="emailSettingLabel">
                  {t("invitationRateReport.notYetJoined")}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box mt={5} className="redioGroupSection">
            <Typography className="psSubLabel">
              {t("projectParticipantReport.subHeaderTxt2")}
            </Typography>

            <Box className="projectRadioBoxSection">
              <Box className="ProjectRadioInput">
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                />
                <Typography className="emailSettingLabel">
                  {t("projectParticipantReport.participantAllParticipant")}
                </Typography>
              </Box>
              <Box className="ProjectRadioInput">
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                />
                <Typography className="emailSettingLabel">
                  {t("projectParticipantReport.participantSelectGroup")}
                </Typography>
              </Box>
            </Box>

            <Box className="projectRadioBoxSection">
              <Box className="ProjectRadioInput">
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                />
                <Typography className="emailSettingLabel">
                  {t("projectParticipantReport.participantSelectParticipant")}
                </Typography>
              </Box>
            </Box>

            <Box className="participantIdentities">
              <Typography>
                {t("projectParticipantReport.participantIdentity")}
              </Typography>
              <Status
                itemAry={indentitiesData}
                setStatusDrop={setIdentitiesDrop}
                statusDrop={identitiesDrop}
                setStatusDropVal={setIdentitiesDropVal}
                statusDropVal={identitiesDropVal}
                label={t(
                  "projectParticipantReport.participantIdentityPlaceholder"
                )}
              />
            </Box>
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.resetBtn")} />
            <AppBtn buttonText={t("commonTxt.viewReportBtn")} />
          </Box>
        </Box>

        <Box
          mt={5}
          sx={{
            display:
              projectSettingDropVal === "Invitation Rates" ? "block" : "none",
          }}
          className="reportContainer integrationsContainer compositionSection"
        >
          <Box mt={3} className="tabelNav">
            <Box className="dropWheader">
              <Typography className="psSubLabel">
                {t("projectParticipantReport.headerTxt2")}
              </Typography>
              <Box className="ndrop">
                <Typography>5</Typography>
                <img src={dropIcon} />
              </Box>
            </Box>
            <Box className="repPrintBtnBox">
              <Box className="exporOpenBtn">
                <img src={printIcon} />
                <Typography className="verifyLink">
                  {t("commonTxt.printLink")}
                </Typography>
              </Box>
              <Box className="exporOpenBtn">
                <img src={exportIcon} />
                <Typography className="verifyLink">
                  {t("commonTxt.exportLink")}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box my={3} className="sideLineChart">
            <StackedBar />
          </Box>

          <Box mb={3} className="tabelNav">
            <Box className="dropWheader">
              <Typography className="pageHeaderText">
                {t("projectParticipantReport.subHeaderTxt2")} (5)
              </Typography>
            </Box>
            <Box className="repPrintBtnBox">
              <Box className="exporOpenBtn">
                <img src={exportIcon} />
                <Typography className="verifyLink">
                  {t("commonTxt.exportLink")}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box mb={6} className="workListBox">
            <Box className="haderRow" minWidth={"1100px"}>
              <Box minWidth={"24%"} className="Tabelsel TabelStatesel">
                <Typography>
                  {t("projectParticipantReport.tblUserName")}
                </Typography>
              </Box>
              <Box minWidth={"10%"} className="Tabelsel TabelStatesel">
                <Typography>
                  {t("projectParticipantReport.tblTasks")}
                </Typography>
              </Box>
              <Box minWidth={"10%"} className="Tabelsel TabelStatesel">
                <Typography>
                  {t("projectParticipantReport.tblRealTime")}
                </Typography>
              </Box>
              <Box minWidth={"14%"} className="Tabelsel TabelStatesel">
                <Typography>
                  {t("projectParticipantReport.tblConversation")}
                </Typography>
              </Box>
              <Box minWidth={"14%"} className="Tabelsel TabelStatesel">
                <Typography>
                  {t("projectParticipantReport.tblComment")}
                </Typography>
              </Box>
              <Box minWidth={"10%"} className="Tabelsel TabelStatesel">
                <Typography>
                  {t("projectParticipantReport.tblRating")}
                </Typography>
              </Box>
              <Box minWidth={"14%"} className="Tabelsel TabelStatesel">
                <Typography>
                  {t("projectParticipantReport.tblLastAction")}
                </Typography>
              </Box>
            </Box>

            {participant5Data?.map((el, i) => (
              <Box className="TabelRow" minWidth={"1100px"}>
                <Box minWidth={"24%"} className="Tabelsel navFsel navtable">
                  <Box className="tabelAvatar">
                    {" "}
                    <img src={el.img} />{" "}
                  </Box>
                  <Typography sx={{ cursor: "pointer", width: "95%" }}>
                    {" "}
                    {el.name}
                  </Typography>
                </Box>
                <Box minWidth={"10%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.tasks}</Typography>
                </Box>
                <Box minWidth={"10%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.RealTime}</Typography>
                </Box>
                <Box minWidth={"14%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Conversations}</Typography>
                </Box>
                <Box minWidth={"14%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Comments}</Typography>
                </Box>
                <Box minWidth={"10%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Ratings}</Typography>
                </Box>
                <Box minWidth={"14%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.LastAction}</Typography>
                </Box>
              </Box>
            ))}
          </Box>

          <Box className="pagenation">
            <Box className="tabelBtn">
              <img src={arrow} />
              <Typography>{t("commonTxt.previouspage")}</Typography>
            </Box>

            <Box className="pageNumberBox">
              <Box
                // onClick={() => setCurrentPage(0)}
                className={
                  currentPage === 0
                    ? "pageNumber pageNumberActive"
                    : "pageNumber"
                }
              >
                <p>1</p>
              </Box>
              <Box
                // onClick={() => setCurrentPage(1)}
                className={
                  currentPage === 1
                    ? "pageNumber pageNumberActive"
                    : "pageNumber"
                }
              >
                <p>2</p>
              </Box>
              <Box
                // onClick={() => setCurrentPage(2)}
                className={
                  currentPage === 2
                    ? "pageNumber pageNumberActive"
                    : "pageNumber"
                }
              >
                <p>3</p>
              </Box>
              <Box
                // onClick={() => setCurrentPage(3)}
                className={
                  currentPage === 3
                    ? "pageNumber pageNumberActive"
                    : "pageNumber"
                }
              >
                <p>4</p>
              </Box>
              <Box
                // onClick={() => setCurrentPage(4)}
                className={
                  currentPage === 4
                    ? "pageNumber pageNumberActive"
                    : "pageNumber"
                }
              >
                <p>5</p>
              </Box>
            </Box>

            <Box className="tabelBtn">
              <Typography>{t("commonTxt.nextPage")}</Typography>
              <img style={{ rotate: "180deg" }} src={arrow} />
            </Box>
          </Box>
        </Box>

        {/* -----------------------------------------------Invitation Rates end-------------------------------------- */}

        {/* ----------------------------------------------Excerpts start-------------------------------------- */}
        <Box
          sx={{
            display: projectSettingDropVal === "Excerpts" ? "block" : "none",
          }}
          className="reportContainer integrationsContainer compositionSection"
        >
          <Box className="projectBtnBox">
            <Box className="inteBtndropBox">
              <Box
                className="projectManagementNavBtn"
                onClick={() => setProjectSettingDrop(!projectSettingDrop)}
              >
                <Typography className="DropBtnText">
                  {projectSettingDropVal || "Project State"}
                </Typography>
                <img
                  className="dropIcon"
                  src={whitedropIcon}
                  style={{
                    rotate: projectSettingDrop ? "180deg" : "0deg",
                    transition: "all 0.5s",
                    marginLeft: "10px",
                  }}
                />
                <Box
                  className="workspaceItemNavPop projectItemNavPop"
                  sx={{ display: projectSettingDrop ? "flex" : "none" }}
                >
                  {reportDropVal?.map((el, i) => (
                    <Box
                      key={i}
                      onClick={() => {
                        setSearchParams({ dr: dataReportTabe, t: el.qstring });
                        setProjectSettingDropVal(el.title);
                      }}
                    >
                      <Typography>{el.title}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>

          <Typography className="pageHeaderText">
            {t("excerptReport.excerptReportHead")}
          </Typography>

          <Box className="excerptsTDropBox">
            <Box className="inputBoxWlabel">
              <Typography>
                {t("excerptReport.contributionTypeDropLabel")}
              </Typography>
              <Status
                itemAry={excerptsFdrop1Data}
                label={t("excerptReport.contributionTypeDropPlaceholder")}
                setStatusDrop={setExcerptsFdrop1}
                statusDrop={excerptsFdrop1}
                setStatusDropVal={setExcerptsFdropVal1}
                statusDropVal={excerptsFdropVal1}
              />
            </Box>

            <Box className="inputBoxWlabel">
              <Typography>
                {t("excerptReport.contributionDateRangeDropLabel")}
              </Typography>
              <Status
                label={t("excerptReport.contributionDateRangeDropPlaceholder")}
                setStatusDrop={setExcerptsFdrop2}
                statusDrop={excerptsFdrop2}
                setStatusDropVal={setExcerptsFdropVal2}
                statusDropVal={excerptsFdropVal2}
              />
            </Box>

            <Box className="inputBoxWlabel">
              <Typography>{t("excerptReport.excerptTypeDropLabel")}</Typography>
              <Status
                itemAry={excerptsFdrop3Data}
                label={t("excerptReport.excerptTypeDropPlaceholder")}
                setStatusDrop={setExcerptsFdrop3}
                statusDrop={excerptsFdrop3}
                setStatusDropVal={setExcerptsFdropVal3}
                statusDropVal={excerptsFdropVal3}
              />
            </Box>
          </Box>

          <Typography className="psSubLabel">
            {t("excerptReport.excerptLenghtHead")}
          </Typography>
          <Box className="lineBarLabelBox">
            <Typography>0 {t("commonTxt.words")}</Typography>
            <Typography>100 {t("commonTxt.words")}</Typography>
          </Box>

          <Box className="lineBarBox">
            <Slider
              sx={{ height: "8px", color: "#4F33D1" }}
              defaultValue={50}
              aria-label="Default"
              valueLabelDisplay="auto"
            />
          </Box>

          <Box className="excerptsTDropBox">
            <Box className="inputBoxWlabel">
              <Typography>{t("excerptReport.excerptDateDropLabel")}</Typography>
              <Status
                itemAry={["item1", "item2"]}
                label={t("excerptReport.excerptDateDropPlaceholder")}
                setStatusDrop={setExcerptsFdrop4}
                statusDrop={excerptsFdrop4}
                setStatusDropVal={setExcerptsFdropVal4}
                statusDropVal={excerptsFdropVal4}
              />
            </Box>

            <Box className="inputBoxWlabel">
              <Typography>{t("excerptReport.adminsDropLabel")}</Typography>
              <Status
                itemAry={["item1", "item2"]}
                label={t("excerptReport.adminsDropPlaceholder")}
                setStatusDrop={setExcerptsFdrop5}
                statusDrop={excerptsFdrop5}
                setStatusDropVal={setExcerptsFdropVal5}
                statusDropVal={excerptsFdropVal5}
              />
            </Box>
          </Box>

          <Box mt={2} className="redioGroupSection">
            <Typography className="psSubLabel">
              {t("excerptReport.useOfCodesHead")}
            </Typography>

            <Box className="projectRadioBoxSection">
              <Box className="ProjectRadioInput">
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                />
                <Typography className="emailSettingLabel">
                  {t("excerptReport.allExcerptsCheckbox")}
                </Typography>
              </Box>

              <Box className="ProjectRadioInput">
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                />
                <Typography className="emailSettingLabel">
                  {t("excerptReport.notYetCodedCheckbox")}
                </Typography>
              </Box>
            </Box>

            <Box className="projectRadioBoxSection">
              <Box className="ProjectRadioInput">
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                />
                <Typography className="emailSettingLabel">
                  {t("excerptReport.onlyCodedExcerptsCheckbox")}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box my={4} className="redioGroupSection">
            <Typography className="psSubLabel">
              {t("projectParticipantReport.subHeaderTxt1")}
            </Typography>

            <Box my={2} className="projectRadioBoxSection">
              <Box className="ProjectRadioInput">
                <input
                  className="custom-checkbox"
                  type="checkBox"
                  name="projectState"
                  checked
                />
                <Typography className="emailSettingLabel">
                  {t("excerptReport.backroomCheckbox")}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box mt={2} className="redioGroupSection">
            <Typography className="psSubLabel">
              {t("projectParticipantReport.subHeaderTxt2")}
            </Typography>

            <Box className="projectRadioBoxSection">
              <Box className="ProjectRadioInput">
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                />
                <Typography className="emailSettingLabel">
                  {t("projectParticipantReport.participantAllParticipant")}
                </Typography>
              </Box>

              <Box className="ProjectRadioInput">
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                />
                <Typography className="emailSettingLabel">
                  {t("projectParticipantReport.participantSelectGroup")}
                </Typography>
              </Box>
            </Box>

            <Box className="projectRadioBoxSection">
              <Box className="ProjectRadioInput">
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                />
                <Typography className="emailSettingLabel">
                  {t("projectParticipantReport.participantSelectParticipant")}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box width={"50%"} className="participantIdentities">
            <Typography>
              {t("projectParticipantReport.participantIdentity")}
            </Typography>
            <Status
              itemAry={indentitiesData}
              setStatusDrop={setIdentitiesDrop2}
              statusDrop={identitiesDrop2}
              setStatusDropVal={setIdentitiesDropVal2}
              statusDropVal={identitiesDropVal2}
              label={t(
                "projectParticipantReport.participantIdentityPlaceholder"
              )}
            />
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.resetBtn")} />
            <AppBtn buttonText={t("commonTxt.viewReportBtn")} />
          </Box>
        </Box>
        <Box
          mt={5}
          sx={{
            display: projectSettingDropVal === "Excerpts" ? "block" : "none",
          }}
          className="reportContainer integrationsContainer compositionSection"
        >
          <Box mt={3} className="tabelNav">
            <Box className="dropWheader">
              <Typography className="psSubLabel">
                {t("commonTxt.Codes")}
              </Typography>
              <Box className="ndrop">
                <Typography>5</Typography>
                <img src={dropIcon} />
              </Box>
            </Box>
            <Box className="repPrintBtnBox">
              <Box className="exporOpenBtn">
                <img src={printIcon} />
                <Typography className="verifyLink">
                  {t("commonTxt.printLink")}
                </Typography>
              </Box>
              <Box className="exporOpenBtn">
                <img src={exportIcon} />
                <Typography className="verifyLink">
                  {t("commonTxt.exportLink")}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box className="pieBox stackedBar">
            <StackedBar2 />
          </Box>

          <Box mt={3} className="tabelNav">
            <Box className="dropWheader">
              <Typography className="psSubLabel">
                {t("excerptReport.excerptsHead")} (10)
              </Typography>
              <Box className="ndrop">
                <Typography>5</Typography>
                <img src={dropIcon} />
              </Box>
            </Box>

            <Box className="repPrintBtnBox">
              <Box className="exporOpenBtn">
                <img src={videoIcon} />
                <Typography>{t("excerptReport.exportVideoClips")}</Typography>
              </Box>
              <Box className="exporOpenBtn">
                <img src={cottIcon} />
                <Typography>{t("excerptReport.exportExcerpts")}</Typography>
              </Box>
              <Box className="exporOpenBtn">
                <img src={clanderIcon} />
                <Typography>{t("excerptReport.contributionDate")}</Typography>
              </Box>
            </Box>
          </Box>

          <Box className="commentSection">
            <Box className="avatarBox">
              <img src={Avatar2} />
              <img className="statusDot" src={idelDot} />
            </Box>

            <Box className="commentTextSection">
              <Box mb={5} className="taskBoxTopDate">
                <Typography className="userNameWspm">
                  Kate Morrison
                  <span>in task The</span>
                  <samp>“Perfect Outdoor Trip”</samp>
                  <span> for activity</span>
                  <samp> Your Great Outdoors</samp>
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <samp className="taskHeaderText3">Aug 04, 2023 02:21 PM</samp>
                </Box>
              </Box>

              <Box className="VideoContentBox">
                <Box className="videoInnerBox">
                  <video className="video" controls>
                    <source src={video1} type="video/mp4" />
                  </video>
                </Box>

                <Box className="videoTextBox">
                  <Typography className="highLightedText">
                    <mark>
                      “It doesn’t matter how good you are or how far you get. It
                      really doesn’t, what matters is that you know you pass
                      that you know you pass that fear and you don’t let it
                      freeze you and you don’t let it stop you from doing what
                      you want.”
                    </mark>
                  </Typography>
                  <PurplelBtn buttonText="Great quote" />
                </Box>
              </Box>

              <Box mt={3} className="taskBoxBottomRow">
                <Box className="taskBoxBottomRowItem">
                  <Typography>{t("excerptReport.backroomComment")}</Typography>
                </Box>
                <Box className="taskBoxBottomRowItem">
                  <Typography>{t("excerptReport.editExcerpt")}</Typography>
                </Box>
                <Box className="taskBoxBottomRowItem">
                  <Typography>{t("excerptReport.deleteExcerpt")} </Typography>
                </Box>
                <Box className="taskBoxBottomRowItem lLine">
                  <Typography>{t("excerptReport.downloadVideo")}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* ----------------------------------------------Excerpts end-------------------------------------- */}

        {/* -----------------------------------------------Activities start------------------------------------- */}
        <Box
          sx={{
            display: projectSettingDropVal === "Activities" ? "block" : "none",
          }}
          className="reportContainer integrationsContainer compositionSection"
        >
          <Box className="projectBtnBox">
            <Box className="inteBtndropBox">
              <Box
                className="projectManagementNavBtn"
                onClick={() => setProjectSettingDrop(!projectSettingDrop)}
              >
                <Typography className="DropBtnText">
                  {projectSettingDropVal || "Project State"}
                </Typography>
                <img
                  className="dropIcon"
                  src={whitedropIcon}
                  style={{
                    rotate: projectSettingDrop ? "180deg" : "0deg",
                    transition: "all 0.5s",
                    marginLeft: "10px",
                  }}
                />
                <Box
                  className="workspaceItemNavPop projectItemNavPop"
                  sx={{ display: projectSettingDrop ? "flex" : "none" }}
                >
                  {reportDropVal?.map((el, i) => (
                    <Box
                      key={i}
                      onClick={() => {
                        setSearchParams({ dr: dataReportTabe, t: el.qstring });
                        setProjectSettingDropVal(el.title);
                      }}
                    >
                      <Typography>{el.title}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Typography className="pageHeaderText">
            {t("activityReport.activityReportHead")}
          </Typography>
          <Box className="participationReportDropSection">
            <Box className="inputBoxWlabel">
              <Typography>{t("activityReport.activityDropLabel")}</Typography>
              <Box
                className="inputAppDrop"
                onClick={() => setActivityDrop(!activityDrop)}
              >
                <Typography>
                  {activityDropVal ||
                    t("activityReport.activityDropPlaceholder")}
                </Typography>
                <img
                  style={{ rotate: activityDrop ? "180deg" : "0deg" }}
                  src={dropArrow}
                />
                {/* pop */}
                <Box
                  sx={{ height: activityDrop ? "auto" : "0px" }}
                  className="statusPop"
                >
                  <Box
                    onClick={() => setActivityDropVal("All Activities")}
                    className="statusPopItem"
                  >
                    <Typography>
                      {t("activityReport.activityDropVal1")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className="inputBoxWlabel">
              <Typography>{t("activityReport.startDateDropLabel")}</Typography>
              <Box
                className="inputAppDrop"
                onClick={() => setContributionDrop4(!contributionDrop4)}
              >
                <Typography>
                  {contributionDropVal4 ||
                    t("activityReport.startDateDropPlaceholder")}
                </Typography>
                <img
                  style={{ rotate: contributionDrop4 ? "180deg" : "0deg" }}
                  src={dropArrow}
                />
                {/* pop */}
                <Box
                  sx={{ height: contributionDrop4 ? "auto" : "0px" }}
                  className="statusPop"
                >
                  <Box
                    onClick={() => setContributionDropVal4("All Time")}
                    className="statusPopItem"
                  >
                    <Typography>
                      {t("activityReport.startDateDropVal1")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box mt={5} className="redioGroupSection">
            <Typography className="psSubLabel">
              {t("projectParticipantReport.subHeaderTxt2")}
            </Typography>

            <Box className="projectRadioBoxSection">
              <Box className="ProjectRadioInput">
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                />
                <Typography className="emailSettingLabel">
                  {t("projectParticipantReport.participantAllParticipant")}
                </Typography>
              </Box>
              <Box className="ProjectRadioInput">
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                />
                <Typography className="emailSettingLabel">
                  {t("projectParticipantReport.participantSelectGroup")}
                </Typography>
              </Box>
            </Box>

            <Box className="projectRadioBoxSection">
              <Box className="ProjectRadioInput">
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                />
                <Typography className="emailSettingLabel">
                  {t("projectParticipantReport.participantSelectParticipant")}
                </Typography>
              </Box>
            </Box>

            <Box className="participantIdentities">
              <Typography>
                {t("projectParticipantReport.participantIdentity")}
              </Typography>
              <Status
                itemAry={indentitiesData}
                setStatusDrop={setIdentitiesDrop}
                statusDrop={identitiesDrop}
                setStatusDropVal={setIdentitiesDropVal}
                statusDropVal={identitiesDropVal}
                label={t(
                  "projectParticipantReport.participantIdentityPlaceholder"
                )}
              />
            </Box>
          </Box>
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.resetBtn")} />
            <AppBtn buttonText={t("commonTxt.viewReportBtn")} />
          </Box>
        </Box>

        <Box
          mt={5}
          sx={{
            display: projectSettingDropVal === "Activities" ? "block" : "none",
          }}
          className="reportContainer integrationsContainer compositionSection"
        >
          <Box mb={6} mt={3} className="tabelNav">
            <Box className="dropWheader">
              <Typography className="psSubLabel">
                {t("projectParticipantReport.headerTxt2")}
              </Typography>
              <Box className="ndrop">
                <Typography>5</Typography>
                <img src={dropIcon} />
              </Box>
            </Box>
            <Box className="repPrintBtnBox">
              <Box className="exporOpenBtn">
                <img src={printIcon} />
                <Typography className="verifyLink">
                  {t("commonTxt.printLink")}
                </Typography>
              </Box>
              <Box className="exporOpenBtn">
                <img src={exportIcon} />
                <Typography className="verifyLink">
                  {t("commonTxt.exportLink")}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Typography className="pageHeaderText">
            {t("activityReport.activityCompletionHead")}
          </Typography>
          <Box className="lineChartBox">
            <SideLineChart />
          </Box>

          {/* table */}
          <Box mb={6} mt={4} className="workListBox">
            <Box className="haderRow" minWidth={"955px"}>
              <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectTaskReport.tblActivity")}</Typography>
              </Box>
              <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectTaskReport.tblTask")}</Typography>
              </Box>
              <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectTaskReport.tblPercentage")}</Typography>
              </Box>
            </Box>

            {activityCompData?.map((el, i) => (
              <Box key={i} className="TabelRow" minWidth={"955px"}>
                <Box minWidth={"33%"} className="Tabelsel navFsel navtable">
                  <Typography sx={{ cursor: "pointer", width: "95%" }}>
                    {" "}
                    {el.Activities}
                  </Typography>
                </Box>
                <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Tasks}</Typography>
                </Box>
                <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Percentage}</Typography>
                </Box>
              </Box>
            ))}
          </Box>

          <Typography className="pageHeaderText">
            {t("projectParticipantReport.subHeaderTxt2")}
          </Typography>

          <Box className="sideLineChart">
            <StackedBar />
          </Box>

          <Box mb={2} mt={3} className="tabelNav">
            <Box className="dropWheader">
              <Typography className="psSubLabel">
                {t("projectTaskReport.summarizDataHead")}{" "}
              </Typography>
            </Box>
            <Box className="repPrintBtnBox">
              <Box className="exporOpenBtn">
                <img src={exportIcon} />
                <Typography className="verifyLink">
                  {t("commonTxt.exportLink")}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* table */}
          <Box mb={6} mt={4} className="workListBox">
            <Box className="haderRow" minWidth={"1000px"}>
              <Box minWidth={"24%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectTaskReport.tblName")}</Typography>
              </Box>
              <Box minWidth={"16%"} className="Tabelsel Tabelcentersel">
                <Typography>{t("projectTaskReport.tblCompleted")}</Typography>
              </Box>
              <Box minWidth={"12%"} className="Tabelsel Tabelcentersel">
                <Typography>{t("projectTaskReport.tblInProgress")}</Typography>
              </Box>
              <Box minWidth={"16%"} className="Tabelsel Tabelcentersel">
                <Typography>{t("projectTaskReport.tblYetToBegin")}</Typography>
              </Box>
              <Box minWidth={"12%"} className="Tabelsel Tabelcentersel">
                <Typography>{t("projectTaskReport.tblTotal")}</Typography>
              </Box>
              <Box minWidth={"16%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectTaskReport.tblLastAction")}</Typography>
              </Box>
            </Box>

            {summarizedData?.map((el, i) => (
              <Box key={i} className="TabelRow" minWidth={"1000px"}>
                <Box minWidth={"24%"} className="Tabelsel navFsel navtable">
                  <Box className="tabelAvatar">
                    {" "}
                    <img src={el.img} />{" "}
                  </Box>
                  <Typography sx={{ cursor: "pointer", width: "95%" }}>
                    {" "}
                    {el.name}
                  </Typography>
                </Box>
                <Box minWidth={"16%"} className="Tabelsel Tabelcentersel">
                  <Typography>{el.Completed}</Typography>
                </Box>
                <Box minWidth={"12%"} className="Tabelsel Tabelcentersel">
                  <Typography>{el.InProgress}</Typography>
                </Box>
                <Box minWidth={"16%"} className="Tabelsel Tabelcentersel">
                  <Typography>{el.YettoBegin}</Typography>
                </Box>
                <Box minWidth={"12%"} className="Tabelsel Tabelcentersel">
                  <Typography>{el.Total}</Typography>
                </Box>
                <Box minWidth={"16%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.LastAction}</Typography>
                </Box>
              </Box>
            ))}
          </Box>

          <Box mb={2} mt={3} className="tabelNav">
            <Box className="dropWheader">
              <Typography className="psSubLabel">
                {t("activityReport.activityDataHead")}
              </Typography>
            </Box>
            <Box className="repPrintBtnBox">
              <Box className="exporOpenBtn">
                <img src={exportIcon} />
                <Typography className="verifyLink">
                  {t("commonTxt.exportLink")}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* table */}
          <Box mb={6} mt={4} className="workListBox">
            <Box className="haderRow" minWidth={"1000px"}>
              <Box minWidth={"20%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectTaskReport.tblName")}</Typography>
              </Box>
              <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                <Typography>1</Typography>
              </Box>
              <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                <Typography>2</Typography>
              </Box>
              <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                <Typography>3</Typography>
              </Box>
              <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                <Typography>4</Typography>
              </Box>
              <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                <Typography>5</Typography>
              </Box>
              <Box minWidth={"13%"} className="Tabelsel TabelStatesel">
                <Typography>6</Typography>
              </Box>
            </Box>

            {repActivitiesData?.map((el, i) => (
              <Box key={i} className="TabelRow" minWidth={"1000px"}>
                <Box minWidth={"20%"} className="Tabelsel navFsel navtable">
                  <Box className="tabelAvatar">
                    {" "}
                    <img src={el.img} />{" "}
                  </Box>
                  <Typography sx={{ cursor: "pointer", width: "95%" }}>
                    {" "}
                    {el.Name}
                  </Typography>
                </Box>
                <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                  {el.one ? (
                    <Box className="greenBox"></Box>
                  ) : (
                    <Box className="redBox"></Box>
                  )}
                </Box>
                <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                  {el.two ? (
                    <Box className="greenBox"></Box>
                  ) : (
                    <Box className="redBox"></Box>
                  )}
                </Box>
                <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                  {el.three ? (
                    <Box className="greenBox"></Box>
                  ) : (
                    <Box className="redBox"></Box>
                  )}
                </Box>
                <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                  {el.four ? (
                    <Box className="greenBox"></Box>
                  ) : (
                    <Box className="redBox"></Box>
                  )}
                </Box>
                <Box minWidth={"13%"} className="Tabelsel TabelStatesel">
                  {el.five ? (
                    <Box className="greenBox"></Box>
                  ) : (
                    <Box className="redBox"></Box>
                  )}
                </Box>
                <Box minWidth={"13%"} className="Tabelsel TabelStatesel">
                  {el.six ? (
                    <Box className="greenBox"></Box>
                  ) : (
                    <Box className="redBox"></Box>
                  )}
                </Box>
              </Box>
            ))}
          </Box>

          <Box className="colorIndicatorBox">
            <Box>
              <Box className="greenBox"></Box>
              <Typography>{t("commonTxt.completedStatus")}</Typography>
            </Box>
            <Box>
              <Box className="redBox"></Box>
              <Typography>{t("commonTxt.inProgressStatus")}</Typography>
            </Box>
            <Box>
              <Box className="redBox"></Box>
              <Typography>{t("commonTxt.yetToBeginStatus")}</Typography>
            </Box>
            <Box>
              <Box className="blackBox"></Box>
              <Typography>{t("commonTxt.notApplicableStatus")}</Typography>
            </Box>
          </Box>

          <Box className="pagenation">
            <Box className="tabelBtn">
              <img src={arrow} />
              <Typography>{t("commonTxt.previouspage")}</Typography>
            </Box>

            <Box className="pageNumberBox">
              <Box
                // onClick={() => setCurrentPage(0)}
                className={
                  currentPage === 0
                    ? "pageNumber pageNumberActive"
                    : "pageNumber"
                }
              >
                <p>1</p>
              </Box>
              <Box
                // onClick={() => setCurrentPage(1)}
                className={
                  currentPage === 1
                    ? "pageNumber pageNumberActive"
                    : "pageNumber"
                }
              >
                <p>2</p>
              </Box>
              <Box
                // onClick={() => setCurrentPage(2)}
                className={
                  currentPage === 2
                    ? "pageNumber pageNumberActive"
                    : "pageNumber"
                }
              >
                <p>3</p>
              </Box>
              <Box
                // onClick={() => setCurrentPage(3)}
                className={
                  currentPage === 3
                    ? "pageNumber pageNumberActive"
                    : "pageNumber"
                }
              >
                <p>4</p>
              </Box>
              <Box
                // onClick={() => setCurrentPage(4)}
                className={
                  currentPage === 4
                    ? "pageNumber pageNumberActive"
                    : "pageNumber"
                }
              >
                <p>5</p>
              </Box>
            </Box>

            <Box className="tabelBtn">
              <Typography>{t("commonTxt.nextPage")}</Typography>
              <img style={{ rotate: "180deg" }} src={arrow} />
            </Box>
          </Box>
        </Box>

        {/* -----------------------------------------------Activities end------------------------------------- */}

        {/* -----------------------------------------------Media start------------------------------------- */}

        <Box
          sx={{ display: projectSettingDropVal === "Media" ? "block" : "none" }}
          className="reportContainer integrationsContainer compositionSection"
        >
          <Box className="projectBtnBox">
            <Box className="inteBtndropBox">
              <Box
                className="projectManagementNavBtn"
                onClick={() => setProjectSettingDrop(!projectSettingDrop)}
              >
                <Typography className="DropBtnText">
                  {projectSettingDropVal || "Project State"}
                </Typography>
                <img
                  className="dropIcon"
                  src={whitedropIcon}
                  style={{
                    rotate: projectSettingDrop ? "180deg" : "0deg",
                    transition: "all 0.5s",
                    marginLeft: "10px",
                  }}
                />
                <Box
                  className="workspaceItemNavPop projectItemNavPop"
                  sx={{ display: projectSettingDrop ? "flex" : "none" }}
                >
                  {reportDropVal?.map((el, i) => (
                    <Box
                      key={i}
                      onClick={() => {
                        setSearchParams({ dr: dataReportTabe, t: el.qstring });
                        setProjectSettingDropVal(el.title);
                      }}
                    >
                      <Typography>{el.title}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>

          <Typography className="pageHeaderText">
            {t("mediaReport.mediaReportHead")}
          </Typography>

          <Box className="excerptsTDropBox">
            <Box className="inputBoxWlabel">
              <Typography>
                {t("projectParticipantReport.dateRangeDrop")}
              </Typography>
              <Status
                itemAry={dateRange}
                label={t("projectParticipantReport.dateRangeDropPlaceholder")}
                setStatusDrop={setExcerptsFdrop6}
                statusDrop={excerptsFdrop6}
                setStatusDropVal={setExcerptsFdropVal6}
                statusDropVal={excerptsFdropVal6}
              />
            </Box>

            <Box className="inputBoxWlabel">
              <Typography>
                {t("projectParticipantReport.contributionTypeDrop")}
              </Typography>
              <Status
                itemAry={contributionType}
                label={t(
                  "projectParticipantReport.contributionTypeDropPlaceholder"
                )}
                setStatusDrop={setExcerptsFdrop7}
                statusDrop={excerptsFdrop7}
                setStatusDropVal={setExcerptsFdropVal7}
                statusDropVal={excerptsFdropVal7}
              />
            </Box>

            <Box className="inputBoxWlabel">
              <Typography>{t("mediaReport.mediaTypeDropLabel")}</Typography>
              <Status
                itemAry={mediaType}
                label={t("mediaReport.mediaTypeDropPlaceholder")}
                setStatusDrop={setExcerptsFdrop8}
                statusDrop={excerptsFdrop8}
                setStatusDropVal={setExcerptsFdropVal8}
                statusDropVal={excerptsFdropVal8}
              />
            </Box>
          </Box>

          <Box mt={2} className="redioGroupSection">
            <Typography className="psSubLabel">
              {t("projectParticipantReport.subHeaderTxt2")}
            </Typography>

            <Box className="projectRadioBoxSection">
              <Box className="ProjectRadioInput">
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                />
                <Typography className="emailSettingLabel">
                  {t("projectParticipantReport.participantAllParticipant")}
                </Typography>
              </Box>

              <Box className="ProjectRadioInput">
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                />
                <Typography className="emailSettingLabel">
                  {t("projectParticipantReport.participantSelectGroup")}
                </Typography>
              </Box>
            </Box>

            <Box className="projectRadioBoxSection">
              <Box className="ProjectRadioInput">
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                />
                <Typography className="emailSettingLabel">
                  {t("projectParticipantReport.participantSelectParticipant")}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box width={"50%"} className="participantIdentities">
            <Typography>
              {t("projectParticipantReport.participantIdentity")}
            </Typography>
            <Status
              itemAry={indentitiesData}
              setStatusDrop={setIdentitiesDrop3}
              statusDrop={identitiesDrop3}
              setStatusDropVal={setIdentitiesDropVal3}
              statusDropVal={identitiesDropVal3}
              label={t(
                "projectParticipantReport.participantIdentityPlaceholder"
              )}
            />
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.resetBtn")} />
            <AppBtn buttonText={t("commonTxt.viewReportBtn")} />
          </Box>
        </Box>

        <Box
          mt={5}
          sx={{ display: projectSettingDropVal === "Media" ? "block" : "none" }}
          className="reportContainer integrationsContainer compositionSection"
        >
          <Box className="tabelNav">
            <Box className="dropWheader">
              <Typography className="psSubLabel">
                {t("projectParticipantReport.headerTxt2")}
              </Typography>
            </Box>
          </Box>

          <Box mb={1} mt={7} className="tabelNav">
            <Box className="dropWheader">
              <Typography className="psSubLabel">
                {t("mediaReport.mediaTypeHead")}
              </Typography>
            </Box>
            <Box className="repPrintBtnBox">
              <Box className="exporOpenBtn">
                <img src={printIcon} />
                <Typography className="verifyLink">
                  {t("commonTxt.printLink")}
                </Typography>
              </Box>
              <Box className="exporOpenBtn">
                <img src={exportIcon} />
                <Typography className="verifyLink">
                  {t("commonTxt.exportLink")}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box className="pieBox">
            <ApexChart />
          </Box>

          {/* table */}
          <Box mb={6} className="workListBox">
            <Box className="haderRow" minWidth={"955px"}>
              <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                <Typography>{t("mediaReport.tblMediaType")}</Typography>
              </Box>
              <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                <Typography>{t("CompositionReport.tblNumber")}</Typography>
              </Box>
              <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                <Typography>{t("CompositionReport.tblPercentage")}</Typography>
              </Box>
            </Box>
            {mediaTypeData?.map((el, i) => (
              <Box key={i} className="TabelRow" minWidth={"955px"}>
                <Box minWidth={"33%"} className="Tabelsel navFsel navtable">
                  <Typography sx={{ cursor: "pointer", width: "95%" }}>
                    {" "}
                    {el.mediaType}
                  </Typography>
                </Box>
                <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Number}</Typography>
                </Box>
                <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Percentage}</Typography>
                </Box>
              </Box>
            ))}
          </Box>

          <Box mb={2} mt={3} className="tabelNav">
            <Box className="dropWheader">
              <Typography className="psSubLabel">
                {t("projectParticipantReport.subHeaderTxt2")}
              </Typography>
              <Box className="ndrop">
                <Typography>5</Typography>
                <img src={dropIcon} />
              </Box>
            </Box>
          </Box>

          <Box my={3} className="sideLineChart">
            <StackedBar />
          </Box>
          <Box className="colorIndicatorBox">
            <Box>
              <Box className="orangeBox"></Box>
              <Typography>{t("commonTxt.image")}</Typography>
            </Box>
            <Box>
              <Box className="redBox"></Box>
              <Typography>{t("commonTxt.video")}</Typography>
            </Box>
          </Box>

          <Box mb={2} mt={6} className="tabelNav">
            <Box className="dropWheader">
              <Typography className="psSubLabel">
                {t("projectTaskReport.summarizDataHead")}{" "}
              </Typography>
            </Box>
            <Box className="repPrintBtnBox">
              <Box className="exporOpenBtn">
                <img src={exportIcon} />
                <Typography className="verifyLink">
                  {t("commonTxt.exportLink")}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* table */}
          <Box mb={6} mt={4} className="workListBox">
            <Box className="haderRow" minWidth={"1000px"}>
              <Box minWidth={"24%"} className="Tabelsel TabelStatesel">
                <Typography>{t("mediaReport.tblName")}</Typography>
              </Box>
              <Box minWidth={"19%"} className="Tabelsel Tabelcentersel">
                <Typography>{t("mediaReport.tblImage")}</Typography>
              </Box>
              <Box minWidth={"19%"} className="Tabelsel Tabelcentersel">
                <Typography>{t("mediaReport.tblVideo")}</Typography>
              </Box>
              <Box minWidth={"19%"} className="Tabelsel Tabelcentersel">
                <Typography>{t("mediaReport.tblAudio")}</Typography>
              </Box>
              <Box minWidth={"19%"} className="Tabelsel Tabelcentersel">
                <Typography>{t("mediaReport.tblTotal")}</Typography>
              </Box>
            </Box>

            {SummarizedData?.map((el, i) => (
              <Box key={i} className="TabelRow" minWidth={"1000px"}>
                <Box minWidth={"24%"} className="Tabelsel navFsel navtable">
                  <Box className="tabelAvatar">
                    {" "}
                    <img src={el.img} />{" "}
                  </Box>
                  <Typography sx={{ cursor: "pointer", width: "95%" }}>
                    {" "}
                    {el.name}
                  </Typography>
                </Box>
                <Box minWidth={"19%"} className="Tabelsel Tabelcentersel">
                  <Typography>{el.Image}</Typography>
                </Box>
                <Box minWidth={"19%"} className="Tabelsel Tabelcentersel">
                  <Typography>{el.Video}</Typography>
                </Box>
                <Box minWidth={"19%"} className="Tabelsel Tabelcentersel">
                  <Typography>{el.Audio}</Typography>
                </Box>
                <Box minWidth={"19%"} className="Tabelsel Tabelcentersel">
                  <Typography>{el.Total}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        {projectSettingDropVal === "Participation" ? (
          <ProjectParticipantReport
            dataReportTabe={dataReportTabe}
            setDataReportTabe={setDataReportTabe}
            dropVal={dropVal}
            projectSettingDrop={projectSettingDrop}
            setProjectSettingDrop={setProjectSettingDrop}
            projectSettingDropVal={projectSettingDropVal}
            setProjectSettingDropVal={setProjectSettingDropVal}
          />
        ) : projectSettingDropVal === "Conversation" ? (
          <ProjectConversationReport
            dataReportTabe={dataReportTabe}
            setDataReportTabe={setDataReportTabe}
            dropVal={dropVal}
            projectSettingDrop={projectSettingDrop}
            setProjectSettingDrop={setProjectSettingDrop}
            projectSettingDropVal={projectSettingDropVal}
            setProjectSettingDropVal={setProjectSettingDropVal}
          />
        ) : projectSettingDropVal === "Points" ? (
          <ProjectPointsPieChartReport
            dataReportTabe={dataReportTabe}
            setDataReportTabe={setDataReportTabe}
            dropVal={dropVal}
            projectSettingDrop={projectSettingDrop}
            setProjectSettingDrop={setProjectSettingDrop}
            projectSettingDropVal={projectSettingDropVal}
            setProjectSettingDropVal={setProjectSettingDropVal}
          />
        ) : projectSettingDropVal === "Tasks" ? (
          <ProjectTaskReport
            dataReportTabe={dataReportTabe}
            setDataReportTabe={setDataReportTabe}
            dropVal={dropVal}
            projectSettingDrop={projectSettingDrop}
            setProjectSettingDrop={setProjectSettingDrop}
            projectSettingDropVal={projectSettingDropVal}
            setProjectSettingDropVal={setProjectSettingDropVal}
          />
        ) : projectSettingDropVal === "Action Log" ? (
          <ProjectActionLogReport
            dataReportTabe={dataReportTabe}
            setDataReportTabe={setDataReportTabe}
            dropVal={dropVal}
            projectSettingDrop={projectSettingDrop}
            setProjectSettingDrop={setProjectSettingDrop}
            projectSettingDropVal={projectSettingDropVal}
            setProjectSettingDropVal={setProjectSettingDropVal}
          />
        ) : null}
      </Box>
    </>
  );
}
