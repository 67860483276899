import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.css";


//images
import dropArrow from "../../../Assets/Images/chevron-down.png";
//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import ReferenceDateUsingValue from "../../AppTools/DatePicker";
import ReferenceDateExplicitTimePicker from "../../AppTools/TimePicker";
import { AntSwitch } from "../../AppTools/AppTool";

export default function AddConversationTopicPop({
  addConversationTopicPop,
  setAddConversationTopicPop,
}) {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const Navigate = useNavigate();
  const [redio, setRedio] = useState(0);
  const [redio1, setRedio1] = useState(0);
  const [redio2, setRedio2] = useState(0);
  const [redio3, setRedio3] = useState(0);
  const [image, setImage] = useState(null);

  const [conversationTopicTab, setConversationTopicTab] = useState("Basic Settings");
  const [topicCategoryDrop, setTopicCategoryDrop] = useState(false);
  const [topicCategoryDropVal, setTopicCategoryDropVal] = useState();
  const [enableTopicDate, setenableTopicDate] = useState(true);
  const [enableTopicDate2, setenableTopicDate2] = useState(true);

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
     setAddConversationTopicPop(false);
    }
  };

  useEffect(() => {
    if (addConversationTopicPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [addConversationTopicPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: addConversationTopicPop ? "flex" : "none" }}
        className="companyPop addActivitiesPop"
      >
        <Box
          sx={{ display: addConversationTopicPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          
            <Typography className="companyHeader">{t("addConversationTopic.conversationTopicHead")}</Typography>

            <Typography className="topicPopLabels">{t("addConversationTopic.topicNameLabel")}</Typography>
            <Box className="input_Box TopicTitleLabel">
              <input
                type="text"
                className="eTemInput"
                placeholder={t("addConversationTopic.topicNamePlaceholder")}
              />
            </Box>
            <Typography className="topicPopLabels">{t("addConversationTopic.categoryLabel")}</Typography>
            <Box className="input_Box TopicTitleLabel">
                <Box
                    className="inputAppDrop"
                    onClick={() => setTopicCategoryDrop(!topicCategoryDrop)}
                >
                    <Typography>{topicCategoryDropVal || t("addConversationTopic.categoryPlaceholder")}</Typography>
                    <img
                    style={{ rotate: topicCategoryDrop ? "180deg" : "0deg" }}
                    src={dropArrow}
                    />
                    {/* pop */}
                    <Box
                    sx={{ height: topicCategoryDrop ? "auto" : "0px" }}
                    className="statusPop"
                    >
                    <Box
                        onClick={() => setTopicCategoryDropVal("Category 1")}
                        className="statusPopItem"
                    >
                        <Typography>Category 1</Typography>
                    </Box>
                    <Box
                        onClick={() => setTopicCategoryDropVal("Category 2")}
                        className="statusPopItem"
                    >
                        <Typography>Category 1</Typography>
                    </Box>
                   
                    </Box>
                </Box>
            </Box>
            <Typography className="topicPopLabels">{t("addConversationTopic.descriptionLabel")}</Typography>
            <Box className="input_Box">
              <textarea
                className="DmettingTextTextAera"
                placeholder={t("addConversationTopic.descriptionPlaceholder")}
              />
            </Box>

            <Box className="responsesNav">
              <Box
                className={
                    conversationTopicTab === "Basic Settings"
                    ? "responsesNavItem responsesNavItemActive"
                    : "responsesNavItem"
                }
                onClick={() => setConversationTopicTab("Basic Settings")}
              >
                <Typography className="standardActivityTabs">{t("addConversationTopic.basicSettingsTab")}</Typography>
              </Box>
              <Box
                className={
                    conversationTopicTab === "Scheduling"
                    ? "responsesNavItem responsesNavItemActive"
                    : "responsesNavItem"
                }
                onClick={() => setConversationTopicTab("Scheduling")}
              >
                <Typography className="standardActivityTabs">{t("addConversationTopic.schedulingTab")}</Typography>
              </Box>
              <Box
                className={
                    conversationTopicTab === "Points Customization"
                    ? "responsesNavItem responsesNavItemActive"
                    : "responsesNavItem"
                }
                onClick={() => setConversationTopicTab("Points Customization")}
              >
                <Typography className="standardActivityTabs">{t("addConversationTopic.pointsCustomizationTab")}</Typography>
              </Box>
            </Box>

            {/* =====Basic Settings======= */}
            <Box
              sx={{
                display: conversationTopicTab === "Basic Settings" ? "block" : "none",
              }}
            >
              

              <Typography className="eTemPopLabel">{t("addConversationTopic.topicSettingsHead")}</Typography>
              <Box mt={3} className="redioItem"

                onClick={() => {
                  setRedio3(0);
                }}
              >
                <Box
                  className={
                    redio3 === 0
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>{t("addConversationTopic.topicSettingsCheckbox1")}</Typography>
              </Box>
              <Box mt={3} className="redioItem" onClick={() => setRedio3(1)}>
                <Box
                  className={
                    redio3 === 1
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>
                {t("addConversationTopic.topicSettingsCheckbox2")}
                </Typography>
              </Box>
              <Box mt={3} className="redioItem" onClick={() => setRedio3(2)}>
                <Box
                  className={
                    redio3 === 2
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>
                {t("addConversationTopic.topicSettingsCheckbox3")}
                </Typography>
              </Box>
              <Box className="gapBox"></Box>

              <Typography className="eTemPopLabel">
              {t("addConversationTopic.topicVisibilityForParticipantsHead")}
              </Typography>
              <Box mt={3} className="redioItem" onClick={() => setRedio(0)}>
                <Box
                  className={
                    redio === 0
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>{t("addConversationTopic.topicVisibilityParticipantsCheckbox1")}</Typography>
              </Box>

              <Box mt={3} className="redioItem" onClick={() => setRedio(1)}>
                <Box
                  className={
                    redio === 1
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>{t("addConversationTopic.topicVisibilityParticipantsCheckbox2")}</Typography>
              </Box>
              <Box className="gapBox"></Box>

                <Typography className="eTemPopLabel">{t("addConversationTopic.topicVisibilityForObserverHead")}</Typography>
                <Box mt={3} className="redioItem" onClick={() => setRedio2(0)}>
                <Box
                    className={
                    redio2 === 0
                        ? "customRedio customRedioActive"
                        : "customRedio"
                    }
                >
                    <Box></Box>
                </Box>
                <Typography>{t("addConversationTopic.topicVisibilityForObserverCheckbox1")}</Typography>
                </Box>
                <Box mt={3} className="redioItem" onClick={() => setRedio2(1)}>
                <Box
                    className={
                    redio2 === 1
                        ? "customRedio customRedioActive"
                        : "customRedio"
                    }
                >
                    <Box></Box>
                </Box>
                <Typography>
                {t("addConversationTopic.topicVisibilityForObserverCheckbox2")}
                </Typography>
                </Box>
                <Box mt={3} className="redioItem" onClick={() => setRedio2(2)}>
                <Box
                    className={
                    redio2 === 2
                        ? "customRedio customRedioActive"
                        : "customRedio"
                    }
                >
                    <Box></Box>
                </Box>
                <Typography>
                {t("addConversationTopic.topicVisibilityForObserverCheckbox3")}
                </Typography>
                </Box>
              <Box className="gapBox"></Box>

              <Typography className="eTemPopLabel">
              {t("addConversationTopic.topicReplyVisibilityHead")}
              </Typography>
              <Box mt={3} className="redioItem" onClick={() => setRedio1(0)}>
                <Box
                  className={
                    redio1 === 0
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>
                {t("addConversationTopic.topicReplyVisibilityCheckbox1")}
                </Typography>
              </Box>
              <Box mt={3} className="redioItem" onClick={() => setRedio1(1)}>
                <Box
                  className={
                    redio1 === 1
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>
                {t("addConversationTopic.topicReplyVisibilityCheckbox2")}
                </Typography>
              </Box>
              <Box mt={3} className="redioItem" onClick={() => setRedio1(2)}>
                <Box
                  className={
                    redio1 === 2
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>
                {t("addConversationTopic.topicReplyVisibilityCheckbox3")}
                </Typography>
              </Box>
              
              <Box className="gapBox"></Box>
              
              <Typography className="eTemPopLabel">{t("addConversationTopic.wordsCensoredHead")}</Typography>
              <Box className="input_Box">
                <textarea
                    className="DmettingTextTextAera"
                    placeholder={t("addConversationTopic.wordsCensoredPlaceholder")}
                />
                </Box>
              
              
              <Box className="gapBox"></Box>
            </Box>
            {/* =====Scheduling======= */}
            <Box
              sx={{
                display: conversationTopicTab === "Scheduling" ? "block" : "none",
              }}
            >
              <Box className="airadioSwitchBox journalActivityNotificationDayDrop">
                <AntSwitch
                    defaultChecked
                  inputProps={{ "aria-label": "ant design" }}
                  onClick={() => setenableTopicDate(!enableTopicDate)}
                />
                <Typography>
                {t("addConversationTopic.delayStartTopic")}
                </Typography>
              </Box>
              <Box className="TopicTitleLabel conversationTopicDelay" sx={{ display: enableTopicDate ? "block" : "none" }} >
                <Typography className="topicPopLabels">{t("addConversationTopic.dateLabel")}</Typography>
                <Typography className="bsNText2">{t("addConversationTopic.dateSubLabel")}</Typography>
                <Box className="pickerBox">
                    <ReferenceDateUsingValue />
                </Box>
                <Typography className="topicPopLabels">{t("addConversationTopic.timeLabel")}</Typography>
                <Box className="pickerBox">
                    <ReferenceDateExplicitTimePicker placeholder={t("addConversationTopic.timePlaceholder")} />
                </Box>
              </Box>
              <Box className="airadioSwitchBox journalActivityNotificationDayDrop">
                <AntSwitch
                  defaultChecked
                  inputProps={{ "aria-label": "ant design" }}
                  onClick={() => setenableTopicDate2(!enableTopicDate2)}
                />
                <Typography>
                {t("addConversationTopic.enableFeedbackStage")}
                </Typography>
                
              </Box>
              <Box className="TopicTitleLabel conversationTopicDelay" sx={{ display: enableTopicDate2 ? "block" : "none" }} >
                <Typography className="topicPopLabels">{t("addConversationTopic.dateLabel")}</Typography>
                <Typography className="bsNText2">{t("addConversationTopic.dateSubLabel")}</Typography>
                <Box className="pickerBox">
                    <ReferenceDateUsingValue />
                </Box>
                <Typography className="topicPopLabels">{t("addConversationTopic.timeLabel")}</Typography>
                <Box className="pickerBox">
                    <ReferenceDateExplicitTimePicker placeholder={t("addConversationTopic.timePlaceholder")} />
                </Box>
              </Box>
              <Box className="airadioSwitchBox journalActivityNotificationDayDrop">
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                />
                <Typography>
                {t("addConversationTopic.waitFeedbackStage")}
                </Typography>
              </Box>
              <Box className="airadioSwitchBox journalActivityNotificationDayDrop">
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                />
                <Typography>
                {t("addConversationTopic.scheduleEndTopic")}
                </Typography>
              </Box>
            </Box>
            {/* =====Points Customizations======= */}
            <Box
              sx={{
                display:
                conversationTopicTab === "Points Customization" ? "block" : "none",
              }}
            >
              <Box className="airadioSwitchBox">
                <AntSwitch
                  defaultChecked
                  inputProps={{ "aria-label": "ant design" }}
                />
                <Typography className="switchText pcTopicSText">
                {t("addConversationTopic.customizePointsAwarded")}
                </Typography>
              </Box>

              <Box mb={3} className="conversationTopicListRow">
                <Box>
                    <input defaultValue="25" />
                    <samp>{t("commonTxt.pts.")}</samp>
                </Box>
                <Box>
                    <Typography>{t("addConversationTopic.replyTopic")}</Typography>
                </Box>
              </Box>

              <Box mb={3} className="conversationTopicListRow">
                <Box>
                    <input defaultValue="15" />
                    <samp>{t("commonTxt.pts.")}</samp>
                </Box>
                <Box>
                    <Typography>{t("addConversationTopic.commentReply")}</Typography>
                </Box>
              </Box>

              <Box mb={3} className="conversationTopicListRow">
                <Box>
                    <input defaultValue="05" />
                    <samp>{t("commonTxt.pts.")}</samp>
                </Box>
                <Box>
                    <Typography>{t("addConversationTopic.likeOtherPost")}</Typography>
                </Box>
              </Box>

              <Box mb={3} className="conversationTopicListRow">
                <Box>
                    <input defaultValue="05" />
                    <samp>{t("commonTxt.pts.")}</samp>
                </Box>
                <Box>
                    <Typography>{t("addConversationTopic.likeRatingPost")}</Typography>
                </Box>
              </Box>
            </Box>

            <Box className="workPupBtnBox">
              <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => setAddConversationTopicPop(false)} />
              <AppBtn buttonText={t("commonTxt.continueBtn")} />
            </Box>
          
          
        </Box>
      </Box>
    </>
  );
}
