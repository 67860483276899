import React from "react";
import AdminLayout from "../../partials/AdminLayout";
import { Box } from "@mui/material";
import SettingEnhancedGroup from "../../../../Components/WSAdministration/SettingEnhancedGroup";

const SettingEnhancedGroupPage = () => {
  return (
    <AdminLayout
      childComponent={
        <Box className="adminMainBox" sx={{ display: "flex" }}>
          <SettingEnhancedGroup />
        </Box>
      }
    />
  );
};

export default SettingEnhancedGroupPage;
