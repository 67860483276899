import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./style.css";

//images
import backArrow from "../../../Assets/Images/noun-back.png";
import iIcon2 from "../../../Assets/Images/IIcon.png";
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png";

//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import {
  AntSwitch,
  SearchBar,
  Status,
  PickEmoji,
} from "../../AppTools/AppTool";

export default function ReviewAnImage({
  reviewAnImage,
  setReviewAnImage,
  handelAddTaskPopBack2,
  setAllDropVal4,
  taskData,
  setTaskData,
  initialTaskState,
  setSelectedTask,
}) {
  const [image, setImage] = useState(null);
  const [scalDrop, setScalDrop] = useState(false);
  const [scalDropVal, setScalDropVal] = useState("5 - point scale");
  const [EmojiDrop, setEmojiDrop] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState();
  const [EmojiDrop2, setEmojiDrop2] = useState(false);
  const [selectedEmoji2, setSelectedEmoji2] = useState();

  const [EmojiDrop3, setEmojiDrop3] = useState(false);
  const [selectedEmoji3, setSelectedEmoji3] = useState();
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const scaleArrya = ["item", "item", "item"];

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setReviewAnImage(false);
    }
  };

  useEffect(() => {
    if (reviewAnImage) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [reviewAnImage]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: reviewAnImage ? "flex" : "none" }}
        className="companyPop reviewImages"
      >
        <Box
          sx={{ display: reviewAnImage ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Box onClick={handelAddTaskPopBack2} className="openBtn taskBackBtn">
            <img src={backArrow} />
            <Typography className="neonBackText">
              {t("commonTxt.backBtn")}
            </Typography>
          </Box>
          <Typography className="taskPopMainLabel">
            {t("reviewImageTaskPop.reviewImageTaskHead")}
          </Typography>
          <Box className="iBox">
            <img src={iIcon2} />
            <Typography>
              {t("reviewImageTaskPop.reviewImageTaskNotify")}
            </Typography>
          </Box>

          <Box className="basicBoxItem">
            <Typography className="eTemPopLabel ">
              {t("addTextTaskPop.taskNameLabel")}
            </Typography>
            <Box className="pickerBox cardTitleLabel">
              <input
                className="eTemInput"
                placeholder={t("addTextTaskPop.taskNamePlaceholder")}
              />
            </Box>
          </Box>

          <Typography className="eTemPopLabel ">
            {t("reviewImageTaskPop.reviewImageHead")}
          </Typography>
          <Box
            className="mediaInputbox upload-area"
            onClick={() => {
              document.getElementById("fileInput").click();
            }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <img src={uploadIcon} />
            <Typography>{t("commonTxt.uploadText")}</Typography>
            <input
              id="fileInput"
              style={{ display: "none" }}
              type="file"
              onChange={handleFileInputChange}
            />
          </Box>
          <Typography className="fileuploadBotLabel">
            {t("reviewImageTaskPop.reviewImageSubText")}
          </Typography>

          <Typography className="eTemPopLabel ">
            {t("recapImgReview.overallImageRating")}
          </Typography>
          <Box className="airadioSwitchBox">
            <AntSwitch
              defaultChecked
              inputProps={{ "aria-label": "ant design" }}
            />
            <Typography className="switchText">
              {t("reviewImageTaskPop.imageRatingSwitch")}
            </Typography>
          </Box>

          <Box ml={5} sx={{ width: "40%" }}>
            <Typography>{t("groups.scale")}</Typography>
            <Status
              statusDrop={scalDrop}
              setStatusDrop={setScalDrop}
              setStatusDropVal={setScalDropVal}
              statusDropVal={scalDropVal}
              label={scaleArrya}
            />
          </Box>

          <Box ml={5} mt={3} sx={{ width: "40%" }}>
            <Typography>{t("recapMatrixPage.tblLabels")}</Typography>

            <Box
              mt={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Typography>1.</Typography>
              <input className="phoneInput" value="Very Unlikely" />
            </Box>
            <Box
              mt={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Typography>2.</Typography>
              <input className="phoneInput" value="Unlikely" />
            </Box>
            <Box
              mt={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Typography>3.</Typography>
              <input className="phoneInput" value="Neural" />
            </Box>
            <Box
              mt={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Typography>4.</Typography>
              <input className="phoneInput" value="Likely" />
            </Box>
            <Box
              mt={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Typography>5.</Typography>
              <input className="phoneInput" value="Very Likely" />
            </Box>
          </Box>

          <Box my={3} sx={{ width: "100%" }}>
            <Box mb={1} className="tagInputBOx">
              <input
                style={{ marginRight: "10px" }}
                className="custom-checkbox"
                type="checkBox"
              />
              <Typography>
                {t("reviewImageTaskPop.imageRatingCheckbox1")}
              </Typography>
            </Box>
            <input
              style={{ marginLeft: "30px", width: "96%" }}
              className="phoneInput"
              placeholder={t("reviewImageTaskPop.scheduleMeetingPlaceholder")}
            />
          </Box>
          <Typography className="eTemPopLabel">
            {t("reviewImageTaskPop.tagsSettingsHead")}
          </Typography>

          <PickEmoji
            setEmojiDrop={setEmojiDrop}
            EmojiDrop={EmojiDrop}
            setSelectedEmoji={setSelectedEmoji}
            selectedEmoji={selectedEmoji}
          />
          <PickEmoji
            setEmojiDrop={setEmojiDrop2}
            EmojiDrop={EmojiDrop2}
            setSelectedEmoji={setSelectedEmoji2}
            selectedEmoji={selectedEmoji2}
          />
          <PickEmoji
            setEmojiDrop={setEmojiDrop3}
            EmojiDrop={EmojiDrop3}
            setSelectedEmoji={setSelectedEmoji3}
            selectedEmoji={selectedEmoji3}
          />

          <Typography className="addTagesText">
            {t("reviewImageTaskPop.addTagLink")}
          </Typography>
          <Typography className="eTemPopLabel">
            {t("reviewImageTaskPop.commentSettingsHead")}
          </Typography>

          <Box my={3} sx={{ width: "100%" }}>
            <Box mb={1} className="tagInputBOx">
              <input
                style={{ marginRight: "10px" }}
                className="custom-checkbox"
                type="checkBox"
              />
              <Typography>{t("addTextTaskPop.customizePrompt")}</Typography>
            </Box>
            <input
              style={{ marginLeft: "30px", width: "96%" }}
              className="phoneInput"
              placeholder={t("reviewImageTaskPop.customPromptPlaceholder")}
            />
          </Box>

          <Box mb={1} className="tagInputBOx">
            <input
              style={{ marginRight: "10px" }}
              className="custom-checkbox"
              type="checkBox"
            />
            <Typography>
              {t("reviewImageTaskPop.commentSettingsCheckbox2")}
            </Typography>
          </Box>

          <Box className="limitBox nLimitebox">
            <input className="phoneInput" value={"01"} />
            <Typography>{t("addTextTaskPop.to")}</Typography>
            <input className="phoneInput" value={"04"} />
            <Typography>{t("recapeVideoReview.tblTags")}</Typography>
          </Box>

          <Box mb={5} mt={2} className="tagInputBOx">
            <input
              style={{ marginRight: "10px" }}
              className="custom-checkbox"
              type="checkBox"
            />
            <Typography>{t("addTextTaskPop.TextFormatting")}</Typography>
          </Box>

          <Typography sx={{ margin: "24px 0 65px 0" }} className="eTemPopLabel">
            {t("addTextTaskPop.taskSettingsHead")}
          </Typography>
          <Box mt={3} mb={1} className="tagInputBOx">
            <input
              style={{ marginRight: "10px" }}
              className="custom-checkbox"
              type="checkBox"
            />
            <Typography>
              {t("reviewImageTaskPop.taskSettingsCheckbox1")}
            </Typography>
          </Box>

          <Box className="limitBox nLimitebox">
            <input className="phoneInput" value={"01"} />
            <Typography>{t("addTextTaskPop.to")}</Typography>
            <input className="phoneInput" value={"04"} />
            <Typography>{t("recapeVideoReview.tblTags")}</Typography>
          </Box>

          <Box mb={5} mt={2} className="tagInputBOx">
            <input
              style={{ marginRight: "10px" }}
              className="custom-checkbox"
              type="checkBox"
            />
            <Typography>{t("addTextTaskPop.responseRequired")}</Typography>
          </Box>

          <Typography className="eTemPopLabel">
            {t("addTextTaskPop.advanceSettingsHead")}
          </Typography>
          <Box mt={2} className="tagInputBOx">
            <input
              style={{ marginRight: "10px" }}
              className="custom-checkbox"
              type="checkBox"
            />
            <Typography>
              {t("addTextTaskPop.advanceSettingsCheckbox1")}
            </Typography>
          </Box>

          <Box mt={2} className="tagInputBOx">
            <input
              style={{ marginRight: "10px" }}
              className="custom-checkbox"
              type="checkBox"
            />
            <Typography>
              {t("addTextTaskPop.advanceSettingsCheckbox2")}{" "}
            </Typography>
          </Box>
          <Box className="workPupBtnBox">
            <AppBtnOutLine
              buttonText={t("commonTxt.cancelBtn")}
              onClick={() => setReviewAnImage(false)}
            />
            <AppBtnOutLine buttonText={t("commonTxt.savePreviewBtn")} />
            <AppBtn buttonText={t("commonTxt.saveTaskBtn")} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
