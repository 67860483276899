import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from "react-i18next";
import "./style.css";

//images
import cott from "../../../Assets/Images/cott.png";
import groupIcon from "../../../Assets/Images/groupsIcon.png";
import filterIcon from "../../../Assets/Images/filterIcon.png"
import tp1 from "../../../Assets/Images/tp1.png"
import tp2 from "../../../Assets/Images/tp2.png"
import tp3 from "../../../Assets/Images/tp3.png"
import tp4 from "../../../Assets/Images/tp4.png"
import tp5 from "../../../Assets/Images/tp5.png"
import tp6 from "../../../Assets/Images/tp6.png"
import tp7 from "../../../Assets/Images/tp7.png"
import tp8 from "../../../Assets/Images/tp8.png"
import tp9 from "../../../Assets/Images/tp9.png"
import tp10 from "../../../Assets/Images/tp10.png"
import tp11 from "../../../Assets/Images/tp11.png"
import blackImg from "../../../Assets/Images/blankImg.png"
import idelDot from "../../../Assets/Images/idelDot.png"
import chevronDrop from "../../../Assets/Images/chevron-down.png"
import avatar1 from "../../../Assets/Images/inb1.png"
import avatar2 from "../../../Assets/Images/inb2.png"
import avatar3 from "../../../Assets/Images/inb3.png"
import editBtn from "../../../Assets/Images/edit 1.png"
import whitedropIcon from "../../../Assets/Images/Wchevron-down.png";
import semoji from "../../../Assets/Images/Semoji.png"
import imgIcon from "../../../Assets/Images/galleryIconB.png"
import emojiDislike from "../../../Assets/Images/emojiLike.png"
import emojiLike from "../../../Assets/Images/emojiDislike.png"
import emojiConfused from "../../../Assets/Images/emojiConfused.png"
import video from "../../../Assets/Images/Videos/part1.mp4"
import AdminBG from "../../../Assets/Images/Add-files-cuate.png";


//component
import EmojiPicker from "emoji-picker-react";
import { like, dislike, comment, tik } from "../../../Assets/Data";
import ThreadFilterPop from "../../../Components/Participants/ThreadFilterPop";


export default function Thread() {

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const [providerDrop, setProviderDrop] = useState(false)
  const [providerDropVal, setProviderDropVal] = useState()
  const [EmojiDrop, setEmojiDrop] = useState(false);
  const [filterPop, setFilterPop] = useState(false);

  const threadData = true;
  const productBox = ({ img1, img2, img3, img4, title, subTitle }) => {
    return (
      <Box className="taskProductItem">
        <Typography>{title}</Typography>
        <Box my={1} className="productImgBox">
          <Box className="productImg"><img src={img1} /></Box>
          <Box className="productImg"><img src={img2} /></Box>
          <Box className="productImg"><img src={img3} /></Box>
          <Box className="productImg"><img src={img4} /></Box>
        </Box>
        <Typography>{subTitle}</Typography>
      </Box>
    )
  }


  const singelComment = ({ img, name, comment, date, time }) => {
    return (
      <Box className="singelComment">
        <Box className="commentSection innerCommentSection">
          <Box className="avatarBox">
            <img src={img} />
          </Box>
          <Box className="commentTextSection">
            <Box className="userNameBox">
              <Typography className='userName'>{name}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "15px",
                  minWidth: "213px"
                }}
              >
                <Box className="editbtn">
                  <img src={editBtn} />
                  <Typography>{t("commonTxt.edit")}</Typography>
                </Box>
                <samp className="taskHeaderText3">
                  {date}
                </samp>
              </Box>
            </Box>
            <Typography className='commentWtag' dangerouslySetInnerHTML={{ __html: wrapAtWordsWithSpan(comment) }}></Typography>
            <Box className="likeBox">
              <Typography>{time}</Typography>
              <Typography>{t("commonTxt.Like")}</Typography>
              <Typography>{t("commonTxt.dislike")}</Typography>
              <Typography>{t("commonTxt.reply")}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

    )
  }


  function wrapAtWordsWithSpan(text) {
    return text.replace(/@(\w+)/g, '<span>@$1</span>');
  }



  const writeCommentSection = ({ img }) => {
    return (
      <Box className="writeCommentSection">
        <Box className="avatarBox">
          <img src={img} />
        </Box>
        <Box className="commentInputBox">
          <input className='phoneInput' placeholder={t("commonTxt.addComment")} />
          <img className='inputIcon1' src={semoji} onClick={() => setEmojiDrop(!EmojiDrop)} />
          <img className='inputIcon2' src={imgIcon} />
          <Box className="commentCheckBox">
            <Box
              mb={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <input
                style={{ marginRight: "10px" }}
                className="custom-checkbox"
                type="checkBox"
              />
              <Typography>{t("recapThread.makeReplyCompulsory")}</Typography>
            </Box>

            <Typography className='followText'>{t("emailPage.temp")}</Typography>
          </Box>
          <Box className="emojiBox2" sx={{ display: EmojiDrop ? "block" : "none" }}>
            <EmojiPicker />
          </Box>
        </Box>

        <Box className="directMessageNavBtn" onClick={() => setProviderDrop(!providerDrop)}>
          <Typography className='DropBtnText'>{t("commonTxt.postBtn")}</Typography>
          <img src={whitedropIcon} style={{ rotate: providerDrop ? "180deg" : "0deg" }} />
          <Box className="workspaceItemNavPop" sx={{ display: providerDrop ? "flex" : "none" }}>
            <Box onClick={() => setProviderDropVal("Visible to Everyone")}>
              <Typography>{t("recapThread.visibleToEveryone")}</Typography>
            </Box>
            <Box onClick={() => setProviderDropVal("Only Andi Lane")}>
              <Typography>{t("recapThread.onlyAndiLane")}</Typography>
            </Box>
            <Box onClick={() => setProviderDropVal("Backroom Comment")}>
              <Typography>{t("recapThread.backroomComment")}</Typography>
            </Box>
            <Box onClick={() => setProviderDropVal("Backroom Task")}>
              <Typography>{t("recapThread.backroomTask")}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }



  return (
    <>
      {!threadData ?
        <Box className="threadNoDataPage">
          <Box className="adminWorkSBG">
            <img src={AdminBG} />
          </Box>
          <Box className="adminBGTextBox">
            <Typography className="adminSubHeaderText">
              {t("ParticipantsThreadRecap.threadNoDataText1")}<br />
              {t("ParticipantsThreadRecap.threadNoDataText2")}
            </Typography>
          </Box>
        </Box>
        :
        <Box className="threadContainer workspaceListBox ParticipantThread">
          <ThreadFilterPop setFilterPop={setFilterPop} filterPop={filterPop} />
          <Box className="threadNavBox">

            <Box className="actTNavBtn">
              <img src={cott} />
              <Typography>{t("commonTxt.excerptBtn")}</Typography>
            </Box>


            <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              {/* <Box className="actTNavBtn">
                <img src={groupIcon} />
                <Typography>{t("commonTxt.groupBtn")}</Typography>
              </Box> */}

              <Box className="filterBtn" onClick={() => setFilterPop(true)}>
                <img src={filterIcon} />
                <p>{t("commonTxt.filters")}</p>
              </Box>
            </Box>
          </Box>



          <Box className="commentSection">
            <Box className="avatarBox">
              <img src={avatar3} />
              <img className='statusDot' src={idelDot} />
            </Box>

            <Box className="commentTextSection paddingNone">


              <Box pt={2} px={3} className="taskBoxTopDate">
                <Typography className='userNameWspm'>
                  Daina Henry
                  <span>{t("ParticipantsThreadRecap.completedActivity")}</span>
                  <samp>{t("ParticipantsThreadRecap.YourGreatOutdoors")}</samp>
                </Typography>
                <Box sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "15px"
                }}>
                  <samp className='taskHeaderText3'>Aug 04, 2023 02:21 PM</samp>
                </Box>
              </Box>

              <Box className="graySBox taskBox">
                <Box className="taskBoxTopDate">
                  <Typography className='taskHeaderText'>{t("ParticipantsThreadRecap.TaskName1")}</Typography>
                  <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "15px"
                  }}>
                    <Typography className='taskHeaderText2'>
                      {t("commonTxt.viewBtn")}
                    </Typography>
                  </Box>
                </Box>
                <Typography mb={3}>Which of these outdoor activities do you participate in? Please select all that apply and explain why these activities are the outdoor activities for you.</Typography>

                <Box ml={5} mb={3}>
                  <ul style={{ marginBottom: "10px" }}>
                    <li>{t("ParticipantsThreadRecap.Camping")}</li>
                    <li>{t("ParticipantsThreadRecap.Hiking")}</li>
                  </ul>
                  <span>All you need is a back pack, tent and some boots - nothing big or complicated!</span>
                </Box>
                <Box className="taskBoxBottomRow">
                  <Box className="taskBoxBottomRowItem">
                    {like}
                    <Typography>{t("commonTxt.Like")}</Typography>
                  </Box>
                  <Box className="taskBoxBottomRowItem">
                    {dislike}
                    <Typography>{t("commonTxt.dislike")}</Typography>
                  </Box>
                  <Box className="taskBoxBottomRowItem">
                    {comment}
                    <Typography>{t("commonTxt.comment")}</Typography>
                  </Box>
                </Box>
              </Box>



              <Box className="graySBox taskBox">
                <Box className="taskBoxTopDate">
                  <Typography className='taskHeaderText'>{t("ParticipantsThreadRecap.TaskName2")}</Typography>
                  <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "15px"
                  }}>
                    <Typography className='taskHeaderText2'>{t("commonTxt.viewBtn")}</Typography>
                  </Box>
                </Box>
                <Typography mb={3}>Select the Outdoor Essentials and sort them into the following categories.</Typography>

                <Box className="taskProductBox">

                  {productBox({
                    img1: tp1,
                    img2: tp2,
                    img3: tp3,
                    img4: tp4,
                    title: "Absolutely Needed",
                    subTitle: "+2 more"
                  })}
                  {productBox({
                    img1: tp5,
                    img2: tp6,
                    img3: tp7,
                    img4: tp8,
                    title: "Nice to Have",
                    subTitle: "+5 more"
                  })}
                  {productBox({
                    img1: tp9,
                    img2: tp10,
                    img3: tp11,
                    img4: blackImg,
                    title: "Forgettable",
                    // subTitle: "+2 more"
                  })}
                </Box>





                <Box className="taskBoxBottomRow">
                  <Box className="taskBoxBottomRowItem">
                    {like}
                    <Typography>{t("commonTxt.Like")}</Typography>
                  </Box>
                  <Box className="taskBoxBottomRowItem">
                    {dislike}
                    <Typography>{t("commonTxt.dislike")}</Typography>
                  </Box>
                  <Box className="taskBoxBottomRowItem">
                    {comment}
                    <Typography>{t("commonTxt.comment")}</Typography>
                  </Box>
                </Box>
              </Box>



              <Box className="graySBox taskBox">
                <Box className="taskBoxTopDate">
                  <Typography className='taskHeaderText'>Task 3 Name</Typography>
                  <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "15px"
                  }}>
                    <Typography className='taskHeaderText2'>
                      {t("commonTxt.viewBtn")}
                    </Typography>
                  </Box>
                </Box>
                <Typography mb={3}>Which of these outdoor activities do you participate in? Please select all that apply and explain why these activities are the outdoor activities for you.</Typography>
                <Typography ml={5} my={3} sx={{
                  color: "#706C80"
                }}>No response added yet. Data will be visible once the participant responds.</Typography>
                <Box className="taskBoxBottomRow">
                  <Box className="taskBoxBottomRowItem">
                    {like}
                    <Typography>{t("commonTxt.Like")}</Typography>
                  </Box>
                  <Box className="taskBoxBottomRowItem">
                    {dislike}
                    <Typography>{t("commonTxt.dislike")}</Typography>
                  </Box>
                  <Box className="taskBoxBottomRowItem">
                    {comment}
                    <Typography>{t("commonTxt.comment")}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>



          <Box className="commentSection">
            <Box className="avatarBox">
              <img src={avatar2} />
              <img className='statusDot' src={idelDot} />
            </Box>

            <Box className="commentTextSection">
              <Box mb={5} className="taskBoxTopDate">
                <Typography className='userNameWspm'>
                  Kate Morrison
                  <span>completed the task</span>
                  <samp>Outdoor Activit</samp>
                  <span> under the activity</span>
                  <samp>Your Great Outdoors</samp>
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <Typography
                    className="taskHeaderText2" >
                    {t("commonTxt.viewBtn")}
                  </Typography>
                  <samp className="taskHeaderText3">
                    Aug 04, 2023 02:21 PM
                  </samp>
                </Box>
              </Box>
              <Typography>Please review the following Video. As you watch the video, pause te video to leave a comment with an associated tag type or just start typing your comment and the video will pause automatically.</Typography>

              <Box className="commentImg">
                <Box className="postImgBox">
                  <video className="video activeVideoItem" controls>
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </Box>

                <Box className="postImgLikeBox">

                  <Box className="postLikeRow">
                    <Box> <img src={emojiLike} />
                      <Typography className="likeHeader">Like:</Typography>
                      <Typography>That is such a lovely location</Typography></Box>
                    <span>00:10</span>
                  </Box>

                  <Box className="postLikeRow">
                    <Box> <img src={emojiConfused} />
                      <Typography className="likeHeader">Confused:</Typography>
                      <Typography>The water looks so cold</Typography></Box>
                    <span>00:15</span>
                  </Box>

                  <Box className="postLikeRow">
                    <Box> <img src={emojiLike} />
                      <Typography className="likeHeader">Like:</Typography>
                      <Typography>This is seriously the best place to chill</Typography>
                    </Box>
                    <span>00:20</span>
                  </Box>

                  <Box className="postLikeRow">
                    <Box> <img src={emojiDislike} />
                      <Typography className="likeHeader">Dislike:</Typography>
                      <Typography>Wish there where more trees over here</Typography>
                    </Box>
                    <span>00:25</span>
                  </Box>
                  <Typography className="taskHeaderText2">{t("commonTxt.viewMore")}</Typography>
                </Box>
              </Box>

              <Box mt={3} className="taskBoxBottomRow">
                <Box className="taskBoxBottomRowItem">
                  {like}
                  <Typography>{t("commonTxt.Like")}</Typography>
                </Box>
                <Box className="taskBoxBottomRowItem">
                  {dislike}
                  <Typography>{t("commonTxt.dislike")}</Typography>
                </Box>
                <Box className="taskBoxBottomRowItem">
                  {comment}
                  <Typography>{t("commonTxt.comment")}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>




          <Box className="commentSection">
            <Box className="avatarBox">
              <img src={avatar2} />
              <img className='statusDot' src={idelDot} />
            </Box>

            <Box className="commentTextSection">

              <Box mb={5} className="taskBoxTopDate">
                <Typography className='userNameWspm'>
                  Andi Lane
                  <span>posted on the topic</span>
                  <samp>Your Trekking Must Haves</samp>
                  <span>of</span>
                  <samp>Equipment Zone</samp>
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <Typography
                    className="taskHeaderText2" >
                    {t("commonTxt.viewBtn")}
                  </Typography>
                  <samp className="taskHeaderText3">
                    Aug 04, 2023 02:21 PM
                  </samp>
                </Box>
              </Box>

              <Typography>There are a few things everybody must carry while trekking to have a safe and sound trip. This includes lorem, ipsum, dolor, sit, amet, conseque. There are many more things that can be of help while trekking, but these are the absolute essentials according to me. </Typography>

              <Box mt={3} className="taskBoxBottomRow">
                <Box className="taskBoxBottomRowItem">
                  {like}
                  <Typography>{t("commonTxt.Like")}</Typography>
                </Box>
                <Box className="taskBoxBottomRowItem">
                  {dislike}
                  <Typography>{t("commonTxt.dislike")}</Typography>
                </Box>
                <Box className="taskBoxBottomRowItem">
                  {comment}
                  <Typography>{t("commonTxt.comment")}</Typography>
                </Box>
              </Box>

              <Box className="CommentBOx">

                <Box className="viewLessBox">
                  <Typography>{t("commonTxt.viewiewLessComments")}</Typography>
                  <img src={chevronDrop} />
                </Box>

                {singelComment({
                  img: avatar3,
                  name: "Richard Han",
                  comment: "Hey @everyone, what other items according to you can be used to avoid injuries while trekking?",
                  date: "Aug 10, 2023",
                  time: "10 h"
                })}
                {singelComment({
                  img: avatar1,
                  name: "Orlando Diggs",
                  comment: "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                  date: "Aug 10, 2023",
                  time: "10 h"
                })}
                {singelComment({
                  img: avatar3,
                  name: "Kate Morrison",
                  comment: "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                  date: "Aug 10, 2023",
                  time: "10 h"
                })}
                {writeCommentSection({ img: avatar1 })}
              </Box>
            </Box>
          </Box>
        </Box>
      }
    </>
  )
}
