import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { AntSwitch } from "../../AppTools/AppTool";

//images

export default function SkipResponsePop({
  setViewResponsePageNavDropVal,
  viewResponsePageNavDropVal,
}) {
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setViewResponsePageNavDropVal("Options");
    }
  };

  useEffect(() => {
    if (viewResponsePageNavDropVal === "Skip Response") {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [viewResponsePageNavDropVal]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{
          display:
            viewResponsePageNavDropVal === "Skip Response" ? "flex" : "none",
        }}
        className="companyPop AddCardPopPeople"
      >
        <Box
          sx={{
            display:
              viewResponsePageNavDropVal === "Skip Response" ? "flex" : "none",
          }}
          className="workspaceContaint rejectResponsePopMain"
        >
          <Box className="basicBoxItem">
            <Typography className="companyHeader">
              Are you sure you want to skip this participant over the task?
            </Typography>
          </Box>
          <Box className="basicBoxItem">
            <Typography>
              By doing so, the participant will not be able to respond to this
              particular task.
            </Typography>
            <Typography className="rejectResponseText">
              If the participant has already responded to this task, the
              Researcher or Collaborator needs to "Reject Response" from the
              Options tab as well.
            </Typography>
          </Box>
          <Box>
            <Typography className="eTemPopLabel addTemplatePopMessage">
              Reason for Skipping
            </Typography>
            <samp>
              (Optional comment, will only be visible to the participant)
            </samp>
          </Box>
          <Box className="basicBoxItem rejectResponseReason">
            <Typography>Hey Olivia,</Typography>
            <Typography>
              You don't need to respond to this task as lorem lpsum dolor sit
              amet
            </Typography>
          </Box>
          <Box className="airadioSwitchBox rejectResponseNotify">
            <AntSwitch
              defaultChecked
              inputProps={{ "aria-label": "ant design" }}
            />
            <Typography className="switchText">
              Notify participant by email immediately
            </Typography>
          </Box>
          <Box className="workPupBtnBox">
            <AppBtnOutLine
              buttonText="Cancel"
              onClick={() => setViewResponsePageNavDropVal("Options")}
            />
            <AppBtn buttonText="Skip Response" />
          </Box>
        </Box>
      </Box>
    </>
  );
}
