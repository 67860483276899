import React, { useState, useEffect } from "react";
import "./style.css";
import { Box, Typography } from "@mui/material";
import { AntSwitch, DropDownLists } from "../../../Components/AppTools/AppTool";
import { useNavigate } from "react-router-dom";

import { SearchBar } from "../../../Components/AppTools/AppTool";
import dropArrow from "../../../Assets/Images/chevron-down.png";
import exportIcon from "../../../Assets/Images/ExpoGroup.png";
import EmailIcon from "../../../Assets/Images/mailIcon.png";
import NIcon from "../../../Assets/Images/neutronIcon.png";
import userF from "../../../Assets/Images/userInF.png";
import groupsIcon from "../../../Assets/Images/groupsIcon.png";
import ImportantIcon from "../../../Assets/Images/ImportantIcon.png";
import EditIcon from "../../../Assets/Images/edit-3.png";
import WhitePlassIcon from "../../../Assets/Images/Wplus.png";
import DummyUser from "../../../Assets/Images/user-dummy.png";

import {
  participantsData,
  addIconBlack,
  xCircleBlack,
  removeGroupBlack,
  inviteGroupBlack,
} from "../../../Assets/Data";
import { GoTop } from "../../../Components/AppTools/AppTool";
import { AppBtn } from "../../../Components/AppTools/AppButton";

//components
import InviteProjectPop from "../../../Components/WSAdministration/InviteProjectPop";
import ExportPartcipantPop from "../../../Components/WSAdministration/ExportParticipantPop";
import { AppBtnOutLine } from "../../../Components/AppTools/AppButton";
import axios from "../../../lib/axios";
import { useAppSelector } from "../../../lib/store/store";
import { API_BASE_URL } from "../../../env";
import { formatDate, renderUserLayout, viewImage } from "../../../lib/helpers";
import DeactivateParticipantResearcherPop from "../../../Components/WSAdministration/DeactivateParticipantResearcher/DeactivateParticipantResearcherPop";
import AddProjectParticipantPop from "../../../Components/WSAdministration/AddProjectParticipantPop";
import RemoveProjectParticipantPop from "../../../Components/WSAdministration/RemoveProjectParticipantPop";
import Pagination from "../../../Components/AppTools/Pagination";
import SendEmailParticipantPopup from "../../../Components/WSAdministration/SendEmailParticipantPopup";
import PreviewEmailParticipantPopup from "../../../Components/WSAdministration/PreviewEmailParticipantPopup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";

export default function AllParticipants({}) {
  const workspaceState = useAppSelector((state) => state.workspace);
  const authState = useAppSelector((state) => state.auth);
  const [nav, setNav] = useState(0);
  const [projectDrop, setProjectDrop] = useState(false);
  const [projectDropVal, setProjectDropVal] = useState();

  const [fieldChoices, setFieldChoices] = useState([]);
  const [groupDropVal, setGroupDropVal] = useState();
  const [enGroupDropVal, setEnGroupDropVal] = useState();

  const [invitePop, setInvitePop] = useState(false);
  const [addProjectParticipantPop, setAddProjectParticipantPop] =
    useState(false);
  const [removeProjectParticipantPop, setRemoveProjectParticipantPop] =
    useState(false);
  const [editParticipantPage, setEditParticipantsPage] = useState(false);

  const [exportPop, setExportPop] = useState(false);

  const [scaleDrop, setScaleDrop] = useState(false);
  const [scaleDropVal, setScaleDropVal] = useState(false);

  const [attributeDrop, setAttributeDrop] = useState(false);
  const [attributeDropVal, setAttributeDropVal] = useState(false);

  const [enhancedDrop, setEnhancedDrop] = useState(false);
  const [enhancedDropVal, setEnhancedDropVal] = useState(false);

  const [valueDrop, setValueDrop] = useState(false);
  const [valueDropVal, setValueDropVal] = useState();

  const [bulkActionDrop, setBulkActionDrop] = useState(false);
  const [bulkActionDropVal, setBulkActionDropVal] = useState(false);
  const [bulkAction, setBulkAction] = useState(null);

  const [addParticipants, setAppParticipants] = useState(false);
  const [allParticipantsList, setAllParticipantsList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [deactivateParticipantPop, setDeactivateParticipantPop] =
    useState(false);
  const [participantList, setParticipantList] = useState("");
  const [projects, setProjects] = useState([]);
  const [groupsListData, setGroupsListData] = useState([]);
  const [basicGroupsListData, setBasicGroupsListData] = useState([]);
  const [enhancedGroupsListData, setEnhancedGroupsListData] = useState([]);
  const [reloadList, setReloadList] = useState(false);
  //pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [newArrayData, setNewArrayData] = useState([]);
  const [isApplyButtonEnabled, setIsApplyButtonEnabled] = useState(false);

  // State to hold details of checked users for inviting to the project
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [editBtn, setEditBtn] = useState(false);
  const [isBulkActionSelected, setIsBulkActionSelected] = useState(false);

  const [checkboxes, setCheckboxes] = useState(
    participantsData.map(() => false)
  );

  const [allCheckBox, setAllCheckBox] = useState(false);
  const [updateParticipantWithId, setUpdateParticipantWithId] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [customFieldList, setCustomFieldList] = useState([]);
  // const [sendEmailPopup, setSendEmailPopup] = useState(false);
  const [sendEmailPopup, setSendEmailPopup] = useState(false);
  const [advancedFilter, setAdvancedFilter] = useState({
    attribute: "",
    scale: "",
    value: [],
    enhanced_groups: "",
  });
  const [displayedValue, setDisplayedValue] = useState("");

  const [composeEmailPop, setComposeMailPop] = useState(false);
  const [previewMailPop, setPreviewMailPop] = useState(false);
  const [scheduleMailPop, setScheduleMailPop] = useState(false);
  const sendEmailObj = {
    users: [],
    subject: "",
    message: "",
  };
  const [emailval, setEmailval] = useState({ ...sendEmailObj });

  const Navigate = useNavigate();

  const handleMainOpenTabe = (Url) => {
    Navigate(Url);
  };

  // const handleRouting = (path, participant_Id) => {
  //   const url = participant_Id ? `${path}/${participant_Id}` : path;
  //   window.location.href = url;
  // };

  const handleCheckAllChange = (event) => {
    const isChecked = event.target.checked;
    setCheckboxes(checkboxes.map(() => isChecked));
    setAllCheckBox(isChecked);
    const newSelectedIds = isChecked
      ? allParticipantsList.map((el) => el._id)
      : [];
    setSelectedIds(newSelectedIds);
    setIsApplyButtonEnabled(isChecked);
  };

  const handleCheckboxChange = (el, index) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index] = !updatedCheckboxes[index];
    setCheckboxes(updatedCheckboxes);
    setUpdateParticipantWithId(el);

    const newSelectedIds = updatedCheckboxes[index]
      ? [...selectedIds, el._id]
      : selectedIds.filter((id) => id !== el._id);

    setSelectedIds(newSelectedIds);
    setIsApplyButtonEnabled(newSelectedIds.length > 0);

    if (updatedCheckboxes[index] && bulkActionDropVal === "Invite-to-Project") {
      setSelectedIds([...newSelectedIds, el._id]);
    } else if (
      !updatedCheckboxes[index] &&
      bulkActionDropVal === "Invite-to-Project"
    ) {
      setSelectedIds(newSelectedIds.filter((id) => id !== el._id));
    }
    setAllCheckBox(!updatedCheckboxes.includes(false));
  };

  const handleBulkActionChange = (action) => {
    setBulkActionDropVal(action);
    setIsBulkActionSelected(action !== null);
  };
  // console.log(checkboxes);
  const handleOptionIndex = (i) => {};

  const trueValues = checkboxes?.filter((value) => value === true);
  const isOnlyOneTrue = trueValues.length === 1;

  const handleSelectedParticipants = (selectedIds) => {
    setParticipantList(selectedIds);
  };

  useEffect(() => {
    printItemsByState(participantsData, currentPage);
  }, [currentPage]);

  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  const defaultFilters = {
    search: "",
    is_basic: true,
    is_advanced: false,
    project_ids: "",
    basic_groups: "",
    status: "active",
  };

  const [filterState, setFilterState] = useState({ ...defaultFilters });
  const [isDeactivated, setIsDeactivated] = useState(false);

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 25,
    totalPages: 0,
    totalItems: 0,
  });

  const handleFilterChange = (changedFilter) => {
    let newFilters = {
      ...filterState,
      ...changedFilter,
    };
    setFilterState(newFilters);
    getParticipants(1, newFilters);
  };

  const handleNavChange = (navValue) => {
    setNav(navValue);
    const newFilters = {
      ...filterState,
      is_basic: navValue === 0,
      is_advanced: navValue === 1,
    };
    setFilterState(newFilters);
    getParticipants(1, newFilters);
  };

  const handleFilterProjects = (val) => {
    if (typeof handleFilterChange != "undefined") {
      handleFilterChange({
        project_ids: val,
      });
    }
  };

  const handleFilterGroups = (val) => {
    if (typeof handleFilterChange != "undefined") {
      handleFilterChange({
        basic_groups: val,
      });
    }
  };

  const handleAdvFilterGroups = (val) => {
    setAdvancedFilter({
      ...advancedFilter,
      enhanced_groups: val,
    });
    setReloadList(true);
  };

  const clearFilters = () => {
    const newFilters = {
      ...defaultFilters,
    };
    setFilterState(newFilters);
    setProjectDropVal("");
    setGroupDropVal("");
    setEnGroupDropVal("");
    setDisplayedValue("");
    setIsDeactivated(false);
    getParticipants(1, newFilters);
  };

  const handleToggleChange = (event) => {
    const newStatus = event.target.checked ? "inactive" : "active";
    setIsDeactivated(event.target.checked);
    const newFilterState = { ...filterState, status: newStatus };
    setFilterState(newFilterState);
    getParticipants(1, newFilterState);
  };

  const onPageChange = (pageNum) => {
    getParticipants(pageNum);
  };

  useEffect(() => {
    getParticipants(1);
    getProjectLists();
    getGroupsLists();
    getCustomFields();
  }, []);

  const getCustomFields = async () => {
    try {
      const url = `/participant/profile-fields?workspace_id=${workspaceState?.selectedWorkspace?._id}&field_type=poll`;
      const data = await axios.get(url);
      setCustomFieldList(data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getProjectLists = async () => {
    try {
      const res = await axios.get(
        API_BASE_URL +
          `/researchers/projects?workspace_id=${workspaceState?.selectedWorkspace?._id}`
      );
      if (res.status) {
        setProjects(res.data.data);
      }
    } catch (error) {
      console.error("Error fetching project lists", error);
    }
  };

  const getGroupsLists = async () => {
    try {
      const res = await axios.get(API_BASE_URL + "/participant/groups");
      if (res.status) {
        const bGroup = res.data?.data?.filter(
          (grp) => grp.is_enhanced == false
        );
        const eGroup = res.data?.data?.filter((grp) => grp.is_enhanced == true);
        setBasicGroupsListData(bGroup);
        setEnhancedGroupsListData(eGroup);
      }
    } catch (error) {
      console.error("Error fetching project lists", error);
    }
  };

  const getParticipants = async (curPage, filters = filterState) => {
    setLoading(true);
    let url =
      API_BASE_URL +
      `/super-admin/admin/participants?page=${curPage}&search=${filters.search}&is_basic=${filters.is_basic}&is_advanced=${filters.is_advanced}&project_ids=${filters.project_ids}&basic_groups=${filters.basic_groups}&status=${filters.status}&workspace=${workspaceState?.selectedWorkspace?._id}&separate_workspace_row=true`;

    return await axios
      .get(url, {
        params: advancedFilter,
      })
      .then((res) => {
        setLoading(false);
        if (res.status) {
          setAllParticipantsList(res.data.data);
          setCheckboxes(res.data.data.map(() => false));
          setPaginationData({
            currentPage: res.data.pagination.currentPage,
            itemsPerPage: res.data.pagination.itemsPerPage,
            totalPages: res.data.pagination.totalPages,
            totalItems: res.data.pagination.totalItems,
          });
        }
      });
  };
  const handleChange = (event) => {
    setAdvancedFilter((prevGroup) => ({
      ...prevGroup,
      [event.target.name]: event.target.value,
    }));
    setReloadList(true);
  };
  const setChoices = (list) => {
    setDisplayedValue(list.question);
    setAdvancedFilter({ ...advancedFilter, value: [] });
    setFieldChoices(list.choices);
  };
  const handleRouting = (Url) => {
    Navigate(Url);
  };

  useEffect(() => {
    if (reloadList) {
      getParticipants(1);
      setReloadList(false);
      setAllCheckBox(false);
      setCheckboxes(checkboxes.map(() => false));
    }
  }, [reloadList]);

  const applyBulkAction = () => {
    if (selectedIds.length < 1)
      return toast.error(
        "Selection required: Please choose at least one participant."
      );
    handleSelectedParticipants(selectedIds); // Ensure selectedIds is defined appropriately
    if (bulkAction === "Add-to-Project") {
      setAddProjectParticipantPop(true);
    } else if (bulkAction === "Invite-to-Project") {
      setInvitePop(true);
    } else if (bulkAction === "Remove-to-Project") {
      setRemoveProjectParticipantPop(true);
    } else {
      setDeactivateParticipantPop(true);
    }
  };

  const sendEmailBtn = () => {
    if (selectedIds.length < 1)
      return toast.error(
        "Selection required: Please choose at least one participant."
      );
    handleSelectedParticipants(selectedIds);
    setSendEmailPopup(true);
  };

  const getActionBtns = () => {
    return (
      <>
        {!addParticipants ? (
          <Box className="AppNavBtnBox">
            <AppBtnOutLine buttonText="Export" />
            <Box
              className="AddPBtn"
              onClick={() =>
                handleMainOpenTabe("/workspace-administration/add-participants")
              }
            >
              <img src={WhitePlassIcon} />
              <Typography ml={1}>Add Participant</Typography>
            </Box>
          </Box>
        ) : null}
      </>
    );
  };

  const getChildren = () => {
    return (
      <Box className="researchersMainBox pageContainer">
        <Box className="workspaceItemBox CompaniesBox">
          <AddProjectParticipantPop
            setAddProjectParticipantPop={setAddProjectParticipantPop}
            addProjectParticipantPop={addProjectParticipantPop}
            participantList={participantList}
            setReloadList={setReloadList}
          />
          <InviteProjectPop
            setInvitePop={setInvitePop}
            invitePop={invitePop}
            participantList={participantList}
            setReloadList={setReloadList}
          />
          <RemoveProjectParticipantPop
            setRemoveProjectParticipantPop={setRemoveProjectParticipantPop}
            removeProjectParticipantPop={removeProjectParticipantPop}
            participantList={participantList}
            setReloadList={setReloadList}
          />
          <ExportPartcipantPop
            setExportPop={setExportPop}
            exportPop={exportPop}
            projects={projects}
            groupsListData={groupsListData}
            basicGroupsListData={basicGroupsListData}
          />
          <SendEmailParticipantPopup
            sendEmailPopup={sendEmailPopup}
            setSendEmailPopup={setSendEmailPopup}
            setPreviewMailPop={setPreviewMailPop}
            participantList={participantList}
            setEmailval={setEmailval}
            emailval={emailval}
          />
          <PreviewEmailParticipantPopup
            previewMailPop={previewMailPop}
            setPreviewMailPop={setPreviewMailPop}
            setSendEmailPopup={setSendEmailPopup}
            participantList={participantList}
            setEmailval={setEmailval}
            emailval={emailval}
            setReloadList={setReloadList}
          />
          <DeactivateParticipantResearcherPop
            deactivateParticipantPop={deactivateParticipantPop}
            setDeactivateParticipantPop={setDeactivateParticipantPop}
            participantList={participantList}
            setReloadList={setReloadList}
            isDeactivated={isDeactivated}
          />
          <Box className="AllPSubBox allPFilters">
            <Typography className="pageHeaderText">Filters</Typography>
            <Box className="allPNavTebe">
              <Box
                onClick={() => handleNavChange(0)}
                className={
                  nav === 0
                    ? "allpNavTebeItem allpNavTebeItemActive"
                    : "allpNavTebeItem"
                }
              >
                <Typography>Basic Filters</Typography>
              </Box>
              <Box
                onClick={() => handleNavChange(1)}
                className={
                  nav === 1
                    ? "allpNavTebeItem allpNavTebeItemActive"
                    : "allpNavTebeItem"
                }
              >
                <Typography>Advance Filters</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{ display: nav === 0 ? "block" : "none" }}
                className="basicFilterBox basicFilterBoxDifferent"
              >
                <Box
                  className="allPbasicFinputBox"
                  sx={{ display: nav === 0 ? "flex" : "none" }}
                >
                  <Box className="inputBoxWlabel">
                    <Typography className="inputLabel">Search</Typography>
                    <SearchBar
                      handleFilterChange={handleFilterChange}
                      val={filterState}
                    />
                  </Box>

                  <DropDownLists
                    label="Project"
                    value={projectDropVal}
                    items={projects}
                    handleSelection={(item) => {
                      handleFilterProjects(item._id);
                      setProjectDropVal(item.name);
                    }}
                    placeholder="Filter by Project"
                  />
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: nav === 0 ? "block" : "none",
                  }}
                  className="inputBoxWlabel"
                >
                  <DropDownLists
                    label="Groups"
                    value={groupDropVal}
                    items={basicGroupsListData}
                    handleSelection={(item) => {
                      handleFilterGroups(item._id);
                      setGroupDropVal(item.name);
                    }}
                    placeholder="Search Groups"
                    icon={groupsIcon}
                    iconClass="groupsIcon"
                  />
                </Box>
                <Box
                  sx={{ display: nav === 0 ? "flex" : "none" }}
                  className="filterBottomBox"
                >
                  <Box className="filterBottomInnerBox">
                    <Typography
                      mx={3}
                      className="LinkText"
                      onClick={clearFilters}
                    >
                      Clear
                    </Typography>
                    {/* <Typography mx={3} className="LinkText LinkTextApply">
                      Apply
                    </Typography> */}
                    <Typography>
                      {paginationData?.totalItems} matches
                    </Typography>
                  </Box>
                  <Box className="filterBottomInnerBox filterBottomInnerBox2">
                    <AntSwitch
                      checked={isDeactivated}
                      onChange={handleToggleChange}
                      inputProps={{ "aria-label": "ant design" }}
                    />
                    <Typography ml={2} className="switchText">
                      Only deactivated Participants
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {/* Fishes here */}

              {/* Advance Filters */}
              <Box
                sx={{
                  display: nav === 1 ? "block" : "none",
                  width: "100%",
                }}
                // className="advanceFiltersBox"
              >
                <Box className="instructionBar">
                  <Box>
                    <img src={ImportantIcon} />
                  </Box>
                  <Typography>
                    Advance filters can be saved as Enhanced Groups. Select an
                    attribute or start with an existing Enhanced Group.
                  </Typography>
                </Box>
                <Box mb={2} className="advanceFilterRowOne">
                  <Box className="inputBoxWlabel">
                    <Typography className="inputLabel">Attribute: 1</Typography>
                    {/*  */}
                    <FormControl sx={{ width: "100%" }} size="small">
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={advancedFilter.attribute}
                        onChange={handleChange}
                        name="attribute"
                        displayEmpty
                        renderValue={(selected) =>
                          displayedValue || "Select Attribute"
                        }
                      >
                        {/* <MenuItem value={0}>All Attributes</MenuItem> */}
                        {customFieldList.map((list, index) => (
                          <MenuItem
                            value={list._id}
                            key={index}
                            onClick={() => setChoices(list)}
                          >
                            {list.question}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box className="inputBoxWlabel">
                    <Typography className="inputLabel">Scale</Typography>

                    {/*  */}
                    <FormControl sx={{ width: "100%" }} size="small" value="0">
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        name="scale"
                        value={advancedFilter.scale.replaceAll("_", " ")}
                        onChange={handleChange}
                        displayEmpty
                        renderValue={(selected) => selected || "Select Scale"}
                      >
                        <MenuItem value={`any`} key={1}>
                          Any
                        </MenuItem>
                        <MenuItem value={`all_of_them`} key={2}>
                          All Off Them
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>

                <Box className="advancedFilterRowTwo" sx={{ width: "100%" }}>
                  <Box className="inputBoxWlabel">
                    <Typography className="inputLabel">Value</Typography>

                    <FormControl sx={{ width: "100%" }} size="small" value="1">
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={advancedFilter.value}
                        onChange={handleChange}
                        name="value"
                        multiple
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <Typography>Select All Choises</Typography>;
                          }
                          return selected
                            .map((value) => {
                              const profile = fieldChoices.find(
                                (profileData) => profileData._id === value
                              );
                              return profile ? profile.question : value;
                            })
                            .join(", ");
                        }}
                      >
                        {fieldChoices.map((list, index) => (
                          <MenuItem value={list.name} key={index}>
                            {list.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box className="straightLine">
                  <hr />
                  <Typography>OR</Typography>
                  <hr />
                </Box>
                <Box className="advancedFilterRowThree">
                  <Box className="inputBoxWlabel EnhancedGroupPop">
                    <Typography className="inputLabel">
                      Enhanced Group
                    </Typography>

                    <DropDownLists
                      label="Groups"
                      value={enGroupDropVal}
                      items={enhancedGroupsListData}
                      handleSelection={(item) => {
                        handleAdvFilterGroups(item._id);
                        setEnGroupDropVal(item.name);
                      }}
                      placeholder="Search Groups"
                      icon={groupsIcon}
                      iconClass="groupsIcon"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* Tale Starts here */}
          <Box className="AllPSubBox allPList">
            <Box className="allPNav">
              <Box
                sx={{
                  width: "46%",
                }}
              >
                <Typography className="pageHeaderText">
                  All Participants
                </Typography>
              </Box>

              <Box className="allPNavBox">
                <Box className="allPNavInnerBox">
                  <Box
                    sx={{ display: isOnlyOneTrue ? "flex" : "none" }}
                    onClick={() => {
                      // setEditParticipantsPage(true);
                      handleRouting(
                        `/workspace-administration/edit-participants/${updateParticipantWithId?._id}`
                      );
                      // GoTop();
                    }}
                    className="allIconBox allParticipantIcons"
                  >
                    <img
                      style={{ marginRight: "10px" }}
                      width={"20px"}
                      src={EditIcon}
                    />
                    <Typography>Edit</Typography>
                  </Box>

                  <Box
                    className="allIconBox allParticipantIcons"
                    onClick={() => {
                      setExportPop(true);
                      GoTop();
                    }}
                  >
                    <img
                      style={{ marginRight: "10px" }}
                      width={"20px"}
                      src={exportIcon}
                    />
                    <Typography>Export</Typography>
                  </Box>
                  <Box
                    minWidth={"118px"}
                    className="allIconBox allParticipantIcons"
                    onClick={sendEmailBtn}
                  >
                    <img
                      style={{ marginRight: "10px" }}
                      width={"20px"}
                      src={EmailIcon}
                    />
                    <Typography>Send Email</Typography>
                  </Box>
                </Box>
                <Box className="allPNavInnerBox">
                  <Box className="allPnavInput">
                    <Box
                      className="inputAppDrop"
                      onClick={() => setBulkActionDrop(!bulkActionDrop)}
                    >
                      <Typography>
                        {bulkActionDropVal || "Bulk Action"}
                      </Typography>
                      <img
                        style={{
                          rotate: bulkActionDrop ? "180deg" : "0deg",
                        }}
                        src={dropArrow}
                      />
                      {/* pop */}
                      <Box
                        sx={{ height: bulkActionDrop ? "auto" : "0px" }}
                        className="statusPop"
                      >
                        <Box
                          onClick={() => {
                            handleBulkActionChange("Add-to-Project");
                            setBulkAction("Add-to-Project");
                          }}
                          className="statusPopItem statusPopItemIcon statusParticipantPopItems"
                        >
                          {addIconBlack}
                          <Typography>Add to Project</Typography>
                        </Box>
                        <Box
                          onClick={() => {
                            handleBulkActionChange("Invite-to-Project");
                            setBulkAction("Invite-to-Project");
                            // GoTop();
                          }}
                          className="statusPopItem statusParticipantPopItems"
                        >
                          {inviteGroupBlack}
                          <Typography>Invite to Project</Typography>
                        </Box>
                        <Box
                          onClick={() => {
                            handleBulkActionChange("Remove-to-Project");
                            setBulkAction("Remove-to-Project");
                          }}
                          className="statusPopItem statusParticipantPopItems"
                        >
                          {removeGroupBlack}
                          <Typography>Remove to Project</Typography>
                        </Box>
                        {/* <Box
                        onClick={() =>{
                          handleBulkActionChange("Delete-Participants");
                          setDeleteParticipantPop(true);
                          handleDeleteParticipant(updateParticipantWithId)
                        }}
                        className="statusPopItem statusParticipantPopItems"
                      >
                        {trashBlack}
                        <Typography>Delete Participants</Typography>
                      </Box> */}
                        {!isDeactivated ? (
                          <Box
                            onClick={() => {
                              handleBulkActionChange("Deactivate-Participants");
                              setBulkAction("Deactivate-to-Project");
                            }}
                            className="statusPopItem statusParticipantPopItems"
                          >
                            {xCircleBlack}
                            <Typography>Deactivate Participants</Typography>
                          </Box>
                        ) : (
                          <Box
                            onClick={() => {
                              handleBulkActionChange("Activate-Participants");
                              setBulkAction("Activate-to-Project");
                            }}
                            className="statusPopItem statusParticipantPopItems"
                          >
                            {xCircleBlack}
                            <Typography>Activate Participants</Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <AppBtn
                  buttonText="Apply"
                  onClick={applyBulkAction}
                  disabled={!isBulkActionSelected}
                />
              </Box>
            </Box>

            {/* table */}
            <Box className="workListBox">
              <Box className="haderRow" minWidth={"1500px"}>
                <Box
                  minWidth={"240px"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0px",
                    paddingLeft: "11px",
                  }}
                  className="Tabelsel navFsel allpHTI participantCheckboxColor"
                >
                  <input
                    className="AllPTablechackBox"
                    type="checkbox"
                    checked={allCheckBox}
                    onChange={handleCheckAllChange}
                  />

                  <Typography>User Name</Typography>
                </Box>
                <Box minWidth={"200px"} className="Tabelsel tableItemSel">
                  <Typography>Email address</Typography>
                </Box>
                <Box minWidth={"200px"} className="Tabelsel tableItemSel">
                  <Typography>Created</Typography>
                </Box>
                <Box minWidth={"200px"} className="Tabelsel tableItemSel">
                  <Typography>Updated</Typography>
                </Box>
                <Box minWidth={"220px"} className="Tabelsel tableItemSel">
                  <Typography>Last Visit</Typography>
                </Box>
                <Box minWidth={"200px"} className="Tabelsel tableItemSel">
                  <img src={userF} />
                </Box>
                <Box minWidth={"200px"} className="Tabelsel tableItemSel">
                  <img src={NIcon} />
                </Box>
              </Box>

              {allParticipantsList &&
                allParticipantsList.map((el, index) => (
                  <Box className="TabelRow" minWidth={"1500px"}>
                    <Box
                      sx={{ position: "relative" }}
                      className="Tabelsel navFsel participantCheckboxColor"
                      minWidth={"240px"}
                    >
                      <input
                        className="AllPTablechackBox"
                        type="checkbox"
                        checked={checkboxes[index]}
                        onChange={() => handleCheckboxChange(el, index)}
                      />
                      <Box className="tabelAvatar">
                        {el?.profile_pic == "undefined" ||
                        el?.profile_pic == null ? (
                          <img src={DummyUser} />
                        ) : (
                          <img src={viewImage(el?.profile_pic)} />
                        )}
                      </Box>
                      <Typography sx={{ cursor: "pointer" }}>
                        {el?.name}
                        <br />
                        <span className="participantsTableText">
                          {el?.username}
                        </span>
                      </Typography>
                      <Box
                        sx={{
                          background: el?.online_status ? "#12B76A" : "#F04438",
                        }}
                        className="ActiveStatusParticipant"
                      ></Box>
                    </Box>
                    <Box
                      minWidth={"200px"}
                      className="Tabelsel TabelselItemCenter"
                    >
                      <Typography className="participantsTableText">
                        {el?.email}
                      </Typography>
                    </Box>
                    <Box
                      className="Tabelsel tableItemSel TabelselItemCenter"
                      minWidth={"200px"}
                    >
                      <Typography className="participantsTableText">
                        {formatDate(el?.created_at)}
                      </Typography>
                    </Box>
                    <Box
                      className="Tabelsel tableItemSel TabelselItemCenter"
                      minWidth={"220px"}
                    >
                      <Typography className="participantsTableText">
                        {formatDate(el?.updated_at)}
                      </Typography>
                    </Box>
                    <Box
                      className="Tabelsel tableItemSel TabelselItemCenter"
                      minWidth={"200px"}
                    >
                      <Typography className="participantsTableText">
                        {formatDate(el?.last_visit)}
                      </Typography>
                    </Box>
                    <Box
                      className="Tabelsel tableItemSel TabelselItemCenter"
                      minWidth={"200px"}
                    >
                      <Typography ml={1} className="participantsTableText">
                        {el?.custom_fields.length}
                      </Typography>
                    </Box>
                    <Box
                      className="Tabelsel tableItemSel TabelselItemCenter"
                      minWidth={"200px"}
                    >
                      <Typography ml={1} className="participantsTableText">
                        {el?.basic_groups.length + el?.enhanced_groups.length}
                      </Typography>
                    </Box>
                  </Box>
                ))}
            </Box>
            <Box className="pagination">
              <Pagination
                paginationData={paginationData}
                onPageChange={onPageChange}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return renderUserLayout(
    authState?.userType,
    getActionBtns(),
    getChildren(),
    "workadmin"
  );
}
