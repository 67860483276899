import { createSlice } from "@reduxjs/toolkit";

export const initialSidebars = {
  workspaceItemNavDrop: false,
  templateDrop: false,
  userDirectoryDrop: false,
  systemSettingsDrop: false,
  allParticipantsDrop: false,
  configurationDrop: false,
};

const initialState = {
  dropdowns: {
    ...initialSidebars,
  },
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setSidebarDropdowns: (state, action) => {
      state.dropdowns = {
        ...action.payload,
      };
    },
  },
});

export const { setSidebarDropdowns } = sidebarSlice.actions;
export const sidebarReducer = sidebarSlice.reducer;
