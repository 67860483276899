import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import axios from "../../../lib/axios";
import { TOAST_CONFIG } from "../../../lib/constants";
import { toast } from "react-toastify";
import { removeProfilePicture } from "../../../Services/adminActions";
import { useAppSelector } from "../../../lib/store/store";

//images

export default function DeleteProfilePicPop({ deleteProfilePop, setDeleteProfilePop, fetchUserProfile }) {
const authState = useAppSelector((state) => state.auth);
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
        setDeleteProfilePop(false);
    }
  };

  const deleteProfilePic = async () => {
    try {
      let response = await removeProfilePicture(authState.authToken);
      console.log("response", response);
      if (response.success) {
        setDeleteProfilePop(false);
        fetchUserProfile();
        toast.success(response.message, TOAST_CONFIG);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  };

  useEffect(() => {
    if (deleteProfilePop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [deleteProfilePop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: deleteProfilePop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople"
      >
        <Box
          sx={{ display: deleteProfilePop ? "flex" : "none" }}
          className="workspaceContaint deletePagePop"
        >
          <Typography className="companyHeader">Delete Profile Pic</Typography>

          <Box className="basicBoxItem">
            <Typography className='addPageLabel'>Are you sure you want to delete the this pic from Terapage ?</Typography>
            
          </Box>
          
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={() => setDeleteProfilePop(false)} />
            <AppBtn buttonText="Delete" onClick={deleteProfilePic} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
