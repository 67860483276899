import { Box } from '@mui/material'
import React, { useState } from 'react'
import DirectoryAdmin from '../../../../Components/Admin/DirectoryAdmin'
import AdminLayout from '../../partials/AdminLayout';
import { AppBtn, AppBtnOutLine } from '../../../../Components/AppTools/AppButton';

const SuperAdmin = () => {
  const [addAdminPop, setAddAdminPop] = useState(false);
  const [superAdminPop, setSuperAdminPop] = useState(false);
  const [inviteSupAdminPop, setInviteSupAdminPop] = useState(false);

  return (
    <AdminLayout
      childComponent={<Box
        sx={{ display: "flex" }}
        className="adminMainBox"
      >
        <DirectoryAdmin
          setSuperAdminPop={setSuperAdminPop}
          superAdminPop={superAdminPop}
          setInviteSupAdminPop={setInviteSupAdminPop}
          inviteSupAdminPop={inviteSupAdminPop}
          addAdminPop={addAdminPop}
          setAddAdminPop={setAddAdminPop}
        />
      </Box>}
      actionBtn={<>
        <Box className="AppNavBtnBox">
          <AppBtnOutLine
            onClick={() => setInviteSupAdminPop(true)}
            buttonText="Invite Super Admin"
            className="inviteAdmin"
          />

          <AppBtn
            onClick={() => setAddAdminPop(true)}
            buttonText="Add Super Admin"
          />
        </Box></>}
    />
  )
}

export default SuperAdmin