import React, { useState, useEffect } from 'react'
import { useSearchParams } from "react-router-dom";
import "./style.css"

//IMAGES
import whitedropIcon from "../../../Assets/Images/Wchevron-down.png";

import overViewImg1 from "../../../Assets/Images/overVImg1.png"
import overViewImg2 from "../../../Assets/Images/overVImg2.png"
import overViewImg3 from "../../../Assets/Images/overVImg3.png"
import overViewImg4 from "../../../Assets/Images/overVImg4.png"
import overViewImg5 from "../../../Assets/Images/overVImg5.png"



//component
import { Box, Typography } from '@mui/material'
import { AppBtn, AppBtnOutLine } from '../../AppTools/AppButton';
import { useTranslation } from 'react-i18next';
import { Status } from "../../AppTools/AppTool"

//data
import { ParticipantData, reportDropVal } from "../../../Assets/Data"



export default function RecapSection({
  dataReportTabe,
  setDataReportTabe,
  dropVal,
  setProjectSettingDropVal,
  projectSettingDropVal,
  setProjectSettingDrop,
  projectSettingDrop
}) {
  const [allTimeDrop, setAllTimeDrop] = useState(false)
  const [allTimeDropVal, setAllTimeDropVal] = useState()
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const allTimeItem = [
    {
      name: "daily",
      value: "daily",
    },
    {
      name: "weekly",
      value: "weekly",
    },
    {
      name: "monthly",
      value: "monthly",
    },
  ];

  const OverViewCard = ({ icon, title, num }) => {
    return (
      <Box className="overViewCard">
        <img src={icon} />
        <Typography>{title}</Typography>
        <span>{num}</span>
      </Box>
    )
  }
  const RecapSCard = ({ title, num }) => {
    return (
      <Box className="recapSCard">
        <Typography>{title}</Typography>
        <span>{num}</span>
      </Box>
    )
  }

  useEffect(() => {
    let selTab = searchParams.get("t");
    let selTab2 = searchParams.get("dr");
    if (selTab && selTab2 != null) {
      var query_string = selTab;
      query_string = query_string.replaceAll('_', ' ');
      query_string = query_string.replace(/(^\w|\s\w)/g, firstCharOfWord => firstCharOfWord.toUpperCase());
      console.log(query_string);
      if(selTab === "size_churn"){
        var query_string = "Size & Churn";
        setProjectSettingDropVal(query_string);
       setDataReportTabe(parseInt(selTab2));
       }
       else {
         setProjectSettingDropVal(query_string);
         setDataReportTabe(parseInt(selTab2));
       }
    }
    else if (selTab == null) {
      setDataReportTabe(parseInt(selTab2));
    }
  }, []);

  return (
    <>
      <Box className="recapSectionBox">
        <Box
          sx={{ display: projectSettingDropVal === "Recap" ? "block" : "none" }}
          className="reportContainer integrationsContainer compositionSection"
        >
          <Box className="projectBtnBox">
            <Box className="inteBtndropBox">
              <Box
                className="projectManagementNavBtn"
                onClick={() => setProjectSettingDrop(!projectSettingDrop)}
              >
                <Typography className="DropBtnText">
                  {projectSettingDropVal || "Project State"}
                </Typography>
                <img
                  className="dropIcon"
                  src={whitedropIcon}
                  style={{
                    rotate: projectSettingDrop ? "180deg" : "0deg",
                    transition: "all 0.5s",
                    marginLeft: "10px",
                  }}
                />
                <Box
                  className="workspaceItemNavPop projectItemNavPop"
                  sx={{ display: projectSettingDrop ? "flex" : "none" }}
                >
                  {reportDropVal?.map((el, i) => (
                    <Box key={i}
                    onClick={() => {
                      setSearchParams({ dr:dataReportTabe,t: el.qstring });
                      setProjectSettingDropVal(el.title);
                    }}
                    >
                      <Typography>{el.title}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Typography className='pageHeaderText'>{t("RecapPage.RecapHeader")}</Typography>

          <Box className="gapBox"></Box>
          <Typography className='psSubLabel'>{t("RecapPage.subHeader1")}</Typography>
          <Box className="overViewCardbox">
            {OverViewCard({ icon: overViewImg1, title: t("RecapPage.collaborators"), num: "1" })}
            {OverViewCard({ icon: overViewImg2, title: t("RecapPage.participants"), num: "15" })}
            {OverViewCard({ icon: overViewImg3, title: t("RecapPage.observers"), num: "1" })}
            {OverViewCard({ icon: overViewImg4, title: t("RecapPage.codes"), num: "8" })}
            {OverViewCard({ icon: overViewImg5, title: t("RecapPage.activities"), num: "15" })}
          </Box>
          <Box mb={4} mt={6} sx={{ width: "160px" }}>
            <Status itemAry={allTimeItem} label="All Time" setStatusDrop={setAllTimeDrop} statusDrop={allTimeDrop} setStatusDropVal={setAllTimeDropVal} statusDropVal={allTimeDropVal} />
          </Box>

          <Typography className='psSubLabel'>{t("RecapPage.subHeader2")}</Typography>

          <Box className="recapSCardBox">
            {RecapSCard({ title: t("RecapPage.participantVisits"), num: "93%" })}
            <Box className="recapSCardLine"></Box>
            {RecapSCard({ title: t("RecapPage.projectVisits"), num: "145" })}
          </Box>

          <Typography className='psSubLabel'>{t("RecapPage.subHeader3")}</Typography>

          <Box className="recapSCardBox">
            {RecapSCard({ title: t("RecapPage.completedActivities"), num: "32" })}
            <Box className="recapSCardLine"></Box>
            {RecapSCard({ title: t("RecapPage.ratingsComments"), num: "32" })}
            <Box className="recapSCardLine"></Box>
            {RecapSCard({ title: t("RecapPage.conversationPosts"), num: "32" })}
          </Box>


          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.resetBtn")} />
            <AppBtn buttonText={t("commonTxt.viewReportBtn")} />
          </Box>
        </Box>
      </Box>
    </>
  )
}
