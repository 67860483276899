import { Box } from '@mui/material'
import React from 'react'
import AdminLayout from '../partials/AdminLayout'
import Language from '../../../Components/Admin/Language'

const LanguagePage = () => {
  return (
    <AdminLayout
    childComponent={<Box
        className="adminMainBox"
        sx={{ display: "flex" }}
      >
        <Language />
      </Box>}
  />
  )
}

export default LanguagePage