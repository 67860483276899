import React, { useState, useEffect } from 'react'
import "./style.css"
import { Typography, Box } from '@mui/material'
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import redIcon from "../../../Assets/Images/redIicon.png"

//components
import { Status } from "../../../Components/AppTools/AppTool"
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AppBtn, AppBtnOutLine, RedBtn } from "../../../Components/AppTools/AppButton"
import { useAppSelector } from '../../../lib/store/store';
import { renderUserLayout } from '../../../lib/helpers';
import { toast } from "react-toastify";
import axios from "../../../lib/axios";
import { TOAST_CONFIG } from "../../../lib/constants";

export default function Subscription({ }) {
  const authState = useAppSelector((state) => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace?.selectedWorkspace?._id);
  const [loading, setLoading] = useState(false);
  const [workspaceSubscription, setWorkspaceSubscription] = useState([]);
  const [statusDrop, setStatusDrop] = useState()
  const [statusDropVal, setStatusDropVal] = useState("Days")
  const sbDateItem = [
    "Days",
    "Weeks",
    "Months",
    "Years"
  ]
  const label = " "

  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  useEffect(() => {
    fetchWorkspaceSubscription();
  }, []);

  const fetchWorkspaceSubscription = async () => {
    try {
      const response = await axios.get(`/researchers/workspace-settings/subscription/${workspaceState}`);
      if (response.success) {
        const workspaceDetail = response.data;
        console.log('Workspace Detail=>',workspaceDetail);
        setWorkspaceSubscription(workspaceDetail);
        setStartDate(dayjs(workspaceDetail?.start_date));
        setEndDate(dayjs(workspaceDetail?.end_date));
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  }

  const getChildren = () => {
    return <Box className="researchersMainBox pageContainer">
      <Box className="workspaceListBox subscriptionBox">
        <Box className="dismisNote">
          <img src={redIcon} />
          <Typography>Service Limit Exceeded: <span> Standard Video Minutes </span>(-30). <span>Contact us</span> to restore full functionality. You will be billed automatically each month or at the end of your subscription.</Typography>
          <RedBtn buttonText="Dismiss" />
        </Box>


        <Typography className='bsTitle'>Admin Access</Typography>
        <Typography className='infoSubTitle margin16'>Start : <span style={{ marginLeft: "18px" }}>{new Date(workspaceSubscription?.start_date).toLocaleString('default', { month: 'long' })} {new Date(workspaceSubscription?.start_date).getDay()}, {new Date(workspaceSubscription?.start_date).getFullYear()} at 12:00 AM️ </span></Typography>
        <Typography className='infoSubTitle'>End : <span style={{ marginLeft: "18px" }}>{new Date(workspaceSubscription?.start_date).toLocaleString('default', { month: 'long' })} {new Date(workspaceSubscription?.start_date).getDay()}, {new Date(workspaceSubscription?.start_date).getFullYear()} at 12:00 AM️ </span></Typography>
        <Box className="gapBox"></Box>

        <Typography className='bsTitle'>Phases</Typography>
        <Box className="gapBox"></Box>
        <Typography className='infoSubTitle'>Project Name: <span style={{ marginLeft: "18px" }}> Outdoor Journey</span></Typography>
        <Typography className='bsTitle2'>Participant Access</Typography>

        <Box className="sbDateInputBox">
          <input className='sbdInput phoneInput' value={10} />
          <Box className="sbDateSelect">
            <Status setStatusDrop={setStatusDrop} statusDrop={statusDrop} setStatusDropVal={setStatusDropVal} statusDropVal={statusDropVal} label={label} itemAry={sbDateItem} />
          </Box>
        </Box>

        <Box className="DateAndTimePicker">
          <Typography className='infoSubTitle'>Start:</Typography>
          <Box ml={2} className="dateTimeBox">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                value={startDate}
                onChange={setStartDate}
                referenceDate={dayjs('2022-04-17T15:30')}
              />
            </LocalizationProvider>
          </Box>
        </Box>


        <Box className="DateAndTimePicker">
          <Typography className='infoSubTitle'>End:</Typography>
          <Box ml={2.5} className="dateTimeBox">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                value={endDate}
                onChange={setEndDate}
                referenceDate={dayjs('2022-04-17T15:30')}
              />
            </LocalizationProvider>
          </Box>
        </Box>

        <Box className="sbDateInputBox">
          <Typography className='bsTitle2'>Active Participants: </Typography>
          <input style={{ marginLeft: "16px" }} className='sbdInput phoneInput' value={workspaceSubscription?.active_participants} />
        </Box>
        <Box className="sbDateInputBox">
          <Typography className='bsTitle2'>Open Projects: </Typography>
          <input style={{ marginLeft: "16px" }} className='sbdInput phoneInput' value={workspaceSubscription?.max_openprojects} />
        </Box>
        <Box className="gapBox"></Box>
        <Typography className='bsTitle'>Standard Video Minutes</Typography>


        <Box className="minutesBox">

          <Box className="minutesItemBox minutesItemActiveBox">
            <Typography>Recent Purchase</Typography>
            <span>+200</span>
            <Typography>Minutes (Mar 2023)</Typography>
          </Box>

        </Box>

        <Box className="minutesBox">

          <Box className="minutesItemBox">
            <Typography>Remaining</Typography>
            <span>200</span>
            <Typography>Minutes</Typography>
          </Box>

          <Box className="minutesItemBox">
            <Typography>April 2023</Typography>
            <span>0</span>
            <Typography>Minutes</Typography>
          </Box>

          <Box className="minutesItemBox">
            <Typography>Past 30 Days</Typography>
            <span>0</span>
            <Typography>Minutes</Typography>
          </Box>

        </Box>


        <Typography className='bsTitle'>Live Video Minutes</Typography>


        <Box mt={2} className="minutesBox">

          <Box className="minutesItemBox minutesItemActiveBox">
            <Typography>Recent Purchase</Typography>
            <span>+200</span>
            <Typography>Minutes (Mar 2023)</Typography>
          </Box>

        </Box>

        <Box className="minutesBox">

          <Box className="minutesItemBox">
            <Typography>Remaining</Typography>
            <span>200</span>
            <Typography>Minutes</Typography>
          </Box>

          <Box className="minutesItemBox">
            <Typography>April 2023</Typography>
            <span>0</span>
            <Typography>Minutes</Typography>
          </Box>

          <Box className="minutesItemBox">
            <Typography>Past 30 Days</Typography>
            <span>0</span>
            <Typography>Minutes</Typography>
          </Box>

        </Box>
        <Box className="workPupBtnBox">
          <AppBtnOutLine buttonText="Cancel" />
          <AppBtn buttonText="Save" />
        </Box>
      </Box>
    </Box>
  }

  return renderUserLayout(authState?.userType, null, getChildren(), 'workadmin');
}
