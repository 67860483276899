import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";

import arrow from "../../../Assets/Images/arrow.png";
import { SearchBar, LatestCreated } from "../../../Components/AppTools/AppTool";
import {
  editIconBlack,
  archiveBlack,
  trashBlack,
  viewIconWhite,
  emailTemplateData,
} from "../../../Assets/Data";

import { sideHaumIcon } from "../../../Assets/Data";
import WhitePlassIcon from "../../../Assets/Images/Wplus.png";

//components
import AddTemplatePop from "../../../Components/WSAdministration/AddTemplatePop";
import SideMenu from "../../../Components/WSAdministration/SideMenu";
import { GoTop } from "../../../Components/AppTools/AppTool";
import ViewWSTemplatePop from "../ViewWSTemplatePop";
import EditWSTemplatePop from "../EditWSTemplatePop";
import DeleteWSTemplatePop from "../DeleteWSTemplatePop";
import ResearcherLayout from "../../Researchers/partials/ResearcherLayout";
import AdminLayout from "../../Admin/partials/AdminLayout";
import { useAppSelector } from "../../../lib/store/store";
import { renderUserLayout } from "../../../lib/helpers";

export default function Template({ }) {
  const authState = useAppSelector((state) => state.auth);
  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Latest Created");
  const [optionIndex, setOptionIndex] = useState();
  const [workSpaceOption, setWorkspaceOption] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [newArrayData, setNewArrayData] = useState([]);
  const [configTemplatePop, setConfigTemplatePop] = useState(false);

  const [viewWSTemplatePop, setViewWSTemplatePop] = useState(false);
  const [editTemplatePop, setEditWSTemplatePop] = useState(false);

  const [deleteWSTemplatePop, setDeleteWSTemplatePop] = useState(false);

  const handleOptionIndex = (i) => {
    setOptionIndex(i);
  };

  useEffect(() => {
    printItemsByState(emailTemplateData, currentPage);
  }, [currentPage]);

  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  const getChildren = () => {
    return <Box className="researchersMainBox pageContainer">
      <Box className="workspaceListBox workspaceListBoxV1">
        <AddTemplatePop
          configTemplatePop={configTemplatePop}
          setConfigTemplatePop={setConfigTemplatePop}
        />
        <Box mb={1} className="workListNavItem workListNavItemMobile">
          <Typography className="pageHeaderText">Templates</Typography>
        </Box>
        <Box className="workListNav">
          <Box className="workListNavItem workListNavItemDesktop">
            <Typography className="pageHeaderText">Templates</Typography>
          </Box>
          <Box className="workListNavItem workListSearchBar configTemplateSearch">
            <SearchBar />
          </Box>

          <Box
            sx={{ minWidth: "215px" }}
            className="workListNavItem workLatest"
          >
            <LatestCreated
              setLatestDrop={setLatestDrop}
              latestCDrop={latestCDrop}
              setLatestDropVal={setLatestDropVal}
              latestDropVal={latestDropVal}
            />
          </Box>
        </Box>

        <Box className="workListBox">
          <Box className="haderRow" minWidth={"1111px"}>
            <Box
              minWidth={"32%"}
              className="Tabelsel navFsel templatehead"
            >
              <Typography>Template</Typography>
            </Box>

            <Box className="Tabelsel statusSel" minWidth={"32%"}>
              <Typography>Projects</Typography>
            </Box>
            <Box
              className="Tabelsel statusSel templateheadl"
              minWidth={"30%"}
            >
              <Typography>Date</Typography>
            </Box>
          </Box>
          {newArrayData &&
            newArrayData?.map((el, index) => (
              <Box key={index} className="TabelRow" minWidth={"1111px"}>
                <Box
                  minWidth={"32%"}
                  className="Tabelsel navFsel navtable"
                >
                  <Box
                    id="dot"
                    onClick={() => {
                      setWorkspaceOption(!workSpaceOption);
                      setOptionIndex(index);
                    }}
                    className={
                      workSpaceOption && index === optionIndex
                        ? "dot dotActive"
                        : "dot"
                    }
                  >
                    {
                      <Box
                        sx={{
                          display:
                            workSpaceOption && index === optionIndex
                              ? "flex"
                              : "none",
                        }}
                        className="optionPop"
                      >
                        <Box
                          className="optionPopItem"
                          onClick={() => setViewWSTemplatePop(true)}
                        >
                          {viewIconWhite}
                          <Typography>View Template</Typography>
                        </Box>
                        <Box
                          className="optionPopItem"
                          onClick={() => setEditWSTemplatePop(true)}
                        >
                          {editIconBlack}
                          <Typography>Edit Template</Typography>
                        </Box>

                        <Box className="optionPopItem">
                          {archiveBlack}
                          <Typography>Archive Template</Typography>
                        </Box>
                        <Box
                          className="optionPopItem"
                          onClick={() => setDeleteWSTemplatePop(true)}
                        >
                          {trashBlack}
                          <Typography>Delete Template</Typography>
                        </Box>
                      </Box>
                    }
                  </Box>

                  <Typography>{el.title}</Typography>
                </Box>

                <Box
                  className="Tabelsel statusSel Tabelcentersel"
                  minWidth={"32%"}
                >
                  <Typography>{el.statement}</Typography>
                </Box>

                <Box
                  className="Tabelsel statusSel Tabelcentersel"
                  minWidth={"30%"}
                >
                  <Typography>{el.date}</Typography>
                </Box>
              </Box>
            ))}
        </Box>

        <Box className="pagenation">
          <Box onClick={handlePrev} className="tabelBtn">
            <img src={arrow} />
            <Typography>Previous</Typography>
          </Box>

          <Box className="pageNumberBox">
            <Box
              onClick={() => setCurrentPage(0)}
              className={
                currentPage === 0
                  ? "pageNumber pageNumberActive"
                  : "pageNumber"
              }
            >
              <p>1</p>
            </Box>
            <Box
              onClick={() => setCurrentPage(1)}
              className={
                currentPage === 1
                  ? "pageNumber pageNumberActive"
                  : "pageNumber"
              }
            >
              <p>2</p>
            </Box>
            <Box
              onClick={() => setCurrentPage(2)}
              className={
                currentPage === 2
                  ? "pageNumber pageNumberActive"
                  : "pageNumber"
              }
            >
              <p>3</p>
            </Box>
            <Box
              onClick={() => setCurrentPage(3)}
              className={
                currentPage === 3
                  ? "pageNumber pageNumberActive"
                  : "pageNumber"
              }
            >
              <p>4</p>
            </Box>
            <Box
              onClick={() => setCurrentPage(4)}
              className={
                currentPage === 4
                  ? "pageNumber pageNumberActive"
                  : "pageNumber"
              }
            >
              <p>5</p>
            </Box>
          </Box>

          <Box onClick={handleNext} className="tabelBtn">
            <Typography>Next</Typography>
            <img style={{ rotate: "180deg" }} src={arrow} />
          </Box>
        </Box>
      </Box>
    </Box>
  }

  const getActionBtns = () => {
    return (
      <>
        <Box
          onClick={() => {
            setConfigTemplatePop(true);
            GoTop();
          }}
          className="AddPBtn"
        >
          <img src={WhitePlassIcon} />
          <Typography ml={1}>Add Template</Typography>
        </Box>
      </>
    )
  }

  return (
    <>
      <Box className="ResearchersContainer ">
        <ViewWSTemplatePop
          setViewWSTemplatePop={setViewWSTemplatePop}
          viewWSTemplatePop={viewWSTemplatePop}
        />
        <EditWSTemplatePop
          setEditWSTemplatePop={setEditWSTemplatePop}
          editTemplatePop={editTemplatePop}
        />
        <DeleteWSTemplatePop
          deleteWSTemplatePop={deleteWSTemplatePop}
          setDeleteWSTemplatePop={setDeleteWSTemplatePop}
        />
        {renderUserLayout(authState?.userType, getActionBtns(), getChildren(), 'workadmin')}
      </Box>
    </>
  );
}
// New Branch
