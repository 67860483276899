import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import iIcon from "../../../Assets/Images/IIcon.png";

export default function ParticipantScreeningNotices() {
  const [signatureTextLaOut, setSignatureTextLaOut] = useState("start");
  const [signatureTextItalic, setSignatureTextItalic] = useState(false);
  const [signatureTextBold, setSignatureTextBold] = useState(false);
  const [signatureTextUnderLine, setSignatureTextUnderLine] = useState();
  const [emailval, setEmailval] = useState();
  const [emailval2, setEmailval2] = useState();

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const email =
    "Welcome to our project. Before getting started, please complete the questions below. Space is limited in the project so not everyone will be accepted right away. Thank you for your interest and time.";

  const email2 =
    "Thank you for completing our questions. You have qualified and may enter the project now.";
  return (
    <>
      <Box className="iBox">
        <img src={iIcon} />
        <Typography>{t("participantScreening.noteTexts3")}</Typography>
      </Box>

      <Box className="agrrementPopLabel sameLineTexts">
        <Typography className="theLabels">
          {t("participantScreening.subHeaderNoticesPage1")}
        </Typography>

        <Typography className="siteNoticeEditLink">
          {t("participantScreening.linkTextNotices1")}
        </Typography>
      </Box>

      <Box className="emailInputBox">
        <textarea
          style={{
            textAlign: signatureTextLaOut,
            fontStyle: signatureTextItalic ? "italic" : "normal",
            fontWeight: signatureTextBold ? "bold" : "normal",
            textDecoration: signatureTextUnderLine,
          }}
          className="emailInput"
          name="emailSignature"
          value={emailval?.emailSignature || email}
          // onChange={(e) => handelChange(e, 1)}
        />
      </Box>

      <Box className="agrrementPopLabel sameLineTexts">
        <Typography className="theLabels">
          {t("participantScreening.subHeaderNoticesPage2")}
          {/* Notice to applicants that <span className="purpleText">qualify</span>{" "}
          and <span className="purpleText">are</span> accepted */}
        </Typography>

        <Typography className="siteNoticeEditLink">
          {t("participantScreening.linkTextNotices1")}
        </Typography>
      </Box>

      <Box className="emailInputBox">
        <textarea
          style={{
            textAlign: signatureTextLaOut,
            fontStyle: signatureTextItalic ? "italic" : "normal",
            fontWeight: signatureTextBold ? "bold" : "normal",
            textDecoration: signatureTextUnderLine,
          }}
          className="emailInput"
          name="emailSignature"
          value={emailval2?.emailSignature || email2}
          // onChange={(e) => handelChange(e, 1)}
        />
      </Box>
    </>
  );
}
