import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import "./style.css";

import axios from "../../../lib/axios";
import { TOAST_CONFIG } from "../../../lib/constants";

//images
import iIcon from "../../../Assets/Images/IIcon.png";

import { API_BASE_URL } from "../../../env";

//component
import { AppBtnOutLine, AppBtn } from "../../../Components/AppTools/AppButton";
import { DropDownLists } from "../../../Components/AppTools/AppTool";
import { useAppSelector } from "../../../lib/store/store";
import { renderUserLayout } from "../../../lib/helpers";

export default function BasicSetting({}) {
  const authState = useAppSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [allSelectedLanguage, setAllSelectedLanguage] = useState("");
  const [allLanguageChunk, setAllLanguageChunk] = useState([]);
  const [defaultLanguage, setDefaultLanguage] = useState();
  const workspaceState = useAppSelector(
    (state) => state.workspace?.selectedWorkspace?._id
  );

  const addLanguageSetting = {
    workspace_ids: workspaceState,
    languge_ids: [],
  };

  const [addLanguageToWorkspace, setAddLanguageToWorkspace] = useState({
    ...addLanguageSetting,
    workspace_ids: [], // Initialize with an empty array
  });

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "200px",
        overflowY: "auto",
      },
    },
  };

  const settingDetail = {
    workspace_id: workspaceState,
    contact_information: {
      name: "",
      email: "",
    },
    site_name: "",
    site_domain: "",
    site_description: "",
    google_analytics: "",
    default_language: "664d7e1f733c492ec92a1c2f",
    secondary_languages: [
      "664d7e50cfc2371f82e9e633",
      "664d7e65cfc2371f82e9e637",
    ],
    site_logo_url: "",
    logout_url: "",
    allow_search: "true",
  };

  // const [basicSettings, setBasicSettings] = useState({ ...settingDetail });

  const [basicSettings, setBasicSettings] = useState({
    ...settingDetail,
    default_language: "", // initial default language
    secondary_languages: [], // initial secondary languages
  });

  useEffect(() => {
    fetchConfig();
    getLanguages();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await axios.get(`/researchers/workspace-settings`);
      if (response.success) {
        if (response.data.data.length > 0) {
          const basicSettingsdata = response.data.data[0];

          let sateData = {};
          for (let key in basicSettings) {
            sateData[key] = basicSettingsdata[key];
          }
          setBasicSettings(sateData);
        }
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  const getLanguages = async (filters = {}) => {
    try {
      let url = `/master/languages?page=1`;
      for (const key in filters) {
        if (filters.hasOwnProperty(key)) {
          url += `&${key}=${filters[key]}`;
        }
      }
      const response = await axios.get(url);

      if (response.success) {
        const selectedCount = response.data.data.filter(
          (el) => el.status === "active"
        ).length;
        setAllSelectedLanguage(`${selectedCount} Languages Selected`);
        setAllLanguageChunk(chunkArray(response.data.data, 2));
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  };

  const activeCountries = [];

  allLanguageChunk.forEach((chunk) => {
    chunk.forEach((country) => {
      if (country.status === "active") {
        activeCountries.push({ id: country._id, name: country.name });
      }
    });
  });

  const chunkArray = (arr, size) => {
    return arr.reduce((acc, _, index) => {
      if (index % size === 0) {
        acc.push(arr.slice(index, index + size));
      }
      return acc;
    }, []);
  };

  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setBasicSettings((prevSettings) => ({
  //     ...prevSettings,
  //     [name]: typeof value === "string" ? value.split(",") : value,
  //   }));
  // };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setBasicSettings((prevSettings) => {
      if (name === "default_language") {
        return {
          ...prevSettings,
          default_language: value,
        };
      } else if (name === "secondary_languages") {
        return {
          ...prevSettings,
          secondary_languages:
            typeof value === "string" ? value.split(",") : value,
        };
      } else {
        return {
          ...prevSettings,
          [name]: value,
        };
      }
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setBasicSettings((prevSettings) => ({
      ...prevSettings,
      [name]: checked ? "true" : "false",
    }));
  };

  // console.log("===All activeCountries Lang==:", activeCountries);
  // console.log("===All Chunked Lang==:", allLanguageChunk);

  // const allActiveLanguage = allLanguageChunk

  const handleSaveSettings = async () => {
    console.log("====Basic Settings===:", basicSettings);
    const payload = {
      ...basicSettings,
      default_language: basicSettings.default_language,
      secondary_languages: basicSettings.secondary_languages,
    };

    try {
      let data = await axios.post(
        API_BASE_URL + `/researchers/workspace-settings/save`,
        payload,
        authState.authToken
      );
      console.log(data.data);
      return data.data;
    } catch (error) {
      let message = "Something went wrong, please try again!";
      let status_code = 500;
      if (error.response) {
        message = error.response.data.message;
        status_code = error.response.status;
      }
      return {
        success: false,
        message: message,
        status_code: status_code,
      };
    }
  };

  const setFormData = (e) => {
    const { name, value } = e.target;

    // Check if the name includes 'contact_information'
    if (name.includes("contact_information")) {
      // Extract the key (either 'name' or 'email')
      const key = name.split(".")[1];
      // Update the contact_information object with the new value
      setBasicSettings((prevSettings) => ({
        ...prevSettings,
        contact_information: {
          ...prevSettings.contact_information,
          [key]: value,
        },
      }));
    } else {
      // Update other fields
      setBasicSettings((prevSettings) => ({
        ...prevSettings,
        [name]: value,
      }));
    }
  };

  // console.log("===Form Data Worked===:", basicSettings);

  const isChecked = (allowed) => {
    return allowed === "active" ? true : false;
  };

  const handleReset = () => {
    setBasicSettings({
      contact_information: {},
      site_name: "",
      site_domain: "",
      site_description: "",
      google_analytics: "",
      default_language: "",
      secondary_languages: [],
      site_logo_url: "",
      logout_url: "",
      allow_search: "true",
    });
  };
  const getChildren = () => {
    return (
      <Box className="researchersMainBox pageContainer">
        <Box className="workspaceListBox basicSettingBox">
          <Typography className="bsTitle">Site Name</Typography>
          <Typography className="bsNText">
            The site name appears primarily in the title bar of the browser and
            as the "From" in emails generated by the site. It can be the name of
            your organization or be made generic (e.g. Terapage Community).
          </Typography>
          <input
            className="phoneInput"
            name="site_name"
            value={basicSettings?.site_name}
            placeholder="Enter Site Name"
            onChange={setFormData}
          />
          <Box className="gapBox"></Box>

          <Typography className="bsTitle">Site Domain</Typography>
          <Typography className="bsNText">
            Every site has a primary domain{" "}
            <span className="bslinkText">
              (e.g. https://sitename.terapage.com)
            </span>
            . If you'd like to use a custom domain, please contact us for
            details.
          </Typography>
          <input
            className="phoneInput"
            name="site_domain"
            value={basicSettings?.site_domain}
            placeholder="Enter Site Domain"
            onChange={setFormData}
          />
          <Box className="gapBox"></Box>

          <Box className="iBox">
            <img src={iIcon} />
            <Typography>
              Search engines cannot enter and index the content of your Projects
              as the site is protected by a user login form. Allowing search
              engines to find the site only causes the login page to be indexed
              along with the description you provide.
            </Typography>
          </Box>

          <Typography className="bsTitle">Site Description</Typography>
          <Typography className="bsNText">
            The site description is primarily to benefit search engines that may
            locate the site. You can provide the text that will be shown by
            Google and others. By default, the site tells search engines to skip
            indexing of the site.
          </Typography>
          <input
            className="phoneInput"
            name="site_description"
            value={basicSettings?.site_description}
            placeholder="Enter External Project ID"
            onChange={setFormData}
          />

          <Box mt={3} className="checkWlabel">
            <input
              className="custom-checkbox"
              name="allow_search"
              type="checkBox"
              defaultChecked={basicSettings.allow_search === "true"}
              onChange={handleCheckboxChange}
            />
            <Typography>Allow search engines to find this site</Typography>
          </Box>
          <Box className="gapBox"></Box>

          <Typography className="bsTitle">Contact Information</Typography>
          <Typography className="bsNText">
            Provide the name and email address of a Support Contact, or shared
            inbox, that will be responsible for questions by end users that are
            needing support. Such inquiries will typically be related to users
            having difficulty joining or returning to a Project.
          </Typography>
          <input
            style={{ marginBottom: "16px" }}
            name="contact_information?.name"
            className="phoneInput"
            value={basicSettings?.contact_information?.name}
            placeholder="Enter Name of Support Contact"
            onChange={setFormData}
          />
          <input
            className="phoneInput"
            name="contact_information?.email"
            value={basicSettings?.contact_information?.email}
            placeholder="Enter an Email Address for Support Contact"
            onChange={setFormData}
          />

          <Typography className="bsTitle labelMargin">
            Website Integration
          </Typography>

          <Box mb={5} className="iBox">
            <img src={iIcon} />
            <Typography>
              We do not recommend setting up a custom Site Logo Destination URL
              as it may impact overall usability. Many people may treat the
              top-left logo of a site as a way to navigate back to the root page
              of the same site. If the logo URL is customized such that it exits
              the site, your participants may feel lost.
            </Typography>
          </Box>

          <Typography className="bsSubTitle">
            Site Logo Destination URL
          </Typography>
          <Typography className="bsNText">
            Location to redirect users if they select the site logo on the
            top-left of all pages
          </Typography>
          <input
            className="phoneInput"
            name="site_logo_url"
            value={basicSettings?.site_logo_url}
            placeholder="Enter Site Logo Destination URL"
            onChange={setFormData}
          />
          <Box className="gapBox"></Box>

          <Typography className="bsSubTitle">Logout Destination URL</Typography>
          <Typography className="bsNText">
            Location to redirect users after they press the Logout button
          </Typography>
          <input
            className="phoneInput"
            name="logout_url"
            value={basicSettings?.logout_url}
            placeholder="Enter Logout Destination URL"
            onChange={setFormData}
          />
          <Box className="gapBox"></Box>

          <Typography className="bsSubTitle">Google Analytics</Typography>
          <Typography className="bsNText">
            Enter the GA Tracking ID (e.g. UA-12345678-1) to include this site
            in your statistics
          </Typography>
          <input
            className="phoneInput"
            name="google_analytics"
            placeholder="Enter the GA Tracking ID"
            value={basicSettings?.google_analytics}
            onChange={setFormData}
          />
          <Box className="gapBox"></Box>

          <Typography className="bsTitle">Site Language</Typography>
          <Box mt={3} className="companiesTopBox">
            <Box className="companyInputBoxWlabel">
              <Typography mb={1} className="inputLabel">
                Default Language
              </Typography>
              <FormControl sx={{ width: "100%" }} size="medium">
                <Select
                  labelId="default-language-select-label"
                  id="default-language-select"
                  value={basicSettings.default_language}
                  onChange={handleChange}
                  name="default_language"
                  displayEmpty
                >
                  {activeCountries.map((language) => (
                    <MenuItem key={language.id} value={language.id}>
                      {language.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {/* Secondary Language Drop Down */}

            <Box className="companyInputBoxWlabel">
              <Typography mb={1} className="inputLabel">
                Secondary Language
              </Typography>
              {/* <FormControl sx={{ width: "100%" }} size="small" value="1">
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={
                    Array.isArray(addLanguageToWorkspace.workspace_ids)
                      ? addLanguageToWorkspace.workspace_ids
                      : []
                  }
                  onChange={handleChange}
                  name="workspace_ids"
                  multiple
                  displayEmpty
                  MenuProps={MenuProps}
                  renderValue={(selected) => {
                    if (!Array.isArray(selected) || selected.length === 0) {
                      return <p>Select Language</p>;
                    }
                    return selected
                      .map((value) => {
                        const language = activeCountries.find(
                          (language) => language.id === value
                        );
                        return language ? language.name : value;
                      })
                      .join(", ");
                  }}
                >
                  {activeCountries.map((language, index) => (
                    <MenuItem value={language.id} key={index}>
                      {language.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}

              <FormControl sx={{ width: "100%" }} size="medium">
                <Select
                  labelId="secondary-languages-select-label"
                  id="secondary-languages-select"
                  multiple
                  value={basicSettings.secondary_languages}
                  onChange={handleChange}
                  name="secondary_languages"
                  displayEmpty
                  renderValue={(selected) =>
                    selected.length === 0
                      ? "Select Languages"
                      : selected
                          .map((id) => {
                            const language = activeCountries.find(
                              (lang) => lang.id === id
                            );
                            return language ? language.name : id;
                          })
                          .join(", ")
                  }
                >
                  {activeCountries.map((language) => (
                    <MenuItem key={language.id} value={language.id}>
                      {language.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Language Drop Down */}
            </Box>
          </Box>
          <Box className="gapBox"></Box>
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Reset" onClick={handleReset} />
            <AppBtn buttonText="Save" onClick={handleSaveSettings} />
          </Box>
        </Box>
      </Box>
    );
  };

  return renderUserLayout(
    authState?.userType,
    null,
    getChildren(),
    "workadmin"
  );
}
