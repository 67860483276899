import { useState } from "react";
import {
  Box,
  Typography,
  Dialog,
  FormLabel,
  Grid,
  TextField,
  Alert,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import ReactFlagsSelect from "react-flags-select";
import "./style.css";

import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import IIcon2 from "../../../Assets/Images/IIcon.png";
import { updateProject } from "../../../Services/adminActions";
import { useAppSelector } from "../../../lib/store/store";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { getCountries } from "../../../Services/master";
import { formatDate } from "../../../lib/helpers";

const EditProjectNextPop = ({
  editProjectNextPop,
  setEditProjectNextPop,
  setEditProjectPop,
  fullScreen,
  setReloadList,
  reloadList,
  setProjectState,
  projectState,
  setImage,
  setProjectID,
  projectID
}) => {
  const handleClose13 = () => {
    setEditProjectPop(false);
    setEditProjectNextPop(false);
  };

  const handleBack = () => {
    setEditProjectPop(true);
    setEditProjectNextPop(false);
  };

  const [selected, setSelected] = useState("");
  const [selected1, setSelected1] = useState("");
  const [SelectTimeZoneDrop, setSelectTimeZoneDrop] = useState(false);
  const [SelectTimeZoneDropVal, setSelectTimeZoneDropVal] = useState("");
  const [timezoneList, setTimezoneList] = useState([]);
  const [checked, setChecked] = useState(true);
  const authState = useAppSelector(state => state.auth);
  const [loading, setLoading] = useState(false);

  const setFormData = (e) => {
    let newState = {
      ...projectState,
      [e.target.name]: e.target.value,
    };

    setProjectState(newState);
  };

  const getTimezone = async (code) => {
    setTimezoneList([]);
    getCountries({ iso2: code }).then(response => {
      if (response.success) {
        if (response?.data?.data.length > 0) {
          const country = response?.data?.data[0]
          setTimezoneList(country.timezones)
        }
      }
    })
  }

  const editProject = async () => {
    setLoading(true);
    try {
      const { duplicate_items, ...payload } = projectState

      const response = await updateProject(projectID, payload, authState.authToken);
      setLoading(false);
      if (response.success) {
        toast.success(response.message, TOAST_CONFIG);
        if (typeof (setReloadList) != 'undefined') {
          setReloadList(true);
        }
        resetPopup();
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  }

  const resetPopup = () => {
    let reset = {}
    for (const key in projectState) {
      reset[key] = ""
    }
    setProjectState({ ...reset })
    setTimezoneList([])
    setProjectID("");
    setImage(null);
    handleClose13();
  }
  return (
    <Dialog
      className="DialogSection"
      fullScreen={fullScreen}
      maxWidth={1080}
      open={editProjectNextPop}
      onClose={handleClose13}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        Edit Project
      </DialogTitle>
      <Box className="AddFormPanel">
        <Box className="FormFiled">
          <FormLabel className="label">Project Language</FormLabel>
          <Typography>Default Language</Typography>
          <ReactFlagsSelect
            selected={projectState.language}
            onSelect={code => {
              setProjectState({ ...projectState, language: code })
              // setSelected(code)
            }}
            placeholder="Select a Language"
          />
        </Box>
        <Box className="FormFiled">
          <FormLabel className="label">Time Zone</FormLabel>
          <Typography>
            If Daylight Saving Time is observed at any point during the year,
            opt for "Daylight Time."
          </Typography>

          <Grid container spacing={2}>
            <Grid item md={6}>
              <ReactFlagsSelect
                selected={projectState.country}
                onSelect={code => {
                  setProjectState({ ...projectState, country: code })
                  getTimezone(code)
                }
                }
              />
            </Grid>
            <Grid item md={6}>
              {/* <TextField
                fullWidth
                id="outlined-basic"
                label="12:31 PM - Greenwich Mean Time"
                variant="outlined"
                size="small"
                onChange={setFormData}
                value={projectState.timezone}
                name="timezone"
              /> */}
              <Box
                className="inputAppDrop"
                onClick={() =>
                  setSelectTimeZoneDrop(!SelectTimeZoneDrop)
                }
              >
                <Typography>
                  {projectState.timezone ||
                    "Select Timezone"}
                </Typography>
                <img
                  style={{
                    rotate: SelectTimeZoneDrop ? "180deg" : "0deg",
                  }}
                  src={DownArrowIcon}
                />
                {/* pop */}
                <Box
                  sx={{ height: SelectTimeZoneDrop ? "auto" : "0px" }}
                  className="statusPop"
                >
                  {timezoneList && timezoneList.map(tmezne => (<Box
                    onClick={() => {
                      setProjectState({ ...projectState, timezone: tmezne.zoneName })
                    }
                    }
                    className="statusPopItem"
                  >
                    <Typography>{tmezne.zoneName}</Typography>
                  </Box>))}

                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>


        <Box className="FormFiled duplicateProjectCheckbox">
          <Box className="FormFiled">
            <FormLabel className="label">Activity Start Date</FormLabel>
            <Typography>
              Pick a start date for the first activity in the copied
              project, and all other activity and conversation dates will
              adjust accordingly.
            </Typography>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextField
                  type="month"
                  fullWidth
                  id="outlined-basic"
                  placeholder="August 29, 2023"
                  variant="outlined"
                  size="small"
                  value={formatDate(projectState.start_date,"YYYY-DD")}
                  onChange={(e) => setProjectState({ ...projectState, start_date: e.target.value })}
                />
              </Grid>
            </Grid>
          </Box>

        </Box>
      </Box>
      <DialogActions>
        <Box className="workPupBtnBox addProjectPopBtn">
          <AppBtnOutLine
            autoFocus
            onClick={resetPopup}
            buttonText="Cancel"
          />
          <AppBtnOutLine
            autoFocus
            onClick={handleBack}
            buttonText="Back"
          />
          <AppBtn onClick={editProject} buttonText="Done" />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EditProjectNextPop;
