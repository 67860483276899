import React from "react";
import AdminLayout from "../../partials/AdminLayout";
import AiIntegration from "../../../../Components/Admin/AiIntegration";
import { Box } from "@mui/material";

const AiIntegrationPage = () => {
  return (
    <AdminLayout
      childComponent={
        <Box className="adminMainBox" sx={{ display: "flex" }}>
          <AiIntegration />
        </Box>
      }
    />
  );
};

export default AiIntegrationPage;
