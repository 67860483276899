import React, { useState, useEffect } from 'react';
import { Box, Typography } from "@mui/material";
import "./style.css";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";

import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
//images
import CameraIcon from "../../../Assets/Images/bi_camera.png";
import uploadIcon from "../../../Assets/Images/uil_upload.png";
import trasIcon from "../../../Assets/Images/fluent_delete-16-regular.png";
import camera from "../../../Assets/Images/Scamera.png";
import BlackMicIcon from "../../../Assets/Images/mic.png";
import videoCameraIcon from "../../../Assets/Images/videoCameraIcon.png";
import micIcon from "../../../Assets/Images/micIcon.png";
import backArrow from "../../../Assets/Images/noun-back.png";

import screenCaptureIcon from "../../../Assets/Images/screen-recording-4789699 1.png";
import screen1 from "../../../Assets/Images/screen1.png"
import screen2 from "../../../Assets/Images/screen2.png"
import screen3 from "../../../Assets/Images/screen3.png"
import traPageIcon from "../../../Assets/Images/TeraPage Transparent mix black.png"
import noteIcon from "../../../Assets/Images/noteAppIcon.png"
import browserIcon from "../../../Assets/Images/browserIcon.png"
import { redIIcon } from "../../../Assets/Data"
import recordScreenIcon from "../../../Assets/Images/recordScreenIcon.png"


//component
import SideMenu from "../../../Components/Participants/SideMenu";
import { sideHaumIcon } from "../../../Assets/Data";
import { AppBtn, AppBtnOutLine, PurplelBtn } from '../../../Components/AppTools/AppButton';
import { IBox, SearchBar } from "../../../Components/AppTools/AppTool"
import { useAppSelector } from "../../../lib/store/store";
import { API_BASE_URL } from "../../../env";
import axios from "../../../lib/axios";
import { TOAST_CONFIG } from "../../../lib/constants";
import { toast } from "react-toastify";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export default function MixedMediaTask({ slideMenu, setSlideMenu, workspaceDrop }) {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { taskId } = useParams();
  const [section, setSection] = useState(0);
  const [selectedImages, setSelectedImages] = useState([]);
  const [videoFile, setVideoFile] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [screenVideo, setScreenVideo] = useState(false);
  const [chooseScreen, setChooseScreen] = useState();
  const [recordPop, setRecortPop] = useState(false);
  const [chooseScreenRedio, setChooseScreenRedio] = useState(0);
  const [workspaceId, setWorkspaceId] = useState("");
  const [projectId, setProjectId] = useState("");
  const [activityId, setActivityId] = useState("");
  const [taskType, setTaskType] = useState("");
  const [taskData, setTaskData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();

  const lineBar = 80;
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#4F33D1",
    },
  }));

  const initialTaskResponseState = {
    workspace_id: "",
    project_id: "",
    activity_id: "",
    task_id:"",
    type:"",
    response:[],
    save_draft:false,
    status: "active",
  };

  const [taskResponseData, setTaskResponseData] = useState(initialTaskResponseState);

  const handleImageChange = (event) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      const imagesArray = filesArray.map(file => {
        const reader = new FileReader();
        return new Promise((resolve) => {
          reader.onload = () => {
            resolve({ name: file.name, url: reader.result });
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(imagesArray).then(images => {
        setSelectedImages(prevImages => [...prevImages, ...images]);
      });
    }
  };

  const handleVideoChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setVideoFile(event.target.files[0]);
    }
  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
  };

  const handleAudioChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setAudioFile(event.target.files[0]);
    }
  };


  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setRecortPop(false);
    }
  };


  const RecordPop = () => {
    return (
      <Box id="popBackdrop" onClick={handelPopClose} className="workspacePop recordPop" sx={{ display: recordPop ? "flex" : "none" }}>

        <Box className="recordPopInnerBox"
          sx={{ display: recordPop ? "flex" : "none" }}
          width={chooseScreenRedio === 1 ? "60%" : "416px"}
          header={chooseScreenRedio === 1 ? "auto" : "248px"}
        >
          <Typography className='workspaceHeader'>{t("ScreenCActivity.ScreenRecording")}</Typography>
          <Box className="pbcRedioRow">
            <Box>
              <input
                className="emailSettingInput emailSettingRadio"
                type='radio'
                name='projectState'
                onChange={() => setChooseScreenRedio(0)}
                checked={chooseScreenRedio === 0 ? true : false}
              />
              <Typography>{t("ScreenCActivity.entireScreen")}</Typography>
            </Box>
          </Box>

          <Box className="pbcRedioRow">
            <Box>
              <input
                className="emailSettingInput emailSettingRadio"
                type='radio'
                name='projectState'
                onChange={() => setChooseScreenRedio(1)}
                checked={chooseScreenRedio === 1 ? true : false}

              />
              <Typography>{t("ScreenCActivity.Window")}</Typography>
            </Box>
          </Box>

          <Typography display={chooseScreenRedio === 1 ? "block" : "none"} mt={4}>{t("ScreenCActivity.Selectwindowrecord")}</Typography>

          <Box className="chooseScreenBox" sx={{ display: chooseScreenRedio === 1 ? "flex" : "none" }}>
            <Box onClick={() => setChooseScreen(0)} className={chooseScreen === 0 ? "window windowActive" : "window"}>
              <img className='screenImg' src={screen1} />
              <Box className="screenNameBox">
                <img src={traPageIcon} />
                <Typography>{t("ScreenCActivity.Terapage")}</Typography>
              </Box>
            </Box>
            <Box onClick={() => setChooseScreen(1)} className={chooseScreen === 1 ? "window windowActive" : "window"}>
              <img className='screenImg' src={screen2} />
              <Box className="screenNameBox">
                <img src={noteIcon} />
                <Typography>{t("ScreenCActivity.NoteApp")}</Typography>
              </Box>
            </Box>
            <Box onClick={() => setChooseScreen(2)} className={chooseScreen === 2 ? "window windowActive" : "window"}>
              <img className='screenImg' src={screen3} />
              <Box className="screenNameBox">
                <img src={browserIcon} />
                <Typography>{t("ScreenCActivity.Browser")}</Typography>
              </Box>
            </Box>
          </Box>

          <Typography display={chooseScreenRedio === 1 ? "block" : "none"} mt={3} className='redWornText'>{redIIcon}{t("ScreenCActivity.TroubleRecording")}</Typography>
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={() => setRecortPop(false)} />
            <AppBtn disabled={![0, 1, 2].includes(chooseScreen)} buttonText="Start Recording" onClick={() => {
              setScreenVideo(true)
              setRecortPop(false)
            }} />
          </Box>
        </Box>
      </Box>
    )
  }

  useEffect(() => {
    const getTask = async () => {
      try {
        setLoading(false);
        const response = await axios.get(
          `/researchers/tasks?_id=${taskId}`
        );
        if (response.success) {
          setTaskData(response.data.data[0]);
          setWorkspaceId(response?.data?.data[0]?.workspace_id);
          setProjectId(response?.data?.data[0]?.project_id);
          setActivityId(response?.data?.data[0]?.activity_id);
          setTaskType(response?.data?.data[0]?.type);
          setTaskResponseData({ ...taskResponseData,task_id:  taskId });
          // console.log("======Task data=>", response.data.data[0]);
          // console.log("======Task Response Data =>", taskResponseData);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(true);
      }
    };
    if (taskId) {
      getTask();
    }
  }, [taskId]);

  return (
    <>
      <Box className="ResearchersContainer FillItOutTaskPage ">
        <RecordPop />

        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon">
              {sideHaumIcon}
            </Box>
            <Box className="ontheDotNav">
              <PurplelBtn buttonText={t("commonTxt.previouspage")} />
              <AppBtn buttonText={t("commonTxt.nextPage")} />
            </Box>
          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer onthedot">
            {loading && (
            <Box className="workspaceListBox">
              <Box mb={3} width="74px" onClick={() => Navigate(`/participants/activities/task/${activityId}`)} className="openBtn">
                <img src={backArrow} />
                <Typography className="neonBackText">Back</Typography>
              </Box>
              <Typography className='activeProgText'>{t("commonTxt.activityProgress")}</Typography>
              <Box className="lineBar">
                <Typography>{lineBar}%</Typography>
                <BorderLinearProgress variant="determinate" value={lineBar} />
              </Box>
              <Typography className="lineBarSubText">{t("onTheDro.TaskComp")}</Typography>

              <Box className="otdmainLabelBox">
                <Typography className='ActInSectionMainHeader'>{taskData?.type} : {taskData?.name}</Typography>
                <Box className="skipBtnBox">
                  <Typography>{t("onTheDro.SaveDraft")}</Typography>
                  <AppBtnOutLine buttonText="Skip Task" />
                </Box>
              </Box>
              <IBox text="The important information for this task will be displayed here" />

              <Box className="perpelBox">
                <Box className="pbNavBox">
                  <Box onClick={() => setSection(0)} className={section === 0 ? "pbNavItemActive pbNavItem" : "pbNavItem"}>
                    <Typography>{t("MixedMediaTask.Text")}</Typography>
                  </Box>
                  <Box onClick={() => setSection(1)} className={section === 1 ? "pbNavItemActive pbNavItem" : "pbNavItem"}>
                    <Typography>{t("MixedMediaTask.Image")}</Typography>
                  </Box>
                  <Box onClick={() => setSection(2)} className={section === 2 ? "pbNavItemActive pbNavItem" : "pbNavItem"}>
                    <Typography>{t("MixedMediaTask.Video")}</Typography>
                  </Box>
                  <Box onClick={() => setSection(3)} className={section === 3 ? "pbNavItemActive pbNavItem" : "pbNavItem"}>
                    <Typography>{t("MixedMediaTask.Audio")}</Typography>
                  </Box>
                  <Box onClick={() => setSection(4)} className={section === 4 ? "pbNavItemActive pbNavItem" : "pbNavItem"}>
                    <Typography>{t("MixedMediaTask.Screen")}</Typography>
                  </Box>
                </Box>

                {/* Text */}
                <Box p={3} className="input_BOx" sx={{ display: section === 0 ? "block" : "none" }}>
                  <Box className="uploadBox">
                  <Box className="emailInputBox textTaskInstruction">
                        <Box className="emailInputBoxNav">
                        <Box
                            onClick={() => setMessageTextItalic(!messageTextItalic)}
                            className="emailImgBox"
                        >
                            <img src={IIcon} />
                        </Box>
                        <Box
                            onClick={() => setMessageTextBold(!messageTextBold)}
                            className="emailImgBox"
                        >
                            <img src={BIcon} />
                        </Box>
                        <Box
                            onClick={() => handelTextlineChekc("underline", "message")}
                            className="emailImgBox"
                        >
                            <img src={UnderLineU} />
                        </Box>
                        <Box
                            onClick={() => handelTextlineChekc("line-through", "message")}
                            className="emailImgBox"
                        >
                            <img src={MLineU} />
                        </Box>
                        <Box className="emailImgBox">
                            <img src={AtachMentIcon} />
                        </Box>
                        <Box className="emailImgBox">
                            <img src={Qbox} />
                        </Box>
                        <Box
                            onClick={() => setMessageTextLaOut("start")}
                            className="emailImgBox"
                        >
                            <img src={Line1} />
                        </Box>
                        <Box
                            onClick={() => setMessageTextLaOut("center")}
                            className="emailImgBox"
                        >
                            <img src={Line2} />
                        </Box>
                        <Box
                            onClick={() => setMessageTextLaOut("end")}
                            className="emailImgBox"
                        >
                            <img src={Line3} />
                        </Box>
                        <Box className="emailImgBox">
                            <img src={Line4} />
                        </Box>
                        <Box className="emailImgBox">
                            <img src={emoji} />
                        </Box>
                        </Box>
                        <textarea
                        style={{
                            textAlign: messageTextLaOut,
                            fontStyle: messageTextItalic ? "italic" : "normal",
                            fontWeight: messageTextBold ? "bold" : "normal",
                            textDecoration: messageTextUnderLine,
                            // textDecoration: messageTextMLine ? "line-through" : null
                        }}
                        className="emailInput"
                        placeholder='Enter your response for this task'
                        name="response"
                        />
                    </Box>  
                  </Box>
                </Box>
                {/* images */}
                <Box p={3} className="input_BOx" sx={{ display: section === 1 ? "block" : "none" }}>
                  {selectedImages?.length > 0 ?
                    <>
                      <Box className="ipageUplod">

                        <Box sx={{ display: selectedImages?.length === 1 ? "block" : "none" }} className="imgBox">
                          <img className="fileinputimg" src={selectedImages[0]?.url} alt="Upload" />
                        </Box>

                        <Box sx={{ display: selectedImages?.length > 1 ? "block" : "none" }} className="multiImageBox">
                          {selectedImages?.slice(0, 4).map((el, i) => (
                            <img style={{ height: selectedImages?.length === 2 ? "80%" : selectedImages?.length >= 3 ? "48%" : null }} key={i} src={el?.url} />
                          ))}
                        </Box>


                        <Box className="mediaInputText">
                          <Box className="textBox iaTextBox">
                            <Typography className="teraText">
                              {t("ImageActivity.Uploaded")}
                            </Typography>
                            <Box mb={2}>
                              <img src={uploadIcon} />
                              <Typography
                                ml={2}
                                onClick={() => {
                                  document.getElementById("fileInput").click();
                                }}
                              >
                                {t("ImageActivity.ChangeImage")}
                              </Typography>
                              <input
                                id="fileInput"
                                style={{ display: "none" }}
                                type="file"
                                onChange={handleImageChange}
                              />
                            </Box>
                            <Box mb={2}>
                              <img src={camera} />
                              <Typography ml={2}>{t("ImageActivity.CaptureImage")}</Typography>
                            </Box>
                            <Box>
                              <img src={trasIcon} />
                              <Typography ml={2} onClick={() => setSelectedImages([])}>{t("commonTxt.delete")}</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Typography className='imgBottominputLabel'>{t("ImageActivity.feelAbout")}</Typography>
                      <textarea className='massageInput' placeholder={t("ImageActivity.messageEnter")} />
                    </>
                    :
                    <>
                      <Box className="imgUplodInputBox" onClick={() => document.getElementById("fileInput").click()}>
                        <input
                          id='fileInput'
                          type="file"
                          accept="image/*"
                          style={{ display: 'none' }}
                          onChange={handleImageChange}
                        />
                        <img src={CameraIcon} alt="Camera" />
                      </Box>
                      <Typography
                        onClick={() => document.getElementById("fileInput").click()}
                        className='imageUploadLabelText'>{t("ImageActivity.captureText1")} <span> {t("ImageActivity.captureText2")} </span>{t("ImageActivity.captureText3")}</Typography>
                      <Box my={3}></Box>
                      <SearchBar placeholder="Search image on internet" />
                    </>
                  }

                </Box>

                {/* video */}
                <Box p={3} className="input_BOx" sx={{ display: section === 2 ? "block" : "none" }}>
                  {videoFile ?
                    <>
                      <Box className="ipageUplod">
                        <Box className="VideoBox">
                          <video controls>
                            <source src={URL.createObjectURL(videoFile)} type={videoFile.type} />
                          </video>
                        </Box>

                        <Box className="mediaInputText">
                          <Box className="textBox iaTextBox">
                            <Typography className="teraText">
                              {videoFile?.name} {t("VideoActivity.Uploaded")}
                            </Typography>
                            <Box mb={2}>
                              <img src={uploadIcon} />
                              <Typography
                                ml={2}
                                onClick={() => {
                                  document.getElementById("fileInput").click();
                                }}
                              >
                                {t("VideoActivity.Uploaded")}
                              </Typography>
                              <input
                                id="fileInput"
                                accept="video/*"
                                style={{ display: 'none' }}
                                onChange={handleVideoChange}
                              />
                            </Box>
                            <Box mb={2}>
                              <img src={BlackMicIcon} />
                              <Typography ml={2}>{t("VideoActivity.ChangeImage")}</Typography>
                            </Box>
                            <Box>
                              <img src={trasIcon} />
                              <Typography ml={2} onClick={() => setVideoFile(null)}>{t("commonTxt.delete")}</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Typography className='imgBottominputLabel'>{t("VideoActivity.feelAbout")}</Typography>
                      <textarea className='massageInput' placeholder='Enter your message here' />
                    </>
                    :
                    <>
                      <Box className="imgUplodInputBox" onClick={() => document.getElementById("fileInput").click()}>
                        <input
                          id='fileInput'
                          type="file"
                          accept="video/*"
                          style={{ display: 'none' }}
                          onChange={handleVideoChange}
                        />
                        <img src={videoCameraIcon} alt="Camera" />
                      </Box>
                      <Typography
                        onClick={() => document.getElementById("fileInput").click()}
                        className='imageUploadLabelText'>{t("VideoActivity.captureText1")} <span>{t("VideoActivity.captureText2")}</span>{t("VideoActivity.captureText3")}</Typography>
                    </>}
                </Box>

                {/* audio */}
                <Box p={3} className="input_BOx" sx={{ display: section === 3 ? "block" : "none" }}>
                  {audioFile ?
                    <>
                      <Box className="ipageUplod">
                        <Box className="audio_Box">
                          <audio controls>
                            <source src={URL.createObjectURL(audioFile)} type={audioFile.type} />
                            {t("AudioActivity.browserNotSupport")}
                          </audio>
                        </Box>

                        <Box className="mediaInputText">
                          <Box className="textBox iaTextBox">
                            <Typography className="teraText">
                              {audioFile?.name} {t("ImageActivity.Uploaded")}
                            </Typography>
                            <Box mb={2}>
                              <img src={uploadIcon} />
                              <Typography
                                ml={2}
                                onClick={() => {
                                  document.getElementById("fileInput").click();
                                }}
                              >
                                {t("ImageActivity.UploadAudio")}
                              </Typography>

                              <input
                                id="fileInput"
                                accept="audio/*"
                                style={{ display: 'none' }}
                                onChange={handleAudioChange}
                              />
                            </Box>
                            <Box mb={2}>
                              <img src={BlackMicIcon} />
                              <Typography ml={2}>{t("ImageActivity.RecordAudio")}
                              </Typography>
                            </Box>
                            <Box>
                              <img src={trasIcon} />
                              <Typography ml={2} onClick={() => setAudioFile(null)}>{t("commonTxt.delete")}</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Typography className='imgBottominputLabel'>{t("ImageActivity.feelAbout")}</Typography>
                      <textarea className='massageInput' placeholder='Enter your message here' />
                    </>
                    :
                    <>
                      <Box className="imgUplodInputBox" onClick={() => document.getElementById("fileInput").click()}>
                        <input
                          id='fileInput'
                          type="file"
                          accept="audio/*"
                          style={{ display: 'none' }}
                          onChange={handleAudioChange}
                        />
                        <img src={micIcon} alt="Camera" />
                      </Box>
                      <Typography
                        onClick={() => document.getElementById("fileInput").click()}
                        className='imageUploadLabelText'>{t("AudioActivity.capture1")} <span>{t("AudioActivity.capture2")}</span> {t("AudioActivity.capture3")}</Typography>
                    </>
                  }
                </Box>

                <Box p={3} className="input_BOx" sx={{ display: section === 4 ? "block" : "none" }}>
                  {screenVideo ?
                    <>
                      <Box className="ipageUplod">
                        <Box className="imgBox">
                          <img className="fileinputimg" src={chooseScreen === 0 ? screen1 : chooseScreen === 1 ? screen2 : chooseScreen === 2 ? screen3 : null} alt="Upload" />
                        </Box>

                        <Box className="mediaInputText">
                          <Box className="textBox iaTextBox">
                            <Typography className="teraText">
                              {t("ScreenCActivity.ScreenRUploaded")}
                            </Typography>
                            <Box mb={2}>
                              <img src={recordScreenIcon} />
                              <Typography ml={2}>{t("ScreenCActivity.RecordScreen")}</Typography>
                            </Box>
                            <Box>
                              <img src={trasIcon} />
                              <Typography ml={2} >{t("commonTxt.delete")}</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Typography className='imgBottominputLabel'>{t("ImageActivity.feelAbout")}</Typography>
                      <textarea className='massageInput' placeholder={t("ImageActivity.messageEnter")} />
                    </>
                    :
                    <Box className="uploadBox">
                      <Box className="imgUplodInputBox" onClick={() => setRecortPop(true)}>
                        <input
                          id='fileInput'
                          type="file"
                          accept="image/*"
                          style={{ display: 'none' }} />
                        <img src={screenCaptureIcon} alt="Camera" />
                      </Box>
                      <Typography
                        className='imageUploadLabelText'><span>{t("ScreenCActivity.captureText")} </span></Typography>
                    </Box>
                  }
                </Box>


              </Box>
            </Box>
            )}
          </Box>
        </Box>
      </Box>

    </>
  )
}
