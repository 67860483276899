import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { useTranslation } from "react-i18next";
import "./style.css";

//images
import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";



export default function AddCardPopPeoplePop({ addCardPopPeoplePop, setAddCardPopPeoplePop, handelAddCardPopPeopleBack }) {
  const email1 = "Enter a description or instructions for the card."
  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();
  const [redio, setRedio] = useState(0);

  const [projectDrop, setProjectDrop] = useState(false);
  const [projectDropVal, setProjectDropVal] = useState("Select how the should be displayed");
  const [projectDrop2, setProjectDrop2] = useState(false);
  const [projectDropVal2, setProjectDropVal2] = useState("Select the list style");
  const [projectDrop3, setProjectDrop3] = useState(false);
  const [projectDropVal3, setProjectDropVal3] = useState("Select participant access options");
  const [projectDrop4, setProjectDrop4] = useState(false);
  const [projectDropVal4, setProjectDropVal4] = useState("Select administaror access options");

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
  };


  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setAddCardPopPeoplePop(false);
    }
  };

  useEffect(() => {
    if (addCardPopPeoplePop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [addCardPopPeoplePop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: addCardPopPeoplePop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople "
      >
        <Box
          sx={{ display: addCardPopPeoplePop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className="companyHeader">{t("addPeopleCardPop.addPeopleCardPopHead")}</Typography>

          <Box className="basicBoxItem">
            <Typography className='eTemPopLabel '>{t("addMessageCardPop.cardTitleLabel")}</Typography>
            <Box className="pickerBox cardTitleLabel">
              <input className='eTemInput' placeholder={t("addMessageCardPop.cardTitlePlaceholder")} />
            </Box>
          </Box>
          <Box mb={4} className='checkWlabel'>
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("addMessageCardPop.cardTitleCheckbox")}</Typography>
          </Box>


          <Typography className='eTemPopLabel '>{t("activityGuidancePop.activityGuidanceDescription")}</Typography>
          <Box className="emailInputBox">
            <Box className="emailInputBoxNav">
              <Box
                onClick={() => setMessageTextItalic(!messageTextItalic)}
                className="emailImgBox"
              >
                <img src={IIcon} />
              </Box>
              <Box
                onClick={() => setMessageTextBold(!messageTextBold)}
                className="emailImgBox"
              >
                <img src={BIcon} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("underline", "message")}
                className="emailImgBox"
              >
                <img src={UnderLineU} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("line-through", "message")}
                className="emailImgBox"
              >
                <img src={MLineU} />
              </Box>
              <Box className="emailImgBox">
                <img src={AtachMentIcon} />
              </Box>
              <Box className="emailImgBox">
                <img src={Qbox} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("start")}
                className="emailImgBox"
              >
                <img src={Line1} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("center")}
                className="emailImgBox"
              >
                <img src={Line2} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("end")}
                className="emailImgBox"
              >
                <img src={Line3} />
              </Box>
              <Box className="emailImgBox">
                <img src={Line4} />
              </Box>
              <Box className="emailImgBox">
                <img src={emoji} />
              </Box>
            </Box>
            <textarea
              style={{
                textAlign: messageTextLaOut,
                fontStyle: messageTextItalic ? "italic" : "normal",
                fontWeight: messageTextBold ? "bold" : "normal",
                textDecoration: messageTextUnderLine,
                // textDecoration: messageTextMLine ? "line-through" : null
              }}
              className="emailInput"
              name="emailMessage"
              defaultValue={emailval?.emailMessage || email1}
              onChange={handelChange}
            />
          </Box>

          <Typography className='eTemPopLabel '>{t("addPeopleCardPop.listPeopleHead")}</Typography>

          <Box className="redioItem" onClick={() => setRedio(0)}>
            <Box
              className={redio === 0 ? "customRedio customRedioActive" : "customRedio"}>
              <Box></Box>
            </Box>
            <Typography>{t("admin.inactive")}</Typography>
          </Box>

          <Box className="redioItem" onClick={() => setRedio(1)}>
            <Box
              className={
                redio === 1 ? "customRedio customRedioActive" : "customRedio"
              }
            >
              <Box></Box>
            </Box>
            <Typography>{t("addPeopleCardPop.listPeopleCheckbox2")}</Typography>
          </Box>
          <Box className="redioItem" onClick={() => setRedio(2)}>
            <Box
              className={
                redio === 2 ? "customRedio customRedioActive" : "customRedio"
              }
            >
              <Box></Box>
            </Box>
            <Typography>{t("projectParticipantReport.participantAllParticipant")}</Typography>
          </Box>
          <Box className="redioItem" onClick={() => setRedio(3)}>
            <Box
              className={
                redio === 3 ? "customRedio customRedioActive" : "customRedio"
              }
            >
              <Box></Box>
            </Box>
            <Typography>{t("projectParticipantReport.participantSelectGroup")}</Typography>
          </Box>
          <Box mb={8} className="redioItem" onClick={() => setRedio(4)}>
            <Box
              className={
                redio === 4 ? "customRedio customRedioActive" : "customRedio"
              }
            >
              <Box></Box>
            </Box>
            <Typography>{t("addPeopleCardPop.listPeopleCheckbox5")}</Typography>
          </Box>


          <Typography className='eTemPopLabel '>{t("addConversationCardPop.appearanceHead")}</Typography>

          <Box className="addcpDropLBox">
            <Typography sx={{ width: "65px" }} className="addcpDropLlabel">{t("addPeopleCardPop.displayLabel")}:</Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setProjectDrop(!projectDrop)}
            >
              <Typography>
                {projectDropVal || t("addPeopleCardPop.displayPlaceholder")}
              </Typography>
              <img
                style={{ rotate: projectDrop ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: projectDrop ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setProjectDropVal("Item-A")}
                  className="statusPopItem"
                >
                  <Typography>Item-A</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal("Item-B")}
                  className="statusPopItem"
                >
                  <Typography>Item-B</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal("Item-C")}
                  className="statusPopItem"
                >
                  <Typography>Item-C</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal("Item-D")}
                  className="statusPopItem"
                >
                  <Typography>Item-D</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="addcpDropLBox">
            <Typography sx={{ width: "65px" }} className="addcpDropLlabel">{t("addPeopleCardPop.styleLabel")}:</Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setProjectDrop2(!projectDrop2)}
            >
              <Typography>
                {projectDropVal2 || t("addPeopleCardPop.stylePlaceholder")}
              </Typography>
              <img
                style={{ rotate: projectDrop2 ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: projectDrop2 ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setProjectDropVal2("Item-A")}
                  className="statusPopItem"
                >
                  <Typography>Item-A</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal2("Item-B")}
                  className="statusPopItem"
                >
                  <Typography>Item-B</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal2("Item-C")}
                  className="statusPopItem"
                >
                  <Typography>Item-C</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal2("Item-D")}
                  className="statusPopItem"
                >
                  <Typography>Item-D</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="addcpDropLBox">
            <Typography sx={{ width: "180px" }} className="addcpDropLlabel">{t("addPeopleCardPop.appearanceText1")}:</Typography>
            <input style={{ width: "48px", height: "40px" }} type="text" className="phoneInput" value={"01"} />
          </Box>

          <Box mb={8} className="addcpDropLBox">
            <Typography sx={{ width: "180px" }} className="addcpDropLlabel">{t("addPeopleCardPop.appearanceText2")}:      </Typography>
            <input style={{ width: "48px", height: "40px" }} type="text" className="phoneInput" value={"01"} />
          </Box>


          <Typography className='eTemPopLabel '>{t("addMessageCardPop.cardVisibilityHead")}</Typography>

          <Box className="addcpDropLBox">
            <Typography sx={{ width: "250px" }} className="addcpDropLlabel">{t("addMessageCardPop.participantAccess")}:</Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setProjectDrop3(!projectDrop3)}
            >
              <Typography>
                {projectDropVal3 || t("addMessageCardPop.participantAccessPlaceholder")}
              </Typography>
              <img
                style={{ rotate: projectDrop3 ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: projectDrop3 ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setProjectDropVal3("Item-A")}
                  className="statusPopItem"
                >
                  <Typography>Item-A</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal3("Item-B")}
                  className="statusPopItem"
                >
                  <Typography>Item-B</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal3("Item-C")}
                  className="statusPopItem"
                >
                  <Typography>Item-C</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal3("Item-D")}
                  className="statusPopItem"
                >
                  <Typography>Item-D</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="addcpDropLBox">
            <Typography sx={{ width: "250px" }} className="addcpDropLlabel">{t("addMessageCardPop.administratorAccess")}:</Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setProjectDrop4(!projectDrop4)}
            >
              <Typography>
                {projectDropVal4 || t("addMessageCardPop.administratorAccessPlaceholder")}
              </Typography>
              <img
                style={{ rotate: projectDrop4 ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: projectDrop4 ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setProjectDropVal4("Item-A")}
                  className="statusPopItem"
                >
                  <Typography>Item-A</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal4("Item-B")}
                  className="statusPopItem"
                >
                  <Typography>Item-B</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal4("Item-C")}
                  className="statusPopItem"
                >
                  <Typography>Item-C</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal4("Item-D")}
                  className="statusPopItem"
                >
                  <Typography>Item-D</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => setAddCardPopPeoplePop(false)} />
            <AppBtnOutLine buttonText={t("commonTxt.backBtn")} onClick={handelAddCardPopPeopleBack} />
            <AppBtn buttonText={t("workspaceHome.addCard")} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
