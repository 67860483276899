import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";

//images
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png";
import { uploadFile } from "../../../Services/adminActions";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../env";
import { useAppSelector } from "../../../lib/store/store";
import { viewImage } from "../../../lib/helpers";

export default function ActivityGuidancePop({
  activityGuidancePop,
  setActivityGuidancePop,
  setAddActivitiesPop,
  activityData,
  setActivityData
}) {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const email1 = "Enter description";
  const authState = useAppSelector((state) => state.auth);
  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();
  const [imageG, setImageG] = useState(null);
  const [fileName, setFileName] = useState(null)

  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setActivityGuidancePop(false);
    }
  };

  const handelGuidancePop = () => {
    setAddActivitiesPop(true);
    setActivityGuidancePop(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    console.log("activityData.basic_settings", activityData.basic_settings)
    if (activityData.basic_settings.activity_guidance_data.image) {
      setImageG(viewImage(activityData.basic_settings.activity_guidance_data.image))
    }

  }, [activityGuidancePop])
  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];

    if (file) {
      const fileType = file.type;
      const imageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
      if (imageTypes.includes(fileType)) {
        const reader = new FileReader();
        reader.onload = () => {
          setImageG(reader.result);
          console.log(reader.result);
        };
        reader.readAsDataURL(file);

      } else {
        setFileName(file.name);
      }
      uploadImage(file)
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const fileType = file.type;
      const imageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
      if (imageTypes.includes(fileType)) {
        const reader = new FileReader();
        reader.onload = () => {
          setImageG(reader.result);
          setFileName(null);
        };
        reader.readAsDataURL(file);

      } else {
        setImageG(null);
        setFileName(file.name);
      }
      uploadImage(file)
    }
  };

  const uploadImage = async (file) => {
    if (!file) return false;
    const formData = new FormData();
    formData.append("file", file);
    try {
      let imageUploadRes = await uploadFile(formData, authState.authToken);
      if (imageUploadRes.success) {
        setActivityData({
          ...activityData,
          basic_settings: {
            ...activityData.basic_settings,
            activity_guidance_data: {
              ...activityData.basic_settings.activity_guidance_data,
              image: imageUploadRes?.data?.upload_path,
            }
          }
        });
        // toast.success(imageUploadRes.message, TOAST_CONFIG);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    } finally {
    }
  };

  useEffect(() => {
    if (activityGuidancePop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [activityGuidancePop]);

  const saveGuidance = () => {
    setImageG(null);
    setFileName(null);
    handelGuidancePop();
  }
  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: activityGuidancePop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople "
      >
        <Box
          sx={{ display: activityGuidancePop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className="companyHeader">{t("activityGuidancePop.activityGuidanceHead")}</Typography>

          <Box className="basicBoxItem">
            <Typography className="eTemPopLabel ">{t("activityGuidancePop.headingLabel")}</Typography>
            <Box className="pickerBox">
              <input className="eTemInput" placeholder={t("activityGuidancePop.headingPlaceholder")}
                value={activityData.basic_settings.activity_guidance_data.heading ?? ""}
                onChange={(e) => setActivityData({
                  ...activityData,
                  basic_settings: {
                    ...activityData.basic_settings,
                    activity_guidance_data: {
                      ...activityData.basic_settings.activity_guidance_data,
                      heading: e.target.value,
                    }
                  }
                })} />
            </Box>
          </Box>

          <Typography className="eTemPopLabel ">
            {t("activityGuidancePop.activityGuidanceDescription")}
          </Typography>
          <Box
            sx={{ marginBottom: "17px !important" }}
            className="emailInputBox"
          >
            <textarea
              style={{
                textAlign: messageTextLaOut,
                fontStyle: messageTextItalic ? "italic" : "normal",
                fontWeight: messageTextBold ? "bold" : "normal",
                textDecoration: messageTextUnderLine,
                // textDecoration: messageTextMLine ? "line-through" : null
              }}
              className="emailInput"
              placeholder={t("activityGuidancePop.activityGuidanceDescriptionPlaceholder")}
              name="emailMessage"
              value={activityData.basic_settings.activity_guidance_data.description ?? ""}
              onChange={(e) => setActivityData({
                ...activityData,
                basic_settings: {
                  ...activityData.basic_settings,
                  activity_guidance_data: {
                    ...activityData.basic_settings.activity_guidance_data,
                    description: e.target.value,
                  }
                }
              })}
            />
          </Box>

          <Box className="basicBoxItem">
            <Typography className="eTemPopLabel ">
              {t("activityGuidancePop.uploadImageGuidance")}
            </Typography>
            <Box
              className="mediaInputbox upload-area"
              onClick={() => {
                document.getElementById("fileGInput").click();
              }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <img src={imageG ? imageG : uploadIcon} />
              <Typography>{fileName ? fileName : t("activityGuidancePop.uploadImageText")}</Typography>
              <input
                id="fileGInput"
                style={{ display: "none" }}
                type="file"
                onChange={handleFileInputChange}
              />
            </Box>
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine
              buttonText={t("commonTxt.cancelBtn")}
              onClick={() => handelGuidancePop()}
            />
            <AppBtnOutLine buttonText={t("commonTxt.backBtn")} onClick={handelGuidancePop} />
            <AppBtn buttonText={t("commonTxt.submitBtn")} onClick={saveGuidance} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
