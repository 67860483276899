import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  SearchBar,
  LatestCreated,
  Status,
  CompanyStatus,
} from "../../AppTools/AppTool";
import arrow from "../../../Assets/Images/arrow.png";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";

import {
  xCircleBlack,
  trashBlack,
  recycleBlack,
  editIconBlack,
} from "../../../Assets/Data";

import { companiesData } from "../../../Assets/Data";
import RemoveFrmWSPop from "../RemoveFrmWSPop";
import EditCompanyPop from "../EditCompanyPop";
import DeleteCompanyPop from "../DeleteCompanyPop";
import axios from "../../../lib/axios";
import { formatDate } from "../../../lib/helpers";
import Pagination from "../../AppTools/Pagination";
import NoWorkspaceBG from "../NoWorkspaceBG";

export default function DirectoryCompanies() {
  const [companyStatusDrop, setCompanyStatusDrop] = useState(false);
  const [compantStatusDropVal, setCompantStatusDropVal] = useState("All");
  const [statusDrop, setStatusDrop] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [statusDropVal, setStatusDropVal] = useState("All");
  const [workspaceStatusdrop, setWorkspaceStatusdrop] = useState(false);
  const [workspaceStatusDropVal, setworkspaceStatusDropVal] = useState("");

  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Latest Created");

  const [workSpaceOption, setWorkspaceOption] = useState(false);
  const [editDetails, setEditDetails] = useState(false);

  const [optionIndex, setOptionIndex] = useState();
  const [deleteAdmin, setDeleteAdmin] = useState(false);

  const [removefromWorkspace, setRemFromWorkspace] = useState(false);
  const [resRemFromWorkspace, setResRemFromWorkspace] = useState(false);
  const [colRemFromWorkspace, setColRemFromWorkspace] = useState(false);
  const [obsRemFromWorkspace, setObsRemFromWorkspace] = useState(false);
  const [partRemFromWorkspace, setPartRemFromWorkspace] = useState(false);

  const [deleteCompanyPop, setDeleteCompanyPop] = useState(false);

  const [adminOptionDropIndex, setadminOptionDropIndex] = useState();
  const [adminOptionDrop, setadminOptionDrop] = useState(false);
  const [editCompanyPop, setEditCompanyPop] = useState(false);
  const [companyList, setAllCompanyList] = useState([]);
  const [reloadList, setReloadList] = useState(false);
  const [removeWokspacesListData, setRemoveWorkspacesListData] = useState(null);

  const [allCompaniesData, setAllCompaniesData] = useState("");
  const [deleteCompaniesListData, setDeleteCompaniesListData] = useState("");

  const workspaceOptionRef = useRef(null);
  const statusDropRef = useRef(null);

  const latestDropRef = useRef(null);
  const adminDropRef = useRef(null);
  const compStatusDrop = useRef(null);
  const wsStatusDrop = useRef(null);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const defaultFilters = {
    search: "",
    sortBy: "created_at",
    sortOrder: -1,
    status: "",
    workspace_status: "",
  };

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 25,
    totalPages: 0,
    totalItems: 0,
  });

  const [filterState, setFilterState] = useState({ ...defaultFilters });

  const handleFilterChange = (changedFilter) => {
    let newFilters = {
      ...filterState,
      ...changedFilter,
    };
    setFilterState(newFilters);
    getCompanies(1, newFilters);
  };

  const handleOptionIndex = (i) => {
    setOptionIndex(i);
  };

  const handleDeleteCompanies = (val) => {
    setDeleteCompaniesListData(val);
  };

  useEffect(() => {
    getCompanies(1);
  }, []);

  useEffect(() => {
    if (reloadList) {
      getCompanies(1);
      setReloadList(false);
    }
  }, [reloadList]);

  const getCompanies = async (curPage, filters = filterState) => {
    return await axios
      .get(
        `/super-admin/admin/companies?page=${curPage}&search=${filters.search}&status=${filters.status}&sortBy=${filters.sortBy}&sortOrder=${filters.sortOrder}&workspace_status=${filters.workspace_status}`
      )
      .then((res) => {
        if (res.status) {
          setAllCompanyList(res.data.data);
          setPaginationData({
            currentPage: res.data.pagination.currentPage,
            itemsPerPage: res.data.pagination.itemsPerPage,
            totalPages: res.data.pagination.totalPages,
            totalItems: res.data.pagination.totalItems,
          });
        }
      });
  };

  const onPageChange = (pageNum) => {
    getCompanies(pageNum, filterState);
  };

  //pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [newArrayData, setNewArrayData] = useState([]);

  useEffect(() => {
    printItemsByState(companiesData, currentPage);
  }, [currentPage]);

  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  const handleRemoveCompanyFrmWs = (val) => {
    setRemoveWorkspacesListData(val);
  };

  return (
    <>
      <RemoveFrmWSPop
        setRemFromWorkspace={setRemFromWorkspace}
        removefromWorkspace={removefromWorkspace}
        setResRemFromWorkspace={setResRemFromWorkspace}
        setColRemFromWorkspace={setColRemFromWorkspace}
        setObsRemFromWorkspace={setObsRemFromWorkspace}
        setPartRemFromWorkspace={setPartRemFromWorkspace}
        removeWokspacesListData={removeWokspacesListData}
        setReloadList={setReloadList}
        // userTitle={companyList.items[optionIndex]?.company_name}
        // userWorkspace={companyList.items[optionIndex]?.workspace_name}
      />
      <Box className="workspaceItemBox CompaniesBox">
        <EditCompanyPop
          editCompanyPop={editCompanyPop}
          setEditCompanyPop={setEditCompanyPop}
          setAllCompaniesData={setAllCompaniesData}
          allCompaniesData={allCompaniesData}
          setReloadList={setReloadList}
          reloadList={reloadList}
        />

        <DeleteCompanyPop
          deleteCompanyPop={deleteCompanyPop}
          setDeleteCompanyPop={setDeleteCompanyPop}
          deleteCompaniesListData={deleteCompaniesListData}
          setReloadList={setReloadList}
        />
        <Box className="AllCSubBox allUFilters">
          <Box className="headerText">
            <Typography className="fHeaderText">
              {t("commonTxt.filters")}
            </Typography>
          </Box>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
            <Box sx={{ width: "100%" }} className="filterBox">
              <Box className="companiesTopBox">
                <Box className="companyInputBoxWlabel">
                  <Typography mb={1} className="inputLabel">
                    {t("directoryCompanies.compStatus")}
                  </Typography>
                  <CompanyStatus
                    ref={compStatusDrop}
                    setCompanyStatusDrop={setCompanyStatusDrop}
                    handleFilterChange={handleFilterChange}
                    companyStatusDrop={companyStatusDrop}
                    compantStatusDropVal={compantStatusDropVal}
                    setCompantStatusDropVal={setCompantStatusDropVal}
                    setClearFilter={setClearFilter}
                    clearFilter={clearFilter}
                  />
                </Box>
                {/* workspace status */}
                <Box className="companyInputBoxWlabel">
                  <Typography mb={1} className="inputLabel">
                    {t("directoryCompanies.workspaceStatus")}
                  </Typography>
                  <Status
                    ref={statusDropRef}
                    setStatusDrop={setStatusDrop}
                    statusDrop={statusDrop}
                    handleFilterChange={handleFilterChange}
                    setStatusDropVal={setStatusDropVal}
                    statusDropVal={statusDropVal}
                    setClearFilter={setClearFilter}
                    clearFilter={clearFilter}
                  />
                </Box>
              </Box>
              {/* Bottom Filter */}
              <Box
                sx={{ display: "flex", justifyContent: "flex-end" }}
                className="filterBottomBox"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  
                >
                  <Typography className="LinkText" mx={3} onClick={() => {
                    setClearFilter(true)
                    setFilterState(defaultFilters)
                    setLatestDropVal("Latest Created")
                  }}>
                    {t("commonTxt.clear")}
                  </Typography>
                  {/* <Typography mx={3} className="LinkText">
                    {t("commonTxt.apply")}
                  </Typography> */}
                  <Typography>{paginationData?.totalItems} {t("commonTxt.21Matches")}</Typography>
                </Box>
              </Box>
              {/* Finishes here */}
            </Box>
          </Box>
        </Box>
        <Box className="AllProjectBox">
          <Box className="workListNavItem workListNavItemMobile">
            <Typography className="pageHeaderText">
              {t("directoryCompanies.allComp")}
            </Typography>
          </Box>
          <Box className="companyListNav ">
            <Box className=" companyListNavItem companyListheader workListNavItemDesktop">
              <Typography className="pageHeaderText">
                {t("directoryCompanies.allComp")}
              </Typography>
            </Box>
            <Box className="companyListNavItem companyListSearchBar">
              <SearchBar handleFilterChange={handleFilterChange} val={filterState}/>
            </Box>
            <Box
              sx={{ minWidth: "215px", height: "40px" }}
              className="companyListNavItem companyLatest"
            >
              <LatestCreated
                setLatestDrop={setLatestDrop}
                latestCDrop={latestCDrop}
                handleFilterChange={handleFilterChange}
                setLatestDropVal={setLatestDropVal}
                latestDropVal={latestDropVal}
              />
            </Box>
          </Box>
          <Box className="companyListBox">
            <Box className="haderRow" minWidth={"1176px"}>
              <Box
                minWidth={"20%"}
                className="Tabelsel navFsel TabelselItemStart"
              >
                <Typography>{t("directoryCompanies.compName")}</Typography>
              </Box>
              <Box minWidth={"16%"} className="Tabelsel TabelselItemStart">
                <Typography>{t("commonTxt.workspace")}</Typography>
              </Box>
              <Box minWidth={"16%"} className="Tabelsel">
                <Typography>{t("commonTxt.status")}</Typography>
              </Box>
              <Box minWidth={"16%"} className="Tabelsel">
                <Typography>{t("commonTxt.userType1As")}</Typography>
              </Box>
              <Box minWidth={"16%"} className="Tabelsel">
                <Typography>{t("commonTxt.userType5A")}</Typography>
              </Box>
              <Box minWidth={"16%"} className="Tabelsel">
                <Typography>{t("directoryCompanies.createdOn")}</Typography>
              </Box>
            </Box>

            {companyList.length > 0 ? (
              companyList?.map((el, index) => (
                <Box key={index} className="TabelRow" minWidth={"1176px"}>
                  <Box minWidth={"20%"} className="Tabelsel navFsel">
                    <Box
                      ref={workspaceOptionRef}
                      id="dot"
                      onClick={() => {
                        setWorkspaceOption(!workSpaceOption);
                        setOptionIndex(index);
                      }}
                      className={
                        workSpaceOption && index === optionIndex
                          ? "dot dotActive"
                          : "dot"
                      }
                    >
                      {
                        <Box
                          sx={{
                            display:
                              workSpaceOption && index === optionIndex
                                ? "flex"
                                : "none",
                          }}
                          className="optionPop"
                        >
                          {el?.workspaces[0]?.name && <Box
                            className="optionPopItem"
                            onClick={() => {
                              setRemFromWorkspace(true);
                              handleRemoveCompanyFrmWs(el);
                            }}
                          >
                            {recycleBlack}
                            <Typography className="optionPopItema">
                              {t("commonTxt.remFrmWs")}
                            </Typography>
                          </Box>}
                          <Box
                            className="optionPopItem"
                            onClick={() => {
                              setEditCompanyPop(true);
                              setAllCompaniesData(el?._id);
                            }}
                          >
                            {editIconBlack}
                            <Typography className="optionPopItema">
                              {t("commonTxt.editCompDetails")}
                            </Typography>
                          </Box>
                          <Box
                            onClick={() => {
                              setDeleteCompanyPop(true);
                              handleDeleteCompanies(el)
                            }}
                            className="optionPopItem"
                          >
                            {trashBlack}
                            <Typography className="optionPopItema">
                              {t("directoryCompanies.deleteCompany")}
                            </Typography>
                          </Box>
                        </Box>
                      }
                    </Box>
                    <Typography>{el.name}</Typography>
                  </Box>
                  <Box minWidth={"16%"} className="Tabelsel ">
                    <Typography>{(el?.workspaces.length > 0) ? el?.workspaces[0]?.name : '-'}</Typography>
                  </Box>

                  <Box minWidth={"16%"} className="Tabelsel TabelselItemCenter">
                    <Typography>{el.status}</Typography>
                  </Box>

                  <Box
                    onClick={() => {
                      setadminOptionDropIndex(index);
                      setadminOptionDrop(!adminOptionDrop);
                    }}
                    minWidth={"16%"}
                    className="Tabelsel adminTabDrop TabelselItemCenter"
                  >
                    <Typography>
                      {parseInt(el?.workspaces[0]?.researcher || 0) +
                        parseInt(el?.user_role_counts?.collaborators || 0) +
                        parseInt(el?.user_role_counts?.observers || 0)}
                    </Typography>
                    <KeyboardArrowDownIcon
                      ref={adminDropRef}
                      style={{
                        transform: `rotate(${
                          adminOptionDrop && index === adminOptionDropIndex
                            ? 180
                            : 0
                        }deg)`,
                      }}
                    />

                    <Box
                      sx={{
                        display:
                          adminOptionDrop && index === adminOptionDropIndex
                            ? "flex"
                            : "none",
                      }}
                      className="adminDrop"
                    >
                      <Typography>
                        {t("commonTxt.userType2A")}:{" "}
                        {el?.user_role_counts?.researcher || 0}
                      </Typography>
                      <Typography>
                        {t("commonTxt.userType3A")}:{" "}
                        {el?.user_role_counts?.collaborators || 0}
                      </Typography>
                      <Typography>
                        {t("commonTxt.userType4A")}:{" "}
                        {el?.user_role_counts?.observers || 0}
                      </Typography>
                    </Box>
                  </Box>

                  <Box minWidth={"16%"} className="Tabelsel TabelselItemCenter">
                    <Typography>{el?.participants_count || 0}</Typography>
                  </Box>
                  <Box minWidth={"16%"} className="Tabelsel TabelselItemCenter">
                    <Typography>{formatDate(el.created_at)}</Typography>
                  </Box>
                </Box>
              ))
            ) : (
              <Box></Box>
            )}
          </Box>
        </Box>
        <Pagination
          paginationData={paginationData}
          onPageChange={onPageChange}
        />
      </Box>
    </>
  );
}
