import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import IIcon from "../../../Assets/Images/IIcon.png";

//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";

export default function DeleteAgreementPop({
    deleteAgreementPop,
    setDeleteAgreementPop,
}) {
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
        setDeleteAgreementPop(false);
    }
  };

 

  useEffect(() => {
    if (deleteAgreementPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [deleteAgreementPop]);

  return (
    <>
      <Box
        onClick={handelPopClose}
        id="popBackdrop"
        sx={{ display: deleteAgreementPop ? "flex" : "none" }}
        className="workspaceDeletePopContainer"
      >
        <Box className="workspaceDeleteBox workspaceDeletePopTextAlign">
          <Typography className="deletePopHeader">
            Delete Terms of Use Agreement ?
          </Typography>

          
          <Box className="deleteTextBox">
            <Typography>
              Are you sure you want to delete “Terms of Use” Agreement from
              Terapage? Kindly note that this action cannot be un-done, any
              Agreement once deleted cannot be retrieved again.
            </Typography>
          </Box>
          <Box className="deleteBtnBox">
            <AppBtnOutLine
              onClick={() => setDeleteAgreementPop(false)}
              buttonText="Cancel"
            />
            <AppBtn buttonText="Delete" />
          </Box>
        </Box>
      </Box>
    </>
  );
}
