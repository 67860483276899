import React, { useEffect, useState } from "react";
import "./style.css";
import { Box, Typography } from "@mui/material";
import axios from '../../../lib/axios';

//images
import avatar from "../../../Assets/Images/Avatar2.png";

//component
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { API_BASE_URL } from "../../../env";
import { useAppSelector } from "../../../lib/store/store";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { assignParticipantToProjectAPI } from "../../../Services/adminActions";
import { viewImage } from "../../../lib/helpers";
import DummyUser from "../../../Assets/Images/user-dummy.png";

export default function AddProjectParticipantPop({
  setAddProjectParticipantPop,
  addProjectParticipantPop,
  participantList,
  setReloadList,
}) {
  let authState = useAppSelector(state => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace);

  const addProjectObj = {
    "participant_ids": [],
    "project_ids": [],
  }
  const [addParticipantToProjects, setAddParticipantToProjects] = useState({ ...addProjectObj })
  const [loading, setLoading] = useState(false);

  const [allParticipantData, setAllParticipantData] = useState([]);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    getParticipants();
    getProjectLists();
    setAddParticipantToProjects({ ...addParticipantToProjects, participant_ids: [...participantList] })
  }, [participantList])

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: '200px',
        overflowY: 'auto',
      },
    },
  };

  const getParticipants = async () => {
    setLoading(true);
    return await axios.get(API_BASE_URL + `/super-admin/admin/participants?workspace=${workspaceState?.selectedWorkspace?._id}`).then((res) => {
      setLoading(false);
      if (res.status) {
        setAllParticipantData(res.data.data);
      }
    })
  }

  const getProjectLists = async () => {
    try {
      const res = await axios.get(API_BASE_URL + '/researchers/projects');
      if (res.status) {
        setProjects(res.data.data);
      }
    } catch (error) {
      console.error('Error fetching project lists', error);
    }
  }

  const matchedParticipants = allParticipantData.filter(participant =>
    participantList.includes(participant._id)
  );

  const handleCheckboxChange = (participantId) => {
    setAddParticipantToProjects((prev) => {
      const newParticipantIds = prev.participant_ids.includes(participantId)
        ? prev.participant_ids.filter(id => id !== participantId)
        : [...prev.participant_ids, participantId];
      return { ...prev, participant_ids: newParticipantIds };
    });
  };

  const UserBox = ({ participant }) => {
    let isChecked = addParticipantToProjects.participant_ids.includes(participant._id);
    return (
      <Box className="userBox">
        <input
          className="custom-checkbox"
          type="checkBox"
          checked={isChecked}
          onChange={(el) => {
            handleCheckboxChange(participant._id)
          }}
        />
        <Box className="avatar">
          {/* <img src={avatar} /> */}
          {(participant?.profile_pic == 'undefined' || participant?.profile_pic == null) ? <img src={DummyUser} /> : <img src={viewImage(participant?.profile_pic)} />}
        </Box>
        <Typography>
          {participant?.name} <br /> <span>{participant?.username}</span>
        </Typography>
      </Box>
    );
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setAddProjectParticipantPop(false);
    }
  };

  useEffect(() => {
    if (addProjectParticipantPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [addProjectParticipantPop]);

  const handleChange = (event) => {
    const selectedValue = event.target.value === "" ? 0 : event.target.value;
    setAddParticipantToProjects((prevGroup) => ({
      ...prevGroup,
      [event.target.name]: selectedValue,
    }));
  };

  let handleAddPartcipantsToProject = async () => {
    setLoading(true);
    try {
      if (addParticipantToProjects.participant_ids.length < 1) {
        return toast.error("Selection required: Please choose at least one participant.");
      }
      if (addParticipantToProjects.project_ids.length < 1) {
        return toast.error("Selection required: Please choose at least one project.");
      }

      let { ...addProjectData } = addParticipantToProjects;
      let addProjectRes = await assignParticipantToProjectAPI(
        addProjectData,
        authState.authToken,
      )
      if (addProjectRes.success) {
        toast.success(addProjectRes.message, TOAST_CONFIG);
        setAddParticipantToProjects({ ...addProjectObj });
        setAddProjectParticipantPop(false);
        setReloadList(true);
      } else {
        toast.error(addProjectRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong! Please try again', TOAST_CONFIG);
      setLoading(false);
    }
  }

  return (
    <>
      <Box
        id="popBackdrop"
        sx={{ display: addProjectParticipantPop ? "flex" : "none" }}
        onClick={handelPopClose}
        className="invitePPopbox workspacePop "
      >
        <Box className="workspaceContaint">
          <Typography className="workspaceHeader">
            Add to Projects
          </Typography>
          <Box className="userMainBox">
            {matchedParticipants.map(participant => (
              <UserBox participant={participant} key={participant._id} />
            ))}
          </Box>
          <Typography className="eTemPopLabel">
            Assign Projects<samp>*</samp>
          </Typography>
          <Box className="ckeckBoxRoom" mb={3} mt={2}>
            <FormControl sx={{ width: "100%" }} size="medium">
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={addParticipantToProjects?.project_ids}
                onChange={handleChange}
                name="project_ids"
                multiple
                displayEmpty
                MenuProps={MenuProps}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <p>Select Projects</p>;
                  }
                  return selected.map(value => {
                    const project = projects.find(project => project._id === value);
                    return project ? project.name : value;
                  }).join(', ');
                }}
              >
                {projects.map((project, index) => (
                  <MenuItem value={project._id} key={index}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine
              onClick={() => {setAddProjectParticipantPop(false);
                setAddParticipantToProjects((prevGroup) => ({
                  ...prevGroup,
                  project_ids: [],
                }))
              }}
              buttonText="Cancel"
            />
            <AppBtn buttonText="Add Project" onClick={() => handleAddPartcipantsToProject()} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
