import axios from "../lib/axios";

export const getCompanies = async (filter = {}) => {
  return await axios.get("/super-admin/admin/companies", {
    params: filter,
  });
};

export const getResearchers = async (filter = {}) => {
  return await axios.get("/super-admin/admin/researchers?limit=100", {
    params: filter,
  });
};

export const getObservers = async () => {
  return await axios.get("/super-admin/admin/observers");
};

export const getCollaborators = async () => {
  return await axios.get("/super-admin/admin/collaborators");
};

export const getWorkspaceUsers = async (filter = {}) => {
  return await axios.get("/super-admin/admin/user-admins", {
    params: filter,
  });
};

export const getCountries = async (filter = {}) => {
  return await axios.get("/master/countries", {
    params: filter,
  });
};

export const getStates = async (id = 0) => {
  return await axios.get(`/master/states/${id}`);
};

export const getCities = async (cid = 0, id = 0) => {
  return await axios.get(`/master/cities/${cid}/${id}`);
};

export const getIndustries = async () => {
  return await axios.get(`/super-admin/admin/industry`);
};

export const getConfigSettings = async (filter = {}) => {
  return await axios.get("/super-admin/admin/settings", {
    params: filter,
  });
};

// export const getUserProfile = async (filter = {}) => {
//   return await axios.get(API_BASE_URL + "/auth/get-profile", {
//     params: filter
//   });
// };
