import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import Clogo from "../../../Assets/Images/Frame 243811.png";
import signature from "../../../Assets/Images/signature.png";

//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { useAppSelector } from "../../../lib/store/store";

export default function PreviewMailPop({
  previewMailPop,
  setPreviewMailPop,
  submitInvitation,
  message
}) {
  const authState = useAppSelector((state) => state.auth);
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setPreviewMailPop(false);
    }
  };

  const handelPreviousPop = () => {
    setPreviewMailPop(false);
  };

  useEffect(() => {
    if (previewMailPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [previewMailPop]);

  return (
    <>
      <Box
        onClick={handelPopClose}
        id="popBackdrop"
        sx={{ display: previewMailPop ? "flex" : "none" }}
        className="workspaceDeletePopContainer workspaceDeletePopTextAlign"
      >
        <Box className="workspaceDeleteBox">
          <Box className="deleteTextBox">
            <img style={{ width: "212px" }} src={Clogo} />
          </Box>
          <Typography className="deletePopHeader">
            Invite to join a Project
          </Typography>

          <Box className="deleteTextBox">
            <Typography>Hello {`Participents`},</Typography>
            <Typography>
              {message}
            </Typography>
          </Box>
          <Box className="deleteTextBox">
            {/* <img style={{ width: "212px" }} src={signature} /> */}
            {authState.userProfile.name}
          </Box>
          <Box className="deleteBtnBox">
            <AppBtnOutLine
              onClick={() => handelPreviousPop()}
              buttonText="Back"
            />
            <AppBtn buttonText="Send Now" onClick={() => submitInvitation()} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
