import React, { useState, useEffect } from 'react';
import "./style.css"
import { useNavigate } from "react-router-dom";

//images
import backArrow from "../../../Assets/Images/noun-back.png";
import exportIcon from "../../../Assets/Images/exportIcon.png";
import prepItem from "../../../Assets/Images/prepItem2.png"
import arrow from "../../../Assets/Images/arrow.png";
import { sideHaumIcon } from "../../../Assets/Data";
import WhiteDownArrow from "../../../Assets/Images/Whitechevron-down.png";

//data
import { editIconBlack, redtrash, publicationSummeryData, deleteIcon } from "../../../Assets/Data"

//component
import { Box, Typography } from "@mui/material";
import { t } from 'i18next';
import SideMenu from "../../../Components/Researchers/SideMenu";
import DeleteResearchPublication from '../../../Components/Researchers/DeleteResearchPublication';

export default function PublicationView({ slideMenu, setSlideMenu, workspaceDrop, setWorkSpaceDrop }) {
  const Navigate = useNavigate();
  const [newArrayData, setNewArrayData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [publicationViewNavDrop, setPublicationViewNavDrop] = useState(false)
  const [publicationViewNavDropVal, setPublicationViewNavDropval] = useState();
  const [deleteResearchPublication, setDeleteResearchPublication] = useState(false);

  const upArrow = (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path d="M10.9987 17.4154V4.58203M10.9987 4.58203L4.58203 10.9987M10.9987 4.58203L17.4154 10.9987" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )

  const upDown = (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path d="M10.9987 4.58203V17.4154M10.9987 17.4154L17.4154 10.9987M10.9987 17.4154L4.58203 10.9987" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )

  useEffect(() => {
    printItemsByState(publicationSummeryData, currentPage);
  }, [currentPage]);

  function printItemsByState(array, page) {
    const startIndex = page * 2;
    const endIndex = (page + 1) * 2;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  return (
    <Box className="ResearchersContainer ">
      {/* side menu */}
      <DeleteResearchPublication 
        deleteResearchPublication={deleteResearchPublication} 
        setDeleteResearchPublication={setDeleteResearchPublication}
      />
      <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop}
        setWorkSpaceDrop={setWorkSpaceDrop} />
      <Box className="adminMainSection">
        <Box className="adminNavBar">
          <Box
            onClick={() => setSlideMenu(!slideMenu)}
            sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
            className="sideHaumIcon"
          >
            {sideHaumIcon}
          </Box>
          <Box onClick={() => setPublicationViewNavDrop(!publicationViewNavDrop)} className="directMessageNavBtn">
            <Typography sx={{ color: "#fff" }}>
              {publicationViewNavDropVal || t("Publications.options")}
            </Typography>
            <img src={WhiteDownArrow} />
            {/* pop */}
            <Box sx={{ display: publicationViewNavDrop ? "flex" : "none", width:"211px !important" }} className="workspaceItemNavPop">
                  <Box key={1} onClick={() => setPublicationViewNavDropval('Edit Publication')}>
                    <Typography className='GsettingIcon'>{editIconBlack} Edit Publication</Typography>
                  </Box>
                  <Box key={2} onClick={() => {
                      setPublicationViewNavDropval('Delete Publication');
                      setDeleteResearchPublication(true);
                    }} >
                    <Typography className='GsettingIcon'>{deleteIcon} Delete Publication</Typography>
                  </Box>
            </Box>
          </Box>


        </Box>
        {/* Page container */}
        <Box className="researchersMainBox pageContainer">

          <Box className="workspaceListBox publicationViewPage">

            <Box mb={2} className="openBtn">
              <Box
                onClick={() => Navigate("/researchers/Publications")}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img src={backArrow} />
                <Typography className="neonBackText">{t("commonTxt.backBtn")}</Typography>
              </Box>
              <Box className="actTNavBtn">
                <img src={exportIcon} />
                <Typography>{t("commonTxt.Export")}</Typography>
              </Box>
            </Box>

            <Box className="publicationViewMainBox">
              <Typography className='pubVMbHeader'>{t("PublicationsView.headerText")}</Typography>
              <Typography className='pubWmSubHeader'>{t("PublicationsView.headerSuvText")}</Typography>

              <Box className="puwbBackgroundCover">
                <img src={prepItem} />
              </Box>
              {newArrayData?.map((el, i) => (
                <Box>
                  <Box key={i} className="pragNav">
                    <Box>
                      <Typography className='pagNavHeader'>{el.title}</Typography>
                      {editIconBlack}
                    </Box>
                    <Box>
                      {upArrow}
                      {upDown}
                      {redtrash}
                    </Box>
                  </Box>

                  <Box className="pragraphSection multiP">
                    {el.summerys?.map((s, i) => (
                      <Typography key={i}>{s}</Typography>
                    ))}
                  </Box>
                </Box>
              ))}

              <Box mb={5} className="pagenation">
                <Box onClick={handlePrev} className="tabelBtn">
                  <img src={arrow} />
                  <Typography>{t("commonTxt.previouspage")}</Typography>
                </Box>

                <Box className="pageNumberBox">
                  <Box
                    onClick={() => setCurrentPage(0)}
                    className={
                      currentPage === 0
                        ? "pageNumber pageNumberActive"
                        : "pageNumber"
                    }
                  >
                    <p>1</p>
                  </Box>
                  <Box
                    onClick={() => setCurrentPage(1)}
                    className={
                      currentPage === 1
                        ? "pageNumber pageNumberActive"
                        : "pageNumber"
                    }
                  >
                    <p>2</p>
                  </Box>
                  <Box
                    onClick={() => setCurrentPage(2)}
                    className={
                      currentPage === 2
                        ? "pageNumber pageNumberActive"
                        : "pageNumber"
                    }
                  >
                    <p>3</p>
                  </Box>
                  <Box
                    onClick={() => setCurrentPage(3)}
                    className={
                      currentPage === 3
                        ? "pageNumber pageNumberActive"
                        : "pageNumber"
                    }
                  >
                    <p>4</p>
                  </Box>
                  <Box
                    onClick={() => setCurrentPage(4)}
                    className={
                      currentPage === 4
                        ? "pageNumber pageNumberActive"
                        : "pageNumber"
                    }
                  >
                    <p>5</p>
                  </Box>
                </Box>

                <Box onClick={handleNext} className="tabelBtn">
                  <Typography>{t("commonTxt.nextPage")}</Typography>
                  <img style={{ rotate: "180deg" }} src={arrow} />
                </Box>
              </Box>
            </Box>
          </Box>

        </Box>
      </Box>
    </Box>

  )
}
