import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Typography,
  Grid,
  FormLabel,
  TextField,
  Alert,
} from "@mui/material";
import "./style.css";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { useAppDispatch, useAppSelector } from "../../../lib/store/store";
import { changeEmailRequest, updateEmail, userProfile } from "../../../Services/auth";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { setUserProfile } from "../../../lib/store/features/authSlice";

export default function EmailManagement() {
  const [Password, setPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ErrorPassword, setErrorPassword] = useState(false);
  const [SuccessPassword, setSuccessPassword] = useState(false);
  const authState = useAppSelector((state) => state.auth);
  let dispatch = useAppDispatch();
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const [userEmails, setUserEmails] = useState({
    email: "",
    new_email: ""
  });

  const [otps, setOtps] = useState({
    otp: "",
    new_otp: ""
  });

  useEffect(() => {
    console.log(authState.userProfile)
    getEmail();
  }, [])

  const getEmail = async () => {
    let userProfileRes = await userProfile(authState.authToken);
    if (userProfileRes?.data?.success) {
      setUserEmails({ ...userEmails, email: userProfileRes?.data?.data?.email })
    }
  }

  const sendRequest = async () => {
    try {
      const response = await changeEmailRequest(userEmails, authState.authToken);
      setLoading(false);
      console.log(response)
      if (response.success) {
        toast.success(response.message, TOAST_CONFIG);
        setPassword(true);

      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  }

  const restEmail = async () => {
    try {
      const payload = {
        ...otps,
        email: userEmails.email,
        new_email: userEmails.new_email,
      }
      const response = await updateEmail(payload, authState.authToken);
      setLoading(false);
      console.log(response)
      if (response.success) {
        toast.success(response.message, TOAST_CONFIG);
        let userProfileRes = await userProfile(authState.authToken);
        if (userProfileRes?.data?.success) {
          dispatch(setUserProfile(userProfileRes?.data?.data));
          setPassword(false);
          getEmail();
          setUserEmails({ ...userEmails, email: "", new_email: "" })
          setOtps({ ...otps, otp: "", new_otp: "" })
        }
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  }
  return (
    <>
      <Box className="PasswordManagementSection">
        <Typography className="emailManageMainLabel">
          {t("emailManagementPage.changeEmail")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <FormLabel className="label">
              {t("emailManagementPage.existing")}
            </FormLabel>
            <TextField
              fullWidth
              id="outlined-basic"
              variant="outlined"
              size="small"
              value={userEmails.email}
              disabled
            />
          </Grid>
          <Grid item sm={12}>
            <FormLabel className="label">
              {t("emailManagementPage.enterNew")}
            </FormLabel>
            <TextField
              fullWidth
              id="outlined-basic"
              variant="outlined"
              size="small"
              placeholder="Enter new email address"
              onChange={(el) => setUserEmails({ ...userEmails, new_email: el.target.value })}
            />
          </Grid>
          <Grid item sm={12}>
            <Box className="workPupBtnBox">
              <AppBtn
                buttonText="Change Email"
                // onClick={() => setPassword(!Password)}
                onClick={sendRequest}
              />
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{ display: Password ? "block" : "none" }}
          className="ChangePasswordForm"
        >
          <Grid container spacing={2} className="PasswordForm">
            <Grid item sm={6}>
              <FormLabel className="label">
                {t("emailManagementPage.enterTheCode")}
              </FormLabel>
              <TextField
                fullWidth
                id="outlined-basic"
                placeholder={t(
                  "emailManagementPage.enteerTheMailCodePlaceHolder"
                )}
                variant="outlined"
                size="small"
                onChange={(el) => setOtps({ ...otps, otp: el.target.value })}
              />
            </Grid>
            <Grid item sm={6}>
              <FormLabel className="label">
                {t("emailManagementPage.enterTheCode2")}
              </FormLabel>
              <TextField
                fullWidth
                id="outlined-basic"
                placeholder={t(
                  "emailManagementPage.enteerTheNewMailCodePlaceHolder"
                )}
                variant="outlined"
                size="small"
                onChange={(el) => setOtps({ ...otps, new_otp: el.target.value })}
              />
            </Grid>
            <Grid item sm={12}>
              <Box className="workPupBtnBox">
                <AppBtnOutLine buttonText="Cancel" />
                <AppBtn
                  buttonText={t("emailManagementPage.saveMailBtn")}
                  // onClick={() => setErrorPassword(!ErrorPassword)}
                  onClick={restEmail}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{ display: ErrorPassword ? "block" : "none" }}
          className="ChangePasswordForm"
        >
          <Typography variant="h3" gutterBottom>
            {t("emailManagementPage.errorEmail")}
          </Typography>
          <Alert
            icon={<InfoOutlinedIcon />}
            severity="success"
            className="alertnote"
          >
            <ul>
              <li>
                {t("emailManagementPage.characterConsist")}
                (e.g., ".", "-", "_"){" "}
              </li>
              <li>{t("emailManagementPage.instruction1")}</li>
              <li>{t("emailManagementPage.instruction2")}</li>
            </ul>
          </Alert>

          <Grid container spacing={2} className="PasswordForm">
            {/* <Grid item sm={6}>
              <FormLabel className="label">Current Password</FormLabel>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Enter current password"
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item sm={6}>

            </Grid> */}
            <Grid item sm={6}>
              <FormLabel className="label error">
                {t("emailManagementPage.enterTheCode")}
              </FormLabel>
              <TextField
                fullWidth
                error
                id="outlined-basic"
                placeholder={t(
                  "emailManagementPage.enteerTheMailCodePlaceHolder"
                )}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item sm={6}>
              <FormLabel className="label error">
                {t("emailManagementPage.enterTheCode2")}
              </FormLabel>
              <TextField
                fullWidth
                error
                helperText="Email already exist"
                id="outlined-basic"
                placeholder={t(
                  "emailManagementPage.enteerTheNewMailCodePlaceHolder"
                )}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item sm={12}>
              <Box className="workPupBtnBox">
                <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} />
                <AppBtn
                  buttonText={t("emailManagementPage.saveMailBtn")}
                  onClick={() => setSuccessPassword(!SuccessPassword)}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{ display: SuccessPassword ? "block" : "none" }}
          className="ChangePasswordForm"
        >
          <Typography variant="h3" gutterBottom>
            {t("emailManagementPage.successMail")}
          </Typography>
          <Alert
            icon={<InfoOutlinedIcon />}
            severity="success"
            className="alertnote"
          >
            <ul>
              <li>
                {t("emailManagementPage.characterConsist")}
                (e.g., ".", "-", "_"){" "}
              </li>
              <li>{t("emailManagementPage.instruction1")}</li>
              <li>{t("emailManagementPage.instruction2")}</li>
            </ul>
          </Alert>

          <Grid container spacing={2} className="PasswordForm">
            {/* <Grid item sm={6}>
              <FormLabel className="label">Current Password</FormLabel>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Enter current password"
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item sm={6}>

            </Grid> */}
            <Grid item sm={6}>
              <FormLabel className="label success">
                {t("emailManagementPage.enterTheCode")}
              </FormLabel>
              <TextField
                fullWidth
                color="success"
                focused
                id="outlined-basic"
                placeholder={t(
                  "emailManagementPage.enteerTheMailCodePlaceHolder"
                )}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item sm={6}>
              <FormLabel className="label success">
                {t("emailManagementPage.enteerTheNewMailCodePlaceHolder")}
              </FormLabel>
              <TextField
                fullWidth
                color="success"
                focused
                id="outlined-basic"
                placeholder={t(
                  "emailManagementPage.enteerTheNewMailCodePlaceHolder"
                )}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item sm={12}>
              <Box className="workPupBtnBox">
                <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} />
                <AppBtn buttonText={t("emailManagementPage.saveMailBtn")} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
