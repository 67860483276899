import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.css";
//images
import dropArrow from "../../../Assets/Images/chevron-down.png";
//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import AddResearchPublication2 from "../AddResearchPublication2";

export default function AddResearchPublication({
    addResearchPublication,
    setAddResearchPublication,
}) {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const Navigate = useNavigate();
  const [addedProjectDrop, setAddedProjectDrop] = useState(false);
  const [addedProjectDropVal, setAddedProjectDropVal] = useState();
  const [lastVisitDrop, setLastVisitDrop] = useState(false);
  const [lastVisitDropVal, setLastVisitDropVal] = useState();
  const [addResearchPublication2, setAddResearchPublication2] = useState(false);

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
        setAddResearchPublication(false);
    }
  };

  const handelPublicationPop = () => {
    setAddResearchPublication(false);
    setAddResearchPublication2(true);
  }

  useEffect(() => {
    if (addResearchPublication) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [addResearchPublication]);

  return (
    <>
    <AddResearchPublication2  
        addResearchPublication2={addResearchPublication2} 
        setAddResearchPublication2={setAddResearchPublication2} 
        setAddResearchPublication={setAddResearchPublication}
    />
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: addResearchPublication ? "flex" : "none" }}
        className="companyPop addActivitiesPop"
      >
        <Box
          sx={{ display: addResearchPublication ? "flex" : "none" }}
          className="workspaceContaint"
        >
          
            <Typography className="companyHeader">Create New Publication</Typography>
            <Typography mb={2} className="basicDetailSteps">Basic Details (Step 1 of 3)</Typography>
            <Typography className="topicPopLabels">Publication Name</Typography>
            <Box className="input_Box">
              <input
                type="text"
                className="eTemInput"
                placeholder="Enter a name for the publication"
              />
            </Box>
            <Box style={{display:"flex",gap:"16px"}} className="allPbasicFinputBox">
                <Box className="inputBoxWlabel">
                <Typography className="inputLabel">Select the completed project to publish </Typography>

                <Box
                    className="inputAppDrop"
                    onClick={() => setAddedProjectDrop(!addedProjectDrop)}
                >
                    <Typography>
                    {addedProjectDropVal || "Select amongst the projects completed"}
                    </Typography>
                    <img
                    style={{ rotate: addedProjectDrop ? "180deg" : "0deg" }}
                    src={dropArrow}
                    />
                    {/* pop */}
                    <Box
                    sx={{ height: addedProjectDrop ? "auto" : "0px" }}
                    className="statusPop"
                    >
                    <Box
                        onClick={() => setAddedProjectDropVal("Project 1")}
                        className="statusPopItem"
                    >
                        <Typography>Project 1</Typography>
                    </Box>
                    </Box>
                </Box>
                </Box>

                <Box className="inputBoxWlabel">
                <Typography className="inputLabel">Select the publication format</Typography>

                <Box
                    className="inputAppDrop"
                    onClick={() => setLastVisitDrop(!lastVisitDrop)}
                >
                    <Typography>
                    {lastVisitDropVal || "Select the format in which you want to publish"}
                    </Typography>
                    <img
                    style={{ rotate: lastVisitDrop ? "180deg" : "0deg" }}
                    src={dropArrow}
                    />
                    {/* pop */}
                    <Box
                    sx={{ height: lastVisitDrop ? "auto" : "0px" }}
                    className="statusPop"
                    >
                    <Box
                        onClick={() => setLastVisitDropVal("Power Point/ Google Slides")}
                        className="statusPopItem"
                    >
                        <Typography>Power Point/ Google Slides</Typography>
                    </Box>
                    <Box
                        onClick={() => setLastVisitDropVal("Word File/Google Docs")}
                        className="statusPopItem"
                    >
                        <Typography>Word File/Google Docs</Typography>
                    </Box>
                    <Box
                        onClick={() => setLastVisitDropVal("PDF")}
                        className="statusPopItem"
                    >
                        <Typography>PDF</Typography>
                    </Box>
                    </Box>
                </Box>
                </Box>
            </Box>

            <Box className="workPupBtnBox">
              <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => setAddResearchPublication(false)} />
              <AppBtn buttonText={t("commonTxt.continueBtn")} onClick={() => handelPublicationPop()} />
            </Box>
          
          
        </Box>
      </Box>
    </>
  );
}
