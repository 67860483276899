import React, { useState } from 'react'
import "./style.css"
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";

//images
import banner from "../../../Assets/Images/logBanner.png"
import CLogo from "../../../Assets/Images/Frame 243811.png"
import googleIcon from "../../../Assets/Images/google.png"
import whiteArrow from "../../../Assets/Images/white Arrow.png"
import VisibleIcon from "../../../Assets/Images/VisibleIcon.png"
import FacebookIcon from "../../../Assets/Images/facebook.png"
import RedInfo from "../../../Assets/Images/info.png";
import { login, setUserWorkspace, userProfile } from '../../../Services/auth';
import { AppBtn, AppBtnOutLine, PurplelBtn, RedBtn } from '../../../Components/AppTools/AppButton';
import { toast } from 'react-toastify';
import { TOAST_CONFIG, USER_TYPE_WS_ADMIN } from '../../../lib/constants';
import { admininvationsResponse } from '../../../Services/adminActions';
import { useAppDispatch, useAppSelector } from '../../../lib/store/store';
import { setAuthState, setUserProfile, setUserType } from '../../../lib/store/features/authSlice';
import { getUserTypeFromSegment } from '../../../lib/helpers';

export default function AdminInvitations() {
    const Navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    const inviteRspnse = async (status) => {
        try {
            const payload = {
                "email": params.get('email'),
                "token": params.get('token'),
                "status": status
            }

            setLoading(true)
            const response = await admininvationsResponse(payload)
            setLoading(false);
            if (response.success) {
                toast.success(response.message, TOAST_CONFIG)
                const userType = getUserTypeFromSegment(response?.data?.user_type);
                if (status === "accepted") {
                    const userProfileRes = await userProfile(response?.data?.token);
                    dispatch(setAuthState(response?.data?.token));
                    dispatch(setUserType(userType));
                    if (userProfileRes?.data?.success) {
                        if (userType == USER_TYPE_WS_ADMIN) {
                            const wsDetails = (userProfileRes?.data?.data?.workspaces?.length > 0) ? userProfileRes?.data?.data?.workspaces[0] : null;
                            if (wsDetails !== null) {
                                setUserWorkspace(userProfileRes?.data?.data, wsDetails?._id, userType, dispatch);
                            }
                        }
                        dispatch(setUserProfile(userProfileRes?.data?.data));
                        let redirectUrl = `/registration`
                        if (response.data?.project_invite_id)
                            redirectUrl += `?pinvit=${response.data?.project_invite_id}`
                        Navigate(redirectUrl)
                    } else {
                        Navigate(`/admin/login`)
                    }
                } else
                    Navigate(`/admin/login`)
            } else {
                toast.error(response.message, TOAST_CONFIG);
            }
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong! Please try again', TOAST_CONFIG);
            setLoading(false);
        }
    }

    return (
        <>
            <Box className="logInPage">
                <Box className="banner">
                    <Typography className=''>Superadmin Proposal</Typography>
                    <Box mt={3} className="slideBox">
                        <Box className="sBox"></Box>
                        <Box className="sBox"></Box>
                        <Box className="sBox sBoxActive"></Box>
                    </Box>
                    <img src={banner} />
                </Box>

                <Box className="signInBox">
                    <Box className="signInInnerBox">
                        <Box className="signColog">
                            <img onClick={() => Navigate("/")} src={CLogo} />
                        </Box>
                        <Box>
                            <RedBtn buttonText="Cancel" onClick={() => inviteRspnse("rejected")} />
                            <PurplelBtn buttonText="Accept" onClick={() => inviteRspnse("accepted")} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
