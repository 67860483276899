import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: "20px",
    width: "100%"
  },
  indeterminateColor: {
    color: "#101828"
  },
  selectAllText: {
    fontWeight: 400
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP =0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};

const options = [
  "Outdoor Activity: Camping",
  "Outdoor Activity: Fishing",
  "Outdoor Activity: Kayaking",
  "Outdoor Activity: Cycling",
];

export { useStyles, MenuProps, options };
