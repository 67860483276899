import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import "./style.css";

import { useTranslation } from "react-i18next";

//images
import backArrow from "../../../Assets/Images/noun-back.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";
import VisibleIcon from "../../../Assets/Images/VisibleIcon.png";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import optionIcon from "../../../Assets/Images/optionIcon.png";
import SideMenu from "../../../Components/Researchers/SideMenu";

//data
import { timeZone, countryList, sideHaumIcon } from "../../../Assets/Data";

//components
import AllParticipants from "../../../Pages/WorkspaceAdministration/AllParticipants";
import { OptionDrop, AntSwitch } from "../../../Components/AppTools/AppTool";
import { AppBtnOutLine, AppBtn } from "../../../Components/AppTools/AppButton";

export default function AddProjectParticipants({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
}) {
  const Navigate = useNavigate();

  const [optionDrop, setOptionDrop] = useState(false);
  const [addParticipants, setAppParticipants] = useState(false);
  const [optionDropVal, setOptionDropVal] = useState("Options");
  const [redio, setRedio] = useState(0);
  const [groupDrop, setGroupDrop] = useState(true);
  const [pwdVisible, setPwdVisible] = useState(false);
  const [cpwdVisible, setCpwdVisible] = useState(false);
  const [countryDrop, setCountryDrop] = useState(false);
  const [countryDropVal, setCountryDropVal] = useState();
  const [timeZoneDrop, setTimeZoneDrop] = useState(false);
  const [timeZoneVal, setTimeZoneVal] = useState(
    "IST (Indian Standard Time): 5:01 PM"
  );

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const previousPageHandler = () => {
    Navigate("/researchers/project/directory_participants");
  };

  const chackBoxRoom = () => {
    return (
      <Box className="checkBoxItem">
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            checked={redio === 0 ? true : false}
          />
          <Typography>Group 0</Typography>
        </Box>
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            checked={redio === 0 ? true : false}
          />
          <Typography>Group 0</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box className="ResearchersContainer ">
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
          </Box>

          <Box className="researchersMainBox pageContainer">
            <Box className="allParticipantsBox workspaceListBox">
              <Box onClick={() => previousPageHandler()} className="openBtn">
                <img src={backArrow} />
                <Typography className="neonBackText">
                  {t("commonTxt.backBtn")}
                </Typography>
              </Box>

              <Box className="addParticipantsHeader">
                <Typography className="pageHeaderText">
                  {t("AddProjectParticipants.addParticipant")}
                </Typography>

                <Box width={"220px"} height={"40px"}>
                  <OptionDrop
                    setOptionDrop={setOptionDrop}
                    optionDrop={optionDrop}
                    setOptionDropVal={setOptionDropVal}
                    optionDropVal={optionDropVal}
                  />
                </Box>
              </Box>

              <Box className="redioBtnBox">
                <Box className="redioItem" onClick={() => setRedio(0)}>
                  <Box
                    className={
                      redio === 0
                        ? "customRedio customRedioActive"
                        : "customRedio"
                    }
                  >
                    {" "}
                  </Box>
                  <Typography>{t("AddProjectParticipants.active")}</Typography>
                </Box>
                <Box className="redioItem" onClick={() => setRedio(1)}>
                  <Box
                    className={
                      redio === 1
                        ? "customRedio customRedioActive"
                        : "customRedio"
                    }
                  >
                    <Box></Box>
                  </Box>
                  <Typography>
                    {t("AddProjectParticipants.inactive")}
                  </Typography>
                </Box>
              </Box>
              <Typography className="allPartRedioLabel">
                {t("commonTxt.sBasicGroup")}
              </Typography>

              <Box className="searchGroupSection">
                <Box
                  className="inputAppDrop"
                  onClick={() => setGroupDrop(!groupDrop)}
                >
                  <Typography>{t("commonTxt.sBasicGroup")}</Typography>
                  <img
                    style={{ rotate: groupDrop ? "180deg" : "0deg" }}
                    src={dropArrow}
                  />
                </Box>
                <Box
                  sx={{ height: groupDrop ? "auto" : "0px" }}
                  className="checkMain_box"
                >
                  <Box className="ckeckBoxRoom">
                    {chackBoxRoom()}
                    {chackBoxRoom()}
                    {chackBoxRoom()}
                  </Box>
                </Box>
              </Box>

              <Typography className="allPartRedioLabel">
                {t("AddProjectParticipants.accDetails")}
              </Typography>

              <Box className="secondBox">
                <Box className="csPBox">
                  <Typography mb={1}>{t("commonTxt.fName")}</Typography>
                  <input
                    className="phoneInput"
                    placeholder="Enter First Name"
                  />
                </Box>
                <Box className="emailAddressBox">
                  <Typography mb={1}>{t("commonTxt.lastUpdated")}</Typography>
                  <input className="emailInput" placeholder="Enter Last Name" />
                </Box>
              </Box>
              <Box className="csPBox">
                <Typography mb={1}>{t("commonTxt.mailAddress")}</Typography>
                <input
                  className="phoneInput"
                  placeholder="Enter Email Address"
                />
              </Box>

              <Box className="airadioSwitchBox">
                <AntSwitch inputProps={{ "aria-label": "ant design" }} />
                <Typography className="switchText">
                  {t("AddProjectParticipants.unSubscribeMail")}
                </Typography>
              </Box>
              <Box className="csPBox">
                <Typography mb={1}>{t("commonTxt.uName")}</Typography>
                <Typography fontSize={"12px"} mb={1}>
                  {t("ExistingParticipants.textMsg")}
                </Typography>
                <input className="phoneInput" placeholder="Enter User Name" />
              </Box>
              <Box className="airadioSwitchBox">
                <AntSwitch inputProps={{ "aria-label": "ant design" }} />
                <Typography className="switchText">
                  {t("AddProjectParticipants.mustConfirm")}
                </Typography>
              </Box>
              <Typography mb={1}>New Password</Typography>
              <Typography fontSize={"12px"} mb={1}>
                {t("AddProjectParticipants.8Char")}
              </Typography>
              <Box mb={1} className="csPBox pwdInput">
                <input
                  className="phoneInput"
                  placeholder="Enter Password"
                  type={pwdVisible ? "text" : "password"}
                />
                <img
                  src={VisibleIcon}
                  onClick={() => setPwdVisible(!pwdVisible)}
                />
              </Box>
              <Box className="csPBox pwdInput">
                <input
                  className="phoneInput"
                  placeholder="Confirm New Password"
                  type={cpwdVisible ? "text" : "password"}
                />
                <img
                  src={VisibleIcon}
                  onClick={() => setCpwdVisible(!cpwdVisible)}
                />
              </Box>
              <Box className="airadioSwitchBox">
                <AntSwitch inputProps={{ "aria-label": "ant design" }} />
                <Typography className="switchText">
                  {t("AddProjectParticipants.passwordChange")}
                </Typography>
              </Box>
              <Typography className="allPartRedioLabel" mb={1}>
                {t("AddProjectParticipants.2Fa")}
              </Typography>
              <Typography className="" mb={1}>
                {t("AddProjectParticipants.2FaText")}
              </Typography>
              <Box className="airadioSwitchBox enS">
                <AntSwitch inputProps={{ "aria-label": "ant design" }} />
                <Typography className="switchText">
                  {t("commonTxt.Enabled")}
                </Typography>
              </Box>
              <Box ml={3}>
                <Box className="airadioSwitchBox enS">
                  <AntSwitch inputProps={{ "aria-label": "ant design" }} />
                  <Typography className="switchText">
                    {t("commonTxt.Enabled")}
                  </Typography>
                </Box>
                <Box mt={2} mb={6} className="alpOverCheck">
                  <input
                    style={{ marginRight: "16px" }}
                    className="custom-checkbox"
                    type="checkBox"
                    defaultChecked
                  />
                  <Typography>
                    {t("AddProjectParticipants.DNF_Override")}
                  </Typography>
                </Box>
              </Box>
              <p className="allPartRedioLabel">
                {t("AddProjectParticipants.tZone")}
              </p>
              <p style={{ marginBottom: "24px", marginTop: "10px" }} mb={1}>
                {t("AddProjectParticipants.darkModeText")}
              </p>

              <Box mt={3} className="companiesTopBox">
                <Box className="companyInputBoxWlabel">
                  <Typography mb={1} className="inputLabel">
                    {t("AddProjectParticipants.selectCountry")}
                  </Typography>

                  <Box
                    className="inputAppDrop"
                    onClick={() => setCountryDrop(!countryDrop)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <img
                        className="countryListImg"
                        src={
                          countryList[countryDropVal]?.img ||
                          countryList[0]?.img
                        }
                      />
                      <Typography ml={2}>
                        {countryList[countryDropVal]?.title ||
                          countryList[0]?.title}
                      </Typography>
                    </Box>
                    <img
                      style={{ rotate: countryDrop ? "180deg" : "0deg" }}
                      src={DownArrowIcon}
                    />
                    {/* pop */}
                    <Box
                      sx={{ height: countryDrop ? "auto" : "0px" }}
                      className="statusPop"
                    >
                      {countryList &&
                        countryList.map((el, index) => (
                          <Box
                            key={index}
                            onClick={() => setCountryDropVal(index)}
                            className="statusPopItem"
                          >
                            <img className="countryListImg" src={el.img} />
                            <Typography ml={2}>{el.title}</Typography>
                          </Box>
                        ))}
                    </Box>
                  </Box>
                </Box>
                {/* drop */}
                <Box className="companyInputBoxWlabel">
                  <Typography mb={1} className="inputLabel">
                    {t("AddProjectParticipants.selectTimeZone")}
                  </Typography>
                  <Box
                    className="inputAppDrop"
                    onClick={() => setTimeZoneDrop(!timeZoneDrop)}
                  >
                    <Typography>
                      {timeZoneVal || "Filter by Workspace Status"}
                    </Typography>
                    <img
                      style={{
                        rotate: timeZoneDrop ? "180deg" : "0deg",
                      }}
                      src={DownArrowIcon}
                    />
                    {/* pop */}
                    <Box
                      sx={{ height: timeZoneDrop ? "auto" : "0px" }}
                      className="statusPop"
                    >
                      {timeZone &&
                        timeZone?.map((el, index) => (
                          <Box
                            key={index}
                            onClick={() => setTimeZoneVal(el)}
                            className="statusPopItem"
                          >
                            <Typography>{el}</Typography>
                          </Box>
                        ))}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="profileFieldNav">
                <Typography className="allPartRedioLabel">
                  {t("AddProjectParticipants.profileFields")}
                </Typography>
                <Typography className="profileFieldNav2ndText">
                  {t("AddProjectParticipants.addProfileFields")}
                </Typography>
              </Box>

              <Box className="profileFieldBox">
                <Box>
                  <Box className="Text_BoxWDot">
                    {" "}
                    <img src={optionIcon} />{" "}
                    <Typography>
                      {t("AddProjectParticipants.currentAge")}
                    </Typography>
                  </Box>

                  <Box className="emailAddressBox">
                    <input
                      className="emailInput"
                      placeholder="Enter Last Name"
                      value={"" || "0"}
                    />
                  </Box>
                </Box>
                <Box>
                  <Box className="Text_BoxWDot">
                    {" "}
                    <img src={optionIcon} />{" "}
                    <Typography>
                      {t("AddProjectParticipants.houseHoldIncome")}
                    </Typography>
                  </Box>

                  <Box className="emailAddressBox">
                    <input
                      className="emailInput"
                      placeholder="Enter Last Name"
                      value={"" || "0"}
                    />
                  </Box>
                </Box>
                <Box>
                  <Box className="Text_BoxWDot">
                    {" "}
                    <img src={optionIcon} />{" "}
                    <Typography>
                      {t("AddProjectParticipants.genderQuest")}
                    </Typography>
                  </Box>

                  <Box className="emailAddressBox">
                    <input
                      className="emailInput"
                      placeholder="Enter Last Name"
                      value={"" || "0"}
                    />
                  </Box>
                </Box>
              </Box>

              <Box className="workPupBtnBox">
                <AppBtnOutLine
                  buttonText={t("commonTxt.cancelBtn")}
                  onClick={() => previousPageHandler()}
                />
                <AppBtn buttonText={t("commonTxt.saveBtn")} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
