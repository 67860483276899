import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import ReferenceDateUsingValue from "../../AppTools/DatePicker";
import ReferenceDateExplicitTimePicker from "../../AppTools/TimePicker";
import "./style.css";
import moment from "moment-timezone";
//images
import dropArrow from "../../../Assets/Images/chevron-down.png";
import chackBox from "../../../Assets/Images/Checkbox.png";
import check from "../../../Assets/Images/check.png";
import { useAppSelector } from "../../../lib/store/store";
import axios from '../../../lib/axios';
import { formatDate, getDatePickerVal } from "../../../lib/helpers";
import dayjs from 'dayjs';
import { TOAST_CONFIG } from "../../../env";
import { toast } from "react-toastify";
import { researcherAction } from "../../../Services/researcherActions";
import DeleteInterviewScheduledPop from "../../../Pages/Researchers/DeleteInterviewScheduledPop";
export default function EditMeetingPop({ editMeetingPop, setEditMeetingPop, scheduleId,
  setScheduleId, setReloadList }) {

  const authState = useAppSelector((state) => state.auth);
  const [redio, setRedio] = useState(0);
  const [groupDrop2, setGroupDrop2] = useState(false);
  const [timeSlotOpen, setTimeSlotOpen] = useState(false);
  const [deleteScheduledPop, setDeleteScheduledPop] = useState(false);
  const [chackVal, setChackVal] = useState([]);
  const [scheduleText, setScheduleText] = useState(null);
  const [availableHosts, setAvailableHosts] = useState([]);
  const scheduleObj = {
    activity_id: null,
    workspace_id: null,
    interview_date: null,
    start_time: null,
    end_time: null,
    hosts: [],
    participants: []
  }
  const [scheduleUpdate, setScheduleUpdate] = useState(scheduleObj);
  const [participantName, setParticipantName] = useState(null);
  const [scheduleData, setScheduleData] = useState(null);
  const [allSlots, setAllSlots] = useState([]);
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const pushData = (string, name) => {
    if (name === 'slot') {

    } else {

      setChackVal((prev) => [...prev, string]);
    }
  };
  const deleteAllCheckData = () => setChackVal([]);
  const selectAllCheckData = () => {
    const hosts = availableHosts.map(el => (el._id))
    setChackVal(hosts);
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setEditMeetingPop(false);
    }
  };

  useEffect(() => {
    if (editMeetingPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    getEditMeeting();
  }, [editMeetingPop]);

  const deleteSchedule = (schedule) => {
    const { interview_date, start_time, end_time, participantDetails } = schedule;

    const formattedDate = moment(interview_date).format('MMMM Do, YYYY');
    const participant = participantDetails[0];
    const participantName = participant ? participant.name : 'Unknown Participant';

    const scheduleText = `
    Date: ${formattedDate}, Start Time: ${start_time}, End Time: ${end_time}, 
    Participant Name: ${participantName}`;
    setScheduleText(scheduleText.trim());
    setDeleteScheduledPop(true);
  }

  const checkAvailableHost = async (start_time, end_time) => {
    const response = await axios.get(`/researchers/get-activity-admins?activity_id=${scheduleUpdate.activity_id}`, {
      params: {
        start_time,
        end_time,
        interview_date: scheduleUpdate.interview_date
      },
    })
    if (response.success) {
      setAvailableHosts(response.data)
    }
  }

  const getEditMeeting = async () => {
    if (scheduleId) {
      let url = `researchers/interview-schedule?_id=${scheduleId}`;
      return await axios
        .get(url)
        .then((res) => {
          if (res.status) {
            if (res.data.data.length > 0) {
              const schedule = res.data.data[0];
              setScheduleUpdate({
                activity_id: schedule.activity_id,
                workspace_id: schedule.workspace_id,
                interview_date: schedule.interview_date,
                start_time: schedule.start_time,
                end_time: schedule.end_time,
                hosts: schedule.hosts,
                participants: schedule.participants,
              })
              setScheduleData(schedule);
              setParticipantName(schedule.participantDetails[0].name)
              getSlots(schedule.activity_id, schedule.interview_date);
              setChackVal(schedule.hosts)
              checkAvailableHost(schedule.start_time, schedule.end_time)
            }
          }
        });
    }
  }

  const selectedHosts = () => {
    const hostName = availableHosts.filter(host => chackVal.includes(host._id)).map(el => el.name)
    return hostName.length > 0 ? (hostName.length > 3 ? `${hostName.length} hosts are selected` : hostName.join(', ')) : t("LiveInterview.SelectMHost");
  }

  const parseTime = (time, interview_date = null) => {
    const currentDate = interview_date ? interview_date : moment().format("YYYY-MM-DD");
    return new Date(`${currentDate}T${moment(time, 'h:mm A').format('HH:mm:ss')}`);
  }

  const getInterviewStatus = (start_time, end_time, interview_date = null) => {
    const startDateTime = parseTime(start_time, interview_date);
    const endDateTime = parseTime(end_time, interview_date);
    const now = new Date();
    if (now < startDateTime) {
      return { status: 0, statusTxt: "Upcoming" };
    } else if (now >= startDateTime && now <= endDateTime) {
      return { status: 1, statusTxt: "Ongoing" };
    } else {
      return { status: 2, statusTxt: "Completed" };
    }
  }

  const getSlots = async (activity_id, interview_date) => {
    if (activity_id) {
      const response = await axios.get(`/researchers/get-interview-slots?activity_id=${activity_id}`, {
        params: {
          interview_date: interview_date
        },
      })
      if (response.success) {
        const slots = {
          start_date: response.data.start_date,
          end_date: response.data.end_date,
          start_time: response.data.start_time,
          end_time: response.data.end_time,
          meeting_duration: response.data.meeting_duration,
          meeting_days: response.data.meeting_days,
          slots: response.data.slots
        }
        setAllSlots(slots)
      }
    }
  }

  const handleSubmit = async () => {
    if (redio === 1) {
      const payload = {
        ...scheduleUpdate,
        hosts: chackVal
      }
      try {
        const reqUrl = '/researchers/interview-schedule/' + scheduleId;
        const response = await researcherAction(reqUrl, payload, authState.authToken, false);
        if (response.success) {
          setScheduleId(null)
          setScheduleUpdate(scheduleObj)
          toast.success(response.message, TOAST_CONFIG);
          setReloadList(true);
          setEditMeetingPop(false);
        } else {
          toast.error(response.message, TOAST_CONFIG);
        }
      } catch (error) {
        toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      } finally {
      }
    } else if (redio === 2) {
      deleteSchedule(scheduleData)
    }
  }

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: editMeetingPop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople"
      >
        <DeleteInterviewScheduledPop
          deleteScheduledPop={deleteScheduledPop}
          setDeleteScheduledPop={setDeleteScheduledPop}
          scheduleId={scheduleId}
          setScheduleId={setScheduleId}
          scheduleText={scheduleText}
          setScheduleText={setScheduleText}
          setReloadList={setReloadList}
        />
        <Box
          sx={{ display: editMeetingPop ? "flex" : "none" }}
          className="workspaceContaint deletePagePop"
        >
          <Typography className="eTemPopLabel">
            {t("editMeetingPop.editMeetingHead")} : {participantName}
          </Typography>
          <Box mt={3} className="redioItem" onClick={() => setRedio(0)}>
            <Box
              className={
                redio === 0
                  ? "customRedio customRedioActive"
                  : "customRedio"
              }
            >
              <Box></Box>
            </Box>
            <Typography>{t("editMeetingPop.editMeetingRadio1")}</Typography>
          </Box>
          <Box sx={{ display: redio === 0 ? "block" : "none" }} className="meetingTimeMessage">
            <Typography className="eTemPopLabel">
              {t("editMeetingPop.enterMessageLabel")}
            </Typography>

            <textarea
              className="DmettingTextTextAera"
              placeholder={t("editMeetingPop.enterMessageLabel")}
            />
          </Box>
          <Box mt={3} className="redioItem" onClick={() => setRedio(1)}>
            <Box
              className={
                redio === 1
                  ? "customRedio customRedioActive"
                  : "customRedio"
              }
            >
              <Box></Box>
            </Box>
            <Typography>{t("editMeetingPop.editMeetingRadio2")}</Typography>
          </Box>
          <Box
            mt={2}
            ml={5}
            sx={{ display: redio === 1 ? "block" : "none" }}
          >
            <Box className="pickerBox">
              <Typography className="eTemPopLabel">{t("LiveInterview.ScheduleMeeting")}</Typography>
              <Box className="pickerBox">
                <ReferenceDateUsingValue
                  value={dayjs(scheduleUpdate?.interview_date)}
                  minDate={dayjs(formatDate(new Date(), "YYYY-MM-DD"))}
                  maxDate={dayjs(allSlots?.end_date)}
                  onChange={e => {
                    setScheduleUpdate({ ...scheduleUpdate, interview_date: getDatePickerVal(e, "date") });
                    getSlots(scheduleUpdate.activity_id, scheduleUpdate.interview_date)
                  }} />
              </Box>
              <Box className="pickerBox">
                <Box pl={4.5} className="inputAppDrop">
                  <Box onClick={() => setTimeSlotOpen(!timeSlotOpen)}>
                    {
                      (scheduleUpdate.start_time) ? <Typography>{scheduleUpdate.start_time} To {scheduleUpdate.end_time}</Typography> : <Typography>Select Slot</Typography>
                    }

                  </Box>
                  <img
                    className="PartDropIcon"
                    style={{ rotate: timeSlotOpen ? "180deg" : "0deg" }}
                    src={dropArrow}
                    onClick={() => setTimeSlotOpen(!timeSlotOpen)}
                  />

                  <Box
                    sx={{ display: timeSlotOpen ? "block" : "none" }}
                    className="statusPop"
                  >
                    {allSlots?.slots?.length > 0 && allSlots?.slots.map((slot) => ((!slot.metadata && getInterviewStatus(slot.slot_time.start, slot.slot_time.end).status === 0) ? <Box
                      className="statusPopItem"
                      onClick={() => {
                        if ((scheduleUpdate.start_time === slot.slot_time.start && scheduleUpdate.end_time === slot.slot_time.end)) {
                          setScheduleUpdate({ ...scheduleUpdate, start_time: null, end_time: null })
                        } else {
                          setScheduleUpdate({ ...scheduleUpdate, start_time: slot.slot_time.start, end_time: slot.slot_time.end })
                          setTimeSlotOpen(!timeSlotOpen)
                          checkAvailableHost(slot.slot_time.start, slot.slot_time.end)
                        }

                      }}
                    >
                      <Box className="chackBox">
                        <img className="chackBoxImg" src={chackBox} />
                        <img
                          style={{
                            display: (scheduleUpdate.start_time === slot.slot_time.start && scheduleUpdate.end_time === slot.slot_time.end)
                              ? "block"
                              : "none",
                          }}
                          className="chack"
                          src={check}
                        />
                      </Box>
                      <p>{slot.slot_time.start} To {slot.slot_time.end}</p>
                    </Box> : null))}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Typography className="eTemPopLabel">{t("LiveInterview.SelectMH")}</Typography>
            <Box pl={4.5} className="inputAppDrop">
              <Box onClick={() => setGroupDrop2(!groupDrop2)}>
                <Typography>{selectedHosts()}</Typography>
              </Box>
              <img
                className="PartDropIcon"
                style={{ rotate: groupDrop2 ? "180deg" : "0deg" }}
                src={dropArrow}
                onClick={() => setGroupDrop2(!groupDrop2)}
              />

              <Box sx={{ display: groupDrop2 ? "block" : "none" }} className="statusPop">
                <Box className="checkSelectorBox">
                  <span onClick={selectAllCheckData}>{t("commonTxt.selectAll")}</span >
                  <span onClick={deleteAllCheckData}>{t("commonTxt.deSelectAll")}</span>
                </Box>


                {
                  availableHosts?.map((el, i) => (
                    <Box key={i} className="statusPopItem"
                      onClick={() => {
                        if (chackVal.includes(el._id)) {
                          console.log(chackVal)
                          setChackVal(chackVal.filter(item => item !== el._id))
                          return;
                        } else {
                          pushData(el._id)
                        }
                      }}

                    >
                      <Box className="chackBox">
                        <img className="chackBoxImg" src={chackBox} />
                        <img style={{ display: chackVal.includes(el._id) ? "block" : "none" }} className="chack" src={check} />
                      </Box>
                      <p>{el.name}</p>
                    </Box>
                  ))
                }
              </Box>
            </Box>
          </Box>
          <Box mt={3} className="redioItem" onClick={() => setRedio(2)}>
            <Box
              className={
                redio === 2
                  ? "customRedio customRedioActive"
                  : "customRedio"
              }
            >
              <Box></Box>
            </Box>
            <Typography>{t("editMeetingPop.editMeetingRadio3")}</Typography>
          </Box>

          <Box className="editMeetingNotifyBtn">
            <Box className="editMeetingNotify agreementRadioBox">
              <Box>
                <input
                  className="emailSettingInput emailSettingRadio"
                  type='checkbox'
                  defaultChecked
                />
              </Box>
              <Box>
                <Typography className="emailSettingLabel">{t("LiveInterview.NotifyParticipants")}</Typography>
              </Box>
            </Box>
            <Box className="editMeetingBtn">
              <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => setEditMeetingPop(false)} />
              <AppBtn buttonText={t("commonTxt.saveBtn")} onClick={handleSubmit} />
            </Box>
          </Box>

        </Box>
      </Box>
    </>
  );
}
