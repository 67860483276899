import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./style.css";

//images
import OptionLine from "../../../Assets/Images/Option2LIcon.png"
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png";
import groupsIcon from "../../../Assets/Images/groupsIcon.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";
import searchIcon from "../../../Assets/Images/search.png"
import dropIcon from "../../../Assets/Images/chevron-downp.png"


import { archive, duplicateIcon, deletSvg } from "../../../Assets/Data";
//component
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { BigDrop } from "../../AppTools/AppTool";

export default function PageSettingPop({ allDropVal3, setAllDropVal3, setDuplicatePagePop, setDeletePagePop }) {
  
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const [nav, setNav] = useState(0)
  const [openTab, setOpenTab] = useState()
  const [image, setImage] = useState(null);
  const [Drop, setDrop] = useState(false);
  const [DropVal, setDropVal] = useState("Select Participants");

  const [Drop2, setDrop2] = useState(false);
  const [DropVal2, setDropVal2] = useState("All Administrators");

  const [Drop3, setDrop3] = useState(false);
  const [DropVal3, setDropVal3] = useState("Select Participants");

  const [Drop4, setDrop4] = useState(false);
  const [DropVal4, setDropVal4] = useState("All Administrators");

  const [groupDrop2, setGroupDrop2] = useState(false);
  const [redio, setRedio] = useState(0);

  const [option, setOption] = useState(false);
  const [optionVal, setOptionVal] = useState();
  const optionAry = [
    "Duplicate",
    "Archive",
    "Delete"
  ]
  const [searchDrop, setSearchDrop] = useState(false);
  const [adminsMore, setAdminsMore] = useState(false);
  const [selectedAdminRoles, setSelectedAdminRoles] = useState([]);
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const [selectedParticipantGroups, setSelectedParticipantGroups] = useState([]);

  const ParticipantItem = [
    "All Participants",
    "Select Participant Groups",
    "None"
  ]

  const AdministratorItem = [
    "All Administrators",
    "Select Admin Roles",
    "Select Admins"
  ]


  const handelDrop = (drop) => {
    if (openTab === drop) {
      setOpenTab(null)
      return;
    }
    setOpenTab(drop)
  }



  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const chackBoxRoom = (val, text) => {
    return (
      <Box className="checkBoxItem" key={`group-${val}`}>
        <Box >
          <input
            className="custom-checkbox"
            type="checkBox"
            name={`group-${val}`}
            value={val}
            checked={selectedParticipantGroups.includes(val)}
            onChange={handleParticipantGroupsChange}
          />
          <Typography>{text}</Typography>
        </Box>
      </Box>
    );
  };

  const AdminRolesCheckBox = (val,text) => {
    return (
      <Box className="checkBoxItem" key={`adminrole-${val}`} >
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            name={`adminrole-${val}`}
            value={val}
            checked={selectedAdminRoles.includes(val)}
            onChange={handleAdminRolesChange}
          />
          <Typography>{text}</Typography>
        </Box>
      </Box>
    );
  };
  const AdminsCheckBox = (val,text) => {
    return (
      <Box className="checkBoxItem" key={`admin-${val}`}>
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            name={`admin-${val}`}
            value={val}
            checked={selectedAdmins.includes(val)}
            onChange={handleAdminsChange}
          />
          <Typography>{text}</Typography>
        </Box>
      </Box>
    );
  };

  const handleAdminRolesChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedAdminRoles([...selectedAdminRoles, value]);
    } else {
      setSelectedAdminRoles(selectedAdminRoles.filter(admin => admin !== value));
    }
  };
  const handleAdminsChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedAdmins([...selectedAdmins, value]);
    } else {
      setSelectedAdmins(selectedAdmins.filter(admin => admin !== value));
    }
  };
  const handleParticipantGroupsChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedParticipantGroups([...selectedParticipantGroups, value]);
    } else {
      setSelectedParticipantGroups(selectedParticipantGroups.filter(participant => participant !== value));
    }
  };

  const Option = ({
    setDuplicatePagePop,
    setDeletePagePop,
    setAllDropVal3,
    setStatusDrop,
    statusDrop,
    setStatusDropVal,
    statusDropVal,
    label,
  }) => {

    return (
      <Box onClick={() => setStatusDrop(!statusDrop)} className="statusBox">
        <Typography className="statusLabel">
          {statusDropVal || t("groups.option")}
        </Typography>
        <img
          style={{ rotate: statusDrop ? "180deg" : "0deg" }}
          src={DownArrowIcon}
        />
        <Box sx={{ height: statusDrop ? "auto" : "0px" }} className="statusPop">

          <Box
            // onClick={() => setStatusDropVal("Duplicate")}
            onClick={() => {
              setStatusDropVal("Duplicate");
              setDuplicatePagePop(true);
            }}
            className="statusPopItem">
            {duplicateIcon}
            <Typography>{t("observer.Duplicate")}</Typography>
          </Box>
          <Box onClick={() => setStatusDropVal("Archive")} className="statusPopItem">
            {archive}
            <Typography>{t("commonTxt.archiveBtn")}</Typography>
          </Box>
          <Box
            // onClick={() => setStatusDropVal("Delete")}
            onClick={() => {
              setAllDropVal3("Page Options");
              setStatusDropVal("Delete");
              setDeletePagePop(true);
            }}
            className="statusPopItem">
            {deletSvg}
            <Typography>{t("commonTxt.delete")}</Typography>
          </Box>

        </Box>
      </Box>
    );
  };


  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setAllDropVal3("Page Options");
    }
  };

  useEffect(() => {
    if (allDropVal3 === "Page Settings") {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [allDropVal3]);


  

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: allDropVal3 === "Page Settings" ? "flex" : "none" }}
        className="companyPop pageSettingPop"
      >
        <Box
          sx={{ display: allDropVal3 === "Page Settings" ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className="companyHeader">{t("pageSettingPop.pageSettingPopHead")} (Outdoor Journey)</Typography>

          <Box className="psNavBox">
            <Box onClick={() => setNav(0)} className={nav === 0 ? "psNavItemActive psNavItem" : "psNavItem"}>
              <Typography>{t("pageSettingPop.activePagesTab")}</Typography>
            </Box>
            <Box onClick={() => setNav(1)} className={nav === 1 ? "psNavItemActive psNavItem" : "psNavItem"}>
              <Typography>{t("pageSettingPop.archivedTab")}</Typography>
            </Box>
          </Box>


          <Box sx={{ display: nav === 0 ? "flex" : "none" }} className="pageSetMainBox">
            {/* ---------------------Active Pages--------------------- */}

            {/* --------------------------Researchers-------------------------- */}
            <Box className={openTab === "Researchers" ? "setPDropBox setPDropBoxOpen" : "setPDropBox"}>
              <Box className="menuTab" onClick={() => handelDrop("Researchers")}>
                <Box className="stdfb">
                  <img src={OptionLine} className="optionLineIcon" />
                  <Typography className="setPDropTitle">{t("workspaceHome.welcomeResearchers")}</Typography>
                </Box>
                <img src={DownArrowIcon} className="setPDropIcon" style={{ rotate: openTab === "Researchers" ? "180deg" : "0deg" }} />
              </Box>

              <Box sx={{ display: openTab === "Researchers" ? "block" : "none" }} className="SetMainCBox">
                <Typography className="psNlabel">{t("pageSettingPop.pageNameLabel")}</Typography>
                <input placeholder={t("duplicatePagePop.pageNamePlaceholder")} className="phoneInput" />
                <Box className="gapBox"></Box>
                <Typography className="psNlabel">{t("pageSettingPop.coverImageLabel")}</Typography>

                <Box className='mediaInputbox upload-area' onClick={() => {
                  document.getElementById("fileInput").click();
                }}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}>
                  <img src={uploadIcon} />
                  <Typography>{t("addProfile.clickOrDragText")}</Typography>
                  <input
                    id="fileInput"
                    style={{ display: "none" }}
                    type="file"
                    onChange={handleFileInputChange}
                  />
                </Box>
                <Box className="gapBox"></Box>
                <Typography className="psNlabel">{t("addMessageCardPop.participantAccess")}</Typography>
                <BigDrop setDrop={setDrop} Drop={Drop} setDropVal={setDropVal} DropVal={DropVal} dropItems={ParticipantItem} />

                {DropVal==="Select Participant Groups" &&  <Box className="searchGroupSection">
                  <Box
                    pl={4.5}
                    className="inputAppDrop"
                    onClick={() => setGroupDrop2(!groupDrop2)}
                  >
                    <img className="groupsIcon" src={groupsIcon} />
                    <Typography>{t("pageSettingPop.participantGroupPlaceholder")}</Typography>
                    <img
                      style={{ rotate: groupDrop2 ? "180deg" : "0deg" }}
                      src={dropArrow}
                    />
                  </Box>
                  <Box
                    sx={{ height: groupDrop2 ? "auto" : "0px" }}
                    className="checkMain_box"
                  >
                    <Box className="ckeckBoxRoom">
                      {chackBoxRoom('gp1','Group 1')}
                      {chackBoxRoom('gp2','Group 2')}
                      {chackBoxRoom('gp3','Group 3')}
                      {chackBoxRoom('gp4','Group 4')}
                      {chackBoxRoom('gp5','Group 5')}
                      {chackBoxRoom('gp6','Group 6')}
                      {chackBoxRoom('gp7','Group 7')}
                      {chackBoxRoom('gp8','Group 8')}
                      {chackBoxRoom('gp9','Group 9')}
                    </Box>
                    <Box className="propertyPagination">
                      <Box>
                        <p>1</p>
                      </Box>
                      <Box>
                        <p>2</p>
                      </Box>
                      <Box>
                        <p>3</p>
                      </Box>
                      <Box>
                        <p>4</p>
                      </Box>
                      <Box>
                        <p>...</p>
                      </Box>
                      <Box>
                        <p>8</p>
                      </Box>
                      <Box>
                        <p>9</p>
                      </Box>
                      <Box>
                        <p>10</p>
                      </Box>
                    </Box>
                  </Box>
                </Box>}
                
                <Typography className="psNlabel adminAccess">{t("addMessageCardPop.administratorAccess")}</Typography>
                <BigDrop setDrop={setDrop2} Drop={Drop2} setDropVal={setDropVal2} DropVal={DropVal2} dropItems={AdministratorItem} />


                {DropVal2==="Select Admin Roles" && 
                <Box className="checkMain_box">
                  <Box className="ckeckBoxRoom">
                      {AdminRolesCheckBox('allresearcher','All Researchers')}
                      {AdminRolesCheckBox('allcollaborators','All Collaborators')}
                      {AdminRolesCheckBox('allobservers','All Observers')}
                  </Box>
                </Box>}

                {DropVal2==="Select Admins" && 
                <>
                  <Box className="searchGroupSection">
                    <Box
                      pl={4.5}
                      className="inputAppDrop"
                      onClick={() => setSearchDrop(!searchDrop)}
                    >
                      <img className="groupsIcon" src={searchIcon} />
                      <Typography>{t("pageSettingPop.adminNamePlaceholder")}</Typography>
                      <img
                        style={{ rotate: searchDrop ? "180deg" : "0deg" }}
                        src={dropArrow}
                      />
                    </Box>
                    <Box
                      sx={{ height: searchDrop ? "auto" : "0px" }}
                      className="checkMain_box"
                    >
                      <Box className="searchDropAdminList admins">
                        
                          <Box className="ckeckBoxRoom">
                            {AdminsCheckBox('Olivia Rhye','Olivia Rhye')}
                            {AdminsCheckBox('Phoenix Baker','Phoenix Baker')}
                            {AdminsCheckBox('Kate Morrison','Kate Morrison')}
                          </Box>
                      

                          {adminsMore && 
                            <Box className="ckeckBoxRoom">
                              {AdminsCheckBox('Olivia Rhye2','Olivia Rhye2')}
                              {AdminsCheckBox('Phoenix Baker2','Phoenix Baker2')}
                              {AdminsCheckBox('Kate Morrison2','Kate Morrison2')}
                              {AdminsCheckBox('Olivia Rhye3','Olivia Rhye3')}
                              {AdminsCheckBox('Phoenix Baker3','Phoenix Baker3')}
                              {AdminsCheckBox('Kate Morrison3','Kate Morrison3')}
                            </Box>
                          }
                        <Box onClick={()=>setAdminsMore(!adminsMore)}>
                          {!adminsMore? (<Box className="viewMoreDrop">
                            <Typography>{t("commonTxt.viewMore")}</Typography>
                            <img src={dropIcon} />
                          </Box>):(<Box className="viewMoreDrop">
                            <Typography>{t("commonTxt.hideBtn")}</Typography>
                            <img src={dropIcon} />
                          </Box>)}
                        </Box>

                      </Box>
                      
                    </Box>
                  </Box>
                </>
                }

                <Box className="workPupBtnBox ActivePageBtnBox">
                  <Option setDuplicatePagePop={setDuplicatePagePop} setDeletePagePop={setDeletePagePop} setAllDropVal3={setAllDropVal3} statusDrop={option} setStatusDrop={setOption} setStatusDropVal={setOptionVal} statusDropVal={optionVal} label={t("Publications.options")} itemAry={optionAry} />
                  <AppBtn buttonText={t("commonTxt.saveBtn")} />
                </Box>
              </Box>

            </Box>


            {/* ------------------------Collaborators------------------------- */}
            <Box onClick={() => handelDrop("Collaborators")} className="setPDropBox">
              <Box className="menuTab">
                <Box className="stdfb">
                  <img src={OptionLine} className="optionLineIcon" />
                  <Typography className="setPDropTitle">{t("workspaceHome.collaboratorCorner")}</Typography>
                </Box>
                <img src={DownArrowIcon} className="setPDropIcon" style={{ rotate: openTab === "Collaborators" ? "180deg" : "0deg" }} />
              </Box>

              <Box className="SetMainCBox">

              </Box>
            </Box>


            {/* -----------------------------Observers------------------------------- */}
            <Box onClick={() => handelDrop("Observers")} className="setPDropBox">
              <Box className="menuTab">

                <Box className="stdfb">
                  <img src={OptionLine} className="optionLineIcon" />
                  <Typography className="setPDropTitle">{t("workspaceHome.observerCorner")}</Typography>
                </Box>
                <img src={DownArrowIcon} className="setPDropIcon" style={{ rotate: openTab === "Observers" ? "180deg" : "0deg" }} />
              </Box>

              <Box className="SetMainCBox">

              </Box>

            </Box>



            {/* -----------------------Participants------------------------- */}
            <Box onClick={() => handelDrop("Participants")} className="setPDropBox">
              <Box className="menuTab">

                <Box className="stdfb">
                  <img src={OptionLine} className="optionLineIcon" />
                  <Typography className="setPDropTitle">{t("workspaceHome.welcomeParticipants")}</Typography>
                </Box>
                <img src={DownArrowIcon} className="setPDropIcon" style={{ rotate: openTab === "Participants" ? "180deg" : "0deg" }} />
              </Box>
              <Box className="SetMainCBox">

              </Box>
            </Box>
          </Box>


          <Box sx={{ display: nav === 1 ? "flex" : "none" }} className="pageSetMainBox">
            {/* --------------------------------Archived------------------------------------ */}

            {/* ---------------------------Beforewestart--------------------- */}
            <Box className={openTab === "Beforewestart" ? "setPDropBox setPDropBoxOpen" : "setPDropBox"}>
              <Box onClick={() => handelDrop("Beforewestart")} className="menuTab">

                <Box className="stdfb">
                  <img src={OptionLine} className="optionLineIcon" />
                  <Typography className="setPDropTitle">{t("pageSettingPop.beforeParticipants")}</Typography>
                </Box>
                <img src={DownArrowIcon} className="setPDropIcon" style={{ rotate: openTab === "Beforewestart" ? "180deg" : "0deg" }} />
              </Box>
              <Box sx={{ display: openTab === "Beforewestart" ? "block" : "none" }} className="SetMainCBox">
                <Typography className="psNlabel">{t("pageSettingPop.pageNameLabel")}</Typography>
                <input placeholder={t("duplicatePagePop.pageNamePlaceholder")} className="phoneInput" />
                <Box className="gapBox"></Box>

                <Typography className="psNlabel">{t("addMessageCardPop.participantAccess")}</Typography>
                <BigDrop setDrop={setDrop3} Drop={Drop3} setDropVal={setDropVal3} DropVal={DropVal3} dropItems={ParticipantItem} />
                <Box className="gapBox"></Box>

                <Typography className="psNlabel">{t("addMessageCardPop.administratorAccess")}</Typography>
                <BigDrop setDrop={setDrop4} Drop={Drop4} setDropVal={setDropVal4} DropVal={DropVal4} dropItems={AdministratorItem} />

                <Box mt={8} className="workPupBtnBox">
                  <AppBtnOutLine buttonText={t("commonTxt.deleteBtn")} />
                  <AppBtn buttonText={t("commonTxt.restoreBtn")} />
                </Box>

              </Box>
            </Box>

            {/* ------------------------------- JanuaryMarch------------------------*/}
            <Box onClick={() => handelDrop("JanuaryMarch")} className="setPDropBox">
              <Box className="menuTab">

                <Box className="stdfb">
                  <img src={OptionLine} className="optionLineIcon" />
                  <Typography className="setPDropTitle">January - March 2023</Typography>
                </Box>
                <img src={DownArrowIcon} className="setPDropIcon" style={{ rotate: openTab === "JanuaryMarch" ? "180deg" : "0deg" }} />
              </Box>
              <Box className="SetMainCBox">

              </Box>
            </Box>
            {/* ---------------------------AprilJune---------------------- */}
            <Box onClick={() => handelDrop("AprilJune")} className="setPDropBox">
              <Box className="menuTab">

                <Box className="stdfb">
                  <img src={OptionLine} className="optionLineIcon" />
                  <Typography className="setPDropTitle">April - June 2023</Typography>
                </Box>
                <img src={DownArrowIcon} className="setPDropIcon" style={{ rotate: openTab === "AprilJune" ? "180deg" : "0deg" }} />
              </Box>
            </Box>
            <Box className="SetMainCBox">

            </Box>
          </Box>


          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => setAllDropVal3("Page Options")} />
            <AppBtn buttonText={t("commonTxt.doneBtn")} />
          </Box>

        </Box>
      </Box>
    </>
  );
}
