import React, { useState, useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import axios from "../../../lib/axios";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";

import plassIcon from "../../../Assets/Images/plassIcon.png";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { capitalizeEachWord, objectToFormData, urlToBase64 } from "../../../lib/helpers";
import { updateAdminUser, uploadFile } from "../../../Services/adminActions";
import { useAppSelector } from "../../../lib/store/store";
import { STORAGE_BASE_PATH } from "../../../env";

export default function EditAdminPop({
  editAdminPop,
  setEditAdminPop,
  setReloadList,
  reloadList,
  adminId
}) {
  const [genderDrop, setGenderDrop] = useState(false);
  const [genderDropVal, setGenderDropVal] = useState("");
  const [image, setImage] = useState(null);
  let fileInputRef = useRef();
  const initAdmin = {
    name: "",
    email: "",
    phone: "",
    gender: "",
    profile_pic: "",
    address_line1: "",
    status: "active",
  }

  let authState = useAppSelector(state => state.auth);
  const [adminData, setAdminData] = useState({ ...initAdmin })
  const [loading, setLoading] = useState(false);
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setEditAdminPop(false);
    }
  };

  useEffect(() => {
    if (editAdminPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
    getAdmin(adminId)
  }, [editAdminPop]);

  const getAdmin = async (adminId) => {
    try {
      if (!adminId)
        return false
      const response = await axios.get(`/super-admin/admin/users?_id=${adminId}`);
      if (response.success) {
        const admin = response.data?.data[0];
        let setData = {};
        for (const key in initAdmin) {
          if (admin.hasOwnProperty(key)) {
            setData[key] = admin[key]
          }
        }

        if (admin?.profile_pic == 'undefined' || admin?.profile_pic == null) {
          setImage(null);
        } else {
          urlToBase64(STORAGE_BASE_PATH + `/` + admin?.profile_pic, function (base64Img) {
            setImage(base64Img);
          })
        }
        setAdminData(setData);
        setGenderDropVal(capitalizeEachWord(setData.gender));
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  }

  const validationObj = {
    hasErr: false,
    'name': {
      error: false,
      msg: ''
    },
    'email': {
      error: false,
      msg: ''
    },
    'phone': {
      error: false,
      msg: ''
    },
    'gender': {
      error: false,
      msg: ''
    },
    'address_line1': {
      error: false,
      msg: ''
    }
  }

  const [validationErrors, setValidationErrors] = useState({ ...validationObj });

  const handleValidation = (formInput) => {
    formInput = formInput || adminData;
    let validationerr = { ...validationObj };

    if (formInput.name == "") {
      validationerr.hasErr = true;
      validationerr.name = {
        error: true,
        msg: 'Name is required'
      }
    }

    if (formInput.email == "") {
      validationerr.hasErr = true;
      validationerr.email = {
        error: true,
        msg: 'Email is required'
      }
    }

    if (formInput.phone == '') {
      validationerr.hasErr = true;
      validationerr.phone = {
        error: true,
        msg: 'Phone is required'
      }
    }

    if (formInput.gender == '') {
      validationerr.hasErr = true;
      validationerr.gender = {
        error: true,
        msg: 'Gender is required'
      }
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  }


  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      uploadImage(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      uploadImage(file);
    }
  };

  const uploadImage = async (file) => {
    if (!file)
      return false;
    const formData = new FormData()
    formData.append('file', file)
    try {
      let imageUploadRes = await uploadFile(formData, authState.authToken);
      if (imageUploadRes.success) {
        setAdminData({ ...adminData, profile_pic: imageUploadRes?.data?.upload_id })
        // toast.success(imageUploadRes.message, TOAST_CONFIG);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    } finally {

    }
  }

  const setFormData = (e) => {
    let newState = {
      ...adminData,
      [e.target.name]: e.target.value,
    };

    setAdminData(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
  };

  const updateAdmin = async () => {
    if (!handleValidation()) {
      toast.error('Please correct form fields', TOAST_CONFIG);
      return false;
    }

    setLoading(true);
    try {
      const payload = adminData;
      const response = await updateAdminUser(adminId, payload, authState.authToken);
      setLoading(false);
      if (response.success) {
        resetPopup();
        toast.success(response.message, TOAST_CONFIG);
        if (typeof (setReloadList) != 'undefined') {
          setReloadList(true);
        }
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  }

  const resetPopup = () => {
    setImage(null);
    setEditAdminPop(false)
    setGenderDrop(false);
    setAdminData(initAdmin)
  }

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: editAdminPop ? "flex" : "none" }}
        className="addResearcherPop"
      >
        <Box
          sx={{ display: editAdminPop ? "flex" : "none" }}
          className="addResearcherContaint"
        >
          <Typography className="addResearcherHeader">
            Edit Super Admin
          </Typography>
          <Typography className="addResearcherLabel">
            Researcher's Full Name
          </Typography>
          <input
            className="addResearcherInput addResearcherInputName"
            placeholder="Richard Han"
            value={adminData.name}
            onChange={setFormData}
            name="name"
          />

          <Box
            sx={{ display: "flex" }}
            className="settingBox"
          >
            <Typography className="workspaceLabel">
              Profile Picture
            </Typography>

            <Box
              className="fileInputBox upload-area"
              onClick={() => {
                fileInputRef.current.click();
              }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {image ? (
                <img className="fileinputimg" src={image} alt="Upload" />
              ) : (
                <img src={plassIcon} alt="Upload" />
              )}
              <input
                ref={fileInputRef}
                id="fileInput"
                hidden
                type="file"
                onChange={handleFileInputChange}
              />
            </Box>
            <Typography className="fileInputLabel">
              Click or drag image to this area to upload
            </Typography>
          </Box>

          <Typography className="addResearcherLabel">Email Address</Typography>
          <input
            className="addResearcherInput addResearcherInputName"
            placeholder="richard@gmail.com"
            value={adminData.email}
            onChange={setFormData}
            name="email"
          />

          <Box className="basicBox lastBasicBox">
            <Box className="inputBox">
              <Typography className="addResearcherLabel">
                Phone Number
              </Typography>
              <input
                className="addResearcherInput"
                placeholder="32158947"
                value={adminData.phone}
                onChange={setFormData}
                name="phone"
              />
            </Box>
            <Box className="inputBox">
              <Typography className="addResearcherLabel">Gender</Typography>
              <input
                onClick={() => setGenderDrop(!genderDrop)}
                value={genderDropVal}
                className="addResearcherInput genderInput"
                placeholder="Male"
              />
              <img
                style={{ rotate: genderDrop ? "180deg" : "0deg" }}
                onClick={() => setGenderDrop(!genderDrop)}
                className="downArrow"
                src={DownArrowIcon}
              />

              <Box
                sx={{ height: genderDrop ? "auto" : "0px" }}
                className="genderDrop"
              >
                <Box
                  onClick={() => {
                    setGenderDropVal("Male");
                    setGenderDrop(false);
                    setAdminData({ ...adminData, gender: "male" })
                  }}
                  className="genderDropItem"
                >
                  <Typography>Male</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setGenderDropVal("Female");
                    setGenderDrop(false);
                    setAdminData({ ...adminData, gender: "female" })
                  }}
                  className="genderDropItem"
                >
                  <Typography>Female</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setGenderDropVal("Other");
                    setGenderDrop(false);
                    setAdminData({ ...adminData, gender: "other" })
                  }}
                  className="genderDropItem"
                >
                  <Typography>Other</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Typography className="addResearcherLabel">Address</Typography>
          <input
            className="addResearcherInput addResearcherInputName"
            placeholder="12/25 New york"
            value={adminData.address_line1}
            onChange={setFormData}
            name="address_line1"
          />

          <Box className="addResearcherPupBtnBox">
            <AppBtnOutLine
              buttonText="Cancel"
              onClick={resetPopup}
            />

            <AppBtn buttonText="Update" onClick={updateAdmin} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
