import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import axios from "../../../lib/axios";

import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import FormControl from "@mui/material/FormControl";

import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { getCities, getCountries, getStates } from "../../../Services/master";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { useAppSelector } from "../../../lib/store/store";
import {
  updateCollaboratorApi,
  uploadUserDirectoryImage,
} from "../../../Services/adminActions";
import { urlToBase64 } from "../../../lib/helpers";
import { STORAGE_BASE_PATH } from "../../../env";
import plassIcon from "../../../Assets/Images/plassIcon.png";

export default function EditCollaborator({
  setEditCollaboratorPop,
  editCollaboratorPop,
  allCollaboratorData,
  setReloadList,
}) {
  const [countryData, setCountryData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [countryList, setCountryList] = useState(false);
  const [countryListVal, setCountryListVal] = useState("");
  const [stateList, setStateList] = useState(false);
  const [stateListVal, setStateListVal] = useState("");
  const [cityList, setCityList] = useState(false);
  const [cityListVal, setCityListVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [countryId, setCountryId] = useState("");
  const [stateId, setStateId] = useState("");
  const [cityId, setCityId] = useState("");
  const [collaboratorImage, setCollaboratorImage] = useState(null);
  let authState = useAppSelector((state) => state.auth);
  const [genderDrop, setGenderDrop] = useState(false);
  const [genderDropVal, setGenderDropVal] = useState("");
  const [allWorkSpaceListData, setAllWorkSpaceListData] = useState([]);

  const addWorkspaceObj = {
    workspaces: [],
  };

  const [addCollaboratorToWorkspace, setAddCollaboratorToWorkspace] = useState({
    ...addWorkspaceObj,
  });

  useEffect(() => {
    if (editCollaboratorPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [editCollaboratorPop]);

  const updateCollaborator = {
    name: "",
    email: "",
    username: "",
    phone: "",
    gender: "",
    address_line1: "",
    country: "",
    city: "",
    state: "",
    zipcode: "",
    profile_pic: "",
    status: "active",
  };
  const [editCollaboratorsApi, setEditCollaboratorsApi] = useState({
    ...updateCollaborator,
  });

  useEffect(() => {
    getAllCountryList();
    getCollaborator();
    getWorkSpaceList();
  }, [allCollaboratorData]);

  const getWorkSpaceList = async () => {
    let res = await axios.get(`/super-admin/admin/workspaces`);
    if (res.status) {
      const workspaces = res.data.data;
      const allOption = { _id: "all", name: "All" };
      const workspaceListWithAll = [allOption, ...workspaces];
      setAllWorkSpaceListData(workspaceListWithAll);
    }
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "200px",
        overflowY: "auto",
      },
    },
  };

  const handleChange = (event) => {
    const selectedValue = event.target.value === "" ? 0 : event.target.value;
    setAddCollaboratorToWorkspace((prevGroup) => ({
      ...prevGroup,
      [event.target.name]: selectedValue,
    }));
    setEditCollaboratorsApi((prevGroup) => ({
      ...prevGroup,
      [event.target.name]: selectedValue,
    }));
  };

  const getCollaborator = async () => {
    if (allCollaboratorData == "") {
      return false;
    }
    return await axios
      .get(`/super-admin/admin/collaborators?_id=${allCollaboratorData}`)
      .then((res) => {
        console.log("res", res);
        if (res.status) {
          let collabData = { ...updateCollaborator };
          let key;
          let allCollaboratorData = res?.data?.data[0];
          for (key in collabData) {
            collabData[key] = allCollaboratorData[key];
          }
          setGenderDropVal(allCollaboratorData?.gender);
          const selectedWorkspaceIds = allCollaboratorData.workspaces.map(
            (workspace) => workspace._id
          );

          setAddCollaboratorToWorkspace((prevGroup) => ({
            ...prevGroup,
            workspaces: selectedWorkspaceIds, // Update the 'workspaces' property directly
          }));

          if (
            allCollaboratorData?.profile_pic == "undefined" ||
            allCollaboratorData?.profile_pic == null
          ) {
            setCollaboratorImage(null);
          } else {
            urlToBase64(
              STORAGE_BASE_PATH + `/` + allCollaboratorData?.profile_pic,
              function (base64Img) {
                setCollaboratorImage(base64Img);
              }
            );
          }
          setEditCollaboratorsApi(collabData);
          if (allCollaboratorData?.country?.length > 0) {
            const assignedCountry = allCollaboratorData.country.map((item) => ({
              name: item.name,
              _id: item._id,
            }));
            if (assignedCountry.length > 0) {
              setCountryListVal(assignedCountry[0].name);
            }
            getAllStatesList(allCollaboratorData?.country[0]?.id);
          } else {
            setCountryListVal("");
          }
          if (allCollaboratorData?.state?.length > 0) {
            const assignedState = allCollaboratorData.state.map((item) => ({
              name: item.name,
              _id: item._id,
            }));
            if (assignedState.length > 0) {
              setStateListVal(assignedState[0].name);
            }
            getAllCitiesList(
              allCollaboratorData?.state[0]?.id,
              allCollaboratorData?.country[0]?.id
            );
          } else {
            setStateListVal("");
          }
          if (allCollaboratorData?.city?.length > 0) {
            const assignedCity = allCollaboratorData.city.map((item) => ({
              name: item.name,
              _id: item._id,
            }));
            if (assignedCity.length > 0) {
              setCityListVal(assignedCity[0].name);
            }
          } else {
            setCityListVal("");
          }
        }
      });
  };

  const setCollaboratorFormValue = (e) => {
    let newState = {
      ...editCollaboratorsApi,
      [e.target.name]: e.target.value,
    };
    setEditCollaboratorsApi(newState);

    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
  };

  const getAllCountryList = () => {
    getCountries().then((res) => {
      if (res.success && res.data.data.length > 0) {
        setCountryData(res.data.data);
      }
    });
  };

  const getAllStatesList = (country_id) => {
    getStates(country_id).then((res) => {
      if (res.success && res.data.data.length > 0) {
        setStatesData(res.data.data);
      }
    });
  };

  const getAllCitiesList = (state_id, country_id) => {
    getCities(country_id, state_id).then((res) => {
      if (res.success && res.data.data.length > 0) {
        setCitiesData(res.data.data);
      }
    });
  };

  const handleCountryChange = (e) => {
    setStateListVal("");
    setCityListVal("");
    let newState = { ...editCollaboratorsApi, country: e._id };
    setCountryId(e._id);
    setEditCollaboratorsApi(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
    getAllStatesList(e.id);
  };

  const handleStateChange = (e) => {
    let newState = { ...editCollaboratorsApi, state: e._id };
    setCityListVal("");
    setStateId(e._id);
    setEditCollaboratorsApi(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
    getAllCitiesList(e.id, e.country_id);
  };

  const handleCityChange = (e) => {
    let newState = { ...editCollaboratorsApi, city: e._id };
    setCityId(e._id);
    setEditCollaboratorsApi(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
  };

  const validationObj = {
    hasErr: false,
    name: {
      error: false,
      msg: "",
    },
    email: {
      error: false,
      msg: "",
    },
    username: {
      error: false,
      msg: "",
    },
    phone: {
      error: false,
      msg: "",
    },
    gender: {
      error: false,
      msg: "",
    },
    address_line1: {
      error: false,
      msg: "",
    },
    country: {
      error: false,
      msg: "",
    },
    state: {
      error: false,
      msg: "",
    },
    city: {
      error: false,
      msg: "",
    },
    zipcode: {
      error: false,
      msg: "",
    },
  };

  const [validationErrors, setValidationErrors] = useState({
    ...validationObj,
  });

  const handleValidation = (formInput) => {
    formInput = formInput || editCollaboratorsApi;
    let validationerr = { ...validationObj };

    if (formInput.name == "") {
      validationerr.hasErr = true;
      validationerr.name = {
        error: true,
        msg: "Name is required",
      };
    }

    if (formInput.email == "") {
      validationerr.hasErr = true;
      validationerr.email = {
        error: true,
        msg: "Email is required",
      };
    }

    if (formInput.username == "") {
      validationerr.hasErr = true;
      validationerr.username = {
        error: true,
        msg: "Username is required",
      };
    }

    if (formInput.phone == "") {
      validationerr.hasErr = true;
      validationerr.phone = {
        error: true,
        msg: "Phone is required",
      };
    }

    if (formInput.gender == "") {
      validationerr.hasErr = true;
      validationerr.gender = {
        error: true,
        msg: "Gender is required",
      };
    }

    if (formInput.address_line1 == "") {
      validationerr.hasErr = true;
      validationerr.address_line1 = {
        error: true,
        msg: "Address is required",
      };
    }

    if (formInput.country == "") {
      validationerr.hasErr = true;
      validationerr.country = {
        error: true,
        msg: "Country is required",
      };
    }

    if (formInput.state == "") {
      validationerr.hasErr = true;
      validationerr.state = {
        error: true,
        msg: "State is required",
      };
    }

    if (formInput.city == "") {
      validationerr.hasErr = true;
      validationerr.city = {
        error: true,
        msg: "City is required",
      };
    }

    if (formInput.zipcode == "") {
      validationerr.hasErr = true;
      validationerr.zipcode = {
        error: true,
        msg: "Zipcode is required",
      };
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setCollaboratorImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleCollboratorProfileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setCollaboratorImage(reader.result);
      };
      reader.readAsDataURL(file);
      updateImage(file);
    }
  };

  const updateImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      let imageUploadRes = await uploadUserDirectoryImage(
        formData,
        authState.authToken
      );
      if (imageUploadRes.success) {
        console.log("imageUploadRes?.data?.upload_id", imageUploadRes);
        setEditCollaboratorsApi({
          ...editCollaboratorsApi,
          profile_pic: imageUploadRes?.data?.upload_id,
        });
        toast.success(imageUploadRes.message, TOAST_CONFIG);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  };

  let updateData = async () => {
    if (!handleValidation()) {
      toast.error("Please correct form fields", TOAST_CONFIG);
      return false;
    }

    setLoading(true);
    try {
      let c_id = countryId;
      let st_id = stateId;
      let cty_id = cityId;
      if (c_id == "") {
        c_id = editCollaboratorsApi.country[0]._id;
      }
      if (st_id == "") {
        st_id = editCollaboratorsApi.state[0]._id;
      }
      if (cty_id == "") {
        cty_id = editCollaboratorsApi.city[0]._id;
      }
      let requestPayload = {
        name: editCollaboratorsApi.name,
        email: editCollaboratorsApi.email,
        username: editCollaboratorsApi.username,
        phone: editCollaboratorsApi.phone,
        gender: editCollaboratorsApi.gender,
        address_line1: editCollaboratorsApi.address_line1,
        country: c_id,
        state: st_id,
        city: cty_id,
        zipcode: editCollaboratorsApi.zipcode,
        profile_pic: editCollaboratorsApi.profile_pic,
        status: "active",
        workspaces: editCollaboratorsApi.workspaces,
      };
      let collaboratorRes = await updateCollaboratorApi(
        allCollaboratorData,
        requestPayload,
        authState.authToken
      );
      setLoading(false);
      if (collaboratorRes.success) {
        toast.success(collaboratorRes.message, TOAST_CONFIG);
        setEditCollaboratorsApi({ ...updateCollaborator });
        setEditCollaboratorPop(false);
        setReloadList(true);
      } else {
        toast.error(collaboratorRes.message, TOAST_CONFIG);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setEditCollaboratorPop(false);
    setReloadList(true);
  };

  return (
    <>
      <Box
        id="popBackdrop"
        // onClick={handelPopClose}
        sx={{ display: editCollaboratorPop ? "flex" : "none" }}
        className="addResearcherPop"
      >
        <Box
          sx={{ display: editCollaboratorPop ? "flex" : "none" }}
          className="addResearcherContaint"
        >
          <Typography className="addResearcherHeader">
            Edit Collaborator
          </Typography>

          {/* =================WorkspaceDropDown============== */}

          <FormControl sx={{ width: "100%" }} size="medium">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={addCollaboratorToWorkspace?.workspaces}
              onChange={handleChange}
              name="workspaces"
              multiple
              displayEmpty
              MenuProps={MenuProps}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <p>Select Workspaces</p>;
                }
                return selected
                  .map((value) => {
                    const workspace = allWorkSpaceListData.find(
                      (workspace) => workspace._id === value
                    );
                    return workspace ? workspace.name : value;
                  })
                  .join(", ");
              }}
            >
              {allWorkSpaceListData.map((workspace, index) => (
                <MenuItem value={workspace._id} key={index}>
                  {workspace.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* =================WorkspaceDropDown============== */}

          {/* <Typography className="addResearcherLabel">
            Researcher's Full Name
          </Typography>
          <input
            className="addResearcherInput addResearcherInputName"
            placeholder="Enter the full name of the Researcher"
          /> */}
          <Typography className="addResearcherLabel">
            Collaborator's Full Name
          </Typography>
          <input
            name="name"
            value={editCollaboratorsApi?.name}
            onChange={setCollaboratorFormValue}
            className="addResearcherInput addResearcherInputName"
            placeholder="Enter the full name of the Researcher"
          />
          <span className="validationerr inputerr">
            {validationErrors.name.msg}
          </span>
          <Box className="settingBox">
            <Typography className="workspaceLabel">
              Collaborator Profile image
            </Typography>

            <Box
              className="fileInputBox upload-area"
              onClick={() => {
                document.getElementById("fileInputCollaborator").click();
              }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {collaboratorImage ? (
                <img
                  className="fileinputimg"
                  src={collaboratorImage}
                  alt="Upload"
                />
              ) : (
                <img src={plassIcon} alt="Upload" />
              )}
              <input
                id="fileInputCollaborator"
                style={{ display: "none" }}
                type="file"
                onChange={handleCollboratorProfileChange}
              />
            </Box>
            <Typography className="fileInputLabel">
              Click or drag image to this area to upload
            </Typography>
          </Box>

          <Box className="basicBox lastBasicBox">
            <Box className="inputBox">
              <Typography className="addResearcherLabel">
                Email Address
              </Typography>
              <input
                name="email"
                value={editCollaboratorsApi?.email}
                onChange={setCollaboratorFormValue}
                className="addResearcherInput addResearcherInputName"
                placeholder="Enter Collaborator's valid Email Address"
              />
              <span className="validationerr inputerr">
                {validationErrors.email.msg}
              </span>
            </Box>
            <Box className="inputBox">
              <Typography className="addResearcherLabel">Username</Typography>
              <input
                name="username"
                value={editCollaboratorsApi?.username}
                onChange={setCollaboratorFormValue}
                className="addResearcherInput addResearcherInputName"
                placeholder="Enter Username"
              />
              <span className="validationerr inputerr">
                {validationErrors.username.msg}
              </span>
            </Box>
          </Box>

          <Box className="basicBox lastBasicBox">
            <Box className="inputBox">
              <Typography className="addResearcherLabel">
                Phone Number
              </Typography>
              <input
                name="phone"
                value={editCollaboratorsApi?.phone}
                onChange={setCollaboratorFormValue}
                className="addResearcherInput"
                placeholder="Enter Collaborator's valid Phone Number"
              />
              <span className="validationerr">
                {validationErrors.phone.msg}
              </span>
            </Box>
            <Box className="inputBox">
              <Typography className="addResearcherLabel">Gender</Typography>
              <input
                onClick={() => setGenderDrop(!genderDrop)}
                name="gender"
                value={editCollaboratorsApi?.gender}
                onChange={setCollaboratorFormValue}
                // value={genderDropVal}
                className="addResearcherInput genderInput"
                placeholder="Select Collaborator Gender"
              />
              <img
                style={{ rotate: genderDrop ? "180deg" : "0deg" }}
                onClick={() => setGenderDrop(!genderDrop)}
                className="downArrow"
                src={DownArrowIcon}
              />

              <Box
                sx={{ height: genderDrop ? "auto" : "0px" }}
                className="genderDrop"
              >
                <Box
                  onClick={() => {
                    setEditCollaboratorsApi({
                      ...editCollaboratorsApi,
                      gender: "male",
                    });
                    setGenderDropVal("Male");
                    setGenderDrop(false);
                  }}
                  className="genderDropItem"
                >
                  <Typography>Male</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setEditCollaboratorsApi({
                      ...editCollaboratorsApi,
                      gender: "female",
                    });
                    setGenderDropVal("Female");
                    setGenderDrop(false);
                  }}
                  className="genderDropItem"
                >
                  <Typography>Female</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setEditCollaboratorsApi({
                      ...editCollaboratorsApi,
                      gender: "others",
                    });
                    setGenderDropVal("Other");
                    setGenderDrop(false);
                  }}
                  className="genderDropItem"
                >
                  <Typography>Other</Typography>
                </Box>
              </Box>
              <span className="validationerr">
                {validationErrors.gender.msg}
              </span>
            </Box>
          </Box>

          <Typography className="addResearcherLabel">Address</Typography>
          <input
            name="address_line1"
            value={editCollaboratorsApi?.address_line1}
            onChange={setCollaboratorFormValue}
            className="addResearcherInput addResearcherInputName"
            placeholder="Enter Collaborator's valid Address"
          />
          <span className="validationerr inputerr">
            {validationErrors.address_line1.msg}
          </span>
          <Box className="basicBox lastBasicBox">
            <Box className="inputBoxOne">
              <Typography className="companyInputLabel">Country</Typography>
              <input
                onClick={(e) => setCountryList(!countryList)}
                value={countryListVal}
                className="workspaceInputTag categoryInputTag"
                placeholder="Choose a Category"
              />
              <img
                style={{ rotate: countryList ? "180deg" : "0deg" }}
                onClick={() => setCountryList(!countryList)}
                className="downArrowPop"
                src={DownArrowIcon}
              />

              <Box
                sx={{
                  maxHeight: countryList ? "300px" : "0px",
                  overflowY: countryList ? "scroll" : "hidden",
                }}
                className="categoryDropDown"
              >
                {countryData.map((data) => (
                  <Box
                    key={data.id}
                    value={data.id}
                    className="categoryDropDownItem"
                    onClick={() => {
                      handleCountryChange(data);
                      setCountryList(false);
                      setCountryListVal(data.name);
                    }}
                  >
                    <Typography>{data.name}</Typography>
                  </Box>
                ))}
              </Box>
              <span className="validationerr">
                {validationErrors.country.msg}
              </span>
            </Box>
            <Box className="inputBoxTwo">
              <Typography className="companyInputLabel">State</Typography>
              <input
                onClick={() => setStateList(!stateList)}
                value={stateListVal}
                className="workspaceInputTag categoryInputTag"
                placeholder="Choose a Category"
              />
              <img
                style={{ rotate: stateList ? "180deg" : "0deg" }}
                onClick={() => setStateList(!stateList)}
                className="downArrowPop"
                src={DownArrowIcon}
              />

              <Box
                sx={{
                  maxHeight: stateList ? "300px" : "0px",
                  overflowY: stateList ? "scroll" : "hidden",
                }}
                className="categoryDropDown"
              >
                {statesData.map((data) => (
                  <Box
                    key={data.id}
                    value={data.id}
                    className="categoryDropDownItem"
                    onClick={() => {
                      handleStateChange(data, data.country_id);
                      setStateList(false);
                      setStateListVal(data.name);
                    }}
                  >
                    <Typography>{data.name}</Typography>
                  </Box>
                ))}
              </Box>
              <span className="validationerr">
                {validationErrors.state.msg}
              </span>
            </Box>
          </Box>
          <Box className="basicBox lastBasicBox">
            <Box className="inputBoxOne">
              <Typography className="companyInputLabel">City</Typography>
              <input
                onClick={() => setCityList(!cityList)}
                // value={createCompany.city_name}
                value={cityListVal}
                className="workspaceInputTag categoryInputTag"
                placeholder="Choose a Category"
              />
              <img
                style={{ rotate: cityList ? "180deg" : "0deg" }}
                onClick={() => setCityList(!cityList)}
                className="downArrowPop"
                src={DownArrowIcon}
              />

              <Box
                sx={{
                  maxHeight: cityList ? "300px" : "0px",
                  overflowY: cityList ? "scroll" : "hidden",
                }}
                className="categoryDropDown"
              >
                {citiesData.map((data) => (
                  <Box
                    key={data.id}
                    value={data.id}
                    className="categoryDropDownItem"
                    onClick={() => {
                      handleCityChange(data);
                      setCityList(false);
                      setCityListVal(data.name);
                    }}
                  >
                    <Typography>{data.name}</Typography>
                  </Box>
                ))}
              </Box>
              <span className="validationerr">{validationErrors.city.msg}</span>
            </Box>
            <Box className="inputBox">
              <Typography className="addResearcherLabel">ZIP Code</Typography>
              <input
                className="addResearcherInput"
                name="zipcode"
                value={editCollaboratorsApi?.zipcode}
                onChange={setCollaboratorFormValue}
                placeholder="Enter Zip code"
              />
              <span className="validationerr">
                {validationErrors.zipcode.msg}
              </span>
            </Box>
          </Box>
          {/* <Box className="basicBox lastBasicBox">
            <Box className="inputBox">
              <Typography className="addResearcherLabel">City</Typography>
              <input className="addResearcherInput" placeholder="" />
            </Box>
            <Box className="inputBox">
              <Typography className="addResearcherLabel">State</Typography>
              <input className="addResearcherInput" placeholder="" />
            </Box>
          </Box>
          <Box className="basicBox lastBasicBox">
            <Box className="inputBox">
              <Typography className="addResearcherLabel">Country</Typography>
              <input className="addResearcherInput" placeholder="" />
            </Box>
            <Box className="inputBox">
              <Typography className="addResearcherLabel">ZIP Code</Typography>
              <input className="addResearcherInput" placeholder="" />
            </Box>
          </Box> */}

          <Box className="addResearcherPupBtnBox">
            <AppBtnOutLine
              buttonText="Cancel"
              onClick={() => {
                handleCancel();
              }}
            />
            <AppBtn buttonText="Update" onClick={() => updateData()} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
