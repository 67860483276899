import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//images
import publicationImg from "../../../Assets/Images/publicationImg.png";
//components
import AddResearchPublication3 from "../AddResearchPublication3";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
export default function AddResearchPublication2({
    addResearchPublication2,
    setAddResearchPublication2,
    setAddResearchPublication,
}) {
  const Navigate = useNavigate();
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const [selectedPublication, setSelectedPublication] = useState();
  const [addResearchPublication3, setAddResearchPublication3] = useState(false);

  const PublicationCard = ({ icon, title, subText, type }) => {
    return (
      <Box
        onClick={() => {setSelectedPublication(title); }}
        className={
            selectedPublication === title
            ? "ActivityTypeItem ActivityTypeItemClicked"
            : "ActivityTypeItem"
        }
      >
        <Typography>{title}</Typography>
        <Box className="acTyTitleBox">
            <img style={{width:"210px",height:"70px",borderRadius:"4px"}} src={icon} />
        </Box>
        <Typography style={{fontSize:"5px"}}>Lorem ipsum dolor sit amet consectetur. Suspendisse tincidunt arcu tellus non fringilla nulla dolor. Adipiscing id feugiat odio velit placerat neque. Purus aliquam quam diam hendrerit lobortis quam et. Eget sed velit id lectus. Adipiscing sapien amet in suspendisse id arcu in purus nunc. Ac risus pretium arcu nibh ipsum dui dignissim arcu eu. Libero amet risus id a nibh.</Typography>
        <Typography style={{fontSize:"5px"}}>Lorem ipsum dolor sit amet consectetur. Suspendisse tincidunt arcu tellus non fringilla nulla dolor. Adipiscing id feugiat odio velit placerat neque. Purus aliquam quam diam hendrerit lobortis quam et. Eget sed velit id lectus. Adipiscing sapien amet in suspendisse id arcu in purus nunc. Ac risus pretium arcu nibh ipsum dui dignissim arcu eu.</Typography>
        <Typography style={{fontSize:"5px"}}>Lorem ipsum dolor sit amet consectetur. Suspendisse tincidunt arcu tellus non fringilla nulla dolor. Adipiscing id feugiat odio velit placerat neque. Purus aliquam quam diam hendrerit lobortis quam et. Eget sed velit id lectus. Adipiscing sapien amet in suspendisse id arcu in purus nunc. Ac risus pretium arcu nibh ipsum dui dignissim arcu eu. Libero amet risus id a nibh.</Typography>
      </Box>
    );
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
        setAddResearchPublication2(false);
    }
  };

  useEffect(() => {
    if (addResearchPublication2) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [addResearchPublication2]);

  const handelPublicationBack = () => {
    setAddResearchPublication(true);
    setAddResearchPublication2(false);
  }

  const handelPublicationNext = () => {
    setAddResearchPublication2(false);
    setAddResearchPublication3(true);
  }

  return (
    <>
    <AddResearchPublication3 
        addResearchPublication3={addResearchPublication3} 
        setAddResearchPublication3={setAddResearchPublication3}
    />
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: addResearchPublication2 ? "flex" : "none" }}
        className="companyPop addActivitiesPop"
      >
        <Box
          sx={{ display: addResearchPublication2 ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Box>
            <Typography className="taskPopMainLabel">
            Create New Publication
            </Typography>

            <Typography mb={2} className="basicDetailSteps">Publication Templates (Step 2 of 3)</Typography>
            <Typography>Choose a template to format your publication. You can always edit the structure of the template selected accordingly. </Typography>
            <Box className="ActivityTypeBox">
              {PublicationCard({
                icon: publicationImg,
                title: "PAPER TITLE LOREM IPSUM",
                subText:
                  "Capture open-ended text responses with optional word limit, formatting and images.",
                type:1,
              })}
              {PublicationCard({
                icon: publicationImg,
                title: "PAPER TITLE LOREM IPSUM 1",
                subText:
                  "Get feedback about an image with mark-up, comments and annotation on various locations.",
                  type:2,
              })}
              {PublicationCard({
                icon: publicationImg,
                title: "PAPER TITLE LOREM IPSUM 2",
                subText:
                  "Get participants to upload a video with open-ended responses and optional captions, emotions and comments.",
                  type:3,
              })}
              {PublicationCard({
                icon: publicationImg,
                title: "PAPER TITLE LOREM IPSUM 3",
                subText:
                  "Get participants to upload an audio with open-ended responses and optional captions, emotions and comments.",
                  type:4,
              })}
              
              
             
            </Box>
            <Box className="workPupBtnBox">
              <AppBtnOutLine
                buttonText={t("commonTxt.cancelBtn")}
                onClick={() => handelPublicationBack()}
              />
              <AppBtn
                buttonText={t("commonTxt.continueBtn")} onClick={() => handelPublicationNext()}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
