import React, { useState } from "react";
import "./style.css";

import { Box, Typography } from "@mui/material";
import NoNotifcationBg from "../../../Assets/Images/No data-cuate 1.png";

import { AppBtn } from "../../AppTools/AppButton";

const NoNotificationBg = ({ setFilterState, defaultFilters }) => {
  return (
    <>
      <Box className="noNotificationImg">
        <Box className="adminWorkSBG">
          <img src={NoNotifcationBg} />
        </Box>
        <Box className="adminBGTextBox">
          <Typography className="adminHeaderText">
            No Notifications Yet
          </Typography>
          <Typography className="adminSubHeaderText">
            Please click on the refresh button to fetch notifications
          </Typography>
          <AppBtn
            buttonText="Refresh"
            onClick={() => setFilterState(defaultFilters)}
          />
        </Box>
      </Box>
    </>
  );
};

export default NoNotificationBg;
