import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import SideMenu from "../../../Components/Collaborator/SideMenu";
import { SearchBar } from "../../../Components/AppTools/AppTool";

import {
  AppBtnOutLine,
  AppAddBtn,
} from "../../../Components/AppTools/AppButton";

import { sideHaumIcon, groupBtnTab } from "../../../Assets/Data";

import backArrow from "../../../Assets/Images/noun-back.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";

import ParticipantScreeningQuestion from "../ParticipantScreeningQuestion";
import ParticipantScreeningScreened from "../ParticipantScreeningScreened";
import ParticipantScreeningQuota from "../ParticipantScreeningQuota";
import ParticipantScreeningNotices from "../ParticipantScreeningNotices";

export default function ProjectParticipantScreening({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
}) {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const Navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [settingOptDrop, setSettingOptDrop] = useState(false);
  const [settingOptDropVal, setSettingOptDropVal] = useState("Questions");

  const [participantTypeDrop, setParticipantTypeDrop] = useState(false);
  const [participantTypeDropVal, setParticipantTypeDropVal] = useState();

  const previousPageHandler = () => {
    Navigate("/collaborator/project/directory_participants");
  };

  useEffect(() => {
    let selTab = searchParams.get("t");
    if (selTab != null) {
      let query_string = selTab;
      query_string = query_string.replaceAll("_", " ");
      query_string = query_string.replace(/(^\w|\s\w)/g, (firstCharOfWord) =>
        firstCharOfWord.toUpperCase()
      );
      console.log(query_string);
      setSettingOptDropVal(query_string);
    }
  }, []);

  return (
    <>
      {/* Setting Options */}

      <Box className="ResearchersContainer">
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
          </Box>
          <Box className="researchersMainBox pageContainer">
            
              <Box className="ParticiProfiling workspaceListBox">
                <Box
                  onClick={() => previousPageHandler()}
                  className="openBtn"
                  style={{ "margin-bottom": "10px" }}
                >
                  <img src={backArrow} />
                  <Typography className="neonBackText">
                    {t("commonTxt.backBtn")}
                  </Typography>
                </Box>
                <Typography className="allPartRedioLabel">
                  {t("participantScreening.headerTxt")}
                </Typography>

                {/* Drop-down */}

                {settingOptDropVal !== "Screened" ? (
                  <Box className="projectSearchGroup btnRow">
                    <Box
                      sx={{ width: "23%" }}
                      className="inputAppDrop settingOpt"
                      onClick={() => setSettingOptDrop(!settingOptDrop)}
                    >
                      <Typography>
                        {settingOptDropVal ||
                          t("participantScreening.settingOptDropPlaceholder")}
                      </Typography>
                      <img
                        style={{
                          rotate: settingOptDrop ? "180deg" : "0deg",
                        }}
                        src={dropArrow}
                      />
                      {/* pop */}
                      <Box
                        sx={{
                          height: settingOptDrop ? "auto" : "0px",
                        }}
                        className="statusPop"
                      >
                        <Box
                          onClick={() => {
                            setSearchParams({ t: "questions" });
                            setSettingOptDropVal("Questions");
                          }}
                          className="statusPopItem"
                        >
                          <Typography>
                            {t("participantScreening.setSettingOptDropType1")}
                          </Typography>
                        </Box>
                        <Box
                          onClick={() => {
                            setSearchParams({ t: "quota_limit" });
                            setSettingOptDropVal("Quota Limit");
                          }}
                          className="statusPopItem"
                        >
                          <Typography>
                            {t("participantScreening.setSettingOptDropType2")}
                          </Typography>
                        </Box>
                        <Box
                          onClick={() => {
                            setSearchParams({ t: "notices" });
                            setSettingOptDropVal("Notices");
                          }}
                          className="statusPopItem"
                        >
                          <Typography>
                            {t("participantScreening.setSettingOptDropType3")}
                          </Typography>
                        </Box>
                        <Box
                          onClick={() => {
                            setSearchParams({ t: "screened" });
                            setSettingOptDropVal("Screened");
                          }}
                          className="statusPopItem"
                        >
                          <Typography>
                            {t("participantScreening.setSettingOptDropType4")}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box>
                      <AppBtnOutLine buttonText={t("commonTxt.manageParticipant")} />
                      <AppAddBtn buttonText={t("observer.Preview")} />
                    </Box>
                  </Box>
                ) : (
                  <Box className="projectSearchGroup">
                    <Box
                      sx={{ width: "23%" }}
                      className="inputAppDrop settingOpt"
                      onClick={() => setSettingOptDrop(!settingOptDrop)}
                    >
                      <Typography>
                        {settingOptDropVal ||
                          t("participantScreening.settingOptDropPlaceholder")}
                      </Typography>
                      <img
                        style={{
                          rotate: settingOptDrop ? "180deg" : "0deg",
                        }}
                        src={dropArrow}
                      />
                      {/* pop */}
                      <Box
                        sx={{
                          height: settingOptDrop ? "auto" : "0px",
                        }}
                        className="statusPop"
                      >
                        <Box
                          onClick={() => {
                            setSearchParams({ t: "questions" });
                            setSettingOptDropVal("Questions");
                          }}
                          className="statusPopItem"
                        >
                          <Typography>
                            {t("participantScreening.setSettingOptDropType1")}
                          </Typography>
                        </Box>
                        <Box
                          onClick={() => {
                            setSearchParams({ t: "quota_limit" });
                            setSettingOptDropVal("Quota Limit");
                          }}
                          className="statusPopItem"
                        >
                          <Typography>
                            {t("participantScreening.setSettingOptDropType2")}
                          </Typography>
                        </Box>
                        <Box
                          onClick={() => {
                            setSearchParams({ t: "notices" });
                            setSettingOptDropVal("Notices");
                          }}
                          className="statusPopItem"
                        >
                          <Typography>
                            {t("participantScreening.setSettingOptDropType3")}
                          </Typography>
                        </Box>
                        <Box
                          onClick={() => {
                            setSearchParams({ t: "screened" });
                            setSettingOptDropVal("Screened");
                          }}
                          className="statusPopItem"
                        >
                          <Typography>
                            {t("participantScreening.setSettingOptDropType4")}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="inputBoxWlabel">
                      <SearchBar />
                    </Box>
                    <Box
                      className="inputAppDrop"
                      onClick={() =>
                        setParticipantTypeDrop(!participantTypeDrop)
                      }
                    >
                      <Typography>
                        {participantTypeDropVal || "Select Type"}
                      </Typography>
                      <img
                        style={{
                          rotate: participantTypeDrop ? "180deg" : "0deg",
                        }}
                        src={dropArrow}
                      />
                      {/* pop */}
                      <Box
                        sx={{
                          height: participantTypeDrop ? "auto" : "0px",
                        }}
                        className="statusPop"
                      >
                        <Box
                          onClick={() =>
                            setParticipantTypeDropVal("Recently Online")
                          }
                          className="statusPopItem"
                        >
                          <Typography>Recently Online</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ width: "13%" }} className="groupBtnTab">
                      {groupBtnTab}
                      <Typography>{t("commonTxt.groupBtn")}</Typography>
                    </Box>
                  </Box>
                )}

                {/* Other Filters */}

                {/* Other Filters */}

                {/* Drop-Down ends */}

                {settingOptDropVal === "Questions" ? (
                  <ParticipantScreeningQuestion />
                ) : settingOptDropVal === "Quota Limit" ? (
                  <ParticipantScreeningQuota />
                ) : settingOptDropVal === "Notices" ? (
                  <ParticipantScreeningNotices />
                ) : settingOptDropVal === "Screened" ? (
                  <ParticipantScreeningScreened />
                ) : null}
              </Box>
            
          </Box>
        </Box>
      </Box>

      {/* Setting Options */}
    </>
  );
}
