import { Bounce } from "react-toastify";

export const PER_PAGE_LIMIT = 10;

export const TOAST_CONFIG = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  transition: Bounce,
};

export const USER_TYPE_SUPERADMIN = "superadmin";
export const USER_TYPE_WS_ADMIN = "workspaceadmin";
export const USER_TYPE_RESEARCHER = "researcher";
export const USER_TYPE_COLLABORATOR = "collaborator";
export const USER_TYPE_OBSERVER = "observer";
export const USER_TYPE_PARTICIPANT = "participant";

export const HELP_URL = "https://www.help.terapage.ai";
