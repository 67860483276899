import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "../../../lib/axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { getConfigSettings } from "../../../Services/master";

export default function ContactSupport() {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const [ticketContact, setTicketContact] = useState({
    contact_url: "",
    ticket_url: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await getConfigSettings({ name: "contact_tickets" });
      if (response.success) {
        if (response.data.data.length > 0) {
          const ticketContactSettings = response.data.data[0];
          console.log("fileSettings", ticketContactSettings?.value)
          let sateData = {
            contact_link: ticketContactSettings?.value?.contact_link,
            ticket_link: ticketContactSettings?.value?.ticket_link
          }

          setTicketContact(sateData);
          console.log(sateData)
        }
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  }

  const openLink = (url) =>{
    window.open(url, "_blank");
  }

  return (
    <>
      <Box className="EmailNContainer">
        <Typography className="emailNotificationHeaderText">
          {t("contactSupportPage.clickLink")}
        </Typography>
        <Typography className="contactSubHeadText">
          {t("contactSupportPage.contactLink")}
        </Typography>
        <Box my={3} className="iBox">
          <Typography className="supportLinks" onClick={()=>openLink(ticketContact.contact_link)}>
           {ticketContact.contact_link}
          </Typography>
        </Box>
        <Typography className="contactSubHeadText">
          {t("contactSupportPage.raiseTicket")}
        </Typography>
        <Box my={3} className="iBox">
          <Typography className="supportLinks" onClick={()=>openLink(ticketContact.ticket_link)}>
            {ticketContact.ticket_link}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
