import { API_BASE_URL } from "../env";
import axios from "axios";
import { store } from "./store/store";
import { setAuthState } from "./store/features/authSlice";

export const getToken = () => "Bearer " + store.getState().auth.authToken;

// Set config defaults when creating the instance
const instance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Accept: "application/json",
  },
});

instance.interceptors.request.use(
  (request) => {
    const token = getToken();
    request.headers.Authorization = token;
    // console.log(request);
    // Edit request config
    return request;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    // console.log(response);
    // Edit response config
    return response.data;
  },
  (error) => {
    console.log(error);
    if (error?.response?.status == 401) {
      store.dispatch(setAuthState(""));
    }
    return {
      success: false,
      message: error?.response?.data?.message,
      error: error,
      err_res: error,
    };
  }
);

export default instance;
