import React, { useState, useEffect } from "react";
import { Box, Typography, FormControl } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./style.css";

//images
import OptionLine from "../../../Assets/Images/Option2LIcon.png";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png";
import groupsIcon from "../../../Assets/Images/groupsIcon.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";

import { archive, duplicateIcon, deletSvg } from "../../../Assets/Data";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

//component
import DeletePagePop from "../DeletePagePop";
import DuplicatePagePop from "../DuplicatePagePop";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { BigDrop } from "../../AppTools/AppTool";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../env";
import { useAppSelector } from "../../../lib/store/store";
import { API_BASE_URL, STORAGE_BASE_PATH } from "../../../env";
import axios from "../../../lib/axios";

export default function PageSettingPop({
  allDropVal3,
  setAllDropVal3,
  setDuplicatePagePop,
  setDeletePagePop,
  deletePagePop,
  duplicatePagePop,
}) {
  const authState = useAppSelector((state) => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace);
  const [nav, setNav] = useState(0);
  const [openTab, setOpenTab] = useState();
  const [image, setImage] = useState(null);
  const [Drop, setDrop] = useState(false);
  const [DropVal, setDropVal] = useState("Select Participant Groups");

  const [Drop3, setDrop3] = useState(false);
  const [DropVal3, setDropVal3] = useState("Select Participant Groups");

  const [Drop4, setDrop4] = useState(false);
  const [DropVal4, setDropVal4] = useState("All Administrators");

  const [groupDrop2, setGroupDrop2] = useState(false);

  const [option, setOption] = useState(false);
  const [optionVal, setOptionVal] = useState();

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const optionAry = ["Duplicate", "Archive", "Delete"];
  const initialState = {
    title: "",
    image: null,
    workspace_id: workspaceState?.selectedWorkspace?._id,
    project_id: workspaceState?.selectedProject?._id,
    participant_access: "",
    participant_groups: [],
    participant_ids: [],
    administrator_access: "",
    administrator_roles: [],
    administrator_ids: [],
    status: "",
  };
  const [selectedAdminRoles, setSelectedAdminRoles] = useState([]);
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const [selectedParticipantGroups, setSelectedParticipantGroups] = useState(
    []
  );
  const [groupsListData, setGroupsListData] = useState([]);
  const [adminsList, setAdminsList] = useState([]);
  const [pageListData, setPageListData] = useState([]);
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [pageUpdateDats, setPageUpdateDats] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [deletePageId, setDeletePageId] = useState("");
  const [deletePageName, setDeletePageName] = useState("");
  const [duplicatePageData, setDuplicatePageData] = useState([]);
  const [draggingIndex, setDraggingIndex] = useState(null);
  const [dragSequence, setDragSequence] = useState(null);
  const [archivedPages, setArchivedPages] = useState([]);
  const [activePages, setActivePages] = useState([]);
  const [statusDropVal, setStatusDropVal] = useState("");

  const [selectedParticipantGroups2, setSelectedParticipantGroups2] = useState(
    []
  );
  const [selectedAdminRoles2, setSelectedAdminRoles2] = useState([]);
  const [updatedSequenceState, setUpdatedSequenceState] = useState("");

  const ParticipantItem = [
    "All Participants",
    "Select Participant Groups",
    "None",
  ];

  const ParticipantItem2 = [
    {
      name: "All Participants",
      value: "all",
    },
    {
      name: "Select Participant Groups",
      value: "selected_participants",
    },
    {
      name: "None",
      value: "none",
    },
  ];

  const AdministratorItem = [
    "All Administrators",
    "Select Admin Roles",
    "Select Admins",
  ];

  const AdministratorItem2 = [
    {
      name: "All Administrators",
      value: "all",
    },
    {
      name: "Select Admin Roles",
      value: "selected_roles",
    },
    {
      name: "Select Admins",
      value: "selected_admins",
    },
  ];

  const administratorRoles = [
    { name: "Researchers", value: "researcher" },
    { name: "Collaborators", value: "collaborator" },
    { name: "Observer", value: "observer" },
  ];

  const handelDrop = (drop) => {
    if (openTab === drop) {
      setOpenTab(null);
      return;
    }
    setOpenTab(drop);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        // Validate file type
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result);
        };
        reader.onerror = () => {
          console.error("Error reading file");
        };
        reader.readAsDataURL(file);
      } else {
        console.error("Invalid file type");
      }
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    getPagesLists();
    getGroupsLists();
    getResearcher();
    getCollaborator();
    getObserver();
    setSelectedProjectName(workspaceState?.selectedProject?.name);
  }, []);

  const getPagesLists = async () => {
    try {
      const res = await axios.get(
        API_BASE_URL +
          `/researchers/pages?workspace_id=${workspaceState?.selectedWorkspace?._id}`
      );
      if (res.status) {
        let initPages = { ...initialState };
        const pages = res.data?.data;

        setPageListData(pages);
        console.log("Page List Data=>", pageListData);
      }
    } catch (error) {
      console.error("Error fetching pages lists", error);
    }
  };

  useEffect(() => {
    const filteredArchivedPages = pageListData.filter(
      (page) => page.status === "archive"
    );
    setArchivedPages(filteredArchivedPages);

    const filteredActivePages = pageListData.filter(
      (page) => page.status === "active"
    );
    setActivePages(filteredActivePages);
  }, [pageListData]);

  const getGroupsLists = async () => {
    try {
      const res = await axios.get(API_BASE_URL + "/participant/groups");
      if (res.status) {
        const group = res.data?.data?.filter((grp) => grp.is_enhanced == false);
        setGroupsListData(group);
      }
    } catch (error) {
      console.error("Error fetching project lists", error);
    }
  };

  const getResearcher = async () => {
    try {
      const response = await axios.get(
        `/super-admin/admin/researchers?workspace=${workspaceState?.selectedWorkspace?._id}`
      );
      setAdminsList((prev) => {
        const prevData = prev || [];
        const newData = response?.data?.data || [];
        const existingIds = new Set(prevData.map((item) => item._id));
        const mergedData = [
          ...prevData,
          ...newData.filter((item) => !existingIds.has(item._id)),
        ];
        return mergedData;
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getCollaborator = async () => {
    try {
      const response = await axios.get(
        `/super-admin/admin/collaborators?workspace=${workspaceState?.selectedWorkspace?._id}`
      );
      setAdminsList((prev) => {
        const prevData = prev || [];
        const newData = response?.data?.data || [];
        const existingIds = new Set(prevData.map((item) => item._id));
        const mergedData = [
          ...prevData,
          ...newData.filter((item) => !existingIds.has(item._id)),
        ];
        return mergedData;
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getObserver = async () => {
    try {
      const response = await axios.get(
        `/super-admin/admin/observers?workspace=${workspaceState?.selectedWorkspace?._id}`
      );
      setAdminsList((prev) => {
        const prevData = prev || [];
        const newData = response?.data?.data || [];
        const existingIds = new Set(prevData.map((item) => item._id));
        const mergedData = [
          ...prevData,
          ...newData.filter((item) => !existingIds.has(item._id)),
        ];
        return mergedData;
      });
    } catch (err) {
      console.log(err);
    }
  };

  const chackBoxRoom = (
    groupId,
    groupName,
    selectedParticipantGroupsVal,
    pageId
  ) => {
    //console.log("selected Participant group1=>",selectedParticipantGroupsVal (pageData?.participant_groups));
    return (
      <Box className="checkBoxItem" key={`group-${groupId}`}>
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            name={`group-${groupId}`}
            value={groupId}
            defaultChecked={selectedParticipantGroupsVal.includes(groupId)}
            // onChange={handleParticipantGroupsChange}
            onChange={(event) =>
              handleParticipantGroupsChange(
                event,
                selectedParticipantGroupsVal,
                pageId
              )
            }
          />
          <Typography>{groupName}</Typography>
        </Box>
      </Box>
    );
  };

  const AdminRolesCheckBox = (val, text, adminSelectedRole, pageId) => {
    return (
      <Box className="checkBoxItem" key={`adminrole-${val}`}>
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            name={`adminrole-${val}`}
            value={val}
            defaultChecked={adminSelectedRole.includes(val)}
            onChange={handleAdminRolesChange}
          />
          <Typography>{text}</Typography>
        </Box>
      </Box>
    );
  };

  const AdminsCheckBox = (val, text) => {
    return (
      <Box className="checkBoxItem" key={`admin-${val}`}>
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            name={`admin-${val}`}
            value={val}
            checked={selectedAdmins.includes(val)}
            onChange={handleAdminsChange}
          />
          <Typography>{text}</Typography>
        </Box>
      </Box>
    );
  };

  const handleAdminRolesChange = (event, pageId) => {
    const { value, checked } = event.target;

    setPageListData((prevPageListData) =>
      prevPageListData.map((page) =>
        page._id === pageId
          ? {
              ...page,
              administrator_roles: checked
                ? [...page.administrator_roles, value]
                : page.administrator_roles.filter((role) => role !== value),
            }
          : page
      )
    );
  };

  const handleAdminsChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedAdmins([...selectedAdmins, value]);
    } else {
      setSelectedAdmins(selectedAdmins.filter((admin) => admin !== value));
    }
  };

  const handleParticipantGroupsChange = (
    event,
    selectedParticipantGroupsVal,
    pageId
  ) => {
    const { value, checked } = event.target;

    setSelectedParticipantGroups((prevSelected) => {
      const updatedGroups = checked
        ? [...prevSelected, value]
        : prevSelected.filter((participant) => participant !== value);

      // Update the corresponding page's participant_groups in pageListData
      setPageListData((prevPageListData) =>
        prevPageListData.map((page) =>
          page._id === pageId
            ? { ...page, participant_groups: updatedGroups }
            : page
        )
      );

      return updatedGroups;
    });
  };

  const handleParticipantAccessChange = (e, pageId) => {
    const { name, value } = e.target;

    const selectedValue = e.target.value;
    console.log("Selected Value:", selectedValue);
    console.log("Name Attribute:", e.target.name);
    setPageListData((prevPageListData) =>
      prevPageListData.map((page) =>
        page._id === pageId ? { ...page, [name]: selectedValue } : page
      )
    );
  };

  const handleAdminAccessChange = (e, pageId) => {
    const { name, value } = e.target;

    setLoading(false);
    const selectedValue2 = e.target.value === "" ? "" : e.target.value;
    setPageListData((prevPageListData) =>
      prevPageListData.map((page) =>
        page._id === pageId ? { ...page, [name]: selectedValue2 } : page
      )
    );
    setLoading(true);
  };

  const handleArchiveClick = (item) => {
    if (item.status === "archive") {
      handleUpdateActicityStatus(item, "active");
    } else {
      handleUpdateActicityStatus(item, "archive");
    }
  };

  let handleUpdateActicityStatus = async (item, status) => {
    setLoading(true);
    try {
      let requestPayload = {
        status: status,
        page_id: item._id,
      };
      let response = await axios.post(
        API_BASE_URL + `/researchers/pages/update-status`,
        requestPayload,
        authState.authToken
      );
      console.log("=============Response=========:", response);
      if (response.success) {
        setPageListData((prevData) =>
          prevData.map((page) =>
            page._id === item._id ? { ...page, status: status } : page
          )
        );
        setAllDropVal3("Page Options");
        setOption("Options");
        toast.success(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      let message = "Something went wrong, please try again!";
      let status_code = 500;
      if (error.response) {
        message = error.response.data.message;
        status_code = error.response.status;
      }
      return {
        success: false,
        message: message,
        status_code: status_code,
      };
    }
  };

  const handleOpenDeletePopup = (id, title) => {
    setDeletePageId(id);
    setDeletePageName(title);
  };

  const handlePageDataChange = (e, pageId) => {
    const { name, value } = e.target;

    setPageListData((prevPageListData) =>
      prevPageListData.map((page) =>
        page._id === pageId ? { ...page, [name]: value } : page
      )
    );
  };

  const updateProjectPage = async (page_id) => {
    try {
      const updatedPage = pageListData.find((page) => page._id === page_id);

      console.log("The value ofupdatedPage:", updatedPage);
      const payload = {
        workspace_id: updatedPage.workspace_id,
        project_id: updatedPage.project_id,
        title: updatedPage.title,
        image: "public/uploads/files/2024/7/22/1721655767862-702009780.png",
        participant_access: updatedPage.participant_access,
        participant_groups: updatedPage.participant_groups,
        participant_ids: updatedPage.participant_ids,
        administrator_access: updatedPage.administrator_access,
        administrator_roles: updatedPage.administrator_roles,
        administrator_ids: updatedPage.administrator_ids,
        status: updatedPage.status,
      };

      if (!updatedPage) {
        toast.error("Page not found!", TOAST_CONFIG);
        return;
      }

      let response = await axios.put(
        API_BASE_URL + `/researchers/pages/${page_id}`,
        payload,
        authState.authToken
      );
      if (response.success) {
        resetAll();
        toast.success(response.message, TOAST_CONFIG);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      let message = "Something went wrong, please try again!";
      let status_code = 500;
      if (error.response) {
        message = error.response.data.message;
        status_code = error.response.status;
      }
      return {
        success: false,
        message: message,
        status_code: status_code,
      };
    }
  };

  const resetAll = () => {
    setPageUpdateDats(initialState);
    setImage(null);
  };

  const handleOpenDuplicatePopup = (page) => {
    setSelectedParticipantGroups2(page?.participant_groups);
    setSelectedAdminRoles2(page?.administrator_roles);
    const initialDuplicatePageState = {
      title: page?.title,
      image: page?.image,
      workspace_id: page?.workspace_id,
      project_id: page?.project_id,
      participant_access: page?.participant_access,
      participant_groups: page?.participant_groups,
      participant_ids: page?.participant_ids,
      administrator_access: page?.administrator_access,
      administrator_roles: page?.administrator_roles,
      administrator_ids: page?.administrator_ids,
      status: page?.status,
    };
    setDuplicatePageData(initialDuplicatePageState);
  };

  const handleDragStart = (index, sequence) => {
    setDraggingIndex(index);
    setDragSequence(sequence);
    console.log("The value of DragSequence:", sequence);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // Handle the drop event and update the sequence
  const handleDragDrop = (index, page_id) => {
    console.log("Value of index on drop:", index);
    console.log("Value of draggingIndex:", draggingIndex);

    // Exit if the drop is at the same position
    if (index === draggingIndex) {
      return;
    }

    // Create a copy of the page list to modify
    const updatedItems = [...pageListData];
    console.log("Initial page list:", updatedItems);

    // Remove the dragged item from its original position
    const [movedItem] = updatedItems.splice(draggingIndex, 1);

    console.log("The value of movedItem:", movedItem);

    // Insert the moved item at the new position
    updatedItems.splice(index, 0, movedItem);

    // Recalculate the sequence index for each page
    const updatedSequence = updatedItems.map((item, newIndex) => ({
      ...item,
      sequence_index: newIndex + 1, // Adjusted to one-based indexing if needed
    }));

    console.log("Updated sequence after drop:", updatedSequence);

    // Update the state with the new order
    setPageListData(updatedSequence); // Update the list for rendering
    setDraggingIndex(null); // Reset the dragging index

    // Set the updated sequence state to be sent to the backend
    setUpdatedSequenceState(updatedSequence);
  };

  const updatePageSequence = async (updatedSequence) => {
    try {
      console.log(
        "The value of updatedSequence before posting through API:",
        updatedSequence
      );

      // Create the sequence payload for the API
      const sequence = updatedSequence.map((item) => ({
        page_id: item._id, // The unique ID of the page
        index: item.sequence_index, // The new sequence index
      }));

      console.log("Sequence payload being sent to the API:", sequence);

      const response = await axios.post(
        API_BASE_URL + `/researchers/pages/update-sequence`,
        {
          sequence: sequence, // The API expects "sequence" as the key
        } // The API expects "sequence" as the key
      );

      if (response.status === 200) {
        toast.success(response.message, TOAST_CONFIG);

        console.log("Task sequence updated successfully:", response.data);
      } else {
        toast.error(response.message, TOAST_CONFIG);

        console.error(
          "Failed to update task sequence:",
          response.status,
          response.data
        );
      }
    } catch (error) {
      console.error("Error updating task sequence:", error);
    }
  };

  // Triggering the update sequence API call after reordering
  const reArrangePages = () => {
    setLoading(true);
    updatePageSequence(updatedSequenceState); // Call the function with the updated sequence state
  };

  const Option = ({
    page,
    setDuplicatePagePop,
    setDeletePagePop,
    setAllDropVal3,
    setStatusDrop,
    statusDrop,
    setStatusDropVal,
    statusDropVal,
    label,
  }) => {
    return (
      <Box onClick={() => setStatusDrop(!statusDrop)} className="statusBox">
        <Typography className="statusLabel">
          {statusDropVal || t("groups.option")}
        </Typography>
        <img
          style={{ rotate: statusDrop ? "180deg" : "0deg" }}
          src={DownArrowIcon}
        />
        <Box sx={{ height: statusDrop ? "auto" : "0px" }} className="statusPop">
          <Box
            // onClick={() => setStatusDropVal("Duplicate")}
            onClick={() => {
              setAllDropVal3("Page Options");
              setStatusDropVal("Duplicate");
              handleOpenDuplicatePopup(page);
              setDuplicatePagePop(true);
            }}
            className="statusPopItem"
          >
            {duplicateIcon}
            <Typography>{t("observer.Duplicate")}</Typography>
          </Box>
          <Box
            onClick={() => {
              setStatusDropVal("Archive");
              handleArchiveClick(page);
            }}
            className="statusPopItem"
          >
            {archive}
            {page?.status === "archive" ? (
              <Typography>Active</Typography>
            ) : (
              t("commonTxt.archiveBtn")
            )}
          </Box>
          <Box
            onClick={() => {
              setAllDropVal3("Page Options");
              setStatusDropVal("Delete");
              handleOpenDeletePopup(page?._id, page?.title);
              setDeletePagePop(true);
            }}
            className="statusPopItem"
          >
            {deletSvg}
            <Typography>{t("commonTxt.delete")}</Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setAllDropVal3("Page Options");
    }
  };

  useEffect(() => {
    if (allDropVal3 === "Page Settings") {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [allDropVal3]);

  return (
    <>
      <DeletePagePop
        deletePagePop={deletePagePop}
        setDeletePagePop={setDeletePagePop}
        setAllDropVal3={setAllDropVal3}
        deletePageId={deletePageId}
        deletePageName={deletePageName}
      />
      <DuplicatePagePop
        duplicatePagePop={duplicatePagePop}
        setDuplicatePagePop={setDuplicatePagePop}
        setAllDropVal3={setAllDropVal3}
        projectName={selectedProjectName}
        duplicatePageData={duplicatePageData}
        setDuplicatePageData={setDuplicatePageData}
        selectedAdminRoles2={selectedAdminRoles2}
        setSelectedAdminRoles2={setSelectedAdminRoles2}
        selectedParticipantGroups2={selectedParticipantGroups2}
        setSelectedParticipantGroups2={setSelectedParticipantGroups2}
      />
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: allDropVal3 === "Page Settings" ? "flex" : "none" }}
        className="companyPop pageSettingPop"
      >
        <Box
          sx={{ display: allDropVal3 === "Page Settings" ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className="companyHeader">
            {t("pageSettingPop.pageSettingPopHead")} ({selectedProjectName})
          </Typography>

          <Box className="psNavBox">
            <Box
              onClick={() => setNav(0)}
              className={nav === 0 ? "psNavItemActive psNavItem" : "psNavItem"}
            >
              <Typography>{t("pageSettingPop.activePagesTab")}</Typography>
            </Box>
            <Box
              onClick={() => setNav(1)}
              className={nav === 1 ? "psNavItemActive psNavItem" : "psNavItem"}
            >
              <Typography>{t("pageSettingPop.archivedTab")}</Typography>
            </Box>
          </Box>

          <Box
            sx={{ display: nav === 0 ? "flex" : "none" }}
            className="pageSetMainBox"
          >
            {/* ---------------------Active Pages--------------------- */}

            {/* --------------------------Researchers-------------------------- */}
            {activePages.length > 0 &&
              activePages.map((pageData, i) => (
                <Box
                  key={pageData._id}
                  className={
                    openTab === pageData?._id
                      ? "setPDropBox setPDropBoxOpen"
                      : "setPDropBox"
                  }
                  draggable
                  onDragStart={() =>
                    handleDragStart(i, pageData?.sequence_index)
                  }
                  onDragOver={handleDragOver}
                  onDrop={() => handleDragDrop(i, pageData?._id)}
                >
                  <Box
                    className="menuTab"
                    onClick={() => handelDrop(pageData?._id)}
                  >
                    <Box className="stdfb">
                      <img src={OptionLine} className="optionLineIcon" />
                      <Typography className="setPDropTitle">
                        {pageData?.title}
                      </Typography>
                    </Box>
                    <img
                      src={DownArrowIcon}
                      className="setPDropIcon"
                      style={{
                        rotate: openTab === pageData?._id ? "180deg" : "0deg",
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: openTab === pageData?._id ? "block" : "none",
                    }}
                    className="SetMainCBox"
                  >
                    <Typography className="psNlabel">
                      {t("pageSettingPop.pageNameLabel")}
                    </Typography>
                    <input
                      placeholder={t("duplicatePagePop.pageNamePlaceholder")}
                      name="title"
                      value={pageData.title || ""}
                      className="phoneInput"
                      onChange={(e) => handlePageDataChange(e, pageData._id)}
                    />
                    <Box className="gapBox"></Box>
                    <Typography className="psNlabel">
                      {t("pageSettingPop.coverImageLabel")}
                    </Typography>

                    <Box
                      className="mediaInputbox upload-area"
                      onClick={() => {
                        document.getElementById("fileInput").click();
                      }}
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                    >
                      <img
                        src={
                          pageData?.image
                            ? STORAGE_BASE_PATH + `/` + pageData?.image
                            : uploadIcon
                        }
                      />
                      <Typography>{t("addProfile.clickOrDragText")}</Typography>
                      <input
                        id="fileInput"
                        style={{ display: "none" }}
                        type="file"
                        onChange={handleFileInputChange}
                      />
                    </Box>
                    {/* <Box className="gapBox"></Box> */}
                    <Box className="basicBoxItem addPageParticipantAccess">
                      <Typography className="psNlabel">
                        {t("addMessageCardPop.participantAccess")}
                      </Typography>
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          sx={{ width: "inherit !important" }}
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          className="statusBox MuiBox-root css-0"
                          value={
                            pageData?.participant_access ||
                            "Select Participant Access"
                          }
                          onChange={(e) =>
                            handleParticipantAccessChange(e, pageData._id)
                          }
                          name="participant_access"
                        >
                          {ParticipantItem2.length > 0 &&
                            ParticipantItem2.map((el, i) => (
                              <MenuItem value={el.value} key={i}>
                                {el.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>

                    {pageData?.participant_access ===
                      "selected_participants" && (
                      <Box className="searchGroupSection">
                        <Box
                          pl={4.5}
                          className="inputAppDrop"
                          onClick={() => setGroupDrop2(!groupDrop2)}
                        >
                          <img className="groupsIcon" src={groupsIcon} />
                          <Typography>
                            {t("pageSettingPop.participantGroupPlaceholder")}
                          </Typography>
                          <img
                            style={{
                              rotate:
                                groupDrop2 ||
                                pageData?.participant_access ===
                                  "selected_participants"
                                  ? "180deg"
                                  : "0deg",
                            }}
                            src={dropArrow}
                          />
                        </Box>
                        <Box
                          sx={{
                            height:
                              groupDrop2 ||
                              pageData?.participant_access ===
                                "selected_participants"
                                ? "auto"
                                : "0px",
                          }}
                          className="checkMain_box"
                        >
                          <Box className="ckeckBoxRoom">
                            {groupsListData.length > 0 &&
                              groupsListData.map((group, index) =>
                                chackBoxRoom(
                                  group?._id,
                                  group?.name,
                                  pageData?.participant_groups,
                                  pageData?._id
                                )
                              )}
                          </Box>
                          <Box className="propertyPagination">
                            <Box>
                              <p>1</p>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}

                    <Box className="basicBoxItem addPageParticipantAccess">
                      <Typography className="psNlabel adminAccess">
                        {t("addMessageCardPop.administratorAccess")}
                      </Typography>
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          sx={{ width: "inherit !important" }}
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          className="statusBox MuiBox-root css-0"
                          value={
                            pageData?.administrator_access ||
                            "Select Administrator Access"
                          }
                          onChange={(e) =>
                            handleAdminAccessChange(e, pageData._id)
                          }
                          name="administrator_access"
                        >
                          {AdministratorItem2.length > 0 &&
                            AdministratorItem2.map((el2, i) => (
                              <MenuItem value={el2.value} key={i}>
                                {el2.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>

                    {pageData?.administrator_access === "selected_roles" && (
                      <Box className="checkMain_box">
                        <Box className="ckeckBoxRoom">
                          {administratorRoles.length > 0 &&
                            administratorRoles.map((el, i) =>
                              AdminRolesCheckBox(
                                el.value,
                                el.name,
                                pageData?.administrator_roles,
                                pageData?._id
                              )
                            )}
                        </Box>
                      </Box>
                    )}

                    {pageData?.administrator_access === "selected_admins" && (
                      <Box className="searchGroupSection">
                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            className="statusBox MuiBox-root css-0"
                            value={
                              pageData.administrator_ids ||
                              "Select Administrator"
                            }
                            onChange={(e) =>
                              setPageListData((prevData) =>
                                prevData.map((item) =>
                                  item._id === pageData._id
                                    ? {
                                        ...item,
                                        administrator_ids: e.target.value,
                                      }
                                    : item
                                )
                              )
                            }
                            multiple
                            displayEmpty
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return (
                                  <Box pl={4.5} className="inputAppDrop">
                                    <Typography>Select admins</Typography>
                                  </Box>
                                );
                              }
                              return selected
                                .map((value) => {
                                  const user = adminsList.find(
                                    (usr) => usr._id === value
                                  );
                                  return user ? user.name : value;
                                })
                                .join(", ");
                            }}
                          >
                            {adminsList.length > 0 &&
                              adminsList.map((el, i) => (
                                <MenuItem value={el._id} key={i}>
                                  {el.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Box>
                    )}

                    <Box className="workPupBtnBox ActivePageBtnBox">
                      <Option
                        page={pageData}
                        setDuplicatePagePop={setDuplicatePagePop}
                        setDeletePagePop={setDeletePagePop}
                        setAllDropVal3={setAllDropVal3}
                        statusDrop={option}
                        setStatusDrop={setOption}
                        setStatusDropVal={setOptionVal}
                        statusDropVal={optionVal}
                        label={t("Publications.options")}
                        itemAry={optionAry}
                      />
                      <AppBtn
                        buttonText={t("commonTxt.saveBtn")}
                        onClick={() => updateProjectPage(pageData._id)}
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>

          {/* --------------------------------Archived------------------------------------ */}

          <Box
            sx={{ display: nav === 1 ? "flex" : "none" }}
            className="pageSetMainBox"
          >
            {archivedPages.length > 0 &&
              archivedPages.map((pageData, i) => (
                <Box
                  key={pageData._id}
                  className={
                    openTab === pageData?._id
                      ? "setPDropBox setPDropBoxOpen"
                      : "setPDropBox"
                  }
                  draggable
                  onDragStart={() =>
                    handleDragStart(i, pageData?.sequence_index)
                  }
                  onDragOver={handleDragOver}
                  onDrop={() => handleDragDrop(i, pageData?._id)}
                >
                  <Box
                    className="menuTab"
                    onClick={() => handelDrop(pageData?._id)}
                  >
                    <Box className="stdfb">
                      <img src={OptionLine} className="optionLineIcon" />
                      <Typography className="setPDropTitle">
                        {pageData?.title}
                      </Typography>
                    </Box>
                    <img
                      src={DownArrowIcon}
                      className="setPDropIcon"
                      style={{
                        rotate: openTab === pageData?._id ? "180deg" : "0deg",
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: openTab === pageData?._id ? "block" : "none",
                    }}
                    className="SetMainCBox"
                  >
                    <Typography className="psNlabel">
                      {t("pageSettingPop.pageNameLabel")}
                    </Typography>
                    <input
                      placeholder={t("duplicatePagePop.pageNamePlaceholder")}
                      name="title"
                      value={pageData.title || ""}
                      className="phoneInput"
                    />
                    <Box className="gapBox"></Box>
                    <Typography className="psNlabel">
                      {t("pageSettingPop.coverImageLabel")}
                    </Typography>

                    <Box className="mediaInputbox upload-area">
                      <img
                        src={
                          pageData?.image
                            ? STORAGE_BASE_PATH + `/` + pageData?.image
                            : uploadIcon
                        }
                      />
                      <Typography>{t("addProfile.clickOrDragText")}</Typography>
                      <input
                        id="fileInput"
                        style={{ display: "none" }}
                        type="file"
                      />
                    </Box>
                    {/* <Box className="gapBox"></Box> */}
                    <Box className="basicBoxItem addPageParticipantAccess">
                      <Typography className="psNlabel">
                        {t("addMessageCardPop.participantAccess")}
                      </Typography>
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          sx={{ width: "inherit !important" }}
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          className="statusBox MuiBox-root css-0"
                          value={
                            pageData?.participant_access ||
                            "Select Participant Access"
                          }
                          name="participant_access"
                        >
                          {ParticipantItem2.length > 0 &&
                            ParticipantItem2.map((el, i) => (
                              <MenuItem value={el.value} key={i}>
                                {el.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>

                    {pageData?.participant_access ===
                      "selected_participants" && (
                      <Box className="searchGroupSection">
                        <Box pl={4.5} className="inputAppDrop">
                          <img className="groupsIcon" src={groupsIcon} />
                          <Typography>
                            {t("pageSettingPop.participantGroupPlaceholder")}
                          </Typography>
                          <img
                            style={{
                              rotate:
                                groupDrop2 ||
                                pageData?.participant_access ===
                                  "selected_participants"
                                  ? "180deg"
                                  : "0deg",
                            }}
                            src={dropArrow}
                          />
                        </Box>
                        <Box
                          sx={{
                            height:
                              groupDrop2 ||
                              pageData?.participant_access ===
                                "selected_participants"
                                ? "auto"
                                : "0px",
                          }}
                          className="checkMain_box"
                        >
                          <Box className="ckeckBoxRoom">
                            {groupsListData.length > 0 &&
                              groupsListData.map((group, index) =>
                                chackBoxRoom(
                                  group?._id,
                                  group?.name,
                                  pageData?.participant_groups,
                                  pageData?._id
                                )
                              )}
                          </Box>
                          <Box className="propertyPagination">
                            <Box>
                              <p>1</p>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}

                    <Box className="basicBoxItem addPageParticipantAccess">
                      <Typography className="psNlabel adminAccess">
                        {t("addMessageCardPop.administratorAccess")}
                      </Typography>
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          sx={{ width: "inherit !important" }}
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          className="statusBox MuiBox-root css-0"
                          value={
                            pageData?.administrator_access ||
                            "Select Administrator Access"
                          }
                          name="administrator_access"
                        >
                          {AdministratorItem2.length > 0 &&
                            AdministratorItem2.map((el2, i) => (
                              <MenuItem value={el2.value} key={i}>
                                {el2.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>

                    {pageData?.administrator_access === "selected_roles" && (
                      <Box className="checkMain_box">
                        <Box className="ckeckBoxRoom">
                          {administratorRoles.length > 0 &&
                            administratorRoles.map((el, i) =>
                              AdminRolesCheckBox(
                                el.value,
                                el.name,
                                pageData?.administrator_roles,
                                pageData?._id
                              )
                            )}
                        </Box>
                      </Box>
                    )}

                    {pageData?.administrator_access === "selected_admins" && (
                      <Box className="searchGroupSection">
                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            className="statusBox MuiBox-root css-0"
                            value={
                              pageData.administrator_ids ||
                              "Select Administrator"
                            }
                            multiple
                            displayEmpty
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return (
                                  <Box pl={4.5} className="inputAppDrop">
                                    <Typography>Select admins</Typography>
                                  </Box>
                                );
                              }
                              return selected
                                .map((value) => {
                                  const user = adminsList.find(
                                    (usr) => usr._id === value
                                  );
                                  return user ? user.name : value;
                                })
                                .join(", ");
                            }}
                          >
                            {adminsList.length > 0 &&
                              adminsList.map((el, i) => (
                                <MenuItem value={el._id} key={i}>
                                  {el.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Box>
                    )}

                    <Box className="workPupBtnBox ActivePageBtnBox">
                      <AppBtnOutLine
                        buttonText={t("commonTxt.delete")}
                        onClick={() => {
                          handleOpenDeletePopup(pageData?._id, pageData?.title);
                          setAllDropVal3("Page Options");
                          setDeletePagePop(true);
                        }}
                      />

                      <AppBtn
                        buttonText={t("commonTxt.restore")}
                        onClick={() => {
                          handleArchiveClick(pageData);
                          setAllDropVal3("Page Options");
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine
              buttonText={t("commonTxt.cancelBtn")}
              onClick={() => setAllDropVal3("Page Options")}
            />
            <AppBtn
              buttonText={t("commonTxt.doneBtn")}
              onClick={() => reArrangePages()}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
