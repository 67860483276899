import React, { useEffect, useState } from "react";
import { Box, Typography, Alert, Button, Grid, TextField } from "@mui/material";
import "./style.css";

import { useNavigate } from "react-router-dom";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { AntSwitch } from "../../../Components/AppTools/AppTool";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DropArrow from "../../../Assets/Images/chevron-down.png";
import backArrow from "../../../Assets/Images/noun-back.png";
import Pagination from "../../../Components/AppTools/Pagination";
import {
  EnhancedGroupsWorkspace,
  StandardGroupsWorkspace,
} from "../../../Assets/Data";

import { trashBlack, settingIconBlack } from "../../../Assets/Data";

import {
  AppBtnOutLine,
  AppBtn,
  AppAddBtn,
} from "../../../Components/AppTools/AppButton";

import deleteIcon from "../../../Assets/Images/icon-delete-red.svg";
import { sideHaumIcon } from "../../../Assets/Data";

import SideMenu from "../../../Components/WSAdministration/SideMenu";
import AddEnhancedGroup from "../../../Components/WSAdministration/AddEnhancedGroup";
import AddStandardGroup from "../../../Components/WSAdministration/AddStandardGroup";
import SettingStandardGroup from "../../../Components/WSAdministration/SettingStandardGroup";
import DeleteWSGroupPop from "../DeleteWSGroupPop";
import axios from "../../../lib/axios";
import { API_BASE_URL } from "../../../env";
import { formatDate, renderUserLayout } from "../../../lib/helpers";
import { toast } from "react-toastify";
import { current } from "@reduxjs/toolkit";
import { useAppSelector } from "../../../lib/store/store";
import AdminLayout from "../../Admin/partials/AdminLayout";
import ResearcherLayout from "../../Researchers/partials/ResearcherLayout";
import PreviewFilterPop from "../../../Components/WSAdministration/PreviewFilterPop";
export default function Groups({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
  participantsSubMenu,
  setParticipantsSubMenu,
  configurationSubMenu,
  setConfigurationSubMenu,
}) {
  const [customFieldList, setCustomFieldList] = useState([]);
  const workspaceState = useAppSelector((state) => state.workspace);
  let authState = useAppSelector((state) => state.auth);
  const enhancegrpData = {
    name: "",
    restricted_projects: [],
    allow_projects: [],
    remove_projects: [],
    is_enhanced: true,
    match_all_criteria: false,
    attributes: [
      {
        name: "",
        scale: "",
        value: [],
        customFields: customFieldList,
        customFieldsChoices: [],
      },
    ],
    workspace_id: workspaceState?.selectedWorkspace?._id,
  };

  const [selectedEnhancData, setSelectedEnhanceData] = useState(enhancegrpData);
  const [enhancedSettng, setEnhancedSetting] = useState();
  const [projectvisibility, setprojectvisibility] = React.useState(0);
  const [deleteWSGroupPop, setDeleteWSGroupPop] = useState();
  const [groupList, setGroupList] = useState([]);
  const [basicGroupListData, setBasicGroupListData] = useState([]);
  const [enhancedGroupsListData, setEnhancedGroupsListData] = useState([]);
  const [enhanceGroupList, setEnhancedGroupList] = useState([]);
  const [nameError, setNameError] = useState("");

  const [projectList, setProjectList] = useState([]);
  const Navigate = useNavigate();
  const grpId = enhancedSettng;
  const handleMainOpenTabe = (Url) => {
    Navigate(Url);
  };

  const handleChange = (event) => {
    setSelectedEnhanceData((prevGroup) => ({
      ...prevGroup,
      [event.target.name]: event.target.value,
    }));
  };

  const handleToggleChange = (key) => (e) => {
    const check = e.target.checked;
    setSelectedEnhanceData({
      ...selectedEnhancData,
      [key]: check,
    });
  };

  const removeAttribute = (index) => {
    setSelectedEnhanceData((prevGroup) => ({
      ...prevGroup,
      attributes: prevGroup.attributes.filter((_, i) => i !== index),
    }));
  };

  const addAttribute = () => {
    setSelectedEnhanceData((prevGroup) => ({
      ...prevGroup,
      attributes: [
        ...prevGroup.attributes,
        {
          name: "",
          scale: "",
          value: [],
          customFields: customFieldList,
          customFieldsChoices: [],
        },
      ],
    }));
  };

  const handleAttributeChange = (index, field, value) => {
    setSelectedEnhanceData((prevGroup) => ({
      ...prevGroup,
      attributes: prevGroup.attributes.map((attr, i) =>
        i === index ? { ...attr, [field]: value } : attr
      ),
    }));
    if (field == "name") {
      setSelectedEnhanceData((prevGroup) => ({
        ...prevGroup,
        attributes: prevGroup.attributes.map((attr, i) =>
          i === index ? { ...attr, value: [] } : attr
        ),
      }));
    }
  };

  const getChoose = (index, ch) => {
    console.log("ch", ch);
    setSelectedEnhanceData((prevGroup) => ({
      ...prevGroup,
      attributes: prevGroup.attributes.map((attr, i) => {
        return index === i ? { ...attr, customFieldsChoices: ch } : attr;
      }),
    }));
  };

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 25,
    totalPages: 0,
    totalItems: 0,
  });

  const onPageChange = (pageNum) => {
    getGroupData(pageNum);
  };
  const [addprojectparticipants, setaddprojectparticipants] = React.useState(0);

  const handleChange2 = (event) => {
    setaddprojectparticipants(event.target.value);
  };

  const [removeprojectparticipants, setremoveprojectparticipants] =
    React.useState(0);

  const handleChange3 = (event) => {
    setremoveprojectparticipants(event.target.value);
  };

  const [attribute, setattribute] = React.useState(0);

  const handleChange4 = (event) => {
    setattribute(event.target.value);
  };

  const [scale, setscale] = React.useState(0);

  const handleChange5 = (event) => {
    setscale(event.target.value);
  };

  const [value, setvalue] = React.useState(0);

  const handleChange6 = (event) => {
    setvalue(event.target.value);
  };

  const [colorSelectorDrop1, setColorSelectorDrop1] = useState(false);
  const [colorSelectorDrop1Val, setColorSelectorDrop1Val] =
    useState("Solid Color");

  const [optionIndex, setOptionIndex] = useState();
  const [workSpaceOption, setWorkspaceOption] = useState(false);

  const [optionIndex02, setOptionIndex02] = useState();

  const [workSpaceOption02, setWorkspaceOption02] = useState(false);

  const [addEnhancedGroup, setAddEnhancedGroup] = useState(false);
  const [addStandardGroup, setAddStandardGroup] = useState(false);
  const [standardSetting, setStandardSetting] = useState();
  const [customFieldIds, setCustomFieldIds] = useState([]);
  const [previewFilterPop, setPreviewFilterPop] = useState(false);

  let newArrayData;
  function printItemsByState(array, state) {
    let startIndex = state * 4;
    let endIndex = (state + 1) * 4;
    let itemsToPrint = array.slice(startIndex, endIndex);
    if (itemsToPrint.length > 0) {
      newArrayData = itemsToPrint;
    } else {
      console.log("No items to print for the given state.");
    }
  }

  let pageNumver = 0;

  const handleNext = () => {
    pageNumver = pageNumver + 1;
    printItemsByState(EnhancedGroupsWorkspace, pageNumver);
  };
  const handlePrev = () => {
    if (pageNumver < 0) {
      pageNumver = pageNumver - 1;
    }
    printItemsByState(EnhancedGroupsWorkspace, pageNumver);
  };
  printItemsByState(EnhancedGroupsWorkspace, pageNumver);

  let newArrayData02;
  function printItemsByState02(array02, state02) {
    let startIndex02 = state02 * 4;
    let endIndex02 = (state02 + 1) * 4;
    let itemsToPrint02 = array02.slice(startIndex02, endIndex02);
    if (itemsToPrint02.length > 0) {
      newArrayData02 = itemsToPrint02;
    } else {
      console.log("No items to print for the given state.");
    }
  }

  let pageNumver02 = 0;

  const handleNext02 = () => {
    pageNumver02 = pageNumver02 + 1;
    printItemsByState02(StandardGroupsWorkspace, pageNumver02);
  };
  const handlePrev02 = () => {
    if (pageNumver02 < 0) {
      pageNumver02 = pageNumver02 - 1;
    }
    printItemsByState02(StandardGroupsWorkspace, pageNumver02);
  };
  printItemsByState02(StandardGroupsWorkspace, pageNumver02);
  useEffect(() => {
    const getEnhancedGroupSelectedData = async () => {
      try {
        const response = await axios.get(
          API_BASE_URL + `/participant/groups?_id=${grpId}`
        );
        const data = response.data.data;
        data.forEach((val, i) => {
          val.attributes.forEach((el) => {
            el.customFields = customFieldList;
            const qs = customFieldList.find((ch) => ch.question === el.name);
            el.customFieldsChoices = qs.choices;
          });
          console.log("val", val.attributes);
          setSelectedEnhanceData((prevData) => ({ ...prevData, ...val }));
        });
      } catch (error) {
        console.log("error.message", error.message);
      }
    };
    if (grpId) {
      getEnhancedGroupSelectedData();
    }
    getCustomFields();
  }, [grpId]);

  useEffect(() => {
    getGroupData();
    // getEnhancedGroupData();

    getProject();
  }, []);

  const getProject = async () => {
    try {
      const data = await axios.get(API_BASE_URL + "/researchers/projects");
      setProjectList(data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomFields = async () => {
    try {
      const url = `/participant/profile-fields?workspace_id=${workspaceState?.selectedWorkspace?._id}&field_type=poll`;
      const data = await axios.get(url);
      console.log("setCustomFieldList", data.data.data);
      setCustomFieldList(data.data.data);
      const ids = data.data.data.map(el=>el._id);
      setCustomFieldIds(ids);
      setSelectedEnhanceData((prevGroup) => ({
        ...prevGroup,
        attributes: prevGroup.attributes.map((attr, i) => ({
          ...attr,
          customFields: data.data.data,
        })),
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const getGroupData = async (pageNum) => {
    try {
      const data = await axios.get(
        API_BASE_URL + `/participant/groups?page=${pageNum}`
      );
      const bGroup = data.data?.data?.filter((grp) => grp.is_enhanced == false);
      const eGroup = data.data?.data?.filter((grp) => grp.is_enhanced == true);
      setBasicGroupListData(bGroup);
      setEnhancedGroupsListData(eGroup);
      setGroupList(data.data.data);
      setPaginationData({
        currentPage: data.data.pagination.currentPage,
        itemsPerPage: data.data.pagination.itemsPerPage,
        totalPages: data.data.pagination.totalPages,
        totalItems: data.data.pagination.totalItems,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getEnhancedGroupData = async () => {
    try {
      const data = await axios.get(API_BASE_URL + "/participant/groups");
      const enhance = data.data.data;
      setEnhancedGroupList(enhance);
    } catch (error) {
      console.log(error);
    }
  };

  const updateEnhancedGroup = () => {
    const {
      attributes,
      _id,
      group_id,
      updated_by,
      updated_at,
      created_at,
      created_by,
      deleted_at,
      deleted_by,
      __v,
      ...payload
    } = { ...selectedEnhancData };
    const attrs = attributes
      .filter(({ name }) => name !== "")
      .map(({ customFields, customFieldsChoices, _id, ...rest }) => rest);
    payload.attributes = attrs;
    if (payload.name === "") {
      setNameError("Group name is required.")
      return toast.error("Group name is required.")
    } else {
      setNameError("")
    }
    axios
      .put(API_BASE_URL + `/participant/groups/${grpId}`, payload)
      .then((res) => {
        if (res.success) {
          toast.success(res.message);
          // setEnhancedSetting(false);
          getGroupData();
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const getChildren = () => {
    return <Box className="researchersMainBox pageContainer">
      <DeleteWSGroupPop
        deleteWSGroupPop={deleteWSGroupPop}
        setDeleteWSGroupPop={setDeleteWSGroupPop}
        getGroupData={getGroupData}
        getEnhancedGroupData={getEnhancedGroupData}
      />
      <PreviewFilterPop
        previewFilterPop={previewFilterPop}
        setPreviewFilterPop={setPreviewFilterPop}
        customFieldIds={customFieldIds}
      />
      {!enhancedSettng &&
        !addEnhancedGroup &&
        !addStandardGroup &&
        !standardSetting ? (
        <>
          <Alert
            icon={<InfoOutlinedIcon />}
            severity="success"
            className="alertnote"
          >
            Categories participants into groups to personalize their
            project experience and enhance your analysis. All activities,
            tasks, conversations, and home pages can focus on one or more
            groups. You can also filter project overviews, reports, and
            transcripts by group.
          </Alert>
          {/* Enhanced Group */}
          <Box className="EnhancedGroupsSection">
            <Box className="GroupHeading">
              <Typography variant="h4">
                Enhanced Groups:{" "}
                <span>
                  Group participants dynamically based on advanced
                  criteria
                </span>
              </Typography>
              <AppAddBtn
                onClick={() =>
                  handleMainOpenTabe(
                    "/workspace-administration/enhanced-group"
                  )
                }
                buttonText="Enhanced Groups"
              />
            </Box>
            <Box className="GroupworkListBox">
              <Box className="GrouphaderRow" minWidth={"1111px"}>
                <Box minWidth={"35%"} className="Tabelsel">
                  <Typography>Groups</Typography>
                </Box>
                <Box className="Tabelsel" minWidth={"25%"}>
                  <Typography>Updated At</Typography>
                </Box>
                <Box className="Tabelsel" minWidth={"25%"}>
                  <Typography>Participants</Typography>
                </Box>
              </Box>

              {enhancedGroupsListData &&
                enhancedGroupsListData?.map((el, index) => (
                  <Box
                    key={index}
                    className="GroupTabelRow"
                    minWidth={"1111px"}
                  >
                    <Box
                      minWidth={"35%"}
                      className="Tabelsel navFsel navtable"
                    >
                      <Box
                        id="dot"
                        onClick={() => {
                          setWorkspaceOption(!workSpaceOption);
                          setOptionIndex(index);
                        }}
                        className={
                          workSpaceOption && index === optionIndex
                            ? "dot dotActive"
                            : "dot"
                        }
                      >
                        {
                          <Box
                            sx={{
                              display:
                                workSpaceOption && index === optionIndex
                                  ? "flex"
                                  : "none",
                            }}
                            className="optionPop"
                          >
                            <Box
                              className="optionPopItem GsettingIcon"
                              onClick={() => setEnhancedSetting(el?._id)}
                            >
                              {settingIconBlack}
                              <Typography>Settings</Typography>
                            </Box>
                            <Box
                              className="optionPopItem"
                              onClick={() =>
                                setDeleteWSGroupPop({
                                  groupId: el?._id,
                                  groupName: el?.name,
                                })
                              }
                            >
                              {trashBlack}
                              <Typography>Delete</Typography>
                            </Box>
                          </Box>
                        }
                      </Box>
                      {
                        <Typography key={index}>{el.name}</Typography>
                        // {index !== el.attributes.length - 1 ? ', ' : ''
                      }
                    </Box>
                    {/* <Box className="Tabelsel">
              <Typography>{el.groups}</Typography>
            </Box> */}
                    <Box className="Tabelsel" minWidth={"25%"}>
                      <Typography>{formatDate(el.updated_at)}</Typography>
                    </Box>
                    <Box className="Tabelsel" minWidth={"25%"}>
                      <Typography className="boxed">
                        {el.allow_projects.length}
                      </Typography>
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
          {/* Statndard Grop */}
          <Box className="StandardGroupsSection">
            <Box className="GroupHeading">
              <Typography variant="h4">
                Standard Groups: <span>Group participants manually</span>
              </Typography>
              <AppAddBtn
                onClick={() =>
                  handleMainOpenTabe(
                    "/workspace-administration/standard-group"
                  )
                }
                buttonText="Standard Groups"
              />
            </Box>
            <Box className="GroupworkListBox">
              <Box className="GrouphaderRow" minWidth={"1111px"}>
                <Box minWidth={"35%"} className="Tabelsel">
                  <Typography>Groups</Typography>
                </Box>
                <Box className="Tabelsel" minWidth={"25%"}>
                  <Typography>Updated At</Typography>
                </Box>
                <Box className="Tabelsel" minWidth={"25%"}>
                  <Typography>Participants</Typography>
                </Box>
              </Box>

              {basicGroupListData &&
                basicGroupListData.map((el, index02) => (
                  <Box
                    key={index02}
                    className="GroupTabelRow"
                    minWidth={"1111px"}
                  >
                    <Box
                      minWidth={"35%"}
                      className="Tabelsel navFsel navtable"
                    >
                      <Box
                        id="dot02"
                        onClick={() => {
                          setWorkspaceOption02(!workSpaceOption02);
                          setOptionIndex02(index02);
                        }}
                        className={
                          workSpaceOption02 && index02 === optionIndex02
                            ? "dot dotActive"
                            : "dot"
                        }
                      >
                        {
                          <Box
                            sx={{
                              display:
                                workSpaceOption02 &&
                                  index02 === optionIndex02
                                  ? "flex"
                                  : "none",
                            }}
                            className="optionPop"
                          >
                            <Box
                              className="optionPopItem GsettingIcon"
                              onClick={() => setStandardSetting(el._id)}
                            >
                              {settingIconBlack}
                              <Typography>Settings</Typography>
                            </Box>
                            <Box
                              className="optionPopItem"
                              onClick={() =>
                                setDeleteWSGroupPop({
                                  groupId: el?._id,
                                  groupName: el?.name,
                                })
                              }
                            >
                              {trashBlack}
                              <Typography>Delete</Typography>
                            </Box>
                          </Box>
                        }
                      </Box>
                      <Typography>{el.name}</Typography>
                    </Box>

                    <Box className="Tabelsel" minWidth={"25%"}>
                      <Typography>{formatDate(el.updated_at)}</Typography>
                    </Box>
                    <Box className="Tabelsel" minWidth={"25%"}>
                      <Typography className="boxed">
                        {el.allow_projects.length}
                      </Typography>
                    </Box>
                  </Box>
                ))}
            </Box>
            <Box className="pagination">
              <Pagination
                paginationData={paginationData}
                onPageChange={onPageChange}
              />
            </Box>
          </Box>
        </>
      ) : addEnhancedGroup ? (
        <AddEnhancedGroup
          addEnhancedGroup={addEnhancedGroup}
          setAddEnhancedGroup={setAddEnhancedGroup}
        />
      ) : addStandardGroup ? (
        <AddStandardGroup
          addStandardGroup={addStandardGroup}
          setAddStandardGroup={setAddStandardGroup}
        />
      ) : standardSetting ? (
        <SettingStandardGroup
          standardSetting={standardSetting}
          setStandardSetting={setStandardSetting}
          getGroupData={getGroupData}
          getEnhancedGroupData={getEnhancedGroupData}
        />
      ) : (
        <Box className="SettingsFormSection">
          <Box
            onClick={() => setEnhancedSetting(false)}
            className="openBtn"
          >
            <img src={backArrow} />
            <Typography className="neonBackText">Back</Typography>
          </Box>

          <Box className="HeadingOptions">
            <Typography variant="h4">
              Settings: Enhanced Groups
            </Typography>
            <Box
              onClick={() => setColorSelectorDrop1(!colorSelectorDrop1)}
              width={"250px"}
              height={"40px"}
              px={2}
              className="statusBox"
            >
              <Typography>Options</Typography>
              <img src={DropArrow} />
              {/* drop */}
              <Box
                sx={{ height: colorSelectorDrop1 ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() =>
                    setColorSelectorDrop1Val("Remove personal data")
                  }
                  className="statusPopItem"
                >
                  <Typography>
                    {<DoDisturbIcon />} Remove personal data
                  </Typography>
                </Box>
                <Box
                  onClick={() => setColorSelectorDrop1Val("Delete")}
                  className="statusPopItem"
                >
                  <Typography>{<DeleteOutlineIcon />} Delete</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Typography className="greybox">
                Group ID: <span>{selectedEnhancData.group_id}</span>
              </Typography>
            </Grid>
            <Grid item sm={6}>
              <Typography className="greybox">
                Updated:{" "}
                <span>{formatDate(selectedEnhancData.updated_at)}</span>
              </Typography>
            </Grid>
            <Grid item sm={6}>
              <Typography className="greybox">
                Participants: <span>3</span>
              </Typography>
            </Grid>
            <Grid item sm={6}>
              <Typography className="greybox">
                Added: <span>Jun 5, 2023; 12:02 PM</span>
              </Typography>
            </Grid>
          </Grid>

          <Box className="FormField">
            <Typography variant="h5">Basics</Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              placeholder="Enter basics"
              variant="outlined"
              size="small"
              value={selectedEnhancData.name}
              onChange={handleChange}
              name="name"
            />
            <span className="validationerr">{nameError}</span>
          </Box>

          <Box className="FormField">
            <Typography variant="h5">Advanced Options</Typography>
            <Typography variant="h6">Project Visibility</Typography>
            <Typography>
              Restrict the group's visibility to projects within the site
              that are specifically relevant to it.
            </Typography>
            <FormControl sx={{ width: "100%" }} size="small">
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectedEnhancData.restricted_projects}
                onChange={handleChange}
                name="restricted_projects"
                multiple
              >
                <MenuItem value={0}>All Project</MenuItem>
                {projectList.map((list, index) => (
                  <MenuItem value={list._id} key={index}>
                    {list.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box className="FormField">
            <Typography variant="h6">
              Add participants to projects
            </Typography>
            <Typography>
              Participants, whether new or existing, who are assigned to
              this group will be included in the selected projects.
            </Typography>
            <FormControl sx={{ width: "100%" }} size="small">
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectedEnhancData.allow_projects}
                onChange={handleChange}
                name="allow_projects"
                multiple
              >
                <MenuItem value={0}>None</MenuItem>
                {projectList.map((list, index) => (
                  <MenuItem value={list._id} key={index}>
                    {list.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box className="FormField">
            <Typography variant="h6">
              Remove participants from projects
            </Typography>
            <Typography>
              Participants, whether new or existing, who are assigned to
              this group will be excluded in the selected projects.
            </Typography>
            <FormControl sx={{ width: "100%" }} size="small">
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectedEnhancData.remove_projects}
                onChange={handleChange}
                name="remove_projects"
                multiple
              >
                <MenuItem value={0}>None</MenuItem>
                {projectList.map((list, index) => (
                  <MenuItem value={list._id} key={index}>
                    {list.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box className="FormField">
            <Typography variant="h5">Enhanced Group Criteria</Typography>
            {/* <FormControlLabel
              control={
                <Switch
                  checked={selectedEnhancData.match_all_criteria}
                  onChange={handleToggleChange("match_all_criteria")}
                />
              }
              label="Match only if all criteria apply"
            /> */}
            <Box className="groupCriteriaSwitchBox">
              <AntSwitch
                checked={selectedEnhancData.match_all_criteria}
                onChange={handleToggleChange("match_all_criteria")}
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography className="switchText">
                Match only if all criteria apply
              </Typography>
            </Box>
          </Box>
          {console.log("selectedEnhancData", selectedEnhancData)}
          {selectedEnhancData.attributes.map((attribute, index) => (
            <Box>
              <Box className="FormField flexEnd">
                <Button
                  onClick={() => removeAttribute(index)}
                  className="deleteBtn"
                  variant="text"
                >
                  <img src={deleteIcon} />
                </Button>

                <Typography className="enhancedGroupDetBtn">
                  Delete
                </Typography>
              </Box>

              <Box className="FormField">
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <Typography>Attribute: {index + 1}</Typography>
                    {/* <FormControl sx={{ width: "100%" }} size="small">
              <TextField
                fullWidth
                id={`name-${index}`}
                placeholder="Enter name"
                variant="outlined"
                size="small"
                onChange={(e) => handleAttributeChange(index, "name", e.target.value)}
                value={attr.name}
              />
              </FormControl> */}
                    <FormControl sx={{ width: "100%" }} size="small">
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={attribute.name}
                        onChange={(e) =>
                          handleAttributeChange(
                            index,
                            "name",
                            e.target.value
                          )
                        }
                      >
                        {attribute.customFields.map((list, i) => (
                          <MenuItem
                            value={list.question}
                            key={i}
                            onClick={() => getChoose(index, list.choices)}
                          >
                            {list.question}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography>Scale</Typography>
                    {/* <FormControl sx={{ width: "100%" }} size="small">
              <TextField
                fullWidth
                id={`name-${index}`}
                placeholder="Enter name"
                variant="outlined"
                size="small"
                onChange={(e) => handleAttributeChange(index, "scale", e.target.value)}
                value={attr.scale}
              />
              </FormControl> */}
                    <FormControl sx={{ width: "100%" }} size="small">
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={attribute.scale}
                        onChange={(e) =>
                          handleAttributeChange(
                            index,
                            "scale",
                            e.target.value
                          )
                        }
                      >
                        <MenuItem value={`any`} key={1}>
                          Any
                        </MenuItem>
                        <MenuItem value={`all_of_them`} key={2}>
                          All Off Them
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography>Value</Typography>
                    {/* <FormControl sx={{ width: "100%" }} size="small">
              <TextField
                fullWidth
                id={`name-${index}`}
                placeholder="Enter name"
                variant="outlined"
                size="small"
                onChange={(e) => handleAttributeChange(index, "value", e.target.value)}
                value={attr.value}
              />
              </FormControl> */}
                    <FormControl sx={{ width: "100%" }} size="small">
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={attribute.value}
                        onChange={(e) =>
                          handleAttributeChange(
                            index,
                            "value",
                            e.target.value
                          )
                        }
                        multiple
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <p>None</p>;
                          }
                          return selected
                            .map((value) => {
                              const choicesVal =
                                attribute.customFieldsChoices.find(
                                  (ch) => ch.name === value
                                );
                              return choicesVal ? choicesVal.name : value;
                            })
                            .join(",");
                        }}
                      >
                        {attribute.customFieldsChoices &&
                          attribute.customFieldsChoices.map((el, i) => (
                            <MenuItem value={el.name} key={i}>
                              {el.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          ))}

          <Box className="FormField" onClick={addAttribute}>
            <Typography className="attributeText">
              Add Attribute
            </Typography>
          </Box>

          <Box className="FormField">
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <AppBtnOutLine  onClick={() => {
                  //alert("test")
                  setPreviewFilterPop(true);
                  // GoTop();
                }}
                buttonText="Preview Filter" />
              </Grid>
              <Grid item sm={6}>
                <Box className="flexEnd">
                  <AppBtnOutLine
                    buttonText="Cancel"
                    onClick={() => setEnhancedSetting(false)}
                  />
                  <AppBtn
                    buttonText="Save"
                    onClick={updateEnhancedGroup}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  }

  return renderUserLayout(authState?.userType, null, getChildren(), 'workadmin');
}
