import React, { useState, useEffect } from 'react'
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./style.css";

import ResearchersSideMenu from "../../../Components/Researchers/SideMenu";
import CollaboratorSideMenu from "../../../Components/Collaborator/SideMenu";
import { sideHaumIcon, editIconBlack, activeCTrashIcon, activeParticipants } from "../../../Assets/Data";
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useAppSelector } from "../../../lib/store/store";

//images
import iIcon from "../../../Assets/Images/IIcon.png";
import mettingImg from "../../../Assets/Images/mettingImg.png"
import PreparedItemsNoData from "../../../Assets/Images/No data-cuate 1.png";
import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import redTrash from "../../../Assets/Images/redTrash.png";
import plassIcon from "../../../Assets/Images/plassIcon.png";
import imageBanner from "../../../Assets/Images/imageBanner.png"
import uploadIcon from "../../../Assets/Images/uil_upload.png";
import trasIcon from "../../../Assets/Images/fluent_delete-16-regular.png";
import OptionIcon from "../../../Assets/Images/optionIcon.png";
import PrepItem2 from "../../../Assets/Images/prepItem2.png";
import groupIcon from "../../../Assets/Images/groupsIcon.png";
import checkIcon from "../../../Assets/Images/checkG.png";
import redWIcon from "../../../Assets/Images/alert-circle.png";
import ExportWhiteIcon from "../../../Assets/Images/exportWhiteIcon.png"
import Avatar from "../../../Assets/Images/Avatar1.png"
import avatar1 from "../../../Assets/Images/inb1.png"
import avatar2 from "../../../Assets/Images/inb2.png"
import avatar3 from "../../../Assets/Images/inb3.png"
import filterIcon from "../../../Assets/Images/filterIcon.png"
import exportIcon from "../../../Assets/Images/exportIcon.png";
import dropIcon from "../../../Assets/Images/chevron-down.png";
import Option2LIcon from "../../../Assets/Images/Option2LIcon.png";
import editIcon from "../../../Assets/Images/edit 1.png";
import aiWhiteIcon from "../../../Assets/Images/aiIconWhite.png"


//comonents
import { AppBtnOutLine, AppBtn, AppAddBtn } from "../../../Components/AppTools/AppButton"
import { SearchBar, AllDrop6, CalendarDropBtn, LatestCreated, Status } from "../../../Components/AppTools/AppTool"
import UpdateTimeZonePop from '../../../Components/Researchers/UpdateTimeZonePop';
import EditMeetingPop from '../../../Components/Researchers/EditMeetingPop';
import LiveGroupChatStartPop from '../../../Components/Researchers/LiveGroupChatStartPop';
import SystemCheckPop from '../../../Components/Researchers/SystemCheckPop';
import BubbleChart from "../../../Components/AppTools/BubbleChart"
import { PieChart } from '@mui/x-charts/PieChart';
import { ApexChart } from "../../../Components/AppTools/PieChart";
import { BarChart } from "../../../Components/AppTools/BarChart"

//data
import { videoGroupCallData, languages } from "../../../Assets/Data"


export default function LiveChatScheduled({ slideMenu, setSlideMenu, workspaceDrop, setWorkSpaceDrop }) {
  const [nav, setNav] = useState(0)
  const [nav2, setNav2] = useState(0)
  const [nav3, setNav3] = useState(0)
  const [preparedItemsPageVal, setPreparedItemsPageVal] = useState(false)
  const [updateTimeZonePop, setUpdateTimeZonePop] = useState(false);
  const [editMeetingPop, setEditMeetingPop] = useState(false);
  const [prepNavItem, setPrepNavItem] = useState(0)
  const [liveGroupChatStartPop, setLiveGroupChatStartPop] = useState(false);

  const email1 = "";
  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();
  const [workspaceStatusdrop, setWorkspaceStatusdrop] = useState(false);
  const [workspaceStatusDropVal, setworkspaceStatusDropVal] = useState("");
  const [attributeCount, setAttributeCount] = useState(1)
  const [companyStatusDrop, setCompanyStatusDrop] = useState(false);
  const [compantStatusDropVal, setCompantStatusDropVal] = useState("");
  const [image, setImage] = useState(null);
  const [preparedItem, setPreparedItem] = useState(false)
  const [preparedItemOptionPop, setPreparedItemOptionPop] = useState(false)
  const [preparedItemOptionPop2, setPreparedItemOptionPop2] = useState(false)
  const [radio, setRadio] = useState(0)

  const [allDrop6, setAllDrop6] = useState();
  const [allDropVal6, setAllDropVal6] = useState("Activity Options");
  const [calendarDrop, setCalendarDrop] = useState();
  const [calendarDropVal, setCalendarDropVal] = useState("Add to Calendar")
  const [newArrayData, setNewArrayData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [systemCheckPop, setSystemCheckPop] = useState(false);

  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Recent Responses");
  const [latestCDrop2, setLatestDrop2] = useState(false);
  const [latestDropVal2, setLatestDropVal2] = useState("Recent Responses");
  const [lang, setLang] = useState(false)
  const [langVal, setLangVal] = useState()
  const [aiSubnav, setAiSubNav] = useState(0);
  const [exportDrop, setExportDrop] = useState(false);
  const [exportDrop2, setExportDrop2] = useState(false);
  const [exportDrop3, setExportDrop3] = useState(false);
  const [exportDrop4, setExportDrop4] = useState(false);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const authState = useAppSelector(state => state.auth);


  const responseArrya = [
    {
      label: "Order Posteds",
    },
    {
      label: "Recent Replies",
    },
    {
      label: "Most Liked",
    },
    {
      label: "Most Comments",
    },
    {
      label: "Recent Liked",
    },
    {
      label: "Recent Comments",
    },
    {
      label: "Reviewed Posts",
    }
  ];
  const exportItem = ["PNG", "JPEG", "PDF", "SVG"];

  const [series] = useState([{ data: [400, 430, 448, 470, 540] }]);
  const categories = ['Product Reviews', 'Price', 'Brand Reputation', 'Durabilty/Quality', 'Aesthetics'];

  const [series2] = useState([{ data: [400, 430, 448, 470, 540] }]);
  const categories2 = ['Relaxation', 'Great Quote', 'Nostalgia', 'Empowerment'];


  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
  };

  const renderComponents = (value) => {
    let components = [];
    for (let i = 0; i < value; i++) {
      components.push(<Attribute count={i} key={i} />);
    }
    return components;
  };



  const handelDeleteAttribute = () => {
    setAttributeCount(attributeCount - 1)
  }
  const handelAddAttribute = () => {
    setAttributeCount(attributeCount + 1)
  }


  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };


  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };



  const Attribute = ({ count }) => {
    return (
      <Box className="AttributeBox">
        <Box className="delete_btn" onClick={handelDeleteAttribute}>
          <Box>
            <img src={redTrash} />
          </Box>
          <Box>
          <p>{t("commonTxt.delete")}</p>
          </Box>
        </Box>


        <Box className="companiesTopBox">
          <Box className="companyInputBoxWlabel">
            <Typography mb={1} className="inputLabel"> {t("LiveInterview.attribute")}: {count * 1 + 1}</Typography>

            <Box
              className="inputAppDrop"
              onClick={() => setCompanyStatusDrop(!companyStatusDrop)}
            >
              <Typography>
                {compantStatusDropVal || "Age"}
              </Typography>
              <img
                className="PartDropIcon"
                style={{ rotate: companyStatusDrop ? "180deg" : "0deg" }}
                src={DownArrowIcon}
              />
              {/* pop */}
              <Box
                sx={{ height: companyStatusDrop ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setCompantStatusDropVal("Item-A")}
                  className="statusPopItem"
                >
                  <Typography>Item-A</Typography>
                  <Typography>Hello</Typography>
                </Box>
                <Box
                  onClick={() => setCompantStatusDropVal("Item-B")}
                  className="statusPopItem"
                >
                  <Typography>Item-B</Typography>
                </Box>
                <Box
                  onClick={() => setCompantStatusDropVal("Item-C")}
                  className="statusPopItem"
                >
                  <Typography>Item-C</Typography>
                </Box>
                <Box
                  onClick={() => setCompantStatusDropVal("Item-D")}
                  className="statusPopItem"
                >
                  <Typography>Item-D</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* workspace status */}
          <Box className="companyInputBoxWlabel">
            <Typography mb={1} className="inputLabel"> {t("LiveInterview.scale")} </Typography>

            <Box
              className="inputAppDrop"
              onClick={() => setWorkspaceStatusdrop(!workspaceStatusdrop)}
            >
              <Typography>
                {workspaceStatusDropVal || "Less than"}
              </Typography>
              <img
                className="PartDropIcon"
                style={{
                  rotate: workspaceStatusdrop ? "180deg" : "0deg",
                }}
                src={DownArrowIcon}
              />
              {/* pop */}
              <Box
                sx={{ height: workspaceStatusdrop ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setworkspaceStatusDropVal("Item-A")}
                  className="statusPopItem"
                >
                  <Typography>Item-A</Typography>
                </Box>
                <Box
                  onClick={() => setworkspaceStatusDropVal("Item-B")}
                  className="statusPopItem"
                >
                  <Typography>Item-B</Typography>
                </Box>
                <Box
                  onClick={() => setworkspaceStatusDropVal("Item-C")}
                  className="statusPopItem"
                >
                  <Typography>Item-C</Typography>
                </Box>
                <Box
                  onClick={() => setworkspaceStatusDropVal("Item-D")}
                  className="statusPopItem"
                >
                  <Typography>Item-D</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="addressBox">
          <Typography className="companyInputLabel">{t("LiveInterview.value")}</Typography>
          <input
            type="text"
            className="addInput"
            placeholder={t("LiveInterview.value")}
            value="40"
          />
        </Box>

      </Box>
    )
  }


  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  useEffect(() => {
    printItemsByState(activeParticipants, currentPage);
  }, [currentPage]);


  return (
    <>
      <Box className="ResearchersContainer LiveChatRecap">
        <LiveGroupChatStartPop liveGroupChatStartPop={liveGroupChatStartPop} setLiveGroupChatStartPop={setLiveGroupChatStartPop} />
        <UpdateTimeZonePop updateTimeZonePop={updateTimeZonePop} setUpdateTimeZonePop={setUpdateTimeZonePop} />
        <EditMeetingPop editMeetingPop={editMeetingPop} setEditMeetingPop={setEditMeetingPop} />
        <SystemCheckPop systemCheckPop={systemCheckPop} setSystemCheckPop={setSystemCheckPop} />

        {/* side menu */}
        {authState?.userProfile?.is_researcher == 1 ? (<ResearchersSideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop} />) : (<CollaboratorSideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop} />)}

        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
            <Box className="AppNavBtnBox">
              <Box sx={{ width: "30%", minWidth: "196px" }}>
                <AllDrop6
                  drop={allDrop6}
                  setDrop={setAllDrop6}
                  dropVal={allDropVal6}
                  serDropVal={setAllDropVal6}
                />
              </Box>
              <AppBtn buttonText={t("LiveInterview.systemCheckBtn")} onClick={() => setSystemCheckPop(true)} />
            </Box>
          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer interviewSchedule">
            <Box className="NotificationSection">
              <Typography className='EAHeaderText'>{t("liveChatSchedulePage.liveGroupChatHead")}</Typography>


              <Box className="iBox chatScheduleNotify">
                <img src={iIcon} />
                <Typography>{t("liveChatSchedulePage.liveChatScheduleNotify")}</Typography>
              </Box>

              <Box className="schedule_Section_box">
                <Box className="nextMettingBox">
                  <Box className="mettingImgBox"><img src={mettingImg} /></Box>
                  <Box className="mettingTextBox">
                    <Typography className='mettingHeaderText'>Sample Activity</Typography>

                    <Typography className='mettingLabelText'>
                    {t("LiveInterview.ScheduledDate")} :
                      <span> Jul 18, 2023</span>
                    </Typography>
                    <Typography className='mettingLabelText'>
                    {t("LiveInterview.ScheduledTime")} :
                      <span>  11:00 AM</span>
                    </Typography>

                    <Box className="chatBtnBox">
                      <Box sx={{ width: "30%", minWidth: "196px" }}>
                        <CalendarDropBtn
                          drop={calendarDrop}
                          setDrop={setCalendarDrop}
                          dropVal={calendarDropVal}
                          serDropVal={setCalendarDropVal}
                        />
                      </Box>
                      <AppBtn buttonText={t("commonTxt.startMeetingBtn")} onClick={() => setLiveGroupChatStartPop(true)} />
                    </Box>
                  </Box>
                </Box>
              </Box>


              <Box className="scheduleNav">
                <Box onClick={() => setNav(0)} className={nav === 0 ? "scheduleNavItem scheduleNavItemActive" : "scheduleNavItem"}>
                  <Typography>{t("LiveInterview.preparedItemsTab")}</Typography>
                </Box>
                <Box onClick={() => setNav(1)} className={nav === 1 ? "scheduleNavItem scheduleNavItemActive" : "scheduleNavItem"}>
                  <Typography>{t("LiveInterview.participantsTab")}</Typography>
                </Box>
                <Box onClick={() => setNav(2)} className={nav === 2 ? "scheduleNavItem scheduleNavItemActive" : "scheduleNavItem"}>
                  <Typography>{t("projectBasicConfiguration.recapLabel")}</Typography>
                </Box>
                <Box onClick={() => setNav(3)} className={nav === 3 ? "scheduleNavItem scheduleNavItemActive" : "scheduleNavItem"}>
                  <Typography>{t("projectSettingTranscript.projectSettingTranscriptHead")}</Typography>
                </Box>
              </Box>

              {/* Prepared */}
              <Box className="schedule_Section_box" sx={{ display: nav === 0 ? "block" : "none" }}>
                {preparedItemsPageVal ?
                  <Box className="noDataPage">
                    <img src={PreparedItemsNoData} />
                    <Typography className='EAHeaderText'>{t("LiveInterview.preparedItemsHead")}</Typography>
                    <Typography className='noDsubText'>{t("LiveInterview.preparedItemsSubText")}</Typography>
                    <AppAddBtn buttonText={t("commonTxt.addItemsBtn")} onClick={() => setPreparedItem(true)} />
                  </Box> : preparedItem ?
                    <Box className="preparedMainPage">
                      <Typography className='preparedLabel'>1. {t("LiveInterview.addItemHead")}</Typography>
                      <Box className="prepareMainBox">
                        <Box className="prepNav">
                          <Box className={prepNavItem === 0 ? "prepNavItem prepNavItemActive" : "prepNavItem"} onClick={() => setPrepNavItem(0)}>
                            <Typography>{t("LiveInterview.pollTab")}</Typography>
                          </Box>
                          <Box className={prepNavItem === 1 ? "prepNavItem prepNavItemActive" : "prepNavItem"} onClick={() => setPrepNavItem(1)}>
                            <Typography>{t("LiveInterview.messageTab")}</Typography>
                          </Box>
                          <Box className={prepNavItem === 2 ? "prepNavItem prepNavItemActive" : "prepNavItem"} onClick={() => setPrepNavItem(2)}>
                            <Typography>{t("LiveInterview.imageTab")}</Typography>
                          </Box>
                          <Box className={prepNavItem === 3 ? "prepNavItem prepNavItemActive" : "prepNavItem"} onClick={() => setPrepNavItem(3)}>
                            <Typography>{t("LiveInterview.videoTab")}</Typography>
                          </Box>
                          <Box className={prepNavItem === 4 ? "prepNavItem prepNavItemActive" : "prepNavItem"} onClick={() => setPrepNavItem(4)}>
                            <Typography>{t("LiveInterview.fileTab")}</Typography>
                          </Box>
                        </Box>


                        <Box sx={{ display: prepNavItem === 0 ? "block" : "none" }} className="prepareInnerBox">
                          {/* Poll */}
                          <Typography className='eTemPopLabel '>{t("LiveInterview.questionLabel")}</Typography>
                          <Box className="emailInputBox">
                            <Box className="emailInputBoxNav">
                              <Box
                                onClick={() => setMessageTextItalic(!messageTextItalic)}
                                className="emailImgBox"
                              >
                                <img src={IIcon} />
                              </Box>
                              <Box
                                onClick={() => setMessageTextBold(!messageTextBold)}
                                className="emailImgBox"
                              >
                                <img src={BIcon} />
                              </Box>
                              <Box
                                onClick={() => handelTextlineChekc("underline", "message")}
                                className="emailImgBox"
                              >
                                <img src={UnderLineU} />
                              </Box>
                              <Box
                                onClick={() => handelTextlineChekc("line-through", "message")}
                                className="emailImgBox"
                              >
                                <img src={MLineU} />
                              </Box>
                              <Box className="emailImgBox">
                                <img src={AtachMentIcon} />
                              </Box>
                              <Box className="emailImgBox">
                                <img src={Qbox} />
                              </Box>
                              <Box
                                onClick={() => setMessageTextLaOut("start")}
                                className="emailImgBox"
                              >
                                <img src={Line1} />
                              </Box>
                              <Box
                                onClick={() => setMessageTextLaOut("center")}
                                className="emailImgBox"
                              >
                                <img src={Line2} />
                              </Box>
                              <Box
                                onClick={() => setMessageTextLaOut("end")}
                                className="emailImgBox"
                              >
                                <img src={Line3} />
                              </Box>
                              <Box className="emailImgBox">
                                <img src={Line4} />
                              </Box>
                              <Box className="emailImgBox">
                                <img src={emoji} />
                              </Box>
                            </Box>
                            <textarea
                              style={{
                                textAlign: messageTextLaOut,
                                fontStyle: messageTextItalic ? "italic" : "normal",
                                fontWeight: messageTextBold ? "bold" : "normal",
                                textDecoration: messageTextUnderLine,
                                // textDecoration: messageTextMLine ? "line-through" : null
                              }}
                              className="emailInput"
                              name="emailMessage"
                              value={emailval?.emailMessage || email1}
                              onChange={handelChange}
                            />
                          </Box>

                          <Typography className='eTemPopLabel'>{t("LiveInterview.choicesHead")}</Typography>
                          {renderComponents(attributeCount)}
                          <Box className="addChoiceBox">
                            <Typography className="addAttributeLink" onClick={handelAddAttribute}>{t("commonTxt.addAttributeLink")}</Typography>
                            <Typography className="addAttributeLink">{t("commonTxt.pasteBulkChoicesLink")}</Typography>
                          </Box>
                          <Typography className='eTemPopLabel'>{t("LiveInterview.pollSettingsHead")}</Typography>
                          <Box my={3} className='checkWlabel'>
                            <input className="custom-checkbox" type="checkBox" />
                            <Typography>{t("LiveInterview.pollSettingCheckbox1")}</Typography>
                          </Box>
                          <Box mb={3} className='checkWlabel'>
                            <input className="custom-checkbox" type="checkBox" />
                            <Typography>{t("LiveInterview.pollSettingCheckbox2")}</Typography>
                          </Box>
                          <Box mb={3} className='checkWlabel'>
                            <input className="custom-checkbox" type="checkBox" />
                            <Typography>{t("LiveInterview.pollSettingCheckbox3")}</Typography>
                          </Box>
                        </Box>


                        <Box sx={{ display: prepNavItem === 1 ? "block" : "none" }} className="prepareInnerBox">
                          {/* Message */}
                          <Typography className='eTemPopLabel '>{t("LiveInterview.messageLabel")}</Typography>
                          <Box className="emailInputBox">
                            <Box className="emailInputBoxNav">
                              <Box
                                onClick={() => setMessageTextItalic(!messageTextItalic)}
                                className="emailImgBox"
                              >
                                <img src={IIcon} />
                              </Box>
                              <Box
                                onClick={() => setMessageTextBold(!messageTextBold)}
                                className="emailImgBox"
                              >
                                <img src={BIcon} />
                              </Box>
                              <Box
                                onClick={() => handelTextlineChekc("underline", "message")}
                                className="emailImgBox"
                              >
                                <img src={UnderLineU} />
                              </Box>
                              <Box
                                onClick={() => handelTextlineChekc("line-through", "message")}
                                className="emailImgBox"
                              >
                                <img src={MLineU} />
                              </Box>
                              <Box className="emailImgBox">
                                <img src={AtachMentIcon} />
                              </Box>
                              <Box className="emailImgBox">
                                <img src={Qbox} />
                              </Box>
                              <Box
                                onClick={() => setMessageTextLaOut("start")}
                                className="emailImgBox"
                              >
                                <img src={Line1} />
                              </Box>
                              <Box
                                onClick={() => setMessageTextLaOut("center")}
                                className="emailImgBox"
                              >
                                <img src={Line2} />
                              </Box>
                              <Box
                                onClick={() => setMessageTextLaOut("end")}
                                className="emailImgBox"
                              >
                                <img src={Line3} />
                              </Box>
                              <Box className="emailImgBox">
                                <img src={Line4} />
                              </Box>
                              <Box className="emailImgBox">
                                <img src={emoji} />
                              </Box>
                            </Box>
                            <textarea
                              style={{
                                textAlign: messageTextLaOut,
                                fontStyle: messageTextItalic ? "italic" : "normal",
                                fontWeight: messageTextBold ? "bold" : "normal",
                                textDecoration: messageTextUnderLine,
                                // textDecoration: messageTextMLine ? "line-through" : null
                              }}
                              className="emailInput"
                              name="emailMessage"
                              value={emailval?.emailMessage || email1}
                              onChange={handelChange}
                            />
                          </Box>
                        </Box>

                        <Box sx={{ display: prepNavItem === 2 ? "block" : "none" }} className="prepareInnerBox">

                          <Box mb={5} className="mediaBox">
                            <Box className="mediaBanner">
                              <img src={imageBanner} />
                            </Box>

                            <Box className="mediaInputText">
                              <Box className="textBox">
                                <Typography className="teraText">
                                  Image.png {t("ImageActivity.Uploaded")}
                                </Typography>
                                <Box mb={2}>
                                  <img src={uploadIcon} />
                                  <Typography
                                    ml={2}
                                    onClick={() => {
                                      document.getElementById("fileInput").click();
                                    }}
                                  >
                                    {t("ImageActivity.ChangeImage")}
                                  </Typography>
                                  <input
                                    id="fileInput"
                                    style={{ display: "none" }}
                                    type="file"
                                    onChange={handleFileInputChange}
                                  />
                                </Box>
                                <Box>
                                  <img src={trasIcon} />
                                  <Typography ml={2}>{t("commonTxt.delete")}</Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>


                          <Box className="emailInputBox imgEmailBox">
                            <Box className="emailInputBoxNav">
                              <Box
                                onClick={() => setMessageTextItalic(!messageTextItalic)}
                                className="emailImgBox"
                              >
                                <img src={IIcon} />
                              </Box>
                              <Box
                                onClick={() => setMessageTextBold(!messageTextBold)}
                                className="emailImgBox"
                              >
                                <img src={BIcon} />
                              </Box>
                              <Box
                                onClick={() => handelTextlineChekc("underline", "message")}
                                className="emailImgBox"
                              >
                                <img src={UnderLineU} />
                              </Box>
                              <Box
                                onClick={() => handelTextlineChekc("line-through", "message")}
                                className="emailImgBox"
                              >
                                <img src={MLineU} />
                              </Box>
                              <Box className="emailImgBox">
                                <img src={AtachMentIcon} />
                              </Box>
                              <Box className="emailImgBox">
                                <img src={Qbox} />
                              </Box>
                              <Box
                                onClick={() => setMessageTextLaOut("start")}
                                className="emailImgBox"
                              >
                                <img src={Line1} />
                              </Box>
                              <Box
                                onClick={() => setMessageTextLaOut("center")}
                                className="emailImgBox"
                              >
                                <img src={Line2} />
                              </Box>
                              <Box
                                onClick={() => setMessageTextLaOut("end")}
                                className="emailImgBox"
                              >
                                <img src={Line3} />
                              </Box>
                              <Box className="emailImgBox">
                                <img src={Line4} />
                              </Box>
                              <Box className="emailImgBox">
                                <img src={emoji} />
                              </Box>
                            </Box>

                            <textarea
                              style={{
                                textAlign: messageTextLaOut,
                                fontStyle: messageTextItalic ? "italic" : "normal",
                                fontWeight: messageTextBold ? "bold" : "normal",
                                textDecoration: messageTextUnderLine,
                              }}
                              className="emailInput"
                              name="emailMessage"
                              value={emailval?.emailMessage || email1}
                              onChange={handelChange}
                            />
                          </Box>

                        </Box>

                        <Box sx={{ display: prepNavItem === 3 ? "block" : "none" }} className="prepareInnerBox">
                          <Box className='mediaInputbox upload-area' onClick={() => {
                            document.getElementById("fileInput").click();
                          }}
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}>
                            <img src={plassIcon} />
                            <input
                              id="fileInput"
                              style={{ display: "none" }}
                              type="file"
                              onChange={handleFileInputChange}
                            />
                          </Box>
                          <Typography className='imgUploadLabel'>{t("LiveInterview.uploadVideo")}</Typography>
                          <Box className="searchBarBox">
                            <SearchBar />
                          </Box>
                        </Box>


                        <Box sx={{ display: prepNavItem === 4 ? "block" : "none" }} className="prepareInnerBox">
                          <Box className='mediaInputbox upload-area' onClick={() => {
                            document.getElementById("fileInput").click();
                          }}
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}>
                            <img src={plassIcon} />
                            <input
                              id="fileInput"
                              style={{ display: "none" }}
                              type="file"
                              onChange={handleFileInputChange}
                            />
                          </Box>
                          <Typography className='imgUploadLabel'>{t("LiveInterview.uploadFile")}</Typography>
                          <Box className="searchBarBox">
                            <SearchBar />
                          </Box>
                        </Box>
                        <Box className="workPupBtnBox">
                          <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => setPreparedItem(false)} />
                          <AppBtn buttonText={t("commonTxt.saveBtn")} />
                        </Box>
                      </Box>
                    </Box>
                    :

                    <Box className="preparedItemBox">
                      {/* item1 */}
                      <Box className="prep_item">
                        <Box className="prep_Nav">
                          <Typography className='preparedLabel'>1. Pick your favourite</Typography>
                          <Box className="OptionIcon">
                            <img src={OptionIcon} onClick={() => setPreparedItemOptionPop(!preparedItemOptionPop)} />
                            <Box sx={{ display: preparedItemOptionPop ? "flex" : "none" }} className="prepTopOPop activeCardOptionPop">
                              <Box className="acItem" onClick={() => setPreparedItemOptionPop(false)} >
                                {editIconBlack}
                                <Typography className="acdl">{t("commonTxt.editItemLink")}</Typography>
                              </Box>
                              <Box className="acItem" onClick={() => setPreparedItemOptionPop(false)} >
                                {activeCTrashIcon}
                                <Typography className="acdl">{t("commonTxt.deleteBtn")} </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Typography>{t("LiveInterview.preparedItemText")}</Typography>


                        <Box mt={3} className="redioItem" onClick={() => setRadio(0)}>
                          <Box
                            className={
                              radio === 0
                                ? "customRedio customRedioActive"
                                : "customRedio"
                            }
                          >
                            <Box></Box>
                          </Box>
                          <Typography>Dog</Typography>
                        </Box>

                        <Box mt={3} className="redioItem" onClick={() => setRadio(1)}>
                          <Box
                            className={
                              radio === 1
                                ? "customRedio customRedioActive"
                                : "customRedio"
                            }
                          >
                            <Box></Box>
                          </Box>
                          <Typography> Cat </Typography>
                        </Box>
                      </Box>

                      {/* item 2 */}
                      <Box className="prep_item">
                        <Box className="prep_Nav">
                          <Typography className='preparedLabel'>1. Pick your favourite</Typography>
                          <Box className="OptionIcon">
                            <img src={OptionIcon} onClick={() => setPreparedItemOptionPop2(!preparedItemOptionPop2)} />
                            <Box sx={{ display: preparedItemOptionPop2 ? "flex" : "none" }} className="prepTopOPop activeCardOptionPop">
                              <Box className="acItem" onClick={() => setPreparedItemOptionPop2(false)} >
                                {editIconBlack}
                                <Typography className="acdl">{t("commonTxt.editItemLink")}</Typography>
                              </Box>
                              <Box className="acItem" onClick={() => setPreparedItemOptionPop2(false)} >
                                {activeCTrashIcon}
                                <Typography className="acdl">{t("commonTxt.deleteBtn")} </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>

                        <Box className="prepItemImageBOx">
                          <img src={PrepItem2} />
                        </Box>
                      </Box>
                      <AppAddBtn buttonText={t("commonTxt.addItemsBtn")} onClick={() => setPreparedItem(true)} />
                    </Box>

                }
              </Box>
              {/* Prepared */}
              {/* Participants */}
              <Box className="schedule_Section_box Participants_section" sx={{ display: nav === 1 ? "block" : "none" }}>
                <Box className="companyListNav">
                  <Typography className='pageHeaderText'>{t("LiveInterview.activityParticipantsHead")} </Typography>
                  <Box className="navMenus">
                    <Box className="actTNavBtn">
                      <img src={groupIcon} />
                      <Typography>{t("commonTxt.groupBtn")}</Typography>
                    </Box>
                    <AppBtn buttonText={t("recapPollMultiPage.sendMessage")} />
                  </Box>
                </Box>
                <Box className="workListBox workListBoxRP">
                  <Box className="haderRow" minWidth={"1130px"}>
                    <Box minWidth={"15%"} className="Tabelsel navFsel TabelStatesel">
                      <Typography>{t("projectDirectory.tblName")}</Typography>
                    </Box>
                    <Box minWidth={"12%"} className="Tabelsel Tabelcentersel">
                      <Typography textAlign={"center"}>{t("LiveInterview.tblSystemCheck")}</Typography>
                    </Box>
                    <Box minWidth={"12%"} className="Tabelsel Tabelcentersel">
                      <Typography textAlign={"center"}>{t("projectDirectory.tblStatus")}</Typography>
                    </Box>
                    <Box minWidth={"8%"} className="Tabelsel">
                      <Typography textAlign={"center"}>{t("LiveInterview.tblPosts")}</Typography>
                    </Box>
                    <Box minWidth={"8%"} className="Tabelsel">
                      <Typography textAlign={"center"}>{t("recapPollMultiPage.tblExcerpts")}</Typography>
                    </Box>
                    <Box minWidth={"15%"} className="Tabelsel ">
                      <Typography textAlign={"center"}>{t("LiveInterview.tblStarted")}</Typography>
                    </Box>
                    <Box minWidth={"12%"} className="Tabelsel">
                      <Typography textAlign={"center"}>{t("LiveInterview.tblExited")}</Typography>
                    </Box>
                    <Box minWidth={"12%"} className="Tabelsel">
                      <Typography textAlign={"center"}>{t("LiveInterview.tblDuration")}</Typography>
                    </Box>
                  </Box>

                  {newArrayData &&
                    newArrayData?.map((el, index) => (
                      <Box key={index} className="TabelRow" minWidth={"1120px"}>
                        <Box minWidth={"15%"} className="Tabelsel navFsel">
                          <Box className="tabelAvatar">
                            <img src={el.img} />
                          </Box>
                          <Typography sx={{ cursor: "pointer" }}>{el.name}</Typography>
                        </Box>



                        <Box minWidth={"12%"} className="Tabelsel Tabelcentersel">
                          <img
                            src={
                              el?.systemCheck === "true" ? checkIcon : redWIcon
                            }
                            alt="Toggle"
                          />
                        </Box>



                        <Box minWidth={"12%"} className="Tabelsel Tabelcentersel">
                          {
                            el.status === "Completed" ?
                              <button className='status'>Completed</button> :
                              <Box className="nStartBox"><p>Not Started</p></Box>
                          }
                        </Box>
                        <Box
                          minWidth={"8%"}
                          className="Tabelsel toggleButton"
                        >
                          {el.Post}
                        </Box>
                        <Box minWidth={"8%"} className="Tabelsel toggleButton" >
                          {el.Excerpts}
                        </Box>
                        <Box minWidth={"12%"} className="Tabelsel toggleButton" >
                          {el.started}
                        </Box>
                        <Box minWidth={"12%"} className="Tabelsel toggleButton" >
                          {el.Exited}
                        </Box>
                        <Box minWidth={"12%"} className="Tabelsel toggleButton" >
                          {el.duration}
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Box>
              {/* Participants */}

              {/* -----------Recap---------- */}
              <Box className="schedule_Section_box" sx={{ display: nav === 2 ? "block" : "none" }}>
                <Box className="tabelNav">
                  <Typography className='recapPageSubLabel'>1. {t("liveChatSchedulePage.likeChocolatesHead")}</Typography>
                  <Box width={"28%"} minWidth={"170px"} className="agreSearchB2" >
                    <LatestCreated
                      setLatestDrop={setLatestDrop}
                      latestCDrop={latestCDrop}
                      setLatestDropVal={setLatestDropVal}
                      latestDropVal={latestDropVal}
                      itemAry={responseArrya}
                    />
                  </Box>
                </Box>

                <Box className="bubbleChartBox">
                  <BubbleChart />
                </Box>

                <Box className="tableWithPie">
                  <Box className="pie_Box">
                    <PieChart series={[{ data: [{ value: 10 }, { value: 15 }, { value: 20 }] }]} />
                  </Box>


                  <Box className="tabelBox">
                    <Box mb={6} className="workListBox">
                      <Box className="haderRow" minWidth={"640px"}>
                        <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                          <Typography>{t("liveChatSchedulePage.tblMark")}</Typography>
                        </Box>
                        <Box minWidth={"33%"} className="Tabelsel Tabelcentersel">
                          <Typography>{t("recapeVideoReview.tblResponses")}</Typography>
                        </Box>
                        <Box minWidth={"33%"} className="Tabelsel Tabelcentersel">
                          <Typography>{t("recapeVideoReview.tblPercentage")}</Typography>
                        </Box>
                      </Box>

                      <Box className="TabelRow" minWidth={"640px"}>
                        <Box minWidth={"33%"} className="Tabelsel ">
                          <Box mr={2} className="colorBox" sx={{ background: "rgb(184, 0, 216)" }} ></Box>
                          <Typography>Like</Typography>
                        </Box>
                        <Box minWidth={"33%"} gap={"10px"} className="Tabelsel Tabelcentersel">
                          <Typography>18</Typography>
                        </Box>
                        <Box minWidth={"33%"} className="Tabelsel Tabelcentersel">
                          <Typography>58.2%</Typography>
                        </Box>
                      </Box>

                      <Box className="TabelRow" minWidth={"640px"}>
                        <Box minWidth={"33%"} className="Tabelsel ">
                          <Box mr={2} className="colorBox" sx={{ background: "rgb(2, 178, 175)" }} ></Box>
                          <Typography>Dislike</Typography>

                        </Box>
                        <Box minWidth={"33%"} gap={"10px"} className="Tabelsel Tabelcentersel">
                          <Typography>05</Typography>
                        </Box>
                        <Box minWidth={"33%"} className="Tabelsel Tabelcentersel">
                          <Typography>18.5%</Typography>
                        </Box>
                      </Box>

                      <Box className="TabelRow" minWidth={"640px"}>
                        <Box minWidth={"33%"} className="Tabelsel ">
                          <Box mr={2} className="colorBox" sx={{ background: "rgb(46, 150, 255)" }} ></Box>
                          <Typography>Confused</Typography>

                        </Box>
                        <Box minWidth={"33%"} gap={"10px"} className="Tabelsel Tabelcentersel">
                          <Typography>08</Typography>
                        </Box>
                        <Box minWidth={"33%"} className="Tabelsel Tabelcentersel">
                          <Typography>23.3%</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>



              </Box>

              {/* -----------Transcript---------- */}
              <Box className="schedule_Section_box transcriptSection" sx={{ display: nav === 3 ? "block" : "none" }}>
                <Box className="tabelNav">
                  <Box width={"28%"} minWidth={"170px"} className="agreSearchB2" >
                    <LatestCreated
                      setLatestDrop={setLatestDrop2}
                      latestCDrop={latestCDrop2}
                      setLatestDropVal={setLatestDropVal2}
                      latestDropVal={latestDropVal2}
                      itemAry={responseArrya}
                      icon={ExportWhiteIcon}
                    />
                  </Box>
                  <Box className="aiBtn">
                    <img src={aiWhiteIcon} />
                    <Typography>{t("commonTxt.aIIntegrationBtn")}</Typography>
                  </Box>
                </Box>

                <Box className="videoGroupBox">
                  <Box className="vgUserInfo">
                    <Box className="avatarBox">
                      <img src={Avatar} />
                    </Box>
                    <Box>
                      <Typography>Group Chat 1</Typography>
                      <span>30/07/2023, 1:00 AM (15 Min)</span>
                    </Box>
                  </Box>


                  <Box className="groupVideoCallInnerBox">
                    {
                      videoGroupCallData?.map((el, i) => (
                        <Box key={i} className="videoCallItemBox">
                          <img src={el.img} />
                          <Box className="vcallBName">
                            <span>{el.name}</span>
                          </Box>
                        </Box>
                      ))
                    }

                  </Box>
                </Box>


                <Box className="tabeSection">
                  <Box className="recpSectionNav">
                    <Box
                      onClick={() => setNav2(0)}
                      className={
                        nav2 === 0
                          ? "recpSectionNavItem recpSectionNavItemActive"
                          : "recpSectionNavItem"
                      }
                    >
                      <Typography>{t("viewRecapVideo.transcriptTab")}</Typography>
                    </Box>
                    <Box
                      onClick={() => setNav2(1)}
                      className={
                        nav2 === 1
                          ? "recpSectionNavItem recpSectionNavItemActive"
                          : "recpSectionNavItem"
                      }
                    >
                      <Typography>{t("viewRecapImage.summaryAiTab")}</Typography>
                    </Box>
                    <Box
                      onClick={() => setNav2(2)}
                      className={
                        nav2 === 2
                          ? "recpSectionNavItem recpSectionNavItemActive"
                          : "recpSectionNavItem"
                      }
                    >
                      <Typography>{t("viewRecapImage.AiAnalysis")}</Typography>
                    </Box>
                  </Box>
                </Box>


                {/* Transcript */}
                <Box
                  className="sectionBox"
                  sx={{ display: nav2 === 0 ? "block" : "none" }}>
                  <Box className="transcroptBox">
                    <Box className="transDrop">
                      <Typography className='translateLabel'>{t("viewRecapImage.translateTo")} :</Typography>
                      <Box width={"250px"}><Status label={t("viewRecapImage.selectLanguagePlaceholder")} itemAry={languages} setStatusDrop={setLang} statusDrop={lang} setStatusDropVal={setLangVal} statusDropVal={langVal} /></Box>
                    </Box>
                  </Box>

                  <Box className="transSearchBox">
                    <Box sx={{ width: "88%" }}><SearchBar placeholder={t("viewRecapImage.searchWordPlaceholder")} /></Box>

                    <Box className="filterBtn">
                      <img src={filterIcon} />
                      <p>{t("commonTxt.filters")}</p>
                    </Box>

                  </Box>

                  <Typography>Mary Joined the meeting at <span style={{ color: "#4F33D1" }}>5:30</span></Typography>
                  <Box className="gapBox"></Box>
                  <Typography>Natalia (Host) Joined the meeting at <span style={{ color: "#4F33D1" }}>5:30</span></Typography>

                  <Box className="chatBox">

                    <Box className="chatItem leftChat">
                      <Box className="chatInnerBox">
                        <Box className="chatInfo">
                          <Box className="chatUserImg">
                            <img style={{ width: "20px" }} src={avatar1} />
                            <span>Mary</span>
                          </Box>
                          <span>05:32 PM</span>
                        </Box>
                        <Box sx={{ background: "#A9DAFF" }} className="chatMessage">
                          <Typography>Really first the same reasons that Casey already stated when I'm camping. I just want to enjoy the experience. So I prefer nice sunny days over the old ones or Ugg spray sunscreen at like that sit in the summer in Spring. </Typography>
                        </Box>
                      </Box>
                    </Box>



                    <Box className="chatItem rightChat">
                      <Box className="chatInnerBox">
                        <Box className="chatInfo">
                          <span>Host</span>
                          <Box className="chatUserImg">
                            <img style={{ width: "20px" }} src={avatar1} />
                            <span>05:32 PM</span>
                          </Box>
                        </Box>
                        <Box sx={{ background: "#DCD6F6" }} className="chatMessage">
                          <Typography>Awesome Let's Just Jump Right In then start off. I just have a quick Poll for you. So just give me one second while I display that to you guys. </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box className="chatItem rightChat">
                      <Box className="chatInnerBox">
                        <Box className="chatInfo">
                          <span>Host</span>
                          <Box className="chatUserImg">
                            <img style={{ width: "20px" }} src={avatar1} />
                            <span>05:32 PM</span>
                          </Box>
                        </Box>
                        <Box sx={{ background: "#DCD6F6" }} className="chatMessage">
                          <Typography>You all indicated that one of your favorite outdoor activities is camping. Let's get some more detail on that! What is your favorite time of year to go camping? </Typography>
                        </Box>
                      </Box>
                    </Box>


                    <Box className="lineGraphBox">
                      <Box width={"30%"}>

                        <Box className="lineBar">
                          <Typography className="lineBarLabel">Spring</Typography>
                          <BorderLinearProgress variant="determinate" value={50} />
                        </Box>
                        <Box className="lineBar">
                          <Typography className="lineBarLabel">Summer</Typography>
                          <BorderLinearProgress variant="determinate" value={80} />
                          <img className="lBarAimg" style={{ width: "20px" }} src={avatar1} />
                          <img className="lBarAimg2" style={{ width: "20px" }} src={avatar2} />
                        </Box>
                        <Box className="lineBar">
                          <Typography className="lineBarLabel">Winter</Typography>
                          <BorderLinearProgress variant="determinate" value={20} />
                          <img className="lBarAimg" style={{ width: "20px" }} src={avatar3} />

                        </Box>
                        <Box className="lineBar">
                          <Typography className="lineBarLabel">Fall</Typography>
                          <BorderLinearProgress variant="determinate" value={0} />
                        </Box>
                      </Box>
                    </Box>

                    <Box className="chatItem leftChat">
                      <Box className="chatInnerBox">
                        <Box className="chatInfo">
                          <Box className="chatUserImg">
                            <img style={{ width: "20px" }} src={avatar1} />
                            <span>Mary</span>
                          </Box>
                          <span>05:32 PM</span>
                        </Box>
                        <Box sx={{ background: "#A9DAFF" }} className="chatMessage">
                          <Typography>Kasey_P Yeah, I really like to add the time. I think it looks really cool. And since I don't have a camp in the rain, I'm not too concerned that doesn't have a rainfly vents on the side. I really like a nice touch and to really help things to keep it, you know as cool in the summer is like the size. Typically 1 prefer a larger 10 so that I'm not to cramp and kind of stumbling around too much hot sauce have room for my dog Timber and the quality looks really good. So obviously it's hard to tell just from an image, but I definitely be interested in purchasing it depending on the price.  </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>



                {/* Summary (AI) */}
                <Box
                  className="sectionBox"
                  sx={{ display: nav2 === 1 ? "block" : "none" }}
                >
                  <Box className="transcroptBox">
                    <Box className="transDrop">
                      <Typography className='translateLabel'>{t("viewRecapImage.translateTo")} :</Typography>
                      <Box width={"200px"}><Status label={t("viewRecapImage.selectLanguagePlaceholder")} itemAry={languages} setStatusDrop={setLang} statusDrop={lang} setStatusDropVal={setLangVal} statusDropVal={langVal} /></Box>
                    </Box>

                    <Box>
                      <SearchBar placeholder={t("viewRecapImage.searchWordPlaceholder")} />
                    </Box>
                  </Box>

                  <Box className="rectVideosummeryBox">
                    <Box>
                      <Typography>The Strategic Planning Summit for Project Acceleration unfolded as a multifaceted exploration encompassing critical domains crucial for corporate success. Attendees included key stakeholders, department heads, and subject matter experts, converging to strategize the next phase of the project while considering broader market dynamics.
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                      <Typography sx={{ fontSize: "18px" }}>Market Analysis and Trends:</Typography>
                      <Typography>The Strategic Planning Summit for Project Acceleration unfolded as a multifaceted exploration encompassing critical domains crucial for corporate success. Attendees included key stakeholders, department heads, and subject matter experts, converging to strategize the next phase of the project while considering broader market dynamics.
                      </Typography>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                      <Typography sx={{ fontSize: "18px" }}>Project Acceleration Strategies:</Typography>
                      <Typography>Brainstorming ensued, generating a wealth of innovative strategies to expedite Project Acceleration. Ideas ranged from product feature enhancements to novel customer engagement models, all aligned with the overarching goal of outpacing competitors. The session saw a dynamic exchange of perspectives, fostering a creative environment conducive to breakthrough solutions.
                      </Typography>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                      <Typography sx={{ fontSize: "18px" }}>Resource Allocation and Optimization:
                      </Typography>
                      <Typography>A pivotal segment focused on resource allocation and optimization to ensure maximal efficiency. Teams delved into assessing current resource utilization, identifying potential bottlenecks, and proposing streamlined workflows. Decisions on budget adjustments and personnel reallocation were made with a strategic lens, aiming to fortify the project's execution.
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                      <Typography sx={{ fontSize: "18px" }}>Timeline Adjustments and Risk Mitigation:
                      </Typography>
                      <Typography>Recognizing the dynamic nature of the business landscape, participants engaged in a thorough review of project timelines. Contingency plans were developed to address potential risks and ensure adaptability to unforeseen challenges. The emphasis was on building resilience into the project framework while maintaining alignment with overarching goals.
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                      <Typography sx={{ fontSize: "18px" }}>Actionable Next Steps:
                      </Typography>
                      <Typography>The summit concluded with a synthesis of insights gathered throughout the meeting. A roadmap of actionable next steps emerged, outlining the strategic initiatives to be executed in the immediate and medium-term future. Each step was assigned to responsible parties, with clear timelines and performance indicators established for ongoing evaluation.
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                      <Typography sx={{ fontSize: "18px" }}>Cohesive Vision for Success:
                      </Typography>
                      <Typography>Throughout the meeting, a unifying theme emerged — a shared commitment to project success and market domination. The collaborative spirit fostered a cohesive vision, with all participants leaving with a heightened sense of purpose and confidence in the strategic direction set forth.
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                      <Typography>In essence, the Comprehensive Strategic Planning Summit not only propelled Project Acceleration forward but also equipped the organization with a resilient and adaptive framework, ensuring it remains at the forefront of industry innovation and market leadership.</Typography>
                    </Box>


                  </Box>

                </Box>

                {/* AI Analysis */}
                <Box
                  className="sectionBox"
                  sx={{ display: nav2 === 2 ? "block" : "none" }}
                >
                  <Box className="aiAnalysisSubNav">
                    <Box
                      onClick={() => setAiSubNav(0)}
                      className={
                        aiSubnav === 0 ? "aiSubTab aiSubTabActive" : "aiSubTab"
                      }
                    >
                      <Typography>{t("viewRecapImage.commentAnalysisTab")}</Typography>
                    </Box>
                    <Box
                      onClick={() => setAiSubNav(1)}
                      className={
                        aiSubnav === 1 ? "aiSubTab aiSubTabActive" : "aiSubTab"
                      }
                    >
                      <Typography>{t("viewRecapVideo.videoAnalysisTab")}</Typography>
                    </Box>
                  </Box>

                  <Box
                    className="aiSubSection"
                    sx={{ display: aiSubnav === 1 ? "block" : "none" }}
                  >
                    <Box className="tabelNav">
                      <Typography className="pageTitle">{t("viewRecapImage.sentimentsAnalysisHead")}</Typography>
                      <Box
                        className="exporOpenBtn"
                        onClick={() => setExportDrop(!exportDrop)}
                      >
                        <img src={exportIcon} />
                        <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
                        <img
                          src={dropIcon}
                          style={{ rotate: exportDrop ? "180deg" : "0deg" }}
                        />
                        <Box
                          className="statusPop"
                          sx={{ height: exportDrop ? "auto" : "0px" }}
                        >
                          {exportItem?.map((el, i) => (
                            <Box className="statusPopItem">
                              <p>{el}</p>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>

                    <Box className="pieBox">
                      <ApexChart />
                    </Box>

                    <Box className="chartTableNav">
                      <Box className="chartNavItem">
                        <Typography>{t("commonTxt.hideBtn")}</Typography>
                      </Box>
                      <Box className="chartNavItem">
                        <Typography>{t("commonTxt.groupBtn2")}</Typography>
                      </Box>
                      <Box className="chartNavItem">
                        <Typography>{t("commonTxt.changeColorBtn")}</Typography>
                      </Box>
                    </Box>

                    <Box mb={6} className="workListBox">
                      <Box className="haderRow" minWidth={"955px"}>
                        <Box minWidth={"20%"} className="Tabelsel TabelStatesel">
                          <Typography>{t("recapeVideoReview.tblAction")}</Typography>
                        </Box>
                        <Box minWidth={"15%"} className="Tabelsel">
                          <Typography>{t("recapeVideoReview.tblColor")}</Typography>
                        </Box>
                        <Box minWidth={"30%"} className="Tabelsel TabelStatesel">
                          <Typography>{t("recapeVideoReview.tblTag")}</Typography>
                        </Box>

                        <Box minWidth={"13%"} className="Tabelsel">
                          <Typography>{t("recapeVideoReview.tblResponses")}</Typography>
                        </Box>
                        <Box minWidth={"13%"} className="Tabelsel">
                          <Typography>{t("recapeVideoReview.tblPercentage")}</Typography>
                        </Box>
                      </Box>

                      <Box className="TabelRow" minWidth={"955px"}>
                        <Box
                          minWidth={"20%"}
                          gap={"40px"}
                          className="Tabelsel navFsel navtable"
                        >
                          <img src={Option2LIcon} />
                          <input type="checkBox" className="custom-checkbox" />
                        </Box>
                        <Box minWidth={"15%"} className="Tabelsel Tabelcentersel">
                          <Box
                            className="colorBox"
                            sx={{ background: "#21A1F9" }}
                          ></Box>
                        </Box>
                        <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                          <img src={editIcon} />
                          <Typography>😍</Typography>
                          <Typography>Like</Typography>
                        </Box>
                        <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                          <Typography>18</Typography>
                        </Box>
                        <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                          <Typography>58.2%</Typography>
                        </Box>
                      </Box>

                      <Box className="TabelRow" minWidth={"955px"}>
                        <Box
                          minWidth={"20%"}
                          gap={"40px"}
                          className="Tabelsel navFsel navtable"
                        >
                          <img src={Option2LIcon} />
                          <input type="checkBox" className="custom-checkbox" />
                        </Box>
                        <Box minWidth={"15%"} className="Tabelsel Tabelcentersel">
                          <Box
                            className="colorBox"
                            sx={{ background: "#FF4E72" }}
                          ></Box>
                        </Box>
                        <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                          <img src={editIcon} />
                          <Typography>😟</Typography>
                          <Typography>Dislike</Typography>
                        </Box>
                        <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                          <Typography>05</Typography>
                        </Box>
                        <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                          <Typography>18.5%</Typography>
                        </Box>
                      </Box>

                      <Box className="TabelRow" minWidth={"955px"}>
                        <Box
                          minWidth={"20%"}
                          gap={"40px"}
                          className="Tabelsel navFsel navtable"
                        >
                          <img src={Option2LIcon} />
                          <input type="checkBox" className="custom-checkbox" />
                        </Box>
                        <Box minWidth={"15%"} className="Tabelsel Tabelcentersel">
                          <Box
                            className="colorBox"
                            sx={{ background: "#FFBE28" }}
                          ></Box>
                        </Box>
                        <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                          <img src={editIcon} />
                          <Typography>🤨</Typography>
                          <Typography>Confused</Typography>
                        </Box>
                        <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                          <Typography>08</Typography>
                        </Box>
                        <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                          <Typography>23.3%</Typography>
                        </Box>
                      </Box>
                    </Box>

                    {/* Sentiments Distribution */}
                    <Box className="tabelNav">
                      <Typography className="pageTitle">{t("viewRecapImage.sentimentsDistributionHead")}</Typography>
                      <Box
                        className="exporOpenBtn"
                        onClick={() => setExportDrop2(!exportDrop2)}
                      >
                        <img src={exportIcon} />
                        <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
                        <img
                          src={dropIcon}
                          style={{ rotate: exportDrop2 ? "180deg" : "0deg" }}
                        />
                        <Box
                          className="statusPop"
                          sx={{ height: exportDrop2 ? "auto" : "0px" }}
                        >
                          {exportItem?.map((el, i) => (
                            <Box className="statusPopItem">
                              <p>{el}</p>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                    <Box className="bubbleChartBox">
                      <BubbleChart />
                    </Box>



                    {/* Quotes Analysis */}
                    <Box className="tabelNav">
                      <Typography className="pageTitle">{t("viewRecapImage.quotesAnalysisHead")}</Typography>
                      <Box
                        className="exporOpenBtn"
                        onClick={() => setExportDrop3(!exportDrop3)}
                      >
                        <img src={exportIcon} />
                        <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
                        <img
                          src={dropIcon}
                          style={{ rotate: exportDrop3 ? "180deg" : "0deg" }}
                        />
                        <Box
                          className="statusPop"
                          sx={{ height: exportDrop3 ? "auto" : "0px" }}
                        >
                          {exportItem?.map((el, i) => (
                            <Box className="statusPopItem">
                              <p>{el}</p>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>

                    <Box className="barChart">
                      <BarChart series={series} categories={categories} />
                    </Box>




                    <Box className="tabelNav">
                      <Typography className="pageTitle">{t("viewRecapImage.excerptsHead")}</Typography>
                      <Box
                        className="exporOpenBtn"
                        onClick={() => setExportDrop4(!exportDrop4)}
                      >
                        <img src={exportIcon} />
                        <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
                        <img
                          src={dropIcon}
                          style={{ rotate: exportDrop4 ? "180deg" : "0deg" }}
                        />
                        <Box
                          className="statusPop"
                          sx={{ height: exportDrop4 ? "auto" : "0px" }}
                        >
                          {exportItem?.map((el, i) => (
                            <Box className="statusPopItem">
                              <p>{el}</p>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>

                    <Box className="barChart">
                      <BarChart series={series2} categories={categories2} />
                    </Box>


                    {/* bottom tab section */}
                    <Box className="bottomTabel">

                      <Box className="tabeSection">
                        <Box className="recpSectionNav">
                          <Box
                            onClick={() => setNav3(0)}
                            className={
                              nav3 === 0
                                ? "recpSectionNavItem recpSectionNavItemActive"
                                : "recpSectionNavItem"
                            }
                          >
                            <Typography>{t("viewRecapImage.manualTab")}</Typography>
                          </Box>
                          <Box
                            onClick={() => setNav3(1)}
                            className={
                              nav3 === 1
                                ? "recpSectionNavItem recpSectionNavItemActive"
                                : "recpSectionNavItem"
                            }
                          >
                            <Typography>{t("viewRecapImage.excerptsAiTab")}</Typography>
                          </Box>
                        </Box>
                      </Box>


                      {/* Manual */}
                      <Box sx={{ display: nav3 === 0 ? "block" : "none" }}>
                        <Box className="tabelNav">
                          <Typography className="pageTitle">{t("viewRecapImage.excerptsHead")}</Typography>
                          <Box width={"320px"}><SearchBar placeholder={t("viewRecapImage.searchPlaceholder")} /></Box>
                        </Box>


                        <Box mb={6} className="workListBox">
                          <Box className="haderRow" minWidth={"955px"}>
                            <Box minWidth={"10%"} className="Tabelsel TabelStatesel">
                              <Typography>{t("commonTxt.Uses")}</Typography>
                            </Box>
                            <Box minWidth={"17%"} className="Tabelsel TabelStatesel">
                              <Typography>{t("commonTxt.Codes")}</Typography>
                            </Box>
                            <Box minWidth={"40%"} className="Tabelsel TabelStatesel">
                              <Typography>{t("commonTxt.Description")}</Typography>
                            </Box>
                          </Box>

                          <Box className="TabelRow" minWidth={"955px"}>
                            <Box minWidth={"10%"} className="Tabelsel ">
                              <Box className="UBox" >6</Box>
                            </Box>
                            <Box minWidth={"17%"} gap={"10px"} className="Tabelsel">
                              <Typography>Empowerment</Typography>
                            </Box>
                            <Box minWidth={"40%"} className="Tabelsel Tabelcentersel">
                              <Typography>Verbatim that touches on the theme of “empowerment”</Typography>
                            </Box>
                          </Box>

                          <Box className="TabelRow" minWidth={"955px"}>
                            <Box minWidth={"10%"} className="Tabelsel ">
                              <Box className="UBox" >4</Box>
                            </Box>
                            <Box minWidth={"17%"} gap={"10px"} className="Tabelsel">
                              <Typography>Nostalgia</Typography>
                            </Box>
                            <Box minWidth={"40%"} className="Tabelsel Tabelcentersel">
                              <Typography>Verbatim that touches on the theme of “nostalgia”</Typography>
                            </Box>
                          </Box>

                          <Box className="TabelRow" minWidth={"955px"}>
                            <Box minWidth={"10%"} className="Tabelsel ">
                              <Box className="UBox" >2</Box>
                            </Box>
                            <Box minWidth={"17%"} gap={"10px"} className="Tabelsel">
                              <Typography>Relaxation</Typography>
                            </Box>
                            <Box minWidth={"40%"} className="Tabelsel Tabelcentersel">
                              <Typography>Verbatim that touches on the theme of “relaxation”</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      {/* Excerpts */}
                      <Box sx={{ display: nav3 === 1 ? "block" : "none" }}></Box>
                    </Box>






                  </Box>
                </Box>

































              </Box>







            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
