import React, { useState, useEffect } from "react";
import "./style.css";
import { Box, Typography } from "@mui/material";

import WhiteArrow from "../../../Assets/Images/Wchevron-down.png";
import userHomeBanner from "../../../Assets/Images/userHomeBanner.png";
import Avatar from "../../../Assets/Images/UserHomeAvatar.png";
import consartImg from "../../../Assets/Images/consartImg.png";
import OptionIcon from "../../../Assets/Images/optionIcon.png";
import users from "../../../Assets/Images/usersB.png";
import star from "../../../Assets/Images/Star 2.png";
import redDot from "../../../Assets/Images/RedDot.png";
import BottomBanner from "../../../Assets/Images/Bbanner.png";
import hillMan from "../../../Assets/Images/hillman.png";
import user1 from "../../../Assets/Images/New folder/user1.png";
import user2 from "../../../Assets/Images/New folder/user2.png";
import user3 from "../../../Assets/Images/New folder/user3.png";
import user4 from "../../../Assets/Images/New folder/user4.png";
import user5 from "../../../Assets/Images/New folder/user5.png";
import OfflineDot from "../../../Assets/Images/offlineDot.png";
import sendIcon from "../../../Assets/Images/sendIcon.png";
import lockIcon from "../../../Assets/Images/lockIocn.png";
import editIcon01 from "../../../Assets/Images/edit-2.png";

import { sideHaumIcon, deletSvg, editIcon } from "../../../Assets/Data";

//components
import SideMenu from "../../../Components/Collaborator/SideMenu";
import HorizontalBars from "../../../Components/AppTools/LineChart";
import { GoTop, AllDrop4 } from "../../../Components/AppTools/AppTool";
import { AppAddBtn } from "../../../Components/AppTools/AppButton";
import AddCardPop from "../../../Components/Collaborator/AddCardPop";
import AddCardPopPeoplePop from "../../../Components/Collaborator/AddCardPeoplePop";
import PageSettingPop from "../../../Components/Collaborator/PageSettingsPop";
import HomeEmptyPage from "../../../Components/Collaborator/HomeEmptyPage";
import AddCardMessagePop from "../../../Components/Collaborator/AddCardMessagePop";
import AddCardImagePop from "../../../Components/Collaborator/AddCardImagePop";
import AddCardVideoPop from "../../../Components/Collaborator/AddCardVideoPop";
import AddCardAudioPop from "../../../Components/Collaborator/AddCardAudioPop";
import AddCardFilePop from "../../../Components/Collaborator/AddCardFilePop";
import AddCardActivityPop from "../../../Components/Collaborator/AddCardActivityPop";
import AddCardConversationPop from "../../../Components/Collaborator/AddCardConversationPop";
import AddCardStatisticPop from "../../../Components/Collaborator/AddCardStatisticPop";
import AddPagePop from "../../../Components/Collaborator/AddPagePop";
import DuplicatePagePop from "../../../Components/Collaborator/DuplicatePagePop";
import DeletePagePop from "../../../Components/Collaborator/DeletePagePop";
import WorkspaceHomeEditView from "../../../Components/Collaborator/WorkspaceHomeEditView";

export default function WorkspaceHome({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
}) {
  const [collaborators, setCollaborator] = useState(false);
  const [collaboratorsVal, setCollaboratorVal] = useState();
  const [memBerPop, setMemBerPop] = useState(false);
  const [memBerPopNav, setMemBerPopNav] = useState(0);
  const [selectedCard, setSelectedCard] = useState();
  const [addCardPopPeoplePop, setAddCardPopPeoplePop] = useState(false);
  const [workSHAddCardPop, setWorkSHAddCardPop] = useState(false);

  const data = false;

  const [addCardMessagePop, setAddCardMessagePop] = useState(false);
  const [addCardImagePop, setAddCardImagePop] = useState(false);
  const [addCardVideoPop, setAddCardVideoPop] = useState(false);
  const [addCardAudioPop, setaddCardAudioPop] = useState(false);
  const [addCardFilePop, setAddCardFilePop] = useState(false);
  const [addCardActivityPop, setaddCardActivityPop] = useState(false);
  const [addCardConversationPop, setAddCardConversationPop] = useState(false);
  const [addCardStatisticPop, setAddCardStatisticPop] = useState(false);
  const [duplicatePagePop, setDuplicatePagePop] = useState(false);
  const [deletePagePop, setDeletePagePop] = useState(false);
  const [allDrop3, setAllDrop3] = useState();
  const [allDropVal3, setAllDropVal3] = useState("Page Options");

  const userRow = (img, title) => {
    return (
      <Box className="rOnlUserRow">
        <Box>
          <img src={img} />
        </Box>
        <Typography>{title}</Typography>
      </Box>
    );
  };

  const MemberPop = () => {
    return (
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: memBerPop ? "flex" : "none" }}
        className="workspacePop"
      >
        <Box className="memberPop">
          <Box className="membUserInfo">
            <img src={OfflineDot} className="OfflineDot" />
            <Box className="membAvatar">
              <img src={user3} />
            </Box>

            <Box className="membUserInfoIn">
              <Typography>Name03_P</Typography>
              <span>Online 3 days ago</span>
              <Box>
                <img src={sendIcon} />
                <img src={lockIcon} />
                <img src={editIcon01} />
              </Box>
            </Box>

            <Box className="membUserInfoLast">
              <Typography>Mega</Typography>
              <span>348 points</span>
            </Box>
          </Box>

          <Box className="membTabBox">
            <Box className="membTabNav">
              <Box
                className={
                  memBerPopNav === 0
                    ? "membNavItem membNavItemActive"
                    : "membNavItem"
                }
                onClick={() => setMemBerPopNav(0)}
              >
                <Typography>Custom Profile</Typography>
              </Box>
              <Box
                className={
                  memBerPopNav === 1
                    ? "membNavItem membNavItemActive"
                    : "membNavItem"
                }
                onClick={() => setMemBerPopNav(1)}
              >
                <Typography>Basic Details</Typography>
              </Box>
            </Box>
            <Box
              sx={{ display: memBerPopNav === 0 ? "flex" : "none" }}
              className="membTabCont"
            >
              <Box className="custProw">
                <Typography>Age:</Typography>
                <span>30 years</span>
              </Box>
              <Box className="custProw">
                <Typography>Income:</Typography>
                <span>100,000$</span>
              </Box>
              <Box className="custProw">
                <Typography>Gender:</Typography>
                <span>Female</span>
              </Box>
              <Box className="custProw">
                <Typography>Contact:</Typography>
                <span>9800269509</span>
              </Box>
            </Box>
            <Box
              sx={{ display: memBerPopNav === 1 ? "flex" : "none" }}
              className="membTabCont"
            >
              <Box className="custProw">
                <Typography>Name:</Typography>
                <span>03 Participant</span>
              </Box>
              <Box className="custProw">
                <Typography>Username:</Typography>
                <span>03_P (TP247023)</span>
              </Box>
              <Box className="custProw">
                <Typography>Email:</Typography>
                <span>p03#name@gmail.com</span>
              </Box>
              <Box className="custProw">
                <Typography>Joining:</Typography>
                <span>Aug 06, 2023</span>
              </Box>
              <Box className="custProw">
                <Typography>Groups:</Typography>
                <span>
                  <ul>
                    <li>Age: 25-35</li>
                    <li>Gender: Female</li>
                    <li>Region: West</li>
                    <li>Point Level: Mega</li>
                    <li>Value Profile: Family</li>
                  </ul>
                </span>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const memberItem = (img, title) => {
    return (
      <Box
        onClick={() => {
          setMemBerPop(true);
          GoTop();
        }}
        className="memberRowItem"
      >
        <Box>
          <img src={img} />
        </Box>
        <Typography>{title}</Typography>
      </Box>
    );
  };

  const handelAddCardPopCoutinue = () => {
    console.log("click", selectedCard);
    setWorkSHAddCardPop(false);
    if (selectedCard === "People") {
      setAddCardPopPeoplePop(true);
    } else if (selectedCard === "Message") {
      setAddCardMessagePop(true);
    } else if (selectedCard === "Image") {
      setAddCardImagePop(true);
    } else if (selectedCard === "Videos") {
      setAddCardVideoPop(true);
    } else if (selectedCard === "Audios") {
      setaddCardAudioPop(true);
    } else if (selectedCard === "File") {
      setAddCardFilePop(true);
    } else if (selectedCard === "Activities") {
      setaddCardActivityPop(true);
    } else if (selectedCard === "Conversations") {
      setAddCardConversationPop(true);
    } else if (selectedCard === "Statistics") {
      setAddCardStatisticPop(true);
    }
  };
  const handelAddCardPopPeopleBack = () => {
    setWorkSHAddCardPop(true);
    setAddCardPopPeoplePop(false);
    setAddCardMessagePop(false);
    setAddCardImagePop(false);
    setAddCardVideoPop(false);
    setaddCardAudioPop(false);
    setAddCardFilePop(false);
    setaddCardActivityPop(false);
    setAddCardConversationPop(false);
    setAddCardStatisticPop(false);
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setMemBerPop(false);
    }
  };

  useEffect(() => {
    if (memBerPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [memBerPop]);

  return (
    <>
      <Box className="CollaboratorContainer ">
        {/* side menu */}
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />

        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>

            <Box className="AppNavBtnBox">
              <Box sx={{ width: "30%", minWidth: "196px" }}>
                {" "}
                <AllDrop4
                  drop={allDrop3}
                  setDrop={setAllDrop3}
                  dropVal={allDropVal3}
                  serDropVal={setAllDropVal3}
                />
              </Box>
              <AppAddBtn
                buttonText="Add Card"
                onClick={() => setWorkSHAddCardPop(true)}
              />
            </Box>
          </Box>

          {/* Page container */}
          <Box className="collaboratorsMainBox pageContainer">
            {data ? (
              <HomeEmptyPage />
            ) : allDropVal3 === "Edit Page" ? (
              <WorkspaceHomeEditView
                setWorkSHAddCardPop={setWorkSHAddCardPop}
                workSHAddCardPop={workSHAddCardPop}
                allDropVal3={allDropVal3}
                setAllDropVal3={setAllDropVal3}
              />
            ) : (
              <Box className="userHomeContainer reworkspace">
                {/* Pops */}
                <MemberPop />

                <AddCardPop
                  setWorkSHAddCardPop={setWorkSHAddCardPop}
                  workSHAddCardPop={workSHAddCardPop}
                  setSelectedCard={setSelectedCard}
                  selectedCard={selectedCard}
                  handelAddCardPopCoutinue={handelAddCardPopCoutinue}
                />

                <AddCardPopPeoplePop
                  addCardPopPeoplePop={addCardPopPeoplePop}
                  setAddCardPopPeoplePop={setAddCardPopPeoplePop}
                  handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
                />

                <PageSettingPop
                  allDropVal3={allDropVal3}
                  setAllDropVal3={setAllDropVal3}
                  setDuplicatePagePop={setDuplicatePagePop}
                  setDeletePagePop={setDeletePagePop}
                />

                <AddCardMessagePop
                  addCardMessagePop={addCardMessagePop}
                  setAddCardMessagePop={setAddCardMessagePop}
                  handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
                />

                <AddCardImagePop
                  addCardImagePop={addCardImagePop}
                  setAddCardImagePop={setAddCardImagePop}
                  handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
                />

                <AddCardVideoPop
                  addCardVideoPop={addCardVideoPop}
                  setAddCardVideoPop={setAddCardVideoPop}
                  handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
                />

                <AddCardAudioPop
                  addCardAudioPop={addCardAudioPop}
                  setaddCardAudioPop={setaddCardAudioPop}
                  handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
                />

                <AddCardFilePop
                  addCardFilePop={addCardFilePop}
                  setAddCardFilePop={setAddCardFilePop}
                  handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
                />

                <AddCardActivityPop
                  addCardActivityPop={addCardActivityPop}
                  setaddCardActivityPop={setaddCardActivityPop}
                  handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
                />

                <AddCardConversationPop
                  addCardConversationPop={addCardConversationPop}
                  setAddCardConversationPop={setAddCardConversationPop}
                  handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
                />

                <AddCardStatisticPop
                  addCardStatisticPop={addCardStatisticPop}
                  setAddCardStatisticPop={setAddCardStatisticPop}
                  handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
                />

                <AddPagePop
                  allDropVal3={allDropVal3}
                  setAllDropVal3={setAllDropVal3}
                />

                <DuplicatePagePop
                  duplicatePagePop={duplicatePagePop}
                  setDuplicatePagePop={setDuplicatePagePop}
                  setAllDropVal3={setAllDropVal3}
                />

                <DeletePagePop
                  deletePagePop={deletePagePop}
                  setDeletePagePop={setDeletePagePop}
                  setAllDropVal3={setAllDropVal3}
                />

                <Box className="uhwelcomeBanner">
                  <img src={userHomeBanner} />
                  <Box
                    sx={{ position: "relative" }}
                    onClick={() => setCollaborator(!collaborators)}
                  >
                    <Typography className="uhwelbannerText" mr={1}>
                      {collaboratorsVal || "Welcome Collaborator!"}
                    </Typography>
                    <img src={WhiteArrow} />

                    <Box
                      sx={{ display: collaborators ? "flex" : "none" }}
                      className="researchPop"
                    >
                      <Box
                        className="researchPopItem"
                        onClick={() =>
                          setCollaboratorVal("Welcome Collaborator!")
                        }
                      >
                        <Typography className="researchPopItemText">
                          Welcome Collaborator!
                        </Typography>
                      </Box>
                      <Box
                        className="researchPopItem"
                        onClick={() =>
                          setCollaboratorVal("Collaborators Corner")
                        }
                      >
                        <Typography className="researchPopItemText">
                          Collaborators Corner
                        </Typography>
                      </Box>
                      <Box
                        className="researchPopItem"
                        onClick={() => setCollaboratorVal("Observers Corner")}
                      >
                        <Typography className="researchPopItemText">
                          Observers Corner
                        </Typography>
                      </Box>
                      <Box
                        className="researchPopItem"
                        onClick={() =>
                          setCollaboratorVal("Welcome Participants")
                        }
                      >
                        <Typography className="researchPopItemText">
                          Welcome Participants
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box className="userHMBox3">
                  <Box className="userHMBoxItem userhAvatarBox homeCard">
                    <Box className="AvartimgBox">
                      <img src={Avatar} />
                    </Box>
                    <Typography className="userHMText">
                      Welcome User Name
                    </Typography>
                    <Typography className="userHNText">
                      Lorem ipsum dolor sit amet consect etur. Ac quisque
                      tristique dignissim non. Erat id ipsum sem non auctor.
                      Elit non massa quis faucibus vel don sapien felis.{" "}
                    </Typography>
                  </Box>

                  <Box className="userHMBoxItem lineChart">
                    <HorizontalBars />
                  </Box>

                  <Box className="userHMBoxItem userHConsart">
                    <Box className="conImgBox">
                      <img src={consartImg} />
                    </Box>
                    <Box className="userHCTextBox">
                      <Typography sx={{ fontSize: "20px" }}>Welcome</Typography>
                      <img src={OptionIcon} />
                    </Box>
                    <Box className="userHCTextBox">
                      <Box>
                        <Typography sx={{ fontSize: "16px", color: "#98A2B3" }}>
                          Aug 02 - Aug 03
                        </Typography>
                        <Box
                          sx={{ background: "#12B76A" }}
                          className="ActiveStatus"
                        ></Box>
                      </Box>
                      <Box>
                        <img src={users} />
                        <Typography
                          ml={1}
                          sx={{ fontSize: "20px", color: "#98A2B3" }}
                        >
                          11
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box className="userHMBox3">
                  <Box className="userHMBoxItem ConversationsBox">
                    <Typography className="ConversationsBoxHText">
                      Conversations
                    </Typography>

                    <Box className="convChatItem">
                      <Box mb={0.8}>
                        <Typography>Recent Trekking Outings</Typography>
                        <Box className="iconBox">
                          <img
                            style={{ width: "20px", height: "20px" }}
                            src={star}
                          />
                          <img
                            style={{ width: "12px", height: "12px" }}
                            src={redDot}
                          />
                        </Box>
                      </Box>
                      <Box>
                        <Typography className="convSubText">
                          (Outdoor Tips)
                        </Typography>
                        <Box className="viewBtn">
                          <Typography>View</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="convChatItem">
                      <Box mb={0.8}>
                        <Typography>Your Trekking must haves</Typography>
                        <Box className="iconBox">
                          <img
                            style={{ width: "20px", height: "20px" }}
                            src={star}
                          />
                          <img
                            style={{ width: "12px", height: "12px" }}
                            src={redDot}
                          />
                        </Box>
                      </Box>
                      <Box>
                        <Typography className="convSubText">
                          (Equipment Zone)
                        </Typography>
                        <Box className="viewBtn">
                          <Typography>View</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="convChatItem">
                      <Box mb={0.8}>
                        <Typography>Meal Hacks</Typography>
                        <Box className="iconBox">
                         
                          <img
                            style={{ width: "12px", height: "12px" }}
                            src={redDot}
                          />
                        </Box>
                      </Box>
                      <Box>
                        <Typography className="convSubText">
                          (Outdoor Tips)
                        </Typography>
                        <Box className="viewBtn">
                          <Typography>View</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="convChatItem">
                      <Box mb={0.8}>
                        <Typography>Things to keep mind</Typography>
                        <Box className="iconBox">
                         
                       
                        </Box>
                      </Box>
                      <Box>
                        <Typography className="convSubText">
                          (Outdoor Tips)
                        </Typography>
                        <Box className="viewBtn">
                          <Typography>View</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box className="bottomBannerBox">
                    <img src={BottomBanner} />
                  </Box>
                </Box>

                <Box className="userHMBox3">
                  <Box className="userHMBoxItem keyDataBox">
                    <Typography className="ConversationsBoxHText">
                      Key Dates
                    </Typography>
                    <Box className="keyDataRow">
                      <Typography>Monday</Typography>
                      <span>Introduction Task</span>
                    </Box>
                    <Box className="keyDataRow">
                      <Typography>Tuesday</Typography>
                      <span>Introduction to Task</span>
                    </Box>
                    <Box className="keyDataRow">
                      <Typography>Wednesday</Typography>
                      <span>Task experience</span>
                    </Box>
                    <Box className="keyDataRow">
                      <Typography>Thursday</Typography>
                      <span>Task experience</span>
                    </Box>
                    <Box className="keyDataRow">
                      <Typography>Friday</Typography>
                      <span>Introduction to Task</span>
                    </Box>
                    <Box className="keyDataRow">
                      <Typography>Saturday</Typography>
                      <span>Task experience</span>
                    </Box>
                    <Box className="keyDataRow">
                      <Typography>Sunday</Typography>
                      <span>Introduction to Task</span>
                    </Box>
                  </Box>

                  <Box className="userHMBoxItem">
                    <img src={hillMan} className="hillManImg" />
                  </Box>
                  <Box className="userHMBoxItem RecentlyOnlineBox">
                    <Typography className="ConversationsBoxHText">
                      Recently Online
                    </Typography>
                    {userRow(user1, "Participant01 FullName")}
                    {userRow(user2, "Participant02 FullName")}
                    {userRow(user3, "Participant03 FullName")}
                    {userRow(user4, "Participant04 FullName")}
                    {userRow(user5, "Participant05 FullName")}
                    <Box className="pageNum">
                      <Box className="pageNumItem pageNumActive">
                        <p>1</p>
                      </Box>
                      <Box className="pageNumItem">
                        <p>2</p>
                      </Box>
                      <Box className="pageNumItem">
                        <p>3</p>
                      </Box>
                      <Box className="pageNumItem">
                        <p>4</p>
                      </Box>
                      <Box className="pageNumItem">
                        <p>5</p>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box className="userHMBox3 userHMBox4">
                  <Box className="userHMBoxItem memberBox1">
                    <Typography className="ConversationsBoxHText">
                      Tera Members
                    </Typography>
                    <Box mb={2} className="memberRow">
                      {memberItem(user1, "Name01")}
                      {memberItem(user2, "Name02")}
                      {memberItem(user3, "Name03")}
                    </Box>
                    <Box className="memberRow">
                      {memberItem(user4, "Name04")}
                      {memberItem(user5, "Name05")}
                    </Box>
                  </Box>

                  <Box className="userHMBoxItem memberBox2">
                    <Typography className="ConversationsBoxHText">
                      Giga Members
                    </Typography>
                    <Box mb={2} className="memberRow">
                      {memberItem(user1, "Name01")}
                      {memberItem(user2, "Name02")}
                      {memberItem(user3, "Name03")}
                    </Box>
                    <Box className="memberRow">
                      {memberItem(user4, "Name04")}
                      {memberItem(user5, "Name05")}
                    </Box>
                  </Box>

                  <Box className="userHMBoxItem memberBox3">
                    <Typography className="ConversationsBoxHText">
                      Mega Members
                    </Typography>
                    <Box mb={2} className="memberRow">
                      {memberItem(user1, "Name01")}
                      {memberItem(user2, "Name02")}
                      {memberItem(user3, "Name03")}
                    </Box>
                    <Box className="memberRow">
                      {memberItem(user4, "Name04")}
                      {memberItem(user5, "Name05")}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
