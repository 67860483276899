import React, { useEffect, useState, useRef } from "react";
import { Box, Typography } from "@mui/material";

// Images
import arrow from "../../../Assets/Images/arrow.png";
import { useTranslation } from "react-i18next";

//components
import {
  SearchResearchersData,
  SearchCollaboratorData,
  SearchObserverData,
} from "../../../Assets/Data";
import DeleteResearcherPop from "../../Admin/DeleteResearcherPop";
import DeleteCollaboratorPop from "../../Admin/DeleteCollaboratorPop";
import DeleteObserverPop from "../../Admin/DeleteObserverPop";

import DummyUser from "../../../Assets/Images/user-dummy.png";

import {
  editIconBlack,
  xCircleBlack,
  trashBlack,
  supportLoginBlack,
} from "../../../Assets/Data";
import { formatDate, viewImage } from "../../../lib/helpers";
import Pagination from "../../AppTools/Pagination";
import { API_BASE_URL } from "../../../env";
import axios from "../../../lib/axios";
import SupportLoginParticipantPop from "../../Admin/SupportLoginPop";
import DeactivateAdminListPop from "../DeactivateAdminListPop";

export default function AllAdminList({
  EditAdminSection,
  setEditAdminSection,
  researcherList,
  collaboratorList,
  observerList,
  selectFilterbyRole,
  setReloadList,
  reloadList,
  setUpdateWSAdmin,
  setWorkspaceUserType,
  searchInResearcherList,
  searchInCollaboratorList,
  searchInOnserverList,
  isDeactivated,
  rpaginationData,
  obRpaginationData,
  cRpaginationData,
  onResearcgerPageChange,
  onObserverPageChange,
  onCollaboratorPageChange
}) {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const [optionIndex, setOptionIndex] = useState();
  const [workSpaceOption, setWorkspaceOption] = useState(false);
  const [optionIndex02, setOptionIndex02] = useState();
  const [workSpaceOption02, setWorkspaceOption02] = useState(false);

  const [optionIndex03, setOptionIndex03] = useState();
  const [workSpaceOption03, setWorkspaceOption03] = useState(false);
  const [deleteResearcherPop, setDeleteResearcherPop] = useState(false);
  const [deleteCollaboratorPop, setDeleteCollaboratorPop] = useState(false);
  const [deleteObserverPop, setDeleteObserverPop] = useState(false);
  const [supportLoginPop, setSupportLoginPop] = useState(false);
  const [deactivatePop, setDeactivatePop] = useState(false);
  const [deleteResearchersListData, setDeleteResearchersListData] = useState("");
  const [deleteCollaboratorsListData, setDeleteCollaboratorListData] = useState("");
  const [deleteObserversListData, setDeleteObserverListData] = useState("");

  const [sendParticularUserData, setSendParticularUserData] = useState();

  let img = SearchCollaboratorData[0].img
  let img1 = SearchCollaboratorData[1].img
  let img2 = SearchCollaboratorData[2].img

let researcherData = '';
let collaboratorData = '';
let observerData = ''

if(selectFilterbyRole === ''){
  researcherData = searchInResearcherList;
  collaboratorData = searchInCollaboratorList
  observerData = searchInOnserverList
}
else if(selectFilterbyRole === "researcher"){
  researcherData = searchInResearcherList;
}
else if(selectFilterbyRole === "collaborator"){
  collaboratorData = searchInCollaboratorList
}
else if(selectFilterbyRole === "observer"){
  observerData = searchInOnserverList
}

const handleDeleteResearchers = (val) => {
  setDeleteResearchersListData(val);
};

const handleDeleteCollaborator = (val) => {
  setDeleteCollaboratorListData(val);
};

const handleDeleteObserver = (val) => {
  setDeleteObserverListData(val);
};

  return (
    <>
      <Box className="SearchResultSection">
        <DeleteResearcherPop
          deleteResearcherPop={deleteResearcherPop}
          setDeleteResearcherPop={setDeleteResearcherPop}
          deleteResearchersListData={deleteResearchersListData}
          setReloadList={setReloadList}
        />
        <DeleteCollaboratorPop
          deleteCollaboratorPop={deleteCollaboratorPop}
          setDeleteCollaboratorPop={setDeleteCollaboratorPop}
          deleteCollaboratorsListData={deleteCollaboratorsListData}
          setReloadList={setReloadList}
        />
        <DeleteObserverPop
          deleteObserverPop={deleteObserverPop}
          setDeleteObserverPop={setDeleteObserverPop}
          deleteObserversListData={deleteObserversListData}
          setReloadList={setReloadList}
        />
        <SupportLoginParticipantPop 
          setSupportLoginPop={setSupportLoginPop}
          supportLoginPop={supportLoginPop}
          sendParticularUserData={sendParticularUserData}
        />
        <DeactivateAdminListPop 
          setDeactivatePop={setDeactivatePop}
          deactivatePop={deactivatePop}
          sendParticularUserData={sendParticularUserData}
          setReloadList={setReloadList}
          isDeactivated={isDeactivated}
        />
        {researcherData &&
          <Box>
        <Typography variant="h4">{t("admin.researcher")}</Typography>
        {/* table */}
        
        <Box className="workListBox">
          <Box className="haderRow" minWidth={"980px"}>
            <Box
              className="Tabelsel navFsel"
              minWidth={"290px"}
              sx={{ justifyContent: "flex-start !important" }}
            >
              <Typography>{t("admin.userName")}</Typography>
            </Box>
            <Box
              className="Tabelsel"
              minWidth={"271px"}
              sx={{ justifyContent: "flex-start !important" }}
            >
              <Typography>{t("admin.email")}</Typography>
            </Box>
            <Box
              className="Tabelsel"
              minWidth={"271px"}
              sx={{ justifyContent: "flex-start !important" }}
            >
              <Typography>
                {t("admin.added")}; {t("admin.updated")}
              </Typography>
            </Box>
            <Box
              className="Tabelsel"
              minWidth={"271px"}
              sx={{ justifyContent: "flex-start !important" }}
            >
              <Typography>{t("admin.lastVisit")}</Typography>
            </Box>
          </Box>
          {
          researcherData &&
            researcherData?.map((el, index) => (
              <Box key={index} className="TabelRow" minWidth={"980px"}>
                <Box className="Tabelsel navFsel navtable" minWidth={"290px"}>
                  <Box
                    id="dot"
                    onClick={() => {
                      setWorkspaceOption(!workSpaceOption);
                      setOptionIndex(index);
                    }}
                    className={
                      workSpaceOption && index === optionIndex
                        ? "dot dotActive"
                        : "dot"
                    }
                  >
                    {
                      <Box
                        sx={{
                          display:
                            workSpaceOption && index === optionIndex
                              ? "flex"
                              : "none",
                          width: "180px !important",
                        }}
                        className="optionPop"
                      >
                        <Box className="optionPopItem" onClick={()=> {
                          setSupportLoginPop(true);
                          setSendParticularUserData(el)
                        }}>
                          {supportLoginBlack}
                          <Typography>{t("admin.supportLogin")}</Typography>
                        </Box>
                        {!isDeactivated ? (
                        <Box className="optionPopItem" onClick={()=> {
                          setDeactivatePop(true);
                          setSendParticularUserData(el)
                        }}>
                          {xCircleBlack}
                          <Typography>{t("admin.deactivate")}</Typography>
                        </Box> ) : (
                        <Box className="optionPopItem" onClick={()=> {
                          setDeactivatePop(true);
                          setSendParticularUserData(el)
                        }}>
                          {xCircleBlack}
                          <Typography>Activate</Typography>
                        </Box> )
                        }
                        <Box
                          className="optionPopItem"
                          onClick={() => {
                            setEditAdminSection(true);
                            setUpdateWSAdmin(el?._id)
                            setWorkspaceUserType('researchers')
                          }}
                        >
                          {editIconBlack}
                          <Typography>{t("admin.edit")}</Typography>
                        </Box>
                        <Box
                          className="optionPopItem"
                          onClick={() => {
                            setDeleteResearcherPop(true);
                            handleDeleteResearchers(el);
                          }}
                        >
                          {trashBlack}
                          <Typography>{t("admin.delete")}</Typography>
                        </Box>
                      </Box>
                    }
                  </Box>
                  <Box className="tabelAvatar">
                    {el?.profile_pic == "undefined" || el?.profile_pic == null ? (
                      <img src={DummyUser} />
                    ) : (
                      <img src={viewImage(el?.profile_pic)} />
                    )}
                  </Box>
                  <Typography>
                    {el.name}
                    <span className="dblock subtitle">{el.username}</span>
                  </Typography>
                </Box>
                <Box
                  className="Tabelsel TabelselItemCenter"
                  minWidth={"271px"}
                  sx={{ justifyContent: "flex-start !important" }}
                >
                  <Typography>{el.email}</Typography>
                </Box>
                <Box
                  className="Tabelsel TabelselItemCenter"
                  minWidth={"271px"}
                  sx={{ justifyContent: "flex-start !important" }}
                >
                  <Typography>
                    {formatDate(el.created_at)} <span className="dblock"></span>
                  </Typography>
                </Box>
                <Box
                  className="Tabelsel TabelselItemCenter"
                  minWidth={"271px"}
                  sx={{ justifyContent: "flex-start !important" }}
                >
                  <Typography>{formatDate(el.updated_at)}</Typography>
                </Box>
              </Box>
            ))}
        </Box>


        <Box className='pagination'>
          <Pagination
            paginationData={rpaginationData}
            onPageChange={onResearcgerPageChange}
          />
        </Box>
        </Box>}

        {collaboratorData &&
          <Box>
        <Typography variant="h4" sx={{ marginTop:selectFilterbyRole? "0px":"30px" }}>
          {t("admin.collaborator")}
        </Typography>
        {/* table */}
        <Box className="workListBox">
          <Box className="haderRow" minWidth={"980px"}>
            <Box
              className="Tabelsel navFsel"
              minWidth={"290px"}
              sx={{ justifyContent: "flex-start !important" }}
            >
              <Typography>{t("admin.userName")}</Typography>
            </Box>
            <Box
              className="Tabelsel"
              minWidth={"271px"}
              sx={{ justifyContent: "flex-start !important" }}
            >
              <Typography>{t("admin.email")}</Typography>
            </Box>
            <Box
              className="Tabelsel"
              minWidth={"230px"}
              sx={{ justifyContent: "flex-start !important" }}
            >
              <Typography>
                {t("admin.added")}; {t("admin.updated")}
              </Typography>
            </Box>
            <Box
              className="Tabelsel"
              minWidth={"230px"}
              sx={{ justifyContent: "flex-start !important" }}
            >
              <Typography>{t("admin.lastVisit")}</Typography>
            </Box>
          </Box>
          {collaboratorData &&
            collaboratorData?.map((el, index02) => (

              <Box key={index02} className="TabelRow" minWidth={"980px"}>
                <Box className="Tabelsel navFsel navtable" minWidth={"290px"}>
                  <Box
                    id="dot2"
                    onClick={() => {
                      setWorkspaceOption02(!workSpaceOption02);
                      setOptionIndex02(index02);
                    }}
                    className={
                      workSpaceOption02 && index02 === optionIndex02
                        ? "dot dotActive"
                        : "dot"
                    }
                  >
                    {
                      <Box
                        sx={{
                          display:
                            workSpaceOption02 && index02 === optionIndex02
                              ? "flex"
                              : "none",
                          width: "180px !important",
                        }}
                        className="optionPop"
                      >
                        <Box className="optionPopItem" onClick={()=> {
                          setSupportLoginPop(true);
                          setSendParticularUserData(el)
                        }}>
                          {supportLoginBlack}
                          <Typography>{t("admin.supportLogin")}</Typography>
                        </Box>
                        {!isDeactivated ? (
                        <Box className="optionPopItem" onClick={()=> {
                          setDeactivatePop(true);
                          setSendParticularUserData(el)
                        }}>
                          {xCircleBlack}
                          <Typography>{t("admin.deactivate")}</Typography>
                        </Box> ) : (
                        <Box className="optionPopItem" onClick={()=> {
                          setDeactivatePop(true);
                          setSendParticularUserData(el)
                        }}>
                          {xCircleBlack}
                          <Typography>Activate</Typography>
                        </Box>)
                        }
                        <Box
                          className="optionPopItem"
                          onClick={() => {
                            setEditAdminSection(true);
                            setUpdateWSAdmin(el?._id);
                            setWorkspaceUserType('collaborators');
                          }}
                        >
                          {editIconBlack}
                          <Typography>{t("admin.edit")}</Typography>
                        </Box>
                        <Box
                          className="optionPopItem"
                          onClick={() => {
                            setDeleteCollaboratorPop(true);
                            handleDeleteCollaborator(el);
                          }}
                        >
                          {trashBlack}
                          <Typography>{t("admin.delete")}</Typography>
                        </Box>
                      </Box>
                    }
                  </Box>
                  <Box className="tabelAvatar">
                    {el?.profile_pic == "undefined" || el?.profile_pic == null ? (
                      <img src={DummyUser} />
                    ) : (
                      <img src={viewImage(el?.profile_pic)} />
                    )}
                  </Box>
                  <Typography>
                    {el.name}
                    <span className="dblock subtitle">{el.username}</span>
                  </Typography>
                </Box>
                <Box
                  className="Tabelsel TabelselItemCenter"
                  minWidth={"271px"}
                  sx={{ justifyContent: "flex-start !important" }}
                >
                  <Typography>{el.email}</Typography>
                </Box>
                <Box
                  className="Tabelsel TabelselItemCenter"
                  minWidth={"230px"}
                  sx={{ justifyContent: "flex-start !important" }}
                >
                  <Typography>
                    {formatDate(el.created_at)} <span className="dblock"></span>
                  </Typography>
                </Box>
                <Box
                  className="Tabelsel TabelselItemCenter"
                  minWidth={"230px"}
                  sx={{ justifyContent: "flex-start !important" }}
                >
                  <Typography>{formatDate(el.updated_at)}</Typography>
                </Box>
              </Box>
            ))}
        </Box>
        <Box className='pagination'>
          <Pagination
            paginationData={cRpaginationData}
            onPageChange={onCollaboratorPageChange}
          />
        </Box>
        </Box>}

        {observerData &&
          <Box>
        <Typography variant="h4" sx={{ marginTop:selectFilterbyRole? "0px":"30px" }}>
          Observer
        </Typography>

        {/* table */}
        <Box className="workListBox">
          <Box className="haderRow" minWidth={"980px"}>
            <Box
              className="Tabelsel navFsel"
              minWidth={"290px"}
              sx={{ justifyContent: "flex-start !important" }}
            >
              <Typography>{t("admin.userName")}</Typography>
            </Box>
            <Box
              className="Tabelsel"
              minWidth={"230px"}
              sx={{ justifyContent: "flex-start !important" }}
            >
              <Typography>{t("admin.email")}</Typography>
            </Box>
            <Box
              className="Tabelsel"
              minWidth={"230px"}
              sx={{ justifyContent: "flex-start !important" }}
            >
              <Typography>
                {t("admin.added")}; {t("admin.updated")}
              </Typography>
            </Box>
            <Box
              className="Tabelsel"
              minWidth={"230px"}
              sx={{ justifyContent: "flex-start !important" }}
            >
              <Typography>{t("admin.lastVisit")}</Typography>
            </Box>
          </Box>
          {observerData &&
            observerData?.map((el, index03) => (
              <Box key={index03} className="TabelRow" minWidth={"980px"}>
                <Box className="Tabelsel navFsel navtable" minWidth={"290px"}>
                  <Box
                    id="dot3"
                    onClick={() => {
                      setWorkspaceOption03(!workSpaceOption03);
                      setOptionIndex03(index03);
                    }}
                    className={
                      workSpaceOption03 && index03 === optionIndex03
                        ? "dot dotActive"
                        : "dot"
                    }
                  >
                    {
                      <Box
                        sx={{
                          display:
                            workSpaceOption03 && index03 === optionIndex03
                              ? "flex"
                              : "none",
                          width: "180px !important",
                        }}
                        className="optionPop"
                      >
                        <Box className="optionPopItem" onClick={()=> {
                          setSupportLoginPop(true);
                          setSendParticularUserData(el)
                        }}>
                          {supportLoginBlack}
                          <Typography>{t("admin.supportLogin")}</Typography>
                        </Box>
                        {!isDeactivated ? (
                        <Box className="optionPopItem" onClick={()=> {
                          setDeactivatePop(true);
                          setSendParticularUserData(el)
                        }}>
                          {xCircleBlack}
                          <Typography>{t("admin.deactivate")}</Typography>
                        </Box> ) : (
                        <Box className="optionPopItem" onClick={()=> {
                          setDeactivatePop(true);
                          setSendParticularUserData(el)
                        }}>
                          {xCircleBlack}
                          <Typography>Activate</Typography>
                        </Box>)
                        }
                        <Box
                          className="optionPopItem"
                          onClick={() => {
                            setEditAdminSection(true);
                            setUpdateWSAdmin(el?._id);
                            setWorkspaceUserType('observers');
                          }}
                        >
                          {editIconBlack}
                          <Typography>{t("admin.edit")}</Typography>
                        </Box>
                        <Box
                          className="optionPopItem"
                          onClick={() => {
                            setDeleteObserverPop(true);
                            handleDeleteObserver(el);
                          }}
                        >
                          {trashBlack}
                          <Typography>{t("admin.delete")}</Typography>
                        </Box>
                      </Box>
                    }
                  </Box>
                  <Box className="tabelAvatar">
                    {el?.profile_pic == "undefined" || el?.profile_pic == null ? (
                      <img src={DummyUser} />
                    ) : (
                      <img src={viewImage(el?.profile_pic)} />
                    )}
                  </Box>
                  <Typography>
                    {el.name}
                    <span className="dblock subtitle">{el.username}</span>
                  </Typography>
                </Box>
                <Box
                  className="Tabelsel TabelselItemCenter"
                  minWidth={"230px"}
                  sx={{ justifyContent: "flex-start !important" }}
                >
                  <Typography>{el.email}</Typography>
                </Box>
                <Box
                  className="Tabelsel TabelselItemCenter"
                  minWidth={"230px"}
                  sx={{ justifyContent: "flex-start !important" }}
                >
                  <Typography>
                    {formatDate(el.created_at)} <span className="dblock"></span>
                  </Typography>
                </Box>
                <Box
                  className="Tabelsel TabelselItemCenter"
                  minWidth={"230px"}
                  sx={{ justifyContent: "flex-start !important" }}
                >
                  <Typography>{formatDate(el.updated_at)}</Typography>
                </Box>
              </Box>
            ))}
        </Box>
        <Box className='pagination'>
          <Pagination
            paginationData={obRpaginationData}
            onPageChange={onObserverPageChange}
          />
        </Box>
        </Box>}
      </Box>
    </>
  );
}
