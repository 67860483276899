import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from "@mui/material";
import "./style.css";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../lib/store/store";
import { API_BASE_URL, STORAGE_BASE_PATH } from "../../../env";
import axios from "../../../lib/axios";
import { TOAST_CONFIG } from "../../../lib/constants";
import { toast } from "react-toastify";
import { uploadFile } from '../../../Services/adminActions';

//images
import uploadIcon from "../../../Assets/Images/uil_upload.png";
import trasIcon from "../../../Assets/Images/fluent_delete-16-regular.png";
import micIcon from "../../../Assets/Images/micIcon.png"
import BlackMicIcon from "../../../Assets/Images/mic.png"
import backArrow from "../../../Assets/Images/noun-back.png";

//component
import SideMenu from "../../../Components/Participants/SideMenu";
import { sideHaumIcon } from "../../../Assets/Data";
import { AppBtn, AppBtnOutLine, PurplelBtn } from '../../../Components/AppTools/AppButton';
import { IBox } from "../../../Components/AppTools/AppTool"
import WaveSurfer from 'wavesurfer.js';

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export default function AudioActivity({ slideMenu, setSlideMenu, workspaceDrop }) {
  const Navigate = useNavigate();
  let authState = useAppSelector((state) => state.auth);
  let createdBy = authState?.userProfile?._id;
  const { t, i18n } = useTranslation();
  const [audioFile, setAudioFile] = useState(null);
  const [selectedAudios, setSelectedAudios] = useState([]);
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const { taskId } = useParams();
  const [workspaceId, setWorkspaceId] = useState("");
  const [projectId, setProjectId] = useState("");
  const [activityId, setActivityId] = useState("");
  const [taskType, setTaskType] = useState("");
  const [taskData, setTaskData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [taskResponseId, setTaskResponseId] = useState("");
  const [loading2, setLoading2] = useState(false);

  const initialTaskResponseState = {
    workspace_id: "",
    project_id: "",
    activity_id: "",
    task_id:"",
    type:"",
    text_response:"",
    audio_response:[],
    save_draft:false,
    status: "active",
  };

  const [taskResponseData, setTaskResponseData] = useState(initialTaskResponseState);

  useEffect(() => {
    if (waveformRef.current) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#ddd',
        progressColor: '#ff6347',
        height: 128,
      });
    }

    return () => {
      if (wavesurfer.current) {
        wavesurfer.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (audioFile && wavesurfer.current) {
      wavesurfer.current.load(URL.createObjectURL(audioFile));
    }
  }, [audioFile]);

  useEffect(() => {
    if (selectedAudios && wavesurfer.current) {
      wavesurfer.current.load(URL.createObjectURL(selectedAudios));
    }
  }, [selectedAudios]);

  // const handleAudioChange = (event) => {
  //   if (event.target.files && event.target.files[0]) {
  //     setAudioFile(event.target.files[0]);
  //   }
  // };

  const handleAudioChange = (event) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      const audiosArray = filesArray.map(file => {
        uploadImage(file);
        // setAudioFile(file);
        const reader = new FileReader();
        return new Promise((resolve) => {
          reader.onload = () => {
            resolve({ name: file.name, url: reader.result, audioFileName: file });
          };
          reader.readAsDataURL(file);
        }); 
      });
      Promise.all(audiosArray).then(audios => {
        setSelectedAudios(prevAudios => [...prevAudios, ...audios]);
      });
    }
  };

  const uploadImage = async (file) => {
    if (!file) return false;
    const formData = new FormData();
    formData.append("file", file);
    try {
      let imageUploadRes = await uploadFile(formData, authState.authToken);
      if (imageUploadRes.success) {
        setTaskResponseData(prevResponse => ({
          ...prevResponse,
          audio_response: [...prevResponse.audio_response, imageUploadRes?.data?.upload_path] 
        }));
        toast.success(imageUploadRes.message, TOAST_CONFIG);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    } finally {
    }
  };


  const lineBar = 80;
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#4F33D1",
    },
  }));

  useEffect(() => {
    const getTask = async () => {
      try {
        setLoading(false);
        const response = await axios.get(
          `/researchers/tasks?_id=${taskId}`
        );
        if (response.success) {
          setTaskData(response.data.data[0]);
          setWorkspaceId(response?.data?.data[0]?.workspace_id);
          setProjectId(response?.data?.data[0]?.project_id);
          setActivityId(response?.data?.data[0]?.activity_id);
          setTaskType(response?.data?.data[0]?.type);
          setTaskResponseData({ ...taskResponseData,task_id:  taskId });
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(true);
      }
    };
    if (taskId) {
      getTask();
    }
  }, [taskId]);

  const getTaskResponse = async (taskId, createdBy) => {
    try {
      setLoading2(false);
      const response = await axios.get(
        `/participant/task-response?task_id=${taskId}&created_by=${createdBy}`
      );
      if (response.success) {
        setTaskResponseId(response?.data?.data?._id);
        let initialTaskResponse = { ...initialTaskResponseState };
        const taskResponse = response.data.data;
        let key;
        for (key in initialTaskResponse) {
          initialTaskResponse[key] = taskResponse[key];
        }
        setTaskResponseData(initialTaskResponse);
        // console.log('Image Task Response Detail=>',initialTaskResponse);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading2(true);
    }
  };

  useEffect(() => {
    if (taskId && createdBy) {
      getTaskResponse(taskId,createdBy);
    }
  }, [taskId, createdBy]);

  useEffect(() => {
    const updateWorkspaceIdState = async () => {
      setTaskResponseData(prevWorkspace => ({
        ...prevWorkspace,
        workspace_id: workspaceId
      }));
    };
    if (workspaceId) {
      updateWorkspaceIdState();
    }
  }, [workspaceId]);

  useEffect(() => {
    const updateProjectIdState = async () => {
      setTaskResponseData(prevProject => ({
        ...prevProject,
        project_id: projectId
      }));
    };
    if (projectId) {
      updateProjectIdState();
    }
  }, [projectId]);

  useEffect(() => {
    const updateActivityIdState = async () => {
      setTaskResponseData(prevActivity => ({
        ...prevActivity,
        activity_id: activityId
      }));
    };
    if (activityId) {
      updateActivityIdState();
    }
  }, [activityId]);

  useEffect(() => {
    const updateTaskTypeState = async () => {
      setTaskResponseData(prevType => ({
        ...prevType,
        type: taskType
      }));
    };
    if (taskType) {
      updateTaskTypeState();
    }
  }, [taskType]);

  const validationObj = {
    hasErr: false,
    text_response: {
      error: false,
      msg: "",
    },
    audio_response: {
      error: false,
      msg: "",
    },
  };

  const [validationErrors, setValidationErrors] = useState({
    ...validationObj,
  });

  const handleValidation = (formInput) => {
    formInput = formInput || taskResponseData;
    let validationerr = { ...validationObj };

    if (formInput.text_response == "") {
      validationerr.hasErr = true;
      validationerr.text_response = {
        error: true,
        msg: "Text Response is required",
      };
    }

    if (formInput.audio_response == "") {
      validationerr.hasErr = true;
      validationerr.audio_response = {
        error: true,
        msg: "Audio Response is required",
      };
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  };

  const setFormData = (e) => {
    let newState = {
      ...taskResponseData,
      [e.target.name]: e.target.value,
    };
    setTaskResponseData(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
  };

  const resetAll = () => {
    setTaskResponseData(initialTaskResponseState);
    setSelectedAudios([]);
  }

  let saveTaskResponse = async () => {
    if (!handleValidation()) {
      toast.error("Please correct form fields", TOAST_CONFIG);
      return false;
    }
    setLoading(true);
    try {
      let response;
      if (taskResponseId){
         response = await axios.put(
          API_BASE_URL + `/participant/activity-task/${taskResponseId}`,
          taskResponseData,
          authState.authToken
        );
      }
      else
      {
         response = await axios.post(
          API_BASE_URL + `/participant/activity-task`,
          taskResponseData,
          authState.authToken
        );
      }
      if (response.success) {
        getTaskResponse(response?.data?._id, response?.data?.created_by);
        toast.success(response.message, TOAST_CONFIG);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      let message = "Something went wrong, please try again!";
      let status_code = 500;
      if (error.response) {
        message = error.response.data.message;
        status_code = error.response.status;
      }
      return {
        success: false,
        message: message,
        status_code: status_code,
      };
    }
  };

  const handleAudioDelete = () => {
    setTaskResponseData(taskResponseData => ({
      ...taskResponseData,
      audio_response: [] 
    }));
  }

  return (
    <>
      <Box className="ResearchersContainer imageActivityPage">
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon">
              {sideHaumIcon}
            </Box>
            <Box className="ontheDotNav">
              <PurplelBtn buttonText={t("commonTxt.previouspage")} />
              <AppBtn buttonText={t("commonTxt.nextPage")} onClick={() =>saveTaskResponse()} />
            </Box>
          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer onthedot">
            <Box className="workspaceListBox">
              <Box mb={3} width="74px" onClick={() => Navigate("/participants/activities")} className="openBtn">
                <img src={backArrow} />
                <Typography className="neonBackText">Back</Typography>
              </Box>
              <Typography className='activeProgText'>{t("commonTxt.activityProgress")}</Typography>
              <Box className="lineBar">
                <Typography>{lineBar}%</Typography>
                <BorderLinearProgress variant="determinate" value={lineBar} />
              </Box>
              <Typography className="lineBarSubText">{t("onTheDro.TaskComp")}</Typography>

              <Box className="otdmainLabelBox">
                <Typography className='ActInSectionMainHeader'>{taskData?.type} : {taskData?.name}</Typography>
                <Box className="skipBtnBox">
                  <Typography>{t("onTheDro.SaveDraft")}</Typography>
                  <AppBtnOutLine buttonText="Skip Task" />
                </Box>
              </Box>
              <IBox text={taskData.instructions ? taskData?.instructions : 'The important information for this task will be displayed here'} />
              <Typography className='imgUploadHeader'>{t("VideoActivity.uploadLabel")}<span>*</span></Typography>

              {loading && loading2 && (
              <Box className="uploadBox">
                {taskResponseData?.audio_response?.length > 0 ?
                  <>
                    <Box className="ipageUplod">
                      <Box sx={{ display: taskResponseData?.audio_response?.length === 1 ? "block" : "none" }} className="audio_Box">
                        <audio controls>
                          <source src={STORAGE_BASE_PATH + `/` + taskResponseData?.audio_response[0]} type={taskResponseData?.audio_response[0].type} />
                          {t("AudioActivity.browserNotSupport")}
                        </audio>
                      </Box>

                      <Box sx={{ display: taskResponseData?.audio_response?.length > 1 ? "block" : "none" }} className="audio_Box">
                      {taskResponseData?.audio_response?.slice(0, 4).map((el, i) => (
                        <audio style={{ height: taskResponseData?.audio_response?.length === 2 ? "20%" : taskResponseData?.audio_response?.length >= 3 ? "15%" : null }} key={i} controls>
                          <source src={STORAGE_BASE_PATH + `/` + el} type={el.type} />
                          {t("AudioActivity.browserNotSupport")}
                        </audio>
                        ))}
                      </Box>

                      <Box className="mediaInputText">
                        <Box className="textBox iaTextBox">
                          <Typography className="teraText">
                             {t("ImageActivity.Uploaded")}
                          </Typography>
                          <Box mb={2}>
                            <img src={uploadIcon} />
                            <Typography
                              ml={2}
                              onClick={() => {
                                document.getElementById("fileInput").click();
                              }}
                            >
                              {t("AudioActivity.UploadAudio")}
                            </Typography>

                            <input
                              id="fileInput"
                              type="file"
                              accept="audio/*"
                              style={{ display: 'none' }}
                              onChange={handleAudioChange}
                            />
                          </Box>
                          <Box mb={2}>
                            <img src={BlackMicIcon} />
                            <Typography ml={2}>{t("AudioActivity.RecordAudio")}
                            </Typography>
                          </Box>
                          <Box>
                            <img src={trasIcon} />
                            <Typography ml={2} onClick={() => handleAudioDelete()}>{t("commonTxt.delete")}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {/* <span className="validationerr">{validationErrors.audio_response.msg}</span> */}
                    <Typography className='imgBottominputLabel'>{t("ImageActivity.feelAbout")}</Typography>
                    <textarea className='massageInput' placeholder='Enter your message here' name="text_response"
                      value={taskResponseData.text_response ? taskResponseData.text_response : "" }
                      onChange={setFormData} />
                      <span className="validationerr">{validationErrors.text_response.msg}</span>
                  </>
                  :
                  <>
                    <Box className="imgUplodInputBox" onClick={() => document.getElementById("fileInput").click()}>
                      <input
                        id='fileInput'
                        type="file"
                        accept="audio/*"
                        style={{ display: 'none' }}
                        onChange={handleAudioChange}
                      />
                      <img src={micIcon} alt="Camera" />
                    </Box>
                    <span className="validationerr">{validationErrors.audio_response.msg}</span>
                    <Typography
                      onClick={() => document.getElementById("fileInput").click()}
                      className='imageUploadLabelText'>{t("AudioActivity.capture1")} <span>{t("AudioActivity.capture2")}</span> {t("AudioActivity.capture3")}</Typography>
                  </>
                }
              </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

    </>
  )
}
