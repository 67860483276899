import React from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Typography,
} from "@mui/material";
import "./style.css";

//Images

import { groupBtnTab } from "../../../Assets/Data";
//components

import { SearchBar } from "../../AppTools/AppTool";
import ProjectDirectoryLeaderboard from "../ProjectDirectoryLeaderboard";

export default function ProjectDirectory() {
  const { t } = useTranslation();

  return (
    <>
      <Box className="researchersMainBox pageContainer">
        <Box className="integrationsContainer">
          <Box className="projectParticipantDropSearchBox">
            <Box className="projectCodeBookHead">
              <Box>
                <Box className="projectManagementNavBtn">
                  <Typography className="DropBtnText"> {t("projectDirectory.userDropVal1")}</Typography>
                </Box>
              </Box>
            </Box>

            <Box className="projectSearchGroupAction">
              <Box className="projectSearchGroup">
                <Box sx={{ width: "23%" }}>
                  <Box className="inputAppDrop">
                    <Typography>Leaderboard</Typography>
                  </Box>
                </Box>

                <Box sx={{ width: "58%" }} className="inputBoxWlabel">
                  <SearchBar />
                </Box>

                <Box sx={{ width: "13%" }} className="groupBtnTab">
                  {groupBtnTab}
                  <Typography>{t("commonTxt.groupBtn")}</Typography>
                </Box>
              </Box>
              <ProjectDirectoryLeaderboard />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
