import React, { useRef, useState } from 'react';
import "./style.css";
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";

//images
import banner from "../../../Assets/Images/logBanner.png";
import CLogo from "../../../Assets/Images/Frame 243811.png";
import RedInfo from "../../../Assets/Images/info.png";
import { useTranslation } from 'react-i18next';
import { loginViaOtp, updateRedUserProfile } from '../../../Services/auth';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { USER_TYPE_WS_ADMIN } from '../../../lib/constants';
import { useParams } from 'react-router-dom';
import { getUserTypeFromSegment } from '../../../lib/helpers';

export default function LoginOTPVerify() {
  const { usersegment } = useParams();
  let usertype = getUserTypeFromSegment(usersegment);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n: { changeLanguage, language } } = useTranslation();
  
  const [otp, setOTP] = useState(['', '', '', '', '', '']);
  const [apiError, setApiError] = useState("");
  const inputRefs = useRef([]);
  const [loading, setLoading] = useState(false);
  usertype = usertype || { USER_TYPE_WS_ADMIN };

  const changeAppLanguage = (lng) => {
    changeLanguage(lng);
  }
  
  const handleChange = (index, value) => {
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);

    // Move focus to the next input field if available
    if (value !== '' && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const verify = async () => {
    const otpStr = otp.reduce((acc, current) => acc + current, '')

    if (otpStr === "") {
      setApiError("OTP is required!");
      return;
    }

    const payload = {
      otp: otp.join(''),
      email: location?.state?.email,
      stay_signed_in: location?.state?.stay_signed_in,
    };

    try {
      setLoading(true);
      const response = await loginViaOtp(payload);
      
      setLoading(false);
      if (response.success) {
        toast.success(response.message);
        updateRedUserProfile(response?.data?.token, dispatch, navigate, true, usertype);
        // Clear location state by navigating to the same route without state
        navigate(location.pathname, { replace: true, state: null });
      } else {
        toast.error(response.message);
        setApiError(response.message);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
      setApiError('Something went wrong! Please try again');
      setLoading(false);
    }
  };

  return (
    <>
      <select style={{ float: 'right' }} onChange={(e) => { changeAppLanguage(e.target.value) }} value={language}>
        <option value="en">English</option>
        <option value="hi">Hindi</option>
      </select>
      <Box className="logInPage">
        <Box className="banner">
          <Typography className=''>Take your research to the next level</Typography>
          <Box mt={3} className="slideBox">
            <Box className="sBox"></Box>
            <Box className="sBox"></Box>
            <Box className="sBox sBoxActive"></Box>
          </Box>
          <img src={banner} />
        </Box>

        <Box className="signInBox">
          <Box className="signInInnerBox">
            <Box className="signColog">
              <img onClick={() => navigate("/")} src={CLogo} />
            </Box>
            <Typography className='authHeaderSubText'>Enter the OTP displayed in your email.</Typography>

            <Box className="otpInputBox">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  inputMode="numeric"
                  name="otp[]"
                  pattern="[0-9]*"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleChange(index, e.target.value)}
                  ref={(input) => (inputRefs.current[index] = input)}
                />
              ))}
            </Box>

            {apiError !== "" && <Box mb={2} className="pwdError">
              <img src={RedInfo} />
              <Typography>{apiError}</Typography>
            </Box>}

            <Box className="signBtn" onClick={verify}>
              {loading ? <Typography>{t('adminSignIn.loading')}...</Typography> : <Typography>Verify OTP</Typography>}
            </Box>

            <Box className="backtologinpage">
              <Typography
                onClick={() => navigate("/users/login")}
                color={"#4F33D1"}
              >
                Back To Login Page
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
