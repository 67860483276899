import React, { useState } from 'react'
import { Box, Typography, CardHeader, Avatar, CardContent } from '@mui/material'
import { useTranslation } from "react-i18next";
import "./style.css";

import iIcon2 from "../../../Assets/Images/IIcon.png";

import { participantInvitation } from "../../../Assets/Data";

export default function ProjectDirectParticipantInvitation({ selectedParticipant, setSelectedParticipant }) {

    const handleCheckboxChange = (index) => {
        const updatedCheckboxes = [...selectedParticipant];
        updatedCheckboxes[index] = !updatedCheckboxes[index];
        setSelectedParticipant(updatedCheckboxes);
    };
    const {
      t,
      i18n: { changeLanguage, language },
    } = useTranslation();

  return (
    <>   
        <Box className="iBox projectiBox">
            <img src={iIcon2} />
            <Typography>{t("projectDirectory.directoryInvitationNotify")}</Typography>
        </Box>
        <Box className="workListBox workListBoxRP">
          <Box className="haderRow" minWidth={"1120px"}>
            <Box minWidth={"25%"} className="Tabelsel navFsel TabelStatesel">
              <Typography>{t("projectDirectory.tblName")}</Typography>
            </Box>
            <Box minWidth={"12%"} className="Tabelsel">
              <Typography textAlign={"center"}>{t("projectDirectory.tblStatus")}</Typography>
            </Box>
            <Box minWidth={"24%"} className="Tabelsel">
              <Typography textAlign={"center"}>{t("projectDirectory.tblInvited")}</Typography>
            </Box>
            <Box minWidth={"18%"} className="Tabelsel">
              <Typography textAlign={"center"}>{t("projectDirectory.tblEmailOpened")}</Typography>
            </Box>
            <Box minWidth={"18%"} className="Tabelsel">
              <Typography textAlign={"center"}>{t("projectDirectory.tblJoined")}</Typography>
            </Box>
          </Box>
          {participantInvitation &&
            participantInvitation?.map((el, index) => (
              <Box key={index} className="TabelRow" minWidth={"1120px"}>
                <Box minWidth={"25%"} className="Tabelsel navFsel participantCheckboxColor">
                <input className="AllPTablechackBox" type="checkbox" checked={selectedParticipant.includes(index)} onChange={() => handleCheckboxChange(index)}  />
                  <Typography
                    sx={{ cursor: "pointer" }}
                  >
                    {el.name}
                  </Typography>
                </Box>
                <Box minWidth={"12%"} className="Tabelsel invitationStatusIcon">
                {el.icon}
                  <Typography className="participantsTableText">
                        {el.statusValue}
                    </Typography>
                </Box>
                <Box
                  minWidth={"24%"}
                  className="Tabelsel TabelselItemCenter"
                >
                  <Typography className="participantsTableText">
                      {el.invited}
                  </Typography>
                </Box>

                <Box
                  minWidth={"18%"}
                  className="Tabelsel TabelselItemCenter"
                >
                  <Typography className="participantsTableText">
                        {el.emailOpened}
                    </Typography>
                </Box>

                <Box
                  minWidth={"18%"}
                  className="Tabelsel TabelselItemCenter"
                >
                    <Typography className="participantsTableText">
                        {el.joined}
                    </Typography>
                </Box>
              </Box>
            ))}
        </Box>
    </>
  )
}
