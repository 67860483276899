import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./style.css";
//components
import { AppBtnOutLine, AppBtn } from "../../../Components/AppTools/AppButton";
import { AntSwitch } from "../../AppTools/AppTool";

export default function AiIntegrationPop({ aiIntegrationPop, setAiIntegrationPop }) {
  const { t, i18n } = useTranslation();


  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setAiIntegrationPop(false);
    }
  };

  useEffect(() => {
    if (aiIntegrationPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [aiIntegrationPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: aiIntegrationPop ? "flex" : "none" }}
        className="companyPop liveInterView"
      >
        <Box
          sx={{ display: aiIntegrationPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className="eTemPopLabel">{t("aiIntegrationPopup.aiIntegrationPopupHead")}</Typography>

          <Box className="aiIntegrationradioSwitchBox journalActivityNotificationDayDrop">
            <AntSwitch
              inputProps={{ "aria-label": "ant design" }}
            />
            <Typography>
              {t("aiIntegrationPopup.aiIntegrationPopupTranscript")}
            </Typography>
          </Box>
          <Box className="aiIntegrationradioSwitchBox journalActivityNotificationDayDrop">
            <AntSwitch
              defaultChecked
              inputProps={{ "aria-label": "ant design" }}
            />
            <Typography>
              {t("aiIntegrationPopup.aiIntegrationPopupSummary")}
            </Typography>
          </Box>
          <Box className="aiIntegrationradioSwitchBox journalActivityNotificationDayDrop">
            <AntSwitch
              inputProps={{ "aria-label": "ant design" }}
            />
            <Typography>
              {t("aiIntegrationPopup.aiIntegrationPopupAnalysis")}
            </Typography>
          </Box>


          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => setAiIntegrationPop(false)} />
            <AppBtn buttonText={t("commonTxt.saveBtn")} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
