import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//images
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png";
import iIcon2 from "../../../Assets/Images/IIcon.png";
//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";

export default function CreateConversationCategoryPop({
  createConversationCategoryPop,
  setCreateConversationCategoryPop,
}) {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const Navigate = useNavigate();
  const [image, setImage] = useState(null);

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setCreateConversationCategoryPop(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (createConversationCategoryPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [createConversationCategoryPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: createConversationCategoryPop ? "flex" : "none" }}
        className="companyPop addActivitiesPop"
      >
        <Box
          sx={{ display: createConversationCategoryPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          
            <Typography className="companyHeader">{t("createCategoryPopup.createConversationCategoryHead")}</Typography>
            <Box className="iBox">
              <img src={iIcon2} />
              <Typography>
              {t("createCategoryPopup.createCategoryNotify")}
              </Typography>
            </Box>

            <Typography className="topicPopLabels">{t("createCategoryPopup.categoryNameLabel")}</Typography>
            <Box className="input_Box TopicTitleLabel">
              <input
                type="text"
                className="eTemInput"
                placeholder={t("createCategoryPopup.categoryNamePlaceholder")}
              />
            </Box>
            <Typography className="topicPopLabels">{t("createCategoryPopup.subCategoryLabel")}</Typography>
            <Box className="input_Box TopicTitleLabel">
              <input
                type="text"
                className="eTemInput"
                placeholder={t("createCategoryPopup.subCategoryPlaceholder")}
              />
            </Box>
            <Typography className="topicPopLabels">{t("addConversationTopic.descriptionLabel")}</Typography>
            <Box className="input_Box">
              <textarea
                className="DmettingTextTextAera"
                placeholder={t("createCategoryPopup.descriptionPlaceholder")}
              />
            </Box>
            <Typography className="topicPopLabels">{t("createCategoryPopup.conversationsCoverHead")}</Typography>
            <Box className="input_Box">
                <Box className='mediaInputbox upload-area taskUoloadImageBox' onClick={() => {
                    document.getElementById("fileInput").click();
                }}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}>
                    <img src={uploadIcon} />
                    <Typography>{t("createCategoryPopup.clickDragImage")}</Typography>
                    <input
                    id="fileInput"
                    style={{ display: "none" }}
                    type="file"
                    onChange={handleFileInputChange}
                    />
                </Box>
            </Box>

            <Typography className='textTaskSetting '>{t("createCategoryPopup.categorySettingsHead")}</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("createCategoryPopup.categorySettingsCheckbox1")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("createCategoryPopup.categorySettingsCheckbox2")} </Typography>
          </Box>

            <Box className="workPupBtnBox">
              <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => setCreateConversationCategoryPop(false)} />
              <AppBtn buttonText={t("commonTxt.continueBtn")} />
            </Box>
          
          
        </Box>
      </Box>
    </>
  );
}
