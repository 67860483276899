import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import "./style.css";
import { sideHaumIcon } from "../../../Assets/Data";

//component
import DeleteAccountPop from '../../../Components/User/DeleteAccountPop';
import { GoTop } from '../../../Components/AppTools/AppTool';
import SideMenu from "../../../Components/User/SideMenu";
import { useAppSelector } from '../../../lib/store/store';
import { renderUserLayout } from '../../../lib/helpers';


export default function SafetyPrivacy({ slideMenu, setSlideMenu, workspaceDrop, setWorkSpaceDrop }) {
  const authState = useAppSelector((state) => state.auth);
  const [deleteAccountPop, setDeleteAccountPop] = useState(false);

  const getChildren = () => {
    return (
      <Box className="researchersMainBox pageContainer">
        <Box className="EmailNContainer">
          <DeleteAccountPop deleteAccountPop={deleteAccountPop} setDeleteAccountPop={setDeleteAccountPop} />
          <Box className="safetyPrivacyBtn">
            <Typography className='safetyPrivacyBtnName'>Download Your Data</Typography>
          </Box>
          <Typography className='safetyPrivacyBtnText'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas luctus porttitor enim. Quisque quis convallis sapien. Integer vel tempor lacus. Vestibulum a justo a lorem mollis tempor in eget dolor. Aliquam sit amet neque vel eros scelerisque suscipit ornare in arcu. Quisque rutrum purus vitae porta mollis</Typography>

          <Box
            onClick={() => {
              setDeleteAccountPop(true);
              GoTop();
            }}
            className="safetyPrivacyBtn">
            <Typography className='safetyPrivacyBtnName'>Delete Your Account</Typography>
          </Box>
          <Typography className='safetyPrivacyBtnText'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas luctus porttitor enim. Quisque quis convallis sapien. Integer vel tempor lacus. Vestibulum a justo a lorem mollis tempor in eget dolor. Aliquam sit amet neque vel eros scelerisque suscipit ornare in arcu. Quisque rutrum purus vitae porta mollis</Typography>
        </Box>
      </Box>
    )
  }
  return renderUserLayout(authState?.userType, null, getChildren(), 'accountsetting');
}
