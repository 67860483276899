import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import whitedropIcon from "../../../Assets/Images/Wchevron-down.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";
import exportIcon from "../../../Assets/Images/ExpoGroup.png";
import dropIcon from "../../../Assets/Images/chevron-downp.png";
import printIcon from "../../../Assets/Images/printIcon.png";
import arrow from "../../../Assets/Images/arrow.png";

import { useTranslation } from "react-i18next";

//component
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { Status } from "../../AppTools/AppTool";
import StackedBar from "../../../Components/AppTools/StackedBar";

//data
import { participant5Data, reportDropVal } from "../../../Assets/Data";

export default function ProjectParticipantReport({
  dataReportTabe,
  setDataReportTabe,
  projectSettingDrop,
  setProjectSettingDrop,
  projectSettingDropVal,
  setProjectSettingDropVal,
}) {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [identitiesDrop, setIdentitiesDrop] = useState(false);
  const [identitiesDropVal, setIdentitiesDropVal] = useState();

  const [rangeDrop4, setRangeDrop4] = useState(false);
  const [rangeDropVal4, setRangeDropVal4] = useState("All Time");

  const [contributionDrop4, setContributionDrop4] = useState(false);
  const [contributionDropVal4, setContributionDropVal4] = useState("All Types");
  const [currentPage, setCurrentPage] = useState(0);

  const indentitiesData = [
    {
      name: "Full Name",
      value: "Full Name",
    },
    {
      name: "Username",
      value: "Username",
    },
    {
      name: "Unique ID",
      value: "Unique ID",
    },
  ];

  useEffect(() => {
    let selTab = searchParams.get("t");
    let selTab2 = searchParams.get("dr");
    if (selTab && selTab2 != null) {
      var query_string = selTab;
      query_string = query_string.replaceAll('_', ' ');
      query_string = query_string.replace(/(^\w|\s\w)/g, firstCharOfWord => firstCharOfWord.toUpperCase());
      console.log(query_string);
      if (selTab === "size_churn") {
        var query_string = "Size & Churn";
        setProjectSettingDropVal(query_string);
        setDataReportTabe(parseInt(selTab2));
      }
      else {
        setProjectSettingDropVal(query_string);
        setDataReportTabe(parseInt(selTab2));
      }
    }
    else if (selTab == null) {
      setDataReportTabe(parseInt(selTab2));
    }
  }, []);

  return (
    <>
      {/* -----------------------------------------------Participant Report-------------------------------------- */}
      <Box
        sx={{
          display: projectSettingDropVal === "Participation" ? "block" : "none",
        }}
        className="reportContainer integrationsContainer compositionSection"
      >
        <Box className="projectBtnBox">
          <Box className="inteBtndropBox">
            <Box
              className="projectManagementNavBtn"
              onClick={() => setProjectSettingDrop(!projectSettingDrop)}
            >
              <Typography className="DropBtnText">
                {projectSettingDropVal || "Size & Churn"}
              </Typography>
              <img
                className="dropIcon"
                src={whitedropIcon}
                style={{
                  rotate: projectSettingDrop ? "180deg" : "0deg",
                  transition: "all 0.5s",
                  marginLeft: "10px",
                }}
              />
              <Box
                className="workspaceItemNavPop projectItemNavPop"
                sx={{ display: projectSettingDrop ? "flex" : "none" }}
              >
                {reportDropVal?.map((el, i) => (
                  <Box key={i}
                    onClick={() => {
                      setSearchParams({ dr: dataReportTabe, t: el.qstring });
                      setProjectSettingDropVal(el.title);
                    }}
                  >
                    <Typography>{el.title}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
        <Typography className="pageHeaderText">
          {t("projectParticipantReport.headerTxt")}
        </Typography>
        <Box className="participationReportDropSection">
          <Box className="inputBoxWlabel">
            <Typography>
              {t("projectParticipantReport.dateRangeDrop")}
            </Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setRangeDrop4(!rangeDrop4)}
            >
              <Typography>{rangeDropVal4 || t("projectParticipantReport.dateRangeDropPlaceholder")}</Typography>
              <img
                style={{ rotate: rangeDrop4 ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: rangeDrop4 ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setRangeDropVal4("All Time")}
                  className="statusPopItem"
                >
                  <Typography>{t("projectParticipantReport.dateRangeDropVal1")}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="inputBoxWlabel">
            <Typography>
              {t("projectParticipantReport.contributionTypeDrop")}
            </Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setContributionDrop4(!contributionDrop4)}
            >
              <Typography>
                {contributionDropVal4 || t("projectParticipantReport.contributionTypeDropPlaceholder")}
              </Typography>
              <img
                style={{ rotate: contributionDrop4 ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: contributionDrop4 ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setContributionDropVal4("All Types")}
                  className="statusPopItem"
                >
                  <Typography>{t("projectParticipantReport.contributionTypeDropVal1")}</Typography>
                </Box>
                <Box
                  onClick={() => setContributionDropVal4("Subscribed")}
                  className="statusPopItem"
                >
                  <Typography>{t("projectParticipantReport.contributionTypeDropVal2")}</Typography>
                </Box>
                <Box
                  onClick={() => setContributionDropVal4("Unsubscribed")}
                  className="statusPopItem"
                >
                  <Typography>{t("projectParticipantReport.contributionTypeDropVal3")}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box mt={5} className="redioGroupSection">
          <Typography className="emailNotificationHeaderText">
            {t("projectParticipantReport.subHeaderTxt1")}
          </Typography>

          <Box my={2} className="projectRadioBoxSection">
            <Box className="ProjectRadioInput">
              <input
                className="custom-checkbox"
                type="checkBox"
                name="projectState"
              />
              <Typography className="emailSettingLabel">
                {t("projectParticipantReport.inclusionComment")}
              </Typography>
            </Box>
            <Box className="ProjectRadioInput">
              <input
                className="custom-checkbox"
                type="checkBox"
                name="projectState"
              />
              <Typography className="emailSettingLabel">
                {t("projectParticipantReport.inclusionSkipTask")}
              </Typography>
            </Box>
          </Box>

          <Box mt={4} className="projectRadioBoxSection">
            <Box className="ProjectRadioInput">
              <input
                className="custom-checkbox"
                type="checkBox"
                name="projectState"
              />
              <Typography className="emailSettingLabel">
                {t("projectParticipantReport.inclusionRatingt")}
              </Typography>
            </Box>
            {/* <Box className="ProjectRadioInput">
                <input
                  className="custom-checkbox"
                  type='checkBox'
                  name='projectState'
                />
                <Typography className="emailSettingLabel">Not-yet Joined</Typography>
              </Box> */}
          </Box>
        </Box>

        <Box mt={5} className="redioGroupSection">
          <Typography className="emailNotificationHeaderText">
            {t("projectParticipantReport.subHeaderTxt2")}
          </Typography>

          <Box className="projectRadioBoxSection">
            <Box className="ProjectRadioInput">
              <input
                className="emailSettingInput emailSettingRadio"
                type="radio"
                name="projectState"
              />
              <Typography className="emailSettingLabel">
                {t("projectParticipantReport.participantAllParticipant")}
              </Typography>
            </Box>
            <Box className="ProjectRadioInput">
              <input
                className="emailSettingInput emailSettingRadio"
                type="radio"
                name="projectState"
              />
              <Typography className="emailSettingLabel">
                {t("projectParticipantReport.participantSelectGroup")}
              </Typography>
            </Box>
          </Box>

          <Box className="projectRadioBoxSection">
            <Box className="ProjectRadioInput">
              <input
                className="emailSettingInput emailSettingRadio"
                type="radio"
                name="projectState"
              />
              <Typography className="emailSettingLabel">
                {t("projectParticipantReport.participantSelectParticipant")}
              </Typography>
            </Box>
          </Box>

          <Box className="participantIdentities">
            <Typography>
              {t("projectParticipantReport.participantIdentity")}
            </Typography>
            <Status
              itemAry={indentitiesData}
              setStatusDrop={setIdentitiesDrop}
              statusDrop={identitiesDrop}
              setStatusDropVal={setIdentitiesDropVal}
              statusDropVal={identitiesDropVal}
              label={t("projectParticipantReport.participantIdentityPlaceholder")}
            />
          </Box>
        </Box>

        <Box className="workPupBtnBox">
          <AppBtnOutLine buttonText={t("commonTxt.resetBtn")} />
          <AppBtn buttonText={t("commonTxt.viewReportBtn")} />
        </Box>
      </Box>
      <Box
        sx={{
          display: projectSettingDropVal === "Participation" ? "block" : "none",
        }}
        mt={5}
        className="integrationsContainer"
      >
        <Box mt={3} className="tabelNav">
          <Box className="dropWheader">
            <Typography className="pageTitle">
              {t("projectParticipantReport.headerTxt2")}
            </Typography>
            <Box className="ndrop">
              <Typography>5</Typography>
              <img src={dropIcon} />
            </Box>
          </Box>
          <Box className="repPrintBtnBox">
            <Box className="exporOpenBtn">
              <img src={printIcon} />
              <Typography className="verifyLink">{t("commonTxt.printLink")}</Typography>
            </Box>
            <Box className="exporOpenBtn">
              <img src={exportIcon} />
              <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
            </Box>
          </Box>
        </Box>

        <Box my={3} className="sideLineChart">
          <StackedBar />
        </Box>

        <Box mt={5} mb={3} className="tabelNav">
          <Box className="dropWheader">
            <Typography className="pageHeaderText">
              {t("projectParticipantReport.subHeaderTxt2")} (5)
            </Typography>
          </Box>
          <Box className="repPrintBtnBox">
            <Box className="exporOpenBtn">
              <img src={exportIcon} />
              <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
            </Box>
          </Box>
        </Box>

        <Box mb={6} className="workListBox">
          <Box className="haderRow" minWidth={"1100px"}>
            <Box minWidth={"24%"} className="Tabelsel TabelStatesel">
              <Typography>
                {t("projectParticipantReport.tblUserName")}
              </Typography>
            </Box>
            <Box minWidth={"10%"} className="Tabelsel TabelStatesel">
              <Typography>{t("projectParticipantReport.tblTasks")}</Typography>
            </Box>
            <Box minWidth={"10%"} className="Tabelsel TabelStatesel">
              <Typography>
                {t("projectParticipantReport.tblRealTime")}
              </Typography>
            </Box>
            <Box minWidth={"14%"} className="Tabelsel TabelStatesel">
              <Typography>
                {t("projectParticipantReport.tblConversation")}
              </Typography>
            </Box>
            <Box minWidth={"14%"} className="Tabelsel TabelStatesel">
              <Typography>
                {t("projectParticipantReport.tblComment")}
              </Typography>
            </Box>
            <Box minWidth={"10%"} className="Tabelsel TabelStatesel">
              <Typography>{t("projectParticipantReport.tblRating")}</Typography>
            </Box>
            <Box minWidth={"14%"} className="Tabelsel TabelStatesel">
              <Typography>
                {t("projectParticipantReport.tblLastAction")}
              </Typography>
            </Box>
          </Box>

          {participant5Data?.map((el, i) => (
            <Box className="TabelRow" minWidth={"1100px"}>
              <Box minWidth={"24%"} className="Tabelsel navFsel navtable">
                <Box className="tabelAvatar">
                  {" "}
                  <img src={el.img} />{" "}
                </Box>
                <Typography sx={{ cursor: "pointer", width: "95%" }}>
                  {" "}
                  {el.name}
                </Typography>
              </Box>
              <Box minWidth={"10%"} className="Tabelsel TabelStatesel">
                <Typography>{el.tasks}</Typography>
              </Box>
              <Box minWidth={"10%"} className="Tabelsel TabelStatesel">
                <Typography>{el.RealTime}</Typography>
              </Box>
              <Box minWidth={"14%"} className="Tabelsel TabelStatesel">
                <Typography>{el.Conversations}</Typography>
              </Box>
              <Box minWidth={"14%"} className="Tabelsel TabelStatesel">
                <Typography>{el.Comments}</Typography>
              </Box>
              <Box minWidth={"10%"} className="Tabelsel TabelStatesel">
                <Typography>{el.Ratings}</Typography>
              </Box>
              <Box minWidth={"14%"} className="Tabelsel TabelStatesel">
                <Typography>{el.LastAction}</Typography>
              </Box>
            </Box>
          ))}
        </Box>

        <Box className="pagenation">
          <Box className="tabelBtn">
            <img src={arrow} />
            <Typography>{t("commonTxt.previouspage")}</Typography>
          </Box>

          <Box className="pageNumberBox">
            <Box
              // onClick={() => setCurrentPage(0)}
              className={
                currentPage === 0 ? "pageNumber pageNumberActive" : "pageNumber"
              }
            >
              <p>1</p>
            </Box>
            <Box
              // onClick={() => setCurrentPage(1)}
              className={
                currentPage === 1 ? "pageNumber pageNumberActive" : "pageNumber"
              }
            >
              <p>2</p>
            </Box>
            <Box
              // onClick={() => setCurrentPage(2)}
              className={
                currentPage === 2 ? "pageNumber pageNumberActive" : "pageNumber"
              }
            >
              <p>3</p>
            </Box>
            <Box
              // onClick={() => setCurrentPage(3)}
              className={
                currentPage === 3 ? "pageNumber pageNumberActive" : "pageNumber"
              }
            >
              <p>4</p>
            </Box>
            <Box
              // onClick={() => setCurrentPage(4)}
              className={
                currentPage === 4 ? "pageNumber pageNumberActive" : "pageNumber"
              }
            >
              <p>5</p>
            </Box>
          </Box>

          <Box className="tabelBtn">
            <Typography>{t("commonTxt.nextPage")}</Typography>
            <img style={{ rotate: "180deg" }} src={arrow} />
          </Box>
        </Box>
      </Box>

      {/* -----------------------------------------------Participant Report-------------------------------------- */}
    </>
  );
}
