import React, { useState } from "react";

import { Box, Typography } from "@mui/material";
import AdminBG from "../../../Assets/Images/Add-files-cuate.png";
import { AppBtn } from "../../AppTools/AppButton";


const NoProjectBgTemplate = () => {
    const [addProjectPop, setAddProjectPop] = useState(false);

  return (
    <>
      <Box sx={{display:"contents"}} className="adminWorkSBG">
        <img src={AdminBG} />
      </Box>
      <Box className="adminBGTextBox">
        <Typography className="adminHeaderText">
          Welcome to Workspace!
        </Typography>
        <Typography className="adminSubHeaderText">
          It seems like you have not added any project.
          Please add to proceed further.
        </Typography>
        <AppBtn
          onClick={() => setAddProjectPop(true)}
          buttonText="Add Project"
        />
      </Box>
    </>
  );
};

export default NoProjectBgTemplate;
