import React, { useState, useEffect } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//images
import backArrow from "../../../Assets/Images/noun-back.png";
import cott from "../../../Assets/Images/cott.png";
import groupIcon from "../../../Assets/Images/groupsIcon.png";
import exportIcon from "../../../Assets/Images/exportIcon.png";
import dropIcon from "../../../Assets/Images/chevron-down.png";
import arrow from "../../../Assets/Images/arrow.png";
import Option2LIcon from "../../../Assets/Images/Option2LIcon.png";
import editIcon from "../../../Assets/Images/edit 1.png";
import tik1 from "../../../Assets/Images/checkG.png";
import redcircle from "../../../Assets/Images/alert-circle.png";

//components
import SideMenu from "../../../Components/Participants/SideMenu";
import { sideHaumIcon } from "../../../Assets/Data";
import { LatestCreated, Status } from "../../../Components/AppTools/AppTool";
import { Box, Typography } from "@mui/material";
import { LineChart2 } from "../../../Components/AppTools/LineChart2";
//data
import { responsesSummeryData, trashBlack } from "../../../Assets/Data";

export default function RecapPollMulltiPage({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
  selectNavItem,
  setSelectNavItem
}) {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const Navigate = useNavigate();
  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Recently Added");

  const responseArrya = [
    {
      label: "Recent Responses",
    },
    {
      label: "Oldest Responses",
    },
    {
      label: "Recent Comments",
    },
  ];

  const navItem = [
    "Thread",
    "Expressions",
    "Images",
    "Videos",
    "Audios",
    "Data & Charts",
  ];

  const [exportDrop3, setExportDrop3] = useState(false);
  const [exportDrop4, setExportDrop4] = useState(false);
  const [exportDrop5, setExportDrop5] = useState(false);
  const exportItem = ["PNG", "JPEG", "PDF", "SVG"];
  const [chartDrop, setChartDrop] = useState(false);
  const [chartDropVal, setChartDropVal] = useState("Donut Chart");
  const chartItem = [
    {
      name: "Donut Chart",
      value: "Donut Chart",
    },
    {
      name: "Horizontal Bar Graph",
      value: "Horizontal Bar Graph",
    },
    {
      name: "Vertical Bar Graph",
      value: "Vertical Bar Graph",
    },
  ];

  const [countDrop, setCountDrop] = useState(false);
  const [countDropVal, setCountDropVal] = useState(
    "Show count with percentage"
  );
  const countItem = [
    {
      name: "Show only count of tags",
      value: "Show only count of tags",
    },
    {
      name: "Show only count of tags",
      value: "Show only count of tags",
    },
    {
      name: "Show only percentage",
      value: "Show only percentage",
    },
  ];
  const scaleItem = [
    {
      name: "1 Decimal (0.1)",
      value: "1 Decimal (0.1)",
    },
    {
      name: "2 Decimals (0.01)",
      value: "2 Decimals (0.01)",
    },
  ];

  const [scaleDrop, setScaleDrop] = useState(false);
  const [scaleDropVal, setScaleDropVal] = useState("1 Decimal (0.1)");
  const [optionIndex, setOptionIndex] = useState();
  const [workSpaceOption, setWorkspaceOption] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [newArrayData, setNewArrayData] = useState([]);

  const handleOptionIndex = (i) => {
    setOptionIndex(i);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    printItemsByState(responsesSummeryData, currentPage);
  }, [currentPage]);

  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }




  return (
    <>
      <Box className="ResearchersContainer recapPage">
        {/* side menu */}
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>

            <Box className="recapNavItems">
              {navItem?.map((el, i) => (
                <Box
                  key={i}
                  onClick={() => setSelectNavItem(el)}
                  className={
                    selectNavItem === el
                      ? "recapNavItem recapNavItemActive"
                      : "recapNavItem"
                  }
                >
                  <Typography>{el}</Typography>
                </Box>
              ))}
            </Box>
            <Box width={"22%"} minWidth={"187px"} className="agreSearchB2">
              <LatestCreated
                setLatestDrop={setLatestDrop}
                latestCDrop={latestCDrop}
                setLatestDropVal={setLatestDropVal}
                latestDropVal={latestDropVal}
                itemAry={responseArrya}
              />
            </Box>
          </Box>
          {/*------------------------------------ Page container--------------------------------------------------- */}
          <Box className="researchersMainBox pageContainer">
            <Box className="imagesReviewPage workspaceListBox reviewPoll2 multiReview">
              <Box className="openBtn">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img src={backArrow} />
                  <Typography className="neonBackText" onClick={() => Navigate("/participants/recap?r=data_charts")}>{t("commonTxt.backBtn")}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "24px",
                  }}
                >
                  <Box className="actTNavBtn">
                    <img src={groupIcon} />
                    <Typography>{t("commonTxt.groupBtn")}</Typography>
                  </Box>

                  <Box className="actTNavBtn">
                    <img src={cott} />
                    <Typography>{t("commonTxt.excerptBtn")}</Typography>
                  </Box>

                  <Box className="actTNavBtn">
                    <img src={exportIcon} />
                    <Typography>{t("commonTxt.exportLink")}</Typography>
                  </Box>
                </Box>
              </Box>

              <Box className="imageReviewMainSection">
                <Box className="imgRNav1Box">
                  <Typography>Outdoor Journey</Typography>
                </Box>
                <Box my={2} className="imgRNav1Box">
                  <Typography className="pageTitle">Shopping Method Poll Task</Typography>

                </Box>
                <Typography className="imgrevsumText">
                  Lorem ipsum dolor sit amet consectetur. Morbi suspendisse quis ornare in. Amet nunc lectus a aenean lectus. Penatibus tempor urna sed eros suspendisse vulputate vulputate enim vitae. Leo ipsum sed erat sed et. Pellentesque cursus proin ornare eros amet velit. Faucibus in volutpat morbi accumsan et augue et eget eu.
                </Typography>
                <Typography>In general, which method of shopping do you prefer? In-Store vs. Online.</Typography>


                {/* chart */}

                <Box mt={5} className="chartNav">
                  <Box className="chartNavDropBox">
                    <Typography>{t("recapeVideoReview.recapVideoReviewChatLabel")}</Typography>
                    <Status
                      label=" "
                      itemAry={chartItem}
                      setStatusDrop={setChartDrop}
                      statusDrop={chartDrop}
                      setStatusDropVal={setChartDropVal}
                      statusDropVal={chartDropVal}
                    />
                  </Box>

                  <Box className="chartNavDropBox">
                    <Typography>{t("recapeVideoReview.recapVideoReviewValueLabel")}</Typography>
                    <Status
                      label=" "
                      itemAry={countItem}
                      setStatusDrop={setCountDrop}
                      statusDrop={countDrop}
                      setStatusDropVal={setCountDropVal}
                      statusDropVal={countDropVal}
                    />
                  </Box>

                  <Box className="chartNavDropBox">
                    <Typography>{t("recapeVideoReview.recapVideoReviewPrecisionScaleLabel")}</Typography>
                    <Status
                      label=" "
                      itemAry={scaleItem}
                      setStatusDrop={setScaleDrop}
                      statusDrop={scaleDrop}
                      setStatusDropVal={setScaleDropVal}
                      statusDropVal={scaleDropVal}
                    />
                  </Box>
                </Box>

                <Box mt={2} className="tabelNav tabelNav1">
                  <Typography></Typography>
                  <Box
                    className="exporOpenBtn"
                    onClick={() => setExportDrop3(!exportDrop3)}
                  >
                    <img src={exportIcon} />
                    <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
                    <img
                      src={dropIcon}
                      style={{ rotate: exportDrop3 ? "180deg" : "0deg" }}
                    />
                    <Box
                      className="statusPop"
                      sx={{ height: exportDrop3 ? "auto" : "0px" }}
                    >
                      {exportItem?.map((el, i) => (
                        <Box className="statusPopItem">
                          <p>{el}</p>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>

                <Box className="pieBox">
                  <LineChart2 />
                </Box>

                <Box className="tabelNav">
                  <Typography className="pageTitle">{t("recapPollMultiPage.summaryData")}</Typography>
                  <Box className="summaryNav">
                    <Box className="chartTableNav">
                      <Box className="chartNavItem">
                        <Typography>{t("commonTxt.hideBtn")}</Typography>
                      </Box>
                      <Box className="chartNavItem">
                        <Typography>{t("commonTxt.groupBtn2")}</Typography>
                      </Box>
                      <Box className="chartNavItem">
                        <Typography>{t("commonTxt.changeColorBtn")}</Typography>
                      </Box>
                    <Box
                      className="exporOpenBtn"
                      onClick={() => setExportDrop4(!exportDrop4)}
                    >
                      <img src={exportIcon} />
                      <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
                      <img
                        src={dropIcon}
                        style={{ rotate: exportDrop4 ? "180deg" : "0deg" }}
                      />
                      <Box
                        className="statusPop"
                        sx={{ height: exportDrop4 ? "auto" : "0px" }}
                      >
                        {exportItem?.map((el, i) => (
                          <Box className="statusPopItem">
                            <p>{el}</p>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    </Box>
                    
                  </Box>
                </Box>


                <Box mb={6} className="workListBox">
                  <Box className="haderRow" minWidth={"955px"}>
                    <Box minWidth={"20%"} className="Tabelsel TabelStatesel">
                      <Typography>{t("recapeVideoReview.tblAction")}</Typography>
                    </Box>
                    <Box minWidth={"15%"} className="Tabelsel">
                      <Typography>{t("recapeVideoReview.tblColor")}</Typography>
                    </Box>
                    <Box minWidth={"30%"} className="Tabelsel TabelStatesel">
                      <Typography>{t("recapeVideoReview.tblTag")}</Typography>
                    </Box>

                    <Box minWidth={"13%"} className="Tabelsel">
                      <Typography>{t("recapeVideoReview.tblResponses")}</Typography>
                    </Box>
                    <Box minWidth={"13%"} className="Tabelsel">
                      <Typography>{t("recapeVideoReview.tblPercentage")}</Typography>
                    </Box>
                  </Box>

                  <Box className="TabelRow" minWidth={"955px"}>
                    <Box
                      minWidth={"20%"}
                      gap={"40px"}
                      className="Tabelsel navFsel navtable"
                    >
                      <img src={Option2LIcon} />
                      <input type="checkBox" className="custom-checkbox" />
                    </Box>
                    <Box minWidth={"15%"} className="Tabelsel Tabelcentersel">
                      <Box className="colorBox" sx={{ background: "#FF4E72" }}></Box>
                    </Box>
                    <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                      <img src={editIcon} />
                      <Typography>😍</Typography>
                      <Typography>Like</Typography>
                    </Box>
                    <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                      <Typography>18</Typography>
                    </Box>
                    <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                      <Typography>58.2%</Typography>
                    </Box>
                  </Box>

                  <Box className="TabelRow" minWidth={"955px"}>
                    <Box
                      minWidth={"20%"}
                      gap={"40px"}
                      className="Tabelsel navFsel navtable"
                    >
                      <img src={Option2LIcon} />
                      <input type="checkBox" className="custom-checkbox" />
                    </Box>
                    <Box minWidth={"15%"} className="Tabelsel Tabelcentersel">
                      <Box className="colorBox" sx={{ background: "#21A1F9" }}></Box>
                    </Box>
                    <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                      <img src={editIcon} />
                      <Typography>😟</Typography>
                      <Typography>Dislike</Typography>
                    </Box>
                    <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                      <Typography>05</Typography>
                    </Box>
                    <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                      <Typography>18.5%</Typography>
                    </Box>
                  </Box>
                  <Box className="TabelRow" minWidth={"955px"}>
                    <Box
                      minWidth={"20%"}
                      gap={"40px"}
                      className="Tabelsel navFsel navtable"
                    >
                      <img src={Option2LIcon} />
                      <input type="checkBox" className="custom-checkbox" />
                    </Box>
                    <Box minWidth={"15%"} className="Tabelsel Tabelcentersel">
                      <Box className="colorBox" sx={{ background: "#FFBE28" }}></Box>
                    </Box>
                    <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                      <img src={editIcon} />
                      <Typography>😟</Typography>
                      <Typography>Dislike</Typography>
                    </Box>
                    <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                      <Typography>05</Typography>
                    </Box>
                    <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                      <Typography>18.5%</Typography>
                    </Box>
                  </Box>
                  <Box className="TabelRow" minWidth={"955px"}>
                    <Box
                      minWidth={"20%"}
                      gap={"40px"}
                      className="Tabelsel navFsel navtable"
                    >
                      <img src={Option2LIcon} />
                      <input type="checkBox" className="custom-checkbox" />
                    </Box>
                    <Box minWidth={"15%"} className="Tabelsel Tabelcentersel">
                      <Box className="colorBox" sx={{ background: "#41AD0E" }}></Box>
                    </Box>
                    <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                      <img src={editIcon} />
                      <Typography>😟</Typography>
                      <Typography>Dislike</Typography>
                    </Box>
                    <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                      <Typography>05</Typography>
                    </Box>
                    <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                      <Typography>18.5%</Typography>
                    </Box>
                  </Box>
                </Box>




                <Box className="tabelNav">
                  <Typography className="pageTitle">{t("recapPollMultiPage.responseSummaryHead")} (10)</Typography>
                  <Box
                    className="exporOpenBtn"
                    onClick={() => setExportDrop5(!exportDrop5)}
                  >
                    <img src={exportIcon} />
                    <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
                    <img
                      src={dropIcon}
                      style={{ rotate: exportDrop5 ? "180deg" : "0deg" }}
                    />
                    <Box
                      className="statusPop"
                      sx={{ height: exportDrop5 ? "auto" : "0px" }}
                    >
                      {exportItem?.map((el, i) => (
                        <Box className="statusPopItem">
                          <p>{el}</p>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>

                {/* table */}


                <Box className="workListBox">
                  <Box className="haderRow" minWidth={"1111px"}>
                    <Box
                      // minWidth={"290px"}
                      minWidth={"19.5%"}
                      className="Tabelsel TabelStatesel"
                    >
                      <Typography>{t("recapeVideoReview.tblName")}</Typography>
                    </Box>
                    <Box
                      //  minWidth={"130px"}
                      minWidth={"12.5%"}
                      className="Tabelsel"
                    >
                      <Typography>{t("recapPollMultiPage.tblChoice")}</Typography>
                    </Box>
                    <Box minWidth={"7.5%"} className="Tabelsel">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                        <path d="M5.83317 18.4401H3.33317C2.89114 18.4401 2.46722 18.2645 2.15466 17.9519C1.8421 17.6394 1.6665 17.2155 1.6665 16.7734V10.9401C1.6665 10.4981 1.8421 10.0742 2.15466 9.76159C2.46722 9.44903 2.89114 9.27344 3.33317 9.27344H5.83317M11.6665 7.60677V4.27344C11.6665 3.6104 11.4031 2.97451 10.9343 2.50567C10.4654 2.03683 9.82954 1.77344 9.1665 1.77344L5.83317 9.27344V18.4401H15.2332C15.6351 18.4446 16.0252 18.3038 16.3314 18.0434C16.6377 17.7831 16.8396 17.4209 16.8998 17.0234L18.0498 9.52344C18.0861 9.28457 18.07 9.04067 18.0026 8.80865C17.9353 8.57662 17.8183 8.36202 17.6597 8.1797C17.5012 7.99738 17.3049 7.85171 17.0845 7.75279C16.8641 7.65386 16.6248 7.60403 16.3832 7.60677H11.6665Z" stroke="#475467" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </Box>

                    <Box minWidth={"7.5%"} className="Tabelsel">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                        <path d="M14.1667 1.77374H16.3917C16.8633 1.7654 17.3216 1.93052 17.6795 2.23775C18.0375 2.54499 18.2701 2.97295 18.3334 3.44041V9.27374C18.2701 9.74119 18.0375 10.1692 17.6795 10.4764C17.3216 10.7836 16.8633 10.9487 16.3917 10.9404H14.1667M8.33336 12.6071V15.9404C8.33336 16.6034 8.59675 17.2393 9.06559 17.7082C9.53443 18.177 10.1703 18.4404 10.8334 18.4404L14.1667 10.9404V1.77374H4.76669C4.36475 1.76919 3.97471 1.91007 3.66844 2.1704C3.36216 2.43073 3.16029 2.79298 3.10003 3.19041L1.95003 10.6904C1.91377 10.9293 1.92988 11.1732 1.99724 11.4052C2.0646 11.6372 2.18161 11.8518 2.34014 12.0341C2.49868 12.2165 2.69496 12.3621 2.91538 12.4611C3.13581 12.56 3.3751 12.6098 3.61669 12.6071H8.33336Z" stroke="#475467" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </Box>
                    <Box minWidth={"7.5%"} className="Tabelsel">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                        <path d="M17.5 12.6055C17.5 13.0475 17.3244 13.4714 17.0118 13.784C16.6993 14.0965 16.2754 14.2721 15.8333 14.2721H5.83333L2.5 17.6055V4.27214C2.5 3.83011 2.67559 3.40618 2.98816 3.09362C3.30072 2.78106 3.72464 2.60547 4.16667 2.60547H15.8333C16.2754 2.60547 16.6993 2.78106 17.0118 3.09362C17.3244 3.40618 17.5 3.83011 17.5 4.27214V12.6055Z" stroke="#475467" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </Box>
                    <Box minWidth={"12.5%"} className="Tabelsel statusSel">
                      <Typography>{t("recapPollMultiPage.tblExcerpts")}</Typography>
                    </Box>
                    <Box minWidth={"12.5%"} className="Tabelsel statusSel">
                      <Typography>{t("projectReview.tblReviewed")}</Typography>
                    </Box>
                    <Box minWidth={"19.5%"} className="Tabelsel statusSel TabelStatesel">
                      <Typography>{t("recapeVideoReview.tblLastEdit")}</Typography>
                    </Box>
                  </Box>
                  {newArrayData &&
                    newArrayData?.map((el, index) => (
                      <Box
                        key={index}
                        className="TabelRow"
                        minWidth={"1111px"}
                      >
                        <Box
                          minWidth={"19.5%"}
                          className="Tabelsel navFsel navtable"
                        >
                          <Box
                            id="dot"
                            onClick={() => {
                              setWorkspaceOption(!workSpaceOption);
                              setOptionIndex(index);
                            }}
                            className={
                              workSpaceOption && index === optionIndex
                                ? "dot dotActive"
                                : "dot"
                            }
                          >
                            {
                              <Box
                                sx={{
                                  display:
                                    workSpaceOption && index === optionIndex
                                      ? "flex"
                                      : "none",
                                }}
                                className="optionPop"
                              >
                                <Box className="optionPopItem">
                                  <Typography>{t("recapPollMultiPage.viewProfile")}</Typography>
                                </Box>
                                <Box className="optionPopItem">
                                  <Typography>
                                  {t("recapPollMultiPage.markAllAsReviewed")}
                                  </Typography>
                                </Box>
                                <Box className="optionPopItem">
                                  <Typography>{t("recapPollMultiPage.sendMessage")}</Typography>
                                </Box>
                                <Box className="optionPopItem">
                                  <Typography>{t("recapPollMultiPage.supportLogin")}</Typography>
                                </Box>
                                <Box className="optionPopItem">
                                  <Typography>{t("recapPollMultiPage.actionLog")}</Typography>
                                </Box>
                                <Box className="optionPopItem">
                                  <Typography>{t("recapPollMultiPage.excerpts")}</Typography>
                                </Box>
                                <Box className="optionPopItem">
                                  <Typography>{t("recapPollMultiPage.reports")}</Typography>
                                </Box>
                                <Box className="optionPopItem">
                                  <Typography>{t("recapPollMultiPage.transcripts")}</Typography>
                                </Box>
                                <Box className="optionPopItem">
                                  {trashBlack}
                                  <Typography>
                                  {t("recapPollMultiPage.deleteAllResponses")}
                                  </Typography>
                                </Box>
                              </Box>
                            }
                          </Box>
                          <Box
                            onClick={() => handleOptionIndex(index)}
                            className="tabelAvatar"
                          >
                            <img src={el.img} />
                          </Box>
                          <Typography
                            sx={{ cursor: "pointer", width: "95%" }}
                          >
                            {el.name}
                          </Typography>
                        </Box>
                        <Box
                          minWidth={"12.5%"}
                          className="Tabelsel Tabelcentersel"
                        >
                          <Typography>
                            {el.choice}
                          </Typography>
                        </Box>

                        <Box
                          minWidth={"7.5%"}
                          className="Tabelsel Tabelcentersel"
                        >
                          <Typography>{el.like}</Typography>
                        </Box>

                        <Box
                          minWidth={"7.5%"}
                          className="Tabelsel Tabelcentersel"
                        >
                          <Typography pl={1}>{el.disLike}</Typography>
                        </Box>
                        <Box
                          minWidth={"7.5%"}
                          className="Tabelsel Tabelcentersel"
                        >
                          <Typography>{el.message}</Typography>
                        </Box>
                        <Box
                          minWidth={"12.5%"}
                          className="Tabelsel Tabelcentersel"
                        >
                          <Typography>{el.excerpts}</Typography>
                        </Box>
                        <Box
                          minWidth={"12.5%"}
                          className="Tabelsel statusSel Tabelcentersel"
                        >
                          {el.Revieweb === "tik" ? (
                            <img src={tik1} />
                          ) : el.Revieweb ===
                            "empty" ? null : el.Revieweb === "error" ? (
                              <img src={redcircle} />
                            ) : null}
                        </Box>
                        <Box
                          minWidth={"19.5%"}
                          className="Tabelsel statusSel TabelStatesel"
                        >
                          <Typography>{el.date}</Typography>
                        </Box>
                      </Box>
                    ))}
                </Box>

                <Box className="pagenation">
                  <Box onClick={handlePrev} className="tabelBtn">
                    <img src={arrow} />
                    <Typography>{t("commonTxt.previouspage")}</Typography>
                  </Box>

                  <Box className="pageNumberBox">
                    <Box
                      onClick={() => setCurrentPage(0)}
                      className={
                        currentPage === 0
                          ? "pageNumber pageNumberActive"
                          : "pageNumber"
                      }
                    >
                      <p>1</p>
                    </Box>
                    <Box
                      onClick={() => setCurrentPage(1)}
                      className={
                        currentPage === 1
                          ? "pageNumber pageNumberActive"
                          : "pageNumber"
                      }
                    >
                      <p>2</p>
                    </Box>
                    <Box
                      onClick={() => setCurrentPage(2)}
                      className={
                        currentPage === 2
                          ? "pageNumber pageNumberActive"
                          : "pageNumber"
                      }
                    >
                      <p>3</p>
                    </Box>
                    <Box
                      onClick={() => setCurrentPage(3)}
                      className={
                        currentPage === 3
                          ? "pageNumber pageNumberActive"
                          : "pageNumber"
                      }
                    >
                      <p>4</p>
                    </Box>
                    <Box
                      onClick={() => setCurrentPage(4)}
                      className={
                        currentPage === 4
                          ? "pageNumber pageNumberActive"
                          : "pageNumber"
                      }
                    >
                      <p>5</p>
                    </Box>
                  </Box>

                  <Box onClick={handleNext} className="tabelBtn">
                    <Typography>{t("commonTxt.nextPage")}</Typography>
                    <img style={{ rotate: "180deg" }} src={arrow} />
                  </Box>
                </Box>
              </Box>
            </Box >


          </Box>
        </Box>
      </Box>
    </>
  );
}
