import React from "react";
import { Box, Typography } from "@mui/material";

//images
import autoTham from "../../../Assets/Images/audioTham.png";
import audioWave from "../../../Assets/Images/audioWav.png";
import Play from "../../../Assets/Images/play.png";

export default function Audio() {
  const audioCard = ({ img, title, time }) => {
    return (
      <Box className="audioCard">
        <Box className="autioInfo">
          <Box className="audioIcon">
            <img src={autoTham} />
          </Box>
          <Typography>{title}</Typography>
        </Box>
        <Box className="audioWave">
          <img className="play" src={Play} />
          <img className="wave" src={audioWave} />
          <img className="wave" src={audioWave} />
          <Typography>08:16</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box className="audioBox">
        {audioCard({ title: "Trekking Essentials Review" })}
        {audioCard({ title: "Hiking mountains Review" })}
        {audioCard({ title: "Sit amet dolor Review" })}
        {audioCard({ title: "Sit amet dolor Review" })}
        {audioCard({ title: "Sit amet dolor Review" })}
        {audioCard({ title: "Sit amet dolor Review" })}
        {audioCard({ title: "Sit amet dolor Review" })}
      </Box>
    </>
  );
}
