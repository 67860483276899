import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//images
import iIcon2 from "../../../Assets/Images/IIcon.png";
import videoClipImg from "../../../Assets/Images/video-playlist.png";
import arrow from "../../../Assets/Images/arrow.png";
import "./style.css";
//data
import { sideHaumIcon } from "../../../Assets/Data";

//components
import SideMenu from "../../../Components/Researchers/SideMenu";
import AddVideoPlaylistPop from "../../../Components/Researchers/AddVideoPlaylistPop";
import { AppBtn, AppBtnOutLine } from "../../../Components/AppTools/AppButton";

export default function ProjectVideoClip({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
}) {
  const [addVideoPlaylistPop, setAddVideoPlaylistPop] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const Navigate = useNavigate();

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  return (
    <>
      <Box className="ResearchersContainer ">
        {/* side menu */}
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
          </Box>
          {/* Video Clip Section */}
          <Box className="researchersMainBox pageContainer">
            <AddVideoPlaylistPop
              addVideoPlaylistPop={addVideoPlaylistPop}
              setAddVideoPlaylistPop={setAddVideoPlaylistPop}
            />
            <Box className="integrationsContainer">
              <Box className="projectVideoPlaylistBox">
                <Box className="projectVideoPlaylistHeadBtn">
                  <Box className="videoPlaylistHead">
                    <Typography className="emailNotificationHeaderText">
                      {t("videoClipPage.videoClipHead")}
                    </Typography>
                  </Box>
                  <Box className="videoPlaylistHeadBtn">
                    <AppBtn
                      buttonText={t("commonTxt.addPlaylist")}
                      onClick={() => setAddVideoPlaylistPop(true)}
                    />
                  </Box>
                </Box>

                <Box className="iBox videoPlaylistiBox">
                  <img src={iIcon2} />
                  <Typography>{t("videoClipPage.videoClipNotify")}</Typography>
                </Box>

                <Box className="projectVideoPlaylistMain">
                  <Box className="projectVideoPlaylistSection">
                    <Box className="videoPlaylistImg">
                      <img src={videoClipImg} />
                      <Box className="videoImgLabel">
                        <Typography>1:02</Typography>
                      </Box>
                    </Box>
                    <Box className="videoPlaylistText">
                      <Box className="videoClipHeadBtn">
                        <Box>
                          <Typography className="eTemPopLabel">
                            Josh B
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="videoPlaylistDes">
                        <Box>
                          <Typography>
                            This collection of quotes is taken from week 4. We
                            presented two concepts and got some great reactions
                            to concept B. This highlights the overall how people
                            felt about the inclusion of a widget in power
                            adapter area. Overall they found the unit to be.....
                          </Typography>
                        </Box>

                        <Box>
                          <Typography className="neonBackText">
                            {"Product Feedback > Concept B"}
                          </Typography>
                        </Box>
                        <Box className="videoPlaylistLinkTime">
                          <Box className="videoClipBtn">
                            <Typography>{t("commonTxt.positive")}</Typography>
                          </Box>
                          <Box className="videoClipBtn">
                            <Typography>{t("commonTxt.greatQuote")}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="vDBtnBox">
                      <Box className="videoClipEditDwnldBtn">
                        <Typography className="taskHeaderText">
                          {t("commonTxt.edit")}
                        </Typography>
                        <Typography className="taskHeaderText">
                          {t("commonTxt.download")}
                        </Typography>
                      </Box>
                      <Typography>Jun 30</Typography>
                    </Box>
                  </Box>
                  <Box className="projectVideoPlaylistSection">
                    <Box className="videoPlaylistImg">
                      <img src={videoClipImg} />
                      <Box className="videoImgLabel">
                        <Typography>1:02</Typography>
                      </Box>
                    </Box>
                    <Box className="videoPlaylistText">
                      <Box className="videoClipHeadBtn">
                        <Box>
                          <Typography className="eTemPopLabel">
                            Josh B
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="videoPlaylistDes">
                        <Box>
                          <Typography>
                            This collection of quotes is taken from week 4. We
                            presented two concepts and got some great reactions
                            to concept B. This highlights the overall how people
                            felt about the inclusion of a widget in power
                            adapter area. Overall they found the unit to be.....
                          </Typography>
                        </Box>

                        <Box>
                          <Typography className="neonBackText">
                            {"Product Feedback > Concept B"}
                          </Typography>
                        </Box>
                        <Box className="videoPlaylistLinkTime">
                          <Box className="videoClipBtn">
                            <Typography>{t("commonTxt.positive")}</Typography>
                          </Box>
                          <Box className="videoClipBtn">
                            <Typography>{t("commonTxt.greatQuote")}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="vDBtnBox">
                      <Box className="videoClipEditDwnldBtn">
                        <Typography className="taskHeaderText">
                          {t("commonTxt.edit")}
                        </Typography>
                        <Typography className="taskHeaderText">
                          {t("commonTxt.download")}
                        </Typography>
                      </Box>
                      <Typography>Jun 30</Typography>
                    </Box>
                  </Box>
                  <Box className="projectVideoPlaylistSection">
                    <Box className="videoPlaylistImg">
                      <img src={videoClipImg} />
                      <Box className="videoImgLabel">
                        <Typography>1:02</Typography>
                      </Box>
                    </Box>
                    <Box className="videoPlaylistText">
                      <Box className="videoClipHeadBtn">
                        <Box>
                          <Typography className="eTemPopLabel">
                            Josh B
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="videoPlaylistDes">
                        <Box>
                          <Typography>
                            This collection of quotes is taken from week 4. We
                            presented two concepts and got some great reactions
                            to concept B. This highlights the overall how people
                            felt about the inclusion of a widget in power
                            adapter area. Overall they found the unit to be.....
                          </Typography>
                        </Box>

                        <Box>
                          <Typography className="neonBackText">
                            {"Product Feedback > Concept B"}
                          </Typography>
                        </Box>
                        <Box className="videoPlaylistLinkTime">
                          <Box className="videoClipBtn">
                            <Typography>{t("commonTxt.positive")}</Typography>
                          </Box>
                          <Box className="videoClipBtn">
                            <Typography>{t("commonTxt.greatQuote")}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="vDBtnBox">
                      <Box className="videoClipEditDwnldBtn">
                        <Typography className="taskHeaderText">
                          {t("commonTxt.edit")}
                        </Typography>
                        <Typography className="taskHeaderText">
                          {t("commonTxt.download")}
                        </Typography>
                      </Box>
                      <Typography>Jun 30</Typography>
                    </Box>
                  </Box>
                </Box>
                {/* Pagination */}
                <Box className="pagenation">
                  <Box onClick={handlePrev} className="tabelBtn">
                    <img src={arrow} />
                    <Typography>{t("commonTxt.previouspage")}</Typography>
                  </Box>

                  <Box className="pageNumberBox">
                    <Box
                      onClick={() => setCurrentPage(0)}
                      className={
                        currentPage === 0
                          ? "pageNumber pageNumberActive"
                          : "pageNumber"
                      }
                    >
                      <p>1</p>
                    </Box>
                    <Box
                      onClick={() => setCurrentPage(1)}
                      className={
                        currentPage === 1
                          ? "pageNumber pageNumberActive"
                          : "pageNumber"
                      }
                    >
                      <p>2</p>
                    </Box>
                    <Box
                      onClick={() => setCurrentPage(2)}
                      className={
                        currentPage === 2
                          ? "pageNumber pageNumberActive"
                          : "pageNumber"
                      }
                    >
                      <p>3</p>
                    </Box>
                    <Box
                      onClick={() => setCurrentPage(3)}
                      className={
                        currentPage === 3
                          ? "pageNumber pageNumberActive"
                          : "pageNumber"
                      }
                    >
                      <p>4</p>
                    </Box>
                    <Box
                      onClick={() => setCurrentPage(4)}
                      className={
                        currentPage === 4
                          ? "pageNumber pageNumberActive"
                          : "pageNumber"
                      }
                    >
                      <p>5</p>
                    </Box>
                  </Box>

                  <Box onClick={handleNext} className="tabelBtn">
                    <Typography>{t("commonTxt.nextPage")}</Typography>
                    <img style={{ rotate: "180deg" }} src={arrow} />
                  </Box>
                </Box>
                {/* Pagination */}
                <Box className="workPupBtnBox">
                  <AppBtnOutLine
                    onClick={() => Navigate("/project-management")}
                    buttonText={t("commonTxt.cancelBtn")}
                  />
                  <AppBtn buttonText={t("commonTxt.continueBtn")} />
                </Box>
              </Box>
            </Box>
          </Box>
          {/* Video Clip Section */}
        </Box>
      </Box>
    </>
  );
}
