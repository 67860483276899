import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AppBtnOutLine, AppBtn } from "../../AppTools/AppButton";
import { useTranslation } from "react-i18next";
import "./style.css";

import ReferenceDateUsingValue from "../../AppTools/DatePicker";
import iIcon2 from "../../../Assets/Images/IIcon.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";

export default function AdjustPointPopup({ allDropVal4, setAllDropVal4 }) {

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const [selectAdminDrop, setSelectAdminDrop] = useState(false);
  const [selectAdminDropVal, setSelectAdminDropVal] = useState();
  const [adjustPointNav, setAdjustPointNav] = useState(0);

  const email1 = ""
  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();

  const [redio, setRedio] = useState(0);

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
        setAllDropVal4("Options");
      }
  };

  useEffect(() => {
    if (allDropVal4 === "Adjust Points") {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [allDropVal4]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: allDropVal4 === "Adjust Points" ? "flex" : "none" }}
        className="companyPop AddCardPopPeople"
      >
        <Box
          sx={{ display: allDropVal4 === "Adjust Points" ? "flex" : "none" }}
          className="workspaceContaint deletePagePop"
        >
          <Typography className="emailNotificationHeaderText">{t("adjustPointPopup.adjustPointPopupHead")}</Typography>
          <Box mt={7} mb={2} className="adjustPointPopNav">
                  <Box
                    onClick={() => setAdjustPointNav(0)}
                    className={
                        adjustPointNav === 0
                        ? "pprofNavItem pprofNavItemActive"
                        : "pprofNavItem"
                    }
                  >
                    <Typography>{t("adjustPointPopup.adjustPointPopupNav1")}</Typography>
                  </Box>
                  <Box
                    onClick={() => setAdjustPointNav(1)}
                    className={
                        adjustPointNav === 1
                        ? "pprofNavItem pprofNavItemActive"
                        : "pprofNavItem"
                    }
                  >
                    <Typography>{t("adjustPointPopup.adjustPointPopupNav2")}</Typography>
                  </Box>
                  <Box
                    onClick={() => setAdjustPointNav(2)}
                    className={
                        adjustPointNav === 2
                        ? "pprofNavItem pprofNavItemActive"
                        : "pprofNavItem"
                    }
                  >
                    <Typography>{t("adjustPointPopup.adjustPointPopupNav3")}</Typography>
                  </Box>
                </Box>
          <Box className="iBox projectIBox">
            <img src={iIcon2} />
            <Typography>{t("adjustPointPopup.notifyMsg")}</Typography>
        </Box>
          <Typography className="eTemPopLabel">{t("adjustPointPopup.adjustAddPointLabel")}</Typography>
            <Box className="input_Box">
                <Box
                    className="inputAppDrop"
                    onClick={() => setSelectAdminDrop(!selectAdminDrop)}
                >
                    <Typography>{selectAdminDropVal || "Select Admin"}</Typography>
                    <img
                    style={{ rotate: selectAdminDrop ? "180deg" : "0deg" }}
                    src={dropArrow}
                    />
                    {/* pop */}
                    <Box
                    sx={{ height: selectAdminDrop ? "auto" : "0px" }}
                    className="statusPop"
                    >
                    <Box
                        onClick={() => setSelectAdminDropVal("Admin 1")}
                        className="statusPopItem"
                    >
                        <Typography>Admin 1</Typography>
                    </Box>
                    <Box
                        onClick={() => setSelectAdminDropVal("Admin 2")}
                        className="statusPopItem"
                    >
                        <Typography>Admin 2</Typography>
                    </Box>
                   
                    </Box>
                </Box>
            </Box>
            <Typography mt={3} className="eTemPopLabel">{t("adjustPointPopup.adjustPointDateLabel")}</Typography>
            <Box className="input_Box">
                <Box className="pickerBox adjustPointDateLabel">
                    <ReferenceDateUsingValue />
                </Box>
            </Box>
          {/* textarea */}
          <Typography className="revokeTextarealabel">{t("revokePointPopup.revokePointReasonLabel")}</Typography>
          <Typography className="revokeSubLabel">{t("revokePointPopup.revokePointReasonSubLabel")}</Typography>
          <Box className="emailInputBox textTaskInstruction">
            <Box className="emailInputBoxNav">
            </Box>
            <textarea
              style={{
                textAlign: messageTextLaOut,
                fontStyle: messageTextItalic ? "italic" : "normal",
                fontWeight: messageTextBold ? "bold" : "normal",
                textDecoration: messageTextUnderLine,
                // textDecoration: messageTextMLine ? "line-through" : null
              }}
              className="emailInput"
              placeholder=""
              name="emailMessage"
              value={emailval?.emailMessage || email1}
            />
          </Box>
          {/* textarea */}
          <Box mt={3} className="redioItem" onClick={() => setRedio(0)}>
            <Box
              className={
                redio === 0
                  ? "customRedio customRedioActive"
                  : "customRedio"
              }
            >
              <Box></Box>
            </Box>
            <Typography>{t("revokePointPopup.revokePointRadioOpt1")}</Typography>
          </Box>
          
          <Box mt={3} className="redioItem" onClick={() => setRedio(1)}>
            <Box
              className={
                redio === 1
                  ? "customRedio customRedioActive"
                  : "customRedio"
              }
            >
              <Box></Box>
            </Box>
            <Typography>{t("revokePointPopup.revokePointRadioOpt2")}</Typography>
          </Box>
            
            <Box className="workPupBtnBox">
              <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => setAllDropVal4("Options")} />
              <AppBtn buttonText={t("commonTxt.adjustPointBtn")} />
            </Box>
         
        </Box>
      </Box>
    </>
  );
}
