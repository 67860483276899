import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from "react-i18next";
import "./style.css";

//images
import groupIcon from "../../../Assets/Images/groupsIcon.png";
import filterIcon from "../../../Assets/Images/filterIcon.png"
import idelDot from "../../../Assets/Images/idelDot.png"
import avatar1 from "../../../Assets/Images/inb1.png"
import avatar2 from "../../../Assets/Images/inb2.png"
import avatar3 from "../../../Assets/Images/inb3.png"
import AdminBG from "../../../Assets/Images/Add-files-cuate.png";
import exportIcon from "../../../Assets/Images/exportIcon.png";
import bannerImg from "../../../Assets/Images/expBanner.png"
import arrow from "../../../Assets/Images/arrow.png";
import eye from "../../../Assets/Images/eye.png"
import eyeOff from "../../../Assets/Images/eye-off.png"



//component
import { like, dislike, comment, tik } from "../../../Assets/Data";
import { SearchBar, Status } from '../../AppTools/AppTool';

//data
import { expressionData } from "../../../Assets/Data"



export default function Expressions() {
  const [nav, setNav] = useState(0)
  const [hl, setHl] = useState(false)
  const [hlVal, setHlVal] = useState("Highest to Lowest")
  const [currentPage, setCurrentPage] = useState(0);
  const [newArrayData, setNewArrayData] = useState([]);

  const hlItems = [
    "item1",
    "item2",
    "item3",
  ]

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  useEffect(() => {
    printItemsByState(expressionData, currentPage);
  }, [currentPage]);



  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  const threadData = true;

  const CommentSection = ({ img, title, subTitle1, subTitle2, date, summery }) => {
    return (
      <Box className="commentSection">
        <Box className="avatarBox">
          <img src={img} />
          <img className='statusDot' src={idelDot} />
        </Box>

        <Box className="commentTextSection rmBPadding">

          <Box mb={5} className="taskBoxTopDate">
            <Typography className='userNameWspm'>
              {title}
              <span>{subTitle1}</span>
              <samp>{subTitle2}</samp>
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <Typography className="taskHeaderText2" >{t("commonTxt.viewBtn")}</Typography>
              <samp className="taskHeaderText3">{date}</samp>
            </Box>
          </Box>
          <Typography>{summery}</Typography>
          <Box mt={3} className="taskBoxBottomRow">
            <Box className="taskBoxBottomRowItem">
              {like}
              <Typography>{t("commonTxt.Like")}</Typography>
            </Box>
            <Box className="taskBoxBottomRowItem">
              {dislike}
              <Typography>{t("commonTxt.dislike")}</Typography>
            </Box>
            <Box className="taskBoxBottomRowItem">
              {comment}
              <Typography>{t("commonTxt.comment")}</Typography>
            </Box>
            <Box className="taskBoxBottomRowItem">
              {tik}
              <Typography>{t("commonTxt.review")}</Typography>
            </Box>
          </Box>

        </Box>
      </Box>

    )
  }



  return (
    <>
      {!threadData ?
        <Box className="threadNoDataPage">
          <Box className="adminWorkSBG">
            <img src={AdminBG} />
          </Box>
          <Box className="adminBGTextBox">
            <Typography className="adminSubHeaderText">
            {t("recapExpression.expresssionText")}
            </Typography>
          </Box>
        </Box>
        :
        <Box className="threadContainer workspaceListBox">
          <Box className="threadNavBox">
            <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <Box className="actTNavBtn">
                <img src={groupIcon} />
                <Typography>{t("commonTxt.groupBtn")}</Typography>
              </Box>

              <Box className="filterBtn">
                <img src={filterIcon} />
                <p>{t("commonTxt.filters")}</p>
              </Box>
            </Box>

            <Box className="actTNavBtn">
              <img src={exportIcon} />
              <Typography>{t("commonTxt.exportLink")}</Typography>
            </Box>
          </Box>

          <Box className="expBannerSection">
            <Box className="expSearchBar">
              <Typography>{t("recapExpression.filterText")}</Typography>
              <Box className="searchExp_box"><SearchBar placeholder={t("recapExpression.searchPlaceholder")} /></Box>
            </Box>

            <Box className="bannerBox">
              <img src={bannerImg} />
            </Box>
          </Box>

          <Box className="expTabSection">
            <Box className="expTabNav">
              <Box onClick={() => setNav(0)} className={nav === 0 ? "expTabItem expTabItemActive" : "expTabItem"}>
                <Typography>{t("recapExpression.expressionResultsTab")}</Typography>
              </Box>
              <Box onClick={() => setNav(1)} className={nav === 1 ? "expTabItem expTabItemActive" : "expTabItem"}>
                <Typography>{t("recapExpression.expressionDataTab")}</Typography>
              </Box>
            </Box>
            <Box sx={{ display: nav === 0 ? "block" : "none" }} className="expTabMainSection">
              <Box className="CmmentSectionBox">
                {CommentSection({
                  img: avatar1,
                  title: "Candice Wu",
                  subTitle1: "posted under the Topic",
                  subTitle2: "Recent Camping Outings",
                  summery: "“My most exciting Cycling trip was with my friends in Grindelwald during the summer of 2022.” ",
                  date: "Aug 16, 2023 11:48"
                })}
                {CommentSection({
                  img: avatar2,
                  title: "Andi Lane",
                  subTitle1: "posted under the activity",
                  subTitle2: "Your Great Outdoors",
                  summery: "“Any trip with friends becomes a memorable experience because it’s mostly about the people you go with that makes a trip worth it.”",
                  date: "Aug 4, 2023 11:38"
                })}
                {CommentSection({
                  img: avatar1,
                  title: "Kate Morrison",
                  subTitle1: " posted under the activity",
                  subTitle2: "Your Great Outdoors",
                  summery: "“Any trip with friends becomes a memorable experience because it’s mostly about the people you go with that makes a trip worth it.”",
                  date: "Aug 4, 2023 11:38"
                })}
                {CommentSection({
                  img: avatar3,
                  title: "Kate Morrison",
                  subTitle1: " posted under the activity",
                  subTitle2: "Your Great Outdoors",
                  summery: "“Any trip with friends becomes a memorable experience because it’s mostly about the people you go with that makes a trip worth it.”",
                  date: "Aug 4, 2023 11:38"
                })}
              </Box>
              <Box className="pagenation">
                <Box className="tabelBtn">
                  <img src={arrow} />
                  <Typography>{t("commonTxt.previouspage")}</Typography>
                </Box>

                <Box className="pageNumberBox">
                  <Box
                    className="pageNumber pageNumberActive">
                    <p>1</p>
                  </Box>
                  <Box className="pageNumber">
                    <p>2</p>
                  </Box>
                  <Box className="pageNumber">
                    <p>3</p>
                  </Box>
                  <Box className="pageNumber">
                    <p>4</p>
                  </Box>
                  <Box className="pageNumber">
                    <p>5</p>
                  </Box>
                </Box>

                <Box className="tabelBtn">
                  <Typography>{t("commonTxt.nextPage")}</Typography>
                  <img style={{ rotate: "180deg" }} src={arrow} />
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: nav === 1 ? "block" : "none" }} className="expTabMainSection">
              <Box className="CmmentSectionBox paddingRm">
                <Box className="expSearchBar">
                  <Typography className='pageHeaderText'>{t("recapExpression.listOfWords")} (182)</Typography>
                  <Box className="searchExp_box ExpsearchExp_box"><Status setStatusDrop={setHl} statusDrop={hl} setStatusDropVal={setHlVal} statusDropVal={hlVal} itemAry={hlItems} label={" "} /></Box>
                </Box>
                <Box className="expHeaderRow">

                  <Box minWidth={"15%"} className="expRowItem">
                    <Typography>{t("recapExpression.tblRank")}</Typography>
                  </Box>
                  <Box minWidth={"55%"} className="expRowItem">
                    <Typography>{t("recapExpression.tblWord")}</Typography>
                  </Box>
                  <Box minWidth={"15%"} className="expRowItem expRowICenter">
                    <Typography>{t("recapExpression.tblFrequency")}</Typography>
                  </Box>
                  <Box minWidth={"15%"} className="expRowItem expRowICenter">
                    <Typography>{t("recapExpression.tblReviewed")}</Typography>
                  </Box>

                </Box>
                {
                  newArrayData?.map((el, i) => (
                    <Box className="expRow">
                      <Box minWidth={"15%"} className="expRowItem">
                        <Typography>{el.rank}</Typography>
                      </Box>
                      <Box minWidth={"55%"} className="expRowItem">
                        <Typography>{el.word}</Typography>
                      </Box>
                      <Box minWidth={"15%"} className="expRowItem expRowICenter">
                        <Typography>{el.frequency}</Typography>
                      </Box>
                      <Box minWidth={"15%"} className="expRowItem expRowICenter">
                        <img src={el.Reviewed === "true" ? eye : eyeOff} />
                      </Box>

                    </Box>
                  ))
                }

              </Box>

              <Box className="pagenation">
                <Box className="tabelBtn" onClick={handlePrev} >
                  <img src={arrow} />
                  <Typography>{t("commonTxt.previouspage")}</Typography>
                </Box>

                <Box className="pageNumberBox">
                  <Box onClick={() => setCurrentPage(0)} className={currentPage === 0 ? "pageNumber pageNumberActive" : "pageNumber"}>
                    <p>1</p>
                  </Box>
                  <Box onClick={() => setCurrentPage(1)} className={currentPage === 1 ? "pageNumber pageNumberActive" : "pageNumber"}>
                    <p>2</p>
                  </Box>
                  <Box onClick={() => setCurrentPage(2)} className={currentPage === 2 ? "pageNumber pageNumberActive" : "pageNumber"}>
                    <p>3</p>
                  </Box>
                  <Box onClick={() => setCurrentPage(3)} className={currentPage === 3 ? "pageNumber pageNumberActive" : "pageNumber"}>
                    <p>4</p>
                  </Box>
                  <Box onClick={() => setCurrentPage(4)} className={currentPage === 4 ? "pageNumber pageNumberActive" : "pageNumber"}>
                    <p>5</p>
                  </Box>
                </Box>

                <Box className="tabelBtn" onClick={handleNext}>
                  <Typography>{t("commonTxt.nextPage")}</Typography>
                  <img style={{ rotate: "180deg" }} src={arrow} />
                </Box>
              </Box>


            </Box>






          </Box>





        </Box>
      }
    </>
  )
}
