import { LineChart } from '@mui/x-charts';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const SideLineChart = () => {
  const [chartData, setChartData] = useState({
    series: [{
      data: [400, 430, 448, 470, 540]
    }],
    options: {
      chart: {
        type: 'bar',
        height: 550
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: 'end',
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: ['South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy'
        ],
      }
    }
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default SideLineChart;
