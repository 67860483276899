import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./style.css";
import { SearchBar } from "../../AppTools/AppTool";
//images
import chevronDrop from "../../../Assets/Images/chevron-down.png"
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
//components
export default function ThreadFilterPop({ filterPop, setFilterPop }) {
  const [nav, setNav] = useState(0)
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const activeData = [
    "Your Great Outdoors",
    "Welcome!",
    "Outdoor Activity Journal",
    "The Purchase Pathway",
    "Video & Product Reviews",
    "Lorem Ipsum",
    "Dolor sit amet consect",
    "Sit lingula parturient",
    "Bibendum adipscing",
    "Turpis odio crasid gravida",
    "Sit lingula parturient",
  ]

  const TasksData = [
    "The “Perfect Outdoor Trip",
    "Outdoor Activities",
    "Outdoor Essentials",
    "Outdoor  Locations",
    "Every Good Story",
    "Dream Trip on Budget",
    "Costs you can avoid",
    "Turpis odio crasid",
    "How to save cost efficiently",
    "Outdoor  Locations",
    "Dolor sit amet consectur",
  ]
  const TaskOptionsData = [
    "Camping",
    "Cycling",
    "Fishing",
    "Hiking",
    "Trekking",
    "Lorem ipsum",
    "Dolor sit",
    "Amet Rhoncus",
    "Consectetur",
  ]
  const CategoriesData = [
    "Equipment Zone",
    "Outdoor Tips",
    "Lorem Ipsum",
    "Dolor sit amet",
    "Rhoncus consectetur",
    "Vitae Iaculis Pellentesque",
    "Lacus ultricies sagittis urna",
    "Sit lingula parturient",
    "Bibendum adipscing",
    "Turpis odio crasid gravida",
    "Sit lingula parturient",
    "Lorem Ipsum",
  ]
  const TaskOptionsData2 = [
    "Things to keep in mind",
    "Your trekking must haves",
    "Recent Outdoor Trips",
    "Meal Time",
    "Places to buy your equipments",
    "Lorem Ipsum",
    "Dolor sit amet consectur",
    "Sit lingula parturient",
    "Bibendum adipscing",
    "Turpis odio crasid gravida",
    "Lorem Ipsum",
  ]

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setFilterPop(false);
    }
  };

  useEffect(() => {
    if (filterPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [filterPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: filterPop ? "flex" : "none" }}
        className="companyPop threadFilterPop"
      >
        <Box
          sx={{ display: filterPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className="companyHeader">{t("ParticipantsThreadRecap.FilterPContent")}</Typography>
          <Box className="ThFilterNav">
            <Box onClick={() => setNav(0)} className={nav === 0 ? "ThFilterNavItem ThFilterNavItemActive" : "ThFilterNavItem"}>
              <Typography>{t("workspaceSplash.Activities")}</Typography>
            </Box>
            <Box onClick={() => setNav(1)} className={nav === 1 ? "ThFilterNavItem ThFilterNavItemActive" : "ThFilterNavItem"}>
              <Typography>{t("workspaceHome.conversations")}</Typography>
            </Box>
          </Box>

          <Box className="threadMain_Box" sx={{ display: nav === 0 ? "block" : "none" }}>
            <Box className="thFilterMainBox">
              <Box className="thFilterSearchBox">
                <Typography className="thFilSeText">{t("workspaceSplash.Activities")}</Typography>
                <Box className="thFilSearchBox">
                  <SearchBar placeholder={t("recapExpression.searchPlaceholder")} />
                  <img className="seDropIcon" src={chevronDrop} />
                </Box>
              </Box>

              <Box className="thFilChipBox">
                {activeData?.map((el, i) => (
                  <Box key={i} className="chip">
                    <Typography>{el}</Typography>
                  </Box>
                ))}

              </Box>
              <Box className="propertyPagination">
                <Box>
                  <p>1</p>
                </Box>
                <Box>
                  <p>2</p>
                </Box>
                <Box>
                  <p>3</p>
                </Box>
                <Box>
                  <p>4</p>
                </Box>
              </Box>
            </Box>

            <Box className="thFilterMainBox">
              <Box className="thFilterSearchBox">
                <Typography className="thFilSeText">{t("projectParticipantReport.tblTasks")}</Typography>
                <Box className="thFilSearchBox">
                  <SearchBar placeholder={t("recapExpression.searchPlaceholder")} />
                  <img className="seDropIcon" src={chevronDrop} />
                </Box>
              </Box>

              <Box className="thFilChipBox">
                {TasksData?.map((el, i) => (
                  <Box key={i} className="chip">
                    <Typography>{el}</Typography>
                  </Box>
                ))}

              </Box>
              <Box className="propertyPagination">
                <Box>
                  <p>1</p>
                </Box>
                <Box>
                  <p>2</p>
                </Box>
                <Box>
                  <p>3</p>
                </Box>
                <Box>
                  <p>4</p>
                </Box>
              </Box>
            </Box>

            <Box className="thFilterMainBox">
              <Box className="thFilterSearchBox">
                <Typography className="thFilSeText">{t("ParticipantsThreadRecap.TaskOptions")}</Typography>
                <Box className="thFilSearchBox">
                  <SearchBar placeholder={t("recapExpression.searchPlaceholder")} />
                  <img className="seDropIcon" src={chevronDrop} />
                </Box>
              </Box>

              <Box className="thFilChipBox">
                {TaskOptionsData?.map((el, i) => (
                  <Box key={i} className="chip">
                    <Typography>{el}</Typography>
                  </Box>
                ))}

              </Box>
              <Box className="propertyPagination">
                <Box>
                  <p>1</p>
                </Box>
                <Box>
                  <p>2</p>
                </Box>
                <Box>
                  <p>3</p>
                </Box>
                <Box>
                  <p>4</p>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* Categories */}
          <Box className="threadMain_Box" sx={{ display: nav === 1 ? "block" : "none" }}>
            <Box className="thFilterMainBox">
              <Box className="thFilterSearchBox">
                <Typography className="thFilSeText">{t("recapRankItShortIt.tblCategories")}</Typography>
                <Box className="thFilSearchBox">
                  <SearchBar placeholder={t("recapExpression.searchPlaceholder")} />
                  <img className="seDropIcon" src={chevronDrop} />
                </Box>
              </Box>

              <Box className="thFilChipBox">
                {CategoriesData?.map((el, i) => (
                  <Box key={i} className="chip">
                    <Typography>{el}</Typography>
                  </Box>
                ))}

              </Box>
              <Box className="propertyPagination">
                <Box>
                  <p>1</p>
                </Box>
                <Box>
                  <p>2</p>
                </Box>
                <Box>
                  <p>3</p>
                </Box>
                <Box>
                  <p>4</p>
                </Box>
              </Box>
            </Box>

            <Box className="thFilterMainBox">
              <Box className="thFilterSearchBox">
                <Typography className="thFilSeText">{t("ParticipantsThreadRecap.TaskOptions")}</Typography>
                <Box className="thFilSearchBox">
                  <SearchBar placeholder={t("recapExpression.searchPlaceholder")} />
                  <img className="seDropIcon" src={chevronDrop} />
                </Box>
              </Box>

              <Box className="thFilChipBox">
                {TaskOptionsData2?.map((el, i) => (
                  <Box key={i} className="chip">
                    <Typography>{el}</Typography>
                  </Box>
                ))}

              </Box>
              <Box className="propertyPagination">
                <Box>
                  <p>1</p>
                </Box>
                <Box>
                  <p>2</p>
                </Box>
                <Box>
                  <p>3</p>
                </Box>
                <Box>
                  <p>4</p>
                </Box>
              </Box>
            </Box>

          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => setFilterPop(false)} />
            <AppBtn buttonText={t("commonTxt.applyFilterBtn")} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
