import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from "react-i18next";


//components 
import { AppBtn, AppBtnOutLine } from '../../../Components/AppTools/AppButton';

export default function ProjectTranscript({ projectSettingDropVal }) {

    const {
        t,
        i18n: { changeLanguage, language },
      } = useTranslation();

  return (
    <>   
        <Box sx={{ display: projectSettingDropVal === "Transcription" ? "block" : "none" }}>
            <Box className="projectNotificationMainBox">
                <Box className="projectAppearanceBox">
                    <Box className="projectAppearanceSection">
                        <Typography className='allPartRedioLabel'>{t("projectTranscription.gloassaryLabel")}</Typography>
                        <Typography className='projectNotificationSubHead'>{t("projectTranscription.gloassarySubText")}</Typography>
                        <textarea
                            className="DmettingTextTextAera"
                            defaultValue={"Terapage, Tera"}
                        />
                    </Box>
                </Box>
                
            </Box>
            <Box className="workPupBtnBox">
                <AppBtnOutLine buttonText={t("commonTxt.resetBtn")} />
                <AppBtn buttonText={t("commonTxt.saveBtn")} />
            </Box>
        </Box>
    </>
  )
}
