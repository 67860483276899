import React, { useState, useEffect } from "react";
import { Box, Typography, Avatar, Grid, Button, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.css";

//images
import backArrow from "../../../Assets/Images/noun-back.png";
import ProfileImage from "../../../Assets/Images/profile-picture.png";
import detailsIcon from "../../../Assets/Images/icon-info.svg";
import shieldsIcon from "../../../Assets/Images/shiled.png";

//data
import {
  participants,
  sideHaumIcon,
} from "../../../Assets/Data";

//components
import {
  AllDrop8,
} from "../../../Components/AppTools/AppTool";
import { AppBtn, AppBtnOutLine } from "../../../Components/AppTools/AppButton";
import SideMenu from "../../../Components/Observer/SideMenu";
import Thread from "../../../Components/Observer/ActivityTasksThread";
import Images from "../../../Components/Observer/ActivityTasksImages";
import Videos from "../../../Components/Observer/ActivityTasksVideos";
import Audio from "../../../Components/Observer/ActivityTasksAudio";
import AdjustPointPopup from "../../../Components/Observer/AdjustPointPopup";
import AddExcerptPop from "../../../Components/Observer/AddExcerptPop";

export default function ViewParticipantProfile({
  workspaceDrop,
  setWorkSpaceDrop,
  slideMenu,
  setSlideMenu,
}) {

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const Navigate = useNavigate();
  const [responsesNav, setResponsesNav] = useState("Thread");

  const [currentPage, setCurrentPage] = useState(0);
  const [newArrayData, setNewArrayData] = useState([]);
  const [allDrop4, setAllDrop4] = useState();
  const [allDropVal4, setAllDropVal4] = useState("Options");
  const [addExcerptPop, setAddExcerptPop] = useState(false);


  useEffect(() => {
    printItemsByState(participants, currentPage);
  }, [currentPage]);

  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }
  //

  return (
    <>
      <Box className="ResearchersContainer ">
        <AdjustPointPopup allDropVal4={allDropVal4} setAllDropVal4={setAllDropVal4} />
        <AddExcerptPop addExcerptPop={addExcerptPop} setAddExcerptPop={setAddExcerptPop} />
        {/* side menu */}
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />

        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>

            <Box sx={{ gap: "15px" }} className="AppNavBtnBox">



              <Box sx={{ width: "30%", minWidth: "196px" }}>
                <AllDrop8
                  drop={allDrop4}
                  setDrop={setAllDrop4}
                  dropVal={allDropVal4}
                  serDropVal={setAllDropVal4}
                />
              </Box>


              <Box>
                <AppBtnOutLine onClick={() => setAddExcerptPop(true)} buttonText={t("commonTxt.actionLogBtn")} />
                <AppBtn buttonText={t("commonTxt.viewMessagesBtn")} onClick={() => Navigate("")} />
              </Box>
            </Box>




          </Box>

          {/* Page container */}
          <Box className="researchersMainBox pageContainer">
            <Box className="workSpaceListBox">
              <Box mb={2}
                width="74px"
                onClick={() => Navigate("/activities/task")}
                className="openBtn"
              >
                <img src={backArrow} />
                <Typography className="neonBackText">{t("commonTxt.backBtn")}</Typography>
              </Box>
              <Box className="ProfileDetailsPanel">
                <Box className="ProfilePicture">
                  <Avatar alt="" src={ProfileImage} sx={{ width: 112, height: 112 }} />
                </Box>
                <Box className="ProfileDetails">
                  <Typography variant="h4" gutterBottom>
                    Amanda Tomlin
                  </Typography>
                  <Grid container spacing={5}>
                    <Grid item xs={5} md={7}>
                      <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}>
                        <Button className="infoBtn" variant="text">
                          <img src={detailsIcon} />
                        </Button>
                        <Typography className="neonBackText">{t("viewParticipantProfile.viewParticipantProfileDetails")}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={7} md={5}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <img src={shieldsIcon} />
                        <Typography variant="h6">{t("viewParticipantProfile.viewParticipantProfileTera")}</Typography>
                        <Chip label="1500 Points" />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box className="ProfileDataPanel">
                <Box className="panel">
                  {t("viewParticipantProfile.viewParticipantProfileProject")} <span>{t("viewParticipantProfile.viewParticipantProfileJoined")}</span> <strong>Jul 2, 2023</strong>
                </Box>
                <Box className="panel">
                  {t("viewParticipantProfile.viewParticipantProfileProject")} <span>{t("viewParticipantProfile.viewParticipantProfileVisits")}</span> <strong>265</strong>
                </Box>
                <Box className="panel">
                  {t("viewParticipantProfile.viewParticipantProfileActivities")} <span>{t("viewParticipantProfile.viewParticipantProfileCompleted")}</span> <strong>122</strong>
                </Box>
                <Box className="panel">
                  {t("viewParticipantProfile.viewParticipantProfileLast")} <span>{t("viewParticipantProfile.viewParticipantProfileVisits")}</span> <strong>Jul 2, 2023</strong>
                </Box>
                <Box className="panel">
                  {t("viewParticipantProfile.viewParticipantProfileLastEmail")} <span>{t("viewParticipantProfile.viewParticipantProfileOpened")}</span> <strong>Jul 2, 2023</strong>
                </Box>
              </Box>
              <Box className="taskTable">

                {/* -------===========--------Responses------======------------ */}

                <Box className="ActiveTaskSectionBox">
                  <Box className="responsesNav">
                    <Box
                      className={
                        responsesNav === "Thread"
                          ? "responsesNavItem responsesNavItemActive"
                          : "responsesNavItem"
                      }
                      onClick={() => setResponsesNav("Thread")}
                    >
                      <Typography>{t("viewParticipantProfile.viewParticipantProfileNav1")}</Typography>
                    </Box>
                    <Box
                      className={
                        responsesNav === "Expressions"
                          ? "responsesNavItem responsesNavItemActive"
                          : "responsesNavItem"
                      }
                      onClick={() => setResponsesNav("Expressions")}
                    >
                      <Typography>{t("viewParticipantProfile.viewParticipantProfileNav2")}</Typography>
                    </Box>
                    <Box
                      className={
                        responsesNav === "Images"
                          ? "responsesNavItem responsesNavItemActive"
                          : "responsesNavItem"
                      }
                      onClick={() => setResponsesNav("Images")}
                    >
                      <Typography>{t("viewParticipantProfile.viewParticipantProfileNav3")}</Typography>
                    </Box>
                    <Box
                      className={
                        responsesNav === "Videos"
                          ? "responsesNavItem responsesNavItemActive"
                          : "responsesNavItem"
                      }
                      onClick={() => setResponsesNav("Videos")}
                    >
                      <Typography>{t("viewParticipantProfile.viewParticipantProfileNav4")}</Typography>
                    </Box>
                    <Box
                      className={
                        responsesNav === "Audios"
                          ? "responsesNavItem responsesNavItemActive"
                          : "responsesNavItem"
                      }
                      onClick={() => setResponsesNav("Audios")}
                    >
                      <Typography>{t("viewParticipantProfile.viewParticipantProfileNav5")}</Typography>
                    </Box>
                  </Box>
                  {/* -----------Thread------------- */}
                  <Box
                    display={responsesNav === "Thread" ? "block" : "none"}
                    className="responsesSection ThreadBox"
                  >
                    <Thread />
                  </Box>
                  {/* -----------Expressions------------- */}
                  <Box
                    display={
                      responsesNav === "Expressions" ? "block" : "none"
                    }
                    className="responsesSection "
                  ></Box>
                  {/* -----------Images------------- */}
                  <Box
                    display={responsesNav === "Images" ? "block" : "none"}
                    className="responsesSection "
                  >
                    <Images />
                  </Box>
                  {/* -----------Videos------------- */}
                  <Box
                    display={responsesNav === "Videos" ? "block" : "none"}
                    className="responsesSection "
                  >
                    <Videos />
                  </Box>
                  {/* -----------Audios------------- */}
                  <Box
                    display={responsesNav === "Audios" ? "block" : "none"}
                    className="responsesSection "
                  >
                    <Audio />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
