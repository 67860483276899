import { Box } from '@mui/material'
import React from 'react'
import AdminLayout from '../partials/AdminLayout'
import Appearance from '../../../Components/Admin/Appearance'

const ApperancePage = () => {
  return (
    <AdminLayout
    childComponent={<Box
        className="adminMainBox"
        sx={{ display: "flex" }}
      >
        {/*Appearance */}
        <Appearance/>
      </Box>}
  />
  )
}

export default ApperancePage