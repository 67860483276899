import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
//images
import AdminBG from "../Assets/Images/Add-files-cuate.png";
//components
import { AppBtn } from "../Components/AppTools/AppButton";

export default function NotFound() {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const Navigate = useNavigate();
  //
  return (
    <>
      <Box className="ResearchersContainer ">
        {/* side menu */}
        <Box className="adminMainSection">
          {/* Page container */}
            
                <Box sx={{display:"block",marginLeft:"auto",marginRight:"auto"}} className="adminWorkSBG">
                    <img src={AdminBG} />
                </Box>
                <Box className="adminBGTextBox">
                    <Typography className="adminHeaderText">
                    404 | Not Found
                    </Typography>
                    <Typography sx={{fontSize:"18px !important",fontWeight:"500 !important",color:"#706c80;",textAlign:"center"}}>
                    So sorry,
                    </Typography>
                    <Typography sx={{fontSize:"18px !important",fontWeight:"500 !important",color:"#706c80;",textAlign:"center",marginBottom:"20px !important"}}>
                    We couldn't find what you are looking for...
                    </Typography>
                    <AppBtn onClick={() => Navigate("/")} buttonText="Back To Terapage"/>
                </Box>
            
          
        </Box>
      </Box>
    </>
  );
}
