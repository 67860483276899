import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import IIcon2 from "../../../Assets/Images/IIcon.png";

//components

import { AppBtnOutLine, AppBtn } from "../../../Components/AppTools/AppButton";
import { AntSwitch, MonthStatus } from "../../../Components/AppTools/AppTool";

export default function ({ setEditWSTemplatePop, editTemplatePop }) {
  const email1 =
    "Hello {fname}, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Read more";
  const email2 = "Best Regards,  Lorem Ipsum";
  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();

  const [signatureTextLaOut, setSignatureTextLaOut] = useState("start");
  const [signatureTextItalic, setSignatureTextItalic] = useState(false);
  const [signatureTextBold, setSignatureTextBold] = useState(false);
  const [signatureTextUnderLine, setSignatureTextUnderLine] = useState();
  const [settingTab, setSettingTab] = useState(0);
  const [statusDrop, setStatusDrop] = useState(false);
  const [statusDropVal, setStatusDropVal] = useState("Month");

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setEditWSTemplatePop(false);
    }
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine === "underline") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine === "line-through") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("line-through");
        }
      }
    }
  };

  useEffect(() => {
    if (editTemplatePop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [editTemplatePop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: editTemplatePop ? "flex" : "none" }}
        className="workspacePop agreementTemPop"
      >
        <Box
          sx={{ display: editTemplatePop ? "flex" : "none" }}
          className="workspaceContaint emailTemPop"
        >
          <Typography className="workspaceHeader">
            Edit Agreement Template
          </Typography>
          {/* Tabs */}
          <Box className="agreementSettingNav">
            <Box
              onClick={() => setSettingTab(0)}
              className={
                settingTab === 0
                  ? "agreeSetNavTab agreeSetNavTabActive"
                  : "agreeSetNavTab"
              }
            >
              <Typography>Basic Details</Typography>
            </Box>
            <Box
              onClick={() => setSettingTab(1)}
              className={
                settingTab === 1
                  ? "agreeSetNavTab agreeSetNavTabActive"
                  : "agreeSetNavTab"
              }
            >
              <Typography>Agreement Page</Typography>
            </Box>
          </Box>
          {/* Tabs */}
          <Box
            sx={{ display: settingTab === 0 ? "flex" : "none" }}
            className="settingBox"
          >
            <Box className="agreeTextBox infoAgreeTextBox">
              <Box className="iIcontD">
                <img src={IIcon2} />
              </Box>
              <Box>
                <Typography>
                  Use an Agreement Page to host details such as lenghty legal
                  terms. Once enabled, you must link text in the statement above
                  to the page via special link tags. For example, I agree with
                  the link Terms of Service link.
                </Typography>
              </Box>
            </Box>
            <Box className="basicBox">
              <Box>
                <Typography className="eTemPopLabel ">
                  Template Name<samp>*</samp>
                </Typography>
                <Box className="pickerBox ">
                  <input className="eTemInput" placeholder="Welcome Messgae" />
                </Box>
              </Box>
            </Box>
            <Box className="agrrementPopLabel">
              <Typography className="eTemPopLabel agreementLabel">
                Opt-in Statement<samp>*</samp>
              </Typography>
              <Typography className="agreementLinkLabel">
                Insert Link
              </Typography>
            </Box>
            <Box className="emailInputBox">
              <Box className="emailInputBoxNav">
                <Box
                  onClick={() => setMessageTextItalic(!messageTextItalic)}
                  className="emailImgBox"
                >
                  <img src={IIcon} />
                </Box>
                <Box
                  onClick={() => setMessageTextBold(!messageTextBold)}
                  className="emailImgBox"
                >
                  <img src={BIcon} />
                </Box>
                <Box
                  onClick={() => handelTextlineChekc("underline", "message")}
                  className="emailImgBox"
                >
                  <img src={UnderLineU} />
                </Box>
                <Box
                  onClick={() => handelTextlineChekc("line-through", "message")}
                  className="emailImgBox"
                >
                  <img src={MLineU} />
                </Box>
                <Box className="emailImgBox">
                  <img src={AtachMentIcon} />
                </Box>
                <Box className="emailImgBox">
                  <img src={Qbox} />
                </Box>
                <Box
                  onClick={() => setMessageTextLaOut("start")}
                  className="emailImgBox"
                >
                  <img src={Line1} />
                </Box>
                <Box
                  onClick={() => setMessageTextLaOut("center")}
                  className="emailImgBox"
                >
                  <img src={Line2} />
                </Box>
                <Box
                  onClick={() => setMessageTextLaOut("end")}
                  className="emailImgBox"
                >
                  <img src={Line3} />
                </Box>
                <Box className="emailImgBox">
                  <img src={Line4} />
                </Box>
                <Box className="emailImgBox">
                  <img src={emoji} />
                </Box>
              </Box>
              <textarea
                style={{
                  textAlign: messageTextLaOut,
                  fontStyle: messageTextItalic ? "italic" : "normal",
                  fontWeight: messageTextBold ? "bold" : "normal",
                  textDecoration: messageTextUnderLine,
                }}
                className="emailInput"
                name="emailMessage"
                placeholder="Enter an Opt-in Statement for the userto check while agreeing"
              />
            </Box>

            <Box className="airadioSwitchBox">
              <AntSwitch
                defaultUnchecked
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography className="switchText">Limit Visibility</Typography>
            </Box>
            <Box className="airadioSwitchBox">
              <AntSwitch
                defaultUnchecked
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography className="switchText">Require Agreement</Typography>
            </Box>
            <Box className="airadioSwitchBox">
              <AntSwitch
                defaultChecked
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography className="switchText">
                Display on my Account
              </Typography>
            </Box>
            <Box className="airadioSwitchBox">
              <AntSwitch
                defaultUnchecked
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography className="switchText">
                Confirm Agreement after every{" "}
              </Typography>
              <input className="agreeNumber" />
              <MonthStatus
                setStatusDrop={setStatusDrop}
                statusDrop={statusDrop}
                setStatusDropVal={setStatusDropVal}
                statusDropVal={statusDropVal}
              />
            </Box>
          </Box>

          <Box
            sx={{ display: settingTab === 1 ? "flex" : "none" }}
            className="settingBox"
          >
            <Box className="airadioSwitchBox">
              <AntSwitch
                defaultChecked
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography className="switchText">
                Enable Agreement Page
              </Typography>
            </Box>
            <Box className="airadioSwitchBox">
              <AntSwitch
                defaultUnchecked
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography className="switchText">
                Display in-line above opt-in statement
              </Typography>
            </Box>

            <Box className="agrrementPopLabel">
              <Typography className="eTemPopLabel agreementLabel">
                Page Content<samp>*</samp>
              </Typography>
              <Typography className="agreementLinkLabel">
                Insert Link
              </Typography>
            </Box>
            <Box className="emailInputBox">
              <Box className="emailInputBoxNav">
                <Box
                  onClick={() => setMessageTextItalic(!messageTextItalic)}
                  className="emailImgBox"
                >
                  <img src={IIcon} />
                </Box>
                <Box
                  onClick={() => setMessageTextBold(!messageTextBold)}
                  className="emailImgBox"
                >
                  <img src={BIcon} />
                </Box>
                <Box
                  onClick={() => handelTextlineChekc("underline", "message")}
                  className="emailImgBox"
                >
                  <img src={UnderLineU} />
                </Box>
                <Box
                  onClick={() => handelTextlineChekc("line-through", "message")}
                  className="emailImgBox"
                >
                  <img src={MLineU} />
                </Box>
                <Box className="emailImgBox">
                  <img src={AtachMentIcon} />
                </Box>
                <Box className="emailImgBox">
                  <img src={Qbox} />
                </Box>
                <Box
                  onClick={() => setMessageTextLaOut("start")}
                  className="emailImgBox"
                >
                  <img src={Line1} />
                </Box>
                <Box
                  onClick={() => setMessageTextLaOut("center")}
                  className="emailImgBox"
                >
                  <img src={Line2} />
                </Box>
                <Box
                  onClick={() => setMessageTextLaOut("end")}
                  className="emailImgBox"
                >
                  <img src={Line3} />
                </Box>
                <Box className="emailImgBox">
                  <img src={Line4} />
                </Box>
                <Box className="emailImgBox">
                  <img src={emoji} />
                </Box>
              </Box>
              <textarea
                style={{
                  textAlign: messageTextLaOut,
                  fontStyle: messageTextItalic ? "italic" : "normal",
                  fontWeight: messageTextBold ? "bold" : "normal",
                  textDecoration: messageTextUnderLine,
                  // textDecoration: messageTextMLine ? "line-through" : null
                }}
                className="emailInput"
                name="emailMessage"
                placeholder="Enter Page Content"
              />
            </Box>

            <Box className="agrrementPopLabel agreeLinkUrl">
              <Typography className="agreeLinkLabel">
                Additional Link
              </Typography>
              <input className="urlInput" placeholder="Enter additional link" />
            </Box>
          </Box>
          <Box className="workPupBtnBox">
            <AppBtnOutLine
              buttonText="Cancel"
              onClick={() => setEditWSTemplatePop(false)}
            />
            <AppBtnOutLine buttonText="Save as Draft" />
            <AppBtn buttonText="Create" />
          </Box>
        </Box>
      </Box>
    </>
  );
}
