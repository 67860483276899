import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import AdminLayout from "../../partials/AdminLayout";
import { Box, Typography } from "@mui/material";
import { AppBtn } from "../../../../Components/AppTools/AppButton";
import horizontalDot from "../../../../Assets/Images/more-horizontal.png";
import UserHome from "../../../../Components/Admin/UserHome";

const Home = () => {
  const [projectOptionPop, setProjectOptionPop] = useState(false);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  let appBtn = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        position: "relative",
      }}
    >
      <AppBtn buttonText="Workspace Administration" />
      <Box
        onClick={() => setProjectOptionPop(!projectOptionPop)}
        className="workspaceItemOption"
      >
        <img src={horizontalDot} />

        <Box
          sx={{ display: projectOptionPop ? "flex" : "none" }}
          className="workspaceItemNavPop"
        >
          <Box>
            <Typography>{t("workspaceHome.addCard")}</Typography>
          </Box>
          <Box>
            <Typography>{t("workspaceHome.pageSetting")}</Typography>
          </Box>
          <Box>
            <Typography>{t("workspaceHome.addPage")}</Typography>
          </Box>
          <Box>
            <Typography>{t("workspaceHome.editView")}</Typography>
          </Box>
          <Box>
            <Typography>{t("workspaceHome.preview")}</Typography>
          </Box>
          <Box>
            <Typography>{t("workspaceHome.actionLog")}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
  return (
    <AdminLayout
      childComponent={
        <Box className="adminMainBox" sx={{ display: "flex" }}>
          <UserHome />
        </Box>
      }
      actionBtn={appBtn}
    />
  );
};

export default Home;
