import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";

import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";
import SideMenu from "../../WSAdministration/SideMenu";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import deleteIcon from "../../../Assets/Images/icon-delete-red.svg";
import { sideHaumIcon } from "../../../Assets/Data";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DropArrow from "../../../Assets/Images/chevron-down.png";
import backArrow from "../../../Assets/Images/noun-back.png";

import { AppBtnOutLine, AppBtn } from "../../AppTools/AppButton";
import { GoTop, AntSwitch } from "../../AppTools/AppTool";

import backIcon from "../../../Assets/Images/icon-arrow.svg";
import PreviewFilterPop from "../PreviewFilterPop";
import { API_BASE_URL } from "../../../env";
import axios from "../../../lib/axios";
import { formatDate, renderUserLayout } from "../../../lib/helpers";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../lib/store/store";
import ResearcherLayout from "../../../Pages/Researchers/partials/ResearcherLayout";
import AdminLayout from "../../../Pages/Admin/partials/AdminLayout";

export default function AddEnhancedGroup({ }) {

  let authState = useAppSelector((state) => state.auth);
  const Navigate = useNavigate();

  const handleRouting = (Url) => {
    Navigate(Url);
  };
  const workspaceState = useAppSelector((state) => state.workspace);
  const [customFieldList, setCustomFieldList] = useState([]);
  const [customFieldFilter, setCustomFieldFilter] = useState({
    attribute: [],
    value: [],
    scale: "any"
  });
  const [nameError, setNameError] = useState("");
  const groupData = {
    name: "",
    restricted_projects: [],
    allow_projects: [],
    remove_projects: [],
    is_enhanced: true,
    match_all_criteria: false,
    attributes: [
      {
        name: "",
        scale: "",
        value: [],
        customFields: customFieldList,
        customFieldsChoices: [],
      },
    ],
    workspace_id: workspaceState?.selectedWorkspace?._id,
  };
  const [enhancedGroup, setEnhancedGroup] = useState(groupData);

  const [projectList, setProjectList] = useState([]);

  const handleToggleChange = (key, e) => {
    const check = e.target.checked;
    setEnhancedGroup({
      ...enhancedGroup,
      [key]: check,
    });
  };

  const handleChange = (event) => {
    setEnhancedGroup((prevGroup) => ({
      ...prevGroup,
      [event.target.name]: event.target.value,
    }));
  };
  const addAttribute = () => {
    setEnhancedGroup((prevGroup) => ({
      ...prevGroup,
      attributes: [
        ...prevGroup.attributes,
        {
          name: "",
          scale: "",
          value: [],
          customFields: customFieldList,
          customFieldsChoices: [],
        },
      ],
    }));
  };

  const getCustomFields = async () => {
    try {
      const url = `/participant/profile-fields?workspace_id=${workspaceState?.selectedWorkspace?._id}&field_type=poll`;
      const data = await axios.get(url);
      console.log("setCustomFieldList", data.data.data);
      setCustomFieldList(data.data.data);
      setEnhancedGroup((prevGroup) => ({
        ...prevGroup,
        attributes: prevGroup.attributes.map((attr, i) => ({
          ...attr,
          customFields: data.data.data,
        })),
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const removeAttribute = (index) => {
    setEnhancedGroup((prevGroup) => ({
      ...prevGroup,
      attributes: prevGroup.attributes.filter((_, i) => i !== index),
    }));
  };

  const handleAttributeChange = (index, field, value) => {
    setEnhancedGroup((prevGroup) => ({
      ...prevGroup,
      attributes: prevGroup.attributes.map((attr, i) =>
        i === index ? { ...attr, [field]: value } : attr
      ),
    }));
    if (field == "name") {
      setEnhancedGroup((prevGroup) => ({
        ...prevGroup,
        attributes: prevGroup.attributes.map((attr, i) =>
          i === index ? { ...attr, value: [] } : attr
        ),
      }));
    }
  };

  const [colorSelectorDrop1, setColorSelectorDrop1] = useState(false);
  const [dropdownIndex, setDropdownIndex] = useState(0);
  const [colorSelectorDrop1Val, setColorSelectorDrop1Val] =
    useState("Solid Color");

  const [previewFilterPop, setPreviewFilterPop] = useState(false);
  // Project list API
  useEffect(() => {
    getProject();
    getCustomFields();
  }, []);

  const getProject = async () => {
    try {
      const data = await axios.get(API_BASE_URL + "/researchers/projects");
      setProjectList(data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getChoose = (index, ch) => {
    console.log("ch", ch);
    setEnhancedGroup((prevGroup) => ({
      ...prevGroup,
      attributes: prevGroup.attributes.map((attr, i) => {
        return index === i ? { ...attr, customFieldsChoices: ch } : attr;
      }),
    }));
  };
  const setFilterParam = (key, val) => {
    const prevKey = [...customFieldFilter[key]] || [];
    prevKey.push(val)
    setCustomFieldFilter({ ...customFieldFilter, [key]: prevKey })
  }
  const handlePreview = () => {
    if (customFieldFilter.attribute.length < 1) {
      return toast.error("Selection required: Please choose attribute.");
    }
    setPreviewFilterPop(true);
  }

  const submitEnhancedGroupDetail = async () => {
    if (enhancedGroup.name === "") {
      setNameError("Group name is required.")
      return toast.error("Group name is required.")
    } else {
      setNameError("")
    }
    const { attributes, ...payload } = { ...enhancedGroup };
    const attrs = attributes
      .filter(({ name }) => name !== "")
      .map(({ customFields, customFieldsChoices, ...rest }) => rest);
    payload.attributes = attrs;
    try {
      const data = await axios.post(
        API_BASE_URL + "/participant/groups",
        payload
      );
      if (data.success) {
        toast.success(data.message);
        setEnhancedGroup(groupData);
        Navigate("/workspace-administration/group")
      } else {
        toast.error(data.message);
      }
      return data.data;
    } catch (error) {
      let message = "Something went wrong, please try again!";
      let status_code = 200;
      if (error.response) {
        message = error.response.data.message;
        status_code = error.response.status;
      }
      return {
        success: false,
        message: message,
        status_code: status_code,
      };
    }
  }

  const getChildren = () => {
    return <Box className="researchersMainBox pageContainer">
      <Box className="SettingsFormSection">
        <PreviewFilterPop
          previewFilterPop={previewFilterPop}
          setPreviewFilterPop={setPreviewFilterPop}
          customFieldFilter={customFieldFilter}
        />

        <Box
          onClick={() => handleRouting("/workspace-administration/group")}
          className="openBtn"
        >
          <img src={backArrow} />
          <Typography className="neonBackText">Back</Typography>
        </Box>

        <Box className="HeadingOptions">
          <Typography variant="h4">Add: Enhanced Groups</Typography>
          {/* <Box
            onClick={() => setColorSelectorDrop1(!colorSelectorDrop1)}
            width={"250px"}
            height={"40px"}
            px={2}
            className="statusBox"
          >
            <Typography>Options</Typography>
            <img src={DropArrow} />
            drop
            <Box
              sx={{ height: colorSelectorDrop1 ? "auto" : "0px" }}
              className="statusPop"
            >
              <Box
                onClick={() =>
                  setColorSelectorDrop1Val("Remove personal data")
                }
                className="statusPopItem"
              >
                <Typography>
                  {<DoDisturbIcon />} Remove personal data
                </Typography>
              </Box>
              <Box
                onClick={() => setColorSelectorDrop1Val("Delete")}
                className="statusPopItem"
              >
                <Typography>{<DeleteOutlineIcon />} Delete</Typography>
              </Box>
            </Box>
          </Box> */}
        </Box>

        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography className="greybox">
              Group ID: <span>--</span>
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography className="greybox">
              Updated: <span>{formatDate(projectList[0]?.updated_at)}</span>
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography className="greybox">
              Participants: <span>0</span>
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography className="greybox">
              Added: <span>Jun 5, 2023; 12:02 PM</span>
            </Typography>
          </Grid>
        </Grid>

        <Box className="FormField">
          <Typography variant="h5">Basics</Typography>
          <TextField
            fullWidth
            id="outlined-basic"
            placeholder="Enter basics"
            variant="outlined"
            size="small"
            onChange={handleChange}
            value={enhancedGroup.name}
            name="name"
            defaultValue={"Age 18 - 25"}
          />
          <span className="validationerr">{nameError}</span>
        </Box>

        <Box className="FormField">
          <Typography variant="h5">Advanced Options</Typography>
          <Typography variant="h6">Project Visibility</Typography>
          <Typography>
            Restrict the group's visibility to projects within the site that
            are specifically relevant to it.
          </Typography>
          <FormControl sx={{ width: "100%" }} size="small">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={enhancedGroup.restricted_projects}
              onChange={handleChange}
              name="restricted_projects"
              multiple
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <p>All Project</p>;
                }
                return selected
                  .map((value) => {
                    const projectLists = projectList.find(
                      (prj) => prj._id === value
                    );
                    return projectLists ? projectLists.name : value;
                  })
                  .join(",");
              }}
            >
              {projectList.map((list, index) => (
                <MenuItem value={list._id} key={index}>
                  {list.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box className="FormField">
          <Typography variant="h6">Add participants to projects</Typography>
          <Typography>
            Participants, whether new or existing, who are assigned to this
            group will be included in the selected projects.
          </Typography>
          <FormControl sx={{ width: "100%" }} size="small">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={enhancedGroup.allow_projects}
              onChange={handleChange}
              name="allow_projects"
              multiple
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <p>None</p>;
                }
                return selected
                  .map((value) => {
                    const projectLists = projectList.find(
                      (prj) => prj._id === value
                    );
                    return projectLists ? projectLists.name : value;
                  })
                  .join(",");
              }}
            >
              {projectList.map((list, index) => (
                <MenuItem value={list._id} key={index}>
                  {list.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box className="FormField">
          <Typography variant="h6">
            Remove participants from projects
          </Typography>
          <Typography>
            Participants, whether new or existing, who are assigned to this
            group will be excluded in the selected projects.
          </Typography>
          <FormControl sx={{ width: "100%" }} size="small">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={enhancedGroup.remove_projects}
              onChange={handleChange}
              name="remove_projects"
              multiple
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <p>None</p>;
                }
                return selected
                  .map((value) => {
                    const projectLists = projectList.find(
                      (prj) => prj._id === value
                    );
                    return projectLists ? projectLists.name : value;
                  })
                  .join(",");
              }}
            >
              {projectList.map((list, index) => (
                <MenuItem value={list._id} key={index}>
                  {list.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box className="FormField">
          <Typography variant="h5">Enhanced Group Criteria</Typography>
          {/* <FormControlLabel
            control={
              <Switch
                checked={enhancedGroup?.match_all_criteria}
                onChange={(e) => {
                  handleToggleChange("match_all_criteria", e);
                  setCustomFieldFilter({ ...customFieldFilter, scale: e.target.checked ? "all_of_them" : "any" })
                }}
              />
            }
            label="Match only if all criteria apply"
          /> */}
            <Box className="groupCriteriaSwitchBox">
              <AntSwitch
                checked={enhancedGroup?.match_all_criteria}
                onChange={handleToggleChange("match_all_criteria")}
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography className="switchText">
                Match only if all criteria apply
              </Typography>
            </Box>
        </Box>
        {enhancedGroup.attributes.map((attribute, index) => (
          <Box key={index}>
            <Box className="FormField flexEnd">
              <Button
                onClick={() => removeAttribute(index)}
                className="deleteBtn"
                variant="text"
              >
                <img src={deleteIcon} />
              </Button>

              <Typography className="enhancedGroupDetBtn">
                Delete
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Typography>Attribute: {index + 1}</Typography>
                {/* <FormControl sx={{ width: "100%" }} size="small">
                <TextField
                  fullWidth
                  id={`name-${index}`}
                  placeholder="Enter name"
                  variant="outlined"
                  size="small"
                  onChange={(e) =>
                    handleAttributeChange(index, "name", e.target.value)
                  }
                  value={attribute.name}
                />
              </FormControl> */}
                <FormControl sx={{ width: "100%" }} size="small">
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={attribute.name}
                    onChange={(e) => {
                      handleAttributeChange(index, "name", e.target.value)
                    }
                    }
                    displayEmpty
                    renderValue={(selected) => selected || "Select Attribute"}
                  >
                    {attribute.customFields.map((list, i) => (
                      <MenuItem
                        value={list.question}
                        key={i}
                        onClick={() => { getChoose(index, list.choices); setFilterParam("attribute", list._id) }}
                      >
                        {list.question}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <Typography>Scale</Typography>
                {/* <FormControl sx={{ width: "100%" }} size="small">
                <TextField
                  fullWidth
                  id={`scale-${index}`}
                  placeholder="Enter scale"
                  variant="outlined"
                  size="small"
                  onChange={(e) =>
                    handleAttributeChange(index, "scale", e.target.value)
                  }
                  value={attribute.scale}
                />
              </FormControl> */}
                <FormControl sx={{ width: "100%" }} size="small">
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={attribute.scale}
                    onChange={(e) =>
                      handleAttributeChange(index, "scale", e.target.value)
                    }
                    displayEmpty
                    renderValue={(selected) => selected || "Select Scale"}
                  >
                    <MenuItem value={`any`} key={1}>
                      Any
                    </MenuItem>
                    <MenuItem value={`all_of_them`} key={2}>
                      All Off Them
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <Typography>Value</Typography>
                {/* <FormControl sx={{ width: "100%" }} size="small">
                <TextField
                  fullWidth
                  id={`value-${index}`}
                  placeholder="Enter value"
                  variant="outlined"
                  size="small"
                  onChange={(e) =>
                    handleAttributeChange(index, "value", e.target.value)
                  }
                  value={attribute.value}
                />
              </FormControl> */}
                <FormControl sx={{ width: "100%" }} size="small">
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={attribute.value}
                    onChange={(e) => {
                      handleAttributeChange(index, "value", e.target.value);
                      setFilterParam("value", e.target.value)
                    }
                    }
                    multiple
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <p>None</p>;
                      }
                      return selected
                        .map((value) => {
                          const choicesVal =
                            attribute.customFieldsChoices.find(
                              (ch) => ch.name === value
                            );
                          return choicesVal ? choicesVal.name : value;
                        })
                        .join(",");
                    }}
                  >
                    {attribute.customFieldsChoices &&
                      attribute.customFieldsChoices.map((el, i) => (
                        <MenuItem value={el.name} key={i}>
                          {el.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        ))}

        <Box className="FormField">
          <Typography className="attributeText" onClick={addAttribute}>
            Add Attribute
          </Typography>
        </Box>

        <Box className="FormField">
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <AppBtn
                onClick={() => {
                  handlePreview()
                  GoTop();
                }}
                buttonText="Preview"
              />
            </Grid>
            <Grid item sm={6}>
              <Box className="flexEnd">
                <AppBtnOutLine
                  buttonText="Cancel"
                  onClick={() =>
                    handleRouting("/workspace-administration/group")
                  }
                />
                <AppBtn
                  buttonText="Save"
                  onClick={submitEnhancedGroupDetail}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  }

  return renderUserLayout(authState?.userType, null, getChildren(), 'workadmin');
}
