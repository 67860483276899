import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import "./style.css";

//components
import { AppBtn, AppBtnOutLine, PurplelBtn } from "../../AppTools/AppButton";
import { SearchBar } from "../../../Components/AppTools/AppTool";

export default function UseTemplatePop({ setUseTemplatePop, useTemplatePop }) {
  const handelPreviousPop = () => {
    setUseTemplatePop(false);
  };
  return (
    <Box className="workspaceDeletePopContainer workspaceDeletePopTextAlign useTempPop">
      <Box className="workspaceDeleteBox">
        <Box className="popTopBox">
          <Box>
            <Typography className="deletePopHeader">Template</Typography>
          </Box>
          <Box>
            <PurplelBtn buttonText="Create Template" />
          </Box>
        </Box>

        <Box className="inputBoxWlabel">
          <SearchBar />
        </Box>

        <Box className="deleteTextBox">
          <Box className="ProjectRadioInput tempRadioBox">
            <Box>
              <input
                className="emailSettingInput emailSettingRadio"
                type="radio"
                name="projectState"
                defaultChecked
              />
            </Box>
            <Box>
              <Typography className="emailSettingLabel">
                Project Wrap Up
              </Typography>
            </Box>

            <Box>
              <Typography className="emailSettingLabel">
                Sept 4, 2023 11:38
              </Typography>
            </Box>
            <Box>
              <KeyboardArrowDownIcon id="keyBoardArrow" />
            </Box>
          </Box>
          <Box className="ProjectRadioInput tempRadioBox">
            <Box>
              <input
                className="emailSettingInput emailSettingRadio"
                type="radio"
                name="projectState"
                defaultChecked
              />
            </Box>
            <Box>
              <Typography className="emailSettingLabel">
                Project Invitation
              </Typography>
            </Box>
            <Box>
              <Typography className="emailSettingLabel">
                Sept 4, 2023 11:38
              </Typography>
            </Box>
            <Box>
              <KeyboardArrowDownIcon id="keyBoardArrow" />
            </Box>
          </Box>
          <Box className="ProjectRadioInput tempRadioBox">
            <Box>
              <input
                className="emailSettingInput emailSettingRadio"
                type="radio"
                name="projectState"
                defaultChecked
              />
            </Box>

            <Box>
              <Typography className="emailSettingLabel">
                Task 4 probe
              </Typography>
            </Box>
            <Box>
              <Typography className="emailSettingLabel">
                Sept 4, 2023 11:38
              </Typography>
            </Box>
            <Box>
              <KeyboardArrowDownIcon id="keyBoardArrow" />
            </Box>
          </Box>
          <Box className="ProjectRadioInput tempRadioBox">
            <Box>
              <input
                className="emailSettingInput emailSettingRadio"
                type="radio"
                name="projectState"
                defaultChecked
              />
            </Box>
            <Box>
              <Typography className="emailSettingLabel">
                Task 7 probe
              </Typography>
            </Box>
            <Box>
              <Typography className="emailSettingLabel">
                Sept 4, 2023 11:38
              </Typography>
            </Box>
            <Box>
              <KeyboardArrowDownIcon id="keyBoardArrow" />
            </Box>
          </Box>
        </Box>

        <Box className="deleteBtnBox">
          <AppBtnOutLine
            onClick={() => handelPreviousPop()}
            buttonText="Cancel"
          />
          <AppBtn buttonText="Use Template" />
        </Box>
      </Box>
    </Box>
  );
}
