import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
//data
import { sideHaumIcon } from "../../../Assets/Data";

//components
import SideMenu from "../../../Components/Researchers/SideMenu";
import { AllDrop9 } from "../../../Components/AppTools/AppTool";
import WorkspaceReport from "../../../Components/Researchers/WorkspaceReport";
import ProjectDataComparison from "../../../Components/Researchers/ProjectDataComparison";

export default function DataAndReport({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
}) {

  const [allDrop4, setAllDrop4] = useState();
  const [allDropVal4, setAllDropVal4] = useState("Export");
  const [dataReportTabe, setDataReportTabe] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  useEffect(() => {
    let selTab = searchParams.get("dr");
    if (selTab != null) {
      setDataReportTabe(parseInt(selTab));
    }
    else {
      setDataReportTabe(0);
    }
  }, []);

  return (
    <>
      <Box className="ResearchersContainer ">
        {/* side menu */}
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>

            <Box className="projectNavItems">
              <Box
                onClick={() => {
                  setSearchParams({ dr: 0 });
                  setDataReportTabe(0);
                }}
                className={
                  dataReportTabe === 0
                    ? "recapNavItem recapNavItemActive"
                    : "recapNavItem"
                }
              >
                <Typography>{t("dataReportPage.workspaceReports")}</Typography>
              </Box>

              <Box className="vrLine"> </Box>
              <Box
                onClick={() => {
                  setSearchParams({ dr: 1 });
                  setDataReportTabe(1);
                }}
                className={
                  dataReportTabe === 1
                    ? "recapNavItem recapNavItemActive mNav2"
                    : "recapNavItem  mNav2"
                }
              >
                <Typography>{t("dataReportPage.projectDataComparison")}</Typography>
              </Box>
            </Box>
            <Box sx={{ gap: "15px" }} className="AppNavBtnBox">
              <Box sx={{ minWidth: "178px" }}>
                <AllDrop9
                  drop={allDrop4}
                  setDrop={setAllDrop4}
                  dropVal={allDropVal4}
                  serDropVal={setAllDropVal4}
                />
              </Box>
            </Box>
          </Box>
          {dataReportTabe === 0 ?
            (<WorkspaceReport dataReportTabe={dataReportTabe} setDataReportTabe={setDataReportTabe} />) :
            dataReportTabe === 1 ?
              (<ProjectDataComparison />) : null

          }
        </Box>
      </Box >
    </>
  );
}
