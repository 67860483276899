import React from 'react'
import { Box, Typography } from "@mui/material";
import "./style.css"

//images
import bgImg from "../../../Assets/Images/dark-skies-camping-header.jpg"

import SideMenu from "../../../Components/Participants/SideMenu";
import { sideHaumIcon } from "../../../Assets/Data";
import { useTranslation } from "react-i18next";


export default function ActivityGuidance({ slideMenu, setSlideMenu, workspaceDrop, setWorkSpaceDrop }) {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Box className="ResearchersContainer activityGuidancePage">
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer">
            <Box className="workspaceListBox">
              <Typography className='pageHeaderText'>{t("ActivityGuidance.pageHeader")}</Typography>
              <Typography className='agSubHeader'>{t("ActivityGuidance.ImageActivity")}</Typography>
              <Typography className='agSummeryText'>{t("ActivityGuidance.SummeryText")}</Typography>
              <Box className="agImageBox">
                <img src={bgImg} />
              </Box>


            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
