import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";

//components
import { AppBtn, AppBtnOutLine } from "../../../Components/AppTools/AppButton";
import { deleteCustomField } from "../../../Services/researcherActions";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { useAppSelector } from "../../../lib/store/store";

export default function DeleteWSProfilingField({
  deleteProfileFieldPop,
  setDeleteProfileFieldPop,
  customFieldID,
  setCustomFieldID,
  customFieldName,
  setCustomFieldName,
  setReloadTable
}) {
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setDeleteProfileFieldPop(false);
    }
  };
  const authState = useAppSelector((state) => state.auth);
  useEffect(() => {
    if (deleteProfileFieldPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [deleteProfileFieldPop]);

  const deleteField = async () => {
    try {

      const response = await deleteCustomField(customFieldID,
        authState.authToken
      );
      // setLoading(false);
      if (response.success) {
        toast.success(response.message, TOAST_CONFIG);
        setCustomFieldID("")
        setCustomFieldName("")
        setDeleteProfileFieldPop(false)
        setReloadTable(true)
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      // setLoading(false);
    }
  }
  return (
    <>
      <Box
        onClick={handelPopClose}
        id="popBackdrop"
        sx={{ display: deleteProfileFieldPop ? "flex" : "none" }}
        className="workspaceDeletePopContainer"
      >
        <Box className="workspaceDeleteBox workspaceDeletePopTextAlign">
          <Typography className="deletePopHeader">
            Delete Profile Field ?
          </Typography>

          <Box className="deleteTextBox">
            <Typography>
              Are you sure you want to delete “{customFieldName}” Agreement from
              Terapage? Kindly note that this action cannot be un-done, any
              Agreement once deleted cannot be retrieved again.
            </Typography>
          </Box>
          <Box className="deleteBtnBox">
            <AppBtnOutLine
              onClick={() => {
                setDeleteProfileFieldPop(false)
                setCustomFieldID("")
                setCustomFieldName("")
              }
              }
              buttonText="Cancel"
            />
            <AppBtn buttonText="Delete" onClick={deleteField} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
