import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import "./style.css";


import SideMenu from "../../../Components/User/SideMenu";
import { sideHaumIcon } from "../../../Assets/Data";
import { renderUserLayout } from '../../../lib/helpers';
import { useAppSelector } from '../../../lib/store/store';
import ContactSupport from '../../../Components/Admin/ContactSupport';


export default function ContactTicket({ slideMenu, setSlideMenu, workspaceDrop, setWorkSpaceDrop }) {

  const authState = useAppSelector((state) => state.auth);
  const getChildren = () => {
    return (
      <Box className="researchersMainBox pageContainer">
        {/* <Box className="EmailNContainer">
          <Typography className='emailNotificationHeaderText'>Click Links to go on the Pages</Typography>
          <Typography className='contactSubHeadText'>Contact Link</Typography>
          <Box my={3} className="iBox">
            <Typography className='supportLinks'>
              www.terapage.ai/contactus.html
            </Typography>
          </Box>
          <Typography className='contactSubHeadText'>Ticket Link</Typography>
          <Box my={3} className="iBox">
            <Typography className='supportLinks'>
              https://terapage.raiseaticket.com/support/#/newticket
            </Typography>
          </Box>

        </Box> */}
         <ContactSupport />
      </Box>
    )
  }
  return renderUserLayout(authState?.userType, null, getChildren(), 'accountsetting')
}
