import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  CardHeader,
  Avatar,
  CardContent,
} from "@mui/material";

import { participantsDataV2 } from "../../../Assets/Data"

//components
import { AppBtnOutLine } from "../../AppTools/AppButton";
import axios from "../../../lib/axios";


export default function ParticipantPop({ participantPop, setParticipantPop, setProjectID, projectID }) {

  const [participants, setParticipant] = useState([]);
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setParticipantPop(false);
    }
  };

  useEffect(() => {
    if (participantPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
    getProject(projectID || "ds")
  }, [participantPop]);

  const getProject = async (projectID) => {
    if (projectID !== "") {
      let url = `/researchers/projects?_id=${projectID}`
      const res = await axios.get(url);
      if (res.status) {
        if (res.data.data.length > 0) {
          const project = res.data.data[0]
          setParticipant(project?.workspaces[0]?.assigned_users_data)

        }

      }
    }
  }

  return (

    <Box
      onClick={handelPopClose}
      sx={{ display: participantPop ? "flex" : "none" }}
      className="workspacePop previewFilter"
      id="popBackdrop">
      <Box
        sx={{ display: participantPop ? "flex" : "none" }}
        className="workspaceContaint previewFilterPop">
        <Typography className='previewFilterHeaderText'>Participants</Typography>

        <Box className="ParticipantsPanel">
          {
            participantsDataV2?.map((el, i) => (
              <Box className="ParticipantBox">
                <CardHeader
                  key={i}
                  avatar={
                    <Avatar
                      alt=""
                      src={el.img}
                      sx={{ width: 44, height: 44 }}
                    />
                  }
                  title={el.name}
                />
                <CardContent>
                  <Typography>{el.phone}</Typography>
                  <Typography>{el.time} </Typography>
                </CardContent>
              </Box>
            ))
          }
        </Box>
        <Box className="workPupBtnBox">
          <AppBtnOutLine
            buttonText="Close"
            onClick={() => setParticipantPop(false)}
          />
        </Box>
      </Box>
    </Box>

  );
}
