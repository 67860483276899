import React, { useEffect, useState } from 'react'
import "./style.css";
import { Box, Typography } from '@mui/material';
import IIcon from "../../../Assets/Images/IIcon.png"
//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton"
export default function EmailVerificationPop({ emailVerificationPop, setEmailVerificationPop, updatedUserProfile, setUserProfileData, userProfileData }) {
  const [verifyButtonDisable, setVerifyButtonDisable] = useState(true)
  const handelPopClose = (e) => {
    if (e.target.id === "popVerifydrop") {
      setEmailVerificationPop(false)
    }
  }
  useEffect(() => {
    if (emailVerificationPop) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = 'auto'; // Enable scrolling
    }
  }, [emailVerificationPop])
  const handelTextChange = (e) => {
    if (e.target.value) {
      setUserProfileData({ ...userProfileData, "otp": e.target.value })
      setVerifyButtonDisable(false)
    } else {
      setVerifyButtonDisable(true)
    }
  }
  return (
    <>
      <Box onClick={handelPopClose} id="popVerifydrop" sx={{ display: emailVerificationPop ? "flex" : "none" }} className="emailVerifyPopContainer">
        <Box className="emailVerifyDeleteBox">
          <Typography className='emailVerifyPopHeader'>Verify your new Email Address</Typography>
          <Box className="emailVerifyTextBox infoEmailVerifyTextBox">
            <Box className="iIcontD"><img src={IIcon} /></Box>
            <Box>
              <Typography>For your protection, a code has been send on your new Email Address provided. Kindly check your mail and enter the code in the box below.</Typography>
            </Box>
          </Box>
          <Box className="emailVerifyBox lastVerifyBasicBox">
            <Box className="inputBox">
              <input className="addResearcherInput" onChange={handelTextChange} placeholder="Enter Code" />
            </Box>
          </Box>
          <Box className="emailVerifyPopBtnBox">
            <AppBtnOutLine onClick={() => setEmailVerificationPop(false)} buttonText="Close" />
            <AppBtn disabled={verifyButtonDisable ? true : false} buttonText="Verify" onClick={updatedUserProfile} />
          </Box>
        </Box>
      </Box>
    </>
  )
}