import React, { useEffect, useState } from "react";
import "./style.css";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import axios from "../../../lib/axios";

import Info from "../../../Assets/Images/info.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";
import avatar from "../../../Assets/Images/Avatar2.png";

//component
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { DropDownLists } from "../../AppTools/AppTool";
import { API_BASE_URL } from "../../../env";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { useAppSelector } from "../../../lib/store/store";
import { createExportParticipantAPI } from "../../../Services/adminActions";
import { viewImage } from "../../../lib/helpers";
import DummyUser from "../../../Assets/Images/user-dummy.png";
export default function ExportPartcipantPop({
  setExportPop,
  exportPop,
  projects,
  groupsListData,
  basicGroupsListData
}) {
  let authState = useAppSelector((state) => state.auth);
  const [standardAttribute, setStandardAttribute] = useState(false);
  const [standardAttributeVal, setStandardAttributeVal] = useState(false);

  const [customAttribute, setCustomAttribute] = useState(false);
  const [customAttributeVal, setCustomAttributeVal] = useState("");

  const [agreementDrop, setAgreementDrop] = useState(false);
  const [agreementDropVal, setAgreementDropVal] = useState(false);

  const [groupDrop, setGroupDrop] = useState(false);
  const [groupDropVal, setGroupDropVal] = useState(false);

  const [projectDrop, setProjectDrop] = useState(false);
  const [projectDropVal, setProjectDropVal] = useState(false);

  const [proLevelDrop, setProlevelDrop] = useState(false);
  const [proLevelDropVal, setProlevelDropVal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [participantProfileLists, setParticipantProfileLists] = useState([]);
  const [standardAttrList, setStandardAttrList] = useState([]);
  // console.log('standardAttrList',standardAttrList);
  const exportUsersObj = {
    project_id: [],
    profiling_id: [],
    group_id: [],
    users_fields: [],
    filters: {},
  };

  const [exportUsers, setExportUsers] = useState({ ...exportUsersObj });
  const workspaceState = useAppSelector((state) => state.workspace);
  const [testFilterParticipants, setTestFilterParticipants] = useState(true);
  const [exportTemplate, setExportTemplate] = useState([]);

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setExportPop(false);
    }
  };

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "200px",
        overflowY: "auto",
      },
    },
  };

  useEffect(() => {
    if (exportPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [exportPop]);

  useEffect(() => {
    getparticipantProfile();
    getStandardAttrList();
  }, []);

  const getparticipantProfile = async () => {
    try {
      let response = await axios.get(
        API_BASE_URL + "/participant/profile-fields"
      );
      if (response.success) {
        setParticipantProfileLists(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching project lists", error);
    }
  };

  const getStandardAttrList = async () => {
    try {
      let response = await axios.get(API_BASE_URL + '/super-admin/admin/get-user-fields')
      if (response.success) {
        setStandardAttrList(response?.data);
      }
    } catch (error) {
      console.error("Error fetching project lists", error);
    }
  };

  const handleChange = (event) => {
    const selectedValue = event.target.value === "" ? 0 : event.target.value;
    setExportUsers((prevGroup) => ({
      ...prevGroup,
      [event.target.name]: selectedValue,
    }));
  };

  const getExportParam = () => {
    let { ...participantData } = exportUsers;
    participantData.filters = {
      group_id: participantData.group_id,
      project_id: participantData.project_id,
    };

    delete participantData.project_id;
    delete participantData.group_id;
    participantData.workspace_id = workspaceState?.selectedWorkspace?._id;
    return participantData;
  };

  function validateFields(data) {
    const { profiling_id, users_fields, filters } = data;
    const { group_id, project_id } = filters;

    // Check if all fields are empty
    const allFieldsEmpty = profiling_id.length === 0 &&
      users_fields.length === 0 &&
      group_id.length === 0 &&
      project_id.length === 0;
    return allFieldsEmpty;
  }

  const handleExportParticipants = async () => {
    try {
      const participantData = getExportParam();
      if (validateFields(participantData))
        return toast.error("At least one field must be filled.");

      setLoading2(true);
      const exportParticipantRes = await createExportParticipantAPI(
        participantData,
        authState.authToken
      );
      setLoading2(false);
      if (exportParticipantRes.success) {
        toast.success(exportParticipantRes.message, TOAST_CONFIG);
        setExportUsers({
          project_id: [],
          profiling_id: [],
          group_id: [],
          users_fields: [],
          filters: {},
        });
        setExportPop(false);
        setTestFilterParticipants(false);
        setExportTemplate([]);
        let showImage = viewImage(exportParticipantRes.data.filePath);
        window.open(showImage, "_blank");
      } else {
        toast.error(exportParticipantRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading2(false);
    }
  };

  const testFilter = async () => {
    // setTestFilterParticipants(!testFilterParticipants)
    setLoading(true);
    try {
      const participantData = getExportParam();
      // return console.log("participantData",participantData)
      let response = await axios.get(
        API_BASE_URL + "/super-admin/admin/check-export-participants",
        { params: participantData }
      );
      setLoading(false);
      if (response.success) {
        setTestFilterParticipants(true);
        setExportTemplate(response.data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        id="popBackdrop"
        sx={{ display: exportPop ? "flex" : "none" }}
        onClick={handelPopClose}
        className="exportPPop exportPopBox "
      >
        <Box className="exportPopContainer">
          <Box className="exportPopHeading">
            <Typography>
              {t("exportParticipantPop.bulkExportParticipant")}
            </Typography>
          </Box>
          <Box className="headContext">
            <Typography>{t("exportParticipantPop.bulkExportText")}</Typography>
          </Box>
          <Box className="instructionRedBar">
            <Box>
              <img src={Info} />
            </Box>
            <Box>
              <Typography>
                {t("exportParticipantPop.bulkExportText2")}
              </Typography>
            </Box>
          </Box>
          <Box className="inputContainer">
            <Box className="formHeader">
              <Typography>
                {t("exportParticipantPop.fieldsToBeExported")}
              </Typography>
            </Box>
            <Box className="inputContainerItem">
              <Box className="inputBoxWlabel">
                <Typography className="inputLabel">
                  {t("exportParticipantPop.standardAttributes")}
                </Typography>
                <FormControl sx={{ width: "100%" }} size="small">
                  <Select
                    labelId="standard-attributes-label"
                    id="standard-attributes-select"
                    value={exportUsers?.users_fields}
                    onChange={handleChange}
                    name="users_fields"
                    multiple
                    MenuProps={MenuProps}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <Typography>
                            {t("exportParticipantPop.selectstdatr")}
                          </Typography>
                        );
                      }
                      return selected
                        .map((value) => {
                          const user = standardAttrList[value];
                          return user ? user.name : value;
                        })
                        .join(", ");
                    }}
                  >
                    {standardAttrList &&
                      Object.entries(standardAttrList).map(([ky, value]) => (
                        <MenuItem value={value} key={ky}>
                          {value}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box className="inputContainerItem">
              <Box className="inputBoxWlabel">
                <Typography className="inputLabel">
                  {t("exportParticipantPop.customAttributes")}
                </Typography>
                <FormControl sx={{ width: "100%" }} size="small">
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={exportUsers?.profiling_id}
                    onChange={handleChange}
                    name="profiling_id"
                    multiple
                    MenuProps={MenuProps}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <Typography>
                            {t("exportParticipantPop.customAttributes")}
                          </Typography>
                        );
                      }
                      return selected
                        .map((value) => {
                          const profile = participantProfileLists.find(
                            (profileData) => profileData._id === value
                          );
                          return profile ? profile.question : value;
                        })
                        .join(", ");
                    }}
                  >
                    {participantProfileLists.map((profile, index) => (
                      <MenuItem value={profile._id} key={index}>
                        {profile.question}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box className="inputContainerItem">
              <Box className="inputBoxWlabel">
                <Typography className="inputLabel">
                  {t("exportParticipantPop.agreement")}
                </Typography>
                <Box
                  className="inputAppDrop"
                  onClick={() => setAgreementDrop(!agreementDrop)}
                >
                  <Typography>
                    {agreementDropVal ||
                      t("exportParticipantPop.selectAgreement")}
                  </Typography>
                  <img
                    style={{ rotate: agreementDrop ? "180deg" : "0deg" }}
                    src={dropArrow}
                  />
                  {/* pop */}
                  <Box
                    sx={{ height: agreementDrop ? "auto" : "0px" }}
                    className="statusPop"
                  >
                    <Box
                      onClick={() => setAgreementDropVal("Item-A")}
                      className="statusPopItem"
                    >
                      <Typography>Item-A</Typography>
                    </Box>
                    <Box
                      onClick={() => setAgreementDropVal("Item-B")}
                      className="statusPopItem"
                    >
                      <Typography>Item-B</Typography>
                    </Box>
                    <Box
                      onClick={() => setAgreementDropVal("Item-C")}
                      className="statusPopItem"
                    >
                      <Typography>Item-C</Typography>
                    </Box>
                    <Box
                      onClick={() => setAgreementDropVal("Item-D")}
                      className="statusPopItem"
                    >
                      <Typography>Item-D</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* InputContainerTwo */}
          <Box className="inputContainer">
            <Box className="formHeader">
              <Typography>
                {t("exportParticipantPop.filterParticipants")}
              </Typography>
            </Box>
            <Box className="inputContainerItem">
              <Box className="inputBoxWlabel">
                <Typography className="inputLabel">
                  {t("exportParticipantPop.groups")}
                </Typography>
                <FormControl sx={{ width: "100%" }} size="small">
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={exportUsers?.group_id}
                    onChange={handleChange}
                    name="group_id"
                    multiple
                    MenuProps={MenuProps}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <Typography>
                            {t("exportParticipantPop.groups")}
                          </Typography>
                        );
                      }
                      return selected
                        .map((value) => {
                          const group = basicGroupsListData.find(
                            (group) => group._id === value
                          );
                          return group ? group.name : value;
                        })
                        .join(", ");
                    }}
                  >
                    {basicGroupsListData.map((group, index) => (
                      <MenuItem value={group._id} key={index}>
                        {group.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box className="inputContainerItem">
              <Box className="inputBoxWlabel">
                <Typography className="inputLabel">
                  {t("commonTxt.project")}
                </Typography>
                <FormControl sx={{ width: "100%" }} size="small">
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={exportUsers?.project_id}
                    onChange={handleChange}
                    name="project_id"
                    multiple
                    MenuProps={MenuProps}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <Typography>{t("commonTxt.project")}</Typography>
                        );
                      }
                      return selected
                        .map((value) => {
                          const projectList = projects.find(
                            (proj) => proj._id === value
                          );
                          return projectList ? projectList.name : value;
                        })
                        .join(", ");
                    }}
                  >
                    {projects.map((proj, index) => (
                      <MenuItem value={proj._id} key={index}>
                        {proj.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box className="inputContainerItem">
              <Box className="inputBoxWlabel">
                <Typography className="inputLabel">
                  {t("exportParticipantPop.projectLevelAtr")}
                </Typography>
                <Box
                  className="inputAppDrop"
                  onClick={() => setProlevelDrop(!proLevelDrop)}
                >
                  <Typography>
                    {proLevelDropVal ||
                      t("exportParticipantPop.selectprojectLevelAtr")}
                  </Typography>
                  <img
                    style={{ rotate: proLevelDrop ? "180deg" : "0deg" }}
                    src={dropArrow}
                  />
                  {/* pop   */}
                  <Box
                    sx={{ height: proLevelDrop ? "auto" : "0px" }}
                    className="statusPop"
                  >
                    <Box
                      onClick={() => setProlevelDropVal("Item-A")}
                      className="statusPopItem"
                    >
                      <Typography>Item-A</Typography>
                    </Box>
                    <Box
                      onClick={() => setProlevelDropVal("Item-B")}
                      className="statusPopItem"
                    >
                      <Typography>Item-B</Typography>
                    </Box>
                    <Box
                      onClick={() => setProlevelDropVal("Item-C")}
                      className="statusPopItem"
                    >
                      <Typography>Item-C</Typography>
                    </Box>
                    <Box
                      onClick={() => setProlevelDropVal("Item-D")}
                      className="statusPopItem"
                    >
                      <Typography>Item-D</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="filterCheckboxBtns">
            {loading ? (
              <div class="textFilterBtn">
                <p>Loading...</p>
              </div>
            ) : (
              <div class="textFilterBtn" onClick={testFilter}>
                <p>{t("exportParticipantPop.testFilterBtn")}</p>
              </div>
            )}
            <Typography
              sx={{ display: testFilterParticipants ? "block" : "none" }}
              className="matchesCountText"
            >
              {exportTemplate.length} Matches
            </Typography>
            <Box className="checkboxandBtns">
              <Box className="groupInbox">
                <input type="checkBox" />
                <Typography>
                  {t("exportParticipantPop.groupByProjects")}
                </Typography>
              </Box>
              <Box className="btnBtn">
                <AppBtnOutLine
                  buttonText={t("commonTxt.cancelBtn")}
                  onClick={() => {
                    setExportPop(false); 
                    setLoading(false);
                    setExportTemplate([]);
                    setExportUsers({
                      project_id: [],
                      profiling_id: [],
                      group_id: [],
                      users_fields: [],
                      filters: {},
                    })
                  }}
                />
                {loading2 ? (
                  <AppBtn buttonText={`Loading...`} />
                ) : (
                  <AppBtn
                    buttonText={t("commonTxt.exportLink")}
                    onClick={handleExportParticipants}
                  />
                )}
              </Box>
            </Box>
          </Box>
          {/* Participant List */}
          <Box
            sx={{ display: testFilterParticipants ? "flex" : "none" }}
            className="filterParticipantMainBox"
          >
            {exportTemplate &&
              exportTemplate?.map((exportItem, index) => (
                <Box className="userBox" key={index}>
                  <Box className="avatar">
                    {exportItem.profile_pic == "undefined" ? (
                      <img src={DummyUser} />
                    ) : (
                      <img src={exportItem.profile_pic} />
                    )}
                  </Box>
                  <Typography>{exportItem.name}</Typography>
                </Box>
              ))}
            {/* <Box className="userBox">
              <Box className="avatar">
                <img src={avatar} />
              </Box>
              <Typography>
                Phoenix Baker <br /> <span>PBResearcher</span>
              </Typography>
            </Box> */}
          </Box>
          {/* Participant LIst */}
        </Box>
      </Box>
    </>
  );
}
