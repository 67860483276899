import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import "./style.css";

//Images
import whitedropIcon from "../../../Assets/Images/Wchevron-down.png";
import tik1 from "../../../Assets/Images/checkG.png";
import redcircle from "../../../Assets/Images/alert-circle.png";

import { AllSuperAdmin } from "../../../Assets/Data";
//components 

export default function PorjectReviews({ dataReportTabe, setDataReportTabe }) {

  const [projectReviewDrop, setProjectReviewDrop] = useState(false);
  const [projectReviewDropVal, setProjectReviewDropVal] = useState("Pending");
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let selTab = searchParams.get("pr");
    let selTab2 = searchParams.get("dr");
    if (selTab && selTab2 != null) {
      var query_string = selTab;
      query_string = query_string.replaceAll('_', ' ');
      query_string = query_string.replace(/(^\w|\s\w)/g, firstCharOfWord => firstCharOfWord.toUpperCase());
      console.log(query_string);
    setProjectReviewDropVal(query_string);
    setDataReportTabe(parseInt(selTab2));
    }
    else if (selTab == null) {
      setDataReportTabe(parseInt(selTab2));
    }
  }, []);

  return (
    <>
      <Box className="researchersMainBox pageContainer">
        <Box className="integrationsContainer">
          <Box className="projectReviewBtnBox">
            <Box className="inteProjectReviewBtndropBox" >
              <Box className="projectManagementNavBtn" onClick={() => setProjectReviewDrop(!projectReviewDrop)}>
                <Typography className='DropBtnText'>{projectReviewDropVal || t("projectReview.projectReviewDropPlaceholder")}</Typography>
                <img className='dropIcon' src={whitedropIcon} style={{ rotate: projectReviewDrop ? "180deg" : "0deg", transition: "all 0.5s", marginLeft: "10px" }} />
                <Box className="workspaceItemNavPop projectItemNavPop" sx={{ display: projectReviewDrop ? "flex" : "none" }}>
                  <Box 
                    onClick={() => {
                      setSearchParams({ dr:dataReportTabe,pr: "pending" });
                      setProjectReviewDropVal("Pending");
                    }}
                  >
                    <Typography>{t("projectReview.projectReviewDropVal1")}</Typography>
                  </Box>
                  <Box
                    onClick={() => {
                      setSearchParams({ dr:dataReportTabe,pr: "all" });
                      setProjectReviewDropVal("All");
                    }}
                  >
                    <Typography>{t("projectReview.projectReviewDropVal2")}</Typography>
                  </Box>
                  <Box 
                    onClick={() => {
                      setSearchParams({ dr:dataReportTabe,pr: "reviewed" });
                      setProjectReviewDropVal("Reviewed");
                    }}
                  >
                    <Typography>{t("projectReview.projectReviewDropVal3")}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            {projectReviewDropVal === "Pending" || projectReviewDropVal === "All" ?
              (<Box className="projectReviewsMarkRead">
                <Box className="projectReviewReadDot">
                  <Box>
                    <Typography className='verifyLink'>{t("commonTxt.markAllRead")} (9)</Typography>
                  </Box>
                  <Box className="projectRDot"></Box>
                </Box>
              </Box>) : null
            }
          </Box>
          {/* Pending Review Table */}
          <Box sx={{ display: projectReviewDropVal === "Pending" ? "block" : "none" }} >
            <Typography className='emailNotificationHeaderText projectReviewHead'>{t("projectReview.pendingReviewsHead")}</Typography>
            <Box className="adminkListBox">
              <Box className="haderRow" minWidth={"1111px"}>
                <Box className="Tabelsel TabelselItemStart" minWidth={"28%"}>
                  <Typography>{t("projectReview.tblUserName")}</Typography>
                </Box>
                <Box className="Tabelsel TabelselItemStart" minWidth={"25%"}>
                  <Typography>{t("projectReview.tblResponses")}</Typography>
                </Box>
                <Box className="Tabelsel TabelselItemStart" minWidth={"27%"} >
                  <Typography>{t("projectReview.tblUpdated")}</Typography>
                </Box>
                <Box className="Tabelsel TabelselItemStart" minWidth={"22%"} >
                  <Typography>{t("projectReview.tblReview")}</Typography>
                </Box>
              </Box>
              {AllSuperAdmin &&
                AllSuperAdmin?.map((el, index) => (
                  index < 4 ?
                    (<Box className="TabelRow" minWidth={"1111px"}>
                      <Box className=" TabCell navFCel navTable" minWidth={"28%"}>
                        <Box
                          className="comptabelAvatar Tabelsel"
                        >
                          <img src={el.img} />
                        </Box>
                        <Box>
                          <Typography>{el.name}</Typography>
                        </Box>
                      </Box>

                      <Box className="Tabelsel TabelselItemStart" minWidth={"25%"}>
                        <Typography>Camping, Hiking</Typography>
                      </Box>
                      <Box className="Tabelsel TabelselItemStart" minWidth={"27%"}>
                        <Typography>Jul 08 @ 3:06 PM</Typography>
                      </Box>
                      <Box
                        className="Tabelsel tableICon TabelselItemStart viewLink"
                        minWidth={"22%"}
                      >
                        <Typography>Review</Typography>
                      </Box>
                    </Box>) : null
                ))}
            </Box>
          </Box>
          {/* Pending Review Table */}
          {/* All Reviews */}
          <Box sx={{ display: projectReviewDropVal === "All" ? "block" : "none" }} >
            <Typography className='emailNotificationHeaderText projectReviewHead'>{t("projectReview.allReviewsHead")}</Typography>
            <Box className="workListBox">
              <Box className="haderRow" minWidth={"1270px"}>
                <Box
                  minWidth={"290px"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0px",
                    paddingLeft: "11px",
                  }}
                  className="Tabelsel navFsel allpHTI participantCheckboxColor"
                >
                  <Typography>{t("projectReview.tblUserName")}</Typography>
                </Box>
                <Box minWidth={"230px"} className="Tabelsel tableItemSel">
                  <Typography>{t("projectReview.tblResponses")}</Typography>
                </Box>
                <Box minWidth={"230px"} className="Tabelsel tableItemSel">
                  <Typography>{t("projectReview.tblUpdated")}</Typography>
                </Box>
                <Box minWidth={"230px"} className="Tabelsel tableItemSel">
                  <Typography>{t("projectReview.tblReview")}</Typography>
                </Box>
                <Box minWidth={"230px"} className="Tabelsel tableItemSel">
                  <Typography>{t("projectReview.tblReviewed")}</Typography>
                </Box>

              </Box>

              {AllSuperAdmin &&
                AllSuperAdmin.map((el, index) => (
                  index < 4 ?
                    (<Box className="TabelRow" minWidth={"1270px"}>
                      <Box
                        sx={{ position: "relative" }}
                        className="Tabelsel navFsel participantCheckboxColor"
                        minWidth={"290px"}
                      >

                        <Box
                          className="comptabelAvatar Tabelsel"
                        >
                          <img src={el.img} />
                        </Box>
                        <Box>
                          <Typography>{el.name}</Typography>
                        </Box>

                      </Box>
                      <Box
                        minWidth={"230px"}
                        className="Tabelsel TabelselItemCenter"
                      >
                        <Typography className="participantsTableText">Rank It & Sort It (3)</Typography>
                      </Box>
                      <Box
                        className="Tabelsel tableItemSel TabelselItemCenter"
                        minWidth={"230px"}
                      >
                        <Typography className="participantsTableText">Sep 08  @ 3:06 PM</Typography>
                      </Box>
                      <Box
                        className="Tabelsel tableItemSel TabelselItemCenter viewLink"
                        minWidth={"230px"}
                      >
                        <Typography className="participantsTableText">
                          View
                        </Typography>
                      </Box>
                      <Box
                        className="Tabelsel tableItemSel TabelselItemCenter statusSel"
                        minWidth={"230px"}
                      >
                        {index < 2 ? (
                          <img src={tik1} />
                        ) : index >=
                          2 ? (
                          <img src={redcircle} />
                        ) : null}
                      </Box>

                    </Box>) : null
                ))}
            </Box>
          </Box>
          {/* All Reviews */}
          {/* Reviewed Reviews */}
          <Box sx={{ display: projectReviewDropVal === "Reviewed" ? "block" : "none" }} >
            <Typography className='emailNotificationHeaderText projectReviewHead'>{t("projectReview.reviewedReviewHead")}</Typography>
            <Box className="adminkListBox">
              <Box className="haderRow" minWidth={"1111px"}>
                <Box className="Tabelsel TabelselItemStart" minWidth={"28%"}>
                  <Typography>{t("projectReview.tblUserName")}</Typography>
                </Box>
                <Box className="Tabelsel TabelselItemStart" minWidth={"25%"}>
                  <Typography>{t("projectReview.tblResponses")}</Typography>
                </Box>
                <Box className="Tabelsel TabelselItemStart" minWidth={"27%"} >
                  <Typography>{t("projectReview.tblUpdated")}</Typography>
                </Box>
                <Box className="Tabelsel TabelselItemStart" minWidth={"22%"} >
                  <Typography>{t("projectReview.tblReview")}</Typography>
                </Box>
              </Box>
              {AllSuperAdmin &&
                AllSuperAdmin?.map((el, index) => (
                  index < 4 ?
                    (<Box className="TabelRow" minWidth={"1111px"}>
                      <Box className=" TabCell navFCel navTable" minWidth={"28%"}>
                        <Box
                          className="comptabelAvatar Tabelsel"
                        >
                          <img src={el.img} />
                        </Box>
                        <Box>
                          <Typography>{el.name}</Typography>
                        </Box>
                      </Box>

                      <Box className="Tabelsel TabelselItemStart" minWidth={"25%"}>
                        <Typography>Camping, Hiking</Typography>
                      </Box>
                      <Box className="Tabelsel TabelselItemStart" minWidth={"27%"}>
                        <Typography>Jul 08 @ 3:06 PM</Typography>
                      </Box>
                      <Box
                        className="Tabelsel tableICon TabelselItemStart viewLink"
                        minWidth={"22%"}
                      >
                        <Typography>Review</Typography>
                      </Box>
                    </Box>) : null
                ))}
            </Box>
          </Box>
          {/* Reviewed Reviews */}
        </Box>
      </Box>
    </>
  )
}
