import React, { useState } from 'react'
import "./style.css"
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

//images
import banner from "../../../Assets/Images/logBanner.png"
import CLogo from "../../../Assets/Images/Frame 243811.png"
import RedInfo from "../../../Assets/Images/info.png"
import VisibleIcon from "../../../Assets/Images/VisibleIcon.png"
import { useTranslation } from 'react-i18next';
import { logout, redirectIfAuthenticated, resetPassword, updatePassword } from '../../../Services/auth';
import { useAppDispatch, useAppSelector } from '../../../lib/store/store';



export default function UpdatePwd() {
    const Navigate = useNavigate();
    const dispatch = useAppDispatch();
    const authState = useAppSelector(state => state.auth);
    const [pwd, setPwd] = useState(false)
    const [ppwd, setPpwd] = useState(false)
    const [pwd1, setPwd1] = useState(false)
    const { t, i18n: { changeLanguage, language } } = useTranslation();
    const [apiError, setApiError] = useState("")
    const [loading, setLoading] = useState(false);
    const [credential, setCredential] = useState({
        previous_password: '',
        password: '',
        confirm_password: ''
    });

    const changeAppLanguage = (lng) => {
        changeLanguage(lng);
    }

    const changePassword = async () => {
        if (credential.previous_password == "") {
            setApiError("Previous password is required!");
            return;
        }

        if (credential.previous_password.length < 6) {
            setApiError("Previous password length should be minimum 6 digits!");
            return;
        }

        if (credential.password == "") {
            setApiError("Password is required!");
            return;
        }

        if (credential.password.length < 6 || credential.confirm_password.length < 6) {
            setApiError("Password length should be minimum 6 digits!");
            return;
        }

        if (credential.password != credential.confirm_password) {
            setApiError("Passwords do not match!");
            return;
        }

        try {
            setLoading(true)
            const response = await updatePassword(credential, authState.authToken)
            setLoading(false);
            if (response.success) {
                toast.success(response.message)
                return redirectIfAuthenticated(Navigate, dispatch, authState?.authToken, authState?.userProfile, authState?.userType);
            } else {
                setApiError(response.message);
            }
        } catch (err) {
            console.log(err);
            setApiError('Something went wrong! Please try again');
            setLoading(false);
        };

    };


    return (
        <>
            <select style={{ float: 'right' }} onChange={(e) => { changeAppLanguage(e.target.value) }} value={language}>
                <option value="en">English</option>
                <option value="hi">Hindi</option>
            </select>
            <Box className="logInPage">
                <Box className="banner">
                    <Typography className=''>Take your research to the next level</Typography>
                    <Box mt={3} className="slideBox">
                        <Box className="sBox"></Box>
                        <Box className="sBox"></Box>
                        <Box className="sBox sBoxActive"></Box>
                    </Box>
                    <img src={banner} />
                </Box>
                <Box className="signInBox">
                    <Box className="signInInnerBox">
                        <Box className="signColog">
                            <img onClick={() => Navigate("/")} src={CLogo} />
                        </Box>

                        <Box className="signInInputBox">
                            <Typography>Previous Password</Typography>
                            <input placeholder='Enter Previous Password' type={ppwd ? "text" : "password"} onChange={e => setCredential({ ...credential, previous_password: e.target.value })} />
                            <img className='eyeIcon' src={VisibleIcon} onClick={() => setPpwd(!ppwd)} />
                        </Box>

                        <Box className="signInInputBox">
                            <Typography>New Password</Typography>
                            <input placeholder='Enter Password' type={pwd ? "text" : "password"} onChange={e => setCredential({ ...credential, password: e.target.value })} />
                            <img className='eyeIcon' src={VisibleIcon} onClick={() => setPwd(!pwd)} />
                        </Box>

                        <Box className="signInInputBox">
                            <Typography>Confirm  New Password</Typography>
                            <input placeholder='Enter Password' type={pwd1 ? "text" : "password"} onChange={e => setCredential({ ...credential, confirm_password: e.target.value })} />
                            <img className='eyeIcon' src={VisibleIcon} onClick={() => setPwd1(!pwd1)} />
                        </Box>

                        {apiError !== "" && <Box mb={2} className="pwdError">
                            <img src={RedInfo} />
                            <Typography>{apiError}</Typography>
                        </Box>}

                        <Box className="signBtn" onClick={changePassword}>
                            {loading ? <Typography>{t('adminSignIn.loading')}...</Typography> : <Typography>Change Password</Typography>}
                        </Box>

                    </Box>
                </Box>

            </Box>
        </>
    )
}
