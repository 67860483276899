import React, { useState } from "react";

import { Box, Typography } from "@mui/material";
// Images
import BlankImage from "../../../Assets/Images/No data-cuate 1.png";

import { useTranslation } from "react-i18next";

const NoAdminBg = ({ setNewAdminSection }) => {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  return (
    <>
      <Box className="NoDataSection">
        <img src={BlankImage} />
        <Typography gutterBottom>{t("admin.noData")}</Typography>
        <Typography className="captionText" gutterBottom>
          {t("admin.addAdminLink")}
        </Typography>
        <Typography
          mx={3}
          className="addAdminLinkText"
          onClick={() => setNewAdminSection(true)}
        >
          {t("admin.addAdmin")}
        </Typography>
      </Box>
    </>
  );
};

export default NoAdminBg;
