import React from 'react'
import { Box } from "@mui/material";

import SideMenu from "../../../Components/Collaborator/SideMenu";
import { sideHaumIcon } from "../../../Assets/Data";

export default function Dashboard({ slideMenu, setSlideMenu, workspaceDrop }) {

  return (
    <>
      <Box className="ResearchersContainer ">
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} workspaceDrop={workspaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer">

            <h1>Dashboard</h1>
          </Box>
        </Box>
      </Box>
    </>
  )
}