import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//images
import OptionIcon from "../../../Assets/Images/optionIcon.png";
import users from "../../../Assets/Images/usersB.png";
import w1 from "../../../Assets/Images/w1.png";
import { sideHaumIcon } from "../../../Assets/Data";
import iIcon from "../../../Assets/Images/IIcon.png";

import {
  ActiveCSettingIcon,
  activeCEyeIcon,
  activeCImageIcon,
  activeCCopyIcon,
  activeCPestIcon,
  activeCFormIcon,
  activeCTrashIcon,
  arrowIcon,
  inProgressIcon,
  completedIcon,
  pendingIcon,
} from "../../../Assets/Data";

//components
import { SearchBar } from "../../../Components/AppTools/AppTool";
import SideMenu from "../../../Components/Researchers/SideMenu";
import {
  AppBtnOutLine,
  AppAddBtn,
  PurplelBtn,
  AppBtn,
} from "../../../Components/AppTools/AppButton";
import ActivityEmptyPage from "../../../Components/Researchers/ActivityEmptyPage";
import AddActivitiesPop from "../../../Components/Researchers/AddActivitiesPop";
import ActivityGuidancePop from "../../../Components/Researchers/ActivityGuidancePop";
import {
  USER_TYPE_COLLABORATOR,
  USER_TYPE_RESEARCHER,
  USER_TYPE_OBSERVER,
} from "../../../lib/constants";
import { API_BASE_URL } from "../../../env";
import axios from "../../../lib/axios";
import { formatDate, viewImage } from "../../../lib/helpers";
import { useAppSelector } from "../../../lib/store/store";
import {
  changeActivityStatus,
  updateCoverImageActivity,
  uploadUserDirectoryImage,
} from "../../../Services/adminActions";
import { TOAST_CONFIG } from "../../../lib/constants";
import { toast } from "react-toastify";
import Pagination from "../../../Components/AppTools/Pagination";
import DeleteActivityPop from "../../../Components/Researchers/DeleteActivityPop";

export default function Activities({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
}) {
  const workspaceState = useAppSelector((state) => state.workspace);
  let authState = useAppSelector((state) => state.auth);

  const defaultFilters = {
    search: "",
    status: "",
  };

  const [filterState, setFilterState] = useState({ ...defaultFilters });

  const handleFilterChange = (changedFilter) => {
    let newFilters = {
      ...filterState,
      ...changedFilter,
    };
    setFilterState(newFilters);
    getActivities(1, newFilters);
  };

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 25,
    totalPages: 0,
    totalItems: 0,
  });

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const Navigate = useNavigate();
  const data = false;
  const [activitySequence, setActivitySequence] = useState(false);
  const [addActivitiesPop, setAddActivitiesPop] = useState(false);
  const [activityGuidancePop, setActivityGuidancePop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [radio, setRadio] = useState(0);
  const [activeCardOptionPop, setActiveCardOptionPop] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [editActivityID, setEditActivityID] = useState("");
  const [popupAction, setPopupAction] = useState("");
  const [allActivitiesList, setAllActivitiesList] = useState([]);

  const [draggingIndex, setDraggingIndex] = useState(null);
  const [dragSequence, setDragSequence] = useState(null);
  const [updatedSequenceState, setUpdatedSequenceState] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState({});

  const [openPopup, setOpenPopup] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [activityId, setActivityId] = useState("");
  const [deleteActivityPop, setDeleteActivityPop] = useState(false);
  const [activityName, setActivityName] = useState("");
  // const [selectedActivityId, setSelectedActivityId] = useState(null);

  const [image, setImage] = useState(null);

  const getActivities = async (curPage, filters = filterState) => {
    return await axios
      .get(
        API_BASE_URL +
          `/researchers/activities?page=${curPage}&search=${filters.search}&status=${filters.status}&project_id=${workspaceState?.selectedProject?._id}`
      )
      .then((res) => {
        console.log("=====res=======:", res);
        if (res.status) {
          setAllActivitiesList(res.data.data);
          setPaginationData({
            currentPage: res.data.pagination.currentPage,
            itemsPerPage: res.data.pagination.itemsPerPage,
            totalPages: res.data.pagination.totalPages,
            totalItems: res.data.pagination.totalItems,
          });
        }
      });
  };

  useEffect(() => {
    getActivities(1);
  }, [workspaceState]);

  useEffect(() => {
    if (reloadList) {
      getActivities(1);
      setReloadList(false);
    }
  }, [reloadList]);

  const handleOpenPopup = (id, name) => {
    // setDeleteActivityPop(true);
    setActivityId(id);
    setActivityName(name);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  let handleUpdateActicityStatus = async (item, status) => {
    setLoading(true);
    try {
      let requestPayload = {
        status: status,
        activity_id: item._id,
      };
      let response = await changeActivityStatus(
        requestPayload,
        authState.authToken
      );
      setLoading(false);
      if (response.success) {
        toast.success(response.message, TOAST_CONFIG);
        setReloadList(true);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  const handleClick = (item) => {
    if (item.status === "archive") {
      handleUpdateActicityStatus(item, "pending");
    } else {
      handleUpdateActicityStatus(item, "archive");
    }
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      updateImage(file);
    }
  };

  const updateImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      let imageUploadRes = await uploadUserDirectoryImage(
        formData,
        authState.authToken
      );
      if (imageUploadRes.success) {
        updateCoverImage(imageUploadRes?.data?.upload_path);
        // toast.success(imageUploadRes.message, TOAST_CONFIG);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput1").click();
  };

  const updateCoverImage = async (imagePath) => {
    try {
      let requestPayload = {
        image: imagePath,
        activity_id: editActivityID,
      };
      console.log("editActivityID", editActivityID);
      let coverImgUploadRes = await updateCoverImageActivity(
        requestPayload,
        authState.authToken
      );
      if (coverImgUploadRes.success) {
        toast.success(coverImgUploadRes.message, TOAST_CONFIG);
        setEditActivityID("");
        setReloadList(true);
      } else {
        toast.error(coverImgUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  };

  const activityNavigate = (type, id) => {
    if (type === "Live Interview Activity") {
      Navigate(`/activities/interview-scheduled/${id}`);
    } else if (type === "Live Group Chat") {
      Navigate("/activities/live-chat-scheduled");
    } else {
      Navigate(`/activities/task/${id}`);
    }
  };

  useEffect(() => {
    const initialCheckboxStates = {};

    // Set initial state based on the condition
    allActivitiesList.forEach((item) => {
      if (item._type === "Standard Activity") {
        initialCheckboxStates[item._id] = true; // Checked initially if condition is met
      } else {
        initialCheckboxStates[item._id] = false; // Unchecked initially
      }
    });

    setCheckboxStates(initialCheckboxStates);
  }, [allActivitiesList]);

  useEffect(() => {
    const initialCheckboxStates = {};

    if (activitySequence) {
      allActivitiesList.forEach((item) => {
        console.log("Processing item:", item);
        if (item.sequenced) {
          initialCheckboxStates[item._id] = true;
          console.log(`Checkbox state for ${item._id} set to true`);
        } else {
          initialCheckboxStates[item._id] = false;
          console.log(`Checkbox state for ${item._id} set to false`);
        }
      });
    }

    console.log("Setting checkbox states:", initialCheckboxStates);
    setCheckboxStates(initialCheckboxStates);

    console.log(
      "Checking the value is set for setCheckboxStates:",
      checkboxStates
    );
  }, [allActivitiesList, activitySequence]);

  const handleDragStart = (index, sequence) => {
    setDraggingIndex(index);
    setDragSequence(sequence);
    console.log("The value of DragSequence:", sequence);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragDrop = (index, activity_id) => {
    console.log("Value of index on drop:", index);
    console.log("Value of draggingIndex:", draggingIndex);

    // Exit if the drop is at the same position
    if (index === draggingIndex) {
      return;
    }

    // Create a copy of the page list to modify
    const updatedItems = [...allActivitiesList];
    console.log("Initial page list:", updatedItems);

    // Remove the dragged item from its original position
    const [movedItem] = updatedItems.splice(draggingIndex, 1);

    console.log("The value of movedItem:", movedItem);

    // Insert the moved item at the new position
    updatedItems.splice(index, 0, movedItem);

    // Recalculate the sequence index for each page
    const updatedSequence = updatedItems.map((item, newIndex) => ({
      ...item,
      sequence_index: newIndex + 1, // Adjusted to one-based indexing if needed
      sequenced: checkboxStates[item._id] || false,
    }));

    console.log("=====Updated sequence after drop:", updatedSequence);

    // Update the state with the new order
    setAllActivitiesList(updatedSequence); // Update the list for rendering
    setDraggingIndex(null); // Reset the dragging index

    // Set the updated sequence state to be sent to the backend
    setUpdatedSequenceState(updatedSequence);
    console.log(
      "=====Updated sequence after drop and before API call:",
      updatedSequence
    );
  };

  const updatePageSequence = async (updatedSequenceState) => {
    try {
      console.log(
        "========The value of updatedSequence before posting through API:",
        updatedSequenceState
      );

      // Create the sequence payload for the API
      const sequence = updatedSequenceState.map((item) => ({
        activity_id: item._id, // The unique ID of the page
        index: item.sequence_index, // The new sequence index
        sequenced: item.sequenced,
      }));

      console.log("Sequence payload being sent to the API:", sequence);

      const response = await axios.post(
        API_BASE_URL + `/researchers/activities/update-sequence`,
        {
          sequence: sequence, // The API expects "sequence" as the key
        } // The API expects "sequence" as the key
      );

      if (response.status === 200) {
        toast.success(response.message, TOAST_CONFIG);
        setAllActivitiesList(updatedSequenceState);

        console.log("Task sequence updated successfully:", response.data);
      } else {
        toast.error(response.message, TOAST_CONFIG);

        console.error(
          "Failed to update task sequence:",
          response.status,
          response.data
        );
      }
    } catch (error) {
      console.error("Error updating task sequence:", error);
    }
  };

  const handleCheckboxChange = (id, e) => {
    const { checked } = e.target;

    // Update checkbox state first
    setCheckboxStates((prevStates) => ({
      ...prevStates,
      [id]: checked,
    }));

    // Immediately use the checked value to update the allActivitiesList

    const updatedItems = allActivitiesList.map((item) => ({
      ...item,
      sequenced: item._id === id ? checked : item.sequenced,
    }));

    // Update the state with the new sequence
    setUpdatedSequenceState(updatedItems);
  };

  const ActivitiesCard = (item, date, img, index) => {
    const isChecked =
      checkboxStates[item._id] !== undefined ? checkboxStates[item._id] : false;

    return (
      <Box
        mb={2}
        className="userHMBoxItem userHConsart"
        draggable={activitySequence}
        onDragStart={() =>
          activitySequence && handleDragStart(index, item?.sequence_index)
        }
        onDragOver={activitySequence ? handleDragOver : undefined}
        onDrop={() => activitySequence && handleDragDrop(index, item?._id)}
      >
        <Box className="sequenceActivityCard">
          <Box style={{ cursor: "pointer" }} className="conImgBox actCard">
            {img == "undefined" || img == null ? (
              <img
                src={w1}
                onClick={() => activityNavigate(item.type, item._id)}
              />
            ) : (
              <img
                src={viewImage(img)}
                onClick={() => activityNavigate(item.type, item._id)}
              />
            )}
          </Box>
          <Box className="userHCTextBox">
            <Typography
              sx={{
                cursor: "pointer",
                fontSize: "18px",
                color: "#101828",
                fontWeight: "600",
              }}
              onClick={() => activityNavigate(item.type, item._id)}
            >
              {item.name} ({item.type})
            </Typography>
            <img
              style={{ cursor: "pointer" }}
              src={OptionIcon}
              onClick={() => {
                if (activeCardOptionPop === index) {
                  setActiveCardOptionPop(null);
                  return;
                }
                setActiveCardOptionPop(index);
              }}
            />
          </Box>
          <Box className="userHCTextBox">
            <Box>
              <Typography sx={{ fontSize: "16px", color: "#98A2B3" }}>
                {date}
              </Typography>
              {/* <Box 
                sx={{ background: "#12B76A" }}
                className="ActiveStatus ActivityListPage"
              ></Box> */}
            </Box>
            <Box>
              <Box
                sx={{ background: "#12B76A" }}
                className="ActivityListPage"
              ></Box>
              <img src={users} />
              <Typography ml={1} sx={{ fontSize: "20px", color: "#98A2B3" }}>
                11
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          mb={2}
          ml={2}
          sx={{
            display: activitySequence ? "flex" : "none",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {item.type !== "Live Interview Activity" ? (
            <>
              <input
                style={{ marginRight: "10px" }}
                className="custom-checkbox"
                type="checkBox"
                checked={isChecked}
                onChange={(e) => handleCheckboxChange(item._id, e)}
              />
              <Typography>{t("observer.Sequenced")}</Typography>
            </>
          ) : null}
        </Box>

        {/* option pop */}
        <Box
          sx={{ display: activeCardOptionPop === index ? "flex" : "none" }}
          className="activeCardOptionPop"
        >
          {authState?.wsUserType == USER_TYPE_RESEARCHER ||
          authState?.wsUserType == USER_TYPE_COLLABORATOR ? (
            <Box
              className="acItem"
              onClick={() => {
                setActiveCardOptionPop(null);
                setEditActivityID(item._id);
                setPopupAction("Edit");
                setAddActivitiesPop(true);
              }}
            >
              {ActiveCSettingIcon}
              <Typography className="acdl">
                {t("observer.ActivitySettings")}
              </Typography>
            </Box>
          ) : (
            ""
          )}
          <Box
            className="acItem"
            onClick={() => {
              setActiveCardOptionPop(null);
              Navigate(`/activities/preview/${item._id}`);
            }}
          >
            {activeCEyeIcon}
            <Typography className="acdl">{t("observer.Preview")}</Typography>
          </Box>
          {authState?.wsUserType == USER_TYPE_RESEARCHER ||
          authState?.wsUserType == USER_TYPE_COLLABORATOR ? (
            <Box
              className="acItem"
              onClick={() => {
                setActiveCardOptionPop(null);
                triggerFileInput();
                setEditActivityID(item._id);
              }}
            >
              {activeCImageIcon}
              <Typography className="acdl">
                {t("observer.ChangeCImage")}
              </Typography>
            </Box>
          ) : (
            ""
          )}
          {authState?.wsUserType == USER_TYPE_RESEARCHER ||
          authState?.wsUserType == USER_TYPE_COLLABORATOR ? (
            <Box
              className="acItem"
              onClick={() => {
                setActiveCardOptionPop(null);
                setEditActivityID(item._id);
                setPopupAction("Duplicate");
                setAddActivitiesPop(true);
              }}
            >
              {activeCEyeIcon}
              <Typography className="acdl">
                {t("observer.Duplicate")}
              </Typography>
            </Box>
          ) : (
            ""
          )}
          <Box className="acItem" onClick={() => setActiveCardOptionPop(null)}>
            {activeCCopyIcon}
            <Typography className="acdl">{t("observer.Reports")}</Typography>
            {arrowIcon}
          </Box>
          <Box
            className="acItem transcript"
            onClick={() => setActiveCardOptionPop(null)}
          >
            {activeCPestIcon}
            <Typography className="acdl">
              {t("observer.Transcripts")}
            </Typography>
            {arrowIcon}
            <Box className="acSubItem">
              <Box>
                <Typography>{t("observer.ActivityTranscript")}</Typography>
              </Box>
              <Box>
                <Typography>{t("observer.ChartSData")}</Typography>
              </Box>
              <Box>
                <Typography>{t("observer.QuantitativeData")}</Typography>
              </Box>
              <Box>
                <Typography>{t("observer.ExportVideo")}</Typography>
              </Box>
            </Box>
          </Box>
          {authState?.wsUserType == USER_TYPE_RESEARCHER ||
          authState?.wsUserType == USER_TYPE_COLLABORATOR ? (
            <Box
              className="acItem"
              onClick={() => {
                setActiveCardOptionPop(null);
                handleClick(item);
              }}
            >
              {activeCFormIcon}
              {/* <Typography className="acdl">{t("commonTxt.archiveBtn")}</Typography> */}
              <Typography className="acdl">
                {item?.status === "archive" ? (
                  <Typography>UnArchive</Typography>
                ) : (
                  t("commonTxt.archiveBtn")
                )}
              </Typography>
            </Box>
          ) : (
            ""
          )}
          {authState?.wsUserType == USER_TYPE_RESEARCHER ||
          authState?.wsUserType == USER_TYPE_COLLABORATOR ? (
            <Box
              className="acItem"
              onClick={() => {
                setActiveCardOptionPop(null);
                handleOpenPopup(item._id, item.name);
                setDeleteActivityPop(true);
              }}
            >
              {activeCTrashIcon}
              <Typography className="acdl">{t("commonTxt.delete")}</Typography>
            </Box>
          ) : (
            ""
          )}
        </Box>
        <input
          id="fileInput1"
          style={{ display: "none" }}
          type="file"
          onChange={(event) => handleFileInputChange(event)}
        />
        {/* <Dialog
          open={openPopup}
          onClose={handleClosePopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this activity?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePopup} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDelete} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog> */}
      </Box>
    );
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const initialState = {
    name: "",
    image: null,
    type: "",
    workspace_id: workspaceState?.selectedWorkspace?._id,
    project_id: workspaceState?.selectedProject?._id,
    basic_settings: {
      start_date: null,
      end_date: null,
      archive_date: null,
      country_id: null,
      timezone: null,
      extra_instruction: null,
      visibility: null,
      selected_groups: [],
      response_visibility: null,
      randomize_task: false,
      activity_guidance: false,
      activity_guidance_data: {
        heading: null,
        description: null,
        image: null,
      },
      enable_live_video: true,
      start_muted_participants: false,
      start_disabled_participants_cameras: false,
      revoke_camera_enable_action: false,
      disable_backroom_audio_translator_support: false,
      limit_invited_members: false,
      limit_visibility: false,
      description: null,
      host_scheduling: "participants-can-schedule",
      participants_schedule_settings: {
        activity_start_time: null,
        invite_notify: false,
        reminder_notify: false,
      },
      meeting_host: [],
      live_group_duration: "",
      meeting_duration: null,
      meeting_buffer: null,
      meeting_days: [],
      send_invitation: null,
      host_availability: null,
      include_pending: false,
      limit_scheduling: false,
      disable_cameras: false,
      revoke_camera_permission: false,
      disable_audio_translator_support: false,
      limit_participant_check: false,
      limit_participant: null,
      require_hour_check: false,
      require_hour: null,
      meeting_invitation: null,
      journal_requirement: null,
      journal_unlimited_responses: false,
      journal_donot_group_entries: false,
      journal_responses_fixed_intervel: false,
      journal_repeat_number: null,
      journal_repeat_unit: null,
    },
    notification_settings: {
      new_activity_enable: false,
      activity_reminder: false,
      activity_overdue: false,
      start_tomorrow: false,
      start_soon: true,
      started: false,
      is_meeting_scheduled: false,
      is_customize: false,
      is_missed: false,
      is_rescheduled: false,
      is_cancelled: false,
      notify_administrator: null,
      administrator_roles: [],
      admin_ids: [],
      starting_soon_heading: null,
      starting_soon_description: null,
      after_reminder: false,
      after_reminder_interval: {
        count: 0,
        unit: null,
      },
    },
    points_customization: {
      customize_points: true,
      standard_completion: null,
      comment_own: null,
      comment_response: null,
      like_response: null,
      like_rating_received: null,
    },
    status: "pending",
  };

  const [activityData, setActivityData] = useState(initialState);

  const onActivityPageChange = (pageNum) => {
    getActivities(pageNum);
  };

  const dateTxt = (sDate, eDate) => {
    const rSdate = sDate ? formatDate(sDate, "MMM DD") : "####";
    const rEdate = eDate ? formatDate(eDate, "MMM DD") : "####";
    return `${rSdate} - ${rEdate}`;
  };

  return (
    <>
      <Box className="ResearchersContainer ">
        {/* side menu */}
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />

        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
            <Box className="AppNavBtnBox">
              <AppBtnOutLine
                onClick={() => setActivitySequence(!activitySequence)}
                buttonText={
                  activitySequence
                    ? t("commonTxt.deactivateSequenceBtn")
                    : t("commonTxt.activitySequenceBtn")
                }
              />
              {authState?.wsUserType == USER_TYPE_RESEARCHER ||
              authState?.wsUserType == USER_TYPE_COLLABORATOR ? (
                <AppAddBtn
                  buttonText={t("commonTxt.addActivityBtn")}
                  onClick={() => {
                    setAddActivitiesPop(true);
                    setActivityData(initialState);
                  }}
                />
              ) : authState?.wsUserType == USER_TYPE_OBSERVER ? (
                <AppBtn buttonText="View Responses" />
              ) : (
                ""
              )}
            </Box>
          </Box>

          {/* Page container */}
          <Box className="researchersMainBox pageContainer">
            {data ? (
              <ActivityEmptyPage />
            ) : (
              <Box className="ActivitieContainer">
                <AddActivitiesPop
                  addActivitiesPop={addActivitiesPop}
                  setAddActivitiesPop={setAddActivitiesPop}
                  setActivityGuidancePop={setActivityGuidancePop}
                  activityData={activityData}
                  setActivityData={setActivityData}
                  initialState={initialState}
                  editActivityID={editActivityID}
                  setEditActivityID={setEditActivityID}
                  popupAction={popupAction}
                  setPopupAction={setPopupAction}
                  setReloadList={setReloadList}
                />
                <ActivityGuidancePop
                  activityGuidancePop={activityGuidancePop}
                  setActivityGuidancePop={setActivityGuidancePop}
                  setAddActivitiesPop={setAddActivitiesPop}
                  activityData={activityData}
                  setActivityData={setActivityData}
                />
                <DeleteActivityPop
                  deleteActivityPop={deleteActivityPop}
                  setDeleteActivityPop={setDeleteActivityPop}
                  setReloadList={setReloadList}
                  activityId={activityId}
                  activityName={activityName}
                />

                <Box
                  sx={{ display: activitySequence ? "block" : "none" }}
                  mb={4}
                  className="workSpaceListBox"
                >
                  <Typography className="atvHeaderText">
                    {t("activityPage.activitySequenceHead")}
                  </Typography>
                  <Box mt={2} className="iBox">
                    <img src={iIcon} />
                    <Typography>
                      {t("activityPage.activitySequenceNotify1")}
                    </Typography>
                    <Typography my={1.5}>
                      {t("activityPage.activitySequenceNotify2")}
                    </Typography>
                    <Typography>
                      {t("activityPage.activitySequenceNotify3")}
                    </Typography>
                  </Box>
                  <Box className="radioBtnBox">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Box className="redioItem" onClick={() => setRadio(0)}>
                        <Box
                          className={
                            radio === 0
                              ? "customRedio customRedioActive"
                              : "customRedio"
                          }
                        >
                          <Box></Box>
                        </Box>
                        <Typography>
                          {t("activityPage.activitySequenceRadio1")}
                        </Typography>
                      </Box>

                      <Box className="redioItem" onClick={() => setRadio(1)}>
                        <Box
                          className={
                            radio === 1
                              ? "customRedio customRedioActive"
                              : "customRedio"
                          }
                        >
                          <Box></Box>
                        </Box>
                        <Typography>
                          {t("activityPage.activitySequenceRadio2")}
                        </Typography>
                      </Box>
                    </Box>
                    <PurplelBtn
                      buttonText={t("commonTxt.doneBtn")}
                      onClick={() => updatePageSequence(updatedSequenceState)}
                    />
                  </Box>
                </Box>
                <Box className="radioBtnBox">
                  <Box
                    sx={{ justifyContent: "start" }}
                    className="activityNav1"
                  >
                    <Box className="projectNavIndicat">
                      {inProgressIcon}
                      <Typography ml={1}>In Progress</Typography>
                    </Box>
                    <Box ml={2} className="projectNavIndicat">
                      {completedIcon}
                      <Typography ml={1}>Completed</Typography>
                    </Box>
                    <Box ml={2} className="projectNavIndicat">
                      {pendingIcon}
                      <Typography ml={1}>Pending</Typography>
                    </Box>
                  </Box>
                  {/* Status Meaning */}
                  <Box>
                    <Box className="activitySearch">
                      <Box></Box>
                      <Box>
                        <SearchBar
                          handleFilterChange={handleFilterChange}
                          val={filterState}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="activitiesCardBox">
                  {allActivitiesList?.length > 0 &&
                    allActivitiesList.map((item, index) =>
                      ActivitiesCard(
                        item,
                        dateTxt(
                          item?.basic_settings?.start_date,
                          item?.basic_settings?.end_date
                        ),
                        item.image,
                        index
                      )
                    )}
                </Box>
                <Box className="pagination">
                  <Pagination
                    paginationData={paginationData}
                    onPageChange={onActivityPageChange}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
