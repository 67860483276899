import React from 'react';
import AceEditor from 'react-ace';
import "./style.css"

// Import the required styles
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/mode-javascript';

function CodeEditor({ colorSelectorDrop3Val, scriptVal, setScriptVal, field }) {
  // Default code
  const CSSCode = `.coverImgTopBox .urlBtn {
    width: 140px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .coverImgTopBox .urlBtn p {
    font-size: 16px !important;
    margin-left: 8px !important;
  }
  .coverImgTopBox .vrLine {
    height: 40px;
    border-left: 2px solid #fff;
    margin: 0 10px;
  }
  .coverImgTopBox .urlBtn img {
    width: 20px;
    height: 20px;
  }
  .coverImgTopBox .whiteB {
    border-radius: 8px;
    background: #edebfa;
  }
  .coverImgTopBox .BlueB {
    border-radius: 8px;
    background: #4f33d1;
  }
  .wsAppearance .colorSelectorItem {
    width: 100%;
  }
  .codeBox {
    width: 100%;
    height: 395px;
    border-radius: 10px;
    border: 1px solid #4f33d1;
    overflow: hidden;
  }
  `;



  const HTMLCode = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Sample HTML Page</title>
  </head>
  <body>
      <header>
          <h1>Welcome to My Website</h1>
      </header>
      <nav>
          <ul>
              <li><a href="#home">Home</a></li>
              <li><a href="#about">About</a></li>
              <li><a href="#contact">Contact</a></li>
          </ul>
      </nav>
      <main>
          <section id="home">
              <h2>Home Section</h2>
              <p>This is the home section of my website. Welcome!</p>
          </section>
          <section id="about">
              <h2>About Section</h2>
              <p>This is the about section where you can learn more about me.</p>
          </section>
          <section id="contact">
              <h2>Contact Section</h2>
              <p>You can contact me through the following means...</p>
          </section>
      </main>
      <footer>
          <p>&copy; 2024 My Website. All rights reserved.</p>
      </footer>
  </body>
  </html>
  `

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <AceEditor
        mode={colorSelectorDrop3Val === "Custom CSS" ? "css" : "html"}
        theme="monokai"
        onChange={(newValue) => {
          // console.log('change', newValue);
          setScriptVal({
            ...scriptVal,
            [field]: newValue
          })
        }}
        name="UNIQUE_ID_OF_DIV"
        editorProps={{ $blockScrolling: true }}
        // Set the default code
        value={colorSelectorDrop3Val === "Custom CSS" ? CSSCode : HTMLCode}
      />
    </div>
  );
}

export default CodeEditor;
