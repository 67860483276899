import React, { useState, useEffect } from 'react';
import { Box, Typography } from "@mui/material";
import axios from "../../../lib/axios";
import "./style.css";

//image
import dropIcon from "../../../Assets/Images/chevron-down.png"
import dropArrow from "../../../Assets/Images/chevron-down.png";
import groupsIcon from "../../../Assets/Images/groupsIcon.png";
import exportIcon from "../../../Assets/Images/ExpoGroup.png";
import NIcon from "../../../Assets/Images/neutronIcon.png";
import userF from "../../../Assets/Images/userInF.png";
import arrow from "../../../Assets/Images/arrow.png";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";

import { AntSwitch, Status, SearchBar, GoTop } from "../../../Components/AppTools/AppTool";
import { AppBtnOutLine, AppBtn } from "../../../Components/AppTools/AppButton";
import { toast } from "react-toastify";
import { API_BASE_URL } from '../../../env';
import ReferenceDateExplicitTimePicker from '../../../Components/AppTools/TimePicker';

//Data
import { participantsData } from "../../../Assets/Data";

//component
import EmailBroadcastPop from '../../../Components/WSAdministration/EmailBroadcastPop';
import EmailPreviewPop from '../../../Components/WSAdministration/EmailPreviewPop';
import EmailSchedulePop from '../../../Components/WSAdministration/EmailSchedulePop';

import { useAppSelector } from '../../../lib/store/store';
import { renderUserLayout } from '../../../lib/helpers';
import { TOAST_CONFIG } from "../../../lib/constants";

export default function EmailPage({ }) {

  const authState = useAppSelector((state) => state.auth);
  const userId = authState?.userProfile?._id;
  const workspaceState = useAppSelector((state) => state.workspace?.selectedWorkspace?._id);

  const [nav, setNav] = useState(0)
  const [nav2, setNav2] = useState(0)

  const [groupDrop, setGroupDrop] = useState(false);
  const [groupDropVal, setGroupDropVal] = useState();

  const [ParticipantsNavDrop, setParticipantsNavDrop] = useState(false);
  const [ParticipantsNavDropVal, setParticipantsNavDropVal] = useState();

  const [ParticipantsNavDrop2, setParticipantsNavDrop2] = useState(false);
  const [ParticipantsNavDropVal2, setParticipantsNavDropVal2] = useState();

  const [participants, setParticipants] = useState(false);
  const [statusDrop, setStatusDrop] = useState(false)
  const [statusDropVal, setStatusDropVal] = useState("Participant")
  const label = " "
  const itemAry = [
    {
      name: "Participant",
      value: "Participant",
    },
    {
      name: "Admins",
      value: "Admins",
    },
  ];
  const [loading, setLoading] = useState(false);
  const [projectDrop, setProjectDrop] = useState(false);
  const [projectDropVal, setProjectDropVal] = useState();
  const [recipientDropVal, setRecipientDropVal] = useState();

  const [researcher, setResearcher] = useState(false)
  const [collaborator, setCollaborator] = useState(false)
  const [observer, setObserver] = useState(false)

  const [emailBroadcastPop, setEmailBroadcastPop] = useState(false);
  const [emailPreviewPop, setEmailPreviewPop] = useState(false);
  const [emailSchedulePop, setEmailSchedulePop] = useState(false);

  const [invitedUsers, setInvitedUsers] = useState([]);
  const [bulkActionDropVal, setBulkActionDropVal] = useState(false);
  const [checkboxes, setCheckboxes] = useState(
    participantsData.map(() => false)
  );

  const initializeNotificationObj = {
    type: "",
    settings: [],
    workspace_id: workspaceState,
    reset_settings: false,
    hide_from_participants: false,
    hide_settings:[]
  };
  const [emailNotification, setEmailNotification] = useState({ ...initializeNotificationObj });

  const notificationData = {
    "type":"none",
    "settings":[],
    "workspace_id":workspaceState,
"reset_settings":true,
"hide_from_participants":true,
"hide_settings":["hourly","none"]
};

  const handleCheckAllChange = (event) => {
    const isChecked = event.target.checked;
    setCheckboxes(checkboxes.map(() => isChecked));
  };

  const handleCheckboxChange = (index) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index] = !updatedCheckboxes[index];
    setCheckboxes(updatedCheckboxes);

    // Update Invite pop-up
    // If checked and bulk action is "Invite-to-Project", add user details to invitedUsers state
    if (updatedCheckboxes[index] && bulkActionDropVal === "Invite-to-Project") {
      setInvitedUsers([...invitedUsers, participantsData[index]]);
    } else if (
      !updatedCheckboxes[index] &&
      bulkActionDropVal === "Invite-to-Project"
    ) {
      // If unchecked, remove user details from invitedUsers state
      const newUserList = invitedUsers.filter((user, idx) => idx !== index);
      setInvitedUsers(newUserList);
    }

    // Updated Invite pop-up
  };


  useEffect(() => {
    fetchEmailNotificationSetting();
  }, []);

  const fetchEmailNotificationSetting = async () => {
    try {
      const response = await axios.get(`/researchers/notification-settings`);
      if (response.success) {
        let initNotification = { ...initializeNotificationObj };
        const notifications = response.data?.data[0];
        //console.log('get notification values',notifications?.hide_settings);
        let key;
        for (key in initNotification) {
          initNotification[key] = notifications[key];
        }
        // setEmailNotification(notifications);
        setEmailNotification(initNotification);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
    finally {
      setLoading(true);
    }
  }


  const handleFrequencyChange = (newFrequency) => {
    //alert(newFrequency);
    setEmailNotification({ ...emailNotification, type: newFrequency });
  };

  const changeParticipantSetting = (e,value2) => {
    if(e.target.checked){
    if(emailNotification?.hide_settings.includes(value2)){

    }
    else {
      emailNotification?.hide_settings.push(value2);
    }
  }
  else {
    let index5= emailNotification?.hide_settings.indexOf(value2);
    emailNotification?.hide_settings.splice(index5, 1);
  }
    // emailNotification?.hide_settings.indexOf(value2) === -1 ? selectedItems.push(value2): ''
    console.log("checkbox value=>",emailNotification);
  }

  let updateEmailNotification = async () => {
    console.log("Notification Email=>",emailNotification);
    setLoading(true);
    try {
      let response = await axios.post(
        API_BASE_URL + `/researchers/notification-settings/save`,
        emailNotification,
        authState.authToken
      );
      if(response.success){
        fetchEmailNotificationSetting();
        toast.success(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      let message = "Something went wrong, please try again!";
      let status_code = 500;
      if (error.response) {
        message = error.response.data.message;
        status_code = error.response.status;
      }
      return {
        success: false,
        message: message,
        status_code: status_code,
      };
    }
  };

  const isChecked = (lenSt) => {
    return emailNotification?.hide_settings.includes(lenSt) ? true : false;
  };

  const PropertyCheck = ({ title, setState, state }) => {
    return (
      <Box mt={2} sx={{ width: "100%" }}>
        <Typography mb={1} className="workspaceLabel">{title}</Typography>
        <Box className="inputBox" onClick={() => setState(!state)}>
          <input className="workspaceInput categoryInput" placeholder={`Select ${title}`} />
          <img className="downArrow" src={DownArrowIcon} />
          <Box className="categoryDrop" ></Box>
        </Box>
        <Box sx={{ display: state ? "flex" : "none" }} className="ckeckBoxRoom">
          {chackBoxRoom()}
          {chackBoxRoom()}
          {chackBoxRoom()}
        </Box>
        <Box sx={{ display: state ? "flex" : "none" }} className="propertyPagination">
          <Box>
            <p>1</p>
          </Box>
          <Box>
            <p>2</p>
          </Box>
          <Box>
            <p>3</p>
          </Box>
          <Box>
            <p>4</p>
          </Box>
          <Box>
            <p>...</p>
          </Box>
          <Box>
            <p>8</p>
          </Box>
          <Box>
            <p>9</p>
          </Box>
          <Box>
            <p>10</p>
          </Box>
        </Box>
      </Box>
    )
  }




  const chackBoxRoom = () => {
    return (
      <Box className="checkBoxItem">
        <Box>
          <input className="custom-checkbox" type='checkBox' defaultChecked={recipientDropVal === "Custom" ? false : true} />
          <Typography>Northeast Lorem</Typography>
        </Box>
        <Box>
          <input className="custom-checkbox" type='checkBox' defaultChecked={recipientDropVal === "Custom" ? false : true} />
          <Typography>Southwest Ipsum</Typography>
        </Box>
        <Box>
          <input className="custom-checkbox" type='checkBox' defaultChecked={recipientDropVal === "Custom" ? false : true} />
          <Typography>Northeast Lorem</Typography>
        </Box>
      </Box>
    )
  }

  const getChildren = () => {
    return <Box className="researchersMainBox pageContainer">
      <Box className="EmailPage workspaceListBox">
        <EmailBroadcastPop emailBroadcastPop={emailBroadcastPop} setEmailBroadcastPop={setEmailBroadcastPop} setEmailPreviewPop={setEmailPreviewPop} setEmailSchedulePop={setEmailSchedulePop} />
        <EmailPreviewPop emailPreviewPop={emailPreviewPop} setEmailPreviewPop={setEmailPreviewPop} setEmailBroadcastPop={setEmailBroadcastPop} />
        <EmailSchedulePop emailSchedulePop={emailSchedulePop} setEmailSchedulePop={setEmailSchedulePop} />
        <Box className="allPNavTebe">
          <Box
            onClick={() => setNav(0)}
            className={
              nav === 0
                ? "allpNavTebeItem allpNavTebeItemActive"
                : "allpNavTebeItem"
            }
          >
            <Typography>Notifications</Typography>
          </Box>
          <Box
            onClick={() => setNav(1)}
            className={
              nav === 1
                ? "allpNavTebeItem allpNavTebeItemActive"
                : "allpNavTebeItem"
            }
          >
            <Typography>Broadcast</Typography>
          </Box>
        </Box>

        {/* notification */}
        <Box sx={{ display: nav === 0 ? "block" : "none" }} className="mailtabe">
          <Typography className='mailLabel'>Notification Frequency</Typography>
          <Typography>Set the default frequency for email notifications</Typography>

          <Box mt={2} className="redioBtnBox">
            {/* <Box className="redioItem" onClick={() => setRedio(0)}>
              <Box
                className={
                  redio === 0 ? "customRedio customRedioActive" : "customRedio"
                }
              >
                <Box></Box>
              </Box>
              <Typography>None</Typography>
            </Box> */}
            <Box className="radioBox">
              <Box>
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="type"
                  onClick={() => {
                    handleFrequencyChange('none');
                    setEmailNotification({
                      ...emailNotification,
                      type: "none",
                    });
                  }}
                  checked={emailNotification?.type === 'none'}
                />
              </Box>
              <Box>
                <Typography className="emailSettingLabel">None</Typography>
              </Box>
            </Box>

            {/* <Box className="redioItem" onClick={() => setRedio(1)}>
              <Box
                className={
                  redio === 1 ? "customRedio customRedioActive" : "customRedio"
                }
              >
                <Box></Box>
              </Box>
              <Typography>Immediately</Typography>
            </Box> */}
            <Box className="radioBox">
              <Box>
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="type"
                  onClick={() => {
                    handleFrequencyChange('immediately');
                    setEmailNotification({
                      ...emailNotification,
                      type: "immediately",
                    });
                  }}
                  checked={emailNotification?.type === 'immediately'}
                />
              </Box>
              <Box>
                <Typography className="emailSettingLabel">Immediately</Typography>
              </Box>
            </Box>

            {/* <Box className="redioItem" onClick={() => setRedio(2)}>
              <Box
                className={
                  redio === 2 ? "customRedio customRedioActive" : "customRedio"
                }
              >
                <Box></Box>
              </Box>
              <Typography>Hourly</Typography>
            </Box> */}
            <Box className="radioBox">
              <Box>
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="type"
                  onClick={() => {
                    handleFrequencyChange('hourly');
                    setEmailNotification({
                      ...emailNotification,
                      type: "hourly",
                    });
                  }}
                  checked={emailNotification?.type === 'hourly'}
                />
              </Box>
              <Box>
                <Typography className="emailSettingLabel">Hourly</Typography>
              </Box>
            </Box>

            {/* <Box className="redioItem" onClick={() => setRedio(3)}>
              <Box
                className={
                  redio === 3 ? "customRedio customRedioActive" : "customRedio"
                }
              >
                <Box></Box>
              </Box>
              <Typography>Daily</Typography>
            </Box> */}
            <Box className="radioBox">
              <Box>
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="type"
                  onClick={() => {
                    handleFrequencyChange('daily');
                    setEmailNotification({
                      ...emailNotification,
                      type: "daily",
                    });
                  }}
                  checked={emailNotification?.type === 'daily'}
                />
              </Box>
              <Box>
                <Typography className="emailSettingLabel">Daily</Typography>
              </Box>
            </Box>

            <Box ml={5} sx={{ display: emailNotification?.type === 'daily' ? "flex" : "none" }} className="dailyBox">
              <Typography>at</Typography>

              <Box className="pickerBox dailyTimePickerBox">
                <ReferenceDateExplicitTimePicker placeholder="Select Time" />
              </Box>

              <Box className="pickerBox dailyTimePickerBox">
                <ReferenceDateExplicitTimePicker placeholder="Select Time" />
              </Box>
              <Box className="pickerBox dailyTimePickerBox">
                <ReferenceDateExplicitTimePicker placeholder="Select Time" />
              </Box>
              <span>Add Time</span>
            </Box>

            {/* <Box className="redioItem" onClick={() => setRedio(4)}>
              <Box
                className={
                  redio === 4 ? "customRedio customRedioActive" : "customRedio"
                }
              >
                <Box></Box>
              </Box>
              <Typography>Weekly</Typography>
            </Box> */}
            <Box className="radioBox">
              <Box>
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="type"
                  onClick={() => {
                    handleFrequencyChange('weekly');
                    setEmailNotification({
                      ...emailNotification,
                      type: "weekly",
                    });
                  }}
                  checked={emailNotification?.type === 'weekly'}
                />
              </Box>
              <Box>
                <Typography className="emailSettingLabel">Weekly</Typography>
              </Box>
            </Box>
          </Box>

          <Box className="airadioSwitchBox">
            <AntSwitch onChange={(el) => setEmailNotification({ ...emailNotification, hide_from_participants: el.target.checked })} checked={emailNotification?.hide_from_participants} inputProps={{ 'aria-label': 'ant design' }} />
            <Typography className='switchText'>Hide options from participants</Typography>
          </Box>
          
          {loading && (
          <Box ml={5} sx={{ display: emailNotification?.hide_from_participants ? "flex" : "none" }} className="hideChckBox">
            <Box className="hideChckInBox">
              <Box>
                <input className="custom-checkbox" type="checkBox" onChange={(e) => changeParticipantSetting(e,'none')} defaultChecked={isChecked('none')}/>
                <Typography>None</Typography>
              </Box>

              <Box>
                <input className="custom-checkbox" type="checkBox" onChange={(e) => changeParticipantSetting(e,'immediately')} defaultChecked={isChecked('immediately')} />
                <Typography>Immediately</Typography>
              </Box>
            </Box>

            <Box className="hideChckInBox">
              <Box>
                <input className="custom-checkbox" type="checkBox" onChange={(e) => changeParticipantSetting(e,'hourly')} defaultChecked={isChecked('hourly')} />
                <Typography>Hourly</Typography>
              </Box>

              <Box>
                <input className="custom-checkbox" type="checkBox" onChange={(e) => changeParticipantSetting(e,'daily')} defaultChecked={isChecked('daily')} />
                <Typography>Daily</Typography>
              </Box>
            </Box>
          </Box>)}

          <Box className="airadioSwitchBox">
            <AntSwitch inputProps={{ 'aria-label': 'ant design' }} onChange={(el) => setEmailNotification({ ...emailNotification, reset_settings: el.target.checked })} checked={emailNotification?.reset_settings} />
            <Typography className='switchText'>reset settings for participants to the default above</Typography>
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" />
            <AppBtn buttonText="Save" onClick={() => updateEmailNotification()} />
          </Box>

        </Box>


        {/* Broadcast */}
        <Box sx={{ display: nav === 1 ? "block" : "none" }} className="mailtabe">
          <Typography className='mailLabel'>Automatic Login</Typography>
          <Typography>To assist new and returning participants, links in outgoing emails can perform an automatic login.</Typography>

          <Box my={4} className="airadioSwitchBox">
            <AntSwitch inputProps={{ 'aria-label': 'ant design' }} />
            <Typography className='switchText'>Enable automatic login links in email broadcasts</Typography>
          </Box>

          <Box className="brodBbox">
            <Box className="brodNumbBox">
              <Typography>Only allow automatic login for</Typography>
              <input style={{ width: "30px", height: "28px", margin: "0 10px" }} className='sbdInput phoneInput' value={1} />
              <Typography>hours after a message is sent</Typography>
            </Box>

            <Box mt={2} mb={2} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <input style={{ marginRight: "10px" }} className="custom-checkbox" type="checkBox" />
              <Typography>Include automatic links in all other outgoing emails</Typography>
            </Box>
            <Typography>If an automatic login link has expired, or is followed by an admin, a secondary verification code can be
              provided for instant access without a password. This code will be sent by email unless the user has two-factor
              authentication enabled.</Typography>

            <Box mt={2} mb={2} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <input style={{ marginRight: "10px" }} className="custom-checkbox" type="checkBox" />
              <Typography>Enable secondary identity verification</Typography>
            </Box>
          </Box>
        </Box>
      </Box>


      {/* Broadcast extantion*/}
      <Box sx={{ display: nav === 1 ? "block" : "none" }} mt={4} className="workspaceListBox">
        <Typography className='mailLabel'>Email Broadcast</Typography>
        <Typography mt={4} >Recipient</Typography>
        <Box mt={1} mb={2} className="participantDrop">
          <Status itemAry={itemAry} setStatusDrop={setStatusDrop} setStatusDropVal={setStatusDropVal} statusDrop={statusDrop} statusDropVal={statusDropVal} label={label} />
        </Box>



        {/* --------------Participant--------------- */}
        <Box sx={{ display: statusDropVal === "Participant" ? "block" : "none" }}>
          <Box className="allPFilters">
            <Typography className="pageHeaderText">Filter</Typography>
            <Box className="allPNavTebe">
              <Box onClick={() => setNav2(0)}
                className={
                  nav2 === 0
                    ? "allpNavTebeItem allpNavTebeItemActive"
                    : "allpNavTebeItem"
                }
              >
                <Typography>Basic Filters</Typography>
              </Box>
              <Box
                onClick={() => setNav2(1)}
                className={
                  nav2 === 1
                    ? "allpNavTebeItem allpNavTebeItemActive"
                    : "allpNavTebeItem"
                }
              >
                <Typography>Advance Filters</Typography>
              </Box>
            </Box>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
              <Box
                sx={{ width: nav2 === 0 ? "100%" : "0px" }}
                className="basicFilterBox"
              >
                <Box
                  className="allPbasicFinputBox"
                  sx={{ display: nav2 === 0 ? "flex" : "none" }}
                >
                  <Box className="inputBoxWlabel">
                    <Typography className="inputLabel">Search</Typography>
                    <SearchBar />
                  </Box>

                  <Box className="inputBoxWlabel">
                    <Typography className="inputLabel">Project</Typography>

                    <Box
                      className="inputAppDrop"
                      onClick={() => setProjectDrop(!projectDrop)}
                    >
                      <Typography>
                        {projectDropVal || "Filter by Project"}
                      </Typography>
                      <img
                        style={{ rotate: projectDrop ? "180deg" : "0deg" }}
                        src={dropArrow}
                      />
                      {/* pop */}
                      <Box
                        sx={{ height: projectDrop ? "auto" : "0px" }}
                        className="statusPop"
                      >
                        <Box
                          onClick={() => setProjectDropVal("Item-A")}
                          className="statusPopItem"
                        >
                          <Typography>Item-A</Typography>
                        </Box>
                        <Box
                          onClick={() => setProjectDropVal("Item-B")}
                          className="statusPopItem"
                        >
                          <Typography>Item-B</Typography>
                        </Box>
                        <Box
                          onClick={() => setProjectDropVal("Item-C")}
                          className="statusPopItem"
                        >
                          <Typography>Item-C</Typography>
                        </Box>
                        <Box
                          onClick={() => setProjectDropVal("Item-D")}
                          className="statusPopItem"
                        >
                          <Typography>Item-D</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{ width: "100%", display: nav2 === 0 ? "block" : "none" }}
                >
                  <Box sx={{ width: "100%" }} className="inputBoxWlabel">
                    <Typography className="inputLabel">Groups</Typography>
                    <Box
                      pl={4.5}
                      className="inputAppDrop"
                      onClick={() => setGroupDrop(!groupDrop)}
                    >
                      <img className="groupsIcon" src={groupsIcon} />
                      <Typography>{groupDropVal || "Search Groups"}</Typography>
                      <img
                        style={{ rotate: groupDrop ? "180deg" : "0deg" }}
                        src={dropArrow}
                      />
                      {/* pop */}
                      <Box
                        className="statusPop"
                        sx={{ height: groupDrop ? "auto" : "0px" }}
                      >
                        <Box
                          onClick={() => setGroupDropVal("Item-A")}
                          className="statusPopItem"
                        >
                          <Typography>Item-A</Typography>
                        </Box>
                        <Box
                          onClick={() => setGroupDropVal("Item-B")}
                          className="statusPopItem"
                        >
                          <Typography>Item-B</Typography>
                        </Box>
                        <Box
                          onClick={() => setGroupDropVal("Item-C")}
                          className="statusPopItem"
                        >
                          <Typography>Item-C</Typography>
                        </Box>
                        <Box
                          onClick={() => setGroupDropVal("Item-D")}
                          className="statusPopItem"
                        >
                          <Typography>Item-D</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{ display: nav2 === 0 ? "flex" : "none" }}
                  className="filterBottomBox"
                >
                  <Box className="filterBottomInnerBox">
                    <Typography className="LinkText">Clear</Typography>
                    <Typography mx={3} className="LinkText">
                      Apply
                    </Typography>
                    <Typography>21 matches</Typography>
                  </Box>
                  <Box className="filterBottomInnerBox">
                    <AntSwitch
                      defaultChecked
                      inputProps={{ "aria-label": "ant design" }}
                    />
                    <Typography ml={2} className="switchText">
                      Only deactivated Participants
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {/* Fishes here */}

              <Box
                sx={{ width: nav2 === 1 ? "100%" : "0px" }}
                className="advanceFiltersBox"></Box>
            </Box>
          </Box>



          {/* All Participants */}
          <Box className=" allPList">
            <Box sx={{ width: "46%" }}>
              <Typography className="pageHeaderText workListNavItemMobile">
                All Participants
              </Typography>
            </Box>

            <Box className="allPNav">
              <Box sx={{ width: "46%" }}>
                <Typography className="pageHeaderText workListNavItemDesktop">
                  All Participants
                </Typography>
              </Box>

              <Box className="allPNavBox">
                <Box className="allPNavInnerBox">
                  <Box minWidth={"77px"} className="allIconBox">
                    <img src={exportIcon} />
                    <Typography ml={1}>Export</Typography>
                  </Box>
                </Box>
                <Box className="allPNavInnerBox">
                  <Box className="allPnavInput">
                    <Box
                      className="inputAppDrop"
                      onClick={() => setParticipantsNavDrop(!ParticipantsNavDrop)}
                    >
                      <Typography>
                        {ParticipantsNavDropVal || "Bulk Action"}
                      </Typography>
                      <img
                        style={{
                          rotate: ParticipantsNavDrop ? "180deg" : "0deg",
                        }}
                        src={dropArrow}
                      />
                      {/* pop */}
                      <Box
                        sx={{ height: ParticipantsNavDrop ? "auto" : "0px" }}
                        className="statusPop"
                      >
                        <Box
                          onClick={() => setParticipantsNavDropVal("Item-A")}
                          className="statusPopItem"
                        >
                          <Typography>Item-A</Typography>
                        </Box>
                        <Box
                          onClick={() => setParticipantsNavDropVal("Item-B")}
                          className="statusPopItem"
                        >
                          <Typography>Item-B</Typography>
                        </Box>
                        <Box
                          onClick={() => setParticipantsNavDropVal("Item-C")}
                          className="statusPopItem"
                        >
                          <Typography>Item-C</Typography>
                        </Box>
                        <Box
                          onClick={() => setParticipantsNavDropVal("Item-D")}
                          className="statusPopItem"
                        >
                          <Typography>Item-D</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <AppBtn buttonText="Apply" />
              </Box>
            </Box>


            <Box mb={2} sx={{ justifyContent: "end", width: "100%" }} className="projectNav1">
              <Box ml={2} className="projectNavIndicat">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <circle cx="5" cy="5" r="5" fill="#12B76A" />
                </svg>
                <Typography ml={1}>Active</Typography>
              </Box>
              <Box ml={2} className="projectNavIndicat">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <circle cx="5" cy="5" r="5" fill="#FB6514" />
                </svg>
                <Typography ml={1}>Inactive</Typography>
              </Box>
            </Box>

            {/* table */}
            <Box className="workListBox">
              <Box className="haderRow" minWidth={"1100px"}>
                <Box
                  minWidth={"19%"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0px",
                    paddingLeft: "11px",
                  }}
                  className="Tabelsel navFsel allpHTI participantCheckboxColor"
                >
                  <input className="AllPTablechackBox" type="checkbox" onChange={handleCheckAllChange} />
                  <Typography>User Name</Typography>
                </Box>
                <Box minWidth={"19%"} className="Tabelsel tableItemSel">
                  <Typography>Email address</Typography>
                </Box>
                <Box minWidth={"16%"} className="Tabelsel tableItemSel">
                  <Typography>Add Updated</Typography>
                </Box>
                <Box minWidth={"16%"} className="Tabelsel tableItemSel">
                  <Typography>Last Visit</Typography>
                </Box>
                <Box minWidth={"13%"} className="Tabelsel tableItemSel">
                  <img src={userF} />
                </Box>
                <Box minWidth={"13%"} className="Tabelsel tableItemSel">
                  <img src={NIcon} />
                </Box>
              </Box>

              {participantsData &&
                participantsData.map((el, index) => (
                  <Box className="TabelRow" minWidth={"1100px"}>
                    <Box
                      sx={{ position: "relative" }}
                      className="Tabelsel navFsel participantCheckboxColor"
                      minWidth={"19%"}
                    >
                      <input className="AllPTablechackBox" type="checkbox" checked={checkboxes[index]} onChange={() => handleCheckboxChange(index)} />
                      <Box className="tabelAvatar">
                        <img src={el.img} />
                      </Box>
                      <Typography sx={{ cursor: "pointer" }}>
                        {el.title}
                        <br />
                        <span className="participantsTableText">
                          {el.subTitle}
                        </span>
                      </Typography>
                      <Box
                        sx={{ background: el.active ? "#12B76A" : "#F04438" }}
                        className="ActiveStatus"
                      ></Box>
                    </Box>
                    <Box
                      minWidth={"19%"}
                      className="Tabelsel TabelselItemCenter"
                    >
                      <Typography className="participantsTableText">
                        {el.email}
                      </Typography>
                    </Box>
                    <Box
                      className="Tabelsel tableItemSel TabelselItemCenter"
                      minWidth={"16%"}
                    >
                      <Typography className="participantsTableText">
                        {el.update}
                      </Typography>
                    </Box>
                    <Box
                      className="Tabelsel tableItemSel TabelselItemCenter"
                      minWidth={"16%"}
                    >
                      <Typography className="participantsTableText">
                        {el.lastVisit}
                      </Typography>
                    </Box>
                    <Box
                      className="Tabelsel tableItemSel TabelselItemCenter"
                      minWidth={"13%"}
                    >
                      <Typography ml={1} className="participantsTableText">
                        {el.userCoutn}
                      </Typography>
                    </Box>
                    <Box
                      className="Tabelsel tableItemSel TabelselItemCenter"
                      minWidth={"13%"}
                    >
                      <Typography ml={1} className="participantsTableText">
                        {el.nCoutn}
                      </Typography>
                    </Box>
                  </Box>
                ))}
            </Box>



            <Box className="allPNavBox allpBDrop">
              <Box className="allPNavInnerBox">
                <Box className="allPnavInput">
                  <Box
                    className="inputAppDrop"
                    onClick={() => setParticipantsNavDrop2(!ParticipantsNavDrop2)}
                  >
                    <Typography>
                      {ParticipantsNavDropVal2 || "Add to New Standard Group"}
                    </Typography>
                    <img
                      style={{
                        rotate: ParticipantsNavDrop2 ? "180deg" : "0deg",
                      }}
                      src={dropArrow}
                    />
                    {/* pop */}
                    <Box
                      sx={{ height: ParticipantsNavDrop2 ? "auto" : "0px" }}
                      className="statusPop"
                    >
                      <Box
                        onClick={() => setParticipantsNavDropVal2("Item-A")}
                        className="statusPopItem"
                      >
                        <Typography>Item-A</Typography>
                      </Box>
                      <Box
                        onClick={() => setParticipantsNavDropVal2("Item-B")}
                        className="statusPopItem"
                      >
                        <Typography>Item-B</Typography>
                      </Box>
                      <Box
                        onClick={() => setParticipantsNavDropVal2("Item-C")}
                        className="statusPopItem"
                      >
                        <Typography>Item-C</Typography>
                      </Box>
                      <Box
                        onClick={() => setParticipantsNavDropVal2("Item-D")}
                        className="statusPopItem"
                      >
                        <Typography>Item-D</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <AppBtn buttonText="Apply" />
            </Box>

            <Box className="pagenation">
              <Box className="tabelBtn">
                <img src={arrow} />
                <Typography>Previous</Typography>
              </Box>

              <Box className="pageNumberBox">
                <Box className="pageNumber pageNumberActive"><p>1</p></Box>
                <Box className="pageNumber"><p>2</p></Box>
                <Box className="pageNumber"><p>3</p></Box>
                <Box className="pageNumber"><p>4</p></Box>
                <Box className="pageNumber"><p>5</p></Box>
              </Box>
              <Box className="tabelBtn">
                <Typography>Next</Typography>
                <img style={{ rotate: "180deg" }} src={arrow} />
              </Box>
            </Box>
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" />
            <AppBtn
              onClick={() => {
                setEmailBroadcastPop(true);
                GoTop();
              }}
              buttonText="Send Email" />
          </Box>
        </Box>


        {/* ---------------------Admins----------------------- */}
        <Box sx={{ display: statusDropVal === "Admins" ? "block" : "none" }}>
          <Typography className='pageHeaderText'>Filter</Typography>

          <PropertyCheck title="Researcher" setState={setResearcher} state={researcher} />
          <PropertyCheck title="Collaborator" setState={setCollaborator} state={collaborator} />
          <PropertyCheck title="Observer" setState={setObserver} state={observer} />

          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" />
            <AppBtn
              onClick={() => {
                setEmailBroadcastPop(true);
                GoTop();
              }}
              buttonText="Send Email" />
          </Box>
        </Box>
      </Box>
    </Box>
  }

  return renderUserLayout(authState?.userType, null, getChildren(), 'workadmin');
}
