import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { AntSwitch } from "../../AppTools/AppTool";
import { useTranslation } from "react-i18next";
//images
import iIcon2 from "../../../Assets/Images/IIcon.png";
import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png";
import backArrow from "../../../Assets/Images/noun-back.png";


export default function AddMixedMediaTaskPop({ setAllDropVal4, addMixedMediaTaskPop, setAddMixedMediaTaskPop, handelAddTaskPopBack }) {

  const email1 = "Type the question/instructions to be visible"

  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();
  const [image, setImage] = useState(null);
  const [checkTaskSettingCount, setCheckTaskSettingCount] = useState(true);
  const [checkTaskSettingPrompt, setCheckTaskSettingPrompt] = useState(true);
  const [checkTaskVideoDescription, setCheckTaskVideoDescription] = useState(true);
  const [checkTotalAttachement, setCheckTotalAttachement] = useState(true);
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
  };


  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
        setAddMixedMediaTaskPop(false);
    }
  };

  const handelCancelPop = () => {
    setAddMixedMediaTaskPop(false);
    setAllDropVal4("Add Tasks");
  }

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (addMixedMediaTaskPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [addMixedMediaTaskPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: addMixedMediaTaskPop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople "
      >
        <Box
          sx={{ display: addMixedMediaTaskPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Box onClick={handelAddTaskPopBack} className="openBtn taskBackBtn">
            <img src={backArrow} />
            <Typography className="neonBackText">{t("commonTxt.backBtn")}</Typography>
            </Box>

          <Typography className="taskPopMainLabel">{t("mixedMediaTaskPop.mixedMediaTaskHead")}</Typography>
          <Box className="iBox">
              <img src={iIcon2} />
              <Typography>
              {t("mixedMediaTaskPop.mixedMediaTaskNotify")}
              </Typography>
            </Box>
          <Box className="basicBoxItem">
            <Typography className='eTemPopLabel '>{t("addTextTaskPop.taskNameLabel")}</Typography>
            <Box className="pickerBox cardTitleLabel">
              <input className='eTemInput' placeholder={t("addTextTaskPop.taskNamePlaceholder")} />
            </Box>
          </Box>

          <Typography className='eTemPopLabel '>{t("LiveInterview.questionLabel")}</Typography>
          <Box className="emailInputBox textTaskInstruction">
            <Box className="emailInputBoxNav">
              <Box
                onClick={() => setMessageTextItalic(!messageTextItalic)}
                className="emailImgBox"
              >
                <img src={IIcon} />
              </Box>
              <Box
                onClick={() => setMessageTextBold(!messageTextBold)}
                className="emailImgBox"
              >
                <img src={BIcon} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("underline", "message")}
                className="emailImgBox"
              >
                <img src={UnderLineU} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("line-through", "message")}
                className="emailImgBox"
              >
                <img src={MLineU} />
              </Box>
              <Box className="emailImgBox">
                <img src={AtachMentIcon} />
              </Box>
              <Box className="emailImgBox">
                <img src={Qbox} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("start")}
                className="emailImgBox"
              >
                <img src={Line1} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("center")}
                className="emailImgBox"
              >
                <img src={Line2} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("end")}
                className="emailImgBox"
              >
                <img src={Line3} />
              </Box>
              <Box className="emailImgBox">
                <img src={Line4} />
              </Box>
              <Box className="emailImgBox">
                <img src={emoji} />
              </Box>
            </Box>
            <textarea
              style={{
                textAlign: messageTextLaOut,
                fontStyle: messageTextItalic ? "italic" : "normal",
                fontWeight: messageTextBold ? "bold" : "normal",
                textDecoration: messageTextUnderLine,
                // textDecoration: messageTextMLine ? "line-through" : null
              }}
              className="emailInput"
              placeholder={t("addTextTaskPop.taskDescriptionPlaceholder")}
              name="emailMessage"
              value={emailval?.emailMessage || email1}
              onChange={handelChange}
            />
          </Box>

          <Box className="basicBoxItem">
            <Box className='mediaInputbox upload-area taskUoloadImageBox' onClick={() => {
                document.getElementById("fileInput").click();
            }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}>
                <img src={uploadIcon} />
                <Typography>{t("commonTxt.uploadText")}</Typography>
                <input
                id="fileInput"
                style={{ display: "none" }}
                type="file"
                onChange={handleFileInputChange}
                />
            </Box>
          </Box>
            
          <Typography className='textTaskSetting '>{t("mixedMediaTaskPop.responseTypeHead")}</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("mixedMediaTaskPop.responseTypeCheckbox1")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("mixedMediaTaskPop.responseTypeCheckbox2")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("mixedMediaTaskPop.responseTypeCheckbox3")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("mixedMediaTaskPop.responseTypeCheckbox4")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("mixedMediaTaskPop.responseTypeCheckbox5")}</Typography>
          </Box>

          <Typography className='textTaskSetting '>{t("addTextTaskPop.taskSettingsHead")}</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" onClick={() => setCheckTaskSettingCount(!checkTaskSettingCount)} defaultChecked />
            <Typography>{t("mixedMediaTaskPop.taskSettingsCheckbox1")}</Typography>
          </Box>
          <Box sx={{ display: checkTaskSettingCount ? "flex" : "none" }} ml={4} className="enforceInputBox">
            <input defaultValue="05" className="phoneInput" />
            <p>{t("addTextTaskPop.to")}</p>
            <input defaultValue="100" className="phoneInput" />
            <p>{t("addTextTaskPop.words")}</p>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("mixedMediaTaskPop.taskSettingsCheckbox2")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("mixedMediaTaskPop.taskSettingsCheckbox3")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" onClick={() => setCheckTaskVideoDescription(!checkTaskVideoDescription)}  defaultChecked />
            <Typography>{t("mixedMediaTaskPop.taskSettingsCheckbox4")}</Typography>
          </Box>
          <Box sx={{ display: checkTaskVideoDescription ? "flex" : "none" }} className="wsCpInputBOx imageTaskDescription">
            <AntSwitch
                inputProps={{ "aria-label": "ant design" }}
            />
            <Typography>
            {t("addImageTaskPop.taskSettingsSwitch")}
            </Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" onClick={() => setCheckTaskSettingPrompt(!checkTaskSettingPrompt)} defaultChecked />
            <Typography>{t("addTextTaskPop.customizePrompt")}</Typography>
          </Box>
          <Box sx={{ display: checkTaskSettingPrompt ? "flex" : "none" }} className="wsCpInputBOx">
            <input
              type="text"
              className="phoneInput customizePromptInput"
              placeholder={t("addTextTaskPop.customizePromptPlaceholder")}
            />
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" onClick={() => setCheckTotalAttachement(!checkTotalAttachement)} defaultChecked />
            <Typography>{t("mixedMediaTaskPop.taskSettingsCheckbox5")}</Typography>
          </Box>
          <Box sx={{ display: checkTotalAttachement ? "flex" : "none" }} ml={4} className="enforceInputBox">
            <input defaultValue="01" className="phoneInput" />
            <p>{t("addTextTaskPop.to")}</p>
            <input defaultValue="05" className="phoneInput" />
            <p>{t("mixedMediaTaskPop.attachments")}</p>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("addTextTaskPop.responseRequired")}</Typography>
          </Box>

          <Typography className='textTaskSetting '>{t("addTextTaskPop.advanceSettingsHead")}</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("addTextTaskPop.advanceSettingsCheckbox1")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("addTextTaskPop.advanceSettingsCheckbox2")} </Typography>
          </Box>
        
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={handelCancelPop} />
            <AppBtnOutLine buttonText={t("commonTxt.savePreviewBtn")} onClick={handelAddTaskPopBack} />
            <AppBtn buttonText={t("commonTxt.saveTaskBtn")} />
          </Box>



        </Box>
      </Box>
    </>
  );
}
