import React from 'react'
import { Box, Typography } from "@mui/material";
import "./style.css"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


//images
import noDataImg from "../../../Assets/Images/noDataRocktImg.png"
import questionMark from "../../../Assets/Images/help-web-button 1.png"
import backArrow from "../../../Assets/Images/noun-back.png";


//component
import SideMenu from "../../../Components/Participants/SideMenu";
import { sideHaumIcon } from "../../../Assets/Data";
import { AppBtn, AppBtnOutLine, PurplelBtn } from '../../../Components/AppTools/AppButton';


export default function OntheDot({ slideMenu, setSlideMenu, workspaceDrop }) {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();



  return (
    <>
      <Box className="ResearchersContainer onTheDotPage">
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon">
              {sideHaumIcon}
            </Box>
            <Box className="ontheDotNav">
              <PurplelBtn buttonText={t("commonTxt.previouspage")} />
              <AppBtn buttonText={t("commonTxt.nextPage")} />
            </Box>

          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer onthedot">
            <Box className="workspaceListBox">
              <Box mb={3} width="74px" onClick={() => Navigate("/participants/activities")} className="openBtn">
                <img src={backArrow} />
                <Typography className="neonBackText">Back</Typography>
              </Box>
              <Typography mb={5} className='pageHeaderText'>{t("onTheDro.header")}</Typography>
              <Typography mb={3}>{t("onTheDro.subHeader")}</Typography>
              <Typography className='otdDSubText'>{t("onTheDro.C_reward0")}<span>{t("onTheDro.C_reward1")}</span></Typography>

              <Box className="bgImgSection">
                <img src={noDataImg} />
                <Box className="btnboxWText">
                  <Typography className='otdBtnsLabel'>{t("onTheDro.startAct")}</Typography>
                  <Box className="workPupBtnBox">
                    <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} />
                    <AppBtn buttonText={t("commonTxt.start")} onClick={() => Navigate("/participants/activities/on-the-dot-start")} />
                  </Box>
                </Box>
              </Box>
              <Box className="bottomActyvityBox">
                <Box className="actGuBtn" onClick={() => window.open("/participants/activities/activity-guidance", "_blank")} >
                  <Typography>{t("onTheDro.ActivityG")}</Typography>
                  <img src={questionMark} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box >
    </>
  )
}
