import React, { useState, useEffect } from "react";
import { Box, Typography, FormControl } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { useTranslation } from "react-i18next";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
//images
import dropArrow from "../../../Assets/Images/chevron-down.png";
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png";
import groupsIcon from "../../../Assets/Images/groupsIcon.png";
import searchIcon from "../../../Assets/Images/search.png";
import dropIcon from "../../../Assets/Images/chevron-downp.png";
import { uploadFile } from "../../../Services/adminActions";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../env";
import { API_BASE_URL, STORAGE_BASE_PATH } from "../../../env";
import axios from "../../../lib/axios";
import { BigDrop } from "../../AppTools/AppTool";
import { useAppSelector } from "../../../lib/store/store";


export default function DuplicatePagePop({ duplicatePagePop, setDuplicatePagePop, setAllDropVal3, projectName, duplicatePageData, setDuplicatePageData, selectedAdminRoles2, setSelectedAdminRoles2, selectedParticipantGroups2, setSelectedParticipantGroups2 }) {

  const [image, setImage] = useState(null);

  const [Drop, setDrop] = useState(false);
  const [DropVal, setDropVal] = useState("Select Participant Groups");

  const [Drop2, setDrop2] = useState(false);
  const [DropVal2, setDropVal2] = useState("All Administrators");

  const [groupDrop2, setGroupDrop2] = useState(false);
  const [searchDrop, setSearchDrop] = useState(false);
  const [redio, setRedio] = useState(0);

  const [adminsMore, setAdminsMore] = useState(false);
  const [selectedAdminRoles, setSelectedAdminRoles] = useState([]);
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const [selectedParticipantGroups, setSelectedParticipantGroups] = useState([]);
  const [groupsListData, setGroupsListData] = useState([]);
  const [adminsList, setAdminsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const authState = useAppSelector((state) => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace);
  // const [duplicatePageApi, setDuplicatePageApi] = useState([]);

  useEffect(() => {
    // setSelectedParticipantGroups(duplicatePageData?.participant_groups);
    // setSelectedAdminRoles(duplicatePageData?.administrator_roles);
    getGroupsLists();
    getResearcher();
    getCollaborator();
    getObserver();
  }, []);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const ParticipantItem = [
    {
      name: "All Participants",
      value: "all",
    },
    {
      name: "Select Participant Groups",
      value: "selected_participants",
    },
    {
      name: "None",
      value: "none",
    },
  ];

  const AdministratorItem = [
    {
      name:"All Administrators",
      value:"all",
    },
    {
      name:"Select Admin Roles",
      value:"selected_roles",
    },
    {
      name:"Select Admins",
      value:"selected_admins",
    },
  ];

  const administratorRoles = [
    { name: "Researchers", value: "researcher" },
    { name: "Collaborators", value: "collaborator" },
    { name: "Observer", value: "observer" }
  ];

  const validationObj = {
    hasErr: false,
    title: {
      error: false,
      msg: "",
    },
    participant_access: {
      error: false,
      msg: "",
    },
    administrator_access: {
      error: false,
      msg: "",
    },
  };

  const [validationErrors, setValidationErrors] = useState({
    ...validationObj,
  });

  const handleValidation = (formInput) => {
    formInput = formInput || duplicatePageData;
    let validationerr = { ...validationObj };

    if (formInput.title == "") {
      validationerr.hasErr = true;
      validationerr.title = {
        error: true,
        msg: "Title is required",
      };
    }

    if (formInput.participant_access == "") {
      validationerr.hasErr = true;
      validationerr.participant_access = {
        error: true,
        msg: "Participant Access is required",
      };
    }

    if (formInput.administrator_access == "") {
      validationerr.hasErr = true;
      validationerr.administrator_access = {
        error: true,
        msg: "Administrator Access is required",
      };
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setDuplicatePagePop(false);
      setAllDropVal3("Page Settings");
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      uploadImage(file);
    }
  };

  const uploadImage = async (file) => {
    if (!file) return false;
    const formData = new FormData();
    formData.append("file", file);
    try {
      let imageUploadRes = await uploadFile(formData, authState.authToken);
      if (imageUploadRes.success) {
        setDuplicatePageData({
          ...duplicatePageData,
          image: imageUploadRes?.data?.upload_path,
        });
        toast.success(imageUploadRes.message, TOAST_CONFIG);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    } finally {
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      uploadImage(file);
    }
  };

  const chackBoxRoom = (groupId,groupName) => {
    console.log("Participant selected groups=>",selectedParticipantGroups2);
    return (
      <Box className="checkBoxItem" key={`group-${groupId}`}>
        <Box >
          <input
            className="custom-checkbox"
            type="checkBox"
            name={`group-${groupId}`}
            value={groupId}
            defaultChecked={selectedParticipantGroups2?.includes(groupId)}
            onChange={handleParticipantGroupsChange}
          />
          <Typography>{groupName}</Typography>
        </Box>
      </Box>
    );
  };

  const AdminRolesCheckBox = (val,text) => {
    console.log("Admin selected Roles=>",selectedAdminRoles2);
    return (
      <Box className="checkBoxItem" key={`adminrole-${val}`} >
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            name={`adminrole-${val}`}
            value={val}
            defaultChecked={selectedAdminRoles2?.includes(val)}
            onChange={handleAdminRolesChange}
          />
          <Typography>{text}</Typography>
        </Box>
      </Box>
    );
  };
  
  const AdminsCheckBox = (val,text) => {
    return (
      <Box className="checkBoxItem" key={`admin-${val}`}>
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            name={`admin-${val}`}
            value={val}
            checked={selectedAdmins.includes(val)}
            onChange={handleAdminsChange}
          />
          <Typography>{text}</Typography>
        </Box>
      </Box>
    );
  };

  const handleAdminRolesChange = (event4) => {
    const { value, checked } = event4.target;
    if (checked) {
      if (selectedAdminRoles2?.includes(value)) {
      } else {
        selectedAdminRoles2.push(value);
      }
    } else {
      let index5 = selectedAdminRoles2.indexOf(value);
      selectedAdminRoles2.splice(index5, 1);
    }
    console.log("Selected Admin Role=>", selectedAdminRoles2);
    setDuplicatePageData({
      ...duplicatePageData,
      administrator_roles: selectedAdminRoles2,
    });
  };

  const handleAdminsChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedAdmins([...selectedAdmins, value]);
    } else {
      setSelectedAdmins(selectedAdmins.filter(admin => admin !== value));
    }
  };

  const handleParticipantGroupsChange = (event3) => {
    const { value, checked } = event3.target;
    if (checked) {
      if(selectedParticipantGroups2?.includes(value)){

      }
      else {
        selectedParticipantGroups2.push(value);
      }
    } else {
      let index5= selectedParticipantGroups2.indexOf(value);
      selectedParticipantGroups2.splice(index5, 1);
    }
    setDuplicatePageData({
      ...duplicatePageData,
      participant_groups: selectedParticipantGroups2,
    });
  };

  const handleAdminAccessChange = (event2) => {
    const selectedValue2 = event2.target.value === "" ? "" : event2.target.value;
    setDuplicatePageData((prevGroup) => ({
      ...prevGroup,
      [event2.target.name]: selectedValue2,
    }));
    if (validationErrors.hasErr) {
      handleValidation(selectedValue2);
    }
  };

  const getResearcher = async () => {
    try {
      const response = await axios.get(`/super-admin/admin/researchers?workspace=${workspaceState?.selectedWorkspace?._id}`)
      setAdminsList((prev) => {
        const prevData = prev || [];
        const newData = response?.data?.data || [];
        const existingIds = new Set(prevData.map(item => item._id));
        const mergedData = [
          ...prevData,
          ...newData.filter(item => !existingIds.has(item._id))
        ];
        return mergedData;
      });
    } catch (err) {
      console.log(err);
    }
  }

  const getCollaborator = async () => {
    try {
      const response = await axios.get(`/super-admin/admin/collaborators?workspace=${workspaceState?.selectedWorkspace?._id}`)
      setAdminsList((prev) => {
        const prevData = prev || [];
        const newData = response?.data?.data || [];
        const existingIds = new Set(prevData.map(item => item._id));
        const mergedData = [
          ...prevData,
          ...newData.filter(item => !existingIds.has(item._id))
        ];
        return mergedData;
      });

    } catch (err) {
      console.log(err);
    }
  }

  const getObserver = async () => {
    try {
      const response = await axios.get(`/super-admin/admin/observers?workspace=${workspaceState?.selectedWorkspace?._id}`)
      setAdminsList((prev) => {
        const prevData = prev || [];
        const newData = response?.data?.data || [];
        const existingIds = new Set(prevData.map(item => item._id));
        const mergedData = [
          ...prevData,
          ...newData.filter(item => !existingIds.has(item._id))
        ];
        return mergedData;
      });
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (duplicatePagePop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [duplicatePagePop]);

  

  const setFormData = (e) => {
    let newState = {
      ...duplicatePageData,
      [e.target.name]: e.target.value,
    };

    setDuplicatePageData(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
    console.log("Duplicate Array=>",duplicatePageData);
  };

  const handleParticipantAccessChange = (event) => {
    const selectedValue = event.target.value === "" ? "" : event.target.value;
    setDuplicatePageData((prevGroup) => ({
      ...prevGroup,
      [event.target.name]: selectedValue,
    }));
    if (validationErrors.hasErr) {
      handleValidation(selectedValue);
    }
  };

  const getGroupsLists = async () => {
    try {
      const res = await axios.get(API_BASE_URL + "/participant/groups");
      if (res.status) {
        const group = res.data?.data?.filter((grp) => grp.is_enhanced == false);
        setGroupsListData(group);
        // console.log("Group List=>",res.data?.data?.filter((grp) => grp.is_enhanced == false))
      }
    } catch (error) {
      console.error("Error fetching project lists", error);
    }
  };

  let duplicateProjectPage = async () => {
    if (!handleValidation()) {
      toast.error("Please correct form fields", TOAST_CONFIG);
      return false;
    }
    setLoading(true);
    try {
      if (duplicatePageData?.image == null || duplicatePageData?.image == "") {
        toast.error("Cover image is required");
        return;
      }
      let response = await axios.post(
        API_BASE_URL + `/researchers/pages`,
        duplicatePageData,
        authState.authToken
      );
      if (response.success) {
        //resetAll();
        toast.success(response.message, TOAST_CONFIG);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      let message = "Something went wrong, please try again!";
      let status_code = 500;
      if (error.response) {
        message = error.response.data.message;
        status_code = error.response.status;
      }
      return {
        success: false,
        message: message,
        status_code: status_code,
      };
    }
  };

  const handelPopBack = () => {
    setDuplicatePagePop(false);
    setAllDropVal3("Page Settings");
  }

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: duplicatePagePop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople "
      >
        <Box
          sx={{ display: duplicatePagePop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className="companyHeader">{t("duplicatePagePop.duplicatePagePopHead")} ({projectName})</Typography>

          <Box className="basicBoxItem">
            <Typography className='psNlabel'>{t("pageSettingPop.pageNameLabel")}</Typography>
            <Box className="pickerBox">
            <input className='eTemInput' name="title" placeholder='Enter page name'
              value={duplicatePageData.title}
              onChange={setFormData}
               />
            <span className="validationerr">
                {validationErrors.title.msg}
              </span>
            </Box>
          </Box>
          
          

          <Box className="basicBoxItem">
            <Typography className='psNlabel'>{t("pageSettingPop.coverImageLabel")}</Typography>
            <Box className='mediaInputbox upload-area' onClick={() => {
                document.getElementById("fileInput").click();
            }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}>
                <img src={duplicatePageData?.image ? STORAGE_BASE_PATH + `/` + duplicatePageData.image : uploadIcon} />
                <Typography>{t("commonTxt.uploadText")}</Typography>
                <input
                id="fileInput"
                style={{ display: "none" }}
                type="file"
                onChange={handleFileInputChange}
                />
            </Box>
          </Box>

            <Box className="basicBoxItem"> 
                <Typography className="psNlabel">{t("addMessageCardPop.participantAccess")}</Typography>
                <FormControl sx={{ width: '100%' }}>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  className="statusBox MuiBox-root css-0"
                  value={duplicatePageData?.participant_access || "Select Participant Access"}
                  onChange={handleParticipantAccessChange}
                  name="participant_access"
                >
                  {ParticipantItem.length > 0 &&
                    ParticipantItem.map((el, i) => (
                      <MenuItem value={el.value} key={i}>
                        {el.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <span className="validationerr">
              {validationErrors.participant_access.msg}
            </span>
              {duplicatePageData?.participant_access==="selected_participants" &&  <Box className="searchGroupSection">
                  <Box
                    pl={4.5}
                    className="inputAppDrop"
                    onClick={() => setGroupDrop2(!groupDrop2)}
                  >
                    <img className="groupsIcon" src={groupsIcon} />
                    <Typography>{t("pageSettingPop.participantGroupPlaceholder")}</Typography>
                    <img
                      style={{ rotate: groupDrop2 || duplicatePageData?.participant_access==="selected_participants" ? "180deg" : "0deg" }}
                      src={dropArrow}
                    />
                  </Box>
                  <Box
                    sx={{ height: groupDrop2 || duplicatePageData?.participant_access==="selected_participants" ? "auto" : "0px" }}
                    className="checkMain_box"
                  >
                    <Box className="ckeckBoxRoom">
                    {groupsListData.length > 0 && 
                    groupsListData.map((group, index) => (
                      chackBoxRoom(group?._id,group?.name)
                    ))}
                    </Box>
                    <Box className="propertyPagination">
                      <Box>
                        <p>1</p>
                      </Box>
                      <Box>
                        <p>2</p>
                      </Box>
                      <Box>
                        <p>3</p>
                      </Box>
                    </Box>
                  </Box>
                </Box>}
            </Box>
            <Box className="basicBoxItem">
                <Typography className="psNlabel adminAccess">{t("addMessageCardPop.administratorAccess")}</Typography>
                <FormControl sx={{ width: '100%' }}>
                  <Select
                    sx={{width:"inherit !important"}}
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    className="statusBox MuiBox-root css-0"
                    value={duplicatePageData?.administrator_access || "Select Administrator Access"}
                    onChange={handleAdminAccessChange}
                    name="administrator_access"
                  >
                    {AdministratorItem.length > 0 &&
                      AdministratorItem.map((el2, i) => (
                        <MenuItem value={el2.value} key={i}>
                          {el2.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <span className="validationerr">
                  {validationErrors.administrator_access.msg}
                </span>
                  
                {duplicatePageData?.administrator_access==="selected_roles" && 
                <Box className="checkMain_box">
                  <Box className="ckeckBoxRoom">
                    {administratorRoles.length > 0 &&
                            administratorRoles.map((el, i) => (
                    AdminRolesCheckBox(el.value,el.name)
                    ))}
                  </Box>
                </Box>}

                {duplicatePageData?.administrator_access === "selected_admins" && (
                  <Box className="searchGroupSection">
                    <FormControl sx={{ width: '100%' }}>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        className="statusBox MuiBox-root css-0"
                        value={duplicatePageData.administrator_ids || "Select Administrator"}
                        onChange={(e) => setDuplicatePageData({ ...duplicatePageData, administrator_ids:  e.target.value })}
                        multiple
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return (
                              <Box
                                pl={4.5}
                                className="inputAppDrop"
                              >
                                <Typography>
                                  Select admins
                                </Typography>
                              </Box>
                            );
                          }
                          return selected.map((value) => {
                              const user = adminsList.find(
                                (usr) => usr._id === value
                              );
                              return user ? user.name : value;
                            })
                            .join(", ");
                        }}
                      >
                        {adminsList.length > 0 &&
                          adminsList.map((el, i) => (
                            <MenuItem value={el._id} key={i} >
                              {el.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                )}
               
            </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={handelPopBack} />
            <AppBtnOutLine buttonText={t("commonTxt.goBackBtn")} onClick={handelPopBack} />
            <AppBtn buttonText={t("workspaceSplash.addPage")} onClick={() => duplicateProjectPage()} />
          </Box>

        </Box>
      </Box>
    </>
  );
}
