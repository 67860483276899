import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material';

//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton"

export default function PendingScreenQuestionPop({ pendingQuestionPop, setPendingQuestionPop, gotoScreeningPage }) {

  const handelPopClose = (e) => {
    if (e.target.id === "popVerifydrop") {
      setPendingQuestionPop(false)
    }
  }
  useEffect(() => {
    if (pendingQuestionPop) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = 'auto'; // Enable scrolling
    }
  }, [pendingQuestionPop])

  return (
    <>
      <Box onClick={handelPopClose} id="popVerifydrop" sx={{ display: pendingQuestionPop ? "flex" : "none" }} className="emailVerifyPopContainer">

        <Box className="emailVerifyDeleteBox">
          <Typography className='deletePopHeader'>Screening Questions</Typography>
          <Typography>Pending screening questions awaiting your completion</Typography>

          <Box className="workPupBtnBox">
            <AppBtnOutLine onClick={() => setPendingQuestionPop(false)} buttonText="Close" />
            <AppBtn buttonText="Complete Now" onClick={() => gotoScreeningPage()} />
          </Box>
        </Box>


      </Box>
    </>
  )
}
