import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Avatar } from "@mui/material";
import "./style.css";

import clogsvg from "../../../Assets/Images/New folder/Icon TeraPage Transparent mix dark 2.svg";
import SClolo from "../../../Assets/Images/TeraPage Transparent mix black.png";
import DashboardIcon from "../../../Assets/Images/dashboard.png";
import NotificationsIcon from "../../../Assets/Images/bell.png";
import DirectMessageIcon from "../../../Assets/Images/AdArrowIcon.png";
import workSpaceIcon from "../../../Assets/Images/workspaceMenuIcon.png";
import HomeIcon from "../../../Assets/Images/home.png";
import ActivitiesIcon from "../../../Assets/Images/map.png";
import ConversationsIcon from "../../../Assets/Images/conversations.png";
import RecapIcon from "../../../Assets/Images/recap.png";
import OutdoorJourneyIcon from "../../../Assets/Images/outdoorjourney.png";
import AvatarOne from "../../../Assets/Images/UserHomeAvatar.png";
import ASIcon from "../../../Assets/Images/icon-setting02.svg";
import HCIcon from "../../../Assets/Images/icon-setting03.svg";
import LIcon from "../../../Assets/Images/icon-setting04.svg";
import ProjectManagementIcon from "../../../Assets/Images/project-management.png";

//Data

//components


export default function SideMenu({
  slideMenu,
  workspaceDrop
}) {
  const currentUrl = new URL(window.location.href).pathname;
  const Navigate = useNavigate();
  const [profilePop, setProfilePop] = useState(false);


  const handleMainOpenTabe = (Url) => {
    Navigate(Url);
  };

  return (
    <>
      <Box
        className={
          slideMenu
            ? "sideMenu researcherSideMenu sideMenuActive"
            : "sideMenu researcherSideMenu"
        }
      >
        <Box onClick={() => Navigate("/")} className="clogBox">
          {slideMenu ? (
            <img style={{ width: "176.442px", height: "20px" }} src={clogsvg} />
          ) : (
            <img style={{ width: "30px" }} src={SClolo} />
          )}
        </Box>
        <Box
          onClick={() => {
            handleMainOpenTabe("/observer/dashboard");
          }}
          className={
            currentUrl === "/observer/dashboard"
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
        >
          <img src={DashboardIcon} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Dashboard
          </Typography>
        </Box>
        <Box
          onClick={() => {
            handleMainOpenTabe("/observer/notification");
          }}
          className={
            currentUrl === "/observer/notification"
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
        >
          <img src={NotificationsIcon} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Notifications
          </Typography>
        </Box>

        <Box
          onClick={() => {
            handleMainOpenTabe("/direct-messages");
          }}
          className={
            currentUrl === "/direct-messages"
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
        >
          <img src={DirectMessageIcon} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Direct Message
          </Typography>
        </Box>

        <Box className="navHrLine"></Box>

        <Box
          onClick={() => {
            handleMainOpenTabe();
          }}
          className="AdSideMenuItem AdSideMenuItemActive"
        >
          <Box className="workspaceIcon">
            <img src={workSpaceIcon} />
          </Box>
          <Typography
            className="workspaceNavText"
            sx={{ display: slideMenu ? "block" : "none" }}
          >
            Workspace
          </Typography>
        </Box>

        {/* sub menu */}
        <Box
          sx={{ display: workspaceDrop ? "flex" : "none" }}
          className="subNavMenu pointer"
        >
          <Box my={3} className="OutdoorJourney">
            <img src={OutdoorJourneyIcon} />
            <select name="" id="">
              <option value="">Outdoor Journey</option>
              <option value="">Indoor Journey</option>
            </select>
          </Box>

          <Box
            onClick={() => {
              handleMainOpenTabe("/observer/home");
            }}
            className={
              currentUrl === "/observer/home"
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
          >
            <img src={HomeIcon} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Home
            </Typography>
          </Box>
          <Box
            onClick={() => {
              handleMainOpenTabe("/observer/activities");
            }}
            className={
              currentUrl === "/observer/activities" ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
          >
            <img src={ActivitiesIcon} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Activities
            </Typography>
          </Box>
          <Box
            onClick={() => handleMainOpenTabe("/observer/conversation")}
            className={
              currentUrl === "/observer/conversation"
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
          >
            <img src={ConversationsIcon} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Conversations
            </Typography>
          </Box>
          <Box
            onClick={() => handleMainOpenTabe("/observer/recap")}
            className={
              currentUrl === "/observer/recap"
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
          >
            <img src={RecapIcon} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Recap
            </Typography>
          </Box>
          <Box
            onClick={() => {
              handleMainOpenTabe("/observer/project-management")
            }
            }
            className={
              currentUrl === "/observer/project-management"
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
          >
            <img src={ProjectManagementIcon} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Project Management
            </Typography>
          </Box>
        </Box>
        {/* sub menu end */}

        <Box
          onClick={() => setProfilePop(!profilePop)}
          className={slideMenu ? "setting " : "setting settingOnClose"}
        >
          <Avatar
            alt="Remy Sharp"
            src={AvatarOne}
            sx={{ width: 34, height: 34 }}
          />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Settings
          </Typography>

          {/* Profile pop */}
          <Box
            sx={{ display: profilePop ? "flex" : "none" }}
            className="sideProfilePop">
            <Box className="profilePopR">
              <Box className="imgPP">
                <img src={AvatarOne} />
              </Box>
              <Box className="profileInfoP">
                <Typography className="ProNP">Avatar Name</Typography>
                <Typography className="ProEP">abc@terapage.com</Typography>
              </Box>
            </Box>
            <Box
              className="sidePPitem"
              onClick={() => Navigate("/account-settings/personal-information")}
            >
              <img src={ASIcon} />
              <Typography>Account Settings</Typography>
            </Box>
            <Box className="sidePPitem">
              <img src={HCIcon} />
              <Typography>Help Center</Typography>
            </Box>
            <Box className="sidePPitem">
              <img src={LIcon} />
              <Typography>Logout</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
