import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";

//components
import { AppBtn, AppBtnOutLine } from "../../../Components/AppTools/AppButton";
import { researcherDeleteAction } from "../../../Services/researcherActions";
import { useAppSelector } from "../../../lib/store/store";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../env";

export default function DeleteAgreementPop({
  deleteAgreementPop,
  setDeleteAgreementPop,
  agreementId,
  agreementName,
  setReloadList,
}) {
  const authState = useAppSelector((state) => state.auth);
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setDeleteAgreementPop(false);
    }
  };

  useEffect(() => {
    if (deleteAgreementPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [deleteAgreementPop]);

  const deleteArgs = async () => {
    try {
      const response = await researcherDeleteAction(
        `/super-admin/admin/agreements/delete/${agreementId}`,
        authState.authToken
      );
      if (response.success) {
        setReloadList(true);
        setDeleteAgreementPop(false);
        toast.success(response.message, TOAST_CONFIG);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  };

  return (
    <>
      <Box
        onClick={handelPopClose}
        id="popBackdrop"
        sx={{ display: deleteAgreementPop ? "flex" : "none" }}
        className="workspaceDeletePopContainer"
      >
        <Box className="workspaceDeleteBox workspaceDeletePopTextAlign">
          <Typography className="deletePopHeader">Delete Group ?</Typography>

          <Box className="deleteTextBox">
            <Typography>
              Are you sure you want to delete “{agreementName}” Agreement from
              Terapage? Kindly note that this action cannot be un-done, any
              Agreement once deleted cannot be retrieved again.
            </Typography>
          </Box>
          <Box className="deleteBtnBox">
            <AppBtnOutLine
              onClick={() => setDeleteAgreementPop(false)}
              buttonText="Cancel"
            />
            <AppBtn buttonText="Delete" onClick={deleteArgs} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
