import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Dialog,
  FormLabel,
  TextField,
  Alert,
  Button,
} from "@mui/material";

import "./style.css";

import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import featuredIcon from "../../../Assets/Images/icon-featured.svg";
import plusIcon from "../../../Assets/Images/plus.svg";
import IIcon2 from "../../../Assets/Images/IIcon.png";

import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { makeProject, uploadFile } from "../../../Services/adminActions";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { useAppSelector } from "../../../lib/store/store";

const AddProjectPop = ({
  setAddProjectPop,
  addProjectPop,
  VisuallyHiddenInput,
  fullScreen,
  setaddProjectNextPop,
  setProjectState,
  projectState,
  image,
  setImage,
}) => {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setAddProjectPop(false);
    }
  };

  const authState = useAppSelector((state) => state.auth);

  const handleClickOpen2 = () => {
    setAddProjectPop(false);
    setaddProjectNextPop(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      uploadImage(file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      uploadImage(file);
    }
  };

  const setFormData = (e) => {
    let newState = {
      ...projectState,
      [e.target.name]: e.target.value,
    };

    setProjectState(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
  };

  const validationObj = {
    hasErr: false,
    name: {
      error: false,
      msg: "",
    },
  };

  const [validationErrors, setValidationErrors] = useState({
    ...validationObj,
  });

  const handleValidation = (formInput) => {
    formInput = formInput || projectState;
    let validationerr = { ...validationObj };

    if (formInput.name == "") {
      validationerr.hasErr = true;
      validationerr.name = {
        error: true,
        msg: "Name is required",
      };
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  };

  const uploadImage = async (file) => {
    if (!file) return false;
    const formData = new FormData();
    formData.append("file", file);
    try {
      let imageUploadRes = await uploadFile(formData, authState.authToken);
      if (imageUploadRes.success) {
        setProjectState({
          ...projectState,
          image: imageUploadRes?.data?.upload_id,
        });
        // toast.success(imageUploadRes.message, TOAST_CONFIG);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    } finally {
    }
  };

  const nextProject = async () => {
    if (!handleValidation()) {
      toast.error("Please correct form fields", TOAST_CONFIG);
      return false;
    }
    handleClickOpen2();
  };

  const resetPopup = () => {
    let reset = {};
    for (const key in projectState) {
      reset[key] = "";
    }
    setProjectState({ ...reset });
    setAddProjectPop(false);
    setImage(null);
  };

  return (
    <Dialog
      className="DialogSection"
      fullScreen={fullScreen}
      maxWidth={1080}
      open={addProjectPop}
      onClose={handelPopClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <img src={featuredIcon} /> {t("workspaceSplash.addProject")}
      </DialogTitle>
      <Box className="AddFormPanel">
        <Box className="FormFiled">
          <FormLabel className="label">
            {t("addProjectPop.addProjectImageLabel")}
          </FormLabel>
          <Box
            className="mediaInputbox upload-area"
            onClick={() => {
              document.getElementById("fileInput").click();
            }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            {image ? (
              <img className="fileinputimg" src={image} alt="Upload" />
            ) : (
              <img src={plusIcon} alt="Upload" />
            )}
            <Typography>{t("createCategoryPopup.clickDragImage")}</Typography>
            <input
              id="fileInput"
              style={{ display: "none" }}
              type="file"
              onChange={handleFileInputChange}
            />
          </Box>
        </Box>
        <Box className="FormFiled">
          <FormLabel className="label">
            {t("Publications.ProjectName")}
          </FormLabel>
          <Typography>{t("addProjectPop.projectNameText")}</Typography>
          <TextField
            fullWidth
            id="outlined-basic"
            placeholder={t("projectAppearance.projectNamePlaceholder")}
            variant="outlined"
            size="small"
            onChange={setFormData}
            value={projectState.name}
            name="name"
          />
          <span className="validationerr">{validationErrors.name.msg}</span>
        </Box>
        <Box className="FormFiled">
          <FormLabel className="label">
            {t("projectAppearance.projectAddressHead")}
          </FormLabel>
          <Typography>{t("addProjectPop.projectAddressText")}</Typography>
          <TextField
            fullWidth
            id="outlined-basic"
            placeholder={t("projectAppearance.projectAddressPlaceholder")}
            variant="outlined"
            size="small"
            onChange={setFormData}
            value={projectState.project_address}
            name="project_address"
          />
        </Box>

        <Box className="timeZoneTextBox infoTimeZoneTextBox">
          <Box className="iIcontD">
            <img src={IIcon2} />
          </Box>
          <Box>
            <Typography>{t("addProjectPop.addProjectNotify")}</Typography>
          </Box>
        </Box>
        <Box className="FormFiled">
          <FormLabel className="label">
            {t("projectAppearance.externalProjectIdHead")}
          </FormLabel>
          <Typography>{t("addProjectPop.externalProjectIdText")}</Typography>
          <TextField
            fullWidth
            id="outlined-basic"
            placeholder={t("projectAppearance.externalProjectIdPlaceholder")}
            variant="outlined"
            size="small"
            onChange={setFormData}
            value={projectState.extrenal_project_id}
            name="extrenal_project_id"
          />
        </Box>
      </Box>
      <DialogActions>
        <Box className="workPupBtnBox addProjectPopBtn">
          <AppBtnOutLine
            autoFocus
            onClick={() => {
              resetPopup();
            }}
            buttonText={t("commonTxt.cancelBtn")}
          />
          <AppBtn
            // onClick={handleClickOpen2}
            onClick={nextProject}
            buttonText={t("admin.next")}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddProjectPop;
