import React, { useState, useEffect } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
//images
import backArrow from "../../../Assets/Images/noun-back.png";
import cott from "../../../Assets/Images/cott.png";
import groupIcon from "../../../Assets/Images/groupsIcon.png";
import exportIcon from "../../../Assets/Images/exportIcon.png";
import dropIcon from "../../../Assets/Images/chevron-down.png";
import arrow from "../../../Assets/Images/arrow.png";
import Option2LIcon from "../../../Assets/Images/Option2LIcon.png";
import editIcon from "../../../Assets/Images/edit 1.png";
//components
import SideMenu from "../../../Components/Researchers/SideMenu";
import SideMenuCollaborator from "../../../Components/Collaborator/SideMenu";
import { Box, Typography } from "@mui/material";
import { LatestCreated, Status } from "../../../Components/AppTools/AppTool"
import { ApexChart } from "../../../Components/AppTools/PieChart";
//data
import { sideHaumIcon, responsesSummeryData, allocationSummeryData, trashBlack } from "../../../Assets/Data";
import { useAppSelector } from "../../../lib/store/store";

export default function RecapAllocation({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
  selectNavItem,
  setSelectNavItem
}) {
  let authState = useAppSelector((state) => state.auth);
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const Navigate = useNavigate();
  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Recently Added");
  const [exportDrop2, setExportDrop2] = useState(false);

  const responseArrya = [
    {
      label: "Recent Responses",
    },
    {
      label: "Oldest Responses",
    },
    {
      label: "Recent Comments",
    },
  ];

  const navItem = [
    "Thread",
    "Expressions",
    "Images",
    "Videos",
    "Audios",
    "Data & Charts",
  ];


  const [exportDrop4, setExportDrop4] = useState(false);
  const [exportDrop5, setExportDrop5] = useState(false);
  const exportItem = ["PNG", "JPEG", "PDF", "SVG"];
  const [chartDrop, setChartDrop] = useState(false);
  const [chartDropVal, setChartDropVal] = useState("Donut Chart");
  const chartItem = [
    {
      name: "Donut Chart",
      value: "Donut Chart",
    },
    {
      name: "Horizontal Bar Graph",
      value: "Horizontal Bar Graph",
    },
    {
      name: "Vertical Bar Graph",
      value: "Vertical Bar Graph",
    },
  ];

  const [countDrop, setCountDrop] = useState(false);
  const [countDropVal, setCountDropVal] = useState(
    "Show count with percentage"
  );
  const countItem = [
    {
      name: "Show only count of tags",
      value: "Show only count of tags",
    },
    {
      name: "Show only count of tags",
      value: "Show only count of tags",
    },
    {
      name: "Show only percentage",
      value: "Show only percentage",
    },
  ];
  const scaleItem = [
    {
      name: "1 Decimal (0.1)",
      value: "1 Decimal (0.1)",
    },
    {
      name: "2 Decimals (0.01)",
      value: "2 Decimals (0.01)",
    },
  ];

  const [scaleDrop, setScaleDrop] = useState(false);
  const [scaleDropVal, setScaleDropVal] = useState("1 Decimal (0.1)");
  const [optionIndex, setOptionIndex] = useState();
  const [workSpaceOption, setWorkspaceOption] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [newArrayData, setNewArrayData] = useState([]);

  const handleOptionIndex = (i) => {
    setOptionIndex(i);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    printItemsByState(responsesSummeryData, currentPage);
  }, [currentPage]);

  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  return (
    <>
      <Box className="ResearchersContainer recapPage">
        {/* side menu */}
        {authState?.userProfile?.is_researcher == 1 ? 
          (<SideMenu
            slideMenu={slideMenu}
            setSlideMenu={setSlideMenu}
            workspaceDrop={workspaceDrop}
            setWorkSpaceDrop={setWorkSpaceDrop}
          />) : (<SideMenuCollaborator
            slideMenu={slideMenu}
            setSlideMenu={setSlideMenu}
            workspaceDrop={workspaceDrop}
            setWorkSpaceDrop={setWorkSpaceDrop}
          />)
        }
        
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>

            <Box className="recapNavItems">
              {navItem?.map((el, i) => (
                <Box
                  key={i}
                  onClick={() => setSelectNavItem(el)}
                  className={
                    selectNavItem === el
                      ? "recapNavItem recapNavItemActive"
                      : "recapNavItem"
                  }
                >
                  <Typography>{el}</Typography>
                </Box>
              ))}
            </Box>
            <Box width={"22%"} minWidth={"187px"} className="agreSearchB2">
              <LatestCreated
                setLatestDrop={setLatestDrop}
                latestCDrop={latestCDrop}
                setLatestDropVal={setLatestDropVal}
                latestDropVal={latestDropVal}
                itemAry={responseArrya}
              />
            </Box>
          </Box>
          {/*----------------------------------- Page container-------------------------------- */}
          <Box className="researchersMainBox pageContainer">
            <Box className="imagesReviewPage workspaceListBox reviewPoll2">
              <Box className="openBtn">
                <Box
                  onClick={() => authState?.userProfile?.is_researcher == 1 ? Navigate("/recap?r=data_charts") : Navigate("/recap?r=data_charts") }
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img src={backArrow} />
                  <Typography className="neonBackText">{t("commonTxt.backBtn")}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "24px",
                  }}
                >
                  <Box className="actTNavBtn">
                    <img src={groupIcon} />
                    <Typography>{t("commonTxt.groupBtn")}</Typography>
                  </Box>

                  <Box className="actTNavBtn">
                    <img src={cott} />
                    <Typography>{t("commonTxt.excerptBtn")}</Typography>
                  </Box>

                  <Box className="actTNavBtn">
                    <img src={exportIcon} />
                    <Typography>{t("commonTxt.exportLink")}</Typography>
                  </Box>
                </Box>
              </Box>

              <Box className="imageReviewMainSection">
                <Box className="imgRNav1Box">
                  <Typography>Outdoor Journey</Typography>
                </Box>
                <Box my={2} className="imgRNav1Box">
                  <Typography className="pageTitle">Brand Spending Scale Allocation Task</Typography>

                </Box>
                <Typography className="imgrevsumText">Lorem ipsum dolor sit amet consectetur. Morbi suspendisse quis ornare in. Amet nunc lectus a aenean lectus. Penatibus tempor urna sed eros suspendisse vulputate vulputate enim vitae. Leo ipsum sed erat sed et. Pellentesque cursus proin ornare eros amet velit. Faucibus in volutpat morbi accumsan et augue et eget eu.</Typography>
                <Typography>Out of the given total sum of amount ($3,000), allocate how much are you willing to spend on the given brands.</Typography>

                <Box className="tabelNav">
                  <Typography className="pageTitle">{t("recapScaleAllocation.allocationChartHead")}</Typography>
                  <Box
                    className="exporOpenBtn"
                    onClick={() => setExportDrop2(!exportDrop2)}
                  >
                    <img src={exportIcon} />
                    <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
                    <img
                      src={dropIcon}
                      style={{ rotate: exportDrop2 ? "180deg" : "0deg" }}
                    />
                    <Box
                      className="statusPop"
                      sx={{ height: exportDrop2 ? "auto" : "0px" }}
                    >
                      {exportItem?.map((el, i) => (
                        <Box className="statusPopItem">
                          <p>{el}</p>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>



                {/* chart */}

                <Box mt={5} className="chartNav">
                  <Box className="chartNavDropBox">
                    <Typography>{t("recapeVideoReview.recapVideoReviewChatLabel")}</Typography>
                    <Status
                      label=" "
                      itemAry={chartItem}
                      setStatusDrop={setChartDrop}
                      statusDrop={chartDrop}
                      setStatusDropVal={setChartDropVal}
                      statusDropVal={chartDropVal}
                    />
                  </Box>

                  <Box className="chartNavDropBox">
                    <Typography>{t("recapeVideoReview.recapVideoReviewValueLabel")}</Typography>
                    <Status
                      label=" "
                      itemAry={countItem}
                      setStatusDrop={setCountDrop}
                      statusDrop={countDrop}
                      setStatusDropVal={setCountDropVal}
                      statusDropVal={countDropVal}
                    />
                  </Box>

                  <Box className="chartNavDropBox">
                    <Typography>{t("recapeVideoReview.recapVideoReviewPrecisionScaleLabel")}</Typography>
                    <Status
                      label=" "
                      itemAry={scaleItem}
                      setStatusDrop={setScaleDrop}
                      statusDrop={scaleDrop}
                      setStatusDropVal={setScaleDropVal}
                      statusDropVal={scaleDropVal}
                    />
                  </Box>
                </Box>

                <Box className="pieBox">
                  <ApexChart />
                </Box>



                <Box className="tabelNav">
                  <Typography className="pageTitle">{t("recapPollMultiPage.summaryData")}</Typography>
                  <Box className="summaryNav">
                    <Box className="chartTableNav">
                      <Box className="chartNavItem">
                        <Typography>{t("commonTxt.hideBtn")}</Typography>
                      </Box>
                      <Box className="chartNavItem">
                        <Typography>{t("commonTxt.groupBtn2")}</Typography>
                      </Box>
                      <Box className="chartNavItem">
                        <Typography>{t("commonTxt.changeColorBtn")}</Typography>
                      </Box>
                    </Box>
                    <Box
                      className="exporOpenBtn"
                      onClick={() => setExportDrop4(!exportDrop4)}
                    >
                      <img src={exportIcon} />
                      <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
                      <img
                        src={dropIcon}
                        style={{ rotate: exportDrop4 ? "180deg" : "0deg" }}
                      />
                      <Box
                        className="statusPop"
                        sx={{ height: exportDrop4 ? "auto" : "0px" }}
                      >
                        {exportItem?.map((el, i) => (
                          <Box className="statusPopItem">
                            <p>{el}</p>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Box>



                <Box mb={6} className="workListBox">
                  <Box className="haderRow" minWidth={"955px"}>
                    <Box minWidth={"20%"} className="Tabelsel TabelStatesel">
                      <Typography>{t("recapeVideoReview.tblAction")}</Typography>
                    </Box>
                    <Box minWidth={"15%"} className="Tabelsel">
                      <Typography>{t("recapeVideoReview.tblColor")}</Typography>
                    </Box>
                    <Box minWidth={"30%"} className="Tabelsel TabelStatesel">
                      <Typography>{t("recapMatrixPage.tblLabels")}</Typography>
                    </Box>

                    <Box minWidth={"13%"} className="Tabelsel">
                      <Typography>{t("recapScaleAllocation.tblAllocation")}</Typography>
                    </Box>
                    <Box minWidth={"13%"} className="Tabelsel">
                      <Typography>{t("recapeVideoReview.tblPercentage")}</Typography>
                    </Box>
                  </Box>

                  {
                    allocationSummeryData?.map((el, i) => (
                      <Box key={i} className="TabelRow" minWidth={"955px"}>
                        <Box
                          minWidth={"20%"}
                          gap={"40px"}
                          className="Tabelsel navFsel navtable"
                        >
                          <img src={Option2LIcon} />
                          <input type="checkBox" className="custom-checkbox" />
                        </Box>
                        <Box minWidth={"15%"} className="Tabelsel Tabelcentersel">
                          <Box className="colorBox" sx={{ background: el.color }}></Box>
                        </Box>
                        <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                          <img src={editIcon} />
                          <Typography>{el.Labels}</Typography>
                        </Box>
                        <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                          <Typography>{el.allocation}</Typography>
                        </Box>
                        <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                          <Typography>{el.Percentage}</Typography>
                        </Box>
                      </Box>
                    ))
                  }

                </Box>




                <Box className="tabelNav">
                  <Typography className="pageTitle">{t("recapPollMultiPage.responseSummaryHead")} (10)</Typography>
                  <Box
                    className="exporOpenBtn"
                    onClick={() => setExportDrop5(!exportDrop5)}
                  >
                    <img src={exportIcon} />
                    <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
                    <img
                      src={dropIcon}
                      style={{ rotate: exportDrop5 ? "180deg" : "0deg" }}
                    />
                    <Box
                      className="statusPop"
                      sx={{ height: exportDrop5 ? "auto" : "0px" }}
                    >
                      {exportItem?.map((el, i) => (
                        <Box className="statusPopItem">
                          <p>{el}</p>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>

                {/* table */}


                <Box className="workListBox">
                  <Box className="haderRow" minWidth={"1100px"}>
                    <Box minWidth={"20%"} className="Tabelsel TabelStatesel">
                      <Typography>{t("recapeVideoReview.tblName")}</Typography>
                    </Box>
                    <Box minWidth={"20%"} className="Tabelsel">
                      <Typography>{t("recapScaleFillItOut.tblItems")}</Typography>
                    </Box>
                    <Box minWidth={"20%"} className="Tabelsel statusSel">
                      <Typography>{t("recapScaleAllocation.tblAllocation")}</Typography>
                    </Box>
                    <Box minWidth={"20%"} className="Tabelsel statusSel">
                      <Typography>{t("recapeVideoReview.tblPercentage")}</Typography>
                    </Box>
                    <Box minWidth={"20%"} className="Tabelsel statusSel TabelStatesel">
                      <Typography>{t("recapeVideoReview.tblLastEdit")}</Typography>
                    </Box>
                  </Box>
                  {newArrayData &&
                    newArrayData?.map((el, index) => (
                      <Box
                        key={index}
                        className="TabelRow"
                        minWidth={"1100px"}
                      >
                        <Box
                          minWidth={"20%"}
                          className="Tabelsel navFsel navtable"
                        >
                          <Box
                            id="dot"
                            onClick={() => {
                              setWorkspaceOption(!workSpaceOption);
                              setOptionIndex(index);
                            }}
                            className={
                              workSpaceOption && index === optionIndex
                                ? "dot dotActive"
                                : "dot"
                            }
                          >
                            {
                              <Box
                                sx={{
                                  display:
                                    workSpaceOption && index === optionIndex
                                      ? "flex"
                                      : "none",
                                }}
                                className="optionPop"
                              >
                                <Box className="optionPopItem">
                                  <Typography>{t("recapPollMultiPage.viewProfile")}</Typography>
                                </Box>
                                <Box className="optionPopItem">
                                  <Typography>
                                  {t("recapPollMultiPage.markAllAsReviewed")}
                                  </Typography>
                                </Box>
                                <Box className="optionPopItem">
                                  <Typography>{t("recapPollMultiPage.sendMessage")}</Typography>
                                </Box>
                                <Box className="optionPopItem">
                                  <Typography>{t("recapPollMultiPage.supportLogin")}</Typography>
                                </Box>
                                <Box className="optionPopItem">
                                  <Typography>{t("recapPollMultiPage.actionLog")}</Typography>
                                </Box>
                                <Box className="optionPopItem">
                                  <Typography>{t("recapPollMultiPage.excerpts")}</Typography>
                                </Box>
                                <Box className="optionPopItem">
                                  <Typography>{t("recapPollMultiPage.reports")}</Typography>
                                </Box>
                                <Box className="optionPopItem">
                                  <Typography>{t("recapPollMultiPage.transcripts")}</Typography>
                                </Box>
                                <Box className="optionPopItem">
                                  {trashBlack}
                                  <Typography>
                                  {t("recapPollMultiPage.deleteAllResponses")}
                                  </Typography>
                                </Box>
                              </Box>
                            }
                          </Box>
                          <Box
                            onClick={() => handleOptionIndex(index)}
                            className="tabelAvatar"
                          >
                            <img src={el.img} />
                          </Box>
                          <Typography
                            sx={{ cursor: "pointer", width: "95%" }}
                          >
                            {el.name}
                          </Typography>
                        </Box>
                        <Box
                          minWidth={"20%"}
                          className="Tabelsel Tabelcentersel" >
                          <Typography>Brand 2</Typography>
                        </Box>

                        <Box
                          minWidth={"20%"}
                          className="Tabelsel Tabelcentersel"
                        >
                          <Typography>$360/month</Typography>
                        </Box>
                        <Box
                          minWidth={"20%"}
                          className="Tabelsel statusSel Tabelcentersel"
                        >
                          <Typography>12%</Typography>
                        </Box>

                        <Box
                          minWidth={"20%"}
                          className="Tabelsel statusSel TabelStatesel"
                        >
                          <Typography>{el.date}</Typography>
                        </Box>
                      </Box>
                    ))}
                </Box>

                <Box className="pagenation">
                  <Box onClick={handlePrev} className="tabelBtn">
                    <img src={arrow} />
                    <Typography>{t("commonTxt.previouspage")}</Typography>
                  </Box>

                  <Box className="pageNumberBox">
                    <Box
                      onClick={() => setCurrentPage(0)}
                      className={
                        currentPage === 0
                          ? "pageNumber pageNumberActive"
                          : "pageNumber"
                      }
                    >
                      <p>1</p>
                    </Box>
                    <Box
                      onClick={() => setCurrentPage(1)}
                      className={
                        currentPage === 1
                          ? "pageNumber pageNumberActive"
                          : "pageNumber"
                      }
                    >
                      <p>2</p>
                    </Box>
                    <Box
                      onClick={() => setCurrentPage(2)}
                      className={
                        currentPage === 2
                          ? "pageNumber pageNumberActive"
                          : "pageNumber"
                      }
                    >
                      <p>3</p>
                    </Box>
                    <Box
                      onClick={() => setCurrentPage(3)}
                      className={
                        currentPage === 3
                          ? "pageNumber pageNumberActive"
                          : "pageNumber"
                      }
                    >
                      <p>4</p>
                    </Box>
                    <Box
                      onClick={() => setCurrentPage(4)}
                      className={
                        currentPage === 4
                          ? "pageNumber pageNumberActive"
                          : "pageNumber"
                      }
                    >
                      <p>5</p>
                    </Box>
                  </Box>

                  <Box onClick={handleNext} className="tabelBtn">
                    <Typography>{t("commonTxt.nextPage")}</Typography>
                    <img style={{ rotate: "180deg" }} src={arrow} />
                  </Box>
                </Box>
              </Box>
            </Box >


          </Box>
        </Box>
      </Box>
    </>
  );
}
