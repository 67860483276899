import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import "./style.css";

//images
import arrow from "../../../Assets/Images/arrow.png";
import avatar from "../../../Assets/Images/Avatar3.png"
import teraIcon from "../../../Assets/Images/teraIcon.png"
import gigaIcon from "../../../Assets/Images/gigaIcon.png"
import megaIcon from "../../../Assets/Images/mega.png"

//component
import { Box, Typography } from '@mui/material'

export default function ProjectDirectoryLeaderboard() {
  const [currentPage, setCurrentPage] = useState()
  const { t } = useTranslation();


  const LeaderboardCard = () => {
    return (
      <Box className="leaderboardCard">
        <Box className="ledbCAbatarBox">
          <Box>
            <img src={avatar} />
          </Box>
          <Typography>Alia Singh</Typography>
        </Box>
        <Box className="ledCardBT">
          <span>{t("PDLeaderboard.cardUserNplaceH")}</span>
          <span>{t("PDLeaderboard.cardDate")}</span>
        </Box>
      </Box>
    )
  }

  const PoingCard = ({ img, title, point, summery }) => {
    return (
      <Box className="ledIconBox">
        <img src={img} />
        <Box className="ledtextB">
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "8px",
            gap: "16px"
          }}>
            <Typography className='ledMText'>{title}</Typography>
            <Box className="ledIconPoingBox">
              <Typography>{point}</Typography>
            </Box>
          </Box>
          <Typography>{summery}</Typography>
        </Box>
      </Box>
    )
  }




  return (
    <>
      <Box className="LeaderboardBox">
        {PoingCard({ img: teraIcon, title: t("commonTxt.tera"), point: "1500 Points", summery: t("projectDirectory.teraSummary") })}
        <Box className="leaderboardCardBox">
          {LeaderboardCard()}
          {LeaderboardCard()}
          {LeaderboardCard()}
          {LeaderboardCard()}
          {LeaderboardCard()}
          {LeaderboardCard()}
        </Box>

        {PoingCard({ img: gigaIcon, title: t("commonTxt.giga"), point: "1000 Points", summery: t("projectDirectory.gigaSummary") })}
        <Box className="leaderboardCardBox">
          {LeaderboardCard()}
          {LeaderboardCard()}
          {LeaderboardCard()}
          {LeaderboardCard()}
          {LeaderboardCard()}
          {LeaderboardCard()}
        </Box>


        {PoingCard({ img: megaIcon, title: t("commonTxt.mega"), point: "500 Points", summery: t("projectDirectory.megaSummary") })}

        <Box className="leaderboardCardBox">
          {LeaderboardCard()}
          {LeaderboardCard()}
          {LeaderboardCard()}
          {LeaderboardCard()}
          {LeaderboardCard()}
          {LeaderboardCard()}
        </Box>


        <Box className="pagenation">
          <Box className="tabelBtn">
            <img src={arrow} />
            <Typography>{t("commonTxt.previouspage")}</Typography>
          </Box>

          <Box className="pageNumberBox">
            <Box onClick={() => setCurrentPage(0)} className={currentPage === 0 ? "pageNumber pageNumberActive" : "pageNumber"}>
              <p>1</p>
            </Box>
            <Box onClick={() => setCurrentPage(1)} className={currentPage === 1 ? "pageNumber pageNumberActive" : "pageNumber"}>
              <p>2</p>
            </Box>
            <Box onClick={() => setCurrentPage(2)} className={currentPage === 2 ? "pageNumber pageNumberActive" : "pageNumber"}>
              <p>3</p>
            </Box>
            <Box onClick={() => setCurrentPage(3)} className={currentPage === 3 ? "pageNumber pageNumberActive" : "pageNumber"}>
              <p>4</p>
            </Box>
            <Box onClick={() => setCurrentPage(4)} className={currentPage === 4 ? "pageNumber pageNumberActive" : "pageNumber"}>
              <p>5</p>
            </Box>
          </Box>

          <Box className="tabelBtn">
            <Typography>{t("commonTxt.nextPage")}</Typography>
            <img style={{ rotate: "180deg" }} src={arrow} />
          </Box>
        </Box>
      </Box>
    </>
  )
}
