import axios from "../lib/axios";
import axiosCore from "axios";
import { setAuthState, setIsSupport, setPrevUser, setUserProfile, setUserType, setWsUserType } from "../lib/store/features/authSlice";
import { API_BASE_URL } from "../env";
import {
  initialSidebars,
  setSidebarDropdowns,
} from "../lib/store/features/sidebarSlice";
import { getUserDashboardUrl, getUserUriSegment } from "../lib/helpers";
import { setSelectedProject, setWorkspace } from "../lib/store/features/workspaceSlice";
import { toast } from "react-toastify";
import { USER_TYPE_COLLABORATOR, USER_TYPE_OBSERVER, USER_TYPE_PARTICIPANT, USER_TYPE_RESEARCHER, USER_TYPE_WS_ADMIN } from "../lib/constants";

export const redirectIfAuthenticated = (
  router,
  dispatch,
  token,
  userProfile = null,
  userType = null
) => {
  if (token != "") {
    if(userType == USER_TYPE_WS_ADMIN && userProfile?.is_participant == 1) {
      userType = USER_TYPE_PARTICIPANT;
      dispatch(setUserType(userType));
    }

    if(userType == USER_TYPE_PARTICIPANT && (userProfile?.is_researcher == 1 || userProfile?.is_collaborator == 1 || userProfile?.is_observer == 1)) {
      userType = USER_TYPE_WS_ADMIN;
      dispatch(setUserType(userType));
    }
    
    let route = getUserDashboardUrl(userType, userProfile);
    if(route == "") {
      toast.error(`Please login as a ${userType}!`);
      logout(router, dispatch, userType);
    } else {
      dispatch(setAuthState(token));
      router(route);
    }
  }
};

export const login = async (loginData) => {
  return await axios.post("/auth/login", loginData);
};

export const userProfile = async (token) => {
  return await axiosCore.get(API_BASE_URL + "/auth/get-profile", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const setUserWorkspace = (userProfile, wsId, userType, dispatch) => {
  if(userType == USER_TYPE_WS_ADMIN) {
    let workspaces = (userProfile?.workspaces?.length > 0) ? userProfile?.workspaces : [];
    workspaces.map((ws) => {
      if(ws._id == wsId) {
        let wsUsers = (ws?.assigned_users?.length > 0) ? ws?.assigned_users : [];
        wsUsers.map((wsu) => {
          if(wsu?.user_id == userProfile?._id) {
            dispatch(setWorkspace(ws));
            if(wsu?.user_role == 'researcher') {
              dispatch(setWsUserType(USER_TYPE_RESEARCHER));
            }
            if(wsu?.user_role == 'collaborator') {
              dispatch(setWsUserType(USER_TYPE_COLLABORATOR));
            }
            if(wsu?.user_role == 'observer') {
              dispatch(setWsUserType(USER_TYPE_OBSERVER));
            }
          }
        })
      }
    })
  }
}

export const updateRedUserProfile = async (token, dispatch, Navigate=null, login = false, userType = null) => {
  let userProfileRes = await userProfile(token);
  if (userProfileRes?.data?.success) {
    dispatch(setUserProfile(userProfileRes?.data?.data));
    if (login) {
      dispatch(setAuthState(token));
      dispatch(setUserType(userType));

      if (userProfileRes?.data?.data?.force_password) {
        return Navigate(`/${getUserUriSegment(userType)}/update-password`);
      }

      if(userType == USER_TYPE_WS_ADMIN) {
        let wsDetails = (userProfileRes?.data?.data?.workspaces?.length > 0) ? userProfileRes?.data?.data?.workspaces[0] : null;
        if(wsDetails != null) {
          setUserWorkspace(userProfileRes?.data?.data, wsDetails?._id, userType, dispatch);
        }
      }
      
      redirectIfAuthenticated(
        Navigate,
        dispatch,
        token,
        userProfileRes?.data?.data,
        userType
      );
    }
  }
}

export const logout = async (router, dispatch, userType = null, authState = null) => {
  await axios.post("/auth/logout", {});
  if(authState != null && authState?.is_support && authState?.prevUser != null) {
    userType = authState?.prevUser?.userType;
    dispatch(setAuthState(authState?.prevUser?.authToken));
    dispatch(setUserProfile(authState?.prevUser?.userProfile));
    dispatch(setIsSupport(false));
    dispatch(setUserType(userType));
    dispatch(setWsUserType(authState?.prevUser?.wsUserType));
    dispatch(setPrevUser(null));
  } else {
    dispatch(setAuthState(""));
    dispatch(setUserProfile(null));
    dispatch(setIsSupport(false));
    dispatch(setUserType(null));
    dispatch(setWsUserType(null));
    dispatch(setWorkspace(null));
    dispatch(setPrevUser(null));
    dispatch(setSelectedProject(null));
  }
  dispatch(setSidebarDropdowns({ ...initialSidebars }));
  let route = "/" + getUserUriSegment(userType) + "/login";
  router(route);
};

export const sendOtp = async (credential) => {
  return await axios.post("/auth/forgot-password", credential);
};

export const verifyOTP = async (credential) => {
  return await axios.post("/auth/verify-otp", credential);
};

export const verify2FAOTP = async (credential) => {
  return await axios.post("/auth/login-step2", credential);
};

export const loginViaOtp = async (credential) => {
  return await axios.post("/auth/login-via-otp", credential);
};

export const resetPassword = async (credential) => {
  return await axios.post("/auth/reset-password", credential);
};

export const updatePassword = async (credential, token) => {
  return await axios.post("/auth/update-password", credential, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const changeEmailRequest = async (payload, token) => {

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  try {
    let data = await axios.post(
      "/auth/change-email-request",
      payload,
      config
    );
    return data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
}

export const updateEmail = async (payload, token) => {

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  try {
    let data = await axios.post(
      "/auth/update-email",
      payload,
      config
    );
    return data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
}

export const sendOtpToEmail = async (payload,token) => {

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  try {
    let data = await axios.post(
      "/auth/send-top",
      payload,
      config
    );
    return data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
}