import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import React, { useState } from "react";
import DirectMessage from "../../../Components/Admin/DirectMessage";
import AdminLayout from "../partials/AdminLayout";
import WhiteDownArrow from "../../../Assets/Images/Whitechevron-down.png";
import { renderUserLayout } from "../../../lib/helpers";
import { useAppSelector } from "../../../lib/store/store";
import { AppBtn } from "../../../Components/AppTools/AppButton";
import { USER_TYPE_COLLABORATOR,  USER_TYPE_RESEARCHER, USER_TYPE_OBSERVER } from "../../../lib/constants";

const DirectMessageAdmin = () => {
  const authState = useAppSelector((state) => state.auth);
  const [directMessageNavBroadCastPop, setDirectMessageNavBroadCastPop] =
    useState(false);
  const [directMessageNavComposePop, setDirectMessageNavComposePop] =
    useState(false);
  const [directMessageNavDrop, setDirectMessageNavDrop] = useState(false);
  const [directMessageNavDropVal, setDirectMessageNavDropVal] =
    useState("Compose");

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const getActionBtns =() => {
    return (
      <>
      {authState?.wsUserType == USER_TYPE_RESEARCHER ? 
        (<Box
          onClick={() => setDirectMessageNavDrop(!directMessageNavDrop)}
          className="directMessageNavBtn"
        >
          <Typography sx={{ color: "#fff" }}>
            {directMessageNavDropVal || "Compose"}
          </Typography>
          <img src={WhiteDownArrow} />
          {/* pop */}
          <Box
            sx={{ display: directMessageNavDrop ? "flex" : "none" }}
            className="workspaceItemNavPop"
          >
            <Box
              onClick={() => {
                setDirectMessageNavDropVal("Broadcast");
                setDirectMessageNavBroadCastPop(true);
              }}
            >
              <Typography>{t("directMessageAdmin.broadCast")}</Typography>
            </Box>
            <Box
              onClick={() => {
                setDirectMessageNavDropVal("Compose");
                setDirectMessageNavComposePop(true);
              }}
            >
              <Typography>{t("directMessageAdmin.compose")}</Typography>
            </Box>
          </Box>
        </Box>) : 
        (<AppBtn
          onClick={() => {
            setDirectMessageNavComposePop(true);
          }}
          buttonText={t("directMessageAdmin.compose")}
        />)
      }
      </>
    )
  }
  
  const getChildren =() => {
    return (
      <Box className="adminMainBox" sx={{ display: "flex" }}>
        <DirectMessage
          directMessageNavBroadCastPop={directMessageNavBroadCastPop}
          setDirectMessageNavBroadCastPop={setDirectMessageNavBroadCastPop}
          directMessageNavComposePop={directMessageNavComposePop}
          setDirectMessageNavComposePop={setDirectMessageNavComposePop}
        />
      </Box>
    )
  }

  return renderUserLayout(authState?.userType, getActionBtns(), getChildren(), 'main');
};

export default DirectMessageAdmin;
