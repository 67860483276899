import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import IIcon from "../../../Assets/Images/IIcon.png";

//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { deleteProject } from "../../../Services/adminActions";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { useAppSelector } from "../../../lib/store/store";

export default function DeleteProjectPop({
  deleteProjectPop,
  setDeleteProjectPop,
  setReloadList,
  setProjectName,
  projectName,
  setProjectID,
  projectID
}) {
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setDeleteProjectPop(false);
    }
  };


  const authState = useAppSelector(state => state.auth);
  useEffect(() => {
    if (deleteProjectPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [deleteProjectPop]);

  const deletePro = async () => {
    try {
      const response = await deleteProject(projectID, authState.authToken);
      if (response.success) {
        toast.success(response.message, TOAST_CONFIG);
        if (typeof (setReloadList) != 'undefined') {
          setReloadList(true);
        }
        setProjectID("")
        setProjectName("")
        setDeleteProjectPop(false)
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  }

  return (
    <>
      <Box
        onClick={handelPopClose}
        id="popBackdrop"
        sx={{ display: deleteProjectPop ? "flex" : "none" }}
        className="workspaceDeletePopContainer"
      >
        <Box className="workspaceDeleteBox workspaceDeletePopTextAlign">
          <Typography className="deletePopHeader">
            Delete {projectName} Project ?
          </Typography>


          <Box className="deleteTextBox">
            <Typography>
              Are you sure you want to delete “{projectName}” Project from
              Terapage? Kindly note that this action cannot be un-done, any
              Project once deleted cannot be retrieved again.
            </Typography>
          </Box>
          <Box className="deleteBtnBox">
            <AppBtnOutLine
              onClick={() => setDeleteProjectPop(false)}
              buttonText="Cancel"
            />
            <AppBtn buttonText="Delete" onClick={() => deletePro()} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
