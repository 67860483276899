import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";

import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";

//components
import { AppBtnOutLine, AppBtn } from "../../AppTools/AppButton";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { inviteSuperadminUser } from "../../../Services/adminActions";
import { useAppSelector } from "../../../lib/store/store";

export default function InviteSupAdminPop({
  setInviteSupAdminPop,
  inviteSupAdminPop,
}) {
  const email1 =`
  We are thrilled to invite you to join our team as a Superadmin! Your exceptional skills and dedication make you a perfect fit for this crucial role, and we are eager to have you onboard.

  To accept this invitation and unleash your superpowers, simply click on the link below:
  
  {{{invite_link}}}
  
  We look forward to having you as an integral part of our team and witnessing the positive impact your contributions will bring.
  
  Thank you for your commitment and enthusiasm.
  
  Best regards,
  TeraPage
  `;
  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();

  const [signatureTextLaOut, setSignatureTextLaOut] = useState("start");
  const [signatureTextItalic, setSignatureTextItalic] = useState(false);
  const [signatureTextBold, setSignatureTextBold] = useState(false);
  const [signatureTextUnderLine, setSignatureTextUnderLine] = useState();
  const [settingTab, setSettingTab] = useState(0);
  let authState = useAppSelector(state => state.auth);

  const initInvite = {
    name: "",
    email: "",
    subject: "Invitation to Become a Superadmin"
  }

  const [createInvite, setCreateInvite] = useState({ ...initInvite });
  const [loading, setLoading] = useState(false);
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setInviteSupAdminPop(false);
    }
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validationObj = {
    hasErr: false,
    'name': {
      error: false,
      msg: ''
    },
    'email': {
      error: false,
      msg: ''
    },
    'subject': {
      error: false,
      msg: ''
    }
  }

  const [validationErrors, setValidationErrors] = useState({ ...validationObj });

  const handleValidation = (formInput) => {
    formInput = formInput || createInvite;
    let validationerr = { ...validationObj };

    if (formInput.name == "") {
      validationerr.hasErr = true;
      validationerr.name = {
        error: true,
        msg: 'Name is required'
      }
    }

    if (formInput.email == "") {
      validationerr.hasErr = true;
      validationerr.email = {
        error: true,
        msg: 'Email is required'
      }
    }

    if (formInput.subject == "") {
      validationerr.hasErr = true;
      validationerr.subject = {
        error: true,
        msg: 'Subject is required'
      }
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  }

  const setFormData = (e) => {
    let newState = {
      ...createInvite,
      [e.target.name]: e.target.value,
    };

    setCreateInvite(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
  };

  const sendInviteRequest = async() =>{
    if (!handleValidation()) {
      toast.error('Please correct form fields', TOAST_CONFIG);
      return false;
    }
    try {
      const payload = {
        name:createInvite.name,
        email:createInvite.email,
        subject:createInvite.subject,
        message: emailval?.emailMessage || email1
      };
      const response = await inviteSuperadminUser(payload, authState.authToken);
      setLoading(false);
      if (response.success) {
        resetPopup();
        toast.success(response.message, TOAST_CONFIG);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
    
  }

  const resetPopup = () => {
    setCreateInvite({...initInvite})
    setInviteSupAdminPop(false)
  }

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine === "underline") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine === "line-through") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("line-through");
        }
      }
    }
  };

  useEffect(() => {
    if (inviteSupAdminPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [inviteSupAdminPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: inviteSupAdminPop ? "flex" : "none" }}
        className="workspacePop"
      >
        <Box
          sx={{ display: inviteSupAdminPop ? "flex" : "none" }}
          className="workspaceContaint emailTemPop"
        >
          <Typography className="workspaceHeader">Invite Admin</Typography>

          <Box className="basicBox">
            <Box className="inviteInputItems">
              <Typography className="inviteAdminLable">Name</Typography>
              <Box className="pickerBox ">
                <input className="eTemInput" 
                  placeholder="Full Name" 
                  name="name"
                  value={createInvite.name} 
                  onChange={setFormData}
                />
                <span className="validationerr inputerr">{validationErrors.name.msg}</span>
              </Box>
            </Box>

            <Box className="inviteInputItems">
              <Typography className="inviteAdminLable">
                Email Address
              </Typography>
              <Box className="pickerBox ">
                <input
                  className="eTemInput"
                  placeholder="Enter Your Email Address"
                  name="email"
                  value={createInvite.email}
                  onChange={setFormData}
                />
                <span className="validationerr inputerr">{validationErrors.email.msg}</span>
              </Box>
            </Box>
          </Box>

          <Box className="basicBox">
            <Box className="inviteInputItems">
              <Typography className="inviteAdminLable">Subject</Typography>
              <Box className="pickerBox ">
                <input className="eTemInput" placeholder="Welcome Messgae" name="subject" value={createInvite.subject} onChange={setFormData} />
                <span className="validationerr inputerr">{validationErrors.subject.msg}</span>
              </Box>
            </Box>
          </Box>

          <Typography className="eTemPopLabel">
            Message<samp>*</samp>
          </Typography>
          <Box className="emailInputBox">
            <Box className="emailInputBoxNav">
              <Box
                onClick={() => setMessageTextItalic(!messageTextItalic)}
                className="emailImgBox"
              >
                <img src={IIcon} />
              </Box>
              <Box
                onClick={() => setMessageTextBold(!messageTextBold)}
                className="emailImgBox"
              >
                <img src={BIcon} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("underline", "message")}
                className="emailImgBox"
              >
                <img src={UnderLineU} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("line-through", "message")}
                className="emailImgBox"
              >
                <img src={MLineU} />
              </Box>
              <Box className="emailImgBox">
                <img src={AtachMentIcon} />
              </Box>
              <Box className="emailImgBox">
                <img src={Qbox} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("start")}
                className="emailImgBox"
              >
                <img src={Line1} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("center")}
                className="emailImgBox"
              >
                <img src={Line2} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("end")}
                className="emailImgBox"
              >
                <img src={Line3} />
              </Box>
              <Box className="emailImgBox">
                <img src={Line4} />
              </Box>
              <Box className="emailImgBox">
                <img src={emoji} />
              </Box>
            </Box>
            <textarea
              style={{
                textAlign: messageTextLaOut,
                fontStyle: messageTextItalic ? "italic" : "normal",
                fontWeight: messageTextBold ? "bold" : "normal",
                textDecoration: messageTextUnderLine,
                // textDecoration: messageTextMLine ? "line-through" : null
              }}
              className="emailInput"
              name="emailMessage"
              value={emailval?.emailMessage || email1}
              onChange={handelChange}
            />
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine
              buttonText="Cancel"
              onClick={() => setInviteSupAdminPop(false)}
            />
            <AppBtn buttonText="Create" onClick={sendInviteRequest}/>
          </Box>
        </Box>
      </Box>
    </>
  );
}
