import React, { useState, useEffect } from 'react';
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { useTranslation } from "react-i18next";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

//images
import iIcon from "../../../Assets/Images/IIcon.png";
import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png"
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png"
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png"
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png"
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png"
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png"
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png"
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png"
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png"
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png"
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png"
import avatar2 from "../../../Assets/Images/inb2.png"
import idelDot from "../../../Assets/Images/idelDot.png"
import backArrow from "../../../Assets/Images/noun-back.png";


//components
import { Box, Typography } from "@mui/material"
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSearchParams } from "react-router-dom";



import SideMenu from "../../../Components/Participants/SideMenu";
import { sideHaumIcon } from "../../../Assets/Data";

export default function ActivityJourrnal({ slideMenu, workspaceDrop, setSlideMenu }) {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [nav, setNav] = useState(0);
  const [nav2, setNav2] = useState(0);
  const [signatureTextItalic4, setSignatureTextItalic4] = useState(false)
  const [signatureTextBold4, setSignatureTextBold4] = useState(false)
  const [signatureTextUnderLine4, setSignatureTextUnderLine4] = useState()
  const email2 = "Provide Instructions here"
  const [emailval, setEmailval] = useState()
  const [emailval2, setEmailval2] = useState()
  const [emailval3, setEmailval3] = useState()
  const [emailval4, setEmailval4] = useState()
  const [signatureTextLaOut, setSignatureTextLaOut] = useState("start")

  const overViewData = [
    {
      data: "Aug 16, 2023",
      summery: "A chilly breeze greeted the morning, a reminder that autumn had firmly settled in. Today unfolded with a productive energy, the to-do list shrinking with each completed task. A midday pause led to a spontaneous exploration of a nearby park, where the crunch of fallen leaves beneath my shoes became a soothing soundtrack. The day ended with a cozy evening spent curled up with a good book, finding solace in the written words as the world outside dimmed into night."
    },
    {
      data: "Aug 15, 2023",
      summery: "The sun played hide-and-seek with the clouds today, casting shadows that danced across the landscape. Work presented its challenges, but a spontaneous brainstorming session with colleagues brought about creative solutions. Evening brought a surprise visit from a friend, turning an ordinary Tuesday into a laughter-filled memory. The day served as a reminder of the beauty in embracing both the expected and the unforeseen."
    },
    {
      data: "Aug 14, 2023",
      summery: "Thanksgiving arrived with a sense of gratitude in the air. The day was filled with the warmth of family gatherings and the comforting aroma of a traditional feast. Amidst the clinking of utensils and heartfelt conversations, reflections on the blessings of the year surfaced. As the day drew to a close, the flickering glow of candles echoed the quiet appreciation for the moments that knit together the fabric of a thankful heart."
    },
  ]

  const handelTextlineChekc4 = (p, input) => {
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine4 === "underline") {
          setSignatureTextUnderLine4(null)
          return
        } else {
          setSignatureTextUnderLine4("underline")
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine4 === "line-through") {
          setSignatureTextUnderLine4(null)
          return
        } else {
          setSignatureTextUnderLine4("line-through")
        }
      }
    }
  }

  const handelChange = (e, i) => {
    const { name, value } = e.target;
    if (i === 1) {
      setEmailval((prev) => ({
        ...prev,
        [name]: value
      }));
    } else if (i === 2) {
      setEmailval2((prev) => ({
        ...prev,
        [name]: value
      }));
    } else if (i === 3) {
      setEmailval3((prev) => ({
        ...prev,
        [name]: value
      }));
    } else if (i === 4) {
      setEmailval4((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  };




  const lineBar = 80;
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#4F33D1",
    },
  }));



  useEffect(() => {
    let selTab = searchParams.get("dr");
    if (selTab != null) {
      setNav(parseInt(selTab));
    }
    else {
      setNav(0);
    }
  }, []);


  return (
    <>
      <Box className="ResearchersContainer ParticipantsActiveInnerPage">
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon">
              {sideHaumIcon}
            </Box>
          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer">
            <Box className="workspaceListBox">
              <Box mb={3} width="74px" onClick={() => Navigate("/participants/activities")} className="openBtn">
                <img src={backArrow} />
                <Typography className="neonBackText">Back</Typography>
              </Box>
              <Typography className='pageHeaderText'>{t("ActivitiesInnerPage.pageHeader")}</Typography>

              <Box className="paiNav">
                <Box className={nav === 0 ? "paiNavItem paiNavItemActive" : "paiNavItem"} onClick={() => {
                  setNav(0)
                  setSearchParams({ dr: 0 })
                }}>
                  <Typography>{t("ActivitiesInnerPage.A_navOne")}</Typography>
                </Box>

                <Box className={nav === 1 ? "paiNavItem paiNavItemActive" : "paiNavItem"} onClick={() => {
                  setNav(1)
                  setSearchParams({ dr: 1 })
                }}>
                  <Typography>{t("ActivitiesInnerPage.A_navTwo")}</Typography>
                </Box>
              </Box>

              <Box py={4} className="responsesSection" sx={{ display: nav === 0 ? "block" : "none" }}>
                <Typography mb={2}>{t("ActivitiesInnerPage.f_summeryOne")}</Typography>
                <Typography mb={2}>{t("ActivitiesInnerPage.f_summeryTwo")}</Typography>
                <Typography mb={2}>{t("ActivitiesInnerPage.f_summeryThree")}</Typography>


                <Box mt={5} className="iBox">
                  <img src={iIcon} />
                  <Typography>{t("ActivitiesInnerPage.iBoxTextOne")}</Typography>
                </Box>


                <Box className="lineBar">
                  <Typography>{lineBar}%</Typography>
                  <BorderLinearProgress variant="determinate" value={lineBar} />
                </Box>
                <Typography className="lineBarSubText">8 {t("ActivitiesInnerPage.of")} 10 {t("ActivitiesInnerPage.responsesRecieved")}</Typography>

                <Box mt={5} className="SecondNavSection">
                  <Box onClick={() => setNav2(0)} className={nav2 === 0 ? "sNsItem sNsItemActive" : "sNsItem"}>
                    <Typography>{t("ActivitiesInnerPage.B_navOne")}</Typography>
                  </Box>

                  <Box onClick={() => setNav2(1)} className={nav2 === 1 ? "sNsItem sNsItemActive" : "sNsItem"}>
                    <Typography>{t("ActivitiesInnerPage.B_navTwo")}</Typography>
                  </Box>
                </Box>

                <Box className="sNsSection" sx={{ display: nav2 === 0 ? "block" : "none" }}>

                  <Box mt={5} className="ajDateInputBox">
                    <Typography>{t("ActivitiesInnerPage.dateInputLabel")}</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker label="15/08/2023" />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>


                  <Box mt={5}>
                    <Box className="emailInputBox">
                      <Box className="emailInputBoxNav">
                        <Box onClick={() => setSignatureTextItalic4(!signatureTextItalic4)} className="emailImgBox">
                          <img src={IIcon} />
                        </Box>
                        <Box onClick={() => setSignatureTextBold4(!signatureTextBold4)} className="emailImgBox">
                          <img src={BIcon} />
                        </Box>
                        <Box onClick={() => handelTextlineChekc4("underline", "signature")} className="emailImgBox">
                          <img src={UnderLineU} />
                        </Box>
                        <Box onClick={() => handelTextlineChekc4("line-through", "signature")} className="emailImgBox">
                          <img src={MLineU} />
                        </Box>
                        <Box className="emailImgBox">
                          <img src={AtachMentIcon} />
                        </Box>
                        <Box className="emailImgBox">
                          <img src={Qbox} />
                        </Box>
                        <Box onClick={() => setSignatureTextLaOut("start")} className="emailImgBox">
                          <img src={Line1} />
                        </Box>
                        <Box onClick={() => setSignatureTextLaOut("center")} className="emailImgBox">
                          <img src={Line2} />
                        </Box>
                        <Box onClick={() => setSignatureTextLaOut("end")} className="emailImgBox">
                          <img src={Line3} />
                        </Box>
                        <Box className="emailImgBox">
                          <img src={Line4} />
                        </Box>
                        <Box className="emailImgBox">
                          <img src={emoji} />
                        </Box>

                      </Box>
                      <textarea
                        style={{
                          textAlign: signatureTextLaOut,
                          fontStyle: signatureTextItalic4 ? "italic" : "normal",
                          fontWeight: signatureTextBold4 ? "bold" : "normal",
                          textDecoration: signatureTextUnderLine4
                        }}
                        className='emailInput' name='emailSignature'
                        value={emailval4?.emailSignature || email2}
                        onChange={(e) => handelChange(e, 4)}
                      />
                    </Box>
                  </Box>
                </Box>

                <Box className="sNsSection overviewSection" sx={{ display: nav2 === 1 ? "block" : "none" }}>

                  {overViewData?.map((el, i) => (
                    <Box key={i} className="overSectionItem">
                      <span>{el.data}</span>
                      <Typography>{el.summery}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>

              <Box className="responsesSection" sx={{ display: nav === 1 ? "block" : "none" }}>

                <Box mb={4} className="commentSection">
                  <Box className="avatarBox">
                    <img src={avatar2} />
                    <img className='statusDot' src={idelDot} />
                  </Box>
                  <Box className="commentTextSection">
                    <Box mb={5} className="taskBoxTopDate">
                      <Typography className='userNameWspm'>
                        Kate Morrison
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "15px",
                        }}
                      >
                        <samp className="taskHeaderText3">
                          Aug 04, 2023 02:21 PM
                        </samp>
                      </Box>
                    </Box>
                    <Typography>A frosty morning greeted the day, transforming the world into a glistening wonderland. The holiday spirit infused the air, and the anticipation of the upcoming festivities added a subtle magic to the routine. Work, though demanding, was punctuated by the exchange of cheerful greetings and shared smiles, a collective acknowledgment of the joyous season. The evening unfolded with the first snowfall of the year, turning the world outside into a serene, white canvas. Stepping outside, the crisp air carried the promise of a winter filled with enchanting moments.</Typography>
                  </Box>
                </Box>



                <Box mb={4} className="commentSection">
                  <Box className="avatarBox">
                    <img src={avatar2} />
                    <img className='statusDot' src={idelDot} />
                  </Box>
                  <Box className="commentTextSection">
                    <Box mb={5} className="taskBoxTopDate">
                      <Typography className='userNameWspm'>
                        Kate Morrison
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "15px",
                        }}
                      >
                        <samp className="taskHeaderText3">
                          Aug 04, 2023 02:21 PM
                        </samp>
                      </Box>
                    </Box>
                    <Typography>{t("ActivitiesInnerPage.allResponceText1")}</Typography>
                  </Box>
                </Box>






                <Box mb={4} className="commentSection">
                  <Box className="avatarBox">
                    <img src={avatar2} />
                    <img className='statusDot' src={idelDot} />
                  </Box>
                  <Box className="commentTextSection">
                    <Box mb={5} className="taskBoxTopDate">
                      <Typography className='userNameWspm'>
                        Kate Morrison
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "15px",
                        }}
                      >
                        <samp className="taskHeaderText3">
                          Aug 04, 2023 02:21 PM
                        </samp>
                      </Box>
                    </Box>
                    <Typography>{t("ActivitiesInnerPage.allResponceText2")}</Typography>
                  </Box>
                </Box>






                <Box mb={4} className="commentSection">
                  <Box className="avatarBox">
                    <img src={avatar2} />
                    <img className='statusDot' src={idelDot} />
                  </Box>
                  <Box className="commentTextSection">
                    <Box mb={5} className="taskBoxTopDate">
                      <Typography className='userNameWspm'>
                        Kate Morrison
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "15px",
                        }}
                      >
                        <samp className="taskHeaderText3">
                          Aug 04, 2023 02:21 PM
                        </samp>
                      </Box>
                    </Box>
                    <Typography>{t("ActivitiesInnerPage.allResponceText3")}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
