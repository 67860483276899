import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../lib/store/store";
import "./style.css";

//images
import conCardImg1 from "../../../Assets/Images/quickTips.png";
import conCardImg2 from "../../../Assets/Images/quicbg.png";
import moreIcon from "../../../Assets/Images/more-vertical.png";
import arrow from "../../../Assets/Images/arrow.png";
import trash from "../../../Assets/Images/redTrash.png";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import ActiveDrop from "../../../Assets/Images/ActiveDot.png";
import chackBox from "../../../Assets/Images/Checkbox.png";
import check from "../../../Assets/Images/check.png";
import { USER_TYPE_COLLABORATOR,  USER_TYPE_RESEARCHER, USER_TYPE_OBSERVER } from "../../../lib/constants";
//component
import SideMenu from "../../../Components/Researchers/SideMenu";
import { topicsData, sideHaumIcon, arrowIcon } from "../../../Assets/Data";
import {
  SearchBar,
  LatestCreated,
} from "../../../Components/AppTools/AppTool";
import { AppAddBtn, AppBtn } from "../../../Components/AppTools/AppButton";
import AddConversationTopicPop from "../../../Components/Researchers/AddConversationTopicPop";
import DeleteConversationCategoryPop from "../../../Components/Researchers/DeleteConversationCategoryPop";
import CreateConversationCategoryPop from "../../../Components/Researchers/CreateConversationCategoryPop";

export default function Conversations({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
}) {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const Navigate = useNavigate();
  let authState = useAppSelector((state) => state.auth);
  const [currentPage, setCurrentPage] = useState(0);
  const [newArrayData, setNewArrayData] = useState([]);
  const [conOpDrop, setConOpDrop] = useState(false);
  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Recently Added");
  const [card1Option, setCard1Option] = useState(false);
  const [card2Option, setCard2Option] = useState(false);
  const [chackVal, setChackVal] = useState([]);
  const [addConversationTopicPop, setAddConversationTopicPop] = useState(false);
  const [deleteConversationCategoryPop, setDeleteConversationCategoryPop] =
    useState(false);
  const [createConversationCategoryPop, setCreateConversationCategoryPop] =
    useState(false);

  const cardDropItems = [
    "Date started",
    "Manually sort topics",
    "View all topics",
    "View unread posts",
    "View latest posts",
    "View all posts",
  ];

  const selectAllCheckData = () => {
    setChackVal(["Richard Han", "Lucy Hutten", "Lorem Ipsum", "Dolor Amet"]);
  };

  const deleteAllCheckData = () => setChackVal([]);

  const pushData = (string) => {
    setChackVal((prev) => [...prev, string]);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleNavDrop = (e) => {
    if (e.target.id === "conNavDrop") {
      setConOpDrop(!conOpDrop);
    }
  };

  useEffect(() => {
    printItemsByState(topicsData, currentPage);
  }, [currentPage]);

  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  return (
    <>
      <Box className="ResearchersContainer ">
        {/* side menu */}
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
            {authState?.wsUserType == USER_TYPE_RESEARCHER || authState?.wsUserType == USER_TYPE_COLLABORATOR ? 
            (<Box sx={{ display: "flex", gap: "25px" }}>
              <Box
                id="conNavDrop"
                sx={{ minWidth: "230px" }}
                onClick={handleNavDrop}
              >
                <Box
                  id="conNavDrop"
                  className="conNavDrop"
                  onClick={handleNavDrop}
                >
                  <Typography id="conNavDrop" onClick={handleNavDrop}>
                  {t("Conversations.ConversationOptions")}
                  </Typography>
                  <img
                    onClick={handleNavDrop}
                    id="conNavDrop"
                    className="dropIcon"
                    src={DownArrowIcon}
                    style={{ rotate: conOpDrop ? "180deg" : "0deg" }}
                  />

                  {/* drop */}
                  <Box
                    sx={{ display: conOpDrop ? "flex" : "none" }}
                    className="activeCardOptionPop conVanDropMenu"
                  >
                    <Box className="acItem" onClick={() => setConOpDrop(null)}>
                      <Typography className="acdl">
                      {t("Conversations.ConversationSettings")}
                      </Typography>
                    </Box>
                    <Box
                      className="acItem"
                      onClick={() => {
                        setConOpDrop(null);
                        setCreateConversationCategoryPop(true);
                      }}
                    >
                      <Typography className="acdl">{t("Conversations.CreateCategory")}</Typography>
                    </Box>
                    <Box className="acItem transcript">
                      <Typography className="acdl">{t("Conversations.SortCategories")}</Typography>
                      {arrowIcon}
                      <Box className="acSubItem">
                        <Box className="researchDropTopLabel">
                          <Typography onClick={selectAllCheckData}>
                          {t("Conversations.SelectAll")}
                          </Typography>
                          <Typography onClick={deleteAllCheckData}>
                          {t("Conversations.DeselectAll")}
                          </Typography>
                        </Box>

                        <Box
                          onClick={() => {
                            if (chackVal.includes("Richard Han")) {
                              setChackVal(
                                chackVal.filter(
                                  (item) => item !== "Richard Han"
                                )
                              );
                            } else {
                              pushData("Richard Han");
                            }
                          }}
                          className="categoryDropItem"
                        >
                          <Box className="chackBox">
                            <img className="chackBoxImg" src={chackBox} />
                            <img
                              style={{
                                display: chackVal.includes("Richard Han")
                                  ? "block"
                                  : "none",
                              }}
                              className="chack"
                              src={check}
                            />
                          </Box>
                          <Typography>Richard Han</Typography>
                        </Box>

                        <Box
                          onClick={() => {
                            if (chackVal.includes("Lucy Hutten")) {
                              setChackVal(
                                chackVal.filter(
                                  (item) => item !== "Lucy Hutten"
                                )
                              );
                            } else {
                              pushData("Lucy Hutten");
                            }
                          }}
                          className="categoryDropItem"
                        >
                          <Box className="chackBox">
                            <img className="chackBoxImg" src={chackBox} />
                            <img
                              style={{
                                display: chackVal.includes("Lucy Hutten")
                                  ? "block"
                                  : "none",
                              }}
                              className="chack"
                              src={check}
                            />
                          </Box>
                          <Typography>Lucy Hutten</Typography>
                        </Box>

                        <Box
                          onClick={() => {
                            if (chackVal.includes("Lorem Ipsum")) {
                              setChackVal(
                                chackVal.filter(
                                  (item) => item !== "Lorem Ipsum"
                                )
                              );
                            } else {
                              pushData("Lorem Ipsum");
                            }
                          }}
                          className="categoryDropItem"
                        >
                          <Box className="chackBox">
                            <img className="chackBoxImg" src={chackBox} />
                            <img
                              style={{
                                display: chackVal.includes("Lorem Ipsum")
                                  ? "block"
                                  : "none",
                              }}
                              className="chack"
                              src={check}
                            />
                          </Box>
                          <Typography>Lorem Ipsum</Typography>
                        </Box>

                        <Box
                          onClick={() => {
                            if (chackVal.includes("Dolor Amet")) {
                              setChackVal(
                                chackVal.filter((item) => item !== "Dolor Amet")
                              );
                            } else {
                              pushData("Dolor Amet");
                            }
                          }}
                          className="categoryDropItem"
                        >
                          <Box className="chackBox">
                            <img className="chackBoxImg" src={chackBox} />
                            <img
                              style={{
                                display: chackVal.includes("Dolor Amet")
                                  ? "block"
                                  : "none",
                              }}
                              className="chack"
                              src={check}
                            />
                          </Box>
                          <Typography>Dolor Amet</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="acItem" onClick={() => setConOpDrop(null)}>
                      <Typography className="acdl">{t("Conversations.PreviewMode")}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <AppAddBtn
                buttonText={t("commonTxt.addTopicBtn")}
                onClick={() => setAddConversationTopicPop(true)}
              />
            </Box>) : ''
            }
          </Box>
          {/* Page container */}

          <Box className="researchersMainBox conversations">
            <AddConversationTopicPop
              addConversationTopicPop={addConversationTopicPop}
              setAddConversationTopicPop={setAddConversationTopicPop}
            />
            <DeleteConversationCategoryPop
              deleteConversationCategoryPop={deleteConversationCategoryPop}
              setDeleteConversationCategoryPop={
                setDeleteConversationCategoryPop
              }
            />
            <CreateConversationCategoryPop
              createConversationCategoryPop={createConversationCategoryPop}
              setCreateConversationCategoryPop={
                setCreateConversationCategoryPop
              }
            />
            <Box className="convCCardBox">
              <Box className="convCCard">
                <Box
                  className="conImg"
                  onClick={() =>
                    Navigate("/view-conversation-category")
                  }
                >
                  <img src={conCardImg1} alt="" />
                </Box>
                <Box className="conTextBox">
                  <Box className="conCTitle">
                    <Typography className="conCT">Outdoor Tips</Typography>
                    <img
                      src={card1Option ? ActiveDrop : moreIcon}
                      onClick={() => setCard1Option(!card1Option)}
                    />
                    {/* drop menu */}
                    <Box
                      className="statusPop"
                      sx={{ display: card1Option ? "block" : "none" }}
                    >
                      {cardDropItems?.map((el, i) => (
                        <Box
                          key={i}
                          className="statusPopItem"
                          onClick={() => setCard1Option(false)}
                        >
                          <p>{el}</p>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  <Typography>
                    Lorem ipsum dolor sit amet consecte tur. Acquis que
                    tristique dignissim non. Erat id ipsum sem non auctor.
                  </Typography>
                  <Box className="conCTitle concb">
                    <Typography>
                    {t("Conversations.Total")}: <span>3 {t("Conversations.Topics")}</span>
                    </Typography>
                    <Typography>
                    {t("Conversations.LastPost")}: <span>2 {t("Conversations.DaysAgo")}</span>
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box className="convCCard">
                <Box className="conImg">
                  <img src={conCardImg2} alt=""
                    onClick={() =>
                      Navigate("/view-conversation-category")
                    }
                  />
                </Box>
                <Box className="conTextBox">
                  <Box className="conCTitle">
                    <Typography className="conCT">Equipment Zone</Typography>
                    <img
                      src={card2Option ? ActiveDrop : moreIcon}
                      onClick={() => setCard2Option(!card2Option)}
                    />
                    {/* drop menu */}
                    <Box
                      className="statusPop"
                      sx={{ display: card2Option ? "block" : "none" }}
                    >
                      {cardDropItems?.map((el, i) => (
                        <Box
                          key={i}
                          className="statusPopItem"
                          onClick={() => setCard2Option(false)}>
                          <p>{el}</p>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  <Typography>
                    Lorem ipsum dolor sit amet consecte tur. Acquis que
                    tristique dignissim non. Erat id ipsum sem non auctor.
                  </Typography>
                  <Box className="conCTitle concb">
                    <Typography>
                    {t("Conversations.Total")}: <span>3 {t("Conversations.Topics")}</span>
                    </Typography>
                    <Typography>
                    {t("Conversations.LastPost")}: <span>2 {t("Conversations.DaysAgo")}</span>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box mt={8} className="ActiveTaskSectionBox">
              <Box mt={2} className="workListNavItem workListNavItemMobile">
                <Typography className="pageHeaderText">{t("Conversations.Topics")} (5)</Typography>
              </Box>
              <Box mt={2} px={3} className="workListNav">
                <Box className="workListNavItem workListNavItemDesktop">
                  <Typography className="pageHeaderText">
                  {t("Conversations.Topics")} (5)
                  </Typography>
                </Box>
                <Box
                  sx={{ minWidth: "418px", display: "flex", gap: "20px" }}
                  className="workListNavItem workListSearchBar"
                >
                  <SearchBar placeholder={t("Conversations.searchHerePlaceholder")} />

                  <Box minWidth={"211px"} className="agreSearchB2">
                    <LatestCreated
                      setLatestDrop={setLatestDrop}
                      latestCDrop={latestCDrop}
                      setLatestDropVal={setLatestDropVal}
                      latestDropVal={latestDropVal}
                    />
                  </Box>
                </Box>
              </Box>

              <Box className="workListBox">
                <Box className="haderRow" minWidth={"1111px"}>
                  <Box
                    minWidth={"7%"}
                    className="Tabelsel TabelStatesel"
                  ></Box>
                  <Box minWidth={"39%"} className="Tabelsel TabelStatesel">
                    <Typography>{t("Conversations.TopicName")}</Typography>
                  </Box>
                  <Box minWidth={"19%"} className="Tabelsel TabelStatesel">
                    <Typography>{t("Conversations.Category")}</Typography>
                  </Box>
                  <Box minWidth={"9%"} className="Tabelsel">
                    <Typography></Typography>
                  </Box>

                  <Box minWidth={"10%"} className="Tabelsel">
                    <Typography></Typography>
                  </Box>
                </Box>
                {newArrayData &&
                  newArrayData?.map((el, index) => (
                    <Box key={index} className="TabelRow" minWidth={"1111px"}>
                      <Box minWidth={"7%"} className="Tabelsel">
                        <img src={el.star} className="tabelStarIcon" />
                      </Box>
                      <Box
                        minWidth={"39%"}
                        className="Tabelsel navFsel navtable"
                      >
                        <Typography sx={{ cursor: "pointer", width: "95%" }}>
                          {el.title}
                        </Typography>
                      </Box>
                      <Box
                        minWidth={"19%"}
                        className="Tabelsel TabelStatesel"
                      >
                        <Typography>{el.Category}</Typography>
                      </Box>
                      <Box
                        minWidth={"9%"}
                        className="Tabelsel Tabelcentersel"
                      >
                        <img src={el.dot} />
                      </Box>

                      <Box className="Tabelsel tableBtnBox" minWidth={"10%"}>
                        <Box
                          className="viewBtn"
                          // onClick={() => setTopicView(true)}
                          onClick={() =>
                            Navigate("/view-conversation-topic")
                          }
                        >
                          {authState?.wsUserType == USER_TYPE_RESEARCHER || authState?.wsUserType == USER_TYPE_COLLABORATOR ? 
                          (<p>{t("commonTxt.viewBtn")}</p>) : (<p>{t("commonTxt.reply")}</p>)
                          }
                        </Box>
                      {authState?.wsUserType == USER_TYPE_RESEARCHER || authState?.wsUserType == USER_TYPE_COLLABORATOR ? 
                        (<Box
                          className="trashBox deleteTopicIcon"
                          onClick={() =>
                            setDeleteConversationCategoryPop(true)
                          }
                        >
                          <img src={trash} />
                        </Box>) : ''
                      }
                      </Box>
                    </Box>
                  ))}
              </Box>

              <Box className="pagenation">
                <Box onClick={handlePrev} className="tabelBtn">
                  <img src={arrow} />
                  <Typography>{t("commonTxt.previouspage")}</Typography>
                </Box>

                <Box className="pageNumberBox">
                  <Box
                    onClick={() => setCurrentPage(0)}
                    className={
                      currentPage === 0
                        ? "pageNumber pageNumberActive"
                        : "pageNumber"
                    }
                  >
                    <p>1</p>
                  </Box>
                  <Box
                    onClick={() => setCurrentPage(1)}
                    className={
                      currentPage === 1
                        ? "pageNumber pageNumberActive"
                        : "pageNumber"
                    }
                  >
                    <p>2</p>
                  </Box>
                  <Box
                    onClick={() => setCurrentPage(2)}
                    className={
                      currentPage === 2
                        ? "pageNumber pageNumberActive"
                        : "pageNumber"
                    }
                  >
                    <p>3</p>
                  </Box>
                  <Box
                    onClick={() => setCurrentPage(3)}
                    className={
                      currentPage === 3
                        ? "pageNumber pageNumberActive"
                        : "pageNumber"
                    }
                  >
                    <p>4</p>
                  </Box>
                  <Box
                    onClick={() => setCurrentPage(4)}
                    className={
                      currentPage === 4
                        ? "pageNumber pageNumberActive"
                        : "pageNumber"
                    }
                  >
                    <p>5</p>
                  </Box>
                </Box>

                <Box onClick={handleNext} className="tabelBtn">
                  <Typography>{t("commonTxt.nextPage")}</Typography>
                  <img style={{ rotate: "180deg" }} src={arrow} />
                </Box>
              </Box>
            </Box>
          </Box>

        </Box>
      </Box>
    </>
  );
}
