import React, { useEffect, useState } from 'react'
import "./style.css"
import { Box, Typography } from '@mui/material';
import EastIcon from '@mui/icons-material/East';

import iIcon from "../../../Assets/Images/IIcon.png";
import bannerClog from "../../../Assets/Images/bannerClogo.png";
import IClog from "../../../Assets/Images/TeraPage Transparent mix black.png";
import DropArrow from "../../../Assets/Images/chevron-down.png";
import BoxIcon from "../../../Assets/Images/BoxIcon.png";
import ballIcon from "../../../Assets/Images/bell.png";
import navIcon from "../../../Assets/Images/sidebar-panel-collapse-icon 1.png"
import CLogo from "../../../Assets/Images/Frame 243811.png"
import googleIcon from "../../../Assets/Images/google.png"
import whiteArrow from "../../../Assets/Images/white Arrow.png"
import backArrow from "../../../Assets/Images/noun-back.png"
import linkIcon from "../../../Assets/Images/link-broken.png"
import uploadIcon from "../../../Assets/Images/WhiteUpload.png"
import GImages from "../../../Assets/Images/AprGimage.png"

import { sideHaumIcon } from "../../../Assets/Data";

//components
import CodeEditor from '../../../Components/WSAdministration/CodeEditor';
import SideMenu from "../../../Components/WSAdministration/SideMenu";

import { AppBtn } from '../../../Components/AppTools/AppButton';
import AdminLayout from "../../Admin/partials/AdminLayout";
import ResearcherLayout from "../../Researchers/partials/ResearcherLayout";
import { useAppSelector } from '../../../lib/store/store';
import { renderUserLayout, viewImage } from '../../../lib/helpers';
import { addApperanceApi, uploadUserDirectoryImage } from '../../../Services/adminActions';
import { toast } from 'react-toastify';
import { TOAST_CONFIG } from '../../../lib/constants';
import { MuiColorInput } from 'mui-color-input'
import ColorPicker from 'react-best-gradient-color-picker'
import { API_BASE_URL } from '../../../env';
import axios from '../../../lib/axios';
import { fontGrid } from '@mui/material/styles/cssUtils';

export default function Appearance({ }) {

  const authState = useAppSelector((state) => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace);
  const [colorSelectorDrop1, setColorSelectorDrop1] = useState(false);
  const [colorSelectorDrop1Val, setColorSelectorDrop1Val] = useState("Solid Color");

  const [colorSelectorDrop2, setColorSelectorDrop2] = useState(false);
  const [colorSelectorDrop2Val, setColorSelectorDrop2Val] = useState("Solid Color");

  const [colorSelectorDrop3, setColorSelectorDrop3] = useState(false);
  const [colorSelectorDrop3Val, setColorSelectorDrop3Val] = useState("Custom CSS");

  const [activityDrop, setActivityDrop] = useState(false);
  const [activityDropVal, setActivityDroprVal] = useState();
  const [nav, setNav] = useState(false);
  const [platformLogoImage, setPlatformLogoImage] = useState(null);
  const [favIconImage, setFavIconImage] = useState(null);
  const [themeImageUpload, setThemeImageUpload] = useState(null);
  const [themeBackgroundImageUpload, setBackgroundThemeImageUpload] = useState(null);
  const [value, setValue] = React.useState('#ffffff')
  const [color, setColor] = useState('rgba(255,255,255,1)');
  const [solidColor, setSolidColor] = useState(true);
  const [gradientColor, setGradientColor] = useState(false);
  const [imageThemeSelection, setImageThemeSelection] = useState(false);
  const [solidBackgroundColor, setSolidBackgroundColor] = useState(true);
  const [gradientBackgroundColor, setGradientBackgroundColor] = useState(false);
  const [imageThemeBackgroundSelection, setImageThemeBackgroundSelection] = useState(false);

  const initialState = {
    "platform_logo":"",
    "fav_icon":"",
    "theme_color":"",
    "color_code":"",
    "theme_image":"",
    "background_theme_color":"",
    "background_color_code":"",
    "background_image":"",
    "code_based":"",
    "code_based_html":"",
  }

  const [apperanceState, setApperanceState] = useState(initialState);
  
  const fetchApperance = async() => {
    return await axios.get(
        API_BASE_URL + `/researchers/workspace-settings?workspace_id=${workspaceState?.selectedWorkspace?._id}`
      ).then((res) => {
        if (res.status) {
          const responseData = res.data.data[0];
          let object = {}
          for (const key in initialState) {
            if (Object.hasOwnProperty.call(responseData, key)) {
              object[key]=responseData[key];
              
            }
          }
          setApperanceState(object);
          setColorSelectorDrop1Val(responseData?.theme_color)
          setColorSelectorDrop2Val(responseData?.background_theme_color)
          setFavIconImage(viewImage(responseData?.fav_icon))
          setPlatformLogoImage(viewImage(responseData?.platform_logo))
          setThemeImageUpload(viewImage(responseData?.theme_image))
          setBackgroundThemeImageUpload(viewImage(responseData?.background_image))
          setColorSelectorDrop3Val(responseData?.code_based)
        }
      });
  }

  useEffect(() => {
    fetchApperance();
  }, [])


  const handleChange = (newValue) => {
    let newState = {
      ...apperanceState,
      // "color_code": gradientToHex(newValue)
      "color_code": newValue
    }
    setApperanceState(newState);
  }

  const handleChange1 = (newValue) => {
    let newState = {
      ...apperanceState,
      "background_color_code": newValue
    }
    setApperanceState(newState);
  }

  const triggerFileInput = () => {
    document.getElementById("fileInput").click()
  }

  const triggerFileInputFavIcon = () => {
    document.getElementById("fileInput1").click()
  }

  const triggerFileInputImageupload = () => {
    document.getElementById("fileInput2").click()
  }

  const triggerFileInput3 = () => {
    document.getElementById("fileInput3").click()
  }
  
  const handleFileInputChange = (e, type) => {
    const file = e.target.files[0]
    if(file) {
      const reader = new FileReader();
      reader.onload = () => {
        if(type == 'platform_logo'){
          setPlatformLogoImage(reader.result);
        } else if(type == 'fav_icon') {
          setFavIconImage(reader.result);
        } else if (type == 'theme_image'){
          setThemeImageUpload(reader.result);
        } else if (type == 'background_image'){
          setBackgroundThemeImageUpload(reader.result);
        }
      }
      reader.readAsDataURL(file);
      updateImage(file, type);
    }
  };

  const updateImage = async (file, type) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      let imageUploadRes = await uploadUserDirectoryImage(
        formData,
        authState.authToken
      );
      if (imageUploadRes.success) {
        setApperanceState({
          ...apperanceState,
          [type]: imageUploadRes?.data?.upload_path,
        });
        toast.success(imageUploadRes.message, TOAST_CONFIG);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  };
  
  let handAddBtn = async() => {
    try {
      let apperanceRes = await addApperanceApi(
        apperanceState,
        authState.authToken
      );
      // setLoading(false);
      if (apperanceRes.success) {
        toast.success(apperanceRes.message, TOAST_CONFIG);
        // setAddCollaboratorPop(false);
        // setReloadList(true);
      } else {
        toast.error(apperanceRes.message, TOAST_CONFIG);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      // setLoading(false);
    }
  }
  const getChildren = () => {
    return <Box className="researchersMainBox pageContainer">
      <Box className="appearance wsAppearance">
        <Box mb={5} className="workSpaceListBox">
          <Typography className='appearanceHeader'>Platform Logo</Typography>
          <Box className="iBox">
            <img src={iIcon} />
            <Typography>Maximum dimensions are 560px wide by 200px tall. The logo will be automatically reduced to fit within 280px by 100px.</Typography>
          </Box>
          <Box className="coverImg">
            <Box>
              {/* <img src={bannerClog} /> */}
              {platformLogoImage ? (
                <img className="fileinputimg" src={platformLogoImage} alt="Upload" />
                ) : (
                <img src={bannerClog} alt="Upload" />
              )}
              <Box className="coverImgTopBox">
                {/* <Box className="urlBtn whiteB">
                  <img src={linkIcon} />
                  <Typography sx={{ color: "#4F33D1" }}>Paste URL</Typography>
                </Box>
                <Box className="vrLine"></Box> */}
                <Box className="urlBtn BlueB" onClick={triggerFileInput}>
                  <img src={uploadIcon} />
                  <Typography sx={{ color: "#FFFFFF" }}>Upload Logo</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <input 
            id="fileInput"
            style={{ display: "none" }}
            type="file"
            onChange={(e) => {handleFileInputChange(e, "platform_logo")}}
          />
        </Box>

        <Box mb={5} className="workSpaceListBox">
          <Typography className='appearanceHeader'>Favicon</Typography>
          <Box className="iBox">
            <img src={iIcon} />
            <Typography>Maximum dimensions are 200 x 200 px. The logo will be automatically reduced to fit within 280px by 100px. </Typography>
          </Box>
          <Box className="coverImg" onClick={triggerFileInputFavIcon}>
            <Box className="AppenSmClogo">
              {/* <img src={IClog} /> */}
              {favIconImage ? (
                <img className="fileinputimg" src={favIconImage} alt="Upload" />
                ) : (
                <img src={bannerClog} alt="Upload" />
              )}
            </Box>
            <input 
              id="fileInput1"
              style={{ display: "none" }}
              type="file"
              onChange={(e) => {handleFileInputChange(e, "fav_icon")}}
            />
          </Box>
        </Box>
        <Box mb={5} className="workSpaceListBox">
          <Typography className='appearanceHeader'>Site Colors</Typography>
          <Box className="iBox">
            <img src={iIcon} />
            <Typography>Select a primary color and the shades required will be generated automatically.</Typography>
          </Box>
          <Box className="colorSelectorNavBox">
            <Box className="colorSelectorItem">
              <Typography className='appearanceHeader'>Color Theme</Typography>
              <Box onClick={() => setColorSelectorDrop1(!colorSelectorDrop1)} height={"40px"} px={2} className="statusBox">
                <Typography>{colorSelectorDrop1Val}</Typography>
                <img src={DropArrow} />
                {/* drop */}
                <Box sx={{ height: colorSelectorDrop1 ? "auto" : "0px" }} className="statusPop">
                  <Box onClick={() => {
                    setApperanceState({
                      ...apperanceState,
                    theme_color: "solid_color"
                  }); 
                    setColorSelectorDrop1Val("Solid Color"); 
                    setSolidColor(true); 
                    setGradientColor(false); 
                    setImageThemeSelection(false)
                  }} className="statusPopItem">
                  <Typography>Solid Color</Typography>
                  </Box>
                  <Box onClick={() => {
                    setApperanceState({
                      ...apperanceState,
                    theme_color: "gradient_color"
                  }); 
                  setColorSelectorDrop1Val("Gradient"); 
                  setGradientColor(true); setSolidColor(false); 
                  setImageThemeSelection(false)
                  }} className="statusPopItem">
                    <Typography>Gradient</Typography>
                  </Box>
                  <Box onClick={() => {
                    setApperanceState({
                        ...apperanceState,
                      theme_color: "image"
                    }); 
                  setColorSelectorDrop1Val("Image"); 
                  setImageThemeSelection(true); 
                  setSolidColor(false); 
                  setGradientColor(false)
                  }} className="statusPopItem">
                    <Typography>Image</Typography>
                  </Box>

                </Box>
              </Box>
            </Box>
            <Box className="colorSelectorItem">
              <Typography className='appearanceHeader'>Color Code</Typography>
              {/* <Box px={2} height={"40px"} className="colorPicker">
                <Box></Box>
                <Typography>#4F33D1</Typography>
              </Box> */}
              {solidColor && <MuiColorInput format="hex" value={apperanceState?.color_code} onChange={handleChange} />}
              {gradientColor && <ColorPicker value={apperanceState?.color_code} onChange={handleChange} />}
              {/* {gradientColor && <ColorPicker value={color} onChange={setColor} />} */}
              {imageThemeSelection && <Box sx={{backgroundColor:"#0020af", display: "flex", justifyContent:"center", alignItems:"center", width:"100%", padding: "8px", borderRadius:"8px"}} onClick={triggerFileInputImageupload} >
                <img src={uploadIcon} />
                <Typography sx={{ color: "#FFFFFF", marginLeft:"10px"}}>Upload Image</Typography>
              </Box>}
              <input 
                id="fileInput2"
                style={{ display: "none" }}
                type="file"
                onChange={(e) => {handleFileInputChange(e, "theme_image")}}
              />
            </Box>
          </Box>
          <Box className="colorPreviewBox">
            <Box className="colorPreNav">
              <Typography className='appearanceHeader'>Preview Color:</Typography>
              {/* {console.log("abc", gradientToHex(apperanceState?.color_code))} */}
              <Box className="colorHaxBox" sx={{background: apperanceState?.color_code}}>
                {/* <Typography>{apperanceState?.color_code}</Typography> */}
              </Box>
              {/* <Typography>{apperanceState?.color_code}</Typography> */}
              <Typography color={"#4F33D1"} className='unLBtn'>Reset</Typography>
            </Box>
            <Box className="colorMenuBox">
              <Box sx={{ width: nav ? "55px" : "0px" }} className="ColorsideM">
                <Box>
                  <img src={IClog} />
                </Box>
                <Box>
                  <img src={BoxIcon} />
                </Box>
                <Box>
                  <img src={ballIcon} />
                </Box>
              </Box>

              <Box className="ColorMBox">
                <Box className="navColorBox">
                  <Box onClick={() => setNav(!nav)} className="CnavLog">
                    <img style={{ rotate: nav ? "0deg" : "-180deg" }} src={navIcon} />
                  </Box>
                  <Box className="cNavBtnBox">
                    <Box onClick={() => setActivityDrop(!activityDrop)} px={2} className="statusBox appearanceActivityDrop">
                      <Typography>{activityDropVal || "Activity Options"}</Typography>
                      <img src={DropArrow} />
                      {/* drop */}
                      <Box sx={{ height: activityDrop ? "auto" : "0px" }} className="statusPop">
                        <Box onClick={() => setActivityDroprVal("Activity1")} className="statusPopItem">
                          <Typography>Activity1</Typography>
                        </Box>
                        <Box onClick={() => setActivityDroprVal("Activity2")} className="statusPopItem">
                          <Typography>Activity2</Typography>
                        </Box>
                        <Box onClick={() => setActivityDroprVal("Activity3")} className="statusPopItem">
                          <Typography>Activity3</Typography>
                        </Box>
                        <Box onClick={() => setActivityDroprVal("Activity4")} className="statusPopItem">
                          <Typography>Activity4</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <AppBtn buttonText="Preview Activity" />
                  </Box>
                </Box>
                <Box className="CMainContainar">
                  <Box className="ApInMenuBackBtn">
                    <Box className="openBtn">
                      <img src={backArrow} />
                      <Typography className='neonBackText'>Back</Typography>
                    </Box>
                  </Box>

                  <Box>
                    <Typography className='ApInMenuHText'>Activity Tasks</Typography>
                  </Box>


                </Box>

              </Box>
            </Box>
            <Box className="CAscentBox">
              <Box className="CAscent1Box">
                <Typography mb={1} className='appearanceHeader'>Form Ascent Color</Typography>
                <Typography>Only required if theme is similar to red. It will automatically match it unless costumized</Typography>
              </Box>
              <Box className="CAscent2Box">
                <Box className="colorSelectorItem">
                  <Box px={2} height={"40px"} className="colorPicker">
                    <Box></Box>
                    <Typography>#4F33D1</Typography>
                  </Box>
                </Box>
                <Typography ml={1} color={"#000000"} className='unLBtn'>Reset</Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box mb={5} className="workSpaceListBox">
          <Typography className='appearanceHeader'>Site Background</Typography>
          <Box className="iBox">
            <img src={iIcon} />
            <Typography>Selected color will be set as the background for onboarding screens.</Typography>
          </Box>
          <Box className="colorSelectorNavBox">
            <Box className="colorSelectorItem">
              <Typography className='appearanceHeader'>Color Theme</Typography>
              <Box onClick={() => setColorSelectorDrop2(!colorSelectorDrop2)} height={"40px"} px={2} className="statusBox">
                <Typography>{colorSelectorDrop2Val}</Typography>
                <img src={DropArrow} />
                {/* drop */}
                <Box sx={{ height: colorSelectorDrop2 ? "auto" : "0px" }} className="statusPop">
                  <Box onClick={() => {
                    setApperanceState({
                      ...apperanceState,
                      background_theme_color: "solid_color"
                    }); 
                      setColorSelectorDrop2Val("Solid Color"); 
                      setSolidBackgroundColor(true); 
                      setGradientBackgroundColor(false); 
                      setImageThemeBackgroundSelection(false)
                    }} className="statusPopItem">
                    <Typography>Solid Color</Typography>
                  </Box>
                  <Box onClick={() => {
                    setApperanceState({
                      ...apperanceState,
                      background_theme_color: "gradient_color"
                    }); 
                    setColorSelectorDrop2Val("Gradient"); 
                    setGradientBackgroundColor(true); 
                    setSolidBackgroundColor(false); 
                    setImageThemeBackgroundSelection(false)
                    }} className="statusPopItem">
                      <Typography>Gradient</Typography>
                  </Box>
                  <Box onClick={() => {
                    setApperanceState({
                        ...apperanceState,
                        background_theme_color: "image"
                      }); 
                      setColorSelectorDrop2Val("Image"); 
                      setImageThemeBackgroundSelection(true); 
                      setSolidBackgroundColor(false); 
                      setGradientBackgroundColor(false);
                    }} className="statusPopItem">
                      <Typography>Image</Typography>
                  </Box>

                </Box>
              </Box>
            </Box>
            <Box className="colorSelectorItem">
              <Typography className='appearanceHeader'>Color Code</Typography>
              {/* <Box px={2} height={"40px"} className="colorPicker">
                <Box></Box>
                <Typography>#4F33D1</Typography>
              </Box> */}
              {solidBackgroundColor && <MuiColorInput format="hex" value={apperanceState?.background_color_code} onChange={handleChange1} />}
              {gradientBackgroundColor && <ColorPicker value={apperanceState?.background_color_code} onChange={handleChange1} />}
              {/* {gradientColor && <ColorPicker value={color} onChange={setColor} />} */}
              {imageThemeBackgroundSelection && <Box sx={{backgroundColor:"#0020af", display: "flex", justifyContent:"center", alignItems:"center", width:"100%", padding: "8px", borderRadius:"8px"}} onClick={triggerFileInput3} >
                <img src={uploadIcon} />
                <Typography sx={{ color: "#FFFFFF", marginLeft:"10px"}}>Upload Image</Typography>
              </Box>}
              <input 
                id="fileInput3"
                style={{ display: "none" }}
                type="file"
                onChange={(e) => {handleFileInputChange(e, "background_image")}}
              />
            </Box>
          </Box>
          <Box className="colorPreviewGoogleBox">
            <Box className="backgroundPreviewBox">
              <Typography className='appearanceHeader'>Background Preview</Typography>
              <Box className="BgPreview">
                <img style={{ display: colorSelectorDrop2Val === "Image" ? "block" : "none" }} src={GImages} />
              </Box>
            </Box>
            <Box className="signInBox">
              <Box className="signInInnerBox">
                <Box className="signColog">
                  <img src={CLogo} />
                </Box>
                <Box className="signInInputBox">
                  <Typography>Username / Email</Typography>
                  <input placeholder='Enter Username or Email Address' />
                </Box>
                <Box className="signInInputBox">
                  <Typography>Password</Typography>
                  <input placeholder='Enter Password' />
                </Box>
                <Box className="forGotPwd">
                  <Typography color={"#4F33D1"}>Forgot Password?</Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <input type="checkbox" />
                    <Typography ml={1}>Stay Signed in for 30 days</Typography>
                  </Box>
                </Box>
                <Box className="signBtn">
                  <Typography>Sign In</Typography>
                  <img src={whiteArrow} />
                </Box>
                <Typography className='createAccoutText'>Create Account <span>instead</span> ?</Typography>
                <Box className="orText">
                  <p>Or</p>
                </Box>
                <Box className="GoogleSignUpBox">
                  <img src={googleIcon} />
                  <Typography>Sign in with Google</Typography>
                </Box>
                <Box className="signBtn">
                  <Typography>Sign In with SSO</Typography>
                </Box>




              </Box>
            </Box>
          </Box>
        </Box>

        <Box mb={5} className="workSpaceListBox">
          <Typography className='appearanceHeader'>Code Based Changes</Typography>
          <Box className="iBox">
            <img src={iIcon} />
            <Typography>Restricted elements would be removed before saving.</Typography>
          </Box>

          <Box mb={5} sx={{ width: "318px" }}>
            <Box className="colorSelectorItem">
              <Typography className='appearanceHeader'>Select Section</Typography>
              <Box onClick={() => setColorSelectorDrop3(!colorSelectorDrop3)} height={"40px"} px={2} className="statusBox">
                <Typography>{colorSelectorDrop3Val}</Typography>
                <img src={DropArrow} />
                {/* drop */}
                <Box sx={{ height: colorSelectorDrop3 ? "auto" : "0px" }} className="statusPop">
                  <Box onClick={() => {
                    setApperanceState({
                        ...apperanceState,
                        code_based: "custom_css"
                      }); 
                      setColorSelectorDrop3Val("Custom CSS")
                      }} className="statusPopItem"
                    >
                    <Typography>Custom CSS</Typography>
                  </Box>
                  <Box onClick={() => {
                    setApperanceState({
                      ...apperanceState,
                      code_based: "header_html"
                    }); 
                    setColorSelectorDrop3Val("Header HTML")
                  }} className="statusPopItem">
                  <Typography>Header HTML</Typography>
                  </Box>
                  <Box onClick={() => {
                      setApperanceState({
                        ...apperanceState,
                        code_based: "footer_html",
                      });
                      setColorSelectorDrop3Val("Footer HTML")
                    }} className="statusPopItem">
                    <Typography>Footer HTML</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="codeBox">
            <CodeEditor 
              colorSelectorDrop3Val={colorSelectorDrop3Val} 
              scriptVal={apperanceState}
              setScriptVal={setApperanceState}
              field="code_based_html"
            />
          </Box>
          <Box className="addResearcherPupBtnBox">
            <AppBtn
              buttonText="Save Change"
              onClick={() => handAddBtn()}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  }

  return renderUserLayout(authState?.userType, null, getChildren(), 'workadmin');
}
