import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.css"

import dropArrow from "../../../Assets/Images/chevron-down.png";

//component
import { AppBtn } from "../../AppTools/AppButton";
import { ApexChart } from "../../../Components/AppTools/PieChart";

export default function ProjectDataComparison() {

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const [currentPage, setCurrentPage] = useState(0);

  const [firstProjectDrop, setFirstProjectDrop] = useState(false);
  const [firstProjectDropVal, setFirstProjectDropVal] = useState();
  const [secondProjectDrop, setSecondProjectDrop] = useState(false);
  const [secondProjectDropVal, setSecondProjectDropVal] = useState();
  const [overAllComparBox, setOverAllComparBox] = useState(false);

  const [participantProfilesBox, setparticipantProfilesBox] = useState(false);
  const [participationRatesBox, setParticipationRatesBox] = useState(false);
  const [adminProfilesBox, setAdminProfilesBox] = useState(false);
  const [activityStatisticsBox, setActivityStatisticsBox] = useState(false);
  const [conversationStatisticsBox, setConversationStatisticsBox] = useState(false);
  const [customFieldsBox, setCustomFieldsBox] = useState(false);


  const ageData = [
    {
      AgeGroup: "Open Activities",
      Number: "08 (40%)",
      Percentage: "25 (50%)",
    },
    {
      AgeGroup: "Closed Activities",
      Number: "04 (20%)",
      Percentage: "14 (25%)",
    },
    {
      AgeGroup: "Drafted Activities",
      Number: "05 (25%)",
      Percentage: "10 (20%)",
    },
  ];


  const firstProjectDropItems = [
    "Outdoor Journey",
    "Financial Awareness",
    "Sports Psychology"
  ]
  const secondProjectDropItems = [
    "Car Brands",
    "Financial Awareness",
    "Sports Psychology"
  ]


  return (
    <>
      <Box className="pageContainer reportsPage">

        {/* -----------------------------------------------Project Data Comparison-------------------------------------- */}
        <Box className="reportContainer integrationsContainer compositionSection"
        >
          <Typography className="pageHeaderText">
            {t("projectDataComparison.dataComparisonHead")}
          </Typography>
          <Box className="participationReportDropSection">
            <Box className="inputBoxWlabel">
              <Typography>{t("projectDataComparison.dataComparisonFirstProjectLabel")}</Typography>
              <Box
                className="inputAppDrop"
                onClick={() => setFirstProjectDrop(!firstProjectDrop)}
              >
                <Typography>{firstProjectDropVal || t("projectDataComparison.dataComparisonFirstProjectLabel")}</Typography>
                <img
                  style={{ rotate: firstProjectDrop ? "180deg" : "0deg" }}
                  src={dropArrow}
                />
                {/* pop */}
                <Box
                  sx={{ height: firstProjectDrop ? "auto" : "0px" }}
                  className="statusPop"
                >
                  {firstProjectDropItems?.map((el, i) => (
                    <Box
                      key={i}
                      onClick={() => setFirstProjectDropVal(el)}
                      className="statusPopItem">
                      <Typography>{el}</Typography>
                    </Box>
                  ))
                  }
                </Box>
              </Box>
            </Box>

            <Box className="inputBoxWlabel">
              <Typography>{t("projectDataComparison.dataComparisonSecondProjectLabel")}</Typography>
              <Box
                className="inputAppDrop"
                onClick={() => setSecondProjectDrop(!secondProjectDrop)}
              >
                <Typography>{secondProjectDropVal || t("projectDataComparison.dataComparisonSecondProjectLabel")}</Typography>
                <img
                  style={{ rotate: secondProjectDrop ? "180deg" : "0deg" }}
                  src={dropArrow}
                />
                {/* pop */}

                <Box
                  sx={{ height: secondProjectDrop ? "auto" : "0px" }}
                  className="statusPop"
                >
                  {secondProjectDropItems?.map((el, i) => (
                    <Box key={i} onClick={() => setSecondProjectDropVal(el)}
                      className="statusPopItem">
                      <Typography>{el}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box mt={5} className="redioGroupSection">
            <Typography className="psSubLabel">
              {t("projectDataComparison.projectDataComparisonAspects")}
            </Typography>

            <Box my={2} className="projectRadioBoxSection">
              <Box className="ProjectRadioInput">
                <input
                  className="custom-checkbox"
                  type="checkBox"
                  name="projectState"
                  onChange={() => setparticipantProfilesBox(!participantProfilesBox)}
                />
                <Typography className="emailSettingLabel">{t("projectDataComparison.participantProfilesCheckbox")}</Typography>
              </Box>
              <Box className="ProjectRadioInput">
                <input
                  className="custom-checkbox"
                  type="checkBox"
                  name="projectState"
                  onChange={() => setParticipationRatesBox(!participationRatesBox)}
                />
                <Typography className="emailSettingLabel">{t("projectDataComparison.participationRatesCheckbox")}</Typography>
              </Box>
            </Box>

            <Box mt={4} className="projectRadioBoxSection">
              <Box className="ProjectRadioInput">
                <input
                  className="custom-checkbox"
                  type="checkBox"
                  name="projectState"
                  onChange={() => setAdminProfilesBox(!adminProfilesBox)}
                />
                <Typography className="emailSettingLabel">
                  {t("projectDataComparison.adminProfilesCheckbox")}
                </Typography>
              </Box>
              <Box className="ProjectRadioInput">
                <input
                  className="custom-checkbox"
                  type="checkBox"
                  name="projectState"
                  onChange={() => setActivityStatisticsBox(!activityStatisticsBox)}
                />
                <Typography className="emailSettingLabel">
                  {t("projectDataComparison.activityStaticsCheckbox")}
                </Typography>
              </Box>
            </Box>

            <Box mt={4} className="projectRadioBoxSection">
              <Box className="ProjectRadioInput">
                <input
                  className="custom-checkbox"
                  type="checkBox"
                  name="projectState"
                  onChange={() => setConversationStatisticsBox(!conversationStatisticsBox)}

                />
                <Typography className="emailSettingLabel">
                  {t("projectDataComparison.conversationStaticsCheckbox")}
                </Typography>
              </Box>
              <Box className="ProjectRadioInput">
                <input
                  className="custom-checkbox"
                  type="checkBox"
                  name="projectState"
                  onChange={() => setCustomFieldsBox(!customFieldsBox)}
                />
                <Typography className="emailSettingLabel">
                  {t("projectDataComparison.customFieldsCheckbox")}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box className="workPupBtnBox">
            <AppBtn
              disabled={firstProjectDropVal && secondProjectDropVal ? false : true}
              buttonText={t("commonTxt.compareProjectsBtn")}
              onClick={() => setOverAllComparBox(true)}
            />
          </Box>
        </Box>

        <Box sx={{ display: overAllComparBox ? "flex" : "none" }} mt={5} className="reportContainer integrationsContainer compositionSection">

          <Box mt={1} className="tabelNav">
            <Box className="dropWheader">
              <Typography className="pageHeaderText">
                {t("projectDataComparison.overallComparisonReport")}
              </Typography>
            </Box>
          </Box>

          <Box className="pieBox">
            <ApexChart />
          </Box>
          <Box my={3} className="tabelNav">
            <Typography className="pageHeaderText">
              {t("projectDataComparison.individualAspectComparisonTable")}
            </Typography>
          </Box>
          <Box className="workListBox">
            <Box className="haderRow" minWidth={"955px"}>
              <Box minWidth={"35%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectDataComparison.tblAspects")}</Typography>
              </Box>
              <Box minWidth={"25%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectDataComparison.tblConformance")}</Typography>
              </Box>
              <Box minWidth={"30%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectDataComparison.tblContradiction")}</Typography>
              </Box>
            </Box>

            {ageData?.map((el, i) => (
              <Box className="TabelRow" minWidth={"955px"}>
                <Box className="Tabelsel navFsel" minWidth={"5%"}>
                  <Box id="dot2" className="dot">
                  </Box>
                </Box>
                <Box minWidth={"35%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.AgeGroup}</Typography>
                </Box>
                <Box minWidth={"25%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Number}</Typography>
                </Box>
                <Box minWidth={"30%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Percentage}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>




        {/* ------------------------------Participant Profiles------------------------------------- */}

        <Box sx={{ display: participantProfilesBox ? "flex" : "none" }} mt={5} className="integrationsContainer">
          <Box mt={1} mb={3} className="tabelNav">
            <Box className="dropWheader">
              <Typography className="pageHeaderText">{t("projectDataComparison.ParticiProfileRateHeader")}</Typography>
            </Box>
          </Box>

          <Box className="twoPieBox">
            <Box className="pieBoxPdc">
              <ApexChart />
            </Box>
            <Box className="pieBoxPdc">
              <ApexChart />
            </Box>
          </Box>



          <Box my={3} className="tabelNav">
            <Typography className="pageHeaderText">{t("projectDataComparison.ComparisonSummary")}</Typography>
          </Box>

          <Box className="workListBox">
            <Box className="haderRow" minWidth={"955px"}>
              <Box minWidth={"5%"} className="Tabelsel TabelStatesel">
                {/* <Typography>Aspects</Typography> */}
              </Box>
              <Box minWidth={"35%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectDataComparison.tblAspects")}</Typography>
              </Box>
              <Box minWidth={"25%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectDataComparison.tblConformance")}</Typography>
              </Box>
              <Box minWidth={"30%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectDataComparison.tblContradiction")}</Typography>
              </Box>
            </Box>

            {ageData?.map((el, i) => (
              <Box className="TabelRow" minWidth={"955px"}>
                <Box className="Tabelsel navFsel" minWidth={"5%"}>
                  <Box id="dot2" className="dot">
                  </Box>
                </Box>
                <Box minWidth={"35%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.AgeGroup}</Typography>
                </Box>
                <Box minWidth={"25%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Number}</Typography>
                </Box>
                <Box minWidth={"30%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Percentage}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        {/* -----------------------------------------------Participation Rate Comparison Report-------------------------------------- */}

        <Box sx={{ display: participationRatesBox ? "flex" : "none" }} mt={5} className="integrationsContainer">
          <Box mt={1} mb={3} className="tabelNav">
            <Box className="dropWheader">
              <Typography className="pageHeaderText">{t("projectDataComparison.ParticipationRateHeader")}</Typography>
            </Box>
          </Box>

          <Box className="twoPieBox">
            <Box className="pieBoxPdc">
              <ApexChart />
            </Box>
            <Box className="pieBoxPdc">
              <ApexChart />
            </Box>
          </Box>

          <Box my={3} className="tabelNav">
            <Typography className="pageHeaderText">{t("projectDataComparison.ComparisonSummary")}</Typography>
          </Box>

          <Box className="workListBox">
            <Box className="haderRow" minWidth={"955px"}>
              <Box minWidth={"5%"} className="Tabelsel TabelStatesel">
                {/* <Typography>Aspects</Typography> */}
              </Box>
              <Box minWidth={"35%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectDataComparison.tblAspects")}</Typography>
              </Box>
              <Box minWidth={"25%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectDataComparison.tblConformance")}</Typography>
              </Box>
              <Box minWidth={"30%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectDataComparison.tblContradiction")}</Typography>
              </Box>
            </Box>

            {ageData?.map((el, i) => (
              <Box className="TabelRow" minWidth={"955px"}>
                <Box className="Tabelsel navFsel" minWidth={"5%"}>
                  <Box id="dot2" className="dot">
                  </Box>
                </Box>
                <Box minWidth={"35%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.AgeGroup}</Typography>
                </Box>
                <Box minWidth={"25%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Number}</Typography>
                </Box>
                <Box minWidth={"30%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Percentage}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>





        {/* --------------------Admin Profiles Comparison Report--------------------- */}

        <Box sx={{ display: adminProfilesBox ? "flex" : "none" }} mt={5} className="integrationsContainer">
          <Box mt={1} mb={3} className="tabelNav">
            <Box className="dropWheader">
              <Typography className="pageHeaderText">{t("projectDataComparison.AdminProfilesHeader")}</Typography>
            </Box>
          </Box>

          <Box className="twoPieBox">
            <Box className="pieBoxPdc">
              <ApexChart />
            </Box>
            <Box className="pieBoxPdc">
              <ApexChart />
            </Box>
          </Box>



          <Box my={3} className="tabelNav">
            <Typography className="pageHeaderText">{t("projectDataComparison.ComparisonSummary")}</Typography>
          </Box>

          <Box className="workListBox">
            <Box className="haderRow" minWidth={"955px"}>
              <Box minWidth={"5%"} className="Tabelsel TabelStatesel">
                {/* <Typography>Aspects</Typography> */}
              </Box>
              <Box minWidth={"35%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectDataComparison.tblAspects")}</Typography>
              </Box>
              <Box minWidth={"25%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectDataComparison.tblConformance")}</Typography>
              </Box>
              <Box minWidth={"30%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectDataComparison.tblContradiction")}</Typography>
              </Box>
            </Box>

            {ageData?.map((el, i) => (
              <Box className="TabelRow" minWidth={"955px"}>
                <Box className="Tabelsel navFsel" minWidth={"5%"}>
                  <Box id="dot2" className="dot">
                  </Box>
                </Box>
                <Box minWidth={"35%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.AgeGroup}</Typography>
                </Box>
                <Box minWidth={"25%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Number}</Typography>
                </Box>
                <Box minWidth={"30%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Percentage}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>


        {/* ------------------------Activity Statistics Comparison Report---------------------------- */}
        <Box sx={{ display: activityStatisticsBox ? "flex" : "none" }} mt={5} className="integrationsContainer">
          <Box mt={1} mb={3} className="tabelNav">
            <Box className="dropWheader">
              <Typography className="pageHeaderText">{t("projectDataComparison.ActivityStatisticsHeader")}</Typography>
            </Box>
          </Box>

          <Box className="twoPieBox">
            <Box className="pieBoxPdc">
              <ApexChart />
            </Box>
            <Box className="pieBoxPdc">
              <ApexChart />
            </Box>
          </Box>


          <Box my={3} className="tabelNav">
            <Typography className="pageHeaderText">{t("projectDataComparison.ComparisonSummary")}</Typography>
          </Box>

          <Box className="workListBox">
            <Box className="haderRow" minWidth={"955px"}>
              <Box minWidth={"35%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectDataComparison.tblAspects")}</Typography>
              </Box>
              <Box minWidth={"25%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectDataComparison.tblConformance")}</Typography>
              </Box>
              <Box minWidth={"30%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectDataComparison.tblContradiction")}</Typography>
              </Box>
            </Box>

            {ageData?.map((el, i) => (
              <Box className="TabelRow" minWidth={"955px"}>
                <Box className="Tabelsel navFsel" minWidth={"5%"}>
                  <Box id="dot2" className="dot">
                  </Box>
                </Box>
                <Box minWidth={"35%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.AgeGroup}</Typography>
                </Box>
                <Box minWidth={"25%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Number}</Typography>
                </Box>
                <Box minWidth={"30%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Percentage}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>




        {/* ------------------------------Conversation Statistics------------------------------------- */}

        <Box sx={{ display: conversationStatisticsBox ? "flex" : "none" }} mt={5} className="integrationsContainer">
          <Box mt={1} mb={3} className="tabelNav">
            <Box className="dropWheader">
              <Typography className="pageHeaderText">{t("projectDataComparison.ParticiConversationRateHeader")}</Typography>
            </Box>
          </Box>

          <Box className="twoPieBox">
            <Box className="pieBoxPdc">
              <ApexChart />
            </Box>
            <Box className="pieBoxPdc">
              <ApexChart />
            </Box>
          </Box>



          <Box my={3} className="tabelNav">
            <Typography className="pageHeaderText">{t("projectDataComparison.ComparisonSummary")}</Typography>
          </Box>

          <Box className="workListBox">
            <Box className="haderRow" minWidth={"955px"}>
              <Box minWidth={"5%"} className="Tabelsel TabelStatesel">
                {/* <Typography>Aspects</Typography> */}
              </Box>
              <Box minWidth={"35%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectDataComparison.tblAspects")}</Typography>
              </Box>
              <Box minWidth={"25%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectDataComparison.tblConformance")}</Typography>
              </Box>
              <Box minWidth={"30%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectDataComparison.tblContradiction")}</Typography>
              </Box>
            </Box>

            {ageData?.map((el, i) => (
              <Box className="TabelRow" minWidth={"955px"}>
                <Box className="Tabelsel navFsel" minWidth={"5%"}>
                  <Box id="dot2" className="dot">
                  </Box>
                </Box>
                <Box minWidth={"35%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.AgeGroup}</Typography>
                </Box>
                <Box minWidth={"25%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Number}</Typography>
                </Box>
                <Box minWidth={"30%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Percentage}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        {/* ------------------------------Custom Fields------------------------------------- */}

        <Box sx={{ display: customFieldsBox ? "flex" : "none" }} mt={5} className="integrationsContainer">
          <Box mt={1} mb={3} className="tabelNav">
            <Box className="dropWheader">
              <Typography className="pageHeaderText">{t("projectDataComparison.ParticiCustomFieldsRateHeader")}</Typography>
            </Box>
          </Box>

          <Box className="twoPieBox">
            <Box className="pieBoxPdc">
              <ApexChart />
            </Box>
            <Box className="pieBoxPdc">
              <ApexChart />
            </Box>
          </Box>



          <Box my={3} className="tabelNav">
            <Typography className="pageHeaderText">{t("projectDataComparison.ComparisonSummary")}</Typography>
          </Box>

          <Box className="workListBox">
            <Box className="haderRow" minWidth={"955px"}>
              <Box minWidth={"5%"} className="Tabelsel TabelStatesel">
                {/* <Typography>Aspects</Typography> */}
              </Box>
              <Box minWidth={"35%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectDataComparison.tblAspects")}</Typography>
              </Box>
              <Box minWidth={"25%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectDataComparison.tblConformance")}</Typography>
              </Box>
              <Box minWidth={"30%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectDataComparison.tblContradiction")}</Typography>
              </Box>
            </Box>

            {ageData?.map((el, i) => (
              <Box className="TabelRow" minWidth={"955px"}>
                <Box className="Tabelsel navFsel" minWidth={"5%"}>
                  <Box id="dot2" className="dot">
                  </Box>
                </Box>
                <Box minWidth={"35%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.AgeGroup}</Typography>
                </Box>
                <Box minWidth={"25%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Number}</Typography>
                </Box>
                <Box minWidth={"30%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Percentage}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>


      </Box>
    </>
  );
}
