import React, { useState, useEffect } from "react";
import "./style.css";
import { styled } from "@mui/material/styles";
import { Box, Typography, fabClasses } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../lib/store/store";

//images
import backArrow from "../../../Assets/Images/noun-back.png";
import arrow from "../../../Assets/Images/arrow.png";
import sendIcon from "../../../Assets/Images/sendIcon.png";
import groupIcon from "../../../Assets/Images/groupsIcon.png";
import tik1 from "../../../Assets/Images/checkG.png";
import redcircle from "../../../Assets/Images/alert-circle.png";
import cott from "../../../Assets/Images/cott.png";

//data
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import {
  Task,
  trashBlack,
  participants,
  GreenDot,
  RedDot,
  sideHaumIcon,
  viewProfileIcon,
  markAllReviewedIcon,
  sendMessageIcon,
  supportLoginIcon,
  actionLogIcon,
  exceprtsIcon,
  reportsIcon,
  transcriptIcon,
} from "../../../Assets/Data";
import {
  USER_TYPE_COLLABORATOR,
  USER_TYPE_RESEARCHER,
  USER_TYPE_OBSERVER,
} from "../../../lib/constants";
//components
import {
  SearchBar,
  Status,
  AllDrop5,
} from "../../../Components/AppTools/AppTool";
import { AppAddBtn } from "../../../Components/AppTools/AppButton";
import SideMenu from "../../../Components/Researchers/SideMenu";
import Slider from "../../../Components/AppTools/Carousel";
import ParticipantGroupPop from "../../../Components/Researchers/ParticipantGroupPop";
import Thread from "../../../Components/Researchers/ActivityTasksThread";
import Images from "../../../Components/Researchers/ActivityTasksImages";
import Videos from "../../../Components/Researchers/ActivityTasksVideos";
import Audio from "../../../Components/Researchers/ActivityTasksAudio";
import AddTaskPop from "../../../Components/Researchers/AddTaskPop";
import AddTextTaskPop from "../../../Components/Researchers/AddTextTaskPop";
import AddImageTaskPop from "../../../Components/Researchers/AddImageTaskPop";
import AddVideoTaskPop from "../../../Components/Researchers/AddVideoTaskPop";
import AddAudioTaskPop from "../../../Components/Researchers/AddAudioTaskPop";
import AddFillOutTaskPop from "../../../Components/Researchers/AddFillOutTaskPop";
import AddMixedMediaTaskPop from "../../../Components/Researchers/AddMixedMediaTaskPop";
import AddPollSurveyTaskPop from "../../../Components/Researchers/AddPollSurveyTaskPop";
import AddOnDotTaskPop from "../../../Components/Researchers/AddOnDotTaskPop";
import RearrangeTaskPop from "../../../Components/Researchers/RearrangeTaskPop";
import GroupRearrangeTaskPop from "../../../Components/Researchers/GroupRearrangeTaskPop";
import AddScreenCaptureTaskPop from "../../../Components/Researchers/AddScreenCaptureTaskPop";
import AnyFileTaskPop from "../../../Components/Researchers/AnyFileTaskPop";
import AddMatrixTaskPop from "../../../Components/Researchers/AddMatrixTaskPop";
import ReviewAnImagePop from "../../../Components/Researchers/ReviewAnImagePop";
import ReviewVideoPop from "../../../Components/Researchers/ReviewVideoPop";
import ReviewAudioPop from "../../../Components/Researchers/ReviewAudioPop";
import RenkItSortItPop from "../../../Components/Researchers/RenkItSortItPop";
import ExpressionsComponent from "../../../Components/Researchers/ExpressionsComponent";
import DeleteTaskPop from "../../../Components/Researchers/DeleteTaskPop";
import NoTaskBg from "../../../Components/Researchers/NoTaskBg";
import { API_BASE_URL } from "../../../env";
import axios from "../../../lib/axios";
import { viewImage, formatDate } from "../../../lib/helpers";
import Pagination from "../../../Components/AppTools/Pagination";

export default function ActivityTasks({
  SetEntiteActivityPage,
  workspaceDrop,
  setWorkSpaceDrop,
  slideMenu,
  setSlideMenu,
}) {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const Navigate = useNavigate();
  const { activityId } = useParams();
  let authState = useAppSelector((state) => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace);
  const [taskNav, setTaskNav] = useState(0);
  const [responsesNav, setResponsesNav] = useState("Thread");
  const [responseExpression, setResponseExpression] = useState(false);
  const [statusDrop, setStatusDrop] = useState(false);
  const [statusDropVal, setStatusDropVal] = useState("All");

  const [statusDrop2, setStatusDrop2] = useState(false);
  const [statusDropVal2, setStatusDropVal2] = useState("All Tasks");

  const [optionIndex, setOptionIndex] = useState();
  const [workSpaceOption, setWorkspaceOption] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [newArrayData, setNewArrayData] = useState([]);

  const [participantGroupPop, setParticipantGroupPop] = useState(false);
  const [allDrop4, setAllDrop4] = useState();
  const [allDropVal4, setAllDropVal4] = useState("Activity Options");
  const [selectedTask, setSelectedTask] = useState();
  const [addTextTaskPop, setAddTextTaskPop] = useState(false);
  const [addImageTaskPop, setAddImageTaskPop] = useState(false);
  const [addVideoTaskPop, setAddVideoTaskPop] = useState(false);
  const [addAudioTaskPop, setAddAudioTaskPop] = useState(false);
  const [addFillOutTaskPop, setAddFillOutTaskPop] = useState(false);
  const [addMixedMediaTaskPop, setAddMixedMediaTaskPop] = useState(false);
  const [addPollSurveyTaskPop, setAddPollSurveyTaskPop] = useState(false);
  const [addOnDotTaskPop, setAddOnDotTaskPop] = useState(false);
  const [addScreenCaptureTaskPop, setAddScreenCaptureTaskPop] = useState(false);
  const [anyFileTaskPop, setAnyFileTaskPop] = useState(false);
  const [addMatrixTaskPop, setaddMatrixTaskPop] = useState(false);
  const [reviewAnImage, setReviewAnImage] = useState(false);
  const [reviewVideo, setReviewVideo] = useState(false);
  const [reviewAudio, setReviewAudio] = useState(false);
  const [renkItSortIt, setRenkItSortIt] = useState(false);
  const [deleteTaskPop, setDeleteTaskPop] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [taskId, setTaskId] = useState();
  const [taskName, setTaskName] = useState();
  const [allTasksList, setAllTasksList] = useState([]);
  const [allActivityParticipantList, setAllActivityParticipantList] = useState(
    []
  );
  const [loading, setLoading] = useState(false);

  const defaultFilters = {
    search: "",
    status: "",
  };

  const [filterState, setFilterState] = useState({ ...defaultFilters });

  const handleFilterChange = (changedFilter) => {
    let newFilters = {
      ...filterState,
      ...changedFilter,
    };
    setFilterState(newFilters);
    getTasks(1, newFilters);
  };

  const defaultFilters2 = {
    search: "",
    status: "",
  };

  const [filterState2, setFilterState2] = useState({ ...defaultFilters2 });

  const handleFilterChange2 = (changedFilter2) => {
    let newFilters2 = {
      ...filterState2,
      ...changedFilter2,
    };
    setFilterState2(newFilters2);
    getActivityParticipants(1, newFilters2);
  };

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 25,
    totalPages: 0,
    totalItems: 0,
  });

  const statusItemAry = [
    {
      name: "All",
      value: "All",
    },
    {
      name: "Completed",
      value: "Completed",
    },
    {
      name: "In Progress",
      value: "InProgress",
    },
    {
      name: "Pending",
      value: "Pending",
    },
  ];

  const initialTaskState = {
    workspace_id: workspaceState?.selectedWorkspace?._id,
    project_id: workspaceState?.selectedProject?._id,
    name: "",
    type: "",
    instructions: "",
    image: "",
    activity_id: activityId,
    blankType: "",
    response_types: {
      text_activity: false,
      image_activity: false,
      video_activity: false,
      audio_activity: false,
      screen_capuring: false,
    },
    task_settings: {
      enforce_word: false,
      from_word: "",
      to_word: "",
      customize_prompt: false,
      customize_prompt_value: "",
      text_format: false,
      response_required: false,
      skip_reason: false,
      limit_quantity: false,
      limit_quantity_value: "",
      stock_image_search: false,
      description_participant: false,
      make_description_complusory: false,
      total_attach_limit: false,
      attach_limit_from: "",
      attach_limit_to: "",
    },
    advance_settings: {
      limit: false,
      participant_option: "",
      participant_groups: [],
      participant_ids: [],
      customize_point: false,
      customize_point_award: "",
      task_guidance: false,
      task_guidance_value: "",
    },
    status: "active",
  };

  const [taskData, setTaskData] = useState(initialTaskState);

  const initialTaskSequence = {
    sequence: [
      {
        task_id: "",
        index: "",
      },
    ],
  };

  const [taskSequence, setTaskSequence] = useState(initialTaskSequence);

  const handleOptionIndex = (i) => {
    setOptionIndex(i);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const lineBar = 80;
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#4F33D1",
    },
  }));

  useEffect(() => {
    getActivityParticipants(1);
  }, []);

  useEffect(() => {
    if (reloadList) {
      getTasks(1);
      getActivityParticipants(1);
      setReloadList(false);
    }
  }, [reloadList]);

  // console.log(selectedTask);
  const handelAddTaskPopCoutinue = () => {
    // console.log("click", selectedTask);
    setAllDropVal4("Activity Options");
    if (selectedTask === "Text Task") {
      setAddTextTaskPop(true);
    } else if (selectedTask === "Image Upload Task") {
      setAddImageTaskPop(true);
    } else if (selectedTask === "Video Upload Task") {
      setAddVideoTaskPop(true);
    } else if (selectedTask === "Audio Upload Task") {
      setAddAudioTaskPop(true);
    } else if (selectedTask === "Fill It Out Task") {
      setAddFillOutTaskPop(true);
    } else if (selectedTask === "Mixed Media Upload Task") {
      setAddMixedMediaTaskPop(true);
    } else if (selectedTask === "Poll & Survey Task") {
      setAddPollSurveyTaskPop(true);
    } else if (selectedTask === "On-the-Dot Task") {
      setAddOnDotTaskPop(true);
    } else if (selectedTask === "Review an Image Task") {
      setReviewAnImage(true);
    } else if (selectedTask === "Review a Video Task") {
      setReviewVideo(true);
    } else if (selectedTask === "Review an Audio Task") {
      setReviewAudio(true);
    } else if (selectedTask === "Screen Capture Task") {
      setAddScreenCaptureTaskPop(true);
    } else if (selectedTask === "Any File Upload Task") {
      setAnyFileTaskPop(true);
    } else if (selectedTask === "Matrix Task") {
      setaddMatrixTaskPop(true);
    } else if (selectedTask === "Rank-it & Sort-it Task") {
      setRenkItSortIt(true);
    }
  };

  const handelAddTaskPopBack = () => {
    setAllDropVal4("Add Tasks");
    setAddTextTaskPop(false);
    setAddImageTaskPop(false);
    setAddVideoTaskPop(false);
    setAddAudioTaskPop(false);
    setAddFillOutTaskPop(false);
    setAddMixedMediaTaskPop(false);
    setAddPollSurveyTaskPop(false);
    setAddOnDotTaskPop(false);
    setAddScreenCaptureTaskPop(false);
    setAnyFileTaskPop(false);
    setaddMatrixTaskPop(false);
    setReviewAnImage(false);
    setReviewVideo(false);
    setReviewAudio(false);
    setRenkItSortIt(false);
  };

  useEffect(() => {
    printItemsByState(participants, currentPage);
  }, [currentPage]);

  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  const getTasks = async (curPage, filters = filterState) => {
    return await axios
      .get(
        API_BASE_URL +
          `/researchers/tasks?page=${curPage}&search=${filters.search}&workspace_id=${workspaceState?.selectedWorkspace?._id}&status=${filters.status}&project_id=${workspaceState?.selectedProject?._id}&activity_id=${activityId}`
      )
      .then((res) => {
        //console.log("=============Task Res======", res);
        if (res.success) {
          setAllTasksList(res.data.data);
          const updatedArray = res.data.data.map((item) => ({
            task_id: item._id,
            index: item.sequence_index,
          }));
          setTaskSequence({ sequence: updatedArray });
        }
      });
  };

  useEffect(() => {
    const getTasks = async (curPage, filters = filterState) => {
      try {
        setLoading(false);
        const response = await axios.get(
          `/researchers/tasks?page=${curPage}&search=${filters.search}&workspace_id=${workspaceState?.selectedWorkspace?._id}&status=${filters.status}&project_id=${workspaceState?.selectedProject?._id}&activity_id=${activityId}`
        );
        if (response.success) {
          setAllTasksList(response.data.data);
          const updatedArray = response.data.data.map((item) => ({
            task_id: item._id,
            index: item.sequence_index,
          }));
          setTaskSequence({ sequence: updatedArray });
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(true);
      }
    };
    if (activityId) {
      getTasks(1);
    }
  }, [activityId]);

  const getActivityParticipants = async (curPage, filters = filterState) => {
    return await axios
      .get(
        API_BASE_URL +
          `/researchers/activity-participant/${workspaceState?.selectedProject?._id}?page=${curPage}&search=${filters.search}&status=${filters.status}`
      )
      .then((res) => {
        // console.log("=============Activity Participant Res======", res);
        if (res.success) {
          setAllActivityParticipantList(res.data.data);

          setPaginationData({
            currentPage: res.data.pagination.currentPage,
            itemsPerPage: res.data.pagination.itemsPerPage,
            totalPages: res.data.pagination.totalPages,
            totalItems: res.data.pagination.totalItems,
          });
        }
      });
  };

  const dateTxt = (sDate) => {
    const rSdate = sDate ? formatDate(sDate, "MMM DD YYYY, h:mm:ss a") : "####";
    return `${rSdate}`;
  };

  const onActivityParticipantPageChange = (pageNum) => {
    getTasks(pageNum);
  };
  //

  return (
    <>
      <AddTaskPop
        allDropVal4={allDropVal4}
        setAllDropVal4={setAllDropVal4}
        selectedTask={selectedTask}
        setSelectedTask={setSelectedTask}
        handelAddTaskPopCoutinue={handelAddTaskPopCoutinue}
        taskData={taskData}
        setTaskData={setTaskData}
        initialTaskState={initialTaskState}
      />

      <RearrangeTaskPop
        allDropVal4={allDropVal4}
        setAllDropVal4={setAllDropVal4}
        allTasksList={allTasksList}
        setAllTasksList={setAllTasksList}
        workspaceState={workspaceState}
        taskSequence={taskSequence}
        setTaskSequence={setTaskSequence} 
        activityId={activityId}
      />
      <GroupRearrangeTaskPop
        allDropVal4={allDropVal4}
        setAllDropVal4={setAllDropVal4}
        allTasksList={allTasksList}
        setAllTasksList={setAllTasksList}
        workspaceState={workspaceState} 
        activityId={activityId}
      />
      <Box className="ResearchersContainer ">
        {/* side menu */}
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />

        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>

            <Box className="AppNavBtnBox">
              {authState?.wsUserType == USER_TYPE_RESEARCHER ||
              authState?.wsUserType == USER_TYPE_COLLABORATOR ? (
                <Box sx={{ width: "30%", minWidth: "196px" }}>
                  <AllDrop5
                    drop={allDrop4}
                    setDrop={setAllDrop4}
                    dropVal={allDropVal4}
                    serDropVal={setAllDropVal4}
                  />
                </Box>
              ) : (
                ""
              )}
              <AppAddBtn
                buttonText={t("commonTxt.previewActivityBtn")}
                onClick={() => Navigate("/activities/preview")}
              />
            </Box>
          </Box>

          {/* Page container */}
          <Box className="researchersMainBox pageContainer">
            <Box
              className={
                statusDropVal == "InProgress"
                  ? "ActivityTaslContainer inprogress"
                  : "ActivityTaslContainer"
              }
            >
              {allTasksList?.length > 0 && loading ? (
                <Box>
                  <ParticipantGroupPop
                    participantGroupPop={participantGroupPop}
                    setParticipantGroupPop={setParticipantGroupPop}
                  />

                  {/* <AddTextTaskPop
                setAllDropVal4={setAllDropVal4}
                addTextTaskPop={addTextTaskPop}
                setAddTextTaskPop={setAddTextTaskPop}
                handelAddTaskPopBack={handelAddTaskPopBack}
              /> */}

                  {/* <AddImageTaskPop
                setAllDropVal4={setAllDropVal4}
                addImageTaskPop={addImageTaskPop}
                setAddImageTaskPop={setAddImageTaskPop}
                handelAddTaskPopBack={handelAddTaskPopBack}
              /> */}

                  {/* <AddVideoTaskPop
                setAllDropVal4={setAllDropVal4}
                addVideoTaskPop={addVideoTaskPop}
                setAddVideoTaskPop={setAddVideoTaskPop}
                handelAddTaskPopBack={handelAddTaskPopBack}
              /> */}

                  {/* <AddAudioTaskPop
                setAllDropVal4={setAllDropVal4}
                addAudioTaskPop={addAudioTaskPop}
                setAddAudioTaskPop={setAddAudioTaskPop}
                handelAddTaskPopBack={handelAddTaskPopBack}
              /> */}

                  {/* <AddFillOutTaskPop
                setAllDropVal4={setAllDropVal4}
                addFillOutTaskPop={addFillOutTaskPop}
                setAddFillOutTaskPop={setAddFillOutTaskPop}
                handelAddTaskPopBack={handelAddTaskPopBack}
              /> */}

                  {/* <AddMixedMediaTaskPop
                setAllDropVal4={setAllDropVal4}
                addMixedMediaTaskPop={addMixedMediaTaskPop}
                setAddMixedMediaTaskPop={setAddMixedMediaTaskPop}
                handelAddTaskPopBack={handelAddTaskPopBack}
              /> */}

                  <AddPollSurveyTaskPop
                    setAllDropVal4={setAllDropVal4}
                    addPollSurveyTaskPop={addPollSurveyTaskPop}
                    setAddPollSurveyTaskPop={setAddPollSurveyTaskPop}
                    handelAddTaskPopBack={handelAddTaskPopBack}
                  />

                  <AddOnDotTaskPop
                    setAllDropVal4={setAllDropVal4}
                    addOnDotTaskPop={addOnDotTaskPop}
                    setAddOnDotTaskPop={setAddOnDotTaskPop}
                    handelAddTaskPopBack={handelAddTaskPopBack}
                  />

                  {/* <AddScreenCaptureTaskPop
                setAllDropVal4={setAllDropVal4}
                addScreenCaptureTaskPop={addScreenCaptureTaskPop}
                setAddScreenCaptureTaskPop={setAddScreenCaptureTaskPop}
                handelAddTaskPopBack={handelAddTaskPopBack}
              /> */}

                  {/* <AnyFileTaskPop
                setAllDropVal4={setAllDropVal4}
                anyFileTaskPop={anyFileTaskPop}
                setAnyFileTaskPop={setAnyFileTaskPop}
                handelAddTaskPopBack={handelAddTaskPopBack}
              /> */}

                  <AddMatrixTaskPop
                    setAllDropVal4={setAllDropVal4}
                    addMatrixTaskPop={addMatrixTaskPop}
                    setaddMatrixTaskPop={setaddMatrixTaskPop}
                    handelAddTaskPopBack={handelAddTaskPopBack}
                  />

                  {/* <ReviewAnImagePop
                    reviewAnImage={reviewAnImage}
                    setReviewAnImage={setReviewAnImage}
                    handelAddTaskPopBack={handelAddTaskPopBack}
                  /> */}
                  <ReviewVideoPop
                    reviewVideo={reviewVideo}
                    setReviewVideo={setReviewVideo}
                    handelAddTaskPopBack={handelAddTaskPopBack}
                  />
                  <ReviewAudioPop
                    reviewAudio={reviewAudio}
                    setReviewAudio={setReviewAudio}
                    handelAddTaskPopBack={handelAddTaskPopBack}
                  />
                  <RenkItSortItPop
                    renkItSortIt={renkItSortIt}
                    setRenkItSortIt={setRenkItSortIt}
                    handelAddTaskPopBack={handelAddTaskPopBack}
                  />
                  <DeleteTaskPop
                    deleteTaskPop={deleteTaskPop}
                    setDeleteTaskPop={setDeleteTaskPop}
                    setReloadList={setReloadList}
                    taskId={taskId}
                    taskName={taskName}
                  />
                  <Box
                    width="74px"
                    onClick={() => Navigate("/activities")}
                    className="openBtn"
                  >
                    <img src={backArrow} />
                    <Typography className="neonBackText">
                      {t("commonTxt.backBtn")}
                    </Typography>
                  </Box>

                  <Box className="taskNavSBox">
                    <Typography className="pageHeaderText">
                      {t("activityTasksPage.activityTasksHead")}
                    </Typography>
                    <Box sx={{ width: "320px", height: "40px" }}>
                      <SearchBar
                        placeholder={t(
                          "activityTasksPage.searchTaskPlaceholder"
                        )}
                        handleFilterChange={handleFilterChange}
                        val={filterState}
                      />
                    </Box>
                  </Box>

                  <Box className="sliderBox">
                    <Slider
                      Task={allTasksList}
                      SetEntiteActivityPage={SetEntiteActivityPage}
                      setDeleteTaskPop={setDeleteTaskPop}
                      setTaskId={setTaskId}
                      setTaskName={setTaskName}
                    />
                  </Box>
                </Box>
              ) : (
                <Box mb={2}>
                  <Box
                    width="74px"
                    onClick={() => Navigate("/activities")}
                    className="openBtn"
                  >
                    <img src={backArrow} />
                    <Typography className="neonBackText">
                      {t("commonTxt.backBtn")}
                    </Typography>
                  </Box>
                  <Box className="taskNavSBox">
                    <Typography className="pageHeaderText">
                      {t("activityTasksPage.activityTasksHead")}
                    </Typography>
                  </Box>
                  <NoTaskBg />
                </Box>
              )}
              <Box className="workSpaceListBox">
                <Box className="lineBar">
                  <Typography>{lineBar}%</Typography>
                  <BorderLinearProgress variant="determinate" value={lineBar} />
                </Box>
                <Typography className="lineBarSubText">
                  8 {t("ActivitiesInnerPage.of")} 10{" "}
                  {t("ActivitiesInnerPage.responsesRecieved")}
                </Typography>

                <Box className="taskTable">
                  <Box className="taskTabelNav">
                    <Box
                      onClick={() => setTaskNav(0)}
                      className={
                        taskNav === 0
                          ? "taskTabelNavItem taskTabelNavItemActive"
                          : "taskTabelNavItem"
                      }
                    >
                      <p>{t("LiveInterview.Participant")}</p>
                    </Box>
                    <Box
                      onClick={() => setTaskNav(1)}
                      className={
                        taskNav === 1
                          ? "taskTabelNavItem taskTabelNavItemActive"
                          : "taskTabelNavItem"
                      }
                    >
                      <p>{t("commonTxt.Responses")}</p>
                    </Box>
                  </Box>

                  {/* -----------------------------Participant------------------------- */}
                  <Box
                    sx={{ display: taskNav === 0 ? "block" : "none" }}
                    className="ActiveTaskSectionBox"
                  >
                    {allActivityParticipantList?.length > 0 ? (
                      <Box>
                        <Box
                          mt={2}
                          className="workListNavItem workListNavItemMobile"
                        >
                          <Typography className="pageHeaderText">
                            {t("activityTasksPage.listofParticipantsHead")} (
                            {allActivityParticipantList?.length})
                          </Typography>
                        </Box>
                        <Box mt={2} className="workListNav">
                          <Box className="workListNavItem workListNavItemDesktop">
                            <Typography className="pageHeaderText">
                              {t("activityTasksPage.listofParticipantsHead")} (
                              {allActivityParticipantList?.length})
                            </Typography>
                          </Box>
                          <Box
                            sx={{ minWidth: "200px" }}
                            className="workListNavItem workListSearchBar"
                          >
                            <SearchBar
                              placeholder={"Search Participant..."}
                              handleFilterChange={handleFilterChange2}
                              val={filterState2}
                            />
                          </Box>
                          <Box
                            sx={{ minWidth: "135px" }}
                            className="workListNavItem workshotList"
                          >
                            <Status
                              setStatusDrop={setStatusDrop}
                              statusDrop={statusDrop}
                              setStatusDropVal={setStatusDropVal}
                              statusDropVal={statusDropVal}
                              label=" "
                              itemAry={statusItemAry}
                            />
                          </Box>

                          {statusDropVal == "InProgress" && (
                            <Box
                              sx={{ minWidth: "156px" }}
                              className="workListNavItem "
                            >
                              <Box className="actTNavBtn removeProjectBtn">
                                {viewProfileIcon}
                                <Typography>
                                  {t("activityTasksPage.removeProject")}
                                </Typography>
                              </Box>
                            </Box>
                          )}

                          <Box
                            sx={{ minWidth: "156px" }}
                            className="workListNavItem"
                          >
                            <Box className="actTNavBtn">
                              <img src={sendIcon} />
                              <Typography>
                                {t("activityTasksPage.messageAll")}
                              </Typography>
                            </Box>
                          </Box>

                          <Box className="workListNavItem">
                            <Box
                              className="actTNavBtn"
                              onClick={() => setParticipantGroupPop(true)}
                            >
                              <img src={groupIcon} />
                              <Typography>{t("commonTxt.groupBtn")}</Typography>
                            </Box>
                          </Box>
                        </Box>

                        <Box className="workListBox">
                          <Box className="haderRow" minWidth={"1111px"}>
                            <Box
                              // minWidth={"290px"}
                              minWidth={"19%"}
                              className="Tabelsel TabelStatesel"
                            >
                              <Typography>
                                {t("projectDirectory.tblName")}
                              </Typography>
                            </Box>
                            <Box
                              //  minWidth={"130px"}
                              minWidth={"14%"}
                              className="Tabelsel"
                            >
                              <Typography>
                                {t("recapeVideoReview.tblResponses")}
                              </Typography>
                            </Box>
                            <Box minWidth={"14%"} className="Tabelsel">
                              <Typography>
                                {t("projectDirectory.tblStatus")}
                              </Typography>
                            </Box>

                            <Box minWidth={"10%"} className="Tabelsel">
                              <Typography>
                                {t("activityTasksPage.tblProgress")}
                              </Typography>
                            </Box>
                            <Box minWidth={"9%"} className="Tabelsel">
                              <Typography>
                                {t("recapPollMultiPage.tblExcerpts")}
                              </Typography>
                            </Box>
                            <Box
                              minWidth={"14%"}
                              className="Tabelsel statusSel"
                            >
                              <Typography>
                                {t("projectReview.tblReviewed")}
                              </Typography>
                            </Box>
                            <Box
                              minWidth={"18%"}
                              className="Tabelsel statusSel"
                            >
                              <Typography>
                                {t("recapeVideoReview.tblLastEdit")}
                              </Typography>
                            </Box>
                          </Box>
                          {allActivityParticipantList.length > 0 &&
                            allActivityParticipantList?.map((el, index) => (
                              <Box
                                key={index}
                                className="TabelRow"
                                minWidth={"1111px"}
                              >
                                <Box
                                  minWidth={"19%"}
                                  className="Tabelsel navFsel navtable"
                                >
                                  <Box
                                    id="dot"
                                    onClick={() => {
                                      setWorkspaceOption(!workSpaceOption);
                                      setOptionIndex(index);
                                    }}
                                    className={
                                      workSpaceOption && index === optionIndex
                                        ? "dot dotActive"
                                        : "dot"
                                    }
                                  >
                                    {
                                      <Box
                                        sx={{
                                          display:
                                            workSpaceOption &&
                                            index === optionIndex
                                              ? "flex"
                                              : "none",
                                        }}
                                        className="optionPop"
                                      >
                                        <Box
                                          onClick={() =>
                                            Navigate(
                                              `/view-participant-profile/${el?._id}`
                                            )
                                          }
                                          className="optionPopItem"
                                        >
                                          {viewProfileIcon}
                                          <Typography>
                                            {t(
                                              "recapPollMultiPage.viewProfile"
                                            )}
                                          </Typography>
                                        </Box>
                                        <Box className="optionPopItem">
                                          {markAllReviewedIcon}
                                          <Typography>
                                            {t(
                                              "recapPollMultiPage.markAllAsReviewed"
                                            )}
                                          </Typography>
                                        </Box>
                                        <Box className="optionPopItem">
                                          {sendMessageIcon}
                                          <Typography>
                                            {t(
                                              "recapPollMultiPage.sendMessage"
                                            )}
                                          </Typography>
                                        </Box>
                                        {authState?.wsUserType ==
                                          USER_TYPE_RESEARCHER ||
                                        authState?.wsUserType ==
                                          USER_TYPE_COLLABORATOR ? (
                                          <Box className="optionPopItem">
                                            {supportLoginIcon}
                                            <Typography>
                                              {t(
                                                "recapPollMultiPage.supportLogin"
                                              )}
                                            </Typography>
                                          </Box>
                                        ) : (
                                          ""
                                        )}
                                        {authState?.wsUserType ==
                                          USER_TYPE_RESEARCHER ||
                                        authState?.wsUserType ==
                                          USER_TYPE_COLLABORATOR ? (
                                          <Box className="optionPopItem">
                                            {actionLogIcon}
                                            <Typography>
                                              {t(
                                                "recapPollMultiPage.actionLog"
                                              )}
                                            </Typography>
                                          </Box>
                                        ) : (
                                          ""
                                        )}
                                        <Box className="optionPopItem">
                                          {exceprtsIcon}
                                          <Typography>
                                            {t("recapPollMultiPage.excerpts")}
                                          </Typography>
                                        </Box>
                                        <Box className="optionPopItem">
                                          {reportsIcon}
                                          <Typography>
                                            {t("recapPollMultiPage.reports")}
                                          </Typography>
                                        </Box>
                                        <Box className="optionPopItem transcripticon">
                                          {transcriptIcon}
                                          <Typography>
                                            {t(
                                              "recapPollMultiPage.transcripts"
                                            )}
                                          </Typography>
                                        </Box>
                                        {authState?.wsUserType ==
                                          USER_TYPE_RESEARCHER ||
                                        authState?.wsUserType ==
                                          USER_TYPE_COLLABORATOR ? (
                                          <Box className="optionPopItem">
                                            {trashBlack}
                                            <Typography>
                                              {t(
                                                "recapPollMultiPage.deleteAllResponses"
                                              )}
                                            </Typography>
                                          </Box>
                                        ) : (
                                          ""
                                        )}
                                      </Box>
                                    }
                                  </Box>
                                  <Box
                                    onClick={() => handleOptionIndex(index)}
                                    className="tabelAvatar"
                                  >
                                    <img src={viewImage(el.profile_pic)} />
                                  </Box>
                                  <Typography
                                    sx={{ cursor: "pointer", width: "95%" }}
                                  >
                                    {el.name}
                                  </Typography>
                                </Box>
                                <Box
                                  minWidth={"14%"}
                                  className="Tabelsel Tabelcentersel viewLink"
                                >
                                  {authState?.wsUserType ==
                                    USER_TYPE_RESEARCHER ||
                                  authState?.wsUserType ==
                                    USER_TYPE_COLLABORATOR ? (
                                    <Typography
                                      onClick={() =>
                                        Navigate("/activities/task/view")
                                      }
                                    >
                                      View
                                    </Typography>
                                  ) : (
                                    <Typography
                                      onClick={() =>
                                        Navigate(
                                          "/activities/participant-response-view"
                                        )
                                      }
                                    >
                                      View
                                    </Typography>
                                  )}
                                </Box>

                                <Box
                                  minWidth={"14%"}
                                  className={
                                    el.status === "Completed"
                                      ? "Tabelsel adminTabDrop Tabelcentersel statusBtnBoxSuccess"
                                      : "Tabelsel adminTabDrop statusBtnBoxError "
                                  }
                                >
                                  <Typography>{el.status}</Typography>
                                </Box>

                                <Box
                                  minWidth={"10%"}
                                  className="Tabelsel Tabelcentersel"
                                >
                                  {el.status === "Completed"
                                    ? GreenDot
                                    : RedDot}
                                  <Typography pl={1}>3</Typography>
                                </Box>
                                <Box
                                  minWidth={"9%"}
                                  className="Tabelsel Tabelcentersel"
                                >
                                  <Typography>1</Typography>
                                </Box>
                                <Box
                                  minWidth={"14%"}
                                  className="Tabelsel statusSel Tabelcentersel"
                                >
                                  {/* <Typography>{el.reviewed}</Typography> */}

                                  {el.status === "active" ? (
                                    <img src={tik1} />
                                  ) : el.status ===
                                    "empty" ? null : el.status === "error" ? (
                                    <img src={redcircle} />
                                  ) : null}
                                </Box>
                                <Box
                                  minWidth={"18%"}
                                  className="Tabelsel statusSel Tabelcentersel"
                                >
                                  <Typography>
                                    {dateTxt(el.updated_at)}
                                  </Typography>
                                </Box>
                              </Box>
                            ))}
                        </Box>

                        <Box className="pagination">
                          <Pagination
                            paginationData={paginationData}
                            onPageChange={onActivityParticipantPageChange}
                          />
                        </Box>
                      </Box>
                    ) : (
                      <Box>
                        <Box className="workListNavItem workListNavItemMobile noParticipantList">
                          <Typography className="pageHeaderText">
                            {t("activityTasksPage.listofParticipantsHead")} (0)
                          </Typography>
                        </Box>
                        <Box mt={3} className="adminBGTextBox">
                          <Typography className="adminSubHeaderText">
                            It seems like no participant has been added or
                            assigned to this activity yet. Please go to “Project
                            Management” to add/assign participants.
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>

                  {/* -------===========--------Responses------======------------ */}

                  <Box
                    sx={{ display: taskNav === 1 ? "block" : "none" }}
                    className="ActiveTaskSectionBox"
                  >
                    <Box className="responsesHeaderBox">
                      <Typography className="mapText">
                        {t("commonTxt.Responses")} (8)
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "24px",
                        }}
                      >
                        <Box className="actTNavBtn">
                          <img src={cott} />
                          <Typography>
                            {t("excerptReport.excerptsHead")}
                          </Typography>
                        </Box>

                        <Box
                          sx={{ minWidth: "130px" }}
                          className="workListNavItem workshotList"
                        >
                          <Status
                            setStatusDrop={setStatusDrop2}
                            statusDrop={statusDrop2}
                            setStatusDropVal={setStatusDropVal2}
                            statusDropVal={statusDropVal2}
                            label=" "
                          />
                        </Box>

                        <Box className="actTNavBtn">
                          <img src={groupIcon} />
                          <Typography>{t("commonTxt.groupBtn")}</Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box className="responsesNav">
                      <Box
                        className={
                          responsesNav === "Thread"
                            ? "responsesNavItem responsesNavItemActive"
                            : "responsesNavItem"
                        }
                        onClick={() => {
                          setResponsesNav("Thread");
                          setResponseExpression(true);
                        }}
                      >
                        <Typography>
                          {t(
                            "viewParticipantProfile.viewParticipantProfileNav1"
                          )}
                        </Typography>
                      </Box>
                      <Box
                        className={
                          responsesNav === "Expressions"
                            ? "responsesNavItem responsesNavItemActive"
                            : "responsesNavItem"
                        }
                        onClick={() => setResponsesNav("Expressions")}
                      >
                        <Typography>
                          {t(
                            "viewParticipantProfile.viewParticipantProfileNav2"
                          )}
                        </Typography>
                      </Box>
                      <Box
                        className={
                          responsesNav === "Images"
                            ? "responsesNavItem responsesNavItemActive"
                            : "responsesNavItem"
                        }
                        onClick={() => setResponsesNav("Images")}
                      >
                        <Typography>
                          {t(
                            "viewParticipantProfile.viewParticipantProfileNav3"
                          )}
                        </Typography>
                      </Box>
                      <Box
                        className={
                          responsesNav === "Videos"
                            ? "responsesNavItem responsesNavItemActive"
                            : "responsesNavItem"
                        }
                        onClick={() => setResponsesNav("Videos")}
                      >
                        <Typography>
                          {t(
                            "viewParticipantProfile.viewParticipantProfileNav4"
                          )}
                        </Typography>
                      </Box>
                      <Box
                        className={
                          responsesNav === "Audios"
                            ? "responsesNavItem responsesNavItemActive"
                            : "responsesNavItem"
                        }
                        onClick={() => setResponsesNav("Audios")}
                      >
                        <Typography>
                          {t(
                            "viewParticipantProfile.viewParticipantProfileNav5"
                          )}
                        </Typography>
                      </Box>
                    </Box>
                    {/* -----------Thread------------- */}
                    <Box
                      display={responsesNav === "Thread" ? "block" : "none"}
                      className="responsesSection ThreadBox"
                    >
                      <Thread />
                    </Box>
                    {/* -----------Expressions------------- */}
                    <Box
                      display={
                        responsesNav === "Expressions" ? "block" : "none"
                      }
                      className="responsesSection "
                    >
                      <ExpressionsComponent
                        responsesNav={responsesNav}
                        responseExpression={responseExpression}
                      />
                    </Box>
                    {/* -----------Images------------- */}
                    <Box
                      display={responsesNav === "Images" ? "block" : "none"}
                      className="responsesSection "
                    >
                      <Images />
                    </Box>
                    {/* -----------Videos------------- */}
                    <Box
                      display={responsesNav === "Videos" ? "block" : "none"}
                      className="responsesSection "
                    >
                      <Videos />
                    </Box>
                    {/* -----------Audios------------- */}
                    <Box
                      display={responsesNav === "Audios" ? "block" : "none"}
                      className="responsesSection "
                    >
                      <Audio />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
