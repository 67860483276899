import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Avatar,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import "./style.css";

import clogsvg from "../../../Assets/Images/New folder/Icon TeraPage Transparent mix dark 2.svg";
import SClolo from "../../../Assets/Images/TeraPage Transparent mix black.png";
import DashboardIcon from "../../../Assets/Images/dashboard.png";
import dataReportIcon from "../../../Assets/Images/dataReportIcon.png";
import NotificationsIcon from "../../../Assets/Images/bell.png";
import DirectMessageIcon from "../../../Assets/Images/AdArrowIcon.png";
import workSpaceIcon from "../../../Assets/Images/workspaceMenuIcon.png";

import DummyUser from "../../../Assets/Images/user-dummy.png";
import HomeIcon from "../../../Assets/Images/home.png";
import ActivitiesIcon from "../../../Assets/Images/map.png";
import ConversationsIcon from "../../../Assets/Images/conversations.png";
import RecapIcon from "../../../Assets/Images/recap.png";
import ProjectManagementIcon from "../../../Assets/Images/project-management.png";
import OutdoorJourneyIcon from "../../../Assets/Images/outdoorjourney.png";
import AvatarOne from "../../../Assets/Images/UserHomeAvatar.png";
import WAIcon from "../../../Assets/Images/icon-setting01.svg";
import ASIcon from "../../../Assets/Images/icon-setting02.svg";
import HCIcon from "../../../Assets/Images/icon-setting03.svg";
import LIcon from "../../../Assets/Images/icon-setting04.svg";
import publicationIocnBlack from "../../../Assets/Images/PublictionIconBlack.png";
import publicationIocnActive from "../../../Assets/Images/publicationIconActive.png";

//Data

//components
import AiIcon from "../../../Assets/Images/AiIcon1.png";
import { SearchBar, GoTop, GoBottom } from "../../AppTools/AppTool";
import { useAppDispatch, useAppSelector } from "../../../lib/store/store";
import { logout } from "../../../Services/auth";
import { viewImage } from "../../../lib/helpers";
import {
  USER_TYPE_COLLABORATOR,
  USER_TYPE_RESEARCHER,
  USER_TYPE_OBSERVER,
} from "../../../lib/constants";
import axios from "../../../lib/axios";
import { setSelectedProject } from "../../../lib/store/features/workspaceSlice";

export default function SideMenu({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
}) {
  const PageContainer = document.getElementById("sideMenu_box");
  const currentUrl = new URL(window.location.href).pathname;
  const Navigate = useNavigate();
  const [profilePop, setProfilePop] = useState(false);
  const [activitySequence, setActivitySequence] = useState(false);
  const [ActivityTasksPage, setActivityTasksPage] = useState(false);
  const [allDropVal3, setAllDropVal3] = useState("Page Options");
  let dispatch = useAppDispatch();
  let authState = useAppSelector((state) => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace);
  const [projectsListData, setProjectsListData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [projectDropVal, setProjectDropVal] = useState("");

  const switchToOtherComponent = () => {
    setActivityTasksPage(false);
  };

  const handleMainOpenTabe = (Url) => {
    Navigate(Url);
  };

  useEffect(() => {
    getProjects();
  }, []);

  // const handleFilterProjects = (val) => {
  //   if (typeof handleFilterChange !== "undefined") {
  //     handleFilterChange({
  //       project_ids: val,
  //     });
  //   }
  // };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "200px",
        overflowY: "auto",
      },
    },
  };

  useEffect(() => {
    if (workspaceState?.selectedProject != null) {
      setProjectDropVal(workspaceState?.selectedProject?._id);
    }
  }, [workspaceState]);

  const handleSelectChange = (event) => {
    const project = projectData.find((el) => el._id == event.target.value);
    dispatch(setSelectedProject(project));
    setProjectDropVal(event.target.value);
  };

  const getProjects = async () => {
    try {
      const res = await axios.get(
        `/researchers/projects?workspace_id=${workspaceState?.selectedWorkspace?._id}`
      );
      if (res.status) {
        setProjectData(res.data.data);
        if (workspaceState.selectedProject == null) {
          dispatch(setSelectedProject(res.data.data[0]));
        }
      }
    } catch (error) {
      console.error("Error fetching project lists", error);
    }
  };

  return (
    <>
      <Box
        id="sideMenu_box"
        className={
          slideMenu
            ? "sideMenu researcherSideMenu sideMenuActive"
            : "sideMenu researcherSideMenu"
        }
      >
        <Box onClick={() => Navigate("/")} className="clogBox">
          {slideMenu ? (
            <img style={{ width: "176.442px", height: "20px" }} src={clogsvg} />
          ) : (
            <img style={{ width: "30px" }} src={SClolo} />
          )}
        </Box>

        <Box
          onClick={() => {
            handleMainOpenTabe("/users/dashboard");
            switchToOtherComponent();
          }}
          className={
            currentUrl === "/users/dashboard"
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
          title={slideMenu ? "" : "Dashboard"}
        >
          <img src={DashboardIcon} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Dashboard
          </Typography>
        </Box>
        {authState?.wsUserType == USER_TYPE_RESEARCHER && (
          <Box
            onClick={() => {
              handleMainOpenTabe("/researchers/Publications");
              switchToOtherComponent();
            }}
            className={
              currentUrl === "/researchers/Publications"
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
            title={slideMenu ? "" : "Research Publications"}
          >
            <img
              src={
                currentUrl === "/researchers/Publications"
                  ? publicationIocnActive
                  : publicationIocnBlack
              }
            />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Research Publications
            </Typography>
          </Box>
        )}

        {authState?.wsUserType == USER_TYPE_RESEARCHER && (
          <Box
            onClick={() => {
              handleMainOpenTabe("/researchers/data-report");
              switchToOtherComponent();
            }}
            className={
              currentUrl === "/researchers/data-report"
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
            title={slideMenu ? "" : "Data & Reports"}
          >
            <img src={dataReportIcon} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Data & Reports
            </Typography>
          </Box>
        )}

        <Box
          onClick={() => {
            handleMainOpenTabe("/notification");
            switchToOtherComponent();
          }}
          className={
            currentUrl === "/notification"
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
          title={slideMenu ? "" : "Notifications"}
        >
          <img src={NotificationsIcon} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Notifications
          </Typography>
        </Box>

        <Box
          onClick={() => {
            handleMainOpenTabe("/direct-messages");
            switchToOtherComponent();
          }}
          className={
            currentUrl === "/direct-messages"
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
          title={slideMenu ? "" : "Direct Message"}
        >
          <img src={DirectMessageIcon} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Direct Message
          </Typography>
        </Box>
        <Box
          sx={{ display: workspaceState.selectedWorkspace ? "block" : "none" }}
          className="navHrLine"
        ></Box>
        {workspaceState.selectedWorkspace != null && (
          <Box
            sx={{ display: workspaceState.selectedWorkspace ? "flex" : "none" }}
            onClick={() => {
              handleMainOpenTabe("/admin/workspace-management/splash");
              switchToOtherComponent();
            }}
            className="AdSideMenuItem AdSideMenuItemActive"
            title={slideMenu ? "" : "Workspace"}
          >
            <Box className="workspaceIcon">
              <img src={workSpaceIcon} />
            </Box>
            <Typography
              className="workspaceNavText"
              sx={{ display: slideMenu ? "block" : "none" }}
            >
              {workspaceState.selectedWorkspace?.name?.length > 15
                ? `${workspaceState.selectedWorkspace?.name.substring(
                    0,
                    15
                  )}...`
                : workspaceState.selectedWorkspace?.name}
            </Typography>
          </Box>
        )}

        {/* sub menu */}
        {workspaceState.selectedWorkspace != null &&
          (console.log(
            "workspaceState.selectedWorkspace",
            workspaceState.selectedWorkspace
          ),
          (
            <Box
              sx={{ display: workspaceDrop ? "flex" : "none" }}
              className="subNavMenu"
            >
              <Box sx={{ width: "85%" }} title={slideMenu ? "" : ""}>
                <FormControl fullWidth>
                  <InputLabel id="project-select-label">
                    Select Project
                  </InputLabel>
                  <Select
                    labelId="project-select-label"
                    id="project-select"
                    className="projectListSelect"
                    onChange={handleSelectChange}
                    value={projectDropVal}
                    MenuProps={MenuProps}
                    label="Select Project"
                  >
                    {projectData.map((project, index) => (
                      <MenuItem value={project._id} key={index}>
                        {project?.image ? (
                          <img
                            src={viewImage(project?.image)}
                            className="projectListImage"
                            alt=""
                          />
                        ) : (
                          <img src={OutdoorJourneyIcon} />
                        )}
                        {project.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box
                onClick={() => {
                  handleMainOpenTabe("/ws-management/workspace-home");
                  setAllDropVal3("Page Options");
                  switchToOtherComponent();
                }}
                className={
                  currentUrl === "/ws-management/workspace-home"
                    ? "AdSideMenuItem AdSideMenuItemActive"
                    : "AdSideMenuItem"
                }
                title={slideMenu ? "" : "Home"}
              >
                <img src={HomeIcon} />
                <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                  Home
                </Typography>
              </Box>
              {/* AI Integration page for researcher */}
              {authState?.wsUserType == USER_TYPE_RESEARCHER && (
                <Box
                  onClick={() => {
                    handleMainOpenTabe("/ws-management/aiintegration");
                  }}
                  className={
                    currentUrl === "/ws-management/aiintegration"
                      ? "AdSideMenuItem AdSideMenuItemActive"
                      : "AdSideMenuItem"
                  }
                  title={slideMenu ? "" : "AI Integration"}
                >
                  <img src={AiIcon} />
                  <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                    AI Integration
                  </Typography>
                </Box>
              )}

              <Box
                onClick={() => {
                  handleMainOpenTabe("/activities");
                  setActivitySequence(false);
                  switchToOtherComponent();
                }}
                className={
                  currentUrl === "/activities"
                    ? "AdSideMenuItem AdSideMenuItemActive"
                    : "AdSideMenuItem"
                }
                title={slideMenu ? "" : "Activities"}
              >
                <img src={ActivitiesIcon} />
                <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                  Activities
                </Typography>
              </Box>
              <Box
                onClick={() => handleMainOpenTabe("/conversation")}
                className={
                  currentUrl === "/conversation"
                    ? "AdSideMenuItem AdSideMenuItemActive"
                    : "AdSideMenuItem"
                }
                title={slideMenu ? "" : "Conversations"}
              >
                <img src={ConversationsIcon} />
                <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                  Conversations
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  handleMainOpenTabe("/recap");
                }}
                className={
                  currentUrl === "/recap"
                    ? "AdSideMenuItem AdSideMenuItemActive"
                    : "AdSideMenuItem"
                }
                title={slideMenu ? "" : "Recap"}
              >
                <img src={RecapIcon} />
                <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                  Recap
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  handleMainOpenTabe("/project-management");
                }}
                className={
                  currentUrl === "/project-management"
                    ? "AdSideMenuItem AdSideMenuItemActive"
                    : "AdSideMenuItem"
                }
                title={slideMenu ? "" : "Project Management"}
              >
                <img src={ProjectManagementIcon} />
                <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                  Project Management
                </Typography>
              </Box>
            </Box>
          ))}
        {/* sub menu end */}

        <Box
          onClick={() => setProfilePop(!profilePop)}
          className={slideMenu ? "setting " : "setting settingOnClose"}
          title={slideMenu ? "" : "Settings"}
        >
          <Avatar
            alt="Remy Sharp"
            src={AvatarOne}
            sx={{ width: 34, height: 34 }}
          />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Settings
          </Typography>

          {/* Profile pop */}
          <Box
            sx={{ display: profilePop ? "flex" : "none" }}
            className="sideProfilePop"
          >
            <Box className="profilePopR">
              <Box className="imgPP">
                {/* <img src={AvatarOne} /> */}
                {authState?.profile_pic == "undefined" ||
                authState?.profile_pic == null ? (
                  <img src={DummyUser} />
                ) : (
                  <img src={viewImage(authState?.profile_pic)} />
                )}
              </Box>
              <Box className="profileInfoP">
                <Typography className="ProNP">
                  {authState?.userProfile?.name}
                </Typography>
                <Typography className="ProEP">
                  {authState?.userProfile?.email}
                </Typography>
              </Box>
            </Box>
            {workspaceState.selectedWorkspace != null &&
              authState?.wsUserType == USER_TYPE_RESEARCHER && (
                <Box
                  className="sidePPitem"
                  onClick={() =>
                    Navigate("/workspace-administration/all-participants")
                  }
                >
                  <img src={WAIcon} />
                  <Typography>Workspace Administration</Typography>
                </Box>
              )}
            <Box
              className="sidePPitem"
              onClick={() => Navigate("/account-settings/personal-information")}
            >
              <img src={ASIcon} />
              <Typography>Account Settings</Typography>
            </Box>
            <Box className="sidePPitem">
              <img src={HCIcon} />
              <Typography>Help Center</Typography>
            </Box>
            <Box
              className="sidePPitem"
              onClick={() =>
                logout(Navigate, dispatch, authState?.userType, authState)
              }
            >
              <img src={LIcon} />
              <Typography>Logout</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
