import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import whitedropIcon from "../../../Assets/Images/Wchevron-down.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";
import exportIcon from "../../../Assets/Images/ExpoGroup.png";
import dropIcon from "../../../Assets/Images/chevron-downp.png";
import printIcon from "../../../Assets/Images/printIcon.png";
import arrow from "../../../Assets/Images/arrow.png";

import { useTranslation } from "react-i18next";

//component
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { Status } from "../../AppTools/AppTool";
import StackedBar from "../../../Components/AppTools/StackedBar";
import SideLineChart from "../../../Components/AppTools/SideLineChart";

//data
import { activityCompData,
    summarizedData,
    repActivitiesData, reportDropVal } from "../../../Assets/Data";

export default function ProjectTaskReport({
  dataReportTabe,
  setDataReportTabe,
  dropVal,
  projectSettingDrop,
  setProjectSettingDrop,
  projectSettingDropVal,
  setProjectSettingDropVal,
}) {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [identitiesDrop, setIdentitiesDrop] = useState(false);
  const [identitiesDropVal, setIdentitiesDropVal] = useState();

  const [rangeDrop4, setRangeDrop4] = useState(false);
  const [rangeDropVal4, setRangeDropVal4] = useState("All Tasks");

  const [contributionDrop4, setContributionDrop4] = useState(false);
  const [contributionDropVal4, setContributionDropVal4] = useState("All Time");
  const [currentPage, setCurrentPage] = useState(0);

  const exportItem = ["PNG", "JPEG", "PDF", "SVG"];

  const indentitiesData = [
    {
      name: "Full Name",
      value: "Full Name",
    },
    {
      name: "Username",
      value: "Username",
    },
    {
      name: "Unique ID",
      value: "Unique ID",
    },
  ];

  useEffect(() => {
    let selTab = searchParams.get("t");
    let selTab2 = searchParams.get("dr");
    if (selTab && selTab2 != null) {
      var query_string = selTab;
      query_string = query_string.replaceAll('_', ' ');
      query_string = query_string.replace(/(^\w|\s\w)/g, firstCharOfWord => firstCharOfWord.toUpperCase());
      console.log(query_string);
      if(selTab === "size_churn"){
        var query_string = "Size & Churn";
        setProjectSettingDropVal(query_string);
       setDataReportTabe(parseInt(selTab2));
       }
       else {
         setProjectSettingDropVal(query_string);
         setDataReportTabe(parseInt(selTab2));
       }
    }
    else if (selTab == null) {
      setDataReportTabe(parseInt(selTab2));
    }
  }, []);

  return (
    <>
      {/* -----------------------------------------------Project Task Report-------------------------------------- */}
      <Box
          sx={{
            display: projectSettingDropVal === "Tasks" ? "block" : "none",
          }}
          className="reportContainer integrationsContainer compositionSection"
        >
          <Box className="projectBtnBox">
            <Box className="inteBtndropBox">
              <Box
                className="projectManagementNavBtn"
                onClick={() => setProjectSettingDrop(!projectSettingDrop)}
              >
                <Typography className="DropBtnText">
                  {projectSettingDropVal || "Project State"}
                </Typography>
                <img
                  className="dropIcon"
                  src={whitedropIcon}
                  style={{
                    rotate: projectSettingDrop ? "180deg" : "0deg",
                    transition: "all 0.5s",
                    marginLeft: "10px",
                  }}
                />
                <Box
                  className="workspaceItemNavPop projectItemNavPop"
                  sx={{ display: projectSettingDrop ? "flex" : "none" }}
                >
                  {reportDropVal?.map((el, i) => (
                    <Box key={i}
                    onClick={() => {
                      setSearchParams({ dr:dataReportTabe,t: el.qstring });
                      setProjectSettingDropVal(el.title);
                    }}
                    >
                      <Typography>{el.title}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Typography className="pageHeaderText">{t("projectTaskReport.headerTxt")}</Typography>
          <Box className="participationReportDropSection">
            <Box className="inputBoxWlabel">
              <Typography>{t("projectTaskReport.taskDrop")}</Typography>
              <Box
                className="inputAppDrop"
                onClick={() => setRangeDrop4(!rangeDrop4)}
              >
                <Typography>{rangeDropVal4 || t("projectTaskReport.taskDropPlaceholder")}</Typography>
                <img
                  style={{ rotate: rangeDrop4 ? "180deg" : "0deg" }}
                  src={dropArrow}
                />
                {/* pop */}
                <Box
                  sx={{ height: rangeDrop4 ? "auto" : "0px" }}
                  className="statusPop"
                >
                  <Box
                    onClick={() => setRangeDropVal4("All Tasks")}
                    className="statusPopItem"
                  >
                    <Typography>{t("projectTaskReport.taskDropVal1")}</Typography>
                  </Box>
                  <Box
                    onClick={() => setRangeDropVal4("Subscribed")}
                    className="statusPopItem"
                  >
                    <Typography>{t("projectTaskReport.taskDropVal2")}</Typography>
                  </Box>
                  <Box
                    onClick={() => setRangeDropVal4("Unsubscribed")}
                    className="statusPopItem"
                  >
                    <Typography>{t("projectTaskReport.taskDropVal3")}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className="inputBoxWlabel">
              <Typography>{t("projectTaskReport.taskStartDateDrop")}</Typography>
              <Box
                className="inputAppDrop"
                onClick={() => setContributionDrop4(!contributionDrop4)}
              >
                <Typography>
                  {contributionDropVal4 || t("projectTaskReport.taskStartDateDropPlaceholder")}
                </Typography>
                <img
                  style={{ rotate: contributionDrop4 ? "180deg" : "0deg" }}
                  src={dropArrow}
                />
                {/* pop */}
                <Box
                  sx={{ height: contributionDrop4 ? "auto" : "0px" }}
                  className="statusPop"
                >
                  <Box
                    onClick={() => setContributionDropVal4("All time")}
                    className="statusPopItem"
                  >
                    <Typography>{t("projectTaskReport.taskStartDateDropVal1")}</Typography>
                  </Box>
                  
                </Box>
              </Box>
            </Box>
          </Box>

          <Box mt={5} className="redioGroupSection">
            <Typography className="psSubLabel">{t("projectParticipantReport.subHeaderTxt2")}</Typography>

            <Box className="projectRadioBoxSection">
              <Box className="ProjectRadioInput">
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                  defaultChecked
                />
                <Typography className="emailSettingLabel">
                {t("projectParticipantReport.participantAllParticipant")}
                </Typography>
              </Box>
              <Box className="ProjectRadioInput">
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                />
                <Typography className="emailSettingLabel">
                {t("projectParticipantReport.participantSelectGroup")}
                </Typography>
              </Box>
            </Box>

            <Box className="projectRadioBoxSection">
              <Box className="ProjectRadioInput">
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                />
                <Typography className="emailSettingLabel">
                {t("projectParticipantReport.participantSelectParticipant")}
                </Typography>
              </Box>
            </Box>

            <Box className="participantIdentities">
              <Typography>{t("projectParticipantReport.participantIdentity")}</Typography>
              <Status
                itemAry={indentitiesData}
                setStatusDrop={setIdentitiesDrop}
                statusDrop={identitiesDrop}
                setStatusDropVal={setIdentitiesDropVal}
                statusDropVal={identitiesDropVal}
                label={t("projectParticipantReport.participantIdentityPlaceholder")}
              />
            </Box>
          </Box>
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.resetBtn")} />
            <AppBtn buttonText={t("commonTxt.viewReportBtn")} />
          </Box>
        </Box>

        <Box
          mt={5}
          sx={{
            display: projectSettingDropVal === "Tasks" ? "block" : "none",
          }}
          className="reportContainer integrationsContainer compositionSection"
        >
          <Box mb={2} mt={3} className="tabelNav">
            <Box className="dropWheader">
              <Typography className="psSubLabel">{t("projectParticipantReport.headerTxt2")} </Typography>
              <Box className="ndrop">
                <Typography>5</Typography>
                <img src={dropIcon} />
              </Box>
            </Box>
            <Box className="repPrintBtnBox">
              <Box className="exporOpenBtn">
                <img src={printIcon} />
                <Typography className="verifyLink">{t("commonTxt.printLink")}</Typography>
              </Box>
              <Box className="exporOpenBtn">
                <img src={exportIcon} />
                <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
              </Box>
            </Box>
          </Box>
          <Box mt={5} >
          <Typography className="pageHeaderText">
          {t("projectTaskReport.taskCompletionHead")}
          </Typography>
          </Box>
          <Box className="lineChartBox">
            <SideLineChart />
          </Box>

          {/* table */}
          <Box mb={6} mt={4} className="workListBox">
            <Box className="haderRow" minWidth={"955px"}>
              <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectTaskReport.tblActivity")}</Typography>
              </Box>
              <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectTaskReport.tblTask")}</Typography>
              </Box>
              <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectTaskReport.tblPercentage")}</Typography>
              </Box>
            </Box>

            {activityCompData?.map((el, i) => (
              <Box key={i} className="TabelRow" minWidth={"955px"}>
                <Box minWidth={"33%"} className="Tabelsel navFsel navtable">
                  <Typography sx={{ cursor: "pointer", width: "95%" }}>
                    {" "}
                    {el.Activities}
                  </Typography>
                </Box>
                <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Tasks}</Typography>
                </Box>
                <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.Percentage}</Typography>
                </Box>
              </Box>
            ))}
          </Box>

          <Typography className="pageHeaderText">{t("projectParticipantReport.subHeaderTxt2")}</Typography>

          <Box className="sideLineChart">
            <StackedBar />
          </Box>

          <Box mb={2} mt={3} className="tabelNav">
            <Box className="dropWheader">
              <Typography className="psSubLabel">{t("projectTaskReport.summarizDataHead")} </Typography>
            </Box>
            <Box className="repPrintBtnBox">
              <Box className="exporOpenBtn">
                <img src={exportIcon} />
                <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
              </Box>
            </Box>
          </Box>

          {/* table */}
          <Box mb={6} mt={4} className="workListBox">
            <Box className="haderRow" minWidth={"1000px"}>
              <Box minWidth={"24%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectTaskReport.tblName")}</Typography>
              </Box>
              <Box minWidth={"16%"} className="Tabelsel Tabelcentersel">
                <Typography>{t("projectTaskReport.tblCompleted")}</Typography>
              </Box>
              <Box minWidth={"12%"} className="Tabelsel Tabelcentersel">
                <Typography>{t("projectTaskReport.tblInProgress")}</Typography>
              </Box>
              <Box minWidth={"16%"} className="Tabelsel Tabelcentersel">
                <Typography>{t("projectTaskReport.tblYetToBegin")}</Typography>
              </Box>
              <Box minWidth={"12%"} className="Tabelsel Tabelcentersel">
                <Typography>{t("projectTaskReport.tblTotal")}</Typography>
              </Box>
              <Box minWidth={"16%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectTaskReport.tblLastAction")}</Typography>
              </Box>
            </Box>

            {summarizedData?.map((el, i) => (
              <Box key={i} className="TabelRow" minWidth={"1000px"}>
                <Box minWidth={"24%"} className="Tabelsel navFsel navtable">
                  <Box className="tabelAvatar">
                    {" "}
                    <img src={el.img} />{" "}
                  </Box>
                  <Typography sx={{ cursor: "pointer", width: "95%" }}>
                    {" "}
                    {el.name}
                  </Typography>
                </Box>
                <Box minWidth={"16%"} className="Tabelsel Tabelcentersel">
                  <Typography>{el.Completed}</Typography>
                </Box>
                <Box minWidth={"12%"} className="Tabelsel Tabelcentersel">
                  <Typography>{el.InProgress}</Typography>
                </Box>
                <Box minWidth={"16%"} className="Tabelsel Tabelcentersel">
                  <Typography>{el.YettoBegin}</Typography>
                </Box>
                <Box minWidth={"12%"} className="Tabelsel Tabelcentersel">
                  <Typography>{el.Total}</Typography>
                </Box>
                <Box minWidth={"16%"} className="Tabelsel TabelStatesel">
                  <Typography>{el.LastAction}</Typography>
                </Box>
              </Box>
            ))}
          </Box>

          <Box mb={2} mt={3} className="tabelNav">
            <Box className="dropWheader">
              <Typography className="psSubLabel">{t("projectTaskReport.DataTaskHead")}</Typography>
            </Box>
            <Box className="repPrintBtnBox">
              <Box className="exporOpenBtn">
                <img src={exportIcon} />
                <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
              </Box>
            </Box>
          </Box>

          {/* table */}
          <Box mb={6} mt={4} className="workListBox">
            <Box className="haderRow" minWidth={"1000px"}>
              <Box minWidth={"20%"} className="Tabelsel TabelStatesel">
                <Typography>{t("projectTaskReport.tblName")}</Typography>
              </Box>
              <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                <Typography>1</Typography>
              </Box>
              <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                <Typography>2</Typography>
              </Box>
              <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                <Typography>3</Typography>
              </Box>
              <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                <Typography>4</Typography>
              </Box>
              <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                <Typography>5</Typography>
              </Box>
              <Box minWidth={"13%"} className="Tabelsel TabelStatesel">
                <Typography>6</Typography>
              </Box>
            </Box>

            {repActivitiesData?.map((el, i) => (
              <Box key={i} className="TabelRow" minWidth={"1000px"}>
                <Box minWidth={"20%"} className="Tabelsel navFsel navtable">
                  <Box className="tabelAvatar">
                    {" "}
                    <img src={el.img} />{" "}
                  </Box>
                  <Typography sx={{ cursor: "pointer", width: "95%" }}>
                    {" "}
                    {el.Name}
                  </Typography>
                </Box>
                <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                  {el.one ? (
                    <Box className="greenBox"></Box>
                  ) : (
                    <Box className="redBox"></Box>
                  )}
                </Box>
                <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                  {el.two ? (
                    <Box className="greenBox"></Box>
                  ) : (
                    <Box className="redBox"></Box>
                  )}
                </Box>
                <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                  {el.three ? (
                    <Box className="greenBox"></Box>
                  ) : (
                    <Box className="redBox"></Box>
                  )}
                </Box>
                <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                  {el.four ? (
                    <Box className="greenBox"></Box>
                  ) : (
                    <Box className="redBox"></Box>
                  )}
                </Box>
                <Box minWidth={"13%"} className="Tabelsel TabelStatesel">
                  {el.five ? (
                    <Box className="greenBox"></Box>
                  ) : (
                    <Box className="redBox"></Box>
                  )}
                </Box>
                <Box minWidth={"13%"} className="Tabelsel TabelStatesel">
                  {el.six ? (
                    <Box className="greenBox"></Box>
                  ) : (
                    <Box className="redBox"></Box>
                  )}
                </Box>
              </Box>
            ))}
          </Box>

          <Box className="colorIndicatorBox">
            <Box>
              <Box className="greenBox"></Box>
              <Typography>{t("commonTxt.completedStatus")}</Typography>
            </Box>
            <Box>
              <Box className="redBox"></Box>
              <Typography>{t("commonTxt.inProgressStatus")}</Typography>
            </Box>
            <Box>
              <Box className="redBox"></Box>
              <Typography>{t("commonTxt.yetToBeginStatus")}</Typography>
            </Box>
            <Box>
              <Box className="blackBox"></Box>
              <Typography>{t("commonTxt.notApplicableStatus")}</Typography>
            </Box>
          </Box>

          <Box className="pagenation">
            <Box className="tabelBtn">
              <img src={arrow} />
              <Typography>{t("commonTxt.previouspage")}</Typography>
            </Box>

            <Box className="pageNumberBox">
              <Box
                // onClick={() => setCurrentPage(0)}
                className={
                  currentPage === 0
                    ? "pageNumber pageNumberActive"
                    : "pageNumber"
                }
              >
                <p>1</p>
              </Box>
              <Box
                // onClick={() => setCurrentPage(1)}
                className={
                  currentPage === 1
                    ? "pageNumber pageNumberActive"
                    : "pageNumber"
                }
              >
                <p>2</p>
              </Box>
              <Box
                // onClick={() => setCurrentPage(2)}
                className={
                  currentPage === 2
                    ? "pageNumber pageNumberActive"
                    : "pageNumber"
                }
              >
                <p>3</p>
              </Box>
              <Box
                // onClick={() => setCurrentPage(3)}
                className={
                  currentPage === 3
                    ? "pageNumber pageNumberActive"
                    : "pageNumber"
                }
              >
                <p>4</p>
              </Box>
              <Box
                // onClick={() => setCurrentPage(4)}
                className={
                  currentPage === 4
                    ? "pageNumber pageNumberActive"
                    : "pageNumber"
                }
              >
                <p>5</p>
              </Box>
            </Box>

            <Box className="tabelBtn">
              <Typography>{t("commonTxt.nextPage")}</Typography>
              <img style={{ rotate: "180deg" }} src={arrow} />
            </Box>
          </Box>
        </Box>

      {/* -----------------------------------------------Project Task Report-------------------------------------- */}
    </>
  );
}
