import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
//images
import backArrow from "../../../Assets/Images/noun-back.png";
import cott from "../../../Assets/Images/cott.png";
import groupIcon from "../../../Assets/Images/groupsIcon.png";
import exportIcon from "../../../Assets/Images/exportIcon.png";
import dropIcon from "../../../Assets/Images/chevron-down.png";
import arrow from "../../../Assets/Images/arrow.png";
import Option2LIcon from "../../../Assets/Images/Option2LIcon.png";
import editIcon from "../../../Assets/Images/edit 1.png";
import audioString from "../../../Assets/Images/audoCommentImg.png"
//components
import SideMenu from "../../../Components/Participants/SideMenu";
import { LatestCreated } from "../../../Components/AppTools/AppTool";
import { Status } from "../../../Components/AppTools/AppTool";
import { ApexChart } from "../../../Components/AppTools/PieChart";
import BubbleChart from "../../../Components/AppTools/BubbleChart";
//data
import { sideHaumIcon, ovrlImgData, tagResponseData } from "../../../Assets/Data";

export default function RecapAudioReview({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
  selectNavItem,
  setSelectNavItem
}) {
    const {
        t,
        i18n: { changeLanguage, language },
      } = useTranslation();
  const Navigate = useNavigate();
  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Recently Added");
  const [currentPage, setCurrentPage] = useState(0);
  const [newArrayData, setNewArrayData] = useState([]);
  const [exportDrop, setExportDrop] = useState(false);
  const [exportDrop2, setExportDrop2] = useState(false);
  const [exportDrop3, setExportDrop3] = useState(false);
  const [exportDrop4, setExportDrop4] = useState(false);
  const [exportDropVal, setExportDropVal] = useState("Donut Chart");
  const exportItem = ["PNG", "JPEG", "PDF", "SVG"];

  const [chartDrop, setChartDrop] = useState(false);
  const [chartDropVal, setChartDropVal] = useState("Donut Chart");
  const chartItem = [
    {
      name: "Donut Chart",
      value: "Donut Chart",
    },
    {
      name: "Horizontal Bar Graph",
      value: "Horizontal Bar Graph",
    },
    {
      name: "Vertical Bar Graph",
      value: "Vertical Bar Graph",
    },
  ];

  const [countDrop, setCountDrop] = useState(false);
  const [countDropVal, setCountDropVal] = useState(
    "Show count with percentage"
  );
  const countItem = [
    {
      name: "Show only count of tags",
      value: "Show only count of tags",
    },
    {
      name: "Show only count of tags",
      value: "Show only count of tags",
    },
    {
      name: "Show only percentage",
      value: "Show only percentage",
    },
  ];

  const [scaleDrop, setScaleDrop] = useState(false);
  const [scaleDropVal, setScaleDropVal] = useState("1 Decimal (0.1)");
  const scaleItem = [
    {
      name: "1 Decimal (0.1)",
      value: "1 Decimal (0.1)",
    },
    {
      name: "2 Decimals (0.01)",
      value: "2 Decimals (0.01)",
    },
  ];
  const [customSlideBox, setCustomSlideBox] = useState(false)

  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  const responseArrya = [
    {
      label: "Recent Responses",
    },
    {
      label: "Oldest Responses",
    },
    {
      label: "Recent Comments",
    },
  ];

  const navItem = [
    "Thread",
    "Expressions",
    "Images",
    "Videos",
    "Audios",
    "Data & Charts",
  ];

  useEffect(() => {
    printItemsByState(ovrlImgData, currentPage);
  }, [currentPage]);


  return (
    <>
      <Box className="ResearchersContainer recapPage">
        {/* side menu */}
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>

            <Box className="recapNavItems">
              {navItem?.map((el, i) => (
                <Box
                  key={i}
                  onClick={() => setSelectNavItem(el)}
                  className={
                    selectNavItem === el
                      ? "recapNavItem recapNavItemActive"
                      : "recapNavItem"
                  }
                >
                  <Typography>{el}</Typography>
                </Box>
              ))}
            </Box>
            <Box width={"22%"} minWidth={"187px"} className="agreSearchB2">
              <LatestCreated
                setLatestDrop={setLatestDrop}
                latestCDrop={latestCDrop}
                setLatestDropVal={setLatestDropVal}
                latestDropVal={latestDropVal}
                itemAry={responseArrya}
              />
            </Box>
          </Box>
          {/* ------------------------------------------Page container --------------------------------------------------*/}
          <Box className="researchersMainBox pageContainer">
            <Box className="imagesReviewPage workspaceListBox">
              <Box className="openBtn">
                <Box
                  onClick={() => Navigate("/participants/recap?r=data_charts")}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img src={backArrow} />
                  <Typography className="neonBackText">{t("commonTxt.backBtn")}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "24px",
                  }}
                >
                  <Box className="actTNavBtn">
                    <img src={groupIcon} />
                    <Typography>{t("commonTxt.groupBtn")}</Typography>
                  </Box>

                  <Box className="actTNavBtn">
                    <img src={cott} />
                    <Typography>{t("commonTxt.excerptBtn")}</Typography>
                  </Box>

                  <Box className="actTNavBtn">
                    <img src={exportIcon} />
                    <Typography>{t("commonTxt.exportLink")}</Typography>
                  </Box>
                </Box>
              </Box>

              <Box className="imageReviewMainSection">
                <Box className="imgRNav1Box">
                  <Typography>{t("recapeVideoReview.recapVideoRevideoProjectName")}</Typography>
                </Box>
                <Box className="imgRNav1Box">
                  <Typography className="pageTitle">
                  {t("recapAudioReview.recapVideoReviewName")}
                  </Typography>
                </Box>
                <Typography mt={3}>
                {t("recapeVideoReview.recapVideoRevideoText1")}
                </Typography>
                <Typography mt={2}>
                {t("recapeVideoReview.recapVideoRevideoText2")}
                </Typography>
                <Typography mt={2}>
                {t("recapeVideoReview.recapVideoRevideoText3")}
                </Typography>
                
                <Box my={2} className="imgRevDropNav">
                    <Box className="AlDropimgRve1">
                    </Box>

                    <Box className="AlDropimgRve2">
                    <Box
                        className="exporOpenBtn"
                        onClick={() => setExportDrop(!exportDrop)}
                    >
                        <img src={exportIcon} />
                        <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
                        
                    </Box>
                    </Box>
                </Box>
                <Box className="mainAudioBox">
                    <img src={audioString} />
                </Box>

                {/* Graphs start here */}
                <Box mt={8} className="reacapVideoReviewNav">
                <Typography className="pageTitle">{t("recapeVideoReview.recapVideoReviewTagDistribution")}</Typography>
                <Box
                    className="exporOpenBtn"
                    onClick={() => setExportDrop2(!exportDrop2)}
                >
                    <img src={exportIcon} />
                    <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
                    
                </Box>
                </Box>
                <Box mt={2} className="bubbleChartBox">
                <BubbleChart />
                </Box>
                {/* graphs start here */}

                {/* table */}
                <Box mt={8} mb={2} className="reacapVideoReviewNav">
                  <Typography className="pageTitle">
                  {t("recapAudioReview.recapVideoReviewAudioRatting")} (6)
                  </Typography>
                  <Box
                    className="exporOpenBtn"
                    onClick={() => setExportDrop2(!exportDrop2)}
                  >
                    <img src={exportIcon} />
                    <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
                    
                  </Box>
                </Box>
                <Box className="ovImgRatTable">
                  <Box className="workListBox">
                    <Box className="haderRow" minWidth={"955px"}>
                      <Box
                        // minWidth={"290px"}
                        minWidth={"28%"}
                        className="Tabelsel TabelStatesel"
                      >
                        <Typography>{t("recapeVideoReview.tblName")}</Typography>
                      </Box>
                      <Box
                        //  minWidth={"130px"}
                        minWidth={"24%"}
                        className="Tabelsel"
                      >
                        <Typography>{t("recapeVideoReview.tblOverallRating")}</Typography>
                      </Box>
                      <Box minWidth={"24%"} className="Tabelsel">
                        <Typography>{t("recapeVideoReview.tblNoOfTag")}</Typography>
                      </Box>

                      <Box minWidth={"24%"} className="Tabelsel">
                        <Typography>{t("recapeVideoReview.tblLastEdit")}</Typography>
                      </Box>
                    </Box>
                    {newArrayData &&
                      newArrayData?.map((el, index) => (
                        <Box key={index} className="TabelRow" minWidth={"955px"}>
                          <Box minWidth={"28%"} className="Tabelsel navFsel navtable">
                            <Box id="dot" className="dot"></Box>
                            <Box className="tabelAvatar">
                              <img src={el.img} />
                            </Box>
                            <Typography sx={{ cursor: "pointer", width: "95%" }}>
                              {el.name}
                            </Typography>
                          </Box>

                          <Box minWidth={"24%"} className="Tabelsel Tabelcentersel">
                            <Typography>{el.OverallRating}</Typography>
                          </Box>
                          <Box minWidth={"24%"} className="Tabelsel Tabelcentersel">
                            <Typography>{el.NoofTags}</Typography>
                          </Box>
                          <Box minWidth={"24%"} className="Tabelsel Tabelcentersel">
                            <Typography>{el.LastEdited}</Typography>
                          </Box>
                        </Box>
                      ))}
                  </Box>
                  <Box className="pagenation">
                    <Box onClick={handlePrev} className="tabelBtn">
                      <img src={arrow} />
                      <Typography>{t("commonTxt.previouspage")}</Typography>
                    </Box>

                    <Box className="pageNumberBox">
                      <Box
                        onClick={() => setCurrentPage(0)}
                        className={
                          currentPage === 0
                            ? "pageNumber pageNumberActive"
                            : "pageNumber"
                        }
                      >
                        <p>1</p>
                      </Box>
                      <Box
                        onClick={() => setCurrentPage(1)}
                        className={
                          currentPage === 1
                            ? "pageNumber pageNumberActive"
                            : "pageNumber"
                        }
                      >
                        <p>2</p>
                      </Box>
                      <Box
                        onClick={() => setCurrentPage(2)}
                        className={
                          currentPage === 2
                            ? "pageNumber pageNumberActive"
                            : "pageNumber"
                        }
                      >
                        <p>3</p>
                      </Box>
                      <Box
                        onClick={() => setCurrentPage(3)}
                        className={
                          currentPage === 3
                            ? "pageNumber pageNumberActive"
                            : "pageNumber"
                        }
                      >
                        <p>4</p>
                      </Box>
                      <Box
                        onClick={() => setCurrentPage(4)}
                        className={
                          currentPage === 4
                            ? "pageNumber pageNumberActive"
                            : "pageNumber"
                        }
                      >
                        <p>5</p>
                      </Box>
                    </Box>

                    <Box onClick={handleNext} className="tabelBtn">
                      <Typography>{t("commonTxt.nextPage")}</Typography>
                      <img style={{ rotate: "180deg" }} src={arrow} />
                    </Box>
                  </Box>
                </Box>

                {/* chart */}
                <Box mt={8} mb={4} className="reacapVideoReviewNav">
                  <Typography className="pageTitle">{t("recapeVideoReview.recapVideoReviewTagFrequency")}</Typography>
                  <Box
                    className="exporOpenBtn"
                    onClick={() => setExportDrop3(!exportDrop3)}
                  >
                    <img src={exportIcon} />
                    <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
                    
                  </Box>
                </Box>

                <Box className="chartNav">
                  <Box className="chartNavDropBox">
                    <Typography>{t("recapeVideoReview.recapVideoReviewChatLabel")}</Typography>
                    <Status
                      label=" "
                      itemAry={chartItem}
                      setStatusDrop={setChartDrop}
                      statusDrop={chartDrop}
                      setStatusDropVal={setChartDropVal}
                      statusDropVal={chartDropVal}
                    />
                  </Box>

                  <Box className="chartNavDropBox">
                    <Typography>{t("recapeVideoReview.recapVideoReviewValueLabel")}</Typography>
                    <Status
                      label=" "
                      itemAry={countItem}
                      setStatusDrop={setCountDrop}
                      statusDrop={countDrop}
                      setStatusDropVal={setCountDropVal}
                      statusDropVal={countDropVal}
                    />
                  </Box>

                  <Box className="chartNavDropBox">
                    <Typography>{t("recapeVideoReview.recapVideoReviewPrecisionScaleLabel")}</Typography>
                    <Status
                      label=" "
                      itemAry={scaleItem}
                      setStatusDrop={setScaleDrop}
                      statusDrop={scaleDrop}
                      setStatusDropVal={setScaleDropVal}
                      statusDropVal={scaleDropVal}
                    />
                  </Box>
                </Box>

                <Box className="pieBox">
                  <ApexChart />
                </Box>

                <Box className="chartTableNav">
                  <Box className="chartNavItem">
                    <Typography>{t("commonTxt.hideBtn")}</Typography>
                  </Box>
                  <Box className="chartNavItem">
                    <Typography>{t("commonTxt.groupBtn2")}</Typography>
                  </Box>
                  <Box className="chartNavItem">
                    <Typography>{t("commonTxt.changeColorBtn")}</Typography>
                  </Box>
                </Box>

                <Box mb={6} className="workListBox">
                  <Box className="haderRow" minWidth={"955px"}>
                    <Box minWidth={"20%"} className="Tabelsel TabelStatesel">
                      <Typography>{t("recapeVideoReview.tblAction")}</Typography>
                    </Box>
                    <Box minWidth={"15%"} className="Tabelsel">
                      <Typography>{t("recapeVideoReview.tblColor")}</Typography>
                    </Box>
                    <Box minWidth={"30%"} className="Tabelsel TabelStatesel">
                      <Typography>{t("recapeVideoReview.tblTag")}</Typography>
                    </Box>

                    <Box minWidth={"13%"} className="Tabelsel">
                      <Typography>{t("recapeVideoReview.tblResponses")}</Typography>
                    </Box>
                    <Box minWidth={"13%"} className="Tabelsel">
                      <Typography>{t("recapeVideoReview.tblPercentage")}</Typography>
                    </Box>
                  </Box>

                  <Box className="TabelRow" minWidth={"955px"}>
                    <Box
                      minWidth={"20%"}
                      gap={"40px"}
                      className="Tabelsel navFsel navtable"
                    >
                      <img src={Option2LIcon} />
                      <input type="checkBox" className="custom-checkbox" />
                    </Box>
                    <Box minWidth={"15%"} className="Tabelsel Tabelcentersel">
                      <Box className="colorBox" sx={{ background: "#21A1F9" }}></Box>
                    </Box>
                    <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                      <img src={editIcon} />
                      <Typography>😍</Typography>
                      <Typography>Like</Typography>
                    </Box>
                    <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                      <Typography>18</Typography>
                    </Box>
                    <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                      <Typography>58.2%</Typography>
                    </Box>
                  </Box>

                  <Box className="TabelRow" minWidth={"955px"}>
                    <Box
                      minWidth={"20%"}
                      gap={"40px"}
                      className="Tabelsel navFsel navtable"
                    >
                      <img src={Option2LIcon} />
                      <input type="checkBox" className="custom-checkbox" />
                    </Box>
                    <Box minWidth={"15%"} className="Tabelsel Tabelcentersel">
                      <Box className="colorBox" sx={{ background: "#FF4E72" }}></Box>
                    </Box>
                    <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                      <img src={editIcon} />
                      <Typography>😟</Typography>
                      <Typography>Dislike</Typography>
                    </Box>
                    <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                      <Typography>05</Typography>
                    </Box>
                    <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                      <Typography>18.5%</Typography>
                    </Box>
                  </Box>

                  <Box className="TabelRow" minWidth={"955px"}>
                    <Box
                      minWidth={"20%"}
                      gap={"40px"}
                      className="Tabelsel navFsel navtable"
                    >
                      <img src={Option2LIcon} />
                      <input type="checkBox" className="custom-checkbox" />
                    </Box>
                    <Box minWidth={"15%"} className="Tabelsel Tabelcentersel">
                      <Box className="colorBox" sx={{ background: "#FFBE28" }}></Box>
                    </Box>
                    <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                      <img src={editIcon} />
                      <Typography>🤨</Typography>
                      <Typography>Confused</Typography>
                    </Box>
                    <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                      <Typography>08</Typography>
                    </Box>
                    <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                      <Typography>23.3%</Typography>
                    </Box>
                  </Box>
                </Box>

                {/* Tags Response Summary */}
                <Box className="tagResponseBox">
                  <Box mt={8} mb={2} className="reacapVideoReviewNav">
                    <Typography className="pageTitle">
                    {t("recapeVideoReview.recapVideoReviewTagsresponseSummary")} (10)
                    </Typography>
                    <Box
                      className="exporOpenBtn"
                      onClick={() => setExportDrop4(!exportDrop4)}
                    >
                      <img src={exportIcon} />
                      <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
                      
                    </Box>
                  </Box>

                  <Box className="workListBox">
                    <Box className="haderRow" minWidth={"1080px"}>
                      <Box
                        // minWidth={"290px"}
                        minWidth={"22%"}
                        className="Tabelsel TabelStatesel"
                      >
                        <Typography>{t("recapeVideoReview.tblName")}</Typography>
                      </Box>
                      <Box minWidth={"15%"} className="Tabelsel TabelStatesel">
                        <Typography>{t("recapeVideoReview.tblTags")}</Typography>
                      </Box>
                      <Box minWidth={"27%"} className="Tabelsel Annotation">
                        <Typography>{t("recapeVideoReview.tblName")}</Typography>
                      </Box>
                      <Box minWidth={"12%"} className="Tabelsel TabelStatesel">
                        <Typography>{t("recapeVideoReview.Time")}</Typography>
                      </Box>

                      <Box minWidth={"21%"} className="Tabelsel">
                        <Typography>{t("recapeVideoReview.tblLastEdit")}</Typography>
                      </Box>
                    </Box>
                    {tagResponseData &&
                      tagResponseData?.map((el, index) => (
                        <Box key={index} className="TabelRow" minWidth={"1080px"}>
                          <Box minWidth={"22%"} className="Tabelsel navFsel navtable">
                            <Box id="dot" className="dot"></Box>
                            <Box className="tabelAvatar">
                              <img src={el.img} />
                            </Box>
                            <Typography sx={{ cursor: "pointer", width: "95%" }}>
                              {el.name}
                            </Typography>
                          </Box>
                          <Box minWidth={"15%"} className="Tabelsel ">
                            <Typography>{el.TagsIcon}</Typography>
                            <Typography>{el.Tags}</Typography>
                          </Box>
                          <Box minWidth={"27%"} className="Tabelsel TabelStatesel">
                            
                            <Typography>{el.Annotation}</Typography>
                          </Box>
                          <Box minWidth={"12%"} className="Tabelsel TabelStatesel">
                            
                            <Typography>00:07</Typography>
                          </Box>
                          <Box minWidth={"21%"} className="Tabelsel Tabelcentersel">
                            <Typography>{el.LastEdited}</Typography>
                          </Box>
                        </Box>
                      ))}
                  </Box>
                  <Box className="pagenation">
                    <Box className="tabelBtn">
                      <img src={arrow} />
                      <Typography>{t("commonTxt.previouspage")}</Typography>
                    </Box>

                    <Box className="pageNumberBox">
                      <Box
                        onClick={() => setCurrentPage(0)}
                        className={
                          currentPage === 0
                            ? "pageNumber pageNumberActive"
                            : "pageNumber"
                        }
                      >
                        <p>1</p>
                      </Box>
                      <Box
                        onClick={() => setCurrentPage(1)}
                        className={
                          currentPage === 1
                            ? "pageNumber pageNumberActive"
                            : "pageNumber"
                        }
                      >
                        <p>2</p>
                      </Box>
                      <Box
                        onClick={() => setCurrentPage(2)}
                        className={
                          currentPage === 2
                            ? "pageNumber pageNumberActive"
                            : "pageNumber"
                        }
                      >
                        <p>3</p>
                      </Box>
                      <Box
                        onClick={() => setCurrentPage(3)}
                        className={
                          currentPage === 3
                            ? "pageNumber pageNumberActive"
                            : "pageNumber"
                        }
                      >
                        <p>4</p>
                      </Box>
                      <Box
                        onClick={() => setCurrentPage(4)}
                        className={
                          currentPage === 4
                            ? "pageNumber pageNumberActive"
                            : "pageNumber"
                        }
                      >
                        <p>5</p>
                      </Box>
                    </Box>

                    <Box className="tabelBtn">
                      <Typography>{t("commonTxt.nextPage")}</Typography>
                      <img style={{ rotate: "180deg" }} src={arrow} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>


          </Box>
        </Box>
      </Box>
    </>
  );
}
