import React, { useState } from "react";
import AgreementPage from "../../../Components/Admin/AgreementPage";
import { Box } from "@mui/material";
import { AppBtn } from "../../../Components/AppTools/AppButton";
import AdminLayout from "../partials/AdminLayout";

const AgreementTemplate = () => {
  const [agreementTemplatePop, setAgreementTemplatePop] = useState(false);
  return (
    <AdminLayout
      childComponent={
        <Box className="adminMainBox" sx={{ display: "flex" }}>
          <AgreementPage
            setAgreementTemplatePop={setAgreementTemplatePop}
            agreementTemplatePop={agreementTemplatePop}
          />
        </Box>
      }
      actionBtn={
        <AppBtn
          onClick={() => setAgreementTemplatePop(true)}
          buttonText="Create Agreement Template"
        />
      }
    />
  );
};

export default AgreementTemplate;
