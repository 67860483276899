import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";

//images
import dropArrow from "../../../Assets/Images/chevron-down.png";
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png"


export default function AddPagePop({ allDropVal3, setAllDropVal3 }) {

  const [image, setImage] = useState(null);

  const [projectDrop3, setProjectDrop3] = useState(false);
  const [projectDropVal3, setProjectDropVal3] = useState("Select participant access options");

  const [projectDrop4, setProjectDrop4] = useState(false);
  const [projectDropVal4, setProjectDropVal4] = useState("Select administaror access options");


  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setAllDropVal3("Page Options");
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (allDropVal3 === "Add Page") {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [allDropVal3]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: allDropVal3 === "Add Page" ? "flex" : "none" }}
        className="companyPop AddCardPopPeople "
      >
        <Box
          sx={{ display: allDropVal3 === "Add Page" ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className="companyHeader">Add Page (Outdoor Journey)</Typography>

          <Box className="basicBoxItem">
            <Typography className='psNlabel'>Page Name</Typography>
            <Box className="pickerBox">
              <input className='eTemInput' placeholder='Enter page name' />
            </Box>
          </Box>
          
          

          <Box className="basicBoxItem">
            <Typography className='psNlabel'>Upload a cover image for the page</Typography>
            <Box className='mediaInputbox upload-area' onClick={() => {
                document.getElementById("fileInput").click();
            }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}>
                <img src={uploadIcon} />
                <Typography>Click or drag an image to this area to upload</Typography>
                <input
                id="fileInput"
                style={{ display: "none" }}
                type="file"
                onChange={handleFileInputChange}
                />
            </Box>
          </Box>

          <Box className="basicBoxItem addPageParticipantAccess">
            <Typography className="psNlabel">Participant Access:</Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setProjectDrop3(!projectDrop3)}
            >
              <Typography>
                {projectDropVal3 || "Filter by Project"}
              </Typography>
              <img
                style={{ rotate: projectDrop3 ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: projectDrop3 ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setProjectDropVal3("Item-A")}
                  className="statusPopItem"
                >
                  <Typography>Item-A</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal3("Item-B")}
                  className="statusPopItem"
                >
                  <Typography>Item-B</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal3("Item-C")}
                  className="statusPopItem"
                >
                  <Typography>Item-C</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal3("Item-D")}
                  className="statusPopItem"
                >
                  <Typography>Item-D</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="basicBoxItem addPageParticipantAccess">
            <Typography className="psNlabel">Administrator Access:</Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setProjectDrop4(!projectDrop4)}
            >
              <Typography>
                {projectDropVal4 || "Filter by Project"}
              </Typography>
              <img
                style={{ rotate: projectDrop4 ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: projectDrop4 ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setProjectDropVal4("Item-A")}
                  className="statusPopItem"
                >
                  <Typography>Item-A</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal4("Item-B")}
                  className="statusPopItem"
                >
                  <Typography>Item-B</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal4("Item-C")}
                  className="statusPopItem"
                >
                  <Typography>Item-C</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal4("Item-D")}
                  className="statusPopItem"
                >
                  <Typography>Item-D</Typography>
                </Box>
              </Box>
            </Box>
          </Box>




          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={() => setAllDropVal3("Page Options")} />
            <AppBtn buttonText="Add Page" />
          </Box>



        </Box>
      </Box>
    </>
  );
}
