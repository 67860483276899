import React, { useState } from "react";
import {
  Box,
  Typography,
  Dialog,
  FormLabel,
  TextField,
  Alert,
  Button,
} from "@mui/material";

import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import uploadImage from "../../../Assets/Images/upload-image.png";
import plusIcon from "../../../Assets/Images/plus.svg";
import IIcon2 from "../../../Assets/Images/IIcon.png";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { uploadFile } from "../../../Services/adminActions";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { useAppSelector } from "../../../lib/store/store";

const DuplicateProjectPop = ({
  setDuplicateProjectPop,
  duplicateProjectPop,
  VisuallyHiddenInput,
  fullScreen,
  setDuplicateProjectNextPop,
  setProjectState,
  projectState,
  image,
  setImage
}) => {
  const authState = useAppSelector(state => state.auth);

  const handleClose12 = (e) => {
    if (e.target.id === "popBackdrop") {
      setDuplicateProjectPop(false);
    }
  };

  const handleClickOpen13 = () => {
    setDuplicateProjectPop(false);
    setDuplicateProjectNextPop(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      uploadImage(file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      uploadImage(file);
    }
  };
  const setFormData = (e) => {
    let newState = {
      ...projectState,
      [e.target.name]: e.target.value,
    };

    setProjectState(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
  };

  const validationObj = {
    hasErr: false,
    'name': {
      error: false,
      msg: ''
    },
  }

  const [validationErrors, setValidationErrors] = useState({ ...validationObj });

  const handleValidation = (formInput) => {
    formInput = formInput || projectState;
    let validationerr = { ...validationObj };

    if (formInput.name == "") {
      validationerr.hasErr = true;
      validationerr.name = {
        error: true,
        msg: 'Name is required'
      }
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  }

  const uploadImage = async (file) => {
    if (!file)
      return false;
    const formData = new FormData()
    formData.append('file', file)
    try {
      let imageUploadRes = await uploadFile(formData, authState.authToken);
      if (imageUploadRes.success) {
        setProjectState({ ...projectState, image: imageUploadRes?.data?.upload_id })
        // toast.success(imageUploadRes.message, TOAST_CONFIG);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    } finally {

    }
  }

  const nextProject = async () => {
    if (!handleValidation()) {
      toast.error('Please correct form fields', TOAST_CONFIG);
      return false;
    }
    handleClickOpen13();
  }

  const resetPopup = () => {
    let reset = {}
    for (const key in projectState) {
      reset[key] = ""
    }
    setProjectState({ ...reset })
    setImage(null);
    setDuplicateProjectPop(false)
  }
  return (
    <Dialog
      className="DialogSection"
      fullScreen={fullScreen}
      maxWidth={1080}
      open={duplicateProjectPop}
      onClose={handleClose12}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        Duplicate Project
      </DialogTitle>
      <Box className="AddFormPanel">
        <Box className="FormFiled">
          <FormLabel className="label">Add Project Image</FormLabel>
          <Box className="FormInputFile">
            <Box className="UploadedImage">
              {/* <img src={uploadImage} /> */}
            </Box>
            <Box sx={{ border: "none", marginBottom: "0px" }} className='mediaInputbox upload-area' onClick={() => {
              document.getElementById("fileInput").click();
            }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}>
              {image ? (
                <img className="fileinputimg" src={image} alt="Upload" />
              ) : (
                <img src={plusIcon} alt="Upload" />
              )}
              <Typography>Click or drag image to this area to upload</Typography>
              <input
                id="fileInput"
                style={{ display: "none" }}
                type="file"
                onChange={handleFileInputChange}
              />
            </Box>
          </Box>
        </Box>

        <Box className="timeZoneTextBox infoTimeZoneTextBox">
          <Box className="iIcontD"><img src={IIcon2} /></Box>
          <Box>
            <Typography>Changing the Project Name won't automatically change the Project
              Address (URL), so please check both settings.</Typography>
          </Box>
        </Box>
        <Box className="FormFiled">
          <FormLabel className="label">Project Name</FormLabel>
          <Typography>
            You can pick a name for your Project that's different from the
            Site’s name. Participants will see the Project Name by default.
          </Typography>
          <TextField
            fullWidth
            id="outlined-basic"
            placeholder="Enter Project Name"
            variant="outlined"
            size="small"
            onChange={setFormData}
            value={projectState.name}
            name="name"
          />
        </Box>
        <Box className="FormFiled">
          <FormLabel className="label">Project Address</FormLabel>
          <Typography>
            The project address is the tail-end of your project’s URL. The
            default Project Address will match the Project name. Standard
            formatting rules for URL’s apply (i.e. no spaces).
          </Typography>
          <TextField
            fullWidth
            id="outlined-basic"
            placeholder="www.terapage.com/"
            variant="outlined"
            size="small"
            onChange={setFormData}
            value={projectState.project_address}
            name="project_address"
          />
        </Box>
        <Box className="timeZoneTextBox infoTimeZoneTextBox">
          <Box className="iIcontD"><img src={IIcon2} /></Box>
          <Box>
            <Typography>The External Project ID is for linking the Project with an
              internal name or number. It helps avoid confusion when Projects
              have similar or generic names, or names in another language.</Typography>
          </Box>
        </Box>
        <Box className="FormFiled">
          <FormLabel className="label">External Project ID</FormLabel>
          <Typography>
            Participants see the Project Name, but the External Project ID is
            optional and visible only to admins.
          </Typography>
          <TextField
            fullWidth
            id="outlined-basic"
            placeholder="Enter External Project ID"
            variant="outlined"
            size="small"
            onChange={setFormData}
            value={projectState.extrenal_project_id}
            name="extrenal_project_id"
          />
        </Box>
      </Box>

      <DialogActions>
        <Box className="workPupBtnBox addProjectPopBtn">
          <AppBtnOutLine
            autoFocus
            onClick={() => { resetPopup() }}
            buttonText="Cancel"
          />
          <AppBtn onClick={nextProject} buttonText="Next" />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DuplicateProjectPop;
