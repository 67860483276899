import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useNavigate } from "react-router-dom";
import "./style.css";

//images
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import chackBox from "../../../Assets/Images/Checkbox.png";
import check from "../../../Assets/Images/check.png";
import backArrow from "../../../Assets/Images/noun-back.png";
import cott from "../../../Assets/Images/cott.png";
import star from "../../../Assets/Images/Star 2.png";
import iIcon from "../../../Assets/Images/IIcon.png";
import idelDot from "../../../Assets/Images/idelDot.png";
import postImg from "../../../Assets/Images/postImg.png";
import editBtn from "../../../Assets/Images/edit 1.png";
import whitedropIcon from "../../../Assets/Images/Wchevron-down.png";
import semoji from "../../../Assets/Images/Semoji.png";
import imgIcon from "../../../Assets/Images/galleryIconB.png";
import groupIcon from "../../../Assets/Images/groupsIcon.png";
import avatar1 from "../../../Assets/Images/inb1.png"
import avatar2 from "../../../Assets/Images/inb2.png"
import avatar3 from "../../../Assets/Images/inb3.png"




//component
import SideMenu from "../../../Components/Observer/SideMenu";
import { useTranslation } from "react-i18next";
import { AppAddBtn } from "../../../Components/AppTools/AppButton"
import { SearchBar, LatestCreated } from "../../../Components/AppTools/AppTool"

import {
  like,
  dislike,
  comment,
  tik,
  sideHaumIcon,
  arrowIcon
} from "../../../Assets/Data";


export default function ViewConversationTopic({ slideMenu, setSlideMenu, workspaceDrop, setWorkSpaceDrop }) {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [conOpDrop, setConOpDrop] = useState(false);
  const [chackVal, setChackVal] = useState([]);
  const [addConversationTopicPop, setAddConversationTopicPop] = useState(false);
  const [createConversationCategoryPop, setCreateConversationCategoryPop] = useState(false);

  const [nav, setNav] = useState(0);
  const [nav3, setNav3] = useState(0)

  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Recently Added");

  const [latestCDrop2, setLatestDrop2] = useState(false);
  const [latestDropVal2, setLatestDropVal2] = useState("Recently Added");

  const [latestCDrop3, setLatestDrop3] = useState(false);
  const [latestDropVal3, setLatestDropVal3] = useState("Recently Added");

  const [providerDrop, setProviderDrop] = useState(false);
  const [providerDropVal, setProviderDropVal] = useState();



  const responseArrya = [
    "Order Posted",
    "Recent Replies",
    "Most Liked",
    "Most Comments",
    "Recent Liked",
    "Recent Comments",
    "Reviewed Posts",
  ];
  const exportItem = ["PNG", "JPEG", "PDF", "SVG"];

  const [series] = useState([{ data: [400, 430, 448, 470, 540] }]);
  const categories = ['Product Reviews', 'Price', 'Brand Reputation', 'Durabilty/Quality', 'Aesthetics'];

  const [series2] = useState([{ data: [400, 430, 448, 470, 540] }]);
  const categories2 = ['Relaxation', 'Great Quote', 'Nostalgia', 'Empowerment'];


  const selectAllCheckData = () => {
    setChackVal(["Richard Han", "Lucy Hutten", "Lorem Ipsum", "Dolor Amet"]);
  };

  const deleteAllCheckData = () => setChackVal([]);

  const pushData = (string) => {
    setChackVal((prev) => [...prev, string]);
  };

  const handleNavDrop = (e) => {
    if (e.target.id === "conNavDrop") {
      setConOpDrop(!conOpDrop)
    }
  }

  function wrapAtWordsWithSpan(text) {
    return text.replace(/@(\w+)/g, "<span>@$1</span>");
  }

  const singelComment = ({ img, name, comment, date, time }) => {
    return (
      <Box className="singelComment">
        <Box className="commentSection innerCommentSection">
          <Box className="avatarBox">
            <img src={img} />
          </Box>
          <Box className="commentTextSection">
            <Box className="userNameBox">
              <Typography className="userName">{name}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "15px",
                  minWidth: "213px",
                }}
              >
                <Box className="editbtn">
                  <img src={editBtn} />
                  <Typography>{t("commonTxt.edit")}</Typography>
                </Box>
                <samp className="taskHeaderText3">{date}</samp>
              </Box>
            </Box>

            <Typography
              className="commentWtag"
              dangerouslySetInnerHTML={{ __html: wrapAtWordsWithSpan(comment) }}
            ></Typography>
            <Box className="likeBox">
              <Typography>{time}</Typography>
              <Typography>{t("commonTxt.Like")}</Typography>
              <Typography>{t("commonTxt.dislike")}</Typography>
              <Typography>{t("commonTxt.reply")}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const writeCommentSection = ({ img }) => {
    return (
      <Box className="writeCommentSection">
        <Box className="avatarBox">
          <img src={img} />
        </Box>
        <Box className="commentInputBox">
          <input className="phoneInput" placeholder="Add comment" />
          <img className="inputIcon1" src={semoji} />
          <img className="inputIcon2" src={imgIcon} />
          <Box className="commentCheckBox">
            <Box
              mb={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <input
                style={{ marginRight: "10px" }}
                className="custom-checkbox"
                type="checkBox"
              />
              <Typography>{t("recapThread.makeReplyCompulsory")}</Typography>
            </Box>

            <Typography className="followText">{t("emailPage.temp")}</Typography>
          </Box>
        </Box>

        <Box
          className="directMessageNavBtn"
          onClick={() => setProviderDrop(!providerDrop)}
        >
          <Typography className="DropBtnText">{t("commonTxt.postBtn")}</Typography>
          <img
            src={whitedropIcon}
            style={{ rotate: providerDrop ? "180deg" : "0deg" }}
          />
          <Box
            className="workspaceItemNavPop"
            sx={{ display: providerDrop ? "flex" : "none" }}
          >
            <Box onClick={() => setProviderDropVal("Visible to Everyone")}>
              <Typography>{t("recapThread.visibleToEveryone")}</Typography>
            </Box>
            <Box onClick={() => setProviderDropVal("Only Andi Lane")}>
              <Typography>{t("recapThread.onlyAndiLane")}</Typography>
            </Box>
            <Box onClick={() => setProviderDropVal("Backroom Comment")}>
              <Typography>{t("recapThread.backroomComment")}</Typography>
            </Box>
            <Box onClick={() => setProviderDropVal("Backroom Task")}>
              <Typography>{t("recapThread.backroomTask")}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const nestedCommentSection = ({ img, name, comment, date, time }) => {
    return (
      <Box className="singelComment">
        <Box className="commentSection innerCommentSection">
          <Box className="avatarBox">
            <img src={img} />
          </Box>
          <Box className="commentTextSection">
            <Box className="userNameBox">
              <Typography className="userName">{name}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "15px",
                  minWidth: "213px",
                }}
              >
                <Box className="editbtn">
                  <img src={editBtn} />
                  <Typography>{t("commonTxt.edit")}</Typography>
                </Box>
                <samp className="taskHeaderText3">{date}</samp>
              </Box>
            </Box>

            <Typography
              className="commentWtag"
              dangerouslySetInnerHTML={{ __html: wrapAtWordsWithSpan(comment) }}
            ></Typography>
            <Box className="likeBox">
              <Typography>{time}</Typography>
              <Typography>{t("commonTxt.Like")}</Typography>
              <Typography>{t("commonTxt.dislike")}</Typography>
              <Typography>{t("commonTxt.reply")}</Typography>
            </Box>
            {singelComment({
              img: avatar3,
              name: "Kate Morrison",
              comment:
                "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
              date: "Aug 10, 2023",
              time: "10 h",
            })}
            {writeCommentSection({ img: avatar1 })}
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box className="ResearchersContainer viewConversatin">
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>

            

          </Box>
          {/* Page container */}
          <Box className="pageContainer">
            <Box className="workSpaceListBox  viewTopicBox">
              <Box className="openBtn">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    Navigate("/observer/conversation")
                  }
                >
                  <img src={backArrow} />
                  <Typography className="neonBackText">{t("commonTxt.backBtn")}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "24px",
                  }}
                >
                  <Box className="actTNavBtn">
                    <img src={cott} />
                    <Typography>{t("commonTxt.excerptBtn")}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="tpcHeaderBox">
                <Box className="">
                  <Box className="pageTitleBox">
                    <img src={star} />
                    <Typography>Your Trekking must haves</Typography>
                  </Box>
                  <Typography className="eqText">(Equipment Zone)</Typography>
                </Box>
                <Box>
                  <Typography className="followText">{t("Conversations.FollowTopic")}</Typography>
                </Box>
              </Box>

              <Typography className="viewTopicNtext">
                This should be a fun one for you guys!
              </Typography>
              <Typography className="viewTopicNtext">
                Lorem ipsum dolor sit amet consectetur. Morbi suspendisse quis ornare
                in. Amet nunc lectus a aenean lectus. Penatibus tempor urna sed eros
                suspendisse vulputate vulputate enim vitae. Leo ipsum sed erat sed et.
                Pellentesque cursus proin ornare eros amet velit. Faucibus in volutpat
                morbi accumsan et augue et eget eu.
              </Typography>
              <Typography className="viewTopicNtext">
                Vitae quis scelerisque orci pellentesque ac pretium nibh sed ipsum.
                Donec lorem nullam integer duis. Morbi ultrices tempor nulla vel.
                Vitae turpis diam natoque non posuere diam neque nulla. Facilisis
                laoreet nibh ullamcorper nunc.
              </Typography>

              <Box className="iBox">
                <img src={iIcon} />
                <Typography>
                  {t("viewConversationCategory.viewCategoryNotify1")} <br />{t("viewConversationCategory.viewCategoryNotify2")}{" "}
                </Typography>
              </Box>

              <Box className="togelSection">

                <Box className="topcVNav">
                  <Box
                    onClick={() => setNav(0)}
                    className={
                      nav === 0 ? "topcNavBtn topcNavBtnActive" : "topcNavBtn"
                    }>
                    <Typography>{t("Conversations.AllPosts")}</Typography>
                  </Box>
                  <Box
                    onClick={() => setNav(1)}
                    className={
                      nav === 1 ? "topcNavBtn topcNavBtnActive" : "topcNavBtn"
                    }
                  >
                    <Typography>{t("Conversations.WordCloud")}</Typography>
                  </Box>
                </Box>



                {/* All Posts section */}
                <Box
                  sx={{ display: nav === 0 ? "block" : "none" }}
                  className="togelSubBox"
                >
                  <Box className="postSectionNav">
                    <Typography sx={{ textDecoration: "underline" }}>
                      {t("Conversations.MarkReviewed")} (2)
                    </Typography>
                    <Box sx={{ display: "flex", gap: "20px" }}>
                      <Box width={"40%"} className="agreSearchB2">
                        <SearchBar placeholder={t("Conversations.searchHerePlaceholder")} />
                      </Box>
                      <Box className="actTNavBtn">
                        <img src={groupIcon} />
                        <Typography>{t("commonTxt.groupBtn")}</Typography>
                      </Box>

                      <Box width={"32%"} minWidth={"170px"} className="agreSearchB2">
                        <LatestCreated
                          setLatestDrop={setLatestDrop}
                          latestCDrop={latestCDrop}
                          setLatestDropVal={setLatestDropVal}
                          latestDropVal={latestDropVal}
                          itemAry={responseArrya}
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box className="commentSection">
                    <Box className="avatarBox">
                      <img src={avatar2} />
                      <img className="statusDot" src={idelDot} />
                    </Box>

                    <Box className="commentTextSection">
                      <Box className="userNameBox">
                        <Typography className="userName">Kate Morrison</Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "15px",
                          }}
                        >
                          <Typography className="taskHeaderText2">{t("commonTxt.viewBtn")}</Typography>
                          <samp className="taskHeaderText3">
                            Aug 04, 2023 02:21 PM
                          </samp>
                        </Box>
                      </Box>
                      <Typography>
                        I love my sleeping pad, not to be confused with the sleeping
                        bag. It gives an extra layer of comfort and insulation.{" "}
                      </Typography>

                      <Box className="commentSection innerCommentSection">
                        <Box className="avatarBox">
                          <img src={avatar1} />
                        </Box>
                        <Box className="commentTextSection">
                          <Box className="userNameBox">
                            <Typography className="userName">Daina Henry</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "15px",
                              }}
                            >
                              <Typography className="taskHeaderText2">
                                {t("commonTxt.viewBtn")}
                              </Typography>
                              <samp className="taskHeaderText3">
                                Aug 04, 2023 02:21 PM
                              </samp>
                            </Box>
                          </Box>

                          <Typography>
                            This is extremely important! Saves my back every time I go
                            out camping.{" "}
                          </Typography>
                          <Box className="imgPostBox">
                            <img src={postImg} />
                          </Box>
                        </Box>
                      </Box>

                      <Box className="taskBoxBottomRow">
                        <Box className="taskBoxBottomRowItem">
                          {like}
                          <Typography>{t("commonTxt.Like")}</Typography>
                        </Box>
                        <Box className="taskBoxBottomRowItem">
                          {dislike}
                          <Typography>{t("commonTxt.dislike")}</Typography>
                        </Box>
                        <Box className="taskBoxBottomRowItem">
                          {comment}
                          <Typography>{t("commonTxt.comment")}</Typography>
                        </Box>
                        <Box className="taskBoxBottomRowItem">
                          {tik}
                          <Typography>{t("commonTxt.review")}</Typography>
                        </Box>
                      </Box>

                      {nestedCommentSection({
                        img: avatar1,
                        name: "Orlando Diggs",
                        comment:
                          "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                        date: "Aug 10, 2023",
                        time: "10 h",
                      })}
                      {singelComment({
                        img: avatar2,
                        name: "Richard Han",
                        comment: "Hey @sam, do have a look this particular post. ",
                        date: "Aug 10, 2023",
                        time: "10 h",
                      })}
                      {singelComment({
                        img: avatar3,
                        name: "Kate Morrison",
                        comment:
                          "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                        date: "Aug 10, 2023",
                        time: "10 h",
                      })}

                      {writeCommentSection({ img: avatar1 })}
                    </Box>
                  </Box>

                  {/* section2 */}
                  <Box className="commentSection">
                    <Box className="avatarBox">
                      <img src={avatar2} />
                      <img className="statusDot" src={idelDot} />
                    </Box>

                    <Box className="commentTextSection">
                      <Box className="userNameBox">
                        <Typography className="userName">Kate Morrison</Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "15px",
                          }}
                        >
                          <Typography className="taskHeaderText2">{t("commonTxt.viewBtn")}</Typography>
                          <samp className="taskHeaderText3">
                            Aug 04, 2023 02:21 PM
                          </samp>
                        </Box>
                      </Box>
                      <Typography>
                        I love my sleeping pad, not to be confused with the sleeping
                        bag. It gives an extra layer of comfort and insulation.{" "}
                      </Typography>

                      <Box className="commentSection innerCommentSection">
                        <Box className="avatarBox">
                          <img src={avatar1} />
                        </Box>
                        <Box className="commentTextSection">
                          <Box className="userNameBox">
                            <Typography className="userName">Daina Henry</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "15px",
                              }}
                            >
                              <Typography className="taskHeaderText2">
                                {t("commonTxt.viewBtn")}
                              </Typography>
                              <samp className="taskHeaderText3">
                                Aug 04, 2023 02:21 PM
                              </samp>
                            </Box>
                          </Box>

                          <Typography>
                            This is extremely important! Saves my back every time I go
                            out camping.{" "}
                          </Typography>
                        </Box>
                      </Box>

                      <Box className="taskBoxBottomRow">
                        <Box className="taskBoxBottomRowItem">
                          {like}
                          <Typography>{t("commonTxt.Like")}</Typography>
                        </Box>
                        <Box className="taskBoxBottomRowItem">
                          {dislike}
                          <Typography>{t("commonTxt.dislike")}</Typography>
                        </Box>
                        <Box className="taskBoxBottomRowItem">
                          {comment}
                          <Typography>{t("commonTxt.comment")}</Typography>
                        </Box>
                        <Box className="taskBoxBottomRowItem">
                          {tik}
                          <Typography>{t("commonTxt.review")}</Typography>
                        </Box>
                      </Box>

                      {singelComment({
                        img: avatar3,
                        name: "Richard Han",
                        comment:
                          "Hey @everyone, what other items according to you can be used to avoid injuries while trekking?",
                        date: "Aug 10, 2023",
                        time: "10 h",
                      })}
                      {singelComment({
                        img: avatar1,
                        name: "Orlando Diggs",
                        comment:
                          "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                        date: "Aug 10, 2023",
                        time: "10 h",
                      })}
                      {nestedCommentSection({
                        img: avatar1,
                        name: "Orlando Diggs",
                        comment:
                          "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                        date: "Aug 10, 2023",
                        time: "10 h",
                      })}
                      {singelComment({
                        img: avatar3,
                        name: "Kate Morrison",
                        comment:
                          "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                        date: "Aug 10, 2023",
                        time: "10 h",
                      })}
                      {writeCommentSection({ img: avatar1 })}
                    </Box>
                  </Box>
                </Box>



                {/*---------------- Word Cloud section---------- */}
                <Box
                  sx={{ display: nav === 1 ? "block" : "none" }} className="togelSubBox">
                  <Box className="postSectionNav">
                    <Typography sx={{ textDecoration: "underline" }}>
                      {t("Conversations.MarkReviewed")} (2)
                    </Typography>
                    <Box sx={{ display: "flex", gap: "20px" }}>
                      <Box width={"40%"} className="agreSearchB2">
                        <SearchBar placeholder={t("Conversations.searchHerePlaceholder")} />
                      </Box>
                      <Box className="actTNavBtn">
                        <img src={groupIcon} />
                        <Typography>{t("commonTxt.groupBtn")}</Typography>
                      </Box>

                      <Box width={"32%"} minWidth={"170px"} className="agreSearchB2">
                        <LatestCreated
                          setLatestDrop={setLatestDrop2}
                          latestCDrop={latestCDrop2}
                          setLatestDropVal={setLatestDropVal2}
                          latestDropVal={latestDropVal2}
                          itemAry={responseArrya}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box >

    </>
  )
}
