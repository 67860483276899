import { Box, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import "./style.css";

//img
import searchIcon from "../../../Assets/Images/search.png";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import check from "../../../Assets/Images/check.png";
import chackBox from "../../../Assets/Images/Checkbox.png";
import uploadIcon from "../../../Assets/Images/uil_upload.png";
import trasIcon from "../../../Assets/Images/fluent_delete-16-regular.png";
import uploadimg from "../../../Assets/Images/Editimage.png";

import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";

//component
import ReferenceDateUsingValue from "../../AppTools/DatePicker";
import ReferenceDateExplicitTimePicker from "../../AppTools/TimePicker";
import { useAppSelector } from "../../../lib/store/store";
import axios from "../../../lib/axios";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { getCompanies, getResearchers } from "../../../Services/master";
import { formatDate, getUserRoleType, urlToBase64 } from "../../../lib/helpers";
import { updateWorkSpaceManagementApi, uploadCoverImage } from "../../../Services/adminActions";
import { STORAGE_BASE_PATH } from "../../../env";
import dayjs from "dayjs";
import { editIconBlack } from "../../../Assets/Data";

export default function EditWorkspacePop({
  setEditWorkspace,
  editWorkspace,
  setWorkSpacePop,
  setCompanyPop,
  setAddResearcherPop,
  setWorkspaceResearcher,
  setEditWorkspaceResearcher,
  setEditWorkspaceCompany,
  editWorkspaceCompany,
  setWorkspaceCompany,
  updateWorkSpaceData,
  setAllCompaniesData,
  setEditCompanyPop,
  setReloadList,
  setWorkspacePopType,
  createdAffiliate
}) {
  const [settingTabe, setSettingTabe] = useState(0);
  const [categoryDrop, setCategoryDrop] = useState(false);
  const [categoryDropVal, setCategoryDropVal] = useState("");

  const [addCompanyLink, setAddCompanyLink] = useState(false);
  const [addResearcherLink, setAddResearcherLink] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null)

  const [researcherDrop, setresearcherDrop] = useState(false);
  const [companyDrop, setCompanyDrop] = useState(false);

  const [image, setImage] = useState(null);
  const [chackVal, setChackVal] = useState([]);
  const [chackWorkspaceVal, setChackWorkspaceVal] = useState([]);
  const [allCompanyList, setAllCompanyList] = useState([]);
  const [allResearchersList, setAllResearchersList] = useState([]);
  const [companyUserList, setCompanyUserList] = useState([]);
  const [monthDrop, setMonthDrop] = useState(false);
  const [coverImage, setCoverImage] = useState(null);
  const [monthDropVal, setMonthDropVal] = useState("Month");
  const [showCompanyUser, setShowCompanyUser] = useState(false);
  const [workspaceUserDrop, setWorkspaceUserDrop] = useState(false);

  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [check3, setCheck3] = useState(false);
  const [check4, setCheck4] = useState(false);
  const [check5, setCheck5] = useState(false);
  const [check6, setCheck6] = useState(false);
  const [check7, setCheck7] = useState(false);
  const [check8, setCheck8] = useState(false);
  const [check9, setCheck9] = useState(false);

  const [loading, setLoading] = useState(false);
  let authState = useAppSelector(state => state.auth);
  createdAffiliate = createdAffiliate || null;

  const updateWorkSpace = {
    'name': '',
    'start_date': null,
    'end_date': null,
    'start_time': null,
    'end_time': null,
    'workspace_affilation': '',
    'assigned_users': [],
    'max_pages': 0,
    'max_project_per_page': 0,
    'max_openprojects': 0,
    'max_video_mins': 0,
    'max_researcher': 0,
    'max_collaborator': 0,
    'max_observer': 0,
    'max_participant': 0,
    'max_activities': {
      'count': 0,
      'duration_count': 1,
      'duration': 'week'
    },
    'status': ''
  }
  const [editWorkSpaceApi, setEditWorkSpaceApi] = useState({ ...updateWorkSpace });

  const validationObj = {
    hasErr: false,
    'name': {
      error: false,
      msg: ''
    },
    'workspace_affiliation': {
      error: false,
      msg: ''
    },
    'start_date': {
      error: false,
      msg: ''
    },
    'end_date': {
      error: false,
      msg: ''
    },
    'start_time': {
      error: false,
      msg: ''
    },
    'end_time': {
      error: false,
      msg: ''
    },
    'assigned_users': {
      error: false,
      msg: ''
    }
  }

  let fileInputRef = useRef();

  const [validationErrors, setValidationErrors] = useState({ ...validationObj });

  const handleValidation = (formInput, checkValInput) => {
    formInput = formInput || editWorkSpaceApi;
    checkValInput = checkValInput || chackVal;
    let validationerr = { ...validationObj };

    if (formInput.start_date == null) {
      validationerr.hasErr = true;
      validationerr.start_date = {
        error: true,
        msg: 'Start date is required'
      }
    }
    if (formInput.end_date == null) {
      validationerr.hasErr = true;
      validationerr.end_date = {
        error: true,
        msg: 'End date is required'
      }
    }

    if (formInput.start_time == null) {
      validationerr.hasErr = true;
      validationerr.start_time = {
        error: true,
        msg: 'Start time is required'
      }
    }

    if (formInput.end_time == null) {
      validationerr.hasErr = true;
      validationerr.end_time = {
        error: true,
        msg: 'End time is required'
      }
    }

    if (formInput.name == '') {
      validationerr.hasErr = true;
      validationerr.name = {
        error: true,
        msg: 'Workspace name is required'
      }
    }

    if (formInput.workspace_affilation == '') {
      validationerr.hasErr = true;
      validationerr.workspace_affiliation = {
        error: true,
        msg: 'Workspace affiliation is required'
      }
    }

    if (checkValInput.length <= 0) {
      validationerr.hasErr = true;
      validationerr.assigned_users = {
        error: true,
        msg: 'Assigned users is required'
      }
    }

    if (formInput.start_date != null && formInput.end_date != null) {
      if (formInput.start_date > formInput.end_date) {
        validationerr.hasErr = true;
        validationerr.end_time = {
          error: true,
          msg: 'End date time should be greater than start date time'
        }
      } else if (formInput.start_date == formInput.end_date) {
        if (formInput.start_time > formInput.end_time) {
          validationerr.hasErr = true;
          validationerr.end_time = {
            error: true,
            msg: 'End date time should be greater than start date time'
          }
        }
      }
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    getWorkSpaceList();
  }, [updateWorkSpaceData]);

  const getWorkSpaceList = async () => {
    if (updateWorkSpaceData == '') {
      return false;
    }
    setLoading(true);
    let res = await axios.get(`/super-admin/admin/workspaces?_id=${updateWorkSpaceData}`);
    setLoading(false);
    if (res.status) {
      let updatedKeys = { ...updateWorkSpace };
      let key;
      let updateWorkSpaceData = res?.data?.data[0];
      for (key in updatedKeys) {
        updatedKeys[key] = updateWorkSpaceData[key];
      }
      setEditWorkSpaceApi(updatedKeys);

      setCategoryDropVal(updateWorkSpaceData?.workspace_affilation)
      if (updateWorkSpaceData?.workspace_affilation == "Company") {
        setAddCompanyLink(true);
        if (updateWorkSpaceData?.assigned_users_data?.length > 0) {
          pushData(updateWorkSpaceData?.assigned_users_data, true)
        }
        if (updateWorkSpaceData?.assigned_users_data?.length > 0) {
          const assignedUsers = updateWorkSpaceData.assigned_users_data
            .filter(item => item.is_company === true)
            .map(item => (item));
          if (assignedUsers.length > 0) {
            // selectCompany(assignedUsers[0])

            getCompanies({ '_id': createdAffiliate ? createdAffiliate._id : assignedUsers[0]?._id }).then(res => {
              if (res.success && res.data.data.length > 0) {
                const newChackWorkspaceVal = []
                if (createdAffiliate) {
                  selectCompany(res.data.data[0]);
                } else {
                  updateWorkSpaceData.assigned_users_data.map(data => {
                    newChackWorkspaceVal.push({
                      id: data._id,
                      name: data.name,
                      role: getUserRoleType(data)
                    });
                  })
                  selectCompany(res.data.data[0], false, newChackWorkspaceVal);
                }
              }
            })
          }
        }

      } else {
        setAddResearcherLink(true);
        // console.log("updateWorkSpaceData?.assigned_users_data", updateWorkSpaceData?.assigned_users_data);

        let individual_users = updateWorkSpaceData?.assigned_users_data || [];

        if (createdAffiliate) {
          getResearchers({ _id: createdAffiliate._id })
            .then(res => {
              // console.log("res.data.data[0]", res.data.data[0]);
              if (res.success && res.data.data.length > 0) {
                individual_users = [...individual_users, res.data.data[0]];
                // console.log("individual_users", individual_users.length);
                pushData(individual_users, true);
              }
            })
            .catch(error => {
              console.error("Error fetching researchers:", error);
            });
        } else {
          if (individual_users.length > 0) {
            pushData(individual_users, true);
          }
        }

      }

      urlToBase64(STORAGE_BASE_PATH + updateWorkSpaceData?.cover_image, function (base64Img) {
        setImage(base64Img);
      })

      setCheck1(updateWorkSpaceData?.max_pages !== 0 ? true : false);
      setCheck2(updateWorkSpaceData?.max_project_per_page !== 0 ? true : false);
      setCheck3(updateWorkSpaceData?.max_openprojects !== 0 ? true : false);
      setCheck4(updateWorkSpaceData?.max_video_mins !== 0 ? true : false);
      setCheck5((updateWorkSpaceData?.max_activities?.count) !== 0 ? true : false);
      setCheck6(updateWorkSpaceData?.max_researcher !== 0 ? true : false);
      setCheck7(updateWorkSpaceData?.max_collaborator !== 0 ? true : false);
      setCheck8(updateWorkSpaceData?.max_observer !== 0 ? true : false);
      setCheck9(updateWorkSpaceData?.max_participant !== 0 ? true : false);

      // getResearcherList();
      // getCompanyList();
    }
  }

  const handleFileInputChange = (e) => {
    console.log(e);
    const file = e.target.files[0];
    if (file) {
      console.log("file", file);
      const reader = new FileReader();
      setCoverImage(file);
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const getResearcherList = () => {
    getResearchers().then(res => {
      if (res.success && res.data.data.length > 0) {
        setAllResearchersList(res.data.data);
      }
    })
  }

  const getCompanyList = () => {
    getCompanies().then(res => {
      if (res.success && res.data.data.length > 0) {
        setAllCompanyList(res.data.data);
        if (createdAffiliate) {
          // console.log("createdAffiliate", createdAffiliate);
          const createdCompany = res.data.data.find(company => company._id === createdAffiliate._id);
          if (createdCompany) {
            setSelectedCompany(createdCompany);
          }
        }
      }
    })
  }
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const pushData = (obj, arr = false, isCompany = false) => {
    // console.log("obj", obj.length);
    let newChakVal = [...chackVal];
    // if (!isCompany && newChakVal >= allResearchersList)
    //   return false
    console.log("newChakVal", newChakVal);
    const isIdExists = (id) => newChakVal.some(item => (item.id === id));
    if (arr) {
      obj.map(data => {

        if (!isIdExists(data?._id)) {
          newChakVal.push({
            id: data._id,
            name: data.name,
            affiliation: editWorkSpaceApi.workspace_affilation
          });
        }
      })
    } else {
      if (isCompany)
        newChakVal = [];

      newChakVal.push({
        id: obj._id,
        name: obj.name,
        affiliation: editWorkSpaceApi.workspace_affilation
      });
    }

    setChackVal(newChakVal);

    if (validationErrors.hasErr) {
      handleValidation(editWorkSpaceApi, newChakVal);
    }
  };

  const pushWorkspaceData = (obj, arr = false) => {
    let newChackWorkspaceVal = [...chackWorkspaceVal];
    if (newChackWorkspaceVal >= companyUserList)
      return false;

    if (arr) {
      obj.map(data => {
        newChackWorkspaceVal.push({
          id: data._id,
          name: data.name,
          role: obj.role
        });
      })
    } else {
      newChackWorkspaceVal.push({
        id: obj._id,
        name: obj.name,
        role: obj.role
      });
    }

    setChackWorkspaceVal(newChackWorkspaceVal);
    if (validationErrors.hasErr) {
      handleValidation(editWorkSpaceApi, newChackWorkspaceVal);
    }
  };


  const deleteAllCheckData = () => {
    if (editWorkSpaceApi.workspace_affilation === 'Company')
      setChackWorkspaceVal([])
    else
      setChackVal([]);
  };

  const selectAllCheckData = () => {
    if (editWorkSpaceApi.workspace_affilation === 'Company') {
      pushData(allCompanyList, true);
    } else {
      pushData(allResearchersList, true);
    }
  };

  const selectAllWorkspaceCheckData = () => {
    pushWorkspaceData(companyUserList, true);
  }

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setEditWorkspace(false);
    }
  };

  const selectCompany = async (company, isSelect = true, selData = []) => {
    // let companyUsers = [];
    setChackWorkspaceVal([]);
    getWorkspaceUserDropVal();
    let companyUsers = [];
    company?.observers?.forEach((observer) => companyUsers.push({ _id: observer._id, name: observer.name, role: 'Observer' }))
    company?.collaborators?.forEach((collaborator) => companyUsers.push({ _id: collaborator._id, name: collaborator.name, role: 'Collaborator' }))
    company?.researchers?.forEach((researcher) => companyUsers.push({ _id: researcher._id, name: researcher.name, role: 'Researcher' }))

    setShowCompanyUser(true)

    setCompanyUserList(companyUsers);
    setSelectedCompany(company);
    pushData(company, false, true);

    // pushWorkspaceData(companyUsers,true)
    if (isSelect) {
      const newChackWorkspaceVal = []
      companyUsers.map(data => {
        newChackWorkspaceVal.push({
          id: data._id,
          name: data.name,
          role: data.role
        });
      })
      setChackWorkspaceVal(newChackWorkspaceVal);
      setCompanyDrop(false)
    } else {
      setChackWorkspaceVal(selData);
    }
  };


  const handleCompanyPop = () => {
    setCompanyPop(true);
    setWorkspaceCompany(false);
    setEditWorkspaceCompany(true);
    setEditWorkspace(false);
  };

  const handleEditCompanyPop = (companyId) => {
    setAllCompaniesData(companyId);
    setEditCompanyPop(true);
    setEditWorkspace(false);
  };

  const handleResearcherPop = () => {
    setAddResearcherPop(true);
    setWorkspaceResearcher(false);
    setEditWorkspaceResearcher(true);
    setEditWorkspace(false);
  };

  const handleCancel = () => {
    setEditWorkspace(false);
    setAddResearcherLink(false);
    setAddCompanyLink(false);
  };

  useEffect(() => {
    if (editWorkspace) {
      getCompanyList();
      getResearcherList();
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [editWorkspace]);


  const setFormValue = (e) => {
    let newFormData = {
      ...editWorkSpaceApi,
      [e.target.name]: e.target.value
    };
    setEditWorkSpaceApi(newFormData);
    if (validationErrors.hasErr) {
      handleValidation(newFormData);
    }
  }

  const setDateTimeVal = (name, value) => {
    let year = value.$y;
    let month = (value.$M < 9) ? '0' + (value.$M + 1) : value.$M + 1;
    let day = (value.$D < 10) ? '0' + value.$D : value.$D;

    let hour = (value.$H < 10) ? '0' + value.$H : value.$H;
    let min = (value.$m < 10) ? '0' + value.$m : value.$m;
    let sec = (value.$s < 10) ? '0' + value.$s : value.$s;

    let newFormData = {
      ...editWorkSpaceApi,
      [name]: (['start_date', 'end_date'].includes(name)) ? year + '-' + month + '-' + day : hour + ':' + min + ':' + sec
    };
    setEditWorkSpaceApi(newFormData);
    if (validationErrors.hasErr) {
      handleValidation(newFormData);
    }
  }

  const getAffiliateDropVal = () => {
    let dropdownLabel = '';
    if (chackVal.length <= 2) {
      chackVal.map((val, index) => {
        if (index > 0) {
          dropdownLabel += ', ';
        }
        dropdownLabel += val.name;
      });
    } else {
      dropdownLabel = chackVal.length + ' Selected';
    }
    return dropdownLabel;
  }

  let updateWorkspaceApi = async (status = null) => {
    if (!handleValidation()) {
      toast.error('Please correct form fields', TOAST_CONFIG);
      return false;
    }

    setLoading(true);
    try {
      let researcherCount = 0
      let collaboratorCount = 0
      let observerCount = 0
      let participantCount = 0;

      let users = chackVal.map((curElem) => {
        if (curElem.affiliation != 'Company')
          researcherCount += 1;

        return {
          user_id: curElem.id,
          user_role: (curElem.affiliation == 'Company') ? 'company' : 'researcher',
        }
      })

      if (editWorkSpaceApi.workspace_affilation == "Company") {
        chackWorkspaceVal.forEach((curElem) => {
          if (curElem.role == "Researcher")
            researcherCount += 1
          else if (curElem.role == "Collaborator")
            collaboratorCount += 1
          else if (curElem.role == "Observer")
            observerCount += 1
          else if (curElem.role == "Participant")
            participantCount += 1
          users.push({
            user_id: curElem.id,
            user_role: curElem.role.toLowerCase(),
          })
        });
      }

      const configValidationSt = [
        { count: researcherCount, max: editWorkSpaceApi.max_researcher, role: 'researcher' },
        { count: collaboratorCount, max: editWorkSpaceApi.max_collaborator, role: 'collaborator' },
        { count: observerCount, max: editWorkSpaceApi.max_observer, role: 'observer' },
        { count: participantCount, max: editWorkSpaceApi.max_participant, role: 'participant' }
      ];

      for (const { count, max, role } of configValidationSt) {
        if (count > 0 && count > max) {
          toast.error(`The number of ${role}s exceeds the maximum limit of ${max}. Please correct the form fields.`, TOAST_CONFIG);
          return false;
        }
      }

      let requestPayload = {
        ...editWorkSpaceApi,
        status: (status == null) ? editWorkSpaceApi.status : status,
        assigned_users: users
      }
      // console.log("requestPayload",requestPayload);return;
      let workSpaceRes = await updateWorkSpaceManagementApi(updateWorkSpaceData, requestPayload, authState.authToken);
      setLoading(false);
      if (workSpaceRes.success) {
        toast.success(workSpaceRes.message, TOAST_CONFIG);
        // resetForm();
        if (coverImage) {
          updateImage(updateWorkSpaceData);
        } else {
          setReloadList(true);
        }
        handleCancel();
      } else {
        toast.error(workSpaceRes.message, TOAST_CONFIG);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  const updateImage = async (workspaceId) => {
    if (!workspaceId)
      return false;
    if (!coverImage)
      return false;
    const formData = new FormData()
    formData.append('cover_image', coverImage)
    try {
      let imageUploadRes = await uploadCoverImage(workspaceId, formData, authState.authToken);
      if (imageUploadRes.success) {
        setReloadList(true);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    } finally {
      setImage(null);
      setCoverImage(null);
    }
  };

  const getWorkspaceUserDropVal = () => {
    let dropdownLabel = '';
    const selectedUser = chackWorkspaceVal.filter(el => el.role !== 'Company');
    if (selectedUser.length <= 2) {
      selectedUser.map((val, index) => {
        if (index > 0) {
          dropdownLabel += ', ';
        }
        dropdownLabel += val.name;
      });
    } else {
      dropdownLabel = selectedUser.length + ' Selected';
    }
    return dropdownLabel;
  }

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "280px",
        overflowY: "auto",
      },
    },
  };

  return (
    <>
      <Box
        id="popBackdrop"
        sx={{ display: editWorkspace ? "flex" : "none" }}
        className="workspacePop "
        onClick={handelPopClose}
      >
        <Box
          sx={{ display: editWorkspace ? "flex" : "none" }}
          className="workspaceContaint EditworkspacePop"
        >
          <Typography className="workspaceHeader">
            Edit: {editWorkSpaceApi.name} ({editWorkSpaceApi.status})
          </Typography>
          <Typography className="workspaceLabel">Workspace Name</Typography>
          <input
            name="name"
            className="workspaceInput workspaceInputName"
            placeholder="Abc Ltd.'s Workspace"
            onChange={setFormValue}
            value={editWorkSpaceApi.name}
          />
          <span className="validationerr inputerr">{validationErrors.name.msg}</span>
          <Box className="workspaceSettingNav">
            <Box
              onClick={() => setSettingTabe(0)}
              className={
                settingTabe === 0
                  ? "workSetNavTabe workSetNavTabeActive"
                  : "workSetNavTabe"
              }
            >
              <Typography>Basic Settings</Typography>
            </Box>
            <Box
              onClick={() => setSettingTabe(1)}
              className={
                settingTabe === 1
                  ? "workSetNavTabe workSetNavTabeActive"
                  : "workSetNavTabe"
              }
            >
              <Typography>Customizations</Typography>
            </Box>
          </Box>

          <Box
            sx={{ display: settingTabe === 0 ? "flex" : "none" }}
            className="settingBox"
          >
            <Typography mb={1} className="workspaceLabel">
              Workspace cover image
            </Typography>
            <Box className="ipageUplod">
              <Box className="imgBox">
                {image ? (
                  <img className="fileinputimg" src={image} alt="Upload" />
                ) : (
                  // <img src={plassIcon} alt="Upload" />
                  <img src={image || uploadimg} />
                )}

              </Box>

              <Box className="textBox">
                {/* <Typography className="teraText">
                  Image.png Uploaded!
                </Typography> */}
                <Box mb={2}>
                  <img src={uploadIcon} />
                  <Typography
                    ml={2}
                    onClick={() => {
                      fileInputRef.current.click();
                    }}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  >
                    Change Image
                  </Typography>
                  <input
                    ref={fileInputRef}
                    id="fileInput"
                    hidden
                    type="file"
                    onChange={handleFileInputChange}
                  />
                </Box>
                {/* <Box>
                  <img src={trasIcon} />
                  <Typography ml={2}>Delete</Typography>
                </Box> */}
              </Box>
            </Box>
            <Typography className="fileInputLabel">
              Click or drag image to this area to upload
            </Typography>
            <Box className="basicBox">
              <Box>
                <Typography className="workspaceLabel">Start Date</Typography>
                <Box className="pickerBox">
                  <ReferenceDateUsingValue name="start_date" value={dayjs(editWorkSpaceApi.start_date)} onChange={(e) => setDateTimeVal('start_date', e)} />
                  <span className="validationerr">{validationErrors.start_date.msg}</span>
                </Box>
                <Box className="pickerBox">
                  <ReferenceDateExplicitTimePicker placeholder="Select Start Time" name="start_time" value={dayjs(editWorkSpaceApi.start_time, "HH:mm:ss")} onChange={(e) => { setDateTimeVal('start_time', e) }} />
                  <span className="validationerr">{validationErrors.start_time.msg}</span>
                </Box>
              </Box>

              <Box>
                <Typography className="workspaceLabel">End Date</Typography>
                <Box className="pickerBox">
                  <ReferenceDateUsingValue name="end_date" value={dayjs(editWorkSpaceApi.end_date)} onChange={(e) => { setDateTimeVal('end_date', e) }} />
                  <span className="validationerr">{validationErrors.end_date.msg}</span>
                </Box>
                <Box className="pickerBox">
                  <ReferenceDateExplicitTimePicker placeholder="Select End Time" name="end_time" value={dayjs(editWorkSpaceApi.end_time, "HH:mm:ss")} onChange={(e) => { setDateTimeVal('end_time', e) }} />
                  <span className="validationerr">{validationErrors.end_time.msg}</span>
                </Box>
              </Box>
            </Box>

            <Box>
              <Typography className="workspaceLabel">
                Workspace Affiliation
              </Typography>
              <Box className="basicBox lastBasicBox">
                <Box className="inputBox">
                  <input
                    onClick={() => setCategoryDrop(!categoryDrop)}
                    value={categoryDropVal}
                    className="workspaceInput categoryInput"
                    placeholder="Choose a Category"
                  />
                  <img
                    style={{ rotate: categoryDrop ? "180deg" : "0deg" }}
                    onClick={() => setCategoryDrop(!categoryDrop)}
                    className="downArrow"
                    src={DownArrowIcon}
                  />

                  <Box
                    sx={{ height: categoryDrop ? "auto" : "0px" }}
                    className="categoryDrop"
                  >
                    <Box
                      onClick={() => {
                        let newState = { ...editWorkSpaceApi, workspace_affilation: 'Company' };
                        setEditWorkSpaceApi(newState)
                        if (validationErrors.hasErr) {
                          handleValidation(newState)
                        }
                        deleteAllCheckData();
                        setCategoryDropVal("Company");
                        setCategoryDrop(false);
                        setAddCompanyLink(true);
                        setShowCompanyUser(true);
                        setAddResearcherLink(false);
                      }}
                      className="categoryDropItem"
                    >
                      <Typography>Company</Typography>
                    </Box>
                    <Box
                      onClick={() => {
                        let newState = { ...editWorkSpaceApi, workspace_affilation: 'Individual' };
                        setEditWorkSpaceApi(newState)
                        if (validationErrors.hasErr) {
                          handleValidation(newState)
                        }
                        deleteAllCheckData();
                        setCategoryDropVal("Individual");
                        setCategoryDrop(false);
                        setAddCompanyLink(false);
                        setShowCompanyUser(false);
                        setAddResearcherLink(true);
                        setChackVal([])
                      }}
                      className="categoryDropItem"
                    >
                      <Typography>Individual</Typography>
                    </Box>
                  </Box>
                  <span className="validationerr">{validationErrors.workspace_affiliation.msg}</span>
                </Box>

                <Box className="dropDownBox">
                  {/* <Box
                    sx={{ display: addResearcherLink ? "flex" : "none" }}
                    className="inputBox"
                  >
                    <input
                      value={getAffiliateDropVal()}
                      className="workspaceInput"
                      placeholder="Choose a Researcher"
                      onClick={() => setresearcherDrop(!researcherDrop)}
                    />
                    <img className="rightIcon" src={searchIcon} />
                    <img
                      style={{ rotate: researcherDrop ? "180deg" : "0deg" }}
                      className="downArrow"
                      src={DownArrowIcon}
                      onClick={() => setresearcherDrop(!researcherDrop)}
                    />

                    <Box
                      sx={{ height: researcherDrop ? "280px" : "0px" }}
                      className="categoryDrop researcherDrop"
                    >
                      <Box className="researchDropTopLabel">
                        <Typography onClick={selectAllCheckData}>
                          Select All
                        </Typography>
                        <Typography onClick={deleteAllCheckData}>
                          Deselect All
                        </Typography>
                      </Box>
                      {console.log("allResearchersList", allResearchersList)}
                      {console.log("chackVal", chackVal)}
                      {
                        allResearchersList.map((researcher, index) => {
                          // const isSelected = chackVal.some(el => el.id === researcher._id);
                          return (
                            <Box
                              key={researcher._id}
                              onClick={() => {
                                if (chackVal.some(el => el.id === researcher._id)) {
                                  setChackVal(
                                    chackVal.filter((item) => item.id !== researcher._id)
                                  );
                                } else {
                                    pushData(researcher);
                                  }
                                }}
                                className="categoryDropItem"
                            >
                              <Box className="chackBox">
                                <img className="chackBoxImg" src={chackBox} />
                                <img
                                  style={{
                                    display: chackVal.some(el => el.id === researcher._id)
                                      ? "block"
                                      : "none",
                                  }}
                                  className="chack"
                                  src={check}
                                />
                              </Box>
                              <Typography>{researcher.name}</Typography>
                            </Box>
                          )
                        })
                      }
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: addCompanyLink ? "flex" : "none" }}
                    className="inputBox"
                  >
                    <input
                      className="workspaceInput"
                      // value={getAffiliateDropVal()}
                      value={selectedCompany ? selectedCompany.name : ""}
                      placeholder="Choose a Company"
                      onClick={() => setCompanyDrop(!companyDrop)}
                    />
                    <img className="rightIcon" src={searchIcon} />
                    <img
                      style={{ rotate: researcherDrop ? "180deg" : "0deg" }}
                      className="downArrow"
                      src={DownArrowIcon}
                      onClick={() => setCompanyDrop(!companyDrop)}
                    />

                    <Box
                      sx={{ height: companyDrop ? "280px" : "0px" }}
                      className="categoryDrop researcherDrop"
                    >
                      {
                        allCompanyList.map((company, index) => {
                          return (
                            <Box
                              key={company._id}
                              onClick={() => selectCompany(company)}
                              className={`categoryDropItem ${selectedCompany && selectedCompany._id === company._id
                                ? "selected"
                                : ""
                                }`}
                            >
                              <Typography>{company.name}</Typography>
                            </Box>
                          )
                        }
                        )
                      }
                    </Box>
                  </Box> */}
                  
                  {addResearcherLink && (
                    <FormControl sx={{ width: "100%" }} size="small">
                      <InputLabel id="researcher-select-label">Choose a Researcher</InputLabel>
                      <Select
                        labelId="researcher-select-label"
                        id="researcher-select"
                        value={getAffiliateDropVal()}
                        onChange={(e) => setresearcherDrop(!researcherDrop)}
                        MenuProps={MenuProps}
                        renderValue={(selected) => selected}
                        input={<OutlinedInput label="Choose a Researcher" />}
                      >
                        <Box className="researchDropTopLabel">
                          <MenuItem onClick={selectAllCheckData}>Select All</MenuItem>
                          <MenuItem onClick={deleteAllCheckData}>Deselect All</MenuItem>
                        </Box>
                        {allResearchersList.map((researcher) => (
                          <MenuItem
                            key={researcher._id}
                            value={researcher.name}
                            onClick={() => {
                              if (chackVal.some(el => el.id === researcher._id)) {
                                setChackVal(chackVal.filter(item => item.id !== researcher._id));
                              } else {
                                pushData(researcher);
                              }
                            }}
                          >
                            <Checkbox checked={chackVal.some(el => el.id === researcher._id)} />
                            <ListItemText primary={researcher.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {addCompanyLink && (
                    <FormControl sx={{ width: "100%" }} size="small">
                      <InputLabel id="company-select-label">Choose a Company</InputLabel>
                      <Select
                        labelId="company-select-label"
                        id="company-select"
                        value={selectedCompany ? selectedCompany.name : ""}
                        onChange={(e) => setCompanyDrop(!companyDrop)}
                        MenuProps={MenuProps}
                        renderValue={(selected) => selected}
                        input={<OutlinedInput label="Choose a Company" />}
                      >
                        {allCompanyList.map((company) => (
                          <MenuItem
                            key={company._id}
                            value={company.name}
                            onClick={() => selectCompany(company)}
                            className={selectedCompany && selectedCompany._id === company._id ? "selected" : ""}
                          >
                            <ListItemText primary={company.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  <span className="validationerr">{(editWorkSpaceApi.workspace_affilation != '') ? validationErrors.assigned_users.msg : ''}</span>
                </Box>
                {addCompanyLink && <Typography onClick={() => { handleEditCompanyPop(selectedCompany?._id); setWorkspacePopType('edit'); }} className="optionPopItema companyedit">
                  {editIconBlack}
                </Typography>}
              </Box>
            </Box>
            <Box className="newLinks">
              <Box sx={{ display: addResearcherLink ? "flex" : "none" }}>
                <Typography
                  className="newLinkItem"
                  onClick={() => { handleResearcherPop(); setWorkspacePopType('edit') }}
                >
                  <span>+</span> Add New Researcher
                </Typography>
              </Box>
              {showCompanyUser && <Box
                sx={{ display: "flex" }}
                className="inputBox"
              >
                <input
                  value={getWorkspaceUserDropVal()}
                  className="workspaceInput"
                  placeholder="Choose workspace users"
                  onClick={() => setWorkspaceUserDrop(!workspaceUserDrop)}
                />
                <img className="rightIcon" src={searchIcon} />
                <img
                  style={{ rotate: workspaceUserDrop ? "180deg" : "0deg" }}
                  className="downArrow"
                  src={DownArrowIcon}
                  onClick={() => setWorkspaceUserDrop(!workspaceUserDrop)}
                />

                <Box
                  sx={{ height: workspaceUserDrop ? "280px" : "0px" }}
                  className="categoryDrop researcherDrop"
                >
                  <Box className="researchDropTopLabel">
                    <Typography onClick={selectAllWorkspaceCheckData}>
                      Select All
                    </Typography>
                    <Typography onClick={deleteAllCheckData}>
                      Deselect All
                    </Typography>
                  </Box>
                  {
                    companyUserList.map((user, index) => {
                      return (
                        <Box
                          onClick={() => {
                            if (chackWorkspaceVal.some(el => el.id === user._id)) {
                              setChackWorkspaceVal(
                                chackWorkspaceVal.filter((item) => item.id !== user._id)
                              );
                            } else {
                              pushWorkspaceData(user);
                            }
                          }}
                          className="categoryDropItem"
                        >
                          <Box className="chackBox">
                            <img className="chackBoxImg" src={chackBox} />
                            <img
                              style={{
                                display: chackWorkspaceVal.some(el => el.id === user._id)
                                  ? "block"
                                  : "none",
                              }}
                              className="chack"
                              src={check}
                            />
                          </Box>
                          <Typography>{user.name} ({user.role})</Typography>
                        </Box>
                      )
                    })
                  }
                </Box>
              </Box>}
              <Box sx={{ display: addCompanyLink ? "flex" : "none" }}>
                <Typography
                  className="newLinkItem"
                  onClick={() => { handleCompanyPop(); setWorkspacePopType('edit') }}
                >
                  <span>+</span> Add New Company
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{ display: settingTabe === 1 ? "flex" : "none" }}
            className="settingBox"
          >
            <Typography className="cusHeaderLabel">Setup Limits</Typography>
            <Box onClick={() => {
              setCheck1(!check1);
              if (check1) {
                setEditWorkSpaceApi({ ...editWorkSpaceApi, 'max_pages': 0 })
              }
            }} className="checkBox">
              <Box className="chackBox">
                <img className="chackBoxImg" src={chackBox} />
                <img
                  style={{ display: check1 ? "block" : "none" }}
                  className="chack"
                  src={check}
                />
              </Box>
              <Typography>Limit maximum number of Site Pages</Typography>
            </Box>
            {check1 && <Box className="subInputBox">
              <input
                value={editWorkSpaceApi?.max_pages}
                className="CSPInput workspaceInput"
                type="number"
                onChange={(e) => { setEditWorkSpaceApi({ ...editWorkSpaceApi, 'max_pages': e.target.value }) }}
              />
              <Typography>Maximum site pages</Typography>
            </Box>}

            <Box onClick={() => {
              setCheck2(!check2);
              if (check2) {
                setEditWorkSpaceApi({ ...editWorkSpaceApi, 'max_project_per_page': 0 })
              }
            }} className="checkBox">
              <Box className="chackBox">
                <img className="chackBoxImg" src={chackBox} />
                <img
                  style={{ display: check2 ? "block" : "none" }}
                  className="chack"
                  src={check}
                />
              </Box>
              <Typography>
                Limit maximum number of open Projects per Site Page
              </Typography>
            </Box>
            {check2 && <Box className="subInputBox">
              <input
                value={editWorkSpaceApi?.max_project_per_page || 0}
                className="CSPInput workspaceInput"
                type="number"
                onChange={(e) => { setEditWorkSpaceApi({ ...editWorkSpaceApi, 'max_project_per_page': e.target.value }) }}
              />
              <Typography>Maximum projects per site</Typography>
            </Box>}

            <Box onClick={() => {
              setCheck3(!check3);
              if (check3) {
                setEditWorkSpaceApi({ ...editWorkSpaceApi, 'max_openprojects': 0 })
              }
            }} className="checkBox">
              <Box className="chackBox">
                <img className="chackBoxImg" src={chackBox} />
                <img
                  style={{ display: check3 ? "block" : "none" }}
                  className="chack"
                  src={check}
                />
              </Box>
              <Typography>
                Limit maximum number of total open Projects
              </Typography>
            </Box>
            {check3 && <Box className="subInputBox">
              <input
                value={editWorkSpaceApi?.max_openprojects}
                className="CSPInput workspaceInput"
                type="number"
                onChange={(e) => setEditWorkSpaceApi({ ...editWorkSpaceApi, 'max_openprojects': e.target.value })}
              />
              <Typography>Projects</Typography>
            </Box>}

            <Box onClick={() => {
              setCheck4(!check4);
              if (check4) {
                setEditWorkSpaceApi({ ...editWorkSpaceApi, 'max_video_mins': 0 })
              }
            }} className="checkBox">
              <Box className="chackBox">
                <img className="chackBoxImg" src={chackBox} />
                <img
                  style={{ display: check4 ? "block" : "none" }}
                  className="chack"
                  src={check}
                />
              </Box>
              <Typography>
                Limit maximum number of video minutes for all activities in
                total
              </Typography>
            </Box>
            {check4 && <Box className="subInputBox">
              <input
                value={editWorkSpaceApi?.max_video_mins}
                className="CSPInput workspaceInput"
                type="number"
                onChange={(e) => setEditWorkSpaceApi({ ...editWorkSpaceApi, 'max_video_mins': e.target.value })}
              />
              <Typography>Maximum Number of Video minutes</Typography>
            </Box>}

            <Box onClick={() => {
              setCheck5(!check5);
              if (check5) {
                setEditWorkSpaceApi({
                  ...editWorkSpaceApi, 'max_activities': {
                    "count": 0,
                    "duration_count": 1,
                    "duration": "week"
                  }
                })
              }
            }} className="checkBox">
              <Box className="chackBox">
                <img className="chackBoxImg" src={chackBox} />
                <img
                  style={{ display: check5 ? "block" : "none" }}
                  className="chack"
                  src={check}
                />
              </Box>
              <Typography>
                Limit maximum number of activities for all projects in total
              </Typography>
            </Box>

            <Box className="subInputBox">
              {check5 && <>
                <input
                  value={editWorkSpaceApi?.max_activities?.count}
                  className="CSPInput workspaceInput"
                  type="number"
                  onChange={(e) => setEditWorkSpaceApi({
                    ...editWorkSpaceApi, 'max_activities': {
                      "count": e.target.value,
                      "duration_count": editWorkSpaceApi?.max_activities?.duration_count,
                      "duration": editWorkSpaceApi?.max_activities?.duration
                    }
                  })}
                />
                <Typography>activities per</Typography>

                <Box
                  onClick={() => setMonthDrop(!monthDrop)}
                  className="CSmonthDrop"
                >
                  <Typography>{`${editWorkSpaceApi?.max_activities?.duration_count} ${editWorkSpaceApi?.max_activities?.duration}`}</Typography>
                  <img
                    style={{ rotate: monthDrop ? "180deg" : "0deg" }}
                    className="MdownArrow"
                    src={DownArrowIcon}
                    onClick={() => setMonthDrop(!monthDrop)}
                  />
                  <Box
                    sx={{ height: monthDrop ? "auto" : "0px" }}
                    className="monDropIbox"
                  >
                    <Box
                      onClick={() => {
                        setEditWorkSpaceApi({
                          ...editWorkSpaceApi, 'max_activities': {
                            "count": editWorkSpaceApi?.max_activities?.count,
                            "duration_count": 1,
                            "duration": "week"
                          }
                        })
                      }}
                      className="monDropItem"
                    >
                      <Typography>1 week</Typography>
                    </Box>
                    <Box
                      onClick={() => {
                        setEditWorkSpaceApi({
                          ...editWorkSpaceApi, 'max_activities': {
                            "count": editWorkSpaceApi?.max_activities?.count,
                            "duration_count": 2,
                            "duration": "week"
                          }
                        })
                      }}
                      className="monDropItem"
                    >
                      <Typography>2 week</Typography>
                    </Box>
                    <Box
                      onClick={() => {
                        setEditWorkSpaceApi({
                          ...editWorkSpaceApi, 'max_activities': {
                            "count": editWorkSpaceApi?.max_activities?.count,
                            "duration_count": 3,
                            "duration": "week"
                          }
                        })
                      }}
                      className="monDropItem"
                    >
                      <Typography>3 week</Typography>
                    </Box>
                    <Box
                      onClick={() => {
                        setEditWorkSpaceApi({
                          ...editWorkSpaceApi, 'max_activities': {
                            "count": editWorkSpaceApi?.max_activities?.count,
                            "duration_count": 1,
                            "duration": "month"
                          }
                        })
                      }}
                      className="monDropItem"
                    >
                      <Typography>1 month</Typography>
                    </Box>
                  </Box>
                </Box></>}
            </Box>
            <Typography className="cusHeaderLabel">User Limits</Typography>
            <Box onClick={() => {
              setCheck6(!check6);
              if (check6) {
                setEditWorkSpaceApi({ ...editWorkSpaceApi, 'max_researcher': 0 })
              }
            }} className="checkBox">
              <Box className="chackBox">
                <img className="chackBoxImg" src={chackBox} />
                <img
                  style={{ display: check6 ? "block" : "none" }}
                  className="chack"
                  src={check}
                />
              </Box>
              <Typography>Limit maximum number of Researchers</Typography>
            </Box>
            {check6 && <Box className="subInputBox">
              <input
                value={editWorkSpaceApi?.max_researcher}
                className="CSPInput workspaceInput"
                type="number"
                onChange={(e) => setEditWorkSpaceApi({ ...editWorkSpaceApi, 'max_researcher': e.target.value })}
              />
              <Typography>Maximum Researchers</Typography>
            </Box>}

            <Box onClick={() => {
              setCheck7(!check7);
              if (check7) {
                setEditWorkSpaceApi({ ...editWorkSpaceApi, 'max_collaborator': 0 })
              }
            }} className="checkBox">
              <Box className="chackBox">
                <img className="chackBoxImg" src={chackBox} />
                <img
                  style={{ display: check7 ? "block" : "none" }}
                  className="chack"
                  src={check}
                />
              </Box>
              <Typography>Limit maximum number of Collaborators</Typography>
            </Box>
            {check7 && <Box className="subInputBox">
              <input
                value={editWorkSpaceApi?.max_collaborator}
                className="CSPInput workspaceInput"
                type="number"
                onChange={(e) => setEditWorkSpaceApi({ ...editWorkSpaceApi, 'max_collaborator': e.target.value })}
              />
              <Typography>Maximum Collaborators</Typography>
            </Box>}

            <Box onClick={() => {
              setCheck8(!check8);
              if (check8) {
                setEditWorkSpaceApi({ ...editWorkSpaceApi, 'max_observer': 0 })
              }
            }} className="checkBox">
              <Box className="chackBox">
                <img className="chackBoxImg" src={chackBox} />
                <img
                  style={{ display: check8 ? "block" : "none" }}
                  className="chack"
                  src={check}
                />
              </Box>
              <Typography>Limit maximum number of Observers</Typography>
            </Box>
            {check8 && <Box className="subInputBox">
              <input
                value={editWorkSpaceApi?.max_observer}
                className="CSPInput workspaceInput"
                type="number"
                onChange={(e) => setEditWorkSpaceApi({ ...editWorkSpaceApi, 'max_observer': e.target.value })}
              />
              <Typography>Maximum Observers</Typography>
            </Box>}

            <Box onClick={() => {
              setCheck9(!check9);
              if (check9) {
                setEditWorkSpaceApi({ ...editWorkSpaceApi, 'max_participant': 0 })
              }
            }} className="checkBox">
              <Box className="chackBox">
                <img className="chackBoxImg" src={chackBox} />
                <img
                  style={{ display: check9 ? "block" : "none" }}
                  className="chack"
                  src={check}
                />
              </Box>
              <Typography>Limit maximum number of Participants</Typography>
            </Box>
            {check9 && <Box className="subInputBox">
              <input
                value={editWorkSpaceApi.max_participant}
                className="CSPInput workspaceInput"
                type="number"
                onChange={(e) => setEditWorkSpaceApi({ ...editWorkSpaceApi, 'max_participant': e.target.value })}
              />
              <Typography>Maximum Participants</Typography>
            </Box>}
          </Box>
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={() => handleCancel()} />
            <AppBtnOutLine buttonText="Save as Draft" onClick={() => updateWorkspaceApi('draft')} />
            <AppBtn buttonText="Update" onClick={() => updateWorkspaceApi()} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
