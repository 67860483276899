import React, { useState } from 'react'
import { Box, Typography, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./style.css"

//images
import clogsvg from "../../../Assets/Images/New folder/Icon TeraPage Transparent mix dark 2.svg";
import SClolo from "../../../Assets/Images/TeraPage Transparent mix black.png";
import backIcon from "../../../Assets/Images/WhiteBack Button.png";
import WAIcon01 from "../../../Assets/Images/icon-WA01.svg";
import WAIcon02 from "../../../Assets/Images/icon-WA02.svg";
import WAIcon03 from "../../../Assets/Images/icon-WA03.svg";
import WAIcon04 from "../../../Assets/Images/icon-WA04.svg";
import WAIcon05 from "../../../Assets/Images/icon-WA05.svg";
import WAIcon06 from "../../../Assets/Images/icon-WA06.svg";
import WAIcon07 from "../../../Assets/Images/icon-WA07.svg";
import InformationIcon from "../../../Assets/Images/InformationIcon.png";
import BasicSettingIcon from "../../../Assets/Images/AdSettingIcon.png";
import SubscriptionIcon from "../../../Assets/Images/SubscriptionIcon.png";
import AccountIcon from "../../../Assets/Images/AccountIcon.png";
import EmailIcon from "../../../Assets/Images/mailBicon.png";
import SiteNoticesIcon from "../../../Assets/Images/SiteNoticesIcon.png";
import AppearanceIcon from "../../../Assets/Images/Traced Image.png";
import SiteLogIcon from "../../../Assets/Images/book.png";
import AgreementsIcon from "../../../Assets/Images/editIcon.png";
import TemplatesIcon from "../../../Assets/Images/AdFolderIcon.png";
import {
  initialSidebars,
  setSidebarDropdowns,
} from "../../../lib/store/features/sidebarSlice";
import { useAppDispatch, useAppSelector } from '../../../lib/store/store';

export default function SideMenu({ slideMenu, participantsSubMenu, setParticipantsSubMenu, configurationSubMenu, setConfigurationSubMenu }) {

  const sidebarState = useAppSelector((state) => state.sidebar);
  let dispatch = useAppDispatch();

  const currentUrl = new URL(window.location.href).pathname;
  const Navigate = useNavigate();
  const [sideTabe, setSideTebe] = useState(0);

  const [participantsSubMenuVal, setParticipantsSubMenuVal] = useState();
  const [addParticipants, setAppParticipants] = useState(false);
  const [addProfileField, setAddProfileField] = useState(false);
  const [ConfigurationSubMenuVal, setConfigurationSubMenuVal] = useState();

  const [NewAdminSection, setNewAdminSection] = useState(false);
  const [EditAdminSection, setEditAdminSection] = useState(false);
  const [inviteAdminPop, setInviteAdminPop] = useState(false);
  const [configTemplatePop, setConfigTemplatePop] = useState(false);
  const [addAgreementPop, setAddAgreementPop] = useState(false);



  const handleUserOpenTabe = (index) => {
    setSideTebe(index);
    setParticipantsSubMenuVal(null);
    setConfigurationSubMenuVal(null);
  };


  const handleConfigurationOpenTabe = (index) => {
    setConfigurationSubMenuVal(index);
    setParticipantsSubMenuVal(null);
    setSideTebe(null);
  };

  const handelConfigurationTab = (Url) => {
    Navigate(Url)
  }

  const navigateSidebar = (url = null, dropdownType = null) => {
    let dropdowns = {
      ...initialSidebars,
    };
    switch (dropdownType) {
      case "allParticipantsDrop":
        dispatch(
          setSidebarDropdowns({
            ...dropdowns,
            allParticipantsDrop:
              url == null ? !sidebarState.dropdowns.allParticipantsDrop : true,
            // workspaceItemNavDrop: true,
          })
        );
        break;
        case "configurationDrop":
        dispatch(
          setSidebarDropdowns({
            ...dropdowns,
            configurationDrop:
              url == null ? !sidebarState.dropdowns.configurationDrop : true,
            // workspaceItemNavDrop: true,
          })
        );
        break;
      default:
        dispatch(setSidebarDropdowns(dropdowns));
      break;
    }

    if (url != null) {
      Navigate(url);
    }
  }

  return (
    <>
      <Box className={slideMenu ? "sideMenu sideMenuActive researcherSideMenu" : "sideMenu researcherSideMenu"}>
        <Box onClick={() => Navigate("/")} className="clogBox">
          {slideMenu ? (
            <img
              style={{ width: "176.442px", height: "20px" }}
              src={clogsvg}
            />
          ) : (
            <img style={{ width: "30px" }} src={SClolo} />
          )}
        </Box>
        <Typography
          variant="h4"
          className="submenuheading"
          sx={{ display: slideMenu ? "block" : "none" }}
        >
          Workspace Administration
        </Typography>
        <Box className="navHrLine"></Box>

        <Box
          onClick={() => {
            navigateSidebar(null, "allParticipantsDrop");
          }}
          className="AdSideMenuItem"
          title={slideMenu ? '' : 'Participants'}
        >
          <img src={WAIcon01} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Participants
          </Typography>
        </Box>
        
        <Box
            sx={{
              display: sidebarState.dropdowns.allParticipantsDrop
                ? "flex"
                : "none",
            }}
            className="UserSubNavMenu ParticpantsMenu"
          >
            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/all-participants",
                  "allParticipantsDrop"
                )
              }
              className={
                currentUrl === "/workspace-administration/all-participants"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'All Participants'}
            >
              <img src={WAIcon02} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                All Participants
              </Typography>
            </Box>
            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/group",
                  "allParticipantsDrop"
                )
              }
              className={
                currentUrl === "/workspace-administration/group"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'Groups'}
            >
              <img src={WAIcon03} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                Groups
              </Typography>
            </Box>
            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/participant-profiling",
                  "allParticipantsDrop"
                )
              }
              className={
                currentUrl ===
                "/workspace-administration/participant-profiling"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'Participant Profiling'}
            >
              <img src={WAIcon04} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                Participant Profiling
              </Typography>
            </Box>
            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/bulk-import",
                  "allParticipantsDrop"
                )
              }
              className={
                currentUrl === "/workspace-administration/bulk-import"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'Bulk Import'}
            >
              <img src={WAIcon05} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                Bulk Import
              </Typography>
            </Box>
          </Box>

        {/* <Box
          onClick={() => {
            setParticipantsSubMenu(!participantsSubMenu);
            handleUserOpenTabe(0);
          }}
          className={
            sideTabe === 0
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
        >
          <img src={WAIcon01} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Participants
          </Typography>
        </Box> */}
        
        {/* sub menu */}
        {/* <Box
          sx={{ display: participantsSubMenu ? "flex" : "none" }}
          className=" UserSubNavMenu"
        >
          <Box
            onClick={() => handelConfigurationTab("/workspace-administration/all-participants")}
            className={currentUrl === "/workspace-administration/all-participants" ? "AdSideMenuItem AdSideMenuItemActive" : "AdSideMenuItem"}>
            <img src={WAIcon02} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              All Participants
            </Typography>
          </Box>

          <Box
            onClick={() => handelConfigurationTab("/workspace-administration/group")}
            className={currentUrl === "/workspace-administration/group" ? "AdSideMenuItem AdSideMenuItemActive" : "AdSideMenuItem"}>
            <img src={WAIcon03} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Groups
            </Typography>
          </Box>
          <Box
            onClick={() => handelConfigurationTab("/workspace-administration/participant-profiling")}
            className={currentUrl === "/workspace-administration/participant-profiling" ? "AdSideMenuItem AdSideMenuItemActive" : "AdSideMenuItem"}>
            <img src={WAIcon04} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Participant Profiling
            </Typography>
          </Box>
          <Box
            onClick={() => handelConfigurationTab("/workspace-administration/Bulk-import")}
            className={currentUrl === "/workspace-administration/Bulk-import" ? "AdSideMenuItem AdSideMenuItemActive" : "AdSideMenuItem"}>
            <img src={WAIcon05} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Bulk Import
            </Typography>
          </Box>
        </Box> */}
        {/* sub menu end*/}

        <Box
          onClick={() =>
            navigateSidebar(
              "/workspace-administration/admin",
              // "allParticipantsDrop"
            )
          }
          className={
            currentUrl === "/workspace-administration/admin"
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
          title={slideMenu ? '' : 'Admins'}
        >
          <img src={WAIcon06} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Admins
          </Typography>
        </Box>
        {/* <Box
          onClick={() => handelConfigurationTab("/workspace-administration/admin")}
          className={currentUrl === "/workspace-administration/admin" ? "AdSideMenuItem AdSideMenuItemActive" : "AdSideMenuItem"}>
          <img src={WAIcon06} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Admins
          </Typography>
        </Box> */}
        <Box
            onClick={() => navigateSidebar(null, "configurationDrop")}
            className={
              sidebarState.dropdowns.configurationDrop
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
            title={slideMenu ? '' : 'Configuration'}
          >
            <img src={WAIcon07} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Configuration
            </Typography>
          </Box>
          <Box
            sx={{
              display: sidebarState.dropdowns.configurationDrop
                ? "flex"
                : "none",
            }}
            className=" UserSubNavMenu configMenu"
          >
            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/information",
                  "configurationDrop"
                )
              }
              className={
                currentUrl ===
                "/workspace-administration/information"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'Information'}
            >
              <img src={InformationIcon} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                Information
              </Typography>
            </Box>

            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/basic-setting",
                  "configurationDrop"
                )
              }
              className={
                currentUrl ===
                "/workspace-administration/basic-setting"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'Basic Settings'}
            >
              <img src={BasicSettingIcon} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                Basic Settings
              </Typography>
            </Box>

            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/subscription",
                  "configurationDrop"
                )
              }
              className={
                currentUrl ===
                "/workspace-administration/subscription"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'Subscription'}
            >
              <img src={SubscriptionIcon} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                Subscription
              </Typography>
            </Box>

            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/account",
                  "configurationDrop"
                )
              }
              className={
                currentUrl ===
                "/workspace-administration/account"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'Account'}
            >
              <img src={AccountIcon} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                Account
              </Typography>
            </Box>

            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/email",
                  "configurationDrop"
                )
              }
              className={
                currentUrl === "/workspace-administration/email"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'Email'}
            >
              <img src={EmailIcon} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                Email
              </Typography>
            </Box>

            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/site-notice",
                  "configurationDrop"
                )
              }
              className={
                currentUrl ===
                "/workspace-administration/site-notice"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'Site Notices'}
            >
              <img src={SiteNoticesIcon} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                Site Notices
              </Typography>
            </Box>

            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/appearance",
                  "configurationDrop"
                )
              }
              className={
                currentUrl ===
                "/workspace-administration/appearance"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'Appearance'}
            >
              <img src={AppearanceIcon} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                Appearance
              </Typography>
            </Box>

            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/site-log",
                  "configurationDrop"
                )
              }
              className={
                currentUrl ===
                "/workspace-administration/site-log"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'Site Log'}
            >
              <img src={SiteLogIcon} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                Site Log
              </Typography>
            </Box>

            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/agreements",
                  "configurationDrop"
                )
              }
              className={
                currentUrl ===
                "/workspace-administration/agreements"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'Agreements'}
            >
              <img src={AgreementsIcon} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                Agreements
              </Typography>
            </Box>

            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/template",
                  "configurationDrop"
                )
              }
              className={
                currentUrl ===
                "/workspace-administration/template"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'Templates'}
            >
              <img src={SiteLogIcon} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Templates
              </Typography>
            </Box>
          </Box>

        {/* <Box
          onClick={() => {
            setConfigurationSubMenu(!configurationSubMenu)
          }}
          className={
            sideTabe === 2
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
        >
          <img src={WAIcon07} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Configuration
          </Typography>
        </Box> */}

        {/* sub menu */}
        {/* <Box
          sx={{ display: configurationSubMenu ? "flex" : "none" }}
          className=" UserSubNavMenu"
        >
          <Box
            onClick={() => handelConfigurationTab("/workspace-administration/information")}
            className={currentUrl === "/workspace-administration/information" ? "AdSideMenuItem AdSideMenuItemActive" : "AdSideMenuItem"}>
            <img src={InformationIcon} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Information
            </Typography>
          </Box>


          <Box
            onClick={() => handelConfigurationTab("/workspace-administration/basic-setting")}
            className={currentUrl === "/workspace-administration/basic-setting" ? "AdSideMenuItem AdSideMenuItemActive" : "AdSideMenuItem"}>
            <img src={BasicSettingIcon} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Basic Settings
            </Typography>
          </Box>


          <Box
            onClick={() => handelConfigurationTab("/workspace-administration/subscription")}
            className={currentUrl === "/workspace-administration/subscription" ? "AdSideMenuItem AdSideMenuItemActive" : "AdSideMenuItem"}>
            <img src={SubscriptionIcon} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>Subscription</Typography>
          </Box>


          <Box
            onClick={() => handelConfigurationTab("/workspace-administration/account")}
            className={currentUrl === "/workspace-administration/account" ? "AdSideMenuItem AdSideMenuItemActive" : "AdSideMenuItem"}>
            <img src={AccountIcon} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Account
            </Typography>
          </Box>


          <Box
            onClick={() => handelConfigurationTab("/workspace-administration/email")}
            className={currentUrl === "/workspace-administration/email" ? "AdSideMenuItem AdSideMenuItemActive" : "AdSideMenuItem"}>
            <img src={EmailIcon} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Email
            </Typography>
          </Box>


          <Box
            onClick={() => handelConfigurationTab("/workspace-administration/site-notice")}
            className={currentUrl === "/workspace-administration/site-notice" ? "AdSideMenuItem AdSideMenuItemActive" : "AdSideMenuItem"}>
            <img src={SiteNoticesIcon} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Site Notices
            </Typography>
          </Box>


          <Box
            onClick={() => handelConfigurationTab("/workspace-administration/appearance")}
            className={currentUrl === "/workspace-administration/appearance" ? "AdSideMenuItem AdSideMenuItemActive" : "AdSideMenuItem"}>
            <img src={AppearanceIcon} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Appearance
            </Typography>
          </Box>


          <Box
            onClick={() => handelConfigurationTab("/workspace-administration/site-log")}
            className={currentUrl === "/workspace-administration/site-log" ? "AdSideMenuItem AdSideMenuItemActive" : "AdSideMenuItem"}>
            <img src={SiteLogIcon} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Site Log
            </Typography>
          </Box>


          <Box
            onClick={() => handelConfigurationTab("/workspace-administration/agreements")}
            className={currentUrl === "/workspace-administration/agreements" ? "AdSideMenuItem AdSideMenuItemActive" : "AdSideMenuItem"}>
            <img src={AgreementsIcon} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Agreements
            </Typography>
          </Box>


          <Box
            onClick={() => handelConfigurationTab("/workspace-administration/template")}
            className={currentUrl === "/workspace-administration/template" ? "AdSideMenuItem AdSideMenuItemActive" : "AdSideMenuItem"}>
            <img src={TemplatesIcon} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Templates
            </Typography>
          </Box>
        </Box> */}

        {/* sub menu end */}

        <Box
          onClick={() => Navigate("/users/dashboard")}
          className={slideMenu ? "userNavBackBtn" : "userNavBackBtn userNavBackBtnInActive"}
          title={slideMenu ? '' : 'Back'}
        >
          <img src={backIcon} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>Back</Typography>
        </Box>
      </Box>
    </>
  )
}
