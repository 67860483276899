import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import ReferenceDateUsingValue from "../../AppTools/DatePicker";
import ReferenceDateExplicitTimePicker from "../../AppTools/TimePicker";
import { useTranslation } from "react-i18next";

import "./style.css";

//images
import dropArrow from "../../../Assets/Images/chevron-down.png";
import chackBox from "../../../Assets/Images/Checkbox.png";
import check from "../../../Assets/Images/check.png";

export default function EditMeetingPop({ editMeetingPop, setEditMeetingPop }) {
  const { t, i18n } = useTranslation();
  const [redio, setRedio] = useState(0);
  const [groupDrop2, setGroupDrop2] = useState(false);
  const [chackVal, setChackVal] = useState([]);

  const pushData = (string) => {
    setChackVal((prev) => [...prev, string]);
  };
  const deleteAllCheckData = () => setChackVal([]);
  const selectAllCheckData = () => {
    setChackVal(["Host 1", "Host 2", "Host 3"]);
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setEditMeetingPop(false);
    }
  };

  useEffect(() => {
    if (editMeetingPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [editMeetingPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: editMeetingPop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople"
      >
        <Box
          sx={{ display: editMeetingPop ? "flex" : "none" }}
          className="workspaceContaint deletePagePop"
        >
          <Typography className="eTemPopLabel">
            {t("LiveInterview.EditMeeting")} : Aaron
          </Typography>


          <Box mt={3} className="redioItem" onClick={() => setRedio(1)}>
            <Box
              className={
                redio === 1
                  ? "customRedio customRedioActive"
                  : "customRedio"
              }
            >
              <Box></Box>
            </Box>
            <Typography>{t("LiveInterview.ChangeMeetingHost")}</Typography>
          </Box>
          <Box
            mt={2}
            ml={5}
            sx={{ display: redio === 1 ? "block" : "none" }}
          >
            <Box className="pickerBox">
              <Typography className="eTemPopLabel">{t("LiveInterview.ScheduleMeeting")}</Typography>
              <Box className="pickerBox">
                <ReferenceDateUsingValue placeholder="Select Invitation Date" />
              </Box>
              <Box className="pickerBox">
                <ReferenceDateExplicitTimePicker placeholder="Select Start Time" />
              </Box>
            </Box>
            <Typography className="eTemPopLabel">{t("LiveInterview.SelectMH")}</Typography>
            <Box pl={4.5} className="inputAppDrop">
              <Box onClick={() => setGroupDrop2(!groupDrop2)}>

                <Typography>{t("LiveInterview.SelectMHost")}</Typography>
              </Box>
              <img
                className="PartDropIcon"
                style={{ rotate: groupDrop2 ? "180deg" : "0deg" }}
                src={dropArrow}
                onClick={() => setGroupDrop2(!groupDrop2)}
              />

              <Box
                sx={{ display: groupDrop2 ? "block" : "none" }}
                className="statusPop"
              >
                <Box className="checkSelectorBox">
                  <span onClick={selectAllCheckData}>{t("LiveInterview.selectAll")}</span>
                  <span onClick={deleteAllCheckData}>{t("LiveInterview.deSelectAll")}</span>
                </Box>

                <Box
                  className="statusPopItem"
                  onClick={() => {
                    if (chackVal.includes("Host 1")) {
                      chackVal.filter((item) => item !== "Host 1");
                      return;
                    } else {
                      pushData("Host 1");
                    }
                  }}
                >
                  <Box className="chackBox">
                    <img className="chackBoxImg" src={chackBox} />
                    <img
                      style={{
                        display: chackVal.includes("Host 1")
                          ? "block"
                          : "none",
                      }}
                      className="chack"
                      src={check}
                    />
                  </Box>
                  <p>{t("LiveInterview.host")} 1</p>
                </Box>

                <Box
                  className="statusPopItem"
                  onClick={() => {
                    if (chackVal.includes("Host 2")) {
                      chackVal.filter((item) => item !== "Host 2");
                      return;
                    } else {
                      pushData("Host 2");
                    }
                  }}
                >
                  <Box className="chackBox">
                    <img className="chackBoxImg" src={chackBox} />
                    <img
                      style={{
                        display: chackVal.includes("Host 2")
                          ? "block"
                          : "none",
                      }}
                      className="chack"
                      src={check}
                    />
                  </Box>
                  <p>{t("LiveInterview.host")} 2</p>
                </Box>

                <Box
                  className="statusPopItem"
                  onClick={() => {
                    if (chackVal.includes("Host 3")) {
                      chackVal.filter((item) => item !== "Host 3");
                      return;
                    } else {
                      pushData("Host 3");
                    }
                  }}
                >
                  <Box className="chackBox">
                    <img className="chackBoxImg" src={chackBox} />
                    <img
                      style={{
                        display: chackVal.includes("Host 3")
                          ? "block"
                          : "none",
                      }}
                      className="chack"
                      src={check}
                    />
                  </Box>
                  <p>{t("LiveInterview.host")} 3</p>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt={3} className="redioItem" onClick={() => setRedio(2)}>
            <Box
              className={
                redio === 2
                  ? "customRedio customRedioActive"
                  : "customRedio"
              }
            >
              <Box></Box>
            </Box>
            <Typography>{t("LiveInterview.CancelReschedule")}</Typography>
          </Box>

          <Box className="editMeetingNotifyBtn">
            <Box className="editMeetingBtn">
              <AppBtnOutLine buttonText="Cancel" onClick={() => setEditMeetingPop(false)} />
              <AppBtn buttonText="Save" />
            </Box>
          </Box>

        </Box>
      </Box>
    </>
  );
}
