import React, { useEffect, useState } from "react";
import "./style.css";
import { Box, Typography } from "@mui/material";
//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { toast } from "react-toastify";
import { TOAST_CONFIG, USER_TYPE_PARTICIPANT, USER_TYPE_WS_ADMIN } from "../../../lib/constants";
import { useAppDispatch, useAppSelector } from "../../../lib/store/store";
import { supportLoginApi } from "../../../Services/adminActions";
import { setUserWorkspace, userProfile } from "../../../Services/auth";
import { setAuthState, setIsSupport, setPrevUser, setUserProfile, setUserType } from "../../../lib/store/features/authSlice";
import { useNavigate } from "react-router";

export default function SupportLoginParticipantPop({
    supportLoginPop,
    setSupportLoginPop,
    sendParticularUserData
}) {
    const Navigate = useNavigate();
    let dispatch = useAppDispatch();

    let authState = useAppSelector((state) => state.auth);
    const workspaceState = useAppSelector((state) => state.workspace);
    const [loading, setLoading] = useState(false);
    const handelPopClose = (e) => {
        if (e.target.id === "popBackdrop") {
            setSupportLoginPop(false);
        }
    };

  useEffect(() => {
    if (supportLoginPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [supportLoginPop]);

  const supportLogin = async () => {
    setLoading(true);
    try {
      let reqPayload = {
        user_id: sendParticularUserData?._id,
        workspace_id: workspaceState?.selectedWorkspace?._id,
      };
      let supportloginRes = await supportLoginApi(
        reqPayload,
        authState.authToken
      );
      if (supportloginRes.success) {
        const newToken = supportloginRes.data.token;
        let userProfileRes = await userProfile(newToken);
        if (userProfileRes?.data?.success) {
          if(userProfileRes?.data?.data.is_superadmin === false){
            dispatch(setPrevUser({...authState}));
            dispatch(setAuthState(newToken));
            dispatch(setIsSupport(true));
            dispatch(setUserProfile(userProfileRes?.data?.data));
            toast.success(supportloginRes.message, TOAST_CONFIG);
            if(userProfileRes?.data?.data.is_participant === true){
                dispatch(setUserType(USER_TYPE_PARTICIPANT));
                return Navigate("/notification");
            } else{
                dispatch(setUserType(USER_TYPE_WS_ADMIN));
                let wsDetails = (userProfileRes?.data?.data?.workspaces?.length > 0) ? userProfileRes?.data?.data?.workspaces[0] : null;
                if(wsDetails != null) {
                  setUserWorkspace(userProfileRes?.data?.data, wsDetails?._id, USER_TYPE_WS_ADMIN, dispatch);
                }
                return Navigate("/users/dashboard");
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        onClick={handelPopClose}
        id="popBackdrop"
        sx={{ display: supportLoginPop ? "flex" : "none" }}
        className="workspaceDeletePopContainer"
      >
        <Box className="workspaceDeleteBox workspaceDeletePopTextAlign">
          <Box className="deleteTextBox">
            <Typography>
              Are you sure you want to login to {sendParticularUserData?.name}'s account as support person.
            </Typography>
          </Box>
          <Box className="deleteBtnBox">
            <AppBtnOutLine
              onClick={() => setSupportLoginPop(false)}
              buttonText="Cancel"
            />
            <AppBtn buttonText="Support Login" onClick={supportLogin} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
