import { Box, Typography } from "@mui/material";
import { sideHaumIcon } from "../../../Assets/Data";
import { useAppSelector } from "../../../lib/store/store";
import { useState } from "react";
import SideMenu from "../../../Components/Researchers/SideMenu";
import SideMenuWorkspace from "../../../Components/WSAdministration/SideMenu";
import SideMenuAccountSettings from "../../../Components/User/SideMenu";
import { USER_TYPE_WS_ADMIN } from "../../../lib/constants";
import useAuth from "../../../hooks/useAuth";
// import { sideHaumIcon } from "../../../Assets/Data";

const ResearcherLayout = ({ workspaceSideMenu, childComponent, actionBtn }) => {
  workspaceSideMenu = workspaceSideMenu || "main";
  let authState = useAppSelector((state) => state.auth);
  const [slideMenu, setSlideMenu] = useState(true);
  const [workspaceDrop, setWorkSpaceDrop] = useState(true);

  useAuth([USER_TYPE_WS_ADMIN]);

  return (
    <Box className="ResearchersContainer">
      {/* side menu */}
      {workspaceSideMenu == "main" && (
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />
      )}
      {workspaceSideMenu == "workadmin" && authState?.userProfile?.is_researcher && (
        <SideMenuWorkspace
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
          participantsSubMenu={slideMenu}
          setParticipantsSubMenu={setSlideMenu}
        />
      )}
      {workspaceSideMenu == "accountsetting" && (
        <SideMenuAccountSettings
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />
      )}
      <Box className="adminMainSection">
        <Box className="adminNavBar">
          <Box
            onClick={() => setSlideMenu(!slideMenu)}
            sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
            className="sideHaumIcon"
          >
            {sideHaumIcon}
          </Box>
          {authState?.is_support == true && (
            <Typography
              sx={{
                backgroundColor: "#fd9843",
                padding: "15px",
                borderRadius: 2,
              }}
            >
              Support login for{" "}
              {`${authState?.userProfile?.name} 
            ${
              authState?.userProfile?.is_researcher == true
                ? "(Researcher)"
                : authState?.userProfile?.is_observer == true
                ? "(Observer)"
                : authState?.userProfile?.is_collaborator == true
                ? "(Collaborator)"
                : ""
            }`}
            </Typography>
          )}
          {actionBtn}
        </Box>
        {/* Page container */}
        {childComponent}
      </Box>
    </Box>
  );
};

export default ResearcherLayout;
