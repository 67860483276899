import React, { useState } from "react";
import "./style.css";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import WhiteArrow from "../../../Assets/Images/Wchevron-down.png";
import userHomeBanner from "../../../Assets/Images/userHomeBanner.png";
import Avatar from "../../../Assets/Images/UserHomeAvatar.png";
import consartImg from "../../../Assets/Images/consartImg.png";
import users from "../../../Assets/Images/usersB.png";
import star from "../../../Assets/Images/Star 2.png";
import redDot from "../../../Assets/Images/RedDot.png";
import BottomBanner from "../../../Assets/Images/Bbanner.png";
import OptionIcon from "../../../Assets/Images/optionIcon.png";

//components
import HorizontalBars from "../../AppTools/LineChart";

export default function UserHome() {
  const [researchers, setResearchers] = useState(false);
  const [researchersVal, setResearchersVal] = useState();
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  return (
    <>
      <Box className="userHomeContainer">
        <Box className="uhwelcomeBanner">
          <img src={userHomeBanner} />
          <Box
            sx={{ position: "relative" }}
            onClick={() => setResearchers(!researchers)}
          >
            <Typography className="uhwelbannerText" mr={1}>
              {researchersVal || "Welcome Researchers!"}
            </Typography>
            <img src={WhiteArrow} />

            <Box
              sx={{ display: researchers ? "flex" : "none" }}
              className="researchPop"
            >
              <Box
                className="researchPopItem"
                onClick={() => setResearchersVal("Welcome Researchers!")}
              >
                <Typography className="researchPopItemText">
                  {t("workspaceHome.welcomeResearchers")}
                </Typography>
              </Box>
              <Box
                className="researchPopItem"
                onClick={() => setResearchersVal("Collaborators Corner")}
              >
                <Typography className="researchPopItemText">
                  {t("workspaceHome.collaboratorCorner")}
                </Typography>
              </Box>
              <Box
                className="researchPopItem"
                onClick={() => setResearchersVal("Observers Corner")}
              >
                <Typography className="researchPopItemText">
                  {t("workspaceHome.observerCorner")}
                </Typography>
              </Box>
              <Box
                className="researchPopItem"
                onClick={() => setResearchersVal("Welcome Participants")}
              >
                <Typography className="researchPopItemText">
                  {t("workspaceHome.welcomeParticipants")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="userHMBox3">
          <Box className="userHMBoxItem userhAvatarBox">
            <Box className="AvartimgBox">
              <img src={Avatar} />
            </Box>
            <Typography className="userHMText">
              {t("workspaceHome.welcomeUser")} Richard Han
            </Typography>
            <Typography className="userHNText">
              Lorem ipsum dolor sit amet consect etur. Ac quisque tristique
              dignissim non. Erat id ipsum sem non auctor. Elit non massa quis
              faucibus vel don sapien felis.{" "}
            </Typography>
          </Box>

          <Box className="userHMBoxItem lineChart">
            <HorizontalBars />
          </Box>

          <Box className="userHMBoxItem userHConsart">
            <Box className="conImgBox">
              <img src={consartImg} />
            </Box>
            <Box className="userHCTextBox">
              <Typography sx={{ fontSize: "20px" }}>
                {t("workspaceHome.welcomeUser")}
              </Typography>
              <img src={OptionIcon} />
            </Box>
            <Box className="userHCTextBox">
              <Box>
                <Typography sx={{ fontSize: "16px", color: "#98A2B3" }}>
                  Aug 02 - Aug 03
                </Typography>
                <Box
                  sx={{ background: "#12B76A" }}
                  className="ActiveStatus"
                ></Box>
              </Box>
              <Box>
                <img src={users} />
                <Typography ml={1} sx={{ fontSize: "20px", color: "#98A2B3" }}>
                  11
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="userHMBox3">
          <Box className="userHMBoxItem ConversationsBox">
            <Typography className="ConversationsBoxHText">
              {t("workspaceHome.conversations")}
            </Typography>

            <Box className="convChatItem">
              <Box mb={0.8}>
                <Typography>Recent Trekking Outings</Typography>
                <Box className="iconBox">
                  <img style={{ width: "20px", height: "20px" }} src={star} />
                  <img style={{ width: "12px", height: "12px" }} src={redDot} />
                </Box>
              </Box>
              <Box>
                <Typography className="convSubText">(Outdoor Tips)</Typography>
                <Box className="viewBtn">
                  <Typography>{t("commonTxt.viewBtn")}</Typography>
                </Box>
              </Box>
            </Box>
            <Box className="convChatItem">
              <Box mb={0.8}>
                <Typography>Your Trekking must haves</Typography>
                <Box className="iconBox">
                  <img style={{ width: "20px", height: "20px" }} src={star} />
                  <img style={{ width: "12px", height: "12px" }} src={redDot} />
                </Box>
              </Box>
              <Box>
                <Typography className="convSubText">
                  (Equipment Zone)
                </Typography>
                <Box className="viewBtn">
                  <Typography>{t("commonTxt.viewBtn")}</Typography>
                </Box>
              </Box>
            </Box>
            <Box className="convChatItem">
              <Box mb={0.8}>
                <Typography>Meal Hacks</Typography>
                <Box className="iconBox">
                  <img style={{ width: "20px", height: "20px" }} src={star} />
                  <img style={{ width: "12px", height: "12px" }} src={redDot} />
                </Box>
              </Box>
              <Box>
                <Typography className="convSubText">(Outdoor Tips)</Typography>
                <Box className="viewBtn">
                  <Typography>{t("commonTxt.viewBtn")}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="bottomBannerBox">
            <img src={BottomBanner} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
