import React from "react";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import iIcon from "../../../Assets/Images/IIcon.png";
import Hash from "../../../Assets/Images/hash.png";

import { ProfileFieldData } from "../../../Assets/Data";
import { settingIconBlack, trashBlack } from "../../../Assets/Data";

export default function ParticipantScreeningQuestion() {
  const [workSpaceOption, setWorkspaceOption] = useState(false);
  const [optionIndex, setOptionIndex] = useState();
  const [deleteProfileFieldPop, setDeleteProfileFieldPop] = useState(false);

  const [profileFieldSetting, setProfileFieldSetting] = useState(false);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  return (
    <>
      <Box className="iBox">
        <img src={iIcon} />
        <Typography>{t("participantScreening.noteTexts")}</Typography>
      </Box>

      <Box className="projectRadioBox customBox">
        <Box className="projectRadioBoxSection projectRadioCustomBox">
          <Box className="ProjectRadioInput ProjectRadioCustomInput">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography className="emailSettingLabel">
              {t("participantScreening.checkBoxText1")}
            </Typography>
          </Box>
          <Box className="ProjectRadioInput ProjectRadioCustomInput">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography className="emailSettingLabel">
              {t("participantScreening.checkBoxText2")}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          margin: "24px 0",
        }}
      >
        <Typography className="ppundText">
          {t("participantScreening.linkTextOne")}
        </Typography>
        <Typography className="ppundText">
          {t("participantScreening.linkTextTwo")}
        </Typography>
      </Box>
      <Typography className="allPartRedioLabel">
        {t("participantScreening.subHeader")}
      </Typography>
      <Box className="projectFieldList profileFieldAllQuestion">
        {ProfileFieldData &&
          ProfileFieldData?.map((el, index) => (
            <Box className="ProfileFiledQuestion">
              <Box
                id="dot"
                onClick={() => {
                  setWorkspaceOption(!workSpaceOption);
                  setOptionIndex(index);
                }}
                className={
                  workSpaceOption && index === optionIndex
                    ? "dotProfile dotProfileActive"
                    : "dotProfile"
                }
              >
                {
                  <Box
                    sx={{
                      display:
                        workSpaceOption && index === optionIndex
                          ? "flex"
                          : "none",
                    }}
                    className="optionPop"
                  >
                    <Box
                      onClick={() => setProfileFieldSetting(true)}
                      className="optionPopItem"
                    >
                      {settingIconBlack}
                      <Typography className="optionProfilePopItem">
                        {t("participantScreening.moreOptions1")}
                      </Typography>
                    </Box>

                    <Box
                      className="optionPopItem"
                      onClick={() => setDeleteProfileFieldPop(true)}
                    >
                      {trashBlack}
                      <Typography className="optionProfilePopItem">
                        {t("participantScreening.moreOptions2")}
                      </Typography>
                    </Box>
                  </Box>
                }
              </Box>
              <Typography>
                <img src={Hash} /> {t("participantScreening.question")}
              </Typography>
            </Box>
          ))}
      </Box>
    </>
  );
}
