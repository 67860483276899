import moment from "moment-timezone";
import { STORAGE_BASE_PATH } from "../env";
import AdminLayout from "../Pages/Admin/partials/AdminLayout";
import ResearcherLayout from "../Pages/Researchers/partials/ResearcherLayout";
import {
  USER_TYPE_PARTICIPANT,
  USER_TYPE_SUPERADMIN,
  USER_TYPE_WS_ADMIN,
} from "./constants";
import ParticipantLayout from "../Pages/Participants/partials/ParticipantLayout";

export const formatDate = (date, format = "DD-MMM-YYYY h:mm A") => {
  return date ? moment.utc(date).local().format(format) : false;
};

export const formatTime = (time) => {
  const currentDate = moment().format("DD-MMM-YYYY");
  const dateTimeString = `${currentDate}T${time}`;
  return moment(dateTimeString).format("h:mm A");
};

export const formatTimestamp = (timestamp) => {
  return moment(timestamp).format('YYYY-MM-DD HH:mm:ss');
};

export const getTimeByTimezone = (timezone = null) => {
  let time;
  if (timezone) {
    time = moment().tz(timezone);
  } else {
    time = moment();
  }
  return time.format('hh:mm A');
}

export const calculateTimeDistance = (createdDate) => {
  const createdDateTime = new Date(createdDate);
  const currentDate = new Date();
  const timeDifference = currentDate - createdDateTime;

  // Calculate days, hours, and minutes
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
  const timeDistance = {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };

  let returnTxt = "";
  if (timeDistance.days > 0) returnTxt = `${timeDistance.days} days ago`;
  else if (timeDistance.hours > 0)
    returnTxt = `${timeDistance.hours} hours ago`;
  else if (timeDistance.minutes > 0)
    returnTxt = `${timeDistance.minutes} minutes ago`;
  else if (timeDistance.seconds > 0)
    returnTxt = `${timeDistance.seconds} seconds ago`;

  return returnTxt;
};

export const viewImage = (imagePath) => {
  return STORAGE_BASE_PATH + `/${imagePath}`;
};

export const appendQueryParams = (history, location, queryParams) => {
  const params = new URLSearchParams(queryParams);
  history.replace({ pathname: location.pathname, search: params.toString() });
};

export const urlToBase64 = (url, callback) => {
  var img = new Image();
  img.crossOrigin = "Anonymous";
  img.onload = function () {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    canvas.height = this.height;
    canvas.width = this.width;
    ctx.drawImage(this, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    callback(dataURL);
    canvas = null;
  };
  img.src = url;
};

export const handleOutsideClick = (dropdownStates) => {
  let fn = (e) => {
    dropdownStates.map((setDropdownObj) => {
      if (
        !e.target.classList.contains(setDropdownObj.className) &&
        !e.target.closest("." + setDropdownObj.className)
      ) {
        setDropdownObj.setDropdownState(false);
      }
    });
  };

  document.addEventListener("mousedown", fn);

  return {
    ev: "mousedown",
    cb: fn,
  };
};

export const removeHandleOutsideClick = (evObj) => {
  document.removeEventListener(evObj.ev, evObj.cb);
};

export const getUserRoleType = (user) => {
  const roleMap = {
    is_company: "Company",
    is_researcher: "Researcher",
    is_collaborator: "Collaborator",
    is_observer: "Observer",
    is_participant: "Participant",
  };

  for (const [property, role] of Object.entries(roleMap)) {
    if (user[property]) {
      return role;
    }
  }

  return "Super Admin";
};

export const getUserDashboardUrl = (userType, userProfile) => {
  if (userType == USER_TYPE_SUPERADMIN && userProfile?.is_superadmin == 1) {
    return `/${getUserUriSegment(userType)}/workspace-management`;
  } else if (
    userType == USER_TYPE_WS_ADMIN &&
    (userProfile?.is_researcher == 1 ||
      userProfile?.is_collaborator == 1 ||
      userProfile?.is_observer == 1)
  ) {
    return `/${getUserUriSegment(userType)}/dashboard`;
  } else if (
    userType == USER_TYPE_PARTICIPANT &&
    userProfile?.is_participant == 1
  ) {
    return `/notification`;
  } else {
    return "";
  }
};

export const getUserUriSegment = (userType) => {
  if (userType == USER_TYPE_SUPERADMIN) {
    return "admin";
  } else if (userType == USER_TYPE_WS_ADMIN) {
    return "users";
  } else if (userType == USER_TYPE_PARTICIPANT) {
    return "participants";
  } else {
    return "researchers";
  }
};

export const getUserTypeFromSegment = (userType) => {
  if (userType == "admin") {
    return USER_TYPE_SUPERADMIN;
  } else if (userType == "users") {
    return USER_TYPE_WS_ADMIN;
  } else if (userType == "participants") {
    return USER_TYPE_PARTICIPANT;
  } else {
    return USER_TYPE_WS_ADMIN;
  }
};

export const capitalizeEachWord = (str = "") =>
  typeof str !== "string" || str.length === 0
    ? str
    : str.replace(/\b\w/g, (char) => char.toUpperCase());

export const objectToFormData = (obj) => {
  const formData = new FormData();
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      formData.append(key, obj[key]);
    }
  }
  return formData;
};

export const capitalizeFirstLetter = (string) => {
  if (typeof string == "string") {
    return string[0].toUpperCase() + string.slice(1);
  }
  return string;
};

export const getDatePickerVal = (value, type = "date-time") => {
  let year = value.$y;
  let month = value.$M < 9 ? "0" + (value.$M + 1) : value.$M + 1;
  let day = value.$D < 10 ? "0" + value.$D : value.$D;

  let hour = value.$H < 10 ? "0" + value.$H : value.$H;
  let min = value.$m < 10 ? "0" + value.$m : value.$m;
  let sec = value.$s < 10 ? "0" + value.$s : value.$s;

  if (type === "time") return hour + ":" + min + ":" + sec;
  else if (type === "date") return year + "-" + month + "-" + day;
  else
    return year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec;
};

export const getFileName = (file = "") => {
  if (file === "") return null;
  const files = file.split("/");
  const len = files.length;
  return files[len - 1];
};

export const renderUserLayout = (
  userType,
  actionBtn = null,
  children = null,
  sidebarType = "main"
) => {
  return (
    <>
      {userType == USER_TYPE_SUPERADMIN && (
        <AdminLayout actionBtn={actionBtn} childComponent={children} />
      )}
      {userType == USER_TYPE_WS_ADMIN && (
        <ResearcherLayout
          workspaceSideMenu={sidebarType}
          actionBtn={actionBtn}
          childComponent={children}
        />
      )}
      {userType == USER_TYPE_PARTICIPANT && (
        <ParticipantLayout
          workspaceSideMenu={sidebarType}
          actionBtn={actionBtn}
          childComponent={children}
        />
      )}
    </>
  );
};

export const dataURLToFile = (dataurl, filename) => {
  let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const estimateSignalStrength = (effectiveType, downlink, rtt) => {
  let baseDbm;
  switch (effectiveType) {
    case '4g':
      baseDbm = -70;
      break;
    case '3g':
      baseDbm = -85;
      break;
    case '2g':
    case 'slow-2g':
      baseDbm = -100;
      break;
    case 'wifi':
      baseDbm = -50;
      break;
    default:
      baseDbm = -120;
      break;
  }

  // Adjust dBm based on downlink speed (higher speed = better signal)
  baseDbm += (downlink - 1) * 5; // Example adjustment factor

  // Adjust dBm based on RTT (lower RTT = better signal)
  if (rtt > 150) {
    baseDbm -= 10;
  } else if (rtt > 300) {
    baseDbm -= 20;
  }

  return baseDbm;
}