import React, { useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

//components
import AiIntegration from "../../../Components/Admin/AiIntegration";
import ResearcherLayout from "../partials/ResearcherLayout";

export default function ResearcherAiIntegration({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
}) {

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  return (
    <>
      <ResearcherLayout
      childComponent={
        <Box className="adminMainBox" sx={{ display: "flex" }}>
          <AiIntegration />
        </Box>
      }
    />
    </>
  );
}
