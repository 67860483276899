import React, { useState, useEffect } from 'react';
import { Box, Typography } from "@mui/material";
import "./style.css";

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import SideMenu from "../../../Components/Participants/SideMenu";
import { styled } from '@mui/material/styles';
import { sideHaumIcon, timeZone1, timeZone2, timeZone3 } from "../../../Assets/Data";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";



//images
import iIcon from "../../../Assets/Images/IIcon.png";
import groupIcon from "../../../Assets/Images/groupsIcon.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";
import chackBox from "../../../Assets/Images/Checkbox.png";
import check from "../../../Assets/Images/check.png";
import calenderW from "../../../Assets/Images/calendarWhiteIcon.png"
import avatar from "../../../Assets/Images/timeSchduleimg.png"
import mettingImg from "../../../Assets/Images/mettingImg.png"
import arrow from "../../../Assets/Images/arrow.png";
import leftArrow from "../../../Assets/Images/arrow-left.png";
import rightArrow from "../../../Assets/Images/arrow-right.png";
import chevronDown from "../../../Assets/Images/chevron-down.png";
import Avatar1 from "../../../Assets/Images/Avatar1.png";
import rec1 from "../../../Assets/Images/rec1.png"
import backArrow from "../../../Assets/Images/noun-back.png";
import bulkIcon from "../../../Assets/Images/bulkIcon.png"
import video1 from "../../../Assets/Images/Videos/part1.mp4"
import filterIcon from "../../../Assets/Images/filterIcon.png"
import aiWhiteIcon from "../../../Assets/Images/aiIconWhite.png"
import exportIcon from "../../../Assets/Images/exportIcon.png";
import dropIcon from "../../../Assets/Images/chevron-down.png";
import avatar1 from "../../../Assets/Images/inb1.png"
import avatar2 from "../../../Assets/Images/inb2.png"
import avatar3 from "../../../Assets/Images/inb3.png"
import Option2LIcon from "../../../Assets/Images/Option2LIcon.png";
import editIcon from "../../../Assets/Images/edit 1.png";
import editBtn from "../../../Assets/Images/edit 1.png"
import whitedropIcon from "../../../Assets/Images/Wchevron-down.png";
import semoji from "../../../Assets/Images/Semoji.png"
import imgIcon from "../../../Assets/Images/galleryIconB.png";


// Data
import { activeParticipants, taskList } from "../../../Assets/Data";

//comonents
import ScheduleInterviewParticipantPop from '../../../Components/Participants/ScheduleInterviewParticipantPop';
import UpdateTimeZonePop from '../../../Components/Participants/UpdateTimeZonePop';
import AiIntegrationPop from '../../../Components/Participants/AiIntegrationPop';
import LiveInterViewPop from "../../../Components/Participants/LiveInterviewPop";
import { AppBtnOutLine, AppBtn } from "../../../Components/AppTools/AppButton";
import ReferenceDateUsingValue from "../../../Components/AppTools/DatePicker";
import SystemCheckPop from "../../../Components/Participants/SystemCheckPop";
import EditMeetingPop from '../../../Components/Participants/EditMeetingPop';
import { Status, SearchBar } from "../../../Components/AppTools/AppTool";
import BubbleChart from "../../../Components/AppTools/BubbleChart";
import { ApexChart } from "../../../Components/AppTools/PieChart";
import { BarChart } from "../../../Components/AppTools/BarChart";


//data
import { languages } from "../../../Assets/Data";

export default function InterviewScheduled({ slideMenu, setSlideMenu, workspaceDrop, setWorkSpaceDrop }) {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [nav, setNav] = useState(0)
  const [nav2, setNav2] = useState(0)
  const [nav3, setNav3] = useState(0)
  const [groupDrop2, setGroupDrop2] = useState(false);
  const [chackVal, setChackVal] = useState([]);
  const [timeZoneIndex1, setTimeZoneIndex1] = useState()
  const [timeZoneIndex2, setTimeZoneIndex2] = useState()
  const [timeZoneIndex3, setTimeZoneIndex3] = useState()
  const [liveInterViewPop, setLiveInterViewPop] = useState(false)
  const [updateTimeZonePop, setUpdateTimeZonePop] = useState(false);
  const [editMeetingPop, setEditMeetingPop] = useState(false);
  const [scheduleInterviewParticipantPop, setScheduleInterviewParticipantPop] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [newArrayData, setNewArrayData] = useState([]);
  const [participantsResNavDrop, setParticipantResNavDrop] = useState(false);
  const [participantsResNavDropVal, setParticipantResNavDropVal] = useState("All Tasks");
  const [recordIndexPage, setRecordIndexPage] = useState(true);
  const [expoMettingDrop, setExpoMettingDrop] = useState(false);
  const [expoMettingDropVal, setExpoMettingDropVal] = useState("Export Meeting");
  const [systemCheckPop, setSystemCheckPop] = useState(false);
  const [providerDrop, setProviderDrop] = useState(false)
  const [providerDropVal, setProviderDropVal] = useState()
  const [providerDrop1, setProviderDrop1] = useState(false)
  const [providerDropVal1, setProviderDropVal1] = useState()
  const [providerDrop2, setProviderDrop2] = useState(false)
  const [providerDropVal2, setProviderDropVal2] = useState()
  const [providerDrop3, setProviderDrop3] = useState(false)
  const [providerDropVal3, setProviderDropVal3] = useState()
  const [lang, setLang] = useState(false)
  const [langVal, setLangVal] = useState()
  const [aiSubnav, setAiSubNav] = useState(0);
  const [exportDrop, setExportDrop] = useState(false);
  const [exportDrop2, setExportDrop2] = useState(false);
  const [exportDrop3, setExportDrop3] = useState(false);
  const [exportDrop4, setExportDrop4] = useState(false);
  const [aiIntegrationPop, setAiIntegrationPop] = useState(false);

  const expoMettingItem = [
    "Video : Front Room",
    "Video : English",
    "Meeting Transcript (Excel",
    "Meeting Transcript (HTML)",
    "Subtitles : Front Room (VTT)",
    "Subtitles : Front Room + Backroom (VTT)",
    "Subtitles : English (VTT)"
  ]
  const hostItems = [
    "Host 1",
    "Host 2",
    "Host 3",
    "Host 4"
  ]

  const exportItem = ["PNG", "JPEG", "PDF", "SVG"];
  const [series] = useState([{ data: [400, 430, 448, 470, 540] }]);
  const categories = ['Product Reviews', 'Price', 'Brand Reputation', 'Durabilty/Quality', 'Aesthetics'];
  const [series2] = useState([{ data: [400, 430, 448, 470, 540] }]);
  const categories2 = ['Relaxation', 'Great Quote', 'Nostalgia', 'Empowerment'];


  const handelTimerClick = (setState, state, i, e) => {
    console.log(e?.target?.id);
    if (e?.target?.id === "hostS") {
      return
    } else {
      if (state === i) {
        setState(null)
        return;
      } else {
        setState(i)
      }
    }
  }


  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#4F33D1",
    },
  }));


  const pushData = (string) => {
    setChackVal((prev) => [...prev, string]);
  };
  const deleteAllCheckData = () => setChackVal([]);
  const selectAllCheckData = () => {
    setChackVal(hostItems);
  };

  const recordCard = () => {
    return (
      <Box className="recordCard" onClick={() => setRecordIndexPage(false)}>
        <Box className="detail">
          <Box className="rcAvatar"><img src={Avatar1} /></Box>
          <Box className="rcText">
            <Typography>Olivia Rhye</Typography>
            <span>30/07/2023, 1:00 AM (15 Min)</span>
          </Box>
        </Box>
        <Box className="recordCover">
          <img src={rec1} />
        </Box>
      </Box>
    )
  }
  function wrapAtWordsWithSpan(text) {
    return text.replace(/@(\w+)/g, "<span>@$1</span>");
  }



  const singelComment = ({ img, name, comment, date, time }) => {
    return (
      <Box className="singelComment">
        <Box className="commentSection innerCommentSection">
          <Box className="avatarBox">
            <img src={img} />
          </Box>
          <Box className="commentTextSection">
            <Box className="userNameBox">
              <Typography className='userName'>{name}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "15px",
                  minWidth: "213px"
                }}
              >
                <Box className="editbtn">
                  <img src={editBtn} />
                  <Typography>{t("commonTxt.edit")}</Typography>
                </Box>
                <samp className="taskHeaderText3">
                  {date}
                </samp>
              </Box>
            </Box>

            <Typography className='commentWtag' dangerouslySetInnerHTML={{ __html: wrapAtWordsWithSpan(comment) }}></Typography>
            <Box className="likeBox">
              <Typography>{time}</Typography>
              <Typography>{t("commonTxt.Like")}</Typography>
              <Typography>{t("commonTxt.dislike")}</Typography>
              <Typography>{t("directMessageAdmin.reply")}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

    )
  }



  const writeCommentSection = ({ img, postDrop, setPostDrop, postDropVal, setPostDropVal }) => {
    return (
      <Box className="writeCommentSection">
        <Box className="avatarBox">
          <img src={img} />
        </Box>
        <Box className="commentInputBox">
          <input className='phoneInput' placeholder='Add comment' />
          <img className='inputIcon1' src={semoji} />
          <img className='inputIcon2' src={imgIcon} />
          <Box className="commentCheckBox">
            <Box
              mb={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                minWidth: "206px"
              }}
            >
              <input
                style={{ marginRight: "10px" }}
                className="custom-checkbox"
                type="checkBox"
              />
              <Typography>{t("viewMessageProfile.viewMessageProfileCheckbox")}</Typography>
            </Box>

            <Typography className='followText'>{t("agreementPage.temp")}</Typography>
          </Box>
        </Box>

        <Box className="directMessageNavBtn" onClick={() => setPostDrop(!postDrop)}>
          <Typography className='DropBtnText'>{t("commonTxt.postBtn")}</Typography>
          <img src={whitedropIcon} style={{ rotate: postDrop ? "180deg" : "0deg" }} />
          <Box className="workspaceItemNavPop" sx={{ display: postDrop ? "flex" : "none" }}>
            <Box onClick={() => setPostDropVal("Visible to Everyone")}>
              <Typography>{t("recapThread.visibleToEveryone")}</Typography>
            </Box>
            <Box onClick={() => setPostDropVal("Only Andi Lane")}>
              <Typography>{t("recapThread.onlyAndiLane")}</Typography>
            </Box>
            <Box onClick={() => setPostDropVal("Backroom Comment")}>
              <Typography>{t("Conversations.BackroomComment")}</Typography>
            </Box>
            <Box onClick={() => setPostDropVal("Backroom Task")}>
              <Typography>{t("recapThread.backroomTask")}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }


  const nestedCommentSection = ({ img, name, comment, date, time, postDrop, setPostDrop, postDropVal, setPostDropVal }) => {
    return (
      <Box className="singelComment">
        <Box className="commentSection innerCommentSection">
          <Box className="avatarBox">
            <img src={img} />
          </Box>
          <Box className="commentTextSection">
            <Box className="userNameBox">
              <Typography className='userName'>{name}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "15px",
                  minWidth: "213px"
                }}
              >
                <Box className="editbtn">
                  <img src={editBtn} />
                  <Typography>{t("commonTxt.edit")}</Typography>
                </Box>
                <samp className="taskHeaderText3">
                  {date}
                </samp>
              </Box>
            </Box>

            <Typography className='commentWtag' dangerouslySetInnerHTML={{ __html: wrapAtWordsWithSpan(comment) }}></Typography>
            <Box className="likeBox">
              <Typography>{time}</Typography>
              <Typography>{t("commonTxt.Like")}</Typography>
              <Typography>{t("commonTxt.dislike")}</Typography>
              <Typography>{t("directMessageAdmin.reply")}</Typography>
            </Box>
            {singelComment({
              img: avatar3,
              name: "Kate Morrison",
              comment: "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
              date: "Aug 10, 2023",
              time: "10 h"
            })}
            {writeCommentSection({ img: avatar1, postDrop, setPostDrop, postDropVal, setPostDropVal })}
          </Box>
        </Box>
      </Box>
    )
  }



  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  useEffect(() => {
    printItemsByState(activeParticipants, currentPage);
  }, [currentPage]);


  return (
    <>
      <Box className="ResearchersContainer participantIntSch">
        <LiveInterViewPop liveInterViewPop={liveInterViewPop} setLiveInterViewPop={setLiveInterViewPop} />
        <UpdateTimeZonePop updateTimeZonePop={updateTimeZonePop} setUpdateTimeZonePop={setUpdateTimeZonePop} />
        <EditMeetingPop editMeetingPop={editMeetingPop} setEditMeetingPop={setEditMeetingPop} />
        <ScheduleInterviewParticipantPop scheduleInterviewParticipantPop={scheduleInterviewParticipantPop} setScheduleInterviewParticipantPop={setScheduleInterviewParticipantPop} />
        <SystemCheckPop systemCheckPop={systemCheckPop} setSystemCheckPop={setSystemCheckPop} />
        <AiIntegrationPop aiIntegrationPop={aiIntegrationPop} setAiIntegrationPop={setAiIntegrationPop} />

        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop} setWorkSpaceDrop={setWorkSpaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon" >
              {sideHaumIcon}
            </Box>
            <Box className="AppNavBtnBox">
              <AppBtn buttonText={t("LiveInterview.systemCheckBtn")} onClick={() => setSystemCheckPop(true)} />
            </Box>
          </Box>

          {/* Page container */}
          <Box className="researchersMainBox pageContainer interviewSchedule">
            <Box className="NotificationSection">
              <Box mb={3} width="74px" onClick={() => Navigate("/participants/activities")} className="openBtn">
                <img src={backArrow} />
                <Typography className="neonBackText">Back</Typography>
              </Box>
              <Typography className='EAHeaderText'>{t("LiveInterview.pageHeader")}</Typography>

              <Box mt={3} className="iBox">
                <img src={iIcon} />
                <Typography>{t("LiveInterview.Ibox1")}<span onClick={() => setUpdateTimeZonePop(true)}>{t("LiveInterview.Ibox2")}</span>{t("LiveInterview.Ibox3")}</Typography>
              </Box>

              <Box className="scheduleNav">
                <Box onClick={() => setNav(0)} className={nav === 0 ? "scheduleNavItem scheduleNavItemActive" : "scheduleNavItem"}>
                  <Typography>{t("LiveInterview.Calender")}</Typography>
                </Box>
                <Box onClick={() => setNav(1)} className={nav === 1 ? "scheduleNavItem scheduleNavItemActive" : "scheduleNavItem"}>
                  <Typography>{t("LiveInterview.ScheduledMeetings")}</Typography>
                </Box>
                <Box onClick={() => setNav(4)} className={nav === 4 ? "scheduleNavItem scheduleNavItemActive" : "scheduleNavItem"}>
                  <Typography>{t("LiveInterview.Recordings")}</Typography>
                </Box>
              </Box>




              {/* calender */}
              <Box className="schedule_Section_box" sx={{ display: nav === 0 ? "block" : "none" }}>
                <Box className="scheduleDropBox">

                  <Box className="dateItembox">
                    <Typography className='drop_label'>{t("LiveInterview.InterviewDate")}</Typography>
                    <Box className="pickerBox">
                      <ReferenceDateUsingValue />
                    </Box>
                  </Box>
                  <Box className="dateItembox">
                    <Typography className='drop_label'>{t("LiveInterview.MeetingDuration")}</Typography>
                    <input type="text" className='phoneInput' value={"30 Mins"} />

                  </Box>
                  <Box className="dateItembox">
                    <Typography className='drop_label'>{t("LiveInterview.SelectHosts")}</Typography>
                    <Box
                      className="inputAppDrop">
                      <Typography onClick={() => setGroupDrop2(!groupDrop2)}>{t("LiveInterview.meetingHostPlaceholder")}</Typography>
                      <img
                        onClick={() => setGroupDrop2(!groupDrop2)}
                        className="PartDropIcon"
                        style={{ rotate: groupDrop2 ? "180deg" : "0deg" }}
                        src={dropArrow}
                      />

                      <Box sx={{ display: groupDrop2 ? "block" : "none" }} className="statusPop">
                        <Box className="checkSelectorBox">
                          <span onClick={selectAllCheckData}>{t("commonTxt.selectAll")}</span >
                          <span onClick={deleteAllCheckData}>{t("commonTxt.deSelectAll")}</span>
                        </Box>

                        {
                          hostItems?.map((el, i) => (
                            <Box key={i} className="statusPopItem"
                              onClick={() => {
                                if (chackVal.includes(el)) {
                                  chackVal.filter(item => item !== el)
                                  return;
                                } else {
                                  pushData(el)
                                }
                              }}

                            >
                              <Box className="chackBox">
                                <img className="chackBoxImg" src={chackBox} />
                                <img style={{ display: chackVal.includes(el) ? "block" : "none" }} className="chack" src={check} />
                              </Box>
                              <p>{el}</p>
                            </Box>
                          ))
                        }
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box className="timerTableBox">

                  <Box className="timerCol">
                    {
                      timeZone1?.map((el, i) => (
                        <Box key={i} className="timerSel">
                          <Box onClick={(e) => {
                            handelTimerClick(setTimeZoneIndex1, timeZoneIndex1, i, e)
                            setTimeZoneIndex2(null)
                            setTimeZoneIndex3(null)
                          }} className={timeZoneIndex1 === i ? "timerSelInnerBox timerSelInnerBActive" : "timerSelInnerBox"}>
                            <Typography>{el}</Typography>
                            <Box id="hostS" sx={{ display: timeZoneIndex1 === i ? "flex" : "none" }} className="addHostBox addHostBox1">
                              <Box id="hostS" className="addHHeader">
                                <img id="hostS" src={calenderW} />
                                <Typography id="hostS">{t("LiveInterview.ScheduleMeeting")}</Typography>
                              </Box>
                              <Box id="hostS" className="ahRowItem">
                                <Typography id="hostS">{t("LiveInterview.Availability")}</Typography>
                              </Box>
                              <Box id="hostS" className="ahRowItem ahrHover" onClick={() => setScheduleInterviewParticipantPop(true)} >
                                <input id="hostS" className="custom-checkbox" type="checkBox" />
                                <Typography id="hostS">host 1 1</Typography>
                              </Box>
                              <Box id="hostS" className="ahRowItem ahrHover" onClick={() => setScheduleInterviewParticipantPop(true)}>
                                <input id="hostS" className="custom-checkbox" type="checkBox" />
                                <Typography id="hostS">host 1 2</Typography>
                              </Box>
                            </Box>

                            <Box className="timeScheduleActive" sx={{ display: i === 0 ? "flex" : "none" }}>
                              <Box className="user_Row">
                                <img src={avatar} />
                                <Typography>Alia Singh</Typography>
                              </Box>
                              <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: "10px"
                              }}>
                                <samp className='scheduleTime'>{el} (15 min)</samp>
                                <span>{t("workspaceSplash.completed")}</span>
                              </Box>
                            </Box>
                          </Box>
                        </Box>

                      ))
                    }
                  </Box>

                  <Box className="timerCol centerTimerCol">
                    {
                      timeZone2?.map((el, i) => (
                        <Box key={i} className="timerSel">
                          <Box onClick={(e) => {
                            handelTimerClick(setTimeZoneIndex2, timeZoneIndex2, i, e)
                            setTimeZoneIndex1(null)
                            setTimeZoneIndex3(null)
                          }} className={timeZoneIndex2 === i ? "timerSelInnerBox timerSelInnerBActive" : "timerSelInnerBox"}>
                            <Typography>{el}</Typography>
                            <Box id="hostS" sx={{ display: timeZoneIndex2 === i ? "flex" : "none" }} className="addHostBox addHostBox2">
                              <Box id="hostS" className="addHHeader">
                                <img id="hostS" src={calenderW} />
                                <Typography id="hostS">{t("LiveInterview.ScheduleMeeting")}</Typography>
                              </Box>
                              <Box id="hostS" className="ahRowItem">
                                <Typography id="hostS">{t("LiveInterview.Availability")}</Typography>
                              </Box>
                              <Box id="hostS" className="ahRowItem ahrHover" onClick={() => setScheduleInterviewParticipantPop(true)} >
                                <input id="hostS" className="custom-checkbox" type="checkBox" />
                                <Typography id="hostS">host 1</Typography>
                              </Box>
                              <Box id="hostS" className="ahRowItem ahrHover" onClick={() => setScheduleInterviewParticipantPop(true)}>
                                <input id="hostS" className="custom-checkbox" type="checkBox" />
                                <Typography id="hostS">host 2</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      ))
                    }
                  </Box>

                  <Box className="timerCol">
                    {
                      timeZone3?.map((el, i) => (
                        <Box key={i} className="timerSel">
                          <Box onClick={(e) => {
                            handelTimerClick(setTimeZoneIndex3, timeZoneIndex3, i, e)
                            setTimeZoneIndex1(null)
                            setTimeZoneIndex2(null)
                          }} className={timeZoneIndex3 === i ? "timerSelInnerBox timerSelInnerBActive" : "timerSelInnerBox"}>
                            <Typography>{el}</Typography>
                            <Box id="hostS" sx={{ display: timeZoneIndex3 === i ? "flex" : "none" }} className="addHostBox addHostBox3">
                              <Box id="hostS" className="addHHeader">
                                <img id="hostS" src={calenderW} />
                                <Typography id="hostS">{t("LiveInterview.ScheduleMeeting")}</Typography>
                              </Box>
                              <Box id="hostS" className="ahRowItem">
                                <Typography id="hostS">{t("LiveInterview.Availability")}</Typography>
                              </Box>
                              <Box id="hostS" className="ahRowItem ahrHover" onClick={() => setScheduleInterviewParticipantPop(true)} >
                                <input id="hostS" className="custom-checkbox" type="checkBox" />
                                <Typography id="hostS">host 1</Typography>
                              </Box>
                              <Box id="hostS" className="ahRowItem ahrHover" onClick={() => setScheduleInterviewParticipantPop(true)} >
                                <input id="hostS" className="custom-checkbox" type="checkBox" />
                                <Typography id="hostS">host 2</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      ))
                    }
                  </Box>
                </Box>
                <Box className="workPupBtnBox">
                  <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} />
                  <AppBtn buttonText={t("commonTxt.saveBtn")} />
                </Box>
              </Box>


              {/* Scheduled Meetings */}
              <Box className="schedule_Section_box" sx={{ display: nav === 1 ? "block" : "none" }}>
                <Box className="nextMettingBox">
                  <Box className="mettingImgBox"><img src={mettingImg} /></Box>
                  <Box className="mettingTextBox">
                    <Typography className='mettingHeaderText'>{t("LiveInterview.NextMeeting")}</Typography>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.Participant")} :
                      <span>   Participant 1</span>
                    </Typography>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.ScheduledDate")} :
                      <span> Jul 18, 2023</span>
                    </Typography>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.ScheduledTime")} :
                      <span>  11:00 AM</span>
                    </Typography>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.host")} :
                      <span>  Host 1</span>
                    </Typography>

                    <Box className="mettingBtnBox">
                      <AppBtnOutLine buttonText={t("commonTxt.editMeetingBtn")} onClick={() => setEditMeetingPop(true)} />
                      <AppBtn buttonText={t("commonTxt.startMeetingBtn")} onClick={() => setLiveInterViewPop(true)} />
                    </Box>
                  </Box>
                </Box>

                <Box className="MettingCardBox">

                  <Box className="mettingCard">
                    <Box className="user_Row">
                      <img src={avatar} />
                      <Typography>Alia Singh</Typography>
                    </Box>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.ScheduledDate")} :
                      <span>  30/07/2023</span>
                    </Typography>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.ScheduledTime")}  :
                      <span>   11:00 AM</span>
                    </Typography>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.host")} :
                      <span>Host 1</span>
                    </Typography>
                    <Box className="mettingBtnBox">
                      <Typography>{t("commonTxt.editMeetingBtn")}</Typography>
                      <AppBtn buttonText={t("commonTxt.startMeetingBtn")} />
                    </Box>
                  </Box>



                  <Box className="mettingCard">
                    <Box className="user_Row">
                      <img src={avatar} />
                      <Typography>Alia Singh</Typography>
                    </Box>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.ScheduledDate")} :
                      <span>  30/07/2023</span>
                    </Typography>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.ScheduledTime")} :
                      <span>   11:00 AM</span>
                    </Typography>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.host")} :
                      <span>   Host 1</span>
                    </Typography>
                    <Box className="mettingBtnBox">
                      <Typography>{t("commonTxt.editMeetingBtn")}</Typography>
                      <AppBtn buttonText={t("commonTxt.startMeetingBtn")} />
                    </Box>
                  </Box>



                  <Box className="mettingCard">
                    <Box className="user_Row">
                      <img src={avatar} />
                      <Typography>Alia Singh</Typography>
                    </Box>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.ScheduledDate")} :
                      <span>  30/07/2023</span>
                    </Typography>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.ScheduledTime")} :
                      <span>   11:00 AM</span>
                    </Typography>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.host")} :
                      <span> Host 1</span>
                    </Typography>
                    <Box className="mettingBtnBox">
                      <Typography>{t("commonTxt.editMeetingBtn")}</Typography>
                      <AppBtn buttonText={t("commonTxt.startMeetingBtn")} />
                    </Box>
                  </Box>
                </Box>



                <Box className="pagenation">
                  <Box className="tabelBtn">
                    <img src={arrow} />
                    <Typography>{t("commonTxt.previouspage")}</Typography>
                  </Box>

                  <Box className="pageNumberBox">
                    <Box
                      // onClick={() => setCurrentPage(0)}
                      className="pageNumber pageNumberActive"
                    >
                      <p>1</p>
                    </Box>
                    <Box
                      // onClick={() => setCurrentPage(1)}
                      className="pageNumber"
                    >
                      <p>2</p>
                    </Box>
                    <Box
                      // onClick={() => setCurrentPage(2)}
                      className="pageNumber"

                    >
                      <p>3</p>
                    </Box>
                    <Box
                      // onClick={() => setCurrentPage(3)}
                      className="pageNumber"

                    >
                      <p>4</p>
                    </Box>
                    <Box
                      // onClick={() => setCurrentPage(4)}
                      className="pageNumber"

                    >
                      <p>5</p>
                    </Box>
                  </Box>

                  <Box className="tabelBtn">
                    <Typography>{t("commonTxt.nextPage")}</Typography>
                    <img style={{ rotate: "180deg" }} src={arrow} />
                  </Box>
                </Box>
              </Box>



              {/* recording */}
              <Box className="schedule_Section_box recording" sx={{ display: nav === 4 ? "block" : "none" }}>
                {recordIndexPage ?
                  <>
                    <Box className="companyListNav">
                      <Box className="AppNavBtnBox">
                        <Box
                          className="allTaskNavDrop"
                          onClick={() =>
                            setParticipantResNavDrop(!participantsResNavDrop)
                          }
                        >
                          <Box className="tNDL">
                            <img
                              style={{ width: "20px", height: "20px" }}
                              src={leftArrow}
                            />
                          </Box>

                          <Box className="tndC">
                            <Typography>{participantsResNavDropVal}</Typography>
                            <img
                              style={{
                                width: "20px",
                                height: "20px",
                                marginLeft: "10px",
                                rotate: participantsResNavDrop ? "180deg" : "0deg",
                              }}
                              src={chevronDown}
                            />
                          </Box>

                          <Box className="tNDR">
                            <img
                              style={{ width: "20px", height: "20px" }}
                              src={rightArrow}
                            />
                          </Box>

                          <Box
                            className="statusPop"
                            sx={{ display: participantsResNavDrop ? "block" : "none" }}
                          >
                            {taskList?.map((el, index) => (
                              <Box
                                key={index}
                                className="statusPopItem"
                                onClick={() => setParticipantResNavDropVal(el)}
                              >
                                <Typography>{el}</Typography>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                      <SearchBar />
                      <Box className="actTNavBtn">
                        <img src={groupIcon} />
                        <Typography>{t("commonTxt.groups")}</Typography>
                      </Box>
                    </Box>

                    <Box className="recordeingBox">
                      {recordCard()}
                      {recordCard()}
                      {recordCard()}
                      {recordCard()}
                      {recordCard()}
                      {recordCard()}
                    </Box>

                    <Box className="pagenation">
                      <Box onClick={handlePrev} className="tabelBtn">
                        <img src={arrow} />
                        <Typography>{t("commonTxt.previouspage")}</Typography>
                      </Box>

                      <Box className="pageNumberBox">
                        <Box
                          onClick={() => setCurrentPage(0)}
                          className={
                            currentPage === 0 ? "pageNumber pageNumberActive" : "pageNumber"
                          }
                        >
                          <p>1</p>
                        </Box>
                        <Box
                          onClick={() => setCurrentPage(1)}
                          className={
                            currentPage === 1 ? "pageNumber pageNumberActive" : "pageNumber"
                          }
                        >
                          <p>2</p>
                        </Box>
                        <Box
                          onClick={() => setCurrentPage(2)}
                          className={
                            currentPage === 2 ? "pageNumber pageNumberActive" : "pageNumber"
                          }
                        >
                          <p>3</p>
                        </Box>
                        <Box
                          onClick={() => setCurrentPage(3)}
                          className={
                            currentPage === 3 ? "pageNumber pageNumberActive" : "pageNumber"
                          }
                        >
                          <p>4</p>
                        </Box>
                        <Box
                          onClick={() => setCurrentPage(4)}
                          className={
                            currentPage === 4 ? "pageNumber pageNumberActive" : "pageNumber"
                          }
                        >
                          <p>5</p>
                        </Box>
                      </Box>

                      <Box onClick={handleNext} className="tabelBtn">
                        <Typography>{t("commonTxt.nextPage")}</Typography>
                        <img style={{ rotate: "180deg" }} src={arrow} />
                      </Box>
                    </Box>
                  </> :
                  <>
                    {/* Record index page */}
                    <Box className="videoIndexPage">
                      <Box className="recIndexNav">
                        <Box className="topBackBtn" onClick={() => setRecordIndexPage(true)}>
                          <img src={backArrow} />
                          <Typography className="neonBackText">{t("commonTxt.backBtn")}</Typography>
                        </Box>
                        <Box sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "20px"
                        }}>
                          <Box className="expoMetting" onClick={() => setExpoMettingDrop(!expoMettingDrop)}>
                            <img className='bulkIcon' src={bulkIcon} />
                            <Typography>{expoMettingDropVal}</Typography>
                            <img
                              className='drop_Icon'
                              style={{ rotate: participantsResNavDrop ? "180deg" : "0deg", }}
                              src={chevronDown}
                            />
                            <Box className="statusPop" sx={{ display: expoMettingDrop ? "block" : "none" }}>
                              {
                                expoMettingItem?.map((el, i) => (
                                  <Box key={i} className="statusPopItem" onClick={() => setExpoMettingDropVal(el)}>
                                    <Typography>{el}</Typography>
                                  </Box>
                                ))
                              }
                            </Box>
                          </Box>

                          <Box className="aiBtn" onClick={() => setAiIntegrationPop(true)}>
                            <img src={aiWhiteIcon} />
                            <Typography>{t("aiIntegrationPopup.aiIntegrationPopupHead")}</Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box className="videoSection">

                        <Box className="detail">
                          <Box className="rcAvatar"><img src={Avatar1} /></Box>
                          <Box className="rcText">
                            <Typography>Olivia Rhye</Typography>
                            <span>30/07/2023, 1:00 AM (15 Min)</span>
                          </Box>
                        </Box>

                        <Box className="videoRecPlayer">
                          <video className='video' controls>
                            <source src={video1} type="video/mp4" />
                          </video>
                        </Box>
                      </Box>

                      {/* tab section */}
                      <Box className="tabeSection">
                        <Box className="recpSectionNav">
                          <Box
                            onClick={() => setNav2(0)}
                            className={
                              nav2 === 0
                                ? "recpSectionNavItem recpSectionNavItemActive"
                                : "recpSectionNavItem"
                            }
                          >
                            <Typography>{t("projectParticipantReport.tblComment")}</Typography>
                          </Box>
                          <Box
                            onClick={() => setNav2(1)}
                            className={
                              nav2 === 1
                                ? "recpSectionNavItem recpSectionNavItemActive"
                                : "recpSectionNavItem"
                            }
                          >
                            <Typography>{t("projectSettingTranscript.projectSettingTranscriptHead")}</Typography>
                          </Box>
                          <Box
                            onClick={() => setNav2(2)}
                            className={
                              nav2 === 2
                                ? "recpSectionNavItem recpSectionNavItemActive"
                                : "recpSectionNavItem"
                            }
                          >
                            <Typography>{t("viewRecapImage.summaryAiTab")}</Typography>
                          </Box>
                          <Box
                            onClick={() => setNav2(3)}
                            className={
                              nav2 === 3
                                ? "recpSectionNavItem recpSectionNavItemActive"
                                : "recpSectionNavItem"
                            }
                          >
                            <Typography>{t("viewRecapImage.AiAnalysis")}</Typography>
                          </Box>
                        </Box>
                      </Box>

                      {/* Comments */}
                      <Box
                        className="sectionBox"
                        sx={{ display: nav2 === 0 ? "block" : "none" }}>
                        <Typography className="followText">{t("commonTxt.hideComments")}</Typography>
                        <Box className="commentListBox">
                          {nestedCommentSection({
                            img: avatar1,
                            name: "Orlando Diggs",
                            comment: "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                            date: "Aug 10, 2023",
                            time: "10 h",
                            postDrop: providerDrop,
                            setPostDrop: setProviderDrop,
                            postDropVal: providerDropVal,
                            setPostDropVal: setProviderDropVal
                          })}
                          {nestedCommentSection({
                            img: avatar2,
                            name: "Orlando Diggs",
                            comment: "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                            date: "Aug 10, 2023",
                            time: "10 h",
                            postDrop: providerDrop1,
                            setPostDrop: setProviderDrop1,
                            postDropVal: providerDropVal1,
                            setPostDropVal: setProviderDropVal1
                          })}
                          {nestedCommentSection({
                            img: avatar3,
                            name: "Orlando Diggs",
                            comment: "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                            date: "Aug 10, 2023",
                            time: "10 h",
                            postDrop: providerDrop2,
                            setPostDrop: setProviderDrop2,
                            postDropVal: providerDropVal2,
                            setPostDropVal: setProviderDropVal2
                          })}
                          {nestedCommentSection({
                            img: avatar2,
                            name: "Orlando Diggs",
                            comment: "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                            date: "Aug 10, 2023",
                            time: "10 h",
                            postDrop: providerDrop3,
                            setPostDrop: setProviderDrop3,
                            postDropVal: providerDropVal3,
                            setPostDropVal: setProviderDropVal3
                          })}
                        </Box>
                      </Box>


                      {/* Transcript */}
                      <Box
                        className="sectionBox"
                        sx={{ display: nav2 === 1 ? "block" : "none" }}>
                        <Box className="transcroptBox">
                          <Box className="transDrop">
                            <Typography className='translateLabel'>{t("viewRecapImage.viewRecapImage")} :</Typography>
                            <Box width={"250px"}><Status label={t("viewRecapImage.selectLanguagePlaceholder")} itemAry={languages} setStatusDrop={setLang} statusDrop={lang} setStatusDropVal={setLangVal} statusDropVal={langVal} /></Box>
                          </Box>
                        </Box>

                        <Box className="transSearchBox">
                          <Box sx={{ width: "88%" }}><SearchBar placeholder={t("LiveInterview.SearchMessage")} /></Box>

                          <Box className="filterBtn">
                            <img src={filterIcon} />
                            <p>{t("directoryCompanies.filters")}</p>
                          </Box>

                        </Box>

                        <Typography>{t("LiveInterview.JoinedMeeting")}t <span style={{ color: "#4F33D1" }}>5:30</span></Typography>
                        <Box className="gapBox"></Box>
                        <Typography>{t("LiveInterview.NataliaJoinedMeeting")}<span style={{ color: "#4F33D1" }}>5:30</span></Typography>

                        <Box className="chatBox">

                          <Box className="chatItem leftChat">
                            <Box className="chatInnerBox">
                              <Box className="chatInfo">
                                <Box className="chatUserImg">
                                  <img style={{ width: "20px" }} src={avatar1} />
                                  <span>Mary</span>
                                </Box>
                                <span>05:32 PM</span>
                              </Box>
                              <Box sx={{ background: "#A9DAFF" }} className="chatMessage">
                                <Typography>{t("LiveInterview.chatBoxSummery")}</Typography>
                              </Box>
                            </Box>
                          </Box>



                          <Box className="chatItem rightChat">
                            <Box className="chatInnerBox">
                              <Box className="chatInfo">
                                <span>{t("LiveInterview.host")}</span>
                                <Box className="chatUserImg">
                                  <img style={{ width: "20px" }} src={avatar1} />
                                  <span>05:32 PM</span>
                                </Box>
                              </Box>
                              <Box sx={{ background: "#DCD6F6" }} className="chatMessage">
                                <Typography>{t("LiveInterview.chatBoxSummery2")}</Typography>
                              </Box>
                            </Box>
                          </Box>

                          <Box className="chatItem rightChat">
                            <Box className="chatInnerBox">
                              <Box className="chatInfo">
                                <span>{t("LiveInterview.host")}</span>
                                <Box className="chatUserImg">
                                  <img style={{ width: "20px" }} src={avatar1} />
                                  <span>05:32 PM</span>
                                </Box>
                              </Box>
                              <Box sx={{ background: "#DCD6F6" }} className="chatMessage">
                                <Typography>{t("LiveInterview.chatBoxSummery3")}</Typography>
                              </Box>
                            </Box>
                          </Box>


                          <Box className="lineGraphBox">
                            <Box width={"30%"}>

                              <Box className="lineBar">
                                <Typography className="lineBarLabel">{t("LiveInterview.Spring")}</Typography>
                                <BorderLinearProgress variant="determinate" value={50} />
                              </Box>
                              <Box className="lineBar">
                                <Typography className="lineBarLabel">{t("LiveInterview.Summer")}</Typography>
                                <BorderLinearProgress variant="determinate" value={80} />
                                <img className="lBarAimg" style={{ width: "20px" }} src={avatar1} />
                                <img className="lBarAimg2" style={{ width: "20px" }} src={avatar2} />
                              </Box>
                              <Box className="lineBar">
                                <Typography className="lineBarLabel">{t("LiveInterview.Winter")}</Typography>
                                <BorderLinearProgress variant="determinate" value={20} />
                                <img className="lBarAimg" style={{ width: "20px" }} src={avatar3} />

                              </Box>
                              <Box className="lineBar">
                                <Typography className="lineBarLabel">F{t("LiveInterview.Fall")}{t("LiveInterview.all")}</Typography>
                                <BorderLinearProgress variant="determinate" value={0} />
                              </Box>
                            </Box>
                          </Box>

                          <Box className="chatItem leftChat">
                            <Box className="chatInnerBox">
                              <Box className="chatInfo">
                                <Box className="chatUserImg">
                                  <img style={{ width: "20px" }} src={avatar1} />
                                  <span>Mary</span>
                                </Box>
                                <span>05:32 PM</span>
                              </Box>
                              <Box sx={{ background: "#A9DAFF" }} className="chatMessage">
                                <Typography>{t("LiveInterview.chatBoxSummery4")}</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      {/* Summary (AI) */}
                      <Box
                        className="sectionBox"
                        sx={{ display: nav2 === 2 ? "block" : "none" }}
                      >
                        <Box className="transcroptBox">
                          <Box className="transDrop">
                            <Typography className='translateLabel'>{t("viewRecapImage.translateTo")} :</Typography>
                            <Box width={"200px"}><Status label="Select Language" itemAry={languages} setStatusDrop={setLang} statusDrop={lang} setStatusDropVal={setLangVal} statusDropVal={langVal} /></Box>
                          </Box>

                          <Box>
                            <SearchBar placeholder="Search Word" />
                          </Box>
                        </Box>

                        <Box className="rectVideosummeryBox">
                          <Box>
                            <Typography>{t("LiveInterview.summaryAi")}</Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                            <Typography sx={{ fontSize: "18px" }}>{t("LiveInterview.summaryAiSub1")}:</Typography>
                            <Typography>{t("LiveInterview.summaryAi1")}</Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                            <Typography sx={{ fontSize: "18px" }}>{t("LiveInterview.summaryAiSub2")}:</Typography>
                            <Typography>{t("LiveInterview.summaryAi2")}</Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                            <Typography sx={{ fontSize: "18px" }}>{t("LiveInterview.summaryAiSub3")}:</Typography>
                            <Typography>{t("LiveInterview.summaryAi3")}</Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                            <Typography sx={{ fontSize: "18px" }}>{t("LiveInterview.summaryAi4")}:</Typography>
                            <Typography>{t("LiveInterview.summaryAi4")}</Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                            <Typography sx={{ fontSize: "18px" }}>{t("LiveInterview.summaryAi5")}:</Typography>
                            <Typography>{t("LiveInterview.summaryAi5")}</Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                            <Typography sx={{ fontSize: "18px" }}>{t("LiveInterview.summaryAi6")}:</Typography>
                            <Typography>{t("LiveInterview.summaryAi6")}</Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                            <Typography>{t("LiveInterview.summaryAi7")}</Typography>
                          </Box>


                        </Box>

                      </Box>

                      {/* AI Analysis */}
                      <Box
                        className="sectionBox"
                        sx={{ display: nav2 === 3 ? "block" : "none" }}
                      >
                        <Box className="aiAnalysisSubNav">
                          <Box
                            onClick={() => setAiSubNav(0)}
                            className={
                              aiSubnav === 0 ? "aiSubTab aiSubTabActive" : "aiSubTab"
                            }
                          >
                            <Typography>{t("viewRecapImage.commentAnalysisTab")}</Typography>
                          </Box>
                          <Box
                            onClick={() => setAiSubNav(1)}
                            className={
                              aiSubnav === 1 ? "aiSubTab aiSubTabActive" : "aiSubTab"
                            }
                          >
                            <Typography>{t("viewRecapVideo.videoAnalysisTab")}</Typography>
                          </Box>
                        </Box>

                        <Box
                          className="aiSubSection"
                          sx={{ display: aiSubnav === 1 ? "block" : "none" }}
                        >
                          <Box className="tabelNav">
                            <Typography className="pageTitle">{t("conversation.pieLabel")}</Typography>
                            <Box
                              className="exporOpenBtn"
                              onClick={() => setExportDrop(!exportDrop)}
                            >
                              <img src={exportIcon} />
                              <Typography>{t("commonTxt.exportLink")}</Typography>
                              <img
                                src={dropIcon}
                                style={{ rotate: exportDrop ? "180deg" : "0deg" }}
                              />
                              <Box
                                className="statusPop"
                                sx={{ height: exportDrop ? "auto" : "0px" }}
                              >
                                {exportItem?.map((el, i) => (
                                  <Box className="statusPopItem">
                                    <p>{el}</p>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          </Box>

                          <Box className="pieBox">
                            <ApexChart />
                          </Box>

                          <Box className="chartTableNav">
                            <Box className="chartNavItem">
                              <Typography>{t("commonTxt.hideBtn")}</Typography>
                            </Box>
                            <Box className="chartNavItem">
                              <Typography>{t("commonTxt.groupBtn2")}</Typography>
                            </Box>
                            <Box className="chartNavItem">
                              <Typography>{t("commonTxt.changeColorBtn")}</Typography>
                            </Box>
                          </Box>

                          <Box mb={6} className="workListBox">
                            <Box className="haderRow" minWidth={"955px"}>
                              <Box minWidth={"20%"} className="Tabelsel TabelStatesel">
                                <Typography>{t("commonTxt.Actions")}</Typography>
                              </Box>
                              <Box minWidth={"15%"} className="Tabelsel">
                                <Typography>{t("commonTxt.Color")}</Typography>
                              </Box>
                              <Box minWidth={"30%"} className="Tabelsel TabelStatesel">
                                <Typography>{t("commonTxt.Tag")}</Typography>
                              </Box>

                              <Box minWidth={"13%"} className="Tabelsel">
                                <Typography>{t("commonTxt.Responses")}</Typography>
                              </Box>
                              <Box minWidth={"13%"} className="Tabelsel">
                                <Typography>{t("commonTxt.Percentage")}</Typography>
                              </Box>
                            </Box>

                            <Box className="TabelRow" minWidth={"955px"}>
                              <Box
                                minWidth={"20%"}
                                gap={"40px"}
                                className="Tabelsel navFsel navtable"
                              >
                                <img src={Option2LIcon} />
                                <input type="checkBox" className="custom-checkbox" />
                              </Box>
                              <Box minWidth={"15%"} className="Tabelsel Tabelcentersel">
                                <Box
                                  className="colorBox"
                                  sx={{ background: "#21A1F9" }}
                                ></Box>
                              </Box>
                              <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                                <img src={editIcon} />
                                <Typography>😍</Typography>
                                <Typography>{t("commonTxt.Like")}</Typography>
                              </Box>
                              <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                                <Typography>18</Typography>
                              </Box>
                              <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                                <Typography>58.2%</Typography>
                              </Box>
                            </Box>

                            <Box className="TabelRow" minWidth={"955px"}>
                              <Box
                                minWidth={"20%"}
                                gap={"40px"}
                                className="Tabelsel navFsel navtable"
                              >
                                <img src={Option2LIcon} />
                                <input type="checkBox" className="custom-checkbox" />
                              </Box>
                              <Box minWidth={"15%"} className="Tabelsel Tabelcentersel">
                                <Box
                                  className="colorBox"
                                  sx={{ background: "#FF4E72" }}
                                ></Box>
                              </Box>
                              <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                                <img src={editIcon} />
                                <Typography>😟</Typography>
                                <Typography>{t("commonTxt.dislike")}</Typography>
                              </Box>
                              <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                                <Typography>05</Typography>
                              </Box>
                              <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                                <Typography>18.5%</Typography>
                              </Box>
                            </Box>

                            <Box className="TabelRow" minWidth={"955px"}>
                              <Box
                                minWidth={"20%"}
                                gap={"40px"}
                                className="Tabelsel navFsel navtable"
                              >
                                <img src={Option2LIcon} />
                                <input type="checkBox" className="custom-checkbox" />
                              </Box>
                              <Box minWidth={"15%"} className="Tabelsel Tabelcentersel">
                                <Box
                                  className="colorBox"
                                  sx={{ background: "#FFBE28" }}
                                ></Box>
                              </Box>
                              <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                                <img src={editIcon} />
                                <Typography>🤨</Typography>
                                <Typography>{t("commonTxt.confused")}</Typography>
                              </Box>
                              <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                                <Typography>08</Typography>
                              </Box>
                              <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                                <Typography>23.3%</Typography>
                              </Box>
                            </Box>
                          </Box>

                          {/* Sentiments Distribution */}
                          <Box className="tabelNav">
                            <Typography className="pageTitle">{t("conversation.BubbleChartLabel")}</Typography>
                            <Box
                              className="exporOpenBtn"
                              onClick={() => setExportDrop2(!exportDrop2)}
                            >
                              <img src={exportIcon} />
                              <Typography>{t("commonTxt.exportLink")}</Typography>
                              <img
                                src={dropIcon}
                                style={{ rotate: exportDrop2 ? "180deg" : "0deg" }}
                              />
                              <Box
                                className="statusPop"
                                sx={{ height: exportDrop2 ? "auto" : "0px" }}
                              >
                                {exportItem?.map((el, i) => (
                                  <Box className="statusPopItem">
                                    <p>{el}</p>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          </Box>
                          <Box className="bubbleChartBox">
                            <BubbleChart />
                          </Box>



                          {/* Quotes Analysis */}
                          <Box className="tabelNav">
                            <Typography className="pageTitle">{t("conversation.lineChart1Label")}</Typography>
                            <Box
                              className="exporOpenBtn"
                              onClick={() => setExportDrop3(!exportDrop3)}
                            >
                              <img src={exportIcon} />
                              <Typography>{t("commonTxt.Export")}</Typography>
                              <img
                                src={dropIcon}
                                style={{ rotate: exportDrop3 ? "180deg" : "0deg" }}
                              />
                              <Box
                                className="statusPop"
                                sx={{ height: exportDrop3 ? "auto" : "0px" }}
                              >
                                {exportItem?.map((el, i) => (
                                  <Box className="statusPopItem">
                                    <p>{el}</p>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          </Box>

                          <Box className="barChart">
                            <BarChart series={series} categories={categories} />
                          </Box>




                          <Box className="tabelNav">
                            <Typography className="pageTitle">{t("commonTxt.excerptBtn")}</Typography>
                            <Box
                              className="exporOpenBtn"
                              onClick={() => setExportDrop4(!exportDrop4)}
                            >
                              <img src={exportIcon} />
                              <Typography>{t("commonTxt.Export")}</Typography>
                              <img
                                src={dropIcon}
                                style={{ rotate: exportDrop4 ? "180deg" : "0deg" }}
                              />
                              <Box
                                className="statusPop"
                                sx={{ height: exportDrop4 ? "auto" : "0px" }}
                              >
                                {exportItem?.map((el, i) => (
                                  <Box className="statusPopItem">
                                    <p>{el}</p>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          </Box>

                          <Box className="barChart">
                            <BarChart series={series2} categories={categories2} />
                          </Box>


                          {/* bottom tab section */}
                          <Box className="bottomTabel">

                            <Box className="tabeSection">
                              <Box className="recpSectionNav">
                                <Box
                                  onClick={() => setNav3(0)}
                                  className={
                                    nav3 === 0
                                      ? "recpSectionNavItem recpSectionNavItemActive"
                                      : "recpSectionNavItem"
                                  }
                                >
                                  <Typography>{t("commonTxt.Manual")}</Typography>
                                </Box>
                                <Box
                                  onClick={() => setNav3(1)}
                                  className={
                                    nav3 === 1
                                      ? "recpSectionNavItem recpSectionNavItemActive"
                                      : "recpSectionNavItem"
                                  }
                                >
                                  <Typography>{t("commonTxt.ExcerptsAI")}</Typography>
                                </Box>
                              </Box>
                            </Box>


                            {/* Manual */}
                            <Box sx={{ display: nav3 === 0 ? "block" : "none" }}>
                              <Box className="tabelNav">
                                <Typography className="pageTitle">{t("commonTxt.excerptBtn")}</Typography>
                                <Box width={"320px"}><SearchBar placeholder="Search" /></Box>
                              </Box>


                              <Box mb={6} className="workListBox">
                                <Box className="haderRow" minWidth={"955px"}>
                                  <Box minWidth={"10%"} className="Tabelsel TabelStatesel">
                                    <Typography>{t("commonTxt.Uses")}</Typography>
                                  </Box>
                                  <Box minWidth={"17%"} className="Tabelsel TabelStatesel">
                                    <Typography>{t("commonTxt.Codes")}</Typography>
                                  </Box>
                                  <Box minWidth={"40%"} className="Tabelsel TabelStatesel">
                                    <Typography>{t("commonTxt.Description")}</Typography>
                                  </Box>
                                </Box>

                                <Box className="TabelRow" minWidth={"955px"}>
                                  <Box minWidth={"10%"} className="Tabelsel ">
                                    <Box className="UBox" >6</Box>
                                  </Box>
                                  <Box minWidth={"17%"} gap={"10px"} className="Tabelsel">
                                    <Typography>{t("conversation.Empowerment")}</Typography>
                                  </Box>
                                  <Box minWidth={"40%"} className="Tabelsel Tabelcentersel">
                                    <Typography>{t("conversation.EmpowermentVal")}</Typography>
                                  </Box>
                                </Box>

                                <Box className="TabelRow" minWidth={"955px"}>
                                  <Box minWidth={"10%"} className="Tabelsel ">
                                    <Box className="UBox" >4</Box>
                                  </Box>
                                  <Box minWidth={"17%"} gap={"10px"} className="Tabelsel">
                                    <Typography>{t("conversation.Nostalgia")}</Typography>
                                  </Box>
                                  <Box minWidth={"40%"} className="Tabelsel Tabelcentersel">
                                    <Typography>{t("conversation.NostalgiaVal")}</Typography>
                                  </Box>
                                </Box>

                                <Box className="TabelRow" minWidth={"955px"}>
                                  <Box minWidth={"10%"} className="Tabelsel ">
                                    <Box className="UBox" >2</Box>
                                  </Box>
                                  <Box minWidth={"17%"} gap={"10px"} className="Tabelsel">
                                    <Typography>{t("conversation.Relaxation")}</Typography>
                                  </Box>
                                  <Box minWidth={"40%"} className="Tabelsel Tabelcentersel">
                                    <Typography>{t("conversation.RelaxationVal")}</Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            {/* Excerpts */}
                            <Box sx={{ display: nav3 === 1 ? "block" : "none" }}></Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </>
                }
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
