import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from "react-i18next";
import "./style.css";

//Images
import dropArrow from "../../../Assets/Images/chevron-down.png";

//components 
import { AppBtn, AppBtnOutLine } from '../../../Components/AppTools/AppButton';

export default function ProjectTranscriptActivities({ projectSettingTranscriptDropVal }) {

    const [transcriptActivityDrop, setTranscriptActivityDrop] = useState(false);
    const [transcriptActivityDropVal, setTranscriptActivityDropVal] = useState();
    const [transcriptDateRangeDrop, setTranscriptDateRangeDrop] = useState(false);
    const [transcriptDateRangeDropVal, setTranscriptDateRangeDropVal] = useState();
    const [participantIdentityDrop, setParticipantIdentityDrop] = useState(false);
    const [participantIdentityDropVal, setParticipantIdentityDropVal] = useState();
    const {
        t,
        i18n: { changeLanguage, language },
    } = useTranslation();

    return (
        <>
            <Box sx={{ display: projectSettingTranscriptDropVal === "Activities" ? "block" : "none" }}>
                <Box className="projectPrivacyIdentity">
                    <Typography className=''>{t("projectTranscriptActivities.activitiesDropLabel")}</Typography>
                    <Box
                        className="inputAppDrop participantIdentityDrop"
                        onClick={() => setTranscriptActivityDrop(!transcriptActivityDrop)}
                    >
                        <Typography>{transcriptActivityDropVal || t("projectTranscriptActivities.activitiesDropPlaceholder")}</Typography>
                        <img
                            style={{ rotate: transcriptActivityDrop ? "180deg" : "0deg" }}
                            src={dropArrow}
                        />
                        {/* pop */}
                        <Box
                            sx={{ height: transcriptActivityDrop ? "auto" : "0px" }}
                            className="statusPop"
                        >
                            <Box
                                onClick={() => setTranscriptActivityDropVal("All Activities")}
                                className="statusPopItem"
                            >
                                <Typography>All Activities</Typography>
                            </Box>
                            <Box
                                onClick={() => setTranscriptActivityDropVal("Activity 1")}
                                className="statusPopItem"
                            >
                                <Typography>Activity 1</Typography>
                            </Box>
                            <Box
                                onClick={() => setTranscriptActivityDropVal("Activity 2")}
                                className="statusPopItem"
                            >
                                <Typography>Activity 2</Typography>
                            </Box>

                        </Box>
                    </Box>
                </Box>
                <Box className="projectPrivacyIdentity">
                    <Typography className=''>{t("projectTranscriptActivities.responseDateRangeLabel")}</Typography>
                    <Box
                        className="inputAppDrop participantIdentityDrop"
                        onClick={() => setTranscriptDateRangeDrop(!transcriptDateRangeDrop)}
                    >
                        <Typography>{transcriptDateRangeDropVal || t("projectTranscriptActivities.responseDateRangePlaceholder")}</Typography>
                        <img
                            style={{ rotate: transcriptDateRangeDrop ? "180deg" : "0deg" }}
                            src={dropArrow}
                        />
                        {/* pop */}
                        <Box
                            sx={{ height: transcriptDateRangeDrop ? "auto" : "0px" }}
                            className="statusPop"
                        >
                            <Box
                                onClick={() => setTranscriptDateRangeDropVal("All Time")}
                                className="statusPopItem"
                            >
                                <Typography>All Time</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Typography className='projectTranscriptLabel'>{t("projectTranscriptActivities.formatLabel")}</Typography>
                <Box className="projectRadioBox">
                    <Box className="projectRadioBoxSection">
                        <Box className="ProjectRadioInput">
                            <input
                                className="emailSettingInput emailSettingRadio"
                                type='radio'
                                name='projectState'
                                defaultChecked
                            />
                            <Typography className="emailSettingLabel">{t("projectTranscriptActivities.formatRadio1")}</Typography>
                        </Box>
                        <Box className="ProjectRadioInput">
                            <input
                                className="emailSettingInput emailSettingRadio"
                                type='radio'
                                name='projectState'
                            />
                            <Typography className="emailSettingLabel">{t("projectTranscriptActivities.formatRadio2")}</Typography>
                        </Box>
                    </Box>
                    <Box className="projectRadioBoxSection">
                        <Box className="ProjectRadioInput">
                            <input
                                className="emailSettingInput emailSettingRadio"
                                type='radio'
                                name='projectState'
                            />
                            <Typography className="emailSettingLabel">{t("projectTranscriptActivities.formatRadio3")}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Typography className='projectTranscriptLabel'>{t("projectTranscriptActivities.groupByLabel")}</Typography>
                <Box className="projectRadioBox">
                    <Box className="projectRadioBoxSection">
                        <Box className="ProjectRadioInput">
                            <input
                                className="emailSettingInput emailSettingRadio"
                                type='radio'
                                name='projectState'
                                defaultChecked
                            />
                            <Typography className="emailSettingLabel">{t("projectTranscriptActivities.groupByRadio1")}</Typography>
                        </Box>
                    </Box>
                    <Box className="projectRadioBoxSection">
                        <Box className="ProjectRadioInput">
                            <input
                                className="emailSettingInput emailSettingRadio"
                                type='radio'
                                name='projectState'
                            />
                            <Typography className="emailSettingLabel">{t("projectTranscriptActivities.groupByRadio2")}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Typography className='projectTranscriptLabel'>{t("projectParticipantReport.subHeaderTxt1")}</Typography>
                <Box className="projectRadioBox">
                    <Box className="projectRadioBoxSection">
                        <Box className="ProjectRadioInput">
                            <input className="custom-checkbox" type="checkBox" defaultChecked />
                            <Typography className="emailSettingLabel">{t("projectParticipantReport.inclusionComment")}</Typography>
                        </Box>
                        <Box className="ProjectRadioInput">
                            <input className="custom-checkbox" type="checkBox" defaultChecked />
                            <Typography className="emailSettingLabel">{t("projectParticipantReport.inclusionRatingt")}</Typography>
                        </Box>
                        <Box className="ProjectRadioInput">
                            <input className="custom-checkbox" type="checkBox" />
                            <Typography className="emailSettingLabel">{t("projectTranscriptActivities.incompletesCheckbox")}</Typography>
                        </Box>
                    </Box>
                    <Box className="projectRadioBoxSection">
                        <Box className="ProjectRadioInput">
                            <input className="custom-checkbox" type="checkBox" defaultChecked />
                            <Typography className="emailSettingLabel">{t("projectTranscriptActivities.excerptsCheckbox")}</Typography>
                        </Box>
                        <Box className="ProjectRadioInput">
                            <input className="custom-checkbox" type="checkBox" defaultChecked />
                            <Typography className="emailSettingLabel">{t("excerptReport.backroomCheckbox")}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Typography className='projectTranscriptLabel'>{t("projectParticipantReport.subHeaderTxt2")}</Typography>
                <Box className="projectPrivacyIdentity">
                    <Typography className=''>{t("projectParticipantReport.participantIdentity")}</Typography>
                    <Box
                        className="inputAppDrop participantIdentityDrop"
                        onClick={() => setParticipantIdentityDrop(!participantIdentityDrop)}
                    >
                        <Typography>{participantIdentityDropVal || t("projectPrivacy.AdminsIdentitiesPlaceholder")}</Typography>
                        <img
                            style={{ rotate: participantIdentityDrop ? "180deg" : "0deg" }}
                            src={dropArrow}
                        />
                        {/* pop */}
                        <Box
                            sx={{ height: participantIdentityDrop ? "auto" : "0px" }}
                            className="statusPop"
                        >
                            <Box
                                onClick={() => setParticipantIdentityDropVal("Identity 1")}
                                className="statusPopItem"
                            >
                                <Typography>Identity 1</Typography>
                            </Box>
                            <Box
                                onClick={() => setParticipantIdentityDropVal("Identity 2")}
                                className="statusPopItem"
                            >
                                <Typography>Identity 2</Typography>
                            </Box>

                        </Box>
                    </Box>
                </Box>
                <Box className="projectRadioBox">
                    <Box className="projectRadioBoxSection">
                        <Box className="ProjectRadioInput">
                            <input
                                className="emailSettingInput emailSettingRadio"
                                type='radio'
                                name='projectState'
                                defaultChecked
                            />
                            <Typography className="emailSettingLabel">{t("projectParticipantReport.participantAllParticipant")}</Typography>
                        </Box>
                        <Box className="ProjectRadioInput">
                            <input
                                className="emailSettingInput emailSettingRadio"
                                type='radio'
                                name='projectState'
                            />
                            <Typography className="emailSettingLabel">{t("projectParticipantReport.participantSelectParticipant")}</Typography>
                        </Box>
                    </Box>
                    <Box className="projectRadioBoxSection">
                        <Box className="ProjectRadioInput">
                            <input
                                className="emailSettingInput emailSettingRadio"
                                type='radio'
                                name='projectState'
                            />
                            <Typography className="emailSettingLabel">{t("projectParticipantReport.participantSelectGroup")}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className="projectRadioBox projectTranscriptParticipant">
                    <Box className="projectRadioBoxSection">
                        <Box className="ProjectRadioInput">
                            <input className="custom-checkbox" type="checkBox" defaultChecked />
                            <Typography className="emailSettingLabel">{t("projectTranscriptActivities.participantPhotosCheckbox")}</Typography>
                        </Box>
                    </Box>
                    <Box className="projectRadioBoxSection">
                        <Box className="ProjectRadioInput">
                            <input className="custom-checkbox" type="checkBox" defaultChecked />
                            <Typography className="emailSettingLabel">{t("projectTranscriptActivities.participantEmailsCheckbox")}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Typography className='projectTranscriptLabel'>{t("projectTranscriptActivities.outputLabel")}</Typography>
                <Box className="projectRadioBox">
                    <Box className="projectRadioBoxSection">
                        <Box className="ProjectRadioInput">
                            <input
                                className="emailSettingInput emailSettingRadio"
                                type='radio'
                                name='projectState'
                                defaultChecked
                            />
                            <Typography className="emailSettingLabel">{t("projectTranscriptActivities.outputCheckbox1")}</Typography>
                        </Box>
                        <Box className="ProjectRadioInput">
                            <input
                                className="emailSettingInput emailSettingRadio"
                                type='radio'
                                name='projectState'
                            />
                            <Typography className="emailSettingLabel">{t("projectTranscriptActivities.outputCheckbox2")}</Typography>
                        </Box>
                    </Box>
                    <Box className="projectRadioBoxSection">
                        <Box className="ProjectRadioInput">
                            <input
                                className="emailSettingInput emailSettingRadio"
                                type='radio'
                                name='projectState'
                            />
                            <Typography className="emailSettingLabel">{t("projectTranscriptActivities.outputCheckbox3")}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className="workPupBtnBox">
                    <AppBtnOutLine buttonText={t("commonTxt.resetBtn")} />
                    <AppBtn buttonText={t("commonTxt.generateTranscriptBtn")} />
                </Box>
            </Box>
        </>
    )
}
