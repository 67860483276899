import { Button } from "@mui/material";
import React from "react";
import "./style.css";

import AddIcon from "@mui/icons-material/Add";

export const AppBtn = ({ buttonText, onClick, disabled }) => {
  return (
    <>
      <Button
        disabled={disabled}
        onClick={onClick}
        className={disabled ? "appBtn appBtnDisable" : "appBtn"}
      >
        {buttonText}
      </Button>
    </>
  );
};

export const AppBtnOutLine = ({ buttonText, onClick, disabled }) => {
  return (
    <>
      <Button variant="outlined" disabled={disabled} onClick={onClick} className="appBtnOutline">
        {buttonText}
      </Button>
    </>
  );
};

export const AppAddBtn = ({ buttonText, onClick, disabled }) => {
  return (
    <>
      <Button
        disabled={disabled}
        onClick={onClick}
        className={disabled ? "appBtn appBtnDisable" : "appBtn"}
        startIcon={<AddIcon />}
      >
        {buttonText}
      </Button>
    </>
  );
};

export const AppBtnText = ({ buttonText, onClick }) => {
  return (
    <>
      <Button variant="outlined" onClick={onClick} className="appBtnText">
        {buttonText}
      </Button>
    </>
  );
};

export const RedBtn = ({ buttonText, onClick }) => {
  return (
    <>
      <Button className="redBtn" onClick={onClick}>
        {buttonText}
      </Button>
    </>
  );
};

export const PurplelBtn = ({ buttonText, onClick }) => {
  return (
    <>
      <Button className="PurplelBtn" onClick={onClick}>
        {buttonText}
      </Button>
    </>
  );
};

export const AppRevokeBtn = ({ buttonText, onClick, disabled }) => {
  return (
    <>
      <Button
        disabled={disabled}
        onClick={onClick}
        className={disabled ? "appRevokeBtn appBtnDisable" : "appRevokeBtn"}
      >
        {buttonText}
      </Button>
    </>
  );
};
