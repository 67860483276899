import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, FormControl } from "@mui/material";
import "./style.css";

import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// images
import arrow from "../../../../Assets/Images/arrow.png";
import projectImg1 from "../../../../Assets/Images/rProject1.png";
import projectImg2 from "../../../../Assets/Images/rProject2.png";
import projectImg3 from "../../../../Assets/Images/rProject3.png";
import projectImg4 from "../../../../Assets/Images/rProject4.png";
import projectImg5 from "../../../../Assets/Images/rProject5.png";
import VDot from "../../../../Assets/Images/more-vertical.png";
import axios from "../../../../lib/axios";
import DummyPlaceholderProject from "../../../../Assets/Images/DummyProject.png";
import { setSelectedProject } from "../../../../lib/store/features/workspaceSlice";
//components
import {
  SearchBar,
  AllDrop,
  AllDrop2,
  AllDrop3,
} from "../../../../Components/AppTools/AppTool";
import {
  trashBlack,
  settingIconBlack,
  duplicatehBlack,
  participantshBlack,
//   projectData,
  sideHaumIcon,
  completedIcon,
  inProgressIcon,
  pendingIcon,
} from "../../../../Assets/Data";

import horizontalDot from "../../../../Assets/Images/more-horizontal.png";

import { renderUserLayout } from "../../../../lib/helpers";
import { setUserWorkspace } from "../../../../Services/auth";
import { AppAddBtn, AppBtn } from "../../../../Components/AppTools/AppButton";
import userIcon from "../../../../Assets/Images/users.png";
import clockIcon from "../../../../Assets/Images/clock.png";
import sideMenu from "../../../../Components/Researchers/SideMenu";

import RecentProjectCard from "../../../../Components/Researchers/RecentProjectCard";
import AddProjectPop from "../../../../Components/Researchers/AddProjectPop";
import WorkspaceAddPagePop from "../../../../Components/Researchers/WorkspaceAddPagePop";
import ParticipantPop from "../../../../Components/Researchers/ParticipantPop";
import NoProjectBgTemplate from "../../../../Components/Researchers/NoProjectBgTemplate";
import AddProjectNextPop from "../../../../Components/Researchers/AddProjectNextPop";
import DuplicateProjectPop from "../../../../Components/Researchers/DuplicateProjectPop";
import DuplicateProjectNextPop from "../../../../Components/Researchers/DuplicateProjectNextPop";
import DeleteProjectPop from "../../../../Components/Researchers/DeleteProjectPop";
import Pagination from "../../../../Components/AppTools/Pagination";
import { formatDate, viewImage } from "../../../../lib/helpers";
import { useAppSelector, useAppDispatch } from "../../../../lib/store/store";
import EditProjectPop from "../../../../Components/Researchers/EditProjectPop";
import EditProjectNextPop from "../../../../Components/Researchers/EditProjectNextPop";
import {
  USER_TYPE_COLLABORATOR,
  USER_TYPE_RESEARCHER,
  USER_TYPE_OBSERVER,
  USER_TYPE_SUPERADMIN, 
  USER_TYPE_WS_ADMIN
} from "../../../../lib/constants";

export default function WorkspaceManagementSplash({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
}) {
  let dispatch = useAppDispatch();
  const [allDrop, setAllDrop] = useState();
  const [allDropVal, setAllDropVal] = useState("All");
  const [allDrop2, setAllDrop2] = useState();
  const [allDrop2Val, setAllDrop2Val] = useState("Page Options");
  const [allDrop3, setAllDrop3] = useState();
  const [addProject, setAdddProject] = useState();
  const [allDrop3Val, setAllDrop3Val] = useState("Site Page 1");
  const [newArrayData, setNewArrayData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [addProjectPop, setAddProjectPop] = useState(false);

  const [addProjectNextPop, setaddProjectNextPop] = useState(false);
  const [duplicateProjectPop, setDuplicateProjectPop] = useState(false);
  const [duplicateProjectNextPop, setDuplicateProjectNextPop] = useState(false);

  const [projectCPopOpenByIndex, setProjectCPopOpenByIndex] = useState("");
  const [projectCPopOpenByIndex2, setProjectCPopOpenByIndex2] = useState("");

  const [participantPop, setParticipantPop] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectID, setProjectID] = useState("");

  const authState = useAppSelector((state) => state.auth);
  const [projectData, setProjectData] = useState([]);
  console.log("authState Workspaces", authState?.userProfile);

  const workspacesList = authState?.userProfile?.workspaces;

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const projectList = ["Outdoor Journey", "Financial Awareness"];

  const [deleteProjectPop, setDeleteProjectPop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [projectsList, setProjectsList] = useState([]);
  const [reloadList, setReloadList] = useState(false);
  const [editProjectPop, setEditProjectPop] = useState(false);
  const [editProjectNextPop, setEditProjectNextPop] = useState(false);
  const [image, setImage] = useState(null);
  const allDropRef = useRef(null);
  const workspaceState = useAppSelector((state) => state.workspace);
  const [projectDropVal, setProjectDropVal] = useState("");

  const handleClickOpen12 = (proId) => {
    setDuplicateProjectPop(true);
    getProject(proId);
  };

  const handleEditPop = (proId) => {
    setEditProjectPop(true);
    getProject(proId);
  };

  console.log("login user type=>", authState);
  //   Pagenation
  useEffect(() => {
    printItemsByState(projectData, currentPage);
  }, [currentPage]);

  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 1,
    totalPages: 0,
    totalItems: 0,
  });

  const defaultFilters = {
    search: "",
    sortBy: "created_at",
    sortOrder: -1,
    status: "",
    workspace_id: workspaceState?.selectedWorkspace?._id,
  };

  const [filterState, setFilterState] = useState({ ...defaultFilters });

  useEffect(() => {
    getProjects(1);
  }, []);

  useEffect(() => {
    if (reloadList) {
      getProjects(1);
      setReloadList(false);
    }
  }, [reloadList]);

  const getProjects = async (curPage, filters = filterState) => {
    setLoading(true);
    let url = `/researchers/projects?page=${curPage}`;
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        url += `&${key}=${filters[key]}`;
      }
    }

    const res = await axios.get(url);
    setLoading(false);
    if (res.status) {
      setProjectsList(res.data.data);
      // setting state for reportsPublication
      setPaginationData({
        currentPage: res.data.pagination.currentPage,
        itemsPerPage: res.data.pagination.itemsPerPage,
        totalPages: res.data.pagination.totalPages,
        totalItems: res.data.pagination.totalItems,
      });
    }
  };

  const handleFilterChange = (changedFilter) => {
    let newFilters = {
      ...filterState,
      ...changedFilter,
    };
    console.log(newFilters);
    setFilterState(newFilters);
    getProjects(1, newFilters);
  };

  const onPageChange = (pageNum) => {
    getProjects(pageNum, filterState);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const initState = {
    name: "",
    project_address: "",
    extrenal_project_id: "",
    image: "",
    language: "",
    country: "",
    timezone: "",
    status: "pending",
    start_date: "",
    duplicate_items: [],
    workspace_id: workspaceState?.selectedWorkspace?._id,
  };
  const [projectState, setProjectState] = useState(initState);

  const deleteProject = async (project) => {
    setProjectName(project.name);
    setProjectID(project._id);
    setProjectCPopOpenByIndex(null);
    setDeleteProjectPop(true);
  };

  const getProject = async (projectID) => {
    if (projectID !== "") {
      let url = `/researchers/projects?_id=${projectID}`;
      const res = await axios.get(url);
      if (res.status) {
        if (res.data.data.length > 0) {
          const project = res.data.data[0];
          let setProject = {};
          for (const key in initState) {
            setProject[key] = project[key];
          }

          if (project.workspaces.length > 0) {
            const users =
              project?.workspaces?.flatMap((workspace) =>
                workspace?.assigned_users_data
                  ?.filter((user) => user.is_participant)
                  .map((user) => user._id)
              ) || [];

            setProject.duplicate_items = users;
          }
          setProjectState({ ...projectState, ...setProject });
          setProjectID(projectID);
          setImage(viewImage(project.image));
        }
      }
    }
  };
  const recentProjectCard = (img, text) => {
    return (
      <Box className="recentProjectcard">
        <img src={img} />
        <img className="recentPOption" src={VDot} />
        <Typography className="recentProjectText">{text}</Typography>
        <Box className="blackDor"></Box>
      </Box>
    );
  };

  const handleWorkspaceChange = (event) => {
    const selectedValue = event.target.value === "" ? "" : event.target.value;
    console.log("Drop Selected Workspace=>",selectedValue);
    setUserWorkspace(authState?.userProfile,selectedValue, USER_TYPE_WS_ADMIN, dispatch);
  };

  useEffect(() => {
    if (workspaceState?.selectedProject != null) {
      setProjectDropVal(workspaceState?.selectedProject?._id);
    }
  }, [workspaceState]);
  
  const changeSelectedProject = (projects) => {
    const project = projectsList.find((el) => el._id == projects._id);
    dispatch(setSelectedProject(project));
  };

  let getActionBtns = () => {
    return (
      <>
        {
          (authState?.wsUserType == USER_TYPE_RESEARCHER ||
            authState?.userType == USER_TYPE_SUPERADMIN) && (
            <Box className="AppNavBtnBox">
              {/* <Box sx={{ width: "30%", minWidth: "196px" }}>
              <AllDrop2
                drop={allDrop2}
                setDrop={setAllDrop2}
                dropVal={allDrop2Val}
                serDropVal={setAllDrop2Val}
              />
            </Box> */}
              <AppAddBtn
                buttonText="Add Project"
                onClick={() => setAddProjectPop(true)}
              />
            </Box>
          )
          // ) : (
          //   <Box
          //     sx={{
          //       display: "flex",
          //       flexDirection: "row",
          //       alignItems: "center",
          //       position: "relative",
          //     }}
          //   >
          //     <AppBtn buttonText="Workspace Administration" />
          //     <Box
          //       onClick={() => setAdddProject(!addProject)}
          //       className="workspaceItemOption"
          //     >
          //       <img src={horizontalDot} />

          //       <Box
          //         sx={{ display: addProject ? "flex" : "none" }}
          //         className="workspaceItemNavPop"
          //       >
          //         <Box
          //           onClick={() => setAddProjectPop(true)}
          //           className="statusPopItem"
          //         >
          //           <Typography className="GsettingIcon">Add Project</Typography>
          //         </Box>
          //       </Box>
          //     </Box>
          //   </Box>
          // )
        }
      </>
    );
  };

  const getChildren = () => {
    return (
      <>
        <Box className="researchersMainBox pageContainer">
          {projectList.length <= 0 ? (
            <Box className="workspaceItemBox">
              <AddProjectPop
                setAddProjectPop={setAddProjectPop}
                addProjectPop={addProjectPop}
                VisuallyHiddenInput={VisuallyHiddenInput}
                fullScreen={fullScreen}
                setaddProjectNextPop={setaddProjectNextPop}
                setProjectState={setProjectState}
                projectState={projectState}
                image={image}
                setImage={setImage}
              />
              <Box sx={{ width: "20%", minWidth: "100px" }}>
                {" "}
                <AllDrop3
                  allDrop3={allDrop3}
                  setAllDrop3={setAllDrop3}
                  allDrop3Val={allDrop3Val}
                  setAllDrop3Val={setAllDrop3Val}
                />
              </Box>
              <NoProjectBgTemplate setAddProjectPop={setAddProjectPop} />
            </Box>
          ) : (
            <Box className="workspaceItemBox">
              <AddProjectPop
                setAddProjectPop={setAddProjectPop}
                addProjectPop={addProjectPop}
                VisuallyHiddenInput={VisuallyHiddenInput}
                fullScreen={fullScreen}
                setaddProjectNextPop={setaddProjectNextPop}
                setProjectState={setProjectState}
                projectState={projectState}
                image={image}
                setImage={setImage}
              />
              <AddProjectNextPop
                addProjectNextPop={addProjectNextPop}
                setaddProjectNextPop={setaddProjectNextPop}
                setAddProjectPop={setAddProjectPop}
                fullScreen={fullScreen}
                setReloadList={setReloadList}
                reloadList={reloadList}
                setProjectState={setProjectState}
                projectState={projectState}
                setImage={setImage}
              />

              <DuplicateProjectPop
                setDuplicateProjectPop={setDuplicateProjectPop}
                duplicateProjectPop={duplicateProjectPop}
                VisuallyHiddenInput={VisuallyHiddenInput}
                fullScreen={fullScreen}
                setDuplicateProjectNextPop={setDuplicateProjectNextPop}
                setProjectState={setProjectState}
                projectState={projectState}
                image={image}
                setImage={setImage}
              />

              <DuplicateProjectNextPop
                duplicateProjectNextPop={duplicateProjectNextPop}
                setDuplicateProjectNextPop={setDuplicateProjectNextPop}
                setDuplicateProjectPop={setDuplicateProjectPop}
                fullScreen={fullScreen}
                setReloadList={setReloadList}
                reloadList={reloadList}
                setProjectState={setProjectState}
                projectState={projectState}
                image={image}
                setImage={setImage}
              />

              <EditProjectPop
                setEditProjectPop={setEditProjectPop}
                editProjectPop={editProjectPop}
                VisuallyHiddenInput={VisuallyHiddenInput}
                fullScreen={fullScreen}
                setEditProjectNextPop={setEditProjectNextPop}
                setProjectState={setProjectState}
                projectState={projectState}
                image={image}
                setImage={setImage}
              />

              <EditProjectNextPop
                editProjectNextPop={editProjectNextPop}
                setEditProjectNextPop={setEditProjectNextPop}
                setEditProjectPop={setEditProjectPop}
                fullScreen={fullScreen}
                setReloadList={setReloadList}
                reloadList={reloadList}
                setProjectState={setProjectState}
                projectState={projectState}
                image={image}
                setImage={setImage}
                setProjectID={setProjectID}
                projectID={projectID}
              />

              <WorkspaceAddPagePop
                allDrop2Val={allDrop2Val}
                setAllDrop2Val={setAllDrop2Val}
              />

              <ParticipantPop
                participantPop={participantPop}
                setParticipantPop={setParticipantPop}
                setProjectID={setProjectID}
                projectID={projectID}
              />

              <DeleteProjectPop
                deleteProjectPop={deleteProjectPop}
                setDeleteProjectPop={setDeleteProjectPop}
                setReloadList={setReloadList}
                setProjectName={setProjectName}
                projectName={projectName}
                setProjectID={setProjectID}
                projectID={projectID}
              />

              <Box sx={{ width: "30%", minWidth: "100px" }}>
                {/* <AllDrop3
                  allDrop3={allDrop3}
                  setAllDrop3={setAllDrop3}
                  allDrop3Val={allDrop3Val}
                  setAllDrop3Val={setAllDrop3Val}
                /> */}
                <FormControl sx={{ width: "100%" }}>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  className="statusBox MuiBox-root css-0"
                  value={
                    workspaceState?.selectedWorkspace?._id ||
                    "Select Workspace"
                  }
                  onChange={handleWorkspaceChange}
                  name="workspace_name"
                >
                  {workspacesList.length > 0 &&
                    workspacesList.map((el2, i) => (
                      <MenuItem value={el2._id} key={i}>
                        {el2.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              </Box>
              <Box className="recentProjectBox">
                <Box className="recentProjectListBox">
                  {projectsList &&
                    projectsList.map(
                      (el, i) =>
                        i <= 6 &&
                        recentProjectCard(viewImage(el?.image), el.name)
                    )}
                </Box>
              </Box>

              <Box className="AllProjectBox">
                <Typography className="pageHeaderText">All Projects</Typography>
                <Box className="projectNav">
                  <Box className="projectNav1 projectNav1V1">
                    <Box mr={2} sx={{ width: "75%" }}>
                      <SearchBar handleFilterChange={handleFilterChange} />
                    </Box>
                    <Box sx={{ width: "20%", minWidth: "132px" }}>
                      <AllDrop
                        ref={allDropRef}
                        allDrop={allDrop}
                        setAllDrop={setAllDrop}
                        allDropVal={allDropVal}
                        setAllDropVal={setAllDropVal}
                        handleFilterChange={handleFilterChange}
                      />
                    </Box>
                  </Box>

                  <Box sx={{ justifyContent: "end" }} className="projectNav1">
                    <Box className="projectNavIndicat">
                      {inProgressIcon}
                      <Typography ml={1}>In Progress</Typography>
                    </Box>
                    <Box ml={2} className="projectNavIndicat">
                      {completedIcon}
                      <Typography ml={1}>Completed</Typography>
                    </Box>
                    <Box ml={2} className="projectNavIndicat">
                      {pendingIcon}
                      <Typography ml={1}>Pending</Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className="workListBox">
                  <Box className="haderRow" minWidth={"1270px"}>
                    <Box className="Tabelsel navFsel" minWidth={"290px"}>
                      <Typography>Project</Typography>
                    </Box>
                    <Box className="Tabelsel activitesSel" minWidth={"230px"}>
                      <Typography ml={2}>Activities</Typography>
                    </Box>
                    <Box
                      className="Tabelsel participantsSel"
                      minWidth={"230px"}
                    >
                      <Typography ml={1}>Participants</Typography>
                    </Box>
                    <Box className="Tabelsel" minWidth={"230px"}>
                      <Typography ml={1}>Start Date</Typography>
                    </Box>
                    <Box className="Tabelsel" minWidth={"230px"}>
                      <Typography>Project State</Typography>
                    </Box>
                  </Box>

                  {projectsList &&
                    projectsList?.map((el, index) => (
                      <Box key={index} className="TabelRow" minWidth={"1270px"}>
                        <Box
                          className="Tabelsel navFsel PNavTable"
                          minWidth={"290px"}
                          
                        >
                        <Box id="dot2" className="dot" 
                            onClick={() => {
                              if (projectCPopOpenByIndex === index) {
                                setProjectCPopOpenByIndex(null);
                              } else {
                                setProjectCPopOpenByIndex(index);
                              }
                            }}
                          >
                            {
                              <Box
                                sx={{
                                  display:
                                    projectCPopOpenByIndex === index
                                      ? "flex"
                                      : "none",
                                }}
                                className="optionPop"
                              >
                                {(authState?.wsUserType ==
                                  USER_TYPE_RESEARCHER ||
                                  authState?.wsUserType ==
                                    USER_TYPE_COLLABORATOR ||
                                  authState?.userType ==
                                    USER_TYPE_SUPERADMIN) && (
                                  <Box
                                    className="optionPopItem"
                                    onClick={() => handleEditPop(el._id)}
                                  >
                                    {settingIconBlack}
                                    <Typography>Project Settings</Typography>
                                  </Box>
                                )}
                                {(authState?.wsUserType ==
                                  USER_TYPE_RESEARCHER ||
                                  authState?.userType ==
                                    USER_TYPE_SUPERADMIN) && (
                                  <Box
                                    className="optionPopItem"
                                    onClick={() => handleClickOpen12(el._id)}
                                  >
                                    {duplicatehBlack}
                                    <Typography>Duplicate</Typography>
                                  </Box>
                                )}
                                <Box
                                  className="optionPopItem"
                                  onClick={() => {
                                    setProjectCPopOpenByIndex(null);
                                    setParticipantPop(true);
                                    setProjectID(el._id);
                                  }}
                                >
                                  {participantshBlack}
                                  <Typography>Participants</Typography>
                                </Box>
                                {(authState?.wsUserType ==
                                  USER_TYPE_RESEARCHER ||
                                  authState?.wsUserType ==
                                    USER_TYPE_COLLABORATOR ||
                                  authState?.userType ==
                                    USER_TYPE_SUPERADMIN) && (
                                  <Box
                                    className="optionPopItem"
                                    onClick={() => {
                                      deleteProject(el);
                                    }}
                                  >
                                    {trashBlack}
                                    <Typography>Delete Project</Typography>
                                  </Box>
                                )}
                              </Box>
                            }
                          </Box>
                          <Box className="tabelAvatar">
                            {el?.image == "undefined" || el?.image == null ? (
                                <img src={DummyPlaceholderProject} />
                            ) : (
                                <img src={viewImage(el?.image)} />
                            )}
                          </Box>
                          {/* <Box className="tabelAvatar">
                            <img src={viewImage(el?.image)} />
                          </Box> */}
                            <Typography onClick={() => {
                                changeSelectedProject(el);
                            }} className="cursorPointer">{el.name}</Typography>
                        </Box>

                        <Box
                          className="Tabelsel activitesSel TabelselItemCenter"
                          minWidth={"230px"}
                        >
                          <Box
                            className="pColorBox"
                            sx={{
                              background: "rgba(254, 224, 208, 0.50)",
                              color: "#FB6514",
                            }}
                          >
                            <p>4</p>
                          </Box>
                          <Box
                            className="pColorBox"
                            sx={{
                              background: "rgba(208, 241, 225, 0.50)",
                              color: "#12B76A",
                            }}
                          >
                            <p>2</p>
                          </Box>
                          <Box
                            className="pColorBox"
                            sx={{
                              background: "rgba(254, 224, 208, 0.50)",
                              color: "#FB6514",
                            }}
                          >
                            <p>1</p>
                          </Box>
                        </Box>

                        <Box
                          className="Tabelsel participantsRow participantsSel TabelselItemCenter"
                          minWidth={"230px"}
                        >
                          <img style={{ width: "20px" }} src={userIcon} />
                          <p style={{ margin: "0 10px" }}>
                            {el?.workspaces.length > 0 &&
                              el?.workspaces[0]?.userCounts?.participant}
                          </p>
                          <img style={{ margin: "0 10px" }} src={clockIcon} />
                          <p>2</p>
                        </Box>
                        <Box
                          className="Tabelsel TabelselItemCenter"
                          minWidth={"230px"}
                        >
                          <Typography>
                            {formatDate(el.start_date, "MMM-YYYY")}
                          </Typography>
                        </Box>
                        <Box
                          className="Tabelsel pState TabelselItemCenter"
                          minWidth={"230px"}
                        >
                          <Typography>{el.status}</Typography>
                        </Box>
                      </Box>
                    ))}
                </Box>
                <Pagination
                  paginationData={paginationData}
                  onPageChange={onPageChange}
                />
              </Box>
            </Box>
          )}
        </Box>
      </>
    );
  };

  return renderUserLayout(
    authState?.userType,
    getActionBtns(),
    getChildren(),
    "main"
  );
}
