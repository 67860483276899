import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import iIcon from "../../../Assets/Images/IIcon.png";

export default function ParticipantScreeningQuota() {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const lineBar = 73;
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#4F33D1",
    },
  }));

  return (
    <>
      <Box className="iBox">
        <img src={iIcon} />
        <Typography>{t("participantScreening.noteTexts2")}</Typography>
      </Box>

      <Box className="projectRadioBox">
        <Box className="projectRadioBoxSection">
          <Box className="ProjectRadioInput customLabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography className="emailSettingLabel">
              {t("participantScreening.checkBoxText3")}
            </Typography>
          </Box>
          <Box className="enforceInputBox internalInputBox">
            <input value="25" className="phoneInput" />
            <p>{t("participantScreening.participants")}</p>
          </Box>
          <Box className="ProjectRadioInput customLabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography className="emailSettingLabel">
              {t("participantScreening.checkBoxText4")}
            </Typography>
          </Box>
          <Box className="enforceInputBox internalInputBox">
            <Typography>{t("participantScreening.lighterText")}</Typography>
          </Box>
        </Box>
      </Box>

      <Box className="lineBar">
        <Typography>{lineBar}%</Typography>
        <BorderLinearProgress variant="determinate" value={lineBar} />
      </Box>

      <Typography>{t("participantScreening.totalApplicants")}</Typography>
    </>
  );
}
