import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SideMenu from "../../../Components/Collaborator/SideMenu";
import {
  AppBtn,
  AppBtnText,
  AppBtnOutLine,
} from "../../../Components/AppTools/AppButton";

import {
  totalList,
  sideHaumIcon,
  ImportDetailsList,
} from "../../../Assets/Data";

import {
  Box,
  Typography,
  Dialog,
  Grid,
  TextField,
  InputAdornment,
  FormLabel,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";

import MenuItem from "@mui/material/MenuItem";
import FolderIcon from "@mui/icons-material/Folder";
import Select from "@mui/material/Select";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import FormControl from "@mui/material/FormControl";
import { useTheme } from "@mui/material/styles";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import TableRowsIcon from "@mui/icons-material/TableRows";

import backArrow from "../../../Assets/Images/noun-back.png";
import groupsIcon from "../../../Assets/Images/groupsIcon.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";

import iIcon from "../../../Assets/Images/IIcon.png";

export default function ProjectParticipantBulkImport({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
}) {
  const Navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [redio1, setRedio1] = useState(false);
  const [redio2, setRedio2] = useState(false);
  const [redio3, setRedio3] = useState(false);
  const [redio4, setRedio4] = useState(false);

  const [BulkImportFileUpload, setBulkImportFileUpload] = useState(false);
  const [templateCheck, setTemplateCheck] = useState(false);
  const [templateCheck1, setTemplateCheck1] = useState(false);
  const [templateCheck2, setTemplateCheck2] = useState(false);
  const [templateCheck3, setTemplateCheck3] = useState(false);
  const [templateCheck4, setTemplateCheck4] = useState(false);
  const [templateCheck5, setTemplateCheck5] = useState(false);
  const [templateCheck6, setTemplateCheck6] = useState(false);
  const [selectparticipants, setselectparticipants] = React.useState(0);

  const [selectstandard11, setselectstandard11] = React.useState(10);
  const [selectemail11, setselectemail11] = React.useState(10);
  const [selectstandard12, setselectstandard12] = React.useState(10);
  const [selectemail12, setselectemail12] = React.useState(10);
  const [selectstandard13, setselectstandard13] = React.useState(10);
  const [selectemail13, setselectemail13] = React.useState(10);
  const [selectstandard14, setselectstandard14] = React.useState(10);
  const [selectemail14, setselectemail14] = React.useState(10);
  const [selectnumber, setselectnumber] = React.useState(10);

  const [BulkImportContinue, setBulkImportContinue] = useState(false);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const theme = useTheme();
  const [groupDrop, setGroupDrop] = useState(false);

  const previousPageHandler = () => {
    Navigate("/collaborator/project/directory_participants");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [selectprojects, setselectprojects] = React.useState(0);

  const handleChange2 = (event, SelectChangeEvent) => {
    setselectprojects(event.target.value);
  };

  const handleChange3 = (event, SelectChangeEvent) => {
    setselectparticipants(event.target.value);
  };

  const handleChange11a = (event, SelectChangeEvent) => {
    setselectstandard11(event.target.value);
  };

  const handleChange11b = (event, SelectChangeEvent) => {
    setselectemail11(event.target.value);
  };

  const handleChange12a = (event, SelectChangeEvent) => {
    setselectstandard12(event.target.value);
  };

  const handleChange12b = (event, SelectChangeEvent) => {
    setselectemail12(event.target.value);
  };

  const handleChange13a = (event, SelectChangeEvent) => {
    setselectstandard13(event.target.value);
  };

  const handleChange13b = (event, SelectChangeEvent) => {
    setselectemail13(event.target.value);
  };

  const handleChange14a = (event, SelectChangeEvent) => {
    setselectstandard14(event.target.value);
  };

  const handleChange14b = (event, SelectChangeEvent) => {
    setselectemail14(event.target.value);
  };

  const handleChange15 = (event, SelectChangeEvent) => {
    setselectnumber(event.target.value);
  };

  const handleSelectAll = () => {
    setRedio1(true);
    setRedio2(true);
    setRedio3(true);
    setRedio4(true);
  };
  const handleDeSelectAll = () => {
    setRedio1(false);
    setRedio2(false);
    setRedio3(false);
    setRedio4(false);
  };

  const chackBoxRoom = () => {
    return (
      <Box className="checkBoxItem">
        <Box>
          <input className="custom-checkbox" type="checkBox" />
          <Typography>Group 0</Typography>
        </Box>
        <Box>
          <input className="custom-checkbox" type="checkBox" />
          <Typography>Group 0</Typography>
        </Box>
        <Box>
          <input className="custom-checkbox" type="checkBox" />
          <Typography>Group 0</Typography>
        </Box>
      </Box>
    );
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Box className="ResearchersContainer">
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
          </Box>
          <Box className="researchersMainBox pageContainer">
            {!BulkImportFileUpload ? (
              <>
                <Box className="BulkImportSection">
                  <Box
                    onClick={() => previousPageHandler()}
                    className="openBtn"
                    style={{ "margin-bottom": "10px" }}
                  >
                    <img src={backArrow} />
                    <Typography className="neonBackText">
                      {t("commonTxt.backBtn")}
                    </Typography>
                  </Box>
                  <Typography variant="h4" gutterBottom>
                    {t("ProjectParticipantBulkImport.heading")}
                  </Typography>

                  <Box className="iBox">
                    <img src={iIcon} />
                    <Typography>
                      {t("ProjectParticipantBulkImport.importantText")}
                    </Typography>
                  </Box>

                  <Box
                    className="FormFiled"
                    sx={{
                      marginTop: "20px",
                      marginBottom: "20px",
                      maxWidth: "300px",
                    }}
                  >
                    <TextField
                      fullWidth
                      id="uploadfile"
                      title="Upload an excel file"
                      variant="outlined"
                      size="small"
                      type="file"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <FolderIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box className="workPupBtnBox">
                    <AppBtn
                      onClick={() => setBulkImportFileUpload(true)}
                      buttonText="Select File"
                    />
                    <AppBtnText
                      onClick={handleClickOpen}
                      buttonText="Create Template"
                    />
                  </Box>
                </Box>
              </>
            ) : (
              <Box
                className="BulkImportFileUploadSection"
                sx={{ display: BulkImportContinue ? "none" : "block" }}
              >
                <Box className="BulkImportSection">
                  <Typography variant="h4" gutterBottom>
                    {t("ProjectParticipantBulkImport.fileUpload")}
                  </Typography>

                  <Box className="iBox">
                    <img src={iIcon} />
                    <Typography>
                      {t("ProjectParticipantBulkImport.selectFileText")}
                    </Typography>
                  </Box>
                  <Box
                    className="FormFiled"
                    sx={{
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <Typography>
                      <span className="bulkImportFileNameText">
                        {t("ProjectParticipantBulkImport.uploadedFileName")}
                      </span>{" "}
                      {t("ProjectParticipantBulkImport.fileUploaded")}
                    </Typography>
                  </Box>
                  <Box className="workPupBtnBox">
                    <AppBtnOutLine
                      onClick={() => setBulkImportFileUpload(false)}
                      buttonText="Change"
                    />
                    <AppBtnText
                      onClick={handleClickOpen}
                      buttonText="Create Template"
                    />
                  </Box>
                </Box>
                <Box className="BulkImportFormSection">
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item sm={8} sx={{ alignItems: "center" }}>
                      <Typography variant="h4">File Upload</Typography>
                    </Grid>
                    <Grid item sm={4}>
                      <List className="listColumnsRows">
                        <ListItem
                          sx={{
                            width: "auto",
                            padding: "0px",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                          }}
                        >
                          <ListItemButton
                            disablePadding
                            sx={{
                              padding: "0px",
                              paddingLeft: "5px",
                              paddingRight: "5px",
                            }}
                          >
                            <ListItemIcon sx={{ minWidth: "36px" }}>
                              <ViewColumnIcon />
                            </ListItemIcon>
                            <ListItemText primary="20 Columns" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem
                          sx={{
                            width: "auto",
                            padding: "0px",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                          }}
                        >
                          <ListItemButton
                            disablePadding
                            sx={{
                              padding: "0px",
                              paddingLeft: "5px",
                              paddingRight: "5px",
                            }}
                          >
                            <ListItemIcon sx={{ minWidth: "36px" }}>
                              <TableRowsIcon />
                            </ListItemIcon>
                            <ListItemText primary="15 Rows" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>

                  <Box className="iBox">
                    <img src={iIcon} />
                    <Typography>
                      {t("ProjectParticipantBulkImport.selectFileText1")}
                    </Typography>
                  </Box>
                  <Box className="FormLable">
                    <FormLabel className="label">
                      {t("ProjectParticipantBulkImport.email")}
                    </FormLabel>
                    <Typography className="data">
                      <a href="#">
                        {t("ProjectParticipantBulkImport.sampleData")}
                      </a>{" "}
                      <span>15</span>
                    </Typography>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item sm={3}>
                      <FormControl sx={{ width: "100%" }} size="small">
                        {/* <InputLabel id="demo-select-small-label">Standard</InputLabel> */}
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={selectstandard11}
                          onChange={handleChange11a}
                        >
                          <MenuItem value={10}>
                            {t("ProjectParticipantBulkImport.emailDropOption1")}
                          </MenuItem>
                          <MenuItem value={20}>
                            {t("ProjectParticipantBulkImport.emailDropOption2")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl sx={{ width: "100%" }} size="small">
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={selectemail11}
                          onChange={handleChange11b}
                        >
                          <MenuItem value={10}>
                            {t("ProjectParticipantBulkImport.addressDrop1")}
                          </MenuItem>
                          <MenuItem value={20}>
                            {t("ProjectParticipantBulkImport.addressDrop2")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Box className="FormLable">
                    <FormLabel className="label">
                      {t("ProjectParticipantBulkImport.inputLabel1")}
                    </FormLabel>
                    <Typography className="data">
                      <a href="#">
                        {t("ProjectParticipantBulkImport.sampleData")}
                      </a>{" "}
                      <span>15</span>
                    </Typography>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item sm={3}>
                      <FormControl sx={{ width: "100%" }} size="small">
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={selectstandard12}
                          onChange={handleChange12a}
                        >
                          <MenuItem value={10}>
                            {t("ProjectParticipantBulkImport.addressDrop1")}
                          </MenuItem>
                          <MenuItem value={20}>
                            {t("ProjectParticipantBulkImport.addressDrop2")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl sx={{ width: "100%" }} size="small">
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={selectemail12}
                          onChange={handleChange12b}
                        >
                          <MenuItem value={10}>
                            {t("ProjectParticipantBulkImport.emailDropOption1")}
                          </MenuItem>
                          <MenuItem value={20}>
                            {t("ProjectParticipantBulkImport.emailDropOption2")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Box className="FormLable">
                    <FormLabel className="label">
                      {t("ProjectParticipantBulkImport.inputLabel2")}
                    </FormLabel>
                    <Typography className="data">
                      <a href="#">
                        {t("ProjectParticipantBulkImport.sampleData")}
                      </a>{" "}
                      <span>15</span>
                    </Typography>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item sm={3}>
                      <FormControl sx={{ width: "100%" }} size="small">
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={selectstandard13}
                          onChange={handleChange13a}
                        >
                          <MenuItem value={10}>
                            {t("ProjectParticipantBulkImport.emailDropOption1")}
                          </MenuItem>
                          <MenuItem value={20}>
                            {t("ProjectParticipantBulkImport.emailDropOption2")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl sx={{ width: "100%" }} size="small">
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={selectemail13}
                          onChange={handleChange13b}
                        >
                          <MenuItem value={10}>
                            {t("ProjectParticipantBulkImport.addressDrop1")}
                          </MenuItem>
                          <MenuItem value={20}>
                            {t("ProjectParticipantBulkImport.addressDrop2")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Box className="FormLable">
                    <FormLabel className="label">
                      {t("ProjectParticipantBulkImport.income")}
                    </FormLabel>
                    <Typography className="data">
                      <a href="#">
                        {t("ProjectParticipantBulkImport.sampleData")}
                      </a>{" "}
                      <span>15</span>
                    </Typography>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item sm={3}>
                      <FormControl sx={{ width: "100%" }} size="small">
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={selectstandard14}
                          onChange={handleChange14a}
                        >
                          <MenuItem value={10}>
                            {t("ProjectParticipantBulkImport.emailDropOption1")}
                          </MenuItem>
                          <MenuItem value={20}>
                            {t("ProjectParticipantBulkImport.emailDropOption2")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl sx={{ width: "100%" }} size="small">
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={selectemail14}
                          onChange={handleChange14b}
                        >
                          <MenuItem value={10}>
                            {t("ProjectParticipantBulkImport.addressDrop1")}
                          </MenuItem>
                          <MenuItem value={20}>
                            {t("ProjectParticipantBulkImport.addressDrop2")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sm={3}>
                      <FormControl sx={{ width: "100%" }} size="small">
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={selectnumber}
                          onChange={handleChange15}
                        >
                          <MenuItem value={10}>
                            {t("ProjectParticipantBulkImport.number01")}
                          </MenuItem>
                          <MenuItem value={20}>
                            {t("ProjectParticipantBulkImport.number02")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                <Box className="workPupBtnBox">
                  <AppBtn
                    onClick={() => setBulkImportContinue(true)}
                    buttonText={t("commonTxt.continueBtn")}
                  />
                </Box>
              </Box>
            )}

            <Box
              className="BulkImportContinue"
              sx={{ display: BulkImportContinue ? "block" : "none" }}
            >
              <Box className="BulkImportFileValidated">
                <Box
                  onClick={() => {
                    setBulkImportFileUpload(true);
                    setBulkImportContinue(false);
                  }}
                  className="openBtn"
                >
                  <img src={backArrow} />
                  <Typography className="neonBackText">
                    {t("commonTxt.backBtn")}
                  </Typography>
                </Box>

                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ marginTop: "10px", marginBottom: "20px" }}
                >
                  {t("ProjectParticipantBulkImport.blkImportFileValid")}
                </Typography>

                <Box className="iBox">
                  <img src={iIcon} />
                  <Typography>
                    {" "}
                    {t("ProjectParticipantBulkImport.strtImprtBtn")}
                  </Typography>
                </Box>

                <List className="listTotal">
                  {totalList &&
                    totalList.map((el, index) => (
                      <ListItem>
                        {el.heading} <span>{el.total}</span>
                      </ListItem>
                    ))}
                </List>

                {ImportDetailsList &&
                  ImportDetailsList.map((el, index) => (
                    <Box className="ImportDetails">
                      <Typography variant="h5">
                        {el.heading}{" "}
                        <KeyboardArrowRightIcon className="arrow" />{" "}
                        {el.headingvalue}{" "}
                      </Typography>
                      <Typography>{el.customfield}</Typography>
                      <Typography>{el.newfield}</Typography>
                      <List className="list">
                        <ListItem className="check">{el.successvalue}</ListItem>
                        <ListItem className="uncheck">{el.crossvalue}</ListItem>
                      </List>
                    </Box>
                  ))}
              </Box>
              <Box className="ImportSettings">
                <Typography variant="h4" gutterBottom>
                  {t("ProjectParticipantBulkImport.importSetting")}
                </Typography>
                <Box className="FormLable">
                  <FormLabel className="label">
                    {t("ProjectParticipantBulkImport.accountSettings")}
                  </FormLabel>
                </Box>
                <Grid container spacing={2}>
                  <Grid item sm={5}>
                    <Box className="alpOverCheck">
                      <input
                        style={{ marginRight: "16px" }}
                        className="custom-checkbox"
                        type="checkBox"
                      />
                      <Typography>
                        {t("ProjectParticipantBulkImport.passwordChangedLabel")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={5}>
                    <Box className="alpOverCheck">
                      <input
                        style={{ marginRight: "16px" }}
                        className="custom-checkbox"
                        type="checkBox"
                      />
                      <Typography>
                        {t("ProjectParticipantBulkImport.passwordChangedLabel")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Box mt={4} className="iBox">
                  <img src={iIcon} />
                  <Typography>
                    {t("ProjectParticipantBulkImport.assignProjectOpt")}
                  </Typography>
                </Box>

                <Box className="FormLable">
                  <FormLabel className="label">
                    {t("ProjectParticipantBulkImport.assignStudy")}
                  </FormLabel>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <Typography gutterBottom>
                    <a href="#" onClick={handleSelectAll}>
                      {t("ProjectParticipantBulkImport.selectAll")}
                    </a>
                  </Typography>
                  <Typography gutterBottom onClick={handleDeSelectAll}>
                    <a href="#">
                      {t("ProjectParticipantBulkImport.deSelectAll")}
                    </a>
                  </Typography>
                </Box>
                <Grid container>
                  <Grid item sm={5}>
                    <Box
                      mb={2}
                      className="alpOverCheck"
                      onClick={() => setRedio1(!redio1)}
                    >
                      <input
                        style={{ marginRight: "16px" }}
                        className="custom-checkbox"
                        type="checkBox"
                        checked={redio1 ? true : false}
                      />
                      <Typography>
                        {t("ProjectParticipantBulkImport.Outdoorjourney")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={5}>
                    <Box
                      mb={2}
                      className="alpOverCheck"
                      onClick={() => setRedio2(!redio2)}
                    >
                      <input
                        style={{ marginRight: "16px" }}
                        className="custom-checkbox"
                        type="checkBox"
                        checked={redio2 ? true : false}
                      />
                      <Typography>
                        {t("ProjectParticipantBulkImport.financialAwareness")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={5}>
                    <Box
                      className="alpOverCheck"
                      onClick={() => setRedio3(!redio3)}
                    >
                      <input
                        style={{ marginRight: "16px" }}
                        className="custom-checkbox"
                        type="checkBox"
                        checked={redio3 ? true : false}
                      />
                      <Typography>
                        {t("ProjectParticipantBulkImport.eatingHabits")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={5}>
                    <Box
                      className="alpOverCheck"
                      onClick={() => setRedio4(!redio4)}
                    >
                      <input
                        style={{ marginRight: "16px" }}
                        className="custom-checkbox"
                        type="checkBox"
                        checked={redio4 ? true : false}
                      />
                      <Typography>
                        {t("ProjectParticipantBulkImport.sportPsychology")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box className="workPupBtnBox">
                <AppBtn buttonText="Start Import" />
              </Box>
            </Box>

            <Dialog
              className="DialogSection"
              fullScreen={fullScreen}
              maxWidth={1080}
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">
                {t("ProjectParticipantBulkImport.createTemplate")}
              </DialogTitle>
              <Box className="AddFormPanel">
                <Box className="FormFiled">
                  <FormLabel className="label">
                    {t("ProjectParticipantBulkImport.selectFields")}
                  </FormLabel>

                  <List className="CheckboxList01">
                    <ListItem disablePadding>
                      <Box
                        className="alpOverCheck"
                        onClick={() => setTemplateCheck(!templateCheck)}
                      >
                        <input
                          style={{ marginRight: "16px" }}
                          className="custom-checkbox"
                          type="checkBox"
                          checked={templateCheck ? true : false}
                        />
                        <Typography>
                          {t("ProjectParticipantBulkImport.email")}
                        </Typography>
                      </Box>
                    </ListItem>
                    <ListItem disablePadding>
                      <Box
                        className="alpOverCheck"
                        onClick={() => setTemplateCheck1(!templateCheck1)}
                      >
                        <input
                          style={{ marginRight: "16px" }}
                          className="custom-checkbox"
                          type="checkBox"
                          checked={templateCheck1 ? true : false}
                        />
                        <Typography>
                          {t("ProjectParticipantBulkImport.externalID")}
                        </Typography>
                      </Box>
                    </ListItem>
                    <ListItem disablePadding>
                      <Box
                        className="alpOverCheck"
                        onClick={() => setTemplateCheck2(!templateCheck2)}
                      >
                        <input
                          style={{ marginRight: "16px" }}
                          className="custom-checkbox"
                          type="checkBox"
                          checked={templateCheck2 ? true : false}
                        />
                        <Typography>
                          {t("ProjectParticipantBulkImport.inputLabel1")}
                        </Typography>
                      </Box>
                    </ListItem>
                    <ListItem disablePadding>
                      <Box
                        className="alpOverCheck"
                        onClick={() => setTemplateCheck3(!templateCheck3)}
                      >
                        <input
                          style={{ marginRight: "16px" }}
                          className="custom-checkbox"
                          type="checkBox"
                          checked={templateCheck3 ? true : false}
                        />
                        <Typography>
                          {t("ProjectParticipantBulkImport.inputLabel2")}
                        </Typography>
                      </Box>
                    </ListItem>
                    <ListItem disablePadding>
                      <Box
                        className="alpOverCheck"
                        onClick={() => setTemplateCheck4(!templateCheck4)}
                      >
                        <input
                          style={{ marginRight: "16px" }}
                          className="custom-checkbox"
                          type="checkBox"
                          checked={templateCheck4 ? true : false}
                        />
                        <Typography>
                          {t("ProjectParticipantBulkImport.userName")}
                        </Typography>
                      </Box>
                    </ListItem>
                    <ListItem disablePadding>
                      <Box
                        className="alpOverCheck"
                        onClick={() => setTemplateCheck5(!templateCheck5)}
                      >
                        <input
                          style={{ marginRight: "16px" }}
                          className="custom-checkbox"
                          type="checkBox"
                          checked={templateCheck5 ? true : false}
                        />
                        <Typography>
                          {t("ProjectParticipantBulkImport.password")}
                        </Typography>
                      </Box>
                    </ListItem>
                    <ListItem disablePadding>
                      <Box
                        className="alpOverCheck"
                        onClick={() => setTemplateCheck6(!templateCheck6)}
                      >
                        <input
                          style={{ marginRight: "16px" }}
                          className="custom-checkbox"
                          type="checkBox"
                          checked={templateCheck6 ? true : false}
                        />
                        <Typography>
                          {t("ProjectParticipantBulkImport.state")}
                        </Typography>
                      </Box>
                    </ListItem>
                  </List>
                </Box>
                <Divider />

                <Box className="searchGroupSection">
                  <Box
                    pl={4.5}
                    className="inputAppDrop"
                    onClick={() => setGroupDrop(!groupDrop)}
                  >
                    <img className="groupsIcon" src={groupsIcon} />
                    <Typography>
                      {t("ProjectParticipantBulkImport.searchGroup")}
                    </Typography>
                    <img
                      style={{ rotate: groupDrop ? "180deg" : "0deg" }}
                      src={dropArrow}
                    />
                  </Box>
                  <Box
                    sx={{ height: groupDrop ? "auto" : "0px" }}
                    className="checkMain_box"
                  >
                    <Box className="ckeckBoxRoom">
                      {chackBoxRoom()}
                      {chackBoxRoom()}
                      {chackBoxRoom()}
                    </Box>
                    <Box className="propertyPagination">
                      <Box>
                        <p>1</p>
                      </Box>
                      <Box>
                        <p>2</p>
                      </Box>
                      <Box>
                        <p>3</p>
                      </Box>
                      <Box>
                        <p>4</p>
                      </Box>
                      <Box>
                        <p>...</p>
                      </Box>
                      <Box>
                        <p>8</p>
                      </Box>
                      <Box>
                        <p>9</p>
                      </Box>
                      <Box>
                        <p>10</p>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box className="FormFiled"></Box>
                <Box className="FormFiled">
                  <FormLabel className="label">
                    {t("ProjectParticipantBulkImport.projectDropTitle")}
                  </FormLabel>
                  <FormControl
                    sx={{ width: "100%", marginTop: "10px" }}
                    size="small"
                  >
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={selectprojects}
                      onChange={handleChange2}
                    >
                      <MenuItem value={0}>
                        {t("ProjectParticipantBulkImport.projectdropOption")}
                      </MenuItem>
                      <MenuItem value={10}>
                        {t("ProjectParticipantBulkImport.projectdropOption1")}
                      </MenuItem>
                      <MenuItem value={20}>
                        {t("ProjectParticipantBulkImport.projectdropOption2")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box className="FormFiled">
                  <FormLabel className="label">
                    {t(
                      "ProjectParticipantBulkImport.participantProfilingLabel"
                    )}
                  </FormLabel>
                  <FormControl
                    sx={{ width: "100%", marginTop: "10px" }}
                    size="small"
                  >
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={selectparticipants}
                      onChange={handleChange3}
                    >
                      <MenuItem value={0}>
                        {t(
                          "ProjectParticipantBulkImport.participantProfilingDropLabel"
                        )}
                      </MenuItem>
                      <MenuItem value={10}>
                        {t(
                          "ProjectParticipantBulkImport.participantProOption1"
                        )}
                      </MenuItem>
                      <MenuItem value={20}>
                        {t(
                          "ProjectParticipantBulkImport.participantProOption2"
                        )}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <DialogActions>
                <Box className="workPupBtnBox">
                  <AppBtnOutLine
                    autoFocus
                    onClick={handleClose}
                    buttonText={t("commonTxt.cancelBtn")}
                  />
                  <AppBtn buttonText={t("commonTxt.applyBtn")} />
                </Box>
              </DialogActions>
            </Dialog>
          </Box>

          {/* Create Template */}
        </Box>
      </Box>
    </>
  );
}
