import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import "./style.css";


import SideMenu from "../../../Components/User/SideMenu";
import { sideHaumIcon } from "../../../Assets/Data";
import { renderUserLayout } from '../../../lib/helpers';
import { useAppSelector } from '../../../lib/store/store';
import axios from '../../../lib/axios';
import { TOAST_CONFIG } from '../../../env';
import { toast } from 'react-toastify';
import { researcherAction } from '../../../Services/researcherActions';
import ViewAgreementPop from '../../../Components/WSAdministration/ViewAgreementPop';
import Pagination from '../../../Components/AppTools/Pagination';

export default function Agreement({ slideMenu, setSlideMenu, workspaceDrop, setWorkSpaceDrop }) {

  const authState = useAppSelector((state) => state.auth);
  const [agreementList, setAgreementList] = useState([]);
  const [viewAgreementId, setViewAgreementId] = useState(null);
  const [viewAgreementPop, setViewAgreementPop] = useState(false);
  useEffect(() => {
    fetchAgreement();

  }, []);

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 25,
    totalPages: 0,
    totalItems: 0,
  });

  const onPageChange = (pageNum) => {
    fetchAgreement(pageNum);
  };

  const fetchAgreement = async (page = 1) => {
    try {
      const response = await axios.get(`super-admin/admin/agreements?page=${page}&limit=10&is_settings=true`);
      const agreements = response?.data?.data;
      fetchAcceptance(agreements)
      setPaginationData({
        currentPage: response.data.pagination.currentPage,
        itemsPerPage: response.data.pagination.itemsPerPage,
        totalPages: response.data.pagination.totalPages,
        totalItems: response.data.pagination.totalItems,
      });
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  }

  const fetchAcceptance = async (agreements) => {
    try {
      const response = await axios.get(`super-admin/admin/agreement-acceptance?user_id=${authState.userProfile._id}`);
      const agreementAcc = response?.data?.data;
      if (agreements.length > 0) {
        agreements.forEach((el, i) => {
          const findData = agreementAcc.find((agree) => agree.agreement_id === el._id);
          el.acceptance = findData ? findData.acceptance : false
        });
      }
      setAgreementList(agreements);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  }

  const updateAcceptance = async (agreedID, status, i) => {
    try {
      const payload = {
        agreement_id: agreedID,
        acceptance: status
      }
      let response = await researcherAction('/super-admin/admin/agreement-acceptance', payload, authState.authToken);
      if (response.success) {
        fetchAgreement();
        toast.success(response.message, TOAST_CONFIG);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  }

  const getChildren = () => {
    return (
      <Box className="researchersMainBox pageContainer">
        <Box className="EmailNContainer">
          <Typography className='emailNotificationHeaderText'>Agreements</Typography>
          <Typography className='agreementSubHeadText'>
            This section displays all the consents that you have agreed to so far.
          </Typography>

          {agreementList.length > 0 && agreementList.map((el, i) => (el.enable_page === "in-line" ? <Box className="workspaceListBox" key={i}>
            <Typography className="pageHeaderText">
              {el.name}
            </Typography>
            <Typography mt={2} className="agreNText">
              {el.page_content}
            </Typography>
            <Box my={1}></Box>
            <Box
              mt={2}
              mb={2}
              sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
            >
              <input
                style={{ marginRight: "10px" }}
                className="custom-checkbox"
                type="checkBox"
                onClick={(e) => { updateAcceptance(el._id, e.target.checked, i) }}
                checked={el?.acceptance}
              />
              <Typography>
                {el.opt_statement}
                {el.enable_page === "blank" && <><span className="agreementLinkLabel" onClick={() => { setViewAgreementId(el._id); setViewAgreementPop(true) }}>View</span></>}
              </Typography>
            </Box>
          </Box> : <>
            <ViewAgreementPop
              viewAgreementPop={viewAgreementPop}
              setViewAgreementPop={setViewAgreementPop}
              agreementId={viewAgreementId}
            />
            <Box
              mt={1}
              mb={1}
              sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
              className="workspaceListBox"
            >
              <input
                style={{ marginRight: "10px" }}
                className="custom-checkbox"
                type="checkBox"
                onClick={(e) => { updateAcceptance(el._id, e.target.checked, i) }}
                checked={el?.acceptance}
              />
              <Typography>
                {el.opt_statement}
                {el.enable_page === "blank" && <><span className="agreementLinkLabel" onClick={() => { setViewAgreementId(el._id); setViewAgreementPop(true) }}>View</span></>}
              </Typography>
            </Box></>))}
          <Box className="pagination">
            <Pagination
              paginationData={paginationData}
              onPageChange={onPageChange}
            />
          </Box>
        </Box>
      </Box>
    )
  }
  return renderUserLayout(authState?.userType, null, getChildren(), 'accountsetting')
}
