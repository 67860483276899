import React, { useEffect, useState } from "react";
// import "./style.css";
import { Box, Typography } from "@mui/material";
import Clogo from "../../../Assets/Images/Frame 243811.png";
import signature from "../../../Assets/Images/signature.png";

//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { useAppSelector } from "../../../lib/store/store";
import { participantEmailSent } from "../../../Services/adminActions";
import { TOAST_CONFIG } from "../../../lib/constants";
import { toast } from "react-toastify";
// import { toast } from "react-toastify";
// import { TOAST_CONFIG } from "../../../lib/constants";

const initialEmailVal = {
  users: [],
  subject: "",
  message: "",
};

export default function PreviewEmailParticipantPopup({
  previewMailPop,
  setPreviewMailPop,
  setSendEmailPopup,
  emailval,
  setReloadList,
  setEmailval
}) {
  const [loading, setLoading] = useState(false)
    let authState = useAppSelector(state => state.auth);
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setPreviewMailPop(false);
    }
  };

  const handelPreviousPop = () => {
    setPreviewMailPop(false);
    setSendEmailPopup(true);
  };

  useEffect(() => {
    if (previewMailPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [previewMailPop]);


  let handleParticipantEmailSent = async() => {
    setLoading(true);
    try {
      let mailRes = await participantEmailSent(
        emailval,
        authState.authToken,
      )
      if(mailRes.success){
        toast.success(mailRes.message, TOAST_CONFIG);
        setEmailval(initialEmailVal);
        setPreviewMailPop(false);
        setSendEmailPopup(false);
        setReloadList(true);
      }else {
        toast.error(mailRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong! Please try again', TOAST_CONFIG);
      setLoading(false);
    }
  }

  return (
    <>
      <Box
        onClick={handelPopClose}
        id="popBackdrop"
        sx={{ display: previewMailPop ? "flex" : "none" }}
        className="workspaceDeletePopContainer"
      >
        <Box className="workspaceDeleteBox workspaceDeletePopTextAlign">
          <Box className="deleteTextBox">
            <img style={{ width: "212px" }} src={Clogo} />
          </Box>
          <Typography className="deletePopHeader">
            {emailval?.subject}
          </Typography>

          <Box className="deleteTextBox">
            <Typography>
                {emailval?.message}
            </Typography>
          </Box>
          {/* <Box className="deleteTextBox">
            <img style={{ width: "212px" }} src={signature} />
          </Box> */}
          <Box className="deleteBtnBox">
            <AppBtnOutLine
              onClick={() => handelPreviousPop()}
              buttonText="Back"
            />
            <AppBtn buttonText={loading ? 'Loading...' : 'Send Now'} onClick={handleParticipantEmailSent}/>
          </Box>
        </Box>
      </Box>
    </>
  );
}
