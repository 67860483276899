import React, { useState } from 'react'
import { Box, Typography, CardHeader, Avatar, CardContent } from '@mui/material'

import { groupBtnTab, participantPreview } from "../../../Assets/Data";

export default function ProjectDirectActiveParticipant({ selectedParticipant, setSelectedParticipant }) {

  return (
    <>   
        <Box className="ParticipantsPanel">
            {participantPreview &&
                participantPreview?.map((el, index) => (
                    <Box onClick={() => setSelectedParticipant([index])} className={selectedParticipant.includes(index) ? "ParticipantBox participantProfCard participantProfCardActive" : "ParticipantBox participantProfCard"} >
                        <CardHeader
                            avatar={
                                <Avatar
                                    alt=""
                                    src={el.img}
                                    sx={{ width: 44, height: 44 }}
                                />
                            }
                            title={el.user}
                        />
                        <CardContent>
                            <Typography>965216452</Typography>
                            <Typography>{el.time}</Typography>
                        </CardContent>
                    </Box>
                ))}
        </Box>
    </>
  )
}
