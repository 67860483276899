import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";

//images
import groupsIcon from "../../../Assets/Images/groupsIcon.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";
import chackBox from "../../../Assets/Images/Checkbox.png";
import check from "../../../Assets/Images/check.png";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import redTrash from "../../../Assets/Images/redTrash.png"
import img1 from "../../../Assets/Images/New folder/img1.png"
import img2 from "../../../Assets/Images/New folder/img2.png"
import img3 from "../../../Assets/Images/New folder/img3.png"
import img4 from "../../../Assets/Images/New folder/img4.png"
import img5 from "../../../Assets/Images/New folder/img5.png"
import img6 from "../../../Assets/Images/New folder/img6.png"
import img7 from "../../../Assets/Images/New folder/img7.png"
import img8 from "../../../Assets/Images/New folder/img8.png"


//data


//component
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { AntSwitch } from "../../AppTools/AppTool";



export default function ParticipantGroupPop({ setParticipantGroupPop, participantGroupPop }) {
  const [nav, setNav] = useState(0)
  const [groupDrop2, setGroupDrop2] = useState(false);
  const [redio, setRedio] = useState(0);
  const [chackVal, setChackVal] = useState([]);
  const [groupDrop3, setGroupDrop3] = useState(false);
  const [companyStatusDrop, setCompanyStatusDrop] = useState(false);
  const [compantStatusDropVal, setCompantStatusDropVal] = useState("");
  const [workspaceStatusdrop, setWorkspaceStatusdrop] = useState(false);
  const [workspaceStatusDropVal, setworkspaceStatusDropVal] = useState("");
  const [attributeCount, setAttributeCount] = useState(1)
  const [privew, setPrivew] = useState(false)



  const chackBoxRoom = () => {
    return (
      <Box className="checkBoxItem">
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            checked={redio === 0 ? true : false}
          />
          <Typography>Group 0</Typography>
        </Box>
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            checked={redio === 0 ? true : false}
          />
          <Typography>Group 0</Typography>
        </Box>
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            checked={redio === 0 ? true : false}
          />
          <Typography>Group 0</Typography>
        </Box>
      </Box>
    );
  };

  const pushData = (string) => {
    setChackVal((prev) => [...prev, string]);
  };
  const deleteAllCheckData = () => setChackVal([]);
  const selectAllCheckData = () => {
    setChackVal(["Camping", "Fishing", "Kayaking", "Cycling"]);
  };

  const Attribute = ({ count }) => {
    return (
      <Box className="AttributeBox">
        <Box className="delete_btn" onClick={handelDeleteAttribute}>
          <Box>
            <img src={redTrash} />
          </Box>
          <Box>
            <p>Delete</p>
          </Box>
        </Box>


        <Box className="companiesTopBox">
          <Box className="companyInputBoxWlabel">
            <Typography mb={1} className="inputLabel"> Attribute: {count * 1 + 1}</Typography>

            <Box
              className="inputAppDrop"
              onClick={() => setCompanyStatusDrop(!companyStatusDrop)}
            >
              <Typography>
                {compantStatusDropVal || "Age"}
              </Typography>
              <img
                className="PartDropIcon"
                style={{ rotate: companyStatusDrop ? "180deg" : "0deg" }}
                src={DownArrowIcon}
              />
              {/* pop */}
              <Box
                sx={{ height: companyStatusDrop ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setCompantStatusDropVal("Item-A")}
                  className="statusPopItem"
                >
                  <Typography>Item-A</Typography>
                  <Typography>Hello</Typography>
                </Box>
                <Box
                  onClick={() => setCompantStatusDropVal("Item-B")}
                  className="statusPopItem"
                >
                  <Typography>Item-B</Typography>
                </Box>
                <Box
                  onClick={() => setCompantStatusDropVal("Item-C")}
                  className="statusPopItem"
                >
                  <Typography>Item-C</Typography>
                </Box>
                <Box
                  onClick={() => setCompantStatusDropVal("Item-D")}
                  className="statusPopItem"
                >
                  <Typography>Item-D</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* workspace status */}
          <Box className="companyInputBoxWlabel">
            <Typography mb={1} className="inputLabel"> Scale </Typography>

            <Box
              className="inputAppDrop"
              onClick={() => setWorkspaceStatusdrop(!workspaceStatusdrop)}
            >
              <Typography>
                {workspaceStatusDropVal || "Less than"}
              </Typography>
              <img
                className="PartDropIcon"
                style={{
                  rotate: workspaceStatusdrop ? "180deg" : "0deg",
                }}
                src={DownArrowIcon}
              />
              {/* pop */}
              <Box
                sx={{ height: workspaceStatusdrop ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setworkspaceStatusDropVal("Item-A")}
                  className="statusPopItem"
                >
                  <Typography>Item-A</Typography>
                </Box>
                <Box
                  onClick={() => setworkspaceStatusDropVal("Item-B")}
                  className="statusPopItem"
                >
                  <Typography>Item-B</Typography>
                </Box>
                <Box
                  onClick={() => setworkspaceStatusDropVal("Item-C")}
                  className="statusPopItem"
                >
                  <Typography>Item-C</Typography>
                </Box>
                <Box
                  onClick={() => setworkspaceStatusDropVal("Item-D")}
                  className="statusPopItem"
                >
                  <Typography>Item-D</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="addressBox">
          <Typography className="companyInputLabel">Value</Typography>
          <input
            type="text"
            className="addInput"
            placeholder="Value"
            value="40"
          />
        </Box>

      </Box>
    )
  }


  const renderComponents = (value) => {
    let components = [];
    for (let i = 0; i < value; i++) {
      components.push(<Attribute count={i} key={i} />);
    }
    return components;
  };


  const handelDeleteAttribute = () => {
    setAttributeCount(attributeCount - 1)
  }
  const handelAddAttribute = () => {
    setAttributeCount(attributeCount + 1)
  }


  const PreviewCard = ({ img, title, subTitle }) => {
    return (
      <Box className="priviewUserBox">
        <Box>
          <img src={img} />
        </Box>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "16px"
        }}>
          <span>{title}</span>
          <samp>{subTitle}</samp>
        </Box>
      </Box>
    )
  }

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setParticipantGroupPop(false);
    }
  };

  useEffect(() => {
    if (participantGroupPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [participantGroupPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: participantGroupPop ? "flex" : "none" }}
        className="companyPop participantGPop">
        <Box
          sx={{ display: participantGroupPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className="companyHeader">Sort Participants By Groups</Typography>
          <Box className="psNavBox">
            <Box onClick={() => setNav(0)} className={nav === 0 ? "psNavItemActive psNavItem" : "psNavItem"}>
              <Typography>Existing Groups</Typography>
            </Box>
            <Box onClick={() => setNav(1)} className={nav === 1 ? "psNavItemActive psNavItem" : "psNavItem"}>
              <Typography>New custom Groups</Typography>
            </Box>
          </Box>



          {/* ---------------Existing Groups--------------- */}
          <Box sx={{ display: nav === 0 ? "flex" : "none" }} className="pageSetMainBox">
            <Box className="airadioSwitchBox">
              <AntSwitch defaultUnchecked inputProps={{ "aria-label": "ant design" }} />
              <Typography className="switchText">Match only if all criteria apply</Typography>
            </Box>

            <Typography className="psNlabel">Standard Groups</Typography>
            <Box
              pl={4.5}
              className="inputAppDrop"
              onClick={() => setGroupDrop2(!groupDrop2)}
            >
              <img className="groupsIcon" src={groupsIcon} /><p></p>
              <Typography>Search participant group</Typography>
              <img
                className="PartDropIcon"
                style={{ rotate: groupDrop2 ? "180deg" : "0deg" }}
                src={dropArrow}
              />

              <Box sx={{ display: groupDrop2 ? "block" : "none" }} className="statusPop">
                <Box className="checkSelectorBox">
                  <span onClick={selectAllCheckData}>Select All</span >
                  <span onClick={deleteAllCheckData}>Deselect All</span>
                </Box>

                <Box className="statusPopItem"
                  onClick={() => {
                    if (chackVal.includes("Camping")) {
                      chackVal.filter(item => item !== "Camping")
                      return;
                    } else {
                      pushData("Camping")
                    }
                  }}

                >
                  <Box className="chackBox">
                    <img className="chackBoxImg" src={chackBox} />
                    <img style={{ display: chackVal.includes("Camping") ? "block" : "none" }} className="chack" src={check} />
                  </Box>
                  <p>Outdoor Activity: Camping</p>
                </Box>

                <Box className="statusPopItem"
                  onClick={() => {
                    if (chackVal.includes("Fishing")) {
                      chackVal.filter(item => item !== "Fishing")
                      return;
                    } else {
                      pushData("Fishing")
                    }
                  }}>
                  <Box className="chackBox">
                    <img className="chackBoxImg" src={chackBox} />
                    <img style={{ display: chackVal.includes("Fishing") ? "block" : "none" }} className="chack" src={check} />
                  </Box>
                  <p>Outdoor Activity: Fishing</p>
                </Box>

                <Box className="statusPopItem"
                  onClick={() => {
                    if (chackVal.includes("Kayaking")) {
                      chackVal.filter(item => item !== "Kayaking")
                      return;
                    } else {
                      pushData("Kayaking")
                    }
                  }}
                >
                  <Box className="chackBox">
                    <img className="chackBoxImg" src={chackBox} />
                    <img style={{ display: chackVal.includes("Kayaking") ? "block" : "none" }} className="chack" src={check} />
                  </Box>
                  <p>Outdoor Activity: Kayaking</p>
                </Box>

                <Box className="statusPopItem"
                  onClick={() => {
                    if (chackVal.includes("Cycling")) {
                      chackVal.filter(item => item !== "Cycling")
                      return;
                    } else {
                      pushData("Cycling")
                    }
                  }}

                >
                  <Box className="chackBox">
                    <img className="chackBoxImg" src={chackBox} />
                    <img style={{ display: chackVal.includes("Cycling") ? "block" : "none" }} className="chack" src={check} />
                  </Box>
                  <p>Outdoor Activity: Cycling</p>
                </Box>


              </Box>
            </Box>

            <Box className="searchGroupSection">
              <Typography className="psNlabel">Enhanced Groups</Typography>
              <Box
                pl={4.5}
                className="inputAppDrop"
                onClick={() => setGroupDrop3(!groupDrop3)}
              >
                <img className="groupsIcon" src={groupsIcon} />
                <Typography>Search participant group</Typography>
                <img
                  className="PartDropIcon"
                  style={{ rotate: groupDrop3 ? "180deg" : "0deg" }}
                  src={dropArrow}
                />
              </Box>
              <Box
                sx={{ height: groupDrop3 ? "auto" : "0px" }}
                className="checkMain_box"
              >
                <Box className="ckeckBoxRoom">
                  {chackBoxRoom()}
                  {chackBoxRoom()}
                  {chackBoxRoom()}
                </Box>
                <Box className="propertyPagination">
                  <Box>
                    <p>1</p>
                  </Box>
                  <Box>
                    <p>2</p>
                  </Box>
                  <Box>
                    <p>3</p>
                  </Box>
                  <Box>
                    <p>4</p>
                  </Box>
                  <Box>++
                    <p>...</p>
                  </Box>
                  <Box>
                    <p>8</p>
                  </Box>
                  <Box>
                    <p>9</p>
                  </Box>
                  <Box>
                    <p>10</p>
                  </Box>
                </Box>
              </Box>
            </Box>







          </Box>

          {/* -------------------custom Groups-------------------- */}
          <Box sx={{ display: nav === 1 ? "flex" : "none" }} className="pageSetMainBox">

            <Box className="airadioSwitchBox">
              <AntSwitch defaultUnchecked inputProps={{ "aria-label": "ant design" }} />
              <Typography className="switchText">Match only if all criteria apply</Typography>
            </Box>
            {/* Attribute */}
            {renderComponents(attributeCount)}
            <Typography className="addAttributeLink" onClick={handelAddAttribute}>Add Attribute</Typography>
            <Box className="cusGBtnBox">
              <Box className="groupParticipantPreview">
                {/* <PurplelBtn buttonText="Preview" onClick={() => setPrivew(!privew)} /> */}
                <Box className="groupParticipantPreviewBtn" onClick={() => setPrivew(!privew)}>
                  <Typography>Preview</Typography>
                </Box>
                <AppBtnOutLine buttonText="Save an Enhanced Group" />
              </Box>
              <AppBtnOutLine buttonText="Clear All" onClick={() => setAttributeCount(0)} />
            </Box>

            <Box sx={{ display: privew ? "flex" : "none" }} className="attPriviewBox">
              <Box className="attPriviewInnerBox">
                <PreviewCard img={img1} title="Phoenix Baker" subTitle="Phoenix_B" />
                <PreviewCard img={img2} title="Lana Steiner" subTitle="Lana_S" />
                <PreviewCard img={img3} title="Kate Morrison" subTitle="Kate_M" />
                <PreviewCard img={img4} title="Orlando Diggs" subTitle="O_Diggs" />
              </Box>
              <Box className="attPriviewInnerBox">
                <PreviewCard img={img5} title="Candice Wu" subTitle="Candice_Wu" />
                <PreviewCard img={img6} title="Nataliya Craig" subTitle="Nataliya_C" />
                <PreviewCard img={img7} title="Demi Wilkinson" subTitle="D_Wilkinson" />
                <PreviewCard img={img8} title="Andi Lane" subTitle="Andi_Lane" />
              </Box>
            </Box>





          </Box>


          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={() => setParticipantGroupPop(false)} />
            <AppBtn buttonText="Apply" />
          </Box>

        </Box>
      </Box>
    </>
  );
}
