import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./style.css";

//images
import groupIcon from "../../../Assets/Images/groupsIcon.png";
import filterIcon from "../../../Assets/Images/filterIcon.png";
import idelDot from "../../../Assets/Images/idelDot.png";
import avatar1 from "../../../Assets/Images/inb1.png";
import avatar2 from "../../../Assets/Images/inb2.png";
import avatar3 from "../../../Assets/Images/inb3.png";
import AdminBG from "../../../Assets/Images/Add-files-cuate.png";
import exportIcon from "../../../Assets/Images/exportIcon.png";
import bannerImg from "../../../Assets/Images/expBanner.png";
import arrow from "../../../Assets/Images/arrow.png";
import eye from "../../../Assets/Images/eye.png";
import eyeOff from "../../../Assets/Images/eye-off.png";

//component
import { like, dislike, comment, tik } from "../../../Assets/Data";
import { SearchBar, Status } from "../../AppTools/AppTool";

//data
import { expressionData } from "../../../Assets/Data";
import ExpressionsComponent from "../ExpressionsComponent";

export default function Expressions() {
  const [nav, setNav] = useState(0);
  const [hl, setHl] = useState(false);
  const [hlVal, setHlVal] = useState("Highest to Lowest");
  const [currentPage, setCurrentPage] = useState(0);
  const [newArrayData, setNewArrayData] = useState([]);

  const hlItems = ["item1", "item2", "item3"];

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  useEffect(() => {
    printItemsByState(expressionData, currentPage);
  }, [currentPage]);

  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  const threadData = true;

  const CommentSection = ({
    img,
    title,
    subTitle1,
    subTitle2,
    date,
    summery,
  }) => {
    return (
      <Box className="commentSection">
        <Box className="avatarBox">
          <img src={img} />
          <img className="statusDot" src={idelDot} />
        </Box>

        <Box className="commentTextSection rmBPadding">
          <Box mb={5} className="taskBoxTopDate">
            <Typography className="userNameWspm">
              {title}
              <span>{subTitle1}</span>
              <samp>{subTitle2}</samp>
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <Typography className="taskHeaderText2">
                {t("commonTxt.viewBtn")}
              </Typography>
              <samp className="taskHeaderText3">{date}</samp>
            </Box>
          </Box>
          <Typography>{summery}</Typography>
          <Box mt={3} className="taskBoxBottomRow">
            <Box className="taskBoxBottomRowItem">
              {like}
              <Typography>{t("commonTxt.Like")}</Typography>
            </Box>
            <Box className="taskBoxBottomRowItem">
              {dislike}
              <Typography>{t("commonTxt.dislike")}</Typography>
            </Box>
            <Box className="taskBoxBottomRowItem">
              {comment}
              <Typography>{t("commonTxt.comment")}</Typography>
            </Box>
            <Box className="taskBoxBottomRowItem">
              {tik}
              <Typography>{t("commonTxt.review")}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {!threadData ? (
        <Box className="threadNoDataPage">
          <Box className="adminWorkSBG">
            <img src={AdminBG} />
          </Box>
          <Box className="adminBGTextBox">
            <Typography className="adminSubHeaderText">
              {t("recapExpression.expresssionText")}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box className="threadContainer workspaceListBox">
          <ExpressionsComponent />
        </Box>
      )}
    </>
  );
}
