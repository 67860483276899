import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from "react-i18next";
import "./style.css";

//Images
import exportIcon from "../../../Assets/Images/exportIcon.png"
import option2Line from "../../../Assets/Images/Option2LIcon.png"

import { AllSuperAdmin } from "../../../Assets/Data";
//components 

export default function ProjectCodeBook() {

    const { t } = useTranslation();

    const [checkboxes, setCheckboxes] = useState(
        []
    );

    const handleCheckAllChange = () => {
        const bookIds = AllSuperAdmin.map((item, index2) => {
            return index2
        })
        setCheckboxes(bookIds)
    };

    const handleUnCheckAllChange = () => {
        setCheckboxes([])
    }

    const handleCheckboxChange = (index) => {
        const updatedCheckboxes = [...checkboxes];
        updatedCheckboxes[index] = !updatedCheckboxes[index];
        setCheckboxes(updatedCheckboxes);
    };

    const trueValues = checkboxes?.filter(value => value === true);
    const isOnlyOneTrue = trueValues.length === 1;

    return (
        <>
            <Box className="researchersMainBox pageContainer">
                <Box className="integrationsContainer">
                    <Box className="projectCodeBookHead">
                        <Typography className='emailNotificationHeaderText'>{t("projectCodeBook.projectCodeBookHead")}</Typography>
                    </Box>
                    <Box className="codeBookTblLinks">
                        <Box className="codeBookSelectDe">
                            <Typography className='verifyLinkCodeBook' onClick={handleCheckAllChange} >{t("commonTxt.selectAll")}</Typography>
                            <Typography className='verifyLinkCodeBook' onClick={handleUnCheckAllChange} >{t("commonTxt.deSelectAll")}</Typography>
                        </Box>
                        <Box className="codeBookExport">
                            <img src={exportIcon} />
                            <Typography className='verifyLink'>{t("commonTxt.exportLink")} ({t("commonTxt.excelLink")})</Typography>
                        </Box>
                    </Box>

                    <Box className="adminkListBox">
                        <Box className="haderRow" minWidth={"1111px"}>
                            <Box className="Tabelsel TabelselItemStart" minWidth={"15%"}>
                                <Typography>{t("projectCodeBook.tblActions")}</Typography>
                            </Box>
                            <Box className="Tabelsel TabelselItemStart" minWidth={"15%"}>
                                <Typography>{t("projectCodeBook.tblUses")}</Typography>
                            </Box>
                            <Box className="Tabelsel TabelselItemStart" minWidth={"20%"} >
                                <Typography>{t("projectCodeBook.tblCodes")}</Typography>
                            </Box>
                            <Box className="Tabelsel TabelselItemStart" minWidth={"45%"} >
                                <Typography>{t("projectCodeBook.tblDescription")}</Typography>
                            </Box>
                        </Box>
                        {AllSuperAdmin &&
                            AllSuperAdmin?.map((el, index) => (
                                index > 0 && index < 5 ?
                                    (<Box className="TabelRow" minWidth={"1111px"}>
                                        <Box className=" TabCell navFCel navTable participantCheckboxColor codeBookActionIcons" minWidth={"15%"}>
                                            <img src={option2Line} />
                                            <input className="AllPTablechackBox" type="checkbox" checked={checkboxes[index]} onChange={() => handleCheckboxChange(index)} />
                                        </Box>

                                        <Box className="Tabelsel TabelselItemStart" minWidth={"15%"}>
                                            <samp className='codeBookUsersBtn'>4</samp>
                                        </Box>
                                        <Box className="Tabelsel TabelselItemStart" minWidth={"20%"}>
                                            <Typography>Empowerment</Typography>
                                        </Box>
                                        <Box
                                            className="Tabelsel tableICon TabelselItemStart"
                                            minWidth={"45%"}
                                        >
                                            <Typography>Verbatim that touches on the theme of “relaxation”</Typography>
                                        </Box>
                                    </Box>) : null
                            ))}
                    </Box>
                    <Box sx={{ display: isOnlyOneTrue ? "flex" : "none" }} className="workPupBtnBox">
                        <Box className="verifyBtn">
                            <Typography>{t("commonTxt.delete")}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
