import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import "./style.css";

import whitedropIcon from "../../../Assets/Images/Wchevron-down.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";
import exportIcon from "../../../Assets/Images/ExpoGroup.png";
import printIcon from "../../../Assets/Images/printIcon.png";

import { useTranslation } from "react-i18next";

//component
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { ApexChart } from "../../../Components/AppTools/PieChart";
import { Status } from "../../AppTools/AppTool";
import StackedBar from "../../../Components/AppTools/StackedBar";

//data
import { ParticipantData, reportDropVal } from "../../../Assets/Data";

export default function ProjectPointsPieChartReport({
  dataReportTabe,
  setDataReportTabe,
  projectSettingDrop,
  setProjectSettingDrop,
  projectSettingDropVal,
  setProjectSettingDropVal,
}) {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [identitiesDrop, setIdentitiesDrop] = useState(false);
  const [identitiesDropVal, setIdentitiesDropVal] = useState();

  const [rangeDrop2, setRangeDrop2] = useState(false);
  const [rangeDropVal2, setRangeDropVal2] = useState("All Time");

  const [contributionDrop2, setContributionDrop2] = useState(false);
  const [contributionDropVal2, setContributionDropVal2] =
    useState("All Actions");

  const [chartTypeDrop, setChartTypeDrop] = useState(false);
  const [chartTypeDropVal, setChartTypeDropVal] = useState("Pie Chart");

  const indentitiesData = [
    {
      name: "Full Name",
      value: "Full Name",
    },
    {
      name: "Username",
      value: "Username",
    },
    {
      name: "Unique ID",
      value: "Unique ID",
    },
  ];

  useEffect(() => {
    let selTab = searchParams.get("t");
    let selTab2 = searchParams.get("dr");
    if (selTab && selTab2 != null) {
      var query_string = selTab;
      query_string = query_string.replaceAll('_', ' ');
      query_string = query_string.replace(/(^\w|\s\w)/g, firstCharOfWord => firstCharOfWord.toUpperCase());
      console.log(query_string);
      if (selTab === "size_churn") {
        var query_string = "Size & Churn";
        setProjectSettingDropVal(query_string);
        setDataReportTabe(parseInt(selTab2));
      }
      else {
        setProjectSettingDropVal(query_string);
        setDataReportTabe(parseInt(selTab2));
      }
    }
    else if (selTab == null) {
      setDataReportTabe(parseInt(selTab2));
    }
  }, []);

  return (
    <>
      {/* -------------------------------------Point Pie Chart start----------------------------- */}
      <Box
        sx={{ display: projectSettingDropVal === "Points" ? "block" : "none" }}
        className="reportContainer integrationsContainer compositionSection"
      >
        <Box className="projectBtnBox">
          <Box className="inteBtndropBox">
            <Box
              className="projectManagementNavBtn"
              onClick={() => setProjectSettingDrop(!projectSettingDrop)}
            >
              <Typography className="DropBtnText">
                {projectSettingDropVal || "Project State"}
              </Typography>
              <img
                className="dropIcon"
                src={whitedropIcon}
                style={{
                  rotate: projectSettingDrop ? "180deg" : "0deg",
                  transition: "all 0.5s",
                  marginLeft: "10px",
                }}
              />
              <Box
                className="workspaceItemNavPop projectItemNavPop"
                sx={{ display: projectSettingDrop ? "flex" : "none" }}
              >
                {reportDropVal?.map((el, i) => (
                  <Box key={i}
                    onClick={() => {
                      setSearchParams({ dr: dataReportTabe, t: el.qstring });
                      setProjectSettingDropVal(el.title);
                    }}
                  >
                    <Typography>{el.title}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
        <Typography className="pageHeaderText">
          {t("projectPointReport.headerTxt")}
        </Typography>
        <Box className="participationReportDropSection">
          <Box className="inputBoxWlabel">
            <Typography>{t("projectPointReport.actionDrop")}</Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setContributionDrop2(!contributionDrop2)}
            >
              <Typography>
                {contributionDropVal2 ||
                  t("projectPointReport.actionDropPlaceholder")}
              </Typography>
              <img
                style={{ rotate: contributionDrop2 ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: contributionDrop2 ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setContributionDropVal2("All Actions")}
                  className="statusPopItem"
                >
                  <Typography>
                    {t("projectPointReport.actionTypeDropVal1")}
                  </Typography>
                </Box>
                <Box
                  onClick={() => setContributionDropVal2("Subscribed")}
                  className="statusPopItem"
                >
                  <Typography>
                    {t("projectPointReport.actionTypeDropVal2")}
                  </Typography>
                </Box>
                <Box
                  onClick={() => setContributionDropVal2("Unsubscribed")}
                  className="statusPopItem"
                >
                  <Typography>
                    {t("projectPointReport.actionTypeDropVal3")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="inputBoxWlabel">
            <Typography>
              {t("projectParticipantReport.dateRangeDrop")}
            </Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setRangeDrop2(!rangeDrop2)}
            >
              <Typography>
                {rangeDropVal2 ||
                  t("projectParticipantReport.dateRangeDropPlaceholder")}
              </Typography>
              <img
                style={{ rotate: rangeDrop2 ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: rangeDrop2 ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setRangeDropVal2("All Time")}
                  className="statusPopItem"
                >
                  <Typography>
                    {t("projectParticipantReport.dateRangeDropVal1")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box mt={5} className="redioGroupSection">
          <Typography className="emailNotificationHeaderText">
            {t("projectParticipantReport.subHeaderTxt2")}
          </Typography>

          <Box className="projectRadioBoxSection">
            <Box className="ProjectRadioInput">
              <input
                className="emailSettingInput emailSettingRadio"
                type="radio"
                name="projectState"
              />
              <Typography className="emailSettingLabel">
                {t("projectParticipantReport.participantAllParticipant")}
              </Typography>
            </Box>
            <Box className="ProjectRadioInput">
              <input
                className="emailSettingInput emailSettingRadio"
                type="radio"
                name="projectState"
              />
              <Typography className="emailSettingLabel">
                {t("projectParticipantReport.participantSelectGroup")}
              </Typography>
            </Box>
          </Box>

          <Box className="projectRadioBoxSection">
            <Box className="ProjectRadioInput">
              <input
                className="emailSettingInput emailSettingRadio"
                type="radio"
                name="projectState"
              />
              <Typography className="emailSettingLabel">
                {t("projectParticipantReport.participantSelectParticipant")}
              </Typography>
            </Box>
          </Box>

          <Box className="participantIdentities">
            <Typography>
              {t("projectParticipantReport.participantIdentity")}
            </Typography>
            <Status
              itemAry={indentitiesData}
              setStatusDrop={setIdentitiesDrop}
              statusDrop={identitiesDrop}
              setStatusDropVal={setIdentitiesDropVal}
              statusDropVal={identitiesDropVal}
              label={t(
                "projectParticipantReport.participantIdentityPlaceholder"
              )}
            />
          </Box>
        </Box>
        <Box className="workPupBtnBox">
          <AppBtnOutLine buttonText={t("commonTxt.resetBtn")} />
          <AppBtn buttonText={t("commonTxt.viewReportBtn")} />
        </Box>
      </Box>

      <Box
        sx={{ display: projectSettingDropVal === "Points" ? "block" : "none" }}
        mt={5}
        className="integrationsContainer"
      >
        <Box mb={3} className="tabelNav">
          <Box className="dropWheader">
            <Typography className="psSubLabel">
              {t("projectParticipantReport.headerTxt2")}
            </Typography>
            {/* Chat type drop */}
            <Box className="inputBoxWlabel projectPointChart">
              <Box
                className="inputAppDrop"
                onClick={() => setChartTypeDrop(!chartTypeDrop)}
              >
                <Typography>
                  {chartTypeDropVal ||
                    t("projectPointReport.chartTypeDropPlaceholder")}
                </Typography>
                <img
                  style={{ rotate: chartTypeDrop ? "180deg" : "0deg" }}
                  src={dropArrow}
                />
                {/* pop */}
                <Box
                  sx={{ height: chartTypeDrop ? "auto" : "0px" }}
                  className="statusPop"
                >
                  <Box
                    onClick={() => setChartTypeDropVal("Pie Chart")}
                    className="statusPopItem"
                  >
                    <Typography>
                      {t("projectPointReport.chartTypeDropVal1")}
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => setChartTypeDropVal("Legend")}
                    className="statusPopItem"
                  >
                    <Typography>
                      {t("projectPointReport.chartTypeDropVal2")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* chat type drop */}
          </Box>
          <Box className="repPrintBtnBox">
            <Box className="exporOpenBtn">
              <img src={printIcon} />
              <Typography className="verifyLink">{t("commonTxt.printLink")}</Typography>
            </Box>
            <Box className="exporOpenBtn">
              <img src={exportIcon} />
              <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
            </Box>
          </Box>
        </Box>
        {chartTypeDropVal === "Pie Chart" ? (
          <Box className="pieBox">
            <ApexChart />
          </Box>
        ) : chartTypeDropVal === "Legend" ? (
          <Box my={3} className="sideLineChart">
            <StackedBar />
          </Box>
        ) : null}
        <Box mt={5} mb={2} className="tabelNav">
          <Box className="dropWheader">
            <Typography className="emailNotificationHeaderText">
              {t("projectParticipantReport.subHeaderTxt2")} (5)
            </Typography>
          </Box>
          <Box className="repPrintBtnBox">
            <Box className="exporOpenBtn">
              <img src={exportIcon} />
              <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
            </Box>
          </Box>
        </Box>

        <Box mb={6} className="workListBox">
          <Box className="haderRow" minWidth={"1100px"}>
            <Box minWidth={"20%"} className="Tabelsel TabelStatesel">
              <Typography>{t("projectPointReport.tblUserName")}</Typography>
            </Box>
            <Box minWidth={"10%"} className="Tabelsel TabelStatesel">
              <Typography>{t("projectPointReport.tblResponse")}</Typography>
            </Box>
            <Box minWidth={"14%"} className="Tabelsel TabelStatesel">
              <Typography>
                {t("projectPointReport.tblActivityFeedback")}
              </Typography>
            </Box>
            <Box minWidth={"12%"} className="Tabelsel TabelStatesel">
              <Typography>{t("projectPointReport.tblConversation")}</Typography>
            </Box>
            <Box minWidth={"10%"} className="Tabelsel TabelStatesel">
              <Typography>{t("projectPointReport.tblMessage")}</Typography>
            </Box>
            <Box minWidth={"10%"} className="Tabelsel TabelStatesel">
              <Typography>{t("projectPointReport.tblGeneral")}</Typography>
            </Box>
            <Box minWidth={"12%"} className="Tabelsel TabelStatesel">
              <Typography>{t("projectPointReport.tblTotalPoint")}</Typography>
            </Box>
            <Box minWidth={"12%"} className="Tabelsel TabelStatesel">
              <Typography>{t("projectPointReport.tblLastAction")}</Typography>
            </Box>
          </Box>

          {ParticipantData?.map((el, i) => (
            <Box className="TabelRow" minWidth={"1100px"}>
              <Box minWidth={"20%"} className="Tabelsel TabelStatesel">
                <Box className="tabelAvatar">
                  <img src={el.img} />
                </Box>
                <Typography sx={{ cursor: "pointer" }}>
                  {el.UserName}
                  <br />
                  <span className="participantsTableText">Tera</span>
                </Typography>
              </Box>
              <Box minWidth={"10%"} className="Tabelsel TabelStatesel">
                <Typography>1770</Typography>
              </Box>
              <Box minWidth={"14%"} className="Tabelsel TabelStatesel">
                <Typography>41</Typography>
              </Box>
              <Box minWidth={"12%"} className="Tabelsel TabelStatesel">
                <Typography>41</Typography>
              </Box>
              <Box minWidth={"10%"} className="Tabelsel TabelStatesel">
                <Typography>56</Typography>
              </Box>
              <Box minWidth={"10%"} className="Tabelsel TabelStatesel">
                <Typography>1</Typography>
              </Box>
              <Box minWidth={"12%"} className="Tabelsel TabelStatesel">
                <Typography>1900</Typography>
              </Box>
              <Box minWidth={"12%"} className="Tabelsel TabelStatesel">
                <Typography>Aug 2, 2:19 PM</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      {/* -------------------------------------Point Pie Chart end----------------------------- */}
    </>
  );
}
