import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Grid, FormLabel, TextField, InputLabel } from "@mui/material";
import "./style.css";
import 'react-image-crop/dist/ReactCrop.css'

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import AvatarOne from "../../../Assets/Images/UserHomeAvatar.png";
import WhiteDownArrow from "../../../Assets/Images/Whitechevron-down.png";
import CropImage from "../../../Assets/Images/crop-image.png";
import { sideHaumIcon } from "../../../Assets/Data";

import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import DummyUser from "../../../Assets/Images/user-dummy.png";

//components
import SideMenu from "../../../Components/User/SideMenu";
import { AppBtn, AppBtnOutLine } from "../../../Components/AppTools/AppButton";
import EmailVerificationPop from "../../../Components/User/EmailVerificationPop";
// import WebcamComponent, { GoTop } from "../../../Components/AppTools/AppTool";
// import { genderList } from "../../../Assets/Data";
import { useAppSelector } from "../../../lib/store/store";
import { renderUserLayout, viewImage, dataURLToFile } from "../../../lib/helpers";
import { sendOtpToEmail, userProfile } from "../../../Services/auth";
import { getCities, getCountries, getStates } from "../../../Services/master";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop,
} from 'react-image-crop'
import { canvasPreview, useDebounceEffect, WebcamCapture} from '../../../Components/AppTools/AppTool'
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { updateLoggedInUserProfile, uploadUserDirectoryImage } from "../../../Services/adminActions";
import DeleteProfilePicPop from "../../../Components/Researchers/DeleteProfilePop";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}


export default function PersonalInformation({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
}) {
  const Navigate = useNavigate();
  const authState = useAppSelector((state) => state.auth);

  const [gender, setGender] = useState('');

  const [directMessageNavDrop, setDirectMessageNavDrop] = useState(false);
  const [directMessageNavDropVal, setDirectMessageNavDropVal] = useState("Upload Image");
  //19-03
  const [emailVerificationPop, setEmailVerificationPop] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [openWebCamModal, setOpenWebCamModal] = React.useState(false);
  const [userProfileData, setUserProfileData] = useState(null);
  const [countryData, setCountryData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [countryList, setCountryList] = useState(false);
  const [countryListVal, setCountryListVal] = useState("");
  const [stateList, setStateList] = useState(false);
  const [stateListVal, setStateListVal] = useState("");
  const [cityList, setCityList] = useState(false);
  const [cityListVal, setCityListVal] = useState("");
  const [countryId, setCountryId] = useState("");
  const [stateId, setStateId] = useState("");
  const [cityId, setCityId] = useState("");
  const [image, setImage] = useState(null);

  const [imgSrc, setImgSrc] = useState('')
  const [screenshortSrc, setScreenshortSrc] = useState('');
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null)
  const [file, setFile] = useState(null);
  const hiddenAnchorRef = useRef(null)
  const blobUrlRef = useRef('')
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState(16 / 9)
  const [deleteProfilePop, setDeleteProfilePop] = useState(false);
  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined)
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      setOpen(true);
      handleToggleAspectClick();
      reader.readAsDataURL(e.target.files[0])
      setFile(e.target.files[0])
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  async function generateFileforServer() {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!previewCanvas) {
      return updateImage(file);
    }
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist');
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    );
    const ctx = offscreen.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    );

    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    });

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
    }
    blobUrlRef.current = URL.createObjectURL(blob);
    updateImage(blob)
  }

  const updateImage = async (file) => {
    const formData = new FormData()
    formData.append('file', file, "profic_pic.png")
    try {
      let imageUploadRes = await uploadUserDirectoryImage(formData, authState.authToken);
      if (imageUploadRes.success) {
        setImgSrc(null);
        setOpen(false);
        setScreenshortSrc(null);
        setOpenWebCamModal(false)
        updateProfile({ profile_pic: imageUploadRes.data.upload_id });
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  };

  const setFormdata = (e) => {
    let newFormState = {
      ...userProfileData,
      [e.target.name]: e.target.value
    }
    setUserProfileData(newFormState)
  }

  const updateProfile = async (payload = {}, isPicture = true) => {
    try {
      let response = await updateLoggedInUserProfile(payload, authState.authToken);
      if (response.success) {
        if (isPicture)
          setEmailVerificationPop(false);
        fetchUserProfile();
        toast.success(response.message, TOAST_CONFIG);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined)
    } else {
      setAspect(16 / 9)

      if (imgRef.current) {
        const { width, height } = imgRef.current
        const newCrop = centerAspectCrop(width, height, 16 / 9)
        setCrop(newCrop);
        console.log("convertToPixelCrop(newCrop, width, height)", convertToPixelCrop(newCrop, width, height));
        setCompletedCrop(convertToPixelCrop(newCrop, width, height))
      }
    }
  }

  const handleClickOpen = () => {
    document.getElementById("fileInput").click();
    // setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseWebCam = () => {
    setOpenWebCamModal(false);
  };

  useEffect(()=> {
    fetchUserProfile();
    getAllCountryList();
  }, []);

  const fetchUserProfile = async () => {
    let token = authState.authToken
    try {
      const response = await userProfile(token);
      const profileData = response?.data?.data;

      setUserProfileData(profileData);
      setImage(profileData.profile_pic);
      setGender(profileData?.gender);

      if (profileData?.country?.length > 0) {
        setCountryId(profileData.country[0].id);
        getAllStatesList(profileData.country[0].id);
      }

      if (profileData?.state?.length > 0) {
        setStateId(profileData.state[0].id);
        getAllCitiesList(profileData.state[0].id, profileData.country[0].id);
      }

      if (profileData?.city?.length > 0) {
        setCityId(profileData.city[0].id);
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  }

  const genderListVal = [
    { value: 'male', title: 'Male' },
    { value: 'female', title: 'Female' },
    { value: 'other', title: 'Other' },
  ];

  const handleChange = (event) => {
    setGender(event.target.value);
  };

  const getAllCountryList = () => {
    getCountries().then((res) => {
      if (res.success && res.data.data.length > 0) {
        setCountryData(res.data.data.map((country) => ({ id: country.id, _id: country._id, name: country.name })));
      }
    });
  };

  const getAllStatesList = (country_id) => {
    getStates(country_id).then((res) => {
      if (res.success && res.data.data.length > 0) {
        setStatesData(res.data.data.map((state) => ({ id: state.id, _id: state._id, name: state.name, country_id: state.country_id })));
      }
    });
  };

  const getAllCitiesList = (state_id, country_id) => {
    getCities(country_id, state_id).then((res) => {
      if (res.success && res.data.data.length > 0) {
        setCitiesData(res.data.data.map((city) => ({ id: city.id, _id: city._id, name: city.name })));
      }
    });
  };

  const setDropdownId = (data) => {
    if (data) {
      return (Array.isArray(data) && data.length) > 0 ? data[0]._id : (data instanceof Object) ? data._id : data
    }
  }

  const handleCountryChange = (event) => {
    const selectedCountryId = event.target.value;
    setCountryId(selectedCountryId);
    setStatesData([]);
    setCitiesData([]);
    getAllStatesList(selectedCountryId);
  };

  const handleStateChange = (event) => {
    const selectedStateId = event.target.value;
    setStateId(selectedStateId);
    setCitiesData([]);
    getAllCitiesList(selectedStateId, countryId);
  };

  const handleCityChange = (event) => {
    setCityId(event.target.value);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "280px",
        overflowY: "auto",
      },
    },
  };

  const sendOtpApi = async () => {
    try {
      setLoading(true)
      let response = await sendOtpToEmail({ otp_type: "UPDATE_PROFILE" }, authState?.authToken);
      setLoading(false)
      if (response.success) {
        setEmailVerificationPop(true)
        toast.success(response.message)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  let updatedUserProfile = async () => {
    setLoading(true);

    try {
      const requestPayload = {
        name: userProfileData.name,
        email: userProfileData.email,
        phone: userProfileData.phone,
        gender: userProfileData.gender,
        address_line1: userProfileData.address_line1,
        country: setDropdownId(userProfileData.country),
        state: setDropdownId(userProfileData.state),
        city: setDropdownId(userProfileData.city),
        zipcode: userProfileData.zipcode,
        status: "active",
        otp: userProfileData?.otp
      };
      await updateProfile(requestPayload, false);
    } catch (err) {
      console.error("Error updating profile:", err);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    } finally {
      setLoading(false);
    }
  };



  const [showWebcam, setShowWebcam] = useState(false);

  const handleToggleWebcam = () => {
    setOpenWebCamModal(true);
    // setShowWebcam((prevShowWebcam) => !prevShowWebcam);
  };

  const uploadScreenshort = async () => {
    if (screenshortSrc) {
      const file = dataURLToFile(screenshortSrc, "profile_pic.png");
      updateImage(file);
    }
  }

  const getChildren = () => {
    return (
      <Box className="researchersMainBox pageContainer">
        <Box className="PersonalInformationSection">
          <EmailVerificationPop
            emailVerificationPop={emailVerificationPop}
            setEmailVerificationPop={setEmailVerificationPop}
            updatedUserProfile={updatedUserProfile}
            setUserProfileData={setUserProfileData}
            userProfileData={userProfileData}
          />
          <DeleteProfilePicPop
            setDeleteProfilePop={setDeleteProfilePop}
            deleteProfilePop={deleteProfilePop}
            fetchUserProfile={fetchUserProfile}
          // deleteProfilePic={deleteProfilePic}
          />
          <Grid container spacing={2} className="flexGrid">
            <Grid item md={7}>
              <Box className="PinfoPanel">
                <Box className="profileImage">
                  {/* <img src={AvatarOne} /> */}
                  {console.log(image)}
                  {!image || image === "undefined" ? (
                    <img src={DummyUser} alt="Dummy User" />
                  ) : (
                    <img src={viewImage(image)} alt="Profile" />
                  )}
                </Box>
                <Box className="profileContent">
                  <Typography variant="h3" gutterBottom>
                    {userProfileData?.name}
                  </Typography>
                  <Typography gutterBottom>
                    {userProfileData?.email}
                  </Typography>
                  <Typography>{userProfileData?.gender}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item md={5}>
              <Box className="btnoPanel">
                <Box
                  onClick={() =>
                    setDirectMessageNavDrop(!directMessageNavDrop)
                  }
                  className="directMessageNavBtn"
                >
                  <Typography sx={{ color: "#FFFFFF" }}>
                    {directMessageNavDropVal || "Upload Image"}
                  </Typography>
                  <img src={WhiteDownArrow} />
                  {/* pop */}
                  <Box
                    sx={{ display: directMessageNavDrop ? "flex" : "none" }}
                    className="workspaceItemNavPop"
                  >
                    {/* <WebcamCapture /> */}
                    <Box onClick={handleToggleWebcam}>
                      <Typography>Use Webcam</Typography>
                    </Box>
                    <Box onClick={handleClickOpen}>
                      <Typography>Upload from system</Typography>
                    </Box>
                  </Box>
                  <input
                    id="fileInput"
                    style={{ display: "none" }}
                    type="file"
                    // onChange={handleFileInputChange}
                    onChange={onSelectFile}
                  />
                </Box>

                <AppBtnOutLine buttonText="Delete" onClick={() => setDeleteProfilePop(true)} />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Dialog
          open={openWebCamModal}
          onClose={handleCloseWebCam}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className="textcenter">
            Edit how the selected image should appear
          </DialogTitle>
          <DialogContent>
            <WebcamCapture screenshortSrc={screenshortSrc} setScreenshortSrc={setScreenshortSrc} />
          </DialogContent>
          <DialogActions>
            <AppBtnOutLine
              className="halfwidthbtn"
              onClick={handleCloseWebCam}
              buttonText="Cancel"
            />
            <AppBtn
              className="halfwidthbtn"
              onClick={uploadScreenshort} // Uncommented and fixed the function name
              buttonText="Upload"
            />
          </DialogActions>
        </Dialog>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className="textcenter">
            Edit how the selected image should appear
          </DialogTitle>
          <DialogContent>
            {!!imgSrc && (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
                minHeight={100}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            )}
            {!!completedCrop && (
              <>
                <div>
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      display: 'none',
                      border: '1px solid black',
                      objectFit: 'contain',
                      width: completedCrop.width,
                      height: completedCrop.height,
                    }}
                  />
                </div>
              </>
            )}
            {/* <img src={CropImage} /> */}
          </DialogContent>
          <DialogActions>
            <AppBtnOutLine
              className="halfwidthbtn"
              onClick={handleClose}
              buttonText="Cancel"
            />
            <AppBtn
              className="halfwidthbtn"
              onClick={generateFileforServer}
              buttonText="upload"
            />
          </DialogActions>
        </Dialog>

        <Box className="PIFromSection">
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <FormLabel className="label">Full Name</FormLabel>
              <TextField
                fullWidth
                id="outlined-basic"
                placeholder="Enter full name"
                variant="outlined"
                size="small"
                name="name"
                value={userProfileData?.name}
                onChange={setFormdata}
              />
            </Grid>
            <Grid item sm={6}>
              <FormLabel className="label">Contact Number</FormLabel>
              <TextField
                fullWidth
                id="outlined-basic"
                placeholder="Enter contact number"
                variant="outlined"
                size="small"
                name="phone"
                value={userProfileData?.phone}
                onChange={setFormdata}
              // defaultValue={"+91 9175208513"}
              />
            </Grid>
            <Grid item sm={6}>
              <FormLabel className="label">Gender</FormLabel>
              <FormControl sx={{ width: "100%" }} size="small">
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={gender}
                  onChange={handleChange}
                >
                  {genderListVal &&
                    genderListVal?.map((el, index) => (
                      <MenuItem key={el.value} value={el.value}>
                        {el.title}
                      </MenuItem>
                    ))}
                  {/* <MenuItem value={20}>Female</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormLabel className="label">Email Address</FormLabel>
              <TextField
                fullWidth
                id="outlined-basic"
                placeholder="Enter email address"
                variant="outlined"
                size="small"
                name="email"
                value={userProfileData?.email}
                onChange={setFormdata}
              />
            </Grid>
            <Grid item sm={12}>
              <FormLabel className="label">Address</FormLabel>
              <TextField
                fullWidth
                id="outlined-basic"
                placeholder="Enter address"
                variant="outlined"
                size="small"
                name="address_line1"
                value={userProfileData?.address_line1}
                onChange={setFormdata}
              />
            </Grid>

            <Grid item sm={6}>
              <FormLabel className="label">Country</FormLabel>
              <FormControl sx={{ width: "100%" }} size="small">
                <Select
                  value={countryId}
                  onChange={handleCountryChange}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="">
                    Select country
                  </MenuItem>
                  {countryData.map((country) => (
                    <MenuItem key={country.id}
                      value={country.id}
                      onClick={() => setUserProfileData({ ...userProfileData, country: setDropdownId(country) })}
                    >
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={6}>
              <FormLabel className="label">State</FormLabel>
              <FormControl sx={{ width: "100%" }} size="small">
                <Select
                  value={stateId}
                  onChange={handleStateChange}
                  disabled={!countryId}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="">
                    Select state
                  </MenuItem>
                  {statesData.map((state) => (
                    <MenuItem key={state.id} value={state.id}
                      onClick={() => setUserProfileData({ ...userProfileData, state: setDropdownId(state) })}
                    >
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={6}>
              <FormLabel className="label">City</FormLabel>
              <FormControl sx={{ width: "100%" }} size="small">
                <Select
                  value={cityId}
                  onChange={handleCityChange}
                  disabled={!stateId}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="">
                    Select city
                  </MenuItem>
                  {citiesData.map((city) => (
                    <MenuItem key={city.id} value={city.id}
                      onClick={() => setUserProfileData({ ...userProfileData, city: setDropdownId(city) })}
                    >
                      {city.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={6}>
              <FormLabel className="label">ZIP Code</FormLabel>
              <TextField
                fullWidth
                id="outlined-basic"
                placeholder="Enter zip code"
                variant="outlined"
                size="small"
                name="zipcode"
                value={userProfileData?.zipcode}
                onChange={setFormdata}
              />
            </Grid>
            <Grid item sm={12}>
              <Box className="workPupBtnBox">
                <AppBtnOutLine buttonText="Cancel" />
                <AppBtn
                  onClick={() => sendOtpApi()}

                  buttonText="Save Changes"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    )
  }

  return renderUserLayout(authState?.userType, null, getChildren(), 'accountsetting')
}
