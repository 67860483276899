import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { useTranslation } from "react-i18next";

//images
import dropArrow from "../../../Assets/Images/chevron-down.png";

export default function DeleteConversationCategoryPop({ deleteConversationCategoryPop, setDeleteConversationCategoryPop }) {

  const [topicCategoryDrop, setTopicCategoryDrop] = useState(false);
  const [topicCategoryDropVal, setTopicCategoryDropVal] = useState();
 
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
        setDeleteConversationCategoryPop(false);
    }
  };

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  useEffect(() => {
    if (deleteConversationCategoryPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [deleteConversationCategoryPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: deleteConversationCategoryPop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople"
      >
        <Box
          sx={{ display: deleteConversationCategoryPop ? "flex" : "none" }}
          className="workspaceContaint deleteConversationCategoryPop"
        >
      
                <Typography className="deleteConversationCategoryHeader">{t("deleteCategoryPop.deleteCategoryHead")}</Typography>
                <Box className="basicBoxItem">
                    <Typography className='deleteConversationLabel'>{t("deleteCategoryPop.deleteCategoryText")}</Typography>
                </Box>
                <Typography className="topicPopLabels">{t("deleteCategoryPop.selectCategoryLabel")}</Typography>
                <Typography className="bsNText3">{t("deleteCategoryPop.selectCategoryText")}</Typography>
                <Box className="input_Box">
                    <Box
                        className="inputAppDrop"
                        onClick={() => setTopicCategoryDrop(!topicCategoryDrop)}
                    >
                        <Typography>{topicCategoryDropVal || t("addConversationTopic.categoryPlaceholder")}</Typography>
                        <img
                        style={{ rotate: topicCategoryDrop ? "180deg" : "0deg" }}
                        src={dropArrow}
                        />
                        {/* pop */}
                        <Box
                        sx={{ height: topicCategoryDrop ? "auto" : "0px" }}
                        className="statusPop"
                        >
                        <Box
                            onClick={() => setTopicCategoryDropVal("Category 1")}
                            className="statusPopItem"
                        >
                            <Typography>Category 1</Typography>
                        </Box>
                        <Box
                            onClick={() => setTopicCategoryDropVal("Category 2")}
                            className="statusPopItem"
                        >
                            <Typography>Category 1</Typography>
                        </Box>
                    
                        </Box>
                    </Box>
                </Box>
            
        
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => setDeleteConversationCategoryPop(false)} />
            <AppBtn buttonText={t("commonTxt.deleteBtn")} />
          </Box>



        </Box>
      </Box>
    </>
  );
}
