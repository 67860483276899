import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles'
import "./style.css";

import imgCmainImg from "../../../Assets/Images/imgCmainImg.png"
import backArrow from "../../../Assets/Images/noun-back.png";
import cott from "../../../Assets/Images/cott.png";
import exportIcon from "../../../Assets/Images/exportIcon.png";
import map from "../../../Assets/Images/map.png"
import avatar from "../../../Assets/Images/profile-picture.png"
import avatar1 from "../../../Assets/Images/inb1.png"
import avatar2 from "../../../Assets/Images/inb2.png"
import avatar3 from "../../../Assets/Images/inb3.png"
import Option2LIcon from "../../../Assets/Images/Option2LIcon.png";
import editIcon from "../../../Assets/Images/edit 1.png";
import editBtn from "../../../Assets/Images/edit 1.png"
import whitedropIcon from "../../../Assets/Images/Wchevron-down.png";
import semoji from "../../../Assets/Images/Semoji.png"
import imgIcon from "../../../Assets/Images/galleryIconB.png"

//comments
import { ApexChart } from "../../../Components/AppTools/PieChart";
import BubbleChart from "../../../Components/AppTools/BubbleChart"
import { BarChart } from "../../../Components/AppTools/BarChart"
import { SearchBar, Status } from "../../../Components/AppTools/AppTool";
import SideMenu from "../../../Components/Researchers/SideMenu";
import { LatestCreated } from "../../../Components/AppTools/AppTool";

//data
import { languages, recapNavItem, sideHaumIcon } from "../../../Assets/Data";

export default function ViewRecapImage({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
  selectNavItem,
  setSelectNavItem,
}) {
  const Navigate = useNavigate();
  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Recently Added");

  const [searchParams, setSearchParams] = useSearchParams();
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const [nav, setNav] = useState(0);
  const [nav2, setNav2] = useState(0);
  const [aiSubnav, setAiSubNav] = useState(0);
  const [exportDrop, setExportDrop] = useState(false);
  const [exportDrop2, setExportDrop2] = useState(false);
  const [exportDrop3, setExportDrop3] = useState(false);
  const [exportDrop4, setExportDrop4] = useState(false);
  const [lang, setLang] = useState(false)
  const [langVal, setLangVal] = useState()

  const [providerDrop, setProviderDrop] = useState(false)
  const [providerDropVal, setProviderDropVal] = useState()

  const [providerDrop1, setProviderDrop1] = useState(false)
  const [providerDropVal1, setProviderDropVal1] = useState()

  const [providerDrop2, setProviderDrop2] = useState(false)
  const [providerDropVal2, setProviderDropVal2] = useState()

  const [providerDrop3, setProviderDrop3] = useState(false)
  const [providerDropVal3, setProviderDropVal3] = useState()

  const exportItem = ["PNG", "JPEG", "PDF", "SVG"];
  const [series] = useState([{ data: [400, 430, 448, 470, 540] }]);
  const categories = ['Product Reviews', 'Price', 'Brand Reputation', 'Durabilty/Quality', 'Aesthetics'];

  const [series2] = useState([{ data: [400, 430, 448, 470, 540] }]);
  const categories2 = ['Relaxation', 'Great Quote', 'Nostalgia', 'Empowerment'];
  const responseArrya = [
    {
      label: "Recent Responses",
    },
    {
      label: "Oldest Responses",
    },
    {
      label: "Recent Comments",
    },
  ];

  function wrapAtWordsWithSpan(text) {
    return text.replace(/@(\w+)/g, "<span>@$1</span>");
  }

  const singelComment = ({ img, name, comment, date, time }) => {
    return (
      <Box className="singelComment">
        <Box className="commentSection innerCommentSection">
          <Box className="avatarBox">
            <img src={img} />
          </Box>
          <Box className="commentTextSection">
            <Box className="userNameBox">
              <Typography className='userName'>{name}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "15px",
                  minWidth: "213px"
                }}
              >
                <Box className="editbtn">
                  <img src={editBtn} />
                  <Typography>{t("commonTxt.edit")}</Typography>
                </Box>
                <samp className="taskHeaderText3">
                  {date}
                </samp>
              </Box>
            </Box>

            <Typography className='commentWtag' dangerouslySetInnerHTML={{ __html: wrapAtWordsWithSpan(comment) }}></Typography>
            <Box className="likeBox">
              <Typography>{time}</Typography>
              <Typography>{t("commonTxt.Like")}</Typography>
              <Typography>{t("commonTxt.dislike")}</Typography>
              <Typography>{t("commonTxt.reply")}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

    )
  }



  const writeCommentSection = ({ img, postDrop, setPostDrop, postDropVal, setPostDropVal }) => {
    return (
      <Box className="writeCommentSection">
        <Box className="avatarBox">
          <img src={img} />
        </Box>
        <Box className="commentInputBox">
          <input className='phoneInput' placeholder={t("commonTxt.addComment")} />
          <img className='inputIcon1' src={semoji} />
          <img className='inputIcon2' src={imgIcon} />
          <Box className="commentCheckBox">
            <Box
              mb={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                minWidth: "206px"
              }}
            >
              <input
                style={{ marginRight: "10px" }}
                className="custom-checkbox"
                type="checkBox"
              />
              <Typography>{t("recapThread.makeReplyCompulsory")}</Typography>
            </Box>

            <Typography className='followText'>{t("emailPage.temp")}</Typography>
          </Box>
        </Box>

        <Box className="directMessageNavBtn" onClick={() => setPostDrop(!postDrop)}>
          <Typography className='DropBtnText'>{t("commonTxt.postBtn")}</Typography>
          <img src={whitedropIcon} style={{ rotate: postDrop ? "180deg" : "0deg" }} />
          <Box className="workspaceItemNavPop" sx={{ display: postDrop ? "flex" : "none" }}>
            <Box onClick={() => setPostDropVal("Visible to Everyone")}>
              <Typography>{t("recapThread.visibleToEveryone")}</Typography>
            </Box>
            <Box onClick={() => setPostDropVal("Only Andi Lane")}>
              <Typography>{t("recapThread.onlyAndiLane")}</Typography>
            </Box>
            <Box onClick={() => setPostDropVal("Backroom Comment")}>
              <Typography>{t("recapThread.backroomComment")}</Typography>
            </Box>
            <Box onClick={() => setPostDropVal("Backroom Task")}>
              <Typography>{t("recapThread.backroomTask")}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }


  const nestedCommentSection = ({ img, name, comment, date, time, postDrop, setPostDrop, postDropVal, setPostDropVal }) => {
    return (
      <Box className="singelComment">
        <Box className="commentSection innerCommentSection">
          <Box className="avatarBox">
            <img src={img} />
          </Box>
          <Box className="commentTextSection">
            <Box className="userNameBox">
              <Typography className='userName'>{name}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "15px",
                  minWidth: "213px"
                }}
              >
                <Box className="editbtn">
                  <img src={editBtn} />
                  <Typography>{t("commonTxt.edit")}</Typography>
                </Box>
                <samp className="taskHeaderText3">
                  {date}
                </samp>
              </Box>
            </Box>

            <Typography className='commentWtag' dangerouslySetInnerHTML={{ __html: wrapAtWordsWithSpan(comment) }}></Typography>
            <Box className="likeBox">
              <Typography>{time}</Typography>
              <Typography>{t("commonTxt.Like")}</Typography>
              <Typography>{t("commonTxt.dislike")}</Typography>
              <Typography>{t("commonTxt.reply")}</Typography>
            </Box>
            {singelComment({
              img: avatar3,
              name: "Kate Morrison",
              comment: "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
              date: "Aug 10, 2023",
              time: "10 h"
            })}
            {writeCommentSection({ img: avatar1, postDrop, setPostDrop, postDropVal, setPostDropVal })}
          </Box>
        </Box>
      </Box>

    )
  }

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

  useEffect(() => {
    let selTab = searchParams.get("r");
    if (selTab != null) {
      var query_string = selTab;
      query_string = query_string.replaceAll("_", " ");
      query_string = query_string.replace(/(^\w|\s\w)/g, (firstCharOfWord) =>
        firstCharOfWord.toUpperCase()
      );
      if (selTab === "data_charts") {
        var query_string = "Data & Charts";
        setSelectNavItem(query_string);
      } else {
        setSelectNavItem(query_string);
      }
    }
  }, []);

  return (
    <>
      <Box className="ResearchersContainer recapPage">
        {/* side menu */}
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>

            <Box className="recapNavItems">
              {recapNavItem?.map((el, i) => (
                <Box
                  key={i}
                  onClick={() => {
                    setSearchParams({ r: el.qstring });
                    setSelectNavItem(el.title);
                  }}
                  className={
                    selectNavItem === el.title
                      ? "recapNavItem recapNavItemActive"
                      : "recapNavItem"
                  }
                >
                  <Typography>{el.title}</Typography>
                </Box>
              ))}
            </Box>
            <Box width={"22%"} minWidth={"187px"} className="agreSearchB2">
              <LatestCreated
                setLatestDrop={setLatestDrop}
                latestCDrop={latestCDrop}
                setLatestDropVal={setLatestDropVal}
                latestDropVal={latestDropVal}
                itemAry={responseArrya}
              />
            </Box>
          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer">
            <Box className="recapImgComment recapVideoComment workspaceListBox">
              <Box mb={2} className="openBtn">
                <Box
                  onClick={() => Navigate("/recap?r=images")}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img src={backArrow} />
                  <Typography className="neonBackText">{t("commonTxt.backBtn")}</Typography>
                </Box>
              </Box>

              <Box className="imgcNavBtnBox">
                <Box className="mapB">
                  <img src={map} />
                  <Typography className="mapText">Perfect Outdoor Trip under Outdoor Journey</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "24px",
                  }}
                >            <Box className="actTNavBtn">
                    <img src={cott} />
                    <Typography>{t("commonTxt.excerptBtn")}</Typography>
                  </Box>


                  <Box className="actTNavBtn" >
                    <img src={exportIcon} />
                    <Typography>{t("commonTxt.exportLink")}</Typography>
                  </Box>

                </Box>
              </Box>


              <Box className="imgcUserInfoBox">
                <Box className="userInfo_Box">
                  <Box className="userimg">
                    <img src={avatar} />
                  </Box>
                  <Box >
                    <Typography className='userName'>Andi Lane</Typography>
                    <Typography className='userEmail'>lane_andi012@gmail.com</Typography>

                  </Box>
                </Box>
                <Typography className='taskHeaderText3'>06 Aug, 06:41 p.m.</Typography>
              </Box>

              <Box className="mainImgBox">
                <img src={imgCmainImg} />
              </Box>

              <Typography>Cheers to the most awesome trekking trip of my life!! </Typography>
              <Box className="gapBox"></Box>
              <Typography>Lorem ipsum dolor sit amet consectetur. Morbi suspendisse quis ornare in. Amet nunc lectus a aenean lectus.</Typography>


              {/* tab section */}
              <Box className="tabeSection">
                <Box className="recpSectionNav">
                  <Box
                    onClick={() => setNav(0)}
                    className={
                      nav === 0
                        ? "recpSectionNavItem recpSectionNavItemActive"
                        : "recpSectionNavItem"
                    }
                  >
                    <Typography>{t("viewRecapImage.commentsTab")}</Typography>
                  </Box>
                  <Box
                    onClick={() => setNav(2)}
                    className={
                      nav === 2
                        ? "recpSectionNavItem recpSectionNavItemActive"
                        : "recpSectionNavItem"
                    }
                  >
                    <Typography>{t("viewRecapImage.summaryAiTab")}</Typography>
                  </Box>
                  <Box
                    onClick={() => setNav(3)}
                    className={
                      nav === 3
                        ? "recpSectionNavItem recpSectionNavItemActive"
                        : "recpSectionNavItem"
                    }
                  >
                    <Typography>{t("viewRecapImage.AiAnalysis")}</Typography>
                  </Box>
                </Box>
              </Box>

              {/* Comments */}
              <Box
                className="sectionBox"
                sx={{ display: nav === 0 ? "block" : "none" }}>
                <Typography className="followText">{t("commonTxt.hideComments")}</Typography>
                <Box className="commentListBox">
                  {nestedCommentSection({
                    img: avatar1,
                    name: "Orlando Diggs",
                    comment: "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                    date: "Aug 10, 2023",
                    time: "10 h",
                    postDrop: providerDrop,
                    setPostDrop: setProviderDrop,
                    postDropVal: providerDropVal,
                    setPostDropVal: setProviderDropVal
                  })}
                  {nestedCommentSection({
                    img: avatar2,
                    name: "Orlando Diggs",
                    comment: "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                    date: "Aug 10, 2023",
                    time: "10 h",
                    postDrop: providerDrop1,
                    setPostDrop: setProviderDrop1,
                    postDropVal: providerDropVal1,
                    setPostDropVal: setProviderDropVal1
                  })}
                  {nestedCommentSection({
                    img: avatar3,
                    name: "Orlando Diggs",
                    comment: "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                    date: "Aug 10, 2023",
                    time: "10 h",
                    postDrop: providerDrop2,
                    setPostDrop: setProviderDrop2,
                    postDropVal: providerDropVal2,
                    setPostDropVal: setProviderDropVal2
                  })}
                  {nestedCommentSection({
                    img: avatar2,
                    name: "Orlando Diggs",
                    comment: "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                    date: "Aug 10, 2023",
                    time: "10 h",
                    postDrop: providerDrop3,
                    setPostDrop: setProviderDrop3,
                    postDropVal: providerDropVal3,
                    setPostDropVal: setProviderDropVal3
                  })}
                </Box>
              </Box>

              {/* Summary (AI) */}
              <Box
                className="sectionBox"
                sx={{ display: nav === 2 ? "block" : "none" }}
              >
                <Box className="transcroptBox">
                  <Box className="transDrop">
                    <Typography className="translateLabel">{t("viewRecapImage.translateTo")} :</Typography>
                    <Box width={"200px"}><Status label={t("viewRecapImage.selectLanguagePlaceholder")} itemAry={languages} setStatusDrop={setLang} statusDrop={lang} setStatusDropVal={setLangVal} statusDropVal={langVal} /></Box>
                  </Box>

                  <Box>
                    <SearchBar placeholder={t("viewRecapImage.searchWordPlaceholder")} />
                  </Box>
                </Box>

                <Box className="rectVideosummeryBox">
                  <Box>
                    <Typography>The Strategic Planning Summit for Project Acceleration unfolded as a multifaceted exploration encompassing critical domains crucial for corporate success. Attendees included key stakeholders, department heads, and subject matter experts, converging to strategize the next phase of the project while considering broader market dynamics.
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                    <Typography sx={{ fontSize: "18px" }}>Market Analysis and Trends:</Typography>
                    <Typography>The Strategic Planning Summit for Project Acceleration unfolded as a multifaceted exploration encompassing critical domains crucial for corporate success. Attendees included key stakeholders, department heads, and subject matter experts, converging to strategize the next phase of the project while considering broader market dynamics.
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                    <Typography sx={{ fontSize: "18px" }}>Project Acceleration Strategies:</Typography>
                    <Typography>Brainstorming ensued, generating a wealth of innovative strategies to expedite Project Acceleration. Ideas ranged from product feature enhancements to novel customer engagement models, all aligned with the overarching goal of outpacing competitors. The session saw a dynamic exchange of perspectives, fostering a creative environment conducive to breakthrough solutions.
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                    <Typography sx={{ fontSize: "18px" }}>Resource Allocation and Optimization:
                    </Typography>
                    <Typography>A pivotal segment focused on resource allocation and optimization to ensure maximal efficiency. Teams delved into assessing current resource utilization, identifying potential bottlenecks, and proposing streamlined workflows. Decisions on budget adjustments and personnel reallocation were made with a strategic lens, aiming to fortify the project's execution.
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                    <Typography sx={{ fontSize: "18px" }}>Timeline Adjustments and Risk Mitigation:
                    </Typography>
                    <Typography>Recognizing the dynamic nature of the business landscape, participants engaged in a thorough review of project timelines. Contingency plans were developed to address potential risks and ensure adaptability to unforeseen challenges. The emphasis was on building resilience into the project framework while maintaining alignment with overarching goals.
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                    <Typography sx={{ fontSize: "18px" }}>Actionable Next Steps:
                    </Typography>
                    <Typography>The summit concluded with a synthesis of insights gathered throughout the meeting. A roadmap of actionable next steps emerged, outlining the strategic initiatives to be executed in the immediate and medium-term future. Each step was assigned to responsible parties, with clear timelines and performance indicators established for ongoing evaluation.
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                    <Typography sx={{ fontSize: "18px" }}>Cohesive Vision for Success:
                    </Typography>
                    <Typography>Throughout the meeting, a unifying theme emerged — a shared commitment to project success and market domination. The collaborative spirit fostered a cohesive vision, with all participants leaving with a heightened sense of purpose and confidence in the strategic direction set forth.
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                    <Typography>In essence, the Comprehensive Strategic Planning Summit not only propelled Project Acceleration forward but also equipped the organization with a resilient and adaptive framework, ensuring it remains at the forefront of industry innovation and market leadership.</Typography>
                  </Box>


                </Box>

              </Box>

              {/* AI Analysis */}
              <Box
                className="sectionBox"
                sx={{ display: nav === 3 ? "block" : "none" }}
              >
                <Box className="aiAnalysisSubNav">
                  <Box
                    onClick={() => setAiSubNav(0)}
                    className={
                      aiSubnav === 0 ? "aiSubTab aiSubTabActive" : "aiSubTab"
                    }
                  >
                    <Typography>{t("viewRecapImage.commentAnalysisTab")}</Typography>
                  </Box>
                  <Box
                    onClick={() => setAiSubNav(1)}
                    className={
                      aiSubnav === 1 ? "aiSubTab aiSubTabActive" : "aiSubTab"
                    }
                  >
                    <Typography>{t("viewRecapImage.imageAnalysisTab")}</Typography>
                  </Box>
                </Box>

                <Box
                  className="aiSubSection"
                  sx={{ display: aiSubnav === 1 ? "block" : "none" }}
                >
                  <Box className="tabelNav">
                    <Typography className="pageTitle">{t("viewRecapImage.sentimentsAnalysisHead")}</Typography>
                    <Box
                      className="exporOpenBtn"
                      onClick={() => setExportDrop(!exportDrop)}
                    >
                      <img src={exportIcon} />
                      <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>

                    </Box>
                  </Box>

                  <Box className="pieBox">
                    <ApexChart />
                  </Box>

                  <Box className="chartTableNav">
                    <Box className="chartNavItem">
                      <Typography>{t("commonTxt.hideBtn")}</Typography>
                    </Box>
                    <Box className="chartNavItem">
                      <Typography>{t("commonTxt.groupBtn2")}</Typography>
                    </Box>
                    <Box className="chartNavItem">
                      <Typography>{t("commonTxt.changeColorBtn")}</Typography>
                    </Box>
                  </Box>

                  <Box mb={6} className="workListBox">
                    <Box className="haderRow" minWidth={"955px"}>
                      <Box minWidth={"20%"} className="Tabelsel TabelStatesel">
                        <Typography>{t("recapeVideoReview.tblAction")}</Typography>
                      </Box>
                      <Box minWidth={"15%"} className="Tabelsel">
                        <Typography>{t("recapeVideoReview.tblColor")}</Typography>
                      </Box>
                      <Box minWidth={"30%"} className="Tabelsel TabelStatesel">
                        <Typography>{t("recapeVideoReview.tblTag")}</Typography>
                      </Box>

                      <Box minWidth={"13%"} className="Tabelsel">
                        <Typography>{t("recapeVideoReview.tblResponses")}</Typography>
                      </Box>
                      <Box minWidth={"13%"} className="Tabelsel">
                        <Typography>{t("recapeVideoReview.tblPercentage")}</Typography>
                      </Box>
                    </Box>

                    <Box className="TabelRow" minWidth={"955px"}>
                      <Box
                        minWidth={"20%"}
                        gap={"40px"}
                        className="Tabelsel navFsel navtable"
                      >
                        <img src={Option2LIcon} />
                        <input type="checkBox" className="custom-checkbox" />
                      </Box>
                      <Box minWidth={"15%"} className="Tabelsel Tabelcentersel">
                        <Box
                          className="colorBox"
                          sx={{ background: "#21A1F9" }}
                        ></Box>
                      </Box>
                      <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                        <img src={editIcon} />
                        <Typography>😍</Typography>
                        <Typography>Like</Typography>
                      </Box>
                      <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                        <Typography>18</Typography>
                      </Box>
                      <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                        <Typography>58.2%</Typography>
                      </Box>
                    </Box>

                    <Box className="TabelRow" minWidth={"955px"}>
                      <Box
                        minWidth={"20%"}
                        gap={"40px"}
                        className="Tabelsel navFsel navtable"
                      >
                        <img src={Option2LIcon} />
                        <input type="checkBox" className="custom-checkbox" />
                      </Box>
                      <Box minWidth={"15%"} className="Tabelsel Tabelcentersel">
                        <Box
                          className="colorBox"
                          sx={{ background: "#FF4E72" }}
                        ></Box>
                      </Box>
                      <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                        <img src={editIcon} />
                        <Typography>😟</Typography>
                        <Typography>Dislike</Typography>
                      </Box>
                      <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                        <Typography>05</Typography>
                      </Box>
                      <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                        <Typography>18.5%</Typography>
                      </Box>
                    </Box>

                    <Box className="TabelRow" minWidth={"955px"}>
                      <Box
                        minWidth={"20%"}
                        gap={"40px"}
                        className="Tabelsel navFsel navtable"
                      >
                        <img src={Option2LIcon} />
                        <input type="checkBox" className="custom-checkbox" />
                      </Box>
                      <Box minWidth={"15%"} className="Tabelsel Tabelcentersel">
                        <Box
                          className="colorBox"
                          sx={{ background: "#FFBE28" }}
                        ></Box>
                      </Box>
                      <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                        <img src={editIcon} />
                        <Typography>🤨</Typography>
                        <Typography>Confused</Typography>
                      </Box>
                      <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                        <Typography>08</Typography>
                      </Box>
                      <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                        <Typography>23.3%</Typography>
                      </Box>
                    </Box>
                  </Box>

                  {/* Sentiments Distribution */}
                  <Box className="tabelNav">
                    <Typography className="pageTitle">{t("viewRecapImage.sentimentsDistributionHead")}</Typography>
                    <Box
                      className="exporOpenBtn"
                      onClick={() => setExportDrop2(!exportDrop2)}
                    >
                      <img src={exportIcon} />
                      <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>

                    </Box>
                  </Box>
                  <Box className="bubbleChartBox">
                    <BubbleChart />
                  </Box>



                  {/* Quotes Analysis */}
                  <Box className="tabelNav">
                    <Typography className="pageTitle">{t("viewRecapImage.quotesAnalysisHead")}</Typography>
                    <Box
                      className="exporOpenBtn"
                      onClick={() => setExportDrop3(!exportDrop3)}
                    >
                      <img src={exportIcon} />
                      <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>

                    </Box>
                  </Box>

                  <Box className="barChart">
                    <BarChart series={series} categories={categories} />
                  </Box>




                  <Box className="tabelNav">
                    <Typography className="pageTitle">{t("viewRecapImage.excerptsHead")}</Typography>
                    <Box
                      className="exporOpenBtn"
                      onClick={() => setExportDrop4(!exportDrop4)}
                    >
                      <img src={exportIcon} />
                      <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>

                    </Box>
                  </Box>

                  <Box className="barChart">
                    <BarChart series={series2} categories={categories2} />
                  </Box>


                  {/* bottom tab section */}
                  <Box className="bottomTabel">

                    <Box className="tabeSection">
                      <Box className="recpSectionNav">
                        <Box
                          onClick={() => setNav2(0)}
                          className={
                            nav2 === 0
                              ? "recpSectionNavItem recpSectionNavItemActive"
                              : "recpSectionNavItem"
                          }
                        >
                          <Typography>{t("viewRecapImage.manualTab")}</Typography>
                        </Box>
                        <Box
                          onClick={() => setNav2(1)}
                          className={
                            nav2 === 1
                              ? "recpSectionNavItem recpSectionNavItemActive"
                              : "recpSectionNavItem"
                          }
                        >
                          <Typography>{t("viewRecapImage.excerptsAiTab")}</Typography>
                        </Box>
                      </Box>
                    </Box>


                    {/* Manual */}
                    <Box sx={{ display: nav2 === 0 ? "block" : "none" }}>
                      <Box className="tabelNav">
                        <Typography className="pageTitle">{t("viewRecapImage.excerptsHead")}</Typography>
                        <Box width={"320px"}><SearchBar placeholder={t("viewRecapImage.searchPlaceholder")} /></Box>
                      </Box>


                      <Box mb={6} className="workListBox">
                        <Box className="haderRow" minWidth={"955px"}>
                          <Box minWidth={"10%"} className="Tabelsel TabelStatesel">
                            <Typography>{t("commonTxt.Uses")}</Typography>
                          </Box>
                          <Box minWidth={"17%"} className="Tabelsel TabelStatesel">
                            <Typography>{t("commonTxt.Codes")}</Typography>
                          </Box>
                          <Box minWidth={"40%"} className="Tabelsel TabelStatesel">
                            <Typography>{t("commonTxt.Description")}</Typography>
                          </Box>
                        </Box>

                        <Box className="TabelRow" minWidth={"955px"}>
                          <Box minWidth={"10%"} className="Tabelsel ">
                            <Box className="UBox" >6</Box>
                          </Box>
                          <Box minWidth={"17%"} gap={"10px"} className="Tabelsel">
                            <Typography>Empowerment</Typography>
                          </Box>
                          <Box minWidth={"40%"} className="Tabelsel Tabelcentersel">
                            <Typography>Verbatim that touches on the theme of “empowerment”</Typography>
                          </Box>
                        </Box>

                        <Box className="TabelRow" minWidth={"955px"}>
                          <Box minWidth={"10%"} className="Tabelsel ">
                            <Box className="UBox" >4</Box>
                          </Box>
                          <Box minWidth={"17%"} gap={"10px"} className="Tabelsel">
                            <Typography>Nostalgia</Typography>
                          </Box>
                          <Box minWidth={"40%"} className="Tabelsel Tabelcentersel">
                            <Typography>Verbatim that touches on the theme of “nostalgia”</Typography>
                          </Box>
                        </Box>

                        <Box className="TabelRow" minWidth={"955px"}>
                          <Box minWidth={"10%"} className="Tabelsel ">
                            <Box className="UBox" >2</Box>
                          </Box>
                          <Box minWidth={"17%"} gap={"10px"} className="Tabelsel">
                            <Typography>Relaxation</Typography>
                          </Box>
                          <Box minWidth={"40%"} className="Tabelsel Tabelcentersel">
                            <Typography>Verbatim that touches on the theme of “relaxation”</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {/* Excerpts */}
                    <Box sx={{ display: nav2 === 1 ? "block" : "none" }}></Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
