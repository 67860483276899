import React, { useState } from 'react'
import "./style.css"
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

//images
import banner from "../../../Assets/Images/logBanner.png"
import CLogo from "../../../Assets/Images/Frame 243811.png"
import RedInfo from "../../../Assets/Images/info.png"
import VisibleIcon from "../../../Assets/Images/VisibleIcon.png"
import { useTranslation } from 'react-i18next';
import { resetPassword } from '../../../Services/auth';
import { USER_TYPE_WS_ADMIN } from '../../../lib/constants';
import { getUserTypeFromSegment, getUserUriSegment } from '../../../lib/helpers';
import { useParams } from 'react-router-dom';

export default function NewPwd() {
  const { usersegment } = useParams();
  let usertype = getUserTypeFromSegment(usersegment);
  const Navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  usertype = usertype || {USER_TYPE_WS_ADMIN};
  const [pwd, setPwd] = useState(false)
  const [pwd1, setPwd1] = useState(false)
  const { t, i18n: { changeLanguage, language } } = useTranslation();
  const [apiError, setApiError] = useState("")
  const [loading, setLoading] = useState(false);
  const [credential, setCredential] = useState({
    email: params.get('email'),
    otp: params.get('otp'),
    password: '',
    confirm_password: ''
  });

  const changeAppLanguage = (lng) => {
    changeLanguage(lng);
  }

  const changePassword = async () => {
    if (credential.email == "") {
      setApiError("Email is required!");
      return;
    }
  
    if (credential.otp == "") {
      setApiError("OTP is required!");
      return;
    }
  
    if (credential.password == "") {
      setApiError("Password is required!");
      return;
    }
  
    if (credential.password.length < 6 || credential.confirm_password.length < 6) {
      setApiError("Password length should be minimum 6 digits!");
      return;
    }
  
    if (credential.password != credential.confirm_password) {
      setApiError("Passwords do not match!");
      return;
    }
    
    try {
      setLoading(true)
      const response = await resetPassword(credential)
      setLoading(false);
      if(response.success) {
        toast.success(response.message)
        Navigate(`/${getUserUriSegment(usertype)}/login`)
      } else {
        setApiError(response.message);
      }
    } catch (err) {
      console.log(err);
      setApiError('Something went wrong! Please try again');
      setLoading(false);
    };

  };
  

  return (
    <>
      <select style={{ float: 'right' }} onChange={(e) => { changeAppLanguage(e.target.value) }} value={language}>
        <option value="en">English</option>
        <option value="hi">Hindi</option>
      </select>
      <Box className="logInPage">
        <Box className="banner">
          <Typography className=''>Take your research to the next level</Typography>
          <Box mt={3} className="slideBox">
            <Box className="sBox"></Box>
            <Box className="sBox"></Box>
            <Box className="sBox sBoxActive"></Box>
          </Box>
          <img src={banner} />
        </Box>
        <Box className="signInBox">
          <Box className="signInInnerBox">
            <Box className="signColog">
              <img onClick={() => Navigate("/")} src={CLogo} />
            </Box>

            <Box className="signInInputBox">
              <Typography>New Password</Typography>
              <input placeholder='Enter Password' type={pwd ? "text" : "password"} onChange={e => setCredential({ ...credential, password: e.target.value })} />
              <img className='eyeIcon' src={VisibleIcon} onClick={() => setPwd(!pwd)} />
            </Box>

            <Box className="signInInputBox">
              <Typography>Confirm  New Password</Typography>
              <input placeholder='Enter Password' type={pwd1 ? "text" : "password"} onChange={e => setCredential({ ...credential, confirm_password: e.target.value })} />
              <img className='eyeIcon' src={VisibleIcon} onClick={() => setPwd1(!pwd1)} />
            </Box>

            {apiError !== "" && <Box mb={2} className="pwdError">
              <img src={RedInfo} />
              <Typography>{apiError}</Typography>
            </Box>}

            <Box className="signBtn"  onClick={changePassword}>
            {loading ? <Typography>{t('adminSignIn.loading')}...</Typography> :<Typography>Continue</Typography>}
            </Box>

            <Typography onClick={() => Navigate(`/${getUserUriSegment(usertype)}/login`)} className='gologPageLing'>Back to Sign in page</Typography>

          </Box>
        </Box>

      </Box>
    </>
  )
}
