import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//images
import backArrow from "../../../Assets/Images/noun-back.png";
import book from "../../../Assets/Images/book.png";
import cott from "../../../Assets/Images/cott.png";
import avatar from "../../../Assets/Images/Edit-Avatar.png";
import tp1 from "../../../Assets/Images/tp1.png";
import tp2 from "../../../Assets/Images/tp2.png";
import tp3 from "../../../Assets/Images/tp3.png";
import tp4 from "../../../Assets/Images/tp4.png";
import tp5 from "../../../Assets/Images/tp5.png";
import tp6 from "../../../Assets/Images/tp6.png";
import tp7 from "../../../Assets/Images/tp7.png";
import tp8 from "../../../Assets/Images/tp8.png";
import tp9 from "../../../Assets/Images/tp9.png";
import tp10 from "../../../Assets/Images/tp10.png";
import tp11 from "../../../Assets/Images/tp11.png";
import blackImg from "../../../Assets/Images/blankImg.png";
import leftArrow from "../../../Assets/Images/arrow-left.png";
import rightArrow from "../../../Assets/Images/arrow-right.png";
import chevronDown from "../../../Assets/Images/chevron-down.png";
import editBtn from "../../../Assets/Images/edit 1.png"


import { sideHaumIcon, taskList, YDot, like, dislike, comment, tik } from "../../../Assets/Data";

//Components
import SideMenu from "../../../Components/Researchers/SideMenu";
import ViewResponse from "../ViewResponse";

export default function ParticipantsRespons({
  viewResponsePage,
  setViewResponsePage,
  viewResponsePageNavDropVal,
  setViewResponsePageNavDropVal,
  workspaceDrop,
  setWorkSpaceDrop,
  slideMenu,
  setSlideMenu,
}) {
  const Navigate = useNavigate();
  const [participantsResNavDrop, setParticipantResNavDrop] = useState(false);
  const [participantsResNavDropVal, setParticipantResNavDropVal] =
    useState("All Tasks");

    const {
      t,
      i18n: { changeLanguage, language },
    } = useTranslation();

  const productBox = ({ img1, img2, img3, img4, title, subTitle }) => {
    return (
      <Box className="taskProductItem">
        <Typography>{title}</Typography>
        <Box my={1} className="productImgBox">
          <Box className="productImg">
            <img src={img1} />
          </Box>
          <Box className="productImg">
            <img src={img2} />
          </Box>
          <Box className="productImg">
            <img src={img3} />
          </Box>
          <Box className="productImg">
            <img src={img4} />
          </Box>
        </Box>
        <Typography>{subTitle}</Typography>
      </Box>
    );
  };

  return (
    <>
      <Box className="ResearchersContainer ">
        {/* side menu */}
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />

        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
            <Box
              className="allTaskNavDrop"
              onClick={() => setParticipantResNavDrop(!participantsResNavDrop)}
            >
              <Box className="tNDL">
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={leftArrow}
                />
              </Box>

              <Box className="tndC">
                <Typography>{participantsResNavDropVal}</Typography>
                <img
                  style={{
                    width: "20px",
                    height: "20px",
                    marginLeft: "10px",
                    rotate: participantsResNavDrop ? "180deg" : "0deg",
                  }}
                  src={chevronDown}
                />
              </Box>

              <Box className="tNDR">
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={rightArrow}
                />
              </Box>

              <Box
                className="statusPop"
                sx={{ display: participantsResNavDrop ? "block" : "none" }}
              >
                {taskList?.map((el, index) => (
                  <Box
                    key={index}
                    className="statusPopItem"
                    onClick={() => setParticipantResNavDropVal(el)}
                  >
                    <Typography>{el}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>

          {/* Page container */}
          <Box className="researchersMainBox pageContainer">
            {viewResponsePage ? (
              <ViewResponse
                setViewResponsePage={setViewResponsePage}
                viewResponsePageNavDropVal={viewResponsePageNavDropVal}
                setViewResponsePageNavDropVal={setViewResponsePageNavDropVal}
              />
            ) : (
              <Box className="participantsResponsContainer workSpaceListBox">
                <Box className="openBtn">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    onClick={() => Navigate("/activities/task")}
                  >
                    <img src={backArrow} />
                    <Typography className="neonBackText">{t("commonTxt.backBtn")}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "24px",
                    }}
                  >
                    <Box className="actTNavBtn">
                      <img src={book} />
                      <Typography>{t("workspaceHome.actionLog")}</Typography>
                    </Box>
                    <Box className="actTNavBtn">
                      <img src={cott} />
                      <Typography>{t("commonTxt.excerptBtn")}</Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className="userProfilrow">
                  <Box className="userInfoBox">
                    <Box className="AvatrIBox">
                      <img src={avatar} />
                      {YDot}
                    </Box>
                    <Typography className="userIName">Olivia Rhye</Typography>
                  </Box>

                  <Box gap="20px" className="userInfoBox">
                    <Box className="statusBtnBoxError">
                      <Typography>{t("projectTaskReport.tblInProgress")}</Typography>
                    </Box>
                    <Typography className="userIInfoDate">
                      Aug 04, 2023 04:35 PM
                    </Typography>
                  </Box>
                </Box>

                <Box className="graySBox taskBox">
                  <Box className="taskBoxTopDate">
                    <Typography className="taskHeaderText">
                      Task 1 Name
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <Typography
                        className="taskHeaderText2"
                        onClick={() =>
                          Navigate("/activities/task/outdoor")
                        }
                      >
                        {t("commonTxt.viewBtn")}
                      </Typography>
                      <samp className="taskHeaderText3">
                        Aug 04, 2023 02:21 PM
                      </samp>
                    </Box>
                  </Box>
                  <Typography mb={3}>
                    Which of these outdoor activities do you participate in?
                    Please select all that apply and explain why these
                    activities are the outdoor activities for you.
                  </Typography>

                  <Box ml={5} mb={3}>
                    <ul style={{ marginBottom: "10px" }}>
                      <li>Camping</li>
                      <li>Hiking</li>
                    </ul>
                    <span>
                      All you need is a back pack, tent and some boots - nothing
                      big or complicated!
                    </span>
                  </Box>
                  <Box className="taskBoxBottomRow">
                    <Box className="taskBoxBottomRowItem">
                      {like}
                      <Typography>{t("commonTxt.Like")}</Typography>
                    </Box>
                    <Box className="taskBoxBottomRowItem">
                      {dislike}
                      <Typography>{t("commonTxt.dislike")}</Typography>
                    </Box>
                    <Box className="taskBoxBottomRowItem">
                      {comment}
                      <Typography>{t("commonTxt.comment")}</Typography>
                    </Box>
                    <Box className="taskBoxBottomRowItem">
                      {tik}
                      <Typography>{t("commonTxt.review")}</Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className="graySBox taskBox">
                  <Box className="taskBoxTopDate">
                    <Typography className="taskHeaderText">
                      Task 2 Name
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <Typography
                        className="taskHeaderText2"
                        onClick={() =>
                          Navigate("/activities/task/outdoor")
                        }
                      >
                        {t("commonTxt.viewBtn")}
                      </Typography>
                      <samp className="taskHeaderText3">
                        Aug 04, 2023 02:21 PM
                      </samp>
                    </Box>
                  </Box>
                  <Typography mb={3}>
                    Select the Outdoor Essentials and sort them into the
                    following categories.
                  </Typography>

                  <Box className="taskProductBox">
                    {productBox({
                      img1: tp1,
                      img2: tp2,
                      img3: tp3,
                      img4: tp4,
                      title: "Absolutely Needed",
                      subTitle: "+2 more",
                    })}
                    {productBox({
                      img1: tp5,
                      img2: tp6,
                      img3: tp7,
                      img4: tp8,
                      title: "Nice to Have",
                      subTitle: "+5 more",
                    })}
                    {productBox({
                      img1: tp9,
                      img2: tp10,
                      img3: tp11,
                      img4: blackImg,
                      title: "Forgettable",
                      // subTitle: "+2 more"
                    })}
                  </Box>

                  <Box className="taskBoxBottomRow">
                    <Box className="taskBoxBottomRowItem">
                      {like}
                      <Typography>{t("commonTxt.Like")}</Typography>
                    </Box>
                    <Box className="taskBoxBottomRowItem">
                      {dislike}
                      <Typography>{t("commonTxt.dislike")}</Typography>
                    </Box>
                    <Box className="taskBoxBottomRowItem">
                      {comment}
                      <Typography>{t("commonTxt.comment")}</Typography>
                    </Box>
                    <Box className="taskBoxBottomRowItem">
                      {tik}
                      <Typography>{t("commonTxt.review")}</Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className="graySBox taskBox">
                  <Box className="taskBoxTopDate">
                    <Typography className="taskHeaderText">
                      Task 3 Name
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <Typography
                        className="taskHeaderText2"
                        onClick={() =>
                          Navigate("/activities/task/outdoor")
                        }
                      >
                        {t("commonTxt.viewBtn")}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography mb={3}>
                    Which of these outdoor activities do you participate in?
                    Please select all that apply and explain why these
                    activities are the outdoor activities for you.
                  </Typography>
                  <Typography
                    ml={5}
                    my={3}
                    sx={{
                      color: "#706C80",
                    }}
                  >
                    No response added yet. Data will be visible once the
                    participant responds.
                  </Typography>
                  <Box className="taskBoxBottomRow">
                    <Box className="taskBoxBottomRowItem">
                      {like}
                      <Typography>{t("commonTxt.Like")}</Typography>
                    </Box>
                    <Box className="taskBoxBottomRowItem">
                      {dislike}
                      <Typography>{t("commonTxt.dislike")}</Typography>
                    </Box>
                    <Box className="taskBoxBottomRowItem">
                      {comment}
                      <Typography>{t("commonTxt.comment")}</Typography>
                    </Box>
                    <Box className="taskBoxBottomRowItem">
                      {tik}
                      <Typography>{t("commonTxt.review")}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
