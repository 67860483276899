import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import "./style.css";

//images
import arrowDrop from "../../../Assets/Images/chevron-down.png"
import teraIcon from "../../../Assets/Images/teraIcon.png"
import gigaIcon from "../../../Assets/Images/gigaIcon.png"
import megaIcon from "../../../Assets/Images/mega.png"

//data

//component
import { Box, Typography, FormLabel, } from '@mui/material';
import { AppBtn, AppBtnOutLine } from '../../AppTools/AppButton';



export default function ProjectPoints() {
  const [activeDrop, setActiveDrop] = useState(false)
  const [activeDrop2, setActiveDrop2] = useState(false)
  const [activeDrop3, setActiveDrop3] = useState(false)
  const [activeDrop4, setActiveDrop4] = useState(false)

  const { t } = useTranslation();

  return (
    <>
      <Box className="pointBox">
        <Box mb={2} className="PointtopBtn">
          <Typography>0 {t("projectSettingPoints.participantEarned")} 0 {t("projectSettingPoints.pointsText")}</Typography>
        </Box>

        <Box className="gapBox"></Box>

        <Typography className='pageSubHeader'>{t("projectSettingPoints.pointsText")}</Typography>

        <Box className="pointDropBox">
          <Box className="DropAroBtn" onClick={() => setActiveDrop(!activeDrop)}>
            <Box className="dropIconPoint">
              <img src={arrowDrop} style={{ rotate: activeDrop ? "180deg" : "-90deg" }} />
            </Box>
            <Typography>{t("projectSettingPoints.activitiesHead")}</Typography>
          </Box>
          <Box sx={{ height: activeDrop ? "auto" : "0px", overflow: "hidden" }}>
            <Box mb={3} className="pcTopicListRow">
              <Typography>{t("projectSettingPoints.activitiesText1")}</Typography>
              <span>:</span>
              <input defaultValue="25" />
              <samp>pts.</samp>
            </Box>
            <Box mb={3} className="pcTopicListRow">
              <Typography>{t("projectSettingPoints.activitiesText2")}</Typography>
              <span>:</span>
              <input defaultValue="25" />
              <samp>pts.</samp>
            </Box>
            <Box mb={3} className="pcTopicListRow">
              <Typography>{t("projectSettingPoints.activitiesText3")}</Typography>
              <span>:</span>
              <input defaultValue="25" />
              <samp>pts.</samp>
            </Box>
            <Box mb={3} className="pcTopicListRow">
              <Typography>{t("projectSettingPoints.activitiesText4")}</Typography>
              <span>:</span>
              <input defaultValue="25" />
              <samp>pts.</samp>
            </Box>
            <Box mb={3} className="pcTopicListRow">
              <Typography>{t("projectSettingPoints.activitiesText5")}</Typography>
              <span>:</span>
              <input defaultValue="25" />
              <samp>pts.</samp>
            </Box>
          </Box>
        </Box>



        <Box className="pointDropBox">
          <Box className="DropAroBtn" onClick={() => setActiveDrop2(!activeDrop2)}>
            <Box className="dropIconPoint">
              <img src={arrowDrop} style={{ rotate: activeDrop2 ? "180deg" : "-90deg" }} />
            </Box>
            <Typography>{t("projectSettingPoints.activitiesFeedbackHead")}</Typography>
          </Box>
          <Box sx={{ height: activeDrop2 ? "auto" : "0px", overflow: "hidden" }}>
            <Box mb={3} className="pcTopicListRow">
              <Typography>{t("projectSettingPoints.activitiesText1")}</Typography>
              <span>:</span>
              <input defaultValue="25" />
              <samp>pts.</samp>
            </Box>
            <Box mb={3} className="pcTopicListRow">
              <Typography>{t("projectSettingPoints.activitiesText2")}</Typography>
              <span>:</span>
              <input defaultValue="25" />
              <samp>pts.</samp>
            </Box>
            <Box mb={3} className="pcTopicListRow">
              <Typography>{t("projectSettingPoints.activitiesText3")}</Typography>
              <span>:</span>
              <input defaultValue="25" />
              <samp>pts.</samp>
            </Box>
            <Box mb={3} className="pcTopicListRow">
              <Typography>{t("projectSettingPoints.activitiesText4")}</Typography>
              <span>:</span>
              <input defaultValue="25" />
              <samp>pts.</samp>
            </Box>
            <Box mb={3} className="pcTopicListRow">
              <Typography>{t("projectSettingPoints.activitiesText5")}</Typography>
              <span>:</span>
              <input defaultValue="25" />
              <samp>pts.</samp>
            </Box>
          </Box>
        </Box>


        <Box className="pointDropBox">
          <Box className="DropAroBtn" onClick={() => setActiveDrop3(!activeDrop3)}>
            <Box className="dropIconPoint">
              <img src={arrowDrop} style={{ rotate: activeDrop3 ? "180deg" : "-90deg" }} />
            </Box>
            <Typography>{t("projectSettingPoints.conversationHead")}</Typography>
          </Box>
          <Box sx={{ height: activeDrop3 ? "auto" : "0px", overflow: "hidden" }}>
            <Box mb={3} className="pcTopicListRow">
              <Typography>{t("projectSettingPoints.activitiesText1")}</Typography>
              <span>:</span>
              <input defaultValue="25" />
              <samp>pts.</samp>
            </Box>
            <Box mb={3} className="pcTopicListRow">
              <Typography>{t("projectSettingPoints.activitiesText2")}</Typography>
              <span>:</span>
              <input defaultValue="25" />
              <samp>pts.</samp>
            </Box>
            <Box mb={3} className="pcTopicListRow">
              <Typography>{t("projectSettingPoints.activitiesText3")}</Typography>
              <span>:</span>
              <input defaultValue="25" />
              <samp>pts.</samp>
            </Box>
            <Box mb={3} className="pcTopicListRow">
              <Typography>{t("projectSettingPoints.activitiesText4")}</Typography>
              <span>:</span>
              <input defaultValue="25" />
              <samp>pts.</samp>
            </Box>
            <Box mb={3} className="pcTopicListRow">
              <Typography>{t("projectSettingPoints.activitiesText5")}</Typography>
              <span>:</span>
              <input defaultValue="25" />
              <samp>pts.</samp>
            </Box>
          </Box>
        </Box>



        <Box className="pointDropBox">
          <Box className="DropAroBtn" onClick={() => setActiveDrop4(!activeDrop4)}>
            <Box className="dropIconPoint">
              <img src={arrowDrop} style={{ rotate: activeDrop4 ? "180deg" : "-90deg" }} />
            </Box>
            <Typography>{t("projectSettingPoints.generalHead")}</Typography>
          </Box>
          <Box sx={{ height: activeDrop4 ? "auto" : "0px", overflow: "hidden" }}>
            <Box mb={3} className="pcTopicListRow">
              <Typography>{t("projectSettingPoints.activitiesText1")}</Typography>
              <span>:</span>
              <input defaultValue="25" />
              <samp>pts.</samp>
            </Box>
            <Box mb={3} className="pcTopicListRow">
              <Typography>{t("projectSettingPoints.activitiesText2")}</Typography>
              <span>:</span>
              <input defaultValue="25" />
              <samp>pts.</samp>
            </Box>
            <Box mb={3} className="pcTopicListRow">
              <Typography>{t("projectSettingPoints.activitiesText3")}</Typography>
              <span>:</span>
              <input defaultValue="25" />
              <samp>pts.</samp>
            </Box>
            <Box mb={3} className="pcTopicListRow">
              <Typography>{t("projectSettingPoints.activitiesText4")}</Typography>
              <span>:</span>
              <input defaultValue="25" />
              <samp>pts.</samp>
            </Box>
            <Box mb={3} className="pcTopicListRow">
              <Typography>{t("projectSettingPoints.activitiesText5")}</Typography>
              <span>:</span>
              <input defaultValue="25" />
              <samp>pts.</samp>
            </Box>
          </Box>
        </Box>



        <Typography className='lConfigHeader'>{t("projectSettingPoints.levelConfigurationHead")}</Typography>
        <Typography>{t("projectSettingPoints.levelConfigurationText")}</Typography>

        <Box className="configRow">
          <img src={teraIcon} alt="" />
          <Typography>{t("commonTxt.tera")}</Typography>
          <Box>
            <span>1500 {t("commonTxt.points")}</span>
          </Box>
        </Box>

        <Box className="configRow">
          <img src={gigaIcon} alt="" />
          <Typography>{t("commonTxt.giga")}</Typography>
          <Box>
            <span>1000 {t("commonTxt.points")}</span>
          </Box>
        </Box>

        <Box className="configRow">
          <img src={megaIcon} alt="" />
          <Typography>{t("commonTxt.mega")}</Typography>
          <Box>
            <span>500 {t("commonTxt.points")}</span>
          </Box>
        </Box>
        <Box className="workPupBtnBox">
          <AppBtnOutLine buttonText={t("commonTxt.resetBtn")} /> <AppBtn buttonText={t("commonTxt.saveBtn")} />
        </Box>

      </Box>

    </>
  )
}
