import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";
//images
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";

//components
import CustomGroupPop from "../CustomGroupPop";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";

export default function BroadcastPop({
  directMessageNavBroadCastPop,
  setDirectMessageNavBroadCastPop,
  setCustomGroupPop,
}) {
  const [workSpaceDrop, setWorkSpaceDrop] = useState(false);
  const [workSpaceDropVal, setWorkSpaceDropVal] = useState();

  const [recipientDrop, setRecipientDrop] = useState(false);
  const [recipientDropVal, setRecipientDropVal] = useState();

  const isChecked = false;
  const recipientDropValues = [
    "All users in the workspace",
    "All participants of X workspace",
    "All researchers of X workspace",
    "All collaborators of X workspace",
    "All observers of X workspace",
    "Custom",
  ];
  const WorkspaceDropValue = [
    "Richard’s Workspace",
    "Abc Ltd.’s Workspace",
    "Lucy’s Workspace",
    "Lorem’s Workspace",
  ];

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setDirectMessageNavBroadCastPop(false);
    }
  };

  const chackBoxRoom = () => {
    return (
      <Box className="checkBoxItem">
        <Box>
          <input
            type="checkBox"
            defaultChecked={recipientDropVal === "Custom" ? false : true}
          />
          <Typography>Northeast Lorem</Typography>
        </Box>
        <Box>
          <input
            type="checkBox"
            defaultChecked={recipientDropVal === "Custom" ? false : true}
          />
          <Typography>Southwest Ipsum</Typography>
        </Box>
        <Box>
          <input
            type="checkBox"
            defaultChecked={recipientDropVal === "Custom" ? false : true}
          />
          <Typography>Northeast Lorem</Typography>
        </Box>
      </Box>
    );
  };

  const PropertyCheck = ({ title }) => {
    return (
      <Box mt={2} sx={{ width: "100%" }}>
        <Typography mb={1} className="workspaceLabel">
          {title}
        </Typography>
        <Box className="inputBox">
          <input
            className="workspaceInput categoryInput"
            placeholder={`Select ${title}`}
          />
          <img className="downArrow" src={DownArrowIcon} />
          <Box className="categoryDrop"></Box>
        </Box>
        <Box className="ckeckBoxRoom">
          {chackBoxRoom()}
          {chackBoxRoom()}
          {chackBoxRoom()}
        </Box>
        <Box className="propertyPagination">
          <Box>
            <p>1</p>
          </Box>
          <Box>
            <p>2</p>
          </Box>
          <Box>
            <p>3</p>
          </Box>
          <Box>
            <p>4</p>
          </Box>
          <Box>
            <p>...</p>
          </Box>
          <Box>
            <p>8</p>
          </Box>
          <Box>
            <p>9</p>
          </Box>
          <Box>
            <p>10</p>
          </Box>
        </Box>
      </Box>
    );
  };

  const handelNextPop = () => {
    if (recipientDropVal === "Custom") {
    } else {
      setCustomGroupPop(true);
      setDirectMessageNavBroadCastPop(false);
    }
  };

  useEffect(() => {
    if (directMessageNavBroadCastPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [directMessageNavBroadCastPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: directMessageNavBroadCastPop ? "flex" : "none" }}
        className="workspacePop"
      >
        <Box className="workspaceContaint broadcastPop">
          <Typography className="workspaceHeader">Message Broadcast</Typography>
          <Box className="basicBox">
            <Box>
              <Typography mb={1} className="workspaceLabel">
                {" "}
                Workspace
              </Typography>
              <Box className="inputBox">
                <input
                  onClick={() => setWorkSpaceDrop(!workSpaceDrop)}
                  value={workSpaceDropVal}
                  className="workspaceInput categoryInput"
                  placeholder="Select Workspace"
                />
                <img
                  style={{ rotate: workSpaceDrop ? "180deg" : "0deg" }}
                  onClick={() => setWorkSpaceDrop(!workSpaceDrop)}
                  className="downArrow"
                  src={DownArrowIcon}
                />
                <Box
                  sx={{ height: workSpaceDrop ? "auto" : "0px" }}
                  className="categoryDrop"
                >
                  {WorkspaceDropValue?.map((el, index) => (
                    <Box
                      key={index}
                      onClick={() => {
                        setWorkSpaceDropVal(el);
                        setWorkSpaceDrop(false);
                      }}
                      className="categoryDropItem"
                    >
                      <Typography>{el}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>

            <Box>
              <Typography mb={1} className="workspaceLabel">
                Group
              </Typography>
              <Box className="inputBox">
                <input
                  onClick={() => setRecipientDrop(!recipientDrop)}
                  value={recipientDropVal}
                  className="workspaceInput categoryInput"
                  placeholder="Select Group"
                />
                <img
                  style={{ rotate: recipientDrop ? "180deg" : "0deg" }}
                  onClick={() => setRecipientDrop(!recipientDrop)}
                  className="downArrow"
                  src={DownArrowIcon}
                />
                <Box
                  sx={{ height: recipientDrop ? "auto" : "0px" }}
                  className="categoryDrop"
                >
                  {recipientDropValues?.map((el, index) => (
                    <Box
                      key={index}
                      onClick={() => {
                        setRecipientDropVal(el);
                        setRecipientDrop(false);
                      }}
                      className="categoryDropItem"
                    >
                      <Typography>{el}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Typography className="bdctSubHeaderText">Filter</Typography>
          {!recipientDropVal ? (
            <Typography mt={1}>
              Select the Workspace and Recipient to select users
            </Typography>
          ) : null}

          <Box className="allPropertybox">
            <Box className="allPropertyItem">
              {recipientDropVal === "All researchers of X workspace" ? (
                <PropertyCheck title="Researcher" />
              ) : null}
              {recipientDropVal === "All collaborators of X workspace" ? (
                <PropertyCheck title="Collaborator" />
              ) : null}
              {recipientDropVal === "All observers of X workspace" ? (
                <PropertyCheck title="Observer" />
              ) : null}
              {recipientDropVal === "All participants of X workspace" ? (
                <PropertyCheck title="participants" />
              ) : null}
              {recipientDropVal === "All users in the workspace" ? (
                <>
                  <PropertyCheck title="participants" />
                  <PropertyCheck title="Researcher" />
                  <PropertyCheck title="Collaborator" />
                  <PropertyCheck title="Observer" />
                </>
              ) : null}
              {recipientDropVal === "Custom" ? (
                <>
                  <PropertyCheck title="participants" />
                  <PropertyCheck title="Researcher" />
                  <PropertyCheck title="Collaborator" />
                  <PropertyCheck title="Observer" />
                </>
              ) : null}
            </Box>
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine
              buttonText="Cancel"
              onClick={() => setDirectMessageNavBroadCastPop(false)}
            />
            <AppBtn
              buttonText="Compose Broadcast"
              disabled={recipientDropVal ? false : true}
              onClick={() => handelNextPop()}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
