import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

//images
import backArrow from "../../../Assets/Images/noun-back.png";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import axios from "../../../lib/axios";
//DATA
import { fieldItem } from "../../../Assets/Data";
import { API_BASE_URL } from "../../../env";

//components
import { AppBtnOutLine, AppBtn } from "../../AppTools/AppButton";
import { useAppSelector } from "../../../lib/store/store";
import { updateCustomField } from "../../../Services/researcherActions";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { RenderCustomField } from "../../AppTools/AppTool";

export default function SettingProfileField({
  setProfileFieldSetting,
  profileFieldId,
  setProfileFieldId,
  setReloadTable,
}) {
  const [filterDrop, setFilterDrop] = useState(false);
  const [filterDropVal, setFilterDropVal] = useState();
  const [profileField, setProfileField] = useState({
    question: "",
    field_name: "",
    field_type: "",
    type: "",
    screening_projects: [],
    choices: [],
  });
  const authState = useAppSelector((state) => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace);

  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);

  useEffect(() => {
    getCustomFields();
    getProjectLists();
  }, [setProfileFieldSetting]);
  const getCustomFields = async () => {
    try {
      let url = `/participant/profile-fields?_id=${profileFieldId}`;
      const res = await axios.get(url);
      if (res.success) {
        const fld = res?.data?.data[0];
        setProfileField({
          ...profileField,
          question: fld.question,
          field_name: fld.field_name,
          field_type: fld.field_type,
          type: fld.type,
          choices: fld.choices,
          screening_projects: fld?.screening_projects || [],
          allow_multiple_choice_check:fld.allow_multiple_choice_check
        });
        const type = fieldItem.findIndex(
          (el, i) => el.qstring.replaceAll("_", "-") == res?.data?.data[0].field_type
        );
        setFilterDropVal(type);
      }
    } catch (error) { }
  };

  const validationObj = {
    hasErr: false,
    question: {
      error: false,
      msg: "",
    },
  };

  const [validationErrors, setValidationErrors] = useState({
    ...validationObj,
  });

  const handleValidation = (formInput) => {
    formInput = formInput || profileField;
    let validationerr = { ...validationObj };

    if (formInput.question == "") {
      validationerr.hasErr = true;
      validationerr.name = {
        error: true,
        msg: "Question is required",
      };
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  };

  // ProjectDropDown

  const getProjectLists = async () => {
    try {
      const res = await axios.get(API_BASE_URL + "/researchers/projects");
      if (res.status) {
        setProjects(res.data.data);
      }
    } catch (error) {
      console.error("Error fetching project lists", error);
    }
  };

  const handleQualifyingProjects = (event, i) => {
    const selectedValue = Array.isArray(event.target.value) ? event.target.value : [event.target.value];
    const allIds = profileField.choices[i].qualifying_projects || [];
    const concatArr = Array.from(new Set([...allIds, ...selectedValue]));
    const updatedChoices = profileField.choices.map((choice, index) =>
      index === i ? { ...choice, qualifying_projects: concatArr } : choice
    );
    setProfileField({ ...profileField, choices: updatedChoices });
  };



  const handleChange = (e) => {
    console.log(e.target.value);
    const selProjects = e.target.value || [];
    setProfileField({ ...profileField, screening_projects: selProjects });
    const selectedPro = projects.filter(el => selProjects.includes(el._id));
    setSelectedProjects(selectedPro);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "200px",
        overflowY: "auto",
      },
    },
  };

  const settingCustomFiled = async () => {
    if (!handleValidation()) {
      toast.error("Please correct form fields", TOAST_CONFIG);
      return false;
    }
    const payload = {
      ...profileField,
      choices: profileField.choices.map(({ _id, ...rest }) => rest),
      workspace_id: workspaceState?.selectedWorkspace?._id,
    };

    try {
      const companyRes = await updateCustomField(
        profileFieldId,
        payload,
        authState.authToken
      );
      // setLoading(false);
      if (companyRes.success) {
        toast.success(companyRes.message, TOAST_CONFIG);
        setProfileFieldId("");
        setProfileFieldSetting(false);
        setReloadTable(true);
      } else {
        toast.error(companyRes.message, TOAST_CONFIG);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      // setLoading(false);
    }
  };

  return (
    <>
      <Box className="addProfileFieldBox workspaceListBox">
        <Box
          mb={2}
          onClick={() => setProfileFieldSetting(false)}
          className="openBtn"
        >
          <img src={backArrow} />
          <Typography className="neonBackText">Back</Typography>
        </Box>
        <Typography className="pageHeaderText">
          Settings Profile Field
        </Typography>

        <Box mt={5} className="csPBox">
          <Typography className="allPartRedioLabel">Profile Field</Typography>
        </Box>

        <Box mt={4} className="searchbox">
          <Box mr={2} width={"20%"}>
            <Box
              className="inputAppDrop"
              onClick={() => setFilterDrop(!filterDrop)}
            >
              <Box className="fieldValBox">
                {fieldItem[filterDropVal]?.icon}
                <Typography>
                  {fieldItem[filterDropVal]?.field || "# Number"}
                </Typography>
              </Box>
              <img
                style={{
                  rotate: filterDrop ? "180deg" : "0deg",
                }}
                src={DownArrowIcon}
              />
              {/* pop */}
              <Box
                sx={{ height: filterDrop ? "auto" : "0px" }}
                className="statusPop"
              >
                {fieldItem &&
                  fieldItem?.map((el, index) => (
                    <Box
                      key={index}
                      onClick={() => {
                        setFilterDropVal(index);
                        setProfileField({
                          ...profileField,
                          field_type: el.qstring.replaceAll("_", "-"),
                        });
                      }}
                      className="statusPopItem"
                    >
                      {el.icon}
                      <Typography>{el.field}</Typography>
                    </Box>
                  ))}
              </Box>
            </Box>
          </Box>
          <Box width={"80%"}>
            <input
              className="phoneInput"
              value={profileField.question}
              placeholder="Enter your question here"
              name="question"
              onChange={(e) =>
                setProfileField({ ...profileField, question: e.target.value })
              }
            />
            <span className="validationerr">
              {validationErrors.question?.msg}
            </span>
          </Box>
        </Box>

        <Box mt={5} className="csPBox">
          <Typography className="allPartRedioLabel">
            Set As Screening Question
          </Typography>
        </Box>
        <Box className="ckeckBoxRoom" mb={3} mt={2}>
          <FormControl sx={{ width: "100%" }} size="medium">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={profileField.screening_projects}
              onChange={handleChange}
              name="screening_projects"
              multiple
              MenuProps={MenuProps}
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return (
                    <Typography>Select Projects</Typography>
                  );
                }
                return selected
                  .map((value) => {
                    const projectList = projects.find(
                      (proj) => proj._id === value
                    );
                    return projectList ? projectList.name : value;
                  })
                  .join(", ");
              }}
            >
              {projects.map((proj, index) => (
                <MenuItem value={proj._id} key={index}>
                  {proj.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {profileField?.field_type ==="poll" && <Box>
          <Typography className="allPartRedioLabel">
            Answer Options
          </Typography>
          {profileField?.choices &&
            profileField?.choices.length > 0 &&
            profileField?.choices.map((el, i) => (
              <>
                <Box className="radioBox">
                  <Box>
                    {profileField?.allow_multiple_choice_check ? (
                      <>
                        <input
                          className="emailSettingInput emailSettingRadio"
                          type="checkbox"
                          name="activity"
                          checked={profileField.choices[i]?.qualifying_projects?.length > 0}
                        />
                      </>
                    ) : (
                      <input
                        className="emailSettingInput emailSettingRadio"
                        type="radio"
                        name="activity"
                        checked={profileField.choices[i]?.qualifying_projects?.length > 0}
                      />
                    )}
                  </Box>
                  <Box>
                    <Typography className="emailSettingLabel">
                      {el.name}
                    </Typography>
                  </Box>
                </Box>
                <Box md={12}>
                  <FormControl sx={{ width: "100%" }} size="medium">
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={profileField.choices[i]?.qualifying_projects || []}
                      onChange={(e) => handleQualifyingProjects(e, i)}
                      name="qualifying_projects"
                      multiple
                      MenuProps={MenuProps}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <Typography>Select Projects</Typography>
                          );
                        }
                        return selected
                          .map((value) => {
                            const projectList = projects.find(
                              (proj) => proj._id === value
                            );
                            return projectList ? projectList.name : value;
                          })
                          .join(", ");
                      }}
                    >
                      {selectedProjects.map((proj, index) => (
                        <MenuItem value={proj._id} key={index}>
                          {proj.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </>
            ))}
        </Box>}

        <Box mt={5} className="csPBox">
          <Typography className="allPartRedioLabel">Show On</Typography>
        </Box>

        <Box className="radioBox">
          <Box>
            <input
              className="emailSettingInput emailSettingRadio"
              type="radio"
              name="type"
              checked={profileField.type == "registration"}
              onClick={(e) =>
                setProfileField({
                  ...profileField,
                  type: e.target.checked ? "registration" : null,
                })
              }
            />
          </Box>
          <Box>
            <Typography className="emailSettingLabel">
              Registration Form
            </Typography>
          </Box>
        </Box>
        <Box className="radioBox">
          <Box>
            <input
              className="emailSettingInput emailSettingRadio"
              type="radio"
              name="type"
              checked={profileField.type == "my_account"}
              onClick={(e) =>
                setProfileField({
                  ...profileField,
                  type: e.target.checked ? "my_account" : null,
                })
              }
            />
          </Box>
          <Box>
            <Typography className="emailSettingLabel">
              Account Settings
            </Typography>
          </Box>
        </Box>
        <Box className="radioBox">
          <Box>
            <input
              className="emailSettingInput emailSettingRadio"
              type="radio"
              name="type"
              checked={profileField.type == "in_projct"}
              onClick={(e) =>
                setProfileField({
                  ...profileField,
                  type: e.target.checked ? "in_projct" : null,
                })
              }
            />
          </Box>
          <Box>
            <Typography className="emailSettingLabel">
              Project Settings
            </Typography>
          </Box>
        </Box>
        <Box className="radioBox">
          <Box>
            <input
              className="emailSettingInput emailSettingRadio"
              type="radio"
              name="type"
              checked={profileField.type == "hidden"}
              onClick={(e) =>
                setProfileField({
                  ...profileField,
                  type: e.target.checked ? "hidden" : null,
                })
              }
            />
          </Box>
          <Box>
            <Typography className="emailSettingLabel">Hidden</Typography>
          </Box>
        </Box>

        <Box className="workPupBtnBox">
          <AppBtnOutLine
            buttonText="Cancel"
            onClick={() => setProfileFieldSetting(false)}
          />
          <AppBtn buttonText="Save" onClick={settingCustomFiled} />
        </Box>
      </Box>
    </>
  );
}
