import { Box } from "@mui/material";
import { Sidebar } from "./Sidebar";
import { sideHaumIcon } from "../../../Assets/Data";
import { useState } from "react";
import { USER_TYPE_SUPERADMIN } from "../../../lib/constants";
import useAuth from "../../../hooks/useAuth";

const AdminLayout = ({ childComponent, actionBtn }) => {
  
  const [slideMenu, setSlideMenu] = useState(true);

  useAuth([USER_TYPE_SUPERADMIN], USER_TYPE_SUPERADMIN);

  return (
    <Box className="AdminHomeContainer">
      {/* side menu */}
      <Sidebar slideMenu={slideMenu} />
      <Box className="adminMainSection">
        <Box className="adminNavBar">
          <Box
            onClick={() => setSlideMenu(!slideMenu)}
            sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
            className="sideHaumIcon"
          >
            {sideHaumIcon}
          </Box>
          {actionBtn}
        </Box>
        {/* Page container */}
        {childComponent}
      </Box>
    </Box>
  );
};

export default AdminLayout;
