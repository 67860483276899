import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";

//images
import backArrow from "../../../Assets/Images/noun-back.png";
import iIcon2 from "../../../Assets/Images/IIcon.png";
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png";
import trash from "../../../Assets/Images/redTrash.png"
import rankImg from "../../../Assets/Images/rankCard.png"

//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";


export default function RenkItSortItPop({ renkItSortIt, setRenkItSortIt, handelAddTaskPopBack }) {
  const [image, setImage] = useState(null);
  const [chooseCount, setChooseCount] = useState(1)
  const [chooseCount2, setChooseCount2] = useState(1)


  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };


  const RankCard = ({ count }) => {
    return (
      <Box mt={2} className="rankCard">
        <Box className="rankTextBox">
          <Typography>Card {count * 1 + 1} Label</Typography>
          <input className="phoneInput" placeholder="Enter card label" />
          <img className="trash" src={trash} onClick={handelDeleteAttribute} />
          <Typography className="rankCardUnderl">Change Image <span>Remove Image</span></Typography>
        </Box>
        <Box className="rankImg">
          <img src={rankImg} />
        </Box>
      </Box>
    )
  }



  const renderComponents = (value) => {
    let components = [];
    for (let i = 0; i < value; i++) {
      components.push(<RankCard count={i} key={i} />);
    }
    return components;
  };


  const handelDeleteAttribute = () => {
    setChooseCount(chooseCount - 1)
  }
  const handelAddAttribute = () => {
    setChooseCount(chooseCount + 1)
  }


  const CategoryLCard = ({ count }) => {
    return (
      <Box my={1}>
        <Box my={3} sx={{ width: "100%", position: "relative" }}>
          <Typography>Category {count * 1 + 1} Label</Typography>
          <input style={{ width: "100%", marginTop: "8px" }} className="phoneInput" placeholder="Absolutely Needed" />
          <img className="trash2" src={trash} onClick={handelDeleteAttribute2} />
        </Box>
        <Box mb={1} className="tagInputBOx">
          <input
            style={{ marginRight: "10px" }}
            className="custom-checkbox"
            type="checkBox" />
          <Typography>Enforce card limit</Typography>
        </Box>

        <Box className="limitBox nLimitebox">
          <input className="phoneInput" value={"01"} />
          <Typography>to</Typography>
          <input className="phoneInput" value={"04"} />
          <Typography>cards</Typography>
        </Box>

        <Box my={3} sx={{ width: "100%" }}>
          <Box mb={1} className="tagInputBOx"><input
            style={{ marginRight: "10px" }}
            className="custom-checkbox"
            type="checkBox"
          />
            <Typography>Customize prompt</Typography>
          </Box>
          <input style={{ marginLeft: "30px", width: "96%" }} className="phoneInput" placeholder="This text will appear just below the group label" />
        </Box>
      </Box>
    )
  }


  const renderComponents2 = (value) => {
    let components = [];
    for (let i = 0; i < value; i++) {
      components.push(<CategoryLCard count={i} key={i} />);
    }
    return components;
  };


  const handelDeleteAttribute2 = () => {
    setChooseCount2(chooseCount2 - 1)
  }
  const handelAddAttribute2 = () => {
    setChooseCount2(chooseCount2 + 1)
  }


  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setRenkItSortIt(false);
    }
  };

  useEffect(() => {
    if (renkItSortIt) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [renkItSortIt]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: renkItSortIt ? "flex" : "none" }}
        className="companyPop reviewImages renkItSortItPop"
      >
        <Box
          sx={{ display: renkItSortIt ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Box onClick={handelAddTaskPopBack} className="openBtn taskBackBtn">
            <img src={backArrow} />
            <Typography className="neonBackText">Back</Typography>
          </Box>
          <Typography className="taskPopMainLabel">Rank-it & Sort-it Task</Typography>
          <Box className="iBox">
            <img src={iIcon2} />
            <Typography>Display labeled cards to be placed into named groups and then ranked via drag-and-drop.</Typography>
          </Box>

          <Box className="basicBoxItem">
            <Typography className='eTemPopLabel '>Task Name</Typography>
            <Box className="pickerBox cardTitleLabel">
              <input className='eTemInput' placeholder='Type a name for your topic' />
            </Box>
          </Box>

          <Box className="QaInstruction">
            <Typography className='eTemPopLabel '>Question or Instructions</Typography>
            <textarea placeholder="Type the question/instructions to be visible" />
          </Box>


          <Box className='mediaInputbox upload-area' onClick={() => {
            document.getElementById("fileInput").click();
          }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}>
            <img src={uploadIcon} />
            <Typography>Click or drag media files to this area to upload</Typography>
            <input
              id="fileInput"
              style={{ display: "none" }}
              type="file"
              onChange={handleFileInputChange}
            />
          </Box>

          <Typography className='eTemPopLabel'>Card Settings</Typography>
          {renderComponents(chooseCount)}

          <Box className="addCardLinkBOx">
            <Typography className="rankCardUnderl addCardLink" onClick={handelAddAttribute}>Add Card</Typography>
            <Typography className="rankCardUnderl addCardLink">Paste Bulk Card</Typography>
          </Box>


          <Typography className='eTemPopLabel'>Category Settings</Typography>
          {renderComponents2(chooseCount2)}

          <Typography className="rankCardUnderl addCardLink catagoryAddLink" onClick={handelAddAttribute2}>Add Category</Typography>




          <Typography className='eTemPopLabel'>Task Settings</Typography>
          <Box mt={2} className="tagInputBOx">
            <input
              style={{ marginRight: "10px" }}
              className="custom-checkbox"
              type="checkBox"
            />
            <Typography>Randomize Card Order</Typography>
          </Box>

          <Box mt={2} className="tagInputBOx">
            <input
              style={{ marginRight: "10px" }}
              className="custom-checkbox"
              type="checkBox"
            />
            <Typography>Make ranking amongst categories compulsory</Typography>
          </Box>
          <Box mb={4} mt={2} className="tagInputBOx">
            <input
              style={{ marginRight: "10px" }}
              className="custom-checkbox"
              type="checkBox"
            />
            <Typography>Response Required</Typography>
          </Box>






          <Typography className='eTemPopLabel'>Advance Settings</Typography>
          <Box mt={2} className="tagInputBOx">
            <input
              style={{ marginRight: "10px" }}
              className="custom-checkbox"
              type="checkBox"
            />
            <Typography>Limit who can complete this task</Typography>
          </Box>

          <Box mt={2} className="tagInputBOx">
            <input
              style={{ marginRight: "10px" }}
              className="custom-checkbox"
              type="checkBox"
            />
            <Typography>Customize points awarded for task completetion </Typography>
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={() => setRenkItSortIt(false)} />
            <AppBtnOutLine buttonText="Save & Preview" />
            <AppBtn buttonText="Save Task" />
          </Box>
        </Box>
      </Box>
    </>
  );
}
