import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.css";

//images
import iIcon from "../../../Assets/Images/IIcon.png";

//svg icons
import {
  TIcon,
  imageIcon,
  videoIcon,
  micIcon,
  editIcon2,
  pollIcon,
  onTheDotIcon,
  rankItShortIcon,
  matrixIcon,
  screenShortIcon,
  anyFileIcon,
} from "../../../Assets/Data";

//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";

export default function AddTaskPop({
  allDropVal4,
  setAllDropVal4,
  selectedTask,
  setSelectedTask,
  handelAddTaskPopCoutinue,
}) {
  const Navigate = useNavigate();
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const ActivityCard = ({ icon, title, subText }) => {
    return (
      <Box
        onClick={() => setSelectedTask(title)}
        className={
          selectedTask === title
            ? "ActivityTypeItem ActivityTypeItemClicked"
            : "ActivityTypeItem"
        }
      >
        <Box className="acTyTitleBox">
          <Typography>{title}</Typography>
          <Box
            className={
              selectedTask === title ? "acIconBox acIconActiveBox" : "acIconBox"
            }
          >
            {icon}
          </Box>
        </Box>
        <span>{subText}</span>
      </Box>
    );
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setAllDropVal4("Activity Options");
    }
  };

  useEffect(() => {
    if (allDropVal4 === "Add Tasks") {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [allDropVal4]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: allDropVal4 === "Add Tasks" ? "flex" : "none" }}
        className="companyPop addActivitiesPop"
      >
        <Box
          sx={{ display: allDropVal4 === "Add Tasks" ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Box>
            <Typography className="taskPopMainLabel">
              {t("addTaskPop.taskType")}
            </Typography>

            <Box className="iBox">
              <img src={iIcon} />
              <Typography>
              {t("addTaskPop.addTaskNotify")}
              </Typography>
            </Box>

            <Box className="ActivityTypeBox">
              {ActivityCard({
                icon: TIcon,
                title: "Text Task",
                subText:
                  "Capture open-ended text responses with optional word limit, formatting and images.",
              })}
              {ActivityCard({
                icon: imageIcon,
                title: "Image Upload Task",
                subText:
                  "Get feedback about an image with mark-up, comments and annotation on various locations.",
              })}
              {ActivityCard({
                icon: videoIcon,
                title: "Video Upload Task",
                subText:
                  "Get participants to upload a video with open-ended responses and optional captions, emotions and comments.",
              })}
              {ActivityCard({
                icon: micIcon,
                title: "Audio Upload Task",
                subText:
                  "Get participants to upload an audio with open-ended responses and optional captions, emotions and comments.",
              })}
              {ActivityCard({
                icon: editIcon2,
                title: "Fill It Out Task",
                subText:
                  "Use a custom form with polls, scales, photo and text to get responses to questions.",
              })}
              {ActivityCard({
                // icon: gelleryIcon2,
                title: "Mixed Media Upload Task",
                subText:
                  "Get participants to upload a mix of video, image and audio with open-ended responses and optional captions, emotions and comments. Ideal for blog style journals.",
              })}
              {ActivityCard({
                // icon: imgIcon2,
                title: "Review an Image Task",
                subText:
                  "Mark-up and annotate precise locations/spots of interest on any image.",
              })}
              {ActivityCard({
                // icon: AddAc4,
                title: "Review a Video Task",
                subText:
                  "Capture responses about a video with tags and comments categorised as it plays.",
              })}
              {ActivityCard({
                // icon: AddAc4,
                title: "Review an Audio Task",
                subText:
                  "Capture responses about an audio with tags and comments categorised as it plays.",
              })}
              {ActivityCard({
                icon: pollIcon,
                title: "Poll & Survey Task",
                subText:
                  "Get participants responses to a list of options including multiple choices and selection.",
              })}
              {ActivityCard({
                icon: onTheDotIcon,
                title: "On-the-Dot Task",
                subText:
                  "Use a range of prompts, URLs (external or internal), messages, discussions and tasks navigations to capture responses.",
              })}
              {ActivityCard({
                icon: rankItShortIcon,
                title: "Rank-it & Sort-it Task",
                subText:
                  "Get participants responses via ranking and sorting techniques or display well-labelled cards that can be categorised.",
              })}
              {ActivityCard({
                icon: matrixIcon,
                title: "Matrix Task",
                subText:
                  "Use a grid-like/tabular structure to present and capture participants' responses.",
              })}
              {ActivityCard({
                icon: screenShortIcon,
                title: "Screen Capture Task",
                subText:
                  "Participants can record their screen and add audio/textual commentary.",
              })}
              {ActivityCard({
                icon: anyFileIcon,
                title: "Any File Upload Task",
                subText:
                  "Have participants upload any file type. File type, template, size and structure can be specified and enforced.",
              })}
            </Box>
            <Box className="workPupBtnBox">
              <AppBtnOutLine
                buttonText={t("commonTxt.cancelBtn")}
                onClick={() => setAllDropVal4("Activity Options")}
              />
              <AppBtn
                buttonText={t("commonTxt.continueBtn")}
                onClick={handelAddTaskPopCoutinue}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
