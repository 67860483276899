import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { useTranslation } from "react-i18next";
//images
import iIcon2 from "../../../Assets/Images/IIcon.png";
import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png";
import backArrow from "../../../Assets/Images/noun-back.png";
import xCircel from "../../../Assets/Images/x-circle.png";
import redTrash from "../../../Assets/Images/redTrash.png";
import groupsIcon from "../../../Assets/Images/groupsIcon.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";


export default function AddPollSurveyTaskPop({ setAllDropVal4, addPollSurveyTaskPop, setAddPollSurveyTaskPop, handelAddTaskPopBack }) {

  const email1 = "Type the question/instructions to be visible"

  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();
  const [image, setImage] = useState(null);
  const [checkTaskSettingCount, setCheckTaskSettingCount] = useState(true);

  const [chooseCount, setChooseCount] = useState(1);
  const [groupDrop, setGroupDrop] = useState(false);
  const [groupDrop2, setGroupDrop2] = useState(false);
  const [redio, setRedio] = useState(0);
  const [checkTaskSettingPrompt, setCheckTaskSettingPrompt] = useState(true);
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
  };

  const chackBoxRoom = () => {
    return (
      <Box className="checkBoxItem">
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            checked={redio === 0 ? true : false}
          />
          <Typography>Group 0</Typography>
        </Box>
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            checked={redio === 0 ? true : false}
          />
          <Typography>Group 0</Typography>
        </Box>
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            checked={redio === 0 ? true : false}
          />
          <Typography>Group 0</Typography>
        </Box>
      </Box>
    );
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
        setAddPollSurveyTaskPop(false);
    }
  };

  const handelCancelPop = () => {
    setAddPollSurveyTaskPop(false);
    setAllDropVal4("Add Tasks");
  }

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const ChooseSection = ({ count }) => {
    return (
      <>
        <p style={{ marginTop: "20px", marginBottom: "10px" }}>Choice {count * 1 + 1}</p>
        <Box className="choiceInputBox">
          <input className="phoneInput" placeholder="Enter choice 1" />
          <img className="xCircel" src={xCircel} />
          <img className="redTrash" src={redTrash} onClick={handelDeleteAttribute} />
        </Box>

        <Box className="searchGroupSection">
        <Typography>Add to Group(s)</Typography>
          <Box
            pl={4.5}
            className="inputAppDrop"
            onClick={() => setGroupDrop(!groupDrop)}
          >
            <img className="groupsIcon" src={groupsIcon} />
            <Typography>Search Groups</Typography>
            <img
              style={{ rotate: groupDrop ? "180deg" : "0deg" }}
              src={dropArrow}
            />
          </Box>
          <Box
            sx={{ height: groupDrop ? "auto" : "0px" }}
            className="checkMain_box"
          >
            <Box className="ckeckBoxRoom">
              {chackBoxRoom()}
              {chackBoxRoom()}
              {chackBoxRoom()}
            </Box>
            <Box className="propertyPagination">
              <Box>
                <p>1</p>
              </Box>
              <Box>
                <p>2</p>
              </Box>
              <Box>
                <p>3</p>
              </Box>
              <Box>
                <p>4</p>
              </Box>
              <Box>
                <p>...</p>
              </Box>
              <Box>
                <p>8</p>
              </Box>
              <Box>
                <p>9</p>
              </Box>
              <Box>
                <p>10</p>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="searchGroupSection">
        <Typography>Remove from Group(s)</Typography>
          <Box
            pl={4.5}
            className="inputAppDrop"
            onClick={() => setGroupDrop2(!groupDrop2)}
          >
            <img className="groupsIcon" src={groupsIcon} />
            <Typography>Search Groups</Typography>
            <img
              style={{ rotate: groupDrop2 ? "180deg" : "0deg" }}
              src={dropArrow}
            />
          </Box>
          <Box
            sx={{ height: groupDrop2 ? "auto" : "0px" }}
            className="checkMain_box"
          >
            <Box className="ckeckBoxRoom">
              {chackBoxRoom()}
              {chackBoxRoom()}
              {chackBoxRoom()}
            </Box>
            <Box className="propertyPagination">
              <Box>
                <p>1</p>
              </Box>
              <Box>
                <p>2</p>
              </Box>
              <Box>
                <p>3</p>
              </Box>
              <Box>
                <p>4</p>
              </Box>
              <Box>
                <p>...</p>
              </Box>
              <Box>
                <p>8</p>
              </Box>
              <Box>
                <p>9</p>
              </Box>
              <Box>
                <p>10</p>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  }


  const renderComponents = (value) => {
    let components = [];
    for (let i = 0; i < value; i++) {
      components.push(<ChooseSection count={i} key={i} />);
    }
    return components;
  };


  const handelDeleteAttribute = () => {
    setChooseCount(chooseCount - 1)
  }
  const handelAddAttribute = () => {
    setChooseCount(chooseCount + 1)
  }


  useEffect(() => {
    if (addPollSurveyTaskPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [addPollSurveyTaskPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: addPollSurveyTaskPop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople "
      >
        <Box
          sx={{ display: addPollSurveyTaskPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Box onClick={handelAddTaskPopBack} className="openBtn taskBackBtn">
            <img src={backArrow} />
            <Typography className="neonBackText">{t("commonTxt.backBtn")}</Typography>
            </Box>

          <Typography className="taskPopMainLabel">{t("pollSurveyTask.pollSurveyTaskHead")}</Typography>
          <Box className="iBox">
              <img src={iIcon2} />
              <Typography>
              {t("pollSurveyTask.pollSurveyTaskNotify")}
              </Typography>
            </Box>
          <Box className="basicBoxItem">
            <Typography className='eTemPopLabel '>{t("addTextTaskPop.taskNameLabel")}</Typography>
            <Box className="pickerBox cardTitleLabel">
              <input className='eTemInput' placeholder={t("addTextTaskPop.taskNamePlaceholder")} />
            </Box>
          </Box>

          <Typography className='eTemPopLabel '>{t("LiveInterview.questionLabel")}</Typography>
          <Box className="emailInputBox textTaskInstruction">
            <Box className="emailInputBoxNav">
              <Box
                onClick={() => setMessageTextItalic(!messageTextItalic)}
                className="emailImgBox"
              >
                <img src={IIcon} />
              </Box>
              <Box
                onClick={() => setMessageTextBold(!messageTextBold)}
                className="emailImgBox"
              >
                <img src={BIcon} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("underline", "message")}
                className="emailImgBox"
              >
                <img src={UnderLineU} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("line-through", "message")}
                className="emailImgBox"
              >
                <img src={MLineU} />
              </Box>
              <Box className="emailImgBox">
                <img src={AtachMentIcon} />
              </Box>
              <Box className="emailImgBox">
                <img src={Qbox} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("start")}
                className="emailImgBox"
              >
                <img src={Line1} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("center")}
                className="emailImgBox"
              >
                <img src={Line2} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("end")}
                className="emailImgBox"
              >
                <img src={Line3} />
              </Box>
              <Box className="emailImgBox">
                <img src={Line4} />
              </Box>
              <Box className="emailImgBox">
                <img src={emoji} />
              </Box>
            </Box>
            <textarea
              style={{
                textAlign: messageTextLaOut,
                fontStyle: messageTextItalic ? "italic" : "normal",
                fontWeight: messageTextBold ? "bold" : "normal",
                textDecoration: messageTextUnderLine,
                // textDecoration: messageTextMLine ? "line-through" : null
              }}
              className="emailInput"
              placeholder={t("addTextTaskPop.taskDescriptionPlaceholder")}
              name="emailMessage"
              value={emailval?.emailMessage || email1}
              onChange={handelChange}
            />
          </Box>

          <Box className="basicBoxItem">
            <Box className='mediaInputbox upload-area taskUoloadImageBox' onClick={() => {
                document.getElementById("fileInput").click();
            }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}>
                <img src={uploadIcon} />
                <Typography>{t("commonTxt.uploadText")}</Typography>
                <input
                id="fileInput"
                style={{ display: "none" }}
                type="file"
                onChange={handleFileInputChange}
                />
            </Box>
          </Box>

        <Typography className="allPartRedioLabel">{t("LiveInterview.choicesHead")}</Typography>
          {renderComponents(chooseCount)}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "24px 0",
            }}
          >
            <Typography className="ppundText" onClick={handelAddAttribute}>{t("FillItOutTaskPop.addChoiceLink")}</Typography>
            <Typography className="ppundText">{t("commonTxt.pasteBulkChoicesLink")}</Typography>
          </Box>

          <Typography className='textTaskSetting '>{t("pollSurveyTask.commentSettingsHead")}</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" onClick={() => setCheckTaskSettingPrompt(!checkTaskSettingPrompt)} defaultChecked />
            <Typography>{t("addTextTaskPop.customizePrompt")}</Typography>
          </Box>
          <Box sx={{ display: checkTaskSettingPrompt ? "flex" : "none" }} className="wsCpInputBOx">
            <input
              type="text"
              className="phoneInput customizePromptInput"
              placeholder={t("addTextTaskPop.customizePromptPlaceholder")}
            />
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" onClick={() => setCheckTaskSettingCount(!checkTaskSettingCount)} defaultChecked />
            <Typography>{t("addTextTaskPop.enforceWordCount")}</Typography>
          </Box>
          <Box sx={{ display: checkTaskSettingCount ? "flex" : "none" }} ml={4} className="enforceInputBox">
            <input defaultValue="05" className="phoneInput" />
            <p>{t("addTextTaskPop.to")}</p>
            <input defaultValue="100" className="phoneInput" />
            <p>{t("recapeVideoReview.tblTags")}</p>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("addTextTaskPop.TextFormatting")}</Typography>
          </Box>
            
          <Typography className='textTaskSetting '>{t("addTextTaskPop.taskSettingsHead")}</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("LiveInterview.pollSettingCheckbox1")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("LiveInterview.pollSettingCheckbox2")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("LiveInterview.pollSettingCheckbox3")}</Typography>
          </Box>

          <Typography className='textTaskSetting '>{t("addTextTaskPop.advanceSettingsHead")}</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("addTextTaskPop.advanceSettingsCheckbox1")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("addTextTaskPop.advanceSettingsCheckbox2")}</Typography>
          </Box>
        

          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={handelCancelPop} />
            <AppBtnOutLine buttonText={t("commonTxt.savePreviewBtn")} onClick={handelAddTaskPopBack} />
            <AppBtn buttonText={t("commonTxt.saveTaskBtn")} />
          </Box>



        </Box>
      </Box>
    </>
  );
}
