import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";

import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import IIcon2 from "../../../Assets/Images/IIcon.png";
import axios from "../../../lib/axios";
//components

import { AppBtnOutLine, AppBtn } from "../../AppTools/AppButton";
import { AntSwitch, MonthStatus } from "../../AppTools/AppTool";
import { researcherAction } from "../../../Services/researcherActions";
import { useAppSelector } from "../../../lib/store/store";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../env";

export default function ViewAgreementPop({
  viewAgreementPop,
  setViewAgreementPop,
  agreementId,
}) {
  console.log(agreementId);
  const [agreement, setAgreement] = useState()
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setViewAgreementPop(false);
    }
  };

  useEffect(() => {
    fetchArg1()
  }, [agreementId])
  const fetchArg1 = async () => {
    try {
      if (!agreementId)
        return false
      const url = `super-admin/admin/agreements?_id=${agreementId}`;
      const response = await axios.get(url);
      const agreement = response?.data?.data[0];
      setAgreement(agreement)
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  }


  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: viewAgreementPop ? "flex" : "none" }}
        className="workspacePop agreementTemPop"
      >
        <Box
          sx={{ display: viewAgreementPop ? "flex" : "none" }}
          className="workspaceContaint emailTemPop"
        >
          <Typography className="pageHeaderText">
            {agreement?.name}
          </Typography>
          <Typography mt={2} className="agreNText">
            {agreement?.page_content}
          </Typography>
          <Box className="workPupBtnBox">
            <AppBtnOutLine
              buttonText="Cancel"
              onClick={() => setViewAgreementPop(false)}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
