import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import ReactFlagsSelect from "react-flags-select";
import "./style.css";

//components
import { AppBtnOutLine, AppBtn } from "../../../Components/AppTools/AppButton"

export default function DefaultLanguagePop({ defaultLanguagePop, setDefaultLanguagePop }) {

    const [selectedLanguage, setSelectedLanguage] = useState("");
  
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
       setDefaultLanguagePop(false);
    }
  };

  useEffect(() => {
    if (defaultLanguagePop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [defaultLanguagePop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: defaultLanguagePop ? "flex" : "none" }}
        className="companyPop liveInterView"
      >
        <Box
          sx={{ display: defaultLanguagePop ? "flex" : "none" }}
          className="workspaceContaint defaultLanguageContaint"
        >
          <Typography className="eTemPopLabel">Change Default Language</Typography>
          
          <Typography className="defaultLanguageLabel">Default Language</Typography>
          <ReactFlagsSelect
              className="SelectLanguage"
              selected={selectedLanguage}
              onSelect={(code) => setSelectedLanguage(code)}
              placeholder="Select a Language"
            />

          <Box className="workPupBtnBox defaultLanguageBtn">
            <AppBtnOutLine buttonText="Cancel" onClick={() => setDefaultLanguagePop(false)} />
            <AppBtn buttonText="Save" />
          </Box>
        </Box>
      </Box>
    </>
  );
}
