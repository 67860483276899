import React, { useState } from 'react';
import { Box, Typography } from "@mui/material";
import "./style.css";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";


//images
import plassIcon from "../../../Assets/Images/plassIcon.png"
import backArrow from "../../../Assets/Images/noun-back.png";


//component
import SideMenu from "../../../Components/Participants/SideMenu";
import { sideHaumIcon } from "../../../Assets/Data";
import { AppBtn, AppBtnOutLine, PurplelBtn } from '../../../Components/AppTools/AppButton';
import { IBox, Status } from "../../../Components/AppTools/AppTool"


import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export default function FillItOutTask({ slideMenu, setSlideMenu, workspaceDrop }) {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [brandDrop, setBrandDrop] = useState(false)
  const [brandDropVal, setBrandDropVal] = useState()
  const [selectDrop, setSelectDrop] = useState(null);
  const [brandDrop2, setBrandDrop2] = useState(false)
  const [brandDropVal2, setBrandDropVal2] = useState()
  const brandDropItem = [
    {
      name: "item1"
    },
    {
      name: "item2"
    },
    {
      name: "item3"
    },
    {
      name: "item4"
    },
  ]


  const lineBar = 80;
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#4F33D1",
    },
  }));


  return (
    <>
      <Box className="ResearchersContainer FillItOutTaskPage ">
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon">
              {sideHaumIcon}
            </Box>
            <Box className="ontheDotNav">
              <PurplelBtn buttonText={t("commonTxt.previouspage")} />
              <AppBtn buttonText={t("commonTxt.nextPage")} />
            </Box>
          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer onthedot">
            <Box className="workspaceListBox">
              <Box mb={3} width="74px" onClick={() => Navigate("/participants/activities")} className="openBtn">
                <img src={backArrow} />
                <Typography className="neonBackText">Back</Typography>
              </Box>
              <Typography className='activeProgText'>{t("commonTxt.activityProgress")}</Typography>
              <Box className="lineBar">
                <Typography>{lineBar}%</Typography>
                <BorderLinearProgress variant="determinate" value={lineBar} />
              </Box>
              <Typography className="lineBarSubText">{t("onTheDro.TaskComp")}</Typography>

              <Box className="otdmainLabelBox">
                <Typography className='ActInSectionMainHeader'>{t("FillItOutTask.pageHeader")}</Typography>
                <Box className="skipBtnBox">
                  <Typography>{t("onTheDro.SaveDraft")}</Typography>
                  <AppBtnOutLine buttonText="Skip Task" />
                </Box>
              </Box>
              <IBox text="Complete your response by filing in the blank spaces below" />

              <Box className="inputwLabelBox">
                <Typography minWidth={"310px"} className='inwlLabel'>Please tell us why you love dogs over cats</Typography>
                <input type="text" className='phoneInput' placeholder='Enter your response here' />
              </Box>

              <Box className="inputwLabelBox">
                <Typography className='inwlLabel'>Which breed do you love the most : </Typography>
                <Box width={"250px"}>
                  <Status itemAry={brandDropItem} label="Select Breed" setStatusDrop={setBrandDrop} statusDrop={brandDrop} setStatusDropVal={setBrandDropVal} statusDropVal={brandDropVal} />
                </Box>
              </Box>

              <Box className="inputwLabelBox">
                <Typography className='inwlLabel'>Which brand food do you prefer</Typography>
                <Box width={"250px"}>
                  <Status itemAry={brandDropItem} label="Select Breed" setStatusDrop={setBrandDrop2} statusDrop={brandDrop2} setStatusDropVal={setBrandDropVal2} statusDropVal={brandDropVal2} />
                </Box>

              </Box>


              <Typography>{t("FillItOutTask.PickFavourite")}</Typography>
              <Box mt={2} sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                  defaultChecked
                />
                <Typography>Dog</Typography>
              </Box>

              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                  defaultChecked
                />
                <Typography>Cat</Typography>
              </Box>

              <Box mt={3}>
                <Typography >{t("imageTask.associatedBrand")}</Typography>
                <textarea className='massageInput' placeholder="Enter your response here" />
              </Box>



              <Box mt={4} className="uploadBox">
                <Box className="imgUplodInp0utBox">
                  <input
                    id='fileInput'
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                  />
                  <img src={plassIcon} alt="Camera" />
                </Box>
                <Typography
                  onClick={() => document.getElementById("fileInput").click()}
                  className='imageUploadLabelText'><span>{t("FillItOutTask.UploadImage")}</span></Typography>
              </Box>

              <Box className="lineDropBox">
                <Typography className='lineDLabel'>{t("imageTask.associatedBrand")}</Typography>

                <Box className="lineDrop">
                  <Box className="LDrop" onClick={() => setSelectDrop(0)}>
                    <Box className="lDropSelectBox" sx={{ display: selectDrop === 0 ? "block" : "none" }}></Box>
                    <Typography>{t("imageTask.VeryPoor")}</Typography>
                  </Box>
                  <Box className="LLine"></Box>
                  <Box className="LDrop" onClick={() => setSelectDrop(1)}>
                    <Box className="lDropSelectBox" sx={{ display: selectDrop === 1 ? "block" : "none" }}></Box>
                    <Typography>{t("imageTask.Poor")}</Typography>
                  </Box>
                  <Box className="LLine"></Box>
                  <Box className="LDrop" onClick={() => setSelectDrop(2)}>
                    <Box className="lDropSelectBox" sx={{ display: selectDrop === 2 ? "block" : "none" }}></Box>
                    <Typography>{t("imageTask.Okay")}</Typography>
                  </Box>
                  <Box className="LLine"></Box>
                  <Box className="LDrop" onClick={() => setSelectDrop(3)}>
                    <Box className="lDropSelectBox" sx={{ display: selectDrop === 3 ? "block" : "none" }}></Box>
                    <Typography>{t("imageTask.Good")}</Typography>
                  </Box>
                </Box>
              </Box>

              <Box className="progresBarMainLabel">
                <Typography>{t("FillItOutTask.HowMuchAllocate")}</Typography>
                <Typography>{t("FillItOutTask.TotalAllocated")}: <span>$ 100</span></Typography>
              </Box>

              <Box className="progressNlabel ">
                <Typography>{t("FillItOutTask.Clothing")}</Typography>
                <Box>
                  <Typography>$</Typography>
                  <input type="text" className='phoneInput' />
                  <Typography>/{t("FillItOutTask.month")}</Typography>
                </Box>
              </Box>

              <Box mb={10} className="lineDrop lineDrag">
                <Box className="LDrop" >
                  <Typography>$ 0</Typography>
                </Box>
                <Box className="LLine"></Box>
                <Box className="LDrop" >
                  <Typography>$ 0</Typography>
                </Box>
              </Box>

              <Box className="progressNlabel ">
                <Typography>{t("FillItOutTask.Food")}</Typography>
                <Box>
                  <Typography>$</Typography>
                  <input type="text" className='phoneInput' />
                  <Typography>/{t("FillItOutTask.month")}</Typography>
                </Box>
              </Box>

              <Box mb={10} className="lineDrop lineDrag">
                <Box className="LDrop" >
                  <Typography>$ 0</Typography>
                </Box>
                <Box className="LLine"></Box>
                <Box className="LDrop" >
                  <Typography>$ 100</Typography>
                </Box>
              </Box>

              <Box className="progressNlabel ">
                <Typography>{t("FillItOutTask.Investments")}</Typography>
                <Box>
                  <Typography>$</Typography>
                  <input type="text" className='phoneInput' />
                  <Typography>/{t("FillItOutTask.month")}</Typography>
                </Box>
              </Box>

              <Box mb={5} className="lineDrop lineDrag">
                <Box className="LDrop" >
                  <Typography>$ 0</Typography>
                </Box>
                <Box className="LLine"></Box>
                <Box className="LDrop" >
                  <Typography>$ 100</Typography>
                </Box>
              </Box>

            </Box>
          </Box>
        </Box>
      </Box>

    </>
  )
}
