import React, { useState } from 'react'
import "./style.css"
import { Box, Typography } from '@mui/material'
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useNavigate } from "react-router-dom";

//images
import iIcon from "../../../Assets/Images/IIcon.png";
import CameraIcon from "../../../Assets/Images/bi_camera.png"
import { sideHaumIcon, groupBtnTab } from "../../../Assets/Data";
import eye from "../../../Assets/Images/eye.png"
import whiteCross from "../../../Assets/Images/whiteCross.png"

//components
import { AppBtnOutLine } from "../../../Components/AppTools/AppButton"
import { SearchBar, Status } from '../../../Components/AppTools/AppTool';
import SideMenu from "../../../Components/Researchers/SideMenu";

export default function EntiteActivity({
  workspaceDrop,
  setWorkSpaceDrop,
  slideMenu,
  setSlideMenu, }) {
  const Navigate = useNavigate();
  const [redio, setRedio] = useState(0)
  const [redio2, setRedio2] = useState(0)
  const [navDrop, setNavDrop] = useState(false)
  const [navDropVal, setNavDropVal] = useState("Participants")
  const [navDrop2, setNavDrop2] = useState(false)
  const [navDropVal2, setNavDropVal2] = useState("Collaborators")
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const navDropItem1 = [
    {
      name: "Participants",
      value: "Participants"
    },
    {
      name: "Administrators",
      value: "Administrators"
    },
  ]
  const navDropItem2 = [
    {
      name: "Collaborators",
      value: "Collaborators"
    },
    {
      name: "Observers",
      value: "Observers"
    },
  ]


  const lineBar = 80
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#4F33D1",
    },
  }));


  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
  };




  return (
    <>
      <Box className="ResearchersContainer ">
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar entiteActivityNav">

            <Box sx={{ gap: "30px" }} className="NavBtnbox">
              <Box
                className="sideHaumIcon"
                onClick={() => setSlideMenu(!slideMenu)}
                sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}>
                {sideHaumIcon}
              </Box>
              <Typography className='navText'>{t("activityParticipantPreview.activityNamePreview")}</Typography>
            </Box>

            <Box className="NavBtnbox">
              <Box className="viewAsBtn">
                <img src={eye} />
                <Typography>{t("activityParticipantPreview.viewAs")}</Typography>
              </Box>
              <Box width={"143px"} height={"40px"}>
                <Status
                  setStatusDrop={setNavDrop}
                  statusDrop={navDrop}
                  setStatusDropVal={setNavDropVal}
                  statusDropVal={navDropVal}
                  itemAry={navDropItem1}
                  label=" "
                />
              </Box>

              {navDropVal === "Participants" ?
                <Box className="groupBtnTab">
                  {groupBtnTab}
                  <Typography>{t("commonTxt.groupBtn")}</Typography>
                </Box> :
                <Box width={"143px"} height={"40px"}>
                  <Status
                    setStatusDrop={setNavDrop2}
                    statusDrop={navDrop2}
                    setStatusDropVal={setNavDropVal2}
                    statusDropVal={navDropVal2}
                    itemAry={navDropItem2}
                    label=" "
                  />
                </Box>
              }
              <Box className="CloseBtn" onClick={() => Navigate("/activities/task")}>
                <img src={whiteCross} />
                <Typography>{t("commonTxt.closePreviewBtn")}</Typography>
              </Box>
            </Box>
          </Box>

          {/* Page container */}
          <Box className="researchersMainBox pageContainer">
            {
              navDropVal === "Participants" ?

                // ------------Participants----------
                <Box className="EntiteActivityContainer workspaceListBox">
                  <Typography className='EAHeaderText'>{t("commonTxt.activityProgress")}</Typography>
                  <Box mt={2} className="lineBar">
                    <Typography>{lineBar}%</Typography>
                    <BorderLinearProgress variant="determinate" value={lineBar} />
                  </Box>
                  <Typography className='lineBarSubText'>8 {t("ActivitiesInnerPage.of")} 10 {t("ActivitiesInnerPage.responsesRecieved")}</Typography>

                  <Box mt={4} className="taskImgHeader">
                    <Typography className='taskImgHeaderText'>{t("ImageActivity.ImageActivityHeader")}</Typography>
                    <Box className="taskImgHed2Box">
                      <Typography mr={2} className='taskHeaderText'>{t("onTheDro.SaveDraft")}</Typography>
                      <AppBtnOutLine buttonText={t("commonTxt.skipTaskBtn")} />
                    </Box>
                  </Box>

                  <Box className="iBox">
                    <img src={iIcon} />
                    <Typography>{t("activityParticipantPreview.activityPreviewNotify")}</Typography>
                  </Box>
                  <Typography className='imgUploadHeader'>{t("ImageActivity.uploadLabel")}<span>*</span></Typography>


                  <Box className="imgUploadMainbox">
                    <Box className="imgUplodInputBox" onClick={() => document.getElementById("fileInput").click()}>
                      <input
                        id='fileInput'
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleFileUpload}
                      />
                      <img src={CameraIcon} alt="Camera" />
                    </Box>
                    <Typography
                      onClick={() => document.getElementById("fileInput").click()}
                      className='imageUploadLabelText'>{t("ImageActivity.captureText1")} <span>{t("ImageActivity.captureText2")}</span> {t("ImageActivity.captureText3")}</Typography>

                    <SearchBar placeholder={t("activityParticipantPreview.searchImageInternet")} />
                  </Box>
                </Box> :

                // --------Administrators------------
                <Box className="EntiteActivityContainer workspaceListBox">
                  <Typography className='EAHeaderText'>{t("commonTxt.activityProgress")}</Typography>
                  <Box mt={2} className="lineBar">
                    <Typography>{lineBar}%</Typography>
                    <BorderLinearProgress variant="determinate" value={lineBar} />
                  </Box>
                  <Typography className='lineBarSubText'>8 {t("ActivitiesInnerPage.of")} 10 {t("ActivitiesInnerPage.responsesRecieved")}</Typography>

                  <Box mt={4} className="taskImgHeader">
                    <Typography className='taskImgHeaderText'>{t("PollAndSurvey.pageHeader")}</Typography>
                    <Box className="taskImgHed2Box">
                      <Typography mr={2} className='taskHeaderText'>{t("onTheDro.SaveDraft")}</Typography>
                      <AppBtnOutLine buttonText={t("commonTxt.skipTaskBtn")} />
                    </Box>
                  </Box>

                  <Typography className='EAHeaderText first'>1. Pick your favourite</Typography>
                  <Typography className='pollsSubText'>{t("LiveInterview.preparedItemText")}</Typography>

                  <Box className="redioItem" onClick={() => setRedio(0)}>
                    <Box className={redio === 0 ? "customRedio customRedioActive" : "customRedio"} ><Box></Box></Box>
                    <Typography>Dog</Typography>
                  </Box>

                  <Box className="redioItem" onClick={() => setRedio(1)}>
                    <Box className={redio === 1 ? "customRedio customRedioActive" : "customRedio"} ><Box></Box></Box>
                    <Typography>Cat</Typography>
                  </Box>

                  <Typography className='EAHeaderText'>2. Which of these skills would you like to master?</Typography>
                  <Typography className='pollsSubText'>{t("LiveInterview.preparedItemText")}</Typography>

                  <Box className="redioItem" onClick={() => setRedio2(0)}>
                    <Box className={redio2 === 0 ? "customRedio customRedioActive" : "customRedio"} ><Box></Box></Box>
                    <Typography>Software Development</Typography>
                  </Box>
                  <Box className="redioItem" onClick={() => setRedio2(1)}>
                    <Box className={redio2 === 1 ? "customRedio customRedioActive" : "customRedio"} ><Box></Box></Box>
                    <Typography>Web Designing</Typography>
                  </Box>
                  <Box className="redioItem" onClick={() => setRedio2(2)}>
                    <Box className={redio2 === 2 ? "customRedio customRedioActive" : "customRedio"} ><Box></Box></Box>
                    <Typography>DevOps</Typography>
                  </Box>
                  <Box className="redioItem" onClick={() => setRedio2(3)}>
                    <Box className={redio2 === 3 ? "customRedio customRedioActive" : "customRedio"} ><Box></Box></Box>
                    <Typography>Testing</Typography>
                  </Box>
                </Box>}
          </Box>
        </Box>
      </Box>
    </>

  )
}
