import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import "./style.css"

import { toast } from "react-toastify";
import axios from "../../../lib/axios";

import { useAppSelector } from '../../../lib/store/store';
import { renderUserLayout } from '../../../lib/helpers';
import { TOAST_CONFIG } from "../../../lib/constants";
import { getTimeByTimezone } from '../../../lib/helpers';

export default function Information({ }) {
  const authState = useAppSelector((state) => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace?.selectedWorkspace?._id);
  const [loading, setLoading] = useState(false);
  const [workspaceInfo, setWorkspaceInfo] = useState([]);

  useEffect(() => {
    fetchWorkspaceInfo();
  }, []);

  const fetchWorkspaceInfo = async () => {
    try {
      const response = await axios.get(`/researchers/workspace-settings/information/${workspaceState}`);
      if (response.success) {
        const workspaceInfo = response.data;
        console.log('get notification values',workspaceInfo);
        setWorkspaceInfo(workspaceInfo);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  }

  const getChildren = () => {
    return <Box className="researchersMainBox pageContainer">
      <Box className="informationBox workspaceListBox">
        <Box className="informationSumBox">
          <Typography className='infoTitle'>Site Name</Typography>
          <Typography className='infoSubTitle'>{workspaceInfo?.site_name}</Typography>
          <Box className="gapBox"></Box>

          <Typography className='infoTitle'>Participant Access</Typography>
          <Box className="workRemanTeb">
            <Typography>{workspaceInfo?.remaining_week} weeks Remaining</Typography>
          </Box>
          <Typography className='infoSubTitle'>Start: <span style={{ marginLeft: "18px" }}>{new Date(workspaceInfo?.start_date).toLocaleString('default', { month: 'long' })} {new Date(workspaceInfo?.start_date).getDay()} at 12:00 AM</span></Typography>
          <Typography className='infoSubTitle'>End: <span style={{ marginLeft: "18px" }}>{new Date(workspaceInfo?.end_date).toLocaleString('default', { month: 'long' })} {new Date(workspaceInfo?.end_date).getDay()} at 11:59 PM</span></Typography>
          <Box className="gapBox"></Box>

          <Typography className='infoTitle'>Administrators</Typography>
          <Box className="adLT">
            <Typography className='infoSubTitle'>Researchers</Typography>
            <Box><p>{workspaceInfo?.administrator?.researcher}</p></Box>
          </Box>
          <Box className="adLT">
            <Typography className='infoSubTitle'>Collaborator</Typography>
            <Box><p>{workspaceInfo?.administrator?.collaborator}</p></Box>
          </Box>
          <Box className="adLT">
            <Typography className='infoSubTitle'>Observer</Typography>
            <Box><p>{workspaceInfo?.administrator?.observer}</p></Box>
          </Box>
          <Box className="gapBox"></Box>

          <Typography className='infoTitle'>Support Contact</Typography>
          <Typography className='infoSubTitle'>{workspaceInfo?.contact_information?.email}</Typography>
          <Box className="gapBox"></Box>

          <Typography className='infoTitle'>Agreements</Typography>
          <Typography className='infoSubTitle'>{workspaceInfo?.required_agreements} Required</Typography>
          <Box className="gapBox"></Box>


        </Box>
        <Box className="informationSumBox infoSubRightBox">
          <Typography className='infoTitle'>Sending Email</Typography>
          <Typography className='infoSubTitle'>{workspaceInfo?.sending_email}</Typography>
          <Box className="gapBox"></Box>

          <Typography className='infoTitle'>Email Notifications</Typography>
          <Typography className='infoSubTitle'>{workspaceInfo?.notificationSetting?.type.replace(/^./, workspaceInfo?.notificationSetting?.type[0].toUpperCase())} at 6 AM 12 PM, 6 PM</Typography>
          <Box className="gapBox"></Box>

          <Typography className='infoTitle'>Time Zone</Typography>
          <Typography className='infoSubTitle'>{workspaceInfo?.timezone?.country}</Typography>
          <Typography className='infoSubTitle'>{getTimeByTimezone(workspaceInfo?.timezone?.zone_name)} - {workspaceInfo?.timezone?.zone_name}</Typography>
          <Box className="gapBox"></Box>

          <Typography className='infoTitle'>Languages</Typography>
          <Typography className='infoSubTitle'>Default <span style={{ marginLeft: "18px" }}>{workspaceInfo?.language?.default}</span></Typography>
          <Typography className='infoSubTitle'>Enabled <span style={{ marginLeft: "18px" }}>{workspaceInfo?.language?.secondary_languages} of {workspaceInfo?.language?.total_language} languages</span></Typography>
          <Box className="gapBox"></Box>
        </Box>
      </Box>
    </Box>
  }

  return renderUserLayout(authState?.userType, null, getChildren(), 'workadmin');
}
