import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";

import { TIcon, sideHaumIcon } from "../../../Assets/Data";

import exisitingPartBlack from "../../../Assets/Images/exisitingPartBlack.png";
import newPartBlack from "../../../Assets/Images/newPartBlack.png";
import messageBlack from "../../../Assets/Images/messageBlack.png";
import partScreeningBlack from "../../../Assets/Images/partScreeningBlack.png";
import invitationLinkBlack from "../../../Assets/Images/invitationLinkBlack.png";
import importIconBlack from "../../../Assets/Images/importIconBlack.png";

import exisitingPartPurple from "../../../Assets/Images/exisitingPartPurple.png";
import newPartPurple from "../../../Assets/Images/newPartPurple.png";
import messagePurple from "../../../Assets/Images/messagePurple.png";
import invitationLinkPurple from "../../../Assets/Images/invitationLinkPurple.png";
import importIconPurple from "../../../Assets/Images/importIconPurple.png";
import SideMenu from "../../../Components/Researchers/SideMenu";
import { useAppSelector } from "../../../lib/store/store";

const ProjectManagementParticipant = ({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
}) => {
  const Navigate = useNavigate();
  const workspaceState = useAppSelector((state) => state.workspace);
  const [addParticipantPage, setAddParticipantPage] = useState(false);
  const [addParticipantType, setAddParticipantType] = useState();
  const [participantScreening, setParticipantScreening] = useState(false);
  const [bulkImportParticipant, setBulkImportParticipant] = useState(false);
  const [mailInvitationParticipant, setMailInvitationParticipant] =
    useState(false);
  const [shareInviteParticipant, setShareInviteParticipant] = useState(false);
  const [existingParticipant, setExistingParticipant] = useState(false);
  const [newParticipant, setNewParticipant] = useState(false);

  const goToDirectoryParticipant = () =>
    Navigate({
      pathname: "/project-management",
      search: "?dr=1",
    });

  const ActivityCard = ({ icon, title, subText }) => {
    return (
      <Box
        onClick={() => setAddParticipantType(title)}
        className={
          addParticipantType === title
            ? "ActivityTypeItem ActivityTypeItemClicked"
            : "ActivityTypeItem"
        }
      >
        <Box className="acTyTitleBox">
          <Typography>{title}</Typography>
          <Box
            className={
              addParticipantType === title
                ? "acIconBox acIconActiveBox"
                : "acIconBox"
            }
          >
            {/* {icon} */}
            <img src={icon} alt="" />
          </Box>
        </Box>
        <span>{subText}</span>
      </Box>
    );
  };

  const handelAddParticipantPopCoutinue = () => {
    if (addParticipantType === "Participant Screening") {
      Navigate("/project-management/project-participant-screening");
    } else if (addParticipantType === "Bulk Import") {
      // Navigate("/project-management/project-participant-bulkImport");
      Navigate("/workspace-administration/bulk-import");
    } else if (addParticipantType === "Send Email Invitations") {
      Navigate("/project-management/project-participant-mailInvitation");
    } else if (addParticipantType === "Share Invitation Link") {
      Navigate("/project-management/project-participant-linkInvitation");
    } else if (addParticipantType === "Add Existing Participants") {
      Navigate("/researchers/project/directory/participantscng");
    } else if (addParticipantType === "Add New Participant") {
      // Navigate("/project-management/add-project-participant");
      Navigate(`/workspace-administration/add-participants?pid=${workspaceState?.selectedProject?._id}`);
    }
  };

  return (
    <Box className="ResearchersContainer">
      <SideMenu
        slideMenu={slideMenu}
        setSlideMenu={setSlideMenu}
        workspaceDrop={workspaceDrop}
        setWorkSpaceDrop={setWorkSpaceDrop}
      />
      <Box className="adminMainSection">
        <Box className="adminNavBar">
          <Box
            onClick={() => setSlideMenu(!slideMenu)}
            sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
            className="sideHaumIcon"
          >
            {sideHaumIcon}
          </Box>
        </Box>
        <Box className="researchersMainBox pageContainer">
          <Box className="workSpaceListBox">
            <h2>Add Participants</h2>

            <Box className="ActivityTypeBox">
              {ActivityCard({
                icon: partScreeningBlack,
                title: "Participant Screening",
                subText:
                  "Qualify and limit participants invited to join this project",
              })}
              {ActivityCard({
                icon: importIconBlack,
                title: "Bulk Import",
                subText:
                  "Import and Group many participants in one step by uploading a CSV file,",
              })}
              {ActivityCard({
                icon: messageBlack,
                title: "Send Email Invitations",
                subText:
                  "Send and tract invitation messages delivered by email.",
              })}
              {ActivityCard({
                icon: invitationLinkBlack,
                title: "Share Invitation Link",
                subText:
                  "Send or share a static link that enables project registration.",
              })}
              {ActivityCard({
                icon: exisitingPartBlack,
                title: "Add Existing Participants",
                subText:
                  "Add all or left participants not assigned to this project",
              })}
              {ActivityCard({
                icon: newPartBlack,
                title: "Add New Participant",
                subText:
                  "Add new participant to the platform which will be part of this project",
              })}
            </Box>
            <Box className="workPupBtnBox">
              <AppBtnOutLine
                buttonText="Cancel"
                onClick={goToDirectoryParticipant}
              />
              <AppBtn
                buttonText="Continue"
                onClick={handelAddParticipantPopCoutinue}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectManagementParticipant;
