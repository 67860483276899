import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import axios from '../../../lib/axios'
import { TOAST_CONFIG } from "../../../lib/constants";
import { toast } from "react-toastify";
import { deactivateParticipantApi } from "../../../Services/adminActions";
import { useAppSelector } from "../../../lib/store/store";
//images

export default function DeactivateParticipantResearcherPop({
  deactivateParticipantPop,
  setDeactivateParticipantPop,
  participantList,
  setReloadList,
  isDeactivated
}) {
  let authState = useAppSelector(state => state.auth);
  const deactiveParticipantObj = {
    "participant_ids": participantList,
    "status": !isDeactivated ? "inactive" : "active"
  }

  useEffect(() => {
  }, [])

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setDeactivateParticipantPop(false);
    }
  };

  const handelDeactivate = async () => {
    try {
      let deactivateRes = await deactivateParticipantApi(
        deactiveParticipantObj,
        authState.authToken,
      );
      if (deactivateRes.success) {
        toast.success(deactivateRes.message, TOAST_CONFIG);
        setDeactivateParticipantPop(false);
        setReloadList(true);
      } else {
        toast.error(deactivateRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong! Please try again', TOAST_CONFIG);
    }
  };


  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: deactivateParticipantPop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople"
      >
        <Box
          sx={{ display: deactivateParticipantPop ? "flex" : "none" }}
          className="workspaceContaint deletePagePop"
        >
          <Typography className="companyHeader">{!isDeactivated ? "Deactivate" : "Activate"} Participants</Typography>

          <Box className="basicBoxItem">
            <Typography className='addPageLabel'>Are you sure you want to {!isDeactivated ? "deactivate" : "activate"} {`"${participantList.length}" participants`}?</Typography>
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={() => setDeactivateParticipantPop(false)} />
            <AppBtn buttonText={!isDeactivated ? "Deactivate" : "Activate"} onClick={handelDeactivate} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
