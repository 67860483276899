import React, { useState } from "react";
import "./style.css";

import { Box, Typography } from "@mui/material";
import AdminBG from "../../../Assets/Images/Add-files-cuate.png";
import { AppBtn } from "../../AppTools/AppButton";

import WorkspacePop from "../WorkspacePop";

const NoWorkspaceBG = ({ loading }) => {
  loading = loading || false;
  const [workSpacePop, setWorkSpacePop] = useState(false);
  // Company pop-up
  const [companyPop, setCompanyPop] = useState(false);
  const [editCompanyPop, setEditCompanyPop] = useState(false);
  const [workspaceCompany, setWorkspaceCompany] = useState(false);
  const [editWorkspaceCompany, setEditWorkspaceCompany] = useState(false);
  const [addResearcherPop, setAddResearcherPop] = useState(false);
  const [workspaceResearcher, setWorkspaceResearcher] = useState(false);
  const [editWorkspaceResearcher, setEditWorkspaceResearcher] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [createdAffiliate, setcreatedAffiliate] = useState(null);
  let [allCompaniesData, setAllCompaniesData] = useState(null);
  let [workspacePopType, setWorkspacePopType] = useState(null);
  const [showCompanyUser, setShowCompanyUser] = useState(false);

  return (
    <>
      <Box className="adminWorkSBG">
        <img src={AdminBG} />
      </Box>
      <Box className="adminBGTextBox">
        <Typography className="adminHeaderText">
          Welcome to Terapage!
        </Typography>
        <Typography className="adminSubHeaderText">
          It seems like you have not created any workspace in the dashboard yet.
          Please create to proceed further.
        </Typography>
        <AppBtn
          onClick={() => setWorkSpacePop(true)}
          buttonText="Create Workspace"
        />
      </Box>
      <WorkspacePop
        workSpacePop={workSpacePop}
        setWorkSpacePop={setWorkSpacePop}
        setCompanyPop={setCompanyPop}
        companyPop={companyPop}
        setAllCompaniesData={setAllCompaniesData}
        workspacePopType={workspacePopType}
        setWorkspacePopType={setWorkspacePopType}
        setReloadList={setReloadList}
        setEditCompanyPop={setEditCompanyPop}
        setWorkspaceCompany={setWorkspaceCompany}
        workspaceCompany={workspaceCompany}
        setEditWorkspaceCompany={setEditWorkspaceCompany}
        setAddResearcherPop={setAddResearcherPop}
        addResearcherPop={addResearcherPop}
        setWorkspaceResearcher={setWorkspaceResearcher}
        workspaceResearcher={workspaceResearcher}
        setEditWorkspaceResearcher={setEditWorkspaceResearcher}
        createdAffiliate={createdAffiliate}
        setShowCompanyUser={setShowCompanyUser}
        showCompanyUser={showCompanyUser}
      />
    </>
  );
};

export default NoWorkspaceBG;
