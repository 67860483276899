import React, { useState } from 'react'
import "./style.css"
import { Box, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { sendOtp } from '../../../Services/auth';
import { toast } from "react-toastify";
//images
import banner from "../../../Assets/Images/logBanner.png"
import CLogo from "../../../Assets/Images/Frame 243811.png"
import RedInfo from "../../../Assets/Images/info.png"
import { useTranslation } from 'react-i18next';
import { USER_TYPE_WS_ADMIN } from '../../../lib/constants';
import { getUserTypeFromSegment, getUserUriSegment } from '../../../lib/helpers';
import { useParams } from 'react-router-dom';

export default function PwdFEmail() {

  const { usersegment } = useParams();

  let usertype = getUserTypeFromSegment(usersegment);

  const { t, i18n: {changeLanguage, language} } = useTranslation();
  const Navigate = useNavigate();
  const [mailSend, setMailSend] = useState(false)
  const [email, setEmail] = useState("")
  const [apiError, setApiError] = useState("")
  const [loading, setLoading] = useState(false);
  usertype = usertype || {USER_TYPE_WS_ADMIN};

  const changeAppLanguage = (lng) => {
    changeLanguage(lng);
  }
  
  const forgotPassword = async () => {

    if (email == "") {
      setApiError("Email is required");
      return false;
    }

    try {
      setLoading(true)
      const response = await sendOtp({ email })
      setLoading(false);
      if(response.success) {
        toast.success(response.message)
        Navigate(`/${getUserUriSegment(usertype)}/otp?email=${email}`)
      } else {
        setApiError(response.message);
      }
    } catch (err) {
      console.log(err);
      setApiError('Something went wrong! Please try again');
      setLoading(false);
    };
  }

  return (
    <>
    <select style={{float: 'right'}} onChange={(e) => {changeAppLanguage(e.target.value)}} value={language}>
        <option value="en">English</option>
        <option value="hi">Hindi</option>
      </select>
      <Box className="logInPage">
        <Box className="banner">
          <Typography className=''>Take your research to the next level</Typography>
          <Box mt={3} className="slideBox">
            <Box className="sBox"></Box>
            <Box className="sBox"></Box>
            <Box className="sBox sBoxActive"></Box>
          </Box>
          <img src={banner} />
        </Box>
        {
          mailSend ?
            <Box className="signInBox">
              <Box className="sendEmailMessageBox">
                <Typography>Verification mail, containing the change password link sent to the provided E-mail address.</Typography>
              </Box>
            </Box>
            :


            <Box className="signInBox">
              <Box className="signInInnerBox">
                <Box className="signColog">
                  <img onClick={() => Navigate("/")} src={CLogo} />
                </Box>
                <Box className="signInInputBox">
                  <Typography>Email</Typography>
                  <input type="email" placeholder='Enter Email Address' name="email" onChange={(e) => setEmail(e.target.value)} />
                </Box>

                {apiError !== "" && <Box mb={2} className="pwdError">
                  <img src={RedInfo} />
                  <Typography>{apiError}</Typography>
                </Box>}

                <Box className="signBtn" onClick={()=>forgotPassword()}>
                {loading ? <Typography>{t('adminSignIn.loading')}...</Typography> : <><Typography>Continue</Typography></>}
                </Box>
                <Typography onClick={() => Navigate(`/${getUserUriSegment(usertype)}/login`)} className='gologPageLing'>Back to Sign in page</Typography>

              </Box>
            </Box>

        }

      </Box>
    </>
  )
}
