import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//images
import AdminBG from "../../../Assets/Images/Add-files-cuate.png";
import arrow from "../../../Assets/Images/arrow.png";
import { useAppSelector } from "../../../lib/store/store";
import { API_BASE_URL } from "../../../env";
import axios from "../../../lib/axios";
import { TOAST_CONFIG } from "../../../lib/constants";
import { toast } from "react-toastify";
import { formatDate, viewImage } from "../../../lib/helpers";
//data
import { P_ActivitiesCardData } from "../../../Assets/Data";
import SideMenu from "../../../Components/Participants/SideMenu";
import {
  sideHaumIcon,
  completedIcon,
  inProgressIcon,
  pendingIcon,
} from "../../../Assets/Data";
import Pagination from "../../../Components/AppTools/Pagination";

export default function Activities({ slideMenu, workspaceDrop, setSlideMenu }) {
  let authState = useAppSelector((state) => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace);
  //console.log("Selected workspace and auth state",authState,workspaceState);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  const data = true;
  const [currentPage, setCurrentPage] = useState(0);
  const [newArrayData, setNewArrayData] = useState([]);
  const [allActivitiesList, setAllActivitiesList] = useState([]);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 25,
    totalPages: 0,
    totalItems: 0,
  });

  const defaultFilters = {
    search: "",
    status: "",
  };

  const [filterState, setFilterState] = useState({ ...defaultFilters });

  useEffect(() => {
    getActivities(1);
  }, [workspaceState]);

  const activityNavigate = (type, id) => {
    if (type === "Live Interview Activity") {
      Navigate(`/participants/activities/Interview-Scheduled/${id}`);
    } else if (type === "Live Group Chat") {
      Navigate("/participants/interview-scheduled");
    } else if (type === "Journal Activity") {
      Navigate("/participants/activities-Jourrnal");
    } else {
      Navigate(`/participants/activities/task/${id}`);
    }
  };

  const ActivitiesCard = (item, date, img, index) => {
    return (
      <Box
        mb={2}
        className="userHMBoxItem userHConsart P_ActivUserHMB"
        key={index}
      >
        <Box
          className="conImgBox"
          // onClick={() => handleCardClick(title)}
          onClick={() => activityNavigate(item.type, item._id)}
        >
          <img src={viewImage(img)} />
        </Box>
        <Box className="userHCTextBox">
          <Typography
            // onClick={() => handleCardClick(title)}
            onClick={() => activityNavigate(item.type, item._id)}
            sx={{
              fontSize: "18px",
              color: "#101828",
              fontWeight: "600",
              cursor: "pointer",
            }}
          >
            {item.name} ({item.type})
          </Typography>
        </Box>
        <Box className="userHCTextBox">
          <Box>
            <Typography sx={{ fontSize: "16px", color: "#98A2B3" }}>
              {date}
            </Typography>
            <Box sx={{ background: "#12B76A" }} className="ActiveStatus"></Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const handleCardClick = (title) => {
    if (title === "On-the-Dot (Task)") {
      Navigate("/participants/activities/on-the-dot");
    }
    if (title === "Image (Task)") {
      Navigate("/participants/activities/image-activity");
    }
    if (title === "Audio (Task)") {
      Navigate("/participants/activities/audio-activity");
    }
    if (title === "Video (Task)") {
      Navigate("/participants/activities/video-activity");
    }
    if (title === "screen capture (Task)") {
      Navigate("/participants/activities/screen-capture");
    }
    if (title === "Outdoor Activity Journal") {
      Navigate("/participants/activities-Jourrnal");
    }
    if (title === "Review an Image (Task)") {
      Navigate("/participants/activities/image-task");
    }
    if (title === "Video & Product Reviews") {
      Navigate("/participants/activities/video-task");
    }
    if (title === "Task- Matrix") {
      Navigate("/participants/activities/matrix-task");
    }
    if (title === "Task- Fill-it-out") {
      Navigate("/participants/activities/fill-it-out");
    }
    if (title === "Task- Rank It or Sort It") {
      Navigate("/participants/activities/rank-and-sort");
    }
    if (title === "Task Poll & Survey") {
      Navigate("/participants/activities/PollAndSurvey");
    }
    if (title === "Task Any File") {
      Navigate("/participants/activities/any-file");
    }
    if (title === "Task : Review an Audio") {
      Navigate("/participants/activities/review-audio");
    }
    if (title === "Task Mixed Media") {
      Navigate("/participants/activities/mixed-media");
    }
    if (title === "Live Interview") {
      Navigate("/participants/activities/Interview-Scheduled");
    }
    if (title === "Live Group Chat") {
      Navigate("/participants/interview-scheduled");
    }
  };

  useEffect(() => {
    printItemsByState(P_ActivitiesCardData, currentPage);
  }, [currentPage]);

  function printItemsByState(array, page) {
    const startIndex = page * 6;
    const endIndex = (page + 1) * 6;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  const getActivities = async (curPage, filters = filterState) => {
    return await axios
      .get(
        API_BASE_URL +
          `/researchers/activities?page=${curPage}&search=${filters.search}&status=${filters.status}&project_id=${workspaceState?.selectedProject?._id}`
      )
      .then((res) => {
        console.log("res", res);
        if (res.status) {
          setAllActivitiesList(res.data.data);
          setPaginationData({
            currentPage: res.data.pagination.currentPage,
            itemsPerPage: res.data.pagination.itemsPerPage,
            totalPages: res.data.pagination.totalPages,
            totalItems: res.data.pagination.totalItems,
          });
        }
      });
  };

  // console.log("What is the allActivitiesList======:", allActivitiesList);

  const dateTxt = (sDate, eDate) => {
    const rSdate = sDate ? formatDate(sDate, "MMM DD") : "####";
    const rEdate = eDate ? formatDate(eDate, "MMM DD") : "####";
    return `${rSdate} - ${rEdate}`;
  };

  const onActivityPageChange = (pageNum) => {
    getActivities(pageNum);
  };

  return (
    <>
      <Box className="ResearchersContainer ParticipantsActivePage">
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} workspaceDrop={workspaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer">
            {!data ? (
              <Box className="noDataPage">
                <Box className="adminWorkSBG">
                  <img src={AdminBG} />
                </Box>
                <Box className="adminBGTextBox">
                  <Typography className="adminSubHeaderText">
                    {t("ActivitiesPage.noDataSummeryText")}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box className="P_activitiesMainBox">
                {/* Status Meaning */}
                <Box sx={{ justifyContent: "end" }} className="activityNav1">
                  <Box className="projectNavIndicat">
                    {inProgressIcon}
                    <Typography ml={1}>In Progress</Typography>
                  </Box>
                  <Box ml={2} className="projectNavIndicat">
                    {completedIcon}
                    <Typography ml={1}>Completed</Typography>
                  </Box>
                  <Box ml={2} className="projectNavIndicat">
                    {pendingIcon}
                    <Typography ml={1}>Pending</Typography>
                  </Box>
                </Box>
                {/* Status Meaning */}
                <Box className="activitiesCardBox">
                  {allActivitiesList?.length > 0 &&
                    allActivitiesList.map((item, index) =>
                      ActivitiesCard(
                        item,
                        dateTxt(
                          item?.basic_settings?.start_date,
                          item?.basic_settings?.end_date
                        ),
                        item.image,
                        index
                      )
                    )}
                </Box>
                <Box className="pagination">
                  <Pagination
                    paginationData={paginationData}
                    onPageChange={onActivityPageChange}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
