import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../lib/store/store';
import { USER_TYPE_PARTICIPANT, USER_TYPE_SUPERADMIN, USER_TYPE_WS_ADMIN } from '../lib/constants';
import { logout } from '../Services/auth';
import { useEffect } from 'react';

const useAuth = (allowedUsers) => {

  allowedUsers    = allowedUsers || [];
  let userRoles   = [];
  let dispatch    = useAppDispatch();
  const Navigate  = useNavigate();
  const authState = useAppSelector(state => state.auth);
  let userType    = authState?.userType;

  if (authState?.userProfile?.is_superadmin) {
    userRoles.push(USER_TYPE_SUPERADMIN);
  } else if (authState?.userProfile?.is_researcher || authState?.userProfile?.is_collaborator || authState?.userProfile?.is_observer) {
    userRoles.push(USER_TYPE_WS_ADMIN);
  } else if (authState?.userProfile?.is_participant) {
    userRoles.push(USER_TYPE_PARTICIPANT);
  }

  if(userType == null && userRoles.length > 0) {
    userType = userRoles[0];
  }

  if(userType == null && userRoles.length <= 0 && allowedUsers.length > 0) {
    userType = allowedUsers[0];
  }

  useEffect(() => {

    let isAuthenticated = (userType != null && authState?.authToken != '');

    if (!isAuthenticated) {
      logout(Navigate, dispatch, userType);
    }

    let allowAccess = (allowedUsers.length > 0 && allowedUsers.indexOf(userType) > -1);

    if (!allowAccess) {
      Navigate('/unauthorized');
    }
  }, [])
};

export default useAuth;
