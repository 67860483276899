import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  CardHeader,
  Avatar,
  CardContent,
} from "@mui/material";

import { participantPreview } from "../../../Assets/Data"

//components
import { AppBtnOutLine } from "../../AppTools/AppButton";
import axios from "../../../lib/axios";
import { calculateTimeDistance, viewImage } from "../../../lib/helpers";


export default function ParticipantPop({ participantPop, setParticipantPop, setProjectID, projectID }) {

  const [participants, setParticipant] = useState([]);
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setParticipantPop(false);
    }
  };

  useEffect(() => {
    if (participantPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
    getProject(projectID)
  }, [participantPop]);

  const getProject = async (projectID) => {
    if (projectID !== "") {
      let url = `/researchers/projects?_id=${projectID}`
      const res = await axios.get(url);
      if (res.status) {
        if (res.data.data.length > 0) {
          const project = res.data.data[0]
          setParticipant(project?.workspaces[0]?.assigned_users_data)

        }

      }
    }
  }

  return (

    <Box
      onClick={handelPopClose}
      sx={{ display: participantPop ? "flex" : "none" }}
      className="workspacePop previewFilter"
      id="popBackdrop">
      <Box
        sx={{ display: participantPop ? "flex" : "none" }}
        className="workspaceContaint previewFilterPop">
        <Typography className='previewFilterHeaderText'>Participants</Typography>

        <Box className="ParticipantsPanel">
          {participants &&
            participants?.map((el, index) => (el.is_participant ?
              <Box className="ParticipantBox">
                <CardHeader
                  avatar={
                    <Avatar
                      alt=""
                      src={viewImage(el.image)}
                      sx={{ width: 44, height: 44 }}
                    />
                  }
                  title={el.name}
                />
                <CardContent>
                  <Typography>{el.phone}</Typography>
                  <Typography>{calculateTimeDistance(el.created_at)}</Typography>
                </CardContent>
              </Box> : null
            ))}

        </Box>
        <Box className="workPupBtnBox">
          <AppBtnOutLine
            buttonText="Close"
            onClick={() => { setParticipantPop(false); setProjectID("") }}
          />
        </Box>
      </Box>
    </Box>

  );
}
