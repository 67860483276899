import React from "react";
import AdminLayout from "../../partials/AdminLayout";
import { Box } from "@mui/material";
import SettingStandardGroup from "../../../../Components/WSAdministration/SettingStandardGroup";

const SettingStandardGroupPage = () => {
  return (
    <AdminLayout
      childComponent={
        <Box className="adminMainBox" sx={{ display: "flex" }}>
          <SettingStandardGroup />
        </Box>
      }
    />
  );
};

export default SettingStandardGroupPage;
