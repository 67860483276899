import React from 'react'
import AdminLayout from '../partials/AdminLayout';
import { Box } from '@mui/material';
import ContactSupport from '../../../Components/Admin/ContactSupport';

const ContactTicketPage = () => {
  return (
    <AdminLayout
      childComponent={
        <Box
          className="adminMainBox"
          sx={{ display: "flex" }}
        >
          <ContactSupport />
        </Box>
      }
    />
  )
}

export default ContactTicketPage;