import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import "./style.css"
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//images
import backArrow from "../../../Assets/Images/noun-back.png";
import avatar from "../../../Assets/Images/Edit-Avatar.png";
import map from "../../../Assets/Images/map.png"
import img1 from "../../../Assets/Images/New folder/img1.png"
import img2 from "../../../Assets/Images/New folder/img2.png"
import img3 from "../../../Assets/Images/New folder/img3.png"
import img4 from "../../../Assets/Images/New folder/img4.png"
import img5 from "../../../Assets/Images/New folder/img5.png"
import { sideHaumIcon, excerptIcon, exportIcon, YDot } from "../../../Assets/Data";

//components
import RejectResponsePop from "../../../Components/Collaborator/RejectResponsePop";
import SkipResponsePop from "../../../Components/Collaborator/SkipResponsePop";
import { OptionBlueDrop } from "../../../Components/AppTools/AppTool";
import SideMenu from "../../../Components/Collaborator/SideMenu";

export default function ViewResponse({ slideMenu, setSlideMenu, workspaceDrop, setWorkSpaceDrop }) {
  const Navigate = useNavigate();
  const [viewResponsePageNavDrop, setViewResponsePageNavDrop] = useState(false);
  const [viewResponsePageNavDropVal, setViewResponsePageNavDropVal] = useState("Options");
  const [ showComment, setShowComment ] = useState("true");

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const UserRow = ({ img, name, date, subText }) => {
    return (
      <Box className="user_row">
        <Box className="user_rowImg">
          <img src={img} />
        </Box>
        <Box ml={2} width="100%">
          <Box className="fr1">
            <Typography>{name}</Typography>
            <span>{date}</span>
          </Box>
          <span>{subText}</span>
        </Box>
      </Box>
    )
  }



  return (
    <>
      <Box className="CollaboratorContainer viewResponseUpper">
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop} />


        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
            <OptionBlueDrop
              viewResponsePageNavDrop={viewResponsePageNavDrop}
              setViewResponsePageNavDrop={setViewResponsePageNavDrop}
              viewResponsePageNavDropVal={viewResponsePageNavDropVal}
              setViewResponsePageNavDropVal={setViewResponsePageNavDropVal}
            />
          </Box>

          {/* Page container */}
          <Box className="collaboratorMainBox pageContainer">
            <Box className="participantsResponsContainer workSpaceListBox viewResponse">

              <RejectResponsePop setViewResponsePageNavDropVal={setViewResponsePageNavDropVal} viewResponsePageNavDropVal={viewResponsePageNavDropVal} />
              <SkipResponsePop setViewResponsePageNavDropVal={setViewResponsePageNavDropVal} viewResponsePageNavDropVal={viewResponsePageNavDropVal} />

              <Box className="topBackBtn" onClick={() => Navigate("/collaborator/activities/task/view")}>
                <img src={backArrow} />
                <Typography className="neonBackText">{t("commonTxt.backBtn")}</Typography>
              </Box>


              <Box className="openBtn">
                <Box className="mapB">
                  <img src={map} />
                  <Typography className="mapText">Perfect Outdoor Trip under Outdoor Journey</Typography>
                </Box>

                <Box sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "24px"
                }}>
                  
                  <Box className="actTNavBtn">
                    {excerptIcon}
                    <Typography>{t("commonTxt.excerptBtn")}</Typography>
                  </Box>
                  <Box className="actTNavBtn">
                    {exportIcon}
                    <Typography>{t("commonTxt.Export")}</Typography>
                  </Box>
                </Box>
              </Box>

              <Box className="userProfilrow">
                <Box className="userInfoBox">
                  <Box className="AvatrIBox">
                    <img src={avatar} />
                    {YDot}
                  </Box>
                  <Box className="userDetail">
                    <Typography className='userIName'>Olivia Rhye</Typography>
                    <Typography className=''>rhye_olivia@gmail.com</Typography>
                  </Box>
                </Box>

                <Box gap="20px" className="userInfoBox">
                  <Typography className='userIInfoDate'>Aug 04, 2023 04:35 PM</Typography>
                </Box>
              </Box>

              <Box className="taskBox">
                <Typography mb={3}>Which of these outdoor activities do you participate in? Please select all that apply and explain why these activities are the outdoor activities for you.</Typography>

                <Box ml={5} mb={3}>
                  <ul style={{ marginBottom: "10px" }}>
                    <li>Camping</li>
                    <li>Hiking</li>
                  </ul>
                  <span>All you need is a back pack, tent and some boots - nothing big or complicated!</span>
                </Box>
              </Box>

              <Typography className="hideCommentText" onClick={()=>setShowComment(!showComment)}>{ showComment? t("commonTxt.hideComments") : t("commonTxt.showComments")}</Typography>
              {showComment && <Box mt={2} className="graySBox userListBox" >
                {UserRow({
                  img: img1,
                  name: "Richard Han",
                  subText: "Hey @everyone, what other items according to you can be used to avoid injuries while trekking?",
                  date: "Aug 08, 2023"
                })}
                {UserRow({
                  img: img2,
                  name: "Orlando Diggs",
                  subText: "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                  date: "Aug 08, 2023"
                })}
                {UserRow({
                  img: img3,
                  name: "Kate Morrison",
                  subText: "Lorem ipsum dolor sit amet consectetur. Morbi suspendisse quis ornare in. Amet nunc lectus a aenean lectus.",
                  date: "Aug 08, 2023"
                })}
                {UserRow({
                  img: img4,
                  name: "Orlando Diggs",
                  subText: "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                  date: "Aug 08, 2023"
                })}
                {UserRow({
                  img: img5,
                  name: "Kate Morrison",
                  subText: "Lorem ipsum dolor sit amet consectetur. Morbi suspendisse quis ornare in. Amet nunc lectus a aenean lectus.",
                  date: "Aug 08, 2023"
                })}
              </Box>}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
