import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import SideMenu from "../../WSAdministration/SideMenu";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";
//images
import backArrow from "../../../Assets/Images/noun-back.png";
import VisibleIcon from "../../../Assets/Images/VisibleIcon.png";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import optionIcon from "../../../Assets/Images/optionIcon.png";
import axios from "../../../lib/axios";
import videoCameraIcon from "../../../Assets/Images/videoCameraIcon.png";
import CameraIcon from "../../../Assets/Images/bi_camera.png";

import DummyUser from "../../../Assets/Images/user-dummy.png";
import shareIcon from "../../../Assets/Images/shareIcon.png";

import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import personalGolBgImg from "../../../Assets/Images/personal goals checklist-amico (1) 1.png";
//data
import { sideHaumIcon } from "../../../Assets/Data";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

//components
import {
  OptionDrop,
  AntSwitch,
  RenderCustomField,
} from "../../AppTools/AppTool";
import { AppBtnOutLine, AppBtn } from "../../AppTools/AppButton";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { createResearcherParticipantAPI, uploadUserDirectoryImage } from "../../../Services/adminActions";
import { useAppSelector } from "../../../lib/store/store";
import { getCountries } from "../../../Services/master";
import { API_BASE_URL } from "../../../env";
import ReferenceDateUsingValue from "../../AppTools/DatePicker";
import ReferenceDateExplicitTimePicker from "../../AppTools/TimePicker";
import AdminLayout from "../../../Pages/Admin/partials/AdminLayout";
import ResearcherLayout from "../../../Pages/Researchers/partials/ResearcherLayout";
import { renderUserLayout } from "../../../lib/helpers";

export default function AddParticipantsResearcher({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
  participantsSubMenu,
  setParticipantsSubMenu,
  configurationSubMenu,
  setConfigurationSubMenu,
}) {
  let authState = useAppSelector((state) => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace);
  const [optionDrop, setOptionDrop] = useState(false);
  const [optionDropVal, setOptionDropVal] = useState("Options");
  const [redio, setRedio] = useState(0);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const pid = params.get('pid') || null;
  const [groupDrop, setGroupDrop] = useState(false);
  const [pwdVisible, setPwdVisible] = useState(false);
  const [cpwdVisible, setCpwdVisible] = useState(false);
  const [countryDrop, setCountryDrop] = useState(false);
  const [countryDropVal, setCountryDropVal] = useState();
  const [timeZoneDrop, setTimeZoneDrop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timeZoneList, setTimezoneList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [groupsListData, setGroupsListData] = useState([]);
  const [profileFieldData, setProfileFieldData] = useState([]);
  const [timeZoneVal, setTimeZoneVal] = useState("Select Time Zone");

  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();
  const [signatureTextUnderLine, setSignatureTextUnderLine] = useState();
  const [image, setImage] = useState(null);

  const Navigate = useNavigate();

  const handleMainOpenTabe = (Url) => {
    Navigate(Url);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "200px",
        overflowY: "auto",
      },
    },
  };
  const createResParticipantObj = {
    project_id: pid,
    name: "",
    email: "",
    username: "",
    workspaces: [workspaceState?.selectedWorkspace?._id],
    is_unsubscribe_emails_check: false,
    confirm_username: false,
    password: "",
    confirm_password: "",
    force_password: false,
    two_fa_enabled: false,
    donot_enforce_2fa: false,
    timezone: "",
    status: "active",
    basic_groups: [],
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine === "underline") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine === "line-through") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("line-through");
        }
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    getAllCountryData();
    getGroupsLists();
    getProfileFields();
  }, []);

  const [addResearcherParticipant, setAddResearcherParticipant] = useState({ ...createResParticipantObj });
  const setFormValue = (e) => {
    let newFormData = {
      ...addResearcherParticipant,
      [e.target.name]: e.target.value,
    };
    setAddResearcherParticipant(newFormData);
  };

  const handleToggleChange = (key) => (event) => {
    const newStatus = event.target.checked;
    setAddResearcherParticipant({
      ...addResearcherParticipant,
      [key]: newStatus,
    });
  };

  const handleTimeZone = (el) => {
    setTimeZoneVal(el);
    const newStatus = {
      ...addResearcherParticipant,
      timezone: el,
    };
    setAddResearcherParticipant(newStatus);
  };

  const getAllCountryData = () => {
    getCountries().then((response) => {
      if (response.success) {
        if (response?.data?.data.length > 0) {
          const country = response?.data?.data;
          setCountryList(country);
        }
      }
    });
  };

  const handleChange = (event) => {
    const selectedValue = event.target.value === "" ? 0 : event.target.value;
    setAddResearcherParticipant((prevGroup) => ({
      ...prevGroup,
      [event.target.name]: selectedValue,
    }));
  };

  const getGroupsLists = async () => {
    try {
      const res = await axios.get(API_BASE_URL + "/participant/groups");
      if (res.status) {
        const group = res.data?.data?.filter((grp) => grp.is_enhanced == false);
        setGroupsListData(group);
      }
    } catch (error) {
      console.error("Error fetching project lists", error);
    }
  };

  const getProfileFields = async () => {
    try {
      const res = await axios.get(
        API_BASE_URL +
        `/participant/profile-fields?workspace_id=${workspaceState?.selectedWorkspace?._id}`
      );
      if (res.status) {
        const cfields = res.data.data.filter(
          (cf) => cf.type === "my_account" && !cf.limit_participant_check
        );
        setProfileFieldData(cfields);
      }
    } catch (error) {
      console.error("Error fetching project lists", error);
    }
  };

  const validationObj = {
    hasErr: false,
    name: {
      error: false,
      msg: "",
    },
  };

  const [validationErrors, setValidationErrors] = useState({
    ...validationObj,
  });

  const handleValidation = (formInput) => {
    formInput = formInput || addResearcherParticipant;
    let validationerr = { ...validationObj };

    if (formInput.name == "") {
      validationerr.hasErr = true;
      validationerr.name = {
        error: true,
        msg: "Name is required",
      };
    }

    if (formInput.email == "") {
      validationerr.hasErr = true;
      validationerr.email = {
        error: true,
        msg: "Email is required",
      };
    }

    if (formInput.username == "") {
      validationerr.hasErr = true;
      validationerr.username = {
        error: true,
        msg: "Username is required",
      };
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  };

  const handleCreateParticipants = async () => {
    if (!handleValidation()) {
      toast.error("Please correct form fields", TOAST_CONFIG);
      return false;
    }

    setLoading(true);
    try {
      if (
        addResearcherParticipant?.password !==
        addResearcherParticipant?.confirm_password
      ) {
        toast.error("password not match");
        return;
      }
      let { confirm_password, ...participantData } = addResearcherParticipant;
      let addParticipantRes = await createResearcherParticipantAPI(
        participantData,
        authState.authToken
      );
      setLoading(false);
      if (addParticipantRes.success) {
        toast.success(addParticipantRes.message, TOAST_CONFIG);
        Navigate("/workspace-administration/all-participants");
      } else {
        toast.error(addParticipantRes.message, TOAST_CONFIG);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      updateImage(file);
    }
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput").click();
  };

  const updateImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      let imageUploadRes = await uploadUserDirectoryImage(
        formData,
        authState.authToken
      );
      if (imageUploadRes.success) {
        setAddResearcherParticipant({
          ...addResearcherParticipant,
          profile_pic: imageUploadRes?.data?.upload_id,
        });
        toast.success(imageUploadRes.message, TOAST_CONFIG);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  };

  // const chackBoxRoom = () => {
  //   return (
  //     <Box className="checkBoxItem">
  {
    /* {groupsListData.map((group, index) => (
        <Box key={index}>
          {console.log("jjj", group)}
          <input
            className="custom-checkbox"
            type="checkBox"
            // checked={group.redio === 0 ? true : false}
          />
          <Typography>{group.name}</Typography>
        </Box>
      ))} */
  }
  //       <Box>
  //         <input
  //           className="custom-checkbox"
  //           type="checkBox"
  //           // checked={redio === 0 ? true : false}
  //         />
  //         <Typography>Group 0</Typography>
  //       </Box>
  //        <Box>
  //         <input
  //           className="custom-checkbox"
  //           type="checkBox"
  //           // checked={redio === 0 ? true : false}
  //         />
  //         <Typography>Group 0</Typography>
  //       </Box>
  //     </Box>
  //   );
  // };

  const getChildren = () => {
    return (
      <Box className="researchersMainBox pageContainer">
        <Box className="allParticipantsBox workspaceListBox">
          <Box
            onClick={() =>
              handleMainOpenTabe("/workspace-administration/all-participants")
            }
            className="openBtn"
          >
            <img src={backArrow} />
            <Typography className="neonBackText">Back</Typography>
          </Box>

          <Box className="addParticipantsHeader">
            <Typography className="pageHeaderText">Add Participant</Typography>
          </Box>

          <Box className="avatarInfoBox">
            <Box className="avatarImgBox">
              {!image || image === "undefined" ? (
                <img src={DummyUser} alt="Dummy User" />
              ) : (
                <img src={image} alt="Profile" />
              )}
            </Box>
            <Box className="avatarIfoBox">
              <Typography mb={3}>
                Admin
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Box
                  mr={2}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  onClick={triggerFileInput}
                >
                  <img src={shareIcon} />
                  <Typography ml={1}>Upload Image</Typography>
                </Box>
              </Box>
            </Box>
            <input
              id="fileInput"
              style={{ display: "none" }}
              type="file"
              onChange={handleFileInputChange}
            />
          </Box>
          <Box className="redioBtnBox">
            <Box
              className="redioItem"
              onClick={() => {
                setRedio(0);
                setAddResearcherParticipant({
                  ...addResearcherParticipant,
                  status: "active",
                });
              }}
            >
              <Box
                className={
                  redio === 0 ? "customRedio customRedioActive" : "customRedio"
                }
              >
                <Box></Box>
              </Box>
              <Typography>Active</Typography>
            </Box>
            <Box
              className="redioItem"
              onClick={() => {
                setRedio(1);
                setAddResearcherParticipant({
                  ...addResearcherParticipant,
                  status: "inactive",
                });
              }}
            >
              <Box
                className={
                  redio === 1 ? "customRedio customRedioActive" : "customRedio"
                }
              >
                <Box></Box>
              </Box>
              <Typography>Inactive</Typography>
            </Box>
          </Box>
          <Typography className="allPartRedioLabel" mb={1}>
            Basic Groups
          </Typography>

          {/* <Box className="searchGroupSection">
          <Box
            className="inputAppDrop"
            onClick={() => setGroupDrop(!groupDrop)}
          >
            <Typography>Search Basic Groups</Typography>
            <img
              style={{ rotate: groupDrop ? "180deg" : "0deg" }}
              src={dropArrow}
            />
          </Box>
          <Box
            sx={{ height: groupDrop ? "auto" : "0px" }}
            className="checkMain_box"
          > */}
          <Box className="ckeckBoxRoom">
            <FormControl sx={{ width: "100%" }} size="small">
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={addResearcherParticipant?.basic_groups}
                onChange={handleChange}
                name="basic_groups"
                multiple
                displayEmpty
                MenuProps={MenuProps}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <p>Select Groups</p>;
                  }
                  return selected
                    .map((value) => {
                      const group = groupsListData.find(
                        (group) => group._id === value
                      );
                      return group ? group.name : value;
                    })
                    .join(", ");
                }}
              >
                {groupsListData.map((group, index) => (
                  <MenuItem value={group._id} key={index}>
                    {group.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Typography className="allPartRedioLabel" mt={3} mb={2}>
            Account Details
          </Typography>
          <Box className="csPBox">
            <Typography mb={1}>Name</Typography>
            <input
              className="phoneInput"
              placeholder="Enter Name"
              name="name"
              value={addResearcherParticipant?.name}
              onChange={setFormValue}
            />
            <span className="validationerr">
              {validationErrors.name?.msg}
            </span>
          </Box>
          <Box className="csPBox" mt={2}>
            <Typography mb={1}>Email Address</Typography>
            <input
              className="phoneInput"
              placeholder="Enter Email Address"
              name="email"
              value={addResearcherParticipant?.email}
              onChange={setFormValue}
            />
            <span className="validationerr">
              {validationErrors.email?.msg}
            </span>
          </Box>

          <Box className="airadioSwitchBox">
            <AntSwitch
              inputProps={{ "aria-label": "unsubscribe emails" }}
              checked={addResearcherParticipant?.is_unsubscribe_emails_check}
              onChange={handleToggleChange("is_unsubscribe_emails_check")}
            />
            <Typography className="switchText">
              Unsubscribe for all emails
            </Typography>
          </Box>
          <Box className="csPBox">
            <Typography mb={1}>Username</Typography>
            <Typography fontSize={"12px"} mb={1}>
              No spaced or special characters. Do not include the last name.
            </Typography>
            <input
              className="phoneInput"
              placeholder="Enter User Name"
              name="username"
              value={addResearcherParticipant?.username}
              onChange={setFormValue}
            />
            <span className="validationerr">
              {validationErrors.username?.msg}
            </span>
          </Box>
          <Box className="airadioSwitchBox">
            <AntSwitch
              inputProps={{ "aria-label": "confirm username" }}
              checked={addResearcherParticipant.confirm_username}
              onChange={handleToggleChange("confirm_username")}
            />
            <Typography className="switchText">
              Must confirm username
            </Typography>
          </Box>
          <Typography mb={1}>New Password</Typography>
          <Typography fontSize={"12px"} mb={1}>
            At least 8 characters
          </Typography>
          <Box mb={1} className="csPBox pwdInput">
            <input
              className="phoneInput"
              placeholder="Enter Password"
              type={pwdVisible ? "text" : "password"}
              name="password"
              onChange={setFormValue}
              value={addResearcherParticipant?.password}
              autoComplete='new-password'
            />
            <img src={VisibleIcon} onClick={() => setPwdVisible(!pwdVisible)} />
          </Box>
          <Box className="csPBox pwdInput">
            <input
              className="phoneInput"
              placeholder="Confirm New Password"
              type={cpwdVisible ? "text" : "password"}
              name="confirm_password"
              onChange={setFormValue}
              value={addResearcherParticipant?.confirm_password}
            />
            <img
              src={VisibleIcon}
              onClick={() => setCpwdVisible(!cpwdVisible)}
            />
          </Box>
          <Box className="airadioSwitchBox">
            <AntSwitch
              inputProps={{ "aria-label": "forgot password change" }}
              checked={addResearcherParticipant?.force_password}
              onChange={handleToggleChange("force_password")}
            />
            <Typography className="switchText">
              Force Password Change
            </Typography>
          </Box>
          <Typography className="allPartRedioLabel" mb={1}>
            Two-factor authentication
          </Typography>
          <Typography className="" mb={1}>
            Two-factor authentication adds an extra layer of security to your
            account. It makes login more secure by asking for a One-Time
            Password (OTP), which can be generated on your phone or computer.
          </Typography>
          {/* <Box className="airadioSwitchBox enS">
          <AntSwitch inputProps={{ "aria-label": "ant design" }} />
          <Typography className="switchText">Enabled</Typography>
        </Box> */}
          <Box ml={3}>
            <Box className="airadioSwitchBox enS">
              <AntSwitch
                inputProps={{ "aria-label": "enabled" }}
                checked={addResearcherParticipant?.two_fa_enabled}
                onChange={handleToggleChange("two_fa_enabled")}
              />
              <Typography className="switchText">Enabled</Typography>
            </Box>
            <Box mt={2} mb={6} className="alpOverCheck">
              <input
                style={{ marginRight: "16px" }}
                className="custom-checkbox"
                type="checkBox"
                checked={addResearcherParticipant?.donot_enforce_2fa}
                onChange={handleToggleChange("donot_enforce_2fa")}
              // defaultChecked
              />
              <Typography>Override: Do not enforce</Typography>
            </Box>
          </Box>
          <p className="allPartRedioLabel">Time Zone</p>
          <p style={{ marginBottom: "24px", marginTop: "10px" }} mb={1}>
            If Daylight Saving Time is observed at any point during the year,
            opt for "Daylight Time."
          </p>

          <Box mt={3} className="companiesTopBox">
            <Box className="companyInputBoxWlabel">
              <Typography mb={1} className="inputLabel">
                Select Country
              </Typography>

              <Box
                className="inputAppDrop"
                onClick={() => setCountryDrop(!countryDrop)}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {/* <img
                  className="countryListImg"
                  src={
                    countryList[countryDropVal]?.img ||
                    countryList[0]?.img
                  }
                /> */}
                  <Typography ml={2}>
                    {countryList[countryDropVal]?.emoji ||
                      countryList[0]?.emoji}

                    {countryList[countryDropVal]?.name || countryList[0]?.name}
                  </Typography>
                </Box>
                <img
                  style={{ rotate: countryDrop ? "180deg" : "0deg" }}
                  src={DownArrowIcon}
                />
                {/* pop */}
                <Box
                  sx={{
                    height: countryDrop ? "180px" : "0px",
                    overflowY: countryDrop ? "scroll" : "hidden",
                  }}
                  className="statusPop"
                >
                  {countryList &&
                    countryList.map((el, index) => (
                      <Box
                        key={index}
                        onClick={() => {
                          setCountryDropVal(index);
                          setAddResearcherParticipant({
                            ...addResearcherParticipant,
                            country: el._id,
                          });
                          setTimezoneList(el.timezones);
                        }}
                        className="statusPopItem"
                      >
                        {/* <img className="countryListImg" src={el.img} /> */}
                        <Typography ml={2}>
                          {el.emoji} {el.name}
                        </Typography>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
            {/* drop */}
            <Box className="companyInputBoxWlabel">
              <Typography mb={1} className="inputLabel">
                Select Time Zone
              </Typography>
              <Box
                className="inputAppDrop"
                onClick={() => setTimeZoneDrop(!timeZoneDrop)}
              >
                <Typography>
                  {timeZoneVal || "Filter by Workspace Status"}
                </Typography>
                <img
                  style={{
                    rotate: timeZoneDrop ? "180deg" : "0deg",
                  }}
                  src={DownArrowIcon}
                />
                {/* pop */}
                <Box
                  sx={{ height: timeZoneDrop ? "auto" : "0px" }}
                  className="statusPop"
                >
                  {timeZoneList &&
                    timeZoneList?.map((el, index) => (
                      <Box
                        key={index}
                        onClick={() => handleTimeZone(el?.zoneName)}
                        className="statusPopItem"
                      >
                        <Typography>{el?.zoneName}</Typography>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="profileFieldNav">
            <Typography className="allPartRedioLabel">
              Profile Fields
            </Typography>
            <Typography
              className="profileFieldNav2ndText"
              onClick={() => console.log("hello")}
            >
              Add Profile Fields
            </Typography>
          </Box>

          <Box className="">
            {profileFieldData &&
              profileFieldData.map((field, index) => (
                <RenderCustomField
                  profileField={field}
                  addResearcherParticipant={addResearcherParticipant}
                  setAddResearcherParticipant={setAddResearcherParticipant}
                  key={index}
                />
              ))}
          </Box>
          {/* Profile Fields */}
          {/* <Box>
          <Box className="Text_BoxWDot participantProfileField">
            {" "}
            <img src={optionIcon} />{" "}
            <Typography>What is your current age?</Typography>
          </Box>
          <Box className="input_Box profileAnswerBox">
            <input
              type="text"
              className="eTemInput"
              placeholder="Enter your response here"
            />
          </Box>

          <Box className="Text_BoxWDot participantProfileField dobProfileFields">
            {" "}
            <img src={optionIcon} />{" "}
            <Typography>What is your date of birth?</Typography>
          </Box>
          <Box className="pickerBox dobInputProfileField profileAnswerBox">
            <ReferenceDateUsingValue />
          </Box>

          <Box className="Text_BoxWDot participantProfileField dobProfileFields">
            {" "}
            <img src={optionIcon} />{" "}
            <Typography>What is your workout timing?</Typography>
          </Box>
          <Box className="pickerBox dobInputProfileField profileAnswerBox">
            <ReferenceDateExplicitTimePicker />
          </Box>

          <Box className="Text_BoxWDot participantProfileField">
            {" "}
            <img src={optionIcon} />{" "}
            <Typography>
              What is your current household income?
            </Typography>
          </Box>

          <Box className="profileFieldBox">
            {profileFieldData &&
              profileFieldData.map((field, index) => (
                <RenderCustomField
                  profileField={field}
                  addResearcherParticipant={addResearcherParticipant}
                  setAddResearcherParticipant={setAddResearcherParticipant}
                  key={index}
                />
              ))}
          </Box>
          {/* Profile Fields */}
          {/* <Box>
            <Box className="Text_BoxWDot participantProfileField">
              {" "}
              <img src={optionIcon} />{" "}
              <Typography>What is your current age?</Typography>
            </Box>
            <Box className="input_Box profileAnswerBox">
              <input
                type="text"
                className="eTemInput"
                placeholder="Enter your response here"
              />
            </Box>

            <Box className="Text_BoxWDot participantProfileField dobProfileFields">
              {" "}
              <img src={optionIcon} />{" "}
              <Typography>What is your date of birth?</Typography>
            </Box>
            <Box className="pickerBox dobInputProfileField profileAnswerBox">
              <ReferenceDateUsingValue />
            </Box>

            <Box className="Text_BoxWDot participantProfileField dobProfileFields">
              {" "}
              <img src={optionIcon} />{" "}
              <Typography>What is your workout timing?</Typography>
            </Box>
            <Box className="pickerBox dobInputProfileField profileAnswerBox">
              <ReferenceDateExplicitTimePicker />
            </Box>

            <Box className="Text_BoxWDot participantProfileField">
              {" "}
              <img src={optionIcon} />{" "}
              <Typography>What is your current household income?</Typography>
            </Box>
            <Box className="input_Box profileAnswerBox">
              <input
                type="text"
                className="eTemInput"
                placeholder="Enter your response here"
              />
            </Box>

            <Box className="Text_BoxWDot participantProfileField dobProfileFields">
              {" "}
              <img src={optionIcon} />{" "}
              <Typography>What gender do you identify as?</Typography>
            </Box>
            <Box className="profileAnswerBox">
              <Box className="radioBox">
                <Box>
                  <input
                    className="emailSettingInput emailSettingRadio"
                    type="radio"
                    name="activity"
                  />
                </Box>
                <Box>
                  <Typography className="emailSettingLabel">Male</Typography>
                </Box>
              </Box>
              <Box className="radioBox">
                <Box>
                  <input
                    className="emailSettingInput emailSettingRadio"
                    type="radio"
                    name="activity"
                  />
                </Box>
                <Box>
                  <Typography className="emailSettingLabel">Female</Typography>
                </Box>
              </Box>
              <Box className="radioBox">
                <Box>
                  <input
                    className="emailSettingInput emailSettingRadio"
                    type="radio"
                    name="activity"
                  />
                </Box>
                <Box>
                  <Typography className="emailSettingLabel">Other</Typography>
                </Box>
              </Box>
            </Box>

            <Box className="Text_BoxWDot participantProfileField dobProfileFields">
              {" "}
              <img src={optionIcon} />{" "}
              <Typography>
                Write a brief introduction about yourself?
              </Typography>
            </Box>
            <Box className="profileAnswerBox">
              <textarea
                className="DmettingTextTextAera"
                placeholder="Enter your response here"
              />
            </Box>

            <Box className="Text_BoxWDot participantProfileField dobProfileFields">
              {" "}
              <img src={optionIcon} />{" "}
              <Typography>Why you are joining Terapage?</Typography>
            </Box>
            <Box className="profileAnswerBox">
              <Box className="emailInputBox formattedProfileAnswer">
                <Box className="emailInputBoxNav">
                  <Box
                    onClick={() => setMessageTextItalic(!messageTextItalic)}
                    className="emailImgBox"
                  >
                    <img src={IIcon} />
                  </Box>
                  <Box
                    onClick={() => setMessageTextBold(!messageTextBold)}
                    className="emailImgBox"
                  >
                    <img src={BIcon} />
                  </Box>
                  <Box
                    onClick={() => handelTextlineChekc("underline", "message")}
                    className="emailImgBox"
                  >
                    <img src={UnderLineU} />
                  </Box>
                  <Box
                    onClick={() =>
                      handelTextlineChekc("line-through", "message")
                    }
                    className="emailImgBox"
                  >
                    <img src={MLineU} />
                  </Box>
                  <Box className="emailImgBox">
                    <img src={AtachMentIcon} />
                  </Box>
                  <Box className="emailImgBox">
                    <img src={Qbox} />
                  </Box>
                  <Box
                    onClick={() => setMessageTextLaOut("start")}
                    className="emailImgBox"
                  >
                    <img src={Line1} />
                  </Box>
                  <Box
                    onClick={() => setMessageTextLaOut("center")}
                    className="emailImgBox"
                  >
                    <img src={Line2} />
                  </Box>
                  <Box
                    onClick={() => setMessageTextLaOut("end")}
                    className="emailImgBox"
                  >
                    <img src={Line3} />
                  </Box>
                  <Box className="emailImgBox">
                    <img src={Line4} />
                  </Box>
                  <Box className="emailImgBox">
                    <img src={emoji} />
                  </Box>
                </Box>
                <textarea
                  style={{
                    textAlign: messageTextLaOut,
                    fontStyle: messageTextItalic ? "italic" : "normal",
                    fontWeight: messageTextBold ? "bold" : "normal",
                    textDecoration: messageTextUnderLine,
                    // textDecoration: messageTextMLine ? "line-through" : null
                  }}
                  className="emailInput"
                  name="emailMessage"
                  placeholder="Enter your response here"
                  onChange={handelChange}
                />
              </Box>
            </Box>

            <Box className="Text_BoxWDot participantProfileField dobProfileFields">
              {" "}
              <img src={optionIcon} />{" "}
              <Typography>What gender do you identify as?</Typography>
            </Box>
            <Box
              className="uploadVideoProfileAnswerBox profileAnswerBox"
              onClick={() => {
                document.getElementById("fileInput").click();
              }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <Box className="imgUplodInputBox deleteTopicIcon">
                <input
                  id="fileInput"
                  type="file"
                  accept="video/*"
                  style={{ display: "none" }}
                  onChange={handleFileInputChange}
                />
                <img src={videoCameraIcon} alt="Camera" />
              </Box>
              <Typography className="imageUploadLabelText deleteTopicIcon">
                Record <span>or</span> Upload Video
              </Typography>
            </Box>

            <Box className="Text_BoxWDot participantProfileField dobProfileFields">
              {" "}
              <img src={optionIcon} />{" "}
              <Typography>What gender do you identify as?</Typography>
            </Box>
            <Box
              className="uploadVideoProfileAnswerBox profileAnswerBox"
              onClick={() => {
                document.getElementById("fileInput").click();
              }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <Box className="imgUplodInputBox deleteTopicIcon">
                <input
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileInputChange}
                />
                <img src={CameraIcon} alt="Camera" />
              </Box>
              <Typography className="imageUploadLabelText deleteTopicIcon">
                Capture <span> or </span>Upload Photo
              </Typography>
            </Box>

            <Box className="Text_BoxWDot participantProfileField dobProfileFields">
              {" "}
              <img src={optionIcon} /> <Typography>On-the-dot Task</Typography>
            </Box>
            <Box className="onTheDotProfileAns">
              <img src={personalGolBgImg} />
              <Box className="btnboxWText">
                <Typography className="">
                  Before we proceed, Complete this small task
                </Typography>
                <Typography className="startbgSubText">
                  Status <span> Not Started</span>
                </Typography>
                <Box className="onTheDotAnsBtnBox">
                  <AppBtn buttonText="Continue" />
                </Box>
              </Box>
            </Box>
          </Box> */}

          {/* Profile Fields */}
          <Box className="workPupBtnBox">
            <AppBtnOutLine
              buttonText="Cancel"
              onClick={() =>
                handleMainOpenTabe(
                  "/workspace-administration/all-participants"
                )
              }
            />
            <AppBtn
              buttonText="Save"
              onClick={() => handleCreateParticipants()}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return renderUserLayout(authState?.userType, null, getChildren(), 'workadmin');
}
