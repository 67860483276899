import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import "./style.css";


import PASideMenu from "../../../Components/Participants/AccountSettingSideMenu";
import { sideHaumIcon } from "../../../Assets/Data";


export default function ContactTicket({ slideMenu, setSlideMenu, workspaceDrop, setWorkSpaceDrop }) {

  return (
    <>
      <Box className="ResearchersContainer ">
        {/* side menu */}
        <PASideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer">
            <Box className="EmailNContainer">
              <Typography className='emailNotificationHeaderText'>Click Links to go on the Pages</Typography>
              <Typography className='contactSubHeadText'>Contact Link</Typography>
              <Box my={3} className="iBox">
                <Typography className='supportLinks'>
                  www.terapage.ai/contactus.html
                </Typography>
              </Box>
              <Typography className='contactSubHeadText'>Ticket Link</Typography>
              <Box my={3} className="iBox">
                <Typography className='supportLinks'>
                  https://terapage.raiseaticket.com/support/#/newticket
                </Typography>
              </Box>

            </Box>
          </Box>
        </Box>
      </Box>
    </>

  )
}
