import React, { useState, useEffect } from 'react'
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./style.css";
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import SideMenu from "../../../Components/Researchers/SideMenu";
import { sideHaumIcon, timeZone1, timeZone2, timeZone3, editIconBlack, activeCTrashIcon } from "../../../Assets/Data";
import moment from "moment-timezone";

//images
import iIcon from "../../../Assets/Images/IIcon.png";
import groupIcon from "../../../Assets/Images/groupsIcon.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";
import chackBox from "../../../Assets/Images/Checkbox.png";
import check from "../../../Assets/Images/check.png";
import calenderW from "../../../Assets/Images/calendarWhiteIcon.png"
import avatar from "../../../Assets/Images/timeSchduleimg.png"
import mettingImg from "../../../Assets/Images/mettingImg.png"
import arrow from "../../../Assets/Images/arrow.png";
import PreparedItemsNoData from "../../../Assets/Images/No data-cuate 1.png";
import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import redTrash from "../../../Assets/Images/redTrash.png";
import plassIcon from "../../../Assets/Images/plassIcon.png";
import imageBanner from "../../../Assets/Images/imageBanner.png"
import uploadIcon from "../../../Assets/Images/uil_upload.png";
import trasIcon from "../../../Assets/Images/fluent_delete-16-regular.png";
import OptionIcon from "../../../Assets/Images/optionIcon.png";
import PrepItem2 from "../../../Assets/Images/prepItem2.png"
import checkIcon from "../../../Assets/Images/checkG.png"
import redWIcon from "../../../Assets/Images/alert-circle.png"
import leftArrow from "../../../Assets/Images/arrow-left.png";
import rightArrow from "../../../Assets/Images/arrow-right.png";
import chevronDown from "../../../Assets/Images/chevron-down.png";
import Avatar1 from "../../../Assets/Images/Avatar1.png";
import rec1 from "../../../Assets/Images/rec1.png"
import backArrow from "../../../Assets/Images/noun-back.png";
import bulkIcon from "../../../Assets/Images/bulkIcon.png"
import video1 from "../../../Assets/Images/Videos/part1.mp4"
import filterIcon from "../../../Assets/Images/filterIcon.png"
import aiWhiteIcon from "../../../Assets/Images/aiIconWhite.png"
import exportIcon from "../../../Assets/Images/exportIcon.png";
import dropIcon from "../../../Assets/Images/chevron-down.png";
import avatar1 from "../../../Assets/Images/inb1.png"
import avatar2 from "../../../Assets/Images/inb2.png"
import avatar3 from "../../../Assets/Images/inb3.png"
import Option2LIcon from "../../../Assets/Images/Option2LIcon.png";
import editIcon from "../../../Assets/Images/edit 1.png";
import editBtn from "../../../Assets/Images/edit 1.png"
import whitedropIcon from "../../../Assets/Images/Wchevron-down.png";
import semoji from "../../../Assets/Images/Semoji.png"
import imgIcon from "../../../Assets/Images/galleryIconB.png"

import { activeParticipants, taskList } from "../../../Assets/Data"

//comonents
import { AppBtnOutLine, AppBtn, AppAddBtn } from "../../../Components/AppTools/AppButton"
import { Status, SearchBar, AllDrop7 } from "../../../Components/AppTools/AppTool"
import ReferenceDateUsingValue from "../../../Components/AppTools/DatePicker"
import LiveInterViewPop from "../../../Components/Researchers/LiveInterviewPop"
import UpdateTimeZonePop from '../../../Components/Researchers/UpdateTimeZonePop';
import EditMeetingPop from '../../../Components/Researchers/EditMeetingPop';
import ScheduleInterviewParticipantPop from '../../../Components/Researchers/ScheduleInterviewParticipantPop';
import SystemCheckPop from "../../../Components/Researchers/SystemCheckPop";
import AiIntegrationPop from '../../../Components/Researchers/AiIntegrationPop';

import { ApexChart } from "../../../Components/AppTools/PieChart";
import BubbleChart from "../../../Components/AppTools/BubbleChart"
import { BarChart } from "../../../Components/AppTools/BarChart"

//data
import { languages } from "../../../Assets/Data";
import axios from '../../../lib/axios';
import { useAppSelector } from '../../../lib/store/store';
import { useNavigate, useParams } from 'react-router';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { TOAST_CONFIG } from '../../../env';
import { researcherAction } from '../../../Services/researcherActions';
import { formatDate, getDatePickerVal, viewImage } from '../../../lib/helpers';
import DeleteInterviewScheduledPop from '../DeleteInterviewScheduledPop';
import Pagination from '../../../Components/AppTools/Pagination';

export default function InterviewScheduled({ slideMenu, setSlideMenu, workspaceDrop, setWorkSpaceDrop }) {

  const authState = useAppSelector((state) => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace);
  const Navigate = useNavigate();
  const { activityId } = useParams();

  const [nav, setNav] = useState(0)
  const [nav2, setNav2] = useState(0)
  const [nav3, setNav3] = useState(0)
  const [groupDrop2, setGroupDrop2] = useState(false);
  const [chackVal, setChackVal] = useState([]);
  const [timeZoneIndex1, setTimeZoneIndex1] = useState()
  const [timeZoneIndex2, setTimeZoneIndex2] = useState()
  const [timeZoneIndex3, setTimeZoneIndex3] = useState()
  const [liveInterViewPop, setLiveInterViewPop] = useState(false)
  const [preparedItemsPageVal, setPreparedItemsPageVal] = useState(false)
  const [updateTimeZonePop, setUpdateTimeZonePop] = useState(false);
  const [editMeetingPop, setEditMeetingPop] = useState(false);
  const [prepNavItem, setPrepNavItem] = useState(0)


  const email1 = "";
  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();
  const [workspaceStatusdrop, setWorkspaceStatusdrop] = useState(false);
  const [workspaceStatusDropVal, setworkspaceStatusDropVal] = useState("");
  const [attributeCount, setAttributeCount] = useState(1)
  const [companyStatusDrop, setCompanyStatusDrop] = useState(false);
  const [compantStatusDropVal, setCompantStatusDropVal] = useState("");
  const [image, setImage] = useState(null);
  const [preparedItem, setPreparedItem] = useState(true)
  const [preparedItemOptionPop, setPreparedItemOptionPop] = useState(false)
  const [preparedItemOptionPop2, setPreparedItemOptionPop2] = useState(false)
  const [radio, setRadio] = useState(0)
  const [scheduleInterviewParticipantPop, setScheduleInterviewParticipantPop] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [newArrayData, setNewArrayData] = useState([]);
  const [participantsResNavDrop, setParticipantResNavDrop] = useState(false);
  const [participantsResNavDropVal, setParticipantResNavDropVal] = useState("All Tasks");
  const [recordIndexPage, setRecordIndexPage] = useState(true);
  const [expoMettingDrop, setExpoMettingDrop] = useState(false);
  const [expoMettingDropVal, setExpoMettingDropVal] = useState("Export Meeting");
  const [allDrop2, setAllDrop2] = useState(false);
  const [allDrop2Val, setAllDrop2Val] = useState("Activity Options");
  const [systemCheckPop, setSystemCheckPop] = useState(false);
  const [providerDrop, setProviderDrop] = useState(false)
  const [providerDropVal, setProviderDropVal] = useState()
  const [providerDrop1, setProviderDrop1] = useState(false)
  const [providerDropVal1, setProviderDropVal1] = useState()
  const [providerDrop2, setProviderDrop2] = useState(false)
  const [providerDropVal2, setProviderDropVal2] = useState()
  const [providerDrop3, setProviderDrop3] = useState(false)
  const [providerDropVal3, setProviderDropVal3] = useState()
  const [lang, setLang] = useState(false)
  const [langVal, setLangVal] = useState()
  const [aiSubnav, setAiSubNav] = useState(0);
  const [exportDrop, setExportDrop] = useState(false);
  const [exportDrop2, setExportDrop2] = useState(false);
  const [exportDrop3, setExportDrop3] = useState(false);
  const [exportDrop4, setExportDrop4] = useState(false);
  const [aiIntegrationPop, setAiIntegrationPop] = useState(false);
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const expoMettingItem = [
    "Video : Front Room",
    "Video : English",
    "Meeting Transcript (Excel",
    "Meeting Transcript (HTML)",
    "Subtitles : Front Room (VTT)",
    "Subtitles : Front Room + Backroom (VTT)",
    "Subtitles : English (VTT)"
  ]



  const exportItem = ["PNG", "JPEG", "PDF", "SVG"];
  const [series] = useState([{ data: [400, 430, 448, 470, 540] }]);
  const categories = ['Product Reviews', 'Price', 'Brand Reputation', 'Durabilty/Quality', 'Aesthetics'];

  const [series2] = useState([{ data: [400, 430, 448, 470, 540] }]);
  const categories2 = ['Relaxation', 'Great Quote', 'Nostalgia', 'Empowerment'];


  const handelTimerClick = (i, e) => {
    if (e?.target?.id === "hostS") {
      return
    } else {
      if (slotIndx === i) {
        setSlotIndx(-1)
        return;
      } else {
        setSlotIndx(i)
      }
    }
  }



  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
  };


  const lineBar = 80
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#4F33D1",
    },
  }));


  const pushData = (string) => {
    setChackVal((prev) => [...prev, string]);
  };
  const deleteAllCheckData = () => setChackVal([]);
  const selectAllCheckData = () => {
    const values = hosts.map(el => el._id);
    setChackVal(values);
  };

  const renderComponents = (value) => {
    let components = [];
    for (let i = 0; i < value; i++) {
      components.push(<Attribute count={i} key={i} />);
    }
    return components;
  };



  const handelDeleteAttribute = () => {
    setAttributeCount(attributeCount - 1)
  }
  const handelAddAttribute = () => {
    setAttributeCount(attributeCount + 1)
  }


  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };


  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };



  const Attribute = ({ count }) => {
    return (
      <Box className="AttributeBox">
        <Box className="delete_btn" onClick={handelDeleteAttribute}>
          <Box>
            <img src={redTrash} />
          </Box>
          <Box>
            <p>{t("commonTxt.delete")}</p>
          </Box>
        </Box>


        <Box className="companiesTopBox">
          <Box className="companyInputBoxWlabel">
            <Typography mb={1} className="inputLabel"> {t("LiveInterview.attribute")}: {count * 1 + 1}</Typography>

            <Box
              className="inputAppDrop"
              onClick={() => setCompanyStatusDrop(!companyStatusDrop)}
            >
              <Typography>
                {compantStatusDropVal || "Age"}
              </Typography>
              <img
                className="PartDropIcon"
                style={{ rotate: companyStatusDrop ? "180deg" : "0deg" }}
                src={DownArrowIcon}
              />
              {/* pop */}
              <Box
                sx={{ height: companyStatusDrop ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setCompantStatusDropVal("Item-A")}
                  className="statusPopItem"
                >
                  <Typography>Item-A</Typography>
                  <Typography>Hello</Typography>
                </Box>
                <Box
                  onClick={() => setCompantStatusDropVal("Item-B")}
                  className="statusPopItem"
                >
                  <Typography>Item-B</Typography>
                </Box>
                <Box
                  onClick={() => setCompantStatusDropVal("Item-C")}
                  className="statusPopItem"
                >
                  <Typography>Item-C</Typography>
                </Box>
                <Box
                  onClick={() => setCompantStatusDropVal("Item-D")}
                  className="statusPopItem"
                >
                  <Typography>Item-D</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* workspace status */}
          <Box className="companyInputBoxWlabel">
            <Typography mb={1} className="inputLabel"> {t("LiveInterview.scale")} </Typography>

            <Box
              className="inputAppDrop"
              onClick={() => setWorkspaceStatusdrop(!workspaceStatusdrop)}
            >
              <Typography>
                {workspaceStatusDropVal || "Less than"}
              </Typography>
              <img
                className="PartDropIcon"
                style={{
                  rotate: workspaceStatusdrop ? "180deg" : "0deg",
                }}
                src={DownArrowIcon}
              />
              {/* pop */}
              <Box
                sx={{ height: workspaceStatusdrop ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setworkspaceStatusDropVal("Item-A")}
                  className="statusPopItem"
                >
                  <Typography>Item-A</Typography>
                </Box>
                <Box
                  onClick={() => setworkspaceStatusDropVal("Item-B")}
                  className="statusPopItem"
                >
                  <Typography>Item-B</Typography>
                </Box>
                <Box
                  onClick={() => setworkspaceStatusDropVal("Item-C")}
                  className="statusPopItem"
                >
                  <Typography>Item-C</Typography>
                </Box>
                <Box
                  onClick={() => setworkspaceStatusDropVal("Item-D")}
                  className="statusPopItem"
                >
                  <Typography>Item-D</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="addressBox">
          <Typography className="companyInputLabel">{t("LiveInterview.value")}</Typography>
          <input
            type="text"
            className="addInput"
            placeholder={t("LiveInterview.value")}
            value="40"
          />
        </Box>

      </Box>
    )
  }


  const recordCard = () => {
    return (
      <Box className="recordCard" onClick={() => setRecordIndexPage(false)}>
        <Box className="detail">
          <Box className="rcAvatar"><img src={Avatar1} /></Box>
          <Box className="rcText">
            <Typography>Olivia Rhye</Typography>
            <span>30/07/2023, 1:00 AM (15 Min)</span>
          </Box>
        </Box>
        <Box className="recordCover">
          <img src={rec1} />
        </Box>
      </Box>
    )
  }
  function wrapAtWordsWithSpan(text) {
    return text.replace(/@(\w+)/g, "<span>@$1</span>");
  }



  const singelComment = ({ img, name, comment, date, time }) => {
    return (
      <Box className="singelComment">
        <Box className="commentSection innerCommentSection">
          <Box className="avatarBox">
            <img src={img} />
          </Box>
          <Box className="commentTextSection">
            <Box className="userNameBox">
              <Typography className='userName'>{name}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "15px",
                  minWidth: "213px"
                }}
              >
                <Box className="editbtn">
                  <img src={editBtn} />
                  <Typography>{t("commonTxt.edit")}</Typography>
                </Box>
                <samp className="taskHeaderText3">
                  {date}
                </samp>
              </Box>
            </Box>

            <Typography className='commentWtag' dangerouslySetInnerHTML={{ __html: wrapAtWordsWithSpan(comment) }}></Typography>
            <Box className="likeBox">
              <Typography>{time}</Typography>
              <Typography>{t("commonTxt.Like")}</Typography>
              <Typography>{t("commonTxt.dislike")}</Typography>
              <Typography>{t("commonTxt.reply")}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

    )
  }



  const writeCommentSection = ({ img, postDrop, setPostDrop, postDropVal, setPostDropVal }) => {
    return (
      <Box className="writeCommentSection">
        <Box className="avatarBox">
          <img src={img} />
        </Box>
        <Box className="commentInputBox">
          <input className='phoneInput' placeholder='Add comment' />
          <img className='inputIcon1' src={semoji} />
          <img className='inputIcon2' src={imgIcon} />
          <Box className="commentCheckBox">
            <Box
              mb={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                minWidth: "206px"
              }}
            >
              <input
                style={{ marginRight: "10px" }}
                className="custom-checkbox"
                type="checkBox"
              />
              <Typography>{t("recapThread.makeReplyCompulsory")}</Typography>
            </Box>

            <Typography className='followText'>{t("emailPage.temp")}</Typography>
          </Box>
        </Box>

        <Box className="directMessageNavBtn" onClick={() => setPostDrop(!postDrop)}>
          <Typography className='DropBtnText'>{t("commonTxt.postBtn")}</Typography>
          <img src={whitedropIcon} style={{ rotate: postDrop ? "180deg" : "0deg" }} />
          <Box className="workspaceItemNavPop" sx={{ display: postDrop ? "flex" : "none" }}>
            <Box onClick={() => setPostDropVal("Visible to Everyone")}>
              <Typography>{t("recapThread.visibleToEveryone")}</Typography>
            </Box>
            <Box onClick={() => setPostDropVal("Only Andi Lane")}>
              <Typography>{t("recapThread.onlyAndiLane")}</Typography>
            </Box>
            <Box onClick={() => setPostDropVal("Backroom Comment")}>
              <Typography>{t("recapThread.backroomComment")}</Typography>
            </Box>
            <Box onClick={() => setPostDropVal("Backroom Task")}>
              <Typography>{t("recapThread.backroomTask")}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }


  const nestedCommentSection = ({ img, name, comment, date, time, postDrop, setPostDrop, postDropVal, setPostDropVal }) => {
    return (
      <Box className="singelComment">
        <Box className="commentSection innerCommentSection">
          <Box className="avatarBox">
            <img src={img} />
          </Box>
          <Box className="commentTextSection">
            <Box className="userNameBox">
              <Typography className='userName'>{name}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "15px",
                  minWidth: "213px"
                }}
              >
                <Box className="editbtn">
                  <img src={editBtn} />
                  <Typography>{t("commonTxt.edit")}</Typography>
                </Box>
                <samp className="taskHeaderText3">
                  {date}
                </samp>
              </Box>
            </Box>

            <Typography className='commentWtag' dangerouslySetInnerHTML={{ __html: wrapAtWordsWithSpan(comment) }}></Typography>
            <Box className="likeBox">
              <Typography>{time}</Typography>
              <Typography>{t("commonTxt.Like")}</Typography>
              <Typography>{t("commonTxt.dislike")}</Typography>
              <Typography>{t("commonTxt.reply")}</Typography>
            </Box>
            {singelComment({
              img: avatar3,
              name: "Kate Morrison",
              comment: "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
              date: "Aug 10, 2023",
              time: "10 h"
            })}
            {writeCommentSection({ img: avatar1, postDrop, setPostDrop, postDropVal, setPostDropVal })}
          </Box>
        </Box>
      </Box>

    )
  }



  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  useEffect(() => {
    printItemsByState(activeParticipants, currentPage);
  }, [currentPage]);

  useEffect(() => {
    getHosts();
    getSlots();
    getSchedules(1);
    getNextMeeting();
    getScheduleParticipants();
  }, []);

  const [hosts, setHosts] = useState([]);
  const [availableHosts, setAvailableHosts] = useState([]);
  const [scheduleList, setScheduleList] = useState([]);
  const [nextSchedule, setNextSchedule] = useState(null);
  const [participants, setParticipans] = useState([]);
  const [scheduleParticipans, setScheduleParticipans] = useState([]);
  const [allSlots, setAllSlots] = useState([]);
  const [slotIndx, setSlotIndx] = useState();
  const [isHostPopupOpen, setIsHostPopupOpen] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [deleteScheduledPop, setDeleteScheduledPop] = useState(false);
  const [scheduleId, setScheduleId] = useState(null);
  const [scheduleText, setScheduleText] = useState(null);
  const initialFilter = {
    interview_date: formatDate(new Date(), "YYYY-MM-DD"),
    hosts: chackVal
  }

  const [filter, setFilter] = useState(initialFilter);
  const scheduleObj = {
    activity_id: activityId,
    meeting_type: "interview",
    workspace_id: workspaceState?.selectedWorkspace?._id,
    project_id: workspaceState?.selectedProject?._id,
    interview_date: filter.interview_date,
    start_time: null,
    end_time: null,
    hosts: [],
    participants: []
  }
  const [scheduleCreate, setScheduleCreate] = useState(scheduleObj);
  useEffect(() => {
    if (reloadList) {
      getSlots();
      getSchedules();
      getNextMeeting();
    }
  }, [reloadList]);

  useEffect(() => {
    getSlots(filter);
  }, [filter, chackVal]);

  const toggleHost = (newHost) => {
    setScheduleCreate((prev) => {
      const hosts = prev.hosts.includes(newHost)
        ? prev.hosts.filter((host) => host !== newHost)
        : [...prev.hosts, newHost];
      return { ...prev, hosts };
    });
  };

  const checkDayType = (date) => {
    const givenDate = new Date(date);
    const dayOfWeek = givenDate.getDay();

    if (dayOfWeek === 0 || dayOfWeek === 6) {
      return "Weekends";
    } else {
      return "Weekdays";
    }
  }

  const hostPopupOpen = async (el, e) => {
    handelTimerClick(el.key, e);
    const days = checkDayType(filter.interview_date);
    if (!allSlots?.meeting_days.includes(days))
      return toast.error(`Please schedule meeting on ${days}.`);

    const response = await axios.get(`/researchers/get-activity-admins?activity_id=${activityId}`, {
      params: {
        interview_date: filter.interview_date,
        start_time: el.slot_time.start,
        end_time: el.slot_time.end
      },
    })
    if (response.success) {
      setAvailableHosts(response.data)
      setIsHostPopupOpen(true);
    }
  }

  const reshapeDataIntoRows = (data, numRows) => {
    const flattenedArray = data.flat();
    const numColumns = Math.ceil(flattenedArray.length / numRows);
    const result = Array.from({ length: numRows }, (_, rowIndex) =>
      flattenedArray.slice(rowIndex * numColumns, rowIndex * numColumns + numColumns)
    );
    return result;
  };

  const getHosts = async () => {
    const response = await axios.get(`/researchers/get-activity-admins?activity_id=${activityId}`)
    if (response.success) {
      setHosts(response.data)
    }
  }

  const getParticipants = async (el) => {
    const response = await axios.get(`/researchers/get-activity-participants?activity_id=${activityId}`, {
      params: {
        project_id: workspaceState?.selectedProject?._id,
        interview_date: filter.interview_date,
        start_time: el.slot_time.start,
        end_time: el.slot_time.end
      },
    })
    if (response.success) {
      setParticipans(response.data)
      setScheduleInterviewParticipantPop(true);
    }
  }

  const getScheduleParticipants = async (el) => {
    const response = await axios.get(`/researchers/get-schedule-participants?activity_id=${activityId}`)
    if (response.success) {
      setScheduleParticipans(response.data)
    }
  }

  const selectedHosts = () => {
    const hostName = hosts.filter(host => chackVal.includes(host._id)).map(el => el.name)
    return hostName.length > 0 ? (hostName.length > 3 ? `${hostName.length} hosts are selected` : hostName.join(', ')) : t("LiveInterview.meetingHostPlaceholder");
  }

  const getSlots = async (filter = initialFilter) => {
    const response = await axios.get(`/researchers/get-interview-slots?activity_id=${activityId}`, {
      params: {
        interview_date: filter.interview_date,
        hosts: chackVal
      },
    })
    if (response.success) {
      const slots = {
        start_date: response.data.start_date,
        end_date: response.data.end_date,
        start_time: response.data.start_time,
        end_time: response.data.end_time,
        meeting_duration: response.data.meeting_duration,
        meeting_days: response.data.meeting_days,
        slots: reshapeDataIntoRows(response.data.slots, 3)
      }
      setAllSlots(slots)
    }
  }

  const handleContine = (el) => {
    setScheduleCreate({ ...scheduleCreate, start_time: el.slot_time.start, end_time: el.slot_time.end })
    getParticipants(el)
  }
  const parseTime = (time, interview_date = null) => {
    const currentDate = interview_date ? interview_date : moment().format("YYYY-MM-DD");
    return new Date(`${currentDate}T${moment(time, 'h:mm A').format('HH:mm:ss')}`);
  }

  const getInterviewStatus = (start_time, end_time, interview_date = null) => {
    const startDateTime = parseTime(start_time, interview_date);
    const endDateTime = parseTime(end_time, interview_date);
    const now = new Date();
    if (now < startDateTime) {
      return { status: 0, statusTxt: "Upcoming" };
    } else if (now >= startDateTime && now <= endDateTime) {
      return { status: 1, statusTxt: "Ongoing" };
    } else {
      return { status: 2, statusTxt: "Completed" };
    }
  }

  const createSchedule = async () => {
    try {
      const reqUrl = '/researchers/interview-schedule';
      const response = await researcherAction(reqUrl, scheduleCreate, authState.authToken);
      if (response.success) {
        resetAll();
        toast.success(response.message, TOAST_CONFIG);
        setReloadList(true);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    } finally {
    }
  }

  const resetAll = () => {
    setScheduleCreate(scheduleObj);
    setScheduleInterviewParticipantPop(false);
  }

  const deleteSchedule = (schedule) => {
    setScheduleId(schedule._id)
    const { interview_date, start_time, end_time, participantDetails } = schedule;

    const formattedDate = moment(interview_date).format('MMMM Do, YYYY');
    const participant = participantDetails[0];
    const participantName = participant ? participant.name : 'Unknown Participant';

    const scheduleText = `
    Date: ${formattedDate}, Start Time: ${start_time}, End Time: ${end_time}, 
    Participant Name: ${participantName}`;
    setScheduleText(scheduleText.trim());
    setDeleteScheduledPop(true);
  }
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 25,
    totalPages: 0,
    totalItems: 0,
  });

  const onPageChange = (pageNum) => {
    getSchedules(pageNum);
  };

  const getSchedules = async (curPage = 1) => {
    let url = `researchers/interview-schedule?page=${curPage}&limit=3&workspace_id=${workspaceState?.selectedWorkspace?._id}&activity_id=${activityId}`;
    return await axios
      .get(url, {
        params: {},
      })
      .then((res) => {
        if (res.status) {
          setScheduleList(res.data.data);
          setPaginationData({
            currentPage: res.data.pagination.currentPage,
            itemsPerPage: res.data.pagination.itemsPerPage,
            totalPages: res.data.pagination.totalPages,
            totalItems: res.data.pagination.totalItems,
          });
        }
      });
  }

  const getNextMeeting = async () => {
    let url = `researchers/interview-schedule?page=1&limit=1&workspace_id=${workspaceState?.selectedWorkspace?._id}&activity_id=${activityId}`;
    return await axios
      .get(url, {
        params: {
          start_time: formatDate(new Date(), "hh:mm A")
        },
      })
      .then((res) => {
        if (res.status) {
          if (res.data.data.length > 0) {
            setNextSchedule(res.data.data[0]);
          }
        }
      });
  }

  return (
    <>
      <Box className="ResearchersContainer ">
        <LiveInterViewPop
          liveInterViewPop={liveInterViewPop}
          setLiveInterViewPop={setLiveInterViewPop}
          scheduleId={scheduleId}
          setScheduleId={setScheduleId}
        />
        <UpdateTimeZonePop
          updateTimeZonePop={updateTimeZonePop}
          setUpdateTimeZonePop={setUpdateTimeZonePop}
        />
        <EditMeetingPop
          editMeetingPop={editMeetingPop}
          setEditMeetingPop={setEditMeetingPop}
          scheduleId={scheduleId}
          setScheduleId={setScheduleId}
          setReloadList={setReloadList}
        />
        <ScheduleInterviewParticipantPop
          scheduleInterviewParticipantPop={scheduleInterviewParticipantPop}
          setScheduleInterviewParticipantPop={setScheduleInterviewParticipantPop}
          participants={participants}
          setScheduleCreate={setScheduleCreate}
          scheduleCreate={scheduleCreate}
          createSchedule={createSchedule}
          resetAll={resetAll}
        />
        <DeleteInterviewScheduledPop
          deleteScheduledPop={deleteScheduledPop}
          setDeleteScheduledPop={setDeleteScheduledPop}
          scheduleId={scheduleId}
          setScheduleId={setScheduleId}
          scheduleText={scheduleText}
          setScheduleText={setScheduleText}
          setReloadList={setReloadList}
        />
        <SystemCheckPop systemCheckPop={systemCheckPop} setSystemCheckPop={setSystemCheckPop} />
        <AiIntegrationPop aiIntegrationPop={aiIntegrationPop} setAiIntegrationPop={setAiIntegrationPop} />
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon" >
              {sideHaumIcon}
            </Box>
            <Box className="AppNavBtnBox">
              <Box sx={{ width: "30%", minWidth: "196px" }}>
                {" "}
                <AllDrop7
                  drop={allDrop2}
                  setDrop={setAllDrop2}
                  dropVal={allDrop2Val}
                  serDropVal={setAllDrop2Val}
                />
              </Box>
              <AppBtn buttonText={t("LiveInterview.systemCheckBtn")} onClick={() => setSystemCheckPop(true)} />
            </Box>
          </Box>

          {/* Page container */}
          <Box className="researchersMainBox pageContainer interviewSchedule">
            <Box className="NotificationSection">
              <Typography className='EAHeaderText'>{t("LiveInterview.pageHeader")}</Typography>
              <Box mt={2} className="lineBar">
                <Typography>{lineBar}%</Typography>
                <BorderLinearProgress variant="determinate" value={lineBar} />
              </Box>
              <Typography className='lineBarSubText'>8 {t("ActivitiesInnerPage.of")} 10 {t("ActivitiesInnerPage.responsesRecieved")}</Typography>
              <Box mt={4} className="taskImgHeader"></Box>

              <Box className="iBox">
                <img src={iIcon} />
                <Typography>{t("LiveInterview.Ibox1")}<span onClick={() => setUpdateTimeZonePop(true)}>{t("LiveInterview.Ibox2")}</span>{t("LiveInterview.Ibox3")}</Typography>
              </Box>

              <Box className="scheduleNav">
                <Box onClick={() => setNav(0)} className={nav === 0 ? "scheduleNavItem scheduleNavItemActive" : "scheduleNavItem"}>
                  <Typography>{t("LiveInterview.Calender")}</Typography>
                </Box>
                <Box onClick={() => setNav(1)} className={nav === 1 ? "scheduleNavItem scheduleNavItemActive" : "scheduleNavItem"}>
                  <Typography>{t("LiveInterview.ScheduledMeetings")}</Typography>
                </Box>
                <Box onClick={() => setNav(2)} className={nav === 2 ? "scheduleNavItem scheduleNavItemActive" : "scheduleNavItem"}>
                  <Typography>{t("LiveInterview.preparedItemsTab")}</Typography>
                </Box>
                <Box onClick={() => setNav(3)} className={nav === 3 ? "scheduleNavItem scheduleNavItemActive" : "scheduleNavItem"}>
                  <Typography>{t("LiveInterview.participantsTab")}</Typography>
                </Box>
                <Box onClick={() => setNav(4)} className={nav === 4 ? "scheduleNavItem scheduleNavItemActive" : "scheduleNavItem"}>
                  <Typography>{t("LiveInterview.Recordings")}</Typography>
                </Box>
              </Box>




              {/* calender */}
              <Box className="schedule_Section_box" sx={{ display: nav === 0 ? "block" : "none" }}>
                <Box className="scheduleDropBox">

                  <Box className="dateItembox">
                    <Typography className='drop_label'>{t("LiveInterview.InterviewDate")}</Typography>
                    <Box className="pickerBox">
                      <ReferenceDateUsingValue
                        value={dayjs(filter?.interview_date)}
                        minDate={dayjs(allSlots?.start_date)}
                        maxDate={dayjs(allSlots?.end_date)}
                        onChange={e => setFilter({ ...filter, interview_date: getDatePickerVal(e, "date") })} />
                    </Box>
                  </Box>
                  <Box className="dateItembox">
                    <Typography className='drop_label'>{t("LiveInterview.MeetingDuration")}</Typography>
                    <input type="text" className='phoneInput' value={`${allSlots?.meeting_duration ?? "30"} Mins.`} />

                  </Box>
                  <Box className="dateItembox">
                    <Typography className='drop_label'>{t("LiveInterview.SelectHosts")}


                    </Typography>
                    {/* <Status setStatusDrop={stHostDrop} statusDrop={hostDrop} setStatusDropVal={stHostDropVal} statusDropVal={hostDropVal} label=" " /> */}
                    <Box
                      className="inputAppDrop">
                      <Typography onClick={() => setGroupDrop2(!groupDrop2)}>{selectedHosts()}</Typography>
                      <img
                        onClick={() => setGroupDrop2(!groupDrop2)}
                        className="PartDropIcon"
                        style={{ rotate: groupDrop2 ? "180deg" : "0deg" }}
                        src={dropArrow}
                      />

                      <Box sx={{ display: groupDrop2 ? "block" : "none" }} className="statusPop">
                        <Box className="checkSelectorBox">
                          <span onClick={selectAllCheckData}>{t("commonTxt.selectAll")}</span >
                          <span onClick={deleteAllCheckData}>{t("commonTxt.deSelectAll")}</span>
                        </Box>


                        {
                          hosts?.map((el, i) => (
                            <Box key={i} className="statusPopItem"
                              onClick={() => {
                                if (chackVal.includes(el._id)) {
                                  console.log(chackVal)
                                  setChackVal(chackVal.filter(item => item !== el._id))
                                  return;
                                } else {
                                  pushData(el._id)
                                }
                              }}

                            >
                              <Box className="chackBox">
                                <img className="chackBoxImg" src={chackBox} />
                                <img style={{ display: chackVal.includes(el._id) ? "block" : "none" }} className="chack" src={check} />
                              </Box>
                              <p>{el.name}</p>
                            </Box>
                          ))
                        }
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box className="timerTableBox">

                  {/*  <Box className="timerCol">
                    {
                      timeZone1?.map((el, i) => (
                        <Box key={i} className="timerSel">
                          <Box onClick={(e) => {
                            handelTimerClick(setTimeZoneIndex1, timeZoneIndex1, i, e)
                            setTimeZoneIndex2(null)
                            setTimeZoneIndex3(null)
                          }} className={timeZoneIndex1 === i ? "timerSelInnerBox timerSelInnerBActive" : "timerSelInnerBox"}>
                            <Typography>{el}</Typography>
                            <Box id="hostS" sx={{ display: timeZoneIndex1 === i ? "flex" : "none" }} className="addHostBox addHostBox1">
                              <Box id="hostS" className="addHHeader">
                                <img id="hostS" src={calenderW} />
                                <Typography id="hostS">{t("LiveInterview.ScheduleMeeting")}</Typography>
                              </Box>
                              <Box id="hostS" className="ahRowItem">
                                <Typography id="hostS">{t("LiveInterview.Availability")}</Typography>
                              </Box>
                              <Box id="hostS" className="ahRowItem ahrHover" onClick={() => setScheduleInterviewParticipantPop(true)} >
                                <input id="hostS" className="custom-checkbox" type="checkBox" />
                                <Typography id="hostS">host 1</Typography>
                              </Box>
                              <Box id="hostS" className="ahRowItem ahrHover" onClick={() => setScheduleInterviewParticipantPop(true)}>
                                <input id="hostS" className="custom-checkbox" type="checkBox" />
                                <Typography id="hostS">host 2</Typography>
                              </Box>
                            </Box>






                          </Box>
                        </Box>

                      ))
                    }
                  </Box> */}



                  {/* <Box className="timerCol">
                    {
                      timeZone3?.map((el, i) => (
                        <Box key={i} className="timerSel">
                          <Box onClick={(e) => {
                            handelTimerClick(setTimeZoneIndex3, timeZoneIndex3, i, e)
                            setTimeZoneIndex1(null)
                            setTimeZoneIndex2(null)
                          }} className={timeZoneIndex3 === i ? "timerSelInnerBox timerSelInnerBActive" : "timerSelInnerBox"}>
                            <Typography>{el}</Typography>
                            <Box id="hostS" sx={{ display: timeZoneIndex3 === i ? "flex" : "none" }} className="addHostBox addHostBox3">
                              <Box id="hostS" className="addHHeader">
                                <img id="hostS" src={calenderW} />
                                <Typography id="hostS">{t("LiveInterview.ScheduleMeeting")}</Typography>
                              </Box>
                              <Box id="hostS" className="ahRowItem">
                                <Typography id="hostS">{t("LiveInterview.Availability")}</Typography>
                              </Box>
                              <Box id="hostS" className="ahRowItem ahrHover" onClick={() => setScheduleInterviewParticipantPop(true)} >
                                <input id="hostS" className="custom-checkbox" type="checkBox" />
                                <Typography id="hostS">host 1</Typography>
                              </Box>
                              <Box id="hostS" className="ahRowItem ahrHover" onClick={() => setScheduleInterviewParticipantPop(true)} >
                                <input id="hostS" className="custom-checkbox" type="checkBox" />
                                <Typography id="hostS">host 2</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      ))
                    }
                  </Box> */}

                  {
                    allSlots?.slots?.length > 0 &&
                    allSlots.slots?.map((slot, ind) => (
                      <Box className="timerCol" key={ind}>
                        {slot.map((el, i) => (
                          <Box key={i} className="timerSel">
                            <Box onClick={(e) => {
                              hostPopupOpen(el, e)

                            }} className={slotIndx === el.key ? "timerSelInnerBox timerSelInnerBActive" : "timerSelInnerBox"}>
                              <Typography>{el.slot_time.start} To {el.slot_time.end}</Typography>
                              <Box id="hostS" sx={{ display: (!el.metadata && getInterviewStatus(el.slot_time.start, el.slot_time.end, filter.interview_date).status === 0 && slotIndx === el.key && isHostPopupOpen) ? "flex" : "none" }} className="addHostBox addHostBox2">
                                <Box id="hostS" className="addHHeader">
                                  <img id="hostS" src={calenderW} />
                                  <Typography id="hostS">{t("LiveInterview.ScheduleMeeting")}</Typography>
                                </Box>
                                <Box id="hostS" className="ahRowItem">
                                  <Typography id="hostS">{t("LiveInterview.Availability")}</Typography>
                                </Box>
                                {availableHosts.length > 0 && availableHosts.map((host) => (
                                  <Box id="hostS" className="ahRowItem ahrHover"

                                    /* onClick={() => setScheduleInterviewParticipantPop(true)} */ >
                                    <input id="hostS" className="custom-checkbox" type="checkBox"
                                      checked={scheduleCreate.hosts.includes(host._id)}
                                      onClick={(e) => toggleHost(host._id)}
                                    />
                                    <Typography id="hostS">{host.name}</Typography>
                                  </Box>
                                ))}
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                  <AppBtn buttonText={`Continue`} sx={{ height: '30px !important', width: "70px" }}
                                    onClick={() => handleContine(el)}
                                  />
                                </Box>
                              </Box>
                              <Box className="timeScheduleActive" sx={{ display: el.metadata ? "flex" : "none" }}>
                                <Box className="" sx={{
                                  display: "flex",
                                  flexDirection: "row", justifyContent: "right",
                                }} onClick={() => deleteSchedule(el.metadata)}>
                                  <span >Cancel</span>
                                </Box>
                                <Box className="user_Row">
                                  <img src={(!el.metadata?.participantDetails[0].profile_pic || el.metadata?.participantDetails[0].profile_pic === 'undefined') ? avatar : viewImage(el.metadata?.participantDetails[0].profile_pic)} />
                                  <Typography>{el.metadata?.participantDetails[0].name}</Typography>
                                </Box>
                                <Box sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  marginTop: "10px"
                                }}>
                                  <samp className='scheduleTime'>{el.slot_time.start} ({allSlots?.meeting_duration} min)</samp>
                                  <span>{getInterviewStatus(el.slot_time.start, el.slot_time.end, filter.interview_date).statusTxt}</span>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    ))
                  }
                </Box>
                <Box className="workPupBtnBox">
                  {/* <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} /> */}
                  {/* <AppBtn buttonText={t("commonTxt.saveBtn")} /> */}
                </Box>
              </Box>


              {/* Scheduled Meetings */}
              <Box className="schedule_Section_box" sx={{ display: nav === 1 ? "block" : "none" }}>
                <Box className="nextMettingBox">
                  <Box className="mettingImgBox"><img src={mettingImg} /></Box>
                  {nextSchedule ? <Box className="mettingTextBox">
                    <Typography className='mettingHeaderText'>{t("LiveInterview.NextMeeting")}</Typography>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.Participant")} :
                      <span> {nextSchedule?.participantDetails[0]?.name}</span>
                    </Typography>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.ScheduledDate")} :
                      <span>  {formatDate(nextSchedule?.interview_date, "MMM DD-YYYY")}</span>
                    </Typography>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.ScheduledTime")} :
                      <span>  {nextSchedule?.start_time}</span>
                    </Typography>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.host")} :
                      <span> {nextSchedule?.hostDetails.map((el) => (` ${el.name}`)).join(", ")}</span>
                    </Typography>

                    <Box className="mettingBtnBox">
                      <AppBtnOutLine buttonText={t("commonTxt.editMeetingBtn")} onClick={() => {
                        setEditMeetingPop(true);
                        setScheduleId(nextSchedule?._id)
                      }
                      } />
                      <AppBtn buttonText={t("commonTxt.startMeetingBtn")} onClick={() => {
                        setLiveInterViewPop(true)
                        setScheduleId(nextSchedule?._id)
                      }} />
                    </Box>
                  </Box> : <Box className="mettingTextBox"><Typography className='mettingHeaderText'>There are no meetings scheduled right now.</Typography></Box>}
                </Box>

                <Box className="MettingCardBox">
                  {scheduleList.length > 0 && scheduleList.map((schedule) => (<Box className="mettingCard">
                    <Box className="user_Row">
                      <img src={(!schedule.participantDetails[0].profile_pic || schedule.participantDetails[0].profile_pic === 'undefined') ? avatar : viewImage(schedule.participantDetails[0].profile_pic)} />
                      <Typography>{schedule.participantDetails[0].name}</Typography>
                    </Box>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.ScheduledDate")} :
                      <span>  {formatDate(schedule.interview_date, "DD-MMM-YYYY")}</span>
                    </Typography>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.ScheduledTime")} :
                      <span>  {schedule.start_time}</span>
                    </Typography>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.host")} :
                      {schedule.hostDetails.map((el) => (` ${el.name}`)).join(", ")}
                    </Typography>
                    <Box className="mettingBtnBox">
                      <Typography onClick={() => {
                        setEditMeetingPop(true);
                        setScheduleId(schedule?._id)
                      }
                      }>{t("commonTxt.editMeetingBtn")}</Typography>
                      <AppBtn buttonText={t("commonTxt.startMeetingBtn")} onClick={() => {
                        setLiveInterViewPop(true)
                        setScheduleId(schedule?._id)
                      }} />
                    </Box>
                  </Box>))}



                  {/*  <Box className="mettingCard">
                    <Box className="user_Row">
                      <img src={avatar} />
                      <Typography>Alia Singh</Typography>
                    </Box>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.ScheduledDate")} :
                      <span>  30/07/2023</span>
                    </Typography>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.ScheduledTime")} :
                      <span>   11:00 AM</span>
                    </Typography>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.host")} :
                      <span>   Host 1</span>
                    </Typography>
                    <Box className="mettingBtnBox">
                      <Typography>{t("commonTxt.editMeetingBtn")}</Typography>
                      <AppBtn buttonText={t("commonTxt.startMeetingBtn")} />
                    </Box>
                  </Box>



                  <Box className="mettingCard">
                    <Box className="user_Row">
                      <img src={avatar} />
                      <Typography>Alia Singh</Typography>
                    </Box>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.ScheduledDate")} :
                      <span>  30/07/2023</span>
                    </Typography>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.ScheduledTime")} :
                      <span>   11:00 AM</span>
                    </Typography>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.host")} :
                      <span>   Host 1</span>
                    </Typography>
                    <Box className="mettingBtnBox">
                      <Typography>{t("commonTxt.editMeetingBtn")}</Typography>
                      <AppBtn buttonText={t("commonTxt.startMeetingBtn")} />
                    </Box>
                  </Box> */}
                </Box>
                <Pagination
                  paginationData={paginationData}
                  onPageChange={onPageChange}
                />
              </Box>


              {/* Prepared Item*/}
              <Box className="schedule_Section_box" sx={{ display: nav === 2 ? "block" : "none" }}>
                {preparedItemsPageVal ?
                  <Box className="noDataPage">
                    <img src={PreparedItemsNoData} />
                    <Typography className='EAHeaderText'>{t("LiveInterview.preparedItemsHead")}</Typography>
                    <Typography className='noDsubText'>{t("LiveInterview.preparedItemsSubText")}</Typography>
                    <AppAddBtn buttonText={t("commonTxt.addItemsBtn")} onClick={() => setPreparedItem(false)} />
                  </Box> : preparedItem ?
                    <Box className="preparedItemBox">

                      {/* item1 */}
                      <Box className="prep_item">
                        <Box className="prep_Nav">
                          <Typography className='preparedLabel'>1. Pick your favourite</Typography>
                          <Box className="OptionIcon">
                            <img src={OptionIcon} onClick={() => setPreparedItemOptionPop(!preparedItemOptionPop)} />
                            <Box sx={{ display: preparedItemOptionPop ? "flex" : "none" }} className="prepTopOPop activeCardOptionPop">
                              <Box className="acItem" onClick={() => setPreparedItemOptionPop(false)} >
                                {editIconBlack}
                                <Typography className="acdl">{t("commonTxt.editItemLink")}</Typography>
                              </Box>
                              <Box className="acItem" onClick={() => setPreparedItemOptionPop(false)} >
                                {activeCTrashIcon}
                                <Typography className="acdl">{t("commonTxt.deleteBtn")} </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Typography>{t("LiveInterview.preparedItemText")}</Typography>


                        <Box mt={3} className="redioItem" onClick={() => setRadio(0)}>
                          <Box
                            className={
                              radio === 0
                                ? "customRedio customRedioActive"
                                : "customRedio"
                            }
                          >
                            <Box></Box>
                          </Box>
                          <Typography>Dog</Typography>
                        </Box>

                        <Box mt={3} className="redioItem" onClick={() => setRadio(1)}>
                          <Box
                            className={
                              radio === 1
                                ? "customRedio customRedioActive"
                                : "customRedio"
                            }
                          >
                            <Box></Box>
                          </Box>
                          <Typography> Cat </Typography>
                        </Box>
                      </Box>

                      {/* item 2 */}
                      <Box className="prep_item">
                        <Box className="prep_Nav">
                          <Typography className='preparedLabel'>1. Pick your favourite</Typography>
                          <Box className="OptionIcon">
                            <img src={OptionIcon} onClick={() => setPreparedItemOptionPop2(!preparedItemOptionPop2)} />
                            <Box sx={{ display: preparedItemOptionPop2 ? "flex" : "none" }} className="prepTopOPop activeCardOptionPop">
                              <Box className="acItem" onClick={() => setPreparedItemOptionPop2(false)} >
                                {editIconBlack}
                                <Typography className="acdl">{t("commonTxt.editItemLink")}</Typography>
                              </Box>
                              <Box className="acItem" onClick={() => setPreparedItemOptionPop2(false)} >
                                {activeCTrashIcon}
                                <Typography className="acdl">{t("commonTxt.deleteBtn")} </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>

                        <Box className="prepItemImageBOx">
                          <img src={PrepItem2} />
                        </Box>
                      </Box>
                      <AppAddBtn buttonText={t("commonTxt.addItemsBtn")} onClick={() => setPreparedItem(false)} />
                    </Box>
                    :


                    <Box className="preparedMainPage">
                      <Typography className='preparedLabel'>1. {t("LiveInterview.addItemHead")}</Typography>
                      <Box className="prepareMainBox">
                        <Box className="prepNav">
                          <Box className={prepNavItem === 0 ? "prepNavItem prepNavItemActive" : "prepNavItem"} onClick={() => setPrepNavItem(0)}>
                            <Typography>{t("LiveInterview.pollTab")}</Typography>
                          </Box>
                          <Box className={prepNavItem === 1 ? "prepNavItem prepNavItemActive" : "prepNavItem"} onClick={() => setPrepNavItem(1)}>
                            <Typography>{t("LiveInterview.messageTab")}</Typography>
                          </Box>
                          <Box className={prepNavItem === 2 ? "prepNavItem prepNavItemActive" : "prepNavItem"} onClick={() => setPrepNavItem(2)}>
                            <Typography>{t("LiveInterview.imageTab")}</Typography>
                          </Box>
                          <Box className={prepNavItem === 3 ? "prepNavItem prepNavItemActive" : "prepNavItem"} onClick={() => setPrepNavItem(3)}>
                            <Typography>{t("LiveInterview.videoTab")}</Typography>
                          </Box>
                          <Box className={prepNavItem === 4 ? "prepNavItem prepNavItemActive" : "prepNavItem"} onClick={() => setPrepNavItem(4)}>
                            <Typography>{t("LiveInterview.fileTab")}</Typography>
                          </Box>
                        </Box>


                        <Box sx={{ display: prepNavItem === 0 ? "block" : "none" }} className="prepareInnerBox">
                          {/* Poll */}
                          <Typography className='eTemPopLabel'>{t("LiveInterview.questionLabel")}</Typography>
                          <Box className="emailInputBox">
                            <Box className="emailInputBoxNav">
                              <Box
                                onClick={() => setMessageTextItalic(!messageTextItalic)}
                                className="emailImgBox"
                              >
                                <img src={IIcon} />
                              </Box>
                              <Box
                                onClick={() => setMessageTextBold(!messageTextBold)}
                                className="emailImgBox"
                              >
                                <img src={BIcon} />
                              </Box>
                              <Box
                                onClick={() => handelTextlineChekc("underline", "message")}
                                className="emailImgBox"
                              >
                                <img src={UnderLineU} />
                              </Box>
                              <Box
                                onClick={() => handelTextlineChekc("line-through", "message")}
                                className="emailImgBox"
                              >
                                <img src={MLineU} />
                              </Box>
                              <Box className="emailImgBox">
                                <img src={AtachMentIcon} />
                              </Box>
                              <Box className="emailImgBox">
                                <img src={Qbox} />
                              </Box>
                              <Box
                                onClick={() => setMessageTextLaOut("start")}
                                className="emailImgBox"
                              >
                                <img src={Line1} />
                              </Box>
                              <Box
                                onClick={() => setMessageTextLaOut("center")}
                                className="emailImgBox"
                              >
                                <img src={Line2} />
                              </Box>
                              <Box
                                onClick={() => setMessageTextLaOut("end")}
                                className="emailImgBox"
                              >
                                <img src={Line3} />
                              </Box>
                              <Box className="emailImgBox">
                                <img src={Line4} />
                              </Box>
                              <Box className="emailImgBox">
                                <img src={emoji} />
                              </Box>
                            </Box>
                            <textarea
                              style={{
                                textAlign: messageTextLaOut,
                                fontStyle: messageTextItalic ? "italic" : "normal",
                                fontWeight: messageTextBold ? "bold" : "normal",
                                textDecoration: messageTextUnderLine,
                                // textDecoration: messageTextMLine ? "line-through" : null
                              }}
                              className="emailInput"
                              name="emailMessage"
                              value={emailval?.emailMessage || email1}
                              onChange={handelChange}
                            />
                          </Box>

                          <Typography className='eTemPopLabel'>{t("LiveInterview.choicesHead")}</Typography>
                          {renderComponents(attributeCount)}
                          <Box className="addChoiceBox">
                            <Typography className="addAttributeLink" onClick={handelAddAttribute}>{t("commonTxt.addAttributeLink")}</Typography>
                            <Typography className="addAttributeLink">{t("commonTxt.pasteBulkChoicesLink")}</Typography>
                          </Box>
                          <Typography className='eTemPopLabel'>{t("LiveInterview.pollSettingsHead")}</Typography>
                          <Box my={3} className='checkWlabel'>
                            <input className="custom-checkbox" type="checkBox" />
                            <Typography>{t("LiveInterview.pollSettingCheckbox1")}</Typography>
                          </Box>
                          <Box mb={3} className='checkWlabel'>
                            <input className="custom-checkbox" type="checkBox" />
                            <Typography>{t("LiveInterview.pollSettingCheckbox2")}</Typography>
                          </Box>
                          <Box mb={3} className='checkWlabel'>
                            <input className="custom-checkbox" type="checkBox" />
                            <Typography>{t("LiveInterview.pollSettingCheckbox3")}</Typography>
                          </Box>
                        </Box>


                        <Box sx={{ display: prepNavItem === 1 ? "block" : "none" }} className="prepareInnerBox">
                          {/* Message */}
                          <Typography className='eTemPopLabel '>{t("LiveInterview.messageLabel")}</Typography>
                          <Box className="emailInputBox">
                            <Box className="emailInputBoxNav">
                              <Box
                                onClick={() => setMessageTextItalic(!messageTextItalic)}
                                className="emailImgBox"
                              >
                                <img src={IIcon} />
                              </Box>
                              <Box
                                onClick={() => setMessageTextBold(!messageTextBold)}
                                className="emailImgBox"
                              >
                                <img src={BIcon} />
                              </Box>
                              <Box
                                onClick={() => handelTextlineChekc("underline", "message")}
                                className="emailImgBox"
                              >
                                <img src={UnderLineU} />
                              </Box>
                              <Box
                                onClick={() => handelTextlineChekc("line-through", "message")}
                                className="emailImgBox"
                              >
                                <img src={MLineU} />
                              </Box>
                              <Box className="emailImgBox">
                                <img src={AtachMentIcon} />
                              </Box>
                              <Box className="emailImgBox">
                                <img src={Qbox} />
                              </Box>
                              <Box
                                onClick={() => setMessageTextLaOut("start")}
                                className="emailImgBox"
                              >
                                <img src={Line1} />
                              </Box>
                              <Box
                                onClick={() => setMessageTextLaOut("center")}
                                className="emailImgBox"
                              >
                                <img src={Line2} />
                              </Box>
                              <Box
                                onClick={() => setMessageTextLaOut("end")}
                                className="emailImgBox"
                              >
                                <img src={Line3} />
                              </Box>
                              <Box className="emailImgBox">
                                <img src={Line4} />
                              </Box>
                              <Box className="emailImgBox">
                                <img src={emoji} />
                              </Box>
                            </Box>
                            <textarea
                              style={{
                                textAlign: messageTextLaOut,
                                fontStyle: messageTextItalic ? "italic" : "normal",
                                fontWeight: messageTextBold ? "bold" : "normal",
                                textDecoration: messageTextUnderLine,
                                // textDecoration: messageTextMLine ? "line-through" : null
                              }}
                              className="emailInput"
                              name="emailMessage"
                              value={emailval?.emailMessage || email1}
                              onChange={handelChange}
                            />
                          </Box>
                        </Box>


                        <Box sx={{ display: prepNavItem === 2 ? "block" : "none" }} className="prepareInnerBox">

                          <Box mb={5} className="mediaBox">
                            <Box className="mediaBanner">
                              <img src={imageBanner} />
                            </Box>

                            <Box className="mediaInputText">
                              <Box className="textBox">
                                <Typography className="teraText">
                                  Image.png {t("ImageActivity.Uploaded")}
                                </Typography>
                                <Box mb={2}>
                                  <img src={uploadIcon} />
                                  <Typography
                                    ml={2}
                                    onClick={() => {
                                      document.getElementById("fileInput").click();
                                    }}
                                  >
                                    {t("ImageActivity.ChangeImage")}
                                  </Typography>
                                  <input
                                    id="fileInput"
                                    style={{ display: "none" }}
                                    type="file"
                                    onChange={handleFileInputChange}
                                  />
                                </Box>
                                <Box>
                                  <img src={trasIcon} />
                                  <Typography ml={2}>{t("commonTxt.delete")}</Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>


                          <Box className="emailInputBox imgEmailBox">
                            <Box className="emailInputBoxNav">
                              <Box
                                onClick={() => setMessageTextItalic(!messageTextItalic)}
                                className="emailImgBox"
                              >
                                <img src={IIcon} />
                              </Box>
                              <Box
                                onClick={() => setMessageTextBold(!messageTextBold)}
                                className="emailImgBox"
                              >
                                <img src={BIcon} />
                              </Box>
                              <Box
                                onClick={() => handelTextlineChekc("underline", "message")}
                                className="emailImgBox"
                              >
                                <img src={UnderLineU} />
                              </Box>
                              <Box
                                onClick={() => handelTextlineChekc("line-through", "message")}
                                className="emailImgBox"
                              >
                                <img src={MLineU} />
                              </Box>
                              <Box className="emailImgBox">
                                <img src={AtachMentIcon} />
                              </Box>
                              <Box className="emailImgBox">
                                <img src={Qbox} />
                              </Box>
                              <Box
                                onClick={() => setMessageTextLaOut("start")}
                                className="emailImgBox"
                              >
                                <img src={Line1} />
                              </Box>
                              <Box
                                onClick={() => setMessageTextLaOut("center")}
                                className="emailImgBox"
                              >
                                <img src={Line2} />
                              </Box>
                              <Box
                                onClick={() => setMessageTextLaOut("end")}
                                className="emailImgBox"
                              >
                                <img src={Line3} />
                              </Box>
                              <Box className="emailImgBox">
                                <img src={Line4} />
                              </Box>
                              <Box className="emailImgBox">
                                <img src={emoji} />
                              </Box>
                            </Box>

                            <textarea
                              style={{
                                textAlign: messageTextLaOut,
                                fontStyle: messageTextItalic ? "italic" : "normal",
                                fontWeight: messageTextBold ? "bold" : "normal",
                                textDecoration: messageTextUnderLine,
                              }}
                              className="emailInput"
                              name="emailMessage"
                              value={emailval?.emailMessage || email1}
                              onChange={handelChange}
                            />
                          </Box>

                        </Box>


                        <Box sx={{ display: prepNavItem === 3 ? "block" : "none" }} className="prepareInnerBox">
                          <Box className='mediaInputbox upload-area' onClick={() => {
                            document.getElementById("fileInput").click();
                          }}
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}>
                            <img src={plassIcon} />
                            <input
                              id="fileInput"
                              style={{ display: "none" }}
                              type="file"
                              onChange={handleFileInputChange}
                            />
                          </Box>
                          <Typography className='imgUploadLabel'>{t("LiveInterview.uploadVideo")}</Typography>
                          <Box className="searchBarBox">
                            <SearchBar />
                          </Box>
                        </Box>


                        <Box sx={{ display: prepNavItem === 4 ? "block" : "none" }} className="prepareInnerBox">
                          <Box className='mediaInputbox upload-area' onClick={() => {
                            document.getElementById("fileInput").click();
                          }}
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}>
                            <img src={plassIcon} />
                            <input
                              id="fileInput"
                              style={{ display: "none" }}
                              type="file"
                              onChange={handleFileInputChange}
                            />
                          </Box>
                          <Typography className='imgUploadLabel'>{t("LiveInterview.uploadFile")}</Typography>
                          <Box className="searchBarBox">
                            <SearchBar />
                          </Box>
                        </Box>
                        <Box className="workPupBtnBox">
                          <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => setPreparedItem(true)} />
                          <AppBtn buttonText={t("commonTxt.saveBtn")} />
                        </Box>
                      </Box>
                    </Box>
                }
              </Box>

              {/* Participants */}
              <Box className="schedule_Section_box Participants_section" sx={{ display: nav === 3 ? "block" : "none" }}>
                <Box className="companyListNav">
                  <Typography className='pageHeaderText'>{t("LiveInterview.activityParticipantsHead")}</Typography>
                  <Box className="navMenus">
                    <Box className="actTNavBtn">
                      <img src={groupIcon} />
                      <Typography>{t("commonTxt.groupBtn")}</Typography>
                    </Box>
                    <AppBtn buttonText={t("recapPollMultiPage.sendMessage")} />
                  </Box>
                </Box>
                <Box className="workListBox workListBoxRP">
                  <Box className="haderRow" minWidth={"1130px"}>
                    <Box minWidth={"15%"} className="Tabelsel navFsel TabelStatesel">
                      <Typography>{t("projectDirectory.tblName")}</Typography>
                    </Box>
                    <Box minWidth={"12%"} className="Tabelsel Tabelcentersel">
                      <Typography textAlign={"center"}>{t("LiveInterview.tblSystemCheck")}</Typography>
                    </Box>
                    <Box minWidth={"12%"} className="Tabelsel Tabelcentersel">
                      <Typography textAlign={"center"}>{t("projectDirectory.tblStatus")}</Typography>
                    </Box>
                    <Box minWidth={"8%"} className="Tabelsel">
                      <Typography textAlign={"center"}>{t("LiveInterview.tblPosts")}</Typography>
                    </Box>
                    <Box minWidth={"8%"} className="Tabelsel">
                      <Typography textAlign={"center"}>{t("recapPollMultiPage.tblExcerpts")}</Typography>
                    </Box>
                    <Box minWidth={"15%"} className="Tabelsel ">
                      <Typography textAlign={"center"}>{t("LiveInterview.tblStarted")}</Typography>
                    </Box>
                    <Box minWidth={"12%"} className="Tabelsel">
                      <Typography textAlign={"center"}>{t("LiveInterview.tblExited")}</Typography>
                    </Box>
                    <Box minWidth={"12%"} className="Tabelsel">
                      <Typography textAlign={"center"}>{t("LiveInterview.tblDuration")}</Typography>
                    </Box>
                  </Box>

                  {scheduleParticipans.length > 0 &&
                    scheduleParticipans?.map((el, index) => (
                      <Box key={index} className="TabelRow" minWidth={"1120px"}>
                        <Box minWidth={"15%"} className="Tabelsel navFsel">
                          <Box className="tabelAvatar">
                            <img src={viewImage(el.profile_pic)} />
                          </Box>
                          <Typography sx={{ cursor: "pointer" }}>{el.name}</Typography>
                        </Box>

                        <Box minWidth={"12%"} className="Tabelsel Tabelcentersel">
                          <img
                            src={
                              el?.system_check === true ? checkIcon : redWIcon
                            }
                            alt="Toggle"
                          />
                        </Box>
                        <Box minWidth={"12%"} className="Tabelsel Tabelcentersel">
                          {getInterviewStatus(el.started, el.exited, el.interview_date).status === 0 && <Box className="nStartBox"><p>Not Started</p></Box>}
                          {getInterviewStatus(el.started, el.exited, el.interview_date).status === 1 && <Box className="nStartBox"><p>Started</p></Box>}
                          {getInterviewStatus(el.started, el.exited, el.interview_date).status === 2 && <button className='status'>Completed</button>}
                        </Box>
                        <Box
                          minWidth={"8%"}
                          className="Tabelsel toggleButton"
                        >
                          {el.posts_count}
                        </Box>
                        <Box minWidth={"8%"} className="Tabelsel toggleButton" >
                          {el.excerpts_count}
                        </Box>
                        <Box minWidth={"12%"} className="Tabelsel toggleButton" >
                          {el.started}
                        </Box>
                        <Box minWidth={"12%"} className="Tabelsel toggleButton" >
                          {el.exited}
                        </Box>
                        <Box minWidth={"12%"} className="Tabelsel toggleButton" >
                          {el.duration}
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Box>


              {/* recording */}

              <Box className="schedule_Section_box recording" sx={{ display: nav === 4 ? "block" : "none" }}>
                {recordIndexPage ?
                  <>
                    <Box className="companyListNav">
                      <Box className="AppNavBtnBox">
                        <Box
                          className="allTaskNavDrop"
                          onClick={() =>
                            setParticipantResNavDrop(!participantsResNavDrop)
                          }
                        >
                          <Box className="tNDL">
                            <img
                              style={{ width: "20px", height: "20px" }}
                              src={leftArrow}
                            />
                          </Box>

                          <Box className="tndC">
                            <Typography>{participantsResNavDropVal}</Typography>
                            <img
                              style={{
                                width: "20px",
                                height: "20px",
                                marginLeft: "10px",
                                rotate: participantsResNavDrop ? "180deg" : "0deg",
                              }}
                              src={chevronDown}
                            />
                          </Box>

                          <Box className="tNDR">
                            <img
                              style={{ width: "20px", height: "20px" }}
                              src={rightArrow}
                            />
                          </Box>

                          <Box
                            className="statusPop"
                            sx={{ display: participantsResNavDrop ? "block" : "none" }}
                          >
                            {taskList?.map((el, index) => (
                              <Box
                                key={index}
                                className="statusPopItem"
                                onClick={() => setParticipantResNavDropVal(el)}
                              >
                                <Typography>{el}</Typography>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                      <SearchBar />
                      <Box className="actTNavBtn">
                        <img src={groupIcon} />
                        <Typography>{t("commonTxt.groupBtn")}</Typography>
                      </Box>
                    </Box>

                    <Box className="recordeingBox">
                      {recordCard()}
                      {recordCard()}
                      {recordCard()}
                      {recordCard()}
                      {recordCard()}
                      {recordCard()}
                    </Box>

                    <Box className="pagenation">
                      <Box onClick={handlePrev} className="tabelBtn">
                        <img src={arrow} />
                        <Typography>{t("commonTxt.previouspage")}</Typography>
                      </Box>

                      <Box className="pageNumberBox">
                        <Box
                          onClick={() => setCurrentPage(0)}
                          className={
                            currentPage === 0 ? "pageNumber pageNumberActive" : "pageNumber"
                          }
                        >
                          <p>1</p>
                        </Box>
                        <Box
                          onClick={() => setCurrentPage(1)}
                          className={
                            currentPage === 1 ? "pageNumber pageNumberActive" : "pageNumber"
                          }
                        >
                          <p>2</p>
                        </Box>
                        <Box
                          onClick={() => setCurrentPage(2)}
                          className={
                            currentPage === 2 ? "pageNumber pageNumberActive" : "pageNumber"
                          }
                        >
                          <p>3</p>
                        </Box>
                        <Box
                          onClick={() => setCurrentPage(3)}
                          className={
                            currentPage === 3 ? "pageNumber pageNumberActive" : "pageNumber"
                          }
                        >
                          <p>4</p>
                        </Box>
                        <Box
                          onClick={() => setCurrentPage(4)}
                          className={
                            currentPage === 4 ? "pageNumber pageNumberActive" : "pageNumber"
                          }
                        >
                          <p>5</p>
                        </Box>
                      </Box>

                      <Box onClick={handleNext} className="tabelBtn">
                        <Typography>{t("commonTxt.nextPage")}</Typography>
                        <img style={{ rotate: "180deg" }} src={arrow} />
                      </Box>
                    </Box>
                  </> :
                  <>
                    {/* Record index page */}
                    <Box className="videoIndexPage">
                      <Box className="recIndexNav">
                        <Box className="topBackBtn" onClick={() => setRecordIndexPage(true)}>
                          <img src={backArrow} />
                          <Typography className="neonBackText">{t("commonTxt.backBtn")}</Typography>
                        </Box>
                        <Box sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "20px"
                        }}>
                          <Box className="expoMetting" onClick={() => setExpoMettingDrop(!expoMettingDrop)}>
                            <img className='bulkIcon' src={bulkIcon} />
                            <Typography>{expoMettingDropVal}</Typography>
                            <img
                              className='drop_Icon'
                              style={{ rotate: participantsResNavDrop ? "180deg" : "0deg", }}
                              src={chevronDown}
                            />
                            <Box className="statusPop" sx={{ display: expoMettingDrop ? "block" : "none" }}>
                              {
                                expoMettingItem?.map((el, i) => (
                                  <Box key={i} className="statusPopItem" onClick={() => setExpoMettingDropVal(el)}>
                                    <Typography>{el}</Typography>
                                  </Box>
                                ))
                              }
                            </Box>
                          </Box>

                          <Box className="aiBtn" onClick={() => setAiIntegrationPop(true)}>
                            <img src={aiWhiteIcon} />
                            <Typography>{t("commonTxt.aIIntegrationBtn")}</Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box className="videoSection">

                        <Box className="detail">
                          <Box className="rcAvatar"><img src={Avatar1} /></Box>
                          <Box className="rcText">
                            <Typography>Olivia Rhye</Typography>
                            <span>30/07/2023, 1:00 AM (15 Min)</span>
                          </Box>
                        </Box>

                        <Box className="videoRecPlayer">
                          <video className='video' controls>
                            <source src={video1} type="video/mp4" />
                          </video>
                        </Box>
                      </Box>

                      {/* tab section */}
                      <Box className="tabeSection">
                        <Box className="recpSectionNav">
                          <Box
                            onClick={() => setNav2(0)}
                            className={
                              nav2 === 0
                                ? "recpSectionNavItem recpSectionNavItemActive"
                                : "recpSectionNavItem"
                            }
                          >
                            <Typography>{t("viewRecapImage.commentsTab")}</Typography>
                          </Box>
                          <Box
                            onClick={() => setNav2(1)}
                            className={
                              nav2 === 1
                                ? "recpSectionNavItem recpSectionNavItemActive"
                                : "recpSectionNavItem"
                            }
                          >
                            <Typography>{t("viewRecapVideo.transcriptTab")}</Typography>
                          </Box>
                          <Box
                            onClick={() => setNav2(2)}
                            className={
                              nav2 === 2
                                ? "recpSectionNavItem recpSectionNavItemActive"
                                : "recpSectionNavItem"
                            }
                          >
                            <Typography>{t("viewRecapImage.summaryAiTab")}</Typography>
                          </Box>
                          <Box
                            onClick={() => setNav2(3)}
                            className={
                              nav2 === 3
                                ? "recpSectionNavItem recpSectionNavItemActive"
                                : "recpSectionNavItem"
                            }
                          >
                            <Typography>{t("viewRecapImage.AiAnalysis")}</Typography>
                          </Box>
                        </Box>
                      </Box>

                      {/* Comments */}
                      <Box
                        className="sectionBox"
                        sx={{ display: nav2 === 0 ? "block" : "none" }}>
                        <Typography className="followText">{t("commonTxt.hideComments")}</Typography>
                        <Box className="commentListBox">
                          {nestedCommentSection({
                            img: avatar1,
                            name: "Orlando Diggs",
                            comment: "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                            date: "Aug 10, 2023",
                            time: "10 h",
                            postDrop: providerDrop,
                            setPostDrop: setProviderDrop,
                            postDropVal: providerDropVal,
                            setPostDropVal: setProviderDropVal
                          })}
                          {nestedCommentSection({
                            img: avatar2,
                            name: "Orlando Diggs",
                            comment: "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                            date: "Aug 10, 2023",
                            time: "10 h",
                            postDrop: providerDrop1,
                            setPostDrop: setProviderDrop1,
                            postDropVal: providerDropVal1,
                            setPostDropVal: setProviderDropVal1
                          })}
                          {nestedCommentSection({
                            img: avatar3,
                            name: "Orlando Diggs",
                            comment: "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                            date: "Aug 10, 2023",
                            time: "10 h",
                            postDrop: providerDrop2,
                            setPostDrop: setProviderDrop2,
                            postDropVal: providerDropVal2,
                            setPostDropVal: setProviderDropVal2
                          })}
                          {nestedCommentSection({
                            img: avatar2,
                            name: "Orlando Diggs",
                            comment: "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                            date: "Aug 10, 2023",
                            time: "10 h",
                            postDrop: providerDrop3,
                            setPostDrop: setProviderDrop3,
                            postDropVal: providerDropVal3,
                            setPostDropVal: setProviderDropVal3
                          })}
                        </Box>
                      </Box>


                      {/* Transcript */}
                      <Box
                        className="sectionBox"
                        sx={{ display: nav2 === 1 ? "block" : "none" }}>
                        <Box className="transcroptBox">
                          <Box className="transDrop">
                            <Typography className='translateLabel'>{t("viewRecapImage.translateTo")} :</Typography>
                            <Box width={"250px"}><Status label={t("viewRecapImage.selectLanguagePlaceholder")} itemAry={languages} setStatusDrop={setLang} statusDrop={lang} setStatusDropVal={setLangVal} statusDropVal={langVal} /></Box>
                          </Box>
                        </Box>

                        <Box className="transSearchBox">
                          <Box sx={{ width: "88%" }}><SearchBar placeholder={t("viewRecapImage.searchWordPlaceholder")} /></Box>

                          <Box className="filterBtn">
                            <img src={filterIcon} />
                            <p>{t("commonTxt.filters")}</p>
                          </Box>

                        </Box>

                        <Typography>Mary Joined the meeting at <span style={{ color: "#4F33D1" }}>5:30</span></Typography>
                        <Box className="gapBox"></Box>
                        <Typography>Natalia (Host) Joined the meeting at <span style={{ color: "#4F33D1" }}>5:30</span></Typography>

                        <Box className="chatBox">

                          <Box className="chatItem leftChat">
                            <Box className="chatInnerBox">
                              <Box className="chatInfo">
                                <Box className="chatUserImg">
                                  <img style={{ width: "20px" }} src={avatar1} />
                                  <span>Mary</span>
                                </Box>
                                <span>05:32 PM</span>
                              </Box>
                              <Box sx={{ background: "#A9DAFF" }} className="chatMessage">
                                <Typography>Really first the same reasons that Casey already stated when I'm camping. I just want to enjoy the experience. So I prefer nice sunny days over the old ones or Ugg spray sunscreen at like that sit in the summer in Spring. </Typography>
                              </Box>
                            </Box>
                          </Box>



                          <Box className="chatItem rightChat">
                            <Box className="chatInnerBox">
                              <Box className="chatInfo">
                                <span>Host</span>
                                <Box className="chatUserImg">
                                  <img style={{ width: "20px" }} src={avatar1} />
                                  <span>05:32 PM</span>
                                </Box>
                              </Box>
                              <Box sx={{ background: "#DCD6F6" }} className="chatMessage">
                                <Typography>Awesome Let's Just Jump Right In then start off. I just have a quick Poll for you. So just give me one second while I display that to you guys. </Typography>
                              </Box>
                            </Box>
                          </Box>

                          <Box className="chatItem rightChat">
                            <Box className="chatInnerBox">
                              <Box className="chatInfo">
                                <span>Host</span>
                                <Box className="chatUserImg">
                                  <img style={{ width: "20px" }} src={avatar1} />
                                  <span>05:32 PM</span>
                                </Box>
                              </Box>
                              <Box sx={{ background: "#DCD6F6" }} className="chatMessage">
                                <Typography>You all indicated that one of your favorite outdoor activities is camping. Let's get some more detail on that! What is your favorite time of year to go camping? </Typography>
                              </Box>
                            </Box>
                          </Box>


                          <Box className="lineGraphBox">
                            <Box width={"30%"}>

                              <Box className="lineBar">
                                <Typography className="lineBarLabel">Spring</Typography>
                                <BorderLinearProgress variant="determinate" value={50} />
                              </Box>
                              <Box className="lineBar">
                                <Typography className="lineBarLabel">Summer</Typography>
                                <BorderLinearProgress variant="determinate" value={80} />
                                <img className="lBarAimg" style={{ width: "20px" }} src={avatar1} />
                                <img className="lBarAimg2" style={{ width: "20px" }} src={avatar2} />
                              </Box>
                              <Box className="lineBar">
                                <Typography className="lineBarLabel">Winter</Typography>
                                <BorderLinearProgress variant="determinate" value={20} />
                                <img className="lBarAimg" style={{ width: "20px" }} src={avatar3} />

                              </Box>
                              <Box className="lineBar">
                                <Typography className="lineBarLabel">Fall</Typography>
                                <BorderLinearProgress variant="determinate" value={0} />
                              </Box>
                            </Box>
                          </Box>

                          <Box className="chatItem leftChat">
                            <Box className="chatInnerBox">
                              <Box className="chatInfo">
                                <Box className="chatUserImg">
                                  <img style={{ width: "20px" }} src={avatar1} />
                                  <span>Mary</span>
                                </Box>
                                <span>05:32 PM</span>
                              </Box>
                              <Box sx={{ background: "#A9DAFF" }} className="chatMessage">
                                <Typography>Kasey_P Yeah, I really like to add the time. I think it looks really cool. And since I don't have a camp in the rain, I'm not too concerned that doesn't have a rainfly vents on the side. I really like a nice touch and to really help things to keep it, you know as cool in the summer is like the size. Typically 1 prefer a larger 10 so that I'm not to cramp and kind of stumbling around too much hot sauce have room for my dog Timber and the quality looks really good. So obviously it's hard to tell just from an image, but I definitely be interested in purchasing it depending on the price.  </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      {/* Summary (AI) */}
                      <Box
                        className="sectionBox"
                        sx={{ display: nav2 === 2 ? "block" : "none" }}
                      >
                        <Box className="transcroptBox">
                          <Box className="transDrop">
                            <Typography className='translateLabel'>{t("viewRecapImage.translateTo")} :</Typography>
                            <Box width={"200px"}><Status label={t("viewRecapImage.selectLanguagePlaceholder")} itemAry={languages} setStatusDrop={setLang} statusDrop={lang} setStatusDropVal={setLangVal} statusDropVal={langVal} /></Box>
                          </Box>

                          <Box>
                            <SearchBar placeholder={t("viewRecapImage.searchWordPlaceholder")} />
                          </Box>
                        </Box>

                        <Box className="rectVideosummeryBox">
                          <Box>
                            <Typography>The Strategic Planning Summit for Project Acceleration unfolded as a multifaceted exploration encompassing critical domains crucial for corporate success. Attendees included key stakeholders, department heads, and subject matter experts, converging to strategize the next phase of the project while considering broader market dynamics.
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                            <Typography sx={{ fontSize: "18px" }}>Market Analysis and Trends:</Typography>
                            <Typography>The Strategic Planning Summit for Project Acceleration unfolded as a multifaceted exploration encompassing critical domains crucial for corporate success. Attendees included key stakeholders, department heads, and subject matter experts, converging to strategize the next phase of the project while considering broader market dynamics.
                            </Typography>
                          </Box>

                          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                            <Typography sx={{ fontSize: "18px" }}>Project Acceleration Strategies:</Typography>
                            <Typography>Brainstorming ensued, generating a wealth of innovative strategies to expedite Project Acceleration. Ideas ranged from product feature enhancements to novel customer engagement models, all aligned with the overarching goal of outpacing competitors. The session saw a dynamic exchange of perspectives, fostering a creative environment conducive to breakthrough solutions.
                            </Typography>
                          </Box>

                          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                            <Typography sx={{ fontSize: "18px" }}>Resource Allocation and Optimization:
                            </Typography>
                            <Typography>A pivotal segment focused on resource allocation and optimization to ensure maximal efficiency. Teams delved into assessing current resource utilization, identifying potential bottlenecks, and proposing streamlined workflows. Decisions on budget adjustments and personnel reallocation were made with a strategic lens, aiming to fortify the project's execution.
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                            <Typography sx={{ fontSize: "18px" }}>Timeline Adjustments and Risk Mitigation:
                            </Typography>
                            <Typography>Recognizing the dynamic nature of the business landscape, participants engaged in a thorough review of project timelines. Contingency plans were developed to address potential risks and ensure adaptability to unforeseen challenges. The emphasis was on building resilience into the project framework while maintaining alignment with overarching goals.
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                            <Typography sx={{ fontSize: "18px" }}>Actionable Next Steps:
                            </Typography>
                            <Typography>The summit concluded with a synthesis of insights gathered throughout the meeting. A roadmap of actionable next steps emerged, outlining the strategic initiatives to be executed in the immediate and medium-term future. Each step was assigned to responsible parties, with clear timelines and performance indicators established for ongoing evaluation.
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                            <Typography sx={{ fontSize: "18px" }}>Cohesive Vision for Success:
                            </Typography>
                            <Typography>Throughout the meeting, a unifying theme emerged — a shared commitment to project success and market domination. The collaborative spirit fostered a cohesive vision, with all participants leaving with a heightened sense of purpose and confidence in the strategic direction set forth.
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                            <Typography>In essence, the Comprehensive Strategic Planning Summit not only propelled Project Acceleration forward but also equipped the organization with a resilient and adaptive framework, ensuring it remains at the forefront of industry innovation and market leadership.</Typography>
                          </Box>


                        </Box>

                      </Box>

                      {/* AI Analysis */}
                      <Box
                        className="sectionBox"
                        sx={{ display: nav2 === 3 ? "block" : "none" }}
                      >
                        <Box className="aiAnalysisSubNav">
                          <Box
                            onClick={() => setAiSubNav(0)}
                            className={
                              aiSubnav === 0 ? "aiSubTab aiSubTabActive" : "aiSubTab"
                            }
                          >
                            <Typography>{t("viewRecapImage.commentAnalysisTab")}</Typography>
                          </Box>
                          <Box
                            onClick={() => setAiSubNav(1)}
                            className={
                              aiSubnav === 1 ? "aiSubTab aiSubTabActive" : "aiSubTab"
                            }
                          >
                            <Typography>{t("viewRecapVideo.videoAnalysisTab")}</Typography>
                          </Box>
                        </Box>

                        <Box
                          className="aiSubSection"
                          sx={{ display: aiSubnav === 1 ? "block" : "none" }}
                        >
                          <Box className="tabelNav">
                            <Typography className="pageTitle">{t("viewRecapImage.sentimentsAnalysisHead")}</Typography>
                            <Box
                              className="exporOpenBtn"
                              onClick={() => setExportDrop(!exportDrop)}
                            >
                              <img src={exportIcon} />
                              <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
                              <img
                                src={dropIcon}
                                style={{ rotate: exportDrop ? "180deg" : "0deg" }}
                              />
                              <Box
                                className="statusPop"
                                sx={{ height: exportDrop ? "auto" : "0px" }}
                              >
                                {exportItem?.map((el, i) => (
                                  <Box className="statusPopItem">
                                    <p>{el}</p>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          </Box>

                          <Box className="pieBox">
                            <ApexChart />
                          </Box>

                          <Box className="chartTableNav">
                            <Box className="chartNavItem">
                              <Typography>{t("commonTxt.hideBtn")}</Typography>
                            </Box>
                            <Box className="chartNavItem">
                              <Typography>{t("commonTxt.groupBtn2")}</Typography>
                            </Box>
                            <Box className="chartNavItem">
                              <Typography>{t("commonTxt.changeColorBtn")}</Typography>
                            </Box>
                          </Box>

                          <Box mb={6} className="workListBox">
                            <Box className="haderRow" minWidth={"955px"}>
                              <Box minWidth={"20%"} className="Tabelsel TabelStatesel">
                                <Typography>{t("recapeVideoReview.tblAction")}</Typography>
                              </Box>
                              <Box minWidth={"15%"} className="Tabelsel">
                                <Typography>{t("recapeVideoReview.tblColor")}</Typography>
                              </Box>
                              <Box minWidth={"30%"} className="Tabelsel TabelStatesel">
                                <Typography>{t("recapeVideoReview.tblTag")}</Typography>
                              </Box>

                              <Box minWidth={"13%"} className="Tabelsel">
                                <Typography>{t("recapeVideoReview.tblResponses")}</Typography>
                              </Box>
                              <Box minWidth={"13%"} className="Tabelsel">
                                <Typography>{t("recapeVideoReview.tblPercentage")}</Typography>
                              </Box>
                            </Box>

                            <Box className="TabelRow" minWidth={"955px"}>
                              <Box
                                minWidth={"20%"}
                                gap={"40px"}
                                className="Tabelsel navFsel navtable"
                              >
                                <img src={Option2LIcon} />
                                <input type="checkBox" className="custom-checkbox" />
                              </Box>
                              <Box minWidth={"15%"} className="Tabelsel Tabelcentersel">
                                <Box
                                  className="colorBox"
                                  sx={{ background: "#21A1F9" }}
                                ></Box>
                              </Box>
                              <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                                <img src={editIcon} />
                                <Typography>😍</Typography>
                                <Typography>Like</Typography>
                              </Box>
                              <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                                <Typography>18</Typography>
                              </Box>
                              <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                                <Typography>58.2%</Typography>
                              </Box>
                            </Box>

                            <Box className="TabelRow" minWidth={"955px"}>
                              <Box
                                minWidth={"20%"}
                                gap={"40px"}
                                className="Tabelsel navFsel navtable"
                              >
                                <img src={Option2LIcon} />
                                <input type="checkBox" className="custom-checkbox" />
                              </Box>
                              <Box minWidth={"15%"} className="Tabelsel Tabelcentersel">
                                <Box
                                  className="colorBox"
                                  sx={{ background: "#FF4E72" }}
                                ></Box>
                              </Box>
                              <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                                <img src={editIcon} />
                                <Typography>😟</Typography>
                                <Typography>Dislike</Typography>
                              </Box>
                              <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                                <Typography>05</Typography>
                              </Box>
                              <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                                <Typography>18.5%</Typography>
                              </Box>
                            </Box>

                            <Box className="TabelRow" minWidth={"955px"}>
                              <Box
                                minWidth={"20%"}
                                gap={"40px"}
                                className="Tabelsel navFsel navtable"
                              >
                                <img src={Option2LIcon} />
                                <input type="checkBox" className="custom-checkbox" />
                              </Box>
                              <Box minWidth={"15%"} className="Tabelsel Tabelcentersel">
                                <Box
                                  className="colorBox"
                                  sx={{ background: "#FFBE28" }}
                                ></Box>
                              </Box>
                              <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                                <img src={editIcon} />
                                <Typography>🤨</Typography>
                                <Typography>Confused</Typography>
                              </Box>
                              <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                                <Typography>08</Typography>
                              </Box>
                              <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                                <Typography>23.3%</Typography>
                              </Box>
                            </Box>
                          </Box>

                          {/* Sentiments Distribution */}
                          <Box className="tabelNav">
                            <Typography className="pageTitle">{t("viewRecapImage.sentimentsDistributionHead")}</Typography>
                            <Box
                              className="exporOpenBtn"
                              onClick={() => setExportDrop2(!exportDrop2)}
                            >
                              <img src={exportIcon} />
                              <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
                              <img
                                src={dropIcon}
                                style={{ rotate: exportDrop2 ? "180deg" : "0deg" }}
                              />
                              <Box
                                className="statusPop"
                                sx={{ height: exportDrop2 ? "auto" : "0px" }}
                              >
                                {exportItem?.map((el, i) => (
                                  <Box className="statusPopItem">
                                    <p>{el}</p>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          </Box>
                          <Box className="bubbleChartBox">
                            <BubbleChart />
                          </Box>



                          {/* Quotes Analysis */}
                          <Box className="tabelNav">
                            <Typography className="pageTitle">{t("viewRecapImage.quotesAnalysisHead")}</Typography>
                            <Box
                              className="exporOpenBtn"
                              onClick={() => setExportDrop3(!exportDrop3)}
                            >
                              <img src={exportIcon} />
                              <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
                              <img
                                src={dropIcon}
                                style={{ rotate: exportDrop3 ? "180deg" : "0deg" }}
                              />
                              <Box
                                className="statusPop"
                                sx={{ height: exportDrop3 ? "auto" : "0px" }}
                              >
                                {exportItem?.map((el, i) => (
                                  <Box className="statusPopItem">
                                    <p>{el}</p>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          </Box>

                          <Box className="barChart">
                            <BarChart series={series} categories={categories} />
                          </Box>




                          <Box className="tabelNav">
                            <Typography className="pageTitle">{t("viewRecapImage.excerptsHead")}</Typography>
                            <Box
                              className="exporOpenBtn"
                              onClick={() => setExportDrop4(!exportDrop4)}
                            >
                              <img src={exportIcon} />
                              <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
                              <img
                                src={dropIcon}
                                style={{ rotate: exportDrop4 ? "180deg" : "0deg" }}
                              />
                              <Box
                                className="statusPop"
                                sx={{ height: exportDrop4 ? "auto" : "0px" }}
                              >
                                {exportItem?.map((el, i) => (
                                  <Box className="statusPopItem">
                                    <p>{el}</p>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          </Box>

                          <Box className="barChart">
                            <BarChart series={series2} categories={categories2} />
                          </Box>


                          {/* bottom tab section */}
                          <Box className="bottomTabel">

                            <Box className="tabeSection">
                              <Box className="recpSectionNav">
                                <Box
                                  onClick={() => setNav3(0)}
                                  className={
                                    nav3 === 0
                                      ? "recpSectionNavItem recpSectionNavItemActive"
                                      : "recpSectionNavItem"
                                  }
                                >
                                  <Typography>{t("viewRecapImage.manualTab")}</Typography>
                                </Box>
                                <Box
                                  onClick={() => setNav3(1)}
                                  className={
                                    nav3 === 1
                                      ? "recpSectionNavItem recpSectionNavItemActive"
                                      : "recpSectionNavItem"
                                  }
                                >
                                  <Typography>{t("viewRecapImage.excerptsAiTab")}</Typography>
                                </Box>
                              </Box>
                            </Box>


                            {/* Manual */}
                            <Box sx={{ display: nav3 === 0 ? "block" : "none" }}>
                              <Box className="tabelNav">
                                <Typography className="pageTitle">{t("viewRecapImage.excerptsHead")}</Typography>
                                <Box width={"320px"}><SearchBar placeholder={t("viewRecapImage.searchPlaceholder")} /></Box>
                              </Box>


                              <Box mb={6} className="workListBox">
                                <Box className="haderRow" minWidth={"955px"}>
                                  <Box minWidth={"10%"} className="Tabelsel TabelStatesel">
                                    <Typography>{t("commonTxt.Uses")}</Typography>
                                  </Box>
                                  <Box minWidth={"17%"} className="Tabelsel TabelStatesel">
                                    <Typography>{t("commonTxt.Codes")}</Typography>
                                  </Box>
                                  <Box minWidth={"40%"} className="Tabelsel TabelStatesel">
                                    <Typography>{t("commonTxt.Description")}</Typography>
                                  </Box>
                                </Box>

                                <Box className="TabelRow" minWidth={"955px"}>
                                  <Box minWidth={"10%"} className="Tabelsel ">
                                    <Box className="UBox" >6</Box>
                                  </Box>
                                  <Box minWidth={"17%"} gap={"10px"} className="Tabelsel">
                                    <Typography>Empowerment</Typography>
                                  </Box>
                                  <Box minWidth={"40%"} className="Tabelsel Tabelcentersel">
                                    <Typography>Verbatim that touches on the theme of “empowerment”</Typography>
                                  </Box>
                                </Box>

                                <Box className="TabelRow" minWidth={"955px"}>
                                  <Box minWidth={"10%"} className="Tabelsel ">
                                    <Box className="UBox" >4</Box>
                                  </Box>
                                  <Box minWidth={"17%"} gap={"10px"} className="Tabelsel">
                                    <Typography>Nostalgia</Typography>
                                  </Box>
                                  <Box minWidth={"40%"} className="Tabelsel Tabelcentersel">
                                    <Typography>Verbatim that touches on the theme of “nostalgia”</Typography>
                                  </Box>
                                </Box>

                                <Box className="TabelRow" minWidth={"955px"}>
                                  <Box minWidth={"10%"} className="Tabelsel ">
                                    <Box className="UBox" >2</Box>
                                  </Box>
                                  <Box minWidth={"17%"} gap={"10px"} className="Tabelsel">
                                    <Typography>Relaxation</Typography>
                                  </Box>
                                  <Box minWidth={"40%"} className="Tabelsel Tabelcentersel">
                                    <Typography>Verbatim that touches on the theme of “relaxation”</Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            {/* Excerpts */}
                            <Box sx={{ display: nav3 === 1 ? "block" : "none" }}></Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </>
                }
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
