import React, { useState, useEffect } from 'react'
import "./style.css";
import { useNavigate } from "react-router-dom";

//images
import AdminBG from "../../../Assets/Images/Add-files-cuate.png";
import arrow from "../../../Assets/Images/arrow.png";


//data
import {
  trashBlack,
  participantshBlack,
  publicationData,
  editIconBlack
} from "../../../Assets/Data";

//component
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import SideMenu from "../../../Components/Researchers/SideMenu";
import { sideHaumIcon } from "../../../Assets/Data";
import { AppAddBtn } from "../../../Components/AppTools/AppButton"
import { SearchBar, LatestCreated, Status } from "../../../Components/AppTools/AppTool";
import PublicationView from "../PublicationView";
import DeleteResearchPublication from '../../../Components/Researchers/DeleteResearchPublication';
import AddResearchPublication from '../../../Components/Researchers/AddResearchPublication';

export default function Publications({ slideMenu, setSlideMenu, workspaceDrop, setWorkSpaceDrop }) {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Latest Published");
  const [allDrop, setAllDrop] = useState(false);
  const [allDropVal, setAllDropVal] = useState();
  const [newArrayData, setNewArrayData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [projectCPopOpenByIndex, setProjectCPopOpenByIndex] = useState("");
  const [publicationView, setPublicationView] = useState(false);
  const [deleteResearchPublication, setDeleteResearchPublication] = useState(false);
  const [addResearchPublication, setAddResearchPublication] = useState(false);


  useEffect(() => {
    printItemsByState(publicationData, currentPage);
  }, [currentPage]);

  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };


  return (
    <>
      <Box className="ResearchersContainer publictionPage">
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
            <AppAddBtn buttonText={t("Publications.createNewPublications")} onClick={() => setAddResearchPublication(true)} />

          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer">
          <DeleteResearchPublication 
            deleteResearchPublication={deleteResearchPublication} 
            setDeleteResearchPublication={setDeleteResearchPublication}
          />
          <AddResearchPublication 
            addResearchPublication={addResearchPublication} 
            setAddResearchPublication={setAddResearchPublication}
          />
            {/*----------------- No data page-------------- */}
            {publicationData.length <= 0 ?
              <>
                <Box className="adminWorkSBG">
                  <img src={AdminBG} />
                </Box>
                <Box className="adminBGTextBox">
                  <Typography className="adminHeaderText">{t("Publications.welcome")}</Typography>
                  <Typography className="adminSubHeaderText">{t("Publications.welcomeSub")}</Typography>
                </Box>
              </> :
              publicationView ?
                <PublicationView setPublicationView={setPublicationView} /> :
                <>
                  <>
                    <Box mt={2} px={3} className="workListNav">
                      <Box mt={2} className="workListNavItem workListNavItemMobile">
                        <Typography className="pageHeaderText">{t("Publications.tableHeader")}</Typography>
                      </Box>
                      <Box className="workListNavItem workListNavItemDesktop">
                        <Typography className="pageHeaderText">{t("Publications.tableHeader")}</Typography>
                      </Box>
                      <Box
                        sx={{ minWidth: "615px", display: "flex", gap: "20px" }}
                        className="workListNavItem workListSearchBar"
                      >
                        <Box minWidth={"200px"}>
                          <SearchBar placeholder={t("Conversations.searchHerePlaceholder")} />
                        </Box>
                        <Box minWidth={"105px"}>
                          <Status label="All" setStatusDrop={setAllDrop} statusDrop={allDrop} setStatusDropVal={setAllDropVal} statusDropVal={allDropVal} />
                        </Box>
                        <Box minWidth={"211px"} className="agreSearchB2">
                          <LatestCreated
                            setLatestDrop={setLatestDrop}
                            latestCDrop={latestCDrop}
                            setLatestDropVal={setLatestDropVal}
                            latestDropVal={latestDropVal}
                          />
                        </Box>
                      </Box>
                    </Box>


                    <Box className="workListBox">
                      <Box className="haderRow" minWidth={"1270px"}>
                        <Box className="Tabelsel navFsel TabelselItemStart" minWidth={"32%"}>
                          <Typography>{t("Publications.PublicationName")}</Typography>
                        </Box>
                        <Box className="Tabelsel TabelselItemStart" minWidth={"17%"}>
                          <Typography>{t("Publications.ProjectName")}</Typography>
                        </Box>
                        <Box className="Tabelsel TabelselItemStart" minWidth={"17%"}>
                          <Typography>{t("Publications.Status")}</Typography>
                        </Box>
                        <Box className="Tabelsel TabelselItemStart" minWidth={"17%"}>
                          <Typography>{t("Publications.PublishedBy")}</Typography>
                        </Box>
                        <Box className="Tabelsel TabelselItemStart" minWidth={"17%"}>
                          <Typography>{t("Publications.PublishedOn")}</Typography>
                        </Box>
                      </Box>

                      {newArrayData &&
                        newArrayData?.map((el, index) => (
                          <Box key={index} className="TabelRow" minWidth={"1270px"}>
                            <Box
                              className="Tabelsel navFsel PNavTable"
                              minWidth={"32%"}
                            >
                              <Box
                                onClick={() => {
                                  if (projectCPopOpenByIndex === index) {
                                    setProjectCPopOpenByIndex(null);
                                  } else {
                                    setProjectCPopOpenByIndex(index);
                                  }
                                }}
                                id="dot2" className="dot">
                                {
                                  <Box
                                    sx={{
                                      display:
                                        projectCPopOpenByIndex === index
                                          ? "flex"
                                          : "none",
                                    }}
                                    className="optionPop"
                                  >
                                    <Box className="optionPopItem">
                                      {editIconBlack}
                                      <Typography>{t("Publications.EditPublication")}</Typography>
                                    </Box>
                                    <Box className="optionPopItem">
                                      {participantshBlack}
                                      <Typography>{t("Publications.ExportPublication")}</Typography>
                                    </Box>
                                    <Box className="optionPopItem" onClick={() => setDeleteResearchPublication(true)}>
                                      {trashBlack}
                                      <Typography>{t("Publications.DeletePublication")}</Typography>
                                    </Box>
                                  </Box>
                                }
                              </Box>
                              <Typography className='publicationTitleText' onClick={() => Navigate("/researchers/Publications-view")}>{el.publicationName}</Typography>
                            </Box>

                            <Box className="Tabelsel " minWidth={"17%"}>
                              <Typography>{el.ProjectName}</Typography>
                            </Box>
                            <Box className="Tabelsel" minWidth={"17%"}>
                              <Typography>{el.Status}</Typography>
                            </Box>
                            <Box className="Tabelsel " minWidth={"17%"}>
                              <Typography>{el.PublishedBy}</Typography>
                            </Box>
                            <Box className="Tabelsel " minWidth={"17%"}>
                              <Typography>{el.PublishedOn}</Typography>
                            </Box>
                          </Box>
                        ))}
                    </Box>
                    <Box mb={5} className="pagenation">
                      <Box onClick={handlePrev} className="tabelBtn">
                        <img src={arrow} />
                        <Typography>{t("commonTxt.previouspage")}</Typography>
                      </Box>

                      <Box className="pageNumberBox">
                        <Box
                          onClick={() => setCurrentPage(0)}
                          className={
                            currentPage === 0
                              ? "pageNumber pageNumberActive"
                              : "pageNumber"
                          }
                        >
                          <p>1</p>
                        </Box>
                        <Box
                          onClick={() => setCurrentPage(1)}
                          className={
                            currentPage === 1
                              ? "pageNumber pageNumberActive"
                              : "pageNumber"
                          }
                        >
                          <p>2</p>
                        </Box>
                        <Box
                          onClick={() => setCurrentPage(2)}
                          className={
                            currentPage === 2
                              ? "pageNumber pageNumberActive"
                              : "pageNumber"
                          }
                        >
                          <p>3</p>
                        </Box>
                        <Box
                          onClick={() => setCurrentPage(3)}
                          className={
                            currentPage === 3
                              ? "pageNumber pageNumberActive"
                              : "pageNumber"
                          }
                        >
                          <p>4</p>
                        </Box>
                        <Box
                          onClick={() => setCurrentPage(4)}
                          className={
                            currentPage === 4
                              ? "pageNumber pageNumberActive"
                              : "pageNumber"
                          }
                        >
                          <p>5</p>
                        </Box>
                      </Box>

                      <Box onClick={handleNext} className="tabelBtn">
                        <Typography>{t("commonTxt.nextPage")}</Typography>
                        <img style={{ rotate: "180deg" }} src={arrow} />
                      </Box>
                    </Box>
                  </>
                </>
            }
          </Box>
        </Box>
      </Box>
    </>
  )
}
