import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";

import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";

//components
import { AppBtnOutLine, AppBtn } from "../../AppTools/AppButton";

export default function ComposeMailPop({
  directMessageNavComposePop,
  setDirectMessageNavComposePop,
  composeEmailPop,
  setComposeMailPop,
  setPreviewMailPop,
  scheduleMailPop,
  setScheduleMailPop,
}) {
  const email1 =
    "Hello {fname}, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Read more";
  const email2 = "Best Regards,  Lorem Ipsum";
  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();
  const [signatureTextLaOut, setSignatureTextLaOut] = useState("start");
  const [signatureTextItalic, setSignatureTextItalic] = useState(false);
  const [signatureTextBold, setSignatureTextBold] = useState(false);
  const [signatureTextUnderLine, setSignatureTextUnderLine] = useState();

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setDirectMessageNavComposePop(false);
    }
  };

  const handelNextPop = () => {
    setPreviewMailPop(true);
    setDirectMessageNavComposePop(false);
  };

  const handelSchedulePop = () => {
    setScheduleMailPop(true);
    setDirectMessageNavComposePop(false);
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine === "underline") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine === "line-through") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("line-through");
        }
      }
    }
  };

  useEffect(() => {
    if (composeEmailPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [composeEmailPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: directMessageNavComposePop ? "flex" : "none" }}
        className="workspacePop"
      >
        <Box
          sx={{ display: directMessageNavComposePop ? "flex" : "none" }}
          className="workspaceContaint emailTemPop BrodMail ComposeMail"
        >
          <Box className="mailBordPopHeader">
            <Typography className="workspaceHeader">Compose Message</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography className="unLBtn" mr={2}>
                Use Templates
              </Typography>
              <AppBtnOutLine buttonText="Save as Template" />
            </Box>
          </Box>

          <Box className="basicBox">
            <Box>
              <Typography className="eTemPopLabel">
                Recipients<samp>*</samp>
              </Typography>
              <Box className="pickerBox">
                <input className="eTemInput" placeholder="" />
              </Box>
            </Box>
          </Box>

          <Box className="basicBox">
            <Box>
              <Typography className="eTemPopLabel">
                Subject<samp>*</samp>
              </Typography>
              <Box className="pickerBox">
                <input
                  className="eTemInput"
                  placeholder="Welcome to the Project!"
                />
              </Box>
            </Box>
          </Box>

          <Typography className="eTemPopLabel">
            Message<samp>*</samp>
          </Typography>
          <Box className="emailInputBox">
            <Box className="emailInputBoxNav">
              <Box
                onClick={() => setMessageTextItalic(!messageTextItalic)}
                className="emailImgBox"
              >
                <img src={IIcon} />
              </Box>
              <Box
                onClick={() => setMessageTextBold(!messageTextBold)}
                className="emailImgBox"
              >
                <img src={BIcon} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("underline", "message")}
                className="emailImgBox"
              >
                <img src={UnderLineU} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("line-through", "message")}
                className="emailImgBox"
              >
                <img src={MLineU} />
              </Box>
              <Box className="emailImgBox">
                <img src={AtachMentIcon} />
              </Box>
              <Box className="emailImgBox">
                <img src={Qbox} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("start")}
                className="emailImgBox"
              >
                <img src={Line1} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("center")}
                className="emailImgBox"
              >
                <img src={Line2} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("end")}
                className="emailImgBox"
              >
                <img src={Line3} />
              </Box>
              <Box className="emailImgBox">
                <img src={Line4} />
              </Box>
              <Box className="emailImgBox">
                <img src={emoji} />
              </Box>
            </Box>
            <textarea
              style={{
                textAlign: messageTextLaOut,
                fontStyle: messageTextItalic ? "italic" : "normal",
                fontWeight: messageTextBold ? "bold" : "normal",
                textDecoration: messageTextUnderLine,
                // textDecoration: messageTextMLine ? "line-through" : null
              }}
              className="emailInput"
              name="emailMessage"
              value={emailval?.emailMessage || email1}
              onChange={handelChange}
            />
          </Box>

          <Typography className="eTemPopLabel">
            Signature<samp>*</samp>
          </Typography>
          <Box className="emailInputBox">
            <Box className="emailInputBoxNav">
              <Box
                onClick={() => setSignatureTextItalic(!signatureTextItalic)}
                className="emailImgBox"
              >
                <img src={IIcon} />
              </Box>
              <Box
                onClick={() => setSignatureTextBold(!signatureTextBold)}
                className="emailImgBox"
              >
                <img src={BIcon} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("underline", "signature")}
                className="emailImgBox"
              >
                <img src={UnderLineU} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("line-through", "signature")}
                className="emailImgBox"
              >
                <img src={MLineU} />
              </Box>
              <Box className="emailImgBox">
                <img src={AtachMentIcon} />
              </Box>
              <Box className="emailImgBox">
                <img src={Qbox} />
              </Box>
              <Box
                onClick={() => setSignatureTextLaOut("start")}
                className="emailImgBox"
              >
                <img src={Line1} />
              </Box>
              <Box
                onClick={() => setSignatureTextLaOut("center")}
                className="emailImgBox"
              >
                <img src={Line2} />
              </Box>
              <Box
                onClick={() => setSignatureTextLaOut("end")}
                className="emailImgBox"
              >
                <img src={Line3} />
              </Box>
              <Box className="emailImgBox">
                <img src={Line4} />
              </Box>
              <Box className="emailImgBox">
                <img src={emoji} />
              </Box>
            </Box>
            <textarea
              style={{
                textAlign: signatureTextLaOut,
                fontStyle: signatureTextItalic ? "italic" : "normal",
                fontWeight: signatureTextBold ? "bold" : "normal",
                textDecoration: signatureTextUnderLine,
              }}
              className="emailInput"
              name="emailSignature"
              value={emailval?.emailSignature || email2}
              onChange={handelChange}
            />
          </Box>
          <Box className="workPupBtnBox">
            <AppBtnOutLine
              buttonText="Save as Draft"
              onClick={() => setDirectMessageNavComposePop(false)}
            />
            <AppBtnOutLine
              buttonText="Cancel"
              onClick={() => setDirectMessageNavComposePop(false)}
            />
            <AppBtnOutLine
              onClick={() => handelSchedulePop()}
              buttonText="Schedule"
            />
            <AppBtn
              buttonText="Preview & Send"
              onClick={() => handelNextPop()}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
