import * as React from "react";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

export default function ReferenceDateExplicitTimePicker({onChange, value}) {
  value = value || null;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={2} sx={{ minWidth: 305 }}>
        <TimePicker
          value={value}
          onChange={(e) => {{if(typeof(onChange) != 'undefined'){onChange(e)}}}}
          referenceDate={dayjs("2022-04-17")}
        />
      </Stack>
    </LocalizationProvider>
  );
}
