import React, { useState, useRef } from 'react'
import { Box, Typography } from '@mui/material'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

//images
import groupIcon from "../../../Assets/Images/groupsIcon.png";
import filterIcon from "../../../Assets/Images/filterIcon.png"
import AdminBG from "../../../Assets/Images/Add-files-cuate.png";
import exportIcon from "../../../Assets/Images/exportIcon.png";

//data
import { videoTham } from "../../../Assets/Data"

import RecapVideoCommentPage from "../RecapVideoCommentPage";


export default function RecapVideo() {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const Navigate = useNavigate();

  const [videoCommentPage, setVideoCommentPage] = useState(false)

  const videoData = true;
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = (event) => {
    const video = event.target.previousSibling; // Get the video element preceding the button
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };


  return (
    <>
      {!videoData ?
        <Box className="threadNoDataPage">
          <Box className="adminWorkSBG">
            <img src={AdminBG} />
          </Box>
          <Box className="adminBGTextBox">
            <Typography className="adminSubHeaderText">
              {t("recapExpression.expresssionText")}
            </Typography>
          </Box>
        </Box>
        :

        <Box className="threadContainer workspaceListBox">
          <Box className="threadNavBox">
            <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <Box className="actTNavBtn">
                <img src={groupIcon} />
                <Typography>{t("commonTxt.groupBtn")}</Typography>
              </Box>

              <Box className="filterBtn">
                <img src={filterIcon} />
                <p>{t("commonTxt.filters")}</p>
              </Box>
            </Box>

            <Box className="actTNavBtn">
              <img src={exportIcon} />
              <Typography>{t("commonTxt.exportLink")}</Typography>
            </Box>
          </Box>

          <ImageList variant="masonry" cols={3} gap={8}>
            {videoTham.map((item, index) => (
              <ImageListItem key={index}
                onClick={() => Navigate("/recap/view-video?r=videos")}
              >
                <video className="video activeVideoItem" ref={videoRef}>
                  <source src={item.img} type="video/mp4" />
                  {t("recapVideo.videoErrorMessage")}
                </video>

                <button className="play-button" onClick={handlePlay}></button>
              </ImageListItem>
            ))}
          </ImageList>

        </Box>
      }
    </>
  )
}
