import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./style.css"

//images
import clogsvg from "../../../Assets/Images/New folder/Icon TeraPage Transparent mix dark 2.svg";
import SClolo from "../../../Assets/Images/TeraPage Transparent mix black.png";
import backIcon from "../../../Assets/Images/WhiteBack Button.png";
import PIIcon from "../../../Assets/Images/icon-ASetting01.svg";
import PWIcon from "../../../Assets/Images/icon-ASetting02.svg";
import FAIcon from "../../../Assets/Images/icon-ASetting03.svg";
import TZIcon from "../../../Assets/Images/icon-ASetting04.svg";
import ENIcon from "../../../Assets/Images/icon-ASetting05.svg";
import AGIcon from "../../../Assets/Images/icon-ASetting06.svg";
import INIcon from "../../../Assets/Images/icon-ASetting07.svg";
import questionMark from "../../../Assets/Images/help-web-button 1.png";
import contactTicketIcon from "../../../Assets/Images/contactTicketIcon.svg";
import safetyPrivacyIcon from "../../../Assets/Images/safetyPrivacyIcon.svg";
import { useSSR } from "react-i18next";
import { HELP_URL } from "../../../lib/constants";

export default function PAS_SideMenu({ slideMenu }) {
  const currentUrl = new URL(window.location.href).pathname;
  const Navigate = useNavigate();

  const [helpButtonState, setHelpButtonState] = useState(false);
  const [helpButtondisplay, setHelpButtonDisplay] = useState("notdisplayed");

  const showButton = e => {
    e.preventDefault();
    setHelpButtonDisplay("actHeBtn");
  };

  const hideButton = e => {
    e.preventDefault();
    setHelpButtonDisplay("notdisplayed");
  };

  const handleUserOpenTabe = (Url) => {
    Navigate(Url);
  };

  const openLink = (url) =>{
    window.open(url, "_blank");
  }

  const imgStyle1 = {
    marginleft:-128
  };

  const imgStyle2 = {
    marginleft:-28
  };

  return (
    <>
      <Box className={slideMenu ? "sideMenu sideMenuActive researcherSideMenu" : "sideMenu researcherSideMenu"}>
        <Box onClick={() => Navigate("/users/dashboard")} className="clogBox">
          {slideMenu ? (
            <img style={{ width: "176.442px", height: "20px" }} src={clogsvg} />
          ) : (
            <img style={{ width: "30px" }} src={SClolo} />
          )}
        </Box>
        <Typography
          variant="h4"
          className="submenuheading"
          sx={{ display: slideMenu ? "block" : "none" }} >
          Account Settings
        </Typography>
        <Box className="navHrLine"></Box>

        <Box
          onClick={() => handleUserOpenTabe("/account-settings/personal-information")}
          className={
            currentUrl === "/account-settings/personal-information"
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
        >
          <img src={PIIcon} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Personal Information
          </Typography>
        </Box>

        <Box
          onClick={() => handleUserOpenTabe("/account-settings/manage-password")}
          className={
            currentUrl === "/account-settings/manage-password"
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
        >
          <img src={PWIcon} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Password Management
          </Typography>
        </Box>

        <Box
          onClick={() => handleUserOpenTabe("/account-settings/2fa-auth")}
          className={
            currentUrl === "/account-settings/2fa-auth"
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
        >
          <img src={FAIcon} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            2 Factor Authentication
          </Typography>
        </Box>

        <Box
          onClick={() => handleUserOpenTabe("/participants/time-Zone")}
          className={
            currentUrl === "/participants/time-Zone"
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
        >
          <img src={TZIcon} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Time Zone
          </Typography>
        </Box>

        <Box
          onClick={() => handleUserOpenTabe("/account-settings/email-notification")}
          className={
            currentUrl === "/account-settings/email-notification"
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
        >
          <img src={ENIcon} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Email Notifications
          </Typography>
        </Box>

        <Box
          onClick={() => handleUserOpenTabe("/account-settings/agreements")}
          className={
            currentUrl === "/account-settings/agreements"
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
        >
          <img src={AGIcon} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Agreements
          </Typography>
        </Box>
        <Box
          onClick={() => handleUserOpenTabe("/participants/integration")}
          className={
            currentUrl === "/participants/integration"
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
        >
          <img src={INIcon} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Integrations
          </Typography>
        </Box>
        <Box
          onClick={() => handleUserOpenTabe("/participants/contact-ticket")}
          className={
            currentUrl === "/participants/contact-ticket"
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
        >
          <img src={contactTicketIcon} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Contact and Tickets
          </Typography>
        </Box>
        <Box
          onClick={() => handleUserOpenTabe("/participants/safety-privacy")}
          className={
            currentUrl === "/participants/safety-privacy"
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
        >
          <img src={safetyPrivacyIcon} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Safety and Privacy
          </Typography>
        </Box>

        <Box
          onClick={() => handleUserOpenTabe("/participants/email-management")}
          className={
            currentUrl === "/participants/email-management"
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
        >
          <img src={ENIcon} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Email Management
          </Typography>
        </Box>

        <Box className="bottomHelpBox" onMouseEnter={e => showButton(e)}
        onMouseLeave={e => hideButton(e)}>
          <Box className={helpButtondisplay} onClick={()=>openLink(HELP_URL)}>
            <Typography>Need Help?</Typography>
          </Box>
          <img className= {helpButtondisplay === "actHeBtn" ? "helpBtnIcon2" : "helpBtnIcon"} src={questionMark} />
          
        </Box>

        <Box
          onClick={() => Navigate("/notification")}
          className={slideMenu ? "userNavBackBtn" : "userNavBackBtn userNavBackBtnInActive"}
        >
          <img src={backIcon} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Back
          </Typography>
        </Box>
      </Box>
    </>
  );
}
