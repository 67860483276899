import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./style.css";
import axios from "../../../lib/axios";
import { SearchBar } from "../../AppTools/AppTool";
import { AppBtn } from "../../AppTools/AppButton";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { updateLanguage } from "../../../Services/adminActions";
import { useAppSelector } from "../../../lib/store/store";

export default function Language() {
  const [recipientDrop, setRecipientDrop] = useState(false);
  const [recipientDropVal, setRecipientDropVal] = useState();
  const [allLanguageChunk, setAllLanguageChunk] = useState([]);
  const [allSelectedLanguage, setAllSelectedLanguage] = useState("");
  const authState = useAppSelector((state) => state.auth);
  const defaultFilters = {
    search: "",
    sortBy: "name",
    sortOrder: 1,
    status: "",
  };

  const [filterState, setFilterState] = useState({ ...defaultFilters });

  const handleFilterChange = (changedFilter) => {
    let newFilters = {
      ...filterState,
      ...changedFilter,
    };
    setFilterState(newFilters);
    getLanguages(newFilters);
  };

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  useEffect(() => {
    getLanguages();
  }, []);

  const getLanguages = async (filters = {}) => {
    try {
      let url = `/master/languages?page=1`;
      for (const key in filters) {
        if (filters.hasOwnProperty(key)) {
          url += `&${key}=${filters[key]}`;
        }
      }
      const response = await axios.get(url);
      if (response.success) {
        const selectedCount = response.data.data.filter(
          (el) => el.status === "active"
        ).length;
        setAllSelectedLanguage(`${selectedCount} Languages Selected`);
        setAllLanguageChunk(chunkArray(response.data.data, 2));
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  };

  const changeStatus = async (language) => {
    try {
      const payload = {
        name: language.name,
        translation: language.translation,
        status: language.status === "active" ? "inactive" : "active",
      };

      const response = await updateLanguage(
        language._id,
        payload,
        authState.authToken
      );
      if (response.success) {
        getLanguages();
        toast.success(response.message, TOAST_CONFIG);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  };

  const chunkArray = (arr, size) => {
    return arr.reduce((acc, _, index) => {
      if (index % size === 0) {
        acc.push(arr.slice(index, index + size));
      }
      return acc;
    }, []);
  };

  const isChecked = (lenSt) => {
    return lenSt === "active" ? true : false;
  };

  return (
    <>
      <Box className="EmailSContainer">
        <Typography className="pageHeaderText">
          {t("languagePage.sprtedLang")}
        </Typography>

        <Box className="languageBoxItem">
          <Box>
            <Typography className="languageMainLabel">
              {allSelectedLanguage}
            </Typography>
          </Box>
          <Box className="workListSearchBar">
            <SearchBar handleFilterChange={handleFilterChange} />
          </Box>
        </Box>
        <Box className="checkBoxLanguageItem">
          {/* <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.Arabic")}
            </Typography>
          </Box> */}
          {/* <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {allSelectedLanguage}
            </Typography>
          </Box> */}
        </Box>

        {/* {allLanguage && allLanguage.map((lan, index) => (
          <Box className="checkBoxLanguageItem" key={index}>
            <Box key={index}>
              <input
                type="checkBox"
                defaultChecked={lanStatus[index]}
                onClick={() => handleLenStatusToggle(lan, index)}
              />
              <Typography className="languageLabel">
                {lan.name}
              </Typography>
            </Box>
          </Box>
        ))} */}
        {allLanguageChunk &&
          allLanguageChunk.map((lanChunk, index) => (
            <Box className="checkBoxLanguageItem" key={index}>
              {lanChunk.map((lan, ind) => (
                <Box key={ind}>
                  <input
                    type="checkBox"
                    defaultChecked={isChecked(lan.status)}
                    onClick={() => changeStatus(lan)}
                  />
                  <Typography className="languageLabel">{lan.name}</Typography>
                </Box>
              ))}
            </Box>
          ))}

        {/* <Box className="checkBoxLanguageItem">
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.Chinese(Simplified)")}
            </Typography>
          </Box>
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.Japanese")}
            </Typography>
          </Box>
        </Box>

        <Box className="checkBoxLanguageItem">
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.Chinese(Traditional)")}
            </Typography>
          </Box>
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.Korean")}
            </Typography>
          </Box>
        </Box>

        <Box className="checkBoxLanguageItem">
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.Danish")}
            </Typography>
          </Box>
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.Malay")}
            </Typography>
          </Box>
        </Box>

        <Box className="checkBoxLanguageItem">
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.Dutch")}
            </Typography>
          </Box>
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.Polish")}
            </Typography>
          </Box>
        </Box>

        <Box className="checkBoxLanguageItem">
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.English(UK)")}
            </Typography>
          </Box>
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.Portuguese")}
            </Typography>
          </Box>
        </Box>

        <Box className="checkBoxLanguageItem">
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.English(US)")}
            </Typography>
          </Box>
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.Russian")}
            </Typography>
          </Box>
        </Box>

        <Box className="checkBoxLanguageItem">
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.French")}
            </Typography>
          </Box>
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.Romanian")}
            </Typography>
          </Box>
        </Box>

        <Box className="checkBoxLanguageItem">
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.French(Canadian)")}
            </Typography>
          </Box>
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.Spanish")}
            </Typography>
          </Box>
        </Box>

        <Box className="checkBoxLanguageItem">
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.German")}
            </Typography>
          </Box>
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.Swedish")}
            </Typography>
          </Box>
        </Box>

        <Box className="checkBoxLanguageItem">
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.Hebrew")}
            </Typography>
          </Box>
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.Tagalog")}
            </Typography>
          </Box>
        </Box>

        <Box className="checkBoxLanguageItem">
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.Hindi")}
            </Typography>
          </Box>
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.Thai")}
            </Typography>
          </Box>
        </Box>

        <Box className="checkBoxLanguageItem">
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.Hungarian")}
            </Typography>
          </Box>
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.Turkish")}
            </Typography>
          </Box>
        </Box>

        <Box className="checkBoxLanguageItem">
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.Indonesian")}
            </Typography>
          </Box>
          <Box>
            <input
              type="checkBox"
              defaultChecked={recipientDropVal === "Custom" ? false : true}
            />
            <Typography className="languageLabel">
              {t("languagePage.Vietnamese")}
            </Typography>
          </Box>
        </Box>
        //Todo
        <Box className="languageBtnBox">
          <AppBtn buttonText={t("languagePage.addLangBtn")} />
        </Box> */}
      </Box>
    </>
  );
}
