import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, Grid, FormLabel } from "@mui/material";
import "./style.css";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

// Images
import BlankImage from "../../../Assets/Images/No data-cuate 1.png";
import arrow from "../../../Assets/Images/arrow.png";
import { sideHaumIcon } from "../../../Assets/Data";

//components
import SideMenu from "../../../Components/WSAdministration/SideMenu";
import {
  AppBtn,
  AppBtnText,
  AppBtnOutLine,
  AppAddBtn,
} from "../../../Components/AppTools/AppButton";
import {
  SearchBar,
  AntSwitch,
  DropDownLists,
} from "../../../Components/AppTools/AppTool";
import NoAdminBg from "../../../Components/WSAdministration/NoAdminBg";
import AllAdminList from "../../../Components/WSAdministration/AllAdminList";
import EditAdmin from "../../../Components/WSAdministration/EditAdmin";
import AddNewAdmin from "../../../Components/WSAdministration/AddNewAdmin";
import InviteAdminPop from "../../../Components/WSAdministration/InviteAdminPop";
import {
  SearchResearchersData,
  SearchCollaboratorData,
  SearchObserverData,
} from "../../../Assets/Data";
import { useTranslation } from "react-i18next";
import axios from "../../../lib/axios";
import { API_BASE_URL } from "../../../env";
import { useSelector } from "react-redux";
import { useAppSelector } from "../../../lib/store/store";
import ResearcherLayout from "../../Researchers/partials/ResearcherLayout";
import AdminLayout from "../../Admin/partials/AdminLayout";
import { renderUserLayout } from "../../../lib/helpers";

export default function WSAdmin({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
  participantsSubMenu,
  setParticipantsSubMenu,
  configurationSubMenu,
  setConfigurationSubMenu,
}) {
  const workspaceState = useAppSelector(
    (state) => state.workspace?.selectedWorkspace?._id
  );
  const authState = useAppSelector((state) => state.auth);

  const handleChange1 = (event) => {
    const selectedItem = projects.find(
      (project) => project._id === event.target.value
    );
    handleFilterProjects(event.target.value);
    setProjectDropVal(selectedItem ? selectedItem._id : "");
  };

  const [NewAdminSection, setNewAdminSection] = useState(false);
  const [EditAdminSection, setEditAdminSection] = useState(false);
  const [inviteAdminPop, setInviteAdminPop] = useState(false);
  const [reloadList, setReloadList] = useState(false);

  const [updateWSAdmin, setUpdateWSAdmin] = useState("");
  const [workspaceUserType, setWorkspaceUserType] = useState("");
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const [researcherList, setResearcherList] = useState([]);
  const [collaboratorList, setCollaboratorList] = useState([]);
  const [observerList, setObserverList] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectDropVal, setProjectDropVal] = useState("");

  // Filter By Role
  const [selectFilterbyRole, setselectFilterbyRole] = useState("");
  const [roleTotalDataLength, setRoleTotalDataLength] = useState();
  const handleChange2 = (event) => {
    const selectedValue = event.target.value;
    setselectFilterbyRole(selectedValue);
    if (selectedValue === "researcher") {
      getResearcher(1);
      setRoleTotalDataLength(researcherList.length);
    } else if (selectedValue === "collaborator") {
      getCollaborator(1);
      setRoleTotalDataLength(collaboratorList.length);
    } else if (selectedValue === "observer") {
      getObserver(1);
      setRoleTotalDataLength(observerList.length);
    }
  };

  // Search By Name
  const [searchInResearcherList, setSearchInResearcherList] = useState([]);
  const [searchInCollaboratorList, setSearchInCollaboratorLsit] = useState([]);
  const [searchInOnserverList, setSearchInObserverLsit] = useState([]);
  let searchFilter = {
    search: "",
    status: "active",
    project_ids: "",
  };

  const [val, setVal] = useState({ ...searchFilter });
  const [isDeactivated, setIsDeactivated] = useState(false);

  const [rpaginationData, setRPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 25,
    totalPages: 0,
    totalItems: 0,
  });

  const [obRpaginationData, setObPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 25,
    totalPages: 0,
    totalItems: 0,
  });

  const [cRpaginationData, setCPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 25,
    totalPages: 0,
    totalItems: 0,
  });

  const onResearcgerPageChange = (pageNum) => {
    getResearcher(pageNum);
  };
  const onObserverPageChange = (pageNum) => {
    getObserver(pageNum);
  };
  const onCollaboratorPageChange = (pageNum) => {
    getCollaborator(pageNum);
  };

  const handleFilterChange = (changedFilter) => {
    let newFilters = {
      ...val,
      ...changedFilter,
    };
    setVal(newFilters);
    const searchResearch = researcherList.filter((fList) =>
      fList.name.toLowerCase().includes(val?.search.toLowerCase())
    );
    setSearchInResearcherList(searchResearch);
    getResearcher(1, newFilters);

    const searchCollaborator = collaboratorList.filter((fList) =>
      fList.name.toLowerCase().includes(val?.search.toLowerCase())
    );
    setSearchInCollaboratorLsit(searchCollaborator);
    getCollaborator(1, newFilters);

    const searchObderver = observerList.filter((fList) =>
      fList.name.toLowerCase().includes(val?.search.toLowerCase())
    );
    setSearchInObserverLsit(searchObderver);
    getObserver(1, newFilters);
  };

  const handleClearFilterField = () => {
    const newFilters = {
      ...searchFilter,
    };
    setVal(newFilters);
    setselectFilterbyRole("");
    setProjectDropVal("");
    getResearcher(1, newFilters);
    getCollaborator(1, newFilters);
    getObserver(1, newFilters);
    setIsDeactivated(false);
  };

  const handleToggleChange = (event) => {
    const newStatus = event.target.checked ? "inactive" : "active";
    setIsDeactivated(event.target.checked);
    const newFilters = { ...val, status: newStatus };
    setVal(newFilters);
    getResearcher(1, newFilters);
    getCollaborator(1, newFilters);
    getObserver(1, newFilters);
  };

  const handleFilterProjects = (val) => {
    if (typeof handleFilterChange !== "undefined") {
      handleFilterChange({
        project_ids: val,
      });
    }
  };
  // Researcher Listing API

  const getResearcher = async (curPage, filters = val) => {
    try {
      const data = await axios.get(
        API_BASE_URL +
          `/super-admin/admin/researchers?page=${curPage}&search=${filters.search}&status=${filters.status}&project_ids=${filters?.project_ids}&workspace=${workspaceState}&is_basic=true`
      );
      setResearcherList(data?.data?.data);
      setSearchInResearcherList(data?.data?.data);
      setRPaginationData({
        currentPage: data.data.pagination?.currentPage,
        itemsPerPage: data.data.pagination?.itemsPerPage,
        totalPages: data.data.pagination?.totalPages,
        totalItems: data.data.pagination?.totalItems,
      });
    } catch (err) {
      console.log(err);
    }
  };
  // Collaborator Listing API

  const getCollaborator = async (curPage, filters = val) => {
    try {
      const data = await axios.get(
        API_BASE_URL +
          `/super-admin/admin/collaborators?page=${curPage}&search=${filters.search}&status=${filters.status}&project_ids=${filters?.project_ids}&workspace=${workspaceState}&is_basic=true`
      );
      setCollaboratorList(data?.data?.data);
      setSearchInCollaboratorLsit(data?.data?.data);
      setCPaginationData({
        currentPage: data.data.pagination?.currentPage,
        itemsPerPage: data.data.pagination?.itemsPerPage,
        totalPages: data.data.pagination?.totalPages,
        totalItems: data.data.pagination?.totalItems,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Observer
  const getObserver = async (curPage, filters = val) => {
    try {
      const data = await axios.get(
        API_BASE_URL +
          `/super-admin/admin/observers?page=${curPage}&search=${filters.search}&status=${filters.status}&project_ids=${filters?.project_ids}&workspace=${workspaceState}&is_basic=true`
      );
      setObserverList(data?.data?.data);
      setSearchInObserverLsit(data?.data?.data);
      setObPaginationData({
        currentPage: data.data.pagination?.currentPage,
        itemsPerPage: data.data.pagination?.itemsPerPage,
        totalPages: data.data.pagination?.totalPages,
        totalItems: data.data.pagination?.totalItems,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getProjectLists = async () => {
    try {
      const res = await axios.get(
        API_BASE_URL + `/researchers/projects?workspace_id=${workspaceState}`
      );
      if (res.status) {
        setProjects(res.data.data);
      }
    } catch (error) {
      console.error("Error fetching project lists", error);
    }
  };

  useEffect(() => {
    getResearcher(1);
    getCollaborator(1);
    getObserver(1);
    getProjectLists();
  }, []);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "200px",
        overflowY: "auto",
      },
    },
  };

  useEffect(() => {
    if (reloadList) {
      getResearcher(1);
      getCollaborator(1);
      getObserver(1);
      setReloadList(false);
    }
  }, [reloadList]);

  const totalDataLength =
    searchInResearcherList.length +
    searchInCollaboratorList.length +
    searchInOnserverList.length;

  const getActionBtns = () => {
    return (
      <>
        {!NewAdminSection && !EditAdminSection ? (
          <Box className="AppNavBtnBox">
            <AppBtnOutLine
              onClick={() => setInviteAdminPop(true)}
              buttonText={t("admin.inviteAdmin")}
            />
            <AppAddBtn
              onClick={() => setNewAdminSection(true)}
              buttonText={t("admin.addAdmin")}
            />
          </Box>
        ) : null}
      </>
    );
  };

  const getChildren = () => {
    return (
      <Box className="researchersMainBox pageContainer">
        {!NewAdminSection ? (
          <>
            <Box
              className="WSAdminSection"
              sx={{ display: EditAdminSection ? "none" : "block" }}
            >
              <InviteAdminPop
                inviteAdminPop={inviteAdminPop}
                setInviteAdminPop={setInviteAdminPop}
              />
              <Box className="FilterSection">
                <Typography variant="h4">{t("admin.filter")}</Typography>
                <Grid container spacing={2}>
                  <Grid item sm={4}>
                    <FormLabel className="label">{t("admin.search")}</FormLabel>
                    <SearchBar
                      handleFilterChange={handleFilterChange}
                      val={val}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <FormLabel className="label">
                      {t("admin.project")}
                    </FormLabel>
                    <FormControl sx={{ width: "100%" }} size="small">
                      <InputLabel id="demo-select-small-label">
                        {t("admin.filterByProject")}
                      </InputLabel>
                      <Select
                        labelId="project-select-label"
                        id="project-select"
                        value={projectDropVal}
                        label={t("admin.filterByProject")}
                        onChange={handleChange1}
                        MenuProps={MenuProps}
                      >
                        {projects.map((project) => (
                          <MenuItem key={project._id} value={project._id}>
                            {project.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={4}>
                    <FormLabel className="label">{t("admin.role")}</FormLabel>
                    <FormControl sx={{ width: "100%" }} size="small">
                      <InputLabel id="demo-select-small-label">
                        {t("admin.filterByRole")}
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={selectFilterbyRole}
                        label={t("admin.filterByRole")}
                        onChange={handleChange2}
                        name="researcher"
                      >
                        <MenuItem value={"researcher"}>
                          {t("admin.researcher")}
                        </MenuItem>
                        <MenuItem value={"collaborator"}>
                          {t("admin.collaborator")}
                        </MenuItem>
                        <MenuItem value={"observer"}>
                          {t("admin.observer")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <Typography
                        mr={3}
                        className="LinkText"
                        onClick={handleClearFilterField}
                      >
                        {t("commonTxt.clear")}
                      </Typography>
                      {/* <Typography mx={3} className="LinkText">
                      {t("commonTxt.apply")}
                    </Typography> */}
                      <Typography>
                        {selectFilterbyRole === ""
                          ? totalDataLength
                          : roleTotalDataLength}{" "}
                        {t("commonTxt.21Matches")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={6}>
                    <Box className="filterBottomInnerBox filterBottomInnerBox2">
                      <AntSwitch
                        checked={isDeactivated}
                        onChange={handleToggleChange}
                        inputProps={{ "aria-label": "ant design" }}
                      />
                      <Typography ml={2} className="switchText">
                        Only deactivated Participants
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {researcherList.length <= 0 &&
              collaboratorList <= 0 &&
              observerList <= 0 ? (
                <NoAdminBg setNewAdminSection={setNewAdminSection} />
              ) : (
                <AllAdminList
                  EditAdminSection={EditAdminSection}
                  setEditAdminSection={setEditAdminSection}
                  setUpdateWSAdmin={setUpdateWSAdmin}
                  setWorkspaceUserType={setWorkspaceUserType}
                  researcherList={researcherList}
                  collaboratorList={collaboratorList}
                  observerList={observerList}
                  searchInResearcherList={searchInResearcherList}
                  searchInCollaboratorList={searchInCollaboratorList}
                  searchInOnserverList={searchInOnserverList}
                  selectFilterbyRole={selectFilterbyRole}
                  setReloadList={setReloadList}
                  reloadList={reloadList}
                  updateWSAdmin={updateWSAdmin}
                  isDeactivated={isDeactivated}
                  rpaginationData={rpaginationData}
                  obRpaginationData={obRpaginationData}
                  cRpaginationData={cRpaginationData}
                  onResearcgerPageChange={onResearcgerPageChange}
                  onObserverPageChange={onObserverPageChange}
                  onCollaboratorPageChange={onCollaboratorPageChange}
                />
              )}
            </Box>

            {EditAdminSection ? (
              <EditAdmin
                EditAdminSection={EditAdminSection}
                setEditAdminSection={setEditAdminSection}
                updateWSAdmin={updateWSAdmin}
                setWorkspaceUserType={setWorkspaceUserType}
                workspaceUserType={workspaceUserType}
                setReloadList={setReloadList}
              />
            ) : null}
          </>
        ) : (
          <AddNewAdmin
            NewAdminSection={NewAdminSection}
            setNewAdminSection={setNewAdminSection}
            setReloadList={setReloadList}
          />
        )}
      </Box>
    );
  };

  return renderUserLayout(
    authState?.userType,
    getActionBtns(),
    getChildren(),
    "workadmin"
  );
}
