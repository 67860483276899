import React from "react";

import "./style.css";
import { AppBtn } from "../../AppTools/AppButton";

import { Box, Typography } from "@mui/material";

const BulkImport = ({ bulkImportData, setBulkImportData }) => {
  const handleBulkImport = () => {
    setBulkImportData(false);
  };

  return (
    <>
      <Box
        className="workspaceItemBox CompaniesBox"
        sx={{
          display: bulkImportData ? "flex" : "none",
        }}
      >
        <Box className="AllCSubBox allUFilters">
          <Box className="bulkImportHeadBox">
            <Box className="boxTitle">
              <Typography>Bulk Import</Typography>
            </Box>
            <Box className="successTitle">
              <Typography>Success</Typography>
            </Box>
          </Box>
          <Box className="bulkImportMidBox">
            <Box>
              <Typography>Date</Typography>
              <Typography>
                <span>Aug 2, 2:19 PM</span>
              </Typography>
            </Box>
            <Box>
              <Typography>LoginId</Typography>
              <Typography>
                <span>498</span>
              </Typography>
            </Box>
            <Box>
              <Typography>User</Typography>
              <Typography>
                <span>Richard Han</span>
              </Typography>
            </Box>
            <Box></Box>
          </Box>
          <Box className="bulkImportMidBox">
            <Box>
              <Typography>Geolocation</Typography>
              <Typography>
                <span>Barrackpur, Kolkata700124</span>
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className="sectionTitle">
          <Typography>Details</Typography>
        </Box>

        <Box className="AllCSubBox allUFilters">
          <Box className="bulkImportBottomBox">
            <Box>
              <Typography>Bulk Import File Validated:</Typography>
              <Typography>
                <span>https://dreammockupr.com/lsb5e.xlsx</span>
              </Typography>
            </Box>
            <Box>
              <Typography>
                Update <span>15 panelists</span>
              </Typography>
            </Box>
            <Box>
              <Typography>
                Total panelists <span>15</span>
              </Typography>
            </Box>
            <Box>
              <Typography>
                Completed <span>Aug 2, 2:19 PM </span>
              </Typography>
            </Box>
            <Box>
              <Typography>
                Duration <span>0 sec</span>
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className="workPupBtnBox">
          <AppBtn buttonText="Close" onClick={() => handleBulkImport()} />
        </Box>
      </Box>
    </>
  );
};

export default BulkImport;
