import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import "./style.css";
//images

import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png"
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png"
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png"
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png"
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png"
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png"
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png"
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png"
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png"
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png"
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png"
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png"

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
//DATA
import { fieldItem } from "../../../Assets/Data"
import ReferenceDateUsingValue from "../../AppTools/DatePicker";
import { uploadFile } from '../../../Services/adminActions';
import { useAppSelector } from '../../../lib/store/store';
import { toast } from 'react-toastify';
import { TOAST_CONFIG } from '../../../lib/constants';

export default function AddDateProfileField({ filterDropVal, setFilterDropVal, setCustomField, customField ,isShowUser,
  setIsShowUser,
  handleParticipantsChange,
  allParticipantsList,}) {

  const email2 = "Provide Instructions here"
  const [emailval, setEmailval] = useState()
  const [emailval2, setEmailval2] = useState()
  const [emailval3, setEmailval3] = useState()
  const [emailval4, setEmailval4] = useState()
  const [signatureTextLaOut, setSignatureTextLaOut] = useState("start")

  const [signatureTextItalic, setSignatureTextItalic] = useState(false)
  const [signatureTextBold, setSignatureTextBold] = useState(false)
  const [signatureTextUnderLine, setSignatureTextUnderLine] = useState()

  const [signatureTextItalic2, setSignatureTextItalic2] = useState(false)
  const [signatureTextBold2, setSignatureTextBold2] = useState(false)
  const [signatureTextUnderLine2, setSignatureTextUnderLine2] = useState()

  const [signatureTextItalic3, setSignatureTextItalic3] = useState(false)
  const [signatureTextBold3, setSignatureTextBold3] = useState(false)
  const [signatureTextUnderLine3, setSignatureTextUnderLine3] = useState()
  const authState = useAppSelector((state) => state.auth);
  const [signatureTextItalic4, setSignatureTextItalic4] = useState(false)
  const [signatureTextBold4, setSignatureTextBold4] = useState(false)
  const [signatureTextUnderLine4, setSignatureTextUnderLine4] = useState()

  const [groupDrop, setGroupDrop] = useState(true);
  const [groupDrop2, setGroupDrop2] = useState(false);
  const [groupDrop3, setGroupDrop3] = useState(false);
  const [redio, setRedio] = useState(0);
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState(null);
  
  useEffect(()=>{
    setFilterDropVal(4);
  },[filterDropVal])

  const handelTextlineChekc4 = (p, input) => {
    console.log("p",p)
    console.log("input",input)
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine4 === "underline") {
          setSignatureTextUnderLine4(null)
          return
        } else {
          setSignatureTextUnderLine4("underline")
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine4 === "line-through") {
          setSignatureTextUnderLine4(null)
          return
        } else {
          setSignatureTextUnderLine4("line-through")
        }
      }
    }
  }

  const handelChange = (e, i) => {
    const { name, value } = e.target;
    if (i === 1) {
      setEmailval((prev) => ({
        ...prev,
        [name]: value
      }));
    } else if (i === 2) {
      setEmailval2((prev) => ({
        ...prev,
        [name]: value
      }));
    } else if (i === 3) {
      setEmailval3((prev) => ({
        ...prev,
        [name]: value
      }));
    } else if (i === 4) {
      setEmailval4((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  };




  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setFileName(file.name);
      };
      reader.readAsDataURL(file);
      uploadImage(file)
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setFileName(file.name);
      };
      reader.readAsDataURL(file);
      uploadImage(file)
    }
  };

  const uploadImage = async (file) => {
    if (!file)
      return false;
    const formData = new FormData()
    formData.append('file', file)
    try {
      let imageUploadRes = await uploadFile(formData, authState.authToken);
      if (imageUploadRes.success) {
        setCustomField({ ...customField, file: imageUploadRes?.data?.upload_id })
        // toast.success(imageUploadRes.message, TOAST_CONFIG);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    } finally {

    }
  }

  const setDateTimeVal = (value) => {
    let year = value.$y;
    let month = (value.$M < 9) ? '0' + (value.$M + 1) : value.$M + 1;
    let day = (value.$D < 10) ? '0' + value.$D : value.$D;

    let hour = (value.$H < 10) ? '0' + value.$H : value.$H;
    let min = (value.$m < 10) ? '0' + value.$m : value.$m;
    let sec = (value.$s < 10) ? '0' + value.$s : value.$s;
    //year + '-' + month + '-' + day : hour + ':' + min + ':' + sec
    return year + '-' + month + '-' + day
  }

  return (
    <>

      {/* Date section */}
      <Box sx={{ display: fieldItem[filterDropVal]?.field === "Date" ? "flex" : "none" }} mt={5} className='fieldBox'>
        <Typography className='eTemPopLabel'> Instructions</Typography>
        <Box className="emailInputBox">
          <Box className="emailInputBoxNav">
            <Box onClick={() => setSignatureTextItalic4(!signatureTextItalic4)} className="emailImgBox">
              <img src={IIcon} />
            </Box>
            <Box onClick={() => setSignatureTextBold4(!signatureTextBold4)} className="emailImgBox">
              <img src={BIcon} />
            </Box>
            <Box onClick={() => handelTextlineChekc4("underline", "signature")} className="emailImgBox">
              <img src={UnderLineU} />
            </Box>
            <Box onClick={() => handelTextlineChekc4("line-through", "signature")} className="emailImgBox">
              <img src={MLineU} />
            </Box>
            <Box className="emailImgBox">
              <img src={AtachMentIcon} />
            </Box>
            <Box className="emailImgBox">
              <img src={Qbox} />
            </Box>
            <Box onClick={() => setSignatureTextLaOut("start")} className="emailImgBox">
              <img src={Line1} />
            </Box>
            <Box onClick={() => setSignatureTextLaOut("center")} className="emailImgBox">
              <img src={Line2} />
            </Box>
            <Box onClick={() => setSignatureTextLaOut("end")} className="emailImgBox">
              <img src={Line3} />
            </Box>
            <Box className="emailImgBox">
              <img src={Line4} />
            </Box>
            <Box className="emailImgBox">
              <img src={emoji} />
            </Box>

          </Box>
          <textarea
            style={{
              textAlign: signatureTextLaOut,
              fontStyle: signatureTextItalic4 ? "italic" : "normal",
              fontWeight: signatureTextBold4 ? "bold" : "normal",
              textDecoration: signatureTextUnderLine4
            }}
            className='emailInput'
            name="instruction"
            value={customField?.instruction}
            onChange={(e) => {
              handelChange(e, 1);
              setCustomField({
                ...customField, instruction: e.target.value
              })
            }}
          />
        </Box>

        <Box className='mediaInputbox upload-area' onClick={() => {
          document.getElementById("fileInput5").click();
        }}
          onDragOver={handleDragOver}
          onDrop={handleDrop}>
          {image? <img src={image} />: <img src={uploadIcon} />}
          <Typography>{fileName
                      ? fileName
                      : "Click or drag media files to this area to upload"}</Typography>
          <input
            id="fileInput5"
            style={{ display: "none" }}
            type="file"
            onChange={handleFileInputChange}
          />
        </Box>

        <p style={{ marginBottom: "24px" }} className='allPartRedioLabel'>Basic Settings</p>
        <Box className='checkWlabel'>
          <input className="custom-checkbox" type="checkBox" 
            checked={customField?.date_range?.enforce_range_check}
            onClick={(e) => setCustomField({
              ...customField, date_range: {
                ...customField.date_range,
                enforce_range_check: e.target.checked
              }
            })} />
          <Typography>Enforce Range</Typography>
        </Box>
        <Box className='enforceInputBox internalInputBox startEndDate'>
          <Box>
            <Typography className="workspaceLabel">Start Date</Typography>
            <Box className="pickerBox">
              <ReferenceDateUsingValue onChange={(e) => setCustomField({
                ...customField, date_range: {
                  ...customField.date_range, start: setDateTimeVal(e)
                }
              })} />
            </Box>
          </Box>

          <Box>
            <Typography className="workspaceLabel">End Date</Typography>
            <Box className="pickerBox">
              <ReferenceDateUsingValue onChange={(e) => setCustomField({
                ...customField,
                date_range: {
                  ...customField.date_range,
                  end: setDateTimeVal(e)
                }
              })} />
            </Box>
          </Box>
        </Box>
        <Box className='checkWlabel'>
          <input className="custom-checkbox" type="checkBox" 
            checked={customField?.customize_prompt_check}
            onClick={(el) => setCustomField({ ...customField, customize_prompt_check: el.target.checked })} />
          <Typography>Customize prompt</Typography>
        </Box>
        <Box className='internalInputBox'>
          <input type="text"
            className='phoneInput customizePromptInput'
            placeholder='This text will appear just above the response text box'
            value={customField?.prompt}
            onChange={(el) => setCustomField({ ...customField, prompt: el.target.value })}
          />
        </Box>
        <Box className='checkWlabel'>
          <input className="custom-checkbox" type="checkBox" 
            checked={customField?.response_required_check}
            onClick={(el) => setCustomField({ ...customField, response_required_check: el.target.checked })} />
          <Typography>Response Required</Typography>
        </Box>


        <p style={{ marginTop: "56px", marginBottom: "24px" }} className='allPartRedioLabel'>Advance Settings</p>
        <Box className="checkWlabel">
                  <input
                    className="custom-checkbox"
                    type="checkBox"
                    checked={customField?.limit_participant_check}
                    onClick={(el) => {
                      setCustomField({
                        ...customField,
                        limit_participant_check: el.target.checked,
                      });
                      setIsShowUser(true);
                      if (el.target.checked === false) {
                        setCustomField((prevGroup) => ({
                          ...prevGroup,
                          limit_participant: [],
                        }));
                        setIsShowUser(false);
                      }
                    }}
                  />
                  <Typography>Limit who can complete this field</Typography>
                </Box>

                <Box className="searchGroupSection">
                  <Box className="internalInputBox">
                    {isShowUser && (
                      <FormControl sx={{ width: "100%" }} size="small">
                        <Select
                          labelId="demo-select-small-label11"
                          id="demo-select-small11"
                          value={customField.limit_participant}
                          onChange={handleParticipantsChange}
                          name="limit_participant"
                          multiple
                          displayEmpty
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return <p>Select Participants</p>;
                            }
                            return selected
                              .map((value) => {
                                const user = allParticipantsList.find(
                                  (user) => user._id === value
                                );
                                return user ? user.name : value;
                              })
                              .join(", ");
                          }}
                        >
                          {allParticipantsList.map((user, index) => (
                            <MenuItem value={user._id} key={index}>
                              {user.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {/* <Box
                      className="inputAppDrop"
                      onClick={() =>
                        setSearchParticipantDrop(!searchParticipantDrop)
                      }
                    >
                      <Typography>
                        {searchParticipantDropVal || "Search Participant"}
                      </Typography>
                      <img
                        style={{
                          rotate: searchParticipantDrop ? "180deg" : "0deg",
                        }}
                        src={dropArrow}
                      />
                      <Box
                        sx={{ height: searchParticipantDrop ? "auto" : "0px" }}
                        className="statusPop"
                      >
                        <Box
                          onClick={() =>
                            setSearchParticipantDropVal("Participant A")
                          }
                          className="statusPopItem"
                        >
                          <Typography>Participant 1</Typography>
                        </Box>
                        <Box
                          onClick={() =>
                            setSearchParticipantDropVal("Participant B")
                          }
                          className="statusPopItem"
                        >
                          <Typography>Participant 2</Typography>
                        </Box>
                      </Box>
                    </Box> */}
                  </Box>
                </Box>
        <Box className='checkWlabel'>
          <input className="custom-checkbox" type="checkBox"
            checked={customField?.content_pii}
            onClick={(el) => setCustomField({ ...customField, content_pii: el.target.checked })} />
          <Typography>May contain personal identifiable information (PII)</Typography>
        </Box>
      </Box>


    </>
  )
}
