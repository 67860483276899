import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material'
import { useSearchParams } from "react-router-dom";

//images
import whitedropIcon from "../../../Assets/Images/Wchevron-down.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";
import exportIcon from "../../../Assets/Images/ExpoGroup.png";
import dropIcon from "../../../Assets/Images/chevron-downp.png"
import printIcon from "../../../Assets/Images/printIcon.png"
import arrow from "../../../Assets/Images/arrow.png";


//component
import { useTranslation } from 'react-i18next';
import { AppBtn, AppBtnOutLine } from '../../AppTools/AppButton';

//data
import { participant5Data, reportDropVal } from "../../../Assets/Data"


export default function ProjectConversationReport({ dataReportTabe, setDataReportTabe, dropVal, projectSettingDrop, setProjectSettingDrop, projectSettingDropVal, setProjectSettingDropVal }) {

  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [rangeDrop4, setRangeDrop4] = useState(false)
  const [rangeDropVal4, setRangeDropVal4] = useState("All Time")

  const [contributionDrop4, setContributionDrop4] = useState(false)
  const [contributionDropVal4, setContributionDropVal4] = useState("All Types")
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    let selTab = searchParams.get("t");
    let selTab2 = searchParams.get("dr");
    if (selTab && selTab2 != null) {
      var query_string = selTab;
      query_string = query_string.replaceAll('_', ' ');
      query_string = query_string.replace(/(^\w|\s\w)/g, firstCharOfWord => firstCharOfWord.toUpperCase());
      console.log(query_string);
      if (selTab === "size_churn") {
        var query_string = "Size & Churn";
        setProjectSettingDropVal(query_string);
        setDataReportTabe(parseInt(selTab2));
      }
      else {
        setProjectSettingDropVal(query_string);
        setDataReportTabe(parseInt(selTab2));
      }
    }
    else if (selTab == null) {
      setDataReportTabe(parseInt(selTab2));
    }
  }, []);

  return (
    <>
      {/* -----------------------------------------------Conversation Report-------------------------------------- */}
      <Box sx={{ display: projectSettingDropVal === "Conversation" ? "block" : "none" }} className="reportContainer integrationsContainer compositionSection">
        <Box className="projectBtnBox">
          <Box className="inteBtndropBox">
            <Box className="projectManagementNavBtn" onClick={() => setProjectSettingDrop(!projectSettingDrop)}>
              <Typography className='DropBtnText'>{projectSettingDropVal || "Size & Churn"}</Typography>
              <img className='dropIcon' src={whitedropIcon} style={{ rotate: projectSettingDrop ? "180deg" : "0deg", transition: "all 0.5s", marginLeft: "10px" }} />
              <Box className="workspaceItemNavPop projectItemNavPop" sx={{ display: projectSettingDrop ? "flex" : "none" }}>
                {
                  reportDropVal?.map((el, i) => (
                    <Box key={i}
                      onClick={() => {
                        setSearchParams({ dr: dataReportTabe, t: el.qstring });
                        setProjectSettingDropVal(el.title);
                      }}
                    >
                      <Typography>{el.title}</Typography>
                    </Box>
                  ))
                }
              </Box>
            </Box>
          </Box>
        </Box>
        <Typography className='pageHeaderText'>{t('projectConversationReport.headerTxt')}</Typography>
        <Box className="participationReportDropSection">
          <Box className="inputBoxWlabel">
            <Typography>{t('projectParticipantReport.dateRangeDrop')}</Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setRangeDrop4(!rangeDrop4)}
            >
              <Typography>{rangeDropVal4 || t("projectParticipantReport.dateRangeDropPlaceholder")}</Typography>
              <img
                style={{ rotate: rangeDrop4 ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: rangeDrop4 ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setRangeDropVal4("All Time")}
                  className="statusPopItem"
                >
                  <Typography>{t("projectParticipantReport.dateRangeDropVal1")}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="inputBoxWlabel">
            <Typography>{t('projectParticipantReport.contributionTypeDrop')}</Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setContributionDrop4(!contributionDrop4)}
            >
              <Typography>{contributionDropVal4 || t("projectParticipantReport.contributionTypeDropPlaceholder")}</Typography>
              <img
                style={{ rotate: contributionDrop4 ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: contributionDrop4 ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box onClick={() => setContributionDropVal4("All Types")} className="statusPopItem">
                  <Typography>{t("projectParticipantReport.contributionTypeDropVal1")}</Typography>
                </Box>
                <Box onClick={() => setContributionDropVal4("Subscribed")} className="statusPopItem">
                  <Typography>{t("projectParticipantReport.contributionTypeDropVal2")}</Typography>
                </Box>
                <Box onClick={() => setContributionDropVal4("Unsubscribed")} className="statusPopItem">
                  <Typography>{t("projectParticipantReport.contributionTypeDropVal3")}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>



        <Box mt={5} className="redioGroupSection">
          <Typography className='emailNotificationHeaderText'>{t('projectParticipantReport.subHeaderTxt1')}</Typography>

          <Box className="projectRadioBoxSection">
            <Box className="ProjectRadioInput">
              <input
                className="emailSettingInput emailSettingRadio"
                type='radio'
                name='projectState'
                defaultChecked
              />
              <Typography className="emailSettingLabel">{t('projectParticipantReport.inclusionComment')}</Typography>
            </Box>
            <Box className="ProjectRadioInput">
              <input
                className="emailSettingInput emailSettingRadio"
                type='radio'
                name='projectState'
              />
              <Typography className="emailSettingLabel">{t('projectConversationReport.inclusionReply')}</Typography>
            </Box>
          </Box>


        </Box>

        <Box className="workPupBtnBox">
          <AppBtnOutLine buttonText={t('commonTxt.resetBtn')} />
          <AppBtn buttonText={t('commonTxt.viewReportBtn')} />
        </Box>
      </Box>
      <Box sx={{ display: projectSettingDropVal === "Conversation" ? "block" : "none" }} mt={5} className="integrationsContainer">

        <Box mt={3} className="tabelNav">
          <Box className="dropWheader">
            <Typography className="pageTitle">{t('projectParticipantReport.headerTxt2')}</Typography>
            <Box className="ndrop">
              <Typography>5</Typography>
              <img src={dropIcon} />
            </Box>
          </Box>
          <Box className="repPrintBtnBox">
            <Box className="exporOpenBtn">
              <img src={printIcon} />
              <Typography className="verifyLink">{t('commonTxt.printLink')}</Typography>
            </Box>
            <Box className="exporOpenBtn">
              <img src={exportIcon} />
              <Typography className="verifyLink">{t('commonTxt.exportLink')}</Typography>
            </Box>
          </Box>
        </Box>

        <Box mt={5} mb={2} className="tabelNav">
          <Typography className="emailNotificationHeaderText">{t('projectConversationReport.tblConversationTopic')}</Typography>
        </Box>

        <Box mb={6} className="workListBox">
          <Box className="haderRow" minWidth={"1135px"}>
            <Box minWidth={"290px"} className="Tabelsel navFsel templatehead">
              <Typography>{t('projectConversationReport.tblTopic')} (265)</Typography>
            </Box>
            <Box className="Tabelsel statusSel" minWidth={"325px"}>
              <Typography>{t('projectConversationReport.tblCategory')}</Typography>
            </Box>
            <Box className="Tabelsel statusSel" minWidth={"230px"}>
              <Typography>{t('projectConversationReport.tblProject')}</Typography>
            </Box>
            <Box
              className="Tabelsel statusSel templateheadl"
              minWidth={"230px"}
            >
              <Typography>{t('projectConversationReport.tblCreator')}</Typography>
            </Box>
          </Box>
          {participant5Data &&
            participant5Data?.map((el, index) => (
              <Box key={index} className="TabelRow" minWidth={"1135px"}>
                <Box minWidth={"290px"} className="Tabelsel navFsel navtable participantCheckboxColor">
                  <Typography>Your trekking must haves</Typography>
                </Box>
                <Box
                  className="Tabelsel statusSel Tabelcentersel invitationStatusIcon"
                  minWidth={"325px"}
                >
                  <Typography>Outdoor Tips</Typography>
                </Box>
                <Box
                  className="Tabelsel statusSel Tabelcentersel"
                  minWidth={"230px"}
                >
                  <Typography>Outdoor Journey</Typography>
                </Box>
                <Box
                  className="Tabelsel statusSel Tabelcentersel"
                  minWidth={"230px"}
                >
                  <Typography>Ryan Reynolds</Typography>
                </Box>
              </Box>
            ))}
        </Box>

        <Box className="pagenation">
          <Box className="tabelBtn">
            <img src={arrow} />
            <Typography>{t('commonTxt.previouspage')}</Typography>
          </Box>

          <Box className="pageNumberBox">
            <Box
              // onClick={() => setCurrentPage(0)}
              className={
                currentPage === 0
                  ? "pageNumber pageNumberActive"
                  : "pageNumber"
              }
            >
              <p>1</p>
            </Box>
            <Box
              // onClick={() => setCurrentPage(1)}
              className={
                currentPage === 1
                  ? "pageNumber pageNumberActive"
                  : "pageNumber"
              }
            >
              <p>2</p>
            </Box>
            <Box
              // onClick={() => setCurrentPage(2)}
              className={
                currentPage === 2
                  ? "pageNumber pageNumberActive"
                  : "pageNumber"
              }
            >
              <p>3</p>
            </Box>
            <Box
              // onClick={() => setCurrentPage(3)}
              className={
                currentPage === 3
                  ? "pageNumber pageNumberActive"
                  : "pageNumber"
              }
            >
              <p>4</p>
            </Box>
            <Box
              // onClick={() => setCurrentPage(4)}
              className={
                currentPage === 4
                  ? "pageNumber pageNumberActive"
                  : "pageNumber"
              }
            >
              <p>5</p>
            </Box>
          </Box>

          <Box className="tabelBtn">
            <Typography>{t('commonTxt.nextPage')}</Typography>
            <img style={{ rotate: "180deg" }} src={arrow} />
          </Box>
        </Box>

        {/* Responses Table */}
        <Box mt={5} mb={2} className="tabelNav">
          <Box className="dropWheader">
            <Typography className="emailNotificationHeaderText">{t('projectConversationReport.subHeaderTxt')}</Typography>
          </Box>
          <Box className="repPrintBtnBox">
            <Box className="exporOpenBtn">
              <img src={printIcon} />
              <Typography className="verifyLink">{t('commonTxt.printLink')}</Typography>
            </Box>
            <Box className="exporOpenBtn">
              <img src={exportIcon} />
              <Typography className="verifyLink">{t('commonTxt.exportLink')}</Typography>
            </Box>
          </Box>
        </Box>

        <Box mb={6} className="workListBox">
          <Box className="haderRow" minWidth={"1120px"}>
            <Box minWidth={"25%"} className="Tabelsel navFsel TabelStatesel">
              <Typography>{t('projectConversationReport.tblUserName')}</Typography>
            </Box>
            <Box minWidth={"10%"} className="Tabelsel">
              <Typography >{t('projectConversationReport.tblResponse')}</Typography>
            </Box>
            <Box minWidth={"24%"} className="Tabelsel">
              <Typography >{t('projectConversationReport.tblTopic2')}</Typography>
            </Box>
            <Box minWidth={"18%"} className="Tabelsel">
              <Typography >{t('projectConversationReport.tblUpdated')}</Typography>
            </Box>
            <Box minWidth={"18%"} className="Tabelsel">
              <Typography >{t('projectConversationReport.tblReview')}</Typography>
            </Box>
          </Box>
          {participant5Data &&
            participant5Data?.map((el, index) => (
              <Box key={index} className="TabelRow" minWidth={"1120px"}>
                <Box minWidth={"25%"} className="Tabelsel navFsel">
                  <Box className="tabelAvatar"> <img src={el.img} /> </Box>
                  <Typography sx={{ cursor: "pointer", width: "95%" }}> {el.name}</Typography>
                </Box>
                <Box minWidth={"10%"} className="Tabelsel invitationStatusIcon">
                  <Typography className="participantsTableText">
                    Comment
                  </Typography>
                </Box>
                <Box
                  minWidth={"24%"}
                  className="Tabelsel TabelselItemCenter"
                >
                  <Typography className="participantsTableText">
                    Your trekking must haves
                  </Typography>
                </Box>

                <Box
                  minWidth={"18%"}
                  className="Tabelsel TabelselItemCenter"
                >
                  <Typography className="participantsTableText">
                    Jul 08 @ 3:06 PM
                  </Typography>
                </Box>

                <Box
                  minWidth={"18%"}
                  className="Tabelsel TabelselItemCenter"
                >
                  <Typography className="participantsTableText verifyLinkCodeBook">
                    View
                  </Typography>
                </Box>
              </Box>
            ))}
        </Box>

        <Box className="pagenation">
          <Box className="tabelBtn">
            <img src={arrow} />
            <Typography>{t('commonTxt.previouspage')}</Typography>
          </Box>

          <Box className="pageNumberBox">
            <Box
              className={
                currentPage === 0
                  ? "pageNumber pageNumberActive"
                  : "pageNumber"
              }
            >
              <p>1</p>
            </Box>
            <Box
              className={
                currentPage === 1
                  ? "pageNumber pageNumberActive"
                  : "pageNumber"
              }
            >
              <p>2</p>
            </Box>
            <Box
              className={
                currentPage === 2
                  ? "pageNumber pageNumberActive"
                  : "pageNumber"
              }
            >
              <p>3</p>
            </Box>
            <Box
              className={
                currentPage === 3
                  ? "pageNumber pageNumberActive"
                  : "pageNumber"
              }
            >
              <p>4</p>
            </Box>
            <Box
              className={
                currentPage === 4
                  ? "pageNumber pageNumberActive"
                  : "pageNumber"
              }
            >
              <p>5</p>
            </Box>
          </Box>

          <Box className="tabelBtn">
            <Typography>{t('commonTxt.nextPage')}</Typography>
            <img style={{ rotate: "180deg" }} src={arrow} />
          </Box>
        </Box>
        {/* Responses Table */}

      </Box>

      {/* -----------------------------------------------Conversation Report-------------------------------------- */}

    </>
  )
}
