import { Bounce } from "react-toastify";

export const PER_PAGE_LIMIT = 10;
export const TOAST_CONFIG = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
}

//export const API_BASE_URL='http://localhost:8000/api';
//export const STORAGE_BASE_PATH='http://localhost:8000';
export const API_BASE_URL='https://teradev.neuronsit.in/api';
export const STORAGE_BASE_PATH='https://teradev.neuronsit.in/';