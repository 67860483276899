import React, { useState } from "react";
import { Box, Typography } from "@mui/material";

//images
import { sideHaumIcon } from "../../../Assets/Data";

//components
import SideMenu from "../../../Components/Observer/SideMenu";


export default function Activities({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
}) {

  return (
    <>
      <Box className="ResearchersContainer ">
        {/* side menu */}
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon">
              {sideHaumIcon}
            </Box>
            
          </Box>

          {/* Page container */}
            <Box className="researchersMainBox pageContainer">
                <h1>Dashboard</h1>
            </Box>
        </Box>
      </Box>
    </>
  );
}
