import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import "./style.css"
//images
import iIcon2 from "../../../Assets/Images/IIcon.png";
import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png";
import backArrow from "../../../Assets/Images/noun-back.png";
import redTrash from "../../../Assets/Images/redTrash.png";

import iIcon3 from "../../../Assets/Images/IconColumnLabel.png";


export default function AddMatrixTaskPop({ setAllDropVal4, addMatrixTaskPop, setaddMatrixTaskPop, handelAddTaskPopBack }) {

  const email1 = "Type the question/instructions to be visible"

  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();
  const [image, setImage] = useState(null);
  const [checkTaskSettingCount, setCheckTaskSettingCount] = useState(true);
  const [chooseCount, setChooseCount] = useState(1);
  const [redio, setRedio] = useState(0);
  const [chooseCount2, setChooseCount2] = useState(1);
  const [checkTaskSettingPrompt, setCheckTaskSettingPrompt] = useState(true);

  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
        setaddMatrixTaskPop(false);
    }
  };

  const handelCancelPop = () => {
    setaddMatrixTaskPop(false);
    setAllDropVal4("Add Tasks");
  }

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const ChooseSection = ({ count }) => {
    return (
      <>
        <p style={{ marginTop: "20px", marginBottom: "10px" }}>Row {count * 1 + 1} Label</p>
        <Box className="choiceInputBox">
          <input className="phoneInput" placeholder="Enter row label" />
          <img className="redTrash" src={redTrash} onClick={handelDeleteAttribute} />
        </Box>
      </>
    )
  }

  const ChooseSection2 = ({ count2 }) => {
    return (
      <>
          <p style={{ marginTop: "20px", marginBottom: "10px" }}>Column {count2 * 1 + 1} Label</p>
            <Box className="choiceInputBox">
            <input className="phoneInput" placeholder="Enter row label" />
            <img className="redTrash" src={redTrash} onClick={handelDeleteAttribute2} />
            </Box>
            <Box className="checkWlabel matrixCloumnLabel">
                <input className="custom-checkbox" type="checkBox" onClick={() => setCheckTaskSettingCount(!checkTaskSettingCount)} defaultChecked />
                <Typography className="assignWeightIcon">Assign Weights</Typography> <img src={iIcon3} />
            </Box>
            <Box sx={{ display: checkTaskSettingCount ? "flex" : "none" }} ml={4} className="enforceInputBox">
                <input defaultValue="05" className="phoneInput" />
                <p>per selection</p>
            </Box>
      </>
    )
  }


  const renderComponents = (value) => {
    let components = [];
    for (let i = 0; i < value; i++) {
      components.push(<ChooseSection count={i} key={i} />);
    }
    return components;
  };


  const handelDeleteAttribute = () => {
    setChooseCount(chooseCount - 1)
  }

  const handelDeleteAttribute2 = () => {
    setChooseCount2(chooseCount2 - 1)
  }

  const handelAddAttribute = () => {
    setChooseCount(chooseCount + 1)
  }

  const renderColumnComponents = (value2) => {
    let components2 = [];
    for (let j = 0; j < value2; j++) {
      components2.push(<ChooseSection2 count2={j} key={j} />);
    }
    return components2;
  };

  const handelAddColumnAttribute = () => {
    setChooseCount2(chooseCount2 + 1)
  }

  useEffect(() => {
    if (addMatrixTaskPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [addMatrixTaskPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: addMatrixTaskPop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople "
      >
        <Box
          sx={{ display: addMatrixTaskPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Box onClick={handelAddTaskPopBack} className="openBtn taskBackBtn">
            <img src={backArrow} />
            <Typography className="neonBackText">Back</Typography>
            </Box>

          <Typography className="taskPopMainLabel">Matrix Task</Typography>
          <Box className="iBox">
              <img src={iIcon2} />
              <Typography>
                Define a matrix of rows and columns for rapid evaluation of many items at once.
              </Typography>
            </Box>
          <Box className="basicBoxItem">
            <Typography className='eTemPopLabel '>Task Name</Typography>
            <Box className="pickerBox cardTitleLabel">
              <input className='eTemInput' placeholder='Enter task name' />
            </Box>
          </Box>

          <Typography className='eTemPopLabel '>Question or Instructions</Typography>
          <Box className="emailInputBox textTaskInstruction">
            <Box className="emailInputBoxNav">
              <Box
                onClick={() => setMessageTextItalic(!messageTextItalic)}
                className="emailImgBox"
              >
                <img src={IIcon} />
              </Box>
              <Box
                onClick={() => setMessageTextBold(!messageTextBold)}
                className="emailImgBox"
              >
                <img src={BIcon} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("underline", "message")}
                className="emailImgBox"
              >
                <img src={UnderLineU} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("line-through", "message")}
                className="emailImgBox"
              >
                <img src={MLineU} />
              </Box>
              <Box className="emailImgBox">
                <img src={AtachMentIcon} />
              </Box>
              <Box className="emailImgBox">
                <img src={Qbox} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("start")}
                className="emailImgBox"
              >
                <img src={Line1} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("center")}
                className="emailImgBox"
              >
                <img src={Line2} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("end")}
                className="emailImgBox"
              >
                <img src={Line3} />
              </Box>
              <Box className="emailImgBox">
                <img src={Line4} />
              </Box>
              <Box className="emailImgBox">
                <img src={emoji} />
              </Box>
            </Box>
            <textarea
              style={{
                textAlign: messageTextLaOut,
                fontStyle: messageTextItalic ? "italic" : "normal",
                fontWeight: messageTextBold ? "bold" : "normal",
                textDecoration: messageTextUnderLine,
                // textDecoration: messageTextMLine ? "line-through" : null
              }}
              className="emailInput"
              placeholder="Enter a discription or instructions for the card"
              name="emailMessage"
              value={emailval?.emailMessage || email1}
              onChange={handelChange}
            />
          </Box>

          <Box className="basicBoxItem">
            <Box className='mediaInputbox upload-area taskUoloadImageBox' onClick={() => {
                document.getElementById("fileInput").click();
            }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}>
                <img src={uploadIcon} />
                <Typography>Click or drag an image to this area to upload</Typography>
                <input
                id="fileInput"
                style={{ display: "none" }}
                type="file"
                onChange={handleFileInputChange}
                />
            </Box>
          </Box>

        {/* Poll */}
        
        <Typography className="allPartRedioLabel">Row Settings</Typography>
        <Typography className="bsNText">Define a criteria or statement (for e.g. “Battery Life”)</Typography>
          {renderComponents(chooseCount)}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "24px 0",
            }}
          >
            <Typography className="ppundText" onClick={handelAddAttribute}>Add Row</Typography>
            <Typography className="ppundText">Paste Bulk Rows</Typography>
          </Box>

          <Typography className="allPartRedioLabel">Column Settings</Typography>
            <Typography className="bsNText">Define a rating or category (for e.g. “Very Important”)</Typography>
            {renderColumnComponents(chooseCount2)}
            <Box
                sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                margin: "24px 0",
                }}
            >
                <Typography className="ppundText" onClick={handelAddColumnAttribute}>Add Column</Typography>
                <Typography className="ppundText">Paste Bulk Rows</Typography>
            </Box>

          <Typography className='textTaskSetting '>Task Settings</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>Allow multiple columns to be selected for each row</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>Allow each columns to be selected only once across all rows</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>Randomize row order</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>Randomize column order</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>Limit Responses</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>Response Commentary</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>Response Required</Typography>
          </Box>

          <Typography className='textTaskSetting '>Comment Settings</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>Allow a comment per row</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" onClick={() => setCheckTaskSettingPrompt(!checkTaskSettingPrompt)} defaultChecked />
            <Typography>Customize prompt</Typography>
          </Box>
          <Box sx={{ display: checkTaskSettingPrompt ? "flex" : "none" }} className="wsCpInputBOx">
            <input
              type="text"
              className="phoneInput customizePromptInput"
              placeholder="Enter customize prompt"
            />
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" onClick={() => setCheckTaskSettingCount(!checkTaskSettingCount)} defaultChecked />
            <Typography>Enforce word count</Typography>
          </Box>
          <Box sx={{ display: checkTaskSettingCount ? "flex" : "none" }} ml={4} className="enforceInputBox">
            <input defaultValue="05" className="phoneInput" />
            <p>to</p>
            <input defaultValue="200" className="phoneInput" />
            <p>Tags</p>
          </Box>

          <Typography className='textTaskSetting '>Advance Settings</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>Limit who can complete this task</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>Customize points awarded for task completetion </Typography>
          </Box>
        
        {/* Poll */}
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={handelCancelPop} />
            <AppBtnOutLine buttonText="Save & Preview" onClick={handelAddTaskPopBack} />
            <AppBtn buttonText="Save Task" />
          </Box>



        </Box>
      </Box>
    </>
  );
}
