import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { TOAST_CONFIG } from "../../../lib/constants";
import { toast } from "react-toastify";
import { deleteActivities } from "../../../Services/adminActions";
import { useAppSelector } from "../../../lib/store/store";
//images

export default function LeaveMeetingPop({ leaveMeeting, setLeaveMeeting, leaveMeetingFn }) {

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const [loading, setLoading] = useState(false);
  let authState = useAppSelector(state => state.auth);

  const handelPopClose = () => {
    setLeaveMeeting(false);
  }


  const handleLeave = async () => {
    leaveMeetingFn(true);
  };

  useEffect(() => {
    if (leaveMeeting) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [leaveMeeting]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: leaveMeeting ? "flex" : "none" }}
        className="companyPop AddCardPopPeople"
      >
        <Box
          sx={{ display: leaveMeeting ? "flex" : "none" }}
          className="workspaceContaint deletePagePop"
        >
          <Typography className="companyHeader">Leave Meeting</Typography>

          <Box className="basicBoxItem">
            <Typography className='addPageLabel'>Are you sure you want to leave from this meeting?</Typography>

          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => setLeaveMeeting(false)} />
            <AppBtn buttonText={`Leave`} onClick={() => handleLeave()} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
