import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { useTranslation } from "react-i18next";

import "./style.css";

//images
import messageIcon from "../../../Assets/Images/message-square.png";
import imageIcon from "../../../Assets/Images/iamgeIcon.png";
import videoIcon from "../../../Assets/Images/videoicon.png";
import micIcon from "../../../Assets/Images/mic.png";
import fileIcon from "../../../Assets/Images/file-text.png";
import mapIcon from "../../../Assets/Images/map.png";
import message2Icon from "../../../Assets/Images/message-circle.png";
import usersIcon from "../../../Assets/Images/usersB.png";
import pieIcon from "../../../Assets/Images/pie-chart.png";

import { useAppSelector } from "../../../lib/store/store";

export default function AddCardPop({
  setWorkSHAddCardPop,
  workSHAddCardPop,
  selectedCard,
  setSelectedCard,
  handelAddCardPopCoutinue,
}) {
  const workspaceState = useAppSelector((state) => state.workspace);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const Card = ({ title, subTitle, icon }) => {
    return (
      <Box
        onClick={() => setSelectedCard(title)}
        className={
          selectedCard === title ? "addCPCard addCPCardActive" : "addCPCard"
        }
      >
        <Box>
          <Typography
            className={
              selectedCard === title ? "hedText hedTextActive" : "hedText"
            }
          >
            {title}
          </Typography>
          <Box
            className={
              selectedCard === title
                ? "addCPCardIcon addCPCardIconActive"
                : "addCPCardIcon"
            }
          >
            <img src={icon} />
          </Box>
        </Box>
        <span>{subTitle}</span>
      </Box>
    );
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setWorkSHAddCardPop(false);
    }
  };

  useEffect(() => {
    if (workSHAddCardPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [workSHAddCardPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: workSHAddCardPop ? "flex" : "none" }}
        className="companyPop AddCardPop"
      >
        <Box
          sx={{ display: workSHAddCardPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className="companyHeader">
            {t("workspaceHome.addCard")}
          </Typography>
          <Typography className="addCPSubHeader">
            {t("addCardPop.addCardPopSubText")}
          </Typography>
          <Box className="addCPBoxRow">
            {Card({
              title: "Message",
              subTitle: "Share formatted text as a message with others",
              icon: messageIcon,
            })}
            {Card({
              title: "Image",
              subTitle: "Display an image or any image graphic",
              icon: imageIcon,
              index: 1,
            })}
            {Card({
              title: "Videos",
              subTitle: "Record on your webcam or upload a video for playback",
              icon: videoIcon,
            })}
          </Box>
          <Box my={5} className="addCPBoxRow">
            {Card({
              title: "Audios",
              subTitle:
                "Display an audio of your own or upload for the web for playback",
              icon: micIcon,
            })}
            {Card({
              title: "File",
              subTitle: "Share a file of any type to be downloaded",
              icon: fileIcon,
              index: 4,
            })}
            {Card({
              title: "Activities",
              subTitle: "Display activities to guide & improve response rates",
              icon: mapIcon,
            })}
          </Box>
          <Box className="addCPBoxRow">
            {Card({
              title: "Conversations",
              subTitle: "Feature conversation topics to maximize engagement",
              icon: message2Icon,
            })}
            {Card({
              title: "People",
              subTitle:
                "Introduce yourself, list recent visitors or showcase scores",
              icon: usersIcon,
            })}
            {Card({
              title: "Statistics",
              subTitle:
                "Display statistics of your activities and conversations in the form of graphs ",
              icon: pieIcon,
            })}
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine
              buttonText={t("commonTxt.cancelBtn")}
              autoFocus
              onClick={() => setWorkSHAddCardPop(false)}
            />
            <AppBtn
              buttonText={t("commonTxt.continueBtn")}
              onClick={handelAddCardPopCoutinue}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
