import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import audioWave from "../../../Assets/Images/audioWav.png";
import "./style.css";

//images
import videoIcon from "../../../Assets/Images/GvideoIcon.png";
import micIcon from "../../../Assets/Images/Gmic.png";
import headPhones from "../../../Assets/Images/BlackHeadphones.png"

//components
import { useTranslation } from "react-i18next";
import { Status } from "../../AppTools/AppTool"
import { AppBtnOutLine, AppBtn } from "../../../Components/AppTools/AppButton"
import { useAppSelector } from "../../../lib/store/store";

export default function LiveGroupChatStartPop({ liveGroupChatStartPop, setLiveGroupChatStartPop }) {
  const { t, i18n } = useTranslation();
  const authState = useAppSelector(state => state.auth);
  
  const [roleDrop, setRoleDrop] = useState(false);
  const [roleDropVal, setRoleDropVal] = useState("Join as");
  const [roleDrop2, setRoleDrop2] = useState(false);
  const [roleDropVal2, setRoleDropVal2] = useState("Select Language");

  const roleItem = [
    {
      name: "Host Meeting",
      value: "Host Meeting"
    },
    {
      name: "Backroom observer",
      value: "Backroom observer"
    },
    {
      name: "Translator",
      value: "Translator"
    }
  ];

  const languages = [
    {
      name: "English",
      value: "English"
    },
    {
      name: "Hindi",
      value: "Hindi"
    },
    {
      name: "Spanish",
      value: "Spanish"
    },
    {
      name: "Chinese",
      value: "Chinese"
    },
    {
      name: "Arabic",
      value: "Arabic"
    },
    {
      name: "Russian",
      value: "Russian"
    },
    {
      name: "German",
      value: "German"
    },
    {
      name: "Japanese",
      value: "Japanese"
    }
  ];

  const handelOpenMetting = () => {
    setLiveGroupChatStartPop(false);
    window.open("/participants/group-meeting", "_blank");
  }
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setLiveGroupChatStartPop(false);
    }
  };

  useEffect(() => {
    if (liveGroupChatStartPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [liveGroupChatStartPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: liveGroupChatStartPop ? "flex" : "none" }}
        className="companyPop liveInterView LGChatSP"
      >
        <Box
          sx={{ display: liveGroupChatStartPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className="livePHeader">{t("LiveInterview.LiveGroupChat")}</Typography>
          <Typography className="lavel_L">{t("LiveInterview.JoinAs")}</Typography>
          <Status
            setStatusDrop={setRoleDrop}
            statusDrop={roleDrop}
            setStatusDropVal={setRoleDropVal}
            statusDropVal={roleDropVal}
            label=" "
            itemAry={roleItem}
          />
          <Typography className="lavel_L">{t("LiveInterview.LanguageSpeak")}</Typography>
          <Status
            setStatusDrop={setRoleDrop2}
            statusDrop={roleDrop2}
            setStatusDropVal={setRoleDropVal2}
            statusDropVal={roleDropVal2}
            label=" "
            itemAry={languages}
          />

          <Box className="mediaInputBox micvideoIcon">
            <Box><img src={videoIcon} /></Box>
            <Box><img src={micIcon} /></Box>
          </Box>
          <Box className="headPhoneRecomanded">
            <img src={headPhones} />
            <Typography>{t("LiveInterview.HeadphonesRecommended")}</Typography>
          </Box>
          <Typography className="csbText" >{t("LiveInterview.FacingSystem")}</Typography>

          <Box className="audioWave">
            <img className="wave" src={audioWave} />
            <img className="wave" src={audioWave} />
          </Box>
          <Typography className="recordingText">{t("LiveInterview.recordingBeging")}</Typography>
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={() => setLiveGroupChatStartPop(false)} />
            <AppBtn buttonText="Join Meeting" onClick={handelOpenMetting} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
