import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import {
  Box,
  Typography,
  CardHeader,
  Avatar,
  CardContent,
} from "@mui/material";
import "./style.css";

//Images
import dropArrow from "../../../Assets/Images/chevron-down.png";
import whitedropIcon from "../../../Assets/Images/Wchevron-down.png";

import {
  AllSuperAdmin,
  groupBtnTab,
  participantPreview,
} from "../../../Assets/Data";
//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { SearchBar } from "../../AppTools/AppTool";
import ProjectDirectActiveParticipant from "../ProjectDirectoryActiveParticipant";
import ProjectDirectParticipantInvitation from "../ProjectDirectoryParticipantInvitation";
import ProjectDirectParticipantScreened from "../ProjectDirectoryParticipantScreened";
import ProjectDirectoryLeaderboard from "../ProjectDirectoryLeaderboard";

export default function ProjectDirectory({ dataReportTabe, setDataReportTabe }) {
  const [directoryDrop, setDirectoryDrop] = useState(false);
  const [directoryDropVal, setDirectoryDropVal] = useState("Participant");
  const [ParticipantsNavDrop, setParticipantsNavDrop] = useState(false);
  const [ParticipantsNavDropVal, setParticipantsNavDropVal] = useState();

  const [participantStatusDrop, setParticipantStatusDrop] = useState(false);
  const [participantStatusDropVal, setParticipantStatusDropVal] =
    useState("Active");
  const [participantTypeDrop, setParticipantTypeDrop] = useState(false);
  const [participantTypeDropVal, setParticipantTypeDropVal] = useState();
  const [selectedParticipant, setSelectedParticipant] = useState([]);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const Navigate = useNavigate();

  const handleCheckAllChange = () => {
    const bookIds = participantPreview.map((item, index2) => {
      return index2;
    });
    setSelectedParticipant(bookIds);
  };

  const handleUnCheckAllChange = () => {
    setSelectedParticipant([]);
  };

  useEffect(() => {
    let selTab = searchParams.get("pd");
    let selTab2 = searchParams.get("dr");
    let selTab3 = searchParams.get("t");
    if (selTab && selTab2 && selTab3 != null) {
      var query_string = selTab;
      query_string = query_string.replaceAll('_', ' ');
      query_string = query_string.replace(/(^\w|\s\w)/g, firstCharOfWord => firstCharOfWord.toUpperCase());

      var query_string2 = selTab3;
      query_string2 = query_string2.replaceAll('_', ' ');
      query_string2 = query_string2.replace(/(^\w|\s\w)/g, firstCharOfWord => firstCharOfWord.toUpperCase());
    setDirectoryDropVal(query_string);
    setParticipantStatusDropVal(query_string2);
    setDataReportTabe(parseInt(selTab2));
    }
    else if (selTab == null) {
      setDataReportTabe(parseInt(selTab2));
    }
  }, []);

  return (
    <>
      <Box className="researchersMainBox pageContainer">
        {/* <ExistingParticipants existingParticipant={existingParticipant} /> */}
        <Box className="integrationsContainer">
          <Box className="projectParticipantDropSearchBox">
            <Box className="projectCodeBookHead">
              <Box>
                {/* <Typography className='emailNotificationHeaderText'>Code Book</Typography> */}
                <Box
                  className="projectManagementNavBtn"
                  onClick={() => setDirectoryDrop(!directoryDrop)}
                >
                  <Typography className="DropBtnText">
                    {directoryDropVal || t("projectDirectory.userDropPlaceholder")}
                  </Typography>
                  <img
                    className="dropIcon"
                    src={whitedropIcon}
                    style={{
                      rotate: directoryDrop ? "180deg" : "0deg",
                      transition: "all 0.5s",
                      marginLeft: "10px",
                    }}
                  />
                  <Box
                    className="workspaceItemNavPop projectItemNavPop"
                    sx={{ display: directoryDrop ? "flex" : "none" }}
                  >
                    <Box 
                      onClick={() => {
                        setSearchParams({ dr:dataReportTabe,pd: "participant" });
                        setDirectoryDropVal("Participant");
                      }}
                    >
                      <Typography>{t("projectDirectory.userDropVal1")}</Typography>
                    </Box>
                    <Box 
                      onClick={() => {
                        setSearchParams({ dr:dataReportTabe,pd: "admin" });
                        setDirectoryDropVal("Admin");
                      }}
                    >
                      <Typography>{t("projectDirectory.userDropVal2")}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {directoryDropVal === "Participant" ? (
                <Box className="codeBookHeadBtn">
                  <AppBtnOutLine buttonText={t("commonTxt.manageParticipants")} />
                  <AppBtn
                    onClick={() =>
                      Navigate("/researchers/project/directory_participants")
                    }
                    buttonText={t("commonTxt.addParticipants")}
                  />
                </Box>
              ) : directoryDropVal === "Admin" ? (
                <Box className="codeBookHeadBtn">
                  <AppBtnOutLine buttonText={t("commonTxt.manageAdmins")} />
                  <AppBtn buttonText={t("commonTxt.addAdmin")} />
                </Box>
              ) : null}
            </Box>

            {directoryDropVal === "Participant" ? (
              <Box className="projectSearchGroupAction">
                <Box className="projectSearchGroup">
                  <Box sx={{ width: "23%" }}>
                    <Box
                      className="inputAppDrop"
                      onClick={() =>
                        setParticipantStatusDrop(!participantStatusDrop)
                      }
                    >
                      <Typography>
                        {participantStatusDropVal || t("projectDirectory.participantStatusDropPlaceholder")}
                      </Typography>
                      <img
                        style={{
                          rotate: participantStatusDrop ? "180deg" : "0deg",
                        }}
                        src={dropArrow}
                      />
                      {/* pop */}
                      <Box
                        sx={{
                          height: participantStatusDrop ? "auto" : "0px",
                        }}
                        className="statusPop"
                      >
                        <Box
                          onClick={() => {
                            setSearchParams({ dr:dataReportTabe,pd: directoryDropVal,t:"active" });
                            setParticipantStatusDropVal("Active")
                          }}
                          className="statusPopItem"
                        >
                          <Typography>{t("projectDirectory.participantStatusDropVal1")}</Typography>
                        </Box>
                        <Box
                          onClick={() => {
                            setSearchParams({ dr:dataReportTabe,pd: directoryDropVal,t:"inactive" });
                            setParticipantStatusDropVal("Inactive")
                          }}
                          className="statusPopItem"
                        >
                          <Typography>{t("projectDirectory.participantStatusDropVal2")}</Typography>
                        </Box>
                        <Box
                          onClick={() => {
                            setSearchParams({ dr:dataReportTabe,pd: directoryDropVal,t:"leaderboard" });
                            setParticipantStatusDropVal("Leaderboard")
                          }}
                          className="statusPopItem"
                        >
                          <Typography>{t("projectDirectory.participantStatusDropVal3")}</Typography>
                        </Box>
                        <Box
                          onClick={() => {
                            setSearchParams({ dr:dataReportTabe,pd: directoryDropVal,t:"invitations" });
                            setParticipantStatusDropVal("Invitations")
                          }}
                          className="statusPopItem"
                        >
                          <Typography>{t("projectDirectory.participantStatusDropVal4")}</Typography>
                        </Box>
                        <Box
                          onClick={() => {
                            setSearchParams({ dr:dataReportTabe,pd: directoryDropVal,t:"screened" });
                            setParticipantStatusDropVal("Screened")
                          }}
                          className="statusPopItem"
                        >
                          <Typography>{t("projectDirectory.participantStatusDropVal5")}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ width: "37%" }} className="inputBoxWlabel">
                    <SearchBar />
                  </Box>
                  <Box sx={{ width: "25%" }}>
                    <Box
                      className="inputAppDrop"
                      onClick={() =>
                        setParticipantTypeDrop(!participantTypeDrop)
                      }
                    >
                      <Typography>
                        {participantTypeDropVal || t("projectDirectory.typeDropPlaceholder")}
                      </Typography>
                      <img
                        style={{
                          rotate: participantTypeDrop ? "180deg" : "0deg",
                        }}
                        src={dropArrow}
                      />
                      {/* pop */}
                      <Box
                        sx={{ height: participantTypeDrop ? "auto" : "0px" }}
                        className="statusPop"
                      >
                        <Box
                          onClick={() =>
                            setParticipantTypeDropVal("Recently Online")
                          }
                          className="statusPopItem"
                        >
                          <Typography>{t("projectDirectory.typeDropVal1")}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ width: "13%" }} className="groupBtnTab">
                    {groupBtnTab}
                    <Typography>{t("commonTxt.groupBtn")}</Typography>
                  </Box>
                </Box>

                <Box className="projectDirectoryActionLinks">
                  <Box className="codeBookSelectDe">
                    <Typography
                      className="verifyLinkCodeBook"
                      onClick={handleCheckAllChange}
                    >
                      {t("commonTxt.selectAll")}
                    </Typography>
                    <Typography
                      className="verifyLinkCodeBook"
                      onClick={handleUnCheckAllChange}
                    >
                      {t("commonTxt.deSelectAll")}
                    </Typography>
                  </Box>
                  <Box className="projectDirectoryAction">
                    <Box className="allPNavInnerBox">
                      <Box className="allPnavInput">
                        <Box
                          className="inputAppDrop"
                          onClick={() =>
                            setParticipantsNavDrop(!ParticipantsNavDrop)
                          }
                        >
                          <Typography>
                            {ParticipantsNavDropVal || t("commonTxt.bulkActionDrop")}
                          </Typography>
                          <img
                            style={{
                              rotate: ParticipantsNavDrop ? "180deg" : "0deg",
                            }}
                            src={dropArrow}
                          />
                          {/* pop */}
                          <Box
                            sx={{
                              height: ParticipantsNavDrop ? "auto" : "0px",
                            }}
                            className="statusPop"
                          >
                            <Box
                              onClick={() =>
                                setParticipantsNavDropVal("Item-A")
                              }
                              className="statusPopItem"
                            >
                              <Typography>Item-A</Typography>
                            </Box>
                            <Box
                              onClick={() =>
                                setParticipantsNavDropVal("Item-B")
                              }
                              className="statusPopItem"
                            >
                              <Typography>Item-B</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <AppBtn buttonText={t("commonTxt.apply")} />
                  </Box>
                </Box>

                {/* participant list */}
                {participantStatusDropVal === "Active" ||
                participantStatusDropVal === "Inactive" ? (
                  <ProjectDirectActiveParticipant
                    selectedParticipant={selectedParticipant}
                    setSelectedParticipant={setSelectedParticipant}
                  />
                ) : participantStatusDropVal === "Invitations" ? (
                  <ProjectDirectParticipantInvitation
                    selectedParticipant={selectedParticipant}
                    setSelectedParticipant={setSelectedParticipant}
                  />
                ) : participantStatusDropVal === "Screened" ? (
                  <ProjectDirectParticipantScreened
                    selectedParticipant={selectedParticipant}
                    setSelectedParticipant={setSelectedParticipant}
                  />
                ) : participantStatusDropVal === "Leaderboard" ? (
                  <ProjectDirectoryLeaderboard />
                ) : null}
                {/* participant list */}
              </Box>
            ) : directoryDropVal === "Admin" ? (
              // Admin section
              <Box className="projectSearchGroupAction">
                <Box className="projectSearchGroup">
                  <Box sx={{ width: "57%" }} className="inputBoxWlabel">
                    <SearchBar />
                  </Box>
                  <Box sx={{ width: "25%" }}>
                    <Box
                      className="inputAppDrop"
                      onClick={() =>
                        setParticipantsNavDrop(!ParticipantsNavDrop)
                      }
                    >
                      <Typography>
                        {ParticipantsNavDropVal || t("projectDirectory.typeDropPlaceholder")}
                      </Typography>
                      <img
                        style={{
                          rotate: ParticipantsNavDrop ? "180deg" : "0deg",
                        }}
                        src={dropArrow}
                      />
                      {/* pop */}
                      <Box
                        sx={{ height: ParticipantsNavDrop ? "auto" : "0px" }}
                        className="statusPop"
                      >
                        <Box
                          onClick={() =>
                            setParticipantsNavDropVal("Recently Online")
                          }
                          className="statusPopItem"
                        >
                          <Typography>{t("projectDirectory.typeDropVal1")}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ width: "13%" }} className="groupBtnTab">
                    {groupBtnTab}
                    <Typography>{t("commonTxt.groupBtn")}</Typography>
                  </Box>
                </Box>
                <Box className="ParticipantsPanel">
                  {participantPreview &&
                    participantPreview?.map((el, index) => (
                      <Box className="ParticipantBox">
                        <CardHeader
                          avatar={
                            <Avatar
                              alt=""
                              src={el.img}
                              sx={{ width: 44, height: 44 }}
                            />
                          }
                          title={el.user}
                        />
                        <CardContent>
                          <Typography>965216452</Typography>
                          <Typography>{el.time}</Typography>
                        </CardContent>
                      </Box>
                    ))}
                </Box>
              </Box>
            ) : // Admin section
            null}
          </Box>
        </Box>
      </Box>
    </>
  );
}
