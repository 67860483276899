import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import SideMenu from "../../../Components/Collaborator/SideMenu";
import { AntSwitch } from "../../../Components/AppTools/AppTool";
import { sideHaumIcon, groupBtnTab } from "../../../Assets/Data";
import backArrow from "../../../Assets/Images/noun-back.png";

export default function ProjectParticipantLinkInvitation({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
}) {
  const Navigate = useNavigate();

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const previousPageHandler = () => {
    Navigate("/collaborator/project/directory_participants");
  };

  return (
    <>
      <Box className="ResearchersContainer">
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
          </Box>
          <Box className="researchersMainBox pageContainer">
            <Box className="BulkImportSection">
              <Box
                onClick={() => previousPageHandler()}
                className="openBtn"
                style={{ "margin-bottom": "10px" }}
              >
                <img src={backArrow} />
                <Typography className="neonBackText">
                  {t("commonTxt.backBtn")}
                </Typography>
              </Box>
              <Box className="invitationPageHeader">
                <Typography variant="h4">
                  {t("ProjectParticipantLinkInvitation.heading")}
                </Typography>
              </Box>
              <Box className="invitationPageRow">
                <Box className="invitationPageRowOne">
                  <Typography>
                    {t("ProjectParticipantLinkInvitation.InvitationText1")}
                  </Typography>
                </Box>
                <Box className="pickerBox invitationPickerBox ">
                  <input placeholder="https//:www.terapage.com/dem" />
                </Box>
              </Box>
              <Box className="invitationPageRow">
                <Box>
                  <Typography>
                    {t("ProjectParticipantLinkInvitation.InvitationText2")}
                  </Typography>
                </Box>
              </Box>
              <Box className="invitationPageRow">
                <Box className="airadioSwitchBox">
                  <AntSwitch
                    defaultChecked
                    inputProps={{ "aria-label": "ant design" }}
                  />
                  <Typography className="switchText">
                    {t("ProjectParticipantLinkInvitation.InvitationLink1")}
                  </Typography>
                  <Typography className="neonBackText purpleText">
                    {t("ProjectParticipantLinkInvitation.InvitationLink2")}
                  </Typography>
                </Box>
              </Box>
              <Box className="invitationPageRowTwo">
                <Box className="invitationPageHeader">
                  <Typography variant="h4">
                    {t("ProjectParticipantLinkInvitation.InvitationHeader1")}
                  </Typography>
                </Box>
              <Box className="invitationLinkAnynomusAccess">
                <Box className="anynomusAccessRadioInput">
                  <input
                    className="custom-checkbox"
                    type="checkBox"
                    defaultChecked
                  />
                  <Typography>
                    {t("ProjectParticipantLinkInvitation.InvitationText3")}
                  </Typography>
                </Box>
                <Box className="anynomusAccessRadioInput">
                  <input
                    className="custom-checkbox"
                    type="checkBox"
                    defaultChecked
                  />
                  <Typography>
                    {t("ProjectParticipantLinkInvitation.InvitationText4")}
                  </Typography>
                </Box>
              </Box>
              </Box>
              <Box className="invitationPageRowTwo">
                <Box className="invitationPageHeader">
                  <Typography variant="h4">
                    {t("ProjectParticipantLinkInvitation.InvitationText5")}
                  </Typography>
                </Box>
                <Box sx={{ width: "100%" }} className="groupBtnTab">
                  {groupBtnTab}
                  <Typography>
                    {t("ProjectParticipantLinkInvitation.label1")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
