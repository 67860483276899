import React, { useState } from "react";

import { Box, Typography } from "@mui/material";
// Images
import BlankImage from "../../../Assets/Images/No data-cuate 1.png";
import iIcon from "../../../Assets/Images/IIcon.png";
import WhitePlassIcon from "../../../Assets/Images/Wplus.png";
import { GoTop } from "../../AppTools/AppTool";

const NoAgreementBg = ({ setAddAgreementPop }) => {

  return (
    <>
      <Box>
        <Typography className="pageHeaderText">Agreements</Typography>
        <Box my={3} className="iBox">
          <img src={iIcon} />
          <Typography>
            Specify opt-in statements for end-user agreements. These agreements may involve a page with additional details.
          </Typography>
        </Box>
      </Box>
      <img src={BlankImage} />
      <Typography gutterBottom>
        It seems like you have not added any agreement yet.
      </Typography>
      <Typography className="captionText" gutterBottom>
        Please add to proceed further
      </Typography>
      <Box
        onClick={() => {
          setAddAgreementPop(true);
          GoTop();
        }}
        className="AddPBtn">
        <img src={WhitePlassIcon} />
        <Typography ml={1}>Add Agreement</Typography>
      </Box>

    </>
  );
};

export default NoAgreementBg;
