import React, { useEffect, useState } from "react";
import "./style.css";
import { Box, Typography } from "@mui/material";

//images
import iIcon from "../../../Assets/Images/IIcon.png";
import OptionIcon from "../../../Assets/Images/Option2LIcon.png";
import EditIcon from "../../../Assets/Images/EditBIcon.png";
import ActiviteIcon from "../../../Assets/Images/ActivateIcon.png";
import DeleteIcon from "../../../Assets/Images/DeleteBIcon.png";
import WhitePlassIcon from "../../../Assets/Images/Wplus.png";
import { sideHaumIcon } from "../../../Assets/Data";

//components
import SideMenu from "../../../Components/WSAdministration/SideMenu";
import {
  SearchBar,
  LatestCreated,
  GoTop,
} from "../../../Components/AppTools/AppTool";
import NoAgreementBg from "../../../Components/WSAdministration/NoAgreementBg";
import AddAgreementPop from "../../../Components/WSAdministration/AddAgreementPop";
import EditAgreementPop from "../../../Components/WSAdministration/EditAgreementPop";
import { AppBtnOutLine } from "../../../Components/AppTools/AppButton";
import DeleteAgreementPop from "../DeleteAgreementPop";
import ResearcherLayout from "../../Researchers/partials/ResearcherLayout";
import AdminLayout from "../../Admin/partials/AdminLayout";
import { useAppSelector } from "../../../lib/store/store";
import { renderUserLayout } from "../../../lib/helpers";
import axios from "../../../lib/axios";
import Pagination from "../../../Components/AppTools/Pagination";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../env";
import { researcherAction } from "../../../Services/researcherActions";
import ViewAgreementPop from "../../../Components/WSAdministration/ViewAgreementPop";

export default function Agreements({ }) {
  const authState = useAppSelector((state) => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace);
  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Recently Added");
  /* const agreementList = [
    "I have read and agree to the terms",
    "I have read and agree to the condition",
  ]; */
  const [addAgreementPop, setAddAgreementPop] = useState(false);
  const [deleteAgreementPop, setDeleteAgreementPop] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [agreementList, setAgreementList] = useState([
    "I have read and agree to the terms",
    "I have read and agree to the condition",
  ])


  const AgreementsItem = ({ agreement }) => {
    const [editAgreementPop, setEditAgreementPop] = useState(false);
    const [viewAgreementPop, setViewAgreementPop] = useState(false);
    const [editAgreementId, setEditAgreementId] = useState(null);
    const [viewAgreementId, setViewAgreementId] = useState(null);
    const viewAgrs = (id) => {
      setViewAgreementId(id)
      setViewAgreementPop(true)
    };

    const createMarkup = (html) => {
      return { __html: html };
    };

    return (
      <Box className="agreementsItem">
        <DeleteAgreementPop
          deleteAgreementPop={deleteAgreementPop}
          setDeleteAgreementPop={setDeleteAgreementPop}
          agreementId={agreement._id}
          agreementName={agreement.name}
          setReloadList={setReloadList}
        />
        <EditAgreementPop
          editAgreementPop={editAgreementPop}
          setEditAgreementPop={setEditAgreementPop}
          agreementId={editAgreementId}
          setEditAgreementId={setEditAgreementId}
          setReloadList={setReloadList}
        />
         <ViewAgreementPop
          viewAgreementPop={viewAgreementPop}
          setViewAgreementPop={setViewAgreementPop}
          agreementId={viewAgreementId}
        />
        <Box className="agreItemNav">
          <Box>
            <img className="option2Icon" src={OptionIcon} />
            <Typography className="agrNavText1 termsText">
              Terms of Use
            </Typography>
            <Box className="agreDraft">
              <Box></Box>
              <Typography className="agrNavText1">{agreement.status === "active" ? "Active" : "Draft"}</Typography>
            </Box>
          </Box>
          <Box>
            <Box className="argNavBtn" onClick={() => { setEditAgreementId(agreement._id); setEditAgreementPop(true) }}>
              <img src={EditIcon} />
              <Typography>Edit</Typography>
            </Box>
            <Box className="argNavBtn" onClick={() => updateStatus(agreement._id, agreement.status === "active" ? "draft" : "active")}>
              <img src={ActiviteIcon} />
              <Typography>{agreement.status === "active" ? "Draft" : "Active"}</Typography>
            </Box>
            <Box
              className="argNavBtn"
              onClick={() => setDeleteAgreementPop(true)}
            >
              <img src={DeleteIcon} />
              <Typography>Delete</Typography>
            </Box>
          </Box>
        </Box>
        <Box
          ml={2}
          mt={2}
          mb={2}
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <input
            style={{ marginRight: "10px" }}
            className="custom-checkbox"
            type="checkBox"
          />
          <Typography>
            {agreement.opt_statement}
            {agreement.enable_page === "blank" && <><span className="agreementLinkLabel" onClick={() =>{setViewAgreementId(agreement._id); setViewAgreementPop(true)}}>View</span></>}
          </Typography>
        </Box>
      </Box>
    );
  };

  const defaultFilters = {
    search: "",
    sortBy: "created_at",
    sortOrder: -1,
    workspace_id: workspaceState?.selectedWorkspace?._id
  };

  const [filterState, setFilterState] = useState({ ...defaultFilters });

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 25,
    totalPages: 0,
    totalItems: 0,
  });

  const handleFilterChange = (changedFilter) => {
    let newFilters = {
      ...filterState,
      ...changedFilter,
    };
    setFilterState(newFilters);
    getAgreements(1, newFilters);
  };

  const clearFilters = () => {
    const newFilters = {
      ...defaultFilters,
    };
    setFilterState(newFilters);
    getAgreements(1, newFilters);
  };

  const onPageChange = (pageNum) => {
    getAgreements(pageNum);
  };

  useEffect(() => {
    getAgreements(1)
  }, [])

  useEffect(() => {
    if (reloadList) {
      getAgreements(1);
      setReloadList(false);
    }
  }, [reloadList])

  const getAgreements = async (page = 1, filter = defaultFilters) => {
    try {
      const queryParams = new URLSearchParams(filter).toString();
      const url = `super-admin/admin/agreements?page=${page}&limit=10&${queryParams}`;
      console.log(url)
      const response = await axios.get(url);
      const agreements = response?.data?.data;
      setAgreementList(agreements);
      setPaginationData({
        currentPage: response.data.pagination.currentPage,
        itemsPerPage: response.data.pagination.itemsPerPage,
        totalPages: response.data.pagination.totalPages,
        totalItems: response.data.pagination.totalItems,
      });
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  }

  const updateStatus = async (agreedId, status) => {
    try {
      const payload = {
        agreement_id: agreedId,
        status
      }
      let response = await researcherAction(`/super-admin/admin/agreements/change-status`, payload, authState.authToken);
      if (response.success) {
        setReloadList(true)
        toast.success(response.message, TOAST_CONFIG);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  }

  const getActionBtns = () => {
    return (
      <>
        <Box className="AppNavBtnBox">
          <AppBtnOutLine buttonText="Use Template" />
          <Box
            onClick={() => {
              setAddAgreementPop(true);
              GoTop();
            }}
            className="AddPBtn"
          >
            <img src={WhitePlassIcon} />
            <Typography ml={1}>Add Agreement</Typography>
          </Box>
        </Box>
      </>
    )
  }

  const getChildren = () => {
    return <Box className="researchersMainBox pageContainer">
      <AddAgreementPop
        addAgreementPop={addAgreementPop}
        setAddAgreementPop={setAddAgreementPop}
        setReloadList={setReloadList}
      />
      {agreementList?.length <= 0 ? (
        <NoAgreementBg setAddAgreementPop={setAddAgreementPop} />
      ) : (
        <Box className="agreements">
          <Box mb={5} className="workspaceListBox">
            <Typography className="pageHeaderText">Agreements</Typography>
            <Box my={3} className="iBox">
              <img src={iIcon} />
              <Typography>
                Specify opt-in statements for end-user agreements. These
                agreements may involve a page with additional details.
              </Typography>
            </Box>
            <Box className="agreSearchBox">
              <Box className="agreSearchB1">
                <SearchBar handleFilterChange={handleFilterChange} val={filterState} />
              </Box>
              <Box className="agreSearchB2">
                <LatestCreated
                  setLatestDrop={setLatestDrop}
                  latestCDrop={latestCDrop}
                  setLatestDropVal={setLatestDropVal}
                  latestDropVal={latestDropVal}
                  handleFilterChange={handleFilterChange}
                />
              </Box>
            </Box>
          </Box>



          {agreementList.length > 0 && agreementList.map((el) => (el.enable_page === "in-line" ? <Box className="workspaceListBox">
            <Typography className="pageHeaderText">
              {el.name}
            </Typography>
            <Typography mt={2} className="agreNText">
              {el.page_content}
            </Typography>
            <Box my={2}></Box>
            <AgreementsItem agreement={el} />
          </Box> : <Box mb={5} className="workspaceListBox">
            <AgreementsItem agreement={el} />
          </Box>))}
          <Box className="pagination">
            <Pagination
              paginationData={paginationData}
              onPageChange={onPageChange}
            />
          </Box>
        </Box>

      )}
    </Box>
  }

  return renderUserLayout(authState?.userType, getActionBtns(), getChildren(), 'workadmin');
}
