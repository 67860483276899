import React, { useEffect, useState } from "react";
import "../../UserAuth/logIn/style.css";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../lib/store/store";

//images
import banner from "../../../Assets/Images/logBanner.png";
import CLogo from "../../../Assets/Images/Frame 243811.png";
import whiteArrow from "../../../Assets/Images/white Arrow.png";
import VisibleIcon from "../../../Assets/Images/VisibleIcon.png";
import googleIcon from "../../../Assets/Images/google.png";
import FacebookIcon from "../../../Assets/Images/facebook.png";
import RedInfo from "../../../Assets/Images/info.png";
import {
  login,
  redirectIfAuthenticated,
  updateRedUserProfile
} from "../../../Services/auth";
import { useTranslation } from "react-i18next";
import { getUserTypeFromSegment, getUserUriSegment } from "../../../lib/helpers";
import { useParams } from 'react-router-dom';
import { USER_TYPE_PARTICIPANT, USER_TYPE_SUPERADMIN, USER_TYPE_WS_ADMIN } from "../../../lib/constants";

export default function ResearchersLogin() {

  const { usersegment } = useParams();
  let usertype = getUserTypeFromSegment(usersegment);

  let dispatch = useAppDispatch();
  let authState = useAppSelector((state) => state.auth);
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const Navigate = useNavigate();
  const [pwd, setPwd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    stay_signed_in: false,
  });

  useEffect(() => {
    redirectIfAuthenticated(
      Navigate,
      dispatch,
      authState.authToken,
      authState.userProfile,
      usertype
    );
  }, []);

  const changeAppLanguage = (lng) => {
    changeLanguage(lng);
  };

  let handleLogin = async () => {
    setApiError("");
    setLoading(true);
    try {
      let loginRes = await login(loginData);
      setLoading(false);
      if (loginRes.success) {
        if (loginRes?.data?.twofaEnabled === true) {
          return Navigate(`/${getUserUriSegment(usertype)}/2fa-verify`, { 'state': { 'token': loginRes?.data?.token } });
        } else {
          return Navigate(`/${getUserUriSegment(usertype)}/login-otp-verify`, { 'state': { email: loginData.email, stay_signed_in: loginData.stay_signed_in } });
        }

        //updateRedUserProfile(loginRes?.data?.token, dispatch, Navigate, true, usertype);
      } else {
        setApiError(loginRes.message);
      }
    } catch (err) {
      console.log(err);
      setApiError("Something went wrong! Please try again");
      setLoading(false);
    }
  };
  return (
    <>
      <select
        style={{ float: "right" }}
        onChange={(e) => changeAppLanguage(e.target.value)}
        value={language}
      >
        <option value="en">English</option>
        <option value="hi">Hindi</option>
      </select>
      <Box className="logInPage">
        <Box className="banner">
          <Typography className="">{t("adminSignIn.headerTxt")}</Typography>
          <Box mt={3} className="slideBox">
            <Box className="sBox"></Box>
            <Box className="sBox"></Box>
            <Box className="sBox sBoxActive"></Box>
          </Box>
          <img src={banner} />
        </Box>

        <Box className="signInBox">
          <Box className="signInInnerBox">
            <Box className="signColog">
              <img onClick={() => Navigate("/")} src={CLogo} />
            </Box>
            <Box className="signInInputBox">
              <Typography>{t("adminSignIn.usernameOrEmail")}</Typography>
              <input
                placeholder="Enter Username or Email Address"
                onChange={(e) => {
                  setLoginData({ ...loginData, email: e.target.value });
                }}
              />
            </Box>
            <Box className="signInInputBox">
              <Typography>{t("adminSignIn.password")}</Typography>
              <input
                placeholder="Enter Password"
                type={pwd ? "text" : "password"}
                onChange={(e) => {
                  setLoginData({ ...loginData, password: e.target.value });
                }}
              />
              <img
                className="eyeIcon"
                src={VisibleIcon}
                onClick={() => setPwd(!pwd)}
              />
            </Box>

            {apiError != "" && (
              <Box className="pwdError">
                <img src={RedInfo} />
                <Typography>{apiError}</Typography>
              </Box>
            )}

            <Box className="forGotPwd">
              <Typography
                onClick={() => Navigate(`/${getUserUriSegment(usertype)}/send-email`)}
                color={"#4F33D1"}
              >
                {t("adminSignIn.forgotPassword")}?
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setLoginData({
                      ...loginData,
                      stay_signed_in: e.target.checked,
                    });
                  }}
                />
                <Typography ml={1}>{t("adminSignIn.staySignedIn")}</Typography>
              </Box>
            </Box>
            <Box className="signBtn" onClick={() => handleLogin()}>
              {loading ? (
                <Typography>{t("adminSignIn.loading")}...</Typography>
              ) : (
                <>
                  <Typography>{t("adminSignIn.signIn")}</Typography>
                  <img src={whiteArrow} />
                </>
              )}
            </Box>

            {usertype != USER_TYPE_SUPERADMIN && <>
              {/* <Typography
                onClick={() => Navigate(`/${(usertype == USER_TYPE_PARTICIPANT) ? getUserUriSegment(USER_TYPE_WS_ADMIN) : getUserUriSegment(USER_TYPE_PARTICIPANT)}/login`)}
                color={"#4F33D1"} style={{ textAlign: 'center', marginTop: 5 }}
              >
                {usertype == USER_TYPE_PARTICIPANT ? `Not a Participant? Login as Workspace Admin` : `Not a Workspace Admin? Login as Participant`}
              </Typography> */}

              <Box className="orText">
                <p>Or</p>
              </Box>
              <Box className="GoogleSignUpBox">
                <img src={googleIcon} />
                <Typography>Sign in with Google</Typography>
              </Box>
              <Box className="GoogleSignUpBox">
                <img src={FacebookIcon} />
                <Typography>Sign in with facebook</Typography>
              </Box>
              <Box className="signBtn">
                <Typography>Sign In with SSO</Typography>
              </Box>
            </>}
          </Box>
        </Box>
      </Box>
    </>
  );
}
