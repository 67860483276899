import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";

import "./style.css";

//images

import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";
import groupsIcon from "../../../Assets/Images/groupsIcon.png";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

//DATA
import { fieldItem, DestinationUrlLists } from "../../../Assets/Data";
import { AntSwitch } from "../../AppTools/AppTool";
import { useAppSelector } from "../../../lib/store/store";
import { uploadFile } from "../../../Services/adminActions";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";

export default function AddOnDotProfileField({
  filterDropVal,
  setFilterDropVal,
  setCustomField,
  customField,
  isShowUser,
  setIsShowUser,
  handleParticipantsChange,
  allParticipantsList,
  allGroups,
}) {
  const email2 = "Provide Instructions here";
  const [emailval, setEmailval] = useState();
  const [emailval2, setEmailval2] = useState();
  const [emailval3, setEmailval3] = useState();
  const [emailval4, setEmailval4] = useState();
  const [signatureTextLaOut, setSignatureTextLaOut] = useState("start");

  const [signatureTextItalic, setSignatureTextItalic] = useState(false);
  const [signatureTextBold, setSignatureTextBold] = useState(false);
  const [signatureTextUnderLine, setSignatureTextUnderLine] = useState();

  const [signatureTextItalic2, setSignatureTextItalic2] = useState(false);
  const [signatureTextBold2, setSignatureTextBold2] = useState(false);
  const [signatureTextUnderLine2, setSignatureTextUnderLine2] = useState();

  const [signatureTextItalic3, setSignatureTextItalic3] = useState(false);
  const [signatureTextBold3, setSignatureTextBold3] = useState(false);
  const [signatureTextUnderLine3, setSignatureTextUnderLine3] = useState();

  const [signatureTextItalic4, setSignatureTextItalic4] = useState(false);
  const [signatureTextBold4, setSignatureTextBold4] = useState(false);
  const [signatureTextUnderLine4, setSignatureTextUnderLine4] = useState();

  const [groupDrop, setGroupDrop] = useState(true);
  const [groupDrop2, setGroupDrop2] = useState(false);
  const [groupDrop3, setGroupDrop3] = useState(false);
  const [redio, setRedio] = useState(0);
  const [image, setImage] = useState(null);

  const [label1Drop, setLabel1Drop] = useState(false);
  const [label1DropVal, setLabel1DropVal] = useState("Continue");
  const [label1StyleDrop, setLabel1StyleDrop] = useState(false);
  const [label1StyleDropVal, setLabel1StyleDropVal] = useState("Tertiary");

  const [label2Drop, setLabel2Drop] = useState(false);
  const [label2DropVal, setLabel2DropVal] = useState("Continue");
  const [label2StyleDrop, setLabel2StyleDrop] = useState(false);
  const [label2StyleDropVal, setLabel2StyleDropVal] = useState("tertiary");

  const [destinationDrop, setDestinationDrop] = useState(false);
  const [destinationDropVal, setDestinationDropVal] = useState("Go to URL");
  const [promptStatus, setPromptStatus] = useState(false);
  const [promptStatusVal, setPromptStatusVal] = useState("Not Started");
  const authState = useAppSelector((state) => state.auth);
  const [fileName, setFileName] = useState(null);
  const [destinationUrlDrop, setDestinationUrlDrop] = useState(false);
  const [destinationUrlDropVal, setDestinationUrlDropVal] = useState(
    "http://terapage.com/abc?completetask"
  );

  const handelTextlineChekc4 = (p, input) => {
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine4 === "underline") {
          setSignatureTextUnderLine4(null);
          return;
        } else {
          setSignatureTextUnderLine4("underline");
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine4 === "line-through") {
          setSignatureTextUnderLine4(null);
          return;
        } else {
          setSignatureTextUnderLine4("line-through");
        }
      }
    }
  };

  const handelChange = (e, i) => {
    const { name, value } = e.target;
    if (i === 1) {
      setEmailval((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else if (i === 2) {
      setEmailval2((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else if (i === 3) {
      setEmailval3((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else if (i === 4) {
      setEmailval4((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

 
  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setFileName(file.name);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setFileName(file.name);
        console.log(reader.result)
      };
      reader.readAsDataURL(file);
      // uploadImage(file);
    }
  };

  const uploadImage = async (file) => {
    if (!file) return false;
    const formData = new FormData();
    formData.append("file", file);
    try {
      let imageUploadRes = await uploadFile(formData, authState.authToken);
      if (imageUploadRes.success) {
        setCustomField({
          ...customField,
          file: imageUploadRes?.data?.upload_id,
        });
        // toast.success(imageUploadRes.message, TOAST_CONFIG);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    } finally {
    }
  };

  const chackBoxRoom = () => {
    return (
      <Box className="checkBoxItem">
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            checked={redio === 0 ? true : false}
          />
          <Typography>Group 0</Typography>
        </Box>
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            checked={redio === 0 ? true : false}
          />
          <Typography>Group 0</Typography>
        </Box>
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            checked={redio === 0 ? true : false}
          />
          <Typography>Group 0</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {/* Video section */}
      <Box
        sx={{
          display:
            fieldItem[filterDropVal]?.field === "On-the-Dot" ? "flex" : "none",
        }}
        mt={5}
        className="fieldBox"
      >
        <Typography className="eTemPopLabel">Instructions</Typography>
        <Box className="emailInputBox">
          <Box className="emailInputBoxNav">
            <Box
              onClick={() => setSignatureTextItalic4(!signatureTextItalic4)}
              className="emailImgBox"
            >
              <img src={IIcon} />
            </Box>
            <Box
              onClick={() => setSignatureTextBold4(!signatureTextBold4)}
              className="emailImgBox"
            >
              <img src={BIcon} />
            </Box>
            <Box
              onClick={() => handelTextlineChekc4("underline", "signature")}
              className="emailImgBox"
            >
              <img src={UnderLineU} />
            </Box>
            <Box
              onClick={() => handelTextlineChekc4("line-through", "signature")}
              className="emailImgBox"
            >
              <img src={MLineU} />
            </Box>
            <Box className="emailImgBox">
              <img src={AtachMentIcon} />
            </Box>
            <Box className="emailImgBox">
              <img src={Qbox} />
            </Box>
            <Box
              onClick={() => setSignatureTextLaOut("start")}
              className="emailImgBox"
            >
              <img src={Line1} />
            </Box>
            <Box
              onClick={() => setSignatureTextLaOut("center")}
              className="emailImgBox"
            >
              <img src={Line2} />
            </Box>
            <Box
              onClick={() => setSignatureTextLaOut("end")}
              className="emailImgBox"
            >
              <img src={Line3} />
            </Box>
            <Box className="emailImgBox">
              <img src={Line4} />
            </Box>
            <Box className="emailImgBox">
              <img src={emoji} />
            </Box>
          </Box>
          <textarea
            style={{
              textAlign: signatureTextLaOut,
              fontStyle: signatureTextItalic4 ? "italic" : "normal",
              fontWeight: signatureTextBold4 ? "bold" : "normal",
              textDecoration: signatureTextUnderLine4,
            }}
            className="emailInput"
            name="instruction"
            value={customField?.instruction}
            onChange={(e) => {
              handelChange(e, 4);
              setCustomField({
                ...customField,
                instruction: e.target.value,
              });
            }}
          />
        </Box>

        <Box
          className="mediaInputbox upload-area"
          onClick={() => {
            document.getElementById("fileInput7").click();
          }}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
         {image ? <img src={image} /> : <img src={uploadIcon} />}
          <Typography>
            Click or drag media files to this area to upload
          </Typography>
          <input
            id="fileInput7"
            style={{ display: "none" }}
            type="file"
            onChange={handleFileInputChange}
          />
        </Box>
        {/* On-the-Dot */}
        <p style={{ marginBottom: "24px" }} className="allPartRedioLabel">
          Configurations
        </p>
        <Box className="advanceFilterRowOne">
          <Box className="inputBoxWlabel">
            <Typography className="inputLabel">Label 1</Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setLabel1Drop(!label1Drop)}
            >
              <Typography>{label1DropVal || "Select Label 1"}</Typography>
              <img
                style={{ rotate: label1Drop ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: label1Drop ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => {
                    setLabel1DropVal("Continue");
                    setCustomField({
                      ...customField,
                      lebel: "Continue",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Continue</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setLabel1DropVal("Save");
                    setCustomField({
                      ...customField,
                      lebel: "Save",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Save</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setLabel1DropVal("Submit");
                    setCustomField({
                      ...customField,
                      lebel: "Submit",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Submit</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="inputBoxWlabel">
            <Typography className="inputLabel">Select Style</Typography>

            <Box
              className="inputAppDrop"
              onClick={() => setLabel1StyleDrop(!label1StyleDrop)}
            >
              <Typography>{label1StyleDropVal || "Select Style"}</Typography>
              <img
                style={{ rotate: label1StyleDrop ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: label1StyleDrop ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => {
                    setLabel1StyleDropVal("Tertiary");
                    setCustomField({
                      ...customField,
                      style: "Tertiary",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Tertiary</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setLabel1StyleDropVal("Success");
                    setCustomField({
                      ...customField,
                      style: "Success",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Success</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setLabel1StyleDropVal("Info");
                    setCustomField({
                      ...customField,
                      style: "Info",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Info</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setLabel1StyleDropVal("Danger");
                    setCustomField({
                      ...customField,
                      style: "Danger",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Danger</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setLabel1StyleDropVal("Warning");
                    setCustomField({
                      ...customField,
                      style: "Warning",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Warning</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setLabel1StyleDropVal("Dark");
                    setCustomField({
                      ...customField,
                      style: "Dark",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Dark</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setLabel1StyleDropVal("Muted");
                    setCustomField({
                      ...customField,
                      style: "Muted",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Muted</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setLabel1StyleDropVal("Primary");
                    setCustomField({
                      ...customField,
                      style: "Primary",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Primary</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setLabel1StyleDropVal("Secondary");
                    setCustomField({
                      ...customField,
                      style: "Secondary",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Secondary</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Typography className="advanceOptionLink">
          Show Advance Options
        </Typography>
        <Box className="advanceFilterRowOne">
          <Box className="inputBoxWlabel">
            <Typography className="inputLabel">Label 2</Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setLabel2Drop(!label2Drop)}
            >
              <Typography>{label2DropVal || "Select Label 1"}</Typography>
              <img
                style={{ rotate: label2Drop ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: label2Drop ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => {
                    setLabel2DropVal("Continue");
                    setCustomField({
                      ...customField,
                      lebel2: "Continue",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Continue</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setLabel2DropVal("Save");
                    setCustomField({
                      ...customField,
                      lebel2: "Save",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Save</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setLabel2DropVal("Submit");
                    setCustomField({
                      ...customField,
                      lebel2: "Submit",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Submit</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="inputBoxWlabel">
            <Typography className="inputLabel">Select Style</Typography>

            <Box
              className="inputAppDrop"
              onClick={() => setLabel2StyleDrop(!label2StyleDrop)}
            >
              <Typography>{label2StyleDropVal || "Select Style"}</Typography>
              <img
                style={{ rotate: label2StyleDrop ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: label2StyleDrop ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => {
                    setLabel2StyleDropVal("Tertiary");
                    setCustomField({
                      ...customField,
                      style2: "Tertiary",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Tertiary</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setLabel2StyleDropVal("Success");
                    setCustomField({
                      ...customField,
                      style2: "Success",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Success</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setLabel2StyleDropVal("Info");
                    setCustomField({
                      ...customField,
                      style2: "Info",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Info</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setLabel2StyleDropVal("Danger");
                    setCustomField({
                      ...customField,
                      style2: "Danger",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Danger</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setLabel2StyleDropVal("Warning");
                    setCustomField({
                      ...customField,
                      style2: "Warning",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Warning</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setLabel2StyleDropVal("Dark");
                    setCustomField({
                      ...customField,
                      style2: "Dark",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Dark</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setLabel2StyleDropVal("Muted");
                    setCustomField({
                      ...customField,
                      style2: "Muted",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Muted</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setLabel2StyleDropVal("Primary");
                    setCustomField({
                      ...customField,
                      style2: "Primary",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Primary</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setLabel2StyleDropVal("Secondary");
                    setCustomField({
                      ...customField,
                      style2: "Secondary",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Secondary</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Typography className="advanceOptionLink">
          Hide Advance Options
        </Typography>
        <Box className="advanceFilterRowOne">
          <Box className="inputBoxWlabel">
            <Typography className="inputLabel">Select Destination</Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setDestinationDrop(!destinationDrop)}
            >
              <Typography>
                {destinationDropVal || "Select Destination"}
              </Typography>
              <img
                style={{ rotate: destinationDrop ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: destinationDrop ? "auto" : "0px" }}
                className="statusPop"
              >
                {DestinationUrlLists &&
                  DestinationUrlLists?.map((el, index) => (
                    <Box className="statusPopItem">
                      <Typography
                        onClick={(e) => {
                          setDestinationDropVal(e.target.innerText);
                          setCustomField({
                            ...customField,
                            destination: el.title.toLocaleLowerCase().replaceAll(' ',"_"),
                          });
                        }} // Modify this line
                      >
                        {el.title}
                      </Typography>
                    </Box>
                  ))}
              </Box>
            </Box>
          </Box>

          <Box className="inputBoxWlabel">
            <Typography className="inputLabel">Prompt Status</Typography>

            <Box
              className="inputAppDrop"
              onClick={() => setPromptStatus(!promptStatus)}
            >
              <Typography>
                {promptStatusVal || "Select Prompt Status"}
              </Typography>
              <img
                style={{ rotate: promptStatus ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: promptStatus ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => {
                    setPromptStatusVal("Not Started");
                    setCustomField({
                      ...customField,
                      prompt_status: "Not Started",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Not Started</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setPromptStatusVal("Complete");
                    setCustomField({
                      ...customField,
                      prompt_status: "Complete",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Complete</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setPromptStatusVal("Incomplete");
                    setCustomField({
                      ...customField,
                      prompt_status: "Incomplete",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Incomplete</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setPromptStatusVal("Inprogress");
                    setCustomField({
                      ...customField,
                      prompt_status: "Inprogress",
                    });
                  }}
                  className="statusPopItem"
                >
                  <Typography>Inprogress</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="advanceFilterRowOne">
          <Box className="inputBoxWlabel">
            <Typography className="inputLabel onDotBasicSetting">
              Destination URL
            </Typography>
            <input
              className="phoneInput"
              placeholder="http://terapage.com/abc?compeletetask"
              value={customField.destination_url}
              onChange={(el) => {
                setCustomField({
                  ...customField,
                  destination_url: el.target.value,
                });
              }}
            />
          </Box>
        </Box>
        {/* <Box className="searchGroupSection">
          <Typography className="inputLabel">Groups</Typography>
          <FormControl sx={{ width: "100%" }} size="small">
            <Select
              labelId="demo-select-small-label11"
              id="demo-select-small11"
              value={customField.groups}
              onChange={handleParticipantsChange}
              name="groups"
              multiple
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <p>Select Groups</p>;
                }
                return selected
                  .map((value) => {
                    const group = allGroups.find(
                      (group) => group._id === value
                    );
                    return group ? group.name : value;
                  })
                  .join(", ");
              }}
            >
              {allGroups.map((group, index) => (
                <MenuItem value={group._id} key={index}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box> */}
        {/* On-the-Dot */}

        <p
          style={{ marginBottom: "24px" }}
          className="allPartRedioLabel onDotBasicSetting"
        >
          Basic Settings
        </p>

        <Box className="checkWlabel">
          <input className="custom-checkbox" type="checkBox" defaultChecked />
          <Typography>Response Required</Typography>
        </Box>
        <Box className="enforceInputBox internalInputBox">
          <AntSwitch
            inputProps={{ "aria-label": "ant design" }}
            onClick={(el) => {
              setCustomField({
                ...customField,
                skip: el.target.checked,
              });
            }}
          />
          <p>Ask for a reason to skip</p>
        </Box>
        <p
          style={{ marginTop: "46px", marginBottom: "24px" }}
          className="allPartRedioLabel"
        >
          Advance Settings
        </p>
        <Box className="checkWlabel">
          <input
            className="custom-checkbox"
            type="checkBox"
            checked={customField?.limit_participant_check}
            onClick={(el) => {
              setCustomField({
                ...customField,
                limit_participant_check: el.target.checked,
              });
              setIsShowUser(true);
              if (el.target.checked === false) {
                setCustomField((prevGroup) => ({
                  ...prevGroup,
                  limit_participant: [],
                }));
                setIsShowUser(false);
              }
            }}
          />
          <Typography>Limit who can complete this field</Typography>
        </Box>

        <Box className="searchGroupSection">
          <Box className="internalInputBox">
            {isShowUser && (
              <FormControl sx={{ width: "100%" }} size="small">
                <Select
                  labelId="demo-select-small-label11"
                  id="demo-select-small11"
                  value={customField.limit_participant}
                  onChange={handleParticipantsChange}
                  name="limit_participant"
                  multiple
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <p>Select Participants</p>;
                    }
                    return selected
                      .map((value) => {
                        const user = allParticipantsList.find(
                          (user) => user._id === value
                        );
                        return user ? user.name : value;
                      })
                      .join(", ");
                  }}
                >
                  {allParticipantsList.map((user, index) => (
                    <MenuItem value={user._id} key={index}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        </Box>

        <Box className="checkWlabel">
          <input
            className="custom-checkbox"
            type="checkBox"
            checked={customField?.content_pii}
            onClick={(el) =>
              setCustomField({
                ...customField,
                content_pii: el.target.checked,
              })
            }
          />
          <Typography>
            May contain personal identifiable information (PII)
          </Typography>
        </Box>
      </Box>
    </>
  );
}
