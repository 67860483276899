import React, { useEffect, useState, useRef } from 'react'
import { Box, Typography } from '@mui/material'
import "./style.css"

//images
import IIcon from "../../../Assets/Images/IIcon.png";
import GIIcon from "../../../Assets/Images/GIIcon.png"
import copyIcon from "../../../Assets/Images/copyIcon.png"
import groupsIcon from "../../../Assets/Images/groupsIcon.png"
import dropArrow from "../../../Assets/Images/chevron-down.png";



//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton"
import { AntSwitch } from "../../../Components/AppTools/AppTool"


export default function OktaPop({ providerDropVal, setProviderDropVal }) {
  const IntegrationDomainText = useRef(null);
  const IntegrationCodeText = useRef(null);
  const LoginText = useRef(null);

  const [oktaTabVal, setOktaTabVal] = useState(0)
  const [showNotification, setShowNotification] = useState();
  const [groupDrop, setGroupDrop] = useState(false)

  const handleCopyText = (p) => {
    const textToCopy = p === 0 ? IntegrationDomainText.current.innerText : p === 1 ? IntegrationCodeText.current.innerText : p === 2 ? LoginText.current.innerText : '';
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);

    setShowNotification(p);
    setTimeout(() => {
      setShowNotification(null);
    }, 2000);
  };


  const chackBoxRoom = () => {
    return (
      <Box className="checkBoxItem">
        <Box>
          <input type='checkBox' defaultChecked />
          <Typography>Northeast Lorem</Typography>
        </Box>
        <Box>
          <input type='checkBox' defaultChecked />
          <Typography>Southwest Ipsum</Typography>
        </Box>
        <Box>
          <input type='checkBox' defaultChecked />
          <Typography>Northeast Lorem</Typography>
        </Box>
      </Box>
    )
  }


  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setProviderDropVal(null);
    }
  };

  useEffect(() => {
    if (providerDropVal === "Okta") {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [providerDropVal]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: providerDropVal === "Okta" ? "flex" : "none" }}
        className="workspacePop oktaPopbackdorp"
      >
        <Box
          sx={{ display: providerDropVal === "Okta" ? "flex" : "none" }}
          className={oktaTabVal === 0 ? "workspaceContaint AppearancePop" : oktaTabVal === 1 ? "workspaceContaint CredentialsPop" : oktaTabVal === 2 ? "workspaceContaint ConfigurationPop" : "workspaceContaint"}
        >
          <Typography className='workspaceHeader'>Identity Provider : Okta</Typography>

          <Box className="workspaceSettingNav">
            <Box onClick={() => setOktaTabVal(0)} className={oktaTabVal === 0 ? "workSetNavTabe workSetNavTabeActive " : "workSetNavTabe"}>
              <Typography>Appearance</Typography>
            </Box>
            <Box onClick={() => setOktaTabVal(1)} className={oktaTabVal === 1 ? "workSetNavTabe workSetNavTabeActive " : "workSetNavTabe"}>
              <Typography>Credentials</Typography>
            </Box>
            <Box onClick={() => setOktaTabVal(2)} className={oktaTabVal === 2 ? "workSetNavTabe workSetNavTabeActive " : "workSetNavTabe"}>
              <Typography>Configuration</Typography>
            </Box>
          </Box>

          {/* AppearancePop */}
          <Box sx={{ display: oktaTabVal === 0 ? "block" : "none" }} className="oktaMainSection">
            <Box className="iBox">
              <img src={IIcon} />
              <Typography>Label and color the acording to make them easily recognizable for you.</Typography>
            </Box>

            <Box className="colorSelectorNavBox">
              <Box className="colorSelectorItem cs1">
                <Typography className='appearanceHeader'>Label</Typography>
                <Box height={"40px"} px={2} className="statusBox">
                  <Typography>Okta</Typography>
                </Box>
              </Box>
              <Box className="colorSelectorItem cs2">
                <Typography className='appearanceHeader'>Color Code</Typography>
                <Box px={2} height={"40px"} className="colorPicker">
                  <Box></Box>
                  <Typography>#4F33D1</Typography>
                </Box>
              </Box>
            </Box>

            <Box className="workPupBtnBox">
              <AppBtnOutLine buttonText="Cancel" onClick={() => setProviderDropVal(null)} />
              <AppBtnOutLine buttonText="Save" />
              <AppBtn buttonText="Next" onClick={() => setOktaTabVal(1)} />
            </Box>
          </Box>

          {/* Credentials pop */}
          <Box sx={{ display: oktaTabVal === 1 ? "block" : "none" }} className="oktaMainSection">
            <Box className="iBox">
              <img src={IIcon} />
              <Typography>For Detailed info, Please refer our <samp style={{ textDecoration: "underline" }}>knowledge center</samp></Typography>
            </Box>

            <Box className="credentialsItembox">
              <Typography className='credentialsItemLabel'>Integration Domain</Typography>
              <Box className="copybox copyboxGray">
                <Typography ref={IntegrationDomainText}>Platform.terapage.com</Typography>
                <img className='copyIcon' src={copyIcon} onClick={() => handleCopyText(0)} />
              </Box>
              {showNotification === 0 ? <div className='textcopyNoti'>Text copied!</div> : null}
            </Box>
            <Box className="credentialsItembox">
              <Typography className='credentialsItemLabel'>Integration Code</Typography>
              <Box className="copybox copyboxGray">
                <Typography ref={IntegrationCodeText}>JBVGJHV</Typography>
                <img className='copyIcon' src={copyIcon} onClick={() => handleCopyText(1)} />
              </Box>
              {showNotification === 1 ? <div className='textcopyNoti'>Text copied!</div> : null}
            </Box>
            <Box className="credentialsItembox">
              <Typography className='credentialsItemLabel'>Client ID<span>*</span></Typography>
              <Box className="copybox">
                <Typography>gsuj - sgbuigsb- 28268hgib - 9y9whins - w989 bjb. platform.terapage.co</Typography>
              </Box>
            </Box>
            <Box className="credentialsItembox">
              <Typography className='credentialsItemLabel'>Client Secret<span>*</span></Typography>
              <Box className="copybox">
                <Typography>gsuj - sgbuigsb- 28268hgib - 9y9whins</Typography>
              </Box>
            </Box>
            <Box className="credentialsItembox">
              <Typography className='credentialsItemLabel'>Okta Domain<span>*</span></Typography>
              <Box className="oktaDomanBox">
                <Box className="copybox">
                  <Typography>Platform.okta.com</Typography>
                </Box>
                <Box className="verifyBtn">
                  <Typography>Verify</Typography>
                </Box>
              </Box>
            </Box>
            <Box className="iBox greenIBox">
              <img src={GIIcon} />
              <Typography>Configuration endpoints successfully retrieved</Typography>
            </Box>
            <Box className="workPupBtnBox">
              <AppBtnOutLine buttonText="Cancel" onClick={() => setProviderDropVal(null)} />
              <AppBtnOutLine buttonText="Save" />
              <AppBtn buttonText="Next" onClick={() => setOktaTabVal(2)} />
            </Box>
          </Box>
          {/* Configuration pop */}
          <Box sx={{ display: oktaTabVal === 2 ? "block" : "none" }} className="oktaMainSection">
            <Box className="iBox">
              <img src={IIcon} />
              <Typography>Manage the limitations on using identity provider and define how unrecognized accounts would be handled</Typography>
            </Box>
            <Typography className='credentialsItemLabel'>Role Usage</Typography>
            <Typography>Select roles for which you want to allow identity provider services</Typography>
            <Box className="redioSwitchBox internalInputBox">
              <Box className="redioSItem">
                <AntSwitch defaultChecked />
                <Typography>Researchers</Typography>
              </Box>
              <Box className="redioSItem">
                <AntSwitch defaultChecked />
                <Typography>Collaborators</Typography>
              </Box>
              <Box className="redioSItem">
                <AntSwitch defaultChecked />
                <Typography>Observers</Typography>
              </Box>
              <Box className="redioSItem">
                <AntSwitch defaultChecked />
                <Typography>Participants</Typography>
              </Box>
            </Box>

            <Typography className='credentialsItemLabel'>Visibility</Typography>
            <Typography>Identity Provider will be visible on administrator’s login page. Copy & share link with administrators who are going to use identity providers.</Typography>
            <Box className="loginLinkBox">
              <Typography mb={1}>Login Link</Typography>
              <Box className="copybox copyboxGray">
                <Typography ref={LoginText}>Platform.terapage.com</Typography>
                <img className='copyIcon' src={copyIcon} onClick={() => handleCopyText(2)} />
              </Box>
              {showNotification === 2 ? <div className='textcopyNoti'>Text copied!</div> : null}
            </Box>
            <Box className="internalInputBox">
              <Box className="redioSItem">
                <AntSwitch defaultChecked />
                <Typography>Enable usage of identity providers to site visitors</Typography>
              </Box>
            </Box>

            <Typography mt={3} className='credentialsItemLabel'>Account Handling</Typography>
            <Typography mb={3}>Handle how unrecognized identity provider accounts will be handled </Typography>
            <Box className="internalInputBox">
              <Box className="redioSItem">
                <AntSwitch defaultChecked />
                <Typography>Match with existing accounts database email addresses</Typography>
              </Box>
              <Box className="redioSItem">
                <AntSwitch defaultChecked />
                <Typography>If no matching account exists, Add a terapage client account</Typography>
              </Box>
              <Box className="redioSItem">
                <AntSwitch defaultChecked />
                <Typography>Assign Groups</Typography>
              </Box>
            </Box>
            <Box className="internalInputBox">
              <Box className="searchGroupSection">
                <Box pl={4.5} className="inputAppDrop" onClick={() => setGroupDrop(!groupDrop)}>
                  <img className="groupsIcon" src={groupsIcon} />
                  <Typography>Search Groups</Typography>
                  <img
                    style={{ rotate: groupDrop ? "180deg" : "0deg" }}
                    src={dropArrow} />
                </Box>
                <Box sx={{ height: groupDrop ? "auto" : "0px" }} className="checkMain_box">
                  <Box className="ckeckBoxRoom">
                    {chackBoxRoom()}
                    {chackBoxRoom()}
                    {chackBoxRoom()}
                  </Box>
                  <Box className="propertyPagination">
                    <Box>
                      <p>1</p>
                    </Box>
                    <Box>
                      <p>2</p>
                    </Box>
                    <Box>
                      <p>3</p>
                    </Box>
                    <Box>
                      <p>4</p>
                    </Box>
                    <Box>
                      <p>...</p>
                    </Box>
                    <Box>
                      <p>8</p>
                    </Box>
                    <Box>
                      <p>9</p>
                    </Box>
                    <Box>
                      <p>10</p>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className="workPupBtnBox">
              <AppBtnOutLine buttonText="Cancel" onClick={() => setProviderDropVal(null)} />
              <AppBtn buttonText="Save" />
            </Box>

          </Box>
        </Box>
      </Box>
    </>
  )
}
