import React, { useEffect, /*useRef,*/ useState } from "react";
import { Box, Typography } from "@mui/material";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./style.css";
import { useTranslation } from "react-i18next";

// import arrow from "../../../Assets/Images/arrow.png";
import { SearchBar, GoTop } from "../../AppTools/AppTool";
// import { Workspace } from "../../../Assets/Data";
// import {
//   editIconBlack,
//   xCircleBlack,
//   archiveBlack,
//   trashBlack,
// } from "../../../Assets/Data";
import ToggleOnImage from "../../../Assets/Images/Toggle.png";
import ToggleOffImage from "../../../Assets/Images/Toggle2.png";
//components
// import EditWorkspacePop from "../EditWorkSpace";
import WorkspaceDeletePop from "../WorkSpaceDeletePop";
import axios from "../../../lib/axios";
import { /*useAppDispatch,*/ useAppSelector } from "../../../lib/store/store";
import Pagination from "../../AppTools/Pagination";
import { viewImage } from "../../../lib/helpers";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { updateWorkSpaceManagementApi } from "../../../Services/adminActions";
import DummyUser from "../../../Assets/Images/user-dummy.png";

export default function WorkspaceList({ setWorkspaceItem }) {
  // const [optionIndex, setOptionIndex] = useState();
  // const [workSpaceOption, setWorkspaceOption] = useState(false);

  // const [editWorkspace, setEditWorkspace] = useState(false);
  const [deleteWorkspace, setDeleteWorkspace] = useState(false);

  const [reportsPublication, setReportsPublication] = useState([]);
  const [projectAndComReport, setProjectAndComReport] = useState([]);
  const [workspaceReport, setWorkspaceReport] = useState([]);
  const [projectDataComp, setProjectDataComp] = useState([]);
  const [workspaceList, setWorkspaceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const authState = useAppSelector((state) => state.auth);
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  // const handleOptionIndex = (i) => {
  //   setOptionIndex(i);
  // };

  // let newArrayData;
  // function printItemsByState(array, state) {
  //   let startIndex = state * 8;
  //   let endIndex = (state + 1) * 8;
  //   let itemsToPrint = array.slice(startIndex, endIndex);
  //   if (itemsToPrint.length > 0) {
  //     newArrayData = itemsToPrint;
  //   } else {
  //     console.log("No items to print for the given state.");
  //   }
  // }

  // let pageNumver = 0;

  // const handleNext = () => {
  //   pageNumver = pageNumver + 1;
  //   printItemsByState(Workspace, pageNumver);
  // };

  // const handlePrev = () => {
  //   if (pageNumver < 0) {
  //     pageNumver = pageNumver - 1;
  //   }
  //   printItemsByState(Workspace, pageNumver);
  // };

  // printItemsByState(Workspace, pageNumver);

  // useEffect(() => {

  // }, []);
  //   Update toggleStat for setReportsPublication
  const handleReportsPublicationToggle = (wrkspaceId, index) => {
    setReportsPublication((prevToggleState) => {
      let newToggleState = [...prevToggleState];
      newToggleState[index] = !newToggleState[index];
      return newToggleState;
    });
    changeStatus(wrkspaceId, { research_publication_enable: !reportsPublication[index]})
  };
  //   Update toggleStat for projectAndComReport
  const handleProjectAndComReport = (wrkspaceId, index) => {
    setProjectAndComReport((prevToggleState) => {
      let newToggleState = [...prevToggleState];
      newToggleState[index] = !newToggleState[index];
      return newToggleState;
    });
    changeStatus(wrkspaceId, { project_community_report_eneble: !projectAndComReport[index] })
  };
  //   Update toggleStat for WorkspaceReport
  const handleWorkspaceReport = (wrkspaceId, index) => {
    setWorkspaceReport((prevToggleState) => {
      let newToggleState = [...prevToggleState];
      newToggleState[index] = !newToggleState[index];
      return newToggleState;
    });
    changeStatus(wrkspaceId, { workspace_report_eneble: !workspaceReport[index] })
  };
  //   Update toggleStat for projectDataComp
  const handleProjectDataComp = (wrkspaceId, index) => {
    setProjectDataComp((prevToggleState) => {
      let newToggleState = [...prevToggleState];
      newToggleState[index] = !newToggleState[index];
      return newToggleState;
    });
    changeStatus(wrkspaceId, { project_comparison_eneble: !projectDataComp[index] })
  };

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 1,
    totalPages: 0,
    totalItems: 0,
  });

  const defaultFilters = {
    search: "",
    sortBy: "created_at",
    sortOrder: -1,
    status: "",
  };

  useEffect(() => {
    getWorkSpaceList(1)
  }, []);

  const [filterState, setFilterState] = useState({ ...defaultFilters });

  const getWorkSpaceList = async (curPage, filters = filterState) => {
    setLoading(true);

    let url = `/super-admin/admin/workspaces?page=${curPage}`
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        url += `&${key}=${filters[key]}`
      }
    }

    let res = await axios.get(url);
    setLoading(false);
    if (res.status) {
      setWorkspaceList(res.data.data);
      // setting state for reportsPublication
      const initialState1 = res.data.data.map(
        (el) => el.research_publication_enable
      );
      setReportsPublication(initialState1);
      // setting state for projectAndComReport
      const initialState2 = res.data.data.map(
        (el) => el.project_community_report_eneble
      );
      console.log(initialState2);
      setProjectAndComReport(initialState2);

      // setting state for WorkspaceReport
      const initialState3 = res.data.data.map((el) => el.workspace_report_eneble);
      setWorkspaceReport(initialState3);

      // setting state for projectDataComp
      const initialState4 = res.data.data.map((el) => el.project_comparison_eneble);
      setProjectDataComp(initialState4);

      setPaginationData({
        currentPage: res.data.pagination.currentPage,
        itemsPerPage: res.data.pagination.itemsPerPage,
        totalPages: res.data.pagination.totalPages,
        totalItems: res.data.pagination.totalItems,
      });
    }
  };

  const handleFilterChange = (changedFilter) => {
    let newFilters = {
      ...filterState,
      ...changedFilter,
    };
    setFilterState(newFilters);
    getWorkSpaceList(1, newFilters);
  };

  const onPageChange = (pageNum) => {
    getWorkSpaceList(pageNum, filterState);
  };

  const changeStatus = async (wrkspaceId, payload) => {
    try {
      
      setLoading(true);
      let workSpaceRes = await updateWorkSpaceManagementApi(wrkspaceId, payload, authState.authToken);
      setLoading(false);
      if (workSpaceRes.success) {
        getWorkSpaceList(1);
        toast.success(workSpaceRes.message, TOAST_CONFIG);
      } else {
        toast.error(workSpaceRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  }

  return (
    <>
      <Box className="workspaceListBox ReportsPublicationBox">
        {/* <EditWorkspacePop
          setEditWorkspace={setEditWorkspace}
          editWorkspace={editWorkspace}
        /> */}
        <WorkspaceDeletePop
          deleteWorkspace={deleteWorkspace}
          setDeleteWorkspace={setDeleteWorkspace}
        />
        <Box className="workListNavItem workListNavItemMobile">
          <Typography className="pageHeaderText">
            {t("adminReportsAndPublications.allWorskapce")}
          </Typography>
        </Box>
        <Box className="workListNav">
          <Box className="workListNavItem workListNavItemDesktop">
            <Typography className="pageHeaderText">
              {t("adminReportsAndPublications.allWorskapce")}
            </Typography>
          </Box>
          <Box className="workListNavItem workListSearchBar">
            <SearchBar handleFilterChange={handleFilterChange} />
          </Box>
        </Box>

        <Box className="workListBox workListBoxRP">
          <Box className="haderRow" minWidth={"1120px"}>
            <Box minWidth={"27%"} className="Tabelsel navFsel TabelStatesel">
              <Typography>{t("commonTxt.workspaces")}</Typography>
            </Box>
            <Box minWidth={"18%"} className="Tabelsel">
              <Typography textAlign={"center"}>
                {t("adminReportsAndPublications.researchPublication")}
              </Typography>
            </Box>
            <Box minWidth={"18%"} className="Tabelsel">
              <Typography textAlign={"center"}>
                Project and <br /> Community Report
              </Typography>
            </Box>
            <Box minWidth={"18%"} className="Tabelsel">
              <Typography textAlign={"center"}>Workspace Report</Typography>
            </Box>
            <Box minWidth={"18%"} className="Tabelsel">
              <Typography textAlign={"center"}>
                Project Data Comparison
              </Typography>
            </Box>
          </Box>
          {workspaceList &&
            workspaceList?.map((el, index) => (
              <Box key={index} className="TabelRow" minWidth={"1120px"}>
                <Box minWidth={"27%"} className="Tabelsel navFsel">
                  {/* <Box
                    id="dot"
                    onClick={() => {
                      setWorkspaceOption(!workSpaceOption);
                      setOptionIndex(index);
                    }}
                    className={
                      workSpaceOption && index === optionIndex
                        ? "dot dotActive"
                        : "dot"
                    }
                  >
                    {
                      <Box
                        sx={{
                          display:
                            workSpaceOption && index === optionIndex
                              ? "flex"
                              : "none",
                        }}
                        className="optionPop"
                      >
                        <Box className="optionPopItem">
                          {editIconBlack}
                          <Typography
                            onClick={() => {
                              setEditWorkspace(true);
                              GoTop()
                            }}
                          >
                            Edit Workspace
                          </Typography>
                        </Box>
                        <Box className="optionPopItem">
                          {xCircleBlack}
                          <Typography>Close Workspace</Typography>
                        </Box>
                        <Box className="optionPopItem">
                          {archiveBlack}
                          <Typography>Archive Workspace</Typography>
                        </Box>
                        <Box
                          onClick={() => {
                            setDeleteWorkspace(true);
                            GoTop()
                          }}
                          className="optionPopItem"
                        >
                          {trashBlack}
                          <Typography>Delete Workspace</Typography>
                        </Box>
                      </Box>
                    }
                  </Box> */}
                  <Box
                    // onClick={() => handleOptionIndex(index)}
                    className="tabelAvatar"
                  >
                    {(el?.cover_image == 'undefined' || el?.cover_image == null) ? <img src={DummyUser} /> : <img src={viewImage(el?.cover_image)} />}
                    {/* <img src={viewImage(el.cover_image)} /> */}
                  </Box>
                  <Typography
                    // onClick={() => setWorkspaceItem(newArrayData[index])}
                    sx={{ cursor: "pointer" }}
                  >
                    {el.name}
                  </Typography>
                </Box>
                <Box
                  minWidth={"18%"}
                  className="Tabelsel toggleButton"
                  onClick={() => handleReportsPublicationToggle(el._id, index)}
                >
                  <img
                    src={
                      reportsPublication[index] ? ToggleOnImage : ToggleOffImage
                    }
                    alt="Toggle"
                  />{reportsPublication[index]}
                </Box>

                <Box
                  minWidth={"18%"}
                  className="Tabelsel toggleButton"
                  onClick={() => handleProjectAndComReport(el._id, index)}
                >
                  <img
                    src={
                      projectAndComReport[index]
                        ? ToggleOnImage
                        : ToggleOffImage
                    }
                    alt="Toggle"
                  />
                </Box>

                <Box
                  minWidth={"18%"}
                  className="Tabelsel toggleButton"
                  onClick={() => handleWorkspaceReport(el._id, index)}
                >
                  <img
                    src={
                      workspaceReport[index] ? ToggleOnImage : ToggleOffImage
                    }
                    alt="Toggle"
                  />
                </Box>

                <Box
                  minWidth={"18%"}
                  className="Tabelsel toggleButton"
                  onClick={() => handleProjectDataComp(el._id, index)}
                >
                  <img
                    src={
                      projectDataComp[index] ? ToggleOnImage : ToggleOffImage
                    }
                    alt="Toggle"
                  />
                </Box>
              </Box>
            ))}
        </Box>

        <Pagination
          paginationData={paginationData}
          onPageChange={onPageChange}
        />
      </Box>
    </>
  );
}
// New Branch
