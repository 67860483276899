import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./style.css";
//components
import { Status } from "../../AppTools/AppTool"
import { AppBtnOutLine, AppBtn } from "../../../Components/AppTools/AppButton"

export default function UpdateTimeZonePop({ updateTimeZonePop, setUpdateTimeZonePop }) {

  const [countryDrop, setCountryDrop] = useState(false);
  const [countryDropVal, setCountryDropVal] = useState("Select Country");
  const [timeZoneDrop, setTimeZoneDrop] = useState(false);
  const [timeZoneDropVal, setTimeZoneDropVal] = useState("Select Time Zone");
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const countries = [
    {
      name: "USA",
      value: "USA"
    },
    {
      name: "Canada",
      value: "Canada"
    },
    {
      name: "UK",
      value: "UK"
    },
    {
      name: "Australia",
      value: "Australia"
    },
    {
      name: "Japan",
      value: "Japan"
    },
    {
      name: "Brazil",
      value: "Brazil"
    },
    {
      name: "France",
      value: "France"
    },

    {
      name: "Germany",
      value: "Germany"
    },
    {
      name: "India",
      value: "India"
    },
    {
      name: "China",
      value: "China"
    },
  ];
  const timeZones = [
    {
      name: "UK, timeZone: UTC",
      value: "UK, timeZone: UTC"
    },
    {
      name: "Iran, timeZone: UTC+03:30",
      value: "Iran, timeZone: UTC+03:30"
    },
    {
      name: "India, timeZone: UTC+05:30",
      value: "India, timeZone: UTC+05:30"
    },
    {
      name: "Japan, timeZone: UTC+09:00",
      value: "Japan, timeZone: UTC+09:00"
    },
    {
      name: "Alaska, timeZone: UTC-09:00",
      value: "Alaska, timeZone: UTC-09:00"
    },
    {
      name: "Brazil, timeZone: UTC-03:00",
      value: "Brazil, timeZone: UTC-03:00"
    },
    {
      name: "Nepal, timeZone: UTC+05:45",
      value: "Nepal, timeZone: UTC+05:45"
    },
    {
      name: "New Zealand : UTC+12:00",
      value: "New Zealand : UTC+12:00"
    },
  ];

  const timeZones2 = [
    {
      name: "(GMT +9:00) Asia/Seoul",
      value: "(GMT +9:00) Asia/Seoul",
    },
    {
      name: "(GMT +9:00) Asia/Tokyo",
      value: "(GMT +9:00) Asia/Tokyo",
    },
    {
      name: "(GMT +9:00) Australia/Adelaide",
      value: "(GMT +9:00) Australia/Adelaide",
    },
    {
      name: "(GMT +9:00) Australia/Broken Hill",
      value: "(GMT +9:00) Australia/Broken Hill",
    },
    
  ];

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setUpdateTimeZonePop(false);
    }
  };

  useEffect(() => {
    if (updateTimeZonePop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [updateTimeZonePop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: updateTimeZonePop ? "flex" : "none" }}
        className="companyPop liveInterView"
      >
        <Box
          sx={{ display: updateTimeZonePop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className="livePHeader">{t("updateTimeZonePop.updateTimeZoneHead")}</Typography>
          <Typography mt={1} >{t("updateTimeZonePop.updateTimeZoneText")}</Typography>
          <Box className="deleteAccountBox">
            <Box mb={5} className="addressBox updateTimeZoneInterviewPop">
              <Typography className="companyInputLabel">{t("AccountSetting.Country")}</Typography>
              <Box height={"40px"}>
                <Status
                  setStatusDrop={setCountryDrop}
                  statusDrop={countryDrop}
                  setStatusDropVal={setCountryDropVal}
                  statusDropVal={countryDropVal}
                  label=" "
                  itemAry={countries}
                />
              </Box>
            </Box>

            <Box mb={5} className="addressBox updateTimeZoneInterviewPop">
              <Typography className="companyInputLabel">{t("TimeZonePage.TimeZone")}</Typography>
              <Box height={"40px"}>
                <Status
                  setStatusDrop={setTimeZoneDrop}
                  statusDrop={timeZoneDrop}
                  setStatusDropVal={setTimeZoneDropVal}
                  statusDropVal={timeZoneDropVal}
                  label=" "
                  itemAry={timeZones2}
                />
              </Box>
            </Box>
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => setUpdateTimeZonePop(false)} />
            <AppBtn buttonText={t("commonTxt.saveBtn")} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
