import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import "./style.css";

//images
import dropArrow from "../../../Assets/Images/chevron-down.png";

export default function ResponsesFilterPop({ setFilterPop, filterPop }) {
  const [activities, setActivities] = useState(true);
  const [conversations, setConversations] = useState(true);
  const [ageGroups, setAgeGroups] = useState(true);
  const [gender, setGender] = useState(false);

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setFilterPop(false);
    }
  };

  const ChipCard = [
    "All Activities",
    "Welcome!",
    "Your Great Outdoors",
    "Outdoor Activity Journal",
    "The Purchase Pathway",
    "Video & Product Reviews",
    "Archived Activities"
  ]

  useEffect(() => {
    if (filterPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [filterPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: filterPop ? "flex" : "none" }}
        className="companyPop responseFilterPop">
        <Box
          sx={{ display: filterPop ? "flex" : "none" }}
          className="workspaceContaint">
          <Typography className="blueHeader">Filter By Data Type</Typography>


          {/* Activities */}
          <Box mt={4} className="filterDropLabel">
            <Box onClick={() => setActivities(!activities)} className="dropRoundBox">
              <img style={{ rotate: activities ? "0deg" : "270deg" }} src={dropArrow} />
            </Box>
            <Typography onClick={() => setActivities(!activities)}>Activities</Typography>
          </Box>
          <Box className={activities ? "chipDropBoxActive" : "chipDropBox"} >
            {
              ChipCard?.map((el, i) => (
                <Box key={i} className="chipCard">
                  <Typography>{el}</Typography>
                </Box>
              ))
            }
          </Box>


          {/* Conversations */}
          <Box mt={8} className="filterDropLabel">
            <Box onClick={() => setConversations(!conversations)} className="dropRoundBox">
              <img style={{ rotate: conversations ? "0deg" : "270deg" }} src={dropArrow} />
            </Box>
            <Typography onClick={() => setConversations(!conversations)}>Conversations</Typography>
          </Box>
          <Box className={conversations ? "chipDropBoxActive" : "chipDropBox"} >
            {
              ChipCard?.map((el, i) => (
                <Box key={i} className="chipCard">
                  <Typography>{el}</Typography>
                </Box>
              ))
            }
          </Box>


          <Typography mt={5} className="blueHeader">Filter By Participants</Typography>

          <Box mt={4} className="filterDropLabel">
            <Box onClick={() => setAgeGroups(!ageGroups)} className="dropRoundBox">
              <img style={{ rotate: ageGroups ? "0deg" : "270deg" }} src={dropArrow} />
            </Box>
            <Typography onClick={() => setAgeGroups(!ageGroups)}>Age Groups</Typography>
          </Box>
          <Box className={ageGroups ? "chipDropBoxActive" : "chipDropBox"} >

            <Box className="dateCheckRow">
              <Box className="checkBoxWLable">
                <input type="checkBox" className="custom-checkbox" />
                <Typography>18 - 25 Years</Typography>
              </Box>

              <Box className="checkBoxWLable">
                <input type="checkBox" className="custom-checkbox" />
                <Typography>26 - 35 Years</Typography>
              </Box>
            </Box>

            <Box className="dateCheckRow">
              <Box className="checkBoxWLable">
                <input type="checkBox" className="custom-checkbox" />
                <Typography>36 - 45 Years</Typography>
              </Box>

              <Box className="checkBoxWLable">
                <input type="checkBox" className="custom-checkbox" />
                <Typography>45+ Years</Typography>
              </Box>
            </Box>

          </Box>

          {/* Gender */}
          <Box mt={8} className="filterDropLabel">
            <Box onClick={() => setGender(!gender)} className="dropRoundBox">
              <img style={{ rotate: gender ? "0deg" : "270deg" }} src={dropArrow} />
            </Box>
            <Typography onClick={() => setGender(!gender)}>Gender</Typography>
          </Box>
          <Box className={gender ? "chipDropBoxActive" : "chipDropBox"} >

            <Box className="chipCard">
              <Typography>Mail</Typography>
            </Box>
            <Box className="chipCard">
              <Typography>Female</Typography>
            </Box>

          </Box>





          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={() => setFilterPop(false)} />
            <AppBtn buttonText="Apply" />
          </Box>
        </Box>
      </Box>
    </>
  );
}
