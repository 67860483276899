import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//images
import Clogo from "../../../Assets/Images/Frame 243811.png";
//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";

export default function AgreementPop({
    acceptAgreementPop,
    setAcceptAgreementPop,
}) {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const Navigate = useNavigate();
  const [acceptAgreement, setAcceptAgreement] = useState(true);

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
        setAcceptAgreementPop(false);
    }
  };

  useEffect(() => {
    if (acceptAgreementPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [acceptAgreementPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: acceptAgreementPop ? "flex" : "none" }}
        className="companyPop addActivitiesPop"
      >
        <Box
          sx={{ display: acceptAgreementPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Box className="deleteTextBox">
            <img style={{ width: "212px",marginTop:"20px" }} src={Clogo} />
          </Box>
          <Typography className="deletePopHeader">
            Agreement
          </Typography>
          <Typography mt={2} className="agreNText">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</Typography>
          <Typography className="agreNSubHText">List of details we can acess</Typography>
          <Typography mt={2} className="agreNText">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</Typography>
          <Typography mt={2} className="agreNText">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Typography>
          <Typography mt={2} className="agreNText">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Typography>
        
          <Box className="agreementsItem">
            <Box className="agreItemNav">
            <Box>
                <Typography className="agrNavText1 termsText">
                Terms of Use
                </Typography>
                
            </Box>
            
            </Box>
            <Box
            ml={2}
            mt={2}
            mb={2}
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
            >
            <input
                style={{ marginRight: "10px" }}
                className="custom-checkbox"
                type="checkBox"
                onClick={() => setAcceptAgreement(!acceptAgreement)}
            />
            <Typography>
                I have read and agree to the <span>Terms of Use</span>
            </Typography>
            </Box>
        </Box>
        <Box className="workPupBtnBox">
            <AppBtn disabled={acceptAgreement ? true : false} buttonText={t("commonTxt.continueBtn")} onClick={() => setAcceptAgreementPop(false)} />
        </Box>
          
          
        </Box>
      </Box>
    </>
  );
}
