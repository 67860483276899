import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./style.css";

//images
import cott from "../../../Assets/Images/cott.png";
import groupIcon from "../../../Assets/Images/groupsIcon.png";
import filterIcon from "../../../Assets/Images/filterIcon.png";
import tp1 from "../../../Assets/Images/tp1.png";
import tp2 from "../../../Assets/Images/tp2.png";
import tp3 from "../../../Assets/Images/tp3.png";
import tp4 from "../../../Assets/Images/tp4.png";
import tp5 from "../../../Assets/Images/tp5.png";
import tp6 from "../../../Assets/Images/tp6.png";
import tp7 from "../../../Assets/Images/tp7.png";
import tp8 from "../../../Assets/Images/tp8.png";
import tp9 from "../../../Assets/Images/tp9.png";
import tp10 from "../../../Assets/Images/tp10.png";
import tp11 from "../../../Assets/Images/tp11.png";
import blackImg from "../../../Assets/Images/blankImg.png";
import idelDot from "../../../Assets/Images/idelDot.png";
import chevronDrop from "../../../Assets/Images/chevron-down.png";
import avatar1 from "../../../Assets/Images/inb1.png";
import avatar2 from "../../../Assets/Images/inb2.png";
import avatar3 from "../../../Assets/Images/inb3.png";
import editBtn from "../../../Assets/Images/edit 1.png";
import whitedropIcon from "../../../Assets/Images/Wchevron-down.png";
import semoji from "../../../Assets/Images/Semoji.png";
import imgIcon from "../../../Assets/Images/galleryIconB.png";
import emojiDislike from "../../../Assets/Images/emojiLike.png";
import emojiLike from "../../../Assets/Images/emojiDislike.png";
import emojiConfused from "../../../Assets/Images/emojiConfused.png";
import video from "../../../Assets/Images/Videos/part1.mp4";
import AdminBG from "../../../Assets/Images/Add-files-cuate.png";

//component
import EmojiPicker from "emoji-picker-react";
import { like, dislike, comment, tik } from "../../../Assets/Data";
import { AppBtn } from "../../AppTools/AppButton";
import ThreadFilterPop from "../../../Components/Researchers/ThreadFilterPop";
import AddExcerptPop from "../../../Components/Researchers/AddExcerptPop";

export default function Thread() {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const [providerDrop, setProviderDrop] = useState(false);
  const [providerDropVal, setProviderDropVal] = useState();
  const [EmojiDrop, setEmojiDrop] = useState(false);
  const [filterPop, setFilterPop] = useState(false);
  const [isTextSelected, setIsTextSelected] = useState(false);
  const [selectedTextIndex, setSelectedTextIndex] = useState();
  const [addExcerptPop, setAddExcerptPop] = useState(false);

  const threadData = true;
  const productBox = ({ img1, img2, img3, img4, title, subTitle }) => {
    return (
      <Box className="taskProductItem">
        <Typography>{title}</Typography>
        <Box my={1} className="productImgBox">
          <Box className="productImg">
            <img src={img1} />
          </Box>
          <Box className="productImg">
            <img src={img2} />
          </Box>
          <Box className="productImg">
            <img src={img3} />
          </Box>
          <Box className="productImg">
            <img src={img4} />
          </Box>
        </Box>
        <Typography>{subTitle}</Typography>
      </Box>
    );
  };

  const singelComment = ({ img, name, comment, date, time }) => {
    return (
      <Box className="singelComment">
        <Box className="commentSection innerCommentSection">
          <Box className="avatarBox">
            <img src={img} />
          </Box>
          <Box className="commentTextSection">
            <Box className="userNameBox">
              <Typography className="userName">{name}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "15px",
                  minWidth: "213px",
                }}
              >
                <Box className="editbtn">
                  <img src={editBtn} />
                  <Typography>{t("commonTxt.edit")}</Typography>
                </Box>
                <samp className="taskHeaderText3">{date}</samp>
              </Box>
            </Box>
            <Typography
              className="commentWtag"
              dangerouslySetInnerHTML={{ __html: wrapAtWordsWithSpan(comment) }}
            ></Typography>
            <Box className="likeBox">
              <Typography>{time}</Typography>
              <Typography>{t("commonTxt.Like")}</Typography>
              <Typography>{t("commonTxt.dislike")}</Typography>
              <Typography>{t("commonTxt.reply")}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  function wrapAtWordsWithSpan(text) {
    return text.replace(/@(\w+)/g, "<span>@$1</span>");
  }

  const writeCommentSection = ({ img }) => {
    return (
      <Box className="writeCommentSection">
        <Box className="avatarBox">
          <img src={img} />
        </Box>
        <Box className="commentInputBox">
          <input
            className="phoneInput"
            placeholder={t("commonTxt.addComment")}
          />
          <img
            className="inputIcon1"
            src={semoji}
            onClick={() => setEmojiDrop(!EmojiDrop)}
          />
          <img className="inputIcon2" src={imgIcon} />
          <Box className="commentCheckBox">
            <Box
              mb={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <input
                style={{ marginRight: "10px" }}
                className="custom-checkbox"
                type="checkBox"
              />
              <Typography>{t("recapThread.makeReplyCompulsory")}</Typography>
            </Box>

            <Typography className="followText">
              {t("emailPage.temp")}
            </Typography>
          </Box>
          <Box
            className="emojiBox2"
            sx={{ display: EmojiDrop ? "block" : "none" }}
          >
            <EmojiPicker />
          </Box>
        </Box>

        <Box
          className="directMessageNavBtn"
          onClick={() => setProviderDrop(!providerDrop)}
        >
          <Typography className="DropBtnText">
            {t("commonTxt.postBtn")}
          </Typography>
          <img
            src={whitedropIcon}
            style={{ rotate: providerDrop ? "180deg" : "0deg" }}
          />
          <Box
            className="workspaceItemNavPop"
            sx={{ display: providerDrop ? "flex" : "none" }}
          >
            <Box onClick={() => setProviderDropVal("Visible to Everyone")}>
              <Typography>{t("recapThread.visibleToEveryone")}</Typography>
            </Box>
            <Box onClick={() => setProviderDropVal("Only Andi Lane")}>
              <Typography>{t("recapThread.onlyAndiLane")}</Typography>
            </Box>
            <Box onClick={() => setProviderDropVal("Backroom Comment")}>
              <Typography>{t("recapThread.backroomComment")}</Typography>
            </Box>
            <Box onClick={() => setProviderDropVal("Backroom Task")}>
              <Typography>{t("recapThread.backroomTask")}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const SelectPop = ({ index }) => {
    return (
      <Box
        className="statusPop select_Pop"
        sx={{
          display:
            isTextSelected && selectedTextIndex === index ? "block" : "none",
        }}
      >
        <Box
          className="statusPopItem"
          onClick={() => {
            setIsTextSelected(false);
            setAddExcerptPop(true);
          }}
        >
          {cottIcon}
          <Typography>Save as Excerpt</Typography>
        </Box>
        <Box className="statusPopItem" onClick={() => setIsTextSelected(false)}>
          {searchIcon}
          <Typography>Search Project</Typography>
        </Box>
        <Box className="statusPopItem" onClick={() => setIsTextSelected(false)}>
          {earthIcon}
          <Typography>Google Search</Typography>
        </Box>
      </Box>
    );
  };

  const cottIcon = (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.11652 11.8008H5.90735C6.52303 11.8008 7.02368 11.2789 7.02368 10.6371V7.72786C7.02368 7.08606 6.52303 6.56417 5.90735 6.56417H2.55835V5.98233C2.55835 4.21775 3.93551 2.78217 5.62827 2.78217V2.20032C3.6278 2.20032 2.00019 3.89698 2.00019 5.98233V6.56417V6.85509V10.6371C2.00019 11.2789 2.50084 11.8008 3.11652 11.8008ZM6.46552 7.72786V10.6371C6.46552 10.9578 6.21504 11.2189 5.90735 11.2189H3.11652C2.80883 11.2189 2.55835 10.9578 2.55835 10.6371V7.14601H5.90735C6.21504 7.14601 6.46552 7.40712 6.46552 7.72786Z"
        fill="white"
        stroke="#475467"
        stroke-width="0.5"
      />
      <path
        d="M10.0928 11.8008H12.8837C13.4994 11.8008 14 11.2789 14 10.6371V7.72786C14 7.08606 13.4994 6.56417 12.8837 6.56417H9.53467V5.98233C9.53467 4.21775 10.9118 2.78217 12.6046 2.78217V2.20032C10.6041 2.20032 8.97651 3.89698 8.97651 5.98233V6.56417V6.85509V10.6371C8.97651 11.2789 9.47715 11.8008 10.0928 11.8008ZM13.4418 7.72786V10.6371C13.4418 10.9578 13.1914 11.2189 12.8837 11.2189H10.0928C9.78515 11.2189 9.53467 10.9578 9.53467 10.6371V7.14601H12.8837C13.1914 7.14601 13.4418 7.40712 13.4418 7.72786Z"
        fill="white"
        stroke="#475467"
        stroke-width="0.5"
      />
    </svg>
  );
  const searchIcon = (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 14L11.1 11.1M12.6667 7.33333C12.6667 10.2789 10.2789 12.6667 7.33333 12.6667C4.38781 12.6667 2 10.2789 2 7.33333C2 4.38781 4.38781 2 7.33333 2C10.2789 2 12.6667 4.38781 12.6667 7.33333Z"
        stroke="#475467"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
  const earthIcon = (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0C2.69143 0 0 2.69143 0 6C0 9.30857 2.69143 12 6 12C9.30857 12 12 9.30857 12 6C12 2.69143 9.30857 0 6 0ZM9.96 9.28286L9.45857 8.4C9.06429 7.71429 8.32714 7.28571 7.53857 7.28571H7.19143C6.53571 7.28571 6 7.82143 6 8.47714V8.94C6 9.12857 5.91857 9.31714 5.77714 9.44571L4.31571 10.7914C4.29857 10.8086 4.28143 10.8257 4.27286 10.8429C3.19714 10.4614 2.28857 9.72857 1.68 8.78571C2.49429 8.58 3.85714 8.05286 3.85714 6.85714C3.85714 6.51857 3.72857 6.19286 3.49714 5.94429C3.31714 5.75143 3.18429 5.61 3.09 5.50714C3.03 5.44286 3 5.36143 3 5.27143C3 4.5 2.57143 3.80571 1.88143 3.46286L1.60714 3.32571C2.51143 1.84714 4.14 0.857143 6 0.857143C6.52286 0.857143 7.02429 0.934286 7.5 1.08L7.01571 1.33714C5.82 1.94571 5.57143 2.71286 5.57143 3.24429V3.85714C5.57143 4.2 5.70429 4.52143 5.94857 4.76571C6.19286 5.01 6.51429 5.14286 6.85714 5.14286C7.18714 5.14286 7.51286 5.09143 7.82571 4.98429L8.51143 4.75714C8.59714 4.72714 8.68714 4.71429 8.78143 4.71429H9.32571C9.54857 4.71429 9.77143 4.80429 9.93 4.96714L11.1386 6.17571C11.1 7.35429 10.6629 8.43429 9.96 9.28286Z"
        fill="#475467"
      />
    </svg>
  );

  useEffect(() => {
    const handleSelectionChange = () => {
      const selectedText = window.getSelection().toString();
      if (selectedText) {
        setIsTextSelected(true);
      } else {
        setIsTextSelected(false);
      }
    };

    document.addEventListener("selectionchange", handleSelectionChange);

    return () => {
      document.removeEventListener("selectionchange", handleSelectionChange);
    };
  }, []);

  return (
    <>
      {!threadData ? (
        <Box className="threadNoDataPage">
          <Box className="adminWorkSBG">
            <img src={AdminBG} />
          </Box>
          <Box className="adminBGTextBox">
            <Typography className="adminSubHeaderText">
              {t("recapThread.recapThreadText")}
            </Typography>
            <AppBtn buttonText={t("commonTxt.goToActivities")} />
          </Box>
        </Box>
      ) : (
        <Box className="threadContainer workspaceListBox">
          <AddExcerptPop
            addExcerptPop={addExcerptPop}
            setAddExcerptPop={setAddExcerptPop}
          />
          <ThreadFilterPop setFilterPop={setFilterPop} filterPop={filterPop} />
          <Box className="threadNavBox">
            <Box className="actTNavBtn">
              <img src={cott} />
              <Typography>{t("commonTxt.excerptBtn")}</Typography>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <Box className="actTNavBtn">
                <img src={groupIcon} />
                <Typography>{t("commonTxt.groupBtn")}</Typography>
              </Box>

              <Box className="filterBtn" onClick={() => setFilterPop(true)}>
                <img src={filterIcon} />
                <p>{t("commonTxt.filters")}</p>
              </Box>
            </Box>
          </Box>

          <Box className="commentSection">
            <Box className="avatarBox">
              <img src={avatar2} />
              <img className="statusDot" src={idelDot} />
            </Box>

            <Box className="commentTextSection paddingNone">
              <Box pt={2} px={3} className="taskBoxTopDate">
                <Typography className="userNameWspm">
                  Daina Henry
                  <span>completed the activity</span>
                  <samp>Your Great Outdoors</samp>
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <samp className="taskHeaderText3">Aug 04, 2023 02:21 PM</samp>
                </Box>
              </Box>

              <Box className="graySBox taskBox">
                <Box className="taskBoxTopDate">
                  <Typography className="taskHeaderText">
                    Task 1 Name
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <Typography className="taskHeaderText2">
                      {t("commonTxt.viewBtn")}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  className="selectedText"
                  onClick={() => setSelectedTextIndex("0")}
                  mb={3}
                >
                  Which of these outdoor activities do you participate in?
                  Please select all that apply and explain why these activities
                  are the outdoor activities for you.
                  <SelectPop index="0" />
                </Typography>

                <Box ml={5} mb={3}>
                  <ul style={{ marginBottom: "10px" }}>
                    <li>Camping</li>
                    <li>Hiking</li>
                  </ul>
                  <span
                    className="selectedText"
                    onClick={() => setSelectedTextIndex("1")}
                  >
                    All you need is a back pack, tent and some boots - nothing
                    big or complicated!
                    <SelectPop index="1" />
                  </span>
                </Box>
                <Box className="taskBoxBottomRow">
                  <Box className="taskBoxBottomRowItem">
                    {like}
                    <Typography>{t("commonTxt.Like")}</Typography>
                  </Box>
                  <Box className="taskBoxBottomRowItem">
                    {dislike}
                    <Typography>{t("commonTxt.dislike")}</Typography>
                  </Box>
                  <Box className="taskBoxBottomRowItem">
                    {comment}
                    <Typography>{t("commonTxt.comment")}</Typography>
                  </Box>
                  <Box className="taskBoxBottomRowItem">
                    {tik}
                    <Typography>{t("commonTxt.review")}</Typography>
                  </Box>
                </Box>
              </Box>

              <Box className="graySBox taskBox">
                <Box className="taskBoxTopDate">
                  <Typography className="taskHeaderText">
                    Task 2 Name
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <Typography className="taskHeaderText2">
                      {t("commonTxt.viewBtn")}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  onClick={() => setSelectedTextIndex("2")}
                  className="selectedText"
                  mb={3}
                >
                  Select the Outdoor Essentials and sort them into the following
                  categories.
                  <SelectPop index="2" />
                </Typography>

                <Box className="taskProductBox">
                  {productBox({
                    img1: tp1,
                    img2: tp2,
                    img3: tp3,
                    img4: tp4,
                    title: "Absolutely Needed",
                    subTitle: "+2 more",
                  })}
                  {productBox({
                    img1: tp5,
                    img2: tp6,
                    img3: tp7,
                    img4: tp8,
                    title: "Nice to Have",
                    subTitle: "+5 more",
                  })}
                  {productBox({
                    img1: tp9,
                    img2: tp10,
                    img3: tp11,
                    img4: blackImg,
                    title: "Forgettable",
                    // subTitle: "+2 more"
                  })}
                </Box>

                <Box className="taskBoxBottomRow">
                  <Box className="taskBoxBottomRowItem">
                    {like}
                    <Typography>{t("commonTxt.Like")}</Typography>
                  </Box>
                  <Box className="taskBoxBottomRowItem">
                    {dislike}
                    <Typography>{t("commonTxt.dislike")}</Typography>
                  </Box>
                  <Box className="taskBoxBottomRowItem">
                    {comment}
                    <Typography>{t("commonTxt.comment")}</Typography>
                  </Box>
                  <Box className="taskBoxBottomRowItem">
                    {tik}
                    <Typography>{t("commonTxt.review")}</Typography>
                  </Box>
                </Box>
              </Box>

              <Box className="graySBox taskBox">
                <Box className="taskBoxTopDate">
                  <Typography className="taskHeaderText">
                    Task 3 Name
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <Typography className="taskHeaderText2">
                      {t("commonTxt.viewBtn")}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  onClick={() => setSelectedTextIndex("3")}
                  className="selectedText"
                  mb={3}
                >
                  Which of these outdoor activities do you participate in?
                  Please select all that apply and explain why these activities
                  are the outdoor activities for you.
                  <SelectPop index="3" />
                </Typography>
                <Typography
                  ml={5}
                  my={3}
                  sx={{
                    color: "#706C80",
                  }}
                >
                  No response added yet. Data will be visible once the
                  participant responds.
                </Typography>
                <Box className="taskBoxBottomRow">
                  <Box className="taskBoxBottomRowItem">
                    {like}
                    <Typography>{t("commonTxt.Like")}</Typography>
                  </Box>
                  <Box className="taskBoxBottomRowItem">
                    {dislike}
                    <Typography>{t("commonTxt.dislike")}</Typography>
                  </Box>
                  <Box className="taskBoxBottomRowItem">
                    {comment}
                    <Typography>{t("commonTxt.comment")}</Typography>
                  </Box>
                  <Box className="taskBoxBottomRowItem">
                    {tik}
                    <Typography>{t("commonTxt.review")}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="commentSection">
            <Box className="avatarBox">
              <img src={avatar2} />
              <img className="statusDot" src={idelDot} />
            </Box>

            <Box className="commentTextSection">
              <Box mb={5} className="taskBoxTopDate">
                <Typography className="userNameWspm">
                  Kate Morrison
                  <span>completed the task</span>
                  <samp>Outdoor Activit</samp>
                  <span> under the activity</span>
                  <samp>Your Great Outdoors</samp>
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <Typography className="taskHeaderText2">
                    {t("commonTxt.viewBtn")}
                  </Typography>
                  <samp className="taskHeaderText3">Aug 04, 2023 02:21 PM</samp>
                </Box>
              </Box>
              <Typography
                onClick={() => setSelectedTextIndex("4")}
                className="selectedText"
              >
                Please review the following Video. As you watch the video, pause
                te video to leave a comment with an associated tag type or just
                start typing your comment and the video will pause
                automatically.
                <SelectPop index="4" />
              </Typography>

              <Box className="commentImg">
                <Box className="postImgBox">
                  <video className="video activeVideoItem" controls>
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </Box>

                <Box className="postImgLikeBox">
                  <Box className="postLikeRow">
                    <Box>
                      {" "}
                      <img src={emojiLike} />
                      <Typography className="likeHeader">Like:</Typography>
                      <Typography>That is such a lovely location</Typography>
                    </Box>
                    <span>00:10</span>
                  </Box>

                  <Box className="postLikeRow">
                    <Box>
                      {" "}
                      <img src={emojiConfused} />
                      <Typography className="likeHeader">Confused:</Typography>
                      <Typography>The water looks so cold</Typography>
                    </Box>
                    <span>00:15</span>
                  </Box>

                  <Box className="postLikeRow">
                    <Box>
                      {" "}
                      <img src={emojiLike} />
                      <Typography className="likeHeader">Like:</Typography>
                      <Typography>
                        This is seriously the best place to chill
                      </Typography>
                    </Box>
                    <span>00:20</span>
                  </Box>

                  <Box className="postLikeRow">
                    <Box>
                      {" "}
                      <img src={emojiDislike} />
                      <Typography className="likeHeader">Dislike:</Typography>
                      <Typography>
                        Wish there where more trees over here
                      </Typography>
                    </Box>
                    <span>00:25</span>
                  </Box>
                  <Typography className="taskHeaderText2">
                    {t("commonTxt.viewMore")}
                  </Typography>
                </Box>
              </Box>

              <Box mt={3} className="taskBoxBottomRow">
                <Box className="taskBoxBottomRowItem">
                  {like}
                  <Typography>{t("commonTxt.Like")}</Typography>
                </Box>
                <Box className="taskBoxBottomRowItem">
                  {dislike}
                  <Typography>{t("commonTxt.dislike")}</Typography>
                </Box>
                <Box className="taskBoxBottomRowItem">
                  {comment}
                  <Typography>{t("commonTxt.comment")}</Typography>
                </Box>
                <Box className="taskBoxBottomRowItem">
                  {tik}
                  <Typography>{t("commonTxt.review")}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="commentSection">
            <Box className="avatarBox">
              <img src={avatar2} />
              <img className="statusDot" src={idelDot} />
            </Box>

            <Box className="commentTextSection">
              <Box mb={5} className="taskBoxTopDate">
                <Typography className="userNameWspm">
                  Andi Lane
                  <span>posted on the topic</span>
                  <samp>Your Trekking Must Haves</samp>
                  <span>of</span>
                  <samp>Equipment Zone</samp>
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <Typography className="taskHeaderText2">
                    {t("commonTxt.viewBtn")}
                  </Typography>
                  <samp className="taskHeaderText3">Aug 04, 2023 02:21 PM</samp>
                </Box>
              </Box>

              <Typography
                onClick={() => setSelectedTextIndex("5")}
                className="selectedText"
              >
                There are a few things everybody must carry while trekking to
                have a safe and sound trip. This includes lorem, ipsum, dolor,
                sit, amet, conseque. There are many more things that can be of
                help while trekking, but these are the absolute essentials
                according to me.
                <SelectPop index="5" />
              </Typography>

              <Box mt={3} className="taskBoxBottomRow">
                <Box className="taskBoxBottomRowItem">
                  {like}
                  <Typography>{t("commonTxt.Like")}</Typography>
                </Box>
                <Box className="taskBoxBottomRowItem">
                  {dislike}
                  <Typography>{t("commonTxt.dislike")}</Typography>
                </Box>
                <Box className="taskBoxBottomRowItem">
                  {comment}
                  <Typography>{t("commonTxt.comment")}</Typography>
                </Box>
                <Box className="taskBoxBottomRowItem">
                  {tik}
                  <Typography>{t("commonTxt.review")}</Typography>
                </Box>
              </Box>

              <Box className="CommentBOx">
                <Box className="viewLessBox">
                  <Typography>{t("commonTxt.viewiewLessComments")}</Typography>
                  <img src={chevronDrop} />
                </Box>

                {singelComment({
                  img: avatar3,
                  name: "Richard Han",
                  comment:
                    "Hey @everyone, what other items according to you can be used to avoid injuries while trekking?",
                  date: "Aug 10, 2023",
                  time: "10 h",
                })}
                {singelComment({
                  img: avatar1,
                  name: "Orlando Diggs",
                  comment:
                    "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                  date: "Aug 10, 2023",
                  time: "10 h",
                })}
                {singelComment({
                  img: avatar3,
                  name: "Kate Morrison",
                  comment:
                    "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                  date: "Aug 10, 2023",
                  time: "10 h",
                })}
                {writeCommentSection({ img: avatar1 })}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
