import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Avatar,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import "./style.css";
import DummyUser from "../../../Assets/Images/user-dummy.png";
import clogsvg from "../../../Assets/Images/New folder/Icon TeraPage Transparent mix dark 2.svg";
import SClolo from "../../../Assets/Images/TeraPage Transparent mix black.png";
import NotificationsIcon from "../../../Assets/Images/bell.png";
import DirectMessageIcon from "../../../Assets/Images/AdArrowIcon.png";
import workSpaceIcon from "../../../Assets/Images/workspaceMenuIcon.png";
import HomeIcon from "../../../Assets/Images/home.png";
import ActivitiesIcon from "../../../Assets/Images/map.png";
import ConversationsIcon from "../../../Assets/Images/conversations.png";
import RecapIcon from "../../../Assets/Images/recap.png";
import OutdoorJourneyIcon from "../../../Assets/Images/outdoorjourney.png";
import AvatarOne from "../../../Assets/Images/UserHomeAvatar.png";
import ASIcon from "../../../Assets/Images/icon-setting02.svg";
import HCIcon from "../../../Assets/Images/icon-setting03.svg";
import LIcon from "../../../Assets/Images/icon-setting04.svg";
import { useAppDispatch, useAppSelector } from "../../../lib/store/store";
import { logout } from "../../../Services/auth";
import axios from "./../../../lib/axios";
//Data

//components
import AgreementPop from "../AgreementPop";
import PendingScreenQuestionPop from "../PendingScreenQuestionPop";
import { setSelectedProject } from "../../../lib/store/features/workspaceSlice";
import { viewImage } from "../../../lib/helpers";

export default function SideMenu({ slideMenu, workspaceDrop }) {
  const currentUrl = new URL(window.location.href).pathname;
  const Navigate = useNavigate();
  const [profilePop, setProfilePop] = useState(false);
  let dispatch = useAppDispatch();
  let authState = useAppSelector((state) => state.auth);
  const [acceptAgreementPop, setAcceptAgreementPop] = useState(false);
  const [pendingQuestionPop, setPendingQuestionPop] = useState(false);
  const [screeningToken, setScreeningToken] = useState(null);
  const workspaceState = useAppSelector((state) => state.workspace);
  const [projectData, setProjectData] = useState([]);
  const [projectDropVal, setProjectDropVal] = useState("");

  //console.log("===The value of authState :", authState.userProfile);

  // console.log("===The value of ProjectData ids:", ids);
  let ids;

  useEffect(() => {
    ids = authState.userProfile.project_ids;

    getParticipantProjects();
  }, []);

  const handleMainOpenTabe = (Url) => {
    Navigate(Url);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "200px",
        overflowY: "auto",
      },
    },
  };

  useEffect(() => {
    if (currentUrl === "/participants/wrkspaceHome") {
      setAcceptAgreementPop(true);
    } else if (currentUrl === "/participants/activities") {
      checkScreeningQuestion();
    }
  }, []);

  // check pending screning question
  const checkScreeningQuestion = async () => {
    const response = await axios.get(
      `/super-admin/admin/participants/check-pending-screening-question`
    );
    if (response.success && !response.data.isCompleted) {
      setScreeningToken(response.data.token);
      setPendingQuestionPop(true);
    }
  };

  const gotoScreeningPage = () => {
    if (screeningToken) {
      Navigate(
        `/projects-invitations?token=${screeningToken}&isScreening=true`
      );
    } else if (currentUrl === "/participants/activities") {
      setPendingQuestionPop(true);
    }
  };

  const getParticipantProjects = async () => {
    axios
      .get(`/participant/projects?ids=${ids.join(",")}`)
      .then((response) => setProjectData(response.data.data))
      .catch((error) => console.error("Error fetching documents:", error));
  };

  useEffect(() => {
    if (projectData.length > 0) {
      const firstProjectId = projectData[0]?.id || projectData[0]?._id; // Fallback to _id if id is undefined
      console.log("Setting projectDropVal to:", firstProjectId); // Log the value being set
      setProjectDropVal(firstProjectId);
    }
  }, [projectData]);

  // console.log("The value of projectDropVal", projectDropVal);

  const handleSelectChange = (event) => {
    const project = projectData.find((el) => el._id == event.target.value);
    dispatch(setSelectedProject(project));
    setProjectDropVal(event.target.value);
  };

  return (
    <>
      <Box
        className={
          slideMenu
            ? "sideMenu researcherSideMenu sideMenuActive"
            : "sideMenu researcherSideMenu"
        }
      >
        <AgreementPop
          acceptAgreementPop={acceptAgreementPop}
          setAcceptAgreementPop={setAcceptAgreementPop}
        />
        <PendingScreenQuestionPop
          pendingQuestionPop={pendingQuestionPop}
          setPendingQuestionPop={setPendingQuestionPop}
          gotoScreeningPage={gotoScreeningPage}
        />
        <Box onClick={() => Navigate("/")} className="clogBox">
          {slideMenu ? (
            <img style={{ width: "176.442px", height: "20px" }} src={clogsvg} />
          ) : (
            <img style={{ width: "30px" }} src={SClolo} />
          )}
        </Box>

        <Box
          onClick={() => {
            handleMainOpenTabe("/notification");
          }}
          className={
            currentUrl === "/notification"
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
          title={slideMenu ? "" : "Notifications"}
        >
          <img src={NotificationsIcon} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Notifications
          </Typography>
        </Box>

        <Box
          onClick={() => {
            handleMainOpenTabe("/direct-messages");
          }}
          className={
            currentUrl === "/direct-messages"
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
          title={slideMenu ? "" : "Direct Message"}
        >
          <img src={DirectMessageIcon} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Direct Message
          </Typography>
        </Box>

        <Box
          sx={{ display: authState.userProfile.project_ids ? "block" : "none" }}
          className="navHrLine"
        >
          {projectDropVal != null && (
            <Box
              sx={{ display: workspaceDrop ? "flex" : "none" }}
              className="subNavMenu pointer"
            >
              <Box sx={{ width: "85%" }} title={slideMenu ? "" : ""}>
                <FormControl fullWidth>
                  <InputLabel id="project-select-label">
                    Select Project
                  </InputLabel>
                  <Select
                    labelId="project-select-label"
                    id="project-select"
                    className="projectListSelect"
                    onChange={handleSelectChange}
                    value={projectDropVal}
                    MenuProps={MenuProps}
                    label="Select Project"
                  >
                    {projectData.map((project, index) => (
                      <MenuItem value={project._id} key={index}>
                        {project?.image ? (
                          <>
                            <img
                              src={viewImage(project?.image)}
                              className="projectListImage"
                              alt={project.name}
                            />
                            {/* Temporarily display the URL for debugging */}
                            {/* <span>{viewImage(project?.image)}</span> */}
                          </>
                        ) : (
                          <img src={OutdoorJourneyIcon} />
                        )}
                        {project.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box
                onClick={() => {
                  handleMainOpenTabe("/participants/wrkspaceHome");
                }}
                className={
                  currentUrl === "/participants/wrkspaceHome"
                    ? "AdSideMenuItem AdSideMenuItemActive"
                    : "AdSideMenuItem"
                }
                title={slideMenu ? "" : "Home"}
              >
                <img src={HomeIcon} />
                <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                  Home
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  handleMainOpenTabe("/participants/activities");
                }}
                className={
                  currentUrl === "/participants/activities"
                    ? "AdSideMenuItem AdSideMenuItemActive"
                    : "AdSideMenuItem"
                }
                title={slideMenu ? "" : "Activities"}
              >
                <img src={ActivitiesIcon} />
                <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                  Activities
                </Typography>
              </Box>
              <Box
                onClick={() =>
                  handleMainOpenTabe("/participants/conversations")
                }
                className={
                  currentUrl === "/participants/conversations"
                    ? "AdSideMenuItem AdSideMenuItemActive"
                    : "AdSideMenuItem"
                }
                title={slideMenu ? "" : "Conversations"}
              >
                <img src={ConversationsIcon} />
                <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                  Conversations
                </Typography>
              </Box>
              <Box
                onClick={() => handleMainOpenTabe("/participants/recap")}
                className={
                  currentUrl === "/participants/recap"
                    ? "AdSideMenuItem AdSideMenuItemActive"
                    : "AdSideMenuItem"
                }
                title={slideMenu ? "" : "Recap"}
              >
                <img src={RecapIcon} />
                <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                  Recap
                </Typography>
              </Box>
            </Box>
          )}
        </Box>

        <Box
          onClick={() => setProfilePop(!profilePop)}
          className={slideMenu ? "setting " : "setting settingOnClose"}
          title={slideMenu ? "" : "Setting"}
        >
          <Avatar
            alt="Remy Sharp"
            src={AvatarOne}
            sx={{ width: 34, height: 34 }}
          />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Settings
          </Typography>

          {/* Profile pop */}
          <Box
            sx={{ display: profilePop ? "flex" : "none" }}
            className="sideProfilePop"
          >
            <Box className="profilePopR">
              <Box className="imgPP">
                {/* <img src={AvatarOne} /> */}
                {authState?.profile_pic == "undefined" ||
                authState?.profile_pic == null ? (
                  <img src={DummyUser} />
                ) : (
                  <img src={viewImage(authState?.profile_pic)} />
                )}
              </Box>
              <Box className="profileInfoP">
                <Typography className="ProNP">
                  {authState?.userProfile?.name}
                </Typography>
                <Typography className="ProEP">
                  {authState?.userProfile?.email}
                </Typography>
              </Box>
            </Box>
            <Box
              className="sidePPitem"
              onClick={() => Navigate("/account-settings/personal-information")}
            >
              <img src={ASIcon} />
              <Typography>Account Settings</Typography>
            </Box>
            <Box className="sidePPitem">
              <img src={HCIcon} />
              <Typography>Help Center</Typography>
            </Box>
            <Box
              className="sidePPitem"
              onClick={() =>
                logout(Navigate, dispatch, authState?.userType, authState)
              }
            >
              <img src={LIcon} />
              <Typography>Logout</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
