import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { TOAST_CONFIG } from "../../../lib/constants";
import { toast } from "react-toastify";
import { deleteActivities } from "../../../Services/adminActions";
import { useAppSelector } from "../../../lib/store/store";
import axios from "../../../lib/axios";
//images

export default function DeleteTaskPop({ deleteTaskPop, setDeleteTaskPop, setReloadList, taskId, taskName }) {

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const [loading, setLoading] = useState(false);
  let authState = useAppSelector(state => state.auth);
 
  const handelPopClose = () => {
    setDeleteTaskPop(false);
  }
  

  const handleDelete = () => {
    setDeleteTaskPop(false);
      return new Promise((resolve, reject) => {
          axios.delete(`/researchers/tasks/${taskId}`).then((res) => {
              if (res.success) {
                setReloadList(true);
                  resolve({
                      success: true,
                      message: res.message
                  });
                  toast.success(res.message, TOAST_CONFIG);
              } else {
                  reject({
                      success: false,
                      message: res.message
                  });
                  toast.success(res.message, TOAST_CONFIG);
              }
          }).catch((err) => {
              reject({
                  success: false,
                  message: err.message
              });
          })
      });
  };

  useEffect(() => {
    if (deleteTaskPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [deleteTaskPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: deleteTaskPop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople"
        >
        <Box
            sx={{ display: deleteTaskPop ? "flex" : "none" }}
            className="workspaceContaint deletePagePop"
        >
            <Typography className="companyHeader">Delete Task</Typography>

            <Box className="basicBoxItem">
            <Typography className='addPageLabel'>Are you sure you want to delete the task titled {`“${taskName}”`} ? Once deleted this task will not be visible to anybody.</Typography>
            
            </Box>
            
            <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => setDeleteTaskPop(false)} />
            <AppBtn buttonText={t("commonTxt.deleteBtn")} onClick={handleDelete} />
            </Box>
        </Box>
        </Box>
    </>
  );
}
