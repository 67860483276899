import { Box, Typography } from "@mui/material";
import { sideHaumIcon } from "../../../Assets/Data";
import { useAppSelector } from "../../../lib/store/store";
import { useState } from "react";
import SideMenu from "../../../Components/Participants/SideMenu";
import SideMenuAccountSettings from "../../../Components/User/SideMenu";
import { USER_TYPE_PARTICIPANT } from "../../../lib/constants";
import useAuth from "../../../hooks/useAuth";
// import { sideHaumIcon } from "../../../Assets/Data";

const ParticipantLayout = ({ workspaceSideMenu, childComponent, actionBtn }) => {
  workspaceSideMenu = workspaceSideMenu || 'main';
  let authState = useAppSelector((state) => state.auth);
  const [slideMenu, setSlideMenu] = useState(true);
  const [workspaceDrop, setWorkSpaceDrop] = useState(true);

  useAuth([USER_TYPE_PARTICIPANT]);

  return (
    <Box className="ResearchersContainer">
      {/* side menu */}
      {workspaceSideMenu == 'main' && <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop}
        setWorkSpaceDrop={setWorkSpaceDrop} />}
      {workspaceSideMenu == 'accountsetting' && <SideMenuAccountSettings
        slideMenu={slideMenu}
        setSlideMenu={setSlideMenu}
        workspaceDrop={workspaceDrop}
        setWorkSpaceDrop={setWorkSpaceDrop}
      />}
      <Box className="adminMainSection">
        <Box className="adminNavBar">
          <Box
            onClick={() => setSlideMenu(!slideMenu)}
            sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
            className="sideHaumIcon"
          >
            {sideHaumIcon}
          </Box>
          {(authState?.is_support == true) && <Typography sx={{ backgroundColor: '#fd9843', padding: '15px', borderRadius: 2 }}>Support login for {`${authState?.userProfile?.name}`}</Typography>}
          {actionBtn}
        </Box>
        {/* Page container */}
        {childComponent}
      </Box>
    </Box>
  );
};

export default ParticipantLayout;
