import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Images
import whitedropIcon from "../../../Assets/Images/Wchevron-down.png";

//components 
import { AppBtn, AppBtnOutLine } from '../../../Components/AppTools/AppButton';
import ProjectTranscriptActivities from '../ProjectTranscriptActivities';
import ProjectTranscriptConversation from '../ProjectTranscriptConversation';
import ProjectTranscriptMessage from '../ProjectTranscriptMessage';
import ProjectTranscriptQuantitative from '../ProjectTranscriptQuantitative';

export default function ProjectSettingTranscript({ dataReportTabe, setDataReportTabe }) {

    const [projectSettingTranscriptDrop, setProjectSettingTranscriptDrop] = useState(false);
    const [projectSettingTranscriptDropVal, setProjectSettingTranscriptDropVal] = useState("Activities");
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        t,
        i18n: { changeLanguage, language },
    } = useTranslation();

    useEffect(() => {
        let selTab = searchParams.get("pt");
        let selTab2 = searchParams.get("dr");
        if (selTab && selTab2 != null) {
          var query_string = selTab;
          query_string = query_string.replaceAll('_', ' ');
          query_string = query_string.replace(/(^\w|\s\w)/g, firstCharOfWord => firstCharOfWord.toUpperCase());
          console.log(query_string);
        setProjectSettingTranscriptDropVal(query_string);
        setDataReportTabe(parseInt(selTab2));
        }
        else if (selTab == null) {
          setDataReportTabe(parseInt(selTab2));
        }
      }, []);

    return (
        <>
            <Box className="researchersMainBox pageContainer">
                <Box className="integrationsContainer">
                    <Box className="projectBtnBox">
                        <Box className="inteBtndropBox" >

                            <Box className="projectManagementNavBtn" onClick={() => setProjectSettingTranscriptDrop(!projectSettingTranscriptDrop)}>
                                <Typography className='DropBtnText'>{projectSettingTranscriptDropVal || t("projectSettingTranscript.projectSettingTranscriptDropPlaceholder")}</Typography>
                                <img className='dropIcon' src={whitedropIcon} style={{ rotate: projectSettingTranscriptDrop ? "180deg" : "0deg", transition: "all 0.5s", marginLeft: "10px" }} />
                                <Box className="workspaceItemNavPop projectItemNavPop" sx={{ display: projectSettingTranscriptDrop ? "flex" : "none" }}>
                                    <Box 
                                        onClick={() => {
                                            setSearchParams({ dr:dataReportTabe,pt: "activities" });
                                            setProjectSettingTranscriptDropVal("Activities");
                                          }}
                                    >
                                        <Typography>{t("projectSettingTranscript.projectSettingTranscriptDropVal1")}</Typography>
                                    </Box>
                                    <Box 
                                        onClick={() => {
                                            setSearchParams({ dr:dataReportTabe,pt: "conversations" });
                                            setProjectSettingTranscriptDropVal("Conversations");
                                          }}
                                    >
                                        <Typography>{t("projectSettingTranscript.projectSettingTranscriptDropVal2")}</Typography>
                                    </Box>
                                    <Box 
                                        onClick={() => {
                                            setSearchParams({ dr:dataReportTabe,pt: "messages" });
                                            setProjectSettingTranscriptDropVal("Messages");
                                          }}
                                    >
                                        <Typography>{t("projectSettingTranscript.projectSettingTranscriptDropVal3")}</Typography>
                                    </Box>
                                    <Box 
                                        onClick={() => {
                                            setSearchParams({ dr:dataReportTabe,pt: "quantitative" });
                                            setProjectSettingTranscriptDropVal("Quantitative");
                                          }}
                                    >
                                        <Typography>{t("projectSettingTranscript.projectSettingTranscriptDropVal4")}</Typography>
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                    <Typography className='pageHeaderText'>{t("projectSettingTranscript.projectSettingTranscriptHead")}</Typography>
                    {projectSettingTranscriptDropVal === "Activities" ? (<ProjectTranscriptActivities projectSettingTranscriptDropVal={projectSettingTranscriptDropVal} />) : projectSettingTranscriptDropVal === "Conversations" ? (<ProjectTranscriptConversation projectSettingTranscriptDropVal={projectSettingTranscriptDropVal} />) : projectSettingTranscriptDropVal === "Messages" ? (<ProjectTranscriptMessage projectSettingTranscriptDropVal={projectSettingTranscriptDropVal} />) : projectSettingTranscriptDropVal === "Quantitative" ? (<ProjectTranscriptQuantitative projectSettingTranscriptDropVal={projectSettingTranscriptDropVal} />) : null
                    }
                </Box>
            </Box>
        </>
    )
}
