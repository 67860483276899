import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { useNavigate } from "react-router-dom";
import "./style.css";

//images
import backArrow from "../../../Assets/Images/noun-back.png";
import cott from "../../../Assets/Images/cott.png";
import star from "../../../Assets/Images/Star 2.png";
import iIcon from "../../../Assets/Images/IIcon.png";
import idelDot from "../../../Assets/Images/idelDot.png";
import postImg from "../../../Assets/Images/postImg.png";
import editBtn from "../../../Assets/Images/edit 1.png";
import whitedropIcon from "../../../Assets/Images/Wchevron-down.png";
import semoji from "../../../Assets/Images/Semoji.png";
import imgIcon from "../../../Assets/Images/galleryIconB.png";
import groupIcon from "../../../Assets/Images/groupsIcon.png";
import avatar1 from "../../../Assets/Images/inb1.png"
import avatar2 from "../../../Assets/Images/inb2.png"
import avatar3 from "../../../Assets/Images/inb3.png"
import exportIcon from "../../../Assets/Images/exportIcon.png";
import dropIcon from "../../../Assets/Images/chevron-down.png";
import Option2LIcon from "../../../Assets/Images/Option2LIcon.png";
import editIcon from "../../../Assets/Images/edit 1.png";






//component
import SideMenu from "../../../Components/Participants/SideMenu";
import { useTranslation } from "react-i18next";
import { SearchBar, LatestCreated } from "../../../Components/AppTools/AppTool"
import BubbleChart from "../../../Components/AppTools/BubbleChart"
import { ApexChart } from "../../../Components/AppTools/PieChart";
import { BarChart } from "../../../Components/AppTools/BarChart"
import StackedBar2 from "../../../Components/AppTools/StackedBar2"
import {
  like,
  dislike,
  comment,
  tik,
  sideHaumIcon,
  arrowIcon
} from "../../../Assets/Data";


export default function ViewConversationTopic({ slideMenu, setSlideMenu, workspaceDrop, setWorkSpaceDrop }) {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [nav, setNav] = useState(0);
  const [nav3, setNav3] = useState(0)
  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Recently Added");
  const [latestCDrop2, setLatestDrop2] = useState(false);
  const [latestDropVal2, setLatestDropVal2] = useState("Recently Added");
  const [latestCDrop3, setLatestDrop3] = useState(false);
  const [latestDropVal3, setLatestDropVal3] = useState("Recently Added");
  const [providerDrop, setProviderDrop] = useState(false);
  const [providerDropVal, setProviderDropVal] = useState();
  const [exportDrop, setExportDrop] = useState(false);
  const [exportDrop2, setExportDrop2] = useState(false);
  const [exportDrop3, setExportDrop3] = useState(false);
  const [exportDrop4, setExportDrop4] = useState(false);
  const [exportDrop5, setExportDrop5] = useState(false);


  // const responseArrya = [
  //   "Order Posted",
  //   "Recent Replies",
  //   "Most Liked",
  //   "Most Comments",
  //   "Recent Liked",
  //   "Recent Comments",
  //   "Reviewed Posts",
  // ];
  const responseArrya = [
    {
      label: "Order Posted",
    },
    {
      label: "Recent Replies",
    },
    {
      label: "Most Liked",
    },
    {
      label: "Most Comments",
    },
    {
      label: "Recent Liked",
    },
    {
      label: "Recent Comments",
    },
    {
      label: "Reviewed Posts",
    },
  ];
  const exportItem = ["PNG", "JPEG", "PDF", "SVG"];

  const [series] = useState([{ data: [400, 430, 448, 470, 540] }]);
  const categories = ['Product Reviews', 'Price', 'Brand Reputation', 'Durabilty/Quality', 'Aesthetics'];

  const [series2] = useState([{ data: [400, 430, 448, 470, 540] }]);
  const categories2 = ['Relaxation', 'Great Quote', 'Nostalgia', 'Empowerment'];


  function wrapAtWordsWithSpan(text) {
    return text.replace(/@(\w+)/g, "<span>@$1</span>");
  }

  const singelComment = ({ img, name, comment, date, time }) => {
    return (
      <Box className="singelComment">
        <Box className="commentSection innerCommentSection">
          <Box className="avatarBox">
            <img src={img} />
          </Box>
          <Box className="commentTextSection">
            <Box className="userNameBox">
              <Typography className="userName">{name}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "15px",
                  minWidth: "213px",
                }}
              >
                <Box className="editbtn">
                  <img src={editBtn} />
                  <Typography>Edit</Typography>
                </Box>
                <samp className="taskHeaderText3">{date}</samp>
              </Box>
            </Box>

            <Typography
              className="commentWtag"
              dangerouslySetInnerHTML={{ __html: wrapAtWordsWithSpan(comment) }}
            ></Typography>
            <Box className="likeBox">
              <Typography>{time}</Typography>
              <Typography>{t("commonTxt.Like")}</Typography>
              <Typography>{t("commonTxt.Dislike")}</Typography>
              <Typography>{t("commonTxt.Reply")}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const writeCommentSection = ({ img }) => {
    return (
      <Box className="writeCommentSection">
        <Box className="avatarBox">
          <img src={img} />
        </Box>
        <Box className="commentInputBox">
          <input className="phoneInput" placeholder="Add comment" />
          <img className="inputIcon1" src={semoji} />
          <img className="inputIcon2" src={imgIcon} />
          <Box className="commentCheckBox">
            <Box
              mb={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <input
                style={{ marginRight: "10px" }}
                className="custom-checkbox"
                type="checkBox"
              />
              <Typography>{t("viewMessageProfile.viewMessageProfileCheckbox")}</Typography>
            </Box>

            <Typography className="followText">{t("emailPage.temp")}</Typography>
          </Box>
        </Box>

        <Box
          className="directMessageNavBtn"
          onClick={() => setProviderDrop(!providerDrop)}
        >
          <Typography className="DropBtnText">{t("Conversations.post")}</Typography>
          <img
            src={whitedropIcon}
            style={{ rotate: providerDrop ? "180deg" : "0deg" }}
          />
          <Box
            className="workspaceItemNavPop"
            sx={{ display: providerDrop ? "flex" : "none" }}
          >
            <Box onClick={() => setProviderDropVal("Visible to Everyone")}>
              <Typography>{t("Conversations.VisibleEveryone")}</Typography>
            </Box>
            <Box onClick={() => setProviderDropVal("Only Andi Lane")}>
              <Typography>{t("Conversations.OnlyAndLane")}</Typography>
            </Box>
            <Box onClick={() => setProviderDropVal("Backroom Comment")}>
              <Typography>{t("Conversations.BackroomComment")}</Typography>
            </Box>
            <Box onClick={() => setProviderDropVal("Backroom Task")}>
              <Typography>{t("Conversations.BackroomTask")}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const nestedCommentSection = ({ img, name, comment, date, time }) => {
    return (
      <Box className="singelComment">
        <Box className="commentSection innerCommentSection">
          <Box className="avatarBox">
            <img src={img} />
          </Box>
          <Box className="commentTextSection">
            <Box className="userNameBox">
              <Typography className="userName">{name}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "15px",
                  minWidth: "213px",
                }}
              >
                <Box className="editbtn">
                  <img src={editBtn} />
                  <Typography>Edit</Typography>
                </Box>
                <samp className="taskHeaderText3">{date}</samp>
              </Box>
            </Box>

            <Typography
              className="commentWtag"
              dangerouslySetInnerHTML={{ __html: wrapAtWordsWithSpan(comment) }}
            ></Typography>
            <Box className="likeBox">
              <Typography>{time}</Typography>
              <Typography>{t('commonTxt.Like')}</Typography>
              <Typography>{t('commonTxt.Dislike')}</Typography>
              <Typography>{t('commonTxt.Reply')}</Typography>
            </Box>
            {singelComment({
              img: avatar3,
              name: "Kate Morrison",
              comment:
                "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
              date: "Aug 10, 2023",
              time: "10 h",
            })}
            {writeCommentSection({ img: avatar1 })}
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box className="ResearchersContainer viewConversatin">
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
          </Box>
          {/* Page container */}
          <Box className="pageContainer">
            <Box className="workSpaceListBox  viewTopicBox">
              <Box className="openBtn">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    Navigate("/participants/conversations")
                  }
                >
                  <img src={backArrow} />
                  <Typography className="neonBackText">{t("commonTxt.viewBtn")}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "24px",
                  }}
                >
                  <Box className="actTNavBtn">
                    <img src={cott} />
                    <Typography>{t("commonTxt.excerptBtn")}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="tpcHeaderBox">
                <Box className="">
                  <Box className="pageTitleBox">
                    <img src={star} />
                    <Typography>Equipment Zone</Typography>
                  </Box>
                  <Typography className="eqText">{t("Conversations.TotalTopics")}:5</Typography>
                </Box>
                <Box>
                  <Typography className="followText">{t("Conversations.FollowTopic")}</Typography>
                </Box>
              </Box>

              <Typography className="viewTopicNtext">{t("Conversations.ThisShould")}</Typography>
              <Typography className="viewTopicNtext">{t("Conversations.summery4")}</Typography>
              <Typography className="viewTopicNtext">{t("Conversations.summery5")}</Typography>
              <Box className="iBox">
                <img src={iIcon} />
                <Typography>{t("Conversations.summery6")}</Typography>
              </Box>

              <Box className="togelSection">
                <Box className="topcVNav">
                  <Box
                    onClick={() => setNav(0)}
                    className={
                      nav === 0 ? "topcNavBtn topcNavBtnActive" : "topcNavBtn"
                    }
                  >
                    <Typography>{t("Conversations.AllPosts")}</Typography>
                  </Box>
                  {/* <Box
                    onClick={() => setNav(1)}
                    className={
                      nav === 1 ? "topcNavBtn topcNavBtnActive" : "topcNavBtn"
                    }
                  >
                    <Typography>{t("Conversations.WordCloud")}</Typography>
                  </Box>
                  <Box
                    onClick={() => setNav(2)}
                    className={
                      nav === 2 ? "topcNavBtn topcNavBtnActive" : "topcNavBtn"
                    }
                  >
                    <Typography>{t("Conversations.AIAnalysis")}</Typography>
                  </Box> */}
                </Box>



                {/* All Posts section */}
                <Box
                  sx={{ display: nav === 0 ? "block" : "none" }}
                  className="togelSubBox"
                >
                  <Box className="postSectionNav">
                    <Typography sx={{ textDecoration: "underline",cursor:"pointer" }}>{t("Conversations.MarkReviewed")}</Typography>
                    <Box sx={{ display: "flex", gap: "20px" }}>
                      <Box width={"40%"} className="agreSearchB2">
                        <SearchBar placeholder="Search here" />
                      </Box>
                      {/* <Box className="actTNavBtn">
                        <img src={groupIcon} />
                        <Typography>{t("projectDirectory.tblGroups")}</Typography>
                      </Box> */}

                      <Box width={"32%"} minWidth={"170px"} className="agreSearchB2">
                        <LatestCreated
                          setLatestDrop={setLatestDrop}
                          latestCDrop={latestCDrop}
                          setLatestDropVal={setLatestDropVal}
                          latestDropVal={latestDropVal}
                          itemAry={responseArrya}
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box className="commentSection">
                    <Box className="avatarBox">
                      <img src={avatar2} />
                      <img className="statusDot" src={idelDot} />
                    </Box>

                    <Box className="commentTextSection">
                      <Box className="userNameBox">
                        <Typography className="userName">Kate Morrison</Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "15px",
                          }}
                        >
                          <Typography className="taskHeaderText2">{t("commonTxt.viewBtn")}</Typography>
                          <samp className="taskHeaderText3">
                            Aug 04, 2023 02:21 PM
                          </samp>
                        </Box>
                      </Box>
                      <Typography>{t("Conversations.sleepingText")} </Typography>

                      <Box className="commentSection innerCommentSection">
                        <Box className="avatarBox">
                          <img src={avatar1} />
                        </Box>
                        <Box className="commentTextSection">
                          <Box className="userNameBox">
                            <Typography className="userName">Daina Henry</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "15px",
                              }}
                            >
                              <Typography className="taskHeaderText2">
                                {t("commonTxt.viewBtn")}
                              </Typography>
                              <samp className="taskHeaderText3">
                                Aug 04, 2023 02:21 PM
                              </samp>
                            </Box>
                          </Box>

                          <Typography>{t("Conversations.thisExtemely")}</Typography>
                          <Box className="imgPostBox">
                            <img src={postImg} />
                          </Box>
                        </Box>
                      </Box>

                      <Box className="taskBoxBottomRow">
                        <Box className="taskBoxBottomRowItem">
                          {like}
                          <Typography>{t("workspaceHome.Like")}</Typography>
                        </Box>
                        <Box className="taskBoxBottomRowItem">
                          {dislike}
                          <Typography>{t("workspaceHome.Dislike")}</Typography>
                        </Box>
                        <Box className="taskBoxBottomRowItem">
                          {comment}
                          <Typography>{t("Conversations.Comment")}</Typography>
                        </Box>
                        <Box className="taskBoxBottomRowItem">
                          {tik}
                          <Typography>{t("Conversations.Review")}</Typography>
                        </Box>
                      </Box>

                      {nestedCommentSection({
                        img: avatar1,
                        name: "Orlando Diggs",
                        comment:
                          "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                        date: "Aug 10, 2023",
                        time: "10 h",
                      })}
                      {singelComment({
                        img: avatar2,
                        name: "Richard Han",
                        comment: "Hey @sam, do have a look this particular post. ",
                        date: "Aug 10, 2023",
                        time: "10 h",
                      })}
                      {singelComment({
                        img: avatar3,
                        name: "Kate Morrison",
                        comment:
                          "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                        date: "Aug 10, 2023",
                        time: "10 h",
                      })}

                      {writeCommentSection({ img: avatar1 })}
                    </Box>
                  </Box>

                  {/* section2 */}
                  <Box className="commentSection">
                    <Box className="avatarBox">
                      <img src={avatar2} />
                      <img className="statusDot" src={idelDot} />
                    </Box>

                    <Box className="commentTextSection">
                      <Box className="userNameBox">
                        <Typography className="userName">Kate Morrison</Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "15px",
                          }}
                        >
                          <Typography className="taskHeaderText2">{t("commonTxt.viewBtn")}</Typography>
                          <samp className="taskHeaderText3">
                            Aug 04, 2023 02:21 PM
                          </samp>
                        </Box>
                      </Box>
                      <Typography>{t("Conversations.sleepingText")}</Typography>

                      <Box className="commentSection innerCommentSection">
                        <Box className="avatarBox">
                          <img src={avatar1} />
                        </Box>
                        <Box className="commentTextSection">
                          <Box className="userNameBox">
                            <Typography className="userName">Daina Henry</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "15px",
                              }}
                            >
                              <Typography className="taskHeaderText2">
                                {t("commonTxt.viewBtn")}
                              </Typography>
                              <samp className="taskHeaderText3">
                                Aug 04, 2023 02:21 PM
                              </samp>
                            </Box>
                          </Box>

                          <Typography>{t("Conversations.thisExtemely")}</Typography>
                        </Box>
                      </Box>

                      <Box className="taskBoxBottomRow">
                        <Box className="taskBoxBottomRowItem">
                          {like}
                          <Typography>{t("commonTxt.Like")}</Typography>
                        </Box>
                        <Box className="taskBoxBottomRowItem">
                          {dislike}
                          <Typography>{t("commonTxt.Dislike")}</Typography>
                        </Box>
                        <Box className="taskBoxBottomRowItem">
                          {comment}
                          <Typography>{t("Conversations.Comment")}</Typography>
                        </Box>
                        <Box className="taskBoxBottomRowItem">
                          {tik}
                          <Typography>{t("Conversations.Review")}</Typography>
                        </Box>
                      </Box>

                      {singelComment({
                        img: avatar3,
                        name: "Richard Han",
                        comment:
                          "Hey @everyone, what other items according to you can be used to avoid injuries while trekking?",
                        date: "Aug 10, 2023",
                        time: "10 h",
                      })}
                      {singelComment({
                        img: avatar1,
                        name: "Orlando Diggs",
                        comment:
                          "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                        date: "Aug 10, 2023",
                        time: "10 h",
                      })}
                      {nestedCommentSection({
                        img: avatar1,
                        name: "Orlando Diggs",
                        comment:
                          "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                        date: "Aug 10, 2023",
                        time: "10 h",
                      })}
                      {singelComment({
                        img: avatar3,
                        name: "Kate Morrison",
                        comment:
                          "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                        date: "Aug 10, 2023",
                        time: "10 h",
                      })}
                      {writeCommentSection({ img: avatar1 })}
                    </Box>
                  </Box>
                </Box>



                {/*---------------- Word Cloud section---------- */}
                {/* <Box
                  sx={{ display: nav === 1 ? "block" : "none" }} className="togelSubBox">
                  <Box className="postSectionNav">
                    <Typography sx={{ textDecoration: "underline" }}>
                      {t("conversation.aiNavLabel1")}
                    </Typography>
                    <Box sx={{ display: "flex", gap: "20px" }}>
                      <Box width={"40%"} className="agreSearchB2">
                        <SearchBar placeholder="Search here" />
                      </Box>
                      <Box className="actTNavBtn">
                        <img src={groupIcon} />
                        <Typography>{t("projectDirectory.tblGroups")}</Typography>
                      </Box>
                      <Box width={"32%"} minWidth={"170px"} className="agreSearchB2">
                        <LatestCreated
                          setLatestDrop={setLatestDrop2}
                          latestCDrop={latestCDrop2}
                          setLatestDropVal={setLatestDropVal2}
                          latestDropVal={latestDropVal2}
                          itemAry={responseArrya}
                        />
                      </Box>
                    </Box>
                  </Box>

                </Box> */}



                {/*---------------- AI Analysis section---------- */}
                {/* <Box
                  sx={{ display: nav === 2 ? "block" : "none" }} className="togelSubBox">

                  <Box className="postSectionNav">
                    <Typography sx={{ textDecoration: "underline" }}>{t("conversation.aiNavLabel1")}</Typography>
                    <Box sx={{ display: "flex", gap: "20px" }}>
                      <Box width={"40%"} className="agreSearchB2">
                        <SearchBar placeholder="Search here" />
                      </Box>
                      <Box className="actTNavBtn">
                        <img src={groupIcon} />
                        <Typography>{t("projectDirectory.tblGroups")}</Typography>
                      </Box>

                      <Box width={"32%"} minWidth={"170px"} className="agreSearchB2">
                        <LatestCreated
                          setLatestDrop={setLatestDrop3}
                          latestCDrop={latestCDrop3}
                          setLatestDropVal={setLatestDropVal3}
                          latestDropVal={latestDropVal3}
                          itemAry={responseArrya}
                        />
                      </Box>
                    </Box>
                  </Box>



                  <Box mt={4} className="aiSubSection">
                    <Box className="tabelNav">
                      <Typography className="pageTitle">{t("conversation.pieLabel")}</Typography>
                      <Box
                        className="exporOpenBtn"
                        onClick={() => setExportDrop(!exportDrop)}
                      >
                        <img src={exportIcon} />
                        <Typography>{t("commonTxt.exportLink")}</Typography>
                        <img
                          src={dropIcon}
                          style={{ rotate: exportDrop ? "180deg" : "0deg" }}
                        />
                        <Box
                          className="statusPop"
                          sx={{ height: exportDrop ? "auto" : "0px" }}
                        >
                          {exportItem?.map((el, i) => (
                            <Box className="statusPopItem">
                              <p>{el}</p>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>

                    <Box className="pieBox ApexChart">
                      <ApexChart />
                    </Box>

                    <Box className="chartTableNav">
                      <Box className="chartNavItem">
                        <Typography>{t("commonTxt.hide")}</Typography>
                      </Box>
                      <Box className="chartNavItem">
                        <Typography>{t("commonTxt.Group")}</Typography>
                      </Box>
                      <Box className="chartNavItem">
                        <Typography>{t("commonTxt.ChangeColor")}</Typography>
                      </Box>
                    </Box>

                    <Box mb={6} className="workListBox">
                      <Box className="haderRow" minWidth={"955px"}>
                        <Box minWidth={"20%"} className="Tabelsel TabelStatesel">
                          <Typography>{t("commonTxt.Actions")}</Typography>
                        </Box>
                        <Box minWidth={"15%"} className="Tabelsel">
                          <Typography>{t("commonTxt.Color")}</Typography>
                        </Box>
                        <Box minWidth={"30%"} className="Tabelsel TabelStatesel">
                          <Typography>{t("commonTxt.Tag")}</Typography>
                        </Box>

                        <Box minWidth={"13%"} className="Tabelsel">
                          <Typography>{t("commonTxt.Responses")}</Typography>
                        </Box>
                        <Box minWidth={"13%"} className="Tabelsel">
                          <Typography>{t("commonTxt.Percentage")}</Typography>
                        </Box>
                      </Box>

                      <Box className="TabelRow" minWidth={"955px"}>
                        <Box
                          minWidth={"20%"}
                          gap={"40px"}
                          className="Tabelsel navFsel navtable"
                        >
                          <img src={Option2LIcon} />
                          <input type="checkBox" className="custom-checkbox" />
                        </Box>
                        <Box minWidth={"15%"} className="Tabelsel Tabelcentersel">
                          <Box
                            className="colorBox"
                            sx={{ background: "#21A1F9" }}
                          ></Box>
                        </Box>
                        <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                          <img src={editIcon} />
                          <Typography>😍</Typography>
                          <Typography>{t("commonTxt.Like")}</Typography>
                        </Box>
                        <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                          <Typography>18</Typography>
                        </Box>
                        <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                          <Typography>58.2%</Typography>
                        </Box>
                      </Box>

                      <Box className="TabelRow" minWidth={"955px"}>
                        <Box
                          minWidth={"20%"}
                          gap={"40px"}
                          className="Tabelsel navFsel navtable"
                        >
                          <img src={Option2LIcon} />
                          <input type="checkBox" className="custom-checkbox" />
                        </Box>
                        <Box minWidth={"15%"} className="Tabelsel Tabelcentersel">
                          <Box
                            className="colorBox"
                            sx={{ background: "#FF4E72" }}
                          ></Box>
                        </Box>
                        <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                          <img src={editIcon} />
                          <Typography>😟</Typography>
                          <Typography>{t("commonTxt.Dislike")}</Typography>
                        </Box>
                        <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                          <Typography>05</Typography>
                        </Box>
                        <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                          <Typography>18.5%</Typography>
                        </Box>
                      </Box>

                      <Box className="TabelRow" minWidth={"955px"}>
                        <Box
                          minWidth={"20%"}
                          gap={"40px"}
                          className="Tabelsel navFsel navtable"
                        >
                          <img src={Option2LIcon} />
                          <input type="checkBox" className="custom-checkbox" />
                        </Box>
                        <Box minWidth={"15%"} className="Tabelsel Tabelcentersel">
                          <Box
                            className="colorBox"
                            sx={{ background: "#FFBE28" }}
                          ></Box>
                        </Box>
                        <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                          <img src={editIcon} />
                          <Typography>🤨</Typography>
                          <Typography>{t("Conversations.Confused")}</Typography>
                        </Box>
                        <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                          <Typography>08</Typography>
                        </Box>
                        <Box minWidth={"13%"} className="Tabelsel Tabelcentersel">
                          <Typography>23.3%</Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box className="tabelNav">
                      <Typography className="pageTitle">{t("conversation.BubbleChartLabel")}</Typography>
                      <Box
                        className="exporOpenBtn"
                        onClick={() => setExportDrop2(!exportDrop2)}
                      >
                        <img src={exportIcon} />
                        <Typography>{t("commonTxt.Export")}</Typography>
                        <img
                          src={dropIcon}
                          style={{ rotate: exportDrop2 ? "180deg" : "0deg" }}
                        />
                        <Box
                          className="statusPop"
                          sx={{ height: exportDrop2 ? "auto" : "0px" }}
                        >
                          {exportItem?.map((el, i) => (
                            <Box className="statusPopItem">
                              <p>{el}</p>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                    <Box mb={5} className="bubbleChartBox">
                      <BubbleChart />
                    </Box>


                    <Box className="tabelNav">
                      <Typography className="pageTitle">{t("conversation.lineChart1Label")}</Typography>
                      <Box
                        className="exporOpenBtn"
                        onClick={() => setExportDrop3(!exportDrop3)}
                      >
                        <img src={exportIcon} />
                        <Typography>{t("commonTxt.Export")}</Typography>
                        <img
                          src={dropIcon}
                          style={{ rotate: exportDrop3 ? "180deg" : "0deg" }}
                        />
                        <Box
                          className="statusPop"
                          sx={{ height: exportDrop3 ? "auto" : "0px" }}
                        >
                          {exportItem?.map((el, i) => (
                            <Box className="statusPopItem">
                              <p>{el}</p>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>

                    <Box mb={5} className="barChart">
                      <BarChart series={series} categories={categories} />
                    </Box>

                    <Box className="tabelNav">
                      <Typography className="pageTitle">{t("conversation.lineChart2Label")}</Typography>
                      <Box
                        className="exporOpenBtn"
                        onClick={() => setExportDrop4(!exportDrop4)}
                      >
                        <img src={exportIcon} />
                        <Typography>{t("commonTxt.Export")}</Typography>
                        <img
                          src={dropIcon}
                          style={{ rotate: exportDrop4 ? "180deg" : "0deg" }}
                        />
                        <Box
                          className="statusPop"
                          sx={{ height: exportDrop4 ? "auto" : "0px" }}
                        >
                          {exportItem?.map((el, i) => (
                            <Box className="statusPopItem">
                              <p>{el}</p>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>

                    <Box className="barChart">
                      <BarChart series={series2} categories={categories2} />
                    </Box>

                    <Box className="bottomTabel">

                      <Box className="tabeSection">
                        <Box className="recpSectionNav">
                          <Box
                            onClick={() => setNav3(0)}
                            className={
                              nav3 === 0
                                ? "recpSectionNavItem recpSectionNavItemActive"
                                : "recpSectionNavItem"
                            }
                          >
                            <Typography>{t("commonTxt.Manual")}</Typography>
                          </Box>
                          <Box
                            onClick={() => setNav3(1)}
                            className={
                              nav3 === 1
                                ? "recpSectionNavItem recpSectionNavItemActive"
                                : "recpSectionNavItem"
                            }
                          >
                            <Typography>{t("commonTxt.ExcerptsAI")}</Typography>
                          </Box>
                        </Box>
                      </Box>


                      <Box sx={{ display: nav3 === 0 ? "block" : "none" }}>
                        <Box className="tabelNav">
                          <Typography className="pageTitle">{t("conversation.lineChart2Label")}</Typography>
                          <Box width={"320px"}><SearchBar placeholder="Search" /></Box>
                        </Box>


                        <Box mb={6} className="workListBox">
                          <Box className="haderRow" minWidth={"955px"}>
                            <Box minWidth={"10%"} className="Tabelsel TabelStatesel">
                              <Typography>{t("commonTxt.Uses")}</Typography>
                            </Box>
                            <Box minWidth={"17%"} className="Tabelsel TabelStatesel">
                              <Typography>{t("commonTxt.Codes")}</Typography>
                            </Box>
                            <Box minWidth={"40%"} className="Tabelsel TabelStatesel">
                              <Typography>{t("commonTxt.Description")}</Typography>
                            </Box>
                          </Box>

                          <Box className="TabelRow" minWidth={"955px"}>
                            <Box minWidth={"10%"} className="Tabelsel ">
                              <Box className="UBox" >6</Box>
                            </Box>
                            <Box minWidth={"17%"} gap={"10px"} className="Tabelsel">
                              <Typography>{t("conversation.Empowerment")}</Typography>
                            </Box>
                            <Box minWidth={"40%"} className="Tabelsel Tabelcentersel">
                              <Typography>{t("conversation.EmpowermentVal")}</Typography>
                            </Box>
                          </Box>

                          <Box className="TabelRow" minWidth={"955px"}>
                            <Box minWidth={"10%"} className="Tabelsel ">
                              <Box className="UBox" >4</Box>
                            </Box>
                            <Box minWidth={"17%"} gap={"10px"} className="Tabelsel">
                              <Typography>{t("conversation.Nostalgia")}</Typography>
                            </Box>
                            <Box minWidth={"40%"} className="Tabelsel Tabelcentersel">
                              <Typography>{t("conversation.NostalgiaVal")}</Typography>
                            </Box>
                          </Box>

                          <Box className="TabelRow" minWidth={"955px"}>
                            <Box minWidth={"10%"} className="Tabelsel ">
                              <Box className="UBox" >2</Box>
                            </Box>
                            <Box minWidth={"17%"} gap={"10px"} className="Tabelsel">
                              <Typography>{t("conversation.Relaxation")}</Typography>
                            </Box>
                            <Box minWidth={"40%"} className="Tabelsel Tabelcentersel">
                              <Typography>{t("conversation.RelaxationVal")}</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
          
                      <Box sx={{ display: nav3 === 1 ? "block" : "none" }}></Box>

                      <Box className="tabelNav">
                        <Typography className="pageTitle">{t("conversation.lineChart3Label")}</Typography>
                        <Box
                          className="exporOpenBtn"
                          onClick={() => setExportDrop5(!exportDrop5)}
                        >
                          <img src={exportIcon} />
                          <Typography>{t("commonTxt.Export")}</Typography>
                          <img
                            src={dropIcon}
                            style={{ rotate: exportDrop5 ? "180deg" : "0deg" }}
                          />
                          <Box
                            className="statusPop"
                            sx={{ height: exportDrop5 ? "auto" : "0px" }}
                          >
                            {exportItem?.map((el, i) => (
                              <Box className="statusPopItem">
                                <p>{el}</p>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </Box>

                      <Box className="pieBox StackedBar2">
                        <StackedBar2 />
                      </Box>

                    </Box>
                  </Box>
                </Box> */}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box >

    </>
  )
}
