import "./style.css";
// images
import dropArrow from "../../../Assets/Images/chevron-down.png";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import WhiteDownArrow from "../../../Assets/Images/Whitechevron-down.png";
import dropIcon from "../../../Assets/Images/chevron-down.png";
import searchIcon from "../../../Assets/Images/searchIcon.png";
import clockIcon from "../../../Assets/Images/clock-light.png";
import googleCalendarIcon from "../../../Assets/Images/google-calendar.png";
import yahooCalendarIcon from "../../../Assets/Images/yahoo-calendar.png";
import outlookCalendarIcon from "../../../Assets/Images/outlook-calendar.png";
import noCamera from "../../../Assets/Images/there-is-no-connected-camera.jpg";
import iIcon from "../../../Assets/Images/IIcon.png";
import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import personalGolBgImg from "../../../Assets/Images/personal goals checklist-amico (1) 1.png";
import videoCameraIcon from "../../../Assets/Images/videoCameraIcon.png";
import CameraIcon from "../../../Assets/Images/bi_camera.png";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import optionIcon from "../../../Assets/Images/optionIcon.png";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import EmojiPicker from "emoji-picker-react";
import axios from "../../../lib/axios";
import Webcam from "react-webcam";

import {
  editIconBlack,
  settingIconBlack,
  addIconBlack,
  previewIconBlack,
  viewResponseNavDropVal,
  bookIcon,
  duplicatehBlack,
  deleteIcon,
  copyIcon,
} from "../../../Assets/Data";

import { useCallback, useEffect, useRef, useState } from "react";
import {
  capitalizeFirstLetter,
  getDatePickerVal,
  getFileName,
  viewImage,
} from "../../../lib/helpers";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { useNavigate, useParams } from "react-router";
import { useAppSelector } from "../../../lib/store/store";
import { uploadFile } from "../../../Services/adminActions";
import ReferenceDateUsingValue from "../DatePicker";
import ReferenceDateExplicitTimePicker from "../TimePicker";
import { AppBtn } from "../AppButton";

export const SearchBar = ({ placeholder, handleFilterChange, val }) => {
  const handleSearchChange = (val) => {
    if (typeof handleFilterChange != "undefined") {
      handleFilterChange({ search: val });
    }
  };

  return (
    <Box className="searchBar">
      <input
        placeholder={placeholder || "Search"}
        onChange={(e) => {
          handleSearchChange(e.target.value);
        }}
        value={val?.search}
      />
      <img src={searchIcon} />
    </Box>
  );
};

export const Status = ({
  setStatusDrop,
  statusDrop,
  setStatusDropVal,
  statusDropVal,
  label,
  itemAry,
  handleFilterChange,
  setClearFilter,
  clearFilter,
}) => {
  useEffect(() => {
    if (clearFilter) {
      handleStatusChange("all");
      setClearFilter(false);
    }
  }, [clearFilter]);

  const defaultItemAry = [
    {
      name: "All",
      value: "all",
    },
    {
      name: "Open",
      value: "open",
    },
    {
      name: "Closed",
      value: "closed",
    },
    {
      name: "Draft",
      value: "draft",
    },
    {
      name: "Archived",
      value: "archived",
    },
  ];

  const dropItems = itemAry || defaultItemAry;

  const handleStatusChange = (val) => {
    setStatusDropVal(val);
    if (typeof handleFilterChange != "undefined") {
      handleFilterChange({
        workspace_status: val == "all" ? "" : val,
        status: val == "all" ? "" : val,
      });
    }
  };

  return (
    <Box onClick={() => setStatusDrop(!statusDrop)} className="statusBox">
      <Typography className="statusLabel">
        {capitalizeFirstLetter(statusDropVal) || label || "Status:"}
      </Typography>
      <img
        style={{ rotate: statusDrop ? "180deg" : "0deg" }}
        src={DownArrowIcon}
      />

      <Box sx={{ height: statusDrop ? "auto" : "0px" }} className="statusPop">
        {dropItems?.map((el, index) => (
          <Box
            key={index}
            onClick={() => handleStatusChange(el.value)}
            className="statusPopItem optionPopItem"
          >
            <Typography>{el.name}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export const PurpleDrop = ({
  setStatusDrop,
  statusDrop,
  setStatusDropVal,
  statusDropVal,
  label,
  itemAry,
  handleFilterChange,
  setClearFilter,
  clearFilter,
}) => {
  useEffect(() => {
    if (clearFilter) {
      handleStatusChange("all");
      setClearFilter(false);
    }
  }, [clearFilter]);

  const defaultItemAry = [
    {
      name: "All",
      value: "All",
    },
    {
      name: "Open",
      value: "open",
    },
    {
      name: "Closed",
      value: "closed",
    },
    {
      name: "Draft",
      value: "draft",
    },
    {
      name: "Archived",
      value: "archived",
    },
  ];

  const dropItems = itemAry || defaultItemAry;

  const handleStatusChange = (val) => {
    setStatusDropVal(val);
    if (typeof handleFilterChange != "undefined") {
      handleFilterChange({
        workspace_status: val == "all" ? "" : val,
        status: val == "all" ? "" : val,
      });
    }
  };

  return (
    <Box
      onClick={() => setStatusDrop(!statusDrop)}
      className="statusBox purpleDrop"
    >
      <Typography className="statusLabel">
        {capitalizeFirstLetter(statusDropVal) || label || "Status:"}
      </Typography>
      <img
        style={{ rotate: statusDrop ? "180deg" : "0deg" }}
        src={WhiteDownArrow}
      />

      <Box sx={{ height: statusDrop ? "auto" : "0px" }} className="statusPop">
        {dropItems?.map((el, index) => (
          <Box
            key={index}
            onClick={() => handleStatusChange(el.value)}
            className="statusPopItem optionPopItem"
          >
            <Typography>{el.name}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export const LatestCreated = ({
  latestCDrop,
  setLatestDrop,
  latestDropVal,
  setLatestDropVal,
  itemAry,
  handleFilterChange,
  icon,
}) => {
  const defaultItemAry = [
    { label: "Latest Created", sortBy: "created_at", sortOrder: "-1" },
    { label: "Oldest Created", sortBy: "created_at", sortOrder: "1" },
    { label: "Alphabetically", sortBy: "name", sortOrder: "1" },
    { label: "Reverse Alphabetically", sortBy: "name", sortOrder: "-1" },
  ];

  const dropItems = itemAry || defaultItemAry;

  const handleSortOrder = (label, sortBy, sortOrder) => {
    setLatestDropVal(label);
    if (typeof handleFilterChange !== "undefined") {
      handleFilterChange({ sortBy, sortOrder });
    }
  };

  return (
    <Box
      onClick={() => setLatestDrop(!latestCDrop)}
      className="statusBox latestCreated"
    >
      <Typography className="statusLabel">{latestDropVal}</Typography>
      <img
        style={{ rotate: latestCDrop ? "180deg" : "0deg" }}
        src={DownArrowIcon}
      />
      <img className="clockIcon" src={icon || clockIcon} />
      <Box
        sx={{ height: latestCDrop ? "auto" : "0px" }}
        className="statusPop conversationPostFilterDrop"
      >
        {dropItems?.map((el, i) => (
          <Box
            key={i}
            onClick={() => handleSortOrder(el?.label, el.sortBy, el.sortOrder)}
            className="statusPopItem"
          >
            <Typography>{el?.label}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export const AllDrop = ({
  setAllDrop,
  allDrop,
  setAllDropVal,
  allDropVal,
  handleFilterChange,
}) => {
  return (
    <Box onClick={() => setAllDrop(!allDrop)} className="statusBox">
      <Typography className="statusLabel">{allDropVal}</Typography>
      <img
        style={{ rotate: allDrop ? "180deg" : "0deg" }}
        src={DownArrowIcon}
      />

      <Box sx={{ height: allDrop ? "auto" : "0px" }} className="statusPop">
        <Box
          onClick={() => {
            setAllDropVal("All");
            handleFilterChange({ status: "" });
          }}
          className="statusPopItem"
        >
          <Typography>All</Typography>
        </Box>
        <Box
          onClick={() => {
            setAllDropVal("pending");
            handleFilterChange({ status: "pending" });
          }}
          className="statusPopItem"
        >
          <Typography>Pending</Typography>
        </Box>
        <Box
          onClick={() => {
            setAllDropVal("in-progress");
            handleFilterChange({ status: "in-progress" });
          }}
          className="statusPopItem"
        >
          <Typography>In Progress</Typography>
        </Box>
        <Box
          onClick={() => {
            setAllDropVal("completed");
            handleFilterChange({ status: "completed" });
          }}
          className="statusPopItem"
        >
          <Typography>Completed</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const AllDrop2 = ({ drop, setDrop, dropVal, serDropVal }) => {
  return (
    <Box onClick={() => setDrop(!drop)} className="statusBox2">
      <Typography className="statusLabel">{dropVal}</Typography>
      <img style={{ rotate: drop ? "180deg" : "0deg" }} src={DownArrowIcon} />

      <Box sx={{ height: drop ? "auto" : "0px" }} className="statusPop">
        <Box
          onClick={() => serDropVal("Page Settings")}
          className="statusPopItem"
        >
          <Typography className="GsettingIcon">
            {settingIconBlack} Page Settings
          </Typography>
        </Box>
        <Box onClick={() => serDropVal("Add Page")} className="statusPopItem">
          <Typography className="GsettingIcon">
            {addIconBlack} Add Page
          </Typography>
        </Box>
        <Box onClick={() => serDropVal("Edit Page")} className="statusPopItem">
          <Typography className="GsettingIcon">
            {editIconBlack} Edit Page
          </Typography>
        </Box>
        <Box onClick={() => serDropVal("Preview")} className="statusPopItem">
          <Typography className="GsettingIcon">
            {previewIconBlack} Preview
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const AllDrop3 = ({
  setAllDrop3,
  allDrop3,
  setAllDrop3Val,
  allDrop3Val,
}) => {
  return (
    <Box onClick={() => setAllDrop3(!allDrop3)} className="statusBox">
      <Typography className="statusLabel3">{allDrop3Val}</Typography>
      <img
        style={{ rotate: allDrop3 ? "180deg" : "0deg" }}
        src={DownArrowIcon}
      />

      <Box sx={{ height: allDrop3 ? "auto" : "0px" }} className="statusPop">
        <Box
          onClick={() => setAllDrop3Val("Site Page 1")}
          className="statusPopItem"
        >
          <Typography>Site Page 1</Typography>
        </Box>
        <Box
          onClick={() => setAllDrop3Val("Site Page 2")}
          className="statusPopItem"
        >
          <Typography>Site Page 2</Typography>
        </Box>
        <Box
          onClick={() => setAllDrop3Val("Site Page 3")}
          className="statusPopItem"
        >
          <Typography>Site Page 3</Typography>
        </Box>
        <Box
          onClick={() => setAllDrop3Val("Site Page 4")}
          className="statusPopItem"
        >
          <Typography>Site Page 4</Typography>
        </Box>
        <Box
          onClick={() => setAllDrop3Val("Site Page 5")}
          className="statusPopItem"
        >
          <Typography>Site Page 5</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const AllDrop4 = ({ drop, setDrop, dropVal, serDropVal }) => {
  const Navigate = useNavigate();
  const handleNavigatetoActionLog = (Url) => {
    Navigate(Url);
  };

  return (
    <Box onClick={() => setDrop(!drop)} className="statusBox2">
      <Typography className="statusLabel">{dropVal}</Typography>
      <img style={{ rotate: drop ? "180deg" : "0deg" }} src={DownArrowIcon} />

      <Box sx={{ height: drop ? "auto" : "0px" }} className="statusPop">
        <Box
          onClick={() => serDropVal("Page Settings")}
          className="statusPopItem"
        >
          <Typography className="GsettingIcon">
            {settingIconBlack} Page Settings
          </Typography>
        </Box>
        <Box onClick={() => serDropVal("Add Page")} className="statusPopItem">
          <Typography className="GsettingIcon">
            {addIconBlack} Add Page
          </Typography>
        </Box>
        <Box onClick={() => serDropVal("Edit Page")} className="statusPopItem">
          <Typography className="GsettingIcon">
            {editIconBlack} Edit View
          </Typography>
        </Box>
        <Box onClick={() => serDropVal("Preview")} className="statusPopItem">
          <Typography className="GsettingIcon">
            {previewIconBlack} Preview
          </Typography>
        </Box>
        <Box
          onClick={() => {
            serDropVal("Action Log");
            handleNavigatetoActionLog("/activity-logs");
          }}
          className="statusPopItem"
        >
          <Typography className="GsettingIcon">
            {bookIcon} Activity Log
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const AllDrop5 = ({ drop, setDrop, dropVal, serDropVal, itemArrya }) => {
  const drop5DefailtItems = [
    "Add Tasks",
    "Rearrange Tasks",
    "Group Tasks",
    "Randomize Tasks",
    "Activity Settings",
  ];
  const drop5Arrya = itemArrya || drop5DefailtItems;
  return (
    <Box onClick={() => setDrop(!drop)} className="statusBox2">
      <Typography className="statusLabel">{dropVal}</Typography>
      <img style={{ rotate: drop ? "180deg" : "0deg" }} src={DownArrowIcon} />

      <Box sx={{ height: drop ? "auto" : "0px" }} className="statusPop">
        {drop5Arrya?.map((el, i) => (
          <Box key={i} onClick={() => serDropVal(el)} className="statusPopItem">
            <Typography className="GsettingIcon">{el}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export const AllDrop6 = ({ drop, setDrop, dropVal, serDropVal }) => {
  return (
    <Box onClick={() => setDrop(!drop)} className="statusBox2">
      <Typography className="statusLabel">{dropVal}</Typography>
      <img style={{ rotate: drop ? "180deg" : "0deg" }} src={DownArrowIcon} />

      <Box sx={{ height: drop ? "auto" : "0px" }} className="statusPop">
        <Box
          onClick={() => serDropVal("Edit Activity")}
          className="statusPopItem"
        >
          <Typography className="GsettingIcon">
            {editIconBlack} Edit Activity
          </Typography>
        </Box>
        <Box onClick={() => serDropVal("Duplicate")} className="statusPopItem">
          <Typography className="GsettingIcon">
            {duplicatehBlack} Duplicate
          </Typography>
        </Box>
        <Box onClick={() => serDropVal("Reports")} className="statusPopItem">
          <Typography className="GsettingIcon">
            {editIconBlack} Reports
          </Typography>
        </Box>
        <Box onClick={() => serDropVal("Transcript")} className="statusPopItem">
          <Typography className="GsettingIcon">
            {previewIconBlack} Transcript
          </Typography>
        </Box>
        <Box
          onClick={() => serDropVal("Delete Activity")}
          className="statusPopItem"
        >
          <Typography className="GsettingIcon">
            {deleteIcon} Delete Activity
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const AllDrop7 = ({ drop, setDrop, dropVal, serDropVal }) => {
  return (
    <Box onClick={() => setDrop(!drop)} className="statusBox2">
      <Typography className="statusLabel">{dropVal}</Typography>
      <img style={{ rotate: drop ? "180deg" : "0deg" }} src={DownArrowIcon} />

      <Box sx={{ height: drop ? "auto" : "0px" }} className="statusPop">
        <Box
          onClick={() => serDropVal("Edit Activity")}
          className="statusPopItem"
        >
          <Typography className="GsettingIcon">
            {editIconBlack} Edit Activity
          </Typography>
        </Box>
        <Box onClick={() => serDropVal("Duplicate")} className="statusPopItem">
          <Typography className="GsettingIcon">{copyIcon} Duplicate</Typography>
        </Box>
        <Box onClick={() => serDropVal("Reports")} className="statusPopItem">
          <Typography className="GsettingIcon">
            {editIconBlack} Reports
          </Typography>
        </Box>
        <Box onClick={() => serDropVal("Transcript")} className="statusPopItem">
          <Typography className="GsettingIcon">
            {editIconBlack} Transcript
          </Typography>
        </Box>
        <Box
          onClick={() => serDropVal("Delete Activity")}
          className="statusPopItem"
        >
          <Typography className="GsettingIcon">
            {deleteIcon} Delete Activity
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#775DEE" : "#6040EB",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export const GoTop = (section) => {
  section?.scrollTo({ top: 0, behavior: "smooth" });
};

export const GoBottom = (section) => {
  section.scrollTo({
    top: section.scrollHeight - section.clientHeight,
    behavior: "smooth",
  });
};

export const TemplateStatus = ({
  setStatusDrop,
  statusDrop,
  setStatusDropVal,
  statusDropVal,
}) => {
  return (
    <Box onClick={() => setStatusDrop(!statusDrop)} className="statusBox">
      <Typography className="statusLabel">
        {" "}
        {statusDropVal} Templates
      </Typography>
      <img
        style={{ rotate: statusDrop ? "180deg" : "0deg" }}
        src={DownArrowIcon}
      />

      <Box sx={{ height: statusDrop ? "auto" : "0px" }} className="statusPop">
        <Box onClick={() => setStatusDropVal("All")} className="statusPopItem">
          <Typography>All Templates</Typography>
        </Box>
        <Box onClick={() => setStatusDropVal("Open")} className="statusPopItem">
          <Typography>Open</Typography>
        </Box>
        <Box
          onClick={() => setStatusDropVal("Draft")}
          className="statusPopItem"
        >
          <Typography>Draft</Typography>
        </Box>
        <Box
          onClick={() => setStatusDropVal("Archived")}
          className="statusPopItem"
        >
          <Typography>Archived</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const MonthStatus = ({
  setStatusDrop,
  statusDrop,
  setStatusDropVal,
  statusDropVal,
}) => {
  return (
    <Box
      onClick={() => setStatusDrop(!statusDrop)}
      className="statusMonthBox monthStatusDropBox"
    >
      {statusDropVal ? (
        <Typography className="statusLabel"> {statusDropVal} </Typography>
      ) : (
        <Typography className="statusLabel"> Month</Typography>
      )}
      <img
        style={{ rotate: statusDrop ? "180deg" : "0deg" }}
        src={DownArrowIcon}
      />

      <Box sx={{ height: statusDrop ? "auto" : "0px" }} className="statusPop">
        <Box onClick={() => setStatusDropVal("Day")} className="statusPopItem">
          <Typography>Day</Typography>
        </Box>
        <Box onClick={() => setStatusDropVal("Week")} className="statusPopItem">
          <Typography>Week</Typography>
        </Box>
        <Box
          onClick={() => setStatusDropVal("Month")}
          className="statusPopItem"
        >
          <Typography>Month</Typography>
        </Box>
        <Box onClick={() => setStatusDropVal("Year")} className="statusPopItem">
          <Typography>Year</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const OptionDrop = ({
  setOptionDrop,
  optionDrop,
  setOptionDropVal,
  optionDropVal,
  workspace_id,
  participant_id,
  setParticipateDeletePop,
}) => {
  const Navigate = useNavigate();
  // const handelRemovePersonalData = () => {
  //   setOptionDropVal("Remove personal data");
  //   let payload = {
  //     workspace_id: workspace_id,
  //     participant_id: participant_id,
  //   };
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post(`/super-admin/admin/participants/remove-personal-data`, payload)
  //       .then((res) => {
  //         if (res.success) {
  //           resolve({
  //             success: true,
  //             message: res.message,
  //           });
  //           Navigate("/workspace-administration/all-participants");
  //           toast.success(res.message, TOAST_CONFIG);
  //         } else {
  //           reject({
  //             success: false,
  //             message: res.message,
  //           });
  //           toast.success(res.message, TOAST_CONFIG);
  //         }
  //       })
  //       .catch((err) => {
  //         reject({
  //           success: false,
  //           message: err.message,
  //         });
  //       });
  //   });
  // };

  return (
    <Box onClick={() => setOptionDrop(!optionDrop)} className="statusBox">
      <Typography className="statusLabel">{optionDropVal}</Typography>
      <img
        style={{ rotate: optionDrop ? "180deg" : "0deg" }}
        src={DownArrowIcon}
      />

      {workspace_id && (
        <Box sx={{ height: optionDrop ? "auto" : "0px" }} className="statusPop">
          <Box
            // onClick={() => setOptionDropVal("Remove personal data")}
            // onClick={handelRemovePersonalData}
            onClick={() => setParticipateDeletePop(true)}
            className="statusPopItem optionItem"
          >
            <Typography>Remove personal data</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export const TimeStatus = ({ index, data, updateTime }) => {
  const [statusTimeDrop, setStatusTimeDrop] = useState(false);
  const [statusTimeDropVal, setStatusTimeDropVal] = useState(data);

  const handleUpdate = (newValue) => {
    setStatusTimeDropVal(newValue);
    updateTime(index, newValue);
  };

  return (
    <Box
      onClick={() => setStatusTimeDrop(!statusTimeDrop)}
      className="statusMonthBox statusTimeBox"
    >
      {statusTimeDropVal ? (
        <Typography className="statusLabel"> {statusTimeDropVal} </Typography>
      ) : (
        <Typography className="statusLabel"> 10:00 A.M.</Typography>
      )}
      <img
        style={{ rotate: statusTimeDrop ? "180deg" : "0deg" }}
        src={DownArrowIcon}
      />

      <Box
        sx={{ height: statusTimeDrop ? "auto" : "0px" }}
        className="statusPop"
      >
        <Box
          onClick={() => handleUpdate("04:00 A.M.")}
          className="statusPopItem"
        >
          <Typography>04:00 A.M.</Typography>
        </Box>
        <Box
          onClick={() => handleUpdate("08:00 P.M.")}
          className="statusPopItem"
        >
          <Typography>08:00 P.M.</Typography>
        </Box>
        <Box
          onClick={() => handleUpdate("10:00 A.M.")}
          className="statusPopItem"
        >
          <Typography>10:00 A.M.</Typography>
        </Box>
        <Box
          onClick={() => handleUpdate("05:00 P.M.")}
          className="statusPopItem"
        >
          <Typography>05:00 P.M.</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const TimeStatus2 = ({
  setStatusTimeDrop2,
  statusTimeDrop2,
  setStatusTimeDropVal2,
  statusTimeDropVal2,
}) => {
  return (
    <Box
      onClick={() => setStatusTimeDrop2(!statusTimeDrop2)}
      className="statusMonthBox statusTimeBox"
    >
      {statusTimeDropVal2 ? (
        <Typography className="statusLabel"> {statusTimeDropVal2} </Typography>
      ) : (
        <Typography className="statusLabel"> 04:00 P.M.</Typography>
      )}
      <img
        style={{ rotate: statusTimeDrop2 ? "180deg" : "0deg" }}
        src={DownArrowIcon}
      />

      <Box
        sx={{ height: statusTimeDrop2 ? "auto" : "0px" }}
        className="statusPop"
      >
        <Box
          onClick={() => setStatusTimeDropVal2("04:00 A.M.")}
          className="statusPopItem"
        >
          <Typography>04:00 A.M.</Typography>
        </Box>
        <Box
          onClick={() => setStatusTimeDropVal2("08:00 P.M.")}
          className="statusPopItem"
        >
          <Typography>08:00 P.M.</Typography>
        </Box>
        <Box
          onClick={() => setStatusTimeDropVal2("10:00 A.M.")}
          className="statusPopItem"
        >
          <Typography>10:00 A.M.</Typography>
        </Box>
        <Box
          onClick={() => setStatusTimeDropVal2("05:00 P.M.")}
          className="statusPopItem"
        >
          <Typography>05:00 P.M.</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const TimeStatus3 = ({
  setStatusTimeDrop3,
  statusTimeDrop3,
  setStatusTimeDropVal3,
  statusTimeDropVal3,
}) => {
  return (
    <Box
      onClick={() => setStatusTimeDrop3(!statusTimeDrop3)}
      className="statusMonthBox statusTimeBox"
    >
      {statusTimeDropVal3 ? (
        <Typography className="statusLabel"> {statusTimeDropVal3} </Typography>
      ) : (
        <Typography className="statusLabel"> 08:00 P.M.</Typography>
      )}
      <img
        style={{ rotate: statusTimeDrop3 ? "180deg" : "0deg" }}
        src={DownArrowIcon}
      />

      <Box
        sx={{ height: statusTimeDrop3 ? "auto" : "0px" }}
        className="statusPop"
      >
        <Box
          onClick={() => setStatusTimeDropVal3("04:00 A.M.")}
          className="statusPopItem"
        >
          <Typography>04:00 A.M.</Typography>
        </Box>
        <Box
          onClick={() => setStatusTimeDropVal3("08:00 P.M.")}
          className="statusPopItem"
        >
          <Typography>08:00 P.M.</Typography>
        </Box>
        <Box
          onClick={() => setStatusTimeDropVal3("10:00 A.M.")}
          className="statusPopItem"
        >
          <Typography>10:00 A.M.</Typography>
        </Box>
        <Box
          onClick={() => setStatusTimeDropVal3("05:00 P.M.")}
          className="statusPopItem"
        >
          <Typography>05:00 P.M.</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const BigDrop = ({ setDrop, Drop, setDropVal, DropVal, dropItems }) => {
  const DefaultdropItems = ["Item 1", "Item 2", "Item 3", "Item 4"];

  const itemAry = dropItems || DefaultdropItems;

  return (
    <Box className="inputAppDrop" onClick={() => setDrop(!Drop)}>
      <Typography>{DropVal || "Filter by Project"}</Typography>
      <img style={{ rotate: Drop ? "180deg" : "0deg" }} src={dropArrow} />
      {/* pop */}
      <Box sx={{ height: Drop ? "auto" : "0px" }} className="statusPop">
        {itemAry.map((el, index) => (
          <Box
            key={index}
            onClick={() => setDropVal(el)}
            className="statusPopItem"
          >
            <Typography>{el}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export const OptionBlueDrop = ({
  viewResponsePageNavDrop,
  setViewResponsePageNavDrop,
  viewResponsePageNavDropVal,
  setViewResponsePageNavDropVal,
}) => {
  return (
    <Box
      onClick={() => setViewResponsePageNavDrop(!viewResponsePageNavDrop)}
      className="directMessageNavBtn"
    >
      <Typography sx={{ color: "#fff" }}>
        {viewResponsePageNavDropVal || "Option"}
      </Typography>
      <img
        style={{ rotate: viewResponsePageNavDrop ? "180deg" : "0deg" }}
        src={WhiteDownArrow}
      />
      <Box
        sx={{ display: viewResponsePageNavDrop ? "flex" : "none" }}
        className="workspaceItemNavPop"
      >
        {viewResponseNavDropVal?.map((el, index) => (
          <Box
            className="vrDropItem"
            key={index}
            onClick={() => {
              setViewResponsePageNavDropVal(el.text);
            }}
          >
            {el.icon}
            <Typography ml={1}>{el.text}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export const CalendarDropBtn = ({ drop, setDrop, dropVal, serDropVal }) => {
  return (
    <Box onClick={() => setDrop(!drop)} className="statusBox2">
      <Typography className="statusLabel">{dropVal}</Typography>
      <img style={{ rotate: drop ? "180deg" : "0deg" }} src={DownArrowIcon} />

      <Box sx={{ height: drop ? "auto" : "0px" }} className="statusPop">
        <Box
          onClick={() => serDropVal("Google Calender")}
          className="statusPopItem"
        >
          <img src={googleCalendarIcon} />
          <Typography className="GsettingIcon">Google Calender</Typography>
        </Box>
        <Box
          onClick={() => serDropVal("Yahoo Calender")}
          className="statusPopItem"
        >
          <Typography className="GsettingIcon">
            <img src={yahooCalendarIcon} /> Yahoo Calender
          </Typography>
        </Box>
        <Box onClick={() => serDropVal("Outlook")} className="statusPopItem">
          <Typography className="GsettingIcon">
            <img src={outlookCalendarIcon} /> Outlook
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const PickEmoji = ({
  setEmojiDrop,
  EmojiDrop,
  setSelectedEmoji,
  selectedEmoji,
}) => {
  return (
    <Box className="emoji_Section">
      <Box className="emojiSelectBox">
        <Box className="emojiDrop">
          <p className="emojiIcon" onClick={() => setEmojiDrop(!EmojiDrop)}>
            {selectedEmoji?.emoji || "👍"}
          </p>
          <img
            className="dropIcon"
            src={dropIcon}
            onClick={() => setEmojiDrop(!EmojiDrop)}
          />
          <Box
            className="emojiBox"
            sx={{ display: EmojiDrop ? "block" : "none" }}
          >
            <EmojiPicker onEmojiClick={(e) => setSelectedEmoji(e)} />
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>
          <input
            style={{ height: "35px" }}
            type="text"
            className="phoneInput"
            placeholder="Like"
            value={selectedEmoji?.names[0]}
          />
        </Box>
      </Box>
      <Box
        ml={4}
        mb={1}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <input
          style={{ marginRight: "10px" }}
          className="custom-checkbox"
          type="checkBox"
        />
        <Typography>Enforce tag limit</Typography>
      </Box>

      <Box className="limitBox">
        <input className="phoneInput" value={"01"} />
        <Typography>to</Typography>
        <input className="phoneInput" value={"04"} />
        <Typography>Tags</Typography>
      </Box>
    </Box>
  );
};

export const CompanyStatus = ({
  setCompanyStatusDrop,
  companyStatusDrop,
  compantStatusDropVal,
  setCompantStatusDropVal,
  label,
  itemAry,
  handleFilterChange,
  setClearFilter,
  clearFilter,
}) => {
  useEffect(() => {
    if (clearFilter) {
      handleStatusChange("All");
      setClearFilter(false);
    }
  }, [clearFilter]);
  const defaultItemAry = ["All", "active", "inactive"];

  const dropItems = itemAry || defaultItemAry;

  const handleStatusChange = (val) => {
    setCompantStatusDropVal(val);
    if (typeof handleFilterChange != "undefined") {
      handleFilterChange({
        status: val == "All" ? "" : val,
      });
    }
  };

  return (
    <Box
      // ref={compStatusDrop}
      className="inputAppDrop"
      onClick={() => setCompanyStatusDrop(!companyStatusDrop)}
    >
      <Typography>
        {compantStatusDropVal || label || "Filter by Project"}
      </Typography>
      <img
        style={{ rotate: companyStatusDrop ? "180deg" : "0deg" }}
        src={DownArrowIcon}
      />
      {/* pop */}
      <Box
        sx={{ height: companyStatusDrop ? "auto" : "0px" }}
        className="statusPop"
      >
        {dropItems?.map((el, index) => (
          <Box
            key={index}
            onClick={() => handleStatusChange(el)}
            className="statusPopItem optionPopItem"
          >
            <Typography>{el}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export const ResearcherStatus = ({
  setResearcherDrop,
  researcherDrop,
  researcherDropVal,
  setResearcherDropVal,
  label,
  itemAry,
  handleFilterChange,
  setClearFilter,
  clearFilter,
}) => {
  const defaultItemAry = ["All", "Assigned Workspace", "Unassigned Workspace"];

  const dropItems = itemAry || defaultItemAry;

  useEffect(() => {
    if (clearFilter) {
      handleStatusChange("All");
      setClearFilter(false);
    }
  }, [clearFilter]);

  const handleStatusChange = (val) => {
    setResearcherDropVal(val);
    if (typeof handleFilterChange != "undefined") {
      handleFilterChange({
        assigned_status:
          val == "All" ? "" : val.replace(" Workspace", "").toLowerCase(),
      });
    }
  };

  return (
    <Box
      // ref={researcherStatusDrop}
      className="statusBox"
      onClick={() => setResearcherDrop(!researcherDrop)}
    >
      <Typography className="statusLabel">
        {researcherDropVal || label || "Filter by Researcher Status"}
      </Typography>
      <img
        style={{ rotate: researcherDrop ? "180deg" : "0deg" }}
        src={dropArrow}
      />
      {/* pop */}
      <Box
        sx={{ height: researcherDrop ? "auto" : "0px" }}
        className="statusPop"
      >
        {dropItems?.map((el, index) => (
          <Box
            key={index}
            onClick={() => handleStatusChange(el)}
            className="statusPopItem optionPopItem"
          >
            <Typography>{el}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export const ReserchersWorkSpaceDropDown = ({
  setWorkspaceNameDrop,
  workspaceNameDrop,
  workspaceNameDropVal,
  setWorkspaceNameDropVal,
  label,
  handleFilterChange,
  setClearFilter,
  clearFilter,
}) => {
  useEffect(() => {
    if (clearFilter) {
      handleStatusChange("all");
      setWorkspaceNameDropVal("All");
      setClearFilter(false);
    }
  }, [clearFilter]);

  const [allWorkSpaceListData, setAllWorkSpaceListData] = useState([]);

  useEffect(() => {
    getWorkSpaceList();
  }, []);

  const getWorkSpaceList = async () => {
    let res = await axios.get(`/super-admin/admin/workspaces`);
    if (res.status) {
      const workspaces = res.data.data;
      const allOption = { _id: "all", name: "All" };
      const workspaceListWithAll = [allOption, ...workspaces];
      setAllWorkSpaceListData(workspaceListWithAll);
    }
  };

  const handleStatusChange = (val) => {
    if (typeof handleFilterChange != "undefined") {
      handleFilterChange({
        workspace: val == "all" ? "" : val,
      });
    }
  };

  return (
    <Box
      // ref={wsNameStatusDrop}
      className="statusBox"
      onClick={() => setWorkspaceNameDrop(!workspaceNameDrop)}
    >
      <Typography className="statusLabel">
        {workspaceNameDropVal || label || "Filter by Workspace Name"}
      </Typography>
      <img
        style={{ rotate: workspaceNameDrop ? "180deg" : "0deg" }}
        src={dropArrow}
      />

      <Box
        sx={{
          height: workspaceNameDrop ? "180px" : "0px",
          overflowY: "scroll",
        }}
        className="statusPop"
      >
        {allWorkSpaceListData.length > 0 ? (
          allWorkSpaceListData?.map((el, index) => (
            <Box
              key={index}
              onClick={() => {
                handleStatusChange(el._id);
                setWorkspaceNameDropVal(el.name);
              }}
              className="statusPopItem optionPopItem"
            >
              <Typography>{el.name}</Typography>
            </Box>
          ))
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export const AllDrop8 = ({ drop, setDrop, dropVal, serDropVal, itemArrya }) => {
  const drop5DefailtItems = [
    "Support Login",
    "Adjust Points",
    "Reports",
    "Transcripts",
    "Edit Participant",
    "Remove from Project",
  ];
  const drop5Arrya = itemArrya || drop5DefailtItems;
  return (
    <Box onClick={() => setDrop(!drop)} className="statusBox2">
      <Typography className="statusLabel">{dropVal}</Typography>
      <img style={{ rotate: drop ? "180deg" : "0deg" }} src={DownArrowIcon} />

      <Box sx={{ height: drop ? "auto" : "0px" }} className="statusPop">
        {drop5Arrya?.map((el, i) => (
          <Box key={i} onClick={() => serDropVal(el)} className="statusPopItem">
            <Typography className="GsettingIcon">{el}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export const AllDrop9 = ({ drop, setDrop, dropVal, serDropVal, itemArrya }) => {
  const drop5DefailtItems = ["As MS Excel", "As Google Sheets", "As PDF"];
  const drop5Arrya = itemArrya || drop5DefailtItems;
  return (
    <Box onClick={() => setDrop(!drop)} className="statusBox2 exportDrop">
      <Typography className="statusLabel exportDropText">{dropVal}</Typography>
      <img style={{ rotate: drop ? "180deg" : "0deg" }} src={WhiteDownArrow} />

      <Box sx={{ height: drop ? "auto" : "0px" }} className="statusPop">
        {drop5Arrya?.map((el, i) => (
          <Box key={i} onClick={() => serDropVal(el)} className="statusPopItem">
            <Typography className="GsettingIcon">{el}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export const IBox = ({ text }) => {
  return (
    <Box mt={5} className="iBox">
      <img src={iIcon} />
      <Typography>{text}</Typography>
    </Box>
  );
};

// Common dropdown For single Select
export const DropDownLists = ({
  label,
  value,
  items,
  handleSelection,
  icon,
  placeholder,
  iconClass,
}) => {
  const [drop, setDrop] = useState(false);
  return (
    <Box className="inputBoxWlabel">
      <Typography className="inputLabel">{label}</Typography>
      <Box
        className="inputAppDrop"
        onClick={() => setDrop(!drop)}
        pl={icon ? 4.5 : undefined}
      >
        {icon && <img className={iconClass} src={icon} />}
        <Typography>{value || placeholder}</Typography>
        <img
          style={{
            rotate: drop ? "180deg" : "0deg",
          }}
          src={dropArrow}
        />
        {/* pop */}
        <Box
          sx={{
            height: drop ? "280px" : "0px",
            overflowY: drop ? "scroll" : "hidden",
          }}
          className="statusPop"
        >
          <Box
            key={0}
            onClick={() => {
              handleSelection("");
              setDrop(false);
            }}
            className="statusPopItem"
          >
            <Typography>Choose Option</Typography>
          </Box>
          {items.map((item, index) => (
            <Box
              key={index + 1}
              onClick={() => {
                handleSelection(item);
                setDrop(false);
              }}
              className="statusPopItem"
            >
              <Typography>{item.name}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export const RenderCustomField = ({
  profileField,
  addResearcherParticipant,
  setAddResearcherParticipant,
}) => {
  const authState = useAppSelector((state) => state.auth);
  const fieldType = profileField.field_type;
  console.log(fieldType);
  const customFields = addResearcherParticipant?.custom_fields || [];
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();
  const [signatureTextUnderLine, setSignatureTextUnderLine] = useState();
  const existingFieldIndex = customFields.findIndex(
    (field) => field.field_id === profileField._id
  );
  const [customFieldVal, setCustomFieldVal] = useState("");
  const [emailval, setEmailval] = useState();
  const [image, setImage] = useState(null);

  useEffect(() => {
    const fieldData = customFields.filter(
      (field) => field.field_id == profileField._id
    );
    if (fieldData.length > 0) {
      console.log("fieldData[0].value", fieldData[0].value);
      setCustomFieldVal(fieldData[0].value);
      if (fieldData[0].value.includes("/")) {
        setImage(viewImage(fieldData[0].value));
      }
    }
  }, [profileField]);

  const updateState = async (e, file = null) => {
    let value = "";

    if (e.target.type === "file") {
      console.log(file);
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        try {
          const imageUploadRes = await uploadFile(
            formData,
            authState.authToken
          );
          if (imageUploadRes.success) {
            value = imageUploadRes?.data?.upload_path;
          } else {
          }
        } catch (error) {
        } finally {
        }
      }
    } else {
      value = e.target.value;
    }
    setCustomFieldVal(value);
    if (value) {
      if (existingFieldIndex !== -1) {
        customFields[existingFieldIndex].value = value;
      } else {
        customFields.push({
          field_id: profileField._id,
          value,
        });
      }
    } else if (existingFieldIndex !== -1) {
      customFields.splice(existingFieldIndex, 1);
    }

    setAddResearcherParticipant((prevState) => ({
      ...prevState,
      custom_fields: customFields,
    }));
  };

  const openFile = (file) => {
    window.open(viewImage(file), "_Blank");
  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine === "underline") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine === "line-through") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("line-through");
        }
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      updateState(e, file);
      const reader = new FileReader();
      reader.onload = () => {
        if (file.type.includes("image")) setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      updateState(e, file);
      const reader = new FileReader();
      reader.onload = () => {
        if (file.type.includes("image")) setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChecRadiokBox = (e, value) => {
    const { type, checked } = e.target;
    const existingFieldIndex = customFields.findIndex(
      (field) => field.field_id === profileField._id
    );
    let updatedValues;
    if (type === "checkbox") {
      updatedValues = customFields[existingFieldIndex]?.value || [];
      if (checked) {
        if (!updatedValues.includes(value)) {
          updatedValues.push(value);
        }
      } else {
        updatedValues = updatedValues.filter((val) => val !== value);
      }
    } else if (type === "radio") {
      updatedValues = value;
    }
    setCustomFieldVal(updatedValues);
    if (existingFieldIndex === -1) {
      customFields.push({
        field_id: profileField._id,
        value: updatedValues,
      });
    } else {
      customFields[existingFieldIndex].value = updatedValues;
    }

    setAddResearcherParticipant((prevState) => ({
      ...prevState,
      custom_fields: customFields,
    }));
  };

  const handelOntheDot = () => {
    // console.log("profileField.destination", profileField.destination);
    if (profileField.destination === "go_to_url_(current_tab)")
      return (window.location.href = profileField.destination_url);
    else if (profileField.destination === "go_to_url_(new_tab)" || true)
      return window.open(profileField.destination_url);
  };

  const handelDate = (e) => {
    const value = getDatePickerVal(e, "date");
    if (value) {
      if (existingFieldIndex !== -1) {
        customFields[existingFieldIndex].value = value;
      } else {
        customFields.push({
          field_id: profileField._id,
          value,
        });
      }
    } else if (existingFieldIndex !== -1) {
      customFields.splice(existingFieldIndex, 1);
    }
  };

  const handelTime = (e) => {
    const value = getDatePickerVal(e, "time");
    if (value) {
      if (existingFieldIndex !== -1) {
        customFields[existingFieldIndex].value = value;
      } else {
        customFields.push({
          field_id: profileField._id,
          value,
        });
      }
    } else if (existingFieldIndex !== -1) {
      customFields.splice(existingFieldIndex, 1);
    }
  };

  return (
    <Box>
      {fieldType == "number" && (
        <Box>
          <Box className="Text_BoxWDot participantProfileField">
            {" "}
            <img src={optionIcon} />{" "}
            <Typography>{profileField.question}</Typography>
          </Box>
          <Box className="input_Box profileAnswerBox">
            <input
              type="text"
              className="eTemInput"
              placeholder={profileField.question}
              onChange={updateState}
              value={customFieldVal}
            />
          </Box>
        </Box>
      )}
      {fieldType == "plain-text" && (
        <Box>
          <Box className="Text_BoxWDot participantProfileField">
            {" "}
            <img src={optionIcon} />{" "}
            <Typography>{profileField.question}</Typography>
          </Box>
          <Box className="profileAnswerBox">
            <textarea
              className="DmettingTextTextAera"
              placeholder={profileField.question}
              onChange={updateState}
              value={customFieldVal}
            />
          </Box>
        </Box>
      )}
      {fieldType == "formatted-text" && (
        <Box>
          <Box className="Text_BoxWDot participantProfileField">
            {" "}
            <img src={optionIcon} />{" "}
            <Typography>{profileField.question}</Typography>
          </Box>
          <Box className="profileAnswerBox">
            <Box className="emailInputBox formattedProfileAnswer">
              <Box className="emailInputBoxNav">
                <Box
                  onClick={() => setMessageTextItalic(!messageTextItalic)}
                  className="emailImgBox"
                >
                  <img src={IIcon} />
                </Box>
                <Box
                  onClick={() => setMessageTextBold(!messageTextBold)}
                  className="emailImgBox"
                >
                  <img src={BIcon} />
                </Box>
                <Box
                  onClick={() => handelTextlineChekc("underline", "message")}
                  className="emailImgBox"
                >
                  <img src={UnderLineU} />
                </Box>
                <Box
                  onClick={() => handelTextlineChekc("line-through", "message")}
                  className="emailImgBox"
                >
                  <img src={MLineU} />
                </Box>
                <Box className="emailImgBox">
                  <img src={AtachMentIcon} />
                </Box>
                <Box className="emailImgBox">
                  <img src={Qbox} />
                </Box>
                <Box
                  onClick={() => setMessageTextLaOut("start")}
                  className="emailImgBox"
                >
                  <img src={Line1} />
                </Box>
                <Box
                  onClick={() => setMessageTextLaOut("center")}
                  className="emailImgBox"
                >
                  <img src={Line2} />
                </Box>
                <Box
                  onClick={() => setMessageTextLaOut("end")}
                  className="emailImgBox"
                >
                  <img src={Line3} />
                </Box>
                <Box className="emailImgBox">
                  <img src={Line4} />
                </Box>
                <Box className="emailImgBox">
                  <img src={emoji} />
                </Box>
              </Box>
              <textarea
                style={{
                  textAlign: messageTextLaOut,
                  fontStyle: messageTextItalic ? "italic" : "normal",
                  fontWeight: messageTextBold ? "bold" : "normal",
                  textDecoration: messageTextUnderLine,
                  // textDecoration: messageTextMLine ? "line-through" : null
                }}
                className="emailInput"
                placeholder="Enter your response here"
                onChange={updateState}
                value={customFieldVal}
              />
            </Box>
          </Box>
        </Box>
      )}
      {fieldType == "date" && (
        <Box>
          <Box className="Text_BoxWDot participantProfileField dobProfileFields">
            {" "}
            <img src={optionIcon} />{" "}
            <Typography>{profileField.question}</Typography>
          </Box>
          <Box className="pickerBox dobInputProfileField profileAnswerBox">
            <ReferenceDateUsingValue
              value={dayjs(customFieldVal)}
              onChange={handelDate}
            />
          </Box>
        </Box>
      )}
      {fieldType == "timestamp" && (
        <Box>
          <Box className="Text_BoxWDot participantProfileField dobProfileFields">
            {" "}
            <img src={optionIcon} />{" "}
            <Typography>{profileField.question}</Typography>
          </Box>
          <Box className="pickerBox dobInputProfileField profileAnswerBox">
            <ReferenceDateExplicitTimePicker
              value={dayjs(customFieldVal, "HH:mm:ss")}
              onChange={handelTime}
            />
          </Box>
        </Box>
      )}
      {fieldType == "video" && (
        <Box>
          <Box className="Text_BoxWDot participantProfileField">
            {" "}
            <img src={optionIcon} />{" "}
            <Typography>{profileField.question}</Typography>
          </Box>
          <Box
            className="uploadVideoProfileAnswerBox profileAnswerBox"
            onClick={() => {
              document.getElementById("fileInputVideo").click();
            }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <Box className="imgUplodInputBox deleteTopicIcon">
              <input
                id="fileInputVideo"
                type="file"
                accept="video/*"
                style={{ display: "none" }}
                onChange={handleFileInputChange}
              />
              <img src={videoCameraIcon} alt="Camera" />
            </Box>

            {customFieldVal ? (
              <Typography
                className="imageUploadLabelText deleteTopicIcon"
                onClick={() => openFile(customFieldVal)}
              >
                {getFileName(customFieldVal)}
              </Typography>
            ) : (
              <Typography className="imageUploadLabelText deleteTopicIcon">
                Record <span>or</span> Upload Video
              </Typography>
            )}
          </Box>
        </Box>
      )}
      {fieldType == "image" && (
        <Box>
          <Box className="Text_BoxWDot participantProfileField">
            {" "}
            <img src={optionIcon} />{" "}
            <Typography>{profileField.question}</Typography>
          </Box>
          <Box
            className="uploadVideoProfileAnswerBox profileAnswerBox"
            onClick={() => {
              document.getElementById("fileInputImage").click();
            }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <Box className="imgUplodInputBox deleteTopicIcon">
              <input
                id="fileInputImage"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileInputChange}
              />
              <img src={image ? image : CameraIcon} alt="Camera" />
            </Box>
            <Typography className="imageUploadLabelText deleteTopicIcon">
              Capture <span> or </span>Upload Photo
            </Typography>
          </Box>
        </Box>
      )}
      {fieldType == "on-the-dot" && (
        <Box>
          <Box className="Text_BoxWDot participantProfileField dobProfileFields">
            {" "}
            <img src={optionIcon} />{" "}
            <Typography>{profileField.question}</Typography>
          </Box>
          <Box className="onTheDotProfileAns">
            <img
              src={
                !profileField.file || profileField.file == "undefined"
                  ? personalGolBgImg
                  : viewImage(profileField.file)
              }
            />
            <Box className="btnboxWText">
              <Typography className="">{profileField.instruction}</Typography>
              <Typography className="startbgSubText">
                Status
                <FormControl sx={{ width: "100%" }} size="small">
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={customFieldVal}
                    onChange={updateState}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Status
                    </MenuItem>
                    <MenuItem value={`Not Started`} key={1}>
                      Not Started
                    </MenuItem>
                    <MenuItem value={`Incomplete`} key={2}>
                      Incomplete
                    </MenuItem>
                    <MenuItem value={`Completed`} key={3}>
                      Completed
                    </MenuItem>
                  </Select>
                </FormControl>
              </Typography>
              <Box className={`onTheDotAnsBtnBox btn-${profileField.style}`}>
                <AppBtn
                  buttonText={profileField.lebel}
                  onClick={handelOntheDot}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {fieldType == "poll" && (
        <>
          <Box className="Text_BoxWDot participantProfileField">
            {" "}
            <img src={optionIcon} />{" "}
            <Typography>{profileField.question}</Typography>
          </Box>
          <Box className="profileAnswerBox">
            {profileField?.choices &&
              profileField?.choices.length > 0 &&
              profileField?.choices.map((el) => (
                <Box className="radioBox">
                  <Box>
                    {profileField?.allow_multiple_choice_check ? (
                      <input
                        className="emailSettingInput emailSettingRadio"
                        type="checkbox"
                        name="activity"
                        checked={customFieldVal.includes(el.name)}
                        onClick={(e) => handleChecRadiokBox(e, el.name)}
                      />
                    ) : (
                      <input
                        className="emailSettingInput emailSettingRadio"
                        type="radio"
                        name="activity"
                        checked={el.name == customFieldVal}
                        onClick={(e) => handleChecRadiokBox(e, el.name)}
                      />
                    )}
                  </Box>
                  <Box>
                    <Typography className="emailSettingLabel">
                      {el.name}
                    </Typography>
                  </Box>
                </Box>
              ))}
          </Box>
        </>
      )}
    </Box>
  );
};

const getFieldType = (type) => {
  if (["plain_text", "formatted_text"].includes(type)) return "text";
  else if (type == "number") return "number";
  else if (type == "date") return "date";
  else if (type == "timestamp") return "datetime";
  else if (["video", "image"].includes(type)) return "file";
};

export const NotificationTypes = ({
  setStatusDrop,
  statusDrop,
  setStatusDropVal,
  statusDropVal,
  label,
  itemAry,
  handleFilterChange,
  setClearFilter,
  clearFilter,
}) => {
  useEffect(() => {
    if (clearFilter) {
      handleStatusChange("all");
      setClearFilter(false);
    }
  }, [clearFilter]);

  const defaultItemAry = [
    {
      name: "All",
      value: "All",
    },
    {
      name: "Open",
      value: "open",
    },
    {
      name: "Closed",
      value: "closed",
    },
    {
      name: "Draft",
      value: "draft",
    },
    {
      name: "Archived",
      value: "archived",
    },
  ];

  const dropItems = itemAry || defaultItemAry;
  const [selectVal, setSelectVal] = useState("");

  const handleStatusChange = (val) => {
    setStatusDropVal(val);
    if (typeof handleFilterChange != "undefined") {
      if (val == "unread") {
        handleFilterChange({
          read_at: false,
        });
      } else if (val == "read") {
        handleFilterChange({
          read_at: true,
        });
      } else {
        handleFilterChange({
          read_at: "",
        });
      }
    }
  };

  return (
    <Box onClick={() => setStatusDrop(!statusDrop)} className="statusBox">
      <Typography className="statusLabel">
        {selectVal || label || "Type:"}
      </Typography>
      <img
        style={{ rotate: statusDrop ? "180deg" : "0deg" }}
        src={DownArrowIcon}
      />

      <Box sx={{ height: statusDrop ? "auto" : "0px" }} className="statusPop">
        {dropItems?.map((el, index) => (
          <Box
            key={index}
            onClick={() => {
              handleStatusChange(el.value);
              setSelectVal(el.name);
            }}
            className="statusPopItem optionPopItem"
          >
            <Typography>{el.name}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export function useDebounceEffect(fn, delay, deps) {
  useEffect(() => {
    const handler = setTimeout(() => fn(), delay);

    return () => {
      clearTimeout(handler);
    };
  }, [...deps, delay]);
}

export function canvasPreview(image, canvas, crop, scale = 1, rotate = 0) {
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    return;
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width * scaleX;
  canvas.height = crop.height * scaleY;

  ctx.save();
  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.scale(scale, scale);
  ctx.rotate((rotate * Math.PI) / 180);
  ctx.translate(-canvas.width / 2, -canvas.height / 2);
  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );
  ctx.restore();
}

const videoConstraints = {
  width: 560,
  height: 480,
  facingMode: "user",
};

export const WebcamCapture = ({ screenshortSrc, setScreenshortSrc }) => {
  const webcamRef = useRef(null);
  const [noCameraImg, setNoCameraImg] = useState(null);
  const [facingMode, setFacingMode] = useState("user"); // State to track camera mode

  useEffect(() => {
    checkCamera();
  }, []);

  const checkCamera = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      if (videoDevices.length < 1) {
        setNoCameraImg(noCamera);
      }
    } catch (error) {
      console.error("Error checking media devices:", error);
    }
  };

  const capturePhoto = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      console.log("imageSrc", imageSrc);
      setScreenshortSrc(imageSrc);
    }
  }, [webcamRef, setScreenshortSrc]);

  const toggleFacingMode = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };
  if (noCameraImg) {
    return (
      <>
        <div>
          <img src={noCameraImg} alt="Screenshot" style={{ width: "553px" }} />
        </div>
      </>
    );
  }
  return (
    <>
      {screenshortSrc ? (
        <div
          style={{
            position: "relative",
            width: "540px",
            height: "480px",
            objectFit: "contain",
          }}
        >
          <img
            src={screenshortSrc}
            alt="Screenshot"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>
      ) : (
        <div
          style={{
            position: "relative",
            width: "540px",
            height: "480px",
            objectFit: "contain",
          }}
        >
          <Webcam
            audio={false}
            height={480}
            ref={webcamRef}
            mirrored={true}
            screenshotFormat="image/jpeg"
            width={540}
            videoConstraints={{ ...videoConstraints, facingMode }}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>
      )}
      {!noCameraImg && (
        <div>
          {!screenshortSrc && (
            <button onClick={capturePhoto}>Capture photo</button>
          )}
          {!screenshortSrc && (
            <button onClick={toggleFacingMode}>Toggle Camera</button>
          )}
          {screenshortSrc && (
            <button onClick={() => setScreenshortSrc(null)}>Refresh</button>
          )}
        </div>
      )}
    </>
  );
};
