import React, { useState } from 'react'
import EmailPage from "../../../Components/Admin/EmailPage";
import { Box } from '@mui/material'
import AdminLayout from '../partials/AdminLayout';
import { AppBtn } from '../../../Components/AppTools/AppButton';

const EmailTemplate = () => {


  const [emailTemplatePop, setEmailTemplatePop] = useState(false);
  return (
    <AdminLayout
      childComponent={<Box
        className="adminMainBox"
        sx={{ display: "flex" }}
      >
        <EmailPage
          setEmailTemplatePop={setEmailTemplatePop}
          emailTemplatePop={emailTemplatePop}
        />
      </Box>}
      actionBtn={<AppBtn
        onClick={() => setEmailTemplatePop(true)}
        buttonText="Create Email Template"
      />}
    />
  )
}

export default EmailTemplate