import React from 'react';
import { Box, Typography } from "@mui/material";
import "./style.css";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";


//images
import loveEmoji from "../../../Assets/Images/emoji/love.png"
import confuseEmoji from "../../../Assets/Images/emoji/confuse.png"
import ultiEmoji from "../../../Assets/Images/emoji/ulti.png"
import greenTikEmoji from "../../../Assets/Images/emoji/greenTik.png"
import video from "../../../Assets/Images/Videos/part1.mp4";
import whiteImg from "../../../Assets/Images/whiteImg.png"
import arrow from "../../../Assets/Images/arrow.png";
import backArrow from "../../../Assets/Images/noun-back.png";


//component
import SideMenu from "../../../Components/Participants/SideMenu";
import { sideHaumIcon } from "../../../Assets/Data";
import { AppBtn, AppBtnOutLine, PurplelBtn } from '../../../Components/AppTools/AppButton';
import { IBox } from "../../../Components/AppTools/AppTool"
import BubbleChart from "../../../Components/AppTools/BubbleChart";


import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export default function VideoTask({ slideMenu, setSlideMenu, workspaceDrop }) {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const lineBar = 80;
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#4F33D1",
    },
  }));


  return (
    <>
      <Box className="ResearchersContainer imageActivityPage imageTask videoTask">
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon">
              {sideHaumIcon}
            </Box>
            <Box className="ontheDotNav">
              <PurplelBtn buttonText={t("commonTxt.previouspage")} />
              <AppBtn buttonText={t("commonTxt.nextPage")} />
            </Box>
          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer onthedot">
            <Box className="workspaceListBox">
              <Box mb={3} width="74px" onClick={() => Navigate("/participants/activities")} className="openBtn">
                <img src={backArrow} />
                <Typography className="neonBackText">Back</Typography>
              </Box>
              <Typography className='activeProgText'>{t("commonTxt.activityProgress")}</Typography>
              <Box className="lineBar">
                <Typography>{lineBar}%</Typography>
                <BorderLinearProgress variant="determinate" value={lineBar} />
              </Box>
              <Typography className="lineBarSubText">{t("onTheDro.TaskComp")}</Typography>

              <Box className="otdmainLabelBox">
                <Typography className='ActInSectionMainHeader'>{t("videoTask.videoTaskHeader")}</Typography>
                <Box className="skipBtnBox">
                  <Typography>Save as Draft</Typography>
                  <AppBtnOutLine buttonText="Skip Task" />
                </Box>
              </Box>
              <IBox text="Pause the video and tag the point in time with a comment. Please carefully select the type of tag for your comment" />
              <Typography className='imgUploadHeader'>{t("imageTask.viewBrand")}</Typography>
              <Box className="reviewImgBox videoBox">
                <video className="video" controls>
                  <source src={video} type="video/mp4" />
                </video>
              </Box>

              <Box className="emojiWText">
                <Typography className='imgBottominputLabel'>{t("videoTask.SelectTag")}</Typography>
                <Box className="emojiSection">
                  <img src={loveEmoji} />
                  <img src={ultiEmoji} />
                  <img src={confuseEmoji} />
                  <img src={greenTikEmoji} />
                </Box>

              </Box>
              <textarea className='massageInput' value="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown pri" />

              <Box my={3}></Box>

              <Typography className='imgBottominputLabel'>{t("ImageActivity.feelAbout")}</Typography>
              <textarea className='massageInput' placeholder="Enter your response here" />

              <Box className="lineDropBox">
                <Typography className='lineDLabel'>{t("imageTask.associatedBrand")}</Typography>

                <Box className="lineDrop">
                  <Box className="LDrop">
                    <Typography>{t("imageTask.VeryPoor")}</Typography>
                  </Box>
                  <Box className="LLine"></Box>
                  <Box className="LDrop">
                    <Typography>{t("imageTask.Poor")}</Typography>
                  </Box>
                  <Box className="LLine"></Box>
                  <Box className="LDrop">
                    <Typography>{t("imageTask.Okay")}</Typography>
                  </Box>
                  <Box className="LLine"></Box>
                  <Box className="LDrop">
                    <Typography>{t("imageTask.Good")}</Typography>
                  </Box>
                </Box>
              </Box>

              <Box className="workPupBtnBox">
                <PurplelBtn buttonText="Submit" />
              </Box>

              <Box mt={4} className="reacapVideoReviewNav">
                <Typography className="pageTitle">{t("videoTask.TagDistribution")}</Typography>
                <Typography className='underLineText'>{t("videoTask.ViewLess")}</Typography>
              </Box>

              <Box mt={2} className="bubbleChartBox">
                <BubbleChart />
              </Box>



              <Typography className='imgUploadHeader'>{t("imageTask.YourResponses")}</Typography>
              <Box className="yourResponceBox">
                <Box className="responce_Box">
                  <Typography>{t("imageTask.Response")} 01</Typography>
                  <Box mt={2} className="resInnerBox">
                    <PurplelBtn buttonText="00:50" />
                    <img src={loveEmoji} className='loveEmoji' />
                    <img src={whiteImg} />
                    <Box>
                      <Typography className='resHed'>{t("imageTask.Response")}</Typography>
                      <Typography>{t("imageTask.responseSummery")}</Typography>
                      <Typography className='resHed marginTop'>{t("videoTask.responce2")}</Typography>
                      <Typography>{t("videoTask.responce2Summery")}</Typography>
                    </Box>
                  </Box>
                </Box>


                <Box className="responce_Box">
                  <Typography>{t("imageTask.Response")} 02</Typography>
                  <Box mt={2} className="resInnerBox">
                    <PurplelBtn buttonText="00:50" />
                    <img src={loveEmoji} className='loveEmoji' />
                    <img src={whiteImg} />
                    <Box>
                      <Typography className='resHed'>{t("imageTask.Response")}</Typography>
                      <Typography>{t("imageTask.responseSummery")}</Typography>
                      <Typography className='resHed marginTop'>{t("videoTask.responce2")}</Typography>
                      <Typography>{t("videoTask.responce2Summery")}</Typography>

                    </Box>
                  </Box>
                </Box>
              </Box>


              <Box className="pagenation">
                <Box className="tabelBtn">
                  <img src={arrow} />
                  <Typography>{t("commonTxt.previouspage")}</Typography>
                </Box>

                <Box className="pageNumberBox">
                  <Box
                    className="pageNumber pageNumberActive">
                    <p>1</p>
                  </Box>
                  <Box
                    className="pageNumber">
                    <p>2</p>
                  </Box>
                  <Box
                    className="pageNumber">
                    <p>3</p>
                  </Box>
                  <Box
                    className="pageNumber">
                    <p>4</p>
                  </Box>
                  <Box
                    className="pageNumber">
                    <p>5</p>
                  </Box>
                </Box>

                <Box className="tabelBtn">
                  <Typography>{t("commonTxt.nextPage")}</Typography>
                  <img style={{ rotate: "180deg" }} src={arrow} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

    </>
  )
}
