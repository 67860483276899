import { Box } from '@mui/material'
import React from 'react'
import DirectoryCompanies from '../../../../Components/Admin/DirectoryCompanies'

const Company = () => {
  return (
    <Box
        sx={{ display: "flex" }}
        className="adminMainBox"
    >
        <DirectoryCompanies />
    </Box>
  )
}

export default Company