import React, { useState, useEffect } from 'react';
import { Box, Typography } from "@mui/material";
import "./style.css";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";


//images
import screenCaptureIcon from "../../../Assets/Images/screen-recording-4789699 1.png";
import screen1 from "../../../Assets/Images/screen1.png"
import screen2 from "../../../Assets/Images/screen2.png"
import screen3 from "../../../Assets/Images/screen3.png"
import traPageIcon from "../../../Assets/Images/TeraPage Transparent mix black.png"
import noteIcon from "../../../Assets/Images/noteAppIcon.png"
import browserIcon from "../../../Assets/Images/browserIcon.png"
import { redIIcon } from "../../../Assets/Data"
import trasIcon from "../../../Assets/Images/fluent_delete-16-regular.png";
import recordScreenIcon from "../../../Assets/Images/recordScreenIcon.png"
import backArrow from "../../../Assets/Images/noun-back.png";



//component
import SideMenu from "../../../Components/Participants/SideMenu";
import { sideHaumIcon } from "../../../Assets/Data";
import { AppBtn, AppBtnOutLine, PurplelBtn } from '../../../Components/AppTools/AppButton';
import { IBox } from "../../../Components/AppTools/AppTool"

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export default function ScreenCaptureActivity({ slideMenu, setSlideMenu, workspaceDrop }) {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [recordPop, setRecortPop] = useState(false);
  const [chooseScreen, setChooseScreen] = useState();
  const [chooseScreenRedio, setChooseScreenRedio] = useState(0)
  const [screenVideo, setScreenVideo] = useState(false)


  const lineBar = 80;
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#4F33D1",
    },
  }));


  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setRecortPop(false);
    }
  };


  useEffect(() => {
    if (recordPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [recordPop]);



  const RecordPop = () => {
    return (
      <Box id="popBackdrop" onClick={handelPopClose} className="workspacePop recordPop" sx={{ display: recordPop ? "flex" : "none" }}>

        <Box className="recordPopInnerBox"
          sx={{ display: recordPop ? "flex" : "none" }}
          width={chooseScreenRedio === 1 ? "60%" : "416px"}
          header={chooseScreenRedio === 1 ? "auto" : "248px"}
        >
          <Typography className='workspaceHeader'>{t("ScreenCActivity.ScreenRecording")}</Typography>
          <Box className="pbcRedioRow">
            <Box>
              <input
                className="emailSettingInput emailSettingRadio"
                type='radio'
                name='projectState'
                onChange={() => setChooseScreenRedio(0)}
                checked={chooseScreenRedio === 0 ? true : false}
              />
              <Typography>{t("ScreenCActivity.entireScreen")}</Typography>
            </Box>
          </Box>

          <Box className="pbcRedioRow">
            <Box>
              <input
                className="emailSettingInput emailSettingRadio"
                type='radio'
                name='projectState'
                onChange={() => setChooseScreenRedio(1)}
                checked={chooseScreenRedio === 1 ? true : false}

              />
              <Typography>{t("ScreenCActivity.Window")}</Typography>
            </Box>
          </Box>

          <Typography display={chooseScreenRedio === 1 ? "block" : "none"} mt={4}>{t("ScreenCActivity.Selectwindowrecord")}</Typography>

          <Box className="chooseScreenBox" sx={{ display: chooseScreenRedio === 1 ? "flex" : "none" }}>
            <Box onClick={() => setChooseScreen(0)} className={chooseScreen === 0 ? "window windowActive" : "window"}>
              <img className='screenImg' src={screen1} />
              <Box className="screenNameBox">
                <img src={traPageIcon} />
                <Typography>{t("ScreenCActivity.Terapage")}</Typography>
              </Box>
            </Box>
            <Box onClick={() => setChooseScreen(1)} className={chooseScreen === 1 ? "window windowActive" : "window"}>
              <img className='screenImg' src={screen2} />
              <Box className="screenNameBox">
                <img src={noteIcon} />
                <Typography>{t("ScreenCActivity.NoteApp")}</Typography>
              </Box>
            </Box>
            <Box onClick={() => setChooseScreen(2)} className={chooseScreen === 2 ? "window windowActive" : "window"}>
              <img className='screenImg' src={screen3} />
              <Box className="screenNameBox">
                <img src={browserIcon} />
                <Typography>{t("ScreenCActivity.Browser")}</Typography>
              </Box>
            </Box>
          </Box>

          <Typography display={chooseScreenRedio === 1 ? "block" : "none"} mt={3} className='redWornText'>{redIIcon}{t("ScreenCActivity.TroubleRecording")}</Typography>
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={() => setRecortPop(false)} />
            <AppBtn disabled={![0, 1, 2].includes(chooseScreen)} buttonText="Start Recording" onClick={() => {
              setScreenVideo(true)
              setRecortPop(false)
            }} />
          </Box>
        </Box>
      </Box>
    )
  }



  return (
    <>
      <Box className="ResearchersContainer imageActivityPage">
        <RecordPop />
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon">
              {sideHaumIcon}
            </Box>

            <Box className="ontheDotNav">
              <PurplelBtn buttonText={t("commonTxt.previouspage")} />
              <AppBtn buttonText={t("commonTxt.nextPage")} />
            </Box>
          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer onthedot">
            <Box className="workspaceListBox">
              <Box mb={3} width="74px" onClick={() => Navigate("/participants/activities")} className="openBtn">
                <img src={backArrow} />
                <Typography className="neonBackText">Back</Typography>
              </Box>
              <Typography className='activeProgText'>{t("commonTxt.activityProgress")}</Typography>
              <Box className="lineBar">
                <Typography>{lineBar}%</Typography>
                <BorderLinearProgress variant="determinate" value={lineBar} />
              </Box>
              <Typography className="lineBarSubText">{t("onTheDro.TaskComp")}</Typography>

              <Box className="otdmainLabelBox">
                <Typography className='ActInSectionMainHeader'>{t("ScreenCActivity.screenCHeader")}</Typography>
                <Box className="skipBtnBox">
                  <Typography>{t("onTheDro.SaveDraft")}</Typography>
                  <AppBtnOutLine buttonText="Skip Task" />
                </Box>
              </Box>
              <IBox text="The important information for this task will be displayed here" />
              <Typography className='imgUploadHeader'>{t("VideoActivity.uploadLabel")}<span>*</span></Typography>


              {screenVideo ?
                <>
                  <Box className="ipageUplod">
                    <Box className="imgBox">
                      <img className="fileinputimg" src={chooseScreen === 0 ? screen1 : chooseScreen === 1 ? screen2 : chooseScreen === 2 ? screen3 : null} alt="Upload" />
                    </Box>

                    <Box className="mediaInputText">
                      <Box className="textBox iaTextBox">
                        <Typography className="teraText">
                          {t("ScreenCActivity.ScreenRUploaded")}
                        </Typography>
                        <Box mb={2}>
                          <img src={recordScreenIcon} />
                          <Typography ml={2}>{t("ScreenCActivity.RecordScreen")}</Typography>
                        </Box>
                        <Box>
                          <img src={trasIcon} />
                          <Typography ml={2} >{t("commonTxt.delete")}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Typography className='imgBottominputLabel'>{t("ImageActivity.feelAbout")}</Typography>
                  <textarea className='massageInput' placeholder={t("ImageActivity.messageEnter")} />
                </>
                :
                <Box className="uploadBox">
                  <Box className="imgUplodInputBox" onClick={() => setRecortPop(true)}>
                    <input
                      id='fileInput'
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }} />
                    <img src={screenCaptureIcon} alt="Camera" />
                  </Box>
                  <Typography
                    className='imageUploadLabelText'><span>{t("ScreenCActivity.captureText")} </span></Typography>
                </Box>
              }
            </Box>
          </Box>
        </Box>
      </Box>

    </>
  )
}
