import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import axios from '../../../lib/axios';
//images

export default function DeleteObserverPop({ deleteObserverPop, setDeleteObserverPop, deleteObserversListData, setReloadList }) {
 
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
        setDeleteObserverPop(false);
    }
  };

  const handelDeletePop = () => {
    setDeleteObserverPop(false);
      return new Promise((resolve, reject) => {
          axios.delete(`/super-admin/admin/observers/delete/${deleteObserversListData?._id}`).then((res) => {
              if (res.success) {
                  resolve({
                      success: true,
                      message: res.message
                  });
                  toast.success(res.message, TOAST_CONFIG);
                  setReloadList(true);
              } else {
                  reject({
                      success: false,
                      message: res.message
                  });
                  toast.success(res.message, TOAST_CONFIG);
              }
          }).catch((err) => {
              reject({
                  success: false,
                  message: err.message
              });
          })
      });
  };

  useEffect(() => {
    if (deleteObserverPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [deleteObserverPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: deleteObserverPop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople"
      >
        <Box
          sx={{ display: deleteObserverPop ? "flex" : "none" }}
          className="workspaceContaint deletePagePop"
        >
          <Typography className="companyHeader">Delete Observer</Typography>

          <Box className="basicBoxItem">
            <Typography className='addPageLabel'>Are you sure you want to delete the {`"${deleteObserversListData?.name}"`}   from Terapage ?</Typography>
            
          </Box>
          
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={() => setDeleteObserverPop(false)} />
            <AppBtn buttonText="Delete" onClick={handelDeletePop}/>
          </Box>



        </Box>
      </Box>
    </>
  );
}
