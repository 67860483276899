import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./style.css";

import imgCmainImg from "../../../Assets/Images/imgCmainImg.png"
import backArrow from "../../../Assets/Images/noun-back.png";
import exportIcon from "../../../Assets/Images/exportIcon.png";
import map from "../../../Assets/Images/map.png"
import avatar from "../../../Assets/Images/profile-picture.png"
import avatar1 from "../../../Assets/Images/inb1.png"
import avatar2 from "../../../Assets/Images/inb2.png"
import avatar3 from "../../../Assets/Images/inb3.png"
import editBtn from "../../../Assets/Images/edit 1.png"
import whitedropIcon from "../../../Assets/Images/Wchevron-down.png";
import semoji from "../../../Assets/Images/Semoji.png"
import imgIcon from "../../../Assets/Images/galleryIconB.png"

//comments
import SideMenu from "../../../Components/Participants/SideMenu";
import { LatestCreated } from "../../../Components/AppTools/AppTool";

//data
import { languages, recapNavItem, sideHaumIcon } from "../../../Assets/Data";

export default function ViewRecapImage({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
  selectNavItem,
  setSelectNavItem,
}) {
  const Navigate = useNavigate();
  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Recently Added");

  const [searchParams, setSearchParams] = useSearchParams();
  const [hideComment, setHideComment] = useState(true)
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();


  const [providerDrop, setProviderDrop] = useState(false)
  const [providerDropVal, setProviderDropVal] = useState()

  const [providerDrop1, setProviderDrop1] = useState(false)
  const [providerDropVal1, setProviderDropVal1] = useState()

  const [providerDrop2, setProviderDrop2] = useState(false)
  const [providerDropVal2, setProviderDropVal2] = useState()

  const [providerDrop3, setProviderDrop3] = useState(false)
  const [providerDropVal3, setProviderDropVal3] = useState()

  const responseArrya = [
    {
      label: "Recent Responses",
    },
    {
      label: "Oldest Responses",
    },
    {
      label: "Recent Comments",
    },
  ];

  const navItem = [
    {
      title: "Thread",
      qstring: "thread",
    },
    {
      title: "Expressions",
      qstring: "expressions",
    },
    {
      title: "Images",
      qstring: "images",
    },
    {
      title: "Videos",
      qstring: "videos",
    },
    {
      title: "Audios",
      qstring: "audios",
    },
  ];

  function wrapAtWordsWithSpan(text) {
    return text.replace(/@(\w+)/g, "<span>@$1</span>");
  }

  const singelComment = ({ img, name, comment, date, time }) => {
    return (
      <Box className="singelComment">
        <Box className="commentSection innerCommentSection">
          <Box className="avatarBox">
            <img src={img} />
          </Box>
          <Box className="commentTextSection">
            <Box className="userNameBox">
              <Typography className='userName'>{name}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "15px",
                  minWidth: "213px"
                }}
              >
                <Box className="editbtn">
                  <img src={editBtn} />
                  <Typography>{t("commonTxt.edit")}</Typography>
                </Box>
                <samp className="taskHeaderText3">
                  {date}
                </samp>
              </Box>
            </Box>

            <Typography className='commentWtag' dangerouslySetInnerHTML={{ __html: wrapAtWordsWithSpan(comment) }}></Typography>
            <Box className="likeBox">
              <Typography>{time}</Typography>
              <Typography>{t("commonTxt.Like")}</Typography>
              <Typography>{t("commonTxt.dislike")}</Typography>
              <Typography>{t("commonTxt.reply")}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

    )
  }



  const writeCommentSection = ({ img, postDrop, setPostDrop, postDropVal, setPostDropVal }) => {
    return (
      <Box className="writeCommentSection">
        <Box className="avatarBox">
          <img src={img} />
        </Box>
        <Box className="commentInputBox">
          <input className='phoneInput' placeholder={t("commonTxt.addComment")} />
          <img className='inputIcon1' src={semoji} />
          <img className='inputIcon2' src={imgIcon} />
          <Box className="commentCheckBox">
            <Box
              mb={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                minWidth: "206px"
              }}
            >
              <input
                style={{ marginRight: "10px" }}
                className="custom-checkbox"
                type="checkBox"
              />
              <Typography>{t("recapThread.makeReplyCompulsory")}</Typography>
            </Box>

            <Typography className='followText'>{t("emailPage.temp")}</Typography>
          </Box>
        </Box>

        <Box className="directMessageNavBtn" onClick={() => setPostDrop(!postDrop)}>
          <Typography className='DropBtnText'>{t("commonTxt.postBtn")}</Typography>
          <img src={whitedropIcon} style={{ rotate: postDrop ? "180deg" : "0deg" }} />
          <Box className="workspaceItemNavPop" sx={{ display: postDrop ? "flex" : "none" }}>
            <Box onClick={() => setPostDropVal("Visible to Everyone")}>
              <Typography>{t("recapThread.visibleToEveryone")}</Typography>
            </Box>
            <Box onClick={() => setPostDropVal("Only Andi Lane")}>
              <Typography>{t("recapThread.onlyAndiLane")}</Typography>
            </Box>
            <Box onClick={() => setPostDropVal("Backroom Comment")}>
              <Typography>{t("recapThread.backroomComment")}</Typography>
            </Box>
            <Box onClick={() => setPostDropVal("Backroom Task")}>
              <Typography>{t("recapThread.backroomTask")}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }


  const nestedCommentSection = ({ img, name, comment, date, time, postDrop, setPostDrop, postDropVal, setPostDropVal }) => {
    return (
      <Box className="singelComment">
        <Box className="commentSection innerCommentSection">
          <Box className="avatarBox">
            <img src={img} />
          </Box>
          <Box className="commentTextSection">
            <Box className="userNameBox">
              <Typography className='userName'>{name}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "15px",
                  minWidth: "213px"
                }}
              >
                <Box className="editbtn">
                  <img src={editBtn} />
                  <Typography>{t("commonTxt.edit")}</Typography>
                </Box>
                <samp className="taskHeaderText3">
                  {date}
                </samp>
              </Box>
            </Box>

            <Typography className='commentWtag' dangerouslySetInnerHTML={{ __html: wrapAtWordsWithSpan(comment) }}></Typography>
            <Box className="likeBox">
              <Typography>{time}</Typography>
              <Typography>{t("commonTxt.Like")}</Typography>
              <Typography>{t("commonTxt.dislike")}</Typography>
              <Typography>{t("commonTxt.reply")}</Typography>
            </Box>
            {singelComment({
              img: avatar3,
              name: "Kate Morrison",
              comment: "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
              date: "Aug 10, 2023",
              time: "10 h"
            })}
            {writeCommentSection({ img: avatar1, postDrop, setPostDrop, postDropVal, setPostDropVal })}
          </Box>
        </Box>
      </Box>

    )
  }


  useEffect(() => {
    let selTab = searchParams.get("r");
    if (selTab != null) {
      var query_string = selTab;
      query_string = query_string.replaceAll("_", " ");
      query_string = query_string.replace(/(^\w|\s\w)/g, (firstCharOfWord) =>
        firstCharOfWord.toUpperCase()
      );
      if (selTab === "data_charts") {
        var query_string = "Data & Charts";
        setSelectNavItem(query_string);
      } else {
        setSelectNavItem(query_string);
      }
    }
  }, []);

  return (
    <>
      <Box className="ResearchersContainer recapPage">
        {/* side menu */}
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>

            <Box sx={{gap:"8% !important;"}} className="recapNavItems">
              {navItem?.map((el, i) => (
                <Box
                  key={i}
                  onClick={() => {
                    setSearchParams({ r: el.qstring });
                    setSelectNavItem(el.title);
                  }}
                  className={
                    selectNavItem === el.title
                      ? "recapNavItem recapNavItemActive"
                      : "recapNavItem"
                  }
                >
                  <Typography>{el.title}</Typography>
                </Box>
              ))}
            </Box>
            <Box width={"22%"} minWidth={"187px"} className="agreSearchB2">
              <LatestCreated
                setLatestDrop={setLatestDrop}
                latestCDrop={latestCDrop}
                setLatestDropVal={setLatestDropVal}
                latestDropVal={latestDropVal}
                itemAry={responseArrya}
              />
            </Box>
          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer">
            <Box className="recapImgComment recapVideoComment workspaceListBox">
              <Box mb={2} className="openBtn">
                <Box
                  onClick={() => Navigate("/participants/recap?r=images")}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img src={backArrow} />
                  <Typography className="neonBackText">{t("commonTxt.backBtn")}</Typography>
                </Box>
              </Box>

              <Box className="imgcNavBtnBox">
                <Box className="mapB">
                  <img src={map} />
                  <Typography className="mapText">{t("ParticipantsThreadRecap.PerfectOutdoor")}</Typography>
                </Box>
                <Box className="actTNavBtn" >
                  <img src={exportIcon} />
                  <Typography>{t("commonTxt.exportLink")}</Typography>
                </Box>
              </Box>


              <Box className="imgcUserInfoBox">
                <Box className="userInfo_Box">
                  <Box className="userimg">
                    <img src={avatar} />
                  </Box>
                  <Box >
                    <Typography className='userName'>Andi Lane</Typography>
                    <Typography className='userEmail'>lane_andi012@gmail.com</Typography>

                  </Box>
                </Box>
                <Typography className='taskHeaderText3'>06 Aug, 06:41 p.m.</Typography>
              </Box>

              <Box className="mainImgBox">
                <img src={imgCmainImg} />
              </Box>

              <Typography>{t("ParticipantsThreadRecap.vImgSummery1")}</Typography>
              <Box className="gapBox"></Box>
              <Typography>{t("ParticipantsThreadRecap.vImgSummery2")}</Typography>


              <Typography className="followText" onClick={() => setHideComment(!hideComment)}>{hideComment ? t("commonTxt.hideComments") : t("commonTxt.showComment")}</Typography>
              {/* Comments */}
              <Box className="sectionBox">
                <Box className="commentListBox" sx={{ display: hideComment ? "block" : "none" }}>
                  {nestedCommentSection({
                    img: avatar1,
                    name: "Orlando Diggs",
                    comment: "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                    date: "Aug 10, 2023",
                    time: "10 h",
                    postDrop: providerDrop,
                    setPostDrop: setProviderDrop,
                    postDropVal: providerDropVal,
                    setPostDropVal: setProviderDropVal
                  })}
                  {nestedCommentSection({
                    img: avatar2,
                    name: "Orlando Diggs",
                    comment: "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                    date: "Aug 10, 2023",
                    time: "10 h",
                    postDrop: providerDrop1,
                    setPostDrop: setProviderDrop1,
                    postDropVal: providerDropVal1,
                    setPostDropVal: setProviderDropVal1
                  })}
                  {nestedCommentSection({
                    img: avatar3,
                    name: "Orlando Diggs",
                    comment: "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                    date: "Aug 10, 2023",
                    time: "10 h",
                    postDrop: providerDrop2,
                    setPostDrop: setProviderDrop2,
                    postDropVal: providerDropVal2,
                    setPostDropVal: setProviderDropVal2
                  })}
                  {nestedCommentSection({
                    img: avatar2,
                    name: "Orlando Diggs",
                    comment: "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                    date: "Aug 10, 2023",
                    time: "10 h",
                    postDrop: providerDrop3,
                    setPostDrop: setProviderDrop3,
                    postDropVal: providerDropVal3,
                    setPostDropVal: setProviderDropVal3
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
