import React, { useEffect } from 'react'
import "./style.css";
import { Box, Typography } from '@mui/material';

//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton"
import ReferenceDateUsingValue from "../../AppTools/DatePicker";
import ReferenceDateExplicitTimePicker from "../../AppTools/TimePicker";

export default function ScheduleMailPop({ scheduleMailPop, setScheduleMailPop }) {


  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setScheduleMailPop(false)
    }
  }



  useEffect(() => {
    if (scheduleMailPop) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = 'auto'; // Enable scrolling
    }
  }, [scheduleMailPop])




  return (
    <>
      <Box onClick={handelPopClose} id="popBackdrop" sx={{ display: scheduleMailPop ? "flex" : "none" }} className="workspaceDeletePopContainer">

        <Box className="workspaceDeleteBox workspaceDeletePopTextAlign">

          <Typography className='deletePopHeader'>Schedule for Letter</Typography>

          <Box className="deleteTextBox">
            <Box>
              <Typography className="workspaceLabel">Select Date/Time</Typography>
              <Box className="pickerBox">
                <ReferenceDateUsingValue />
              </Box>
              <Box className="pickerBox">
                <ReferenceDateExplicitTimePicker placeholder="Select Start Time" />
              </Box>
            </Box>

          </Box>

          <Box className="deleteBtnBox">
            <AppBtnOutLine onClick={() => setScheduleMailPop(false)} buttonText="Cancel" />
            <AppBtn buttonText="Save" />
          </Box>
        </Box>


      </Box>
    </>
  )
}
