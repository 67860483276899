import React, { useState, useEffect, useRef } from "react";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import audioWave from "../../../Assets/Images/audioWav.png";
import { useTranslation } from "react-i18next";
import noCamera from "../../../Assets/Images/there-is-no-connected-camera.jpg";
import "./style.css";
import axios from '../../../lib/axios';
// images
import videoTham from "../../../Assets/Images/c9b1cfbd77205f8e197ea72402a5bffb.png";
import videoIcon from "../../../Assets/Images/GvideoIcon.png";
import micOffIcon from "../../../Assets/Images/mic-off.png";
import micIcon from "../../../Assets/Images/Gmic.png";
import headPhones from "../../../Assets/Images/BlackHeadphones.png";

// components
import { AppBtnOutLine, AppBtn } from "../../../Components/AppTools/AppButton";
import { useAppSelector } from "../../../lib/store/store";
import { getUserRoleType } from "../../../lib/helpers";

import AgoraRTC from "agora-rtc-sdk-ng";
import { MicVolumeDisplay } from "../../AppTools/AppTool";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../env";

export default function LiveInterViewPop({
  liveInterViewPop,
  setLiveInterViewPop,
  scheduleId,
  setScheduleId
}) {
  const [participantName, setParticipantName] = useState(null);
  const [allLanguage, setAllLanguage] = useState([]);
  const [scheduleData, setScheduleData] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [userType, setUserType] = useState(null);
  const [selectedLenguage, setSelectedLenguage] = useState(null);
  const [videoTrack, setVideoTrack] = useState(null);
  const [cameraWorking, setCameraWorking] = useState(false);
  const [audioWorking, setAudioWorking] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const videoRef = useRef(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [agoraCredentials, setAgoraCredentials] = useState({});
  const [meetingToken, setMeetingToken] = useState(null)
  const [uuid, setUuid] = useState(null)
  const [rmId, setRmId] = useState(null)
  const [chatAppUserToken, setChatAppUserToken] = useState(null)
  const [appToken, setAppToken] = useState(null)

  const [analyser, setAnalyser] = useState(null);
  const [micStream, setMicStream] = useState(null);
  const [micLevel, setMicLevel] = useState(0);

  const roleItem = [
    { name: "Host Meeting", value: "Host Meeting", shouldDisable: false },
    { name: "Backroom observer", value: "Backroom observer", shouldDisable: false },
    { name: "Participant", value: "Participant", shouldDisable: true },
  ];

  useEffect(() => {
    if (liveInterViewPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
      getSettings();
      getMeeting();
      checkMediaDevices();
      getCredentials();
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [liveInterViewPop]);

  const { t } = useTranslation();
  const authState = useAppSelector(state => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace);

  const stopVideo = async () => {
    if (videoRef.current && cameraWorking) {
      videoRef.current.srcObject = null;
      setCameraWorking(false);
      videoTrack.setEnabled(false)
    } else {
      checkMediaDevices();
    }
  };

  const startMic = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const microphone = audioContext.createMediaStreamSource(stream);
      const analyser = audioContext.createAnalyser();

      microphone.connect(analyser);
      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      // Define a noise threshold
      const noiseThreshold = 20; // Adjust this value based on your needs

      setAnalyser(analyser);
      setMicStream(stream);
      setIsSpeaking(true);

      const updateMicLevel = () => {
        analyser.getByteFrequencyData(dataArray);
        const sum = dataArray.reduce((acc, value) => acc + value, 0);
        const average = sum / dataArray.length;
        console.log("average", average);

        // Apply noise gate filter
        if (average > noiseThreshold) {
          setMicLevel(average);
        } else {
          setMicLevel(0); // Set to 0 if below threshold
        }

        requestAnimationFrame(updateMicLevel);
      };

      updateMicLevel();
    } catch (error) {
      console.error("Error starting microphone:", error);
    }
  };


  const stopMic = () => {
    if (micStream) {
      micStream.getTracks().forEach((track) => track.stop());
    }
    setIsSpeaking(false)
  };

  const toggleAudio = () => {
    if (isSpeaking) {
      stopMic()
    } else {
      startMic()
    }
  }

  useEffect(() => {
    return () => {
      stopMic(); // Cleanup when the component unmounts
    };
  }, []);

  const handelOpenMetting = () => {
    setLiveInterViewPop(false);
    //${authState?.userProfile?.is_researcher === 1 ? "/researchers" : "/collaborator"}
    if (meetingToken)
      window.open(`/activities/interview-meeting/${scheduleId}?pid=${encodeURIComponent(meetingToken)}&aptkn=${encodeURIComponent(appToken)}&uuid=${uuid}&rmId=${rmId}&u=${userType}&ctukn=${encodeURIComponent(chatAppUserToken)}&v=${cameraWorking}&a=${isSpeaking}`, "_blank");
    else
      toast.error("Meeting token is required.")
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setLiveInterViewPop(false);
    }
  };

  const checkMediaDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices.filter(device => device.kind === 'audioinput');
      const videoDevices = devices.filter(device => device.kind === 'videoinput');

      if (videoDevices.length > 0) {
        try {
          const cameraTrack = await AgoraRTC.createCameraVideoTrack();
          if (cameraTrack) {
            setVideoTrack(cameraTrack);
            if (videoRef.current) {
              cameraTrack.play(videoRef.current);

              cameraTrack.on("track-stopped", () => {
                console.warn("Camera track stopped.");
                setCameraWorking(false);
              });

              cameraTrack.on("error", (error) => {
                console.error("Camera track error:", error);
                setCameraWorking(false);
              });

              setCameraWorking(true);
            } else {
              console.error("Video reference is not available.");
              setCameraWorking(false);
            }
          } else {
            console.error("Failed to create camera track.");
            setCameraWorking(false);
          }
        } catch (error) {
          console.error("Error creating camera track:", error);
          setCameraWorking(false);
        }
      } else {
        console.warn("No video devices found.");
        setCameraWorking(false);
      }
    } catch (error) {
      console.error("Error accessing media devices:", error);
      setCameraWorking(false);
      setAudioWorking(false);
    }
  };



  const getMeeting = async () => {
    if (scheduleId) {
      const url = `researchers/interview-schedule?_id=${scheduleId}`;
      const res = await axios.get(url);
      if (res.status && res.data.data.length > 0) {
        const schedule = res.data.data[0];
        const userType = getUserRoleType(authState.userProfile);
        const isHost = schedule.hosts.find(el => el === authState.userProfile._id);
        if (userType === "Participant") {
          setSelectedRole(userType);
          setUserType("participant")
          getToken(userType.toLocaleLowerCase());
        } else if (isHost) {
          setSelectedRole("Host Meeting");
          getToken("host_meeting");
          setUserType("host")
        } else {
          setSelectedRole("Backroom observer");
          getToken("backroom_observer");
          setUserType("backroom")
        }
        setScheduleData(schedule);
        setParticipantName(schedule.participantDetails[0].name);
      }
    }
  };

  const getSettings = async () => {
    const url = `researchers/workspace-settings?workspace_id=${workspaceState?.selectedWorkspace?._id}`;
    const res = await axios.get(url);
    if (res.status && res.data.data.length > 0) {
      const settings = res.data.data[0];
      const languages = [...settings.defaultLanguage, ...settings.secondaryLanguages];
      setAllLanguage(languages);
      setSelectedLenguage(settings.defaultLanguage[0]._id);
    }
  };

  const getCredentials = async () => {
    const url = `super-admin/admin/settings?name=api_credentials`;
    const res = await axios.get(url);
    if (res.status && res.data.data.length > 0) {
      const setting = res.data.data[0]
      if (setting && setting.value && setting.value.agora_credentials) {
        setAgoraCredentials(setting.value.agora_credentials);
      }
    }
  }

  const getToken = async (type) => {
    const url = `researchers/generate-meeting-token`;
    const res = await axios.post(url, {
      schedule_id: scheduleId,
      user_type: type
    });
    if (res.status) {
      setIsDisabled(false);
      setMeetingToken(res.data.token)
      setUuid(res.data.uuid)
      setRmId(res.data.chatRoomId)
      setChatAppUserToken(res.data.chatUserToken)
      setAppToken(res.data.appToken)
    } else {
      setLiveInterViewPop(false);
      toast.error(res.message, TOAST_CONFIG);
    }
  }

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: liveInterViewPop ? "flex" : "none" }}
        className="companyPop liveInterView"
      >
        <Box
          sx={{ display: liveInterViewPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className="livePHeader">{t("LiveInterview.pageHeader")} : {participantName}</Typography>
          <Typography className="lavel_L">{t("liveInterviewPop.joinAsLabel")}</Typography>
          <FormControl sx={{ width: '100%' }}>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedRole}
              onChange={e => setSelectedRole(e.target.value)}
              disabled={selectedRole === "Participant"}
            >
              {roleItem.map((item, index) => (
                <MenuItem value={item.value} key={index} disabled={item.shouldDisable}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography className="lavel_L">{t("liveInterviewPop.languageYouSpeakLabel")}</Typography>
          <FormControl sx={{ width: '100%' }}>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedLenguage}
              onChange={e => setSelectedLenguage(e.target.value)}
            >
              {allLanguage.map((item, index) => (
                <MenuItem value={item._id} key={index} disabled={item.shouldDisable}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box className="videoThumBox">
            <video ref={videoRef} autoPlay style={{ width: '100%', height: 'auto', display: cameraWorking ? "block" : 'none' }} />
            {!cameraWorking && <img src={noCamera} />}
          </Box>

          <Box className="mediaInputBox">
            <Box onClick={stopVideo} ><img src={videoIcon} /></Box>
            <Box onClick={toggleAudio}>
              {!isSpeaking ? <img style={{ width: '30px', height: '30px' }} src={micOffIcon} /> : <img src={micIcon} />}</Box>

          </Box>
          <Box className="headPhoneRecomanded">
            <img src={headPhones} />
            <Typography>{t("liveInterviewPop.headphonesRecommendedText")}</Typography>
          </Box>
          <Typography className="csbText" >{t("liveInterviewPop.facingIssueText")}</Typography>

          <Box className="audioWave">
            {isSpeaking ? <>
              <progress id="file" sx={{ width: '100%' }} value={Math.floor(micLevel % 30)} max="100">{Math.floor(micLevel % 30)}</progress>
            </> : <Typography style={{ color: 'red' }}>Microphone is off or not accessible</Typography>}

          </Box>
          <Typography className="recordingText">{t("liveInterviewPop.recordingText")}</Typography>
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => setLiveInterViewPop(false)} />
            <AppBtn buttonText={t("commonTxt.joinMeetingBtn")} disabled={isDisabled} onClick={handelOpenMetting} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
