import React, { useState } from "react";
import { Box, Typography, Button, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DropArrow from "../../../Assets/Images/chevron-down.png";

import { AppBtnOutLine, AppBtn } from "../../AppTools/AppButton";

import backIcon from "../../../Assets/Images/icon-arrow.svg";
import deleteIcon from "../../../Assets/Images/icon-delete-red.svg";

export default function SettingEnhancedGroup() {
  const [projectvisibility, setprojectvisibility] = React.useState(0);
  const [enhancedSettng, setEnhancedSetting] = useState(false);
  const [attribute, setattribute] = React.useState(0);
  const [scale, setscale] = React.useState(0);
  const [value, setvalue] = React.useState(0);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const Navigate = useNavigate();

  const handleRouting = (Url) => {
    Navigate(Url);
  };

  const handleChange = (event) => {
    setprojectvisibility(event.target.value);
  };

  const [addprojectparticipants, setaddprojectparticipants] = React.useState(0);

  const handleChange2 = (event) => {
    setaddprojectparticipants(event.target.value);
  };

  const [removeprojectparticipants, setremoveprojectparticipants] =
    React.useState(0);

  const handleChange3 = (event) => {
    setremoveprojectparticipants(event.target.value);
  };

  const handleChange4 = (event) => {
    setattribute(event.target.value);
  };

  const handleChange5 = (event) => {
    setscale(event.target.value);
  };

  const handleChange6 = (event) => {
    setvalue(event.target.value);
  };

  const [colorSelectorDrop1, setColorSelectorDrop1] = useState(false);
  const [colorSelectorDrop1Val, setColorSelectorDrop1Val] =
    useState("Solid Color");

  return (
    <Box className="SettingsFormSection">
      <Box
        onClick={() => handleRouting("/workspace-administration/group")}
        className="openBtn"
      >
        <img src={backIcon} />
        <Typography className="neonBackText">
          {t("commonTxt.backBtn")}
        </Typography>
      </Box>

      <Box className="HeadingOptions">
        <Typography variant="h4">
          {t("groups.settings")}: {t("groups.enhanceGrpBtn")}
        </Typography>
        <Box
          onClick={() => setColorSelectorDrop1(!colorSelectorDrop1)}
          width={"250px"}
          height={"40px"}
          px={2}
          className="statusBox"
        >
          <Typography>{t("groups.option")}</Typography>
          <img src={DropArrow} />
          {/* drop */}
          <Box
            sx={{ height: colorSelectorDrop1 ? "auto" : "0px" }}
            className="statusPop"
          >
            <Box
              onClick={() =>
                setColorSelectorDrop1Val(t("Remove Personal Data"))
              }
              className="statusPopItem"
            >
              <Typography>
                {<DoDisturbIcon />} {t("groups.remPersonalData")}
              </Typography>
            </Box>
            <Box
              onClick={() => setColorSelectorDrop1Val("Delete")}
              className="statusPopItem"
            >
              <Typography>
                {<DeleteOutlineIcon />} {t("commonTxt.delete")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Grid container spacing={2}>
        <Grid item sm={6}>
          <Typography className="greybox">
            {t("groups.grpId")}: <span>156559</span>
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography className="greybox">
            {t("groups.updated")}: <span>Jul 2, 2023; 12:02 PM</span>
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography className="greybox">
            {t("groups.participants")}: <span>3</span>
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography className="greybox">
            {t("directoryUsers.added")}: <span>Jun 5, 2023; 12:02 PM</span>
          </Typography>
        </Grid>
      </Grid>

      <Box className="FormField">
        <Typography variant="h5">{t("groups.basics")}</Typography>
        <TextField
          fullWidth
          id="outlined-basic"
          placeholder={t("groups.enterBasics")}
          variant="outlined"
          size="small"
          defaultValue={"Age 18 - 25"}
        />
      </Box>

      <Box className="FormField">
        <Typography variant="h5">{t("groups.advancedOptions")}</Typography>
        <Typography variant="h6">{t("groups.projectVisibility")}</Typography>
        <Typography>{t("groups.texts")}</Typography>
        <FormControl sx={{ width: "100%" }} size="small">
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={projectvisibility}
            onChange={handleChange}
          >
            <MenuItem value={0}>All Projects</MenuItem>
            <MenuItem value={10}>Projects 01</MenuItem>
            <MenuItem value={20}>Projects 02</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box className="FormField">
        <Typography variant="h6">{t("groups.label1")}</Typography>
        <Typography>{t("groups.label2")}</Typography>
        <FormControl sx={{ width: "100%" }} size="small">
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={addprojectparticipants}
            onChange={handleChange2}
          >
            <MenuItem value={0}>None</MenuItem>
            <MenuItem value={10}>Projects 01</MenuItem>
            <MenuItem value={20}>Projects 02</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box className="FormField">
        <Typography variant="h6">{t("groups.label3")}</Typography>
        <Typography>{t("groups.label4")}</Typography>
        <FormControl sx={{ width: "100%" }} size="small">
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={removeprojectparticipants}
            onChange={handleChange3}
          >
            <MenuItem value={0}>None</MenuItem>
            <MenuItem value={10}>Projects 01</MenuItem>
            <MenuItem value={20}>Projects 02</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box className="FormField">
        <Typography variant="h5">{t("groups.enhancedGrpCriteria")}</Typography>
        <FormControlLabel
          control={<Switch />}
          label="Match only if all criteria apply"
        />
      </Box>

      <Box className="FormField flexEnd">
        <Button className="deleteBtn" variant="text">
          <img src={deleteIcon} />
        </Button>

        <Typography className="enhancedGroupDetBtn">
          {t("commonTxt.delete")}
        </Typography>
      </Box>

      <Box className="FormField">
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography>{t("groups.attribute")}: 1</Typography>
            <FormControl sx={{ width: "100%" }} size="small">
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={attribute}
                onChange={handleChange4}
              >
                <MenuItem value={0}>{t("groups.pii")}</MenuItem>
                <MenuItem value={10}>Projects 01</MenuItem>
                <MenuItem value={20}>Projects 02</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={6}>
            <Typography>{t("groups.scale")}</Typography>
            <FormControl sx={{ width: "100%" }} size="small">
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={scale}
                onChange={handleChange5}
              >
                <MenuItem value={0}>Includes Any</MenuItem>
                <MenuItem value={10}>Projects 01</MenuItem>
                <MenuItem value={20}>Projects 02</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={12}>
            <Typography>{t("groups.value")}</Typography>
            <FormControl sx={{ width: "100%" }} size="small">
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={value}
                onChange={handleChange6}
              >
                <MenuItem value={0}>Search States</MenuItem>
                <MenuItem value={10}>Projects 01</MenuItem>
                <MenuItem value={20}>Projects 02</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Box className="FormField">
        <Typography className="attributeText">
          {t("groups.addAttribute")}
        </Typography>
      </Box>

      <Box className="FormField">
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <AppBtnOutLine buttonText={t("groups.previewFilterBtn")} />
          </Grid>
          <Grid item sm={6}>
            <Box className="flexEnd">
              <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} />
              <AppBtn buttonText={t("commonTxt.saveBtn")} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
