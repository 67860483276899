import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.css";

//image
import filterImg from "../../../Assets/Images/FilterWhiteIcon.png"

//components
import SideMenu from "../../../Components/Observer/SideMenu";
import { sideHaumIcon, recapNavItem } from "../../../Assets/Data";
import { LatestCreated } from "../../../Components/AppTools/AppTool";

import Thread from "../../../Components/Observer/Thread";
import Expressions from "../../../Components/Observer/Expressions";
import RecapImage from "../../../Components/Observer/RecapImage";
import RecapVideo from "../../../Components/Observer/RecapVideo";
import RecapAudio from "../../../Components/Observer/RecapAudio";
import DateAndCharts from "../../../Components/Observer/DateAndCharts";


export default function Recap({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
  selectNavItem,
  setSelectNavItem,
}) {
  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Recently Added");

  const [searchParams, setSearchParams] = useSearchParams();
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const responseArrya = [
    {
      label: "Recent Responses",
    },
    {
      label: "Oldest Responses",
    },
    {
      label: "Recent Comments",
    },
  ];

  const navItem = [
    "Thread",
    "Expressions",
    "Images",
    "Videos",
    "Audios",
    "Data & Charts",
  ];



  useEffect(() => {
    let selTab = searchParams.get("r");
    if (selTab != null) {
      var query_string = selTab;
      query_string = query_string.replaceAll("_", " ");
      query_string = query_string.replace(/(^\w|\s\w)/g, (firstCharOfWord) =>
        firstCharOfWord.toUpperCase()
      );
      if (selTab === "data_charts") {
        var query_string = "Data & Charts";
        setSelectNavItem(query_string);
      } else {
        setSelectNavItem(query_string);
      }
    }
  }, []);

  return (
    <>
      <Box className="ResearchersContainer recapPage">
        {/* side menu */}
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>



            <Box className="recapNavItems">
              {recapNavItem?.map((el, i) => (
                <Box
                  key={i}
                  onClick={() => {
                    setSearchParams({ r: el.qstring });
                    setSelectNavItem(el.title);
                  }}
                  className={
                    selectNavItem === el.title
                      ? "recapNavItem recapNavItemActive"
                      : "recapNavItem"
                  }
                >
                  <Typography>{el.title}</Typography>
                </Box>
              ))}
            </Box>

            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "20px" }}>
              <Box width={"22%"} minWidth={"187px"} className="agreSearchB2">
                <LatestCreated
                  setLatestDrop={setLatestDrop}
                  latestCDrop={latestCDrop}
                  setLatestDropVal={setLatestDropVal}
                  latestDropVal={latestDropVal}
                  itemAry={responseArrya}
                />
              </Box>

              <Box className="filter_Btn">
                <img src={filterImg} />
                <Typography>Filters</Typography>
              </Box>


            </Box>

          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer">
            <Box
              sx={{ display: selectNavItem === "Thread" ? "block" : "none" }}
            >
              <Thread />
            </Box>
            <Box
              sx={{
                display: selectNavItem === "Expressions" ? "block" : "none",
              }}
            >
              <Expressions />
            </Box>
            <Box
              sx={{
                display: selectNavItem === "Data & Charts" ? "block" : "none",
              }}
            >
              <DateAndCharts />
            </Box>
            <Box
              sx={{ display: selectNavItem === "Images" ? "block" : "none" }}
            >
              <RecapImage selectNavItem={selectNavItem} setSelectNavItem={setSelectNavItem} />
            </Box>
            <Box
              sx={{ display: selectNavItem === "Videos" ? "block" : "none" }}
            >
              <RecapVideo />
            </Box>
            <Box
              sx={{ display: selectNavItem === "Audios" ? "block" : "none" }}
            >
              <RecapAudio />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
