import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css"
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";

//images

export default function DeletePagePop({ deletePagePop, setDeletePagePop, setAllDropVal3 }) {
 
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setDeletePagePop(false);
      setAllDropVal3("Page Settings");
    }
  };
  const handelPopBack = () => {
    setDeletePagePop(false);
    setAllDropVal3("Page Settings");
  }

  useEffect(() => {
    if (deletePagePop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [deletePagePop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: deletePagePop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople"
      >
        <Box
          sx={{ display: deletePagePop ? "flex" : "none" }}
          className="workspaceContaint deletePagePop"
        >
          <Typography className="companyHeader">Delete Page</Typography>

          <Box className="basicBoxItem">
            <Typography className='addPageLabel'>Are you sure you want to delete the page titled “Welcome Researchers!” ? </Typography>
            <Typography className='addPageLabel'>Once deleted this page will not be visible to anybody.</Typography>
          </Box>
          
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={() => setDeletePagePop(false)} />
            <AppBtnOutLine buttonText="Go Back" onClick={handelPopBack} />
            <AppBtn buttonText="Delete" />
          </Box>



        </Box>
      </Box>
    </>
  );
}
