import React, { useEffect } from 'react'
import "./style.css";
import { Box, Typography } from '@mui/material';
import IIcon from "../../../Assets/Images/IIcon.png"

//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton"

export default function WorkspaceDeleteErrorPop({ deleteErrorWorkspace, setDeleteErrorWorkspace, setDeleteWorkspace }) {


  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
        setDeleteErrorWorkspace(false)
    }
  }
  const handelErrorClosePop = () => {
    setDeleteErrorWorkspace(false);
    setDeleteWorkspace(true);
  }

  useEffect(() => {
    if (deleteErrorWorkspace) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = 'auto'; // Enable scrolling
    }
  }, [deleteErrorWorkspace])




  return (
    <>
      <Box onClick={handelPopClose} id="popBackdrop" sx={{ display: deleteErrorWorkspace ? "flex" : "none" }} className="workspaceDeletePopContainer">

        <Box className="workspaceDeleteBox workspaceDeletePopTextAlign">
          <Typography className='deletePopHeader deleteErrorHeader'>Error: Delete “Lucy’s Workspace”</Typography>
          
          <Box className="deleteTextBox">
            <Typography>Looks like one or more projects within this Workspace was left open. Kindly make sure that all the projects under this Workspace are either closed, deleted, archived or in the draft mode and try again.</Typography>
          </Box>
          <Box className="deleteBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={handelErrorClosePop} />
            <AppBtn buttonText="Go to Workspace" onClick={() => setDeleteErrorWorkspace(false)} />
          </Box>
        </Box>


      </Box>
    </>
  )
}
