import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./style.css";
import { useAppSelector } from "../../../lib/store/store";
import { USER_TYPE_COLLABORATOR,  USER_TYPE_RESEARCHER, USER_TYPE_OBSERVER } from "../../../lib/constants";
import OptionIcon from "../../../Assets/Images/optionIcon.png";
import ActiveDot from "../../../Assets/Images/ActiveDot.png";
import { viewImage } from "../../../lib/helpers";

import { copyIcon, trashIcon, IIcon, settingIcon } from "../../../Assets/Data";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 767, min: 464 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const Slider = ({ Task, SetEntiteActivityPage, setDeleteTaskPop, setTaskId, setTaskName }) => {
  let authState = useAppSelector((state) => state.auth);
  const [taskOption, setTaskOption] = useState();
  

  const handleTackOption = (index) => {
    if (taskOption === index) {
      setTaskOption(null);
      return;
    } else {
      setTaskOption(index);
    }
  };

  const handleOpenPopup = (id,name) => {
    setTaskId(id);
    setTaskName(name);
  };

  const handleTackOptionClose = () => setTaskOption(null);

  return (
    <div className="parent">
      <Carousel
        responsive={responsive}
        // autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={true}
        partialVisible={false}
        dotListClass="custom-dot-list-style"
      >
        {Task?.length > 0 && Task.map((el, index) => {
          return (
            <div key={index} className="slider taskCard">
              <div className="taskCImgBox">
                <img src={viewImage(el.image)} />
              </div>
              <div className="userHCTextBox">
                <p
                  sx={{ fontSize: "18px", color: "#101828", fontWeight: "600" }}
                >
                  {el.name}
                </p>
                <img
                  style={{ cursor: "pointer", width: "20px", height: "20px" }}
                  src={taskOption === index ? ActiveDot : OptionIcon}
                  onClick={() => handleTackOption(index)}
                />
              </div>
              <p className="taskSubTitle">{el.type}</p>

              <div
                style={{ display: taskOption === index ? "flex" : "none" }}
                className={authState?.wsUserType == USER_TYPE_OBSERVER ? 'taskDropMenu observerTaskDropMenu' : 'taskDropMenu'}
              >
              {authState?.wsUserType == USER_TYPE_RESEARCHER || authState?.wsUserType == USER_TYPE_COLLABORATOR ? 
                (<div onClick={handleTackOptionClose}>
                  {settingIcon}
                  <p>Task Settings</p>
                </div>) : ''
              }
                <div>
                  {IIcon}
                  <p>Preview</p>
                </div>
              {authState?.wsUserType == USER_TYPE_RESEARCHER || authState?.wsUserType == USER_TYPE_COLLABORATOR ? 
                (<div onClick={handleTackOptionClose}>
                  {copyIcon}
                  <p>Duplicate</p>
                </div>) : ''
              }
              {authState?.wsUserType == USER_TYPE_RESEARCHER || authState?.wsUserType == USER_TYPE_COLLABORATOR ? 
                (<div 
                  // onClick={handleTackOptionClose}
                  onClick={() => { handleTackOptionClose(); handleOpenPopup(el._id,el.name);setDeleteTaskPop(true); }}
                >
                  {trashIcon}
                  <p>Delete</p>
                </div>): ''
              }
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};
export default Slider;
