import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";

//components
import { AppBtn } from "../../AppTools/AppButton";

export default function ClosedProjectPop({
    closedProjectPop,
    setClosedProjectPop,
}) {
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
        setClosedProjectPop(false);
    }
  };

  useEffect(() => {
    if (closedProjectPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [closedProjectPop]);

  return (
    <>
      <Box
        onClick={handelPopClose}
        id="popBackdrop"
        sx={{ display: closedProjectPop ? "flex" : "none" }}
        className="workspaceDeletePopContainer"
      >
        <Box sx={{width:"480px"}} className="workspaceDeleteBox">
          <Typography className="deletePopHeader">The <strong>Outdoor Project</strong> has been closed by Richard.</Typography>

          <Box className="deletPopBtnBox customPopLoginBtn">
            <AppBtn buttonText="Close" onClick={() => setClosedProjectPop(false)} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
