import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";

//components
import { AppBtn } from "../../AppTools/AppButton";

export default function TerminatedProjectPop({
    terminatedProjectPop,
    setTerminatedProjectPop,
}) {
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
        setTerminatedProjectPop(false);
    }
  };

  useEffect(() => {
    if (terminatedProjectPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [terminatedProjectPop]);

  return (
    <>
      <Box
        onClick={handelPopClose}
        id="popBackdrop"
        sx={{ display: terminatedProjectPop ? "flex" : "none" }}
        className="workspaceDeletePopContainer"
      >
        <Box sx={{width:"480px"}} className="workspaceDeleteBox">
          <Typography className="deletePopHeader">The <strong>Night Safari Project</strong> has been terminated by Richard.</Typography>

          <Box className="deletPopBtnBox customPopLoginBtn">
            <AppBtn buttonText="Close" onClick={() => setTerminatedProjectPop(false)} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
