import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { AntSwitch } from "../../AppTools/AppTool";
//images
import iIcon2 from "../../../Assets/Images/IIcon.png";
import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png";
import backArrow from "../../../Assets/Images/noun-back.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";


export default function AddVideoTaskPop({ setAllDropVal4, addVideoTaskPop, setAddVideoTaskPop, handelAddTaskPopBack }) {

  const email1 = "Type the question/instructions to be visible"

  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();
  const [image, setImage] = useState(null);
  const [checkTaskVideoDescription, setCheckTaskVideoDescription] = useState(true);
  const [checkTaskSettingPrompt, setCheckTaskSettingPrompt] = useState(true);
  const [participantDrop, setParticipantDrop] = useState(false);
  const [participantDropVal, setParticipantDropVal] = useState();
  const [searchParticipantDrop, setSearchParticipantDrop] = useState(false);
  const [searchParticipantDropVal, setSearchParticipantDropVal] = useState(false);
  const [checkLimitCompleteTask, setCheckLimitCompleteTask] = useState(true);

  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
  };


  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
        setAddVideoTaskPop(false);
    }
  };

  const handelCancelPop = () => {
    setAddVideoTaskPop(false);
    setAllDropVal4("Add Tasks");
  }

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (addVideoTaskPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [addVideoTaskPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: addVideoTaskPop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople "
      >
        <Box
          sx={{ display: addVideoTaskPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
            <Box onClick={handelAddTaskPopBack} className="openBtn taskBackBtn">
            <img src={backArrow} />
            <Typography className="neonBackText">Back</Typography>
            </Box>
          <Typography className="taskPopMainLabel">Video Upload Task</Typography>
          <Box className="iBox">
              <img src={iIcon2} />
              <Typography>
                Open-ended video responses with optional captions, emotions and comments.
              </Typography>
            </Box>
          <Box className="basicBoxItem">
            <Typography className='eTemPopLabel '>Task Name</Typography>
            <Box className="pickerBox cardTitleLabel">
              <input className='eTemInput' placeholder='Enter task name' />
            </Box>
          </Box>

          <Typography className='eTemPopLabel '>Question or Instructions</Typography>
          <Box className="emailInputBox textTaskInstruction">
            <Box className="emailInputBoxNav">
              <Box
                onClick={() => setMessageTextItalic(!messageTextItalic)}
                className="emailImgBox"
              >
                <img src={IIcon} />
              </Box>
              <Box
                onClick={() => setMessageTextBold(!messageTextBold)}
                className="emailImgBox"
              >
                <img src={BIcon} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("underline", "message")}
                className="emailImgBox"
              >
                <img src={UnderLineU} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("line-through", "message")}
                className="emailImgBox"
              >
                <img src={MLineU} />
              </Box>
              <Box className="emailImgBox">
                <img src={AtachMentIcon} />
              </Box>
              <Box className="emailImgBox">
                <img src={Qbox} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("start")}
                className="emailImgBox"
              >
                <img src={Line1} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("center")}
                className="emailImgBox"
              >
                <img src={Line2} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("end")}
                className="emailImgBox"
              >
                <img src={Line3} />
              </Box>
              <Box className="emailImgBox">
                <img src={Line4} />
              </Box>
              <Box className="emailImgBox">
                <img src={emoji} />
              </Box>
            </Box>
            <textarea
              style={{
                textAlign: messageTextLaOut,
                fontStyle: messageTextItalic ? "italic" : "normal",
                fontWeight: messageTextBold ? "bold" : "normal",
                textDecoration: messageTextUnderLine,
                // textDecoration: messageTextMLine ? "line-through" : null
              }}
              className="emailInput"
              placeholder="Enter a discription or instructions for the card"
              name="emailMessage"
              value={emailval?.emailMessage || email1}
              onChange={handelChange}
            />
          </Box>

          <Box className="basicBoxItem">
            <Box className='mediaInputbox upload-area taskUoloadImageBox' onClick={() => {
                document.getElementById("fileInput").click();
            }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}>
                <img src={uploadIcon} />
                <Typography>Click or drag an image to this area to upload</Typography>
                <input
                id="fileInput"
                style={{ display: "none" }}
                type="file"
                onChange={handleFileInputChange}
                />
            </Box>
          </Box>

          <Typography className='textTaskSetting '>Task Settings</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>Limit Quantity</Typography>
          </Box>
          
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" onClick={() => setCheckTaskVideoDescription(!checkTaskVideoDescription)}  defaultChecked />
            <Typography>Allow video descriptions by participants</Typography>
          </Box>
          <Box sx={{ display: checkTaskVideoDescription ? "flex" : "none" }} className="wsCpInputBOx imageTaskDescription">
            <AntSwitch
                defaultChecked
                inputProps={{ "aria-label": "ant design" }}
            />
            <Typography>
                Make descriptions complusory
            </Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" onClick={() => setCheckTaskSettingPrompt(!checkTaskSettingPrompt)} defaultChecked />
            <Typography>Customize prompt</Typography>
          </Box>
          <Box sx={{ display: checkTaskSettingPrompt ? "flex" : "none" }} className="wsCpInputBOx">
            <input
              type="text"
              className="phoneInput customizePromptInput"
              placeholder="Enter customize prompt"
            />
          </Box>
         
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>Response Required</Typography>
          </Box>

          <Typography className='textTaskSetting '>Advance Settings</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" onClick={() => setCheckLimitCompleteTask(!checkLimitCompleteTask)} defaultChecked />
            <Typography>Limit who can complete this task</Typography>
          </Box>
          <Box sx={{ display: checkLimitCompleteTask ? "block" : "none" }} className="searchGroupSection">
          <Box className='internalInputBox'>
            <Box
              className="inputAppDrop"
              onClick={() => setParticipantDrop(!participantDrop)}
            >
              <Typography>{participantDropVal || "Select Particular Participant"}</Typography>
              <img
                style={{ rotate: participantDrop ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: participantDrop ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() =>
                    setParticipantDropVal("Participant 1")
                  }
                  className="statusPopItem"
                >
                  <Typography>Participant 1</Typography>
                </Box>
                <Box
                  onClick={() =>
                    setParticipantDropVal("Participant 2")
                  }
                  className="statusPopItem"
                >
                  <Typography>Participant 2</Typography>
                </Box>
              </Box>
              {/* pop */}
            </Box>
            <Box
              className="inputAppDrop"
              onClick={() => setSearchParticipantDrop(!searchParticipantDrop)}
            >
              <Typography>{searchParticipantDropVal || "Search Participant"}</Typography>
              <img
                style={{ rotate: searchParticipantDrop ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              <Box
                sx={{ height: searchParticipantDrop ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() =>
                    setSearchParticipantDropVal("Participant A")
                  }
                  className="statusPopItem"
                >
                  <Typography>Participant 1</Typography>
                </Box>
                <Box
                  onClick={() =>
                    setSearchParticipantDropVal("Participant B")
                  }
                  className="statusPopItem"
                >
                  <Typography>Participant 2</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: checkLimitCompleteTask ? "block" : "none" }} className='internalInputBox'>
          <Box className="iBox">
            <img src={iIcon2} />
            <Typography>Only participants (in the groups) you select can complete the task. Use this option only if you need to limit visibility within the activity. To limit access to the entire activity, update Activity Visibility under “Activity Settings”.</Typography>
          </Box>
          
        </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>Customize points awarded for task completetion </Typography>
          </Box>
        
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={handelCancelPop} />
            <AppBtnOutLine buttonText="Save & Preview" />
            <AppBtn buttonText="Save Task" />
          </Box>



        </Box>
      </Box>
    </>
  );
}
