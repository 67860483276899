import React, { useEffect, useState } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import "./style.css";

import { useTranslation } from "react-i18next";

import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";

//components
import { AppBtnOutLine, AppBtn } from "../../AppTools/AppButton";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { useAppSelector } from "../../../lib/store/store";
import { createInviteNewAdminAPI } from "../../../Services/adminActions";
import axios from "../../../lib/axios";
import { API_BASE_URL } from "../../../env";
import { participantAction } from "../../../Services/researcherActions";
export default function InviteAdminPop({ inviteAdminPop, setInviteAdminPop }) {
  let authState = useAppSelector((state) => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace);

  const email1 = "Please join as an admin of {{{invite_link}}} our Terapage site";
  const email2 = "Best Regards,  Lorem Ipsum";
  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();

  const [signatureTextLaOut, setSignatureTextLaOut] = useState("start");
  const [signatureTextItalic, setSignatureTextItalic] = useState(false);
  const [signatureTextBold, setSignatureTextBold] = useState(false);
  const [loading, setLoading] = useState(false);
  const [signatureTextUnderLine, setSignatureTextUnderLine] = useState();
  const [settingTab, setSettingTab] = useState(0);

  const [attributeDrop, setAttributeDrop] = useState(false);
  const [attributeDropVal, setAttributeDropVal] = useState(false);
  const [scaleDrop, setScaleDrop] = useState(false);
  const [scaleDropVal, setScaleDropVal] = useState(false);
  const [valueDrop, setValueDrop] = useState(false);
  const [valueDropVal, setValueDropVal] = useState();
  const [usersDrop, setUsersDrop] = useState(false);
  const [usersDropVal, setUsersDropVal] = useState();
  const [selectFilterbyRole, setselectFilterbyRole] = useState("");
  const [roleTotalDataLength, setRoleTotalDataLength] = useState();
  const [researchers, setResearcher] = useState([]);
  const [collaborators, setCollaborator] = useState([]);
  const [observers, setObserver] = useState([]);
  const inviteNewAdminObj = {
    name: "",
    role: "",
    email: "",
    message: "",
    subject: "",
    workspace_id: "",
  };

  const inviteAdminProjectObj = {
    participant_ids: [],
    project_ids: [],
    subject: "",
    message: "",
  };

  const [inviteNewAdmin, setInviteNewAdmin] = useState({ ...inviteNewAdminObj });
  const [projectList, setProjectlist] = useState([]);
  const [inviteAdminToProject, setInviteAdminToProject] = useState({
    ...inviteAdminProjectObj
  });


  const validationObj = {
    hasErr: false,
    'name': {
      error: false,
      msg: ''
    },
    'email': {
      error: false,
      msg: ''
    },
    'subject': {
      error: false,
      msg: ''
    }
  }
  const validationProjectObj = {
    hasErr: false,
    'participant_ids': {
      error: false,
      msg: ''
    },
    'project_ids': {
      error: false,
      msg: ''
    },
    'subject': {
      error: false,
      msg: ''
    }
  }

  const [validationErrors, setValidationErrors] = useState({ ...validationObj });
  const [validationProjectErrors, setValidationProjectErrors] = useState({ ...validationProjectObj });

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const setFormData = (e) => {
    const newFormValue = {
      ...inviteNewAdmin,
      [e.target.name]: e.target.value
    }
    setInviteNewAdmin(newFormValue)

    if (validationErrors.hasErr) {
      handleValidation(newFormValue);
    }
  }
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setInviteAdminPop(false);
    }
  };

  useEffect(() => {
    getResearcher();
    getCollaborator();
    getObserver();
    getProjectLists();
  }, [])

  const getResearcher = async () => {
    try {
      const data = await axios.get(API_BASE_URL + `/super-admin/admin/researchers?workspace=${workspaceState?.selectedWorkspace?._id}`)
      setResearcher(data?.data?.data)
    } catch (err) {
      console.log(err);
    }
  }
  // Collaborator Listing API

  const getCollaborator = async () => {
    try {
      const data = await axios.get(API_BASE_URL + `/super-admin/admin/collaborators?workspace=${workspaceState?.selectedWorkspace?._id}`)
      setCollaborator(data?.data?.data)
    } catch (err) {
      console.log(err);
    }
  }

  const getProjectLists = async () => {
    try {
      const res = await axios.get(API_BASE_URL + `/researchers/projects?workspace_id=${workspaceState?.selectedWorkspace?._id}`);
      if (res.status) {
        setProjectlist(res.data.data);
      }
    } catch (error) {
      console.error("Error fetching project lists", error);
    }
  };
  // Observer
  const getObserver = async () => {
    try {
      const data = await axios.get(API_BASE_URL + `/super-admin/admin/observers?workspace=${workspaceState?.selectedWorkspace?._id}`)
      setObserver(data?.data?.data)
    } catch (err) {
      console.log(err);
    }
  }


  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleValidation = (formInput) => {
    formInput = formInput || inviteNewAdmin;
    let validationerr = { ...validationObj };

    if (formInput.name == "") {
      validationerr.hasErr = true;
      validationerr.name = {
        error: true,
        msg: 'Name is required'
      }
    }

    if (formInput.email == "") {
      validationerr.hasErr = true;
      validationerr.email = {
        error: true,
        msg: 'Email is required'
      }
    }

    if (formInput.subject == "") {
      validationerr.hasErr = true;
      validationerr.subject = {
        error: true,
        msg: 'Subject is required'
      }
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  }

  const handleProjectValidation = (formInput) => {
    formInput = formInput || inviteAdminToProject;
    let validationerr = { ...inviteAdminProjectObj };

    if (formInput.participant_ids.length <= 0) {
      validationerr.hasErr = true;
      validationerr.participant_ids = {
        error: true,
        msg: 'Participants is required'
      }
    }

    if (formInput.project_ids.length <= 0) {
      validationerr.hasErr = true;
      validationerr.project_ids = {
        error: true,
        msg: 'Projects is required'
      }
    }

    if (formInput.subject == "") {
      validationerr.hasErr = true;
      validationerr.subject = {
        error: true,
        msg: 'Subject is required'
      }
    }

    setValidationProjectErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  }

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine === "underline") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine === "line-through") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("line-through");
        }
      }
    }
  };

  useEffect(() => {
    if (inviteAdminPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [inviteAdminPop]);

  const handleCreateInviteAdmin = async () => {
    if (!handleValidation()) {
      toast.error('Please correct form fields', TOAST_CONFIG);
      return;
    }
    try {
      setLoading(true);
      const payload = {
        ...inviteNewAdmin,
        workspace_id: workspaceState?.selectedWorkspace?._id,
      };

      const inviteRes = await createInviteNewAdminAPI(payload, authState.authToken);
      setLoading(false);
      if (inviteRes.success) {
        toast.success(inviteRes.message, TOAST_CONFIG);
        setInviteNewAdmin({ ...inviteNewAdminObj });
        setInviteAdminPop(false);
      } else {
        toast.error(inviteRes.message, TOAST_CONFIG);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  const handleInviteAdminToProject = async () => {
    if (!handleProjectValidation()) {
      toast.error('Please correct form fields', TOAST_CONFIG);
      return;
    }
    try {
      setLoading(true);
      const payload = {
        ...inviteAdminToProject
      };
      // return console.log("payload",payload);
      const inviteRes = await participantAction(`/super-admin/admin/participants/invite-participants-to-projects`, payload, authState.authToken);
      setLoading(false);
      if (inviteRes.success) {
        toast.success(inviteRes.message, TOAST_CONFIG);
        setInviteAdminToProject({ ...inviteAdminProjectObj });
        setInviteAdminPop(false);
      } else {
        toast.error(inviteRes.message, TOAST_CONFIG);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  const handleInviteInvite = () => {
    if (settingTab == 0) {
      handleCreateInviteAdmin();
    } else {
      handleInviteAdminToProject();
    }
  }

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: inviteAdminPop ? "flex" : "none" }}
        className="workspacePop"
      >
        <Box
          sx={{ display: inviteAdminPop ? "flex" : "none" }}
          className="workspaceContaint emailTemPop"
        >
          <Typography className="inviteAdminHead">
            {t("admin.inviteAdmin")}
          </Typography>
          {/* Tabs */}
          <Box className="agreementSettingNav">
            <Box
              onClick={() => setSettingTab(0)}
              className={
                settingTab === 0
                  ? "agreeSetNavTab agreeSetNavTabActive"
                  : "agreeSetNavTab"
              }
            >
              <Typography>{t("admin.inviteNewAdmins")}</Typography>
            </Box>
            <Box
              onClick={() => setSettingTab(1)}
              className={
                settingTab === 1
                  ? "agreeSetNavTab agreeSetNavTabActive"
                  : "agreeSetNavTab"
              }
            >
              <Typography>{t("admin.inviteToPro")}</Typography>
            </Box>
          </Box>
          {/* Tabs */}
          <Box
            sx={{ display: settingTab === 0 ? "flex" : "none" }}
            className="settingBox"
          >
            <Box className="advanceFilterRowOne" sx={{ display: 'flex', gap: 2 }}>
              <Box className="csPBox" sx={{ flex: 1 }}>
                <Typography className="eTemPopLabel">Name<samp>*</samp></Typography>
                <input
                  className="eTemInput"
                  placeholder="Enter Name"
                  name="name"
                  value={inviteNewAdmin?.name}
                  onChange={setFormData}
                />
                <span className="validationerr inputerr">{validationErrors.name.msg}</span>
              </Box>
              <Box className="inputBoxWlabel" sx={{ flex: 1 }}>
                <Typography className="eTemPopLabel">
                  {t("admin.role")}
                  <samp>*</samp>
                </Typography>
                <Box className="ckeckBoxRoom">
                  <FormControl sx={{ width: "100%" }} size="small">
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={inviteNewAdmin.role}
                      onChange={setFormData}
                      displayEmpty
                      name="role"
                    >
                      <MenuItem value="" disabled>
                        {t("admin.selectRole")}
                      </MenuItem>
                      <MenuItem value={"researcher"} >{t("admin.researcher")}</MenuItem>
                      <MenuItem value={"collaborator"}>{t("admin.collaborator")}</MenuItem>
                      <MenuItem value={"observer"}>{t("admin.observer")}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Box>

            <Box className="advancedFilterRowTwo" sx={{ width: "100%" }}>
              <Box className="csPBox">
                <Typography className="eTemPopLabel emailAddresses">
                  {t("admin.selectEmailAdd")}
                  <samp>*</samp>
                </Typography>
                <input
                  className="eTemInput"
                  placeholder="Enter Email here"
                  name="email"
                  value={inviteNewAdmin?.email}
                  onChange={setFormData}
                />
                <span className="validationerr inputerr">{validationErrors.email.msg}</span>
              </Box>
            </Box>
            <Box className="basicBox inviteAdminSubject">
              <Box>
                <Typography className="eTemPopLabel emailAddresses">
                  {t("admin.subject")}
                  <samp>*</samp>
                </Typography>
                <Box className="pickerBox ">
                  <input
                    className="eTemInput"
                    placeholder={t("admin.terms&Conditions")}
                    name="subject" value={inviteNewAdmin.subject} onChange={setFormData}
                  />
                  <span className="validationerr inputerr">{validationErrors.subject.msg}</span>
                </Box>
              </Box>
            </Box>

            <Box className="agrrementPopLabel">
              <Typography className="eTemPopLabel inviteAdminLabel">
                {t("admin.message")}
                <samp>*</samp>
              </Typography>
              <Typography className="inviteAdminLinkLabel">
                {t("admin.useTemplate")}
              </Typography>
            </Box>
            <Box className="emailInputBox">
              <Box className="emailInputBoxNav">
                <Box
                  onClick={() => setMessageTextItalic(!messageTextItalic)}
                  className="emailImgBox"
                >
                  <img src={IIcon} />
                </Box>
                <Box
                  onClick={() => setMessageTextBold(!messageTextBold)}
                  className="emailImgBox"
                >
                  <img src={BIcon} />
                </Box>
                <Box
                  onClick={() => handelTextlineChekc("underline", "message")}
                  className="emailImgBox"
                >
                  <img src={UnderLineU} />
                </Box>
                <Box
                  onClick={() => handelTextlineChekc("line-through", "message")}
                  className="emailImgBox"
                >
                  <img src={MLineU} />
                </Box>
                <Box className="emailImgBox">
                  <img src={AtachMentIcon} />
                </Box>
                <Box className="emailImgBox">
                  <img src={Qbox} />
                </Box>
                <Box
                  onClick={() => setMessageTextLaOut("start")}
                  className="emailImgBox"
                >
                  <img src={Line1} />
                </Box>
                <Box
                  onClick={() => setMessageTextLaOut("center")}
                  className="emailImgBox"
                >
                  <img src={Line2} />
                </Box>
                <Box
                  onClick={() => setMessageTextLaOut("end")}
                  className="emailImgBox"
                >
                  <img src={Line3} />
                </Box>
                <Box className="emailImgBox">
                  <img src={Line4} />
                </Box>
                <Box className="emailImgBox">
                  <img src={emoji} />
                </Box>
              </Box>
              <textarea
                style={{
                  textAlign: messageTextLaOut,
                  fontStyle: messageTextItalic ? "italic" : "normal",
                  fontWeight: messageTextBold ? "bold" : "normal",
                  textDecoration: messageTextUnderLine,
                  // textDecoration: messageTextMLine ? "line-through" : null
                }}
                className="emailInput"
                name="message"
                value={inviteNewAdmin?.message}
                onChange={setFormData}
              />
            </Box>
          </Box>

          <Box
            sx={{ display: settingTab === 1 ? "flex" : "none" }}
            className="settingBox"
          >
            <Box className="advanceFilterRowOne" sx={{ width: "100%" }}>
              <Box className="inputBoxWlabel">
                <Typography className="eTemPopLabel">
                  {t("admin.role")}
                  <samp>*</samp>
                </Typography>
                <Box
                  className="inputAppDrop"
                  onClick={() => setAttributeDrop(!attributeDrop)}
                >
                  <Typography>
                    {attributeDropVal || t("admin.selectRole")}
                  </Typography>
                  <img
                    style={{ rotate: attributeDrop ? "180deg" : "0deg" }}
                    src={dropArrow}
                  />
                  {/* pop */}
                  <Box
                    sx={{ height: attributeDrop ? "auto" : "0px" }}
                    className="statusPop"
                  >
                    <Box
                      onClick={() => {
                        setAttributeDropVal("Researcher");
                        setInviteAdminToProject((prevGroup) => ({
                          ...prevGroup,
                          participant_ids: [],
                        }))
                      }}
                      className="statusPopItem"
                    >
                      <Typography>{t("admin.researcher")}</Typography>
                    </Box>
                    <Box
                      onClick={() => {
                        setAttributeDropVal("Collaborator");
                        setInviteAdminToProject((prevGroup) => ({
                          ...prevGroup,
                          participant_ids: [],
                        }));
                      }}
                      className="statusPopItem"
                    >
                      <Typography>{t("admin.collaborator")}</Typography>
                    </Box>
                    <Box
                      onClick={() => {
                        setAttributeDropVal("Observer"); setInviteAdminToProject((prevGroup) => ({
                          ...prevGroup,
                          participant_ids: [],
                        }))
                      }}
                      className="statusPopItem"
                    >
                      <Typography>{t("admin.observer")}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* Particular User */}
            <Box className="advanceFilterRowOne" sx={{ width: "100%" }}>
              {attributeDropVal === "Researcher" && (<Box className="advancedFilterRowTwo" sx={{ width: "100%" }}>
                <Box className="inputBoxWlabel">
                  <FormControl sx={{ width: "100%" }} size="small">
                    <Select
                      labelId="project-select-label"
                      id="project-select"
                      value={inviteAdminToProject.participant_ids}
                      onChange={(e) => setInviteAdminToProject((prevGroup) => ({
                        ...prevGroup,
                        participant_ids: e.target.value,
                      }))}
                      name="project_ids"
                      multiple
                      displayEmpty
                      renderValue={(selected) => {

                        if (selected.length === 0) {
                          return (
                            <Typography>
                              Select Admin Users
                            </Typography>
                          );
                        }
                        return selected
                          .map((value) => {
                            const researcher = researchers.find(
                              (el) => el._id === value
                            );
                            return researcher ? researcher.name : value;
                          })
                          .join(", ");
                      }}
                    >

                      {researchers.map((el) => (
                        <MenuItem key={el._id} value={el._id}>
                          {el.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <span className="validationerr inputerr">{validationProjectErrors.participant_ids.msg}</span>
                  {/*  */}
                </Box>
              </Box>)}</Box>
            <Box className="advanceFilterRowOne" sx={{ width: "100%" }}>
              {attributeDropVal === "Collaborator" && (<Box className="advancedFilterRowTwo" sx={{ width: "100%" }}>
                <Box className="inputBoxWlabel">
                  <FormControl sx={{ width: "100%" }} size="small">
                    <Select
                      labelId="project-select-label"
                      id="project-select"
                      value={inviteAdminToProject.participant_ids}
                      onChange={(e) => setInviteAdminToProject((prevGroup) => ({
                        ...prevGroup,
                        participant_ids: e.target.value,
                      }))}
                      name="project_ids"
                      multiple
                      displayEmpty
                      renderValue={(selected) => {

                        if (selected.length === 0) {
                          return (
                            <Typography>
                              Select Admin Users
                            </Typography>
                          );
                        }
                        return selected
                          .map((value) => {
                            const collaborator = collaborators.find(
                              (el) => el._id === value
                            );
                            return collaborator ? collaborator.name : value;
                          })
                          .join(", ");
                      }}
                    >

                      {collaborators.map((el) => (
                        <MenuItem key={el._id} value={el._id}>
                          {el.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <span className="validationerr inputerr">{validationProjectErrors.participant_ids.msg}</span>
                  {/*  */}
                </Box>
              </Box>)}</Box>
            <Box className="advanceFilterRowOne" sx={{ width: "100%" }}>
              {attributeDropVal === "Observer" && (<Box className="advancedFilterRowTwo" sx={{ width: "100%" }}>
                <Box className="inputBoxWlabel">

                  <FormControl sx={{ width: "100%" }} size="small">
                    <Select
                      labelId="project-select-label"
                      id="project-select"
                      value={inviteAdminToProject.participant_ids}
                      onChange={(e) => setInviteAdminToProject((prevGroup) => ({
                        ...prevGroup,
                        participant_ids: e.target.value,
                      }))}
                      name="project_ids"
                      multiple
                      displayEmpty
                      renderValue={(selected) => {

                        if (selected.length === 0) {
                          return (
                            <Typography>
                              Select Admin Users
                            </Typography>
                          );
                        }
                        return selected
                          .map((value) => {
                            const observer = observers.find(
                              (el) => el._id === value
                            );
                            return observer ? observer.name : value;
                          })
                          .join(", ");
                      }}
                    >

                      {observers.map((el) => (
                        <MenuItem key={el._id} value={el._id}>
                          {el.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <span className="validationerr inputerr">{validationProjectErrors.participant_ids.msg}</span>
                  {/*  */}
                </Box>
              </Box>)}</Box>

            {/* Particular User */}

            <Box className="advancedFilterRowTwo" sx={{ width: "100%" }}>
              <Box className="inputBoxWlabel">
                <Typography className="eTemPopLabel emailAddresses">
                  {t("admin.assignedPro")}
                  <samp>*</samp>
                </Typography>
                <FormControl sx={{ width: "100%" }} size="small">
                  <Select
                    labelId="project-select-label"
                    id="project-select"
                    value={inviteAdminToProject.project_ids}
                    onChange={(e) => setInviteAdminToProject((prevGroup) => ({
                      ...prevGroup,
                      project_ids: e.target.value,
                    }))}
                    name="project_ids"
                    multiple
                    displayEmpty
                    renderValue={(selected) => {

                      if (selected.length === 0) {
                        return (
                          <Typography>
                            {t("admin.assignedPro")}
                          </Typography>
                        );
                      }
                      return selected
                        .map((value) => {
                          const proj = projectList.find(
                            (pro) => pro._id === value
                          );
                          return proj ? proj.name : value;
                        })
                        .join(", ");
                    }}
                  >

                    {projectList.map((project) => (
                      <MenuItem key={project._id} value={project._id}>
                        {project.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/*  */}
                <span className="validationerr inputerr">{validationProjectErrors.project_ids.msg}</span>
              </Box>
            </Box>
            <Box className="basicBox inviteAdminSubject">
              <Box>
                <Typography className="eTemPopLabel emailAddresses">
                  {t("admin.subject")}
                  <samp>*</samp>
                </Typography>
                <Box className="pickerBox ">
                  <input
                    className="eTemInput"
                    placeholder={`Subject`}
                    value={inviteAdminToProject?.subject}
                    onChange={(e) => setInviteAdminToProject((prevGroup) => ({
                      ...prevGroup,
                      subject: e.target.value,
                    }))}
                  />
                  <span className="validationerr inputerr">{validationProjectErrors.subject.msg}</span>
                </Box>
              </Box>
            </Box>

            <Box className="agrrementPopLabel">
              <Typography className="eTemPopLabel inviteAdminLabel">
                {t("admin.message")}
                <samp>*</samp>
              </Typography>
              <Typography className="inviteAdminLinkLabel">
                {t("admin.insertLink")}
              </Typography>
            </Box>
            <Box className="emailInputBox">
              <Box className="emailInputBoxNav">
                <Box
                  onClick={() => setMessageTextItalic(!messageTextItalic)}
                  className="emailImgBox"
                >
                  <img src={IIcon} />
                </Box>
                <Box
                  onClick={() => setMessageTextBold(!messageTextBold)}
                  className="emailImgBox"
                >
                  <img src={BIcon} />
                </Box>
                <Box
                  onClick={() => handelTextlineChekc("underline", "message")}
                  className="emailImgBox"
                >
                  <img src={UnderLineU} />
                </Box>
                <Box
                  onClick={() => handelTextlineChekc("line-through", "message")}
                  className="emailImgBox"
                >
                  <img src={MLineU} />
                </Box>
                <Box className="emailImgBox">
                  <img src={AtachMentIcon} />
                </Box>
                <Box className="emailImgBox">
                  <img src={Qbox} />
                </Box>
                <Box
                  onClick={() => setMessageTextLaOut("start")}
                  className="emailImgBox"
                >
                  <img src={Line1} />
                </Box>
                <Box
                  onClick={() => setMessageTextLaOut("center")}
                  className="emailImgBox"
                >
                  <img src={Line2} />
                </Box>
                <Box
                  onClick={() => setMessageTextLaOut("end")}
                  className="emailImgBox"
                >
                  <img src={Line3} />
                </Box>
                <Box className="emailImgBox">
                  <img src={Line4} />
                </Box>
                <Box className="emailImgBox">
                  <img src={emoji} />
                </Box>
              </Box>
              <textarea
                style={{
                  textAlign: messageTextLaOut,
                  fontStyle: messageTextItalic ? "italic" : "normal",
                  fontWeight: messageTextBold ? "bold" : "normal",
                  textDecoration: messageTextUnderLine,
                  // textDecoration: messageTextMLine ? "line-through" : null
                }}
                className="emailInput"
                name="message"
                value={inviteAdminToProject?.message}
                onChange={(e) => setInviteAdminToProject((prevGroup) => ({
                  ...prevGroup,
                  message: e.target.value,
                }))}
              />
            </Box>
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine
              buttonText={t("commonTxt.cancelBtn")}
              onClick={() => setInviteAdminPop(false)}
            />
            <AppBtn buttonText={t("admin.invite")} onClick={handleInviteInvite} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
