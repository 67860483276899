import React, { useState, useEffect } from 'react'
import "./style.css"

import { Box, Typography } from '@mui/material';
import { SearchBar } from '../../AppTools/AppTool';

import { englishLangData } from "../../../Assets/Data"
import { AppBtn, AppBtnOutLine } from '../../AppTools/AppButton';



export default function BasicConLangPop({ basicLangPop, setBasicLangPop }) {
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setBasicLangPop(false);
    }
  };


  useEffect(() => {
    if (basicLangPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [basicLangPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: basicLangPop ? "flex" : "none" }}
        className="companyPop basicConLangPop"
      >
        <Box
          sx={{ display: basicLangPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className='eTemPopLabel bclHeader'>Select Secondary Language</Typography>

          <Typography className="eTemPopLabel">Activity Name</Typography>
          <Box mb={4} className="input_Box">
            <SearchBar placeholder="Search Languages" />
          </Box>

          <Typography>21 Selected</Typography>

          <Box className="langBox">
            {
              englishLangData?.map((el, i) => (
                <Box key={i} className="langItem">
                  <input
                    className="emailSettingInput emailSettingRadio"
                    type='checkBox'
                    name='projectState'
                    defaultChecked
                  />
                  <img src={el.icon} />
                  <Typography>{el.lang}</Typography>
                </Box>
              ))
            }
          </Box>
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={() => setBasicLangPop(false)} /> <AppBtn buttonText="Save" />
          </Box>
        </Box>
      </Box>
    </>
  )
}
