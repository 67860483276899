import React from 'react'
import { Box, Typography } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';


import { avtivityImages } from "../../../Assets/Data"


export default function Images() {
  return (
    <>
      <Box sx={{ width: "100%", height: "700px", overflowY: 'scroll' }}>
        <ImageList variant="masonry" cols={3} gap={8}>
          {avtivityImages.map((item, index) => (
            <ImageListItem key={index}>
              <img
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
              <Box className="grayTextBox">
                <Typography>{item.title}</Typography>
                <span>{item.subTitle}</span>
              </Box>
            </ImageListItem>
          ))}
        </ImageList>
      </Box>

    </>
  )
}
