import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";

//images
import option2Line from "../../../Assets/Images/Option2LIcon.png"
import lockIcon from "../../../Assets/Images/lock.png"
import unlockIcon from "../../../Assets/Images/unlock.png"
import iIcon from "../../../Assets/Images/IIcon.png";


//components
import { AppBtn, AppBtnOutLine } from "../../../Components/AppTools/AppButton";
import { SearchBar, AntSwitch } from "../../AppTools/AppTool";


export default function GroupRearrangeTaskPop({ allDropVal4, setAllDropVal4 }) {
  const [lock, setLock] = useState(false)

  const arrangData = [
    "Outdoor Activities",
    "Outdoor Essentials",
    "Outdoor Locations",
    "Task Name",
    "Lorem Ipsum",
  ]


  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setAllDropVal4("Activity Options");
    }
  };

  useEffect(() => {
    if (allDropVal4 === "Group Tasks") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [allDropVal4]);


  return (
    <Box onClick={handelPopClose} sx={{ display: allDropVal4 === "Group Tasks" ? "flex" : "none" }} className="workspacePop rearrangeTask groupRearrangeTask" id="popBackdrop">
      <Box sx={{ display: allDropVal4 === "Group Tasks" ? "flex" : "none" }} className="workspaceContaint">
        <Typography className="companyHeader">Grouping Tasks</Typography>

        <Typography sx={{ fontWeight: "600", marginBottom: "10px" }}>Grouped Task Name</Typography>
        <Box className="arrangSearchBox">
          <input className="phoneInput" placeholder="Type a name for the grouped tasks" />
        </Box>


        <Typography sx={{ fontWeight: "600", marginBottom: "10px" }}>Select the un-locked tasks you want to group together</Typography>
        <Box className="arrangSearchBox">
          <SearchBar placeholder="Search Tasks" />
        </Box>

        {arrangData?.map((el, i) => (
          <Box key={i} className="arrengItem">
            <input className="custom-checkbox" type="checkBox" />
            <img className="opt2l" src={option2Line} />
            <Typography>{el}</Typography>
            <Box className="lockIcon" onClick={() => setLock(!lock)}>
              {lock ? <img src={lockIcon} /> : <img src={unlockIcon} />}
            </Box>
          </Box>
        ))}

        <Box className="airadioSwitchBox">
          <AntSwitch
            defaultChecked
            inputProps={{ "aria-label": "ant design" }}
          />
          <Typography className="switchText">Randomize un-locked tasks for participants</Typography>
        </Box>
        <Box className="iBox">
          <img src={iIcon} />
          <Typography>The random ordering of Tasks is experienced by participants only while completing the Activity. The responses to the randomized Tasks will appear in the Thread in the order they were experienced by each participant. In all other cases, the Tasks will appear in their configured order.</Typography>
        </Box>

        <Box className="workPupBtnBox">
          <AppBtnOutLine buttonText="Cancel" onClick={() => setAllDropVal4("Activity Options")} />
          <AppBtn buttonText="Group Selected Tasks" />
        </Box>


      </Box>
    </Box>

  );
}
