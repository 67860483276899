import React, { useEffect, useState, useRef } from "react";
import "./style.css";

import { useTranslation } from "react-i18next";
import axios from "../../../lib/axios";
import { Box, Typography } from "@mui/material";
import { SearchBar, LatestCreated } from "../../AppTools/AppTool";
import InviteSupAdminPop from "../InviteSuperAdminPop";
import AddAdminPop from "../AddAdminPop";
import EditAdminPop from "../EditAdminPop";
import DeleteAdminPop from "../DeleteAdminPop";
import { editPen, deleteIcon } from "../../../Assets/Data";
import Pagination from "../../AppTools/Pagination";
import { formatDate, viewImage } from "../../../lib/helpers";
import DummyUser from "../../../Assets/Images/user-dummy.png";

const DirectoryAdmin = ({
  setInviteSupAdminPop,
  inviteSupAdminPop,
  addAdminPop,
  setAddAdminPop,
}) => {
  const [latestCDrop, setLatestDrop] = useState();
  const [latestDropVal, setLatestDropVal] = useState("Latest Created");

  const [editAdminPop, setEditAdminPop] = useState(false);
  const [deleteAdminPop, setDeleteAdminPop] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [alladminData, setAllAdmins] = useState([]);
  const [adminId, setAdminId] = useState("");
  const [delAdminId, setDelAdminId] = useState("");
  const [delAdminName, setDelAdminName] = useState("");
  const latestDropRef = useRef(null);

  const defaultFilters = {
    search: "",
    sortBy: "created_at",
    sortOrder: -1,
  };

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 25,
    totalPages: 0,
    totalItems: 0,
  });

  const [filterState, setFilterState] = useState({ ...defaultFilters });

  const handleFilterChange = (changedFilter) => {
    let newFilters = {
      ...filterState,
      ...changedFilter,
    };
    setFilterState(newFilters);
    getAdmins(1, newFilters);
  };
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const handleOptionIndex = (i) => {
    // setOptionIndex(i);
  };

  useEffect(() => {
    getAdmins(1);
  }, []);

  const getAdmins = async (curPage, filters = filterState) => {
    let url = `/super-admin/admin/users?page=${curPage}`;

    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        url += `&${key}=${filters[key]}`
      }
    }

    return await axios
      .get(
        url
      )
      .then((res) => {
        if (res.status) {
          setAllAdmins(res.data.data);
          setPaginationData({
            currentPage: res.data.pagination.currentPage,
            itemsPerPage: res.data.pagination.itemsPerPage,
            totalPages: res.data.pagination.totalPages,
            totalItems: res.data.pagination.totalItems,
          });
        }
      });
  };

  const onPageChange = (pageNum) => {
    getAdmins(pageNum, filterState);
  };

  useEffect(() => {
    if (reloadList) {
      getAdmins(1);
      setReloadList(false);
    }
  }, [reloadList]);

  return (
    <>
      <Box className="allSuperAdminListBox">
        <InviteSupAdminPop
          setInviteSupAdminPop={setInviteSupAdminPop}
          inviteSupAdminPop={inviteSupAdminPop}
        />
        <AddAdminPop
          addAdminPop={addAdminPop}
          setAddAdminPop={setAddAdminPop}
          setReloadList={setReloadList}
          reloadList={reloadList}
        />
        <EditAdminPop
          editAdminPop={editAdminPop}
          setEditAdminPop={setEditAdminPop}
          setReloadList={setReloadList}
          reloadList={reloadList}
          adminId={adminId}
        />
        <DeleteAdminPop
          deleteAdminPop={deleteAdminPop}
          setDeleteAdminPop={setDeleteAdminPop}
          setReloadList={setReloadList}
          delAdminId={delAdminId}
          delAdminName={delAdminName}
        />
        <Box className="adminListNav">
          <Box>
            <Typography className="mainHeader">
              {t("directoryAdmins.allSupAdmin")}
            </Typography>
          </Box>
          <Box className="navMenus">
            <Box className="adminListNavItem adminListSearchBar">
              <SearchBar handleFilterChange={handleFilterChange} />
            </Box>
            <Box
              sx={{ minWidth: "215px" }}
              className="adminListNavItem adminLatest"
            >
              <LatestCreated
                ref={latestDropRef}
                setLatestDrop={setLatestDrop}
                latestCDrop={latestCDrop}
                handleFilterChange={handleFilterChange}
                setLatestDropVal={setLatestDropVal}
                latestDropVal={latestDropVal}
              />
            </Box>
          </Box>
        </Box>
        <Box className="adminkListBox">
          <Box className="haderRow" minWidth={"1111px"}>
            <Box className="Tabelsel TabelselItemStart" minWidth={"28%"}>
              <Typography>{t("directoryAdmins.supAdminName")}</Typography>
            </Box>
            <Box className="Tabelsel TabelselItemStart" minWidth={"25%"}>
              <Typography>{t("commonTxt.mailAddress")}</Typography>
            </Box>
            <Box className="Tabelsel TabelselItemStart" minWidth={"27%"}>
              <Typography>{t("directoryAdmins.added")}</Typography>
            </Box>
          </Box>
          {alladminData &&
            alladminData?.map((el, index) => (
              <Box className="TabelRow" minWidth={"1111px"}>
                {/* {console.log("spadmin", el)} */}
                <Box className=" TabCell navFCel navTable" minWidth={"28%"}>
                  <Box
                    // onClick={() => handleOptionIndex(index)}
                    className="tabelAvatar"
                  >
                    {(el?.profile_pic == 'undefined' || el?.profile_pic == null) ? <img src={DummyUser} /> : <img src={viewImage(el?.profile_pic)} />}
                  </Box>
                  <Box>
                    <Typography>{el.name}</Typography>
                  </Box>
                </Box>

                <Box className="Tabelsel TabelselItemStart" minWidth={"25%"}>
                  <Typography>{el.email}</Typography>
                </Box>
                <Box className="Tabelsel TabelselItemStart" minWidth={"27%"}>
                  <Typography>{formatDate(el.created_at)}</Typography>
                </Box>
                <Box
                  className="Tabelsel tableICon TabelselItemStart"
                  minWidth={"10%"}
                >
                  <Typography onClick={() => {setEditAdminPop(true);setAdminId(el._id)}}>
                    {editPen}
                  </Typography>
                  <Typography onClick={() => {
                    setDeleteAdminPop(true);
                    setDelAdminName(el.name);
                    setDelAdminId(el._id)}
                    }>
                    {deleteIcon}
                  </Typography>
                </Box>
              </Box>
            ))}
        </Box>
        <Pagination
          paginationData={paginationData}
          onPageChange={onPageChange}
        />
      </Box>
    </>
  );
};

export default DirectoryAdmin;
