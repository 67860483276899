import React, { useEffect, useState } from 'react';
import "./style.css"
import { Box, Typography } from '@mui/material';
import { AntSwitch } from "../../../Components/AppTools/AppTool"

//images
import qrImg from "../../../Assets/Images/Qrimg.png"
import { sideHaumIcon } from "../../../Assets/Data";

//components
import { AppBtn, AppBtnOutLine } from "../../../Components/AppTools/AppButton";
import SideMenu from "../../../Components/User/SideMenu";
import axios from '../../../lib/axios';
import { API_BASE_URL } from '../../../env';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { updateRedUserProfile } from '../../../Services/auth';
import { renderUserLayout } from '../../../lib/helpers';
import { useAppSelector } from '../../../lib/store/store';


export default function Authentication({ slideMenu, setSlideMenu, workspaceDrop, setWorkSpaceDrop }) {
  
  const user2FAState = useSelector((state)=>state.auth.userProfile.two_fa_enabled);
  const authState = useAppSelector((state)=>state.auth);
  const dispatch = useDispatch()
  
  const [Auth, setAuth] = useState(false)
  const [qrCode, setQrCode] = useState()

  const authData = {
    email: authState.userProfile.email,
  }
  const verifyAuthData = {
    email: authState.userProfile.email,
    token: ""
  }
  
  const [verifyOTP, setVerifyOTP] = useState(verifyAuthData)
  const generate2FA = async() =>{
    const payload = {...authData}
    try {
      const data = await axios.post(API_BASE_URL + '/auth/generate-2fa-secret', payload);
      // console.log(data.data);
      if(data.success){
        toast.success(data.message)
        setQrCode(data.data)
      }else{
        toast.promise(data.message)
      }
    } catch (error) {
      console.error('Error generating 2FA secret:', error);
      toast.error(error.message);
    }
  }
  const disable2FA = async() =>{
    const payload = {...authData}
    try {
      const data = await axios.post(API_BASE_URL + '/auth/disable-2fa', payload);
      // console.log(data);
      if(data.success){
        toast.success(data.message)
        updateRedUserProfile(authState.authToken, dispatch);
      }else{
        toast.error(data.message)
      }
    } catch (error) {
      console.error('Error Disable 2FA :', error);
      toast.error(error.message);
    }
  }

  const updateVerify2FA = async() =>{
    const payload = {...verifyOTP}
    try {
      const data = await axios.post(API_BASE_URL + '/auth/verify-2fa-otp', payload);
      console.log(data);
      if(data.success){
        toast.success(data.message)
        setVerifyOTP(verifyAuthData)
        updateRedUserProfile(authState.authToken, dispatch);
      }else{
        toast.error(data.message)
      }
    } catch (error) {
      console.error('Error Disable 2FA :', error);
      toast.error(error.message);
    }
  }

  const handleEnableDisableAuth = async () => {
    try {
      if (Auth) {

        await disable2FA();
      } else {
        
        await generate2FA();
      }
     
      setAuth(!Auth);
    } catch (error) {
      console.error('Error toggling Two-Factor Authentication:', error);
      toast.error(error.message);
    }
  };

  const handleVerify2faotp = (e) =>{
    setVerifyOTP((prevGroup) => ({
      ...prevGroup,
      [e.target.name]: e.target.value,
    }));
  }

  useEffect(()=>{
    setAuth(user2FAState)
  },[])

  const getChildren =() => {
    return (
      <Box className="researchersMainBox pageContainer">
            <Box className="authOuterBox">
              <Box className="authContainer">
                <Typography className='pageHeaderText'>Two-Factor Authentication</Typography>
                <Typography className='authContainerSubText'>This feature adds an extra layer of security to your account by asking for a One Time Password (OTP) at the time of login. This required OTP will be generated on either your phone or computer by an external application. We recommend using Google Authenticator, Authy or 1Password for the same.</Typography>
                <Box className="airadioSwitchBox">
                  <AntSwitch onClick={handleEnableDisableAuth} checked={Auth} inputProps={{ 'aria-label': 'ant design' }} />
                  <Typography className='switchText'>Enable Two-Factor Authentication</Typography>
                </Box>
              </Box>

              <Box sx={{ display: (Auth && !user2FAState) ? "block" : "none" }} className="authContainer">
                <Typography className='pageHeaderText'>Step 1</Typography>
                <Typography className='authContainerSubText'>Open a Two - Factor authentication app on your phone or computer. We recommend using <span>Authy, Google Authenticator</span> or <span>Password.</span></Typography>
                <Typography className='authContainerSubText'>Scan the barcode below with the app or enter the code manually (without spaces).</Typography>

                <Box className="QrBox">
                  <Box>
                    <img src={qrCode?.qrImageDataUrl} />
                  </Box>
                </Box>
                {/* <Box className="qrCodenumBox">
                  <span>38H4</span>
                  <span>20L4</span>
                  <span>DH7K</span>
                  <span>RN0Q</span>
                  <span>61F4</span>
                  <span>93J7</span>
                </Box> */}
                <Typography className='pageHeaderText'>Step 2</Typography>
                <Typography className='authContainerSubText'>Enter the 8 digit code generated by the app in the box below</Typography>
                {/* error */}
                {/* success */}
                <Box className="qrInputbox success">
                  <input value={verifyOTP.token} onChange={handleVerify2faotp} name='token'/>
                  <Typography>Incorrect code! Try again</Typography>
                </Box>

                <Box className="workPupBtnBox">
                  <AppBtnOutLine buttonText="Cancel" />
                  <AppBtn buttonText="Save" onClick={updateVerify2FA}/>
                </Box>
              </Box>
            </Box>
          </Box>
    )
  }

  return renderUserLayout(authState?.userType, null, getChildren(), 'accountsetting')
}
