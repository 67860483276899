import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.css";

//images
import iIcon from "../../../Assets/Images/IIcon.png";
import recapImageIcon from "../../../Assets/Images/recapImageIcon.png";
import recapImageIcon2 from "../../../Assets/Images/recapImageIcon2.png";
import mixedMediaIcon from "../../../Assets/Images/mixedMediaIcon.png";
import mixedMediaIcon2 from "../../../Assets/Images/mixedMediaIcon2.png";
import reviewVideoIcon from "../../../Assets/Images/reviewVideoIcon.png";
import reviewVideoIcon2 from "../../../Assets/Images/reviewVideoIcon2.png";
import reviewAudioIcon from "../../../Assets/Images/reviewAudioIcon.png";
import reviewAudioIcon2 from "../../../Assets/Images/reviewAudioIcon2.png";

//svg icons
import {
  TIcon,
  imageIcon,
  videoIcon,
  micIcon,
  editIcon2,
  pollIcon,
  onTheDotIcon,
  rankItShortIcon,
  matrixIcon,
  screenShortIcon,
  anyFileIcon,
} from "../../../Assets/Data";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../env";
//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import AddTextTaskPop from "../AddTextTaskPop";
import AddImageTaskPop from "../AddImageTaskPop";
import AddVideoTaskPop from "../AddVideoTaskPop";
import AddAudioTaskPop from "../AddAudioTaskPop";
import AddFillOutTaskPop from "../AddFillOutTaskPop";
import AddMixedMediaTaskPop from "../AddMixedMediaTaskPop";
import AddScreenCaptureTaskPop from "../AddScreenCaptureTaskPop";
import AnyFileTaskPop from "../AnyFileTaskPop";
import ReviewAnImage from "../ReviewAnImagePop";

export default function AddTaskPop({
  allDropVal4,
  setAllDropVal4,
  selectedTask,
  setSelectedTask,
  handelAddTaskPopCoutinue,
  taskData,
  setTaskData,
  initialTaskState,
}) {
  const Navigate = useNavigate();
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const [addTextTaskPop, setAddTextTaskPop] = useState(false);
  const [addImageTaskPop, setAddImageTaskPop] = useState(false);
  const [addVideoTaskPop, setAddVideoTaskPop] = useState(false);
  const [addAudioTaskPop, setAddAudioTaskPop] = useState(false);
  const [addFillOutTaskPop, setAddFillOutTaskPop] = useState(false);
  const [addMixedMediaTaskPop, setAddMixedMediaTaskPop] = useState(false);
  const [addScreenCaptureTaskPop, setAddScreenCaptureTaskPop] = useState(false);
  const [anyFileTaskPop, setAnyFileTaskPop] = useState(false);
  const [reviewAnImage, setReviewAnImage] = useState(false);

  const ActivityCard = ({ icon, title, subText, type }) => {
    return (
      <Box
        onClick={() => {
          setTaskData({ ...taskData, type: title });
          setSelectedTask(title);
        }}
        className={
          selectedTask === title
            ? "ActivityTypeItem ActivityTypeItemClicked"
            : "ActivityTypeItem"
        }
      >
        <Box className="acTyTitleBox">
          <Typography>{title}</Typography>
          <Box
            className={
              selectedTask === title ? "acIconBox acIconActiveBox" : "acIconBox"
            }
          >
            {/* {icon} */}
            {type == 1 ? icon : <img src={icon} />}
          </Box>
        </Box>
        <span>{subText}</span>
      </Box>
    );
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setAllDropVal4("Activity Options");
    }
  };

  const validationObj = {
    type: {
      error: false,
      msg: "",
    },
  };

  const [validationErrors, setValidationErrors] = useState({
    ...validationObj,
  });

  const handleValidation = (formInput) => {
    formInput = formInput || taskData;
    let validationerr = { ...validationObj };

    if (formInput.type == "") {
      validationerr.hasErr = true;
      validationerr.type = {
        error: true,
        msg: "Type is required",
      };
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  };

  const handle1stForm = () => {
    if (!handleValidation()) {
      toast.error("Please correct form fields", TOAST_CONFIG);
      return false;
    }
    handelAddTaskPopCoutinue2();
  };

  const handelAddTaskPopCoutinue2 = () => {
    // console.log("click selected task", selectedTask);
    setAllDropVal4("Activity Options");
    if (selectedTask === "Text Task") {
      setAddTextTaskPop(true);
    } else if (selectedTask === "Image Upload Task") {
      setAddImageTaskPop(true);
    } else if (selectedTask === "Video Upload Task") {
      setAddVideoTaskPop(true);
    } else if (selectedTask === "Audio Upload Task") {
      setAddAudioTaskPop(true);
    } else if (selectedTask === "Fill It Out Task") {
      setAddFillOutTaskPop(true);
    } else if (selectedTask === "Mixed Media Upload Task") {
      setAddMixedMediaTaskPop(true);
    } else if (selectedTask === "Screen Capture Task") {
      setAddScreenCaptureTaskPop(true);
    } else if (selectedTask === "Any File Upload Task") {
      setAnyFileTaskPop(true);
    } else if (selectedTask === "Review an Image Task") {
      setReviewAnImage(true);
    }
  };

  useEffect(() => {
    if (allDropVal4 === "Add Tasks") {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [allDropVal4]);

  const handelAddTaskPopBack2 = () => {
    setAllDropVal4("Add Tasks");
    setAddTextTaskPop(false);
  };

  return (
    <>
      <AddTextTaskPop
        setAllDropVal4={setAllDropVal4}
        addTextTaskPop={addTextTaskPop}
        setAddTextTaskPop={setAddTextTaskPop}
        handelAddTaskPopBack2={handelAddTaskPopBack2}
        taskData={taskData}
        setTaskData={setTaskData}
        initialTaskState={initialTaskState}
        setSelectedTask={setSelectedTask}
      />
      <AddImageTaskPop
        setAllDropVal4={setAllDropVal4}
        addImageTaskPop={addImageTaskPop}
        setAddImageTaskPop={setAddImageTaskPop}
        handelAddTaskPopBack2={handelAddTaskPopBack2}
        taskData={taskData}
        setTaskData={setTaskData}
        initialTaskState={initialTaskState}
        setSelectedTask={setSelectedTask}
      />
      <AddVideoTaskPop
        setAllDropVal4={setAllDropVal4}
        addVideoTaskPop={addVideoTaskPop}
        setAddVideoTaskPop={setAddVideoTaskPop}
        handelAddTaskPopBack2={handelAddTaskPopBack2}
        taskData={taskData}
        setTaskData={setTaskData}
        initialTaskState={initialTaskState}
        setSelectedTask={setSelectedTask}
      />

      <AddAudioTaskPop
        setAllDropVal4={setAllDropVal4}
        addAudioTaskPop={addAudioTaskPop}
        setAddAudioTaskPop={setAddAudioTaskPop}
        handelAddTaskPopBack2={handelAddTaskPopBack2}
        taskData={taskData}
        setTaskData={setTaskData}
        initialTaskState={initialTaskState}
        setSelectedTask={setSelectedTask}
      />

      <AddFillOutTaskPop
        setAllDropVal4={setAllDropVal4}
        addFillOutTaskPop={addFillOutTaskPop}
        setAddFillOutTaskPop={setAddFillOutTaskPop}
        handelAddTaskPopBack2={handelAddTaskPopBack2}
        taskData={taskData}
        setTaskData={setTaskData}
        initialTaskState={initialTaskState}
        setSelectedTask={setSelectedTask}
      />

      <AddMixedMediaTaskPop
        setAllDropVal4={setAllDropVal4}
        addMixedMediaTaskPop={addMixedMediaTaskPop}
        setAddMixedMediaTaskPop={setAddMixedMediaTaskPop}
        handelAddTaskPopBack2={handelAddTaskPopBack2}
        taskData={taskData}
        setTaskData={setTaskData}
        initialTaskState={initialTaskState}
        setSelectedTask={setSelectedTask}
      />

      <AddScreenCaptureTaskPop
        setAllDropVal4={setAllDropVal4}
        addScreenCaptureTaskPop={addScreenCaptureTaskPop}
        setAddScreenCaptureTaskPop={setAddScreenCaptureTaskPop}
        handelAddTaskPopBack2={handelAddTaskPopBack2}
        taskData={taskData}
        setTaskData={setTaskData}
        initialTaskState={initialTaskState}
        setSelectedTask={setSelectedTask}
      />

      <AnyFileTaskPop
        setAllDropVal4={setAllDropVal4}
        anyFileTaskPop={anyFileTaskPop}
        setAnyFileTaskPop={setAnyFileTaskPop}
        handelAddTaskPopBack2={handelAddTaskPopBack2}
        taskData={taskData}
        setTaskData={setTaskData}
        initialTaskState={initialTaskState}
        setSelectedTask={setSelectedTask}
      />
      <ReviewAnImage
        setAllDropVal4={setAllDropVal4}
        reviewAnImage={reviewAnImage}
        setReviewAnImage={setReviewAnImage}
        handelAddTaskPopBack2={handelAddTaskPopBack2}
        taskData={taskData}
        setTaskData={setTaskData}
        initialTaskState={initialTaskState}
        setSelectedTask={setSelectedTask}
      />

      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: allDropVal4 === "Add Tasks" ? "flex" : "none" }}
        className="companyPop addActivitiesPop"
      >
        <Box
          sx={{ display: allDropVal4 === "Add Tasks" ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Box>
            <Typography className="taskPopMainLabel">
              {t("addTaskPop.taskType")}
            </Typography>

            <Box className="iBox">
              <img src={iIcon} />
              <Typography>{t("addTaskPop.addTaskNotify")}</Typography>
            </Box>

            <Box className="ActivityTypeBox">
              {ActivityCard({
                icon: TIcon,
                title: "Text Task",
                subText:
                  "Capture open-ended text responses with optional word limit, formatting and images.",
                type: 1,
              })}
              {ActivityCard({
                icon: imageIcon,
                title: "Image Upload Task",
                subText:
                  "Get feedback about an image with mark-up, comments and annotation on various locations.",
                type: 1,
              })}
              {ActivityCard({
                icon: videoIcon,
                title: "Video Upload Task",
                subText:
                  "Get participants to upload a video with open-ended responses and optional captions, emotions and comments.",
                type: 1,
              })}
              {ActivityCard({
                icon: micIcon,
                title: "Audio Upload Task",
                subText:
                  "Get participants to upload an audio with open-ended responses and optional captions, emotions and comments.",
                type: 1,
              })}
              {ActivityCard({
                icon: editIcon2,
                title: "Fill It Out Task",
                subText:
                  "Use a custom form with polls, scales, photo and text to get responses to questions.",
                type: 1,
              })}
              {ActivityCard({
                icon:
                  selectedTask === "Mixed Media Upload Task"
                    ? mixedMediaIcon2
                    : mixedMediaIcon,
                title: "Mixed Media Upload Task",
                subText:
                  "Get participants to upload a mix of video, image and audio with open-ended responses and optional captions, emotions and comments. Ideal for blog style journals.",
                type: 0,
              })}
              {ActivityCard({
                icon:
                  selectedTask === "Review an Image Task"
                    ? recapImageIcon2
                    : recapImageIcon,
                title: "Review an Image Task",
                subText:
                  "Mark-up and annotate precise locations/spots of interest on any image.",
                type: 0,
              })}
              {ActivityCard({
                icon:
                  selectedTask === "Review a Video Task"
                    ? reviewVideoIcon2
                    : reviewVideoIcon,
                title: "Review a Video Task",
                subText:
                  "Capture responses about a video with tags and comments categorised as it plays.",
                type: 0,
              })}
              {ActivityCard({
                icon:
                  selectedTask === "Review an Audio Task"
                    ? reviewAudioIcon2
                    : reviewAudioIcon,
                title: "Review an Audio Task",
                subText:
                  "Capture responses about an audio with tags and comments categorised as it plays.",
                type: 0,
              })}
              {ActivityCard({
                icon: pollIcon,
                title: "Poll & Survey Task",
                subText:
                  "Get participants responses to a list of options including multiple choices and selection.",
                type: 1,
              })}
              {ActivityCard({
                icon: onTheDotIcon,
                title: "On-the-Dot Task",
                subText:
                  "Use a range of prompts, URLs (external or internal), messages, discussions and tasks navigations to capture responses.",
                type: 1,
              })}
              {ActivityCard({
                icon: rankItShortIcon,
                title: "Rank-it & Sort-it Task",
                subText:
                  "Get participants responses via ranking and sorting techniques or display well-labelled cards that can be categorised.",
                type: 1,
              })}
              {ActivityCard({
                icon: matrixIcon,
                title: "Matrix Task",
                subText:
                  "Use a grid-like/tabular structure to present and capture participants' responses.",
                type: 1,
              })}
              {ActivityCard({
                icon: screenShortIcon,
                title: "Screen Capture Task",
                subText:
                  "Participants can record their screen and add audio/textual commentary.",
                type: 1,
              })}
              {ActivityCard({
                icon: anyFileIcon,
                title: "Any File Upload Task",
                subText:
                  "Have participants upload any file type. File type, template, size and structure can be specified and enforced.",
                type: 1,
              })}
              <span className="validationerr">{validationErrors.type.msg}</span>
            </Box>
            <Box className="workPupBtnBox">
              <AppBtnOutLine
                buttonText={t("commonTxt.cancelBtn")}
                onClick={() => setAllDropVal4("Activity Options")}
              />
              <AppBtn
                buttonText={t("commonTxt.continueBtn")}
                onClick={handle1stForm}
                // onClick={() => { handle1stForm();handelAddTaskPopCoutinue(); }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
