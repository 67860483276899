import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material';
import "./style.css";

import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";


//components
import { AppBtnOutLine, AppBtn } from "../../AppTools/AppButton";

export default function AddTemplatePop({ configTemplatePop, setConfigTemplatePop }) {
  const email1 = "Hello {fname}, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Read more"
  const email2 = "Best Regards,  Lorem Ipsum"
  const [emailval, setEmailval] = useState()
  const [messageTextLaOut, setMessageTextLaOut] = useState("start")
  const [messageTextItalic, setMessageTextItalic] = useState(false)
  const [messageTextBold, setMessageTextBold] = useState(false)
  const [messageTextUnderLine, setMessageTextUnderLine] = useState()
  const [signatureTextLaOut, setSignatureTextLaOut] = useState("start")
  const [signatureTextItalic, setSignatureTextItalic] = useState(false)
  const [signatureTextBold, setSignatureTextBold] = useState(false)
  const [signatureTextUnderLine, setSignatureTextUnderLine] = useState()

  const [projectDrop, setProjectDrop] = useState(false);
  const [projectDropVal, setProjectDropVal] = useState();

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setConfigTemplatePop(false)
    }
  }


  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value
    }));

  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null)
          return
        } else {
          setMessageTextUnderLine("underline")
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null)
          return
        } else {
          setMessageTextUnderLine("line-through")
        }
      }
    }
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine === "underline") {
          setSignatureTextUnderLine(null)
          return
        } else {
          setSignatureTextUnderLine("underline")
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine === "line-through") {
          setSignatureTextUnderLine(null)
          return
        } else {
          setSignatureTextUnderLine("line-through")
        }
      }
    }

  }




  useEffect(() => {
    if (configTemplatePop) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [configTemplatePop])


  return (
    <>
      <Box id="popBackdrop" onClick={handelPopClose} sx={{ display: configTemplatePop ? "flex" : "none" }} className="workspacePop">
        <Box sx={{ display: configTemplatePop ? "flex" : "none" }} className="workspaceContaint emailTemPop BrodMail">
          <Box className="mailBordPopHeader">
            <Typography className='workspaceHeader'>Add Template</Typography>

          </Box>

          <Box className="basicBox">
            <Box >
              <Typography className='eTemPopLabel'>Template Name<samp>*</samp></Typography>
              <Box className="pickerBox">
                <input className='eTemInput' placeholder='Welcome Message' />
              </Box>
            </Box>
          </Box>
          <Box className="basicBox">
              <Box className="inputBoxWlabel">
                <Typography className="eTemPopLabel">Project Visibility<samp>*</samp></Typography>

                <Box
                  className="inputAppDrop"
                  onClick={() => setProjectDrop(!projectDrop)}
                >
                  <Typography>{projectDropVal || "Select Projects"}</Typography>
                  <img
                    style={{ rotate: projectDrop ? "180deg" : "0deg" }}
                    src={dropArrow}
                  />
                  {/* pop */}
                  <Box
                    sx={{ height: projectDrop ? "auto" : "0px" }}
                    className="statusPop"
                  >
                    <Box
                      onClick={() => setProjectDropVal("Project-A")}
                      className="statusPopItem"
                    >
                      <Typography>Project-A</Typography>
                    </Box>
                    <Box
                      onClick={() => setProjectDropVal("Project-B")}
                      className="statusPopItem"
                    >
                      <Typography>Project-B</Typography>
                    </Box>
                    <Box
                      onClick={() => setProjectDropVal("Project-C")}
                      className="statusPopItem"
                    >
                      <Typography>Project-C</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
           </Box>

           <Box className="basicBox addTemplatePopSubject">
            <Box >
              <Typography className='eTemPopLabel'>Subject<samp>*</samp></Typography>
              <Box className="pickerBox">
                <input className='eTemInput' placeholder='Welcome to the project' />
              </Box>
            </Box>
          </Box>

          <Typography className='eTemPopLabel addTemplatePopDynamics'>Dynamic Values</Typography>
          <Box className="basicBox">
            <Box className="eTemPopDyValuBox">
              <Box>
                <Typography className='eTemPopDyValuText'>
                  First Name
                </Typography>
                <span className='eTemPopDyValuTextSpan etpTcolon'>:</span>
                <span className='eTemPopDyValuTextSpan'>{"{fname}"}</span>
              </Box>
              <Box>
                <Typography className='eTemPopDyValuText'>
                  Last Name
                </Typography>
                <span className='eTemPopDyValuTextSpan etpTcolon'>:</span>
                <span className='eTemPopDyValuTextSpan'>{"{lname}"}</span>
              </Box>
              <Box>
                <Typography className='eTemPopDyValuText'>
                  Username
                </Typography>
                <span className='eTemPopDyValuTextSpan etpTcolon'>:</span>
                <span className='eTemPopDyValuTextSpan'>{"{username}"}</span>
              </Box>
              <Box>
                <Typography className='eTemPopDyValuText'>
                  Password
                </Typography>
                <span className='eTemPopDyValuTextSpan etpTcolon'>:</span>
                <span className='eTemPopDyValuTextSpan'>{"{password}"}</span>
              </Box>




            </Box>

            <Box className="eTemPopDyValuBox">
              <Box>
                <Typography className='eTemPopDyValuText'>
                  Email
                </Typography>
                <span className='eTemPopDyValuTextSpan etpTcolon'>:</span>
                <span className='eTemPopDyValuTextSpan'>{"{email}"}</span>
              </Box>
              <Box>
                <Typography className='eTemPopDyValuText'>
                  Participant id
                </Typography>
                <span className='eTemPopDyValuTextSpan etpTcolon'>:</span>
                <span className='eTemPopDyValuTextSpan'>{"{pid}"}</span>
              </Box>
              <Box>
                <Typography className='eTemPopDyValuText'>
                  Ext. Participant id
                </Typography>
                <span className='eTemPopDyValuTextSpan etpTcolon'>:</span>
                <span className='eTemPopDyValuTextSpan'>{"{epid}"}</span>
              </Box>
              <Box>
                <Typography className='eTemPopDyValuText'>
                  Project id
                </Typography>
                <span className='eTemPopDyValuTextSpan etpTcolon'>:</span>
                <span className='eTemPopDyValuTextSpan'>{"{prid}"}</span>
              </Box>
            </Box>
          </Box>


          <Typography className='eTemPopLabel addTemplatePopMessage'>Message<samp>*</samp></Typography>
          <Box className="emailInputBox">
            <Box className="emailInputBoxNav">
              <Box onClick={() => setMessageTextItalic(!messageTextItalic)} className="emailImgBox">
                <img src={IIcon} />
              </Box>
              <Box onClick={() => setMessageTextBold(!messageTextBold)} className="emailImgBox">
                <img src={BIcon} />
              </Box>
              <Box onClick={() => handelTextlineChekc("underline", "message")} className="emailImgBox">
                <img src={UnderLineU} />
              </Box>
              <Box onClick={() => handelTextlineChekc("line-through", "message")} className="emailImgBox">
                <img src={MLineU} />
              </Box>
              <Box className="emailImgBox">
                <img src={AtachMentIcon} />
              </Box>
              <Box className="emailImgBox">
                <img src={Qbox} />
              </Box>
              <Box onClick={() => setMessageTextLaOut("start")} className="emailImgBox">
                <img src={Line1} />
              </Box>
              <Box onClick={() => setMessageTextLaOut("center")} className="emailImgBox">
                <img src={Line2} />
              </Box>
              <Box onClick={() => setMessageTextLaOut("end")} className="emailImgBox">
                <img src={Line3} />
              </Box>
              <Box className="emailImgBox">
                <img src={Line4} />
              </Box>
              <Box className="emailImgBox">
                <img src={emoji} />
              </Box>

            </Box>
            <textarea
              style={{
                textAlign: messageTextLaOut,
                fontStyle: messageTextItalic ? "italic" : "normal",
                fontWeight: messageTextBold ? "bold" : "normal",
                textDecoration: messageTextUnderLine
                // textDecoration: messageTextMLine ? "line-through" : null

              }}
              className='emailInput' name='emailMessage' value={emailval?.emailMessage || email1} onChange={handelChange} />
          </Box>

          <Typography className='eTemPopLabel'>Signature<samp>*</samp></Typography>
          <Box className="emailInputBox">
            <Box className="emailInputBoxNav">
              <Box onClick={() => setSignatureTextItalic(!signatureTextItalic)} className="emailImgBox">
                <img src={IIcon} />
              </Box>
              <Box onClick={() => setSignatureTextBold(!signatureTextBold)} className="emailImgBox">
                <img src={BIcon} />
              </Box>
              <Box onClick={() => handelTextlineChekc("underline", "signature")} className="emailImgBox">
                <img src={UnderLineU} />
              </Box>
              <Box onClick={() => handelTextlineChekc("line-through", "signature")} className="emailImgBox">
                <img src={MLineU} />
              </Box>
              <Box className="emailImgBox">
                <img src={AtachMentIcon} />
              </Box>
              <Box className="emailImgBox">
                <img src={Qbox} />
              </Box>
              <Box onClick={() => setSignatureTextLaOut("start")} className="emailImgBox">
                <img src={Line1} />
              </Box>
              <Box onClick={() => setSignatureTextLaOut("center")} className="emailImgBox">
                <img src={Line2} />
              </Box>
              <Box onClick={() => setSignatureTextLaOut("end")} className="emailImgBox">
                <img src={Line3} />
              </Box>
              <Box className="emailImgBox">
                <img src={Line4} />
              </Box>
              <Box className="emailImgBox">
                <img src={emoji} />
              </Box>

            </Box>
            <textarea
              style={{
                textAlign: signatureTextLaOut,
                fontStyle: signatureTextItalic ? "italic" : "normal",
                fontWeight: signatureTextBold ? "bold" : "normal",
                textDecoration: signatureTextUnderLine
              }}
              className='emailInput' name='emailSignature'
              value={emailval?.emailSignature || email2}
              onChange={handelChange}
            />
          </Box>

          <Box className="eTemPopLogInBtnBox">
            <Typography className='eTemPopLabel'>Login Button<samp>*</samp> : </Typography>
            <Box className="loginBtn">
              <Typography>Click here to login</Typography>
            </Box>
          </Box>
          
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={() => setConfigTemplatePop(false)} />
            <AppBtn buttonText="Save" />
          </Box>

        </Box>
      </Box>
    </>
  )
}
