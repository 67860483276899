import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { AntSwitch } from "../../AppTools/AppTool";
//images
import iIcon2 from "../../../Assets/Images/IIcon.png";
import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png";
import backArrow from "../../../Assets/Images/noun-back.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";
import groupsIcon from "../../../Assets/Images/groupsIcon.png";

import { DestinationUrlLists } from "../../../Assets/Data";


export default function AddOnDotTaskPop({ setAllDropVal4, addOnDotTaskPop, setAddOnDotTaskPop, handelAddTaskPopBack }) {

  const email1 = "Type the question/instructions to be visible"

  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();
  const [image, setImage] = useState(null);
  const [checkTaskVideoDescription, setCheckTaskVideoDescription] = useState(true);

  const [label1Drop, setLabel1Drop] = useState(false);
  const [label1DropVal, setLabel1DropVal] = useState('Continue');
  const [label1StyleDrop, setLabel1StyleDrop] = useState(false);
  const [label1StyleDropVal, setLabel1StyleDropVal] = useState('Tertiary');
  const [destinationDrop, setDestinationDrop] = useState(false);
  const [destinationDropVal, setDestinationDropVal] = useState('Go to URL')
  const [promptStatus, setPromptStatus] = useState(false);
  const [promptStatusVal, setPromptStatusVal] = useState('Incomplete');
  const [label2Drop, setLabel2Drop] = useState(false);
  const [label2DropVal, setLabel2DropVal] = useState('Continue');
  const [label2StyleDrop, setLabel2StyleDrop] = useState(false);
  const [label2StyleDropVal, setLabel2StyleDropVal] = useState('tertiary');
  const [groupDrop2, setGroupDrop2] = useState(false);
  const [redio, setRedio] = useState(0);
  const [destinationUrlDrop, setDestinationUrlDrop] = useState(false);
  const [destinationUrlDropVal, setDestinationUrlDropVal] = useState('http://terapage.com/abc?completetask');
  const [groupDrop, setGroupDrop] = useState(false);

  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
  };


  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
        setAddOnDotTaskPop(false);
    }
  };

  const handelCancelPop = () => {
    setAddOnDotTaskPop(false);
    setAllDropVal4("Add Tasks");
  }

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const chackBoxRoom = () => {
    return (
      <Box className="checkBoxItem">
        <Box>
          <input className="custom-checkbox" type="checkBox" defaultChecked={redio === 0 ? true : false} />
          <Typography>Group 0</Typography>
        </Box>
        <Box>
          <input className="custom-checkbox" type="checkBox" defaultChecked={redio === 0 ? true : false} />
          <Typography>Group 0</Typography>
        </Box>
        <Box>
          <input className="custom-checkbox" type="checkBox" defaultChecked={redio === 0 ? true : false} />
          <Typography>Group 0</Typography>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    if (addOnDotTaskPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [addOnDotTaskPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: addOnDotTaskPop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople "
      >
        <Box
          sx={{ display: addOnDotTaskPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Box onClick={handelAddTaskPopBack} className="openBtn taskBackBtn">
            <img src={backArrow} />
            <Typography className="neonBackText">Back</Typography>
            </Box>

          <Typography className="taskPopMainLabel">On-the-Dot Task</Typography>
          <Box className="iBox">
              <img src={iIcon2} />
              <Typography>
                Display messages, integrate external URLs, connect discussions or after task navigations
              </Typography>
            </Box>
          <Box className="basicBoxItem">
            <Typography className='eTemPopLabel '>Task Name</Typography>
            <Box className="pickerBox cardTitleLabel">
              <input className='eTemInput' placeholder='Enter task name' />
            </Box>
          </Box>

          <Typography className='eTemPopLabel '>Question or Instructions</Typography>
          <Box className="emailInputBox textTaskInstruction">
            <Box className="emailInputBoxNav">
              <Box
                onClick={() => setMessageTextItalic(!messageTextItalic)}
                className="emailImgBox"
              >
                <img src={IIcon} />
              </Box>
              <Box
                onClick={() => setMessageTextBold(!messageTextBold)}
                className="emailImgBox"
              >
                <img src={BIcon} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("underline", "message")}
                className="emailImgBox"
              >
                <img src={UnderLineU} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("line-through", "message")}
                className="emailImgBox"
              >
                <img src={MLineU} />
              </Box>
              <Box className="emailImgBox">
                <img src={AtachMentIcon} />
              </Box>
              <Box className="emailImgBox">
                <img src={Qbox} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("start")}
                className="emailImgBox"
              >
                <img src={Line1} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("center")}
                className="emailImgBox"
              >
                <img src={Line2} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("end")}
                className="emailImgBox"
              >
                <img src={Line3} />
              </Box>
              <Box className="emailImgBox">
                <img src={Line4} />
              </Box>
              <Box className="emailImgBox">
                <img src={emoji} />
              </Box>
            </Box>
            <textarea
              style={{
                textAlign: messageTextLaOut,
                fontStyle: messageTextItalic ? "italic" : "normal",
                fontWeight: messageTextBold ? "bold" : "normal",
                textDecoration: messageTextUnderLine,
                // textDecoration: messageTextMLine ? "line-through" : null
              }}
              className="emailInput"
              placeholder="Enter a discription or instructions for the card"
              name="emailMessage"
              value={emailval?.emailMessage || email1}
              onChange={handelChange}
            />
          </Box>

          <Box className="basicBoxItem">
            <Box className='mediaInputbox upload-area taskUoloadImageBox' onClick={() => {
                document.getElementById("fileInput").click();
            }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}>
                <img src={uploadIcon} />
                <Typography>Click or drag an image to this area to upload</Typography>
                <input
                id="fileInput"
                style={{ display: "none" }}
                type="file"
                onChange={handleFileInputChange}
                />
            </Box>
          </Box>
            
          <Typography className='textTaskSetting '>Task Configuration</Typography>
            <Box className="advanceFilterRowOne">
                <Box className="inputBoxWlabel">
                <Typography className="inputLabel">Label 1</Typography>
                <Box
                    className="inputAppDrop"
                    onClick={() => setLabel1Drop(!label1Drop)}
                >
                    <Typography>
                    {label1DropVal || "Select Label 1"}
                    </Typography>
                    <img
                    style={{ rotate: label1Drop ? "180deg" : "0deg" }}
                    src={dropArrow}
                    />
                    {/* pop */}
                    <Box
                    sx={{ height: label1Drop ? "auto" : "0px" }}
                    className="statusPop"
                    >
                    <Box
                        onClick={() => setLabel1DropVal("Continue")}
                        className="statusPopItem"
                    >
                        <Typography>Continue</Typography>
                    </Box>
                    <Box
                        onClick={() => setLabel1DropVal("Continue 1")}
                        className="statusPopItem"
                    >
                        <Typography>Continue 1</Typography>
                    </Box>
                    <Box
                        onClick={() => setLabel1DropVal("Continue 2")}
                        className="statusPopItem"
                    >
                        <Typography>Continue 2</Typography>
                    </Box>
                    <Box
                        onClick={() => setLabel1DropVal("Continue 3")}
                        className="statusPopItem"
                    >
                        <Typography>Continue 3</Typography>
                    </Box>
                    </Box>
                </Box>
                </Box>

                <Box className="inputBoxWlabel">
                <Typography className="inputLabel">Select Style</Typography>

                <Box
                    className="inputAppDrop"
                    onClick={() => setLabel1StyleDrop(!label1StyleDrop)}
                >
                    <Typography>{label1StyleDropVal || "Select Style"}</Typography>
                    <img
                    style={{ rotate: label1StyleDrop ? "180deg" : "0deg" }}
                    src={dropArrow}
                    />
                    {/* pop */}
                    <Box
                    sx={{ height: label1StyleDrop ? "auto" : "0px" }}
                    className="statusPop"
                    >
                    <Box
                        onClick={() => setLabel1StyleDropVal("Tertiary")}
                        className="statusPopItem"
                    >
                        <Typography>Tertiary</Typography>
                    </Box>
                    <Box
                        onClick={() => setLabel1StyleDropVal("Tertiary 1")}
                        className="statusPopItem"
                    >
                        <Typography>Tertiary 1</Typography>
                    </Box>
                    <Box
                        onClick={() => setLabel1StyleDropVal("Primary")}
                        className="statusPopItem"
                    >
                        <Typography>Primary</Typography>
                    </Box>
                    <Box
                        onClick={() => setLabel1StyleDropVal("Secondary")}
                        className="statusPopItem"
                    >
                        <Typography>Secondary</Typography>
                    </Box>
                    </Box>
                </Box>
                </Box>
            </Box>
            <Box className="advanceFilterRowOne">
                <Box className="inputBoxWlabel">
                <Typography className="inputLabel onDotBasicSetting">Select Destination</Typography>
                <Box
                    className="inputAppDrop"
                    onClick={() => setDestinationDrop(!destinationDrop)}
                >
                    <Typography>
                    {destinationDropVal || "Select Destination"}
                    </Typography>
                    <img
                    style={{ rotate: destinationDrop ? "180deg" : "0deg" }}
                    src={dropArrow}
                    />
                    {/* pop */}
                    <Box
                    sx={{ height: destinationDrop ? "auto" : "0px" }}
                    className="statusPop"
                    >
                    {DestinationUrlLists &&
                        DestinationUrlLists?.map((el, index) => (
                            <Box className="statusPopItem">
                            <Typography 
                                onClick={(e) => setDestinationDropVal(e.target.innerText)} // Modify this line
                            >
                                {el.title}
                            </Typography>
                            </Box>
                    ))}
                    </Box>
                </Box>
                </Box>

                <Box className="inputBoxWlabel onDotBasicSetting">
                <Typography className="inputLabel">Prompt Status</Typography>

                <Box
                    className="inputAppDrop"
                    onClick={() => setPromptStatus(!promptStatus)}
                >
                    <Typography>{promptStatusVal || "Select Prompt Status"}</Typography>
                    <img
                    style={{ rotate: promptStatus ? "180deg" : "0deg" }}
                    src={dropArrow}
                    />
                    {/* pop */}
                    <Box
                    sx={{ height: promptStatus ? "auto" : "0px" }}
                    className="statusPop"
                    >
                    <Box
                        onClick={() => setPromptStatusVal("Complete")}
                        className="statusPopItem"
                    >
                        <Typography>Complete</Typography>
                    </Box>
                    <Box
                        onClick={() => setPromptStatusVal("Incomplete")}
                        className="statusPopItem"
                    >
                        <Typography>Incomplete</Typography>
                    </Box>
                    <Box
                        onClick={() => setPromptStatusVal("Inprogress")}
                        className="statusPopItem"
                    >
                        <Typography>Inprogress</Typography>
                    </Box>
                    
                    </Box>
                </Box>
                </Box>
            </Box>

            <Box className="advanceFilterRowOne">
                <Box className="inputBoxWlabel">
                <Typography className="inputLabel onDotBasicSetting">Destination URL</Typography>
                <Box
                    className="inputAppDrop"
                    onClick={() => setDestinationUrlDrop(!destinationUrlDrop)}
                >
                    <Typography>
                    {destinationUrlDropVal || "Select Label 1"}
                    </Typography>
                    <img
                    style={{ rotate: destinationUrlDrop ? "180deg" : "0deg" }}
                    src={dropArrow}
                    />
                    {/* pop */}
                    <Box
                    sx={{ height: destinationUrlDrop ? "auto" : "0px" }}
                    className="statusPop"
                    >
                    <Box
                        onClick={() => setDestinationUrlDropVal("http://terapage.com/abc?compeletetask")}
                        className="statusPopItem"
                    >
                        <Typography>http://terapage.com/abc?compeletetask</Typography>
                    </Box>
                    
                    </Box>
                </Box>
                </Box>
            </Box>
            <Typography className='advanceOptionLink'>Show Advance Options</Typography>
            <Box className="advanceFilterRowOne">
                <Box className="inputBoxWlabel">
                <Typography className="inputLabel">Label 2</Typography>
                <Box
                    className="inputAppDrop"
                    onClick={() => setLabel2Drop(!label2Drop)}
                >
                    <Typography>
                    {label2DropVal || "Select Label 1"}
                    </Typography>
                    <img
                    style={{ rotate: label2Drop ? "180deg" : "0deg" }}
                    src={dropArrow}
                    />
                    {/* pop */}
                    <Box
                    sx={{ height: label2Drop ? "auto" : "0px" }}
                    className="statusPop"
                    >
                    <Box
                        onClick={() => setLabel2DropVal("Continue")}
                        className="statusPopItem"
                    >
                        <Typography>Continue</Typography>
                    </Box>
                    <Box
                        onClick={() => setLabel2DropVal("Continue 1")}
                        className="statusPopItem"
                    >
                        <Typography>Continue 1</Typography>
                    </Box>
                    <Box
                        onClick={() => setLabel2DropVal("Continue 2")}
                        className="statusPopItem"
                    >
                        <Typography>Continue 2</Typography>
                    </Box>
                    <Box
                        onClick={() => setLabel2DropVal("Continue 3")}
                        className="statusPopItem"
                    >
                        <Typography>Continue 3</Typography>
                    </Box>
                    </Box>
                </Box>
                </Box>

                <Box className="inputBoxWlabel">
                <Typography className="inputLabel">Select Style</Typography>

                <Box
                    className="inputAppDrop"
                    onClick={() => setLabel2StyleDrop(!label2StyleDrop)}
                >
                    <Typography>{label2StyleDropVal || "Select Style"}</Typography>
                    <img
                    style={{ rotate: label2StyleDrop ? "180deg" : "0deg" }}
                    src={dropArrow}
                    />
                    {/* pop */}
                    <Box
                    sx={{ height: label2StyleDrop ? "auto" : "0px" }}
                    className="statusPop"
                    >
                    <Box
                        onClick={() => setLabel2StyleDropVal("Tertiary")}
                        className="statusPopItem"
                    >
                        <Typography>Tertiary</Typography>
                    </Box>
                    <Box
                        onClick={() => setLabel2StyleDropVal("Tertiary 1")}
                        className="statusPopItem"
                    >
                        <Typography>Tertiary 1</Typography>
                    </Box>
                    <Box
                        onClick={() => setLabel2StyleDropVal("Primary")}
                        className="statusPopItem"
                    >
                        <Typography>Primary</Typography>
                    </Box>
                    <Box
                        onClick={() => setLabel2StyleDropVal("Secondary")}
                        className="statusPopItem"
                    >
                        <Typography>Secondary</Typography>
                    </Box>
                    </Box>
                </Box>
                </Box>
            </Box>
            <Box className="advanceFilterRowOne">
                <Box className="inputBoxWlabel">
                <Typography className="inputLabel onDotBasicSetting">Select Destination</Typography>
                <Box
                    className="inputAppDrop"
                    onClick={() => setDestinationDrop(!destinationDrop)}
                >
                    <Typography>
                    {destinationDropVal || "Select Destination"}
                    </Typography>
                    <img
                    style={{ rotate: destinationDrop ? "180deg" : "0deg" }}
                    src={dropArrow}
                    />
                    {/* pop */}
                    <Box
                    sx={{ height: destinationDrop ? "auto" : "0px" }}
                    className="statusPop"
                    >
                    {DestinationUrlLists &&
                        DestinationUrlLists?.map((el, index) => (
                            <Box className="statusPopItem">
                            <Typography 
                                onClick={(e) => setDestinationDropVal(e.target.innerText)} // Modify this line
                            >
                                {el.title}
                            </Typography>
                            </Box>
                    ))}
                    </Box>
                </Box>
                </Box>

                <Box className="inputBoxWlabel onDotBasicSetting">
                <Typography className="inputLabel">Prompt Status</Typography>

                <Box
                    className="inputAppDrop"
                    onClick={() => setPromptStatus(!promptStatus)}
                >
                    <Typography>{promptStatusVal || "Select Prompt Status"}</Typography>
                    <img
                    style={{ rotate: promptStatus ? "180deg" : "0deg" }}
                    src={dropArrow}
                    />
                    {/* pop */}
                    <Box
                    sx={{ height: promptStatus ? "auto" : "0px" }}
                    className="statusPop"
                    >
                    <Box
                        onClick={() => setPromptStatusVal("Complete")}
                        className="statusPopItem"
                    >
                        <Typography>Complete</Typography>
                    </Box>
                    <Box
                        onClick={() => setPromptStatusVal("Incomplete")}
                        className="statusPopItem"
                    >
                        <Typography>Incomplete</Typography>
                    </Box>
                    <Box
                        onClick={() => setPromptStatusVal("Inprogress")}
                        className="statusPopItem"
                    >
                        <Typography>Inprogress</Typography>
                    </Box>
                    
                    </Box>
                </Box>
                </Box>
            </Box>

            <Box className="advanceFilterRowOne">
                <Box className="inputBoxWlabel">
                <Typography className="inputLabel onDotBasicSetting">Destination URL</Typography>
                <Box
                    className="inputAppDrop"
                    onClick={() => setDestinationUrlDrop(!destinationUrlDrop)}
                >
                    <Typography>
                    {destinationUrlDropVal || "Select Label 1"}
                    </Typography>
                    <img
                    style={{ rotate: destinationUrlDrop ? "180deg" : "0deg" }}
                    src={dropArrow}
                    />
                    {/* pop */}
                    <Box
                    sx={{ height: destinationUrlDrop ? "auto" : "0px" }}
                    className="statusPop"
                    >
                    <Box
                        onClick={() => setDestinationUrlDropVal("http://terapage.com/abc?compeletetask")}
                        className="statusPopItem"
                    >
                        <Typography>http://terapage.com/abc?compeletetask</Typography>
                    </Box>
                    
                    </Box>
                </Box>
                </Box>
            </Box>
            
            <Typography className='advanceOptionLink'>Hide Advance Options</Typography>
            
            <Box className="searchGroupSection">
            <Typography className="inputLabel">Add to Group(s)</Typography>
                <Box
                pl={4.5}
                className="inputAppDrop"
                onClick={() => setGroupDrop(!groupDrop)}
                >
                <img className="groupsIcon" src={groupsIcon} />
                <Typography>Search Groups</Typography>
                <img style={{ rotate: groupDrop ? "180deg" : "0deg" }} src={dropArrow} />
                </Box>
                <Box
                sx={{ height: groupDrop ? "auto" : "0px" }}
                className="checkMain_box">
                <Box className="ckeckBoxRoom">
                    {chackBoxRoom()}
                    {chackBoxRoom()}
                    {chackBoxRoom()}
                </Box>
                <Box className="propertyPagination">
                    <Box>
                    <p>1</p>
                    </Box>
                    <Box>
                    <p>2</p>
                    </Box>
                    <Box>
                    <p>3</p>
                    </Box>
                    <Box>
                    <p>4</p>
                    </Box>
                    <Box>
                    <p>...</p>
                    </Box>
                    <Box>
                    <p>8</p>
                    </Box>
                    <Box>
                    <p>9</p>
                    </Box>
                    <Box>
                    <p>10</p>
                    </Box>
                </Box>
                </Box>
            </Box>
            <Box className="searchGroupSection">
            <Typography>Remove from Group(s)</Typography>
              <Box
                pl={4.5}
                className="inputAppDrop"
                onClick={() => setGroupDrop2(!groupDrop2)}
              >
                <img className="groupsIcon" src={groupsIcon} />
                <Typography>Search Groups</Typography>
                <img
                  style={{ rotate: groupDrop2 ? "180deg" : "0deg" }}
                  src={dropArrow}
                />
              </Box>
              <Box
                sx={{ height: groupDrop2 ? "auto" : "0px" }}
                className="checkMain_box"
              >
                <Box className="ckeckBoxRoom">
                  {chackBoxRoom()}
                  {chackBoxRoom()}
                  {chackBoxRoom()}
                </Box>
                <Box className="propertyPagination">
                  <Box>
                    <p>1</p>
                  </Box>
                  <Box>
                    <p>2</p>
                  </Box>
                  <Box>
                    <p>3</p>
                  </Box>
                  <Box>
                    <p>4</p>
                  </Box>
                  <Box>
                    <p>...</p>
                  </Box>
                  <Box>
                    <p>8</p>
                  </Box>
                  <Box>
                    <p>9</p>
                  </Box>
                  <Box>
                    <p>10</p>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "24px 0",
            }}
          >
            <Typography className="ppundText">Add Label</Typography>
            <Typography className="ppundText">Paste Bulk Labels</Typography>
          </Box>
          <Typography className='textTaskSetting '>Task Settings</Typography>
          
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" onClick={() => setCheckTaskVideoDescription(!checkTaskVideoDescription)}  defaultChecked />
            <Typography>Response Required</Typography>
          </Box>
          <Box sx={{ display: checkTaskVideoDescription ? "flex" : "none" }} className="wsCpInputBOx imageTaskDescription">
            <AntSwitch
                inputProps={{ "aria-label": "ant design" }}
            />
            <Typography>
                Ask for a reason to skip
            </Typography>
          </Box>

          <Typography className='textTaskSetting '>Advance Settings</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>Limit who can complete this task</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>Customize points awarded for task completetion </Typography>
          </Box>
        
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={handelCancelPop} />
            <AppBtnOutLine buttonText="Save & Preview" onClick={handelAddTaskPopBack} />
            <AppBtn buttonText="Save Task" />
          </Box>



        </Box>
      </Box>
    </>
  );
}
