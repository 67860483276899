import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../lib/store/store";
import "./style.css";

//images
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import chackBox from "../../../Assets/Images/Checkbox.png";
import check from "../../../Assets/Images/check.png";
import backArrow from "../../../Assets/Images/noun-back.png";
import cott from "../../../Assets/Images/cott.png";
import star from "../../../Assets/Images/Star 2.png";
import iIcon from "../../../Assets/Images/IIcon.png";
import idelDot from "../../../Assets/Images/idelDot.png";
import postImg from "../../../Assets/Images/postImg.png";
import editBtn from "../../../Assets/Images/edit 1.png";
import whitedropIcon from "../../../Assets/Images/Wchevron-down.png";
import semoji from "../../../Assets/Images/Semoji.png";
import imgIcon from "../../../Assets/Images/galleryIconB.png";
import mettingImg from "../../../Assets/Images/mettingImg.png";
import PreparedItemsNoData from "../../../Assets/Images/No data-cuate 1.png";
import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import redTrash from "../../../Assets/Images/redTrash.png";
import plassIcon from "../../../Assets/Images/plassIcon.png";
import imageBanner from "../../../Assets/Images/imageBanner.png";
import uploadIcon from "../../../Assets/Images/uil_upload.png";
import trasIcon from "../../../Assets/Images/fluent_delete-16-regular.png";
import OptionIcon from "../../../Assets/Images/optionIcon.png";
import PrepItem2 from "../../../Assets/Images/prepItem2.png";
import groupIcon from "../../../Assets/Images/groupsIcon.png";
import checkIcon from "../../../Assets/Images/checkG.png";
import redWIcon from "../../../Assets/Images/alert-circle.png";
import ExportWhiteIcon from "../../../Assets/Images/exportWhiteIcon.png";
import Avatar from "../../../Assets/Images/Avatar1.png";
import avatar1 from "../../../Assets/Images/inb1.png";
import avatar2 from "../../../Assets/Images/inb2.png";
import avatar3 from "../../../Assets/Images/inb3.png";
import filterIcon from "../../../Assets/Images/filterIcon.png";
import exportIcon from "../../../Assets/Images/exportIcon.png";
import dropIcon from "../../../Assets/Images/chevron-down.png";
import Option2LIcon from "../../../Assets/Images/Option2LIcon.png";
import editIcon from "../../../Assets/Images/edit 1.png";
import aiWhiteIcon from "../../../Assets/Images/aiIconWhite.png";

//component
import SideMenu from "../../../Components/Researchers/SideMenu";
import { useTranslation } from "react-i18next";
import {
  AppBtnOutLine,
  AppBtn,
  AppAddBtn,
} from "../../../Components/AppTools/AppButton";
import {
  SearchBar,
  AllDrop6,
  CalendarDropBtn,
  LatestCreated,
  Status,
} from "../../../Components/AppTools/AppTool";
import BubbleChart from "../../../Components/AppTools/BubbleChart";
import { ApexChart } from "../../../Components/AppTools/PieChart";
import { BarChart } from "../../../Components/AppTools/BarChart";
import StackedBar2 from "../../../Components/AppTools/StackedBar2";
import {
  like,
  dislike,
  comment,
  tik,
  sideHaumIcon,
  arrowIcon,
} from "../../../Assets/Data";
import Expressions from "../../../Components/Researchers/Expressions";
import ExpressionsComponent from "../../../Components/Researchers/ExpressionsComponent";
import { USER_TYPE_COLLABORATOR,  USER_TYPE_RESEARCHER, USER_TYPE_OBSERVER } from "../../../lib/constants";
export default function ViewConversationTopic({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
}) {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  let authState = useAppSelector((state) => state.auth);
  const [conOpDrop, setConOpDrop] = useState(false);
  const [chackVal, setChackVal] = useState([]);
  const [addConversationTopicPop, setAddConversationTopicPop] = useState(false);
  const [createConversationCategoryPop, setCreateConversationCategoryPop] =
    useState(false);

  const [nav, setNav] = useState(0);
  const [nav3, setNav3] = useState(0);

  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Recently Added");

  const [latestCDrop2, setLatestDrop2] = useState(false);
  const [latestDropVal2, setLatestDropVal2] = useState("Recently Added");

  const [latestCDrop3, setLatestDrop3] = useState(false);
  const [latestDropVal3, setLatestDropVal3] = useState("Recently Added");

  const [providerDrop, setProviderDrop] = useState(false);
  const [providerDropVal, setProviderDropVal] = useState();

  const [exportDrop, setExportDrop] = useState(false);
  const [exportDrop2, setExportDrop2] = useState(false);
  const [exportDrop3, setExportDrop3] = useState(false);
  const [exportDrop4, setExportDrop4] = useState(false);
  const [exportDrop5, setExportDrop5] = useState(false);

  const responseArrya = [
    {
      label: "Order Posted",
    },
    {
      label: "Recent Replies",
    },
    {
      label: "Most Liked",
    },
    {
      label: "Most Comments",
    },
    {
      label: "Recent Liked",
    },
    {
      label: "Recent Comments",
    },
    {
      label: "Reviewed Posts",
    },
  ];
  const exportItem = ["PNG", "JPEG", "PDF", "SVG"];

  const [series] = useState([{ data: [400, 430, 448, 470, 540] }]);
  const categories = [
    "Product Reviews",
    "Price",
    "Brand Reputation",
    "Durabilty/Quality",
    "Aesthetics",
  ];

  const [series2] = useState([{ data: [400, 430, 448, 470, 540] }]);
  const categories2 = ["Relaxation", "Great Quote", "Nostalgia", "Empowerment"];

  const selectAllCheckData = () => {
    setChackVal(["Richard Han", "Lucy Hutten", "Lorem Ipsum", "Dolor Amet"]);
  };

  const deleteAllCheckData = () => setChackVal([]);

  const pushData = (string) => {
    setChackVal((prev) => [...prev, string]);
  };

  const handleNavDrop = (e) => {
    if (e.target.id === "conNavDrop") {
      setConOpDrop(!conOpDrop);
    }
  };

  function wrapAtWordsWithSpan(text) {
    return text.replace(/@(\w+)/g, "<span>@$1</span>");
  }

  const singelComment = ({ img, name, comment, date, time }) => {
    return (
      <Box className="singelComment">
        <Box className="commentSection innerCommentSection">
          <Box className="avatarBox">
            <img src={img} />
          </Box>
          <Box className="commentTextSection">
            <Box className="userNameBox">
              <Typography className="userName">{name}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "15px",
                  minWidth: "213px",
                }}
              >
                <Box className="editbtn">
                  <img src={editBtn} />
                  <Typography>{t("commonTxt.edit")}</Typography>
                </Box>
                <samp className="taskHeaderText3">{date}</samp>
              </Box>
            </Box>

            <Typography
              className="commentWtag"
              dangerouslySetInnerHTML={{ __html: wrapAtWordsWithSpan(comment) }}
            ></Typography>
            <Box className="likeBox">
              <Typography>{time}</Typography>
              <Typography>{t("commonTxt.Like")}</Typography>
              <Typography>{t("commonTxt.dislike")}</Typography>
              <Typography>{t("commonTxt.reply")}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const writeCommentSection = ({ img }) => {
    return (
      <Box className="writeCommentSection">
        <Box className="avatarBox">
          <img src={img} />
        </Box>
        <Box className="commentInputBox">
          <input className="phoneInput" placeholder="Add comment" />
          <img className="inputIcon1" src={semoji} />
          <img className="inputIcon2" src={imgIcon} />
          <Box className="commentCheckBox">
            <Box
              mb={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <input
                style={{ marginRight: "10px" }}
                className="custom-checkbox"
                type="checkBox"
              />
              <Typography>{t("recapThread.makeReplyCompulsory")}</Typography>
            </Box>

            <Typography className="followText">
              {t("emailPage.temp")}
            </Typography>
          </Box>
        </Box>

        <Box
          className="directMessageNavBtn"
          onClick={() => setProviderDrop(!providerDrop)}
        >
          <Typography className="DropBtnText">
            {t("commonTxt.postBtn")}
          </Typography>
          <img
            src={whitedropIcon}
            style={{ rotate: providerDrop ? "180deg" : "0deg" }}
          />
          <Box
            className="workspaceItemNavPop"
            sx={{ display: providerDrop ? "flex" : "none" }}
          >
            <Box onClick={() => setProviderDropVal("Visible to Everyone")}>
              <Typography>{t("recapThread.visibleToEveryone")}</Typography>
            </Box>
            <Box onClick={() => setProviderDropVal("Only Andi Lane")}>
              <Typography>{t("recapThread.onlyAndiLane")}</Typography>
            </Box>
            <Box onClick={() => setProviderDropVal("Backroom Comment")}>
              <Typography>{t("recapThread.backroomComment")}</Typography>
            </Box>
            <Box onClick={() => setProviderDropVal("Backroom Task")}>
              <Typography>{t("recapThread.backroomTask")}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const nestedCommentSection = ({ img, name, comment, date, time }) => {
    return (
      <Box className="singelComment">
        <Box className="commentSection innerCommentSection">
          <Box className="avatarBox">
            <img src={img} />
          </Box>
          <Box className="commentTextSection">
            <Box className="userNameBox">
              <Typography className="userName">{name}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "15px",
                  minWidth: "213px",
                }}
              >
                <Box className="editbtn">
                  <img src={editBtn} />
                  <Typography>{t("commonTxt.edit")}</Typography>
                </Box>
                <samp className="taskHeaderText3">{date}</samp>
              </Box>
            </Box>

            <Typography
              className="commentWtag"
              dangerouslySetInnerHTML={{ __html: wrapAtWordsWithSpan(comment) }}
            ></Typography>
            <Box className="likeBox">
              <Typography>{time}</Typography>
              <Typography>{t("commonTxt.Like")}</Typography>
              <Typography>{t("commonTxt.dislike")}</Typography>
              <Typography>{t("commonTxt.reply")}</Typography>
            </Box>
            {singelComment({
              img: avatar3,
              name: "Kate Morrison",
              comment:
                "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
              date: "Aug 10, 2023",
              time: "10 h",
            })}
            {writeCommentSection({ img: avatar1 })}
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box className="ResearchersContainer viewConversatin">
        {/* side menu */}
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>

            {authState?.wsUserType == USER_TYPE_RESEARCHER || authState?.wsUserType == USER_TYPE_COLLABORATOR ? 
            (<Box sx={{ display: "flex", gap: "25px" }}>
              <Box
                id="conNavDrop"
                sx={{ minWidth: "230px" }}
                onClick={handleNavDrop}
              >
                <Box
                  id="conNavDrop"
                  className="conNavDrop"
                  onClick={handleNavDrop}
                >
                  <Typography id="conNavDrop" onClick={handleNavDrop}>
                    {t("Conversations.ConversationOptions")}
                  </Typography>
                  <img
                    onClick={handleNavDrop}
                    id="conNavDrop"
                    className="dropIcon"
                    src={DownArrowIcon}
                    style={{ rotate: conOpDrop ? "180deg" : "0deg" }}
                  />

                  {/* drop */}
                  <Box
                    sx={{ display: conOpDrop ? "flex" : "none" }}
                    className="activeCardOptionPop conVanDropMenu"
                  >
                    <Box className="acItem" onClick={() => setConOpDrop(null)}>
                      <Typography className="acdl">
                        {t("Conversations.ConversationSettings")}
                      </Typography>
                    </Box>
                    <Box
                      className="acItem"
                      onClick={() => {
                        setConOpDrop(null);
                        setCreateConversationCategoryPop(true);
                      }}
                    >
                      <Typography className="acdl">
                        {t("Conversations.CreateCategory")}
                      </Typography>
                    </Box>
                    <Box className="acItem transcript">
                      <Typography className="acdl">
                        {t("Conversations.SortCategories")}
                      </Typography>
                      {arrowIcon}
                      <Box className="acSubItem">
                        <Box className="researchDropTopLabel">
                          <Typography onClick={selectAllCheckData}>
                            {t("Conversations.SelectAll")}
                          </Typography>
                          <Typography onClick={deleteAllCheckData}>
                            {t("Conversations.DeselectAll")}
                          </Typography>
                        </Box>

                        <Box
                          onClick={() => {
                            if (chackVal.includes("Richard Han")) {
                              setChackVal(
                                chackVal.filter(
                                  (item) => item !== "Richard Han"
                                )
                              );
                            } else {
                              pushData("Richard Han");
                            }
                          }}
                          className="categoryDropItem"
                        >
                          <Box className="chackBox">
                            <img className="chackBoxImg" src={chackBox} />
                            <img
                              style={{
                                display: chackVal.includes("Richard Han")
                                  ? "block"
                                  : "none",
                              }}
                              className="chack"
                              src={check}
                            />
                          </Box>
                          <Typography>Richard Han</Typography>
                        </Box>

                        <Box
                          onClick={() => {
                            if (chackVal.includes("Lucy Hutten")) {
                              setChackVal(
                                chackVal.filter(
                                  (item) => item !== "Lucy Hutten"
                                )
                              );
                            } else {
                              pushData("Lucy Hutten");
                            }
                          }}
                          className="categoryDropItem"
                        >
                          <Box className="chackBox">
                            <img className="chackBoxImg" src={chackBox} />
                            <img
                              style={{
                                display: chackVal.includes("Lucy Hutten")
                                  ? "block"
                                  : "none",
                              }}
                              className="chack"
                              src={check}
                            />
                          </Box>
                          <Typography>Lucy Hutten</Typography>
                        </Box>

                        <Box
                          onClick={() => {
                            if (chackVal.includes("Lorem Ipsum")) {
                              setChackVal(
                                chackVal.filter(
                                  (item) => item !== "Lorem Ipsum"
                                )
                              );
                            } else {
                              pushData("Lorem Ipsum");
                            }
                          }}
                          className="categoryDropItem"
                        >
                          <Box className="chackBox">
                            <img className="chackBoxImg" src={chackBox} />
                            <img
                              style={{
                                display: chackVal.includes("Lorem Ipsum")
                                  ? "block"
                                  : "none",
                              }}
                              className="chack"
                              src={check}
                            />
                          </Box>
                          <Typography>Lorem Ipsum</Typography>
                        </Box>

                        <Box
                          onClick={() => {
                            if (chackVal.includes("Dolor Amet")) {
                              setChackVal(
                                chackVal.filter((item) => item !== "Dolor Amet")
                              );
                            } else {
                              pushData("Dolor Amet");
                            }
                          }}
                          className="categoryDropItem"
                        >
                          <Box className="chackBox">
                            <img className="chackBoxImg" src={chackBox} />
                            <img
                              style={{
                                display: chackVal.includes("Dolor Amet")
                                  ? "block"
                                  : "none",
                              }}
                              className="chack"
                              src={check}
                            />
                          </Box>
                          <Typography>Dolor Amet</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="acItem" onClick={() => setConOpDrop(null)}>
                      <Typography className="acdl">
                        {t("Conversations.PreviewMode")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <AppAddBtn
                buttonText={t("commonTxt.addTopicBtn")}
                onClick={() => setAddConversationTopicPop(true)}
              />
            </Box>) : ''
            }
          </Box>
          {/* Page container */}
          <Box className="pageContainer">
            <Box className="workSpaceListBox  viewTopicBox">
              <Box className="openBtn">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  onClick={() => Navigate("/conversation")}
                >
                  <img src={backArrow} />
                  <Typography className="neonBackText">
                    {t("commonTxt.backBtn")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "24px",
                  }}
                >
                  <Box className="actTNavBtn">
                    <img src={cott} />
                    <Typography>{t("commonTxt.excerptBtn")}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="tpcHeaderBox">
                <Box className="">
                  <Box className="pageTitleBox">
                    <img src={star} />
                    <Typography>Your Trekking must haves</Typography>
                  </Box>
                  <Typography className="eqText">(Equipment Zone)</Typography>
                </Box>
                <Box>
                  <Typography className="followText">
                    {t("Conversations.FollowTopic")}
                  </Typography>
                </Box>
              </Box>

              <Typography className="viewTopicNtext">
                This should be a fun one for you guys!
              </Typography>
              <Typography className="viewTopicNtext">
                Lorem ipsum dolor sit amet consectetur. Morbi suspendisse quis
                ornare in. Amet nunc lectus a aenean lectus. Penatibus tempor
                urna sed eros suspendisse vulputate vulputate enim vitae. Leo
                ipsum sed erat sed et. Pellentesque cursus proin ornare eros
                amet velit. Faucibus in volutpat morbi accumsan et augue et eget
                eu.
              </Typography>
              <Typography className="viewTopicNtext">
                Vitae quis scelerisque orci pellentesque ac pretium nibh sed
                ipsum. Donec lorem nullam integer duis. Morbi ultrices tempor
                nulla vel. Vitae turpis diam natoque non posuere diam neque
                nulla. Facilisis laoreet nibh ullamcorper nunc.
              </Typography>

              <Box className="iBox">
                <img src={iIcon} />
                <Typography>
                  {t("viewConversationCategory.viewCategoryNotify1")} <br />
                  {t("viewConversationCategory.viewCategoryNotify2")}{" "}
                </Typography>
              </Box>

              <Box className="togelSection">
                <Box className="topcVNav">
                  <Box
                    onClick={() => setNav(0)}
                    className={
                      nav === 0 ? "topcNavBtn topcNavBtnActive" : "topcNavBtn"
                    }
                  >
                    <Typography>{t("Conversations.AllPosts")}</Typography>
                  </Box>
                  <Box
                    onClick={() => setNav(1)}
                    className={
                      nav === 1 ? "topcNavBtn topcNavBtnActive" : "topcNavBtn"
                    }
                  >
                    <Typography>{t("Conversations.Expressions")}</Typography>
                  </Box>
                  <Box
                    onClick={() => setNav(2)}
                    className={
                      nav === 2 ? "topcNavBtn topcNavBtnActive" : "topcNavBtn"
                    }
                  >
                    <Typography>{t("Conversations.AIAnalysis")}</Typography>
                  </Box>
                </Box>

                {/* All Posts section */}
                <Box
                  sx={{ display: nav === 0 ? "block" : "none" }}
                  className="togelSubBox"
                >
                  <Box className="postSectionNav">
                    <Typography sx={{ textDecoration: "underline" }}>
                      {t("Conversations.MarkReviewed")} (2)
                    </Typography>
                    <Box sx={{ display: "flex", gap: "20px" }}>
                      <Box width={"40%"} className="agreSearchB2">
                        <SearchBar
                          placeholder={t("Conversations.searchHerePlaceholder")}
                        />
                      </Box>
                      <Box className="actTNavBtn">
                        <img src={groupIcon} />
                        <Typography>{t("commonTxt.groupBtn")}</Typography>
                      </Box>

                      <Box
                        width={"32%"}
                        minWidth={"170px"}
                        className="agreSearchB2"
                      >
                        <LatestCreated
                          setLatestDrop={setLatestDrop}
                          latestCDrop={latestCDrop}
                          setLatestDropVal={setLatestDropVal}
                          latestDropVal={latestDropVal}
                          itemAry={responseArrya}
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box className="commentSection">
                    <Box className="avatarBox">
                      <img src={avatar2} />
                      <img className="statusDot" src={idelDot} />
                    </Box>

                    <Box className="commentTextSection">
                      <Box className="userNameBox">
                        <Typography className="userName">
                          Kate Morrison
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "15px",
                          }}
                        >
                          <Typography className="taskHeaderText2">
                            {t("commonTxt.viewBtn")}
                          </Typography>
                          <samp className="taskHeaderText3">
                            Aug 04, 2023 02:21 PM
                          </samp>
                        </Box>
                      </Box>
                      <Typography>
                        I love my sleeping pad, not to be confused with the
                        sleeping bag. It gives an extra layer of comfort and
                        insulation.{" "}
                      </Typography>

                      <Box className="commentSection innerCommentSection">
                        <Box className="avatarBox">
                          <img src={avatar1} />
                        </Box>
                        <Box className="commentTextSection">
                          <Box className="userNameBox">
                            <Typography className="userName">
                              Daina Henry
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "15px",
                              }}
                            >
                              <Typography className="taskHeaderText2">
                                {t("commonTxt.viewBtn")}
                              </Typography>
                              <samp className="taskHeaderText3">
                                Aug 04, 2023 02:21 PM
                              </samp>
                            </Box>
                          </Box>

                          <Typography>
                            This is extremely important! Saves my back every
                            time I go out camping.{" "}
                          </Typography>
                          <Box className="imgPostBox">
                            <img src={postImg} />
                          </Box>
                        </Box>
                      </Box>

                      <Box className="taskBoxBottomRow">
                        <Box className="taskBoxBottomRowItem">
                          {like}
                          <Typography>{t("commonTxt.Like")}</Typography>
                        </Box>
                        <Box className="taskBoxBottomRowItem">
                          {dislike}
                          <Typography>{t("commonTxt.dislike")}</Typography>
                        </Box>
                        <Box className="taskBoxBottomRowItem">
                          {comment}
                          <Typography>{t("commonTxt.comment")}</Typography>
                        </Box>
                        <Box className="taskBoxBottomRowItem">
                          {tik}
                          <Typography>{t("commonTxt.review")}</Typography>
                        </Box>
                      </Box>

                      {nestedCommentSection({
                        img: avatar1,
                        name: "Orlando Diggs",
                        comment:
                          "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                        date: "Aug 10, 2023",
                        time: "10 h",
                      })}
                      {singelComment({
                        img: avatar2,
                        name: "Richard Han",
                        comment:
                          "Hey @sam, do have a look this particular post. ",
                        date: "Aug 10, 2023",
                        time: "10 h",
                      })}
                      {singelComment({
                        img: avatar3,
                        name: "Kate Morrison",
                        comment:
                          "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                        date: "Aug 10, 2023",
                        time: "10 h",
                      })}

                      {writeCommentSection({ img: avatar1 })}
                    </Box>
                  </Box>

                  {/* section2 */}
                  <Box className="commentSection">
                    <Box className="avatarBox">
                      <img src={avatar2} />
                      <img className="statusDot" src={idelDot} />
                    </Box>

                    <Box className="commentTextSection">
                      <Box className="userNameBox">
                        <Typography className="userName">
                          Kate Morrison
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "15px",
                          }}
                        >
                          <Typography className="taskHeaderText2">
                            {t("commonTxt.viewBtn")}
                          </Typography>
                          <samp className="taskHeaderText3">
                            Aug 04, 2023 02:21 PM
                          </samp>
                        </Box>
                      </Box>
                      <Typography>
                        I love my sleeping pad, not to be confused with the
                        sleeping bag. It gives an extra layer of comfort and
                        insulation.{" "}
                      </Typography>

                      <Box className="commentSection innerCommentSection">
                        <Box className="avatarBox">
                          <img src={avatar1} />
                        </Box>
                        <Box className="commentTextSection">
                          <Box className="userNameBox">
                            <Typography className="userName">
                              Daina Henry
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "15px",
                              }}
                            >
                              <Typography className="taskHeaderText2">
                                {t("commonTxt.viewBtn")}
                              </Typography>
                              <samp className="taskHeaderText3">
                                Aug 04, 2023 02:21 PM
                              </samp>
                            </Box>
                          </Box>

                          <Typography>
                            This is extremely important! Saves my back every
                            time I go out camping.{" "}
                          </Typography>
                        </Box>
                      </Box>

                      <Box className="taskBoxBottomRow">
                        <Box className="taskBoxBottomRowItem">
                          {like}
                          <Typography>{t("commonTxt.Like")}</Typography>
                        </Box>
                        <Box className="taskBoxBottomRowItem">
                          {dislike}
                          <Typography>{t("commonTxt.dislike")}</Typography>
                        </Box>
                        <Box className="taskBoxBottomRowItem">
                          {comment}
                          <Typography>{t("commonTxt.comment")}</Typography>
                        </Box>
                        <Box className="taskBoxBottomRowItem">
                          {tik}
                          <Typography>{t("commonTxt.review")}</Typography>
                        </Box>
                      </Box>

                      {singelComment({
                        img: avatar3,
                        name: "Richard Han",
                        comment:
                          "Hey @everyone, what other items according to you can be used to avoid injuries while trekking?",
                        date: "Aug 10, 2023",
                        time: "10 h",
                      })}
                      {singelComment({
                        img: avatar1,
                        name: "Orlando Diggs",
                        comment:
                          "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                        date: "Aug 10, 2023",
                        time: "10 h",
                      })}
                      {nestedCommentSection({
                        img: avatar1,
                        name: "Orlando Diggs",
                        comment:
                          "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                        date: "Aug 10, 2023",
                        time: "10 h",
                      })}
                      {singelComment({
                        img: avatar3,
                        name: "Kate Morrison",
                        comment:
                          "Definitely some good insole inserts for my trekking shoes. Can never take the risk of leaving without them!",
                        date: "Aug 10, 2023",
                        time: "10 h",
                      })}
                      {writeCommentSection({ img: avatar1 })}
                    </Box>
                  </Box>
                </Box>

                {/*---------------- Word Cloud section---------- */}
                <Box
                  sx={{ display: nav === 1 ? "block" : "none" }}
                  className="togelSubBox"
                >
                  <ExpressionsComponent />
                </Box>

                {/*---------------- AI Analysis section---------- */}
                <Box
                  sx={{ display: nav === 2 ? "block" : "none" }}
                  className="togelSubBox"
                >
                  <Box className="postSectionNav">
                    <Typography sx={{ textDecoration: "underline" }}>
                      {t("conversation.aiNavLabel1")}
                    </Typography>
                    <Box sx={{ display: "flex", gap: "20px" }}>
                      <Box width={"40%"} className="agreSearchB2">
                        <SearchBar
                          placeholder={t("Conversations.searchHerePlaceholder")}
                        />
                      </Box>
                      <Box className="actTNavBtn">
                        <img src={groupIcon} />
                        <Typography>{t("commonTxt.groupBtn")}</Typography>
                      </Box>

                      <Box
                        width={"32%"}
                        minWidth={"170px"}
                        className="agreSearchB2"
                      >
                        <LatestCreated
                          setLatestDrop={setLatestDrop3}
                          latestCDrop={latestCDrop3}
                          setLatestDropVal={setLatestDropVal3}
                          latestDropVal={latestDropVal3}
                          itemAry={responseArrya}
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box mt={4} className="aiSubSection">
                    <Box className="tabelNav">
                      <Typography className="pageTitle">
                        {t("conversation.pieLabel")}
                      </Typography>
                      <Box
                        className="exporOpenBtn"
                        onClick={() => setExportDrop(!exportDrop)}
                      >
                        <img src={exportIcon} />
                        <Typography className="verifyLink">
                          {t("commonTxt.exportLink")}
                        </Typography>
                        <img
                          src={dropIcon}
                          style={{ rotate: exportDrop ? "180deg" : "0deg" }}
                        />
                        <Box
                          className="statusPop"
                          sx={{ height: exportDrop ? "auto" : "0px" }}
                        >
                          {exportItem?.map((el, i) => (
                            <Box className="statusPopItem">
                              <p>{el}</p>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>

                    <Box className="pieBox ApexChart">
                      <ApexChart />
                    </Box>

                    <Box className="chartTableNav">
                      <Box className="chartNavItem">
                        <Typography>{t("commonTxt.hideBtn")}</Typography>
                      </Box>
                      <Box className="chartNavItem">
                        <Typography>{t("commonTxt.Group")}</Typography>
                      </Box>
                      <Box className="chartNavItem">
                        <Typography>{t("commonTxt.ChangeColor")}</Typography>
                      </Box>
                    </Box>

                    <Box mb={6} className="workListBox">
                      <Box className="haderRow" minWidth={"955px"}>
                        <Box
                          minWidth={"20%"}
                          className="Tabelsel TabelStatesel"
                        >
                          <Typography>{t("commonTxt.Actions")}</Typography>
                        </Box>
                        <Box minWidth={"15%"} className="Tabelsel">
                          <Typography>{t("commonTxt.Color")}</Typography>
                        </Box>
                        <Box
                          minWidth={"30%"}
                          className="Tabelsel TabelStatesel"
                        >
                          <Typography>{t("commonTxt.Tag")}</Typography>
                        </Box>

                        <Box minWidth={"13%"} className="Tabelsel">
                          <Typography>{t("commonTxt.Responses")}</Typography>
                        </Box>
                        <Box minWidth={"13%"} className="Tabelsel">
                          <Typography>{t("commonTxt.Percentage")}</Typography>
                        </Box>
                      </Box>

                      <Box className="TabelRow" minWidth={"955px"}>
                        <Box
                          minWidth={"20%"}
                          gap={"40px"}
                          className="Tabelsel navFsel navtable"
                        >
                          <img src={Option2LIcon} />
                          <input type="checkBox" className="custom-checkbox" />
                        </Box>
                        <Box
                          minWidth={"15%"}
                          className="Tabelsel Tabelcentersel"
                        >
                          <Box
                            className="colorBox"
                            sx={{ background: "#21A1F9" }}
                          ></Box>
                        </Box>
                        <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                          <img src={editIcon} />
                          <Typography>😍</Typography>
                          <Typography>{t("commonTxt.Like")}</Typography>
                        </Box>
                        <Box
                          minWidth={"13%"}
                          className="Tabelsel Tabelcentersel"
                        >
                          <Typography>18</Typography>
                        </Box>
                        <Box
                          minWidth={"13%"}
                          className="Tabelsel Tabelcentersel"
                        >
                          <Typography>58.2%</Typography>
                        </Box>
                      </Box>

                      <Box className="TabelRow" minWidth={"955px"}>
                        <Box
                          minWidth={"20%"}
                          gap={"40px"}
                          className="Tabelsel navFsel navtable"
                        >
                          <img src={Option2LIcon} />
                          <input type="checkBox" className="custom-checkbox" />
                        </Box>
                        <Box
                          minWidth={"15%"}
                          className="Tabelsel Tabelcentersel"
                        >
                          <Box
                            className="colorBox"
                            sx={{ background: "#FF4E72" }}
                          ></Box>
                        </Box>
                        <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                          <img src={editIcon} />
                          <Typography>😟</Typography>
                          <Typography>Dislike</Typography>
                        </Box>
                        <Box
                          minWidth={"13%"}
                          className="Tabelsel Tabelcentersel"
                        >
                          <Typography>05</Typography>
                        </Box>
                        <Box
                          minWidth={"13%"}
                          className="Tabelsel Tabelcentersel"
                        >
                          <Typography>18.5%</Typography>
                        </Box>
                      </Box>

                      <Box className="TabelRow" minWidth={"955px"}>
                        <Box
                          minWidth={"20%"}
                          gap={"40px"}
                          className="Tabelsel navFsel navtable"
                        >
                          <img src={Option2LIcon} />
                          <input type="checkBox" className="custom-checkbox" />
                        </Box>
                        <Box
                          minWidth={"15%"}
                          className="Tabelsel Tabelcentersel"
                        >
                          <Box
                            className="colorBox"
                            sx={{ background: "#FFBE28" }}
                          ></Box>
                        </Box>
                        <Box minWidth={"30%"} gap={"10px"} className="Tabelsel">
                          <img src={editIcon} />
                          <Typography>🤨</Typography>
                          <Typography>Confused</Typography>
                        </Box>
                        <Box
                          minWidth={"13%"}
                          className="Tabelsel Tabelcentersel"
                        >
                          <Typography>08</Typography>
                        </Box>
                        <Box
                          minWidth={"13%"}
                          className="Tabelsel Tabelcentersel"
                        >
                          <Typography>23.3%</Typography>
                        </Box>
                      </Box>
                    </Box>

                    {/* Sentiments Distribution */}
                    <Box className="tabelNav">
                      <Typography className="pageTitle">
                        {t("conversation.BubbleChartLabel")}
                      </Typography>
                      <Box
                        className="exporOpenBtn"
                        onClick={() => setExportDrop2(!exportDrop2)}
                      >
                        <img src={exportIcon} />
                        <Typography className="verifyLink">
                          {t("commonTxt.Export")}
                        </Typography>
                        <img
                          src={dropIcon}
                          style={{ rotate: exportDrop2 ? "180deg" : "0deg" }}
                        />
                        <Box
                          className="statusPop"
                          sx={{ height: exportDrop2 ? "auto" : "0px" }}
                        >
                          {exportItem?.map((el, i) => (
                            <Box className="statusPopItem">
                              <p>{el}</p>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                    <Box mb={5} className="bubbleChartBox">
                      <BubbleChart />
                    </Box>

                    {/* Quotes Analysis */}
                    <Box className="tabelNav">
                      <Typography className="pageTitle">
                        {t("conversation.lineChart1Label")}
                      </Typography>
                      <Box
                        className="exporOpenBtn"
                        onClick={() => setExportDrop3(!exportDrop3)}
                      >
                        <img src={exportIcon} />
                        <Typography className="verifyLink">
                          {t("commonTxt.Export")}
                        </Typography>
                        <img
                          src={dropIcon}
                          style={{ rotate: exportDrop3 ? "180deg" : "0deg" }}
                        />
                        <Box
                          className="statusPop"
                          sx={{ height: exportDrop3 ? "auto" : "0px" }}
                        >
                          {exportItem?.map((el, i) => (
                            <Box className="statusPopItem">
                              <p>{el}</p>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>

                    <Box mb={5} className="barChart">
                      <BarChart series={series} categories={categories} />
                    </Box>

                    <Box className="tabelNav">
                      <Typography className="pageTitle">
                        {t("conversation.lineChart2Label")}
                      </Typography>
                      <Box
                        className="exporOpenBtn"
                        onClick={() => setExportDrop4(!exportDrop4)}
                      >
                        <img src={exportIcon} />
                        <Typography className="verifyLink">
                          {t("commonTxt.Export")}
                        </Typography>
                        <img
                          src={dropIcon}
                          style={{ rotate: exportDrop4 ? "180deg" : "0deg" }}
                        />
                        <Box
                          className="statusPop"
                          sx={{ height: exportDrop4 ? "auto" : "0px" }}
                        >
                          {exportItem?.map((el, i) => (
                            <Box className="statusPopItem">
                              <p>{el}</p>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>

                    <Box className="barChart">
                      <BarChart series={series2} categories={categories2} />
                    </Box>

                    {/* bottom tab section */}
                    <Box className="bottomTabel">
                      <Box className="tabeSection">
                        <Box className="recpSectionNav">
                          <Box
                            onClick={() => setNav3(0)}
                            className={
                              nav3 === 0
                                ? "recpSectionNavItem recpSectionNavItemActive"
                                : "recpSectionNavItem"
                            }
                          >
                            <Typography>{t("commonTxt.Manual")}</Typography>
                          </Box>
                          <Box
                            onClick={() => setNav3(1)}
                            className={
                              nav3 === 1
                                ? "recpSectionNavItem recpSectionNavItemActive"
                                : "recpSectionNavItem"
                            }
                          >
                            <Typography>{t("commonTxt.ExcerptsAI")}</Typography>
                          </Box>
                        </Box>
                      </Box>

                      {/* Manual */}
                      <Box sx={{ display: nav3 === 0 ? "block" : "none" }}>
                        <Box className="tabelNav">
                          <Typography className="pageTitle">
                            {t("conversation.lineChart2Label")}
                          </Typography>
                          <Box width={"320px"}>
                            <SearchBar
                              placeholder={t(
                                "recapExpression.searchPlaceholder"
                              )}
                            />
                          </Box>
                        </Box>

                        <Box mb={6} className="workListBox">
                          <Box className="haderRow" minWidth={"955px"}>
                            <Box
                              minWidth={"10%"}
                              className="Tabelsel TabelStatesel"
                            >
                              <Typography>{t("commonTxt.Uses")}</Typography>
                            </Box>
                            <Box
                              minWidth={"17%"}
                              className="Tabelsel TabelStatesel"
                            >
                              <Typography>{t("commonTxt.Codes")}</Typography>
                            </Box>
                            <Box
                              minWidth={"40%"}
                              className="Tabelsel TabelStatesel"
                            >
                              <Typography>
                                {t("commonTxt.Description")}
                              </Typography>
                            </Box>
                          </Box>

                          <Box className="TabelRow" minWidth={"955px"}>
                            <Box minWidth={"10%"} className="Tabelsel ">
                              <Box className="UBox">6</Box>
                            </Box>
                            <Box
                              minWidth={"17%"}
                              gap={"10px"}
                              className="Tabelsel"
                            >
                              <Typography>
                                {t("conversation.Empowerment")}
                              </Typography>
                            </Box>
                            <Box
                              minWidth={"40%"}
                              className="Tabelsel Tabelcentersel"
                            >
                              <Typography>
                                {t("conversation.EmpowermentVal")}
                              </Typography>
                            </Box>
                          </Box>

                          <Box className="TabelRow" minWidth={"955px"}>
                            <Box minWidth={"10%"} className="Tabelsel ">
                              <Box className="UBox">4</Box>
                            </Box>
                            <Box
                              minWidth={"17%"}
                              gap={"10px"}
                              className="Tabelsel"
                            >
                              <Typography>
                                {t("conversation.Nostalgia")}
                              </Typography>
                            </Box>
                            <Box
                              minWidth={"40%"}
                              className="Tabelsel Tabelcentersel"
                            >
                              <Typography>
                                {t("conversation.NostalgiaVal")}
                              </Typography>
                            </Box>
                          </Box>

                          <Box className="TabelRow" minWidth={"955px"}>
                            <Box minWidth={"10%"} className="Tabelsel ">
                              <Box className="UBox">2</Box>
                            </Box>
                            <Box
                              minWidth={"17%"}
                              gap={"10px"}
                              className="Tabelsel"
                            >
                              <Typography>
                                {t("conversation.Relaxation")}
                              </Typography>
                            </Box>
                            <Box
                              minWidth={"40%"}
                              className="Tabelsel Tabelcentersel"
                            >
                              <Typography>
                                {t("conversation.RelaxationVal")}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      {/* Excerpts */}
                      <Box
                        sx={{ display: nav3 === 1 ? "block" : "none" }}
                      ></Box>

                      <Box className="tabelNav">
                        <Typography className="pageTitle">
                          {t("conversation.lineChart3Label")}
                        </Typography>
                        <Box
                          className="exporOpenBtn"
                          onClick={() => setExportDrop5(!exportDrop5)}
                        >
                          <img src={exportIcon} />
                          <Typography className="verifyLink">
                            {t("commonTxt.Export")}
                          </Typography>
                          <img
                            src={dropIcon}
                            style={{ rotate: exportDrop5 ? "180deg" : "0deg" }}
                          />
                          <Box
                            className="statusPop"
                            sx={{ height: exportDrop5 ? "auto" : "0px" }}
                          >
                            {exportItem?.map((el, i) => (
                              <Box className="statusPopItem">
                                <p>{el}</p>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </Box>

                      <Box className="pieBox StackedBar2">
                        <StackedBar2 />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
