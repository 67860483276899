import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";

import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { AntSwitch } from "../../AppTools/AppTool";

//images

export default function RejectResponsePop({ setViewResponsePageNavDropVal, viewResponsePageNavDropVal }) {
 
const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setViewResponsePageNavDropVal('Options');
    }
    };

    useEffect(() => {
        if (viewResponsePageNavDropVal === "Reject Response") {
          document.body.style.overflow = "hidden"; // Disable scrolling
        } else {
          document.body.style.overflow = "auto"; // Enable scrolling
        }
      }, [viewResponsePageNavDropVal]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: viewResponsePageNavDropVal === "Reject Response" ? "flex" : "none" }}
        className="companyPop AddCardPopPeople"
      >
        <Box
          sx={{ display: viewResponsePageNavDropVal === "Reject Response" ? "flex" : "none" }}
          className="workspaceContaint rejectResponsePopMain"
        >
            <Box className="basicBoxItem">
            <Typography className="companyHeader">Are you sure you want to reject this task response?</Typography>
            </Box>
            <Box className="basicBoxItem">
                <Typography>This response has 2 excerpts that might also include backroom comments. Rejecting this task response will cause all of those excerpts to be deleted. This action cannot be undone.</Typography>
                <Typography className="rejectResponseText">This task will re-appear to the participant as “Pending”.</Typography>
            </Box>
            <Box>
            <Typography className='eTemPopLabel addTemplatePopMessage'>Reason for Rejecting</Typography>
            <samp>(Optional comment, will only be visible to the participant)</samp>
            </Box>
            <Box className="basicBoxItem rejectResponseReason">
                <Typography>Hey Olivia,</Typography>
                <Typography>Kindly re-read the task instructions and answer. Seems like you have mis-interpreted what has been asked.</Typography>
            </Box>
            <Box className="airadioSwitchBox rejectResponseNotify">
              <AntSwitch
                defaultChecked
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography className="switchText">
                Notify participant by email immediately
              </Typography>
            </Box>
            <Box className="workPupBtnBox">
                <AppBtnOutLine buttonText="Cancel" onClick={() => setViewResponsePageNavDropVal('Options')} />
                <AppBtn buttonText="Reject Response" />
            </Box>



        </Box>
      </Box>
    </>
  );
}
