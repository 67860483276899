import React, { useState, useEffect } from "react";
import "./style.css";
import { styled } from "@mui/material/styles";
import { Box, Typography, fabClasses } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//images
import backArrow from "../../../Assets/Images/noun-back.png";
import arrow from "../../../Assets/Images/arrow.png";
import sendIcon from "../../../Assets/Images/sendIcon.png";
import groupIcon from "../../../Assets/Images/groupsIcon.png";
import tik1 from "../../../Assets/Images/checkG.png";
import redcircle from "../../../Assets/Images/alert-circle.png";
import cott from "../../../Assets/Images/cott.png";

//data
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import {
  Task,
  trashBlack,
  participants,
  GreenDot,
  RedDot,
  sideHaumIcon,
  viewProfileIcon,
  markAllReviewedIcon,
  sendMessageIcon,
  supportLoginIcon,
  actionLogIcon,
  exceprtsIcon,
  reportsIcon,
  transcriptIcon
} from "../../../Assets/Data";

//components
import {
  SearchBar,
  Status,
  AllDrop5,
} from "../../../Components/AppTools/AppTool";
import { AppAddBtn } from "../../../Components/AppTools/AppButton";
import SideMenu from "../../../Components/Collaborator/SideMenu";
import Slider from "../../../Components/AppTools/Carousel";
import ParticipantGroupPop from "../../../Components/Collaborator/ParticipantGroupPop";
import Thread from "../../../Components/Collaborator/ActivityTasksThread";
import Images from "../../../Components/Collaborator/ActivityTasksImages";
import Videos from "../../../Components/Collaborator/ActivityTasksVideos";
import Audio from "../../../Components/Collaborator/ActivityTasksAudio";
import AddTaskPop from "../../../Components/Collaborator/AddTaskPop";
import AddTextTaskPop from "../../../Components/Collaborator/AddTextTaskPop";
import AddImageTaskPop from "../../../Components/Collaborator/AddImageTaskPop";
import AddVideoTaskPop from "../../../Components/Collaborator/AddVideoTaskPop";
import AddAudioTaskPop from "../../../Components/Collaborator/AddAudioTaskPop";
import AddFillOutTaskPop from "../../../Components/Collaborator/AddFillOutTaskPop";
import AddMixedMediaTaskPop from "../../../Components/Collaborator/AddMixedMediaTaskPop";
import AddPollSurveyTaskPop from "../../../Components/Collaborator/AddPollSurveyTaskPop";
import AddOnDotTaskPop from "../../../Components/Collaborator/AddOnDotTaskPop";
import RearrangeTaskPop from "../../../Components/Collaborator/RearrangeTaskPop";
import GroupRearrangeTaskPop from "../../../Components/Collaborator/GroupRearrangeTaskPop";
import AddScreenCaptureTaskPop from "../../../Components/Collaborator/AddScreenCaptureTaskPop";
import AnyFileTaskPop from "../../../Components/Collaborator/AnyFileTaskPop";
import AddMatrixTaskPop from "../../../Components/Collaborator/AddMatrixTaskPop";
import ReviewAnImagePop from "../../../Components/Collaborator/ReviewAnImagePop";
import ReviewVideoPop from "../../../Components/Collaborator/ReviewVideoPop";
import ReviewAudioPop from "../../../Components/Collaborator/ReviewAudioPop";
import RenkItSortItPop from "../../../Components/Collaborator/RenkItSortItPop";

export default function ActivityTasks({
  SetEntiteActivityPage,
  workspaceDrop,
  setWorkSpaceDrop,
  slideMenu,
  setSlideMenu,
}) {
  
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const Navigate = useNavigate();
  const [taskNav, setTaskNav] = useState(0);
  const [responsesNav, setResponsesNav] = useState("Thread");
  const [statusDrop, setStatusDrop] = useState(false);
  const [statusDropVal, setStatusDropVal] = useState("All");

  const [statusDrop2, setStatusDrop2] = useState(false);
  const [statusDropVal2, setStatusDropVal2] = useState("All Tasks");

  const [optionIndex, setOptionIndex] = useState();
  const [workSpaceOption, setWorkspaceOption] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [newArrayData, setNewArrayData] = useState([]);

  const [participantGroupPop, setParticipantGroupPop] = useState(false);
  const [allDrop4, setAllDrop4] = useState();
  const [allDropVal4, setAllDropVal4] = useState("Activity Options");
  const [selectedTask, setSelectedTask] = useState();
  const [addTextTaskPop, setAddTextTaskPop] = useState(false);
  const [addImageTaskPop, setAddImageTaskPop] = useState(false);
  const [addVideoTaskPop, setAddVideoTaskPop] = useState(false);
  const [addAudioTaskPop, setAddAudioTaskPop] = useState(false);
  const [addFillOutTaskPop, setAddFillOutTaskPop] = useState(false);
  const [addMixedMediaTaskPop, setAddMixedMediaTaskPop] = useState(false);
  const [addPollSurveyTaskPop, setAddPollSurveyTaskPop] = useState(false);
  const [addOnDotTaskPop, setAddOnDotTaskPop] = useState(false);
  const [addScreenCaptureTaskPop, setAddScreenCaptureTaskPop] = useState(false);
  const [anyFileTaskPop, setAnyFileTaskPop] = useState(false);
  const [addMatrixTaskPop, setaddMatrixTaskPop] = useState(false);
  const [reviewAnImage, setReviewAnImage] = useState(false);
  const [reviewVideo, setReviewVideo] = useState(false);
  const [reviewAudio, setReviewAudio] = useState(false);
  const [renkItSortIt, setRenkItSortIt] = useState(false);

  const statusItemAry = [
    {
      name: "All",
      value: "All",
    },
    {
      name: "Completed",
      value: "Completed",
    },
    {
      name: "In Progress",
      value: "InProgress",
    },
    {
      name: "Pending",
      value: "Pending",
    }
  ];

  const handleOptionIndex = (i) => {
    setOptionIndex(i);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const lineBar = 80;
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#4F33D1",
    },
  }));

  console.log(selectedTask);
  const handelAddTaskPopCoutinue = () => {
    console.log("click", selectedTask);
    setAllDropVal4("Activity Options");
    if (selectedTask === "Text Task") {
      setAddTextTaskPop(true);
    } else if (selectedTask === "Image Upload Task") {
      setAddImageTaskPop(true);
    } else if (selectedTask === "Video Upload Task") {
      setAddVideoTaskPop(true);
    } else if (selectedTask === "Audio Upload Task") {
      setAddAudioTaskPop(true);
    } else if (selectedTask === "Fill It Out Task") {
      setAddFillOutTaskPop(true);
    } else if (selectedTask === "Mixed Media Upload Task") {
      setAddMixedMediaTaskPop(true);
    } else if (selectedTask === "Poll & Survey Task") {
      setAddPollSurveyTaskPop(true);
    } else if (selectedTask === "On-the-Dot Task") {
      setAddOnDotTaskPop(true);
    } else if (selectedTask === "Review an Image Task") {
      setReviewAnImage(true);
    } else if (selectedTask === "Review a Video Task") {
      setReviewVideo(true);
    } else if (selectedTask === "Review an Audio Task") {
      setReviewAudio(true);
    } else if (selectedTask === "Screen Capture Task") {
      setAddScreenCaptureTaskPop(true);
    } else if (selectedTask === "Any File Upload Task") {
      setAnyFileTaskPop(true);
    } else if (selectedTask === "Matrix Task") {
      setaddMatrixTaskPop(true);
    } else if (selectedTask === "Rank-it & Sort-it Task") {
      setRenkItSortIt(true);
    }
  };

  const handelAddTaskPopBack = () => {
    setAllDropVal4("Add Tasks");
    setAddTextTaskPop(false);
    setAddImageTaskPop(false);
    setAddVideoTaskPop(false);
    setAddAudioTaskPop(false);
    setAddFillOutTaskPop(false);
    setAddMixedMediaTaskPop(false);
    setAddPollSurveyTaskPop(false);
    setAddOnDotTaskPop(false);
    setAddScreenCaptureTaskPop(false);
    setAnyFileTaskPop(false);
    setaddMatrixTaskPop(false);
    setReviewAnImage(false);
    setReviewVideo(false);
    setReviewAudio(false);
    setRenkItSortIt(false);
  };

  useEffect(() => {
    printItemsByState(participants, currentPage);
  }, [currentPage]);

  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }
  
  

  return (
    <>
      <Box className="CollaboratorContainer ">
        {/* side menu */}
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />

        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>

            <Box className="AppNavBtnBox">
              <Box sx={{ width: "30%", minWidth: "196px" }}>
                <AllDrop5
                  drop={allDrop4}
                  setDrop={setAllDrop4}
                  dropVal={allDropVal4}
                  serDropVal={setAllDropVal4}
                />
              </Box>
              <AppAddBtn
                buttonText={t("commonTxt.previewActivityBtn")}
                onClick={() => Navigate("/collaborator/activities/preview")}
              />
            </Box>
          </Box>

          {/* Page container */}
          <Box className="collaboratorMainBox pageContainer">
            <Box className={statusDropVal=="InProgress" ? "ActivityTaslContainer inprogress":"ActivityTaslContainer"}>
              <ParticipantGroupPop
                participantGroupPop={participantGroupPop}
                setParticipantGroupPop={setParticipantGroupPop}
              />

              <AddTaskPop
                allDropVal4={allDropVal4}
                setAllDropVal4={setAllDropVal4}
                selectedTask={selectedTask}
                setSelectedTask={setSelectedTask}
                handelAddTaskPopCoutinue={handelAddTaskPopCoutinue}
              />

              <AddTextTaskPop
                setAllDropVal4={setAllDropVal4}
                addTextTaskPop={addTextTaskPop}
                setAddTextTaskPop={setAddTextTaskPop}
                handelAddTaskPopBack={handelAddTaskPopBack}
              />

              <AddImageTaskPop
                setAllDropVal4={setAllDropVal4}
                addImageTaskPop={addImageTaskPop}
                setAddImageTaskPop={setAddImageTaskPop}
                handelAddTaskPopBack={handelAddTaskPopBack}
              />

              <AddVideoTaskPop
                setAllDropVal4={setAllDropVal4}
                addVideoTaskPop={addVideoTaskPop}
                setAddVideoTaskPop={setAddVideoTaskPop}
                handelAddTaskPopBack={handelAddTaskPopBack}
              />

              <AddAudioTaskPop
                setAllDropVal4={setAllDropVal4}
                addAudioTaskPop={addAudioTaskPop}
                setAddAudioTaskPop={setAddAudioTaskPop}
                handelAddTaskPopBack={handelAddTaskPopBack}
              />

              <AddFillOutTaskPop
                setAllDropVal4={setAllDropVal4}
                addFillOutTaskPop={addFillOutTaskPop}
                setAddFillOutTaskPop={setAddFillOutTaskPop}
                handelAddTaskPopBack={handelAddTaskPopBack}
              />

              <AddMixedMediaTaskPop
                setAllDropVal4={setAllDropVal4}
                addMixedMediaTaskPop={addMixedMediaTaskPop}
                setAddMixedMediaTaskPop={setAddMixedMediaTaskPop}
                handelAddTaskPopBack={handelAddTaskPopBack}
              />

              <AddPollSurveyTaskPop
                setAllDropVal4={setAllDropVal4}
                addPollSurveyTaskPop={addPollSurveyTaskPop}
                setAddPollSurveyTaskPop={setAddPollSurveyTaskPop}
                handelAddTaskPopBack={handelAddTaskPopBack}
              />

              <AddOnDotTaskPop
                setAllDropVal4={setAllDropVal4}
                addOnDotTaskPop={addOnDotTaskPop}
                setAddOnDotTaskPop={setAddOnDotTaskPop}
                handelAddTaskPopBack={handelAddTaskPopBack}
              />
              <RearrangeTaskPop
                allDropVal4={allDropVal4}
                setAllDropVal4={setAllDropVal4}
              />
              <GroupRearrangeTaskPop
                allDropVal4={allDropVal4}
                setAllDropVal4={setAllDropVal4}
              />

              <AddScreenCaptureTaskPop
                setAllDropVal4={setAllDropVal4}
                addScreenCaptureTaskPop={addScreenCaptureTaskPop}
                setAddScreenCaptureTaskPop={setAddScreenCaptureTaskPop}
                handelAddTaskPopBack={handelAddTaskPopBack}
              />

              <AnyFileTaskPop
                setAllDropVal4={setAllDropVal4}
                anyFileTaskPop={anyFileTaskPop}
                setAnyFileTaskPop={setAnyFileTaskPop}
                handelAddTaskPopBack={handelAddTaskPopBack}
              />

              <AddMatrixTaskPop
                setAllDropVal4={setAllDropVal4}
                addMatrixTaskPop={addMatrixTaskPop}
                setaddMatrixTaskPop={setaddMatrixTaskPop}
                handelAddTaskPopBack={handelAddTaskPopBack}
              />

              <ReviewAnImagePop
                reviewAnImage={reviewAnImage}
                setReviewAnImage={setReviewAnImage}
                handelAddTaskPopBack={handelAddTaskPopBack}
              />
              <ReviewVideoPop
                reviewVideo={reviewVideo}
                setReviewVideo={setReviewVideo}
                handelAddTaskPopBack={handelAddTaskPopBack}
              />
              <ReviewAudioPop
                reviewAudio={reviewAudio}
                setReviewAudio={setReviewAudio}
                handelAddTaskPopBack={handelAddTaskPopBack}
              />
              <RenkItSortItPop
                renkItSortIt={renkItSortIt}
                setRenkItSortIt={setRenkItSortIt}
                handelAddTaskPopBack={handelAddTaskPopBack}
              />
              <Box
                width="74px"
                onClick={() => Navigate("/collaborator/activities")}
                className="openBtn"
              >
                <img src={backArrow} />
                <Typography className="neonBackText">{t("commonTxt.backBtn")}</Typography>
              </Box>

              <Box className="taskNavSBox">
                <Typography className="pageHeaderText">
                {t("activityTasksPage.activityTasksHead")}
                </Typography>
                <Box sx={{ width: "320px", height: "40px" }}>
                  <SearchBar placeholder={t("activityTasksPage.searchTaskPlaceholder")} />
                </Box>
              </Box>

              <Box className="sliderBox">
                <Slider
                  Task={Task}
                  SetEntiteActivityPage={SetEntiteActivityPage}
                />
              </Box>

              <Box className="workSpaceListBox">
                <Box className="lineBar">
                  <Typography>{lineBar}%</Typography>
                  <BorderLinearProgress variant="determinate" value={lineBar} />
                </Box>
                <Typography className="lineBarSubText">
                  8 {t("ActivitiesInnerPage.of")} 10 {t("ActivitiesInnerPage.responsesRecieved")}
                </Typography>

                <Box className="taskTable">
                  <Box className="taskTabelNav">
                    <Box
                      onClick={() => setTaskNav(0)}
                      className={
                        taskNav === 0
                          ? "taskTabelNavItem taskTabelNavItemActive"
                          : "taskTabelNavItem"
                      }
                    >
                      <p>{t("LiveInterview.Participant")}</p>
                    </Box>
                    <Box
                      onClick={() => setTaskNav(1)}
                      className={
                        taskNav === 1
                          ? "taskTabelNavItem taskTabelNavItemActive"
                          : "taskTabelNavItem"
                      }
                    >
                      <p>{t("commonTxt.Responses")}</p>
                    </Box>
                  </Box>

                  {/* -----------------------------Participant------------------------- */}
                  <Box
                    sx={{ display: taskNav === 0 ? "block" : "none" }}
                    className="ActiveTaskSectionBox"
                  >
                    <Box
                      mt={2}
                      className="workListNavItem workListNavItemMobile"
                    >
                      <Typography className="pageHeaderText">
                      {t("activityTasksPage.listofParticipantsHead")} (10)
                      </Typography>
                    </Box>
                    <Box mt={2} className="workListNav">
                      <Box className="workListNavItem workListNavItemDesktop">
                        <Typography className="pageHeaderText">
                        {t("activityTasksPage.listofParticipantsHead")} (10)
                        </Typography>
                      </Box>
                      <Box
                        sx={{ minWidth: "200px" }}
                        className="workListNavItem workListSearchBar"
                      >
                        <SearchBar />
                      </Box>
                      <Box
                        sx={{ minWidth: "135px" }}
                        className="workListNavItem workshotList"
                      >
                        <Status
                          setStatusDrop={setStatusDrop}
                          statusDrop={statusDrop}
                          setStatusDropVal={setStatusDropVal}
                          statusDropVal={statusDropVal}
                          label=" "
                          itemAry={statusItemAry}
                        />
                      </Box>

                      {statusDropVal=="InProgress" && <Box
                        sx={{ minWidth: "156px" }}
                        className="workListNavItem "
                      >
                        <Box className="actTNavBtn removeProjectBtn">
                          {viewProfileIcon}
                          <Typography>{t("activityTasksPage.removeProject")}</Typography>
                        </Box>
                      </Box>}

                      <Box
                        sx={{ minWidth: "156px" }}
                        className="workListNavItem"
                      >
                        <Box className="actTNavBtn">
                          <img src={sendIcon} />
                          <Typography>{t("activityTasksPage.messageAll")}</Typography>
                        </Box>
                      </Box>

                      <Box className="workListNavItem">
                        <Box
                          className="actTNavBtn"
                          onClick={() => setParticipantGroupPop(true)}
                        >
                          <img src={groupIcon} />
                          <Typography>{t("commonTxt.groupBtn")}</Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box className="workListBox">
                      <Box className="haderRow" minWidth={"1111px"}>
                        <Box
                          // minWidth={"290px"}
                          minWidth={"19%"}
                          className="Tabelsel TabelStatesel"
                        >
                          <Typography>{t("projectDirectory.tblName")}</Typography>
                        </Box>
                        <Box
                          //  minWidth={"130px"}
                          minWidth={"14%"}
                          className="Tabelsel"
                        >
                          <Typography>{t("recapeVideoReview.tblResponses")}</Typography>
                        </Box>
                        <Box minWidth={"14%"} className="Tabelsel">
                          <Typography>{t("projectDirectory.tblStatus")}</Typography>
                        </Box>

                        <Box minWidth={"10%"} className="Tabelsel">
                          <Typography>{t("activityTasksPage.tblProgress")}</Typography>
                        </Box>
                        <Box minWidth={"9%"} className="Tabelsel">
                          <Typography>{t("recapPollMultiPage.tblExcerpts")}</Typography>
                        </Box>
                        <Box minWidth={"14%"} className="Tabelsel statusSel">
                          <Typography>{t("projectReview.tblReviewed")}</Typography>
                        </Box>
                        <Box minWidth={"18%"} className="Tabelsel statusSel">
                          <Typography>{t("recapeVideoReview.tblLastEdit")}</Typography>
                        </Box>
                      </Box>
                      {newArrayData &&
                        newArrayData?.map((el, index) => (
                          <Box
                            key={index}
                            className="TabelRow"
                            minWidth={"1111px"}
                          >
                            <Box
                              minWidth={"19%"}
                              className="Tabelsel navFsel navtable"
                            >
                              <Box
                                id="dot"
                                onClick={() => {
                                  setWorkspaceOption(!workSpaceOption);
                                  setOptionIndex(index);
                                }}
                                className={
                                  workSpaceOption && index === optionIndex
                                    ? "dot dotActive"
                                    : "dot"
                                }
                              >
                                {
                                  <Box
                                    sx={{
                                      display:
                                        workSpaceOption && index === optionIndex
                                          ? "flex"
                                          : "none",
                                    }}
                                    className="optionPop"
                                  >
                                    <Box onClick={() => Navigate("/collaborator/view-participant-profile")} className="optionPopItem">
                                      {viewProfileIcon}
                                      <Typography>{t("recapPollMultiPage.viewProfile")}</Typography>
                                    </Box>
                                    <Box className="optionPopItem">
                                      {markAllReviewedIcon}
                                      <Typography>{t("recapPollMultiPage.markAllAsReviewed")}</Typography>
                                    </Box>
                                    <Box className="optionPopItem">
                                      {sendMessageIcon}
                                      <Typography>{t("recapPollMultiPage.sendMessage")}</Typography>
                                    </Box>
                                    <Box className="optionPopItem">
                                      {supportLoginIcon}
                                      <Typography>{t("recapPollMultiPage.supportLogin")}</Typography>
                                    </Box>
                                    <Box className="optionPopItem">
                                      {actionLogIcon}
                                      <Typography>{t("recapPollMultiPage.actionLog")}</Typography>
                                    </Box>
                                    <Box className="optionPopItem">
                                      {exceprtsIcon}
                                      <Typography>{t("recapPollMultiPage.excerpts")}</Typography>
                                    </Box>
                                    <Box className="optionPopItem">
                                      {reportsIcon}
                                      <Typography>{t("recapPollMultiPage.reports")}</Typography>
                                    </Box>
                                    <Box className="optionPopItem transcripticon">
                                      {transcriptIcon}
                                      <Typography>{t("recapPollMultiPage.transcripts")}</Typography>
                                    </Box>
                                    <Box className="optionPopItem">
                                      {trashBlack}
                                      <Typography>
                                      {t("recapPollMultiPage.deleteAllResponses")}
                                      </Typography>
                                    </Box>
                                  </Box>
                                }
                              </Box>
                              <Box
                                onClick={() => handleOptionIndex(index)}
                                className="tabelAvatar"
                              >
                                <img src={el.img} />
                              </Box>
                              <Typography
                                sx={{ cursor: "pointer", width: "95%" }}
                              >
                                {el.name}
                              </Typography>
                            </Box>
                            <Box
                              minWidth={"14%"}
                              className="Tabelsel Tabelcentersel viewLink"
                            >
                              <Typography
                                onClick={() =>
                                  Navigate("/collaborator/activities/task/view")
                                }
                              >
                                {el.responses}
                              </Typography>
                            </Box>

                            <Box
                              minWidth={"14%"}
                              className={
                                el.status === "Completed"
                                  ? "Tabelsel adminTabDrop Tabelcentersel statusBtnBoxSuccess"
                                  : "Tabelsel adminTabDrop statusBtnBoxError "
                              }
                            >
                              <Typography>{el.status}</Typography>
                            </Box>

                            <Box
                              minWidth={"10%"}
                              className="Tabelsel Tabelcentersel"
                            >
                              {el.status === "Completed" ? GreenDot : RedDot}
                              <Typography pl={1}>{el.progress}</Typography>
                            </Box>
                            <Box
                              minWidth={"9%"}
                              className="Tabelsel Tabelcentersel"
                            >
                              <Typography>{el.excerpts}</Typography>
                            </Box>
                            <Box
                              minWidth={"14%"}
                              className="Tabelsel statusSel Tabelcentersel"
                            >
                              {/* <Typography>{el.reviewed}</Typography> */}

                              {el.reviewed === "success" ? (
                                <img src={tik1} />
                              ) : el.reviewed ===
                                "empty" ? null : el.reviewed === "error" ? (
                                <img src={redcircle} />
                              ) : null}
                            </Box>
                            <Box
                              minWidth={"18%"}
                              className="Tabelsel statusSel Tabelcentersel"
                            >
                              <Typography>{el.lastEdited}</Typography>
                            </Box>
                          </Box>
                        ))}
                    </Box>

                    <Box className="pagenation">
                      <Box onClick={handlePrev} className="tabelBtn">
                        <img src={arrow} />
                        <Typography>{t("commonTxt.previouspage")}</Typography>
                      </Box>

                      <Box className="pageNumberBox">
                        <Box
                          onClick={() => setCurrentPage(0)}
                          className={
                            currentPage === 0
                              ? "pageNumber pageNumberActive"
                              : "pageNumber"
                          }
                        >
                          <p>1</p>
                        </Box>
                        <Box
                          onClick={() => setCurrentPage(1)}
                          className={
                            currentPage === 1
                              ? "pageNumber pageNumberActive"
                              : "pageNumber"
                          }
                        >
                          <p>2</p>
                        </Box>
                        <Box
                          onClick={() => setCurrentPage(2)}
                          className={
                            currentPage === 2
                              ? "pageNumber pageNumberActive"
                              : "pageNumber"
                          }
                        >
                          <p>3</p>
                        </Box>
                        <Box
                          onClick={() => setCurrentPage(3)}
                          className={
                            currentPage === 3
                              ? "pageNumber pageNumberActive"
                              : "pageNumber"
                          }
                        >
                          <p>4</p>
                        </Box>
                        <Box
                          onClick={() => setCurrentPage(4)}
                          className={
                            currentPage === 4
                              ? "pageNumber pageNumberActive"
                              : "pageNumber"
                          }
                        >
                          <p>5</p>
                        </Box>
                      </Box>

                      <Box onClick={handleNext} className="tabelBtn">
                        <Typography>{t("commonTxt.nextPage")}</Typography>
                        <img style={{ rotate: "180deg" }} src={arrow} />
                      </Box>
                    </Box>
                  </Box>

                  {/* -------===========--------Responses------======------------ */}

                  <Box
                    sx={{ display: taskNav === 1 ? "block" : "none" }}
                    className="ActiveTaskSectionBox"
                  >
                    <Box className="responsesHeaderBox">
                      <Typography className="mapText">{t("commonTxt.Responses")} (8)</Typography>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "24px",
                        }}
                      >
                        <Box className="actTNavBtn">
                          <img src={cott} />
                          <Typography>{t("excerptReport.excerptsHead")}</Typography>
                        </Box>

                        <Box
                          sx={{ minWidth: "130px" }}
                          className="workListNavItem workshotList"
                        >
                          <Status
                            setStatusDrop={setStatusDrop2}
                            statusDrop={statusDrop2}
                            setStatusDropVal={setStatusDropVal2}
                            statusDropVal={statusDropVal2}
                            label=" "
                          />
                        </Box>

                        <Box className="actTNavBtn">
                          <img src={groupIcon} />
                          <Typography>{t("commonTxt.groupBtn")}</Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box className="responsesNav">
                      <Box
                        className={
                          responsesNav === "Thread"
                            ? "responsesNavItem responsesNavItemActive"
                            : "responsesNavItem"
                        }
                        onClick={() => setResponsesNav("Thread")}
                      >
                        <Typography>{t("viewParticipantProfile.viewParticipantProfileNav1")}</Typography>
                      </Box>
                      <Box
                        className={
                          responsesNav === "Expressions"
                            ? "responsesNavItem responsesNavItemActive"
                            : "responsesNavItem"
                        }
                        onClick={() => setResponsesNav("Expressions")}
                      >
                        <Typography>{t("viewParticipantProfile.viewParticipantProfileNav2")}</Typography>
                      </Box>
                      <Box
                        className={
                          responsesNav === "Images"
                            ? "responsesNavItem responsesNavItemActive"
                            : "responsesNavItem"
                        }
                        onClick={() => setResponsesNav("Images")}
                      >
                        <Typography>{t("viewParticipantProfile.viewParticipantProfileNav3")}</Typography>
                      </Box>
                      <Box
                        className={
                          responsesNav === "Videos"
                            ? "responsesNavItem responsesNavItemActive"
                            : "responsesNavItem"
                        }
                        onClick={() => setResponsesNav("Videos")}
                      >
                        <Typography>{t("viewParticipantProfile.viewParticipantProfileNav4")}</Typography>
                      </Box>
                      <Box
                        className={
                          responsesNav === "Audios"
                            ? "responsesNavItem responsesNavItemActive"
                            : "responsesNavItem"
                        }
                        onClick={() => setResponsesNav("Audios")}
                      >
                        <Typography>{t("viewParticipantProfile.viewParticipantProfileNav5")}</Typography>
                      </Box>
                    </Box>
                    {/* -----------Thread------------- */}
                    <Box
                      display={responsesNav === "Thread" ? "block" : "none"}
                      className="responsesSection ThreadBox"
                    >
                      <Thread />
                    </Box>
                    {/* -----------Expressions------------- */}
                    <Box
                      display={
                        responsesNav === "Expressions" ? "block" : "none"
                      }
                      className="responsesSection "
                    ></Box>
                    {/* -----------Images------------- */}
                    <Box
                      display={responsesNav === "Images" ? "block" : "none"}
                      className="responsesSection "
                    >
                      <Images />
                    </Box>
                    {/* -----------Videos------------- */}
                    <Box
                      display={responsesNav === "Videos" ? "block" : "none"}
                      className="responsesSection "
                    >
                      <Videos />
                    </Box>
                    {/* -----------Audios------------- */}
                    <Box
                      display={responsesNav === "Audios" ? "block" : "none"}
                      className="responsesSection "
                    >
                      <Audio />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
