import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { GoTop } from "../../../Components/AppTools/AppTool";
import { AppBtn } from "../../../Components/AppTools/AppButton";

import dropArrow from "../../../Assets/Images/chevron-down.png";
import { useTranslation } from "react-i18next";

import {
  participantInvitation,
  addIconBlack,
  xCircleBlack,
  trashBlack,
  inviteGroupBlack,
  removeGroupBlack,
} from "../../../Assets/Data";

export default function ParticipantScreeningScreened() {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const [selectedParticipant, setSelectedParticipant] = useState();
  const [bulkActionDrop, setBulkActionDrop] = useState(false);
  const [bulkActionDropVal, setBulkActionDropVal] = useState(false);

  const [invitePop, setInvitePop] = useState(false);

  const handleCheckboxChange = (index) => {
    const updatedCheckboxes = [...selectedParticipant];
    updatedCheckboxes[index] = !updatedCheckboxes[index];
    setSelectedParticipant(updatedCheckboxes);
  };

  return (
    <>
      <Box className="allPNavBox managePNavBox">
        <Box className="allPNavInnerBox">
          <Box className="allPnavInput">
            <Box
              className="inputAppDrop"
              onClick={() => setBulkActionDrop(!bulkActionDrop)}
            >
              <Typography>
                {bulkActionDropVal ||
                  t("ParticipantScreeningScreened.bulkActionDropPlaceHolder")}
              </Typography>
              <img
                style={{
                  rotate: bulkActionDrop ? "180deg" : "0deg",
                }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: bulkActionDrop ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setBulkActionDropVal("Add-to-Project")}
                  className="statusPopItem statusPopItemIcon statusParticipantPopItems"
                >
                  {addIconBlack}
                  <Typography>
                    {t("ParticipantScreeningScreened.bulkActionDropOption1")}
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    setBulkActionDropVal("Invite-to-Project");
                    setInvitePop(true);
                    GoTop();
                  }}
                  className="statusPopItem statusParticipantPopItems"
                >
                  {inviteGroupBlack}
                  <Typography>
                    {t("ParticipantScreeningScreened.bulkActionDropOption2")}
                  </Typography>
                </Box>
                <Box
                  onClick={() => setBulkActionDropVal("Remove-to-Project")}
                  className="statusPopItem statusParticipantPopItems"
                >
                  {removeGroupBlack}
                  <Typography>
                    {t("ParticipantScreeningScreened.bulkActionDropOption3")}
                  </Typography>
                </Box>
                <Box
                  onClick={() => setBulkActionDropVal("Delete-Participants")}
                  className="statusPopItem statusParticipantPopItems"
                >
                  {trashBlack}
                  <Typography>
                    {t("ParticipantScreeningScreened.bulkActionDropOption4")}
                  </Typography>
                </Box>
                <Box
                  onClick={() =>
                    setBulkActionDropVal("Deactivate-Participants")
                  }
                  className="statusPopItem statusParticipantPopItems"
                >
                  {xCircleBlack}
                  <Typography>
                    {t("ParticipantScreeningScreened.bulkActionDropOption5")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <AppBtn buttonText={t("commonTxt.apply")} />
        </Box>
      </Box>

      <Box className="workListBox">
        <Box className="haderRow" minWidth={"1135px"}>
          <Box minWidth={"290px"} className="Tabelsel navFsel templatehead">
            <Typography>
              {t("participantScreening.tableColumnField1")}
            </Typography>
          </Box>
          <Box className="Tabelsel statusSel" minWidth={"325px"}>
            <Typography>
              {t("participantScreening.tableColumnField2")}
            </Typography>
          </Box>
          <Box className="Tabelsel statusSel" minWidth={"230px"}>
            <Typography>
              {t("participantScreening.tableColumnField3")}
            </Typography>
          </Box>
          <Box className="Tabelsel statusSel templateheadl" minWidth={"230px"}>
            <Typography>
              {t("participantScreening.tableColumnField4")}
            </Typography>
          </Box>
        </Box>
        {participantInvitation &&
          participantInvitation?.map((el, index) => (
            <Box key={index} className="TabelRow" minWidth={"1135px"}>
              <Box
                minWidth={"290px"}
                className="Tabelsel navFsel navtable participantCheckboxColor"
              >
                <input
                  className="AllPTablechackBox"
                  type="checkbox"
                  // checked={selectedParticipant.includes(index)}
                  onChange={() => handleCheckboxChange(index)}
                />
                <Typography>{el.name}</Typography>
              </Box>
              <Box
                className="Tabelsel statusSel Tabelcentersel invitationStatusIcon"
                minWidth={"325px"}
              >
                {el.icon}
                <Typography>
                  {t("ParticipantScreeningScreened.tableTag")}
                </Typography>
              </Box>
              <Box
                className="Tabelsel statusSel Tabelcentersel"
                minWidth={"230px"}
              >
                <Typography>{el.invited}</Typography>
              </Box>
              <Box
                className="Tabelsel statusSel Tabelcentersel"
                minWidth={"230px"}
              >
                <Typography>3</Typography>
              </Box>
            </Box>
          ))}
      </Box>
    </>
  );
}
