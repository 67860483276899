import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import { Box, Typography, Grid, TextField } from "@mui/material";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DropArrow from "../../../Assets/Images/chevron-down.png";
import backArrow from "../../../Assets/Images/noun-back.png";

import { AppBtnOutLine, AppBtn } from "../../AppTools/AppButton";

import backIcon from "../../../Assets/Images/icon-arrow.svg";

export default function AddStandardGroup() {
  const Navigate = useNavigate();

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const handleRouting = (Url) => {
    Navigate(Url);
  };

  const [projectvisibility, setprojectvisibility] = React.useState(0);

  const handleChange = (event) => {
    setprojectvisibility(event.target.value);
  };

  const [addprojectparticipants, setaddprojectparticipants] = React.useState(0);

  const handleChange2 = (event) => {
    setaddprojectparticipants(event.target.value);
  };

  const [removeprojectparticipants, setremoveprojectparticipants] =
    React.useState(0);

  const handleChange3 = (event) => {
    setremoveprojectparticipants(event.target.value);
  };

  const [colorSelectorDrop1, setColorSelectorDrop1] = useState(false);
  const [colorSelectorDrop1Val, setColorSelectorDrop1Val] =
    useState("Solid Color");

  return (
    <Box className="SettingsFormSection">
      <Box
        onClick={() => handleRouting("/workspace-administration/group")}
        className="openBtn"
      >
        <img src={backArrow} />
        <Typography className="neonBackText">
          {t("commonTxt.backBtn")}
        </Typography>
      </Box>

      <Box className="HeadingOptions">
        <Typography variant="h4">{t("standardGroup.add")}</Typography>
        <Box
          onClick={() => setColorSelectorDrop1(!colorSelectorDrop1)}
          width={"250px"}
          height={"40px"}
          px={2}
          className="statusBox"
        >
          <Typography>{t("groups.option")}</Typography>
          <img src={DropArrow} />
          {/* drop */}
          <Box
            sx={{ height: colorSelectorDrop1 ? "auto" : "0px" }}
            className="statusPop"
          >
            <Box
              onClick={() => setColorSelectorDrop1Val("Remove personal data")}
              className="statusPopItem"
            >
              <Typography>
                {<DoDisturbIcon />} {t("groups.remPersonalData")}
              </Typography>
            </Box>
            <Box
              onClick={() => setColorSelectorDrop1Val("Delete")}
              className="statusPopItem"
            >
              <Typography>
                {<DeleteOutlineIcon />} {t("commonTxt.delete")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Grid container spacing={2}>
        <Grid item sm={6}>
          <Typography className="greybox">
            {t("groups.grpId")}: <span>--</span>
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography className="greybox">
            {t("groups.updated")}: <span>--</span>
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography className="greybox">
            {t("groups.participants")}: <span>0</span>
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography className="greybox">
            {t("directoryAdmins.added")}: <span>Jun 5, 2023; 12:02 PM</span>
          </Typography>
        </Grid>
      </Grid>

      <Box className="FormField">
        <Typography variant="h5">{t("groups.basics")}</Typography>
        <TextField
          fullWidth
          id="outlined-basic"
          placeholder="Enter basics"
          variant="outlined"
          size="small"
          defaultValue={"Age 18 - 25"}
        />
      </Box>

      <Box className="FormField">
        <Typography variant="h5">{t("groups.advancedOptions")}</Typography>
        <Typography variant="h6">{t("groups.projectVisibility")}</Typography>
        <Typography>{t("groups.texts")}</Typography>
        <FormControl sx={{ width: "100%" }} size="small">
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={projectvisibility}
            onChange={handleChange}
          >
            <MenuItem value={0}>All Projects</MenuItem>
            <MenuItem value={10}>Projects 01</MenuItem>
            <MenuItem value={20}>Projects 02</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box className="FormField">
        <Typography variant="h6">{t("groups.label1")}</Typography>
        <Typography>{t("groups.label2")}</Typography>
        <FormControl sx={{ width: "100%" }} size="small">
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={addprojectparticipants}
            onChange={handleChange2}
          >
            <MenuItem value={0}>None</MenuItem>
            <MenuItem value={10}>Projects 01</MenuItem>
            <MenuItem value={20}>Projects 02</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box className="FormField">
        <Typography variant="h6">{t("groups.label3")}</Typography>
        <Typography>{t("groups.label4")}</Typography>
        <FormControl sx={{ width: "100%" }} size="small">
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={removeprojectparticipants}
            onChange={handleChange3}
          >
            <MenuItem value={0}>None</MenuItem>
            <MenuItem value={10}>Projects 01</MenuItem>
            <MenuItem value={20}>Projects 02</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box className="FormField">
        <Grid container spacing={2}>
          <Grid item sm={6}></Grid>
          <Grid item sm={6}>
            <Box className="flexEnd">
              <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} />
              <AppBtnOutLine buttonText={t("groups.saveAndAddAnother")} />
              <AppBtn buttonText={t("commonTxt.saveBtn")} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
