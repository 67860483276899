import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./style.css";

// images
import clogsvg from "../Assets/Images/New folder/Icon TeraPage Transparent mix dark 2.svg";
import iIcon from "../Assets/Images/IIcon.png";
import axios from "../lib/axios";

// components
import { AppBtn, AppBtnOutLine, PurplelBtn, RedBtn } from "../Components/AppTools/AppButton";
import CLogo from "../Assets/Images/Frame 243811.png";
import { Navigate, useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../env";
import { researcherAction } from "../Services/researcherActions";
import { useAppSelector } from "../lib/store/store";
import { RenderCustomField } from "../Components/AppTools/AppTool";
import { getUserDashboardUrl, getUserRoleType } from "../lib/helpers";

export default function ScreenQuestion() {
  const { t, i18n: { changeLanguage, language } } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isScreening = params.get('isScreening')
  const authState = useAppSelector((state) => state.auth);

  const [inviteStaus, setInviteStaus] = useState(isScreening === 'true' ? "accepted" : "rejected");
  const [loading, setLoading] = useState(false);
  const [screeningQuestion, setScreeningQuestion] = useState({});
  const [screeningAnswer, setScreeningAnswer] = useState({ custom_fields: [] });
  const userType = getUserRoleType(authState.userProfile);
  const deshboardURL = getUserDashboardUrl(userType.toLocaleLowerCase(), authState.userProfile)
  console.log("authState?.userType",authState?.userType)
  useEffect(() => {
    inviteStaus === "accepted" && getQuestion();
  }, [inviteStaus]);

  const inviteRspnse = async (status) => {
    try {
      const payload = {
        invitation_token: params.get('token'),
        status: status
      };
      setLoading(true);
      const response = await researcherAction("/super-admin/admin/participants/accept-reject-projects-invitation", payload, authState.authToken);
      setLoading(false);
      if (response.success) {
        if (response.data.hasScreening) {
          setInviteStaus(status);
          getQuestion();
        } else {
          toast.success(response.message, TOAST_CONFIG);
          navigate(deshboardURL);
        }
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong! Please try again', TOAST_CONFIG);
      setLoading(false);
    }
  };

  const getQuestion = async () => {
    const res = await axios.get(`/super-admin/admin/participants/invite-screening-question/${params.get('token')}`);
    if (res.status) {
      if (res.data.prevAns.length > 0) {
        setScreeningAnswer({ custom_fields: res.data.prevAns });
      }
      setScreeningQuestion(res.data);
    }
  }

  const submitAnswer = async (status = "submit") => {
    try {
      const payload = {
        invitation_token: params.get('token'),
        invitation_id: screeningQuestion.invite_id,
        screening_answer: screeningAnswer?.custom_fields,
        status
      };
      //return console.log(screeningAnswer)
      const response = await researcherAction("/super-admin/admin/participants/verify-screening-answer", payload, authState.authToken);
      setLoading(false);
      if (response.success) {
        toast.success(response.message, TOAST_CONFIG);
        navigate(deshboardURL);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong! Please try again', TOAST_CONFIG);
      setLoading(false);
    }
  };

  if (inviteStaus !== "accepted") {
    return (
      <Box className="signInBox">
        <Box className="signInInnerBox">
          <Box className="signColog">
            <img src={CLogo} alt="Logo" />
          </Box>
          <Box>
            <RedBtn buttonText="Reject" onClick={() => inviteRspnse("rejected")} />
            <PurplelBtn buttonText="Accept" onClick={() => inviteRspnse("accepted")} />
          </Box>
        </Box>
      </Box>
    );
  }

  if (inviteStaus === "accepted") {
    return (
      <Box className="ResearchersContainer">
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box className="terapageLogBox">
              <img style={{ width: "176.442px", height: "20px" }} src={clogsvg} alt="Logo" />
            </Box>
          </Box>
          <Box className="adminMainBox screenMainBox" sx={{ display: "flex" }}>
            <Box className="AiIContainer">
              <Typography mb={2} className="inviteAdminHead">Screening Questions</Typography>
              <Box className="iBox">
                <img src={iIcon} alt="Info Icon" />
                <Typography>Complete your response by filling the spaces below</Typography>
              </Box>
              {screeningQuestion?.customFields?.length > 0 &&
                screeningQuestion.customFields.map((field, index) => (
                  <RenderCustomField
                    profileField={field}
                    addResearcherParticipant={screeningAnswer}
                    setAddResearcherParticipant={setScreeningAnswer}
                    key={index}
                  />
                ))}
              <Box className="workPupBtnBox">
                <AppBtnOutLine buttonText="Cancel" />
                <AppBtnOutLine buttonText="Save for Later" onClick={() => submitAnswer("pending")} />
                <AppBtn buttonText="Submit" onClick={submitAnswer} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}
