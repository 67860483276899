import React, { useEffect, useState } from "react";
import "./style.css";
import { Box, FormControl, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//images
import iIcon from "../../../Assets/Images/IIcon.png";
import plassIcon from "../../../Assets/Images/plassIcon.png";
import AddAc1 from "../../../Assets/Images/New folder/AddAc1.png";
import AddAc2 from "../../../Assets/Images/New folder/AddAc2.png";
import AddAc3 from "../../../Assets/Images/New folder/AddAc3.png";
import AddAc4 from "../../../Assets/Images/New folder/AddAc4.png";

import AddAtv1 from "../../../Assets/Images/New folder/AddAtv1.png";
import AddAtv2 from "../../../Assets/Images/New folder/AddAtv2.png";
import AddAtv3 from "../../../Assets/Images/New folder/AddAtv3.png";
import AddAtv4 from "../../../Assets/Images/New folder/AddAtv4.png";

import groupsIcon from "../../../Assets/Images/groupsIcon.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";
import chackBox from "../../../Assets/Images/Checkbox.png";
import check from "../../../Assets/Images/check.png";
import searchIcon from "../../../Assets/Images/search.png";
import dropIcon from "../../../Assets/Images/chevron-downp.png";

//components
import { AppBtn, AppBtnOutLine, PurplelBtn } from "../../AppTools/AppButton";
import { BigDrop } from "../../AppTools/AppTool";
import ReferenceDateUsingValue from "../../AppTools/DatePicker";
import ReferenceDateExplicitTimePicker from "../../AppTools/TimePicker";
import { Status, AntSwitch } from "../../AppTools/AppTool";
import { useAppSelector } from "../../../lib/store/store";
import AddJournalActivityPop from "../AddJournalActivityPop";
import AddLiveGroupChatActivityPop from "../AddLiveGroupChatActivityPop";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../env";
import { uploadFile } from "../../../Services/adminActions";
import { getDatePickerVal, viewImage } from "../../../lib/helpers";
import { getCountries } from "../../../Services/master";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "../../../lib/axios";
import { researcherAction } from "../../../Services/researcherActions";
import dayjs from "dayjs";
export default function AddActivitiesPop({
  addActivitiesPop,
  setAddActivitiesPop,
  setActivityGuidancePop,
  activityData,
  setActivityData,
  initialState,
  editActivityID,
  setEditActivityID,
  popupAction,
  setPopupAction,
  setReloadList
}) {
  const Navigate = useNavigate();
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const authState = useAppSelector((state) => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace);
  const [activeItem, setActiveItem] = useState();
  const [activePage, setActivePage] = useState();
  const [standardTab, setStandardTab] = useState("Basic Settings");
  const [standardTab4, setStandardTab4] = useState("Basic Settings");
  const [countryDrop, setCountryDrop] = useState(false);
  const [countryDropVal, setCountryDropVal] = useState("Select Country");
  const [adminDrop, setAdminDrop] = useState(false);
  const [adminDropVal, setAdminDropVal] = useState("All Administrators");
  const [timeZoneDrop, setTimeZoneDrop] = useState(false);
  const [timeZoneDropVal, setTimeZoneDropVal] = useState("Select Time Zone");
  const [redio, setRedio] = useState(0);
  const [redio1, setRedio1] = useState(0);
  const [redio2, setRedio2] = useState(0);
  const [redio3, setRedio3] = useState();
  const [redio4, setRedio4] = useState(0);
  const [groupDrop2, setGroupDrop2] = useState(false);
  const [chackVal, setChackVal] = useState([]);
  const [chackHostVal, setChackHostVal] = useState([]);
  const [hostDrop, setHostDrop] = useState(false);
  const [image, setImage] = useState(null);
  const [addJournalActivityPop, setAddJournalActivityPop] = useState(false);
  const [addLiveGroupChatActivityPop, setAddLiveGroupChatActivityPop] =
    useState(false);
  const [meetingDay, setMeetingDay] = useState(false);
  const [meetingDayVal, setMeetingDayVal] = useState();

  const [switch1, setSwitch1] = useState();
  const [switch2, setSwitch2] = useState();
  const [switch3, setSwitch3] = useState();
  const [switch4, setSwitch4] = useState();
  const [switch5, setSwitch5] = useState();

  const [searchDrop, setSearchDrop] = useState(false);
  const [adminsMore, setAdminsMore] = useState(false);
  const [selectedAdminRoles, setSelectedAdminRoles] = useState([]);
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const [countries, setCountries] = useState([])
  const [timeZones, setTimeZone] = useState([])
  const [groups, setGroups] = useState([]);
  const [adminsList, setAdminsList] = useState([]);

  const meetingDayItem = [
    {
      name: "Weekdays",
      value: "Weekends",
    },
    {
      name: "Weekends",
      value: "Weekends",
    },
  ];

  const AdministratorItem = [
    "All Administrators",
    "Select Admin Roles",
    "Select Admins",
  ];

  const administratorRoles = [
    { name: "allresearcher", value: "All Researchers" },
    { name: "allcollaborators", value: "All Collaborators" },
    { name: "allobservers", value: "All Observers" }
  ];

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      uploadImage(file)
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      uploadImage(file)
    }
  };



  const ActivityCard = ({ icon, activeIcon, title, subText }) => {
    return (
      <Box
        onClick={() => { /* setActiveItem(title); */ setActivityData({ ...activityData, type: title }) }}
        className={
          activityData.type === title
            ? "ActivityTypeItem ActivityTypeItemClicked"
            : "ActivityTypeItem"
        }
      >
        <Box className="acTyTitleBox">
          <Typography>{title}</Typography>
          <img src={activityData.type === title ? activeIcon : icon} />
        </Box>
        <span>{subText}</span>
      </Box>
    );
  };

  const removeIds = (obj) => {
    const { _id, ...rest } = obj;
    return rest;
  };

  const getActivity = async () => {
    try {
      if (editActivityID !== "") {
        const response = await axios.get(`/researchers/activities?_id=${editActivityID}`);
        const activityData = response.data.data[0];

        // Destructure and remove specific fields
        const {
          _id,
          sequence_index,
          created_by,
          updated_by,
          deleted_by,
          deleted_at,
          created_at,
          updated_at,
          sequenced,
          __v,
          ...activity
        } = activityData;


        const cleanedActivity = {
          ...activity,
          basic_settings: removeIds(activity.basic_settings),
          notification_settings: removeIds(activity.notification_settings),
          points_customization: removeIds(activity.points_customization),
        };
        console.log("activity", cleanedActivity);
        setActivityData((prev) => ({ ...prev, ...cleanedActivity }));
        setImage(viewImage(activity.image));
        getAllCountryList({ _id: activity.basic_settings.country_id });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const pushData = (string) => {
    setChackVal((prev) => [...prev, string]);
  };
  const deleteAllCheckData = () => setChackVal([]);
  const selectAllCheckData = () => {
    setChackVal(["Camping", "Fishing", "Kayaking", "Cycling"]);
  };

  const pushHostData = (string) => {
    setChackHostVal((prev) => [...prev, string]);
  };
  const deleteAllCheckHostData = () => setChackHostVal([]);
  const selectAllCheckHostData = () => {
    setChackHostVal(["Host1", "Host2", "Host3", "Host4"]);
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setAddActivitiesPop(false);
    }
  };

  const handelGuidancePop = () => {
    setAddActivitiesPop(false);
    setActivityGuidancePop(true);
    setRedio3();
  };

  const handleSwitchChange = ({ event, state, setState }) => {
    setState(event.target.checked);
  };

  useEffect(() => {
    if (addActivitiesPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    getAllCountryList();
    getGroups();
    getResearcher();
    getCollaborator();
    getObserver();
    getActivity();
  }, [addActivitiesPop]);

  const AdminRolesCheckBox = (val, text) => {
    return (
      <Box className="checkBoxItem" key={`adminrole-${val}`}>
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            name={`adminrole-${val}`}
            value={val}
            checked={selectedAdminRoles.includes(val)}
            onChange={handleAdminRolesChange}
          />
          <Typography>{text}</Typography>
        </Box>
      </Box>
    );
  };
  const AdminsCheckBox = (val, text) => {
    return (
      <Box className="checkBoxItem" key={`admin-${val}`}>
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            name={`admin-${val}`}
            value={val}
            checked={selectedAdmins.includes(val)}
            onChange={handleAdminsChange}
          />
          <Typography>{text}</Typography>
        </Box>
      </Box>
    );
  };

  const handleAdminRolesChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedAdminRoles([...selectedAdminRoles, value]);
    } else {
      setSelectedAdminRoles(
        selectedAdminRoles.filter((admin) => admin !== value)
      );
    }
  };
  const handleAdminsChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedAdmins([...selectedAdmins, value]);
    } else {
      setSelectedAdmins(selectedAdmins.filter((admin) => admin !== value));
    }
  };



  const validationObj = {
    hasErr: false,
    'name': {
      error: false,
      msg: ''
    },
    'type': {
      error: false,
      msg: ''
    },
  }

  const [validationErrors, setValidationErrors] = useState({ ...validationObj });

  const handleValidation = (formInput) => {
    formInput = formInput || activityData;
    let validationerr = { ...validationObj };

    if (formInput.name == "") {
      validationerr.hasErr = true;
      validationerr.name = {
        error: true,
        msg: 'Name is required'
      }
    }

    if (formInput.type == "") {
      validationerr.hasErr = true;
      validationerr.type = {
        error: true,
        msg: 'Type is required'
      }
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  }

  const setFormData = (e) => {
    let newState = {
      ...activityData,
      [e.target.name]: e.target.value,
    };

    setActivityData(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
  };

  const uploadImage = async (file) => {
    if (!file) return false;
    const formData = new FormData();
    formData.append("file", file);
    try {
      let imageUploadRes = await uploadFile(formData, authState.authToken);
      if (imageUploadRes.success) {
        setActivityData({
          ...activityData,
          image: imageUploadRes?.data?.upload_path,
        });
        // toast.success(imageUploadRes.message, TOAST_CONFIG);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    } finally {
    }
  };

  const getAllCountryList = (filter = {}) => {
    getCountries(filter).then((res) => {
      if (res.success && res.data.data.length > 0) {
        const obj = res.data.data.map(el => ({ name: el.name, value: el.name, _id: el._id, timezones: el.timezones }))
        if (filter._id) {
          const timezoneObj = obj[0].timezones;
          setTimeZone(timezoneObj);
        }
        setCountries(obj);
      }
    });
  };

  const getGroups = async () => {
    const url = `/participant/groups?workspace_id=${workspaceState?.selectedWorkspace?._id}`;
    const res = await axios.get(url);
    if (res.status) {
      if (res.data.data.length > 0) {
        const groups = res.data.data;
        setGroups(groups);
      }
    }
  };

  const handle1stForm = () => {
    if (!handleValidation()) {
      toast.error('Please correct form fields', TOAST_CONFIG);
      return false;
    }
    setActivePage(activityData.type)
  }

  const getResearcher = async () => {
    try {
      const response = await axios.get(`/super-admin/admin/researchers?workspace=${workspaceState?.selectedWorkspace?._id}`)
      setAdminsList((prev) => {
        const prevData = prev || [];
        const newData = response?.data?.data || [];
        const existingIds = new Set(prevData.map(item => item._id));
        const mergedData = [
          ...prevData,
          ...newData.filter(item => !existingIds.has(item._id))
        ];
        return mergedData;
      });
    } catch (err) {
      console.log(err);
    }
  }

  const getCollaborator = async () => {
    try {
      const response = await axios.get(`/super-admin/admin/collaborators?workspace=${workspaceState?.selectedWorkspace?._id}`)
      setAdminsList((prev) => {
        const prevData = prev || [];
        const newData = response?.data?.data || [];
        const existingIds = new Set(prevData.map(item => item._id));
        const mergedData = [
          ...prevData,
          ...newData.filter(item => !existingIds.has(item._id))
        ];
        return mergedData;
      });

    } catch (err) {
      console.log(err);
    }
  }

  const getObserver = async () => {
    try {
      const response = await axios.get(`/super-admin/admin/observers?workspace=${workspaceState?.selectedWorkspace?._id}`)
      setAdminsList((prev) => {
        const prevData = prev || [];
        const newData = response?.data?.data || [];
        const existingIds = new Set(prevData.map(item => item._id));
        const mergedData = [
          ...prevData,
          ...newData.filter(item => !existingIds.has(item._id))
        ];
        return mergedData;
      });
    } catch (err) {
      console.log(err);
    }
  }


  const saveStandardActivity = async (isRedirect = false, url = "") => {
    try {
      // return console.log(activityData);
      const isEditAction = popupAction === "Edit";
      const reqUrl = isEditAction ? `/researchers/activities/${editActivityID}` : '/researchers/activities';
      const isPostMethod = isEditAction ? false : true;

      const response = await researcherAction(reqUrl, activityData, authState.authToken, isPostMethod);

      if (response.success) {
        resetAll();
        toast.success(response.message, TOAST_CONFIG);
        setReloadList(true);
        if (isRedirect)
          Navigate(`${url}/${response.data._id}`)
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    } finally {
    }
  }

  const resetAll = () => {
    setActivityData(initialState);
    setStandardTab("Basic Settings")
    setActivePage(null)
    setImage(null);
    setAddActivitiesPop(false)
    setEditActivityID("");
    setPopupAction("");
  }

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: addActivitiesPop ? "flex" : "none" }}
        className="companyPop addActivitiesPop"
      >
        <Box
          sx={{ display: addActivitiesPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          {/* --------------------------------------------------------------Standard-------------------------------------------------- */}
          <Box
            sx={{
              display: activePage === "Standard Activity" ? "block" : "none",
            }}
          >
            <Typography className="companyHeader">
              {t("standardActivityPop.standardActivityHead")}
            </Typography>
            <Box className="iBox StandardiBox">
              <img src={iIcon} />
              <Typography>
                {t("standardActivityPop.standardActivityNotify")}
              </Typography>
            </Box>

            <Typography className="eTemPopLabel">
              {t("addActivityPop.activityNameLabel")}
            </Typography>
            <Box className="input_Box">
              <input
                type="text"
                className="eTemInput"
                placeholder={t("addActivityPop.activityNamePlaceholder")}
                value={activityData.name ?? ""}
                name="name"
                onChange={setFormData}
              />
              <span className="validationerr">{validationErrors.name.msg}</span>
            </Box>

            <Box className="responsesNav">
              <Box
                className={
                  standardTab === "Basic Settings"
                    ? "responsesNavItem responsesNavItemActive"
                    : "responsesNavItem"
                }
                onClick={() => setStandardTab("Basic Settings")}
              >
                <Typography className="standardActivityTabs">
                  {t("standardActivityPop.basicSettingsTab")}
                </Typography>
              </Box>
              <Box
                className={
                  standardTab === "Notifications"
                    ? "responsesNavItem responsesNavItemActive"
                    : "responsesNavItem"
                }
                onClick={() => setStandardTab("Notifications")}
              >
                <Typography className="standardActivityTabs">
                  {t("standardActivityPop.notificationsTab")}
                </Typography>
              </Box>
              <Box
                className={
                  standardTab === "Points Customization"
                    ? "responsesNavItem responsesNavItemActive"
                    : "responsesNavItem"
                }
                onClick={() => setStandardTab("Points Customization")}
              >
                <Typography className="standardActivityTabs">
                  {t("standardActivityPop.PointsCustomizationTab")}
                </Typography>
              </Box>
            </Box>

            {/* =====Basic Settings======= */}
            <Box
              sx={{
                display: standardTab === "Basic Settings" ? "block" : "none",
              }}
            >
              <Box className="timeInputBox">
                <Box className="timeInputItemBox">
                  <Typography className="eTemPopLabel">
                    {t("workspaceSplash.startDate")}
                  </Typography>
                  <Box className="pickerBox">
                    <ReferenceDateUsingValue value={dayjs(activityData.basic_settings.start_date)}
                      onChange={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, start_date: getDatePickerVal(e, "date") } })} />
                  </Box>
                  <Box className="pickerBox">
                    <ReferenceDateExplicitTimePicker
                      placeholder={t("addConversationTopic.timePlaceholder")}
                      value={dayjs(activityData.basic_settings.start_time, "HH:mm:ss")}
                      onChange={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, start_time: getDatePickerVal(e, "time") } })}
                    />
                  </Box>
                </Box>

                <Box className="timeInputItemBox">
                  <Typography className="eTemPopLabel">
                    {t("standardActivityPop.endDateLabel")}
                  </Typography>
                  <Box className="pickerBox">
                    <ReferenceDateUsingValue value={dayjs(activityData.basic_settings.end_date)} onChange={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, end_date: getDatePickerVal(e, "date") } })} />
                  </Box>
                  <Box className="pickerBox">
                    <ReferenceDateExplicitTimePicker
                      placeholder={t("addConversationTopic.timePlaceholder")}
                      value={dayjs(activityData.basic_settings.end_time, "HH:mm:ss")}
                      onChange={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, end_time: getDatePickerVal(e, "time") } })}
                    />
                  </Box>
                </Box>

                <Box className="timeInputItemBox">
                  <Typography className="eTemPopLabel">
                    {t("standardActivityPop.archiveDateLabel")}
                  </Typography>
                  <Box className="pickerBox">
                    <ReferenceDateUsingValue value={dayjs(activityData.basic_settings.archive_date)} onChange={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, archive_date: getDatePickerVal(e, "date") } })} />
                  </Box>
                  <Box className="pickerBox">
                    <ReferenceDateExplicitTimePicker
                      placeholder={t("addConversationTopic.timePlaceholder")}
                      value={dayjs(activityData.basic_settings.archive_time, "HH:mm:ss")}
                      onChange={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, archive_time: getDatePickerVal(e, "time") } })}
                    />
                  </Box>
                </Box>
              </Box>

              <Box className="timeInputBox">
                <Box mb={5} className="timeInputItemBox">
                  <Typography className="eTemPopLabel">
                    {t("AccountSetting.Country")}
                  </Typography>
                  <Box height={"40px"}>
                    {/* <Status
                      setStatusDrop={setCountryDrop}
                      statusDrop={countryDrop}
                      setStatusDropVal={setCountryDropVal}
                      statusDropVal={countryDropVal}
                      label=" "
                      itemAry={countries}
                    /> */}
                    <FormControl sx={{ width: '100%' }}>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        className="statusBox MuiBox-root css-0"
                        value={activityData.basic_settings.country_id || "Select Country"}
                        onChange={(e) => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, country_id: e.target.value } })}
                      >
                        {countries.length > 0 &&
                          countries.map((el, i) => (
                            <MenuItem value={el._id} key={i} onClick={() => setTimeZone(el.timezones)}>
                              {el.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display:
                      activityData.basic_settings.country_id ? "block" : "none",
                  }}
                  mb={5}
                  className="timeInputItemBox"
                >
                  <Typography className="eTemPopLabel">
                    {t("AddProjectParticipants.tZone")}
                  </Typography>
                  <Box height={"40px"}>
                    {/* <Status
                      setStatusDrop={setTimeZoneDrop}
                      statusDrop={timeZoneDrop}
                      setStatusDropVal={setTimeZoneDropVal}
                      statusDropVal={timeZoneDropVal}
                      label=" "
                      itemAry={timeZones}
                    /> */}
                    <FormControl sx={{ width: '100%' }}>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        className="statusBox MuiBox-root css-0"
                        value={activityData.basic_settings.timezone || "Select Country"}
                        onChange={(e) => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, timezone: e.target.value } })}
                      >
                        {timeZones.length > 0 &&
                          timeZones.map((el, i) => (
                            <MenuItem value={el.zoneName} key={i} >
                              {el.zoneName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>

              <Typography className="eTemPopLabel">
                {t("standardActivityPop.extraInstructionLabel")}
              </Typography>
              <Box className="input_Box">
                <input
                  type="text"
                  className="eTemInput"
                  placeholder={t(
                    "standardActivityPop.extraInstructionPlaceholder"
                  )}
                  value={activityData.basic_settings.extra_instruction ?? ""}
                  onChange={(e) => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, extra_instruction: e.target.value } })}
                />
              </Box>

              <Typography className="eTemPopLabel">
                {t("standardActivityPop.activityVisibilityHead")}
              </Typography>
              <Box mt={3} className="redioItem" onClick={() => {
                setActivityData({
                  ...activityData, basic_settings:
                    { ...activityData.basic_settings, visibility: "all-participant" }
                })
              }}>
                <Box
                  className={
                    activityData.basic_settings.visibility === "all-participant"
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>
                  {t("projectParticipantReport.participantAllParticipant")}
                </Typography>
              </Box>

              <Box mt={3} className="redioItem" onClick={() => {
                setActivityData({
                  ...activityData, basic_settings:
                    { ...activityData.basic_settings, visibility: "selected-groups" }
                })
              }}>
                <Box
                  className={
                    activityData.basic_settings.visibility === "selected-groups"
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>
                  {t(
                    "addConversationTopic.topicVisibilityParticipantsCheckbox2"
                  )}
                </Typography>
              </Box>

              <Box
                mt={2}
                ml={5}
                sx={{ display: activityData.basic_settings.visibility === "selected-groups" ? "block" : "none" }}
              >
                <Typography className="psNlabel">
                  {t("projectParticipantReport.participantSelectGroup")}
                </Typography>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    className="statusBox MuiBox-root css-0"
                    value={activityData.basic_settings.selected_groups || "Select Country"}
                    onChange={(e) => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, selected_groups: e.target.value } })}
                    multiple
                  >
                    {groups.length > 0 &&
                      groups.map((el, i) => (
                        <MenuItem value={el._id} key={i} >
                          {el.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

              </Box>

              <Box className="gapBox"></Box>

              <Typography className="eTemPopLabel">
                {t("standardActivityPop.responseVisibilityHead")}
              </Typography>
              <Box mt={3} className="redioItem" onClick={() => { setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, response_visibility: "public" } }) }}>
                <Box
                  className={
                    activityData.basic_settings.response_visibility === "public"
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>
                  {t("standardActivityPop.responseVisibilityRadio1")}
                </Typography>
              </Box>
              <Box mt={3} className="redioItem" onClick={() => { setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, response_visibility: "uninfluenced" } }) }}>
                <Box
                  className={
                    activityData.basic_settings.response_visibility === "uninfluenced"
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>
                  {t("standardActivityPop.responseVisibilityRadio2")}
                </Typography>
              </Box>
              <Box mt={3} className="redioItem" onClick={() => { setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, response_visibility: "private" } }) }}>
                <Box
                  className={
                    activityData.basic_settings.response_visibility === "private"
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>
                  {t("standardActivityPop.responseVisibilityRadio3")}
                </Typography>
              </Box>
              <Box className="gapBox"></Box>

              <Typography className="eTemPopLabel">
                {t("standardActivityPop.randomizeTasksHead")}
              </Typography>
              <Box mt={3} className="redioItem"
                onClick={() => { setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, randomize_task: false } }) }}
              >
                <Box
                  className={
                    !activityData.basic_settings.randomize_task
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>
                  {t("standardActivityPop.randomizeTasksRadio1")}
                </Typography>
              </Box>
              <Box mt={3} className="redioItem" onClick={() => { setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, randomize_task: true } }) }}>
                <Box
                  className={
                    activityData.basic_settings.randomize_task
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>
                  {t("standardActivityPop.randomizeTasksRadio2")}
                </Typography>
              </Box>
              <Box className="gapBox"></Box>
              <Typography className="eTemPopLabel">
                {t("standardActivityPop.addActivityGuidanceHead")}
              </Typography>
              <Box
                mt={3}
                className="redioItem"
                onClick={() => {
                  handelGuidancePop();
                  setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, activity_guidance: true } });
                }}
              >
                <Box
                  className={
                    activityData.basic_settings.activity_guidance
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>{t("commonTxt.Yes")}</Typography>
              </Box>
              <Box mt={3} className="redioItem" onClick={() => { setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, activity_guidance: false } }); }}>
                <Box
                  className={
                    !activityData.basic_settings.activity_guidance
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>{t("commonTxt.No")}</Typography>
              </Box>
              <Box className="gapBox"></Box>
            </Box>
            {/* =====Notifications======= */}
            <Box
              sx={{
                display: standardTab === "Notifications" ? "block" : "none",
              }}
            >
              <Box className="airadioSwitchBox">
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                  checked={activityData.notification_settings.new_activity_enable}
                  onClick={(e) => { setActivityData({ ...activityData, notification_settings: { ...activityData.notification_settings, new_activity_enable: e.target.checked } }); }} />
                <Typography className="switchText">
                  {t("standardActivityPop.notificationsSwitch1")}
                </Typography>
              </Box>
              <Box className="airadioSwitchBox">
                <AntSwitch
                  checked={activityData.notification_settings.activity_reminder}
                  inputProps={{ "aria-label": "ant design" }}
                  onClick={(e) => { setActivityData({ ...activityData, notification_settings: { ...activityData.notification_settings, activity_reminder: e.target.checked } }); }}
                />
                <Typography className="switchText">
                  {t("standardActivityPop.notificationsSwitch2")}
                </Typography>
              </Box>
              <Box className="airadioSwitchBox">
                <AntSwitch
                  checked={activityData.notification_settings.activity_overdue}
                  inputProps={{ "aria-label": "ant design" }}
                  onClick={(e) => { setActivityData({ ...activityData, notification_settings: { ...activityData.notification_settings, activity_overdue: e.target.checked } }); }}
                />
                <Typography className="switchText">
                  {t("standardActivityPop.notificationsSwitch3")}
                </Typography>
              </Box>
            </Box>
            {/* =====Points Customizations======= */}
            <Box
              sx={{
                display:
                  standardTab === "Points Customization" ? "block" : "none",
              }}
            >
              <Box className="airadioSwitchBox">
                <AntSwitch
                  checked={activityData.points_customization.customize_points}
                  inputProps={{ "aria-label": "ant design" }}
                  onClick={(e) => { setActivityData({ ...activityData, points_customization: { ...activityData.points_customization, customize_points: e.target.checked } }); }}
                />
                <Typography className="switchText pcTopicSText">
                  {t("standardActivityPop.pointCustomizationSwitch")}
                </Typography>
              </Box>

              <Box mb={3} className="pcTopicListRow">
                <Typography>
                  {t("standardActivityPop.pointCustomization1")}
                </Typography>
                <span>:</span>
                <input value={activityData.points_customization.standard_completion ?? 0} onChange={(e) => { setActivityData({ ...activityData, points_customization: { ...activityData.points_customization, standard_completion: e.target.value } }); }} />
                <samp>{t("commonTxt.pts.")}</samp>
              </Box>

              <Box mb={3} className="pcTopicListRow">
                <Typography>
                  {t("standardActivityPop.pointCustomization2")}{" "}
                </Typography>
                <span>:</span>
                <input value={activityData.points_customization.comment_own ?? 0}
                  onChange={(e) => { setActivityData({ ...activityData, points_customization: { ...activityData.points_customization, comment_own: e.target.value } }); }} />
                <samp>{t("commonTxt.pts.")}</samp>
              </Box>

              <Box mb={3} className="pcTopicListRow">
                <Typography>
                  {t("standardActivityPop.pointCustomization3")}
                </Typography>
                <span>:</span>
                <input value={activityData.points_customization.comment_response ?? 0}
                  onChange={(e) => { setActivityData({ ...activityData, points_customization: { ...activityData.points_customization, comment_response: e.target.value } }); }} />
                <samp>{t("commonTxt.pts.")}</samp>
              </Box>

              <Box mb={3} className="pcTopicListRow">
                <Typography>
                  {t("standardActivityPop.pointCustomization4")}
                </Typography>
                <span>:</span>
                <input value={activityData.points_customization.like_response ?? 0}
                  onChange={(e) => { setActivityData({ ...activityData, points_customization: { ...activityData.points_customization, like_response: e.target.value } }); }} />
                <samp>{t("commonTxt.pts.")}</samp>
              </Box>

              <Box mb={3} className="pcTopicListRow">
                <Typography>
                  {t("standardActivityPop.pointCustomization5")}
                </Typography>
                <span>:</span>
                <input value={activityData.points_customization.like_rating_received ?? 0}
                  onChange={(e) => { setActivityData({ ...activityData, points_customization: { ...activityData.points_customization, like_rating_received: e.target.value } }); }}
                />
                <samp>{t("commonTxt.pts.")}</samp>
              </Box>
            </Box>

            <Box className="workPupBtnBox">
              <AppBtnOutLine
                buttonText={t("commonTxt.cancelBtn")}
                onClick={() => { setActivePage(null); }}
              />
              <AppBtnOutLine
                buttonText={t("commonTxt.backBtn")}
                onClick={() => { setActivePage(null); }}
              />
              <AppBtn buttonText={t("commonTxt.continueBtn")} onClick={saveStandardActivity} />
            </Box>
          </Box>

          {activePage === "Journal Activity" ? (
            <AddJournalActivityPop
              addJournalActivityPop={addJournalActivityPop}
              setAddJournalActivityPop={setAddJournalActivityPop}
              setAddActivitiesPop={setAddActivitiesPop}
              activePage={activePage}
              setActivePage={setActivePage}
              activityData={activityData}
              setActivityData={setActivityData}
              initialState={initialState}
              countries={countries}
              groups={groups}
              saveStandardActivity={saveStandardActivity}
              timeZones={timeZones}
              setTimeZone={setTimeZone}
            />
          ) : null}
          {/* -------------------------------------------------Journal-------------------------------------------------------- */}
          {/* <Box sx={{ display: activePage === "Journal Activity" ? "block" : "none" }}>
            <Box className="workPupBtnBox">
              <AppBtnOutLine buttonText="Cancel" />
              <AppBtnOutLine
                buttonText="Back"
                onClick={() => setActivePage(null)}
              />
              <AppBtn buttonText="Continue" />
            </Box>
          </Box> */}
          {activePage === "Live Group Chat" ? (
            <AddLiveGroupChatActivityPop
              addLiveGroupChatActivityPop={addLiveGroupChatActivityPop}
              setAddLiveGroupChatActivityPop={setAddLiveGroupChatActivityPop}
              activePage={activePage}
              setActivePage={setActivePage}
              activityData={activityData}
              setActivityData={setActivityData}
              initialState={initialState}
              countries={countries}
              groups={groups}
              saveStandardActivity={saveStandardActivity}
              timeZones={timeZones}
              setTimeZone={setTimeZone}
            />
          ) : null}
          {/* --------------------------------------------------Live Group Chat--------------------------------------------------- */}
          {/* <Box
            sx={{
              display: activePage === "Live Group Chat" ? "block" : "none",
            }}
          >
            <Box className="workPupBtnBox">
              <AppBtnOutLine buttonText="Cancel" onClick={() => setActivePage(null)} />
              <AppBtnOutLine
                buttonText="Back"
                onClick={() => setActivePage(null)}
              />
              <AppBtn buttonText="Continue" />
            </Box>
          </Box> */}

          {/* -----------------------------------------------------Live Interview----------------------------------------------------- */}
          <Box
            sx={{
              display:
                activePage === "Live Interview Activity" ? "block" : "none",
            }}
          >
            <Typography className="companyHeader">
              {t("liveInterviewActivityPop.liveInterviewHead")}
            </Typography>
            <Box className="iBox">
              <img src={iIcon} />
              <Typography>
                {t("liveInterviewActivityPop.liveInterviewNotify")}
              </Typography>
            </Box>

            <Typography className="eTemPopLabel">
              {t("addActivityPop.activityNameLabel")}
            </Typography>
            <Box className="input_Box">
              <input
                type="text"
                className="eTemInput"
                placeholder={t("addActivityPop.activityNamePlaceholder")}
                value={activityData.name ?? ""}
                name="name"
                onChange={setFormData}
              />
              <span className="validationerr">{validationErrors.name.msg}</span>
            </Box>

            <Box className="responsesNav">
              <Box
                className={
                  standardTab4 === "Basic Settings"
                    ? "responsesNavItem responsesNavItemActive"
                    : "responsesNavItem"
                }
                onClick={() => setStandardTab4("Basic Settings")}
              >
                <Typography className="standardActivityTabs">
                  {t("standardActivityPop.basicSettingsTab")}
                </Typography>
              </Box>
              <Box
                className={
                  standardTab4 === "Notifications"
                    ? "responsesNavItem responsesNavItemActive"
                    : "responsesNavItem"
                }
                onClick={() => setStandardTab4("Notifications")}
              >
                <Typography className="standardActivityTabs">
                  {t("standardActivityPop.notificationsTab")}
                </Typography>
              </Box>
              <Box
                className={
                  standardTab4 === "Points Customization"
                    ? "responsesNavItem responsesNavItemActive"
                    : "responsesNavItem"
                }
                onClick={() => setStandardTab4("Points Customization")}
              >
                <Typography className="standardActivityTabs">
                  {t("standardActivityPop.PointsCustomizationTab")}
                </Typography>
              </Box>
            </Box>

            {/* =====Basic Settings======= */}
            <Box
              sx={{
                display: standardTab4 === "Basic Settings" ? "block" : "none",
              }}
            >
              <Box className="timeInputBox">
                <Box className="timeInputItemBox">
                  <Typography className="eTemPopLabel">
                    {t("liveInterviewActivityPop.sendInvitationLabel")}
                  </Typography>
                  <Box className="pickerBox">
                    <ReferenceDateUsingValue value={dayjs(activityData.basic_settings.send_invitation)} onChange={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, send_invitation: getDatePickerVal(e, "date") } })} />
                  </Box>
                  <Box className="pickerBox">
                    <ReferenceDateExplicitTimePicker
                      placeholder={t("addConversationTopic.timePlaceholder")}
                      value={dayjs(activityData.basic_settings.invitation_time, "HH:mm:ss")}
                      onChange={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, invitation_time: getDatePickerVal(e, "time") } })}
                    />
                  </Box>
                </Box>

                <Box className="timeInputItemBox">
                  <Typography className="eTemPopLabel">
                    {t("liveInterviewActivityPop.interviewStartDateLabel")}
                  </Typography>
                  <Box className="pickerBox">
                    <ReferenceDateUsingValue value={dayjs(activityData.basic_settings.interview_start_date)} onChange={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, interview_start_date: getDatePickerVal(e, "date") } })} />
                  </Box>
                </Box>

                <Box className="timeInputItemBox">
                  <Typography className="eTemPopLabel">
                    {t("liveInterviewActivityPop.interviewEndDateLabel")}
                  </Typography>
                  <Box className="pickerBox">
                    <ReferenceDateUsingValue value={dayjs(activityData.basic_settings.interview_end_date)} onChange={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, interview_end_date: getDatePickerVal(e, "date") } })} />
                  </Box>
                </Box>
              </Box>

              <Box className="timeInputBox">
                <Box mb={5} className="timeInputItemBox">
                  <Typography className="eTemPopLabel">
                    {t("AccountSetting.Country")}
                  </Typography>
                  <Box height={"40px"}>
                    {/* <Status
                      setStatusDrop={setCountryDrop}
                      statusDrop={countryDrop}
                      setStatusDropVal={setCountryDropVal}
                      statusDropVal={countryDropVal}
                      label=" "
                      itemAry={countries}
                    /> */}
                    <FormControl sx={{ width: '100%' }}>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        className="statusBox MuiBox-root css-0"
                        value={activityData.basic_settings.country_id || "Select Country"}
                        onChange={(e) => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, country_id: e.target.value } })}
                      >
                        {countries.length > 0 &&
                          countries.map((el, i) => (
                            <MenuItem value={el._id} key={i} onClick={() => setTimeZone(el.timezones)}>
                              {el.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>

                <Box mb={5} className="timeInputItemBox">
                  <Typography className="eTemPopLabel">
                    {t("AddProjectParticipants.tZone")}
                  </Typography>
                  <Box height={"40px"}>
                    {/*  <Status
                      setStatusDrop={setTimeZoneDrop}
                      statusDrop={timeZoneDrop}
                      setStatusDropVal={setTimeZoneDropVal}
                      statusDropVal={timeZoneDropVal}
                      label=" "
                      itemAry={timeZones2}
                    /> */}
                    <FormControl sx={{ width: '100%' }}>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        className="statusBox MuiBox-root css-0"
                        value={activityData.basic_settings.timezone || "Select Country"}
                        onChange={(e) => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, timezone: e.target.value } })}
                      >
                        {timeZones.length > 0 &&
                          timeZones.map((el, i) => (
                            <MenuItem value={el.zoneName} key={i} >
                              {el.zoneName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>

              <Typography className="eTemPopLabel">
                {t("liveInterviewActivityPop.hostSchedulingHead")}
              </Typography>
              <Box mt={3} className="redioItem" onClick={() => {
                setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, host_scheduling: "participants-can-schedule" } })
              }}>
                <Box
                  className={
                    activityData.basic_settings.host_scheduling === "participants-can-schedule"
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>
                  {t("liveInterviewActivityPop.hostSchedulingCheckbox1")}
                </Typography>
              </Box>

              <Box
                mt={3}
                sx={{ width: "32%", display: activityData.basic_settings.host_scheduling === "participants-can-schedule" ? "block" : "none" }}
                ml={5}
              >
                <Typography className="eTemPopLabel">
                  {t("liveInterviewActivityPop.openActivityLabel")}
                </Typography>
                <Box className="pickerBox">
                  <ReferenceDateUsingValue value={dayjs(activityData.basic_settings.participants_schedule_settings.activity_start_time)} onChange={e => setActivityData({
                    ...activityData, basic_settings: {
                      ...activityData.basic_settings, participants_schedule_settings: {
                        ...activityData.basic_settings.participants_schedule_settings,
                        activity_start_time: getDatePickerVal(e, "date")
                      }
                    }
                  })} />
                </Box>

                <Typography className="eTemPopLabel">
                  {t("liveInterviewActivityPop.notificationsLabel")}
                </Typography>

                <Box
                  mb={2}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <input
                    style={{ marginRight: "10px" }}
                    className="custom-checkbox"
                    type="checkBox"
                    checked={activityData.basic_settings.participants_schedule_settings.invite_notify}
                    onClick={e => setActivityData({
                      ...activityData, basic_settings: {
                        ...activityData.basic_settings, participants_schedule_settings: {
                          ...activityData.basic_settings.participants_schedule_settings,
                          invite_notify: e.target.checked
                        }
                      }
                    })}
                  />
                  <Typography>
                    {t("liveInterviewActivityPop.notificationsCheckbox1")}
                  </Typography>
                </Box>
                <Box
                  mb={2}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <input
                    style={{ marginRight: "10px" }}
                    className="custom-checkbox"
                    type="checkBox"
                    checked={activityData.basic_settings.participants_schedule_settings.reminder_notify}
                    onClick={e => setActivityData({
                      ...activityData, basic_settings: {
                        ...activityData.basic_settings, participants_schedule_settings: {
                          ...activityData.basic_settings.participants_schedule_settings,
                          reminder_notify: e.target.checked
                        }
                      }
                    })}
                  />
                  <Typography>
                    {t("liveInterviewActivityPop.notificationsCheckbox2")}
                  </Typography>
                </Box>
              </Box>

              <Box mt={3} className="redioItem" onClick={() => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, host_scheduling: "admins-schedule" } })}>
                <Box
                  className={
                    activityData.basic_settings.host_scheduling === "admins-schedule"
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>
                  {t("liveInterviewActivityPop.hostSchedulingCheckbox2")}
                </Typography>
              </Box>

              <Box
                mt={2}
                sx={{ display: activityData.basic_settings.host_scheduling === "admins-schedule" ? "block" : "none" }}
                className="iBox"
              >
                <img src={iIcon} />
                <Typography>
                  {t("liveInterviewActivityPop.hostSchedulingNotify")}
                </Typography>
              </Box>

              <Box mt={5} className="timeInputBox">
                <Box className="timeInputItemBox">
                  <Typography className="eTemPopLabel">
                    {t("liveInterviewActivityPop.meetingHostsLabel")}
                  </Typography>
                  <FormControl sx={{ width: '100%' }}>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      className="statusBox MuiBox-root css-0"
                      value={activityData.basic_settings.meeting_host || "Select Administrator"}
                      onChange={(e) => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, meeting_host: e.target.value } })}
                      multiple
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <Box
                              pl={4.5}
                              className="inputAppDrop"
                            >
                              <Typography>
                                Select admins
                              </Typography>
                            </Box>
                          );
                        }
                        return selected
                          .map((value) => {
                            const user = adminsList.find(
                              (usr) => usr._id === value
                            );
                            return user ? user.name : value;
                          })
                          .join(", ");
                      }}
                    >
                      {adminsList.length > 0 &&
                        adminsList.map((el, i) => (
                          <MenuItem value={el._id} key={i} >
                            {el.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {/* <Box pl={4.5} className="inputAppDrop">
                    <Box onClick={() => setHostDrop(!hostDrop)}>
                      <Typography>{t("LiveInterview.SelectMHost")}</Typography>
                    </Box>
                    <img
                      className="PartDropIcon"
                      style={{ rotate: hostDrop ? "180deg" : "0deg" }}
                      src={dropArrow}
                      onClick={() => setHostDrop(!hostDrop)}
                    />

                    <Box
                      sx={{ display: hostDrop ? "block" : "none" }}
                      className="statusPop"
                    >
                      <Box className="checkSelectorBox">
                        <span onClick={selectAllCheckHostData}>
                          {t("commonTxt.selectAll")}
                        </span>
                        <span onClick={deleteAllCheckHostData}>
                          {t("commonTxt.deSelectAll")}
                        </span>
                      </Box>

                      <Box
                        className="statusPopItem"
                        onClick={() => {
                          if (chackHostVal.includes("Host1")) {
                            chackHostVal.filter((item) => item !== "Host1");
                            return;
                          } else {
                            pushHostData("Host1");
                          }
                        }}
                      >
                        <Box className="chackBox">
                          <img className="chackBoxImg" src={chackBox} />
                          <img
                            style={{
                              display: chackHostVal.includes("Host1")
                                ? "block"
                                : "none",
                            }}
                            className="chack"
                            src={check}
                          />
                        </Box>
                        <p>Host1</p>
                      </Box>

                      <Box
                        className="statusPopItem"
                        onClick={() => {
                          if (chackHostVal.includes("Host2")) {
                            chackHostVal.filter((item) => item !== "Host2");
                            return;
                          } else {
                            pushHostData("Host2");
                          }
                        }}
                      >
                        <Box className="chackBox">
                          <img className="chackBoxImg" src={chackBox} />
                          <img
                            style={{
                              display: chackHostVal.includes("Host2")
                                ? "block"
                                : "none",
                            }}
                            className="chack"
                            src={check}
                          />
                        </Box>
                        <p>Host2</p>
                      </Box>

                      <Box
                        className="statusPopItem"
                        onClick={() => {
                          if (chackHostVal.includes("Host4")) {
                            chackHostVal.filter((item) => item !== "Host3");
                            return;
                          } else {
                            pushHostData("Host3");
                          }
                        }}
                      >
                        <Box className="chackBox">
                          <img className="chackBoxImg" src={chackBox} />
                          <img
                            style={{
                              display: chackHostVal.includes("Host3")
                                ? "block"
                                : "none",
                            }}
                            className="chack"
                            src={check}
                          />
                        </Box>
                        <p>Host3</p>
                      </Box>

                      <Box
                        className="statusPopItem"
                        onClick={() => {
                          if (chackHostVal.includes("Host4")) {
                            chackHostVal.filter((item) => item !== "Host4");
                            return;
                          } else {
                            pushHostData("Host4");
                          }
                        }}
                      >
                        <Box className="chackBox">
                          <img className="chackBoxImg" src={chackBox} />
                          <img
                            style={{
                              display: chackHostVal.includes("Host4")
                                ? "block"
                                : "none",
                            }}
                            className="chack"
                            src={check}
                          />
                        </Box>
                        <p>Host4</p>
                      </Box>
                    </Box>
                  </Box> */}
                </Box>
                <Box className="timeInputItemBox">
                  <Typography className="eTemPopLabel">
                    {t("LiveInterview.MeetingDuration")}
                  </Typography>
                  <Box className="pickerBox">
                    <FormControl sx={{ width: '100%' }}>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        className="statusBox MuiBox-root css-0"
                        value={activityData.basic_settings.meeting_duration}
                        onChange={(e) => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, meeting_duration: e.target.value } })}
                      >
                        <MenuItem value={15} key={15} >
                          15 Mins.
                        </MenuItem>
                        <MenuItem value={30} key={30} >
                          30 Mins.
                        </MenuItem>
                        <MenuItem value={45} key={45} >
                          45 Mins.
                        </MenuItem>
                        <MenuItem value={60} key={60} >
                          1 Hrs.
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box className="timeInputItemBox">
                  <Typography className="eTemPopLabel">
                    {t("liveInterviewActivityPop.meetingBufferLabel")}
                  </Typography>
                  <Box className="pickerBox">
                    {/* <ReferenceDateUsingValue /> */}
                    <input type="number" className="eTemInput" value={activityData.basic_settings.meeting_buffer} onChange={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, meeting_buffer: e.target.value } })} />
                  </Box>
                </Box>
              </Box>

              <Box mt={5} mb={5} className="timeInputBox">
                <Box className="timeInputItemBox">
                  <Typography className="eTemPopLabel">
                    {t("liveInterviewActivityPop.meetingDaysLabel")}
                  </Typography>
                  {/* <Status
                    label={t("liveInterviewActivityPop.meetingDaysPlaceholder")}
                    itemAry={meetingDayItem}
                    setStatusDrop={setMeetingDay}
                    statusDrop={meetingDay}
                    setStatusDropVal={setMeetingDayVal}
                    statusDropVal={meetingDayVal}
                  /> */}
                  <FormControl sx={{ width: '100%' }}>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      className="statusBox MuiBox-root css-0"
                      value={activityData.basic_settings.meeting_days || []}
                      onChange={(e) => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, meeting_days: e.target.value } })}
                      multiple
                    >
                      <MenuItem value={'Weekends'} key={1}>
                        {`Weekends`}
                      </MenuItem>
                      <MenuItem value={'Weekdays'} key={2}>
                        {`Weekdays`}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Box className="timeInputItemBox">
                  <Typography className="eTemPopLabel">
                    {t("liveGroupChatPop.startTimeLabel")}
                  </Typography>
                  <Box className="pickerBox">
                    <ReferenceDateExplicitTimePicker
                      placeholder={t("addConversationTopic.timePlaceholder")}
                      value={dayjs(activityData.basic_settings.start_time, "HH:mm:ss")}
                      onChange={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, start_time: getDatePickerVal(e, "time") } })}
                    />
                  </Box>
                </Box>
                <Box className="timeInputItemBox">
                  <Typography className="eTemPopLabel">
                    {t("liveInterviewActivityPop.endTimeLabel")}
                  </Typography>

                  <Box className="pickerBox">
                    <ReferenceDateExplicitTimePicker
                      placeholder={t(
                        "liveInterviewActivityPop.endTimePlaceholder"
                      )}
                      value={dayjs(activityData.basic_settings.end_time, "HH:mm:ss")}
                      onChange={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, end_time: getDatePickerVal(e, "time") } })}
                    />
                  </Box>
                </Box>
              </Box>

              <Typography className="eTemPopLabel">
                {t("liveInterviewActivityPop.hostAvailabilityHead")}
              </Typography>

              <Box mt={3} className="redioItem" onClick={() => {
                setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, host_availability: "available" } })
              }}>
                <Box
                  className={
                    activityData.basic_settings.host_availability === "available"
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>
                  {t("liveInterviewActivityPop.hostAvailabilityCheckbox1")}
                </Typography>
              </Box>

              <Box
                mt={3}
                mb={5}
                className="redioItem"
                onClick={() => {
                  setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, host_availability: "unavailable" } })
                }}
              >
                <Box
                  className={
                    activityData.basic_settings.host_availability === "unavailable"
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>
                  {t("liveInterviewActivityPop.hostAvailabilityCheckbox2")}
                </Typography>
              </Box>

              <Typography className="eTemPopLabel marginB">
                {t("liveInterviewActivityPop.limitsHead")}
              </Typography>

              <Box className="airadioSwitchBox">
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                  checked={activityData.basic_settings.include_pending}
                  onClick={(e) => {
                    setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, include_pending: e.target.checked } })
                  }}
                />
                <Typography className="switchText">
                  {t("liveInterviewActivityPop.limitSwitch1")}
                </Typography>
              </Box>
              <Box className="airadioSwitchBox">
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                  checked={activityData.basic_settings.limit_scheduling}
                  onClick={(e) => {
                    setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, limit_scheduling: e.target.checked } })
                  }}
                />
                <Typography className="switchText">
                  {t("liveInterviewActivityPop.limitSwitch2")}
                </Typography>
              </Box>
              {activityData.basic_settings.limit_scheduling && <><Box mt={3} ml={3} className="redioItem" onClick={() => {
                setActivityData({
                  ...activityData, basic_settings:
                    { ...activityData.basic_settings, visibility: "all-participant" }
                })
              }}>
                <Box
                  className={
                    activityData.basic_settings.visibility === "all-participant"
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>
                  {t("projectParticipantReport.participantAllParticipant")}
                </Typography>
              </Box>

                <Box mt={3} ml={3} mb={3} className="redioItem" onClick={() => {
                  setActivityData({
                    ...activityData, basic_settings:
                      { ...activityData.basic_settings, visibility: "selected-groups" }
                  })
                }}>
                  <Box
                    className={
                      activityData.basic_settings.visibility === "selected-groups"
                        ? "customRedio customRedioActive"
                        : "customRedio"
                    }
                  >
                    <Box></Box>
                  </Box>
                  <Typography>
                    {t(
                      "addConversationTopic.topicVisibilityParticipantsCheckbox2"
                    )}
                  </Typography>
                </Box>

              </>}

              <Box
                mt={2}
                ml={5}
                sx={{ display: activityData.basic_settings.visibility === "selected-groups" ? "block" : "none" }}
              >
                <Typography className="psNlabel">
                  {t("projectParticipantReport.participantSelectGroup")}
                </Typography>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    className="statusBox MuiBox-root css-0"
                    value={activityData.basic_settings.selected_groups || "Select Country"}
                    onChange={(e) => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, selected_groups: e.target.value } })}
                    multiple
                  >
                    {groups.length > 0 &&
                      groups.map((el, i) => (
                        <MenuItem value={el._id} key={i} >
                          {el.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Box className="airadioSwitchBox">
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                  checked={activityData.basic_settings.limit_visibility}
                  onClick={(e) => {
                    setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, limit_visibility: e.target.checked } })
                  }}
                />
                <Typography className="switchText">
                  {t("liveInterviewActivityPop.limitSwitch3")}
                </Typography>
              </Box>
              <Box className="airadioSwitchBox">
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                  checked={activityData.basic_settings.disable_cameras}
                  onClick={(e) => {
                    setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, disable_cameras: e.target.checked } })
                  }}
                />
                <Typography className="switchText">
                  {t("liveInterviewActivityPop.limitSwitch4")}
                </Typography>
              </Box>
              <Box className="airadioSwitchBox">
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                  checked={activityData.basic_settings.revoke_camera_permission}
                  onClick={(e) => {
                    setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, revoke_camera_permission: e.target.checked } })
                  }}
                />
                <Typography className="switchText">
                  {t("liveInterviewActivityPop.limitSwitch5")}
                </Typography>
              </Box>
              <Box
                sx={{ display: redio == 0 ? "flex" : "none" }}
                className="airadioSwitchBox"
              >
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                  checked={activityData.basic_settings.limit_participant_check}
                  onClick={(e) => {
                    setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, limit_participant_check: e.target.checked } })
                  }}
                />
                <Typography mr={2} className="switchText">
                  {t("liveInterviewActivityPop.limitSwitch6")}
                </Typography>
                <input className="agreeNumber" value={activityData.basic_settings.limit_participant}
                  onChange={(e) => {
                    setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, limit_participant: e.target.value } })
                  }}
                />
                <Typography className="switchText">
                  {t("liveInterviewActivityPop.limitSwitch9")}
                </Typography>
              </Box>
              <Box
                sx={{ display: redio == 0 ? "flex" : "none" }}
                className="airadioSwitchBox"
              >
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                  checked={activityData.basic_settings.require_hour_check}
                  onClick={(e) => {
                    setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, require_hour_check: e.target.checked } })
                  }}
                />
                <Typography mr={2} className="switchText">
                  {t("liveInterviewActivityPop.limitSwitch7")}
                </Typography>

                <input className="agreeNumber" value={activityData.basic_settings.require_hour}
                  onChange={(e) => {
                    setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, require_hour: e.target.value } })
                  }}
                />
                <Typography className="switchText">
                  {t("liveInterviewActivityPop.limitSwitch10")}
                </Typography>
              </Box>
              <Box className="airadioSwitchBox">
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                  checked={activityData.basic_settings.disable_audio_translator_support}
                  onClick={(e) => {
                    setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, disable_audio_translator_support: e.target.checked } })
                  }}
                />
                <Typography className="switchText">
                  {t("liveInterviewActivityPop.limitSwitch8")}
                </Typography>
              </Box>
              <Box className="gapBox"></Box>
              <Typography className="eTemPopLabel">
                {t("liveInterviewActivityPop.meetingInvitationLabel")}
              </Typography>
              <Box className="input_Box">
                <input
                  type="text"
                  className="eTemInput"
                  placeholder={t(
                    "liveInterviewActivityPop.meetingInvitationPlaceholder"
                  )}
                  value={activityData.basic_settings.meeting_invitation}
                  onChange={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, meeting_invitation: e.target.value } })}
                />
              </Box>

              <Typography className="DmettingTExt">
                {t("liveInterviewActivityPop.meetingPurposeLabel")}
              </Typography>
              <textarea
                className="DmettingTextTextAera"
                placeholder="Hi {Name}"
                value={activityData.basic_settings.description}
                onChange={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, description: e.target.value } })}
              />
            </Box>

            {/* =====Notifications======= */}
            <Box
              className="notLive"
              sx={{
                display: standardTab4 === "Notifications" ? "block" : "none",
              }}
            >
              <Box className="airadioSwitchBox">
                <AntSwitch
                  //checked={switch1}
                  inputProps={{ "aria-label": "ant design" }}
                  /* onChange={(e) =>
                    handleSwitchChange({
                      event: e,
                      setState: setSwitch1,
                    })
                  } */
                  checked={activityData.notification_settings.is_meeting_scheduled}
                  onClick={(e) => setActivityData({ ...activityData, notification_settings: { ...activityData.notification_settings, is_meeting_scheduled: e.target.checked } })}
                />
                <Typography className="switchText">
                  {t("liveInterviewActivityPop.notificationsSwitch1")}
                </Typography>
              </Box>

              <Box className="airadioSwitchBox">
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                  /* onChange={(e) =>
                    handleSwitchChange({
                      event: e,
                      setState: setSwitch2,
                    })
                  } */
                  checked={activityData.notification_settings.is_customize}
                  onClick={(e) => setActivityData({ ...activityData, notification_settings: { ...activityData.notification_settings, is_customize: e.target.checked } })}
                />
                <Typography className="switchText">
                  {t("liveInterviewActivityPop.notificationsSwitch2")}
                </Typography>
              </Box>

              <Box mb={2} sx={{ display: activityData.notification_settings.is_customize ? "block" : "none" }}>
                <Typography className="eTemPopLabel">
                  {t("liveInterviewActivityPop.meetingStartingSoonLabel")}
                </Typography>
                <Box className="input_Box">
                  <input
                    type="text"
                    className="eTemInput"
                    placeholder={t(
                      "liveInterviewActivityPop.meetingStartPlaceholder"
                    )}
                    value={activityData.notification_settings.starting_soon_heading}
                    onChange={(e) => setActivityData({ ...activityData, notification_settings: { ...activityData.notification_settings, starting_soon_heading: e.target.value } })}
                  />
                </Box>

                <Typography className="DmettingTExt">
                  {t("liveInterviewActivityPop.describeMeetingPurpose")}
                </Typography>
                <textarea
                  className="DmettingTextTextAera"
                  placeholder="Hi {Name}"
                  value={activityData.notification_settings.starting_soon_description}
                  onChange={(e) => setActivityData({ ...activityData, notification_settings: { ...activityData.notification_settings, starting_soon_description: e.target.value } })}
                />
                <Box
                  my={2}
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  {/* <PurplelBtn buttonText={t("commonTxt.submitBtn")} /> */}
                </Box>
              </Box>

              <Box className="airadioSwitchBox">
                <AntSwitch
                  //checked={switch3}
                  inputProps={{ "aria-label": "ant design" }}
                  /* onChange={(e) =>
                    handleSwitchChange({
                      event: e,
                      setState: setSwitch3,
                    })
                  } */
                  checked={activityData.notification_settings.is_missed}
                  onClick={(e) => setActivityData({ ...activityData, notification_settings: { ...activityData.notification_settings, is_missed: e.target.checked } })}
                />
                <Typography className="switchText">
                  {t("liveInterviewActivityPop.notificationsSwitch3")}
                </Typography>
              </Box>
              <Box className="airadioSwitchBox">
                <AntSwitch
                  // checked={switch4}
                  inputProps={{ "aria-label": "ant design" }}
                  /* onChange={(e) =>
                    handleSwitchChange({
                      event: e,
                      setState: setSwitch4,
                    })
                  } */
                  checked={activityData.notification_settings.is_rescheduled}
                  onClick={(e) => setActivityData({ ...activityData, notification_settings: { ...activityData.notification_settings, is_rescheduled: e.target.checked } })}
                />
                <Typography className="switchText">
                  {t("liveInterviewActivityPop.notificationsSwitch4")}
                </Typography>
              </Box>
              <Box className="airadioSwitchBox">
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                  //checked={switch5}
                  /*  onChange={(e) =>
                     handleSwitchChange({
                       event: e,
                       setState: setSwitch5,
                     })
                   } */
                  checked={activityData.notification_settings.is_cancelled}
                  onClick={(e) => setActivityData({ ...activityData, notification_settings: { ...activityData.notification_settings, is_cancelled: e.target.checked } })}
                />
                <Typography className="switchText">
                  {t("liveInterviewActivityPop.notificationsSwitch5")}
                </Typography>
              </Box>

              <Typography mb={2} className="eTemPopLabel">
                {t("liveInterviewActivityPop.notifyAdministratorsLabel")}
              </Typography>
              {/* <BigDrop
                setDrop={setAdminDrop}
                Drop={adminDrop}
                setDropVal={setAdminDropVal}
                DropVal={adminDropVal}
                dropItems={AdministratorItem}
              /> */}

              <FormControl sx={{ width: '100%' }}>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  className="statusBox MuiBox-root css-0"
                  value={activityData.notification_settings.notify_administrator || "Select Administrator"}
                  onChange={(e) => setActivityData({ ...activityData, notification_settings: { ...activityData.notification_settings, notify_administrator: e.target.value } })}
                >
                  {AdministratorItem.length > 0 &&
                    AdministratorItem.map((el, i) => (
                      <MenuItem value={el} key={i} >
                        {el}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>


              {activityData.notification_settings.notify_administrator === "Select Admin Roles" && (
                <Box className="checkMain_box">
                  <Box className="ckeckBoxRoom">
                    {/* {AdminRolesCheckBox("allresearcher", "All Researchers")}
                    {AdminRolesCheckBox(
                      "allcollaborators",
                      "All Collaborators"
                    )}
                    {AdminRolesCheckBox("allobservers", "All Observers")} */}
                    <FormControl sx={{ width: '100%' }}>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        className="statusBox MuiBox-root css-0"
                        value={activityData.notification_settings.administrator_roles || "Select Administrator"}
                        onChange={(e) => setActivityData({ ...activityData, notification_settings: { ...activityData.notification_settings, administrator_roles: e.target.value } })}
                        multiple
                      >
                        {administratorRoles.length > 0 &&
                          administratorRoles.map((el, i) => (
                            <MenuItem value={el.value} key={i} >
                              {el.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              )}

              {activityData.notification_settings.notify_administrator === "Select Admins" && (
                <Box className="searchGroupSection">
                  <FormControl sx={{ width: '100%' }}>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      className="statusBox MuiBox-root css-0"
                      value={activityData.notification_settings.admin_ids || "Select Administrator"}
                      onChange={(e) => setActivityData({ ...activityData, notification_settings: { ...activityData.notification_settings, admin_ids: e.target.value } })}
                      multiple
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <Box
                              pl={4.5}
                              className="inputAppDrop"
                            >
                              <Typography>
                                Select admins
                              </Typography>
                            </Box>
                          );
                        }
                        return selected
                          .map((value) => {
                            const user = adminsList.find(
                              (usr) => usr._id === value
                            );
                            return user ? user.name : value;
                          })
                          .join(", ");
                      }}
                    >
                      {adminsList.length > 0 &&
                        adminsList.map((el, i) => (
                          <MenuItem value={el._id} key={i} >
                            {el.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {/* <Box
                    pl={4.5}
                    className="inputAppDrop"
                    onClick={() => setSearchDrop(!searchDrop)}
                  >
                    <img className="groupsIcon" src={searchIcon} />
                    <Typography>
                      {t("pageSettingPop.adminNamePlaceholder")}
                    </Typography>
                    <img
                      style={{ rotate: searchDrop ? "180deg" : "0deg" }}
                      src={dropArrow}
                    />
                  </Box>
                  <Box
                    sx={{ height: searchDrop ? "auto" : "0px" }}
                    className="checkMain_box"
                  >
                    <Box className="searchDropAdminList admins"> */}
                  {/* <Box className="ckeckBoxRoom">
                        {AdminsCheckBox("Olivia Rhye", "Olivia Rhye")}
                        {AdminsCheckBox("Phoenix Baker", "Phoenix Baker")}
                        {AdminsCheckBox("Kate Morrison", "Kate Morrison")}
                      </Box>

                      {adminsMore && (
                        <Box className="ckeckBoxRoom">
                          {AdminsCheckBox("Olivia Rhye2", "Olivia Rhye2")}
                          {AdminsCheckBox("Phoenix Baker2", "Phoenix Baker2")}
                          {AdminsCheckBox("Kate Morrison2", "Kate Morrison2")}
                          {AdminsCheckBox("Olivia Rhye3", "Olivia Rhye3")}
                          {AdminsCheckBox("Phoenix Baker3", "Phoenix Baker3")}
                          {AdminsCheckBox("Kate Morrison3", "Kate Morrison3")}
                        </Box>
                      )} */}
                  {/* <Box onClick={() => setAdminsMore(!adminsMore)}>
                        {!adminsMore ? (
                          <Box className="viewMoreDrop">
                            <Typography>{t("commonTxt.viewMore")}</Typography>
                            <img src={dropIcon} />
                          </Box>
                        ) : (
                          <Box className="viewMoreDrop">
                            <Typography>{t("commonTxt.hideBtn")}</Typography>
                            <img src={dropIcon} />
                          </Box>
                        )}
                      </Box> */}

                  {/* </Box>
                  </Box> */}
                </Box>
              )}
            </Box>

            {/* =====Points Customizations======= */}
            <Box
              sx={{
                display:
                  standardTab4 === "Points Customization" ? "block" : "none",
              }}
            >
              <Box className="airadioSwitchBox">
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                  checked={activityData.points_customization.customize_points}
                  onClick={e => setActivityData({ ...activityData, points_customization: { ...activityData.points_customization, customize_points: e.target.checked } })}
                />
                <Typography className="switchText pcTopicSText">
                  {t("standardActivityPop.pointCustomizationSwitch")}
                </Typography>
              </Box>

              <Box mb={3} className="pcTopicListRow">
                <Typography>
                  {t("liveGroupChatPop.pointCustomization1")}
                </Typography>
                <span>:</span>
                <input
                  value={activityData.points_customization.standard_completion}
                  onChange={e => setActivityData({ ...activityData, points_customization: { ...activityData.points_customization, standard_completion: e.target.value } })}
                />
                <samp>{t("commonTxt.pts.")}</samp>
              </Box>
            </Box>

            <Box className="workPupBtnBox">
              <AppBtnOutLine
                buttonText={t("commonTxt.cancelBtn")}
                onClick={() => setActivePage(null)}
              />
              <AppBtnOutLine
                buttonText={t("commonTxt.backBtn")}
                onClick={() => setActivePage(null)}
              />
              <AppBtn
                buttonText={t("commonTxt.continueBtn")}
                onClick={() => saveStandardActivity(true, "/activities/interview-scheduled")}
              />
            </Box>
          </Box>

          {/*---------------Default --------------- */}
          <Box sx={{ display: !activePage ? "block" : "none" }}>
            <Typography className="companyHeader">
              {editActivityID == "" ? t("addActivityPop.addActivityHead") : `${popupAction} Activity`}
            </Typography>

            <Box className="iBox">
              <img src={iIcon} />
              <Typography>{t("addActivityPop.addActivityNotify")}</Typography>
            </Box>

            <Typography className="eTemPopLabel">
              {t("addActivityPop.activityNameLabel")}
            </Typography>
            <Box className="input_Box">
              <input
                type="text"
                className="eTemInput"
                placeholder={t("addActivityPop.activityNamePlaceholder")}
                value={activityData.name ?? ""}
                name="name"
                onChange={setFormData}
              />
              <span className="validationerr">{validationErrors.name.msg}</span>
            </Box>

            <Typography className="eTemPopLabel">
              {t("addActivityPop.activityImageLabel")}
            </Typography>
            <Box
              className="mediaInputbox upload-area"
              onClick={() => {
                document.getElementById("fileInput").click();
              }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <img src={image ? image : plassIcon} />
              <Typography>{t("commonTxt.uploadText")}</Typography>
              <input
                id="fileInput"
                style={{ display: "none" }}
                type="file"
                onChange={handleFileInputChange}
              />
            </Box>
            <Typography className="eTemPopLabel">
              {t("addActivityPop.selectActivityTypeLabel")}
            </Typography>

            <Box className="ActivityTypeBox">
              {ActivityCard({
                icon: AddAc1,
                activeIcon: AddAtv1,
                title: t("addActivityPop.standardActivity"),
                subText: t("addActivityPop.standardActivityText"),
              })}
              {ActivityCard({
                icon: AddAc2,
                activeIcon: AddAtv2,
                title: t("addActivityPop.journalActivity"),
                subText: t("addActivityPop.journalActivityText"),
              })}
              {ActivityCard({
                icon: AddAc3,
                activeIcon: AddAtv3,
                title: t("addActivityPop.liveGroupChat"),
                subText: t("addActivityPop.liveGroupChatText"),
              })}
              {ActivityCard({
                icon: AddAc4,
                activeIcon: AddAtv4,
                title: t("addActivityPop.liveInterviewActivity"),
                subText: t("addActivityPop.liveInterviewActivityText"),
              })}
              <span className="validationerr">{validationErrors.type.msg}</span>
            </Box>

            <Box className="workPupBtnBox">
              <AppBtnOutLine
                buttonText={t("commonTxt.cancelBtn")}
                onClick={() => {
                  resetAll()
                }}
              />
              <AppBtn
                buttonText={t("commonTxt.continueBtn")}
                onClick={handle1stForm}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
