import React, { useState, useEffect } from "react";
import { Box, Typography, Avatar, Grid, Button, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "./style.css";

//images
import backArrow from "../../../Assets/Images/noun-back.png";
import ProfileImage from "../../../Assets/Images/profile-picture.png";
import detailsIcon from "../../../Assets/Images/icon-info.svg";
import shieldsIcon from "../../../Assets/Images/shiled.png";
import cott from "../../../Assets/Images/cott.png";
import AvatarTwo from "../../../Assets/Images/UserHomeAvatar.png";

//data
import {
  participants,
  sideHaumIcon,mailData2,
} from "../../../Assets/Data";

//components
import { AppBtn } from "../../../Components/AppTools/AppButton";
import SideMenu from "../../../Components/Researchers/SideMenu";
import { SearchBar } from "../../../Components/AppTools/AppTool";

export default function ViewMessageProfile({
  workspaceDrop,
  setWorkSpaceDrop,
  slideMenu,
  setSlideMenu,
}) {

    const {
        t,
        i18n: { changeLanguage, language },
      } = useTranslation();
  const Navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(0);
  const [newArrayData, setNewArrayData] = useState([]);
  const [activeNav, setActiveNav] = useState("All");

  const handelRead = (type) => {
    if (type === "All") {
      let element = document.getElementById('markRead')
      ReactDOM.findDOMNode(element).style.display = "block"
    }
    else if (type === "Draft") {
      let element = document.getElementById('markRead')
      ReactDOM.findDOMNode(element).style.display = "block"

    }
    else if (type === "Unread") {
      let element = document.getElementById('markRead')
      ReactDOM.findDOMNode(element).style.display = "none"

    }
    else if (type === "Awaiting Reply") {
      let element = document.getElementById('markRead')
      ReactDOM.findDOMNode(element).style.display = "none"

    }
  };

  useEffect(() => {
    printItemsByState(participants, currentPage);
  }, [currentPage]);

  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  const InboxCard = ({ avata, title, subject, date, read, index, type }) => {
    return (
      <Box key={index} className="inboxCard">
        <Box className="inboxC1">
          <Box className="inboxAavatar">
            <img src={avata} />
          </Box>
        </Box>
        <Box className={read ? "inboxC2 inboxC2Active" : "inboxC2"}>
          <Box>
            <Typography className="inbCTitle">{title}</Typography>
            <Typography className="inbDate">{date}</Typography>
          </Box>
          <Typography className="inbCSubject">{subject}</Typography>
        </Box>
      </Box>
    );
  };
  //

  return (
    <>
      <Box className="ResearchersContainer ">
        {/* side menu */}
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />

        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>

            <Box sx={{gap:"15px"}} className="AppNavBtnBox">
                <Box className="actTNavBtn">
                    <img src={cott} />
                    <Typography>{t("commonTxt.excerptBtn")}</Typography>
                </Box>
                <Box>
                    <AppBtn buttonText={t("commonTxt.viewProfileBtn")} onClick={() => Navigate("/view-participant-profile")} />
                </Box>
            </Box>
          </Box>

          {/* Page container */}
          <Box className="researchersMainBox pageContainer">
              <Box className="workSpaceListBox">
                <Box mb={2}
                    width="74px"
                    onClick={() => Navigate("/view-participant-profile")}
                    className="openBtn"
                >
                    <img src={backArrow} />
                    <Typography className="neonBackText">{t("commonTxt.backBtn")}</Typography>
                </Box>
                <Box className="ProfileDetailsPanel">
                    <Box className="ProfilePicture">
                    <Avatar alt="" src={ProfileImage} sx={{ width: 112, height: 112 }} />
                    </Box>
                    <Box className="ProfileDetails">
                    <Typography variant="h4" gutterBottom>
                        Amanda Tomlin
                    </Typography>
                    <Grid container spacing={5}>
                        <Grid item xs={5} md={7}>
                        <Box  sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            }}>
                            <Button className="infoBtn" variant="text">
                                <img src={detailsIcon} /> 
                            </Button>
                            <Typography className="neonBackText">{t("viewParticipantProfile.viewParticipantProfileDetails")}</Typography>
                        </Box>
                        </Grid>
                        <Grid item xs={7} md={5}>
                        <Box
                            sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: "20px",
                            }}
                        >
                            <img src={shieldsIcon} />
                            <Typography variant="h6">{t("viewParticipantProfile.viewParticipantProfileTera")}</Typography>
                            <Chip label="1500 Points" />
                        </Box>
                        </Grid>
                    </Grid>
                    </Box>
                </Box>
                <Box className="ProfileDataPanel">
                    <Box className="panel">
                    {t("viewParticipantProfile.viewParticipantProfileProject")} <span>{t("viewParticipantProfile.viewParticipantProfileJoined")}</span> <strong>Jul 2, 2023</strong>
                    </Box>
                    <Box className="panel">
                    {t("viewParticipantProfile.viewParticipantProfileProject")} <span>{t("viewParticipantProfile.viewParticipantProfileVisits")}</span> <strong>265</strong>
                    </Box>
                    <Box className="panel">
                    {t("viewParticipantProfile.viewParticipantProfileActivities")} <span>{t("viewParticipantProfile.viewParticipantProfileCompleted")}</span> <strong>122</strong>
                    </Box>
                    <Box className="panel">
                    {t("viewParticipantProfile.viewParticipantProfileLast")} <span>{t("viewParticipantProfile.viewParticipantProfileVisits")}</span> <strong>Jul 2, 2023</strong>
                    </Box>
                    <Box className="panel">
                    {t("viewParticipantProfile.viewParticipantProfileLastEmail")} <span>{t("viewParticipantProfile.viewParticipantProfileOpened")}</span> <strong>Jul 2, 2023</strong>
                    </Box>
                </Box>
                <Box className="taskTable">
                {/* -------===========--------Messages------======------------ */}
                <Box className="dMsgNavBox">
                  <Box
                    onClick={() => {
                      setActiveNav("All");
                      handelRead("All");
                    }}
                    className={
                      activeNav === "All"
                        ? "dMsgNavItem dMsgNavItemActive"
                        : "dMsgNavItem "
                    }
                  >
                    <Typography>{t("viewMessageProfile.viewMessageProfileNav1")}</Typography>
                  </Box>
                  <Box
                    onClick={() => {
                      setActiveNav("Draft");
                      handelRead("Draft");
                    }}
                    className={
                      activeNav === "Draft"
                        ? "dMsgNavItem dMsgNavItemActive"
                        : "dMsgNavItem "
                    }
                  >
                    <Typography>{t("viewMessageProfile.viewMessageProfileNav2")}</Typography>
                  </Box>
                  <Box
                    onClick={() => {
                      setActiveNav("Unread");
                      handelRead("Unread");
                    }}
                    className={
                      activeNav === "Unread"
                        ? "dMsgNavItem dMsgNavItemActive"
                        : "dMsgNavItem "
                    }
                  >
                    <Typography>{t("viewMessageProfile.viewMessageProfileNav3")}</Typography>
                  </Box>
                  <Box
                    onClick={() => {
                      setActiveNav("Awaiting Reply");
                      handelRead("Awaiting Reply");
                    }}
                    className={
                      activeNav === "Awaiting Reply"
                        ? "dMsgNavItem dMsgNavItemActive"
                        : "dMsgNavItem "
                    }
                  >
                    <Typography>{t("viewMessageProfile.viewMessageProfileNav4")}</Typography>
                  </Box>
                </Box>

                <Box className="dMsgSearchBox">
                  <Box className="searchB">
                    <SearchBar />
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "20px" }}>
                    <Typography className="markText" id="markRead">{t("commonTxt.markAllReadLink")}</Typography>
                  </Box>
                </Box>
                    <Box
                    sx={{
                      width: activeNav === "All" ? "100%" : "0%",
                      display: activeNav === "All" ? "flex" : "none",
                    }}
                    className="inboxCardBox"
                  >
                    {mailData2[0]['msg']["indox"] &&
                      mailData2[0]['msg']["indox"]?.map((el, index) =>
                        InboxCard({
                          avata: el.img,
                          title: el.title,
                          subject: el.subject,
                          date: el.date,
                          read: el.read,
                          index,
                          type: "indox",
                        })
                      )}
                    </Box>
                    <Box
                    sx={{
                        width: activeNav === "Draft" ? "100%" : "0%",
                        display: activeNav === "Draft" ? "flex" : "none",
                    }}
                    className="broadcastCardBox"
                    >
                    {mailData2[0]['msg']["draft"] &&
                        mailData2[0]['msg']["draft"]?.map((el, index) =>
                        InboxCard({
                            avata: el.img,
                            title: el.title,
                            subject: el.subject,
                            date: el.date,
                            read: el.read,
                            index,
                            type: "draft",
                        })
                        )}
                    </Box>
                    <Box
                    sx={{
                        width: activeNav === "Unread" ? "100%" : "0%",
                        display: activeNav === "Unread" ? "flex" : "none",
                    }}
                    className="sentCardBox"
                    >
                    {mailData2[0]['msg']["sent"] &&
                        mailData2[0]['msg']["sent"]?.map((el, index) =>
                        InboxCard({
                            avata: el.img,
                            title: el.title,
                            subject: el.subject,
                            date: el.date,
                            read: el.read,
                            index,
                            type: "sent",
                        })
                        )}
                    </Box>
                    <Box
                    sx={{
                        width: activeNav === "Awaiting Reply" ? "100%" : "0%",
                        display: activeNav === "Awaiting Reply" ? "flex" : "none",
                    }}
                    className="draftCardBox"
                    >
                    {mailData2[0]['msg']["broadcast"] &&
                        mailData2[0]['msg']["broadcast"]?.map((el, index) =>
                        InboxCard({
                            avata: el.img,
                            title: el.title,
                            subject: el.subject,
                            date: el.date,
                            read: el.read,
                            index,
                            type: "broadcast",
                        })
                        )}
                    </Box>
                <Box className="replyBox">
                    <Avatar alt="" src={AvatarTwo} sx={{ width: 40, height: 40 }} />
                    <Box className="NewmsgBox">
                    <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                        placeholder={t("viewMessageProfile.viewMessageProfileMessageTextareaPlaceholder")}
                    ></textarea>
                    <Box className="bot">
                        
                        <Box className="checkPermissionSection">
                        <input className="custom-checkbox" type="checkBox" />
                        <Typography>{t("viewMessageProfile.viewMessageProfileCheckbox")}</Typography>
                        </Box>
                        <Box>
                            <Typography className="markText">{t("viewMessageProfile.viewMessageProfileTemplatesLink")}</Typography>
                        </Box>
                    </Box>
                    </Box>
                    <Box className="SendBtn">
                    <AppBtn buttonText={t("commonTxt.sendBtn")} />
                    </Box>
                </Box>
                {/* -------===========--------Messages------======------------ */}
                </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
