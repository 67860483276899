import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from "react-i18next";
import "./style.css";

//Images
import iIcon2 from "../../../Assets/Images/IIcon.png";
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png";

//components 
import { AppBtn, AppBtnOutLine } from '../../../Components/AppTools/AppButton';

export default function ProjectAppearance({ projectSettingDropVal }) {

    const [image, setImage] = useState(null);

    const {
        t,
        i18n: { changeLanguage, language },
      } = useTranslation();

    const handleDragOver = (e) => {
        e.preventDefault();
      };
    
      const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            setImage(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };
    
      const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            setImage(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };

  return (
    <>   
        <Box sx={{ display: projectSettingDropVal === "Appearance" ? "block" : "none" }}>
            <Box className="projectNotificationMainBox">
                <Box className="projectAppearanceBox">
                    <Box className="iBox projectIBox">
                        <img src={iIcon2} />
                        <Typography>{t("projectAppearance.projectAppearanceNotify1")}</Typography>
                    </Box>
                    <Box className="projectAppearanceSection">
                        <Typography className='allPartRedioLabel'>{t("projectAppearance.projectNameHead")}</Typography>
                        <Typography className='projectNotificationSubHead'>{t("projectAppearance.projectNameSubText")}</Typography>
                            <input
                                type="text"
                                className="eTemInput"
                                placeholder={t("projectAppearance.projectNamePlaceholder")}
                            />
                    </Box>
                </Box>
                <Box className="projectAppearanceBox">
                    <Box className="iBox projectIBox">
                        <img src={iIcon2} />
                        <Typography>{t("projectAppearance.projectAppearanceNotify2")}</Typography>
                    </Box>
                    <Box className="projectAppearanceSection">
                        <Typography className='allPartRedioLabel'>{t("projectAppearance.projectAddressHead")}</Typography>
                        <Typography className='projectNotificationSubHead'>{t("projectAppearance.projectAddressSubText")}</Typography>
                            <input
                                type="text"
                                className="eTemInput"
                                placeholder={t("projectAppearance.projectAddressPlaceholder")}
                                defaultValue={"www.terapage.com/outdoor-journey"}
                            />
                    </Box>
                </Box>
                <Box className="projectAppearanceBox">
                    <Box className="iBox projectIBox">
                        <img src={iIcon2} />
                        <Typography>{t("projectAppearance.projectAppearanceNotify3")}</Typography>
                    </Box>
                    <Box className="projectAppearanceSection">
                        <Typography className='allPartRedioLabel'>{t("projectAppearance.externalProjectIdHead")}</Typography>
                        <Typography className='projectNotificationSubHead'>{t("projectAppearance.externalProjectIdSubText")}</Typography>
                            <input
                                type="text"
                                className="eTemInput"
                                placeholder={t("projectAppearance.externalProjectIdPlaceholder")}
                            />
                    </Box>
                </Box>
                <Box className="projectAppearanceBox">
                    <Box className="projectAppearanceSection">
                        <Typography className='allPartRedioLabel'>{t("projectAppearance.projectLogoHead")}</Typography>
                        <Box className='mediaInputbox upload-area' onClick={() => {
                            document.getElementById("fileInput").click();
                            }}
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}>
                            <img src={uploadIcon} />
                            <Typography>{t("commonTxt.uploadText")}</Typography>
                            <input
                                id="fileInput"
                                style={{ display: "none" }}
                                type="file"
                                onChange={handleFileInputChange}
                            />
                        </Box>
                    </Box>
                </Box>
                
            </Box>
            <Box className="workPupBtnBox">
                <AppBtnOutLine buttonText={t("commonTxt.resetBtn")} />
                <AppBtn buttonText={t("commonTxt.saveBtn")} />
            </Box>
        </Box>
    </>
  )
}
