import React, { useState, useEffect } from "react";
import { Box, Typography, FormControl } from "@mui/material";
import "./style.css";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";

//images
import groupsIcon from "../../../Assets/Images/groupsIcon.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../env";
import { uploadFile } from "../../../Services/adminActions";
import { useAppSelector } from "../../../lib/store/store";
import { API_BASE_URL } from "../../../env";
import axios from "../../../lib/axios";

export default function AddPagePop({
  allDropVal3,
  setAllDropVal3,
  projectData,
  setProjectData,
  initialState,
  projectName,
}) {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [Drop, setDrop] = useState(false);
  const [groupDrop2, setGroupDrop2] = useState(false);
  const [selectedAdminRoles, setSelectedAdminRoles] = useState([]);
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const [selectedParticipantGroups, setSelectedParticipantGroups] = useState(
    []
  );
  const [groupsListData, setGroupsListData] = useState([]);
  const [adminsList, setAdminsList] = useState([]);

  const authState = useAppSelector((state) => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace);
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const ParticipantItem = [
    {
      name: "All Participants",
      value: "all",
    },
    {
      name: "Select Participant Groups",
      value: "selected_participants",
    },
    {
      name: "None",
      value: "none",
    },
  ];

  const AdministratorItem = [
    {
      name: "All Administrators",
      value: "all",
    },
    {
      name: "Select Admin Roles",
      value: "selected_roles",
    },
    {
      name: "Select Admins",
      value: "selected_admins",
    },
  ];
  const administratorRoles = [
    { name: "Researchers", value: "researcher" },
    { name: "Collaborators", value: "collaborator" },
    { name: "Observer", value: "observer" },
  ];

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setAllDropVal3("Page Options");
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      uploadImage(file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      uploadImage(file);
    }
  };

  const resetAll = () => {
    setProjectData(initialState);
    setImage(null);
  };

  useEffect(() => {
    if (allDropVal3 === "Add Page") {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [allDropVal3]);

  useEffect(() => {
    getGroupsLists();
    getResearcher();
    getCollaborator();
    getObserver();
  }, []);

  const getGroupsLists = async () => {
    try {
      const res = await axios.get(API_BASE_URL + "/participant/groups");
      if (res.status) {
        const group = res.data?.data?.filter((grp) => grp.is_enhanced == false);
        setGroupsListData(group);
        console.log(
          "Group List=>",
          res.data?.data?.filter((grp) => grp.is_enhanced == false)
        );
      }
    } catch (error) {
      console.error("Error fetching project lists", error);
    }
  };

  const getResearcher = async () => {
    try {
      const response = await axios.get(
        `/super-admin/admin/researchers?workspace=${workspaceState?.selectedWorkspace?._id}`
      );
      setAdminsList((prev) => {
        const prevData = prev || [];
        const newData = response?.data?.data || [];
        const existingIds = new Set(prevData.map((item) => item._id));
        const mergedData = [
          ...prevData,
          ...newData.filter((item) => !existingIds.has(item._id)),
        ];
        return mergedData;
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getCollaborator = async () => {
    try {
      const response = await axios.get(
        `/super-admin/admin/collaborators?workspace=${workspaceState?.selectedWorkspace?._id}`
      );
      setAdminsList((prev) => {
        const prevData = prev || [];
        const newData = response?.data?.data || [];
        const existingIds = new Set(prevData.map((item) => item._id));
        const mergedData = [
          ...prevData,
          ...newData.filter((item) => !existingIds.has(item._id)),
        ];
        return mergedData;
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getObserver = async () => {
    try {
      const response = await axios.get(
        `/super-admin/admin/observers?workspace=${workspaceState?.selectedWorkspace?._id}`
      );
      setAdminsList((prev) => {
        const prevData = prev || [];
        const newData = response?.data?.data || [];
        const existingIds = new Set(prevData.map((item) => item._id));
        const mergedData = [
          ...prevData,
          ...newData.filter((item) => !existingIds.has(item._id)),
        ];
        return mergedData;
      });
    } catch (err) {
      console.log(err);
    }
  };

  const validationObj = {
    hasErr: false,
    title: {
      error: false,
      msg: "",
    },
    participant_access: {
      error: false,
      msg: "",
    },
    administrator_access: {
      error: false,
      msg: "",
    },
  };

  const [validationErrors, setValidationErrors] = useState({
    ...validationObj,
  });

  const handleValidation = (formInput) => {
    formInput = formInput || projectData;
    let validationerr = { ...validationObj };

    if (formInput.title == "") {
      validationerr.hasErr = true;
      validationerr.title = {
        error: true,
        msg: "Title is required",
      };
    }

    if (formInput.participant_access == "") {
      validationerr.hasErr = true;
      validationerr.participant_access = {
        error: true,
        msg: "Participant Access is required",
      };
    }

    if (formInput.administrator_access == "") {
      validationerr.hasErr = true;
      validationerr.administrator_access = {
        error: true,
        msg: "Administrator Access is required",
      };
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  };

  const setFormData = (e) => {
    let newState = {
      ...projectData,
      [e.target.name]: e.target.value,
    };

    setProjectData(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
  };

  const uploadImage = async (file) => {
    if (!file) return false;
    const formData = new FormData();
    formData.append("file", file);
    try {
      let imageUploadRes = await uploadFile(formData, authState.authToken);
      if (imageUploadRes.success) {
        setProjectData({
          ...projectData,
          image: imageUploadRes?.data?.upload_path,
        });
        toast.success(imageUploadRes.message, TOAST_CONFIG);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    } finally {
    }
  };

  let addProjectPage = async () => {
    if (!handleValidation()) {
      toast.error("Please correct form fields", TOAST_CONFIG);
      return false;
    }
    setLoading(true);
    try {
      if (projectData?.image == null || projectData?.image == "") {
        toast.error("Cover image is required");
        return;
      }
      let response = await axios.post(
        API_BASE_URL + `/researchers/pages`,
        projectData,
        authState.authToken
      );
      if (response.success) {
        resetAll();
        toast.success(response.message, TOAST_CONFIG);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      let message = "Something went wrong, please try again!";
      let status_code = 500;
      if (error.response) {
        message = error.response.data.message;
        status_code = error.response.status;
      }
      return {
        success: false,
        message: message,
        status_code: status_code,
      };
    }
  };

  const handleParticipantAccessChange = (event) => {
    const selectedValue = event.target.value === "" ? "" : event.target.value;
    setProjectData((prevGroup) => ({
      ...prevGroup,
      [event.target.name]: selectedValue,
    }));
    if (validationErrors.hasErr) {
      handleValidation(selectedValue);
    }
  };

  const handleParticipantGroupsChange = (event3) => {
    const { value, checked } = event3.target;
    if (checked) {
      //setSelectedParticipantGroups([...selectedParticipantGroups, value]);
      if (selectedParticipantGroups.includes(value)) {
      } else {
        selectedParticipantGroups.push(value);
      }
    } else {
      //setSelectedParticipantGroups(selectedParticipantGroups.filter(participant => participant !== value));
      let index5 = selectedParticipantGroups.indexOf(value);
      selectedParticipantGroups.splice(index5, 1);
    }
    console.log("Selected Participant Group=>", selectedParticipantGroups);
    setProjectData({
      ...projectData,
      participant_groups: selectedParticipantGroups,
    });
  };

  const handleAdminAccessChange = (event2) => {
    setLoading(false);
    const selectedValue2 =
      event2.target.value === "" ? "" : event2.target.value;
    setProjectData((prevGroup) => ({
      ...prevGroup,
      [event2.target.name]: selectedValue2,
    }));
    setLoading(true);
    if (validationErrors.hasErr) {
      handleValidation(selectedValue2);
    }
    //console.log("Admin Access=>",projectData?.administrator_access);
  };

  const handleAdminRolesChange = (event4) => {
    const { value, checked } = event4.target;
    if (checked) {
      //setSelectedAdminRoles([...selectedAdminRoles, value]);
      if (selectedAdminRoles.includes(value)) {
      } else {
        selectedAdminRoles.push(value);
      }
    } else {
      //setSelectedAdminRoles(selectedAdminRoles.filter(admin => admin !== value));
      let index5 = selectedAdminRoles.indexOf(value);
      selectedAdminRoles.splice(index5, 1);
    }
    console.log("Selected Admin Role=>", selectedAdminRoles);
    setProjectData({
      ...projectData,
      administrator_roles: selectedAdminRoles,
    });
  };

  const chackBoxRoom = (group, index) => {
    return (
      <Box className="checkBoxItem" key={`group-${group.index}`}>
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            name={`group-${group.group._id}`}
            value={group.group._id}
            checked={selectedParticipantGroups.includes(group.group._id)}
            onChange={handleParticipantGroupsChange}
          />
          <Typography>{group.group.name}</Typography>
        </Box>
      </Box>
    );
  };

  const AdminRolesCheckBox = (val, text) => {
    return (
      <Box className="checkBoxItem" key={`adminrole-${val}`}>
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            name={`adminrole-${val}`}
            value={val}
            checked={selectedAdminRoles.includes(val)}
            onChange={handleAdminRolesChange}
          />
          <Typography>{text}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: allDropVal3 === "Add Page" ? "flex" : "none" }}
        className="companyPop AddCardPopPeople "
      >
        <Box
          sx={{ display: allDropVal3 === "Add Page" ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className="companyHeader">
            Add Page ({projectName})
          </Typography>

          <Box className="basicBoxItem">
            <Typography className="psNlabel">Page Name</Typography>
            <Box className="pickerBox">
              <input
                className="eTemInput"
                name="title"
                placeholder="Enter page name"
                value={projectData.title ?? ""}
                onChange={setFormData}
              />
              <span className="validationerr">
                {validationErrors.title.msg}
              </span>
            </Box>
          </Box>

          <Box className="basicBoxItem">
            <Typography className="psNlabel">
              Upload a cover image for the page
            </Typography>
            <Box
              className="mediaInputbox upload-area"
              onClick={() => {
                document.getElementById("fileInput").click();
              }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <img src={image ? image : uploadIcon} />
              <Typography>
                Click or drag an image to this area to upload
              </Typography>
              <input
                id="fileInput"
                style={{ display: "none" }}
                type="file"
                onChange={handleFileInputChange}
              />
            </Box>
          </Box>

          <Box className="basicBoxItem addPageParticipantAccess">
            <Typography className="psNlabel">Participant Access:</Typography>

            <FormControl sx={{ width: "100%" }}>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                className="statusBox MuiBox-root css-0"
                value={
                  projectData?.participant_access || "Select Participant Access"
                }
                onChange={handleParticipantAccessChange}
                name="participant_access"
              >
                {ParticipantItem.length > 0 &&
                  ParticipantItem.map((el, i) => (
                    <MenuItem value={el.value} key={i}>
                      {el.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <span className="validationerr">
              {validationErrors.participant_access.msg}
            </span>
          </Box>

          {projectData?.participant_access === "selected_participants" && (
            <Box className="searchGroupSection">
              <Box
                pl={4.5}
                className="inputAppDrop"
                onClick={() => setGroupDrop2(!groupDrop2)}
              >
                <img className="groupsIcon" src={groupsIcon} />
                <Typography>
                  {t("pageSettingPop.participantGroupPlaceholder")}
                </Typography>
                <img
                  style={{ rotate: groupDrop2 ? "180deg" : "0deg" }}
                  src={dropArrow}
                />
              </Box>
              <Box
                sx={{ height: groupDrop2 ? "auto" : "0px" }}
                className="checkMain_box"
              >
                <Box className="ckeckBoxRoom">
                  {groupsListData.length > 0 &&
                    groupsListData.map((group, index) =>
                      chackBoxRoom({ group, index })
                    )}
                </Box>
                <Box className="propertyPagination">
                  <Box>
                    <p>1</p>
                  </Box>
                  <Box>
                    <p>2</p>
                  </Box>
                  <Box>
                    <p>3</p>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          <Box className="basicBoxItem addPageParticipantAccess">
            <Typography className="psNlabel">Administrator Access:</Typography>
            <FormControl sx={{ width: "100%" }}>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                className="statusBox MuiBox-root css-0"
                value={
                  projectData?.administrator_access ||
                  "Select Administrator Access"
                }
                onChange={handleAdminAccessChange}
                name="administrator_access"
              >
                {AdministratorItem.length > 0 &&
                  AdministratorItem.map((el2, i) => (
                    <MenuItem value={el2.value} key={i}>
                      {el2.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <span className="validationerr">
              {validationErrors.administrator_access.msg}
            </span>
          </Box>

          {projectData?.administrator_access === "selected_roles" &&
            loading && (
              <Box className="checkMain_box adminRoleCheckBox">
                <Box className="ckeckBoxRoom">
                  {administratorRoles.length > 0 &&
                    administratorRoles.map((el, i) =>
                      AdminRolesCheckBox(el.value, el.name)
                    )}
                  {/* {AdminRolesCheckBox('allresearcher','All Researchers')}
                  {AdminRolesCheckBox('allcollaborators','All Collaborators')}
                  {AdminRolesCheckBox('allobservers','All Observers')} */}
                </Box>
              </Box>
            )}

          {projectData?.administrator_access === "selected_admins" && (
            <Box className="searchGroupSection">
              <FormControl sx={{ width: "100%" }}>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  className="statusBox MuiBox-root css-0"
                  value={
                    projectData.administrator_ids || "Select Administrator"
                  }
                  onChange={(e) =>
                    setProjectData({
                      ...projectData,
                      administrator_ids: e.target.value,
                    })
                  }
                  multiple
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return (
                        <Box pl={4.5} className="inputAppDrop">
                          <Typography>Select admins</Typography>
                        </Box>
                      );
                    }
                    return selected
                      .map((value) => {
                        const user = adminsList.find(
                          (usr) => usr._id === value
                        );
                        return user ? user.name : value;
                      })
                      .join(", ");
                  }}
                >
                  {adminsList.length > 0 &&
                    adminsList.map((el, i) => (
                      <MenuItem value={el._id} key={i}>
                        {el.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          )}

          <Box className="workPupBtnBox">
            <AppBtnOutLine
              buttonText="Cancel"
              onClick={() => setAllDropVal3("Page Options")}
            />
            <AppBtn buttonText="Add Page" onClick={() => addProjectPage()} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
