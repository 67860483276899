import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { useTranslation } from "react-i18next";
import "./style.css";
//images
import iIcon2 from "../../../Assets/Images/IIcon.png";
import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png";
import backArrow from "../../../Assets/Images/noun-back.png";
import xCircel from "../../../Assets/Images/x-circle.png";
import redTrash from "../../../Assets/Images/redTrash.png";
import groupsIcon from "../../../Assets/Images/groupsIcon.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";


export default function AddFillOutTaskPop({ setAllDropVal4, addFillOutTaskPop, setAddFillOutTaskPop, handelAddTaskPopBack }) {

  const email1 = "Type the question/instructions to be visible"

  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();
  const [image, setImage] = useState(null);
  const [checkTaskSettingCount, setCheckTaskSettingCount] = useState(true);
  const [radioBankType, setRadioBankType] = useState(0);
  const [chooseCount, setChooseCount] = useState(1);
  const [groupDrop, setGroupDrop] = useState(false);
  const [groupDrop2, setGroupDrop2] = useState(false);
  const [redio, setRedio] = useState(0);
  const [checkTaskSettingImageCount, setCheckTaskSettingImageCount] = useState(true);
  const [chooseCount2, setChooseCount2] = useState(1);
  const [scaleDrop, setScaleDrop] = useState(false);
  const [scaleDropVal, setScaleDropVal] = useState(false);
  const [labelTypeDrop, setLabelTypeDrop] = useState(false);
  const [labelTypeDropVal, setLabelTypeDropVal] = useState(false);
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
  };

  const chackBoxRoom = () => {
    return (
      <Box className="checkBoxItem">
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            checked={redio === 0 ? true : false}
          />
          <Typography>Group 0</Typography>
        </Box>
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            checked={redio === 0 ? true : false}
          />
          <Typography>Group 0</Typography>
        </Box>
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            checked={redio === 0 ? true : false}
          />
          <Typography>Group 0</Typography>
        </Box>
      </Box>
    );
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
       setAddFillOutTaskPop(false);
    }
  };

  const handelCancelPop = () => {
    setAddFillOutTaskPop(false);
    setAllDropVal4("Add Tasks");
  }

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const ChooseSection = ({ count }) => {
    return (
      <>
        <p style={{ marginTop: "20px", marginBottom: "10px" }}>Choice {count * 1 + 1}</p>
        <Box className="choiceInputBox">
          <input className="phoneInput" placeholder="Enter choice 1" />
          <img className="xCircel" src={xCircel} />
          <img className="redTrash" src={redTrash} onClick={handelDeleteAttribute} />
        </Box>

        <Box className="searchGroupSection">
        <Typography>Add to Group(s)</Typography>
          <Box
            pl={4.5}
            className="inputAppDrop"
            onClick={() => setGroupDrop(!groupDrop)}
          >
            <img className="groupsIcon" src={groupsIcon} />
            <Typography>Search Groups</Typography>
            <img
              style={{ rotate: groupDrop ? "180deg" : "0deg" }}
              src={dropArrow}
            />
          </Box>
          <Box
            sx={{ height: groupDrop ? "auto" : "0px" }}
            className="checkMain_box"
          >
            <Box className="ckeckBoxRoom">
              {chackBoxRoom()}
              {chackBoxRoom()}
              {chackBoxRoom()}
            </Box>
            <Box className="propertyPagination">
              <Box>
                <p>1</p>
              </Box>
              <Box>
                <p>2</p>
              </Box>
              <Box>
                <p>3</p>
              </Box>
              <Box>
                <p>4</p>
              </Box>
              <Box>
                <p>...</p>
              </Box>
              <Box>
                <p>8</p>
              </Box>
              <Box>
                <p>9</p>
              </Box>
              <Box>
                <p>10</p>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="searchGroupSection">
        <Typography>Remove from Group(s)</Typography>
          <Box
            pl={4.5}
            className="inputAppDrop"
            onClick={() => setGroupDrop2(!groupDrop2)}
          >
            <img className="groupsIcon" src={groupsIcon} />
            <Typography>Search Groups</Typography>
            <img
              style={{ rotate: groupDrop2 ? "180deg" : "0deg" }}
              src={dropArrow}
            />
          </Box>
          <Box
            sx={{ height: groupDrop2 ? "auto" : "0px" }}
            className="checkMain_box"
          >
            <Box className="ckeckBoxRoom">
              {chackBoxRoom()}
              {chackBoxRoom()}
              {chackBoxRoom()}
            </Box>
            <Box className="propertyPagination">
              <Box>
                <p>1</p>
              </Box>
              <Box>
                <p>2</p>
              </Box>
              <Box>
                <p>3</p>
              </Box>
              <Box>
                <p>4</p>
              </Box>
              <Box>
                <p>...</p>
              </Box>
              <Box>
                <p>8</p>
              </Box>
              <Box>
                <p>9</p>
              </Box>
              <Box>
                <p>10</p>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  }

  const ChooseSection2 = ({ count2 }) => {
    return (
      <>
          <input className="phoneInput inputChoiceItems" placeholder="Define an item or statement" />
      </>
    )
  }


  const renderComponents = (value) => {
    let components = [];
    for (let i = 0; i < value; i++) {
      components.push(<ChooseSection count={i} key={i} />);
    }
    return components;
  };


  const handelDeleteAttribute = () => {
    setChooseCount(chooseCount - 1)
  }
  const handelAddAttribute = () => {
    setChooseCount(chooseCount + 1)
  }

  const renderScaleComponents = (value2) => {
    let components2 = [];
    for (let j = 0; j < value2; j++) {
      components2.push(<ChooseSection2 count2={j} key={j} />);
    }
    return components2;
  };

  const handelAddScaleAttribute = () => {
    setChooseCount2(chooseCount2 + 1)
  }

  useEffect(() => {
    if (addFillOutTaskPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [addFillOutTaskPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: addFillOutTaskPop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople "
      >
        <Box
          sx={{ display: addFillOutTaskPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Box onClick={handelAddTaskPopBack} className="openBtn taskBackBtn">
            <img src={backArrow} />
            <Typography className="neonBackText">{t("commonTxt.backBtn")}</Typography>
            </Box>

          <Typography className="taskPopMainLabel">{t("FillItOutTaskPop.FillItOutTaskHead")}</Typography>
          <Box className="iBox">
              <img src={iIcon2} />
              <Typography>
              {t("FillItOutTaskPop.FillItOutTaskNotify")}
              </Typography>
            </Box>
          <Box className="basicBoxItem">
            <Typography className='eTemPopLabel '>{t("addTextTaskPop.taskNameLabel")}</Typography>
            <Box className="pickerBox cardTitleLabel">
              <input className='eTemInput' placeholder={t("addTextTaskPop.taskNamePlaceholder")} />
            </Box>
          </Box>

          <Box style={{ marginBottom: "24px" }} className="basicBoxItem">
            <Typography className='eTemPopLabel '>{t("FillItOutTaskPop.blankTypeHead")}</Typography>
                <Box className="radioBox">
                    <Box>
                    <input
                        className="emailSettingInput emailSettingRadio"
                        type='radio'
                        name='blankType'
                        defaultChecked
                        onClick={() => setRadioBankType(0)}
                    />
                    </Box>
                    <Box>
                    <Typography style={{ marginTop: "18px" }} className="emailSettingLabel">{t("FillItOutTaskPop.plainTextRadio")}</Typography>
                    <Typography className="sublabel">{t("FillItOutTaskPop.plainTextSubText")}</Typography>
                    </Box>
                </Box>
                <Box className="radioBox">
                    <Box>
                    <input
                        className="emailSettingInput emailSettingRadio"
                        type='radio'
                        name='blankType'
                        onClick={() => setRadioBankType(1)}
                    />
                    </Box>
                    <Box>
                    <Typography style={{ marginTop: "18px" }} className="emailSettingLabel">{t("FillItOutTaskPop.pollRadio")}</Typography>
                    <Typography className="sublabel">{t("FillItOutTaskPop.pollSubText")}</Typography>
                    </Box>
                </Box>
                <Box className="radioBox">
                    <Box>
                    <input
                        className="emailSettingInput emailSettingRadio"
                        type='radio'
                        name='blankType'
                        onClick={() => setRadioBankType(2)}
                    />
                    </Box>
                    <Box>
                    <Typography style={{ marginTop: "18px" }} className="emailSettingLabel">{t("commonTxt.images")}</Typography>
                    <Typography className="sublabel">{t("FillItOutTaskPop.imageSubText")}</Typography>
                    </Box>
                </Box>
                <Box className="radioBox">
                    <Box>
                    <input
                        className="emailSettingInput emailSettingRadio"
                        type='radio'
                        name='blankType'
                        onClick={() => setRadioBankType(3)}
                    />
                    </Box>
                    <Box>
                    <Typography style={{ marginTop: "18px" }} className="emailSettingLabel">{t("groups.scale")}</Typography>
                    <Typography className="sublabel">{t("FillItOutTaskPop.scaleSubText")}</Typography>
                    </Box>
                </Box>
                <Box className="radioBox">
                    <Box>
                    <input
                        className="emailSettingInput emailSettingRadio"
                        type='radio'
                        name='blankType'
                        onClick={() => setRadioBankType(4)}
                    />
                    </Box>
                    <Box>
                    <Typography style={{ marginTop: "18px" }} className="emailSettingLabel">{t("FillItOutTaskPop.scaleAllocation")}</Typography>
                    <Typography className="sublabel">{t("FillItOutTaskPop.scaleAllocationSubText")}</Typography>
                    </Box>
                </Box>
          </Box>

          <Typography className='eTemPopLabel '>{t("LiveInterview.questionLabel")}</Typography>
          <Box className="emailInputBox textTaskInstruction">
            <Box className="emailInputBoxNav">
              <Box
                onClick={() => setMessageTextItalic(!messageTextItalic)}
                className="emailImgBox"
              >
                <img src={IIcon} />
              </Box>
              <Box
                onClick={() => setMessageTextBold(!messageTextBold)}
                className="emailImgBox"
              >
                <img src={BIcon} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("underline", "message")}
                className="emailImgBox"
              >
                <img src={UnderLineU} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("line-through", "message")}
                className="emailImgBox"
              >
                <img src={MLineU} />
              </Box>
              <Box className="emailImgBox">
                <img src={AtachMentIcon} />
              </Box>
              <Box className="emailImgBox">
                <img src={Qbox} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("start")}
                className="emailImgBox"
              >
                <img src={Line1} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("center")}
                className="emailImgBox"
              >
                <img src={Line2} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("end")}
                className="emailImgBox"
              >
                <img src={Line3} />
              </Box>
              <Box className="emailImgBox">
                <img src={Line4} />
              </Box>
              <Box className="emailImgBox">
                <img src={emoji} />
              </Box>
            </Box>
            <textarea
              style={{
                textAlign: messageTextLaOut,
                fontStyle: messageTextItalic ? "italic" : "normal",
                fontWeight: messageTextBold ? "bold" : "normal",
                textDecoration: messageTextUnderLine,
                // textDecoration: messageTextMLine ? "line-through" : null
              }}
              className="emailInput"
              placeholder={t("addTextTaskPop.taskDescriptionPlaceholder")}
              name="emailMessage"
              value={emailval?.emailMessage || email1}
              onChange={handelChange}
            />
          </Box>

          <Box className="basicBoxItem">
            <Box className='mediaInputbox upload-area taskUoloadImageBox' onClick={() => {
                document.getElementById("fileInput").click();
            }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}>
                <img src={uploadIcon} />
                <Typography>{t("commonTxt.uploadText")}</Typography>
                <input
                id="fileInput"
                style={{ display: "none" }}
                type="file"
                onChange={handleFileInputChange}
                />
            </Box>
          </Box>

        {/* Plain Text */} 
        <Box sx={{ display: radioBankType === 0 ? "block" : "none" }}>
          <Typography className='textTaskSetting '>{t("addTextTaskPop.taskSettingsHead")}</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" onClick={() => setCheckTaskSettingCount(!checkTaskSettingCount)} defaultChecked />
            <Typography>{t("FillItOutTaskPop.enforceCharacterLimit")}</Typography>
          </Box>
          <Box sx={{ display: checkTaskSettingCount ? "flex" : "none" }} ml={4} className="enforceInputBox">
            <input defaultValue="20" className="phoneInput" />
            <p>{t("addTextTaskPop.to")}</p>
            <input defaultValue="100" className="phoneInput" />
            <p>{t("addTextTaskPop.words")}</p>
          </Box>
          
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("addTextTaskPop.TextFormatting")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("addTextTaskPop.responseRequired")}</Typography>
          </Box>

          <Typography className='textTaskSetting '>{t("addTextTaskPop.advanceSettingsHead")}</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("addTextTaskPop.advanceSettingsCheckbox1")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("addTextTaskPop.advanceSettingsCheckbox2")} </Typography>
          </Box>
        </Box>
        {/* Plain Text */}

        {/* Poll */}
        <Box sx={{ display: radioBankType === 1 ? "block" : "none" }}>
        <Typography className="allPartRedioLabel">{t("LiveInterview.choicesHead")}</Typography>
          {renderComponents(chooseCount)}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "24px 0",
            }}
          >
            <Typography className="ppundText" onClick={handelAddAttribute}>{t("FillItOutTaskPop.addChoiceLink")}</Typography>
            <Typography className="ppundText">{t("commonTxt.pasteBulkChoicesLink")}</Typography>
          </Box>

          <Typography className='textTaskSetting '>{t("addTextTaskPop.taskSettingsHead")}</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("LiveInterview.pollSettingCheckbox1")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("LiveInterview.pollSettingCheckbox2")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("LiveInterview.pollSettingCheckbox3")}</Typography>
          </Box>

          <Typography className='textTaskSetting '>{t("addTextTaskPop.advanceSettingsHead")}</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("addTextTaskPop.advanceSettingsCheckbox1")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("addTextTaskPop.advanceSettingsCheckbox2")} </Typography>
          </Box>
        </Box>
        {/* Poll */}

        {/* Images */}
        <Box sx={{ display: radioBankType === 2 ? "block" : "none" }}>
          <Typography className='textTaskSetting '>{t("addTextTaskPop.taskSettingsHead")}</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" onClick={() => setCheckTaskSettingImageCount(!checkTaskSettingImageCount)} defaultChecked />
            <Typography>{t("FillItOutTaskPop.taskSettingsCheckbox1")}</Typography>
          </Box>
          <Box sx={{ display: checkTaskSettingImageCount ? "flex" : "none" }} ml={4} className="enforceInputBox">
            <input defaultValue="1" className="phoneInput" />
            <p>{t("addTextTaskPop.to")}</p>
            <input defaultValue="3" className="phoneInput" />
            <p>{t("addTextTaskPop.words")}</p>
          </Box>
          
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("addImageTaskPop.taskSettingsCheckbox2")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("addTextTaskPop.responseRequired")}</Typography>
          </Box>

          <Typography className='textTaskSetting '>{t("addTextTaskPop.advanceSettingsHead")}</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("addTextTaskPop.advanceSettingsCheckbox1")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("addTextTaskPop.advanceSettingsCheckbox2")} </Typography>
          </Box>
        </Box>
        {/* Images */}

        {/* Scale */}
        <Box sx={{ display: radioBankType === 3 ? "block" : "none" }}>
        <Typography className="allPartRedioLabel">{t("recapScaleFillItOut.tblItems")}</Typography>
        <Box className="choiceInputBox">
          {renderScaleComponents(chooseCount2)}
        </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "24px 0",
            }}
          >
            <Typography className="ppundText" onClick={handelAddScaleAttribute}>{t("FillItOutTaskPop.addChoiceLink")}</Typography>
            <Typography className="ppundText">{t("commonTxt.pasteBulkChoicesLink")}</Typography>
          </Box>
          <Typography className="allPartRedioLabel">{t("FillItOutTaskPop.scaleSetup")}</Typography>
            <Box mb={2} className="advanceFilterRowOne fillOutTaskScaleSetup">
              <Box className="inputBoxWlabel taskScaleSetup">
                <Typography className="inputLabel">{t("groups.scale")}</Typography>
                <Box
                  className="inputAppDrop"
                  onClick={() => setScaleDrop(!scaleDrop)}
                >
                  <Typography>
                    {scaleDropVal || t("FillItOutTaskPop.selectScalePlaceholder")}
                  </Typography>
                  <img
                    style={{ rotate: scaleDrop ? "180deg" : "0deg" }}
                    src={dropArrow}
                  />
                  {/* pop */}
                  <Box
                    sx={{ height: scaleDrop ? "auto" : "0px" }}
                    className="statusPop"
                  >
                    <Box
                      onClick={() => setScaleDropVal("1 - point scale")}
                      className="statusPopItem"
                    >
                      <Typography>1 - point scale</Typography>
                    </Box>
                    <Box
                      onClick={() => setScaleDropVal("2 - point scale")}
                      className="statusPopItem"
                    >
                      <Typography>2 - point scale</Typography>
                    </Box>
                    <Box
                      onClick={() => setScaleDropVal("3 - point scale")}
                      className="statusPopItem"
                    >
                      <Typography>3 - point scale</Typography>
                    </Box>
                    <Box
                      onClick={() => setScaleDropVal("4 - point scale")}
                      className="statusPopItem"
                    >
                      <Typography>4 - point scale</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="inputBoxWlabel taskScaleSetup">
                <Typography className="inputLabel">{t("FillItOutTaskPop.labelType")}</Typography>

                <Box
                  className="inputAppDrop"
                  onClick={() => setLabelTypeDrop(!labelTypeDrop)}
                >
                  <Typography>{labelTypeDropVal || t("FillItOutTaskPop.labelTypePlaceholder")}</Typography>
                  <img
                    style={{ rotate: labelTypeDrop ? "180deg" : "0deg" }}
                    src={dropArrow}
                  />
                  {/* pop */}
                  <Box
                    sx={{ height: labelTypeDrop ? "auto" : "0px" }}
                    className="statusPop"
                  >
                    <Box
                      onClick={() => setLabelTypeDropVal("Agreement")}
                      className="statusPopItem"
                    >
                      <Typography>Agreement</Typography>
                    </Box>
                    <Box
                      onClick={() => setLabelTypeDropVal("Awareness")}
                      className="statusPopItem"
                    >
                      <Typography>Awareness</Typography>
                    </Box>
                    <Box
                      onClick={() => setLabelTypeDropVal("Consideration")}
                      className="statusPopItem"
                    >
                      <Typography>Consideration</Typography>
                    </Box>
                    <Box
                      onClick={() => setLabelTypeDropVal("Familiarity")}
                      className="statusPopItem"
                    >
                      <Typography>Familiarity</Typography>
                    </Box>
                    <Box
                      onClick={() => setLabelTypeDropVal("Importance")}
                      className="statusPopItem"
                    >
                      <Typography>Importance</Typography>
                    </Box>
                    <Box
                      onClick={() => setLabelTypeDropVal("Likelihood")}
                      className="statusPopItem"
                    >
                      <Typography>Likelihood</Typography>
                    </Box>
                    <Box
                      onClick={() => setLabelTypeDropVal("Quality")}
                      className="statusPopItem"
                    >
                      <Typography>Quality</Typography>
                    </Box>
                    <Box
                      onClick={() => setLabelTypeDropVal("Satisfaction")}
                      className="statusPopItem"
                    >
                      <Typography>Satisfaction</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="inputBoxWlabel"></Box>
            </Box>
            
              <Box className="inputBoxWlabel">
                <Typography className="inputLabel">{t("recapMatrixPage.tblLabels")}</Typography>
                <Box className="choiceInputBox">
                  <Box className="taskScaleLabels"><samp>1. </samp><input className="phoneInput inputChoiceItems" defaultValue="Strongly Disagree" /></Box>
                  <Box className="taskScaleLabels"><samp>2. </samp><input className="phoneInput inputChoiceItems" defaultValue="Disagree" /></Box>
                  <Box className="taskScaleLabels"><samp>3. </samp><input className="phoneInput inputChoiceItems" defaultValue="Neural" /></Box>
                  <Box className="taskScaleLabels"><samp>4. </samp><input className="phoneInput inputChoiceItems" defaultValue="Agree" /></Box>
                  <Box className="taskScaleLabels"><samp>5. </samp><input className="phoneInput inputChoiceItems" defaultValue="Strongly Agree" /></Box>
                </Box>
              </Box>
            
          <Typography className='textTaskSetting '>{t("addTextTaskPop.taskSettingsHead")}</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("FillItOutTaskPop.randomizeItems")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("addTextTaskPop.responseRequired")}</Typography>
          </Box>

          <Typography className='textTaskSetting '>{t("addTextTaskPop.advanceSettingsHead")}</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("addTextTaskPop.advanceSettingsCheckbox1")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("addTextTaskPop.advanceSettingsCheckbox2")}</Typography>
          </Box>
        </Box>
        {/* Scale */}

        {/* Scale Allocation */}
        <Box sx={{ display: radioBankType === 4 ? "block" : "none" }}>
        <Typography className="allPartRedioLabel">{t("recapScaleFillItOut.tblItems")}</Typography>
        <Box className="choiceInputBox">
          {renderScaleComponents(chooseCount2)}
        </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "24px 0",
            }}
          >
            <Typography className="ppundText" onClick={handelAddScaleAttribute}>{t("FillItOutTaskPop.addChoiceLink")}</Typography>
            <Typography className="ppundText">{t("commonTxt.pasteBulkChoicesLink")}</Typography>
          </Box>
          <Typography className="allPartRedioLabel">{t("FillItOutTaskPop.scaleSetup")}</Typography>
            <Box mb={2} className="advanceFilterRowOne fillOutTaskScaleSetup">
              <Box className="inputBoxWlabel taskScaleSetup">
                <Typography className="inputLabel">{t("FillItOutTaskPop.scaleSetup")}</Typography>
                <Box
                  className="inputAppDrop"
                  onClick={() => setScaleDrop(!scaleDrop)}
                >
                  <Typography>
                    {scaleDropVal || t("FillItOutTaskPop.presentPlaceholder")}
                  </Typography>
                  <img
                    style={{ rotate: scaleDrop ? "180deg" : "0deg" }}
                    src={dropArrow}
                  />
                  {/* pop */}
                  <Box
                    sx={{ height: scaleDrop ? "auto" : "0px" }}
                    className="statusPop"
                  >
                    <Box
                      onClick={() => setScaleDropVal("Custom")}
                      className="statusPopItem"
                    >
                      <Typography>Custom</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="inputBoxWlabel">
                <Typography className="inputLabel">{t("FillItOutTaskPop.requiredAllocationLabel")}</Typography>
                <input className="phoneInput" placeholder="" defaultValue={"0"} />
              </Box>
              <Box className="inputBoxWlabel">
                <Typography className="inputLabel">{t("FillItOutTaskPop.scaleLimitLabel")}</Typography>
                <input className="phoneInput" placeholder="" defaultValue={"100"} />
              </Box>
            </Box>
            <Box mb={2} className="advanceFilterRowOne fillOutTaskScaleSetup">
              <Box className="inputBoxWlabel">
                <Typography className="inputLabel">{t("FillItOutTaskPop.totalValueLabel")}</Typography>
                <input className="phoneInput" placeholder={t("FillItOutTaskPop.totalValuePlaceholder")} />
              </Box>
              <Box className="inputBoxWlabel">
                <Typography className="inputLabel">{t("FillItOutTaskPop.prefix")}</Typography>
                <input className="phoneInput" placeholder="Eg. $" />
              </Box>
              <Box className="inputBoxWlabel">
                <Typography className="inputLabel">{t("FillItOutTaskPop.suffix")}</Typography>
                <input className="phoneInput" placeholder="Eg. /month" />
              </Box>
            </Box>
            
          <Typography className='textTaskSetting '>{t("addTextTaskPop.taskSettingsHead")}</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("FillItOutTaskPop.randomizeItems")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("addTextTaskPop.responseRequired")}</Typography>
          </Box>

          <Typography className='textTaskSetting '>{t("addTextTaskPop.advanceSettingsHead")}</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("addTextTaskPop.advanceSettingsCheckbox1")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("addTextTaskPop.advanceSettingsCheckbox2")}</Typography>
          </Box>
        </Box>
        {/* Scale Allocation */}

          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={handelCancelPop} />
            <AppBtnOutLine buttonText={t("commonTxt.savePreviewBtn")} onClick={handelAddTaskPopBack} />
            <AppBtn buttonText={t("commonTxt.saveTaskBtn")} />
          </Box>



        </Box>
      </Box>
    </>
  );
}
