import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "../../../lib/axios";
import "./style.css";
import { AntSwitch } from "../../AppTools/AppTool";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import { getConfigSettings } from "../../../Services/master";
import { updateConfigSettings } from "../../../Services/adminActions";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { useAppSelector } from "../../../lib/store/store";
import searchIcon from "../../../Assets/Images/search.png";
import chackBox from "../../../Assets/Images/Checkbox.png";
import check from "../../../Assets/Images/check.png";
import { AppBtn } from "../../AppTools/AppButton";

export default function FileConfiguration() {
  const [imageAcceptFormatDrop, setImageAcceptFormatDrop] = useState(false);
  const [imageAcceptFormatDropVal, setImageAcceptFormatDropVal] = useState("");
  const [videoAcceptFormatDrop, setVideoAcceptFormatDrop] = useState(false);
  const [videoAcceptFormatDropVal, setVideoAcceptFormatDropVal] = useState("");
  const [audioAcceptFormatDrop, setAudioAcceptFormatDrop] = useState(false);
  const [audioAcceptFormatDropVal, setAudioAcceptFormatDropVal] = useState("");
  const [documentAcceptFormatDrop, setDocumentAcceptFormatDrop] =
    useState(false);
  const [documentAcceptFormatDropVal, setDocumentAcceptFormatDropVal] =
    useState("");
  const [spreadAcceptFormatDrop, setSpreadAcceptFormatDrop] = useState(false);
  const [spreadAcceptFormatDropVal, setSpreadAcceptFormatDropVal] =
    useState("");
  const [pdfAcceptFormatDrop, setPdfAcceptFormatDrop] = useState(false);
  const [pdfAcceptFormatDropVal, setPdfAcceptFormatDropVal] = useState("");
  const [presentationAcceptFormatDrop, setPresentationAcceptFormatDrop] =
    useState(false);
  const [presentationAcceptFormatDropVal, setPresentationAcceptFormatDropVal] =
    useState("");

  const [loading, setLoading] = useState(false);
  const [fileFormats, setFileFormats] = useState([]);
  const [typeDrop, setTypeDrop] = useState(false);
  const authState = useAppSelector((state) => state.auth);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const [fileConfig, setFileConfig] = useState({
    image_size: "",
    image_format: [],
    video_size: "",
    video_format: [],
    audio_size: "",
    audio_format: [],
    document_size: "",
    document_format: [],
    spreadsheet_size: "",
    spreadsheet_format: [],
    pdf_size: "",
    pdf_format: [],
    presentation_size: "",
    presentation_format: [],
  });

  useEffect(() => {
    getFileFormats();
    fetchConfig();
  }, []);

  useEffect(() => {}, [fileConfig]);

  const getFileFormats = async () => {
    try {
      const response = await axios.get(`/master/file-accepted-formats`);
      if (response.success) {
        const formats = response.data?.data;
        setFileFormats(formats);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  const setFormData = (e) => {
    let newState = {
      ...fileConfig,
      [e.target.name]: e.target.value,
    };
    setFileConfig(newState);
  };

  const updateSetting = async () => {
    const payload = {
      name: "file_config",
      value: fileConfig,
      is_visible: true,
    };

    console.log("payload", payload);

    try {
      const companyRes = await updateConfigSettings(
        payload,
        authState.authToken
      );
      setLoading(false);
      if (companyRes.success) {
        toast.success(companyRes.message, TOAST_CONFIG);
        fetchConfig();
      } else {
        toast.error(companyRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  const selectAllCheckData = (key, filed) => {
    if (fileConfig[key]?.length >= fileFormats.length) return false;

    let selectedItems = [];
    fileFormats.map((item) => {
      if (Array.isArray(filed)) {
        if (filed.includes(item.type)) selectedItems.push(item.name);
      } else {
        if (item.type == filed) selectedItems.push(item.name);
      }
    });

    setFileConfig({ ...fileConfig, [key]: selectedItems });
  };

  const deleteAllCheckData = (key) => {
    if (fileConfig[key]?.length == 0) return false;
    setFileConfig({ ...fileConfig, [key]: [] });
  };

  const getSelectedDropVal = (key) => {
    let dropdownLabel = "";
    if (fileConfig[key].length <= 2) {
      fileConfig[key].map((val, index) => {
        if (index > 0) {
          dropdownLabel += ", ";
        }
        dropdownLabel += val;
      });
    } else {
      dropdownLabel = fileConfig[key].length + " Selected";
    }
    return dropdownLabel;
  };

  const pushData = (field = "image_format", type) => {
    let fileTypes = [...fileConfig[field], type];
    setFileConfig({ ...fileConfig, [field]: fileTypes });
  };

  const fetchConfig = async () => {
    try {
      const response = await getConfigSettings({ name: "file_config" });
      if (response.success) {
        if (response.data.data.length > 0) {
          const fileSettings = response.data.data[0];
          // console.log("fileSettings", fileSettings)
          let sateData = {};
          for (let key in fileConfig) {
            if (fileSettings?.value[key]) {
              sateData[key] = fileSettings?.value[key];
            }
          }
          setFileConfig(sateData);
        }
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  return (
    <>
      <Box className="EmailSContainer">
        <Typography className="pageHeaderText">
          {t("fileConfiguration.title")}
        </Typography>

        <Box className="fileConfigHead">
          <Typography className="">{t("commonTxt.images")}</Typography>
        </Box>
        <Box className="basicBox lastBasicBox">
          <Box className="inputBox">
            <Typography className="emailSettingLabel">
              {t("fileConfiguration.maxSizeAcceptable")}
            </Typography>
            <input
              className="emailSettingInput"
              placeholder="Enter max limit"
              name="image_size"
              onChange={setFormData}
              value={fileConfig?.image_size}
            />
          </Box>
          <Box className="inputBox">
            <Typography className="emailSettingLabel">
              {t("fileConfiguration.acceptableFormats")}
            </Typography>
            <input
              className="emailSettingInput securityInput"
              placeholder="Choose file types"
              onClick={() => setTypeDrop(!typeDrop)}
              value={getSelectedDropVal("image_format")}
            />

            <img
              style={{ rotate: typeDrop ? "180deg" : "0deg" }}
              className="downArrowPop"
              src={DownArrowIcon}
              onClick={() => setTypeDrop(!typeDrop)}
            />

            <Box
              sx={{
                height: typeDrop ? "280px" : "0px",
                maxHeight: fileFormats ? "300px" : "0px",
                overflowY: fileFormats ? "scroll" : "hidden",
              }}
              className="categoryDrop researcherDrop"
            >
              <Box className="researchDropTopLabel">
                <Typography
                  onClick={() => selectAllCheckData("image_format", "image")}
                >
                  Select All
                </Typography>
                <Typography onClick={() => deleteAllCheckData("image_format")}>
                  Deselect All
                </Typography>
              </Box>

              {fileFormats &&
                fileFormats.map((type) =>
                  type.type === "image" ? (
                    <Box
                      onClick={() => {
                        if (
                          fileConfig.image_format.some((el) => el === type.name)
                        ) {
                          setFileConfig({
                            ...fileConfig,
                            image_format: fileConfig.image_format.filter(
                              (item) => item !== type.name
                            ),
                          });
                        } else {
                          pushData("image_format", type.name);
                        }
                      }}
                      className="categoryDropItem"
                    >
                      <Box className="chackBox">
                        <img className="chackBoxImg" src={chackBox} />
                        <img
                          style={{
                            display: fileConfig.image_format.some(
                              (el) => el === type.name
                            )
                              ? "block"
                              : "none",
                          }}
                          className="chack"
                          src={check}
                        />
                      </Box>
                      <Typography>{type.name}</Typography>
                    </Box>
                  ) : null
                )}
            </Box>
          </Box>
        </Box>

        <Box className="fileConfigHead">
          <Typography className="">{t("commonTxt.videos")}</Typography>
        </Box>
        <Box className="basicBox lastBasicBox">
          <Box className="inputBox">
            <Typography className="emailSettingLabel">
              {t("fileConfiguration.maxAcceptableSize")}
            </Typography>
            <input
              className="emailSettingInput"
              placeholder="Enter max limit"
              name="video_size"
              onChange={setFormData}
              value={fileConfig?.video_size}
            />
          </Box>
          <Box className="inputBox">
            <Typography className="emailSettingLabel">
              {t("fileConfiguration.acceptableFormats")}
            </Typography>
            <input
              className="emailSettingInput securityInput"
              placeholder="Choose file types"
              onClick={() => setVideoAcceptFormatDrop(!videoAcceptFormatDrop)}
              value={getSelectedDropVal("video_format")}
            />

            <img
              style={{ rotate: videoAcceptFormatDrop ? "180deg" : "0deg" }}
              className="downArrowPop"
              src={DownArrowIcon}
              onClick={() => setVideoAcceptFormatDrop(!videoAcceptFormatDrop)}
            />

            <Box
              sx={{
                height: videoAcceptFormatDrop ? "280px" : "0px",
                maxHeight: fileFormats ? "300px" : "0px",
                overflowY: fileFormats ? "scroll" : "hidden",
              }}
              className="categoryDrop researcherDrop"
            >
              <Box className="researchDropTopLabel">
                <Typography
                  onClick={() => selectAllCheckData("video_format", "video")}
                >
                  Select All
                </Typography>
                <Typography onClick={() => deleteAllCheckData("video_format")}>
                  Deselect All
                </Typography>
              </Box>

              {fileFormats &&
                fileFormats.map((type) =>
                  type.type === "video" ? (
                    <Box
                      onClick={() => {
                        if (
                          fileConfig.video_format.some((el) => el === type.name)
                        ) {
                          setFileConfig({
                            ...fileConfig,
                            video_format: fileConfig.video_format.filter(
                              (item) => item !== type.name
                            ),
                          });
                        } else {
                          pushData("video_format", type.name);
                        }
                      }}
                      className="categoryDropItem"
                    >
                      <Box className="chackBox">
                        <img className="chackBoxImg" src={chackBox} />
                        <img
                          style={{
                            display: fileConfig.video_format.some(
                              (el) => el === type.name
                            )
                              ? "block"
                              : "none",
                          }}
                          className="chack"
                          src={check}
                        />
                      </Box>
                      <Typography>{type.name}</Typography>
                    </Box>
                  ) : null
                )}
            </Box>
          </Box>
        </Box>

        <Box className="fileConfigHead">
          <Typography className="">{t("fileConfiguration.audios")}</Typography>
        </Box>
        <Box className="basicBox lastBasicBox">
          <Box className="inputBox">
            <Typography className="emailSettingLabel">
              {t("fileConfiguration.maxAcceptableSize")}
            </Typography>
            <input
              className="emailSettingInput"
              placeholder="Enter max limit"
              name="audio_size"
              onChange={setFormData}
              value={fileConfig?.audio_size}
            />
          </Box>
          <Box className="inputBox">
            <Typography className="emailSettingLabel">
              {t("fileConfiguration.acceptableFormats")}
            </Typography>
            <input
              onClick={() => setAudioAcceptFormatDrop(!audioAcceptFormatDrop)}
              // value={audioAcceptFormatDropVal}
              className="emailSettingInput securityInput"
              placeholder="Select all the formats acceptable"
              value={getSelectedDropVal("audio_format")}
            />

            <img
              style={{ rotate: audioAcceptFormatDrop ? "180deg" : "0deg" }}
              onClick={() => setAudioAcceptFormatDrop(!audioAcceptFormatDrop)}
              className="downArrowFileConfig"
              src={DownArrowIcon}
            />

            <Box
              sx={{
                height: audioAcceptFormatDrop ? "280px" : "0px",
                maxHeight: fileFormats ? "300px" : "0px",
                overflowY: fileFormats ? "scroll" : "hidden",
              }}
              className="categoryDrop researcherDrop"
            >
              <Box className="researchDropTopLabel">
                <Typography
                  onClick={() => selectAllCheckData("audio_format", "audio")}
                >
                  Select All
                </Typography>
                <Typography onClick={() => deleteAllCheckData("audio_format")}>
                  Deselect All
                </Typography>
              </Box>

              {fileFormats &&
                fileFormats.map((type) =>
                  type.type === "audio" ? (
                    <Box
                      onClick={() => {
                        if (
                          fileConfig.audio_format.some((el) => el === type.name)
                        ) {
                          setFileConfig({
                            ...fileConfig,
                            audio_format: fileConfig.audio_format.filter(
                              (item) => item !== type.name
                            ),
                          });
                        } else {
                          pushData("audio_format", type.name);
                        }
                      }}
                      className="categoryDropItem"
                    >
                      <Box className="chackBox">
                        <img className="chackBoxImg" src={chackBox} />
                        <img
                          style={{
                            display: fileConfig.audio_format.some(
                              (el) => el === type.name
                            )
                              ? "block"
                              : "none",
                          }}
                          className="chack"
                          src={check}
                        />
                      </Box>
                      <Typography>{type.name}</Typography>
                    </Box>
                  ) : null
                )}
            </Box>
          </Box>
        </Box>

        <Box className="fileConfigHead">
          <Typography className="">
            {t("fileConfiguration.documents")}
          </Typography>
        </Box>
        <Box className="basicBox lastBasicBox">
          <Box className="inputBox">
            <Typography className="emailSettingLabel">
              {t("fileConfiguration.maxAcceptableSize")}
            </Typography>
            <input
              className="emailSettingInput"
              placeholder="Enter max limit"
              name="document_size"
              onChange={setFormData}
              value={fileConfig?.document_size}
            />
          </Box>
          <Box className="inputBox">
            <Typography className="emailSettingLabel">
              {t("fileConfiguration.acceptableFormats")}
            </Typography>
            <input
              onClick={() =>
                setDocumentAcceptFormatDrop(!documentAcceptFormatDrop)
              }
              value={getSelectedDropVal("document_format")}
              className="emailSettingInput securityInput"
              placeholder="Select all the formats acceptable"
            />

            <img
              style={{ rotate: documentAcceptFormatDrop ? "180deg" : "0deg" }}
              onClick={() =>
                setDocumentAcceptFormatDrop(!documentAcceptFormatDrop)
              }
              className="downArrowFileConfig"
              src={DownArrowIcon}
            />

            <Box
              sx={{
                height: documentAcceptFormatDrop ? "280px" : "0px",
                maxHeight: fileFormats ? "300px" : "0px",
                overflowY: fileFormats ? "scroll" : "hidden",
              }}
              className="categoryDrop researcherDrop"
            >
              <Box className="researchDropTopLabel">
                <Typography
                  onClick={() =>
                    selectAllCheckData("document_format", [
                      "presentation",
                      "pdf",
                      "spreadsheet",
                      "image",
                    ])
                  }
                >
                  Select All
                </Typography>
                <Typography
                  onClick={() => deleteAllCheckData("document_format")}
                >
                  Deselect All
                </Typography>
              </Box>

              {fileFormats &&
                fileFormats.map((type) =>
                  ["presentation", "pdf", "spreadsheet", "image"].includes(
                    type.type
                  ) ? (
                    <Box
                      onClick={() => {
                        if (
                          fileConfig.document_format.some(
                            (el) => el === type.name
                          )
                        ) {
                          setFileConfig({
                            ...fileConfig,
                            document_format: fileConfig.document_format.filter(
                              (item) => item !== type.name
                            ),
                          });
                        } else {
                          pushData("document_format", type.name);
                        }
                      }}
                      className="categoryDropItem"
                    >
                      <Box className="chackBox">
                        <img className="chackBoxImg" src={chackBox} />
                        <img
                          style={{
                            display: fileConfig.document_format.some(
                              (el) => el === type.name
                            )
                              ? "block"
                              : "none",
                          }}
                          className="chack"
                          src={check}
                        />
                      </Box>
                      <Typography>{type.name}</Typography>
                    </Box>
                  ) : null
                )}
            </Box>
          </Box>
        </Box>

        <Box className="fileConfigHead">
          <Typography className="">
            {t("fileConfiguration.spreadSheet")}
          </Typography>
        </Box>
        <Box className="basicBox lastBasicBox">
          <Box className="inputBox">
            <Typography className="emailSettingLabel">
              {t("fileConfiguration.maxAcceptableSize")}
            </Typography>
            <input
              className="emailSettingInput"
              placeholder="Enter max limit"
              name="spreadsheet_size"
              onChange={setFormData}
              value={fileConfig?.spreadsheet_size}
            />
          </Box>
          <Box className="inputBox">
            <Typography className="emailSettingLabel">
              {t("fileConfiguration.acceptableFormats")}
            </Typography>
            <input
              onClick={() => setSpreadAcceptFormatDrop(!spreadAcceptFormatDrop)}
              value={getSelectedDropVal("spreadsheet_format")}
              className="emailSettingInput securityInput"
              placeholder="Select all the formats acceptable"
            />
            <img
              style={{ rotate: spreadAcceptFormatDrop ? "180deg" : "0deg" }}
              onClick={() => setSpreadAcceptFormatDrop(!spreadAcceptFormatDrop)}
              className="downArrowFileConfig"
              src={DownArrowIcon}
            />

            <Box
              sx={{
                height: spreadAcceptFormatDrop ? "280px" : "0px",
                maxHeight: fileFormats ? "300px" : "0px",
                overflowY: fileFormats ? "scroll" : "hidden",
              }}
              className="categoryDrop researcherDrop"
            >
              <Box className="researchDropTopLabel">
                <Typography
                  onClick={() =>
                    selectAllCheckData("spreadsheet_format", "spreadsheet")
                  }
                >
                  Select All
                </Typography>
                <Typography
                  onClick={() => deleteAllCheckData("spreadsheet_format")}
                >
                  Deselect All
                </Typography>
              </Box>

              {fileFormats &&
                fileFormats.map((type) =>
                  type.type === "spreadsheet" ? (
                    <Box
                      onClick={() => {
                        if (
                          fileConfig.spreadsheet_format.some(
                            (el) => el === type.name
                          )
                        ) {
                          setFileConfig({
                            ...fileConfig,
                            spreadsheet_format:
                              fileConfig.spreadsheet_format.filter(
                                (item) => item !== type.name
                              ),
                          });
                        } else {
                          pushData("spreadsheet_format", type.name);
                        }
                      }}
                      className="categoryDropItem"
                    >
                      <Box className="chackBox">
                        <img className="chackBoxImg" src={chackBox} />
                        <img
                          style={{
                            display: fileConfig.spreadsheet_format.some(
                              (el) => el === type.name
                            )
                              ? "block"
                              : "none",
                          }}
                          className="chack"
                          src={check}
                        />
                      </Box>
                      <Typography>{type.name}</Typography>
                    </Box>
                  ) : null
                )}
            </Box>
          </Box>
        </Box>

        <Box className="fileConfigHead">
          <Typography className="">PDFs</Typography>
        </Box>
        <Box className="basicBox lastBasicBox">
          <Box className="inputBox">
            <Typography className="emailSettingLabel">
              {t("fileConfiguration.maxAcceptableSize")}
            </Typography>
            <input
              className="emailSettingInput"
              placeholder="Enter max limit"
              name="pdf_size"
              onChange={setFormData}
              value={fileConfig?.pdf_size}
            />
          </Box>
          <Box className="inputBox">
            <Typography className="emailSettingLabel">
              {t("fileConfiguration.acceptableFormats")}
            </Typography>
            <input
              onClick={() => setPdfAcceptFormatDrop(!pdfAcceptFormatDrop)}
              value={getSelectedDropVal("pdf_format")}
              className="emailSettingInput securityInput"
              placeholder="Select all the formats acceptable"
            />
            <img
              style={{ rotate: pdfAcceptFormatDrop ? "180deg" : "0deg" }}
              onClick={() => setPdfAcceptFormatDrop(!pdfAcceptFormatDrop)}
              className="downArrowFileConfig"
              src={DownArrowIcon}
            />

            <Box
              sx={{
                height: pdfAcceptFormatDrop ? "280px" : "0px",
                maxHeight: fileFormats ? "300px" : "0px",
                overflowY: fileFormats ? "scroll" : "hidden",
              }}
              className="categoryDrop researcherDrop"
            >
              <Box className="researchDropTopLabel">
                <Typography
                  onClick={() => selectAllCheckData("pdf_format", "pdf")}
                >
                  Select All
                </Typography>
                <Typography onClick={() => deleteAllCheckData("pdf_format")}>
                  Deselect All
                </Typography>
              </Box>

              {fileFormats &&
                fileFormats.map((type) =>
                  type.type === "pdf" ? (
                    <Box
                      onClick={() => {
                        if (
                          fileConfig.pdf_format.some((el) => el === type.name)
                        ) {
                          setFileConfig({
                            ...fileConfig,
                            pdf_format: fileConfig.pdf_format.filter(
                              (item) => item !== type.name
                            ),
                          });
                        } else {
                          pushData("pdf_format", type.name);
                        }
                      }}
                      className="categoryDropItem"
                    >
                      <Box className="chackBox">
                        <img className="chackBoxImg" src={chackBox} />
                        <img
                          style={{
                            display: fileConfig.pdf_format.some(
                              (el) => el === type.name
                            )
                              ? "block"
                              : "none",
                          }}
                          className="chack"
                          src={check}
                        />
                      </Box>
                      <Typography>{type.name}</Typography>
                    </Box>
                  ) : null
                )}
            </Box>
          </Box>
        </Box>

        <Box className="fileConfigHead">
          <Typography className="">
            {t("fileConfiguration.presentations")}
          </Typography>
        </Box>
        <Box className="basicBox lastBasicBox">
          <Box className="inputBox">
            <Typography className="emailSettingLabel">
              {t("fileConfiguration.maxAcceptableSize")}
            </Typography>
            <input
              className="emailSettingInput"
              placeholder="Enter max limit"
              name="presentation_size"
              onChange={setFormData}
              value={fileConfig?.presentation_size}
            />
          </Box>
          <Box className="inputBox">
            <Typography className="emailSettingLabel">
              {t("fileConfiguration.acceptableFormats")}
            </Typography>
            <input
              onClick={() =>
                setPresentationAcceptFormatDrop(!presentationAcceptFormatDrop)
              }
              value={getSelectedDropVal("presentation_format")}
              className="emailSettingInput securityInput"
              placeholder="Select all the formats acceptable"
            />
            <img
              style={{
                rotate: presentationAcceptFormatDrop ? "180deg" : "0deg",
              }}
              onClick={() =>
                setPresentationAcceptFormatDrop(!presentationAcceptFormatDrop)
              }
              className="downArrowFileConfig"
              src={DownArrowIcon}
            />

            <Box
              sx={{
                height: presentationAcceptFormatDrop ? "280px" : "0px",
                maxHeight: fileFormats ? "300px" : "0px",
                overflowY: fileFormats ? "scroll" : "hidden",
              }}
              className="categoryDrop researcherDrop"
            >
              <Box className="researchDropTopLabel">
                <Typography
                  onClick={() =>
                    selectAllCheckData("presentation_format", [
                      "presentation",
                      "pdf",
                      "spreadsheet",
                      "image",
                    ])
                  }
                >
                  Select All
                </Typography>
                <Typography
                  onClick={() => deleteAllCheckData("presentation_format")}
                >
                  Deselect All
                </Typography>
              </Box>

              {fileFormats &&
                fileFormats.map((type) =>
                  ["presentation", "pdf", "spreadsheet", "image"].includes(
                    type.type
                  ) ? (
                    <Box
                      onClick={() => {
                        if (
                          fileConfig.presentation_format.some(
                            (el) => el === type.name
                          )
                        ) {
                          setFileConfig({
                            ...fileConfig,
                            presentation_format:
                              fileConfig.presentation_format.filter(
                                (item) => item !== type.name
                              ),
                          });
                        } else {
                          pushData("presentation_format", type.name);
                        }
                      }}
                      className="categoryDropItem"
                    >
                      <Box className="chackBox">
                        <img className="chackBoxImg" src={chackBox} />
                        <img
                          style={{
                            display: fileConfig.presentation_format.some(
                              (el) => el === type.name
                            )
                              ? "block"
                              : "none",
                          }}
                          className="chack"
                          src={check}
                        />
                      </Box>
                      <Typography>{type.name}</Typography>
                    </Box>
                  ) : null
                )}
            </Box>
          </Box>
        </Box>
        <Box className="languageBtnBox">
          <AppBtn buttonText={`Update`} onClick={updateSetting} />
        </Box>
      </Box>
    </>
  );
}
