import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.css";

//images

//data
import { sideHaumIcon } from "../../../Assets/Data";

//components
import SideMenu from "../../../Components/Observer/SideMenu";
import ProjectManagementSetting from "../../../Components/Observer/ProjectManagementSetting";
import ProjectCodeBook from "../../../Components/Observer/ProjectCodeBook";
import ProjectSettingTranscript from "../../../Components/Observer/ProjectSettingTranscript";
import ProjectDirectory from "../../../Components/Observer/ProjectDirectory";
import ProjectSettingReports from "../../../Components/Observer/ProjectSettingReports";

export default function ProjectManagement({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
}) {
  const [projectTabe, setProjectTabe] = useState(0);
  const [navOption, setNavOption] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  useEffect(() => {
    let selTab = searchParams.get("dr");
    if (selTab != null) {
      setProjectTabe(parseInt(selTab));
    } else {
      setProjectTabe(0);
    }
  }, []);

  return (
    <>
      <Box className="ResearchersContainer ">
        {/* side menu */}
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>

            <Box className="projectNavItems">
              <Box
                onClick={() => {
                  setSearchParams({ dr: 0 });
                  setProjectTabe(0);
                }}
                className={
                  projectTabe === 0
                    ? "recapNavItem recapNavItemActive"
                    : "recapNavItem"
                }
              >
                <Typography>
                  {t("projectManegementPage.projectManegementNav1")}
                </Typography>
              </Box>

              <Box
                onClick={() => {
                  setSearchParams({ dr: 1 });
                  setProjectTabe(1);
                }}
                className={
                  projectTabe === 1
                    ? "recapNavItem recapNavItemActive mNav1"
                    : "recapNavItem mNav1"
                }
              >
                <Typography>
                  {t("projectManegementPage.projectManegementNav2")}
                </Typography>
              </Box>

              <Box className="vrLine"> </Box>
              <Box
                onClick={() => {
                  setSearchParams({ dr: 2 });
                  setProjectTabe(2);
                }}
                className={
                  projectTabe === 2
                    ? "recapNavItem recapNavItemActive mNav2"
                    : "recapNavItem  mNav2"
                }
              >
                <Typography>
                  {t("projectManegementPage.projectManegementNav6")}
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  setSearchParams({ dr: 3 });
                  setProjectTabe(3);
                }}
                className={
                  projectTabe === 3
                    ? "recapNavItem recapNavItemActive mNav2"
                    : "recapNavItem  mNav2"
                }
              >
                <Typography>
                  {t("projectManegementPage.projectManegementNav7")}
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  setSearchParams({ dr: 4 });
                  setProjectTabe(4);
                }}
                className={
                  projectTabe === 4
                    ? "recapNavItem recapNavItemActive mNav3"
                    : "recapNavItem  mNav3"
                }
              >
                <Typography>
                  {t("projectManegementPage.projectManegementNav8")}
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* Page container */}
          {projectTabe === 0 ? (
            <ProjectManagementSetting
              dataReportTabe={projectTabe}
              setDataReportTabe={setProjectTabe}
            />
          ) : projectTabe === 1 ? (
            <ProjectDirectory
              dataReportTabe={projectTabe}
              setDataReportTabe={setProjectTabe}
            />
          ) : projectTabe === 2 ? (
            <ProjectCodeBook />
          ) : projectTabe === 3 ? (
            <ProjectSettingTranscript
              dataReportTabe={projectTabe}
              setDataReportTabe={setProjectTabe}
            />
          ) : projectTabe === 4 ? (
            <ProjectSettingReports
              dataReportTabe={projectTabe}
              setDataReportTabe={setProjectTabe}
            />
          ) : null}
        </Box>
      </Box>
    </>
  );
}
