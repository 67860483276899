import React, { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//style
import "./Assets/Style/style.css";
import "./Assets/Style/style copy.css";
import "./Assets/Style/style copy 2.css";

//Not Found Page
import NotFound from "./Pages/NotFound";
import ScreenQuestion from "./Pages/ScreenQuestion";
import Registration from "./Pages/Registration";
import Enforce2Fa from "./Pages/Enforce2Fa";

//Researchers
import Notification from "./Pages/Researchers/Notification";
import Dashboard from "./Pages/Researchers/Dashboard";
import Publications from "./Pages/Researchers/Publications";
import PublicationView from "./Pages/Researchers/PublicationView";
import WorkspaceHome from "./Pages/Researchers/WorkspaceHome";
import Activities from "./Pages/Researchers/Activities/index";
import ActivityTasks from "./Pages/Researchers/ActivityTasks";
import ParticipantsRespons from "./Pages/Researchers/ParticipantsRespons";
import ViewResponse from "./Pages/Researchers/ViewResponse";
import EntiteActivity from "./Pages/Researchers/EntiteActivity";
import InterviewScheduled from "./Pages/Researchers/InterviewScheduled";
import LiveChatScheduled from "./Pages/Researchers/LiveChatScheduled";
import Conversations from "./Pages/Researchers/Conversations";
import ViewConversationCategory from "./Pages/Researchers/ViewConversationCategory";
import ProjectManagement from "./Pages/Researchers/ProjectManagement";
import Recap from "./Pages/Researchers/Recap";
import ProjectVideoClip from "./Pages/Researchers/ProjectVideoClip";
import ViewConversationTopic from "./Pages/Researchers/ViewConversationTopic";
import RecapImgReviewPage from "./Pages/Researchers/RecapImgReviewPage";
import RecapPollMulltiPage from "./Pages/Researchers/RecapPollMulltiPage";
import RecapFillitOut from "./Pages/Researchers/RecapFillitOut";
import RecapAllocation from "./Pages/Researchers/RecapAllocation";
import RecapPoll2Page from "./Pages/Researchers/RecapPoll2Page";
import RecapRankShort from "./Pages/Researchers/RecapRankShort";
import RecapMatrixTask from "./Pages/Researchers/RecapMatrixTask";
import LiveGroupMeeting from "./Pages/Researchers/LiveGroupMeeting";
import RecapVideoReview from "./Pages/Researchers/RecapVideoReview";
import RecapAudioReview from "./Pages/Researchers/RecapAudioReview";
import LiveInterviewMeeting from "./Pages/Researchers/LiveInterviewMeeting";
import ViewParticipantProfile from "./Pages/Researchers/ViewParticipantProfile";
import ViewMessageProfile from "./Pages/Researchers/ViewMessageProfile";
import DataAndReport from "./Pages/Researchers/DataAndReport";
import ViewRecapImage from "./Pages/Researchers/ViewRecapImage";
import ViewRecapVideo from "./Pages/Researchers/ViewRecapVideo";
import ViewRecapAudio from "./Pages/Researchers/ViewRecapAudio";

// Account Setting
import PersonalInformation from "./Pages/AccountSetting/PersonalInformation";
import PasswordManagement from "./Pages/AccountSetting/PasswordManagement";
import Authentication from "./Pages/AccountSetting/Authentication";
import TimeZone from "./Pages/AccountSetting/TimeZone";
import EmailNotification from "./Pages/AccountSetting/EmailNotification";
import Agreement from "./Pages/AccountSetting/Agreement";
import Integrations from "./Pages/AccountSetting/Integrations";
import ContactTicket from "./Pages/AccountSetting/ContactTicket";
import SafetyPrivacy from "./Pages/AccountSetting/SafetyPrivacy";

// Workspace Administration
import AllParticipants from "./Pages/WorkspaceAdministration/AllParticipants";
import AddParticipantsResearcher from "./Components/WSAdministration/AddParticipantsResearcher";
import EditParticipantResearcher from "./Components/WSAdministration/EditParticipantsResearcher";
import Groups from "./Pages/WorkspaceAdministration/Groups";
import ParticipantProfiling from "./Pages/WorkspaceAdministration/ParticipantProfiling";
import BulkImport from "./Pages/WorkspaceAdministration/BulkImport";
import Information from "./Pages/WorkspaceAdministration/Information";
import BasicSetting from "./Pages/WorkspaceAdministration/BasicSettings";
import Subscription from "./Pages/WorkspaceAdministration/Subscription";
import Account from "./Pages/WorkspaceAdministration/Account";
import EmailPage from "./Pages/WorkspaceAdministration/Email";
import SiteNotice from "./Pages/WorkspaceAdministration/SiteNotice";
import Appearance from "./Pages/WorkspaceAdministration/Appearance";
import SiteLog from "./Pages/WorkspaceAdministration/SiteLog";
import Agreements from "./Pages/WorkspaceAdministration/Agreements";
import Template from "./Pages/WorkspaceAdministration/Template";
import WSAdmin from "./Pages/WorkspaceAdministration/Admin";

//login
import OTPVerify from "./Pages/UserAuth/OTP Verify";
import PwdFEmail from "./Pages/UserAuth/PwdFEmail";
import NewPwd from "./Pages/UserAuth/NewPwd";
import ReduxProvider from "./lib/store/redux-provider";
import WorkSpaceManagement from "./Pages/Admin/Workspacemanagement";
import Ai_Integration from "./Pages/Admin/AiIntegartion/Index";
import AdminLayout from "./Pages/Admin/partials/AdminLayout";
import Reports from "./Pages/Admin/Reports&Publications";
import Company from "./Pages/Admin/UserDirectory/Company";
import SuperAdmin from "./Pages/Admin/UserDirectory/SuperAdmins";
import Users from "./Pages/Admin/UserDirectory/Users";
import EmailTemplate from "./Pages/Admin/Templates/EmailTemplate";
import AgreementTemplate from "./Pages/Admin/Templates/AgreementTemplate";
import DirectMessageAdmin from "./Pages/Admin/DirectMessage/DirectMessageAdmin";
import PlatformLogPage from "./Pages/Admin/SystemSettings/PlatformLogPage";
import ActionLogPage from "./Pages/Admin/SystemSettings/ActionLogPage";
import EmailSettingsPage from "./Pages/Admin/SystemSettings/EmailSettingsPage";
import FileConfigPage from "./Pages/Admin/SystemSettings/FileConfigPage";
import ApperancePage from "./Pages/Admin/SystemSettings/ApperancePage";
import LanguagePage from "./Pages/Admin/SystemSettings/LanguagePage";
import ApiCredentialPage from "./Pages/Admin/SystemSettings/ApiCredentialPage";
import ContactTicketPage from "./Pages/Admin/SystemSettings/ContactsTicketPage";
import EmailManagementPage from "./Pages/Admin/SystemSettings/EmailManageMentPage";
import Home from "./Pages/Admin/Workspacemanagement/Home/Home";
import WorkspaceManagementSplash from "./Pages/Admin/Workspacemanagement/WorkspaceManagementSplash/WorkspaceManagementSplash";
import AiIntegrationPage from "./Pages/Admin/Workspacemanagement/WorkSpaceAiIntegration/AiIntegrationPage";

import ResearcherAiIntegration from "./Pages/Researchers/ResearcherAiIntegration";
import ExistingParticipants from "./Pages/Researchers/ExisitngParticipants";
import ProjectManagementParticipant from "./Components/Researchers/ProjectManagementParticipant";
import AddProjectParticipants from "./Pages/Researchers/AddProjectParticipants";
import ProjectParticipantScreening from "./Pages/Researchers/ProjectParticipantScreening";
import ProjectParticipantBulkImport from "./Pages/Researchers/ProjectParticipantBulkImport";
import ProjectParticipantMailInvitation from "./Pages/Researchers/ProjectParticipantMailInvitation";
import ProjectParticipantLinkInvitation from "./Pages/Researchers/ProjectParticipantLinkInvitation";
import Invations from "./Pages/Admin/Invations";

//Participants
import P_Activities from "./Pages/Participants/Activities";
import P_ActivityJourrnal from "./Pages/Participants/ActivityJourrnal";
import UpdatePwd from "./Pages/UserAuth/UpdatePwd";
import P_OntheDot from "./Pages/Participants/OntheDot";
import P_OntheDotStart from "./Pages/Participants/OnTheDotStart";
import P_ImageActivity from "./Pages/Participants/ImageActivity";
import P_AudioActivity from "./Pages/Participants/AudioActivity";
import P_VideoActivity from "./Pages/Participants/VideoActivity";

import AddEnhancedGroup from "./Components/WSAdministration/AddEnhancedGroup";
import P_ScreenCaptureActivity from "./Pages/Participants/ScreenCaptureActivity";
import P_ImageTask from "./Pages/Participants/ReviewImageTask";
import P_VideoTask from "./Pages/Participants/ReviewVideoTask";
import AddStandardGroupResearcher from "./Components/WSAdministration/AddStandardGroupResearcher";
import AddProfileField from "./Components/WSAdministration/AddProfileField";
import P_ActivityGuidance from "./Pages/Participants/ActivityGuidance";
import P_MatrixTask from "./Pages/Participants/MatrixTask";
import P_FillItOutTask from "./Pages/Participants/FillItOutTask";
import P_RankItOrSortItTask from "./Pages/Participants/RankItOrSortIt";

import ResearchersLogin from "./Pages/Researchers/Auth/ResearchersLogin";

import P_WorkspaceItem from "./Pages/Participants/WorkspaceItem";
import P_WorkspaceHome from "./Pages/Participants/WorkspaceHome";
import P_Conversations from "./Pages/Participants/Conversations";
import P_ViewConversationCategory from "./Pages/Participants/ViewConversationCategory";
import P_ViewConversationTopic from "./Pages/Participants/ViewConversationTopic";
import P_PollAndSurvey from "./Pages/Participants/PollAndSurvey";
import P_AnyFileUpload from "./Pages/Participants/AnyFileUpload";
import P_ReviewAudio from "./Pages/Participants/ReviewAudioTask";
import P_MixedMediaTask from "./Pages/Participants/MixedMedia";
import P_InterviewScheduled from "./Pages/Participants/InterviewScheduled";
import P_LiveInterviewMeeting from "./Pages/Participants/LiveInterviewMeeting";
import P_LiveChatScheduled from "./Pages/Participants/LiveChatScheduled";
import P_LiveGroupMeeting from "./Pages/Participants/LiveGroupMeeting";
import P_Recap from "./Pages/Participants/Recap";
import P_ViewRecapImage from "./Pages/Participants/ViewRecapImage";
import P_ViewRecapVideo from "./Pages/Participants/ViewRecapVideo";
import P_ViewRecapAudio from "./Pages/Participants/ViewRecapAudio";
import P_RecapImgReviewPage from "./Pages/Participants/P_RecapImgReviewPage";
import P_RecapVideoReview from "./Pages/Participants/P_RecapVideoReview";
import P_RecapAudioReview from "./Pages/Participants/P_RecapAudioReview";
import P_RecapPollMulltiPage from "./Pages/Participants/RecapPollMulltiPage";
import P_RecapFillitOut from "./Pages/Participants/RecapFillitOut";
import P_RecapPoll2Page from "./Pages/Participants/RecapPoll2Page";
import P_RecapMatrixTask from "./Pages/Participants/RecapMatrixTask";
import P_RecapAllocation from "./Pages/Participants/RecapAllocation";
import P_RecapRankShort from "./Pages/Participants/RecapRankShort";
import P_SafetyPrivacy from "./Pages/ParticipantAccountSetting/SafetyPrivacy";
import P_ContactTicket from "./Pages/ParticipantAccountSetting/ContactTicket";
import P_EmailManagement from "./Pages/ParticipantAccountSetting/EmailManagement";
import P_ActivityTask from "./Pages/Participants/ActivityTask";
import P_TextTask from "./Pages/Participants/TextTask";

//Observer
import O_WorkspaceHome from "./Pages/Observer/WorkspaceHome";
import O_Activities from "./Pages/Observer/Activities";
import O_ActivityTasks from "./Pages/Observer/ActivityTasks";
import O_ViewParticipantProfile from "./Pages/Observer/ViewParticipantProfile";
import O_ParticipantsRespons from "./Pages/Observer/ParticipantsRespons";
import O_EntiteActivity from "./Pages/Observer/EntiteActivity";
import SettingStandardGroupPage from "./Pages/Admin/Workspacemanagement/Participants/SettingStandardGroupPage";
import O_Recap from "./Pages/Observer/Recap";
import O_ViewRecapImage from "./Pages/Observer/ViewRecapImage";
import O_ViewRecapVideo from "./Pages/Observer/ViewRecapVideo";
import SettingEnhancedGroupPage from "./Pages/Admin/Workspacemanagement/Participants/SettingEnhancedGroupPage";

import O_ProjectManagement from "./Pages/Observer/ProjectManagement";
import O_Conversations from "./Pages/Observer/Conversations";
import O_ViewConversationTopic from "./Pages/Observer/ViewConversationTopic";
import O_ViewConversationCategory from "./Pages/Observer/ViewConversationCategory";
import O_ViewRecapAudio from "./Pages/Observer/ViewRecapAudio";

import O_Dashboard from "./Pages/Observer/Dashboard";

//Collaborator
import C_Dashboard from "./Pages/Collaborator/Dashboard";

import C_WorkspaceItem from "./Pages/Collaborator/WorkspaceItem";
import C_WorkspaceHome from "./Pages/Collaborator/WorkspaceHome";
import C_Activities from "./Pages/Collaborator/Activities/index";
import C_ActivityTasks from "./Pages/Collaborator/ActivityTasks";
import C_ParticipantsRespons from "./Pages/Collaborator/ParticipantsRespons";
import C_ViewResponse from "./Pages/Collaborator/ViewResponse";
import C_Conversations from "./Pages/Collaborator/Conversations";
import C_ViewConversationCategory from "./Pages/Collaborator/ViewConversationCategory";
import C_EntiteActivity from "./Pages/Collaborator/EntiteActivity";
import C_ProjectManagement from "./Pages/Collaborator/ProjectManagement";
import C_ProjectVideoClip from "./Pages/Collaborator/ProjectVideoClip";
import C_ProjectManagementParticipant from "./Components/Collaborator/ProjectManagementParticipant";
import C_AddProjectParticipants from "./Pages/Collaborator/AddProjectParticipants";
import C_ProjectParticipantScreening from "./Pages/Collaborator/ProjectParticipantScreening";
import C_ProjectParticipantBulkImport from "./Pages/Collaborator/ProjectParticipantBulkImport";
import C_ProjectParticipantMailInvitation from "./Pages/Collaborator/ProjectParticipantMailInvitation";
import C_ProjectParticipantLinkInvitation from "./Pages/Collaborator/ProjectParticipantLinkInvitation";
import C_ExistingParticipants from "./Pages/Collaborator/ExisitngParticipants";
import C_Recap from "./Pages/Collaborator/Recap";
import C_ViewRecapAudio from "./Pages/Collaborator/ViewRecapAudio";
import C_ViewRecapImage from "./Pages/Collaborator/ViewRecapImage";
import C_ViewRecapVideo from "./Pages/Collaborator/ViewRecapVideo";

//Collaborator ac

import TwoFactorAuthVerify from "./Pages/UserAuth/TwoFactorAuth";
import AdminInvitations from "./Pages/Admin/AdminInvitations";
import LoginOTPVerify from "./Pages/UserAuth/loginOtpVerify";

export default function App() {
  const Navigate = useNavigate();
  const [slideMenu, setSlideMenu] = useState(true);
  const [workspaceDrop, setWorkSpaceDrop] = useState(true);

  const [selectNavItem, setSelectNavItem] = useState("Thread");

  return (
    <ReduxProvider>
      <Routes>
        <Route
          path="/"
          element={
            <div
              style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h2
                onClick={() => Navigate("/admin/login")}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  paddingTop: "50px",
                }}
              >
                Go admin page
              </h2>
              <h2
                onClick={() => Navigate("/users/login")}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  paddingTop: "50px",
                }}
              >
                Go researchers page
              </h2>
              <h2
                onClick={() => Navigate("/users/login")}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  paddingTop: "50px",
                }}
              >
                Participants page
              </h2>
              <h2
                onClick={() => Navigate("/users/login")}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  paddingTop: "50px",
                }}
              >
                Observer page
              </h2>
              <h2
                onClick={() => Navigate("/users/login")}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  paddingTop: "50px",
                }}
              >
                Collaborator page
              </h2>

              <h3
                style={{
                  textAlign: "center",
                  paddingTop: "50px",
                  textDecoration: "underline",
                }}
              >
                Update: 16-July-2024
              </h3>
            </div>
          }
        />

        {/* ----------------------------------------Auth Routes-------------------------------------------------- */}
        <Route path="/:usersegment/login" element={<ResearchersLogin />} />
        <Route path="/:usersegment/send-email" element={<PwdFEmail />} />
        <Route path="/:usersegment/otp" element={<OTPVerify />} />
        <Route path="/:usersegment/new-password" element={<NewPwd />} />
        <Route path="/:usersegment/update-password" element={<UpdatePwd />} />
        <Route
          path="/:usersegment/2fa-verify"
          element={<TwoFactorAuthVerify />}
        />
        <Route
          path="/:usersegment/login-otp-verify"
          element={<LoginOTPVerify />}
        />

        {/* ---------------------------------------Super Admin-------------------------------------------- */}

        {/* <Route path="/admin" element={<AdminHome />} /> */}
        {/* <Route path="/researchers0" element={<Researchers />} /> */}

        {/* ----------------------------------------ADMIN TABS-------------------------------------------------- */}
        <Route
          path="/admin/workspace-management"
          element={<WorkSpaceManagement />}
        />

        <Route
          path="/admin/ai-integration"
          element={<AdminLayout childComponent={<Ai_Integration />} />}
        />

        <Route
          path="/admin/reports"
          element={<AdminLayout childComponent={<Reports />} />}
        />
        <Route
          path="/admin/userdirectory/company"
          element={<AdminLayout childComponent={<Company />} />}
        />
        <Route path="/admin/userdirectory/users" element={<Users />} />
        <Route
          path="/admin/userdirectory/super-admin"
          element={<SuperAdmin />}
        />
        <Route path="/admin/templates/email" element={<EmailTemplate />} />
        <Route
          path="/admin/templates/agreement"
          element={<AgreementTemplate />}
        />

        <Route path="/direct-messages" element={<DirectMessageAdmin />} />

        <Route path="/admin/system/platformlog" element={<PlatformLogPage />} />

        <Route path="/admin/system/actionlog" element={<ActionLogPage />} />
        <Route
          path="/admin/system/email-settings"
          element={<EmailSettingsPage />}
        />
        <Route
          path="/admin/system/files-configuration"
          element={<FileConfigPage />}
        />
        <Route path="/admin/system/appearance" element={<ApperancePage />} />
        <Route path="/admin/system/language" element={<LanguagePage />} />
        <Route
          path="/admin/system/api-credential"
          element={<ApiCredentialPage />}
        />
        <Route
          path="/admin/system/contact-tickets"
          element={<ContactTicketPage />}
        />
        <Route
          path="/admin/system/email-management"
          element={<EmailManagementPage />}
        />

        {/* ----------------------------------------ADMIN --> WorkSpaceManageMent Tabs/ -------------------------------------------------- */}
        <Route
          path="/admin/workspace-management/splash"
          element={<WorkspaceManagementSplash />}
        />
        <Route path="/admin/workspace-management/home" element={<Home />} />
        <Route
          path="/admin/ws-management/aiintegration"
          element={<AiIntegrationPage />}
        />
        {/* <Route
          path="/admin/workspace-management/setting_enhancedgroup"
          element={<GroupsPage />}
        /> */}
        <Route
          path="/admin/workspace-management/setting_stdgroup"
          element={<SettingStandardGroupPage />}
        />

        <Route
          path="/admin/workspace-management/setting_enhancedgroup"
          element={<SettingEnhancedGroupPage />}
        />

        {/* ---------------------------------------AccountSetting-------------------------------------------- */}
        <Route
          path="/account-settings/personal-information"
          element={<PersonalInformation />}
        />
        <Route
          path="/account-settings/manage-password"
          element={<PasswordManagement />}
        />
        <Route
          path="/admin/manage-password"
          element={<PasswordManagement />}
        />
        <Route path="/account-settings/2fa-auth" element={<Authentication />} />
        <Route path="/account-settings/time-zone" element={<TimeZone />} />
        <Route
          path="/account-settings/email-notification"
          element={<EmailNotification />}
        />
        <Route path="/account-settings/agreements" element={<Agreement />} />
        <Route
          path="/account-settings/integrations"
          element={<Integrations />}
        />
        <Route
          path="/account-settings/contact-ticket"
          element={<ContactTicket />}
        />
        <Route
          path="/account-settings/safety-privacy"
          element={<SafetyPrivacy />}
        />

        {/* ------------------------------------------WorkspaceAdministration----------------------------------------- */}
        <Route
          path="/workspace-administration/all-participants"
          element={<AllParticipants />}
        />

        <Route
          path="/workspace-administration/add-participants"
          element={<AddParticipantsResearcher />}
        />

        <Route
          path="/workspace-administration/edit-participants/:participant_Id"
          element={<EditParticipantResearcher />}
        />
        <Route path="/workspace-administration/group" element={<Groups />} />

        <Route
          path="/workspace-administration/enhanced-group"
          element={<AddEnhancedGroup />}
        />

        <Route
          path="/workspace-administration/standard-group"
          element={<AddStandardGroupResearcher />}
        />

        <Route
          path="/workspace-administration/participant-profiling"
          element={<ParticipantProfiling />}
        />

        <Route
          path="/workspace-administration/add-profileField"
          element={<AddProfileField />}
        />

        <Route
          path="/workspace-administration/Bulk-import"
          element={<BulkImport />}
        />

        <Route path="/workspace-administration/admin" element={<WSAdmin />} />

        <Route
          path="/workspace-administration/information"
          element={<Information />}
        />
        <Route
          path="/workspace-administration/basic-setting"
          element={<BasicSetting />}
        />
        <Route
          path="/workspace-administration/subscription"
          element={<Subscription />}
        />
        <Route path="/workspace-administration/account" element={<Account />} />
        <Route path="/workspace-administration/email" element={<EmailPage />} />
        <Route
          path="/workspace-administration/site-notice"
          element={<SiteNotice />}
        />
        <Route
          path="/workspace-administration/appearance"
          element={<Appearance />}
        />
        <Route
          path="/workspace-administration/site-log"
          element={<SiteLog />}
        />
        <Route path="/activity-logs" element={<ActionLogPage />} />
        <Route
          path="/workspace-administration/agreements"
          element={<Agreements />}
        />
        <Route
          path="/workspace-administration/template"
          element={<Template />}
        />

        {/* ----------------------------------------researchers-------------------------------------------------- */}

        {/* ----------------------------------------Researcher TABS-------------------------------------------------- */}
        <Route path="/users/dashboard" element={<Dashboard />} />
        <Route
          path="/researchers/Publications"
          element={
            <Publications
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />
        <Route
          path="/researchers/Publications-view"
          element={
            <PublicationView
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />
        <Route
          path="/notification"
          element={
            <Notification
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        <Route
          path="/ws-management/workspace-home"
          element={
            <WorkspaceHome
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />
        <Route
          path="/ws-management/aiintegration"
          element={
            <ResearcherAiIntegration
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />
        {/* <Route
          path="/researchers/activities"
          element={
            <Activities
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        /> */}
        <Route
          path="/activities"
          element={
            <Activities
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />
        {/* <Route
          path="/researchers/activities/task"
          element={
            <ActivityTasks
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        /> */}
        <Route
          path="/activities/task/:activityId"
          element={
            <ActivityTasks
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />
        {/* <Route
          path="/researchers/activities/task/view"
          element={
            <ParticipantsRespons
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        /> */}
        <Route
          path="/activities/task/view"
          element={
            <ParticipantsRespons
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />
        <Route
          path="/activities/participant-response-view"
          element={
            <O_ParticipantsRespons
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />
        {/* <Route
          path="/researchers/activities/task/outdoor"
          element={
            <ViewResponse
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        /> */}
        <Route
          path="/activities/task/outdoor"
          element={
            <ViewResponse
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />
        {/* <Route
          path="/researchers/activities/preview"
          element={
            <EntiteActivity
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        /> */}
        <Route
          path="/activities/preview/:activityId"
          element={
            <EntiteActivity
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />
        {/* <Route
          path="/researchers/activities/interview-scheduled"
          element={
            <InterviewScheduled
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        /> */}
        <Route
          path="/activities/interview-scheduled/:activityId"
          element={
            <InterviewScheduled
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        {/* <Route
          path="/researchers/activities/live-chat-scheduled"
          element={
            <LiveChatScheduled
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        /> */}
        <Route
          path="/activities/live-chat-scheduled"
          element={
            <LiveChatScheduled
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />
        {/* <Route
          path="/researchers/conversation"
          element={
            <Conversations
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        /> */}
        <Route
          path="/conversation"
          element={
            <Conversations
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />
        {/* <Route
          path="/researchers/recap"
          element={
            <Recap
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        /> */}
        <Route
          path="/recap"
          element={
            <Recap
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        {/* <Route
          path="/researchers/view-conversation-category"
          element={
            <ViewConversationCategory
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        /> */}
        <Route
          path="/view-conversation-category"
          element={
            <ViewConversationCategory
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />
        {/* <Route
          path="/researchers/recap/image-Review"
          element={
            <RecapImgReviewPage
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        /> */}
        <Route
          path="/recap/image-review"
          element={
            <RecapImgReviewPage
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />
        {/* <Route
          path="/researchers/recap/poll-mullti"
          element={
            <RecapPollMulltiPage
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        /> */}
        <Route
          path="/recap/poll-mullti"
          element={
            <RecapPollMulltiPage
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />
        {/* <Route
          path="/researchers/recap/fill-it-out-1"
          element={
            <RecapFillitOut
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        /> */}
        <Route
          path="/recap/fill-it-out-1"
          element={
            <RecapFillitOut
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />
        {/* <Route
          path="/researchers/recap/fill-it-out/allocation"
          element={
            <RecapAllocation
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        /> */}
        <Route
          path="/recap/fill-it-out/allocation"
          element={
            <RecapAllocation
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />
        {/* <Route
          path="/researchers/recap/fill-it-out-2"
          element={
            <RecapPoll2Page
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        /> */}
        <Route
          path="/recap/fill-it-out-2"
          element={
            <RecapPoll2Page
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />
        {/* <Route
          path="/researchers/recap/rank-short"
          element={
            <RecapRankShort
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        /> */}
        <Route
          path="/recap/rank-short"
          element={
            <RecapRankShort
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />
        {/* <Route
          path="/researchers/recap/matrix-task"
          element={
            <RecapMatrixTask
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        /> */}
        <Route
          path="/recap/matrix-task"
          element={
            <RecapMatrixTask
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        {/* <Route
          path="/researchers/view-conversation-topic"
          element={
            <ViewConversationTopic
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        /> */}
        <Route
          path="/view-conversation-topic"
          element={
            <ViewConversationTopic
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />
        <Route
          path="/researchers/group-meeting"
          element={<LiveGroupMeeting />}
        />
        <Route
          path="/researchers/interview-meeting"
          element={<LiveInterviewMeeting />}
        />

        {/* ----------------------------------------Project Management----------------------------------------- */}
        <Route
          path="/project-management"
          element={
            <ProjectManagement
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        {/* ----------------------------------------Project Management----------------------------------------- */}

        <Route
          path="/researchers/project/directory_participants"
          element={
            <ProjectManagementParticipant
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />
        {/* Project Mgt Director Add New Participant */}
        <Route
          path="/researchers/project/directory/participantscng"
          element={
            <ExistingParticipants
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        <Route
          path="/project-management/add-project-participant"
          element={
            <AddProjectParticipants
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        <Route
          path="/project-management/project-participant-screening"
          element={
            <ProjectParticipantScreening
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        <Route
          path="/project-management/project-participant-bulkImport"
          element={
            <ProjectParticipantBulkImport
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        <Route
          path="/project-management/project-participant-mailInvitation"
          element={
            <ProjectParticipantMailInvitation
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        <Route
          path="/project-management/project-participant-linkInvitation"
          element={
            <ProjectParticipantLinkInvitation
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        {/* Project Management Video Clip */}
        <Route
          path="/researchers/project/video-clip"
          element={
            <ProjectVideoClip
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />
        {/* Project Management Video Clip */}

        {/* <Route
          path="/researchers/recap/video-review"
          element={
            <RecapVideoReview
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        /> */}
        <Route
          path="/recap/video-review"
          element={
            <RecapVideoReview
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />
        {/* <Route
          path="/researchers/recap/audio-review"
          element={
            <RecapAudioReview
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        /> */}
        <Route
          path="/recap/audio-review"
          element={
            <RecapAudioReview
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        {/* <Route
          path="/researchers/view-participant-profile"
          element={
            <ViewParticipantProfile
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        /> */}
        <Route
          path="/view-participant-profile/:participantId"
          element={
            <ViewParticipantProfile
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        {/* <Route
          path="/researchers/view-message-profile"
          element={
            <ViewMessageProfile
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        /> */}

        <Route
          path="/view-message-profile"
          element={
            <ViewMessageProfile
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        {/* Data and Report */}
        <Route
          path="/researchers/data-report"
          element={
            <DataAndReport
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        {/* <Route
          path="/researchers/recap/view-Image"
          element={
            <ViewRecapImage
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        /> */}
        <Route
          path="/recap/view-Image"
          element={
            <ViewRecapImage
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        {/* <Route
          path="/researchers/recap/view-video"
          element={
            <ViewRecapVideo
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        /> */}
        <Route
          path="/recap/view-video"
          element={
            <ViewRecapVideo
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        {/* <Route
          path="/researchers/recap/view-audio"
          element={
            <ViewRecapAudio
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        /> */}

        <Route
          path="/recap/view-audio"
          element={
            <ViewRecapAudio
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        {/* Data and Report */}
        <Route path="/invitations" element={<Invations />} />

        <Route path="/admin-invitations" element={<AdminInvitations />} />

        {/* -----------------------------Participants------------------------------ */}
        <Route
          path="/participants/activities"
          element={
            <P_Activities
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/activities-Jourrnal"
          element={
            <P_ActivityJourrnal
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/activities/on-the-dot"
          element={
            <P_OntheDot
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/activities/on-the-dot-start"
          element={
            <P_OntheDotStart
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/activities/text-task/:taskId"
          element={
            <P_TextTask
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/activities/image-activity/:taskId"
          element={
            <P_ImageActivity
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/activities/audio-activity/:taskId"
          element={
            <P_AudioActivity
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/activities/video-activity/:taskId"
          element={
            <P_VideoActivity
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/activities/screen-capture"
          element={
            <P_ScreenCaptureActivity
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/activities/image-task"
          element={
            <P_ImageTask
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/activities/video-task"
          element={
            <P_VideoTask
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/activities/activity-guidance"
          element={
            <P_ActivityGuidance
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/activities/matrix-task"
          element={
            <P_MatrixTask
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/activities/fill-it-out"
          element={
            <P_FillItOutTask
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/activities/rank-and-sort"
          element={
            <P_RankItOrSortItTask
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/activities/PollAndSurvey"
          element={
            <P_PollAndSurvey
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/activities/any-file"
          element={
            <P_AnyFileUpload
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/activities/review-audio"
          element={
            <P_ReviewAudio
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/activities/mixed-media/:taskId"
          element={
            <P_MixedMediaTask
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/activities/Interview-Scheduled"
          element={
            <P_InterviewScheduled
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/activities/Interview-Scheduled/:activityId"
          element={
            <P_InterviewScheduled
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/activities/task/:taskId"
          element={
            <P_ActivityTask
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/wrkspaceItem"
          element={
            <P_WorkspaceItem
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/wrkspaceHome"
          element={
            <P_WorkspaceHome
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/conversations"
          element={
            <P_Conversations
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/view-conversation-category"
          element={
            <P_ViewConversationCategory
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/view-conversation-topic"
          element={
            <P_ViewConversationTopic
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/interview-meeting"
          element={
            <P_LiveInterviewMeeting
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/interview-scheduled"
          element={
            <P_LiveChatScheduled
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/group-meeting"
          element={
            <P_LiveGroupMeeting
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/participants/recap"
          element={
            <P_Recap
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />
        <Route
          path="/participants/recap/view-image"
          element={
            <P_ViewRecapImage
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />
        <Route
          path="/participants/recap/view-video"
          element={
            <P_ViewRecapVideo
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />
        <Route
          path="/participants/recap/view-audio"
          element={
            <P_ViewRecapAudio
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        <Route
          path="/participants/recap/image-review"
          element={
            <P_RecapImgReviewPage
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        <Route
          path="/participants/recap/video-review"
          element={
            <P_RecapVideoReview
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        <Route
          path="/participants/recap/audio-review"
          element={
            <P_RecapAudioReview
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        <Route
          path="/participants/recap/poll-mullti"
          element={
            <P_RecapPollMulltiPage
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        <Route
          path="/participants/recap/fill-it-out-1"
          element={
            <P_RecapFillitOut
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        <Route
          path="/participants/recap/fill-it-out-2"
          element={
            <P_RecapPoll2Page
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        <Route
          path="/participants/recap/matrix-task"
          element={
            <P_RecapMatrixTask
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        <Route
          path="/participants/recap/fill-it-out/allocation"
          element={
            <P_RecapAllocation
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        <Route
          path="/participants/recap/rank-short"
          element={
            <P_RecapRankShort
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        <Route
          path="/participants/safety-privacy"
          element={
            <P_SafetyPrivacy
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />

        <Route
          path="/participants/contact-ticket"
          element={
            <P_ContactTicket
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />

        <Route
          path="/participants/email-management"
          element={
            <P_EmailManagement
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />

        {/* -----------------------------Observer------------------------------ */}
        <Route
          path="/observer/home"
          element={
            <O_WorkspaceHome
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/observer/activities"
          element={
            <O_Activities
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/observer/activities/task"
          element={
            <O_ActivityTasks
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/observer/view-participant-profile"
          element={
            <O_ViewParticipantProfile
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/observer/activities/task/view"
          element={
            <O_ParticipantsRespons
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/observer/activities/preview"
          element={
            <O_EntiteActivity
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/observer/recap"
          element={
            <O_Recap
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />
        <Route
          path="/observer/recap/view-Image"
          element={
            <O_ViewRecapImage
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />
        <Route
          path="/observer/recap/view-video"
          element={
            <O_ViewRecapVideo
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />
        <Route
          path="/observer/recap/view-audio"
          element={
            <O_ViewRecapAudio
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />
        <Route
          path="/observer/project-management"
          element={
            <O_ProjectManagement
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/observer/conversation"
          element={
            <O_Conversations
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/observer/view-conversation-topic"
          element={
            <O_ViewConversationTopic
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/observer/view-conversation-category"
          element={
            <O_ViewConversationCategory
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/observer/dashboard"
          element={
            <O_Dashboard
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />

        {/* --------------------------------Collaborator--------------------- */}

        <Route
          path="/collaborator/dashboard"
          element={
            <C_Dashboard
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/collaborator/wrkspaceItem"
          element={
            <C_WorkspaceItem
              slideMenu={slideMenu}
              workspaceDrop={workspaceDrop}
              setSlideMenu={setSlideMenu}
            />
          }
        />
        <Route
          path="/collaborator/home"
          element={
            <C_WorkspaceHome
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />
        <Route
          path="/collaborator/activities"
          element={
            <C_Activities
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />
        <Route
          path="/collaborator/activities/task"
          element={
            <C_ActivityTasks
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />
        <Route
          path="/collaborator/activities/task/view"
          element={
            <C_ParticipantsRespons
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />
        <Route
          path="/collaborator/activities/task/outdoor"
          element={
            <C_ViewResponse
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />
        <Route
          path="/collaborator/activities/preview"
          element={
            <C_EntiteActivity
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />
        <Route
          path="/collaborator/activities/interview-scheduled"
          element={
            <InterviewScheduled
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        <Route
          path="/collaborator/activities/live-chat-scheduled"
          element={
            <LiveChatScheduled
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />
        <Route
          path="/collaborator/group-meeting"
          element={<LiveGroupMeeting />}
        />
        <Route
          path="/activities/interview-meeting/:scheduleId"
          element={<LiveInterviewMeeting />}
        />

        <Route
          path="/collaborator/conversation"
          element={
            <C_Conversations
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        <Route
          path="/collaborator/view-conversation-topic"
          element={
            <C_ViewConversationCategory
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        <Route
          path="/collaborator/view-conversation-category"
          element={
            <C_ViewConversationCategory
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        <Route
          path="/collaborator/project-management"
          element={
            <C_ProjectManagement
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        <Route
          path="/collaborator/project/video-clip"
          element={
            <C_ProjectVideoClip
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        <Route
          path="/collaborator/project/directory_participants"
          element={
            <C_ProjectManagementParticipant
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        <Route
          path="/collaborator/project-management/add-project-participant"
          element={
            <C_AddProjectParticipants
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        <Route
          path="/collaborator/project-management/project-participant-screening"
          element={
            <C_ProjectParticipantScreening
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        <Route
          path="/collaborator/project-management/project-participant-bulkImport"
          element={
            <C_ProjectParticipantBulkImport
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        <Route
          path="/collaborator/project-management/project-participant-mailInvitation"
          element={
            <C_ProjectParticipantMailInvitation
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        <Route
          path="/collaborator/project-management/project-participant-linkInvitation"
          element={
            <C_ProjectParticipantLinkInvitation
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        <Route
          path="/collaborator/project/directory/participantscng"
          element={
            <C_ExistingParticipants
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
            />
          }
        />

        <Route
          path="/collaborator/recap"
          element={
            <C_Recap
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        <Route
          path="/collaborator/recap/view-audio"
          element={
            <C_ViewRecapAudio
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        <Route
          path="/collaborator/recap/view-image"
          element={
            <C_ViewRecapImage
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        <Route
          path="/collaborator/recap/view-video"
          element={
            <C_ViewRecapVideo
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        <Route
          path="/collaborator/recap/video-review"
          element={
            <RecapVideoReview
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        <Route
          path="/collaborator/recap/image-review"
          element={
            <RecapImgReviewPage
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        <Route
          path="/collaborator/recap/audio-review"
          element={
            <RecapAudioReview
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        <Route
          path="/collaborator/recap/fill-it-out-1"
          element={
            <RecapFillitOut
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        <Route
          path="/collaborator/recap/fill-it-out-2"
          element={
            <RecapPoll2Page
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        <Route
          path="/collaborator/recap/fill-it-out/allocation"
          element={
            <RecapAllocation
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        <Route
          path="/collaborator/recap/poll-mullti"
          element={
            <RecapPollMulltiPage
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        <Route
          path="/collaborator/recap/matrix-task"
          element={
            <RecapMatrixTask
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        <Route
          path="/collaborator/recap/rank-short"
          element={
            <RecapRankShort
              slideMenu={slideMenu}
              setSlideMenu={setSlideMenu}
              workspaceDrop={workspaceDrop}
              setWorkSpaceDrop={setWorkSpaceDrop}
              selectNavItem={selectNavItem}
              setSelectNavItem={setSelectNavItem}
            />
          }
        />

        {/* <Route path="/screen-question" element={<ScreenQuestion />} /> */}
        <Route path="/projects-invitations" element={<ScreenQuestion />} />

        <Route path="*" element={<NotFound />} />

        <Route path="/registration" element={<Registration />} />

        <Route path="/enforce-2fa" element={<Enforce2Fa />} />
      </Routes>

      <ToastContainer />
    </ReduxProvider>
  );
}
