import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import "./style.css";

import WhiteArrow from "../../../Assets/Images/Wchevron-down.png";
import userHomeBanner from "../../../Assets/Images/userHomeBanner.png"
import Avatar from "../../../Assets/Images/UserHomeAvatar.png"
import consartImg from "../../../Assets/Images/consartImg.png"
import OptionIcon from "../../../Assets/Images/optionIcon.png";
import users from "../../../Assets/Images/usersB.png";
import star from "../../../Assets/Images/Star 2.png"
import redDot from "../../../Assets/Images/RedDot.png"
import BottomBanner from "../../../Assets/Images/Bbanner.png";
import hillMan from "../../../Assets/Images/hillman.png"
import user1 from "../../../Assets/Images/New folder/user1.png"
import user2 from "../../../Assets/Images/New folder/user2.png"
import user3 from "../../../Assets/Images/New folder/user3.png"
import user4 from "../../../Assets/Images/New folder/user4.png"
import user5 from "../../../Assets/Images/New folder/user5.png"
import OfflineDot from "../../../Assets/Images/offlineDot.png"
import sendIcon from "../../../Assets/Images/sendIcon.png"
import lockIcon from "../../../Assets/Images/lockIocn.png"
import editIcon01 from "../../../Assets/Images/edit-2.png"
import dropIcon from "../../../Assets/Images/chevron-downp.png"
import iIcon from "../../../Assets/Images/IIcon.png";
import groupsIcon from "../../../Assets/Images/groupsIcon.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";

import { deletSvg, editIcon, backgroundIcon } from "../../../Assets/Data"
import { AppBtn, AppBtnOutLine } from '../../AppTools/AppButton';

//components
import HorizontalBars from "../../AppTools/LineChart";
import { GoTop } from "../../AppTools/AppTool"
import AddCardPop from '../AddCardPop';
import AddCardPopPeoplePop from '../AddCardPeoplePop';
import PageSettingPop from "../PageSettingsPop"
import EditCardPop from "../EditCardPop"
import AddCardMessagePop from "../AddCardMessagePop";
import AddCardImagePop from "../AddCardImagePop";
import AddCardVideoPop from "../AddCardVideoPop";
import AddCardAudioPop from "../AddCardAudioPop";
import AddCardFilePop from "../AddCardFilePop";
import AddCardActivityPop from "../AddCardActivityPop";
import AddCardConversationPop from "../AddCardConversationPop";
import AddCardStatisticPop from "../AddCardStatisticPop";
import AddPagePop from '../AddPagePop';
import DuplicatePagePop from '../DuplicatePagePop';
import DeletePagePop from '../DeletePagePop';
import DeleteCardPop from '../DeleteCardPop';

export default function WorkspaceHomeEditView({ setWorkSHAddCardPop, workSHAddCardPop, allDropVal3, setAllDropVal3 }) {

  const [researchers, setResearchers] = useState(false);
  const [researchersVal, setResearchersVal] = useState();
  const [memBerPop, setMemBerPop] = useState(false);
  const [memBerPopNav, setMemBerPopNav] = useState(0);
  const [selectedCard, setSelectedCard] = useState();
  const [addCardPopPeoplePop, setAddCardPopPeoplePop] = useState(false);
  const [editCardPop, setEditCardPop] = useState(false)
  const [userCardDrop, setUserCardDrop] = useState(false)

  const [addCardMessagePop, setAddCardMessagePop] = useState(false);
  const [addCardImagePop, setAddCardImagePop] = useState(false);
  const [addCardVideoPop, setAddCardVideoPop] = useState(false);
  const [addCardAudioPop, setaddCardAudioPop] = useState(false);
  const [addCardFilePop, setAddCardFilePop] = useState(false);
  const [addCardActivityPop, setaddCardActivityPop] = useState(false);
  const [addCardConversationPop, setAddCardConversationPop] = useState(false);
  const [addCardStatisticPop, setAddCardStatisticPop] = useState(false);
  const [duplicatePagePop, setDuplicatePagePop] = useState(false);
  const [deletePagePop, setDeletePagePop] = useState(false);
  const [deleteCardPop, setDeleteCardPop] = useState(false);

  const [backgroundDrop, setBackgroundDrop] =  useState(false);
  const [backgroundDropVal, setBackgroundDropVal] = useState('Background');


  const userRow = (img, title) => {
    return (
      <Box className="rOnlUserRow">
        <Box>
          <img src={img} />
        </Box>
        <Typography>{title}</Typography>
      </Box>
    )
  }




  const MemberPop = () => {
    return (
      <Box id="popBackdrop"
        onClick={handelPopClose} sx={{ display: memBerPop ? "flex" : "none" }} className="workspacePop" >
        <Box className="memberPop">
          <Box className="membUserInfo">
            <img src={OfflineDot} className='OfflineDot' />
            <Box className="membAvatar">
              <img src={user3} />
            </Box>

            <Box className="membUserInfoIn">
              <Typography>Name03_P</Typography>
              <span >Online 3 days ago</span>
              <Box>
                <img src={sendIcon} />
                <img src={lockIcon} />
                <img src={editIcon01} />
              </Box>
            </Box>

            <Box className="membUserInfoLast">
              <Typography>Mega</Typography>
              <span>348 points</span>
            </Box>
          </Box>

          <Box className="membTabBox">
            <Box className="membTabNav">
              <Box className={memBerPopNav === 0 ? "membNavItem membNavItemActive" : "membNavItem"} onClick={() => setMemBerPopNav(0)}>
                <Typography>Custom Profile</Typography>
              </Box>
              <Box className={memBerPopNav === 1 ? "membNavItem membNavItemActive" : "membNavItem"} onClick={() => setMemBerPopNav(1)}>
                <Typography>Basic Details</Typography>
              </Box>
            </Box>
            <Box sx={{ display: memBerPopNav === 0 ? "flex" : "none" }} className="membTabCont">
              <Box className="custProw">
                <Typography>Age:</Typography>
                <span>30 years</span>
              </Box>
              <Box className="custProw">
                <Typography>Income:</Typography>
                <span>100,000$</span>
              </Box>
              <Box className="custProw">
                <Typography>Gender:</Typography>
                <span>Female</span>
              </Box>
              <Box className="custProw">
                <Typography>Contact:</Typography>
                <span>9800269509</span>
              </Box>

            </Box>
            <Box sx={{ display: memBerPopNav === 1 ? "flex" : "none" }} className="membTabCont">
              <Box className="custProw">
                <Typography>Name:</Typography>
                <span>03 Participant</span>
              </Box>
              <Box className="custProw">
                <Typography>Username:</Typography>
                <span>03_P (TP247023)</span>
              </Box>
              <Box className="custProw">
                <Typography>Email:</Typography>
                <span>p03#name@gmail.com</span>
              </Box>
              <Box className="custProw">
                <Typography>Joining:</Typography>
                <span>Aug 06, 2023</span>
              </Box>
              <Box className="custProw">
                <Typography>Groups:</Typography>
                <span></span>
              </Box>
            </Box>

          </Box>
        </Box>
      </Box>
    )
  }

  const memberItem = (img, title) => {
    return (
      <Box onClick={() => {
        setMemBerPop(true)
        GoTop()
      }} className="memberRowItem">
        <Box><img src={img} /></Box>
        <Typography>{title}</Typography>
      </Box>
    )
  }

  const handelAddCardPopCoutinue = () => {
    console.log("click", selectedCard);
    setWorkSHAddCardPop(false)
    if (selectedCard === "People") {
      setAddCardPopPeoplePop(true)
    } else if (selectedCard === "Message") {
      setAddCardMessagePop(true);
    } else if (selectedCard === "Image") {
      setAddCardImagePop(true);
    } else if (selectedCard === "Videos") {
      setAddCardVideoPop(true);
    } else if (selectedCard === "Audios") {
      setaddCardAudioPop(true);
    } else if (selectedCard === "File") {
      setAddCardFilePop(true);
    } else if (selectedCard === "Activities") {
      setaddCardActivityPop(true);
    } else if (selectedCard === "Conversations") {
      setAddCardConversationPop(true);
    } else if (selectedCard === "Statistics") {
      setAddCardStatisticPop(true);
    }
  }
  const handelAddCardPopPeopleBack = () => {
    setWorkSHAddCardPop(true)
    setAddCardPopPeoplePop(false)
    setAddCardMessagePop(false);
    setAddCardImagePop(false);
    setAddCardVideoPop(false);
    setaddCardAudioPop(false);
    setAddCardFilePop(false);
    setaddCardActivityPop(false);
    setAddCardConversationPop(false);
    setAddCardStatisticPop(false);
  }


  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setMemBerPop(false);
    }
  };

  useEffect(() => {
    if (memBerPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [memBerPop]);

  return (
    <>
      
        <Box className="userHomeContainer reworkspace">

          {/* Pops */}
          <MemberPop />

          <AddCardPop
            setWorkSHAddCardPop={setWorkSHAddCardPop}
            workSHAddCardPop={workSHAddCardPop}
            setSelectedCard={setSelectedCard}
            selectedCard={selectedCard}
            handelAddCardPopCoutinue={handelAddCardPopCoutinue}
          />

          <AddCardPopPeoplePop
            addCardPopPeoplePop={addCardPopPeoplePop}
            setAddCardPopPeoplePop={setAddCardPopPeoplePop}
            handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
          />

          <PageSettingPop allDropVal3={allDropVal3} setAllDropVal3={setAllDropVal3} setDuplicatePagePop={setDuplicatePagePop} setDeletePagePop={setDeletePagePop} />

          <EditCardPop editCardPop={editCardPop} setEditCardPop={setEditCardPop} />

          <AddCardMessagePop
            addCardMessagePop={addCardMessagePop}
            setAddCardMessagePop={setAddCardMessagePop}
            handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
          />

          <AddCardImagePop
            addCardImagePop={addCardImagePop}
            setAddCardImagePop={setAddCardImagePop}
            handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
          />

          <AddCardVideoPop
            addCardVideoPop={addCardVideoPop}
            setAddCardVideoPop={setAddCardVideoPop}
            handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
          />

          <AddCardAudioPop
            addCardAudioPop={addCardAudioPop}
            setaddCardAudioPop={setaddCardAudioPop}
            handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
          />

          <AddCardFilePop
            addCardFilePop={addCardFilePop}
            setAddCardFilePop={setAddCardFilePop}
            handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
          />

          <AddCardActivityPop
            addCardActivityPop={addCardActivityPop}
            setaddCardActivityPop={setaddCardActivityPop}
            handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
          />

          <AddCardConversationPop
            addCardConversationPop={addCardConversationPop}
            setAddCardConversationPop={setAddCardConversationPop}
            handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
          />

          <AddCardStatisticPop
            addCardStatisticPop={addCardStatisticPop}
            setAddCardStatisticPop={setAddCardStatisticPop}
            handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
          />

          <AddPagePop allDropVal3={allDropVal3} setAllDropVal3={setAllDropVal3} />

          <DuplicatePagePop duplicatePagePop={duplicatePagePop} setDuplicatePagePop={setDuplicatePagePop} setAllDropVal3={setAllDropVal3} />

          <DeletePagePop deletePagePop={deletePagePop} setDeletePagePop={setDeletePagePop} setAllDropVal3={setAllDropVal3}  />

          <DeleteCardPop deleteCardPop={deleteCardPop} setDeleteCardPop={setDeleteCardPop} />

          <Box mb={4} className="workSpaceListBox">
            <Typography className='atvHeaderText editPageHeaderText'>Edit Page</Typography>
            <Box className="editPageAllBtn">
            <Box className="workPupBtnBox firstSetBtn">
                {/* <Box  className="inputBoxWlabel"> */}
                    <Box
                      pl={4.5}
                      className="inputAppDrop editPageBackfroundIcon"
                      onClick={() => setBackgroundDrop(!backgroundDrop)}
                    >
                      {backgroundIcon}
                      <Typography>{backgroundDropVal || "Search Background"}</Typography>
                      <img
                        style={{ rotate: backgroundDrop ? "180deg" : "0deg" }}
                        src={dropArrow}
                      />
                      {/* pop */}
                      <Box
                        className="statusPop"
                        sx={{ height: backgroundDrop ? "auto" : "0px" }}
                      >
                        <Box
                          onClick={() => setBackgroundDropVal("Background")}
                          className="statusPopItem"
                        >
                          <Typography>Background</Typography>
                        </Box>
                        
                      </Box>
                    </Box>
                  {/* </Box> */}
                    <Box>
                        <Typography className='unLBtn'>Use default Template</Typography>
                    </Box>
                    
                </Box>
            <Box className="workPupBtnBox editPageCancelBtn">
                    <AppBtnOutLine buttonText="Cancel" onClick={() => setAllDropVal3('Page Options')} />
                    <Box className="verifyBtn">
                        <Typography>Publish</Typography>
                    </Box>
                </Box>
                
            </Box>
            <Box className="iBox editPageAlert">
                <img src={iIcon} />
                <Typography>Resize cards by dragging them from their edges or position them by simply picking/dragging the whole card and dropping it on a new spot to edit and customize this page. You can also change the background of this section. Changes made will only be seen once they are published.</Typography>
            </Box>
          </Box>

          <Box className="uhwelcomeBanner">
            <img src={userHomeBanner} />
            <Box sx={{ position: "relative" }} onClick={() => setResearchers(!researchers)}>
              <Typography className='uhwelbannerText' mr={1}>{researchersVal || "Welcome Researchers!"}</Typography>
              <img src={WhiteArrow} />

              <Box sx={{ display: researchers ? "flex" : "none" }} className="researchPop">
                <Box className="researchPopItem" onClick={() => setResearchersVal("Welcome Researchers!")}>
                  <Typography className='researchPopItemText'>Welcome Researchers!</Typography>
                </Box>
                <Box className="researchPopItem" onClick={() => setResearchersVal("Collaborators Corner")}>
                  <Typography className='researchPopItemText'>Collaborators Corner</Typography>
                </Box>
                <Box className="researchPopItem" onClick={() => setResearchersVal("Observers Corner")}>
                  <Typography className='researchPopItemText'>Observers Corner</Typography>
                </Box>
                <Box className="researchPopItem" onClick={() => setResearchersVal("Welcome Participants")}>
                  <Typography className='researchPopItemText'>Welcome Participants</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="userHMBox3">

            <Box className="userHMBoxItem userhAvatarBox homeCard">
              <Box className="AvartimgBox"><img src={Avatar} /></Box>
              <Typography className='userHMText'>Welcome User Name</Typography>
              <Typography className='userHNText'>Lorem ipsum dolor sit amet consect etur. Ac quisque tristique dignissim non. Erat id ipsum sem non auctor. Elit non massa quis faucibus vel don sapien felis. </Typography>
              <Box className="homeCardDrop" onClick={() => setUserCardDrop(!userCardDrop)}>
                <img style={{ rotate: userCardDrop ? "180deg" : "0deg" }} src={dropIcon} />
                <Box sx={{ display: userCardDrop ? "block" : "none" }} className="statusPop">
                  <Box className="statusPopItem" onClick={() => setEditCardPop(true)}>
                    {editIcon}
                    <p>Edit Card</p>
                  </Box>
                  <Box onClick={() => setDeleteCardPop(true)} className="statusPopItem">
                    {deletSvg}
                    <p>Delete Card</p>
                  </Box>

                </Box>
              </Box>

            </Box>

            <Box className="userHMBoxItem lineChart">
              <HorizontalBars />
            </Box>

            <Box className="userHMBoxItem userHConsart">
              <Box className="conImgBox"><img src={consartImg} /></Box>
              <Box className="userHCTextBox">
                <Typography sx={{ fontSize: "20px" }}>Welcome</Typography>
                <img src={OptionIcon} />
              </Box>
              <Box className="userHCTextBox">
                <Box>
                  <Typography sx={{ fontSize: "16px", color: "#98A2B3" }}>Aug 02 - Aug 03</Typography>
                  <Box sx={{ background: "#12B76A" }} className="ActiveStatus"></Box>
                </Box>
                <Box>
                  <img src={users} />
                  <Typography ml={1} sx={{ fontSize: "20px", color: "#98A2B3" }}>11</Typography>
                </Box>
              </Box>

            </Box>
          </Box>






          <Box className="userHMBox3">
            <Box className="userHMBoxItem ConversationsBox">
              <Typography className='ConversationsBoxHText'>Conversations</Typography>

              <Box className="convChatItem">
                <Box mb={0.8}>
                  <Typography>Recent Trekking Outings</Typography>
                  <Box className="iconBox">
                    <img style={{ width: "20px", height: "20px" }} src={star} />
                    <img style={{ width: "12px", height: "12px" }} src={redDot} />
                  </Box>
                </Box>
                <Box>
                  <Typography className='convSubText'>(Outdoor Tips)</Typography>
                  <Box className="viewBtn">
                    <Typography>View</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="convChatItem">
                <Box mb={0.8}>
                  <Typography>Your Trekking must haves</Typography>
                  <Box className="iconBox">
                    <img style={{ width: "20px", height: "20px" }} src={star} />
                    <img style={{ width: "12px", height: "12px" }} src={redDot} />
                  </Box>
                </Box>
                <Box>
                  <Typography className='convSubText'>(Equipment Zone)</Typography>
                  <Box className="viewBtn">
                    <Typography>View</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="convChatItem">
                <Box mb={0.8}>
                  <Typography>Meal Hacks</Typography>
                  <Box className="iconBox">
                    <img style={{ width: "20px", height: "20px" }} src={star} />
                    <img style={{ width: "12px", height: "12px" }} src={redDot} />
                  </Box>
                </Box>
                <Box>
                  <Typography className='convSubText'>(Outdoor Tips)</Typography>
                  <Box className="viewBtn">
                    <Typography>View</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className="bottomBannerBox"><img src={BottomBanner} />
            </Box>
          </Box>




          <Box className="userHMBox3">
            <Box className="userHMBoxItem keyDataBox">
              <Typography className='ConversationsBoxHText'>Key Dates</Typography>
              <Box className="keyDataRow">
                <Typography>Monday</Typography>
                <span>Introduction  Task</span>
              </Box>
              <Box className="keyDataRow">
                <Typography>Tuesday</Typography>
                <span>Introduction to Task</span>
              </Box>
              <Box className="keyDataRow">
                <Typography>Wednesday</Typography>
                <span>Task experience</span>
              </Box>
              <Box className="keyDataRow">
                <Typography>Thursday</Typography>
                <span>Task experience</span>
              </Box>
              <Box className="keyDataRow">
                <Typography>Friday</Typography>
                <span>Introduction to Task</span>
              </Box>
              <Box className="keyDataRow">
                <Typography>Saturday</Typography>
                <span>Task experience</span>
              </Box>
              <Box className="keyDataRow">
                <Typography>Sunday</Typography>
                <span>Introduction to Task</span>
              </Box>

            </Box>

            <Box className="userHMBoxItem">
              <img src={hillMan} className='hillManImg' />
            </Box>
            <Box className="userHMBoxItem RecentlyOnlineBox">
              <Typography className='ConversationsBoxHText'>Recently Online</Typography>
              {userRow(user1, "Participant01 FullName")}
              {userRow(user2, "Participant02 FullName")}
              {userRow(user3, "Participant03 FullName")}
              {userRow(user4, "Participant04 FullName")}
              {userRow(user5, "Participant05 FullName")}
              <Box className="pageNum">
                <Box className="pageNumItem pageNumActive"><p>1</p></Box>
                <Box className="pageNumItem"><p>2</p></Box>
                <Box className="pageNumItem"><p>3</p></Box>
                <Box className="pageNumItem"><p>4</p></Box>
                <Box className="pageNumItem"><p>5</p></Box>
              </Box>

            </Box>
          </Box>

          <Box className="userHMBox3 userHMBox4">
            <Box className="userHMBoxItem memberBox1">
              <Typography className='ConversationsBoxHText'>Tera Members</Typography>
              <Box mb={2} className="memberRow">
                {memberItem(user1, "Name01")}
                {memberItem(user2, "Name02")}
                {memberItem(user3, "Name03")}
              </Box>
              <Box className="memberRow">
                {memberItem(user4, "Name04")}
                {memberItem(user5, "Name05")}
              </Box>
            </Box>

            <Box className="userHMBoxItem memberBox2">
              <Typography className='ConversationsBoxHText'>Giga Members</Typography>
              <Box mb={2} className="memberRow">
                {memberItem(user1, "Name01")}
                {memberItem(user2, "Name02")}
                {memberItem(user3, "Name03")}
              </Box>
              <Box className="memberRow">
                {memberItem(user4, "Name04")}
                {memberItem(user5, "Name05")}
              </Box>
            </Box>

            <Box className="userHMBoxItem memberBox3">
              <Typography className='ConversationsBoxHText'>Mega Members</Typography>
              <Box mb={2} className="memberRow">
                {memberItem(user1, "Name01")}
                {memberItem(user2, "Name02")}
                {memberItem(user3, "Name03")}
              </Box>
              <Box className="memberRow">
                {memberItem(user4, "Name04")}
                {memberItem(user5, "Name05")}
              </Box>
            </Box>



          </Box>



        </Box>

    </>
  )
}
