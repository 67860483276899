import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from "react-i18next";

//Images
import iIcon2 from "../../../Assets/Images/IIcon.png";

//components 
import { AppBtn, AppBtnOutLine } from '../../../Components/AppTools/AppButton';

export default function ProjectState({ projectSettingDropVal }) {

    const {
        t,
        i18n: { changeLanguage, language },
      } = useTranslation();

    return (
        <>
            <Box sx={{ display: projectSettingDropVal === "Project State" ? "block" : "none" }}>
                <Typography className='emailNotificationHeaderText'>{t("projectState.projectStateHead")}</Typography>
                <Box className="iBox projectIBox">
                    <img src={iIcon2} />
                    <Typography>{t("projectState.projectStateNotify")}</Typography>
                </Box>
                <Box className="projectRadioBox">
                    <Box className="projectRadioBoxSection">
                        <Box className="ProjectRadioInput">
                            <input
                                className="emailSettingInput emailSettingRadio"
                                type='radio'
                                name='projectState'
                                defaultChecked
                            />
                            <Typography className="emailSettingLabel">{t("videoTask.projectStateDraftRadio")}</Typography>
                        </Box>
                        <Box className="ProjectRadioInput">
                            <input
                                className="emailSettingInput emailSettingRadio"
                                type='radio'
                                name='projectState'
                            />
                            <Typography className="emailSettingLabel">{t("videoTask.projectStateClosed")}</Typography>
                        </Box>
                    </Box>
                    <Box className="projectRadioBoxSection">
                        <Box className="ProjectRadioInput">
                            <input
                                className="emailSettingInput emailSettingRadio"
                                type='radio'
                                name='projectState'
                            />
                            <Typography className="emailSettingLabel">{t("videoTask.projectStateOpenRadio")}</Typography>
                        </Box>
                        <Box className="ProjectRadioInput">
                            <input
                                className="emailSettingInput emailSettingRadio"
                                type='radio'
                                name='projectState'
                            />
                            <Typography className="emailSettingLabel">{t("videoTask.projectStateArchivedRadio")}</Typography>
                        </Box>

                    </Box>
                </Box>
                <Box className="workPupBtnBox">
                    <AppBtn buttonText={t("commonTxt.apply")} />
                </Box>
            </Box>
        </>
    )
}
