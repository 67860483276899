import React, { useEffect, useState, useRef } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";
import DummyUser from "../../../Assets/Images/user-dummy.png";

import { useTranslation } from "react-i18next";

import { DirectoryUsersData } from "../../../Assets/Data";
import { editIconBlack, trashBlack, recycleBlack } from "../../../Assets/Data";

import {
  GoTop,
  ResearcherStatus,
  ReserchersWorkSpaceDropDown,
  Status,
} from "../../AppTools/AppTool";

import dropArrow from "../../../Assets/Images/chevron-down.png";
import arrow from "../../../Assets/Images/arrow.png";
import ExportVector from "../../../Assets/Images/ExportVector.png";
import axios from "../../../lib/axios";

//components
import { SearchBar, LatestCreated } from "../../AppTools/AppTool";
import AddResearcherPop from "../AddResearcherPop/index.jsx";
import AddCollaboratorPop from "../AddCollaboratorPop";
import AddObserverPop from "../AddObserverPop";
import AddParticipantPop from "../AddParticipantPop";
import RemoveFrmWSPop from "../RemoveFrmWSPop";
import DeleteResearcherPop from "../DeleteResearcherPop";
import DeleteCollaboratorPop from "../DeleteCollaboratorPop";
import DeleteObserverPop from "../DeleteObserverPop";
import DeleteParticipantPop from "../DeleteParticipantPop";
import EditResearcherPop from "../EditResearcherPop";
import EditCollaborator from "../EditCollaborator";
import EditObserverPop from "../EditObserverPop";
import EditParticipantPop from "../EditParticipantPop";
import { formatDate, viewImage } from "../../../lib/helpers";
import Pagination from "../../AppTools/Pagination";

export function UserDirectoryResearchers({
  setAddResearcherPop,
  addResearcherPop,
}) {
  const [nav, setNav] = useState(0);
  const popupRef = useRef(null);

  const [researcherDrop, setResearcherDrop] = useState(false);
  const [researcherDropVal, setResearcherDropVal] = useState("All");

  const [workspaceNameDrop, setWorkspaceNameDrop] = useState(false);
  const [workspaceNameDropVal, setWorkspaceNameDropVal] = useState("All");

  const [workspaceStatusDrop, setWorkspaceStatusDrop] = useState(false);
  const [workspaceStatusDropVal, setWorkspaceStatusDropVal] = useState();

  const [resRemFromWorkspace, setResRemFromWorkspace] = useState(false);
  const [removefromWorkspace, setRemFromWorkspace] = useState(false);
  const [colRemFromWorkspace, setColRemFromWorkspace] = useState(false);
  const [obsRemFromWorkspace, setObsRemFromWorkspace] = useState(false);
  const [partRemFromWorkspace, setPartRemFromWorkspace] = useState(false);

  const [ParticipantsNavDrop, setParticipantsNavDrop] = useState(false);
  const [ParticipantsNavDropVal, setParticipantsNavDropVal] = useState();

  const [allResearchersList, setAllResearchersList] = useState([]);
  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Latest Created");

  const [optionIndex, setOptionIndex] = useState();
  const [workSpaceOption, setWorkspaceOption] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [editResearcherPop, setEditResearcherPop] = useState(false);

  const [deleteResearcherPop, setDeleteResearcherPop] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [deleteResearchersListData, setDeleteResearchersListData] =
    useState("");
  const [removeWokspacesListData, setRemoveWokspacesListData] = useState(null);
  const [clearFilter, setClearFilter] = useState(false);
  const [allResearchersData, setAllResearchersData] = useState("");

  const [statusDrop, setStatusDrop] = useState(false);
  const [statusDropVal, setStatusDropVal] = useState("All");

  const workspaceOptionRef = useRef(null);
  const latestDropRef = useRef(null);
  const researcherStatusDrop = useRef(null);
  const wsNameStatusDrop = useRef(null);
  const wsStatusDrop = useRef(null);
  const statusDropRef = useRef(null);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const defaultFilters = {
    search: "",
    sortBy: "created_at",
    sortOrder: -1,
    workspace_status: "",
    assigned_status: "",
    workspace: "",
  };

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 25,
    totalPages: 0,
    totalItems: 0,
  });

  const [filterState, setFilterState] = useState({ ...defaultFilters });

  const handleOptionIndex = (i) => {
    setOptionIndex(i);
    console.log(optionIndex);
  };
  console.log(optionIndex);

  useEffect(() => {
    getResearchers(1);
  }, []);

  let newArrayData;
  function printItemsByState(array, state) {
    let startIndex = state * 8;
    let endIndex = (state + 1) * 8;
    let itemsToPrint = array.slice(startIndex, endIndex);
    if (itemsToPrint.length > 0) {
      // console.log(itemsToPrint);
      newArrayData = itemsToPrint;
    } else {
      console.log("No items to print for the given state.");
    }
  }

  let pageNumver = 0;

  console.log(pageNumver);
  printItemsByState(DirectoryUsersData, pageNumver);

  const handleFilterChange = (changedFilter) => {
    let newFilters = {
      ...filterState,
      ...changedFilter,
    };
    setFilterState(newFilters);
    getResearchers(1, newFilters);
  };

  const getResearchers = async (curPage, filters = filterState) => {
    return await axios
      .get(
        `/super-admin/admin/researchers?page=${curPage}&search=${filters?.search}&workspace_status=${filters?.workspace_status}&sortBy=${filters.sortBy}&sortOrder=${filters?.sortOrder}&assigned_status=${filters?.assigned_status}&workspace=${filters?.workspace}`
      )
      .then((res) => {
        if (res.status) {
          setAllResearchersList(res.data.data);
          setPaginationData({
            currentPage: res.data.pagination.currentPage,
            itemsPerPage: res.data.pagination.itemsPerPage,
            totalPages: res.data.pagination.totalPages,
            totalItems: res.data.pagination.totalItems,
          });
        }
      });
  };

  const onPageChange = (pageNum) => {
    getResearchers(pageNum, filterState);
  };

  const handleDeleteResearchers = (val) => {
    setDeleteResearchersListData(val);
  };

  const handleRemoveResearchersFrmWs = (val) => {
    setRemoveWokspacesListData(val);
  };

  useEffect(() => {
    if (reloadList) {
      getResearchers(1);
      setReloadList(false);
    }
  }, [reloadList]);

  return (
    <>
      <RemoveFrmWSPop
        setResRemFromWorkspace={setResRemFromWorkspace}
        resRemFromWorkspace={resRemFromWorkspace}
        setRemFromWorkspace={setRemFromWorkspace}
        setColRemFromWorkspace={setColRemFromWorkspace}
        setObsRemFromWorkspace={setObsRemFromWorkspace}
        setPartRemFromWorkspace={setPartRemFromWorkspace}
        userTitle={newArrayData[optionIndex]?.title}
        userWorkspace={newArrayData[optionIndex]?.Workspace}
        removeWokspacesListData={removeWokspacesListData}
        setReloadList={setReloadList}
      />

      <Box className="AllParticipantsBox userDirectoryBox">
        <EditResearcherPop
          editResearcherPop={editResearcherPop}
          setEditResearcherPop={setEditResearcherPop}
          setAllResearchersData={setAllResearchersData}
          allResearchersData={allResearchersData}
          setReloadList={setReloadList}
          reloadList={reloadList}
        />
        <AddResearcherPop
          setAddResearcherPop={setAddResearcherPop}
          addResearcherPop={addResearcherPop}
          setReloadList={setReloadList}
          reloadList={reloadList}
        />
        <DeleteResearcherPop
          deleteResearcherPop={deleteResearcherPop}
          setDeleteResearcherPop={setDeleteResearcherPop}
          deleteResearchersListData={deleteResearchersListData}
          setReloadList={setReloadList}
        />
        <Box className="AllPSubBox allPFilters">
          <Typography className="pageHeaderText">
            {t("directoryUsers.filter")}
          </Typography>

          <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
            <Box
              sx={{ width: nav === 0 ? "100%" : "0px" }}
              className="basicFilterBox"
            >
              <Box
                className="filterBoxes"
                sx={{ display: nav === 0 ? "flex" : "none" }}
              >
                <Box className="inputBoxWlabel">
                  <Typography className="inputLabel">
                    {t("directoryUsers.changedStatus")}
                  </Typography>
                  <ResearcherStatus
                    ref={researcherStatusDrop}
                    setResearcherDrop={setResearcherDrop}
                    researcherDrop={researcherDrop}
                    researcherDropVal={researcherDropVal}
                    setResearcherDropVal={setResearcherDropVal}
                    handleFilterChange={handleFilterChange}
                    setClearFilter={setClearFilter}
                    clearFilter={clearFilter}
                  />
                </Box>

                <Box className="inputBoxWlabel">
                  <Typography className="inputLabel">
                    {t("commonTxt.workspace")}
                  </Typography>
                  <ReserchersWorkSpaceDropDown
                    ref={wsNameStatusDrop}
                    setWorkspaceNameDrop={setWorkspaceNameDrop}
                    workspaceNameDrop={workspaceNameDrop}
                    workspaceNameDropVal={workspaceNameDropVal}
                    setWorkspaceNameDropVal={setWorkspaceNameDropVal}
                    handleFilterChange={handleFilterChange}
                    setClearFilter={setClearFilter}
                    clearFilter={clearFilter}
                  />
                </Box>
                <Box className="inputBoxWlabel">
                  <Typography className="inputLabel">
                    {t("directoryUsers.wsStatus")}
                  </Typography>
                  <Status
                    ref={statusDropRef}
                    setStatusDrop={setStatusDrop}
                    statusDrop={statusDrop}
                    handleFilterChange={handleFilterChange}
                    setStatusDropVal={setStatusDropVal}
                    statusDropVal={statusDropVal}
                    setClearFilter={setClearFilter}
                    clearFilter={clearFilter}
                  />
                </Box>
              </Box>

              <Box
                sx={{ display: nav === 0 ? "flex" : "none" }}
                className="filterBottomBox"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    mx={3}
                    className="LinkText"
                    onClick={() => {
                      setClearFilter(true);
                      setFilterState(defaultFilters);
                      setLatestDropVal("Latest Created");
                    }}
                  >
                    Clear
                  </Typography>
                  {/* <Typography mx={3} className="LinkText">
                    Apply
                  </Typography> */}
                  <Typography>
                    {paginationData?.totalItems} {t("commonTxt.21Matches")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="AllPSubBox allPList">
          <Box className="allPNav">
            <Box
              sx={{
                width: "40%",
              }}
            >
              <Typography className="pageHeaderText">
                {t("directoryUsers.allResaerchers")}
              </Typography>
            </Box>

            <Box className="workListNavItem workListSearchBar">
              <SearchBar
                handleFilterChange={handleFilterChange}
                val={filterState}
              />
            </Box>
            <Box className="workListNavItem workLatest">
              <LatestCreated
                setLatestDrop={setLatestDrop}
                latestCDrop={latestCDrop}
                handleFilterChange={handleFilterChange}
                setLatestDropVal={setLatestDropVal}
                latestDropVal={latestDropVal}
              />
            </Box>
          </Box>

          {/* table */}
          <Box className="workListBox">
            <Box className="haderRow" minWidth={"1266px"}>
              <Box className="Tabelsel navFsel TabelStatesel" minWidth={"25%"}>
                <Typography>{t("directoryUsers.researcherName")}</Typography>
              </Box>
              <Box className="Tabelsel TabelStatesel" minWidth={"20%"}>
                <Typography>{t("commonTxt.workspace")}</Typography>
              </Box>
              <Box className="Tabelsel" minWidth={"11%"}>
                <Typography>{t("commonTxt.status")}</Typography>
              </Box>
              <Box className="Tabelsel TabelStatesel" minWidth={"20%"}>
                <Typography>{t("commonTxt.mailAddress")}</Typography>
              </Box>
              <Box className="Tabelsel TabelStatesel" minWidth={"12%"}>
                <Typography>{t("directoryUsers.added")}</Typography>
              </Box>
              {/* <Box className="Tabelsel TabelStatesel" minWidth={"12%"}>
                <Typography>{t("commonTxt.action")}</Typography>
              </Box> */}
            </Box>

            {allResearchersList.length > 0 ? (
              allResearchersList?.map(
                (el, index) => (
                  console.log("el", el),
                  (
                    <Box key={index} className="TabelRow" minWidth={"1266px"}>
                      <Box
                        className="Tabelsel navFsel navtable"
                        minWidth={"25%"}
                      >
                        <Box
                          ref={workspaceOptionRef}
                          id="dot"
                          onClick={() => {
                            setWorkspaceOption(!workSpaceOption);
                            setOptionIndex(index);
                          }}
                          className={
                            workSpaceOption && index === optionIndex
                              ? "dot dotActive"
                              : "dot"
                          }
                        >
                          {
                            <Box
                              sx={{
                                display:
                                  workSpaceOption && index === optionIndex
                                    ? "flex"
                                    : "none",
                              }}
                              className="optionPop"
                            >
                              {el?.workspaces[0]?.name && (
                                <Box
                                  className="optionPopItem"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setResRemFromWorkspace(true);
                                    handleRemoveResearchersFrmWs(el);
                                    GoTop();
                                  }}
                                >
                                  {recycleBlack}
                                  <Typography className="optionPopItema">
                                    {t("commonTxt.remFrmWs")}
                                  </Typography>
                                </Box>
                              )}
                              <Box
                                className="optionPopItem"
                                onClick={() => {
                                  setEditResearcherPop(true);
                                  setAllResearchersData(el?._id);
                                }}
                              >
                                {editIconBlack}
                                <Typography className="optionPopItema">
                                  {t("commonTxt.editDetails")}
                                </Typography>
                              </Box>

                              <Box
                                className="optionPopItem"
                                onClick={() => {
                                  setDeleteResearcherPop(true);
                                  handleDeleteResearchers(el);
                                }}
                              >
                                {trashBlack}
                                <Typography className="optionPopItema">
                                  {t("commonTxt.delete")}
                                </Typography>
                              </Box>
                            </Box>
                          }
                        </Box>
                        <Box
                          onClick={() => handleOptionIndex(index)}
                          className="tabelAvatar"
                        >
                          {el?.profile_pic == "undefined" ||
                          el?.profile_pic == null ? (
                            <img src={DummyUser} />
                          ) : (
                            <img src={viewImage(el?.profile_pic)} />
                          )}
                        </Box>
                        <Typography>{el.name}</Typography>
                      </Box>
                      <Box className="Tabelsel TabelStatesel" minWidth={"20%"}>
                        <Typography>
                          {el.workspaces.length > 0
                            ? el?.workspaces[0]?.name
                            : "-"}
                        </Typography>
                      </Box>
                      <Box className="Tabelsel Tabelcentersel" minWidth={"11%"}>
                        <Typography>{el?.status}</Typography>
                      </Box>
                      <Box className="Tabelsel TabelStatesel" minWidth={"20%"}>
                        <Typography>{el?.email}</Typography>
                      </Box>
                      <Box className="Tabelsel TabelStatesel" minWidth={"12%"}>
                        <Typography>{formatDate(el?.created_at)}</Typography>
                      </Box>
                      {/* <Box
                    className="Tabelsel TabelStatesel ExportColumn"
                    minWidth={"12%"}
                  >
                    <img src={ExportVector} alt="" />
                    <Typography className="exportText">
                      {t("commonTxt.Export")}
                    </Typography>
                  </Box> */}
                    </Box>
                  )
                )
              )
            ) : (
              <Box></Box>
            )}
          </Box>
          <Pagination
            paginationData={paginationData}
            onPageChange={onPageChange}
          />
        </Box>
      </Box>
    </>
  );
}

// Collaborators Section
export function UserDirectoryCollaborators({
  setAddCollaboratorPop,
  addCollaboratorPop,
}) {
  const [nav, setNav] = useState(0);
  const popupRef = useRef(null);
  const [researcherDrop, setResearcherDrop] = useState(false);
  const [researcherDropVal, setResearcherDropVal] = useState();

  const [resRemFromWorkspace, setResRemFromWorkspace] = useState(false);
  const [removefromWorkspace, setRemFromWorkspace] = useState(false);
  const [colRemFromWorkspace, setColRemFromWorkspace] = useState(false);
  const [obsRemFromWorkspace, setObsRemFromWorkspace] = useState(false);
  const [partRemFromWorkspace, setPartRemFromWorkspace] = useState(false);

  const [workspaceNameDrop, setWorkspaceNameDrop] = useState(false);
  const [workspaceNameDropVal, setWorkspaceNameDropVal] = useState();

  const [workspaceStatusDrop, setWorkspaceStatusDrop] = useState(false);
  const [workspaceStatusDropVal, setWorkspaceStatusDropVal] = useState();

  const [ParticipantsNavDrop, setParticipantsNavDrop] = useState(false);
  const [ParticipantsNavDropVal, setParticipantsNavDropVal] = useState();

  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Latest Created");

  const [optionIndex, setOptionIndex] = useState();
  const [workSpaceOption, setWorkspaceOption] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [deleteCollaboratorPop, setDeleteCollaboratorPop] = useState(false);

  const [editCollaboratorPop, setEditCollaboratorPop] = useState(false);
  const [allCollaboratorsList, setAllCollaboratorsList] = useState([]);
  const [reloadList, setReloadList] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [statusDrop, setStatusDrop] = useState(false);
  const [statusDropVal, setStatusDropVal] = useState("All");
  const [deleteCollaboratorsListData, setDeleteCollaboratorsListData] =
    useState("");
  const [removeWokspacesListData, setRemoveWokspacesListData] = useState(null);
  const [allCollaboratorData, setAllCollaboratorData] = useState("");

  const workspaceOptionRef = useRef(null);
  const latestDropRef = useRef(null);
  const wsName = useRef(null);
  const wsStatus = useRef(null);
  const wsNameStatusDrop = useRef(null);
  const statusDropRef = useRef(null);

  const defaultFilters = {
    search: "",
    sortBy: "created_at",
    sortOrder: -1,
    workspace_status: "",
    assigned_status: "",
    workspace: "",
  };

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 25,
    totalPages: 0,
    totalItems: 0,
  });

  const [filterState, setFilterState] = useState({ ...defaultFilters });

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const handleOptionIndex = (i) => {
    setOptionIndex(i);
    console.log(optionIndex);
  };
  console.log(optionIndex);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  // console.log("Clicked element:", event.target);
  // console.log("Workspace option ref:", workspaceOptionRef.current);

  //     if (
  //       workspaceOptionRef.current &&
  //       !workspaceOptionRef.current.contains(event.target) &&
  //       !event.target.classList.contains("optionPopItem") &&
  //       !event.target.classList.contains("optionPopItema")
  //     ) {
  //       setWorkspaceOption(false);
  //     }

  //     if (wsName || wsStatus) {
  //       setWorkspaceNameDrop(false);
  //       setWorkspaceStatusDrop(false);
  //     }

  //     if (latestDropRef) {
  //       setLatestDrop(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  let newArrayData;
  function printItemsByState(array, state) {
    let startIndex = state * 8;
    let endIndex = (state + 1) * 8;
    let itemsToPrint = array.slice(startIndex, endIndex);
    if (itemsToPrint.length > 0) {
      // console.log(itemsToPrint);
      newArrayData = itemsToPrint;
    } else {
      console.log("No items to print for the given state.");
    }
  }

  let pageNumver = 0;

  const handleNext = () => {
    pageNumver = pageNumver + 1;
    printItemsByState(DirectoryUsersData, pageNumver);
  };
  const handlePrev = () => {
    if (pageNumver < 0) {
      console.log(pageNumver);
      pageNumver = pageNumver - 1;
    }
    printItemsByState(DirectoryUsersData, pageNumver);
  };

  console.log(pageNumver);
  printItemsByState(DirectoryUsersData, pageNumver);

  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     if (popupRef.current && !popupRef.current.contains(event.target)) {
  //       setIsOpen(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleOutsideClick);

  //   return () => {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   };
  // }, []);

  useEffect(() => {
    getCollaborators(1);
  }, []);

  const handleFilterChange = (changedFilter) => {
    let newFilters = {
      ...filterState,
      ...changedFilter,
    };
    setFilterState(newFilters);
    getCollaborators(1, newFilters);
  };

  const getCollaborators = async (curPage, filters = filterState) => {
    return await axios
      .get(
        `/super-admin/admin/collaborators?page=${curPage}&search=${filters?.search}&workspace_status=${filters?.workspace_status}&sortBy=${filters.sortBy}&sortOrder=${filters?.sortOrder}&assigned_status=${filters?.assigned_status}&workspace=${filters?.workspace}`
      )
      .then((res) => {
        if (res.status) {
          setAllCollaboratorsList(res.data.data);
          setPaginationData({
            currentPage: res.data.pagination.currentPage,
            itemsPerPage: res.data.pagination.itemsPerPage,
            totalPages: res.data.pagination.totalPages,
            totalItems: res.data.pagination.totalItems,
          });
        }
      });
  };

  useEffect(() => {
    if (reloadList) {
      getCollaborators(1);
      setReloadList(false);
    }
  }, [reloadList]);

  const handleDeleteCollaborators = (val) => {
    setDeleteCollaboratorsListData(val);
  };

  const handleRemoveCollaboratorsFrmWs = (val) => {
    setRemoveWokspacesListData(val);
  };

  const onPageChange = (pageNum) => {
    getCollaborators(pageNum, filterState);
  };

  return (
    <>
      <RemoveFrmWSPop
        setColRemFromWorkspace={setColRemFromWorkspace}
        colRemFromWorkspace={colRemFromWorkspace}
        setRemFromWorkspace={setRemFromWorkspace}
        setResRemFromWorkspace={setResRemFromWorkspace}
        setObsRemFromWorkspace={setObsRemFromWorkspace}
        setPartRemFromWorkspace={setPartRemFromWorkspace}
        userTitle={newArrayData[optionIndex]?.title}
        userWorkspace={newArrayData[optionIndex]?.Workspace}
        removeWokspacesListData={removeWokspacesListData}
        setReloadList={setReloadList}
      />
      <Box className="AllParticipantsBox">
        <AddCollaboratorPop
          addCollaboratorPop={addCollaboratorPop}
          setAddCollaboratorPop={setAddCollaboratorPop}
          setReloadList={setReloadList}
          reloadList={reloadList}
        />
        <DeleteCollaboratorPop
          deleteCollaboratorPop={deleteCollaboratorPop}
          setDeleteCollaboratorPop={setDeleteCollaboratorPop}
          deleteCollaboratorsListData={deleteCollaboratorsListData}
          setReloadList={setReloadList}
        />
        <EditCollaborator
          setEditCollaboratorPop={setEditCollaboratorPop}
          editCollaboratorPop={editCollaboratorPop}
          setAllCollaboratorData={setAllCollaboratorData}
          allCollaboratorData={allCollaboratorData}
          setReloadList={setReloadList}
          reloadList={reloadList}
        />
        <Box className="AllPSubBox allPFilters">
          <Typography className="pageHeaderText">
            {t("commonTxt.filters")}
          </Typography>

          <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
            <Box
              sx={{ width: nav === 0 ? "100%" : "0px" }}
              className="basicFilterBox"
            >
              <Box
                className="filterBoxes"
                mb={3}
                sx={{
                  display: nav === 0 ? "flex" : "none",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box className="inputBoxWlabel inputBoxWlabelUsers">
                  <Typography className="inputLabel">
                    {t("commonTxt.workspace")}
                  </Typography>
                  <ReserchersWorkSpaceDropDown
                    ref={wsNameStatusDrop}
                    setWorkspaceNameDrop={setWorkspaceNameDrop}
                    workspaceNameDrop={workspaceNameDrop}
                    workspaceNameDropVal={workspaceNameDropVal}
                    setWorkspaceNameDropVal={setWorkspaceNameDropVal}
                    handleFilterChange={handleFilterChange}
                    setClearFilter={setClearFilter}
                    clearFilter={clearFilter}
                  />
                </Box>
                <Box className="inputBoxWlabel inputBoxWlabelUsers">
                  <Typography className="inputLabel">
                    {t("directoryUsers.wsStatus")}
                  </Typography>
                  <Status
                    ref={statusDropRef}
                    setStatusDrop={setStatusDrop}
                    statusDrop={statusDrop}
                    handleFilterChange={handleFilterChange}
                    setStatusDropVal={setStatusDropVal}
                    statusDropVal={statusDropVal}
                    setClearFilter={setClearFilter}
                    clearFilter={clearFilter}
                  />
                </Box>
              </Box>

              <Box
                sx={{ display: nav === 0 ? "flex" : "none" }}
                className="filterBottomBox"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    mx={3}
                    className="LinkText"
                    onClick={() => {
                      setClearFilter(true);
                      setFilterState(defaultFilters);
                      setLatestDropVal("Latest Created");
                    }}
                  >
                    Clear
                  </Typography>
                  <Typography>
                    {paginationData?.totalItems} {t("commonTxt.21Matches")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="AllPSubBox allPList">
          <Box className="allPNav">
            <Box
              sx={{
                width: "40%",
              }}
            >
              <Typography className="pageHeaderText">
                {t("directoryUsers.allCollaborators")}
              </Typography>
            </Box>

            <Box className="workListNavItem workListSearchBar">
              <SearchBar
                handleFilterChange={handleFilterChange}
                val={filterState}
              />
            </Box>
            <Box className="workListNavItem workLatest">
              <LatestCreated
                setLatestDrop={setLatestDrop}
                latestCDrop={latestCDrop}
                handleFilterChange={handleFilterChange}
                setLatestDropVal={setLatestDropVal}
                latestDropVal={latestDropVal}
              />
            </Box>
          </Box>

          {/* table */}
          <Box className="workListBox">
            <Box className="haderRow" minWidth={"1270px"}>
              <Box className="Tabelsel navFsel" minWidth={"290px"}>
                <Typography>{t("directoryUsers.collaboratorName")}</Typography>
              </Box>
              <Box className="Tabelsel" minWidth={"230px"}>
                <Typography>{t("commonTxt.workspace")}</Typography>
              </Box>
              <Box className="Tabelsel" minWidth={"230px"}>
                <Typography>{t("commonTxt.status")}</Typography>
              </Box>
              <Box className="Tabelsel" minWidth={"230px"}>
                <Typography>{t("commonTxt.mailAddress")}</Typography>
              </Box>
              <Box className="Tabelsel" minWidth={"230px"}>
                <Typography>{t("directoryUsers.added")}</Typography>
              </Box>
            </Box>
            {allCollaboratorsList.length > 0 ? (
              allCollaboratorsList?.map((el, index) => (
                <Box key={index} className="TabelRow" minWidth={"1270px"}>
                  <Box className="Tabelsel navFsel navtable" minWidth={"290px"}>
                    <Box
                      ref={workspaceOptionRef}
                      id="dot"
                      onClick={() => {
                        setWorkspaceOption(!workSpaceOption);
                        setOptionIndex(index);
                      }}
                      className={
                        workSpaceOption && index === optionIndex
                          ? "dot dotActive"
                          : "dot"
                      }
                    >
                      {
                        <Box
                          sx={{
                            display:
                              workSpaceOption && index === optionIndex
                                ? "flex"
                                : "none",
                          }}
                          className="optionPop"
                        >
                          {el?.workspaces[0]?.name && (
                            <Box
                              className="optionPopItem"
                              onClick={(event) => {
                                event.stopPropagation();
                                setColRemFromWorkspace(true);
                                handleRemoveCollaboratorsFrmWs(el);
                                GoTop();
                              }}
                            >
                              {recycleBlack}
                              <Typography className="optionPopItema">
                                {t("commonTxt.remFrmWs")}
                              </Typography>
                            </Box>
                          )}
                          <Box
                            className="optionPopItem"
                            onClick={() => {
                              setEditCollaboratorPop(true);
                              setAllCollaboratorData(el?._id);
                            }}
                          >
                            {editIconBlack}
                            <Typography className="optionPopItema">
                              {t("commonTxt.editDetails")}
                            </Typography>
                          </Box>

                          <Box
                            className="optionPopItem"
                            onClick={() => {
                              setDeleteCollaboratorPop(true);
                              handleDeleteCollaborators(el);
                            }}
                          >
                            {trashBlack}
                            <Typography className="optionPopItema">
                              {t("commonTxt.delete")}
                            </Typography>
                          </Box>
                        </Box>
                      }
                    </Box>
                    <Box
                      onClick={() => handleOptionIndex(index)}
                      className="tabelAvatar"
                    >
                      {el?.profile_pic == "undefined" ||
                      el?.profile_pic == null ? (
                        <img src={DummyUser} />
                      ) : (
                        <img src={viewImage(el?.profile_pic)} />
                      )}
                    </Box>
                    <Typography>{el?.name}</Typography>
                  </Box>
                  <Box
                    className="Tabelsel TabelselItemCenter"
                    minWidth={"230px"}
                  >
                    <Typography>
                      {el.workspaces.length > 0 ? el?.workspaces[0]?.name : "-"}
                    </Typography>
                  </Box>
                  <Box
                    className="Tabelsel TabelselItemCenter"
                    minWidth={"230px"}
                  >
                    <Typography>{el?.status}</Typography>
                  </Box>
                  <Box
                    className="Tabelsel TabelselItemCenter"
                    minWidth={"230px"}
                  >
                    <Typography>{el?.email}</Typography>
                  </Box>
                  <Box
                    className="Tabelsel TabelselItemCenter"
                    minWidth={"230px"}
                  >
                    <Typography>{formatDate(el?.updated_at)}</Typography>
                  </Box>
                </Box>
              ))
            ) : (
              <Box></Box>
            )}
          </Box>
          <Pagination
            paginationData={paginationData}
            onPageChange={onPageChange}
          />
        </Box>
      </Box>
    </>
  );
}
// Collaborators Section

// Observers Section
export function UserDirectoryObservers({ setAddObserverPop, addObserverPop }) {
  const [nav, setNav] = useState(0);
  const popupRef = useRef(null);

  const [researcherDrop, setResearcherDrop] = useState(false);
  const [researcherDropVal, setResearcherDropVal] = useState();

  const [resRemFromWorkspace, setResRemFromWorkspace] = useState(false);
  const [removefromWorkspace, setRemFromWorkspace] = useState(false);
  const [colRemFromWorkspace, setColRemFromWorkspace] = useState(false);
  const [obsRemFromWorkspace, setObsRemFromWorkspace] = useState(false);
  const [partRemFromWorkspace, setPartRemFromWorkspace] = useState(false);

  const [workspaceNameDrop, setWorkspaceNameDrop] = useState(false);
  const [workspaceNameDropVal, setWorkspaceNameDropVal] = useState();

  const [workspaceStatusDrop, setWorkspaceStatusDrop] = useState(false);
  const [workspaceStatusDropVal, setWorkspaceStatusDropVal] = useState();

  const [ParticipantsNavDrop, setParticipantsNavDrop] = useState(false);
  const [ParticipantsNavDropVal, setParticipantsNavDropVal] = useState();

  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Latest Created");
  const [optionIndex, setOptionIndex] = useState();
  const [workSpaceOption, setWorkspaceOption] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [allObserverLists, setAllObserverLists] = useState([]);
  const [allObserverData, setAllObserverData] = useState("");
  const [clearFilter, setClearFilter] = useState(false);
  const [statusDrop, setStatusDrop] = useState(false);
  const [statusDropVal, setStatusDropVal] = useState("All");
  const [removeWokspacesListData, setRemoveWokspacesListData] = useState(null);
  const [reloadList, setReloadList] = useState(false);
  const [deleteObserverPop, setDeleteObserverPop] = useState(false);

  const [editObserverPop, setEditObserverPop] = useState(false);
  const [deleteObserversListData, setDeleteObserversListData] = useState("");

  const workspaceOptionRef = useRef(null);
  const latestDropRef = useRef(null);
  const wsNameStatusDrop = useRef(null);
  const statusDropRef = useRef(null);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const handleOptionIndex = (i) => {
    setOptionIndex(i);
    console.log(optionIndex);
  };
  console.log(optionIndex);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  // console.log("Clicked element:", event.target);
  // console.log("Workspace option ref:", workspaceOptionRef.current);

  //   if (
  //     workspaceOptionRef.current &&
  //     !workspaceOptionRef.current.contains(event.target) &&
  //     !event.target.classList.contains("optionPopItem") &&
  //     !event.target.classList.contains("optionPopItema")
  //   ) {
  //     setWorkspaceOption(false);
  //   }

  //   if (latestDropRef) {
  //     setLatestDrop(false);
  //   }
  // };

  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  let newArrayData;
  function printItemsByState(array, state) {
    let startIndex = state * 8;
    let endIndex = (state + 1) * 8;
    let itemsToPrint = array.slice(startIndex, endIndex);
    if (itemsToPrint.length > 0) {
      // console.log(itemsToPrint);
      newArrayData = itemsToPrint;
    } else {
      console.log("No items to print for the given state.");
    }
  }

  let pageNumver = 0;

  const handleNext = () => {
    pageNumver = pageNumver + 1;
    printItemsByState(DirectoryUsersData, pageNumver);
  };
  const handlePrev = () => {
    if (pageNumver < 0) {
      console.log(pageNumver);
      pageNumver = pageNumver - 1;
    }
    printItemsByState(DirectoryUsersData, pageNumver);
  };

  console.log(pageNumver);
  printItemsByState(DirectoryUsersData, pageNumver);

  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     if (popupRef.current && !popupRef.current.contains(event.target)) {
  //       setIsOpen(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleOutsideClick);

  //   return () => {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   };
  // }, []);

  const defaultFilters = {
    search: "",
    sortBy: "created_at",
    sortOrder: -1,
    workspace_status: "",
    assigned_status: "",
    workspace: "",
  };

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 25,
    totalPages: 0,
    totalItems: 0,
  });

  const [filterState, setFilterState] = useState({ ...defaultFilters });

  const handleFilterChange = (changedFilter) => {
    let newFilters = {
      ...filterState,
      ...changedFilter,
    };
    setFilterState(newFilters);
    getObservers(1, newFilters);
  };

  useEffect(() => {
    getObservers(1);
  }, []);

  useEffect(() => {
    if (reloadList) {
      getObservers(1);
      setReloadList(false);
    }
  }, [reloadList]);

  const getObservers = async (curPage, filters = filterState) => {
    return await axios
      .get(
        `/super-admin/admin/observers?page=${curPage}&search=${filters?.search}&workspace_status=${filters?.workspace_status}&sortBy=${filters.sortBy}&sortOrder=${filters?.sortOrder}&assigned_status=${filters?.assigned_status}&workspace=${filters?.workspace}`
      )
      .then((res) => {
        if (res.status) {
          setAllObserverLists(res.data.data);
          setPaginationData({
            currentPage: res.data.pagination.currentPage,
            itemsPerPage: res.data.pagination.itemsPerPage,
            totalPages: res.data.pagination.totalPages,
            totalItems: res.data.pagination.totalItems,
          });
        }
      });
  };

  const handleRemoveObserverFrmWs = (val) => {
    setRemoveWokspacesListData(val);
  };

  const handleDeleteObserver = (val) => {
    setDeleteObserversListData(val);
  };

  const onPageChange = (pageNum) => {
    getObservers(pageNum, filterState);
  };

  return (
    <>
      <RemoveFrmWSPop
        setObsRemFromWorkspace={setObsRemFromWorkspace}
        obsRemFromWorkspace={obsRemFromWorkspace}
        setRemFromWorkspace={setRemFromWorkspace}
        setResRemFromWorkspace={setResRemFromWorkspace}
        setColRemFromWorkspace={setColRemFromWorkspace}
        setPartRemFromWorkspace={setPartRemFromWorkspace}
        userTitle={newArrayData[optionIndex]?.title}
        userWorkspace={newArrayData[optionIndex]?.Workspace}
        removeWokspacesListData={removeWokspacesListData}
        setReloadList={setReloadList}
      />
      <Box className="AllParticipantsBox">
        <AddObserverPop
          addObserverPop={addObserverPop}
          setAddObserverPop={setAddObserverPop}
          setReloadList={setReloadList}
          reloadList={reloadList}
        />
        <DeleteObserverPop
          deleteObserverPop={deleteObserverPop}
          setDeleteObserverPop={setDeleteObserverPop}
          deleteObserversListData={deleteObserversListData}
          setReloadList={setReloadList}
        />
        <EditObserverPop
          setEditObserverPop={setEditObserverPop}
          editObserverPop={editObserverPop}
          allObserverData={allObserverData}
          setAllObserverData={setAllObserverData}
          reloadList={reloadList}
          setReloadList={setReloadList}
        />
        <Box className="AllPSubBox allPFilters">
          <Typography className="pageHeaderText">
            {t("commonTxt.filterHead")}
          </Typography>

          <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
            <Box
              sx={{ width: nav === 0 ? "100%" : "0px" }}
              className="basicFilterBox"
            >
              <Box
                className="filterBoxes"
                mb={3}
                sx={{
                  display: nav === 0 ? "flex" : "none",
                }}
              >
                <Box className="inputBoxWlabel inputBoxWlabelUsers">
                  <Typography className="inputLabel">
                    {t("commonTxt.workspace")}
                  </Typography>
                  <ReserchersWorkSpaceDropDown
                    ref={wsNameStatusDrop}
                    setWorkspaceNameDrop={setWorkspaceNameDrop}
                    workspaceNameDrop={workspaceNameDrop}
                    workspaceNameDropVal={workspaceNameDropVal}
                    setWorkspaceNameDropVal={setWorkspaceNameDropVal}
                    handleFilterChange={handleFilterChange}
                    setClearFilter={setClearFilter}
                    clearFilter={clearFilter}
                  />
                  {/* <Box
                    className="inputAppDrop"
                    onClick={() => setWorkspaceNameDrop(!workspaceNameDrop)}
                  >
                    <Typography>
                      {workspaceNameDropVal || "Filter by Workspace Name"}
                    </Typography>
                    <img
                      style={{ rotate: workspaceNameDrop ? "180deg" : "0deg" }}
                      src={dropArrow}
                    />
                    {/* pop
                    <Box
                      sx={{ height: workspaceNameDrop ? "auto" : "0px" }}
                      className="statusPop"
                    >
                      <Box
                        onClick={() =>
                          setWorkspaceNameDropVal("Richard's Workspace")
                        }
                        className="statusPopItem"
                      >
                        <Typography>Richard's Workspace</Typography>
                      </Box>
                      <Box
                        onClick={() =>
                          setWorkspaceNameDropVal("Lucy's Workspace")
                        }
                        className="statusPopItem"
                      >
                        <Typography>Lucy's Workspace</Typography>
                      </Box>
                      <Box
                        onClick={() =>
                          setWorkspaceNameDropVal("Abc Ltd's Workspace")
                        }
                        className="statusPopItem"
                      >
                        <Typography>Abc Ltd's Workspace</Typography>
                      </Box>
                      <Box
                        onClick={() =>
                          setWorkspaceNameDropVal("Loren's Workspace")
                        }
                        className="statusPopItem"
                      >
                        <Typography>Loren's Workspace</Typography>
                      </Box>
                    </Box>
                  </Box> */}
                </Box>
                <Box className="inputBoxWlabel inputBoxWlabelUsers">
                  <Typography className="inputLabel">
                    {t("directoryUsers.wsStatus")}
                  </Typography>
                  <Status
                    ref={statusDropRef}
                    setStatusDrop={setStatusDrop}
                    statusDrop={statusDrop}
                    handleFilterChange={handleFilterChange}
                    setStatusDropVal={setStatusDropVal}
                    statusDropVal={statusDropVal}
                    setClearFilter={setClearFilter}
                    clearFilter={clearFilter}
                  />
                  {/* <Box
                    className="inputAppDrop"
                    onClick={() => setWorkspaceStatusDrop(!workspaceStatusDrop)}
                  >
                    <Typography>
                      {workspaceStatusDropVal || "Filter by Workspace Status"}
                    </Typography>
                    <img
                      style={{
                        rotate: workspaceStatusDrop ? "180deg" : "0deg",
                      }}
                      src={dropArrow}
                    />
                    pop
                    <Box
                      className="statusPop"
                      sx={{ height: workspaceStatusDrop ? "auto" : "0px" }}
                    >
                      <Box
                        onClick={() =>
                          setWorkspaceStatusDropVal("Open Workspaces")
                        }
                        className="statusPopItem"
                      >
                        <Typography>{t("directoryUsers.openWS")}</Typography>
                      </Box>
                      <Box
                        onClick={() =>
                          setWorkspaceStatusDropVal("Closed Workspaces")
                        }
                        className="statusPopItem"
                      >
                        <Typography>{t("directoryUsers.closeWS")}</Typography>
                      </Box>
                      <Box
                        onClick={() =>
                          setWorkspaceStatusDropVal("Archived Workspaces")
                        }
                        className="statusPopItem"
                      >
                        <Typography>
                          {t("directoryUsers.archivedWS")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box> */}
                </Box>
              </Box>

              <Box
                sx={{ display: nav === 0 ? "flex" : "none" }}
                className="filterBottomBox"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    mx={3}
                    className="LinkText"
                    onClick={() => {
                      setClearFilter(true);
                      setFilterState(defaultFilters);
                      setLatestDropVal("Latest Created");
                    }}
                  >
                    Clear
                  </Typography>
                  <Typography>
                    {paginationData?.totalItems} {t("commonTxt.21Matches")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="AllPSubBox allPList">
          <Box className="allPNav">
            <Box
              sx={{
                width: "40%",
              }}
            >
              <Typography className="pageHeaderText">
                {t("directoryUsers.allObservers")}
              </Typography>
            </Box>

            <Box className="workListNavItem workListSearchBar">
              <SearchBar
                handleFilterChange={handleFilterChange}
                val={filterState}
              />
            </Box>
            <Box className="workListNavItem workLatest">
              <LatestCreated
                setLatestDrop={setLatestDrop}
                latestCDrop={latestCDrop}
                handleFilterChange={handleFilterChange}
                setLatestDropVal={setLatestDropVal}
                latestDropVal={latestDropVal}
              />
            </Box>
          </Box>

          {/* table */}
          <Box className="workListBox">
            <Box className="haderRow" minWidth={"1270px"}>
              <Box className="Tabelsel navFsel" minWidth={"290px"}>
                <Typography>{t("directoryUsers.observerName")}</Typography>
              </Box>
              <Box className="Tabelsel" minWidth={"230px"}>
                <Typography>{t("commonTxt.workspace")}</Typography>
              </Box>
              <Box className="Tabelsel" minWidth={"230px"}>
                <Typography>{t("commonTxt.status")}</Typography>
              </Box>
              <Box className="Tabelsel" minWidth={"230px"}>
                <Typography>{t("commonTxt.mailAddress")}</Typography>
              </Box>
              <Box className="Tabelsel" minWidth={"230px"}>
                <Typography>{t("directoryUsers.added")}</Typography>
              </Box>
            </Box>
            {allObserverLists.length > 0 ? (
              allObserverLists?.map((el, index) => (
                <Box key={index} className="TabelRow" minWidth={"1270px"}>
                  <Box className="Tabelsel navFsel navtable" minWidth={"290px"}>
                    <Box
                      ref={workspaceOptionRef}
                      id="dot"
                      onClick={() => {
                        setWorkspaceOption(!workSpaceOption);
                        setOptionIndex(index);
                      }}
                      className={
                        workSpaceOption && index === optionIndex
                          ? "dot dotActive"
                          : "dot"
                      }
                    >
                      {
                        <Box
                          sx={{
                            display:
                              workSpaceOption && index === optionIndex
                                ? "flex"
                                : "none",
                          }}
                          className="optionPop"
                        >
                          {el?.workspaces[0]?.name && (
                            <Box
                              className="optionPopItem"
                              onClick={(event) => {
                                event.stopPropagation();
                                setObsRemFromWorkspace(true);
                                handleRemoveObserverFrmWs(el);
                                GoTop();
                              }}
                            >
                              {recycleBlack}
                              <Typography className="optionPopItema">
                                {t("commonTxt.remFrmWs")}
                              </Typography>
                            </Box>
                          )}
                          <Box
                            className="optionPopItem"
                            onClick={() => {
                              setEditObserverPop(true);
                              setAllObserverData(el?._id);
                            }}
                          >
                            {editIconBlack}
                            <Typography className="optionPopItema">
                              {t("commonTxt.editDetails")}
                            </Typography>
                          </Box>

                          <Box
                            className="optionPopItem"
                            onClick={() => {
                              setDeleteObserverPop(true);
                              handleDeleteObserver(el);
                            }}
                          >
                            {trashBlack}
                            <Typography className="optionPopItema">
                              {t("commonTxt.delete")}
                            </Typography>
                          </Box>
                        </Box>
                      }
                    </Box>
                    <Box
                      onClick={() => handleOptionIndex(index)}
                      className="tabelAvatar"
                    >
                      {el?.profile_pic == "undefined" ||
                      el?.profile_pic == null ? (
                        <img src={DummyUser} />
                      ) : (
                        <img src={viewImage(el?.profile_pic)} />
                      )}
                    </Box>
                    <Typography>{el?.name}</Typography>
                  </Box>
                  <Box
                    className="Tabelsel TabelselItemCenter"
                    minWidth={"230px"}
                  >
                    <Typography>
                      {el.workspaces.length > 0 ? el?.workspaces[0]?.name : "-"}
                    </Typography>
                  </Box>
                  <Box
                    className="Tabelsel TabelselItemCenter"
                    minWidth={"230px"}
                  >
                    <Typography>{el?.status}</Typography>
                  </Box>
                  <Box
                    className="Tabelsel TabelselItemCenter"
                    minWidth={"230px"}
                  >
                    <Typography>{el?.email}</Typography>
                  </Box>
                  <Box
                    className="Tabelsel TabelselItemCenter"
                    minWidth={"230px"}
                  >
                    {/* <Typography>{formatDate(el?.createdAt)}</Typography> */}
                    <Typography>{formatDate(el?.updated_at)}</Typography>
                  </Box>
                </Box>
              ))
            ) : (
              <Box></Box>
            )}
          </Box>
          <Pagination
            paginationData={paginationData}
            onPageChange={onPageChange}
          />
        </Box>
      </Box>
    </>
  );
}
// observer Section

// Participants Section
export function UserDirectoryParticipants({
  setAddParticipantPop,
  addParticipantPop,
}) {
  const [nav, setNav] = useState(0);
  const popupRef = useRef(null);

  const [workspaceNameDrop, setWorkspaceNameDrop] = useState(false);
  const [workspaceNameDropVal, setWorkspaceNameDropVal] = useState();

  const [resRemFromWorkspace, setResRemFromWorkspace] = useState(false);
  const [removefromWorkspace, setRemFromWorkspace] = useState(false);
  const [colRemFromWorkspace, setColRemFromWorkspace] = useState(false);
  const [obsRemFromWorkspace, setObsRemFromWorkspace] = useState(false);
  const [partRemFromWorkspace, setPartRemFromWorkspace] = useState(false);

  const [workspaceStatusDrop, setWorkspaceStatusDrop] = useState(false);
  const [workspaceStatusDropVal, setWorkspaceStatusDropVal] = useState();

  const [ParticipantsNavDrop, setParticipantsNavDrop] = useState(false);
  const [ParticipantsNavDropVal, setParticipantsNavDropVal] = useState();

  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Latest Created");

  const [optionIndex, setOptionIndex] = useState();
  const [workSpaceOption, setWorkspaceOption] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [deleteParticipantPop, setDeleteParticipantPop] = useState(false);

  const [editParticipantPop, setEditParticipantPop] = useState(false);
  const [allParticipantsLists, setAllParticipantsLists] = useState([]);
  const [allParticipantsData, setAllParticipantsData] = useState("");
  const [clearFilter, setClearFilter] = useState(false);
  const [statusDrop, setStatusDrop] = useState(false);
  const [statusDropVal, setStatusDropVal] = useState("All");
  const [removeWokspacesListData, setRemoveWokspacesListData] = useState(null);
  const [reloadList, setReloadList] = useState(false);
  const [deleteParticipantsListData, setDeleteParticipantsData] = useState("");

  const workspaceOptionRef = useRef(null);
  const latestDropRef = useRef(null);
  const wsNameStatusDrop = useRef(null);
  const statusDropRef = useRef(null);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const handleOptionIndex = (i) => {
    setOptionIndex(i);
    console.log(optionIndex);
  };
  console.log(optionIndex);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  // console.log("Clicked element:", event.target);
  // console.log("Workspace option ref:", workspaceOptionRef.current);

  //     if (
  //       workspaceOptionRef.current &&
  //       !workspaceOptionRef.current.contains(event.target) &&
  //       !event.target.classList.contains("optionPopItem") &&
  //       !event.target.classList.contains("optionPopItema")
  //     ) {
  //       setWorkspaceOption(false);
  //     }

  //     if (latestDropRef) {
  //       setLatestDrop(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  let newArrayData;
  function printItemsByState(array, state) {
    let startIndex = state * 8;
    let endIndex = (state + 1) * 8;
    let itemsToPrint = array.slice(startIndex, endIndex);
    if (itemsToPrint.length > 0) {
      // console.log(itemsToPrint);
      newArrayData = itemsToPrint;
    } else {
      console.log("No items to print for the given state.");
    }
  }

  let pageNumver = 0;

  const handleNext = () => {
    pageNumver = pageNumver + 1;
    printItemsByState(DirectoryUsersData, pageNumver);
  };
  const handlePrev = () => {
    if (pageNumver < 0) {
      console.log(pageNumver);
      pageNumver = pageNumver - 1;
    }
    printItemsByState(DirectoryUsersData, pageNumver);
  };

  console.log(pageNumver);
  printItemsByState(DirectoryUsersData, pageNumver);

  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     if (popupRef.current && !popupRef.current.contains(event.target)) {
  //       setIsOpen(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleOutsideClick);

  //   return () => {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   };
  // }, []);

  const defaultFilters = {
    search: "",
    sortBy: "created_at",
    sortOrder: -1,
    workspace_status: "",
    assigned_status: "",
    workspace: "",
  };

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 25,
    totalPages: 0,
    totalItems: 0,
  });

  const [filterState, setFilterState] = useState({ ...defaultFilters });

  const handleFilterChange = (changedFilter) => {
    let newFilters = {
      ...filterState,
      ...changedFilter,
    };
    setFilterState(newFilters);
    getParticipants(1, newFilters);
  };

  useEffect(() => {
    getParticipants(1);
  }, []);

  useEffect(() => {
    if (reloadList) {
      getParticipants(1);
      setReloadList(false);
    }
  }, [reloadList]);

  const getParticipants = async (curPage, filters = filterState) => {
    return await axios
      .get(
        `/super-admin/admin/participants?page=${curPage}&search=${filters?.search}&workspace_status=${filters?.workspace_status}&sortBy=${filters.sortBy}&sortOrder=${filters?.sortOrder}&assigned_status=${filters?.assigned_status}&workspace=${filters?.workspace}&separate_workspace_row=true`
      )
      .then((res) => {
        if (res.status) {
          setAllParticipantsLists(res.data.data);
          setPaginationData({
            currentPage: res.data.pagination.currentPage,
            itemsPerPage: res.data.pagination.itemsPerPage,
            totalPages: res.data.pagination.totalPages,
            totalItems: res.data.pagination.totalItems,
          });
        }
      });
  };

  const handleRemoveParticipantsFrmWs = (val) => {
    setRemoveWokspacesListData(val);
  };

  const handleDeleteParticipants = (val) => {
    setDeleteParticipantsData(val);
  };

  const onPageChange = (pageNum) => {
    getParticipants(pageNum, filterState);
  };

  return (
    <>
      <RemoveFrmWSPop
        setPartRemFromWorkspace={setPartRemFromWorkspace}
        partRemFromWorkspace={partRemFromWorkspace}
        setRemFromWorkspace={setRemFromWorkspace}
        setResRemFromWorkspace={setResRemFromWorkspace}
        setColRemFromWorkspace={setColRemFromWorkspace}
        setObsRemFromWorkspace={setObsRemFromWorkspace}
        userTitle={newArrayData[optionIndex]?.title}
        userWorkspace={newArrayData[optionIndex]?.Workspace}
        removeWokspacesListData={removeWokspacesListData}
        setReloadList={setReloadList}
      />
      <Box className="AllParticipantsBox">
        <AddParticipantPop
          addParticipantPop={addParticipantPop}
          setAddParticipantPop={setAddParticipantPop}
          setReloadList={setReloadList}
          reloadList={reloadList}
        />
        <DeleteParticipantPop
          deleteParticipantPop={deleteParticipantPop}
          setDeleteParticipantPop={setDeleteParticipantPop}
          deleteParticipantsListData={deleteParticipantsListData}
          setReloadList={setReloadList}
        />
        <EditParticipantPop
          setEditParticipantPop={setEditParticipantPop}
          editParticipantPop={editParticipantPop}
          allParticipantsData={allParticipantsData}
          setAllParticipantsData={setAllParticipantsData}
          reloadList={reloadList}
          setReloadList={setReloadList}
        />
        <Box className="AllPSubBox allPFilters">
          <Typography className="pageHeaderText">
            {t("commonTxt.filterHead")}
          </Typography>

          <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
            <Box
              sx={{ width: nav === 0 ? "100%" : "0px" }}
              className="basicFilterBox"
            >
              <Box
                className="filterBoxes"
                mb={3}
                sx={{
                  display: nav === 0 ? "flex" : "none",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box className="inputBoxWlabel inputBoxWlabelUsers">
                  <Typography className="inputLabel">
                    {t("commonTxt.workspace")}
                  </Typography>
                  <ReserchersWorkSpaceDropDown
                    ref={wsNameStatusDrop}
                    setWorkspaceNameDrop={setWorkspaceNameDrop}
                    workspaceNameDrop={workspaceNameDrop}
                    workspaceNameDropVal={workspaceNameDropVal}
                    setWorkspaceNameDropVal={setWorkspaceNameDropVal}
                    handleFilterChange={handleFilterChange}
                    setClearFilter={setClearFilter}
                    clearFilter={clearFilter}
                  />
                  {/* <Box
                    className="inputAppDrop"
                    onClick={() => setWorkspaceNameDrop(!workspaceNameDrop)}
                  >
                    <Typography>
                      {workspaceNameDropVal || "Filter by Workspace Name"}
                    </Typography>
                    <img
                      style={{ rotate: workspaceNameDrop ? "180deg" : "0deg" }}
                      src={dropArrow}
                    />
                    {/* pop 
                    <Box
                      sx={{ height: workspaceNameDrop ? "auto" : "0px" }}
                      className="statusPop"
                    >
                      <Box
                        onClick={() =>
                          setWorkspaceNameDropVal("Richard's Workspace")
                        }
                        className="statusPopItem"
                      >
                        <Typography>Richard's Workspace</Typography>
                      </Box>
                      <Box
                        onClick={() =>
                          setWorkspaceNameDropVal("Lucy's Workspace")
                        }
                        className="statusPopItem"
                      >
                        <Typography>Lucy's Workspace</Typography>
                      </Box>
                      <Box
                        onClick={() =>
                          setWorkspaceNameDropVal("Abc Ltd's Workspace")
                        }
                        className="statusPopItem"
                      >
                        <Typography>Abc Ltd's Workspace</Typography>
                      </Box>
                      <Box
                        onClick={() =>
                          setWorkspaceNameDropVal("Loren's Workspace")
                        }
                        className="statusPopItem"
                      >
                        <Typography>Loren's Workspace</Typography>
                      </Box>
                    </Box>
                  </Box> */}
                </Box>
                <Box className="inputBoxWlabel inputBoxWlabelUsers">
                  <Typography className="inputLabel">
                    {t("commonTxt.status")}
                  </Typography>
                  <Status
                    ref={statusDropRef}
                    setStatusDrop={setStatusDrop}
                    statusDrop={statusDrop}
                    handleFilterChange={handleFilterChange}
                    setStatusDropVal={setStatusDropVal}
                    statusDropVal={statusDropVal}
                    setClearFilter={setClearFilter}
                    clearFilter={clearFilter}
                  />
                  {/* <Box
                    className="inputAppDrop"
                    onClick={() => setWorkspaceStatusDrop(!workspaceStatusDrop)}
                  >
                    <Typography>
                      {workspaceStatusDropVal || "Filter by Workspace Status"}
                    </Typography>
                    <img
                      style={{
                        rotate: workspaceStatusDrop ? "180deg" : "0deg",
                      }}
                      src={dropArrow}
                    />
                    {/* pop 
                    <Box
                      className="statusPop"
                      sx={{ height: workspaceStatusDrop ? "auto" : "0px" }}
                    >
                      <Box
                        onClick={() =>
                          setWorkspaceStatusDropVal("Open Workspaces")
                        }
                        className="statusPopItem"
                      >
                        <Typography>{t("directoryUsers.openWS")}</Typography>
                      </Box>
                      <Box
                        onClick={() =>
                          setWorkspaceStatusDropVal("Closed Workspaces")
                        }
                        className="statusPopItem"
                      >
                        <Typography>{t("directoryUsers.closeWS")}</Typography>
                      </Box>
                      <Box
                        onClick={() =>
                          setWorkspaceStatusDropVal("Archived Workspaces")
                        }
                        className="statusPopItem"
                      >
                        <Typography>
                          {t("directoryUsers.archivedWS")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box> */}
                </Box>
              </Box>

              <Box
                sx={{ display: nav === 0 ? "flex" : "none" }}
                className="filterBottomBox"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                ></Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    mx={3}
                    className="LinkText"
                    onClick={() => {
                      setClearFilter(true);
                      setFilterState(defaultFilters);
                      setLatestDropVal("Latest Created");
                    }}
                  >
                    Clear
                  </Typography>
                  <Typography>
                    {paginationData?.totalItems} {t("commonTxt.21Matches")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="AllPSubBox allPList">
          <Box className="allPNav">
            <Box
              sx={{
                width: "40%",
              }}
            >
              <Typography className="pageHeaderText">
                {t("directoryUsers.allParticipants")}
              </Typography>
            </Box>

            <Box className="workListNavItem workListSearchBar">
              <SearchBar
                handleFilterChange={handleFilterChange}
                val={filterState}
              />
            </Box>
            <Box className="workListNavItem workLatest">
              <LatestCreated
                setLatestDrop={setLatestDrop}
                latestCDrop={latestCDrop}
                handleFilterChange={handleFilterChange}
                setLatestDropVal={setLatestDropVal}
                latestDropVal={latestDropVal}
              />
            </Box>
          </Box>

          {/* table */}
          <Box className="workListBox">
            <Box className="haderRow" minWidth={"1270px"}>
              <Box className="Tabelsel navFsel" minWidth={"290px"}>
                <Typography>{t("directoryUsers.participantName")}</Typography>
              </Box>
              <Box className="Tabelsel" minWidth={"230px"}>
                <Typography>{t("commonTxt.workspace")}</Typography>
              </Box>
              <Box className="Tabelsel" minWidth={"230px"}>
                <Typography>{t("commonTxt.status")}</Typography>
              </Box>
              <Box className="Tabelsel" minWidth={"230px"}>
                <Typography>{t("commonTxt.mailAddress")}</Typography>
              </Box>
              <Box className="Tabelsel" minWidth={"230px"}>
                <Typography>{t("directoryUsers.added")}</Typography>
              </Box>
            </Box>
            {allParticipantsLists.length > 0 ? (
              allParticipantsLists?.map((el, index) => (
                <Box key={index} className="TabelRow" minWidth={"1270px"}>
                  <Box className="Tabelsel navFsel navtable" minWidth={"290px"}>
                    <Box
                      ref={workspaceOptionRef}
                      id="dot"
                      onClick={() => {
                        setWorkspaceOption(!workSpaceOption);
                        setOptionIndex(index);
                      }}
                      className={
                        workSpaceOption && index === optionIndex
                          ? "dot dotActive"
                          : "dot"
                      }
                    >
                      {
                        <Box
                          sx={{
                            display:
                              workSpaceOption && index === optionIndex
                                ? "flex"
                                : "none",
                          }}
                          className="optionPop"
                        >
                          {el?.workspaces[0]?.name && (
                            <Box
                              className="optionPopItem"
                              onClick={(event) => {
                                event.stopPropagation();
                                setPartRemFromWorkspace(true);
                                handleRemoveParticipantsFrmWs(el);
                                GoTop();
                              }}
                            >
                              {recycleBlack}
                              <Typography className="optionPopItema">
                                {t("commonTxt.remFrmWs")}
                              </Typography>
                            </Box>
                          )}
                          <Box
                            className="optionPopItem"
                            onClick={() => {
                              setEditParticipantPop(true);
                              setAllParticipantsData(el?._id);
                            }}
                          >
                            {editIconBlack}
                            <Typography className="optionPopItema">
                              {t("commonTxt.editDetails")}
                            </Typography>
                          </Box>

                          <Box
                            className="optionPopItem"
                            onClick={() => {
                              setDeleteParticipantPop(true);
                              handleDeleteParticipants(el);
                            }}
                          >
                            {trashBlack}
                            <Typography className="optionPopItema">
                              {t("commonTxt.delete")}
                            </Typography>
                          </Box>
                        </Box>
                      }
                    </Box>
                    <Box
                      onClick={() => handleOptionIndex(index)}
                      className="tabelAvatar"
                    >
                      {el?.profile_pic == "undefined" ||
                      el?.profile_pic == null ? (
                        <img src={DummyUser} />
                      ) : (
                        <img src={viewImage(el?.profile_pic)} />
                      )}
                      {/* <img src={viewImage(el?.profile_pic)} /> */}
                    </Box>
                    <Typography>{el?.name}</Typography>
                  </Box>
                  <Box
                    className="Tabelsel TabelselItemCenter"
                    minWidth={"230px"}
                  >
                    <Typography>
                      {el.workspaces.length > 0 ? el?.workspaces[0]?.name : "-"}
                    </Typography>
                  </Box>
                  <Box
                    className="Tabelsel TabelselItemCenter"
                    minWidth={"230px"}
                  >
                    <Typography>{el?.status}</Typography>
                  </Box>
                  <Box
                    className="Tabelsel TabelselItemCenter"
                    minWidth={"230px"}
                  >
                    <Typography>{el?.email}</Typography>
                  </Box>
                  <Box
                    className="Tabelsel TabelselItemCenter"
                    minWidth={"230px"}
                  >
                    <Typography>{formatDate(el?.updated_at)}</Typography>
                  </Box>
                </Box>
              ))
            ) : (
              <Box></Box>
            )}
          </Box>
          <Pagination
            paginationData={paginationData}
            onPageChange={onPageChange}
          />
        </Box>
      </Box>
    </>
  );
}
// Participants Section
