import React, { useEffect } from 'react'
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.css"

//IMAGES
import whitedropIcon from "../../../Assets/Images/Wchevron-down.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";
import exportIcon from "../../../Assets/Images/ExpoGroup.png";
import dropIcon from "../../../Assets/Images/chevron-downp.png"
import printIcon from "../../../Assets/Images/printIcon.png"


//component
import { Box, Typography } from '@mui/material'
import { AppBtn, AppBtnOutLine } from '../../AppTools/AppButton';
import { ApexChart } from "../../../Components/AppTools/PieChart";

//data
import { ParticipantData, reportDropVal } from "../../../Assets/Data"



export default function SizeAndChurn({
  dataReportTabe,
  setDataReportTabe,
  setProjectSettingDropVal,
  projectSettingDropVal,
  setProjectSettingDrop,
  projectSettingDrop,
  setRangeDrop,
  rangeDrop,
  setRangeDropVal,
  rangeDropVal,
  setContributionDrop,
  contributionDrop,
  setContributionDropVal,
  contributionDropVal,
}) {

  const [searchParams, setSearchParams] = useSearchParams();
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  useEffect(() => {
    let selTab = searchParams.get("t");
    let selTab2 = searchParams.get("dr");
    if (selTab && selTab2 != null) {
      var query_string = selTab;
      query_string = query_string.replaceAll('_', ' ');
      query_string = query_string.replace(/(^\w|\s\w)/g, firstCharOfWord => firstCharOfWord.toUpperCase());
      console.log(query_string);
      if (selTab === "size_churn") {
        var query_string = "Size & Churn";
        setProjectSettingDropVal(query_string);
        setDataReportTabe(parseInt(selTab2));
      }
      else {
        setProjectSettingDropVal(query_string);
        setDataReportTabe(parseInt(selTab2));
      }
    }
    else if (selTab == null) {
      setDataReportTabe(parseInt(selTab2));
    }
  }, []);

  return (
    <>
      <Box className="sizeAndChurnBox">
        <Box sx={{ display: projectSettingDropVal === "Size & Churn" ? "block" : "none" }} className="reportContainer integrationsContainer">
          <Box className="projectBtnBox">
            <Box className="inteBtndropBox">
              <Box className="projectManagementNavBtn" onClick={() => setProjectSettingDrop(!projectSettingDrop)}>
                <Typography className='DropBtnText'>{projectSettingDropVal || "Size & Churn"}</Typography>
                <img className='dropIcon' src={whitedropIcon} style={{ rotate: projectSettingDrop ? "180deg" : "0deg", transition: "all 0.5s", marginLeft: "10px" }} />
                <Box className="workspaceItemNavPop projectItemNavPop" sx={{ display: projectSettingDrop ? "flex" : "none" }}>
                  {
                    reportDropVal?.map((el, i) => (
                      <Box key={i}
                        onClick={() => {
                          setSearchParams({ dr: dataReportTabe, t: el.qstring });
                          setProjectSettingDropVal(el.title);
                        }}
                      >
                        <Typography>{el.title}</Typography>
                      </Box>
                    ))
                  }
                </Box>
              </Box>
            </Box>
          </Box>
          <Typography className='pageHeaderText'>{t("projectParticipantReport.headerTxt")}</Typography>
          <Box className="participationReportDropSection">
            <Box className="inputBoxWlabel">
              <Typography>{t("projectParticipantReport.dateRangeDrop")}</Typography>
              <Box
                className="inputAppDrop"
                onClick={() => setRangeDrop(!rangeDrop)}
              >
                <Typography>{rangeDropVal || t("projectParticipantReport.dateRangeDropPlaceholder")}</Typography>
                <img
                  style={{ rotate: rangeDrop ? "180deg" : "0deg" }}
                  src={dropArrow}
                />
                {/* pop */}
                <Box
                  sx={{ height: rangeDrop ? "auto" : "0px" }}
                  className="statusPop"
                >
                  <Box
                    onClick={() => setRangeDropVal("All Time")}
                    className="statusPopItem"
                  >
                    <Typography>{t("projectParticipantReport.dateRangeDropVal1")}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className="inputBoxWlabel">
              <Typography>{t("projectParticipantReport.contributionTypeDrop")}</Typography>
              <Box
                className="inputAppDrop"
                onClick={() => setContributionDrop(!contributionDrop)}
              >
                <Typography>{contributionDropVal || t("projectParticipantReport.contributionTypeDropPlaceholder")}</Typography>
                <img
                  style={{ rotate: contributionDrop ? "180deg" : "0deg" }}
                  src={dropArrow}
                />
                {/* pop */}
                <Box
                  sx={{ height: contributionDrop ? "auto" : "0px" }}
                  className="statusPop"
                >
                  <Box onClick={() => setContributionDropVal("All Types")} className="statusPopItem">
                    <Typography>{t("projectParticipantReport.contributionTypeDropVal1")}</Typography>
                  </Box>
                  <Box onClick={() => setContributionDropVal("Subscribed")} className="statusPopItem">
                    <Typography>{t("projectParticipantReport.contributionTypeDropVal2")}</Typography>
                  </Box>
                  <Box onClick={() => setContributionDropVal("Unsubscribed")} className="statusPopItem">
                    <Typography>{t("projectParticipantReport.contributionTypeDropVal3")}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.resetBtn")} />
            <AppBtn buttonText={t("commonTxt.viewReportBtn")} />
          </Box>
        </Box>


        <Box sx={{ display: projectSettingDropVal === "Size & Churn" ? "block" : "none" }} mt={5} className="integrationsContainer">
          <Box className="tabelNav">
            <Box className="dropWheader">
              <Typography className="pageHeaderText">{t("projectParticipantReport.headerTxt2")}</Typography>
              <Box className="ndrop">
                <Typography>5</Typography>
                <img src={dropIcon} />
              </Box>
            </Box>
            <Box className="repPrintBtnBox">

              <Box className="exporOpenBtn">
                <img src={printIcon} />
                <Typography className="verifyLink">{t("commonTxt.printLink")}</Typography>
              </Box>

              <Box className="exporOpenBtn">
                <img src={exportIcon} />
                <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
              </Box>

            </Box>
          </Box>


          <Box className="pieBox">
            <ApexChart />
          </Box>

          <Box mb={2} mt={6} className="tabelNav">
            <Box className="dropWheader">
              <Typography className="psSubLabel">{t("projectParticipantReport.subHeaderTxt2")} (125) </Typography>
            </Box>
            <Box className="repPrintBtnBox">
              <Box className="exporOpenBtn">
                <img src={exportIcon} />
                <Typography className="verifyLink">{t("commonTxt.exportLink")}</Typography>
              </Box>
            </Box>
          </Box>



          <Box mb={6} className="workListBox">
            <Box className="haderRow" minWidth={"955px"}>
              <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                <Typography>{t("sizeAndChurn.tblUserName")}</Typography>
              </Box>
              <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                <Typography>{t("sizeAndChurn.tblUnSubscriptionDate")}</Typography>
              </Box>
              <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                <Typography>{t("sizeAndChurn.tblSubscriptionDate")}</Typography>
              </Box>
            </Box>

            {
              ParticipantData?.map((el, i) => (
                <Box className="TabelRow" minWidth={"955px"}>
                  <Box minWidth={"33%"} className="Tabelsel navFsel navtable">
                    <Box className="tabelAvatar"> <img src={el.img} /> </Box>
                    <Typography sx={{ cursor: "pointer", width: "95%" }}> {el.UserName}</Typography>
                  </Box>
                  <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                    <Typography>{el.UnSubscriptionDate}</Typography>
                  </Box>
                  <Box minWidth={"33%"} className="Tabelsel TabelStatesel">
                    <Typography>{el.SubscriptionDate}</Typography>
                  </Box>
                </Box>
              ))
            }
          </Box>
        </Box>
      </Box>
    </>
  )
}
