import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";

import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// images
import arrow from "../../../Assets/Images/arrow.png";
import projectImg1 from "../../../Assets/Images/rProject1.png";
import projectImg2 from "../../../Assets/Images/rProject2.png";
import projectImg3 from "../../../Assets/Images/rProject3.png";
import projectImg4 from "../../../Assets/Images/rProject4.png";
import projectImg5 from "../../../Assets/Images/rProject5.png";
import VDot from "../../../Assets/Images/more-vertical.png";
import polegon from "../../../Assets/Images/Polygon 3.png"


//images
import userIcon from "../../../Assets/Images/users.png";
import clockIcon from "../../../Assets/Images/clock.png";


//components
import { useTranslation } from "react-i18next";
import { SearchBar, AllDrop, AllDrop3 } from "../../../Components/AppTools/AppTool";
import SideMenu from "../../../Components/Collaborator/SideMenu";
import AddProjectPop from "../../../Components/Collaborator/AddProjectPop";
import NoProjectBgTemplate from "../../../Components/Collaborator/NoProjectBgTemplate";
import ParticipantPop from "../../../Components/Collaborator/ParticipantsPop"

import {
  trashBlack,
  settingIconBlack,
  duplicatehBlack,
  participantshBlack,
  projectDataC,
  sideHaumIcon,
  dislike
} from "../../../Assets/Data";


const WorkspaceItem = ({ slideMenu, setSlideMenu, workspaceDrop, setWorkSpaceDrop }) => {
  const { t, i18n } = useTranslation();
  const [allDrop, setAllDrop] = useState();
  const [allDropVal, setAllDropVal] = useState("All");
  const [allDrop3, setAllDrop3] = useState();
  const [allDrop3Val, setAllDrop3Val] = useState("Site Page 1");
  const [newArrayData, setNewArrayData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [addProjectPop, setAddProjectPop] = useState(false);

  const [addProjectNextPop, setaddProjectNextPop] = useState(false);
  const [duplicateProjectPop, setDuplicateProjectPop] = useState(false);
  const [projectCPopOpenByIndex, setProjectCPopOpenByIndex] = useState("");
  const [participantPop, setParticipantPop] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const projectList = ["Outdoor Journey", "Financial Awareness"];
  const [deleteProjectPop, setDeleteProjectPop] = useState(false);
  const handleClickOpen12 = () => setDuplicateProjectPop(true);
  const [hoverIndex, setHoverIndex] = useState()


  useEffect(() => {
    printItemsByState(projectDataC, currentPage);
  }, [currentPage]);

  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });


  const recentProjectCard = (img, text) => {
    return (
      <Box className="recentProjectcard">
        <img src={img} />
        <img className="recentPOption" src={VDot} />
        <Typography className="recentProjectText">{text}</Typography>
        <Box className="blackDor"></Box>
      </Box>
    );
  };

  return (
    <>
      <Box className="ResearchersContainer ">
        <ParticipantPop participantPop={participantPop} setParticipantPop={setParticipantPop} />
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop} setWorkSpaceDrop={setWorkSpaceDrop} />

        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon">
              {sideHaumIcon}
            </Box>
          </Box>


          {/* Page container */}
          <Box className="researchersMainBox pageContainer">
            {projectList.length <= 0 ? (
              <Box className="workspaceItemBox">
                <AddProjectPop
                  setAddProjectPop={setAddProjectPop}
                  addProjectPop={addProjectPop}
                  VisuallyHiddenInput={VisuallyHiddenInput}
                  fullScreen={fullScreen}
                  setaddProjectNextPop={setaddProjectNextPop}
                />
                <Box sx={{ width: "20%", minWidth: "100px" }}>
                  {" "}
                  <AllDrop3
                    allDrop3={allDrop3}
                    setAllDrop3={setAllDrop3}
                    allDrop3Val={allDrop3Val}
                    setAllDrop3Val={setAllDrop3Val}
                  />
                </Box>
                <NoProjectBgTemplate setAddProjectPop={setAddProjectPop} />
              </Box>
            ) : (
              <Box className="workspaceItemBox">

                <Box className="recentProjectBox">
                  <Box className="recentProjectListBox">
                    {recentProjectCard(projectImg1, "Outdoor Journey")}
                    {/* {recentProjectCard(projectImg2, "Financial Awareness")}
                    {recentProjectCard(projectImg3, "Sport Psychology")}
                    {recentProjectCard(projectImg4, "Car Brands")}
                    {recentProjectCard(projectImg5, "Eating Habits")} */}
                  </Box>
                </Box>

                <Box className="AllProjectBox">
                  <Typography className="pageHeaderText">{t("workspaceSplash.allProjects")}</Typography>
                  <Box className="projectNav">
                    <Box className="projectNav1 projectNav1V1">
                      <Box mr={2} sx={{ width: "75%" }}>
                        <SearchBar />
                      </Box>
                      <Box sx={{ width: "20%", minWidth: "132px" }}>
                        <AllDrop
                          allDrop={allDrop}
                          setAllDrop={setAllDrop}
                          allDropVal={allDropVal}
                          setAllDropVal={setAllDropVal}
                        />
                      </Box>
                    </Box>

                    <Box sx={{ justifyContent: "end" }} className="projectNav1">
                      <Box className="projectNavIndicat">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                        >
                          <circle cx="5" cy="5" r="5" fill="#FB6514" />
                        </svg>
                        <Typography ml={1}>{t("workspaceSplash.inProgess")}</Typography>
                      </Box>
                      <Box ml={2} className="projectNavIndicat">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                        >
                          <circle cx="5" cy="5" r="5" fill="#12B76A" />
                        </svg>
                        <Typography ml={1}>{t("workspaceSplash.completed")}</Typography>
                      </Box>
                      <Box ml={2} className="projectNavIndicat">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                        >
                          <circle cx="5" cy="5" r="5" fill="#FB6514" />
                        </svg>
                        <Typography ml={1}>{t("workspaceSplash.Pending")}</Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box className="workListBox">
                    <Box className="haderRow" minWidth={"1270px"}>
                      <Box className="Tabelsel navFsel" minWidth={"290px"}>
                        <Typography>{t("workspaceSplash.Project")}</Typography>
                      </Box>
                      <Box className="Tabelsel activitesSel" minWidth={"230px"}>
                        <Typography ml={2}>{t("workspaceSplash.Activities")}</Typography>
                      </Box>
                      <Box className="Tabelsel participantsSel" minWidth={"230px"}>
                        <Typography ml={1}>{t("workspaceSplash.Participants")}</Typography>
                      </Box>
                      <Box className="Tabelsel" minWidth={"230px"}>
                        <Typography ml={1}>{t("workspaceSplash.StartDate")}</Typography>
                      </Box>
                      <Box className="Tabelsel" minWidth={"230px"}>
                        <Typography>{t("workspaceSplash.ProjectState")}</Typography>
                      </Box>
                    </Box>

                    {newArrayData &&
                      newArrayData?.map((el, index) => (
                        <Box key={index} className="TabelRow" minWidth={"1270px"}>
                          <Box
                            className="Tabelsel navFsel PNavTable"
                            minWidth={"290px"}
                            onClick={() => {
                              if (projectCPopOpenByIndex === index) {
                                setProjectCPopOpenByIndex(null);
                              } else {
                                setProjectCPopOpenByIndex(index);
                              }
                            }}
                          >
                            <Box id="dot2" className={projectCPopOpenByIndex === index ? "dot dotActive" : "dot"}>
                              {
                                <Box
                                  sx={{
                                    display:
                                      projectCPopOpenByIndex === index
                                        ? "flex"
                                        : "none",
                                  }}
                                  className="optionPop"
                                >
                                  <Box
                                    className="optionPopItem"
                                    onClick={() => setProjectCPopOpenByIndex(null)}
                                  >
                                    {settingIconBlack}
                                    <Typography>{t("workspaceSplash.pageSetting")}</Typography>
                                  </Box>
                                  <Box
                                    className="optionPopItem"
                                    onClick={() => {
                                      setProjectCPopOpenByIndex(null);
                                      setParticipantPop(true);
                                    }}
                                  >
                                    {participantshBlack}
                                    <Typography>{t("workspaceSplash.Participants")}</Typography>
                                  </Box>
                                  <Box
                                    className="optionPopItem"
                                    onClick={() => {
                                      setProjectCPopOpenByIndex(null);
                                      setDeleteProjectPop(true);
                                    }}
                                  >
                                    {trashBlack}
                                    <Typography>{t("workspaceSplash.DeleteProject")}</Typography>
                                  </Box>
                                </Box>
                              }
                            </Box>
                            <Box className="tabelAvatar">
                              <img src={el.img} />
                            </Box>
                            <Typography>{el.title}</Typography>
                          </Box>
                          <Box
                            className="Tabelsel activitesSel TabelselItemCenter"
                            minWidth={"230px"}
                          >
                            <Box
                              className="pColorBox"
                              sx={{
                                background: "rgba(254, 224, 208, 0.50)",
                                color: "#FB6514",
                              }}
                            >
                              <p>4</p>
                            </Box>
                            <Box
                              className="pColorBox"
                              sx={{
                                background: "rgba(208, 241, 225, 0.50)",
                                color: "#12B76A",
                              }}
                            >
                              <p>2</p>
                            </Box>
                            <Box
                              className="pColorBox"
                              sx={{
                                background: "rgba(254, 224, 208, 0.50)",
                                color: "#FB6514",
                              }}
                            >
                              <p>1</p>
                            </Box>
                          </Box>

                          <Box
                            className="Tabelsel participantsRow participantsSel TabelselItemCenter"
                            minWidth={"230px"}
                            position={"relative"}
                          >
                            <img style={{ width: "20px" }} src={userIcon} />
                            <p style={{ margin: "0 10px" }}>10</p>
                            <img
                              onMouseEnter={() => setHoverIndex(index)}
                              onMouseLeave={() => setHoverIndex(null)}
                              style={{ cursor: "pointer", margin: "0 10px" }} src={clockIcon} />
                            <p>2</p>

                            <Box className="clockHoverBox" sx={{ display: hoverIndex === index ? "flex" : "none" }}>
                              <img src={polegon} />
                              <Typography>Email Invitations</Typography>
                            </Box>


                          </Box>
                          <Box
                            className="Tabelsel TabelselItemCenter"
                            minWidth={"230px"}
                          >
                            <Typography>{el.statDate}</Typography>
                          </Box>
                          <Box
                            className="Tabelsel pState TabelselItemCenter"
                            minWidth={"230px"}
                          >
                            <Typography>{el.projectState}</Typography>
                          </Box>
                        </Box>
                      ))}
                  </Box>
                  <Box className="pagenation">
                    <Box onClick={handlePrev} className="tabelBtn">
                      <img src={arrow} />
                      <Typography>{t("commonTxt.previouspage")}</Typography>
                    </Box>

                    <Box className="pageNumberBox">
                      <Box
                        onClick={() => setCurrentPage(0)}
                        className={
                          currentPage === 0
                            ? "pageNumber pageNumberActive"
                            : "pageNumber"
                        }
                      >
                        <p>1</p>
                      </Box>
                      <Box
                        onClick={() => setCurrentPage(1)}
                        className={
                          currentPage === 1
                            ? "pageNumber pageNumberActive"
                            : "pageNumber"
                        }
                      >
                        <p>2</p>
                      </Box>
                      <Box
                        onClick={() => setCurrentPage(2)}
                        className={
                          currentPage === 2
                            ? "pageNumber pageNumberActive"
                            : "pageNumber"
                        }
                      >
                        <p>3</p>
                      </Box>
                      <Box
                        onClick={() => setCurrentPage(3)}
                        className={
                          currentPage === 3
                            ? "pageNumber pageNumberActive"
                            : "pageNumber"
                        }
                      >
                        <p>4</p>
                      </Box>
                      <Box
                        onClick={() => setCurrentPage(4)}
                        className={
                          currentPage === 4
                            ? "pageNumber pageNumberActive"
                            : "pageNumber"
                        }
                      >
                        <p>5</p>
                      </Box>
                    </Box>

                    <Box onClick={handleNext} className="tabelBtn">
                      <Typography>{t("commonTxt.nextPage")}</Typography>
                      <img style={{ rotate: "180deg" }} src={arrow} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}

          </Box>


        </Box>
      </Box>
    </>
  );
};

export default WorkspaceItem;
