import React, { useState, useEffect, useRef } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from "react-i18next";

//Images
import dropArrow from "../../../Assets/Images/chevron-down.png";

//components 
import { AppBtn, AppBtnOutLine } from '../../../Components/AppTools/AppButton';

export default function ProjectTranscriptQuantitative({ projectSettingTranscriptDropVal }) {

    const [transcriptActivityDrop, setTranscriptActivityDrop] = useState(false);
    const [transcriptActivityDropVal, setTranscriptActivityDropVal] = useState();
    const [transcriptDateRangeDrop, setTranscriptDateRangeDrop] = useState(false);
    const [transcriptDateRangeDropVal, setTranscriptDateRangeDropVal] = useState();
    const [participantIdentityDrop, setParticipantIdentityDrop] = useState(false);
    const [participantIdentityDropVal, setParticipantIdentityDropVal] = useState();

    const transcriptActivityRef = useRef(null);
    const transcriptDateRangeRef = useRef(null);
    const {
        t,
        i18n: { changeLanguage, language },
    } = useTranslation();

    useEffect(() => {
        const handleClickOutside = (event) => {
          console.log("Clicked element:", event.target);
          console.log("Workspace option ref:", transcriptDateRangeRef.current);
          if (transcriptDateRangeRef.current) {
            setTranscriptDateRangeDrop(false);
          }
          if (transcriptActivityRef.current) {
            setTranscriptActivityDrop(false);
          }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      });

  return (
    <>   
         <Box sx={{ display: projectSettingTranscriptDropVal === "Quantitative" ? "block" : "none" }}>
            <Box className="projectPrivacyIdentity">
                <Typography className=''>{t("projectTranscriptActivities.responseDateRangeLabel")}</Typography>
                <Box
                    className="inputAppDrop participantIdentityDrop"
                    ref={transcriptDateRangeRef}
                    onClick={() => setTranscriptDateRangeDrop(!transcriptDateRangeDrop)}
                    >
                    <Typography>{transcriptDateRangeDropVal || t("projectTranscriptActivities.responseDateRangePlaceholder")}</Typography>
                    <img
                    style={{ rotate: transcriptDateRangeDrop ? "180deg" : "0deg" }}
                    src={dropArrow}
                    />
                    {/* pop */}
                    <Box
                    sx={{ height: transcriptDateRangeDrop ? "auto" : "0px" }}
                    className="statusPop"
                    >
                    <Box
                        onClick={() => setTranscriptDateRangeDropVal("All Time")}
                        className="statusPopItem"
                    >
                        <Typography>All Time</Typography>
                    </Box>
                    </Box>
                </Box>
            </Box>
            <Box className="projectPrivacyIdentity">
                    <Typography className=''>{t("projectTranscriptActivities.activitiesDropLabel")}</Typography>
                    <Box
                    className="inputAppDrop participantIdentityDrop"
                    ref={transcriptActivityRef}
                    onClick={() => setTranscriptActivityDrop(!transcriptActivityDrop)}
                    >
                    <Typography>{transcriptActivityDropVal || t("projectTranscriptActivities.activitiesDropPlaceholder")}</Typography>
                    <img
                    style={{ rotate: transcriptActivityDrop ? "180deg" : "0deg" }}
                    src={dropArrow}
                    />
                    {/* pop */}
                    <Box
                    sx={{ height: transcriptActivityDrop ? "auto" : "0px" }}
                    className="statusPop"
                    >
                    <Box
                        onClick={() => setTranscriptActivityDropVal("All Activities")}
                        className="statusPopItem"
                    >
                        <Typography>All Activities</Typography>
                    </Box>
                    <Box
                        onClick={() => setTranscriptActivityDropVal("Activity 1")}
                        className="statusPopItem"
                    >
                        <Typography>Activity 1</Typography>
                    </Box>
                    <Box
                        onClick={() => setTranscriptActivityDropVal("Activity 2")}
                        className="statusPopItem"
                    >
                        <Typography>Activity 2</Typography>
                    </Box>
                   
                    </Box>
                </Box>
            </Box>
            <Box className="ProjectRadioInput">
                <input className="custom-checkbox" type="checkBox" defaultChecked />
                <Typography className="emailSettingLabel">{t("projectTranscriptMessage.topicCheckbox")}</Typography>
            </Box>
            <Typography className='projectTranscriptLabel'>{t("projectParticipantReport.subHeaderTxt2")}</Typography>
            <Box className="projectRadioBox">
                <Box className="projectRadioBoxSection">
                    <Box className="ProjectRadioInput">
                        <input
                            className="emailSettingInput emailSettingRadio"
                            type='radio'
                            name='projectState'
                            defaultChecked
                        />
                        <Typography className="emailSettingLabel">{t("projectParticipantReport.participantAllParticipant")}</Typography>
                    </Box>
                    <Box className="ProjectRadioInput">
                        <input
                            className="emailSettingInput emailSettingRadio"
                            type='radio'
                            name='projectState'
                        />
                        <Typography className="emailSettingLabel">{t("projectParticipantReport.participantSelectParticipant")}</Typography>
                    </Box>
                </Box>
                <Box className="projectRadioBoxSection">
                    <Box className="ProjectRadioInput">
                        <input
                            className="emailSettingInput emailSettingRadio"
                            type='radio'
                            name='projectState'
                        />
                        <Typography className="emailSettingLabel">{t("projectParticipantReport.participantSelectGroup")}</Typography>
                    </Box>
                </Box>
            </Box>
            
            <Typography className='projectTranscriptLabel'>{t("projectParticipantReport.subHeaderTxt1")}</Typography>
            <Box className="projectRadioBox">
                <Box className="projectRadioBoxSection">
                    <Box className="ProjectRadioInput">
                        <input className="custom-checkbox" type="checkBox" defaultChecked />
                        <Typography className="emailSettingLabel">{t("projectTranscriptActivities.incompletesCheckbox")}</Typography>
                    </Box>
                </Box>
            </Box>
            <Typography className='projectTranscriptLabel'>{t("projectParticipantReport.subHeaderTxt2")}</Typography>
            <Box className="projectRadioBox">
                <Box className="projectRadioBoxSection">
                    <Box className="ProjectRadioInput">
                        <input
                            className="emailSettingInput emailSettingRadio"
                            type='radio'
                            name='projectState'
                            defaultChecked
                        />
                        <Typography className="emailSettingLabel">{t("projectParticipantReport.participantAllParticipant")}</Typography>
                    </Box>
                    <Box className="ProjectRadioInput">
                        <input
                            className="emailSettingInput emailSettingRadio"
                            type='radio'
                            name='projectState'
                        />
                        <Typography className="emailSettingLabel">{t("projectParticipantReport.participantSelectParticipant")}</Typography>
                    </Box>
                </Box>
                <Box className="projectRadioBoxSection">
                    <Box className="ProjectRadioInput">
                        <input
                            className="emailSettingInput emailSettingRadio"
                            type='radio'
                            name='projectState'
                        />
                        <Typography className="emailSettingLabel">{t("projectParticipantReport.participantSelectGroup")}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box className="projectPrivacyIdentity">
                <Typography className=''>{t("projectParticipantReport.participantIdentity")}</Typography>
                <Box
                className="inputAppDrop participantIdentityDrop"
                onClick={() => setParticipantIdentityDrop(!participantIdentityDrop)}
                >
                <Typography>{participantIdentityDropVal || t("projectPrivacy.AdminsIdentitiesPlaceholder")}</Typography>
                <img
                style={{ rotate: participantIdentityDrop ? "180deg" : "0deg" }}
                src={dropArrow}
                />
                {/* pop */}
                <Box
                sx={{ height: participantIdentityDrop ? "auto" : "0px" }}
                className="statusPop"
                >
                <Box
                    onClick={() => setParticipantIdentityDropVal("Identity 1")}
                    className="statusPopItem"
                >
                    <Typography>Identity 1</Typography>
                </Box>
                <Box
                    onClick={() => setParticipantIdentityDropVal("Identity 2")}
                    className="statusPopItem"
                >
                    <Typography>Identity 2</Typography>
                </Box>
                
                </Box>
                </Box>
            </Box>
            
            <Box className="projectRadioBox projectTranscriptParticipant">
                <Box className="projectRadioBoxSection">
                    <Box className="ProjectRadioInput">
                        <input className="custom-checkbox" type="checkBox" defaultChecked />
                        <Typography className="emailSettingLabel">{t("projectTranscriptActivities.participantEmailsCheckbox")}</Typography>
                    </Box>
                </Box>
            </Box>
            <Typography className='projectTranscriptLabel'>{t("projectTranscriptActivities.outputLabel")}</Typography>
            <Box className="projectRadioBox">
                <Box className="projectRadioBoxSection">
                    <Box className="ProjectRadioInput">
                        <input
                            className="emailSettingInput emailSettingRadio"
                            type='radio'
                            name='projectState'
                            defaultChecked
                        />
                        <Typography className="emailSettingLabel">{t("projectTranscriptActivities.outputCheckbox3")}</Typography>
                    </Box>
                </Box>
                <Box className="projectRadioBoxSection">
                    <Box className="ProjectRadioInput">
                        <input
                            className="emailSettingInput emailSettingRadio"
                            type='radio'
                            name='projectState'
                        />
                        <Typography className="emailSettingLabel">{t("projectTranscriptActivities.outputCheckbox2")}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.resetBtn")} />
            <AppBtn buttonText={t("commonTxt.generateTranscriptBtn")} />
            </Box>
        </Box>
    </>
  )
}
