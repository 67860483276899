import React, { useState } from 'react'
import AdminLayout from '../partials/AdminLayout'
import { Box } from '@mui/material'
import PlatformLog from "../../../Components/Admin/PlatformLog";
import BulkImport from "../../../Components/Admin/BulkImport";

const PlatformLogPage = () => {

  const [bulkImportData, setBulkImportData] = useState(false);

  return (
    <AdminLayout
      childComponent={
        <Box
            className="adminMainBox"
            sx={{ display: "flex" }}
          >
            {/*Appearance */}
            {bulkImportData ? (
              <BulkImport
                setBulkImportData={setBulkImportData}
                bulkImportData={bulkImportData}
              />
            ) : (
              <PlatformLog
                setBulkImportData={setBulkImportData}
                bulkImportData={bulkImportData}
              />
            )}
          </Box>
      }
    />
  )
}

export default PlatformLogPage;