import { Box } from '@mui/material'
import React from 'react'
import EmailSetting from '../../../Components/Admin/EmailSetting'
import AdminLayout from '../partials/AdminLayout'
import { AppBtn } from '../../../Components/AppTools/AppButton'

const EmailSettingsPage = () => {

  let actionBtn = <AppBtn buttonText="Export Log" />
  
  return (
    <AdminLayout
      childComponent={
        <Box
            className="adminMainBox"
            sx={{ display: "flex" }}
          >
            <EmailSetting />
          </Box>
      }
      // actionBtn={actionBtn}
    />
  )
}

export default EmailSettingsPage