import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.css";

//Images

//components
import ProjectPoints from "../ProjectMngPoints";

export default function ProjectManagementSetting({
  setDataReportTabe,
}) {
  const [projectSettingDropVal, setProjectSettingDropVal] =
    useState("Project State");
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  useEffect(() => {
    let selTab = searchParams.get("ps");
    let selTab2 = searchParams.get("dr");
    if (selTab && selTab2 != null) {
      var query_string = selTab;
      query_string = query_string.replaceAll("_", " ");
      query_string = query_string.replace(/(^\w|\s\w)/g, (firstCharOfWord) =>
        firstCharOfWord.toUpperCase()
      );
      console.log(query_string);
      setProjectSettingDropVal(query_string);
      setDataReportTabe(parseInt(selTab2));
    } else if (selTab == null) {
      setDataReportTabe(parseInt(selTab2));
    }
  }, []);

  return (
    <>
      <Box className="researchersMainBox pageContainer">
        <Box className="integrationsContainer">
          <Box className="projectBtnBox">
            <Box className="inteBtndropBox">
              <Box className="projectManagementNavBtn">
                <Typography className="DropBtnText">{t("projectSettings.projectSettingDropVal3")}</Typography>
              </Box>
            </Box>
          </Box>

          <ProjectPoints />

        </Box>
      </Box>
    </>
  );
}
