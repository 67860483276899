import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { deleteAdminUser } from "../../../Services/adminActions";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { useAppSelector } from "../../../lib/store/store";

//images

export default function DeleteAdminPop({ deleteAdminPop, setDeleteAdminPop,setReloadList,delAdminId,delAdminName }) {
 
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
       setDeleteAdminPop(false);
    }
  };
  let authState = useAppSelector(state => state.auth);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (deleteAdminPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [deleteAdminPop]);

  const deleteAdmin = async()=>{
    setLoading(true);
    try {
      console.log("object")
      const response = await deleteAdminUser(delAdminId, authState.authToken);
      setLoading(false);
      if (response.success) {
        setDeleteAdminPop(false)
        toast.success(response.message, TOAST_CONFIG);
        if (typeof (setReloadList) != 'undefined') {
          setReloadList(true);
        }
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  }
  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: deleteAdminPop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople"
      >
        <Box
          sx={{ display: deleteAdminPop ? "flex" : "none" }}
          className="workspaceContaint deletePagePop"
        >
          <Typography className="companyHeader">Delete Super Admin</Typography>

          <Box className="basicBoxItem">
            <Typography className='addPageLabel'>Are you sure you want to delete the {delAdminName}”  from Terapage ?</Typography>
            
          </Box>
          
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={()=>setDeleteAdminPop(false)} />
            <AppBtn buttonText="Delete" onClick={deleteAdmin} />
          </Box>

        </Box>
      </Box>
    </>
  );
}
