import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

import DownArrowIcon from "../../../Assets/Images/chevron-down.png";

import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { useAppSelector } from "../../../lib/store/store";
import axios from '../../../lib/axios';
import { getCities, getCountries, getStates } from "../../../Services/master";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { updatePartcipantsApi, uploadUserDirectoryImage } from "../../../Services/adminActions";
import { urlToBase64 } from "../../../lib/helpers";
import { STORAGE_BASE_PATH } from "../../../env";
import plassIcon from "../../../Assets/Images/plassIcon.png";

export default function EditParticipantPop({
  setEditParticipantPop,
  editParticipantPop,
  allParticipantsData,
  setReloadList,
  setAllParticipantsData
}) {
  let authState = useAppSelector(state => state.auth);
  const [genderDrop, setGenderDrop] = useState(false);
  const [genderDropVal, setGenderDropVal] = useState("");
  
  const handleCancel = () => {
    setEditParticipantPop(false);
    setAllParticipantsData('');
  }

  useEffect(() => {
    if (editParticipantPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [editParticipantPop]);

  const [countryData, setCountryData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [countryList, setCountryList] = useState(false);
  const [countryListVal, setCountryListVal] = useState("");
  const [stateList, setStateList] = useState(false);
  const [stateListVal, setStateListVal] = useState("");
  const [cityList, setCityList] = useState(false);
  const [cityListVal, setCityListVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [countryId, setCountryId] = useState("");
  const [stateId, setStateId] = useState("");
  const [cityId, setCityId] = useState("");
  const [participantsImage, setParticipantsImage] = useState(null);

  const updateParticipants = {
    "name":"",
    "email": "",
    "username": "",
    "phone":"",
    "gender":"",
    "address_line1":"",
    "country":"",
    "city":"",
    "state":"",
    "zipcode":"",
    "profile_pic":"",
    "status":"active",
  }
  const [editparticipantsApi, setEditParticipantsApi] = useState({ ...updateParticipants });

  useEffect(() => {
    getAllCountryList();
    getParticipants();
  }, [allParticipantsData]);

  const getParticipants = async () => {
    if(allParticipantsData == ''){
      return false;
    }
    return await axios.get(`/super-admin/admin/participants?_id=${allParticipantsData}`).then((res) => {
      console.log("res", res);
      if (res.status) {
        let ovserberData = { ...updateParticipants };
        let key;
        let allParticipantsData = res?.data?.data[0];
        for (key in ovserberData) {
          ovserberData[key] = allParticipantsData[key];
        }
        setEditParticipantsApi(ovserberData);
        setGenderDropVal(allParticipantsData?.gender);
        if (allParticipantsData?.profile_pic == 'undefined' || allParticipantsData?.profile_pic == null) {
          setParticipantsImage(null);
        }else{
          urlToBase64(STORAGE_BASE_PATH +`/` +allParticipantsData?.profile_pic, function (base64Img) {
            setParticipantsImage(base64Img);
          })
        }
        if (allParticipantsData?.country?.length > 0) {
          const assignedCountry = allParticipantsData.country.map(item => ({
            name: item.name,
            _id: item._id
          }))
          if (assignedCountry.length > 0) {
            setCountryListVal(assignedCountry[0].name)
          }
          getAllStatesList(allParticipantsData?.country[0]?.id);
        } else {
          setCountryListVal("");
        }
        if (allParticipantsData?.state?.length > 0) {
          const assignedState = allParticipantsData.state.map(item => ({
            name: item.name,
            _id: item._id
          }))
          if (assignedState.length > 0) {
            setStateListVal(assignedState[0].name)
          }
          getAllCitiesList(allParticipantsData?.state[0]?.id, allParticipantsData?.country[0]?.id);
        } else {
          setStateListVal("");
        }
        if (allParticipantsData?.city?.length > 0) {
          const assignedCity = allParticipantsData.city.map(item => ({
            name: item.name,
            _id: item._id
          }))
          if (assignedCity.length > 0) {
            setCityListVal(assignedCity[0].name)
          }
        } else {
          setCityListVal("");
        }
      }
    })
  }

  const setParticipantFormValue = (e) => {
    let newState = {
      ...editparticipantsApi,
      [e.target.name]: e.target.value,
    };
    setEditParticipantsApi(newState);

    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
  };

  const getAllCountryList = () => {
    getCountries().then((res) => {
      if (res.success && res.data.data.length > 0) {
        setCountryData(res.data.data);
      }
    });
  };

  const getAllStatesList = (country_id) => {
    getStates(country_id).then((res) => {
      if (res.success && res.data.data.length > 0) {
        setStatesData(res.data.data);
      }
    });
  };

  const getAllCitiesList = (state_id, country_id) => {
    getCities(country_id, state_id).then((res) => {
      if (res.success && res.data.data.length > 0) {
        setCitiesData(res.data.data);
      }
    });
  };

  const handleCountryChange = (e) => {
    setStateListVal("");
    setCityListVal("");
    let newState = { ...editparticipantsApi, country: e._id };
    setCountryId(e._id);
    setEditParticipantsApi(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
    getAllStatesList(e.id);
  };

  const handleStateChange = (e) => {
    let newState = { ...editparticipantsApi, state: e._id };
    setCityListVal("");
    setStateId(e._id)
    setEditParticipantsApi(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
    getAllCitiesList(e.id, e.country_id);
  };

  const handleCityChange = (e) => {
    let newState = { ...editparticipantsApi, city: e._id };
    setCityId(e._id);
    setEditParticipantsApi(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
  };

  const validationObj = {
    hasErr: false,
    'name': {
      error: false,
      msg: ''
    },
    'email': {
      error: false,
      msg: ''
    },
    'username': {
      error: false,
      msg: ''
    },
    'phone': {
      error: false,
      msg: ''
    },
    'gender': {
      error: false,
      msg: ''
    },
    'address_line1': {
      error: false,
      msg: ''
    },
    'country': {
      error: false,
      msg: ''
    },
    'state': {
      error: false,
      msg: ''
    },
    'city': {
      error: false,
      msg: ''
    },
    'zipcode': {
      error: false,
      msg: ''
    }
  }

  const [validationErrors, setValidationErrors] = useState({ ...validationObj });

  const handleValidation = (formInput) => {
    formInput = formInput || editparticipantsApi;
    let validationerr = { ...validationObj };

    if (formInput.name == "") {
      validationerr.hasErr = true;
      validationerr.name = {
        error: true,
        msg: 'Name is required'
      }
    }

    if (formInput.email == "") {
      validationerr.hasErr = true;
      validationerr.email = {
        error: true,
        msg: 'Email is required'
      }
    }

    if (formInput.username == "") {
      validationerr.hasErr = true;
      validationerr.username = {
        error: true,
        msg: 'Username is required'
      }
    }

    if (formInput.phone == '') {
      validationerr.hasErr = true;
      validationerr.phone = {
        error: true,
        msg: 'Phone is required'
      }
    }

    if (formInput.gender == '') {
      validationerr.hasErr = true;
      validationerr.gender = {
        error: true,
        msg: 'Gender is required'
      }
    }

    if (formInput.address_line1 == '') {
      validationerr.hasErr = true;
      validationerr.address_line1 = {
        error: true,
        msg: 'Address is required'
      }
    }

    if (formInput.country == '') {
      validationerr.hasErr = true;
      validationerr.country = {
        error: true,
        msg: 'Country is required'
      }
    }

    if (formInput.state == '') {
      validationerr.hasErr = true;
      validationerr.state = {
        error: true,
        msg: 'State is required'
      }
    }

    if (formInput.city == '') {
      validationerr.hasErr = true;
      validationerr.city = {
        error: true,
        msg: 'City is required'
      }
    }

    if (formInput.zipcode == '') {
      validationerr.hasErr = true;
      validationerr.zipcode = {
        error: true,
        msg: 'Zipcode is required'
      }
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  }

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setParticipantsImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleParticipantProfileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setParticipantsImage(reader.result);
      };
      reader.readAsDataURL(file);
      updateImage(file);
    }
  };

  const updateImage = async (file) => {
    const formData = new FormData()
    formData.append('file', file)
    try {
      let imageUploadRes = await uploadUserDirectoryImage(formData, authState.authToken);
      if (imageUploadRes.success) {
        console.log("imageUploadRes?.data?.upload_id", imageUploadRes);
        setEditParticipantsApi({...editparticipantsApi, profile_pic: imageUploadRes?.data?.upload_id})
        toast.success(imageUploadRes.message, TOAST_CONFIG);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  };


  let updateData = async () => {
    if (!handleValidation()) {
      toast.error('Please correct form fields', TOAST_CONFIG);
      return false;
    }

    setLoading(true);
    try {
      let c_id = countryId;
      let st_id = stateId;
      let cty_id = cityId;
      if (c_id == '') {
        c_id = editparticipantsApi.country[0]._id
      }
      if (st_id == "") {
        st_id = editparticipantsApi.state[0]._id
      }
      if (cty_id == "") {
        cty_id = editparticipantsApi.city[0]._id
      }
      let requestPayload = {
        "name": editparticipantsApi.name,
        "email": editparticipantsApi.email,
        "username": editparticipantsApi.username,
        "phone": editparticipantsApi.phone,
        "gender": editparticipantsApi.gender,
        "address_line1": editparticipantsApi.address_line1,
        "country": c_id,
        "state": st_id,
        "city": cty_id,
        "zipcode": editparticipantsApi.zipcode,
        "profile_pic": editparticipantsApi.profile_pic,
        "status": "active",
      }
      let observerRes = await updatePartcipantsApi(allParticipantsData, requestPayload, authState.authToken);
      setLoading(false);
      if (observerRes.success) {
        toast.success(observerRes.message, TOAST_CONFIG);
        setEditParticipantsApi({...updateParticipants});
        setEditParticipantPop(false);
        setReloadList(true);
      } else {
        toast.error(observerRes.message, TOAST_CONFIG);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  }

  return (
    <>
      <Box
        id="popBackdrop"
        // onClick={handelPopClose}
        sx={{ display: editParticipantPop ? "flex" : "none" }}
        className="addResearcherPop"
      >
        <Box
          sx={{ display: editParticipantPop ? "flex" : "none" }}
          className="addResearcherContaint"
        >
          <Typography className="addResearcherHeader">
            Edit Participant
          </Typography>
          {/* <Typography className="addResearcherLabel">
            Researcher's Full Name
          </Typography>
          <input
            className="addResearcherInput addResearcherInputName"
            placeholder="Enter the full name of the Researcher"
          /> */}
          <input
              name="name"
              value={editparticipantsApi?.name}
              onChange={setParticipantFormValue}
              className="addResearcherInput addResearcherInputName"
              placeholder="Enter the full name of the Participant"
            />
              <span className="validationerr inputerr">{validationErrors.name.msg}</span>
              <Box className="settingBox">
            <Typography className="workspaceLabel">
                Participants Profile image
              </Typography>

              <Box
                className="fileInputBox upload-area"
                onClick={() => {
                  document.getElementById("fileInputParticipant").click();
                }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                {participantsImage ? (
                  <img className="fileinputimg" src={participantsImage} alt="Upload" />
                ) : (
                  <img src={plassIcon} alt="Upload" />
                )}
                <input
                  id="fileInputParticipant"
                  style={{ display: "none" }}
                  type="file"
                  onChange={handleParticipantProfileChange}
                />
              </Box>
              <Typography className="fileInputLabel">
                Click or drag image to this area to upload
              </Typography>
              </Box>

              <Box className="basicBox lastBasicBox">
            <Box className="inputBox">
              <Typography className="addResearcherLabel">Email Address</Typography>
              <input
                name="email"
                value={editparticipantsApi?.email}
                onChange={setParticipantFormValue}
                className="addResearcherInput addResearcherInputName"
                placeholder="Enter Participant's valid Email Address"
              />
              <span className="validationerr inputerr">{validationErrors.email.msg}</span>
            </Box>
            <Box className="inputBox">
              <Typography className="addResearcherLabel">Username</Typography>
              <input
                name="username"
                value={editparticipantsApi?.username}
                onChange={setParticipantFormValue}
                className="addResearcherInput addResearcherInputName"
                placeholder="Enter Username"
              />
              <span className="validationerr inputerr">{validationErrors.username.msg}</span>
            </Box>
          </Box>

          <Box className="basicBox lastBasicBox">
            <Box className="inputBox">
              <Typography className="addResearcherLabel">
                Phone Number
              </Typography>
              <input
                name="phone"
                value={editparticipantsApi?.phone}
                onChange={setParticipantFormValue}
                className="addResearcherInput"
                placeholder="Enter Participant's valid Phone Number"
              />
              <span className="validationerr">{validationErrors.phone.msg}</span>
            </Box>
            <Box className="inputBox">
              <Typography className="addResearcherLabel">Gender</Typography>
              <input
                  onClick={() => setGenderDrop(!genderDrop)}
                  name="gender"
                  value={editparticipantsApi?.gender}
                  onChange={setParticipantFormValue}
                  // value={genderDropVal}
                  className="addResearcherInput genderInput"
                  placeholder="Select Participant Gender"
                />
              <img
                style={{ rotate: genderDrop ? "180deg" : "0deg" }}
                onClick={() => setGenderDrop(!genderDrop)}
                className="downArrow"
                src={DownArrowIcon}
              />

              <Box
                sx={{ height: genderDrop ? "auto" : "0px" }}
                className="genderDrop"
              >
                <Box
                  onClick={() => {
                    setEditParticipantsApi({
                      ...editparticipantsApi,
                      gender: "male",
                    });
                    setGenderDropVal("Male");
                    setGenderDrop(false);
                  }}
                  className="genderDropItem"
                >
                  <Typography>Male</Typography>
                </Box>
                <Box
                 onClick={() => {
                  setEditParticipantsApi({
                    ...editparticipantsApi,
                    gender: "female",
                  });
                  setGenderDropVal("Female");
                  setGenderDrop(false);
                }}
                className="genderDropItem"
                >
                  <Typography>Female</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setEditParticipantsApi({
                      ...editparticipantsApi,
                      gender: "others",
                    });
                    setGenderDropVal("Other");
                    setGenderDrop(false);
                  }}
                  className="genderDropItem"
                >
                  <Typography>Other</Typography>
                </Box>
              </Box>
              <span className="validationerr">{validationErrors.gender.msg}</span>
            </Box>
          </Box>

          <Typography className="addResearcherLabel">Address</Typography>
          <input
            name="address_line1"
            value={editparticipantsApi?.address_line1}
            onChange={setParticipantFormValue}
            className="addResearcherInput addResearcherInputName"
            placeholder="Enter Participant's valid Address"
          />
          <span className="validationerr inputerr">{validationErrors.address_line1.msg}</span>
          <Box className="basicBox lastBasicBox">
            <Box className="inputBoxOne">
              <Typography className="companyInputLabel">Country</Typography>
              <input
                onClick={(e) => setCountryList(!countryList)}
                value={countryListVal}
                className="workspaceInputTag categoryInputTag"
                placeholder="Choose a Category"
              />
              <img
                style={{ rotate: countryList ? "180deg" : "0deg" }}
                onClick={() => setCountryList(!countryList)}
                className="downArrowPop"
                src={DownArrowIcon}
              />

              <Box
                sx={{
                  maxHeight: countryList ? "300px" : "0px",
                  overflowY: countryList ? "scroll" : "hidden",
                }}
                className="categoryDropDown"
              >
                {countryData.map((data) => (
                  <Box
                    key={data.id}
                    value={data.id}
                    className="categoryDropDownItem"
                    onClick={() => {
                      handleCountryChange(data);
                      setCountryList(false);
                      setCountryListVal(data.name);
                    }}
                  >
                    <Typography>{data.name}</Typography>
                  </Box>
                ))}
              </Box>
              <span className="validationerr">{validationErrors.country.msg}</span>
            </Box>
            <Box className="inputBoxTwo">
              <Typography className="companyInputLabel">State</Typography>
              <input
                onClick={() => setStateList(!stateList)}
                value={stateListVal}
                className="workspaceInputTag categoryInputTag"
                placeholder="Choose a Category"
              />
              <img
                style={{ rotate: stateList ? "180deg" : "0deg" }}
                onClick={() => setStateList(!stateList)}
                className="downArrowPop"
                src={DownArrowIcon}
              />

              <Box
                sx={{
                  maxHeight: stateList ? "300px" : "0px",
                  overflowY: stateList ? "scroll" : "hidden",
                }}
                className="categoryDropDown"
              >
                {statesData.map((data) => (
                  <Box
                    key={data.id}
                    value={data.id}
                    className="categoryDropDownItem"
                    onClick={() => {
                      handleStateChange(data, data.country_id);
                      setStateList(false);
                      setStateListVal(data.name);
                    }}
                  >
                    <Typography>{data.name}</Typography>
                  </Box>
                ))}
              </Box>
              <span className="validationerr">{validationErrors.state.msg}</span>
            </Box>
          </Box>
          <Box className="basicBox lastBasicBox">
            <Box className="inputBoxOne">
              <Typography className="companyInputLabel">City</Typography>
              <input
                onClick={() => setCityList(!cityList)}
                // value={createCompany.city_name}
                value={cityListVal}
                className="workspaceInputTag categoryInputTag"
                placeholder="Choose a Category"
              />
              <img
                style={{ rotate: cityList ? "180deg" : "0deg" }}
                onClick={() => setCityList(!cityList)}
                className="downArrowPop"
                src={DownArrowIcon}
              />

              <Box
                sx={{
                  maxHeight: cityList ? "300px" : "0px",
                  overflowY: cityList ? "scroll" : "hidden",
                }}
                className="categoryDropDown"
              >
                {citiesData.map((data) => (
                  <Box
                    key={data.id}
                    value={data.id}
                    className="categoryDropDownItem"
                    onClick={() => {
                      handleCityChange(data);
                      setCityList(false);
                      setCityListVal(data.name);
                    }}
                  >
                    <Typography>{data.name}</Typography>
                  </Box>
                ))}
              </Box>
              <span className="validationerr">{validationErrors.city.msg}</span>
            </Box>
            <Box className="inputBox">
              <Typography className="addResearcherLabel">ZIP Code</Typography>
              <input
                className="addResearcherInput"
                name="zipcode"
                value={editparticipantsApi?.zipcode}
                onChange={setParticipantFormValue}
                placeholder="Enter Zip code"
              />
              <span className="validationerr">{validationErrors.zipcode.msg}</span>
            </Box>
          </Box>

          <Box className="addResearcherPupBtnBox">
            <AppBtnOutLine
              buttonText="Cancel"
              // onClick={() => setEditParticipantPop(false)}
              onClick={() => {handleCancel()}}
            />
            <AppBtn buttonText="Add" onClick={() => updateData()} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
