import React, { useState } from "react";
import "./style.css";

import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";
import ReactDOM from "react-dom";

//images
import refreshIcon from "../../../Assets/Images/refresh-ccw.png";
import backArrow from "../../../Assets/Images/noun-back.png";
import replyIcon from "../../../Assets/Images/neon-reply.png";
import trash from "../../../Assets/Images/fluent_delete-16-regular.png";
import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
//data
import { mailData, mailData2 } from "../../../Assets/Data";

//Component
import { SearchBar, GoBottom } from "../../AppTools/AppTool";
import BroadcastPop from "../BroadcastPop";
import BroadcastMailPop from "../BroadcastMailPop";
import ComposeMailPop from "../ComposeMailPop";
import PreviewMailPopup from "../PreviewMailPopup";
import ScheduleMailPop from "../ScheduleMailPop";
import CustomGroupPop from "../CustomGroupPop";
import { AppBtnOutLine, AppBtn } from "../../AppTools/AppButton";
import { useAppSelector } from "../../../lib/store/store";

export default function DirectMessage({
  directMessageNavBroadCastPop,
  setDirectMessageNavBroadCastPop,
  directMessageNavComposePop,
  setDirectMessageNavComposePop,
}) {
  const authState = useAppSelector((state) => state.auth);
  const PageContainer = document.getElementById('scroll_Box');
  const [activeNav, setActiveNav] = useState("Inbox");
  const [viewMail, setViewMail] = useState(false);
  const [mailIndex, setMailIndex] = useState();
  const [BroadcastmailPop, setBroadcastMailPop] = useState(false);
  const [composeEmailPop, setComposeMailPop] = useState(false);
  const [previewMailPop, setPreviewMailPop] = useState(false);
  const [scheduleMailPop, setScheduleMailPop] = useState(false);
  const [customGroupPop, setCustomGroupPop] = useState(false);
  const [messageReply, setMessageReply] = useState(false);
  const [replyLink, setReplyLink] = useState(true);
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();
  const [signatureTextUnderLine, setSignatureTextUnderLine] = useState();
  const [mailType, setMailType] = useState("inbox");
  const email1 = "Provide Instructions here";
  const [emailval, setEmailval] = useState();

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine === "underline") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine === "line-through") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("line-through");
        }
      }
    }
  };

  const handelRead = (type) => {
    if (type === "Inbox") {
      let element = document.getElementById("markRead");
      ReactDOM.findDOMNode(element).style.display = "block";
    } else if (type === "Broadcasts") {
      let element = document.getElementById("markRead");
      ReactDOM.findDOMNode(element).style.display = "block";
    } else if (type === "Sent") {
      let element = document.getElementById("markRead");
      ReactDOM.findDOMNode(element).style.display = "none";
    } else if (type === "Draft") {
      let element = document.getElementById("markRead");
      ReactDOM.findDOMNode(element).style.display = "none";
    }
  };

  const InboxCard = ({ avata, title, subject, date, read, index, type }) => {
    return (
      <Box key={index} className="inboxCard">
        <Box className="inboxC1">
          <Box className="inboxAavatar">
            <img src={avata} />
          </Box>
        </Box>
        <Box
          onClick={() => {
            setViewMail(true);
            setMailType(type);
            setMailIndex(index);
            setReplyLink(true);
          }}
          className={read ? "inboxC2 inboxC2Active" : "inboxC2"}
        >
          <Box>
            <Typography className="inbCTitle">{title}</Typography>
            <Typography className="inbDate">{date}</Typography>
          </Box>
          <Typography className="inbCSubject">{subject}</Typography>
        </Box>
      </Box>
    );
  };

  const ViewMail = () => {
    return (
    <>
      <Box sx={{ display: viewMail ? "flex" : "none" }} className="viewMailBox">
        <Box className="viewMailNavBar">
          <Box>
            <Box 
              onClick={() => {
                setViewMail(false);
                setMessageReply(false);
              }}
              className="openBtn"
            >
              <img src={backArrow} />
              <Typography className="neonBackText">
                {t("commonTxt.backBtn")}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ display: replyLink ? "flex" : "none" }} mr={4} className="openBtn"
              onClick={() => {
                setReplyLink(false);
                setMessageReply(true);
                setTimeout(() => {
                  GoBottom(PageContainer);
                }, 500);
              }}
            >
              <img src={replyIcon} />
              <Typography>{t("directMessageAdmin.reply")}</Typography>
            </Box>
            <Box className="openBtn">
              <img src={trash} />
              <Typography>{t("commonTxt.delete")}</Typography>
            </Box>
          </Box>
        </Box>

        <Box className="inboxCard">
          <Box className="inboxC1">
            <Box className="inboxAavatar">
              <img src={mailData2[0]["msg"][mailType][mailIndex]?.img} />
            </Box>
          </Box>
          <Box className="inboxC2">
            <Box>
              <Typography className="inbCSubject">
                {mailData2[0]["msg"][mailType][mailIndex]?.subject}
              </Typography>
              <Typography className="inbDate">
                {mailData2[0]["msg"][mailType][mailIndex]?.date}
              </Typography>
            </Box>
            <Typography className="inbCTitle">
              {mailData2[0]["msg"][mailType][mailIndex]?.title}{" "}
              <span>to me </span>
            </Typography>
            <Typography mb={1}>
              I hope this email finds you well, I wanted to reach out and
              provide you with some important updates and information to ensure
              that your participation in the activity is as smooth and enjoyable
              as possible.
            </Typography>
            <Box className="mailViewTextBox">
              <span className="BoldParagrap">1.</span>
              <Typography ml={1} mb={1}>
                <span className="BoldParagrap">Activity Schedule:</span> We have
                finalized the activity schedule, and I wanted to share it with
                you in advance. You can find the detailed schedule attached to
                this email. Please review it carefully and make note of any
                sessions or activities that you are particularly interested in.
              </Typography>
            </Box>
            <Box className="mailViewTextBox">
              <span className="BoldParagrap">2.</span>
              <Typography ml={1} mb={1}>
                <span className="BoldParagrap">Venue Details:</span> The event
                will be held at [Venue Name], located at [Address]. We have also
                attached a map to help you navigate your way to the venue. Upon
                arrival, our registration desk will be open to assist you with
                check-in and provide any additional information you may need.
              </Typography>
            </Box>
            <Box className="mailViewTextBox">
              <span className="BoldParagrap">3.</span>
              <Typography ml={1} mb={1}>
                <span className="BoldParagrap">Networking Opportunities:</span>{" "}
                One of the highlights of [Event Name] is the excellent
                networking opportunities it offers. We have organized several
                networking sessions and social events where you can connect with
                fellow participants, speakers, and sponsors. Take advantage of
                these opportunities to expand your professional network.
              </Typography>
            </Box>
            <Box className="mailViewTextBox">
              <span className="BoldParagrap">4.</span>
              <Typography ml={1} mb={1}>
                <span className="BoldParagrap">Special Guest Speakers:</span> We
                are thrilled to announce that [Name], [Position/Title] from
                [Company/Organization], will be joining us as a special guest
                speaker. This is a unique opportunity to gain insights from a
                leader in the industry. Make sure to attend [his/her] session on
                [Date and Time].
              </Typography>
            </Box>
            <Box className="mailViewTextBox">
              <span className="BoldParagrap">5.</span>
              <Typography ml={1} mb={1}>
                <span className="BoldParagrap">Q&A Sessions:</span> We have
                allocated time for Q&A sessions after each presentation. This is
                your chance to engage with the speakers directly and get your
                questions answered. Please come prepared with any questions you
                may have.
              </Typography>
            </Box>
            <Box className="mailViewTextBox">
              <span className="BoldParagrap">6.</span>
              <Typography ml={1} mb={1}>
                <span className="BoldParagrap">Social Media Engagemen:</span>{" "}
                Join the conversation on social media by using our official
                event hashtag, #[EventHashtag]. Share your thoughts, insights,
                and photos throughout the event. We love seeing your perspective
                and creating a vibrant online community.
              </Typography>
            </Box>
            <Typography mb={1}>
              If you have any specific questions or concerns before the event,
              feel free to reply to this email, and our team will be happy to
              assist you.
            </Typography>
            <Typography mb={1}>
              Thank you for being part of activity. We are looking forward to
              seeing you and making this event a memorable experience for
              everyone involved.
            </Typography>
            <Typography mb={1}>Best regards,</Typography>
          </Box>
        </Box>
      </Box>
      
      <Box sx={{ display: messageReply ? "flex" : "none" }} className="ReplyingSection">
        <Box className="topPanel">
          <img src={replyIcon} />{" "}
          <Typography>{t("directMessageAdmin.replyingTo")} Daina Henry</Typography>
        </Box>
        <Box className="emailInputBox">
          <Box className="emailInputBoxNav">
            <Box
              onClick={() => setMessageTextItalic(!messageTextItalic)}
              className="emailImgBox"
            >
              <img src={IIcon} />
            </Box>
            <Box
              onClick={() => setMessageTextBold(!messageTextBold)}
              className="emailImgBox"
            >
              <img src={BIcon} />
            </Box>
            <Box
              onClick={() => handelTextlineChekc("underline", "message")}
              className="emailImgBox"
            >
              <img src={UnderLineU} />
            </Box>
            <Box
              onClick={() => handelTextlineChekc("line-through", "message")}
              className="emailImgBox"
            >
              <img src={MLineU} />
            </Box>
            <Box className="emailImgBox">
              <img src={AtachMentIcon} />
            </Box>
            <Box className="emailImgBox">
              <img src={Qbox} />
            </Box>
            <Box
              onClick={() => setMessageTextLaOut("start")}
              className="emailImgBox"
            >
              <img src={Line1} />
            </Box>
            <Box
              onClick={() => setMessageTextLaOut("center")}
              className="emailImgBox"
            >
              <img src={Line2} />
            </Box>
            <Box
              onClick={() => setMessageTextLaOut("end")}
              className="emailImgBox"
            >
              <img src={Line3} />
            </Box>
            <Box className="emailImgBox">
              <img src={Line4} />
            </Box>
            <Box className="emailImgBox">
              <img src={emoji} />
            </Box>
          </Box>
          <textarea
            style={{
              textAlign: messageTextLaOut,
              fontStyle: messageTextItalic ? "italic" : "normal",
              fontWeight: messageTextBold ? "bold" : "normal",
              textDecoration: messageTextUnderLine,
              // textDecoration: messageTextMLine ? "line-through" : null
            }}
            className="emailInput"
            name="emailMessage"
            value={emailval?.emailMessage || email1}
            onChange={handelChange}
          />
        </Box>
        <Box className="workPupBtnBox">
          <AppBtnOutLine
            buttonText={t("commonTxt.cancelBtn")}
          />
          <AppBtn buttonText={t("commonTxt.saveBtn")} />
        </Box>
      </Box>
    </>
    );
  };

  return (
    <>
    <Box id="scroll_Box">
    <Box id="messageDiv">
      {viewMail ? (
        <ViewMail />
      ) : (
        <Box className="direceMessageContainer">
          <BroadcastPop
            directMessageNavBroadCastPop={directMessageNavBroadCastPop}
            setDirectMessageNavBroadCastPop={setDirectMessageNavBroadCastPop}
            setBroadcastMailPop={setBroadcastMailPop}
            setCustomGroupPop={setCustomGroupPop}
          />
          <CustomGroupPop
            setCustomGroupPop={setCustomGroupPop}
            customGroupPop={customGroupPop}
            setBroadcastMailPop={setBroadcastMailPop}
          />

          <BroadcastMailPop
            BroadcastmailPop={BroadcastmailPop}
            setBroadcastMailPop={setBroadcastMailPop}
            setPreviewMailPop={setPreviewMailPop}
            setScheduleMailPop={setScheduleMailPop}
            setDirectMessageNavBroadCastPop={setDirectMessageNavBroadCastPop}
          />
          <ComposeMailPop
            directMessageNavComposePop={directMessageNavComposePop}
            setDirectMessageNavComposePop={setDirectMessageNavComposePop}
            composeEmailPop={composeEmailPop}
            setComposeMailPop={setComposeMailPop}
            setPreviewMailPop={setPreviewMailPop}
            scheduleMailPop={scheduleMailPop}
            setScheduleMailPop={setScheduleMailPop}
          />
          <PreviewMailPopup
            previewMailPop={previewMailPop}
            setPreviewMailPop={setPreviewMailPop}
            setDirectMessageNavComposePop={setDirectMessageNavComposePop}
            scheduleMailPop={scheduleMailPop}
          />
          <ScheduleMailPop
            scheduleMailPop={scheduleMailPop}
            setScheduleMailPop={setScheduleMailPop}
          />
          <Box className="dMsgHeader">
            <Typography className="pageHeaderText">
              {t("directMessageAdmin.directMsg")}
            </Typography>
            <Box className="msgCountBox">
              <p>3</p>
            </Box>
            <img className="refreshIcon" src={refreshIcon} />
          </Box>

          <Box className="dMsgNavBox">
            <Box
              onClick={() => {
                setActiveNav("Inbox");
                handelRead("Inbox");
              }}
              className={
                activeNav === "Inbox"
                  ? "dMsgNavItem dMsgNavItemActive"
                  : "dMsgNavItem "
              }
            >
              <Typography>{t("directMessageAdmin.inbox")}</Typography>
            </Box>
            <Box
              onClick={() => {
                setActiveNav("Broadcasts");
                handelRead("Broadcasts");
              }}
              className={
                activeNav === "Broadcasts"
                  ? "dMsgNavItem dMsgNavItemActive"
                  : "dMsgNavItem "
              }
            >
              <Typography>{t("directMessageAdmin.broadcast")}</Typography>
            </Box>
            <Box
              onClick={() => {
                setActiveNav("Sent");
                handelRead("Sent");
              }}
              className={
                activeNav === "Sent"
                  ? "dMsgNavItem dMsgNavItemActive"
                  : "dMsgNavItem "
              }
            >
              <Typography>{t("directMessageAdmin.sent")}</Typography>
            </Box>
            <Box
              onClick={() => {
                setActiveNav("Draft");
                handelRead("Draft");
              }}
              className={
                activeNav === "Draft"
                  ? "dMsgNavItem dMsgNavItemActive"
                  : "dMsgNavItem "
              }
            >
              <Typography>{t("directMessageAdmin.draft")}</Typography>
            </Box>
          </Box>

          <Box className="dMsgSearchBox">
            <Box className="searchB">
              <SearchBar />
            </Box>
            <Typography className="markText" id="markRead">
              {t("directMessageAdmin.markAllRead")}
            </Typography>
          </Box>

          <Box
            sx={{
              width: activeNav === "Inbox" ? "100%" : "0%",
              display: activeNav === "Inbox" ? "flex" : "none",
            }}
            className="inboxCardBox"
          >
            {mailData2[0]["msg"]["indox"] &&
              mailData2[0]["msg"]["indox"]?.map((el, index) =>
                InboxCard({
                  avata: el.img,
                  title: el.title,
                  subject: el.subject,
                  date: el.date,
                  read: el.read,
                  index,
                  type: "indox",
                })
              )}
          </Box>
          <Box
            sx={{
              width: activeNav === "Broadcasts" ? "100%" : "0%",
              display: activeNav === "Broadcasts" ? "flex" : "none",
            }}
            className="broadcastCardBox"
          >
            {mailData2[0]["msg"]["broadcast"] &&
              mailData2[0]["msg"]["broadcast"]?.map((el, index) =>
                InboxCard({
                  avata: el.img,
                  title: el.title,
                  subject: el.subject,
                  date: el.date,
                  read: el.read,
                  index,
                  type: "broadcast",
                })
              )}
          </Box>
          <Box
            sx={{
              width: activeNav === "Sent" ? "100%" : "0%",
              display: activeNav === "Sent" ? "flex" : "none",
            }}
            className="sentCardBox"
          >
            {mailData2[0]["msg"]["sent"] &&
              mailData2[0]["msg"]["sent"]?.map((el, index) =>
                InboxCard({
                  avata: el.img,
                  title: el.title,
                  subject: el.subject,
                  date: el.date,
                  read: el.read,
                  index,
                  type: "sent",
                })
              )}
          </Box>
          <Box
            sx={{
              width: activeNav === "Draft" ? "100%" : "0%",
              display: activeNav === "Draft" ? "flex" : "none",
            }}
            className="draftCardBox"
          >
            {mailData2[0]["msg"]["draft"] &&
              mailData2[0]["msg"]["draft"]?.map((el, index) =>
                InboxCard({
                  avata: el.img,
                  title: el.title,
                  subject: el.subject,
                  date: el.date,
                  read: el.read,
                  index,
                  type: "draft",
                })
              )}
          </Box>
        </Box>
      )}
    </Box>
    </Box>
    </>
  );
}
