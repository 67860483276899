import React, { useEffect } from "react";
import "./style.css";
import { Box, Typography } from "@mui/material";
import IIcon from "../../../Assets/Images/IIcon.png";
import axios from '../../../lib/axios'
//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";

export default function WorkspaceDeletePop({
  deleteWorkspace,
  setDeleteWorkspace,
  setDeleteErrorWorkspace,
  deleteWorkSpaceListData,
  setReloadList
}) {
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setDeleteWorkspace(false);
    }
  };

  const handelDeletePop = () => {
    setDeleteWorkspace(false);
      return new Promise((resolve, reject) => {
          axios.delete(`/super-admin/admin/workspaces/delete/${deleteWorkSpaceListData?._id}`).then((res) => {
              if (res.success) {
                  resolve({
                      success: true,
                      message: res.message
                  });
                  toast.success(res.message, TOAST_CONFIG);
                  setReloadList(true);
              } else {
                  reject({
                      success: false,
                      message: res.message
                  });
                  toast.success(res.message, TOAST_CONFIG);
              }
          }).catch((err) => {
              reject({
                  success: false,
                  message: err.message
              });
          })
      });
    // setDeleteErrorWorkspace(true);
  };

  useEffect(() => {
    if (deleteWorkspace) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [deleteWorkspace]);

  return (
    <>
      <Box
        onClick={handelPopClose}
        id="popBackdrop"
        sx={{ display: deleteWorkspace ? "flex" : "none" }}
        className="workspaceDeletePopContainer"
      >
        <Box className="workspaceDeleteBox workspaceDeletePopTextAlign">
          <Typography className="deletePopHeader">
            Delete “{deleteWorkSpaceListData?.name}” ?
          </Typography>

          <Box className="emailVerifyTextBox infoEmailVerifyTextBox">
            <Box className="iIcontD">
              <img src={IIcon} />
            </Box>
            <Box>
              <Typography>
                For a workspace to be deleted, all the projects within it must
                either be closed, deleted, archived or in draft mode. Even if
                any one of the projects is left open, the action cannot be
                completed.
              </Typography>
            </Box>
          </Box>
          <Box className="deleteTextBox">
            <Typography>
              Are you sure you want to delete “{deleteWorkSpaceListData?.name}” Workspace from
              Terapage? Kindly note that this action cannot be un-done, any
              Workspace once deleted cannot be retrieved again.
            </Typography>
          </Box>
          <Box className="deleteBtnBox">
            <AppBtnOutLine
              onClick={() => setDeleteWorkspace(false)}
              buttonText="Cancel"
            />
            <AppBtn buttonText="Delete" onClick={handelDeletePop} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
