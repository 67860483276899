import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css"

import sysBg from "../../../Assets/Images/sysBg.png"
import checkG from "../../../Assets/Images/checkG.png"

//components
import { AppBtn, AppBtnOutLine } from "../../../Components/AppTools/AppButton"

export default function SystemCheckPop({ systemCheckPop, setSystemCheckPop }) {

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setSystemCheckPop(false);
    }
  };

  useEffect(() => {
    if (systemCheckPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [systemCheckPop]);


  return (
    <Box onClick={handelPopClose} sx={{ display: systemCheckPop ? "flex" : "none" }} className="workspacePop systemCheck" id="popBackdrop">
      <Box sx={{ display: systemCheckPop ? "flex" : "none" }} className="workspaceContaint">

        <Box className="systemSubBox">
          <Typography className="companyHeader">All Tests Passed</Typography>
          <Typography>All the required tests are passed. Download the report
            for detailed information related to test.</Typography>

          <Box className="sysImgBox">
            <img src={sysBg} />
          </Box>

          <Typography className="blueText">Unsure about Results ? Restart Test</Typography>
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={() => setSystemCheckPop(false)} />
            <AppBtn buttonText="Download Report" />
          </Box>
        </Box>

        <Box className="systemSubBox">
          <Box className="labelWTik">
            <img src={checkG} />
            <Typography>Device Setup</Typography>
          </Box>
          <Typography>No issues found in audio & video from hardware and browser side.</Typography>

          <Box className="blueUTextBox">
            <Typography className="blueUText">Review Browser</Typography>
            <Typography className="blueUText">Review hardware</Typography>
          </Box>
          <Box className="labelWTik">
            <img src={checkG} />
            <Typography>Connectivity</Typography>
          </Box>
          <Typography>All connections are working without any issues.</Typography>
          <Typography className="blueUText">Review Connectivity</Typography>
          <Box className="labelWTik">
            <img src={checkG} />
            <Typography>Performance</Typography>
          </Box>
          <Typography>The audio & video performance is excellent as per the performance</Typography>
          <Typography className="blueUText">Review Performance</Typography>
        </Box>
      </Box>
    </Box>

  );
}
