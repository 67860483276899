import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

//components
import { AppBtn, AppBtnOutLine } from "../../../Components/AppTools/AppButton";

export default function ProjectObserverPermission({ projectSettingDropVal }) {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  return (
    <>
      <Box
        sx={{
          display:
            projectSettingDropVal === "Observer Permissions" ? "block" : "none",
        }}
      >
        <Typography className="emailNotificationHeaderText projectObserverPermissionHead">
          {t("projectObserverPermission.permittedActionsHead")}
        </Typography>
        <Box className="checkPermissionBox">
          <Box className="checkPermissionSection">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>
              {t("projectObserverPermission.permittedActionsCheckbox1")}
            </Typography>
          </Box>
          <Box className="checkPermissionSection">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>
              {t("projectObserverPermission.permittedActionsCheckbox2")}
            </Typography>
          </Box>
          <Box className="checkPermissionSection">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>
              {t("projectObserverPermission.permittedActionsCheckbox3")}
            </Typography>
          </Box>
          <Box className="checkPermissionSection">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>
              {t("projectObserverPermission.permittedActionsCheckbox4")}
            </Typography>
          </Box>
          <Box className="checkPermissionSection">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>
              {t("projectObserverPermission.permittedActionsCheckbox5")}
            </Typography>
          </Box>
          <Box className="checkPermissionSection">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>
              {t("projectObserverPermission.permittedActionsCheckbox6")}
            </Typography>
          </Box>
          <Box className="checkPermissionSection">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>
              {t("projectObserverPermission.permittedActionsCheckbox7")}
            </Typography>
          </Box>
          <Box className="checkPermissionSection">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>
              {t("projectObserverPermission.permittedActionsCheckbox8")}
            </Typography>
          </Box>
          <Box className="checkPermissionSection">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>
              {t("projectObserverPermission.permittedActionsCheckbox9")}
            </Typography>
          </Box>
          <Box className="checkPermissionSection">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>
              {t("projectObserverPermission.permittedActionsCheckbox10")}
            </Typography>
          </Box>
        </Box>
        <Box className="workPupBtnBox">
          <AppBtnOutLine buttonText={t("commonTxt.resetBtn")} />
          <AppBtn buttonText={t("commonTxt.saveBtn")} />
        </Box>
      </Box>
    </>
  );
}
