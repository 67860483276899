import React, { useState, useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import WorkspacesNoData from "../../../Assets/Images/No data-cuate 1.png";
import "./style.css";

import {
  SearchBar,
  Status,
  LatestCreated,
  GoTop,
} from "../../AppTools/AppTool";

import {
  editIconBlack,
  xCircleBlack,
  archiveBlack,
  trashBlack,
} from "../../../Assets/Data";

//components
import EditWorkspacePop from "../EditWorkSpace";
import WorkspaceDeletePop from "../WorkSpaceDeletePop";
import CompanyPop from "../CompanyPop";
import AddResearcherPop from "../AddResearcherPop/index.jsx";
import WorkspaceDeleteErrorPop from "../WorkspaceDeleteErrorPop";
import { setWorkspace } from "../../../lib/store/features/workspaceSlice";
import { useAppDispatch, useAppSelector } from "../../../lib/store/store";
import axios from "../../../lib/axios";
import {
  capitalizeFirstLetter,
  formatDate,
  handleOutsideClick,
  removeHandleOutsideClick,
  viewImage,
} from "../../../lib/helpers";
import { STORAGE_BASE_PATH } from "../../../env";
import Pagination from "../../AppTools/Pagination";
import { AppAddBtn } from "../../AppTools/AppButton";
import NoWorkspaceBG from "../NoWorkspaceBG";
import { updateWorkSpaceManagementApi } from "../../../Services/adminActions";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import EditCompanyPop from "../EditCompanyPop";

export default function WorkspaceList({
  companyPop,
  setCompanyPop,
  workSpacePop,
  setWorkSpacePop,
  setReloadList,
  setEditCompanyPop,
  editCompanyPop,
  reloadList,
  setAddResearcherPop,
  addResearcherPop,
  setWorkspaceResearcher,
  workspaceResearcher,
  setEditWorkspaceResearcher,
  allCompaniesData,
  setAllCompaniesData,
  setWorkspacePopType,
  workspacePopType,
  editWorkspaceResearcher,
  setWorkspaceCompany,
  workspaceCompany,
  setEditWorkspaceCompany,
  editWorkspaceCompany,
  setcreatedAffiliate,
  createdAffiliate,
  setShowCompanyUser,
  showCompanyUser,
}) {
  setcreatedAffiliate = setcreatedAffiliate || false;

  const [statusDrop, setStatusDrop] = useState(false);
  const [statusDropVal, setStatusDropVal] = useState("All");
  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Latest Created");
  const [optionIndex, setOptionIndex] = useState();
  const [workSpaceOption, setWorkspaceOption] = useState(false);
  const [editWorkspace, setEditWorkspace] = useState(false);
  const [deleteWorkspace, setDeleteWorkspace] = useState(false);
  const [adminOptionDropIndex, setadminOptionDropIndex] = useState();
  const [adminOptionDrop, setadminOptionDrop] = useState(false);
  const [allWorkSpaceListData, setAllWorkSpaceListData] = useState([]);
  const [deleteErrorWorkspace, setDeleteErrorWorkspace] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteWorkSpaceListData, setDeleteWorkSpaceListData] = useState("");

  const [updateWorkSpaceData, setUpdateWorkSpaceData] = useState("");

  let authState = useAppSelector((state) => state.auth);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const defaultFilters = {
    search: "",
    sortBy: "created_at",
    sortOrder: -1,
    status: "",
  };

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 1,
    totalPages: 0,
    totalItems: 0,
  });

  const [filterState, setFilterState] = useState({ ...defaultFilters });

  const handleFilterChange = (changedFilter) => {
    let newFilters = {
      ...filterState,
      ...changedFilter,
    };
    setFilterState(newFilters);
    getWorkSpaceList(1, newFilters);
  };

  const workspaceOptionRef = useRef(null);
  const statusDropRef = useRef(null);
  const latestDropRef = useRef(null);
  const adminDropRef = useRef(null);
  const dispatch = useAppDispatch();

  const handleOptionIndex = (i) => {
    setOptionIndex(i);
  };

  useEffect(() => {
    let outsideEv = handleOutsideClick([
      {
        setDropdownState: setWorkspaceOption,
        className: "optionPop",
      },
      {
        setDropdownState: setStatusDrop,
        className: "statusPop",
      },
      {
        setDropdownState: setLatestDrop,
        className: "statusPop",
      },
      {
        setDropdownState: setadminOptionDrop,
        className: "statusPop",
      },
    ]);

    getWorkSpaceList(1);

    return () => {
      removeHandleOutsideClick(outsideEv);
    };
  }, []);

  useEffect(() => {
    if (reloadList) {
      getWorkSpaceList(1);
      setReloadList(false);
    }
  }, [reloadList]);

  const getWorkSpaceList = async (curPage, filters = filterState) => {
    setLoading(true);
    let res = await axios.get(
      `/super-admin/admin/workspaces?page=${curPage}&search=${filters.search}&status=${filters.status}&sortBy=${filters.sortBy}&sortOrder=${filters.sortOrder}`
    );
    setLoading(false);
    if (res.status) {
      setAllWorkSpaceListData(res.data.data);
      setPaginationData({
        currentPage: res.data.pagination.currentPage,
        itemsPerPage: res.data.pagination.itemsPerPage,
        totalPages: res.data.pagination.totalPages,
        totalItems: res.data.pagination.totalItems,
      });
    }
  };

  const onPageChange = (pageNum) => {
    getWorkSpaceList(pageNum, filterState);
  };

  let handleUpdateWorkspace = async (obj, status) => {
    setLoading(true);
    try {
      let requestPayload = {
        status: status,
      };
      let workSpaceRes = await updateWorkSpaceManagementApi(
        obj._id,
        requestPayload,
        authState.authToken
      );
      setLoading(false);
      if (workSpaceRes.success) {
        toast.success(workSpaceRes.message, TOAST_CONFIG);
        setReloadList(true);
      } else {
        toast.error(workSpaceRes.message, TOAST_CONFIG);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  const handleDeleteWorkspace = (val) => {
    setDeleteWorkSpaceListData(val);
  };

  return (
    <>
      <Box className="workspaceListBox workspaceListBoxV1">
        <CompanyPop
          setCompanyPop={setCompanyPop}
          companyPop={companyPop}
          editWorkspaceCompany={editWorkspaceCompany}
          workSpacePop={workSpacePop}
          setWorkSpacePop={setWorkSpacePop}
          workspaceCompany={workspaceCompany}
          setEditWorkspace={setEditWorkspace}
          setcreatedAffiliate={setcreatedAffiliate}
          workspacePopType={workspacePopType}
          setShowCompanyUser={setShowCompanyUser}
        />
        <AddResearcherPop
          setAddResearcherPop={setAddResearcherPop}
          addResearcherPop={addResearcherPop}
          workspaceResearcher={workspaceResearcher}
          workSpacePop={workSpacePop}
          setWorkSpacePop={setWorkSpacePop}
          editWorkspaceResearcher={editWorkspaceResearcher}
          setEditWorkspace={setEditWorkspace}
          setcreatedAffiliate={setcreatedAffiliate}
          workspacePopType={workspacePopType}
        />
        <EditCompanyPop
          editCompanyPop={editCompanyPop}
          setEditCompanyPop={setEditCompanyPop}
          workSpacePop={workSpacePop}
          workspacePopType={workspacePopType}
          setWorkSpacePop={setWorkSpacePop}
          setAllCompaniesData={setAllCompaniesData}
          allCompaniesData={allCompaniesData}
          setEditWorkspace={setEditWorkspace}
        />
        {editWorkspace && (
          <EditWorkspacePop
            setWorkSpacePop={setWorkSpacePop}
            setCompanyPop={setCompanyPop}
            setWorkspaceCompany={setWorkspaceCompany}
            setEditWorkspaceCompany={setEditWorkspaceCompany}
            editWorkspaceCompany={editWorkspaceCompany}
            setEditWorkspace={setEditWorkspace}
            workspacePopType={workspacePopType}
            setWorkspacePopType={setWorkspacePopType}
            setAllCompaniesData={setAllCompaniesData}
            editWorkspace={editWorkspace}
            setAddResearcherPop={setAddResearcherPop}
            setWorkspaceResearcher={setWorkspaceResearcher}
            setEditWorkspaceResearcher={setEditWorkspaceResearcher}
            setUpdateWorkSpaceData={setUpdateWorkSpaceData}
            updateWorkSpaceData={updateWorkSpaceData}
            setReloadList={setReloadList}
            setEditCompanyPop={setEditCompanyPop}
            createdAffiliate={createdAffiliate}
          />
        )}

        <WorkspaceDeletePop
          deleteWorkspace={deleteWorkspace}
          setDeleteWorkspace={setDeleteWorkspace}
          setDeleteErrorWorkspace={setDeleteErrorWorkspace}
          deleteWorkSpaceListData={deleteWorkSpaceListData}
          setReloadList={setReloadList}
        />
        <WorkspaceDeleteErrorPop
          deleteErrorWorkspace={deleteErrorWorkspace}
          setDeleteErrorWorkspace={setDeleteErrorWorkspace}
          setDeleteWorkspace={setDeleteWorkspace}
        />
        <Box className="workListNavItem workListNavItemMobile">
          <Typography className="pageHeaderText">
            {t("workspaceList.allWorkspace")}
          </Typography>
        </Box>
        <Box className="workListNav">
          <Box className="workListNavItem workListNavItemDesktop">
            <Typography className="pageHeaderText">
              {t("workspaceList.allWorkspace")}
            </Typography>
          </Box>
          <Box className="workListNavItem workListSearchBar">
            <SearchBar handleFilterChange={handleFilterChange} />
          </Box>
          <Box
            sx={{ minWidth: "145px" }}
            className="workListNavItem workshotList"
          >
            <Status
              ref={statusDropRef}
              setStatusDrop={setStatusDrop}
              statusDrop={statusDrop}
              handleFilterChange={handleFilterChange}
              setStatusDropVal={setStatusDropVal}
              statusDropVal={statusDropVal}
            />
          </Box>
          <Box
            sx={{ minWidth: "215px" }}
            className="workListNavItem workLatest"
          >
            <LatestCreated
              ref={latestDropRef}
              setLatestDrop={setLatestDrop}
              handleFilterChange={handleFilterChange}
              latestCDrop={latestCDrop}
              setLatestDropVal={setLatestDropVal}
              latestDropVal={latestDropVal}
            />
          </Box>
        </Box>
        <Box className="workListBox">
          <Box className="haderRow" minWidth={"1111px"}>
            <Box
              // minWidth={"290px"}
              minWidth={"31%"}
              className="Tabelsel TabelStatesel"
            >
              <Typography>{t("commonTxt.workspaces")}</Typography>
            </Box>
            <Box minWidth={"12%"} className="Tabelsel">
              <Typography>{t("commonTxt.projects")}</Typography>
            </Box>
            <Box minWidth={"14%"} className="Tabelsel">
              <Typography>{t("commonTxt.userType1As")}</Typography>
            </Box>

            <Box minWidth={"14%"} className="Tabelsel">
              <Typography>{t("commonTxt.userType5A")}</Typography>
            </Box>
            <Box minWidth={"16%"} className="Tabelsel">
              <Typography>{t("workspaceList.createdOn")}</Typography>
            </Box>
            <Box minWidth={"13%"} className="Tabelsel statusSel">
              <Typography>{t("commonTxt.status")}</Typography>
            </Box>
          </Box>
          {allWorkSpaceListData.length > 0 ? (
            allWorkSpaceListData?.map((el, index) => (
              <Box key={index} className="TabelRow" minWidth={"1111px"}>
                <Box minWidth={"31%"} className="Tabelsel navFsel navtable">
                  <Box
                    ref={workspaceOptionRef}
                    id="dot"
                    onClick={() => {
                      setWorkspaceOption(!workSpaceOption);
                      setOptionIndex(index);
                    }}
                    className={
                      workSpaceOption && index === optionIndex
                        ? "dot dotActive"
                        : "dot"
                    }
                  >
                    {
                      <Box
                        sx={{
                          display:
                            workSpaceOption && index === optionIndex
                              ? "flex"
                              : "none",
                        }}
                        className="optionPop"
                      >
                        <Box
                          onClick={(event) => {
                            event.stopPropagation();
                            setEditWorkspace(true);
                            setWorkspacePopType("edit");
                            // GoTop();
                            setUpdateWorkSpaceData(el?._id);
                          }}
                          className="optionPopItem"
                        >
                          {editIconBlack}
                          <Typography className="optionPopItema">
                            {t("workspaceList.editWorkspace")}
                          </Typography>
                        </Box>
                        <Box
                          className="optionPopItem"
                          onClick={() =>
                            handleUpdateWorkspace(
                              el,
                              el.status == "closed" ? "open" : "closed"
                            )
                          }
                        >
                          {xCircleBlack}
                          <Typography className="optionPopItema">
                            {el.status == "closed" ? "Open" : "Close"}{" "}
                            {t("commonTxt.workspace")}
                          </Typography>
                        </Box>
                        <Box
                          className="optionPopItem"
                          onClick={() =>
                            handleUpdateWorkspace(
                              el,
                              el.status == "archived" ? "open" : "archived"
                            )
                          }
                        >
                          {archiveBlack}
                          <Typography className="optionPopItema">
                            {el.status == "archived" ? "UnArchive" : "Archive"}{" "}
                            Workspace
                          </Typography>
                        </Box>
                        <Box
                          onClick={() => {
                            setDeleteWorkspace(true);
                            handleDeleteWorkspace(el);
                            GoTop();
                          }}
                          className="optionPopItem"
                        >
                          {trashBlack}
                          <Typography className="optionPopItema">
                            {t("workspaceList.deleteWorkspace")}
                          </Typography>
                        </Box>
                      </Box>
                    }
                  </Box>
                  <Box
                    onClick={() => handleOptionIndex(index)}
                    className="tabelAvatar"
                  >
                    <img src={viewImage(el.cover_image)} />
                  </Box>
                  <Typography
                    onClick={() => dispatch(setWorkspace(el))}
                    sx={{ cursor: "pointer", width: "95%" }}
                  >
                    {el.name}
                  </Typography>
                </Box>
                <Box minWidth={"12%"} className="Tabelsel Tabelcentersel">
                  <Typography>{el?.project_count || 0}</Typography>
                </Box>

                <Box
                  minWidth={"14%"}
                  onClick={() => {
                    setadminOptionDropIndex(index);
                    setadminOptionDrop(!adminOptionDrop);
                  }}
                  className="Tabelsel adminTabDrop Tabelcentersel"
                >
                  <Typography>
                    {parseInt(el?.user_role_counts?.researcher || 0) +
                      parseInt(el?.user_role_counts?.collaborators || 0) +
                      parseInt(el?.user_role_counts?.observers || 0)}
                  </Typography>
                  {/* <img src={DownArrow} /> */}
                  <KeyboardArrowDownIcon
                    ref={adminDropRef}
                    id="keyBoardArrow"
                    style={{
                      transform: `rotate(${
                        adminOptionDrop && index === adminOptionDropIndex
                          ? 180
                          : 0
                      }deg)`,
                    }}
                  />
                  <Box
                    sx={{
                      display:
                        adminOptionDrop && index === adminOptionDropIndex
                          ? "flex"
                          : "none",
                    }}
                    className="adminDrop"
                  >
                    <Typography>
                      {t("commonTxt.userType2A")}:{" "}
                      {el?.user_role_counts?.researcher || 0}
                    </Typography>
                    <Typography>
                      {t("commonTxt.userType3A")}:{" "}
                      {el?.user_role_counts?.collaborators || 0}
                    </Typography>
                    <Typography>
                      {t("commonTxt.userType4A")}:{" "}
                      {el?.user_role_counts?.observers || 0}
                    </Typography>
                  </Box>
                </Box>

                <Box minWidth={"14%"} className="Tabelsel Tabelcentersel">
                  <Typography>
                    {el?.user_role_counts?.participant || 0}
                  </Typography>
                </Box>
                <Box minWidth={"16%"} className="Tabelsel Tabelcentersel">
                  <Typography>{formatDate(el.created_at)}</Typography>
                </Box>
                <Box
                  minWidth={"13%"}
                  className="Tabelsel statusSel Tabelcentersel"
                >
                  <Typography>{capitalizeFirstLetter(el.status)}</Typography>
                </Box>
              </Box>
            ))
          ) : (
            <NoWorkspaceBG loading={loading} />
          )}
        </Box>

        <Pagination
          paginationData={paginationData}
          onPageChange={onPageChange}
        />
      </Box>
    </>
  );
}
