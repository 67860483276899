import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Box, Typography, Grid, FormLabel } from "@mui/material";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import {
  sideHaumIcon,
  participantsData,
  addIconBlack,
  inviteGroupBlack,
  removeGroupBlack,
  trashBlack,
  xCircleBlack,
} from "../../../Assets/Data";

import { AppBtn } from "../../../Components/AppTools/AppButton";
import backArrow from "../../../Assets/Images/noun-back.png";

import dropArrow from "../../../Assets/Images/chevron-down.png";
import NIcon from "../../../Assets/Images/neutronIcon.png";
import userF from "../../../Assets/Images/userInF.png";

import SideMenu from "../../../Components/Collaborator/SideMenu";
import {
  SearchBar,
  AntSwitch,
  GoTop,
} from "../../../Components/AppTools/AppTool";

export default function ExistingParticipants({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
}) {
  const Navigate = useNavigate();
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const [selectFilterbyProject, setselectFilterbyProject] = useState("");

  const [nav, setNav] = useState(0);

  const [bulkActionDrop, setBulkActionDrop] = useState(false);
  const [bulkActionDropVal, setBulkActionDropVal] = useState(false);
  const [assignedStatusDrop, setAssignedStatusDrop] = useState(false);

  const [assignedParticipant, setAssignedParticipants] = useState(false);
  const [assignedStatusDropVal, setAssignedStatusDropVal] = useState(false);
  const [participantStatusDrop, setParticipantStatusDrop] = useState(false);
  const [participantStatusDropVal, setParticipantStatusDropVal] =
    useState(false);

  const [checkboxes, setCheckboxes] = useState(
    participantsData.map(() => false)
  );

  const trueValues = checkboxes?.filter((value) => value === true);

  const isOnlyOneTrue = trueValues.length === 1;

  const [selectFilterbyRole, setselectFilterbyRole] = useState("");

  const handleCheckboxChange = () => {};

  const handleCheckAllChange = (event) => {
    const isChecked = event.target.checked;
    setCheckboxes(checkboxes.map(() => isChecked));
  };

  const handleChange1 = (event, SelectChangeEvent) => {
    setselectFilterbyProject(event.target.value);
  };

  const handleChange2 = (event, SelectChangeEvent) => {
    setselectFilterbyRole(event.target.value);
  };
  const previousPageHandler = () => {
    Navigate("/collaborator/project/directory_participants");
  };
  return (
    <>
      <Box className="ResearchersContainer">
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
          </Box>

          <Box className="researchersMainBox pageContainer">
            <Box className="workspaceItemBox CompaniesBox">
              <Box className="AllPSubBox allPFilters">
                <Box
                  onClick={() => previousPageHandler()}
                  className="openBtn"
                  style={{ "margin-botton": "25px" }}
                >
                  <img src={backArrow} />
                  <Typography className="neonBackText">
                    {t("commonTxt.backBtn")}
                  </Typography>
                </Box>
                <Typography
                  className="pageHeaderText"
                  style={{ "margin-botton": "25px" }}
                >
                  {t("commonTxt.filters")}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item sm={4}>
                    <FormLabel className="label">
                      {t("ExistingParticipants.search")}
                    </FormLabel>
                    <SearchBar />
                  </Grid>
                  <Grid item sm={4}>
                    <FormLabel className="label">
                      {t("ExistingParticipants.project")}
                    </FormLabel>
                    <FormControl sx={{ width: "100%" }} size="small">
                      <InputLabel id="demo-select-small-label">
                        {t("ExistingParticipants.projectFilter")}
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={selectFilterbyProject}
                        label={t("ExistingParticipants.projectFilter")}
                        onChange={handleChange1}
                      >
                        <MenuItem value={10}>
                          {t("ExistingParticipants.projectDropOption")}
                        </MenuItem>
                        <MenuItem value={20}>
                          {t("ExistingParticipants.projectDropOption1")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={4}>
                    <FormLabel className="label">
                      {t("ExistingParticipants.role")}
                    </FormLabel>
                    <FormControl sx={{ width: "100%" }} size="small">
                      <InputLabel id="demo-select-small-label">
                        {t("ExistingParticipants.roleFilter")}
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={selectFilterbyRole}
                        label="Filter by Role"
                        onChange={handleChange2}
                      >
                        <MenuItem value={10}>
                          {t("ExistingParticipants.roleDropOption")}
                        </MenuItem>
                        <MenuItem value={20}>
                          {t("ExistingParticipants.roleDropOption1")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <Typography>{t("commonTxt.21Matches")}</Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={6}>
                    <Box
                      className="airadioSwitchBox"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        margin: "0",
                        marginTop: "5px",
                      }}
                    >
                      <AntSwitch />
                      <Typography className="switchText">
                        {t("ExistingParticipants.deactivatedAdmin")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* Tale Starts here */}
              <Box className="AllPSubBox allPList">
                <Box className="allTableNavBox">
                  <Box
                    className="inputAppDrop"
                    onClick={() =>
                      setParticipantStatusDrop(!participantStatusDrop)
                    }
                  >
                    <Typography>
                      {participantStatusDropVal ||
                        t("ExistingParticipants.participantsStatusDropLabel")}
                    </Typography>
                    <img
                      style={{
                        rotate: participantStatusDrop ? "180deg" : "0deg",
                      }}
                      src={dropArrow}
                    />
                    {/* pop */}
                    <Box
                      sx={{
                        height: participantStatusDrop ? "auto" : "0px",
                      }}
                      className="statusPop"
                    >
                      <Box
                        onClick={() => setParticipantStatusDropVal("Active")}
                        className="statusPopItem statusPopItemIcon statusParticipantPopItems"
                      >
                        <Typography>
                          {t("ExistingParticipants.participantDropOptions1")}
                        </Typography>
                      </Box>

                      <Box
                        onClick={() => setParticipantStatusDropVal("Invited")}
                        className="statusPopItem statusParticipantPopItems"
                      >
                        <Typography>
                          {t("ExistingParticipants.participantDropOptions2")}
                        </Typography>
                      </Box>
                      <Box
                        onClick={() => setParticipantStatusDropVal("Added")}
                        className="statusPopItem statusParticipantPopItems"
                      >
                        <Typography>
                          {t("ExistingParticipants.participantDropOptions3")}
                        </Typography>
                      </Box>
                      <Box
                        onClick={() => setParticipantStatusDropVal("Rejected")}
                        className="statusPopItem statusParticipantPopItems"
                      >
                        <Typography>
                          {t("ExistingParticipants.participantDropOptions4")}
                        </Typography>
                      </Box>
                      <Box
                        onClick={() =>
                          setParticipantStatusDropVal("Non Participant")
                        }
                        className="statusPopItem statusParticipantPopItems"
                      >
                        <Typography>
                          {t("ExistingParticipants.participantDropOptions5")}
                        </Typography>
                      </Box>
                      <Box
                        onClick={() =>
                          setParticipantStatusDropVal("Not Assigned")
                        }
                        className="statusPopItem statusParticipantPopItems"
                      >
                        <Typography>
                          {t("ExistingParticipants.participantDropOptions6")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    className="inputAppDrop"
                    onClick={() => setAssignedStatusDrop(!assignedStatusDrop)}
                  >
                    <Typography>
                      {assignedStatusDropVal || "Assigned"}
                    </Typography>
                    <img
                      style={{
                        rotate: assignedStatusDrop ? "180deg" : "0deg",
                      }}
                      src={dropArrow}
                    />
                    {/* pop */}
                    <Box
                      sx={{ height: assignedStatusDrop ? "auto" : "0px" }}
                      className="statusPop"
                    >
                      <Box
                        onClick={() => setAssignedStatusDropVal("Assigned")}
                        className="statusPopItem statusPopItemIcon statusParticipantPopItems"
                      >
                        <Typography>
                          {t("ExistingParticipants.addToProjectLabel")}
                        </Typography>
                      </Box>

                      <Box
                        onClick={() => setAssignedStatusDropVal("Not Assigned")}
                        className="statusPopItem statusParticipantPopItems"
                      >
                        <Typography>
                          {t("ExistingParticipants.remFromProjectLabel")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    className="inputAppDrop"
                    onClick={() => setBulkActionDrop(!bulkActionDrop)}
                  >
                    <Typography>
                      {bulkActionDropVal || "Bulk Action"}
                    </Typography>
                    <img
                      style={{
                        rotate: bulkActionDrop ? "180deg" : "0deg",
                      }}
                      src={dropArrow}
                    />
                    {/* pop */}
                    <Box
                      sx={{ height: bulkActionDrop ? "auto" : "0px" }}
                      className="statusPop"
                    >
                      <Box
                        onClick={() => setBulkActionDropVal("Add-to-Project")}
                        className="statusPopItem statusPopItemIcon statusParticipantPopItems"
                      >
                        {addIconBlack}
                        <Typography>
                          {t("ExistingParticipants.addToProjectLabel")}
                        </Typography>
                      </Box>

                      <Box
                        onClick={() =>
                          setBulkActionDropVal("Remove-to-Project")
                        }
                        className="statusPopItem statusParticipantPopItems"
                      >
                        {removeGroupBlack}
                        <Typography>
                          {t("ExistingParticipants.remFromProjectLabel")}
                        </Typography>
                      </Box>
                      <Box
                        onClick={() =>
                          setBulkActionDropVal("Delete-Participants")
                        }
                        className="statusPopItem statusParticipantPopItems"
                      >
                        {trashBlack}
                        <Typography>
                          {t("ExistingParticipants.deleteParticipants")}
                        </Typography>
                      </Box>
                      <Box
                        onClick={() =>
                          setBulkActionDropVal("Deactivate-Participants")
                        }
                        className="statusPopItem statusParticipantPopItems"
                      >
                        {xCircleBlack}
                        <Typography>
                          {t("ExistingParticipants.deActivateParticipants")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <AppBtn buttonText="Apply" />
                  </Box>
                </Box>

                {/* table */}
                <Box className="workListBox">
                  <Box className="haderRow" minWidth={"1500px"}>
                    <Box
                      minWidth={"290px"}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0px",
                        paddingLeft: "11px",
                      }}
                      className="Tabelsel navFsel allpHTI participantCheckboxColor"
                    >
                      <input
                        className="AllPTablechackBox"
                        type="checkbox"
                        onChange={handleCheckAllChange}
                      />

                      <Typography>{t("commonTxt.uName")}</Typography>
                    </Box>
                    <Box minWidth={"230px"} className="Tabelsel tableItemSel">
                      <Typography>{t("commonTxt.mailAddress")}</Typography>
                    </Box>
                    <Box minWidth={"230px"} className="Tabelsel tableItemSel">
                      <Typography>{t("commonTxt.addUpdated")}</Typography>
                    </Box>
                    <Box minWidth={"230px"} className="Tabelsel tableItemSel">
                      <Typography>{t("commonTxt.lastUpdated")}</Typography>
                    </Box>
                    <Box minWidth={"230px"} className="Tabelsel tableItemSel">
                      <img src={userF} />
                    </Box>
                    <Box minWidth={"230px"} className="Tabelsel tableItemSel">
                      <img src={NIcon} />
                    </Box>
                  </Box>

                  {participantsData &&
                    participantsData.map((el, index) => (
                      <Box className="TabelRow" minWidth={"1500px"}>
                        <Box
                          sx={{ position: "relative" }}
                          className="Tabelsel navFsel participantCheckboxColor"
                          minWidth={"290px"}
                        >
                          <input
                            className="AllPTablechackBox"
                            type="checkbox"
                            checked={checkboxes[index]}
                            onChange={() => handleCheckboxChange(index)}
                          />
                          <Box className="tabelAvatar">
                            <img src={el.img} />
                          </Box>
                          <Typography sx={{ cursor: "pointer" }}>
                            {el.title}
                            <br />
                            <span className="participantsTableText">
                              {el.subTitle}
                            </span>
                          </Typography>
                          <Box
                            sx={{
                              background: el.active ? "#12B76A" : "#F04438",
                            }}
                            className="ActiveStatus"
                          ></Box>
                        </Box>
                        <Box
                          minWidth={"230px"}
                          className="Tabelsel TabelselItemCenter"
                        >
                          <Typography className="participantsTableText">
                            {el.email}
                          </Typography>
                        </Box>
                        <Box
                          className="Tabelsel tableItemSel TabelselItemCenter"
                          minWidth={"230px"}
                        >
                          <Typography className="participantsTableText">
                            {el.update}
                          </Typography>
                        </Box>
                        <Box
                          className="Tabelsel tableItemSel TabelselItemCenter"
                          minWidth={"230px"}
                        >
                          <Typography className="participantsTableText">
                            {el.lastVisit}
                          </Typography>
                        </Box>
                        <Box
                          className="Tabelsel tableItemSel TabelselItemCenter"
                          minWidth={"230px"}
                        >
                          <Typography ml={1} className="participantsTableText">
                            {el.userCoutn}
                          </Typography>
                        </Box>
                        <Box
                          className="Tabelsel tableItemSel TabelselItemCenter"
                          minWidth={"230px"}
                        >
                          <Typography ml={1} className="participantsTableText">
                            {el.nCoutn}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
