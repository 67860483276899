import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";

//components
import { AppBtn } from "../../AppTools/AppButton";

export default function RemovedProjectPop({
    removedProjectPop,
    setRemovedProjectPop,
}) {
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
        setRemovedProjectPop(false);
    }
  };

  useEffect(() => {
    if (removedProjectPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [removedProjectPop]);

  return (
    <>
      <Box
        onClick={handelPopClose}
        id="popBackdrop"
        sx={{ display: removedProjectPop ? "flex" : "none" }}
        className="workspaceDeletePopContainer"
      >
        <Box sx={{width:"480px"}} className="workspaceDeleteBox">
          <Typography className="deletePopHeader">You have been removed from the <strong>Trekkin Project</strong> by Kevin.</Typography>

          <Box className="deletPopBtnBox customPopLoginBtn">
            <AppBtn buttonText="Close" onClick={() => setRemovedProjectPop(false)} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
