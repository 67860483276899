import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

//images
import groupIcon from "../../../Assets/Images/groupsIcon.png";
import filterIcon from "../../../Assets/Images/filterIcon.png"
import AdminBG from "../../../Assets/Images/Add-files-cuate.png";
import exportIcon from "../../../Assets/Images/exportIcon.png";
import autoTham from "../../../Assets/Images/audioTham.png";
import audioWave from "../../../Assets/Images/audioWav.png";
import Play from "../../../Assets/Images/play.png";


//component
import RecapAudioCommentPage from "../RecapAudioCommentPage"



export default function RecapAudio() {
  const [audioCommentPage, setAudioCommetnPage] = useState(false)

  const audioData = true;

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const Navigate = useNavigate();

  const audioCard = ({ img, title, time }) => {
    return (
      <Box className="audioCard" onClick={() => Navigate("/recap/view-audio?r=audios")}>
        <Box className="autioInfo">
          <Box className="audioIcon">
            <img src={autoTham} />
          </Box>
          <Typography>{title}</Typography>
        </Box>
        <Box className="audioWave">
          <img className="play" src={Play} />
          <img className="wave" src={audioWave} />
          <img className="wave" src={audioWave} />
          <Typography>08:16</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {!audioData ?
        <Box className="threadNoDataPage">
          <Box className="adminWorkSBG">
            <img src={AdminBG} />
          </Box>
          <Box className="adminBGTextBox">
            <Typography className="adminSubHeaderText">
            {t("recapExpression.expresssionText")}
            </Typography>
          </Box>
        </Box>
        :

          <Box className="threadContainer workspaceListBox">
                <Box className="threadNavBox">
                  <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                    <Box className="actTNavBtn">
                      <img src={groupIcon} />
                      <Typography>{t("commonTxt.groupBtn")}</Typography>
                    </Box>

                    <Box className="filterBtn">
                      <img src={filterIcon} />
                      <p>{t("commonTxt.filters")}</p>
                    </Box>
                  </Box>

                  <Box className="actTNavBtn">
                    <img src={exportIcon} />
                    <Typography>{t("commonTxt.exportLink")}</Typography>
                  </Box>
                </Box>

                <Box className="audioBox">
                  {audioCard({ title: "Trekking Essentials Review" })}
                  {audioCard({ title: "Hiking mountains Review" })}
                  {audioCard({ title: "Sit amet dolor Review" })}
                  {audioCard({ title: "Lorem sit Review" })}
                  {audioCard({ title: "Sit amet dolor Review" })}
                  {audioCard({ title: "Lorem Ipsum dolor Review" })}
                  {audioCard({ title: "20km Trek Review" })}
                </Box>

          </Box>
      }
    </>
  )
}
