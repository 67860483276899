import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "../../../lib/axios";
import { useTranslation } from "react-i18next";

import "./style.css";
import { AntSwitch } from "../../AppTools/AppTool";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import { Bo } from "react-flags-select";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import searchIcon from "../../../Assets/Images/search.png";
import chackBox from "../../../Assets/Images/Checkbox.png";
import check from "../../../Assets/Images/check.png";
import { useAppSelector } from "../../../lib/store/store";
import { updateConfigSettings } from "../../../Services/adminActions";
import { getConfigSettings } from "../../../Services/master";
import { AppBtn } from "../../AppTools/AppButton";

export default function EmailSetting() {
  const host = "Richard Han";
  const [securityDrop, setSecurityDrop] = useState(false);
  const [securityDropVal, setSecurityDropVal] = useState("");
  const [fileTypeDrop, setFileTypeDrop] = useState(false);
  const [fileTypeDropVal, setFileTypeDropVal] = useState("");
  const [fileFormats, setFileFormats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [attachmentDrop, setAttachmentDrop] = useState(false);
  const authState = useAppSelector((state) => state.auth);
  const [isCallUpdate, setIsCallUpdate] = useState(false);
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const [emailConfig, setEmailConfig] = useState({
    protocol: "smtp",
    hostname: "",
    port: "",
    sender_id: "",
    security_mode: "",
    attech_file_limit: 0,
    attech_file_type: [],
    attech_file_size: "",
    ttl_attech_file_size: "",
    is_auth_required: true,
  });

  useEffect(() => {
    getFileFormats();
    fetchConfig();
  }, []);

  const getFileFormats = async () => {
    try {
      const response = await axios.get(`/master/file-accepted-formats`);
      if (response.success) {
        const formats = response.data?.data;
        setFileFormats(formats);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  const handleProtocolChange = (newProtocol) => {
    setEmailConfig({ ...emailConfig, protocol: newProtocol });
  };

  const setFormData = (e) => {
    let newState = {
      ...emailConfig,
      [e.target.name]: e.target.value,
    };
    setEmailConfig(newState);
  };

  const updateSetting = async () => {
    const payload = {
      name: "email_config",
      value: emailConfig,
      is_visible: true,
    };

    try {
      const companyRes = await updateConfigSettings(
        payload,
        authState.authToken
      );
      setLoading(false);
      if (companyRes.success) {
        toast.success(companyRes.message, TOAST_CONFIG);
        fetchConfig();
      } else {
        toast.error(companyRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  const selectAllCheckData = () => {
    if (emailConfig?.attech_file_type?.length >= fileFormats.length)
      return false;

    let selectedItems = [];
    fileFormats.map((item) => {
      selectedItems.push(item.name);
    });

    setEmailConfig({ ...emailConfig, attech_file_type: selectedItems });
  };

  const deleteAllCheckData = () => {
    if (emailConfig?.attech_file_type?.length == 0) return false;
    setEmailConfig({ ...emailConfig, attech_file_type: [] });
  };

  const getAttachmentDropVal = () => {
    let dropdownLabel = "";
    if (emailConfig.attech_file_type.length <= 2) {
      emailConfig.attech_file_type.map((val, index) => {
        if (index > 0) {
          dropdownLabel += ", ";
        }
        dropdownLabel += val;
      });
    } else {
      dropdownLabel = emailConfig.attech_file_type.length + " Selected";
    }
    return dropdownLabel;
  };

  const pushData = (type) => {
    let fileTypes = [...emailConfig.attech_file_type, type];
    setEmailConfig({ ...emailConfig, attech_file_type: fileTypes });
  };

  const fetchConfig = async () => {
    try {
      const response = await getConfigSettings({ name: "email_config" });
      if (response.success) {
        if (response.data.data.length > 0) {
          const emailSettings = response.data.data[0];
          let sateData = {};
          for (let key in emailConfig) {
            if (emailSettings?.value[key]) {
              sateData[key] = emailSettings?.value[key];
            }
          }
          setEmailConfig(sateData);
          setSecurityDropVal(sateData.security_mode);
        }
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  return (
    <>
      <Box className="EmailSContainer">
        <Typography className="pageHeaderText">
          {t("emailSettings.mailSetting")}
        </Typography>

        <Box className="radioBasicBox ">
          <Typography className="">
            {t("emailSettings.selectProtocol")}
          </Typography>
        </Box>
        <Box className="radioBox">
          <Box>
            <input
              className="emailSettingInput emailSettingRadio"
              type="radio"
              name="protocol"
              onClick={() => handleProtocolChange("smtp")}
              checked={emailConfig?.protocol === "smtp"}
            />
          </Box>
          <Box>
            <Typography className="emailSettingLabel">
              {t("emailSettings.smtp")}
            </Typography>
          </Box>
        </Box>
        <Box className="radioBox">
          <Box>
            <input
              className="emailSettingInput emailSettingRadio"
              type="radio"
              name="protocol"
              onClick={() => handleProtocolChange("pop3")}
              checked={emailConfig?.protocol === "pop3"}
            />
          </Box>
          <Box>
            <Typography className="emailSettingLabel">
              {t("emailSettings.pop3")}
            </Typography>
          </Box>
        </Box>

        <Box className="basicBox lastBasicBox">
          <Box className="inputBox">
            <Typography className="emailSettingLabel">
              {t("emailSettings.hostName")}
            </Typography>
            <input
              className="emailSettingInput"
              placeholder="Enter Host Name"
              value={emailConfig?.hostname}
              name="hostname"
              onChange={setFormData}
            />
          </Box>
          <Box className="inputBox">
            <Typography className="emailSettingLabel">
              {t("emailSettings.port")}
            </Typography>
            <input
              className="emailSettingInput"
              placeholder="Enter Port"
              value={emailConfig?.port}
              name="port"
              onChange={setFormData}
            />
          </Box>
        </Box>

        <Box className="basicBox lastBasicBox">
          <Box className="inputBox">
            <Typography className="emailSettingLabel">
              {t("emailSettings.defaultSenderId")}
            </Typography>
            <input
              className="emailSettingInput"
              placeholder="Enter the Default Sender ID"
              value={emailConfig?.sender_id}
              name="sender_id"
              onChange={setFormData}
            />
          </Box>
          <Box className="inputBox">
            <Typography className="emailSettingLabel">
              {t("emailSettings.securityMode")}
            </Typography>
            <input
              onClick={() => setSecurityDrop(!securityDrop)}
              value={securityDropVal}
              className="emailSettingInput securityInput"
              placeholder="Select the Security Mode"
            />
            <img
              style={{ rotate: securityDrop ? "180deg" : "0deg" }}
              onClick={() => setSecurityDrop(!securityDrop)}
              className="downArrow settingDropDownArrow"
              src={DownArrowIcon}
            />

            <Box
              sx={{ height: securityDrop ? "auto" : "0px" }}
              className="securityDrop"
            >
              <Box
                onClick={() => {
                  setSecurityDropVal("Security1");
                  setSecurityDrop(false);
                  setEmailConfig({
                    ...emailConfig,
                    security_mode: "Security1",
                  });
                }}
                className="securityDropItem"
              >
                <Typography>{t("emailSettings.security1")}</Typography>
              </Box>
              <Box
                onClick={() => {
                  setSecurityDropVal("Security2");
                  setSecurityDrop(false);
                  setEmailConfig({
                    ...emailConfig,
                    security_mode: "Security2",
                  });
                }}
                className="securityDropItem"
              >
                <Typography>{t("emailSettings.security2")}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="basicBox lastBasicBox">
          <Box className="inputBox">
            <Typography className="emailSettingLabel">
              {t("emailSettings.maxAttachments")}
            </Typography>
            <input
              className="emailSettingInput"
              placeholder="Enter max limit"
              value={emailConfig?.attech_file_limit}
              name="attech_file_limit"
              onChange={setFormData}
            />
          </Box>
          {/* <Box className="inputBox">
            <Typography className="emailSettingLabel">
              {t("emailSettings.supportedAttachments")}
            </Typography>
            <input
              onClick={() => setFileTypeDrop(!fileTypeDrop)}
              value={fileTypeDropVal}
              className="emailSettingInput securityInput"
              placeholder="Select the supported file types"
            />
            <img
              style={{ rotate: fileTypeDrop ? "180deg" : "0deg" }}
              onClick={() => setFileTypeDrop(!fileTypeDrop)}
              className="downArrow"
              src={DownArrowIcon}
            />

            <Box
              sx={{ height: fileTypeDrop ? "auto" : "0px" }}
              className="securityDrop"
            >
              {fileFormats && fileFormats.map((el, index) => {
                return (
                  <Box key={index}
                    onClick={() => {
                      setFileTypeDropVal(el.name);
                      setFileTypeDrop(false);
                    }}
                    className="securityDropItem"
                  >
                    <Typography>{el.name}</Typography>
                  </Box>
                )
              })}
            </Box>
          </Box> */}
          {/* Input Drop Down */}
          <Box className="inputBox">
            <Typography className="emailSettingLabel">
              {t("emailSettings.supportedAttachments")}
            </Typography>
            <Box className="searchBar searchInputBox">
              <input
                className="emailSettingInput searchInputIcon"
                placeholder="Choose file types"
                onClick={() => setAttachmentDrop(!attachmentDrop)}
                value={getAttachmentDropVal()} 
              />
              <img className="rightIcon" src={searchIcon} />
            </Box>
            <img
              style={{ rotate: attachmentDrop ? "180deg" : "0deg" }}
              className="downArrowPop"
              src={DownArrowIcon}
              onClick={() => setAttachmentDrop(!attachmentDrop)}
            />

            <Box
              sx={{ height: attachmentDrop ? "282px" : "0px" }}
              className="categoryDrop researcherDrop"
            >
              <Box className="researchDropTopLabel">
                <Typography onClick={selectAllCheckData}>Select All</Typography>
                <Typography onClick={deleteAllCheckData}>
                  Deselect All
                </Typography>
              </Box>

              {fileFormats &&
                fileFormats.map((type) => {
                  return (
                    <Box
                      onClick={() => {
                        if (
                          emailConfig.attech_file_type.some(
                            (el) => el === type.name
                          )
                        ) {
                          setEmailConfig({
                            ...emailConfig,
                            attech_file_type:
                              emailConfig.attech_file_type.filter(
                                (item) => item !== type.name
                              ),
                          });
                        } else {
                          pushData(type.name);
                        }
                      }}
                      className="categoryDropItem"
                    >
                      <Box className="chackBox">
                        <img className="chackBoxImg" src={chackBox} />
                        <img
                          style={{
                            display: emailConfig.attech_file_type.some(
                              (el) => el === type.name
                            )
                              ? "block"
                              : "none",
                          }}
                          className="chack"
                          src={check}
                        />
                      </Box>
                      <Typography>{type.name}</Typography>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Box>

        <Box className="basicBox lastBasicBox">
          <Box className="inputBox">
            <Typography className="emailSettingLabel">
              {t("emailSettings.maxSizeAttach")}
            </Typography>
            <input
              className="emailSettingInput"
              placeholder="Enter max limit"
              value={emailConfig?.attech_file_size}
              name="attech_file_size"
              onChange={setFormData}
            />
          </Box>
          <Box className="inputBox">
            <Typography className="emailSettingLabel">
              {t("emailSettings.maxSizeTotal")}
            </Typography>
            <input
              className="emailSettingInput"
              placeholder="Enter max limit"
              value={emailConfig?.ttl_attech_file_size}
              name="ttl_attech_file_size"
              onChange={setFormData}
            />
          </Box>
        </Box>

        <Box className="airadioSwitchBox">
          <AntSwitch
            defaultChecked
            inputProps={{ "aria-label": "ant design" }}
            onChange={(el) =>
              setEmailConfig({
                ...emailConfig,
                is_auth_required: el.target.checked,
              })
            }
          />
          <Typography className="switchText">
            {t("emailSettings.authenticationRequired")}
          </Typography>
        </Box>
        <Box className="languageBtnBox">
          <AppBtn buttonText={`Update`} onClick={updateSetting} />
        </Box>
      </Box>
    </>
  );
}
