import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import axios from "../../../lib/axios";

import "./style.css";

import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import { API_BASE_URL } from "../../../env";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { getCities, getCountries, getStates } from "../../../Services/master";
import { useAppSelector } from "../../../lib/store/store";
import {
  addResearcher,
  uploadUserDirectoryImage,
} from "../../../Services/adminActions";
import { Navigate } from "react-router";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import plassIcon from "../../../Assets/Images/plassIcon.png";

export default function AddResearcherPop({
  setAddResearcherPop,
  addResearcherPop,
  workspaceResearcher,
  setWorkSpacePop,
  setEditWorkspace,
  editWorkspaceResearcher,
  setcreatedAffiliate,
  workspacePopType,
  setReloadList,
}) {
  let authState = useAppSelector((state) => state.auth);

  const [genderDrop, setGenderDrop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [genderDropVal, setGenderDropVal] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [countryList, setCountryList] = useState(false);
  const [countryListVal, setCountryListVal] = useState("");
  const [stateList, setStateList] = useState(false);
  const [stateListVal, setStateListVal] = useState("");
  const [cityList, setCityList] = useState(false);
  const [cityListVal, setCityListVal] = useState("");
  const [researcherProfileImage, setResearcherProfileImage] = useState(null);
  const [projects, setProjects] = useState([]);
  const [allWorkSpaceListData, setAllWorkSpaceListData] = useState([]);

  setcreatedAffiliate = setcreatedAffiliate || false;

  const addWorkspaceObj = {
    workspaces: [],
  };
  const [addResearchersToWorkspace, setAddResearchersToWorkspace] = useState({
    ...addWorkspaceObj,
  });

  const initResearcherState = {
    name: "",
    email: "",
    password: "",
    phone: "",
    gender: "",
    address_line1: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    status: "active",
  };
  const [createResearcher, setCreateResearcher] = useState({
    ...initResearcherState,
  });

  const handleChange = (event) => {
    const selectedValue = event.target.value === "" ? 0 : event.target.value;
    setAddResearchersToWorkspace((prevGroup) => ({
      ...prevGroup,
      [event.target.name]: selectedValue,
    }));
    setCreateResearcher((prevGroup) => ({
      ...prevGroup,
      [event.target.name]: selectedValue,
    }));
  };

  console.log(
    "The value of addResearchersToWorkspace:",
    addResearchersToWorkspace
  );

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "200px",
        overflowY: "auto",
      },
    },
  };

  const setResearcherFormValue = (e) => {
    let newFormData = {
      ...createResearcher,
      [e.target.name]: e.target.value,
    };
    setCreateResearcher(newFormData);
    if (validationErrors.hasErr) {
      handleValidation(newFormData);
    }
  };

  const handleCountryChange = (e) => {
    setStateListVal("");
    setCityListVal("");
    let newState = { ...createResearcher, country: e._id };
    setCreateResearcher(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
    getAllStatesList(e.id);
  };

  const handleStateChange = (e) => {
    let newState = { ...createResearcher, state: e._id };
    setCityListVal("");
    setCreateResearcher(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
    getAllCitiesList(e.id, e.country_id);
  };

  const handleCityChange = (e) => {
    let newState = { ...createResearcher, city: e._id };
    setCreateResearcher(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setAddResearcherPop(false);
    }
  };

  const handleBack = () => {
    setCreateResearcher({ ...initResearcherState });
    setGenderDropVal("");
    setCountryListVal("");
    setStateListVal("");
    setCityListVal("");
    setValidationErrors(validationObj);
    setAddResearcherPop(false);
    // setReloadList(true);
    // setWorkSpacePop(true);
    if (workspacePopType == "add" && typeof setWorkSpacePop != "undefined") {
      setWorkSpacePop(true);
    } else if (
      workspacePopType == "edit" &&
      typeof setWorkSpacePop != "undefined"
    ) {
      setEditWorkspace(true);
    }
  };

  const handleBackAgain = () => {
    setAddResearcherPop(false);
    setEditWorkspace(true);
  };

  useEffect(() => {
    getAllCountryList();
    getWorkSpaceList();
  }, [addResearcherPop]);

  useEffect(() => {
    if (addResearcherPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [addResearcherPop]);

  const getWorkSpaceList = async () => {
    let res = await axios.get(`/super-admin/admin/workspaces`);
    if (res.status) {
      const workspaces = res.data.data;
      const allOption = { _id: "all", name: "All" };
      const workspaceListWithAll = [allOption, ...workspaces];
      setAllWorkSpaceListData(workspaceListWithAll);
    }
  };

  const getAllCountryList = () => {
    getCountries().then((res) => {
      if (res.success && res.data.data.length > 0) {
        setCountryData(res.data.data);
      }
    });
  };

  const getAllStatesList = (country_id) => {
    getStates(country_id).then((res) => {
      if (res.success && res.data.data.length > 0) {
        setStatesData(res.data.data);
      }
    });
  };

  const getAllCitiesList = (state_id, country_id) => {
    getCities(country_id, state_id).then((res) => {
      if (res.success && res.data.data.length > 0) {
        setCitiesData(res.data.data);
      }
    });
  };

  const validationObj = {
    hasErr: false,
    name: {
      error: false,
      msg: "",
    },
    email: {
      error: false,
      msg: "",
    },
    password: {
      error: false,
      msg: "",
    },
    phone: {
      error: false,
      msg: "",
    },
    gender: {
      error: false,
      msg: "",
    },
    address_line1: {
      error: false,
      msg: "",
    },
    country: {
      error: false,
      msg: "",
    },
    state: {
      error: false,
      msg: "",
    },
    city: {
      error: false,
      msg: "",
    },
    zipcode: {
      error: false,
      msg: "",
    },
  };

  const [validationErrors, setValidationErrors] = useState({
    ...validationObj,
  });

  const handleValidation = (formInput) => {
    formInput = formInput || createResearcher;
    let validationerr = { ...validationObj };

    if (formInput.name == "") {
      validationerr.hasErr = true;
      validationerr.name = {
        error: true,
        msg: "Name is required",
      };
    }

    if (formInput.email == "") {
      validationerr.hasErr = true;
      validationerr.email = {
        error: true,
        msg: "Email is required",
      };
    }

    if (formInput.password == "") {
      validationerr.hasErr = true;
      validationerr.password = {
        error: true,
        msg: "Password is required",
      };
    }

    if (formInput.phone == "") {
      validationerr.hasErr = true;
      validationerr.phone = {
        error: true,
        msg: "Phone is required",
      };
    }

    if (formInput.gender == "") {
      validationerr.hasErr = true;
      validationerr.gender = {
        error: true,
        msg: "Gender is required",
      };
    }

    if (formInput.address_line1 == "") {
      validationerr.hasErr = true;
      validationerr.address_line1 = {
        error: true,
        msg: "Address is required",
      };
    }

    if (formInput.country == "") {
      validationerr.hasErr = true;
      validationerr.country = {
        error: true,
        msg: "Country is required",
      };
    }

    if (formInput.state == "") {
      validationerr.hasErr = true;
      validationerr.state = {
        error: true,
        msg: "State is required",
      };
    }

    if (formInput.city == "") {
      validationerr.hasErr = true;
      validationerr.city = {
        error: true,
        msg: "City is required",
      };
    }

    if (formInput.zipcode == "") {
      validationerr.hasErr = true;
      validationerr.zipcode = {
        error: true,
        msg: "Zipcode is required",
      };
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setResearcherProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileResearcherChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setResearcherProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
      updateImage(file);
    }
  };

  const updateImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      let imageUploadRes = await uploadUserDirectoryImage(
        formData,
        authState.authToken
      );
      if (imageUploadRes.success) {
        setCreateResearcher({
          ...createResearcher,
          profile_pic: imageUploadRes?.data?.upload_id,
        });
        // setReloadList(true);
        toast.success(imageUploadRes.message, TOAST_CONFIG);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  };

  let handleCreateResearchers = async () => {
    if (!handleValidation()) {
      toast.error("Please correct form fields", TOAST_CONFIG);
      return false;
    }

    setLoading(true);
    try {
      let createResearcherRes = await addResearcher(
        createResearcher,
        authState.authToken
      );
      setLoading(false);
      if (createResearcherRes.success) {
        toast.success(createResearcherRes.message, TOAST_CONFIG);
        if (setcreatedAffiliate !== false) {
          setcreatedAffiliate(createResearcherRes.data);
        }
        handleBack();
      } else {
        toast.error(createResearcherRes.message, TOAST_CONFIG);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  console.log("The value of selectedWorkspace:", addResearchersToWorkspace);

  console.log("The value of createReseacher:", createResearcher);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: addResearcherPop ? "flex" : "none" }}
        className="addResearcherPop"
      >
        <Box
          sx={{ display: addResearcherPop ? "flex" : "none" }}
          className="addResearcherContaint"
        >
          <Typography className="addResearcherHeader">
            Add Researcher
          </Typography>
          {/* =================WorkspaceDropDown============== */}

          <FormControl sx={{ width: "100%",marginBottom:"4%" }} size="medium">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={addResearchersToWorkspace?.workspaces}
              onChange={handleChange}
              name="workspaces"
              multiple
              displayEmpty
              MenuProps={MenuProps}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <p>Select Workspaces</p>;
                }
                return selected
                  .map((value) => {
                    const workspace = allWorkSpaceListData.find(
                      (workspace) => workspace._id === value
                    );
                    return workspace ? workspace.name : value;
                  })
                  .join(", ");
              }}
            >
              {allWorkSpaceListData.map((workspace, index) => (
                <MenuItem value={workspace._id} key={index}>
                  {workspace.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* =================WorkspaceDropDown============== */}

          <Typography className="addResearcherLabel">
            Researcher's Full Name
          </Typography>
          <input
            name="name"
            value={createResearcher.name}
            onChange={setResearcherFormValue}
            className="addResearcherInput addResearcherInputName"
            placeholder="Enter the full name of the Researcher"
          />
          <span className="validationerr inputerr">
            {validationErrors.name.msg}
          </span>

          <Box className="imgbox">
            <Typography className="workspaceLabel">
              Researcher Profile image
            </Typography>

            <Box
              className="fileInputBox upload-area"
              onClick={() => {
                document.getElementById("researcherFileInput").click();
              }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {researcherProfileImage ? (
                <img
                  className="fileinputimg"
                  src={researcherProfileImage}
                  alt="Upload"
                />
              ) : (
                <img src={plassIcon} alt="Upload" />
              )}
              <input
                id="researcherFileInput"
                style={{ display: "none" }}
                type="file"
                onChange={handleFileResearcherChange}
              />
            </Box>
            <Typography className="fileInputLabel">
              Click or drag image to this area to upload
            </Typography>
          </Box>

          <Typography className="addResearcherLabel">Email Address</Typography>
          <input
            name="email"
            value={createResearcher.email}
            onChange={setResearcherFormValue}
            className="addResearcherInput addResearcherInputName"
            placeholder="Enter Researcher's valid Email Address"
          />
          <span className="validationerr inputerr">
            {validationErrors.email.msg}
          </span>
          <Typography sx={{marginTop:"4%"}} className="addResearcherLabel">Password</Typography>
          <input
            name="password"
            value={createResearcher.password}
            onChange={setResearcherFormValue}
            className="addResearcherInput addResearcherInputName"
            placeholder="Enter Researcher's Password"
          />
          <span className="validationerr inputerr">
            {validationErrors.password.msg}
          </span>

          <Box className="basicBox lastBasicBox">
            <Box className="inputBox">
              <Typography className="addResearcherLabel">
                Phone Number
              </Typography>
              <input
                name="phone"
                value={createResearcher.phone}
                onChange={setResearcherFormValue}
                className="addResearcherInput"
                placeholder="Enter Researcher's valid Phone Number"
              />
              <span className="validationerr">
                {validationErrors.phone.msg}
              </span>
            </Box>
            <Box className="inputBox">
              <Typography className="addResearcherLabel">Gender</Typography>
              <input
                onClick={() => setGenderDrop(!genderDrop)}
                // value={genderDropVal}
                name="gender"
                value={createResearcher.gender}
                onChange={setResearcherFormValue}
                className="addResearcherInput genderInput"
                placeholder="Select Researcher Gender"
              />
              <img
                style={{ rotate: genderDrop ? "180deg" : "0deg" }}
                onClick={() => setGenderDrop(!genderDrop)}
                className="downArrow"
                src={DownArrowIcon}
              />

              <Box
                sx={{ height: genderDrop ? "auto" : "0px" }}
                className="genderDrop"
              >
                <Box
                  onClick={() => {
                    setCreateResearcher({
                      ...createResearcher,
                      gender: "male",
                    });
                    setGenderDropVal("Male");
                    setGenderDrop(false);
                  }}
                  className="genderDropItem"
                >
                  <Typography>Male</Typography>
                </Box>
                <Box
                  onClick={() => {
                    let newState = {
                      ...createResearcher,
                      gender: "female",
                    };
                    setCreateResearcher(newState);
                    if (validationErrors.hasErr) {
                      handleValidation(newState);
                    }
                    setGenderDropVal("Female");
                    setGenderDrop(false);
                  }}
                  className="genderDropItem"
                >
                  <Typography>Female</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setCreateResearcher({
                      ...createResearcher,
                      gender: "others",
                    });
                    setGenderDropVal("Other");
                    setGenderDrop(false);
                  }}
                  className="genderDropItem"
                >
                  <Typography>Other</Typography>
                </Box>
              </Box>
              <span className="validationerr">
                {validationErrors.gender.msg}
              </span>
            </Box>
          </Box>

          <Typography className="addResearcherLabel">Address</Typography>
          <input
            name="address_line1"
            value={createResearcher.address_line1}
            onChange={setResearcherFormValue}
            className="addResearcherInput addResearcherInputName"
            placeholder="Enter Researcher's valid Address"
          />
          <span className="validationerr inputerr">
            {validationErrors.address_line1.msg}
          </span>
          <Box className="basicBox lastBasicBox">
            <Box className="inputBox">
              <Typography className="addResearcherLabel">Country</Typography>
              <input
                onClick={(e) => setCountryList(!countryList)}
                // value={createCompany.country_name}
                // value={console.log("val", countryListVal)}
                value={countryListVal}
                className="addResearcherInput genderInput"
                placeholder="Choose a Category"
              />
              <img
                style={{ rotate: countryList ? "180deg" : "0deg" }}
                onClick={() => setCountryList(!countryList)}
                className="downArrow"
                src={DownArrowIcon}
              />
              <Box
                sx={{
                  maxHeight: countryList ? "300px" : "0px",
                  overflowY: countryList ? "scroll" : "hidden",
                }}
                className="categoryDropDown"
              >
                {countryData.map((data) => (
                  <Box
                    key={data.id}
                    value={data.id}
                    className="categoryDropDownItem"
                    onClick={() => {
                      handleCountryChange(data);
                      setCountryList(false);
                      setCountryListVal(data.name);
                    }}
                  >
                    <Typography>{data.name}</Typography>
                  </Box>
                ))}
              </Box>
              <span className="validationerr">
                {validationErrors.country.msg}
              </span>
            </Box>
            <Box className="inputBox">
              <Typography className="addResearcherLabel">State</Typography>
              <input
                onClick={() => setStateList(!stateList)}
                // value={createCompany.state_name}
                value={stateListVal}
                className="addResearcherInput genderInput"
                placeholder="Choose a Category"
              />
              <img
                style={{ rotate: stateList ? "180deg" : "0deg" }}
                onClick={() => setStateList(!stateList)}
                className="downArrowPop"
                src={DownArrowIcon}
              />

              <Box
                sx={{
                  maxHeight: stateList ? "300px" : "0px",
                  overflowY: stateList ? "scroll" : "hidden",
                }}
                className="genderDrop"
              >
                {statesData.map((data) => (
                  <Box
                    key={data.id}
                    value={data.id}
                    className="genderDropItem"
                    onClick={() => {
                      handleStateChange(data, data.country_id);
                      setStateList(false);
                      setStateListVal(data.name);
                    }}
                  >
                    <Typography>{data.name}</Typography>
                  </Box>
                ))}
              </Box>
              <span className="validationerr">
                {validationErrors.state.msg}
              </span>
            </Box>
          </Box>
          <Box className="basicBox lastBasicBox">
            <Box className="inputBox">
              <Typography className="addResearcherLabel">City</Typography>
              <input
                onClick={() => setCityList(!cityList)}
                // value={createCompany.city_name}
                value={cityListVal}
                className="addResearcherInput genderInput"
                placeholder="Choose a Category"
              />
              <img
                style={{ rotate: cityList ? "180deg" : "0deg" }}
                onClick={() => setCityList(!cityList)}
                className="downArrow"
                src={DownArrowIcon}
              />

              <Box
                sx={{
                  maxHeight: cityList ? "300px" : "0px",
                  overflowY: cityList ? "scroll" : "hidden",
                }}
                className="genderDrop"
              >
                {citiesData.map((data) => (
                  <Box
                    key={data.id}
                    value={data.id}
                    className="genderDropItem"
                    onClick={() => {
                      handleCityChange(data);
                      setCityList(false);
                      setCityListVal(data.name);
                    }}
                  >
                    <Typography>{data.name}</Typography>
                  </Box>
                ))}
              </Box>
              <span className="validationerr">{validationErrors.city.msg}</span>
            </Box>
            <Box className="inputBox">
              <Typography className="addResearcherLabel">ZIP Code</Typography>
              <input
                className="addResearcherInput"
                name="zipcode"
                value={createResearcher.zipcode}
                onChange={setResearcherFormValue}
                placeholder="Enter Zip code"
              />
              <span className="validationerr">
                {validationErrors.zipcode.msg}
              </span>
            </Box>
          </Box>

          <Box className="addResearcherPupBtnBox">
            <AppBtnOutLine
              buttonText="Cancel"
              onClick={() => setAddResearcherPop(false)}
            />
            {workspaceResearcher ? (
              <AppBtnOutLine buttonText="Back" onClick={() => handleBack()} />
            ) : null}
            {editWorkspaceResearcher ? (
              <AppBtnOutLine
                buttonText="Back"
                onClick={() => handleBackAgain()}
              />
            ) : null}
            <AppBtn
              buttonText="Add"
              onClick={() => handleCreateResearchers()}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
