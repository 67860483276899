import React, { useEffect, useState } from "react";
import "./style.css";
import { Box, Typography } from "@mui/material";
import axios from '../../../lib/axios';

//images
import avatar from "../../../Assets/Images/Avatar2.png";
import DummyUser from "../../../Assets/Images/user-dummy.png";
//component
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { API_BASE_URL } from "../../../env";
import { useAppSelector } from "../../../lib/store/store";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { removeParticipantToProjectAPI } from "../../../Services/adminActions";
import { viewImage } from "../../../lib/helpers";

export default function RemoveProjectParticipantPop({
  setRemoveProjectParticipantPop,
  removeProjectParticipantPop,
  participantList,
  setReloadList,
}) {
  let authState = useAppSelector(state => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace);

  const removeProjectObj = {
    "participant_ids": [],
    "project_ids": [],
  }
  
  const [removeParticipantToProjects, setRemoveParticipantToProjects] = useState({...removeProjectObj});

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: '200px', 
        overflowY: 'auto', 
      },
    },
  };
  
  const [loading, setLoading] = useState(false);

  const [allParticipantData, setAllParticipantData] = useState([]);
  const [projects, setProjects] = useState([]);

  useEffect(()=> {
    getParticipants();
    getProjectLists();
    setRemoveParticipantToProjects({...removeParticipantToProjects, participant_ids: [...participantList]})
  },[participantList])

  const getParticipants = async () => {
    setLoading(true);
    return await axios.get(API_BASE_URL + `/super-admin/admin/participants?workspace=${workspaceState?.selectedWorkspace?._id}`).then((res) => {
      setLoading(false);
      if (res.status) {
        setAllParticipantData(res.data.data);
        }
      })
    }

    const getProjectLists = async() => {
      try {
        const res = await axios.get(API_BASE_URL + '/researchers/projects');
        if (res.status) {
          setProjects(res.data.data);
        }
      } catch (error) {
        console.error('Error fetching project lists', error);
      }
    }

    const matchedParticipants = allParticipantData.filter(participant =>
      participantList.includes(participant._id)
    );

    const handleCheckboxChange = (participantId) => {
      setRemoveParticipantToProjects((prev) => {
        const newParticipantIds = prev.participant_ids.includes(participantId)
          ? prev.participant_ids.filter(id => id !== participantId)
          : [...prev.participant_ids, participantId];
        return { ...prev, participant_ids: newParticipantIds };
      });
    };

  const UserBox = ({ participant }) => {
    let isChecked = removeParticipantToProjects.participant_ids.includes(participant._id);
    return (
      <Box className="userBox">
        <input
          className="custom-checkbox" 
          type="checkBox"
          checked={isChecked}
          onChange={(el) =>{
             handleCheckboxChange(participant._id)
          }}
        />
        <Box className="avatar">
          {/* <img src={avatar} /> */}
          {(participant?.profile_pic == 'undefined' || participant?.profile_pic == null) ? <img src={DummyUser} /> : <img src={viewImage(participant?.profile_pic)} /> }
        </Box>
        <Typography>
          {participant?.name} <br /> <span>{participant?.username}</span>
        </Typography>
      </Box>
    );
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setRemoveProjectParticipantPop(false);
    }
  };

  useEffect(() => {
    if (removeProjectParticipantPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [removeProjectParticipantPop]);

  const handleChange = (event) => {
    const selectedValue = event.target.value === "" ? 0 : event.target.value;
    setRemoveParticipantToProjects((prevGroup) => ({
      ...prevGroup,
      [event.target.name]: selectedValue,
    }));
  };

  let handleRemovePartcipantsToProject = async() => {
    setLoading(true);
    try {
      if (removeParticipantToProjects.participant_ids.length < 1) {
        return toast.error("Selection required: Please choose at least one participant.");
      }
      if (removeParticipantToProjects.project_ids.length < 1) {
        return toast.error("Selection required: Please choose at least one project.");
      }
      let { ...removeProjectData } = removeParticipantToProjects;
      let removeRes = await removeParticipantToProjectAPI(
        removeProjectData,
        authState.authToken,
      )
      if(removeRes.success){
        toast.success(removeRes.message, TOAST_CONFIG);
        setRemoveProjectParticipantPop(false);
        setReloadList(true);
      }else {
        toast.error(removeRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong! Please try again', TOAST_CONFIG);
      setLoading(false);
    }
  }
 
  return (
    <>
      <Box
        id="popBackdrop"
        sx={{ display: removeProjectParticipantPop ? "flex" : "none" }}
        onClick={handelPopClose}
        className="invitePPopbox workspacePop "
      >
        <Box className="workspaceContaint">
          <Typography className="workspaceHeader">
            Remove to Projects
          </Typography>
          <Box className="userMainBox">
            {matchedParticipants.map(participant => (
              <UserBox participant={participant} key={participant._id} />
            ))}
          </Box>
          <Typography className="eTemPopLabel">
            Assign Projects<samp>*</samp>
          </Typography>
          <Box className="ckeckBoxRoom" mb={3} mt={2}>
            <FormControl sx={{ width: "100%" }} size="small">
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={removeParticipantToProjects?.project_ids}
                onChange={handleChange}
                name="project_ids"
                multiple
                displayEmpty
                MenuProps={MenuProps}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <p>Select Projects</p>;
                  }
                  return selected.map(value => {
                    const project = projects.find(project => project._id === value);
                    return project ? project.name : value;
                  }).join(', ');
                }}
              >
                {projects.map((project, index) => (
                  <MenuItem value={project._id} key={index}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine
              onClick={() => {setRemoveProjectParticipantPop(false);
                setRemoveParticipantToProjects((prevGroup) => ({
                  ...prevGroup,
                  project_ids: [],
                }))
              }}
              buttonText="Cancel"
            />
            <AppBtn buttonText="Remove Project" onClick={() => handleRemovePartcipantsToProject()}/>
          </Box>
        </Box>
      </Box>
    </>
  );
}
