import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from "@mui/material";
import "./style.css";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";


//images
import imagearBg from "../../../Assets/Images/imagearBg.png";
import audioWave from "../../../Assets/Images/audioWave.png"
import loveEmoji from "../../../Assets/Images/emoji/love.png"
import confuseEmoji from "../../../Assets/Images/emoji/confuse.png"
import ultiEmoji from "../../../Assets/Images/emoji/ulti.png"
import greenTikEmoji from "../../../Assets/Images/emoji/greenTik.png"
import backArrow from "../../../Assets/Images/noun-back.png";


//component
import SideMenu from "../../../Components/Participants/SideMenu";
import { sideHaumIcon } from "../../../Assets/Data";
import { AppBtn, AppBtnOutLine, PurplelBtn } from '../../../Components/AppTools/AppButton';
import { IBox } from "../../../Components/AppTools/AppTool"
import WaveSurfer from 'wavesurfer.js';
import BubbleChart from "../../../Components/AppTools/BubbleChart"


import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export default function ReviewAudio({ slideMenu, setSlideMenu, workspaceDrop }) {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [audioFile, setAudioFile] = useState(null);
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [selectDrop, setSelectDrop] = useState(null);


  useEffect(() => {
    if (waveformRef.current) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#ddd',
        progressColor: '#ff6347',
        height: 128,
      });
    }

    return () => {
      if (wavesurfer.current) {
        wavesurfer.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (audioFile && wavesurfer.current) {
      wavesurfer.current.load(URL.createObjectURL(audioFile));
    }
  }, [audioFile]);


  const lineBar = 80;
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#4F33D1",
    },
  }));


  return (
    <>
      <Box className="ResearchersContainer imageActivityPage">
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon">
              {sideHaumIcon}
            </Box>
            <Box className="ontheDotNav">
              <PurplelBtn buttonText={t("commonTxt.previouspage")} />
              <AppBtn buttonText={t("commonTxt.nextPage")} />
            </Box>
          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer onthedot">
            <Box className="workspaceListBox">
              <Box mb={3} width="74px" onClick={() => Navigate("/participants/activities")} className="openBtn">
                <img src={backArrow} />
                <Typography className="neonBackText">Back</Typography>
              </Box>
              <Typography className='activeProgText'>{t("commonTxt.activityProgress")}</Typography>
              <Box className="lineBar">
                <Typography>{lineBar}%</Typography>
                <BorderLinearProgress variant="determinate" value={lineBar} />
              </Box>
              <Typography className="lineBarSubText">{t("onTheDro.TaskComp")}</Typography>

              <Box className="otdmainLabelBox">
                <Typography className='ActInSectionMainHeader'>{t("ReviewAudio.pageHeader")}</Typography>
                <Box className="skipBtnBox">
                  <Typography>{t("onTheDro.SaveDraft")}</Typography>
                  <AppBtnOutLine buttonText="Skip Task" />
                </Box>
              </Box>
              <IBox text="Pause the audio and tag the point in time with a comment. Please carefully select the type of tag for your comment" />
              <Typography className='imgUploadHeader'>{t("imageTask.viewBrand")}<span>*</span></Typography>

              <Box mb={4} className="audioBox">
                <Box className="arImgBox">
                  <img src={imagearBg} />
                  <Typography>{t("ReviewAudio.trekkingE")}</Typography>
                </Box>
                <Box className="audioWaveBox">
                  <img src={audioWave} />
                </Box>
              </Box>

              <Box className="emojiWText">
                <Typography className='imgBottominputLabel'>{t("videoTask.SelectTag")}</Typography>
                <Box className="emojiSection">
                  <img src={loveEmoji} />
                  <img src={ultiEmoji} />
                  <img src={confuseEmoji} />
                  <img src={greenTikEmoji} />
                </Box>
              </Box>
              <textarea className='massageInput' value="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown pri" />

              <Box mt={3}>
                <Typography>{t("videoTask.responce2")}</Typography>
                <textarea className='massageInput' placeholder="Enter your response here" />
              </Box>

              <Box className="lineDropBox">
                <Typography className='lineDLabel'>{t("imageTask.associatedBrand")}</Typography>

                <Box className="lineDrop">
                  <Box className="LDrop" onClick={() => setSelectDrop(0)}>
                    <Box className="lDropSelectBox" sx={{ display: selectDrop === 0 ? "block" : "none" }}></Box>
                    <Typography>{t("imageTask.VeryPoor")}</Typography>
                  </Box>
                  <Box className="LLine"></Box>
                  <Box className="LDrop" onClick={() => setSelectDrop(1)}>
                    <Box className="lDropSelectBox" sx={{ display: selectDrop === 1 ? "block" : "none" }}></Box>
                    <Typography>{t("imageTask.Poor")}</Typography>
                  </Box>
                  <Box className="LLine"></Box>
                  <Box className="LDrop" onClick={() => setSelectDrop(2)}>
                    <Box className="lDropSelectBox" sx={{ display: selectDrop === 2 ? "block" : "none" }}></Box>
                    <Typography>{t("imageTask.Okay")}</Typography>
                  </Box>
                  <Box className="LLine"></Box>
                  <Box className="LDrop" onClick={() => setSelectDrop(3)}>
                    <Box className="lDropSelectBox" sx={{ display: selectDrop === 3 ? "block" : "none" }}></Box>
                    <Typography>{t("imageTask.Good")}</Typography>
                  </Box>
                </Box>
              </Box>

              <Box my={4} sx={{ display: "flex", justifyContent: "end" }} >
                <PurplelBtn buttonText="Submit" />
              </Box>

              <Box mb={2} className="tabelNav">
                <Typography className="pageTitle">{t("viewRecapImage.sentimentsDistributionHead")}</Typography>
                <Typography sx={{ color: "#4F33D1", textDecoration: "underline" }}>{t("ReviewAudio.ViewResponses")}</Typography>
              </Box>
              <Box className="bubbleChartBox">
                <BubbleChart />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

    </>
  )
}
