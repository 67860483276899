import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import "./style.css";

import { AppBtn, AppBtnOutLine } from "../../../Components/AppTools/AppButton";

//Images
import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png"
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png"
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png"
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png"
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png"
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png"
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png"
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png"
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png"
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png"
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png"

import { useAppSelector } from '../../../lib/store/store';
import { renderUserLayout } from "../../../lib/helpers";

import { toast } from "react-toastify";
import axios from "../../../lib/axios";
import { TOAST_CONFIG } from "../../../lib/constants";
import { API_BASE_URL } from "../../../env";

export default function SiteNotice({ }) {
  const authState = useAppSelector((state) => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace?.selectedWorkspace?._id);
  const [loading, setLoading] = useState(false);
  const email = "This site will be open to participants on {participant start date}. Please come back at that time or contact {support contact} at {support email} if you require immediate assistance."
  const email2 = "Sorry, this site is no longer open to participants. Please {support contact} at {support email} if you require assistance."
  const email3 = ""
  const email4 = "Thank you for completing our questions. We unfortunately cannot accept all applicants into the project at this time but we may contact you again as we seek more participants for this project or a project in the near future. We appreciate your time and interest."
  const email5 = "Sorry, this site has temporarily reached its capacity. Please contact {support contact} at {support email} if you require assistance."
  const [emailval, setEmailval] = useState()
  const [emailval2, setEmailval2] = useState()
  const [emailval3, setEmailval3] = useState()
  const [emailval4, setEmailval4] = useState()
  const [emailval5, setEmailval5] = useState()
  const [signatureTextLaOut, setSignatureTextLaOut] = useState("start")

  const [signatureTextItalic, setSignatureTextItalic] = useState(false)
  const [signatureTextBold, setSignatureTextBold] = useState(false)
  const [signatureTextUnderLine, setSignatureTextUnderLine] = useState()

  const [signatureTextItalic2, setSignatureTextItalic2] = useState(false)
  const [signatureTextBold2, setSignatureTextBold2] = useState(false)
  const [signatureTextUnderLine2, setSignatureTextUnderLine2] = useState()

  const [signatureTextItalic3, setSignatureTextItalic3] = useState(false)
  const [signatureTextBold3, setSignatureTextBold3] = useState(false)
  const [signatureTextUnderLine3, setSignatureTextUnderLine3] = useState()

  const [signatureTextItalic4, setSignatureTextItalic4] = useState(false)
  const [signatureTextBold4, setSignatureTextBold4] = useState(false)
  const [signatureTextUnderLine4, setSignatureTextUnderLine4] = useState()

  const [signatureTextItalic5, setSignatureTextItalic5] = useState(false)
  const [signatureTextBold5, setSignatureTextBold5] = useState(false)
  const [signatureTextUnderLine5, setSignatureTextUnderLine5] = useState()
  const [beforeSiteNotice, setBeforeSiteNotice] = useState(false);
  const [afterSiteClosed, setAfterSiteClosed] = useState(false);
  const [introAfterRegistration, setIntroAfterRegistration] = useState(false);
  const [noticeDisqualification, setNoticeDisqualification] = useState(false);
  const [noticeOverCapacity, setNoticeOverCapacity] = useState(false);

  const initializeNoticeObj = {
    workspace_id: workspaceState,
    notice_before_open: "",
    notice_after_close: "",
    intro_above_registration: "",
    notice_disqualified: "",
    notice_over_capacity: ""
  }

  const [siteNoticeDetail, setSiteNoticeDetail] = useState({ ...initializeNoticeObj });

  useEffect(() => {
    fetchSiteNoticeDetail();
  }, []);

  const fetchSiteNoticeDetail = async () => {
    try {
      const response = await axios.get(`/researchers/workspace-settings?workspace_id=${workspaceState}`);
      if (response.success) {
        let initSiteNotice = { ...initializeNoticeObj };
        const siteNoticeInfo = response.data.data[0];
        //console.log('Get Site Notice Detail=>',siteNoticeInfo);
        let key;
        for (key in initSiteNotice) {
          initSiteNotice[key] = siteNoticeInfo[key];
        }
        setSiteNoticeDetail(initSiteNotice);
        console.log('Get Site Notice Detail=>',initSiteNotice);
        //setSiteNoticeDetail(siteNoticeInfo);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  }

  const setFormData = (e) => {
    const { name, value } = e.target;
    setSiteNoticeDetail((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
    //console.log("postData=>",siteNoticeDetail)
  };

  const handleBeforeOpen = (e) => {
    const { value } = e.target;
    setSiteNoticeDetail({ ...siteNoticeDetail, notice_before_open: value });
  };

  const handleAfterClose = (e) => {
    const { value } = e.target;
    setSiteNoticeDetail({ ...siteNoticeDetail, notice_after_close: value });
  };

  const handleAboveRegistration = (e) => {
    const { value } = e.target;
    setSiteNoticeDetail({ ...siteNoticeDetail, intro_above_registration: value });
  };

  const handleDisqualification = (e) => {
    const { value } = e.target;
    setSiteNoticeDetail({ ...siteNoticeDetail, notice_disqualified: value });
  };

  const handleOverCapacity = (e) => {
    const { value } = e.target;
    setSiteNoticeDetail({ ...siteNoticeDetail, notice_over_capacity: value });
  };

  let updateSiteNotice = async () => {
    
    setLoading(true);
    setSiteNoticeDetail({ ...siteNoticeDetail, workspace_id: workspaceState });
    console.log("Notification Email=>",siteNoticeDetail);
    try {
      let response = await axios.post(
        API_BASE_URL + `/researchers/workspace-settings/save`,
        siteNoticeDetail,
        authState.authToken
      );
      if(response.success){
        fetchSiteNoticeDetail();
        toast.success(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      let message = "Something went wrong, please try again!";
      let status_code = 500;
      if (error.response) {
        message = error.response.data.message;
        status_code = error.response.status;
      }
      return {
        success: false,
        message: message,
        status_code: status_code,
      };
    }
  };


  const handelTextlineChekc = (p, input) => {
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine === "underline") {
          setSignatureTextUnderLine(null)
          return
        } else {
          setSignatureTextUnderLine("underline")
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine === "line-through") {
          setSignatureTextUnderLine(null)
          return
        } else {
          setSignatureTextUnderLine("line-through")
        }
      }
    }
  }

  const handelTextlineChekc2 = (p, input) => {
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine2 === "underline") {
          setSignatureTextUnderLine2(null)
          return
        } else {
          setSignatureTextUnderLine2("underline")
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine2 === "line-through") {
          setSignatureTextUnderLine2(null)
          return
        } else {
          setSignatureTextUnderLine2("line-through")
        }
      }
    }
  }

  const handelTextlineChekc3 = (p, input) => {
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine3 === "underline") {
          setSignatureTextUnderLine3(null)
          return
        } else {
          setSignatureTextUnderLine3("underline")
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine3 === "line-through") {
          setSignatureTextUnderLine3(null)
          return
        } else {
          setSignatureTextUnderLine3("line-through")
        }
      }
    }
  }

  const handelTextlineChekc4 = (p, input) => {
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine4 === "underline") {
          setSignatureTextUnderLine4(null)
          return
        } else {
          setSignatureTextUnderLine4("underline")
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine4 === "line-through") {
          setSignatureTextUnderLine4(null)
          return
        } else {
          setSignatureTextUnderLine4("line-through")
        }
      }
    }
  }

  const handelTextlineChekc5 = (p, input) => {
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine5 === "underline") {
          setSignatureTextUnderLine5(null)
          return
        } else {
          setSignatureTextUnderLine5("underline")
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine5 === "line-through") {
          setSignatureTextUnderLine5(null)
          return
        } else {
          setSignatureTextUnderLine5("line-through")
        }
      }
    }
  }

  const handelChange = (e, i) => {
    const { name, value } = e.target;
    if (i === 1) {
      setEmailval((prev) => ({
        ...prev,
        [name]: value
      }));
    } else if (i === 2) {
      setEmailval2((prev) => ({
        ...prev,
        [name]: value
      }));
    } else if (i === 3) {
      setEmailval3((prev) => ({
        ...prev,
        [name]: value
      }));
    } else if (i === 4) {
      setEmailval4((prev) => ({
        ...prev,
        [name]: value
      }));
    } else if (i === 5) {
      setEmailval5((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const getChildren = () => {
    return <Box className="researchersMainBox pageContainer">
      <Box className="EmailSContainer">
        <Typography className='bsTitle'>Site Notices</Typography>
        <Box>
          <Typography className='siteNoticeLabel'> Notice displayed before site is open to participants</Typography>
          <Typography className='bsNText siteNoticeSubLabel'>(Before Participant Access has started)</Typography>
          <Box className="agrrementPopLabel">
            <Typography className="eTemPopLabel siteNoticeEditLabel">
            </Typography>
            <Typography className="siteNoticeEditLink" onClick={() => setBeforeSiteNotice(true)}>
              Edit
            </Typography>
          </Box>
          <Box className="emailInputBox">
            <Box className="emailInputBoxNav">
              <Box onClick={() => setSignatureTextItalic(!signatureTextItalic)} className="emailImgBox">
                <img src={IIcon} />
              </Box>
              <Box onClick={() => setSignatureTextBold(!signatureTextBold)} className="emailImgBox">
                <img src={BIcon} />
              </Box>
              <Box onClick={() => handelTextlineChekc("underline", "signature")} className="emailImgBox">
                <img src={UnderLineU} />
              </Box>
              <Box onClick={() => handelTextlineChekc("line-through", "signature")} className="emailImgBox">
                <img src={MLineU} />
              </Box>
              <Box className="emailImgBox">
                <img src={AtachMentIcon} />
              </Box>
              <Box className="emailImgBox">
                <img src={Qbox} />
              </Box>
              <Box onClick={() => setSignatureTextLaOut("start")} className="emailImgBox">
                <img src={Line1} />
              </Box>
              <Box onClick={() => setSignatureTextLaOut("center")} className="emailImgBox">
                <img src={Line2} />
              </Box>
              <Box onClick={() => setSignatureTextLaOut("end")} className="emailImgBox">
                <img src={Line3} />
              </Box>
              <Box className="emailImgBox">
                <img src={Line4} />
              </Box>
              <Box className="emailImgBox">
                <img src={emoji} />
              </Box>

            </Box>
            <textarea
              style={{
                textAlign: signatureTextLaOut,
                fontStyle: signatureTextItalic ? "italic" : "normal",
                fontWeight: signatureTextBold ? "bold" : "normal",
                textDecoration: signatureTextUnderLine
              }}
              readOnly={beforeSiteNotice ? false : true}
              className='emailInput' name='notice_before_open'
              value={siteNoticeDetail?.notice_before_open}
              onChange={(e) => { handelChange(e, 1);handleBeforeOpen(e)}}
            />
          </Box>
        </Box>

        <Box>
          <Typography className='siteNoticeLabel'> Notice displayed after site is closed to participants</Typography>
          <Typography className='bsNText siteNoticeSubLabel'>(Once Participant Access has ended)</Typography>
          <Box className="agrrementPopLabel">
            <Typography className="eTemPopLabel siteNoticeEditLabel">
            </Typography>
            <Typography className="siteNoticeEditLink" onClick={() => setAfterSiteClosed(true)}>
              Edit
            </Typography>
          </Box>
          <Box className="emailInputBox">
            <Box className="emailInputBoxNav">
              <Box onClick={() => setSignatureTextItalic2(!signatureTextItalic2)} className="emailImgBox">
                <img src={IIcon} />
              </Box>
              <Box onClick={() => setSignatureTextBold2(!signatureTextBold2)} className="emailImgBox">
                <img src={BIcon} />
              </Box>
              <Box onClick={() => handelTextlineChekc2("underline", "signature")} className="emailImgBox">
                <img src={UnderLineU} />
              </Box>
              <Box onClick={() => handelTextlineChekc2("line-through", "signature")} className="emailImgBox">
                <img src={MLineU} />
              </Box>
              <Box className="emailImgBox">
                <img src={AtachMentIcon} />
              </Box>
              <Box className="emailImgBox">
                <img src={Qbox} />
              </Box>
              <Box onClick={() => setSignatureTextLaOut("start")} className="emailImgBox">
                <img src={Line1} />
              </Box>
              <Box onClick={() => setSignatureTextLaOut("center")} className="emailImgBox">
                <img src={Line2} />
              </Box>
              <Box onClick={() => setSignatureTextLaOut("end")} className="emailImgBox">
                <img src={Line3} />
              </Box>
              <Box className="emailImgBox">
                <img src={Line4} />
              </Box>
              <Box className="emailImgBox">
                <img src={emoji} />
              </Box>

            </Box>
            <textarea
              style={{
                textAlign: signatureTextLaOut,
                fontStyle: signatureTextItalic2 ? "italic" : "normal",
                fontWeight: signatureTextBold2 ? "bold" : "normal",
                textDecoration: signatureTextUnderLine2
              }}
              readOnly={afterSiteClosed ? false : true}
              className='emailInput' name='notice_after_close'
              value={siteNoticeDetail?.notice_after_close}
              onChange={(e) => { handelChange(e, 2);handleAfterClose(e)}}
            />
          </Box>
        </Box>

        <Box>
          <Typography className='siteNoticeLabel'>Introduction shown above the Registration form</Typography>
          <Typography className='bsNText siteNoticeSubLabel'>Introduction shown above the Registration form.</Typography>
          <Box className="agrrementPopLabel">
            <Typography className="eTemPopLabel siteNoticeEditLabel">
            </Typography>
            <Typography className="siteNoticeEditLink" onClick={() => setIntroAfterRegistration(true)}>
              Edit
            </Typography>
          </Box>
          <Box className="emailInputBox">
            <Box className="emailInputBoxNav">
              <Box onClick={() => setSignatureTextItalic3(!signatureTextItalic3)} className="emailImgBox">
                <img src={IIcon} />
              </Box>
              <Box onClick={() => setSignatureTextBold3(!signatureTextBold3)} className="emailImgBox">
                <img src={BIcon} />
              </Box>
              <Box onClick={() => handelTextlineChekc3("underline", "signature")} className="emailImgBox">
                <img src={UnderLineU} />
              </Box>
              <Box onClick={() => handelTextlineChekc3("line-through", "signature")} className="emailImgBox">
                <img src={MLineU} />
              </Box>
              <Box className="emailImgBox">
                <img src={AtachMentIcon} />
              </Box>
              <Box className="emailImgBox">
                <img src={Qbox} />
              </Box>
              <Box onClick={() => setSignatureTextLaOut("start")} className="emailImgBox">
                <img src={Line1} />
              </Box>
              <Box onClick={() => setSignatureTextLaOut("center")} className="emailImgBox">
                <img src={Line2} />
              </Box>
              <Box onClick={() => setSignatureTextLaOut("end")} className="emailImgBox">
                <img src={Line3} />
              </Box>
              <Box className="emailImgBox">
                <img src={Line4} />
              </Box>
              <Box className="emailImgBox">
                <img src={emoji} />
              </Box>

            </Box>
            <textarea
              style={{
                textAlign: signatureTextLaOut,
                fontStyle: signatureTextItalic3 ? "italic" : "normal",
                fontWeight: signatureTextBold3 ? "bold" : "normal",
                textDecoration: signatureTextUnderLine3
              }}
              readOnly={introAfterRegistration ? false : true}
              className='emailInput' name='intro_above_registration'
              value={siteNoticeDetail?.intro_above_registration}
              onChange={(e) => { handelChange(e, 3);handleAboveRegistration(e)}}
            />
          </Box>
        </Box>

        <Box>
          <Typography className='siteNoticeLabel'>Notice to applicants <samp className="siteNoticeRedLabel">disqualified</samp> due to choices made on the Registration form</Typography>
          <Typography className='bsNText siteNoticeSubLabel'>(Only applicable if profile fields with disqualifying are shown on the Registration Form</Typography>
          <Box className="agrrementPopLabel">
            <Typography className="eTemPopLabel siteNoticeEditLabel">
            </Typography>
            <Typography className="siteNoticeEditLink" onClick={() => setNoticeDisqualification(true)}>
              Edit
            </Typography>
          </Box>
          <Box className="emailInputBox">
            <Box className="emailInputBoxNav">
              <Box onClick={() => setSignatureTextItalic4(!signatureTextItalic4)} className="emailImgBox">
                <img src={IIcon} />
              </Box>
              <Box onClick={() => setSignatureTextBold4(!signatureTextBold4)} className="emailImgBox">
                <img src={BIcon} />
              </Box>
              <Box onClick={() => handelTextlineChekc4("underline", "signature")} className="emailImgBox">
                <img src={UnderLineU} />
              </Box>
              <Box onClick={() => handelTextlineChekc4("line-through", "signature")} className="emailImgBox">
                <img src={MLineU} />
              </Box>
              <Box className="emailImgBox">
                <img src={AtachMentIcon} />
              </Box>
              <Box className="emailImgBox">
                <img src={Qbox} />
              </Box>
              <Box onClick={() => setSignatureTextLaOut("start")} className="emailImgBox">
                <img src={Line1} />
              </Box>
              <Box onClick={() => setSignatureTextLaOut("center")} className="emailImgBox">
                <img src={Line2} />
              </Box>
              <Box onClick={() => setSignatureTextLaOut("end")} className="emailImgBox">
                <img src={Line3} />
              </Box>
              <Box className="emailImgBox">
                <img src={Line4} />
              </Box>
              <Box className="emailImgBox">
                <img src={emoji} />
              </Box>

            </Box>
            <textarea
              style={{
                textAlign: signatureTextLaOut,
                fontStyle: signatureTextItalic4 ? "italic" : "normal",
                fontWeight: signatureTextBold4 ? "bold" : "normal",
                textDecoration: signatureTextUnderLine4
              }}
              readOnly={noticeDisqualification ? false : true}
              className='emailInput' name='notice_disqualified'
              value={siteNoticeDetail?.notice_disqualified}
              onChange={(e) => { handelChange(e, 4);handleDisqualification(e)}}
            />
          </Box>
        </Box>

        <Box>
          <Typography className='siteNoticeLabel'>Notice to applicants when site is <samp className="siteNoticeRedLabel">over capacity</samp></Typography>
          <Typography className='bsNText siteNoticeSubLabel'>Notice to applicants when site is over capacity (if the number of Active Participants has been)</Typography>
          <Box className="agrrementPopLabel">
            <Typography className="eTemPopLabel siteNoticeEditLabel">
            </Typography>
            <Typography className="siteNoticeEditLink" onClick={() => setNoticeOverCapacity(true)}>
              Edit
            </Typography>
          </Box>
          <Box className="emailInputBox">
            <Box className="emailInputBoxNav">
              <Box onClick={() => setSignatureTextItalic5(!signatureTextItalic5)} className="emailImgBox">
                <img src={IIcon} />
              </Box>
              <Box onClick={() => setSignatureTextBold5(!signatureTextBold5)} className="emailImgBox">
                <img src={BIcon} />
              </Box>
              <Box onClick={() => handelTextlineChekc5("underline", "signature")} className="emailImgBox">
                <img src={UnderLineU} />
              </Box>
              <Box onClick={() => handelTextlineChekc5("line-through", "signature")} className="emailImgBox">
                <img src={MLineU} />
              </Box>
              <Box className="emailImgBox">
                <img src={AtachMentIcon} />
              </Box>
              <Box className="emailImgBox">
                <img src={Qbox} />
              </Box>
              <Box onClick={() => setSignatureTextLaOut("start")} className="emailImgBox">
                <img src={Line1} />
              </Box>
              <Box onClick={() => setSignatureTextLaOut("center")} className="emailImgBox">
                <img src={Line2} />
              </Box>
              <Box onClick={() => setSignatureTextLaOut("end")} className="emailImgBox">
                <img src={Line3} />
              </Box>
              <Box className="emailImgBox">
                <img src={Line4} />
              </Box>
              <Box className="emailImgBox">
                <img src={emoji} />
              </Box>

            </Box>
            <textarea
              style={{
                textAlign: signatureTextLaOut,
                fontStyle: signatureTextItalic5 ? "italic" : "normal",
                fontWeight: signatureTextBold5 ? "bold" : "normal",
                textDecoration: signatureTextUnderLine5
              }}
              readOnly={noticeOverCapacity ? false : true}
              className='emailInput' name='notice_over_capacity'
              value={siteNoticeDetail?.notice_over_capacity}
              onChange={(e) => { handelChange(e, 5);handleOverCapacity(e)}}
            />
          </Box>
        </Box>

        <Box className="languageBtnBox">
          <AppBtnOutLine buttonText="Cancel" />
          <AppBtn buttonText="Save" onClick={() => updateSiteNotice()} />
        </Box>

      </Box>
    </Box>
  }
  
  return renderUserLayout(authState?.userType, null, getChildren(), 'workadmin');
}
