import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DirectoryAdmin from "../../../../Components/Admin/DirectoryAdmin";
import {
  UserDirectoryCollaborators,
  UserDirectoryObservers,
  UserDirectoryParticipants,
  UserDirectoryResearchers,
} from "../../../../Components/Admin/DirectoryUsers";
import AdminLayout from "../../partials/AdminLayout";
import { AppBtn } from "../../../../Components/AppTools/AppButton";
import { appendQueryParams } from "../../../../lib/helpers";
import { useSearchParams } from "react-router-dom";

const Users = () => {
  const [addResearcherPop, setAddResearcherPop] = useState(false);
  const [addObserverPop, setAddObserverPop] = useState(false);
  const [addParticipantPop, setAddParticipantPop] = useState(false);
  const [addCollaboratorPop, setAddCollaboratorPop] = useState(false);
  const [usersTabe, setUsersTabe] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let selTab = searchParams.get("t");
    if (selTab != null) {
      setUsersTabe(parseInt(selTab));
    }
  }, []);

  let actionBtn = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Box className="workListNav workspaceSettingNav alluserslist">
        <Box className="workListNavItem workListheader">
          <Box
            onClick={() => {
              setSearchParams({ t: 0 });
              setUsersTabe(0);
            }}
            className={
              usersTabe === 0
                ? "workSetNavTabe workSetNavTabeActive"
                : "workSetNavTabe"
            }
          >
            <Typography>Researchers</Typography>
          </Box>
          <Box
            onClick={() => {
              setSearchParams({ t: 1 });
              setUsersTabe(1);
            }}
            className={
              usersTabe === 1
                ? "workSetNavTabe workSetNavTabeActive"
                : "workSetNavTabe"
            }
          >
            <Typography>Collaborators</Typography>
          </Box>
          <Box
            onClick={() => {
              setSearchParams({ t: 2 });
              setUsersTabe(2);
            }}
            className={
              usersTabe === 2
                ? "workSetNavTabe workSetNavTabeActive"
                : "workSetNavTabe"
            }
          >
            <Typography>Observer</Typography>
          </Box>
          <Box
            onClick={() => {
              setSearchParams({ t: 3 });
              setUsersTabe(3);
            }}
            className={
              usersTabe === 3
                ? "workSetNavTabe workSetNavTabeActive"
                : "workSetNavTabe"
            }
          >
            <Typography>Participaints</Typography>
          </Box>
        </Box>
      </Box>
      <Box width={"200px"}>
        {usersTabe === 0 ? (
          <AppBtn
            onClick={() => {
              setAddResearcherPop(true);
            }}
            buttonText="Add Researcher"
          />
        ) : usersTabe === 1 ? (
          <AppBtn
            onClick={() => setAddCollaboratorPop(true)}
            buttonText="Add Collaborator"
          />
        ) : usersTabe === 2 ? (
          <AppBtn
            onClick={() => setAddObserverPop(true)}
            buttonText="Add Observer"
          />
        ) : usersTabe === 3 ? (
          <AppBtn
            onClick={() => setAddParticipantPop(true)}
            buttonText="Add Participant"
          />
        ) : null}
      </Box>
    </Box>
  );

  return (
    <AdminLayout
      childComponent={
        <Box sx={{ display: "flex" }} className="adminMainBox">
          {usersTabe === 0 ? (
            <UserDirectoryResearchers
              setAddResearcherPop={setAddResearcherPop}
              addResearcherPop={addResearcherPop}
            />
          ) : usersTabe === 1 ? (
            <UserDirectoryCollaborators
              setAddCollaboratorPop={setAddCollaboratorPop}
              addCollaboratorPop={addCollaboratorPop}
            />
          ) : usersTabe === 2 ? (
            <UserDirectoryObservers
              setAddObserverPop={setAddObserverPop}
              addObserverPop={addObserverPop}
            />
          ) : usersTabe === 3 ? (
            <UserDirectoryParticipants
              setAddParticipantPop={setAddParticipantPop}
              addParticipantPop={addParticipantPop}
            />
          ) : null}
        </Box>
      }
      actionBtn={actionBtn}
    />
  );
};

export default Users;
