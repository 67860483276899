import React, { useState } from "react";
import { Box } from "@mui/material";

import { Workspace } from "../../../Assets/Data";
import { AppBtn } from "../../../Components/AppTools/AppButton";
import NoWorkspaceBG from "../../../Components/Admin/NoWorkspaceBG";
import AdminAiIntegration from "../../../Components/Admin/AdminAiIntegration";

export default function Ai_Integration() {
  const [workspaceItem, setWorkspaceItem] = useState();

  return (
    <Box sx={{ display: "flex" }} className="adminMainBox">
      {Workspace.length <= 0 ? (
        <NoWorkspaceBG />
      ) : (
        <AdminAiIntegration setWorkspaceItem={setWorkspaceItem} />
      )}
    </Box>
  );
}
