import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import axios from '../../../lib/axios';

//images

export default function DeleteCompanyPop({
  deleteCompanyPop,
  setDeleteCompanyPop,
  deleteCompaniesListData,
  setReloadList
}) {
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setDeleteCompanyPop(false);
    }
  };

  const handelDeletePop = () => {
    setDeleteCompanyPop(false);
      return new Promise((resolve, reject) => {
          axios.delete(`/super-admin/admin/companies/delete/${deleteCompaniesListData?._id}`).then((res) => {
              if (res.success) {
                  resolve({
                      success: true,
                      message: res.message
                  });
                  toast.success(res.message, TOAST_CONFIG);
                  setReloadList(true);
              } else {
                  reject({
                      success: false,
                      message: res.message
                  });
                  toast.success(res.message, TOAST_CONFIG);
              }
          }).catch((err) => {
              reject({
                  success: false,
                  message: err.message
              });
          })
      });
  };

  useEffect(() => {
    if (deleteCompanyPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [deleteCompanyPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: deleteCompanyPop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople"
      >
        <Box
          sx={{ display: deleteCompanyPop ? "flex" : "none" }}
          className="workspaceContaint deletePagePop"
        >
          <Typography className="companyHeader">Delete Company</Typography>

          <Box className="basicBoxItem">
            <Typography className="addPageLabel">
              Are you sure you want to delete "{deleteCompaniesListData?.name}" from Terapage?
            </Typography>
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine
              buttonText="Cancel"
              onClick={() => setDeleteCompanyPop(false)}
            />
            <AppBtn buttonText="Delete"  onClick={handelDeletePop}/>
          </Box>
        </Box>
      </Box>
    </>
  );
}
