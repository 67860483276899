import React, { useEffect, useState } from "react";
import "./style.css";
import { Box, Typography } from "@mui/material";
import axios from "../../../lib/axios";
import { useTranslation } from "react-i18next";

//images
import avatar from "../../../Assets/Images/Avatar2.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";

import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";

//component
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { API_BASE_URL } from "../../../env";
import { useAppSelector } from "../../../lib/store/store";
import { DropDownLists } from "../../AppTools/AppTool";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { addInviteAPI } from "../../../Services/adminActions";
import { Navigate } from "react-router";
import { viewImage } from "../../../lib/helpers";
import DummyUser from "../../../Assets/Images/user-dummy.png";

export default function InviteProjectPop({
  setInvitePop,
  invitePop,
  participantList,
  setReloadList,
}) {

  let authState = useAppSelector((state) => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: '200px',
        overflowY: 'auto',
      },
    },
  };

  const inviteProjectObj = {
    participant_ids: [],
    project_ids: [],
    subject: "",
    message: "",
  };
  const [addInviteParticipant, setAddInviteParticipant] = useState({
    ...inviteProjectObj,
  });
  const [AssignProjectsDrop, setAssignProjectsDrop] = useState(false);
  const [AssignProjectsDropVal, setAssignProjectsDropVal] = useState();

  const [subjectDrop, setSubjectDrop] = useState(false);
  const [subjectDropVal, setSubjectDropVal] = useState();
  const [emailval, setEmailval] = useState();
  const [signatureTextLaOut, setSignatureTextLaOut] = useState("start");
  const [signatureTextItalic, setSignatureTextItalic] = useState(false);
  const [signatureTextBold, setSignatureTextBold] = useState(false);
  const [loading, setLoading] = useState(false);
  const [signatureTextUnderLine, setSignatureTextUnderLine] = useState();

  const [allParticipantData, setAllParticipantData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectDropVal, setProjectDropVal] = useState();

  useEffect(() => {
    getParticipants();
    getProjectLists();
    setAddInviteParticipant({
      ...addInviteParticipant,
      participant_ids: [...participantList],
    });
  }, [participantList]);

  const getParticipants = async () => {
    setLoading(true);
    return await axios
      .get(
        API_BASE_URL +
        `/super-admin/admin/participants?workspace=${workspaceState?.selectedWorkspace?._id}`
      )
      .then((res) => {
        setLoading(false);
        if (res.status) {
          setAllParticipantData(res.data.data);
        }
      });
  };

  const getProjectLists = async () => {
    try {
      const res = await axios.get(API_BASE_URL + "/researchers/projects");
      if (res.status) {
        setProjects(res.data.data);
      }
    } catch (error) {
      console.error("Error fetching project lists", error);
    }
  };

  const matchedParticipants = allParticipantData.filter((participant) =>
    participantList.includes(participant._id)
  );

  const handleCheckboxChange = (participantId) => {
    setAddInviteParticipant((prev) => {
      const newParticipantIds = prev.participant_ids.includes(participantId)
        ? prev.participant_ids.filter((id) => id !== participantId)
        : [...prev.participant_ids, participantId];
      return { ...prev, participant_ids: newParticipantIds };
    });
  };

  console.log("===addInviteParticipant==:", addInviteParticipant);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const UserBox = ({ participant }) => {
    let isChecked = addInviteParticipant.participant_ids.includes(
      participant._id
    );
    return (
      <Box className="userBox">
        <input
          className="custom-checkbox"
          type="checkBox"
          checked={isChecked}
          onChange={(el) => {
            handleCheckboxChange(participant._id);
          }}
        />
        <Box className="avatar">
          {/* <img src={avatar} /> */}
          {participant?.profile_pic == "undefined" ||
            participant?.profile_pic == null ? (
            <img src={DummyUser} />
          ) : (
            <img src={viewImage(participant?.profile_pic)} />
          )}
        </Box>
        <Typography>
          {participant?.name} <br /> <span>{participant?.username}</span>
        </Typography>
      </Box>
    );
  };

  const handelTextlineChekc = (p, input) => {
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine === "underline") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine === "line-through") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("line-through");
        }
      }
    }
  };

  // const setFormValue = (e) => {
  //   const { name, value } = e.target;
  //   setEmailval((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };
  const setFormValue = (e) => {
    let newFormData = {
      ...addInviteParticipant,
      [e.target.name]: e.target.value,
    };
    setAddInviteParticipant(newFormData);
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setInvitePop(false);
    }
  };

  useEffect(() => {
    if (invitePop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [invitePop]);

  const handleChange = (event) => {
    const selectedValue = event.target.value === "" ? 0 : event.target.value;
    setAddInviteParticipant((prevGroup) => ({
      ...prevGroup,
      [event.target.name]: selectedValue,
    }));
  };

  let handleCreateInviteProject = async () => {
    setLoading(true);
    try {
      if (addInviteParticipant.participant_ids.length < 1) {
        return toast.error("Selection required: Please choose at least one participant.");
      }
      if (addInviteParticipant.project_ids.length < 1) {
        return toast.error("Selection required: Please choose at least one project.");
      }
      let { ...InviteData } = addInviteParticipant;
      let inviteRes = await addInviteAPI(InviteData, authState.authToken);
      if (inviteRes.success) {
        toast.success(inviteRes.message, TOAST_CONFIG);
        setInvitePop(false);
        setReloadList(true);
      } else {
        toast.error(inviteRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        id="popBackdrop"
        sx={{ display: invitePop ? "flex" : "none" }}
        onClick={handelPopClose}
        className="invitePPopbox workspacePop "
      >
        <Box className="workspaceContaint">
          <Typography className="workspaceHeader">
            {t("inviteProjectPop.headerText")}
          </Typography>
          <Box className="userMainBox">
            {/* {UserBox()}
            {UserBox()} */}
            {matchedParticipants.map((participant) => (
              <UserBox participant={participant} key={participant._id} />
            ))}
          </Box>
          {/* <Box
            mt={2}
            mb={3}
            className="inputAppDrop"
            onClick={() => setAssignProjectsDrop(!AssignProjectsDrop)}
          >
            <Typography>
              {AssignProjectsDropVal || t("inviteProjectPop.selectProjects")}
            </Typography>
            <img
              style={{ rotate: AssignProjectsDrop ? "180deg" : "0deg" }}
              src={dropArrow}
            />
            {/* pop
            <Box
              className="statusPop"
              sx={{ height: AssignProjectsDrop ? "auto" : "0px" }}
            >
              <Box
                onClick={() => setAssignProjectsDropVal("Item-A")}
                className="statusPopItem"
              >
                <Typography>Item-A</Typography>
              </Box>
              <Box
                onClick={() => setAssignProjectsDropVal("Item-B")}
                className="statusPopItem"
              >
                <Typography>Item-B</Typography>
              </Box>
              <Box
                onClick={() => setAssignProjectsDropVal("Item-C")}
                className="statusPopItem"
              >
                <Typography>Item-C</Typography>
              </Box>
              <Box
                onClick={() => setAssignProjectsDropVal("Item-D")}
                className="statusPopItem"
              >
                <Typography>Item-D</Typography>
              </Box>
            </Box>
          </Box> */}

          {/* <DropDownLists
            label="Assign Projects"
            value={projectDropVal}
            items={projects}
            handleSelection={(item) => {
              // handleFilterProjects(item._id);
              setProjectDropVal(item.name);
            }}
            placeholder="Filter by Project"
          /> */}
          <Typography className="eTemPopLabel">
            Assign Projects<samp>*</samp>
          </Typography>
          <Box className="ckeckBoxRoom" mb={3} mt={2}>
            <FormControl sx={{ width: "100%" }} size="medium">
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={addInviteParticipant?.project_ids}
                onChange={handleChange}
                name="project_ids"
                multiple
                displayEmpty
                MenuProps={MenuProps}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <p>Select Projects</p>;
                  }
                  return selected
                    .map((value) => {
                      const project = projects.find(
                        (project) => project._id === value
                      );
                      return project ? project.name : value;
                    })
                    .join(", ");
                }}
              >
                {projects.map((project, index) => (
                  <MenuItem value={project._id} key={index}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Typography className="eTemPopLabel">
            {t("inviteProjectPop.subject")}
            <samp>*</samp>
          </Typography>
          <Box className="csPBox" mt={2} mb={3} s>
            <input
              className="phoneInput"
              placeholder="Enter Name"
              name="subject"
              value={addInviteParticipant?.subject}
              onChange={setFormValue}
            />
          </Box>
          {/* <Box
            mt={2}
            mb={3}
            className="inputAppDrop inputAppDropInvite"
            onClick={() => setSubjectDrop(!subjectDrop)}
          >
            <Typography>
              {subjectDropVal || t("inviteProjectPop.terms&condition")}
            </Typography>
            <img
              style={{ rotate: subjectDrop ? "180deg" : "0deg" }}
              src={dropArrow}
            />
            {/* pop *
            <Box
              className="statusPop"
              sx={{ height: subjectDrop ? "auto" : "0px" }}
            >
              <Box
                onClick={() => setSubjectDropVal("Item-A")}
                className="statusPopItem"
              >
                <Typography>Item-A</Typography>
              </Box>
              <Box
                onClick={() => setSubjectDropVal("Item-B")}
                className="statusPopItem"
              >
                <Typography>Item-B</Typography>
              </Box>
              <Box
                onClick={() => setSubjectDropVal("Item-C")}
                className="statusPopItem"
              >
                <Typography>Item-C</Typography>
              </Box>
              <Box
                onClick={() => setSubjectDropVal("Item-D")}
                className="statusPopItem"
              >
                <Typography>Item-D</Typography>
              </Box>
            </Box>
          </Box> */}

          <Typography className="eTemPopLabel">
            {t("inviteProjectPop.message")}
            <samp>*</samp>
          </Typography>

          <Box className="emailInputBox">
            <Box className="emailInputBoxNav">
              <Box
                onClick={() => setSignatureTextItalic(!signatureTextItalic)}
                className="emailImgBox"
              >
                <img src={IIcon} />
              </Box>
              <Box
                onClick={() => setSignatureTextBold(!signatureTextBold)}
                className="emailImgBox"
              >
                <img src={BIcon} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("underline", "signature")}
                className="emailImgBox"
              >
                <img src={UnderLineU} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("line-through", "signature")}
                className="emailImgBox"
              >
                <img src={MLineU} />
              </Box>
              <Box className="emailImgBox">
                <img src={AtachMentIcon} />
              </Box>
              <Box className="emailImgBox">
                <img src={Qbox} />
              </Box>
              <Box
                onClick={() => setSignatureTextLaOut("start")}
                className="emailImgBox"
              >
                <img src={Line1} />
              </Box>
              <Box
                onClick={() => setSignatureTextLaOut("center")}
                className="emailImgBox"
              >
                <img src={Line2} />
              </Box>
              <Box
                onClick={() => setSignatureTextLaOut("end")}
                className="emailImgBox"
              >
                <img src={Line3} />
              </Box>
              <Box className="emailImgBox">
                <img src={Line4} />
              </Box>
              <Box className="emailImgBox">
                <img src={emoji} />
              </Box>
            </Box>
            <textarea
              style={{
                textAlign: signatureTextLaOut,
                fontStyle: signatureTextItalic ? "italic" : "normal",
                fontWeight: signatureTextBold ? "bold" : "normal",
                textDecoration: signatureTextUnderLine,
              }}
              className="emailInput"
              // name="emailSignature"
              name="message"
              // value={emailval?.emailSignature || email2}
              value={addInviteParticipant?.message}
              onChange={setFormValue}
            />
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine
              onClick={() => {
                setInvitePop(false);
                setAddInviteParticipant((prevGroup) => ({
                  ...prevGroup,
                  project_ids: [],
                  subject: "",
                  message: ""
                }))
              }}
              buttonText={t("commonTxt.cancelBtn")}
            />
            <AppBtn
              buttonText={t("inviteProjectPop.invite")}
              onClick={() => handleCreateInviteProject()}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
