import { Box, Typography,Grid,
    FormLabel,
    TextField,
    Alert } from '@mui/material';
import React, { useState } from 'react';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PASideMenu from "../../../Components/Participants/AccountSettingSideMenu";
import { sideHaumIcon } from "../../../Assets/Data";
import { AppBtn, AppBtnOutLine } from '../../../Components/AppTools/AppButton';

export default function ContactTicket({ slideMenu, setSlideMenu, workspaceDrop, setWorkSpaceDrop }) {
    const [Password, setPassword] = useState(false);
    const [ErrorPassword, setErrorPassword] = useState(false);
    const [SuccessPassword, setSuccessPassword] = useState(false);

  return (
    <>
      <Box className="ResearchersContainer ">
        {/* side menu */}
        <PASideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
          </Box>
          {/* Page container */}
        <Box className="researchersMainBox pageContainer">
            <Box className="PasswordManagementSection">
                <Typography className="emailManageMainLabel">Change Email</Typography>
                <Grid container spacing={2}>
                <Grid item sm={12}>
                    <FormLabel className="label">Existing Email Address</FormLabel>
                    <TextField
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    value={'Han123Richard@gmail.com'}
                    disabled
                    />

                </Grid>
                <Grid item sm={12}>
                    <FormLabel className="label">Enter New Email Address</FormLabel>
                    <TextField
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    placeholder="Enter new email address"
                    />
                </Grid>
                <Grid item sm={12}>
                    <Box className="workPupBtnBox">
                    <AppBtn
                        buttonText="Change Email"
                        onClick={() => setPassword(!Password)}
                    />
                    </Box>
                </Grid>
                </Grid>

                <Box
                sx={{ display: Password ? "block" : "none" }}
                className="ChangePasswordForm"
                >

                <Grid container spacing={2} className="PasswordForm">

                    <Grid item sm={6}>
                    <FormLabel className="label">Enter the code sent to your existing email address</FormLabel>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        placeholder="Enter the code sent to your existing email address"
                        variant="outlined"
                        size="small"
                    />
                    </Grid>
                    <Grid item sm={6}>
                    <FormLabel className="label">Enter the code sent to your new email address</FormLabel>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        placeholder="Enter the code sent to your new email address"
                        variant="outlined"
                        size="small"
                    />
                    </Grid>
                    <Grid item sm={12}>
                    <Box className="workPupBtnBox">
                        <AppBtnOutLine buttonText="Cancel" />
                        <AppBtn buttonText="Save Email" onClick={() => setErrorPassword(!ErrorPassword)} />
                    </Box>
                    </Grid>
                </Grid>
                </Box>

                <Box
                sx={{ display: ErrorPassword ? "block" : "none" }}
                className="ChangePasswordForm"
                >
                <Typography variant="h3" gutterBottom>
                    Error Email
                </Typography>
                <Alert icon={<InfoOutlinedIcon />} severity="success" className="alertnote">
                    <ul>
                    <li>Consists of alphanumeric characters and certain special characters (e.g., ".", "-", "_") </li>
                    <li>Cannot start or end with a special character</li>
                    <li>Special characters cannot appear consecutively </li>
                    </ul>
                </Alert>

                <Grid container spacing={2} className="PasswordForm">
                    <Grid item sm={6}>
                    <FormLabel className="label error">Enter the code sent to your existing email address</FormLabel>
                    <TextField
                        fullWidth
                        error
                        id="outlined-basic"
                        placeholder="Enter the code sent to your existing email address"
                        variant="outlined"
                        size="small"
                    />
                    </Grid>
                    <Grid item sm={6}>
                    <FormLabel className="label error">Enter the code sent to your new email address</FormLabel>
                    <TextField
                        fullWidth
                        error
                        helperText="Email already exist"
                        id="outlined-basic"
                        placeholder="Enter the code sent to your new email address"
                        variant="outlined"
                        size="small"
                    />
                    </Grid>
                    <Grid item sm={12}>
                    <Box className="workPupBtnBox">
                        <AppBtnOutLine buttonText="Cancel" />
                        <AppBtn buttonText="Save Email" onClick={() => setSuccessPassword(!SuccessPassword)} />
                    </Box>
                    </Grid>
                </Grid>
                </Box>

                <Box
                sx={{ display: SuccessPassword ? "block" : "none" }}
                className="ChangePasswordForm"
                >
                <Typography variant="h3" gutterBottom>
                    Success Email
                </Typography>
                <Alert icon={<InfoOutlinedIcon />} severity="success" className="alertnote">
                    <ul>
                    <li>Consists of alphanumeric characters and certain special characters (e.g., ".", "-", "_") </li>
                    <li>Cannot start or end with a special character</li>
                    <li>Special characters cannot appear consecutively </li>
                    </ul>
                </Alert>

                <Grid container spacing={2} className="PasswordForm">
                    <Grid item sm={6}>
                    <FormLabel className="label success">Enter the code sent to your existing email address</FormLabel>
                    <TextField
                        fullWidth
                        color="success"
                        focused
                        id="outlined-basic"
                        placeholder="Enter the code sent to your existing email address"
                        variant="outlined"
                        size="small"
                    />
                    </Grid>
                    <Grid item sm={6}>
                    <FormLabel className="label success">Enter the code sent to your new email address</FormLabel>
                    <TextField
                        fullWidth
                        color="success"
                        focused
                        id="outlined-basic"
                        placeholder="Enter the code sent to your new email address"
                        variant="outlined"
                        size="small"
                    />
                    </Grid>
                    <Grid item sm={12}>
                    <Box className="workPupBtnBox">
                        <AppBtnOutLine buttonText="Cancel" />
                        <AppBtn buttonText="Save Email" />
                    </Box>
                    </Grid>
                </Grid>
                </Box>
            </Box>
        </Box>
        </Box>
      </Box>
    </>

  )
}
