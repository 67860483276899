import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import "./style.css";

import { sideHaumIcon } from "../../../Assets/Data";
import { groupBtnTab } from "../../../Assets/Data";

import backArrow from "../../../Assets/Images/noun-back.png";
import ToggleOnImage from "../../../Assets/Images/Toggle.png";
import ToggleOffImage from "../../../Assets/Images/Toggle2.png";

import SideMenu from "../../../Components/Researchers/SideMenu";
import PreviewMailPop from "../../../Components/Researchers/PreviewMailPop";
import ScheduleMailPop from "../../../Components/Researchers/ScheduleMailPop";
import UseTemplatePop from "../../../Components/Researchers/UseTemplatePop";

import { AppBtnOutLine, AppBtn } from "../../../Components/AppTools/AppButton";
import { useAppSelector } from "../../../lib/store/store";
import { researcherAction } from "../../../Services/researcherActions";
import { TOAST_CONFIG } from "../../../env";
import { toast } from "react-toastify";

export default function ProjectParticipantMailInvitation({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
}) {
  const Navigate = useNavigate();

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const previousPageHandler = () => {
    Navigate("/researchers/project/directory_participants");
  };
  const authState = useAppSelector((state) => state.auth);
  const [setEmailTemplatePop, emailTemplatePop] = useState(false);
  const workspaceState = useAppSelector((state) => state.workspace);
  const email1 = "Enforce a minimum of";
  const email2 = "Enforce a minimum of";

  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();

  const [signatureTextLaOut, setSignatureTextLaOut] = useState("start");
  const [signatureTextItalic, setSignatureTextItalic] = useState(false);
  const [signatureTextBold, setSignatureTextBold] = useState(false);
  const [signatureTextUnderLine, setSignatureTextUnderLine] = useState();
  const [awaitingReply, setAwaitingReply] = useState();
  const [previewMailPop, setPreviewMailPop] = useState(false);
  const [scheduleMailPop, setScheduleMailPop] = useState(false);
  const [useTemplatePop, setUseTemplatePop] = useState(false);

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      previousPageHandler();
    }
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine === "underline") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine === "line-through") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("line-through");
        }
      }
    }
  };

  const handleToggleMenu = () => {
    setAwaitingReply((prevToggleState) => {
      let newToggleState = prevToggleState;
      newToggleState = !newToggleState;
      return newToggleState;
    });
  };

  const handelNextPop = () => {
    if (!handleValidation()) {
      toast.error("Please correct form fields", TOAST_CONFIG);
      return false;
    }

    setPreviewMailPop(true);
  };

  const handelSchedulePop = () => {
    setScheduleMailPop(true);
  };

  const handleUseTempPop = () => {
    setUseTemplatePop(true);
  };

  useEffect(() => {
    if (emailTemplatePop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [emailTemplatePop]);

  // 
  const initial = {
    workspace_id: workspaceState?.selectedWorkspace?._id,
    project_id: workspaceState?.selectedProject?._id,
    recipient_email: "",
    recipient: "",
    subject: "",
    message: "",
    login_btn_txt: "",
    signature: "",
    awaiting_replay: false
  }

  const [invitation, setInvitation] = useState(initial);

  const validationObj = {
    hasErr: false,
    recipient: {
      error: false,
      msg: "",
    },
  };

  const [validationErrors, setValidationErrors] = useState({
    ...validationObj,
  });

  const handleValidation = (formInput) => {
    formInput = formInput || invitation;
    let validationerr = { ...validationObj };

    if (formInput.recipient == "") {
      validationerr.hasErr = true;
      validationerr.recipient = {
        error: true,
        msg: "Recipient is required",
      };
    }

    if (formInput.subject == "") {
      validationerr.hasErr = true;
      validationerr.subject = {
        error: true,
        msg: "Subject is required",
      };
    }

    if (formInput.message == "") {
      validationerr.hasErr = true;
      validationerr.message = {
        error: true,
        msg: "Message is required",
      };
    }

    if (invitation.recipient === "new-participient") {
      if (formInput.recipient_email == "") {
        validationerr.hasErr = true;
        validationerr.recipient_email = {
          error: true,
          msg: "email is required",
        };
      }
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  };



  const submitInvitation = async () => {
    try {
      const payload = {
        ...invitation,
        project_id: workspaceState?.selectedProject?._id
      }
      const response = await researcherAction("/super-admin/admin/participants/invite-to-project-participants", payload, authState.authToken)
      console.log("response", response)
      if (response.success) {
        setInvitation({ ...invitation, ...initial });
        setPreviewMailPop(false);
        toast.success(response.message, TOAST_CONFIG);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  }

  return (
    <>
      {previewMailPop ? (
        <PreviewMailPop
          previewMailPop={previewMailPop}
          setPreviewMailPop={setPreviewMailPop}
          submitInvitation={submitInvitation}
          message={invitation.message}
        />
      ) : scheduleMailPop ? (
        <ScheduleMailPop
          scheduleMailPop={scheduleMailPop}
          setScheduleMailPop={setScheduleMailPop}
        />
      ) : useTemplatePop ? (
        <UseTemplatePop
          useTemplatePop={useTemplatePop}
          setUseTemplatePop={setUseTemplatePop}
        />
      ) : null}
      <Box className="ResearchersContainer">
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
          </Box>

          <>
            <Box className="researchersMainBox pageContainer">
              <Box className="workspaceListBox">
                <Box
                  onClick={() => previousPageHandler()}
                  className="openBtn"
                  style={{ "margin-bottom": "10px" }}
                >
                  <img src={backArrow} />
                  <Typography className="neonBackText">
                    {t("commonTxt.backBtn")}
                  </Typography>
                </Box>

                <Box className="upperBox">
                  <Box className="leftUpperBox">
                    <Typography className="eTemPopLabel">
                      {t("ProjectParticipantMailInvitation.heading")}
                    </Typography>
                  </Box>
                  <Box className="rightUpperBox">
                    <Box sx={{ width: "13%" }} className="groupBtnTab">
                      {groupBtnTab}
                      <Typography>{t("commonTxt.groups")}</Typography>
                    </Box>
                    <Box
                      onClick={() => {
                        handleUseTempPop();
                      }}
                    >
                      <Typography className="verifyLinkCodeBook">
                        {t("ProjectParticipantMailInvitation.tempLink")}
                      </Typography>
                    </Box>
                    <Box>
                      <AppBtnOutLine buttonText={t("commonTxt.btnTextDraft")} />
                    </Box>
                  </Box>
                </Box>

                <Box className="basicBox customBasicBox">
                  <Typography className="eTemPopLabel">
                    {t("ProjectParticipantMailInvitation.recipient")}
                  </Typography>
                  <Box>
                    <Box className="ProjectRadioInput">
                      <input
                        className="emailSettingInput emailSettingRadio"
                        type="radio"
                        name="projectState"
                        checked={invitation.recipient === "all-participient"}
                        onClick={() => setInvitation({ ...invitation, recipient: "all-participient" })}
                      />
                      <Typography className="emailSettingLabel">
                        {t("ProjectParticipantMailInvitation.allParticipient")}
                      </Typography>
                    </Box>
                    <Box className="ProjectRadioInput">
                      <input
                        className="emailSettingInput emailSettingRadio"
                        type="radio"
                        name="projectState"
                        checked={invitation.recipient === "active-participient"}
                        onClick={() => setInvitation({ ...invitation, recipient: "active-participient" })}
                      />
                      <Typography className="emailSettingLabel">
                        {t(
                          "ProjectParticipantMailInvitation.activeParticipients"
                        )}
                      </Typography>
                    </Box>
                    <Box className="ProjectRadioInput">
                      <input
                        className="emailSettingInput emailSettingRadio"
                        type="radio"
                        name="projectState"
                        checked={invitation.recipient === "pending-invite"}
                        onClick={() => setInvitation({ ...invitation, recipient: "pending-invite" })}
                      />
                      <Typography className="emailSettingLabel">
                        {t("ProjectParticipantMailInvitation.text1")}
                      </Typography>
                    </Box>
                    <Box className="ProjectRadioInput">
                      <input
                        className="emailSettingInput emailSettingRadio"
                        type="radio"
                        name="projectState"
                        checked={invitation.recipient === "administrators"}
                        onClick={() => setInvitation({ ...invitation, recipient: "administrators" })}
                      />
                      <Typography className="emailSettingLabel">
                        {t("ProjectParticipantMailInvitation.admin")}
                      </Typography>
                    </Box>
                    <Box className="ProjectRadioInput">
                      <input
                        className="emailSettingInput emailSettingRadio"
                        type="radio"
                        name="projectState"
                        checked={invitation.recipient === "new-participient"}
                        onClick={() => setInvitation({ ...invitation, recipient: "new-participient" })}
                      />
                      <Typography className="emailSettingLabel">
                        {t("ProjectParticipantMailInvitation.invitedNewPart")}
                      </Typography>

                    </Box>
                    {invitation.recipient === "new-participient" && <Box className="pickerBox ">
                      <input
                        className="eTemInput"
                        placeholder="New Participant Email"
                        value={invitation.recipient_email}
                        onChange={(e) => setInvitation({ ...invitation, recipient_email: e.target.value })}
                      />
                      <span className="validationerr">
                        {validationErrors.recipient_email?.msg}
                      </span>
                    </Box>}
                    <span className="validationerr">
                      {validationErrors.recipient?.msg}
                    </span>
                  </Box>
                  <Box>
                    <Typography className="eTemPopLabel ">
                      {t("ProjectParticipantMailInvitation.subject")}
                      <samp>*</samp>
                    </Typography>
                  </Box>
                  <Box className="pickerBox ">
                    <input
                      className="eTemInput"
                      placeholder="Welcome Messgae"
                      value={invitation.subject}
                      onChange={(e) => setInvitation({ ...invitation, subject: e.target.value })}
                    />
                    <span className="validationerr">
                      {validationErrors.subject?.msg}
                    </span>
                  </Box>
                </Box>

                <Typography className="eTemPopLabel ">
                  {t("ProjectParticipantMailInvitation.dynamicVal")}
                </Typography>
                <Box className="basicBox">
                  <Box className="eTemPopDyValuBox basicBoxItem">
                    <Box>
                      <Typography className="eTemPopDyValuText">
                        {t("ProjectParticipantMailInvitation.fName")}
                      </Typography>
                      <span className="eTemPopDyValuTextSpan etpTcolon">:</span>
                      <span className="eTemPopDyValuTextSpan">{"{fname}"}</span>
                    </Box>
                    <Box>
                      <Typography className="eTemPopDyValuText">
                        {t("ProjectParticipantMailInvitation.lName")}
                      </Typography>
                      <span className="eTemPopDyValuTextSpan etpTcolon">:</span>
                      <span className="eTemPopDyValuTextSpan">{"{lname}"}</span>
                    </Box>
                    <Box>
                      <Typography className="eTemPopDyValuText">
                        {t("ProjectParticipantMailInvitation.uName")}
                      </Typography>
                      <span className="eTemPopDyValuTextSpan etpTcolon">:</span>
                      <span className="eTemPopDyValuTextSpan">
                        {"{username}"}
                      </span>
                    </Box>
                    <Box>
                      <Typography className="eTemPopDyValuText">
                        {t("ProjectParticipantMailInvitation.password")}
                      </Typography>
                      <span className="eTemPopDyValuTextSpan etpTcolon">:</span>
                      <span className="eTemPopDyValuTextSpan">
                        {"{password}"}
                      </span>
                    </Box>
                  </Box>

                  <Box className="eTemPopDyValuBox basicBoxItem">
                    <Box>
                      <Typography className="eTemPopDyValuText">
                        {t("ProjectParticipantMailInvitation.email")}
                      </Typography>
                      <span className="eTemPopDyValuTextSpan etpTcolon">:</span>
                      <span className="eTemPopDyValuTextSpan">{"{email}"}</span>
                    </Box>
                    <Box>
                      <Typography className="eTemPopDyValuText">
                        {t("ProjectParticipantMailInvitation.participantId")}
                      </Typography>
                      <span className="eTemPopDyValuTextSpan etpTcolon">:</span>
                      <span className="eTemPopDyValuTextSpan">{"{pid}"}</span>
                    </Box>
                    <Box>
                      <Typography className="eTemPopDyValuText">
                        {t("ProjectParticipantMailInvitation.extParticipantId")}
                      </Typography>
                      <span className="eTemPopDyValuTextSpan etpTcolon">:</span>
                      <span className="eTemPopDyValuTextSpan">{"{epid}"}</span>
                    </Box>
                    <Box>
                      <Typography className="eTemPopDyValuText">
                        {t("ProjectParticipantMailInvitation.projectId")}
                      </Typography>
                      <span className="eTemPopDyValuTextSpan etpTcolon">:</span>
                      <span className="eTemPopDyValuTextSpan">{"{prid}"}</span>
                    </Box>
                  </Box>
                </Box>

                <Typography className="eTemPopLabel">
                  {t("ProjectParticipantMailInvitation.message")}
                  <samp>*</samp>
                </Typography>
                <Box className="emailInputBox">
                  <textarea
                    style={{
                      textAlign: messageTextLaOut,
                      fontStyle: messageTextItalic ? "italic" : "normal",
                      fontWeight: messageTextBold ? "bold" : "normal",
                      textDecoration: messageTextUnderLine,
                      // textDecoration: messageTextMLine ? "line-through" : null
                    }}
                    className="emailInput"
                    name="emailMessage"
                    value={invitation?.message}
                    onChange={(e) => setInvitation({ ...invitation, message: e.target.value })}
                  />

                </Box>
                <span className="validationerr">
                  {validationErrors.message?.msg}
                </span>
                <Box
                  minWidth={"18%"}
                  className="Tabelsel customToggle"
                  onClick={() => setInvitation({ ...invitation, awaiting_replay: !invitation.awaiting_replay })}
                >
                  <img
                    src={invitation.awaiting_replay ? ToggleOnImage : ToggleOffImage}
                    alt="Toggle"
                  />
                  <Typography>
                    {t("ProjectParticipantMailInvitation.awaitingreply")}
                  </Typography>
                </Box>

                <Box className="eTemPopLogInBtnBox">
                  <Typography className="eTemPopLabel">
                    {t("ProjectParticipantMailInvitation.loginButton")}
                  </Typography>
                  <Box className="loginBtn">

                    {/* {t("ProjectParticipantMailInvitation.loginLink")} */}
                    <input
                      className="eTemInput"
                      placeholder={t("ProjectParticipantMailInvitation.loginLink")}
                      value={invitation.login_btn_txt}
                      onChange={(e) => setInvitation({ ...invitation, login_btn_txt: e.target.value })}
                    />

                  </Box>
                </Box>

                <Typography className="eTemPopLabel">
                  {t("ProjectParticipantMailInvitation.sign")}
                </Typography>
                <Box className="emailInputBox">
                  <textarea
                    style={{
                      textAlign: signatureTextLaOut,
                      fontStyle: signatureTextItalic ? "italic" : "normal",
                      fontWeight: signatureTextBold ? "bold" : "normal",
                      textDecoration: signatureTextUnderLine,
                    }}
                    className="emailInput"
                    name="emailSignature"
                    value={invitation?.signature}
                    onChange={(e) => setInvitation({ ...invitation, signature: e.target.value })}
                  />
                </Box>

                <Box className="workPupBtnBox">
                  <AppBtnOutLine
                    buttonText={t("commonTxt.cancelBtn")}
                    onClick={() => previousPageHandler()}
                  />
                  <AppBtnOutLine
                    buttonText={t("ProjectParticipantMailInvitation.schedule")}
                    onClick={() => handelSchedulePop()}
                  />
                  <AppBtn
                    buttonText={t(
                      "ProjectParticipantMailInvitation.previewAndSend"
                    )}
                    onClick={() => handelNextPop()}
                  />
                </Box>
              </Box>
            </Box>
          </>
        </Box>
      </Box>
    </>
  );
}
