import axios from "axios";
import { API_BASE_URL } from "../env";

export const createWorkSpaceManagementApi = async (
  createWorkSpaceData,
  token
) => {
  console.log(createWorkSpaceData);
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + "/super-admin/admin/workspaces/create",
      createWorkSpaceData,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const createAddNewCompany = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + "/super-admin/admin/companies/create",
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const addResearcher = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + "/super-admin/admin/researchers/create",
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const updateResearcherApi = async (researcher_id, updateData, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + `/super-admin/admin/researchers/update/${researcher_id}`,
      updateData,
      config
    );
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const uploadCoverImage = async (
  workspace_id,
  createWorkSpaceData,
  token
) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL +
        `/super-admin/admin/workspaces/upload-image/${workspace_id}`,
      createWorkSpaceData,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const updateWorkSpaceManagementApi = async (
  workspace_id,
  updateData,
  token
) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + `/super-admin/admin/workspaces/update/${workspace_id}`,
      updateData,
      config
    );
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const updateCompanyApi = async (company_id, updateData, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + `/super-admin/admin/companies/update/${company_id}`,
      updateData,
      config
    );
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const createAdminUser = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + `/super-admin/admin/create`,
      payload,
      config
    );
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const updateAdminUser = async (adminId, payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  try {
    let data = await axios.post(
      API_BASE_URL + `/super-admin/admin/update-user/${adminId}`,
      payload,
      config
    );
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const deleteAdminUser = async (adminId, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  try {
    let data = await axios.delete(
      API_BASE_URL + `/super-admin/admin/delete-user/${adminId}`,
      config
    );
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const inviteSuperadminUser = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  try {
    let data = await axios.post(
      API_BASE_URL + `/super-admin/admin/invite`,
      payload,
      config
    );
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const invationsResponse = async (payload) => {
  try {
    let data = await axios.post(
      API_BASE_URL + `/super-admin/admin/invites/accept-reject`,
      payload
    );
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const updateCollaboratorApi = async (
  collaborator_id,
  updateData,
  token
) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL +
        `/super-admin/admin/collaborators/update/${collaborator_id}`,
      updateData,
      config
    );
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

// Observer Api

export const addObserverApi = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + "/super-admin/admin/observers/create",
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const updateObserverApi = async (observer_id, updateData, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + `/super-admin/admin/observers/update/${observer_id}`,
      updateData,
      config
    );
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

// Particpants Api

export const addParticipantsApi = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + "/super-admin/admin/participants/create",
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const updatePartcipantsApi = async (
  participants_id,
  updateData,
  token
) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL +
        `/super-admin/admin/participants/update/${participants_id}`,
      updateData,
      config
    );
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

// collaborator

export const addCollaboratorApi = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + "/super-admin/admin/collaborators/create",
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const uploadUserDirectoryImage = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + `/master/upload-file`,
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const uploadFile = async (fileData, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + `/master/upload-file`,
      fileData,
      config
    );
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const updateConfigSettings = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + "/super-admin/admin/settings/save",
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const updateLanguage = async (lanId, payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  try {
    let data = await axios.put(
      API_BASE_URL + `/master/language/${lanId}`,
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const makeProject = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  try {
    let data = await axios.post(
      API_BASE_URL + `/researchers/projects`,
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const updateProject = async (resourceId, payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  try {
    let data = await axios.put(
      API_BASE_URL + `/researchers/projects/${resourceId}`,
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const deleteProject = async (resourceId, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  try {
    let data = await axios.delete(
      API_BASE_URL + `/researchers/projects/${resourceId}`,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const createResearcherParticipantAPI = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + `/super-admin/admin/participants/create`,
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const updateParticipantApi = async (
  participant_id,
  updateData,
  token
) => {
  console.log("jhjhgf", updateData);
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + `/super-admin/admin/participants/update/${participant_id}`,
      updateData,
      config
    );
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

// Deactivate Participant
export const deactivateParticipantApi = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + `/super-admin/admin/participants/multiple/changeStatus`,
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

// add Invite
export const addInviteAPI = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL +
        `/super-admin/admin/participants/invite-participants-to-projects`,
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

// Add participant to project
export const assignParticipantToProjectAPI = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL +
        `/super-admin/admin/participants/add-participants-to-projects`,
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

// Remove participant to project
export const removeParticipantToProjectAPI = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL +
        `/super-admin/admin/participants/remove-participants-to-projects`,
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

// Export Participant Api
export const createExportParticipantAPI = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + `/super-admin/admin/export-users`,
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

// Export Participant Api
export const participantEmailSent = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + `/master/send-mail-users`,
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

// create Template

export const createTemplateAPI = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + `/participant/create-import-template`,
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

// upload Excel file bulk Import

// export const bulkImportFileUpadte = async (payload, token) => {
//   let config = {
//     headers: {
//       Authorization: "Bearer " + token,
//     },
//   };
//   try {
//     let data = await axios.post(API_BASE_URL + `//participant/import-participant`, payload, config);
//     console.log(data.data);
//     return data.data;
//   } catch (error) {
//     let message = "Something went wrong, please try again!";
//     let status_code = 500;
//     if (error.response) {
//       message = error.response.data.message;
//       status_code = error.response.status;
//     }
//     return {
//       success: false,
//       message: message,
//       status_code: status_code,
//     };
//   }
// };

// support login edit Participant
export const supportLoginApi = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + `/auth/impersonate-login`,
      payload,
      config
    );
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

// Admin Workspace administration

export const createWorkspaceAdminAPI = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + `/super-admin/admin/workspace-user/create`,
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

// update Admin Workspace administration API
export const updateWorkspaceAdministrationApi = async (
  userWorkspace_id,
  updateData,
  token
) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL +
        `/super-admin/admin/workspace-user/update/${userWorkspace_id}`,
      updateData,
      config
    );
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

// Deactivate Admin Researcher, collaborator, and Observer API
export const DeacitvateUsersAPI = async (userId, payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + `/super-admin/admin/researchers/update/${userId}`,
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

// invite Admin API
export const createInviteNewAdminAPI = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + `/super-admin/invite-admin`,
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

// admin Invitation Response
export const admininvationsResponse = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + `/super-admin/invite-accept-reject`,
      payload,
      config
    );
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const updateLoggedInUserProfile = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + `/auth/update-profile`,
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const removeProfilePicture = async (token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + `/super-admin/admin/remove-profile-pic`,
      {},
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const deleteActivities = async (activityId, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  try {
    let data = await axios.delete(
      API_BASE_URL + `/researchers/activities/${activityId}`,
      config
    );
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const changeActivityStatus = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let response = await axios.post(
      API_BASE_URL + `/researchers/activities/update-status`,
      payload,
      config
    );
    console.log("response", response);
    return response.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.message;
      status_code = error.status_code;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const updateCoverImageActivity = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + `/researchers/activities/update-background-image`,
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const addApperanceApi = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  try {
    let data = await axios.post(
      API_BASE_URL + "/researchers/workspace-settings/save",
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};
