import React, { useEffect, useState } from "react";
import { Box, Typography, Alert, Button, Grid, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { useNavigate } from "react-router";

import SideMenu from "../SideMenu";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import DropArrow from "../../../Assets/Images/chevron-down.png";

import backArrow from "../../../Assets/Images/noun-back.png";

import { AppBtnOutLine, AppBtn } from "../../AppTools/AppButton";
import axios from "../../../lib/axios"
import { API_BASE_URL } from "../../../env";
import { toast } from "react-toastify";
import { formatDate, renderUserLayout } from "../../../lib/helpers";
import { useAppSelector } from "../../../lib/store/store";
import ResearcherLayout from "../../../Pages/Researchers/partials/ResearcherLayout";
import AdminLayout from "../../../Pages/Admin/partials/AdminLayout";

export default function AddStandardGroupResearcher({ }) {

  let authState = useAppSelector((state) => state.auth);
  const [nameError, setNameError] = useState("");
  const Navigate = useNavigate();

  const handleRouting = (Url) => {
    Navigate(Url);
  };

  const workspaceState = useAppSelector((state) => state.workspace);
  const groupData = {
    name: "",
    is_enhanced: false,
    restricted_projects: [],
    allow_projects: [],
    remove_projects: [],
    workspace_id: workspaceState?.selectedWorkspace?._id,
  }
  const [group, setGroup] = React.useState(groupData)

  const [projectList, setProjectList] = React.useState([]);
  const handleChange = (event) => {
    setGroup((prevGroup) => ({
      ...prevGroup,
      [event.target.name]: event.target.value,
    }));
  };

  const [colorSelectorDrop1, setColorSelectorDrop1] = useState(false);
  const [colorSelectorDrop1Val, setColorSelectorDrop1Val] =
    useState("Solid Color");

  useEffect(() => {
    getProject()
  }, [])

  const getProject = async () => {
    try {
      const data = await axios.get(API_BASE_URL + '/researchers/projects');
      setProjectList(data.data.data)
    } catch (error) {
      console.log(error);
    }
  }

  const submitGroupDetail = async (navigate) => {
    if (group.name === "") {
      setNameError("Group name is required.")
      return toast.error("Group name is required.")
    } else {
      setNameError("")
    }
    const payload = { ...group }
    try {
      const data = await axios.post(API_BASE_URL + '/participant/groups', payload);
      if (data.success) {
        toast.success(data.message)
        setGroup(groupData)
        Navigate(navigate)
      } else {
        toast.error(data.message)
      }
      return data.data
    } catch (error) {
      let message = 'Something went wrong, please try again!';
      let status_code = 200;
      if (error.response) {
        message = error.response.data.message;
        status_code = error.response.status;
      }
      return {
        'success': false,
        'message': message,
        'status_code': status_code
      }
    }
  }

  const getChildren = () => {
    return <Box className="researchersMainBox pageContainer">
      <Box className="SettingsFormSection">
        <Box
          onClick={() => handleRouting("/workspace-administration/group")}
          className="openBtn"
        >
          <img src={backArrow} />
          <Typography className="neonBackText">Back</Typography>
        </Box>

        <Box className="HeadingOptions">
          <Typography variant="h4">Add: Standard Groups</Typography>
          {/* <Box
            onClick={() => setColorSelectorDrop1(!colorSelectorDrop1)}
            width={"250px"}
            height={"40px"}
            px={2}
            className="statusBox"
          >
            <Typography>Options</Typography>
            <img src={DropArrow} />
            drop
            <Box
              sx={{ height: colorSelectorDrop1 ? "auto" : "0px" }}
              className="statusPop"
            >
              <Box
                onClick={() =>
                  setColorSelectorDrop1Val("Remove personal data")
                }
                className="statusPopItem"
              >
                <Typography>
                  {<DoDisturbIcon />} Remove personal data
                </Typography>
              </Box>
              <Box
                onClick={() => setColorSelectorDrop1Val("Delete")}
                className="statusPopItem"
              >
                <Typography>{<DeleteOutlineIcon />} Delete</Typography>
              </Box>
            </Box>
          </Box> */}
        </Box>

        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography className="greybox">
              Group ID: <span>--</span>
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography className="greybox">
              Updated: <span>{formatDate(projectList[0]?.updated_at)}</span>
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography className="greybox">
              Participants: <span>{projectList.length}</span>
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography className="greybox">
              Added: <span>Jun 5, 2023; 12:02 PM</span>
            </Typography>
          </Grid>
        </Grid>

        <Box className="FormField">
          <Typography variant="h5">Basics</Typography>
          <TextField
            fullWidth
            id="outlined-basic"
            placeholder="Enter basics"
            variant="outlined"
            size="small"
            name="name"
            onChange={handleChange}
            value={group.name}
            defaultValue={"Age 18 - 25"}
          />
          <span className="validationerr">{nameError}</span>
        </Box>

        <Box className="FormField">
          <Typography variant="h5">Advanced Options</Typography>
          <Typography variant="h6">Project Visibility</Typography>
          <Typography>
            Restrict the group's visibility to projects within the site that
            are specifically relevant to it.
          </Typography>
          <FormControl sx={{ width: "100%" }} size="small">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={group.restricted_projects}
              onChange={handleChange}
              name="restricted_projects"
              multiple
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <p>All Project</p>
                }
                return selected.map(value => {
                  const projectLists = projectList.find(prj => prj._id === value);
                  return projectLists ? projectLists.name : value;
                }).join(',')
              }}
            >
              {projectList.map((list, id) => (
                <MenuItem value={list._id} key={id}>{list.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box className="FormField">
          <Typography variant="h6">Add participants to projects</Typography>
          <Typography>
            Participants, whether new or existing, who are assigned to this
            group will be included in the selected projects.
          </Typography>
          <FormControl sx={{ width: "100%" }} size="small">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={group.allow_projects}
              onChange={handleChange}
              name="allow_projects"
              multiple
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <p>None</p>
                }
                return selected.map(value => {
                  const projectLists = projectList.find(prj => prj._id === value);
                  return projectLists ? projectLists.name : value;
                }).join(',')
              }}
            >
              {projectList.map((list, id) => (
                <MenuItem value={list._id} key={id}>{list.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box className="FormField">
          <Typography variant="h6">
            Remove participants from projects
          </Typography>
          <Typography>
            Participants, whether new or existing, who are assigned to this
            group will be excluded in the selected projects.
          </Typography>
          <FormControl sx={{ width: "100%" }} size="small">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={group.remove_projects}
              onChange={handleChange}
              name="remove_projects"
              multiple
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <p>None</p>
                }
                return selected.map(value => {
                  const projectLists = projectList.find(prj => prj._id === value);
                  return projectLists ? projectLists.name : value;
                }).join(',')
              }}
            >
              {projectList.map((list, id) => (
                <MenuItem value={list._id} key={id}>{list.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box className="FormField">
          <Grid container spacing={2}>
            <Grid item sm={6}></Grid>
            <Grid item sm={6}>
              <Box className="flexEnd">
                <AppBtnOutLine buttonText="Cancel" onClick={() => handleRouting("/workspace-administration/group")} />
                <AppBtnOutLine buttonText="Save & Add Another" onClick={submitGroupDetail} />
                <AppBtn buttonText="Save" onClick={() => submitGroupDetail("/workspace-administration/group")} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  }

  return renderUserLayout(authState?.userType, null, getChildren(), 'workadmin');
}
