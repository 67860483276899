import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import axios from "../../../lib/axios";
import { TOAST_CONFIG } from "../../../lib/constants";
import { toast } from "react-toastify";

//images

export default function DeleteResearcherPop({ deleteResearcherPop, setDeleteResearcherPop, deleteResearchersListData, setReloadList }) {
 
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
       setDeleteResearcherPop(false);
    }
  };

  const handelDeletePop = () => {
    setDeleteResearcherPop(false);
      return new Promise((resolve, reject) => {
          axios.delete(`/super-admin/admin/researchers/delete/${deleteResearchersListData?._id}`).then((res) => {
              if (res.success) {
                  resolve({
                      success: true,
                      message: res.message
                  });
                  toast.success(res.message, TOAST_CONFIG);
                  setReloadList(true);
              } else {
                  reject({
                      success: false,
                      message: res.message
                  });
                  toast.success(res.message, TOAST_CONFIG);
              }
          }).catch((err) => {
              reject({
                  success: false,
                  message: err.message
              });
          })
      });
  };

  useEffect(() => {
    if (deleteResearcherPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [deleteResearcherPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: deleteResearcherPop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople"
      >
        <Box
          sx={{ display: deleteResearcherPop ? "flex" : "none" }}
          className="workspaceContaint deletePagePop"
        >
          <Typography className="companyHeader">Delete Researcher</Typography>

          <Box className="basicBoxItem">
            <Typography className='addPageLabel'>Are you sure you want to delete the {`"${deleteResearchersListData?.name}”`} from Terapage ?</Typography>
            
          </Box>
          
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={() => setDeleteResearcherPop(false)} />
            <AppBtn buttonText="Delete" onClick={handelDeletePop} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
