import React from 'react';
import "./style.css";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";



//images
import video2 from "../../../Assets/Images/Videos/part2.mp4";
import charImg from "../../../Assets/Images/chartcardImg.png";
import charImg2 from "../../../Assets/Images/chartImg2.png";
import recordTitleThum from "../../../Assets/Images/chartRecordThum.png";
import audioWave from "../../../Assets/Images/audioWav.png";
import Play from "../../../Assets/Images/play.png";


export default function ReviewDataCharts() {
  const Navigate = useNavigate();
  const { t } = useTranslation();



  const ImgCard = ({ img, title }) => {
    return (
      <Box className="chartImageBox">
        <Box className="imgBoxCht">
          <img src={img} />
        </Box>

        <Box className="chartCardTextSection">
          <Box className="viewTitle">
            <span >{title}</span>
            <Typography className="viewBtn" onClick={() => Navigate("")}>{t("commonTxt.viewBtn")}</Typography>
          </Box>
          <Box className="likeCountBox">
            <Box>
              <Box sx={{ background: "#21A1F9" }} className="roundSimbol"></Box>
              <Typography>18.5% <br />{t("commonTxt.Like")}</Typography>
            </Box>

            <Box>
              <Box sx={{ background: "#FAA7E0" }} className="roundSimbol"></Box>
              <Typography>18.5% <br />{t("commonTxt.dislike")}</Typography>
            </Box>

            <Box>
              <Box sx={{ background: "#FEB273" }} className="roundSimbol"></Box>
              <Typography>18.5% <br />{t("commonTxt.confused")}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }



  const VideoCard = ({ video, title }) => {
    return (
      <Box className="chartVideoBox">
        <Box className="charterVideoSec">
          <video className='video' controls>
            <source src={video} type="video/mp4" />
          </video>
        </Box>
        <Box className="chartCardTextSection">
          <Box className="viewTitle">
            <span>{title}</span>
            <Typography onClick={() => Navigate("")} className="viewBtn">{t("commonTxt.viewBtn")}</Typography>
          </Box>
          <Box className="likeCountBox">
            <Box>
              <Box sx={{ background: "#21A1F9" }} className="roundSimbol"></Box>
              <Typography>18.5% <br />{t("commonTxt.Like")}</Typography>
            </Box>

            <Box>
              <Box sx={{ background: "#FAA7E0" }} className="roundSimbol"></Box>
              <Typography>18.5% <br />{t("commonTxt.dislike")}</Typography>
            </Box>

            <Box>
              <Box sx={{ background: "#FEB273" }} className="roundSimbol"></Box>
              <Typography>18.5% <br />{t("commonTxt.confused")}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }


  const ChartRecordBox = ({ title, url }) => {
    return (
      <Box className="chartRecordBox">
        <Box className="viewTitle">
          <Box className="rcThum">
            <img src={recordTitleThum} />
            <span>{title}</span>
          </Box>
          <Typography onClick={() => { }} className="viewBtn">{t("commonTxt.viewBtn")}</Typography>
        </Box>
        <Box mb={2} className="audioWave">
          <img className="play" src={Play} />
          <img className="wave" src={audioWave} />
          <img className="wave" src={audioWave} />
          <Typography>08:16</Typography>
        </Box>
        <Box className="likeCountBox">
          <Box>
            <Box sx={{ background: "#21A1F9" }} className="roundSimbol"></Box>
            <Typography>18.5% <br />{t("commonTxt.Like")}</Typography>
          </Box>
          <Box>
            <Box sx={{ background: "#FAA7E0" }} className="roundSimbol"></Box>
            <Typography>18.5% <br />{t("commonTxt.dislike")}</Typography>
          </Box>
          <Box>
            <Box sx={{ background: "#FEB273" }} className="roundSimbol"></Box>
            <Typography>18.5% <br />{t("commonTxt.confused")}</Typography>
          </Box>
        </Box>
      </Box>
    )
  }



  const TaskBox = ({ title, setState, url }) => {
    return (
      <Box className="chartSealBox">
        <Box className="viewTitle">
          <span>{title}</span>
          <Typography className="viewBtn" onClick={() => Navigate()}>{t("commonTxt.viewBtn")}</Typography>
        </Box>
        <Box className="sealRow">
          <Box>
            <Box sx={{ background: "#4F33D1" }} className="roundSimbol"></Box>
            <Typography>Brand Name 1</Typography>
          </Box>
          <Typography>4.8</Typography>
        </Box>
        <Box className="sealRow">
          <Box>
            <Box sx={{ background: "#FF4E72" }} className="roundSimbol"></Box>
            <Typography>Brand Name 2</Typography>
          </Box>
          <Typography>2.6</Typography>
        </Box>
        <Box className="sealRow">
          <Box>
            <Box sx={{ background: "#21A1F9" }} className="roundSimbol"></Box>
            <Typography>Brand Name 3</Typography>
          </Box>
          <Typography>3.7</Typography>
        </Box>
        <Box className="sealRow">
          <Box>
            <Box sx={{ background: "#FFBE28" }} className="roundSimbol"></Box>
            <Typography>Brand Name 4</Typography>
          </Box>
          <Typography>3.0</Typography>
        </Box>
        <Box className="sealRow">
          <Box>
            <Box sx={{ background: "#41AD0E" }} className="roundSimbol"></Box>
            <Typography>Brand Name 5</Typography>
          </Box>
          <Typography>1.5</Typography>
        </Box>
      </Box>
    )
  }


  return (
    <>
      <Box className="chartMainBox objReviewDChart">
        <Box className="chartCol">
          {VideoCard({ video: video2, title: "Road Trip Video" })}
          {ChartRecordBox({ title: "20km Trek Review", url: "/researchers/recap/audio-review" })}
          {ImgCard({ img: charImg2, title: "Trekking Essentials Review" })}
        </Box>

        <Box className="chartCol">
          {ImgCard({ img: charImg2, title: "Trekking Essentials Review" })}
          {VideoCard({ video: video2, title: "Road Trip Video" })}
          {TaskBox({ title: "Matrix Task Name", url: "/researchers/recap/matrix-task" })}
        </Box>

        <Box className="chartCol">
          {ImgCard({ img: charImg, title: "Trekking Essentials Review" })}
          {ChartRecordBox({ title: "20km Trek Review", url: "/researchers/recap/audio-review" })}
          {VideoCard({ video: video2, title: "Road Trip Video" })}
        </Box>
      </Box>
    </>
  )
}
