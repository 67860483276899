import React, { useState } from 'react';
import { Box, Typography } from "@mui/material";
import "./style.css";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";


//images
import img1 from "../../../Assets/Images/rProject1.png"
import img2 from "../../../Assets/Images/rProject2.png"
import img3 from "../../../Assets/Images/rProject3.png"
import img4 from "../../../Assets/Images/rProject4.png"
import backArrow from "../../../Assets/Images/noun-back.png";


//component
import SideMenu from "../../../Components/Participants/SideMenu";
import { sideHaumIcon } from "../../../Assets/Data";
import { AppBtn, AppBtnOutLine, PurplelBtn } from '../../../Components/AppTools/AppButton';
import { IBox } from "../../../Components/AppTools/AppTool"


import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export default function MatrixTask({ slideMenu, setSlideMenu, workspaceDrop }) {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const tableData = [
    {
      title: "Product Element A",
      img: img1
    },
    {
      title: "Product Element B",
      img: img2
    },
    {
      title: "Product Element C",
      img: img3
    },
    {
      title: "Product Element D",
      img: img4
    },
  ]

  const lineBar = 80;
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#4F33D1",
    },
  }));


  return (
    <>
      <Box className="ResearchersContainer imageActivityPage imageTask">
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon">
              {sideHaumIcon}
            </Box>
            <Box className="ontheDotNav">
              <PurplelBtn buttonText={t("commonTxt.previouspage")} />
              <AppBtn buttonText={t("commonTxt.nextPage")} />
            </Box>
          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer onthedot">
            <Box className="workspaceListBox">
              <Box mb={3} width="74px" onClick={() => Navigate("/participants/activities")} className="openBtn">
                <img src={backArrow} />
                <Typography className="neonBackText">Back</Typography>
              </Box>
              <Typography className='activeProgText'>{t("commonTxt.activityProgress")}</Typography>
              <Box className="lineBar">
                <Typography>{lineBar}%</Typography>
                <BorderLinearProgress variant="determinate" value={lineBar} />
              </Box>
              <Typography className="lineBarSubText">{t("onTheDro.TaskComp")}</Typography>

              <Box className="otdmainLabelBox">
                <Typography className='ActInSectionMainHeader'>{t("MatrixTask.pageHeader")}</Typography>
                <Box className="skipBtnBox">
                  <Typography>{t("onTheDro.SaveDraft")}</Typography>
                  <AppBtnOutLine buttonText="Skip Task" />
                </Box>
              </Box>
              <IBox text={t("MatrixTask.Ibox")} />
              <Typography className='imgUploadHeader'>{t("imageTask.viewBrand")}</Typography>
              <Typography className='resetText'>Reset all</Typography>

              <Box className="workListBox">
                <Box className="haderRow" minWidth={"1100px"}>
                  <Box className="Tabelsel TabelselItemStart" minWidth={"16%"}>
                    <Typography>{t("MatrixTask.name")}</Typography>
                  </Box>
                  <Box className="Tabelsel activitesSel" minWidth={"16%"}>
                    <Typography ml={2}>{t("MatrixTask.VeryImportant")}</Typography>
                  </Box>
                  <Box className="Tabelsel activitesSel" minWidth={"16%"}>
                    <Typography ml={2}>{t("MatrixTask.Important")}</Typography>
                  </Box>
                  <Box className="Tabelsel participantsSel" minWidth={"16%"}>
                    <Typography ml={1}>{t("MatrixTask.SomewhatImportant")}</Typography>
                  </Box>
                  <Box className="Tabelsel participantsSel" minWidth={"16%"}>
                    <Typography ml={1}>{t("MatrixTask.NotImportant")}</Typography>
                  </Box>
                  <Box className="Tabelsel participantsSel" minWidth={"16%"}>
                    <Typography ml={1}>{t("MatrixTask.NotallImportant")}</Typography>
                  </Box>
                </Box>
                {
                  tableData?.map((el, i) => (
                    <Box key={i} className="TabelRow mxTabelRow" minWidth={"1100px"}>
                      <Box className="mxTabelInnerRow">

                        <Box className="Tabelsel tabelimgBox" minWidth={"16%"}>
                          <Typography className='tabelMtTableHeader'>{el.title}</Typography>
                          <Box className="tabelBImg">
                            <img src={el.img} />
                          </Box>
                        </Box>
                        <Box className="Tabelsel TabelselItemCenter" minWidth={"16%"}>
                          <input
                            className="emailSettingInput emailSettingRadio"
                            type="radio"
                            name="projectState"
                            defaultChecked
                          />

                        </Box>
                        <Box className="Tabelsel TabelselItemCenter" minWidth={"16%"}>
                          <input
                            className="emailSettingInput emailSettingRadio"
                            type="radio"
                            name="projectState"
                            defaultChecked
                          />
                        </Box>
                        <Box className="Tabelsel TabelselItemCenter" minWidth={"16%"}>
                          <input
                            className="emailSettingInput emailSettingRadio"
                            type="radio"
                            name="projectState"
                            defaultChecked
                          />
                        </Box>
                        <Box className="Tabelsel TabelselItemCenter" minWidth={"16%"}>
                          <input
                            className="emailSettingInput emailSettingRadio"
                            type="radio"
                            name="projectState"
                            defaultChecked
                          />
                        </Box>
                        <Box className="Tabelsel TabelselItemCenter" minWidth={"16%"}>
                          <input
                            className="emailSettingInput emailSettingRadio"
                            type="radio"
                            name="projectState"
                            defaultChecked
                          />
                        </Box>
                      </Box>
                      <Box>
                        <Typography className='imgBottominputLabel'>{t("videoTask.responce2")}</Typography>
                        <textarea className='massageInput' placeholder="Enter your response here" />
                      </Box>
                    </Box>
                  ))
                }
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

    </>
  )
}
