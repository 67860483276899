import React, { useEffect, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./style.css";

import NoNotificationBg from "../../../Components/Researchers/NoNotifcationBg/index.jsx";
import axios from "../../../lib/axios.js";
//images
import refreshIcon from "../../../Assets/Images/refresh-ccw.png";
import arrow from "../../../Assets/Images/arrow.png";
import DummyUser from "../../../Assets/Images/user-dummy.png";
import { notification } from "../../../Assets/Data/index.js";
import { NotificationTypes } from "../../../Components/AppTools/AppTool/index.js";
import { sideHaumIcon } from "../../../Assets/Data/index.js";

import SideMenu from "../../../Components/Researchers/SideMenu/index.jsx";
import {
  calculateTimeDistance,
  capitalizeEachWord,
  renderUserLayout,
  viewImage,
} from "../../../lib/helpers.js";
import Pagination from "../../../Components/AppTools/Pagination/index.jsx";
import { useAppSelector } from "../../../lib/store/store.js";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants.js";
import { researcherAction } from "../../../Services/researcherActions.js";
// import { notification } from "../../../Assets/Data";

// import { sideHaumIcon } from "../../../Assets/Data";

// import SideMenu from "../../../Components/Researchers/SideMenu";
export default function Notification({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
}) {
  const [recentNotification, setRecentNotification] = useState([]);
  const [oldNotification, setOldNotification] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [newArrayData, setNewArrayData] = useState([]);
  const [statusDrop, setStatusDrop] = useState(false);
  const [statusDropVal, setStatusDropVal] = useState();

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  useEffect(() => {
    const recent = notification.filter((ele) => ele.time !== "4 Days Ago");
    setRecentNotification(recent);

    const old = notification.filter((ele) => ele.time === "4 Days Ago");
    setOldNotification(old);
  }, []);

  // Pagenation Logic

  function NotificationText({ notification }) {
    // Function to render notification
    const renderNotification = () => {
      const parts = notification.split("Equipment Exercise");
      return (
        <span>
          {parts[0]}
          {/* <span style={{ textDecoration: "underline" }}>
            Equipment Exercise
          </span> */}
          {parts[1]}
        </span>
      );
    };

    return <span>{renderNotification()}</span>;
  }

  useEffect(() => {
    printItemsByState(recentNotification, oldNotification, currentPage);
  }, [currentPage, recentNotification, oldNotification]);

  function printItemsByState(recentArray, oldArray, page) {
    const combinedArray = [...recentArray, ...oldArray];
    const startIndex = page * 6;
    const endIndex = (page + 1) * 6;
    const itemsToPrint = combinedArray.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  const handleNext = () => {
    setCurrentPage((prevPage) =>
      prevPage < Math.ceil(notification.length / 6) - 1
        ? prevPage + 1
        : prevPage
    );
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  // Pagenation Logic
  const defaultFilters = {
    search: "",
    sortBy: "created_at",
    sortOrder: -1,
    // workspace_id: workspaceState?.selectedWorkspace?._id
  };

  const [filterState, setFilterState] = useState({ ...defaultFilters });
  const [loading, setLoading] = useState(false);
  const [shouldAppendTitle, setShouldAppendTitle] = useState(false);
  const [renderCount, setRenderCount] = useState(0);
  const [reloadList, setReloadList] = useState(false);
  const [notifyCount, setNotifyCount] = useState(0);
  const [notifyTypes, setNotifyType] = useState([
    {
      name: "All",
      value: "all",
    },
    {
      name: "Read",
      value: "read",
    },
    {
      name: "Unread",
      value: "unread",
    },
  ]);
  const [newNotificationList, setNewNotificationList] = useState([]);
  const authState = useAppSelector((state) => state.auth);
  const [newPaginationData, setNewPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 1,
    totalPages: 0,
    totalItems: 0,
  });

  useEffect(() => {
    newNotification(1);
  }, [currentPage, filterState]);

  useEffect(() => {
    if (reloadList) {
      newNotification(1);
      setReloadList(false);
    }
  }, [reloadList]);

  useEffect(() => {
    console.log("authState", authState);
    // Reset the shouldAppendTitle state when new notifications are fetched
    setShouldAppendTitle(false);
  }, [newNotificationList]);

  const newNotification = async (currentPage = 1, filters = filterState) => {
    let url = `/super-admin/admin/notifications?page=${currentPage}`;
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        url += `&${key}=${filters[key]}`;
      }
    }

    const res = await axios.get(url);
    setLoading(false);
    if (res.status) {
      setNewNotificationList(res.data.data);
      // setting state for reportsPublication
      setNotifyCount(res.data.pagination.unreadCount);
      setNewPaginationData({
        currentPage: res.data.pagination.currentPage,
        itemsPerPage: res.data.pagination.itemsPerPage,
        totalPages: res.data.pagination.totalPages,
        totalItems: res.data.pagination.totalItems,
      });
    }
  };

  const onNewPageChange = (pageNum) => {
    newNotification(pageNum, filterState);
  };

  const handleFilterChange = (changedFilter) => {
    let newFilters = {
      ...filterState,
      ...changedFilter,
    };
    setFilterState(newFilters);
    newNotification(1, newFilters);
  };

  const check3DaysAge = (str) => {
    const inputDate = new Date(str);
    const currentDate = new Date();
    const timeDifference = currentDate - inputDate;
    const differenceInDays = timeDifference / (1000 * 3600 * 24);
    return differenceInDays >= 3;
  };

  const readAllMark = async () => {
    try {
      const notification_id = newNotificationList.flatMap((el) => el._id);
      const payload = {
        read_all: true,
        notification_id,
      };
      const response = await researcherAction(
        "/super-admin/admin/read-notifications",
        payload,
        authState.authToken
      );
      setLoading(false);
      if (response.success) {
        toast.success(response.message, TOAST_CONFIG);
        setReloadList(true);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };
  let titleAppended = false;

  const getChildren = () => {
    return (
      <Box className="researchersMainBox pageContainer">
        {newNotificationList.length <= 0 ? (
          <NoNotificationBg
            setFilterState={setFilterState}
            defaultFilters={defaultFilters}
          />
        ) : (
          <Box className="NotificationSection">
            <Grid container spacing={2}>
              <Grid item sm={12} md={6}>
                <Box className="dMsgHeader">
                  <Typography className="pageHeaderText">
                    {t("P_notification.Notification")}
                  </Typography>
                  <Box className="msgCountBox">
                    <p>{notifyCount}</p>
                  </Box>
                  <img
                    className="refreshIcon"
                    src={refreshIcon}
                    onClick={() => setReloadList(true)}
                  />
                </Box>
              </Grid>
              <Grid item sm={12} md={6}>
                <Box className="dMsgRight">
                  <a href="#" onClick={readAllMark}>
                    {t("directMessageAdmin.markAllRead")}
                  </a>

                  <Box sx={{ width: "150px", height: "40px" }}>
                    <NotificationTypes
                      label="Status"
                      setStatusDrop={setStatusDrop}
                      statusDrop={statusDrop}
                      setStatusDropVal={setStatusDropVal}
                      statusDropVal={statusDropVal}
                      itemAry={notifyTypes}
                      handleFilterChange={handleFilterChange}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Typography variant="h4" className="subHeaderText">
              {t("P_notification.RecentNotification")}
            </Typography>

            <Box className="notificationBox">
              {newNotificationList &&
                newNotificationList.map((el, index) => {
                  const shouldRenderTitle =
                    check3DaysAge(el.created_at) && !titleAppended;
                  if (shouldRenderTitle) {
                    titleAppended = true; // Mark title as appended
                  }

                  return (
                    <React.Fragment key={index}>
                      {shouldRenderTitle && (
                        <Typography variant="h4" className="subHeaderText">
                          3 Days Ago
                        </Typography>
                      )}
                      <Box className="notificationRow">
                        <Box className="ntfAvatar">
                          {el.type == "LOGIN" ? (
                            <img
                              src={
                                authState?.userProfile?.profile_pic ===
                                  "undefined" || !authState?.userProfile?.profile_pic
                                  ? DummyUser
                                  : viewImage(authState?.userProfile?.profile_pic)
                              }
                              alt="Avatar"
                            />
                          ) : (
                            <img src={DummyUser} alt="Avatar" />
                          )}
                        </Box>
                        <Box className="ntfTextBox">
                          <Box className="namDate">
                            <Typography className="userNamveNtf">
                              {el?.data?.name}
                            </Typography>
                            <Box>
                              {el.read_at == null && (
                                <Box className="Rdot"></Box>
                              )}
                              <Typography className="ntfDate">
                                {calculateTimeDistance(el.created_at)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </React.Fragment>
                  );
                })}
              <Pagination
                paginationData={newPaginationData}
                onPageChange={onNewPageChange}
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  return renderUserLayout(authState?.userType, null, getChildren(), "main");
}
