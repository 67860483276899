import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import AdminBG from "../../../Assets/Images/Add-files-cuate.png";
import { AppBtn } from "../../AppTools/AppButton";

const NoTaskBg = () => {
  return (
    <>
      <Box className="NoWorkBg">
        <Box className="adminWorkSBG">
          <img src={AdminBG} />
        </Box>
        <Box className="adminBGTextBox">
          <Typography className="adminSubHeaderText">
            It seems like you have not added any tasks in this activity yet.
            Please add to proceed further.
          </Typography>
          <AppBtn buttonText="Add Task" />
        </Box>
      </Box>
    </>
  );
};

export default NoTaskBg;
