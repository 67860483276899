import React, { useState, useEffect } from "react";
import "./style.css";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import WhiteArrow from "../../../Assets/Images/Wchevron-down.png";
import userHomeBanner from "../../../Assets/Images/userHomeBanner.png";
import Avatar from "../../../Assets/Images/UserHomeAvatar.png";
import consartImg from "../../../Assets/Images/consartImg.png";
import OptionIcon from "../../../Assets/Images/optionIcon.png";
import users from "../../../Assets/Images/usersB.png";
import star from "../../../Assets/Images/Star 2.png";
import redDot from "../../../Assets/Images/RedDot.png";
import BottomBanner from "../../../Assets/Images/Bbanner.png";
import hillMan from "../../../Assets/Images/hillman.png";
import user1 from "../../../Assets/Images/New folder/user1.png";
import user2 from "../../../Assets/Images/New folder/user2.png";
import user3 from "../../../Assets/Images/New folder/user3.png";
import user4 from "../../../Assets/Images/New folder/user4.png";
import user5 from "../../../Assets/Images/New folder/user5.png";
import OfflineDot from "../../../Assets/Images/offlineDot.png";
import sendIcon from "../../../Assets/Images/sendIcon.png";
import lockIcon from "../../../Assets/Images/lockIocn.png";
import horizontalDot from "../../../Assets/Images/more-horizontal.png";

import editIcon01 from "../../../Assets/Images/edit-2.png";
import { API_BASE_URL, TOAST_CONFIG } from "../../../env";

import { useAppSelector } from "../../../lib/store/store";
import {
  USER_TYPE_COLLABORATOR,
  USER_TYPE_RESEARCHER,
  USER_TYPE_OBSERVER,
  USER_TYPE_SUPERADMIN,
} from "../../../lib/constants";

import { formatDate, renderUserLayout, viewImage } from "../../../lib/helpers";

import {
  deletSvg,
  editIcon,
  settingIconBlack,
  addIconBlack,
  editIconBlack,
  previewIconBlack,
} from "../../../Assets/Data";

//components
import SideMenu from "../../../Components/Researchers/SideMenu";
import HorizontalBars from "../../../Components/AppTools/LineChart";
import { GoTop, AllDrop4 } from "../../../Components/AppTools/AppTool";
import { AppAddBtn } from "../../../Components/AppTools/AppButton";
import { AppBtn } from "../../../Components/AppTools/AppButton";

import AddCardPop from "../../../Components/Researchers/AddCardPop";
import AddCardPopPeoplePop from "../../../Components/Researchers/AddCardPeoplePop";
import PageSettingPop from "../../../Components/Researchers/PageSettingsPop";
import HomeEmptyPage from "../../../Components/Researchers/HomeEmptyPage";
import AddCardMessagePop from "../../../Components/Researchers/AddCardMessagePop";
import AddCardImagePop from "../../../Components/Researchers/AddCardImagePop";
import AddCardVideoPop from "../../../Components/Researchers/AddCardVideoPop";
import AddCardAudioPop from "../../../Components/Researchers/AddCardAudioPop";
import AddCardFilePop from "../../../Components/Researchers/AddCardFilePop";
import AddCardActivityPop from "../../../Components/Researchers/AddCardActivityPop";
import AddCardConversationPop from "../../../Components/Researchers/AddCardConversationPop";
import AddCardStatisticPop from "../../../Components/Researchers/AddCardStatisticPop";
import AddPagePop from "../../../Components/Researchers/AddPagePop";
import WorkspaceHomeEditView from "../../../Components/Researchers/WorkspaceHomeEditView";
import axios from "axios";

export default function WorkspaceHome() {
  const [researchers, setResearchers] = useState(false);
  const [researchersVal, setResearchersVal] = useState();
  const [memBerPop, setMemBerPop] = useState(false);
  const [memBerPopNav, setMemBerPopNav] = useState(0);
  const [selectedCard, setSelectedCard] = useState();
  const [addCardPopPeoplePop, setAddCardPopPeoplePop] = useState(false);
  const [workSHAddCardPop, setWorkSHAddCardPop] = useState(false);

  const data = false;
  const [projectOptionPop, setProjectOptionPop] = useState(false);

  const [addCardMessagePop, setAddCardMessagePop] = useState(false);
  const [addCardImagePop, setAddCardImagePop] = useState(false);
  const [addCardVideoPop, setAddCardVideoPop] = useState(false);
  const [addCardAudioPop, setaddCardAudioPop] = useState(false);
  const [addCardFilePop, setAddCardFilePop] = useState(false);
  const [addCardActivityPop, setaddCardActivityPop] = useState(false);
  const [addCardConversationPop, setAddCardConversationPop] = useState(false);
  const [addCardStatisticPop, setAddCardStatisticPop] = useState(false);
  const [duplicatePagePop, setDuplicatePagePop] = useState(false);
  const [deletePagePop, setDeletePagePop] = useState(false);
  const [allDrop3, setAllDrop3] = useState();
  const [allDropVal3, setAllDropVal3] = useState("Page Options");
  const [projectName, setProjectName] = useState("");

  const [pageCards, setPageCards] = useState([]);
  const [imagePageCards, setImagePageCards] = useState([]);

  const authState = useAppSelector((state) => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const initialState = {
    title: "",
    image: null,
    workspace_id: workspaceState?.selectedWorkspace?._id,
    project_id: workspaceState?.selectedProject?._id,
    participant_access: "",
    participant_groups: [],
    participant_ids: [],
    administrator_access: "",
    administrator_roles: [],
    administrator_ids: [],
    status: "active",
  };
  const [projectData, setProjectData] = useState(initialState);

  // InitialState for Cards
  const initialCardState = {
    workspace_id: workspaceState?.selectedWorkspace?._id,
    page_id: "66a216c09d56c23441241092",
    title: "",
    type: "message",
    is_show_title: "false",
    instructions: "",
    file_url: "",
    activity_id: [],
    conversation_display_type: "",
    conversation_id: [],
    appearance: {},
    peoples: "",
    data_set: "",
    participant_access: "",
    participant_groups: [],
    participant_ids: [],
    administrator_access: "",
    administrator_roles: [],
    administrator_ids: [],
    status: "active",
  };

  const [cardData, setCardData] = useState(initialCardState);

  const userRow = (img, title) => {
    return (
      <Box className="rOnlUserRow">
        <Box>
          <img src={img} />
        </Box>
        <Typography>{title}</Typography>
      </Box>
    );
  };

  const MemberPop = () => {
    return (
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: memBerPop ? "flex" : "none" }}
        className="workspacePop"
      >
        <Box className="memberPop">
          <Box className="membUserInfo">
            <img src={OfflineDot} className="OfflineDot" />
            <Box className="membAvatar">
              <img src={user3} />
            </Box>

            <Box className="membUserInfoIn">
              <Typography>Name03_P</Typography>
              <span>Online 3 days ago</span>
              <Box>
                <img src={sendIcon} />
                <img src={lockIcon} />
                <img src={editIcon01} />
              </Box>
            </Box>

            <Box className="membUserInfoLast">
              <Typography>{t("commonTxt.mega")}</Typography>
              <span>348 {t("commonTxt.points")}</span>
            </Box>
          </Box>

          <Box className="membTabBox">
            <Box className="membTabNav">
              <Box
                className={
                  memBerPopNav === 0
                    ? "membNavItem membNavItemActive"
                    : "membNavItem"
                }
                onClick={() => setMemBerPopNav(0)}
              >
                <Typography>{t("workspaceHome.CustomProfile")}</Typography>
              </Box>
              <Box
                className={
                  memBerPopNav === 1
                    ? "membNavItem membNavItemActive"
                    : "membNavItem"
                }
                onClick={() => setMemBerPopNav(1)}
              >
                <Typography>{t("workspaceHome.BasicDetails")}</Typography>
              </Box>
            </Box>
            <Box
              sx={{ display: memBerPopNav === 0 ? "flex" : "none" }}
              className="membTabCont"
            >
              <Box className="custProw">
                <Typography>{t("workspaceHome.age")}:</Typography>
                <span>30 years</span>
              </Box>
              <Box className="custProw">
                <Typography>{t("workspaceHome.Income")}:</Typography>
                <span>100,000$</span>
              </Box>
              <Box className="custProw">
                <Typography>{t("workspaceHome.Gender")}:</Typography>
                <span>Female</span>
              </Box>
              <Box className="custProw">
                <Typography>{t("workspaceHome.Female")}:</Typography>
                <span>9800269509</span>
              </Box>
            </Box>
            <Box
              sx={{ display: memBerPopNav === 1 ? "flex" : "none" }}
              className="membTabCont"
            >
              <Box className="custProw">
                <Typography>{t("workspaceHome.Name")}:</Typography>
                <span>03 {t("workspaceHome.Participant")}</span>
              </Box>
              <Box className="custProw">
                <Typography>{t("workspaceHome.Username")}:</Typography>
                <span>03_P (TP247023)</span>
              </Box>
              <Box className="custProw">
                <Typography>{t("workspaceHome.Email")}:</Typography>
                <span>p03#name@gmail.com</span>
              </Box>
              <Box className="custProw">
                <Typography>{t("workspaceHome.Joining")}:</Typography>
                <span>Aug 06, 2023</span>
              </Box>
              <Box className="custProw">
                <Typography>{t("workspaceHome.Groups")}:</Typography>
                <span></span>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const memberItem = (img, title) => {
    return (
      <Box
        onClick={() => {
          setMemBerPop(true);
          GoTop();
        }}
        className="memberRowItem"
      >
        <Box>
          <img src={img} />
        </Box>
        <Typography>{title}</Typography>
      </Box>
    );
  };

  const handelAddCardPopCoutinue = () => {
    console.log("click", selectedCard);
    setWorkSHAddCardPop(false);
    if (selectedCard === "People") {
      setAddCardPopPeoplePop(true);
    } else if (selectedCard === "Message") {
      setAddCardMessagePop(true);
    } else if (selectedCard === "Image") {
      setAddCardImagePop(true);
    } else if (selectedCard === "Videos") {
      setAddCardVideoPop(true);
    } else if (selectedCard === "Audios") {
      setaddCardAudioPop(true);
    } else if (selectedCard === "File") {
      setAddCardFilePop(true);
    } else if (selectedCard === "Activities") {
      setaddCardActivityPop(true);
    } else if (selectedCard === "Conversations") {
      setAddCardConversationPop(true);
    } else if (selectedCard === "Statistics") {
      setAddCardStatisticPop(true);
    }
  };
  const handelAddCardPopPeopleBack = () => {
    setWorkSHAddCardPop(true);
    setAddCardPopPeoplePop(false);
    setAddCardMessagePop(false);
    setAddCardImagePop(false);
    setAddCardVideoPop(false);
    setaddCardAudioPop(false);
    setAddCardFilePop(false);
    setaddCardActivityPop(false);
    setAddCardConversationPop(false);
    setAddCardStatisticPop(false);
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setMemBerPop(false);
    }
  };

  useEffect(() => {
    if (memBerPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [memBerPop]);

  useEffect(() => {
    setProjectName(workspaceState?.selectedProject?.name);
    getPageCards();
  }, []);

  useEffect(() => {
    const ImageCards = pageCards.filter((card) => card.type === "image");
    console.log("Filtered Image Cards:", ImageCards);
    setImagePageCards(ImageCards);
  }, [pageCards]);

  const getPageCards = async () => {
    try {
      const response = await axios.get(API_BASE_URL + `/researchers/cards`, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + authState.authToken,
        },
      });

      if (response.data.success) {
        const cards = response.data?.data?.data;
        setPageCards(cards);
        console.log("The value of cards from API:", cards);
      }
    } catch (err) {
      console.log(err);
    }
  };

  console.log("========Cards======:", pageCards);
  console.log("========ImageCards======:", imagePageCards);

  const getActionBtns = () => {
    return (
      <>
        {
          authState?.wsUserType == USER_TYPE_RESEARCHER ||
          authState?.wsUserType == USER_TYPE_COLLABORATOR ||
          authState?.userType == USER_TYPE_SUPERADMIN ? (
            <Box className="AppNavBtnBox">
              <Box sx={{ width: "30%", minWidth: "196px" }}>
                {" "}
                <AllDrop4
                  drop={allDrop3}
                  setDrop={setAllDrop3}
                  dropVal={allDropVal3}
                  serDropVal={setAllDropVal3}
                />
              </Box>
              <AppAddBtn
                buttonText={t("workspaceHome.addCard")}
                onClick={() => setWorkSHAddCardPop(true)}
              />
            </Box>
          ) : (
            ""
          )
          // (
          //   <Box
          //     sx={{
          //       display: "flex",
          //       flexDirection: "row",
          //       alignItems: "center",
          //       position: "relative",
          //     }}
          //   >
          //     <AppBtn buttonText="Workspace Administration" />
          //     <Box
          //       onClick={() => setAllDrop3(!allDrop3)}
          //       className="workspaceItemOption"
          //     >
          //       <img src={horizontalDot} />

          //       <Box
          //         sx={{ display: allDrop3 ? "flex" : "none" }}
          //         className="workspaceItemNavPop"
          //       >
          //         <Box
          //           onClick={() => setWorkSHAddCardPop(true)}
          //           className="statusPopItem"
          //         >
          //           <Typography className="GsettingIcon">
          //             {settingIconBlack} Add Card
          //           </Typography>
          //         </Box>

          //         <Box
          //           onClick={() => setAllDropVal3("Page Settings")}
          //           className="statusPopItem"
          //         >
          //           <Typography className="GsettingIcon">
          //             {settingIconBlack} Page Settings
          //           </Typography>
          //         </Box>
          //         <Box
          //           onClick={() => setAllDropVal3("Add Page")}
          //           className="statusPopItem"
          //         >
          //           <Typography className="GsettingIcon">
          //             {addIconBlack} Add Page
          //           </Typography>
          //         </Box>
          //         <Box
          //           onClick={() => setAllDropVal3("Edit Page")}
          //           className="statusPopItem"
          //         >
          //           <Typography className="GsettingIcon">
          //             {editIconBlack} Edit Page
          //           </Typography>
          //         </Box>
          //         <Box
          //           onClick={() => setAllDropVal3("Preview")}
          //           className="statusPopItem"
          //         >
          //           <Typography className="GsettingIcon">
          //             {previewIconBlack} Preview
          //           </Typography>
          //         </Box>
          //         <Box
          //           onClick={() => setAllDropVal3("Action Log")}
          //           className="statusPopItem"
          //         >
          //           <Typography className="GsettingIcon">
          //             {previewIconBlack} Action Log
          //           </Typography>
          //         </Box>
          //       </Box>
          //     </Box>
          //   </Box>
          // )
        }
      </>
    );
  };
  const getChildren = () => {
    return (
      <>
        <Box className="researchersMainBox pageContainer">
          {data ? (
            <HomeEmptyPage />
          ) : allDropVal3 === "Edit Page" ? (
            <WorkspaceHomeEditView
              setWorkSHAddCardPop={setWorkSHAddCardPop}
              workSHAddCardPop={workSHAddCardPop}
              allDropVal3={allDropVal3}
              setAllDropVal3={setAllDropVal3}
            />
          ) : (
            <Box className="userHomeContainer reworkspace">
              {/* Pops */}
              <MemberPop />
              <AddCardPop
                setWorkSHAddCardPop={setWorkSHAddCardPop}
                workSHAddCardPop={workSHAddCardPop}
                setSelectedCard={setSelectedCard}
                selectedCard={selectedCard}
                handelAddCardPopCoutinue={handelAddCardPopCoutinue}
              />
              <AddCardPopPeoplePop
                addCardPopPeoplePop={addCardPopPeoplePop}
                setAddCardPopPeoplePop={setAddCardPopPeoplePop}
                handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
              />
              <PageSettingPop
                allDropVal3={allDropVal3}
                setAllDropVal3={setAllDropVal3}
                setDuplicatePagePop={setDuplicatePagePop}
                setDeletePagePop={setDeletePagePop}
                deletePagePop={deletePagePop}
                duplicatePagePop={duplicatePagePop}
              />
              <AddCardMessagePop
                addCardMessagePop={addCardMessagePop}
                setAddCardMessagePop={setAddCardMessagePop}
                handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
              />
              <AddCardImagePop
                addCardImagePop={addCardImagePop}
                setAddCardImagePop={setAddCardImagePop}
                cardData={cardData}
                setCardData={setCardData}
                handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
                initialCardState={initialCardState}
              />
              <AddCardVideoPop
                addCardVideoPop={addCardVideoPop}
                setAddCardVideoPop={setAddCardVideoPop}
                handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
              />
              <AddCardAudioPop
                addCardAudioPop={addCardAudioPop}
                setaddCardAudioPop={setaddCardAudioPop}
                handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
              />
              <AddCardFilePop
                addCardFilePop={addCardFilePop}
                setAddCardFilePop={setAddCardFilePop}
                handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
              />
              <AddCardActivityPop
                addCardActivityPop={addCardActivityPop}
                setaddCardActivityPop={setaddCardActivityPop}
                handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
              />
              <AddCardConversationPop
                addCardConversationPop={addCardConversationPop}
                setAddCardConversationPop={setAddCardConversationPop}
                handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
              />
              <AddCardStatisticPop
                addCardStatisticPop={addCardStatisticPop}
                setAddCardStatisticPop={setAddCardStatisticPop}
                handelAddCardPopPeopleBack={handelAddCardPopPeopleBack}
              />
              <AddPagePop
                allDropVal3={allDropVal3}
                setAllDropVal3={setAllDropVal3}
                projectData={projectData}
                setProjectData={setProjectData}
                initialState={initialState}
                projectName={projectName}
              />
              {(authState?.wsUserType == USER_TYPE_RESEARCHER ||
                authState?.wsUserType == USER_TYPE_COLLABORATOR ||
                authState?.userType == USER_TYPE_SUPERADMIN) && (
                <Box className="uhwelcomeBanner">
                  <img src={userHomeBanner} />
                  <Box
                    sx={{ position: "relative" }}
                    onClick={() => setResearchers(!researchers)}
                  >
                    <Typography className="uhwelbannerText" mr={1}>
                      {researchersVal || "Welcome Researchers!"}
                    </Typography>
                    <img src={WhiteArrow} />

                    <Box
                      sx={{ display: researchers ? "flex" : "none" }}
                      className="researchPop"
                    >
                      <Box
                        className="researchPopItem"
                        onClick={() =>
                          setResearchersVal("Welcome Researchers!")
                        }
                      >
                        <Typography className="researchPopItemText">
                          Welcome Researchers!
                        </Typography>
                      </Box>
                      <Box
                        className="researchPopItem"
                        onClick={() =>
                          setResearchersVal("Collaborators Corner")
                        }
                      >
                        <Typography className="researchPopItemText">
                          Collaborators Corner
                        </Typography>
                      </Box>
                      <Box
                        className="researchPopItem"
                        onClick={() => setResearchersVal("Observers Corner")}
                      >
                        <Typography className="researchPopItemText">
                          Observers Corner
                        </Typography>
                      </Box>
                      <Box
                        className="researchPopItem"
                        onClick={() =>
                          setResearchersVal("Welcome Participants")
                        }
                      >
                        <Typography className="researchPopItemText">
                          Welcome Participants
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
              <Box className="userHMBox3">
                <Box className="userHMBoxItem userhAvatarBox homeCard">
                  <Box className="AvartimgBox">
                    <img src={Avatar} />
                  </Box>
                  <Typography className="userHMText">
                    Welcome User Name
                  </Typography>
                  <Typography className="userHNText">
                    Lorem ipsum dolor sit amet consect etur. Ac quisque
                    tristique dignissim non. Erat id ipsum sem non auctor. Elit
                    non massa quis faucibus vel don sapien felis.{" "}
                  </Typography>
                </Box>
                {authState?.wsUserType == USER_TYPE_RESEARCHER ||
                authState?.wsUserType == USER_TYPE_COLLABORATOR ? (
                  <Box className="userHMBoxItem lineChart">
                    <HorizontalBars />
                  </Box>
                ) : (
                  <Box className="userHMBoxItem userhAvatarBox homeCard">
                    <Typography className="ConversationsBoxHText">
                      Lorem ipsum dolor sit amet consectetur.
                    </Typography>
                    <Typography className="userHNText">
                      Lorem ipsum dolor sit amet consec tetur. Ac quisque
                      tristique dignissim non. Erat id ipsum sem non auctor.
                      Elit non massa quis faucibus vel donec sapien felis.
                      Tellus integer et vulputate netus sed. Ac quisque
                      tristique dignissim non. Erat id ipsum sem non auctor.
                      Elit non massa quis faucibus vel donec sapien felis.{" "}
                    </Typography>
                  </Box>
                )}

                <Box className="userHMBoxItem userHConsart">
                  <Box className="conImgBox">
                    <img src={consartImg} />
                  </Box>
                  <Box className="userHCTextBox">
                    <Typography sx={{ fontSize: "20px" }}>Welcome</Typography>
                    <img src={OptionIcon} />
                  </Box>
                  <Box className="userHCTextBox">
                    <Box>
                      <Typography sx={{ fontSize: "16px", color: "#98A2B3" }}>
                        Aug 02 - Aug 03
                      </Typography>
                      <Box
                        sx={{ background: "#12B76A" }}
                        className="ActiveStatus"
                      ></Box>
                    </Box>
                    <Box>
                      <img src={users} />
                      <Typography
                        ml={1}
                        sx={{ fontSize: "20px", color: "#98A2B3" }}
                      >
                        11
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="userHMBox3">
                <Box className="userHMBoxItem ConversationsBox">
                  <Typography className="ConversationsBoxHText">
                    Conversations
                  </Typography>

                  <Box className="convChatItem">
                    <Box mb={0.8}>
                      <Typography>Recent Trekking Outings</Typography>
                      <Box className="iconBox">
                        <img
                          style={{ width: "20px", height: "20px" }}
                          src={star}
                        />
                        <img
                          style={{ width: "12px", height: "12px" }}
                          src={redDot}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Typography className="convSubText">
                        (Outdoor Tips)
                      </Typography>
                      <Box className="viewBtn">
                        <Typography>View</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="convChatItem">
                    <Box mb={0.8}>
                      <Typography>Your Trekking must haves</Typography>
                      <Box className="iconBox">
                        <img
                          style={{ width: "20px", height: "20px" }}
                          src={star}
                        />
                        <img
                          style={{ width: "12px", height: "12px" }}
                          src={redDot}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Typography className="convSubText">
                        (Equipment Zone)
                      </Typography>
                      <Box className="viewBtn">
                        <Typography>View</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="convChatItem">
                    <Box mb={0.8}>
                      <Typography>Meal Hacks</Typography>
                      <Box className="iconBox">
                        <img
                          style={{ width: "20px", height: "20px" }}
                          src={star}
                        />
                        <img
                          style={{ width: "12px", height: "12px" }}
                          src={redDot}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Typography className="convSubText">
                        (Outdoor Tips)
                      </Typography>
                      <Box className="viewBtn">
                        <Typography>View</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box className="bottomBannerBox">
                  <img src={BottomBanner} />
                </Box>
              </Box>
              <Box className="userHMBox3">
                <Box className="userHMBoxItem keyDataBox">
                  <Typography className="ConversationsBoxHText">
                    Key Dates
                  </Typography>
                  <Box className="keyDataRow">
                    <Typography>Monday</Typography>
                    <span>Introduction Task</span>
                  </Box>
                  <Box className="keyDataRow">
                    <Typography>Tuesday</Typography>
                    <span>Introduction to Task</span>
                  </Box>
                  <Box className="keyDataRow">
                    <Typography>Wednesday</Typography>
                    <span>Task experience</span>
                  </Box>
                  <Box className="keyDataRow">
                    <Typography>Thursday</Typography>
                    <span>Task experience</span>
                  </Box>
                  <Box className="keyDataRow">
                    <Typography>Friday</Typography>
                    <span>Introduction to Task</span>
                  </Box>
                  <Box className="keyDataRow">
                    <Typography>Saturday</Typography>
                    <span>Task experience</span>
                  </Box>
                  <Box className="keyDataRow">
                    <Typography>Sunday</Typography>
                    <span>Introduction to Task</span>
                  </Box>
                </Box>

                <Box className="userHMBoxItem">
                  <img src={hillMan} className="hillManImg" />
                </Box>
                <Box className="userHMBoxItem RecentlyOnlineBox">
                  <Typography className="ConversationsBoxHText">
                    Recently Online
                  </Typography>
                  {userRow(user1, "Participant01 FullName")}
                  {userRow(user2, "Participant02 FullName")}
                  {userRow(user3, "Participant03 FullName")}
                  {userRow(user4, "Participant04 FullName")}
                  {userRow(user5, "Participant05 FullName")}
                  <Box className="pageNum">
                    <Box className="pageNumItem pageNumActive">
                      <p>1</p>
                    </Box>
                    <Box className="pageNumItem">
                      <p>2</p>
                    </Box>
                    <Box className="pageNumItem">
                      <p>3</p>
                    </Box>
                    <Box className="pageNumItem">
                      <p>4</p>
                    </Box>
                    <Box className="pageNumItem">
                      <p>5</p>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="userHMBox3 userHMBox4">
                <Box className="userHMBoxItem memberBox1">
                  <Typography className="ConversationsBoxHText">
                    Tera Members
                  </Typography>
                  <Box mb={2} className="memberRow">
                    {memberItem(user1, "Name01")}
                    {memberItem(user2, "Name02")}
                    {memberItem(user3, "Name03")}
                  </Box>
                  <Box className="memberRow">
                    {memberItem(user4, "Name04")}
                    {memberItem(user5, "Name05")}
                  </Box>
                </Box>

                <Box className="userHMBoxItem memberBox2">
                  <Typography className="ConversationsBoxHText">
                    Giga Members
                  </Typography>
                  <Box mb={2} className="memberRow">
                    {memberItem(user1, "Name01")}
                    {memberItem(user2, "Name02")}
                    {memberItem(user3, "Name03")}
                  </Box>
                  <Box className="memberRow">
                    {memberItem(user4, "Name04")}
                    {memberItem(user5, "Name05")}
                  </Box>
                </Box>

                <Box className="userHMBoxItem memberBox3">
                  <Typography className="ConversationsBoxHText">
                    Mega Members
                  </Typography>
                  <Box mb={2} className="memberRow">
                    {memberItem(user1, "Name01")}
                    {memberItem(user2, "Name02")}
                    {memberItem(user3, "Name03")}
                  </Box>
                  <Box className="memberRow">
                    {memberItem(user4, "Name04")}
                    {memberItem(user5, "Name05")}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </>
    );
  };

  return renderUserLayout(
    authState?.userType,
    getActionBtns(),
    getChildren(),
    "main"
  );
}
