import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import IIcon from "../../../Assets/Images/IIcon.png";

//components
import { AppBtn, AppBtnOutLine } from "../../../Components/AppTools/AppButton";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../../../env";
import axios from "../../../lib/axios";

export default function DeleteWSGroupPop({
  deleteWSGroupPop,
  setDeleteWSGroupPop,
  getGroupData,
  getEnhancedGroupData
}) {
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setDeleteWSGroupPop(false);
    }
  };
  
  useEffect(() => {
    if (deleteWSGroupPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [deleteWSGroupPop]);

const deleteEnhancedGroup = () =>{
  axios.delete(API_BASE_URL + `/participant/groups/${deleteWSGroupPop?.groupId}`).then((res)=>{
    if(res.success){
      toast.success(res.message)
      getGroupData();
      getEnhancedGroupData();
      setDeleteWSGroupPop(false)
    }else{
      toast.error(res.message)
    }
  }).catch((err)=>{
    toast.error(err.message)
  })
}

  return (
    <>
      <Box
        onClick={handelPopClose}
        id="popBackdrop"
        sx={{ display: deleteWSGroupPop ? "flex" : "none" }}
        className="workspaceDeletePopContainer"
      >
        <Box className="workspaceDeleteBox workspaceDeletePopTextAlign">
          <Typography className="deletePopHeader">Delete Group ?</Typography>

          <Box className="deleteTextBox">
            <Typography>
              Are you sure you want to delete {deleteWSGroupPop?.groupName} from
              Terapage? Kindly note that this action cannot be un-done, any
              Group once deleted cannot be retrieved again.
            </Typography>
          </Box>
          <Box className="deleteBtnBox">
            <AppBtnOutLine
              onClick={() => setDeleteWSGroupPop(false)}
              buttonText="Cancel"
            />
            <AppBtn buttonText="Delete" onClick={deleteEnhancedGroup}/>
          </Box>
        </Box>
      </Box>
    </>
  );
}
