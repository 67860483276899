import React from "react";
import { Box, Typography } from "@mui/material";

//images
import autoTham from "../../../Assets/Images/audioTham.png";
import audioWave from "../../../Assets/Images/audioWav.png";
import Play from "../../../Assets/Images/play.png";

import mp3 from "../../../Assets/Images/Videos/CM_01 Friday Vibes.mp3";

// class MusicList extends Component {
//   initialTracks = {
//     tracks: [
//       { source: track1, title: "Zimt" },
//       { source: track2, title: "Ingwer" }
//     ]
//   };
//   audioMotion = undefined;

//   constructor(props) {
//     super(props);
//     this.audioRef = createRef();
//     this.state = {
//       mediaElt: null,
//       playing: false,
//       tracks: [
//         { source: track1, title: "Zimt" },
//         { source: track2, title: "Ingwer" }
//       ],
//       track: this.initialTracks.tracks[0]
//     };
//   }

// <div
// style={{ color: "red" }}
// onClick={() => {
//   this.setState({ playing: !this.state.playing });
// }}
// >
// Click me to trigger play: {!this.state.playing ? "▶" : "■"}
// </div>

// <ReactWaves
// audioFile={this.state.track.source} // maps the audio to the element
// className={"react-waves"}
// options={{
//   backend: "MediaElement", // maps the waveform to an audio element
//   normalize: true,
//   cursorWidth: 0,
//   mediaType: "audio",
//   hideScrollbar: true,
//   responsive: true
// }}
// zoom={1}
// playing={this.state.playing}
// />

export default function Audio() {
  const audioCard = ({ img, title, time }) => {
    return (
      <Box className="audioCard">
        <Box className="autioInfo">
          <Box className="audioIcon">
            <img src={autoTham} />
          </Box>
          <Typography>{title}</Typography>
        </Box>
        <Box className="audioWave">
          <img className="play" src={Play} />
          <img className="wave" src={audioWave} />
          <img className="wave" src={audioWave} />
          <Typography>08:16</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box className="audioBox">
        {audioCard({ title: "Trekking Essentials Review" })}
        {audioCard({ title: "Hiking mountains Review" })}
        {audioCard({ title: "Sit amet dolor Review" })}
        {audioCard({ title: "Sit amet dolor Review" })}
        {audioCard({ title: "Sit amet dolor Review" })}
        {audioCard({ title: "Sit amet dolor Review" })}
        {audioCard({ title: "Sit amet dolor Review" })}
      </Box>
    </>
  );
}
