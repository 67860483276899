import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./style.css";

import option2Line from "../../../Assets/Images/Option2LIcon.png";
import lockIcon from "../../../Assets/Images/lock.png";
import unlockIcon from "../../../Assets/Images/unlock.png";
import iIcon from "../../../Assets/Images/IIcon.png";

//components
import { AppBtn, AppBtnOutLine } from "../../../Components/AppTools/AppButton";
import { SearchBar, AntSwitch } from "../../AppTools/AppTool";
import { API_BASE_URL } from "../../../env";
import axios from "../../../lib/axios";

export default function RearrangeTaskPop({
  allDropVal4,
  setAllDropVal4,
  allTasksList,
  setAllTasksList,
  workspaceState,
  taskSequence,
  setTaskSequence, 
  activityId
}) {
  const [lockTask, setLockTask] = useState([]);
  const [draggingIndex, setDraggingIndex] = useState(null);
  const [dragSequence, setDragSequence] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const [updatedSequenceState, setUpdatedSequenceState] = useState("");

  const defaultFilters1 = {
    search: "",
    status: "",
  };

  const [taskFilterState, setTaskFilterState] = useState({
    ...defaultFilters1,
  });

  const handleFilterChange1 = (changedFilter1) => {
    let newFilters1 = {
      ...taskFilterState,
      ...changedFilter1,
    };
    setTaskFilterState(newFilters1);
    getTasks2(1, newFilters1);
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setAllDropVal4("Activity Options");
    }
  };

  const handleLock = (taskId) => {
    const imgPath = document.getElementById(taskId);
    if (imgPath.src.includes(lockIcon)) {
      imgPath.src = unlockIcon;
    } else {
      imgPath.src = lockIcon;
    }
    if (lockTask.includes(taskId)) {
      let index1 = lockTask.indexOf(taskId);
      lockTask.splice(index1, 1);
      return;
    } else {
      lockTask.push(taskId);
      return;
    }
  };

  const getTasks2 = async (curPage, filters = taskFilterState) => {
    return await axios
      .get(
        API_BASE_URL +
          `/researchers/tasks?page=${curPage}&search=${filters.search}&workspace_id=${workspaceState?.selectedWorkspace?._id}&status=${filters.status}&project_id=${workspaceState?.selectedProject?._id}&activity_id=${activityId}`
      )
      .then((res) => {
        if (res.status === 200) {
          setAllTasksList(res.data.data);
          const updatedArray = res.data.data.map((item) => ({
            task_id: item._id,
            index: item.sequence_index,
          }));
          setTaskSequence({ sequence: updatedArray });
          console.log("Task Sequenced Values", taskSequence);
        }
      });
  };

  const resetAll = () => {
    const images = document.querySelectorAll(".lockIcon img");
    images.forEach((img) => {
      img.src = unlockIcon;
    });
    setAllDropVal4("Activity Options");
  };

  const handleDragStart = (index, sequence) => {
    setDraggingIndex(index);
    setDragSequence(sequence);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (index) => {
    if (index === draggingIndex) {
      return; // No need to update if the item is dropped in the same position
    }

    const updatedItems = [...allTasksList];
    const [movedItem] = updatedItems.splice(draggingIndex, 1);
    updatedItems.splice(index, 0, movedItem);

    // Update the sequence_index based on the new order
    const updatedSequence = updatedItems.map((item, newIndex) => ({
      ...item,
      sequence_index: newIndex + 1, // Assuming sequence_index starts from 1
    }));

    setAllTasksList(updatedSequence);
    setDraggingIndex(null);

    setUpdatedSequenceState(updatedSequence);
  };

  const updateTaskSequence = async (updatedSequence) => {
    try {
      const sequence = updatedSequence.map((item) => ({
        task_id: item._id, // or item.task_id depending on your data structure
        index: item.sequence_index, // Ensure "index" is correctly mapped
      }));

      const response = await axios.post(
        API_BASE_URL + `/researchers/tasks/update-sequence`,
        {
          sequence: sequence, // The API expects "sequence" as the key
        }
      );

      if (response.status === 200) {
        console.log("Task sequence updated successfully:", response.data);
      }
    } catch (error) {
      console.error("Error updating task sequence:", error);
    }
  };

  const reaarangeTask = () => {
    setLoading(true);
    updateTaskSequence(updatedSequenceState);
  };

  useEffect(() => {
    if (allDropVal4 === "Rearrange Tasks") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [allDropVal4]);

  return (
    <Box
      onClick={handelPopClose}
      sx={{ display: allDropVal4 === "Rearrange Tasks" ? "flex" : "none" }}
      className="workspacePop rearrangeTask"
      id="popBackdrop"
    >
      <Box
        sx={{ display: allDropVal4 === "Rearrange Tasks" ? "flex" : "none" }}
        className="workspaceContaint"
      >
        <Typography className="companyHeader">
          {t("rearrangeTaskPop.rearrangeTaskPopHead")} (
          {allTasksList?.length > 0 && allTasksList?.length}{" "}
          {t("projectParticipantReport.tblTasks")})
        </Typography>
        <Box className="arrangSearchBox">
          <SearchBar
            placeholder={t("rearrangeTaskPop.searchTasksPlaceholder")}
            handleFilterChange={handleFilterChange1}
            val={taskFilterState}
          />
        </Box>

        {allTasksList?.length > 0 &&
          allTasksList?.map((el, i) => (
            <Box
              key={i}
              className="arrengItem"
              draggable
              onDragStart={() => handleDragStart(i, el?.sequence_index)}
              onDragOver={handleDragOver}
              onDrop={() => handleDrop(i, el?.sequence_index)}
            >
              <Typography>0{i + 1}.</Typography>
              <img className="opt2l" src={option2Line} />
              <Typography>{el.name}</Typography>
              <Box className="lockIcon" onClick={() => handleLock(el?._id)}>
                {lockTask.includes(el?._id) ? (
                  <img src={lockIcon} id={el?._id} />
                ) : (
                  <img src={unlockIcon} id={el?._id} />
                )}
              </Box>
            </Box>
          ))}

        <Box className="airadioSwitchBox">
          <AntSwitch
            defaultChecked
            inputProps={{ "aria-label": "ant design" }}
          />
          <Typography className="switchText">
            {t("rearrangeTaskPop.rearrangeTaskPopSwitch")}
          </Typography>
        </Box>

        <Box className="iBox">
          <img src={iIcon} />
          <Typography>
            {t("rearrangeTaskPop.rearrangeTaskPopNotify")}
          </Typography>
        </Box>
        <Box className="workPupBtnBox">
          <AppBtnOutLine
            buttonText={t("commonTxt.cancelBtn")}
            onClick={() => resetAll()}
          />
          <AppBtn
            buttonText={t("commonTxt.saveChangesBtn")}
            onClick={() => reaarangeTask()}
          />
        </Box>
      </Box>
    </Box>
  );
}
