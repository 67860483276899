import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import "./style.css";
import { sideHaumIcon } from "../../../Assets/Data";

//component
import DeleteAccountPop from '../../../Components/User/DeleteAccountPop';
import { GoTop } from '../../../Components/AppTools/AppTool';
import PASideMenu from "../../../Components/Participants/AccountSettingSideMenu/";


export default function SafetyPrivacy({ slideMenu, setSlideMenu, workspaceDrop, setWorkSpaceDrop }) {
  const [deleteAccountPop, setDeleteAccountPop] = useState(false);

  return (
    <>
      <Box className="ResearchersContainer ">
        {/* side menu */}
        <PASideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer">
            <Box className="EmailNContainer">
              <DeleteAccountPop deleteAccountPop={deleteAccountPop} setDeleteAccountPop={setDeleteAccountPop} />
              <Box className="safetyPrivacyBtn">
                <Typography className='safetyPrivacyBtnName'>Download Your Data</Typography>
              </Box>
              <Typography className='safetyPrivacyBtnText'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas luctus porttitor enim. Quisque quis convallis sapien. Integer vel tempor lacus. Vestibulum a justo a lorem mollis tempor in eget dolor. Aliquam sit amet neque vel eros scelerisque suscipit ornare in arcu. Quisque rutrum purus vitae porta mollis</Typography>

              <Box
                onClick={() => {
                  setDeleteAccountPop(true);
                  GoTop();
                }}
                className="safetyPrivacyBtn">
                <Typography className='safetyPrivacyBtnName'>Delete Your Account</Typography>
              </Box>
              <Typography className='safetyPrivacyBtnText'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas luctus porttitor enim. Quisque quis convallis sapien. Integer vel tempor lacus. Vestibulum a justo a lorem mollis tempor in eget dolor. Aliquam sit amet neque vel eros scelerisque suscipit ornare in arcu. Quisque rutrum purus vitae porta mollis</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>

  )
}
