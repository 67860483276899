import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import SideMenu from "../../WSAdministration/SideMenu";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../../lib/axios";
import DummyUser from "../../../Assets/Images/user-dummy.png";
import "./style.css";

//images
import backArrow from "../../../Assets/Images/noun-back.png";
import logIcon from "../../../Assets/Images/Union.png";
import shareIcon from "../../../Assets/Images/shareIcon.png";
import VisibleIcon from "../../../Assets/Images/VisibleIcon.png";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import optionIcon from "../../../Assets/Images/optionIcon.png";
import videoCameraIcon from "../../../Assets/Images/videoCameraIcon.png";
import CameraIcon from "../../../Assets/Images/bi_camera.png";

import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import personalGolBgImg from "../../../Assets/Images/personal goals checklist-amico (1) 1.png";
//data
import { sideHaumIcon } from "../../../Assets/Data";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

//components
import {
  OptionDrop,
  AntSwitch,
  RenderCustomField,
} from "../../AppTools/AppTool";
import { AppBtnOutLine, AppBtn } from "../../AppTools/AppButton";
import { useAppDispatch, useAppSelector } from "../../../lib/store/store";
import { API_BASE_URL, STORAGE_BASE_PATH } from "../../../env";
import { getCountries } from "../../../Services/master";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import {
  supportLoginApi,
  updateParticipantApi,
  uploadUserDirectoryImage,
} from "../../../Services/adminActions";
import {
  formatDate,
  renderUserLayout,
  urlToBase64,
  viewImage,
} from "../../../lib/helpers";
import ReferenceDateUsingValue from "../../AppTools/DatePicker";
import ReferenceDateExplicitTimePicker from "../../AppTools/TimePicker";
import {
  setAuthState,
  setIsSupport,
  setUserProfile,
} from "../../../lib/store/features/authSlice";
import { userProfile } from "../../../Services/auth";
import AdminLayout from "../../../Pages/Admin/partials/AdminLayout";
import ResearcherLayout from "../../../Pages/Researchers/partials/ResearcherLayout";
import SupportLoginParticipantPop from "../../Admin/SupportLoginPop";

export default function EditParticipantResearcher({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
  participantsSubMenu,
  setParticipantsSubMenu,
  configurationSubMenu,
  setConfigurationSubMenu,
}) {
  let dispatch = useAppDispatch();
  let authState = useAppSelector((state) => state.auth);
  console.log("authState", authState);
  const workspaceState = useAppSelector((state) => state.workspace);

  const [optionDrop, setOptionDrop] = useState(false);
  const [optionDropVal, setOptionDropVal] = useState("Options");
  const [redio, setRedio] = useState(0);
  const [pwdVisible, setPwdVisible] = useState(false);
  const [cpwdVisible, setCpwdVisible] = useState(false);
  const [countryDrop, setCountryDrop] = useState(false);
  const [timeZoneDrop, setTimeZoneDrop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [timeZoneList, setTimezoneList] = useState([]);
  const [groupsListData, setGroupsListData] = useState([]);

  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [supportLoginPop, setSupportLoginPop] = useState(false);
  const [participateDeletepop, setParticipateDeletePop] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();
  const [signatureTextUnderLine, setSignatureTextUnderLine] = useState();

  const [basicGroupListData, setBasicGroupListData] = useState([]);
  const [enhancedGroupsListData, setEnhancedGroupsListData] = useState([]);
  const [profileFieldData, setProfileFieldData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({
    countryName: "",
    countryEmoji: "",
  });
  const [timeZoneVal, setTimeZoneVal] = useState(
    "IST (Indian Standard Time): 5:01 PM"
  );

  const Navigate = useNavigate();
  const { participant_Id } = useParams();

  const [sendParticularUserData, setSendParticularUserData] = useState();
  const handleRouting = (Url) => {
    Navigate(Url);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "200px",
        overflowY: "auto",
      },
    },
  };

  const editParticipantObj = {
    name: "",
    email: "",
    username: "",
    workspaces: [workspaceState?.selectedWorkspace?._id],
    is_unsubscribe_emails_check: false,
    confirm_username: false,
    password: "",
    confirm_password: "",
    force_password: false,
    two_fa_enabled: false,
    donot_enforce_2fa: false,
    timezone: "",
    status: "active",
    basic_groups: [],
    enhanced_groups: [],
    custom_fields: [],
  };

  const [editResearcherParticipant, setEditResearcherParticipant] = useState({
    ...editParticipantObj,
  });
  const [listingData, setListingData] = useState(null);
  const [image, setImage] = useState(null);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      updateImage(file);
    }
  };

  const updateImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      let imageUploadRes = await uploadUserDirectoryImage(
        formData,
        authState.authToken
      );
      if (imageUploadRes.success) {
        setEditResearcherParticipant({
          ...editResearcherParticipant,
          profile_pic: imageUploadRes?.data?.upload_id,
        });
        toast.success(imageUploadRes.message, TOAST_CONFIG);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput").click();
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine === "underline") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine === "line-through") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("line-through");
        }
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const getAllCountryList = () => {
    getCountries().then((res) => {
      if (res.success && res.data.data.length > 0) {
        setCountryList(res.data.data);
      }
    });
  };

  useEffect(() => {
    getParticipants();
    getAllCountryList();
    getGroupsLists();
    getProfileFields();
  }, []);

  const handleChange = (event) => {
    const selectedValue = event.target.value === "" ? 0 : event.target.value;
    setEditResearcherParticipant((prevGroup) => ({
      ...prevGroup,
      [event.target.name]: selectedValue,
    }));
  };

  const getGroupsLists = async () => {
    try {
      const res = await axios.get(API_BASE_URL + "/participant/groups");
      if (res.status) {
        const bGroup = res.data?.data?.filter(
          (grp) => grp.is_enhanced == false
        );
        const eGroup = res.data?.data?.filter((grp) => grp.is_enhanced == true);
        setBasicGroupListData(bGroup);
        setEnhancedGroupsListData(eGroup);
      }
    } catch (error) {
      console.error("Error fetching project lists", error);
    }
  };

  const getProfileFields = async () => {
    try {
      const res = await axios.get(
        API_BASE_URL +
          `/participant/profile-fields?workspace_id=${workspaceState?.selectedWorkspace?._id}`
      );
      if (res.status) {
        console.log("profile field", res.data.data);
        const cfields = res.data.data.filter(
          (cf) =>
            cf.type === "my_account" &&
            (!cf.limit_participant_check ||
              (cf.limit_participant &&
                cf.limit_participant.includes(participant_Id)))
        );
        console.log("cfields", cfields);
        setProfileFieldData(cfields);
      }
    } catch (error) {
      console.error("Error fetching project lists", error);
    }
  };

  const getParticipants = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${API_BASE_URL}/super-admin/admin/participants?_id=${participant_Id}`
      );
      if (res.status) {
        let initParticipant = { ...editParticipantObj };
        let key;
        let allParticipantData = res?.data?.data[0];
        setListingData(res?.data?.data[0]);
        for (key in initParticipant) {
          if (key !== "password") {
            initParticipant[key] = allParticipantData[key];
          }
        }
        if (
          allParticipantData.basic_groups &&
          allParticipantData.basic_groups.length > 0
        ) {
          const basicGroups = allParticipantData.basic_groups.filter(
            (group) => group?.is_enhanced === false
          );
          initParticipant.basic_groups = basicGroups.map((group) => group._id);
        }
        if (
          allParticipantData.enhanced_groups &&
          allParticipantData.enhanced_groups.length > 0
        ) {
          const enhancedGroups = allParticipantData.enhanced_groups.filter(
            (group) => group?.is_enhanced === true
          );
          initParticipant.enhanced_groups = enhancedGroups.map(
            (group) => group._id
          );
        }
        setEditResearcherParticipant(initParticipant);
        setLoading(false);
        if (allParticipantData?.status == "active") {
          setRedio(0);
        } else {
          setRedio(1);
        }
        if (
          allParticipantData?.profile_pic === "undefined" ||
          allParticipantData?.profile_pic == null
        ) {
          setImage(null);
        } else {
          const imageUrl =
            STORAGE_BASE_PATH + `/` + allParticipantData?.profile_pic;
          urlToBase64(imageUrl, function (base64Img) {
            if (base64Img) {
              setImage(base64Img);
            } else {
              console.error("Failed to convert image URL to base64");
            }
          });
        }
        if (allParticipantData.country.length > 0) {
          const assignedCountry = allParticipantData.country.map((item) => ({
            name: item.name,
            _id: item._id,
            emoji: item.emoji,
            timezone: item.timezones,
          }));
          if (assignedCountry.length > 0) {
            setSelectedCountry({
              countryName: assignedCountry[0]?.name,
              countryEmoji: assignedCountry[0]?.emoji,
            });
            if (assignedCountry[0]?.timezone.length > 0) {
              setTimezoneList(assignedCountry[0]?.timezone);
              assignedCountry[0]?.timezone.map((tz) => {
                setTimeZoneVal(tz.zoneName);
              });
            }
          }
        } else {
          setSelectedCountry({
            countryName: "",
            countryEmoji: "",
          });
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching participant data:", error);
    }
  };

  const setFormValue = (e) => {
    let newFormData = {
      ...editResearcherParticipant,
      [e.target.name]: e.target.value,
    };
    setEditResearcherParticipant(newFormData);
  };

  // const chackBoxRoom = () => {
  //   return (
  //     <Box className="checkBoxItem">
  //       <Box>
  //         <input
  //           className="custom-checkbox"
  //           type="checkBox"
  //           checked={redio === 0 ? true : false}
  //         />
  //         <Typography>Group 0</Typography>
  //       </Box>
  //       <Box>
  //         <input
  //           className="custom-checkbox"
  //           type="checkBox"
  //           checked={redio === 0 ? true : false}
  //         />
  //         <Typography>Group 0</Typography>
  //       </Box>
  //       <Box>
  //         <input
  //           className="custom-checkbox"
  //           type="checkBox"
  //           checked={redio === 0 ? true : false}
  //         />
  //         <Typography>Group 0</Typography>
  //       </Box>
  //     </Box>
  //   );
  // };

  const handleTimeZone = (el) => {
    setTimeZoneVal(el);
    const newStatus = {
      ...editResearcherParticipant,
      timezone: el,
    };
    setEditResearcherParticipant(newStatus);
  };

  const validationObj = {
    hasErr: false,
    name: {
      error: false,
      msg: "",
    },
  };

  const [validationErrors, setValidationErrors] = useState({
    ...validationObj,
  });

  const handleValidation = (formInput) => {
    formInput = formInput || editResearcherParticipant;
    let validationerr = { ...validationObj };

    if (formInput.name == "") {
      validationerr.hasErr = true;
      validationerr.name = {
        error: true,
        msg: "Name is required",
      };
    }

    if (formInput.email == "") {
      validationerr.hasErr = true;
      validationerr.email = {
        error: true,
        msg: "Email is required",
      };
    }

    if (formInput.username == "") {
      validationerr.hasErr = true;
      validationerr.username = {
        error: true,
        msg: "Username is required",
      };
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  };

  const updateParticipant = async () => {
    if (!handleValidation()) {
      toast.error("Please correct form fields", TOAST_CONFIG);
      return false;
    }
    setLoading(true);
    try {
      let { confirm_password, ...participantData } = editResearcherParticipant;
      const custom_fields = editResearcherParticipant.custom_fields;
      if (custom_fields.length > 0) {
        const cfild = custom_fields.map((el) => ({
          field_id: el.field_id,
          value: el.value,
        }));
        participantData.custom_fields = cfild;
      }

      // return console.log("custom_fields", custom_fields);
      console.log("====participant data===:", participantData);
      let participantRes = await updateParticipantApi(
        participant_Id,
        participantData,
        authState.authToken
      );
      setLoading(false);
      if (participantRes.success) {
        toast.success(participantRes.message, TOAST_CONFIG);
        Navigate("/workspace-administration/all-participants");
      } else {
        toast.error(participantRes.message, TOAST_CONFIG);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  const DeleteParticipantPop = () => {
    const handelRemovePersonalData = () => {
      setOptionDropVal("Remove personal data");
      let payload = {
        workspace_id: workspaceState?.selectedWorkspace?._id,
        participant_id: participant_Id,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(`/super-admin/admin/participants/remove-personal-data`, payload)
          .then((res) => {
            if (res.success) {
              resolve({
                success: true,
                message: res.message,
              });
              Navigate("/workspace-administration/all-participants");
              toast.success(res.message, TOAST_CONFIG);
            } else {
              reject({
                success: false,
                message: res.message,
              });
              toast.success(res.message, TOAST_CONFIG);
            }
          })
          .catch((err) => {
            reject({
              success: false,
              message: err.message,
            });
          });
      });
    };

    const handelPopClose = (e) => {
      if (e.target.id === "popBackdrop") {
        setParticipateDeletePop(false);
      }
    };

    useEffect(() => {
      if (participateDeletepop) {
        document.body.style.overflow = "hidden"; // Disable scrolling
      } else {
        document.body.style.overflow = "auto"; // Enable scrolling
      }
    }, [participateDeletepop]);

    return (
      <>
        <Box
          onClick={handelPopClose}
          id="popBackdrop"
          sx={{ display: participateDeletepop ? "flex" : "none" }}
          className="workspaceDeletePopContainer"
        >
          <Box className="workspaceDeleteBox workspaceDeletePopTextAlign">
            <Box className="deleteTextBox">
              <Typography>
                Are you sure you want to remove {listingData?.name} participants
                ?
              </Typography>
            </Box>
            <Box className="deleteBtnBox">
              <AppBtnOutLine
                onClick={() => setParticipateDeletePop(false)}
                buttonText="Cancel"
              />
              <AppBtn
                buttonText="Remove Participant"
                onClick={handelRemovePersonalData}
              />
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  const getChildren = () => {
    return (
      <Box className="researchersMainBox pageContainer">
        <Box className="EditParticipantBox workspaceListBox">
          <DeleteParticipantPop />
          <SupportLoginParticipantPop
            setSupportLoginPop={setSupportLoginPop}
            supportLoginPop={supportLoginPop}
            sendParticularUserData={sendParticularUserData}
          />
          <Box
            onClick={() =>
              handleRouting("/workspace-administration/all-participants")
            }
            className="openBtn"
          >
            <img src={backArrow} />
            <Typography className="neonBackText">Back</Typography>
          </Box>
          <Box className="addParticipantsHeader">
            <Typography className="pageHeaderText">Edit Participant</Typography>
            <Box
              width={"400px"}
              height={"40px"}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                className="supLog"
                onClick={() => {
                  setSendParticularUserData(listingData);
                  setSupportLoginPop(true);
                }}
              >
                <img src={logIcon} />
                <Typography ml={1}>Support Login</Typography>
              </Box>
              <OptionDrop
                setOptionDrop={setOptionDrop}
                optionDrop={optionDrop}
                setOptionDropVal={setOptionDropVal}
                optionDropVal={optionDropVal}
                workspace_id={workspaceState?.selectedWorkspace?._id}
                participant_id={participant_Id}
                setParticipateDeletePop={setParticipateDeletePop}
                DeleteParticipantPop={DeleteParticipantPop}
              />
            </Box>
          </Box>

          <Box className="avatarInfoBox">
            <Box className="avatarImgBox">
              {/* {(listingData?.profile_pic == 'undefined' || listingData?.profile_pic == null) ? <img src={DummyUser} /> : <img src={viewImage(listingData?.profile_pic)} /> } */}
              {!image || image === "undefined" ? (
                <img src={DummyUser} alt="Dummy User" />
              ) : (
                <img src={image} alt="Profile" />
              )}
            </Box>
            <Box className="avatarIfoBox">
              <Typography mb={3}>
                Participant :
                <span>
                  {` ${listingData?.name} (${
                    listingData?.is_participant ? "Participant" : ""
                  })`}
                </span>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Box
                  mr={2}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  onClick={triggerFileInput}
                >
                  <img src={shareIcon} />
                  <Typography ml={1}>Upload Image</Typography>
                </Box>
                {/* <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <img src={pastIcon} />
                      <Typography ml={1}>Paste Url</Typography>
                    </Box> */}
              </Box>
            </Box>
            <input
              id="fileInput"
              style={{ display: "none" }}
              type="file"
              onChange={handleFileInputChange}
            />
          </Box>

          <Box className="redioBtnBox">
            <Box
              className="redioItem"
              onClick={() => {
                setRedio(0);
                setEditResearcherParticipant({
                  ...editResearcherParticipant,
                  status: "active",
                });
              }}
            >
              <Box
                className={
                  redio === 0 ? "customRedio customRedioActive" : "customRedio"
                }
              >
                <Box></Box>
              </Box>
              <Typography>Active</Typography>
            </Box>
            <Box
              className="redioItem"
              onClick={() => {
                setRedio(1);
                setEditResearcherParticipant({
                  ...editResearcherParticipant,
                  status: "inactive",
                });
              }}
            >
              <Box
                className={
                  redio === 1 ? "customRedio customRedioActive" : "customRedio"
                }
              >
                <Box></Box>
              </Box>
              <Typography>Inactive</Typography>
            </Box>
          </Box>

          <Box className="infolabelBox">
            <Box className="infoRowBox">
              <Box>
                <Typography>
                  {console.log("list", listingData)}
                  Assigned Projects:{" "}
                  <span>{listingData?.projects?.length}</span>
                </Typography>
              </Box>
              <Box>
                <Typography>
                  Project Visits: <span></span>
                </Typography>
              </Box>
            </Box>
            <Box className="infoRowBox">
              <Box>
                <Typography>
                  Last Visit: <span>{formatDate(listingData?.last_visit)}</span>
                </Typography>
              </Box>
              <Box>
                <Typography>
                  Added: <span>{formatDate(listingData?.created_at)}</span>
                </Typography>
              </Box>
            </Box>
          </Box>

          <Typography className="allPartRedioLabel">Enhanced Groups</Typography>

          <Box className="ckeckBoxRoom">
            <FormControl sx={{ width: "100%" }} size="small">
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={editResearcherParticipant?.enhanced_groups}
                onChange={handleChange}
                name="enhanced_groups"
                multiple
                displayEmpty
                MenuProps={MenuProps}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <p>Select Enhanced Groups</p>;
                  }
                  return selected
                    .map((value) => {
                      const group = enhancedGroupsListData.find(
                        (group) => group._id === value
                      );
                      return group ? group.name : value;
                    })
                    .join(", ");
                }}
              >
                {enhancedGroupsListData.map((group, index) => (
                  <MenuItem value={group._id} key={index}>
                    {group.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {/* <Box className="propertyPagination">
                  <Box><p>1</p></Box>
                  <Box><p>2</p></Box>
                  <Box><p>3</p></Box>
                  <Box><p>4</p></Box>
                  <Box><p>...</p></Box>
                  <Box><p>8</p></Box>
                  <Box><p>9</p></Box>
                  <Box><p>10</p></Box>
                </Box> */}
          {/* <Box className="searchGroupSection">
                <Box
                  pl={4.5}
                  className="inputAppDrop"
                  onClick={() => setGroupDrop(!groupDrop)}
                >
                  <img className="groupsIcon" src={groupsIcon} />
                  <Typography>Search Groups</Typography>
                  <img
                    style={{ rotate: groupDrop ? "180deg" : "0deg" }}
                    src={dropArrow}
                  />
                </Box>
                <Box
                  sx={{ height: groupDrop ? "auto" : "0px" }}
                  className="checkMain_box"
                >
                  <Box className="ckeckBoxRoom">
                    {chackBoxRoom()}
                    {chackBoxRoom()}
                    {chackBoxRoom()}
                  </Box>
                </Box>
              </Box> */}

          <Typography className="allPartRedioLabel" mt={3}>
            Basic Groups
          </Typography>
          <Box className="ckeckBoxRoom">
            <FormControl sx={{ width: "100%" }} size="small">
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={editResearcherParticipant?.basic_groups}
                onChange={handleChange}
                name="basic_groups"
                multiple
                displayEmpty
                MenuProps={MenuProps}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <p>Select Groups</p>;
                  }
                  return selected
                    .map((value) => {
                      const group = basicGroupListData.find(
                        (group) => group._id === value
                      );
                      return group ? group.name : value;
                    })
                    .join(", ");
                }}
              >
                {basicGroupListData.map((group, index) => (
                  <MenuItem value={group._id} key={index}>
                    {group.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Typography mt={3} mb={2} className="allPartRedioLabel">
            Account Details
          </Typography>
          <Box className="csPBox">
            <Typography>Name</Typography>
            <input
              className="phoneInput"
              placeholder="Enter Name"
              name="name"
              value={editResearcherParticipant?.name}
              onChange={setFormValue}
            />
            <span className="validationerr">{validationErrors.name?.msg}</span>
          </Box>
          <Box className="csPBox">
            <Typography mt={2}>Email Address</Typography>
            <input
              className="phoneInput"
              placeholder="Enter Email Address"
              name="email"
              value={editResearcherParticipant?.email}
              onChange={setFormValue}
            />
            <span className="validationerr">{validationErrors.email?.msg}</span>
          </Box>

          <Box className="airadioSwitchBox">
            <AntSwitch
              inputProps={{ "aria-label": "unsubscribe emails" }}
              checked={
                editResearcherParticipant?.is_unsubscribe_emails_check == true
                  ? true
                  : false
              }
              onChange={(e) => {
                setEditResearcherParticipant({
                  ...editResearcherParticipant,
                  is_unsubscribe_emails_check: e.target.checked,
                });
              }}
            />
            <Typography className="switchText">
              Unsubscribe for all emails
            </Typography>
          </Box>
          <Box className="csPBox">
            <Typography mb={1}>Username</Typography>
            <Typography fontSize={"12px"} mb={1}>
              No spaced or special characters. Do not include the last name.
            </Typography>
            <input
              className="phoneInput"
              placeholder="Enter User Name"
              name="username"
              value={editResearcherParticipant?.username}
              onChange={setFormValue}
            />
            <span className="validationerr">
              {validationErrors.username?.msg}
            </span>
          </Box>
          <Box className="airadioSwitchBox">
            <AntSwitch
              inputProps={{ "aria-label": "confirm username" }}
              checked={
                editResearcherParticipant?.confirm_username == true
                  ? true
                  : false
              }
              onChange={(e) => {
                setEditResearcherParticipant({
                  ...editResearcherParticipant,
                  confirm_username: e.target.checked,
                });
              }}
            />
            <Typography className="switchText">
              Must confirm username
            </Typography>
          </Box>
          <Typography mb={1}>New Password</Typography>
          <Typography fontSize={"12px"} mb={1}>
            At least 8 characters
          </Typography>
          <Box mb={1} className="csPBox pwdInput">
            <input
              className="phoneInput"
              placeholder="Enter Password"
              type={pwdVisible ? "text" : "password"}
              name="password"
              onChange={setFormValue}
              value={editResearcherParticipant?.password}
            />
            <img src={VisibleIcon} onClick={() => setPwdVisible(!pwdVisible)} />
          </Box>
          <Box className="csPBox pwdInput">
            <input
              className="phoneInput"
              placeholder="Confirm New Password"
              type={cpwdVisible ? "text" : "password"}
              name="confirm_password"
              onChange={setFormValue}
              value={editResearcherParticipant?.confirm_password}
            />
            <img
              src={VisibleIcon}
              onClick={() => setCpwdVisible(!cpwdVisible)}
            />
          </Box>
          <Box className="airadioSwitchBox">
            <AntSwitch
              inputProps={{ "aria-label": "forgot password change" }}
              checked={
                editResearcherParticipant?.force_password == true ? true : false
              }
              onChange={(e) => {
                setEditResearcherParticipant({
                  ...editResearcherParticipant,
                  force_password: e.target.checked,
                });
              }}
            />
            <Typography className="switchText">
              Force Password Change
            </Typography>
          </Box>
          <Typography className="allPartRedioLabel" mb={1}>
            Two-factor authentication
          </Typography>
          <Typography className="" mb={1}>
            Two-factor authentication adds an extra layer of security to your
            account. It makes login more secure by asking for a One-Time
            Password (OTP), which can be generated on your phone or computer.
          </Typography>
          {/* <Box className="airadioSwitchBox enS">
                <AntSwitch inputProps={{ "aria-label": "ant design" }} />
                <Typography className="switchText">Enabled</Typography>
              </Box> */}
          <Box ml={3}>
            <Box className="airadioSwitchBox enS">
              <AntSwitch
                inputProps={{ "aria-label": "enabled" }}
                checked={
                  editResearcherParticipant?.two_fa_enabled == true
                    ? true
                    : false
                }
                onChange={(e) => {
                  setEditResearcherParticipant({
                    ...editResearcherParticipant,
                    two_fa_enabled: e.target.checked,
                  });
                }}
              />
              <Typography className="switchText">Enabled</Typography>
            </Box>
            <Box mt={2} className="alpOverCheck">
              <input
                className="custom-checkbox"
                type="checkBox"
                checked={
                  editResearcherParticipant?.donot_enforce_2fa == true
                    ? true
                    : false
                }
                onChange={(e) => {
                  setEditResearcherParticipant({
                    ...editResearcherParticipant,
                    donot_enforce_2fa: e.target.checked,
                  });
                }}
                // defaultChecked
              />
              <Typography ml={2}>Override: Do not enforce</Typography>
            </Box>
          </Box>
          <Typography className="allPartRedioLabel" mt={3} mb={1}>
            Time Zone
          </Typography>
          <Typography className="" mb={1}>
            If Daylight Saving Time is observed at any point during the year,
            opt for "Daylight Time."
          </Typography>

          <Box mt={3} className="companiesTopBox">
            <Box className="companyInputBoxWlabel">
              <Typography mb={1} className="inputLabel">
                Select Country
              </Typography>

              <Box
                className="inputAppDrop"
                onClick={() => setCountryDrop(!countryDrop)}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography ml={2}>
                    {selectedCountry?.countryEmoji}

                    {selectedCountry?.countryName}
                  </Typography>
                </Box>
                <img
                  style={{ rotate: countryDrop ? "180deg" : "0deg" }}
                  src={DownArrowIcon}
                />
                {/* pop */}
                <Box
                  sx={{
                    height: countryDrop ? "180px" : "0px",
                    overflowY: countryDrop ? "scroll" : "hidden",
                  }}
                  className="statusPop"
                >
                  {countryList &&
                    countryList.map((el, index) => (
                      <Box
                        key={index}
                        onClick={() => {
                          // setCountryDropVal(index);
                          setSelectedCountry({
                            countryName: el?.name,
                            countryEmoji: el?.emoji,
                          });
                          setEditResearcherParticipant({
                            ...editResearcherParticipant,
                            country: el._id,
                          });
                          setTimezoneList(el.timezones);
                        }}
                        className="statusPopItem"
                      >
                        {/* <img className="countryListImg" src={el.img} /> */}
                        <Typography ml={2}>
                          {el.emoji} {el.name}
                        </Typography>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
            {/* drop */}
            <Box className="companyInputBoxWlabel">
              <Typography mb={1} className="inputLabel">
                Select Time Zone
              </Typography>
              <Box
                className="inputAppDrop"
                onClick={() => setTimeZoneDrop(!timeZoneDrop)}
              >
                <Typography>
                  {timeZoneVal || "Filter by Workspace Status"}
                </Typography>
                <img
                  style={{
                    rotate: timeZoneDrop ? "180deg" : "0deg",
                  }}
                  src={DownArrowIcon}
                />
                {/* pop */}
                <Box
                  sx={{ height: timeZoneDrop ? "auto" : "0px" }}
                  className="statusPop"
                >
                  {timeZoneList &&
                    timeZoneList?.map((el, index) => (
                      <Box
                        key={index}
                        onClick={() => handleTimeZone(el?.zoneName)}
                        className="statusPopItem"
                      >
                        <Typography>{el?.zoneName}</Typography>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="profileFieldNav">
            <Typography className="allPartRedioLabel">
              Profile Fields
            </Typography>
            <Typography className="profileFieldNav2ndText">
              Add Profile Fields
            </Typography>
          </Box>

          <Box>
            {profileFieldData &&
              profileFieldData.map((field, index) => (
                <RenderCustomField
                  profileField={field}
                  addResearcherParticipant={editResearcherParticipant}
                  setAddResearcherParticipant={setEditResearcherParticipant}
                  key={index}
                />
              ))}
          </Box>

          {/* Profile Fields */}
          {/* <Box>
                <Box className="Text_BoxWDot participantProfileField">
                  {" "}
                  <img src={optionIcon} />{" "}
                  <Typography>What is your current age?</Typography>
                </Box>
                <Box className="input_Box profileAnswerBox">
                    <input
                      type="text"
                      className="eTemInput"
                      placeholder="Enter your response here"
                    />
                </Box>

                <Box className="Text_BoxWDot participantProfileField dobProfileFields">
                  {" "}
                  <img src={optionIcon} />{" "}
                  <Typography>What is your date of birth?</Typography>
                </Box>
                <Box className="pickerBox dobInputProfileField profileAnswerBox">
                  <ReferenceDateUsingValue />
                </Box>

                <Box className="Text_BoxWDot participantProfileField dobProfileFields">
                  {" "}
                  <img src={optionIcon} />{" "}
                  <Typography>What is your workout timing?</Typography>
                </Box>
                <Box className="pickerBox dobInputProfileField profileAnswerBox">
                  <ReferenceDateExplicitTimePicker />
                </Box>

                <Box className="Text_BoxWDot participantProfileField">
                  {" "}
                  <img src={optionIcon} />{" "}
                  <Typography>What is your current household income?</Typography>
                </Box>
                <Box className="input_Box profileAnswerBox">
                    <input
                      type="text"
                      className="eTemInput"
                      placeholder="Enter your response here"
                    />
                </Box>

                <Box className="Text_BoxWDot participantProfileField dobProfileFields">
                  {" "}
                  <img src={optionIcon} />{" "}
                  <Typography>What gender do you identify as?</Typography>
                </Box>
                <Box className="profileAnswerBox">
                  <Box className="radioBox">
                    <Box>
                    <input
                      className="emailSettingInput emailSettingRadio"
                      type='radio'
                      name='activity'
                    />
                    </Box>
                    <Box>
                      <Typography className="emailSettingLabel">Male</Typography>
                    </Box>
                  </Box> 
                  <Box className="radioBox">
                    <Box>
                    <input
                      className="emailSettingInput emailSettingRadio"
                      type='radio'
                      name='activity'
                    />
                    </Box>
                    <Box>
                      <Typography className="emailSettingLabel">Female</Typography>
                    </Box>
                  </Box>
                  <Box className="radioBox">
                    <Box>
                    <input
                      className="emailSettingInput emailSettingRadio"
                      type='radio'
                      name='activity'
                    />
                    </Box>
                    <Box>
                      <Typography className="emailSettingLabel">Other</Typography>
                    </Box>
                  </Box>                             
                </Box>

                <Box className="Text_BoxWDot participantProfileField dobProfileFields">
                  {" "}
                  <img src={optionIcon} />{" "}
                  <Typography>Write a brief introduction about yourself?</Typography>
                </Box>
                <Box className="profileAnswerBox">
                <textarea
                  className="DmettingTextTextAera"
                  placeholder="Enter your response here"
                />
                </Box>

                <Box className="Text_BoxWDot participantProfileField dobProfileFields">
                  {" "}
                  <img src={optionIcon} />{" "}
                  <Typography>Why you are joining Terapage?</Typography>
                </Box>
                <Box className="profileAnswerBox">
                  <Box className="emailInputBox formattedProfileAnswer">
                    <Box className="emailInputBoxNav">
                      <Box
                        onClick={() => setMessageTextItalic(!messageTextItalic)}
                        className="emailImgBox"
                      >
                        <img src={IIcon} />
                      </Box>
                      <Box
                        onClick={() => setMessageTextBold(!messageTextBold)}
                        className="emailImgBox"
                      >
                        <img src={BIcon} />
                      </Box>
                      <Box
                        onClick={() => handelTextlineChekc("underline", "message")}
                        className="emailImgBox"
                      >
                        <img src={UnderLineU} />
                      </Box>
                      <Box
                        onClick={() => handelTextlineChekc("line-through", "message")}
                        className="emailImgBox"
                      >
                        <img src={MLineU} />
                      </Box>
                      <Box className="emailImgBox">
                        <img src={AtachMentIcon} />
                      </Box>
                      <Box className="emailImgBox">
                        <img src={Qbox} />
                      </Box>
                      <Box
                        onClick={() => setMessageTextLaOut("start")}
                        className="emailImgBox"
                      >
                        <img src={Line1} />
                      </Box>
                      <Box
                        onClick={() => setMessageTextLaOut("center")}
                        className="emailImgBox"
                      >
                        <img src={Line2} />
                      </Box>
                      <Box
                        onClick={() => setMessageTextLaOut("end")}
                        className="emailImgBox"
                      >
                        <img src={Line3} />
                      </Box>
                      <Box className="emailImgBox">
                        <img src={Line4} />
                      </Box>
                      <Box className="emailImgBox">
                        <img src={emoji} />
                      </Box>
                    </Box>
                    <textarea
                      style={{
                        textAlign: messageTextLaOut,
                        fontStyle: messageTextItalic ? "italic" : "normal",
                        fontWeight: messageTextBold ? "bold" : "normal",
                        textDecoration: messageTextUnderLine,
                        // textDecoration: messageTextMLine ? "line-through" : null
                      }}
                      className="emailInput"
                      name="emailMessage"
                      placeholder="Enter your response here"
                      onChange={handelChange}
                    />
                  </Box>
                </Box>

                <Box className="Text_BoxWDot participantProfileField dobProfileFields">
                  {" "}
                  <img src={optionIcon} />{" "}
                  <Typography>What gender do you identify as?</Typography>
                </Box>
                <Box className="uploadVideoProfileAnswerBox profileAnswerBox"
                  onClick={() => {
                    document.getElementById("fileInput").click();
                    }}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                  <Box className="imgUplodInputBox deleteTopicIcon">
                        <input
                          id='fileInput'
                          type="file"
                          accept="video/*"
                          style={{ display: 'none' }}
                          onChange={handleFileInputChange}
                        />
                      <img src={videoCameraIcon} alt="Camera" />
                  </Box>
                  <Typography className='imageUploadLabelText deleteTopicIcon'>Record  <span>or</span> Upload Video</Typography>
                </Box>

                <Box className="Text_BoxWDot participantProfileField dobProfileFields">
                  {" "}
                  <img src={optionIcon} />{" "}
                  <Typography>What gender do you identify as?</Typography>
                </Box>
                <Box className="uploadVideoProfileAnswerBox profileAnswerBox"
                  onClick={() => {
                    document.getElementById("fileInput").click();
                    }}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                <Box className="imgUplodInputBox deleteTopicIcon">
                      <input
                        id='fileInput'
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleFileInputChange}
                      />
                      <img src={CameraIcon} alt="Camera" />
                    </Box>
                    <Typography className='imageUploadLabelText deleteTopicIcon'>Capture <span> or </span>Upload Photo</Typography>
                </Box>

                <Box className="Text_BoxWDot participantProfileField dobProfileFields">
                  {" "}
                  <img src={optionIcon} />{" "}
                  <Typography>On-the-dot Task</Typography>
                </Box>
                <Box className="onTheDotProfileAns">
                  <img src={personalGolBgImg} />
                  <Box className="btnboxWText">
                    <Typography className=''>Before we proceed, Complete this small task</Typography>
                    <Typography className='startbgSubText'>Status <span> Not Started</span></Typography>
                    <Box className="onTheDotAnsBtnBox">
                      <AppBtn buttonText="Continue" />
                    </Box>
                  </Box>
                </Box>

              </Box> */}

          {/* Profile Fields */}

          <Box className="workPupBtnBox">
            <AppBtnOutLine
              buttonText="Cancel"
              onClick={() =>
                handleRouting("/workspace-administration/all-participants")
              }
            />
            <AppBtn buttonText="Save" onClick={updateParticipant} />
          </Box>
        </Box>
      </Box>
    );
  };

  return renderUserLayout(
    authState?.userType,
    null,
    getChildren(),
    "workadmin"
  );
}
