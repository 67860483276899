import React, { useEffect, useState } from "react";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./style.css";
//images
import iIcon from "../../../Assets/Images/IIcon.png";

import groupsIcon from "../../../Assets/Images/groupsIcon.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";
import chackBox from "../../../Assets/Images/Checkbox.png";
import check from "../../../Assets/Images/check.png";

//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import ReferenceDateUsingValue from "../../AppTools/DatePicker";
import ReferenceDateExplicitTimePicker from "../../AppTools/TimePicker";
import { Status, AntSwitch, MonthStatus } from "../../AppTools/AppTool";
import { getDatePickerVal } from "../../../lib/helpers";
import dayjs from "dayjs";
export default function AddJournalActivityPop({
  addJournalActivityPop,
  setAddJournalActivityPop,
  setAddActivitiesPop,
  activePage,
  setActivePage,
  activityData,
  setActivityData,
  initialState,
  countries,
  groups,
  saveStandardActivity,
  timeZones,
  setTimeZone,
}) {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const [countryDrop, setCountryDrop] = useState(false);
  const [countryDropVal, setCountryDropVal] = useState("Select Country");
  const [timeZoneDrop, setTimeZoneDrop] = useState(false);
  const [timeZoneDropVal, setTimeZoneDropVal] = useState("Select Time Zone");
  //const [timeZones, setTimeZone] = useState([])
  const [redio, setRedio] = useState(0);
  const [redio1, setRedio1] = useState(0);
  const [redio2, setRedio2] = useState(0);
  const [groupDrop2, setGroupDrop2] = useState(false);
  const [chackVal, setChackVal] = useState([]);
  const [image, setImage] = useState(null);

  const [journalTab, setJournalTab] = useState("Basic Settings");
  const [redio3, setRedio3] = useState(1);
  const [statusDrop, setStatusDrop] = useState(false);
  const [statusDropVal, setStatusDropVal] = useState("Day");
  const [statusDrop2, setStatusDrop2] = useState(false);
  const [statusDropVal2, setStatusDropVal2] = useState("Day");

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    setActivityData({
      ...activityData,
      basic_settings: {
        ...activityData.basic_settings,
        journal_repeat_unit: statusDropVal,
      },
    });
  }, [statusDropVal]);

  useEffect(() => {
    setActivityData({
      ...activityData,
      notification_settings: {
        ...activityData.notification_settings,
        after_reminder_interval: {
          ...activityData.notification_settings.after_reminder_interval,
          unit: statusDropVal2,
        },
      },
    });
  }, [statusDropVal2]);

  useEffect(() => {
    setStatusDropVal(activityData.basic_settings.journal_repeat_unit);
    setStatusDropVal2(
      activityData.notification_settings.after_reminder_interval.unit
    );
    const filterTimezone = countries
      .filter((el) => el._id === activityData.basic_settings.country_id)
      .map((el) => el.timezones);
    console.log(filterTimezone);
    setTimeZone(filterTimezone);
  }, [addJournalActivityPop]);

  const pushData = (string) => {
    setChackVal((prev) => [...prev, string]);
  };
  const deleteAllCheckData = () => setChackVal([]);
  const selectAllCheckData = () => {
    setChackVal(["Camping", "Fishing", "Kayaking", "Cycling"]);
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setAddJournalActivityPop(false);
    }
  };

  useEffect(() => {
    if (addJournalActivityPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [addJournalActivityPop]);

  const setFormData = (e) => {
    let newState = {
      ...activityData,
      [e.target.name]: e.target.value,
    };

    setActivityData(newState);
  };

  const saveJournal = async () => {
    console.log(activityData);
    saveStandardActivity();
  };

  return (
    <>
      {/* --------------------------------------------------------------Journal-------------------------------------------------- */}

      <Box
        sx={{ display: activePage === "Journal Activity" ? "block" : "none" }}
      >
        <Typography className="companyHeader">
          {t("addJournalActivity.journalActivityHead")}
        </Typography>
        <Box className="iBox StandardiBox">
          <img src={iIcon} />
          <Typography>
            {t("addJournalActivity.journalActivityNotify")}
          </Typography>
        </Box>

        <Typography className="eTemPopLabel">
          {t("addActivityPop.activityNameLabel")}
        </Typography>
        <Box className="input_Box">
          <input
            type="text"
            className="eTemInput"
            placeholder={t("addActivityPop.activityNamePlaceholder")}
            value={activityData.name ?? ""}
            name="name"
            onChange={setFormData}
          />
        </Box>

        <Box className="responsesNav">
          <Box
            className={
              journalTab === "Basic Settings"
                ? "responsesNavItem responsesNavItemActive"
                : "responsesNavItem"
            }
            onClick={() => setJournalTab("Basic Settings")}
          >
            <Typography className="standardActivityTabs">
              {t("standardActivityPop.basicSettingsTab")}
            </Typography>
          </Box>
          <Box
            className={
              journalTab === "Notifications"
                ? "responsesNavItem responsesNavItemActive"
                : "responsesNavItem"
            }
            onClick={() => setJournalTab("Notifications")}
          >
            <Typography className="standardActivityTabs">
              {t("standardActivityPop.notificationsTab")}
            </Typography>
          </Box>
          <Box
            className={
              journalTab === "Points Customization"
                ? "responsesNavItem responsesNavItemActive"
                : "responsesNavItem"
            }
            onClick={() => setJournalTab("Points Customization")}
          >
            <Typography className="standardActivityTabs">
              {t("standardActivityPop.PointsCustomizationTab")}
            </Typography>
          </Box>
        </Box>

        {/* =====Basic Settings======= */}
        <Box
          sx={{
            display: journalTab === "Basic Settings" ? "block" : "none",
          }}
        >
          <Box className="timeInputBox">
            <Box className="timeInputItemBox">
              <Typography className="eTemPopLabel">
                {t("workspaceSplash.startDate")}
              </Typography>
              <Box className="pickerBox">
                <ReferenceDateUsingValue
                  value={dayjs(activityData.basic_settings.start_date)}
                  onChange={(e) =>
                    setActivityData({
                      ...activityData,
                      basic_settings: {
                        ...activityData.basic_settings,
                        start_date: getDatePickerVal(e, "date"),
                      },
                    })
                  }
                />
              </Box>
              <Box className="pickerBox">
                <ReferenceDateExplicitTimePicker
                  placeholder={t("addConversationTopic.timePlaceholder")}
                  value={dayjs(
                    activityData.basic_settings.start_time,
                    "HH:mm:ss"
                  )}
                  onChange={(e) =>
                    setActivityData({
                      ...activityData,
                      basic_settings: {
                        ...activityData.basic_settings,
                        start_time: getDatePickerVal(e, "time"),
                      },
                    })
                  }
                />
              </Box>
            </Box>

            <Box className="timeInputItemBox">
              <Typography className="eTemPopLabel">
                {t("standardActivityPop.endDateLabel")}
              </Typography>
              <Box className="pickerBox">
                <ReferenceDateUsingValue
                  value={dayjs(activityData.basic_settings.end_date)}
                  onChange={(e) =>
                    setActivityData({
                      ...activityData,
                      basic_settings: {
                        ...activityData.basic_settings,
                        end_date: getDatePickerVal(e, "date"),
                      },
                    })
                  }
                />
              </Box>
              <Box className="pickerBox">
                <ReferenceDateExplicitTimePicker
                  placeholder={t("addConversationTopic.timePlaceholder")}
                  value={dayjs(
                    activityData.basic_settings.end_time,
                    "HH:mm:ss"
                  )}
                  onChange={(e) =>
                    setActivityData({
                      ...activityData,
                      basic_settings: {
                        ...activityData.basic_settings,
                        end_time: getDatePickerVal(e, "time"),
                      },
                    })
                  }
                />
              </Box>
            </Box>

            <Box className="timeInputItemBox">
              <Typography className="eTemPopLabel">
                {t("standardActivityPop.archiveDateLabel")}
              </Typography>
              <Box className="pickerBox">
                <ReferenceDateUsingValue
                  value={dayjs(activityData.basic_settings.archive_date)}
                  onChange={(e) =>
                    setActivityData({
                      ...activityData,
                      basic_settings: {
                        ...activityData.basic_settings,
                        archive_date: getDatePickerVal(e, "date"),
                      },
                    })
                  }
                />
              </Box>
              <Box className="pickerBox">
                <ReferenceDateExplicitTimePicker
                  placeholder={t("addConversationTopic.timePlaceholder")}
                  value={dayjs(
                    activityData.basic_settings.archive_time,
                    "HH:mm:ss"
                  )}
                  onChange={(e) =>
                    setActivityData({
                      ...activityData,
                      basic_settings: {
                        ...activityData.basic_settings,
                        archive_time: getDatePickerVal(e, "time"),
                      },
                    })
                  }
                />
              </Box>
            </Box>
          </Box>

          <Box className="timeInputBox">
            <Box mb={5} className="timeInputItemBox">
              <Typography className="eTemPopLabel">
                {t("AccountSetting.Country")}
              </Typography>
              <Box height={"40px"}>
                {/* <Status
                  setStatusDrop={setCountryDrop}
                  statusDrop={countryDrop}
                  setStatusDropVal={setCountryDropVal}
                  statusDropVal={countryDropVal}
                  label=" "
                  itemAry={countries}
                /> */}
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    className="statusBox MuiBox-root css-0"
                    value={
                      activityData.basic_settings.country_id || "Select Country"
                    }
                    onChange={(e) =>
                      setActivityData({
                        ...activityData,
                        basic_settings: {
                          ...activityData.basic_settings,
                          country_id: e.target.value,
                        },
                      })
                    }
                  >
                    {countries.length > 0 &&
                      countries.map((el, i) => (
                        <MenuItem
                          value={el._id}
                          key={i}
                          onClick={() => setTimeZone(el.timezones)}
                        >
                          {el.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>

            <Box
              sx={{
                display: activityData.basic_settings.country_id
                  ? "block"
                  : "none",
              }}
              mb={5}
              className="timeInputItemBox"
            >
              <Typography className="eTemPopLabel">
                {t("AddProjectParticipants.tZone")}
              </Typography>
              <Box height={"40px"}>
                {/* <Status
                  setStatusDrop={setTimeZoneDrop}
                  statusDrop={timeZoneDrop}
                  setStatusDropVal={setTimeZoneDropVal}
                  statusDropVal={timeZoneDropVal}
                  label=" "
                  itemAry={timeZones}
                /> */}
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    className="statusBox MuiBox-root css-0"
                    value={
                      activityData.basic_settings.timezone || "Select Country"
                    }
                    onChange={(e) =>
                      setActivityData({
                        ...activityData,
                        basic_settings: {
                          ...activityData.basic_settings,
                          timezone: e.target.value,
                        },
                      })
                    }
                  >
                    {timeZones.length > 0 &&
                      timeZones.map((el, i) => (
                        <MenuItem value={el.zoneName} key={i}>
                          {el.zoneName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>

          <Typography className="eTemPopLabel">
            {t("standardActivityPop.activityVisibilityHead")}
          </Typography>
          <Box
            mt={3}
            className="redioItem"
            onClick={() => {
              setActivityData({
                ...activityData,
                basic_settings: {
                  ...activityData.basic_settings,
                  visibility: "all-participant",
                },
              });
            }}
          >
            <Box
              className={
                activityData.basic_settings.visibility === "all-participant"
                  ? "customRedio customRedioActive"
                  : "customRedio"
              }
            >
              <Box></Box>
            </Box>
            <Typography>
              {t("projectParticipantReport.participantAllParticipant")}
            </Typography>
          </Box>

          <Box
            mt={3}
            className="redioItem"
            onClick={() => {
              setActivityData({
                ...activityData,
                basic_settings: {
                  ...activityData.basic_settings,
                  visibility: "selected-groups",
                },
              });
            }}
          >
            <Box
              className={
                activityData.basic_settings.visibility === "selected-groups"
                  ? "customRedio customRedioActive"
                  : "customRedio"
              }
            >
              <Box></Box>
            </Box>
            <Typography>
              {t("addConversationTopic.topicVisibilityParticipantsCheckbox2")}
            </Typography>
          </Box>

          <Box
            mt={2}
            ml={5}
            sx={{
              display:
                activityData.basic_settings.visibility === "selected-groups"
                  ? "block"
                  : "none",
            }}
          >
            <Typography className="psNlabel">
              {t("projectParticipantReport.participantSelectGroup")}
            </Typography>
            <FormControl sx={{ width: "100%" }}>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                className="statusBox MuiBox-root css-0"
                value={
                  activityData.basic_settings.selected_groups ||
                  "Select Country"
                }
                onChange={(e) =>
                  setActivityData({
                    ...activityData,
                    basic_settings: {
                      ...activityData.basic_settings,
                      selected_groups: e.target.value,
                    },
                  })
                }
                multiple
              >
                {groups.length > 0 &&
                  groups.map((el, i) => (
                    <MenuItem value={el._id} key={i}>
                      {el.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {/*  <Box pl={4.5} className="inputAppDrop">
              <Box onClick={() => setGroupDrop2(!groupDrop2)}>
                <img className="groupsIcon" src={groupsIcon} />
                <p></p>
                <Typography>{t("allParticipantPage.searchGroups")}</Typography>
              </Box>
              <img
                className="PartDropIcon"
                style={{ rotate: groupDrop2 ? "180deg" : "0deg" }}
                src={dropArrow}
                onClick={() => setGroupDrop2(!groupDrop2)}
              />

              <Box
                sx={{ display: groupDrop2 ? "block" : "none" }}
                className="statusPop"
              >
                <Box className="checkSelectorBox">
                  <span onClick={selectAllCheckData}>{t("commonTxt.selectAll")}</span>
                  <span onClick={deleteAllCheckData}>{t("commonTxt.deSelectAll")}</span>
                </Box>

                <Box
                  className="statusPopItem"
                  onClick={() => {
                    if (chackVal.includes("Camping")) {
                      chackVal.filter((item) => item !== "Camping");
                      return;
                    } else {
                      pushData("Camping");
                    }
                  }}
                >
                  <Box className="chackBox">
                    <img className="chackBoxImg" src={chackBox} />
                    <img
                      style={{
                        display: chackVal.includes("Camping")
                          ? "block"
                          : "none",
                      }}
                      className="chack"
                      src={check}
                    />
                  </Box>
                  <p>Outdoor Activity: Camping</p>
                </Box>

                <Box
                  className="statusPopItem"
                  onClick={() => {
                    if (chackVal.includes("Fishing")) {
                      chackVal.filter((item) => item !== "Fishing");
                      return;
                    } else {
                      pushData("Fishing");
                    }
                  }}
                >
                  <Box className="chackBox">
                    <img className="chackBoxImg" src={chackBox} />
                    <img
                      style={{
                        display: chackVal.includes("Fishing")
                          ? "block"
                          : "none",
                      }}
                      className="chack"
                      src={check}
                    />
                  </Box>
                  <p>Outdoor Activity: Fishing</p>
                </Box>

                <Box
                  className="statusPopItem"
                  onClick={() => {
                    if (chackVal.includes("Kayaking")) {
                      chackVal.filter((item) => item !== "Kayaking");
                      return;
                    } else {
                      pushData("Kayaking");
                    }
                  }}
                >
                  <Box className="chackBox">
                    <img className="chackBoxImg" src={chackBox} />
                    <img
                      style={{
                        display: chackVal.includes("Kayaking")
                          ? "block"
                          : "none",
                      }}
                      className="chack"
                      src={check}
                    />
                  </Box>
                  <p>Outdoor Activity: Kayaking</p>
                </Box>

                <Box
                  className="statusPopItem"
                  onClick={() => {
                    if (chackVal.includes("Cycling")) {
                      chackVal.filter((item) => item !== "Cycling");
                      return;
                    } else {
                      pushData("Cycling");
                    }
                  }}
                >
                  <Box className="chackBox">
                    <img className="chackBoxImg" src={chackBox} />
                    <img
                      style={{
                        display: chackVal.includes("Cycling")
                          ? "block"
                          : "none",
                      }}
                      className="chack"
                      src={check}
                    />
                  </Box>
                  <p>Outdoor Activity: Cycling</p>
                </Box>
              </Box>
            </Box> */}
          </Box>

          <Box className="gapBox"></Box>

          <Typography className="eTemPopLabel">
            {t("standardActivityPop.responseVisibilityHead")}
          </Typography>
          <Box
            mt={3}
            className="redioItem"
            onClick={() => {
              setActivityData({
                ...activityData,
                basic_settings: {
                  ...activityData.basic_settings,
                  response_visibility: "public",
                },
              });
            }}
          >
            <Box
              className={
                activityData.basic_settings.response_visibility === "public"
                  ? "customRedio customRedioActive"
                  : "customRedio"
              }
            >
              <Box></Box>
            </Box>
            <Typography>
              {t("standardActivityPop.responseVisibilityRadio1")}
            </Typography>
          </Box>
          <Box
            mt={3}
            className="redioItem"
            onClick={() => {
              setActivityData({
                ...activityData,
                basic_settings: {
                  ...activityData.basic_settings,
                  response_visibility: "uninfluenced",
                },
              });
            }}
          >
            <Box
              className={
                activityData.basic_settings.response_visibility ===
                "uninfluenced"
                  ? "customRedio customRedioActive"
                  : "customRedio"
              }
            >
              <Box></Box>
            </Box>
            <Typography>
              {t("standardActivityPop.responseVisibilityRadio2")}
            </Typography>
          </Box>
          <Box
            mt={3}
            className="redioItem"
            onClick={() => {
              setActivityData({
                ...activityData,
                basic_settings: {
                  ...activityData.basic_settings,
                  response_visibility: "private",
                },
              });
            }}
          >
            <Box
              className={
                activityData.basic_settings.response_visibility === "private"
                  ? "customRedio customRedioActive"
                  : "customRedio"
              }
            >
              <Box></Box>
            </Box>
            <Typography>
              {t("standardActivityPop.responseVisibilityRadio3")}
            </Typography>
          </Box>
          <Box className="gapBox"></Box>

          <Typography className="eTemPopLabel">
            {t("standardActivityPop.randomizeTasksHead")}
          </Typography>
          <Box
            mt={3}
            className="redioItem"
            onClick={() => {
              setActivityData({
                ...activityData,
                basic_settings: {
                  ...activityData.basic_settings,
                  randomize_task: false,
                },
              });
            }}
          >
            <Box
              className={
                !activityData.basic_settings.randomize_task
                  ? "customRedio customRedioActive"
                  : "customRedio"
              }
            >
              <Box></Box>
            </Box>
            <Typography>
              {t("standardActivityPop.randomizeTasksRadio1")}
            </Typography>
          </Box>
          <Box
            mt={3}
            className="redioItem"
            onClick={() => {
              setActivityData({
                ...activityData,
                basic_settings: {
                  ...activityData.basic_settings,
                  randomize_task: true,
                },
              });
            }}
          >
            <Box
              className={
                activityData.basic_settings.randomize_task
                  ? "customRedio customRedioActive"
                  : "customRedio"
              }
            >
              <Box></Box>
            </Box>
            <Typography>
              {t("standardActivityPop.randomizeTasksRadio2")}
            </Typography>
          </Box>
          <Box className="gapBox"></Box>
          <Typography className="eTemPopLabel">
            {t("addJournalActivity.journalTypeHead")}
          </Typography>
          <Box
            mt={3}
            className="redioItem"
            onClick={() => {
              setRedio3(0);
              setActivityData({
                ...activityData,
                basic_settings: {
                  ...activityData.basic_settings,
                  journal_unlimited_responses: true,
                },
              });
            }}
          >
            <Box
              className={
                redio3 === 0 ? "customRedio customRedioActive" : "customRedio"
              }
            >
              <Box></Box>
            </Box>
            <Typography>{t("addJournalActivity.journalTypeRadio1")}</Typography>
          </Box>
          <Box
            mt={3}
            sx={{
              display: redio3 == 0 ? "block !important" : "none !important",
            }}
            className="journalTypeRepeat"
          >
            <Box
              mb={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <input
                style={{ marginRight: "10px" }}
                className="custom-checkbox"
                type="checkBox"
                checked={
                  activityData.basic_settings.journal_donot_group_entries
                }
                onClick={(e) =>
                  setActivityData({
                    ...activityData,
                    basic_settings: {
                      ...activityData.basic_settings,
                      journal_donot_group_entries: e.target.checked,
                    },
                  })
                }
              />
              <Typography>{t("liveGroupChatPop.liveVideoSwitch5")}</Typography>
            </Box>
          </Box>

          <Box
            mt={3}
            className="redioItem"
            onClick={() => {
              setRedio3(1);
              setActivityData({
                ...activityData,
                basic_settings: {
                  ...activityData.basic_settings,
                  journal_responses_fixed_intervel: true,
                },
              });
            }}
          >
            <Box
              className={
                redio3 === 1 ? "customRedio customRedioActive" : "customRedio"
              }
            >
              <Box></Box>
            </Box>
            <Typography>{t("addJournalActivity.journalTypeRadio1")}</Typography>
          </Box>
          <Box
            mt={3}
            sx={{
              display: redio3 == 1 ? "block !important" : "none !important",
            }}
            className="journalTypeRepeat"
          >
            <Typography className="switchText">
              {t("addJournalActivity.repeatEvery")}{" "}
            </Typography>
            <input
              className="agreeNumber"
              value={activityData.basic_settings.journal_repeat_number}
              onChange={(e) =>
                setActivityData({
                  ...activityData,
                  basic_settings: {
                    ...activityData.basic_settings,
                    journal_repeat_number: e.target.value,
                  },
                })
              }
            />
            <MonthStatus
              setStatusDrop={setStatusDrop}
              statusDrop={statusDrop}
              setStatusDropVal={setStatusDropVal}
              statusDropVal={statusDropVal}
            />

            <Typography className="switchText">
              {t("addJournalActivity.untilActivityDueDate")}{" "}
            </Typography>
          </Box>
          <Box className="gapBox"></Box>
          <Typography className="eTemPopLabel">
            {t("addJournalActivity.journalRequirementsHead")}
          </Typography>

          <Box mt={3} className="journalRequirement">
            <Typography className="switchText">
              {t("addJournalActivity.enforceMinimum")}{" "}
            </Typography>
            <input
              className="agreeNumber"
              value={activityData.basic_settings.journal_requirement}
              onChange={(e) =>
                setActivityData({
                  ...activityData,
                  basic_settings: {
                    ...activityData.basic_settings,
                    journal_requirement: e.target.value,
                  },
                })
              }
            />
            <Typography className="switchText">
              {t("addJournalActivity.entries")}{" "}
            </Typography>
          </Box>
          <Box className="gapBox"></Box>
          <Typography className="eTemPopLabel">
            {t("addJournalActivity.journalInstructionsHead")}
          </Typography>
          <Typography className="DmettingTExt">
            {t("addJournalActivity.journalInstructionsText")}
          </Typography>
          <textarea
            className="DmettingTextTextAera"
            placeholder={t("addJournalActivity.journalInstructionsPlaceholder")}
            value={activityData.basic_settings.description}
            onChange={(e) =>
              setActivityData({
                ...activityData,
                basic_settings: {
                  ...activityData.basic_settings,
                  description: e.target.value,
                },
              })
            }
          />
          <Box className="gapBox"></Box>
        </Box>
        {/* =====Notifications======= */}
        <Box
          sx={{
            display: journalTab === "Notifications" ? "block" : "none",
          }}
        >
          <Box className="airadioSwitchBox journalActivityNotificationDayDrop">
            <AntSwitch
              inputProps={{ "aria-label": "ant design" }}
              checked={activityData.notification_settings.new_activity_enable}
              onClick={(e) => {
                setActivityData({
                  ...activityData,
                  notification_settings: {
                    ...activityData.notification_settings,
                    new_activity_enable: e.target.checked,
                  },
                });
              }}
            />
            <Typography>
              {t("standardActivityPop.notificationsSwitch1")}
            </Typography>
          </Box>
          <Box className="airadioSwitchBox journalActivityNotificationDayDrop">
            <AntSwitch inputProps={{ "aria-label": "ant design" }} />
            <Typography>
              {t("addJournalActivity.journalNotificationSwitch1")}
            </Typography>
            {/* notification days drop */}
            <input
              className="agreeNumber"
              value={
                activityData.notification_settings.after_reminder_interval.count
              }
              onChange={(e) => {
                setActivityData({
                  ...activityData,
                  notification_settings: {
                    ...activityData.notification_settings,
                    after_reminder_interval: {
                      ...activityData.notification_settings
                        .after_reminder_interval,
                      count: e.target.value,
                    },
                  },
                });
              }}
            />
            <MonthStatus
              setStatusDrop={setStatusDrop2}
              statusDrop={statusDrop2}
              setStatusDropVal={setStatusDropVal2}
              statusDropVal={statusDropVal2}
            />

            <Typography>
              {t("addJournalActivity.journalNotificationSwitch2")}{" "}
            </Typography>

            {/* notification days drop */}
          </Box>
          <Box className="airadioSwitchBox journalActivityNotificationDayDrop">
            <AntSwitch
              inputProps={{ "aria-label": "ant design" }}
              checked={activityData.notification_settings.activity_overdue}
              onClick={(e) => {
                setActivityData({
                  ...activityData,
                  notification_settings: {
                    ...activityData.notification_settings,
                    activity_overdue: e.target.checked,
                  },
                });
              }}
            />
            <Typography>
              {t("standardActivityPop.notificationsSwitch3")}
            </Typography>
          </Box>
        </Box>
        {/* =====Points Customizations======= */}
        <Box
          sx={{
            display: journalTab === "Points Customization" ? "block" : "none",
          }}
        >
          <Box className="airadioSwitchBox">
            <AntSwitch
              checked={activityData.points_customization.customize_points}
              inputProps={{ "aria-label": "ant design" }}
              onClick={(e) => {
                setActivityData({
                  ...activityData,
                  points_customization: {
                    ...activityData.points_customization,
                    customize_points: e.target.checked,
                  },
                });
              }}
            />
            <Typography className="switchText pcTopicSText">
              {t("standardActivityPop.pointCustomizationSwitch")}
            </Typography>
          </Box>

          <Box mb={3} className="pcTopicListRow">
            <Typography>{t("projectSettingPoints.activitiesText1")}</Typography>
            <span>:</span>
            <input
              value={activityData.points_customization.standard_completion ?? 0}
              onChange={(e) => {
                setActivityData({
                  ...activityData,
                  points_customization: {
                    ...activityData.points_customization,
                    standard_completion: e.target.value,
                  },
                });
              }}
            />
            <samp>{t("commonTxt.pts.")}</samp>
          </Box>

          <Box mb={3} className="pcTopicListRow">
            <Typography>{t("projectSettingPoints.activitiesText2")}</Typography>
            <span>:</span>
            <input
              value={activityData.points_customization.comment_own ?? 0}
              onChange={(e) => {
                setActivityData({
                  ...activityData,
                  points_customization: {
                    ...activityData.points_customization,
                    comment_own: e.target.value,
                  },
                });
              }}
            />
            <samp>{t("commonTxt.pts.")}</samp>
          </Box>

          <Box mb={3} className="pcTopicListRow">
            <Typography>{t("projectSettingPoints.activitiesText3")}</Typography>
            <span>:</span>
            <input
              value={activityData.points_customization.comment_response ?? 0}
              onChange={(e) => {
                setActivityData({
                  ...activityData,
                  points_customization: {
                    ...activityData.points_customization,
                    comment_response: e.target.value,
                  },
                });
              }}
            />
            <samp>{t("commonTxt.pts.")}</samp>
          </Box>

          <Box mb={3} className="pcTopicListRow">
            <Typography>{t("projectSettingPoints.activitiesText4")}</Typography>
            <span>:</span>
            <input
              value={activityData.points_customization.like_response ?? 0}
              onChange={(e) => {
                setActivityData({
                  ...activityData,
                  points_customization: {
                    ...activityData.points_customization,
                    like_response: e.target.value,
                  },
                });
              }}
            />
            <samp>{t("commonTxt.pts.")}</samp>
          </Box>

          <Box mb={3} className="pcTopicListRow">
            <Typography>{t("projectSettingPoints.activitiesText5")}</Typography>
            <span>:</span>
            <input
              value={
                activityData.points_customization.like_rating_received ?? 0
              }
              onChange={(e) => {
                setActivityData({
                  ...activityData,
                  points_customization: {
                    ...activityData.points_customization,
                    like_rating_received: e.target.value,
                  },
                });
              }}
            />
            <samp>{t("commonTxt.pts.")}</samp>
          </Box>
        </Box>

        <Box className="workPupBtnBox">
          <AppBtnOutLine
            buttonText={t("commonTxt.cancelBtn")}
            onClick={() => setActivePage(null)}
          />
          <AppBtnOutLine
            buttonText={t("commonTxt.backBtn")}
            onClick={() => setActivePage(null)}
          />
          <AppBtn
            buttonText={t("commonTxt.continueBtn")}
            onClick={saveJournal}
          />
        </Box>
      </Box>
    </>
  );
}
