import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from "react-i18next";
import "./style.css";

//Images
import iIcon2 from "../../../Assets/Images/IIcon.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";

//components 
import { AppBtn, AppBtnOutLine } from '../../../Components/AppTools/AppButton';

export default function ProjectManagementPrivacy({ projectSettingDropVal }) {

    const [participantIdentityDrop, setParticipantIdentityDrop] = useState(false);
    const [participantIdentityDropVal, setParticipantIdentityDropVal] = useState();
    const [admintIdentityDrop, setAdminIdentityDrop] = useState(false);
    const [adminIdentityDropVal, setAdminIdentityDropVal] = useState();
    const [participantIdentityDrop2, setParticipantIdentityDrop2] = useState(false);
    const [participantIdentityDropVal2, setParticipantIdentityDropVal2] = useState();
    const [participantIdentityDrop3, setParticipantIdentityDrop3] = useState(false);
    const [participantIdentityDropVal3, setParticipantIdentityDropVal3] = useState();
    const {
        t,
        i18n: { changeLanguage, language },
      } = useTranslation();

  return (
    <>   
         <Box sx={{ display: projectSettingDropVal === "Privacy" ? "block" : "none" }}>
            <Box className="iBox projectIBox">
                <img src={iIcon2} />
                <Typography>{t("projectPrivacy.projectPrivacyNotify")}</Typography>
            </Box>
            <Typography className='emailNotificationHeaderText'>{t("projectParticipantReport.subHeaderTxt2")}</Typography>
            <Box className="projectPrivacyIdentity">
                    <Typography className=''>{t("projectParticipantReport.participantIdentity")}</Typography>
                    <Typography className='bsNText2'>{t("projectPrivacy.participantIdentitiesSubText")}</Typography>
                    <Box
                    className="inputAppDrop participantIdentityDrop"
                    onClick={() => setParticipantIdentityDrop(!participantIdentityDrop)}
                    >
                    <Typography>{participantIdentityDropVal || t("projectParticipantReport.participantIdentityPlaceholder")}</Typography>
                    <img
                    style={{ rotate: participantIdentityDrop ? "180deg" : "0deg" }}
                    src={dropArrow}
                    />
                    {/* pop */}
                    <Box
                    sx={{ height: participantIdentityDrop ? "auto" : "0px" }}
                    className="statusPop"
                    >
                    <Box
                        onClick={() => setParticipantIdentityDropVal("Identity 1")}
                        className="statusPopItem"
                    >
                        <Typography>Identity 1</Typography>
                    </Box>
                    <Box
                        onClick={() => setParticipantIdentityDropVal("Identity 2")}
                        className="statusPopItem"
                    >
                        <Typography>Identity 2</Typography>
                    </Box>
                   
                    </Box>
                </Box>
            </Box>
            <Box className="projectRadioBox">
                <Box className="projectRadioBoxSection">
                    <Box className="ProjectRadioInput">
                        <input className="custom-checkbox" type="checkBox" defaultChecked />
                        <Typography className="emailSettingLabel">{t("projectPrivacy.participantCheckbox1")}</Typography>
                    </Box>
                    <Box className="ProjectRadioInput">
                        <input className="custom-checkbox" type="checkBox" defaultChecked />
                        <Typography className="emailSettingLabel">{t("projectPrivacy.participantCheckbox2")}</Typography>
                    </Box>
                </Box>
                <Box className="projectRadioBoxSection">
                    <Box className="ProjectRadioInput">
                        <input className="custom-checkbox" type="checkBox" />
                        <Typography className="emailSettingLabel">{t("projectPrivacy.participantCheckbox3")}</Typography>
                    </Box>
                    
                </Box>
            </Box>
            <Box className="projectPrivacyIdentity">
                <Typography className=''>{t("projectPrivacy.AdminsIdentitiesLabel")}</Typography>
                <Typography className='bsNText2'>{t("projectPrivacy.AdminsIdentitiesSubText")}</Typography>
                <Box
                className="inputAppDrop participantIdentityDrop"
                onClick={() => setAdminIdentityDrop(!admintIdentityDrop)}
                >
                <Typography>{adminIdentityDropVal || t("projectPrivacy.AdminsIdentitiesPlaceholder")}</Typography>
                <img
                style={{ rotate: admintIdentityDrop ? "180deg" : "0deg" }}
                src={dropArrow}
                />
                {/* pop */}
                <Box
                sx={{ height: admintIdentityDrop ? "auto" : "0px" }}
                className="statusPop"
                >
                <Box
                    onClick={() => setAdminIdentityDropVal("Identity 1")}
                    className="statusPopItem"
                >
                    <Typography>Identity 1</Typography>
                </Box>
                <Box
                    onClick={() => setAdminIdentityDropVal("Identity 2")}
                    className="statusPopItem"
                >
                    <Typography>Identity 2</Typography>
                </Box>
                
                </Box>
                </Box>
            </Box>
            <Typography className='emailNotificationHeaderText projectPrivacyObserver'>{t("projectPrivacy.observerHead")}</Typography>
            <Box className="projectPrivacyIdentity">
                    <Typography className=''>{t("projectPrivacy.observerIdentitiesLabel")}</Typography>
                    <Typography className='bsNText2'>{t("projectPrivacy.observerIdentitiesSubText")}</Typography>
                    <Box
                    className="inputAppDrop participantIdentityDrop"
                    onClick={() => setParticipantIdentityDrop2(!participantIdentityDrop2)}
                    >
                    <Typography>{participantIdentityDropVal2 || t("projectPrivacy.observerIdentitiesPlaceholder")}</Typography>
                    <img
                    style={{ rotate: participantIdentityDrop2 ? "180deg" : "0deg" }}
                    src={dropArrow}
                    />
                    {/* pop */}
                    <Box
                    sx={{ height: participantIdentityDrop2 ? "auto" : "0px" }}
                    className="statusPop"
                    >
                    <Box
                        onClick={() => setParticipantIdentityDropVal2("Identity 1")}
                        className="statusPopItem"
                    >
                        <Typography>Identity 1</Typography>
                    </Box>
                    <Box
                        onClick={() => setParticipantIdentityDropVal2("Identity 2")}
                        className="statusPopItem"
                    >
                        <Typography>Identity 2</Typography>
                    </Box>
                   
                    </Box>
                </Box>
            </Box>
            <Box className="projectRadioBox">
                <Box className="projectRadioBoxSection">
                    <Box className="ProjectRadioInput">
                        <input className="custom-checkbox" type="checkBox" defaultChecked />
                        <Typography className="emailSettingLabel">{t("projectPrivacy.observerCheckbox1")}</Typography>
                    </Box>
                    <Box className="ProjectRadioInput">
                        <input className="custom-checkbox" type="checkBox" defaultChecked />
                        <Typography className="emailSettingLabel">{t("projectPrivacy.observerCheckbox2")}</Typography>
                    </Box>
                    <Box className="ProjectRadioInput">
                        <input className="custom-checkbox" type="checkBox" />
                        <Typography className="emailSettingLabel">{t("projectPrivacy.observerCheckbox3")}</Typography>
                    </Box>
                </Box>
                <Box className="projectRadioBoxSection">
                    <Box className="ProjectRadioInput">
                        <input className="custom-checkbox" type="checkBox" />
                        <Typography className="emailSettingLabel">{t("projectPrivacy.observerCheckbox4")}</Typography>
                    </Box>
                    <Box className="ProjectRadioInput">
                        <input className="custom-checkbox" type="checkBox" />
                        <Typography className="emailSettingLabel">{t("projectPrivacy.observerCheckbox5")}</Typography>
                    </Box>
                    <Box className="ProjectRadioInput">
                        <input className="custom-checkbox" type="checkBox" />
                        <Typography className="emailSettingLabel">{t("projectPrivacy.observerCheckbox6")}</Typography>
                    </Box>
                </Box>
            </Box>
            <Typography className='emailNotificationHeaderText projectPrivacyCollaborator'>{t("projectPrivacy.ResearcherCollaboratorHead")}</Typography>
            <Box className="projectPrivacyIdentity">
                    <Typography className=''>{t("projectParticipantReport.participantIdentity")}</Typography>
                    <Typography className='bsNText2'>{t("projectPrivacy.ResearcherCollaboratorSubText")}</Typography>
                    <Box
                    className="inputAppDrop participantIdentityDrop"
                    onClick={() => setParticipantIdentityDrop3(!participantIdentityDrop3)}
                    >
                    <Typography>{participantIdentityDropVal3 || t("projectParticipantReport.participantIdentityPlaceholder")}</Typography>
                    <img
                    style={{ rotate: participantIdentityDrop3 ? "180deg" : "0deg" }}
                    src={dropArrow}
                    />
                    {/* pop */}
                    <Box
                    sx={{ height: participantIdentityDrop3 ? "auto" : "0px" }}
                    className="statusPop"
                    >
                    <Box
                        onClick={() => setParticipantIdentityDropVal3("Identity 1")}
                        className="statusPopItem"
                    >
                        <Typography>Identity 1</Typography>
                    </Box>
                    <Box
                        onClick={() => setParticipantIdentityDropVal3("Identity 2")}
                        className="statusPopItem"
                    >
                        <Typography>Identity 2</Typography>
                    </Box>
                   
                    </Box>
                </Box>
            </Box>
            <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.resetBtn")} />
            <AppBtn buttonText={t("commonTxt.saveBtn")} />
            </Box>
        </Box>
    </>
  )
}
