import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";

//components
import { AppBtnOutLine, AppBtn } from "../../AppTools/AppButton";

export default function ViewEmailTemplatePop({
  setViewEmailTemplatePop,
  viewEmailTemplatePop,
}) {
  const email1 =
    "Hello {fname}, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Read more";
  const email2 = "Best Regards,  Lorem Ipsum";
  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();

  const [signatureTextLaOut, setSignatureTextLaOut] = useState("start");
  const [signatureTextItalic, setSignatureTextItalic] = useState(false);
  const [signatureTextBold, setSignatureTextBold] = useState(false);
  const [signatureTextUnderLine, setSignatureTextUnderLine] = useState();

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setViewEmailTemplatePop(false);
    }
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine === "underline") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine === "line-through") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("line-through");
        }
      }
    }
  };

  useEffect(() => {
    if (viewEmailTemplatePop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [viewEmailTemplatePop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: viewEmailTemplatePop ? "flex" : "none" }}
        className="workspacePop"
      >
        <Box
          sx={{ display: viewEmailTemplatePop ? "flex" : "none" }}
          className="workspaceContaint emailTemPop"
        >
          <Typography className="workspaceHeader">
            View Email Template
          </Typography>

          <Box className="basicBox">
            <Box>
              <Typography className="eTemPopLabel ">Template Name</Typography>
              <Box className="pickerBox ">
                <input className="eTemInput" placeholder="Welcome Messgae" />
              </Box>
            </Box>
            <Box>
              <Typography className="eTemPopLabel">Subject</Typography>
              <Box className="pickerBox">
                <input
                  className="eTemInput"
                  placeholder="Welcome to your Workspace!"
                />
              </Box>
            </Box>
          </Box>

          <Typography className="eTemPopLabel ">Dynamic Values</Typography>
          <Box className="basicBox inputBoxes">
            <Box className="eTemPopDyValuBox">
              <Box>
                <Typography className="eTemPopDyValuText">
                  First Name
                </Typography>
                <span className="eTemPopDyValuTextSpan etpTcolon">:</span>
                <span className="eTemPopDyValuTextSpan">{"{fname}"}</span>
              </Box>
              <Box>
                <Typography className="eTemPopDyValuText">Last Name</Typography>
                <span className="eTemPopDyValuTextSpan etpTcolon">:</span>
                <span className="eTemPopDyValuTextSpan">{"{lname}"}</span>
              </Box>
              <Box>
                <Typography className="eTemPopDyValuText">Username</Typography>
                <span className="eTemPopDyValuTextSpan etpTcolon">:</span>
                <span className="eTemPopDyValuTextSpan">{"{username}"}</span>
              </Box>
              <Box>
                <Typography className="eTemPopDyValuText">Password</Typography>
                <span className="eTemPopDyValuTextSpan etpTcolon">:</span>
                <span className="eTemPopDyValuTextSpan">{"{password}"}</span>
              </Box>
            </Box>

            <Box className="eTemPopDyValuBox">
              <Box>
                <Typography className="eTemPopDyValuText">Email</Typography>
                <span className="eTemPopDyValuTextSpan etpTcolon">:</span>
                <span className="eTemPopDyValuTextSpan">{"{email}"}</span>
              </Box>
              <Box>
                <Typography className="eTemPopDyValuText">
                  Participant id
                </Typography>
                <span className="eTemPopDyValuTextSpan etpTcolon">:</span>
                <span className="eTemPopDyValuTextSpan">{"{pid}"}</span>
              </Box>
              <Box>
                <Typography className="eTemPopDyValuText">
                  Ext. Participant id
                </Typography>
                <span className="eTemPopDyValuTextSpan etpTcolon">:</span>
                <span className="eTemPopDyValuTextSpan">{"{epid}"}</span>
              </Box>
              <Box>
                <Typography className="eTemPopDyValuText">
                  Project id
                </Typography>
                <span className="eTemPopDyValuTextSpan etpTcolon">:</span>
                <span className="eTemPopDyValuTextSpan">{"{prid}"}</span>
              </Box>
            </Box>
          </Box>

          <Typography className="eTemPopLabel">Message</Typography>

          <Box className="templateMsg">
            <Typography>{emailval?.emailMessage || email1}</Typography>
          </Box>

          <Typography className="eTemPopLabel">Signature</Typography>
          <Box className="templateMsg">
            <Typography>{emailval?.emailSignature || email2}</Typography>
          </Box>

          <Box className="eTemPopLogInBtnBox">
            <Typography className="eTemPopLabel">Login Button : </Typography>
            <Box className="loginBtn">
              <Typography>Click here to login</Typography>
            </Box>
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine
              buttonText="Cancel"
              onClick={() => setViewEmailTemplatePop(false)}
            />

            <AppBtn buttonText="Edit Template" />
          </Box>
        </Box>
      </Box>
    </>
  );
}
