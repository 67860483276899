import React, { useEffect, useState } from 'react'
import { Box, Typography, Grid } from '@mui/material';
import "./style.css";

import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";


//components
import { AppBtn } from "../../AppTools/AppButton";
import { formatDate } from '../../../lib/helpers';

export default function BulkImportDetailPop({ bulkImportDetailPop, setBulkImportDetailPop, selectedLogDetails }) {
  const email1 = "Hello {fname}, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Read more"
  const [emailval, setEmailval] = useState()
  const [messageTextLaOut, setMessageTextLaOut] = useState("start")
  const [messageTextItalic, setMessageTextItalic] = useState(false)
  const [messageTextBold, setMessageTextBold] = useState(false)
  const [messageTextUnderLine, setMessageTextUnderLine] = useState()

  const [signatureTextUnderLine, setSignatureTextUnderLine] = useState()
console.log("hello", selectedLogDetails);
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setBulkImportDetailPop(false)
    }
  }


  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value
    }));

  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null)
          return
        } else {
          setMessageTextUnderLine("underline")
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null)
          return
        } else {
          setMessageTextUnderLine("line-through")
        }
      }
    }
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine === "underline") {
          setSignatureTextUnderLine(null)
          return
        } else {
          setSignatureTextUnderLine("underline")
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine === "line-through") {
          setSignatureTextUnderLine(null)
          return
        } else {
          setSignatureTextUnderLine("line-through")
        }
      }
    }

  }

  useEffect(() => {
    if (bulkImportDetailPop) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [bulkImportDetailPop])


  return (
    <>
      <Box id="popBackdrop" onClick={handelPopClose} sx={{ display: bulkImportDetailPop ? "flex" : "none" }} className="workspacePop">
        <Box sx={{ display: bulkImportDetailPop ? "flex" : "none" }} className="workspaceContaint emailTemPop BrodMail">
          <Box className="mailBordPopHeader">
            <Typography className='workspaceHeader'>Bulk Import</Typography>
          </Box>

          <Grid className='bulkImportGridContainer' container spacing={2}>
            <Grid item sm={5}>
              <Typography className="greybox">
                Date: <span className='bulkImportPopTap'>{formatDate(selectedLogDetails?.created_at)}</span>
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography className="greybox">
                User: <span className='bulkImportPopTap'>{selectedLogDetails?.user_details[0]?.name}</span>
              </Typography>
            </Grid>
            <Grid item sm={3}>
              <Typography className="greybox">
                Log ID: <span className='bulkImportPopTap'>{selectedLogDetails?.action_id}</span>
              </Typography>
            </Grid>
          </Grid>
          
          <Typography className='eTemPopLabel addTemplatePopMessage'>Message<samp>*</samp></Typography>
          <Box className="emailInputBox">
            <Box className="emailInputBoxNav">
              <Box onClick={() => setMessageTextItalic(!messageTextItalic)} className="emailImgBox">
                <img src={IIcon} />
              </Box>
              <Box onClick={() => setMessageTextBold(!messageTextBold)} className="emailImgBox">
                <img src={BIcon} />
              </Box>
              <Box onClick={() => handelTextlineChekc("underline", "message")} className="emailImgBox">
                <img src={UnderLineU} />
              </Box>
              <Box onClick={() => handelTextlineChekc("line-through", "message")} className="emailImgBox">
                <img src={MLineU} />
              </Box>
              <Box className="emailImgBox">
                <img src={AtachMentIcon} />
              </Box>
              <Box className="emailImgBox">
                <img src={Qbox} />
              </Box>
              <Box onClick={() => setMessageTextLaOut("start")} className="emailImgBox">
                <img src={Line1} />
              </Box>
              <Box onClick={() => setMessageTextLaOut("center")} className="emailImgBox">
                <img src={Line2} />
              </Box>
              <Box onClick={() => setMessageTextLaOut("end")} className="emailImgBox">
                <img src={Line3} />
              </Box>
              <Box className="emailImgBox">
                <img src={Line4} />
              </Box>
              <Box className="emailImgBox">
                <img src={emoji} />
              </Box>

            </Box>
            {/* <textarea
              style={{
                textAlign: messageTextLaOut,
                fontStyle: messageTextItalic ? "italic" : "normal",
                fontWeight: messageTextBold ? "bold" : "normal",
                textDecoration: messageTextUnderLine

              }}
              className='emailInput' name='emailMessage' value={emailval?.emailMessage || email1} onChange={handelChange} /> */}
              <Box className="bulkImportMessage">
              <Typography>Bulk Import File Validated:</Typography>
              <Typography>{selectedLogDetails?.action_description}</Typography>
              <Typography className='bulkImportUpdateParticipant'>Update Participants: 15</Typography>
              <Typography>Completed: Tue Jul 23 14:21 EDT 2023</Typography>
              <Typography className='bulkImportUpdateParticipant'>Duration: 0s</Typography>
              </Box>
          </Box>

          
          <Box className="workPupBtnBox bulkImportPopBtn">
            <AppBtn onClick={() => setBulkImportDetailPop(false)} buttonText="Close" />
          </Box>

        </Box>
      </Box>
    </>
  )
}
