import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { participantInvitation } from "../../../Assets/Data";

export default function ProjectDirectParticipantScreened({
  selectedParticipant,
  setSelectedParticipant,
}) {
  const handleCheckboxChange = (index) => {
    const updatedCheckboxes = [...selectedParticipant];
    updatedCheckboxes[index] = !updatedCheckboxes[index];
    setSelectedParticipant(updatedCheckboxes);
  };
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  return (
    <>
      <Box className="workListBox">
        <Box className="haderRow" minWidth={"1135px"}>
          <Box minWidth={"290px"} className="Tabelsel navFsel templatehead">
            <Typography>{t("projectDirectory.tblName")}</Typography>
          </Box>
          <Box className="Tabelsel statusSel" minWidth={"325px"}>
            <Typography>{t("projectDirectory.tblTag")}</Typography>
          </Box>
          <Box className="Tabelsel statusSel" minWidth={"230px"}>
            <Typography>{t("projectDirectory.tblScreened")}</Typography>
          </Box>
          <Box className="Tabelsel statusSel templateheadl" minWidth={"230px"}>
            <Typography>{t("projectDirectory.tblGroups")}</Typography>
          </Box>
        </Box>
        {participantInvitation &&
          participantInvitation?.map((el, index) => (
            <Box key={index} className="TabelRow" minWidth={"1135px"}>
              <Box
                minWidth={"290px"}
                className="Tabelsel navFsel navtable participantCheckboxColor"
              >
                <input
                  className="AllPTablechackBox"
                  type="checkbox"
                  checked={selectedParticipant.includes(index)}
                  onChange={() => handleCheckboxChange(index)}
                />
                <Typography>{el.name}</Typography>
              </Box>
              <Box
                className="Tabelsel statusSel Tabelcentersel invitationStatusIcon"
                minWidth={"325px"}
              >
                {el.icon}
                <Typography>Accepted Manually</Typography>
              </Box>
              <Box
                className="Tabelsel statusSel Tabelcentersel"
                minWidth={"230px"}
              >
                <Typography>{el.invited}</Typography>
              </Box>
              <Box
                className="Tabelsel statusSel Tabelcentersel"
                minWidth={"230px"}
              >
                <Typography>3</Typography>
              </Box>
            </Box>
          ))}
      </Box>
    </>
  );
}
