import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import searchIcon from "../../../Assets/Images/search.png";
import chackBox from "../../../Assets/Images/Checkbox.png";
import check from "../../../Assets/Images/check.png";

import "./style.css";
import {
  getCities,
  getCountries,
  getIndustries,
  getStates,
  getWorkspaceUsers,
} from "../../../Services/master";
import { createAddNewCompany } from "../../../Services/adminActions";
import { useAppSelector } from "../../../lib/store/store";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { getUserRoleType } from "../../../lib/helpers"

export default function CompanyPop({
  setCompanyPop,
  companyPop,
  setWorkSpacePop,
  workspaceCompany,
  editWorkspaceCompany,
  setEditWorkspace,
  setcreatedAffiliate,
  workspacePopType,
  setSelectedCompany,
  setShowCompanyUser
}) {
  let authState = useAppSelector((state) => state.auth);
  setcreatedAffiliate = setcreatedAffiliate || false;

  const initCompState = {
    name: "",
    email: "",
    phone: "",
    industry: "",
    address_line1: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    contact_researchers: [],
    status: "active",
    contact_persons: [
      {
        name: "",
        email: "",
        phone: "",
        address: "",
      },
    ],
  };

  const [createCompany, setCreateCompany] = useState({
    ...initCompState,
  });

  const setCompanyFormValue = (e) => {
    let newState = {
      ...createCompany,
      [e.target.name]: e.target.value,
    };

    setCreateCompany(newState);

    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
  };

  const [settingTab, setSettingTab] = useState(0);
  const [researcherDrop, setresearcherDrop] = useState(false);
  const [loading, setLoading] = useState(false);

  const [cityList, setCityList] = useState(false);
  const [cityListVal, setCityListVal] = useState("");

  const [stateList, setStateList] = useState(false);
  const [stateListVal, setStateListVal] = useState("");

  const [countryList, setCountryList] = useState(false);
  const [countryListVal, setCountryListVal] = useState("");

  const [industryList, setIndustryList] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [industriesListsData, setIndustriesListsData] = useState([]);
  const [industryListVal, setIndustryListVal] = useState();

  const [filteredCountries, setFilteredCountries] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [allResearchersList, setAllResearchersList] = useState([]);

  useEffect(() => {
    getAllCountryList();
    getResearcherList();
    getObserversList();
    getCollaboratorsList();
    getIndustries().then((res) => {
      if (res.success && res.data.data.length > 0) {
        setIndustriesListsData(res.data.data);
      }
    });
  }, []);

  const getAllCountryList = () => {
    getCountries().then((res) => {
      if (res.success && res.data.data.length > 0) {
        setCountryData(res.data.data);
        setFilteredCountries(res.data.data);
      }
    });
  };

  const getAllStatesList = (country_id) => {
    getStates(country_id).then((res) => {
      if (res.success && res.data.data.length > 0) {
        setStatesData(res.data.data);
      }
    });
  };

  const getResearcherList = () => {
    getWorkspaceUsers({ "types[]": "researcher" }).then((res) => {
      if (res.success && res.data.data.length > 0) {
        const existingIdsAndRoles = new Set(allResearchersList.map(researcher => JSON.stringify({ _id: researcher._id, role: 'Researcher' })));
        const researchersToAdd = res.data.data.filter(el => {
          const idAndRole = JSON.stringify({ _id: el._id, role: 'Researcher' });
          return !existingIdsAndRoles.has(idAndRole);
        });
        setAllResearchersList(prevList => [...prevList, ...researchersToAdd.map(({ _id, name }) => ({ _id, name, role: 'Researcher' }))]);
      }
    });
  };

  const getObserversList = () => {
    getWorkspaceUsers({ "types[]": "observer" }).then((res) => {
      if (res.success && res.data.data.length > 0) {
        const existingIdsAndRoles = new Set(allResearchersList.map(observer => JSON.stringify({ _id: observer._id, role: 'Observer' })));
        const observersToAdd = res.data.data.filter(observer => {
          const idAndRole = JSON.stringify({ _id: observer._id, role: 'Observer' });
          return !existingIdsAndRoles.has(idAndRole);
        });
        setAllResearchersList(prevList => [...prevList, ...observersToAdd.map(({ _id, name }) => ({ _id, name, role: 'Observer' }))]);
      }
    });
  };

  const getCollaboratorsList = () => {
    getWorkspaceUsers({ "types[]": "collaborator" }).then((res) => {
      if (res.success && res.data.data.length > 0) {
        const existingIdsAndRoles = new Set(allResearchersList.map(researcher => JSON.stringify({ _id: researcher._id, role: researcher.role })));
        const collaboratorsToAdd = res.data.data.filter(el => {
          const idAndRole = JSON.stringify({ _id: el._id, role: 'Collaborator' });
          return !existingIdsAndRoles.has(idAndRole);
        });
        setAllResearchersList(prevList => [...prevList, ...collaboratorsToAdd.map(({ _id, name }) => ({ _id, name, role: 'Collaborator' }))]);
      }
    });
  };

  const getAllCitiesList = (state_id, country_id) => {
    getCities(country_id, state_id).then((res) => {
      if (res.success && res.data.data.length > 0) {
        setCitiesData(res.data.data);
      }
    });
  };

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    const filtered = countryData.filter((data) =>
      data.name.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredCountries(filtered);
  };

  const handleCountryChange = (e) => {
    let newState = { ...createCompany, country: e._id };
    setStateListVal("");
    setCityListVal("");
    setCreateCompany(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
    getAllStatesList(e.id);
  };

  const handleStateChange = (e) => {
    let newState = { ...createCompany, state: e._id };
    setCityListVal("");
    setCreateCompany(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
    getAllCitiesList(e.id, e.country_id);
  };

  const handleCityChange = (e) => {
    let newState = { ...createCompany, city: e._id };
    setCreateCompany(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
  };

  const handleIndustryChange = (e) => {
    let newState = { ...createCompany, industry: e._id };
    setCreateCompany(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setCompanyPop(false);
    }
  };

  const selectAllCheckData = () => {
    let selectedItems = [];
    allResearchersList.map(item => {
      selectedItems.push({ _id: item._id, name: item.name, role: item.role });
    })
    setCreateCompany({ ...createCompany, contact_researchers: selectedItems });
  };

  const getResearcherDropVal = () => {
    let dropdownLabel = '';
    if (createCompany.contact_researchers.length <= 2) {
      createCompany.contact_researchers.map((val, index) => {
        if (index > 0) {
          dropdownLabel += ', ';
        }
        dropdownLabel += val.name;
      });
    } else {
      dropdownLabel = createCompany.contact_researchers.length + ' Selected';
    }
    return dropdownLabel;
  }

  const deleteAllCheckData = () => setCreateCompany({ ...createCompany, contact_researchers: [] });

  const pushData = (obj) => {
    let selectedResearchers = [...createCompany.contact_researchers];
    selectedResearchers = selectedResearchers?.filter((item) => (item._id !== obj._id))
    selectedResearchers.push({ _id: obj._id, name: obj.name, role: obj.role })
    setCreateCompany({ ...createCompany, contact_researchers: selectedResearchers });
  };

  const handleAddPerson = () => {
    let prevContactPersons = [...createCompany.contact_persons];
    setCreateCompany({
      ...createCompany,
      contact_persons: [
        ...prevContactPersons,
        {
          name: "",
          email: "",
          phone: "",
          address: "",
        },
      ],
    });
  };

  const handleRemovePerson = (index) => {
    setCreateCompany((prevCompany) => ({
      ...prevCompany,
      contact_persons: prevCompany.contact_persons.filter(
        (_, i) => i !== index
      ),
    }));
  };

  const handlePersonInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedPersons = [...createCompany.contact_persons];
    updatedPersons[index] = {
      ...updatedPersons[index],
      [name]: value,
    };
    setCreateCompany((prevCompany) => ({
      ...prevCompany,
      contact_persons: updatedPersons,
    }));
  };

  const handleBack = () => {
    setCreateCompany({
      ...initCompState,
    });
    setIndustryListVal("");
    setCountryListVal("");
    setStateListVal("");
    setCityListVal("");
    setValidationErrors(validationObj);
    setCompanyPop(false);
    // setWorkSpacePop(true);
    if (workspacePopType == 'add' && typeof (setWorkSpacePop) != 'undefined') {
      setWorkSpacePop(true);
    } else if ((workspacePopType == 'edit' && typeof (setWorkSpacePop) != 'undefined')) {
      setEditWorkspace(true);
    }
  };

  const handleBackAgain = () => {
    setCompanyPop(false);
    setEditWorkspace(true);
  };

  useEffect(() => {
    if (companyPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [companyPop]);

  const validationObj = {
    hasErr: false,
    'name': {
      error: false,
      msg: ''
    },
    'email': {
      error: false,
      msg: ''
    },
    'phone': {
      error: false,
      msg: ''
    },
    'industry': {
      error: false,
      msg: ''
    },
    'address_line1': {
      error: false,
      msg: ''
    },
    'country': {
      error: false,
      msg: ''
    },
    'state': {
      error: false,
      msg: ''
    },
    'city': {
      error: false,
      msg: ''
    },
    'zipcode': {
      error: false,
      msg: ''
    }
  }

  const [validationErrors, setValidationErrors] = useState({ ...validationObj });

  const handleValidation = (formInput) => {
    formInput = formInput || createCompany;
    let validationerr = { ...validationObj };

    if (formInput.name == "") {
      validationerr.hasErr = true;
      validationerr.name = {
        error: true,
        msg: 'Name is required'
      }
    }

    if (formInput.email == "") {
      validationerr.hasErr = true;
      validationerr.email = {
        error: true,
        msg: 'Email is required'
      }
    }

    if (formInput.phone == '') {
      validationerr.hasErr = true;
      validationerr.phone = {
        error: true,
        msg: 'Phone is required'
      }
    }

    if (formInput.industry == '') {
      validationerr.hasErr = true;
      validationerr.industry = {
        error: true,
        msg: 'Industry is required'
      }
    }

    if (formInput.address_line1 == '') {
      validationerr.hasErr = true;
      validationerr.address_line1 = {
        error: true,
        msg: 'Address is required'
      }
    }

    if (formInput.country == '') {
      validationerr.hasErr = true;
      validationerr.country = {
        error: true,
        msg: 'Country is required'
      }
    }

    if (formInput.state == '') {
      validationerr.hasErr = true;
      validationerr.state = {
        error: true,
        msg: 'State is required'
      }
    }

    if (formInput.city == '') {
      validationerr.hasErr = true;
      validationerr.city = {
        error: true,
        msg: 'City is required'
      }
    }

    if (formInput.zipcode == '') {
      validationerr.hasErr = true;
      validationerr.zipcode = {
        error: true,
        msg: 'Zipcode is required'
      }
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  }

  let handleCreateCompany = async () => {

    if (!handleValidation()) {
      toast.error('Please correct form fields', TOAST_CONFIG);
      return false;
    }

    setLoading(true);
    try {
      let researchers = [];
      let contact_researchers = [];
      let contact_collaborators = [];
      let contact_observers = []
      let contact_participants = [];
      createCompany.contact_researchers.map(researcher => {
        researchers.push(researcher.id);
        if (researcher.role == "Researcher")
          return contact_researchers.push(researcher._id);
        else if (researcher.role == "Collaborator")
          return contact_collaborators.push(researcher._id);
        else if (researcher.role == "Observer")
          return contact_observers.push(researcher._id);
        else if (researcher.role == "Participant")
          return contact_participants.push(researcher._id);
      });

      if (researchers.length == 0 && (createCompany.contact_persons.length == 0 || (createCompany.contact_persons.length > 0 && createCompany.contact_persons[0].name == ''))) {
        toast.error('Please select either researchers or contact persons', TOAST_CONFIG);
        return false;
      }

      let contact_persons = createCompany.contact_persons;

      if (researchers.length > 0 && (createCompany.contact_persons.length == 0 || (createCompany.contact_persons.length > 0 && createCompany.contact_persons[0].name == ''))) {
        contact_persons = [];
      }
      // return console.log("payload", { ...createCompany, contact_researchers, contact_collaborators, contact_observers, contact_participants, contact_persons })
      const companyRes = await createAddNewCompany(
        { ...createCompany, contact_researchers, contact_collaborators, contact_observers, contact_participants, contact_persons },
        authState.authToken
      );
      setLoading(false);
      if (companyRes.success) {
        toast.success(companyRes.message, TOAST_CONFIG);
        if (setcreatedAffiliate !== false) {
          setcreatedAffiliate(companyRes.data);
        }
        setShowCompanyUser(true);
        handleBack();
      } else {
        toast.error(companyRes.message, TOAST_CONFIG);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: companyPop ? "flex" : "none" }}
        className="companyPop"
      >
        <Box
          sx={{ display: companyPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className="companyHeader">Add Company</Typography>
          <Typography mb={1} className="companyLabel">
            Company Name
          </Typography>
          <input
            name="name"
            value={createCompany.name}
            onChange={setCompanyFormValue}
            className="companyInput companyInputName"
            placeholder="Enter the full registered name of the company"
          />
          <span className="validationerr">{validationErrors.name.msg}</span>
          {/* Nav menu starts */}

          <Box className="companySettingNav">
            <Box
              onClick={() => setSettingTab(0)}
              className={
                settingTab === 0
                  ? "compSetNavTab compSetNavTabActive"
                  : "compSetNavTab"
              }
            >
              <Typography>Company Details</Typography>
            </Box>
            <Box
              onClick={() => setSettingTab(1)}
              className={
                settingTab === 1
                  ? "compSetNavTab compSetNavTabActive"
                  : "compSetNavTab"
              }
            >
              <Typography>Contact Person's Details</Typography>
            </Box>
          </Box>
          {/* Nav menu ends */}

          {/* Adding Companies */}
          <Box
            sx={{ display: settingTab === 0 ? "flex" : "none" }}
            className="settingBox"
          >
            <Box className="fistBox">
              <Box className="emailBox">
                <Typography className="companyInputLabel">
                  Email Address
                </Typography>
                <input
                  name="email"
                  value={createCompany.email}
                  onChange={setCompanyFormValue}
                  className="emailInput"
                  placeholder="Enter company's valid Email Address"
                />
                <span className="validationerr inputerr">{validationErrors.email.msg}</span>
              </Box>
              <Box className="industryBox">
                <Typography className="companyInputLabel">Industry</Typography>
                <input
                  onClick={() => setIndustryList(!industryList)}
                  // value={createCompany.industrylist_name}
                  value={industryListVal}
                  className="workspaceInputTag categoryInputTag"
                  placeholder="Choose a Category"
                />
                <img
                  style={{ rotate: industryList ? "180deg" : "0deg" }}
                  onClick={() => setIndustryList(!industryList)}
                  className="downArrowPop"
                  src={DownArrowIcon}
                />

                <Box
                  sx={{ height: industryList ? "auto" : "0px" }}
                  className="categoryDropDown"
                >
                  {industriesListsData.map((data) => (
                    <Box
                      key={data.id}
                      value={data.id}
                      className="categoryDropDownItem"
                      onClick={() => {
                        handleIndustryChange(data);
                        setIndustryList(false);
                        setIndustryListVal(data.name);
                      }}
                    >
                      <Typography>{data.name}</Typography>
                    </Box>
                  ))}
                </Box>
                <span className="validationerr">{validationErrors.industry.msg}</span>
              </Box>
            </Box>
            <Box className="addressBox">
              <Typography className="companyInputLabel">Address</Typography>
              <input
                name="address_line1"
                value={createCompany.address_line1}
                onChange={setCompanyFormValue}
                type="text"
                className="addInput"
                placeholder="Enter company's registered office address"
              />
              <span className="validationerr">{validationErrors.address_line1.msg}</span>
            </Box>
            <Box className="csPBox">
              <Typography className="companyInputLabel">
                Phone Number
              </Typography>
              <input
                name="phone"
                value={createCompany.phone}
                onChange={setCompanyFormValue}
                className="phoneInput"
                placeholder="Enter Phone Number"
              />
              <span className="validationerr inputerr">{validationErrors.phone.msg}</span>
            </Box>

            {/* Dropdown */}
            <Box className="dropDownOne lastBasicBox">
              <Box className="inputBoxOne">
                <Typography className="companyInputLabel">Country</Typography>
                <input
                  onClick={(e) => setCountryList(!countryList)}
                  // value={createCompany.country_name}
                  onChange={handleSearch}
                  value={countryListVal}
                  className="workspaceInputTag categoryInputTag"
                  placeholder="Choose a Category"
                />
                <img
                  style={{ rotate: countryList ? "180deg" : "0deg" }}
                  onClick={() => setCountryList(!countryList)}
                  className="downArrowPop"
                  src={DownArrowIcon}
                />

                <Box
                  sx={{
                    maxHeight: countryList ? "300px" : "0px",
                    overflowY: countryList ? "scroll" : "hidden",
                  }}
                  className="categoryDropDown"
                >
                  {filteredCountries.map((data) => (
                    <Box
                      key={data.id}
                      value={data.id}
                      className="categoryDropDownItem"
                      onClick={() => {
                        handleCountryChange(data);
                        setCountryList(false);
                        setCountryListVal(data.name);
                      }}
                    >
                      <Typography>{data.name}</Typography>
                    </Box>
                  ))}
                </Box>
                <span className="validationerr">{validationErrors.country.msg}</span>
              </Box>

              <Box className="inputBoxTwo">
                <Typography className="companyInputLabel">State</Typography>
                <input
                  onClick={() => setStateList(!stateList)}
                  // value={createCompany.state_name}
                  value={stateListVal}
                  className="workspaceInputTag categoryInputTag"
                  placeholder="Choose a Category"
                />
                <img
                  style={{ rotate: stateList ? "180deg" : "0deg" }}
                  onClick={() => setStateList(!stateList)}
                  className="downArrowPop"
                  src={DownArrowIcon}
                />

                <Box
                  sx={{
                    maxHeight: stateList ? "300px" : "0px",
                    overflowY: stateList ? "scroll" : "hidden",
                  }}
                  className="categoryDropDown"
                >
                  {statesData.map((data) => (
                    <Box
                      key={data.id}
                      value={data.id}
                      className="categoryDropDownItem"
                      onClick={() => {
                        handleStateChange(data, data.country_id);
                        setStateList(false);
                        setStateListVal(data.name);
                      }}
                    >
                      <Typography>{data.name}</Typography>
                    </Box>
                  ))}
                </Box>
                <span className="validationerr inputerr">{validationErrors.state.msg}</span>
              </Box>
            </Box>
            {/* dropdown */}

            <Box className="basicBox lastBasicBox counBox">
              <Box className="inputBoxOne">
                <Typography className="companyInputLabel">City</Typography>
                <input
                  onClick={() => setCityList(!cityList)}
                  // value={createCompany.city_name}
                  value={cityListVal}
                  className="workspaceInputTag categoryInputTag"
                  placeholder="Choose a Category"
                />
                <img
                  style={{ rotate: cityList ? "180deg" : "0deg" }}
                  onClick={() => setCityList(!cityList)}
                  className="downArrowPop"
                  src={DownArrowIcon}
                />

                <Box
                  sx={{
                    maxHeight: cityList ? "300px" : "0px",
                    overflowY: cityList ? "scroll" : "hidden",
                  }}
                  className="categoryDropDown"
                >
                  {citiesData.map((data) => (
                    <Box
                      key={data.id}
                      value={data.id}
                      className="categoryDropDownItem"
                      onClick={() => {
                        handleCityChange(data);
                        setCityList(false);
                        setCityListVal(data.name);
                      }}
                    >
                      <Typography>{data.name}</Typography>
                    </Box>
                  ))}
                </Box>
                <span className="validationerr">{validationErrors.city.msg}</span>
              </Box>

              <Box className="inputBoxFour">
                <Typography className="companyInputLabel">Zip Code</Typography>
                <input
                  name="zipcode"
                  value={createCompany?.zipcode}
                  onChange={setCompanyFormValue}
                  className="emailInput"
                  placeholder="Enter zip code here"
                />
                <span className="validationerr">{validationErrors.zipcode.msg}</span>
              </Box>
            </Box>
            {/* Dropdown2 */}
          </Box>

          {/* Contact Person's details */}
          <Box
            sx={{ display: settingTab === 1 ? "flex" : "none" }}
            className="settingBox contactBox"
          >
            {/* Input Drop Down */}
            <Box className="inputBox">
              <input
                className="workspaceInput"
                placeholder="Choose a Researcher"
                onClick={() => setresearcherDrop(!researcherDrop)}
                value={getResearcherDropVal()}
              />
              <img className="rightIcon" src={searchIcon} />
              <img
                style={{ rotate: researcherDrop ? "180deg" : "0deg" }}
                className="downArrowPop dropDownArrow"
                src={DownArrowIcon}
                onClick={() => setresearcherDrop(!researcherDrop)}
              />

              <Box
                sx={{ height: researcherDrop ? "282px" : "0px" }}
                className="categoryDrop researcherDrop"
              >
                <Box className="researchDropTopLabel">
                  <Typography onClick={selectAllCheckData}>
                    Select All
                  </Typography>
                  <Typography onClick={deleteAllCheckData}>
                    Deselect All
                  </Typography>
                </Box>

                {allResearchersList.map((researcher) => {
                  return <Box
                    onClick={() => {
                      if (createCompany?.contact_researchers?.some(el => (el?._id === researcher._id && el.role == researcher.role))) {
                        setCreateCompany({
                          ...createCompany,
                          contact_researchers: createCompany?.contact_researchers?.filter((item) => (item._id !== researcher._id && item.role !== researcher.role))
                        });
                      } else {
                        pushData(researcher);
                      }
                    }}
                    className="categoryDropItem"
                  >
                    <Box className="chackBox">
                      <img className="chackBoxImg" src={chackBox} />
                      <img
                        style={{
                          display: createCompany?.contact_researchers?.some(el => (el?._id == researcher._id && el.role == researcher.role))
                            ? "block"
                            : "none",
                        }}
                        className="chack"
                        src={check}
                      />
                    </Box>
                    <Typography>{researcher.name} ({researcher.role})</Typography>
                  </Box>
                })}
              </Box>
            </Box>

            {/* Input Drop Down Ends here */}

            {createCompany?.contact_persons?.map((contact_person, index) => (
              <Box key={index} className="contactPersonForm">
                <Box className="pName">
                  <Typography className="companyInputLabel">
                    First Contact Person’s Name
                  </Typography>
                  <input
                    name="name"
                    value={contact_person?.name}
                    onChange={(e) => handlePersonInputChange(e, index)}
                    className="nameInput"
                    placeholder="Enter Contact Person's Name"
                  />
                </Box>
                <Box className="secondBox">
                  <Box className="csPBox">
                    <Typography className="companyInputLabel">
                      Phone Number
                    </Typography>
                    <input
                      name="phone"
                      value={contact_person?.phone}
                      onChange={(e) => handlePersonInputChange(e, index)}
                      className="phoneInput"
                      placeholder="Enter Contact Person's Phone Number"
                    />
                  </Box>
                  <Box className="emailAddressBox">
                    <Typography className="companyInputLabel">
                      Email Address
                    </Typography>
                    <input
                      name="email"
                      value={contact_person?.email}
                      onChange={(e) => handlePersonInputChange(e, index)}
                      className="emailInput"
                      placeholder="Enter Contact Person's Email Address"
                    />
                  </Box>
                </Box>
                <Box className="contPName">
                  <Typography className="companyInputLabel">Address</Typography>
                  <input
                    name="address"
                    value={contact_person?.address}
                    onChange={(e) => handlePersonInputChange(e, index)}
                    className="addressInput"
                    placeholder="Enter Contact Person's Address"
                  />
                </Box>
                {index !== 0 && (
                  <Typography
                    className="personLink"
                    onClick={() => {
                      handleRemovePerson(index);
                    }}
                    style={{ color: "red" }}
                  >
                    Remove Contact Person
                  </Typography>
                )}
              </Box>
            ))}

            <Typography
              className="personLink"
              onClick={handleAddPerson}
              style={{ color: "red" }}
            >
              Add New Contact Person
            </Typography>
          </Box>

          <Box className="companyPopBtns">
            <Box className="workPupBtnBox">
              {workspaceCompany ? (
                <AppBtnOutLine buttonText="Back" onClick={() => handleBack()} />
              ) : null}
              {editWorkspaceCompany ? (
                <AppBtnOutLine
                  buttonText="Back"
                  onClick={() => handleBackAgain()}
                />
              ) : null}
              <AppBtnOutLine
                buttonText="Cancel"
                onClick={() => setCompanyPop(false)}
              />
              <AppBtn
                buttonText="Create"
                onClick={() => handleCreateCompany()}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
