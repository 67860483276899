import React from 'react';
import { Box, Typography } from "@mui/material";
import "./style.css";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";


//images
import backArrow from "../../../Assets/Images/noun-back.png";



//component
import SideMenu from "../../../Components/Participants/SideMenu";
import { sideHaumIcon } from "../../../Assets/Data";
import { AppBtn, AppBtnOutLine, PurplelBtn } from '../../../Components/AppTools/AppButton';


import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export default function PollAndSurvey({ slideMenu, setSlideMenu, workspaceDrop }) {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();


  const lineBar = 80;
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#4F33D1",
    },
  }));


  return (
    <>
      <Box className="ResearchersContainer FillItOutTaskPage ">
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon">
              {sideHaumIcon}
            </Box>
            <Box className="ontheDotNav">
              <PurplelBtn buttonText={t("commonTxt.previouspage")} />
              <AppBtn buttonText={t("commonTxt.nextPage")} />
            </Box>
          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer onthedot">
            <Box className="workspaceListBox">
              <Box mb={3} width="74px" onClick={() => Navigate("/participants/activities")} className="openBtn">
                <img src={backArrow} />
                <Typography className="neonBackText">Back</Typography>
              </Box>
              <Typography className='activeProgText'>{t("commonTxt.activityProgress")}</Typography>
              <Box className="lineBar">
                <Typography>{lineBar}%</Typography>
                <BorderLinearProgress variant="determinate" value={lineBar} />
              </Box>
              <Typography className="lineBarSubText">{t("onTheDro.TaskComp")}</Typography>

              <Box className="otdmainLabelBox">
                <Typography className='ActInSectionMainHeader'>{t("PollAndSurvey.pageHeader")}</Typography>
                <Box className="skipBtnBox">
                  <Typography>{t("onTheDro.SaveDraft")}</Typography>
                  <AppBtnOutLine buttonText="Skip Task" />
                </Box>
              </Box>

              <Typography className='subHeaderAc'>1. {t("PollAndSurvey.redioLabel1")}</Typography>
              <Typography>{t("FillItOutTask.PickFavourite")}</Typography>

              <Box mt={2} sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                  defaultChecked
                />
                <Typography>{t("commonTxt.Dog")}</Typography>
              </Box>

              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                  defaultChecked
                />
                <Typography>{t("commonTxt.Cat")}</Typography>
              </Box>

              <Box mb={6} mt={3}>
                <Typography>{t("PollAndSurvey.inputLabel")}</Typography>
                <textarea className='massageInput' placeholder="Enter your response here" />
              </Box>


              <Typography className='subHeaderAc'>2. {t("PollAndSurvey.redioLabel2")}</Typography>
              <Typography>{t("FillItOutTask.PickFavourite")}</Typography>

              <Box mt={2} sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                  defaultChecked
                />
                <Typography>{t("PollAndSurvey.SoftwareDevelopment")}</Typography>
              </Box>

              <Box mt={2} sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                  defaultChecked
                />
                <Typography><span style={{ color: '#4F33D1' }}>{t("PollAndSurvey.WebDesigning")}</span></Typography>
              </Box>

              <Box mt={2} sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                  defaultChecked
                />
                <Typography>DevOps</Typography>
              </Box>

              <Box mt={2} sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                <input
                  className="emailSettingInput emailSettingRadio"
                  type="radio"
                  name="projectState"
                  defaultChecked
                />
                <Typography>{t("PollAndSurvey.Testing")}</Typography>
              </Box>

              <Box mt={3}>
                <Typography>{t("PollAndSurvey.inputLabel")}</Typography>
                <textarea className='massageInput' placeholder="Enter your response here" />
              </Box>



            </Box>
          </Box>
        </Box>
      </Box>

    </>
  )
}
