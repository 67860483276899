import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import "./style.css";
import { useTranslation } from "react-i18next";
import axios from "../../../lib/axios";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../../../env";

import { AppBtn, AppBtnOutLine } from "../../../Components/AppTools/AppButton";
//images
import iIcon from "../../../Assets/Images/IIcon.png";
import { AntSwitch } from "../../../Components/AppTools/AppTool";

import { useAppSelector } from '../../../lib/store/store';
import { renderUserLayout } from "../../../lib/helpers";
import { TOAST_CONFIG } from "../../../lib/constants";

export default function Account({ }) {
  const authState = useAppSelector((state) => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace?.selectedWorkspace?._id);
  const [accountTab, setAccountTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const initializeAccountObj = {
    workspace_id: workspaceState,
    is_customize_field_available: false,
    participant_registration_fields: [],
    account_update_fields:[]
  };

  const initialize2Fabj = {
    workspace_id: workspaceState,
    enforce_2fa_roles: [],
  };

  const [accountSetting, setAccountSetting] = useState({ ...initializeAccountObj });
  const [faSetting, setFaSetting] = useState({ ...initialize2Fabj });

  useEffect(() => {
    fetchAccountSetting();
  }, []);

  const fetchAccountSetting = async () => {
    try {
      const response = await axios.get(`/researchers/workspace-settings?workspace_id=${workspaceState}`);
      if (response.success) {
        let initAccount2Fa = { ...initializeAccountObj };
        let init2Fa = { ...initialize2Fabj };
        const account2fa = response.data?.data[0];
        console.log("Account 2FA=>",account2fa);
        let key;
        let key1;
        for (key in initAccount2Fa) {
          initAccount2Fa[key] = account2fa[key];
        }
        for (key1 in init2Fa) {
          init2Fa[key1] = account2fa[key1];
        }
        setAccountSetting(initAccount2Fa);
        setFaSetting(init2Fa);
        console.log("Account 2FA Second=>",accountSetting);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
    finally {
      setLoading(true);
    }
  }

  const isAccountRegisterChecked = (lenSt) => {
    
    let registerChecked = accountSetting?.participant_registration_fields.includes(lenSt) ? true : false;
    //console.log("Default Checked Value=>",lenSt,registerChecked);
    return registerChecked;
  };

  const isAccountUpdateChecked = (lenSt) => {
    return accountSetting?.account_update_fields.includes(lenSt) ? true : false;
  };

  const isEnable2FaRole = (role) => {
    return faSetting?.enforce_2fa_roles.includes(role) ? true : false;
  }

  const handleCustomField = (e) => {
    if(e.target.checked){
      setAccountSetting({ ...accountSetting, is_customize_field_available: true });
    }
    else{
      setAccountSetting({ ...accountSetting, is_customize_field_available: false });
    }
  }

  const changeRegisterSetting = (e,value2) => {
    if(e.target.checked){
    if(accountSetting?.participant_registration_fields.includes(value2)){

    }
    else {
      accountSetting?.participant_registration_fields.push(value2);
    }
  }
  else {
    let index5= accountSetting?.participant_registration_fields.indexOf(value2);
    accountSetting?.participant_registration_fields.splice(index5, 1);
  }
    // emailNotification?.hide_settings.indexOf(value2) === -1 ? selectedItems.push(value2): ''
    //console.log("Register Checkbox Value=>",accountSetting);
  }

  const changeUpdateSetting = (e,value2) => {
    if(e.target.checked){
    if(accountSetting?.account_update_fields.includes(value2)){

    }
    else {
      accountSetting?.account_update_fields.push(value2);
    }
  }
  else {
    let index5= accountSetting?.account_update_fields.indexOf(value2);
    accountSetting?.account_update_fields.splice(index5, 1);
  }
    // emailNotification?.hide_settings.indexOf(value2) === -1 ? selectedItems.push(value2): ''
    //console.log("Register Checkbox Value=>",accountSetting);
  }

  let updateAccountSetting = async () => {
    console.log("Notification Email=>",accountSetting);
    setLoading(true);
    try {
      let response = await axios.post(
        API_BASE_URL + `/researchers/workspace-settings/save`,
        accountSetting,
        authState.authToken
      );
      if(response.success){
        setAccountSetting();
        toast.success(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      let message = "Something went wrong, please try again!";
      let status_code = 500;
      if (error.response) {
        message = error.response.data.message;
        status_code = error.response.status;
      }
      return {
        success: false,
        message: message,
        status_code: status_code,
      };
    }
  };

  const change2FaRole = (el,role2) => {
    if(el.target.checked){
    if(faSetting?.enforce_2fa_roles.includes(role2)){

    }
    else {
      faSetting?.enforce_2fa_roles.push(role2);
    }
  }
  else {
    let index4= faSetting?.enforce_2fa_roles.indexOf(role2);
    faSetting?.enforce_2fa_roles.splice(index4, 1);
  }
  }

  let update2FaSetting = async () => {
    console.log("Notification Email=>",faSetting);
    setLoading(true);
    try {
      let response = await axios.post(
        API_BASE_URL + `/researchers/workspace-settings/save`,
        faSetting,
        authState.authToken
      );
      if(response.success){
        setFaSetting();
        toast.success(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      let message = "Something went wrong, please try again!";
      let status_code = 500;
      if (error.response) {
        message = error.response.data.message;
        status_code = error.response.status;
      }
      return {
        success: false,
        message: message,
        status_code: status_code,
      };
    }
  };

  

  const getChildren = () => {
    return <Box className="researchersMainBox pageContainer">
      <Box className="EmailSContainer">
        <Box className="agreementSettingNav accountNav">
          <Box
            onClick={() => setAccountTab(0)}
            className={
              accountTab === 0
                ? "agreeSetNavTab agreeSetNavTabActive"
                : "agreeSetNavTab"
            }
          >
            <Typography>{t("account.accFields")}</Typography>
          </Box>
          <Box
            onClick={() => setAccountTab(1)}
            className={
              accountTab === 1
                ? "agreeSetNavTab agreeSetNavTabActive"
                : "agreeSetNavTab"
            }
          >
            <Typography>{t("account.2FA")}</Typography>
          </Box>
        </Box>

        {loading && (
        <Box
          sx={{ display: accountTab === 0 ? "flex" : "none" }}
          className="settingBox"
        >
          <Typography className="bsTitle">
            {t("account.accFields")}
          </Typography>
          <Typography className="bsNText">
            {t("account.selectAcc")}
          </Typography>

          <Box className="languageBoxItem">
            <Box>
              <Typography>{t("account.accRegistration")}</Typography>
            </Box>
          </Box>
          <Box className="checkBoxLanguageItem">
            <Box>
              <input type="checkBox" onChange={(e) => changeRegisterSetting(e,'fname')} defaultChecked={isAccountRegisterChecked('fname')} />
              <Typography className="languageLabel">
                {t("account.fName")}
              </Typography>
            </Box>
            <Box>
              <input type="checkBox" onChange={(e) => changeRegisterSetting(e,'email')} defaultChecked={isAccountRegisterChecked('email')} />
              <Typography className="languageLabel">
                {t("account.emailAdd")}
              </Typography>
            </Box>
          </Box>

          <Box className="checkBoxLanguageItem">
            <Box>
              <input type="checkBox" onChange={(e) => changeRegisterSetting(e,'profile_pic')} defaultChecked={isAccountRegisterChecked('profile_pic')} />
              <Typography className="languageLabel">
                {t("account.profilePhoto")}
              </Typography>
            </Box>
            <Box>
              <input type="checkBox" onChange={(e) => changeRegisterSetting(e,'username')} defaultChecked={isAccountRegisterChecked('username')} />
              <Typography className="languageLabel">
                {t("account.username")}
              </Typography>
            </Box>
          </Box>

          <Box className="checkBoxLanguageItem">
            <Box>
              <input type="checkBox" onChange={(e) => changeRegisterSetting(e,'lname')} defaultChecked={isAccountRegisterChecked('lname')} />
              <Typography className="languageLabel">
                {t("account.lastName")}
              </Typography>
            </Box>
          </Box>

          <Box className="languageBoxItem">
            <Box>
              <Typography>{t("account.accUpdates")}</Typography>
            </Box>
          </Box>

          <Box className="checkBoxLanguageItem">
            <Box>
              <input type="checkBox" onChange={(e) => changeUpdateSetting(e,'fname')} defaultChecked={isAccountUpdateChecked('fname')} />
              <Typography className="languageLabel">
                {t("account.fName")}
              </Typography>
            </Box>
            <Box>
              <input type="checkBox" onChange={(e) => changeUpdateSetting(e,'email')} defaultChecked={isAccountUpdateChecked('email')} />
              <Typography className="languageLabel">
                {t("account.emailAdd")}
              </Typography>
            </Box>
          </Box>

          <Box className="checkBoxLanguageItem">
            <Box>
              <input type="checkBox" onChange={(e) => changeUpdateSetting(e,'profile_pic')} defaultChecked={isAccountUpdateChecked('profile_pic')} />
              <Typography className="languageLabel">
                {t("account.profilePhoto")}
              </Typography>
            </Box>
            <Box>
              <input type="checkBox" onChange={(e) => changeUpdateSetting(e,'username')} defaultChecked={isAccountUpdateChecked('username')} />
              <Typography className="languageLabel">
                {t("account.username")}
              </Typography>
            </Box>
          </Box>

          <Box className="checkBoxLanguageItem">
            <Box>
              <input type="checkBox" onChange={(e) => changeUpdateSetting(e,'lname')} defaultChecked={isAccountUpdateChecked('lname')} />
              <Typography className="languageLabel">
                {t("account.lastName")}
              </Typography>
            </Box>
          </Box>

          <Box className="checkBoxLanguageItem">
            <Box>
              <input type="checkBox" onClick={(e) => {
                    handleCustomField(e);
                  }} defaultChecked={accountSetting?.is_customize_field_available} />
              <Typography className="languageLabel">
                {t("account.customizeFields")}
              </Typography>
            </Box>
          </Box>
          <Box className="languageBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} />
            <AppBtn buttonText={t("commonTxt.saveBtn")} onClick={() => updateAccountSetting()} />
          </Box>
        </Box>)}
        
        {loading && (
        <Box
          sx={{ display: accountTab === 1 ? "flex" : "none" }}
          className="settingBox"
        >
          <Box className="iBox">
            <img src={iIcon} />
            <Typography>{t("account.searchEngines")}</Typography>
          </Box>
          <Typography className="bsNText">
            {t("account.enforceUseof2FA")}
          </Typography>

          <Box className="airadioSwitchBox accountRole">
            <AntSwitch onChange={(el) => change2FaRole(el,'researcher')} defaultChecked={isEnable2FaRole('researcher')} inputProps={{ "aria-label": "ant design" }} />
            <Typography className="switchText">
              {t("account.researchers")}
            </Typography>
          </Box>
          <Box className="airadioSwitchBox accountRole">
            <AntSwitch onChange={(el) => change2FaRole(el,'collaborator')} defaultChecked={isEnable2FaRole('collaborator')} inputProps={{ "aria-label": "ant design" }} />
            <Typography className="switchText">
              {t("account.collaborators")}
            </Typography>
          </Box>
          <Box className="airadioSwitchBox accountRole">
            <AntSwitch onChange={(el) => change2FaRole(el,'observer')} defaultChecked={isEnable2FaRole('observer')} inputProps={{ "aria-label": "ant design" }} />
            <Typography className="switchText">
              {t("account.observers")}
            </Typography>
          </Box>
          <Box className="airadioSwitchBox accountRole">
            <AntSwitch onChange={(el) => change2FaRole(el,'participant')} defaultChecked={isEnable2FaRole('participant')} inputProps={{ "aria-label": "ant design" }} />
            <Typography className="switchText">
              {t("account.participants")}
            </Typography>
          </Box>
          <Box className="languageBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} />
            <AppBtn buttonText={t("commonTxt.saveBtn")} onClick={() => update2FaSetting()} />
          </Box>
        </Box>)}

      </Box>
    </Box>
  }

  return renderUserLayout(authState?.userType, null, getChildren(), 'workadmin');
}
