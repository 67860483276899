import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import { useTranslation } from "react-i18next";

import {
  Box,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Divider,
  List,
  ListItem,
  RadioGroup,
  Radio,
} from "@mui/material";

import backArrow from "../../../Assets/Images/noun-back.png";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import VisibleIcon from "../../../Assets/Images/VisibleIcon.png";
import DummyUser from "../../../Assets/Images/user-dummy.png";
import shareIcon from "../../../Assets/Images/shareIcon.png";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";

//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { AntSwitch } from "../../AppTools/AppTool";
import { EditAdminList } from "../../../Assets/Data";
import { useAppSelector } from "../../../lib/store/store";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { useNavigate } from "react-router";
import {
  createWorkspaceAdminAPI,
  uploadUserDirectoryImage,
} from "../../../Services/adminActions";
import { getCountries } from "../../../Services/master";

export default function AddNewAdmin({
  NewAdminSection,
  setNewAdminSection,
  setReloadList,
}) {
  const Navigate = useNavigate();
  let authState = useAppSelector((state) => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace);

  const [redio, setRedio] = useState(0);
  const [pwdVisible, setPwdVisible] = useState(false);
  const [pwdVisible2, setPwdVisible2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [countryDrop, setCountryDrop] = useState(false);
  const [countryDropVal, setCountryDropVal] = useState();
  const [timeZoneDrop, setTimeZoneDrop] = useState(false);
  const [timeZoneList, setTimezoneList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [timeZoneVal, setTimeZoneVal] = useState("Select Time Zone");

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const initialWsAdminObj = {
    name: "",
    email: "",
    username: "",
    // phone:"",
    password: "",
    confirm_password: "",
    two_fa_enabled: false,
    role: "",
    // is_unsubscribe_emails_check:false,
    force_password: false,
    // initial_2fa:false,
    donot_enforce_2fa: false,
    confirm_username: false,
    status: "active",
    workspaces: [workspaceState?.selectedWorkspace?._id],
    // country:"",
    timezone: "",
  };

  console.log(
    "Value of workspaceState:",
    workspaceState?.selectedWorkspace?._id
  );

  const [createNewAdmin, setCreateNewAdmin] = useState({
    ...initialWsAdminObj,
  });

  useEffect(() => {
    getAllCountryData();
  }, []);

  const setFormValue = (e) => {
    let newFormData = {
      ...createNewAdmin,
      [e.target.name]: e.target.value,
    };
    setCreateNewAdmin(newFormData);
  };

  const handleToggleChange = (key) => (event) => {
    const newStatus = event.target.checked;
    setCreateNewAdmin({
      ...createNewAdmin,
      [key]: newStatus,
    });
  };

  const handleRadioChange = (event) => {
    const newRole = event.target.value;
    setCreateNewAdmin((prevState) => ({
      ...prevState,
      role: newRole,
    }));
  };

  const handleTimeZone = (el) => {
    setTimeZoneVal(el);
    const newStatus = {
      ...createNewAdmin,
      timezone: el,
    };
    setCreateNewAdmin(newStatus);
  };

  const getAllCountryData = () => {
    getCountries().then((response) => {
      if (response.success) {
        if (response?.data?.data.length > 0) {
          const country = response?.data?.data;
          setCountryList(country);
        }
      }
    });
  };

  let handleCreateWorkspaceAdmin = async () => {
    setLoading(true);
    try {
      if (createNewAdmin?.password !== createNewAdmin?.confirm_password) {
        toast.error("password not match");
        return;
      }
      let { confirm_password, ...adminData } = createNewAdmin;
      console.log(adminData);
      let adminRes = await createWorkspaceAdminAPI(
        adminData,
        authState.authToken
      );
      setLoading(false);
      if (adminRes.success) {
        toast.success(adminRes.message, TOAST_CONFIG);
        setCreateNewAdmin({ ...initialWsAdminObj });
        setNewAdminSection(false);
        setReloadList(true);
      } else {
        toast.error(adminRes.message, TOAST_CONFIG);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      updateImage(file);
    }
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput").click();
  };

  const updateImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      let imageUploadRes = await uploadUserDirectoryImage(
        formData,
        authState.authToken
      );
      if (imageUploadRes.success) {
        setCreateNewAdmin({
          ...createNewAdmin,
          profile_pic: imageUploadRes?.data?.upload_id,
        });
        toast.success(imageUploadRes.message, TOAST_CONFIG);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  };

  return (
    <>
      <Box
        className="EditAdminSection"
        sx={{ display: NewAdminSection ? "block" : "none" }}
      >
        <Box
          mb={2}
          onClick={() => setNewAdminSection(false)}
          className="openBtn"
        >
          <img src={backArrow} />
          <Typography className="neonBackText">
            {t("commonTxt.backBtn")}
          </Typography>
        </Box>
        <Typography variant="h4">{t("admin.newAdministrator")}</Typography>
        <Box className="avatarInfoBox">
          <Box className="avatarImgBox">
            {!image || image === "undefined" ? (
              <img src={DummyUser} alt="Dummy User" />
            ) : (
              <img src={image} alt="Profile" />
            )}
          </Box>
          <Box className="avatarIfoBox">
            <Typography mb={3}>Admin</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                mr={2}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                onClick={triggerFileInput}
              >
                <img src={shareIcon} />
                <Typography ml={1}>Upload Image</Typography>
              </Box>
            </Box>
          </Box>
          <input
            id="fileInput"
            style={{ display: "none" }}
            type="file"
            onChange={handleFileInputChange}
          />
        </Box>
        {/* {EditAdminList &&
          EditAdminList.map((el, index) => ( */}
        <Grid container spacing={4}>
          <Grid item sm={12} sx={{ marginBottom: "5px" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              defaultValue="Active"
              name="row-radio-buttons-group"
            >
              <Box
                className="redioItem"
                onClick={() => {
                  setRedio(0);
                  setCreateNewAdmin({
                    ...createNewAdmin,
                    status: "active",
                  });
                }}
              >
                <Box
                  className={
                    redio === 0
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>{t("admin.active")}</Typography>
              </Box>

              <Box
                className="redioItem"
                onClick={() => {
                  setRedio(1);
                  setCreateNewAdmin({
                    ...createNewAdmin,
                    status: "inactive",
                  });
                }}
              >
                <Box
                  className={
                    redio === 1
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>{t("admin.inactive")}</Typography>
              </Box>
            </RadioGroup>
          </Grid>
        </Grid>
        {/* ))} */}
        <Typography className="addAdminLabels addAdminAccountDetailLabel">
          {t("admin.accDetails")}
        </Typography>
        <Box className="csPBox">
          <Typography className="companyInputLabel" mt={2} mb={1}>
            Name
          </Typography>
          <input
            className="phoneInput"
            placeholder={t("admin.enterName")}
            name="name"
            value={createNewAdmin?.name}
            onChange={setFormValue}
          />
        </Box>

        <Box className="FormField">
          <FormLabel className="label">{t("admin.email")}</FormLabel>
          <input
            className="phoneInput"
            placeholder="Enter Email"
            name="email"
            value={createNewAdmin?.email}
            onChange={setFormValue}
          />
        </Box>

        <Box className="FormField">
          <FormLabel className="label">{t("admin.uName")}</FormLabel>
          <Typography className="sublabel">{t("admin.noSpace")}</Typography>
          <input
            className="phoneInput"
            placeholder="Enter Username"
            name="username"
            value={createNewAdmin?.username}
            onChange={setFormValue}
          />
          <Box
            className="airadioSwitchBox"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              margin: "0",
              marginTop: "10px",
            }}
          >
            <AntSwitch
              inputProps={{ "aria-label": "confirm username" }}
              checked={createNewAdmin.confirm_username}
              onChange={handleToggleChange("confirm_username")}
            />
            <Typography
              className="switchText"
              sx={{ marginBottom: "0px !important" }}
            >
              {t("admin.mustConfirmUname")}
            </Typography>
          </Box>
        </Box>

        <Box className="FormField">
          <Typography mb={1}>{t("admin.newPassword")}</Typography>
          <Typography fontSize={"12px"} mb={1}>
            {t("admin.atLeast8Char")}
          </Typography>
          <Box mb={1} className="csPBox pwdInput">
            <input
              className="phoneInput"
              placeholder={t("admin.enterPassword")}
              type={pwdVisible ? "text" : "password"}
              name="password"
              value={createNewAdmin.password}
              onChange={setFormValue}
            />
            <img src={VisibleIcon} onClick={() => setPwdVisible(!pwdVisible)} />
          </Box>
          <Divider className="gapbetween" />
          <Box mb={1} className="csPBox pwdInput">
            <input
              className="phoneInput"
              placeholder="Confirm New Password"
              type={pwdVisible2 ? "text" : "password"}
              name="confirm_password"
              onChange={setFormValue}
              value={createNewAdmin?.confirm_password}
            />
            <img
              src={VisibleIcon}
              onClick={() => setPwdVisible2(!pwdVisible2)}
            />
          </Box>
          <Box
            className="airadioSwitchBox"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              margin: "0",
              marginTop: "5px",
            }}
          >
            <AntSwitch
              inputProps={{ "aria-label": "forgot password change" }}
              checked={createNewAdmin.force_password}
              onChange={handleToggleChange("force_password")}
            />
            <Typography
              className="switchText"
              sx={{ marginBottom: "0px !important" }}
            >
              {t("admin.forcePasswordChange")}
            </Typography>
          </Box>
        </Box>

        <Box className="FormField">
          <Typography className="addAdminLabels">
            {t("admin.2FactorAuth")}
          </Typography>
          <Typography className="sublabel">{t("admin.2FAAuth")}</Typography>
          <Box
            className="airadioSwitchBox"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              margin: "0",
              marginTop: "5px",
            }}
          >
            <AntSwitch
              inputProps={{ "aria-label": "enabled" }}
              checked={createNewAdmin.two_fa_enabled}
              onChange={handleToggleChange("two_fa_enabled")}
            />
            <Typography
              className="switchText"
              sx={{ marginBottom: "0px !important" }}
            >
              {t("admin.enabled")}
            </Typography>
          </Box>
        </Box>

        <Box className="FormField" sx={{ marginLeft: "50px" }}>
          {/* <Box
            className="airadioSwitchBox"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              margin: "0",
              marginTop: "5px",
            }}
          >
            <AntSwitch />
            <Typography
              className="switchText"
              sx={{ marginBottom: "0px !important" }}
            >
              {t("admin.enfByRole")}
            </Typography>
          </Box> */}
          <Box className="alpOverCheck">
            <input
              style={{ marginRight: "25px" }}
              className="custom-checkbox"
              type="checkBox"
              checked={createNewAdmin.donot_enforce_2fa}
              onChange={handleToggleChange("donot_enforce_2fa")}
            />
            <Typography>{t("admin.overRiderdnEnf")}</Typography>
          </Box>
        </Box>

        <Box mt={3} className="companiesTopBox">
          <Box className="companyInputBoxWlabel">
            <Typography mb={1} className="inputLabel">
              Select Country
            </Typography>

            <Box
              className="inputAppDrop"
              onClick={() => setCountryDrop(!countryDrop)}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography>
                  {countryList[countryDropVal]?.emoji || countryList[0]?.emoji}

                  {countryList[countryDropVal]?.name || countryList[0]?.name}
                </Typography>
              </Box>
              <img
                style={{ rotate: countryDrop ? "180deg" : "0deg" }}
                src={DownArrowIcon}
              />
              {/* pop */}
              <Box
                sx={{
                  height: countryDrop ? "180px" : "0px",
                  overflowY: countryDrop ? "scroll" : "hidden",
                }}
                className="statusPop"
              >
                {countryList &&
                  countryList.map((el, index) => (
                    <Box
                      key={index}
                      onClick={() => {
                        setCountryDropVal(index);
                        setCreateNewAdmin({
                          ...createNewAdmin,
                          country: el?._id,
                        });
                        setTimezoneList(el.timezones);
                      }}
                      className="statusPopItem"
                    >
                      {/* <img className="countryListImg" src={el.img} /> */}
                      <Typography>{`${el.emoji} ${el.name}`}</Typography>
                    </Box>
                  ))}
              </Box>
            </Box>
          </Box>
          {/* drop */}
          <Box className="companyInputBoxWlabel">
            <Typography mb={1} className="inputLabel">
              Select Time Zone
            </Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setTimeZoneDrop(!timeZoneDrop)}
            >
              <Typography>
                {timeZoneVal || "Filter by Workspace Status"}
              </Typography>
              <img
                style={{
                  rotate: timeZoneDrop ? "180deg" : "0deg",
                }}
                src={DownArrowIcon}
              />
              {/* pop */}
              <Box
                sx={{ height: timeZoneDrop ? "auto" : "0px" }}
                className="statusPop"
              >
                {timeZoneList &&
                  timeZoneList?.map((el, index) => (
                    <Box
                      key={index}
                      onClick={() => handleTimeZone(el?.zoneName)}
                      className="statusPopItem"
                    >
                      <Typography>{el?.zoneName}</Typography>
                    </Box>
                  ))}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="FormField">
          <Typography className="addAdminLabels">{t("admin.role")}</Typography>
          <Box className="radioBox">
            <Box>
              <input
                className="emailSettingInput emailSettingRadio"
                type="radio"
                name="role"
                value="researcher"
                checked={createNewAdmin.role === "researcher"}
                onChange={handleRadioChange}
              />
            </Box>
            <Box>
              <Typography
                style={{ marginTop: "35px" }}
                className="emailSettingLabel"
              >
                {t("admin.researcher")}
              </Typography>
              <Typography className="sublabel">
                {t("admin.fullAccess")}
              </Typography>
            </Box>
          </Box>
          <Box className="radioBox">
            <Box>
              <input
                className="emailSettingInput emailSettingRadio"
                type="radio"
                name="role"
                value="collaborator"
                checked={createNewAdmin.role === "collaborator"}
                onChange={handleRadioChange}
              />
            </Box>
            <Box>
              <Typography
                style={{ marginTop: "35px" }}
                className="emailSettingLabel"
              >
                {t("admin.collaborator")}
              </Typography>
              <Typography className="sublabel">
                {t("admin.fullControlOver")}
              </Typography>
            </Box>
          </Box>
          <Box className="radioBox">
            <Box>
              <input
                className="emailSettingInput emailSettingRadio"
                type="radio"
                name="role"
                value="observer"
                checked={createNewAdmin.role === "observer"}
                onChange={handleRadioChange}
              />
            </Box>
            <Box>
              <Typography
                style={{ marginTop: "35px" }}
                className="emailSettingLabel"
              >
                {t("admin.observer")}
              </Typography>
              <Typography className="sublabel">
                {t("admin.limitedControl")}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className="workPupBtnBox">
          <AppBtnOutLine
            buttonText={t("commonTxt.cancelBtn")}
            onClick={() => setNewAdminSection(false)}
          />
          <AppBtn
            buttonText={t("commonTxt.saveBtn")}
            onClick={() => handleCreateWorkspaceAdmin()}
          />
        </Box>
      </Box>
    </>
  );
}
