import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../lib/store/store";

import "./style.css";

//images
import optionIcon from "../../../Assets/Images/optionIcon.png";
import ActiveOptionIcon from "../../../Assets/Images/ActiveDot.png";
//data
import { sideHaumIcon } from "../../../Assets/Data";

//components
import SideMenu from "../../../Components/Researchers/SideMenu";
import ProjectManagementExcerpt from "../../../Components/Researchers/ProjectManagementExcerpt";

import ProjectManagementSetting from "../../../Components/Researchers/ProjectManagementSetting";

// observer's component
import ProjectManagementSettingObserver from "../../../Components/Observer/ProjectManagementSetting";
import ProjectDirectoryObserver from "../../../Components/Observer/ProjectDirectory";
import ProjectCodeBookObserver from "../../../Components/Observer/ProjectCodeBook";
import ProjectSettingTranscriptObserver from "../../../Components/Observer/ProjectSettingTranscript";
import ProjectSettingReportsObserver from "../../../Components/Observer/ProjectSettingReports";
// observer's component ends

import ProjectReviews from "../../../Components/Researchers/ProjectReviews";
import ProjectCodeBook from "../../../Components/Researchers/ProjectCodeBook";
import ProjectSettingTranscript from "../../../Components/Researchers/ProjectSettingTranscript";
import ProjectSettingBroadcast from "../../../Components/Researchers/ProjectSettingBroadcast";
import ProjectDirectory from "../../../Components/Researchers/ProjectDirectory";
import ProjectVideoPlaylist from "../../../Components/Researchers/ProjectVideoPlaylist";
import ProjectSettingReports from "../../../Components/Researchers/ProjectSettingReports";

import {
  USER_TYPE_COLLABORATOR,
  USER_TYPE_RESEARCHER,
  USER_TYPE_OBSERVER,
} from "../../../lib/constants";

export default function ProjectManagement({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
}) {
  const [projectTabe, setProjectTabe] = useState(0);
  const [navOption, setNavOption] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  let authState = useAppSelector((state) => state.auth);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  useEffect(() => {
    let selTab = searchParams.get("dr");
    if (selTab != null) {
      setProjectTabe(parseInt(selTab));
    } else {
      setProjectTabe(0);
    }
  }, []);

  return (
    <>
      <Box className="ResearchersContainer ">
        {/* side menu */}
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>

            {authState?.wsUserType == USER_TYPE_RESEARCHER ||
            authState?.wsUserType == USER_TYPE_COLLABORATOR ? (
              <Box className="projectNavItems">
                <Box
                  onClick={() => {
                    setSearchParams({ dr: 0 });
                    setProjectTabe(0);
                  }}
                  className={
                    projectTabe === 0
                      ? "recapNavItem recapNavItemActive"
                      : "recapNavItem"
                  }
                >
                  <Typography>
                    {t("projectManegementPage.projectManegementNav1")}
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    setSearchParams({ dr: 1 });
                    setProjectTabe(1);
                  }}
                  className={
                    projectTabe === 1
                      ? "recapNavItem recapNavItemActive mNav1"
                      : "recapNavItem mNav1"
                  }
                >
                  <Typography>
                    {t("projectManegementPage.projectManegementNav2")}
                  </Typography>
                </Box>
                <Box className="vrLine"> </Box>
                <Box
                  onClick={() => {
                    setSearchParams({ dr: 2 });
                    setProjectTabe(2);
                  }}
                  className={
                    projectTabe === 2
                      ? "recapNavItem recapNavItemActive mNav2"
                      : "recapNavItem  mNav2"
                  }
                >
                  <Typography>
                    {t("projectManegementPage.projectManegementNav3")}
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    setSearchParams({ dr: 3 });
                    setProjectTabe(3);
                  }}
                  className={
                    projectTabe === 3
                      ? "recapNavItem recapNavItemActive mNav3"
                      : "recapNavItem  mNav3"
                  }
                >
                  <Typography>
                    {t("projectManegementPage.projectManegementNav4")}
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    setSearchParams({ dr: 4 });
                    setProjectTabe(4);
                  }}
                  className={
                    projectTabe === 4
                      ? "recapNavItem recapNavItemActive mNav4"
                      : "recapNavItem  mNav4"
                  }
                >
                  <Typography>
                    {t("projectManegementPage.projectManegementNav5")}
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    setSearchParams({ dr: 5 });
                    setProjectTabe(5);
                  }}
                  className={
                    projectTabe === 5
                      ? "recapNavItem recapNavItemActive mNav5"
                      : "recapNavItem  mNav5"
                  }
                >
                  <Typography>
                    {t("projectManegementPage.projectManegementNav6")}
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    setSearchParams({ dr: 6 });
                    setProjectTabe(6);
                  }}
                  className={
                    projectTabe === 6
                      ? "recapNavItem recapNavItemActive mNav6"
                      : "recapNavItem  mNav6"
                  }
                >
                  <Typography>
                    {t("projectManegementPage.projectManegementNav7")}
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    setSearchParams({ dr: 7 });
                    setProjectTabe(7);
                  }}
                  className={
                    projectTabe === 7
                      ? "recapNavItem recapNavItemActive mNav7"
                      : "recapNavItem  mNav7"
                  }
                >
                  <Typography>
                    {t("projectManegementPage.projectManegementNav8")}
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    setSearchParams({ dr: 8 });
                    setProjectTabe(8);
                  }}
                  className={
                    projectTabe === 8
                      ? "recapNavItem recapNavItemActive mNav8"
                      : "recapNavItem  mNav8"
                  }
                >
                  <Typography>
                    {t("projectManegementPage.projectManegementNav9")}
                  </Typography>
                </Box>
                {/* --------------------------------------- */}
                <Box
                  onClick={() => setNavOption(!navOption)}
                  className="PMNavOption"
                >
                  <img src={navOption ? ActiveOptionIcon : optionIcon} />
                  <Box
                    sx={{ height: navOption ? "auto" : "0px" }}
                    className="statusPop"
                  >
                    <Box
                      className="statusPopItem sNav1"
                      onClick={() => {
                        setSearchParams({ dr: 2 });
                        setProjectTabe(2);
                      }}
                    >
                      <p>{t("projectManegementPage.projectManegementNav3")}</p>
                    </Box>
                    <Box
                      className="statusPopItem sNav2"
                      onClick={() => {
                        setSearchParams({ dr: 3 });
                        setProjectTabe(3);
                      }}
                    >
                      <p>{t("projectManegementPage.projectManegementNav4")}</p>
                    </Box>
                    <Box
                      className="statusPopItem sNav3"
                      onClick={() => {
                        setSearchParams({ dr: 4 });
                        setProjectTabe(4);
                      }}
                    >
                      <p>{t("projectManegementPage.projectManegementNav5")}</p>
                    </Box>
                    <Box
                      className="statusPopItem sNav4"
                      onClick={() => {
                        setSearchParams({ dr: 5 });
                        setProjectTabe(5);
                      }}
                    >
                      <p>{t("projectManegementPage.projectManegementNav6")}</p>
                    </Box>
                    <Box
                      className="statusPopItem sNav5"
                      onClick={() => {
                        setSearchParams({ dr: 6 });
                        setProjectTabe(6);
                      }}
                    >
                      <p>{t("projectManegementPage.projectManegementNav7")}</p>
                    </Box>
                    <Box
                      className="statusPopItem sNav6"
                      onClick={() => {
                        setSearchParams({ dr: 7 });
                        setProjectTabe(7);
                      }}
                    >
                      <p>{t("projectManegementPage.projectManegementNav8")}</p>
                    </Box>
                    <Box
                      className="statusPopItem sNav7"
                      onClick={() => {
                        setSearchParams({ dr: 8 });
                        setProjectTabe(8);
                      }}
                    >
                      <p>{t("projectManegementPage.projectManegementNav9")}</p>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box className="projectNavItems">
                <Box
                  onClick={() => {
                    setSearchParams({ dr: 0 });
                    setProjectTabe(0);
                  }}
                  className={
                    projectTabe === 0
                      ? "recapNavItem recapNavItemActive"
                      : "recapNavItem"
                  }
                >
                  <Typography>
                    {t("projectManegementPage.projectManegementNav1")}
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    setSearchParams({ dr: 1 });
                    setProjectTabe(1);
                  }}
                  className={
                    projectTabe === 1
                      ? "recapNavItem recapNavItemActive mNav1"
                      : "recapNavItem mNav1"
                  }
                >
                  <Typography>
                    {t("projectManegementPage.projectManegementNav2")}
                  </Typography>
                </Box>
                <Box className="vrLine"> </Box>

                <Box
                  onClick={() => {
                    setSearchParams({ dr: 5 });
                    setProjectTabe(5);
                  }}
                  className={
                    projectTabe === 5
                      ? "recapNavItem recapNavItemActive mNav5"
                      : "recapNavItem  mNav5"
                  }
                >
                  <Typography>
                    {t("projectManegementPage.projectManegementNav6")}
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    setSearchParams({ dr: 6 });
                    setProjectTabe(6);
                  }}
                  className={
                    projectTabe === 6
                      ? "recapNavItem recapNavItemActive mNav6"
                      : "recapNavItem  mNav6"
                  }
                >
                  <Typography>
                    {t("projectManegementPage.projectManegementNav7")}
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    setSearchParams({ dr: 7 });
                    setProjectTabe(7);
                  }}
                  className={
                    projectTabe === 7
                      ? "recapNavItem recapNavItemActive mNav7"
                      : "recapNavItem  mNav7"
                  }
                >
                  <Typography>
                    {t("projectManegementPage.projectManegementNav8")}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>

          {/* Page container */}

          {authState?.wsUserType == USER_TYPE_RESEARCHER ||
          authState?.wsUserType == USER_TYPE_COLLABORATOR ? (
            <>
              {projectTabe === 0 ? (
                <ProjectManagementSetting
                  dataReportTabe={projectTabe}
                  setDataReportTabe={setProjectTabe}
                />
              ) : projectTabe === 1 ? (
                <ProjectDirectory
                  dataReportTabe={projectTabe}
                  setDataReportTabe={setProjectTabe}
                />
              ) : projectTabe === 2 ? (
                <ProjectSettingBroadcast />
              ) : projectTabe === 3 ? (
                <ProjectReviews
                  dataReportTabe={projectTabe}
                  setDataReportTabe={setProjectTabe}
                />
              ) : projectTabe === 4 ? (
                <ProjectManagementExcerpt />
              ) : projectTabe === 5 ? (
                <ProjectCodeBook />
              ) : projectTabe === 6 ? (
                <ProjectSettingTranscript
                  dataReportTabe={projectTabe}
                  setDataReportTabe={setProjectTabe}
                />
              ) : projectTabe === 8 ? (
                <ProjectVideoPlaylist />
              ) : projectTabe === 7 ? (
                <ProjectSettingReports
                  dataReportTabe={projectTabe}
                  setDataReportTabe={setProjectTabe}
                />
              ) : null}
            </>
          ) : (
            <>
              {projectTabe === 0 ? (
                <ProjectManagementSettingObserver
                  dataReportTabe={projectTabe}
                  setDataReportTabe={setProjectTabe}
                />
              ) : projectTabe === 1 ? (
                <ProjectDirectoryObserver
                  dataReportTabe={projectTabe}
                  setDataReportTabe={setProjectTabe}
                />
              ) : projectTabe === 5 ? (
                <ProjectCodeBookObserver />
              ) : projectTabe === 6 ? (
                <ProjectSettingTranscriptObserver
                  dataReportTabe={projectTabe}
                  setDataReportTabe={setProjectTabe}
                />
              ) : projectTabe === 7 ? (
                <ProjectSettingReportsObserver
                  dataReportTabe={projectTabe}
                  setDataReportTabe={setProjectTabe}
                />
              ) : null}
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
