import React, { useState } from "react";
import "./style.css";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import EastIcon from "@mui/icons-material/East";

import iIcon from "../../../Assets/Images/IIcon.png";
import bannerClog from "../../../Assets/Images/bannerClogo.png";
import IClog from "../../../Assets/Images/TeraPage Transparent mix black.png";
import DropArrow from "../../../Assets/Images/chevron-down.png";
import BoxIcon from "../../../Assets/Images/BoxIcon.png";
import ballIcon from "../../../Assets/Images/bell.png";
import navIcon from "../../../Assets/Images/sidebar-panel-collapse-icon 1.png";
import CLogo from "../../../Assets/Images/Frame 243811.png";
import googleIcon from "../../../Assets/Images/google.png";
import whiteArrow from "../../../Assets/Images/white Arrow.png";
import backArrow from "../../../Assets/Images/noun-back.png";

import { AppBtn } from "../../AppTools/AppButton";

export default function Appearance() {
  const [colorSelectorDrop1, setColorSelectorDrop1] = useState(false);
  const [colorSelectorDrop1Val, setColorSelectorDrop1Val] =
    useState("Solid Color");

  const [colorSelectorDrop2, setColorSelectorDrop2] = useState(false);
  const [colorSelectorDrop2Val, setColorSelectorDrop2Val] =
    useState("Solid Color");

  const [activityDrop, setActivityDrop] = useState(false);
  const [activityDropVal, setActivityDroprVal] = useState();
  const [nav, setNav] = useState(false);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  return (
    <>
      <Box className="appearance">
        <Box mb={5} className="workSpaceListBox">
          <Typography className="appearanceHeader">
            {t("appearance.platformLogo")}
          </Typography>
          <Box className="iBox">
            <img src={iIcon} />
            <Typography>{t("appearance.appearanceTxt")}</Typography>
          </Box>
          <Box className="coverImg">
            <Box>
              <img src={bannerClog} />
            </Box>
          </Box>
        </Box>

        <Box mb={5} className="workSpaceListBox">
          <Typography className="appearanceHeader">
            {t("appearance.favIcon")}
          </Typography>
          <Box className="iBox">
            <img src={iIcon} />
            <Typography>{t("appearance.appearanceTxt2")}</Typography>
          </Box>
          <Box className="coverImg">
            <Box className="AppenSmClogo">
              <img src={IClog} />
            </Box>
          </Box>
        </Box>
        <Box mb={5} className="workSpaceListBox">
          <Typography className="appearanceHeader">
            {t("appearance.siteColors")}
          </Typography>
          <Box className="iBox">
            <img src={iIcon} />
            <Typography>{t("appearance.appearanceTxt3")}</Typography>
          </Box>
          <Box className="colorSelectorNavBox">
            <Box className="colorSelectorItem">
              <Typography className="appearanceHeader">
                {t("appearance.colorTheme")}
              </Typography>
              <Box
                onClick={() => setColorSelectorDrop1(!colorSelectorDrop1)}
                height={"40px"}
                px={2}
                className="statusBox"
              >
                <Typography>{colorSelectorDrop1Val}</Typography>
                <img src={DropArrow} />
                {/* drop */}
                <Box
                  sx={{ height: colorSelectorDrop1 ? "auto" : "0px" }}
                  className="statusPop"
                >
                  <Box
                    onClick={() => setColorSelectorDrop1Val("Solid Color")}
                    className="statusPopItem"
                  >
                    <Typography>{t("appearance.solidColor")}</Typography>
                  </Box>
                  <Box
                    onClick={() => setColorSelectorDrop1Val("Solid Color1")}
                    className="statusPopItem"
                  >
                    <Typography>{t("appearance.solidColor1")}</Typography>
                  </Box>
                  <Box
                    onClick={() => setColorSelectorDrop1Val("Solid Color2")}
                    className="statusPopItem"
                  >
                    <Typography>{t("appearance.solidColor2")}</Typography>
                  </Box>
                  <Box
                    onClick={() => setColorSelectorDrop1Val("Solid Color3")}
                    className="statusPopItem"
                  >
                    <Typography>{t("appearance.solidColor3")}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="colorSelectorItem">
              <Typography className="appearanceHeader">
                {t("appearance.colorCode")}
              </Typography>
              <Box px={2} height={"40px"} className="colorPicker">
                <Box></Box>
                <Typography>{t("appearance.#4F33D1")}</Typography>
              </Box>
            </Box>
          </Box>

          <Box className="colorPreviewBox">
            <Box className="colorPreNav">
              <Typography className="appearanceHeader">
                {t("appearance.prevColor")}
              </Typography>
              <Box className="colorHaxBox">
                <Typography>#4F33D1</Typography>
              </Box>
              <Typography color={"#4F33D1"} className="unLBtn">
                {t("commonTxt.resetBtn")}
              </Typography>
            </Box>
            <Box className="colorMenuBox">
              <Box sx={{ width: nav ? "55px" : "0px" }} className="ColorsideM">
                <Box>
                  <img src={IClog} />
                </Box>
                <Box>
                  <img src={BoxIcon} />
                </Box>
                <Box>
                  <img src={ballIcon} />
                </Box>
              </Box>

              <Box className="ColorMBox">
                <Box className="navColorBox">
                  <Box onClick={() => setNav(!nav)} className="CnavLog">
                    <img
                      style={{ rotate: nav ? "0deg" : "-180deg" }}
                      src={navIcon}
                    />
                  </Box>
                  <Box className="cNavBtnBox">
                    <Box
                      onClick={() => setActivityDrop(!activityDrop)}
                      px={2}
                      className="statusBox appearanceActivityDrop"
                    >
                      <Typography>
                        {activityDropVal || t("appearance.activityOptions")}
                      </Typography>
                      <img src={DropArrow} />
                      {/* drop */}
                      <Box
                        sx={{ height: activityDrop ? "auto" : "0px" }}
                        className="statusPop"
                      >
                        <Box
                          onClick={() => setActivityDroprVal("Activity1")}
                          className="statusPopItem"
                        >
                          <Typography>Activity1</Typography>
                        </Box>
                        <Box
                          onClick={() => setActivityDroprVal("Activity2")}
                          className="statusPopItem"
                        >
                          <Typography>Activity2</Typography>
                        </Box>
                        <Box
                          onClick={() => setActivityDroprVal("Activity3")}
                          className="statusPopItem"
                        >
                          <Typography>Activity3</Typography>
                        </Box>
                        <Box
                          onClick={() => setActivityDroprVal("Activity4")}
                          className="statusPopItem"
                        >
                          <Typography>Activity4</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <AppBtn buttonText="Preview Activity" />
                  </Box>
                </Box>
                <Box className="CMainContainar">
                  <Box className="ApInMenuBackBtn">
                    <Box className="openBtn">
                      <img src={backArrow} />
                      <Typography className="neonBackText">
                        {t("commonTxt.backBtn")}
                      </Typography>
                    </Box>
                  </Box>

                  <Box>
                    <Typography className="ApInMenuHText">
                      {t("appearance.activityTasks")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="CAscentBox">
              <Box className="CAscent1Box">
                <Typography mb={1} className="appearanceHeader">
                  {t("appearance.frmAscentClr")}
                </Typography>
                <Typography>{t("appearance.appearanceTxt1")}</Typography>
              </Box>
              <Box className="CAscent2Box">
                <Box className="colorSelectorItem">
                  <Box px={2} height={"40px"} className="colorPicker">
                    <Box></Box>
                    <Typography>#4F33D1</Typography>
                  </Box>
                </Box>
                <Typography ml={1} color={"#000000"} className="unLBtn">
                  {t("commonTxt.resetBtn")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box mb={5} className="workSpaceListBox">
          <Typography className="appearanceHeader">
            {t("appearance.siteBcg")}
          </Typography>
          <Box className="iBox">
            <img src={iIcon} />
            <Typography>{t("appearance.appearanceTxt4")}</Typography>
          </Box>
          <Box className="colorSelectorNavBox">
            <Box className="colorSelectorItem">
              <Typography className="appearanceHeader">
                {t("appearance.colorTheme")}
              </Typography>
              <Box
                onClick={() => setColorSelectorDrop2(!colorSelectorDrop2)}
                height={"40px"}
                px={2}
                className="statusBox"
              >
                <Typography>{colorSelectorDrop2Val}</Typography>
                <img src={DropArrow} />
                {/* drop */}
                <Box
                  sx={{ height: colorSelectorDrop2 ? "auto" : "0px" }}
                  className="statusPop"
                >
                  <Box
                    onClick={() => setColorSelectorDrop2Val("Solid Color")}
                    className="statusPopItem"
                  >
                    <Typography>{t("appearance.solidColor")}</Typography>
                  </Box>
                  <Box
                    onClick={() => setColorSelectorDrop2Val("Solid Color1")}
                    className="statusPopItem"
                  >
                    <Typography>{t("appearance.solidColor1")}</Typography>
                  </Box>
                  <Box
                    onClick={() => setColorSelectorDrop2Val("Solid Color2")}
                    className="statusPopItem"
                  >
                    <Typography>{t("appearance.solidColor2")}</Typography>
                  </Box>
                  <Box
                    onClick={() => setColorSelectorDrop2Val("Solid Color3")}
                    className="statusPopItem"
                  >
                    <Typography>{t("appearance.solidColor3")}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="colorSelectorItem">
              <Typography className="appearanceHeader">
                {t("appearance.colorCode")}
              </Typography>
              <Box px={2} height={"40px"} className="colorPicker">
                <Box></Box>
                <Typography>#4F33D1</Typography>
              </Box>
            </Box>
          </Box>
          <Box className="colorPreviewGoogleBox">
            <Box className="backgroundPreviewBox">
              <Typography className="appearanceHeader">
                {t("appearance.bcgPreview")}
              </Typography>
              <Box className="BgPreview"></Box>
            </Box>
            <Box className="signInBox">
              <Box className="signInInnerBox">
                <Box className="signColog">
                  <img src={CLogo} />
                </Box>
                <Box className="signInInputBox">
                  <Typography>
                    {t("commonTxt.uName")} / {t("commonTxt.mail")}
                  </Typography>
                  <input placeholder="Enter Username or Email Address" />
                </Box>
                <Box className="signInInputBox">
                  <Typography>{t("commonTxt.password")}</Typography>
                  <input placeholder="Enter Password" />
                </Box>
                <Box className="forGotPwd">
                  <Typography color={"#4F33D1"}>
                    {t("commonTxt.forgotPassword")}?
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <input type="checkbox" />
                    <Typography ml={1}>
                      {t("appearance.styaSignedIn")}
                    </Typography>
                  </Box>
                </Box>
                <Box className="signBtn">
                  <Typography>Sign In</Typography>
                  <img src={whiteArrow} />
                </Box>
                <Typography className="createAccoutText">
                  {t("appearance.createAcc")}
                  <span>{t("appearance.instead")}</span> ?
                </Typography>
                <Box className="orText">
                  <p>{t("appearance.or")}</p>
                </Box>
                <Box className="GoogleSignUpBox">
                  <img src={googleIcon} />
                  <Typography>{t("appearance.signWithGoogle")}</Typography>
                </Box>
                <Box className="signBtn">
                  <Typography>{t("appearance.signWitOkta")}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
