import React, { useState, useEffect } from "react";
import { Box, Typography, FormControl } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./style.css";

import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "../../../lib/axios";
import { API_BASE_URL, TOAST_CONFIG } from "../../../env";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../lib/store/store";

//images
import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";

import groupsIcon from "../../../Assets/Images/groupsIcon.png";

import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";

export default function AddCardMessagePop({
  addCardMessagePop,
  setAddCardMessagePop,
  handelAddCardPopPeopleBack,
}) {
  const email1 = "Enter a description or instructions for the card.";

  const workspaceState = useAppSelector((state) => state.workspace);

  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [groupsListData, setGroupsListData] = useState([]);
  const authState = useAppSelector((state) => state.auth);

  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();
  const [redio, setRedio] = useState(0);

  const [groupDrop2, setGroupDrop2] = useState(false);

  const [loading, setLoading] = useState(false);

  const [adminsList, setAdminsList] = useState([]);

  const [selectedAdminRoles, setSelectedAdminRoles] = useState([]);

  const [projectDrop3, setProjectDrop3] = useState(false);
  const [projectDropVal3, setProjectDropVal3] = useState(
    "Select participant access options"
  );

  const [projectDrop4, setProjectDrop4] = useState(false);
  const [projectDropVal4, setProjectDropVal4] = useState(
    "Select administaror access options"
  );

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const [selectedParticipantGroups, setSelectedParticipantGroups] = useState(
    []
  );

  // InitialState for Cards
  const initialCardState = {
    workspace_id: workspaceState?.selectedWorkspace?._id,
    page_id: "66a216c09d56c23441241092",
    title: "",
    type: "message",
    is_show_title: "false",
    instructions: "",
    file_url: "",
    activity_id: [],
    conversation_display_type: "",
    conversation_id: [],
    appearance: {},
    peoples: "",
    data_set: "",
    participant_access: "",
    participant_groups: [],
    participant_ids: [],
    administrator_access: "",
    administrator_roles: [],
    administrator_ids: [],
    status: "active",
  };

  const [cardData, setCardData] = useState(initialCardState);

  const handleParticipantGroupsChange = (event3) => {
    const { value, checked } = event3.target;
    if (checked) {
      setSelectedParticipantGroups([...selectedParticipantGroups, value]);
    } else {
      setSelectedParticipantGroups(
        selectedParticipantGroups.filter((participant) => participant !== value)
      );
    }

    console.log(
      "The value of selectedParticipantGroups:",
      selectedParticipantGroups
    );
    setCardData({
      ...cardData,
      participant_groups: selectedParticipantGroups,
    });
  };

  const chackBoxRoom = (group, index) => {
    return (
      <Box className="checkBoxItem" key={`group-${group.index}`}>
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            name={`group-${group.group._id}`}
            value={group.group._id}
            checked={selectedParticipantGroups.includes(group.group._id)}
            onChange={handleParticipantGroupsChange}
          />
          <Typography>{group.group.name}</Typography>
        </Box>
      </Box>
    );
  };

  const cardFormData = (e) => {
    let newState = {
      ...cardData,
      [e.target.name]: e.target.value,
    };

    setCardData(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
    console.log("Updated cardData:", newState); // Log the updated state
  };

  const validationObj = {
    hasErr: false,
    title: {
      error: false,
      msg: "",
    },
    instructions: {
      error: false,
      msg: "",
    },
    participant_access: {
      error: false,
      msg: "",
    },
    administrator_access: {
      error: false,
      msg: "",
    },
  };

  const [validationErrors, setValidationErrors] = useState({
    ...validationObj,
  });

  const handleValidation = (formInput) => {
    formInput = formInput || cardData;
    let validationerr = { ...validationObj };

    if (formInput.title == "") {
      validationerr.hasErr = true;
      validationerr.title = {
        error: true,
        msg: "Title is required",
      };
    }

    if (formInput.instructions == "") {
      validationerr.hasErr = true;
      validationerr.instructions = {
        error: true,
        msg: "Message is required",
      };
    }

    if (formInput.participant_access == "") {
      validationerr.hasErr = true;
      validationerr.participant_access = {
        error: true,
        msg: "Participant Access is required",
      };
    }

    if (formInput.administrator_access == "") {
      validationerr.hasErr = true;
      validationerr.administrator_access = {
        error: true,
        msg: "Administrator Access is required",
      };
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  };

  const ParticipantItem = [
    {
      name: "All Participants",
      value: "all",
    },
    {
      name: "Select Participant Groups",
      value: "selected_participants",
    },
    {
      name: "None",
      value: "none",
    },
  ];

  const AdministratorItem = [
    {
      name: "All Administrators",
      value: "all",
    },
    {
      name: "Select Admin Roles",
      value: "selected_roles",
    },
    {
      name: "Select Admins",
      value: "selected_admins",
    },
  ];

  const AdminRolesCheckBox = (val, text) => {
    console.log("Admin Role Value=>", val);
    return (
      <Box className="checkBoxItem" key={`adminrole-${val}`}>
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            name={`adminrole-${val}`}
            value={val}
            checked={selectedAdminRoles.includes(val)}
            onChange={handleAdminRolesChange}
          />
          <Typography>{text}</Typography>
        </Box>
      </Box>
    );
  };

  const handleParticipantAccessChange = (event) => {
    const selectedValue = event.target.value === "" ? "" : event.target.value;

    setCardData((prevGroup) => ({
      ...prevGroup,
      [event.target.name]: selectedValue,
    }));
    if (validationErrors.hasErr) {
      handleValidation(selectedValue);
    }
  };

  const administratorRoles = [
    { name: "Researchers", value: "researcher" },
    { name: "Collaborators", value: "collaborator" },
    { name: "Observer", value: "observer" },
  ];

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
  };

  const handleAdminRolesChange = (event4) => {
    const { value, checked } = event4.target;
    if (checked) {
      setSelectedAdminRoles([...selectedAdminRoles, value]);
    } else {
      setSelectedAdminRoles(
        selectedAdminRoles.filter((admin) => admin !== value)
      );
    }
    setCardData({
      ...cardData,
      administrator_roles: selectedAdminRoles,
    });
  };

  const handleAdminAccessChange = (event2) => {
    setLoading(false);
    const selectedValue2 =
      event2.target.value === "" ? "" : event2.target.value;
    setCardData((prevGroup) => ({
      ...prevGroup,
      [event2.target.name]: selectedValue2,
    }));
    setLoading(true);
    if (validationErrors.hasErr) {
      handleValidation(selectedValue2);
    }
    //console.log("Admin Access=>",cardData?.administrator_access);
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setAddCardMessagePop(false);
    }
  };

  const handleToggleChange = (event) => {
    const { name, checked } = event.target;
    setCardData((prevSettings) => ({
      ...prevSettings,
      [name]: checked ? "true" : "false",
    }));
  };

  const resetAll = () => {
    setCardData(initialCardState);
    // setImage(null);
  };

  useEffect(() => {
    if (addCardMessagePop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [addCardMessagePop]);

  useEffect(() => {
    getResearcher();
    getGroupsLists();
  }, []);

  const getResearcher = async () => {
    try {
      const response = await axios.get(
        `/super-admin/admin/researchers?workspace=${workspaceState?.selectedWorkspace?._id}`
      );
      setAdminsList((prev) => {
        const prevData = prev || [];
        const newData = response?.data?.data || [];
        const existingIds = new Set(prevData.map((item) => item._id));
        const mergedData = [
          ...prevData,
          ...newData.filter((item) => !existingIds.has(item._id)),
        ];
        return mergedData;
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getGroupsLists = async () => {
    try {
      const res = await axios.get(API_BASE_URL + "/participant/groups");
      if (res.status) {
        const group = res.data?.data?.filter((grp) => grp.is_enhanced == false);
        setGroupsListData(group);
        console.log(
          "Group List=>",
          res.data?.data?.filter((grp) => grp.is_enhanced == false)
        );
      }
    } catch (error) {
      console.error("Error fetching project lists", error);
    }
  };

  let handleAddCard = async () => {
    if (!handleValidation()) {
      toast.error("Please correct form fields", TOAST_CONFIG);
      return false;
    }
    setLoading(true);
    const payload = {
      workspace_id: cardData.workspace_id,
      page_id: cardData.page_id,
      title: cardData?.title,
      type: cardData.type,
      is_show_title: cardData.is_show_title,
      instructions: cardData?.instructions,
      file_url: cardData.file_url,
      activity_id: cardData.activity_id,
      // conversation_display_type: cardData.conversation_display_type,
      conversation_id: cardData.conversation_id,
      appearance: cardData.appearance,
      participant_access: cardData.participant_access,
      participant_groups: cardData.participant_groups,
      participant_ids: cardData.participant_ids,
      administrator_access: cardData.administrator_access,
      administrator_roles: cardData.administrator_roles,
      administrator_ids: cardData.administrator_ids,
      status: cardData.status,
    };
    console.log("The value of payload:", payload);
    try {
      // if (cardData?.image == null || cardData?.image == "") {
      //   toast.error("Cover image is required");
      //   return;
      // }

      let response = await axios.post(
        API_BASE_URL + `/researchers/cards`,
        // createCard,
        payload,
        authState.authToken
      );
      if (response.success) {
        resetAll();
        toast.success(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      let message = "Something went wront, please try again!";
      let status_code = 500;
      if (error.response) {
        message = error.response.date.message;
        status_code = error.response.status;
      }
      return {
        success: false,
        message: message,
        status_code: status_code,
      };
    }
  };

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: addCardMessagePop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople "
      >
        <Box
          sx={{ display: addCardMessagePop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className="companyHeader">
            {t("addMessageCardPop.addMessageCardPopHead")}
          </Typography>

          <Box className="basicBoxItem">
            <Typography className="eTemPopLabel ">
              {t("addMessageCardPop.cardTitleLabel")}
            </Typography>
            <Box className="pickerBox cardTitleLabel">
              <input
                className="eTemInput"
                placeholder={t("addMessageCardPop.cardTitlePlaceholder")}
                name="title"
                value={cardData?.title ?? ""}
                onChange={cardFormData}
              />
              <span className="validationerr">
                {validationErrors.title.msg}
              </span>
            </Box>
          </Box>
          <Box mb={4} className="checkWlabel">
            <input
              className="custom-checkbox"
              type="checkBox"
              name="is_show_title"
              defaultChecked={cardData.is_show_title === "true"}
              onChange={handleToggleChange}
            />
            <Typography>{t("addMessageCardPop.cardTitleCheckbox")}</Typography>
          </Box>

          <Typography className="eTemPopLabel ">
            {t("LiveInterview.messageLabel")}
          </Typography>
          <Box className="emailInputBox">
            <Box className="emailInputBoxNav">
              <Box
                onClick={() => setMessageTextItalic(!messageTextItalic)}
                className="emailImgBox"
              >
                <img src={IIcon} />
              </Box>
              <Box
                onClick={() => setMessageTextBold(!messageTextBold)}
                className="emailImgBox"
              >
                <img src={BIcon} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("underline", "message")}
                className="emailImgBox"
              >
                <img src={UnderLineU} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("line-through", "message")}
                className="emailImgBox"
              >
                <img src={MLineU} />
              </Box>
              <Box className="emailImgBox">
                <img src={AtachMentIcon} />
              </Box>
              <Box className="emailImgBox">
                <img src={Qbox} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("start")}
                className="emailImgBox"
              >
                <img src={Line1} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("center")}
                className="emailImgBox"
              >
                <img src={Line2} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("end")}
                className="emailImgBox"
              >
                <img src={Line3} />
              </Box>
              <Box className="emailImgBox">
                <img src={Line4} />
              </Box>
              <Box className="emailImgBox">
                <img src={emoji} />
              </Box>
            </Box>
            <textarea
              style={{
                textAlign: messageTextLaOut,
                fontStyle: messageTextItalic ? "italic" : "normal",
                fontWeight: messageTextBold ? "bold" : "normal",
                textDecoration: messageTextUnderLine,
                // textDecoration: messageTextMLine ? "line-through" : null
              }}
              className="emailInput"
              placeholder={t("addMessageCardPop.messagePlaceholder")}
              name="instructions"
              value={cardData?.instructions ?? ""}
              onChange={cardFormData}
            />
            <span className="validationerr">
              {validationErrors.instructions.msg}
            </span>
          </Box>

          <Typography className="eTemPopLabel ">
            {t("addMessageCardPop.cardVisibilityHead")}
          </Typography>

          <Box className="addcpDropLBox">
            <Typography sx={{ width: "250px" }} className="addcpDropLlabel">
              {t("addMessageCardPop.participantAccess")}:
            </Typography>
            <FormControl sx={{ width: "100%" }}>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                className="statusBox MuiBox-root css-0"
                value={
                  cardData?.participant_access || "Select Participant Access"
                }
                onChange={handleParticipantAccessChange}
                name="participant_access"
              >
                {ParticipantItem.length > 0 &&
                  ParticipantItem.map((el, i) => (
                    <MenuItem value={el.value} key={i}>
                      {el.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <span className="validationerr">
              {validationErrors.participant_access.msg}
            </span>
          </Box>

          {cardData?.participant_access === "selected_participants" && (
            <Box className="searchGroupSection">
              <Box
                pl={4.5}
                className="inputAppDrop"
                onClick={() => setGroupDrop2(!groupDrop2)}
              >
                <img className="groupsIcon" src={groupsIcon} />
                <Typography>
                  {t("pageSettingPop.participantGroupPlaceholder")}
                </Typography>
                <img
                  style={{ rotate: groupDrop2 ? "180deg" : "0deg" }}
                  src={dropArrow}
                />
              </Box>
              <Box
                sx={{ height: groupDrop2 ? "auto" : "0px" }}
                className="checkMain_box"
              >
                <Box className="ckeckBoxRoom">
                  {groupsListData.length > 0 &&
                    groupsListData.map((group, index) =>
                      chackBoxRoom({ group, index })
                    )}
                </Box>
                <Box className="propertyPagination">
                  <Box>
                    <p>1</p>
                  </Box>
                  <Box>
                    <p>2</p>
                  </Box>
                  <Box>
                    <p>3</p>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          <Box className="addcpDropLBox">
            <Typography sx={{ width: "250px" }} className="addcpDropLlabel">
              {t("addMessageCardPop.administratorAccess")}:
            </Typography>
            <FormControl sx={{ width: "100%" }}>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                className="statusBox MuiBox-root css-0"
                value={
                  cardData?.administrator_access ||
                  "Select Administrator Access"
                }
                onChange={handleAdminAccessChange}
                name="administrator_access"
              >
                {AdministratorItem.length > 0 &&
                  AdministratorItem.map((el2, i) => (
                    <MenuItem value={el2.value} key={i}>
                      {el2.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <span className="validationerr">
              {validationErrors.administrator_access.msg}
            </span>
          </Box>

          {cardData?.administrator_access === "selected_roles" && loading && (
            <Box className="checkMain_box">
              <Box className="ckeckBoxRoom">
                {administratorRoles.length > 0 &&
                  administratorRoles.map((el, i) =>
                    AdminRolesCheckBox(el.value, el.name)
                  )}
                {/* {AdminRolesCheckBox('allresearcher','All Researchers')}
                  {AdminRolesCheckBox('allcollaborators','All Collaborators')}
                  {AdminRolesCheckBox('allobservers','All Observers')} */}
              </Box>
            </Box>
          )}

          {cardData?.administrator_access === "selected_admins" && (
            <Box className="searchGroupSection">
              <FormControl sx={{ width: "100%" }}>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  className="statusBox MuiBox-root css-0"
                  value={cardData.administrator_ids || "Select Administrator"}
                  onChange={(e) =>
                    setCardData({
                      ...cardData,
                      administrator_ids: e.target.value,
                    })
                  }
                  multiple
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return (
                        <Box pl={4.5} className="inputAppDrop">
                          <Typography>Select admins</Typography>
                        </Box>
                      );
                    }
                    return selected
                      .map((value) => {
                        const user = adminsList.find(
                          (usr) => usr._id === value
                        );
                        return user ? user.name : value;
                      })
                      .join(", ");
                  }}
                >
                  {adminsList.length > 0 &&
                    adminsList.map((el, i) => (
                      <MenuItem value={el._id} key={i}>
                        {el.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          )}

          <Box className="workPupBtnBox">
            <AppBtnOutLine
              buttonText={t("commonTxt.cancelBtn")}
              onClick={() => setAddCardMessagePop(false)}
            />
            <AppBtnOutLine
              buttonText={t("commonTxt.backBtn")}
              onClick={handelAddCardPopPeopleBack}
            />
            <AppBtn
              buttonText={t("workspaceHome.addCard")}
              onClick={() => handleAddCard()}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
