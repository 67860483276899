import React from 'react'
import { Box, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";

//images
import tp1 from "../../../Assets/Images/tp1.png"
import tp2 from "../../../Assets/Images/tp2.png"
import tp3 from "../../../Assets/Images/tp3.png"
import tp4 from "../../../Assets/Images/tp4.png"
import tp5 from "../../../Assets/Images/tp5.png"
import tp6 from "../../../Assets/Images/tp6.png"
import tp7 from "../../../Assets/Images/tp7.png"
import tp8 from "../../../Assets/Images/tp8.png"
import tp9 from "../../../Assets/Images/tp9.png"
import tp10 from "../../../Assets/Images/tp10.png"
import tp11 from "../../../Assets/Images/tp11.png"
import blackImg from "../../../Assets/Images/blankImg.png"
import user from "../../../Assets/Images/New folder/user1.png"

import { YDot, like, dislike, comment, tik } from "../../../Assets/Data"

export default function Thread() {

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const productBox = ({ img1, img2, img3, img4, title, subTitle }) => {
    return (
      <Box className="taskProductItem">
        <Typography>{title}</Typography>
        <Box my={1} className="productImgBox">
          <Box className="productImg"><img src={img1} /></Box>
          <Box className="productImg"><img src={img2} /></Box>
          <Box className="productImg"><img src={img3} /></Box>
          <Box className="productImg"><img src={img4} /></Box>
        </Box>
        <Typography>{subTitle}</Typography>
      </Box>
    )
  }

  return (
    <>

      <Box className="threadAvatrBox"><img src={user} /></Box>
      <Box pt={2} px={3} className="taskBoxTopDate">
        <Typography className='userNameWspm'>
          Daina Henry
          <span>completed the activity</span>
          <samp>Your Great Outdoors</samp>
        </Typography>
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "15px"
        }}>
          <samp className='taskHeaderText3'>Aug 04, 2023 02:21 PM</samp>
        </Box>
      </Box>

      <Box className="graySBox taskBox">
        <Box className="taskBoxTopDate">
          <Typography className='taskHeaderText'>Task 1 Name</Typography>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "15px"
          }}>
            <Typography className='taskHeaderText2'>
            {t("commonTxt.viewBtn")}
            </Typography>
          </Box>
        </Box>
        <Typography mb={3}>Which of these outdoor activities do you participate in? Please select all that apply and explain why these activities are the outdoor activities for you.</Typography>

        <Box ml={5} mb={3}>
          <ul style={{ marginBottom: "10px" }}>
            <li>Camping</li>
            <li>Hiking</li>
          </ul>
          <span>All you need is a back pack, tent and some boots - nothing big or complicated!</span>
        </Box>
        <Box className="taskBoxBottomRow">
          <Box className="taskBoxBottomRowItem">
            {like}
            <Typography>{t("commonTxt.Like")}</Typography>
          </Box>
          <Box className="taskBoxBottomRowItem">
            {dislike}
            <Typography>{t("commonTxt.dislike")}</Typography>
          </Box>
          <Box className="taskBoxBottomRowItem">
            {comment}
            <Typography>{t("commonTxt.comment")}</Typography>
          </Box>
          <Box className="taskBoxBottomRowItem">
            {tik}
            <Typography>{t("commonTxt.review")}</Typography>
          </Box>
        </Box>
      </Box>



      <Box className="graySBox taskBox">
        <Box className="taskBoxTopDate">
          <Typography className='taskHeaderText'>Task 2 Name</Typography>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "15px"
          }}>
            <Typography className='taskHeaderText2'>{t("commonTxt.viewBtn")}</Typography>
          </Box>
        </Box>
        <Typography mb={3}>Select the Outdoor Essentials and sort them into the following categories.</Typography>

        <Box className="taskProductBox">

          {productBox({
            img1: tp1,
            img2: tp2,
            img3: tp3,
            img4: tp4,
            title: "Absolutely Needed",
            subTitle: "+2 more"
          })}
          {productBox({
            img1: tp5,
            img2: tp6,
            img3: tp7,
            img4: tp8,
            title: "Nice to Have",
            subTitle: "+5 more"
          })}
          {productBox({
            img1: tp9,
            img2: tp10,
            img3: tp11,
            img4: blackImg,
            title: "Forgettable",
            // subTitle: "+2 more"
          })}
        </Box>





        <Box className="taskBoxBottomRow">
          <Box className="taskBoxBottomRowItem">
            {like}
            <Typography>{t("commonTxt.Like")}</Typography>
          </Box>
          <Box className="taskBoxBottomRowItem">
            {dislike}
            <Typography>{t("commonTxt.dislike")}</Typography>
          </Box>
          <Box className="taskBoxBottomRowItem">
            {comment}
            <Typography>{t("commonTxt.comment")}</Typography>
          </Box>
          <Box className="taskBoxBottomRowItem">
            {tik}
            <Typography>{t("commonTxt.review")}</Typography>
          </Box>
        </Box>
      </Box>



      <Box className="graySBox taskBox">
        <Box className="taskBoxTopDate">
          <Typography className='taskHeaderText'>Task 3 Name</Typography>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "15px"
          }}>
            <Typography className='taskHeaderText2'>
            {t("commonTxt.viewBtn")}
            </Typography>
          </Box>
        </Box>
        <Typography mb={3}>Which of these outdoor activities do you participate in? Please select all that apply and explain why these activities are the outdoor activities for you.</Typography>
        <Typography ml={5} my={3} sx={{
          color: "#706C80"
        }}>No response added yet. Data will be visible once the participant responds.</Typography>
        <Box className="taskBoxBottomRow">
          <Box className="taskBoxBottomRowItem">
            {like}
            <Typography>{t("commonTxt.Like")}</Typography>
          </Box>
          <Box className="taskBoxBottomRowItem">
            {dislike}
            <Typography>{t("commonTxt.dislike")}</Typography>
          </Box>
          <Box className="taskBoxBottomRowItem">
            {comment}
            <Typography>{t("commonTxt.comment")}</Typography>
          </Box>
          <Box className="taskBoxBottomRowItem">
            {tik}
            <Typography>{t("commonTxt.review")}</Typography>
          </Box>
        </Box>
      </Box>

    </>
  )
}
