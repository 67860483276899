import React, { useState, useEffect } from "react";
import { Box, Typography, FormControl } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import "./style.css";
//images
import groupsIcon from "../../../Assets/Images/groupsIcon.png";
import iIcon2 from "../../../Assets/Images/IIcon.png";
import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png";
import backArrow from "../../../Assets/Images/noun-back.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";
import { useAppSelector } from "../../../lib/store/store";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../env";
import { API_BASE_URL, STORAGE_BASE_PATH } from "../../../env";
import { uploadFile } from "../../../Services/adminActions";
import { AntSwitch } from "../../AppTools/AppTool";
import axios from "../../../lib/axios";

export default function AddTextTaskPop({
  setAllDropVal4,
  addTextTaskPop,
  setAddTextTaskPop,
  handelAddTaskPopBack2,
  taskData,
  setTaskData,
  initialTaskState,
  setSelectedTask,
}) {
  const authState = useAppSelector((state) => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace);
  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [groupsListData, setGroupsListData] = useState([]);
  const [groupDrop2, setGroupDrop2] = useState(false);
  const [selectedParticipantGroups, setSelectedParticipantGroups] = useState(
    []
  );
  const [participantListData, setParticipantListData] = useState([]);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const ParticipantItem = [
    {
      name: "All Participants",
      value: "all",
    },
    {
      name: "Selected Particular Particiapnt",
      value: "selected_participants",
    },
    {
      name: "Select Participant Groups",
      value: "selected_participants_groups",
    },
  ];

  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setAddTextTaskPop(false);
    }
  };

  const handelCancelPop = () => {
    setAddTextTaskPop(false);
    setAllDropVal4("Add Tasks");
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      uploadImage(file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      uploadImage(file);
    }
  };

  const uploadImage = async (file) => {
    if (!file) return false;
    const formData = new FormData();
    formData.append("file", file);
    try {
      let imageUploadRes = await uploadFile(formData, authState.authToken);
      if (imageUploadRes.success) {
        setTaskData({
          ...taskData,
          image: imageUploadRes?.data?.upload_path,
        });
        toast.success(imageUploadRes.message, TOAST_CONFIG);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    } finally {
    }
  };

  useEffect(() => {
    getGroupsLists();
    getParticipant();
  }, []);

  useEffect(() => {
    if (addTextTaskPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [addTextTaskPop]);

  const getGroupsLists = async () => {
    try {
      const res = await axios.get(API_BASE_URL + "/participant/groups");
      if (res.status) {
        const group = res.data?.data?.filter((grp) => grp.is_enhanced == false);
        setGroupsListData(group);
        console.log(
          "Group List=>",
          res.data?.data?.filter((grp) => grp.is_enhanced == false)
        );
      }
    } catch (error) {
      console.error("Error fetching project lists", error);
    }
  };

  const getParticipant = async () => {
    try {
      const res = await axios.get(
        API_BASE_URL +
          `/super-admin/admin/participants?project_ids=665194caea9668124ad02e26`
      );
      if (res.status) {
        const group = res.data?.data || [];
        setParticipantListData(group);
      }
    } catch (error) {
      console.error("Error fetching project lists", error);
    }
  };

  const setFormData = (e) => {
    let newState = {
      ...taskData,
      [e.target.name]: e.target.value,
    };
    setTaskData(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
    // console.log("Name Set=>",taskData);
  };

  const setFormData2 = (e) => {
    let newState2 = {
      ...taskData,
      task_settings: {
        ...taskData.task_settings,
        [e.target.name]: e.target.value,
      },
    };
    setTaskData(newState2);
  };

  const setFormData3 = (e) => {
    let newState3 = {
      ...taskData,
      advance_settings: {
        ...taskData.advance_settings,
        [e.target.name]: e.target.value,
      },
    };
    setTaskData(newState3);
  };

  const handleTaskSetting = (e) => {
    setTaskData({
      ...taskData,
      task_settings: {
        ...taskData.task_settings,
        [e.target.name]: e.target.checked,
      },
    });
    // console.log("Task Setting Word=>",taskData);
  };

  const handleTaskSetting2 = (e) => {
    setTaskData({
      ...taskData,
      advance_settings: {
        ...taskData.advance_settings,
        [e.target.name]: e.target.checked,
      },
    });
  };

  const handleTaskSetting3 = (e) => {
    setTaskData({
      ...taskData,
      advance_settings: {
        ...taskData.advance_settings,
        [e.target.name]: e.target.value,
      },
    });
  };

  const resetAll = () => {
    setTaskData(initialTaskState);
    setImage(null);
    setAddTextTaskPop(false);
    setSelectedTask("");
    setAllDropVal4("Add Tasks");
  };

  const validationObj = {
    hasErr: false,
    name: {
      error: false,
      msg: "",
    },
    instructions: {
      error: false,
      msg: "",
    },
  };

  const [validationErrors, setValidationErrors] = useState({
    ...validationObj,
  });

  const handleValidation = (formInput) => {
    formInput = formInput || taskData;
    let validationerr = { ...validationObj };

    if (formInput.name == "") {
      validationerr.hasErr = true;
      validationerr.name = {
        error: true,
        msg: "Task Name is required",
      };
    }

    if (formInput.instructions == "") {
      validationerr.hasErr = true;
      validationerr.instructions = {
        error: true,
        msg: "Instruction is required",
      };
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  };

  let saveActivityTask = async () => {
    console.log("Final Task Data=>", taskData);
    if (!handleValidation()) {
      toast.error("Please correct form fields", TOAST_CONFIG);
      return false;
    }
    setLoading(true);
    try {
      if (taskData?.image == null || taskData?.image == "") {
        toast.error("Task image is required");
        return;
      }
      let response = await axios.post(
        API_BASE_URL + `/researchers/tasks`,
        taskData,
        authState.authToken
      );
      if (response.success) {
        resetAll();
        toast.success(response.message, TOAST_CONFIG);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      let message = "Something went wrong, please try again!";
      let status_code = 500;
      if (error.response) {
        message = error.response.data.message;
        status_code = error.response.status;
      }
      return {
        success: false,
        message: message,
        status_code: status_code,
      };
    }
  };

  const handleParticipantGroupsChange = (event3) => {
    const { value, checked } = event3.target;
    if (checked) {
      if (selectedParticipantGroups.includes(value)) {
      } else {
        selectedParticipantGroups.push(value);
      }
    } else {
      let index5 = selectedParticipantGroups.indexOf(value);
      selectedParticipantGroups.splice(index5, 1);
    }
    setTaskData({
      ...taskData,
      advance_settings: {
        ...taskData.advance_settings,
        participant_groups: selectedParticipantGroups,
      },
    });
  };

  const chackBoxRoom = (group, index) => {
    return (
      <Box className="checkBoxItem" key={`group-${group.index}`}>
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            name={`group-${group.group._id}`}
            value={group.group._id}
            checked={selectedParticipantGroups.includes(group.group._id)}
            onChange={handleParticipantGroupsChange}
          />
          <Typography>{group.group.name}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: addTextTaskPop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople "
      >
        <Box
          sx={{ display: addTextTaskPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Box onClick={handelAddTaskPopBack2} className="openBtn taskBackBtn">
            <img src={backArrow} />
            <Typography className="neonBackText">
              {t("commonTxt.backBtn")}
            </Typography>
          </Box>

          <Typography className="taskPopMainLabel">
            {t("addTextTaskPop.textTaskHead")}
          </Typography>
          <Box className="iBox">
            <img src={iIcon2} />
            <Typography>{t("addTextTaskPop.textTaskNotify")}</Typography>
          </Box>
          <Box className="basicBoxItem">
            <Typography className="eTemPopLabel ">
              {t("addTextTaskPop.taskNameLabel")}
            </Typography>
            <Box className="pickerBox cardTitleLabel">
              <input
                className="eTemInput"
                placeholder={t("addTextTaskPop.taskNamePlaceholder")}
                value={taskData.name ?? ""}
                name="name"
                onChange={setFormData}
              />
              <span className="validationerr">{validationErrors.name.msg}</span>
            </Box>
          </Box>

          <Typography className="eTemPopLabel ">
            {t("LiveInterview.questionLabel")}
          </Typography>
          <Box className="emailInputBox textTaskInstruction">
            <Box className="emailInputBoxNav">
              <Box
                onClick={() => setMessageTextItalic(!messageTextItalic)}
                className="emailImgBox"
              >
                <img src={IIcon} />
              </Box>
              <Box
                onClick={() => setMessageTextBold(!messageTextBold)}
                className="emailImgBox"
              >
                <img src={BIcon} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("underline", "message")}
                className="emailImgBox"
              >
                <img src={UnderLineU} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("line-through", "message")}
                className="emailImgBox"
              >
                <img src={MLineU} />
              </Box>
              <Box className="emailImgBox">
                <img src={AtachMentIcon} />
              </Box>
              <Box className="emailImgBox">
                <img src={Qbox} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("start")}
                className="emailImgBox"
              >
                <img src={Line1} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("center")}
                className="emailImgBox"
              >
                <img src={Line2} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("end")}
                className="emailImgBox"
              >
                <img src={Line3} />
              </Box>
              <Box className="emailImgBox">
                <img src={Line4} />
              </Box>
              <Box className="emailImgBox">
                <img src={emoji} />
              </Box>
            </Box>
            <textarea
              style={{
                textAlign: messageTextLaOut,
                fontStyle: messageTextItalic ? "italic" : "normal",
                fontWeight: messageTextBold ? "bold" : "normal",
                textDecoration: messageTextUnderLine,
                // textDecoration: messageTextMLine ? "line-through" : null
              }}
              className="emailInput"
              placeholder={t("addTextTaskPop.taskDescriptionPlaceholder")}
              name="instructions"
              value={taskData.instructions ?? ""}
              onChange={setFormData}
              // onChange={handelChange}
            />
          </Box>
          <span style={{ marginBottom: "15px" }} className="validationerr">
            {validationErrors.instructions.msg}
          </span>
          <Box className="basicBoxItem">
            <Box
              className="mediaInputbox upload-area taskUoloadImageBox"
              onClick={() => {
                document.getElementById("fileInput").click();
              }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <img
                src={
                  taskData?.image
                    ? STORAGE_BASE_PATH + `/` + taskData.image
                    : uploadIcon
                }
              />
              <Typography>{t("commonTxt.uploadText")}</Typography>
              <input
                id="fileInput"
                style={{ display: "none" }}
                type="file"
                onChange={handleFileInputChange}
              />
            </Box>
          </Box>

          <Typography className="textTaskSetting ">
            {t("addTextTaskPop.taskSettingsHead")}
          </Typography>
          <Box className="checkWlabel">
            <input
              className="custom-checkbox"
              type="checkBox"
              name="enforce_word"
              onClick={(e) => {
                handleTaskSetting(e);
              }}
              defaultChecked={taskData?.task_settings?.enforce_word}
            />
            <Typography>{t("addTextTaskPop.enforceWordCount")}</Typography>
          </Box>
          <Box
            sx={{
              display: taskData?.task_settings?.enforce_word ? "flex" : "none",
            }}
            ml={4}
            className="enforceInputBox"
          >
            <input
              name="from_word"
              value={taskData.task_settings.from_word ?? ""}
              onChange={setFormData2}
              className="phoneInput"
            />
            <p>{t("addTextTaskPop.to")}</p>
            <input
              name="to_word"
              value={taskData.task_settings.to_word ?? ""}
              onChange={setFormData2}
              className="phoneInput"
            />
            <p>{t("addTextTaskPop.words")}</p>
          </Box>
          <Box className="checkWlabel">
            <input
              className="custom-checkbox"
              type="checkBox"
              name="customize_prompt"
              onClick={(e) => {
                handleTaskSetting(e);
              }}
              defaultChecked={taskData?.task_settings?.customize_prompt}
            />
            <Typography>{t("addTextTaskPop.customizePrompt")}</Typography>
          </Box>
          <Box
            sx={{
              display: taskData?.task_settings?.customize_prompt
                ? "flex"
                : "none",
            }}
            className="wsCpInputBOx"
          >
            <input
              type="text"
              className="phoneInput customizePromptInput"
              placeholder={t("addTextTaskPop.customizePromptPlaceholder")}
              name="customize_prompt_value"
              value={taskData.task_settings.customize_prompt_value ?? ""}
              onChange={setFormData2}
            />
          </Box>
          <Box className="checkWlabel">
            <input
              className="custom-checkbox"
              type="checkBox"
              name="text_format"
              defaultChecked={taskData?.task_settings?.text_format}
              onChange={handleTaskSetting}
            />
            <Typography>{t("addTextTaskPop.TextFormatting")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input
              className="custom-checkbox"
              type="checkBox"
              name="response_required"
              defaultChecked={taskData?.task_settings?.response_required}
              onChange={handleTaskSetting}
            />
            <Typography>{t("addTextTaskPop.responseRequired")}</Typography>
          </Box>
          <Box
            sx={{
              display: !taskData?.task_settings?.response_required
                ? "flex"
                : "none",
            }}
            className="wsCpInputBOx imageTaskDescription"
          >
            <AntSwitch
              inputProps={{ "aria-label": "ant design" }}
              onChange={(el) =>
                setTaskData({
                  ...taskData,
                  task_settings: {
                    ...taskData.task_settings,
                    skip_reason: el.target.checked,
                  },
                })
              }
              checked={taskData?.task_settings?.skip_reason}
            />
            <Typography>Ask for a reason to skip</Typography>
          </Box>

          <Typography className="textTaskSetting ">
            {t("addTextTaskPop.advanceSettingsHead")}
          </Typography>
          <Box className="checkWlabel">
            <input
              className="custom-checkbox"
              type="checkBox"
              name="limit"
              value={taskData.advance_settings.limit ?? ""}
              onChange={handleTaskSetting2}
            />
            <Typography>
              {t("addTextTaskPop.advanceSettingsCheckbox1")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: taskData?.advance_settings?.limit ? "flex" : "none",
            }}
            style={{ marginLeft: "32px" }}
            className="enforceInputBox"
          >
            <FormControl sx={{ width: "100%" }}>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                className="statusBox MuiBox-root css-0"
                name="participant_option"
                value={
                  taskData?.advance_settings?.participant_option ||
                  "Select Participant"
                }
                onChange={handleTaskSetting3}
              >
                {ParticipantItem.length > 0 &&
                  ParticipantItem.map((el, i) => (
                    <MenuItem value={el.value} key={i}>
                      {el.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          {taskData?.advance_settings?.participant_option ===
            "selected_participants" && (
            <Box sx={{ marginLeft: "32px" }} className="enforceInputBox">
              <FormControl sx={{ width: "100%" }}>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  className="statusBox MuiBox-root css-0"
                  value={
                    taskData?.advance_settings?.participant_ids ||
                    "Select Administrator"
                  }
                  onChange={(e) =>
                    setTaskData({
                      ...taskData,
                      advance_settings: {
                        ...taskData.advance_settings,
                        participant_ids: e.target.value,
                      },
                    })
                  }
                  multiple
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return (
                        <Box pl={4.5} className="inputAppDrop">
                          <Typography>Select Participants</Typography>
                        </Box>
                      );
                    }
                    return selected
                      .map((value) => {
                        const user = participantListData.find(
                          (usr) => usr._id === value
                        );
                        return user ? user.name : value;
                      })
                      .join(", ");
                  }}
                >
                  {participantListData.length > 0 &&
                    participantListData.map((el, i) => (
                      <MenuItem value={el._id} key={i}>
                        {el.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          )}

          {taskData?.advance_settings?.participant_option ===
            "selected_participants_groups" && (
            <Box
              sx={{
                marginLeft: "32px",
                width: "auto !important",
                marginBottom: "16px !important",
              }}
              className="searchGroupSection"
            >
              <Box
                pl={4.5}
                className="inputAppDrop"
                onClick={() => setGroupDrop2(!groupDrop2)}
              >
                <img className="groupsIcon" src={groupsIcon} />
                <Typography>
                  {t("pageSettingPop.participantGroupPlaceholder")}
                </Typography>
                <img
                  style={{ rotate: groupDrop2 ? "180deg" : "0deg" }}
                  src={dropArrow}
                />
              </Box>
              <Box
                sx={{ height: groupDrop2 ? "auto" : "0px" }}
                className="checkMain_box"
              >
                <Box className="ckeckBoxRoom">
                  {groupsListData.length > 0 &&
                    groupsListData.map((group, index) =>
                      chackBoxRoom({ group, index })
                    )}
                </Box>
                <Box className="propertyPagination">
                  <Box>
                    <p>1</p>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          <Box className="checkWlabel">
            <input
              className="custom-checkbox"
              type="checkBox"
              name="customize_point"
              value={taskData.advance_settings.customize_point ?? ""}
              onChange={handleTaskSetting2}
            />
            <Typography>
              {t("addTextTaskPop.advanceSettingsCheckbox2")}{" "}
            </Typography>
          </Box>

          <Box
            sx={{
              display: taskData?.advance_settings?.customize_point
                ? "flex"
                : "none",
            }}
            ml={4}
            className="enforceInputBox"
          >
            <input
              style={{ width: "60px !important" }}
              type="text"
              className="phoneInput"
              name="customize_point_award"
              value={taskData.advance_settings.customize_point_award ?? ""}
              onChange={setFormData3}
            />
            <p>Points</p>
          </Box>

          <Box className="checkWlabel">
            <input
              className="custom-checkbox"
              type="checkBox"
              name="task_guidance"
              value={taskData.advance_settings.task_guidance ?? ""}
              onChange={handleTaskSetting2}
            />
            <Typography>Add Task Guidance </Typography>
          </Box>

          <Box
            sx={{
              display: taskData?.advance_settings?.task_guidance
                ? "flex"
                : "none",
            }}
            className="taskGuidanceBox"
          >
            <Box className="taskGuidanceNotifyBox">
              <img src={iIcon2} />
              <Typography>
                Task Guidance for Text Task which help to participant at time of
                task completion
              </Typography>
            </Box>
            <Typography className="DmettingTExt">Task Guidance</Typography>
            <textarea
              className="DmettingTextTextAera"
              placeholder="Enter Task Guidance"
              name="task_guidance_value"
              value={taskData.advance_settings.task_guidance_value}
              onChange={(e) =>
                setTaskData({
                  ...taskData,
                  advance_settings: {
                    ...taskData.advance_settings,
                    task_guidance_value: e.target.value,
                  },
                })
              }
            />
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine
              buttonText={t("commonTxt.cancelBtn")}
              onClick={resetAll}
            />
            <AppBtnOutLine
              buttonText={t("commonTxt.savePreviewBtn")}
              onClick={handelAddTaskPopBack2}
            />
            <AppBtn
              buttonText={t("commonTxt.saveTaskBtn")}
              onClick={() => saveActivityTask()}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
