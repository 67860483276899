import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { sideHaumIcon } from "../../../Assets/Data";
import { groupBtnTab } from "../../../Assets/Data";

import backArrow from "../../../Assets/Images/noun-back.png";
import ToggleOnImage from "../../../Assets/Images/Toggle.png";
import ToggleOffImage from "../../../Assets/Images/Toggle2.png";

import SideMenu from "../../../Components/Collaborator/SideMenu";
import PreviewMailPop from "../../../Components/Collaborator/PreviewMailPop";
import ScheduleMailPop from "../../../Components/Collaborator/ScheduleMailPop";
import UseTemplatePop from "../../../Components/Collaborator/UseTemplatePop";

import { AppBtnOutLine, AppBtn } from "../../../Components/AppTools/AppButton";

export default function ProjectParticipantMailInvitation({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
}) {
  const Navigate = useNavigate();

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const previousPageHandler = () => {
    Navigate("/collaborator/project/directory_participants");
  };

  const [setEmailTemplatePop, emailTemplatePop] = useState(false);

  const email1 = "Enforce a minimum of";
  const email2 = "Enforce a minimum of";

  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();

  const [signatureTextLaOut, setSignatureTextLaOut] = useState("start");
  const [signatureTextItalic, setSignatureTextItalic] = useState(false);
  const [signatureTextBold, setSignatureTextBold] = useState(false);
  const [signatureTextUnderLine, setSignatureTextUnderLine] = useState();
  const [awaitingReply, setAwaitingReply] = useState();
  const [previewMailPop, setPreviewMailPop] = useState(false);
  const [scheduleMailPop, setScheduleMailPop] = useState(false);
  const [useTemplatePop, setUseTemplatePop] = useState(false);

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      previousPageHandler();
    }
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine === "underline") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine === "line-through") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("line-through");
        }
      }
    }
  };

  const handleToggleMenu = () => {
    setAwaitingReply((prevToggleState) => {
      let newToggleState = prevToggleState;
      newToggleState = !newToggleState;
      return newToggleState;
    });
  };

  const handelNextPop = () => {
    setPreviewMailPop(true);
  };

  const handelSchedulePop = () => {
    setScheduleMailPop(true);
  };

  const handleUseTempPop = () => {
    setUseTemplatePop(true);
  };

  useEffect(() => {
    if (emailTemplatePop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [emailTemplatePop]);

  return (
    <>
      {previewMailPop ? (
        <PreviewMailPop
          previewMailPop={previewMailPop}
          setPreviewMailPop={setPreviewMailPop}
        />
      ) : scheduleMailPop ? (
        <ScheduleMailPop
          scheduleMailPop={scheduleMailPop}
          setScheduleMailPop={setScheduleMailPop}
        />
      ) : useTemplatePop ? (
        <UseTemplatePop
          useTemplatePop={useTemplatePop}
          setUseTemplatePop={setUseTemplatePop}
        />
      ) : null}
      <Box className="ResearchersContainer">
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
          </Box>

          <>
            <Box className="researchersMainBox pageContainer">
              <Box className="workspaceListBox">
                <Box
                  onClick={() => previousPageHandler()}
                  className="openBtn"
                  style={{ "margin-bottom": "10px" }}
                >
                  <img src={backArrow} />
                  <Typography className="neonBackText">
                    {t("commonTxt.backBtn")}
                  </Typography>
                </Box>

                <Box className="upperBox">
                  <Box className="leftUpperBox">
                    <Typography className="eTemPopLabel">
                      {t("ProjectParticipantMailInvitation.heading")}
                    </Typography>
                  </Box>
                  <Box className="rightUpperBox">
                    <Box sx={{ width: "13%" }} className="groupBtnTab">
                      {groupBtnTab}
                      <Typography>{t("commonTxt.groups")}</Typography>
                    </Box>
                    <Box
                      onClick={() => {
                        handleUseTempPop();
                      }}
                    >
                      <Typography className="verifyLinkCodeBook">
                        {t("ProjectParticipantMailInvitation.tempLink")}
                      </Typography>
                    </Box>
                    <Box>
                      <AppBtnOutLine buttonText={t("commonTxt.btnTextDraft")} />
                    </Box>
                  </Box>
                </Box>

                <Box className="basicBox customBasicBox">
                  <Typography className="eTemPopLabel">
                    {t("ProjectParticipantMailInvitation.recipient")}
                  </Typography>
                  <Box>
                    <Box className="ProjectRadioInput">
                      <input
                        className="emailSettingInput emailSettingRadio"
                        type="radio"
                        name="projectState"
                        defaultChecked
                      />
                      <Typography className="emailSettingLabel">
                        {t("ProjectParticipantMailInvitation.allParticipient")}
                      </Typography>
                    </Box>
                    <Box className="ProjectRadioInput">
                      <input
                        className="emailSettingInput emailSettingRadio"
                        type="radio"
                        name="projectState"
                        defaultChecked
                      />
                      <Typography className="emailSettingLabel">
                        {t(
                          "ProjectParticipantMailInvitation.activeParticipients"
                        )}
                      </Typography>
                    </Box>
                    <Box className="ProjectRadioInput">
                      <input
                        className="emailSettingInput emailSettingRadio"
                        type="radio"
                        name="projectState"
                        defaultChecked
                      />
                      <Typography className="emailSettingLabel">
                        {t("ProjectParticipantMailInvitation.text1")}
                      </Typography>
                    </Box>
                    <Box className="ProjectRadioInput">
                      <input
                        className="emailSettingInput emailSettingRadio"
                        type="radio"
                        name="projectState"
                        defaultChecked
                      />
                      <Typography className="emailSettingLabel">
                        {t("ProjectParticipantMailInvitation.admin")}
                      </Typography>
                    </Box>
                    <Box className="ProjectRadioInput">
                      <input
                        className="emailSettingInput emailSettingRadio"
                        type="radio"
                        name="projectState"
                        defaultChecked
                      />
                      <Typography className="emailSettingLabel">
                        {t("ProjectParticipantMailInvitation.invitedNewPart")}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography className="eTemPopLabel ">
                      {t("ProjectParticipantMailInvitation.subject")}
                      <samp>*</samp>
                    </Typography>
                  </Box>
                  <Box className="pickerBox ">
                    <input
                      className="eTemInput"
                      placeholder="Welcome Messgae"
                    />
                  </Box>
                </Box>

                <Typography className="eTemPopLabel ">
                  {t("ProjectParticipantMailInvitation.dynamicVal")}
                </Typography>
                <Box className="basicBox">
                  <Box className="eTemPopDyValuBox basicBoxItem">
                    <Box>
                      <Typography className="eTemPopDyValuText">
                        {t("ProjectParticipantMailInvitation.fName")}
                      </Typography>
                      <span className="eTemPopDyValuTextSpan etpTcolon">:</span>
                      <span className="eTemPopDyValuTextSpan">{"{fname}"}</span>
                    </Box>
                    <Box>
                      <Typography className="eTemPopDyValuText">
                        {t("ProjectParticipantMailInvitation.lName")}
                      </Typography>
                      <span className="eTemPopDyValuTextSpan etpTcolon">:</span>
                      <span className="eTemPopDyValuTextSpan">{"{lname}"}</span>
                    </Box>
                    <Box>
                      <Typography className="eTemPopDyValuText">
                        {t("ProjectParticipantMailInvitation.uName")}
                      </Typography>
                      <span className="eTemPopDyValuTextSpan etpTcolon">:</span>
                      <span className="eTemPopDyValuTextSpan">
                        {"{username}"}
                      </span>
                    </Box>
                    <Box>
                      <Typography className="eTemPopDyValuText">
                        {t("ProjectParticipantMailInvitation.password")}
                      </Typography>
                      <span className="eTemPopDyValuTextSpan etpTcolon">:</span>
                      <span className="eTemPopDyValuTextSpan">
                        {"{password}"}
                      </span>
                    </Box>
                  </Box>

                  <Box className="eTemPopDyValuBox basicBoxItem">
                    <Box>
                      <Typography className="eTemPopDyValuText">
                        {t("ProjectParticipantMailInvitation.email")}
                      </Typography>
                      <span className="eTemPopDyValuTextSpan etpTcolon">:</span>
                      <span className="eTemPopDyValuTextSpan">{"{email}"}</span>
                    </Box>
                    <Box>
                      <Typography className="eTemPopDyValuText">
                        {t("ProjectParticipantMailInvitation.participantId")}
                      </Typography>
                      <span className="eTemPopDyValuTextSpan etpTcolon">:</span>
                      <span className="eTemPopDyValuTextSpan">{"{pid}"}</span>
                    </Box>
                    <Box>
                      <Typography className="eTemPopDyValuText">
                        {t("ProjectParticipantMailInvitation.extParticipantId")}
                      </Typography>
                      <span className="eTemPopDyValuTextSpan etpTcolon">:</span>
                      <span className="eTemPopDyValuTextSpan">{"{epid}"}</span>
                    </Box>
                    <Box>
                      <Typography className="eTemPopDyValuText">
                        {t("ProjectParticipantMailInvitation.projectId")}
                      </Typography>
                      <span className="eTemPopDyValuTextSpan etpTcolon">:</span>
                      <span className="eTemPopDyValuTextSpan">{"{prid}"}</span>
                    </Box>
                  </Box>
                </Box>

                <Typography className="eTemPopLabel">
                  {t("ProjectParticipantMailInvitation.message")}
                  <samp>*</samp>
                </Typography>
                <Box className="emailInputBox">
                  <textarea
                    style={{
                      textAlign: messageTextLaOut,
                      fontStyle: messageTextItalic ? "italic" : "normal",
                      fontWeight: messageTextBold ? "bold" : "normal",
                      textDecoration: messageTextUnderLine,
                      // textDecoration: messageTextMLine ? "line-through" : null
                    }}
                    className="emailInput"
                    name="emailMessage"
                    value={emailval?.emailMessage || email1}
                    onChange={handelChange}
                  />
                </Box>

                <Box
                  minWidth={"18%"}
                  className="Tabelsel customToggle"
                  onClick={() => handleToggleMenu()}
                >
                  <img
                    src={awaitingReply ? ToggleOnImage : ToggleOffImage}
                    alt="Toggle"
                  />
                  <Typography>
                    {t("ProjectParticipantMailInvitation.awaitingreply")}
                  </Typography>
                </Box>

                <Box className="eTemPopLogInBtnBox">
                  <Typography className="eTemPopLabel">
                    {t("ProjectParticipantMailInvitation.loginButton")}
                  </Typography>
                  <Box className="loginBtn">
                    <Typography>
                      {t("ProjectParticipantMailInvitation.loginLink")}
                    </Typography>
                  </Box>
                </Box>

                <Typography className="eTemPopLabel">
                  {t("ProjectParticipantMailInvitation.sign")}
                </Typography>
                <Box className="emailInputBox">
                  <textarea
                    style={{
                      textAlign: signatureTextLaOut,
                      fontStyle: signatureTextItalic ? "italic" : "normal",
                      fontWeight: signatureTextBold ? "bold" : "normal",
                      textDecoration: signatureTextUnderLine,
                    }}
                    className="emailInput"
                    name="emailSignature"
                    value={emailval?.emailSignature || email2}
                    onChange={handelChange}
                  />
                </Box>

                <Box className="workPupBtnBox">
                  <AppBtnOutLine
                    buttonText={t("commonTxt.cancelBtn")}
                    onClick={() => previousPageHandler()}
                  />
                  <AppBtnOutLine
                    buttonText={t("ProjectParticipantMailInvitation.schedule")}
                    onClick={() => handelSchedulePop()}
                  />
                  <AppBtn
                    buttonText={t(
                      "ProjectParticipantMailInvitation.previewAndSend"
                    )}
                    onClick={() => handelNextPop()}
                  />
                </Box>
              </Box>
            </Box>
          </>
        </Box>
      </Box>
    </>
  );
}
