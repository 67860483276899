import React, { useState } from 'react'
import "./style.css"
import { Box, Typography } from '@mui/material'

//images
import whitedropIcon from "../../../Assets/Images/Wchevron-down.png";
import { sideHaumIcon } from "../../../Assets/Data";

//components 
import OktaPop from '../../../Components/User/OktaPop';
import SideMenu from "../../../Components/User/SideMenu";
import { useAppSelector } from '../../../lib/store/store';
import { renderUserLayout } from '../../../lib/helpers';



export default function Integrations({ slideMenu, setSlideMenu, workspaceDrop, setWorkSpaceDrop }) {
  const authState = useAppSelector((state) => state.auth);
  const [providerDrop, setProviderDrop] = useState(false)
  const [providerDropVal, setProviderDropVal] = useState()

  const getChildren = () => {
    return (
      <Box className="researchersMainBox pageContainer">
        <Box className="integrationsContainer">
          <OktaPop providerDropVal={providerDropVal} setProviderDropVal={setProviderDropVal} />

          <Typography className='emailNotificationHeaderText'>Integrations</Typography>
          <Typography className='integrationSubHeadText'>
            Integrating Identity Providers permit remote authentication. Each provider can be limited to select user roles.
          </Typography>

          <Box className="radioBox agreementRadioBox">
            <Box>
              <input
                className="emailSettingInput emailSettingRadio"
                type='checkBox'
                defaultChecked
              />
            </Box>
            <Box>
              <Typography className="emailSettingLabel">Facebook</Typography>
            </Box>
          </Box>
          <Box className="radioBox agreementRadioBox">
            <Box>
              <input
                className="emailSettingInput emailSettingRadio"
                type='checkbox'
                defaultChecked
              />
            </Box>
            <Box>
              <Typography className="emailSettingLabel">Google</Typography>
            </Box>
          </Box>

          <Box className="inteBtnBox">
            <Box className="inteBtndropBox" >

              <Box className="directMessageNavBtn" onClick={() => setProviderDrop(!providerDrop)}>
                <Typography className='DropBtnText'>Add Provider</Typography>
                <img src={whitedropIcon} style={{ rotate: providerDrop ? "180deg" : "0deg" }} />
                <Box className="workspaceItemNavPop" sx={{ display: providerDrop ? "flex" : "none" }}>
                  <Box onClick={() => setProviderDropVal("Okta")}>
                    <Typography>Okta</Typography>
                  </Box>
                  <Box onClick={() => setProviderDropVal("OpenID")}>
                    <Typography>OpenID</Typography>
                  </Box>
                  <Box onClick={() => setProviderDropVal("Lorem")}>
                    <Typography>Lorem</Typography>
                  </Box>
                </Box>
              </Box>

            </Box>
          </Box>

        </Box>
      </Box>
    )
  }
  return renderUserLayout(authState?.userType, null, getChildren(), 'accountsetting')
}
