import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";

import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import IIcon2 from "../../../Assets/Images/IIcon.png";

//components

import { AppBtnOutLine, AppBtn } from "../../AppTools/AppButton";
import { AntSwitch, MonthStatus } from "../../AppTools/AppTool";
import { researcherAction } from "../../../Services/researcherActions";
import { useAppSelector } from "../../../lib/store/store";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../env";

export default function AddAgreementPop({
  addAgreementPop,
  setAddAgreementPop,
  setReloadList
}) {
  const email1 =
    "Hello {fname}, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Read more";
  const email2 = "Best Regards,  Lorem Ipsum";
  const [emailval, setEmailval] = useState();
  const textareaRef = useRef();
  const [focusedIndex, setFocusedIndex] = useState(null);
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();
  const authState = useAppSelector((state) => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace);

  const [signatureTextLaOut, setSignatureTextLaOut] = useState("start");
  const [signatureTextItalic, setSignatureTextItalic] = useState(false);
  const [signatureTextBold, setSignatureTextBold] = useState(false);
  const [signatureTextUnderLine, setSignatureTextUnderLine] = useState();
  const [settingTab, setSettingTab] = useState(0);
  const [statusDrop, setStatusDrop] = useState(false);
  const [statusDropVal, setStatusDropVal] = useState("Month");
  const payLoadObj = {
    name: "",
    opt_statement: "",
    limit_visibility: false,
    limit_visibility_roles: [],
    require_agreement: false,
    display_on_account: false,
    additional_link: "",
    page_content: "",
    enable_page: false,
    enable_opt_statement: false,
    config_every_check: false,
    config_every: {
      count: 0,
      duration: ""
    },
    status: "",
    workspace_id: "",
  }
  const [agreementPayload, setAgreementPayload] = useState(payLoadObj)

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setAddAgreementPop(false);
    }
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const insertListFn = () => {
    const link = "{{{TERMS_LINK}}}";
    const textarea = textareaRef.current;
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;
  
    const textBefore = agreementPayload.opt_statement.substring(0, startPos);
    const textAfter = agreementPayload.opt_statement.substring(endPos);
  
    // Create the new value
    const newValue = textBefore + link + textAfter;
  
    // Update the state with the new value
    setAgreementPayload({ ...agreementPayload, opt_statement: newValue });
  
    // Use setTimeout to update the cursor position after the state has been updated
    setTimeout(() => {
      textarea.selectionStart = textarea.selectionEnd = startPos + link.length;
    }, 0);
  };
  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine === "underline") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine === "line-through") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("line-through");
        }
      }
    }
  };

  useEffect(() => {
    if (addAgreementPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [addAgreementPop]);

  const handleRoles = (e, role) => {
    let roles = [...agreementPayload.limit_visibility_roles];
    if (e.target.checked) {
      roles.push(role);
    } else {
      roles = roles.filter(r => r !== role);
    }
    setAgreementPayload({ ...agreementPayload, limit_visibility_roles: roles });
  };

  const createAgreement = async (status) => {
    try {
      const payload = { ...agreementPayload }
      payload.status = status;
      payload.config_every.duration = statusDropVal;
      payload.workspace_id = workspaceState?.selectedWorkspace?._id;
      console.log("payload", payload)
      let response = await researcherAction("/super-admin/admin/agreements/create", payload, authState.authToken);
      if (response.success) {
        setAgreementPayload(payLoadObj);
        setAddAgreementPop(false)
        setReloadList(true)
        toast.success(response.message, TOAST_CONFIG);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  }

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: addAgreementPop ? "flex" : "none" }}
        className="workspacePop agreementTemPop"
      >
        <Box
          sx={{ display: addAgreementPop ? "flex" : "none" }}
          className="workspaceContaint emailTemPop"
        >
          <Typography className="workspaceHeader agreementTitle">
            Add Agreement
          </Typography>
          {/* Tabs */}
          <Box className="agreementSettingNav">
            <Box
              onClick={() => setSettingTab(0)}
              className={
                settingTab === 0
                  ? "agreeSetNavTab agreeSetNavTabActive"
                  : "agreeSetNavTab"
              }
            >
              <Typography>Basic Details</Typography>
            </Box>
            <Box
              onClick={() => setSettingTab(1)}
              className={
                settingTab === 1
                  ? "agreeSetNavTab agreeSetNavTabActive"
                  : "agreeSetNavTab"
              }
            >
              <Typography>Agreement Page</Typography>
            </Box>
          </Box>
          {/* Tabs */}
          <Box
            sx={{ display: settingTab === 0 ? "flex" : "none" }}
            className="settingBox"
          >
            <Box className="agreeTextBox infoAgreeTextBox">
              <Box className="iIcontD">
                <img src={IIcon2} />
              </Box>
              <Box>
                <Typography>
                  Use an Agreement Page to host details such as lenghty legal
                  terms. Once enabled, you must link text in the statement above
                  to the page via special link tags. For example, I agree with
                  the link Terms of Service link.
                </Typography>
              </Box>
            </Box>
            <Box className="basicBox">
              <Box>
                <Typography className="eTemPopLabel ">
                  Agreement Name<samp>*</samp>
                </Typography>
                <Box className="pickerBox ">
                  <input className="eTemInput" placeholder="Welcome Messgae" onChange={(e) => setAgreementPayload({ ...agreementPayload, name: e.target.value })} />
                </Box>
              </Box>
            </Box>
            <Box className="agrrementPopLabel">
              <Typography className="eTemPopLabel agreementLabel">
                Opt-in Statement<samp>*</samp>
              </Typography>
              <Typography className="agreementLinkLabel" onClick={insertListFn}>
                Insert Link
              </Typography>
            </Box>
            <Box className="emailInputBox">
              <Box className="emailInputBoxNav">
                <Box
                  onClick={() => setMessageTextItalic(!messageTextItalic)}
                  className="emailImgBox"
                >
                  <img src={IIcon} />
                </Box>
                <Box
                  onClick={() => setMessageTextBold(!messageTextBold)}
                  className="emailImgBox"
                >
                  <img src={BIcon} />
                </Box>
                <Box
                  onClick={() => handelTextlineChekc("underline", "message")}
                  className="emailImgBox"
                >
                  <img src={UnderLineU} />
                </Box>
                <Box
                  onClick={() => handelTextlineChekc("line-through", "message")}
                  className="emailImgBox"
                >
                  <img src={MLineU} />
                </Box>
                <Box className="emailImgBox">
                  <img src={AtachMentIcon} />
                </Box>
                <Box className="emailImgBox">
                  <img src={Qbox} />
                </Box>
                <Box
                  onClick={() => setMessageTextLaOut("start")}
                  className="emailImgBox"
                >
                  <img src={Line1} />
                </Box>
                <Box
                  onClick={() => setMessageTextLaOut("center")}
                  className="emailImgBox"
                >
                  <img src={Line2} />
                </Box>
                <Box
                  onClick={() => setMessageTextLaOut("end")}
                  className="emailImgBox"
                >
                  <img src={Line3} />
                </Box>
                <Box className="emailImgBox">
                  <img src={Line4} />
                </Box>
                <Box className="emailImgBox">
                  <img src={emoji} />
                </Box>
              </Box>
              <textarea
                ref={textareaRef}
                style={{
                  textAlign: messageTextLaOut,
                  fontStyle: messageTextItalic ? "italic" : "normal",
                  fontWeight: messageTextBold ? "bold" : "normal",
                  textDecoration: messageTextUnderLine,
                  // textDecoration: messageTextMLine ? "line-through" : null
                }}
                onChange={(e) => setAgreementPayload({ ...agreementPayload, opt_statement: e.target.value })}
                className="emailInput"
                name="emailMessage"
                placeholder="Enter an Opt-in Statement for the userto check while agreeing"
              />
            </Box>

            <Box className="airadioSwitchBox">
              <AntSwitch
                checked={agreementPayload.limit_visibility}
                inputProps={{ "aria-label": "ant design" }}
                onClick={(e) => setAgreementPayload({ ...agreementPayload, limit_visibility: e.target.checked })}
              />
              <Typography className="switchText">Limit Visibility</Typography>
            </Box>
            {/* Enabel visibility */}
            <Box className="checkBoxLanguageItem agreementLimitVisibility">
              <Box>
                <input
                  type="checkBox"
                  onClick={(e) => handleRoles(e, "researcher")}
                  checked={agreementPayload.limit_visibility_roles.includes("researcher")}
                />
                <Typography className="languageLabel">Researcher</Typography>
              </Box>
              <Box>
                <input
                  type="checkBox"
                  onClick={(e) => handleRoles(e, "collaborator")}
                  checked={agreementPayload.limit_visibility_roles.includes("collaborator")}
                />
                <Typography className="languageLabel">Collaborator</Typography>
              </Box>
              <Box>
                <input
                  type="checkBox"
                  onClick={(e) => handleRoles(e, "observer")}
                  checked={agreementPayload.limit_visibility_roles.includes("observer")}
                />
                <Typography className="languageLabel">Observer</Typography>
              </Box>
              <Box>
                <input
                  type="checkBox"
                  onClick={(e) => handleRoles(e, "participants")}
                  checked={agreementPayload.limit_visibility_roles.includes("participants")}
                />
                <Typography className="languageLabel">Participants</Typography>
              </Box>
            </Box>
            {/* Enabel visibility */}
            <Box className="airadioSwitchBox">
              <AntSwitch
                checked={agreementPayload.require_agreement}
                inputProps={{ "aria-label": "ant design" }}
                onClick={(e) => setAgreementPayload({ ...agreementPayload, require_agreement: e.target.checked })}
              />
              <Typography className="switchText">Require Agreement</Typography>
            </Box>
            <Box className="airadioSwitchBox">
              <AntSwitch
                checked={agreementPayload.display_on_account}
                inputProps={{ "aria-label": "ant design" }}
                onClick={(e) => setAgreementPayload({ ...agreementPayload, display_on_account: e.target.checked })}
              />
              <Typography className="switchText">
                Display on my Account
              </Typography>
            </Box>
            <Box className="airadioSwitchBox">
              <AntSwitch
                checked={agreementPayload.config_every_check}
                inputProps={{ "aria-label": "ant design" }}
                onClick={(e) => setAgreementPayload({ ...agreementPayload, config_every_check: e.target.checked })}
              />
              <Typography className="switchText">
                Confirm Agreement after every{" "}
              </Typography>
              <input className="agreeNumber" onChange={(e) => setAgreementPayload({
                ...agreementPayload, config_every: {
                  ...agreementPayload.config_every,
                  count: e.target.value
                }
              })} />
              <MonthStatus
                setStatusDrop={setStatusDrop}
                statusDrop={statusDrop}
                setStatusDropVal={setStatusDropVal}
                statusDropVal={statusDropVal}
              />
            </Box>
          </Box>

          <Box
            sx={{ display: settingTab === 1 ? "flex" : "none" }}
            className="settingBox"
          >
            <Box className="airadioSwitchBox">
              <AntSwitch
                inputProps={{ "aria-label": "ant design" }}
                checked={agreementPayload.enable_page === "blank"}
                onClick={(e) => setAgreementPayload({ ...agreementPayload, enable_page: "blank" })}
              />
              <Typography className="switchText">
                Enable Agreement Page
              </Typography>
            </Box>
            <Box className="airadioSwitchBox">
              <AntSwitch
                checked={agreementPayload.enable_page === "in-line"}
                onClick={(e) => setAgreementPayload({ ...agreementPayload, enable_page: "in-line" })}
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography className="switchText">
                Display in-line above opt-in statement
              </Typography>
            </Box>

            <Box className="agrrementPopLabel">
              <Typography className="eTemPopLabel agreementLabel">
                Page Content<samp>*</samp>
              </Typography>
              {/* <Typography className="agreementLinkLabel">
                Insert Link
              </Typography> */}
            </Box>
            <Box className="emailInputBox">
              <Box className="emailInputBoxNav">
                <Box
                  onClick={() => setMessageTextItalic(!messageTextItalic)}
                  className="emailImgBox"
                >
                  <img src={IIcon} />
                </Box>
                <Box
                  onClick={() => setMessageTextBold(!messageTextBold)}
                  className="emailImgBox"
                >
                  <img src={BIcon} />
                </Box>
                <Box
                  onClick={() => handelTextlineChekc("underline", "message")}
                  className="emailImgBox"
                >
                  <img src={UnderLineU} />
                </Box>
                <Box
                  onClick={() => handelTextlineChekc("line-through", "message")}
                  className="emailImgBox"
                >
                  <img src={MLineU} />
                </Box>
                <Box className="emailImgBox">
                  <img src={AtachMentIcon} />
                </Box>
                <Box className="emailImgBox">
                  <img src={Qbox} />
                </Box>
                <Box
                  onClick={() => setMessageTextLaOut("start")}
                  className="emailImgBox"
                >
                  <img src={Line1} />
                </Box>
                <Box
                  onClick={() => setMessageTextLaOut("center")}
                  className="emailImgBox"
                >
                  <img src={Line2} />
                </Box>
                <Box
                  onClick={() => setMessageTextLaOut("end")}
                  className="emailImgBox"
                >
                  <img src={Line3} />
                </Box>
                <Box className="emailImgBox">
                  <img src={Line4} />
                </Box>
                <Box className="emailImgBox">
                  <img src={emoji} />
                </Box>
              </Box>
              <textarea
                style={{
                  textAlign: messageTextLaOut,
                  fontStyle: messageTextItalic ? "italic" : "normal",
                  fontWeight: messageTextBold ? "bold" : "normal",
                  textDecoration: messageTextUnderLine,
                  // textDecoration: messageTextMLine ? "line-through" : null
                }}
                onChange={(e) => setAgreementPayload({ ...agreementPayload, page_content: e.target.value })}
                className="emailInput"
                name="emailMessage"
                placeholder="Enter Page Content"
              />
            </Box>

            <Box className="agrrementPopLabel agreeLinkUrl">
              <Typography className="agreeLinkLabel">
                Additional Link
              </Typography>
              <input className="urlInput" placeholder="Enter additional link"
                onChange={(e) => setAgreementPayload({ ...agreementPayload, additional_link: e.target.value })} />
            </Box>
          </Box>
          <Box className="workPupBtnBox">
            <AppBtnOutLine
              buttonText="Cancel"
              onClick={() => setAddAgreementPop(false)}
            />
            <AppBtnOutLine buttonText="Save as Draft" disabled={settingTab === 0} onClick={() => createAgreement("draft")} />
            <AppBtn disabled={settingTab === 0} buttonText="Create" onClick={() => createAgreement("active")} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
