import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";
import { useNavigate } from "react-router-dom";

//images
import backArrow from "../../../Assets/Images/noun-back.png";
import avatar from "../../../Assets/Images/Edit-Avatar.png";
import avarat1 from "../../../Assets/Images/Researcher1.png"
import leftArrow from "../../../Assets/Images/arrow-left.png";
import rightArrow from "../../../Assets/Images/arrow-right.png";
import chevronDown from "../../../Assets/Images/chevron-down.png";


import { sideHaumIcon, taskList } from "../../../Assets/Data";

//Components
import SideMenu from "../../../Components/Observer/SideMenu";
import { PurpleDrop } from "../../../Components/AppTools/AppTool"

export default function ParticipantsRespons({
  workspaceDrop,
  setWorkSpaceDrop,
  slideMenu,
  setSlideMenu,
}) {
  const Navigate = useNavigate();
  const [participantsResNavDrop, setParticipantResNavDrop] = useState(false);
  const [participantsResNavDropVal, setParticipantResNavDropVal] =
    useState("All Tasks");
  const [responceDrop, setResponceDrop] = useState(false)
  const [responceDropVal, setResponceDropVal] = useState(false)

  const responceDropItem = [
    {
      name: "itme1",
      value: "itme1"
    },
    {
      name: "itme2",
      value: "itme2"
    },
    {
      name: "itme3",
      value: "itme4"
    },
  ]


  const productBox = ({ img1, img2, img3, img4, title, subTitle }) => {
    return (
      <Box className="taskProductItem">
        <Typography>{title}</Typography>
        <Box my={1} className="productImgBox">
          <Box className="productImg">
            <img src={img1} />
          </Box>
          <Box className="productImg">
            <img src={img2} />
          </Box>
          <Box className="productImg">
            <img src={img3} />
          </Box>
          <Box className="productImg">
            <img src={img4} />
          </Box>
        </Box>
        <Typography>{subTitle}</Typography>
      </Box>
    );
  };

  return (
    <>
      <Box className="ResearchersContainer objParticViewBox">
        {/* side menu */}
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />

        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
            <Box sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between"

            }}>

              <Box
                ml={2}
                className="allTaskNavDrop"
                onClick={() => setParticipantResNavDrop(!participantsResNavDrop)}
              >
                <Box className="tNDL">
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={leftArrow}
                  />
                </Box>

                <Box className="tndC">
                  <Typography>{participantsResNavDropVal}</Typography>
                  <img
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "10px",
                      rotate: participantsResNavDrop ? "180deg" : "0deg",
                    }}
                    src={chevronDown}
                  />
                </Box>

                <Box className="tNDR">
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={rightArrow}
                  />
                </Box>

                <Box
                  className="statusPop"
                  sx={{ display: participantsResNavDrop ? "block" : "none" }}
                >
                  {taskList?.map((el, index) => (
                    <Box
                      key={index}
                      className="statusPopItem"
                      onClick={() => setParticipantResNavDropVal(el)}
                    >
                      <Typography>{el}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>

              <Box minWidth={"195px"}>
                <PurpleDrop statusDrop={responceDrop} setStatusDrop={setResponceDrop} statusDropVal={responceDropVal} setStatusDropVal={setResponceDropVal} itemAry={responceDropItem} label="Response Options" />
              </Box>
            </Box>




          </Box>

          {/* Page container */}
          <Box className="researchersMainBox pageContainer">
            <Box className="participantsResponsContainer workSpaceListBox">
              <Box className="openBtn">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  onClick={() => Navigate("/activities/task")}
                >
                  <img src={backArrow} />
                  <Typography className="neonBackText">Back</Typography>
                </Box>
              </Box>

              <Typography className="partVSummeryText">Which of these outdoor activities do you participate in? Please select all that apply and explain why these activities are the outdoor activities for you.</Typography>

              <Box className="sideAvatarBox">
                <Box className="sideAvatarImg">
                  <img src={avatar} />
                </Box>

                <Box className="sideTextBox">
                  <Box className="commentSection ">
                    <Typography className="userName">Olivia Rhye</Typography>
                    <samp className="taskHeaderText3">Aug 04, 2023 02:21 PM</samp>
                  </Box>

                  <Box className="checkWLabel">
                    <input type="checkBox" className="custom-checkbox" />
                    <Typography>Cycling</Typography>
                  </Box>
                  <Box className="checkWLabel">
                    <input type="checkBox" className="custom-checkbox" />
                    <Typography>Camping</Typography>
                  </Box>
                  <Box className="checkWLabel">
                    <input type="checkBox" className="custom-checkbox" />
                    <Typography>Hiking</Typography>
                  </Box>
                  <Box className="checkWLabel">
                    <input type="checkBox" className="custom-checkbox" />
                    <Typography>Fishing</Typography>
                  </Box>
                  <Box className="checkWLabel">
                    <input type="checkBox" className="custom-checkbox" />
                    <Typography>Kayaking</Typography>
                  </Box>
                  <Box className="checkWLabel">
                    <input type="checkBox" className="custom-checkbox" />
                    <Typography>Other, please specify which outdoor activity</Typography>
                  </Box>
                  <Box className="checkWLabel">
                    <input type="checkBox" className="custom-checkbox" />
                    <Typography>All of the above</Typography>
                  </Box>
                  <Box className="checkWLabel">
                    <input type="checkBox" className="custom-checkbox" />
                    <Typography>None of the above</Typography>
                  </Box>

                  <Box mt={4}>
                    <Typography mb={1} className="purpelText">Please explain why these activities are the outdoor activities for you.</Typography>
                    <Typography className="">All you need is a backpack, tent and some boots - nothing big or complicated</Typography>
                  </Box>
                </Box>
              </Box>

              <Box mb={4} className="addCommentBox">
                <Box>
                  <img src={avarat1} />
                </Box>
                <input className="phoneInput" placeholder="Add a comment" />
              </Box>


              <Typography className="partVSummeryText">Which of these outdoor activities do you participate in? Please select all that apply and explain why these activities are the outdoor activities for you.</Typography>
              <Box className="sideAvatarBox">
                <Box className="sideAvatarImg">
                  <img src={avatar} />
                </Box>

                <Box className="sideTextBox">
                  <Box className="commentSection ">
                    <Typography className="userName">Olivia Rhye</Typography>
                    <samp className="taskHeaderText3">Aug 04, 2023 02:21 PM</samp>
                  </Box>

                  <Box className="checkWLabel">
                    <input type="checkBox" className="custom-checkbox" />
                    <Typography>Cycling</Typography>
                  </Box>
                  <Box className="checkWLabel">
                    <input type="checkBox" className="custom-checkbox" />
                    <Typography>Camping</Typography>
                  </Box>
                  <Box className="checkWLabel">
                    <input type="checkBox" className="custom-checkbox" />
                    <Typography>Hiking</Typography>
                  </Box>
                  <Box className="checkWLabel">
                    <input type="checkBox" className="custom-checkbox" />
                    <Typography>Fishing</Typography>
                  </Box>
                  <Box className="checkWLabel">
                    <input type="checkBox" className="custom-checkbox" />
                    <Typography>Kayaking</Typography>
                  </Box>
                  <Box className="checkWLabel">
                    <input type="checkBox" className="custom-checkbox" />
                    <Typography>Other, please specify which outdoor activity</Typography>
                  </Box>
                  <Box className="checkWLabel">
                    <input type="checkBox" className="custom-checkbox" />
                    <Typography>All of the above</Typography>
                  </Box>
                  <Box className="checkWLabel">
                    <input type="checkBox" className="custom-checkbox" />
                    <Typography>None of the above</Typography>
                  </Box>

                  <Box mt={4}>
                    <Typography mb={1} className="purpelText">Please explain why these activities are the outdoor activities for you.</Typography>
                    <Typography className="">All you need is a backpack, tent and some boots - nothing big or complicated</Typography>
                  </Box>
                </Box>
              </Box>


            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
