import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";

import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import { viewImage } from "../../../lib/helpers";
import DummyUser from "../../../Assets/Images/user-dummy.png";
import axios from "../../../lib/axios";

//components
import { AppBtnOutLine, AppBtn } from "../../AppTools/AppButton";
import { useAppSelector } from "../../../lib/store/store";
import { API_BASE_URL } from "../../../env";
import { toast } from "react-toastify";

export default function SendEmailParticipantPopup({
  sendEmailPopup,
  setSendEmailPopup,
  setPreviewMailPop,
  participantList,
  setEmailval,
  emailval
}) {
  //   const email1 =
  //     "Hello {fname}, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Read more";
  //   const email2 = "Best Regards,  Lorem Ipsum";

  let authState = useAppSelector(state => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace);

  //   const sendEmailObj = {
  //     "participant_ids": [],
  //     "subject": "",
  //     "message": ""
  //   }
  //   const [emailval, setEmailval] = useState({...sendEmailObj});
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();
  const [signatureTextLaOut, setSignatureTextLaOut] = useState("start");
  const [signatureTextItalic, setSignatureTextItalic] = useState(false);
  const [signatureTextBold, setSignatureTextBold] = useState(false);
  const [loading, setLoading] = useState(false);
  const [signatureTextUnderLine, setSignatureTextUnderLine] = useState();
  const [allParticipantData, setAllParticipantData] = useState([]);

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setSendEmailPopup(false);
    }
  };

  const handelNextPop = () => {
    if (participantList.length < 1)
      return toast.error("Selection required: Please choose at least one participant.");
    if (emailval?.subject == "" || emailval?.message == "")
      return toast.error("Both subject and message fields are required.");
    setPreviewMailPop(true);
    setSendEmailPopup(false);
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
    if (input === "signature") {
      if (p === "underline") {
        if (signatureTextUnderLine === "underline") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (signatureTextUnderLine === "line-through") {
          setSignatureTextUnderLine(null);
          return;
        } else {
          setSignatureTextUnderLine("line-through");
        }
      }
    }
  };

  useEffect(() => {
    getParticipants()
    setEmailval({ ...emailval, users: [...participantList] });
  }, [participantList])

  const getParticipants = async () => {
    setLoading(true);
    return await axios.get(API_BASE_URL + `/super-admin/admin/participants?workspace=${workspaceState?.selectedWorkspace?._id}`).then((res) => {
      setLoading(false);
      if (res.status) {
        setAllParticipantData(res.data.data);
      }
    })
  }

  const matchedParticipants = allParticipantData.filter(participant =>
    participantList.includes(participant._id)
  );

  const handleCheckboxChange = (participantId) => {
    setEmailval((prev) => {
      const newParticipantIds = prev.users.includes(participantId)
        ? prev.users.filter(id => id !== participantId)
        : [...prev.users, participantId];
      return { ...prev, users: newParticipantIds };
    });
  };

  const UserBox = ({ participant }) => {
    let isChecked = emailval.users.includes(participant._id);
    return (
      <Box className="userBox" mb={2}>
        <input
          className="custom-checkbox"
          type="checkBox"
          checked={isChecked}
          onChange={() => {
            handleCheckboxChange(participant._id)
          }}
        />
        <Box className="avatar">
          {(participant?.profile_pic == 'undefined' || participant?.profile_pic == null) ? <img src={DummyUser} /> : <img src={viewImage(participant?.profile_pic)} />}
        </Box>
        <Typography>
          {participant?.name} <br /> <span>{participant?.username}</span>
        </Typography>
      </Box>
    )
  }

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: sendEmailPopup ? "flex" : "none" }}
        className="workspacePop"
      >
        <Box
          sx={{ display: sendEmailPopup ? "flex" : "none" }}
          className="workspaceContaint emailTemPop BrodMail ComposeMail"
        >
          <Box className="mailBordPopHeader">
            <Typography className="workspaceHeader">Compose Message</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography className="unLBtn" mr={2}>
                Use Templates
              </Typography>
              {/* <AppBtnOutLine buttonText="Save as Template" /> */}
            </Box>
          </Box>

          {/* <Box className="basicBox">
            <Box>
              <Typography className="eTemPopLabel">
                Recipients<samp>*</samp>
              </Typography>
              <Box className="pickerBox">
                <input className="eTemInput" placeholder="" />
              </Box>
            </Box>
          </Box> */}
          {/* <Box className="workspaceContaint"> */}
          <Box className="userMainBox userbox-style">
            {matchedParticipants.map(participant => (
              <UserBox participant={participant} key={participant._id} />
            ))}
          </Box>
          {/* </Box> */}

          <Box className="basicBox">
            <Box>
              <Typography className="eTemPopLabel">
                Subject<samp>*</samp>
              </Typography>
              <Box className="pickerBox">
                <input
                  className="eTemInput"
                  placeholder="Welcome to the Project!"
                  name="subject"
                  value={emailval?.subject}
                  onChange={handelChange}
                />
              </Box>
            </Box>
          </Box>

          <Typography className="eTemPopLabel">
            Message<samp>*</samp>
          </Typography>
          <Box className="emailInputBox">
            <Box className="emailInputBoxNav">
              <Box
                onClick={() => setMessageTextItalic(!messageTextItalic)}
                className="emailImgBox"
              >
                <img src={IIcon} />
              </Box>
              <Box
                onClick={() => setMessageTextBold(!messageTextBold)}
                className="emailImgBox"
              >
                <img src={BIcon} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("underline", "message")}
                className="emailImgBox"
              >
                <img src={UnderLineU} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("line-through", "message")}
                className="emailImgBox"
              >
                <img src={MLineU} />
              </Box>
              <Box className="emailImgBox">
                <img src={AtachMentIcon} />
              </Box>
              <Box className="emailImgBox">
                <img src={Qbox} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("start")}
                className="emailImgBox"
              >
                <img src={Line1} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("center")}
                className="emailImgBox"
              >
                <img src={Line2} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("end")}
                className="emailImgBox"
              >
                <img src={Line3} />
              </Box>
              <Box className="emailImgBox">
                <img src={Line4} />
              </Box>
              <Box className="emailImgBox">
                <img src={emoji} />
              </Box>
            </Box>
            <textarea
              style={{
                textAlign: messageTextLaOut,
                fontStyle: messageTextItalic ? "italic" : "normal",
                fontWeight: messageTextBold ? "bold" : "normal",
                textDecoration: messageTextUnderLine,
              }}
              className="emailInput"
              name="message"
              value={emailval?.message}
              onChange={handelChange}
            />
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine
              buttonText="Cancel"
              onClick={() => { setSendEmailPopup(false); setEmailval({ ...emailval, email: "", message: "" }) }}
            />
            <AppBtn
              buttonText="Preview & Send"
              onClick={() => handelNextPop()}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}