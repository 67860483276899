import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.css";

//Images
import whitedropIcon from "../../../Assets/Images/Wchevron-down.png";

//components
import ProjectState from "../ProjectState";
import ProjectBasicConfiguration from "../ProjectBasicConfiguration";
import ProjectPoints from "../ProjectMngPoints";
import ProjectObserverPermission from "../ProjectObserverPermission";
import ProjectManagementPrivacy from "../ProjectManagementPrivacy";
import ProjectProfileField from "../ProjectProfileField";
import ProjectNotification from "../ProjectNotification";
import ProjectAppearance from "../ProjectAppearance";
import ProjectTranscript from "../ProjectTranscript";

export default function ProjectManagementSetting({
  dataReportTabe,
  setDataReportTabe,
}) {
  const [projectSettingDrop, setProjectSettingDrop] = useState(false);
  const [projectSettingDropVal, setProjectSettingDropVal] =
    useState("Project State");
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  useEffect(() => {
    let selTab = searchParams.get("ps");
    let selTab2 = searchParams.get("dr");
    if (selTab && selTab2 != null) {
      var query_string = selTab;
      query_string = query_string.replaceAll("_", " ");
      query_string = query_string.replace(/(^\w|\s\w)/g, (firstCharOfWord) =>
        firstCharOfWord.toUpperCase()
      );
      console.log(query_string);
      setProjectSettingDropVal(query_string);
      setDataReportTabe(parseInt(selTab2));
    } else if (selTab == null) {
      setDataReportTabe(parseInt(selTab2));
    }
  }, []);

  return (
    <>
      <Box className="researchersMainBox pageContainer">
        <Box className="integrationsContainer">
          <Box className="projectBtnBox">
            <Box className="inteBtndropBox">
              <Box
                className="projectManagementNavBtn"
                onClick={() => setProjectSettingDrop(!projectSettingDrop)}
              >
                <Typography className="DropBtnText">
                  {projectSettingDropVal || "Project State"}
                </Typography>
                <img
                  className="dropIcon"
                  src={whitedropIcon}
                  style={{
                    rotate: projectSettingDrop ? "180deg" : "0deg",
                    transition: "all 0.5s",
                    marginLeft: "10px",
                  }}
                />

                <Box
                  className="workspaceItemNavPop projectItemNavPop"
                  sx={{ display: projectSettingDrop ? "flex" : "none" }}
                >
                  <Box
                    onClick={() => {
                      setSearchParams({
                        dr: dataReportTabe,
                        ps: "project_state",
                      });
                      setProjectSettingDropVal("Project State");
                    }}
                  >
                    <Typography>
                      {t("projectSettings.projectSettingDropVal1")}
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => {
                      setSearchParams({
                        dr: dataReportTabe,
                        ps: "basic_configuration",
                      });
                      setProjectSettingDropVal("Basic Configuration");
                    }}
                  >
                    <Typography>
                      {t("projectSettings.projectSettingDropVal2")}
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => {
                      setSearchParams({ dr: dataReportTabe, ps: "points" });
                      setProjectSettingDropVal("Points");
                    }}
                  >
                    <Typography>
                      {t("projectSettings.projectSettingDropVal3")}
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => {
                      setSearchParams({
                        dr: dataReportTabe,
                        ps: "observer_permissions",
                      });
                      setProjectSettingDropVal("Observer Permissions");
                    }}
                  >
                    <Typography>
                      {t("projectSettings.projectSettingDropVal4")}
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => {
                      setSearchParams({ dr: dataReportTabe, ps: "privacy" });
                      setProjectSettingDropVal("Privacy");
                    }}
                  >
                    <Typography>
                      {t("projectSettings.projectSettingDropVal5")}
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => {
                      setSearchParams({
                        dr: dataReportTabe,
                        ps: "profile_fields",
                      });
                      setProjectSettingDropVal("Profile Fields");
                    }}
                  >
                    <Typography>
                      {t("projectSettings.projectSettingDropVal6")}
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => {
                      setSearchParams({
                        dr: dataReportTabe,
                        ps: "notifications",
                      });
                      setProjectSettingDropVal("Notifications");
                    }}
                  >
                    <Typography>
                      {t("projectSettings.projectSettingDropVal7")}
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => {
                      setSearchParams({ dr: dataReportTabe, ps: "appearance" });
                      setProjectSettingDropVal("Appearance");
                    }}
                  >
                    <Typography>
                      {t("projectSettings.projectSettingDropVal8")}
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => {
                      setSearchParams({
                        dr: dataReportTabe,
                        ps: "transcription",
                      });
                      setProjectSettingDropVal("Transcription");
                    }}
                  >
                    <Typography>
                      {t("projectSettings.projectSettingDropVal9")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {projectSettingDropVal === "Project State" ? (
            <ProjectState projectSettingDropVal={projectSettingDropVal} />
          ) : projectSettingDropVal === "Basic Configuration" ? (
            <ProjectBasicConfiguration />
          ) : projectSettingDropVal === "Points" ? (
            <ProjectPoints />
          ) : projectSettingDropVal === "Observer Permissions" ? (
            <ProjectObserverPermission
              projectSettingDropVal={projectSettingDropVal}
            />
          ) : projectSettingDropVal === "Privacy" ? (
            <ProjectManagementPrivacy
              projectSettingDropVal={projectSettingDropVal}
            />
          ) : projectSettingDropVal === "Profile Fields" ? (
            <ProjectProfileField
              projectSettingDropVal={projectSettingDropVal}
            />
          ) : projectSettingDropVal === "Notifications" ? (
            <ProjectNotification
              projectSettingDropVal={projectSettingDropVal}
            />
          ) : projectSettingDropVal === "Appearance" ? (
            <ProjectAppearance projectSettingDropVal={projectSettingDropVal} />
          ) : projectSettingDropVal === "Transcription" ? (
            <ProjectTranscript projectSettingDropVal={projectSettingDropVal} />
          ) : null}
        </Box>
      </Box>
    </>
  );
}
