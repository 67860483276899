import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import axios from "../../../lib/axios";

import DownArrowIcon from "../../../Assets/Images/chevron-down.png";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import MenuItem from "@mui/material/MenuItem";

import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { TOAST_CONFIG } from "../../../lib/constants";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../lib/store/store";
import { getCities, getCountries, getStates } from "../../../Services/master";
import {
  addObserverApi,
  uploadUserDirectoryImage,
} from "../../../Services/adminActions";
import plassIcon from "../../../Assets/Images/plassIcon.png";

export default function AddObserverPop({
  setAddObserverPop,
  addObserverPop,
  setReloadList,
}) {
  const [genderDrop, setGenderDrop] = useState(false);
  const [genderDropVal, setGenderDropVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [countryList, setCountryList] = useState(false);
  const [countryListVal, setCountryListVal] = useState("");
  const [stateList, setStateList] = useState(false);
  const [stateListVal, setStateListVal] = useState("");
  const [cityList, setCityList] = useState(false);
  const [cityListVal, setCityListVal] = useState("");
  const [observerProfileImage, setObserverProfileImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);

  let authState = useAppSelector((state) => state.auth);

  const addWorkspaceObj = {
    workspaces: [],
  };

  const [addObserverToWorkspace, setAddObserverToWorkspace] = useState({
    ...addWorkspaceObj,
  });

  const [allWorkSpaceListData, setAllWorkSpaceListData] = useState([]);

  const initObserverState = {
    name: "",
    email: "",
    password: "",
    username: "",
    phone: "",
    gender: "",
    address_line1: "",
    country: "",
    city: "",
    state: "",
    zipcode: "",
    profile_pic: "",
    status: "active",
  };

  const [createObserver, setCreateObserver] = useState({
    ...initObserverState,
  });

  const handelPopClose = () => {
    setCreateObserver({ ...initObserverState });
    setCountryListVal("");
    setStateListVal("");
    setCityListVal("");
    setAddObserverPop(false);
  };

  const setObserverFormValue = (e) => {
    let newFormData = {
      ...createObserver,
      [e.target.name]: e.target.value,
    };
    setCreateObserver(newFormData);
    if (validationErrors.hasErr) {
      handleValidation(newFormData);
    }
  };

  useEffect(() => {
    getAllCountryList();
    getWorkSpaceList();
  }, []);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "200px",
        overflowY: "auto",
      },
    },
  };

  const handleChange = (event) => {
    const selectedValue = event.target.value === "" ? 0 : event.target.value;
    setAddObserverToWorkspace((prevGroup) => ({
      ...prevGroup,
      [event.target.name]: selectedValue,
    }));
    setCreateObserver((prevGroup) => ({
      ...prevGroup,
      [event.target.name]: selectedValue,
    }));
  };

  console.log("The value of createObserver:", createObserver);

  const getWorkSpaceList = async () => {
    let res = await axios.get(`/super-admin/admin/workspaces`);
    if (res.status) {
      const workspaces = res.data.data;
      const allOption = { _id: "all", name: "All" };
      const workspaceListWithAll = [allOption, ...workspaces];
      setAllWorkSpaceListData(workspaceListWithAll);
    }
  };

  const getAllCountryList = () => {
    getCountries().then((res) => {
      if (res.success && res.data.data.length > 0) {
        setCountryData(res.data.data);
      }
    });
  };

  const getAllStatesList = (country_id) => {
    getStates(country_id).then((res) => {
      if (res.success && res.data.data.length > 0) {
        setStatesData(res.data.data);
      }
    });
  };

  const getAllCitiesList = (state_id, country_id) => {
    getCities(country_id, state_id).then((res) => {
      if (res.success && res.data.data.length > 0) {
        setCitiesData(res.data.data);
      }
    });
  };

  const handleCountryChange = (e) => {
    setStateListVal("");
    setCityListVal("");
    let newState = { ...createObserver, country: e._id };
    setCreateObserver(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
    getAllStatesList(e.id);
  };

  const handleStateChange = (e) => {
    let newState = { ...createObserver, state: e._id };
    setCityListVal("");
    setCreateObserver(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
    getAllCitiesList(e.id, e.country_id);
  };

  const handleCityChange = (e) => {
    let newState = { ...createObserver, city: e._id };
    setCreateObserver(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
  };

  const validationObj = {
    hasErr: false,
    name: {
      error: false,
      msg: "",
    },
    email: {
      error: false,
      msg: "",
    },
    password: {
      error: false,
      msg: "",
    },
    username: {
      error: false,
      msg: "",
    },
    phone: {
      error: false,
      msg: "",
    },
    gender: {
      error: false,
      msg: "",
    },
    address_line1: {
      error: false,
      msg: "",
    },
    country: {
      error: false,
      msg: "",
    },
    state: {
      error: false,
      msg: "",
    },
    city: {
      error: false,
      msg: "",
    },
    zipcode: {
      error: false,
      msg: "",
    },
  };

  const [validationErrors, setValidationErrors] = useState({
    ...validationObj,
  });

  const handleValidation = (formInput) => {
    formInput = formInput || createObserver;
    let validationerr = { ...validationObj };

    if (formInput.name == "") {
      validationerr.hasErr = true;
      validationerr.name = {
        error: true,
        msg: "Name is required",
      };
    }

    if (formInput.password == "") {
      validationerr.hasErr = true;
      validationerr.password = {
        error: true,
        msg: "Password is required",
      };
    }

    if (formInput.email == "") {
      validationerr.hasErr = true;
      validationerr.email = {
        error: true,
        msg: "Email is required",
      };
    }

    if (formInput.username == "") {
      validationerr.hasErr = true;
      validationerr.username = {
        error: true,
        msg: "Username is required",
      };
    }

    if (formInput.phone == "") {
      validationerr.hasErr = true;
      validationerr.phone = {
        error: true,
        msg: "Phone is required",
      };
    }

    if (formInput.gender == "") {
      validationerr.hasErr = true;
      validationerr.gender = {
        error: true,
        msg: "Gender is required",
      };
    }

    if (formInput.address_line1 == "") {
      validationerr.hasErr = true;
      validationerr.address_line1 = {
        error: true,
        msg: "Address is required",
      };
    }

    if (formInput.country == "") {
      validationerr.hasErr = true;
      validationerr.country = {
        error: true,
        msg: "Country is required",
      };
    }

    if (formInput.state == "") {
      validationerr.hasErr = true;
      validationerr.state = {
        error: true,
        msg: "State is required",
      };
    }

    if (formInput.city == "") {
      validationerr.hasErr = true;
      validationerr.city = {
        error: true,
        msg: "City is required",
      };
    }

    if (formInput.zipcode == "") {
      validationerr.hasErr = true;
      validationerr.zipcode = {
        error: true,
        msg: "Zipcode is required",
      };
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setObserverProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
      updateImage(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileInputObsChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      setCoverImage(file);
      reader.onload = () => {
        setObserverProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
      updateImage(file);
    }
  };

  const updateImage = async (file) => {
    if (!file) return false;
    const formData = new FormData();
    formData.append("file", file);
    try {
      let imageUploadRes = await uploadUserDirectoryImage(
        formData,
        authState.authToken
      );
      if (imageUploadRes.success) {
        setCreateObserver({
          ...createObserver,
          profile_pic: imageUploadRes.data.upload_id,
        });
        toast.success(imageUploadRes.message, TOAST_CONFIG);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  };

  let handleCreateObserver = async () => {
    if (!handleValidation()) {
      toast.error("Please correct form fields", TOAST_CONFIG);
      return false;
    }

    setLoading(true);
    try {
      let observerRes = await addObserverApi(
        createObserver,
        authState.authToken
      );
      setLoading(false);
      if (observerRes.success) {
        toast.success(observerRes.message, TOAST_CONFIG);
        setAddObserverPop(false);
        setReloadList(true);
        // handelPopClose();
      } else {
        toast.error(observerRes.message, TOAST_CONFIG);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  // const handelPopClose = (e) => {
  //   if (e.target.id === "popBackdrop") {
  //     setAddObserverPop(false);
  //   }
  // };

  useEffect(() => {
    if (addObserverPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [addObserverPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        sx={{ display: addObserverPop ? "flex" : "none" }}
        className="addResearcherPop"
      >
        <Box
          sx={{ display: addObserverPop ? "flex" : "none" }}
          className="addResearcherContaint"
        >
          <Typography className="addResearcherHeader">Add Observer</Typography>

          {/* =================WorkspaceDropDown============== * */}

          <FormControl
            className="addResearcherLabel"
            sx={{ width: "100%",marginBottom:"4%" }}
            size="medium"
          >
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={addObserverToWorkspace?.workspaces}
              onChange={handleChange}
              name="workspaces"
              multiple
              displayEmpty
              MenuProps={MenuProps}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <p>Select Workspaces</p>;
                }
                return selected
                  .map((value) => {
                    const workspace = allWorkSpaceListData.find(
                      (workspace) => workspace._id === value
                    );
                    return workspace ? workspace.name : value;
                  })
                  .join(", ");
              }}
            >
              {allWorkSpaceListData.map((workspace, index) => (
                <MenuItem value={workspace._id} key={index}>
                  {workspace.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* =================WorkspaceDropDown============== */}

          <Typography className="addResearcherLabel">
            Observer's Full Name
          </Typography>
          {/* <Typography className="addResearcherLabel">
            Researcher's Full Name
          </Typography>
          <input
            className="addResearcherInput addResearcherInputName"
            placeholder="Enter the full name of the Researcher"
          /> */}
          <input
            name="name"
            value={createObserver.name}
            onChange={setObserverFormValue}
            className="addResearcherInput addResearcherInputName"
            placeholder="Enter the full name of the Researcher"
          />
          <span className="validationerr inputerr">
            {validationErrors.name.msg}
          </span>

          <Box className="imgbox">
            <Typography className="workspaceLabel">
              Observer profile image
            </Typography>

            <Box
              className="fileInputBox upload-area"
              onClick={() => {
                document.getElementById("observerFileInput").click();
              }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {observerProfileImage ? (
                <img
                  className="fileinputimg"
                  src={observerProfileImage}
                  alt="Upload"
                />
              ) : (
                <img src={plassIcon} alt="Upload" />
              )}
              <input
                id="observerFileInput"
                style={{ display: "none" }}
                type="file"
                onChange={handleFileInputObsChange}
              />
            </Box>
            <Typography className="fileInputLabel">
              Click or drag image to this area to upload
            </Typography>
          </Box>

          <Box className="basicBox lastBasicBox">
            <Box className="inputBox">
              <Typography className="addResearcherLabel">
                Email Address
              </Typography>
              <input
                name="email"
                value={createObserver.email}
                onChange={setObserverFormValue}
                className="addResearcherInput addResearcherInputName"
                placeholder="Enter Observer's valid Email Address"
              />
              <span className="validationerr inputerr">
                {validationErrors.email.msg}
              </span>
            </Box>
            <Box className="inputBox">
              <Typography className="addResearcherLabel">Username</Typography>
              <input
                name="username"
                value={createObserver.username}
                onChange={setObserverFormValue}
                className="addResearcherInput addResearcherInputName"
                placeholder="Enter Username"
              />
              <span className="validationerr inputerr">
                {validationErrors.username.msg}
              </span>
            </Box>
          </Box>
          <Typography sx={{marginTop:"4%"}} className="addResearcherLabel">
            Password
          </Typography>
          <input
            name="password"
            value={createObserver.password}
            onChange={setObserverFormValue}
            className="addResearcherInput addResearcherInputName"
            placeholder="Enter Observer Password"
          />
          <span className="validationerr inputerr">
            {validationErrors.password.msg}
          </span>
          <Box className="basicBox lastBasicBox">
            <Box className="inputBox">
              <Typography className="addResearcherLabel">
                Phone Number
              </Typography>
              <input
                name="phone"
                value={createObserver.phone}
                onChange={setObserverFormValue}
                className="addResearcherInput"
                placeholder="Enter Observer's valid Phone Number"
              />
              <span className="validationerr">
                {validationErrors.phone.msg}
              </span>
            </Box>
            <Box className="inputBox">
              <Typography className="addResearcherLabel">Gender</Typography>
              <input
                onClick={() => setGenderDrop(!genderDrop)}
                name="gender"
                value={createObserver.gender}
                onChange={setObserverFormValue}
                // value={genderDropVal}
                className="addResearcherInput genderInput"
                placeholder="Select Observer Gender"
              />
              <img
                style={{ rotate: genderDrop ? "180deg" : "0deg" }}
                onClick={() => setGenderDrop(!genderDrop)}
                className="downArrow"
                src={DownArrowIcon}
              />

              <Box
                sx={{ height: genderDrop ? "auto" : "0px" }}
                className="genderDrop"
              >
                <Box
                  onClick={() => {
                    setCreateObserver({
                      ...createObserver,
                      gender: "male",
                    });
                    setGenderDropVal("Male");
                    setGenderDrop(false);
                  }}
                  className="genderDropItem"
                >
                  <Typography>Male</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setCreateObserver({
                      ...createObserver,
                      gender: "female",
                    });
                    setGenderDropVal("Female");
                    setGenderDrop(false);
                  }}
                  className="genderDropItem"
                >
                  <Typography>Female</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setCreateObserver({
                      ...createObserver,
                      gender: "others",
                    });
                    setGenderDropVal("Other");
                    setGenderDrop(false);
                  }}
                  className="genderDropItem"
                >
                  <Typography>Other</Typography>
                </Box>
              </Box>
              <span className="validationerr">
                {validationErrors.gender.msg}
              </span>
            </Box>
          </Box>

          <Typography className="addResearcherLabel">Address</Typography>
          <input
            name="address_line1"
            value={createObserver.address_line1}
            onChange={setObserverFormValue}
            className="addResearcherInput addResearcherInputName"
            placeholder="Enter Observers's valid Address"
          />
          <span className="validationerr inputerr">
            {validationErrors.address_line1.msg}
          </span>

          <Box className="basicBox lastBasicBox">
            <Box className="inputBox">
              <Typography className="addResearcherLabel">Country</Typography>
              <input
                onClick={(e) => setCountryList(!countryList)}
                value={countryListVal}
                className="addResearcherInput genderInput"
                placeholder="Choose a Category"
              />
              <img
                style={{ rotate: countryList ? "180deg" : "0deg" }}
                onClick={() => setCountryList(!countryList)}
                className="downArrowPop"
                src={DownArrowIcon}
              />

              <Box
                sx={{
                  maxHeight: countryList ? "300px" : "0px",
                  overflowY: countryList ? "scroll" : "hidden",
                }}
                className="categoryDropDown"
              >
                {countryData.map((data) => (
                  <Box
                    key={data.id}
                    value={data.id}
                    className="categoryDropDownItem"
                    onClick={() => {
                      handleCountryChange(data);
                      setCountryList(false);
                      setCountryListVal(data.name);
                    }}
                  >
                    <Typography>{data.name}</Typography>
                  </Box>
                ))}
              </Box>
              <span className="validationerr">
                {validationErrors.country.msg}
              </span>
            </Box>
            <Box className="inputBox">
              <Typography className="addResearcherLabel">State</Typography>
              <input
                onClick={() => setStateList(!stateList)}
                value={stateListVal}
                className="addResearcherInput genderInput"
                placeholder="Choose a Category"
              />
              <img
                style={{ rotate: stateList ? "180deg" : "0deg" }}
                onClick={() => setStateList(!stateList)}
                className="downArrowPop"
                src={DownArrowIcon}
              />

              <Box
                sx={{
                  maxHeight: stateList ? "300px" : "0px",
                  overflowY: stateList ? "scroll" : "hidden",
                }}
                className="categoryDropDown"
              >
                {statesData.map((data) => (
                  <Box
                    key={data.id}
                    value={data.id}
                    className="categoryDropDownItem"
                    onClick={() => {
                      handleStateChange(data, data.country_id);
                      setStateList(false);
                      setStateListVal(data.name);
                    }}
                  >
                    <Typography>{data.name}</Typography>
                  </Box>
                ))}
              </Box>
              <span className="validationerr">
                {validationErrors.state.msg}
              </span>
            </Box>
          </Box>
          <Box className="basicBox lastBasicBox">
            <Box className="inputBox">
              <Typography className="addResearcherLabel">City</Typography>
              <input
                onClick={() => setCityList(!cityList)}
                // value={createCompany.city_name}
                value={cityListVal}
                className="addResearcherInput genderInput"
                placeholder="Choose a Category"
              />
              <img
                style={{ rotate: cityList ? "180deg" : "0deg" }}
                onClick={() => setCityList(!cityList)}
                className="downArrowPop"
                src={DownArrowIcon}
              />

              <Box
                sx={{
                  maxHeight: cityList ? "300px" : "0px",
                  overflowY: cityList ? "scroll" : "hidden",
                }}
                className="categoryDropDown"
              >
                {citiesData.map((data) => (
                  <Box
                    key={data.id}
                    value={data.id}
                    className="categoryDropDownItem"
                    onClick={() => {
                      handleCityChange(data);
                      setCityList(false);
                      setCityListVal(data.name);
                    }}
                  >
                    <Typography>{data.name}</Typography>
                  </Box>
                ))}
              </Box>
              <span className="validationerr">{validationErrors.city.msg}</span>
            </Box>
            <Box className="inputBox">
              <Typography className="addResearcherLabel">ZIP Code</Typography>
              <input
                className="addResearcherInput"
                name="zipcode"
                value={createObserver.zipcode}
                onChange={setObserverFormValue}
                placeholder="Enter Zip code"
              />
              <span className="validationerr">
                {validationErrors.zipcode.msg}
              </span>
            </Box>
          </Box>
          {/* <Box className="basicBox lastBasicBox">
            <Box className="inputBox">
              <Typography className="addResearcherLabel">City</Typography>
              <input className="addResearcherInput" placeholder="" />
            </Box>
            <Box className="inputBox">
              <Typography className="addResearcherLabel">State</Typography>
              <input className="addResearcherInput" placeholder="" />
            </Box>
          </Box>
          <Box className="basicBox lastBasicBox">
            <Box className="inputBox">
              <Typography className="addResearcherLabel">Country</Typography>
              <input className="addResearcherInput" placeholder="" />
            </Box>
            <Box className="inputBox">
              <Typography className="addResearcherLabel">ZIP Code</Typography>
              <input className="addResearcherInput" placeholder="" />
            </Box>
          </Box> */}

          <Box className="addResearcherPupBtnBox">
            <AppBtnOutLine
              buttonText="Cancel"
              onClick={() => handelPopClose()}
            />
            <AppBtn buttonText="Add" onClick={handleCreateObserver} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
