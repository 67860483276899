import React, { useState } from 'react'
import "./style.css"
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";

//images
import banner from "../../../Assets/Images/logBanner.png"
import CLogo from "../../../Assets/Images/Frame 243811.png"
import googleIcon from "../../../Assets/Images/google.png"
import whiteArrow from "../../../Assets/Images/white Arrow.png"
import VisibleIcon from "../../../Assets/Images/VisibleIcon.png"
import FacebookIcon from "../../../Assets/Images/facebook.png"
import RedInfo from "../../../Assets/Images/info.png";
import { login } from '../../../Services/auth';
import { AppBtn, AppBtnOutLine, PurplelBtn, RedBtn } from '../../../Components/AppTools/AppButton';
import { invationsResponse } from '../../../Services/adminActions';
import { toast } from 'react-toastify';
import { TOAST_CONFIG } from '../../../lib/constants';

export default function Invations() {
    const Navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [loading, setLoading] = useState(false);


    const inviteRspnse = async (status) => {
        try {
            const payload = {
                "email": params.get('email'),
                "token": params.get('token'),
                "status": status
            }

            setLoading(true)
            const response = await invationsResponse(payload)
            setLoading(false);
            if (response.success) {
                toast.success(response.message,TOAST_CONFIG)
                Navigate(`/admin/login`)
            } else {
                toast.error(response.message,TOAST_CONFIG);
            }
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong! Please try again',TOAST_CONFIG);
            setLoading(false);
        }
    }

    return (
        <>
            <Box className="logInPage">
                <Box className="banner">
                    <Typography className=''>Superadmin Proposal</Typography>
                    <Box mt={3} className="slideBox">
                        <Box className="sBox"></Box>
                        <Box className="sBox"></Box>
                        <Box className="sBox sBoxActive"></Box>
                    </Box>
                    <img src={banner} />
                </Box>

                <Box className="signInBox">
                    <Box className="signInInnerBox">
                        <Box className="signColog">
                            <img onClick={() => Navigate("/")} src={CLogo} />
                        </Box>
                        <Box>
                            <RedBtn buttonText="Cancel" onClick={() => inviteRspnse("rejected")} />
                            <PurplelBtn buttonText="Accept" onClick={() => inviteRspnse("accepted")} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
