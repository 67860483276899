import React, { useState } from 'react';
import { Box, Typography } from "@mui/material";
import "./style.css";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";


//images
import reviewImg from "../../../Assets/Images/reviewImgBg.png"
import resInnerCardIMg from "../../../Assets/Images/resCardImg.png"
import loveEmoji from "../../../Assets/Images/emoji/love.png"
import confuseEmoji from "../../../Assets/Images/emoji/confuse.png"
import ultiEmoji from "../../../Assets/Images/emoji/ulti.png"
import greenTikEmoji from "../../../Assets/Images/emoji/greenTik.png"
import backArrow from "../../../Assets/Images/noun-back.png";


//component
import SideMenu from "../../../Components/Participants/SideMenu";
import { sideHaumIcon } from "../../../Assets/Data";
import { AppBtn, AppBtnOutLine, PurplelBtn } from '../../../Components/AppTools/AppButton';
import { IBox } from "../../../Components/AppTools/AppTool"


import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export default function ImageTask({ slideMenu, setSlideMenu, workspaceDrop }) {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [selectDrop, setSelectDrop] = useState(null);

  const lineBar = 80;
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#4F33D1",
    },
  }));


  return (
    <>
      <Box className="ResearchersContainer imageActivityPage imageTask">
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon">
              {sideHaumIcon}
            </Box>
            <Box className="ontheDotNav">
              <PurplelBtn buttonText={t("commonTxt.previouspage")} />
              <AppBtn buttonText={t("commonTxt.nextPage")} />
            </Box>
          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer onthedot">
            <Box className="workspaceListBox">
              <Box mb={3} width="74px" onClick={() => Navigate("/participants/activities")} className="openBtn">
                <img src={backArrow} />
                <Typography className="neonBackText">Back</Typography>
              </Box>
              <Typography className='activeProgText'>{t("commonTxt.activityProgress")}</Typography>
              <Box className="lineBar">
                <Typography>{lineBar}%</Typography>
                <BorderLinearProgress variant="determinate" value={lineBar} />
              </Box>
              <Typography className="lineBarSubText">{t("onTheDro.TaskComp")}</Typography>

              <Box className="otdmainLabelBox">
                <Typography className='ActInSectionMainHeader'>{t("imageTask.imageTaskHeader")}</Typography>
                <Box className="skipBtnBox">
                  <Typography>Save as Draft</Typography>
                  <AppBtnOutLine buttonText="Skip Task" />
                </Box>
              </Box>
              <IBox text="The important information for this task will be displayed here" />
              <Typography className='imgUploadHeader'>{t("imageTask.viewBrand")}</Typography>
              <Box className="reviewImgBox">
                <img className="reviewBgimg" src={reviewImg} />
                <Box className="emojiSection">
                  <img src={loveEmoji} />
                  <img src={ultiEmoji} />
                  <img src={confuseEmoji} />
                  <img src={greenTikEmoji} />
                </Box>
                <Box sx={{ display: selectDrop === null ? "none" : "block" }} className="writeCommentBox">
                  <Typography className='wcHeader'>Enter comment</Typography>
                  <textarea placeholder='Enter commnet you want to add' />
                  <Box className="workPupBtnBox">
                    <AppBtnOutLine buttonText="Cancel" onClick={() => setSelectDrop(null)} />
                    <AppBtn buttonText="Submit" />
                  </Box>
                </Box>

              </Box>
              <Typography className='imgBottominputLabel'>{t("ImageActivity.feelAbout")}</Typography>
              <textarea className='massageInput' placeholder="Enter your response here" />

              <Box className="lineDropBox">
                <Typography className='lineDLabel'>{t("imageTask.associatedBrand")}</Typography>

                <Box className="lineDrop">
                  <Box className="LDrop" onClick={() => setSelectDrop(0)}>
                    <Box className="lDropSelectBox" sx={{ display: selectDrop === 0 ? "block" : "none" }}></Box>
                    <Typography>{t("imageTask.VeryPoor")}</Typography>
                  </Box>
                  <Box className="LLine"></Box>
                  <Box className="LDrop" onClick={() => setSelectDrop(1)}>
                    <Box className="lDropSelectBox" sx={{ display: selectDrop === 1 ? "block" : "none" }}></Box>
                    <Typography>{t("imageTask.Poor")}</Typography>
                  </Box>
                  <Box className="LLine"></Box>
                  <Box className="LDrop" onClick={() => setSelectDrop(2)}>
                    <Box className="lDropSelectBox" sx={{ display: selectDrop === 2 ? "block" : "none" }}></Box>
                    <Typography>{t("imageTask.Okay")}</Typography>
                  </Box>
                  <Box className="LLine"></Box>
                  <Box className="LDrop" onClick={() => setSelectDrop(3)}>
                    <Box className="lDropSelectBox" sx={{ display: selectDrop === 3 ? "block" : "none" }}></Box>
                    <Typography>{t("imageTask.Good")}</Typography>
                  </Box>
                </Box>

              </Box>
              <Typography className='imgUploadHeader'>{t("imageTask.YourResponses")}</Typography>
              <Box className="yourResponceBox">

                <Box className="responce_Box">
                  <Typography>{t("imageTask.Response")} 01</Typography>
                  <Box mt={2} className="resInnerBox">
                    <img src={loveEmoji} className='loveEmoji' />
                    <img src={resInnerCardIMg} />
                    <Box>
                      <Typography className='resHed'>{t("imageTask.Response")}</Typography>
                      <Typography>{t("imageTask.responseSummery")}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="responce_Box">
                  <Typography>{t("imageTask.Response")} 02</Typography>
                  <Box mt={2} className="resInnerBox">
                    <img src={loveEmoji} className='loveEmoji' />
                    <img src={resInnerCardIMg} />
                    <Box>
                      <Typography className='resHed'>{t("imageTask.Response")}</Typography>
                      <Typography>{t("imageTask.responseSummery")}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

    </>
  )
}
