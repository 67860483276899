import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from "react-i18next";
import "./style.css";

//components 
import { AppBtn, AppBtnOutLine } from '../../../Components/AppTools/AppButton';
import { AntSwitch } from '../../AppTools/AppTool';

export default function ProjectNotification({ projectSettingDropVal }) {

    const {
        t,
        i18n: { changeLanguage, language },
      } = useTranslation();

  return (
    <>   
        <Box sx={{ display: projectSettingDropVal === "Notifications" ? "block" : "none" }}>
            <Box className="projectNotificationMainBox">
                <Box className="projectNotificationBox">
                    <Box>
                        <Typography className='allPartRedioLabel'>{t("projectNotifications.projectNotificationsHead1")}</Typography>
                        <Typography className='projectNotificationSubHead'>{t("projectNotifications.projectNotificationsSubText1")}</Typography>
                    </Box>
                    <Box className="projectNotificationSwitchBox">
                        <AntSwitch
                        inputProps={{ "aria-label": "ant design" }}
                        defaultChecked
                        />
                        <Typography>{t("commonTxt.userType2A")}</Typography>
                    </Box>
                    <Box className="projectNotificationSwitchBox">
                        <AntSwitch
                        inputProps={{ "aria-label": "ant design" }}
                        defaultChecked
                        />
                        <Typography>{t("commonTxt.userType3A")}</Typography>
                    </Box>
                    <Box className="projectNotificationSwitchBox">
                        <AntSwitch
                        inputProps={{ "aria-label": "ant design" }}
                        defaultChecked
                        />
                        <Typography>{t("commonTxt.userType4A")}</Typography>
                    </Box>
                </Box>
                <Box className="projectNotificationBox">
                    <Box>
                        <Typography className='allPartRedioLabel'>{t("projectNotifications.projectNotificationsHead2")}</Typography>
                        <Typography className='projectNotificationSubHead'>{t("projectNotifications.projectNotificationsSubText1")}</Typography>
                    </Box>
                    <Box className="projectNotificationSwitchBox">
                        <AntSwitch
                        inputProps={{ "aria-label": "ant design" }}
                        defaultChecked
                        />
                        <Typography>{t("commonTxt.userType2A")}</Typography>
                    </Box>
                    <Box className="projectNotificationSwitchBox">
                        <AntSwitch
                        inputProps={{ "aria-label": "ant design" }}
                        defaultChecked
                        />
                        <Typography>{t("commonTxt.userType3A")}</Typography>
                    </Box>
                    <Box className="projectNotificationSwitchBox">
                        <AntSwitch
                        inputProps={{ "aria-label": "ant design" }}
                        defaultChecked
                        />
                        <Typography>{t("commonTxt.userType4A")}</Typography>
                    </Box>
                </Box>
                <Box className="projectNotificationBox">
                    <Box>
                        <Typography className='allPartRedioLabel'>{t("projectNotifications.projectNotificationsHead3")}</Typography>
                        <Typography className='projectNotificationSubHead'>{t("projectNotifications.projectNotificationsSubText3")}</Typography>
                    </Box>
                    <Box className="projectNotificationSwitchBox">
                        <AntSwitch
                        inputProps={{ "aria-label": "ant design" }}
                        defaultChecked
                        />
                        <Typography>{t("commonTxt.userType2A")}</Typography>
                    </Box>
                    <Box className="projectNotificationSwitchBox">
                        <AntSwitch
                        inputProps={{ "aria-label": "ant design" }}
                        defaultChecked
                        />
                        <Typography>{t("commonTxt.userType3A")}</Typography>
                    </Box>
                </Box>
                <Box className="projectNotificationBox">
                    <Box>
                        <Typography className='allPartRedioLabel'>{t("projectNotifications.projectNotificationsHead4")}</Typography>
                        <Typography className='projectNotificationSubHead'>{t("projectNotifications.projectNotificationsSubText4")}</Typography>
                    </Box>
                    <Box className="projectNotificationSwitchBox">
                        <AntSwitch
                        inputProps={{ "aria-label": "ant design" }}
                        defaultChecked
                        />
                        <Typography>{t("commonTxt.userType2A")}</Typography>
                    </Box>
                    <Box className="projectNotificationSwitchBox">
                        <AntSwitch
                        inputProps={{ "aria-label": "ant design" }}
                        defaultChecked
                        />
                        <Typography>{t("commonTxt.userType3A")}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box className="workPupBtnBox">
                <AppBtnOutLine buttonText={t("commonTxt.resetBtn")} />
                <AppBtn buttonText={t("commonTxt.saveBtn")} />
            </Box>
        </Box>
    </>
  )
}
