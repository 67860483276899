import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import "./style.css";

import whitedropIcon from "../../../Assets/Images/Wchevron-down.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";
import clockIcon from "../../../Assets/Images/clock-light.png";
import { useTranslation } from "react-i18next";

//component
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import RevokePointPopup from "../RevokePointPopup";

//data
import { summarizedData, groupBtnTab, calendarIcon, reportDropVal } from "../../../Assets/Data";

export default function ProjectActionLogReport({
  dataReportTabe,
  setDataReportTabe,
  projectSettingDrop,
  setProjectSettingDrop,
  projectSettingDropVal,
  setProjectSettingDropVal,
}) {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [rangeDrop4, setRangeDrop4] = useState(false);
  const [rangeDropVal4, setRangeDropVal4] = useState("All Actions");

  const [contributionDrop4, setContributionDrop4] = useState(false);
  const [contributionDropVal4, setContributionDropVal4] = useState("All Time");

  const [roleDrop, setRoleDrop] = useState(false);
  const [roleDropVal, setRoleDropVal] = useState("Participants");

  const [recentActionDrop, setRecentActionDrop] = useState(false);
  const [recentActionDropVal, setRecentActionDropVal] = useState("Recent Actions");

  const [actionLogDrop, setActionLogDrop] = useState(false);
  const [actionDropIndex, setActionDropIndex] = useState();
  const [actionLogDrop2, setActionLogDrop2] = useState(false);
  const [actionDropIndex2, setActionDropIndex2] = useState();
  const [revokePointPop, setRevokePointPop] = useState(false);

  useEffect(() => {
    let selTab = searchParams.get("t");
    let selTab2 = searchParams.get("dr");
    if (selTab && selTab2 != null) {
      var query_string = selTab;
      query_string = query_string.replaceAll('_', ' ');
      query_string = query_string.replace(/(^\w|\s\w)/g, firstCharOfWord => firstCharOfWord.toUpperCase());
      console.log(query_string);
      if (selTab === "size_churn") {
        var query_string = "Size & Churn";
        setProjectSettingDropVal(query_string);
        setDataReportTabe(parseInt(selTab2));
      }
      else {
        setProjectSettingDropVal(query_string);
        setDataReportTabe(parseInt(selTab2));
      }
    }
    else if (selTab == null) {
      setDataReportTabe(parseInt(selTab2));
    }
  }, []);

  return (
    <>
      {/* -----------------------------------------------Project Task Report-------------------------------------- */}
      <Box
        sx={{
          display: projectSettingDropVal === "Action Log" ? "block" : "none",
        }}
        className="reportContainer integrationsContainer compositionSection"
      >
        <RevokePointPopup revokePointPop={revokePointPop} setRevokePointPop={setRevokePointPop} />
        <Box className="projectActionLogBtnBox">
          <Box className="inteBtndropBox">
            <Box
              className="projectManagementNavBtn"
              onClick={() => setProjectSettingDrop(!projectSettingDrop)}
            >
              <Typography className="DropBtnText">
                {projectSettingDropVal || "Project State"}
              </Typography>
              <img
                className="dropIcon"
                src={whitedropIcon}
                style={{
                  rotate: projectSettingDrop ? "180deg" : "0deg",
                  transition: "all 0.5s",
                  marginLeft: "10px",
                }}
              />
              <Box
                className="workspaceItemNavPop projectItemNavPop"
                sx={{ display: projectSettingDrop ? "flex" : "none" }}
              >
                {reportDropVal?.map((el, i) => (
                  <Box key={i}
                    onClick={() => {
                      setSearchParams({ dr: dataReportTabe, t: el.qstring });
                      setProjectSettingDropVal(el.title);
                    }}
                  >
                    <Typography>{el.title}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          <Box className="projectActionLogBtns">
            <Box className="groupBtnTab">
              {groupBtnTab}
              <Typography>{t("commonTxt.groupBtn")}</Typography>
            </Box>
            <Box>
              {calendarIcon}
            </Box>
          </Box>
        </Box>
        <Typography className="psSubLabel">{t("commonTxt.filterHead")}</Typography>
        <Box className="participationReportDropSection">

          <Box className="inputBoxWlabel">
            <Typography>{t("projectActionLogReport.roleLabel")}</Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setRoleDrop(!roleDrop)}
            >
              <Typography>
                {roleDropVal || t("projectActionLogReport.roleDropPlaceholder")}
              </Typography>
              <img
                style={{ rotate: roleDrop ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: roleDrop ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setRoleDropVal("Participants")}
                  className="statusPopItem"
                >
                  <Typography>{t("projectActionLogReport.roleDropVal1")}</Typography>
                </Box>
                <Box
                  onClick={() => setRoleDropVal("Researchers")}
                  className="statusPopItem"
                >
                  <Typography>{t("projectActionLogReport.roleDropVal2")}</Typography>
                </Box>
                <Box
                  onClick={() => setRoleDropVal("Collaborators")}
                  className="statusPopItem"
                >
                  <Typography>{t("projectActionLogReport.roleDropVal3")}</Typography>
                </Box>
                <Box
                  onClick={() => setRoleDropVal("Observers")}
                  className="statusPopItem"
                >
                  <Typography>{t("projectActionLogReport.roleDropVal4")}</Typography>
                </Box>

              </Box>
            </Box>
          </Box>

          <Box className="inputBoxWlabel">
            <Typography>{t('projectPointReport.actionDrop')}</Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setRangeDrop4(!rangeDrop4)}
            >
              <Typography>{rangeDropVal4 || t("projectPointReport.actionDropPlaceholder")}</Typography>
              <img
                style={{ rotate: rangeDrop4 ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: rangeDrop4 ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setRangeDropVal4("All Actions")}
                  className="statusPopItem"
                >
                  <Typography>{t("projectPointReport.actionTypeDropVal1")}</Typography>
                </Box>
                <Box
                  onClick={() => setRangeDropVal4("Subscribed")}
                  className="statusPopItem"
                >
                  <Typography>{t("projectPointReport.actionTypeDropVal2")}</Typography>
                </Box>
                <Box
                  onClick={() => setRangeDropVal4("Unsubscribed")}
                  className="statusPopItem"
                >
                  <Typography>{t("projectPointReport.actionTypeDropVal3")}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="inputBoxWlabel">
            <Typography>{t("projectParticipantReport.dateRangeDrop")}</Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setContributionDrop4(!contributionDrop4)}
            >
              <Typography>
                {contributionDropVal4 || t("projectTaskReport.taskStartDateDropPlaceholder")}
              </Typography>
              <img
                style={{ rotate: contributionDrop4 ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: contributionDrop4 ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setContributionDropVal4("All time")}
                  className="statusPopItem"
                >
                  <Typography>{t("projectTaskReport.taskStartDateDropVal1")}</Typography>
                </Box>

              </Box>
            </Box>
          </Box>

        </Box>

        {/* table */}
        <Box mb={3} mt={6} className="tabelNav">
          <Box className="dropWheader">
            <Typography className="psSubLabel">Jul 31, 2023</Typography>
          </Box>
          <Box sx={{ width: "20%" }} className="repPrintBtnBox">
            {/* Recent Action */}
            <Box
              onClick={() => setRecentActionDrop(!recentActionDrop)}
              className="statusBox latestCreated"
            >
              <Typography className="statusLabel">{recentActionDropVal}</Typography>
              <img
                style={{ rotate: recentActionDrop ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              <img className="clockIcon" src={clockIcon} />
              <Box sx={{ height: recentActionDrop ? "auto" : "0px" }} className="statusPop">
                <Box
                  onClick={() => setRecentActionDropVal("Recent Actions")}
                  className="statusPopItem"
                >
                  <Typography>{t("projectActionLogReport.recentActionDropVal1")}</Typography>
                </Box>

              </Box>
            </Box>
            {/* Recent Action */}
          </Box>
        </Box>
        <Box className="workListBox">
          <Box className="haderRow" minWidth={"1000px"}>
            <Box minWidth={"26%"} className="Tabelsel TabelStatesel">
              <Typography>{t("projectActionLogReport.tblName")}</Typography>
            </Box>
            <Box minWidth={"18%"} className="Tabelsel Tabelcentersel">
              <Typography>{t("projectActionLogReport.tblVisit")}</Typography>
            </Box>
            <Box minWidth={"12%"} className="Tabelsel Tabelcentersel">
              <Typography>{t("projectActionLogReport.tblAction")}</Typography>
            </Box>
            <Box minWidth={"16%"} className="Tabelsel Tabelcentersel">
              <Typography>{t("projectActionLogReport.tblPoint")}</Typography>
            </Box>
            <Box minWidth={"17%"} className="Tabelsel Tabelcentersel">
              <Typography>{t("projectActionLogReport.tblLastAction")}</Typography>
            </Box>
            <Box minWidth={"5%"} className="Tabelsel TabelStatesel">

            </Box>
          </Box>

          {summarizedData?.map((el, i) => (
            <Box key={i} className="TabelRow" minWidth={"1000px"}>
              <Box minWidth={"26%"} className="Tabelsel navFsel navtable">
                <Box className="tabelAvatar">
                  {" "}
                  <img src={el.img} />{" "}
                </Box>
                <Typography sx={{ cursor: "pointer", width: "95%" }}>
                  {" "}
                  {el.name}
                </Typography>
              </Box>
              <Box minWidth={"18%"} className="Tabelsel Tabelcentersel">
                <Typography>{el.Completed}</Typography>
              </Box>
              <Box minWidth={"12%"} className="Tabelsel Tabelcentersel">
                <Typography>{el.InProgress}</Typography>
              </Box>
              <Box minWidth={"16%"} className="Tabelsel Tabelcentersel">
                <Typography>{el.YettoBegin}</Typography>
              </Box>
              <Box minWidth={"17%"} className="Tabelsel Tabelcentersel">
                <Typography>{el.Total}</Typography>
              </Box>
              <Box minWidth={"5%"} className="Tabelsel TabelStatesel actionDropIcon">
                <img
                  style={{ rotate: actionLogDrop2 && i === actionDropIndex2 ? "180deg" : "0deg" }}
                  src={dropArrow}
                  onClick={() => {
                    setActionLogDrop2(!actionLogDrop2);
                    setActionDropIndex2(i);
                  }}
                />
                {/* Action log drop */}
                <Box sx={{ display: actionLogDrop2 && i === actionDropIndex2 ? "flex" : "none" }} className="actionLogOptionPop">
                  <Box onClick={() => setRevokePointPop(true)} className="optionPopItem">
                    <Typography className="optionPopItema">{t("projectActionLogReport.actionLogDropVal1")}</Typography>
                  </Box>
                  <Box className="optionPopItem">
                    <Typography className="optionPopItema">{t("projectActionLogReport.actionLogDropVal2")}</Typography>
                  </Box>
                  <Box className="optionPopItem">
                    <Typography className="optionPopItema">{t("projectActionLogReport.actionLogDropVal3")}</Typography>
                  </Box>
                </Box>
                {/* Action log drop */}
              </Box>
            </Box>
          ))}
        </Box>
        <Box mb={3} mt={6} className="tabelNav">
          <Box className="dropWheader">
            <Typography className="psSubLabel">Jul 16, 2023</Typography>
          </Box>
        </Box>
        <Box className="workListBox">
          <Box className="haderRow" minWidth={"1000px"}>
            <Box minWidth={"26%"} className="Tabelsel TabelStatesel">
              <Typography>{t("projectActionLogReport.tblName")}</Typography>
            </Box>
            <Box minWidth={"18%"} className="Tabelsel Tabelcentersel">
              <Typography>{t("projectActionLogReport.tblVisit")}</Typography>
            </Box>
            <Box minWidth={"12%"} className="Tabelsel Tabelcentersel">
              <Typography>{t("projectActionLogReport.tblAction")}</Typography>
            </Box>
            <Box minWidth={"16%"} className="Tabelsel Tabelcentersel">
              <Typography>{t("projectActionLogReport.tblPoint")}</Typography>
            </Box>
            <Box minWidth={"17%"} className="Tabelsel Tabelcentersel">
              <Typography>{t("projectActionLogReport.tblLastAction")}</Typography>
            </Box>
            <Box minWidth={"5%"} className="Tabelsel TabelStatesel">

            </Box>
          </Box>

          {summarizedData?.map((el, i) => (
            <Box key={i} className="TabelRow" minWidth={"1000px"}>
              <Box minWidth={"26%"} className="Tabelsel navFsel navtable">
                <Box className="tabelAvatar">
                  {" "}
                  <img src={el.img} />{" "}
                </Box>
                <Typography sx={{ cursor: "pointer", width: "95%" }}>
                  {" "}
                  {el.name}
                </Typography>
              </Box>
              <Box minWidth={"18%"} className="Tabelsel Tabelcentersel">
                <Typography>{el.Completed}</Typography>
              </Box>
              <Box minWidth={"12%"} className="Tabelsel Tabelcentersel">
                <Typography>{el.InProgress}</Typography>
              </Box>
              <Box minWidth={"16%"} className="Tabelsel Tabelcentersel">
                <Typography>{el.YettoBegin}</Typography>
              </Box>
              <Box minWidth={"17%"} className="Tabelsel Tabelcentersel">
                <Typography>{el.Total}</Typography>
              </Box>
              <Box minWidth={"5%"} className="Tabelsel TabelStatesel actionDropIcon">
                <img
                  style={{ rotate: actionLogDrop && i === actionDropIndex ? "180deg" : "0deg" }}
                  src={dropArrow}
                  onClick={() => {
                    setActionLogDrop(!actionLogDrop);
                    setActionDropIndex(i);
                  }}
                />
                {/* Action log drop */}
                <Box sx={{ display: actionLogDrop && i === actionDropIndex ? "flex" : "none" }} className="actionLogOptionPop">
                  <Box onClick={() => setRevokePointPop(true)} className="optionPopItem">
                    <Typography className="optionPopItema">{t("projectActionLogReport.actionLogDropVal1")}</Typography>
                  </Box>
                  <Box className="optionPopItem">
                    <Typography className="optionPopItema">{t("projectActionLogReport.actionLogDropVal2")}</Typography>
                  </Box>
                  <Box className="optionPopItem">
                    <Typography className="optionPopItema">{t("projectActionLogReport.actionLogDropVal3")}</Typography>
                  </Box>
                </Box>
                {/* Action log drop */}
              </Box>
            </Box>
          ))}
        </Box>


        <Box className="workPupBtnBox">
          <AppBtnOutLine buttonText={t("commonTxt.resetBtn")} />
          <AppBtn buttonText={t("commonTxt.viewReportBtn")} />
        </Box>
      </Box>

      {/* -----------------------------------------------Project Task Report-------------------------------------- */}
    </>
  );
}
