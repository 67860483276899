import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  FormLabel,
  TextField,
  Alert,
} from "@mui/material";
import "./style.css";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { sideHaumIcon } from "../../../Assets/Data";


//components
import { AppBtn, AppBtnOutLine } from "../../../Components/AppTools/AppButton";
import IdentityVerificationPop from "../../../Components/User/IdentityVerificationPop";
import { GoTop } from "../../../Components/AppTools/AppTool";
import SideMenu from "../../../Components/User/SideMenu";
import { renderUserLayout } from "../../../lib/helpers";
import { useAppSelector } from "../../../lib/store/store";
import { toast } from "react-toastify";
import { sendOtpToEmail, sendOtpToEmailApi } from "../../../Services/auth";


export default function PasswordManagement({ slideMenu, setSlideMenu, workspaceDrop, setWorkSpaceDrop }) {
  const authState = useAppSelector((state) => state.auth);
  const [Password, setPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState({
    previous_password: false,
    password: false,
    confirm_password: false,
  });
  const [passwordErrorMessage, setPasswordErrorMessage] = useState({
    previous_password: "",
    password: "",
    confirm_password: "",
  });
  const [success, setSuccess] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

  const [sendOtp, setSendOtp] = useState({
    "email": authState.userProfile.email,
    "otp_type": "CHANGE_PASSWORD",
  });
  const [updatePassword, setUpdatePassword] = useState({
    "previous_password": "",
    "password": "",
    "confirm_password": "",
    "otp": "",
  })

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 6) {
      errors.push("Password must have at least 6 characters.");
    }
    if (!/[a-z]/.test(password) || !/[A-Z]/.test(password)) {
      errors.push("Password must contain both uppercase and lowercase letters.");
    }
    if (!/[!@#?]/.test(password)) {
      errors.push("Password must include at least one special character (e.g., ! @ # ?).");
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedPassword = { ...updatePassword, [name]: value };
    setUpdatePassword(updatedPassword);

    let error = false;
    let errorMessage = "";

    const errors = validatePassword(value);
    if (name === "password") {
      if (errors.length > 0) {
        error = true;
        errorMessage = errors[0];
        setIsSaveButtonDisabled(true);
      }else {
        setIsSaveButtonDisabled(false);
      }
    } else if (name === "confirm_password") {
      if (updatedPassword.password !== value) {
        error = true;
        errorMessage = "Password does not match";
        setIsSaveButtonDisabled(true);
      }else {
        setIsSaveButtonDisabled(false);
      }
    } 

    setPasswordError((prev) => ({ ...prev, [name]: error }));
    setPasswordErrorMessage((prev) => ({ ...prev, [name]: errorMessage }));

  };

  const sendOtpApi = async () => {
    try {
      setLoading(true)
      let response = await sendOtpToEmail({ otp_type: "CHANGE_PASSWORD"},authState?.authToken);
      setLoading(false)
      if (response.success) {
        setIdentittyVerificationPop(true)
        toast.success(response.message)
      }
    } catch (error) {
      console.log("error", error)
    }
  }
  

  const [identityVerificationPop, setIdentittyVerificationPop] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    const passErrors = validatePassword(updatePassword.password);
    const ConfirmErrors = validatePassword(updatePassword.confirm_password);
    if (passErrors.length === 0 && ConfirmErrors.length === 0) {
      sendOtpApi();
    } else {
      console.log("passErrors", passErrors)
      console.log("ConfirmErrors", ConfirmErrors)
      toast.error("Validation error",);
    }
  };
  const getChildren = () => {
    return (
      <Box className="researchersMainBox pageContainer">
        <Box className="PasswordManagementSection">
          <IdentityVerificationPop identityVerificationPop={identityVerificationPop} 
          setIdentittyVerificationPop={setIdentittyVerificationPop}
          updatePasswordSt={updatePassword}
          setUpdatePassword={setUpdatePassword}
           />
          <Grid container spacing={2}>
            <Box
              sx={{ display: "block" }}
              className="ChangePasswordForm"
            >
              <Typography variant="h3" gutterBottom>
                Change Password
              </Typography>
              <Alert icon={<InfoOutlinedIcon />} severity="success" className="alertnote">
                <ul>
                  <li>Password must have at least 6 characters</li>
                  <li>A mixture of both uppercase and lowercase letters</li>
                  <li>Inclusion of at least one special character, e.g., ! @ # ? ] </li>
                </ul>
              </Alert>

              <Grid container spacing={2} className="PasswordForm">

                <Grid item sm={6}>
                  <FormLabel className="label">Current Password</FormLabel>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    placeholder="Enter current password"
                    variant="outlined"
                    autoComplete="off"
                    size="small"
                    type="password"
                    name="previous_password"
                    value={updatePassword.previous_password}
                    onChange={handleChange}
                    error={passwordError.previous_password}
                    helperText={passwordError ? passwordErrorMessage.previous_password : ""}
                    color={success ? "success" : "primary"}
                    focused={success}
                  />
                </Grid>
                <Grid item sm={6}></Grid>
                <Grid item sm={6}>
                  <FormLabel className={`label ${passwordError.password ? "error" : success ? "success" : ""}`}>New Password</FormLabel>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    placeholder="Enter new password"
                    variant="outlined"
                    size="small"
                    name="password"
                    value={updatePassword.password}
                    onChange={handleChange}
                    error={passwordError.password}
                    helperText={passwordError ? passwordErrorMessage.password : ""}
                    color={success ? "success" : "primary"}
                    focused={success}
                  />
                </Grid>
                <Grid item sm={6}>
                  <FormLabel className={`label ${passwordError.confirm_password ? "error" : success ? "success" : ""}`}>Confirmed New Password</FormLabel>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    placeholder="Enter confirmed new password"
                    variant="outlined"
                    size="small"
                    name="confirm_password"
                    value={updatePassword.confirm_password}
                    onChange={handleChange}
                    error={passwordError.confirm_password}
                    helperText={passwordError ? passwordErrorMessage.confirm_password : ""}
                    color={success ? "success" : "primary"}
                    focused={success}
                  />
                </Grid>
                <Grid item sm={12}>
                  <Box className="workPupBtnBox">
                    <AppBtnOutLine buttonText="Cancel" />
                    <AppBtn buttonText="Save Password"
                      // onClick={() => setErrorPassword(!ErrorPassword)} 
                      onClick={handleSubmit}
                      disabled={isSaveButtonDisabled}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {/* <Grid item sm={12}>
              <Box className="workPupBtnBox">
                <AppBtn
                  buttonText="Change Password"
                  onClick={() => {sendOtpApi()}}
                />
              </Box>
            </Grid> */}
          </Grid>



          {/* <Box
            sx={{ display: ErrorPassword ? "block" : "none" }}
            className="ChangePasswordForm"
          >
            <Typography variant="h3" gutterBottom>
              Error Password
            </Typography>
            <Alert icon={<InfoOutlinedIcon />} severity="success" className="alertnote">
              <ul>
                <li>Password must have at least 6 characters</li>
                <li>A mixture of both uppercase and lowercase letters</li>
                <li>Inclusion of at least one special character, e.g., ! @ # ? ] </li>
              </ul>
            </Alert>

            <Grid container spacing={2} className="PasswordForm">

              <Grid item sm={6}>
                <FormLabel className="label">Current Password</FormLabel>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  placeholder="Enter current password"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item sm={6}>

              </Grid>
              <Grid item sm={6}>
                <FormLabel className="label error">New Password</FormLabel>
                <TextField
                  fullWidth
                  error
                  id="outlined-basic"
                  placeholder="Enter new password"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item sm={6}>
                <FormLabel className="label error">Confirmed New Password</FormLabel>
                <TextField
                  fullWidth
                  error
                  helperText="Password does not match"
                  id="outlined-basic"
                  placeholder="Enter confirmed new password"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item sm={12}>
                <Box className="workPupBtnBox">
                  <AppBtnOutLine buttonText="Cancel" />
                  <AppBtn buttonText="Save Password" onClick={() => setSuccessPassword(!SuccessPassword)} />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{ display: SuccessPassword ? "block" : "none" }}
            className="ChangePasswordForm"
          >
            <Typography variant="h3" gutterBottom>
              Success Password
            </Typography>
            <Alert icon={<InfoOutlinedIcon />} severity="success" className="alertnote">
              <ul>
                <li>Password must have at least 6 characters</li>
                <li>A mixture of both uppercase and lowercase letters</li>
                <li>Inclusion of at least one special character, e.g., ! @ # ? ] </li>
              </ul>
            </Alert>

            <Grid container spacing={2} className="PasswordForm">

              <Grid item sm={6}>
                <FormLabel className="label">Current Password</FormLabel>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  placeholder="Enter current password"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item sm={6}>

              </Grid>
              <Grid item sm={6}>
                <FormLabel className="label success">New Password</FormLabel>
                <TextField
                  fullWidth
                  color="success"
                  focused
                  id="outlined-basic"
                  placeholder="Enter new password"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item sm={6}>
                <FormLabel className="label success">Confirmed New Password</FormLabel>
                <TextField
                  fullWidth
                  color="success"
                  focused
                  id="outlined-basic"
                  placeholder="Enter confirmed new password"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item sm={12}>
                <Box className="workPupBtnBox">
                  <AppBtnOutLine buttonText="Cancel" />
                  <AppBtn
                    onClick={() => {
                      setIdentittyVerificationPop(true);
                      GoTop();
                    }}
                    buttonText="Save Password" />
                </Box>
              </Grid>
            </Grid>
          </Box> */}

        </Box>
      </Box>
    )
  }

  return renderUserLayout(authState?.userType, null, getChildren(), 'accountsetting')
}
