import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import "./style.css";
//images

export default function AddVideoPlaylistPop({ addVideoPlaylistPop, setAddVideoPlaylistPop }) {

  const email1 = "Type the question/instructions to be visible"

  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();

  const [redio, setRedio] = useState(0);

  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
        setAddVideoPlaylistPop(false);
    }
  };
  
  useEffect(() => {
    if (addVideoPlaylistPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [addVideoPlaylistPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: addVideoPlaylistPop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople "
      >
        <Box
          sx={{ display: addVideoPlaylistPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className="taskPopMainLabel addVideoPlayListHead">Add Playlist</Typography>
          <Box className="basicBoxItem">
            <Typography className='eTemPopLabel '>Name</Typography>
            <Box className="pickerBox">
              <input className='eTemInput' placeholder='Enter playlist name' />
            </Box>
          </Box>

          <Typography className='eTemPopLabel '>Description</Typography>
          <Box className="emailInputBox textTaskInstruction">
          <Box className="emailInputBoxNav"></Box>
            <textarea
              style={{
                textAlign: messageTextLaOut,
                fontStyle: messageTextItalic ? "italic" : "normal",
                fontWeight: messageTextBold ? "bold" : "normal",
                textDecoration: messageTextUnderLine,
                // textDecoration: messageTextMLine ? "line-through" : null
              }}
              className="emailInput"
              placeholder="Enter a discription or instructions for the card"
              name="emailMessage"
              value={emailval?.emailMessage || email1}
              onChange={handelChange}
            />
          </Box>
          <Typography className="eTemPopLabel videoPlaylistRatingLabel">Ratings</Typography>
              <Box mt={3} className="redioItem" onClick={() => setRedio(0)}>
                <Box
                  className={
                    redio === 0
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>Researcher, Collaborator & Clients</Typography>
              </Box>
              <Box mt={3} className="redioItem" onClick={() => setRedio(1)}>
                <Box
                  className={
                    redio === 1
                      ? "customRedio customRedioActive"
                      : "customRedio"
                  }
                >
                  <Box></Box>
                </Box>
                <Typography>
                    Only Researcher & Collaborator
                </Typography>
              </Box>
                  
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={() => setAddVideoPlaylistPop(false)} />
            <AppBtn buttonText="Save" />
          </Box>



        </Box>
      </Box>
    </>
  );
}
