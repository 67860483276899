import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.css";

//Images
import iIcon2 from "../../../Assets/Images/IIcon.png";
import videoPlaylistImg from "../../../Assets/Images/video-playlist.png";
import arrow from "../../../Assets/Images/arrow.png";

//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { SearchBar, LatestCreated } from "../../AppTools/AppTool";
import AddVideoPlaylistPop from "../AddVideoPlaylistPop";

export default function ProjectVideoPlaylist() {
  const Navigate = useNavigate();
  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Latest Created");
  const [addVideoPlaylistPop, setAddVideoPlaylistPop] = useState(false);
  const {
    t,
    i18n: { changeLanguage, language },
} = useTranslation();

  const [currentPage, setCurrentPage] = useState(0);

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };


  const videoIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
      <path d="M19.1668 2.83073L13.3335 6.9974L19.1668 11.1641V2.83073Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11.6668 1.16406H2.50016C1.57969 1.16406 0.833496 1.91025 0.833496 2.83073V11.1641C0.833496 12.0845 1.57969 12.8307 2.50016 12.8307H11.6668C12.5873 12.8307 13.3335 12.0845 13.3335 11.1641V2.83073C13.3335 1.91025 12.5873 1.16406 11.6668 1.16406Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )

  return (
    <>
      <Box className="researchersMainBox pageContainer">
        <AddVideoPlaylistPop
          addVideoPlaylistPop={addVideoPlaylistPop}
          setAddVideoPlaylistPop={setAddVideoPlaylistPop}
        />
        <Box className="integrationsContainer">
          <Box className="projectVideoPlaylistBox">
            <Box className="projectVideoPlaylistHeadBtn">
              <Box className="videoPlaylistHead">
                <Typography className="emailNotificationHeaderText">
                {t("projectVideoPlaylist.videoPlaylist")}
                </Typography>
              </Box>
              <Box className="videoPlaylistHeadBtn">
                <AppBtn
                  buttonText={t("commonTxt.addPlaylist")}
                  onClick={() => setAddVideoPlaylistPop(true)}
                />
              </Box>
            </Box>

            <Box className="iBox videoPlaylistiBox">
              <img src={iIcon2} />
              <Typography>
              {t("projectVideoPlaylist.videoPlaylistNotify")}
              </Typography>
            </Box>

            <Box className="projectVideoPlaylistFilters">
              <Box className="videoPlaylistSearchFilter">
                <SearchBar />
              </Box>
              <Box
                sx={{ minWidth: "241px" }}
                className="videoPlaylistShortFilter"
              >
                <LatestCreated
                  setLatestDrop={setLatestDrop}
                  latestCDrop={latestCDrop}
                  setLatestDropVal={setLatestDropVal}
                  latestDropVal={latestDropVal}
                />
              </Box>
            </Box>
            <Box className="projectVideoPlaylistMain">
              <Box className="projectVideoPlaylistSection">
                <Box className="videoPlaylistImg">
                  <img src={videoPlaylistImg} />
                  <Box className="videoImgLabel">
                    {videoIcon}
                    <Typography>3 {t("commonTxt.videos")}</Typography>
                  </Box>
                </Box>
                <Box className="videoPlaylistText">
                  <Typography className="eTemPopLabel">
                    Best Quotes: Concept B
                  </Typography>

                  <Box className="videoPlaylistDes">
                    <Box className="videoPlaylistLinkTime">
                      <Box className="videoPlaylistLink">
                        <Typography
                          className="taskHeaderText"
                          onClick={() =>
                            Navigate("/collaborator/project/video-clip")
                          }
                        >
                          {t("projectVideoPlaylist.viewPlaylist")}
                        </Typography>
                      </Box>
                      <Box className="videoPlaylistLink">
                        <Typography>{t("projectVideoPlaylist.updatedOn")} 3 days ago</Typography>
                      </Box>
                    </Box>
                    <Typography>
                      This collection of quotes is taken from week 4. We
                      presented two concepts and got some great reactions to
                      concept B. This highlights the overall how people felt
                      about the inclusion of a widget in power adapter area.
                      Overall they found the unit to be.....
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography>Jun 30</Typography>
                </Box>
              </Box>
              <Box className="projectVideoPlaylistSection">
                <Box className="videoPlaylistImg">
                  <img src={videoPlaylistImg} />
                  <Box className="videoImgLabel">
                    {videoIcon}
                    <Typography>3 {t("commonTxt.videos")}</Typography>
                  </Box>
                </Box>
                <Box className="videoPlaylistText">
                  <Typography className="eTemPopLabel">
                    Best Quotes: Concept B
                  </Typography>

                  <Box className="videoPlaylistDes">
                    <Box className="videoPlaylistLinkTime">
                      <Box className="videoPlaylistLink">
                        <Typography onClick={() =>
                            Navigate("/researchers/project/video-clip")
                          } className="taskHeaderText">
                          {t("projectVideoPlaylist.viewPlaylist")}
                        </Typography>
                      </Box>
                      <Box className="videoPlaylistLink">
                        <Typography>{t("projectVideoPlaylist.updatedOn")} 3 days ago</Typography>
                      </Box>
                    </Box>
                    <Typography>
                      This collection of quotes is taken from week 4. We
                      presented two concepts and got some great reactions to
                      concept B. This highlights the overall how people felt
                      about the inclusion of a widget in power adapter area.
                      Overall they found the unit to be.....
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography>Jun 30</Typography>
                </Box>
              </Box>
              <Box className="projectVideoPlaylistSection">
                <Box className="videoPlaylistImg">
                  <img src={videoPlaylistImg} />
                  <Box className="videoImgLabel">
                    {videoIcon}
                    <Typography>3 {t("commonTxt.videos")}</Typography>
                  </Box>
                </Box>
                <Box className="videoPlaylistText">
                  <Typography className="eTemPopLabel">
                    Best Quotes: Concept B
                  </Typography>

                  <Box className="videoPlaylistDes">
                    <Box className="videoPlaylistLinkTime">
                      <Box className="videoPlaylistLink">
                        <Typography onClick={() =>
                            Navigate("/researchers/project/video-clip")
                          } className="taskHeaderText">
                          {t("projectVideoPlaylist.viewPlaylist")}
                        </Typography>
                      </Box>
                      <Box className="videoPlaylistLink">
                        <Typography>{t("projectVideoPlaylist.updatedOn")} 3 days ago</Typography>
                      </Box>
                    </Box>
                    <Typography>
                      This collection of quotes is taken from week 4. We
                      presented two concepts and got some great reactions to
                      concept B. This highlights the overall how people felt
                      about the inclusion of a widget in power adapter area.
                      Overall they found the unit to be.....
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography>Jun 30</Typography>
                </Box>
              </Box>
            </Box>
            {/* Pagination */}
            <Box className="pagenation">
              <Box onClick={handlePrev} className="tabelBtn">
                <img src={arrow} />
                <Typography>{t("commonTxt.previouspage")}</Typography>
              </Box>

              <Box className="pageNumberBox">
                <Box
                  onClick={() => setCurrentPage(0)}
                  className={
                    currentPage === 0
                      ? "pageNumber pageNumberActive"
                      : "pageNumber"
                  }
                >
                  <p>1</p>
                </Box>
                <Box
                  onClick={() => setCurrentPage(1)}
                  className={
                    currentPage === 1
                      ? "pageNumber pageNumberActive"
                      : "pageNumber"
                  }
                >
                  <p>2</p>
                </Box>
                <Box
                  onClick={() => setCurrentPage(2)}
                  className={
                    currentPage === 2
                      ? "pageNumber pageNumberActive"
                      : "pageNumber"
                  }
                >
                  <p>3</p>
                </Box>
                <Box
                  onClick={() => setCurrentPage(3)}
                  className={
                    currentPage === 3
                      ? "pageNumber pageNumberActive"
                      : "pageNumber"
                  }
                >
                  <p>4</p>
                </Box>
                <Box
                  onClick={() => setCurrentPage(4)}
                  className={
                    currentPage === 4
                      ? "pageNumber pageNumberActive"
                      : "pageNumber"
                  }
                >
                  <p>5</p>
                </Box>
              </Box>

              <Box onClick={handleNext} className="tabelBtn">
                <Typography>{t("commonTxt.nextPage")}</Typography>
                <img style={{ rotate: "180deg" }} src={arrow} />
              </Box>
            </Box>
            {/* Pagination */}
            <Box className="workPupBtnBox">
              <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} />
              <AppBtn buttonText={t("commonTxt.continueBtn")} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
