import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Dialog,
  FormLabel,
  Grid,
  TextField,
} from "@mui/material";

import ReactFlagsSelect from "react-flags-select";

import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import featuredIcon from "../../../Assets/Images/icon-featured.svg";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";

import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { makeProject } from "../../../Services/adminActions";
import { useAppSelector } from "../../../lib/store/store";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { getCountries } from "../../../Services/master";

const AddProjectNextPop = ({
  addProjectNextPop,
  setaddProjectNextPop,
  setAddProjectPop,
  fullScreen,
  setReloadList,
  reloadList,
  setProjectState,
  projectState,
  setImage
}) => {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const handleClose2 = () => {
    setAddProjectPop(false);
    setaddProjectNextPop(false);
  };

  const handleBack = () => {
    setAddProjectPop(true);
    setaddProjectNextPop(false);
  };

  const [SelectTimeZoneDrop, setSelectTimeZoneDrop] = useState(false);
  const [SelectTimeZoneDropVal, setSelectTimeZoneDropVal] = useState("");
  const [selected, setSelected] = useState("");
  const [selected1, setSelected1] = useState("");
  const authState = useAppSelector(state => state.auth);
  const [loading, setLoading] = useState(false);
  const [timezoneList, setTimezoneList] = useState([]);

  const getTimezone = async (code) => {
    getCountries({ iso2: code }).then(response => {
      if (response.success) {
        if (response?.data?.data.length > 0) {
          const country = response?.data?.data[0]
          setTimezoneList(country.timezones)
        }
      }
    })
  }

  // }
  const createProject = async () => {
    setLoading(true);
    try {
      const response = await makeProject(projectState, authState.authToken);
      setLoading(false);
      if (response.success) {
        toast.success(response.message, TOAST_CONFIG);
        if (typeof (setReloadList) != 'undefined') {
          setReloadList(true);
        }
        resetPopup();
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  }

  const resetPopup = () => {
    let reset = {}
    for (const key in projectState) {
      reset[key] = ""
    }
    setProjectState({ ...reset })
    setImage(null);
    handleClose2();
  }

  return (
    <Dialog
      className="DialogSection"
      fullScreen={fullScreen}
      maxWidth={1080}
      open={addProjectNextPop}
      onClose={handleClose2}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <img src={featuredIcon} /> {t("workspaceSplash.addProject")}
      </DialogTitle>
      <Box className="AddFormPanel">
        <Box className="FormFiled">
          <FormLabel
            className="label"
            sx={{ marginBottom: "20px !important" }}
          >
            {t("projectBasicConfiguration.projectLanguageSubHead")}
          </FormLabel>
          <Typography>{t("projectBasicConfiguration.defaultLanguageLabel")}</Typography>
          <ReactFlagsSelect
            className="SelectLanguage"
            selected={projectState.language}
            onSelect={code => {
              setProjectState({ ...projectState, language: code })
            }}
            placeholder={t("projectBasicConfiguration.defaultLanguagePlaceholder")}
          />
        </Box>
        <Box className="FormFiled">
          <FormLabel className="label">{t("projectBasicConfiguration.timeZoneHead")}</FormLabel>
          <Typography>
          {t("addProjectPop.timeZoneText")}
          </Typography>

          <Grid container spacing={2}>
            <Grid item md={6}>
              <Typography>{t("projectBasicConfiguration.selectCountryLabel")}</Typography>
              <ReactFlagsSelect
                className="SelectCountry"
                selected={projectState.country}
                onSelect={code => {
                  setProjectState({ ...projectState, country: code })
                  getTimezone(code)
                }}
              />
            </Grid>
            <Grid item md={6}>
              <Typography>{t("projectBasicConfiguration.selectTimeZoneLabel")}</Typography>
              <Box
                className="inputAppDrop"
                onClick={() =>
                  setSelectTimeZoneDrop(!SelectTimeZoneDrop)
                }
              >
                <Typography>
                  {projectState.timezone ||
                    t("projectBasicConfiguration.selectTimeZonePlaceholder")}
                </Typography>
                <img
                  style={{
                    rotate: SelectTimeZoneDrop ? "180deg" : "0deg",
                  }}
                  src={DownArrowIcon}
                />
                {/* pop */}
                <Box
                  sx={{ height: SelectTimeZoneDrop ? "auto" : "0px" }}
                  className="statusPop"
                >
                  {timezoneList && timezoneList.map(tmezne => (<Box
                    onClick={() => {
                      setProjectState({ ...projectState, timezone: tmezne.zoneName })
                    }
                    }
                    className="statusPopItem"
                  >
                    <Typography>{tmezne.zoneName}</Typography>
                  </Box>))}
                </Box>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box className="FormFiled">
                <FormLabel className="label">{t("activityReport.startDateDropLabel")}</FormLabel>
                <Typography>
                {t("addProjectPop.activityStartDateText")}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <TextField
                      type="month"
                      fullWidth
                      id="outlined-basic"
                      placeholder="August 29, 2023"
                      variant="outlined"
                      size="small"
                      onChange={(e) => setProjectState({ ...projectState, start_date: e.target.value })}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <DialogActions>
        <Box className="workPupBtnBox addProjectPopBtn">
          <AppBtnOutLine
            autoFocus
            onClick={handleClose2}
            buttonText={t("commonTxt.cancelBtn")}
          />
          <AppBtnOutLine
            autoFocus
            onClick={handleBack}
            buttonText={t("commonTxt.backBtn")}
          />
          <AppBtn autoFocus onClick={createProject} buttonText={t("commonTxt.addBtn")} />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddProjectNextPop;
