import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AppRevokeBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import "./style.css";
import { useTranslation } from "react-i18next";

export default function RevokePointPopup({ revokePointPop, setRevokePointPop }) {

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const email1 = ""
  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();

  const [redio, setRedio] = useState(0);

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
        setRevokePointPop(false);
    }
  };

  useEffect(() => {
    if (revokePointPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [revokePointPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: revokePointPop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople"
      >
        <Box
          sx={{ display: revokePointPop ? "flex" : "none" }}
          className="workspaceContaint deletePagePop"
        >
          <Typography className="eTemPopLabel">
          {t("revokePointPopup.revokePointHeaderTxt")}
          </Typography>
          <Typography className="revokeTextarealabel">{t("revokePointPopup.revokePointText")} <samp className="revokePointText">{t("revokePointPopup.revokePointTextRed")}</samp> {t("revokePointPopup.revokePointText2")}</Typography>
          <Typography className="revokeTextarealabel">{t("revokePointPopup.revokePointReasonLabel")}</Typography>
          <Typography className="revokeSubLabel">{t("revokePointPopup.revokePointReasonSubLabel")}</Typography>
          {/* textarea */}
          <Box className="emailInputBox textTaskInstruction">
            <Box className="emailInputBoxNav">
            </Box>
            <textarea
              style={{
                textAlign: messageTextLaOut,
                fontStyle: messageTextItalic ? "italic" : "normal",
                fontWeight: messageTextBold ? "bold" : "normal",
                textDecoration: messageTextUnderLine,
                // textDecoration: messageTextMLine ? "line-through" : null
              }}
              className="emailInput"
              placeholder=""
              name="emailMessage"
              value={emailval?.emailMessage || email1}
            />
          </Box>
          {/* textarea */}
          <Box mt={3} className="redioItem" onClick={() => setRedio(0)}>
            <Box
              className={
                redio === 0
                  ? "customRedio customRedioActive"
                  : "customRedio"
              }
            >
              <Box></Box>
            </Box>
            <Typography>{t("revokePointPopup.revokePointRadioOpt1")}</Typography>
          </Box>
          
          <Box mt={3} className="redioItem" onClick={() => setRedio(1)}>
            <Box
              className={
                redio === 1
                  ? "customRedio customRedioActive"
                  : "customRedio"
              }
            >
              <Box></Box>
            </Box>
            <Typography>{t("revokePointPopup.revokePointRadioOpt2")}</Typography>
          </Box>
            
            <Box className="workPupBtnBox">
              <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => setRevokePointPop(false)} />
              <AppRevokeBtn buttonText={t("commonTxt.revokeBtn")} />
            </Box>
         
        </Box>
      </Box>
    </>
  );
}
