import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HTML5Backend } from "react-dnd-html5-backend";
import App from "./App";
import "./index.css";
import './i18n';
import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";

const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
ReactDOM.createRoot(document.querySelector("#root")).render(
  <BrowserRouter backend={HTML5Backend}>
    <AgoraRTCProvider client={client}>
      <App />
    </AgoraRTCProvider>
  </BrowserRouter>
);
