import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from "@mui/material";
import "./style.css";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";


//images
import uploadIcon from "../../../Assets/Images/uil_upload.png";
import trasIcon from "../../../Assets/Images/fluent_delete-16-regular.png";
import micIcon from "../../../Assets/Images/micIcon.png"
import BlackMicIcon from "../../../Assets/Images/mic.png";
import plassIcon from "../../../Assets/Images/plassIcon.png"
import PDFImg from "../../../Assets/Images/PDF Img.png"
import backArrow from "../../../Assets/Images/noun-back.png";


//component
import SideMenu from "../../../Components/Participants/SideMenu";
import { sideHaumIcon } from "../../../Assets/Data";
import { AppBtn, AppBtnOutLine, PurplelBtn } from '../../../Components/AppTools/AppButton';
import { IBox } from "../../../Components/AppTools/AppTool"
import WaveSurfer from 'wavesurfer.js';

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export default function AnyFileUpload({ slideMenu, setSlideMenu, workspaceDrop }) {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [file, setFile] = useState(null);
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [fileType, setFileType] = useState()

  useEffect(() => {
    if (waveformRef.current) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#ddd',
        progressColor: '#ff6347',
        height: 128,
      });
    }

    return () => {
      if (wavesurfer.current) {
        wavesurfer.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (file && wavesurfer.current) {
      wavesurfer.current.load(URL.createObjectURL(file));
    }
  }, [file]);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);

    setFileType(event.target.files[0]?.type)
    console.log(event.target.files[0]);

    // image/png
    // audio/mpeg
    // video/mp4
    // application/pdf

    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };


  const lineBar = 80;

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#4F33D1",
    },
  }));


  return (
    <>
      <Box className="ResearchersContainer imageActivityPage">
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon">
              {sideHaumIcon}
            </Box>
            <Box className="ontheDotNav">
              <PurplelBtn buttonText={t("commonTxt.previouspage")} />
              <AppBtn buttonText={t("commonTxt.nextPage")} />
            </Box>
          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer onthedot">
            <Box className="workspaceListBox">
              <Box mb={3} width="74px" onClick={() => Navigate("/participants/activities")} className="openBtn">
                <img src={backArrow} />
                <Typography className="neonBackText">Back</Typography>
              </Box>
              <Typography className='activeProgText'>{t("commonTxt.activityProgress")}</Typography>
              <Box className="lineBar">
                <Typography>{lineBar}%</Typography>
                <BorderLinearProgress variant="determinate" value={lineBar} />
              </Box>
              <Typography className="lineBarSubText">{t("onTheDro.TaskComp")}</Typography>

              <Box className="otdmainLabelBox">
                <Typography className='ActInSectionMainHeader'>Task : Any File</Typography>
                <Box className="skipBtnBox">
                  <Typography>{t("onTheDro.SaveDraft")}</Typography>
                  <AppBtnOutLine buttonText="Skip Task" />
                </Box>
              </Box>
              <IBox text="Upload any file type according to the specified File type, template, size and structure." />
              <Typography className='imgUploadHeader'>{t("VideoActivity.uploadLabel")}<span>*</span></Typography>

              <Box className="uploadBox">
                {file ?
                  <>
                    <Box className="ipageUplod">
                      {
                        fileType === "audio/mpeg" ?
                          <Box className="audio_Box">
                            <audio controls>
                              <source src={URL.createObjectURL(file)} type={file.type} />
                              {t("AudioActivity.browserNotSupport")}
                            </audio>
                          </Box> :
                          fileType === "image/png" ?
                            <Box className="imgBox">
                              <img className="fileinputimg" src={URL.createObjectURL(file)} alt="Upload" />
                            </Box> :
                            fileType === "application/pdf" ?
                              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="imgBox">
                                <img style={{ width: "165px", height: "165px" }} className="fileinputimg" src={PDFImg} alt="Upload" />
                              </Box> :
                              null
                      }
                      <Box className="mediaInputText">
                        <Box className="textBox iaTextBox">
                          <Typography className="teraText">
                            {file?.name} {t("ImageActivity.Uploaded")}
                          </Typography>
                          <Box mb={2}>
                            <img src={uploadIcon} />
                            <Typography
                              ml={2}
                              onClick={() => {
                                document.getElementById("fileInput").click();
                              }}>
                              {t("ImageActivity.UploadAudio")}
                            </Typography>

                            <input
                              id="fileInput"
                              type='file'
                              style={{ display: 'none' }}
                              onChange={handleFileChange}
                            />
                          </Box>
                          <Box mb={2}>
                            <img src={BlackMicIcon} />
                            <Typography ml={2}>{t("ImageActivity.RecordAudio")}
                            </Typography>
                          </Box>
                          <Box>
                            <img src={trasIcon} />
                            <Typography ml={2} onClick={() => setFile(null)}>{t("commonTxt.delete")}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Typography className='imgBottominputLabel'>{t("ImageActivity.feelAbout")}</Typography>
                    <textarea className='massageInput' placeholder='Enter your message here' />
                  </>
                  :
                  <>
                    <Box className="imgUplodInputBox" onClick={() => document.getElementById("fileInput").click()}>
                      <input
                        id='fileInput'
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                      />
                      <img src={plassIcon} alt="Camera" />
                    </Box>
                    <Typography
                      onClick={() => document.getElementById("fileInput").click()}
                      className='imageUploadLabelText'><span>Upload file</span></Typography>
                  </>
                }
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
