import React from 'react'
import { Box } from "@mui/material";
import { useAppSelector } from '../../../lib/store/store';
import { USER_TYPE_WS_ADMIN } from '../../../lib/constants';
import { renderUserLayout } from '../../../lib/helpers';

export default function Dashboard() {
  const authState = useAppSelector(state => state.auth);
  const userType = authState.userType || USER_TYPE_WS_ADMIN;
  const getChildren = () => {
    return (
      <Box className="researchersMainBox pageContainer">
        <h1>Dashboard</h1>
      </Box>
    );
  }

  return renderUserLayout(userType, null, getChildren(), 'main');
}
