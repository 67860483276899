import React, { useState } from 'react'
import { Box, Typography } from "@mui/material";
import "./style.css"
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

//images
import questionMark from "../../../Assets/Images/help-web-button 1.png"
import personalGolBgImg from "../../../Assets/Images/personal goals checklist-amico (1) 1.png";
import backArrow from "../../../Assets/Images/noun-back.png";

//component
import SideMenu from "../../../Components/Participants/SideMenu";
import { sideHaumIcon } from "../../../Assets/Data";
import { AppBtn, AppBtnOutLine, PurplelBtn } from '../../../Components/AppTools/AppButton';
import { IBox } from "../../../Components/AppTools/AppTool"

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";


export default function OntheDotStart({ slideMenu, setSlideMenu, workspaceDrop }) {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();


  const lineBar = 80;
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#4F33D1",
    },
  }));


  return (
    <>
      <Box className="ResearchersContainer onTheDotPage">
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon">
              {sideHaumIcon}
            </Box>
            <Box className="ontheDotNav">
              <PurplelBtn buttonText={t("commonTxt.previouspage")} />
              <AppBtn buttonText={t("commonTxt.nextPage")} />
            </Box>

          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer onthedot">
            <Box className="workspaceListBox">
              <Box mb={3} width="74px" onClick={() => Navigate("/participants/activities/on-the-dot")} className="openBtn">
                <img src={backArrow} />
                <Typography className="neonBackText">Back</Typography>
              </Box>

              <Typography className='activeProgText'>{t("commonTxt.activityProgress")}</Typography>
              <Box className="lineBar">
                <Typography>{lineBar}%</Typography>
                <BorderLinearProgress variant="determinate" value={lineBar} />
              </Box>
              <Typography className="lineBarSubText">{t("onTheDro.TaskComp")}</Typography>

              <Box className="otdmainLabelBox">
                <Typography className='ActInSectionMainHeader'>{t("onTheDro.onTheDroHeader")}</Typography>
                <Box className="skipBtnBox">
                  <Typography>{t("onTheDro.SaveDraft")}</Typography>
                  <AppBtnOutLine buttonText="Skip Task" />
                </Box>
              </Box>
              <IBox text="The important information for this task will be displayed here" />

              <Box className="bgImgSection">
                <img src={personalGolBgImg} />
                <Box className="btnboxWText">
                  <Typography className='otdBtnsLabel'>{t("onTheDro.excitedStart")}</Typography>
                  <Typography className='startbgSubText'>{t("onTheDro.excitedStartSubText1")}<span>{t("onTheDro.excitedStartSubText2")}</span></Typography>
                  <Box className="workPupBtnBox">
                    <AppBtn buttonText={t("commonTxt.continueBtn")} />
                  </Box>
                </Box>
              </Box>
              <Box className="bottomActyvityBox">
                <Box className="actGuBtn" onClick={() => window.open("/participants/activities/activity-guidance", "_blank")}>
                  <Typography>{t("onTheDro.ActivityG")}</Typography>
                  <img src={questionMark} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box >
    </>
  )
}
