import { Box, Typography } from "@mui/material";
import React from "react";
import "./style.css";
import { AntSwitch } from "../../AppTools/AppTool";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../lib/store/store";
import { useEffect, useState } from "react";
import { updateWorkSpaceManagementApi } from "../../../Services/adminActions";
import { TOAST_CONFIG } from "../../../lib/constants";
import { toast } from "react-toastify";
import axios from "../../../lib/axios";

export default function AiIntegration() {
    const {
        t,
        i18n: { changeLanguage, language },
    } = useTranslation();
    const workspaceState = useAppSelector((state) => state.workspace?.selectedWorkspace?._id);
    let authState = useAppSelector(state => state.auth);
    const [aiStatus, setAiStatus] = useState();
    const [loading, setLoading] = useState(false);

    const handleAiStatusToggle = (e) => {
        // console.log("Toggle Status=>",e.target.checked);
        let newToggleState = e.target.checked;
        setAiStatus(newToggleState);
        changeStatus(workspaceState, { ai_enabled: e.target.checked });
        //console.log(workspaceState);
        return newToggleState;
    };

    useEffect(() => {
        getWorkspaceStatus();
    }, []);

    const getWorkspaceStatus = async () => {
        setLoading(true);
        let url = `/super-admin/admin/workspaces?_id=${workspaceState}`

        let res = await axios.get(url);
        
        if (res.success) {
            // console.log("Get Workspace Response=>",res.data.data[0]);
            let initialAiStatus = res.data.data[0].ai_enabled;
            setAiStatus(initialAiStatus);
        }
        setLoading(true);
    };

    const changeStatus = async (wrkspaceId, payload) => {
        try {
            // console.log('Payload => ',payload);
            setLoading(true);
            let workSpaceRes = await updateWorkSpaceManagementApi(wrkspaceId, payload, authState.authToken);
            setLoading(false);
            if (workSpaceRes.success) {
                getWorkspaceStatus();
                toast.success(workSpaceRes.message, TOAST_CONFIG);
            } else {
                toast.error(workSpaceRes.message, TOAST_CONFIG);
            }
        } catch (error) {
            // console.log(error);
            toast.error("Something went wrong! Please try again", TOAST_CONFIG);
            setLoading(false);
        }
        finally {
            setLoading(true);
        }
    }

    return (
        <>
            <Box className="AiIContainer">
                <Typography className="pageHeaderText">
                {t("adminWSAiIntegration.aIIntegration")}
                </Typography>
                {loading && 
                (<Box className="airadioSwitchBox">
                    <AntSwitch
                        checked={aiStatus}
                        inputProps={{ "aria-label": "ant design" }}
                        onChange={(e) => handleAiStatusToggle(e)}
                    />
                    <Typography className="switchText">
                        {t("adminWSAiIntegration.enableAiTxt")}
                    </Typography>
                </Box>
                )}

                <Typography className="aiINtext">
                {t("adminWSAiIntegration.aiIntegrationPTxt")}
                </Typography>
                <Typography className="aiINtext">
                {t("adminWSAiIntegration.aiIntegrationPTxt2")}
                </Typography>
                <Box className="bulletBox">
                    <ul>
                        <li>
                            <Typography className="aiINtext">
                                {t("adminWSAiIntegration.aiTeraChatTxt")}
                            </Typography>
                        </li>
                        <li>
                            <Typography className="aiINtext">
                                {t("adminWSAiIntegration.aiTeraChatTxt2")}
                            </Typography>
                        </li>
                        <li>
                            <Typography className="aiINtext">
                                {t("adminWSAiIntegration.aiTeraChatTxt3")}
                            </Typography>
                        </li>
                        <li>
                            <Typography className="aiINtext">
                                {t("adminWSAiIntegration.aiTeraChatTxt4")}
                            </Typography>
                        </li>
                        <li>
                            <Typography className="aiINtext">
                                {t("adminWSAiIntegration.aiTeraChatTxt5")}
                            </Typography>
                        </li>
                    </ul>
                </Box>
            </Box>
        </>
    );
}