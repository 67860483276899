import { Box } from "@mui/material";
import React from "react";
import AdminLayout from "../partials/AdminLayout";
import FileConfiguration from "../../../Components/Admin/FileConfiguration";

const FileConfigPage = () => {
  return (
    <AdminLayout
      childComponent={
        <Box className="adminMainBox" sx={{ display: "flex" }}>
          <FileConfiguration />
        </Box>
      }
    />
  );
};

export default FileConfigPage;
