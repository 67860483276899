import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//images
import publicationImg2 from "../../../Assets/Images/publicationImg2.png";
import { publicationSummeryData, redtrash, editIconBlack } from "../../../Assets/Data";
import arrow from "../../../Assets/Images/arrow.png";
//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
export default function AddResearchPublication3({
    addResearchPublication3,
    setAddResearchPublication3,
}) {
  const Navigate = useNavigate();
  const [newArrayData, setNewArrayData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
        setAddResearchPublication3(false);
    }
  };

  useEffect(() => {
    printItemsByState(publicationSummeryData, currentPage);
  }, [currentPage]);

  function printItemsByState(array, page) {
    const startIndex = page * 2;
    const endIndex = (page + 1) * 2;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  useEffect(() => {
    if (addResearchPublication3) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [addResearchPublication3]);

  const upArrow = (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path d="M10.9987 17.4154V4.58203M10.9987 4.58203L4.58203 10.9987M10.9987 4.58203L17.4154 10.9987" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )

  const upDown = (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path d="M10.9987 4.58203V17.4154M10.9987 17.4154L17.4154 10.9987M10.9987 17.4154L4.58203 10.9987" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )

  return (
    <>

      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: addResearchPublication3 ? "flex" : "none" }}
        className="companyPop addActivitiesPop"
      >
        <Box
          sx={{ display: addResearchPublication3 ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Box>
            <Typography className="taskPopMainLabel">
            Create New Publication
            </Typography>

            <Typography mb={2} className="basicDetailSteps">Publication Structure (Step 3 of 3)</Typography>
            {/* <Typography>Choose a template to format your publication. You can always edit the structure of the template selected accordingly. </Typography> */}
            <Box className="publicationViewMainBox">

              <Box className="puwbBackgroundCover">
                <img src={publicationImg2} />
              </Box>
              <Typography className='publicationHeader'>PAPER TITLE LOREM IPSUM DOLOR SIT AMET</Typography>
              <Typography className='pubWmSubHeader'>{t("PublicationsView.headerSuvText")}</Typography>
              {newArrayData?.map((el, i) => (
                <Box>
                  <Box key={i} className="pragNav">
                    <Box>
                      <Typography className='pagNavHeader'>{el.title}</Typography>
                      {editIconBlack}
                    </Box>
                    <Box>
                      {upArrow}
                      {upDown}
                      {redtrash}
                    </Box>
                  </Box>

                  <Box className="pragraphSection multiP">
                    {el.summerys?.map((s, i) => (
                      <Typography key={i}>{s}</Typography>
                    ))}
                  </Box>
                </Box>
              ))}

              <Box mb={5} className="pagenation">
                <Box onClick={handlePrev} className="tabelBtn">
                  <img src={arrow} />
                  <Typography>{t("commonTxt.previouspage")}</Typography>
                </Box>

                <Box className="pageNumberBox">
                  <Box
                    onClick={() => setCurrentPage(0)}
                    className={
                      currentPage === 0
                        ? "pageNumber pageNumberActive"
                        : "pageNumber"
                    }
                  >
                    <p>1</p>
                  </Box>
                  <Box
                    onClick={() => setCurrentPage(1)}
                    className={
                      currentPage === 1
                        ? "pageNumber pageNumberActive"
                        : "pageNumber"
                    }
                  >
                    <p>2</p>
                  </Box>
                  <Box
                    onClick={() => setCurrentPage(2)}
                    className={
                      currentPage === 2
                        ? "pageNumber pageNumberActive"
                        : "pageNumber"
                    }
                  >
                    <p>3</p>
                  </Box>
                  <Box
                    onClick={() => setCurrentPage(3)}
                    className={
                      currentPage === 3
                        ? "pageNumber pageNumberActive"
                        : "pageNumber"
                    }
                  >
                    <p>4</p>
                  </Box>
                  <Box
                    onClick={() => setCurrentPage(4)}
                    className={
                      currentPage === 4
                        ? "pageNumber pageNumberActive"
                        : "pageNumber"
                    }
                  >
                    <p>5</p>
                  </Box>
                </Box>

                <Box onClick={handleNext} className="tabelBtn">
                  <Typography>{t("commonTxt.nextPage")}</Typography>
                  <img style={{ rotate: "180deg" }} src={arrow} />
                </Box>
              </Box>
            </Box>
            <Box className="workPupBtnBox">
              <AppBtnOutLine
                buttonText={t("commonTxt.cancelBtn")}
                onClick={() => setAddResearchPublication3(true)}
              />
              <AppBtnOutLine
                buttonText={t("onTheDro.SaveDraft")}
                onClick={() => setAddResearchPublication3(true)}
              />
              <AppBtn
                buttonText={t("commonTxt.continueBtn")}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
