import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//images
import arrow from "../../../Assets/Images/arrow.png";
import OptionIcon from "../../../Assets/Images/optionIcon.png";
import users from "../../../Assets/Images/usersB.png";
import w1 from "../../../Assets/Images/w1.png";
import w2 from "../../../Assets/Images/w2.png";
import w3 from "../../../Assets/Images/w3.png";
import w4 from "../../../Assets/Images/w4.png";
import w5 from "../../../Assets/Images/w5.png";
import w6 from "../../../Assets/Images/w6.png";
import { sideHaumIcon } from "../../../Assets/Data";
import iIcon from "../../../Assets/Images/IIcon.png";
import {
  ActiveCSettingIcon,
  activeCEyeIcon,
  activeCImageIcon,
  activeCCopyIcon,
  activeCPestIcon,
  activeCFormIcon,
  activeCTrashIcon,
  arrowIcon,
} from "../../../Assets/Data";

//components
import { SearchBar } from "../../../Components/AppTools/AppTool";
import SideMenu from "../../../Components/Collaborator/SideMenu";
import {
  AppBtnOutLine,
  AppAddBtn,
  PurplelBtn,
} from "../../../Components/AppTools/AppButton";
import ActivityEmptyPage from "../../../Components/Researchers/ActivityEmptyPage";
import AddActivitiesPop from "../../../Components/Researchers/AddActivitiesPop";
import ActivityGuidancePop from "../../../Components/Researchers/ActivityGuidancePop";

export default function Activities({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
}) {
  
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();


  const Navigate = useNavigate();
  const [activitySequence, setActivitySequence] = useState(false);
  const [addActivitiesPop, setAddActivitiesPop] = useState(false);
  const [activityGuidancePop, setActivityGuidancePop] = useState(false);
  const [radio, setRadio] = useState(0);
  const [activeCardOptionPop, setActiveCardOptionPop] = useState();
  const [currentPage, setCurrentPage] = useState(0);

  var data = false;

  const ActivitiesCard = (title, date, img, index) => {
    return (
      <Box mb={2} className="userHMBoxItem userHConsart">
        <Box className="sequenceActivityCard">
          <Box style={{ cursor: "pointer" }} className="conImgBox">
            <img
              src={img}
              onClick={() => Navigate("/activities/task")}
            />
          </Box>
          <Box className="userHCTextBox">
            <Typography
              sx={{
                cursor: "pointer",
                fontSize: "18px",
                color: "#101828",
                fontWeight: "600",
              }}
              onClick={() => Navigate("/activities/task")}
            >
              {title}
            </Typography>
            <img
              style={{ cursor: "pointer" }}
              src={OptionIcon}
              onClick={() => {
                if (activeCardOptionPop === index) {
                  setActiveCardOptionPop(null);
                  return;
                }
                setActiveCardOptionPop(index);
              }}
            />
          </Box>
          <Box className="userHCTextBox">
            <Box>
              <Typography sx={{ fontSize: "16px", color: "#98A2B3" }}>
                {date}
              </Typography>
              <Box
                sx={{ background: "#12B76A" }}
                className="ActiveStatus"
              ></Box>
            </Box>
            <Box>
              <img src={users} />
              <Typography ml={1} sx={{ fontSize: "20px", color: "#98A2B3" }}>
                11
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          mb={2}
          ml={2}
          sx={{
            display: activitySequence ? "flex" : "none",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <input
            style={{ marginRight: "10px" }}
            className="custom-checkbox"
            type="checkBox"
          />
          <Typography>{t("observer.Sequenced")}</Typography>
        </Box>

        {/* option pop */}
        <Box
          sx={{ display: activeCardOptionPop === index ? "flex" : "none" }}
          className="activeCardOptionPop"
        >
          <Box className="acItem" onClick={() => setActiveCardOptionPop(null)}>
            {ActiveCSettingIcon}
            <Typography className="acdl">{t("observer.ActivitySettings")}</Typography>
          </Box>
          <Box className="acItem" onClick={() => setActiveCardOptionPop(null)}>
            {activeCEyeIcon}
            <Typography className="acdl">{t("observer.Preview")}</Typography>
          </Box>
          <Box className="acItem" onClick={() => setActiveCardOptionPop(null)}>
            {activeCImageIcon}
            <Typography className="acdl">{t("observer.ChangeCImage")}</Typography>
          </Box>
          <Box className="acItem" onClick={() => setActiveCardOptionPop(null)}>
            {activeCEyeIcon}
            <Typography className="acdl">{t("observer.Duplicate")}</Typography>
          </Box>
          <Box className="acItem" onClick={() => setActiveCardOptionPop(null)}>
            {activeCCopyIcon}
            <Typography className="acdl">{t("observer.Reports")}</Typography>
            {arrowIcon}
          </Box>
          <Box
            className="acItem transcript"
            onClick={() => setActiveCardOptionPop(null)}
          >
            {activeCPestIcon}
            <Typography className="acdl">{t("observer.Transcripts")}</Typography>
            {arrowIcon}
            <Box className="acSubItem">
              <Box>
                <Typography>{t("observer.ActivityTranscript")}</Typography>
              </Box>
              <Box>
                <Typography>{t("observer.ChartSData")}</Typography>
              </Box>
              <Box>
                <Typography>{t("observer.QuantitativeData")}</Typography>
              </Box>
              <Box>
                <Typography>{t("observer.ExportVideo")}</Typography>
              </Box>
            </Box>
          </Box>
          <Box className="acItem" onClick={() => setActiveCardOptionPop(null)}>
            {activeCFormIcon}
            <Typography className="acdl">{t("commonTxt.archiveBtn")}</Typography>
          </Box>
          <Box className="acItem" onClick={() => setActiveCardOptionPop(null)}>
            {activeCTrashIcon}
            <Typography className="acdl">{t("commonTxt.delete")}</Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      <Box className="CollaboratorContainer ">
        {/* side menu */}
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />

        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
            <Box className="AppNavBtnBox">
              <AppBtnOutLine
                onClick={() => setActivitySequence(!activitySequence)}
                buttonText={
                  activitySequence ? t("commonTxt.deactivateSequenceBtn") : t("commonTxt.activitySequenceBtn")
                }
              />

              <AppAddBtn
                buttonText={t("commonTxt.addActivityBtn")}
                onClick={() => setAddActivitiesPop(true)}
              />
            </Box>
          </Box>

          {/* Page container */}
          <Box className="collaboratorsMainBox pageContainer">
          {data ? (
              <ActivityEmptyPage />
            ) : (
            <Box className="ActivitieContainer">
              <AddActivitiesPop
                addActivitiesPop={addActivitiesPop}
                setAddActivitiesPop={setAddActivitiesPop}
                setActivityGuidancePop={setActivityGuidancePop}
              />
              <ActivityGuidancePop
                activityGuidancePop={activityGuidancePop}
                setActivityGuidancePop={setActivityGuidancePop}
                setAddActivitiesPop={setAddActivitiesPop}
              />

              <Box className="ActivitieContainerInner">
                <Box
                  className="activitySearch"
                  sx={{ display: activitySequence ? "none" : "flex" }}
                >
                  <Box></Box>
                  <Box>
                    <SearchBar placeholder={t("activityPage.searchActivityPlaceholder")} />
                  </Box>
                </Box>

                <Box
                  sx={{ display: activitySequence ? "block" : "none" }}
                  mb={4}
                  className="workSpaceListBox"
                >
                  <Typography className="atvHeaderText">
                  {t("activityPage.activitySequenceHead")}
                  </Typography>
                  <Box mt={2} className="iBox">
                    <img src={iIcon} />
                    <Typography>
                    {t("activityPage.activitySequenceNotify1")}
                    </Typography>
                    <Typography my={1.5}>
                    {t("activityPage.activitySequenceNotify2")}
                    </Typography>
                    <Typography>
                    {t("activityPage.activitySequenceNotify3")}
                    </Typography>
                  </Box>

                  <Box className="radioBtnBox">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Box className="redioItem" onClick={() => setRadio(0)}>
                        <Box
                          className={
                            radio === 0
                              ? "customRedio customRedioActive"
                              : "customRedio"
                          }
                        >
                          <Box></Box>
                        </Box>
                        <Typography>{t("activityPage.activitySequenceRadio1")}</Typography>
                      </Box>

                      <Box className="redioItem" onClick={() => setRadio(1)}>
                        <Box
                          className={
                            radio === 1
                              ? "customRedio customRedioActive"
                              : "customRedio"
                          }
                        >
                          <Box></Box>
                        </Box>
                        <Typography>{t("activityPage.activitySequenceRadio2")}</Typography>
                      </Box>
                    </Box>
                    <PurplelBtn buttonText={t("commonTxt.doneBtn")} />
                  </Box>
                </Box>

                <Box className="activitiesCardBox">
                  {ActivitiesCard("Welcome", "Aug 02 - Aug 03", w1, 0)}
                  {ActivitiesCard(
                    "Your Great Outdoors",
                    "Aug 02 - Aug 03",
                    w2,
                    1
                  )}
                  {ActivitiesCard(
                    "Outdoor Activity Journal",
                    "Aug 02 - Aug 03",
                    w3,
                    2
                  )}
                  {ActivitiesCard(
                    "The Purchase Pathway",
                    "Aug 02 - Aug 03",
                    w4,
                    3
                  )}
                  {ActivitiesCard(
                    "Video & Product Reviews",
                    "Aug 02 - Aug 03",
                    w5,
                    4
                  )}
                  {ActivitiesCard(
                    "Optional Feedback Activity",
                    "Aug 02 - Aug 03",
                    w6,
                    5
                  )}
                </Box>

                <Box className="pagenation">
                  <Box onClick={handlePrev} className="tabelBtn">
                    <img src={arrow} />
                    <Typography>{t("commonTxt.previouspage")}</Typography>
                  </Box>

                  <Box className="pageNumberBox">
                    <Box
                      onClick={() => setCurrentPage(0)}
                      className={
                        currentPage === 0
                          ? "pageNumber pageNumberActive"
                          : "pageNumber"
                      }
                    >
                      <p>1</p>
                    </Box>
                    <Box
                      onClick={() => setCurrentPage(1)}
                      className={
                        currentPage === 1
                          ? "pageNumber pageNumberActive"
                          : "pageNumber"
                      }
                    >
                      <p>2</p>
                    </Box>
                    <Box
                      onClick={() => setCurrentPage(2)}
                      className={
                        currentPage === 2
                          ? "pageNumber pageNumberActive"
                          : "pageNumber"
                      }
                    >
                      <p>3</p>
                    </Box>
                    <Box
                      onClick={() => setCurrentPage(3)}
                      className={
                        currentPage === 3
                          ? "pageNumber pageNumberActive"
                          : "pageNumber"
                      }
                    >
                      <p>4</p>
                    </Box>
                    <Box
                      onClick={() => setCurrentPage(4)}
                      className={
                        currentPage === 4
                          ? "pageNumber pageNumberActive"
                          : "pageNumber"
                      }
                    >
                      <p>5</p>
                    </Box>
                  </Box>

                  <Box onClick={handleNext} className="tabelBtn">
                    <Typography>{t("commonTxt.nextPage")}</Typography>
                    <img style={{ rotate: "180deg" }} src={arrow} />
                  </Box>
                </Box>
              </Box>
            </Box>)}
          </Box>
        </Box>
      </Box>
    </>
  );
}
