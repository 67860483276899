import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

//images
import clogsvg from "../Assets/Images/New folder/Icon TeraPage Transparent mix dark 2.svg";
import qrImg from "../Assets/Images/Qrimg.png"

//components
import { AppBtn, AppBtnOutLine } from "../Components/AppTools/AppButton";

export default function Enforce2Fa() {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
 

  return (
    <>
      <Box className="ResearchersContainer">
        <Box className="adminMainSection">
        <Box className="adminNavBar">
          <Box  className="terapageLogBox">
              <img style={{ width: "176.442px", height: "20px" }} src={clogsvg} />
          </Box>
        </Box>
          {/* Page container */}
          <Box className="adminMainBox screenMainBox" sx={{ display: "flex" }}>
            <Box className="researchersMainBox pageContainer">
                <Box className="authOuterBox">
                    <Box className="authContainer">
                    <Typography className='pageHeaderText'>Two-Factor Authentication</Typography>
                    <Typography className='authContainerSubText'>This feature adds an extra layer of security to your account by asking for a One Time Password (OTP) at the time of login. This required OTP will be generated on either your phone or computer by an external application. We recommend using Google Authenticator, Authy or 1Password for the same.</Typography>
                    </Box>

                    <Box className="authContainer">
                    <Typography className='pageHeaderText'>Step 1</Typography>
                    <Typography className='authContainerSubText'>Open a Two - Factor authentication app on your phone or computer. We recommend using <span>Authy, Google Authenticator</span> or <span>Password.</span></Typography>
                    <Typography className='authContainerSubText'>Scan the barcode below with the app or enter the code manually (without spaces).</Typography>

                    <Box className="QrBox">
                        <Box>
                        <img src={qrImg} />
                        </Box>
                    </Box>
                    <Typography className='pageHeaderText'>Step 2</Typography>
                    <Typography className='authContainerSubText'>Enter the 8 digit code generated by the app in the box below</Typography>
                    {/* error */}
                    {/* success */}
                    <Box className="qrInputbox success">
                        <input  name='token'/>
                        <Typography>Incorrect code! Try again</Typography>
                    </Box>

                    <Box className="workPupBtnBox">
                        <AppBtnOutLine buttonText="Cancel" />
                        <AppBtn buttonText="Next" />
                    </Box>
                    </Box>
                </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
