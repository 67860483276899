import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  CardHeader,
  Avatar,
  CardContent,
} from "@mui/material";
import "./style.css";
import AvatarOne from "../../../Assets/Images/inb1.png";

import { participantPreview } from "../../../Assets/Data"

//components
import { AppBtnOutLine } from "../../AppTools/AppButton";
import { calculateTimeDistance, formatDate, viewImage } from "../../../lib/helpers";
import { API_BASE_URL } from "../../../env";
import axios from "../../../lib/axios";
import { useAppSelector } from "../../../lib/store/store";
import DummyUser from "../../../Assets/Images/user-dummy.png";
import { toast } from "react-toastify";

export default function PreviewFilterPop({ previewFilterPop, setPreviewFilterPop, customFieldFilter }) {

  const workspaceState = useAppSelector((state) => state.workspace);
  const [loading, setLoading] = useState(false);
  const [allParticipantsList, setAllParticipantsList] = useState([]);

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setPreviewFilterPop(false);
    }
  };

  const getParticipants = async () => {
    setLoading(true);
    let url = API_BASE_URL + `/super-admin/admin/participants?is_advanced=true&workspace=${workspaceState?.selectedWorkspace?._id}&separate_workspace_row=true`;
    return await axios
      .get(url, {
        params: { ...customFieldFilter },
      }).then((res) => {
        setLoading(false);
        if (res.status) {
          if (res.data.data.length > 0)
            setAllParticipantsList(res.data.data);
          else{
            toast.error("No matching data found based on the given criteria.");
            setPreviewFilterPop(false);
          }
        }
      });
  };

  useEffect(() => {
    if (previewFilterPop) {
      getParticipants();
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [previewFilterPop]);


  return (

    <Box
      onClick={handelPopClose}
      sx={{ display: previewFilterPop ? "flex" : "none" }}
      className="workspacePop previewFilter"
      id="popBackdrop">
      <Box
        sx={{ display: previewFilterPop ? "flex" : "none" }}
        className="workspaceContaint previewFilterPop">
        <Typography className='previewFilterHeaderText'>Preview Filter</Typography>
        {/* <Typography className='previewFilterSubHeadText'>{projectList?.length} matches</Typography> */}
        <Box className="ParticipantsPanel">
          {allParticipantsList &&
            allParticipantsList?.map((el, index) => (
              <Box className="ParticipantBox" key={index}>
                {console.log(el)}
                <CardHeader
                  avatar={
                    <Avatar
                      alt=""
                      src={el?.profile_pic == "undefined" ||
                        el?.profile_pic == null ? (
                        <img src={DummyUser} />
                      ) : (
                        <img src={viewImage(el?.profile_pic)} />
                      )}
                      sx={{ width: 44, height: 44 }}
                    />
                  }
                  title={el.name}
                />
                <CardContent>
                  <Typography>{el?.username.length > 10 ? `${el?.username.substring(0, 15)}...` : `${el?.username}`}</Typography>
                  <Typography>{calculateTimeDistance(el?.created_at)}</Typography>
                </CardContent>
              </Box>
            ))}

        </Box>
        <Box className="workPupBtnBox">
          <AppBtnOutLine
            buttonText="Cancel"
            onClick={() => setPreviewFilterPop(false)}
          />
        </Box>
      </Box>
    </Box>

  );
}
