import React, { useState } from 'react';
import { Box, Typography } from "@mui/material";
import "./style.css";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import backArrow from "../../../Assets/Images/noun-back.png";
import { useNavigate } from "react-router-dom";


//images


//component
import SideMenu from "../../../Components/Participants/SideMenu";
import { sideHaumIcon, rankGroupCardData } from "../../../Assets/Data";
import { AppBtn, AppBtnOutLine, PurplelBtn } from '../../../Components/AppTools/AppButton';
import { IBox } from "../../../Components/AppTools/AppTool";


import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export default function RankItOrSortItTask({ slideMenu, setSlideMenu, workspaceDrop }) {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const lineBar = 80;
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#4F33D1",
    },
  }));


  return (
    <>
      <Box className="ResearchersContainer FillItOutTaskPage ">
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon">
              {sideHaumIcon}
            </Box>
            <Box className="ontheDotNav">
              <PurplelBtn buttonText={t("commonTxt.previouspage")} />
              <AppBtn buttonText={t("commonTxt.nextPage")} />
            </Box>
          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer onthedot">
            <Box className="workspaceListBox">
              <Box mb={3} width="74px" onClick={() => Navigate("/participants/activities")} className="openBtn">
                <img src={backArrow} />
                <Typography className="neonBackText">Back</Typography>
              </Box>
              <Typography className='activeProgText'>{t("commonTxt.activityProgress")}</Typography>
              <Box className="lineBar">
                <Typography>{lineBar}%</Typography>
                <BorderLinearProgress variant="determinate" value={lineBar} />
              </Box>
              <Typography className="lineBarSubText">{t("onTheDro.TaskComp")}</Typography>

              <Box className="otdmainLabelBox">
                <Typography className='ActInSectionMainHeader'>{t("RankItorSortIt.rankHeader")}</Typography>
                <Box className="skipBtnBox">
                  <Typography>{t("onTheDro.SaveDraft")}</Typography>
                  <AppBtnOutLine buttonText="Skip Task" />
                </Box>
              </Box>
              <IBox text={t("RankItorSortIt.Ibox")} />

              <Typography className='imgUploadHeader'>{t("RankItorSortIt.dragDropText")}<span>*</span> </Typography>

              <Box className="DropGroupBox">
                {
                  rankGroupCardData?.map((el, i) => (
                    <Box className="dropImgBox" key={i}>
                      <Box>
                        <img src={el.img} />
                      </Box>
                      <Typography>{el.title}</Typography>
                    </Box>
                  ))
                }
              </Box>


              <Typography className='imgUploadHeader'>{t("RankItorSortIt.AvailableGroups")} :- </Typography>

              <Box className="AGroupBox">

                <Box className="AgropuInnerCard">
                  <Box className="AgroupItemBox"><Box className="numRank">1</Box></Box>
                  <Box className="AgroupItemBox"><Box className="numRank">2</Box></Box>
                  <Box className="AgroupItemBox"><Box className="numRank">3</Box></Box>
                  <Box className="AgroupItemBox"><Box className="numRank">4</Box></Box>
                  <span>{t("RankItorSortIt.AbsolutelyNeeded")}</span>
                </Box>

                <Box className="AgropuInnerCard">
                  <Box className="AgroupItemBox"><Box className="numRank">1</Box></Box>
                  <Box className="AgroupItemBox"><Box className="numRank">2</Box></Box>
                  <Box className="AgroupItemBox"><Box className="numRank">3</Box></Box>
                  <Box className="AgroupItemBox"><Box className="numRank">4</Box></Box>
                  <span>{t("RankItorSortIt.NiceHave")}</span>

                </Box>

                <Box className="AgropuInnerCard">
                  <Box className="AgroupItemBox"><Box className="numRank">1</Box></Box>
                  <Box className="AgroupItemBox"><Box className="numRank">2</Box></Box>
                  <Box className="AgroupItemBox"><Box className="numRank">3</Box></Box>
                  <Box className="AgroupItemBox"><Box className="numRank">4</Box></Box>
                  <span>{t("RankItorSortIt.Forgettable")}</span>

                </Box>

              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

    </>
  )
}
