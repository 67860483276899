import React, { useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useTranslation } from "react-i18next";

//images
import { videoTham } from "../../../Assets/Data";

export default function Videos() {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const handlePlay = (event) => {
    const video = event.target.previousSibling; // Get the video element preceding the button
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  const handleVideoStateChange = () => {
    const video = videoRef.current;
    setIsPlaying(!video.paused);
  };

  return (
    <>
      <Box sx={{ width: "100%", height: "700px", overflowY: "scroll" }}>
        <ImageList variant="masonry" cols={3} gap={8}>
          {videoTham.map((item, index) => (
            <ImageListItem key={index}>
              <video className="video activeVideoItem" ref={videoRef}>
                <source src={item.img} type="video/mp4" />
                {t("recapVideo.videoErrorMessage")}
              </video>

              <button className="play-button" onClick={handlePlay}></button>
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </>
  );
}
