import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import { Box, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import { SearchBar, LatestCreated } from "../../AppTools/AppTool";
import arrow from "../../../Assets/Images/arrow.png";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import VideoIcon from "../../../Assets/Images/video.png";
import exportIcon from "../../../Assets/Images/ExpoGroup.png";

import { Workspace } from "../../../Assets/Data";
import BulkImport from "../BulkImport";

export default function PlatformLog({
  setCompanyPop,
  companyPop,
  setBulkImportData,
  bulkImportData,
}) {
  const [companyStatusDrop, setCompanyStatusDrop] = useState(false);
  const [compantStatusDropVal, setCompantStatusDropVal] = useState("");

  const [workspaceStatusdrop, setWorkspaceStatusdrop] = useState(false);
  const [workspaceStatusDropVal, setworkspaceStatusDropVal] = useState("");

  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Latest Created");

  const [optionIndex, setOptionIndex] = useState();

  const [dataRangeDrop, setDateRangeDrop] = useState(false);
  const [dataRangeDropVal, setDateRangeDropVal] = useState("");

  const [projectStatusDrop, setProjectStatusDrop] = useState(false);

  const [projectStatusDropVal, setProjectStatusDropVal] = useState("");

  const [userTypeDrop, setUserTypeDrop] = useState(false);
  const [userTypeDropVal, setUserTypeDropVal] = useState("");

  const latestDropRef = useRef(null);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const handleOptionIndex = (i) => {
    setOptionIndex(i);
  };

  //pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [newArrayData, setNewArrayData] = useState([]);
  // const [bulkImportData, setBulkImportData] = useState(false);

  const handleImport = () => {
    setBulkImportData(true);
  };

  useEffect(() => {
    printItemsByState(Workspace, currentPage);

    const handleClickOutside = (event) => {
      console.log("Clicked element:", event.target);

      if (latestDropRef) {
        setLatestDrop(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [currentPage, latestCDrop]);

  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  return (
    <>
      <Box className="workspaceItemBox CompaniesBox">
        <BulkImport
          bulkImportData={bulkImportData}
          setBulkImportData={setBulkImportData}
        />
        <Box className="AllCSubBox allUFilters">
          <Box className="headerText platformHeader">
            <Typography className="fHeaderText">
              {t("commonTxt.filterHead")}
            </Typography>
            <Box className="fHeaderTextLink">
              <img src={VideoIcon} />
              <Typography className="fHeaderText fHeaderTextLink">
                {t("platformLogPage.recentUsedMint")}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
            <Box sx={{ width: "100%" }} className="filterBox">
              <Box className="companiesTopBox">
                <Box className="companyInputBoxWlabel">
                  <Typography className="inputLabel">
                    {t("commonTxt.workspaces")}
                  </Typography>

                  <Box
                    className="inputAppDrop"
                    onClick={() => setCompanyStatusDrop(!companyStatusDrop)}
                  >
                    <Typography>
                      {compantStatusDropVal || t("platformLogPage.filterByWS")}
                    </Typography>
                    <img
                      style={{ rotate: companyStatusDrop ? "180deg" : "0deg" }}
                      src={DownArrowIcon}
                    />
                    {/* pop */}
                    <Box
                      sx={{ height: companyStatusDrop ? "auto" : "0px" }}
                      className="statusPop"
                    >
                      <Box
                        onClick={() => setCompantStatusDropVal("Item-A")}
                        className="statusPopItem"
                      >
                        <Typography>
                          {t("platformLogPage.filterByWSOption1")}
                        </Typography>
                      </Box>
                      <Box
                        onClick={() => setCompantStatusDropVal("Item-B")}
                        className="statusPopItem"
                      >
                        <Typography>Item-B</Typography>
                      </Box>
                      <Box
                        onClick={() => setCompantStatusDropVal("Item-C")}
                        className="statusPopItem"
                      >
                        <Typography>Item-C</Typography>
                      </Box>
                      <Box
                        onClick={() => setCompantStatusDropVal("Item-D")}
                        className="statusPopItem"
                      >
                        <Typography>Item-D</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {/* workspace status */}
                <Box className="companyInputBoxWlabel">
                  <Typography className="inputLabel">
                    {t("commonTxt.project")}
                  </Typography>

                  <Box
                    className="inputAppDrop"
                    onClick={() => setWorkspaceStatusdrop(!workspaceStatusdrop)}
                  >
                    <Typography>
                      {workspaceStatusDropVal ||
                        t("platformLogPage.filterByPro")}
                    </Typography>
                    <img
                      style={{
                        rotate: workspaceStatusdrop ? "180deg" : "0deg",
                      }}
                      src={DownArrowIcon}
                    />
                    {/* pop */}
                    <Box
                      sx={{ height: workspaceStatusdrop ? "auto" : "0px" }}
                      className="statusPop"
                    >
                      <Box
                        onClick={() => setworkspaceStatusDropVal("Item-A")}
                        className="statusPopItem"
                      >
                        <Typography>
                          {t("platformLogPage.filterByProOption1")}
                        </Typography>
                      </Box>
                      <Box
                        onClick={() => setworkspaceStatusDropVal("Item-B")}
                        className="statusPopItem"
                      >
                        <Typography>Item-B</Typography>
                      </Box>
                      <Box
                        onClick={() => setworkspaceStatusDropVal("Item-C")}
                        className="statusPopItem"
                      >
                        <Typography>Item-C</Typography>
                      </Box>
                      <Box
                        onClick={() => setworkspaceStatusDropVal("Item-D")}
                        className="statusPopItem"
                      >
                        <Typography>Item-D</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="companiesTopBox platformTopBox">
                <Box className="companyInputBoxWlabel">
                  <Typography className="inputLabel">
                    {t("platformLogPage.dateRange")}
                  </Typography>

                  <Box
                    className="inputAppDrop"
                    onClick={() => setDateRangeDrop(!dataRangeDrop)}
                  >
                    <Typography>
                      {dataRangeDropVal || t("platformLogPage.selectDateRange")}
                    </Typography>
                    <img
                      style={{ rotate: dataRangeDrop ? "180deg" : "0deg" }}
                      src={DownArrowIcon}
                    />
                    {/* pop */}
                    <Box
                      sx={{ height: dataRangeDrop ? "auto" : "0px" }}
                      className="statusPop"
                    >
                      <Box
                        onClick={() => setDateRangeDropVal("Item-A")}
                        className="statusPopItem"
                      >
                        <Typography>Item-A</Typography>
                        <Typography>Hello</Typography>
                      </Box>
                      <Box
                        onClick={() => setDateRangeDropVal("Item-B")}
                        className="statusPopItem"
                      >
                        <Typography>Item-B</Typography>
                      </Box>
                      <Box
                        onClick={() => setDateRangeDropVal("Item-C")}
                        className="statusPopItem"
                      >
                        <Typography>Item-C</Typography>
                      </Box>
                      <Box
                        onClick={() => setDateRangeDropVal("Item-D")}
                        className="statusPopItem"
                      >
                        <Typography>Item-D</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {/* workspace status */}
                <Box className="companyInputBoxWlabel">
                  <Typography className="inputLabel">
                    {t("commonTxt.status")}
                  </Typography>

                  <Box
                    className="inputAppDrop"
                    onClick={() => setProjectStatusDrop(!projectStatusDrop)}
                  >
                    <Typography>
                      {projectStatusDropVal || t("platformLogPage.filterByPro")}
                    </Typography>
                    <img
                      style={{
                        rotate: projectStatusDrop ? "180deg" : "0deg",
                      }}
                      src={DownArrowIcon}
                    />
                    {/* pop */}
                    <Box
                      sx={{ height: projectStatusDrop ? "auto" : "0px" }}
                      className="statusPop"
                    >
                      <Box
                        onClick={() => setProjectStatusDropVal("Item-A")}
                        className="statusPopItem"
                      >
                        <Typography>Item-A</Typography>
                      </Box>
                      <Box
                        onClick={() => setProjectStatusDropVal("Item-B")}
                        className="statusPopItem"
                      >
                        <Typography>Item-B</Typography>
                      </Box>
                      <Box
                        onClick={() => setProjectStatusDropVal("Item-C")}
                        className="statusPopItem"
                      >
                        <Typography>Item-C</Typography>
                      </Box>
                      <Box
                        onClick={() => setProjectStatusDropVal("Item-D")}
                        className="statusPopItem"
                      >
                        <Typography>Item-D</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="companyInputBoxWlabel">
                  <Typography className="inputLabel">
                    {t("platformLogPage.userType")}
                  </Typography>

                  <Box
                    className="inputAppDrop"
                    onClick={() => setUserTypeDrop(!userTypeDrop)}
                  >
                    <Typography>
                      {userTypeDropVal || t("platformLogPage.filterByPro")}
                    </Typography>
                    <img
                      style={{
                        rotate: userTypeDrop ? "180deg" : "0deg",
                      }}
                      src={DownArrowIcon}
                    />
                    {/* pop */}
                    <Box
                      sx={{ height: userTypeDrop ? "auto" : "0px" }}
                      className="statusPop"
                    >
                      <Box
                        onClick={() => setUserTypeDropVal("Item-A")}
                        className="statusPopItem"
                      >
                        <Typography>Item-A</Typography>
                      </Box>
                      <Box
                        onClick={() => setUserTypeDropVal("Item-B")}
                        className="statusPopItem"
                      >
                        <Typography>Item-B</Typography>
                      </Box>
                      <Box
                        onClick={() => setUserTypeDropVal("Item-C")}
                        className="statusPopItem"
                      >
                        <Typography>Item-C</Typography>
                      </Box>
                      <Box
                        onClick={() => setUserTypeDropVal("Item-D")}
                        className="statusPopItem"
                      >
                        <Typography>Item-D</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* Bottom Filter */}
              <Box
                sx={{ display: "flex", justifyContent: "flex-end" }}
                className="filterBottomBox"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography className="LinkText">
                    {t("commonTxt.clear")}
                  </Typography>
                  <Typography mx={3} className="LinkText">
                    {t("commonTxt.apply")}
                  </Typography>
                  <Typography>{t("commonTxt.21Matches")}</Typography>
                </Box>
              </Box>
              {/* Finishes here */}
            </Box>
          </Box>
        </Box>
        <Box className="AllProjectBox">
          <Box className="companyListNav ">
            <Box className=" companyListNavItem companyListheader">
              <Typography className="pageHeaderText">
                {t("platformLogPage.platformLog")}
              </Typography>
            </Box>
            <Box minWidth={"77px"} className="allIconBox">
              <img src={exportIcon} />
              <Typography ml={1}>{t("commonTxt.exportLink")}</Typography>
            </Box>

            <Box
              sx={{ minWidth: "215px", height: "40px" }}
              className="companyListNavItem companyLatest"
            >
              <LatestCreated
                ref={latestDropRef}
                setLatestDrop={setLatestDrop}
                latestCDrop={latestCDrop}
                setLatestDropVal={setLatestDropVal}
                latestDropVal={latestDropVal}
              />
            </Box>
          </Box>
          <Box className="companyListBox workListBox">
            <Box className="haderRow platform" minWidth={"1188px"}>
              <Box
                className="Tabelsel navFsel TabelselItemStart"
                minWidth={"12%"}
              >
                <Typography ml={3}>{t("commonTxt.status")}</Typography>
              </Box>
              <Box className="Tabelsel TabelStatesel" minWidth={"19%"}>
                <Typography>{t("commonTxt.workspace")}</Typography>
              </Box>

              <Box minWidth={"15%"} className="Tabelsel TabelStatesel">
                <Typography>{t("commonTxt.projects")}</Typography>
              </Box>
              <Box minWidth={"15%"} className="Tabelsel Tabelcentersel">
                <Typography>{t("commonTxt.summary")}</Typography>
              </Box>
              <Box minWidth={"15%"} className="Tabelsel TabelStatesel">
                <Typography>{t("commonTxt.uName")}</Typography>
              </Box>
              <Box minWidth={"12%"} className="Tabelsel TabelStatesel">
                <Typography>{t("commonTxt.uType")}</Typography>
              </Box>
              <Box minWidth={"12%"} className="Tabelsel TabelStatesel">
                <Typography>{t("platformLogPage.date")}</Typography>
              </Box>
            </Box>

            {newArrayData &&
              newArrayData?.map((el, index) => (
                <Box key={index} className="TabelRow" minWidth={"1188px"}>
                  <Box className="Tabelsel navFsel" minWidth={"12%"}>
                    <button className="status">{el.logStatus}</button>
                  </Box>
                  <Box minWidth={"19%"} className="Tabelsel TabelStatesel">
                    <Typography sx={{ width: "95%" }}>{el.title}</Typography>
                  </Box>
                  <Box
                    minWidth={"15%"}
                    className="Tabelsel projectWork TabelStatesel"
                  >
                    <Typography>{el.projectTitle}</Typography>
                  </Box>
                  <Box
                    minWidth={"15%"}
                    className="Tabelsel summary TabelStatesel"
                  >
                    <Typography>{t("commonTxt.bulkImport")}</Typography>
                    <Typography
                      sx={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => handleImport()}
                    >
                      {t("commonTxt.summary")}
                    </Typography>
                  </Box>
                  <Box minWidth={"15%"} className="Tabelsel TabelStatesel">
                    <Typography>Richard Han</Typography>
                  </Box>
                  <Box minWidth={"12%"} className="Tabelsel TabelStatesel">
                    <Typography>Researcher</Typography>
                  </Box>

                  <Box minWidth={"12%"} className="Tabelsel TabelStatesel">
                    <Typography>{el.createdOn}</Typography>
                  </Box>
                </Box>
              ))}
          </Box>
          <Box className="pagenation">
            <Box onClick={handlePrev} className="tabelBtn">
              <img src={arrow} />
              <Typography>{t("commonTxt.previouspage")}</Typography>
            </Box>

            <Box className="pageNumberBox">
              <Box
                onClick={() => setCurrentPage(0)}
                className={
                  currentPage === 0
                    ? "pageNumber pageNumberActive"
                    : "pageNumber"
                }
              >
                <p>1</p>
              </Box>
              <Box
                onClick={() => setCurrentPage(1)}
                className={
                  currentPage === 1
                    ? "pageNumber pageNumberActive"
                    : "pageNumber"
                }
              >
                <p>2</p>
              </Box>
              <Box
                onClick={() => setCurrentPage(2)}
                className={
                  currentPage === 2
                    ? "pageNumber pageNumberActive"
                    : "pageNumber"
                }
              >
                <p>3</p>
              </Box>
              <Box
                onClick={() => setCurrentPage(3)}
                className={
                  currentPage === 3
                    ? "pageNumber pageNumberActive"
                    : "pageNumber"
                }
              >
                <p>4</p>
              </Box>
              <Box
                onClick={() => setCurrentPage(4)}
                className={
                  currentPage === 4
                    ? "pageNumber pageNumberActive"
                    : "pageNumber"
                }
              >
                <p>5</p>
              </Box>
            </Box>

            <Box onClick={handleNext} className="tabelBtn">
              <Typography>{t("commonTxt.nextPage")}</Typography>
              <img style={{ rotate: "180deg" }} src={arrow} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
