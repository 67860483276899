import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Grid, FormLabel, TextField, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./style.css"

//images
import clogsvg from "../Assets/Images/New folder/Icon TeraPage Transparent mix dark 2.svg";
import WhiteDownArrow from "../Assets/Images/Whitechevron-down.png";
import VisibleIcon from "../Assets/Images/VisibleIcon.png";
import CropImage from "../Assets/Images/crop-image.png";
import DummyUser from "../Assets/Images/user-dummy.png";

//components
import { AppBtn, AppBtnOutLine } from "../Components/AppTools/AppButton";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { genderList } from "../Assets/Data";
import { useLocation, useNavigate } from "react-router";
import { useAppSelector } from "../lib/store/store";
import { userProfile } from "../Services/auth";
import { getCities, getCountries, getStates } from "../Services/master";
import { dataURLToFile, viewImage } from "../lib/helpers";
import { updateLoggedInUserProfile, uploadUserDirectoryImage } from "../Services/adminActions";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../env";
import { canvasPreview, useDebounceEffect, WebcamCapture } from "../Components/AppTools/AppTool";
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from "react-image-crop";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

export default function Registration() {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const [emailval, setEmailval] = useState();
  const [loading, setLoading] = useState();
  const [directMessageNavDrop, setDirectMessageNavDrop] = useState(false);
  const [directMessageNavDropVal, setDirectMessageNavDropVal] = useState("Upload Image");
  const [gender, setGender] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [pwdVisible, setPwdVisible] = useState(false);
  const [pwdVisible2, setPwdVisible2] = useState(false);
  const authState = useAppSelector((state) => state.auth);
  const Navigate = useNavigate();
  const [userProfileData, setUserProfileData] = useState(null);
  const [countryData, setCountryData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [countryId, setCountryId] = useState("");
  const [stateId, setStateId] = useState("");
  const [cityId, setCityId] = useState("");
  const [image, setImage] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [imgSrc, setImgSrc] = useState('')
  const [screenshortSrc, setScreenshortSrc] = useState('');
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null)
  const [file, setFile] = useState(null);
  const hiddenAnchorRef = useRef(null)
  const blobUrlRef = useRef('')
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState(16 / 9)
  const [deleteProfilePop, setDeleteProfilePop] = useState(false);
  const [openWebCamModal, setOpenWebCamModal] = React.useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const projectInvitId = params.get('pinvit');

  const handleChange = (event) => {
    setGender(event.target.value);
  };

  const handleClickOpen = () => {
    document.getElementById("fileInput").click();
    //setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchUserProfile();
    getAllCountryList();
  }, []);

  const genderListVal = [
    { value: 'male', title: 'Male' },
    { value: 'female', title: 'Female' },
    { value: 'other', title: 'Other' },
  ];

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "280px",
        overflowY: "auto",
      },
    },
  };

  const fetchUserProfile = async () => {
    let token = authState.authToken
    try {
      const response = await userProfile(token);
      const profileData = response?.data?.data;

      setUserProfileData(profileData);
      setImage(profileData.profile_pic);
      setGender(profileData?.gender);

      if (profileData?.country?.length > 0) {
        setCountryId(profileData.country[0].id);
        getAllStatesList(profileData.country[0].id);
      }

      if (profileData?.state?.length > 0) {
        setStateId(profileData.state[0].id);
        getAllCitiesList(profileData.state[0].id, profileData.country[0].id);
      }

      if (profileData?.city?.length > 0) {
        setCityId(profileData.city[0].id);
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  }

  const getAllCountryList = () => {
    getCountries().then((res) => {
      if (res.success && res.data.data.length > 0) {
        setCountryData(res.data.data.map((country) => ({ id: country.id, _id: country._id, name: country.name })));
      }
    });
  };

  const getAllStatesList = (country_id) => {
    getStates(country_id).then((res) => {
      if (res.success && res.data.data.length > 0) {
        setStatesData(res.data.data.map((state) => ({ id: state.id, _id: state._id, name: state.name, country_id: state.country_id })));
      }
    });
  };

  const getAllCitiesList = (state_id, country_id) => {
    getCities(country_id, state_id).then((res) => {
      if (res.success && res.data.data.length > 0) {
        setCitiesData(res.data.data.map((city) => ({ id: city.id, _id: city._id, name: city.name })));
      }
    });
  };

  const setDropdownId = (data) => {
    if (data) {
      return (Array.isArray(data) && data.length) > 0 ? data[0]._id : (data instanceof Object) ? data._id : data
    }
  }

  const handleCountryChange = (event) => {
    const selectedCountryId = event.target.value;
    setCountryId(selectedCountryId);
    setStatesData([]);
    setCitiesData([]);
    getAllStatesList(selectedCountryId);
  };

  const handleStateChange = (event) => {
    const selectedStateId = event.target.value;
    setStateId(selectedStateId);
    setCitiesData([]);
    getAllCitiesList(selectedStateId, countryId);
  };

  const handleCityChange = (event) => {
    setCityId(event.target.value);
  };

  const setFormdata = (e) => {
    let newFormState = {
      ...userProfileData,
      [e.target.name]: e.target.value
    }
    setUserProfileData(newFormState)
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined)
    } else {
      setAspect(16 / 9)

      if (imgRef.current) {
        const { width, height } = imgRef.current
        const newCrop = centerAspectCrop(width, height, 16 / 9)
        setCrop(newCrop);
        console.log("convertToPixelCrop(newCrop, width, height)", convertToPixelCrop(newCrop, width, height));
        setCompletedCrop(convertToPixelCrop(newCrop, width, height))
      }
    }
  }

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined)
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      setOpen(true);
      handleToggleAspectClick();
      reader.readAsDataURL(e.target.files[0])
      setFile(e.target.files[0])
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  async function generateFileforServer() {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!previewCanvas) {
      return updateImage(file);
    }
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist');
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    );
    const ctx = offscreen.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    );

    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    });

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
    }
    blobUrlRef.current = URL.createObjectURL(blob);
    updateImage(blob)
  }

  const handleToggleWebcam = () => {
    setOpenWebCamModal(true);
    // setShowWebcam((prevShowWebcam) => !prevShowWebcam);
  };

  const handleCloseWebCam = () => {
    setOpenWebCamModal(false);
  };

  const updateImage = async (file) => {
    const formData = new FormData()
    formData.append('file', file, "profic_pic.png")
    try {
      let imageUploadRes = await uploadUserDirectoryImage(formData, authState.authToken);
      if (imageUploadRes.success) {
        setImgSrc(null);
        setOpen(false);
        setScreenshortSrc(null);
        setOpenWebCamModal(false)
        updateProfile({ profile_pic: imageUploadRes.data.upload_id }, false);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  };

  const updatedUserProfile = async () => {
    setLoading(true);
    try {

      if (password !== "" || confirmPassword !== "") {
        if (password === "") {
          return toast.error("Password is required.");
        }
        if (password.length < 8) {
          return toast.error("Password must be at least 8 characters long.");
        }
        if (confirmPassword === "") {
          return toast.error("Confirm password is required.");
        }
        if (confirmPassword.length < 8) {
          return toast.error("Confirm password must be at least 8 characters long.");
        }
        if (password !== confirmPassword) {
          return toast.error("Password and confirm password do not match.");
        }
      }

      const requestPayload = {
        name: userProfileData.name,
        email: userProfileData.email,
        phone: userProfileData.phone,
        gender: gender,
        address_line1: userProfileData.address_line1,
        country: setDropdownId(userProfileData.country),
        state: setDropdownId(userProfileData.state),
        city: setDropdownId(userProfileData.city),
        zipcode: userProfileData.zipcode,
        ...(password === "" ? {} : { password: password }),
        status: "active",
        project_invite_id: projectInvitId
      };

      await updateProfile(requestPayload);
    } catch (err) {
      console.error("Error updating profile:", err);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    } finally {
      setLoading(false);
    }
  };

  const updateProfile = async (payload = {}, isRedirect = true) => {
    try {
      let response = await updateLoggedInUserProfile(payload, authState.authToken);
      if (response.success) {
        if (isRedirect) {
          if (response.data.projectInvitionToken)
            return Navigate(`/projects-invitations?token=${response.data.projectInvitionToken}`)
          else
            return Navigate('/users/dashboard')
        }
        fetchUserProfile();
        toast.success(response.message, TOAST_CONFIG);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  };

  const uploadScreenshort = async () => {
    if (screenshortSrc) {
      const file = dataURLToFile(screenshortSrc, "profile_pic.png");
      updateImage(file);
    }
  }


  return (
    <>
      <Box className="ResearchersContainer">
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box className="terapageLogBox">
              <img style={{ width: "176.442px", height: "20px" }} src={clogsvg} />
            </Box>
          </Box>
          {/* Page container */}
          <Box className="adminMainBox screenMainBox" sx={{ display: "flex" }}>
            <Box className="PersonalInformationSection">
              <Grid container spacing={2} className="flexGrid">
                <Grid item md={7}>
                  <Box className="PinfoPanel">
                    <Box className="profileImage">
                      {!image || image === "undefined" ? (
                        <img src={DummyUser} alt="Dummy User" />
                      ) : (
                        <img src={viewImage(image)} alt="Profile" />
                      )}
                    </Box>
                    <Box className="profileContent">
                      <Typography variant="h3" gutterBottom>
                        {userProfileData?.name}
                      </Typography>
                      <Typography gutterBottom>
                        {userProfileData?.email}
                      </Typography>
                      <Typography>{userProfileData?.gender}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={5}>
                  <Box className="btnoPanel">
                    <Box
                      onClick={() =>
                        setDirectMessageNavDrop(!directMessageNavDrop)
                      }
                      className="directMessageNavBtn"
                    >
                      <Typography sx={{ color: "#FFFFFF" }}>
                        {directMessageNavDropVal || "Upload Image"}
                      </Typography>
                      <img src={WhiteDownArrow} />
                      {/* pop */}
                      <Box
                        sx={{ display: directMessageNavDrop ? "flex" : "none" }}
                        className="workspaceItemNavPop"
                      >
                        <Box onClick={handleToggleWebcam}>
                          <Typography>Use Webcam</Typography>
                        </Box>
                        <Box onClick={handleClickOpen}>
                          <Typography>Upload from system</Typography>
                        </Box>
                      </Box>
                      <input
                        id="fileInput"
                        style={{ display: "none" }}
                        type="file"
                        // onChange={handleFileInputChange}
                        onChange={onSelectFile}
                      />
                    </Box>

                    <AppBtnOutLine buttonText="Delete" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Dialog
              open={openWebCamModal}
              onClose={handleCloseWebCam}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" className="textcenter">
                Edit how the selected image should appear
              </DialogTitle>
              <DialogContent>
                <WebcamCapture screenshortSrc={screenshortSrc} setScreenshortSrc={setScreenshortSrc} />
              </DialogContent>
              <DialogActions>
                <AppBtnOutLine
                  className="halfwidthbtn"
                  onClick={handleCloseWebCam}
                  buttonText="Cancel"
                />
                <AppBtn
                  className="halfwidthbtn"
                  onClick={uploadScreenshort} // Uncommented and fixed the function name
                  buttonText="Upload"
                />
              </DialogActions>
            </Dialog>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" className="textcenter">
                Edit how the selected image should appear
              </DialogTitle>
              <DialogContent>
                {!!imgSrc && (
                  <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={aspect}
                    minHeight={100}
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={imgSrc}
                      style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                      onLoad={onImageLoad}
                    />
                  </ReactCrop>
                )}
                {!!completedCrop && (
                  <>
                    <div>
                      <canvas
                        ref={previewCanvasRef}
                        style={{
                          display: 'none',
                          border: '1px solid black',
                          objectFit: 'contain',
                          width: completedCrop.width,
                          height: completedCrop.height,
                        }}
                      />
                    </div>
                  </>
                )}
                {/* <img src={CropImage} /> */}
              </DialogContent>
              <DialogActions>
                <AppBtnOutLine
                  className="halfwidthbtn"
                  onClick={handleClose}
                  buttonText="Cancel"
                />
                <AppBtn
                  className="halfwidthbtn"
                  onClick={generateFileforServer}
                  buttonText="upload"
                />
              </DialogActions>
            </Dialog>

            <Box className="PIFromSection">
              <Grid container spacing={4}>
                <Grid item sm={12}>
                  <FormLabel className="label">Full Name</FormLabel>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    placeholder="Enter full name"
                    variant="outlined"
                    size="small"
                    name="name"
                    value={userProfileData?.name}
                    onChange={setFormdata}
                  />
                </Grid>
                <Grid item sm={6}>
                  <FormLabel className="label">Contact Number</FormLabel>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    placeholder="Enter contact number"
                    variant="outlined"
                    size="small"
                    name="phone"
                    value={userProfileData?.phone}
                    onChange={setFormdata}
                  // defaultValue={"+91 9175208513"}
                  />
                </Grid>
                <Grid item sm={6}>
                  <FormLabel className="label">Gender <span class="req">*</span></FormLabel>
                  <FormControl sx={{ width: "100%" }} size="small">
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={gender}
                      onChange={handleChange}
                    >
                      {genderListVal &&
                        genderListVal?.map((el, index) => (
                          <MenuItem value={el.value}>{el.title}</MenuItem>
                        ))}
                      {/* <MenuItem value={20}>Female</MenuItem> */}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12}>
                  <FormLabel className="label">Email Address</FormLabel>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    placeholder="Enter email address"
                    variant="outlined"
                    size="small"
                    name="email"
                    value={userProfileData?.email}
                    onChange={setFormdata}
                  />
                </Grid>
                <Grid item sm={12}>
                  <FormLabel className="label">Username <span class="req">*</span></FormLabel>
                  <Typography className="sublabel">No spaced or special characters. Please do not include the last name.</Typography>
                  <input
                    className="phoneInput"
                    placeholder="Enter Username"
                    name="username"
                    value={userProfileData?.username}
                    onChange={setFormdata}

                  />
                </Grid>
                <Grid item sm={12}>
                  <FormLabel className="label">New Password <span class="req">*</span></FormLabel>
                  <Typography className="sublabel">At least 8 characters</Typography>
                  <Box mb={1} className="csPBox pwdInput">
                    <input
                      className="phoneInput"
                      placeholder={t("admin.enterPassword")}
                      type={pwdVisible ? "text" : "password"}
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <img src={VisibleIcon} onClick={() => setPwdVisible(!pwdVisible)} />
                  </Box>
                  <Divider className="gapbetween" />
                  <Box mb={1} className="csPBox pwdInput">
                    <input
                      className="phoneInput"
                      placeholder="Confirm New Password"
                      type={pwdVisible2 ? "text" : "password"}
                      name="confirm_password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <img
                      src={VisibleIcon}
                      onClick={() => setPwdVisible2(!pwdVisible2)}
                    />
                  </Box>
                </Grid>
                <Grid item sm={12}>
                  <FormLabel className="label">Address <span class="req">*</span></FormLabel>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    placeholder="Enter address"
                    variant="outlined"
                    size="small"
                    name="address_line1"
                    value={userProfileData?.address_line1}
                    onChange={setFormdata}
                  />
                </Grid>
                <Grid item sm={6}>
                  <FormLabel className="label">Country</FormLabel>
                  <FormControl sx={{ width: "100%" }} size="small">
                    <Select
                      value={countryId}
                      onChange={handleCountryChange}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="">
                        Select country
                      </MenuItem>
                      {countryData.map((country) => (
                        <MenuItem key={country.id}
                          value={country.id}
                          onClick={() => setUserProfileData({ ...userProfileData, country: setDropdownId(country) })}
                        >
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormLabel className="label">State</FormLabel>
                  <FormControl sx={{ width: "100%" }} size="small">
                    <Select
                      value={stateId}
                      onChange={handleStateChange}
                      disabled={!countryId}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="">
                        Select state
                      </MenuItem>
                      {statesData.map((state) => (
                        <MenuItem key={state.id} value={state.id}
                          onClick={() => setUserProfileData({ ...userProfileData, state: setDropdownId(state) })}
                        >
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormLabel className="label">City</FormLabel>
                  <FormControl sx={{ width: "100%" }} size="small">
                    <Select
                      value={cityId}
                      onChange={handleCityChange}
                      disabled={!stateId}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="">
                        Select city
                      </MenuItem>
                      {citiesData.map((city) => (
                        <MenuItem key={city.id} value={city.id}
                          onClick={() => setUserProfileData({ ...userProfileData, city: setDropdownId(city) })}
                        >
                          {city.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormLabel className="label">ZIP Code</FormLabel>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    placeholder="Enter zip code"
                    variant="outlined"
                    size="small"
                    name="zipcode"
                    value={userProfileData?.zipcode}
                    onChange={setFormdata}
                  />
                </Grid>
                <Grid item sm={12}>
                  <Box className="workPupBtnBox">
                    <AppBtnOutLine buttonText="Cancel" />
                    <AppBtn
                      onClick={updatedUserProfile}
                      buttonText="Next"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
