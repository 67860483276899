import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material';
import "./style.css";

//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton"
import { useAppSelector } from '../../../lib/store/store';
import { sendOtpToEmail } from '../../../Services/auth';
import { researcherAction } from '../../../Services/researcherActions';
import { toast } from 'react-toastify';
import { TOAST_CONFIG } from '../../../env';

export default function DeleteAccountPop({ deleteAccountPop, setDeleteAccountPop }) {

  const authState = useAppSelector((state) => state.auth);
  const [verifyButtonDisable, setVerifyButtonDisable] = useState(true)
  const [otp, setOtp] = useState("")


  const handelPopClose = (e) => {
    if (e.target.id === "popVerifydrop") {
      setDeleteAccountPop(false)
    }
  }

  useEffect(() => {
    if (deleteAccountPop) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = 'auto'; // Enable scrolling
    }
  }, [deleteAccountPop])

  const handelTextChange = (e) => {
    if (e.target.value) {
      setVerifyButtonDisable(false)
    } else {
      setVerifyButtonDisable(true)
    }
  }

  const sendOtpApi = async () => {
    try {
      // setLoading(true)
      let response = await sendOtpToEmail({ otp_type: "DELETE_ACCOUNT" }, authState?.authToken);
      // setLoading(false)
      if (response.success) {
        toast.success(response.message)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const deleteProfile = async () => {
    try {
      if (otp === "")
        toast.error("OTP is required.", TOAST_CONFIG);
      if (otp.length >= 6)
        toast.error("OTP must be six digit.", TOAST_CONFIG);

      let response = await researcherAction('/auth/delete-profile', { otp }, authState.authToken);
      if (response.success) {
        setDeleteAccountPop(false)
        toast.success(response.message, TOAST_CONFIG);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  }

  return (
    <>
      <Box onClick={handelPopClose} id="popVerifydrop" sx={{ display: deleteAccountPop ? "flex" : "none" }} className="emailVerifyPopContainer">

        <Box className="emailVerifyDeleteBox">
          <Typography className='deleteAccountPopHeader'>Delete Account</Typography>

          <Typography>This account can not be undone. Once the account is deleted you will not be able to access your account and data again.</Typography>
          <Box className="deleteAccountBox lastVerifyBasicBox">
            <Box className="addressBox">
              <Typography className="companyInputLabel">Email Address</Typography>
              <input
                readOnly
                type="text"
                className="addInput"
                placeholder="Han123Richard@gmail.com"
                value={authState.userProfile.email}
              />
              <div style={{ textAlign: 'right' }}><a className='agreeLinkLabel' href='javascript:void(0)' onClick={sendOtpApi}>Send OTP</a></div>
            </Box>


            <Box className="addressBox">
              <Typography className="companyInputLabel">Code Send on Email</Typography>
              <input
                type="text"
                className="addInput"
                onChange={(e) => { handelTextChange(e); setOtp(e.target.value) }} placeholder="Enter your Code"
              />
            </Box>
          </Box>
          <Box className="emailVerifyPopBtnBox">
            <AppBtnOutLine onClick={() => setDeleteAccountPop(false)} buttonText="Cancel" />
            <AppBtn disabled={verifyButtonDisable ? true : false} buttonText="Submit" onClick={deleteProfile} />
          </Box>
        </Box>


      </Box>
    </>
  )
}
