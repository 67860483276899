import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import "./style.css";

import { useNavigate } from "react-router-dom";

//images
import iIcon from "../../../Assets/Images/IIcon.png";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import Hash from "../../../Assets/Images/hash.png";
import bar from "../../../Assets/Images/bar-chart-2.png";
import { sideHaumIcon } from "../../../Assets/Data";
import WhitePlassIcon from "../../../Assets/Images/Wplus.png";

import { AppBtnOutLine } from "../../../Components/AppTools/AppButton";
import axios from "../../../lib/axios";
//component
import SideMenu from "../../../Components/WSAdministration/SideMenu";
import { SearchBar } from "../../../Components/AppTools/AppTool";
import AddProfileField from "../../../Components/WSAdministration/AddProfileField";
import SettingProfileField from "../../../Components/WSAdministration/SettingProfileField";
//Data
import { ProfileFieldData } from "../../../Assets/Data";

import { settingIconBlack, trashBlack } from "../../../Assets/Data";
import DeleteProfileFieldPop from "../../../Components/Admin/DeleteProfileFieldPop";
import DeleteWSProfilingField from "../DeleteWSProfilingField";
import { useAppSelector } from "../../../lib/store/store";
import { fieldItem } from "../../../Assets/Data";
import AdminLayout from "../../Admin/partials/AdminLayout";
import ResearcherLayout from "../../Researchers/partials/ResearcherLayout";
import { renderUserLayout } from "../../../lib/helpers";

export default function ParticipantProfiling({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
  participantsSubMenu,
  setParticipantsSubMenu,
  configurationSubMenu,
  setConfigurationSubMenu,
}) {
  const [pprofileNav, setPprofileNav] = useState(0);
  const [filterDrop, setFilterDrop] = useState(false);
  const [filterDropVal, setFilterDropVal] = useState();
  const [tabTxt, setTabTxt] = useState("");

  const [optionIndex, setOptionIndex] = useState();
  const [workSpaceOption, setWorkspaceOption] = useState(false);

  const [profileFieldSetting, setProfileFieldSetting] = useState(false);
  const [profileFieldId, setProfileFieldId] = useState("");
  const [addProfileField, setAddProfileField] = useState(false);
  const [deleteProfileFieldPop, setDeleteProfileFieldPop] = useState(false);
  const [customFields, setCustomFields] = useState([]);
  const [customFieldID, setCustomFieldID] = useState("");
  const [customFieldName, setCustomFieldName] = useState("");
  const [search, setSearch] = useState("");
  const [fieldType, setFieldType] = useState("");
  const [reloadTable, setReloadTable] = useState(false);
  const [expand_collapse, setExpand_collapse] = useState(true)

  let authState = useAppSelector((state) => state.auth);

  const Navigate = useNavigate();

  const handleMainOpenTabe = (Url, tab) => {
    const link = Url + (tab == "" ? "" : `?type=${tab}`);
    Navigate(link);
  };
  // const handleMainOpenTabe = (Url) => {
  //   Navigate(Url);
  // };

  useEffect(() => {
    getCustomFields();

  }, []);
  useEffect(() => {
    getCustomFields();
    setReloadTable(false);
  }, [reloadTable]);

  const workspaceState = useAppSelector((state) => state.workspace);
  const getCustomFields = async (curPage = 1) => {
    let url =
      `/participant/profile-fields?workspace_id=${workspaceState?.selectedWorkspace?._id}` +
      (tabTxt ? `&type=${tabTxt}` : "");
    if (search && search !== "") url += `&search=${search}`;
    if (filterDropVal && filterDropVal !== "")
      url += `&field_type=${filterDropVal.replace(" ", "_").toLocaleLowerCase()}`;
    console.log("url", url);
    const res = await axios.get(url);
    if (res.status) {
      setCustomFields(res.data.data);
    }
  };

  const handleFilterChange = (changedFilter) => {
    setSearch(changedFilter.search);
    setReloadTable(true);
  };

  const getActionBtns = () => {
    return (<>{!addProfileField && !profileFieldSetting ? (
      <Box className="AppNavBtnBox">
        <AppBtnOutLine buttonText="Export" />
        <Box
          className="AddPBtn"
          onClick={() => {
            const url = "/workspace-administration/add-profileField"; // + tabTxt == "" ? "" : `?type=${tabTxt}`
            handleMainOpenTabe(url, tabTxt);
            // handleMainOpenTabe(url);
          }}
        >
          <img src={WhitePlassIcon} />
          <Typography ml={1}>Add Profile Field</Typography>
        </Box>
      </Box>
    ) : null}</>);
  }

  const getChildren = () => {
    return <Box className="researchersMainBox pageContainer">
      {addProfileField ? (
        <AddProfileField setAddProfileField={setAddProfileField} />
      ) : profileFieldSetting ? (
        <SettingProfileField
          setProfileFieldSetting={setProfileFieldSetting}
          profileFieldId={profileFieldId}
          setProfileFieldId={setProfileFieldId}
          setReloadTable={setReloadTable}
        />
      ) : deleteProfileFieldPop ?(
        <DeleteWSProfilingField
        setDeleteProfileFieldPop = {setDeleteProfileFieldPop}
        deleteProfileFieldPop = {deleteProfileFieldPop}
        customFieldName={customFieldName}
        customFieldID={customFieldID}
        setReloadTable = {setReloadTable}
        setCustomFieldID={setCustomFieldID}
        setCustomFieldName = {setCustomFieldName}
        />
      ) :(
        <Box className="ParticiProfiling workspaceListBox">
          <Box className="PProfNav">
            <Box
              onClick={() => {
                setPprofileNav(0);
                setTabTxt("");
                setReloadTable(true);
              }}
              className={
                pprofileNav === 0
                  ? "pprofNavItem pprofNavItemActive"
                  : "pprofNavItem"
              }
            >
              <Typography>All Fields</Typography>
              <Box className="fieldCount">
                <p>6</p>
              </Box>
              <img className="fieldCountArrow" src={DownArrowIcon} />
            </Box>
            <Box
              onClick={() => {
                setPprofileNav(1);
                setTabTxt("registration");
                setReloadTable(true);
              }}
              className={
                pprofileNav === 1
                  ? "pprofNavItem pprofNavItemActive"
                  : "pprofNavItem"
              }
            >
              <Typography>Registration</Typography>
            </Box>
            <Box
              onClick={() => {
                setPprofileNav(2);
                setTabTxt("my_account");
                setReloadTable(true);
              }}
              className={
                pprofileNav === 2
                  ? "pprofNavItem pprofNavItemActive"
                  : "pprofNavItem"
              }
            >
              <Typography>My Account</Typography>
            </Box>
            <Box
              onClick={() => {
                setPprofileNav(3);
                setTabTxt("in_projct");
                setReloadTable(true);
              }}
              className={
                pprofileNav === 3
                  ? "pprofNavItem pprofNavItemActive"
                  : "pprofNavItem"
              }
            >
              <Typography>In Project</Typography>
            </Box>
            <Box
              onClick={() => {
                setPprofileNav(4);
                setTabTxt("hidden");
                setReloadTable(true);
              }}
              className={
                pprofileNav === 4
                  ? "pprofNavItem pprofNavItemActive"
                  : "pprofNavItem"
              }
            >
              <Typography>Hidden</Typography>
            </Box>
          </Box>

          <Box className="iBox">
            <img src={iIcon} />
            <Typography>
              Profile fields for participants enable the storage of custom
              values associated with each participant. A profile field can
              remain hidden custom information, required during
              registration (demographics), or used in project screeners
              (qualification criteria).
            </Typography>
          </Box>

          <Box className="searchbox">
            <Box width={"70%"}>
              <SearchBar
                placeholder="Search Fields"
                handleFilterChange={handleFilterChange}
              />
            </Box>

            <Box ml={2} width={"30%"}>
              <Box
                className="inputAppDrop"
                onClick={() => setFilterDrop(!filterDrop)}
              >
                <Typography>
                  {filterDropVal || "Filter by Type"}
                </Typography>
                <img
                  style={{
                    rotate: filterDrop ? "180deg" : "0deg",
                  }}
                  src={DownArrowIcon}
                />
                {/* pop */}
                <Box
                  sx={{ height: filterDrop ? "auto" : "0px" }}
                  className="statusPop"
                >
                  {/* <Box
                  onClick={() => setFilterDropVal("Item-A")}
                  className="statusPopItem"
                >
                  <Typography>Item-A</Typography>
                </Box>
                <Box
                  onClick={() => setFilterDropVal("Item-B")}
                  className="statusPopItem"
                >
                  <Typography>Item-B</Typography>
                </Box>
                <Box
                  onClick={() => setFilterDropVal("Item-C")}
                  className="statusPopItem"
                >
                  <Typography>Item-C</Typography>
                </Box>
                <Box

                  onClick={() => setFilterDropVal("Item-D")}
                  className="statusPopItem"
                >
                  <Typography>Item-D</Typography>
                </Box> */}
                  <Box
                    onClick={() => {
                      setFilterDropVal("");
                      setReloadTable(true);
                    }}
                    className="statusPopItem"
                  >
                    <Typography>Filter by Type</Typography>
                  </Box>
                  {fieldItem &&
                    fieldItem?.map((el, index) => (
                      <Box
                        key={index}
                        onClick={() => {
                          setFilterDropVal(
                            el.field
                          );
                          setReloadTable(true);
                        }}
                        className="statusPopItem"
                      >
                        {el.icon}
                        <Typography>{el.field}</Typography>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "24px 0",
            }}
          >
            <Typography className="ppundText" onClick={()=>setExpand_collapse(true)}>Expand All</Typography>
            <Typography className="ppundText" onClick={()=>setExpand_collapse(false)}>Collapse All</Typography>
          </Box>
          {expand_collapse &&
            <Box>
              <Typography className="allPartRedioLabel">
            Profile Fields
          </Typography>
          <Box className="projectFieldList profileFieldAllQuestion">
            {customFields &&
              customFields?.map((el, index) => (
                <Box className="ProfileFiledQuestion">
                  <Box
                    id="dot"
                    onClick={() => {
                      setWorkspaceOption(!workSpaceOption);
                      setOptionIndex(index);
                    }}
                    className={
                      workSpaceOption && index === optionIndex
                        ? "dotProfile dotProfileActive"
                        : "dotProfile"
                    }
                  >
                    {
                      <Box
                        sx={{
                          display:
                            workSpaceOption && index === optionIndex
                              ? "flex"
                              : "none",
                        }}
                        className="optionPop"
                      >
                        <Box
                          onClick={() => { setProfileFieldSetting(true); 
                            setProfileFieldId(el._id) }}
                          className="optionPopItem"
                        >
                          {settingIconBlack}
                          <Typography className="optionProfilePopItem">
                            Setting
                          </Typography>
                        </Box>

                        <Box
                          className="optionPopItem"
                          onClick={() => {
                            setCustomFieldID(el._id);
                            setCustomFieldName(el.question);
                            setDeleteProfileFieldPop(true);
                          }}
                        >
                          {trashBlack}
                          <Typography className="optionProfilePopItem">
                            Delete
                          </Typography>
                        </Box>
                      </Box>
                    }
                  </Box>
                  <Typography>
                    <img src={Hash} /> {el.question}
                  </Typography>
                </Box>
              ))}

            {/* <Typography><span>:</span><img src={Hash} /> What is your current household income?</Typography>
    <Typography><span>:</span><img src={bar} /> What gender do you identify as?</Typography>
    <Typography><span>:</span><img src={bar} /> What is your current education level?</Typography>
    <Typography><span>:</span><img src={bar} /> What gender do you identify as?</Typography> */}
          </Box>
            </Box>
          }
        </Box>
      )}
    </Box>
  }

  return renderUserLayout(authState?.userType, getActionBtns(), getChildren(), 'workadmin');
}
