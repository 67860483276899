import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";

//components
import { AppBtnOutLine, AppBtn } from "../../AppTools/AppButton";
import dropArrow from "../../../Assets/Images/chevron-down.png"

export default function Api3Tab({ apiCredentialTab }) {

    const [apiModeDrop, setApiModeDrop] = useState(false);
    const [apiModeDropVal, setApiModeDropVal] = useState(false);

  return (
    <>
          <Box
            sx={{ display: apiCredentialTab === 2 ? "flex" : "none" }}
            className="settingBox"
          >
            <Box mt={5} className="csPBox">
            <Typography className="allPartRedioLabel">API 3 Client ID</Typography>
            <input
                style={{ marginTop: "16px" }}
                className="phoneInput"
                placeholder="Enter API Client ID"
            />
            </Box>

            <Box mt={5} className="csPBox">
            <Typography className="allPartRedioLabel">API 3 Secret</Typography>
            <input
                style={{ marginTop: "16px" }}
                className="phoneInput"
                placeholder="Enter API Secret"
            />
            </Box>

            <Box mt={5} className="csPBox">
            <Typography className="allPartRedioLabel">API 3 Mode</Typography>
            <Box
                className="inputAppDrop apiModeDrop"
                onClick={() => setApiModeDrop(!apiModeDrop)}
              >
                <Typography>{apiModeDropVal || "Select API Mode"}</Typography>
                <img
                  style={{ rotate: apiModeDrop ? "180deg" : "0deg" }}
                  src={dropArrow}
                />
                {/* pop */}
                <Box
                  sx={{ height: apiModeDrop ? "auto" : "0px" }}
                  className="statusPop"
                >
                  <Box
                    onClick={() =>
                        setApiModeDropVal("Test")
                    }
                    className="statusPopItem"
                  >
                    <Typography>Test</Typography>
                  </Box>
                  <Box
                    onClick={() =>
                        setApiModeDropVal("Live")
                    }
                    className="statusPopItem"
                  >
                    <Typography>Live</Typography>
                  </Box>
                </Box>
                {/* pop */}
              </Box>
            </Box>

            <Box className="workPupBtnBox">
            <AppBtnOutLine
                buttonText="Cancel"/>
            <AppBtn buttonText="Save" />
            </Box>
        </Box>

    </>
  );
}
