import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";

import Clogo from "../../../Assets/Images/Frame 243811.png";
import signature from "../../../Assets/Images/signature.png";

//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";

export default function EmailPreviewPop({
  emailPreviewPop,
  setEmailPreviewPop,
  setEmailBroadcastPop,
}) {
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setEmailPreviewPop(false);
    }
  };

  const handelPreviousPop = () => {
    setEmailPreviewPop(false);
    setEmailBroadcastPop(true);
  };

  useEffect(() => {
    if (emailPreviewPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [emailPreviewPop]);

  return (
    <>
      <Box
        onClick={handelPopClose}
        id="popBackdrop"
        sx={{ display: emailPreviewPop ? "flex" : "none" }}
        className="workspaceDeletePopContainer"
      >
        <Box className="workspaceDeleteBox workspaceDeletePopTextAlign">
          <Box className="deleteTextBox">
            <img style={{ width: "212px" }} src={Clogo} />
          </Box>
          <Typography className="deletePopHeader">
            Invite to join a Project
          </Typography>

          <Box className="deleteTextBox">
            <Typography>Hello Alan,</Typography>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur.
            </Typography>
          </Box>

          <Box className="deletPopBtnBox emailPreviewLoginBtn">
            <AppBtn buttonText="Click here to login" />
          </Box>

          <Box className="deleteTextBox">
            <img style={{ width: "212px" }} src={signature} />
          </Box>
          <Box className="deleteBtnBox">
            <AppBtnOutLine
              onClick={() => handelPreviousPop()}
              buttonText="Back"
            />
            <AppBtn buttonText="Send Now" />
          </Box>
        </Box>
      </Box>
    </>
  );
}
