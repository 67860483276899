import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css"

import sysBg from "../../../Assets/Images/sysBg.png"
import checkG from "../../../Assets/Images/checkG.png"

//components
import { AppBtn, AppBtnOutLine } from "../../../Components/AppTools/AppButton";
import { useTranslation } from "react-i18next";


export default function SystemCheckPop({ systemCheckPop, setSystemCheckPop }) {
  const { t, i18n } = useTranslation();

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setSystemCheckPop(false);
    }
  };

  useEffect(() => {
    if (systemCheckPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [systemCheckPop]);


  return (
    <Box onClick={handelPopClose} sx={{ display: systemCheckPop ? "flex" : "none" }} className="workspacePop systemCheck" id="popBackdrop">
      <Box sx={{ display: systemCheckPop ? "flex" : "none" }} className="workspaceContaint">

        <Box className="systemSubBox">
          <Typography className="companyHeader">{t("LiveInterview.AllTestsPassed")}</Typography>
          <Typography>{t("LiveInterview.AllRequired")}</Typography>

          <Box className="sysImgBox">
            <img src={sysBg} />
          </Box>

          <Typography className="blueText">{t("LiveInterview.UnsureRestart")}</Typography>
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={() => setSystemCheckPop(false)} />
            <AppBtn buttonText="Download Report" />
          </Box>
        </Box>

        <Box className="systemSubBox">
          <Box className="labelWTik">
            <img src={checkG} />
            <Typography>{t("LiveInterview.DeviceSetup")}</Typography>
          </Box>
          <Typography>{t("LiveInterview.noIssues")}</Typography>

          <Box className="blueUTextBox">
            <Typography className="blueUText">{t("LiveInterview.ReviewBrowser")}</Typography>
            <Typography className="blueUText">{t("LiveInterview.ReviewHardware")}</Typography>
          </Box>
          <Box className="labelWTik">
            <img src={checkG} />
            <Typography>{t("LiveInterview.Connectivity")}</Typography>
          </Box>
          <Typography>{t("LiveInterview.ALLConnectionsIssues")}</Typography>
          <Typography className="blueUText">{t("LiveInterview.ReviewConnectivity")}</Typography>
          <Box className="labelWTik">
            <img src={checkG} />
            <Typography>{t("LiveInterview.Performance")}</Typography>
          </Box>
          <Typography>{t("LiveInterview.ThePerformanceErformance")}</Typography>
          <Typography className="blueUText">{t("LiveInterview.ReviewPerformance")}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
