import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./style.css";

//images
import backArrow from "../../../Assets/Images/noun-back.png";
import iIcon2 from "../../../Assets/Images/IIcon.png";
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png";
import dropIcon from "../../../Assets/Images/chevron-down.png";
import trash from "../../../Assets/Images/redTrash.png"
import rankImg from "../../../Assets/Images/rankCard.png"

import { emojis } from "../../../Assets/Data"
//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { AntSwitch, SearchBar, Status, PickEmoji } from "../../AppTools/AppTool"


export default function RenkItSortItPop({ renkItSortIt, setRenkItSortIt, handelAddTaskPopBack }) {
  const [image, setImage] = useState(null);
  const [chooseCount, setChooseCount] = useState(1)
  const [chooseCount2, setChooseCount2] = useState(1)

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const scaleArrya = [
    "item",
    "item",
    "item",
  ]


  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };


  const RankCard = ({ count }) => {
    return (
      <Box mt={2} className="rankCard">
        <Box className="rankTextBox">
          <Typography>Card {count * 1 + 1} Label</Typography>
          <input className="phoneInput" placeholder="Enter card label" />
          <img className="trash" src={trash} onClick={handelDeleteAttribute} />
          <Typography className="rankCardUnderl">Change Image <span>Remove Image</span></Typography>
        </Box>
        <Box className="rankImg">
          <img src={rankImg} />
        </Box>
      </Box>
    )
  }



  const renderComponents = (value) => {
    let components = [];
    for (let i = 0; i < value; i++) {
      components.push(<RankCard count={i} key={i} />);
    }
    return components;
  };


  const handelDeleteAttribute = () => {
    setChooseCount(chooseCount - 1)
  }
  const handelAddAttribute = () => {
    setChooseCount(chooseCount + 1)
  }


  const CategoryLCard = ({ count }) => {
    return (
      <Box my={1}>
        <Box my={3} sx={{ width: "100%", position: "relative" }}>
          <Typography>Category {count * 1 + 1} {t("onDotTaskPop.label")}</Typography>
          <input style={{ width: "100%", marginTop: "8px" }} className="phoneInput" placeholder={t("rankItSortItPop.absolutelyNeeded")} />
          <img className="trash2" src={trash} onClick={handelDeleteAttribute2} />
        </Box>
        <Box mb={1} className="tagInputBOx">
          <input
            style={{ marginRight: "10px" }}
            className="custom-checkbox"
            type="checkBox" />
          <Typography>{t("rankItSortItPop.enforceCardLimit")}</Typography>
        </Box>

        <Box className="limitBox nLimitebox">
          <input className="phoneInput" value={"01"} />
          <Typography>{t("addTextTaskPop.to")}</Typography>
          <input className="phoneInput" value={"04"} />
          <Typography>{t("rankItSortItPop.cards")}</Typography>
        </Box>

        <Box my={3} sx={{ width: "100%" }}>
          <Box mb={1} className="tagInputBOx"><input
            style={{ marginRight: "10px" }}
            className="custom-checkbox"
            type="checkBox"
          />
            <Typography>{t("addProfile.customizePrmt")}</Typography>
          </Box>
          <input style={{ marginLeft: "30px", width: "96%" }} className="phoneInput" placeholder={t("rankItSortItPop.customPromptPlaceholder")} />
        </Box>
      </Box>
    )
  }


  const renderComponents2 = (value) => {
    let components = [];
    for (let i = 0; i < value; i++) {
      components.push(<CategoryLCard count={i} key={i} />);
    }
    return components;
  };


  const handelDeleteAttribute2 = () => {
    setChooseCount2(chooseCount2 - 1)
  }
  const handelAddAttribute2 = () => {
    setChooseCount2(chooseCount2 + 1)
  }


  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setRenkItSortIt(false);
    }
  };

  useEffect(() => {
    if (renkItSortIt) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [renkItSortIt]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: renkItSortIt ? "flex" : "none" }}
        className="companyPop reviewImages renkItSortItPop"
      >
        <Box
          sx={{ display: renkItSortIt ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Box onClick={handelAddTaskPopBack} className="openBtn taskBackBtn">
            <img src={backArrow} />
            <Typography className="neonBackText">{t("commonTxt.backBtn")}</Typography>
          </Box>
          <Typography className="taskPopMainLabel">{t("rankItSortItPop.rankItSortItHead")}</Typography>
          <Box className="iBox">
            <img src={iIcon2} />
            <Typography>{t("rankItSortItPop.rankItSortItNotify")}</Typography>
          </Box>

          <Box className="basicBoxItem">
            <Typography className='eTemPopLabel '>{t("addTextTaskPop.taskNameLabel")}</Typography>
            <Box className="pickerBox cardTitleLabel">
              <input className='eTemInput' placeholder={t("addTextTaskPop.taskNamePlaceholder")} />
            </Box>
          </Box>

          <Box className="QaInstruction">
            <Typography className='eTemPopLabel '>{t("LiveInterview.questionLabel")}</Typography>
            <textarea placeholder={t("rankItSortItPop.instructionPlaceholder")} />
          </Box>


          <Box className='mediaInputbox upload-area' onClick={() => {
            document.getElementById("fileInput").click();
          }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}>
            <img src={uploadIcon} />
            <Typography>{t("commonTxt.uploadText")}</Typography>
            <input
              id="fileInput"
              style={{ display: "none" }}
              type="file"
              onChange={handleFileInputChange}
            />
          </Box>

          <Typography className='eTemPopLabel'>{t("rankItSortItPop.cardSettingsHead")}</Typography>
          {renderComponents(chooseCount)}

          <Box className="addCardLinkBOx">
            <Typography className="rankCardUnderl addCardLink" onClick={handelAddAttribute}>{t("workspaceHome.addCard")}</Typography>
            <Typography className="rankCardUnderl addCardLink">{t("rankItSortItPop.pasteBulkCard")}</Typography>
          </Box>


          <Typography className='eTemPopLabel'>{t("createCategoryPopup.categorySettingsHead")}</Typography>
          {renderComponents2(chooseCount2)}

          <Typography className="rankCardUnderl addCardLink catagoryAddLink" onClick={handelAddAttribute2}>{t("rankItSortItPop.addCategory")}</Typography>




          <Typography className='eTemPopLabel'>{t("addTextTaskPop.taskSettingsHead")}</Typography>
          <Box mt={2} className="tagInputBOx">
            <input
              style={{ marginRight: "10px" }}
              className="custom-checkbox"
              type="checkBox"
            />
            <Typography>{t("rankItSortItPop.taskSettingsCheckbox1")}</Typography>
          </Box>

          <Box mt={2} className="tagInputBOx">
            <input
              style={{ marginRight: "10px" }}
              className="custom-checkbox"
              type="checkBox"
            />
            <Typography>{t("rankItSortItPop.taskSettingsCheckbox2")}</Typography>
          </Box>
          <Box mb={4} mt={2} className="tagInputBOx">
            <input
              style={{ marginRight: "10px" }}
              className="custom-checkbox"
              type="checkBox"
            />
            <Typography>{t("addTextTaskPop.responseRequired")}</Typography>
          </Box>






          <Typography className='eTemPopLabel'>{t("addTextTaskPop.advanceSettingsHead")}</Typography>
          <Box mt={2} className="tagInputBOx">
            <input
              style={{ marginRight: "10px" }}
              className="custom-checkbox"
              type="checkBox"
            />
            <Typography>{t("addTextTaskPop.advanceSettingsCheckbox1")}</Typography>
          </Box>

          <Box mt={2} className="tagInputBOx">
            <input
              style={{ marginRight: "10px" }}
              className="custom-checkbox"
              type="checkBox"
            />
            <Typography>{t("addTextTaskPop.advanceSettingsCheckbox2")} </Typography>
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => setRenkItSortIt(false)} />
            <AppBtnOutLine buttonText={t("commonTxt.savePreviewBtn")} />
            <AppBtn buttonText={t("commonTxt.saveTaskBtn")} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
