import React, { useState } from 'react'
import { Box } from "@mui/material";
import ReportsAndPublication from "../../../Components/Admin/ReportsAndPublication";
import { AppBtn } from '../../../Components/AppTools/AppButton';

export default function Reports() {
    // <AppBtn
    //     onClick={() => setWorkSpacePop(true)}
    //     buttonText="Create Workspace"
    // />

    const [workspaceItem, setWorkspaceItem] = useState();
    return (
        <Box
            sx={{ display: "flex" }}
            className="adminMainBox"
        >
            <ReportsAndPublication setWorkspaceItem={setWorkspaceItem} />
        </Box>

    )
}
