import React, { useEffect, useState, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import "./style.css";

import arrow from "../../../Assets/Images/arrow.png";
import ToggleOnImage from "../../../Assets/Images/Toggle.png";
import ToggleOffImage from "../../../Assets/Images/Toggle2.png";
import DummyUser from "../../../Assets/Images/user-dummy.png";
import {
  SearchBar,
  Status,
  LatestCreated,
  GoTop,
} from "../../AppTools/AppTool";
import { Workspace } from "../../../Assets/Data";
import axios from "../../../lib/axios";

//components
import EditWorkspacePop from "../EditWorkSpace";
import WorkspaceDeletePop from "../WorkSpaceDeletePop";
import { useAppSelector } from "../../../lib/store/store";
import { updateWorkSpaceManagementApi } from "../../../Services/adminActions";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import { formatDate, viewImage } from "../../../lib/helpers";
import Pagination from "../../AppTools/Pagination";

export default function AdminAiIntegration({ setWorkspaceItem }) {
  const [statusDrop, setStatusDrop] = useState(false);
  const [statusDropVal, setStatusDropVal] = useState("All");

  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Latest Created");
  const [optionIndex, setOptionIndex] = useState();
  const [workSpaceOption, setWorkspaceOption] = useState(false);

  const [editWorkspace, setEditWorkspace] = useState(false);
  const [deleteWorkspace, setDeleteWorkspace] = useState(false);

  const [adminOptionDropIndex, setadminOptionDropIndex] = useState();
  const [adminOptionDrop, setadminOptionDrop] = useState(false);

  const [aiStatus, setAiStatus] = useState([]);
  const [settingTabe, setSettingTabe] = useState(0); //For three tabs

  const [workspaceList, setWorkspaceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const authState = useAppSelector((state) => state.auth);

  const statusDropRef = useRef(null);
  const latestDropRef = useRef(null);

  const handleOptionIndex = (i) => {
    setOptionIndex(i);
  };

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  useEffect(() => {
    // printItemsByState(Workspace, currentPage);

    // Add event listener to detect clicks outside of workSpaceOption, statusDrop, and latestCDrop
    const handleClickOutside = () => {
      // if (statusDropRef.current || latestDropRef) {
      //   setStatusDrop(false);
      //   setLatestDrop(false);
      // }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [statusDrop, latestCDrop]);

  // let newArrayData;
  // function printItemsByState(array, state) {
  //   let startIndex = state * 8;
  //   let endIndex = (state + 1) * 8;
  //   let itemsToPrint = array.slice(startIndex, endIndex);
  //   if (itemsToPrint.length > 0) {
  //     newArrayData = itemsToPrint;
  //   } else {
  //     console.log("No items to print for the given state.");
  //   }
  // }

  // let pageNumver = 0;

  // const handleNext = () => {
  //   pageNumver = pageNumver + 1;
  //   printItemsByState(Workspace, pageNumver);
  // };
  // const handlePrev = () => {
  //   if (pageNumver < 0) {
  //     pageNumver = pageNumver - 1;
  //   }
  //   printItemsByState(Workspace, pageNumver);
  // };
  // printItemsByState(Workspace, pageNumver);
  //   Handle toggle

  const handleAiStatusToggle = (wrkspaceId, index) => {
    setAiStatus((prevToggleState) => {
      let newToggleState = [...prevToggleState];
      newToggleState[index] = !newToggleState[index];
      return newToggleState;
    });
    changeStatus(wrkspaceId, { ai_enabled: !aiStatus[index] })
  };

  useEffect(() => {
    getWorkSpaceList(1)
  }, []);

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 1,
    totalPages: 0,
    totalItems: 0,
  });

  const defaultFilters = {
    search: "",
    sortBy: "created_at",
    sortOrder: -1,
    status: "",
  };

  const [filterState, setFilterState] = useState({ ...defaultFilters });

  const getWorkSpaceList = async (curPage, filters = filterState) => {
    setLoading(true);
    let url = `/super-admin/admin/workspaces?page=${curPage}`
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        url += `&${key}=${filters[key]}`
      }
    }

    let res = await axios.get(url);
    setLoading(false);
    if (res.status) {
      setWorkspaceList(res.data.data);
      // setting state for reportsPublication
      let initialAiStatus = res.data.data.map((el) => el.ai_enabled);
      setAiStatus(initialAiStatus);

      setPaginationData({
        currentPage: res.data.pagination.currentPage,
        itemsPerPage: res.data.pagination.itemsPerPage,
        totalPages: res.data.pagination.totalPages,
        totalItems: res.data.pagination.totalItems,
      });
    }
  };

  const handleFilterChange = (changedFilter) => {
    let newFilters = {
      ...filterState,
      ...changedFilter,
    };
    setFilterState(newFilters);
    getWorkSpaceList(1, newFilters);
  };

  const onPageChange = (pageNum) => {
    getWorkSpaceList(pageNum, filterState);
  };

  const changeStatus = async (wrkspaceId, payload) => {
    try {

      setLoading(true);
      let workSpaceRes = await updateWorkSpaceManagementApi(wrkspaceId, payload, authState.authToken);
      setLoading(false);
      if (workSpaceRes.success) {
        getWorkSpaceList(1);
        toast.success(workSpaceRes.message, TOAST_CONFIG);
      } else {
        toast.error(workSpaceRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  }

  return (
    <>
      <Box className="workspaceListBox aiAdminPage">
        {/* <EditWorkspacePop
          setEditWorkspace={setEditWorkspace}
          editWorkspace={editWorkspace}
        /> */}
        <WorkspaceDeletePop
          deleteWorkspace={deleteWorkspace}
          setDeleteWorkspace={setDeleteWorkspace}
        />
        <Box className="workListNav">
          <Box className="workListNavItem">
            <Typography className="pageHeaderText">
              {t("adminAiIntegration.allWorskapce")}
            </Typography>
          </Box>

          <Box className="AiAdminDropBox">
            <Box
              sx={{ minWidth: "145px" }}
              className="workListNavItem workshotList"
            >
              <Status
                ref={statusDropRef}
                setStatusDrop={setStatusDrop}
                statusDrop={statusDrop}
                setStatusDropVal={setStatusDropVal}
                handleFilterChange={handleFilterChange}
                statusDropVal={statusDropVal}
              />
            </Box>
            <Box
              sx={{ minWidth: "215px" }}
              className="workListNavItem workLatest"
            >
              <LatestCreated
                ref={latestDropRef}
                setLatestDrop={setLatestDrop}
                latestCDrop={latestCDrop}
                handleFilterChange={handleFilterChange}
                setLatestDropVal={setLatestDropVal}
                latestDropVal={latestDropVal}
              />
            </Box>
          </Box>
        </Box>
        {/* All three Tabs */}
        <Box className="workListNav workspaceSettingNav">
          <Box className="workListNavItem  adminAiInNavTextBox">
            <Box
              onClick={() => setSettingTabe(0)}
              className={
                settingTabe === 0
                  ? "workSetNavTabe workSetNavTabeActive"
                  : "workSetNavTabe"
              }
            >
              <Typography className="adminAiInNavText">
                {t("commonTxt.all")}
              </Typography>
            </Box>
            <Box
              onClick={() => setSettingTabe(1)}
              className={
                settingTabe === 1
                  ? "workSetNavTabe workSetNavTabeActive"
                  : "workSetNavTabe"
              }
            >
              <Typography className="adminAiInNavText">
                {t("adminAiIntegration.enabled")}
              </Typography>
            </Box>
            <Box
              onClick={() => setSettingTabe(2)}
              className={
                settingTabe === 2
                  ? "workSetNavTabe workSetNavTabeActive"
                  : "workSetNavTabe"
              }
            >
              <Typography className="adminAiInNavText">
                {t("adminAiIntegration.disabled")}
              </Typography>
            </Box>
          </Box>
          <Box className="workListNavItem workListSearchBar aiASearchBox">
            <SearchBar handleFilterChange={handleFilterChange} />
          </Box>
        </Box>
        {/* All three tabs */}

        <Box
          sx={{ display: settingTabe === 0 ? "flex" : "none" }}
          className="workListBox"
        >
          <Box className="haderRow" minWidth={"1036px"}>
            <Box className="Tabelsel TabelStatesel" minWidth={"25%"}>
              <Typography>{t("commonTxt.workspaces")}</Typography>
            </Box>
            <Box className="Tabelsel" minWidth={"25%"}>
              <Typography>{t("commonTxt.status")}</Typography>
            </Box>
            <Box className="Tabelsel" minWidth={"25%"}>
              <Typography>{t("adminAiIntegration.enableAi")}</Typography>
            </Box>
            <Box className="Tabelsel" minWidth={"25%"}>
              <Typography>{t("commonTxt.createdOn")}</Typography>
            </Box>
          </Box>
          {workspaceList &&
            workspaceList?.map((el, index) => (
              <Box key={index} className="TabelRow" minWidth={"1036px"}>
                <Box className="Tabelsel navFsel  navtable" minWidth={"25%"}>
                  {/* <Box
                    id="dot"
                    onClick={() => {
                      setWorkspaceOption(!workSpaceOption);
                      setOptionIndex(index);
                    }}
                    className={
                      workSpaceOption && index === optionIndex
                        ? "dot dotActive"
                        : "dot"
                    }
                  >
                    {
                      <Box
                        sx={{
                          display:
                            workSpaceOption && index === optionIndex
                              ? "flex"
                              : "none",
                        }}
                        className="optionPop"
                      >
                        <Box className="optionPopItem">
                          {editIconBlack}
                          <Typography
                            onClick={() => {
                              setEditWorkspace(true);
                              GoTop();
                            }}
                          >
                            Edit Workspace
                          </Typography>
                        </Box>
                        <Box className="optionPopItem">
                          {xCircleBlack}
                          <Typography>Close Workspace</Typography>
                        </Box>
                        <Box className="optionPopItem">
                          {archiveBlack}
                          <Typography>Archive Workspace</Typography>
                        </Box>
                        <Box
                          onClick={() => {
                            setDeleteWorkspace(true);
                            GoTop();
                          }}
                          className="optionPopItem"
                        >
                          {trashBlack}
                          <Typography>Delete Workspace</Typography>
                        </Box>
                      </Box>
                    }
                  </Box> */}
                  <Box
                    onClick={() => handleOptionIndex(index)}
                    className="tabelAvatar"
                  >
                    {(el?.cover_image == 'undefined' || el?.cover_image == null) ? <img src={DummyUser} /> : <img src={viewImage(el?.cover_image)} />}
                    {/* <img src={viewImage(el.cover_image)} /> */}
                  </Box>
                  <Typography
                    onClick={() => setWorkspaceItem(aiStatus[index])}
                    sx={{ cursor: "pointer" }}
                  >
                    {el.name}
                  </Typography>
                </Box>
                <Box className="Tabelsel TabelselItemCenter" minWidth={"25%"}>
                  <Typography>{el.status}</Typography>
                </Box>
                <Box
                  minWidth={"25%"}
                  className="Tabelsel TabelselItemCenter"
                  onClick={() => handleAiStatusToggle(el._id, index)}
                >
                  <img
                    src={aiStatus[index] ? ToggleOnImage : ToggleOffImage}
                    alt="Toggle"
                  />
                </Box>
                <Box className="Tabelsel TabelselItemCenter" minWidth={"25%"}>
                  <Typography>{formatDate(el.created_at)}</Typography>
                </Box>
              </Box>
            ))}
        </Box>
        {/*
          Enable List
          */}
        <Box
          sx={{ display: settingTabe === 1 ? "flex" : "none" }}
          className="workListBox"
        >
          <Box className="haderRow" minWidth={"1036px"}>
            <Box className="Tabelsel TabelStatesel" minWidth={"25%"}>
              <Typography>{t("commonTxt.workspaces")}</Typography>
            </Box>
            <Box className="Tabelsel" minWidth={"25%"}>
              <Typography>{t("commonTxt.status")}</Typography>
            </Box>
            <Box className="Tabelsel" minWidth={"25%"}>
              <Typography>{t("adminAiIntegration.enableAi")}</Typography>
            </Box>
            <Box className="Tabelsel" minWidth={"25%"}>
              <Typography>{t("adminAiIntegration.createdOn")}</Typography>
            </Box>
          </Box>
          {workspaceList &&
            workspaceList?.map((el, index) =>
              el.ai_enabled == true ? (
                <Box key={index} className="TabelRow" minWidth={"1036px"}>
                  <Box
                    className="Tabelsel TabelStatesel navFsel navtable"
                    minWidth={"25%"}
                  >
                    {/* <Box
                      id="dot"
                      onClick={() => {
                        setWorkspaceOption(!workSpaceOption);
                        setOptionIndex(index);
                      }}
                      className={
                        workSpaceOption && index === optionIndex
                          ? "dot dotActive"
                          : "dot"
                      }
                    >
                      {
                        <Box
                          sx={{
                            display:
                              workSpaceOption && index === optionIndex
                                ? "flex"
                                : "none",
                          }}
                          className="optionPop"
                        >
                          <Box className="optionPopItem">
                            {editIconBlack}
                            <Typography
                              onClick={() => {
                                setEditWorkspace(true);
                              }}
                            >
                              Edit Workspace
                            </Typography>
                          </Box>
                          <Box className="optionPopItem">
                            {xCircleBlack}
                            <Typography>Close Workspace</Typography>
                          </Box>
                          <Box className="optionPopItem">
                            {archiveBlack}
                            <Typography>Archive Workspace</Typography>
                          </Box>
                          <Box
                            onClick={() => {
                              setDeleteWorkspace(true);
                            }}
                            className="optionPopItem"
                          >
                            {trashBlack}
                            <Typography>Delete Workspace</Typography>
                          </Box>
                        </Box>
                      }
                    </Box> */}
                    <Box
                      onClick={() => handleOptionIndex(index)}
                      className="tabelAvatar"
                    >
                      <img src={viewImage(el.cover_image)} />
                    </Box>
                    <Typography
                      onClick={() => setWorkspaceItem(aiStatus[index])}
                      sx={{ cursor: "pointer" }}
                    >
                      {el.name}
                    </Typography>
                  </Box>
                  <Box className="Tabelsel TabelselItemCenter" minWidth={"25%"}>
                    <Typography>{el.status}</Typography>
                  </Box>
                  <Box
                    minWidth={"25%"}
                    className="Tabelsel TabelselItemCenter"
                    onClick={() => handleAiStatusToggle(el._id, index)}
                  >
                    <img
                      src={aiStatus[index] ? ToggleOnImage : ToggleOffImage}
                      alt="Toggle"
                    />
                  </Box>
                  <Box className="Tabelsel TabelselItemCenter" minWidth={"25%"}>
                    <Typography>{formatDate(el.created_at)}</Typography>
                  </Box>
                </Box>
              ) : null
            )}
        </Box>

        {/*
          Disable List
          */}

        <Box
          sx={{ display: settingTabe === 2 ? "flex" : "none" }}
          className="workListBox"
        >
          <Box className="haderRow" minWidth={"1036px"}>
            <Box className="Tabelsel TabelStatesel" minWidth={"25%"}>
              <Typography>{t("commonTxt.workspaces")}</Typography>
            </Box>
            <Box className="Tabelsel" minWidth={"25%"}>
              <Typography>{t("commonTxt.status")}</Typography>
            </Box>
            <Box className="Tabelsel" minWidth={"25%"}>
              <Typography>{t("adminAiIntegration.enableAi")}</Typography>
            </Box>
            <Box className="Tabelsel" minWidth={"25%"}>
              <Typography>{t("adminAiIntegration.createdOn")}</Typography>
            </Box>
          </Box>
          {workspaceList &&
            workspaceList?.map((el, index) =>
              el.ai_enabled == false ? (
                <Box key={index} className="TabelRow" minWidth={"1036px"}>
                  <Box className="Tabelsel navFsel navtable" minWidth={"25%"}>
                    {/* <Box
                      id="dot"
                      onClick={() => {
                        setWorkspaceOption(!workSpaceOption);
                        setOptionIndex(index);
                      }}
                      className={
                        workSpaceOption && index === optionIndex
                          ? "dot dotActive"
                          : "dot"
                      }
                    >
                      {
                        <Box
                          sx={{
                            display:
                              workSpaceOption && index === optionIndex
                                ? "flex"
                                : "none",
                          }}
                          className="optionPop"
                        >
                          <Box className="optionPopItem">
                            {editIconBlack}
                            <Typography
                              onClick={() => {
                                setEditWorkspace(true);
                              }}
                            >
                              Edit Workspace
                            </Typography>
                          </Box>
                          <Box className="optionPopItem">
                            {xCircleBlack}
                            <Typography>Close Workspace</Typography>
                          </Box>
                          <Box className="optionPopItem">
                            {archiveBlack}
                            <Typography>Archive Workspace</Typography>
                          </Box>
                          <Box
                            onClick={() => {
                              setDeleteWorkspace(true);
                            }}
                            className="optionPopItem"
                          >
                            {trashBlack}
                            <Typography>Delete Workspace</Typography>
                          </Box>
                        </Box>
                      }
                    </Box> */}
                    <Box
                      onClick={() => handleOptionIndex(index)}
                      className="tabelAvatar"
                    >
                      <img src={viewImage(el.cover_image)} />
                    </Box>
                    <Typography
                      onClick={() => setWorkspaceItem(aiStatus[index])}
                      sx={{ cursor: "pointer" }}
                    >
                      {el.name}
                    </Typography>
                  </Box>
                  <Box className="Tabelsel TabelselItemCenter" minWidth={"25%"}>
                    <Typography>{el.status}</Typography>
                  </Box>
                  <Box
                    minWidth={"25%"}
                    className="Tabelsel TabelselItemCenter"
                    onClick={() => handleAiStatusToggle(el._id, index)}
                  >
                    <img
                      src={aiStatus[index] ? ToggleOnImage : ToggleOffImage}
                      alt="Toggle"
                    />
                  </Box>
                  <Box className="Tabelsel TabelselItemCenter" minWidth={"25%"}>
                    <Typography>{formatDate(el.created_at)}</Typography>
                  </Box>
                </Box>
              ) : null
            )}
        </Box>

        <Pagination
          paginationData={paginationData}
          onPageChange={onPageChange}
        />
      </Box>
    </>
  );
}
// New Branch
