import React, { useEffect, useState } from "react";
import { Box, FormControl, MenuItem, Select, Typography, useScrollTrigger } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//images
import iIcon from "../../../Assets/Images/IIcon.png";


import groupsIcon from "../../../Assets/Images/groupsIcon.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";
import chackBox from "../../../Assets/Images/Checkbox.png";
import check from "../../../Assets/Images/check.png";

//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import ReferenceDateUsingValue from "../../AppTools/DatePicker";
import ReferenceDateExplicitTimePicker from "../../AppTools/TimePicker";
import { Status, AntSwitch, MonthStatus } from "../../AppTools/AppTool";
import { useAppSelector } from "../../../lib/store/store";
import { getDatePickerVal } from "../../../lib/helpers";
import dayjs from "dayjs";

export default function AddLiveGroupChatActivityPop({
  addLiveGroupChatActivityPop,
  setAddLiveGroupChatActivityPop,
  activePage,
  setActivePage,
  activityData,
  setActivityData,
  initialState,
  countries,
  groups,
  saveStandardActivity,
  timeZones,
  setTimeZone
}) {

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const Navigate = useNavigate();
  const authState = useAppSelector(state => state.auth);

  const [countryDrop, setCountryDrop] = useState(false);
  const [countryDropVal, setCountryDropVal] = useState("Select Country");
  const [timeZoneDrop, setTimeZoneDrop] = useState(false);
  const [timeZoneDropVal, setTimeZoneDropVal] = useState("Select Time Zone");
  const [groupDrop2, setGroupDrop2] = useState(false);
  const [chackVal, setChackVal] = useState([]);

  const [chatTab, setChatTab] = useState("Basic Settings");
  const [enableLiveVideo, setEnableLiveVideo] = useState(true);
  const [limitInviteMember, setLimitInviteMember] = useState(false);
  //const [timeZones, setTimeZone] = useState([])
  const countries1 = [
    {
      name: "USA",
      value: "USA",
    },
    {
      name: "Canada",
      value: "Canada",
    },
    {
      name: "UK",
      value: "UK",
    },
    {
      name: "Australia",
      value: "Australia",
    },
    {
      name: "Japan",
      value: "Japan",
    },
    {
      name: "Brazil",
      value: "Brazil",
    },
    {
      name: "France",
      value: "France",
    },
    {
      name: "Germany",
      value: "Germany",
    },
    {
      name: "India",
      value: "India",
    },
    {
      name: "China",
      value: "China",
    }
  ];
  const timeZones2 = [
    {
      name: "UK, timeZone: UTC",
      value: "UK, timeZone: UTC",
    },
    {
      name: "Iran, timeZone: UTC+03:30",
      value: "Iran, timeZone: UTC+03:30",
    },
    {
      name: "India, timeZone: UTC+05:30",
      value: "India, timeZone: UTC+05:30",
    },
    {
      name: "Japan, timeZone: UTC+09:00",
      value: "Japan, timeZone: UTC+09:00",
    },
    {
      name: "Alaska, timeZone: UTC-09:00",
      value: "Alaska, timeZone: UTC-09:00",
    },
    {
      name: "Brazil, timeZone: UTC-03:00",
      value: "Brazil, timeZone: UTC-03:00",
    },
    {
      name: "Nepal, timeZone: UTC+05:45",
      value: "Nepal, timeZone: UTC+05:45",
    }, {
      name: "New Zealand : UTC+12:00",
      value: "New Zealand : UTC+12:00",
    }
  ];


  const pushData = (string) => {
    setChackVal((prev) => [...prev, string]);
  };
  const deleteAllCheckData = () => setChackVal([]);
  const selectAllCheckData = () => {
    setChackVal(["Camping", "Fishing", "Kayaking", "Cycling"]);
  };

  useEffect(() => {
    if (addLiveGroupChatActivityPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [addLiveGroupChatActivityPop]);

  const saveLiveGroup = async () => {
    saveStandardActivity(true, "/activities/live-chat-scheduled")
  }

  return (
    <>
      {/* --------------------------------------------------------------Live Group Chat-------------------------------------------------- */}

      <Box sx={{ display: activePage === "Live Group Chat" ? "block" : "none" }}>
        <Typography className="companyHeader">{t("liveGroupChatPop.liveGroupChatHead")}</Typography>
        <Box className="iBox StandardiBox">
          <img src={iIcon} />
          <Typography>
            {t("liveGroupChatPop.liveGroupChatNotify")}
          </Typography>
        </Box>

        <Typography className="eTemPopLabel">{t("addActivityPop.activityNameLabel")}</Typography>
        <Box className="input_Box">
          <input
            type="text"
            className="eTemInput"
            placeholder={t("addActivityPop.activityNamePlaceholder")}
            value={activityData.name ?? ""}
            onChange={(e) => setActivityData({ ...activityData, name: e.target.value })}
          />
        </Box>

        <Box className="responsesNav">
          <Box
            className={
              chatTab === "Basic Settings"
                ? "responsesNavItem responsesNavItemActive"
                : "responsesNavItem"
            }
            onClick={() => setChatTab("Basic Settings")}
          >
            <Typography className="standardActivityTabs">{t("standardActivityPop.basicSettingsTab")}</Typography>
          </Box>
          <Box
            className={
              chatTab === "Notifications"
                ? "responsesNavItem responsesNavItemActive"
                : "responsesNavItem"
            }
            onClick={() => setChatTab("Notifications")}
          >
            <Typography className="standardActivityTabs">{t("standardActivityPop.notificationsTab")}</Typography>
          </Box>
          <Box
            className={
              chatTab === "Points Customization"
                ? "responsesNavItem responsesNavItemActive"
                : "responsesNavItem"
            }
            onClick={() => setChatTab("Points Customization")}
          >
            <Typography className="standardActivityTabs">{t("standardActivityPop.PointsCustomizationTab")}</Typography>
          </Box>
        </Box>

        {/* =====Basic Settings======= */}
        <Box
          sx={{
            display: chatTab === "Basic Settings" ? "block" : "none",
          }}
        >
          <Box className="timeInputBox">
            <Box className="timeInputItemBox">
              <Typography className="eTemPopLabel">{t("workspaceSplash.startDate")}</Typography>
              <Box className="pickerBox">
                `<ReferenceDateUsingValue value={dayjs(activityData.basic_settings.start_date)} onChange={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, start_date: getDatePickerVal(e, "date") } })} />
              </Box>
            </Box>

            <Box className="timeInputItemBox">
              <Typography className="eTemPopLabel">{t("liveGroupChatPop.startTimeLabel")}</Typography>
              <Box className="pickerBox">
                <ReferenceDateExplicitTimePicker
                  placeholder={t("addConversationTopic.timePlaceholder")}
                  value={dayjs(activityData.basic_settings.start_time, "HH:mm:ss")}
                  onChange={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, start_time: getDatePickerVal(e, "time") } })}
                />
              </Box>
            </Box>

            <Box className="timeInputItemBox">
              <Typography className="eTemPopLabel">{t("liveGroupChatPop.durationLabel")}</Typography>
              <Box className="pickerBox">
                <input
                  type="text"
                  className="eTemInput"
                  placeholder={t("liveGroupChatPop.durationPlaceholder")}
                  value={activityData.basic_settings.live_group_duration}
                  onChange={(e) => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, live_group_duration: e.target.value } })}
                />
              </Box>

            </Box>
          </Box>

          <Box className="timeInputBox">
            <Box mb={5} className="timeInputItemBox">
              <Typography className="eTemPopLabel">{t("AccountSetting.Country")}</Typography>
              <Box height={"40px"}>
                {/* <Status
                  setStatusDrop={setCountryDrop}
                  statusDrop={countryDrop}
                  setStatusDropVal={setCountryDropVal}
                  statusDropVal={countryDropVal}
                  label=" "
                  itemAry={countries}
                /> */}
                <FormControl sx={{ width: '100%' }}>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    className="statusBox MuiBox-root css-0"
                    value={activityData.basic_settings.country_id || "Select Country"}
                    onChange={(e) => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, country_id: e.target.value } })}
                  >
                    {countries.length > 0 &&
                      countries.map((el, i) => (
                        <MenuItem value={el._id} key={i} onClick={() => setTimeZone(el.timezones)}>
                          {el.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>

            <Box sx={{ display: activityData.basic_settings.country_id ? "block" : "none" }} mb={5} className="timeInputItemBox">
              <Typography className="eTemPopLabel">{t("AddProjectParticipants.tZone")}</Typography>
              <Box height={"40px"}>
                {/* <Status
                  setStatusDrop={setTimeZoneDrop}
                  statusDrop={timeZoneDrop}
                  setStatusDropVal={setTimeZoneDropVal}
                  statusDropVal={timeZoneDropVal}
                  label=" "
                  itemAry={timeZones}
`                /> */}
                <FormControl sx={{ width: '100%' }}>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    className="statusBox MuiBox-root css-0"
                    value={activityData.basic_settings.timezone || "Select Country"}
                    onChange={(e) => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, timezone: e.target.value } })}
                  >
                    {timeZones.length > 0 &&
                      timeZones.map((el, i) => (
                        <MenuItem value={el.zoneName} key={i} >
                          {el.zoneName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>

          <Box className="airadioSwitchBox" onClick={() => setEnableLiveVideo(!enableLiveVideo)}>
            <AntSwitch
              checked={activityData.basic_settings.enable_live_video}
              onClick={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, enable_live_video: e.target.checked } })}
              inputProps={{ "aria-label": "ant design" }}
            />
            <Typography className="switchText pcTopicSText">
              {t("liveGroupChatPop.liveVideoSwitchHead")}
            </Typography>
          </Box>
          <Box mt={3} sx={{ display: activityData.basic_settings.enable_live_video ? "block" : "none", width: "100%" }} ml={5}>
            <Box
              mb={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <input
                style={{ marginRight: "10px" }}
                className="custom-checkbox"
                type="checkBox"
                checked={activityData.basic_settings.start_muted_participants}
                onClick={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, start_muted_participants: e.target.checked } })}
              />
              <Typography>{t("liveGroupChatPop.liveVideoSwitch1")}</Typography>
            </Box>
            <Box
              mb={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <input
                style={{ marginRight: "10px" }}
                className="custom-checkbox"
                type="checkBox"
                checked={activityData.basic_settings.start_disabled_participants_cameras}
                onClick={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, start_disabled_participants_cameras: e.target.checked } })}
              />
              <Typography>{t("liveGroupChatPop.liveVideoSwitch2")}</Typography>
            </Box>
            <Box
              mb={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <input
                style={{ marginRight: "10px" }}
                className="custom-checkbox"
                type="checkBox"
                checked={activityData.basic_settings.revoke_camera_enable_action}
                onClick={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, revoke_camera_enable_action: e.target.checked } })}
              />
              <Typography>{t("liveGroupChatPop.liveVideoSwitch3")}</Typography>
            </Box>
            <Box
              mb={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <input
                style={{ marginRight: "10px" }}
                className="custom-checkbox"
                type="checkBox"
                checked={activityData.basic_settings.disable_backroom_audio_translator_support}
                onClick={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, disable_backroom_audio_translator_support: e.target.checked } })}
              />
              <Typography>{t("liveGroupChatPop.liveVideoSwitch4")}</Typography>
            </Box>
          </Box>
          <Box className="airadioSwitchBox" onClick={() => setLimitInviteMember(!limitInviteMember)}>
            <AntSwitch
              inputProps={{ "aria-label": "ant design" }}
              checked={activityData.basic_settings.limit_invited_members}
              onClick={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, limit_invited_members: e.target.checked } })}
            />
            <Typography className="switchText pcTopicSText">
              {t("liveGroupChatPop.inviteMemberSwitchHead")}
            </Typography>
          </Box>
          {/* limit invited members */}
          <Box
            mt={2}
            ml={5}
            sx={{ display: activityData.basic_settings.limit_invited_members ? "block" : "none" }}
          >
            <Typography className="psNlabel">{t("projectParticipantReport.participantSelectGroup")}</Typography>
            <FormControl sx={{ width: "100%" }}>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                className="statusBox MuiBox-root css-0"
                value={activityData.basic_settings.selected_groups || "Select Country"}
                onChange={(e) => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, selected_groups: e.target.value } })}
                multiple
              >
                {groups.length > 0 &&
                  groups.map((el, i) => (
                    <MenuItem value={el._id} key={i} >
                      {el.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {/* <Box pl={4.5} className="inputAppDrop">
              <Box onClick={() => setGroupDrop2(!groupDrop2)}>
                <img className="groupsIcon" src={groupsIcon} />
                <p></p>
                <Typography>{t("allParticipantPage.searchGroups")}</Typography>
              </Box>
              <img
                className="PartDropIcon"
                style={{ rotate: groupDrop2 ? "180deg" : "0deg" }}
                src={dropArrow}
                onClick={() => setGroupDrop2(!groupDrop2)}
              />

              <Box
                sx={{ display: groupDrop2 ? "block" : "none" }}
                className="statusPop"
              >
                <Box className="checkSelectorBox">
                  <span onClick={selectAllCheckData}>{t("commonTxt.selectAll")}</span>
                  <span onClick={deleteAllCheckData}>{t("commonTxt.deSelectAll")}</span>
                </Box>

                <Box
                  className="statusPopItem"
                  onClick={() => {
                    if (chackVal.includes("Camping")) {
                      chackVal.filter((item) => item !== "Camping");
                      return;
                    } else {
                      pushData("Camping");
                    }
                  }}
                >
                  <Box className="chackBox">
                    <img className="chackBoxImg" src={chackBox} />
                    <img
                      style={{
                        display: chackVal.includes("Camping")
                          ? "block"
                          : "none",
                      }}
                      className="chack"
                      src={check}
                    />
                  </Box>
                  <p>Outdoor Activity: Camping</p>
                </Box>

                <Box
                  className="statusPopItem"
                  onClick={() => {
                    if (chackVal.includes("Fishing")) {
                      chackVal.filter((item) => item !== "Fishing");
                      return;
                    } else {
                      pushData("Fishing");
                    }
                  }}
                >
                  <Box className="chackBox">
                    <img className="chackBoxImg" src={chackBox} />
                    <img
                      style={{
                        display: chackVal.includes("Fishing")
                          ? "block"
                          : "none",
                      }}
                      className="chack"
                      src={check}
                    />
                  </Box>
                  <p>Outdoor Activity: Fishing</p>
                </Box>

                <Box
                  className="statusPopItem"
                  onClick={() => {
                    if (chackVal.includes("Kayaking")) {
                      chackVal.filter((item) => item !== "Kayaking");
                      return;
                    } else {
                      pushData("Kayaking");
                    }
                  }}
                >
                  <Box className="chackBox">
                    <img className="chackBoxImg" src={chackBox} />
                    <img
                      style={{
                        display: chackVal.includes("Kayaking")
                          ? "block"
                          : "none",
                      }}
                      className="chack"
                      src={check}
                    />
                  </Box>
                  <p>Outdoor Activity: Kayaking</p>
                </Box>

                <Box
                  className="statusPopItem"
                  onClick={() => {
                    if (chackVal.includes("Cycling")) {
                      chackVal.filter((item) => item !== "Cycling");
                      return;
                    } else {
                      pushData("Cycling");
                    }
                  }}
                >
                  <Box className="chackBox">
                    <img className="chackBoxImg" src={chackBox} />
                    <img
                      style={{
                        display: chackVal.includes("Cycling")
                          ? "block"
                          : "none",
                      }}
                      className="chack"
                      src={check}
                    />
                  </Box>
                  <p>Outdoor Activity: Cycling</p>
                </Box>
              </Box>
            </Box> */}
          </Box>
          <Box className="gapBox"></Box>
          {/* limit invited members */}
          <Box className="airadioSwitchBox">
            <AntSwitch
              inputProps={{ "aria-label": "ant design" }}
              checked={activityData.basic_settings.limit_visibility}
              onClick={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, limit_visibility: e.target.checked } })}
            />
            <Typography className="switchText pcTopicSText">
              {t("liveGroupChatPop.visibilityObserverSwitchHead")}
            </Typography>
          </Box>

          <Box className="gapBox"></Box>
          <Typography className="eTemPopLabel">
            {t("commonTxt.Description")}
          </Typography>
          <Typography className="DmettingTExt">
            {t("liveGroupChatPop.descriptionText")}
          </Typography>
          <textarea
            className="DmettingTextTextAera"
            placeholder={t("liveGroupChatPop.descriptionPlaceholder")}
            value={activityData.basic_settings.description}
            onChange={e => setActivityData({ ...activityData, basic_settings: { ...activityData.basic_settings, description: e.target.value } })}
          />
          <Box className="gapBox"></Box>
        </Box>
        {/* =====Notifications======= */}
        <Box
          sx={{
            display: chatTab === "Notifications" ? "block" : "none",
          }}
        >
          <Box className="airadioSwitchBox journalActivityNotificationDayDrop">
            <AntSwitch
              inputProps={{ "aria-label": "ant design" }}
              checked={activityData.notification_settings.start_tomorrow}
              onClick={e => setActivityData({ ...activityData, notification_settings: { ...activityData.notification_settings, start_tomorrow: e.target.checked } })}
            />
            <Typography>
              {t("liveGroupChatPop.notificationsSwitch1")}
            </Typography>
          </Box>
          <Box className="airadioSwitchBox journalActivityNotificationDayDrop">
            <AntSwitch
              inputProps={{ "aria-label": "ant design" }}
              checked={activityData.notification_settings.start_soon}
              onClick={e => setActivityData({ ...activityData, notification_settings: { ...activityData.notification_settings, start_soon: e.target.checked } })}
            />
            <Typography>
              {t("liveGroupChatPop.notificationsSwitch2")}
            </Typography>
          </Box>
          <Box className="airadioSwitchBox journalActivityNotificationDayDrop">
            <AntSwitch
              inputProps={{ "aria-label": "ant design" }}
              checked={activityData.notification_settings.started}
              onClick={e => setActivityData({ ...activityData, notification_settings: { ...activityData.notification_settings, started: e.target.checked } })}
            />
            <Typography>
              {t("liveGroupChatPop.notificationsSwitch3")}
            </Typography>
          </Box>
        </Box>
        {/* =====Points Customizations======= */}
        <Box
          sx={{
            display:
              chatTab === "Points Customization" ? "block" : "none",
          }}
        >
          <Box className="airadioSwitchBox">
            <AntSwitch
              inputProps={{ "aria-label": "ant design" }}
              checked={activityData.points_customization.customize_points}
              onClick={e => setActivityData({ ...activityData, points_customization: { ...activityData.points_customization, customize_points: e.target.checked } })}
            />
            <Typography className="switchText pcTopicSText">
              {t("standardActivityPop.pointCustomizationSwitch")}
            </Typography>
          </Box>

          <Box mb={3} className="pcTopicListRow">
            <Typography>{t("liveGroupChatPop.pointCustomization1")}</Typography>
            <span>:</span>
            <input
              value={activityData.points_customization.standard_completion}
              onChange={e => setActivityData({ ...activityData, points_customization: { ...activityData.points_customization, standard_completion: e.target.value } })}
            />
            <samp>{t("commonTxt.pts.")}</samp>
          </Box>

        </Box>

        <Box className="workPupBtnBox">
          <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => setActivePage(null)} />
          <AppBtnOutLine
            buttonText={t("commonTxt.backBtn")}
            onClick={() => setActivePage(null)}
          />
          <AppBtn buttonText={t("commonTxt.continueBtn")}
            //onClick={() => Navigate("/activities/live-chat-scheduled")} 
            onClick={saveLiveGroup}
          />
        </Box>
      </Box>

    </>
  );
}
