import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import "./style.css"
//images
import iIcon2 from "../../../Assets/Images/IIcon.png";
import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png";
import backArrow from "../../../Assets/Images/noun-back.png";
import redTrash from "../../../Assets/Images/redTrash.png";

import iIcon3 from "../../../Assets/Images/IconColumnLabel.png";


export default function AddMatrixTaskPop({ setAllDropVal4, addMatrixTaskPop, setaddMatrixTaskPop, handelAddTaskPopBack }) {

  const email1 = "Type the question/instructions to be visible"

  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();
  const [image, setImage] = useState(null);
  const [checkTaskSettingCount, setCheckTaskSettingCount] = useState(true);
  const [chooseCount, setChooseCount] = useState(1);
  const [redio, setRedio] = useState(0);
  const [chooseCount2, setChooseCount2] = useState(1);
  const [checkTaskSettingPrompt, setCheckTaskSettingPrompt] = useState(true);
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
        setaddMatrixTaskPop(false);
    }
  };

  const handelCancelPop = () => {
    setaddMatrixTaskPop(false);
    setAllDropVal4("Add Tasks");
  }

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const ChooseSection = ({ count }) => {
    return (
      <>
        <p style={{ marginTop: "20px", marginBottom: "10px" }}>{t("matrixTaskPop.row")} {count * 1 + 1} {t("onDotTaskPop.label")}</p>
        <Box className="choiceInputBox">
          <input className="phoneInput" placeholder={t("matrixTaskPop.rowLabelPlaceholder")} />
          <img className="redTrash" src={redTrash} onClick={handelDeleteAttribute} />
        </Box>
      </>
    )
  }

  const ChooseSection2 = ({ count2 }) => {
    return (
      <>
          <p style={{ marginTop: "20px", marginBottom: "10px" }}>{t("recapMatrixPage.tblColumn")} {count2 * 1 + 1} {t("onDotTaskPop.label")}</p>
            <Box className="choiceInputBox">
            <input className="phoneInput" placeholder={t("matrixTaskPop.columnLabelPlaceholder")} />
            <img className="redTrash" src={redTrash} onClick={handelDeleteAttribute2} />
            </Box>
            <Box className="checkWlabel matrixCloumnLabel">
                <input className="custom-checkbox" type="checkBox" onClick={() => setCheckTaskSettingCount(!checkTaskSettingCount)} defaultChecked />
                <Typography className="assignWeightIcon">{t("matrixTaskPop.assignWeights")}</Typography> <img src={iIcon3} />
            </Box>
            <Box sx={{ display: checkTaskSettingCount ? "flex" : "none" }} ml={4} className="enforceInputBox">
                <input defaultValue="05" className="phoneInput" />
                <p>{t("matrixTaskPop.perSelection")}</p>
            </Box>
      </>
    )
  }


  const renderComponents = (value) => {
    let components = [];
    for (let i = 0; i < value; i++) {
      components.push(<ChooseSection count={i} key={i} />);
    }
    return components;
  };


  const handelDeleteAttribute = () => {
    setChooseCount(chooseCount - 1)
  }

  const handelDeleteAttribute2 = () => {
    setChooseCount2(chooseCount2 - 1)
  }

  const handelAddAttribute = () => {
    setChooseCount(chooseCount + 1)
  }

  const renderColumnComponents = (value2) => {
    let components2 = [];
    for (let j = 0; j < value2; j++) {
      components2.push(<ChooseSection2 count2={j} key={j} />);
    }
    return components2;
  };

  const handelAddColumnAttribute = () => {
    setChooseCount2(chooseCount2 + 1)
  }

  useEffect(() => {
    if (addMatrixTaskPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [addMatrixTaskPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: addMatrixTaskPop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople "
      >
        <Box
          sx={{ display: addMatrixTaskPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Box onClick={handelAddTaskPopBack} className="openBtn taskBackBtn">
            <img src={backArrow} />
            <Typography className="neonBackText">{t("commonTxt.backBtn")}</Typography>
            </Box>

          <Typography className="taskPopMainLabel">{t("matrixTaskPop.matrixTaskHead")}</Typography>
          <Box className="iBox">
              <img src={iIcon2} />
              <Typography>
              {t("matrixTaskPop.matrixTaskNotify")}
              </Typography>
            </Box>
          <Box className="basicBoxItem">
            <Typography className='eTemPopLabel '>{t("addTextTaskPop.taskNameLabel")}</Typography>
            <Box className="pickerBox cardTitleLabel">
              <input className='eTemInput' placeholder={t("addTextTaskPop.taskNamePlaceholder")} />
            </Box>
          </Box>

          <Typography className='eTemPopLabel '>{t("LiveInterview.questionLabel")}</Typography>
          <Box className="emailInputBox textTaskInstruction">
            <Box className="emailInputBoxNav">
              <Box
                onClick={() => setMessageTextItalic(!messageTextItalic)}
                className="emailImgBox"
              >
                <img src={IIcon} />
              </Box>
              <Box
                onClick={() => setMessageTextBold(!messageTextBold)}
                className="emailImgBox"
              >
                <img src={BIcon} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("underline", "message")}
                className="emailImgBox"
              >
                <img src={UnderLineU} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("line-through", "message")}
                className="emailImgBox"
              >
                <img src={MLineU} />
              </Box>
              <Box className="emailImgBox">
                <img src={AtachMentIcon} />
              </Box>
              <Box className="emailImgBox">
                <img src={Qbox} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("start")}
                className="emailImgBox"
              >
                <img src={Line1} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("center")}
                className="emailImgBox"
              >
                <img src={Line2} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("end")}
                className="emailImgBox"
              >
                <img src={Line3} />
              </Box>
              <Box className="emailImgBox">
                <img src={Line4} />
              </Box>
              <Box className="emailImgBox">
                <img src={emoji} />
              </Box>
            </Box>
            <textarea
              style={{
                textAlign: messageTextLaOut,
                fontStyle: messageTextItalic ? "italic" : "normal",
                fontWeight: messageTextBold ? "bold" : "normal",
                textDecoration: messageTextUnderLine,
                // textDecoration: messageTextMLine ? "line-through" : null
              }}
              className="emailInput"
              placeholder="Enter a discription or instructions for the card"
              name="emailMessage"
              value={emailval?.emailMessage || email1}
              onChange={handelChange}
            />
          </Box>

          <Box className="basicBoxItem">
            <Box className='mediaInputbox upload-area taskUoloadImageBox' onClick={() => {
                document.getElementById("fileInput").click();
            }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}>
                <img src={uploadIcon} />
                <Typography>{t("commonTxt.uploadText")}</Typography>
                <input
                id="fileInput"
                style={{ display: "none" }}
                type="file"
                onChange={handleFileInputChange}
                />
            </Box>
          </Box>

        {/* Poll */}
        
        <Typography className="allPartRedioLabel">{t("matrixTaskPop.rowSettings")}</Typography>
        <Typography className="bsNText">{t("matrixTaskPop.rowSettingsSubText")}</Typography>
          {renderComponents(chooseCount)}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "24px 0",
            }}
          >
            <Typography className="ppundText" onClick={handelAddAttribute}>{t("matrixTaskPop.addRow")}</Typography>
            <Typography className="ppundText">{t("matrixTaskPop.pasteBulkRows")}</Typography>
          </Box>

          <Typography className="allPartRedioLabel">{t("matrixTaskPop.columnSettings")}</Typography>
            <Typography className="bsNText">{t("matrixTaskPop.columnSettingsSubText")}</Typography>
            {renderColumnComponents(chooseCount2)}
            <Box
                sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                margin: "24px 0",
                }}
            >
                <Typography className="ppundText" onClick={handelAddColumnAttribute}>{t("matrixTaskPop.addColumn")}</Typography>
                <Typography className="ppundText">{t("matrixTaskPop.pasteBulkColumns")}</Typography>
            </Box>

          <Typography className='textTaskSetting '>{t("addTextTaskPop.taskSettingsHead")}</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("matrixTaskPop.taskSettingsCheckbox1")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("matrixTaskPop.taskSettingsCheckbox2")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("matrixTaskPop.taskSettingsCheckbox3")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("matrixTaskPop.taskSettingsCheckbox4")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("matrixTaskPop.taskSettingsCheckbox5")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("matrixTaskPop.taskSettingsCheckbox6")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("addTextTaskPop.responseRequired")}</Typography>
          </Box>

          <Typography className='textTaskSetting '>{t("pollSurveyTask.commentSettingsHead")}</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" defaultChecked />
            <Typography>{t("matrixTaskPop.commentSettingCheckbox1")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" onClick={() => setCheckTaskSettingPrompt(!checkTaskSettingPrompt)} defaultChecked />
            <Typography>{t("addProfile.customizePrmt")}</Typography>
          </Box>
          <Box sx={{ display: checkTaskSettingPrompt ? "flex" : "none" }} className="wsCpInputBOx">
            <input
              type="text"
              className="phoneInput customizePromptInput"
              placeholder={t("addTextTaskPop.customizePromptPlaceholder")}
            />
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" onClick={() => setCheckTaskSettingCount(!checkTaskSettingCount)} defaultChecked />
            <Typography>{t("addProfile.enforceWrdCnt")}</Typography>
          </Box>
          <Box sx={{ display: checkTaskSettingCount ? "flex" : "none" }} ml={4} className="enforceInputBox">
            <input defaultValue="05" className="phoneInput" />
            <p>{t("addProfile.to")}</p>
            <input defaultValue="200" className="phoneInput" />
            <p>{t("recapeVideoReview.tblTags")}</p>
          </Box>

          <Typography className='textTaskSetting '>{t("addTextTaskPop.advanceSettingsHead")}</Typography>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("addTextTaskPop.advanceSettingsCheckbox1")}</Typography>
          </Box>
          <Box className="checkWlabel">
            <input className="custom-checkbox" type="checkBox" />
            <Typography>{t("addTextTaskPop.advanceSettingsCheckbox2")} </Typography>
          </Box>
        
        {/* Poll */}
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={handelCancelPop} />
            <AppBtnOutLine buttonText={t("commonTxt.savePreviewBtn")} onClick={handelAddTaskPopBack} />
            <AppBtn buttonText={t("commonTxt.saveTaskBtn")} />
          </Box>



        </Box>
      </Box>
    </>
  );
}
