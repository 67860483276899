import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";

import "./style.css";

//images
import IIcon from "../../../Assets/Images/MailBoxIcons/symbol.png";
import BIcon from "../../../Assets/Images/MailBoxIcons/Bicon.png";
import UnderLineU from "../../../Assets/Images/MailBoxIcons/UndeLineU.png";
import MLineU from "../../../Assets/Images/MailBoxIcons/UMline.png";
import AtachMentIcon from "../../../Assets/Images/MailBoxIcons/atachment.png";
import Qbox from "../../../Assets/Images/MailBoxIcons/Sqbox.png";
import Line1 from "../../../Assets/Images/MailBoxIcons/Line1.png";
import Line2 from "../../../Assets/Images/MailBoxIcons/Line2.png";
import Line3 from "../../../Assets/Images/MailBoxIcons/Line3.png";
import Line4 from "../../../Assets/Images/MailBoxIcons/Line4.png";
import emoji from "../../../Assets/Images/MailBoxIcons/Emoji.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";
import groupsIcon from "../../../Assets/Images/groupsIcon.png";





export default function EditCardPop({ editCardPop, setEditCardPop }) {

  const email1 = "Lorem ipsum dolor sit amet consect etur. Ac quisque tristique dignissim non. Erat id ipsum sem non auctor. Elit non massa quis faucibus vel don sapien felis. Lorem ipsum dolor sit amet consect etur. Ac quisque tristique dignissim non. Erat id ipsum sem non auctor. Elit non massa quis faucibus vel don sapien felis.";

  const [emailval, setEmailval] = useState();
  const [messageTextLaOut, setMessageTextLaOut] = useState("start");
  const [messageTextItalic, setMessageTextItalic] = useState(false);
  const [messageTextBold, setMessageTextBold] = useState(false);
  const [messageTextUnderLine, setMessageTextUnderLine] = useState();
  const [redio, setRedio] = useState(0);

  const [allParticipantsDrop, setallParticipantsDrop] = useState(false);
  const [allParticipantsDropVal, setallParticipantsDropVal] = useState("Olivia Rhye");
  const allParticipantsAry = [
    "Olivia Rhye",
    "Phoenix Baker",
    "Lana Steiner",
    "Kate Morrison",
    "Orlando Diggs",
    "Candice Wu",
    "Nataliya Craig",
    "Drew Cano",
    "Andi Lane",
    "Demi Wilkinson"
  ]

  const [PointsLevelDrop, setPointsLevelDrop] = useState(false);
  const [PointsLevelDropVal, setPointsLevelDropVal] = useState();
  const PointsLevelDropary = [
    "Item 1",
    "Item 2",
    "Item 3",
    "Item 4",
  ]

  const [projectDrop, setProjectDrop] = useState(false);
  const [projectDropVal, setProjectDropVal] = useState("First Name");

  const [projectDrop2, setProjectDrop2] = useState(false);
  const [projectDropVal2, setProjectDropVal2] = useState("Large Image");

  const [projectDrop3, setProjectDrop3] = useState(false);
  const [projectDropVal3, setProjectDropVal3] = useState("All Participants");

  const [projectDrop4, setProjectDrop4] = useState(false);
  const [projectDropVal4, setProjectDropVal4] = useState("All Administrators");
  const [groupDrop, setGroupDrop] = useState(false);




  const handelChange = (e) => {
    const { name, value } = e.target;
    setEmailval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelTextlineChekc = (p, input) => {
    if (input === "message") {
      if (p === "underline") {
        if (messageTextUnderLine === "underline") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("underline");
        }
      }

      if (p === "line-through") {
        if (messageTextUnderLine === "line-through") {
          setMessageTextUnderLine(null);
          return;
        } else {
          setMessageTextUnderLine("line-through");
        }
      }
    }
  };


  const chackBoxRoom = () => {
    return (
      <Box className="checkBoxItem">
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
          />
          <Typography>Northeast Lorem</Typography>
        </Box>
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
          />
          <Typography>Southwest Ipsum</Typography>
        </Box>
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
          />
          <Typography>Northeast Lorem</Typography>
        </Box>
      </Box>
    );
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setEditCardPop(false);
    }
  };

  useEffect(() => {
    if (editCardPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [editCardPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: editCardPop ? "flex" : "none" }}
        className="companyPop editCardPop"
      >
        <Box
          sx={{ display: editCardPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className="companyHeader">Edit Card: People</Typography>

          <Box sx={{ width: "50%" }} className="basicBoxItem">
            <Typography className='eTemPopLabel '>Card Title</Typography>
            <Box className="pickerBox ">
              <input className='eTemInput' placeholder='Welcome User Name' />
            </Box>
          </Box>
          <Box mb={2} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <input style={{ marginRight: "10px" }} className="custom-checkbox" type="checkBox" />
            <Typography>Show Card Title on top of the card</Typography>
          </Box>


          <Typography className='eTemPopLabel '>Description or Instructions</Typography>
          <Box className="emailInputBox">
            <Box className="emailInputBoxNav">
              <Box
                onClick={() => setMessageTextItalic(!messageTextItalic)}
                className="emailImgBox"
              >
                <img src={IIcon} />
              </Box>
              <Box
                onClick={() => setMessageTextBold(!messageTextBold)}
                className="emailImgBox"
              >
                <img src={BIcon} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("underline", "message")}
                className="emailImgBox"
              >
                <img src={UnderLineU} />
              </Box>
              <Box
                onClick={() => handelTextlineChekc("line-through", "message")}
                className="emailImgBox"
              >
                <img src={MLineU} />
              </Box>
              <Box className="emailImgBox">
                <img src={AtachMentIcon} />
              </Box>
              <Box className="emailImgBox">
                <img src={Qbox} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("start")}
                className="emailImgBox"
              >
                <img src={Line1} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("center")}
                className="emailImgBox"
              >
                <img src={Line2} />
              </Box>
              <Box
                onClick={() => setMessageTextLaOut("end")}
                className="emailImgBox"
              >
                <img src={Line3} />
              </Box>
              <Box className="emailImgBox">
                <img src={Line4} />
              </Box>
              <Box className="emailImgBox">
                <img src={emoji} />
              </Box>
            </Box>
            <textarea
              style={{
                textAlign: messageTextLaOut,
                fontStyle: messageTextItalic ? "italic" : "normal",
                fontWeight: messageTextBold ? "bold" : "normal",
                textDecoration: messageTextUnderLine,
                // textDecoration: messageTextMLine ? "line-through" : null
              }}
              className="emailInput"
              name="emailMessage"
              value={emailval?.emailMessage || email1}
              onChange={handelChange}
            />
          </Box>

          <Box mb={2} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <input style={{ marginRight: "10px" }} className="custom-checkbox" type="checkBox" />
            <Typography>Show description/instructions below the list of people</Typography>
          </Box>
          <Typography className='eTemPopLabel'>List of people</Typography>

          <Box mt={3} className="redioItem" onClick={() => setRedio(0)}>
            <Box
              className={redio === 0 ? "customRedio customRedioActive" : "customRedio"}>
              <Box></Box>
            </Box>
            <Typography>Project Creator</Typography>
          </Box>

          <Box mt={3} className="redioItem" onClick={() => setRedio(1)}>
            <Box
              className={redio === 1 ? "customRedio customRedioActive" : "customRedio"}>
              <Box></Box>
            </Box>
            <Typography>Select Administrator</Typography>
          </Box>

          <Box mt={3} className="redioItem" onClick={() => setRedio(2)}>
            <Box
              className={redio === 2 ? "customRedio customRedioActive" : "customRedio"}>
              <Box></Box>
            </Box>
            <Typography>All Participants</Typography>
          </Box>

          <Box mt={3} className="redioItem" onClick={() => setRedio(3)}>
            <Box
              className={redio === 3 ? "customRedio customRedioActive" : "customRedio"}>
              <Box></Box>
            </Box>
            <Typography>Select Participants</Typography>
          </Box>

          <Box
            sx={{ display: redio === 3 ? "flex" : "none" }}
            mt={2}
            className="inputAppDrop"
            onClick={() => setallParticipantsDrop(!allParticipantsDrop)}
          >
            <Typography>
              {allParticipantsDropVal || "Filter by Project"}
            </Typography>
            <img
              style={{ rotate: allParticipantsDrop ? "180deg" : "0deg" }}
              src={dropArrow}
            />
            {/* pop */}
            <Box
              sx={{ height: allParticipantsDrop ? "auto" : "0px" }}
              className="statusPop">
              {allParticipantsAry?.map((el, index) => (
                <Box
                  key={index}
                  onClick={() => setallParticipantsDropVal(el)}
                  className="statusPopItem">
                  <Typography>{el}</Typography>
                </Box>
              ))}
            </Box>
          </Box>





          <Box mt={3} className="redioItem" onClick={() => setRedio(4)}>
            <Box
              className={redio === 4 ? "customRedio customRedioActive" : "customRedio"}>
              <Box></Box>
            </Box>
            <Typography>Select Groups</Typography>
          </Box>

          <Box sx={{ display: redio === 4 ? "block" : "none" }} className="searchGroupSection">
            <Box
              pl={4.5}
              className="inputAppDrop"
              onClick={() => setGroupDrop(!groupDrop)}
            >
              <img className="groupsIcon" src={groupsIcon} />
              <Typography>Search Groups</Typography>
              <img
                style={{ rotate: groupDrop ? "180deg" : "0deg" }}
                src={dropArrow}
              />
            </Box>
            <Box
              sx={{ height: groupDrop ? "auto" : "0px" }}
              className="checkMain_box"
            >
              <Box className="ckeckBoxRoom">
                {chackBoxRoom()}
                {chackBoxRoom()}
                {chackBoxRoom()}
              </Box>
              <Box className="propertyPagination">
                <Box>
                  <p>1</p>
                </Box>
                <Box>
                  <p>2</p>
                </Box>
                <Box>
                  <p>3</p>
                </Box>
                <Box>
                  <p>4</p>
                </Box>
                <Box>
                  <p>...</p>
                </Box>
                <Box>
                  <p>8</p>
                </Box>
                <Box>
                  <p>9</p>
                </Box>
                <Box>
                  <p>10</p>
                </Box>
              </Box>
            </Box>
          </Box>













          <Box mt={3} className="redioItem" onClick={() => setRedio(5)}>
            <Box
              className={redio === 5 ? "customRedio customRedioActive" : "customRedio"}>
              <Box></Box>
            </Box>
            <Typography>Points Level</Typography>
          </Box>

          <Box
            sx={{ display: redio === 5 ? "flex" : "none" }}
            mt={2}
            className="inputAppDrop"
            onClick={() => setPointsLevelDrop(!PointsLevelDrop)}
          >
            <Typography>
              {PointsLevelDropVal || "Filter by Project"}
            </Typography>
            <img
              style={{ rotate: PointsLevelDrop ? "180deg" : "0deg" }}
              src={dropArrow}
            />
            {/* pop */}
            <Box
              sx={{ height: PointsLevelDrop ? "auto" : "0px" }}
              className="statusPop">
              {PointsLevelDropary?.map((el, index) => (
                <Box
                  key={index}
                  onClick={() => setPointsLevelDropVal(el)}
                  className="statusPopItem">
                  <Typography>{el}</Typography>
                </Box>
              ))}
            </Box>
          </Box>










          <Typography mt={5} className='eTemPopLabel '>Appearance</Typography>

          <Box className="addcpDropLBox">
            <Typography sx={{ width: "65px" }} className="addcpDropLlabel">Display:</Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setProjectDrop(!projectDrop)}
            >
              <Typography>
                {projectDropVal || "Filter by Project"}
              </Typography>
              <img
                style={{ rotate: projectDrop ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: projectDrop ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setProjectDropVal("Item-A")}
                  className="statusPopItem"
                >
                  <Typography>Item-A</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal("Item-B")}
                  className="statusPopItem"
                >
                  <Typography>Item-B</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal("Item-C")}
                  className="statusPopItem"
                >
                  <Typography>Item-C</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal("Item-D")}
                  className="statusPopItem"
                >
                  <Typography>Item-D</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="addcpDropLBox">
            <Typography sx={{ width: "65px" }} className="addcpDropLlabel">Style:</Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setProjectDrop2(!projectDrop2)}
            >
              <Typography>
                {projectDropVal2 || "Filter by Project"}
              </Typography>
              <img
                style={{ rotate: projectDrop2 ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: projectDrop2 ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setProjectDropVal2("Item-A")}
                  className="statusPopItem"
                >
                  <Typography>Item-A</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal2("Item-B")}
                  className="statusPopItem"
                >
                  <Typography>Item-B</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal2("Item-C")}
                  className="statusPopItem"
                >
                  <Typography>Item-C</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal2("Item-D")}
                  className="statusPopItem"
                >
                  <Typography>Item-D</Typography>
                </Box>
              </Box>
            </Box>
          </Box>


          <Box className="addcpDropLBox">
            <Typography sx={{ width: "180px" }} className="addcpDropLlabel">No. of people per card:</Typography>
            <input style={{ width: "48px", height: "40px" }} type="text" className="phoneInput" value={"01"} />
          </Box>

          <Box mb={8} className="addcpDropLBox">
            <Typography sx={{ width: "180px" }} className="addcpDropLlabel">No. of people in total:      </Typography>
            <input style={{ width: "48px", height: "40px" }} type="text" className="phoneInput" value={"01"} />
          </Box>


          <Typography className='eTemPopLabel '>Card Visibility</Typography>

          <Box className="addcpDropLBox">
            <Typography sx={{ width: "250px" }} className="addcpDropLlabel">Participant Access:</Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setProjectDrop3(!projectDrop3)}
            >
              <Typography>
                {projectDropVal3 || "Filter by Project"}
              </Typography>
              <img
                style={{ rotate: projectDrop3 ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: projectDrop3 ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setProjectDropVal3("Item-A")}
                  className="statusPopItem"
                >
                  <Typography>Item-A</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal3("Item-B")}
                  className="statusPopItem"
                >
                  <Typography>Item-B</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal3("Item-C")}
                  className="statusPopItem"
                >
                  <Typography>Item-C</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal3("Item-D")}
                  className="statusPopItem"
                >
                  <Typography>Item-D</Typography>
                </Box>
              </Box>
            </Box>
          </Box>




          <Box className="addcpDropLBox">
            <Typography sx={{ width: "250px" }} className="addcpDropLlabel">Administrator Access:</Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setProjectDrop4(!projectDrop4)}
            >
              <Typography>
                {projectDropVal4 || "Filter by Project"}
              </Typography>
              <img
                style={{ rotate: projectDrop4 ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: projectDrop4 ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setProjectDropVal4("Item-A")}
                  className="statusPopItem"
                >
                  <Typography>Item-A</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal4("Item-B")}
                  className="statusPopItem"
                >
                  <Typography>Item-B</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal4("Item-C")}
                  className="statusPopItem"
                >
                  <Typography>Item-C</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal4("Item-D")}
                  className="statusPopItem"
                >
                  <Typography>Item-D</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={() => setEditCardPop(false)} />
            <AppBtn buttonText="Save Changes" />
          </Box>
        </Box>
      </Box>
    </>
  );
}
