import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./style.css";
//components
import { AppBtnOutLine, AppBtn } from "../../../Components/AppTools/AppButton";
import dropArrow from "../../../Assets/Images/chevron-down.png";

export default function AddExcerptPop({ addExcerptPop, setAddExcerptPop }) {

    const {
        t,
        i18n: { changeLanguage, language },
      } = useTranslation();
  const [codeDrop, setCodeDrop] = useState(false);
  const [codeDropVal, setCodeDropVal] = useState("All");
  const [codeDrop2, setCodeDrop2] = useState(false);
  const [codeDropVal2, setCodeDropVal2] = useState();

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
        setAddExcerptPop(false);
    }
  };

  useEffect(() => {
    if (addExcerptPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [addExcerptPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: addExcerptPop ? "flex" : "none" }}
        className="companyPop liveInterView"
      >
        <Box
          sx={{ display: addExcerptPop ? "flex" : "none" }}
          className="workspaceContaint addExcerptContaint"
        >
          <Typography className="livePHeader">{t("addExcerptPopup.addExcerptPopupHead")}</Typography>
          <Box className="deleteAccountBox">
                <Box className="addressBox addExcerptBox">
                  <Typography className="companyInputLabel">{t("addExcerptPopup.addExcerptPopupCodeLabel")}</Typography>
                  <Box height={"40px"}>
                    <Box
                    className="inputAppDrop participantIdentityDrop"
                    onClick={() => setCodeDrop(!codeDrop)}
                    >
                    <Typography>{codeDropVal || t("addExcerptPopup.addExcerptPopupCodePlaceholder")}</Typography>
                    <img
                    style={{ rotate: codeDrop ? "180deg" : "0deg" }}
                    src={dropArrow}
                    />
                    {/* pop */}
                        <Box
                        sx={{ height: codeDrop ? "auto" : "0px" }}
                        className="statusPop"
                        >
                            <Box
                                onClick={() => setCodeDropVal("All")}
                                className="statusPopItem"
                            >
                                <Typography>{t("addExcerptPopup.addExcerptPopupCodeVal1")}</Typography>
                            </Box>
                        </Box>
                    </Box>
                  </Box>
                  <Box height={"40px"}>
                  <Box
                    className="inputAppDrop participantIdentityDrop"
                    onClick={() => setCodeDrop2(!codeDrop2)}
                    >
                    <Typography>{codeDropVal2 || t("addExcerptPopup.addExcerptPopupCodePlaceholder")}</Typography>
                    <img
                    style={{ rotate: codeDrop2 ? "180deg" : "0deg" }}
                    src={dropArrow}
                    />
                    {/* pop */}
                        <Box
                        sx={{ height: codeDrop2 ? "auto" : "0px" }}
                        className="statusPop"
                        >
                            <Box
                                onClick={() => setCodeDropVal2("Code 1")}
                                className="statusPopItem"
                            >
                                <Typography>{t("addExcerptPopup.addExcerptPopupCodeVal2")}</Typography>
                            </Box>
                            <Box
                                onClick={() => setCodeDropVal2("Code 2")}
                                className="statusPopItem"
                            >
                                <Typography>{t("addExcerptPopup.addExcerptPopupCodeVal3")}</Typography>
                            </Box>
                        </Box>
                    </Box>
                  </Box>
                  <Box>
                  <Typography className="verifyLink">{t("addExcerptPopup.manageCodeLink")}</Typography>
                  </Box>
                </Box>

                <Box mt={4}  className="addressBox addExcerptBox">
                    <Box>
                    <Typography className="companyInputLabel">{t("addExcerptPopup.addExcerptPopupCommentLabel")}</Typography>
                        <textarea
                            className="DmettingTextTextAera"
                            placeholder={t("addExcerptPopup.addExcerptPopupCommentPlaceholder")}
                        />
                    </Box>
                    <Box className="checkPermissionSection">
                      <input className="custom-checkbox" type="checkBox" />
                      <Typography>{t("addExcerptPopup.addExcerptPopupCheckbox")}</Typography>
                    </Box>
                </Box>
                

            </Box>
          
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => setAddExcerptPop(false)} />
            <AppBtn buttonText={t("commonTxt.saveExcerptBtn")} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
