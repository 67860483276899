import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
// import "./style.css";

import MenuItem from "@mui/material/MenuItem";
import axios from "../../../lib/axios";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";

import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { getCities, getCountries, getStates } from "../../../Services/master";
import { TOAST_CONFIG } from "../../../lib/constants";
import {
  updateResearcherApi,
  uploadUserDirectoryImage,
} from "../../../Services/adminActions";
import { useAppSelector } from "../../../lib/store/store";
import { toast } from "react-toastify";
import { urlToBase64 } from "../../../lib/helpers";
import { STORAGE_BASE_PATH } from "../../../env";
import plassIcon from "../../../Assets/Images/plassIcon.png";

export default function EditResearcherPop({
  setEditResearcherPop,
  editResearcherPop,
  allResearchersData,
  setReloadList,
}) {
  let authState = useAppSelector((state) => state.auth);
  const [genderDrop, setGenderDrop] = useState(false);
  const [genderDropVal, setGenderDropVal] = useState("");

  const [countryData, setCountryData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [countryList, setCountryList] = useState(false);
  const [countryListVal, setCountryListVal] = useState("");
  const [stateList, setStateList] = useState(false);
  const [stateListVal, setStateListVal] = useState("");
  const [cityList, setCityList] = useState(false);
  const [cityListVal, setCityListVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [countryId, setCountryId] = useState("");
  const [stateId, setStateId] = useState("");
  const [cityId, setCityId] = useState("");
  const [researcherImage, setResearcherImage] = useState(null);
  const [allWorkSpaceListData, setAllWorkSpaceListData] = useState([]);

  const addWorkspaceObj = {
    workspaces: [],
  };
  const [addResearchersToWorkspace, setAddResearchersToWorkspace] = useState({
    ...addWorkspaceObj,
  });

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setEditResearcherPop(false);
    }
  };

  const handleBack = () => {
    setEditResearcherPop(false);
  };

  const handleBackAgain = () => {
    setEditResearcherPop(false);
  };

  const updateResearchers = {
    name: "",
    email: "",
    phone: "",
    gender: "",
    address_line1: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    status: "active",
    profile_pic: "",
    workspaces: [],
  };

  const [editResearchersApi, setEditResearchersApi] = useState({
    ...updateResearchers,
  });

  useEffect(() => {
    getAllCountryList();
    getResearchers();
    getWorkSpaceList();
  }, [allResearchersData]);

  const getWorkSpaceList = async () => {
    let res = await axios.get(`/super-admin/admin/workspaces`);
    if (res.status) {
      const workspaces = res.data.data;
      const allOption = { _id: "all", name: "All" };
      const workspaceListWithAll = [allOption, ...workspaces];
      setAllWorkSpaceListData(workspaceListWithAll);
    }
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "200px",
        overflowY: "auto",
      },
    },
  };

  const handleChange = (event) => {
    const selectedValue = event.target.value === "" ? 0 : event.target.value;
    setAddResearchersToWorkspace((prevGroup) => ({
      ...prevGroup,
      [event.target.name]: selectedValue,
    }));
    setEditResearchersApi((prevGroup) => ({
      ...prevGroup,
      [event.target.name]: selectedValue,
    }));
  };

  console.log("The value of selected workspace:", addResearchersToWorkspace);

  const getResearchers = async () => {
    if (allResearchersData == "") {
      return false;
    }
    return await axios
      .get(`/super-admin/admin/researchers?_id=${allResearchersData}`)
      .then((res) => {
        if (res.status) {
          let researcherData = { ...updateResearchers };
          let key;
          let allResearchersData = res?.data?.data[0];
          console.log("Value from the API:", allResearchersData);
          for (key in researcherData) {
            researcherData[key] = allResearchersData[key];
          }
          console.log("The value of allResearchersData:", allResearchersData);
          setEditResearchersApi(researcherData);
          const selectedWorkspaceIds = allResearchersData.workspaces.map(
            (workspace) => workspace._id
          );
          setAddResearchersToWorkspace((prevGroup) => ({
            ...prevGroup,
            workspaces: selectedWorkspaceIds, // Update the 'workspaces' property directly
          }));

          setGenderDropVal(allResearchersData?.gender);
          if (
            allResearchersData?.profile_pic == "undefined" ||
            allResearchersData?.profile_pic == null
          ) {
            setResearcherImage(null);
          } else {
            urlToBase64(
              STORAGE_BASE_PATH + `/` + allResearchersData?.profile_pic,
              function (base64Img) {
                setResearcherImage(base64Img);
              }
            );
          }
          if (allResearchersData?.country?.length > 0) {
            const assignedCountry = allResearchersData.country.map((item) => ({
              name: item.name,
              _id: item._id,
            }));
            if (assignedCountry.length > 0) {
              setCountryListVal(assignedCountry[0].name);
            }
            getAllStatesList(allResearchersData?.country[0]?.id);
          } else {
            setCountryListVal("");
          }
          if (allResearchersData?.state?.length > 0) {
            const assignedState = allResearchersData.state.map((item) => ({
              name: item.name,
              _id: item._id,
            }));
            if (assignedState.length > 0) {
              setStateListVal(assignedState[0].name);
            }
            getAllCitiesList(
              allResearchersData?.state[0]?.id,
              allResearchersData?.country[0]?.id
            );
          } else {
            setStateListVal("");
          }
          if (allResearchersData?.city?.length > 0) {
            const assignedCity = allResearchersData.city.map((item) => ({
              name: item.name,
              _id: item._id,
            }));
            if (assignedCity.length > 0) {
              setCityListVal(assignedCity[0].name);
            }
          } else {
            setCityListVal("");
          }
        }
      });
  };

  console.log("The value of selected editResearchersApi:", editResearchersApi);

  useEffect(() => {
    if (editResearcherPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [editResearcherPop]);

  const setResearcherFormValue = (e) => {
    let newState = {
      ...editResearchersApi,
      [e.target.name]: e.target.value,
    };
    setEditResearchersApi(newState);

    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
  };

  const getAllCountryList = () => {
    getCountries().then((res) => {
      if (res.success && res.data.data.length > 0) {
        setCountryData(res.data.data);
      }
    });
  };

  const getAllStatesList = (country_id) => {
    getStates(country_id).then((res) => {
      if (res.success && res.data.data.length > 0) {
        setStatesData(res.data.data);
      }
    });
  };

  const getAllCitiesList = (state_id, country_id) => {
    getCities(country_id, state_id).then((res) => {
      if (res.success && res.data.data.length > 0) {
        setCitiesData(res.data.data);
      }
    });
  };

  const handleCountryChange = (e) => {
    setStateListVal("");
    setCityListVal("");
    let newState = { ...editResearchersApi, country: e._id };
    setCountryId(e._id);
    setEditResearchersApi(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
    getAllStatesList(e.id);
  };

  const handleStateChange = (e) => {
    let newState = { ...editResearchersApi, state: e._id };
    setCityListVal("");
    setStateId(e._id);
    setEditResearchersApi(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
    getAllCitiesList(e.id, e.country_id);
  };

  const handleCityChange = (e) => {
    let newState = { ...editResearchersApi, city: e._id };
    setCityId(e._id);
    setEditResearchersApi(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
  };

  const validationObj = {
    hasErr: false,
    name: {
      error: false,
      msg: "",
    },
    email: {
      error: false,
      msg: "",
    },
    phone: {
      error: false,
      msg: "",
    },
    gender: {
      error: false,
      msg: "",
    },
    address_line1: {
      error: false,
      msg: "",
    },
    country: {
      error: false,
      msg: "",
    },
    state: {
      error: false,
      msg: "",
    },
    city: {
      error: false,
      msg: "",
    },
    zipcode: {
      error: false,
      msg: "",
    },
  };

  const [validationErrors, setValidationErrors] = useState({
    ...validationObj,
  });

  const handleValidation = (formInput) => {
    formInput = formInput || editResearchersApi;
    let validationerr = { ...validationObj };

    if (formInput.name == "") {
      validationerr.hasErr = true;
      validationerr.name = {
        error: true,
        msg: "Name is required",
      };
    }

    if (formInput.email == "") {
      validationerr.hasErr = true;
      validationerr.email = {
        error: true,
        msg: "Email is required",
      };
    }

    if (formInput.phone == "") {
      validationerr.hasErr = true;
      validationerr.phone = {
        error: true,
        msg: "Phone is required",
      };
    }

    if (formInput.gender == "") {
      validationerr.hasErr = true;
      validationerr.gender = {
        error: true,
        msg: "Gender is required",
      };
    }

    if (formInput.address_line1 == "") {
      validationerr.hasErr = true;
      validationerr.address_line1 = {
        error: true,
        msg: "Address is required",
      };
    }

    if (formInput.country == "") {
      validationerr.hasErr = true;
      validationerr.country = {
        error: true,
        msg: "Country is required",
      };
    }

    if (formInput.state == "") {
      validationerr.hasErr = true;
      validationerr.state = {
        error: true,
        msg: "State is required",
      };
    }

    if (formInput.city == "") {
      validationerr.hasErr = true;
      validationerr.city = {
        error: true,
        msg: "City is required",
      };
    }

    if (formInput.zipcode == "") {
      validationerr.hasErr = true;
      validationerr.zipcode = {
        error: true,
        msg: "Zipcode is required",
      };
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setResearcherImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleProfileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setResearcherImage(reader.result);
      };
      reader.readAsDataURL(file);
      updateImage(file);
    }
  };

  const updateImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      let imageUploadRes = await uploadUserDirectoryImage(
        formData,
        authState.authToken
      );
      if (imageUploadRes.success) {
        setEditResearchersApi({
          ...editResearchersApi,
          profile_pic: imageUploadRes?.data?.upload_id,
        });
        toast.success(imageUploadRes.message, TOAST_CONFIG);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  };

  let updateResearcher = async () => {
    if (!handleValidation()) {
      toast.error("Please correct form fields", TOAST_CONFIG);
      return false;
    }

    setLoading(true);
    try {
      let c_id = countryId;
      let st_id = stateId;
      let cty_id = cityId;
      if (c_id == "") {
        c_id = editResearchersApi.country[0]._id;
      }
      if (st_id == "") {
        st_id = editResearchersApi.state[0]._id;
      }
      if (cty_id == "") {
        cty_id = editResearchersApi.city[0]._id;
      }
      let requestPayload = {
        name: editResearchersApi.name,
        email: editResearchersApi.email,
        phone: editResearchersApi.phone,
        gender: editResearchersApi.gender,
        address_line1: editResearchersApi.address_line1,
        country: c_id,
        state: st_id,
        city: cty_id,
        zipcode: editResearchersApi.zipcode,
        profile_pic: editResearchersApi.profile_pic,
        status: "active",
        workspaces: editResearchersApi.workspaces,
      };

      console.log("The value of allResearchersData:", allResearchersData);

      console.log("The value of requestPayload:", requestPayload);

      let researcherRes = await updateResearcherApi(
        allResearchersData,
        requestPayload,
        authState.authToken
      );
      setLoading(false);
      if (researcherRes.success) {
        toast.success(researcherRes.message, TOAST_CONFIG);
        setEditResearchersApi({ ...updateResearchers });
        setEditResearcherPop(false);
        setReloadList(true);
      } else {
        toast.error(researcherRes.message, TOAST_CONFIG);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: editResearcherPop ? "flex" : "none" }}
        className="addResearcherPop"
      >
        <Box
          sx={{ display: editResearcherPop ? "flex" : "none" }}
          className="addResearcherContaint"
        >
          <Typography className="addResearcherHeader">
            Edit Researcher
          </Typography>

          {/* =================WorkspaceDropDown============== */}

          <FormControl sx={{ width: "100%" }} size="medium">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={addResearchersToWorkspace?.workspaces}
              onChange={handleChange}
              name="workspaces"
              multiple
              displayEmpty
              MenuProps={MenuProps}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <p>Select Workspaces</p>;
                }
                return selected
                  .map((value) => {
                    const workspace = allWorkSpaceListData.find(
                      (workspace) => workspace._id === value
                    );
                    return workspace ? workspace.name : value;
                  })
                  .join(", ");
              }}
            >
              {allWorkSpaceListData.map((workspace, index) => (
                <MenuItem value={workspace._id} key={index}>
                  {workspace.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* =================WorkspaceDropDown============== */}

          <Typography className="addResearcherLabel">
            Researcher's Full Name
          </Typography>
          <input
            name="name"
            value={editResearchersApi.name}
            onChange={setResearcherFormValue}
            className="addResearcherInput addResearcherInputName"
            placeholder="Enter the full name of the Researcher"
          />
          <span className="validationerr inputerr">
            {validationErrors.name.msg}
          </span>
          <Box className="settingBox">
            <Typography className="workspaceLabel">
              Researcher Profile image
            </Typography>

            <Box
              className="fileInputBox upload-area"
              onClick={() => {
                document.getElementById("fileInputresearcher").click();
              }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {researcherImage ? (
                <img
                  className="fileinputimg"
                  src={researcherImage}
                  alt="Upload"
                />
              ) : (
                <img src={plassIcon} alt="Upload" />
              )}
              <input
                id="fileInputresearcher"
                style={{ display: "none" }}
                type="file"
                onChange={handleProfileChange}
              />
            </Box>
            <Typography className="fileInputLabel">
              Click or drag image to this area to upload
            </Typography>
          </Box>

          <Typography className="addResearcherLabel">Email Address</Typography>
          <input
            name="email"
            value={editResearchersApi?.email}
            onChange={setResearcherFormValue}
            className="addResearcherInput addResearcherInputName"
            placeholder="Enter Researcher's valid Email Address"
          />
          <span className="validationerr inputerr">
            {validationErrors.email.msg}
          </span>

          <Box className="basicBox lastBasicBox">
            <Box className="inputBox">
              <Typography className="addResearcherLabel">
                Phone Number
              </Typography>
              <input
                name="phone"
                value={editResearchersApi?.phone}
                onChange={setResearcherFormValue}
                className="addResearcherInput"
                placeholder="Enter Researcher's valid Phone Number"
              />
              <span className="validationerr">
                {validationErrors.phone.msg}
              </span>
            </Box>
            <Box className="inputBox">
              <Typography className="addResearcherLabel">Gender</Typography>
              <input
                onClick={() => setGenderDrop(!genderDrop)}
                value={genderDropVal}
                name="gender"
                onChange={setResearcherFormValue}
                className="addResearcherInput genderInput"
                placeholder="Select Researcher Gender"
              />
              <img
                style={{ rotate: genderDrop ? "180deg" : "0deg" }}
                onClick={() => setGenderDrop(!genderDrop)}
                className="downArrow"
                src={DownArrowIcon}
              />

              <Box
                sx={{ height: genderDrop ? "auto" : "0px" }}
                className="genderDrop"
              >
                <Box
                  onClick={() => {
                    setEditResearchersApi({
                      ...editResearchersApi,
                      gender: "male",
                    });
                    setGenderDropVal("Male");
                    setGenderDrop(false);
                  }}
                  className="genderDropItem"
                >
                  <Typography>Male</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setEditResearchersApi({
                      ...editResearchersApi,
                      gender: "female",
                    });
                    setGenderDropVal("Female");
                    setGenderDrop(false);
                  }}
                  className="genderDropItem"
                >
                  <Typography>Female</Typography>
                </Box>
                <Box
                  onClick={() => {
                    setEditResearchersApi({
                      ...editResearchersApi,
                      gender: "others",
                    });
                    setGenderDropVal("Other");
                    setGenderDrop(false);
                  }}
                  className="genderDropItem"
                >
                  <Typography>Other</Typography>
                </Box>
              </Box>
              <span className="validationerr">
                {validationErrors.gender.msg}
              </span>
            </Box>
          </Box>

          <Typography className="addResearcherLabel">Address</Typography>
          <input
            name="address_line1"
            value={editResearchersApi?.address_line1}
            onChange={setResearcherFormValue}
            className="addResearcherInput addResearcherInputName"
            placeholder="Enter Researcher's valid Address"
          />
          <span className="validationerr inputerr">
            {validationErrors.address_line1.msg}
          </span>

          <Box className="basicBox lastBasicBox">
            <Box className="inputBoxOne">
              <Typography className="addResearcherLabel">Country</Typography>
              <input
                onClick={(e) => setCountryList(!countryList)}
                value={countryListVal}
                className="addResearcherInput"
                placeholder="Choose a Country"
              />
              <img
                style={{ rotate: countryList ? "180deg" : "0deg" }}
                onClick={() => setCountryList(!countryList)}
                className="downArrowPop"
                src={DownArrowIcon}
              />

              <Box
                sx={{
                  maxHeight: countryList ? "300px" : "0px",
                  overflowY: countryList ? "scroll" : "hidden",
                }}
                className="categoryDropDown"
              >
                {countryData.map((data) => (
                  <Box
                    key={data.id}
                    value={data.id}
                    className="categoryDropDownItem"
                    onClick={() => {
                      handleCountryChange(data);
                      setCountryList(false);
                      setCountryListVal(data.name);
                    }}
                  >
                    <Typography>{data.name}</Typography>
                  </Box>
                ))}
              </Box>
              <span className="validationerr">
                {validationErrors.country.msg}
              </span>
            </Box>

            <Box className="inputBoxTwo">
              <Typography className="addResearcherLabel">State</Typography>
              <input
                onClick={() => setStateList(!stateList)}
                value={stateListVal}
                className="addResearcherInput"
                placeholder="Choose a Category"
              />
              <img
                style={{ rotate: stateList ? "180deg" : "0deg" }}
                onClick={() => setStateList(!stateList)}
                className="downArrowPop"
                src={DownArrowIcon}
              />

              <Box
                sx={{
                  maxHeight: stateList ? "300px" : "0px",
                  overflowY: stateList ? "scroll" : "hidden",
                }}
                className="categoryDropDown"
              >
                {statesData.map((data) => (
                  <Box
                    key={data.id}
                    value={data.id}
                    className="categoryDropDownItem"
                    onClick={() => {
                      handleStateChange(data, data.country_id);
                      setStateList(false);
                      setStateListVal(data.name);
                    }}
                  >
                    <Typography>{data.name}</Typography>
                  </Box>
                ))}
              </Box>
              <span className="validationerr">
                {validationErrors.state.msg}
              </span>
            </Box>
          </Box>

          <Box className="basicBox lastBasicBox">
            <Box className="inputBoxOne">
              <Typography className="addResearcherLabel">City</Typography>
              <input
                onClick={() => setCityList(!cityList)}
                value={cityListVal}
                className="addResearcherInput"
                placeholder="Choose a Category"
              />
              <img
                style={{ rotate: cityList ? "180deg" : "0deg" }}
                onClick={() => setCityList(!cityList)}
                className="downArrowPop"
                src={DownArrowIcon}
              />

              <Box
                sx={{
                  maxHeight: cityList ? "300px" : "0px",
                  overflowY: cityList ? "scroll" : "hidden",
                }}
                className="categoryDropDown"
              >
                {citiesData.map((data) => (
                  <Box
                    key={data.id}
                    value={data.id}
                    className="categoryDropDownItem"
                    onClick={() => {
                      handleCityChange(data);
                      setCityList(false);
                      setCityListVal(data.name);
                    }}
                  >
                    <Typography>{data.name}</Typography>
                  </Box>
                ))}
              </Box>
              <span className="validationerr">{validationErrors.city.msg}</span>
            </Box>
            <Box className="inputBox">
              <Typography className="addResearcherLabel">Zip Code</Typography>
              <input
                name="zipcode"
                value={editResearchersApi?.zipcode}
                onChange={setResearcherFormValue}
                className="addResearcherInput"
                placeholder="Enter zip code here"
              />
              <span className="validationerr">
                {validationErrors.zipcode.msg}
              </span>
            </Box>
          </Box>
          <Box className="addResearcherPupBtnBox">
            <AppBtnOutLine
              buttonText="Cancel"
              onClick={() => setEditResearcherPop(false)}
            />
            <AppBtn buttonText="Update" onClick={() => updateResearcher()} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
