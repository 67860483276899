import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import "./style.css";

//Images
import dropArrow from "../../../Assets/Images/chevron-down.png";
import groupsIcon from "../../../Assets/Images/groupsIcon.png";
import ImportantIcon from "../../../Assets/Images/ImportantIcon.png";
import NIcon from "../../../Assets/Images/neutronIcon.png";
import userF from "../../../Assets/Images/userInF.png";
import arrow from "../../../Assets/Images/arrow.png";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";

//components 
import EmailBroadcastPop from '../../WSAdministration/EmailBroadcastPop';
import EmailPreviewPop from '../../WSAdministration/EmailPreviewPop';
import EmailSchedulePop from '../../WSAdministration/EmailSchedulePop';

import { participantsData } from '../../../Assets/Data';

import { AppBtn, AppBtnOutLine } from '../../../Components/AppTools/AppButton';
import { GoTop } from '../../AppTools/AppTool';

export default function ProjectSettingBroadcast() {

  const [nav, setNav] = useState(0);

  const [groupDrop, setGroupDrop] = useState(false);
  const [groupDropVal, setGroupDropVal] = useState();

  const [scaleDrop, setScaleDrop] = useState(false);
  const [scaleDropVal, setScaleDropVal] = useState(false);

  const [attributeDrop, setAttributeDrop] = useState(false);
  const [attributeDropVal, setAttributeDropVal] = useState(false);

  const [enhancedDrop, setEnhancedDrop] = useState(false);
  const [enhancedDropVal, setEnhancedDropVal] = useState(false);

  const [valueDrop, setValueDrop] = useState(false);
  const [valueDropVal, setValueDropVal] = useState();

  const [recipientDrop, setRecipientDrop] = useState(false);
  const [recipientDropVal, setRecipientDropVal] = useState("Participant");

  const [participantDrop, setParticipantDrop] = useState(false);
  const [participantDropVal, setParticipantDropVal] = useState();

  const [addedProjectDrop, setAddedProjectDrop] = useState(false);
  const [addedProjectDropVal, setAddedProjectDropVal] = useState();
  const [lastVisitDrop, setLastVisitDrop] = useState(false);
  const [lastVisitDropVal, setLastVisitDropVal] = useState();
  const [projectActivityDrop, setProjectActivityDrop] = useState(false);
  const [projectActivityDropVal, setProjectActivityDropVal] = useState();
  const [activityStatusDrop, setActivityStatusDrop] = useState(false);
  const [activityStatusDropVal, setActivityStatusDropVal] = useState();
  const [responseStatusDrop, setResponseStatusDrop] = useState(false);
  const [responseStatusDropVal, setResponseStatusDropVal] = useState();

  const [emailBroadcastPop, setEmailBroadcastPop] = useState(false);
  const [emailPreviewPop, setEmailPreviewPop] = useState(false);
  const [emailSchedulePop, setEmailSchedulePop] = useState(false);

  const [recipientTypeDrop, setRecipientTypeDrop] = useState();

  const [checkboxes, setCheckboxes] = useState(
    participantsData.map(() => false)
  );

  const [researcher, setResearcher] = useState(false)
  const [collaborator, setCollaborator] = useState(false)
  const [observer, setObserver] = useState(false)

  const handleCheckAllChange = (event) => {
    const isChecked = event.target.checked;
    setCheckboxes(checkboxes.map(() => isChecked));
  };

  const handleCheckboxChange = (index) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index] = !updatedCheckboxes[index];
    setCheckboxes(updatedCheckboxes);
  };

  const trueValues = checkboxes?.filter(value => value === true);
  const isOnlyOneTrue = trueValues.length === 1;

  const PropertyCheck = ({ title, setState, state }) => {
    return (
      <Box mt={2} sx={{ width: "100%" }}>
        <Typography mb={1} className="workspaceLabel">{title}</Typography>
        <Box className="inputBox" onClick={() => setState(!state)}>
          <input className="workspaceInput categoryInput" placeholder={`Select ${title}`} />
          <img className="downArrow" src={DownArrowIcon} />
          <Box className="categoryDrop" ></Box>
        </Box>
        <Box sx={{ display: state ? "flex" : "none" }} className="ckeckBoxRoom">
          {chackBoxRoom()}
          {chackBoxRoom()}
          {chackBoxRoom()}
        </Box>
        <Box sx={{ display: state ? "flex" : "none" }} className="propertyPagination">
          <Box>
            <p>1</p>
          </Box>
          <Box>
            <p>2</p>
          </Box>
          <Box>
            <p>3</p>
          </Box>
          <Box>
            <p>4</p>
          </Box>
          <Box>
            <p>...</p>
          </Box>
          <Box>
            <p>8</p>
          </Box>
          <Box>
            <p>9</p>
          </Box>
          <Box>
            <p>10</p>
          </Box>
        </Box>
      </Box>
    )
  }

  const chackBoxRoom = () => {
    return (
      <Box className="checkBoxItem">
        <Box>
          <input className="custom-checkbox" type='checkBox' defaultChecked={recipientTypeDrop === "Custom" ? false : true} />
          <Typography>Northeast Lorem</Typography>
        </Box>
        <Box>
          <input className="custom-checkbox" type='checkBox' defaultChecked={recipientTypeDrop === "Custom" ? false : true} />
          <Typography>Southwest Ipsum</Typography>
        </Box>
        <Box>
          <input className="custom-checkbox" type='checkBox' defaultChecked={recipientTypeDrop === "Custom" ? false : true} />
          <Typography>Northeast Lorem</Typography>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Box className="researchersMainBox pageContainer">
        <EmailBroadcastPop emailBroadcastPop={emailBroadcastPop} setEmailBroadcastPop={setEmailBroadcastPop} setEmailPreviewPop={setEmailPreviewPop} setEmailSchedulePop={setEmailSchedulePop} />
        <EmailPreviewPop emailPreviewPop={emailPreviewPop} setEmailPreviewPop={setEmailPreviewPop} setEmailBroadcastPop={setEmailBroadcastPop} />
        <EmailSchedulePop emailSchedulePop={emailSchedulePop} setEmailSchedulePop={setEmailSchedulePop} />
        <Box className="integrationsContainer">
          <Typography className='pageHeaderText projectBroadcastHeadText'>Email Broadcast</Typography>
          <Typography className="projectBroadcastRecipientLabel">Recipient</Typography>
          <Box mb={2} className="advanceFilterRowOne">
            <Box sx={{ width: "50%" }} className="inputBoxWlabel">
              <Box
                className="inputAppDrop"
                onClick={() => setRecipientDrop(!recipientDrop)}
              >
                <Typography>
                  {recipientDropVal || "Select Recipient"}
                </Typography>
                <img
                  style={{ rotate: recipientDrop ? "180deg" : "0deg" }}
                  src={dropArrow}
                />
                {/* pop */}
                <Box
                  sx={{ height: recipientDrop ? "auto" : "0px" }}
                  className="statusPop"
                >
                  <Box
                    onClick={() => setRecipientDropVal("Participant")}
                    className="statusPopItem"
                  >
                    <Typography>Participant</Typography>
                  </Box>
                  <Box
                    onClick={() => setRecipientDropVal("Admins")}
                    className="statusPopItem"
                  >
                    <Typography>Admins</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: recipientDropVal === "Participant" ? "block" : "none", width: "50%" }} className="inputBoxWlabel">
              <Box
                className="inputAppDrop"
                onClick={() => setParticipantDrop(!participantDrop)}
              >
                <Typography>{participantDropVal || "Select Participant"}</Typography>
                <img
                  style={{ rotate: participantDrop ? "180deg" : "0deg" }}
                  src={dropArrow}
                />
                {/* pop */}
                <Box
                  sx={{ height: participantDrop ? "auto" : "0px" }}
                  className="statusPop"
                >
                  <Box
                    onClick={() => setParticipantDropVal("All Participants (15)")}
                    className="statusPopItem"
                  >
                    <Typography>All Participants (15)</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: recipientDropVal === "Participant" ? "block" : "none" }} >
            <Typography className="emailNotificationHeaderText projectBroadcastSubHeadText">Filters</Typography>
            <Box className="allPNavTebe">
              <Box
                onClick={() => setNav(0)}
                className={
                  nav === 0
                    ? "allpNavTebeItem allpNavTebeItemActive"
                    : "allpNavTebeItem"
                }
              >
                <Typography className='eTemPopLabel'>Basic Filters</Typography>
              </Box>
              <Box
                onClick={() => setNav(1)}
                className={
                  nav === 1
                    ? "allpNavTebeItem allpNavTebeItemActive"
                    : "allpNavTebeItem"
                }
              >
                <Typography className='eTemPopLabel'>Advance Filters</Typography>
              </Box>
            </Box>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
              <Box
                sx={{ display: nav === 0 ? "block" : "none" }}
                className="basicFilterBox basicFilterBoxDifferent"
              >
                <Box className="projectBroadcasrBasicFilter">
                  <Box className="broadcastGroupProject">
                    <Box
                      sx={{ width: "100%", display: nav === 0 ? "block" : "none" }}
                      className="broadcastGroupCheckbox"
                    >
                      <Box sx={{ width: "100%" }} className="inputBoxWlabel">
                        <Typography className="inputLabel">Groups</Typography>
                        <Box
                          pl={4.5}
                          className="inputAppDrop"
                          onClick={() => setGroupDrop(!groupDrop)}
                        >
                          <img className="groupsIcon" src={groupsIcon} />
                          <Typography>{groupDropVal || "Search Groups"}</Typography>
                          <img
                            style={{ rotate: groupDrop ? "180deg" : "0deg" }}
                            src={dropArrow}
                          />
                          {/* pop */}
                          <Box
                            className="statusPop"
                            sx={{ height: groupDrop ? "auto" : "0px" }}
                          >
                            <Box
                              onClick={() => setGroupDropVal("Item-A")}
                              className="statusPopItem"
                            >
                              <Typography>Item-A</Typography>
                            </Box>
                            <Box
                              onClick={() => setGroupDropVal("Item-B")}
                              className="statusPopItem"
                            >
                              <Typography>Item-B</Typography>
                            </Box>
                            <Box
                              onClick={() => setGroupDropVal("Item-C")}
                              className="statusPopItem"
                            >
                              <Typography>Item-C</Typography>
                            </Box>
                            <Box
                              onClick={() => setGroupDropVal("Item-D")}
                              className="statusPopItem"
                            >
                              <Typography>Item-D</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box className="projectBroadcastRadio">
                        <input className="custom-checkbox" type="checkBox" defaultChecked />
                        <Typography className="emailSettingLabel">Exclude Selected Segments</Typography>
                      </Box>
                    </Box>
                    <Box
                      className="allPbasicFinputBox"
                      sx={{ display: nav === 0 ? "flex" : "none" }}
                    >
                      <Box className="inputBoxWlabel">
                        <Typography className="inputLabel">Added to Project</Typography>

                        <Box
                          className="inputAppDrop"
                          onClick={() => setAddedProjectDrop(!addedProjectDrop)}
                        >
                          <Typography>
                            {addedProjectDropVal || "Filter by Project"}
                          </Typography>
                          <img
                            style={{ rotate: addedProjectDrop ? "180deg" : "0deg" }}
                            src={dropArrow}
                          />
                          {/* pop */}
                          <Box
                            sx={{ height: addedProjectDrop ? "auto" : "0px" }}
                            className="statusPop"
                          >
                            <Box
                              onClick={() => setAddedProjectDropVal("Date Range")}
                              className="statusPopItem"
                            >
                              <Typography>Date Range</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      <Box className="inputBoxWlabel">
                        <Typography className="inputLabel">Last Visit</Typography>

                        <Box
                          className="inputAppDrop"
                          onClick={() => setLastVisitDrop(!lastVisitDrop)}
                        >
                          <Typography>
                            {lastVisitDropVal || "Filter last visit"}
                          </Typography>
                          <img
                            style={{ rotate: lastVisitDrop ? "180deg" : "0deg" }}
                            src={dropArrow}
                          />
                          {/* pop */}
                          <Box
                            sx={{ height: lastVisitDrop ? "auto" : "0px" }}
                            className="statusPop"
                          >
                            <Box
                              onClick={() => setLastVisitDropVal("Past 7 Days")}
                              className="statusPopItem"
                            >
                              <Typography>Past 7 Days</Typography>
                            </Box>
                            <Box
                              onClick={() => setLastVisitDropVal("Past 5 Days")}
                              className="statusPopItem"
                            >
                              <Typography>Past 5 Days</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: nav === 0 ? "block" : "none" }}
                    className="broadcastGroupCheckbox"
                  >
                    <Box sx={{ width: "100%" }} className="broadcastActivityCheckbox">
                      <Box sx={{ width: "50%" }} className="inputBoxWlabel">
                        <Typography className="inputLabel">Filter by Activity</Typography>

                        <Box
                          className="inputAppDrop"
                          onClick={() => setProjectActivityDrop(!projectActivityDrop)}
                        >
                          <Typography>
                            {projectActivityDropVal || "Filter by Activity"}
                          </Typography>
                          <img
                            style={{ rotate: projectActivityDrop ? "180deg" : "0deg" }}
                            src={dropArrow}
                          />
                          {/* pop */}
                          <Box
                            sx={{ height: projectActivityDrop ? "auto" : "0px" }}
                            className="statusPop"
                          >
                            <Box
                              onClick={() => setProjectActivityDropVal("Activity-1")}
                              className="statusPopItem"
                            >
                              <Typography>Activity-1</Typography>
                            </Box>
                            <Box
                              onClick={() => setProjectActivityDrop("Activity-2")}
                              className="statusPopItem"
                            >
                              <Typography>Activity-2</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box sx={{ width: "25%" }} className="inputBoxWlabel">
                        <Typography className="inputLabel">Response Status</Typography>

                        <Box
                          className="inputAppDrop"
                          onClick={() => setActivityStatusDrop(!activityStatusDrop)}
                        >
                          <Typography>
                            {activityStatusDropVal || "Filter by Status"}
                          </Typography>
                          <img
                            style={{ rotate: activityStatusDrop ? "180deg" : "0deg" }}
                            src={dropArrow}
                          />
                          {/* pop */}
                          <Box
                            sx={{ height: activityStatusDrop ? "auto" : "0px" }}
                            className="statusPop"
                          >
                            <Box
                              onClick={() => setActivityStatusDropVal("Completed")}
                              className="statusPopItem"
                            >
                              <Typography>Completed</Typography>
                            </Box>
                            <Box
                              onClick={() => setActivityStatusDropVal("In Progress")}
                              className="statusPopItem"
                            >
                              <Typography>In Progress</Typography>
                            </Box>
                            <Box
                              onClick={() => setActivityStatusDropVal("Pending")}
                              className="statusPopItem"
                            >
                              <Typography>Pending</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box sx={{ width: "25%" }} className="inputBoxWlabel">
                        <Typography className="inputLabel">Response Status</Typography>

                        <Box
                          className="inputAppDrop"
                          onClick={() => setResponseStatusDrop(!responseStatusDrop)}
                        >
                          <Typography>
                            {responseStatusDropVal || "Filter Response"}
                          </Typography>
                          <img
                            style={{ rotate: responseStatusDrop ? "180deg" : "0deg" }}
                            src={dropArrow}
                          />
                          {/* pop */}
                          <Box
                            sx={{ height: responseStatusDrop ? "auto" : "0px" }}
                            className="statusPop"
                          >
                            <Box
                              onClick={() => setResponseStatusDropVal("Date Range")}
                              className="statusPopItem"
                            >
                              <Typography>Date Range</Typography>
                            </Box>

                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="projectBroadcastRadio">
                      <input className="custom-checkbox" type="checkBox" />
                      <Typography className="emailSettingLabel">Has never completed an activity</Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className="workPupBtnBox projectBroadcastBtnBox">
                  <Typography>21 matches</Typography>
                  <AppBtnOutLine buttonText="Clear" />
                  <AppBtn buttonText="Apply" />
                </Box>
              </Box>
              {/* Fishes here */}


              {/* Advance Filters */}
              <Box
                sx={{ display: nav === 1 ? "block" : "none", width: "100%" }}
                className="advanceFiltersBox"
              >
                <Box className="instructionBar">
                  <Box>
                    <img src={ImportantIcon} />
                  </Box>
                  <Typography>
                    Advance filters can be saved as Enhanced Groups. Select an attribute or start with an existing Enhanced Group.
                  </Typography>
                </Box>
                <Box mb={2} className="advanceFilterRowOne">
                  <Box className="inputBoxWlabel">
                    <Typography className="inputLabel">Attribute: 1</Typography>
                    <Box
                      className="inputAppDrop"
                      onClick={() => setAttributeDrop(!attributeDrop)}
                    >
                      <Typography>
                        {attributeDropVal || "Select Attribute"}
                      </Typography>
                      <img
                        style={{ rotate: attributeDrop ? "180deg" : "0deg" }}
                        src={dropArrow}
                      />
                      {/* pop */}
                      <Box
                        sx={{ height: attributeDrop ? "auto" : "0px" }}
                        className="statusPop"
                      >
                        <Box
                          onClick={() => setAttributeDropVal("Item-A")}
                          className="statusPopItem"
                        >
                          <Typography>Item-A</Typography>
                        </Box>
                        <Box
                          onClick={() => setAttributeDropVal("Item-B")}
                          className="statusPopItem"
                        >
                          <Typography>Item-B</Typography>
                        </Box>
                        <Box
                          onClick={() => setAttributeDropVal("Item-C")}
                          className="statusPopItem"
                        >
                          <Typography>Item-C</Typography>
                        </Box>
                        <Box
                          onClick={() => setAttributeDropVal("Item-D")}
                          className="statusPopItem"
                        >
                          <Typography>Item-D</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box className="inputBoxWlabel">
                    <Typography className="inputLabel">Scale</Typography>

                    <Box
                      className="inputAppDrop"
                      onClick={() => setScaleDrop(!scaleDrop)}
                    >
                      <Typography>{scaleDropVal || "Select Scale"}</Typography>
                      <img
                        style={{ rotate: scaleDrop ? "180deg" : "0deg" }}
                        src={dropArrow}
                      />
                      {/* pop */}
                      <Box
                        sx={{ height: scaleDrop ? "auto" : "0px" }}
                        className="statusPop"
                      >
                        <Box
                          onClick={() => setScaleDropVal("Item-A")}
                          className="statusPopItem"
                        >
                          <Typography>Item-A</Typography>
                        </Box>
                        <Box
                          onClick={() => setScaleDropVal("Item-B")}
                          className="statusPopItem"
                        >
                          <Typography>Item-B</Typography>
                        </Box>
                        <Box
                          onClick={() => setScaleDropVal("Item-C")}
                          className="statusPopItem"
                        >
                          <Typography>Item-C</Typography>
                        </Box>
                        <Box
                          onClick={() => setScaleDropVal("Item-D")}
                          className="statusPopItem"
                        >
                          <Typography>Item-D</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box className="advancedFilterRowTwo" sx={{ width: "100%" }}>
                  <Box className="inputBoxWlabel EnhancedGroupPop">
                    <Typography className="inputLabel">Value</Typography>

                    <Box
                      className="inputAppDrop"
                      onClick={() => setValueDrop(!valueDrop)}
                    >
                      <Typography>{valueDropVal || "Select Value"}</Typography>
                      <img
                        style={{ rotate: valueDrop ? "180deg" : "0deg" }}
                        src={dropArrow}
                      />
                      {/* pop */}
                      <Box
                        sx={{ height: valueDrop ? "auto" : "0px" }}
                        className="statusPop"
                      >
                        <Box
                          onClick={() => setValueDropVal("Item-A")}
                          className="statusPopItem"
                        >
                          <Typography>Item-A</Typography>
                        </Box>
                        <Box
                          onClick={() => setValueDropVal("Item-B")}
                          className="statusPopItem"
                        >
                          <Typography>Item-B</Typography>
                        </Box>
                        <Box
                          onClick={() => setValueDropVal("Item-C")}
                          className="statusPopItem"
                        >
                          <Typography>Item-C</Typography>
                        </Box>
                        <Box
                          onClick={() => setValueDropVal("Item-D")}
                          className="statusPopItem"
                        >
                          <Typography>Item-D</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="straightLine">
                  <hr />
                  <Typography>OR</Typography>
                  <hr />
                </Box>
                <Box className="advancedFilterRowThree">
                  <Box className="inputBoxWlabel EnhancedGroupPop">
                    <Typography className="inputLabel">
                      Enhanced Group
                    </Typography>

                    <Box
                      pl={4.5}
                      className="inputAppDrop"
                      onClick={() => setEnhancedDrop(!enhancedDrop)}
                    >
                      <img className="groupsIcon" src={groupsIcon} />
                      <Typography>
                        {enhancedDropVal || "Search Groups"}
                      </Typography>
                      <img
                        style={{ rotate: enhancedDrop ? "180deg" : "0deg" }}
                        src={dropArrow}
                      />
                      {/* pop */}
                      <Box
                        sx={{ height: enhancedDrop ? "auto" : "0px" }}
                        className="statusPop"
                      >
                        <Box
                          onClick={() => setEnhancedDropVal("Item-A")}
                          className="statusPopItem"
                        >
                          <Typography>Item-A</Typography>
                        </Box>
                        <Box
                          onClick={() => setEnhancedDropVal("Item-B")}
                          className="statusPopItem"
                        >
                          <Typography>Item-B</Typography>
                        </Box>
                        <Box
                          onClick={() => setEnhancedDropVal("Item-C")}
                          className="statusPopItem"
                        >
                          <Typography>Item-C</Typography>
                        </Box>
                        <Box
                          onClick={() => setEnhancedDropVal("Item-D")}
                          className="statusPopItem"
                        >
                          <Typography>Item-D</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="allPNav">
              <Box>
                <Typography className="emailNotificationHeaderText projectBroadcastSubHeadText">
                  All Participants
                </Typography>
              </Box>
            </Box>
            {/* table */}
            <Box className="workListBox">
              <Box className="haderRow" minWidth={"1500px"}>
                <Box
                  minWidth={"290px"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0px",
                    paddingLeft: "11px",
                  }}
                  className="Tabelsel navFsel allpHTI participantCheckboxColor"
                >
                  <input
                    className="AllPTablechackBox"
                    type="checkbox"
                    onChange={handleCheckAllChange}
                  />

                  <Typography>User Name</Typography>
                </Box>
                <Box minWidth={"230px"} className="Tabelsel tableItemSel">
                  <Typography>Email address</Typography>
                </Box>
                <Box minWidth={"230px"} className="Tabelsel tableItemSel">
                  <Typography>Added Updated</Typography>
                </Box>
                <Box minWidth={"230px"} className="Tabelsel tableItemSel">
                  <Typography>Last Visit</Typography>
                </Box>
                <Box minWidth={"230px"} className="Tabelsel tableItemSel">
                  <img src={userF} />
                </Box>
                <Box minWidth={"230px"} className="Tabelsel tableItemSel">
                  <img src={NIcon} />
                </Box>
              </Box>

              {participantsData &&
                participantsData.map((el, index) => (
                  <Box className="TabelRow" minWidth={"1500px"}>
                    <Box
                      sx={{ position: "relative" }}
                      className="Tabelsel navFsel participantCheckboxColor"
                      minWidth={"290px"}
                    >
                      <input
                        className="AllPTablechackBox"
                        type="checkbox"
                        checked={checkboxes[index]}
                        onChange={() => handleCheckboxChange(index)}
                      />
                      <Box className="tabelAvatar">
                        <img src={el.img} />
                      </Box>
                      <Typography sx={{ cursor: "pointer" }}>
                        {el.title}
                        <br />
                        <span className="participantsTableText">
                          {el.subTitle}
                        </span>
                      </Typography>
                      <Box
                        sx={{ background: el.active ? "#12B76A" : "#F04438" }}
                        className="ActiveStatus"
                      ></Box>
                    </Box>
                    <Box
                      minWidth={"230px"}
                      className="Tabelsel TabelselItemCenter"
                    >
                      <Typography className="participantsTableText">
                        {el.email}
                      </Typography>
                    </Box>
                    <Box
                      className="Tabelsel tableItemSel TabelselItemCenter"
                      minWidth={"230px"}
                    >
                      <Typography className="participantsTableText">
                        {el.update}
                      </Typography>
                    </Box>
                    <Box
                      className="Tabelsel tableItemSel TabelselItemCenter"
                      minWidth={"230px"}
                    >
                      <Typography className="participantsTableText">
                        {el.lastVisit}
                      </Typography>
                    </Box>
                    <Box
                      className="Tabelsel tableItemSel TabelselItemCenter"
                      minWidth={"230px"}
                    >
                      <Typography ml={1} className="participantsTableText">
                        {el.userCoutn}
                      </Typography>
                    </Box>
                    <Box
                      className="Tabelsel tableItemSel TabelselItemCenter"
                      minWidth={"230px"}
                    >
                      <Typography ml={1} className="participantsTableText">
                        {el.nCoutn}
                      </Typography>
                    </Box>
                  </Box>
                ))}
            </Box>
            {/* table */}
            {/* pagination */}
            <Box className="pagenation">
              <Box className="tabelBtn">
                <img src={arrow} />
                <Typography>Previous</Typography>
              </Box>

              <Box className="pageNumberBox">
                <Box className="pageNumber pageNumberActive"><p>1</p></Box>
                <Box className="pageNumber"><p>2</p></Box>
                <Box className="pageNumber"><p>3</p></Box>
                <Box className="pageNumber"><p>4</p></Box>
                <Box className="pageNumber"><p>5</p></Box>
              </Box>
              <Box className="tabelBtn">
                <Typography>Next</Typography>
                <img style={{ rotate: "180deg" }} src={arrow} />
              </Box>
            </Box>
            {/* pagination */}
            {/* Send email button */}
            <Box className="workPupBtnBox">
              <AppBtnOutLine buttonText="Cancel" />
              <AppBtn
                buttonText="Send Email"
                onClick={() => {
                  setEmailBroadcastPop(true);
                  GoTop();
                }}
              />
            </Box>
            {/* send email button */}
          </Box>

          <Box sx={{ display: recipientDropVal === "Admins" ? "block" : "none" }}>

          </Box>
          <Box sx={{ display: recipientDropVal === "Admins" ? "block" : "none" }}>
            <Typography className='emailNotificationHeaderText projectBroadcastSubHeadText'>Filter</Typography>

            <PropertyCheck title="Researcher" setState={setResearcher} state={researcher} />
            <PropertyCheck title="Collaborator" setState={setCollaborator} state={collaborator} />
            <PropertyCheck title="Observer" setState={setObserver} state={observer} />

            <Box className="workPupBtnBox">
              <AppBtnOutLine buttonText="Cancel" />
              <AppBtn
                onClick={() => {
                  setEmailBroadcastPop(true);
                  GoTop();
                }}
                buttonText="Send Email" />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
