import React, { useState, useEffect } from 'react'
import { Box, Typography } from "@mui/material";
import "./style.css";

import SideMenu from "../../../Components/Participants/SideMenu";
import { sideHaumIcon, activeParticipants } from "../../../Assets/Data";
import { useNavigate } from "react-router-dom";


//images
import iIcon from "../../../Assets/Images/IIcon.png";
import mettingImg from "../../../Assets/Images/mettingImg.png";
import backArrow from "../../../Assets/Images/noun-back.png";


//comonents
import { useTranslation } from "react-i18next";
import { AppBtn } from "../../../Components/AppTools/AppButton";
import { CalendarDropBtn } from "../../../Components/AppTools/AppTool";
import UpdateTimeZonePop from '../../../Components/Researchers/UpdateTimeZonePop';
import EditMeetingPop from '../../../Components/Researchers/EditMeetingPop';
import LiveGroupChatStartPop from '../../../Components/Participants/LiveGroupChatStartPop';
import SystemCheckPop from '../../../Components/Researchers/SystemCheckPop';



export default function LiveChatScheduled({ slideMenu, setSlideMenu, workspaceDrop, setWorkSpaceDrop }) {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [updateTimeZonePop, setUpdateTimeZonePop] = useState(false);
  const [editMeetingPop, setEditMeetingPop] = useState(false);
  const [liveGroupChatStartPop, setLiveGroupChatStartPop] = useState(false);
  const [calendarDrop, setCalendarDrop] = useState();
  const [calendarDropVal, setCalendarDropVal] = useState("Add to Calendar");
  const [newArrayData, setNewArrayData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [systemCheckPop, setSystemCheckPop] = useState(false);

  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  useEffect(() => {
    printItemsByState(activeParticipants, currentPage);
  }, [currentPage]);


  return (
    <>
      <Box className="ResearchersContainer LiveChatRecap LiveChatSheduled">
        <LiveGroupChatStartPop liveGroupChatStartPop={liveGroupChatStartPop} setLiveGroupChatStartPop={setLiveGroupChatStartPop} />
        <UpdateTimeZonePop updateTimeZonePop={updateTimeZonePop} setUpdateTimeZonePop={setUpdateTimeZonePop} />
        <EditMeetingPop editMeetingPop={editMeetingPop} setEditMeetingPop={setEditMeetingPop} />
        <SystemCheckPop systemCheckPop={systemCheckPop} setSystemCheckPop={setSystemCheckPop} />
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
            <AppBtn buttonText="System Check" onClick={() => setSystemCheckPop(true)} />
          </Box>
          {/* Page container */}
          <Box className="researchersMainBox pageContainer interviewSchedule">
            <Box className="NotificationSection">
              <Box mb={3} width="74px" onClick={() => Navigate("/participants/activities")} className="openBtn">
                <img src={backArrow} />
                <Typography className="neonBackText">Back</Typography>
              </Box>
              <Typography className='EAHeaderText'>{t("LiveInterview.LiveGroupChat")}</Typography>
              <Box className="iBox chatScheduleNotify">
                <img src={iIcon} />
                <Typography>{t("LiveInterview.ActivityScheduled")}</Typography>
              </Box>
              <Box className="schedule_Section_box">
                <Box className="nextMettingBox">
                  <Box className="mettingImgBox"><img src={mettingImg} /></Box>
                  <Box className="mettingTextBox">
                    <Typography className='mettingHeaderText'>{t("LiveInterview.SampleActivity")}</Typography>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.ScheduledDate")} :
                      <span> Jul 18, 2023</span>
                    </Typography>
                    <Typography className='mettingLabelText'>
                      {t("LiveInterview.ScheduledTime")} :
                      <span>  11:20 AM</span>
                    </Typography>
                    <Box className="chatBtnBox">
                      <Box sx={{ width: "30%", minWidth: "196px" }}>
                        <CalendarDropBtn
                          drop={calendarDrop}
                          setDrop={setCalendarDrop}
                          dropVal={calendarDropVal}
                          serDropVal={setCalendarDropVal} />
                      </Box>
                      <AppBtn buttonText="Start Meeting" onClick={() => setLiveGroupChatStartPop(true)} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
