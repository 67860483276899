import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { TOAST_CONFIG } from "../../../lib/constants";
import { toast } from "react-toastify";
import { deleteActivities } from "../../../Services/adminActions";
import { useAppSelector } from "../../../lib/store/store";
//images

export default function DeleteActivityPop({ deleteActivityPop, setDeleteActivityPop, setReloadList, activityId, activityName }) {

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const [loading, setLoading] = useState(false);
  let authState = useAppSelector(state => state.auth);
 
  const handelPopClose = () => {
    setDeleteActivityPop(false);
  }
  

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await deleteActivities(activityId, authState.authToken);
      setLoading(false);
      if (response.success) {
        setDeleteActivityPop(false);
        toast.success(response.message, TOAST_CONFIG);
        if (typeof setReloadList !== 'undefined') {
          setReloadList(true);
        }
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (deleteActivityPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [deleteActivityPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: deleteActivityPop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople"
        >
        <Box
            sx={{ display: deleteActivityPop ? "flex" : "none" }}
            className="workspaceContaint deletePagePop"
        >
            <Typography className="companyHeader">Delete Activity</Typography>

            <Box className="basicBoxItem">
            <Typography className='addPageLabel'>Are you sure you want to delete the activity titled {`“${activityName}”`} ? Once deleted this activity will not be visible to anybody.</Typography>
            
            </Box>
            
            <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => setDeleteActivityPop(false)} />
            <AppBtn buttonText={t("commonTxt.deleteBtn")} onClick={handleDelete} />
            </Box>
        </Box>
        </Box>
    </>
  );
}
