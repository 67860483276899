import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { useNavigate } from "react-router-dom";

//images
import arrow from "../../../Assets/Images/arrow.png";
import trash from "../../../Assets/Images/redTrash.png"
import backArrow from "../../../Assets/Images/noun-back.png";
import cott from "../../../Assets/Images/cott.png";
import iIcon from "../../../Assets/Images/IIcon.png";

//component
import SideMenu from "../../../Components/Participants/SideMenu";
import { topicsData, sideHaumIcon, arrowIcon } from "../../../Assets/Data";
import { SearchBar, LatestCreated } from "../../../Components/AppTools/AppTool";
import AddConversationTopicPop from '../../../Components/Researchers/AddConversationTopicPop';
import DeleteConversationCategoryPop from '../../../Components/Researchers/DeleteConversationCategoryPop';
import CreateConversationCategoryPop from '../../../Components/Researchers/CreateConversationCategoryPop';
import { t } from 'i18next';


export default function ViewConversationCategory({ slideMenu, setSlideMenu, workspaceDrop, setWorkSpaceDrop }) {
  const Navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [newArrayData, setNewArrayData] = useState([]);
  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Recently Added");
  const [addConversationTopicPop, setAddConversationTopicPop] = useState(false);
  const [deleteConversationCategoryPop, setDeleteConversationCategoryPop] = useState(false);
  const [createConversationCategoryPop, setCreateConversationCategoryPop] = useState(false);

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    printItemsByState(topicsData, currentPage);
  }, [currentPage]);

  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  return (
    <>
      <Box className="ResearchersContainer ">
        {/* side menu */}
        <SideMenu slideMenu={slideMenu} setSlideMenu={setSlideMenu} workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop} />
        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon">
              {sideHaumIcon}
            </Box>

          </Box>
          {/* Page container */}
          <Box className="pageContainer">
            <Box className="workSpaceListBox  viewTopicBox">
              <Box className="openBtn">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  onClick={() => Navigate("/participants/conversations")}
                >
                  <img src={backArrow} />
                  <Typography className="neonBackText">{t("commonTxt.backBtn")}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "24px",
                  }}
                >
                  <Box className="actTNavBtn">
                    <img src={cott} />
                    <Typography>{t("Conversations.Excerpts")}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="tpcHeaderBox">
                <Box className="">
                  <Box className="pageTitleBox">
                    <Typography>{t("Conversations.EquipmentZone")}</Typography>
                  </Box>
                  <Typography>({t("Conversations.TotalTopics")}: 05)</Typography>
                </Box>
              </Box>
              <Typography className='viewTopicNtext'>{t("Conversations.summery1")}</Typography>
              <Typography className='viewTopicNtext'>{t("Conversations.summery2")}</Typography>
              <Box className="iBox">
                <img src={iIcon} />
                <Typography>{t("Conversations.summery3")}</Typography>
              </Box>
            </Box>
          </Box>
          <Box className="researchersMainBox conversations">
            <AddConversationTopicPop addConversationTopicPop={addConversationTopicPop} setAddConversationTopicPop={setAddConversationTopicPop} />
            <DeleteConversationCategoryPop deleteConversationCategoryPop={deleteConversationCategoryPop} setDeleteConversationCategoryPop={setDeleteConversationCategoryPop} />
            <CreateConversationCategoryPop createConversationCategoryPop={createConversationCategoryPop} setCreateConversationCategoryPop={setCreateConversationCategoryPop} />

            <Box mt={8} className="ActiveTaskSectionBox">
              <Box mt={2} className="workListNavItem workListNavItemMobile">
                <Typography className="pageHeaderText">{t("projectConversationReport.tblTopic")} (5)</Typography>
              </Box>
              <Box mt={2} px={3} className="workListNav">
                <Box className="workListNavItem workListNavItemDesktop">
                  <Typography className="pageHeaderText">{t("projectConversationReport.tblTopic")} (5)</Typography>
                </Box>
                <Box sx={{ minWidth: "418px", display: "flex", gap: "20px" }} className="workListNavItem workListSearchBar">
                  <SearchBar placeholder="Search here" />

                  <Box minWidth={"211px"} className="agreSearchB2" >
                    <LatestCreated
                      setLatestDrop={setLatestDrop}
                      latestCDrop={latestCDrop}
                      setLatestDropVal={setLatestDropVal}
                      latestDropVal={latestDropVal}
                    />
                  </Box>
                </Box>
              </Box>

              <Box className="workListBox">
                <Box className="haderRow" minWidth={"1111px"}>
                  <Box minWidth={"7%"} className="Tabelsel TabelStatesel" >
                  </Box>
                  <Box minWidth={"39%"} className="Tabelsel TabelStatesel" >
                    <Typography>{t("Conversations.TopicName")}</Typography>
                  </Box>
                  <Box minWidth={"19%"} className="Tabelsel TabelStatesel" >
                    <Typography>{t("Conversations.Category")}</Typography>
                  </Box>
                  <Box minWidth={"9%"} className="Tabelsel">
                    <Typography></Typography>
                  </Box>

                  <Box minWidth={"10%"} className="Tabelsel">
                    <Typography></Typography>
                  </Box>

                </Box>
                {newArrayData &&
                  newArrayData?.map((el, index) => (
                    <Box key={index} className="TabelRow" minWidth={"1111px"}>
                      <Box minWidth={"7%"} className="Tabelsel">
                        <img src={el.star} className='tabelStarIcon' />
                      </Box>
                      <Box minWidth={"39%"} className="Tabelsel navFsel navtable">
                        <Typography sx={{ cursor: "pointer", width: "95%" }}>
                          {el.title}
                        </Typography>
                      </Box>
                      <Box minWidth={"19%"} className="Tabelsel TabelStatesel">
                        <Typography>{el.Category}</Typography>
                      </Box>
                      <Box minWidth={"9%"} className="Tabelsel Tabelcentersel">
                        <img src={el.dot} />
                      </Box>

                      <Box className="Tabelsel tableBtnBox" minWidth={"10%"}>
                        <Box className="viewBtn">
                          <p>{t("commonTxt.viewBtn")}</p>
                        </Box>
                        <Box className="trashBox" onClick={() => setDeleteConversationCategoryPop(true)} >
                          <img src={trash} />
                        </Box>
                      </Box>
                    </Box>
                  ))}
              </Box>

              <Box className="pagenation">
                <Box onClick={handlePrev} className="tabelBtn">
                  <img src={arrow} />
                  <Typography>{t("commonTxt.previouspage")}</Typography>
                </Box>

                <Box className="pageNumberBox">
                  <Box
                    onClick={() => setCurrentPage(0)}
                    className={
                      currentPage === 0 ? "pageNumber pageNumberActive" : "pageNumber"
                    }
                  >
                    <p>1</p>
                  </Box>
                  <Box
                    onClick={() => setCurrentPage(1)}
                    className={
                      currentPage === 1 ? "pageNumber pageNumberActive" : "pageNumber"
                    }
                  >
                    <p>2</p>
                  </Box>
                  <Box
                    onClick={() => setCurrentPage(2)}
                    className={
                      currentPage === 2 ? "pageNumber pageNumberActive" : "pageNumber"
                    }
                  >
                    <p>3</p>
                  </Box>
                  <Box
                    onClick={() => setCurrentPage(3)}
                    className={
                      currentPage === 3 ? "pageNumber pageNumberActive" : "pageNumber"
                    }
                  >
                    <p>4</p>
                  </Box>
                  <Box
                    onClick={() => setCurrentPage(4)}
                    className={
                      currentPage === 4 ? "pageNumber pageNumberActive" : "pageNumber"
                    }
                  >
                    <p>5</p>
                  </Box>
                </Box>

                <Box onClick={handleNext} className="tabelBtn">
                  <Typography>{t("commonTxt.nextPage")}</Typography>
                  <img style={{ rotate: "180deg" }} src={arrow} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box >

    </>
  )
}
