import React, { useState } from "react";
import "./style.css";
import { Box, Typography } from "@mui/material";

//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";

export default function CustomGroupPop({
  customGroupPop,
  setCustomGroupPop,
  setBroadcastMailPop,
}) {
  const [customConfrimationPop, setCustomConfimationPop] = useState(false);

  const handelNextCustomPop = () => {
    setCustomConfimationPop(true);
    setCustomGroupPop(false);
  };

  const handelNextPop = () => {
    setBroadcastMailPop(true);
    setCustomConfimationPop(false);

    setCustomGroupPop(false);
  };
  return (
    <>
      <Box
        id="popBackdrop"
        sx={{ display: customGroupPop ? "flex" : "none" }}
        className="workspaceDeletePopContainer customGroupContainer"
      >
        <Box
          className="workspaceDeleteBox"
          sx={{ width: "540px", height: "190px" }}
        >
          <Typography className="deletePopHeader">
            Do you want to create as a group?
          </Typography>

          <Box className="deletPopBtnBox customPopLoginBtn">
            <AppBtnOutLine onClick={() => handelNextPop()} buttonText="No" />
            <AppBtn buttonText="Yes" onClick={() => handelNextCustomPop()} />
          </Box>
        </Box>
      </Box>

      <Box
        id="popBackdrop"
        sx={{ display: customConfrimationPop ? "flex" : "none" }}
        className="workspaceDeletePopContainer customGroupContainer"
      >
        <Box
          className="workspaceDeleteBox"
          sx={{ width: "540px", height: "235px" }}
        >
          <Box className="customNameLabel">
            <Typography className="deletePopHeader">
              Enter Your Group Name Below
            </Typography>
          </Box>
          <Box className="customNameInput">
            <input type="text" placeholder="Enter Your Group Name Below" />
          </Box>

          <Box className="deletPopBtnBox">
            <AppBtnOutLine
              onClick={() => handelNextPop()}
              buttonText="Cancel"
            />
            <AppBtn buttonText="Save" onClick={() => handelNextPop()} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
