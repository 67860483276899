import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import "./style.css";
import { TimeStatus, TimeStatus2, TimeStatus3 } from "../../../Components/AppTools/AppTool"


import { sideHaumIcon } from "../../../Assets/Data";

//component
import SideMenu from "../../../Components/User/SideMenu"
import { useAppSelector } from '../../../lib/store/store';
import { renderUserLayout } from '../../../lib/helpers';
import axios from '../../../lib/axios';
import { researcherAction } from '../../../Services/researcherActions';
import { toast } from 'react-toastify';
import { TOAST_CONFIG } from '../../../env';
import { AppBtn } from '../../../Components/AppTools/AppButton';


export default function EmailNotification({ slideMenu, setSlideMenu, workspaceDrop, setWorkSpaceDrop }) {
  const authState = useAppSelector((state) => state.auth);
  const [isRender, setIsRender] = useState(false);
  const [statusTimeDropVal, setStatusTimeDropVal] = useState("10:00 A.M");
  const [statusTimeDrop2, setStatusTimeDrop2] = useState(false);
  const [statusTimeDropVal2, setStatusTimeDropVal2] = useState("04:00 P.M");

  const [statusTimeDrop3, setStatusTimeDrop3] = useState(false);
  const [statusTimeDropVal3, setStatusTimeDropVal3] = useState("08:00 P.M");
  const [dailyStatusTime, setDailyStatusTime] = useState(false)
  const [notifyTimes, setNotifyTimes] = useState([1]);
  const [notificationSetting, setNotificationSetting] = useState({
    type: "",
    settings: [""]
  });

  useEffect(() => {
    fetchUserNotification();
  }, [])

  const fetchUserNotification = async () => {
    try {
      const response = await axios.get(`researchers/notification-settings?user_id=${authState.userProfile._id}`);
      const notifyData = response?.data?.data[0];
      console.log(notifyData)
      setNotificationSetting({
        ...notificationSetting,
        type: notifyData.type,
        settings: notifyData.settings
      });

      setIsRender(true)
      if (notifyData.type === "daily"){
        setDailyStatusTime(true)

      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  }
  const addTime = () => {
    const settings = [...notificationSetting.settings];
    settings.push("");
    setNotificationSetting({ ...notificationSetting, settings: settings });
    // setNotifyTimes([...notifyTimes, notifyTimes.length + 1]);
  };

  const removeTime = (id) => {
    if (notificationSetting.settings.length > 1) {
      const settings = [...notificationSetting.settings];
      settings.splice(id, 1);
      setNotificationSetting({ ...notificationSetting, settings: settings });
    }
  };
  const updateTime = (index, newValue) => {
    const settings = [...notificationSetting.settings];
    settings[index] = newValue;
    setNotificationSetting({ ...notificationSetting, settings });
  };


  const updateNotificationSetting = async () => {
    try {
      const response = await researcherAction('/researchers/notification-settings/save', notificationSetting, authState.authToken);
      if (response.success) {
        fetchUserNotification();
        toast.success(response.message, TOAST_CONFIG);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  }
  const getChildren = () => {
    return (
      <Box className="researchersMainBox pageContainer">
        <Box className="EmailNContainer">
          <Typography className='emailNotificationHeaderText'>Email Notifications</Typography>
          <Typography>
            An email will only be sent if there are unread notifications
          </Typography>

          <Box className="radioBox">
            <Box>
              <input
                onClick={() => { setDailyStatusTime(false); setNotificationSetting({ ...notificationSetting, type: "none" }) }}
                className="emailSettingInput emailSettingRadio"
                checked={notificationSetting.type === "none"}
                type='radio'
                name='notification'

              />
            </Box>
            <Box>
              <Typography className="emailSettingLabel">None</Typography>
            </Box>
          </Box>
          <Box className="radioBox">
            <Box>
              <input
                onClick={() => { setDailyStatusTime(false); setNotificationSetting({ ...notificationSetting, type: "immediately" }) }}
                className="emailSettingInput emailSettingRadio"
                checked={notificationSetting.type === "immediately"}
                type='radio'
                name='notification'
              />
            </Box>
            <Box>
              <Typography className="emailSettingLabel">Immediately</Typography>
            </Box>
          </Box>
          <Box className="radioBox">
            <Box>
              <input
                onClick={() => { setDailyStatusTime(false); setNotificationSetting({ ...notificationSetting, type: "hourly" }) }}
                className="emailSettingInput emailSettingRadio"
                type='radio'
                checked={notificationSetting.type === "hourly"}
                name='notification'
              />
            </Box>
            <Box>
              <Typography className="emailSettingLabel">Hourly</Typography>
            </Box>
          </Box>
          <Box className="radioBox">
            <Box>
              <input
                onClick={() => { setDailyStatusTime(true); setNotificationSetting({ ...notificationSetting, type: "daily" }) }}
                className="emailSettingInput emailSettingRadio"
                type='radio'
                checked={notificationSetting.type === "daily"}
                name='notification'
              />
            </Box>
            <Box>
              <Typography className="emailSettingLabel">Daily</Typography>
            </Box>

          </Box>
          <Box sx={{ display: dailyStatusTime ? "flex" : "none" }} className="emailNotifyRadioSwitchBox notifyTimeBox">

            <Typography className="switchText">
              at
            </Typography>
            {isRender && notificationSetting?.settings.length > 0 && notificationSetting.settings.map((data, i) => (<>
              <TimeStatus
                index={i}
                data={data}
                updateTime={updateTime}
              />
              <Box onClick={() => removeTime(i)}><small>Remove</small></Box>
            </>))}
            {/*  <TimeStatus2
              setStatusTimeDrop2={setStatusTimeDrop2}
              statusTimeDrop2={statusTimeDrop2}
              setStatusTimeDropVal2={setStatusTimeDropVal2}
              statusTimeDropVal2={statusTimeDropVal2}
            />
            <TimeStatus3
              setStatusTimeDrop3={setStatusTimeDrop3}
              statusTimeDrop3={statusTimeDrop3}
              setStatusTimeDropVal3={setStatusTimeDropVal3}
              statusTimeDropVal3={statusTimeDropVal3}
            /> */}
            <Typography className="verifyLink notifyVerifyLink" onClick={addTime}>Add Time</Typography>
          </Box>
          <Box className="radioBox">
            <Box>
              <input
                onClick={() => { setDailyStatusTime(false); setNotificationSetting({ ...notificationSetting, type: "weekly" }) }}
                className="emailSettingInput emailSettingRadio"
                type='radio'
                checked={notificationSetting.type === "weekly"}
                name='notification'

              />
            </Box>
            <Box>
              <Typography className="emailSettingLabel">Weekly</Typography>
            </Box>
          </Box>
        </Box>
        <Box className="workPupBtnBox"> <AppBtn buttonText="Save Change" onClick={updateNotificationSetting} /></Box>

      </Box>
    )
  }

  return renderUserLayout(authState?.userType, null, getChildren(), 'accountsetting')
}
