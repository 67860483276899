import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Alert,
  Dialog,
  Grid,
  TextField,
  InputAdornment,
  FormLabel,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Divider,
  Pagination,
  Stack,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import "./style.css";

import { useTranslation } from "react-i18next";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FolderIcon from "@mui/icons-material/Folder";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import TableRowsIcon from "@mui/icons-material/TableRows";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import axios from "../../../lib/axios";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import backIcon from "../../../Assets/Images/icon-arrow.svg";
import iIcon from "../../../Assets/Images/IIcon.png";
import backArrow from "../../../Assets/Images/noun-back.png";
import groupsIcon from "../../../Assets/Images/groupsIcon.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";

//components
import SideMenu from "../../../Components/WSAdministration/SideMenu";
import {
  AppBtn,
  AppBtnText,
  AppBtnOutLine,
} from "../../../Components/AppTools/AppButton";
import { MenuProps, useStyles, options } from "./utils";
import {
  // totalList,
  sideHaumIcon,
  // ImportDetailsList,
} from "../../../Assets/Data";
import { capitalizeFirstLetter, renderUserLayout, viewImage } from "../../../lib/helpers";
import { useAppSelector } from "../../../lib/store/store";
import { participantAction } from "../../../Services/researcherActions";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../lib/constants";
import AdminLayout from "../../Admin/partials/AdminLayout";
import ResearcherLayout from "../../Researchers/partials/ResearcherLayout";

export default function BulkImport({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
  participantsSubMenu,
  setParticipantsSubMenu,
  configurationSubMenu,
  setConfigurationSubMenu,
}) {
  const [open, setOpen] = React.useState(false);
  const [redio1, setRedio1] = useState(false);
  const [redio2, setRedio2] = useState(false);
  const [redio3, setRedio3] = useState(false);
  const [redio4, setRedio4] = useState(false);

  const [templateCheck, setTemplateCheck] = useState(false);
  const [templateCheck1, setTemplateCheck1] = useState(false);
  const [templateCheck2, setTemplateCheck2] = useState(false);
  const [templateCheck3, setTemplateCheck3] = useState(false);
  const [templateCheck4, setTemplateCheck4] = useState(false);
  const [templateCheck5, setTemplateCheck5] = useState(false);
  const [templateCheck6, setTemplateCheck6] = useState(false);

  const [redio, setRedio] = useState(false);
  const [groupDrop, setGroupDrop] = useState(false);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setTemplate({
      project_id: [],
      profiling_id: [],
      group_id: [],
      users_fields: [],
    });
    setOpen(false);
  };

  const [searchgroups, setsearchgroups] = React.useState("");
  const handleChange = (event, SelectChangeEvent) => {
    setsearchgroups(event.target.value);
  };

  const [selectprojects, setselectprojects] = React.useState(0);
  const handleChange2 = (event, SelectChangeEvent) => {
    setselectprojects(event.target.value);
  };

  const [selectparticipants, setselectparticipants] = React.useState(0);
  const handleChange3 = (event, SelectChangeEvent) => {
    setselectparticipants(event.target.value);
  };

  const [selectstandard11, setselectstandard11] = React.useState(10);
  const handleChange11a = (event, SelectChangeEvent) => {
    setselectstandard11(event.target.value);
  };

  const [selectemail11, setselectemail11] = React.useState(10);
  const handleChange11b = (event, SelectChangeEvent) => {
    setselectemail11(event.target.value);
  };

  const [selectstandard12, setselectstandard12] = React.useState(10);
  const handleChange12a = (event, SelectChangeEvent) => {
    setselectstandard12(event.target.value);
  };

  const [selectemail12, setselectemail12] = React.useState(10);
  const handleChange12b = (event, SelectChangeEvent) => {
    setselectemail12(event.target.value);
  };

  const [selectstandard13, setselectstandard13] = React.useState(10);
  const handleChange13a = (event, SelectChangeEvent) => {
    setselectstandard13(event.target.value);
  };

  const [selectemail13, setselectemail13] = React.useState(10);
  const handleChange13b = (event, SelectChangeEvent) => {
    setselectemail13(event.target.value);
  };

  const [selectstandard14, setselectstandard14] = React.useState(10);
  const handleChange14a = (event, SelectChangeEvent) => {
    setselectstandard14(event.target.value);
  };

  const [selectemail14, setselectemail14] = React.useState(10);
  const handleChange14b = (event, SelectChangeEvent) => {
    setselectemail14(event.target.value);
  };

  const [selectnumber, setselectnumber] = React.useState(10);
  const handleChange15 = (event, SelectChangeEvent) => {
    setselectnumber(event.target.value);
  };

  const [BulkImportFileUpload, setBulkImportFileUpload] = useState(false);
  const [BulkImportContinue, setBulkImportContinue] = useState(false);

  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const handleChange4 = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      return;
    }
    setSelected(value);
  };

  const chackBoxRoom = () => {
    return (
      <Box className="checkBoxItem">
        <Box>
          <input className="custom-checkbox" type="checkBox" />
          <Typography>{t("bulkImport.group")} 0</Typography>
        </Box>
        <Box>
          <input className="custom-checkbox" type="checkBox" />
          <Typography>{t("bulkImport.group")} 0</Typography>
        </Box>
        <Box>
          <input className="custom-checkbox" type="checkBox" />
          <Typography>{t("bulkImport.group")} 0</Typography>
        </Box>
      </Box>
    );
  };

  // dev code

  useEffect(() => {
    getUserFields();
    getGroupsLists();
    getProfileFields();
    getProjects();
  }, []);
  const workspaceState = useAppSelector((state) => state.workspace);
  const authState = useAppSelector((state) => state.auth);
  const [userFields, setUserFields] = useState([]);
  const [groupsListData, setGroupsListData] = useState([]);
  const [profileFieldData, setProfileFieldData] = useState([]);
  const [profileFields, setProfileFields] = useState([]);
  const [groups, setGroups] = useState([]);
  const [projects, setProjects] = useState([]);
  const [userProfile, setUserProfile] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [uploadResponse, setUploadResponse] = useState([]);
  const [totalList, setTotalList] = useState([]);
  const [importDetailsList, setImportDetailsList] = useState([]);
  const [existProjects, setExistProjects] = useState([]);
  const [importSettings, setImportSettings] = useState({
    confirm_username: false,
    force_password: false,
    project_ids: [],
  });
  const [uploadPath, setUploadPath] = useState("");
  const [uploadFile, setUploadFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState({
    project_id: [],
    profiling_id: [],
    group_id: [],
    users_fields: [],
  });
  const getUserFields = async () => {
    let url = `/super-admin/admin/get-user-fields`;
    const res = await axios.get(url);
    if (res.status) {
      setUserFields(res.data);
    }
  };

  const getGroupsLists = async () => {
    try {
      const res = await axios.get(
        `/participant/groups?workspace_id=${workspaceState?.selectedWorkspace?._id}`
      );
      if (res.status) {
        setGroupsListData(res.data?.data);
        setGroups(
          res.data.data.map((el) => ({ _id: el._id, name: `GRP:${el.name}` }))
        );
      }
    } catch (error) {
      console.error("Error fetching project lists", error);
    }
  };

  const getProfileFields = async () => {
    try {
      const res = await axios.get(
        `/participant/profile-fields?workspace_id=${workspaceState?.selectedWorkspace?._id}`
      );
      if (res.status) {
        setProfileFieldData(res.data.data);
        setProfileFields(
          res.data.data.map((el) => ({
            _id: el._id,
            name: `CFP:${el.question}`,
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching project lists", error);
    }
  };

  const getProjects = async () => {
    try {
      const res = await axios.get(
        `/researchers/projects?workspace_id=${workspaceState?.selectedWorkspace?._id}`
      );
      if (res.status) {
        setProjectData(res.data.data);
        setProjects(
          res.data.data.map((el) => ({ _id: el._id, name: `PRO:${el.name}` }))
        );
      }
    } catch (error) {
      console.error("Error fetching project lists", error);
    }
  };

  const handleGroupChange = (e) => {
    const selectedValue = e.target.value === "" ? 0 : e.target.value;
    setTemplate((prevGroup) => ({
      ...prevGroup,
      [e.target.name]: selectedValue,
    }));
  };

  const handleUserField = (e, user) => {
    const uListSet = new Set(template.users_fields || []);
    if (e.target.checked) {
      uListSet.add(user);
    } else {
      uListSet.delete(user);
    }
    setTemplate((prevGroup) => ({
      ...prevGroup,
      users_fields: Array.from(uListSet),
    }));
  };

  const createTemplate = async () => {
    try {
      const response = await participantAction(
        `/participant/create-import-template`,
        template,
        authState.authToken
      );
      // setLoading(false);
      if (response.success) {
        toast.success(response.message, TOAST_CONFIG);
        window.open(viewImage(response.data.filePath), "_Blank");
        handleClose();
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      // setLoading(false);
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (
      file.type ==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    )
      setUploadFile(e.target.files[0]);
    else toast.error("Invalid file type.", TOAST_CONFIG);
  };

  const handleChangeFieldType = (e, index) => {
    const updateFieldType = [...uploadResponse.mappingFields];
    let updateFieldTypeIndex = { ...updateFieldType[index] };
    updateFieldTypeIndex.field_type = e.target.value;
    updateFieldType[index] = updateFieldTypeIndex;
    const mappingFieldsWithMaster = setMaster(updateFieldType);
    setUploadResponse({
      ...uploadResponse,
      mappingFields: mappingFieldsWithMaster,
    });
  };

  const handleChangeField = (e, index) => {
    const newHeader = e.target.value;
    const updateFieldType = [...uploadResponse.mappingFields];

    // Check if the new header value already exists in another header
    const isDuplicate = updateFieldType.some(
      (field, idx) => field.header === newHeader && idx !== index
    );

    if (isDuplicate) {
      toast.error("Field is already selected in another column");
      return;
    }

    let updateFieldTypeIndex = { ...updateFieldType[index] };
    updateFieldTypeIndex.header = newHeader;
    updateFieldType[index] = updateFieldTypeIndex;

    setUploadResponse({
      ...uploadResponse,
      mappingFields: updateFieldType,
    });
  };

  const setMaster = (updateFieldType, users = []) => {
    return updateFieldType.map((el) => {
      el.master =
        el.field_type === "custom_fields"
          ? [...profileFields]
          : el.field_type === "group"
          ? [...groups]
          : el.field_type === "project"
          ? [...projects]
          : users.length > 0
          ? [...users]
          : [...userProfile];

      if (el.header.includes("PRO:")) {
        setExistProjects((prev) => {
          let newEl = prev ? [...prev] : [];
          newEl.push(el.header);
          return newEl;
        });
      }
      return el;
    });
  };

  const uploadImportFile = async () => {
    if (!uploadFile) return toast.error("Please select a file.", TOAST_CONFIG);

    try {
      const formData = new FormData();
      formData.append("import_file", uploadFile);
      formData.append("workspace_id", workspaceState?.selectedWorkspace?._id);

      setLoading(true);
      let response = await participantAction(
        `/participant/import-participant`,
        formData,
        authState.authToken
      );
      setLoading(false);
      if (response.success) {
        const filePth = response.data.filePath.split("/");
        setUploadPath(filePth[filePth.length - 1]);
        const users = Object.entries(response.data.allFields).map(
          ([ky, value]) => ({
            _id: ky,
            name: value,
          })
        );
        setUserProfile(users);
        const mappingFieldsWithMaster = setMaster(
          response.data.mappingFields,
          users
        );

        setUploadResponse({
          filePath: response.data.filePath,
          mappingFields: mappingFieldsWithMaster,
          totalColumn: response.data.totalColumn,
          totalRow: response.data.totalRow,
        });
        toast.success(response.message, TOAST_CONFIG);
        setBulkImportFileUpload(true);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  const getMappingObject = (mappingFields) => {
    const mapData = mappingFields.map((field) => ({
      header: field.header,
      field: field.field_type,
      value: field.value,
    }));

    return {
      import_file: uploadResponse.filePath,
      mapping_fields: mapData,
    };
  };

  const validateColumn = async () => {
    const payload = getMappingObject(uploadResponse.mappingFields);

    try {
      setLoading(true);
      const response = await participantAction(
        `/participant/validate-import-participant`,
        payload,
        authState.authToken
      );
      setLoading(false);
      if (response.success) {
        setTotalList(response.data?.summary);
        setImportDetailsList(response.data?.report);
        toast.success(response.message, TOAST_CONFIG);
        setBulkImportContinue(true);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  const handleProjectCheck = (e, project) => {
    const updateIds = new Set(importSettings.project_ids);
    if (e.target.checked) {
      updateIds.add(project._id);
    } else {
      updateIds.delete(project._id);
    }
    setImportSettings({
      ...importSettings,
      project_ids: Array.from(updateIds),
    });
  };

  const handleSelectAll = () => {
    const projIds = projects
      .filter((proj) => !existProjects.includes(proj.name))
      .map((proj) => proj._id);
    setImportSettings({ ...importSettings, project_ids: projIds });
  };
  const handleDeSelectAll = () => {
    setImportSettings({ ...importSettings, project_ids: [] });
  };

  const startImport = async () => {
    const payload = getMappingObject(uploadResponse.mappingFields);
    payload.confirm_username = importSettings.confirm_username;
    payload.force_password = importSettings.force_password;
    payload.project_ids = importSettings.project_ids;

    try {
      setLoading(true);
      const response = await participantAction(
        `/participant/confirm-import-participant`,
        payload,
        authState.authToken
      );
      setLoading(false);
      if (response.success) {
        cancelUpload();
        toast.success(response.message, TOAST_CONFIG);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const cancelUpload = () => {
    setUploadResponse({
      filePath: [],
      mappingFields: [],
      totalColumn: 0,
      totalRow: 0,
    });

    setImportSettings({
      confirm_username: false,
      force_password: false,
      project_ids: [],
    });

    setTotalList([]);
    setImportDetailsList([]);
    setBulkImportContinue(false);
    setUploadPath("");
    setBulkImportFileUpload(false);
    setUploadFile(null);
  };

  const getChildren = () => {
    return (<Box className="researchersMainBox pageContainer">
      {!BulkImportFileUpload ? (
        <>
          <Box className="BulkImportSection">
            <Typography variant="h4" gutterBottom>
              {t("bulkImport.import")}
            </Typography>

            <Box className="iBox">
              <img src={iIcon} />
              <Typography>{t("bulkImport.noteText")}</Typography>
            </Box>

            <Box
              className="FormFiled"
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
                maxWidth: "300px",
              }}
            >
              <TextField
                fullWidth
                id="uploadfile"
                title={t("bulkImport.txtTitle")}
                variant="outlined"
                size="small"
                type="file"
                onChange={handleFileUpload}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FolderIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box className="workPupBtnBox">
              {loading ? (
                <AppBtn buttonText={`Loading...`} />
              ) : (
                <AppBtn
                  onClick={uploadImportFile}
                  buttonText={t("bulkImport.btnTitle")}
                />
              )}
              <AppBtnText
                onClick={handleClickOpen}
                buttonText={t("bulkImport.linkText")}
              />
            </Box>
          </Box>
        </>
      ) : (
        <Box
          className="BulkImportFileUploadSection"
          sx={{ display: BulkImportContinue ? "none" : "block" }}
        >
          <Box className="BulkImportSection">
            <Typography variant="h4" gutterBottom>
              {t("bulkImport.fileUpload")}
            </Typography>

            <Box className="iBox">
              <img src={iIcon} />
              <Typography>{t("bulkImport.noteText2")}</Typography>
            </Box>
            <Box
              className="FormFiled"
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Typography>
                <span className="bulkImportFileNameText">
                  {uploadPath}
                </span>{" "}
                {t("bulkImport.fileUploaded")}
              </Typography>
            </Box>
            <Box className="workPupBtnBox">
              <AppBtnOutLine
                onClick={cancelUpload}
                buttonText={t("bulkImport.btnTitle2")}
              />
              <AppBtnText
                onClick={handleClickOpen}
                buttonText={t("bulkImport.linkText")}
              />
            </Box>
          </Box>
          <Box className="BulkImportFormSection">
            <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
              <Grid item sm={8} sx={{ alignItems: "center" }}>
                <Typography variant="h4">
                  {t("bulkImport.fileUpload")}
                </Typography>
              </Grid>
              <Grid item sm={4}>
                <List className="listColumnsRows">
                  <ListItem
                    sx={{
                      width: "auto",
                      padding: "0px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                  >
                    <ListItemButton
                      disablePadding
                      sx={{
                        padding: "0px",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: "36px" }}>
                        <ViewColumnIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${uploadResponse.totalColumn} Columns`}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    sx={{
                      width: "auto",
                      padding: "0px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                  >
                    <ListItemButton
                      disablePadding
                      sx={{
                        padding: "0px",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: "36px" }}>
                        <TableRowsIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${uploadResponse.totalRow} Rows`}
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Grid>
            </Grid>

            <Box className="iBox">
              <img src={iIcon} />
              <Typography>{t("bulkImport.columnContent")}</Typography>
            </Box>
            {/* <Box className="FormLable">
              <FormLabel className="label">
                {t("bulkImport.email")}
              </FormLabel>
              <Typography className="data">
                <a href="#">{t("bulkImport.sampleData")}</a>{" "}
                <span>15</span>
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item sm={3}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel id="demo-select-small-label">Standard</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectstandard11}
                    onChange={handleChange11a}
                  >
                    <MenuItem value={10}>
                      {t("bulkImport.standard")} 1
                    </MenuItem>
                    <MenuItem value={20}>
                      {t("bulkImport.standard")} 2
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectemail11}
                    onChange={handleChange11b}
                  >
                    <MenuItem value={10}>
                      {t("bulkImport.emailAddress")} 01
                    </MenuItem>
                    <MenuItem value={20}>
                      {t("bulkImport.emailAddress")} 02
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid> */}

            {/* <Box className="FormLable">
              <FormLabel className="label">
                {t("bulkImport.fname")}
              </FormLabel>
              <Typography className="data">
                <a href="#">{t("bulkImport.sampleData")}</a>{" "}
                <span>15</span>
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item sm={3}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectstandard12}
                    onChange={handleChange12a}
                  >
                    <MenuItem value={10}>
                      {t("bulkImport.standard")} 01
                    </MenuItem>
                    <MenuItem value={20}>
                      {t("bulkImport.standard")} 02
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectemail12}
                    onChange={handleChange12b}
                  >
                    <MenuItem value={10}>
                      {t("bulkImport.emailAddress")} 01
                    </MenuItem>
                    <MenuItem value={20}>
                      {t("bulkImport.emailAddress")} 02
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid> */}

            {/* <Box className="FormLable">
              <FormLabel className="label">
                {t("bulkImport.lName")}
              </FormLabel>
              <Typography className="data">
                <a href="#">{t("bulkImport.sampleData")}</a>{" "}
                <span>15</span>
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item sm={3}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectstandard13}
                    onChange={handleChange13a}
                  >
                    <MenuItem value={10}>
                      {t("bulkImport.standard")} 01
                    </MenuItem>
                    <MenuItem value={20}>
                      {t("bulkImport.standard")} 02
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectemail13}
                    onChange={handleChange13b}
                  >
                    <MenuItem value={10}>
                      {t("bulkImport.emailAddress")} 01
                    </MenuItem>
                    <MenuItem value={20}>
                      {t("bulkImport.emailAddress")} 02
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid> */}
            {uploadResponse.mappingFields &&
              uploadResponse.mappingFields.map((field, k) => (
                <>
                  <Box className="FormLable">
                    <FormLabel className="label">
                      {field.header}
                    </FormLabel>
                    <Typography className="data">
                      <a href="#">{t("bulkImport.sampleData")}</a>{" "}
                      <span>{field.count}</span>
                    </Typography>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item sm={3}>
                      <FormControl sx={{ width: "100%" }} size="small">
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={field.field_type}
                          onChange={(e) => handleChangeFieldType(e, k)}
                        >
                          <MenuItem value={`standard`}>Standard</MenuItem>
                          <MenuItem value={`custom_fields`}>
                            Custom Field
                          </MenuItem>
                          <MenuItem value={`group`}>Group</MenuItem>
                          <MenuItem value={`project`}>Project</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl sx={{ width: "100%" }} size="small">
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={field.header}
                          onChange={(e) => handleChangeField(e, k)}
                        >
                          {/* <MenuItem value={10}>
                            {t("bulkImport.emailAddress")} 01
                          </MenuItem> */}
                          {field.master &&
                            field.master.map((el, i) => (
                              <MenuItem value={el.name} key={i}>
                                {el.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </>
              ))}
          </Box>
          <Box className="workPupBtnBox">
            <AppBtn
              onClick={validateColumn}
              buttonText={t("bulkImport.btnTitle3")}
            />
          </Box>
        </Box>
      )}

      <Box
        className="BulkImportContinue"
        sx={{ display: BulkImportContinue ? "block" : "none" }}
      >
        <Box className="BulkImportFileValidated">
          <Box
            onClick={() => {
              setBulkImportFileUpload(true);
              setBulkImportContinue(false);
            }}
            className="openBtn"
          >
            <img src={backArrow} />
            <Typography className="neonBackText">
              {t("commonTxt.backBtn")}
            </Typography>
          </Box>

          <Typography
            variant="h4"
            gutterBottom
            sx={{ marginTop: "10px", marginBottom: "20px" }}
          >
            {t("bulkImport.blkImportFileValidated")}
          </Typography>

          <Box className="iBox">
            <img src={iIcon} />
            <Typography> {t("bulkImport.startImport")}</Typography>
          </Box>

          <List className="listTotal">
            {totalList &&
              totalList.map((el, index) => (
                <ListItem>
                  {el.heading} <span>{el.total}</span>
                </ListItem>
              ))}
          </List>

          {importDetailsList &&
            importDetailsList.map((el, index) => (
              <Box className="ImportDetails">
                <Typography variant="h5">
                  {el.heading}{" "}
                  <KeyboardArrowRightIcon className="arrow" />{" "}
                  {el.headingvalue}{" "}
                </Typography>
                <Typography>{el.fieldType}</Typography>
                <Typography>{el.newfield}</Typography>
                <List className="list">
                  <ListItem className="check">{el.successvalue}</ListItem>
                  <ListItem className="uncheck">{el.crossvalue}</ListItem>
                </List>
              </Box>
            ))}
        </Box>
        <Box className="ImportSettings">
          <Typography variant="h4" gutterBottom>
            {t("bulkImport.importSettings")}
          </Typography>
          <Box className="FormLable">
            <FormLabel className="label">
              {t("bulkImport.accSettings")}
            </FormLabel>
          </Box>
          <Grid container spacing={2}>
            <Grid item sm={5}>
              <Box className="alpOverCheck">
                <input
                  style={{ marginRight: "16px" }}
                  className="custom-checkbox"
                  type="checkBox"
                  checked={importSettings.force_password}
                  onClick={(e) =>
                    setImportSettings({
                      ...importSettings,
                      force_password: e.target.checked,
                    })
                  }
                />
                <Typography>{t("bulkImport.passwordChange")}</Typography>
              </Box>
            </Grid>
            <Grid item sm={5}>
              <Box className="alpOverCheck">
                <input
                  style={{ marginRight: "16px" }}
                  className="custom-checkbox"
                  type="checkBox"
                  checked={importSettings.confirm_username}
                  onClick={(e) =>
                    setImportSettings({
                      ...importSettings,
                      confirm_username: e.target.checked,
                    })
                  }
                />
                <Typography>
                  {t("bulkImport.userNameConfirmation")}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box mt={4} className="iBox">
            <img src={iIcon} />
            <Typography>{t("bulkImport.useThisOption")}</Typography>
          </Box>

          <Box className="FormLable">
            <FormLabel className="label">
              {t("bulkImport.assignStudies")}
            </FormLabel>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Typography gutterBottom>
              <a href="#" onClick={handleSelectAll}>
                {t("bulkImport.selectAll")}
              </a>
            </Typography>
            <Typography gutterBottom onClick={handleDeSelectAll}>
              <a href="#">{t("bulkImport.deSelectAll")}</a>
            </Typography>
          </Box>
          <Grid container>
            {projects &&
              projects.map((project) =>
                !existProjects.includes(project.name) ? (
                  <Grid item sm={5}>
                    <Box
                      mb={2}
                      className="alpOverCheck"
                      // onClick={() => setRedio1(!redio1)}
                    >
                      <input
                        style={{ marginRight: "16px" }}
                        className="custom-checkbox"
                        type="checkBox"
                        checked={importSettings.project_ids.includes(
                          project._id
                        )}
                        onClick={(e) => handleProjectCheck(e, project)}
                      />
                      <Typography>
                        {project.name.replace("PRO:", "")}
                      </Typography>
                    </Box>
                  </Grid>
                ) : null
              )}
            {/* <Grid item sm={5}>
              <Box
                mb={2}
                className="alpOverCheck"
                onClick={() => setRedio2(!redio2)}
              >
                <input
                  style={{ marginRight: "16px" }}
                  className="custom-checkbox"
                  type="checkBox"
                  checked={redio2 ? true : false}
                />
                <Typography>Financial Awareness</Typography>
              </Box>
            </Grid>
            <Grid item sm={5}>
              <Box
                className="alpOverCheck"
                onClick={() => setRedio3(!redio3)}
              >
                <input
                  style={{ marginRight: "16px" }}
                  className="custom-checkbox"
                  type="checkBox"
                  checked={redio3 ? true : false}
                />
                <Typography>Eating Habits</Typography>
              </Box>
            </Grid>
            <Grid item sm={5}>
              <Box
                className="alpOverCheck"
                onClick={() => setRedio4(!redio4)}
              >
                <input
                  style={{ marginRight: "16px" }}
                  className="custom-checkbox"
                  type="checkBox"
                  checked={redio4 ? true : false}
                />
                <Typography>Sport Psychology</Typography>
              </Box>
            </Grid> */}
          </Grid>
        </Box>
        <Box className="workPupBtnBox">
          <AppBtn
            buttonText={t("bulkImport.btnTitle4")}
            onClick={startImport}
          />
        </Box>
      </Box>

      {/* Create Template */}
      <Dialog
        className="DialogSection"
        fullScreen={fullScreen}
        maxWidth={1080}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t("bulkImport.createTemplate")}
        </DialogTitle>
        <Box className="AddFormPanel">
          <Box className="FormFiled">
            <FormLabel className="label">
              {t("bulkImport.selectFields")}
            </FormLabel>
            {/* UserField */}
            <List className="CheckboxList01">
              {userFields &&
                Object.entries(userFields).map(([ky, value]) => (
                  <ListItem disablePadding>
                    <Box
                      className="alpOverCheck"
                      onClick={() => setTemplateCheck(!templateCheck)}
                    >
                      <input
                        style={{ marginRight: "16px" }}
                        className="custom-checkbox"
                        type="checkBox"
                        checked={template.users_fields.includes(ky)}
                        onClick={(e) => handleUserField(e, ky)}
                      />
                      <Typography>
                        {capitalizeFirstLetter(value)}
                      </Typography>
                    </Box>
                  </ListItem>
                ))}
              {/* <ListItem disablePadding>
                <Box
                  className="alpOverCheck"
                  onClick={() => setTemplateCheck1(!templateCheck1)}
                >
                  <input
                    style={{ marginRight: "16px" }}
                    className="custom-checkbox"
                    type="checkBox"
                    checked={templateCheck1 ? true : false}
                  />
                  <Typography>{t("bulkImport.externalID")}</Typography>
                </Box>
              </ListItem>
              <ListItem disablePadding>
                <Box
                  className="alpOverCheck"
                  onClick={() => setTemplateCheck2(!templateCheck2)}
                >
                  <input
                    style={{ marginRight: "16px" }}
                    className="custom-checkbox"
                    type="checkBox"
                    checked={templateCheck2 ? true : false}
                  />
                  <Typography>{t("bulkImport.fname")}</Typography>
                </Box>
              </ListItem>
              <ListItem disablePadding>
                <Box
                  className="alpOverCheck"
                  onClick={() => setTemplateCheck3(!templateCheck3)}
                >
                  <input
                    style={{ marginRight: "16px" }}
                    className="custom-checkbox"
                    type="checkBox"
                    checked={templateCheck3 ? true : false}
                  />
                  <Typography>{t("bulkImport.lName")}</Typography>
                </Box>
              </ListItem>
              <ListItem disablePadding>
                <Box
                  className="alpOverCheck"
                  onClick={() => setTemplateCheck4(!templateCheck4)}
                >
                  <input
                    style={{ marginRight: "16px" }}
                    className="custom-checkbox"
                    type="checkBox"
                    checked={templateCheck4 ? true : false}
                  />
                  <Typography>{t("bulkImport.uName")}</Typography>
                </Box>
              </ListItem>
              <ListItem disablePadding>
                <Box
                  className="alpOverCheck"
                  onClick={() => setTemplateCheck5(!templateCheck5)}
                >
                  <input
                    style={{ marginRight: "16px" }}
                    className="custom-checkbox"
                    type="checkBox"
                    checked={templateCheck5 ? true : false}
                  />
                  <Typography>{t("bulkImport.password")}</Typography>
                </Box>
              </ListItem>
              <ListItem disablePadding>
                <Box
                  className="alpOverCheck"
                  onClick={() => setTemplateCheck6(!templateCheck6)}
                >
                  <input
                    style={{ marginRight: "16px" }}
                    className="custom-checkbox"
                    type="checkBox"
                    checked={templateCheck6 ? true : false}
                  />
                  <Typography>{t("bulkImport.state")}</Typography>
                </Box>
              </ListItem> */}
            </List>
          </Box>
          <Divider />
          <Box className="searchGroupSection">
            <FormControl sx={{ width: "100%" }} size="small">
              <Select
                labelId="demo-select-small-label11"
                id="demo-select-small11"
                value={template.group_id}
                onChange={handleGroupChange}
                name="group_id"
                multiple
                displayEmpty
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <Box
                        pl={4.5}
                        className="inputAppDrop"
                        onClick={() => setGroupDrop(!groupDrop)}
                      >
                        <img className="groupsIcon" src={groupsIcon} />
                        <Typography>
                          {t("bulkImport.searchgroups")}
                        </Typography>
                      </Box>
                    );
                  }
                  return selected
                    .map((value) => {
                      const group = groupsListData.find(
                        (group) => group._id === value
                      );
                      return group ? group.name : value;
                    })
                    .join(", ");
                }}
              >
                {groupsListData.map((group, index) => (
                  <MenuItem value={group._id} key={index}>
                    {group.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <Box
              pl={4.5}
              className="inputAppDrop"
              onClick={() => setGroupDrop(!groupDrop)}
            >
              <img className="groupsIcon" src={groupsIcon} />
              <Typography>{t("bulkImport.searchgroups")}</Typography>
              <img
                style={{ rotate: groupDrop ? "180deg" : "0deg" }}
                src={dropArrow}
              />
            </Box> */}
            {/* <Box
              sx={{ height: groupDrop ? "auto" : "0px" }}
              className="checkMain_box"
            >
              <Box className="ckeckBoxRoom">
                {chackBoxRoom()}
                {chackBoxRoom()}
                {chackBoxRoom()}
              </Box>
              <Box className="propertyPagination">
                <Box>
                  <p>1</p>
                </Box>
                <Box>
                  <p>2</p>
                </Box>
                <Box>
                  <p>3</p>
                </Box>
                <Box>
                  <p>4</p>
                </Box>
                <Box>
                  <p>...</p>
                </Box>
                <Box>
                  <p>8</p>
                </Box>
                <Box>
                  <p>9</p>
                </Box>
                <Box>
                  <p>10</p>
                </Box>
              </Box>
            </Box> */}
          </Box>

          <Box className="FormFiled"></Box>
          <Box className="FormFiled">
            <FormLabel className="label">
              {t("bulkImport.projects")}
            </FormLabel>
            <FormControl
              sx={{ width: "100%", marginTop: "10px" }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={template.project_id}
                onChange={handleGroupChange}
                name="project_id"
                multiple
                displayEmpty
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <p>Select Projects</p>;
                  }
                  return selected
                    .map((value) => {
                      const project = projectData.find(
                        (pro) => pro._id === value
                      );
                      return project ? project.name : value;
                    })
                    .join(", ");
                }}
              >
                {projectData.map((project, index) => (
                  <MenuItem value={project._id} key={index}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className="FormFiled">
            <FormLabel className="label">
              {t("bulkImport.participantProfiling")}
              {/* profileFieldData */}
            </FormLabel>
            <FormControl
              sx={{ width: "100%", marginTop: "10px" }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={template.profiling_id}
                onChange={handleGroupChange}
                name="profiling_id"
                multiple
                displayEmpty
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <p>Select Profiles</p>;
                  }
                  return selected
                    .map((value) => {
                      const profile = profileFieldData.find(
                        (prof) => prof._id === value
                      );
                      return profile ? profile.question : value;
                    })
                    .join(", ");
                }}
              >
                {profileFieldData.map((profile, index) => (
                  <MenuItem value={profile._id} key={index}>
                    {profile.question}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <DialogActions>
          <Box className="workPupBtnBox">
            <AppBtnOutLine
              autoFocus
              onClick={handleClose}
              buttonText={t("commonTxt.cancelBtn")}
            />
            <AppBtn
              buttonText={t("commonTxt.applyBtn")}
              onClick={createTemplate}
            />
          </Box>
        </DialogActions>
      </Dialog>
    </Box>);
  }

  return renderUserLayout(authState?.userType, null, getChildren(), 'workadmin');
}
