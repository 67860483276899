import React from 'react'
import AdminLayout from '../partials/AdminLayout';
import { Box } from '@mui/material';
import ApiCredential from '../../../Components/Admin/ApiCredential';

const ApiCredentialPage = () => {
  return (
    <AdminLayout
      childComponent={
        <Box
            className="adminMainBox"
            sx={{ display: "flex" }}
          >
            <ApiCredential />
        </Box>
      }
    />
  )
}

export default ApiCredentialPage;