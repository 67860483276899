import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import { Box, Typography } from "@mui/material";

import {
  SearchBar,
  GoTop
} from "../../../Components/AppTools/AppTool";
import arrow from "../../../Assets/Images/arrow.png";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import VideoIcon from "../../../Assets/Images/video.png";
import groupsIcon from "../../../Assets/Images/groupsIcon.png";

import { AppBtn } from "../../../Components/AppTools/AppButton";

import {
  Workspace
} from "../../../Assets/Data";
import { sideHaumIcon } from "../../../Assets/Data";
import AdminLayout from "../../Admin/partials/AdminLayout";
import ResearcherLayout from "../../Researchers/partials/ResearcherLayout";
import { useAppSelector } from '../../../lib/store/store';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
//component
import SideMenu from "../../../Components/WSAdministration/SideMenu";
import BulkImportDetailPop from "../../../Components/WSAdministration/BulkImportDetailPop";
import { formatDate, renderUserLayout } from "../../../lib/helpers";
import { API_BASE_URL } from "../../../env";
import axios from "../../../lib/axios";
import Pagination from "../../../Components/AppTools/Pagination";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ReferenceDateUsingValue from "../../../Components/AppTools/DatePicker";
import ReferenceDateExplicitTimePicker from "../../../Components/AppTools/TimePicker";
import dayjs from "dayjs";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { Padding } from "@mui/icons-material";

export default function SiteLog({ }) {

  const authState = useAppSelector((state) => state.auth);

  const [dataRangeDrop, setDateRangeDrop] = useState(false);
  const [dataRangeDropVal, setDateRangeDropVal] = useState("");

  const [projectStatusDrop, setProjectStatusDrop] = useState(false);
  const [projectStatusDropVal, setProjectStatusDropVal] = useState("");

  const [userTypeDrop, setUserTypeDrop] = useState(false);
  const [userTypeDropVal, setUserTypeDropVal] = useState("");

  const [groupDrop, setGroupDrop] = useState(false);
  const [groupDropVal, setGroupDropVal] = useState();
  const [siteLogList, setSiteLogList] = useState([]);

  const [bulkImportDetailPop, setBulkImportDetailPop] = useState(false);
  const [selectedLogDetails, setSelectedLogDetails] = useState(null);

  const [value, setValue] = useState(['', '']);
  const dateRangePickerRef = useRef(null);

  const defaultFilters = {
    'search': '',
    'action_type': '',
    'start_date': '',
    'end_date': ''
  }

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 25,
    totalPages: 0,
    totalItems: 0,
  })

  const [filterState, setFilterState] = useState({...defaultFilters});

  const handleFilterChange = (changedFilter) => {
    let newFilters = {
      ...filterState,
      ...changedFilter,
    }
    setFilterState(newFilters)
    fetchSiteLogList(1, newFilters)
  }

  const clearFilters = () => {
    const newFilters = {
      ...defaultFilters,
    };
    console.log("newFilters", newFilters);
    setFilterState(newFilters);
    fetchSiteLogList(1, newFilters);
    setUserTypeDropVal("");
    setValue(['', '']);
    if (dateRangePickerRef.current) {
      dateRangePickerRef.current.clear();
    }
  };
  //pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [newArrayData, setNewArrayData] = useState([]);

  useEffect(() => {
    printItemsByState(Workspace, currentPage);
  }, [currentPage]);

  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };
  const onActivityChange = (pageNum) => {
    fetchSiteLogList(pageNum);
  };

  const fetchSiteLogList = async(curPage, filters = filterState) => {
    try {
      const res = await axios.get(API_BASE_URL + `/researchers/logs?page=${curPage}&search=${filters.search}&action_type=${filters.action_type}&start_date=${filters.start_date}&end_date=${filters.end_date}&log_type=SITE`);
      console.log("132", res);
      if (res.status) {
        setSiteLogList(res.data.data);
        setPaginationData({
          currentPage: res.data.pagination.currentPage,
          itemsPerPage: res.data.pagination.itemsPerPage,
          totalPages: res.data.pagination.totalPages,
          totalItems: res.data.pagination.totalItems,
        });
      }
    } catch (error) {
      console.error("Error fetching project lists", error);
    }
  }

  useEffect(()=> {
    fetchSiteLogList(1);
  },[])



  const getChildren = () => {
    return <Box className="researchersMainBox pageContainer">
      <Box className="workspaceItemBox CompaniesBox">
        <BulkImportDetailPop bulkImportDetailPop={bulkImportDetailPop} setBulkImportDetailPop={setBulkImportDetailPop} selectedLogDetails={selectedLogDetails}/>
        <Box className="AllCSubBox allUFilters">
          <Box className="headerText platformHeader">
            <Typography className="fSiteLogTitle">Site Log</Typography>
            <Box className="fHeaderTextLink">
              <img src={VideoIcon} />
              <Typography className="fHeaderText fHeaderTextLink">
                Recent Video Minutes Used
              </Typography>
            </Box>
          </Box>
          <Typography className="siteLogFilterLabel">Filters</Typography>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
            <Box sx={{ width: "100%" }} className="filterBox">
              <Box className="companiesTopBox">
                <Box className="companyInputBoxWlabel">
                  <Typography className="inputLabel">Search Log</Typography>
                  <Box>
                    <SearchBar handleFilterChange={handleFilterChange} placeholder="Search Log" val={filterState} />
                  </Box>
                </Box>
              </Box>

              {/* <Box className="companiesTopBox">
                <Box className="companyInputBoxWlabel">
                  <Typography className="inputLabel">Groups</Typography>
                  <Box
                    pl={4.5}
                    className="inputAppDrop"
                    onClick={() => setGroupDrop(!groupDrop)}
                  >
                    <img className="groupsIcon" src={groupsIcon} />
                    <Typography>{groupDropVal || "Search Groups"}</Typography>
                    <img
                      style={{ rotate: groupDrop ? "180deg" : "0deg" }}
                      src={DownArrowIcon}
                    />
                    pop
                    <Box
                      className="statusPop"
                      sx={{ height: groupDrop ? "auto" : "0px" }}
                    >
                      <Box
                        onClick={() => setGroupDropVal("Item-A")}
                        className="statusPopItem"
                      >
                        <Typography>Item-A</Typography>
                      </Box>
                      <Box
                        onClick={() => setGroupDropVal("Item-B")}
                        className="statusPopItem"
                      >
                        <Typography>Item-B</Typography>
                      </Box>
                      <Box
                        onClick={() => setGroupDropVal("Item-C")}
                        className="statusPopItem"
                      >
                        <Typography>Item-C</Typography>
                      </Box>
                      <Box
                        onClick={() => setGroupDropVal("Item-D")}
                        className="statusPopItem"
                      >
                        <Typography>Item-D</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box> */}

              <Box className="companiesTopBox platformTopBox">
                {/* <Box className="companyInputBoxWlabel">
                  <Typography className="inputLabel">Status</Typography>

                  <Box
                    className="inputAppDrop"
                    onClick={() => setProjectStatusDrop(!projectStatusDrop)}
                  >
                    <Typography>
                      {projectStatusDropVal || "Filter by Status"}
                    </Typography>
                    <img
                      style={{
                        rotate: projectStatusDrop ? "180deg" : "0deg",
                      }}
                      src={DownArrowIcon}
                    />
                    pop
                    <Box
                      sx={{ height: projectStatusDrop ? "auto" : "0px" }}
                      className="statusPop"
                    >
                      <Box
                        onClick={() => setProjectStatusDropVal("Item-A")}
                        className="statusPopItem"
                      >
                        <Typography>Item-A</Typography>
                      </Box>
                      <Box
                        onClick={() => setProjectStatusDropVal("Item-B")}
                        className="statusPopItem"
                      >
                        <Typography>Item-B</Typography>
                      </Box>
                      <Box
                        onClick={() => setProjectStatusDropVal("Item-C")}
                        className="statusPopItem"
                      >
                        <Typography>Item-C</Typography>
                      </Box>
                      <Box
                        onClick={() => setProjectStatusDropVal("Item-D")}
                        className="statusPopItem"
                      >
                        <Typography>Item-D</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box> */}
                {/* workspace status */}

                <Box className="companyInputBoxWlabel">
                  <Typography className="inputLabel">Category / Action</Typography>

                  <Box
                    className="inputAppDrop"
                    onClick={() => setUserTypeDrop(!userTypeDrop)}
                  >
                    <Typography>
                      {userTypeDropVal || "Filter Category / Action"}
                    </Typography>
                    <img
                      style={{
                        rotate: userTypeDrop ? "180deg" : "0deg",
                      }}
                      src={DownArrowIcon}
                    />
                    {/* pop */}
                    <Box
                      sx={{ height: userTypeDrop ? "auto" : "0px" }}
                      className="statusPop"
                    >
                      <Box
                        onClick={() => {
                          setUserTypeDropVal("PARTICIPANT_BULK_IMPORT");
                          handleFilterChange({ action_type: 'PARTICIPANT_BULK_IMPORT' })
                        }}
                        className="statusPopItem"
                      >
                        <Typography>PARTICIPANT_BULK_IMPORT</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="companyInputBoxWlabel">
                  <Typography className="inputLabel">Date Range </Typography>
                  <DateRangePicker 
                    ref={dateRangePickerRef}
                    onChange={(range)=> {
                    setValue(range);
                    const adjustForTimezone = (date) => {
                      const offset = date.getTimezoneOffset();
                      const adjustedDate = new Date(date.getTime() - (offset * 60 * 1000));
                      return adjustedDate;
                    };
                    if (range && range.length === 2) {
                      const startDate = adjustForTimezone(range[0]).toISOString().split('T')[0];
                      const endDate = adjustForTimezone(range[1]).toISOString().split('T')[0];
                      
                      console.log("Adjusted Start Date", startDate);
                      console.log("Adjusted End Date", endDate);
              
                      handleFilterChange({
                        start_date: startDate,
                        end_date: endDate
                      });
                    } else {
                      handleFilterChange({
                        start_date: '',
                        end_date: ''
                      });
                    }
                  }} value={value} />
                </Box>
                
                {/* <Box className="companyInputBoxWlabel">
                  <Typography className="inputLabel">Date Range </Typography>

                  <Box
                    className="inputAppDrop"
                    onClick={() => setDateRangeDrop(!dataRangeDrop)}
                  >
                    <Typography>
                      {dataRangeDropVal || "Select Date Range"}
                    </Typography>
                    <img
                      style={{ rotate: dataRangeDrop ? "180deg" : "0deg" }}
                      src={DownArrowIcon}
                    />
                    {/* pop *
                    <Box
                      sx={{ height: dataRangeDrop ? "auto" : "0px" }}
                      className="statusPop"
                    >
                      <Box
                        onClick={() => setDateRangeDropVal("Item-A")}
                        className="statusPopItem"
                      >
                        <Typography>Item-A</Typography>
                        <Typography>Hello</Typography>
                      </Box>
                      <Box
                        onClick={() => setDateRangeDropVal("Item-B")}
                        className="statusPopItem"
                      >
                        <Typography>Item-B</Typography>
                      </Box>
                      <Box
                        onClick={() => setDateRangeDropVal("Item-C")}
                        className="statusPopItem"
                      >
                        <Typography>Item-C</Typography>
                      </Box>
                      <Box
                        onClick={() => setDateRangeDropVal("Item-D")}
                        className="statusPopItem"
                      >
                        <Typography>Item-D</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box> */}

              </Box>
              {/* Bottom Filter */}
              <Box
                sx={{ display: "flex", justifyContent: "flex-end" }}
                className="filterBottomBox"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "86%",
                  }}
                >
                   <Typography mx={3} className="LinkText" onClick={clearFilters}>Clear</Typography>
                  {/* <Typography mx={3} className="LinkText">
                    Apply
                  </Typography> */}
                  <Typography>{paginationData?.totalItems} matches</Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "11%",
                  }}
                >
                  <Box className="languageBtnBox">
                    <AppBtn buttonText="Export" />
                  </Box>
                </Box>

              </Box>
              {/* Finishes here */}
            </Box>
          </Box>
          {/* Log Table */}
          <Box className="companyListBox workListBox siteLogList">
            <Box className="haderRow platform" minWidth={"1120px"}>
              {/* <Box className="Tabelsel navFsel TabelselItemStart" minWidth={"17%"}>
                <Typography ml={3}>Status</Typography>
              </Box> */}
              <Box minWidth={"20%"} className="Tabelsel Tabelcentersel">
                <Typography>Summary</Typography>
              </Box>
              <Box minWidth={"20%"} className="Tabelsel Tabelcentersel">
                <Typography>Category / Action</Typography>
              </Box>

              <Box minWidth={"20%"} className="Tabelsel Tabelcentersel">
                <Typography>User Name</Typography>
              </Box>
              <Box minWidth={"20%"} className="Tabelsel Tabelcentersel">
                <Typography>Date</Typography>
              </Box>
            </Box>

            {siteLogList &&
              siteLogList?.map((el, index) => (
                <Box key={index} className="TabelRow" minWidth={"1120px"}>
                  {/* <Box className="Tabelsel navFsel" minWidth={"17%"}>
                    <button className="status">{el.status}</button>
                  </Box> */}
                  <Box
                    onClick={() => {
                      setBulkImportDetailPop(true);
                      setSelectedLogDetails(el);
                      GoTop();
                    }}
                    minWidth={"20%"} className="Tabelsel summary TabelStatesel">
                    <Typography>{el?.action_type}</Typography>
                    <Typography sx={{ textDecoration: "underline" }}>Details</Typography>
                  </Box>
                  <Box minWidth={"20%"} className="Tabelsel summary TabelStatesel">
                    <Typography>{el?.action_type}</Typography>
                    <Typography sx={{ color: "#475467" }}>{el?.log_type}</Typography>
                  </Box>

                  <Box minWidth={"20%"} className="Tabelsel summary TabelStatesel">
                    <Typography>{el?.user_details[0]?.name}</Typography>
                    <Typography sx={{ color: "#475467" }}>{el?.user_details[0]?.username}</Typography>
                  </Box>

                  <Box minWidth={"20%"} className="Tabelsel Tabelcentersel">
                    <Typography>{formatDate(el?.created_at)}</Typography>
                  </Box>
                </Box>
              ))}
          </Box>
            <Box className="pagination">
              <Pagination
                paginationData={paginationData}
                onPageChange={onActivityChange}
              />
            </Box>
          {/* Log Table */}
        </Box>
        {/* <Box className="AllProjectBox">
    
    
  </Box> */}
      </Box>
    </Box>
  }

  return renderUserLayout(authState?.userType, null, getChildren(), 'workadmin');
}
