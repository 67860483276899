import React, { useState, useEffect } from "react";
import { Box, Typography, Avatar, Grid, Button, Chip } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.css";

//images
import backArrow from "../../../Assets/Images/noun-back.png";
import ProfileImage from "../../../Assets/Images/profile-picture.png";
import detailsIcon from "../../../Assets/Images/icon-info.svg";
import shieldsIcon from "../../../Assets/Images/shiled.png";
import { useAppSelector } from "../../../lib/store/store";
import DummyUser from "../../../Assets/Images/user-dummy.png";
//data
import {
  participants,
  sideHaumIcon,
} from "../../../Assets/Data";

//components
import {
    AllDrop8,
} from "../../../Components/AppTools/AppTool";
import { AppBtn, AppBtnOutLine } from "../../../Components/AppTools/AppButton";
import SideMenu from "../../../Components/Researchers/SideMenu";
import Thread from "../../../Components/Researchers/ActivityTasksThread";
import Images from "../../../Components/Researchers/ActivityTasksImages";
import Videos from "../../../Components/Researchers/ActivityTasksVideos";
import Audio from "../../../Components/Researchers/ActivityTasksAudio";
import AdjustPointPopup from "../../../Components/Researchers/AdjustPointPopup";
import { USER_TYPE_COLLABORATOR,  USER_TYPE_RESEARCHER, USER_TYPE_OBSERVER } from "../../../lib/constants";
import { API_BASE_URL } from "../../../env";
import axios from "../../../lib/axios";
import { viewImage } from "../../../lib/helpers";

export default function ViewParticipantProfile({
  workspaceDrop,
  setWorkSpaceDrop,
  slideMenu,
  setSlideMenu,
}) {
  let authState = useAppSelector((state) => state.auth);
  const { participantId } = useParams();
    const {
        t,
        i18n: { changeLanguage, language },
      } = useTranslation();
  const Navigate = useNavigate();
  const [responsesNav, setResponsesNav] = useState("Thread");

  const [currentPage, setCurrentPage] = useState(0);
  const [newArrayData, setNewArrayData] = useState([]);
  const [allDrop4, setAllDrop4] = useState();
  const [allDropVal4, setAllDropVal4] = useState("Options");
  const [loading, setLoading] = useState(false);
  const [participantData, setParticipantData] = useState(false);

  useEffect(() => {
    const getParticipantDetail = async () => {
      try {
        setLoading(false);
        const response = await axios.get(
          `${API_BASE_URL}/super-admin/admin/participants?_id=${participantId}`
        );
        if (response.success) {
          setParticipantData(response.data.data[0]);
          console.log("==========Participant data===============", response);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(true); 
      }
    };
    if (participantId) {
      getParticipantDetail();
    }
  }, [participantId]);

  const participantProfileDropItem = [
    "Support Login",
    "Adjust Points",
    "Reports",
    "Transcripts",
    "Edit Participant",
    "Remove from Project",
  ];
  const participantProfileDropItem2 = [
    "Adjust Points",
    "Reports",
    "Transcripts",
  ];
  const participantProfileDropItem3 = authState?.wsUserType == (USER_TYPE_RESEARCHER || USER_TYPE_COLLABORATOR) ? participantProfileDropItem : participantProfileDropItem2;

  useEffect(() => {
    printItemsByState(participants, currentPage);
  }, [currentPage]);

  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }
  //

  return (
    <>
      <Box className="ResearchersContainer ">
        <AdjustPointPopup allDropVal4={allDropVal4} setAllDropVal4={setAllDropVal4} />
        {/* side menu */}
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />

        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>

            <Box sx={{gap:"15px"}} className="AppNavBtnBox">
              <Box sx={{ width: "30%", minWidth: "196px" }}>
                <AllDrop8
                  drop={allDrop4}
                  setDrop={setAllDrop4}
                  dropVal={allDropVal4}
                  serDropVal={setAllDropVal4} 
                  itemAry={participantProfileDropItem3}
                />
              </Box>
              <Box>
              {authState?.wsUserType == USER_TYPE_RESEARCHER || authState?.wsUserType == USER_TYPE_COLLABORATOR ? 
              (<AppBtnOutLine buttonText={t("commonTxt.actionLogBtn")} />) : ''
              }
              <AppBtn buttonText={t("commonTxt.viewMessagesBtn")} onClick={() => Navigate("/view-message-profile")} />
              </Box>
            </Box>
          </Box>

          {/* Page container */}
          <Box className="researchersMainBox pageContainer">
            {loading && (
              <Box className="workSpaceListBox">
                <Box mb={2}
                    width="74px"
                    onClick={() => Navigate(`/activities/task`)}
                    className="openBtn"
                >
                    <img src={backArrow} />
                    <Typography className="neonBackText">{t("commonTxt.backBtn")}</Typography>
                </Box>
                <Box className="ProfileDetailsPanel">
                    <Box className="ProfilePicture">
                    <Avatar alt="" src={participantData?.profile_pic === "undefined" ||
          participantData?.profile_pic == null ? DummyUser : viewImage(participantData?.profile_pic)} sx={{ width: 112, height: 112 }} />
                    </Box>
                    <Box className="ProfileDetails">
                    <Typography variant="h4" gutterBottom>
                        {participantData?.name}
                    </Typography>
                    <Grid container spacing={5}>
                        <Grid item xs={5} md={7}>
                        <Box  sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            }}>
                            <Button className="infoBtn" variant="text">
                                <img src={detailsIcon} /> 
                            </Button>
                            <Typography className="neonBackText">{t("viewParticipantProfile.viewParticipantProfileDetails")}</Typography>
                        </Box>
                        </Grid>
                        <Grid item xs={7} md={5}>
                        <Box
                            sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: "20px",
                            }}
                        >
                            <img src={shieldsIcon} />
                            <Typography variant="h6">{t("viewParticipantProfile.viewParticipantProfileTera")}</Typography>
                            <Chip label="1500 Points" />
                        </Box>
                        </Grid>
                    </Grid>
                    </Box>
                </Box>
                <Box className="ProfileDataPanel">
                    <Box className="panel">
                    {t("viewParticipantProfile.viewParticipantProfileProject")} <span>{t("viewParticipantProfile.viewParticipantProfileJoined")}</span> <strong>Jul 2, 2023</strong>
                    </Box>
                    <Box className="panel">
                    {t("viewParticipantProfile.viewParticipantProfileProject")} <span>{t("viewParticipantProfile.viewParticipantProfileVisits")}</span> <strong>265</strong>
                    </Box>
                    <Box className="panel">
                    {t("viewParticipantProfile.viewParticipantProfileActivities")} <span>{t("viewParticipantProfile.viewParticipantProfileCompleted")}</span> <strong>122</strong>
                    </Box>
                    <Box className="panel">
                    {t("viewParticipantProfile.viewParticipantProfileLast")} <span>{t("viewParticipantProfile.viewParticipantProfileVisits")}</span> <strong>Jul 2, 2023</strong>
                    </Box>
                    <Box className="panel">
                    {t("viewParticipantProfile.viewParticipantProfileLastEmail")} <span>{t("viewParticipantProfile.viewParticipantProfileOpened")}</span> <strong>Jul 2, 2023</strong>
                    </Box>
                </Box>
                <Box className="taskTable">

                    {/* -------===========--------Responses------======------------ */}

                    <Box className="ActiveTaskSectionBox">
                    <Box className="responsesNav">
                        <Box
                        className={
                            responsesNav === "Thread"
                            ? "responsesNavItem responsesNavItemActive"
                            : "responsesNavItem"
                        }
                        onClick={() => setResponsesNav("Thread")}
                        >
                        <Typography>{t("viewParticipantProfile.viewParticipantProfileNav1")}</Typography>
                        </Box>
                        <Box
                        className={
                            responsesNav === "Expressions"
                            ? "responsesNavItem responsesNavItemActive"
                            : "responsesNavItem"
                        }
                        onClick={() => setResponsesNav("Expressions")}
                        >
                        <Typography>{t("viewParticipantProfile.viewParticipantProfileNav2")}</Typography>
                        </Box>
                        <Box
                        className={
                            responsesNav === "Images"
                            ? "responsesNavItem responsesNavItemActive"
                            : "responsesNavItem"
                        }
                        onClick={() => setResponsesNav("Images")}
                        >
                        <Typography>{t("viewParticipantProfile.viewParticipantProfileNav3")}</Typography>
                        </Box>
                        <Box
                        className={
                            responsesNav === "Videos"
                            ? "responsesNavItem responsesNavItemActive"
                            : "responsesNavItem"
                        }
                        onClick={() => setResponsesNav("Videos")}
                        >
                        <Typography>{t("viewParticipantProfile.viewParticipantProfileNav4")}</Typography>
                        </Box>
                        <Box
                        className={
                            responsesNav === "Audios"
                            ? "responsesNavItem responsesNavItemActive"
                            : "responsesNavItem"
                        }
                        onClick={() => setResponsesNav("Audios")}
                        >
                        <Typography>{t("viewParticipantProfile.viewParticipantProfileNav5")}</Typography>
                        </Box>
                    </Box>
                    {/* -----------Thread------------- */}
                    <Box
                        display={responsesNav === "Thread" ? "block" : "none"}
                        className="responsesSection ThreadBox"
                    >
                        <Thread />
                    </Box>
                    {/* -----------Expressions------------- */}
                    <Box
                        display={
                        responsesNav === "Expressions" ? "block" : "none"
                        }
                        className="responsesSection "
                    ></Box>
                    {/* -----------Images------------- */}
                    <Box
                        display={responsesNav === "Images" ? "block" : "none"}
                        className="responsesSection "
                    >
                        <Images />
                    </Box>
                    {/* -----------Videos------------- */}
                    <Box
                        display={responsesNav === "Videos" ? "block" : "none"}
                        className="responsesSection "
                    >
                        <Videos />
                    </Box>
                    {/* -----------Audios------------- */}
                    <Box
                        display={responsesNav === "Audios" ? "block" : "none"}
                        className="responsesSection "
                    >
                        <Audio />
                    </Box>
                    </Box>
                </Box>
            </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
