import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material';
import IIcon from "../../../Assets/Images/IIcon.png"

//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton"
import { updatePassword } from '../../../Services/auth';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../../lib/store/store';

export default function IdentityVerificationPop({ identityVerificationPop, setIdentittyVerificationPop, updatePasswordSt,
  setUpdatePassword }) {

  const authState = useAppSelector((state) => state.auth);
  const handelPopClose = (e) => {
    if (e.target.id === "popVerifydrop") {
      setIdentittyVerificationPop(false)
    }
  }
  useEffect(() => {
    if (identityVerificationPop) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = 'auto'; // Enable scrolling
    }
  }, [identityVerificationPop])

  const changePassSubmit = async () => {
    try {
      // setLoading(true)
      console.log(updatePasswordSt)
      if (updatePasswordSt.otp.length < 6) {
        return toast.success("invalid OTP");
      }
      let response = await updatePassword(updatePasswordSt, authState?.authToken);
      // setLoading(false)
      if (response.success) {
        setIdentittyVerificationPop(false)
        setUpdatePassword({
          "previous_password": "",
          "password": "",
          "confirm_password": "",
          "otp": "",
        })
        toast.success(response.message)
      }else{
        toast.error(response.message)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  return (
    <>
      <Box onClick={handelPopClose} id="popVerifydrop" sx={{ display: identityVerificationPop ? "flex" : "none" }} className="emailVerifyPopContainer">

        <Box className="emailVerifyDeleteBox">
          <Typography className='emailVerifyPopHeader'>Verify your Identity</Typography>
          <Box className="emailVerifyTextBox infoEmailVerifyTextBox">
            <Box className="iIcontD"><img src={IIcon} /></Box>
            <Box>
              <Typography>For your protection, a code has been send on your new Email Address. Kindly check your mail and enter the code in the box below.</Typography>
            </Box>
          </Box>
          <Box className="emailVerifyBox lastVerifyBasicBox">
            <Box className="inputBox">
              <input className="addResearcherInput" onChange={e => {
                setUpdatePassword({ ...updatePasswordSt, otp: e.target.value })
              }} placeholder="Enter Code" />
            </Box>
          </Box>
          <Box className="emailVerifyPopBtnBox">
            <AppBtnOutLine onClick={() => setIdentittyVerificationPop(false)} buttonText="Close" />
            <AppBtn buttonText="Continue" onClick={changePassSubmit} />
          </Box>
        </Box>


      </Box>
    </>
  )
}
