import { useNavigate } from "react-router";

// images
import HomeIconPurple from "../../../Assets/Images/HomeIconPruple.png";
import HomeIconBlack from "../../../Assets/Images/HomeIconBlack.png";
import clogsvg from "../../../Assets/Images/New folder/Icon TeraPage Transparent mix dark 2.svg";
import SClolo from "../../../Assets/Images/TeraPage Transparent mix black.png";
import AiIcon from "../../../Assets/Images/AiIcon1.png";
import AiIconPurple from "../../../Assets/Images/AiIConPurple.png";
import PestIcon from "../../../Assets/Images/AdPestIcon.png";
import PestIconPurple from "../../../Assets/Images/AdPestIconPurple.png";
import PhoneIcon from "../../../Assets/Images/UserDir.png";
import FolderIcon from "../../../Assets/Images/AdFolderIcon.png";
import ArrowIcon from "../../../Assets/Images/AdArrowIcon.png";
import ArrowIconPurple from "../../../Assets/Images/AdArrowIconPurple.png";
import SetingIcon from "../../../Assets/Images/AdSettingIcon.png";
import workSpaceIcon from "../../../Assets/Images/workspaceMenuIcon.png";
import EmailIcon from "../../../Assets/Images/mailBicon.png";
import EmailIconPurple from "../../../Assets/Images/mailBiconPurple.png";
import EditIcon from "../../../Assets/Images/editIcon.png";
import EditIconPurple from "../../../Assets/Images/editIconPurple.png";
import CompanyIcon from "../../../Assets/Images/Building3.png";
import CompanyIconPurple from "../../../Assets/Images/Building3Purple.png";
import UserIcon from "../../../Assets/Images/users.png";
import UserIconBlack from "../../../Assets/Images/usersBlack.png";
import SuperAdmin from "../../../Assets/Images/Superadmin.png";
import SuperAdminPurple from "../../../Assets/Images/SuperadminPurple.png";
import bookIcon from "../../../Assets/Images/book.png";
import bookIconPurple from "../../../Assets/Images/bookIconPurple.png";
import mailIcon from "../../../Assets/Images/mailBicon.png";
import fileIcon from "../../../Assets/Images/file.png";
import fileIconPurple from "../../../Assets/Images/filePurple.png";
import appearance from "../../../Assets/Images/Traced Image.png";
import languageIcon from "../../../Assets/Images/message-square.png";
import actionIcon from "../../../Assets/Images/actionDark.png";
import actionIconPurple from "../../../Assets/Images/actionPurple.png";
import apiIcon from "../../../Assets/Images/apiIcon.png";
import contactIcon from "../../../Assets/Images/contactIcon.png";
import contactIconBlue from "../../../Assets/Images/contactIconBlue.png";
import { Box, Typography } from "@mui/material";
import { logout } from "../../../Services/auth";
import { useAppDispatch, useAppSelector } from "../../../lib/store/store";
import users from "../../../Assets/Images/usersB.png";
import groupIcon from "../../../Assets/Images/groupsIcon.png";
import participantIcon from "../../../Assets/Images/participantIcon.png";
import bulkIcon from "../../../Assets/Images/bulkIcon.png";
import usersSetting from "../../../Assets/Images/userSetting.png";
import WAIcon07 from "../../../Assets/Images/icon-WA07.svg";
import InformationIcon from "../../../Assets/Images/InformationIcon.png";
import BasicSettingIcon from "../../../Assets/Images/AdSettingIcon.png";
import SubscriptionIcon from "../../../Assets/Images/SubscriptionIcon.png";
import AccountIcon from "../../../Assets/Images/AccountIcon.png";
import SiteNoticesIcon from "../../../Assets/Images/SiteNoticesIcon.png";
import AgreementsIcon from "../../../Assets/Images/editIcon.png";
import PWIcon from "../../../Assets/Images/icon-ASetting02.svg";
import {
  initialSidebars,
  setSidebarDropdowns,
} from "../../../lib/store/features/sidebarSlice";

export const Sidebar = ({ slideMenu }) => {
  const sidebarState = useAppSelector((state) => state.sidebar);
  const currentUrl = new URL(window.location.href).pathname;
  const Navigate = useNavigate();
  let dispatch = useAppDispatch();
  let authState = useAppSelector((state) => state.auth);

  const workspaceState = useAppSelector((state) => state.workspace);

  const navigateSidebar = (url = null, dropdownType = null) => {
    let dropdowns = {
      ...initialSidebars,
    };

    switch (dropdownType) {
      case "workspaceItemNavDrop":
        dispatch(
          setSidebarDropdowns({
            ...dropdowns,
            workspaceItemNavDrop:
              url == "/admin/workspace-management/splash"
                ? !sidebarState.dropdowns.workspaceItemNavDrop
                : true,
          })
        );
        break;
      case "templateDrop":
        dispatch(
          setSidebarDropdowns({
            ...dropdowns,
            templateDrop:
              url == null ? !sidebarState.dropdowns.templateDrop : true,
          })
        );
        break;
      case "userDirectoryDrop":
        dispatch(
          setSidebarDropdowns({
            ...dropdowns,
            userDirectoryDrop:
              url == null ? !sidebarState.dropdowns.userDirectoryDrop : true,
          })
        );
        break;
      case "systemSettingsDrop":
        dispatch(
          setSidebarDropdowns({
            ...dropdowns,
            systemSettingsDrop:
              url == null ? !sidebarState.dropdowns.systemSettingsDrop : true,
          })
        );
        break;
      case "allParticipantsDrop":
        dispatch(
          setSidebarDropdowns({
            ...dropdowns,
            allParticipantsDrop:
              url == null ? !sidebarState.dropdowns.allParticipantsDrop : true,
            workspaceItemNavDrop: true,
          })
        );
        break;
      case "configurationDrop":
        dispatch(
          setSidebarDropdowns({
            ...dropdowns,
            configurationDrop:
              url == null ? !sidebarState.dropdowns.configurationDrop : true,
            workspaceItemNavDrop: true,
          })
        );
        break;
      default:
        dispatch(setSidebarDropdowns(dropdowns));
        break;
    }

    if (url != null) {
      Navigate(url);
    }
  };

  return (
    <>
      <Box className={slideMenu ? "sideMenu sideMenuActive" : "sideMenu"}>
        <Box onClick={() => Navigate("/")} className="clogBox">
          {slideMenu ? (
            <img style={{ width: "176.442px", height: "20px" }} src={clogsvg} />
          ) : (
            <img style={{ width: "30px" }} src={SClolo} />
          )}
        </Box>

        <Box
          onClick={() => navigateSidebar("/admin/workspace-management", null)}
          className={
            currentUrl === "/admin/workspace-management"
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
          title={slideMenu ? '' : 'Workspace Management'}
        >
          {currentUrl === "/admin/workspace-management" ? (
            <img src={HomeIconPurple}/>
          ) : (
            <img src={HomeIconBlack}/>
          )}
          {/* <img src={HomeIconWhite} /> */}
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Workspace Management
          </Typography>
        </Box>

        <Box
          onClick={() => navigateSidebar("/admin/ai-integration", null)}
          className={
            currentUrl === "/admin/ai-integration"
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
          title={slideMenu ? '' : 'AI Integration'}
        >
          {currentUrl === "/admin/ai-integration" ? (
            <img src={AiIconPurple} />
          ) : (
            <img src={AiIcon} />
          )}
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            AI Integration
          </Typography>
        </Box>

        <Box
          onClick={() => navigateSidebar("/admin/reports", null)}
          className={
            currentUrl === "/admin/reports"
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
          title={slideMenu ? '' : 'Reports & Publications'}
        >
          {/* <img src={PestIcon} /> */}
          {currentUrl === "/admin/reports" ? (
            <img src={PestIconPurple} />
          ) : (
            <img src={PestIcon} />
          )}

          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Reports & Publications
          </Typography>
        </Box>
        {/* User Directory Side-Menu */}
        <Box
          onClick={() => navigateSidebar(null, "userDirectoryDrop")}
          className={
            sidebarState.dropdowns.userDirectoryDrop
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
          title={slideMenu ? '' : 'User Directory'}
        >
          <img src={PhoneIcon} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            User Directory
          </Typography>
        </Box>
        {/* User Directory Sub Menu */}
        <Box
          sx={{
            display: sidebarState.dropdowns.userDirectoryDrop ? "flex" : "none",
          }}
          className="UserSubNavMenu"
        >
          <Box
            onClick={() => {
              navigateSidebar(
                "/admin/userdirectory/company",
                "userDirectoryDrop"
              );
            }}
            className={
              currentUrl === "/admin/userdirectory/company"
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
            title={slideMenu ? '' : 'Companies'}
          >
            {/* <img src={CompanyIcon} /> */}
            {currentUrl === "/admin/userdirectory/company" ? (
              <img src={CompanyIconPurple} />
            ) : (
              <img src={CompanyIcon} />
            )}
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Companies
            </Typography>
          </Box>
          <Box
            onClick={() => {
              navigateSidebar(
                "/admin/userdirectory/users",
                "userDirectoryDrop"
              );
            }}
            className={
              currentUrl === "/admin/userdirectory/users"
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
            title={slideMenu ? '' : 'Users'}
          >
            {currentUrl === "/admin/userdirectory/users" ? (
              <img src={UserIcon} />
            ) : (
              <img src={UserIconBlack} />
            )}
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Users
            </Typography>
          </Box>
          <Box
            onClick={() => {
              navigateSidebar(
                "/admin/userdirectory/super-admin",
                "userDirectoryDrop"
              );
            }}
            className={
              currentUrl === "/admin/userdirectory/super-admin"
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
            title={slideMenu ? '' : 'Super Admins'}
          >
            {currentUrl === "/admin/userdirectory/super-admin" ? (
              <img src={SuperAdminPurple} />
            ) : (
              <img src={SuperAdmin} />
            )}
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Super Admins
            </Typography>
          </Box>
        </Box>

        {/* User Directory Sub Menu Ends Here*/}
        <Box
          onClick={() => navigateSidebar(null, "templateDrop")}
          className={
            sidebarState.dropdowns.templateDrop
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
          title={slideMenu ? '' : 'Templates'}
        >
          <img src={FolderIcon} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Templates
          </Typography>
        </Box>
        {/* subNavMenu */}
        {/* sub menu */}
        <Box
          sx={{
            display: sidebarState.dropdowns.templateDrop ? "flex" : "none",
          }}
          className=" UserSubNavMenu"
        >
          <Box
            onClick={() =>
              navigateSidebar("/admin/templates/email", "templateDrop")
            }
            className={
              currentUrl === "/admin/templates/email"
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
            title={slideMenu ? '' : 'Email'}
          >
            {currentUrl === "/admin/templates/email" ? (
              <img src={EmailIconPurple} />
            ) : (
              <img src={EmailIcon} />
            )}
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Email
            </Typography>
          </Box>
          <Box
            onClick={() =>
              navigateSidebar("/admin/templates/agreement", "templateDrop")
            }
            className={
              currentUrl === "/admin/templates/agreement"
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
            title={slideMenu ? '' : 'Agreement'}
          >
            {currentUrl === "/admin/templates/agreement" ? (
              <img src={EditIconPurple} />
            ) : (
              <img src={EditIcon} />
            )}
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Agreement
            </Typography>
          </Box>
        </Box>
        {/* sub menu end */}

        <Box
          onClick={() => navigateSidebar("/direct-messages", null)}
          className={
            currentUrl === "/direct-messages"
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
          title={slideMenu ? '' : 'Direct Message'}
        >
          {currentUrl === "/direct-messages" ? (
            <img src={ArrowIconPurple} />
          ) : (
            <img src={ArrowIcon} />
          )}
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Direct Message
          </Typography>
        </Box>

        <Box
          onClick={() => navigateSidebar(null, "systemSettingsDrop")}
          className={
            sidebarState.dropdowns.systemSettingsDrop
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
          title={slideMenu ? '' : 'System Settings'}
        >
          <img src={SetingIcon} />
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            System Settings
          </Typography>
        </Box>
        {/* sub menu */}
        {/* System settings sub-menu starts here */}
        <Box
          sx={{
            display: sidebarState.dropdowns.systemSettingsDrop
              ? "flex"
              : "none",
          }}
          className="UserSubNavMenu"
        >
          <Box
            onClick={() => {
              navigateSidebar(
                "/admin/system/platformlog",
                "systemSettingsDrop"
              );
            }}
            className={
              currentUrl === "/admin/system/platformlog"
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
            title={slideMenu ? '' : 'Platform Log'}
          >
            {currentUrl === "/admin/system/platformlog" ? (
              <img src={bookIconPurple} />
            ) : (
              <img src={bookIcon} />
            )}
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Platform Log
            </Typography>
          </Box>
          <Box
            onClick={() =>
              navigateSidebar("/admin/system/actionlog", "systemSettingsDrop")
            }
            className={
              currentUrl === "/admin/system/actionlog"
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
            title={slideMenu ? '' : 'Action Log'}
          >
            {currentUrl === "/admin/system/actionlog" ? (
              <img src={actionIconPurple} />
            ) : (
              <img src={actionIcon} />
            )}
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Action Log
            </Typography>
          </Box>

          <Box
            onClick={() =>
              navigateSidebar(
                "/admin/system/email-settings",
                "systemSettingsDrop"
              )
            }
            className={
              currentUrl === "/admin/system/email-settings"
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
            title={slideMenu ? '' : 'Email Settings'}
          >
            {currentUrl === "/admin/system/email-settings" ? (
              <img src={EmailIconPurple} />
            ) : (
              <img src={EmailIcon} />
            )}
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Email Settings
            </Typography>
          </Box>
          <Box
            onClick={() =>
              navigateSidebar(
                "/admin/system/files-configuration",
                "systemSettingsDrop"
              )
            }
            className={
              currentUrl === "/admin/system/files-configuration"
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
            title={slideMenu ? '' : 'Files Configuration'}
          >
            {currentUrl === "/admin/system/files-configuration" ? (
              <img src={fileIconPurple} />
            ) : (
              <img src={fileIcon} />
            )}

            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Files Configuration
            </Typography>
          </Box>
          <Box
            onClick={() =>
              navigateSidebar("/admin/system/appearance", "systemSettingsDrop")
            }
            className={
              currentUrl === "/admin/system/appearance"
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
            title={slideMenu ? '' : 'Appearance'}
          >
            <img src={appearance} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Appearance
            </Typography>
          </Box>
          <Box
            onClick={() =>
              navigateSidebar("/admin/system/language", "systemSettingsDrop")
            }
            className={
              currentUrl === "/admin/system/language"
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
            title={slideMenu ? '' : 'Language'}
          >
            <img src={languageIcon} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Language
            </Typography>
          </Box>
          <Box
            onClick={() =>
              navigateSidebar(
                "/admin/system/api-credential",
                "systemSettingsDrop"
              )
            }
            className={
              currentUrl === "/admin/system/api-credential"
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
            title={slideMenu ? '' : 'APIs Credentials'}
          >
            <img src={apiIcon} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              APIs Credentials
            </Typography>
          </Box>
          {/* Contact Support */}
          <Box
            onClick={() =>
              navigateSidebar(
                "/admin/system/contact-tickets",
                "systemSettingsDrop"
              )
            }
            className={
              currentUrl === "/admin/system/contact-tickets"
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
            title={slideMenu ? '' : 'Contact and Tickets'}
          >
            {currentUrl === "/admin/system/contact-tickets" ? (
              <img src={contactIconBlue} />
            ) : (
              <img src={contactIcon} />
            )}
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Contact and Tickets
            </Typography>
          </Box>
          {/* Contact Support */}
          <Box
            onClick={() =>
              navigateSidebar(
                "/admin/system/email-management",
                "systemSettingsDrop"
              )
            }
            className={
              currentUrl === "/admin/system/email-management"
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
            title={slideMenu ? '' : 'Email Management'}
          >
            <img src={mailIcon} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Email Management
            </Typography>
          </Box>

          <Box
            onClick={() =>
              navigateSidebar(
                "/admin/manage-password",
                "systemSettingsDrop"
              )
            }
            className={
              currentUrl === "/admin/manage-password"
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
            title={slideMenu ? '' : 'Email Management'}
          >
            <img src={PWIcon} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Password Management
            </Typography>
          </Box>
        </Box>
        <Box
          onClick={() => logout(Navigate, dispatch, authState?.userType, authState)}
          className="AdSideMenuItem"
          title={slideMenu ? '' : 'Logout'}
        >
          <img src={HomeIconBlack} />
          {/* <img src={HomeIconWhite} /> */}
          <Typography sx={{ display: slideMenu ? "block" : "none" }}>
            Logout
          </Typography>
        </Box>
        {/* System settings sub-menu starts here */}

        {/* workspace sub mene */}
        <Box
          sx={{ display: workspaceState.selectedWorkspace ? "block" : "none" }}
          className="navHrLine"
        ></Box>
        <Box
          sx={{ display: workspaceState.selectedWorkspace ? "flex" : "none" }}
          onClick={() => {
            navigateSidebar(
              "/admin/workspace-management/splash",
              "workspaceItemNavDrop"
            );
          }}
           className={
            currentUrl === "/admin/workspace-management/splash"
              ? "AdSideMenuItem AdSideMenuItemActive"
              : "AdSideMenuItem"
          }
          title={slideMenu ? '' : 'Workspace'}
        >
          <Box className="workspaceIcon">
            <img src={workSpaceIcon} />
          </Box>
          <Typography
            className="workspaceNavText"
            sx={{ display: slideMenu ? "block" : "none" }}
          >
            {workspaceState.selectedWorkspace?.name.length > 0
              ? `${workspaceState.selectedWorkspace?.name.substring(0, 15)}...`
              : workspaceState.selectedWorkspace?.name}
          </Typography>
        </Box>

        {/* pop */}
        <Box
          className="wISideNav"
          sx={{
            display: sidebarState.dropdowns.workspaceItemNavDrop
              ? "block"
              : "none",
          }}
        >
          <Box
            onClick={() =>
              navigateSidebar(
                "/ws-management/workspace-home",
                "workspaceItemNavDrop"
              )
            }
            className={
              currentUrl === "/ws-management/workspace-home"
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
            title={slideMenu ? '' : 'Home'}
          >
            <img src={HomeIconBlack} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Home
            </Typography>
          </Box>

          <Box
            onClick={() =>
              navigateSidebar(
                "/admin/ws-management/aiintegration",
                "workspaceItemNavDrop"
              )
            }
            className={
              currentUrl === "/admin/ws-management/aiintegration"
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
            title={slideMenu ? '' : 'AI Integration'}
          >
            <img src={AiIcon} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              AI Integration
            </Typography>
          </Box>
          <Box
            onClick={() => {
              navigateSidebar(null, "allParticipantsDrop");
            }}
            className="AdSideMenuItem"
            title={slideMenu ? '' : 'Participants'}
          >
            <img src={users} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Participants
            </Typography>
          </Box>

          {/* sub menu */}
          <Box
            sx={{
              display: sidebarState.dropdowns.allParticipantsDrop
                ? "flex"
                : "none",
            }}
            className="UserSubNavMenu ParticpantsMenu"
          >
            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/all-participants",
                  "allParticipantsDrop"
                )
              }
              className={
                currentUrl === "/workspace-administration/all-participants"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'All Participants'}
            >
              <img src={users} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                All Participants
              </Typography>
            </Box>
            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/group",
                  "allParticipantsDrop"
                )
              }
              className={
                currentUrl === "/workspace-administration/group"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'Groups'}
            >
              <img src={groupIcon} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                Groups
              </Typography>
            </Box>
            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/participant-profiling",
                  "allParticipantsDrop"
                )
              }
              className={
                currentUrl === "/workspace-administration/participant-profiling"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'Participant Profiling'}
            >
              <img src={participantIcon} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                Participant Profiling
              </Typography>
            </Box>
            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/bulk-import",
                  "allParticipantsDrop"
                )
              }
              className={
                currentUrl === "/workspace-administration/bulk-import"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'Bulk Import'}
            >
              <img src={bulkIcon} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                Bulk Import
              </Typography>
            </Box>
          </Box>
          {/* sub menu end */}

          <Box
            onClick={() =>
              navigateSidebar(
                "/workspace-administration/admin",
                "workspaceItemNavDrop"
              )
            }
            className={
              currentUrl === "/workspace-administration/admin"
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
            title={slideMenu ? '' : 'Admins'}
          >
            <img src={usersSetting} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Admins
            </Typography>
          </Box>

          <Box
            onClick={() => navigateSidebar(null, "configurationDrop")}
            className={
              sidebarState.dropdowns.configurationDrop
                ? "AdSideMenuItem AdSideMenuItemActive"
                : "AdSideMenuItem"
            }
            title={slideMenu ? '' : 'Configuration'}
          >
            <img src={WAIcon07} />
            <Typography sx={{ display: slideMenu ? "block" : "none" }}>
              Configuration
            </Typography>
          </Box>

          <Box
            sx={{
              display: sidebarState.dropdowns.configurationDrop
                ? "flex"
                : "none",
            }}
            className=" UserSubNavMenu configMenu"
          >
            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/information",
                  "configurationDrop"
                )
              }
              className={
                currentUrl === "/workspace-administration/information"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'Information'}
            >
              <img src={InformationIcon} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                Information
              </Typography>
            </Box>

            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/basic-setting",
                  "configurationDrop"
                )
              }
              className={
                currentUrl === "/workspace-administration/basic-setting"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'Basic Settings'}
            >
              <img src={BasicSettingIcon} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                Basic Settings
              </Typography>
            </Box>

            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/subscription",
                  "configurationDrop"
                )
              }
              className={
                currentUrl === "/workspace-administration/subscription"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'Subscription'}
            >
              <img src={SubscriptionIcon} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                Subscription
              </Typography>
            </Box>

            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/account",
                  "configurationDrop"
                )
              }
              className={
                currentUrl === "/workspace-administration/account"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'Account'}
            >
              <img src={AccountIcon} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                Account
              </Typography>
            </Box>

            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/email",
                  "configurationDrop"
                )
              }
              className={
                currentUrl === "/workspace-administration/email"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'Email'}
            >
              <img src={EmailIcon} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                Email
              </Typography>
            </Box>

            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/site-notice",
                  "configurationDrop"
                )
              }
              className={
                currentUrl === "/workspace-administration/site-notice"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'Site Notices'}
            >
              <img src={SiteNoticesIcon} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                Site Notices
              </Typography>
            </Box>

            {/* <Box
                onClick={() => Navigate(19)}
                className={sideTabe === 19 ? "AdSideMenuItem AdSideMenuItemActive" : "AdSideMenuItem"}>
                <img src={AppearanceIcon} />
                <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                  Appearance
                </Typography>
              </Box> */}

            {/* <Box
                onClick={() => Navigate(20)}
                className={sideTabe === 20 ? "AdSideMenuItem AdSideMenuItemActive" : "AdSideMenuItem"}>
                <img src={SiteLogIcon} />
                <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                  Site Log
                </Typography>
              </Box> */}

            <Box
              onClick={() =>
                navigateSidebar(
                  "/workspace-administration/agreements",
                  "configurationDrop"
                )
              }
              className={
                currentUrl === "/workspace-administration/agreements"
                  ? "AdSideMenuItem AdSideMenuItemActive"
                  : "AdSideMenuItem"
              }
              title={slideMenu ? '' : 'Agreements'}
            >
              <img src={AgreementsIcon} />
              <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                Agreements
              </Typography>
            </Box>

            {/* <Box
                onClick={() => Navigate(22)}
                className={sideTabe === 22 ? "AdSideMenuItem AdSideMenuItemActive" : "AdSideMenuItem"}>
                <img src={TemplatesIcon} />
                <Typography sx={{ display: slideMenu ? "block" : "none" }}>
                  Templates
                </Typography>
              </Box> */}
          </Box>
        </Box>
      </Box>
    </>
  );
};
