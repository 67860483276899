import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { useTranslation } from "react-i18next";

//images
import dropArrow from "../../../Assets/Images/chevron-down.png";
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png";
import groupsIcon from "../../../Assets/Images/groupsIcon.png";
import searchIcon from "../../../Assets/Images/search.png";
import dropIcon from "../../../Assets/Images/chevron-downp.png";

import { BigDrop } from "../../AppTools/AppTool";


export default function DuplicatePagePop({ duplicatePagePop, setDuplicatePagePop, setAllDropVal3 }) {

  const [image, setImage] = useState(null);

  const [Drop, setDrop] = useState(false);
  const [DropVal, setDropVal] = useState("Select Participant Groups");

  const [Drop2, setDrop2] = useState(false);
  const [DropVal2, setDropVal2] = useState("All Administrators");

  const [groupDrop2, setGroupDrop2] = useState(false);
  const [searchDrop, setSearchDrop] = useState(false);
  const [redio, setRedio] = useState(0);

  const [adminsMore, setAdminsMore] = useState(false);
  const [selectedAdminRoles, setSelectedAdminRoles] = useState([]);
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const [selectedParticipantGroups, setSelectedParticipantGroups] = useState([]);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const ParticipantItem = [
    "All Participants",
    "Select Participant Groups",
    "None"
  ]

  const AdministratorItem = [
    "All Administrators",
    "Select Admin Roles",
    "Select Admins"
  ]


  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setDuplicatePagePop(false);
      setAllDropVal3("Page Settings");
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const chackBoxRoom = (val, text) => {
    return (
      <Box className="checkBoxItem" key={`group-${val}`}>
        <Box >
          <input
            className="custom-checkbox"
            type="checkBox"
            name={`group-${val}`}
            value={val}
            checked={selectedParticipantGroups.includes(val)}
            onChange={handleParticipantGroupsChange}
          />
          <Typography>{text}</Typography>
        </Box>
      </Box>
    );
  };

  const AdminRolesCheckBox = (val,text) => {
    return (
      <Box className="checkBoxItem" key={`adminrole-${val}`} >
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            name={`adminrole-${val}`}
            value={val}
            checked={selectedAdminRoles.includes(val)}
            onChange={handleAdminRolesChange}
          />
          <Typography>{text}</Typography>
        </Box>
      </Box>
    );
  };

  const AdminsCheckBox = (val,text) => {
    return (
      <Box className="checkBoxItem" key={`admin-${val}`}>
        <Box>
          <input
            className="custom-checkbox"
            type="checkBox"
            name={`admin-${val}`}
            value={val}
            checked={selectedAdmins.includes(val)}
            onChange={handleAdminsChange}
          />
          <Typography>{text}</Typography>
        </Box>
      </Box>
    );
  };

  const handleAdminRolesChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedAdminRoles([...selectedAdminRoles, value]);
    } else {
      setSelectedAdminRoles(selectedAdminRoles.filter(admin => admin !== value));
    }
  };

  const handleAdminsChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedAdmins([...selectedAdmins, value]);
    } else {
      setSelectedAdmins(selectedAdmins.filter(admin => admin !== value));
    }
  };

  const handleParticipantGroupsChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedParticipantGroups([...selectedParticipantGroups, value]);
    } else {
      setSelectedParticipantGroups(selectedParticipantGroups.filter(participant => participant !== value));
    }
  };


  useEffect(() => {
    if (duplicatePagePop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [duplicatePagePop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: duplicatePagePop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople "
      >
        <Box
          sx={{ display: duplicatePagePop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className="companyHeader">{t("duplicatePagePop.duplicatePagePopHead")} (Outdoor Journey)</Typography>

          <Box className="basicBoxItem">
            <Typography className='psNlabel'>{t("pageSettingPop.pageNameLabel")}</Typography>
            <Box className="pickerBox">
              <input className='eTemInput' value={"Welcome Researchers"} placeholder={t("duplicatePagePop.pageNamePlaceholder")} />
            </Box>
          </Box>
          
          

          <Box className="basicBoxItem">
            <Typography className='psNlabel'>{t("pageSettingPop.coverImageLabel")}</Typography>
            <Box className='mediaInputbox upload-area' onClick={() => {
                document.getElementById("fileInput").click();
            }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}>
                <img src={uploadIcon} />
                <Typography>{t("commonTxt.uploadText")}</Typography>
                <input
                id="fileInput"
                style={{ display: "none" }}
                type="file"
                onChange={handleFileInputChange}
                />
            </Box>
          </Box>

            <Box className="basicBoxItem"> 
                <Typography className="psNlabel">{t("addMessageCardPop.participantAccess")}</Typography>
                <BigDrop setDrop={setDrop} Drop={Drop} setDropVal={setDropVal} DropVal={DropVal} dropItems={ParticipantItem} />

                {DropVal==="Select Participant Groups" &&  <Box className="searchGroupSection">
                  <Box
                    pl={4.5}
                    className="inputAppDrop"
                    onClick={() => setGroupDrop2(!groupDrop2)}
                  >
                    <img className="groupsIcon" src={groupsIcon} />
                    <Typography>{t("pageSettingPop.participantGroupPlaceholder")}</Typography>
                    <img
                      style={{ rotate: groupDrop2 ? "180deg" : "0deg" }}
                      src={dropArrow}
                    />
                  </Box>
                  <Box
                    sx={{ height: groupDrop2 ? "auto" : "0px" }}
                    className="checkMain_box"
                  >
                    <Box className="ckeckBoxRoom">
                      {chackBoxRoom('gp1','Group 1')}
                      {chackBoxRoom('gp2','Group 2')}
                      {chackBoxRoom('gp3','Group 3')}
                      {chackBoxRoom('gp4','Group 4')}
                      {chackBoxRoom('gp5','Group 5')}
                      {chackBoxRoom('gp6','Group 6')}
                      {chackBoxRoom('gp7','Group 7')}
                      {chackBoxRoom('gp8','Group 8')}
                      {chackBoxRoom('gp9','Group 9')}
                    </Box>
                    <Box className="propertyPagination">
                      <Box>
                        <p>1</p>
                      </Box>
                      <Box>
                        <p>2</p>
                      </Box>
                      <Box>
                        <p>3</p>
                      </Box>
                      <Box>
                        <p>4</p>
                      </Box>
                      <Box>
                        <p>...</p>
                      </Box>
                      <Box>
                        <p>8</p>
                      </Box>
                      <Box>
                        <p>9</p>
                      </Box>
                      <Box>
                        <p>10</p>
                      </Box>
                    </Box>
                  </Box>
                </Box>}
            </Box>
            <Box className="basicBoxItem">
                <Typography className="psNlabel adminAccess">{t("addMessageCardPop.administratorAccess")}</Typography>
                <BigDrop setDrop={setDrop2} Drop={Drop2} setDropVal={setDropVal2} DropVal={DropVal2} dropItems={AdministratorItem} />


                {DropVal2==="Select Admin Roles" && 
                <Box className="checkMain_box">
                  <Box className="ckeckBoxRoom">
                      {AdminRolesCheckBox('allresearcher','All Researchers')}
                      {AdminRolesCheckBox('allcollaborators','All Collaborators')}
                      {AdminRolesCheckBox('allobservers','All Observers')}
                  </Box>
                </Box>}

                {DropVal2==="Select Admins" && 
                <>
                  <Box className="searchGroupSection">
                    <Box
                      pl={4.5}
                      className="inputAppDrop"
                      onClick={() => setSearchDrop(!searchDrop)}
                    >
                      <img className="groupsIcon" src={searchIcon} />
                      <Typography>{t("pageSettingPop.adminNamePlaceholder")}</Typography>
                      <img
                        style={{ rotate: searchDrop ? "180deg" : "0deg" }}
                        src={dropArrow}
                      />
                    </Box>
                    <Box
                      sx={{ height: searchDrop ? "auto" : "0px" }}
                      className="checkMain_box"
                    >
                      <Box className="searchDropAdminList admins">
                        
                          <Box className="ckeckBoxRoom">
                            {AdminsCheckBox('Olivia Rhye','Olivia Rhye')}
                            {AdminsCheckBox('Phoenix Baker','Phoenix Baker')}
                            {AdminsCheckBox('Kate Morrison','Kate Morrison')}
                          </Box>
                      

                        {adminsMore && 
                          <Box className="ckeckBoxRoom">
                            {AdminsCheckBox('Olivia Rhye2','Olivia Rhye2')}
                            {AdminsCheckBox('Phoenix Baker2','Phoenix Baker2')}
                            {AdminsCheckBox('Kate Morrison2','Kate Morrison2')}
                            {AdminsCheckBox('Olivia Rhye3','Olivia Rhye3')}
                            {AdminsCheckBox('Phoenix Baker3','Phoenix Baker3')}
                            {AdminsCheckBox('Kate Morrison3','Kate Morrison3')}
                          </Box>
                       }
                        <Box onClick={()=>setAdminsMore(!adminsMore)}>
                          {!adminsMore? (<Box className="viewMoreDrop">
                            <Typography>{t("commonTxt.viewMore")}</Typography>
                            <img src={dropIcon} />
                          </Box>):(<Box className="viewMoreDrop">
                            <Typography>{t("commonTxt.hideBtn")}</Typography>
                            <img src={dropIcon} />
                          </Box>)}
                        </Box>

                      </Box>
                      
                    </Box>
                  </Box>
                </>
                }
            </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => setDuplicatePagePop(false)} />
            <AppBtnOutLine buttonText={t("commonTxt.goBackBtn")} onClick={() => setDuplicatePagePop(false)} />
            <AppBtn buttonText={t("workspaceSplash.addPage")} />
          </Box>

        </Box>
      </Box>
    </>
  );
}
