import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { authReducer } from "./features/authSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { workspaceReducer } from "./features/workspaceSlice";
import { sidebarReducer } from "./features/sidebarSlice";

const rootReducer = combineReducers({
  auth: persistReducer(
    {
      key: "terapage_auth",
      storage: storage,
      whitelist: [
        "authToken",
        "userProfile",
        "is_support",
        "userType",
        "wsUserType",
        "prevUser",
      ],
    },
    authReducer
  ),
  workspace: persistReducer(
    {
      key: "terapage_workspace",
      storage: storage,
      whitelist: ["selectedWorkspace", "selectedProject"],
    },
    workspaceReducer
  ),
  sidebar: persistReducer(
    {
      key: "terapage_sidebar",
      storage: storage,
      whitelist: ["dropdowns"],
    },
    sidebarReducer
  ),
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;
