import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

//images
import groupIcon from "../../../Assets/Images/groupsIcon.png";
import filterIcon from "../../../Assets/Images/filterIcon.png"
import AdminBG from "../../../Assets/Images/Add-files-cuate.png";
import exportIcon from "../../../Assets/Images/exportIcon.png";

//data
import { avtivityImages } from "../../../Assets/Data"

export default function RecapImage() {
  const Navigate = useNavigate();
  const imageData = true;

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  return (
    <>
      {!imageData ?
        <Box className="threadNoDataPage">
          <Box className="adminWorkSBG">
            <img src={AdminBG} />
          </Box>
          <Box className="adminBGTextBox">
            <Typography className="adminSubHeaderText">
              {t("recapExpression.expresssionText")}
            </Typography>
          </Box>
        </Box>
        :
        <Box className="threadContainer workspaceListBox">
          <Box className="threadNavBox">
            <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <Box className="actTNavBtn">
                <img src={groupIcon} />
                <Typography>{t("commonTxt.groupBtn")}</Typography>
              </Box>

              <Box className="filterBtn">
                <img src={filterIcon} />
                <p>{t("commonTxt.filters")}</p>
              </Box>
            </Box>

            <Box className="actTNavBtn">
              <img src={exportIcon} />
              <Typography>{t("commonTxt.exportLink")}</Typography>
            </Box>
          </Box>

          <ImageList variant="masonry" cols={3} gap={8}>
            {avtivityImages.map((item, index) => (
              <ImageListItem key={index}
                onClick={() => Navigate("/observer/recap/view-Image")}>
                <img
                  srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.img}?w=248&fit=crop&auto=format`}
                  alt={item.title}
                  loading="lazy"
                />
                <Box className="grayTextBox">
                  <Typography>{item.title}</Typography>
                  <span>{item.subTitle}</span>
                </Box>
              </ImageListItem>
            ))}
          </ImageList>

        </Box>

      }
    </>
  )
}
