import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from "react-i18next";
import "./style.css";

//Images
import iIcon2 from "../../../Assets/Images/IIcon.png";
import dropArrow from "../../../Assets/Images/chevron-down.png";

//components 
import { AppBtn, AppBtnOutLine } from '../../../Components/AppTools/AppButton';
import { AntSwitch } from '../../AppTools/AppTool';

export default function ProjectProfileField({ projectSettingDropVal }) {

    const [profileFieldDrop, setProfileFieldDrop] = useState(false);
    const [profileFieldDropVal, setProfileFieldDropVal] = useState(false);
    const {
        t,
        i18n: { changeLanguage, language },
      } = useTranslation();

  return (
    <>   
        <Box sx={{ display: projectSettingDropVal === "Profile Fields" ? "block" : "none" }}>
            <Typography className='emailNotificationHeaderText'>{t("projectProfileFields.profileFieldsHead")}</Typography>
            <Box className="iBox projectIBox">
                <img src={iIcon2} />
                <Typography>{t("projectProfileFields.profileFieldsNotify")}</Typography>
            </Box>
            <Box className="projectSwitchBox journalActivityNotificationDayDrop">
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                  defaultChecked
                />
                <Typography>
                {t("projectProfileFields.profileFieldsSwtich")}
                </Typography>
            </Box>
            <Box className="projectPrivacyIdentity">
                <Typography className=''>{t("projectProfileFields.profileFieldsLabel")}</Typography>
                <Typography className='bsNText2'>{t("projectProfileFields.profileFieldsSubText")}</Typography>
                <Box
                className="inputAppDrop participantIdentityDrop"
                onClick={() => setProfileFieldDrop(!profileFieldDrop)}
                >
                <Typography>{profileFieldDropVal || t("projectProfileFields.profileFieldsPlaceholder")}</Typography>
                <img
                style={{ rotate: profileFieldDrop ? "180deg" : "0deg" }}
                src={dropArrow}
                />
                {/* pop */}
                <Box
                sx={{ height: profileFieldDrop ? "auto" : "0px" }}
                className="statusPop"
                >
                <Box
                    onClick={() => setProfileFieldDropVal("Profile Field 1")}
                    className="statusPopItem"
                >
                    <Typography>Profile Field 1</Typography>
                </Box>
                <Box
                    onClick={() => setProfileFieldDropVal("Profile Field 2")}
                    className="statusPopItem"
                >
                    <Typography>Profile Field 2</Typography>
                </Box>
                
                </Box>
                </Box>
            </Box>
            <Box className="checkPermissionBox">
                <Box className="checkPermissionSection">
                    <input className="custom-checkbox" type="checkBox" defaultChecked />
                    <Typography>{t("projectProfileFields.ageCheckbox")}</Typography>
                </Box>
                <Box className="checkPermissionSection">
                    <input className="custom-checkbox" type="checkBox" defaultChecked />
                    <Typography>{t("projectProfileFields.genderCheckbox")}</Typography>
                </Box>
                <Box className="checkPermissionSection">
                    <input className="custom-checkbox" type="checkBox" defaultChecked />
                    <Typography>{t("projectProfileFields.incomeCheckbox")}</Typography>
                </Box>
                <Box className="checkPermissionSection">
                    <input className="custom-checkbox" type="checkBox" defaultChecked />
                    <Typography>{t("projectProfileFields.medicalHistoryCheckbox")}</Typography>
                </Box>
                
            </Box>
            <Box className="workPupBtnBox">
                <AppBtnOutLine buttonText={t("commonTxt.resetBtn")} />
                <AppBtn buttonText={t("commonTxt.saveBtn")} />
            </Box>
        </Box>
    </>
  )
}
