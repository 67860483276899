import React, { useState, useEffect, useRef } from "react";
import "./style.css";

import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";

import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import VideoIcon from "../../../Assets/Images/video.png";
import Richard from "../../../Assets/Images/UserHomeAvatar.png";

import { Workspace } from "../../../Assets/Data";
import axios from "../../../lib/axios";
import { API_BASE_URL } from "../../../env";
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Pagination from "../../AppTools/Pagination";
import { formatDate } from "../../../lib/helpers";
import { useAppSelector } from "../../../lib/store/store";

export default function ActionLog() {

  const [siteLogList, setSiteLogList] = useState([]);
  const [value, setValue] = useState(['', '']);
  const dateRangePickerRef = useRef(null);

  const defaultFilters = {
    'action_type': '',
    'start_date': '',
    'end_date': ''
  }

  const [filterState, setFilterState] = useState({...defaultFilters});

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: 25,
    totalPages: 0,
    totalItems: 0,
  })

  const [companyStatusDrop, setCompanyStatusDrop] = useState(false);
  const [compantStatusDropVal, setCompantStatusDropVal] = useState("");

  const [workspaceStatusdrop, setWorkspaceStatusdrop] = useState(false);
  const [workspaceStatusDropVal, setworkspaceStatusDropVal] = useState("");

  const [optionIndex, setOptionIndex] = useState();

  const [dataRangeDrop, setDateRangeDrop] = useState(false);
  const [dataRangeDropVal, setDateRangeDropVal] = useState("");

  const [projectStatusDrop, setProjectStatusDrop] = useState(false);

  const [projectStatusDropVal, setProjectStatusDropVal] = useState("");

  const [userTypeDrop, setUserTypeDrop] = useState(false);
  const [userTypeDropVal, setUserTypeDropVal] = useState("");

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const handleOptionIndex = (i) => {
    setOptionIndex(i);
  };

  //pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [newArrayData, setNewArrayData] = useState([]);

  // useEffect(() => {
  //   printItemsByState(Workspace, currentPage);
  // }, [currentPage]);

  // function printItemsByState(array, page) {
  //   const startIndex = page * 8;
  //   const endIndex = (page + 1) * 8;
  //   const itemsToPrint = array.slice(startIndex, endIndex);
  //   setNewArrayData(itemsToPrint);
  // }

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };


  const handleFilterChange = (changedFilter) => {
    let newFilters = {
      ...filterState,
      ...changedFilter,
    }
    setFilterState(newFilters)
    fetchSiteLogList(1, newFilters)
  }

  const clearFilters = () => {
    const newFilters = {
      ...defaultFilters,
    };
    console.log("newFilters", newFilters);
    setFilterState(newFilters);
    fetchSiteLogList(1, newFilters);
    setUserTypeDropVal("");
    setValue(['', '']);
    if (dateRangePickerRef.current) {
      dateRangePickerRef.current.clear();
    }
  };

  const fetchSiteLogList = async(curPage, filters = filterState) => {
    console.log("curPage", curPage);
    
    try {
      const res = await axios.get(API_BASE_URL + `/researchers/logs?page=${curPage}&action_type=${filters.action_type}&start_date=${filters.start_date}&end_date=${filters.end_date}&limit=6&log_type=ACTION`);
      console.log("132", res);
      if (res.status) {
        setSiteLogList(res.data.data);
        setPaginationData({
          currentPage: res.data.pagination.currentPage,
          itemsPerPage: res.data.pagination.itemsPerPage,
          totalPages: res.data.pagination.totalPages,
          totalItems: res.data.pagination.totalItems,
        });
      }
    } catch (error) {
      console.error("Error fetching project lists", error);
    }
  }

  const onActionLogChange = (pageNum) => {
    fetchSiteLogList(pageNum);
  };

  useEffect(()=> {
    fetchSiteLogList(1);
  },[])

  return (
    <>
      <Box className="workspaceItemBox CompaniesBox">
        <Box className="AllCSubBox allUFilters">
          <Box className="headerText platformHeader">
            <Typography className="fHeaderText">
              {t("commonTxt.filters")}
            </Typography>
            <Box className="fHeaderTextLink">
              <img src={VideoIcon} />
              <Typography className="fHeaderText fHeaderTextLink">
                {t("actionLogPage.recentUsedMint")}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
            <Box sx={{ width: "100%" }} className="filterBox">
              <Box className="companiesTopBox">
                <Box className="companyInputBoxWlabel">
                  <Typography className="inputLabel">
                    {t("commonTxt.workspaces")}
                  </Typography>

                  <Box
                    className="inputAppDrop"
                    onClick={() => setCompanyStatusDrop(!companyStatusDrop)}
                  >
                    <Typography>
                      {compantStatusDropVal || t("actionLogPage.filterByWS")}
                    </Typography>
                    <img
                      style={{ rotate: companyStatusDrop ? "180deg" : "0deg" }}
                      src={DownArrowIcon}
                    />
                    {/* pop */}
                    <Box
                      sx={{ height: companyStatusDrop ? "auto" : "0px" }}
                      className="statusPop"
                    >
                      <Box
                        onClick={() => setCompantStatusDropVal("Item-A")}
                        className="statusPopItem"
                      >
                        <Typography>
                          {t("actionLogPage.filterByWSOption1")}
                        </Typography>
                      </Box>
                      <Box
                        onClick={() => setCompantStatusDropVal("Item-B")}
                        className="statusPopItem"
                      >
                        <Typography>Item-B</Typography>
                      </Box>
                      <Box
                        onClick={() => setCompantStatusDropVal("Item-C")}
                        className="statusPopItem"
                      >
                        <Typography>Item-C</Typography>
                      </Box>
                      <Box
                        onClick={() => setCompantStatusDropVal("Item-D")}
                        className="statusPopItem"
                      >
                        <Typography>Item-D</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {/* workspace status */}
                <Box className="companyInputBoxWlabel">
                  <Typography className="inputLabel">
                    {t("actionLogPage.project")}
                  </Typography>

                  <Box
                    className="inputAppDrop"
                    onClick={() => setWorkspaceStatusdrop(!workspaceStatusdrop)}
                  >
                    <Typography>
                      {workspaceStatusDropVal || t("actionLogPage.filterByPro")}
                    </Typography>
                    <img
                      style={{
                        rotate: workspaceStatusdrop ? "180deg" : "0deg",
                      }}
                      src={DownArrowIcon}
                    />
                    {/* pop */}
                    <Box
                      sx={{ height: workspaceStatusdrop ? "auto" : "0px" }}
                      className="statusPop"
                    >
                      <Box
                        onClick={() => setworkspaceStatusDropVal("Item-A")}
                        className="statusPopItem"
                      >
                        <Typography>Item-A</Typography>
                      </Box>
                      <Box
                        onClick={() => setworkspaceStatusDropVal("Item-B")}
                        className="statusPopItem"
                      >
                        <Typography>Item-B</Typography>
                      </Box>
                      <Box
                        onClick={() => setworkspaceStatusDropVal("Item-C")}
                        className="statusPopItem"
                      >
                        <Typography>Item-C</Typography>
                      </Box>
                      <Box
                        onClick={() => setworkspaceStatusDropVal("Item-D")}
                        className="statusPopItem"
                      >
                        <Typography>Item-D</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="companiesTopBox platformTopBox">
              <Box className="companyInputBoxWlabel">
                  <Typography className="inputLabel">Date Range </Typography>
                  <DateRangePicker 
                    ref={dateRangePickerRef}
                    onChange={(range)=> {
                    setValue(range);
                    const adjustForTimezone = (date) => {
                      const offset = date.getTimezoneOffset();
                      const adjustedDate = new Date(date.getTime() - (offset * 60 * 1000));
                      return adjustedDate;
                    };
                    if (range && range.length === 2) {
                      const startDate = adjustForTimezone(range[0]).toISOString().split('T')[0];
                      const endDate = adjustForTimezone(range[1]).toISOString().split('T')[0];
                      
                      console.log("Adjusted Start Date", startDate);
                      console.log("Adjusted End Date", endDate);
              
                      handleFilterChange({
                        start_date: startDate,
                        end_date: endDate
                      });
                    } else {
                      handleFilterChange({
                        start_date: '',
                        end_date: ''
                      });
                    }
                  }} value={value} />
                </Box>
                {/* workspace status */}
                <Box className="companyInputBoxWlabel">
                  <Typography className="inputLabel">
                    {t("commonTxt.status")}
                  </Typography>

                  <Box
                    className="inputAppDrop"
                    onClick={() => setProjectStatusDrop(!projectStatusDrop)}
                  >
                    <Typography>
                      {projectStatusDropVal || t("actionLogPage.filterByPro")}
                    </Typography>
                    <img
                      style={{
                        rotate: projectStatusDrop ? "180deg" : "0deg",
                      }}
                      src={DownArrowIcon}
                    />
                    {/* pop */}
                    <Box
                      sx={{ height: projectStatusDrop ? "auto" : "0px" }}
                      className="statusPop"
                    >
                      <Box
                        onClick={() => setProjectStatusDropVal("Item-A")}
                        className="statusPopItem"
                      >
                        <Typography>Item-A</Typography>
                      </Box>
                      <Box
                        onClick={() => setProjectStatusDropVal("Item-B")}
                        className="statusPopItem"
                      >
                        <Typography>Item-B</Typography>
                      </Box>
                      <Box
                        onClick={() => setProjectStatusDropVal("Item-C")}
                        className="statusPopItem"
                      >
                        <Typography>Item-C</Typography>
                      </Box>
                      <Box
                        onClick={() => setProjectStatusDropVal("Item-D")}
                        className="statusPopItem"
                      >
                        <Typography>Item-D</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="companyInputBoxWlabel">
                  <Typography className="inputLabel">
                    {t("actionLogPage.userType")}
                  </Typography>

                  <Box
                    className="inputAppDrop"
                    onClick={() => setUserTypeDrop(!userTypeDrop)}
                  >
                    <Typography>
                      {userTypeDropVal || t("actionLogPage.filterByPro")}
                    </Typography>
                    <img
                      style={{
                        rotate: userTypeDrop ? "180deg" : "0deg",
                      }}
                      src={DownArrowIcon}
                    />
                    {/* pop */}
                    <Box
                      sx={{ height: userTypeDrop ? "auto" : "0px" }}
                      className="statusPop"
                    >
                      <Box
                        onClick={() => setUserTypeDropVal("Item-A")}
                        className="statusPopItem"
                      >
                        <Typography>Item-A</Typography>
                      </Box>
                      <Box
                        onClick={() => setUserTypeDropVal("Item-B")}
                        className="statusPopItem"
                      >
                        <Typography>Item-B</Typography>
                      </Box>
                      <Box
                        onClick={() => setUserTypeDropVal("Item-C")}
                        className="statusPopItem"
                      >
                        <Typography>Item-C</Typography>
                      </Box>
                      <Box
                        onClick={() => setUserTypeDropVal("Item-D")}
                        className="statusPopItem"
                      >
                        <Typography>Item-D</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* Bottom Filter */}
              <Box
                sx={{ display: "flex", justifyContent: "flex-end" }}
                className="filterBottomBox"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography mx={3} className="LinkText" onClick={clearFilters}>
                    {t("commonTxt.clear")}
                  </Typography>
                  {/* <Typography mx={3} className="LinkText">
                    {t("commonTxt.apply")}
                  </Typography> */}
                  <Typography>{paginationData?.totalItems} matches</Typography>
                </Box>
              </Box>
              {/* Finishes here */}
            </Box>
          </Box>
        </Box>

        <Box className="AllProjectBox">
          <Box className="workListBox">
          {siteLogList &&
              siteLogList?.map((el, index) => (
              <Box key={index} className="TabelRow  " minWidth={"1111px"}>
                <Box minWidth={"16%"} className="Tabelsel firstSel">
                  <Box className="tabelAvatar">
                    <img src={Richard} />
                  </Box>
                  <Typography>{el?.user_details[0]?.name}</Typography>
                </Box>
                {/* <Box minWidth={"18%"} className="Tabelsel">
                  <Typography>Richard's Workspace</Typography>
                </Box>

                <Box minWidth={"14%"} className="Tabelsel">
                  <Typography>Outdoor Journey</Typography>
                </Box>
                <Box minWidth={"22%"} className="Tabelsel">
                  <Typography>Video and Product Reviews</Typography>
                </Box>
                <Box minWidth={"17%"} className="Tabelsel">
                  <Typography>Like Rating Received</Typography>
                </Box>
                <Box minWidth={"6%"} className="Tabelsel">
                  <Typography>5 pts</Typography>
                </Box> */}
                <Box minWidth={"12%"} className="Tabelsel">
                  <Typography>{formatDate(el?.created_at)}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Box className="pagination">
          <Pagination
            paginationData={paginationData}
            onPageChange={onActionLogChange}
          />
        </Box>
      </Box>
    </>
  );
}
