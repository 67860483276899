import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import searchIcon from "../../../Assets/Images/search.png";
import chackBox from "../../../Assets/Images/Checkbox.png";
import check from "../../../Assets/Images/check.png";
import axios from "../../../lib/axios";
import { getCities, getCountries, getIndustries, getStates, getWorkspaceUsers } from "../../../Services/master";
import { TOAST_CONFIG } from "../../../lib/constants";
import { toast } from "react-toastify";
import { updateCompanyApi } from "../../../Services/adminActions";
import { useAppSelector } from "../../../lib/store/store";
import { getUserRoleType } from "../../../lib/helpers";

export default function EditCompanyPop({
  setEditCompanyPop,
  editCompanyPop,
  allCompaniesData,
  setReloadList,
  workspacePopType,
  workSpacePop,
  setWorkSpacePop,
  setEditWorkspace,
  setAllCompaniesData
}) {

  workspacePopType = workspacePopType || null;

  let authState = useAppSelector(state => state.auth);
  const [settingTab, setSettingTab] = useState(0);
  const [researcherDrop, setresearcherDrop] = useState(false);

  const [chackVal, setChackVal] = useState([]);

  const [cityList, setCityList] = useState(false);
  const [cityListVal, setCityListVal] = useState("");

  const [stateList, setStateList] = useState(false);
  const [stateListVal, setStateListVal] = useState("");

  const [countryList, setCountryList] = useState(false);
  const [countryListVal, setCountryListVal] = useState("");

  const [zipCode, setZipCode] = useState(false);
  const [zipCodeVal, setZipCodeVal] = useState();

  const [industryList, setIndustryList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [industryListVal, setIndustryListVal] = useState("");

  const [industryId, setIndustryId] = useState("");
  const [countryId, setCountryId] = useState("");
  const [stateId, setStateId] = useState("");
  const [cityId, setCityId] = useState("");
  const [industriesListsData, setIndustriesListsData] = useState([]);
  const [allResearchersList, setAllResearchersList] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);

  const [numAdditionalContacts, setNumAdditionalContacts] = useState(0);

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setEditCompanyPop(false);
    }
  };

  // const selectAllCheckData = () => {
  //   setChackVal(["Richard Han", "Lucy Hutten", "Lorem Ipsum", "Dolor Amet"]);
  // };
  const selectAllCheckData = () => {
    let selectedItems = [];
    allResearchersList.map(item => {
      selectedItems.push({ _id: item._id, name: item.name, role: item.role });
    })
    setEditCompanyApi({ ...editCompanyApi, contact_researchers: selectedItems });
  };

  // const deleteAllCheckData = () => setChackVal([]);
  const deleteAllCheckData = () => setEditCompanyApi({ ...editCompanyApi, contact_researchers: [] })

  const getResearcherDropVal = () => {
    let dropdownLabel = '';
    if (editCompanyApi?.contact_researchers?.length <= 2) {
      editCompanyApi?.contact_researchers?.map((val, index) => {
        if (index > 0) {
          dropdownLabel += ', ';
        }
        dropdownLabel += val.name;
      });
    } else {
      dropdownLabel = editCompanyApi?.contact_researchers?.length + ' Selected';
    }
    return dropdownLabel;
  }

  // const handleAddPerson = () => {
  //   setNumAdditionalContacts(numAdditionalContacts + 1);
  // };

  // const handleRemovePerson = () => {
  //   setNumAdditionalContacts(numAdditionalContacts - 1);
  // };

  const updateCompany = {
    name: "",
    email: "",
    phone: "",
    industry: "",
    address_line1: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    contact_researchers: [],
    status: "active",
    contact_persons: [
      {
        name: "",
        email: "",
        phone: "",
        address: "",
      },
    ],
  }
  const [editCompanyApi, setEditCompanyApi] = useState({ ...updateCompany });

  useEffect(() => {
    getAllCountryList();
    getAllCompanies();
    getAllIndustryListData();
  }, [allCompaniesData])

  const getAllCompanies = async () => {
    if (allCompaniesData == '' || allCompaniesData == null) {
      return false;
    }
    getResearcherList();
    getObserversList();
    getCollaboratorsList();
    return await axios.get(`/super-admin/admin/companies?_id=${allCompaniesData}`).then((res) => {
      if (res.status) {
        let companyData = { ...updateCompany }
        let key;
        let allCompaniesData = res.data.data[0];

        for (key in companyData) {
          companyData[key] = allCompaniesData[key];
        }

        if (allCompaniesData?.industry.length > 0) {
          const assignedIndustry = allCompaniesData.industry
            .map(item => ({
              name: item.name,
              _id: item._id
            }));
          if (assignedIndustry?.length > 0) {
            setIndustryListVal(assignedIndustry[0]?.name)
          }
        } else {
          setIndustryListVal("")
        }
        if (allCompaniesData?.country?.length > 0) {
          const assignedCountry = allCompaniesData?.country
            .map(item => ({
              name: item.name,
              _id: item._id
            }));
          if (assignedCountry?.length > 0) {
            setCountryListVal(assignedCountry[0]?.name && assignedCountry[0]?.name || '');
          }
          getAllStatesList(allCompaniesData?.country[0]?.id);
        } else {
          setCountryListVal("");
        }
        if (allCompaniesData?.state?.length > 0) {
          const assignedState = allCompaniesData?.state
            .map(item => ({
              name: item.name,
              _id: item._id
            }));
          if (assignedState?.length > 0) {
            setStateListVal(assignedState[0]?.name && assignedState[0]?.name || '');
          }
          getAllCitiesList(allCompaniesData?.state[0]?.id, allCompaniesData?.country[0]?.id);
        } else {
          setStateListVal("");
        }
        if (allCompaniesData?.city.length > 0) {
          const assignedCity = allCompaniesData?.city
            .map(item => ({
              name: item.name,
              _id: item._id
            }));
          if (assignedCity?.length > 0) {
            setCityListVal(assignedCity[0]?.name && assignedCity[0]?.name || '');
          }
        } else {
          setCityListVal("");
        }

        const observers = allCompaniesData?.observers?.map(item => ({ _id: item._id, name: item.name, role: "Observer" })) || [];
        const collaborators = allCompaniesData?.collaborators?.map(item => ({ _id: item._id, name: item.name, role: "Collaborator" })) || [];
        const researchers = allCompaniesData?.researchers?.map(item => ({ _id: item._id, name: item.name, role: "Researcher" })) || [];

        const companyUsers = [].concat(observers, collaborators, researchers);

        setEditCompanyApi({
          ...allCompaniesData, contact_researchers: companyUsers, contact_persons: [{
            name: "",
            email: "",
            phone: "",
            address: "",
          }]
        });
      }
    })
  }

  const setCompanyFormValue = (e) => {
    let newState = {
      ...editCompanyApi,
      [e.target.name]: e.target.value,
    };

    setEditCompanyApi(newState);

    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
  };

  const getResearcherList = () => {
    getWorkspaceUsers({ "types[]": "researcher" }).then((res) => {
      if (res.success && res.data.data.length > 0) {
        const existingIdsAndRoles = new Set(allResearchersList.map(researcher => JSON.stringify({ _id: researcher._id, role: 'Researcher' })));
        const researchersToAdd = res.data.data.filter(el => {
          const idAndRole = JSON.stringify({ _id: el._id, role: 'Researcher' });
          return !existingIdsAndRoles.has(idAndRole);
        });
        setAllResearchersList(prevList => [...prevList, ...researchersToAdd.map(({ _id, name }) => ({ _id, name, role: 'Researcher' }))]);
      }
    });
  };

  const getObserversList = () => {
    getWorkspaceUsers({ "types[]": "observer" }).then((res) => {
      if (res.success && res.data.data.length > 0) {
        const existingIdsAndRoles = new Set(allResearchersList.map(observer => JSON.stringify({ _id: observer._id, role: 'Observer' })));
        const observersToAdd = res.data.data.filter(observer => {
          const idAndRole = JSON.stringify({ _id: observer._id, role: 'Observer' });
          return !existingIdsAndRoles.has(idAndRole);
        });
        setAllResearchersList(prevList => [...prevList, ...observersToAdd.map(({ _id, name }) => ({ _id, name, role: 'Observer' }))]);
      }
    });
  };

  const getCollaboratorsList = () => {
    getWorkspaceUsers({ "types[]": "collaborator" }).then((res) => {
      if (res.success && res.data.data.length > 0) {
        const existingIdsAndRoles = new Set(allResearchersList.map(researcher => JSON.stringify({ _id: researcher._id, role: researcher.role })));
        const collaboratorsToAdd = res.data.data.filter(el => {
          const idAndRole = JSON.stringify({ _id: el._id, role: 'Collaborator' });
          return !existingIdsAndRoles.has(idAndRole);
        });
        setAllResearchersList(prevList => [...prevList, ...collaboratorsToAdd.map(({ _id, name }) => ({ _id, name, role: 'Collaborator' }))]);
      }
    });
  };


  const getAllIndustryListData = () => {
    getIndustries().then((res) => {
      if (res.success && res.data.data.length > 0) {
        setIndustriesListsData(res.data.data);
      }
    });
  }

  const getAllCountryList = () => {
    getCountries().then((res) => {
      if (res.success && res.data.data.length > 0) {
        setCountryData(res.data.data);
      }
    });
  };

  const getAllStatesList = (country_id) => {
    getStates(country_id).then((res) => {
      if (res.success && res.data.data.length > 0) {
        setStatesData(res.data.data);
      }
    });
  };

  const getAllCitiesList = (state_id, country_id) => {
    getCities(country_id, state_id).then((res) => {
      if (res.success && res.data.data.length > 0) {
        setCitiesData(res.data.data);
      }
    });
  };

  const handleIndustryChange = (e) => {
    let newState = { ...editCompanyApi, industry: e._id };
    setIndustryId(e._id);
    setEditCompanyApi(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
  };

  const handleCountryChange = (e) => {
    setStateListVal("");
    setCityListVal("");
    let newState = { ...editCompanyApi, country: e._id };
    setCountryId(e._id);
    setEditCompanyApi(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
    getAllStatesList(e.id);
  };

  const handleStateChange = (e) => {
    let newState = { ...editCompanyApi, state: e._id };
    setCityListVal("");
    setStateId(e._id)
    setEditCompanyApi(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
    getAllCitiesList(e.id, e.country_id);
  };

  const handleCityChange = (e) => {
    let newState = { ...editCompanyApi, city: e._id };
    setCityId(e._id);
    setEditCompanyApi(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
  };

  const validationObj = {
    hasErr: false,
    'name': {
      error: false,
      msg: ''
    },
    'email': {
      error: false,
      msg: ''
    },
    'phone': {
      error: false,
      msg: ''
    },
    'industry': {
      error: false,
      msg: ''
    },
    'address_line1': {
      error: false,
      msg: ''
    },
    'country': {
      error: false,
      msg: ''
    },
    'state': {
      error: false,
      msg: ''
    },
    'city': {
      error: false,
      msg: ''
    },
    'zipcode': {
      error: false,
      msg: ''
    }
  }

  const [validationErrors, setValidationErrors] = useState({ ...validationObj });

  const handleValidation = (formInput) => {
    formInput = formInput || editCompanyApi;
    let validationerr = { ...validationObj };

    if (formInput.name == "") {
      validationerr.hasErr = true;
      validationerr.name = {
        error: true,
        msg: 'Name is required'
      }
    }

    if (formInput.email == "") {
      validationerr.hasErr = true;
      validationerr.email = {
        error: true,
        msg: 'Email is required'
      }
    }

    if (formInput.phone == '') {
      validationerr.hasErr = true;
      validationerr.phone = {
        error: true,
        msg: 'Phone is required'
      }
    }

    if (formInput.industry == '') {
      validationerr.hasErr = true;
      validationerr.industry = {
        error: true,
        msg: 'Industry is required'
      }
    }

    if (formInput.address_line1 == '') {
      validationerr.hasErr = true;
      validationerr.address_line1 = {
        error: true,
        msg: 'Address is required'
      }
    }

    if (formInput.country == '') {
      validationerr.hasErr = true;
      validationerr.country = {
        error: true,
        msg: 'Country is required'
      }
    }

    if (formInput.state == '') {
      validationerr.hasErr = true;
      validationerr.state = {
        error: true,
        msg: 'State is required'
      }
    }

    if (formInput.city == '') {
      validationerr.hasErr = true;
      validationerr.city = {
        error: true,
        msg: 'City is required'
      }
    }

    if (formInput.zipcode == '') {
      validationerr.hasErr = true;
      validationerr.zipcode = {
        error: true,
        msg: 'Zipcode is required'
      }
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (editCompanyPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [editCompanyPop]);

  const pushData = (obj) => {
    let selectedResearchers = [...editCompanyApi.contact_researchers];
    selectedResearchers = selectedResearchers?.filter((item) => (item._id !== obj._id && item.role !== obj.role))
    selectedResearchers.push({ _id: obj._id, name: obj.name, role: obj.role })
    setEditCompanyApi({ ...editCompanyApi, contact_researchers: selectedResearchers });
  };

  const handleAddPerson = () => {
    let prevContactPersons = [...editCompanyApi.contact_persons];
    setEditCompanyApi({
      ...editCompanyApi,
      contact_persons: [
        ...prevContactPersons,
        {
          name: "",
          email: "",
          phone: "",
          address: "",
        },
      ],
    });
  };

  const handleRemovePerson = (index) => {
    setEditCompanyApi((prevCompany) => ({
      ...prevCompany,
      contact_persons: prevCompany.contact_persons.filter(
        (_, i) => i !== index
      ),
    }));
  };

  const handlePersonInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedPersons = [...editCompanyApi.contact_persons];
    updatedPersons[index] = {
      ...updatedPersons[index],
      [name]: value,
    };
    setEditCompanyApi((prevCompany) => ({
      ...prevCompany,
      contact_persons: updatedPersons,
    }));
  };

  const handlePrevPopup = () => {
    if (workspacePopType == 'add' && typeof (setWorkSpacePop) != 'undefined') {
      setWorkSpacePop(true);
    }
  }
  const handleCompanyCancel = () => {
    if (workspacePopType == 'edit' && typeof (setWorkSpacePop) != 'undefined') {
      setEditWorkspace(true);
    }
    setEditCompanyApi({ ...updateCompany })
    setEditCompanyPop(false);
    setAllCompaniesData(null);
  }

  let updateCompanyData = async () => {
    if (!handleValidation()) {
      toast.error('Please correct form fields', TOAST_CONFIG);
      return false;
    }

    setLoading(true);
    try {
      let researchers = [];
      const contact_researchers = [];
      const contact_collaborators = [];
      const contact_observers = []
      const contact_participants = [];
      editCompanyApi.contact_researchers.map(researcher => {
        researchers.push(researcher._id);
        if (researcher.role == "Researcher")
          return contact_researchers.push(researcher._id);
        else if (researcher.role == "Collaborator")
          return contact_collaborators.push(researcher._id);
        else if (researcher.role == "Observer")
          return contact_observers.push(researcher._id);
        else if (researcher.role == "Participant")
          return contact_participants.push(researcher._id);
      });
      let c_id = countryId;
      let st_id = stateId;
      let cty_id = cityId;
      let industry_id = industryId;
      if (c_id == '') {
        c_id = editCompanyApi.country[0]._id
      }
      if (st_id == "") {
        st_id = editCompanyApi.state[0]._id
      }
      if (cty_id == "") {
        cty_id = editCompanyApi.city[0]._id
      }
      if (industry_id == "") {
        industry_id = editCompanyApi.industry[0]._id
      }
      let requestPayload = {
        "name": editCompanyApi.name,
        "email": editCompanyApi.email,
        "phone": editCompanyApi.phone,
        "address_line1": editCompanyApi.address_line1,
        "country": c_id,
        "state": st_id,
        "city": cty_id,
        "industry": industry_id,
        "zipcode": editCompanyApi.zipcode,
        "status": "active",
        "contact_persons": (editCompanyApi.contact_persons.length > 0 && editCompanyApi.contact_persons[0].name != '') ? editCompanyApi.contact_persons : [],
        "contact_researchers": contact_researchers,
        contact_collaborators, contact_observers, contact_participants
      }
      let companyRes = await updateCompanyApi(allCompaniesData, requestPayload, authState.authToken);
      setLoading(false);
      if (companyRes.success) {
        if (workspacePopType == 'add' && typeof (setWorkSpacePop) != 'undefined') {
          setWorkSpacePop(true);
        } else if ((workspacePopType == 'edit' && typeof (setWorkSpacePop) != 'undefined')) {
          setEditWorkspace(true);
        }
        toast.success(companyRes.message, TOAST_CONFIG);
        setEditCompanyApi({ ...updateCompany })
        setEditCompanyPop(false);
        allDropdownClose()
        handlePrevPopup();
        if (typeof (setReloadList) != 'undefined') {
          setReloadList(true);
        }
      } else {
        toast.error(companyRes.message, TOAST_CONFIG);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  }

  const allDropdownClose = () => {
    setresearcherDrop(false);
    setIndustryList(false)
    setCountryList(false)
    setStateList(false)
    setCityList(false)
  }

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: editCompanyPop ? "flex" : "none" }}
        className="companyPop"
      >
        <Box
          sx={{ display: editCompanyPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className="companyHeader">Edit Company</Typography>
          <Typography mb={1} className="companyLabel">
            Company Name
          </Typography>
          <input
            name="name"
            value={editCompanyApi.name}
            onChange={setCompanyFormValue}
            className="companyInput companyInputName"
            placeholder="Enter the full registered name of the company"
            defaultValue={"ASDF Software"}
          />
          <span className="validationerr">{validationErrors.name.msg}</span>
          {/* Nav menu starts */}

          <Box className="companySettingNav">
            <Box
              onClick={() => setSettingTab(0)}
              className={
                settingTab === 0
                  ? "compSetNavTab compSetNavTabActive"
                  : "compSetNavTab"
              }
            >
              <Typography>Company Details</Typography>
            </Box>
            <Box
              onClick={() => setSettingTab(1)}
              className={
                settingTab === 1
                  ? "compSetNavTab compSetNavTabActive"
                  : "compSetNavTab"
              }
            >
              <Typography>Contact Person's Details</Typography>
            </Box>
          </Box>
          {/* Nav menu ends */}

          {/* Adding Companies */}
          <Box
            sx={{ display: settingTab === 0 ? "flex" : "none" }}
            className="settingBox"
          >
            <Box className="fistBox">
              <Box className="emailBox">
                <Typography className="companyInputLabel">
                  Email Address
                </Typography>
                <input
                  name="email"
                  value={editCompanyApi.email}
                  onChange={setCompanyFormValue}
                  className="emailInput"
                  placeholder="Enter company's valid Email Address"
                  defaultValue={"asdf@gmail.com"}
                />
                <span className="validationerr inputerr">{validationErrors.email.msg}</span>
              </Box>
              <Box className="industryBox">
                <Typography className="companyInputLabel">Industry</Typography>
                <input
                  value={industryListVal}
                  onClick={() => setIndustryList(!industryList)}
                  className="workspaceInputTag categoryInputTag"
                  placeholder="Company"
                />
                <img
                  style={{ rotate: industryList ? "180deg" : "0deg" }}
                  onClick={() => setIndustryList(!industryList)}
                  className="downArrowPop"
                  src={DownArrowIcon}
                />
                <Box
                  sx={{
                    height: industryList ? "280px" : "0px",
                    overflowY: industryList ? "scroll" : "hidden"
                  }}
                  className="categoryDropDown"
                >
                  {industriesListsData.map((data) => (
                    <Box
                      key={data.id}
                      value={data.id}
                      className="categoryDropDownItem"
                      onClick={() => {
                        handleIndustryChange(data);
                        setIndustryList(false);
                        setIndustryListVal(data.name);
                      }}
                    >
                      <Typography>{data.name}</Typography>
                    </Box>
                  ))}
                </Box>
                <span className="validationerr">{validationErrors.industry.msg}</span>
              </Box>
            </Box>
            <Box className="addressBox">
              <Typography className="companyInputLabel">Address</Typography>
              <input
                name="address_line1"
                value={editCompanyApi.address_line1}
                onChange={setCompanyFormValue}
                type="text"
                className="addInput"
                placeholder="Enter company's registered office address"
                defaultValue={"Company Address"}
              />
              <span className="validationerr">{validationErrors.address_line1.msg}</span>
            </Box>
            <Box className="csPBox">
              <Typography className="companyInputLabel">
                Phone Number
              </Typography>
              <input
                name="phone"
                value={editCompanyApi.phone}
                onChange={setCompanyFormValue}
                className="phoneInput"
                placeholder="Enter Phone Number"
              />
              <span className="validationerr inputerr">{validationErrors.phone.msg}</span>
            </Box>

            {/* Dropdown */}
            <Box className="dropDownOne lastBasicBox">
              <Box className="inputBoxOne">
                <Typography className="companyInputLabel">Country</Typography>
                <input
                  onClick={() => setCountryList(!countryList)}
                  value={countryListVal}
                  className="workspaceInputTag categoryInputTag"
                  placeholder="Country 1"
                />
                <img
                  style={{ rotate: countryList ? "180deg" : "0deg" }}
                  onClick={() => setCountryList(!countryList)}
                  className="downArrowPop"
                  src={DownArrowIcon}
                />

                <Box
                  sx={{
                    height: countryList ? "280px" : "0px",
                    overflowY: countryList ? "scroll" : "hidden"
                  }}
                  className="categoryDropDown"
                >
                  {countryData.map((data) => (
                    <Box
                      key={data.id}
                      value={data.id}
                      className="categoryDropDownItem"
                      onClick={() => {
                        handleCountryChange(data);
                        setCountryList(false);
                        setCountryListVal(data.name);
                      }}
                    >
                      <Typography>{data.name}</Typography>
                    </Box>
                  ))}
                </Box>
                <span className="validationerr">{validationErrors.country.msg}</span>
              </Box>

              <Box className="inputBoxTwo">
                <Typography className="companyInputLabel">State</Typography>
                <input
                  onClick={() => setStateList(!stateList)}
                  value={stateListVal}
                  className="workspaceInputTag categoryInputTag"
                  placeholder="State 1"
                />
                <img
                  style={{ rotate: stateList ? "180deg" : "0deg" }}
                  onClick={() => setStateList(!stateList)}
                  className="downArrowPop"
                  src={DownArrowIcon}
                />

                <Box
                  sx={{
                    height: stateList ? "280px" : "0px",
                    overflowY: stateList ? "scroll" : "hidden"
                  }}
                  className="categoryDropDown"
                >
                  {statesData.map((data) => (
                    <Box
                      key={data.id}
                      value={data.id}
                      className="categoryDropDownItem"
                      onClick={() => {
                        handleStateChange(data, data.country_id);
                        setStateList(false);
                        setStateListVal(data.name);
                      }}
                    >
                      <Typography>{data.name}</Typography>
                    </Box>
                  ))}
                </Box>
                <span className="validationerr inputerr">{validationErrors.state.msg}</span>
              </Box>
            </Box>
            {/* dropdown */}

            <Box className="basicBox lastBasicBox counBox">
              <Box className="inputBoxOne">
                <Typography className="companyInputLabel">City</Typography>
                <input
                  onClick={() => setCityList(!cityList)}
                  value={cityListVal}
                  className="workspaceInputTag categoryInputTag"
                  placeholder="City 1"
                />
                <img
                  style={{ rotate: cityList ? "180deg" : "0deg" }}
                  onClick={() => setCityList(!cityList)}
                  className="downArrowPop"
                  src={DownArrowIcon}
                />

                <Box
                  sx={{
                    height: cityList ? "auto" : "0px",
                    overflowY: cityList ? "scroll" : "hidden"
                  }}
                  className="categoryDropDown"
                >
                  {citiesData.map((data) => (
                    <Box
                      key={data.id}
                      value={data.id}
                      className="categoryDropDownItem"
                      onClick={() => {
                        handleCityChange(data);
                        setCityList(false);
                        setCityListVal(data.name);
                      }}
                    >
                      <Typography>{data.name}</Typography>
                    </Box>
                  ))}
                </Box>
                <span className="validationerr">{validationErrors.city.msg}</span>
              </Box>

              <Box className="inputBoxFour">
                <Typography className="companyInputLabel">Zip Code</Typography>
                <input
                  name="zipcode"
                  value={editCompanyApi?.zipcode}
                  onChange={setCompanyFormValue}
                  className="emailInput"
                  placeholder="Enter zip code here"
                />
                <span className="validationerr">{validationErrors.zipcode.msg}</span>
              </Box>
            </Box>
            {/* Dropdown2 */}
          </Box>

          {/* Contact Person's details */}
          <Box
            sx={{ display: settingTab === 1 ? "flex" : "none" }}
            className="settingBox contactBox"
          >
            {/* Input Drop Down */}
            <Box className="inputBox">
              <input
                className="workspaceInput"
                placeholder="Choose a Researcher"
                onClick={() => setresearcherDrop(!researcherDrop)}
                value={getResearcherDropVal()}
              />
              <img className="rightIcon" src={searchIcon} />
              <img
                style={{ rotate: researcherDrop ? "180deg" : "0deg" }}
                className="downArrowPop dropDownArrow"
                src={DownArrowIcon}
                onClick={() => setresearcherDrop(!researcherDrop)}
              />

              <Box
                sx={{ height: researcherDrop ? "282px" : "0px" }}
                className="categoryDrop researcherDrop"
              >
                <Box className="researchDropTopLabel">
                  <Typography onClick={selectAllCheckData}>
                    Select All
                  </Typography>
                  <Typography onClick={deleteAllCheckData}>
                    Deselect All
                  </Typography>
                </Box>

                {allResearchersList.map((researcher) => {
                  return <Box
                    onClick={() => {
                      if (editCompanyApi?.contact_researchers?.some(el => (el?._id === researcher._id && el.role == researcher.role))) {
                        setEditCompanyApi({
                          ...editCompanyApi,
                          contact_researchers: editCompanyApi?.contact_researchers?.filter((item) => (item._id !== researcher._id && item.role !== researcher.role))
                        });
                      } else {
                        pushData(researcher);
                      }
                    }}
                    className="categoryDropItem"
                  >
                    <Box className="chackBox">
                      <img className="chackBoxImg" src={chackBox} />
                      <img
                        style={{
                          display: editCompanyApi?.contact_researchers?.some(el => (el?._id == researcher._id && el.role == researcher.role))
                            ? "block"
                            : "none",
                        }}
                        className="chack"
                        src={check}
                      />
                    </Box>
                    <Typography>{researcher.name} ({researcher.role})</Typography>
                  </Box>
                })}
              </Box>
            </Box>

            {/* Input Drop Down Ends here */}

            {editCompanyApi?.contact_persons?.map((contact_person, index) => (
              <Box key={index} className="contactPersonForm">
                <Box className="pName">
                  <Typography className="companyInputLabel">
                    First Contact Person’s Name
                  </Typography>
                  <input
                    name="name"
                    value={contact_person?.name}
                    onChange={(e) => handlePersonInputChange(e, index)}
                    className="nameInput"
                    placeholder="Enter Contact Person's Name"
                  />
                </Box>
                <Box className="secondBox">
                  <Box className="csPBox">
                    <Typography className="companyInputLabel">
                      Phone Number
                    </Typography>
                    <input
                      name="phone"
                      className="phoneInput"
                      value={contact_person?.phone}
                      onChange={(e) => handlePersonInputChange(e, index)}
                      placeholder="Enter Contact Person's Phone Number"
                    />
                  </Box>
                  <Box className="emailAddressBox">
                    <Typography className="companyInputLabel">
                      Email Address
                    </Typography>
                    <input
                      name="email"
                      value={contact_person?.email}
                      onChange={(e) => handlePersonInputChange(e, index)}
                      className="emailInput"
                      placeholder="Enter Contact Person's Email Address"
                    />
                  </Box>
                </Box>
                <Box className="contPName">
                  <Typography className="companyInputLabel">Address</Typography>
                  <input
                    name="address"
                    value={contact_person?.address}
                    onChange={(e) => handlePersonInputChange(e, index)}
                    className="addressInput"
                    placeholder="Enter Contact Person's Address"
                    defaultValue={"24/6 London"}
                  />
                </Box>
                <Typography
                  className="personLink"
                  onClick={() => handleRemovePerson(index)}
                  style={{ color: "red" }}
                >
                  Remove Contact Person
                </Typography>
              </Box>
            ))}

            <Typography
              className="personLink"
              onClick={handleAddPerson}
              style={{ color: "red" }}
            >
              Add New Contact Person
            </Typography>
          </Box>

          <Box className="companyPopBtns">
            <Box className="workPupBtnBox">
              <AppBtnOutLine
                buttonText="Cancel"
                onClick={() => { handleCompanyCancel(); handlePrevPopup(); allDropdownClose() }}
              />
              <AppBtn buttonText="Update" onClick={() => updateCompanyData()} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
