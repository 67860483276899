import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import "./style.css";
import { useTranslation } from "react-i18next";

//components

import Api1Tab from "../Api1Tab";
import Api2Tab from "../Api2Tab";
import Api3Tab from "../Api3Tab";
import Api4Tab from "../Api4Tab";
import { updateConfigSettings } from "../../../Services/adminActions";
import { getConfigSettings } from "../../../Services/master";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../env";
import { useAppSelector } from "../../../lib/store/store";

export default function ApiCredential() {
  const [apiCredentialTab, setApiCredentialTab] = useState(0);
  const authState = useAppSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const [apiCredentialConfig, setApiCredentialConfig] = useState({
    tab_name: "",
    agora_credentials: {
      customer_id: "",
      key: "",
      secret: "",
      chat_app_key: "",
      chat_orgname: "",
      chat_app_name: "",
      chat_websocket: "",
      chat_rest_api: "",
    }
  });


  useEffect(() => {
    fetchConfig();
  }, []);

  const updateSetting = async () => {
    const payload = {
      name: "api_credentials",
      value: apiCredentialConfig,
      is_visible: true,
    };

    try {
      const companyRes = await updateConfigSettings(
        payload,
        authState.authToken
      );
      setLoading(false);
      if (companyRes.success) {
        toast.success(companyRes.message, TOAST_CONFIG);
        fetchConfig();
      } else {
        toast.error(companyRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  const fetchConfig = async () => {
    try {
      const response = await getConfigSettings({ name: "api_credentials" });
      if (response.success) {
        if (response.data.data.length > 0) {
          const apiCredentials = response.data.data[0];
          setApiCredentialConfig({ ...apiCredentialConfig, ...apiCredentials.value })
        }
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };
  return (
    <>
      <Box className="addProfileFieldBox workspaceListBox">
        <Typography className="pageHeaderText">
          {t("apiCredentialPage.heading")}
        </Typography>

        {/* Apis Credentials Tab */}
        <Box className="PProfNav apiCredentialNav">
          <Box
            onClick={() => setApiCredentialTab(0)}
            className={
              apiCredentialTab === 0
                ? "pprofNavItem pprofNavItemActive"
                : "pprofNavItem"
            }
          >
            <Typography>{apiCredentialConfig?.tab_name}</Typography>
          </Box>
          {/* <Box
            onClick={() => setApiCredentialTab(1)}
            className={
              apiCredentialTab === 1
                ? "pprofNavItem pprofNavItemActive"
                : "pprofNavItem"
            }
          >
            <Typography>{t("apiCredentialPage.api2")}</Typography>
          </Box> */}

        </Box>
        {/* Apis Credentials Tab */}

        {apiCredentialTab === 0 ? (
          <Api1Tab
            apiCredentialTab={apiCredentialTab}
            apiCredentialConfig={apiCredentialConfig}
            setApiCredentialConfig={setApiCredentialConfig}
            updateSetting={updateSetting}
          />
        ) : apiCredentialTab === 1 ? (
          <Api2Tab apiCredentialTab={apiCredentialTab} />
        ) : apiCredentialTab === 2 ? (
          <Api3Tab apiCredentialTab={apiCredentialTab} />
        ) : apiCredentialTab === 3 ? (
          <Api4Tab apiCredentialTab={apiCredentialTab} />
        ) : null}
      </Box>
    </>
  );
}
