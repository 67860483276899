import React, { useEffect, useState } from 'react';
import "./style.css";
import { Box, Typography } from '@mui/material';

//images
import IIcon from "../../../Assets/Images/IIcon.png";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";
import { sideHaumIcon } from "../../../Assets/Data";

//components 
import { AppBtn, AppBtnOutLine } from "../../../Components/AppTools/AppButton";
import ReferenceDateExplicitTimePicker from "../../../Components/AppTools/TimePicker";
import SideMenu from "../../../Components/User/SideMenu";
import { getTimeByTimezone, renderUserLayout } from '../../../lib/helpers';
import { useAppSelector } from '../../../lib/store/store';
import { getCountries } from '../../../Services/master';
import { userProfile } from '../../../Services/auth';
import { updateLoggedInUserProfile } from '../../../Services/adminActions';
import { toast } from 'react-toastify';
import { TOAST_CONFIG } from '../../../env';


export default function TimeZone({ slideMenu, setSlideMenu, workspaceDrop, setWorkSpaceDrop }) {
  const authState = useAppSelector((state) => state.auth);
  const [updateTimeZone, setUpdateTimeZone] = useState(false);
  const [countryDrop, setCountryDrop] = useState(false);
  const [countryDropVal, setCountryDropVal] = useState("");
  const [timezoneDrop, setTimezoneDrop] = useState(false);
  const [timezoneDropVal, setTimezoneDropVal] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [timeZones, setTimeZones] = useState([]);
  const [timezoneDetails, setTimezoneDetails] = useState({
    timezone: "",
    timezone_country: ""
  })

  useEffect(() => {
    getAllCountryList();
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    let token = authState.authToken
    try {
      const response = await userProfile(token);
      const profileData = response?.data?.data;
      console.log(profileData)
      if (!profileData?.timezone)
        setUpdateTimeZone(true)
      setTimezoneDetails({
        timezone: profileData?.timezone,
        timezone_country: profileData?.timezone_country
      });
      setCountryDropVal(profileData?.timezone_country)
      setTimezoneDropVal(profileData?.timezone)
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  }

  const getAllCountryList = () => {
    getCountries().then((res) => {
      if (res.success && res.data.data.length > 0) {
        setCountryData(res.data.data);
      }
    });
  };

  const submitTimeZone = async () => {
    try {
      if (timezoneDetails.timezone == null && timezoneDetails.timezone_country == null)
        return toast.error("Timezone & Timezone country fields are required.");
      let response = await updateLoggedInUserProfile(timezoneDetails, authState.authToken);
      if (response.success) {
        fetchUserProfile();
        toast.success(response.message, TOAST_CONFIG);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  }

  const getChildren = () => {
    return (
      <Box className="researchersMainBox pageContainer">
        <Box className="timeZoneOuterBox">
          <Box sx={{ display: timezoneDetails?.timezone ? "block" : "none" }} className="timeZoneContainer">
            <Typography className='pageTimeZoneHeaderText'>Time Zone</Typography>
            <Box className="timeZoneTextBox infoTimeZoneTextBox">
              <Box className="iIcontD"><img src={IIcon} /></Box>
              <Box>
                <Typography>When the time zone from browser does not match the time zone on your profile, you will prompted to verify your time zone from below</Typography>
              </Box>
            </Box>

            {getTimeByTimezone() != getTimeByTimezone(timezoneDetails?.timezone) && <Box className="timeZoneBtn">
              <Typography>{getTimeByTimezone(timezoneDetails?.timezone)}</Typography>
            </Box>}
            <Box className="timeZoneLinkTextBox">
              <Box className="timeZoneNBtn redTimeZoneBtn">
                <Typography>{getTimeByTimezone()}</Typography>

              </Box>
              <Typography className='verifyLink' onClick={() => setUpdateTimeZone(true)} >Verify your time zone</Typography>
            </Box>
          </Box>
          {/* Update Time Zone */}
          <Box sx={{ display: updateTimeZone ? "block" : "none" }} className="timeZoneContainer">
            <Typography className='pageTimeZoneHeaderText'>Update My Time Zone</Typography>
            <Box className="timeZoneTextBox infoTimeZoneTextBox">
              <Box className="iIcontD"><img src={IIcon} /></Box>
              <Box>
                <Typography>Please select your country and time zone. If Daylight Saving Time is followed at any point during the year, select a "Daylight Time Zone".</Typography>
              </Box>
            </Box>
            <Box className="basicBox lastBasicBox">
              <Box className="inputBox">
                <Typography className="addResearcherLabel">Country</Typography>
                <input
                  onClick={() => setCountryDrop(!countryDrop)}
                  value={countryDropVal}
                  className="addResearcherInput genderInput"
                  placeholder="Select Country"
                />
                <img
                  style={{ rotate: countryDrop ? "180deg" : "0deg" }}
                  onClick={() => setCountryDrop(!countryDrop)}
                  className="downArrow"
                  src={DownArrowIcon}
                />

                <Box
                  sx={{ height: countryDrop ? "auto" : "0px" }}
                  className="genderDrop"
                >
                  {countryData.length > 0 && countryData.map((el, i) => (<Box key={i}
                    onClick={() => {
                      setCountryDropVal(el.name);
                      setTimeZones(el.timezones || []);
                      setCountryDrop(false);
                      setTimezoneDetails({
                        ...timezoneDetails,
                        timezone_country: el.name
                      })
                      console.log(el)
                    }}
                    className="genderDropItem"
                  >
                    <Typography>{el.name}</Typography>
                  </Box>))}
                </Box>
              </Box>
              <Box className="inputBox pickerBox">
                <Typography className="addResearcherLabel">Time Zone</Typography>
                <input
                  onClick={() => setTimezoneDrop(!timezoneDrop)}
                  value={timezoneDropVal}
                  className="addResearcherInput genderInput"
                  placeholder="Select Country"
                />
                <img
                  style={{ rotate: timezoneDrop ? "180deg" : "0deg" }}
                  onClick={() => setTimezoneDrop(!timezoneDrop)}
                  className="downArrow"
                  src={DownArrowIcon}
                />

                <Box
                  sx={{ height: timezoneDrop ? "auto" : "0px" }}
                  className="genderDrop"
                >
                  {timeZones.length > 0 && timeZones.map((el, i) => (<Box key={i}
                    onClick={() => {
                      setTimezoneDropVal(el.zoneName);
                      setTimezoneDrop(false);
                      setTimezoneDetails({
                        ...timezoneDetails,
                        timezone: el.zoneName
                      })
                    }}
                    className="genderDropItem"
                  >
                    <Typography>{el.zoneName}</Typography>
                  </Box>))}
                </Box>
              </Box>
            </Box>


            <Box className="workPupBtnBox">
              <AppBtnOutLine onClick={() => setUpdateTimeZone(false)} buttonText="Cancel" />

              <AppBtn buttonText="Save" onClick={submitTimeZone} />
            </Box>
          </Box>
          {/* Update Time Zone */}
        </Box>
      </Box>
    )
  }

  return renderUserLayout(authState?.userType, null, getChildren(), 'accountsetting')
}
