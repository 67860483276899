import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//images
import AdminBG from "../../../Assets/Images/Add-files-cuate.png";
import groupIcon from "../../../Assets/Images/groupsIcon.png";
import filterIcon from "../../../Assets/Images/filterIcon.png";
import exportIcon from "../../../Assets/Images/exportIcon.png";
import video1 from "../../../Assets/Images/Videos/part1.mp4";
import video2 from "../../../Assets/Images/Videos/part2.mp4";
import charImg from "../../../Assets/Images/chartcardImg.png";
import charImg2 from "../../../Assets/Images/chartImg2.png";
import recordTitleThum from "../../../Assets/Images/chartRecordThum.png";
import audioWave from "../../../Assets/Images/audioWav.png";
import Play from "../../../Assets/Images/play.png";

//component

export default function DateAndCharts() {
  const Navigate = useNavigate();
  const chaatData = true;
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const VideoCard = ({ video, title }) => {
    return (
      <Box className="chartVideoBox">
        <Box className="charterVideoSec">
          <video className="video" controls>
            <source src={video} type="video/mp4" />
          </video>
        </Box>
        <Box className="chartCardTextSection">
          <Box className="viewTitle">
            <span>{title}</span>
            <Typography
              onClick={() => Navigate("/participants/recap/video-review")}
              className="viewBtn"
            >
              {t("commonTxt.viewBtn")}
            </Typography>
          </Box>
          <Box className="likeCountBox">
            <Box>
              <span>😟</span>
              <Typography>
                18.5% <br />
                {t("commonTxt.Like")}
              </Typography>
            </Box>

            <Box>
              <span>😟</span>
              <Typography>
                18.5% <br />
                {t("commonTxt.dislike")}
              </Typography>
            </Box>

            <Box>
              <span>😟</span>
              <Typography>
                18.5% <br />
                {t("commonTxt.confused")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const ImgCard = ({ img, title }) => {
    return (
      <Box className="chartImageBox">
        <Box className="imgBoxCht">
          <img src={img} />
        </Box>

        <Box className="chartCardTextSection">
          <Box className="viewTitle">
            <span>{title}</span>
            <Typography
              className="viewBtn"
               onClick={() => Navigate("/participants/recap/image-review")}
            >
              {t("commonTxt.viewBtn")}
            </Typography>
          </Box>
          <Box className="likeCountBox">
            <Box>
              <span>😟</span>
              <Typography>
                18.5% <br />
                {t("commonTxt.Like")}
              </Typography>
            </Box>

            <Box>
              <span>😟</span>
              <Typography>
                18.5% <br />
                {t("commonTxt.dislike")}
              </Typography>
            </Box>

            <Box>
              <span>😟</span>
              <Typography>
                18.5% <br />
                {t("commonTxt.confused")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const TaskBox = ({ title, setState, url }) => {
    return (
      <Box className="chartSealBox">
        <Box className="viewTitle">
          <span>{title}</span>
          <Typography onClick={() => Navigate(url)} className="viewBtn">{t("commonTxt.viewBtn")}</Typography>
        </Box>
        <Box className="sealRow">
          <Box>
            <Box sx={{ background: "#4F33D1" }} className="roundSimbol"></Box>
            <Typography>Brand Name 1</Typography>
          </Box>
          <Typography>4.8</Typography>
        </Box>
        <Box className="sealRow">
          <Box>
            <Box sx={{ background: "#FF4E72" }} className="roundSimbol"></Box>
            <Typography>Brand Name 2</Typography>
          </Box>
          <Typography>2.6</Typography>
        </Box>
        <Box className="sealRow">
          <Box>
            <Box sx={{ background: "#21A1F9" }} className="roundSimbol"></Box>
            <Typography>Brand Name 3</Typography>
          </Box>
          <Typography>3.7</Typography>
        </Box>
        <Box className="sealRow">
          <Box>
            <Box sx={{ background: "#FFBE28" }} className="roundSimbol"></Box>
            <Typography>Brand Name 4</Typography>
          </Box>
          <Typography>3.0</Typography>
        </Box>
        <Box className="sealRow">
          <Box>
            <Box sx={{ background: "#41AD0E" }} className="roundSimbol"></Box>
            <Typography>Brand Name 5</Typography>
          </Box>
          <Typography>1.5</Typography>
        </Box>
      </Box>
    );
  };

  const ChartRecordBox = ({ title, url }) => {
    return (
      <Box className="chartRecordBox">
        <Box className="viewTitle">
          <Box className="rcThum">
            <img src={recordTitleThum} />
            <span>{title}</span>
          </Box>
          <Typography onClick={() => Navigate(url)} className="viewBtn">{t("commonTxt.viewBtn")}</Typography>
        </Box>
        <Box mb={2} className="audioWave">
          <img className="play" src={Play} />
          <img className="wave" src={audioWave} />
          <img className="wave" src={audioWave} />
          <Typography>08:16</Typography>
        </Box>
        <Box className="likeCountBox">
          <Box>
            <span>😟</span>
            <Typography>
              18.5% <br />
              {t("commonTxt.Like")}
            </Typography>
          </Box>
          <Box>
            <span>😟</span>
            <Typography>
              18.5% <br />
              {t("commonTxt.dislike")}
            </Typography>
          </Box>
          <Box>
            <span>😟</span>
            <Typography>
              18.5% <br />
              {t("commonTxt.confused")}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {!chaatData ? (
        <Box className="threadNoDataPage">
          <Box className="adminWorkSBG">
            <img src={AdminBG} />
          </Box>
          <Box className="adminBGTextBox">
            <Typography className="adminSubHeaderText">
              {t("recapExpression.expresssionText")}
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          <Box className="workspaceListBox DateChartBox">
            <Box className="threadNavBox">
              <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                <Box className="actTNavBtn">
                  <img src={groupIcon} />
                  <Typography>{t("commonTxt.groupBtn")}</Typography>
                </Box>
                <Box className="filterBtn">
                  <img src={filterIcon} />
                  <p>{t("commonTxt.filters")}</p>
                </Box>
              </Box>
              <Box className="actTNavBtn">
                <img src={exportIcon} />
                <Typography>{t("commonTxt.exportLink")}</Typography>
              </Box>
            </Box>

            <Box className="chartMainBox">
              <Box className="chartCol">
                {VideoCard({ video: video1, title: "“Fun Trip” Video" })}
                {TaskBox({
                  title: "Scale (fio) Task Name",
                  url: "/participants/recap/fill-it-out-1",
                })}
                {ChartRecordBox({
                  title: "20km Trek Review",
                  url: "/participants/recap/audio-review",
                })}
                {TaskBox({
                  title: "Rank-it & Sort-it Task Name",
                  url: "/participants/recap/rank-short",
                })}
                {VideoCard({ video: video2, title: "Road Trip Video" })}
              </Box>

              <Box className="chartCol">
                {ImgCard({ img: charImg, title: "Trekking Essentials Review" })}
                {TaskBox({
                  title: "Poll (2) Task Name",
                  url: "/participants/recap/fill-it-out-2",
                })}
                {TaskBox({
                  title: "Allocation (fio) Task Name",
                  url: "/participants/recap/fill-it-out/allocation",
                })}
                {ImgCard({
                  img: charImg2,
                  title: "Trekking Essentials Review",
                })}
              </Box>

              <Box className="chartCol">
                {TaskBox({
                  title: "Poll (multi) Task Name",
                  url: "/participants/recap/poll-mullti",
                })}
                {VideoCard({ video: video2, title: "Road Trip Video" })}
                {TaskBox({
                  title: "Matrix Task Name",
                  url: "/participants/recap/matrix-task",
                })}
                {VideoCard({ video: video1, title: "“Fun Trip” Video" })}
                {VideoCard({ video: video2, title: "Road Trip Video" })}
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
