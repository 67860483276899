import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import autoTham from "../../../Assets/Images/audioTham.png";
import audioWave from "../../../Assets/Images/audioWav.png";
import Play from "../../../Assets/Images/play.png";

import "./style.css";

//images
import videoTham from "../../../Assets/Images/c9b1cfbd77205f8e197ea72402a5bffb.png";
import videoIcon from "../../../Assets/Images/GvideoIcon.png";
import micIcon from "../../../Assets/Images/Gmic.png";
import headPhones from "../../../Assets/Images/BlackHeadphones.png";

//components
import { Status } from "../../AppTools/AppTool";
import { AppBtnOutLine, AppBtn } from "../../../Components/AppTools/AppButton";
import { t } from "i18next";

export default function LiveInterViewPop({ liveInterViewPop, setLiveInterViewPop }) {
  const [roleDrop, setRoleDrop] = useState(false)
  const [roleDropVal, setRoleDropVal] = useState("Join as");

  const roleItem = [
    {
      name: "Host Meeting",
      value: "Host Meeting"
    },
    {
      name: "Backroom observer",
      value: "Backroom observer"
    },
    {
      name: "Translator",
      value: "Translator"
    }]

  const [roleDrop2, setRoleDrop2] = useState(false)
  const [roleDropVal2, setRoleDropVal2] = useState("Select Language")
  const languages = [
    {
      name: "English",
      value: "English"
    },
    {
      name: "Hindi",
      value: "Hindi"
    },
    {
      name: "Spanish",
      value: "Spanish"
    },
    {
      name: "French",
      value: "French"
    },
    {
      name: "Chinese",
      value: "Chinese"
    },
    {
      name: "Arabic",
      value: "Arabic"
    },
    {
      name: "Russian",
      value: "Russian"
    },
    {
      name: "German",
      value: "German"
    },
    {
      name: "Japanese",
      value: "Japanese"
    }
  ];
  const handelOpenMetting = () => {
    setLiveInterViewPop(false);
    window.open("/participants/interview-meeting", "_blank");
  }
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setLiveInterViewPop(false);
    }
  };

  useEffect(() => {
    if (liveInterViewPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [liveInterViewPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: liveInterViewPop ? "flex" : "none" }}
        className="companyPop liveInterView"
      >
        <Box
          sx={{ display: liveInterViewPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className="livePHeader">{t("LiveInterview.pageHeader")} : {t("LiveInterview.Participant")} 1</Typography>
          <Typography className="lavel_L">{t("LiveInterview.Joinas")}</Typography>
          <Status
            setStatusDrop={setRoleDrop}
            statusDrop={roleDrop}
            setStatusDropVal={setRoleDropVal}
            statusDropVal={roleDropVal}
            label=" "
            itemAry={roleItem}
          />
          <Typography className="lavel_L">{t("LiveInterview.LanguageSpeak")}</Typography>
          <Status
            setStatusDrop={setRoleDrop2}
            statusDrop={roleDrop2}
            setStatusDropVal={setRoleDropVal2}
            statusDropVal={roleDropVal2}
            label=" "
            itemAry={languages}
          />

          <Box className="videoThumBox">
            <img src={videoTham} />
          </Box>

          <Box className="mediaInputBox">
            <Box><img src={videoIcon} /></Box>
            <Box><img src={micIcon} /></Box>
          </Box>
          <Box className="headPhoneRecomanded">
            <img src={headPhones} />
            <Typography>{t("LiveInterview.HeadphonesRecommended")}</Typography>
          </Box>
          <Typography className="csbText">{t("LiveInterview.FacingSystem")}</Typography>

          <Box className="audioWave">
            <img className="wave" src={audioWave} />
            <img className="wave" src={audioWave} />
          </Box>
          <Typography className="recordingText">{t("LiveInterview.recordingBeging")}</Typography>
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={() => setLiveInterViewPop(false)} />
            <AppBtn buttonText="Join Meeting" onClick={handelOpenMetting} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
