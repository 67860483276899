import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from "react-i18next";

//components 
import { AppBtn, AppBtnOutLine } from '../../../Components/AppTools/AppButton';

export default function ProjectManagementExcerpt() {

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  return (
    <>   
        <Box className="researchersMainBox pageContainer">
            <Box className="integrationsContainer">
                <Typography className='emailNotificationHeaderText'>{t("projectExcerpt.projectExcerptHead")}</Typography>
                <Typography className='integrationSubHeadText'>
                {t("projectExcerpt.projectExcerptSubText")}
                </Typography>
                <Box className="radioBox agreementRadioBox">
                <Box>
                  <input
                    className="emailSettingInput emailSettingRadio"
                    type='checkBox'
                    defaultChecked
                  />
                </Box>
                <Box>
                  <Typography className="emailSettingLabel">{t("projectExcerpt.excerptCheckbox1")}</Typography>
                </Box>
                </Box>
                <Box className="radioBox agreementRadioBox">
                    <Box>
                    <input
                        className="emailSettingInput emailSettingRadio"
                        type='checkbox'
                        defaultChecked
                    />
                    </Box>
                    <Box>
                    <Typography className="emailSettingLabel">{t("projectExcerpt.excerptCheckbox2")}</Typography>
                    </Box>
                </Box>
                

                <Box className="workPupBtnBox">
                  <AppBtnOutLine buttonText={t("commonTxt.resetBtn")} />
                  <AppBtn buttonText={t("commonTxt.saveBtn")} />
                </Box>
            </Box>
        </Box>
    </>
  )
}
