import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { useAppSelector } from "../../../lib/store/store";
//images

export default function DeleteResearchPublication({ deleteResearchPublication, setDeleteResearchPublication}) {

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const [loading, setLoading] = useState(false);
  let authState = useAppSelector(state => state.auth);
 
  const handelPopClose = () => {
    setDeleteResearchPublication(false);
  }

  useEffect(() => {
    if (deleteResearchPublication) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [deleteResearchPublication]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: deleteResearchPublication ? "flex" : "none" }}
        className="companyPop AddCardPopPeople"
        >
        <Box
            sx={{ display: deleteResearchPublication ? "flex" : "none" }}
            className="workspaceContaint deletePagePop"
        >
            <Typography className="companyHeader">Delete Publication</Typography>

            <Box className="basicBoxItem">
            <Typography className='addPageLabel'>Are you sure you want to delete the publication titled “A Study on Lorem Ipsum dolor sit Amet” ? Once deleted, this publication will not be available for anybody to view.</Typography>
            
            </Box>
            
            <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => setDeleteResearchPublication(false)} />
            <AppBtn buttonText={t("commonTxt.deleteBtn")} />
            </Box>
        </Box>
        </Box>
    </>
  );
}
