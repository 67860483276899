import { Box, Typography } from '@mui/material';
import React from 'react';
import arrow from "../../../Assets/Images/arrow.png";

const Pagination = ({ paginationData, onPageChange, isShowNxtPrv = true }) => {
    console.log("paginationData", paginationData);
    const generatePaginationButtons = () => {
        const buttons = [];
        const maxButtonsToShow = 5;

        let startPage = Math.max(1, paginationData.currentPage - Math.floor(maxButtonsToShow / 2));
        let endPage = Math.min(paginationData.totalPages, startPage + maxButtonsToShow - 1);

        if (paginationData.totalPages <= maxButtonsToShow) {
            startPage = 1;
            endPage = paginationData.totalPages;
        }

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <Box
                    key={i}
                    onClick={() => onPageChange(i)}
                    className={
                        paginationData.currentPage === i ? "pageNumber pageNumberActive" : "pageNumber"
                    }
                >
                    <p>{i}</p>
                </Box>
            );
        }

        return buttons;
    };

    const handlePrev = () => {
        if (paginationData.currentPage > 1) {
            onPageChange(paginationData.currentPage - 1);
        }
    }

    const handleNext = () => {
        if (paginationData.totalPages > paginationData.currentPage) {
            onPageChange(paginationData.currentPage + 1);
        }
    }

    return (
        paginationData.totalItems > 0 && <Box className="pagenation">
            {isShowNxtPrv && <Box onClick={handlePrev} className="tabelBtn">
                <img src={arrow} />
                <Typography>Previous</Typography>
            </Box>}

            <Box className="pageNumberBox">
                {generatePaginationButtons()}
            </Box>

            {isShowNxtPrv && <Box onClick={handleNext} className="tabelBtn">
                <Typography>Next</Typography>
                <img style={{ rotate: "180deg" }} src={arrow} />
            </Box>}
        </Box>
    );
};

export default Pagination;