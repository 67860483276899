import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authToken: "",
  userProfile: null,
  userType: null,
  wsUserType: null,
  is_support: false,
  prevUser: null
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthState: (state, action) => {
      state.authToken = action.payload;
    },
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
    },
    setIsSupport: (state, action) => {
      state.is_support = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    setWsUserType: (state, action) => {
      state.wsUserType = action.payload;
    },
    setPrevUser: (state, action) => {
      state.prevUser = action.payload;
    },
  },
});

export const { setAuthState, setUserProfile, setIsSupport, setUserType, setWsUserType, setPrevUser } = authSlice.actions;
export const authReducer = authSlice.reducer;
