import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./style.css";

import option2Line from "../../../Assets/Images/Option2LIcon.png"
import lockIcon from "../../../Assets/Images/lock.png"
import unlockIcon from "../../../Assets/Images/unlock.png"
import iIcon from "../../../Assets/Images/IIcon.png";

//components
import { AppBtn, AppBtnOutLine } from "../../../Components/AppTools/AppButton";
import { SearchBar, AntSwitch } from "../../AppTools/AppTool";
import { API_BASE_URL } from "../../../env";
import axios from "../../../lib/axios";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../env";
import { useAppSelector } from "../../../lib/store/store";

export default function GroupRearrangeTaskPop({ allDropVal4, setAllDropVal4, allTasksList, setAllTasksList, workspaceState, activityId }) {
  let authState = useAppSelector((state) => state.auth);
  const [lock, setLock] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTaskGroup, setSelectedTaskGroup] = useState([]);
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const initialGroupTaskState = {
    workspace_id: workspaceState?.selectedWorkspace?._id,
    project_id: workspaceState?.selectedProject?._id,
    activity_id: activityId,
    task_ids:[],
    name: "",
    randomize:false,
  };

  const [taskGroupData, setTaskGroupData] = useState(initialGroupTaskState);

  const defaultFilters2 = {
    'search': '',
    'status': '',
  }

  const [taskFilterState, setTaskFilterState] = useState({ ...defaultFilters2 });

  const handleFilterChange2 = (changedFilter2) => {
    let newFilters2 = {
      ...taskFilterState,
      ...changedFilter2,
    };
    setTaskFilterState(newFilters2);
    getTasks3(1, newFilters2);
  };

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setAllDropVal4("Activity Options");
    }
  };

  const getTasks3 = async (curPage, filters = taskFilterState) => {
    return await axios
      .get(
        API_BASE_URL + `/researchers/tasks?page=${curPage}&search=${filters.search}&workspace_id=${workspaceState?.selectedWorkspace?._id}&status=${filters.status}&project_id=${workspaceState?.selectedProject?._id}&activity_id=${activityId}`
      )
      .then((res) => {
        // console.log("=============Task Res======", res);
        if (res.status) {
          setAllTasksList(res.data.data);
        }
      });
  };

  const validationObj = {
    hasErr: false,
    name: {
      error: false,
      msg: "",
    },
    task_ids: {
      error: false,
      msg: "",
    },
  };

  const [validationErrors, setValidationErrors] = useState({
    ...validationObj,
  });

  const handleValidation = (formInput) => {
    formInput = formInput || taskGroupData;
    let validationerr = { ...validationObj };

    if (formInput.name == "") {
      validationerr.hasErr = true;
      validationerr.name = {
        error: true,
        msg: "Group Name is required",
      };
    }

    if (formInput.task_ids == "") {
      validationerr.hasErr = true;
      validationerr.task_ids = {
        error: true,
        msg: "Task Id is required",
      };
    }

    setValidationErrors(validationerr);
    if (validationerr.hasErr) {
      return false;
    }
    return true;
  };

  const setFormData = (e) => {
    let newState = {
      ...taskGroupData,
      [e.target.name]: e.target.value,
    };
    setTaskGroupData(newState);
    if (validationErrors.hasErr) {
      handleValidation(newState);
    }
   // console.log("Group Data=>",taskGroupData);
  };

  const handleTaskChange = (event,taskId) => {
    const imgPath = document.getElementById('imgLck-'+taskId);
    if (imgPath.src.includes(lockIcon)) {
      imgPath.src = unlockIcon;
    } else {
      imgPath.src = lockIcon;
    }
    const { value, checked } = event.target;
    if (checked) {
      if(selectedTaskGroup?.includes(value)){

      }
      else {
        selectedTaskGroup.push(value);
      }
    } else {
      let index= selectedTaskGroup.indexOf(value);
      selectedTaskGroup.splice(index, 1);
    }
    setTaskGroupData({
      ...taskGroupData,
      task_ids: selectedTaskGroup,
    });
  };

  const handleLock = (taskId) => {
    const imgPath = document.getElementById('imgLck-'+taskId);
    const checkboxVal = document.getElementById('taskCh-'+taskId);
    if (imgPath.src.includes(lockIcon)) {
      imgPath.src = unlockIcon;
     checkboxVal.checked = false;
    } else {
      imgPath.src = lockIcon;
      checkboxVal.checked = true;
    }
    if (taskGroupData.task_ids.includes(taskId)) {
      let index1= taskGroupData.task_ids.indexOf(taskId);
      taskGroupData.task_ids.splice(index1, 1);
      return;
    }
    else {
      taskGroupData.task_ids.push(taskId);
      return;
    }
  }

  const resetAll = () => {
    const images = document.querySelectorAll('.lockIcon img');
    images.forEach(img => {
      img.src = unlockIcon;
  });
    const checkboxes = document.querySelectorAll('.arrengItem input[type="checkbox"]');
      checkboxes.forEach(checkbox => {
          checkbox.checked = false;
      });
    setTaskGroupData(initialGroupTaskState);
    setAllDropVal4("Activity Options");
  }

  let saveTaskGroup = async () => {
    console.log("Final Task Group Data=>",taskGroupData);
    if (!handleValidation()) {
      toast.error("Please correct form fields", TOAST_CONFIG);
      return false;
    }
    setLoading(true);
    try {
      let response = await axios.post(
        API_BASE_URL + `/researchers/tasks-groups`,
        taskGroupData,
        authState.authToken
      );
      if (response.success) {
        resetAll();
        toast.success(response.message, TOAST_CONFIG);
      } else {
        toast.error(response.message, TOAST_CONFIG);
      }
    } catch (error) {
      let message = "Something went wrong, please try again!";
      let status_code = 500;
      if (error.response) {
        message = error.response.data.message;
        status_code = error.response.status;
      }
      return {
        success: false,
        message: message,
        status_code: status_code,
      };
    }
  };

  useEffect(() => {
    if (allDropVal4 === "Group Tasks") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [allDropVal4]);


  return (
    <Box onClick={handelPopClose} sx={{ display: allDropVal4 === "Group Tasks" ? "flex" : "none" }} className="workspacePop rearrangeTask groupRearrangeTask" id="popBackdrop">
      <Box sx={{ display: allDropVal4 === "Group Tasks" ? "flex" : "none" }} className="workspaceContaint">
        <Typography className="companyHeader">{t("groupingTasksPop.groupingTasksPopHead")}</Typography>

        <Typography sx={{ fontWeight: "600", marginBottom: "10px" }}>{t("groupingTasksPop.groupedTaskNameLabel")}</Typography>
        <Box className="arrangSearchBox">
          <input className="phoneInput" placeholder={t("groupingTasksPop.groupedTaskNamePlaceholder")} value={taskGroupData.name ?? ""}
                name="name"
                onChange={setFormData} />
        </Box>


        <Typography sx={{ fontWeight: "600", marginBottom: "10px" }}>{t("groupingTasksPop.searchTaskLabel")}</Typography>
        <Box className="arrangSearchBox">
          <SearchBar placeholder={t("rearrangeTaskPop.searchTasksPlaceholder")} handleFilterChange={handleFilterChange2} val={taskFilterState} />
        </Box>

        {allTasksList?.length >0 && allTasksList?.map((el, i) => (
          <Box key={i} className="arrengItem">
            <input className="custom-checkbox" type="checkBox" value={el._id}
            onChange={(event) => handleTaskChange(event,el._id)} id={`taskCh-${el._id}`} />
            <img className="opt2l" src={option2Line} />
            <Typography>{el.name}</Typography>
            <Box className="lockIcon" onClick={() => handleLock(el._id)}>
              {lock ? <img src={lockIcon} id={`imgLck-${el._id}`} /> : <img src={unlockIcon} id={`imgLck-${el._id}`} />}
            </Box>
          </Box>
        ))}

        <Box className="airadioSwitchBox">
          <AntSwitch
            onChange={(el) => setTaskGroupData({ ...taskGroupData,randomize:  el.target.checked } )} checked={taskGroupData?.randomize}
            inputProps={{ "aria-label": "ant design" }}
          />
          <Typography className="switchText">{t("rearrangeTaskPop.rearrangeTaskPopSwitch")}</Typography>
        </Box>
        <Box className="iBox">
          <img src={iIcon} />
          <Typography>{t("rearrangeTaskPop.rearrangeTaskPopNotify")}</Typography>
        </Box>

        <Box className="workPupBtnBox">
          <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => resetAll()} />
          <AppBtn buttonText={t("groupingTasksPop.groupSelectedTasksBtn")} onClick={() => saveTaskGroup()} />
        </Box>


      </Box>
    </Box>

  );
}
