import axios from "axios";
import { API_BASE_URL } from "../env";

export const createNewCustomField = async (payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  try {
    let data = await axios.post(
      API_BASE_URL + `/participant/profile-fields`,
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const updateCustomField = async (resourceId, payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  try {
    let data = await axios.put(
      API_BASE_URL + `/participant/profile-fields/${resourceId}`,
      payload,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const deleteCustomField = async (resourceId, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  try {
    let data = await axios.delete(
      API_BASE_URL + `/participant/profile-fields/${resourceId}`,
      config
    );
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const participantAction = async (url, payload, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  try {
    let data = await axios.post(API_BASE_URL + url, payload, config);
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const researcherAction = async (url, payload, token, isPost = true) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  try {
    const data = isPost
      ? await axios.post(`${API_BASE_URL}${url}`, payload, config)
      : await axios.put(`${API_BASE_URL}${url}`, payload, config);

    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const researcherDeleteAction = async (url, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  try {
    const data = await axios.delete(`${API_BASE_URL}${url}`, config);
    console.log(data.data);
    return data.data;
  } catch (error) {
    let message = "Something went wrong, please try again!";
    let status_code = 500;
    if (error.response) {
      message = error.response.data.message;
      status_code = error.response.status;
    }
    return {
      success: false,
      message: message,
      status_code: status_code,
    };
  }
};

export const getBasicSettings = async () => {};

export const addPageCardAPI = async (payload, token) => {};
