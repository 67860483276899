import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";

//images
import backArrow from "../../../Assets/Images/noun-back.png";
import iIcon2 from "../../../Assets/Images/IIcon.png";
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png";

//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { AntSwitch, Status, PickEmoji } from "../../AppTools/AppTool"


export default function ReviewAnImage({ reviewAnImage, setReviewAnImage, handelAddTaskPopBack }) {
  const [image, setImage] = useState(null);
  const [scalDrop, setScalDrop] = useState(false)
  const [scalDropVal, setScalDropVal] = useState("5 - point scale")
  const [EmojiDrop, setEmojiDrop] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState();
  const [EmojiDrop2, setEmojiDrop2] = useState(false);
  const [selectedEmoji2, setSelectedEmoji2] = useState();

  const [EmojiDrop3, setEmojiDrop3] = useState(false);
  const [selectedEmoji3, setSelectedEmoji3] = useState();


  const scaleArrya = [
    "item",
    "item",
    "item",
  ]


  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };




  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setReviewAnImage(false);
    }
  };

  useEffect(() => {
    if (reviewAnImage) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [reviewAnImage]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: reviewAnImage ? "flex" : "none" }}
        className="companyPop reviewImages"
      >
        <Box
          sx={{ display: reviewAnImage ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Box onClick={handelAddTaskPopBack} className="openBtn taskBackBtn">
            <img src={backArrow} />
            <Typography className="neonBackText">Back</Typography>
          </Box>
          <Typography className="taskPopMainLabel">Review an Image Task</Typography>
          <Box className="iBox">
            <img src={iIcon2} />
            <Typography>Mark-up and annotate precise locations of interest on any image you provide.</Typography>
          </Box>

          <Box className="basicBoxItem">
            <Typography className='eTemPopLabel '>Task Name</Typography>
            <Box className="pickerBox cardTitleLabel">
              <input className='eTemInput' placeholder='Type a name for your topic' />
            </Box>
          </Box>


          <Typography className='eTemPopLabel '>Image for review</Typography>
          <Box className='mediaInputbox upload-area' onClick={() => {
            document.getElementById("fileInput").click();
          }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}>
            <img src={uploadIcon} />
            <Typography>Click or drag media files to this area to upload</Typography>
            <input
              id="fileInput"
              style={{ display: "none" }}
              type="file"
              onChange={handleFileInputChange}
            />
          </Box>
          <Typography className="fileuploadBotLabel">Image should be at least 800px wide (ideal width  1440px)</Typography>

          <Typography className='eTemPopLabel '>Overall Image Rating</Typography>
          <Box className="airadioSwitchBox">
            <AntSwitch
              defaultChecked
              inputProps={{ "aria-label": "ant design" }} />
            <Typography className="switchText">Overall rating required</Typography>
          </Box>

          <Box ml={5} sx={{ width: "40%" }}>
            <Typography>Scale</Typography>
            <Status statusDrop={scalDrop} setStatusDrop={setScalDrop} setStatusDropVal={setScalDropVal} statusDropVal={scalDropVal} label={scaleArrya} />
          </Box>

          <Box ml={5} mt={3} sx={{ width: "40%" }}>
            <Typography>Labels</Typography>

            <Box mt={1} sx={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
              <Typography>1.</Typography>
              <input className="phoneInput" value="Very Unlikely" />
            </Box>
            <Box mt={1} sx={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
              <Typography>2.</Typography>
              <input className="phoneInput" value="Unlikely" />
            </Box>
            <Box mt={1} sx={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
              <Typography>3.</Typography>
              <input className="phoneInput" value="Neural" />
            </Box>
            <Box mt={1} sx={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
              <Typography>4.</Typography>
              <input className="phoneInput" value="Likely" />
            </Box>
            <Box mt={1} sx={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
              <Typography>5.</Typography>
              <input className="phoneInput" value="Very Likely" />
            </Box>
          </Box>

          <Box my={3} sx={{ width: "100%" }}>
            <Box mb={1} className="tagInputBOx"><input
              style={{ marginRight: "10px" }}
              className="custom-checkbox"
              type="checkBox"
            />
              <Typography>Reminder to schedule a meeting</Typography>
            </Box>
            <input style={{ marginLeft: "30px", width: "96%" }} className="phoneInput" placeholder="Type some basic instructions for the participant" />
          </Box>


          <Typography className='eTemPopLabel'>Tags Settings</Typography>

          <PickEmoji
            setEmojiDrop={setEmojiDrop}
            EmojiDrop={EmojiDrop}
            setSelectedEmoji={setSelectedEmoji}
            selectedEmoji={selectedEmoji}
          />
          <PickEmoji
            setEmojiDrop={setEmojiDrop2}
            EmojiDrop={EmojiDrop2}
            setSelectedEmoji={setSelectedEmoji2}
            selectedEmoji={selectedEmoji2}
          />
          <PickEmoji
            setEmojiDrop={setEmojiDrop3}
            EmojiDrop={EmojiDrop3}
            setSelectedEmoji={setSelectedEmoji3}
            selectedEmoji={selectedEmoji3}
          />

          <Typography className="addTagesText">Add Tag</Typography>
          <Typography className='eTemPopLabel'>Comment Settings</Typography>


          <Box my={3} sx={{ width: "100%" }}>
            <Box mb={1} className="tagInputBOx"><input
              style={{ marginRight: "10px" }}
              className="custom-checkbox"
              type="checkBox"
            />
              <Typography>Customize prompt</Typography>
            </Box>
            <input style={{ marginLeft: "30px", width: "96%" }} className="phoneInput" placeholder="Why did you add this tag here?" />
          </Box>


          <Box mb={1} className="tagInputBOx">
            <input
              style={{ marginRight: "10px" }}
              className="custom-checkbox"
              type="checkBox"
            />
            <Typography>Enforce tag limit</Typography>
          </Box>

          <Box className="limitBox nLimitebox">
            <input className="phoneInput" value={"01"} />
            <Typography>to</Typography>
            <input className="phoneInput" value={"04"} />
            <Typography>Tags</Typography>
          </Box>

          <Box mb={5} mt={2} className="tagInputBOx">
            <input
              style={{ marginRight: "10px" }}
              className="custom-checkbox"
              type="checkBox"
            />
            <Typography>Allow text formatting</Typography>
          </Box>



          <Typography sx={{ margin: "24px 0 65px 0" }} className='eTemPopLabel'>Task Settings</Typography>
          <Box mt={3} mb={1} className="tagInputBOx">
            <input
              style={{ marginRight: "10px" }}
              className="custom-checkbox"
              type="checkBox"
            />
            <Typography>Enforce total tag limit</Typography>
          </Box>

          <Box className="limitBox nLimitebox">
            <input className="phoneInput" value={"01"} />
            <Typography>to</Typography>
            <input className="phoneInput" value={"04"} />
            <Typography>Tags</Typography>
          </Box>

          <Box mb={5} mt={2} className="tagInputBOx">
            <input
              style={{ marginRight: "10px" }}
              className="custom-checkbox"
              type="checkBox"
            />
            <Typography>Response  Required</Typography>
          </Box>


          <Typography className='eTemPopLabel'>Advance Settings</Typography>
          <Box mt={2} className="tagInputBOx">
            <input
              style={{ marginRight: "10px" }}
              className="custom-checkbox"
              type="checkBox"
            />
            <Typography>Limit who can complete this task</Typography>
          </Box>

          <Box mt={2} className="tagInputBOx">
            <input
              style={{ marginRight: "10px" }}
              className="custom-checkbox"
              type="checkBox"
            />
            <Typography>Customize points awarded for task completetion </Typography>
          </Box>





          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={() => setReviewAnImage(false)} />
            <AppBtnOutLine buttonText="Save & Preview" />
            <AppBtn buttonText="Save Task" />
          </Box>
        </Box>
      </Box>
    </>
  );
}
