import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
//components
import { AppBtnOutLine, AppBtn } from "../../../Components/AppTools/AppButton"
import { useTranslation } from "react-i18next";


export default function ScheduleInterviewParticipantPop({ scheduleInterviewParticipantPop, setScheduleInterviewParticipantPop }) {
  const { t, i18n } = useTranslation();

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setScheduleInterviewParticipantPop(false);
    }
  };

  useEffect(() => {
    if (scheduleInterviewParticipantPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [scheduleInterviewParticipantPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: scheduleInterviewParticipantPop ? "flex" : "none" }}
        className="companyPop liveInterView"
      >
        <Box
          sx={{ display: scheduleInterviewParticipantPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className="livePHeader">{t("LiveInterview.Participant")}</Typography>
          <Box className="deleteAccountBox">
            <Box className="inputBox">
              <input className="addResearcherInput" placeholder="Name, Email, UserID" />
            </Box>
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={() => setScheduleInterviewParticipantPop(false)} />
            <AppBtn buttonText="Save" />
          </Box>
        </Box>
      </Box>
    </>
  );
}
