import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import IIcon from "../../../Assets/Images/IIcon.png";

//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { TOAST_CONFIG } from "../../../lib/constants";
import axios from '../../../lib/axios'
import { toast } from "react-toastify";

export default function RemoveFrmWSPop({
  removefromWorkspace,
  setRemFromWorkspace,
  resRemFromWorkspace,
  setResRemFromWorkspace,
  colRemFromWorkspace,
  setColRemFromWorkspace,
  obsRemFromWorkspace,
  setObsRemFromWorkspace,
  partRemFromWorkspace,
  setPartRemFromWorkspace,
  userTitle,
  userWorkspace,
  removeWokspacesListData,
  setReloadList,
}) {

  const handelPopClose = () => {
    setRemFromWorkspace(false);
    setResRemFromWorkspace(false);
    setColRemFromWorkspace(false);
    setObsRemFromWorkspace(false);
    setPartRemFromWorkspace(false);
  };

  // useEffect(()=> {

  // },[])

  const handelRemoveResearcher = () => {
    setResRemFromWorkspace(false);
      return new Promise((resolve, reject) => {
        let reqPayload = {
          "user_id": removeWokspacesListData?._id, 
          "workspace_id": removeWokspacesListData?.workspaces[0]?._id, 
        }
          axios.post(`/super-admin/admin/workspaces/remove-user`, reqPayload).then((res) => {
              if (res.success) {
                  resolve({
                      success: true,
                      message: res.message
                  });
                  toast.success(res.message, TOAST_CONFIG);
                  setReloadList(true);
              } else {
                  reject({
                      success: false,
                      message: res.message
                  });
                  toast.success(res.message, TOAST_CONFIG);
              }
          }).catch((err) => {
              reject({
                  success: false,
                  message: err.message
              });
          })
      });
  };

  // Inside RemoveFrmWSPop component
  console.log("userTitle:", userTitle);
  console.log("userWorkspace:", userWorkspace);

  useEffect(() => {
    const shouldDisableScrolling =
      removefromWorkspace ||
      resRemFromWorkspace ||
      colRemFromWorkspace ||
      obsRemFromWorkspace ||
      partRemFromWorkspace;
    document.body.style.overflow = shouldDisableScrolling ? "hidden" : "auto";
  }, [
    removefromWorkspace,
    resRemFromWorkspace,
    colRemFromWorkspace,
    obsRemFromWorkspace,
    partRemFromWorkspace,
  ]);

  return (
    <>
      <Box
        onClick={handelPopClose}
        id="popBackdrop"
        sx={{
          display:
            removefromWorkspace ||
            resRemFromWorkspace ||
            colRemFromWorkspace ||
            obsRemFromWorkspace ||
            partRemFromWorkspace
              ? "flex"
              : "none",
        }}
        className="workspaceDeletePopContainer"
      >
        <Box className="workspaceDeleteBox workspaceDeletePopTextAlign">
          <Typography className="deletePopHeader">
            Remove "{removeWokspacesListData?.workspaces[0]?.name}" ?
          </Typography>

          <Box className="emailVerifyTextBox infoEmailVerifyTextBox">
            <Box className="iIcontD">
              <img src={IIcon} />
            </Box>
            <Box>
              <Typography>
                For a company to be removed, all the workspace related projects
                should be closed.
              </Typography>
            </Box>
          </Box>
          <Box className="deleteTextBox">
            <Typography>
              Are you sure you want to remove "{removeWokspacesListData?.name}" from "{removeWokspacesListData?.workspaces[0]?.name}"
              ? Kindly note that this action cannot be un-done, any Workspace
              once deleted cannot be retrieved again.
            </Typography>
          </Box>
          <Box className="deleteBtnBox">
            {[
              { state: removefromWorkspace, setState: setRemFromWorkspace },
              { state: resRemFromWorkspace, setState: setResRemFromWorkspace },
              { state: colRemFromWorkspace, setState: setColRemFromWorkspace },
              { state: obsRemFromWorkspace, setState: setObsRemFromWorkspace },
              {
                state: partRemFromWorkspace,
                setState: setPartRemFromWorkspace,
              },
            ].map(
              ({ state, setState }, index) =>
                state && (
                  <AppBtnOutLine
                    key={index}
                    buttonText="Cancel"
                    onClick={() => setState(false)}
                  />
                )
            )}

            <AppBtn buttonText="Remove" onClick={() => {handelRemoveResearcher(); handelPopClose()}} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
