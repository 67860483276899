import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";

//images

export default function DeleteCardPop({ deleteCardPop, setDeleteCardPop }) {
 
  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setDeleteCardPop(false);
    }
  };

  useEffect(() => {
    if (deleteCardPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [deleteCardPop]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: deleteCardPop ? "flex" : "none" }}
        className="companyPop AddCardPopPeople"
      >
        <Box
          sx={{ display: deleteCardPop ? "flex" : "none" }}
          className="workspaceContaint deletePagePop"
        >
          <Typography className="companyHeader">Delete Card</Typography>

          <Box className="basicBoxItem">
            <Typography className='addPageLabel'>Are you sure you want to delete the “Welcome User Name” People Card from your Home section page, named “Welcome Researcher!” ?</Typography>
            
          </Box>
          
          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={() => setDeleteCardPop(false)} />
            <AppBtn buttonText="Delete" />
          </Box>



        </Box>
      </Box>
    </>
  );
}
