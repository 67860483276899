import React, { useState, useEffect } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
//components
import { AppBtnOutLine, AppBtn } from "../../../Components/AppTools/AppButton"

export default function ScheduleInterviewParticipantPop({
  scheduleInterviewParticipantPop,
  setScheduleInterviewParticipantPop,
  participants,
  setScheduleCreate,
  scheduleCreate,
  createSchedule,
  resetAll
}) {

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
      setScheduleInterviewParticipantPop(false);
    }
  };

  useEffect(() => {
    if (scheduleInterviewParticipantPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [scheduleInterviewParticipantPop]);

  const handleSubmit = ()=>{
    createSchedule();
  }

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: scheduleInterviewParticipantPop ? "flex" : "none" }}
        className="companyPop liveInterView"
      >
        <Box
          sx={{ display: scheduleInterviewParticipantPop ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className="livePHeader">{t("workspaceHome.Participant")}</Typography>
          <Box className="deleteAccountBox">
            <Box className="inputBox">
              {/* <input className="addResearcherInput" placeholder="Name, Email, UserID" /> */}
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="participant-select-label">Select Participant</InputLabel>
                <Select
                  labelId="participant-select-label"
                  id="participant-select"
                  value={scheduleCreate.participants[0] ?? null}
                  onChange={(e) => setScheduleCreate((prev) => ({
                    ...prev,
                    participants: [e.target.value]
                  }))}
                  label={"Choose a Participant"}
                >
                  {participants.map((participant) => (
                    <MenuItem
                      key={participant._id}
                      value={participant._id}
                    >
                      {participant.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>

          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")} onClick={() => resetAll()} />
            <AppBtn buttonText={t("commonTxt.saveBtn")} onClick={handleSubmit}/>
          </Box>
        </Box>
      </Box>
    </>
  );
}
