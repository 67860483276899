import React from 'react'
import AdminLayout from '../partials/AdminLayout';
import { Box } from '@mui/material';
import ActionLog from '../../../Components/Admin/ActionLog';
import { renderUserLayout } from '../../../lib/helpers';
import { useAppSelector } from '../../../lib/store/store';

const ActionLogPage = () => {
  const authState = useAppSelector((state) => state.auth);
  console.log("authState", authState.userType);

  const getChildren =() => {
    return (
      <Box
        className="adminMainBox"
        sx={{ display: "flex" }}
      >
        <ActionLog />
      </Box>
    )
  }

  return renderUserLayout(authState?.userType, null, getChildren(), 'main');
}

export default ActionLogPage;