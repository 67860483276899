import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Divider,
  List,
  ListItem,
  RadioGroup,
  Radio,
} from "@mui/material";

import { useTranslation } from "react-i18next";

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

//components
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";
import { AntSwitch } from "../../AppTools/AppTool";
import { EditAdminList } from "../../../Assets/Data";
import ReactFlagsSelect from "react-flags-select";
import DummyUser from "../../../Assets/Images/user-dummy.png";
import DownArrowIcon from "../../../Assets/Images/chevron-down.png";

import backArrow from "../../../Assets/Images/noun-back.png";
import shareIcon from "../../../Assets/Images/shareIcon.png";
import pastIcon from "../../../Assets/Images/pastIcon.png";
import VisibleIcon from "../../../Assets/Images/VisibleIcon.png";
import { useAppSelector } from "../../../lib/store/store";
import axios from "../../../lib/axios";
import { TOAST_CONFIG } from "../../../lib/constants";
import { toast } from "react-toastify";
import { updateWorkspaceAdministrationApi, uploadUserDirectoryImage } from "../../../Services/adminActions";
import { formatDate, urlToBase64, viewImage } from "../../../lib/helpers";
import { STORAGE_BASE_PATH } from "../../../env";
import { getCountries } from "../../../Services/master";

export default function EditAdmin({ EditAdminSection, setEditAdminSection, updateWSAdmin, workspaceUserType, setReloadList }) {
  const authState = useAppSelector((state) => state.auth);
  const workspaceState = useAppSelector((state) => state.workspace);

  const [selected, setSelected] = useState("");
  const [redio, setRedio] = useState(0);
  const [pwdVisible, setPwdVisible] = useState(false);
  const [pwdVisible2, setPwdVisible2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const { t, i18n: { changeLanguage, language } } = useTranslation();

  const [countryDrop, setCountryDrop] = useState(false);
  const [timeZoneDrop, setTimeZoneDrop] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [timeZoneList, setTimezoneList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({
    countryName: "",
    countryEmoji: "",
  });
  const [timeZoneVal, setTimeZoneVal] = useState(
    "IST (Indian Standard Time): 5:01 PM"
  );


  const initializeWsAdminObj = {
    name: "",
    email: "",
    username: "",
    password: "",
    confirm_password: "",
    two_fa_enabled: false,
    is_unsubscribe_emails_check: false,
    role: "",
    force_password: false,
    donot_enforce_2fa: false,
    confirm_username: false,
    status: "active",
    workspaces: [workspaceState?.selectedWorkspace?._id],
    profile_pic: "",
    // timezone: "",
  };

  const [editWsAdminApi, setEditWsAdminApi] = useState({ ...initializeWsAdminObj });
  const [listingData, setListingData] = useState(null);
console.log(listingData);
  const getAllCountryList = () => {
    getCountries().then((res) => {
      if (res.success && res.data.data.length > 0) {
        setCountryList(res.data.data);
      }
    });
  };

  useEffect(() => {
    if (updateWSAdmin) {
      getData();
      getAllCountryList();
    }
  }, [updateWSAdmin]);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/super-admin/admin/${workspaceUserType}?_id=${updateWSAdmin}`);
      if (res.status === 200) {
        const updatedKeys = { ...initializeWsAdminObj };

        const allWsAdminListData = res.data.data[0];
        setListingData(allWsAdminListData);

        console.log("Profile pic:", allWsAdminListData?.profile_pic);
      
        if (allWsAdminListData?.profile_pic === 'undefined' || allWsAdminListData?.profile_pic == null) {
          setImage(null);
        } else {
          const imageUrl = STORAGE_BASE_PATH + `/` + allWsAdminListData?.profile_pic;
          console.log("Image URL:", imageUrl);
          urlToBase64(imageUrl, function (base64Img) {
            if (base64Img) {
              setImage(base64Img);
            } else {
              console.error("Failed to convert image URL to base64");
            }
          });
        }

        if (allWsAdminListData.workspaces?.[0]?.assigned_users?.length > 0) {
          allWsAdminListData.workspaces[0].assigned_users.map((item) => {
            if (item.user_id === updateWSAdmin) {
              updatedKeys.role = item.user_role;
            }
          });
        }
        Object.keys(updatedKeys).forEach(key => {
          if (allWsAdminListData[key] !== undefined) {
            if (key !== "password") {
              updatedKeys[key] = allWsAdminListData[key];
            }
          }
        });
        setEditWsAdminApi(updatedKeys);
        if (allWsAdminListData?.status == "active") {
          setRedio(0);
        } else {
          setRedio(1);
        }

        if (allWsAdminListData.country.length > 0) {
          const assignedCountry = allWsAdminListData.country.map((item) => ({
            name: item.name,
            _id: item._id,
            emoji: item.emoji,
            timezone: item.timezones,
          }));
          if (assignedCountry.length > 0) {
            setSelectedCountry({
              countryName: assignedCountry[0].name,
              countryEmoji: assignedCountry[0].emoji,
            });
            if (assignedCountry[0]?.timezone.length > 0) {
              setTimezoneList(assignedCountry[0]?.timezone);
              assignedCountry[0]?.timezone.map((tz) => {
                setTimeZoneVal(tz.zoneName);
              });
            }
          }
        } else {
          setSelectedCountry({
            countryName: "",
            countryEmoji: "",
          });
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching participant data:", error);
    }
  };

  const setFormValue = (e) => {
    let newFormData = {
      ...editWsAdminApi,
      [e.target.name]: e.target.value,
    };
    setEditWsAdminApi(newFormData);
  };

  const handleToggleChange = (key) => (event) => {
    const newStatus = event.target.checked;
    setEditWsAdminApi({
      ...editWsAdminApi,
      [key]: newStatus
    })
  }

  const handleTimeZone = (el) => {
    setTimeZoneVal(el);
    const newStatus = {
      ...editWsAdminApi,
      timezone: el,
    };
    setEditWsAdminApi(newStatus);
  };

  let updateWorkSpaceAdmin = async () => {
    setLoading(true);
    try {
      let { confirm_password, ...adminData } = editWsAdminApi;
      let apiRes = await updateWorkspaceAdministrationApi(
        updateWSAdmin,
        adminData,
        authState.authToken
      );
      setLoading(false);
      if (apiRes.success) {
        toast.success(apiRes.message, TOAST_CONFIG);
        setEditWsAdminApi({ ...initializeWsAdminObj })
        setEditAdminSection(false);
        setReloadList(true);
      } else {
        toast.error(apiRes.message, TOAST_CONFIG);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
      setLoading(false);
    }
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      updateImage(file);
    }
  };

  const updateImage = async (file) => {
    const formData = new FormData()
    formData.append('file', file)
    try {
      let imageUploadRes = await uploadUserDirectoryImage(formData, authState.authToken);
      if (imageUploadRes.success) {
        setEditWsAdminApi({...editWsAdminApi, profile_pic: imageUploadRes?.data?.upload_id})
        toast.success(imageUploadRes.message, TOAST_CONFIG);
      } else {
        toast.error(imageUploadRes.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again", TOAST_CONFIG);
    }
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput").click();
  };

  
  return (
    <>
      <Box
        className="EditAdminSection"
        sx={{ display: EditAdminSection ? "block" : "none" }}
      >
        <Box
          mb={2}
          onClick={() => setEditAdminSection(false)}
          className="openBtn"
        >
          <img src={backArrow} />
          <Typography className="neonBackText">
            {t("commonTxt.backBtn")}
          </Typography>
        </Box>
        <Typography variant="h4">{t("admin.editAdmin")}</Typography>
        <Box className="avatarInfoBox">
          <Box className="avatarImgBox">
            {/* {(listingData?.profile_pic == 'undefined' || listingData?.profile_pic == null) ? <img src={DummyUser} /> : <img src={viewImage(listingData?.profile_pic)} /> } */}
            {!image || image === "undefined" ? (
              <img src={DummyUser} alt="Dummy User" />
            ) : (
              <img src={image} alt="Profile" />
            )}
          </Box>
          <Box className="avatarIfoBox">
            <Typography mb={3}>
              Admin:
              <span>
                {listingData?.name} ( {/* {listingData?.is_participant == true ? "Participant" : ""} */} )
              </span>
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                mr={2}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                onClick={triggerFileInput}
              >
                <img src={shareIcon} />
                <Typography ml={1}>Upload Image</Typography>
              </Box>
            </Box>
          </Box>
          <input
            id="fileInput"
            style={{ display: "none" }}
            type="file"
            onChange={handleFileInputChange}
          />
        </Box>

        <Grid item sm={12} mb={3}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            defaultValue="Active"
            name="row-radio-buttons-group"
          >
            <Box className="redioItem" onClick={() => {
              setRedio(0);
              setEditWsAdminApi({
                ...editWsAdminApi,
                status: "active",
              });
            }}>
              <Box
                className={
                  redio === 0
                    ? "customRedio customRedioActive"
                    : "customRedio"
                }
              >
                <Box></Box>
              </Box>
              <Typography>{t("admin.active")}</Typography>
            </Box>

            <Box className="redioItem" onClick={() => {
              setRedio(1);
              setEditWsAdminApi({
                ...editWsAdminApi,
                status: "inactive",
              });
            }}>
              <Box
                className={
                  redio === 1
                    ? "customRedio customRedioActive"
                    : "customRedio"
                }
              >
                <Box></Box>
              </Box>
              <Typography>{t("admin.inactive")}</Typography>
            </Box>
          </RadioGroup>
        </Grid>
        <Box className="infolabelBox">
          <Box className="infoRowBox">
            <Box>
              <Typography>
                Assigned Projects:{" "}
                <span></span>
              </Typography>
            </Box>
            <Box>
              <Typography>
                Project Visits: <span></span>
              </Typography>
            </Box>
          </Box>
          <Box className="infoRowBox">
            <Box>
              <Typography>
                Last Visit: <span>{formatDate(listingData?.last_visit)}</span>
              </Typography>
            </Box>
            <Box>
              <Typography>
                Added:
                <span>
                  {" " + formatDate(listingData?.created_at)}
                </span>
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* {EditAdminList &&
          EditAdminList.map((el, index) => (
            <Grid container spacing={4}>
              <Grid item sm={2}>
                <img src={el.img} />
              </Grid>
              <Grid item sm={10}>
                <Typography variant="h4">
                  {t("admin.admin")} {el.adminID}: {el.name} ({el.user})
                </Typography>
                <List className="listIcons">
                  <ListItem>
                    <img className="editAPIcon" src={shareIcon} />
                    {t("admin.uploadImg")}
                  </ListItem>
                  <ListItem>
                    <img className="editAPIcon" src={pastIcon} />
                    {t("admin.pasteURL")}
                  </ListItem>
                </List>
              </Grid>
              <Grid item sm={12}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  defaultValue="Active"
                  name="row-radio-buttons-group"
                >
                  <Box className="redioItem" onClick={() => {
                    setRedio(0);
                    setEditWsAdminApi({
                      ...editWsAdminApi,
                      status: "active",
                    });
                  }}>
                    <Box
                      className={
                        redio === 0
                          ? "customRedio customRedioActive"
                          : "customRedio"
                      }
                    >
                      <Box></Box>
                    </Box>
                    <Typography>{t("admin.active")}</Typography>
                  </Box>

                  <Box className="redioItem" onClick={() => {
                    setRedio(1);
                    setEditWsAdminApi({
                      ...editWsAdminApi,
                      status: "inactive",
                    });
                  }}>
                    <Box
                      className={
                        redio === 1
                          ? "customRedio customRedioActive"
                          : "customRedio"
                      }
                    >
                      <Box></Box>
                    </Box>
                    <Typography>{t("admin.inactive")}</Typography>
                  </Box>
                </RadioGroup>
              </Grid>
              <Grid item sm={6}>
                <Box className="greyBox">
                  {t("admin.assignedPro")}: <span></span>
                </Box>
                <Divider className="gapbetween" />
                <Box className="greyBox">
                  {t("admin.projectsVisits")}: <span>{el.projectvisits}</span>
                </Box>
              </Grid>
              <Grid item sm={6}>
                <Box className="greyBox">
                  {t("admin.lastVisit1")}: <span>{el.lastvisit}</span>
                </Box>
                <Divider className="gapbetween" />
                <Box className="greyBox">
                  {t("admin.added")}: <span>{el.added}</span>
                </Box>
              </Grid>
            </Grid>
          ))} */}

        {/* <Box className="FormField">
          <Box className="csPBox">
            <Typography className="companyInputLabel">
              {t("admin.url")}
            </Typography>
            <input
              className="phoneInput"
              placeholder={t("admin.placeholder3")}
            />
          </Box>
        </Box> */}
        <Typography variant="h5">{t("admin.accDetails")}</Typography>
        <Box className="csPBox">
          <Typography className="companyInputLabel" mt={2} mb={1}>Name</Typography>
          <input
            className="phoneInput"
            placeholder={t("admin.enterName")}
            name="name"
            value={editWsAdminApi?.name}
            onChange={setFormValue}
          />
        </Box>
        <Box className="FormField">
          <FormLabel className="label">{t("admin.email")}</FormLabel>
          <input
            className="phoneInput"
            placeholder="Enter Email"
            name="email"
            value={editWsAdminApi?.email}
            onChange={setFormValue}
          />
          <Box
            className="airadioSwitchBox"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              margin: "0",
              marginTop: "5px",
            }}
          >
            <AntSwitch
              inputProps={{ "aria-label": "unsubscribe emails" }}
              checked={editWsAdminApi.is_unsubscribe_emails_check}
              onChange={handleToggleChange("is_unsubscribe_emails_check")}
            />
            <Typography
              className="switchText"
              sx={{ marginBottom: "0px !important" }}
            >
              {t("admin.unsubs")}
            </Typography>
          </Box>
        </Box>
        <Box className="FormField">
          <FormLabel className="label">{t("admin.uName")}</FormLabel>
          <Typography className="sublabel">{t("admin.noSpace")}</Typography>
          <input
            className="phoneInput"
            placeholder="Enter Username"
            name="username"
            value={editWsAdminApi?.username}
            onChange={setFormValue}
          />
          <Box
            className="airadioSwitchBox"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              margin: "0",
              marginTop: "5px",
            }}
          >
            <AntSwitch
              inputProps={{ "aria-label": "confirm username" }}
              checked={editWsAdminApi.confirm_username}
              onChange={handleToggleChange("confirm_username")}
            />
            <Typography
              className="switchText"
              sx={{ marginBottom: "0px !important" }}
            >
              {t("admin.mustConfirmUname")}
            </Typography>
          </Box>
        </Box>
        <Box className="FormField">
          <Typography mb={1}>{t("admin.newPassword")}</Typography>
          <Typography fontSize={"12px"} mb={1}>
            {t("admin.atLeast8Char")}
          </Typography>
          <Box mb={1} className="csPBox pwdInput">
            <input
              className="phoneInput"
              placeholder="Enter Password"
              type={pwdVisible ? "text" : "password"}
              name="password"
              onChange={setFormValue}
              value={editWsAdminApi?.password}
            />
            <img src={VisibleIcon} onClick={() => setPwdVisible(!pwdVisible)} />
          </Box>
          <Divider className="gapbetween" />
          <Box mb={1} className="csPBox pwdInput">
            <input
              className="phoneInput"
              placeholder={t("admin.confirmNewPass")}
              type={pwdVisible2 ? "text" : "password"}
              name="confirm_password"
              onChange={setFormValue}
              value={editWsAdminApi?.confirm_password}
            />
            <img
              src={VisibleIcon}
              onClick={() => setPwdVisible2(!pwdVisible2)}
            />
          </Box>
          <Box
            className="airadioSwitchBox"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              margin: "0",
              marginTop: "5px",
            }}
          >
            <AntSwitch
              inputProps={{ "aria-label": "forgot password change" }}
              checked={editWsAdminApi.force_password}
              onChange={handleToggleChange('force_password')}
            />
            <Typography
              className="switchText"
              sx={{ marginBottom: "0px !important" }}
            >
              {t("admin.forcePasswordChange")}
            </Typography>
          </Box>
        </Box>
        <Box className="FormField">
          <FormLabel className="label">{t("admin.2FactorAuth")}</FormLabel>
          <Typography className="sublabel">{t("admin.2FAAuth")}</Typography>
          <Box
            className="airadioSwitchBox"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              margin: "0",
              marginTop: "5px",
            }}
          >
            <AntSwitch
              inputProps={{ "aria-label": "enabled" }}
              checked={editWsAdminApi.two_fa_enabled}
              onChange={handleToggleChange('two_fa_enabled')}
            />
            <Typography
              className="switchText"
              sx={{ marginBottom: "0px !important" }}
            >
              {t("admin.enabled")}
            </Typography>
          </Box>
        </Box>
        <Box className="FormField" sx={{ marginLeft: "50px" }}>
          <Box className="alpOverCheck">
            <input
              style={{ marginRight: "25px" }}
              className="custom-checkbox"
              type="checkBox"
              checked={editWsAdminApi.donot_enforce_2fa}
              onChange={handleToggleChange('donot_enforce_2fa')}
            />
            <Typography>{t("admin.overRiderdnEnf")}</Typography>
          </Box>
        </Box>
        <Box className="FormField">
          <FormLabel className="label">{t("admin.timeZone")}</FormLabel>
          <Typography className="sublabel">
            {t("admin.daylightSaving")}
          </Typography>
          {/* <Grid container spacing={2}>
            <Grid item md={6}>
              <ReactFlagsSelect
                selected={selected}
                onSelect={(code) => setSelected(code)}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                placeholder="12:31 PM - Greenwich Mean Time"
                variant="outlined"
                size="small"
              />
            </Grid>
          </Grid> */}
          <Box mt={3} className="companiesTopBox">
            <Box className="companyInputBoxWlabel">
              <Typography mb={1} className="inputLabel">
                Select Country
              </Typography>

              <Box
                className="inputAppDrop"
                onClick={() => setCountryDrop(!countryDrop)}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography>
                    {selectedCountry?.countryEmoji || countryList[0]?.emoji}

                    {selectedCountry?.countryName || countryList[0]?.name}
                  </Typography>
                </Box>
                <img
                  style={{ rotate: countryDrop ? "180deg" : "0deg" }}
                  src={DownArrowIcon}
                />
                {/* pop */}
                <Box
                  sx={{
                    height: countryDrop ? "180px" : "0px",
                    overflowY: countryDrop ? "scroll" : "hidden",
                  }}
                  className="statusPop"
                >
                  {countryList &&
                    countryList.map((el, index) => (
                      <Box
                        key={index}
                        onClick={() => {
                          // setCountryDropVal(index);
                          setSelectedCountry({
                            countryName: el?.name,
                            countryEmoji: el?.emoji,
                          });
                          setEditWsAdminApi({
                            ...editWsAdminApi,
                            country: el._id,
                          });
                          setTimezoneList(el.timezones);
                        }}
                        className="statusPopItem"
                      >
                        {/* <img className="countryListImg" src={el.img} /> */}
                        <Typography>
                          {el.emoji} {el.name}
                        </Typography>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
            {/* drop */}
            <Box className="companyInputBoxWlabel">
              <Typography mb={1} className="inputLabel">
                Select Time Zone
              </Typography>
              <Box
                className="inputAppDrop"
                onClick={() => setTimeZoneDrop(!timeZoneDrop)}
              >
                <Typography>
                  {timeZoneVal || "Filter by Workspace Status"}
                </Typography>
                <img
                  style={{
                    rotate: timeZoneDrop ? "180deg" : "0deg",
                  }}
                  src={DownArrowIcon}
                />
                {/* pop */}
                <Box
                  sx={{ height: timeZoneDrop ? "auto" : "0px" }}
                  className="statusPop"
                >
                  {timeZoneList &&
                    timeZoneList?.map((el, index) => (
                      <Box
                        key={index}
                        onClick={() => handleTimeZone(el?.zoneName)}
                        className="statusPopItem"
                      >
                        <Typography>{el?.zoneName}</Typography>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="workPupBtnBox">
          <AppBtnOutLine buttonText={t("commonTxt.cancelBtn")}
          onClick={() => setEditAdminSection(false)} />
          <AppBtn buttonText={t("commonTxt.saveBtn")} onClick={() => updateWorkSpaceAdmin()} />
        </Box>
      </Box>
    </>
  );
}
