import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";

//components
import { AppBtnOutLine, AppBtn } from "../../AppTools/AppButton";
import dropArrow from "../../../Assets/Images/chevron-down.png"

export default function Api1Tab({ apiCredentialTab, apiCredentialConfig,
  setApiCredentialConfig,
  updateSetting }) {

  const update = async () => {
    updateSetting();
  }

  return (
    <>
      <Box
        sx={{ display: apiCredentialTab === 0 ? "flex" : "none" }}
        className="settingBox"
      >
        <Box mt={5} className="csPBox">
          <Typography className="allPartRedioLabel">Client ID</Typography>
          <input
            style={{ marginTop: "16px" }}
            className="phoneInput"
            placeholder="Enter API Client ID"
            value={apiCredentialConfig?.agora_credentials?.customer_id}
            onChange={(e) => setApiCredentialConfig({
              ...apiCredentialConfig, agora_credentials: {
                ...apiCredentialConfig.agora_credentials,
                customer_id: e.target.value
              }
            })}
          />
        </Box>

        <Box mt={5} className="csPBox">
          <Typography className="allPartRedioLabel">Key</Typography>
          <input
            style={{ marginTop: "16px" }}
            className="phoneInput"
            placeholder="Enter API Key"
            value={apiCredentialConfig?.agora_credentials?.key}
            onChange={(e) => setApiCredentialConfig({
              ...apiCredentialConfig, agora_credentials: {
                ...apiCredentialConfig.agora_credentials,
                key: e.target.value
              }
            })}
          />
        </Box>

        <Box mt={5} className="csPBox">
          <Typography className="allPartRedioLabel">Secret</Typography>
          <input
            style={{ marginTop: "16px" }}
            className="phoneInput"
            placeholder="Enter API Secret"
            value={apiCredentialConfig?.agora_credentials?.secret}
            onChange={(e) => setApiCredentialConfig({
              ...apiCredentialConfig, agora_credentials: {
                ...apiCredentialConfig.agora_credentials,
                secret: e.target.value
              }
            })}
          />
        </Box>
        <Box mt={5} className="csPBox">
          <Typography className="allPartRedioLabel">Chat App Key</Typography>
          <input
            style={{ marginTop: "16px" }}
            className="phoneInput"
            placeholder="Enter API Key"
            value={apiCredentialConfig?.agora_credentials?.chat_app_key}
            onChange={(e) => setApiCredentialConfig({
              ...apiCredentialConfig, agora_credentials: {
                ...apiCredentialConfig.agora_credentials,
                chat_app_key: e.target.value
              }
            })}
          />
        </Box>
        <Box mt={5} className="csPBox">
          <Typography className="allPartRedioLabel">Chat Orgname</Typography>
          <input
            style={{ marginTop: "16px" }}
            className="phoneInput"
            placeholder="Enter API Key"
            value={apiCredentialConfig?.agora_credentials?.chat_orgname}
            onChange={(e) => setApiCredentialConfig({
              ...apiCredentialConfig, agora_credentials: {
                ...apiCredentialConfig.agora_credentials,
                chat_orgname: e.target.value
              }
            })}
          />
        </Box>
        <Box mt={5} className="csPBox">
          <Typography className="allPartRedioLabel">Chat App Name</Typography>
          <input
            style={{ marginTop: "16px" }}
            className="phoneInput"
            placeholder="Enter API Key"
            value={apiCredentialConfig?.agora_credentials?.chat_app_name}
            onChange={(e) => setApiCredentialConfig({
              ...apiCredentialConfig, agora_credentials: {
                ...apiCredentialConfig.agora_credentials,
                chat_app_name: e.target.value
              }
            })}
          />
        </Box>
        <Box mt={5} className="csPBox">
          <Typography className="allPartRedioLabel">Chat Websocket</Typography>
          <input
            style={{ marginTop: "16px" }}
            className="phoneInput"
            placeholder="Enter API Key"
            value={apiCredentialConfig?.agora_credentials?.chat_websocket}
            onChange={(e) => setApiCredentialConfig({
              ...apiCredentialConfig, agora_credentials: {
                ...apiCredentialConfig.agora_credentials,
                chat_websocket: e.target.value
              }
            })}
          />
        </Box>
        <Box mt={5} className="csPBox">
          <Typography className="allPartRedioLabel">Chat Rest API</Typography>
          <input
            style={{ marginTop: "16px" }}
            className="phoneInput"
            placeholder="Enter API Key"
            value={apiCredentialConfig?.agora_credentials?.chat_rest_api}
            onChange={(e) => setApiCredentialConfig({
              ...apiCredentialConfig, agora_credentials: {
                ...apiCredentialConfig.agora_credentials,
                chat_rest_api: e.target.value
              }
            })}
          />
        </Box>
        <Box className="workPupBtnBox">
          <AppBtnOutLine
            buttonText="Cancel" />
          <AppBtn buttonText="Save" onClick={update} />
        </Box>
      </Box>

    </>
  );
}
