import React, { useState, useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";

import { useTranslation } from "react-i18next";

import arrow from "../../../Assets/Images/arrow.png";
import {
  SearchBar,
  TemplateStatus,
  LatestCreated,
  GoTop,
} from "../../AppTools/AppTool";
import { emailTemplateData } from "../../../Assets/Data";
import {
  editIconBlack,
  trashBlack,
  previewIconBlack,
} from "../../../Assets/Data";

//components
import EditEmailTemplatePop from "../EditEmailTemplatePop";
import EmailTemplatePop from "../EmailTemplatePop";
import ViewEmailTemplatePop from "../ViewEmailTemplatePop";
import DeleteTemplatePop from "../DeleteTemplatePop";

export default function EmailPage({ setEmailTemplatePop, emailTemplatePop }) {
  const [statusDrop, setStatusDrop] = useState(false);
  const [statusDropVal, setStatusDropVal] = useState("All");

  const [latestCDrop, setLatestDrop] = useState(false);
  const [latestDropVal, setLatestDropVal] = useState("Latest Created");
  const [optionIndex, setOptionIndex] = useState();
  const [workSpaceOption, setWorkspaceOption] = useState(false);

  const [deleteWorkspace, setDeleteWorkspace] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [newArrayData, setNewArrayData] = useState([]);

  const [editEmailTemplatePop, setEditEmailTemplatePop] = useState(false);
  const [viewEmailTemplatePop, setViewEmailTemplatePop] = useState(false);

  const [deleteTemplatePop, setDeleteTemplatePop] = useState(false);

  const workspaceOptionRef = useRef(null);
  const statusDropRef = useRef(null);
  const latestDropRef = useRef(null);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const handleOptionIndex = (i) => {
    setOptionIndex(i);
  };

  useEffect(() => {
    printItemsByState(emailTemplateData, currentPage);

    const handleClickOutside = (event) => {
      console.log("Clicked element:", event.target);
      console.log("Workspace option ref:", workspaceOptionRef.current);
      if (
        workspaceOptionRef.current &&
        !workspaceOptionRef.current.contains(event.target) &&
        !event.target.classList.contains("optionPopItem") &&
        !event.target.classList.contains("optionPopItema")
      ) {
        setWorkspaceOption(false);
      }

      if (statusDropRef.current || latestDropRef) {
        setStatusDrop(false);
        setLatestDrop(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [currentPage, statusDrop, latestCDrop]);

  function printItemsByState(array, page) {
    const startIndex = page * 8;
    const endIndex = (page + 1) * 8;
    const itemsToPrint = array.slice(startIndex, endIndex);
    setNewArrayData(itemsToPrint);
  }

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
  };

  return (
    <>
      <Box className="workspaceListBox workspaceListBoxV1">
        <EditEmailTemplatePop
          setEditEmailTemplatePop={setEditEmailTemplatePop}
          editEmailTemplatePop={editEmailTemplatePop}
        />
        <ViewEmailTemplatePop
          setViewEmailTemplatePop={setViewEmailTemplatePop}
          viewEmailTemplatePop={viewEmailTemplatePop}
        />
        <EmailTemplatePop
          setEmailTemplatePop={setEmailTemplatePop}
          emailTemplatePop={emailTemplatePop}
        />
        <DeleteTemplatePop
          deleteTemplatePop={deleteTemplatePop}
          setDeleteTemplatePop={setDeleteTemplatePop}
        />

        <Box mb={1} className="workListNavItem workListNavItemMobile">
          <Typography className="pageHeaderText">
            {t("emailPage.mailTemp")}
          </Typography>
        </Box>

        <Box className="workListNav">
          <Box className="workListNavItem workListNavItemDesktop">
            <Typography className="pageHeaderText ">
              {t("emailPage.mailTemp")}
            </Typography>
          </Box>
          <Box className="workListNavItem workListSearchBar">
            <SearchBar />
          </Box>
          <Box
            sx={{ minWidth: "145px" }}
            className="workListNavItem workshotList"
          >
            <TemplateStatus
              ref={statusDropRef}
              setStatusDrop={setStatusDrop}
              statusDrop={statusDrop}
              setStatusDropVal={setStatusDropVal}
              statusDropVal={statusDropVal}
            />
          </Box>
          <Box
            sx={{ minWidth: "215px" }}
            className="workListNavItem workLatest"
          >
            <LatestCreated
              ref={latestDropRef}
              setLatestDrop={setLatestDrop}
              latestCDrop={latestCDrop}
              setLatestDropVal={setLatestDropVal}
              latestDropVal={latestDropVal}
            />
          </Box>
        </Box>

        <Box className="workListBox">
          <Box className="haderRow" minWidth={"1111px"}>
            <Box minWidth={"35%"} className="Tabelsel navFsel templatehead">
              <Typography>{t("emailPage.temp")}</Typography>
            </Box>

            <Box className="Tabelsel statusSel" minWidth={"25%"}>
              <Typography>{t("emailPage.tempStatus")}</Typography>
            </Box>
            <Box className="Tabelsel statusSel templateheadl" minWidth={"25%"}>
              <Typography>{t("emailPage.date")}</Typography>
            </Box>
          </Box>
          {newArrayData &&
            newArrayData?.map((el, index) => (
              <Box key={index} className="TabelRow" minWidth={"1111px"}>
                <Box minWidth={"35%"} className="Tabelsel navFsel navtable">
                  <Box
                    ref={workspaceOptionRef}
                    id="dot"
                    onClick={() => {
                      setWorkspaceOption(!workSpaceOption);
                      setOptionIndex(index);
                    }}
                    className={
                      workSpaceOption && index === optionIndex
                        ? "dot dotActive"
                        : "dot"
                    }
                  >
                    {
                      <Box
                        sx={{
                          display:
                            workSpaceOption && index === optionIndex
                              ? "flex"
                              : "none",
                        }}
                        className="optionPop"
                      >
                        <Box
                          onClick={(event) => {
                            event.stopPropagation();
                            setViewEmailTemplatePop(true);
                            GoTop();
                          }}
                          className="optionPopItem"
                        >
                          {previewIconBlack}
                          <Typography>{t("emailPage.viewTemp")}</Typography>
                        </Box>
                        <Box
                          onClick={(event) => {
                            event.stopPropagation();
                            setEditEmailTemplatePop(true);
                            GoTop();
                          }}
                          className="optionPopItem"
                        >
                          {editIconBlack}
                          <Typography>{t("emailPage.editTemp")}</Typography>
                        </Box>
                        <Box
                          onClick={(event) => {
                            event.stopPropagation();
                            setDeleteTemplatePop(true);
                            GoTop();
                          }}
                          className="optionPopItem"
                        >
                          {trashBlack}
                          <Typography>{t("emailPage.deleteTemp")}</Typography>
                        </Box>
                      </Box>
                    }
                  </Box>

                  <Typography>{el.title}</Typography>
                </Box>

                <Box
                  className="Tabelsel statusSel Tabelcentersel"
                  minWidth={"25%"}
                >
                  <Typography>{el.status}</Typography>
                </Box>
                <Box
                  className="Tabelsel statusSel Tabelcentersel"
                  minWidth={"25%"}
                >
                  <Typography>{el.date}</Typography>
                </Box>
              </Box>
            ))}
        </Box>

        <Box className="pagenation">
          <Box onClick={handlePrev} className="tabelBtn">
            <img src={arrow} />
            <Typography>{t("commonTxt.previouspage")}</Typography>
          </Box>

          <Box className="pageNumberBox">
            <Box
              onClick={() => setCurrentPage(0)}
              className={
                currentPage === 0 ? "pageNumber pageNumberActive" : "pageNumber"
              }
            >
              <p>1</p>
            </Box>
            <Box
              onClick={() => setCurrentPage(1)}
              className={
                currentPage === 1 ? "pageNumber pageNumberActive" : "pageNumber"
              }
            >
              <p>2</p>
            </Box>
            <Box
              onClick={() => setCurrentPage(2)}
              className={
                currentPage === 2 ? "pageNumber pageNumberActive" : "pageNumber"
              }
            >
              <p>3</p>
            </Box>
            <Box
              onClick={() => setCurrentPage(3)}
              className={
                currentPage === 3 ? "pageNumber pageNumberActive" : "pageNumber"
              }
            >
              <p>4</p>
            </Box>
            <Box
              onClick={() => setCurrentPage(4)}
              className={
                currentPage === 4 ? "pageNumber pageNumberActive" : "pageNumber"
              }
            >
              <p>5</p>
            </Box>
          </Box>

          <Box onClick={handleNext} className="tabelBtn">
            <Typography>{t("commonTxt.nextPage")}</Typography>
            <img style={{ rotate: "180deg" }} src={arrow} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
// New Branch
