import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AppBtn, AppBtnOutLine } from "../../AppTools/AppButton";

//images
import dropArrow from "../../../Assets/Images/chevron-down.png";
import uploadIcon from "../../../Assets/Images/uil_Colo_upload.png"


export default function WorkspaceAddPagePop({ allDrop2Val, setAllDrop2Val }) {

    const [projectDrop3, setProjectDrop3] = useState(false);
  const [projectDropVal3, setProjectDropVal3] = useState("Select participant access options");

  const [projectDrop4, setProjectDrop4] = useState(false);
  const [projectDropVal4, setProjectDropVal4] = useState("Select administaror access options");


  const handelPopClose = (e) => {
    if (e.target.id === "popBackdrop") {
       setAllDrop2Val("Page Options");
    }
  };


  useEffect(() => {
    if (allDrop2Val === "Add Page") {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [allDrop2Val]);

  return (
    <>
      <Box
        id="popBackdrop"
        onClick={handelPopClose}
        sx={{ display: allDrop2Val === "Add Page" ? "flex" : "none" }}
        className="companyPop AddCardPopPeople "
      >
        <Box
          sx={{ display: allDrop2Val === "Add Page" ? "flex" : "none" }}
          className="workspaceContaint"
        >
          <Typography className="companyHeader">Add Page </Typography>

          <Box className="basicBoxItem">
            <Typography className='addPageLabel'>Site Page Name</Typography>
            <Box className="pickerBox">
              <input className='eTemInput' placeholder='Enter page name' />
            </Box>
          </Box>
          <Box className="basicBoxItem">
            <Typography className='addPageLabel'>Address</Typography>
            <Box className="pickerBox">
              <input className='eTemInput' placeholder='www.terapage.com/' />
            </Box>
          </Box>
          
          <Typography className="eTemPopLabel">Visibility </Typography>

          <Box className="basicBoxItem addPageParticipantAccess">
            <Typography className="addPageLabel">Participant Access</Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setProjectDrop3(!projectDrop3)}
            >
              <Typography>
                {projectDropVal3 || "Filter by Project"}
              </Typography>
              <img
                style={{ rotate: projectDrop3 ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: projectDrop3 ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setProjectDropVal3("Item-A")}
                  className="statusPopItem"
                >
                  <Typography>Item-A</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal3("Item-B")}
                  className="statusPopItem"
                >
                  <Typography>Item-B</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal3("Item-C")}
                  className="statusPopItem"
                >
                  <Typography>Item-C</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal3("Item-D")}
                  className="statusPopItem"
                >
                  <Typography>Item-D</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="basicBoxItem addPageParticipantAccess">
            <Typography className="addPageLabel">Administrator Access</Typography>
            <Box
              className="inputAppDrop"
              onClick={() => setProjectDrop4(!projectDrop4)}
            >
              <Typography>
                {projectDropVal4 || "Filter by Project"}
              </Typography>
              <img
                style={{ rotate: projectDrop4 ? "180deg" : "0deg" }}
                src={dropArrow}
              />
              {/* pop */}
              <Box
                sx={{ height: projectDrop4 ? "auto" : "0px" }}
                className="statusPop"
              >
                <Box
                  onClick={() => setProjectDropVal4("Item-A")}
                  className="statusPopItem"
                >
                  <Typography>Item-A</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal4("Item-B")}
                  className="statusPopItem"
                >
                  <Typography>Item-B</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal4("Item-C")}
                  className="statusPopItem"
                >
                  <Typography>Item-C</Typography>
                </Box>
                <Box
                  onClick={() => setProjectDropVal4("Item-D")}
                  className="statusPopItem"
                >
                  <Typography>Item-D</Typography>
                </Box>
              </Box>
            </Box>
          </Box>




          <Box className="workPupBtnBox">
            <AppBtnOutLine buttonText="Cancel" onClick={() => setAllDrop2Val("Page Options")} />
            <AppBtn buttonText="Save Changes" />
          </Box>



        </Box>
      </Box>
    </>
  );
}
