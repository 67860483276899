import React, { useState } from "react";
import { Box } from "@mui/material";

import { Workspace } from "../../../Assets/Data";
import { AppBtn } from "../../../Components/AppTools/AppButton";
import WorkspacePop from "../../../Components/Admin/WorkspacePop";
import WorkspaceList from "../../../Components/Admin/WorkspaceList";
import NoWorkspaceBG from "../../../Components/Admin/NoWorkspaceBG";
import AdminLayout from "../partials/AdminLayout";

export default function WorkSpaceManagement() {
  const [workSpacePop, setWorkSpacePop] = useState(false);
  // Company pop-up
  const [companyPop, setCompanyPop] = useState(false);
  const [editCompanyPop, setEditCompanyPop] = useState(false);
  const [workspaceCompany, setWorkspaceCompany] = useState(false);
  const [editWorkspaceCompany, setEditWorkspaceCompany] = useState(false);
  const [addResearcherPop, setAddResearcherPop] = useState(false);
  const [workspaceResearcher, setWorkspaceResearcher] = useState(false);
  const [editWorkspaceResearcher, setEditWorkspaceResearcher] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [createdAffiliate, setcreatedAffiliate] = useState(null);
  let [allCompaniesData, setAllCompaniesData] = useState(null);
  let [workspacePopType, setWorkspacePopType] = useState(null);

  const [showCompanyUser, setShowCompanyUser] = useState(false);

  return (
    <AdminLayout
      childComponent={
        <Box sx={{ display: "flex" }} className="adminMainBox">
          {Workspace.length <= 0 ? (
            <NoWorkspaceBG />
          ) : (
            <>
              <WorkspacePop
                workSpacePop={workSpacePop}
                setWorkSpacePop={setWorkSpacePop}
                setCompanyPop={setCompanyPop}
                companyPop={companyPop}
                setAllCompaniesData={setAllCompaniesData}
                workspacePopType={workspacePopType}
                setWorkspacePopType={setWorkspacePopType}
                setReloadList={setReloadList}
                setEditCompanyPop={setEditCompanyPop}
                setWorkspaceCompany={setWorkspaceCompany}
                workspaceCompany={workspaceCompany}
                setEditWorkspaceCompany={setEditWorkspaceCompany}
                setAddResearcherPop={setAddResearcherPop}
                addResearcherPop={addResearcherPop}
                setWorkspaceResearcher={setWorkspaceResearcher}
                workspaceResearcher={workspaceResearcher}
                setEditWorkspaceResearcher={setEditWorkspaceResearcher}
                createdAffiliate={createdAffiliate}
                setShowCompanyUser={setShowCompanyUser}
                showCompanyUser={showCompanyUser}
              />
              <WorkspaceList
                workSpacePop={workSpacePop}
                setWorkSpacePop={setWorkSpacePop}
                setCompanyPop={setCompanyPop}
                companyPop={companyPop}
                setWorkspacePopType={setWorkspacePopType}
                workspacePopType={workspacePopType}
                allCompaniesData={allCompaniesData}
                setAllCompaniesData={setAllCompaniesData}
                setEditCompanyPop={setEditCompanyPop}
                editCompanyPop={editCompanyPop}
                reloadList={reloadList}
                setReloadList={setReloadList}
                setWorkspaceCompany={setWorkspaceCompany}
                workspaceCompany={workspaceCompany}
                setEditWorkspaceCompany={setEditWorkspaceCompany}
                editWorkspaceCompany={editWorkspaceCompany}
                setAddResearcherPop={setAddResearcherPop}
                addResearcherPop={addResearcherPop}
                setWorkspaceResearcher={setWorkspaceResearcher}
                workspaceResearcher={workspaceResearcher}
                setEditWorkspaceResearcher={setEditWorkspaceResearcher}
                editWorkspaceResearcher={editWorkspaceResearcher}
                setcreatedAffiliate={setcreatedAffiliate}
                createdAffiliate={createdAffiliate}
                setShowCompanyUser={setShowCompanyUser}
                showCompanyUser={showCompanyUser}
              />
            </>
          )}
        </Box>
      }
      actionBtn={
        <AppBtn
          onClick={() => setWorkSpacePop(true)}
          buttonText="Create Workspace"
        />
      }
    />
  );
}
