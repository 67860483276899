import React, { useState, useEffect } from "react";
import "./style.css";
import { Box, Typography } from "@mui/material";

import Avatar from "../../../Assets/Images/UserHomeAvatar.png";
import consartImg from "../../../Assets/Images/consartImg.png";
import users from "../../../Assets/Images/usersB.png";
import star from "../../../Assets/Images/Star 2.png";
import redDot from "../../../Assets/Images/RedDot.png";
import BottomBanner from "../../../Assets/Images/Bbanner.png";
import hillMan from "../../../Assets/Images/hillman.png";
import user1 from "../../../Assets/Images/New folder/user1.png";
import user2 from "../../../Assets/Images/New folder/user2.png";
import user3 from "../../../Assets/Images/New folder/user3.png";
import user4 from "../../../Assets/Images/New folder/user4.png";
import avatar from "../../../Assets/Images/UserHomeAvatar.png";
import backgroundImg from "../../../Assets/Images/Add-files-cuate.png";
import palyBtn from "../../../Assets/Images/bi_play-fill.png";



import { sideHaumIcon } from "../../../Assets/Data";


//components
import SideMenu from "../../../Components/Observer/SideMenu";
import WorkspaceHomeEditView from "../../../Components/Researchers/WorkspaceHomeEditView";
import { t } from "i18next";


export default function WorkspaceHome({
  slideMenu,
  setSlideMenu,
  workspaceDrop,
  setWorkSpaceDrop,
}) {
  const data = true;
  const [memBerPop, setMemBerPop] = useState(false);
  const [workSHAddCardPop, setWorkSHAddCardPop] = useState(false);
  const [allDropVal3, setAllDropVal3] = useState("Page Options");

  const userRow = (img, title) => {
    return (
      <Box className="rOnlUserRow">
        <Box>
          <img src={img} />
        </Box>
        <Typography>{title}</Typography>
      </Box>
    );
  };

  const memberItem = (img, title) => {
    return (
      <Box
        onClick={() => {
          setMemBerPop(true);
        }}
        className="memberRowItem"
      >
        <Box>
          <img src={img} />
        </Box>
        <Typography>{title}</Typography>
      </Box>
    );
  };

  useEffect(() => {
    if (memBerPop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }
  }, [memBerPop]);

  return (
    <>
      <Box className="ResearchersContainer observerHome">
        {/* side menu */}
        <SideMenu
          slideMenu={slideMenu}
          setSlideMenu={setSlideMenu}
          workspaceDrop={workspaceDrop}
          setWorkSpaceDrop={setWorkSpaceDrop}
        />

        <Box className="adminMainSection">
          <Box className="adminNavBar">
            <Box
              onClick={() => setSlideMenu(!slideMenu)}
              sx={{ rotate: slideMenu ? "0deg" : "-180deg" }}
              className="sideHaumIcon"
            >
              {sideHaumIcon}
            </Box>
          </Box>

          {/* Page container */}
          <Box className="researchersMainBox pageContainer">
            {data === false ? (
              <>
                <Box mb={7} className="HomeEmptyContainer">
                  <Box className="userWelBox">
                    <Box className="user_Box">
                      <Box>
                        <img src={avatar} />
                      </Box>
                      <Typography>{t("workspaceHome.Welcome")}Lana Steiner</Typography>
                    </Box>
                    <Typography className='userboxSubText'>{t("workspaceHome.noDataSummery")}<span>{t("workspaceHome.noDataSummery1")}</span>{t("workspaceHome.noDataSummery2")}</Typography>
                  </Box>
                  <Box className="bgMainBox">
                    <img src={backgroundImg} />
                    <Typography className='userboxSubText'>{t("workspaceHome.noDataSummery3")}</Typography>
                    <Typography className='userboxSubText'>{t("workspaceHome.noDataSummery4")}</Typography>
                  </Box>
                </Box>
              </>
            )
              // : allDropVal3 === "Edit Page" ? (
              //   <WorkspaceHomeEditView
              //     setWorkSHAddCardPop={setWorkSHAddCardPop}
              //     workSHAddCardPop={workSHAddCardPop}
              //     allDropVal3={allDropVal3}
              //     setAllDropVal3={setAllDropVal3}
              //   />
              // ) 
              : (
                <Box className="userHomeContainer reworkspace">

                  <Box className="userHMBox3">
                    <Box className="userHMBoxItem userhAvatarBox homeCard objBox">
                      <Box className="AvartimgBox">
                        <img src={Avatar} />
                      </Box>
                      <Typography className="userHMText">User Name</Typography>
                    </Box>
                    <Box className="userHMBoxItem objBox objHTextBox">
                      <Typography>Lorem ipsum dolor sit amet consectetur.</Typography>
                      <span>Lorem ipsum dolor sit amet consec tetur. Ac quisque tristique dignissim non. Erat id ipsum sem non auctor. Elit non massa quis faucibus vel donec sapien felis. Tellus integer et vulputate netus sed. Ac quisque tristique dignissim non. Erat id ipsum sem non auctor. Elit non massa quis faucibus vel donec sapien felis.</span>
                    </Box>
                    <Box className="userHMBoxItem userHConsart objBox">
                      <Box className="conImgBox">
                        <img src={consartImg} />
                      </Box>
                      <Box sx={{ height: "48px" }} className="userHCTextBox">
                        <Typography sx={{ fontSize: "20px" }}>{t("workspaceHome.Welcome")}</Typography>
                        <Box className="obgHPlayPtb">
                          <img src={palyBtn} />
                        </Box>
                      </Box>
                      <Box className="userHCTextBox">
                        <Box>
                          <Typography sx={{ fontSize: "16px", color: "#98A2B3" }}>
                            Aug 02 - Aug 03
                          </Typography>
                          <Box
                            sx={{ background: "#12B76A" }}
                            className="ActiveStatus"
                          ></Box>
                        </Box>
                        <Box>
                          <img src={users} />
                          <Typography
                            ml={1}
                            sx={{ fontSize: "20px", color: "#98A2B3" }}
                          >
                            11
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box className="userHMBox3">
                    <Box className="userHMBoxItem ConversationsBox objBox">
                      <Typography className="ConversationsBoxHText">{t("workspaceHome.Conversations")}</Typography>

                      <Box className="convChatItem">
                        <Box mb={0.8}>
                          <Typography>{t("workspaceHome.RecentTrekkingOutings")}</Typography>
                          <Box className="iconBox">
                            <img
                              style={{ width: "20px", height: "20px" }}
                              src={star}
                            />
                            <img
                              style={{ width: "12px", height: "12px" }}
                              src={redDot}
                            />
                          </Box>
                        </Box>
                        <Box>
                          <Typography className="convSubText">
                            {t("workspaceHome.OutdoorTips")}
                          </Typography>
                          <Box className="viewBtn">
                            <Typography>{t("commonTxt.viewBtn")}</Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box className="convChatItem">
                        <Box mb={0.8}>
                          <Typography>{t("workspaceHome.YourTrekkingMustHaves")}</Typography>
                          <Box className="iconBox">
                            <img
                              style={{ width: "20px", height: "20px" }}
                              src={star}
                            />
                            <img
                              style={{ width: "12px", height: "12px" }}
                              src={redDot}
                            />
                          </Box>
                        </Box>
                        <Box>
                          <Typography className="convSubText">{t("workspaceHome.EquipmentZone")}</Typography>
                          <Box className="viewBtn">
                            <Typography>{t("commonTxt.viewBtn")}</Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box className="convChatItem">
                        <Box mb={0.8}>
                          <Typography>{t("workspaceHome.MealHacks")}</Typography>
                          <Box className="iconBox">
                            <img
                              style={{ width: "20px", height: "20px" }}
                              src={star}
                            />
                            <img
                              style={{ width: "12px", height: "12px" }}
                              src={redDot}
                            />
                          </Box>
                        </Box>
                        <Box>
                          <Typography className="convSubText">
                            {t("workspaceHome.OutdoorTips")}
                          </Typography>
                          <Box className="viewBtn">
                            <Typography>{t("commonTxt.viewBtn")}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    <Box className="bottomBannerBox objBox">
                      <img src={BottomBanner} />
                    </Box>
                  </Box>

                  <Box className="userHMBox3">
                    <Box className="userHMBoxItem keyDataBox objBox">
                      <Typography className="ConversationsBoxHText">
                        Key Dates
                      </Typography>
                      <Box className="keyDataRow">
                        <Typography>{t("commonTxt.Monday")} -</Typography>
                        <span> {t("workspaceHome.IntroductionTask")}</span>
                      </Box>
                      <Box className="keyDataRow">
                        <Typography>{t("commonTxt.Tuesday")} -</Typography>
                        <span>{t("workspaceHome.IntroductionTask")}</span>
                      </Box>
                      <Box className="keyDataRow">
                        <Typography>{t("commonTxt.Wednesday")} -</Typography>
                        <span>{t("workspaceHome.Taskexperience")}</span>
                      </Box>
                      <Box className="keyDataRow">
                        <Typography>{t("commonTxt.Thursday")} -</Typography>
                        <span>{t("workspaceHome.Taskexperience")}</span>
                      </Box>
                      <Box className="keyDataRow">
                        <Typography>{t("commonTxt.Friday")} -</Typography>
                        <span>{t("workspaceHome.IntroductionTask")}</span>
                      </Box>
                      <Box className="keyDataRow">
                        <Typography>{t("commonTxt.Saturday")} -</Typography>
                        <span>{t("workspaceHome.Taskexperience")}</span>
                      </Box>
                      <Box className="keyDataRow">
                        <Typography>{t("commonTxt.Sunday")} -</Typography>
                        <span>{t("workspaceHome.IntroductionTask")}</span>
                      </Box>
                    </Box>

                    <Box className="userHMBoxItem objBox">
                      <img src={hillMan} className="hillManImg" />
                    </Box>

                    <Box className="userHMBoxItem RecentlyOnlineBox objBox">
                      <Typography className="ConversationsBoxHText">
                        {t("workspaceHome.RecentlyOnline")}
                      </Typography>
                      {userRow(user1, "Participant01 FullName")}
                      {userRow(user2, "Participant02 FullName")}
                      {userRow(user3, "Participant03 FullName")}
                      {userRow(user4, "Participant04 FullName")}
                      <Box className="pageNum">
                        <Box className="pageNumItem pageNumActive">
                          <p>1</p>
                        </Box>
                        <Box className="pageNumItem">
                          <p>2</p>
                        </Box>
                        <Box className="pageNumItem">
                          <p>3</p>
                        </Box>
                        <Box className="pageNumItem">
                          <p>4</p>
                        </Box>
                        <Box className="pageNumItem">
                          <p>5</p>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box className="userHMBox3 userHMBox4">
                    <Box className="userHMBoxItem memberBox1">
                      <Typography className="ConversationsBoxHText">Gold Members</Typography>
                      <Box mb={2} className="memberRow">
                        {memberItem(user1, "Name01")}
                        {memberItem(user2, "Name02")}
                        {memberItem(user3, "Name03")}
                      </Box>
                    </Box>
                    <Box className="userHMBoxItem memberBox2">
                      <Typography className="ConversationsBoxHText">Silver Members</Typography>
                      <Box mb={2} className="memberRow">
                        {memberItem(user1, "Name01")}
                        {memberItem(user2, "Name02")}
                        {memberItem(user3, "Name03")}
                      </Box>
                    </Box>
                    <Box className="userHMBoxItem memberBox3">
                      <Typography className="ConversationsBoxHText">Bronze Members</Typography>
                      <Box mb={2} className="memberRow">
                        {memberItem(user1, "Name01")}
                        {memberItem(user2, "Name02")}
                        {memberItem(user3, "Name03")}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
