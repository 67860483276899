import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DropArrow from "../../../Assets/Images/chevron-down.png";

import { AppBtnOutLine, AppBtn } from "../../AppTools/AppButton";

import backIcon from "../../../Assets/Images/icon-arrow.svg";
import axios from "../../../lib/axios";
import { API_BASE_URL } from "../../../env";
import { toast } from "react-toastify";
import { formatDate } from "../../../lib/helpers";
import { useAppSelector } from "../../../lib/store/store";

export default function SettingStandardGroup({
  standardSetting,
  setStandardSetting,
  getGroupData,
  getEnhancedGroupData,
}) {
  const grpId = standardSetting;
  const Navigate = useNavigate();
  const workspaceState = useAppSelector((state) => state.workspace);
  const [projectvisibility, setprojectvisibility] = React.useState(0);
  const [allParticipantsList, setAllParticipantsList] = useState([]);
  const [nameError, setNameError] = useState("");
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const handleRouting = (Url) => {
    Navigate(Url);
  };
  const groupData = {
    name: "",
    restricted_projects: [],
    allow_projects: [],
    remove_projects: [],
  };
  const [updateGroupData, setupdateGroupData] = useState(groupData);
  const [projectList, setProjectList] = useState([]);

  const [addprojectparticipants, setaddprojectparticipants] = React.useState(0);

  const handleChange2 = (event) => {
    setaddprojectparticipants(event.target.value);
  };

  const [removeprojectparticipants, setremoveprojectparticipants] =
    React.useState(0);

  const handleChange3 = (event) => {
    setremoveprojectparticipants(event.target.value);
  };
  const handleChange = (event) => {
    setupdateGroupData((prevGroup) => ({
      ...prevGroup,
      [event.target.name]: event.target.value,
    }));
  };


  const [colorSelectorDrop1, setColorSelectorDrop1] = useState(false);
  const [colorSelectorDrop1Val, setColorSelectorDrop1Val] =
    useState("Solid Color");
  useEffect(() => {
    const getGroupData = async () => {
      try {
        const response = await axios.get(API_BASE_URL + `/participant/groups?_id=${grpId}`);

        const data = response.data.data
        data.forEach(val => {
          setupdateGroupData(prevData => ({ ...prevData, ...val }));
        });
      } catch (error) {
        console.log(error.message);
      }
    }
    getGroupData();
    getProject();
  }, [])
  const getProject = async () => {
    try {
      const data = await axios.get(API_BASE_URL + '/researchers/projects');
      setProjectList(data.data.data)
    } catch (error) {
      console.log(error);
    }
  }
  const updateGroup = () => {
    if (updateGroupData.name === "") {
      setNameError("Group name is required.")
      return toast.error("Group name is required.")
    } else {
      setNameError("")
    }
    const payload = {
      name: updateGroupData?.name,
      allow_projects: updateGroupData?.allow_projects,
      restricted_projects: updateGroupData?.restricted_projects,
      remove_projects: updateGroupData?.remove_projects,
      workspace_id: workspaceState?.selectedWorkspace?._id,
    };
    axios
      .put(API_BASE_URL + `/participant/groups/${grpId}`, payload)
      .then((res) => {
        if (res.success) {
        toast.success(res.message)
          getGroupData();
        getEnhancedGroupData()
        setStandardSetting(false)
        } else {
        toast.error(res.message)
        }
    }).catch((err) => {
      toast.error(err.message)
      })
  }

  return (
    <Box className="workspaceItemBox">
      <Box className="SettingsFormSection">
        
        <Box
          onClick={() => setStandardSetting(false)}
          className="openBtn"
        >
          <img src={backIcon} />
          <Typography className="neonBackText">
            {t("commonTxt.backBtn")}
          </Typography>
        </Box>

        <Box className="HeadingOptions">
          <Typography variant="h4">
            {t("groups.settings")}: {t("groups.stdGrpBtn")}
          </Typography>
          <Box
            onClick={() => setColorSelectorDrop1(!colorSelectorDrop1)}
            width={"250px"}
            height={"40px"}
            px={2}
            className="statusBox"
          >
            <Typography>{t("groups.option")}</Typography>
            <img src={DropArrow} />
            {/* drop */}
            <Box
              sx={{ height: colorSelectorDrop1 ? "auto" : "0px" }}
              className="statusPop"
            >
              <Box
                onClick={() => setColorSelectorDrop1Val("Remove personal data")}
                className="statusPopItem"
              >
                <Typography>
                  {<DoDisturbIcon />} {t("groups.remPersonalData")}
                </Typography>
              </Box>
              <Box
                onClick={() => setColorSelectorDrop1Val("Delete")}
                className="statusPopItem"
              >
                <Typography>
                  {<DeleteOutlineIcon />} {t("commonTxt.delete")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography className="greybox">
              {t("groups.grpId")}: <span>{updateGroupData.group_id}</span>
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography className="greybox">
              {t("groups.updated")}:{" "}
              <span>{formatDate(updateGroupData.updated_at)}</span>
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography className="greybox">
              {t("groups.participants")}:{" "}
              <span>{updateGroupData.allow_projects.length}</span>
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography className="greybox">
              {t("directoryUsers.added")}: <span>Jun 5, 2023; 12:02 PM</span>
            </Typography>
          </Grid>
        </Grid>

        <Box className="FormField">
          <Box className="FormField">
            <Typography variant="h5">Basics</Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              placeholder="Enter basics"
              variant="outlined"
              size="small"
              onChange={handleChange}
              value={updateGroupData.name}
              defaultValue={"Age 18 - 25"}
              name="name"
            />
            <span className="validationerr">{nameError}</span>
          </Box>

          <Box className="FormField">
            <Typography variant="h5">Advanced Options</Typography>
            <Typography variant="h6">Project Visibility</Typography>
            <Typography>
              Restrict the group's visibility to projects within the site that
              are specifically relevant to it.
            </Typography>
            <FormControl sx={{ width: "100%" }} size="small">
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={updateGroupData.restricted_projects}
                onChange={handleChange}
                name="restricted_projects"
                multiple
              >
                <MenuItem value={0}>All Projects</MenuItem>
                {projectList.map((list, id) => (
                  <MenuItem value={list._id} key={id}>
                    {list.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box className="FormField">
            <Typography variant="h6">Add participants to projects</Typography>
            <Typography>
              Participants, whether new or existing, who are assigned to this
              group will be included in the selected projects.
            </Typography>
            <FormControl sx={{ width: "100%" }} size="small">
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={updateGroupData.allow_projects}
                onChange={handleChange}
                name="allow_projects"
                multiple
              >
                <MenuItem value={0}>None</MenuItem>
                {projectList.map((list, id) => (
                  <MenuItem value={list._id} key={id}>
                    {list.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box className="FormField">
            <Typography variant="h6">
              Remove participants from projects
            </Typography>
            <Typography>
              Participants, whether new or existing, who are assigned to this
              group will be excluded in the selected projects.
            </Typography>
            <FormControl sx={{ width: "100%" }} size="small">
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={updateGroupData.remove_projects}
                name="remove_projects"
                onChange={handleChange}
                multiple
              >
                <MenuItem value={0}>None</MenuItem>
                {projectList.map((list, id) => (
                  <MenuItem value={list._id} key={id}>
                    {list.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box className="FormField">
            <Grid container spacing={2}>
              <Grid item sm={6}></Grid>
              <Grid item sm={6}>
                <Box className="flexEnd">
                  <AppBtnOutLine
                    buttonText={t("commonTxt.cancelBtn")}
                    onClick={() => handleRouting(setStandardSetting(false))}
                  />
                  <AppBtn
                    buttonText={t("commonTxt.saveBtn")}
                    onClick={updateGroup}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
